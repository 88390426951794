import Services from "./services";

export const ActionTypes = {
  SEARCH_PHOTOS: "SEARCH_PHOTOS",
  SEARCH_PHOTOS_SUCCESS: "SEARCH_PHOTOS_SUCCESS",
  REQUEST_FAILED: "REQUEST_FAILED",
  SET_LOADING_IMG: "SET_LOADING_IMG",
  REMOVE_LOADING_IMG: "REMOVE_LOADING_IMG",
};

class Actions {

  static search({query, provider, page, mode}) {
    return async dispatch => {
      try {
        dispatch({type: ActionTypes.SEARCH_PHOTOS});
        const callback = (res) => {
          dispatch({
            type: ActionTypes.SEARCH_PHOTOS_SUCCESS,
            page: page,
            max_page: res.max_page,
            payload: res.images,
            mode
          });
        }
        switch(provider){
          case 0:
            await Services.searchPhotosUnsplash(query, page, callback);
            break;
          case 1:
            await Services.searchPhotosPexels(query, page, callback);
            break;
          case 2:
            await Services.searchPhotosShutterstock(query, callback);
            break;
          case 3:
            await Services.searchPhotosAdobeStock(query, callback);
            break;
          default:
            break;
        }
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }

  static setLoadingImg(index) {
    return async dispatch => {
      try {
        dispatch({type: ActionTypes.SET_LOADING_IMG,
          payload: index});
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }

  static removeLoadingImg(index) {
    return async dispatch => {
      try {
        dispatch({type: ActionTypes.REMOVE_LOADING_IMG,
          payload: index});
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
}

export default Actions;
