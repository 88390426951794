import React from 'react';
import { makeStyles, IconButton, Dialog, Slide,
  AppBar, Toolbar } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { useHistory, useLocation } from "react-router-dom";
import RadioButtons from '../../../layout/mobile/webBuilder/forms/SortSettings/radioButtons'


const DialogTransition = props => <Slide direction="up" {...props} />;


const useStyles = makeStyles(theme => ({
  root: {
    height: "auto !important",
    bottom: "0px !important",
    top: "auto !important",
    zIndex: "9999999999 !important"
  },
  appBar: {
    height: 55,
    position: "relative",
    backgroundColor: "white",
    boxShadow: "none",
    // borderBottom: "1px solid #d1d5dd"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    width: "100%"
  },
  closeBtn: {
    position: "absolute"
  },
  body: {
    height: "auto"
  },
}));

const sortChoices = [
  { name: "Latest", value: "-created_on" },
  { name: "Oldest", value: "created_on" },
  { name: "A - Z", value: "name" },
  { name: "Z - A", value: "-name" },
  { name: "₱ - ₱₱₱ ", value: "price" },
  { name: "₱₱₱ - ₱ ", value: "-price" }
]

const SortSettings = (props) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [sortBy, setSortBy] = React.useState({ name: "Latest", value: "-created_on" });
  const category_filter = new URLSearchParams(location.search).get("category");
  
  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(sortBy.value)
      history.push({
        pathname: location.pathname,
        search: `?category=${encodeURIComponent(
          category_filter 
        )}&sortBy=${encodeURIComponent(sortBy.value)}`,
      });
    }
  }, [sortBy])

  return (
    <Dialog
      fullScreen
      open={props.open}
      TransitionComponent={DialogTransition}
      className={classes.root}
    >
      <div >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={() => props.setOpen(false)}
              edge="start"
              color="primary"
              className={classes.closeBtn}
            >
              <Close style={{ color: props.color ? props.color: "#f97663" }} />
            </IconButton>
            <div className={`${classes.header} text-center`}>SORT BY</div>
          </Toolbar>
        </AppBar>
        <div className={`${classes.body} p-3`}>
          <RadioButtons color={props.color} choices={sortChoices} value={sortBy} onChange={(val) => setSortBy(val)}/>
        </div>
      </div>
      
    </Dialog>
  )
}

export default SortSettings;