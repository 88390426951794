require("dotenv").config();
export const apiConfig = {
  gtmId: process.env.REACT_APP_GTM_ID,
  apiURL: process.env.REACT_APP_API_URL,
  fbID: process.env.REACT_APP_FACEBOOK_APP_ID,
  clientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
  clientSecret: process.env.REACT_APP_FACEBOOK_CLIENT_SECRET,
  localHost: process.env.REACT_APP_LOCAL_HOST,
  buxURL: process.env.REACT_APP_BUX_URL,
  indexStoreID: process.env.REACT_APP_GTM_INDEX_STORE_ID,
  googleAPIKey: process.env.REACT_GOOGLE_API_KEY,
  activePromo: process.env.REACT_APP_PROMO,
  unsplash_key: process.env.REACT_APP_UNSPLASH_KEY,
  pexel_key: process.env.REACT_APP_PEXEL_KEY,
  shutterstock_key: process.env.REACT_APP_SHUTTERSTOCK_KEY,
  adobestock_key: process.env.REACT_APP_ADOBESTOCK_KEY,
  bux_wallet_add_money: process.env.REACT_APP_BUX_WALLET_ADD_MONEY,
  nftApiUrl: process.env.REACT_APP_NFT_API_URL,
  mawbebsAuthApiUrl: process.env.REACT_APP_MAWBEBS_AUTH_API_URL,
  mawbebsApiUrl: process.env.REACT_APP_MAWBEBS_API_URL,
  mawbebsAuthKey: process.env.REACT_APP_MAWBEBS_AUTH,
  mawbebsChannelId: process.env.REACT_APP_MAWBEBS_CHANNEL_ID,
  tokenAddress: process.env.REACT_APP_TOKEN_ADDRESS,
  domainVersion: process.env.REACT_APP_DOMAIN_VERSION,
  chainId: process.env.REACT_APP_CHAIN_ID,
  platformFee: process.env.REACT_APP_PLATFORM_FEE,
  platformFeeRecipient: process.env.REACT_APP_PLATFORM_FEE_RECIPIENT,
  alamat_users: process.env.REACT_APP_ALAMAT_USERS,
  recipientAddress: process.env.REACT_APP_RECIPIENT_ADDRESS,
  recipientWalletAddress: process.env.REACT_APP_SELLER_ADDRESS,
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
  storeUsername: process.env.REACT_APP_STORE_USERNAME,
  storeUserPassword: process.env.REACT_APP_STORE_PASSWORD,
  alamatApiUrl: process.env.REACT_APP_ALAMAT_API_URL,
  alamatApiKey: process.env.REACT_APP_ALAMAT_API_KEY,
  nftPostbackSecret: process.env.REACT_APP_NFT_POSTBACK_SECRET,
  alamatSiteUrl: process.env.REACT_APP_ALAMAT_SITE_URL,
  alamatSecretKey: process.env.REACT_APP_ALAMAT_SECRET_KEY,
  nftWalletMapSecret: process.env.REACT_APP_NFT_WALLET_MAP_SECRET,
  alamatOrderFormUrl: process.env.REACT_APP_ALAMAT_ORDER_FORM_URL,
  fiatOption: process.env.REACT_APP_FIAT_OPTION,
  nftAlamatBuxRecipient: process.env.REACT_APP_NFT_ALAMAT_BUX_RECIPIENT,
  orderPostbackClientId: process.env.REACT_APP_SENTRO_ORDER_POSTBACK_CLIENT_ID,
  orderPostbackApiKey: process.env.REACT_APP_SENTRO_ORDER_POSTBACK_API_KEY,
  orderPostbackSecret: process.env.REACT_APP_SENTRO_ORDER_POSTBACK_SECRET,
  alamatchClientId: process.env.REACT_APP_ALAMATCH_CLIENT_ID,
  alamatchClientSecret: process.env.REACT_APP_ALAMATCH_CLIENT_SECRET,
};

export default apiConfig;
