import React from "react";
import { Link } from "@material-ui/core";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";
let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
let keyData = data ? data.email : null;
export const FAQ = [
  {
    key: 0,
    question: "What is Sentro?",
    answer: (
      <div>
        <div style={{ fontSize: 14 }}>
          Sentro is your go-to platform that will help you go digital in
          starting, running and growing your business. We have various digital
          tools and solutions to help you manage your business. One of Sentro's
          main features is the FREE online shop builder, which can be set up in
          just a matter of minutes!{" "}
          <Link
            onClick={() => {
              keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
            }}
            style={{ color: "#f97663", fontWeight: 600 }}
          >
            Click here to get started.
          </Link>
        </div>
      </div>
    ),
    answerString:
      "Sentro is your go-to platform that will help you go digital in starting, running and growing your business. We have various digital tools and solutions to help you manage your business. One of Sentro's main features is the FREE online shop builder, which can be set up in just a matter of minutes!"
  },
  {
    key: 0,
    question: "Why was Sentro created?",
    answer:
      " Now that is a question worthy of a beauty pageant contest! But seriously, Sentro was created to address the problems or issues of small business entrepreneurs, like you, here in the Philippines. Now that e-commerce has taken off in our country, we want to solve your concerns revolving around starting an online business, making your business digital, running your business day-to-day activities, increasing your reach and sales, making your business known locally and internationally, and penetrating overseas markets. Yes, it’s true, Sentro was created for business owners like you!",
    answerString:
      "Now that is a question worthy of a beauty pageant contest! But seriously, Sentro was created to address the problems or issues of small business entrepreneurs, like you, here in the Philippines. Now that e-commerce has taken off in our country, we want to solve your concerns revolving around starting an online business, making your business digital, running your business day-to-day activities, increasing your reach and sales, making your business known locally and internationally, and penetrating overseas markets. Yes, it’s true, Sentro was created for business owners like you!"
  },
  {
    key: 1,
    question: "Who owns Sentro?",
    answer:
      "Sentro is owned by UBX Philippines Corporation (“UBX PH”). UBX PH operates several platforms aimed for use of individuals, institutions and businesses. UBX PH is registered with the Securities and Exchange Commission and has office address at 33/F UnionBank Plaza, Meralco Avenue corner Onyx and Sapphire Streets, Ortigas Center, Pasig City 1605, Philippines.",
    answerString:
      "Sentro is owned by UBX Philippines Corporation (“UBX PH”). UBX PH operates several platforms aimed for use of individuals, institutions and businesses. UBX PH is registered with the Securities and Exchange Commission and has office address at 33/F UnionBank Plaza, Meralco Avenue corner Onyx and Sapphire Streets, Ortigas Center, Pasig City 1605, Philippines."
  },
  {
    key: 1,
    question: "How much does it cost to have a Sentro account?",
    answer: (
      <div>
        <div style={{ fontSize: 14 }}>
          Right now, creation of an account in Sentro is absolutely free! Don’t
          worry, if ever that we charge any fee, you will know about it first.{" "}
          <Link
            onClick={() => {
              keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
            }}
            style={{ color: "#f97663", fontWeight: 600 }}
          >
            Sign up now
          </Link>{" "}
          to get started!
        </div>
      </div>
    ),
    answerString:
      "Right now, creation of an account in Sentro is absolutely free! Don’t worry, if ever that we charge any fee, you will know about it first. Sign up now to get started"
  },
  {
    key: 1,
    question: "What do I need to join Sentro?",
    answer:
      "Sentro works best with the latest version of Google Chrome with your laptop or desktop. In the future, Sentro may also be accessible via your mobile phone.",
    answerString:
      "Sentro works best with the latest version of Google Chrome with your laptop or desktop. In the future, Sentro may also be accessible via your mobile phone."
  },
  {
    key: 1,
    question: "Do I need to have an internet connection to access Sentro?",
    answer:
      "Yes, as Sentro is web-based, you need an internet connection to access it.",
    answerString:
      "Yes, as Sentro is web-based, you need an internet connection to access it."
  },
  {
    key: 2,
    question: "Who is the target market of Sentro?",
    answer: (
      <div>
        Sentro is ideal for small to medium enterprises who want to either
        jump-start, run or grow their business digitally. We made Sentro very
        user friendly. You don’t need to be a techie or an IT person to use it.
      </div>
    ),
    answerString:
      "Sentro is ideal for small to medium enterprises who want to either jump-start, run or grow their business digitally. We made Sentro very user friendly. You don’t need to be a techie or an IT person to use it."
  },
  {
    key: 3,
    question: "Who are Sentro Partners?",
    answer:
      "Sentro Partners are various local or international fintechs or third party service providers that can improve your business management. At present, we have the Monetary Authority of Singapore (MAS) and Infocomm Media Development, Microsoft through Crayon, UnionBank of the Philippines and HR Avatar, to name a few Sentro Partners. Check your Sentro account regularly to see new Sentro Partners as we keep adding them for you.",
    answerString:
      "Sentro Partners are various local or international fintechs or third party service providers that can improve your business management. At present, we have the Monetary Authority of Singapore (MAS) and Infocomm Media Development, Microsoft through Crayon, UnionBank of the Philippines and HR Avatar, to name a few Sentro Partners. Check your Sentro account regularly to see new Sentro Partners as we keep adding them for you."
  },
  {
    key: 4,
    question: "What are the features of Sentro Online Shop?",
    answer:
      "Sentro’s Online Shop allows you to post and advertise all your products and store details. It has its built-in payment feature (currently through Bux, a sister platform of Sentro) that allows you to immediately conduct online transactions with your clients. We will likewise be integrating with logistics partners so that the delivery of your products can likewise be done seamlessly from your online shop. Build yours today!",
    answerString:
      "Sentro’s Online Shop allows you to post and advertise all your products and store details. It has its built-in payment feature (currently through Bux, a sister platform of Sentro) that allows you to immediately conduct online transactions with your clients. We will likewise be integrating with logistics partners so that the delivery of your products can likewise be done seamlessly from your online shop. Build yours today!"
  },
  {
    key: 4,
    question: "Do I have to pay anything to create my Online Shop?",
    answer: (
      <div>
        <div style={{ fontSize: 14 }}>
          Currently, creating and running your Sentro Online Shop is FREE!
          Should we ever decide to charge you something for it, you will
          definitely know about it first!{" "}
        </div>
        <Link
          onClick={() => {
            keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
          }}
          style={{ color: "#f97663", fontWeight: 600 }}
        >
          Sign up now
        </Link>{" "}
        and let’s get started on your very own Online Shop!
      </div>
    ),
    answerString:
      "Currently, creating and running your Sentro Online Shop is FREE! Should we ever decide to charge you something for it, you will definitely know about it first! Sign up now and let’s get started on your very own Online Shop!"
  },
  {
    key: 4,
    question:
      "Do I need to know how to code or design to be able to create my online shop?",
    answer:
      "Anyone can build their online shop in Sentro. No coding or design background is needed. No need to hire a programmer to maintain your shop as well. Simply provide the details you want to be shown in your online shop, publish it, and you're set",
    answerString:
      "Anyone can build their online shop in Sentro. No coding or design background is needed. No need to hire a programmer to maintain your shop as well. Simply provide the details you want to be shown in your online shop, publish it, and you're set"
  },
  {
    key: 4,
    question: "I am not a techie, how can I create my Online Shop?",
    answer:
      "It’s okay! We know that 99% of folks do not how to code or design an online page. That is why Sentro is here to help your business exist not only through brick and mortar but in cyberspace as well. In Sentro’s Online Shop, simply provide the details you want to be shown in your Online Shop, publish it and you’re all set! It’s as easy as 1-2-3…",
    answerString:
      "It’s okay! We know that 99% of folks do not how to code or design an online page. That is why Sentro is here to help your business exist not only through brick and mortar but in cyberspace as well. In Sentro’s Online Shop, simply provide the details you want to be shown in your Online Shop, publish it and you’re all set! It’s as easy as 1-2-3…"
  },
  {
    key: 4,
    question:
      "Do I need to have my own web address (domain) first before I build my Online Shop in Sentro?",
    answer: (
      <div>
        No need. Once you have created your Online Shop in Sentro, it comes with
        your business’ unique web address (example: xyzshop.sentro.ph).
      </div>
    ),
    answerString:
      "No need. Once you have created your Online Shop in Sentro, it comes with your business’ unique web address (example: xyzshop.sentro.ph)."
  },
  {
    key: 4,
    question: "In which countries can I operate my Sentro Online Shop?",
    answer:
      "As your created Sentro Online Shop is in the web, it can be viewed and accessed anywhere in the world. However, at this time, its payment and future logistics functions can only provide services within the Philippines.",
    answerString:
      "As your created Sentro Online Shop is in the web, it can be viewed and accessed anywhere in the world. However, at this time, its payment and future logistics functions can only provide services within the Philippines."
  },
  {
    key: 4,
    question: "What products can I sell in my Sentro Online Shop?",
    answer: (
      <div>
        <div style={{ fontSize: 14 }}>
          The prohibited products and activities in Sentro are stated in our{" "}
          <Link
            onClick={() => {
              history.push(Path.TNC);
            }}
            style={{ color: "#f97663", fontWeight: 600 }}
          >
            Terms and Conditions
          </Link>{" "}
          which you can find.
        </div>
      </div>
    ),
    answerString:
      "The prohibited products and activities in Sentro are stated in our Terms and Conditions which you can find."
  },
  {
    key: 4,
    question:
      "Is there a limit as to the number of items I can upload in my Sentro Online Shop?",
    answer: (
      <div>
        <div style={{ fontSize: 14 }}>
          We’re happy to tell you that you can upload as many items as you want
          in your{" "}
          <Link
            onClick={() => {
              history.push(Path.DASHBOARD);
            }}
            style={{ color: "#f97663", fontWeight: 600 }}
          >
            Sentro Online Shop
          </Link>
          ! Let’s get you started then!
        </div>
      </div>
    ),
    answerString:
      "We’re happy to tell you that you can upload as many items as you want in your Sentro Online Shop! Let’s get you started then!"
  },
  {
    key: 4,
    question: "Can I edit or update my Sentro Online Shop after I publish it?",
    answer:
      "Oh yes! You can edit and update your Sentro Online Shop any time and as often as you wish.",
    answerString:
      "Oh yes! You can edit and update your Sentro Online Shop any time and as often as you wish."
  },
  {
    key: 4,
    question: "Can I create a 2nd online shop in Sentro?",
    answer:
      "We are continuously working to add more features like multi-shops, so stay tuned!",
    answerString:
      "We are continuously working to add more features like multi-shops, so stay tuned!"
  },
  {
    key: 4,
    question: "Can I delete my Sentro Online Shop?",
    answer:
      "Yes, of course. The power to create carries the power to delete. But once you have deleted it, you cannot recover it anymore. You have to create a new one all over again.",
    answerString:
      "Yes, of course. The power to create carries the power to delete. But once you have deleted it, you cannot recover it anymore. You have to create a new one all over again."
  },
  {
    key: 4,
    question: "How do I change my Online Shop's Color Pattern?",
    answer:
      "To change your Online Shop's Color Pattern, click on the Edit button in your previously created Online Shop, then proceed to the Branding step and choose your preferred color pattern under Color.",
    answerString:
      "To change your Online Shop's Color Pattern, click on the Edit button in your previously created Online Shop, then proceed to the Branding step and choose your preferred color pattern under Color."
  },
  {
    key: 4,
    question: "How do I change my Product's Stock Quantity?",
    answer:
      "To change or update your Product's stock quantity, click on the Edit button in your Online Shop and proceed to the Products step, then click on the Edit button of a specific product, and edit the value under Stocks on hand. If you always have stocks on hand, you can simply toggle the Always available button so that you don't need to keep changing your stock quantity.",
    answerString:
      "To change or update your Product's stock quantity, click on the Edit button in your Online Shop and proceed to the Products step, then click on the Edit button of a specific product, and edit the value under Stocks on hand. If you always have stocks on hand, you can simply toggle the Always available button so that you don't need to keep changing your stock quantity."
  },
  {
    key: 4,
    question: "Do you have a short tutorial video on how to use Sentro?",
    answer: (
      <div>
        <div style={{ fontSize: 14 }}>
          To learn how to log in to Sentro and Create your own Online Shop,{" "}
          <Link
            onClick={() => {
              window.open(
                "https://www.facebook.com/SentroofAttention/videos/538353023513620/"
              );
            }}
            style={{ color: "#f97663", fontWeight: 600 }}
          >
            Click here
          </Link>
        </div>
      </div>
    ),
    answerString:
      "To learn how to log in to Sentro and Create your own Online Shop, click here"
  },
  {
    key: 4,
    question: "Can I add more photos to my products?",
    answer:
      "Yes, you can! Click the Edit Online Shop button then proceed to the products step. Choose which product you wish to edit and begin uploading different pictures of your product. You can upload up to 5 photos per product.",
    answerString:
      "Yes, you can! Click the Edit Online Shop button then proceed to the products step. Choose which product you wish to edit and begin uploading different pictures of your product. You can upload up to 5 photos per product."
  },
  {
    key: 5,
    question:
      "If my Sentro Online Shop has buyers, how can they pay for their purchases?",
    answer: (
      <div>
        <div style={{ fontSize: 14 }}>
          Your Sentro Online Shop has an integrated payment feature. At present,
          it can accept cash, bank transfers, and credit card and debit card
          payments via Bux. To know more about Bux, click{" "}
        </div>
        <Link
          href="https://bux.ph/faq"
          target="_blank"
          style={{ color: "#f97663", fontWeight: 600 }}
        >
          here
        </Link>
      </div>
    ),
    answerString:
      "Your Sentro Online Shop has an integrated payment feature. At present, it can accept cash payments via Bux. But in the very near future, your Sentro Online Shop can accept debit or credit card payments as well. To know more about Bux, click here"
  },
  {
    key: 5,
    question: "Are the payment and logistics gateway also for free?",
    answer:
      "There are no set-up fees to have a payment and logistics gateway in your Sentro Online Shop; however, there are fees when these gateways are used. You have to pay a certain amount for every transaction that you make using the payment or logistics gateway. You shall see the amount prior to confirmation of the transaction.",
    answerString:
      "There are no set-up fees to have a payment and logistics gateway in your Sentro Online Shop; however, there are fees when these gateways are used. You have to pay a certain amount for every transaction that you make using the payment or logistics gateway. You shall see the amount prior to confirmation of the transaction."
  },
  {
    key: 5,
    question: "Where will my buyers’ payment go?",
    answer:
      "All payments made in your Sentro Online Shop, whether bank transfer, credit card or debit card, or cash, will be credited to your Bux account. You can view the balance of your account by going to the Sales and Payments menu in your Sentro account.",
    answerString:
      "All payments made in your Sentro Online Shop, whether bank transfer, credit card or debit card, or cash, will be credited to your Bux account. You can view the balance of your account by going to the Sales and Payments menu in your Sentro account."
  },
  {
    key: 5,
    question:
      "How can I withdraw the money that has been paid to me from my Sentro Online Shop sales?",
    answer:
      "You can withdraw the funds from your Bux account and have it transferred to your own bank account. Your bank account has to be with a local bank in the Philippines.",
    answerString:
      "You can withdraw the funds from your Bux account and have it transferred to your own bank account. Your bank account has to be with a local bank in the Philippines."
  },
  {
    key: 6,
    question: "How do I ship my orders?",
    answer: `Go to your Orders and Deliveries Dashboard found on the left side of the screen on Desktop or on the bottom right-hand corner of your Dashboard on Mobile. For Desktop, click on the three dots on your specific order then click on "Request for Delivery". For Mobile, on the specific order you wish to book for delivery and tap "Book for delivery". Then proceed to fill out the necessary details to complete your delivery request. Once you're done filling out the necessary details, click on "Book Delivery" and wait for the rider to arrive.`,
    answerString: `Go to your Orders and Deliveries Dashboard found on the left side of the screen on Desktop or on the bottom right-hand corner of your Dashboard on Mobile. For Desktop, click on the three dots on your specific order then click on "Request for Delivery". For Mobile, on the specific order you wish to book for delivery and tap "Book for delivery". Then proceed to fill out the necessary details to complete your delivery request. Once you're done filling out the necessary details, click on "Book Delivery" and wait for the rider to arrive.`
  },
  {
    key: 6,
    question: "How can I see a list of all my orders?",
    answer: `Go to your Orders and Deliveries Dashboard found on the left side of the screen on Desktop or on the bottom right-hand corner of your Dashboard on Mobile. You will then be redirected to your Orders and Deliveries dashboard where you will see a list of all your orders and the status of each order.`,
    answerString: `Go to your Orders and Deliveries Dashboard found on the left side of the screen on Desktop or on the bottom right-hand corner of your Dashboard on Mobile. You will then be redirected to your Orders and Deliveries dashboard where you will see a list of all your orders and the status of each order.`
  },
  {
    key: 6,
    question: "Who is your partnered logistics provider?",
    answer:
      "Sentro has partnered with Mr. Speedy so you can instantly ship the orders of your customers. Soon, we'll have more logistics partners so you have the option to choose.",
    answerString:
      "Sentro has partnered with Mr. Speedy so you can instantly ship the orders of your customers. Soon, we'll have more logistics partners so you have the option to choose."
  },
  {
    key: 6,
    question: "Do I need to create a Mr. Speedy account to book my deliveries?",
    answer:
      "You don't need to create your own Mr Speedy account to book your deliveries as long as you book them within the Sentro site.",
    answerString:
      "You don't need to create your own Mr Speedy account to book your deliveries as long as you book them within the Sentro site."
  },
  {
    key: 6,
    question: "Where can I see my Order's delivery status?",
    answer:
      "(For Desktop) On your Sentro Dashboard you will see your Orders and Deliveries tab on the left side of your browser. (For Mobile) On the top-right part of your screen, you will see beside your name a button with three lines on top of each other. Tap on that to see your Orders and Deliveries tab.",
    answerString:
      "(For Desktop) On your Sentro Dashboard you will see your Orders and Deliveries tab on the left side of your browser. (For Mobile) On the top-right part of your screen, you will see beside your name a button with three lines on top of each other. Tap on that to see your Orders and Deliveries tab."
  },
  {
    key: 7,
    question: "What is the Apps and Services Marketplace?",
    answer:
      "The Apps and Services Marketplace, is where you can avail of various apps and tools from our partners that can help you with running your business.",
    answerString:
      "The Apps and Services Marketplace, is where you can avail of various apps and tools from our partners that can help you with running your business."
  },
  {
    key: 7,
    question: "How can I buy services from the Apps and Services Marketplace?",
    answer:
      "Click on the Apps and Services Marketplace tab located on the left side of your Sentro Dashboard, choose among the available brands, select a product, proceed to checkout, and then fulfill payment via Bux.",
    answerString:
      "Click on the Apps and Services Marketplace tab located on the left side of your Sentro Dashboard, choose among the available brands, select a product, proceed to checkout, and then fulfill payment via Bux."
  },
  {
    key: 7,
    question:
      "I did not receive the service I purchased from the Apps and Services Marketplace",
    answer:
      "To report an issue with your Apps and Services Marketplace purchase, kindly send an email to support@sentro.ph for further assistance.",
    answerString:
      "To report an issue with your Apps and Services Marketplace purchase, kindly send an email to support@sentro.ph for further assistance."
  },
  {
    key: 7,
    question: "I am unable to pay using Bux",
    answer:
      "For Bux related concerns, kindly send an email to support+bux@ubx.ph so that they may assist you with your query.",
    answerString:
      "For Bux related concerns, kindly send an email to support+bux@ubx.ph so that they may assist you with your query."
  },
  {
    key: 8,
    question: "How can I contact Sentro for comments, issues or suggestions?",
    answer:
      "You can contact us through email at: support@sentro.ph. We reply during Mondays to Fridays, except holidays, from 9:00am to 5:00pm, Philippine Standard Time. Oh yes, we’d also love to hear your experience and success stories with Sentro through the same channel.",
    answerString:
      "You can contact us through email at: support@sentro.ph. We reply during Mondays to Fridays, except holidays, from 9:00am to 5:00pm, Philippine Standard Time. Oh yes, we’d also love to hear your experience and success stories with Sentro through the same channel."
  },
  {
    key: 9,
    question: "How to add Facebook Messenger to my Sentro Online Shop?",
    answer: `On your Sentro dashboard, look for the "Chat to your clients on your shop" and click on "Learn More". Simply follow the steps provided and click submit when you're done.`,
    answerString: `On your Sentro dashboard, look for the "Chat to your clients on your shop" and click on "Learn More". Simply follow the steps provided and click submit when you're done.`
  },
  {
    key: 10,
    question: "Where can I see all of Sentro's emails, announcements?",
    answer: `In your Sentro dashboard, click on your name on the top right-hand corner and choose "Announcements" in the dropdown. You will be redirected to a page with all of Sentro's current and previous emails on features, issues, and other announcements. `,
    answerString: `In your Sentro dashboard, click on your name on the top right-hand corner and choose "Announcements" in the dropdown. You will be redirected to a page with all of Sentro's current and previous emails on features, issues, and other announcements. `
  }
];
export default FAQ;
