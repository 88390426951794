import React from "react";
import {
  Button,
  Popover,
  MenuItem,
  Modal,
  useMediaQuery,
  Dialog,
  DialogContent,
  Drawer,
  Slide,
  Snackbar,
  ListItem,
  ListItemIcon,
  Chip
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Card, CardHeader, CardMedia, IconButton } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import ShareIcon from "@material-ui/icons/Share";
import { MoreHoriz, Close } from "@material-ui/icons/";
import { useSelector, useDispatch } from "react-redux";
import MobileThumbnail from "./mobileThumbnail";
import history from "../../../../store/history";
import Path from "../../../../Paths";
import ErrorIcon from "@material-ui/icons/Error";
import {
  getProducts,
  getStore,
  getStoreProducts,
  deleteStore,
  getPaymentMethods
} from "./webBuilder/websiteBuilderActions";
import CreateMobileWebCard from "./webBuilder/createMobileWebCard";
import SentroLoading from "../../loading";
import ShopDetailsForm from "./webBuilder/forms/shopDetailsForm";
import CustomizeShopStyleForm from "./webBuilder/forms/customizeShopStyleForm";
import MobileShopTools from "./webBuilder/MobileShopTools";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CloseIcon from "@material-ui/icons/Close";
import MobileAddProducts from "./webBuilder/forms/MobileAddProducts";
import AlertDialogBox from "./webBuilder/common/AlertDialogBox";
import DeleteShopModal from "./webBuilder/common/DeleteShopModal";
import PreviewHome from "../../template/components/PreviewHome";
import PreviewProducts from "../../template/components/PreviewProducts";
import apiConfig from "../../../../common/apiConfig";
import facebookIcon from "../../../../assets/images/facebook.svg";
import viberIcon from "../../../../assets/images/viber.svg";
import whatsappIcon from "../../../../assets/images/whatsapp.svg";
import twitterIcon from "../../../../assets/images/twitter.svg";
import copyIcon from "../../../../assets/images/copyButton.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import paymentLogo from "../../../../assets/images/Logomark.png";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import {
  FacebookShareButton,
  ViberShareButton,
  WhatsappShareButton,
  TwitterShareButton
} from "react-share";
import TNCNotice from "../../../dashboard/tncNotice";
import KYCQuestionsMobile from "./webBuilder/common/kycSurveyMobile";
import Payments from "./mobilePayments/payments";
import ActivateAccountBanner from "../../../dashboard/common/activateAccountBanner";
import ManageDeliveryBanner from "../../../dashboard/common/manageDeliveryBanner";
import TemplateLibrary from "./webBuilder/forms/templateLibrary";
import PreviewShop from "../../../websiteBuilder/desktop/forms/previewShop";
import DashboardCard from "../../../dashboard/webCard";
import DashboardAds from "../../../dashboard/dashboardAds";
import Banner from "../../../subscription/components/dashboard/banner";
import Extended from "../../../subscription/components/dashboard/extended";
import BuxUpgrade from "../../../subscription/components/dashboard/buxUpgrade";
const DialogTransition = props => <Slide direction="up" {...props} />;
let BlueWarning = props => {
  const { hide, setHide, setOpenAddProduct, language } = props;
  return (
    <div
      style={{
        backgroundColor: "#1E4B83",
        color: "white",
        marginTop: "10vh",
        padding: "10px 20px"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div style={{ fontSize: 13, fontWeight: "bold" }}>
          {language === "English"
            ? "Add products and start selling!"
            : "Lagyan na ng mga produkto at simulan na ang pagbebenta!"}
        </div>
        <IconButton onClick={() => setHide(true)}>
          <CloseIcon style={{ color: "#fff", fontSize: 14 }} />
        </IconButton>
      </div>
      <div style={{ fontSize: 12, fontWeight: "normal" }}>
        {language === "English"
          ? "Your shop is almost ready to open! Upload your products so you can start selling."
          : "Onti nalang at magbubukas na ang online shop mo! I-upload mo na ang mga produkto mo at simulan na ang pagbebenta!"}
      </div>
      <Button
        onClick={() => history.push(Path.PRODUCTS_ADD)}
        fullWidth
        type="contained"
        style={{ backgroundColor: "white", color: "#1E4B83", marginTop: "2vh" }}
      >
        {language === "English" ? "Add Product" : "Magdagdag ng produkto"}
      </Button>
    </div>
  );
};

let PreviewWindow = props => {
  const useStyles = makeStyles(theme => ({
    fullCard: {
      height: "100vh",
      width: "100vw",
      overflow: "auto"
    },
    title: {
      margin: "auto",
      paddingTop: "29px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      textAlign: "center",
      color: "#002C42",
      position: "relative"
    },
    closedBtn: {
      position: "absolute",
      top: "20px",
      left: "10px"
    }
  }));
  const classes = useStyles();
  const [onClick, setOnClick] = React.useState("Home");

  function renderPreview() {
    if (onClick === "Home") {
      return (
        <PreviewHome
          setOnClick={setOnClick}
          style={{ overflowY: "auto" }}
          storeInfo={props.storeInfo}
          isPreview={true}
          productList={props.productList}
          // setValues={setValues}
          // setImageSettings={setImageSettings}
        />
      );
    } else if (onClick === "Products") {
      return (
        <PreviewProducts
          setOnClick={setOnClick}
          style={{ overflowY: "auto" }}
          storeInfo={props.storeInfo}
          productList={props.productList}
        />
      );
    }
  }
  return (
    <Card className={classes.fullCard}>
      <CardHeader
        className={classes.title}
        title="Preview"
        action={
          <IconButton
            className={classes.closedBtn}
            aria-label="Share"
            onClick={props.handleClose}
          >
            <Close />
          </IconButton>
        }
      ></CardHeader>
      <div>
        {/* <MobileThumbnail
          storeInfo={props.storeInfo}
          productList={props.productList}
        /> */}

        {renderPreview()}
      </div>
    </Card>
  );
};

let WebPreviewCard = props => {
  const {
    stores,
    values,
    setValues,
    hide,
    setHide,
    data,
    setOpenAddProduct,
    storeInfo,
    language
  } = props;
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      position: "relative",
      marginTop: "15px",
      borderRadius: "8px"
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },

    thumbnail: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        height: "480px"
      },
      [theme.breakpoints.down("xs")]: {
        height: "240px"
      }
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatar: {
      backgroundColor: red[500]
    },
    name: {
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42"
    },
    site: {
      fontSize: 14,
      color: "#54575f"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content"
    },
    header: {
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 2em"
      },
      [theme.breakpoints.down("xs")]: {
        padding: 20
      }
    },
    previewPadding: {
      [theme.breakpoints.down("sm")]: {
        padding: "0 2em"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0 15px 20px"
      }
    },
    chip: {
      backgroundColor: "#fcf7e1",
      position: "absolute",
      zIndex: 1,
      top: 60,
      left: 10
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const domRef = React.createRef();

  let loading = useSelector(state => state.webBuilder.loading);
  let storeProductList = useSelector(state => state.webBuilder.products);
  let activeStep = useSelector(state => state.webBuilder.step);
  let isEdit = useSelector(state => state.webBuilder.isEdit);
  let products = useSelector(state => state.mobileDashboard.products.products);
  let currentStoreCreated = useSelector(state => state.webBuilder.storeInfo);
  let alertMessage = useSelector(state => state.mobileDashboard.message);
  let dialogOpen = useSelector(state => state.mobileDashboard.dialogOpen);
  let [missingPhotoFields, setMissingPhotoFields] = React.useState([]);
  React.useEffect(() => {
    setValues({ ...props.storeInfo });
  }, []);

  // React.useState(async () => {
  //   const res = await dispatch(getStores());
  //   if (res.length) history.push(Path.MOBILE_WEB_BUILDER);
  // }, []);

  // SubMenu Functionality
  const handlePopoverClick = event => {
    setValues({ ...values, ["submenu_open"]: event.currentTarget });
  };
  const handlePopoverClose = () => {
    setValues({ ...values, ["submenu_open"]: null });
  };
  const openPopover = Boolean(values.submenu_open);

  // Preview Modal On
  const handlePreviewOpen = event => {
    setValues({ ...values, preview_open: true, submenu_open: null });
  };
  const handlePreviewClose = event => {
    setValues({ ...values, preview_open: null, submenu_open: null });
  };

  // Delete Modal
  const handleDeleteOpen = event => {
    setValues({ ...values, delete_modal_open: true, submenu_open: null });
  };
  const handleDeleteClose = event => {
    setValues({ ...values, delete_modal_open: false, submenu_open: null });
  };
  const handleDeleteShop = item => {
    setValues({ ...values, delete_modal_open: false, submenu_open: null });
    dispatch(deleteStore(storeInfo));
  };

  // Shop Details Form
  const setShopDetailsFormModal = open => {
    setValues({ ...values, shop_details_form_open: open, submenu_open: false });
  };
  // Customize Shop Style Form
  const setCustomizeShopStyleFormModal = open => {
    setValues({
      ...values,
      customize_shop_style_form_open: open,
      submenu_open: false
    });
  };
  const setOpenShareDrawer = open => {
    setValues({ ...values, share_drawerbox: open });
  };

  React.useEffect(() => {
    missingPhotoFields.length = 0;
    if (stores.length) {
      let store = stores ? stores[0] : "";
      let array = [
        store.logo_image,
        store.cover_image,
        store.aboutus_image,
        store.contactus_image
      ];
      for (let i in array) {
        if (array[i] === null) {
          missingPhotoFields.push(array[i]);
        }
      }
    }
  }, [stores]);

  return (
    <div>
      {hide ? (
        ""
      ) : (
        <BlueWarning
          hide={hide}
          setHide={setHide}
          setOpenAddProduct={setOpenAddProduct}
          language={props.language}
        />
      )}
      <div className={classes.previewPadding}>
        <div></div>
        <Card className={classes.root}>
          <CardMedia className={classes.thumbnail}>
            <Chip
              size="small"
              onClick={() => setCustomizeShopStyleFormModal(true)}
              label={missingPhotoFields.length + " missing photo"}
              icon={<ErrorIcon style={{ color: "#edc143" }} />}
              className={classes.chip}
              style={{ display: missingPhotoFields.length === 0 ? "none" : "" }}
            />
            <MobileThumbnail
              storeInfo={props.storeInfo}
              productList={props.productList}
              type="small"
            />
          </CardMedia>
          <div>
            <div style={{ padding: "10px 20px" }}>
              <div>
                <div className={classes.name}>
                  {props.storeInfo ? props.storeInfo.name : "Store Name"}
                </div>
                <div className={classes.site}>
                  {props.storeInfo ? props.storeInfo.site : "Store Site"}
                  .sentro.ph
                </div>
              </div>
            </div>
            <div style={{ padding: "0 5px" }}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <IconButton onClick={handlePopoverClick}>
                  <MoreHorizIcon color="primary" />
                </IconButton>
                <Popover
                  open={values.submenu_open}
                  anchorEl={values.submenu_open}
                  container={domRef.current}
                  onClose={handlePopoverClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <MenuItem
                    style={{ width: 170, fontSize: 12 }}
                    id="view"
                    onClick={() =>
                      window.open(
                        `${apiConfig.localHost}shop/${
                          props.storeInfo ? props.storeInfo.site : ""
                        }`
                      )
                    }
                  >
                    View (opens in new tab)
                  </MenuItem>
                  <MenuItem
                    onClick={handleDeleteOpen}
                    style={{ width: 170, fontSize: 12 }}
                    id="delete_modal"
                  >
                    Delete
                  </MenuItem>
                </Popover>
                <IconButton onClick={() => setOpenShareDrawer(true)}>
                  <ShareIcon color="primary" />
                </IconButton>
                <Button
                  onClick={() => setShopDetailsFormModal(true)}
                  variant="text"
                  color="primary"
                >
                  Edit Details
                </Button>
                <Button
                  id="style_shop"
                  onClick={() => setCustomizeShopStyleFormModal(true)}
                  variant="text"
                  color="primary"
                >
                  Style Shop
                </Button>
              </div>
            </div>
          </div>
        </Card>
        <Modal
          open={values.preview_open}
          onClose={handlePreviewClose}
          aria-labelledby="Preview"
          aria-describedby="Preview your mobile website"
        >
          <PreviewWindow
            handleClose={handlePreviewClose}
            storeInfo={props.values}
            productList={products}
          />
        </Modal>

        <Dialog open={values.delete_modal_open} fullWidth maxWidth="xs">
          <DialogContent style={{ padding: 20 }}>
            <div className={classes.form}>
              <div
                style={{ fontSize: 20, color: "#002c42", fontWeight: "bold" }}
              >
                {language === "English"
                  ? "Are you sure you want to delete your online shop?"
                  : "Sigurado ka bang gusto mong burahin ang iyong online shop?"}
              </div>
              <div style={{ marginTop: 10, fontSize: 14 }}>
                {language === "English"
                  ? "Once deleted, you will not be able to restore your shop"
                  : "Kapag na-delete na ang iyong online shop, hindi na ito pwedeng ma-restore"}
              </div>
              <div
                style={{
                  marginTop: "4vh",
                  display: "block"
                }}
              >
                <Button
                  id="cancel"
                  onClick={handleDeleteClose}
                  style={{ height: 40 }}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  No, do not delete
                </Button>
                <Button
                  id="delete"
                  onClick={handleDeleteShop}
                  style={{ marginTop: "1vh", height: 40 }}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Yes, delete my site
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <AlertDialogBox
          open={dialogOpen}
          alertType={alertMessage ? alertMessage.type : "success"}
          message={alertMessage ? alertMessage.message : ""}
        />
      </div>
    </div>
  );
};

let ShareDrawer = props => {
  const { values, setValues, store } = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const useStyles = makeStyles(theme => ({
    snackBar: {
      backgroundColor: "#fff",
      color: "#F97663",
      width: 50
    },
    header: {
      textAlign: "center",
      width: "100%",
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42"
    }
  }));
  const classes = useStyles();
  function AlertBox() {
    return (
      <Snackbar
        style={{ top: 70 }}
        ContentProps={{
          classes: {
            root: classes.snackBar
          }
        }}
        TransitionComponent={DialogTransition}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openDialog}
        autoHideDuration={3000}
        onClose={() => setOpenDialog(false)}
        message="Copied to clipboard!"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenDialog(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  }
  return (
    <Drawer anchor="bottom" open={values.share_drawerbox}>
      <AlertBox />
      <div>
        <div
          style={{ display: "flex", alignItems: "center", padding: "20px 0" }}
        >
          <IconButton
            style={{ position: "absolute" }}
            onClick={() => setValues({ ...values, share_drawerbox: false })}
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.header}>Share</div>
        </div>
        <div style={{ padding: 20, display: "flex", justifyContent: "center" }}>
          <div>
            <FacebookShareButton
              url={`${apiConfig.localHost}shop/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img src={facebookIcon} width="40" height="40" alt="facebook" />
              </IconButton>
            </FacebookShareButton>
          </div>

          <div>
            <WhatsappShareButton
              url={`${apiConfig.localHost}shop/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img src={whatsappIcon} width="40" height="40" alt="whatsapp" />
              </IconButton>
            </WhatsappShareButton>
          </div>
          <div>
            <TwitterShareButton
              url={`${apiConfig.localHost}shop/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img src={twitterIcon} width="40" height="40" alt="twitter" />
              </IconButton>
            </TwitterShareButton>
          </div>
          <div>
            <ViberShareButton
              url={`${apiConfig.localHost}shop/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img src={viberIcon} width="40" height="40" alt="viber" />
              </IconButton>
            </ViberShareButton>
          </div>
          <div>
            <CopyToClipboard
              onCopy={() => setOpenDialog(true)}
              text={`${apiConfig.localHost}shop/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img
                  src={copyIcon}
                  width="40"
                  height="40"
                  alt="copy-to-clipboard"
                />
              </IconButton>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

let SettingsSection = props => {
  const { setOpen, store_uuid } = props;
  const dispatch = useDispatch();
  let currentStoreCreated = useSelector(
    state => state.mobileDashboard.storeInfo
  );
  const useStyles = makeStyles(theme => ({
    listBtn: {
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        padding: "2em"
      },
      [theme.breakpoints.down("xs")]: {
        padding: 16
      }
    },
    listTitle: {
      color: "#002c42",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 14
      }
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        width: 40,
        height: 40
      },
      [theme.breakpoints.down("xs")]: {
        width: 30,
        height: 30
      }
    }
  }));
  const classes = useStyles();

  const settings = [
    {
      icon: <img src={paymentLogo} className={classes.logo} />,
      header: "Payment Settings",
      key: "payment"
    },
    {
      icon: <LocalShippingIcon style={{ color: "#2a3d85", fontSize: 36 }} />,
      header: "Delivery Settings",
      key: "delivery"
    }
  ];

  function handleOnClick(key) {
    switch (key) {
      case "payment":
        setOpen(true);
        dispatch(getPaymentMethods(store_uuid));
        break;
      case "delivery":
        history.push(Path.DELIVERY_SETTINGS);
        break;
    }
  }
  return (
    <div style={{ margin: "2vh 0" }}>
      {settings.map(item => {
        return (
          <div style={{ borderBottom: "1px solid #d1d5dd" }}>
            <ListItem
              disabled={currentStoreCreated ? false : true}
              onClick={() => handleOnClick(item.key)}
              button
              className={classes.listBtn}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <div>
                  <div className={classes.listTitle}>{item.header}</div>
                </div>
              </div>
              <div>
                <ArrowForward style={{ color: "#909196", fontSize: 14 }} />
              </div>
            </ListItem>
          </div>
        );
      })}
    </div>
  );
};

let WebBuilder = props => {
  const filters = useSelector(state => state.mobileDashboard.products.filters);
  React.useEffect(() => {
    let params = {
      ...filters,
      sortBy: "created_on"
    };
    async function fetchStore() {
      let res = await dispatch(getStoreProducts(params));
      if (res && !res.length) {
        history.push(Path.SHOP_NEW_CREATE);
      }
    }
    fetchStore();
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {
      overflowY: "scroll",
      backgroundColor: "rgb(250, 250, 250)",
      [theme.breakpoints.down("sm")]: {
        height: "93vh",
        paddingTop: "8vh"
      },
      [theme.breakpoints.down("xs")]: {
        height: "89vh",
        paddingTop: "10vh"
      }
    }
  }));
  const classes = useStyles();
  let currentStoreCreated = useSelector(
    state => state.mobileDashboard.storeInfo
  );
  let currentStoreProducts = useSelector(
    state => state.mobileDashboard.products.products
  );
  localStorage.setItem(
    "STORE_UUID",
    currentStoreCreated ? currentStoreCreated.uuid : ""
  );
  let dispatch = useDispatch();
  let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let STORE_UUID = localStorage.getItem("STORE_UUID");
  let loading = useSelector(state => state.mobileDashboard.loading);
  let sites = useSelector(state => state.mobileDashboard.stores);
  let deleteShop = useSelector(state => state.mobileDashboard.deleteModal);
  let theme = useTheme();
  let desktopView = useMediaQuery(theme.breakpoints.up("md"));
  const [hide, setHide] = React.useState(true);
  const [openAddProduct, setOpenAddProduct] = React.useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);

  const [values, setValues] = React.useState({
    owner: data.id,
    name: "",
    industry: "",
    template: "Classic",
    description: "",
    slogan: "",
    site: "",
    address: "",
    email: "",
    mobile: "",
    landline: "",
    facebook: "",
    instagram: "",
    shopee: "",
    lazada: "",
    kumu: "",
    color: {},
    font: {},
    logo_image: "",
    cover_image: "",
    aboutus_image: "",
    contactus_image: "",
    logo_original_image: "",
    cover_original_image: "",
    aboutus_original_image: "",
    contactus_original_image: "",
    payment_deadline: 24,
    is_published: false,
    uuid: STORE_UUID,
    image_settings: {},
    image_crop_area: {},
    fb_page_id: "",
    submenu_open: false,
    preview_open: false,
    shop_details_form_open: false,
    customize_shop_style_form_open: false,
    delete_modal_open: false,
    share_drawerbox: false,
    template_library_open: false
  });
  const [product, setProduct] = React.useState({
    store: STORE_UUID,
    name: "",
    description: "",
    category: "",
    featured: true,
    is_active: true,
    always_available: true,
    quantity: 5,
    images: "",
    productImages: [],
    photo_primary: "0",
    product_code: ""
  });

  function onClose() {
    setOpenAddProduct(false);
  }

  React.useEffect(() => {
    if (loading) {
      setHide(true);
    } else if (
      currentStoreProducts &&
      (currentStoreProducts.active_count > 0 ||
        currentStoreProducts.drafts_count > 0 ||
        currentStoreProducts.deleted_count > 0)
    ) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [currentStoreProducts, loading]);
  React.useEffect(() => {
    if (desktopView) {
      history.push(Path.DASHBOARD);
    }
  }, [desktopView]);

  const has_pickup_address = sites.some(shop =>
    shop.pickup_address ? shop.pickup_address.length : false
  );

  return (
    <div className={classes.root}>
      <div>
        <SentroLoading open={loading} />
        <BuxUpgrade />
        <Extended />
        <Banner />
        {!data.is_verified && (
          <div className="my-1">
            <ActivateAccountBanner />
          </div>
        )}
        {/* {!has_pickup_address && sites.length ? <ManageDeliveryBanner /> : ""} */}
        {sites.length > 0 ? (
          sites.map((item, key) => {
            return (
              <>
                <DashboardCard
                  stores={sites}
                  values={values}
                  setValues={setValues}
                />
                {/* <WebPreviewCard
                  stores={sites}
                  values={values}
                  setValues={setValues}
                  storeInfo={item}
                  hide={hide}
                  setHide={setHide}
                  data={data}
                  setOpenAddProduct={setOpenAddProduct}
                  language={props.language}
                /> */}
                <ShopDetailsForm
                  values={values}
                  setValues={setValues}
                  storeInfo={item}
                  language={props.language}
                />
                <CustomizeShopStyleForm
                  values={values}
                  setValues={setValues}
                  storeInfo={item}
                  productList={currentStoreProducts}
                  language={props.language}
                />
                <MobileAddProducts
                  open={openAddProduct}
                  setOpen={setOpenAddProduct}
                  onClose={onClose}
                  product={product}
                  setProduct={setProduct}
                  storeInfo={item}
                />
                <TemplateLibrary
                  values={values}
                  setValues={setValues}
                  storeInfo={item}
                />
              </>
            );
          })
        ) : (
          <CreateMobileWebCard
            values={values}
            setValues={setValues}
            data={data}
            language={props.language}
          />
        )}
      </div>
      <div>
        <ShareDrawer
          values={values}
          setValues={setValues}
          store={currentStoreCreated}
        />

        {!data.is_verified || !sites.length ? (
          ""
        ) : (
          <div>
            <div className="p-3">
              <DashboardAds />
            </div>
            <MobileShopTools data={data} language={props.language} />
          </div>
        )}

        <Payments
          open={openPayment}
          setOpen={setOpenPayment}
          store_uuid={STORE_UUID}
        />
        <DeleteShopModal open={deleteShop} />
        <KYCQuestionsMobile />
        <TNCNotice />
      </div>
    </div>
  );
};

export default WebBuilder;
