import axios from "axios";
import apiConfig from "../../../../common/apiConfig";
import history from "../../../../store/history";

class Services {
  static async createWishlist(marketplace, data) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/${marketplace}/registries/`,
        data,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async patchWishlist(marketplace, data) {
    try {
      let response;
      const cover_photo = data.cover_photo;
      if (!cover_photo){
        response = await axios.patch(
          `${apiConfig.apiURL}/api/enterprise/${marketplace}/registries/${data.id}/`,
          data,
          {
            headers: {
              Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
            }
          }
        );
      } else {
        let formData = new FormData();
        formData.append("cover_photo", data.cover_photo);
        response = await axios.patch(
          `${apiConfig.apiURL}/api/enterprise/${marketplace}/registries/${data.id}/`,
          formData,
          {
            headers: {
              Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
            }
          }
        );
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPublicWishlists(marketplace, params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${marketplace}/registries/`,
        { params }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getWishlists(marketplace, filter={}) {
    const conditional_param = filter.dashboard_view ? 
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }: {}
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${marketplace}/registries/`,
        { 
          ...conditional_param,
          params: filter
        },
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getWishlistItems(marketplace, registry_id, filters) {
    const conditional_param = filters.dashboard_view ? 
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }: {}
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${marketplace}/registries/${registry_id}/products/`,
        { 
          ...conditional_param,
          params: filters
        },
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async deleteWishlistItem(data) {
    try {
      let response = await axios.delete(
        `${apiConfig.apiURL}/api/enterprise/${data.marketplace}/registries/${data.registry_id}/products/${data.registry_item_id}/`,
        { 
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          },
        },
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async addWishlistItem(data) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/${data.marketplace}/registries/${data.registry_id}/products/`,
        data,
        { 
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }
}
export default Services;
