import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ACL from "../../../common/utilities/ACL";
import AdminDashboardLayout from "../../layout";
import Actions from "../../Redux/Actions";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BasicTable, { stableSort, getComparator}  from '../../components/unPaginatedTable';
import moment from 'moment';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    padding: "2.0em"
  },
  title: {
    color: "#002C42",
    fontWeight: "bold",
    fontSize: 28,
  },
  card: {
    border: "1px solid #E6EAF0",
    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
    margin: "1.5em",
    backgroundColor: "white",
    padding: "2.0em",
    [theme.breakpoints.down("sm")]: {
      margin: "1em"
    }
  },
  cardHeader: {
    fontSize: "28px",
    marginBottom: "0.5em",
    textAlign: "left",
    color: "#F97663",
    fontWeight: 600
  },
  textDivider: {
    fontSize: "18px",
    padding: "1.5em 0",
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    "& > span": {
      marginRight: "1.5em"
    },
    "& > hr": {
      marginTop: 5,
      flexGrow: 1,
      paddingLeft: "3em",
      borderTop: "1px solid rgba(84, 87, 95, 0.75)",
      backgroundColor: "none"
    }
  },
  pageHeaderButton: {
    height: 35,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginTop: "1em",
      flex: 1
    },
    marginRight: 20
  },
  searchBar: {
    height: 35,
    background: "white",
    maxWidth: "240px",
    borderRadius: "4px",
    color: "gray",
    fontSize: 12,
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      marginTop: "1em",
      maxWidth: "100%",
      width: "100%"
    },
  },
  table: {
    "& th": {
      background: "#F97663",
      color: "white !important",
      fontSize: 14,
      // fontWeight: "bold",
      padding: 10,
    },
    "& th:hover": {
      color: "#002C42 !important",
    },
    "& td": {
      padding: 15,
      background: "white",
      fontSize: 14,
      fontWeight: "normal"
    }
  },
  status: {
    display: "flex",
    fontWeight: "bold",
    color: "white",
    "& .looking_for_rider": {
      background: "#FFC044",
      color: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .for_pickup": {
      background: "#FFC044",
      color: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .otw_to_customer": {
      background: "#FFC044",
      color: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .delivered": {
      background: "#57B19F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .completed": {
      background: "#57B19F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .cancelled": {
      background: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    }
  },
  link: {
    fontWeight: "bold",
    color: "#F97663",
    fontSize: 14,
    marginBottom: 0,
    cursor: "pointer",
  }

});

const headCells = [
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
  { id: 'old', numeric: false, disablePadding: false, label: 'Old' },
  { id: 'new', numeric: false, disablePadding: false, label: 'New' },
  { id: 'datetime_created', numeric: false, disablePadding: false, label: 'Shop Created Date & Time' },
  { id: 'datetime_changed', numeric: false, disablePadding: false, label: 'Change Date & Time' },
];


function TableBodyData (props) {
  return (
    <TableBody>
      {stableSort(props.data, getComparator(props.order, props.orderBy))
      .map((row, index) => {
        return (
          <TableRow
            key={index}
            hover
            role="checkbox"
            tabIndex={-1}
          >
            <TableCell align="left"><a href={`mailto:${row.email}`}>{row.email}</a></TableCell>
            <TableCell align="left">{row.action}</TableCell>
            <TableCell align="left">{row.old}</TableCell>
            <TableCell align="left">{row.new}</TableCell>
            <TableCell align="left">{moment(row.datetime_created).format("lll")}</TableCell>
            <TableCell align="left">{moment(row.datetime_changed).format("lll")}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  )
}

const ShopLogs = (props) => {
  const { classes, history, dispatch, match, shops } = props;
  const [state, setState] = React.useState({
    order: "asc",
    orderBy: "email",
  })

  React.useEffect(() => {
    dispatch(Actions.fetchShop(match.params.id))
  }, [])

  let logs = "";
  try {
    if (shops.length){
      const shop = shops.filter((item) => item.pk === match.params.id)[0];
      if (shop.logs.length) {
        logs = shop.logs.split("\n").filter(item => item.length > 0).map((nl) => {
          const splited_data = nl.split(", ");
          return {
            email: splited_data[0],
            action: splited_data[1],
            old: splited_data[2],
            new: splited_data[3],
            datetime_created: splited_data[4],
            datetime_changed: splited_data[5]
          }
        });
      }
    }
  } catch {}

  return (
    <AdminDashboardLayout filterHeaderHidden backLink="/admin/dashboard/shops">
      <ACL roles={["admin", "superuser"]} redirect_to="/login">
        <div className="mx-4">
          <h2 className={classes.title}>Logs</h2>
        </div>
        <div className="mt-4 mx-4">
          {
            logs.length > 0 ?
            <div className={classes.table}>
              <BasicTable 
                headCells={headCells}
                tableBody={
                  <TableBodyData 
                    order={state.order} 
                    orderBy={state.orderBy} 
                    data={logs}
                    classes={classes}
                    history={history}
                  />
                }
                length={5}
                defaultOrder="asc"
                defaultOrderBy="pr_no"
                setOrderAndOrderBy={(order, orderBy) => setState({...state, order, orderBy})}
                loading={false}
              />
            </div> :
            <div className="text-center m-5 p-5">
              <p className="text-secondary">No logs found</p>
            </div>
          }
        </div>
      </ACL>
    </AdminDashboardLayout>
  );
}

const mapStateToProps = state => ({ adminDashboard }) => ({ shops: adminDashboard.shopsList });
export default connect(mapStateToProps)(withStyles(useStyles)(ShopLogs));
