import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = theme => ({
	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "gray",
    cursor: "pointer"
  }
});

class ConfirmModal extends React.Component {

  render (){
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={(event) => this.props.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >	
        <div className={classes.modalDiv}>
          <div className="position-relative">
            <CloseIcon className={classes.closeIcon} 
            onClick={(event) => this.props.onClose()}/>
            <div className={classes.modalTile}>
              {this.props.title}
            </div>
            <div className={classes.modalBody}>
              {this.props.body}
            </div>
            <div className={classes.modalActionsWrapper}>
              <Button
                fullWidth
                id="action_button"
                variant="contained"
                onClick={(event) => this.props.onClick()} 
                color="primary"
                className={classes.modalActions}
              >
                Confirm
              </Button>
              {/* {actionButton()} */}
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}


export default withStyles(useStyles)(ConfirmModal);

