const defaultColor = {
  //0f4c81 f29191 f8efe1 d1d5dd
  bastille: {
    color: "#2b2d33",
    appBarLogo: "https://www.colorhexa.com/2b2d33.png"
  },
  redOrange: {
    color: "#d55e00",
    appBarLogo: "https://www.colorhexa.com/d55e00.png"
  },
  violetRed: {
    color: "#cc79a7",
    appBarLogo: "https://www.colorhexa.com/cc79a7.png"
  },
  orange: {
    color: "#e69f00",
    appBarLogo: "https://www.colorhexa.com/e69f00.png"
  },
  goldenFizz: {
    color: "#f0e442",
    appBarLogo: "https://www.colorhexa.com/f0e442.png"
  },
  darkCyan: {
    color: "#2f9395",
    appBarLogo: "https://www.colorhexa.com/2f9395.png"
  },
  skyBlue: {
    color: "#3e92cc",
    appBarLogo: "https://www.colorhexa.com/3e92cc.png"
  },
  funBlue: {
    color: "#9763c0",
    appBarLogo: "https://www.colorhexa.com/9763c0.png"
  },

  "blue/navy": {
    colorA: "#3e92cc",
    colorB: "#1e0065",
    appBarLogo: "https://www.colorhexa.com/3e92cc.png"
  },
  "pink/darkCyan": {
    colorA: "#2f9395",
    colorB: "#e9a6b3",
    appBarLogo: "https://www.colorhexa.com/2f9395.png"
  },
  "goldYellow/bastille": {
    colorA: "#f1c944",
    colorB: "#2b2d33",
    appBarLogo: "https://www.colorhexa.com/f1c944.png"
  },
  "lightGray/grey": {
    colorA: "#f1f1f1",
    colorB: "#373737",
    appBarLogo: "https://www.colorhexa.com/f1f1f1.png"
  },
  "pink/darkGrey": {
    colorA: "#fcbfb7",
    colorB: "#334e58",
    appBarLogo: "https://www.colorhexa.com/fcbfb7.png"
  },
  "richBlue/turquoise": {
    colorA: "#5cc5cf",
    colorB: "#4964af",
    appBarLogo: "https://www.colorhexa.com/5cc5cf.png"
  },
  "redOrange/Mondo": {
    colorA: "#514842",
    colorB: "#d55e00",
    appBarLogo: "https://www.colorhexa.com/514842.png"
  },
  "orange/dimGray": {
    colorA: "#5e5e5e",
    colorB: "#e69f00",
    appBarLogo: "https://www.colorhexa.com/5e5e5e.png"
  }
};

export default defaultColor;
