import React from "react";
import {
  Paper,
  Link,
  Box,
  TableCell,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableBody,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import image1 from "../../assets/images/Bux_Card_Icon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import RedirectDialog from "./redirectDialog";
import SentroButton from "../common/sentroButton";
import DashboardActions from "./dashboardActions";
import CloseIcon from "@material-ui/icons/Close";
import { Modal, Button } from "antd";
import DashboardServices from "./dashboardServices";
require("dotenv").config();

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: theme.spacing(4, 6, 4)
  },

  BuxCard: {
    padding: 20,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    // width: 500,
    maxHeight: "auto",
    flexWrap: "wrap"
  },

  thumbnail: {
    width: "100vw",
    marginRight: 50,
    overflow: "hidden",
    padding: 20,
    transform: "scale(.18)",
    transformOrigin: "top left",
    position: "absolute",
    [theme.breakpoints.up("xl")]: {
      transform: `scale(0.08)`
    }
  },

  content: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "block"
    }
  },
  buxLevel: {
    fontWeight: "bold",
    textDecoration: "underline",
    "&hover": {
      color: "#F97663"
    }
  },
  dialog: {
    padding: "50px 50px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0 0"
    }
  }
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const BuxCard = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));

  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [openBux, setOpenBux] = React.useState(false);
  const kyc_level = useSelector(state => state.dashboard.status);

  // function getStatus(id) {
  //   let status = DashboardServices.goBuxStatus(id);
  //   let key = status.then(data => {
  //     let keys = data.data.kyc_level;
  //     if (typeof keys == "number") {
  //       console.log(keys);
  //       return keys;
  //     }
  //   });
  //   console.log(status);
  // }

  // let status = getStatus(user.id);

  // React.useEffect(() => {
  //   getStatus(user.id);
  // }, []);

  // function proceedToBux() {
  //   dispatch(DashboardActions.goToBux(user.id));
  //   // window.open(`https://demo-app.bux.ph/sentro/login/${"buxToken"}`);
  // }

  // function getStatus() {
  //   let status = DashboardServices.goBuxStatus(user.id).then(result => {
  //     console.log(result);
  //     return result.data.kyc_level;
  //   });
  //   console.log(status, "status");
  // }
  function proceedToCompleteProfile() {
    let status = kyc_level;
    dispatch(DashboardActions.goToCompleteProfile(user.id, status));
    setOpenBux(false);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  function setStatus() {
    let status = kyc_level;
    switch (status) {
      case 0:
        return "Basic level";
        break;
      case 1:
        return "Pro level";
        break;
      case 2:
        return "Advanced level";
        break;
      case 3:
        return "Business level";
        break;
      default:
        return "Basic level";
    }
  }

  function statusLink() {
    return (
      <>
        <Dialog
          name="bux-modal"
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleClose}
        >
          <div style={{ padding: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10
              }}
            >
              <div
                style={{ fontSize: 20, fontWeight: "bold", color: "#002C42" }}
              >
                Wallet Size by Level
              </div>
              <div onClick={() => setOpen(false)} style={{ cursor: "pointer" }}>
                <CloseIcon />
              </div>
            </div>
            <div style={{ fontSize: 12, color: "#909196", marginBottom: 10 }}>
              Powered by Bux
            </div>
            <Table size="medium" style={{ border: "1px solid" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: 600, fontSize: 12 }}
                    align="center"
                  >
                    Level
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 600, fontSize: 12 }}
                    align="center"
                  >
                    Period
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 600, fontSize: 12 }}
                    align="center"
                  >
                    Cash In
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 600, fontSize: 12 }}
                    align="center"
                  >
                    Cash Out
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={1}
                    style={{ fontWeight: 500, borderRight: "1px solid" }}
                  >
                    Basic
                  </TableCell>
                  <TableCell align="center">Monthly</TableCell>
                  <TableCell name="basic-cashin" align="center">
                    5,000
                  </TableCell>
                  <TableCell name="basic-cashout" align="center">
                    0
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={1}
                    style={{ fontWeight: 500, borderRight: "1px solid" }}
                  >
                    Pro
                  </TableCell>

                  <TableCell align="center">Monthly</TableCell>
                  <TableCell name="pro-cashin" align="center">
                    100,000
                  </TableCell>
                  <TableCell name="pro-cashout" align="center">
                    100,000
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={1}
                    style={{ fontWeight: 500, borderRight: "1px solid" }}
                  >
                    Advanced
                  </TableCell>

                  <TableCell align="center">Monthly</TableCell>
                  <TableCell name="advanced-cashin" align="center">
                    500,000
                  </TableCell>
                  <TableCell name="advanced-cashout" align="center">
                    500,000
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 500, borderRight: "1px solid" }}
                  >
                    Business
                  </TableCell>
                  <TableCell align="center">Monthly</TableCell>
                  <TableCell name="business-cashin" align="center">
                    1,000,000
                  </TableCell>
                  <TableCell name="business-cashout" align="center">
                    1,000,000
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div
              style={{
                margin: 15,
                padding: "0px 12vw",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <SentroButton
                name="upgrade-modal"
                style={{ padding: 0 }}
                onClick={() => setOpenBux(true)}
                label="Upgrade Wallet"
                variant="contained"
                fullWidth
              />
            </div>
          </div>
        </Dialog>
        <Link
          id="status"
          className={classes.buxLevel}
          onClick={() => setOpen(true)}
        >
          {setStatus()}
        </Link>
      </>
    );
  }

  return (
    <Paper className={classes.BuxCard}>
      <RedirectDialog
        onClose={() => setOpenBux(false)}
        open={openBux}
        onProceed={() => proceedToCompleteProfile()}
        header={"Bux Dashboard"}
        text={"This page will redirect to your BUX dashboard"}
      />
      <div className={classes.content}>
        <div
          style={{
            display: "flex",
            padding: 5,
            justifyContent: "center"
          }}
        >
          <img
            src={image1}
            width={"auto"}
            height={75}
            style={{ marginTop: 10 }}
          />
        </div>
        <div style={{ flex: 10, margin: 20 }}>
          <div
            style={{
              fontStyle: "normal",
              fontWeight: "bold",
              color: "#002C42",
              fontSize: 16
            }}
          >
            {props.language === "Filipino"
              ? "I-upgrade ang iyong online shop wallet"
              : "Upgrade your online shop’s wallet"}
          </div>
          <div
            style={{
              fontStyle: "normal",
              //   fontWeight: "bold",
              fontSize: 12,

              marginTop: 8
            }}
          >
            {props.language === "Filipino"
              ? "Kasalukuyang nasa "
              : "Your online shop's wallet is currently in "}{" "}
            {statusLink()}{" "}
            {props.language === "Filipino"
              ? " ang iyong online shop wallet. I-upgrade ito ng walang bayad para tumaas ang pwede mong matanggap at ma-withdraw na pera"
              : ". Upgrade it for free to increase the amount you can receive and withdraw so you can transact more."}
          </div>
        </div>
        <div style={{ marginRight: 20 }}>
          <SentroButton
            name="upgrade-button"
            disabled={user ? (user.is_verified == true ? false : true) : true}
            variant="outlined"
            label="Upgrade"
            fullWidth
            onClick={() => setOpenBux(true)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
          fontSize: 12,
          color: "grey",
          marginRight: 20
        }}
      >
        Powered by Bux
      </div>
    </Paper>
  );
};

export default BuxCard;
