import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Chip,
  Popover,
  FormControl,
  MenuItem,
  Select,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
export default function VariantPicker(props) {
  const { product, productSelection } = props;
  const dispatch = useDispatch();
  const themeColor = useSelector(state => state.enterprise.main.theme);
  const useStyles = makeStyles(theme => ({
    btnSelectVariant: {
      borderRadius: 0,
      border: "1px solid red",
      color: "red",
      fontSize: 10,
      fontWeight: "normal",
      height: 25
    },
    chip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    },
    header: {
      padding: 12,
      fontSize: 14,
      fontWeight: 600,
      borderBottom: "1px solid #d1d5dd",
      color:
        themeColor.palette.secondary.main !== "#18B4A1"
          ? themeColor.palette.secondary.main
          : themeColor.palette.secondary.defaultHoverColor
    }
  }));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState("");
  const [selected_variant, set_selected_variant] = React.useState("");
  const openReplaceVariant = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (group_id, value) => {
    set_selected_variant(state => ({ ...state, [group_id]: value }));
  };

  const findProductSelectionMatch = selectedVariantSelectionIds => {
    const matched_selection = product?.selections?.filter(i =>
      i.variants.every(v => selectedVariantSelectionIds.some(id => v.id === id))
    );
    return matched_selection?.length ? matched_selection[0] : null;
  };

  const confirmSelectVariant = () => {
    const variant_temp_ids_int = Object.values(selected_variant);
    const matched_selection = findProductSelectionMatch(variant_temp_ids_int);
    if (props.handleConfirmVariant) {
      props.handleConfirmVariant({
        ...product,
        variants: matched_selection.variants,
        productSelection: matched_selection,
        prevSelection: productSelection,
        index: props.index
      });
      setAnchorEl(null);
    }
  };

  React.useEffect(() => {
    if (product && productSelection) {
      productSelection.variants.map(variant =>
        set_selected_variant(state => ({
          ...state,
          [variant.group]: variant.id
        }))
      );
    }
  }, [product]);

  const variantPickerDialog = () => {
    let variant_groups = product.variant_groups;

    return (
      <Popover
        open={openReplaceVariant}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div style={{ width: 220 }}>
          <div className={classes.header}>Replace variant</div>
          <div style={{ padding: 12 }}>
            {variant_groups.length
              ? variant_groups.map((item, key) => {
                  return (
                    <div>
                      <div style={{ fontSize: 12 }}>{item.name}</div>
                      <FormControl
                        style={{ width: "100%" }}
                        variant="outlined"
                        margin="dense"
                      >
                        <Select
                          value={selected_variant[item.id]}
                          onChange={e => onChange(item.id, e.target.value)}
                          style={{ fontSize: 12, background: "#fff" }}
                          color="primary"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            }
                          }}
                        >
                          {item.variants
                            .filter(item => item.is_enabled)
                            .map((variant, key) => {
                              return (
                                <MenuItem
                                  value={variant.id}
                                  style={{
                                    fontSize: 12,
                                    textTransform: "capitalize"
                                  }}
                                >
                                  {variant.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  );
                })
              : ""}
            <div className="d-flex justify-content-end mt-2">
              <Button
                variant="outlined"
                color="primary"
                style={{ height: 35, fontSize: 12 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                style={{ marginLeft: 10, height: 35, fontSize: 12 }}
                onClick={() => confirmSelectVariant()}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    );
  };
  return (
    <div>
      <div className="d-flex align-items-center my-2">
        {product.has_variants ? (
          !productSelection ? (
            <Chip
              variant="outlined"
              className={classes.btnSelectVariant}
              label="Select variant"
            />
          ) : (
            productSelection.variants.map(variant => (
              <Chip
                size="small"
                label={variant.name}
                className={classes.chip}
              />
            ))
          )
        ) : (
          ""
        )}
        {product.has_variants && !product.registry ? (
          <IconButton
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setSelected(props.index);
            }}
            className="p-0"
          >
            <ExpandMoreIcon fontSize="small" />
          </IconButton>
        ) : (
          ""
        )}
        {selected === props.index ? variantPickerDialog() : ""}
      </div>
    </div>
  );
}
