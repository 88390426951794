import React from "react";
import Footer from "./Footer";
import ProductTemplate from "./products/productTemplate";
import PreviewHeader from "./PreviewAppBar";
import PreviewProductTemplate from "./products/previewProductTemplate";
const Products = props => {
  let storeInfo = props.storeInfo;
  let productList = props.productList;

  return (
    <div>
      {/* <Header
        isPreview={true}
        storeInfo={storeInfo}
        color={true}
        logo={true}
        shadow={true}
        logo={true}
      /> */}
      <PreviewHeader
        setOnClick={props.setOnClick}
        isPreview={true}
        storeInfo={storeInfo}
        color={true}
        logo={true}
        shadow={true}
      />
      <PreviewProductTemplate store={storeInfo} productList={productList} />

      <Footer
        setImageSettings={props.setImageSettings}
        isPreview={true}
        store={storeInfo}
      />
    </div>
  );
};

export default Products;
