import React, { useEffect, cloneElement, Children } from "react";
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import ACL from "../common/utilities/ACL";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Snackbar from "@material-ui/core/Snackbar";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Category from "@material-ui/icons/Category";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import history from "../../store/history";
import Path from "../../Paths";
import sentro_logo2 from "../../assets/images/sentro_logo2.png";
import SentroButton from "../common/sentroButton";
import Action, { ActionTypes } from "./Redux/Actions";
import { ArrowDropDown } from "@material-ui/icons";
import { Popover, MenuItem } from "@material-ui/core";
import { signOff } from "../auth/authActions";
import { useDispatch } from "react-redux";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import moment from "moment";
import SentroLoading from '../common/loading'
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { animateScroll as scroll } from "react-scroll";


const drawerWidth = 230;

let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
const is_superuser = data && data.is_superuser;

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		backgroundColor: "#f1f1f1",
		minHeight: "100vh"
	},
	appBar: {
		backgroundColor: "white",
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: theme.spacing(2),
		color: theme.palette.text.secondary
	},
	hide: {
		display: "none"
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		borderRight: 0
	},
	drawerPaper: {
		// boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
		width: drawerWidth,
		borderRight: 0
	},
	drawerHeader: {
		position: "absolute",
		bottom: 0,
		width: "100%"
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
		width: theme.spacing(9) + 1
		}
	},
	drawerTitle: {
		fontWeight: "bold",
		color: "rgba(0,0,0,0.4)",
		fontSize: "13px"
	},
	content: {
		width: "calc(95vw - 230px)",
		backgroundColor: "#f1f1f1",
		flexGrow: 1,
		transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
		}),
		marginLeft: -drawerWidth
	},
	contentShift: {
		transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.easeOut,
		duration: theme.transitions.duration.enteringScreen
		}),
		marginLeft: 0
	},
	error: {
		color: "rgb(97, 26, 21)",
		backgroundColor: "rgb(253, 236, 234)",
		boxShadow: "0 0 0 0 rgba('0,0,0,0.15')",
		padding: "1em",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column"
	},
	errorHeader: {
		justifyItems: 'center',
		display: 'flex'
	},
	listMenu: {
		color: "#909196",
		fontWeight: "600",
		fontSize: 16
	},
	activeMenu: {
		background: "#f1f1f1",
		"& div": {
			color: "#f97663 !important"
		}
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	drawerContainer: {
		overflow: "auto",
		marginTop: 10
	},
	headerButtons: {
		height: "35px",
		color: '#002C42',
		background: 'white',
		border: "none",
		fontWeight: 400,
		boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.1)",
		"&:hover": {
			background: '#f1f1f1',
			border: "none"
		}
	},
	headerButtonsActive: {
		height: "35px",
		color: 'white',
		background: '#002C42',
		border: "none",
		fontWeight: 400,
		boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.1)",
		"&:hover": {
			background: '#002c42cf',
			border: "none"
		}
	},
	pageHeader: {
		backgroundColor: "#F1F1F1",
		display: "flex",
		flexWrap: "wrap",
		padding: "1.5em",
		alignItems: "center",
		fontWeight: 600,
		boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.2)",
		position: 'fixed',
		top: 65,
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			display: "block",
			padding: "1em",
			"& > p": {
				marginBottom: "1em !important"
			}
		},
		"& > p": {
			marginBottom: 0,
			marginRight: "auto"
		},
		zIndex: 1000
	},
	datePicker: {
		backgroundColor: "white",
		border: 'none',
		width: 160,
		boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.1)",
		borderRadius: 3,
		"& input": {
			padding: 11,
			fontSize: 12,
			fontWeight: 400,
			color: "#002C42 !important"
		},
		"& > div": {
			paddingLeft: 0
		},
		"& fieldset": {
			borderColor: 'rgba(0,0,0,0.1)'
		},
		"& button": {
			color: "rgba(3, 46, 68, 0.65)"
		}
	},
	dateRangeWrapper: {
		paddingTop: 15,
		display: 'flex', 
		width: '100%',
		alignItems: "center",
		"& > div": {
			marginLeft: "auto"
		},
		[theme.breakpoints.down("sm")]: {
			"& > div": {
				marginLeft: 0
			}
		},
	},
	backHeader: {
		width: 120,
		backgroundColor: "#F1F1F1",
		display: "flex !important",
		padding: "1.5em 2em 1.5em",
		alignItems: "center",
		fontWeight: 600,
		fontSize: 14,
		color: "#F97663 !important"
	},
}));

function PartnerDashboardLayout(props) {
	const classes = useStyles();
	const theme = useTheme();
	const MOBILE_WIDTH_THRESHOLD = 740;
	const first_drawer_state = window.innerWidth > MOBILE_WIDTH_THRESHOLD;
	const [open, setOpen] = React.useState(first_drawer_state);
	const [errorState, errorStateChange] = React.useState(false);
	const [clicked, setClicked] = React.useState("Marketing");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuActive, setMenuActive] = React.useState(props.partnerDashboard.menuActive);
	const [time, setTime] = React.useState()
	const [dateFilter, setDateFilter] = React.useState({
		type: props.partnerDashboard.dateFilter.type || null,
		startDate: props.partnerDashboard.dateFilter.startDate || new Date().toLocaleDateString("en-US"),
		endDate: props.partnerDashboard.dateFilter.endDate || new Date().toLocaleDateString("en-US")
		
	})
	const dispatch = useDispatch();
	const { currentUser } = props.partnerDashboard
	const name = currentUser ? currentUser.first_name + " " + currentUser.last_name : ""
	const [displayName, setDisplayName] = React.useState(name);
	const pageHeaderRef = React.useRef();
	const [pageHeaderHeight, setPageHeaderHeight] = React.useState(0);
	
	const setMenuListActive = (menu) => {
		dispatch(Action.setMenuActive(menu))
	}
	const location = useLocation()

	const resizeListener = () => {
		setTimeout(() => {
			const val = window.innerWidth > MOBILE_WIDTH_THRESHOLD
			setOpen(val);
			if (!props.filterHeaderHidden && pageHeaderRef.current){
				setPageHeaderHeight(pageHeaderRef.current.clientHeight)
			}
		})
	};

	useEffect(() => {
		async function fetchCurrentUser() {
			const response = await dispatch(Action.loadCurrentUser());
			if (response.status !== 200){
				dispatch(signOff())
				history.push(Path.LOGIN)
			}
		}
		fetchCurrentUser();
		var date = moment()
			.utcOffset("+05:30")
			.format("MMM DD, YYYY");
		setTime(date)
		resizeListener()
		window.addEventListener("resize", resizeListener);
		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, [])

	useEffect(() => {
		const { currentUser } = props.partnerDashboard
		const name = currentUser ? currentUser.first_name + " " + currentUser.last_name : ""
		setDisplayName(name)
		errorStateChange(props.partnerDashboard.error);
		setMenuActive(props.partnerDashboard.menuActive);
	}, [props.partnerDashboard]);

	const logout = () => {
		dispatch(signOff());
	};

	const setDrawerState = (val) => {
		setOpen(val);
	};

	const handleClickMenu = e => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = e => {
		setAnchorEl(null);
	};

	const dateFilterTypeOnClick = (type) => {
		setDateFilter({
			...dateFilter,
			type
		})
		dispatch(Action.setDateFilter({ 
			...dateFilter,
			type 
		}))
		resizeListener()
		if (type!=='custom'){
			props.onDateFilterCallback({
				...dateFilter,
				type 
			})
		}
	}

	const setDateRangeFilter = (type, val) => {
		const date = new Date(val);
		const date_str = date.toLocaleDateString("en-US");
		if (type === "start") setDateFilter({
			...dateFilter,
			startDate: date_str
		})
		else if (type === "end") setDateFilter({
			...dateFilter,
			endDate: date_str
		})
		dispatch(Action.setDateFilter({ 
			...dateFilter,
			[type === 'start' ? 'startDate': 'endDate']: date_str 
		}))
		props.onDateFilterCallback({
			...dateFilter,
			[type === 'start' ? 'startDate': 'endDate']: date_str
		})
	}

	function returnAvatar(word) {
	
		if (word.length){
			let newWord = word.split(/\W+/);
			if (newWord.length === 2) {
				return (
					newWord[0].charAt(0).toUpperCase() + newWord[1].charAt(0).toUpperCase()
				);
			} else {
				return word.charAt(0).toUpperCase();
			}
		}
	}

	setTimeout(() => {
		['html', 'body'].map((element) => {
			const html = document.getElementsByTagName(element)
			html[0].style.removeProperty("overflow")
		})
	})
	
	function getElemDistance ( elem ) {
		var location = 0;
		if (elem.offsetParent) {
			do {
				location += elem.offsetTop;
				elem = elem.offsetParent;
			} while (elem);
		}
		return location >= 0 ? location : 0;
	};

	return (
		<div className={classes.root} id="dashboard-root">
			<CssBaseline />
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
				{
					(!props.hideSideBar || false) && 
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={() => setDrawerState(true)}
						edge="start"
						className={clsx(classes.menuButton, open && classes.hide)}
					>
						<MenuIcon />
					</IconButton>
				}
				<a
					onClick={() => {
						if (data) {
							history.push(Path.ADMIN_APP_MARKETPLACE_PARTNERS_SALES_VIEW);
						} else {
							history.push(Path.HOME);
						}
						window.scrollTo(0, 0);
					}}
				>
					<img
					alt="sentro-light"
					src={sentro_logo2}
					style={{ width: 120, height: "auto" }}
					/>
				</a>

				<div style={{ marginLeft: "auto" }}>
					<SentroButton
					label={displayName}
					startIcon={
						<Avatar
						style={{
							width: 25,
							height: 25,
							background: `linear-gradient(106.17deg, #35B0AB 4.56%, #226B80 154.44%)`,
							fontSize: 10
						}}
						>
						{returnAvatar(displayName)}
						</Avatar>
					}
					endIcon={<ArrowDropDown style={{ color: "#002C42" }} />}
					style={{
						margin: 10,
						textTransform: "capitalize",
						color: "#002C42"
					}}
					onClick={handleClickMenu}
					/>

					<Popover
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left"
					}}
					id="lock-menu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleClose}
					>
					<MenuItem
						style={{
						height: "8vh",
						width: is_superuser ? 170 : 130
						}}
						onClick={() => window.open("https://faq.sentro.ph/hc/en-us")}
					>
						<Typography style={{ fontSize: 14, color: "#2B2D33" }}>
						FAQs
						</Typography>
					</MenuItem>
					<MenuItem
						style={{
						height: "8vh",
						width: is_superuser ? 170 : 130
						}}
						onClick={() => {
							logout();
							history.push("/login")
						}}
					>
						<Typography style={{ fontSize: 14, color: "#2B2D33" }}>
						Logout
						</Typography>
					</MenuItem>
					</Popover>
				</div>
				</Toolbar>
			</AppBar>
			{
				(!props.hideSideBar || false) && 
				<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="left"
					open={open}
					classes={{
					paper: classes.drawerPaper
					}}
				>
					<Toolbar />
					<div className={classes.drawerContainer}>
						<List>
							<ListItem button key={"sales"} onClick={() => {
									history.push(Path.ADMIN_APP_MARKETPLACE_PARTNERS_SALES_VIEW)
									setMenuListActive("sales")
								}} className={`${menuActive === 'sales' ? classes.activeMenu : ''}`}>
								<ListItemIcon className={`
									${classes.listMenu}
								`}>
									<ReceiptIcon />
								</ListItemIcon>
								<ListItemText>
									<div className={`
										${classes.listMenu}
									`}>Sales & Orders</div>
								</ListItemText>
							</ListItem>
						</List>
					</div>
					<div className={classes.drawerHeader}>
						<List>
							<ListItem button onClick={ () => setDrawerState(false) }>
							<ListItemIcon className={classes.listMenu}>
								<ChevronLeftIcon />
							</ListItemIcon>
							<ListItemText className={classes.listMenu}>Collapse</ListItemText>
							</ListItem>
						</List>
					</div>
				</Drawer>
			}
			<main
				name={props.name}
				id={props.id}
				className={clsx(classes.content, {
					[classes.contentShift]: (!props.hideSideBar || false) ? open : open || (props.hideSideBar || true) 
				})}
			>
				<div className={classes.toolbar} style={{ 
					height: props.filterHeaderHidden ? 64 : pageHeaderHeight+64 
				}} />
				{
					! props.filterHeaderHidden &&
					<div> 
						<div
							ref={pageHeaderRef}
							className={classes.pageHeader} 
							style={{ 
								width: open ? "calc(100% - 230px)" : "100%", 
							}}
						>
							<p className={classes.pageTitle}>As of {time}</p>
							<ButtonGroup
								style={{ backgroundColor: "#fff" }}
								color="primary"
								aria-label="outlined primary button group"
							>
								{
									["YTD", "MTD", "1-YEAR", "4-WEEK", "1-WEEK", "CUSTOM"].map((filterType) => (
										<Button
											key={filterType.toLowerCase()}
											size="small" 
											className={
												dateFilter.type==filterType.toLowerCase() ? 
												classes.headerButtonsActive : classes.headerButtons
											}
											onClick={() => dateFilterTypeOnClick(
												dateFilter.type === filterType.toLowerCase() ? 
												null : filterType.toLowerCase()
											)}
										>{filterType}</Button>
									))
								}
							</ButtonGroup>
							{
								dateFilter.type === "custom" &&
								<div className={classes.dateRangeWrapper}>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											className={classes.datePicker}
											label=""
											autoOk
											variant="inline"
											inputVariant="outlined"
											format="MM/DD/YYYY"
											value={dateFilter.startDate}
											maxDate={dateFilter.endDate}
											InputAdornmentProps={{ position: "start" }}
											onChange={(date) => setDateRangeFilter("start", date)}
											orientation="landscape"
										/>
										<span style={{
											color: "rgba(0,0,0,0.5)",
											fontSize: 12,
											margin: "0 7px"
										}}>-</span>
										<KeyboardDatePicker
											className={classes.datePicker}
											style={{marginLeft: 0}}
											label=""
											autoOk
											variant="inline"
											inputVariant="outlined"
											format="MM/DD/YYYY"
											minDate={dateFilter.startDate}
											value={dateFilter.endDate}
											InputAdornmentProps={{ position: "start" }}
											onChange={(date) => setDateRangeFilter("end", date)}
											orientation="landscape"
										/>
									</MuiPickersUtilsProvider>
								</div>
							}
						</div>
					</div>
				}
				{
					!props.backButtonHidden &&
					<a className={classes.backHeader} onClick={() => {
						history.goBack()
					}}>
						<ArrowBackIcon/>
						<span style={{marginLeft: "1em"}}>{`  BACK`}</span>
					</a>
				}
				{props.children}
			</main>
			<SentroLoading open={props.partnerDashboard.loading} />
		</div>
	);
}

export default connect(({ partnerDashboard }) => ({ 
    partnerDashboard,
}))(PartnerDashboardLayout);
