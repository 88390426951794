import { makeStyles } from "@material-ui/core";
import bg from "../../../../../../assets/images/kycPopup.jpg";
const useStyles = makeStyles(theme => ({
  paper: {
    width: "60em",
    height: "100vh",
    position: "absolute",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover"
  },
  title: {
    fontSize: 28,
    color: "#fff",
    fontWeight: "bold",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  title2: {
    textAlign: "center",
    fontSize: 20,
    color: "#2b2d33",
    fontWeight: "bold"
  },
  subTitle: {
    padding: 20,
    fontSize: 14,
    color: "#54575f"
  },
  form: {
    padding: 20,
    width: "100%"
    // [theme.breakpoints.up("xl")]: {
    //   height: "63em"
    // },
    // [theme.breakpoints.down("md")]: {
    //   height: "43em"
    // }
  },
  form2: {
    backgroundColor: "#fff",
    width: "100%",
    marginTop: 15
  },
  label: {
    fontSize: 14,
    color: "#2b2d33"
  },
  label2: {
    fontSize: 12,
    color: "#2b2d33"
  },
  radioGender: {
    display: "flex",
    flexDirection: "inherit"
  },
  radioStatus: {
    display: "flex",
    flexWrap: "wrap"
  },
  radioNationality: {
    display: "flex",
    flexDirection: "inherit"
  },
  btnNext: {
    width: 100
  },
  skip: {
    color: "#54575f",
    fontSize: 12,
    fontWeight: 600,
    display: "flex",
    justifyContent: "flex-end"
  },
  btnHomepage: {
    display: "flex",
    justifyContent: "center",
    margin: 30,
    width: 180,
    height: 45
  },
  errorText: {
    fontSize: 12,
    color: "red"
  },
  appBar: {
    width: "100%",
    position: "fixed",
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd",
    padding: 20
  }
}));

export default useStyles;
