import React from 'react'
import Utils from "../common/utils";


export const usePriceRangeDisplay = (product) => {
  const [priceDisplay, setPriceDisplay] = React.useState("");
  
  React.useEffect(() => {
    if (!product.has_variants) {
      setPriceDisplay(
        product.discounted_price ?
        <div className="d-flex">
            <p className="mb-0 mr-1"><del>{Utils.commafy(parseFloat(product.price))}</del></p>
            <p className="mb-0"><strong>{Utils.commafy(parseFloat(product.discounted_price))}</strong></p>
        </div>
        :
        Utils.commafy(parseFloat(product.price))
      );
    } 
    else {
      const prices = product.selections.map(i => (
        parseFloat(i.discounted_price) || parseFloat(i.price)
      ));
      const min = Math.min(...prices), max = Math.max(...prices);
      if (min === max) {
        setPriceDisplay(Utils.commafy(min));
      }
      else {
        setPriceDisplay(`${Utils.commafy(Math.min(...prices))} - ${Utils.commafy(Math.max(...prices))}`)
      }
    }
  }, [product])


  return [priceDisplay]
}