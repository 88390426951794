import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, useMediaQuery, Slide, Container } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import SentroAppBarMobile from './components/appBarMobile';
import MobileBottomNavigation from './mobile/bottomNavigation';
import MuiAlert from '@material-ui/lab/Alert';
import Path from '../../../Paths';
import { Store, Fastfood, Receipt } from '@material-ui/icons';
import { hideAlert } from './mobile/webBuilder/websiteBuilderActions';
import BuyerSideMenu from '../../dashboard/buyerDashboard/buyerSideMenu';
import BuyerAppbar from '../../dashboard/buyerDashboard/buyerAppbar';
import BottomNavigation from '../../dashboard/buyerDashboard/bottomNavigation';
import SellerAppbar from './components/sellerAppbar';
import SideMenu from '../../dashboard/sideMenu';
import { useLocation } from 'react-router-dom';
export const SellerLayout = (props) => {
  const { language, setLanguage, children } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#fafafa',
      minHeight: `calc(100vh)`,
      [theme.breakpoints.down('sm')]: {
        overflow: 'scroll',
        display: 'none',
      },
    },
    mobile: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    side: {
      zIndex: 800,
      display: 'flex',
      position: 'fixed',
      height: '100%',
    },
    content: {
      width: '100%',
      marginTop: 55,
      paddingLeft: '23vw',
      paddingRight: '6vw',
      paddingBottom: '10vh',
      backgroundColor: '#fafafa',
    },
  }));
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const common = useSelector((state) => state.common);
  const stores = useSelector((state) => state.webBuilder.stores);
  const data = JSON.parse(sessionStorage.getItem('CURRENT_USER'));
  const is_desktop = useMediaQuery(theme.breakpoints.up('md'));
  const visible_header_footer = useSelector(
    (state) => state.marketplace.visible_header_footer
  );

  const closePopup = () => {
    dispatch(hideAlert());
  };

  const is_buyer_dashboard =
    location.pathname.includes(`my_cart`) ||
    location.pathname.includes(`purchased`);
  let menus = [
    {
      label: 'My Shop',
      path: Path.MOBILE_WEB_BUILDER,
      icon: <Store />,
      key: 0,
    },
    {
      label: 'Products',
      path: Path.MOBILE_PRODUCTS,
      icon: <Fastfood />,
      key: 1,
    },
    {
      label: 'Orders',
      path: Path.MOBILE_ORDERS,
      icon: <Receipt />,
      key: 2,
    },
    {
      label: 'Vouchers',
      path: Path.MOBILE_VOUCHERS,
      icon: '',
      key: 3,
    },
  ];

  return (
    <>
      <Snackbar
        id="snackbar"
        autoHideDuration={5000}
        style={{ zIndex: common.showAlert ? 9999 : -1 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={common.showAlert}
        onClose={closePopup}
      >
        <MuiAlert severity={common.alertType}>{common.message}</MuiAlert>
      </Snackbar>
      {is_desktop ? (
        <div id="desktop" className={classes.root}>
          {location.pathname === '/webbuilder' ? (
            ''
          ) : (
              <div className={classes.side}>
                <SideMenu
                  store={stores}
                  language={language}
                  setLanguage={setLanguage}
                />
              </div>
            )}

          <SellerAppbar />
          <div
            style={{
              paddingTop: 64,
              paddingRight: location.pathname === '/webbuilder' ? 0 : '2vw',
              marginLeft: location.pathname === '/webbuilder' ? 0 : 260,
            }}
          >
            <Container maxWidth="lg" className="px-5 py-3">
              <div>{children}</div>
            </Container>
          </div>
        </div>
      ) : (
          <div>
            <div>
              {visible_header_footer ? (
                <SentroAppBarMobile
                  setLanguage={setLanguage}
                  language={language}
                  currentUser={data}
                  private={true}
                />
              ) : (
                  ""
                )}
            </div>
            <div
              style={{
                minHeight: "100vh",
                padding: is_buyer_dashboard ? "10vh 5vw" : "inherit"
              }}
            >
              {children}
            </div>
            <div style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
              {visible_header_footer ? (
                <MobileBottomNavigation menu={menus} />
              ) : (
                  ""
                )}
            </div>
          </div>
        )}
    </>
  );
};

export const BuyerLayout = (props) => {
  const { children } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#fafafa',
      display: 'flex',
      minHeight: '100vh',
    },
    content: {
      width: '100%',
      marginTop: 55,
      paddingLeft: '23vw',
      paddingRight: '6vw',
      paddingBottom: '10vh',
      [theme.breakpoints.down('sm')]: {
        padding: '0 16px 20vh',
      },
    },
    bottom: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const is_desktop = useMediaQuery(theme.breakpoints.up('md'));
  const common = useSelector((state) => state.common);

  const TransitionDown = (props) => {
    return <Slide {...props} direction="down" />;
  };

  const closePopup = () => {
    dispatch(hideAlert());
  };
  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        open={common.showAlert}
        onClose={closePopup}
        style={{ zIndex: common.showAlert ? 9999 : -1 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert severity={common.alertType}>{common.message}</MuiAlert>
      </Snackbar>
      <div className={classes.root}>
        <div
          style={{
            display: 'flex',
            position: 'fixed',
            height: '100%',
            zIndex: 3,
          }}
        >
          <BuyerSideMenu />
        </div>
        <BuyerAppbar />
        <div className={classes.content}>{children}</div>
        <div className={classes.bottom}>
          <BottomNavigation />
        </div>
      </div>
    </>
  );
};
