import React from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import {
  AppBar,
  Box,
  MenuItem,
  Typography,
  makeStyles,
  useMediaQuery,
  IconButton,
  Dialog,
  Button,
  Popover,
  Avatar
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDropDown, Menu } from "@material-ui/icons";
import sentroTheme from "../../sentroTheme";
import sentro2 from "../../../../assets/images/sentro_logo2.png";
import { signOff, confirmLogOut } from "../../../auth/authActions";
import Path from "../../../../Paths";
import history from "../../../../store/history";
import MoreIcon from "@material-ui/icons/Menu";
import TranslateIcon from "@material-ui/icons/Language";
import { useTheme, ThemeProvider } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/Help";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SentroButton from "../../sentroButton";
import ACL from "../../utilities/ACL";
import { useLocation } from "react-router-dom";
import SideMenu from "../../../dashboard/sideMenu";
let menuItems = [];

let loggedOutMenu = [
  {
    label: "Login",
    path: Path.LOGIN
  },
  {
    label: "Sign up",
    path: Path.SIGN_UP,
    variant: "contained"
  }
];

function SentroAppBar(props) {
  const [position, setPosition] = React.useState(0);
  useScrollPosition(({ prevPos, currPos }) => {
    setPosition(currPos.y);
  });
  const location = useLocation();
  const path = location.pathname;
  let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const appBarStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "2vw",
      marginRight: "2vw",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: 0
      }
    },
    desktop: {
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: data ? "none" : "block"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    mobile: {
      display: "none",

      [theme.breakpoints.down("md")]: {
        display: data ? "block" : "none"
      },
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },
    dialog: {
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        display: "none"
      }
    },
    appBar: {
      position: "fixed",
      // color: "#002C42",
      // opacity: 0.2,
      boxShadow: !props.private && position >= -40 ? "none" : "",
      backgroundColor:
        !props.private && position >= -40 ? "transparent" : "white"
    },
    buttons: {
      display: "block",
      margin: 16,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "0px 100px"
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: 0
      }
    },
    btnSignup: {
      fontSize: 16,
      width: 100,
      height: 40,
      margin: 10,
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        height: 48,
        width: 200,
        marginRight: "3vw"
      },
      [theme.breakpoints.down("xs")]: {
        width: 100,
        height: 40,
        margin: 10
      }
    },
    popoverIpad: {
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    dialogMobile: {
      [theme.breakpoints.only("md")]: {
        marginTop: "8vh",
        width: "50vw",
        position: "absolute",
        marginLeft: "50vw"

        // position: "absolute",
        // right: 0,
        // top: 0,
        // display: "none",
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: "8vh",
        width: "50vw",
        position: "absolute",
        marginLeft: "50vw"
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "8vh",
        width: "100%"
      }
    }
  }));

  let menu = data ? menuItems : menuItems.concat(loggedOutMenu);
  let user = useSelector(state => state.user.profile);
  let name = user.first_name + " " + user.last_name;
  let user_name = data ? data.first_name + " " + data.last_name : "";
  let last_name = data ? data.last_name : "";
  let first_name = data ? data.first_name : "";
  let email = data ? data.email : "";
  let displayEmail = email ? email.split("@") : "";
  let displayName =
    first_name === "" && last_name === "" ? displayEmail[0] : user_name;

  const classes = appBarStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorTranslate, setAnchorTranslate] = React.useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(confirmLogOut());
  };
  const handleClickMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleTranslate = e => {
    setAnchorTranslate(e.currentTarget);
  };

  const handleClickMenu2 = e => {
    setAnchorEl2(anchorEl2 ? null : e.currentTarget);
  };

  const handleClickMenu3 = e => {
    setAnchorEl3(anchorEl3 ? null : e.currentTarget);
  };

  const handleClickMenu4 = e => {
    setAnchorEl4(anchorEl4 ? null : e.currentTarget);
  };

  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const handleClose = e => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleCloseTranslate = e => {
    setAnchorTranslate(null);
  };

  const handleClose2 = e => {
    setAnchorEl2(null);
    setOpen(false);
  };

  const handleClose3 = e => {
    setAnchorEl3(null);
    setOpen(false);
  };

  const handleClose4 = e => {
    setAnchorEl4(null);
    setOpen(false);
  };

  const handleClickOpen = e => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleAccountSettings = () => {
    history.push(Path.ACCOUNT_SETTINGS_BASIC_INFORMATION);
    handleClose();
    handleClose2();
    handleClose3();
  };

  const handleFAQ = () => {
    window.open("https://faq.sentro.ph/hc/en-us");
    handleClose();
    handleClose2();
    handleClose3();
  };

  const handleAnnouncements = () => {
    history.push(Path.ANNOUNCEMENTS);
    window.scrollTo(0, 0);
    handleClose();
    handleClose2();
    handleClose3();
  };

  function returnAvatar(word) {
    let newWord = word.split(/\W+/);
    if (newWord.length === 2) {
      return (
        newWord[0].charAt(0).toUpperCase() + newWord[1].charAt(0).toUpperCase()
      );
    } else {
      return word.charAt(0).toUpperCase();
    }
  }
  return (
    <AppBar className={classes.appBar}>
      <div className={classes.root}>
        <Box>
          <div style={{ padding: 18 }}>
            <a
              onClick={() => {
                if (data) {
                  history.push(Path.DASHBOARD);
                } else {
                  history.push(Path.HOME);
                }
                window.scrollTo(0, 0);
              }}
            >
              <img
                alt="sentro-light"
                src={sentro2}
                style={{ width: 120, height: "auto" }}
              />
            </a>
          </div>
        </Box>
        <Box className={classes.desktop}>
          {data ? (
            <div style={{ height: "auto" }}>
              {/* <SentroButton
                onClick={handleTranslate}
                id="dropdown_button"
                label={props.language ? props.language : "English"}
                startIcon={<TranslateIcon style={{ color: "#F97663" }} />}
                endIcon={<ArrowDropDown style={{ color: "#002C42" }} />}
                style={{
                  margin: 10,
                  textTransform: "capitalize",
                  color: "#002C42"
                }}
              /> */}
              <Popover
                anchorEl={anchorTranslate}
                open={Boolean(anchorTranslate)}
                onClose={handleCloseTranslate}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem
                  onClick={() => {
                    props.setLanguage("English");
                    localStorage.setItem("language", "English");
                    setAnchorTranslate(null);
                  }}
                  id="english"
                  style={{ padding: "10px 25px" }}
                >
                  <Typography style={{ fontSize: 14, color: "#2B2D33" }}>
                    English
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.setLanguage("Filipino");
                    localStorage.setItem("language", "Filipino");
                    setAnchorTranslate(null);
                  }}
                  id="english"
                  style={{ padding: "10px 25px" }}
                >
                  <Typography style={{ fontSize: 14, color: "#2B2D33" }}>
                    Filipino
                  </Typography>
                </MenuItem>
              </Popover>
              <SentroButton
                id="dropdown_button"
                label={path ? displayName : name}
                startIcon={
                  <Avatar
                    style={{
                      width: 25,
                      height: 25,
                      background: `linear-gradient(106.17deg, #35B0AB 4.56%, #226B80 154.44%)`,
                      fontSize: 10
                    }}
                  >
                    {returnAvatar(displayName)}
                  </Avatar>
                }
                endIcon={<ArrowDropDown style={{ color: "#002C42" }} />}
                style={{
                  margin: 10,
                  textTransform: "capitalize",
                  color: "#002C42"
                }}
                onClick={handleClickMenu}
              />

              <Popover
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                id="lock-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  id="account_settings_button"
                  style={{
                    height: "8vh"
                  }}
                  onClick={() => handleAccountSettings()}
                >
                  <SettingsIcon style={{ marginRight: 10, color: "#2B2D33" }} />
                  <Typography style={{ fontSize: 14, color: "#2B2D33" }}>
                    Account Settings
                  </Typography>
                </MenuItem>
                <MenuItem
                  id="faq_button"
                  style={{
                    height: "8vh"
                  }}
                  onClick={() => handleFAQ()}
                >
                  <HelpIcon style={{ marginRight: 10, color: "#2B2D33" }} />
                  <Typography style={{ fontSize: 14, color: "#2B2D33" }}>
                    FAQs
                  </Typography>
                </MenuItem>
                <MenuItem
                  id="announcement_button"
                  style={{
                    height: "8vh"
                  }}
                  onClick={() => handleAnnouncements()}
                >
                  <NotificationsActiveIcon
                    style={{ marginRight: 10, color: "#2B2D33" }}
                  />
                  <Typography style={{ fontSize: 14, color: "#2B2D33" }}>
                    Announcements
                  </Typography>
                </MenuItem>

                <MenuItem
                  id="logout_button"
                  style={{
                    height: "8vh"
                  }}
                  onClick={logout}
                >
                  <ExitToAppIcon
                    style={{ marginRight: 10, color: "#2B2D33" }}
                  />
                  <Typography style={{ fontSize: 14, color: "#2B2D33" }}>
                    Logout
                  </Typography>
                </MenuItem>
              </Popover>
            </div>
          ) : (
            <>
              <SentroButton
                id="login_button"
                label={"Login"}
                onClick={() => history.push(Path.LOGIN)}
                style={{
                  fontSize: 16,
                  width: 135,
                  height: 48,
                  margin: 10,
                  fontWeight: 600,
                  color: position >= -40 ? "white" : ""
                }}
              />
              <SentroButton
                id="signup_button"
                label={"Sign up"}
                onClick={() => history.push(Path.SIGN_UP)}
                style={{
                  fontSize: 16,
                  width: 135,
                  height: 48,
                  margin: 10,
                  fontWeight: 600
                }}
                variant={"contained"}
              />
              {/* <IconButton
                onClick={handleTranslate}
                color="primary"
                style={{ color: position >= -40 ? "white" : "", margin: 10 }}
              >
                <TranslateIcon />
              </IconButton> */}

              <Popover
                anchorEl={anchorTranslate}
                open={Boolean(anchorTranslate)}
                onClose={handleCloseTranslate}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem
                  onClick={() => {
                    localStorage.setItem("language", "English");
                    props.setLanguage("English");
                    setAnchorTranslate(null);
                  }}
                  id="english"
                  style={{ padding: "10px 25px" }}
                >
                  <Typography style={{ fontSize: 14, color: "#2B2D33" }}>
                    English
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    localStorage.setItem("language", "Filipino");
                    props.setLanguage("Filipino");
                    setAnchorTranslate(null);
                  }}
                  id="english"
                  style={{ padding: "10px 25px" }}
                >
                  <Typography style={{ fontSize: 14, color: "#2B2D33" }}>
                    Filipino
                  </Typography>
                </MenuItem>
              </Popover>
            </>
          )}
        </Box>

        <Box className={classes.mobile}>
          {data ? (
            <div>
              <SentroButton
                id="dropdown_button"
                label={displayName}
                startIcon={
                  <Avatar
                    style={{
                      width: 25,
                      height: 25,
                      background: `linear-gradient(106.17deg, #35B0AB 4.56%, #226B80 154.44%)`,
                      fontSize: 10
                    }}
                  >
                    {returnAvatar(displayName)}
                  </Avatar>
                }
                endIcon={<Menu style={{ color: "#002C42" }} />}
                style={{
                  margin: 10,
                  textTransform: "capitalize",
                  color: "#002C42"
                }}
                onClick={handleClickMenu3}
              />
              <Dialog
                fullWidth={true}
                fullScreen={true}
                className={classes.dialogMobile}
                style={{ zIndex: 1 }}
                // fullScreen={fullScreen}
                // open={true}
                open={open3}
                anchorEl={anchorEl3}
                onClose={handleClose3}
              >
                <div>
                  <SideMenu
                    language={props.language}
                    setLanguage={props.setLanguage}
                    onClick={handleClickMenu3}
                    setAnchorEl3={setAnchorEl3}
                  />
                  {/* <div className={classes.buttons}>
                    <SentroButton
                      label={"Account Settings"}
                      onClick={() => handleAccountSettings()}
                      fullWidth
                      style={{ margin: "10px 0" }}
                    />
                    <SentroButton
                      label={"FAQs"}
                      onClick={() => handleFAQ()}
                      fullWidth
                      style={{ margin: "10px 0" }}
                    />
                    <SentroButton
                      label={"Logout"}
                      onClick={logout}
                      style={{ margin: "10px 0" }}
                      fullWidth
                    />
                  </div> */}
                </div>
              </Dialog>
            </div>
          ) : (
            <>
              <ThemeProvider theme={sentroTheme}>
                <Button
                  id="signup_button"
                  className={classes.btnSignup}
                  onClick={() => history.push(Path.SIGN_UP)}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Sign up
                </Button>
              </ThemeProvider>
              {/* <IconButton
                onClick={handleTranslate}
                color="primary"
                style={{ padding: 0 }}
              >
                <TranslateIcon />
              </IconButton> */}
              <IconButton onClick={handleClickMenu4} color="primary">
                <MoreIcon />
              </IconButton>
              <Dialog
                style={{ zIndex: 1 }}
                className={classes.dialog}
                fullScreen={fullScreen}
                open={open4}
                onClose={handleClose4}
                aria-labelledby="responsive-dialog-title"
                anchorEl={anchorEl4}
              >
                {data ? (
                  <div
                    style={{
                      padding: 20,
                      marginTop: "20vh",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <div className={classes.buttons}>
                      <SentroButton
                        onClick={() => window.open(Path.FAQ)}
                        fullWidth
                        variant="contained"
                        label="FAQs"
                        style={{ margin: "10px 0" }}
                      />

                      <SentroButton
                        onClick={logout}
                        variant="contained"
                        fullWidth
                        label="Log Out"
                        style={{ margin: "10px 0" }}
                      />

                      <SentroButton
                        onClick={handleClose}
                        // variant="contained"
                        fullWidth
                        label="Close"
                        style={{ margin: "10px 0" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: "30vh",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <div className={classes.buttons}>
                      <SentroButton
                        label={"Login"}
                        onClick={() => history.push(Path.LOGIN)}
                        fullWidth
                        style={{ margin: "10px 0" }}
                      />
                      <SentroButton
                        label={"Sign up"}
                        onClick={() => history.push(Path.SIGN_UP)}
                        variant={"contained"}
                        style={{ margin: "10px 0" }}
                        fullWidth
                      />
                    </div>
                  </div>
                )}
              </Dialog>
            </>
          )}
        </Box>
      </div>
    </AppBar>
  );
}

export default SentroAppBar;
