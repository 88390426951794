

import React, {Suspense} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector, useDispatch } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Truncate from 'react-truncate';
import { ReactComponent as PenIcon } from '../../../../../assets/images/enterprise/pen.svg';
import {
  Typography,
} from "@material-ui/core";

import {
  Tabs,
  Tab,
} from "@material-ui/core";
import DashboardActions from '../../../redux/actions/dashboardActions';
import Pagination from '@material-ui/lab/Pagination';




const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function GoogleFontSelector(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mainColor = useSelector(state => state.enterprise.main.theme.palette.primary.main)
  const useStyles = makeStyles(theme => ({
    header: {
      position: "sticky",
      top: 0,
      width: 400,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
    },
    searchBar: {
      marginLeft: "auto",
      background: "white",
      width: "100%",
      borderRadius: "4px",
      color: "gray",
      height: 40,
      fontSize: 15,
      flexGrow: 0,
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1,
        marginTop: "1em",
        maxWidth: "100%",
        width: "100%"
      },
    },
    dropShadow: {
      display: "flex",
      background: "black",
      opacity: 0.4,
      position: "absolute",
      height: "100%",
      width: "100%",
      zIndex: 100,
      bottom: 0
    },
    list: {
      height: "calc(100vh - 120px)",
      width: 400,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
      "&::-webkit-scrollbar": {
        width: 6
      },
      "&::-webkit-scrollbar-track": {
        background: "#EEEEEE"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#B7B9C0",
        borderRadius: 8
      },
      "& span": {
        fontFamily: "inherit",
        fontWeight: 600
      }
    },
    itemDiv: {
      "& .itemDiv": {
        border: "1px solid #dee2e6 !important",
        borderRadius: 4,
        fontSize: 32,
        transition: "all 0.2s linear"
      },
      "& .useFont": {
        opacity: 0,
        color: theme.palette.primary.main,
        transition: "all 0.2s linear",
        fontWeight: "500"
      },
      cursor: "pointer",
      "&:hover": {
        "& .itemDiv": {
          border: `1px solid ${theme.palette.primary.main} !important`,
        },
        "& .useFont": {
          opacity: 1,
        }
      }
    },
    changeFontBtn: {
      cursor: "pointer"
    }
  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [text, setText] = React.useState("");
  const [tab, setTab] = React.useState(0);
  const fonts = useSelector(state => state.enterprise.main.dashboard.fonts)
  const [filteredFonts, setFilteredFonts] = React.useState([])
  const category = tab ? "sans-serif": "serif";
  React.useEffect(() => {
    dispatch(DashboardActions.getFonts("sans-serif"))
  }, [])


  const changeCategory = (val) => {
    setTab(val)
    dispatch(DashboardActions.getFonts(category))
  }

  const [count, setCount] = React.useState(10);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    setPage(1);
    setCount(Math.ceil(filteredFonts.length / 10))
  }, [tab, filteredFonts])

  React.useEffect(() => {
    let vals = fonts.filter(item => search ? 
      item.family.toLowerCase().includes(search.toLowerCase()): 
      item
    )
    // vals = vals.filter(item => item.category === category)
    setFilteredFonts(vals)
  }, [search, fonts])

  const onChange = (item) => {
    console.log(item);
    if (props.onChange) props.onChange(item);
    setOpen(false);
  }


  return (
    <div>
      <div className={`d-flex align-items-center ml-auto font-picker-button ${
        classes.changeFontBtn
      }`} onClick={() => setOpen(true)}>
        <PenIcon className="mr-2" fill={theme.palette.primary.main} />
        <Typography className="mb-0" type="p" color="primary"
          style={{ fontSize: 12 }}>
          <strong>Change font</strong>
        </Typography>
      </div>
      <Drawer className="font-picker-drawer" classes={{ paper: "p-4"}} anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className={classes.header}>
          <div className="d-flex align-items-center">
            <h5><strong>Google Fonts library</strong></h5>
            <IconButton className="ml-auto" color="primary" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <OutlinedInput
              className={classes.searchBar}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
              endAdornment={
                search ? 
                <InputAdornment position="end">
                    <IconButton
                    style={{ marginRight: "-0.5em"}}
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setSearch("")}
                    >
                      <CloseIcon />
                    </IconButton>
                </InputAdornment>
                : ""
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
              'aria-label': 'weight',
              }}
              labelWidth={0}
              margin="dense"
              placeholder="Search font"
            />
            <OutlinedInput
              className={`${classes.searchBar} my-3`}
              value={text}
              onChange={(event) => setText(event.target.value)}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
              'aria-label': 'weight',
              }}
              labelWidth={0}
              margin="dense"
              placeholder="Type something to preview text"
            />
          </div>
          <Tabs
            className={classes.tabs}
            value={tab}
            onChange={(q, val) => changeCategory(val)}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Sans serif" {...a11yProps(0)} />
            <Tab label="Serif" {...a11yProps(0)} />
          </Tabs>
        </div>
        <div className={`${classes.list} overflow-auto`}>
          <div className="pr-3">
            {filteredFonts.slice(10*(page-1), 10*page )
              .map((item, index) => {
                return (
                  <Suspense fallback={null} key={index}>
                    <div className={`my-4 ${classes.itemDiv} font-picker-item`}
                      onClick={() => onChange(item)}>
                      <link href={item.css_url} rel="stylesheet"></link>
                      <div className="d-flex">
                        <p className="text-secondary mb-0" style={{ fontSize: 12 }}>{item.family}</p>
                        <p className={`useFont ml-auto mb-0`} style={{ 
                          fontSize: 12,
                        }}>Use font</p>
                      </div>
                      <div className="itemDiv">
                        <div className="mx-2" style={{
                          fontFamily: `'${item.family}', sans-serif`
                        }}>
                          <Truncate lines={1} ellipsis={<span>...</span>}>
                            {text || "The quick brown fox jumps over the lazy dog"}
                          </Truncate>
                        </div>
                      </div>
                    </div>
                  </Suspense>
                )
              }
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Pagination className="mt-3" color="primary" count={count} page={page} 
            onChange={(e, val) => setPage(val)} size={is_mobile ? "small": ""}/>
        </div>
      </Drawer>
    </div>
  );
}
