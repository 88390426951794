import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ACL from "../common/utilities/ACL"
import Action from "./Redux/Actions"
import AdminDashboardLayout from "./layout"
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import apiConfig from "../../common/apiConfig";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import history from "../../store/history";
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import { stringify_get_params } from "../common/utilities/DateFilter"
import AdminTable from './components/adminTable'

import ReportsPopover from "./components/reports_popover";

const headCells = [
	{ id: 'first_name', numeric: false, disablePadding: true, label: 'Name' },
	{ id: 'email', numeric: false, disablePadding: false, label: 'Email' },
	{ id: 'stores', numeric: false, disablePadding: false, label: 'Stores' },
	{ id: 'id', numeric: false, disablePadding: false, label: 'Member Since' },
	{ id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

const menu = (row, is_open, menuAnchor, closeMenu, setDialogState) => (
	<Menu
		id="simple-menu"
		anchorEl={menuAnchor}
		keepMounted
		open={is_open && menuAnchor ? true:false}
		onClose={closeMenu}
	>
		<MenuItem onClick={() => {
			history.push({
				pathname: `/admin/dashboard/signups/${row.id}`, 
			})
		}}>
			View Details
		</MenuItem>
		<MenuItem 
			onClick={() => {
				setDialogState(true, "delete")
				closeMenu()
			}}
		>
			Delete
		</MenuItem>
	</Menu>
)

const mainStyles = theme => ({
	paper: {
		overflow: "hidden",
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(1),
		},
	},
	card: {
		backgroundColor: "white",
		padding: 0
	},
	cardHeader: {
		fontSize: "28px",
		marginBottom: "0.5em",
		textAlign: "left",
		color: "#F97663",
		fontWeight: 600
	},
	textDivider: {
		fontSize: "16px",
		padding: "1.5em 0",
		marginBottom: 0
	},
	miniCardHeader: {
		fontSize: "16px",
		textAlign: 'left',
		color: "#F97663",
		paddingBottom: "0.5em",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
		},
	},
	miniCardBody: {
		fontSize: "28px",
		weight: "bold",
		textAlign: 'left',
		color: theme.palette.text.primary,
	},
	lineChart: {
		paddingLeft: "auto",
		paddingRight: "auto",
		paddingTop: "2em",
		position: "relative",
		height: "400px",
		width: "100%",
		display: "block"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	excelExportButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		border: "solid 1px #F97663",
		background: " #F97663",
		borderRadius: 4,
		marginRight: 10,
		color: "white",
		transition: "all 0.3s linear",
		"&:hover": {
			background: "#f96a63"
		},
		"&:focus": {
			outline: 0
		},
		boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
	},
	headerActionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		},
	},
	searchBar: {
		background: "white",
		maxWidth: "240px",
		borderRadius: "4px",
		color: "gray",
		fontSize: 12,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "100%",
			width: "100%"
		},
	},
	alertSuccess: {
		padding: "0.75em 1em",
		border: "solid 1px #1DD28B",
		borderLeft: "solid 5px #1DD28B",
		borderRadius: 4,
		background: "#1dd28b14"
	},
	primaryColor: {
		color: theme.palette.text.primary
	},
	dFlex: {
		display: "flex"
	},

	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
	moreHorizIcon: {
		color: "gray",
		cursor: "pointer"
	},
	pageHeaderButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		marginRight: 20
	},
});

class Signups extends React.Component {
	constructor(props) {
		super(props);
		this.searchTimeout = 0; // trigger fetch after typing inactivity
		this.state = {
			targetRow: null,
			// menu
			menuAnchor: null,
			// dialog
			dialogTitleText: '',
			dialogStat: false,
			dialogType: "delete",
			dialogBody: '',

			// filters
			date_filter_query: "",
			order: "",
			orderBy: "",
			search_query: "",
			page: 0,
			rowsPerPage: 5,
			
			// menu popover
			anchorEl: null,
		};
		this.onSearch = this.onSearch.bind(this)
		this.setDialogState = this.setDialogState.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.closeMenu = this.closeMenu.bind(this)
		this.fetch = this.fetch.bind(this)
		this.onSortRequest = this.onSortRequest.bind(this)
		this.constructFilterQuery = this.constructFilterQuery.bind(this)
	}
	
	onSearch(event) {
		const search_query = event.target.value;

		if(this.searchTimeout) clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.setState({
				search_query: `&q=${search_query}`,
				page: 0
			}, () => {
				console.log(this.constructFilterQuery())
				this.fetch(this.constructFilterQuery());
			})
		}, 500);
	}

	setDialogState(state, dialogType = "edit") {
		this.setState({
			dialogStat : state,
			dialogType: state ? dialogType: this.state.dialogType
		})
	}

	handleDelete = async () => {
		const { dispatch } = this.props
		const { targetRow } = this.state
		const response = await dispatch(Action.userDetailMethod("delete", targetRow.id, {}))
		if (response.status == 200 || response.status == 204) {
			this.fetch(this.constructFilterQuery());
			this.setState({
				dialogStat: false,
				alert: "User successfully deleted"
			});
		} else {
			this.setState({
				dialogStat: true,
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}

	fetch = (query_filters, is_csv=false, filename="") => {
		// has different response handling if csv 
		// should not change userList redux state
		const { dispatch } = this.props
		dispatch(Action.fetchUsers(query_filters, is_csv, filename))
	}

	handleOpenPopover = (event) => {
		this.props.dispatch(Action.fetchLatestReport("Users"))
		this.setState({
			...this.state,
			anchorEl: event.currentTarget
		})
	}

	handleClosePopover = () => {
		this.setState({
			...this.state,
			anchorEl: null
		})
	}

	downloadCSV = () => {
		const { dispatch } = this.props
		dispatch(Action.fetchUsers(
			this.constructFilterQuery()+"&csv_download=True&filename=Signups", 
			true
		))
	}

	componentDidMount = async() => {
		const { adminDashboard } = this.props
		const date_filter_query = stringify_get_params(adminDashboard.dateFilter)
		this.setState({
			date_filter_query
		}, () => {
			console.log(this.constructFilterQuery());
			this.fetch(this.constructFilterQuery());
		})
	}

	setMenu(event, targetRow){
		this.setState({
			menuAnchor: event.target,
			targetRow
		})
	}

	closeMenu(){
		this.setState({
			menuAnchor: null
		})
	}

	constructFilterQuery(){
		return (this.state.date_filter_query + 
			"&order=" + this.state.order + 
			"&orderBy=" + this.state.orderBy + 
			"&page=" + this.state.page +
			"&rowsPerPage=" + this.state.rowsPerPage +
			this.state.search_query)
	}
	
	onDateFilterCallback = (filter) => {
		const date_filter_query = stringify_get_params(filter)
		this.setState({
			date_filter_query,
			page: 0
		}, () => {
			console.log(this.constructFilterQuery());
			// this.fetch(date_filter_query);
			this.fetch(this.constructFilterQuery());
		})
	}

	onSortRequest(order, orderBy, page, rowsPerPage){
		this.setState({
			order,
			orderBy,
			page,
			rowsPerPage
		}, () => {
			console.log(this.constructFilterQuery())
			this.fetch(this.constructFilterQuery());
		})
	}
	
	render (){
		const {
			list,
			dialogStat,
			dialogType,
			dialogTitleText,
			dialogBody,
			alert,
			page,
			rowsPerPage
		} = this.state
		const { classes, adminDashboard } = this.props

		const dialogTitle = () => {
			if (dialogType == "delete") {
				return "Are you sure you want to delete this record?"
			} else if (dialogType==="info") {
				return dialogTitleText
			}
		}
		const dialogContent = () => {
			if (["delete"].includes(dialogType)) {
				return "Click submit to confirm"
			} else if (dialogType==="info") {
				return dialogBody
			}
		}
		const actionButton = () => {
			if (dialogType==="delete") {
				return (
					<Button
						id="delete_button"
						onClick={(event) => {
							this.handleDelete()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Delete
					</Button>
				)
			}
		}
		return (
			<AdminDashboardLayout onDateFilterCallback={this.onDateFilterCallback}>
				<ACL roles={["admin", "superuser"]} redirect_to="/login">
					<ReportsPopover 
						reports={adminDashboard.reports.data} 
						anchorEl={this.state.anchorEl}
						handleReportPopoverClose={this.handleClosePopover}
						onButtonClick={this.downloadCSV}
						loading={adminDashboard.reports.loading}
					/>
					<Dialog
						open={dialogStat}
						onClose={(event) => this.setDialogState(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						maxWidth="xs"
					>	
						<div className={classes.modalDiv}>
							<div className={classes.modalTile}>
								{dialogTitle()}
							</div>
							<div className={classes.modalBody}>
								{dialogContent()}
							</div>
							<div className={classes.modalActionsWrapper}>
								<Button
									id="action_button"
									variant="outlined"
									onClick={(event) => this.setDialogState(false)} 
									color="primary"
									className={classes.modalActions}
								>
									{
										dialogType == "info" ?
										"Close"
										:
										"Cancel"
									}
								</Button>
								{actionButton()}
							</div>
						</div>
					</Dialog>
					<div style={{
						display: "flex", 
						flexWrap: "wrap",
						alignItems: "center",
						marginBottom: "1.5em",
						padding: "0 1.5em"
					}}>
						<div className={classes.pageTitle}>
							Signups
						</div>
						<div className={classes.headerActionsWrapper}>
							{
								adminDashboard.loading && 
								<CircularProgress color="secondary" style={{height: 25, width: 25, color: "#f97663", marginRight: 20}}/>
							}
							<Button
								id="add_user_button" 
								variant="contained" 
								color="primary" 
								disableElevation 
								className={classes.pageHeaderButton}
								onClick={(event) => this.handleOpenPopover(event)}
							>
								Downloads
							</Button>
							<OutlinedInput
								className={classes.searchBar}
								id="outlined-adornment-weight"
								onChange={(event) => this.onSearch(event)}
								startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
									'aria-label': 'weight',
								}}
								labelWidth={0}
								margin="dense"
								placeholder="Type here to search"
							/>
						</div>
					</div>
					<div style={{ padding: "0 1.5em 1em"}}>
						{
							alert ?
							<p className={classes.alertSuccess}>{alert}</p>
							:
							""
						}
						<Paper className={classes.card}>
							<AdminTable 
								loading={adminDashboard.loading}
								headCells={headCells}
								body={<TableBody>
										{adminDashboard.usersList.map((row, index) => {
											return (
												<TableRow
													key={index}
													hover
													role="checkbox"
													tabIndex={-1}
												>
													<TableCell component="th" scope="row"
														style={{ marginLeft: "1em"}}
													>	
														{`${row.first_name} ${row.last_name}`}
													</TableCell>
													<TableCell>{row.email}</TableCell>
													<TableCell>
														{row.stores.map((shop, index) => (
															<a key={index} href={`${apiConfig.localHost}shop/${shop.site}`} target="_blank">
																{shop.name}
															</a>
														))}
													</TableCell>
													<TableCell>
														{row.created_on}
													</TableCell>
													<ACL roles={['superuser']} child="deletebtn">
														<TableCell align="left">
															{
																menu(
																	row, 
																	this.state.targetRow ? row.id === this.state.targetRow.id:false,
																	this.state.menuAnchor, 
																	this.closeMenu,
																	this.setDialogState,
																)
															}
															<MoreHorizIcon onClick={(event) => {this.setMenu(event, row)}} className={classes.moreHorizIcon}/>
														</TableCell>
													</ACL>
												</TableRow>
											);
										})}
									</TableBody>
								}
								length={adminDashboard.listLength}
								page={page}
								rowsPerPage={rowsPerPage}
								onSortRequest={this.onSortRequest}
							/>
						</Paper>
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(mainStyles)(Signups))
