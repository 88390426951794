import React from "react";
import {
  Dialog,
  Slide,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
} from "@material-ui/core";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ChangeCategory(props) {
  const [category, setCategory] = React.useState("_category");
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={props.action.open}
      TransitionComponent={Transition}
    >
      <div style={{ padding: 32 }}>
        <div style={{ fontWeight: 600, fontSize: 18, color: "#2b2d32" }}>
          Change category
        </div>
        <FormControl
          variant="outlined"
          className="my-4"
          color="secondary"
          margin="dense"
          fullWidth
        >
          <InputLabel id="demo-simple-select-filled-label">
            Select category
          </InputLabel>
          <Select
            id="category"
            className="bg-white"
            label="Select category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {props.product_categories.map((item, key) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="d-flex">
          <Button
            variant="outlined"
            color="primary"
            className="mr-2"
            fullWidth
            onClick={() =>
              props.setAction({
                ...props.action,
                open: false,
                value: "actions",
              })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            onClick={() => props.handleChangeCategory(category)}
          >
            Move to category
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
