import React from "react";
import banner from "../../assets/images/sentro-signup.jpg";
import logo from "../../assets/images/sentro_vertical.png";
import { ArrowBack } from "@material-ui/icons";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import RegisterForm from "./registerComponents/registerForm";
import SentroAlert from "../common/alert";
import Text from "../../common/text";
import Path from "../../Paths";
import history from "../../store/history";
import SentroButton from "../common/sentroButton";
import SentroLoading from "../common/loading";
import { useSelector } from "react-redux";
import DocumentMeta from "react-document-meta";
const Register = props => {
  const meta = {
    title: "Sentro | Sign-up for an account",
    description: `Signing up with Sentro for free!`,
    canonical: "https://sentro.ph/sign_up",
    meta: {
      charset: "utf-8"
    }
  };
  let param = props.location.search.split("&");
  let utms = {
    utm_source: param[1] ? param[1].replace("utm_source=", "") : "",
    utm_medium: param[2] ? param[2].replace("utm_medium=", "") : "",
    utm_campaign: param[3] ? param[3].replace("utm_campaign=", "") : "",
    utm_content: param[4] ? param[4].replace("utm_content=", "") : ""
  };
  React.useEffect(() => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("CURRENT_USER");
    sessionStorage.removeItem("TOKEN");
    sessionStorage.removeItem("CURRENT_USER");
  }, []);
  const RegisterStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60%",
      height: "100vh",
      backgroundImage: `url(${banner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      [theme.breakpoints.up("xl")]: {
        width: "100%"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    register: {
      height: "100vh",
      overflow: "auto",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    box1: {
      marginTop: "35vh",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },

    box2: {
      padding: "3vh 2vw 0 ",

      [theme.breakpoints.down("sm")]: {
        padding: "3vh 12vw"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "3vh 5vw"
      }
    }
  }));

  const classes = RegisterStyles();
  const loading = useSelector(state => state.signUp.loading);

  return (
    <DocumentMeta {...meta}>
      <div className={classes.root}>
        <SentroLoading open={loading} />
        <Box className={classes.banner}>
          {/* <Container>
            <Box className={classes.box1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img src={logo} style={{ width: 150 }} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    marginTop: "2vh"
                  }}
                  textType="h2"
                  content="Create your online shop here."
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                    color: "#fff",
                    position: "absolute",
                    bottom: 25
                  }}
                >
                  This works best in the latest Google desktop browser.
                </Typography>
              </div>
            </Box>
          </Container> */}
        </Box>
        <Box className={classes.register}>
          <Container>
            <Box style={{ marginTop: "2vh" }}>
              <SentroButton
                color="primary"
                onClick={() => history.push(Path.HOME)}
                startIcon={<ArrowBack color="primary" />}
                style={{ fontSize: 14 }}
                label={"BACK"}
              />
            </Box>
            <Box className={classes.box2}>
              <Text
                style={{ color: "#002C42" }}
                textType="h3"
                content="Create an account"
              />

              <Box style={{ marginTop: "4vh" }}>
                <SentroAlert />
              </Box>

              <Box style={{ marginTop: "3vh" }}>
                <RegisterForm utms={utms} />
              </Box>
              <Box
                style={{
                  marginTop: "3vh",
                  textAlign: "center",
                  marginBottom: "4vh"
                }}
              >
                <Typography style={{ fontSize: "13px" }}>
                  Already have an account?{" "}
                  <a
                    id="login_link"
                    style={{
                      color: "#F97663",
                      fontSize: 14,
                      fontWeight: "bold"
                    }}
                    onClick={() => props.history.push(Path.LOGIN)}
                    variant="link"
                  >
                    Login
                  </a>
                </Typography>
              </Box>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                    color: "#2b2d33",
                    padding: "3vh 3vh 1vh"
                  }}
                >
                  Have some concerns? Feel free to reach out to us at
                  <a
                    href="mailto:support@sentro.ph"
                    style={{
                      color: "#F97663",
                      fontSize: 13,
                      cursor: "pointer",
                      fontWeight: 600,
                      marginLeft: 10
                    }}
                  >
                    support@sentro.ph
                  </a>
                </Typography>
              </div>
            </Box>
          </Container>
        </Box>
      </div>
    </DocumentMeta>
  );
};
export default Register;
