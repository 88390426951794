import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import history from "../../store/history";
import Path from "../../Paths";
import apiConfig from "../../common/apiConfig";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ExpandMore } from "@material-ui/icons";
import SentroLoading from "../common/loading";
import DashboardActions from "./dashboardActions";
import { animateScroll as scroll } from "react-scroll";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import fbPixel from "../../assets/images/pixel.png";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  OutlinedInput
} from "@material-ui/core";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = theme => ({
  root: {
    padding: "2em",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  fbMessengerIcon: {
    color: "#0D3D76",
    fontSize: 100
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  content: {
    padding: "0 1.5em",
    "& > strong": {
      color: "#002C42"
    },
    "& > p": {
      marginTop: 10
    }
  }
});

class Card extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <img src={fbPixel} style={{ width: 60, height: "auto" }} />
        <div className={classes.content}>
          <strong>Track visitor activity on your Sentro shop</strong>
          <p style={{ fontSize: 12 }}>
            If you are placing ads on Facebook for your Sentro shop, FB Pixel
            will help you track and optimize your ads, build targeted audiences
            for future ads, and more.{" "}
          </p>
        </div>
        <Button
          className={classes.button}
          id="view"
          color="primary"
          disableElevation
          className={classes.button}
          variant="outlined"
          disableElevation={true}
          onClick={() => {
            // const link =  data.variants ? `/marketplace/${data.id}/packages` : `marketplace/${data.id}/checkout`
            history.push(Path.FB_PIXEL_SETUP);
          }}
        >
          Learn More
        </Button>
      </div>
    );
  }
}
const FBPixelCard = withStyles(useStyles)(Card);
export { FBPixelCard };
const useStylesInstructionCard = theme => ({
  root: {
    padding: "2em",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    color: "#F97663",
    fontSize: 45
  },
  iconGreen: {
    color: "#57B19F",
    fontSize: 45
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  content: {
    padding: "0 1.5em",
    "& > strong": {
      color: "#002C42"
    },
    "& > p": {
      marginTop: 5
    }
  },
  pinkLink: {
    color: "#F97663"
  },
  footerText: {
    color: "#909196",
    fontSize: 12,
    paddingTop: 5
  }
});

class InstructionCardComponent extends React.Component {
  render() {
    const { classes, onClick, store } = this.props;
    return (
      <div className={classes.root}>
        {store.length ? (
          store[0] ? (
            store[0].fb_pixel_id ? (
              <>
                <CheckCircleIcon className={classes.iconGreen} />
                <div className={classes.content}>
                  <strong>
                    You have currently linked to a Facebook Page to your Sentro
                    Shop
                  </strong>
                  <p>
                    <a
                      href={`${apiConfig.localHost}shop/${store[0].site}`}
                      target="_blank"
                      className={classes.pinkLink}
                    >
                      Visit your shop
                    </a>{" "}
                    to check if FB Pixel is working.
                  </p>
                  <p className={classes.footerText}>
                    Having problems? Kindly check the steps below.
                  </p>
                </div>
                <Button
                  className={classes.button}
                  id="view"
                  color="primary"
                  disableElevation
                  className={classes.button}
                  variant="outlined"
                  disableElevation={true}
                  onClick={() => {
                    onClick();
                  }}
                >
                  Update
                </Button>
              </>
            ) : (
              <>
                <AddCircleIcon className={classes.icon} />
                <div className={classes.content}>
                  <strong>
                    Enable your FB Pixel to your Sentro Online Shop
                  </strong>
                  <p>Simply follow the steps below</p>
                </div>
                <Button
                  className={classes.button}
                  id="view"
                  color="primary"
                  disableElevation
                  className={classes.button}
                  variant="outlined"
                  disableElevation={true}
                  onClick={() => {
                    onClick();
                  }}
                >
                  Proceed
                </Button>
              </>
            )
          ) : (
            ``
          )
        ) : (
          <>
            <AddCircleIcon className={classes.icon} />
            <div className={classes.content}>
              <strong>Create your Sentro Online Shop First</strong>
              {/* <p>Simply follow the steps below</p> */}
            </div>
            <Button
              className={classes.button}
              id="view"
              color="primary"
              disableElevation
              className={classes.button}
              variant="outlined"
              disableElevation={true}
              onClick={() => {
                // onClick()
                history.push("/dashboard");
              }}
            >
              Dashboard
            </Button>
          </>
        )}
      </div>
    );
  }
}

const mapStateToPropsInstruction = state => ({ dashboard }) => ({
  store: dashboard.stores
});
const InstructionCard = connect(mapStateToPropsInstruction)(
  withStyles(useStylesInstructionCard)(InstructionCardComponent)
);

const useStylesSetup = theme => ({
  header: {
    padding: "1.5em 3em",
    marginTop: 68
  },
  backDivWrapper: {
    display: "flex",
    "& > span": {
      marginLeft: "1em",
      fontWeight: 600
    },
    color: "#F97663 !important",
    alignItems: "center",
    cursor: "pointer",
    width: 100
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
    maxWidth: "80em",
    margin: "auto"
  },
  fbMessengerIcon: {
    color: "#0D3D76",
    margin: "10px 50px",
    maxHeight: 85
  },
  headerDetails: {
    paddingRight: 100,
    "& > strong": {
      fontSize: 30,
      color: "#2B2D33"
    },
    "& > p": {
      marginTop: 10
    }
  },
  mainDiv: {
    display: "block",
    padding: "1em",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  root: {
    margin: "auto",
    maxWidth: "60em",
    paddingBottom: "3em"
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  expansionTitle: {
    color: "#002C42",
    fontSize: 16,
    padding: "0 1em"
  },
  expansionForm: {
    background: "#F1F1F1",
    padding: "2em 6em",
    display: "block",
    "& .expansionForm": {
      display: "flex",
      alignItems: "center"
    },
    "& img": {
      margin: "1em 0"
    }
  },
  inputFieldButton: {
    marginLeft: "1em",
    height: 40
  },
  inputField: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    height: 40,
    backgroundColor: "white"
  },
  howToGetFBPage: {
    margin: "1.5em 0"
  },
  step2Details: {
    backgroundColor: "white",
    padding: "1.5em"
  },
  imageFluid: {
    width: "100%",
    height: "auto"
  },
  instructionText: {
    marginTop: "3em"
  }
});

class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section1: false,
      section2: false,
      fbPixelId: "",

      alertStat: false,
      alertType: "success"
    };
    this.expansionChange = this.expansionChange.bind(this);
    this.cardOnClick = this.cardOnClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.unlink = this.unlink.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    const stores = await dispatch(DashboardActions.getStores());
    if (stores[0]) {
      this.setState({
        fbPixelId: stores[0].fb_pixel_id
      });
    }
    scroll.scrollTo(0);
  };

  expansionChange(section, stat) {
    console.log(section, stat);
    this.setState({
      [section]: stat
    });
  }

  cardOnClick() {
    this.setState({
      section1: true,
      section2: true
    });
  }

  onSubmit = async () => {
    const { dispatch, stores } = this.props;
    const store = await dispatch(
      DashboardActions.saveFBPixelID(stores[0].site, this.state.fbPixelId)
    );
    this.setState({
      alertStat: true,
      alertType: "success"
    });
    scroll.scrollTo(0);
  };

  unlink = async () => {
    const { dispatch, stores } = this.props;
    const store = await dispatch(
      DashboardActions.saveFBPixelID(stores[0].site, "")
    );
    this.setState({
      fbPixelId: "",
      alertStat: true,
      alertType: "warning"
    });
    scroll.scrollTo(0);
  };

  handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      alertStat: false
    });
  };

  render() {
    const { classes, loading, stores } = this.props;
    return (
      <div
        className={classes.mainDiv}
        style={{ backgroundColor: "white", minHeight: `calc(100vh - 68px)` }}
      >
        <Snackbar
          open={this.state.alertStat && this.state.alertType === "success"}
          autoHideDuration={2000}
          onClose={this.handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleCloseAlert}
            severity="success"
            icon={<InfoOutlinedIcon fontSize="inherit" />}
          >
            Your Facebook Pixel ID is now linked to your Sentro shop. Visit your
            shop to check it out.
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.alertStat && this.state.alertType === "warning"}
          autoHideDuration={2000}
          onClose={this.handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleCloseAlert}
            severity="warning"
            icon={<InfoOutlinedIcon fontSize="inherit" />}
          >
            You have unlinked a Facebook Pixel ID from your Sentro shop.
          </Alert>
        </Snackbar>
        <SentroLoading open={loading} />
        <div className={classes.header}>
          <div
            className={classes.backDivWrapper}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
            <span>BACK</span>
          </div>
          <div className={classes.headerContent}>
            <img src={fbPixel} className={classes.fbMessengerIcon} alt="" />
            <div className={classes.headerDetails}>
              <strong>Add FB Pixel to your Sentro Online Shop</strong>
              <p style={{ fontSize: 16 }}>
                If you are placing ads on Facebook for your Sentro shop,
                Facebook Pixel will help you track its conversion, optimize it,
                build targeted audiences for future ads, and remarket to people
                who have already taken some kind of action on your website.
              </p>
            </div>
          </div>
        </div>
        <div className={classes.root}>
          <InstructionCard onClick={this.cardOnClick} />
          <ExpansionPanel
            expanded={this.state.section1}
            onChange={(obj, stat) => this.expansionChange("section1", stat)}
          >
            <ExpansionPanelSummary
              style={{
                borderBottom: "1px solid #D1D5DD",
                backgroundColor: "#FAFAFA"
              }}
              expandIcon={<ExpandMore />}
            >
              <strong className={classes.expansionTitle}>
                Step 1: Link your Facebook Pixel ID to your Sentro Shop
              </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={classes.expansionForm}
              style={{ borderBottom: "1px solid #D1D5DD" }}
            >
              <strong>Facebook Pixel ID</strong>
              <div className="expansionForm">
                <OutlinedInput
                  className={classes.inputField}
                  value={this.state.fbPixelId}
                  type="number"
                  onChange={event => {
                    this.setState({
                      fbPixelId: event.target.value
                    });
                  }}
                />
                <Button
                  className={classes.inputFieldButton}
                  color="primary"
                  disableElevation
                  variant="contained"
                  disabled={!this.state.fbPixelId || !stores.length}
                  disableElevation={true}
                  onClick={this.onSubmit}
                >
                  Submit
                </Button>
                <Button
                  // className={classes.button}
                  disableElevation
                  className={classes.button}
                  style={{ marginLeft: 5 }}
                  disableElevation={true}
                  disabled={!stores.length}
                  onClick={() => {
                    this.unlink();
                  }}
                >
                  Unlink
                </Button>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div style={{ position: "relative" }}>
            <p className={classes.instructionText}>
              1. Go to your FB page. Under the “Manage Page” section, select “Ad
              Center”. Once Ad Center page loads, click “Visit Ads Manager.” It
              will redirect you to your FB Ads Manager account.
            </p>
            <img
              className={classes.imageFluid}
              src="https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/FB_Pixel_1+%281%29.gif"
              alt="1"
            ></img>
            <p className={classes.instructionText}>
              2. In your FB Ads Manager page, select the Ad account to manage.
              After it loads, click the Business tools icon then select the
              Events Manager shortcut
            </p>
            <img
              className={classes.imageFluid}
              src="https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/FB_Pixel_2+%281%29.gif"
              alt="2"
            ></img>
            <p className={classes.instructionText}>
              3. Create a new data source. When prompted, select Web as the
              source of data and Facebook Pixel as connection method. Then
              indicate a name for your Facebook Pixel.{" "}
            </p>
            <img
              className={classes.imageFluid}
              src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_3.gif"
              alt="3"
            ></img>
            <p className={classes.instructionText}>
              4. Close the next pop-up window of the setup. Copy the ID of the
              Facebook Pixel you just created.
            </p>
            <img
              className={classes.imageFluid}
              src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_4.gif"
              alt="4"
            ></img>
            <p className={classes.instructionText}>
              5. Login to your Sentro account and paste your Facebook Pixel ID
              here.
            </p>
            <img
              className={classes.imageFluid}
              src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_5.gif"
              alt="5"
            ></img>
          </div>
          <ExpansionPanel
            style={{ boxShadow: "none" }}
            expanded={this.state.section2}
            onChange={(obj, stat) => this.expansionChange("section2", stat)}
          >
            <ExpansionPanelSummary
              style={{
                borderBottom: "1px solid #D1D5DD",
                backgroundColor: "#FAFAFA",
                boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2), 
                  0px 1px 1px 0px rgba(0,0,0,0.14), 
                  0px 1px 3px 0px rgba(0,0,0,0.12)`
              }}
              expandIcon={<ExpandMore />}
            >
              <strong className={classes.expansionTitle}>
                Step 2: Connect your Sentro Shop to your Facebook Pixel
              </strong>
            </ExpansionPanelSummary>
            <div style={{ color: "rgba(0,0,0,0.65)" }}>
              <p className={classes.instructionText}>
                1. Go back to your Facebook Ad Manager and refresh the page. If
                linking to Sentro Shop is successful, the page should look like
                the one below,
              </p>
              <img
                className={classes.imageFluid}
                src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_6.gif"
                alt="6"
              ></img>
              <p className={classes.instructionText}>
                2. Click Add Events, then select From the Pixel. In the pop-up
                window that will appear, click the Open Event Setup Tool button.
                In the next window, type your Sentro Shop link and click Open
                Website. It will redirect you to your Sentro Shop.{" "}
              </p>
              <img
                className={classes.imageFluid}
                src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_7.gif"
                alt="7"
              ></img>
              <p className={classes.instructionText}>
                3. When your Sentro shop is opened, a Setup Event Tool will be
                shown on the top left. Click which parts of your online shop you
                want to track (i.e. Track New Button or Track a URL) and
                identify the type of event or behavior you want to associate to
                that part. Once done, click Finish Setup, and you will be
                returned to your FB Ad Manager account.
              </p>
              <img
                className={classes.imageFluid}
                src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_8.gif"
                alt="8"
              ></img>
            </div>
          </ExpansionPanel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ dashboard }) => ({
  loading: dashboard.loading,
  stores: dashboard.stores
});
const FBPixel = connect(mapStateToProps)(withStyles(useStylesSetup)(Setup));
export default FBPixel;
