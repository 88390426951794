import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: 0,
  },
  thumb: {
    background: '#C8C7C2',
    width: 12,
    height: 12,
  },
  track: {
    background: '#7E9CB8',
    height: 5,
  },
  rail: {
    height: 5,
    background: '#0F3344',
  },
});

export default function RangeSlider(props) {
  const classes = useStyles();

  function handleChange(event, newValue) {
    props.onChange(newValue);
  }

  return (
    <div>
      <Slider
        value={props.value}
        min={0}
        step={1}
        max={25}
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          track: classes.track,
          rail: classes.rail,
        }}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
    </div>
  );
}
