import React from "react";
import {
  FormControl,
  Modal,
  Typography,
  FormControlLabel,
  Select,
  FormGroup,
  Checkbox,
  TextField,
  Button,
  Link,
  MenuItem,
  Dialog,
  AppBar,
  IconButton
} from "@material-ui/core";
import { Radio } from "antd";
import sentroLogo from "../../../../../../assets/images/badge.png";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./kycStyles";
import { skipSurvey, saveKYCSurvey } from "../websiteBuilderActions";
import DateDropdown from "../../../../../dashboard/kycSurvey/dateDropdownField";
import { ADDRESS } from "../../../../template/components/constants/address";
import CloseIcon from "@material-ui/icons/Close";
const KYCQuestionsMobile = props => {
  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      display: isKYC_successful ? "flex" : "",
      alignItems: isKYC_successful ? "center" : "",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }
  const isKYC_successful = useSelector(
    state => state.mobileDashboard.kyc_success
  );
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [pageTwo, setPageTwo] = React.useState(false);
  const [pageThree, setPageThree] = React.useState(false);
  const [sameAddress, setSameAddress] = React.useState(false);
  const [kycAnswer, setKycAnswer] = React.useState({
    birthdate: "",
    gender: "not_specified",
    civil_status: "",
    industry: "Please choose one...",
    selling_age: "Please choose one...",
    businessAge: "",
    other_stores: [],
    employee_size: "Please choose one...",
    business_province: "Select province",
    business_city: "Select city",
    business_needs: [],
    notSpecified: false,
    month: "",
    day: "",
    year: "",
    other_stores_others: false,
    other_stores_others_value: "",
    business_needs_others: false,
    business_needs_others_value: ""
  });

  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [checkboxError2, setCheckboxError2] = React.useState(false);
  const [errors, setErrors] = React.useState({
    birthdayError: "",
    nationalityError: "",
    typeOfProductsError: "",
    onlineSellingError: "",
    businessAddressError: "",
    homeAddressError: "",
    runBusinessError: "",
    businessNeedsError: ""
    // checkboxError: ""
  });

  React.useEffect(() => {
    window.onbeforeunload = function() {
      if (isKYC_successful) {
        let existing = sessionStorage.getItem("CURRENT_USER");
        existing = existing ? JSON.parse(existing) : {};
        existing["survey_answered"] = true;
        sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
      }
    };
  });

  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [errors]);

  const handleOtherStores = (e, val) => {
    if (e.target.checked) {
      if (!kycAnswer.other_stores.includes(val)) {
        setKycAnswer({
          ...kycAnswer,
          other_stores: [...kycAnswer.other_stores, val]
        });
      }
    } else {
      const array = kycAnswer.other_stores.filter(e => e !== val);
      setKycAnswer({ ...kycAnswer, other_stores: array });
    }
  };

  const getProvinces = () => {
    let provinces = [];
    for (var i in ADDRESS) {
      provinces.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return provinces;
  };

  const getCity = () => {
    let cities = [];
    if (kycAnswer.business_province in ADDRESS) {
      for (var i in ADDRESS[kycAnswer.business_province]["Municipality_list"]) {
        cities.push(<MenuItem value={i}>{i}</MenuItem>);
      }
    }

    return cities;
  };

  const handleBusinessNeedsChange = (e, val) => {
    if (e.target.checked) {
      if (!kycAnswer.business_needs.includes(val)) {
        setKycAnswer({
          ...kycAnswer,
          business_needs: [...kycAnswer.business_needs, val]
        });
      }
    } else {
      const array = kycAnswer.business_needs.filter(e => e !== val);
      setKycAnswer({ ...kycAnswer, business_needs: array });
    }
  };
  function setKycAnswerDetails(key, value) {
    setKycAnswer(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  function handleHomepage() {
    dispatch(skipSurvey());
  }
  function handleSubmit() {
    if (
      kycAnswer.business_needs_others &&
      kycAnswer.business_needs_others_value === ""
    ) {
      setErrors({
        ...errors,
        businessNeedsError: "This field is required."
      });
    } else {
      let value = {
        birthdate: kycAnswer.birthdate,
        gender: kycAnswer.gender,
        civil_status: kycAnswer.civil_status,
        industry: kycAnswer.industry,
        selling_age: kycAnswer.selling_age,
        other_stores: kycAnswer.other_stores_others
          ? [...kycAnswer.other_stores, kycAnswer.other_stores_others_value]
          : kycAnswer.other_stores,
        employee_size: kycAnswer.employee_size,
        business_province: kycAnswer.business_province,
        business_city: kycAnswer.business_city,
        business_needs: kycAnswer.business_needs_others
          ? [...kycAnswer.business_needs, kycAnswer.business_needs_others_value]
          : kycAnswer.business_needs
      };

      dispatch(saveKYCSurvey(value));
    }
  }
  function surveyAnswered() {
    if (user.is_facebookLogin) {
      if (user.survey_answered) {
        if (user.survey_answered === false || user.survey_answered === null) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      if (user.survey_answered === false || user.survey_answered === null) {
        return true;
      } else {
        return false;
      }
    }
  }

  function checkOtherStores() {
    if (!kycAnswer.other_stores.length) {
      if (
        kycAnswer.other_stores_others &&
        !kycAnswer.other_stores_others_value
      ) {
        return true;
      } else if (
        kycAnswer.other_stores_others &&
        kycAnswer.other_stores_others_value
      ) {
        return false;
      } else return true;
    } else {
      return false;
    }
  }

  function checkBusinessNeeds() {
    if (!kycAnswer.business_needs.length) {
      if (
        kycAnswer.business_needs_others &&
        !kycAnswer.business_needs_others_value
      ) {
        return true;
      } else if (
        kycAnswer.business_needs_others &&
        kycAnswer.business_needs_others_value
      ) {
        return false;
      } else return true;
    } else {
      return false;
    }
  }

  function handleClose() {
    dispatch(skipSurvey());
  }

  const gender = [
    { label: "Male", value: "Male", width: "30%" },
    { label: "Rather not say", value: "Rather not say" },
    { label: "Female", value: "Female", width: "30%" },
    { label: "Not specified", value: "" }
  ];

  const industryList = [
    { value: "Art & photography" },
    { value: "Beauty & wellness" },
    { value: "Clothing" },
    { value: "Footwear" },
    { value: "Digital services" },
    { value: "Electronics" },
    { value: "Food & drink" },
    { value: "Groceries" },
    { value: "Handicrafts" },
    { value: "Home & furniture" },
    { value: "Jewelry & accessories" },
    { value: "Sports & fitness" },
    { value: "Travel & leisure" },
    { value: "I haven't decided yet" }
  ];

  const civilStatus = [
    { value: "Single" },
    { value: "Married" },
    { value: "Separated" },
    { value: "Widowed" }
  ];

  const businessAge = [
    { value: "I just started" },
    { value: "Less than a year" },
    { value: "1-2 years" },
    { value: "3-5 years" },
    { value: "5-10 years" },
    { value: "Over 10 years" }
  ];

  const onlineSellingPlatform = [
    { label: "Facebook", name: "facebook" },
    { label: "Shopee", name: "shopee" },
    { label: "Physical store", name: "physicalStore" },
    { label: "Instagram", name: "instagram" },
    { label: "Lazada", name: "lazada" },
    { label: "Shopify", name: "shopify" },
    { label: "Own website", name: "ownWebsite" }
  ];

  const businessSizeList = [
    { value: "I do all the work myself" },
    { value: "1-3 employees" },
    { value: "4-10 employees" },
    { value: "Over 10 employees" },
    { value: "Over 50 employees" },
    { value: "Over 100 employees" }
  ];

  const businessNeeds = [
    {
      label: "Additional capital",
      name: "additionalCapital"
    },
    { label: "Suppliers", name: "suppliers" },
    {
      label: "Digital advertising",
      name: "digitalAdvertising"
    },
    {
      label: "Government compliance",
      name: "complianceGovernment"
    },
    {
      label: "Payment solutions",
      name: "paymentSolution"
    },
    { label: "Delivery and logistics", name: "logistics" }
  ];

  React.useEffect(() => {
    if (kycAnswer.other_stores_others) {
      setErrors({
        ...errors,
        onlineSellingError: "This field is required."
      });
    } else if (!kycAnswer.other_stores_others) {
      setErrors({
        ...errors,
        onlineSellingError: ""
      });
    }
  }, [kycAnswer.other_stores_others]);
  function kycPageOne() {
    return (
      <div className={classes.form}>
        <div>
          <div className={classes.label}>1. When is your date of birth?</div>
          <div style={{ marginTop: 10, marginLeft: 20 }}>
            <DateDropdown state={kycAnswer} setState={setKycAnswer} />
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div className={classes.label}>2. Gender</div>
          <div style={{ marginLeft: 20, position: "relative" }}>
            <Radio.Group
              style={{ margin: "15px 0 0", width: "100%", flexWrap: "wrap" }}
              className={classes.radioGender}
              onChange={e => {
                setKycAnswerDetails("gender", e.target.value);
                setKycAnswerDetails(
                  "notSpecified",
                  e.target.value !== "" ? false : true
                );
              }}
            >
              {gender.map(item => {
                return (
                  <Radio
                    style={{
                      width: "45%",
                      marginBottom: 20
                    }}
                    value={item.value}
                  >
                    {item.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
          {kycAnswer.notSpecified ? (
            <div className="d-flex justify-content-end ">
              <TextField
                margin="dense"
                variant="outlined"
                color="secondary"
                style={{ width: "50%", marginTop: 0 }}
                inputProps={{ maxLength: 16 }}
                placeholder="Pls. specify"
                onChange={e => setKycAnswerDetails("gender", e.target.value)}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        <div style={{ marginTop: 20 }}>
          <div className={classes.label}>3. Civil Status</div>
          <div style={{ marginLeft: 20 }}>
            <Radio.Group
              style={{ margin: "15px 0" }}
              className={classes.radioStatus}
              onChange={e =>
                setKycAnswerDetails("civil_status", e.target.value)
              }
            >
              {civilStatus.map(item => {
                return (
                  <Radio
                    style={{ marginBottom: 20, width: "45%" }}
                    value={item.value}
                  >
                    {item.value}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <div className={classes.label}>
            4. Which type of industry do you plan to sell in?
          </div>
          <div style={{ margin: "10px 20px" }}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <Select
                margin="dense"
                color="secondary"
                value={kycAnswer.industry}
                onChange={e => setKycAnswerDetails("industry", e.target.value)}
              >
                <MenuItem disabled value={"Please choose one..."}>
                  Please choose one...
                </MenuItem>
                {industryList.map(item => (
                  <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <div className={classes.label}>
            5. How long have you been selling?
          </div>
          <div style={{ margin: "10px 20px" }}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <Select
                margin="dense"
                color="secondary"
                value={kycAnswer.selling_age}
                onChange={e =>
                  setKycAnswerDetails("selling_age", e.target.value)
                }
              >
                <MenuItem disabled value={"Please choose one..."}>
                  Please choose one...
                </MenuItem>
                {businessAge.map(item => (
                  <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <div className={classes.label}>
            6. Where else do you sell your products? Select all that apply.
          </div>
          <div style={{ marginLeft: 20, position: "relative" }}>
            <FormGroup
              style={{
                flexDirection: "inherit"
              }}
            >
              {onlineSellingPlatform.map(item => {
                return (
                  <div style={{ width: "50%" }}>
                    <FormControlLabel
                      style={{
                        marginRight: 0,
                        display: "flex"
                      }}
                      control={
                        <Checkbox
                          name={item.name}
                          checked={kycAnswer.other_stores.includes(item.label)}
                          onChange={e => handleOtherStores(e, item.label)}
                          color="primary"
                        />
                      }
                      label={<div style={{ fontSize: 12 }}>{item.label}</div>}
                    />
                  </div>
                );
              })}
              <div style={{ width: "50%" }}>
                <FormControlLabel
                  style={{
                    marginRight: 0,
                    display: "flex"
                  }}
                  control={
                    <Checkbox
                      name="onlineChannelOthers"
                      checked={kycAnswer.other_stores_others}
                      onChange={() => {
                        setKycAnswer({
                          ...kycAnswer,
                          other_stores_others_value: "",
                          other_stores_others: kycAnswer.other_stores_others
                            ? false
                            : true
                        });
                        setErrors({ ...errors, onlineSellingError: "" });
                      }}
                      color="primary"
                    />
                  }
                  label={<div style={{ fontSize: 12 }}>Others</div>}
                />
              </div>
              {kycAnswer.other_stores_others ? (
                <div
                  className="d-flex justify-content-end"
                  style={{ width: "100%" }}
                >
                  <TextField
                    variant="outlined"
                    margin="dense"
                    disabled={!kycAnswer.other_stores_others}
                    value={kycAnswer.other_stores_others_value}
                    inputProps={{ maxLength: 32 }}
                    onChange={e => {
                      setKycAnswer({
                        ...kycAnswer,
                        other_stores_others_value: e.target.value
                      });
                      if (e.target.value === "") {
                        setErrors({
                          ...errors,
                          onlineSellingError: "This field is required."
                        });
                      } else {
                        setErrors({
                          ...errors,
                          onlineSellingError: ""
                        });
                      }
                    }}
                    onBlur={e => {
                      if (e.target.value === "") {
                        setErrors({
                          ...errors,
                          onlineSellingError: "This field is required."
                        });
                      } else {
                        setErrors({
                          ...errors,
                          onlineSellingError: ""
                        });
                      }
                    }}
                    style={{
                      width: "50%",
                      marginTop: 0
                    }}
                  />
                  <div
                    className={classes.errorText}
                    style={{
                      width: 120,
                      position: "absolute",
                      right: 0,
                      bottom: -18
                    }}
                  >
                    {errors.onlineSellingError}
                  </div>
                </div>
              ) : (
                ""
              )}
            </FormGroup>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <div className={classes.label}>
            7. How long have you been into online selling?
          </div>
          <div style={{ margin: "10px 20px" }}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <Select
                margin="dense"
                color="secondary"
                value={kycAnswer.employee_size}
                onChange={e =>
                  setKycAnswerDetails("employee_size", e.target.value)
                }
              >
                <MenuItem disabled value={"Please choose one..."}>
                  Please choose one...
                </MenuItem>
                {businessSizeList.map(item => (
                  <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <div className={classes.label}>
            8. Where is your business located?
          </div>
          <div style={{ margin: "10px 20px" }} className="d-flex">
            <FormControl style={{ width: "50%" }} variant="outlined">
              <Select
                id="province"
                color="secondary"
                margin="dense"
                value={kycAnswer.business_province}
                onChange={e =>
                  setKycAnswerDetails("business_province", e.target.value)
                }
              >
                <MenuItem disabled value={"Select province"}>
                  Select province
                </MenuItem>
                {getProvinces()}
              </Select>
            </FormControl>
            <FormControl
              style={{ width: "50%", marginLeft: 10 }}
              variant="outlined"
              disabled={
                kycAnswer
                  ? kycAnswer.business_province === "Select province"
                    ? true
                    : false
                  : true
              }
            >
              <Select
                id="city"
                color="secondary"
                margin="dense"
                value={kycAnswer.business_city}
                inputProps={{ style: { fontSize: 12 } }}
                onChange={e =>
                  setKycAnswerDetails("business_city", e.target.value)
                }
              >
                <MenuItem disabled value={"Select city"}>
                  Select city
                </MenuItem>
                {getCity()}
              </Select>
            </FormControl>
          </div>
        </div>

        <div>
          <div className={classes.label}>
            9. What are your other business needs? Select all that apply.
          </div>
          <div style={{ marginLeft: 20, position: "relative" }}>
            <FormGroup
              style={{
                flexDirection: "inherit"
              }}
            >
              {businessNeeds.map(item => {
                return (
                  <div style={{ width: "50%" }}>
                    <FormControlLabel
                      style={{
                        marginRight: 0,
                        marginBottom: 0,
                        display: "flex"
                      }}
                      control={
                        <Checkbox
                          name={item.name}
                          checked={kycAnswer.business_needs.includes(
                            item.label
                          )}
                          onChange={e =>
                            handleBusinessNeedsChange(e, item.label)
                          }
                          color="primary"
                        />
                      }
                      label={<div style={{ fontSize: 12 }}>{item.label}</div>}
                    />
                  </div>
                );
              })}
              <div style={{ width: "50%" }}>
                <FormControlLabel
                  style={{
                    marginRight: 0,
                    marginBottom: 0,
                    display: "flex"
                  }}
                  control={
                    <Checkbox
                      name={"businessNeedsOthers"}
                      checked={kycAnswer.business_needs_others}
                      onChange={() => {
                        setKycAnswer({
                          ...kycAnswer,
                          business_needs_others_value: "",
                          business_needs_others: kycAnswer.business_needs_others
                            ? false
                            : true
                        });
                        setErrors({ ...errors, businessNeedsError: "" });
                      }}
                      color="primary"
                    />
                  }
                  label={<div style={{ fontSize: 12 }}>Others</div>}
                />
              </div>
            </FormGroup>
            <TextField
              color="secondary"
              variant="outlined"
              margin="dense"
              value={kycAnswer.business_needs_others_value}
              disabled={!kycAnswer.business_needs_others}
              inputProps={{ maxLength: 32, style: { fontSize: 12 } }}
              onChange={e => {
                setKycAnswer({
                  ...kycAnswer,
                  business_needs_others_value: e.target.value
                });
                if (e.target.value.length > 0) {
                  setErrors({
                    ...errors,
                    businessNeedsError: ""
                  });
                }
              }}
              onBlur={e => {
                if (e.target.value === "") {
                  setErrors({
                    ...errors,
                    businessNeedsError: "This field is required."
                  });
                } else {
                  setErrors({
                    ...errors,
                    businessNeedsError: ""
                  });
                }
              }}
              style={{
                width: 120,
                position: "absolute",
                left: 100,
                bottom: 0
              }}
            />
            <div
              style={{
                width: 120,
                position: "absolute",
                left: 100,
                bottom: -18
              }}
              className={classes.errorText}
            >
              {errors.businessNeedsError}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function kycFinish() {
    return (
      <div className={classes.form2}>
        <div className="d-flex justify-content-end p-2">
          <IconButton aria-label="close" onClick={handleHomepage}>
            <CloseIcon color="secondary" />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2vh"
          }}
        >
          <img src={sentroLogo} style={{ width: 80, height: "auto" }} />
        </div>
        <div className={classes.title2}>Thank you!</div>
        <div
          style={{
            textAlign: "center",
            fontSize: 14,
            padding: "2vh 2vw",
            color: "rgba(43,45,50,0.8)"
          }}
        >
          Your answers will help us improve Sentro and build more helpful tools
          for your business needs.
        </div>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            padding: 30
          }}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => handleHomepage()}
          >
            Continue to my dashboard
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Dialog
      fullScreen={isKYC_successful ? false : true}
      maxWidth={isKYC_successful ? "xs" : "lg"}
      open={false}
    >
      <AppBar
        className={classes.appBar}
        style={{ display: isKYC_successful ? "none" : "" }}
      >
        <div
          style={{
            color: "#002c42",
            fontSize: 16,
            fontWeight: 600,
            textAlign: "center",
            width: "100%"
          }}
        >
          Tell us about yourself
        </div>
      </AppBar>
      <div
        style={{
          marginTop: isKYC_successful ? 0 : "10vh",
          overflowY: "scroll",
          overflowX: "hidden",
          minHeight: isKYC_successful ? "25em" : "50vh"
        }}
      >
        {isKYC_successful ? null : (
          <div className={classes.subTitle}>
            Help us understand you better so we can provide you the best
            experience possible. This will only take less than 5 minutes.
          </div>
        )}
        {isKYC_successful ? kycFinish() : kycPageOne()}
      </div>
      {isKYC_successful ? (
        ""
      ) : (
        <div style={{ padding: 20, borderTop: "1px solid #d1d5dd" }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            style={{ height: 40 }}
            onClick={handleSubmit}
            disabled={
              formHasErrors ||
              kycAnswer.birthdate === "" ||
              kycAnswer.day === "Day" ||
              kycAnswer.month === "" ||
              kycAnswer.year === "" ||
              kycAnswer.gender === "" ||
              kycAnswer.gender === "not_specified" ||
              kycAnswer.civil_status === "" ||
              kycAnswer.industry === "Please choose one..." ||
              kycAnswer.selling_age === "Please choose one..." ||
              kycAnswer.employee_size === "Please choose one..." ||
              kycAnswer.business_province === "Select province" ||
              kycAnswer.business_city === "Select city" ||
              checkOtherStores() ||
              checkBusinessNeeds()
            }
          >
            Finish survey
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            disableElevation
            style={{ marginTop: "1vh", height: 40 }}
            onClick={handleClose}
          >
            Skip for now
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default KYCQuestionsMobile;
