import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ACL from "../common/utilities/ACL";
import Action from "./Redux/Actions";
import AdminDashboardLayout from "./layout";
import { Divider } from "@material-ui/core";
import Path from "../../Paths";
import Actions from "./Redux/Actions";
import { stringify_get_params } from "../common/utilities/DateFilter";
import EnhancedTable, {
  stableSort,
  getComparator
} from "./components/unPaginatedTable";
import Card from "./components/dashboardCards";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import numeral from "numeral";

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    padding: "2.0em"
  },
  paper: {
    overflow: "hidden",
    padding: theme.spacing(2),
    border: "1px solid #D1D5DD",
    boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    }
  },
  card: {
    border: "1px solid #E6EAF0",
    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
    margin: "1.5em",
    backgroundColor: "white",
    padding: "2.0em",
    [theme.breakpoints.down("sm")]: {
      margin: "1em"
    }
  },
  cardHeader: {
    fontSize: "28px",
    marginBottom: "0.5em",
    textAlign: "left",
    color: "#F97663",
    fontWeight: 600
  },
  textDivider: {
    fontSize: "18px",
    padding: "1.5em 0",
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    "& > span": {
      marginRight: "1.5em"
    },
    "& > hr": {
      marginTop: 5,
      flexGrow: 1,
      paddingLeft: "3em",
      borderTop: "1px solid rgba(84, 87, 95, 0.75)",
      backgroundColor: "none"
    }
  },
  textLink: {
    fontWeight: "bold",
    color: "#F97663",
    marginBottom: 0,
    cursor: "pointer"
  },
  disabled: {
    fontWeight: "bold",
    color: "gray !important",
    marginBottom: 0
  }
});

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Channel" },
  { id: "count", numeric: false, disablePadding: false, label: "Count" },
  {
    id: "countPercent",
    numeric: false,
    disablePadding: false,
    label: "Count %"
  },
  { id: "volume", numeric: false, disablePadding: false, label: "Volume" },
  {
    id: "volumePercent",
    numeric: false,
    disablePadding: false,
    label: "Volume %"
  },
  { id: "actions", numeric: false, disablePadding: false, label: "" }
];

function TableBodyData(props) {
  return (
    <TableBody>
      {stableSort(props.data, getComparator(props.order, props.orderBy))
        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow key={index} hover role="checkbox" tabIndex={-1}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.count}</TableCell>
              <TableCell align="left">{row.countPercent}%</TableCell>
              <TableCell align="left">{row.volume}</TableCell>
              <TableCell align="left">{row.volumePercent}%</TableCell>
              <TableCell align="left">
                <p
                  className={`${props.classes.textLink} ${
                    row.name !== "BUX" ? props.classes.disabled : ``
                  }`}
                  onClick={() => {
                    if (row.name === "BUX")
                      props.history.push(Path.ADMIN_BUX_PAYMENTS);
                  }}
                >
                  View Details
                </p>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
}

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      order: "desc",
      orderBy: "volume"
    };
    this.trackScrolling = this.trackScrolling.bind(this);
    this.onDateFilterCallback = this.onDateFilterCallback.bind(this);
    this.onImageStateChange = this.onImageStateChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, login, adminDashboard } = this.props;
    const query_params = stringify_get_params(adminDashboard.dateFilter);
    dispatch(Action.loadAdminDashboard(query_params));
    document.removeEventListener("scroll", this.trackScrolling);
    document.addEventListener("scroll", this.trackScrolling);
    localStorage.setItem("adminMenuActive", "Marketing");
  }

  trackScrolling = () => {
    const { dispatch, adminDashboard } = this.props;
    const { menuActive } = adminDashboard;
    const wrappedElement = document.getElementById("marketing");
    if (wrappedElement) {
      const menuToBeActive =
        wrappedElement.scrollHeight < window.pageYOffset
          ? "online_shop"
          : "marketing";
      if (menuActive != menuToBeActive) {
        dispatch(Actions.setMenuActive(menuToBeActive));
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.adminDashboard.dashboardData).length) {
      const data_string = JSON.stringify(
        nextProps.adminDashboard.dashboardData
      );
      const json_object = JSON.parse(data_string);
      this.setState({
        data: nextProps.adminDashboard.dashboardData
      });
    }
  }

  onDateFilterCallback(filter) {
    const { dispatch } = this.props;
    const query_params = stringify_get_params(filter);
    dispatch(Action.loadAdminDashboard(query_params));
  }

  onImageStateChange(top, left, scale) {
    console.log({
      top,
      left,
      scale
    });
  }

  render() {
    const {
      signups,
      ubx_signups,
      verified_signups,
      bux_account,
      sellers_with_created_shops,
      created_shops,
      published_shops,
      deleted_shops,
      active_users,
      daily_active_users,
      monthly_active_users,
      number_of_checkouts,
      total_checkouts_amount,
      paid_checkouts_count,
      paid_checkouts_amount,
      bux_checkouts_count,
      bux_checkouts_amount,
      offline_checkouts_count,
      offline_checkouts_amount,
      total_products_available,
      payment_channels,
      classic,
      contemporary,
      minimalist,
      GCash,
      Bank,
      PayMaya,
      PayPal,
      partners,
      subscriptions,
      user_subscriptions
    } = this.state.data;
    const paymentChannelDataKeys = [
      { display_name: "BUX", var_key: "bux" },
      { display_name: "PayMaya", var_key: "paymaya" },
      { display_name: "GCash (Non-Bux)", var_key: "gcash" },
      { display_name: "PayPal", var_key: "paypal" },
      { display_name: "Banks", var_key: "bank" }
    ];
    const paymentChannelData = paymentChannelDataKeys.map(key => ({
      name: key.display_name,
      count: payment_channels
        ? parseFloat(payment_channels[key.var_key].count)
        : "-",
      countPercent: payment_channels
        ? parseFloat(
            (payment_channels[key.var_key].count / number_of_checkouts) * 100
          ).toFixed(0)
        : "-",
      volume: payment_channels
        ? parseFloat(payment_channels[key.var_key].amount)
        : "-",
      volumePercent: payment_channels
        ? parseFloat(
            (payment_channels[key.var_key].amount / total_checkouts_amount) *
              100
          ).toFixed(0)
        : "-"
    }));
    const { classes, adminDashboard, history } = this.props;
    return (
      <AdminDashboardLayout
        onDateFilterCallback={this.onDateFilterCallback}
        backButtonHidden
      >
        <ACL roles={["admin", "superuser"]} redirect_to="/login">
          <div style={{ backgroundColor: "#f1f1f1" }}>
            {/* {
              adminDashboard.error ?
              "" : */}
            <Fragment>
              <div className={classes.card} id="marketing">
                <div className={`${classes.cardHeader}`}>Marketing</div>
                <h3 className={classes.textDivider}>
                  <span>ACQUISITION</span>
                  <Divider />
                </h3>
                <Grid container spacing={2}>
                  <Card
                    title="Signups"
                    data={signups}
                    link={Path.ADMIN_SIGNUPS}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="UBX Accounts"
                    data={ubx_signups}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Verified Sellers"
                    data={verified_signups}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="New Bux Account"
                    data={bux_account}
                    numeralFormat="0,0"
                    history={history}
                  />
                </Grid>
                <h3 className={classes.textDivider}>
                  <span>ACTIVATION</span>
                  <Divider />
                </h3>
                <Grid container spacing={2}>
                  <Card
                    title="Sellers with Created Shops"
                    data={sellers_with_created_shops}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Created Shops"
                    data={created_shops}
                    link={Path.ADMIN_SHOPS}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Published Shops"
                    data={published_shops}
                    link={`${Path.ADMIN_SHOPS}?is_published=True`}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Published Products"
                    data={total_products_available}
                    link={Path.ADMIN_PRODUCTS}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Deleted Shops"
                    data={deleted_shops}
                    link={`${Path.ADMIN_SHOPS}?is_deleted=True`}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Active Users"
                    data={active_users}
                    link={Path.ADMIN_ACTIVE_USERS}
                    numeralFormat="0,0"
                    history={history}
                  />
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: 9 }}>
                  <Card
                    title="Active Users"
                    data={active_users}
                    link={Path.ADMIN_ACTIVE_USERS}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Daily Active Users"
                    data={daily_active_users}
                    link={Path.ADMIN_DAILY_ACTIVE_USERS}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Monthly Active Users"
                    data={monthly_active_users}
                    link={Path.ADMIN_MONTHLY_ACTIVE_USERS}
                    numeralFormat="0,0"
                    history={history}
                  />
                </Grid>
              </div>
              <Paper className={classes.card} id="online_shop">
                <div className={`${classes.cardHeader}`}>Online Shop</div>
                <h3 className={classes.textDivider}>
                  <span>OVERALL CHECKOUT TRANSACTIONS</span>
                  <Divider />
                </h3>
                <Grid container spacing={2}>
                  <Card
                    title="Total Checkout Amount"
                    data={total_checkouts_amount}
                    numeralFormat="0,0.00"
                    history={history}
                  />
                  <Card
                    title="Paid Checkout Amount"
                    data={paid_checkouts_amount}
                    numeralFormat="0,0.00"
                    history={history}
                    hideLinkText={true}
                    footerText={`${numeral(
                      parseFloat(
                        (paid_checkouts_amount / total_checkouts_amount) * 100
                      )
                    ).format("0,0")}% of total checkout amount`}
                  />
                  <Card
                    title="Bux Payments (Amount)"
                    data={bux_checkouts_amount}
                    numeralFormat="0,0.00"
                    history={history}
                    hideLinkText={true}
                    footerText={`${numeral(
                      parseFloat(
                        (bux_checkouts_amount / total_checkouts_amount) * 100
                      )
                    ).format("0,0")}% of total checkout amount`}
                  />
                  <Card
                    title="Offline Payments (Amount)"
                    data={offline_checkouts_amount}
                    numeralFormat="0,0.00"
                    history={history}
                    hideLinkText={true}
                    footerText={`${numeral(
                      parseFloat(
                        (offline_checkouts_amount / total_checkouts_amount) *
                          100
                      )
                    ).format("0,0")}% of total checkout amount`}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Card
                    title="Total Checkout Count"
                    data={number_of_checkouts}
                    link={Path.ADMIN_CHECKOUTS}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Paid Checkout Count"
                    data={paid_checkouts_count}
                    link={`${Path.ADMIN_CHECKOUTS}?q_type=paid`}
                    numeralFormat="0,0"
                    history={history}
                    hideLinkText={true}
                    footerText={`${numeral(
                      parseFloat(
                        (paid_checkouts_count / number_of_checkouts) * 100
                      )
                    ).format("0,0")}% of total checkout count`}
                  />
                  <Card
                    title="Bux Payments (Count)"
                    data={bux_checkouts_count}
                    link={`${Path.ADMIN_CHECKOUTS}?q_type=bux`}
                    numeralFormat="0,0"
                    history={history}
                    hideLinkText={true}
                    footerText={`${numeral(
                      parseFloat(
                        (bux_checkouts_count / number_of_checkouts) * 100
                      )
                    ).format("0,0")}% of total checkout count`}
                  />
                  <Card
                    title="Offline Payments (Count)"
                    data={offline_checkouts_count}
                    link={`${Path.ADMIN_CHECKOUTS}?q_type=offline`}
                    numeralFormat="0,0"
                    history={history}
                    hideLinkText={true}
                    footerText={`${numeral(
                      parseFloat(
                        (offline_checkouts_count / number_of_checkouts) * 100
                      )
                    ).format("0,0")}% of total checkout count`}
                  />
                </Grid>
                <h3 className={classes.textDivider}>
                  <span>PAYMENT CHANNELS</span>
                  <Divider />
                </h3>
                <EnhancedTable
                  headCells={headCells}
                  tableBody={
                    <TableBodyData
                      order={this.state.order}
                      orderBy={this.state.orderBy}
                      data={paymentChannelData}
                      classes={classes}
                      history={history}
                    />
                  }
                  length={5}
                  defaultOrder="desc"
                  defaultOrderBy="volume"
                  setOrderAndOrderBy={(order, orderBy) =>
                    this.setState({ ...this.state, order, orderBy }, () =>
                      console.log(this.state)
                    )
                  }
                  loading={false}
                />
                <h3 className={classes.textDivider}>
                  <span>TEMPLATE USED</span>
                  <Divider />
                </h3>
                <Grid container spacing={2}>
                  <Card
                    title="Classic"
                    data={classic}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Minimalist"
                    data={minimalist}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Contemporary"
                    data={contemporary}
                    numeralFormat="0,0"
                    history={history}
                  />
                </Grid>
              </Paper>
              <div className={classes.card} id="marketlace">
                <div className={`${classes.cardHeader}`}>Marketplace</div>
                <Grid container spacing={2}>
                  <Card
                    title="Partners"
                    data={partners}
                    link={Path.ADMIN_APP_MARKETPLACE_PARTNERS}
                    numeralFormat="0,0"
                    history={history}
                  />
                  <Card
                    title="Subscriptions"
                    data={subscriptions}
                    link={Path.ADMIN_APP_MARKETPLACE_SUBSCRIPTIONS}
                    numeralFormat="0,0"
                    history={history}
                  />
                </Grid>
              </div>
              <div className={classes.card} id="marketlace">
                <div className={`${classes.cardHeader}`}>
                  Free Trial Subscription
                </div>
                <Grid container spacing={2}>
                  <Card
                    title="Free Trial Subscribers"
                    data={user_subscriptions}
                    numeralFormat="0,0"
                    history={history}
                  />
                </Grid>
              </div>
            </Fragment>
            {/* } */}
          </div>
        </ACL>
      </AdminDashboardLayout>
    );
  }
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(useStyles)(AdminDashboard));
