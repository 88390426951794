import { ActionTypes } from "./actions";
const initialState = {
  images: [],
  loading: false,
  loadingImages: [],
  page: 1,
  max_page: 1
};

export default function stockImagesReducers(state = initialState, action) {
  let loadingImages;
  switch (action.type) {
    case ActionTypes.SEARCH_PHOTOS:
      return {
        ...state,
        loading: true
      }
    case ActionTypes.SEARCH_PHOTOS_SUCCESS:
      return {
        ...state,
        page: action.page,
        images: action.mode === "append" ? [...state.images, ...action.payload]: action.payload,
        max_page: action.max_page,
        loading: false
      }
    case ActionTypes.SET_LOADING_IMG:
      loadingImages = state.loadingImages;
      loadingImages = [...state.loadingImages, action.payload]
      return {
          ...state,
          loadingImages
      }
    case ActionTypes.REMOVE_LOADING_IMG:
      loadingImages = state.loadingImages;
      loadingImages = state.loadingImages.filter(i => i !== action.payload)
      return {
          ...state,
          loadingImages
      }
    default:
      return initialState;
  }
}
