import React from "react";
import {
  Typography,
  Box,
  FormControl,
  InputAdornment,
  TextField
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Instagram, Facebook } from "@material-ui/icons";
import Text from "../../../common/text";
import validations from "../../common/validations";
import storeContactContent from "../formContent/storeContactContent";
import fbIcon from "../../../assets/images/icons/fb.png";
import instagramIcon from "../../../assets/images/icons/insta.png";
import shopeeIcon from "../../../assets/images/icons/shopee.png";
import lazadaIcon from "../../../assets/images/icons/lazada.png";
import kumuIcon from "../../../assets/images/icons/kumu.png";

let StoreContactForm = props => {
  // GTM trigger
  React.useEffect(() => {
    let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    window.dataLayer.push({
      eventAction: "Step - Contact Details",
      eventCategory: "Store Builder",
      event: "storebuildersteptrack",
      userId: currentUser.id
    });

    // HotJar heat map
    if (typeof window.hj === "function") {
      window.hj("trigger", "storeBuilderContactDetails");
    }
  }, []);

  let currentStoreCreated = useSelector(state => state.webBuilder.storeInfo);
  function storeInfoChanges(value, name) {
    props.setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function handleSocMed(value) {
    if (value.includes(".com/")) {
      let v = value.split(".com/");
      return v[1];
    } else if (value.includes(".ph/")) {
      let s = value.split(".ph/");
      return s[1];
    } else {
      return value;
    }
  }

  React.useEffect(() => {
    storeInfoChanges(
      currentStoreCreated ? currentStoreCreated.owner : "",
      "owner"
    );
  }, []);

  const [errors, setErrors] = React.useState({
    storeAddressError: "",
    storeMobileError: "",
    storeEmailError: "",
    fbPageIdError: ""
  });
  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        props.setFormHasErrors(true);
        return;
      }
    }
    props.setFormHasErrors(false);
  }, [errors]);

  const storeInfo = props.storeInfo;
  React.useEffect(() => {
    props.setIsFormEnough(
      storeInfo.address != null &&
        storeInfo.address.length > 0 &&
        storeInfo.mobile != null &&
        storeInfo.mobile.length > 0
    );
  }, [storeInfo]);

  const FIELD_MAX_LENGTHS = {
    address: 100,
    mobile: 11,
    landline: 30,
    email: 128,
    facebook: 32,
    instagram: 32,
    shopee: 32
  };

  let trimInput = (raw_input, maxLength) => {
    return maxLength > 0 && raw_input.length > maxLength
      ? raw_input.slice(0, maxLength)
      : raw_input;
  };

  let handleTextInput = params => {
    const { raw_input, fieldname } = params;
    const maxLength = FIELD_MAX_LENGTHS[fieldname] || 0;
    let input = trimInput(raw_input, maxLength);
    storeInfoChanges(input, fieldname);
  };

  let charactersLeftFor = fieldname => {
    const fieldLength = storeInfo[fieldname] ? storeInfo[fieldname].length : 0;
    const charsRemaining = FIELD_MAX_LENGTHS[fieldname] - fieldLength;
    return `${charsRemaining} characters left`;
  };

  let digitsLeftFor = fieldname => {
    const fieldLength = storeInfo[fieldname] ? storeInfo[fieldname].length : 0;
    const digitsRemaining = FIELD_MAX_LENGTHS[fieldname] - fieldLength;
    return `${digitsRemaining} digits left`;
  };

  function removeSpaces(string) {
    return string.split(" ").join("");
  }

  return (
    <Box>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <p
          style={{ color: "#54575f", fontSize: 12, fontWeight: 500, margin: 0 }}
        >
          <sup style={{ color: "red" }}>*</sup>Required
        </p>
      </div>
      <Box style={{ marginTop: "2vh" }}>
        <div style={{ display: "flex" }}>
          <Text
            textType="p2_Semibold"
            content={
              storeContactContent[props.language ? props.language : "English"]
                .storeAddress
            }
          />
          <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
        </div>
        <Text textType="label1" content="Business Address" />
        <TextField
          id="address"
          color="secondary"
          value={props.storeInfo.address}
          rows={4}
          multiline
          onChange={value => {
            const raw_input = value.target.value;
            handleTextInput({
              raw_input,
              fieldname: "address"
            });

            if (raw_input === "") {
              setErrors({
                ...errors,
                storeAddressError: "This field is required."
              });
            } else {
              setErrors({ ...errors, storeAddressError: "" });
            }
          }}
          onBlur={value => {
            if (value.target.value === "") {
              setErrors({
                ...errors,
                storeAddressError: "This field is required."
              });
            }
          }}
          fullWidth
          variant="outlined"
          error={errors.storeAddressError}
          helperText={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{errors.storeAddressError}</div>
              <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                {charactersLeftFor("address")}
              </div>
            </div>
          }
        />
      </Box>
      <Box style={{ marginTop: "4vh" }}>
        <Text
          textType="p2_Semibold"
          content={
            storeContactContent[props.language ? props.language : "English"]
              .storeMobile
          }
        />
        <div style={{ display: "flex" }}>
          <Text textType="label1" content="Mobile Number" />
          <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
        </div>
        <TextField
          id="mobile_number"
          color="secondary"
          value={props.storeInfo.mobile}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="end">
          //       <Typography
          //         style={{
          //           fontWeight: 600,
          //           color: "rgba(0, 0, 0, 0.54)",
          //           paddingRight: "8px"
          //         }}
          //       >
          //         +63
          //       </Typography>
          //     </InputAdornment>
          //   )
          // }}
          multiline
          onChange={value => {
            const raw_input = value.target.value.replace(/[^0-9]/g, "");

            handleTextInput({
              raw_input,
              fieldname: "mobile"
            });

            if (raw_input === "") {
              setErrors({
                ...errors,
                storeMobileError: "This field is required."
              });
            } else {
              setErrors({ ...errors, storeMobileError: "" });
            }
          }}
          onBlur={value => {
            if (value.target.value === "") {
              setErrors({
                ...errors,
                storeMobileError: "This field is required."
              });
            } else if (value.target.value.length !== 11) {
              setErrors({
                ...errors,
                storeMobileError: "Please enter a valid 11-digit mobile number."
              });
            } else if (
              value.target.value.charAt(0) !== 0 &&
              value.target.value.charAt(1) != 9
            ) {
              setErrors({
                ...errors,
                storeMobileError: "You have entered an invalid mobile number"
              });
            }
          }}
          fullWidth
          variant="outlined"
          error={errors.storeMobileError}
          helperText={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{errors.storeMobileError}</div>
              <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                {digitsLeftFor("mobile")}
              </div>
            </div>
          }
        />
      </Box>
      <Box style={{ marginTop: 10 }}>
        <Text textType="label1" content="Landline Number" />
        <TextField
          id="landline"
          color="secondary"
          value={props.storeInfo.landline}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="end">
          //       <Typography
          //         style={{
          //           fontWeight: 600,
          //           color: "rgba(0, 0, 0, 0.54)",
          //           paddingRight: "8px"
          //         }}
          //       >
          //         +63
          //       </Typography>
          //     </InputAdornment>
          //   )
          // }}
          onChange={value => {
            handleTextInput({
              raw_input: value.target.value,
              fieldname: "landline"
            });
          }}
          fullWidth
          variant="outlined"
        />
      </Box>
      <Box style={{ marginTop: 10 }}>
        <Text textType="label1" content="Email Address" />
        <TextField
          id="email"
          color="secondary"
          value={props.storeInfo.email}
          onChange={value => {
            let raw_input = value.target.value;
            handleTextInput({
              raw_input,
              fieldname: "email"
            });

            if (!validations.email(raw_input)) {
              setErrors({
                ...errors,
                storeEmailError: ""
              });
            }
          }}
          onBlur={value => {
            let raw_input = value.target.value;
            if (validations.email(raw_input)) {
              setErrors({
                ...errors,
                storeEmailError: "Please enter a valid email address."
              });
            }
          }}
          fullWidth
          variant="outlined"
          error={errors.storeEmailError}
          helperText={errors.storeEmailError}
        />
      </Box>
      <Box style={{ marginTop: "4vh" }}>
        <Text
          textType="p2_Semibold"
          content={
            storeContactContent[props.language ? props.language : "English"]
              .socialMedia
          }
        />
        <Box style={{ marginBottom: "15px" }}>
          <Text
            textType="label1"
            content={
              storeContactContent[props.language ? props.language : "English"]
                .socialSubheader
            }
          />
        </Box>
        <Text textType="label1" content="Facebook" />
        <TextField
          id="facebook"
          color="secondary"
          placeholder="your-facebook-page"
          value={props.storeInfo.facebook}
          InputProps={{
            startAdornment: (
              <InputAdornment
                style={{
                  marginRight: "0vw"
                }}
                position="end"
              >
                <img src={fbIcon} alt="fbicon" />
                <span style={{ marginLeft: "1vw" }}>facebook.com/</span>
              </InputAdornment>
            )
          }}
          onChange={value => {
            let raw_input = value.target.value;
            if (raw_input == "") {
              raw_input = " ";
            }
            handleTextInput({
              raw_input,
              fieldname: "facebook"
            });
          }}
          onBlur={value => {
            let raw_input = removeSpaces(value.target.value);
            storeInfoChanges(handleSocMed(raw_input), "facebook");
          }}
          fullWidth
          variant="outlined"
        />
      </Box>
      <Box style={{ marginTop: 5 }}>
        <FormControl style={{ width: "100%" }}>
          <Text textType="label1" content="Instagram" />
          <TextField
            id="instagram"
            color="secondary"
            placeholder="your-instagram-handle"
            value={props.storeInfo.instagram}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{
                    marginRight: "0vw"
                  }}
                  position="end"
                >
                  <img src={instagramIcon} alt="instagramicon" />
                  <span style={{ marginLeft: "1vw" }}>instagram.com/</span>
                </InputAdornment>
              )
            }}
            onChange={value => {
              let raw_input = value.target.value;
              if (raw_input == "") {
                raw_input = " ";
              }
              handleTextInput({
                raw_input,
                fieldname: "instagram"
              });
            }}
            onBlur={value => {
              let raw_input = removeSpaces(value.target.value);
              storeInfoChanges(handleSocMed(raw_input), "instagram");
            }}
            fullWidth
            variant="outlined"
          />
        </FormControl>
      </Box>
      <Box style={{ marginTop: 10 }}>
        <FormControl style={{ width: "100%" }}>
          <Text textType="label1" content="Shopee" />
          <TextField
            id="shopee"
            color="secondary"
            value={props.storeInfo.shopee}
            placeholder="your-shopee-page"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{
                    marginRight: "0vw"
                  }}
                  position="end"
                >
                  <img
                    src={shopeeIcon}
                    alt="shopeeicon"
                    style={{ height: 24, width: 24 }}
                  />{" "}
                  <span style={{ marginLeft: "1vw" }}>shopee.ph/</span>
                </InputAdornment>
              )
            }}
            onChange={value => {
              let raw_input = value.target.value;
              if (raw_input == "") {
                raw_input = " ";
              }
              handleTextInput({
                raw_input,
                fieldname: "shopee"
              });
            }}
            onBlur={value => {
              let raw_input = removeSpaces(value.target.value);
              storeInfoChanges(handleSocMed(raw_input), "shopee");
            }}
            fullWidth
            variant="outlined"
          />
        </FormControl>
      </Box>
      <Box style={{ marginTop: 10 }}>
        <FormControl style={{ width: "100%" }}>
          <Text textType="label1" content="Lazada" />
          <TextField
            id="lazada"
            color="secondary"
            value={props.storeInfo.lazada}
            // placeholder="your-lazada-page"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{
                    marginRight: "0vw"
                  }}
                  position="end"
                >
                  <img
                    src={lazadaIcon}
                    alt="lazadaicon"
                    style={{ height: 24, width: 24 }}
                  />{" "}
                  <span style={{ marginLeft: "1vw" }}>lazada.com.ph/shop/</span>
                </InputAdornment>
              )
            }}
            onChange={value => {
              let raw_input = value.target.value;
              if (raw_input === "") {
                raw_input = " ";
              }
              handleTextInput({
                raw_input,
                fieldname: "lazada"
              });
            }}
            onBlur={value => {
              let raw_input = removeSpaces(value.target.value);
              storeInfoChanges(handleSocMed(raw_input), "lazada");
            }}
            fullWidth
            variant="outlined"
          />
        </FormControl>
      </Box>
      <Box style={{ marginTop: 10 }}>
        <FormControl style={{ width: "100%" }}>
          <Text textType="label1" content="Kumu" />
          <TextField
            id="kumu"
            color="secondary"
            value={props.storeInfo.kumu}
            // placeholder="your-kumu-page"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{
                    marginRight: "0vw"
                  }}
                  position="end"
                >
                  <img
                    src={kumuIcon}
                    alt="kumuicon"
                    style={{ height: 24, width: 24 }}
                  />{" "}
                  <span style={{ marginLeft: "1vw" }}>@</span>
                </InputAdornment>
              )
            }}
            onChange={value => {
              let raw_input = value.target.value;
              if (raw_input === "") {
                raw_input = " ";
              }
              handleTextInput({
                raw_input,
                fieldname: "kumu"
              });
            }}
            onBlur={value => {
              let raw_input = removeSpaces(value.target.value);
              storeInfoChanges(handleSocMed(raw_input), "kumu");
            }}
            fullWidth
            variant="outlined"
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default StoreContactForm;
