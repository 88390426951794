import React from "react";
import { reduxForm, Field } from "redux-form";
import MultipleImageAreaField from "../../reduxFields/multipleImageAreaField ";
const AddImages = props => {
  return (
    <div>
      <Field
        fullWidth
        id={`images-1`}
        name={`images-1`}
        defaultCropperRatio="landscape"
        component={MultipleImageAreaField}
        height={290}
        onImageSelect={props.onImageSelect}
        statuses={props.statuses}
      />
    </div>
  );
};

let Form = reduxForm({
  form: "addMediaImages",
  enableReinitialize: true
})(AddImages);

export default Form;
