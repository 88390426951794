import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "../../../../store/history";
import Layout from "../../layout";
import Header from '../../components/order/header';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BasicTable, { stableSort, getComparator}  from '../../../admin/components/unPaginatedTable';
import { animateScroll as scroll } from "react-scroll";
import qs from 'query-string';

import moment from 'moment';
import Actions from '../../Redux/Actions';
import _ from 'lodash';


const useStyles = theme => ({
  backHeader: {
    width: 120,
    display: "flex !important",
    alignItems: "center",
    padding: "0 0 2em",
    fontWeight: 600,
    fontSize: 14,
    color: "#F97663 !important"
  },
  table: {
    "& th": {
      background: "#E6EAF0",
      color: "#002C42 !important",
      fontSize: 14,
      fontWeight: "bold",
      padding: 10,
    },
    "& th:hover": {
      color: "#002C42 !important",
    },
    "& td": {
      padding: 10,
      background: "white",
      fontSize: 14,
      fontWeight: "normal"
    }
  },
  status: {
    display: "flex",
    fontWeight: "bold",
    color: "white",
    "& .for_confirmation": {
      background: "#F5922F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .pending_payment": {
      background: "#1E4B83",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .paid": {
      background: "#57B19F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .cancelled": {
      background: "#D1D5DD",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    }
  }, 
  link: {
    fontWeight: 600,
    color: "#F97663",
    fontSize: 12,
    marginBottom: 0,
    cursor: "pointer",
    marginLeft: "2em"
  }
});

const headCells = [
  { id: 'order_id', numeric: false, disablePadding: true, label: 'PR No.' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Customer Name' },
  { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
  { id: 'total', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Date Ordered' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'options', numeric: false, disablePadding: false, label: 'Options' },
];

const data = [
  {
    pr_no: "10023445",
    name: "John Cruz (XYZ Co.)",
    country: "Philippines",
    amount: "USD 500.00",
    date: "12 Jan 2030",
    status: "For confirmation"
  },
  {
    pr_no: "HYP5678",
    name: "Wil Tee (ABC Shop)",
    country: "Malaysia",
    amount: "USD 221.32",
    date: "11 Nov 2010",
    status: "Pending Payment"
  },
  {
    pr_no: "HYP1938",
    name: "Kipi Go (Random Inc)",
    country: "Philippines",
    amount: "USD 120.00",
    date: "24 Sep 2020",
    status: "Paid"
  },
  {
    pr_no: "HYP1002",
    name: "Sands Vee (Sari Store)",
    country: "India",
    amount: "USD 1230.00",
    date: "01 Sep 2020",
    status: "Cancelled"
  }
]

function TableBodyData (props) {
  return (
    <TableBody>
      {stableSort(props.data, getComparator(props.order, props.orderBy))
      .map((row, index) => {
        let status = "For Confirmation";
        switch(row.status) {
          case "Accept":
            status = "Pending Payment";
            break;
          case "Reject":
            status = "Cancelled";
            break;
          default: 
            break;
        }
        return (
          <TableRow
            key={index}
            hover
            role="checkbox"
            tabIndex={-1}
          >
            <TableCell align="left">{row.order_id}</TableCell>
            <TableCell align="left">{row.billing_address.first_name} {row.billing_address.last_name}</TableCell>
            <TableCell align="left">{row.billing_address.country}</TableCell>
            <TableCell align="left">USD {(parseInt(row.total.amount).toFixed(2)/100)}</TableCell>
            <TableCell align="left">{moment(row.timestamp).format('DD MMM YYYY')}</TableCell>
            <TableCell align="left">
              <div className={`${props.classes.status} `}>
                <div className={status.toLowerCase().replace(" ", "_")}>{status}</div>
              </div>
            </TableCell>
            <TableCell align="left">
              <p className={props.classes.link} onClick={() => {
                history.push(`/proxtera/order/${row.order_id}`)
              }}>
                View
              </p>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  )
}

class OrderList extends React.Component {

  state = {
    date_created: "Today",
    status: "All",
    order: "asc",
    orderBy: "pr_no",
    search: ""
  }

  getOrders = (param) => {
    this.props.dispatch(Actions.GET_ORDERS({
      params: {
        ...this.state,
        ...param
      }
    }))
  }

  componentDidMount = async () => {
    let { stores } = this.props;
    scroll.scrollTo(0)
    const params = qs.parse(this.props.location.search);
    if (!stores.length) {
      const response = await this.props.dispatch(Actions.getStores())
      try {
        stores = response.data;
      } catch { }
    }
    this.setState({
        date_created: params.date_created || "Today",
        status: params.status || "All",
        order: params.order || "asc",
        orderBy: params.orderBy || "pr_no",
        search: params.search || "",
        start: params.start || "",
        end: params.end || "",
        order_type: "Supplier"
    }, () => {
      this.setFiltersToUrl(this.state)
      if (stores.length) {
        this.getOrders({
          store_uuid: stores[0].uuid
        })
      }
    })
  }

  setFilters = (filters) => {
    this.setState({
      ...this.state,
      ...filters
    }, () => this.setFiltersToUrl(this.state))
  }

  setFiltersToUrl = (queries) => {
    const searchString = qs.stringify(queries);
    history.push({
    //   pathname: '/proxtera/my_orders',
      search: searchString
    })
  }

  render (){
    const { classes, orders } = this.props;
    const customersOrders = _.filter(orders, ['order_type', 'Supplier']);
    return (
      <Layout noSideBar>
        <a className={classes.backHeader} href="#" onClick={(e) => {
            e.preventDefault();
            history.push("/proxtera/products");
        }}>
          <ArrowBackIcon/>
          <span style={{marginLeft: "1em"}}>{`  BACK`}</span>
        </a>
        <Header title="Customer's Orders" onChange={this.setFilters}
        filter={this.state}/>
        <div className="mt-4">
          <div className={classes.table}>
            <BasicTable 
              headCells={headCells}
              tableBody={
                <TableBodyData 
                  order={this.state.order} 
                  orderBy={this.state.orderBy} 
                  data={customersOrders}
                  classes={classes}
                  history={history}
                />
              }
              length={5}
              defaultOrder="asc"
              defaultOrderBy="pr_no"
              setOrderAndOrderBy={(order, orderBy) => this.setState({...this.state, order, orderBy}, () => console.log(this.state))}
              loading={false}
            />
          </div>
          {
            !customersOrders.length > 0 &&
            <div className="text-center m-5 p-5">
              <p className="text-secondary">No orders found</p>
            </div>
          }
        </div>
      </Layout>
    )
  }
}


const mapStateToProps = state => ({ proxtera }) => ({ 
  orders: proxtera.orders,
  stores: proxtera.stores
});
export default connect(mapStateToProps)(withStyles(useStyles)(OrderList));

