import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Text from "../../../../../../common/text";
import SentroButton from "../../../../sentroButton";
import axios from "axios";
import apiConfig from "../../../../../../common/apiConfig";
import validations from "../../../../validations";
import { useDispatch, useSelector } from "react-redux";
import { createStore } from "../websiteBuilderActions";
import CreateShopSuccess from "../common/createShopSuccess";
import SentroLoading from "../../../../loading";
import {
  makeStyles,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment
} from "@material-ui/core";
import history from "../../../../../../store/history";
import Path from "../../../../../../Paths";

import OTPActions from '../../../../../otp/redux/actions'
import OTPModal from '../../../../../otp/OTPModal';

export const Form = props => {
  const { values, setValues, language } = props;
  const [name, setName] = React.useState(values.name);
  const [site, setSite] = React.useState(values.site);
  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [errors, setErrors] = React.useState({
    storeNameError: "",
    storeSiteError: ""
  });
  const field_max_lengths = {
    name: 30,
    site: 25,
    mobile: 11
  };
  function setValueChanges(value, name) {
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  let trimInput = (raw_input, maxLength) => {
    return maxLength > 0 && raw_input.length > maxLength
      ? raw_input.slice(0, maxLength)
      : raw_input;
  };

  let handleTextInput = params => {
    const { raw_input, fieldname } = params;
    const maxLength = field_max_lengths[fieldname] || 0;
    let input = trimInput(raw_input, maxLength);

    setValueChanges(input, fieldname);
  };

  let charactersLeft = fieldname => {
    const charsRemaining =
      field_max_lengths[fieldname] - values[fieldname].length;
    return `${charsRemaining}/${field_max_lengths[fieldname]}`;
  };

  let setStoreSiteError = errorString => {
    setErrors({ ...errors, storeSiteError: errorString });
  };

  async function checkUniqueName(value) {
    let response = await axios.get(`${apiConfig.apiURL}/api/unique-store/`, {
      params: { name: value }
    });
    if (response.data === value) {
      setErrors({
        ...errors,
        storeNameError: "This is already taken, please try again."
      });
    }
  }

  const [siteTimeoutId, setSiteTimeoutId] = React.useState("");
  const [validatingSite, setValidatingSite] = React.useState("");
  function validateSite(value) {
    clearTimeout(siteTimeoutId)
    setValidatingSite(true)
    if (value !== site) {
      setSiteTimeoutId(setTimeout(async () => {
        let uniqueError = "";
        let changeLimiterror = "";
        if (props.mode !== "create") {
          let res1 = await axios.get(`${apiConfig.apiURL}/api/change-site/`, {
            params: { site }
          });
          if (res1.data) {
            if (!res1.data.allowed) {
              changeLimiterror = `You have changed your shop’s 
              URL within the last 30 days. You may 
              try again in ${res1.data.days_left} day${res1.data.days_left > 1? `s`: ""}.`;
            } else changeLimiterror = ""
          }
        }
        try{
          let res2 = await axios.get(`${apiConfig.apiURL}/api/unique-store/`, {
            params: { site: value }
          });
          if (res2.data === value) uniqueError = "This is already taken, please try again."
          else uniqueError = ""
        } catch {}
        setErrors({
          ...errors,
          storeSiteError: uniqueError || changeLimiterror
        });
        setValidatingSite(false)
      }, 1000))
    } else {
      setStoreSiteError(false);
      setValidatingSite(false);
    }
  }

  const useStyles = makeStyles(theme => ({
    title: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      width: "100%"
    },
    secondaryAlert: {
      fontSize: 14, background: "#EEEEEE"
    },
    subdomain: {
      "& input": {
        background: "white",
      },
      "& .MuiInputAdornment-positionEnd": {
        "& p": {
          margin: "1em 2em",
          color: "white",
          fontWeight: "500",
        }
      },
      "& .MuiInputBase-root": {
        background: "#002C42",
      }
    },
  }));

  const classes = useStyles();

  React.useEffect(() => {
    let hasFormError = false;
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        hasFormError = true;
      }
    }
    setFormHasErrors(hasFormError);
    setIsFormEnough(values.name > 0 && values.site && values.mobile);
    if (props.errorCallback) {
      props.errorCallback(
        hasFormError || !values.name || !values.site || !values.mobile || validatingSite
      );
    }
  }, [errors, values, validatingSite]);

  return (
    <div
      style={{
        padding: 20,
        height: "calc(100vh - 153px)",
        overflow: "auto"
      }}
    >
      <div className={classes.title}>
        {" "}
        {language === "English"
          ? "How do we call your shop?"
          : "Anong itatawag namin sa shop mo?"}
      </div>
      <Text
        style={{ marginTop: 10 }}
        textType="p2"
        content={
          language === "English"
            ? "Please put your shop's name and website"
            : "Ilagay ang pangalan ng iyong online shop at ang gusto mong website link."
        }
      />
      <div style={{ marginTop: 25 }}>
        <Text
          textType="p2"
          content={
            language === "English"
              ? "What's the name of your shop?"
              : "Anong pangalan ng store mo?"
          }
        />
        <TextField
          fullWidth
          margin="dense"
          id="store_name"
          color="secondary"
          variant="outlined"
          style={{ marginTop: 0 }}
          error={errors.storeNameError}
          value={values.name}
          onChange={e => {
            const raw_input = e.target.value;
            handleTextInput({ raw_input, fieldname: "name" });

            if (raw_input === "") {
              setErrors({
                ...errors,
                storeNameError: "This field is required."
              });
            } else {
              setErrors({ ...errors, storeNameError: "" });
              if (raw_input !== name) {
                checkUniqueName(raw_input);
              }
            }
          }}
          onBlur={e => {
            if (e.target.value === "") {
              setErrors({
                ...errors,
                storeNameError: "This field is required."
              });
            }
          }}
          helperText={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{errors.storeNameError}</div>
              <div style={{ color: "rgba(0,0,0,0.54)" }}>
                {charactersLeft("name")}
              </div>
            </div>
          }
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <Text
          textType="p2"
          content={
            language === "English"
              ? "What will be your shop's website?"
              : "Anong gusto mong website link para sa online shop mo?"
          }
        />
        <TextField
          fullWidth
          margin="dense"
          id="store_site"
          color="secondary"
          variant="outlined"
          className={classes.subdomain}
          style={{ marginTop: 0 }}
          error={errors.storeSiteError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">.sentro.ph</InputAdornment>
            )
          }}
          value={values.site}
          onChange={e => {
            let raw_input = e.target.value;
            handleTextInput({ raw_input, fieldname: "site" });
            if (validations.required(raw_input)) {
              setStoreSiteError(validations.required(raw_input));
            } else if (validations.uppercase(raw_input)) {
              setStoreSiteError(validations.uppercase(raw_input));
            } else if (validations.spaceBetween(raw_input)) {
              setStoreSiteError(validations.spaceBetween(raw_input));
            } else if (validations.specialCharacters(raw_input)) {
              setStoreSiteError(validations.specialCharacters(raw_input));
            } else {
              validateSite(e.target.value)
            }
          }}
          onBlur={e => {
            if (e.target.value === "") {
              setErrors({
                ...errors,
                storeSiteError: "This field is required"
              });
            }
          }}
          helperText={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{errors.storeSiteError}</div>
              <div style={{ color: "rgba(0,0,0,0.54)" }}>
                {charactersLeft("site")}
              </div>
            </div>
          }
        />
      </div>
      {
        props.mode === "create" ?
        <>
          {/* <p className={`px-4 py-3 my-2 mb-0 ${classes.secondaryAlert}`}>
            <strong>Please note:</strong> You can use your own domain name after you’ve setup your shop.
          </p> */}
          <Text
            style={{ marginTop: 15 }}
            textType="p2"
            content="Mobile Number"
          />
          <TextField
            fullWidth
            id="store_mobile"
            color="secondary"
            variant="outlined"
            margin="dense"
            style={{ marginTop: 0 }}
            value={values.mobile}
            inputProps={{ maxLength: 11 }}
            error={errors.mobileError}
            onChange={e => {
              const input = e.target.value;
              setValueChanges(input, "mobile");
              if (input === "") {
                setErrors({
                  ...errors,
                  mobileError: "This field is required."
                });
              } else if (validations.mobileNumber(input)) {
                setErrors({
                  ...errors,
                  mobileError: validations.mobileNumber(input)
                });
              } else {
                setErrors({ ...errors, mobileError: "" });
              }
            }}
            onBlur={e => {
              const input = e.target.value;
              if (input === "") {
                setErrors({
                  ...errors,
                  mobileError: "This field is required."
                });
              } else if (validations.mobileNumber(input)) {
                setErrors({
                  ...errors,
                  mobileError: validations.mobileNumber(input)
                });
              } else {
                setErrors({ ...errors, mobileError: "" });
              }
            }}
            helperText={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <div>{errors.mobileError}</div>
                <div style={{ color: "rgba(0,0,0,0.54)" }}>
                  {charactersLeft("mobile")}
                </div>
              </div>
            }
          />
        </>: ""
      }
      {
        props.mode !== "create" ?
        <p className="bg-light p-4 mt-4 mb-0" style={{ fontSize: 14}}>
          <strong>Please note:</strong> If you change your shop’s website, 
          you won’t be able to change it again for 30 days.
        </p> : ""
      }
    </div>
  );
};

const DialogTransition = props => <Slide direction="up" {...props} />;
const MobileCreateShop = props => {
  const { open, setOpen, onClose, values, setValues, language } = props;
  const useStyles = makeStyles(theme => ({
    appBar: {
      position: "relative",
      backgroundColor: "#fafafa",
      boxShadow: "none",
      borderBottom: "1px solid #d1d5dd"
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    errorText: {
      fontSize: 12,
      color: "red"
    },
    closeBtn: { position: "absolute", left: 15 }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.mobileDashboard.loading);
  const openModal = useSelector(state => state.mobileDashboard.dialogOpen);
  const [hasError, setError] = React.useState(false);

  function handleSubmit() {
    dispatch(OTPActions.SEND_SMS_OTP(values.mobile))
  }

  const submit = (values) => {
    if (values.name && values.site && values.mobile) {
      dispatch(createStore(values));
      setOpen(false);
      history.push(Path.MOBILE_PRODUCTS);
    }
  }

  return (
    <div>
      <OTPModal proceedCallback={() => submit(values)} />
      {/* <SentroLoading open={loading} /> */}
      {/* <CreateShopSuccess open={openModal} /> */}
      <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              className={classes.closeBtn}
            >
              <CloseIcon style={{ color: "#f97663" }} />
            </IconButton>
            <div className={classes.header}>Name your shop</div>
          </Toolbar>
        </AppBar>
        <Form
          mode="create"
          values={values}
          setValues={setValues}
          errorCallback={err => setError(err)}
          language={language}
        />
        <div style={{ padding: 20, width: "100%" }}>
          <SentroButton
            disableElevation
            fullWidth
            label="Submit"
            variant="contained"
            onClick={handleSubmit}
            disabled={hasError}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default MobileCreateShop;
