import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  Button,
  Snackbar
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AddProductCategory = props => {
  const {
    open,
    setOpen,
    categories,
    error,
    alert,
    setAlert,
    handleAddCategory
  } = props;
  const useStyles = makeStyles(theme => ({
    title: {
      color: "#002c42",
      fontSize: 18,
      fontWeight: 600
    },
    titleSection: {
      paddingTop: "2vh",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    alert: {
      display: "flex",
      alignItems: "center",
      fontSize: 12,
      backgroundColor: "#00e289",
      color: "#fff",
      padding: "0 5px"
    },
    categories: {
      marginTop: 10,
      padding: "0 20px",
      overflowY: "scroll",
      height: "30vh"
    }
  }));
  const classes = useStyles();

  const [category, setCategory] = React.useState("");

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogContent style={{ padding: 0, position: "relative" }}>
        <div style={{ padding: "0 20px" }}>
          <div className={classes.titleSection}>
            <div className={classes.title}>Shop Categories</div>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon color="secondary" />
            </IconButton>
          </div>
          <div style={{ marginTop: 10 }}>
            <div style={{ fontSize: 12, color: "#54575f" }}>Name</div>
            <div className={classes.titleSection} style={{ paddingTop: 0 }}>
              <TextField
                style={{ marginTop: 0 }}
                variant="outlined"
                margin="dense"
                color="secondary"
                value={category}
                onChange={e => {
                  setCategory(e.target.value);
                }}
                inputProps={{ maxLength: 32 }}
                error={error}
                helperText={error}
              />
              <Button
                style={{
                  marginLeft: 10,
                  height: 40,
                  marginTop: error ? -25 : 0
                }}
                variant="outlined"
                color="primary"
                onClick={() => handleAddCategory(category)}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.categories}>
          {categories
            .sort(function(a, b) {
              if (a && b) {
                if (a.toLowerCase() < b.toLowerCase()) return -1;
                if (a.toLowerCase() > b.toLowerCase()) return 1;
              }
              return 0;
            })
            .map(item => {
              return item === "" ? (
                ""
              ) : (
                <div
                  style={{ fontSize: 16, color: "#54575f", padding: "1vh 0" }}
                >
                  {/* <IconButton>
                    <CancelIcon color="secondary" />
                  </IconButton> */}
                  {item}
                </div>
              );
            })}
        </div>
        <div style={{ padding: 12, position: "relative" }}>
          <Snackbar
            style={{ position: "relative" }}
            open={alert}
            autoHideDuration={5000}
            onClose={() => setAlert(false)}
          >
            <Alert onClose={() => setAlert(false)} severity="success">
              Category successfully added!
            </Alert>
          </Snackbar>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddProductCategory;
