import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import bux from "../../../../assets/images/buxLOGO.png";
import { Link, useTheme, useMediaQuery } from "@material-ui/core";
import BuxWallet from "./buxWallet";
import CODWallet from "./codWallet";
import {
  bankLogos,
  ewallets,
  cards,
  otcLogos
} from "../../../websiteBuilder/forms/logoImages";
import { otc } from "./otcImages";
import BuxLimit from "./buxLimit";
import CODWalletActive from "./codWalletActive";
import { useSelector } from "react-redux";
export default function BuxPane(props) {
  const useStyles = makeStyles(theme => ({
    img: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap"
      }
    }
  }));

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const codWallet = useSelector(state => state.payments.codWallet);
  const otcPartners = (
    <div
      style={{
        backgroundColor: "#fafafa",
        padding: isMobile ? "5vh 2em" : "8vh 6em"
      }}
      className="border-bottom"
    >
      <div
        className="text-md-left text-center"
        style={{ fontSize: 16, fontWeight: 500, color: "#2b2d32" }}
      >
        Online and over-the-counter payment partners
      </div>
      <div className="d-md-flex col-12 py-5 px-0 border-bottom">
        <div className="col-md-6 pl-0">
          <div style={{ fontSize: 14, fontWeight: 500, color: "#2b2d32" }}>
            1. Online payment channels
          </div>
          <div
            style={{ fontSize: 14, color: "#54575f" }}
            className="pl-md-3 pl-0 pt-2"
          >
            Online Banking fee: PHP15 per transaction
          </div>
        </div>
        <div
          className={`${classes.img} col-md-6 pr-md-0 pr-0 pl-md-3 pl-0 pt-md-0 pt-3`}
        >
          {bankLogos.map(item => (
            <img
              src={item.logo}
              alt=""
              width="auto"
              height="40"
              className="mr-2"
            />
          ))}
        </div>
      </div>
      <div className="d-md-flex col-12 py-5 px-0 border-bottom">
        <div className="col-md-6 pl-0">
          <div style={{ fontSize: 14, fontWeight: 500, color: "#2b2d32" }}>
            2. Cash payments (over the counter)
          </div>
          <div
            style={{ fontSize: 14, color: "#54575f" }}
            className="pl-md-3 pl-0 pt-2"
          >
            Fee: PHP 20.00 per transaction
          </div>
        </div>
        <div className={`col-md-6 pr-md-0 pr-0 pl-md-3 pl-0 pt-md-0 pt-3`}>
          {isMobile
            ? otcLogos.map(img => (
                <img
                  src={img.logo}
                  alt=""
                  width="70"
                  height="70"
                  className="m-1"
                />
              ))
            : otc.map(img => (
                <img
                  src={img}
                  alt=""
                  width="auto"
                  height="45"
                  className="mr-2 mb-2"
                />
              ))}
        </div>
      </div>
      <div className="d-md-flex col-12 py-5 px-0 border-bottom">
        <div className="col-md-6 pl-0">
          <div style={{ fontSize: 14, fontWeight: 500, color: "#2b2d32" }}>
            3. E-wallets
          </div>
          <div
            style={{ fontSize: 14, color: "#54575f" }}
            className="pl-md-3 pl-0 pt-2"
          >
            Fee: Php 12 + 3.5% of transaction amount
          </div>
        </div>
        <div
          className={`${classes.img} col-md-6 pr-md-0 pr-0 pl-md-3 pl-0 pt-md-0 pt-3`}
        >
          {ewallets.map(img => (
            <img
              src={img.logo}
              alt=""
              width="auto"
              height="35"
              className="mr-3"
            />
          ))}
        </div>
      </div>
      <div className="d-md-flex col-12 pt-5 px-0">
        <div className="col-md-6 pl-0">
          <div style={{ fontSize: 14, fontWeight: 500, color: "#2b2d32" }}>
            4. Credit/Debit Cards
          </div>
          <div
            style={{ fontSize: 14, color: "#54575f" }}
            className="pl-md-3 pl-0 pt-1"
          >
            Applicable to Bux Enterprise accounts
          </div>
          <div
            style={{ fontSize: 14, color: "#54575f" }}
            className="pl-md-3 pl-0 pt-1"
          >
            Fee: Php 10 + 2% of transaction amount
          </div>
          <div
            style={{ fontSize: 14, color: "#54575f" }}
            className="pl-md-3 pl-0 pt-1"
          >
            (Additional 1% for cards issued internationaly)
          </div>
        </div>
        <div
          className={`${classes.img}  col-md-6 pr-md-0 pr-0 pl-md-3 pl-0 pt-md-0 pt-3`}
        >
          {cards.map(img => (
            <img
              src={img.logo}
              alt=""
              width="auto"
              height="40"
              className="mr-4"
            />
          ))}
        </div>
      </div>
    </div>
  );

  const buxWallet = (
    <div
      style={{
        backgroundColor: "#fff",
        padding: isMobile ? "5vh 2em" : "8vh 6em"
      }}
    >
      <div style={{ fontSize: 16, fontWeight: 500, color: "#54575f" }}>
        Bux Wallet
      </div>
      <div style={{ fontSize: 14, color: "#54575f" }} className="pt-3">
        All payments done via Bux, regardless of the method chosen by customer,
        will go straight to your Bux Wallet, net of applicable fees. You can
        then withdraw from your Bux Wallet to your bank via online transfers
      </div>
      <div
        style={{ fontSize: 16, fontWeight: 500, color: "#54575f" }}
        className="pt-4"
      >
        Cash in and cash out limit
      </div>
      <div style={{ fontSize: 14, color: "#54575f" }} className="pt-3">
        Amount transacted within your wallet is subject to limits depending on
        your account type with Bux. To know more,{" "}
        <Link color="primary" onClick={() => window.open("https://bux.ph/faq")}>
          click here
        </Link>
        .
        <BuxLimit open={open} setOpen={setOpen} />
      </div>
    </div>
  );

  return (
    <div>
      <div className="p-4 border-bottom">
        <div className="d-md-flex align-items-center">
          <div className="d-flex justify-content-center">
            <img src={bux} alt="bux" width="auto" height="40" />
          </div>
          <div
            className="ml-md-5 mt-md-0 mt-3 text-md-left text-center"
            style={{ fontSize: 14, color: "#54575f" }}
          >
            Receive payments via Bux—automatically available upon publishing
            your shop.{" "}
            <Link
              className="font-weight-bold"
              color="primary"
              onClick={() => window.open("https://bux.ph/faq")}
            >
              Learn more.
            </Link>
          </div>
        </div>
        <div className={`py-4 d-md-flex col-12 p-0`}>
          <div className="col-md-6 pl-md-0 pl-0 pr-md-3 pr-0">
            <BuxWallet />
          </div>
          <div className="col-md-6 pr-md-0 pr-0 pl-md-3 pl-0 pt-md-0 pt-4">
            {codWallet === "" ? <CODWallet /> : <CODWalletActive />}
          </div>
        </div>
      </div>
      {otcPartners}
      {buxWallet}
    </div>
  );
}
