import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import AppBar from "./AppBar";
import Footer from "./Footer";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import history from "../../../../store/history";
import StoreActions from "./StoreActions";
import { Radio } from "antd";
import Utils from "../../utils";
import tags from "../../../../assets/images/tags.svg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { ThemeProvider } from "@material-ui/styles";
import {
  Breadcrumbs,
  Link,
  Paper,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import VoucherModal from "./voucher/voucherModal";
import GoogleAddressForm from "../../googleAddressForm";
import Alert from "@material-ui/lab/Alert";
import AddressCard from "../../../dashboard/buyerDashboard/components/profile/address/addressCard";
import Summary from "./cart/summary";
import DeliveryRates from "./cart/deliveryRates";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f8f8f8",
    width: "100%",
    padding: "6em 10vw",
    [theme.breakpoints.down("sm")]: {
      padding: "2em 0 10vh",
      height: "71vh",
      overflowY: "scroll"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2em 0 15vh",
      overflowY: "scroll"
    }
  },
  breadcrumbs: {
    fontSize: 14,
    color: "rgba(43,45,50, 0.8)",
    "&:hover": {
      fontWeight: 600,
      textDecoration: "none",
      color: "#2b2d32"
    }
  },
  header: {
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: 28,
    color: "#2b2d32",
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px"
    }
  },
  details: {
    width: "65%",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 15
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: "rgba(43,45,50, 0.8)"
  },
  labelbold: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#2b2d32"
  },
  btnContinue: {
    color: "#2b2d32",
    height: 40
  },
  btnCheckout: {
    backgroundColor: "#2b2d32",
    color: "#fff",
    height: 40,
    marginLeft: "1vw",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 15
    }
  },
  summary: {
    height: "fit-content",
    marginLeft: "2vw",
    padding: 24,
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 10,
      marginLeft: 0
    }
  },
  columnSummary: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: "2vh",
    color: "rgba(43,45,50, 0.8)",
    border: "none"
  },
  column: {
    border: "none",
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 600,
    color: "rgba(43,45,50, 0.8)"
  },
  noItems: {
    width: "46vw",
    textAlign: "center",
    padding: 20,
    fontSize: 12
  },
  shippingFeeCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    border: "1px solid #d1d5dd",
    borderRadius: 4,
    width: "100%",
    marginBottom: 10
  },
  box: {
    transition: "all .5s ease-in-out",
    borderRadius: 4,
    backgroundColor: "#fafafa",
    padding: "24px 16px"
  }
}));

const DeliveryDetails = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/delivery_details", "");
  const storeName = param.replace("/shop/", "");
  const theme = useTheme();
  const store_theme = useSelector(state => state.store.store.color);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [flatRate, setFlatRate] = React.useState("");
  const [mrspeedy_address, set_mrspeedy_address] = React.useState("");
  const storeInfo = useSelector(state => state.store.store);
  const loading = useSelector(state => state.store.loading);
  const cartItems = useSelector(state => state.store.items);
  const subtotal = useSelector(state => state.store.subtotal);
  const total = useSelector(state => state.store.total);
  const customer_details = useSelector(state => state.store.customerDetails);
  const voucher_discount = useSelector(state => state.store.voucher_discount);
  const delivery_details = useSelector(state => state.store.deliveryDetails);
  const shipping_fee = useSelector(
    state => state.store.deliveryDetails.shippingFee
  );
  const mrspeedy_settings = useSelector(
    state => state.store.store.mrspeedy_settings
  );
  const ninjavan_settings = useSelector(
    state => state.store.store.ninjavan_settings
  );
  const pickup_address = useSelector(state => state.store.store.pickup_address);
  const delivery_locations = useSelector(
    state => state.store.deliveryLocations
  );
  const pickup_google_address =
    pickup_address && pickup_address.length
      ? pickup_address[0].google_address
      : "";
  const is_mrspeedy_autocompute =
    pickup_address && pickup_address.length
      ? pickup_address[0].is_mrspeedy_auto_compute
      : "";
  let vouchers = useSelector(state => state.store.voucherList);
  const [openVoucher, setOpenVoucher] = React.useState(false);

  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold"
      },
      fontFamily: ["Inter", "sans-serif"]
    }
  });

  const setDeliveryDetails = shipping => {
    let params = {
      ...delivery_details,
      shipping: shipping,
      shippingFee: 0,
      delivery_detail: "",
      mrspeedy_delivery_address: { address: "", longitude: "", latitude: "" }
    };
    if (shipping === "flat_rate") {
      params = {
        ...params,
        shippingFee: flatRate ? flatRate.rate : 0,
        delivery_detail: flatRate ? flatRate : ""
      };
    } else if (shipping === "mr_speedy") {
      params = { ...params, shippingFee: 0 };
    } else if (shipping === "pick_up") {
      params = {
        ...params,
        shippingFee: 0,
        delivery_detail: pickup_address[0]
      };
    }
    dispatch(StoreActions.setDeliveryDetails(params));
  };

  const computeShipping = async params => {
    let values = {
      matter: "",
      vehicle_type: 8,
      weight_kg: 5,
      motobox: false,
      src_address: pickup_google_address.address,
      dst_address: params.address,
      src_coord: {
        latitude: pickup_google_address.lat,
        longitude: pickup_google_address.lng
      },
      dst_coord: {
        latitude: params.latitude,
        longitude: params.longitude
      }
    };
    dispatch(
      StoreActions.setDeliveryDetails({
        ...delivery_details,
        mrspeedy_delivery_address: {
          address: params.address,
          longitude: params.longitude,
          latitude: params.latitude
        }
      })
    );
    await dispatch(StoreActions.computeShipping(values, storeInfo.uuid));
  };

  const isDisabled = () => {
    let pickup = pickup_address && pickup_address.length ? pickup_address : [];

    if (!delivery_locations.length && !pickup.length) {
      return false;
    } else {
      if (!delivery_details.shipping) {
        return true;
      } else if (
        delivery_details.shipping === "courier" &&
        !delivery_details.delivery_detail
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const deliveryOnChange = params => {
    dispatch(StoreActions.setDeliveryDetails(params));
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(storeName));
    }
    fetchData();
    dispatch(StoreActions.getDeliveryLocations(storeInfo.uuid));
    if (cartItems.length === 0) {
      history.push(`/shop/${storeName}`);
    }
  }, []);

  React.useEffect(() => {
    let hasProvince = [];
    let hasCity = [];
    let hasNoCity = [];
    for (let i in delivery_locations) {
      if (delivery_locations[i].province === customer_details.provinceAddress) {
        hasProvince.push(delivery_locations[i]);
      }
    }

    if (hasProvince.length) {
      for (let i in hasProvince) {
        if (hasProvince[i].cities.length) {
          if (hasProvince[i].cities.includes(customer_details.cityAddress)) {
            hasCity.push(hasProvince[i]);
          }
        } else {
          hasNoCity.push(hasProvince[i]);
        }
      }
    }

    if (hasProvince.length && hasCity.length) {
      setFlatRate(hasCity[0]);
    } else if (hasProvince.length && !hasCity.length) {
      setFlatRate(hasNoCity[0]);
    }
  }, [delivery_locations]);

  React.useEffect(() => {
    if (delivery_details.shipping === "flat_rate" && !flatRate) {
      dispatch(
        StoreActions.setDeliveryDetails({
          ...delivery_details,
          shippingFee: 0,
          delivery_detail: ""
        })
      );
    } else if (delivery_details.shipping === "flat_rate" && flatRate) {
      dispatch(
        StoreActions.setDeliveryDetails({
          ...delivery_details,
          shippingFee: flatRate.rate,
          delivery_detail: flatRate
        })
      );
    }
  }, [flatRate]);

  const breadcrumbLinks = [
    {
      label: "Home",
      path: `/shop/${storeInfo.site}`
    },
    {
      label: "Cart",
      path: `/shop/${storeInfo.site}/cart`
    },
    {
      label: "Details",
      path: `/shop/${storeInfo.site}/delivery`
    },
    {
      label: "Delivery",
      path: `/shop/${storeInfo.site}/delivery_details`
    },
    {
      label: "Payment",
      path: `/shop/${storeInfo.site}/payment`,
      isDisabled: true
    }
  ];

  const noShippingSelections = () => {
    return (
      <div id="no_shipping_selections" style={{ padding: "0 24px 24px" }}>
        <Alert
          id="flat_rate_error"
          style={{ fontSize: 12, border: "1px solid #e89937" }}
          severity="warning"
        >
          Oops! This seller has not included delivery fees in the total amount.
          If you proceed with payment, seller will inform you once items are
          ready for pick-up or delivery. Kindly coordinate for the delivery
          arrangement.
        </Alert>

        <div className="pt-4">
          <div style={{ fontSize: 14, fontWeight: 600 }}>{storeInfo.name}</div>
          <div className="pt-3">
            {storeInfo && storeInfo.email ? (
              <div className="d-flex align-items-center">
                <MailOutlineIcon style={{ color: "rgba(43,45,50, 0.8)" }} />
                <div
                  className="ml-3"
                  style={{ fontSize: 12, color: "rgba(43,45,50, 0.8)" }}
                >
                  {storeInfo.email}
                </div>
              </div>
            ) : (
                ""
              )}
            {storeInfo && storeInfo.mobile ? (
              <div className="d-flex align-items-center mt-2">
                <PhoneIphoneIcon style={{ color: "rgba(43,45,50, 0.8)" }} />
                <div
                  className="ml-3"
                  style={{ fontSize: 12, color: "rgba(43,45,50, 0.8)" }}
                >
                  {storeInfo.mobile}
                </div>
              </div>
            ) : (
                ""
              )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {storeInfo && storeInfo.font.css_url ? (
        <link href={storeInfo.font.css_url} rel="stylesheet"></link>
      ) : (
          ""
        )}
      <ThemeProvider theme={store_generated_theme}>
        <div className={classes.root}>
          <AppBar isPreview={false} storeInfo={storeInfo} />
          <Loading open={loading} />
          <div>
            <div>
              <Breadcrumbs
                style={{ display: isMobile ? "none" : "" }}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbLinks.map(item => {
                  return (
                    <Link
                      disabled={item.isDisabled}
                      className={classes.breadcrumbs}
                      style={{
                        fontWeight:
                          item.label === "Delivery" ? "bold" : "normal"
                      }}
                      color="inherit"
                      onClick={() =>
                        item.isDisabled ? null : history.push(item.path)
                      }
                    >
                      {item.label}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            </div>
            <div style={{ marginTop: "3vh" }}>
              {/* <Button
                startIcon={<ArrowBackIosIcon />}
                variant="text"
                color="secondary"
                style={{ margin: "16px 24px", display: isMobile ? "" : "none" }}
                onClick={() => history.goBack()}
              >
                Back
              </Button> */}
              <div className={classes.header}>Delivery Details</div>
              <div
                style={{
                  display: isMobile ? "block" : "flex",
                  marginTop: "3vh"
                }}
              >
                <Paper id="shipping" className={classes.details}>
                  <div className={classes.labelbold} style={{ padding: 24 }}>
                    Shipping
                  </div>
                  <div>
                    <DeliveryRates
                      delivery_details={delivery_details}
                      delivery_locations={delivery_locations}
                      pickup_address={pickup_address}
                      mrspeedy_settings={mrspeedy_settings}
                      ninjavan_settings={ninjavan_settings}
                      storeInfo={storeInfo}
                      customer_details={customer_details}
                      items={cartItems}
                      onChange={deliveryOnChange}
                      pickup_google_address={pickup_google_address}
                    />
                  </div>
                  <div
                    className="p-4 justify-content-end"
                    style={{
                      marginTop: "2vh",
                      display: isMobile ? "none" : "flex"
                    }}
                  >
                    <Button
                      variant="outlined"
                      disableElevation
                      className={classes.btnContinue}
                      onClick={() =>
                        history.push(`/shop/${storeInfo.site}/products`)
                      }
                    >
                      Continue Shopping
                    </Button>
                    <Button
                      id="proceed_to_payment"
                      variant="contained"
                      disableElevation
                      className={classes.btnCheckout}
                      disabled={isDisabled() || delivery_details.loading}
                      onClick={() =>
                        history.push(`/shop/${storeInfo.site}/payment`)
                      }
                    >
                      Proceed to Payment
                    </Button>
                  </div>
                </Paper>
                <Summary
                  loading={loading}
                  storeInfo={storeInfo}
                  cartItems={cartItems}
                  subtotal={subtotal}
                  total={total}
                  delivery_details={delivery_details}
                  voucher_discount={voucher_discount}
                  shipping_fee={shipping_fee}
                  proceed_to_checkout={() =>
                    history.push(`/shop/${storeInfo.site}/payment`)
                  }
                  disabled={isDisabled() || delivery_details.loading}
                  continue_shopping={true}
                />
              </div>
            </div>
          </div>
        </div>
        <VoucherModal
          id="voucher-modal"
          open={openVoucher}
          setOpen={setOpenVoucher}
        />
      </ThemeProvider>
    </div>
  );
};

export default DeliveryDetails;
