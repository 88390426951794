import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Chip
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import imgPlaceholder from "../../../../assets/images/product_placeholder.png";
import PriceDisplay, {
  WishlistItemPriceDisplay
} from "../../../common/template/components/PriceDisplay";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";
import ProductActions from "../../redux/actions/productActions";
import Utils from "../../../common/utils";
import history from "../../../../store/history";
import VariantPicker from "./variantPicker";
import FavoriteIcon from "@material-ui/icons/Favorite";
export default function CartDrawer(props) {
  const themeColor = useSelector(state => state.enterprise.main.theme);
  const enterprise = useSelector(state => state.enterprise.main.enterprise);
  const useStyles = makeStyles(theme => ({
    drawer: {
      backgroundColor: "#fff",
      width: 440,
      [theme.breakpoints.down("xs")]: {
        width: "100vw"
      }
    },
    header: {
      fontSize: 28,
      fontWeight: 600,
      color: "#2b2d32",
      fontFamily: "Inter"
    },
    items: {
      marginTop: "2vh",
      height: "40vh",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 4
      },
      "&::-webkit-scrollbar-track": {
        background: "#eee"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: themeColor.palette.primary.main
      }
    },
    itemSection: {
      display: "flex"
    },
    image: {
      borderRadius: 4,
      width: 65,
      height: 65,
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    columnItem: {
      border: "none",
      padding: "16px 0"
    },
    noItems: {
      textAlign: "center",
      padding: 20,
      fontSize: 12
    },
    label: {
      fontSize: 14,
      fontFamily: "Inter",
      color: "#2b2d32"
    },
    labelbold: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#2b2d32"
    },
    labelbold2: {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#2b2d32"
    },
    priceSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      bottom: 0,
      width: "100%"
    },
    btn: {
      borderRadius: 0,
      width: 25,
      height: 25,
      minWidth: 25
    },
    actionBtnSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "50%"
    },
    summary: {
      display: "flex",
      justifyContent: "space-between",
      padding: "15px 0"
    },
    btnCheckout: {
      color: "#fff",
      height: 40,
      backgroundColor: themeColor.palette.secondary.main,
      "&:hover": {
        color: "#fff",
        filter:
          themeColor.palette.secondary.main !== "#18B4A1"
            ? "brightness(0.8)"
            : "none",
        backgroundColor:
          themeColor.palette.secondary.main !== "#18B4A1"
            ? themeColor.palette.secondary.main
            : themeColor.palette.secondary.defaultHoverColor
      }
    },
    btnSelectVariant: {
      borderRadius: 0,
      border: "1px solid red",
      color: "red",
      fontSize: 10,
      fontWeight: "normal",
      height: 25,
      padding: 8
    },
    variantChip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    },
    replaceVariantHeader: {
      padding: 12,
      fontSize: 14,
      fontWeight: 600,
      borderBottom: "1px solid #d1d5dd",
      color:
        themeColor.palette.secondary.main !== "#18B4A1"
          ? themeColor.palette.secondary.main
          : themeColor.palette.secondary.defaultHoverColor
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const cart = useSelector(state => state.enterprise.main.cart);
  const cart_amount = useSelector(
    state => state.enterprise.main.cart.cart_amount
  );
  const [hasError, setHasError] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedVariant, setSelectedVariant] = React.useState("");
  const [variantUpdate, setVariantUpdate] = React.useState({
    variant_groups: [],
    variants: []
  });

  function productImage(item) {
    if (item.gallery && item.gallery.length) {
      return item.gallery[0].image;
    } else if (item.image) {
      return item.image;
    } else {
      return imgPlaceholder;
    }
  }

  function decrement(row) {
    let params = {
      ...row,
      quantity: row.quantity - 1
    };
    dispatch(ProductActions.updateQuantity(params));
  }

  function increment(row) {
    let params = {
      ...row,
      quantity: row.quantity + 1
    };
    dispatch(ProductActions.updateQuantity(params));
  }

  function removeFromCart(index) {
    dispatch(ProductActions.removeFromCart(index));
  }

  function handleSelectVariant(variants, value, variant) {
    let variantToInsert = { ...variant, variants: value };
    let newVariantList = [...variants];
    let isAlreadyListed = false;
    for (let variant of newVariantList) {
      if (variant["name"] === variantToInsert["name"]) {
        isAlreadyListed = true;
        variant.variants = variantToInsert.variants;
      }
    }
    const items = isAlreadyListed
      ? newVariantList
      : [...variants, variantToInsert];

    setVariantUpdate({
      ...variantUpdate,
      variants: items
    });
  }

  const isEqual = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  function handleConfirmVariant(product) {
    let productSelectionIds = product.productSelection.variants.map(i => i.id);
    let prevSelectionIds = product.prevSelection
      ? product.prevSelection.variants.map(i => i.id)
      : [];
    if (isEqual(productSelectionIds, prevSelectionIds)) {
      setAnchorEl(null);
    } else {
      dispatch(ProductActions.updateVariant(product));
      setAnchorEl(null);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkStock = product => {
    if (product.has_variants) {
      if (product.productSelection) {
        if (product.quantity >= product.productSelection.quantity) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (product.always_available) {
        return false;
      } else {
        if (product.quantity >= product.stock) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const getStocks = product => {
    if (product.has_variants) {
      return product.productSelection ? product.productSelection.quantity : -2;
    } else {
      if (product.always_available) {
        return -1;
      } else {
        return product.stock;
      }
    }
  };

  const show_error = product => {
    const stocks = getStocks(product);
    if (stocks === -2 || stocks === -1) {
      return "";
    } else if (stocks === 0) {
      return <small className="text-danger">Out of stock</small>;
    } else if (product.quantity > stocks) {
      return (
        <small className="text-danger">
          {" "}
          Selected quantity is more than available stocks.
        </small>
      );
    }
  };
  const [errors, setErrors] = React.useState([]);
  React.useEffect(() => {
    let items = cart.items;
    errors.length = 0;
    for (let i in items) {
      if (items[i].has_variants) {
        if (items[i].has_variants && items[i].productSelection) {
          if (items[i].productSelection.quantity <= 0) {
            errors.push(true);
          } else if (items[i].quantity > items[i].productSelection.quantity) {
            errors.push(true);
          } else {
            errors.push(false);
          }
        } else if (items[i].has_variants && !items[i].productSelection) {
          errors.push(true);
        }
      } else {
        if (items[i].always_available) {
          errors.push(false);
        } else if (items[i].stock <= 0) {
          errors.push(true);
        } else if (items[i].quantity > items[i].stock) {
          errors.push(true);
        }
      }
    }

    if (errors.includes(true)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [cart.items, props.open]);

  return (
    <div>
      <Drawer
        id="cart_drawer"
        anchor="right"
        open={props.open}
        onClose={props.toggleDrawer}
      >
        <div className={classes.drawer}>
          <div className="d-flex justify-content-end">
            <IconButton onClick={props.toggleDrawer}>
              <CloseIcon color="secondary" />
            </IconButton>
          </div>
          <div style={{ padding: "0 2em 3vh" }}>
            <div className={classes.header}>Your Cart</div>
            <div className={classes.items}>
              <Table>
                <TableBody>
                  {cart.items && cart.items.length > 0 ? (
                    cart.items.map((row, key) => (
                      <TableRow
                        id="cart_items"
                        key={key}
                        style={{ borderBottom: "1px solid #d1d5dd" }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.columnItem}
                        >
                          {row.registry && (
                            <div className="mb-3">
                              <Chip
                                icon={
                                  <FavoriteIcon style={{ color: "#f97663" }} />
                                }
                                size="small"
                                label={
                                  <div
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: 11
                                    }}
                                  >
                                    {row.registry.display_text}
                                  </div>
                                }
                              />
                            </div>
                          )}

                          <div className={classes.itemSection}>
                            <div
                              className={classes.image}
                              style={{
                                backgroundImage: `url(${productImage(row)})`
                              }}
                            />
                            <div
                              style={{
                                marginLeft: "1vw",
                                position: "relative",
                                width: "70%"
                              }}
                            >
                              <div className={classes.labelbold}>
                                {row.name}
                              </div>
                              <VariantPicker
                                index={key}
                                product={row}
                                productSelection={row.productSelection}
                                handleConfirmVariant={handleConfirmVariant}
                              />
                              <div className={classes.priceSection}>
                                <WishlistItemPriceDisplay
                                  symbol="₱"
                                  item={row}
                                  productSelection={row.productSelection}
                                />
                                <div className={classes.actionBtnSection}>
                                  <div>x</div>
                                  <div>{row.quantity}</div>
                                  <div className="d-flex">
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      className={classes.btn}
                                      style={{ borderRight: "none" }}
                                      onClick={() =>
                                        row.quantity === 1
                                          ? removeFromCart(key)
                                          : decrement(row)
                                      }
                                    >
                                      {row.quantity <= 1 ? (
                                        <DeleteIcon style={{ fontSize: 12 }} />
                                      ) : (
                                        <RemoveIcon style={{ fontSize: 12 }} />
                                      )}
                                    </Button>
                                    <Button
                                      disabled={checkStock(row)}
                                      variant="outlined"
                                      color="secondary"
                                      className={classes.btn}
                                      onClick={() => increment(row)}
                                    >
                                      <AddIcon style={{ fontSize: 12 }} />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              {show_error(row)}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <div className={classes.noItems}>
                      No item(s) in your cart
                    </div>
                  )}
                </TableBody>
              </Table>
            </div>
            <div style={{ marginTop: "5vh" }}>
              <div
                className={classes.summary}
                style={{ display: cart.items.length ? "" : "none" }}
              >
                <div className={classes.labelbold}>Summary</div>
                {/* <Link
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    color: "rgba(43,45,50,0.8)"
                  }}
                >
                  <img
                    src={tags}
                    width="15"
                    height="15"
                    style={{ marginRight: 5 }}
                  />
                  Vouchers
                </Link> */}
              </div>
              <div className={classes.summary}>
                <div className={classes.labelbold2}>Items</div>
                <div id="items" className={classes.labelbold2}>
                  {parseFloat(
                    cart.items.reduce((total, object) => {
                      return object.has_variants
                        ? parseFloat(total) +
                            (object.always_available
                              ? parseFloat(object.quantity)
                              : object.quantity > 0
                              ? parseFloat(object.quantity)
                              : 0)
                        : parseFloat(total) +
                            (object.always_available
                              ? parseFloat(object.quantity)
                              : object.stock > 0
                              ? parseFloat(object.quantity)
                              : 0);
                    }, 0)
                  )}
                </div>
              </div>
              <div
                className={classes.summary}
                style={{ borderBottom: "1px dashed #d1d5dd" }}
              >
                <div className={classes.labelbold2}>Subtotal</div>
                <div className={classes.labelbold2}>
                  ₱ {Utils.commafy(cart_amount)}
                </div>
              </div>
              <div
                className={classes.summary}
                style={{ borderBottom: "1px dashed #d1d5dd" }}
              >
                <div className={classes.labelbold2}>Total</div>
                <div className={classes.labelbold2}>
                  ₱ {Utils.commafy(cart_amount)}
                </div>
              </div>
              <div style={{ marginTop: "5vh" }}>
                <Button
                  id="proceed_to_checkout"
                  disableElevation
                  fullWidth
                  variant="contained"
                  className={classes.btnCheckout}
                  disabled={hasError}
                  onClick={() => {
                    history.push(`/enterprise/${enterprise.site_name}/cart`);
                    dispatch(ProductActions.showCartDrawer(false));
                  }}
                >
                  Proceed to Checkout
                </Button>
                <Button
                  style={{ marginTop: "1vh", height: 40 }}
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  onClick={() => {
                    history.push(
                      `/enterprise/${enterprise.site_name}/products`
                    );
                    props.toggleDrawer();
                  }}
                >
                  Continue Shopping
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
