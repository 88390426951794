import { ethers } from 'ethers';
import Web3 from 'web3';
import ABI from './GetABI.js';
import TokenABI from './TokenABI.js';
import { notification } from 'antd';
import apiConfig from '../common/apiConfig.js';
import METAMASK_POSSIBLE_ERRORS from './MetamaskErrorMessage';
import METAMASK_NETWORKS from './MetamaskNetworks';
const TOKEN_ADDRESS = apiConfig.tokenAddress;
const DOMAIN_VERSION = apiConfig.domainVersion;
const CHAIN_ID = apiConfig.chainId;

export const handleError = (message, errorCode) => {
  let errorMessage = message;

  if (errorCode !== undefined) {
    if (errorCode in METAMASK_POSSIBLE_ERRORS) {
      errorMessage = METAMASK_POSSIBLE_ERRORS[errorCode]?.message;
    }
  }

  notification.error({
    description: errorMessage,
    className: 'ant-notification-error',
    placement: 'bottomRight',
  });
};

export const handleSuccessMessage = (message) => {
  notification.success({
    description: message,
    className: 'ant-notification-success',
    placement: 'bottomRight',
  });
};

const handleMetamaskChecking = () => {
  if (!window.ethereum) {
    handleError('No Mestamask Installed');
    return false;
  }
  return true;
};

async function switchMetamaskNetwork(chainId) {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: Web3.utils.toHex(chainId) }],
    });
    return true;
  } catch (error) {
    if (error.code === 4902) {
      await addMetamaskNetwork(chainId);
    } else if (error.code === 4001) {
      handleError('User Rejected Switch Network.');
      return false;
    } else {
      handleError('Failed to switch to the network');
      return false;
    }
  }
}

async function addMetamaskNetwork(chainId) {
  try {
    if (chainId === '137') {
      await window.ethereum.request(METAMASK_NETWORKS.MaticMainnet);
    } else if (chainId === '80001') {
      await window.ethereum.request(METAMASK_NETWORKS.MumbaiTestnet);
    }
  } catch (error) {
    handleError('Failed to add new network');
  }
}

const getCurrentChainId = async () => {
  const web3 = new Web3(window.ethereum);
  const currentChainId = await web3.eth.getChainId();
  return currentChainId.toString();
};

export const getDisplayWallet = (wallet) => {
  let displayWallet = null;
  if (wallet)
    displayWallet =
      wallet.substr(0, 4) +
      '.....' +
      wallet.substr(wallet.length - 4, wallet.length);
  return displayWallet;
};

export const handlePersonalSign = async () => {
  handleMetamaskChecking();
  const message = 'Please sign this message to connect to Artifract.';

  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts',
  });
  const account = accounts[0];

  const sign = await window.ethereum.request({
    method: 'personal_sign',
    params: [message, account],
  });

  return { address: account, signature: sign };
};

export const handleGetCurrentWallet = async () => {
  if (handleMetamaskChecking()) {
    const currentChainId = await getCurrentChainId();

    if (apiConfig.chainId !== currentChainId) {
      const switchMetamask = await switchMetamaskNetwork(apiConfig.chainId);

      if (!switchMetamask) return false;
    }

    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    return account;
  }
};

export const handleDisconnectWallet = () => async () => {
  if (localStorage) {
    console.log('locals', localStorage);
    localStorage.removeItem('getwalletdisplay');
    localStorage.removeItem('getwallet');
    localStorage.removeItem('userId');
  }
};

export const handleGetBalance = async () => {
  handleMetamaskChecking();
  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts',
  });
  const account = accounts[0];

  const jsonAbi = ABI; // JSON ABI of the token contract
  const contractAddress = TOKEN_ADDRESS; // address of the token contract
  const tokenAddress = account; // address of which you want to get the token balance

  const web3 = new Web3(window.ethereum);
  const token = new web3.eth.Contract(jsonAbi, contractAddress);
  const balance = await token.methods.balanceOf(tokenAddress).call();

  return balance;
};

export const handleSendTransaction = async (sender, receiver, amount) => {
  handleMetamaskChecking();
  const amountHex = (amount * Math.pow(10, 18)).toString(16);

  const transactionParams = {
    from: sender,
    to: receiver,
    value: amountHex,
  };

  const request = await window.ethereum.request({
    method: 'eth_sendTransaction',
    params: [transactionParams],
  });
  return request;
};

export const handleGetNonce = async (walletAddress) => {
  const web3 = new Web3(window.ethereum);
  const contractInstance = new web3.eth.Contract(ABI, TOKEN_ADDRESS);
  const nonce = await contractInstance.methods.nonces(walletAddress).call();
  return nonce;
};

export const handleSignTransaction = async (
  walletAddress,
  amount,
  nonce,
  deadline,
  smartContract
) => {
  handleMetamaskChecking();
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // MetaMask requires requesting permission to connect users accounts
  const accts = await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(TOKEN_ADDRESS, TokenABI, provider);
  // Name of the token that will be pulled from the wallet
  const tokenName = await contract.name();

  // "EIP712Domain(string name,string version,address verifyingContract,bytes32 salt)"
  const domain = _getEIP712Domain(
    tokenName,
    TOKEN_ADDRESS,
    DOMAIN_VERSION,
    CHAIN_ID
  );
  // "Permit(address owner,address spender,uint256 value,uint256 nonce,uint256 deadline)"
  const types = {
    Permit: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ],
  };
  const message = {
    owner: walletAddress,
    spender: smartContract,
    value: parseInt(ethers.utils.parseUnits(amount.toString(), 6)), // 0.01 usdc
    nonce: Number(nonce), // Dont pass the nonce as big number
    deadline,
  };

  // Permit sig
  const permitSig = await signer._signTypedData(domain, types, message);
  // Check the permit sig signer
  const permitSigSigner = ethers.utils.verifyTypedData(
    domain,
    types,
    message,
    permitSig
  );
  // Should return true
  console.log(walletAddress === permitSigSigner.toLowerCase());

  const { v, r, s } = ethers.utils.splitSignature(permitSig);

  return {
    permitSig,
    v,
    r,
    s,
  };
};

const _getEIP712Domain = (name, tokenAddress, version = '1', chainId) => {
  let domain = {
    name: name,
    version: version,
    verifyingContract: tokenAddress,
  };
  chainId = ethers.BigNumber.from(chainId);
  if (
    [
      '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC POS
    ].includes(tokenAddress.toLowerCase())
  ) {
    domain = Object.assign(domain, {
      salt: ethers.utils.hexZeroPad(chainId.toHexString(), 32),
    });
  } else {
    domain = Object.assign(domain, {
      chainId: chainId,
    });
  }
  return domain;
};

export const handleMintSingleBlocksWithPermit = async (
  buyerAddress,
  sellerAddress,
  tokenUris,
  value,
  deadline,
  mintSignature,
  v,
  r,
  s,
  smartContract,
  vPfee,
  rPfee,
  sPfee,
  platformFee
) => {
  const web3 = new Web3(window.ethereum);
  let gasPrice = await web3.eth.getGasPrice();
  let total = parseInt(ethers.utils.parseUnits(value.toString(), 6));

  let totalAndPfee = value * platformFee;
  totalAndPfee = parseInt(
    ethers.utils.parseUnits(totalAndPfee.toFixed(6).toString(), 6)
  );
  const artifractContractInstance = new web3.eth.Contract(ABI, smartContract); // Contract or class instance of the artifract nft
  const mint = await artifractContractInstance.methods
    .mintSingleBlockWithPermit(
      // mintSigleBlockWithPermit -> Typo on contract function sorry.
      buyerAddress,
      tokenUris,
      total,
      sellerAddress,
      mintSignature,
      TOKEN_ADDRESS,
      {
        deadline: deadline,
        v: v,
        r: r,
        s: s,
      },
      {
        recipient: apiConfig.platformFeeRecipient, // platform fee recipient (add to env vars)
        amount: totalAndPfee, //platform fee = value * 0.05 (5% of the value)
        deadline: deadline,
        v: vPfee,
        r: rPfee,
        s: sPfee,
      }
    )
    .send({ gasPrice: gasPrice, from: buyerAddress }); // Set the gas price value to 70 gwei 70 gwei is for fasting minting 35-50 should be good na,

  return mint;
};
