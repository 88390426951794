import React from "react";
import { makeStyles, Container, Box, Grid } from "@material-ui/core";
import { Typography, Carousel } from "antd";
import banner from "../../assets/images/WEBbuilderbanner.png";
import Path from "../../Paths";
import history from "../../store/history";
import { Button } from "antd";
const BuilderEntryStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  websitePreview: {
    height: "100vh",
    width: "44vw"
  },
  builderWelcome: {
    height: "100vh",
    width: "44vw"
  },
  button: {
    backgroundColor: "#F97663",
    color: "#fff",
    width: "360px",
    marginTop: "3vh",
    fontWeight: "bold"
  }
}));

export default function WebsiteBuilderEntry(props) {
  const classes = BuilderEntryStyles();

  return (
    <div>
      <Box style={{ display: "flex" }}>
        <Box className={classes.builderWelcome}>
          <Container style={{ marginLeft: "4vw", marginTop: "30vh" }}>
            <Typography
              variant="h1"
              style={{ fontSize: "48px", color: "#2B2D33", fontWeight: 600 }}
            >
              Website Builder
            </Typography>
            <Typography style={{ fontSize: "20px", color: "#54575F" }}>
              Having your own website is just a few <br /> clicks away! Just
              answer a few <br />
              questions and we’ll do the rest for you.
            </Typography>

            <Button
              onClick={() => history.push(Path.BUILDER)}
              variant="contained"
              className={classes.button}
              size="large"
            >
              Start Building
            </Button>
          </Container>
        </Box>
        <Box className={classes.websitePreview}>
          <img
            src={banner}
            style={{
              backgroundSize: "cover",
              width: "100%",
              marginTop: "18vh"
            }}
          />
        </Box>

        {/* <Grid container spacing={0}>
        <Grid item xs={6}>
      
        </Grid>

        

        <Grid  className={classes.websitePreview} item xs={6}>
       
          <Container className={classes.websitePreview}>
            <Carousel autoplay>
              <div>
                <img src={welcome1} style={{ margin: "140px", width: "47%" }} />
              </div>
              <div>
                <img src={welcome2} style={{ margin: "140px", width: "47%" }} />
              </div>
              <div>
                <img src={welcome3} style={{ margin: "140px", width: "47%" }} />
              </div>
            </Carousel>
          </Container>
        </Grid>
      </Grid> */}
      </Box>
    </div>
  );
}
