import { makeStyles } from "@material-ui/core/styles";
const authStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginBotton: "2vh"
  },
  btnFacebook: {
    backgroundColor: "#3B5998",
    "&:hover": {
      backgroundColor: "#224080"
    },
    color: "#fff",
    width: "100%",
    height: 48,
    fontSize: 16,
    fontWeight: 600
  },
  link: {
    fontSize: 13,
    color: "#F97663",
    marginnTop: 5,
    fontWeight: "600"
  }
}));

export default authStyles;
