import React from "react";
import {
  makeStyles,
  Button,
  ButtonGroup,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import SentroLoading from "../common/loading";
import SideMenu from "../dashboard/sideMenu";
import cardImg from "../../assets/images/voucher_webcard.svg";
import VoucherSortSection from "./voucherSortSection";
import CreateVoucher from "./createVoucher";
import EditVoucher from "./editVoucher";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoreInfo,
  getProductCategories,
  setVoucherFilters,
  getVouchers,
  setSelectedVoucher,
  filterVouchers,
} from "./actions";
import moment from "moment";
import VoucherTable from "./voucherTable";
import ViewVoucher from "./viewVoucher";
import SnackbarAlert from "../common/SnackbarAlert";
import Skeleton from "@material-ui/lab/Skeleton";
import VoucherEmptyState from "./voucherEmptyState";
import { Add } from "@material-ui/icons";
import FreeTrial from "../subscription/freeTrial";
import { getStore } from "../websiteBuilder/websiteBuilderActions";
const VouchersPage = (props) => {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#FAFAFA",
      overflowX: "hidden",
    },
    side: {
      position: "fixed",
      display: "flex",
      height: "100%",
    },
    headerSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      fontSize: 28,
      color: "#002c42",
      fontWeight: 600,
    },
    card: {
      display: "flex",
      marginTop: "3vh",
      border: "1px solid #d1d5dd",
      borderRadius: 10,
      padding: 36,
      backgroundColor: "#fff",
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const getLanguage = localStorage.getItem("language");
  const loading = useSelector((state) => state.vouchers.loading);
  const stores = useSelector((state) => state.vouchers.stores);
  const productCategories = useSelector(
    (state) => state.vouchers.product_categories
  );
  const vouchers = useSelector((state) => state.vouchers.vouchers);
  const voucherListFilter = useSelector(
    (state) => state.vouchers.voucherListFilter
  );
  const voucherFilters = useSelector((state) => state.vouchers.voucherFilters);
  const subscription = useSelector((state) => state.subscription.subscription);
  const [language, setLanguage] = React.useState(getLanguage);
  const [openCreateVoucher, setOpenCreateVoucher] = React.useState(false);
  const [openEditVoucher, setOpenEditVoucher] = React.useState(false);
  const [openViewVoucher, setOpenViewVoucher] = React.useState(false);
  const [categories, setCategories] = React.useState([]);

  const [state, setState] = React.useState({
    openAlert: false,
    alertType: "success",
    alertMessage: "",
  });
  const [values, setValues] = React.useState({
    voucher_code: "",
    voucher_type: "",
    discount_value: "",
    applies_to: "",
    category: "",
    minimum_requirements: "",
    minimum_purchase_amount: 0,
    free_quantity: 0,
    usage_limit_to_one: false,
    usage_limit_no_times: false,
    usage_limit_value: 0,
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
  });

  function closeAlertBox() {
    setState({
      ...state,
      openAlert: false,
      alertType: "success",
      alertMessage: "",
    });
  }
  function handleEditVoucher(voucher) {
    setValues({
      ...voucher,
      start_date: moment(voucher.start_datetime),
      start_time: moment(voucher.start_datetime),
      end_date: moment(voucher.end_datetime),
      end_time: moment(voucher.end_datetime),
    });
    dispatch(setSelectedVoucher(voucher));
    setOpenEditVoucher(true);
  }

  function handleViewVoucher(voucher) {
    setValues(voucher);
    dispatch(setSelectedVoucher(voucher));
    setOpenViewVoucher(true);
  }
  function getVoucherFilter(params) {
    console.log(params);
  }

  React.useEffect(() => {
    dispatch(getStore());
    fetch_vouchers(voucherFilters);
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    fetch_vouchers(voucherFilters);
  }, [voucherFilters]);

  React.useEffect(() => {
    setLanguage(getLanguage);
  }, [getLanguage]);

  const setFilters = (filters) => {
    dispatch(setVoucherFilters(filters));
  };

  const onSortRequest = (order, orderBy, page, rowsPerPage) => {
    dispatch(
      setVoucherFilters({
        order,
        orderBy,
        page,
        rowsPerPage,
      })
    );
  };

  const fetch_vouchers = (params) => {
    dispatch(filterVouchers(params));
  };

  console.log(voucherListFilter);
  return (
    <div className={classes.root}>
      <SentroLoading open={loading} />
      {!subscription.free_trial_availed &&
      subscription.subscription_type === null ? (
        <>
          <div
            style={{ fontSize: 28, fontWeight: 600, color: "#002c42" }}
            className="my-2"
          >
            Vouchers
          </div>
          <FreeTrial
            header="Give your buyers a discount"
            subheader="Attract sales when you create vouchers and plan the best deals for your products."
          />
        </>
      ) : openCreateVoucher || openEditVoucher || openViewVoucher ? null : (
        <div className={classes.content}>
          {voucherListFilter.length > 0 ? (
            <div>
              <div className={classes.headerSection}>
                <div className={classes.title}>Vouchers</div>
                <Button
                  id="create_btn"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => setOpenCreateVoucher(true)}
                >
                  Create a Voucher
                </Button>
              </div>

              <div>
                <VoucherSortSection
                  id="voucher_sort_section"
                  onChange={setFilters}
                  filter={voucherFilters}
                  items_count={voucherListFilter}
                />
              </div>
              <div style={{ marginTop: "3vh" }}>
                <VoucherTable
                  id="voucher_table"
                  handleEditVoucher={handleEditVoucher}
                  handleViewVoucher={handleViewVoucher}
                  onSortRequest={onSortRequest}
                  state={state}
                  setState={setState}
                />
              </div>
            </div>
          ) : (
            <div className={classes.card}>
              {loading ? (
                <Skeleton variant="rect" height={150} width={250} />
              ) : (
                <img src={cardImg} style={{ width: "auto", height: 150 }} />
              )}

              <div style={{ margin: "auto", padding: "0 30px 0 85px" }}>
                {loading ? (
                  <Skeleton variant="text" height={45} width={500} />
                ) : (
                  <div
                    style={{ fontSize: 14, display: "flex", color: "#54575f" }}
                  >
                    Boost your sales by creating your own promos using Sentro's
                    vouchers feature. Start creating vouchers now!
                  </div>
                )}
                {loading ? (
                  <Skeleton variant="text" height={45} width={500} />
                ) : (
                  <Button
                    style={{ marginTop: "3vh" }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => setOpenCreateVoucher(true)}
                  >
                    Create a Voucher
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <CreateVoucher
        id="create_voucher"
        setOpen={setOpenCreateVoucher}
        open={openCreateVoucher}
        values={values}
        setValues={setValues}
        state={state}
        setState={setState}
        categories={categories}
      />

      <EditVoucher
        id="edit_voucher"
        setOpen={setOpenEditVoucher}
        open={openEditVoucher}
        values={values}
        setValues={setValues}
        state={state}
        setState={setState}
        categories={categories}
      />
      <ViewVoucher
        id="view_voucher"
        setOpen={setOpenViewVoucher}
        open={openViewVoucher}
        values={values}
        setValues={setValues}
        categories={categories}
        handleEditVoucher={handleEditVoucher}
        state={state}
        setState={setState}
      />

      <SnackbarAlert
        open={state.openAlert}
        alertType={state.alertType}
        message={state.alertMessage}
        closeAlertBox={closeAlertBox}
      />
    </div>
  );
};

export default VouchersPage;
