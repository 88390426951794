import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  useMediaQuery,
  Drawer
} from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import { useLocation } from "react-router-dom";
import cart from "../../../../../assets/images/enterprise/cart.svg";
import purchased from "../../../../../assets/images/enterprise/purchased.svg";
import discount from "../../../../../assets/images/enterprise/discounts.svg";
import wishlist from "../../../../../assets/images/enterprise/wishlist.svg";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import history from "../../../../../store/history";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useTheme } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import MarketplaceActions from "../../../redux/actions/marketplaceActions";
import ProductActions from "../../../redux/actions/productActions";
export default function Sidebar(props) {
  const enterprise = useSelector(state => state.enterprise.main.enterprise);
  const useStyles = makeStyles(theme => ({
    root: {
      width: 240,
      backgroundColor: "#fff",
      border: "1px solid #eee",
      borderRadius: "0px 16px 0px 0px",
      height: "100%",
      overflow: "auto"
    },
    listLabel: {
      color: "#002c42",
      fontWeight: 500,
      fontSize: 14
    },
    listBtn: {
      padding: "14px 14px 14px 0",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "space-between"
    },
    drawer: {
      backgroundColor: "#fff",
      width: 220
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const currentLoc = location.pathname;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [active, setActive] = React.useState("");

  React.useEffect(() => {
    if (currentLoc.includes(`/purchased`)) {
      setActive(
        `/enterprise/${enterprise.site_name}/buyer_dashboard/purchased`
      );
    } else {
      setActive(`/enterprise/${enterprise.site_name}/buyer_dashboard`);
    }
  }, [enterprise]);

  const goToMarketplace = () => {
    history.push(`/enterprise/${enterprise.site_name}`);
    dispatch(ProductActions.getBuyerCart(enterprise.site_name));
  };

  const ListItem = withStyles({
    root: {
      "&$selected": {
        backgroundColor: "#e5f2fd"
      },
      "&$selected:hover": {
        backgroundColor: "#e5f2fd"
      },
      "&:hover": {
        backgroundColor: "#e5f2fd"
      }
    },
    selected: {}
  })(MuiListItem);

  const listContent = [
    {
      icon: cart,
      label: "My Cart",
      key: "cart",
      path: `/enterprise/${enterprise.site_name}/buyer_dashboard`,
      id: "cart"
    },
    {
      icon: purchased,
      label: "Purchased",
      key: "purchased",
      path: `/enterprise/${enterprise.site_name}/buyer_dashboard/purchased`,
      id: "purchased"
    },
    {
      icon: discount,
      label: "Discounts",
      key: "discount",
      path: "/dashboard/discounts",
      id: "discount",
      isDisabled: true
    },
    {
      icon: wishlist,
      label: "Wishlist",
      key: "wishlist",
      path: `/enterprise/${enterprise.site_name}/buyer_dashboard/wishlist`,
      id: "wishlist"
    }
  ];

  const listContent2 = [
    {
      icon: (
        <AccountCircleOutlinedIcon style={{ color: "#002c42", fontSize: 18 }} />
      ),
      label: "My Account"
    },
    {
      icon: <HelpOutlinedIcon style={{ color: "#002c42", fontSize: 18 }} />,
      label: "Help Center"
    },
    {
      icon: (
        <ExitToAppOutlinedIcon style={{ color: "#002c42", fontSize: 18 }} />
      ),
      label: "Log Out",
      onClick: () => dispatch(MarketplaceActions.logout(enterprise))
    }
  ];

  function drawerMenu() {
    return (
      <div>
        <List style={{ borderBottom: "1px solid #d1d5dd" }}>
          {listContent.map(item => (
            <ListItem
              disabled={item.isDisabled}
              onClick={() => {
                history.push(item.path);
                setActive(item.path);
                props.toggleDrawer();
              }}
              button
              style={{
                padding: 16,
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: "flex" }}>
                <ListItemIcon style={{ minWidth: 56 }}>
                  <img src={item.icon} alt={item.icon} />
                </ListItemIcon>
                <div className={classes.listLabel}>{item.label}</div>
              </div>
            </ListItem>
          ))}
        </List>
        <List>
          {listContent2.map(item => (
            <ListItem
              disabled={item.disabled}
              onClick={item.onClick}
              button
              style={{
                padding: 16,
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: "flex" }}>
                <ListItemIcon style={{ minWidth: 56 }}>
                  {item.icon}
                </ListItemIcon>
                <div className={classes.listLabel}>{item.label}</div>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
  return (
    <>
      {isMobile ? (
        <Drawer
          id="drawer"
          anchor="right"
          open={props.open}
          onClose={props.toggleDrawer}
        >
          <div className={classes.drawer}>
            <div
              style={{ borderBottom: "1px solid #d1d5dd" }}
              className="d-flex justify-content-end"
            >
              <IconButton onClick={props.toggleDrawer}>
                <CloseIcon color="secondary" />
              </IconButton>
            </div>
          </div>
          {drawerMenu()}
        </Drawer>
      ) : (
        <div className={classes.root}>
          <div>
            <div
              style={{
                padding: "24px",
                borderBottom: "1px solid #eee",
                display: "flex",
                alignItems: "center"
              }}
            >
              <ArrowBackIosIcon
                onClick={goToMarketplace}
                style={{ color: "#1e4b83", fontSize: 16, marginRight: "1vw" }}
              />
              <div
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  color: "#1e4b83",
                  fontWeight: 600,
                  textTransform: "capitalize"
                }}
              >
                {enterprise.name}
              </div>
            </div>
            <List style={{ padding: 0 }}>
              <div>
                {listContent.map(item => (
                  <ListItem
                    button
                    id={item.id}
                    selected={active === item.path}
                    className={classes.listBtn}
                    disabled={item.isDisabled}
                    onClick={() => {
                      history.push(item.path);
                      setActive(item.path);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <ListItemIcon style={{ minWidth: 56 }}>
                        <img
                          src={item.icon}
                          alt={item.icon}
                          style={{ margin: "auto" }}
                        />
                      </ListItemIcon>
                      <div className={classes.listLabel}>{item.label}</div>
                    </div>
                  </ListItem>
                ))}
              </div>
            </List>
          </div>
        </div>
      )}
    </>
  );
}
