import axios from "axios";
import apiConfig from "../../../../common/apiConfig";
import history from "../../../../store/history";

class Services {
  static async getMarketplace(name) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/marketplace/${name}/`
        // {
        //   headers: {
        //     Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        //   }
        // }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getGlobalCategories(name) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/global_category/`
        // {
        //   headers: {
        //     Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        //   }
        // }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async filteredGlobalCategories(site) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${site}/categories`
        // {
        //   headers: {
        //     Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        //   }
        // }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async getMerchants(enterprise) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/merchants`
        // {
        //   headers: {
        //     Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        //   }
        // }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async getMerchant(enterprise, merchant) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/merchants/${merchant}/`
        // {
        //   headers: {
        //     Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        //   }
        // }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async getMerchants(site, category) {
    try {
      let response = await axios.get(
        `${
          apiConfig.apiURL
        }/api/enterprise/${site}/merchants/?category=${encodeURIComponent(
          category
        )}`
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async signup(site, params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/${site}/signup/`,
        params
      );

      return response;
    } catch (error) {
      throw error.message;
    }
  }

  static async login(site, params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/${site}/login/`,
        params
      );
      let data = response.data;
      if (data.token != null && data.token != "") {
        sessionStorage.setItem("TOKEN", data.token);
        sessionStorage.setItem("CURRENT_USER", JSON.stringify(data.user));
        let existing = sessionStorage.getItem("CURRENT_USER");
        existing = existing ? JSON.parse(existing) : {};
        existing["enterprise"] = site;
        existing["role"] = data.role;
        sessionStorage.setItem(
          "CURRENT_USER",
          JSON.stringify({
            ...existing,
            password_change: data.password_change
          })
        );
      }
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async forgotPassword(site, email) {
    let params = {
      email: email
    };
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/${site}/forget_password/`,
        params
      );
      localStorage.setItem("CHANGE_PASSWORD_EMAIL", params.email);
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async changePassword(site, password) {
    try {
      let response = await axios.put(
        `${apiConfig.apiURL}/api/enterprise/${site}/change_password/`,
        { new_password: password },
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async verifyCode(site, token) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${site}/forget_password/?token=${token}`
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async scheduleDemo(values) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/schedule-demo/`,
        values
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }
}
export default Services;
