import React from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import Text from "../../common/text";
import apiConfig from "../../common/apiConfig";

export default function TermsAndConditions(props) {
  const { labelFontSize } = props;
  const useStyles = makeStyles(theme => ({
    accntData: {
      marginTop: "3vh",
      display: "flex",
      textAlign: "center",
      flex: 12,
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    relDocuments: {
      flex: 6,
      display: "block",
      padding: "0 10px",
      [theme.breakpoints.down("xs")]: {
        flex: 12,
        padding: 0,
        marginTop: 10
      }
    }
  }));
  const classes = useStyles();
  const bodyFontColor = "#54575F";
  const blackish = "#2B2D33";
  const subheaderStyle = {
    fontSize: labelFontSize,
    color: blackish,
    fontWeight: 600
  };
  return (
    <div className={classes.content}>

                  <Box>
                    <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                      Kindly review these Terms and Conditions of Use before
                      proceeding. Any use of this website creates a binding
                      agreement and acceptance to comply with these Terms and
                      Conditions. If you do not agree to these terms, you may
                      exit this website immediately.
                    </Typography>
                  </Box>

                  <Box style={{ marginTop: "5vh" }}>
                    <Typography style={subheaderStyle}>About Sentro</Typography>
                    <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                      Sentro (sentro.ph) (the “Platform,” “website”) is the
                      operating business for micro, small and medium
                      enterprises. It helps MSMEs tech-up by providing them with
                      tools to jump-start, run and scale their business. The
                      platform is built and owned by UBX Philippines Corporation
                      (“UBX PH”)
                    </Typography>
                  </Box>

                  <Box style={{ marginTop: "5vh" }}>
                <Typography
                  style={{ fontSize: labelFontSize, fontWeight: 600, color: blackish }}
                >
                  User Agreement
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  The following are the terms of the legal agreement (the
                  “Agreement”) between you and Sentro that will govern your use
                  of the Platform through{" "}
                  <a onClick={() => window.open(`${apiConfig.localHost}`)}>
                    {" "}
                    sentro.ph
                  </a>
                  . The Platform is owned and operated by UBX PH, a duly
                  organized and existing entity under the laws of the Republic
                  of the Philippines with the office address at 33rd Floor
                  UnionBank Plaza, Meralco Ave. corner Onyx and Sapphire Roads,
                  Ortigas Center, Pasig City, Philippines. By accessing,
                  browsing and/or using the Platform, you acknowledge that you
                  have read, understood, and agree to be bound by the Terms and
                  Conditions of this Agreement and to comply with all applicable
                  laws and regulations. The Terms and Conditions of this
                  Agreement form an essential basis of the agreement between you
                  and UBX PH.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  UBX PH reserves the right to amend this Agreement at any time
                  and changes shall be posted in the Platform. All changes shall
                  have immediate effect and your continued use of the Platform
                  is deemed a continuing acceptance of, understanding or
                  agreement to all the provisions of this Agreement. Similarly,
                  UBX PH may terminate, suspend, amend, or restrict access to
                  all or any part or feature of the Platform without notice or
                  liability.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography
                  style={{ fontSize: labelFontSize, fontWeight: 600, color: blackish }}
                >
                  Limitations of Use
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  The copyright in all materials on this website and in the
                  Platform, including without limitation, the text, data,
                  articles, design, source code, software, photos, images and
                  other information (collectively the "Content") is owned by UBX
                  PH, protected by Philippine laws and international copyright
                  laws or treaties. Any Content may not be copied, reproduced,
                  distributed, republished, displayed, posted or transmitted in
                  any form or by any means, including, but not limited to,
                  electronic, mechanical, photocopying, recording, or otherwise,
                  without the express prior written consent of UBX PH as
                  copyright owner. The content is and shall remain the property
                  of UBX PH at all times. You may not modify, participate in the
                  sale or transfer of, or create derivative works based on any
                  Content, in whole or in part. The use of the Content on any
                  other website, including by linking or framing, or in any
                  networked computer environment for any purpose is prohibited
                  without UBX PH’s prior written consent. The prohibition
                  likewise extends to the "mirroring" of any Content contained
                  in this website or the Platform on any server. Any
                  unauthorized use of any Content shall be construed as a
                  violation of the applicable laws on copyright, trademark or
                  intellectual property, among others.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  All data obtained from or provided by UBX PH, regardless of
                  the method of delivery, is explicitly prohibited from
                  publication and distribution and is subject to UBX PH’s data
                  terms of use. Moreover, any data provided by UBX PH,
                  regardless of the method of delivery, cannot be used for any
                  competing purpose and such data may only be used for the
                  transactions performed in Sentro or with other products or
                  services of UBX PH and may permit loan or product applications
                  using the products or services of UBX PH.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  The use of the website, Platform and their Contents may only
                  be used for lawful purposes. All participants in the website
                  or Platform are prohibited from using the site, Platform and
                  their contents that would constitute a violation of any
                  applicable law, regulation, rule or ordinance of any
                  nationality, state, or locality or of any international law or
                  treaty, or that could give rise to any civil, criminal, or
                  administrative liability to UBX PH or any third party. Any
                  unauthorized use of the website, Platform and their Contents,
                  including but not limited to, unauthorized entry into UBX PH’s
                  systems, misuse of passwords, or misuse of any information
                  posted on the Platform is strictly prohibited. Any eligibility
                  for a particular product or service in the Platform is subject
                  to the final determination by UBX PH or its nominees or
                  authorized third parties.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography
                  style={{ fontSize: labelFontSize, fontWeight: 600, color: blackish }}
                >
                  Prohibited Use of the Platform
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  Once you sign up for use of Sentro, you are giving your
                  continuing consent and permission for Sentro, UBX PH or its
                  authorized representatives or third parties to conduct
                  checking, inquiry, validation or verification of the
                  information you have provided in your Sentro account. Any
                  derogatory findings from such checking, inquiry, validation or
                  verification may, at Sentro’s or UBX PH’s sole discretion, be
                  grounds to immediately suspend, refuse, terminate, discontinue
                  or deny your continuous access or use of Sentro and/or its
                  products and services, including taking down the e-store
                  website you have made in Sentro. Derogatory findings refer to
                  information that was provided in Sentro that is false,
                  inaccurate, misleading, or fraudulent. This is without
                  prejudice to any civil, criminal or administrative action that
                  UBX PH may bring against you for any liability brought about
                  by such false, inaccurate, misleading or fraudulent
                  information.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  Similarly, you and your business cannot use Sentro to offer to
                  sell, offer or sell products or services that are prohibited
                  by law, rules and regulations or are contrary to good morals,
                  customs or public policy. Sentro or UBX PH reserves the right
                  to conduct discreet checking, tests, inquiries, simulations,
                  validations or verifications of the products or services you
                  or your business is offering in Sentro either on its own or
                  through third persons. Should the checking, test, inquiry,
                  simulation, validation or verification result in a finding
                  that the product or service you are offering in Sentro is
                  prohibited by law, rules and regulations or is contrary to
                  good morals, customs or public policy, Sentro or UBX PH, at
                  its sole discretion, has the right to immediately suspend,
                  refuse, terminate, discontinue or deny your access or use of
                  Sentro or any of its services, including taking down the
                  e-store website you have made in Sentro.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  Should Sentro or UBX PH come across information or come to
                  know of any information or receives any complaint pertaining
                  to you, your business or your product or service or your use
                  of Sentro, it has the right to inquire into, investigate,
                  examine, validate or verify such information or knowledge on
                  its own and the results thereof may be grounds for Sentro or
                  UBX PH to immediately suspend, refuse, terminate, discontinue
                  or deny your access or use of Sentro or any of its services
                  temporarily or permanently.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography
                  style={{ fontSize: labelFontSize, fontWeight: 600, color: blackish }}
                >
                  Trademarks
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  UBX PH logo and all related logos (collectively the
                  "trademarks") are trademarks or service marks of UBX PH. Other
                  company, product, and service names and logos used and
                  displayed on this website or in the Platform may be trademarks
                  or service marks owned by other entities participating in the
                  Platform. Nothing in this website or Platform should be
                  construed as granting, by implication, estoppel, or otherwise,
                  any license or right to use any of the trademarks displayed in
                  this website or Platform without the prior written consent of
                  the trademark owners.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography
                  style={{ fontSize: labelFontSize, fontWeight: 600, color: blackish }}
                >
                  Consent to Doing Business Electronically
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  You may access your profile by signing in to the Platform
                  using your web browser or your mobile device.You allow Sentro
                  to impose fees for the use of its services such as payment
                  channels. The applicable fees are posted in the website and
                  you will be informed of the exact amount to be collected from
                  you for every transaction that you will make in Sentro
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  Signing up to the Platform shall be done via website, Facebook
                  Messenger and in the future, we may feature signup via mobile
                  app. During sign up, you must provide the necessary
                  information needed such as, but not limited to, your complete
                  name, email address, mobile number, legal business name,
                  company position, company industry, business type, business
                  contact information, personal or business documents, financial
                  statements, identification cards, etc. It shall be assumed
                  that all the information you have provided are correct, true
                  and accurate. The information you have provided can and will
                  be used as a basis for allowing you to avail of certain
                  products or services offered in the Platform. Whenever any
                  information provided turns out to be false or inaccurate or
                  misleading but was used as a basis to enter into a valid or
                  binding agreement, the participant who provided such false,
                  inaccurate or misleading information shall be considered as
                  acting in a fraudulent, deceptive or illegal manner and may be
                  held criminally or civilly liable for such. UBX PH may
                  terminate, suspend, amend, or restrict the Participant’s
                  access to all or any part of the Platform due to such
                  fraudulent, deceptive or illegal action.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  Once signed up, the Platform will allow you to browse SME
                  (small and medium enterprise) productivity solutions and other
                  products and services that are made available by participating
                  partners. You can filter solutions, products or services based
                  on specific categories and subscribe to a particular solution,
                  product or service. Applying for a solution, product or
                  service will require you to submit your application and
                  consent to the requisite Terms & Conditions of the Solution,
                  Product or Service Partner. Once approved for the chosen item,
                  the Platform will allow you to receive and accept the
                  following communications sent by the Partner: (1) subscription
                  or transaction notice; (2) order forms; (3) transactions; (4)
                  documents, and; (5) information as required by the law and
                  participating partners, among others.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  You agree to assume full responsibility and liability for all
                  transactions made by or under your profile through the use of
                  the Platform. It is understood that the password is known only
                  to you and, as such, any transaction effected using your
                  password and/or one-time PIN (OTP) shall be conclusively
                  presumed to have been done, executed or authorized by you.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  You agree that the Platform, at its sole discretion, is
                  entitled to act on the instructions it has received from you
                  after the correct entry of your password and/or one-time PIN,
                  or your other biometric authentication methods such as, but
                  not limited to, fingerprint scanning, facial recognition or
                  retina scan. You further agree that the Platform shall not be
                  liable and you agree to indemnify the Platform for any loss,
                  damages or costs the Platform incurs for acting in accordance
                  with or based on instructions it has received from you.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  This section informs you of your rights when receiving
                  electronic communications from the Platform.
                </Typography>
              </Box>
              <Box style={{ marginLeft: 15 }}>
                <Box style={{ marginTop: "5vh" }}>
                  <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                    <strong>Electronic Communications</strong>. You agree that
                    all communications from UBX PH relating to your use of the
                    Platform or related services may be provided or made
                    available to you electronically by electronic mail, SMS, at
                    the Platform, or mobile app.
                  </Typography>
                </Box>

                <Box style={{ marginTop: "5vh" }}>
                  <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                    <strong>Scope of Consent</strong>. Your consent to receive
                    electronic communications and do business electronically,
                    and our agreement to do so, applies to all your interactions
                    or transactions to which such electronic communications
                    relate, whether between you and UBX PH or participating
                    Partners involved in your transaction.
                  </Typography>
                </Box>

                <Box style={{ marginTop: "5vh" }}>
                  <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                    <strong>Hardware and Software Requirements</strong>. To
                    access and retain the communications electronically, you
                    will need to use a device with an internet connection and an
                    up-to-date browser capable of attaching files in the form of
                    portable document format (PDF), images (JPG/PNG), and other
                    file types.
                  </Typography>
                </Box>

                <Box style={{ marginTop: "5vh" }}>
                  <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                    <strong>Mobile Technology</strong>. Mobile devices such as
                    tablets, smart phones or similar devices should be able to
                    access and retain electronic communications when accessing
                    the website or Platform.
                  </Typography>
                </Box>

                <Box style={{ marginTop: "5vh" }}>
                  <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                    <strong>Withdrawing Consent</strong>. You may withdraw your
                    consent to receive electronic communications in the manner
                    described below. Once consent is withdrawn, no further
                    transactions will be allowed and any pending transactions
                    shall be considered cancelled. The withdrawal of your
                    consent will not affect the legal validity and
                    enforceability of electronic communications provided or
                    business transacted prior to the withdrawal of your consent.
                  </Typography>
                </Box>

                <Box style={{ marginTop: "5vh" }}>
                  <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                    <strong>Changes in Your Contact Information</strong>. You
                    agree to keep us informed of any changes in your mobile
                    number, email address, and other contact information you
                    have provided to enable continuous receipt of electronic
                    communications from the website. Your information can be
                    updated at any time by accessing your profile in the
                    website.
                  </Typography>
                </Box>
              </Box>
              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>
                  Password Security
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  It is your duty to keep your password and other profile
                  information confidential and secure at all times. The website
                  provides tips on confidentiality and security. If you believe
                  that your password, confidential information, access points
                  (i.e., mobile phones, tablets, laptop, computers, etc.) have
                  been compromised, lost or stolen or used without your
                  permission, you should contact UBX PH immediately to
                  deactivate your compromised ID or password. The website is not
                  responsible for any loss, damage or cost incurred that results
                  from the compromise or loss or unauthorized use of your
                  password, confidential information or access points or any
                  transactions resulting therefrom.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>
                  Data Storing and Monitoring
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  In order to provide the products and services in the website,
                  it needs to collect personal and business information from
                  you. By providing these information, you consent to the
                  storing, processing, and monitoring of these information for
                  purposes which may include: Know-Your- Customer, risk,
                  compliance or anti-fraud checks, credit scoring, or
                  marketing/cross-selling of financial or non-financial
                  products. Furthermore, you consent to us providing your
                  information to the participating SME partners that you select
                  when subscribing or availing a solution, product or service.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>
                  New Features or Services
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  The website may, from time to time, introduce new features or
                  services. Any new feature or service will be announced or
                  posted in the website. Your continuous use of the website or
                  maintenance of your account in the website is understood to be
                  your acceptance of these new features or services.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  Features or services that have third-party dependencies or
                  fulfilment shall be further subject to the terms and
                  conditions of the third-party provider in addition to these
                  Terms and Conditions of Use. You are encouraged to read their
                  terms and conditions so that you will be aware of the limits
                  of their liabilities, if any. Sentro or UBX PH shall not be
                  liable for any acts, deeds, omissions or losses caused by
                  third-party providers. But if you have complaints or issues
                  against third-party providers in Sentro, we will listen to
                  them with the goal of improving the value proposition of
                  Sentro to your business.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>
                  Non-Liability for Loss, Damage or Profit
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  The website shall not be liable for any direct, indirect,
                  incidental or consequential loss or damage or loss of profit
                  of whatever nature arising from:
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  1. Disruption, failure or delay relating to or in connection
                  with the website and/or its services due to circumstances
                  beyond the control of UBX PH, unforeseen events, fortuitous
                  events such as, but not limited to, prolonged power outages,
                  breakdown in computers, software, operating systems and
                  telecommunication facilities, typhoons, floods, public
                  disturbances, calamities or other acts of nature or Acts of
                  God.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  2. Any fraudulent or unauthorized access or utilization of the
                  website due to negligence, theft, unauthorized disclosure of
                  personal information, mobile phone numbers, emails, passwords
                  or unauthorized usage of biometrics or other security measures
                  used in the website with or without the participation of the
                  information owner.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  3. Inaccurate, incomplete or delayed information received due
                  to disruption or failure of any information or communication
                  facilities or telecommunication network, internet or software
                  or hardware.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  4. Failure or inability to use the website.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  5. Any disclosure of information concerning your profile
                  and/or transactions to persons for any reason whatsoever,
                  including, but not limited to, wiretapping of communication
                  lines or erroneous connection by telecommunication switches,
                  or errors in transmitted information due to faulty lines, and
                  any and all forms of high technology surveillance or fraud.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  6. Loss of your mobile phone, mobile devices and other
                  electronic devices enrolled in the Platform.
                </Typography>
              </Box>

              <Box style={{ marginTop: "8vh" }}>
                <Typography style={subheaderStyle}>Fee Changes</Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  UBX PH reserves the right to modify the transactions fees,
                  charges, or billing methods at any time without prior notice.
                  However, the updated schedule or information of applicable
                  transaction fees, charges or billing methods shall be posted
                  in the website. Your continued use of the website after the
                  application of any change shall indicate your agreement to and
                  acceptance of such changes. Non-payment of the fees or charges
                  for the use of the website or any of its solutions, products
                  or services is a ground for UBX PH to suspend, restrict or
                  deny your use or access or transaction in the website.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>Acceptable Use</Typography>
                <Typography style={{ fontSize: labelFontSize, color: blackish }}>
                  1. Plug-ins
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  You warrant and undertake that the Buyer Information you input
                  using Plug-in(s) Feature is correct and you undertake your
                  best efforts to verify these Buyer Information before
                  approving any orders for processing.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: blackish }}>
                  2. Manipulation of Ratings and Reviews.
                </Typography>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  Ratings and Reviews Feature is created to increase public
                  trust for your business, protect Buyers, and maintain the
                  integrity of the Platform. You warrant and undertake not to
                  manipulate ratings and reviews of your Account by allowing
                  Buyers to independently rate and review their experience with
                  you. If you are found to be manipulating your Ratings and
                  Reviews through any means, UBX PH, at its sole discretion, may
                  deactivate your profile or account, suspend, restrict or deny
                  your use or access to the website.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>
                  Dispute Resolution
                </Typography>

                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  BY AVAILING OF OUR SERVICES YOU WARRANT AND UNDERTAKE TO
                  COMPLY WITH DISPUTE RESOLUTION TERMS.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  All disputes arising out of, relating to, or in connection
                  with the interpretation or application or the Terms or your
                  use of the Services that cannot be resolved informally or will
                  be resolved through binding arbitration on an individual
                  basis, except that you and us are not required to arbitrate
                  any dispute in which either party seeks equitable relief for
                  the alleged unlawful use of copyrights, trademarks, trade
                  names, logos, trade secrets, or patents. Arbitration will be
                  initiated solely through the Philippine Dispute Resolution
                  Center, Inc. (“PDRCI”). Notwithstanding the foregoing, UBX PH
                  may choose to bring an individual action in court. This
                  arbitration agreement will survive the termination of your
                  relationship with UBX PH.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>Arbitration</Typography>

                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  Any dispute, claim or difference directly or indirectly
                  arising out of or in connection with this Agreement, including
                  any question regarding its existence, interpretation,
                  construction, performance, validity or termination shall be
                  referred to and finally determined and settled by arbitration
                  in accordance with the PDRCI arbitration rules (the “Rules”)
                  by three (3) arbitrators appointed in accordance with the
                  Rules. The arbitration shall be held in Pasig City,
                  Philippines and shall be conducted in English.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  These Terms and Conditions shall be exclusively governed and
                  interpreted under the laws of the Republic of the Philippines
                  without regard to its conflict of laws provisions.
                </Typography>
              </Box>
              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>Disclaimers</Typography>

                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  You undertake and warrant that the Items You are selling via
                  the website satisfy the standard expectations of a buyer, do
                  not and will not infringe any third party rights and are of an
                  acceptable quality and consistent to Your released product
                  information.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  In any event, you agree and understand that UBX PH is not a
                  party to the transaction between you and your Buyer. As such
                  UBX PH shall not be liable for any damages or losses aside
                  from actual losses sustained by you due to UBX PH’s willful
                  misconduct or gross negligence in accordance with these Terms
                  and Conditions.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>
                  Third Party Services
                </Typography>

                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  You will have access to services of third parties through the
                  website (the “Third Party Services”) for Payment Processing
                  and Logistics. In those cases, said Third Party Services shall
                  be subject to the terms, conditions, and limitations imposed
                  by those third parties, which shall also be binding on you.
                  UBX PH reserves the right to reject or refuse any Third Party
                  Service used by you in conjunction with its services.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  Your correspondence or business dealings with, or
                  participation in promotions of third parties, including
                  payment and delivery of related goods or services, and any
                  other terms, conditions, warranties, or representations
                  associated with such dealings, are solely between you and such
                  third party. UBX PH shall not be responsible or liable for any
                  loss or damage incurred as a result of any such dealings or as
                  a result of the presence of such third party advertisers on
                  the website.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  When you acquire goods or services from, or when you sell or
                  offer to sell goods or services to a third party through our
                  website, you understand and agree that (i) UBX PH is not a
                  party to the contract, agreement, deal or transaction between
                  you and the third party; (ii) UBX PH is under no obligation to
                  monitor the third party service you have availed or contracted
                  with; (iii) the third party will be responsible for all
                  obligations under the contract with you, including, without
                  limitation, warranties or representations.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  UBX PH shall not be liable and/or responsible with respect to
                  the title, quantity, quality or any other aspect related to
                  the goods and/or services that are offered in the Platform,
                  and no warranty, or indemnity of any kind shall be given or
                  deemed to be given by UBX PH in respect thereof.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  You shall be solely liable for the loss incurred by you due to
                  unauthorized transactions where the loss is due to negligence
                  or an act or omission attributable to you, your business or
                  your representatives.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  You agree and acknowledge that nothing in this Agreement
                  limits you from entering into similar arrangements or availing
                  of similar services with third parties. Accordingly, you agree
                  and acknowledge that we shall not be liable for any loss
                  incurred by you due to such similar arrangements or services
                  with third parties.
                </Typography>
              </Box>

              <Box style={{ marginTop: "5vh" }}>
                <Typography style={subheaderStyle}>Venue of Actions</Typography>

                <Typography style={{ fontSize: labelFontSize, color: bodyFontColor }}>
                  The venue of all legal actions or proceedings arising out of
                  or in connection with these Terms and Conditions shall be
                  brought exclusively in the proper courts of Pasig City to the
                  exclusion of all other venues.
                </Typography>
              </Box>
              <Box style={{ textAlign: "center", marginTop: "5vh" }}>
                <Typography style={{ fontSize: labelFontSize, color: blackish }}>
                  BY CONTINUING, YOU ACCEPT AND CONSENT TO THESE TERMS AND
                  CONDITIONS.
                </Typography>
              </Box>

    </div>
  );
}
