import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Paper, Button, TextField,
  Select,
  MenuItem,
  FormControl, } from '@material-ui/core';
import validations from "../../../common/validations";
import { Provinces, getCities } from "../../../common/template/components/constants/address";
import Truncate from 'react-truncate'

const OrderDetailsCard = (props) => {
  const classes = makeStyles(theme => ({
    title: {
      fontSize: 24,
      fontWeight: "bold",
      color: "#002C42",
      marginBottom: 0,
      borderBottom: "1px solid #D1D5DD !important",
    },
    items_qty: {
      fontSize: 14,
      borderBottom: "1px dashed #D1D5DD",
    },
    subtotal: {
      fontSize: 16,
      padding: "1em 0",
      borderBottom: "1px dashed #D1D5DD"
    },
    total: {
      fontSize: 20,
      fontWeight: 700,
      padding: "1em 0"
    },
    table: {
      borderBottom: "2px solid #D1D5DD",
      "& td": {
        border: "none"
      },
      "& .price": {
        minWidth: 100
      }
    }
  }))()
  return (
    <Paper className={classes.paper} elevation={3}>
      <h3 className={`${classes.title} p-4`}>Order Details</h3> 
      <div className="p-4">
        <table className={`table ${classes.table}`}>
          <tbody>
          {
            props.items.map((product, index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>
                  <strong>
                    <Truncate lines={3} ellipsis="...">
                      <strong>
                        {product.name}
                      </strong>
                    </Truncate>
                  </strong>
                  <p>USD {(parseInt(product.unit_price)/100).toFixed(2)}</p>
                </td>
                <td className="price" align="right">USD {product.subtotal}</td>
              </tr>
            ))
          }
          </tbody>
        </table>
        <p className={`pb-3 mb-0 ${classes.items_qty}`}>
          Items: {props.qty}
        </p>
        <div className={`d-flex ${classes.subtotal}`}>
          <p className="mb-0">Subtotal</p>
          <p className="mb-0 ml-auto">USD {props.subtotal}</p>
        </div>
        <div className={`d-flex ${classes.total}`}>
          <p className="mb-0">Total*</p>
          <p className="mb-0 ml-auto">USD {props.total}</p>
        </div>
      </div>
    </Paper>
  )
}

const useStyles = theme => ({
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#002C42",
    marginBottom: 0,
    borderBottom: "1px solid #D1D5DD !important",
  },
  button: {
    minWidth: 320,
    fontSize: 16,
    height: 55,
  },
  formRoot: {
    maxWidth: 630,
    margin: "auto"
  },
  formControl: {
    padding: "0 0.5em",
    "& .label": {
      fontSize: 12,
      marginBottom: 5
    },
    "& .field": {
      height: 56
    },
    
  }
});

const FormField = (props) => {
  const classes = makeStyles(theme => ({
    formControl: {
      padding: "0 0.5em",
      "& .label": {
        fontSize: 12,
        marginBottom: 5
      },
      "& .field": {
        // height: 45
      },
      
    }
  }))()
  return (
    <div className={classes.formControl}>
      <p className="label mt-2">{props.label}</p>
      <TextField
        className={!props.multiline ? `field` : ``}
        variant="outlined"
        fullWidth
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        multiline={props.multiline}
        rows={props.multiline ? 2: 1}
        value={props.value}
        onBlur={() => props.onBlur ? props.onBlur(props.keyName): ``}
        onChange={e => props.onChange(props.keyName, e.target.value)}
        error={props.error}
        helperText={props.error}
      />
    </div>
  )
}
const FormSelect = (props) => {
  const classes = makeStyles(theme => ({
    formControl: {
      padding: "0 0.5em",
      "& .label": {
        fontSize: 12,
        marginBottom: 5
      },
      "& .field": {
        height: 56
      },
      
    }
  }))()
  return (
    <FormControl className={classes.formControl} fullWidth variant="outlined"
    disabled={props.disabled}>
      <p className="label mt-2">{props.label}</p>
      <Select
        className="field"
        color="secondary"
        value={props.value}
        onChange={e => props.onChange(props.keyName, e.target.value)}
      >
        {
          props.choices.map((choice, index) => (
            <MenuItem key={index} value={choice}>{choice}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

class AddressForm extends React.Component {
  state = {
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    country: "Philippines",
    province: "",
    city: "",
    zip_code: "",
    street: "",
    notes: "",
    errors: {}
  }

  setValue = (key, val) => {
    const postProcess = {
      mobile: (value) => value.replace(
        /[^0-9]/g,
        ""
      ).slice(0, 11),
    }
    this.setState({
      ...this.state,
      [key]: postProcess[key] ? postProcess[key](val) : val
    })
  }

  setError = (key) => {
    const validation = {
      first_name: ['required'],
      last_name: ['required'],
      mobile: ['mobileNumber','required'],
      email: ['email', 'required'],
      street: ['required'],
    }
    let error = false;
    if (validation[key]){
      for (let i in validation[key]){
        error = validations[validation[key][i]](this.state[key])
        if (error) break;
      }
    }
    this.setState({
      ...this.state,
      errors: {
        ...this.state.errors,
        [key]: error
      }
    })
  }

  hasError = () => {
    const { first_name, last_name, mobile, 
      email, street, province, city, } = this.state;
    let error = false;
    for(let i in Object.keys(this.state.errors)) {
      error = this.state.errors[Object.keys(this.state.errors)[i]]
      if (error) break;
    }
    error = error || !first_name || !last_name ||
      !mobile || !email || !street || !province || !city
    return error
  }

  render(){
    const { classes } = this.props;

    return (
      <Paper className={classes.paper} elevation={3}>
        <h3 className={`${classes.title} p-4`}>Customer and Shipping Details</h3> 
        <div className="p-4">
          <div className={classes.formRoot}>
            <div className="row no-gutters">
              <div className="col-12 col-lg-6">
                <FormField 
                label="First name" 
                keyName="first_name"
                error={this.state.errors.first_name || ""}
                value={this.state.first_name} 
                onBlur={this.setError}
                onChange={this.setValue}/>
              </div>
              <div className="col-12 col-lg-6">
                <FormField 
                label="Last name" 
                keyName="last_name" 
                value={this.state.last_name} 
                error={this.state.errors.last_name || ""}
                onBlur={this.setError}
                onChange={this.setValue}/>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12 col-lg-6">
                <FormField 
                label="Mobile number" 
                keyName="mobile"
                value={this.state.mobile} 
                error={this.state.errors.mobile || ""}
                onBlur={this.setError}
                onChange={this.setValue}/>
              </div>
              <div className="col-12 col-lg-6">
                <FormField 
                label="Email address" 
                keyName="email" 
                value={this.state.email} 
                error={this.state.errors.email || ""}
                onBlur={this.setError}
                onChange={this.setValue}/>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12 col-lg-6">
                <FormSelect 
                value={this.state.country} 
                keyName="country" 
                label="Country" 
                choices={["Philippines"]}
                onBlur={this.setError}
                onChange={this.setValue}
                />
              </div>
              <div className="col-12 col-lg-6">
                <FormSelect 
                value={this.state.province} 
                keyName="province" 
                label="State/Province" 
                choices={Provinces}
                onBlur={this.setError}
                onChange={this.setValue}
                />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12 col-lg-6">
                <FormSelect 
                disabled={!this.state.province.length}
                value={this.state.city} 
                keyName="city" 
                label="City" 
                choices={getCities(this.state.province)}
                onBlur={this.setError}
                onChange={this.setValue}
                />
              </div>
              <div className="col-12 col-lg-6">
                <FormField 
                label="Postal / ZIP code (optional)" 
                value={this.state.zip_code} 
                keyName="zip_code" 
                onBlur={this.setError}
                error={this.state.errors.zip_code || ""}
                onChange={this.setValue}/>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12">
                <FormField 
                label="Street Address" 
                value={this.state.street} 
                keyName="street" 
                error={this.state.errors.street || ""}
                onBlur={this.setError}
                onChange={this.setValue}/>
              </div>
            </div>
            <div className="row no-gutters mt-4">
              <div className="col-12">
                <FormField 
                label="Notes or questions to seller (optional)" 
                value={this.state.notes} 
                keyName="notes" 
                error={this.state.errors.notes || ""}
                onBlur={this.setError}
                onChange={this.setValue}
                multiline/>
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <Button
              color="primary" 
              variant="contained"
              className={classes.button}
              disabled={Boolean(this.hasError())}
              onClick={() => {
                if(this.props.onClick) this.props.onClick(this.state)
              }}
            >
              Submit Purchase Request
            </Button>
          </div>
        </div>
      </Paper>
    )
  }
}

const AddressFormCard = withStyles(useStyles)(AddressForm)

export { OrderDetailsCard,  AddressFormCard };
