const defaultSRC = {
  //0f4c81 f29191 f8efe1 d1d5dd,
  Leftist: {
    background: '#342826',
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
  Classic: {
    appBarButton: '#0f4c81',
    appColor: '#0f4c81',
    appBarBackground: '#fff',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/224C7D.png',
    bannerPhoto: 'https://www.colorhexa.com/0f4c81.png',
    bannerBackgroundColor: '#0f4c81',
    background: '#fff',
    buttonColor: '#224C7D',
    aboutUsPhoto: 'https://www.colorhexa.com/f29191.png',
    aboutUsBackgroundColor: '#f29191',
    contactUsPhoto: 'https://www.colorhexa.com/f8efe1.png',
    contactUsBackgroundColor: '#f8efe1',
    footerPhoto: 'https://www.colorhexa.com/d1d5dd.png',
    footerBackgroundColor: '#0f4c81',
    headerFont: 'Georgia',
    headerColor: '#0f4c81',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Arimo',
  },
  Contemporary: {
    appBarButton: '#f19db2',
    appColor: '#2F9395',
    appBarBackground: '#fff',
    appBarText: 'green',
    appBarLogo: 'https://www.colorhexa.com/2F9395.png',
    background: '#fff',
    bannerPhoto: 'https://www.colorhexa.com/2F9395.png',
    bannerBackgroundColor: '#2F9395',
    buttonColor: '#2F9395',
    aboutUsPhoto: 'https://www.colorhexa.com/F68868.png',
    aboutUsBackgroundColor: '#F68868',
    contactUsPhoto: 'https://www.colorhexa.com/F7D969.png',
    contactUsBackgroundColor: '#F7D969',
    footerPhoto: 'https://www.colorhexa.com/909196.png',
    footerBackgroundColor: '#2F9395',
    headerFont: 'Oswald',
    headerColor: '#2F9395',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Work Sans',
  },
  Minimalist: {
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
  ClassicV2: {
    appBarButton: '#0f4c81',
    appColor: '#0f4c81',
    appBarBackground: '#fff',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/224C7D.png',
    bannerPhoto: 'https://www.colorhexa.com/0f4c81.png',
    bannerBackgroundColor: '#0f4c81',
    background: '#fff',
    buttonColor: '#224C7D',
    aboutUsPhoto: 'https://www.colorhexa.com/f29191.png',
    aboutUsBackgroundColor: '#f29191',
    contactUsPhoto: 'https://www.colorhexa.com/f8efe1.png',
    contactUsBackgroundColor: '#f8efe1',
    footerPhoto: 'https://www.colorhexa.com/d1d5dd.png',
    footerBackgroundColor: '#0f4c81',
    headerFont: 'Georgia',
    headerColor: '#0f4c81',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Arimo',
  },
  ContemporaryV2: {
    appBarButton: '#f19db2',
    appColor: '#2F9395',
    appBarBackground: '#fff',
    appBarText: 'green',
    appBarLogo: 'https://www.colorhexa.com/2F9395.png',
    background: '#fff',
    bannerPhoto: 'https://www.colorhexa.com/2F9395.png',
    bannerBackgroundColor: '#2F9395',
    buttonColor: '#2F9395',
    aboutUsPhoto: 'https://www.colorhexa.com/F68868.png',
    aboutUsBackgroundColor: '#F68868',
    contactUsPhoto: 'https://www.colorhexa.com/F7D969.png',
    contactUsBackgroundColor: '#F7D969',
    footerPhoto: 'https://www.colorhexa.com/909196.png',
    footerBackgroundColor: '#2F9395',
    headerFont: 'Oswald',
    headerColor: '#2F9395',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Work Sans',
  },
  MinimalistV2: {
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
  SariSari: {
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
  Pastel: {
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
  MinimalistV2: {
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
  Blocks: {
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
  Greenery: {
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
  Japandi: {
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
  NFT: {
    appBarButton: '#2B2D33',
    appColor: '#efd42b',
    appBarBackground: '#2B2D33',
    appBarText: '#black',
    appBarLogo: 'https://www.colorhexa.com/F1C944.png',
    bannerPhoto: 'https://www.colorhexa.com/305F72.png',
    bannerBackgroundColor: '#305F72',
    buttonColor: '#2B2D33',
    aboutUsPhoto: 'https://www.colorhexa.com/568EA6.png',
    aboutUsBackgroundColor: '#568EA6',
    contactUsPhoto: 'https://www.colorhexa.com/F0B7A4.png',
    contactUsBackgroundColor: '#F0B7A4',
    footerPhoto: 'https://www.colorhexa.com/F1F1F1.png',
    footerBackgroundColor: '#efd42b',
    headerFont: 'Ubuntu',
    headerColor: '#2B2D33',
    bodyFont: 'Arimo, Sans-serif',
    secondaryFont: 'Monda',
  },
};

const a = ['o', 1, 2];

export default defaultSRC;
