import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import Actions from "../StoreActions";
const VoucherModal = props => {
  const { open, setOpen } = props;
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 20,
      fontWeight: "bold",
      color: "#2b2d32",
      textAlign: "center"
    },
    card: {
      backgroundColor: "#fcf7e1",
      border: "1px solid #edc143",
      borderRadius: 4,
      boxShadow: "0px 8px 12px rgba(0,0,0,0.05)",
      marginBottom: 10,
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      minHeight: 105,
      cursor: "pointer"
    },
    disabled: {
      opacity: 0.5,
      cursor: "not-allowed"
    },
    voucherContent: {
      padding: 16,
      width: "100%"
    },
    code: {
      fontSize: 14,
      color: "#2b2d32",
      fontWeight: "bold"
    },
    date: {
      fontSize: 12,
      color: "#616675"
    },
    discountSection: {
      backgroundColor: "#f9e9b5",
      width: 160,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    discount: {
      fontSize: 18,
      color: "#2b2d32",
      fontWeight: 500,
      textAlign: "center"
    },
    dialogContent: {
      padding: "0 35px 35px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 20px 20px"
      }
    },
    requirements: {
      bottom: 16,
      right: 16,
      left: 16,
      fontSize: 12,
      color: "#616675"
    }
  }));

  const dispatch = useDispatch();
  const classes = useStyles();
  const store_uuid = useSelector(state => state.store.store.uuid);
  const vouchers = useSelector(state => state.store.voucherList);
  const subtotal = useSelector(state => state.store.subtotal);
  const total = useSelector(state => state.store.total);
  const applied_voucher = useSelector(state => state.store.applied_voucher);
  const cart = useSelector(state => state.store.items);
  const selected_payment_channel = useSelector(
    state => state.store.selected_payment_channel
  );
  function discount(voucher) {
    if (voucher.voucher_type === "fixed_amount") {
      return `${parseFloat(voucher.discount_value) + " OFF"}`;
    } else if (voucher.voucher_type === "percentage") {
      return `${parseFloat(voucher.discount_value)}% OFF`;
    } else {
      return "FREE SHIPPING";
    }
  }

  function voucherRequirements(voucher) {
    let req = "";
    if (voucher.minimum_requirements === "Minimum Purchase Amount (PHP)") {
      req = `${`Purchase a minimum amount of PHP ${voucher.minimum_purchase_amount}`}`;
    } else if (voucher.minimum_requirements === "Minimum Quantity of items") {
      req = `${`Purchase a minimum quantity of ${parseFloat(
        voucher.minimum_purchase_amount
      )} items`}`;
    } else {
      req = "Applicable to all purchases";
    }
    if (voucher.required_payment_channel) {
      const channel_display =
        voucher.required_payment_channel === "otc"
          ? "BUX"
          : voucher.required_payment_channel.toUpperCase();
      req = req + `. ${channel_display} payments only.`;
    }
    return req;
  }

  function handleSelected(item) {
    if (item.is_applicable) {
      dispatch(Actions.applyVoucher(item));
    }
  }

  React.useEffect(() => {
    let purchase_amount, items_total_qty;
    const product_categories = cart.length && cart.map(item => item.category);
    if (cart.length || open) {
      purchase_amount = subtotal;
      items_total_qty = cart.reduce((a, b) => a + b.quantity, 0);
    }
    if (store_uuid && cart.length) {
      dispatch(
        Actions.getVouchers(store_uuid, {
          purchase_amount,
          items_total_qty,
          selected_payment_channel,
          product_categories: JSON.stringify(product_categories)
        })
      );
    }
  }, [store_uuid, cart, open, subtotal, selected_payment_channel]);

  const currently_selected_voucher = vouchers.filter(
    a => applied_voucher.voucher_code === a.voucher_code
  );
  React.useEffect(() => {
    if (cart.length) {
      if (currently_selected_voucher.length) {
        if (!currently_selected_voucher[0].is_applicable) {
          const applicable_vouchers = vouchers.filter(a => a.is_applicable);
          if (applicable_vouchers.length) {
            dispatch(Actions.applyVoucher(applicable_vouchers[0]));
          } else {
            dispatch(Actions.applyVoucher(""));
          }
        }
      }
    }
  }, [currently_selected_voucher, vouchers, cart, applied_voucher, open]);

  React.useEffect(() => {
    if (!currently_selected_voucher.length) {
      const applicable_vouchers = vouchers.filter(
        a => a.is_applicable && a.is_auto_apply && a.is_made_internally
      );
      if (applicable_vouchers.length) {
        dispatch(Actions.applyVoucher(applicable_vouchers[0]));
      } else {
        dispatch(Actions.applyVoucher(""));
      }
    }
  }, [currently_selected_voucher, vouchers, selected_payment_channel]);

  return (
    <div>
      <Dialog id="voucher-dialog" fullWidth maxWidth="sm" open={open}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 24px"
          }}
        >
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="secondary" />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.header}>Select a Voucher</div>

          <div
            id="vouchers-div"
            style={{ marginTop: "2vh", overflowY: "auto", height: 400 }}
          >
            {vouchers
              ? vouchers.length
                ? vouchers.map(item => {
                    return (
                      <div
                        className={`${classes.card} voucher-item
                          ${!item.is_applicable ? classes.disabled : ""}`}
                        onClick={() => handleSelected(item)}
                      >
                        <div className={classes.voucherContent}>
                          <div
                            style={{
                              display: "flex",

                              justifyContent: "space-between"
                            }}
                          >
                            <div className={classes.code}>
                              {item.voucher_code}
                            </div>
                            <div
                              style={{
                                display:
                                  applied_voucher.voucher_code ===
                                  item.voucher_code
                                    ? "flex"
                                    : "none",
                                alignItems: "center"
                              }}
                            >
                              <CircleCheckedFilled
                                style={{ color: "#1dd28b", fontSize: 14 }}
                              />
                              <div
                                style={{
                                  marginLeft: 5,
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  color: "#1dd28b"
                                }}
                              >
                                {" "}
                                Applied
                              </div>
                            </div>
                          </div>
                          <div className={classes.date}>
                            {moment(item.start_datetime).format("D MMM YYYY") +
                              " - " +
                              moment(item.end_datetime).format("D MMM YYYY")}
                          </div>
                          <div className={classes.requirements}>
                            {voucherRequirements(item)}
                          </div>
                        </div>
                        <div className={classes.discountSection}>
                          <div className={classes.discount}>
                            {discount(item)}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""
              : ""}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VoucherModal;
