import { ActionTypes } from "./actions";
const initialState = {
  visible: false,
  loading: false,
  uid: "",
  mobile: "",
  errorType: "",
};

export default function OTPReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_VISIBILITY:
      return {
        ...state,
        visible: action.payload,
        loading: false
      }
    case ActionTypes.SEND_SMS_OTP:
      return {
        ...state,
        loading: true,
        visible: true,
        mobile: action.payload.mobile,
        errorType: ""
      }
    case ActionTypes.SEND_SMS_OTP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case ActionTypes.VERIFY_SMS_OTP:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.VERIFY_SMS_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        visible: false,
        errorType: "",
      }
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        errorType: action.payload,
        loading: false,
      }
    default:
      return state;
  }
}
