import { createAction } from "redux-actions";

export const AlertActionTypes = {
  SHOW_ALERT: "@@SHOW_ALERT",
  HIDE_ALERT: "@@HIDE_ALERT",
  SHOW_LOADING: "@@SHOW_LOADING",
  HIDE_LOADING: "@@HIDE_LOADING"
};

export const showAlert = createAction(AlertActionTypes.SHOW_ALERT);
export const hideAlert = createAction(AlertActionTypes.HIDE_ALERT);
export const showLoading = createAction(AlertActionTypes.SHOW_LOADING);
export const hideLoading = createAction(AlertActionTypes.HIDE_LOADING);
