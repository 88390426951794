import React from "react";
import { Route, Switch } from "react-router-dom";
import Path from "../Paths";
import WebsiteBuilder from "../components/websiteBuilder/websiteBuilder2";
import WebsiteBuilderEntry from "../components/websiteBuilder/websiteBuilderEntry";
import ProductSearch from "../components/productSearch/productSearch";
import ProductDetails from "../components/productSearch/productDetails";
import Dashboard from "../components/dashboard/dashboard";
import Published from "../components/websiteBuilder/PublishedSite";
import FBChatSetup from "../components/dashboard/FBChat";
import OrderListView from "../components/orders/OrderListView";
import OrderDetailsView from "../components/orders/OrderDetailsView";
import Publish from "../components/websiteBuilder/PublishSite";
import Announcements from "../components/announcements/announcements";
import ErrorPage from "../components/common/ErrorPage";
import OrderDeliveryRequestView from "../components/orders/OrderDeliveryRequestView";
import OrderBookingSuccess from "../components/orders/OrderBookingSuccess";
import OrderUpdatePayment from "../components/orders/OrderUpdatePayment";
import OrderDeliveryMap from "../components/orders/orderDeliveryMap";
import FBPixel from "../components/dashboard/FBPixel";
import AppMarketplace from "../components/marketplace/list";
import AppMarketplaceDetailed from "../components/marketplace/detailed";
import MarketplacePackages from "../components/marketplace/packages";
import MarketplaceCart from "../components/marketplace/Cart";
import MarketplaceCheckout from "../components/marketplace/checkout";
import MarketplaceCheckoutDetails from "../components/marketplace/checkout_details";
import BasicInformation from "../components/accountSettings/basicInformation/basicInformation";
import Password from "../components/accountSettings/password/password";
import WebBuilder from "../components/common/layout/mobile/webBuilder";
import WebProducts from "../components/common/layout/mobile/webProducts";
import WebOrders from "../components/common/layout/mobile/webOrders";
import MobileSalesDashboard from "../components/common/layout/mobile/mobileShopTools/mobileSalesDashboard";
import MobileFBChat from "../components/common/layout/mobile/mobileShopTools/mobileFBChat";
import MobileFBPixel from "../components/common/layout/mobile/mobileShopTools/mobileFBPixel";
import CustomDomain from "../components/dashboard/CustomDomain";
import SalesAndOrdersDashboard from "../components/salesAndOrders/dashboard";
import { signOff, confirmLogOut } from "../components/auth/authActions";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "../components/common/idleTimeoutModal";
import { useDispatch, useSelector } from "react-redux";
import ProxteraRoutes from "./ProxteraRoutes";
import ShopCreateForm from "../components/websiteBuilder/desktop/forms/shopCreateForm";
import ShopDetailsForm from "../components/websiteBuilder/desktop/forms/shopDetailsForm";
import ShopStylesForm from "../components/websiteBuilder/desktop/forms/shopStylesForm";
import ProductsPage from "../components/websiteBuilder/desktop/forms/productsPage";
import ProductsForm from "../components/websiteBuilder/desktop/forms/productForm";
import PaymentsPage from "../components/payments/paymentsPage";
import DeliverySettings from "../components/delivery_settings/index";
import TemplateLibrary from "../components/websiteBuilder/desktop/forms/templateLibrary";
import BatchUpload from "../components/websiteBuilder/desktop/forms/batchUpload";
import VouchersPage from "../components/vouchers/vouchersPage";
import WebVouchers from "../components/common/layout/mobile/webVouchers";
import CartViewOrder from "../components/dashboard/buyerDashboard/cartViewOrder";
import BuyerDashboard from "../components/dashboard/buyerDashboard/buyerDashboard";
import PurchasedPage from "../components/dashboard/buyerDashboard/purchasedPage";
import PurchasedViewOrder from "../components/dashboard/buyerDashboard/purchasedViewOrder";
import BuyerDashboardRoutes from "./BuyerDashboardRoutes";
import SentroAdvanced from "../components/subscription";
import AnalyticsPage from "../components/analytics/analyticsPage";
import MediaLibrary from "../components/mediaLibrary";
import AccountForm from "../components/payments/components/otherAccount";
import Subscription from "../components/subscription/components/subscription";
import SuccessSubscription from "../components/subscription/components/subscription/successSubscription";
import QuickOrderSettings from "../components/websiteBuilder/desktop/forms/quickOrder";
import OrderDetails from "../components/orders/OrderDetails";

let PrivateRoutes = (props) => {
  const dispatch = useDispatch();
  const [timeout] = React.useState(1000 * 53 * 60);
  const [showModal, setShowModal] = React.useState(false);
  const [isTimedOut, setIsTimedOut] = React.useState(false);
  const idleTimer = React.createRef();

  function onAction(e) {
    setIsTimedOut(false);
  }
  function onIdle(e) {
    if (isTimedOut) {
      setShowModal(true);
    } else {
      idleTimer.current.reset();
      setShowModal(true);
      setIsTimedOut(true);
      window.onbeforeunload = function () {
        dispatch(signOff());
      };
    }
  }

  function handleLogout() {
    setShowModal(false);
    dispatch(confirmLogOut());
  }
  return (
    <>
      <IdleTimer
        key="idleTimer"
        startOnMount={true}
        ref={idleTimer}
        element={document}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={timeout}
      />
      <div>
        <Switch>
          <Route
            exact
            from="/login"
            to={Path.DASHBOARD}
            component={Dashboard}
          />

          <Route path={Path.ORDER_LIST_VIEW} component={OrderListView} />
          <Route
            path={Path.ORDER_DELIVERY_REQUEST}
            component={OrderDeliveryRequestView}
          />
          <Route
            path={Path.ORDER_DELIVERY_REQUEST_SUCCESS}
            component={OrderBookingSuccess}
          />
          <Route path={Path.ORDER_DETAILS_VIEW} component={OrderDetails} />
          <Route
            path={Path.ORDER_UPDATE_PAYMENT}
            component={OrderUpdatePayment}
          />
          {/* <Route path={Path.ORDER_DELIVERY_MAP} component={OrderDeliveryMap} /> */}
          <Route
            path={Path.APP_MARKETPLACE_CHECKOUT_DETAILS}
            component={MarketplaceCheckoutDetails}
          />
          <Route path={Path.APP_MARKETPLACE_CART} component={MarketplaceCart} />
          <Route
            path={Path.APP_MARKETPLACE_CHECKOUT}
            component={MarketplaceCheckout}
          />
          <Route
            path={Path.APP_MARKETPLACE_PACKAGES}
            component={MarketplacePackages}
          />
          <Route
            path={Path.APP_MARKETPLACE_DETAILED}
            component={AppMarketplaceDetailed}
          />
          {/* <Route path={Path.APP_MARKETPLACE} component={AppMarketplace} /> */}
          {/* <Route
            path={Path.BUX_DASHBOARD}
            component={SalesAndOrdersDashboard}
          /> */}
          <Route path={Path.FB_CHAT_SETUP} component={FBChatSetup} />
          <Route path={Path.FB_PIXEL_SETUP} component={FBPixel} />
          <Route path={Path.CUSTOM_DOMAIN_SETUP} component={CustomDomain} />
          <Route
            path={Path.DASHBOARD}
            render={(routeProps) => (
              <Dashboard {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.ACCOUNT_SETTINGS_BASIC_INFORMATION}
            component={BasicInformation}
          />
          <Route path={Path.ACCOUNT_SETTINGS_PASSWORD} component={Password} />
          <Route path={Path.ANNOUNCEMENTS} component={Announcements} />
          {/* <Route path={Path.BUILDER_ENTRY} component={WebsiteBuilderEntry} /> */}
          <Route
            path={Path.BUILDER}
            render={(routeProps) => (
              <WebsiteBuilder {...routeProps} language={props.language} />
            )}
          />
          {/* <Route path={Path.PRODUCTS} component={ProductSearch} /> */}
          {/* <Route path={Path.PRODUCT_DETAILS} component={ProductDetails} /> */}
          <Route
            path={Path.PUBLISH}
            render={(routeProps) => (
              <Publish {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.PUBLISHED}
            render={(routeProps) => (
              <Published {...routeProps} language={props.language} />
            )}
          />
          {/* Mobile Routes */}
          <Route
            path={Path.MOBILE_WEB_BUILDER}
            render={(routeProps) => (
              <WebBuilder {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.MOBILE_PRODUCTS}
            render={(routeProps) => (
              <WebProducts {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.MOBILE_ORDERS}
            render={(routeProps) => (
              <WebOrders {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.MOBILE_VOUCHERS}
            render={(routeProps) => (
              <WebVouchers {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.MOBILE_BUX_DASHBOARD}
            render={(routeProps) => (
              <MobileSalesDashboard {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.MOBILE_FB_PIXEL}
            render={(routeProps) => (
              <MobileFBPixel {...routeProps} language={props.language} />
            )}
          />

          <Route
            path={Path.SHOP_INFO}
            render={(routeProps) => (
              <ShopDetailsForm {...routeProps} language={props.language} />
            )}
          />

          <Route
            path={Path.SHOP_STYLE}
            render={(routeProps) => (
              <ShopStylesForm {...routeProps} language={props.language} />
            )}
          />
          <Route
            exact
            path={Path.QUICK_ORDER_SETTINGS}
            component={QuickOrderSettings}
          />
          <Route
            path={Path.TEMPLATE_LIBRARY}
            render={(routeProps) => (
              <TemplateLibrary {...routeProps} language={props.language} />
            )}
          />

          <Route
            path={Path.PRODUCTS_ADD}
            render={(routeProps) => (
              <ProductsForm {...routeProps} language={props.language} />
            )}
          />

          <Route
            path={Path.PRODUCTS_EDIT}
            render={(routeProps) => (
              <ProductsForm {...routeProps} language={props.language} />
            )}
          />

          <Route
            path={Path.PRODUCTS}
            render={(routeProps) => (
              <ProductsPage {...routeProps} language={props.language} />
            )}
          />

          <Route
            path={Path.SHOP_NEW_CREATE}
            render={(routeProps) => (
              <ShopCreateForm {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.PAYMENTS_ADD}
            render={(routeProps) => (
              <AccountForm {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.PAYMENTS_EDIT}
            render={(routeProps) => (
              <AccountForm {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.PAYMENTS}
            render={(routeProps) => (
              <PaymentsPage {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.VOUCHERS}
            render={(routeProps) => (
              <VouchersPage {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.CUSTOM_DOMAIN}
            render={(routeProps) => (
              <CustomDomain {...routeProps} language={props.language} />
            )}
          />
          <Route
            exact
            path={Path.BATCH_UPLOAD}
            render={(routeProps) => (
              <BatchUpload {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.SENTRO_ADVANCED}
            render={(routeProps) => (
              <SentroAdvanced {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.MEDIA_LIBRARY}
            render={(routeProps) => (
              <MediaLibrary {...routeProps} language={props.language} />
            )}
          />
          <Route
            path={Path.SUBSCRIPTION_SUCCESS}
            component={SuccessSubscription}
          />
          <Route
            path={Path.SUBSCRIPTION}
            render={(routeProps) => (
              <Subscription {...routeProps} language={props.language} />
            )}
          />
          {BuyerDashboardRoutes}
          <Route
            exact
            path={Path.DELIVERY_SETTINGS}
            component={DeliverySettings}
          />
          <Route exact path={Path.ANALYTICS} component={AnalyticsPage} />
          <Route
            exact
            path={Path.QUICK_ORDER_SETTINGS}
            component={QuickOrderSettings}
          />
          {ProxteraRoutes}
          <Route path="*" exact={true} component={ErrorPage} />
        </Switch>

        <IdleTimeOutModal showModal={showModal} handleLogout={handleLogout} />
      </div>
    </>
  );
};
export default PrivateRoutes;
