import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Slide
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function FeaturedProductWarning(props) {
  const handleClose = () => {
    props.setOpen(false);
  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="d-flex justify-content-end p-0">
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 55px 30px" }}>
        <div
          className="d-flex flex-column m-auto"
          style={{ width: "fit-content" }}
        >
          <ErrorIcon
            style={{ width: "100%", color: "#ee2545", fontSize: 100 }}
            className="text-center"
          />
          <strong style={{ fontSize: 18, padding: "20px 0" }}>
            You can only select a maximum of 4 products to feature on your
            online shop
          </strong>
        </div>
      </DialogContent>
    </Dialog>
  );
}
