import { makeStyles } from "@material-ui/core";
import theme from "../common/sentroTheme";

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    fontSize: 28,
    color: "#002c42",
    fontWeight: "bold"
  },
  side: {
    zIndex: 3,
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  content: {
    width: "100%",
    padding: "0 12vw"
  },
  card: {
    marginBottom: "1em",
    width: "100%",
    border: "1px solid #d1d5dd",
    backgroundColor: "#fff",
    padding: 25,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
    maxHeight: "auto",
    flexWrap: "wrap"
  },
  title: {
    color: "#2b2d33",
    fontSize: 18,
    fontWeight: 600,
    "&:hover": {
      color: "#2b2d33"
    }
  },
  date: {
    paddingTop: "1vh",
    fontSize: 12,
    color: "#2b2d337d"
  }
}));

export default useStyles;
