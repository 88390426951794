import React from "react";
import Product from "./components/Product";

import "./App.css";
import DocumentMeta from "react-document-meta";

const App = (props) => {
  let params = props.match ? props.match.params : "";
  const meta = {
    title: "Some Meta Title",
    description: "I am a description, and I can create multiple tags",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "react,meta,document,html,tags",
      },
      meta: {
        property: {
          "og:title": "Samvikshana",
          "og:url": "https://samvikshana.weebly.com/",
          // "og:image": imageUri,
          "og:description": "New Perspective of Exploration",
          "twitter:card": "summary_large_image",
          "twitter:title": "Samvikshana",
          "twitter:description": "New Perspective of Exploration",
          // "twitter:image": imageUri,
        },
      },
    },
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <DocumentMeta>
      <Product id={params.id} {...meta} />
    </DocumentMeta>
  );
};

export default App;
