import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SideMenu from '../../../dashboard/sideMenu';
import history from '../../../../store/history';
import Path from '../../../../Paths';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TemplateCard from '../components/shopStylesForm/templateCard';
import { templates } from '../components/shopStylesForm/templateDisplayImage';
import PreviewShop from './previewShop';
import TemplateConfirmationDialog from '../components/shopStylesForm/confirmationDialog';
import { patchStore } from '../../websiteBuilderActions';
import { showAlert } from '../../../common/commonActions';
import PreviewTemplate from './previewTemplate';
import apiConfig from '../../../../common/apiConfig';

export default function TemplateLibrary(props) {
  const useStyles = makeStyles((theme) => ({
    side: { zIndex: 3, display: 'flex', position: 'fixed', height: '100%' },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const getLanguage = localStorage.getItem('language');

  const [language, setLanguage] = React.useState(getLanguage);
  const [values, setValues] = React.useState({});
  const [open, setOpen] = React.useState({
    dialog: false,
    preview: false,
    template: '',
  });
  const storeInfo = useSelector(
    (state) => state.webBuilder.storeInfo || state.mobileDashboard.storeInfo
  );
  const productList = useSelector((state) => state.webBuilder.products);
  const subscription = useSelector((state) => state.subscription.subscription);
  const handleClosePreview = () => {
    setOpen({ ...open, preview: false });
  };

  const handleViewDemo = (template) => {
    // setValues({ ...storeInfo, template: template });
    setOpen({ ...open, preview: true, template: template });
  };

  const handleUseTemplate = async () => {
    const res = await dispatch(
      patchStore(
        'styles',
        storeInfo.site,
        { template: values.template },
        {
          image_crop_area: values.image_crop_area,
          image_settings: values.image_settings,
          font: values.font,
        }
      )
    );

    try {
      if (res.status === 200) {
        setOpen({ ...open, dialog: false });
        history.goBack();
        setTimeout(() => {
          dispatch(
            showAlert({
              type: 'success',
              message: 'You have successfully updated your template',
            })
          );
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const current_user = JSON.parse(sessionStorage.getItem('CURRENT_USER'));
  const alamatUsers = JSON.parse(apiConfig.alamat_users);

  React.useEffect(() => {
    if (storeInfo) {
      setValues({ ...storeInfo });
    } else {
      history.push(Path.DASHBOARD);
    }
  }, [storeInfo]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setLanguage(getLanguage);
  }, [getLanguage]);
  return (
    <div>
      <div className="d-flex flex-wrap" style={{ backgroundColor: '#fafafa' }}>
        <div className={classes.content}>
          <div className="d-flex justify-content-between align-items-center">
            <Button
              startIcon={<ArrowBackIcon />}
              variant="text"
              color="primary"
              style={{ position: 'absolute', zIndex: 3 }}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <strong
              style={{ fontSize: 28, color: '#002c42', width: '100%' }}
              className="text-center"
            >
              Template library
            </strong>
          </div>
          <div style={{ fontSize: 14 }} className="text-center my-3">
            Choose from any of the following free templates.
          </div>
          <div className="d-flex row flex-wrap my-4 px-0">
            {templates.map((item, index) => {
              if (item.template === 'NFT') {
                if (!alamatUsers.includes(current_user.email)) {
                  return null;
                }
              }

              return (
                <TemplateCard
                  key={index}
                  item={item}
                  handleViewDemo={handleViewDemo}
                  open={open}
                  setOpen={setOpen}
                  setValues={setValues}
                  values={values}
                  subscription={subscription}
                />
              );
            })}
          </div>
        </div>
        <PreviewTemplate open={open} setOpen={setOpen} />
        {/* <PreviewShop
          open={open}
          setOpen={setOpen}
          storeInfo={values}
          setValues={setValues}
          productList={productList}
          handleClosePreview={handleClosePreview}
        /> */}
        <TemplateConfirmationDialog
          open={open}
          setOpen={setOpen}
          handleUseTemplate={handleUseTemplate}
        />
      </div>
    </div>
  );
}
