import React from "react";
import { useHistory } from "react-router";
import { useDispatch, connect, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/styles";
import SwipeableViews from "react-swipeable-views";
import {
  Button,
  Tabs,
  AppBar,
  Tab,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  Slide,
  Toolbar,
  IconButton,
  Link
} from "@material-ui/core";
import { Field } from "redux-form";
import ImageField from "../../../../../reduxFields/imageField";
import ColorPickerField from "../../../../../reduxFields/colorField";
import FontPickerField from "../../../../../reduxFields/fontPicker";
import ImageTips from "../../imageTips";

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 600,
    fontSize: 18
  },
  label2: {
    color: "#54575F",
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 0
  },
  smallText: {
    fontWeight: "normal",
    fontSize: 12,
    color: "rgba(43, 45, 50, 0.8)"
  },
  colorPreview: {
    padding: "1em 3em",
    borderRadius: 4
  }
}));

const BrandingPane = props => {
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const data = useSelector(state => state.form.customizeShopForm?.values);
  const [openImageTips, setOpenImageTips] = React.useState(false);

  return (
    <div className="bg-white mb-0 mb-md-5 p-3 p-md-0">
      {/* logo */}
      <ImageTips
        is_product_image_tips={false}
        open={openImageTips}
        setOpen={setOpenImageTips}
      />
      <div className="py-4 p-md-5 border-bottom">
        <div className="row">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <Typography color="primary" className={classes.label}>
              Logo
            </Typography>
          </div>
          <div className="col-12 col-md-8">
            <Field
              fullWidth
              id="logo_image"
              name="logo_image"
              label={
                <div>
                  <p className={classes.label2}> Upload a logo</p>
                  <p className={classes.smallText}>
                    Max file size of 1MB. Supports .jpg, .jpeg, .png.{" "}
                    <Link
                      onClick={() => setOpenImageTips(true)}
                      color="primary"
                    >
                      Image tips
                    </Link>
                  </p>
                </div>
              }
              component={ImageField}
              height={85}
              width={85}
            />
          </div>
        </div>
      </div>
      {/* colors */}
      <div className="py-4 p-md-5 border-bottom">
        <div className="row">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <Typography color="primary" className={classes.label}>
              Colors
            </Typography>
          </div>
          <div className="col-12 col-md-8">
            <p className={classes.label2}>Primary colors</p>
            <p className={classes.smallText}>For header and footer colors</p>
            <div className="d-flex align-items-center py-2 mb-3">
              <div className="w-50">
                <div className="d-flex align-items-center">
                  <Field
                    fullWidth
                    id="primary_background_color"
                    name="primary_background_color"
                    component={ColorPickerField}
                  />
                  <p className={`${classes.label3} ml-3 mb-0`}>Background</p>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <Field
                    fullWidth
                    id="primary_text_color"
                    name="primary_text_color"
                    component={ColorPickerField}
                  />
                  <p className={`${classes.label3} ml-3 mb-0`}>Text</p>
                </div>
              </div>
              <div className="w-50">
                <div className="d-flex flex-column text-center">
                  <p className={`${classes.label3} ml-3 mb-1`}>Preview</p>
                  <div
                    className={classes.colorPreview}
                    style={{
                      backgroundColor: data?.primary_background_color
                    }}
                  >
                    <p
                      style={{ fontSize: 36, color: data?.primary_text_color }}
                      className="mb-0"
                    >
                      {" "}
                      Text
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className={classes.label2}>Secondary colors</p>
            <p className={classes.smallText}>
              For text links and action buttons
            </p>
            <div className="d-flex align-items-center py-2">
              <div className="w-50">
                <div className="d-flex align-items-center">
                  <Field
                    fullWidth
                    id="secondary_background_color"
                    name="secondary_background_color"
                    component={ColorPickerField}
                  />
                  <p className={`${classes.label3} ml-3 mb-0`}>Background</p>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <Field
                    fullWidth
                    id="secondary_text_color"
                    name="secondary_text_color"
                    component={ColorPickerField}
                  />
                  <p className={`${classes.label3} ml-3 mb-0`}>Text</p>
                </div>
              </div>
              <div className="w-50">
                <div className="d-flex flex-column text-center">
                  <p className={`${classes.label3} ml-3 mb-1`}>Preview</p>
                  <div
                    className={classes.colorPreview}
                    style={{
                      backgroundColor: data?.secondary_background_color
                    }}
                  >
                    <p
                      style={{
                        fontSize: 36,
                        color: data?.secondary_text_color
                      }}
                      className="mb-0"
                    >
                      {" "}
                      Text
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* typography */}
      <div className="py-4 p-md-5">
        <div className="row">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <Typography color="primary" className={classes.label}>
              Typography
            </Typography>
          </div>
          <div className="col-12 col-md-8">
            <Field
              fullWidth
              id="font"
              name="font"
              label="Headings"
              component={FontPickerField}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandingPane;
