import { AlertActionTypes } from "./commonActions";
const initialState = {
  showAlert: false,
  showLoading: false,
  message: "",
  variant: ""
};
export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case AlertActionTypes.SHOW_ALERT:
      return {
        ...state,
        showAlert: true,
        alertType: action.payload.type,
        message: action.payload.message,
        variant: action.payload.variant
      };
    case AlertActionTypes.HIDE_ALERT:
      return {
        ...state,
        showAlert: false,
        message: ""
      };
    case AlertActionTypes.SHOW_LOADING:
      return {
        showLoading: true
      };
    case AlertActionTypes.HIDE_LOADING:
      return {
        showLoading: false
      };
    default:
      return state;
  }
}
