import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, TextField, Checkbox } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  checkboxLabel: {
    fontSize: 12
  }
}));

const ReduxTextField = ({
  input,
  label,
  maxLength,
  minLength,
  multiline,
  cut,
  noHelpText,
  meta: { touched, error },
  ...custom
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className="text-secondary"
      classes={{ label: classes.checkboxLabel }}
      control={
        <Checkbox
          color="primary"
          {...input}
          {...custom}
          checked={input.value}
        />
      }
      label={label}
    />
  );
};

export default ReduxTextField;
