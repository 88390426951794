import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputAdornment,
  Button,
  IconButton,
  OutlinedInput
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SortSettings from "../../forms/SortSettings/index";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as SortAsc } from "../../../../../../../assets/images/enterprise/sortAsc.svg";
import { ReactComponent as SortDesc } from "../../../../../../../assets/images/enterprise/sortDesc.svg";
import SentroButton from "../../../../../sentroButton";
import { setProductFilters } from "../../websiteBuilderActions";
function HeaderFilter(props) {
  const useStyles = makeStyles(theme => ({
    input: {
      backgroundColor: "#fff"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useSelector(state => state.mobileDashboard.products.loading);
  const filters = useSelector(state => state.mobileDashboard.products.filters);
  const [timeoutId, setTimeoutId] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const handleChange = (field, value) => {
    dispatch(setProductFilters({ [field]: value, page: 1 }));
  };

  const onSearch = e => {
    const val = e.target.value;
    setSearch(val);
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        dispatch(setProductFilters({ ...filters, search: val, page: 1 }));
      }, 1000)
    );
  };

  const onClear = () => {
    setSearch("");
    dispatch(setProductFilters({ ...filters, search: "", page: 1 }));
  };

  const searchBar = (
    <OutlinedInput
      className={classes.input}
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        loading ? (
          <InputAdornment position="end">
            <div
              className="spinner-border mx-auto"
              role="status"
              style={{
                color: "18B4A1",
                fontSize: 1,
                height: 15,
                width: 15
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </InputAdornment>
        ) : search ? (
          <InputAdornment position="end">
            <IconButton
              style={{ marginRight: "-0.5em" }}
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => onClear()}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          ""
        )
      }
      value={search}
      margin="dense"
      placeholder="Search all products"
      onChange={e => onSearch(e)}
    />
  );

  const filter = (
    <div>
      <Button
        variant="outlined"
        color="primary"
        style={{ height: 40 }}
        onClick={() => setSortOpen(true)}
      >
        Filter
      </Button>
    </div>
  );

  const sortIcon = (
    <div className="d-flex">
      <IconButton onClick={() => handleChange("sort", "asc")}>
        <SortAsc fill={filters.sort === "asc" ? "#f97663" : "#909196"} />
      </IconButton>
      <IconButton onClick={() => handleChange("sort", "desc")}>
        <SortDesc fill={filters.sort === "desc" ? "#f97663" : "#909196"} />
      </IconButton>
    </div>
  );

  return (
    <div className="d-flex">
      <div className="mr-2">{searchBar}</div>
      <div>{filter}</div>
      <div>{sortIcon}</div>
      <SortSettings open={sortOpen} setOpen={setSortOpen} filters={filters} />
    </div>
  );
}

export default HeaderFilter;
