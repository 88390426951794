import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Slide, Dialog, Button } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DeleteProductModal(props) {
  const useStyles = makeStyles(theme => ({}));
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={props.isDelete.open}
        TransitionComponent={Transition}
      >
        <div style={{ padding: 32 }}>
          <div className="d-flex justify-content-center">
            <ErrorIcon style={{ color: "#f97663", fontSize: 80 }} />
          </div>
          <div
            className="text-center my-3 px-4"
            style={{ fontSize: 20, fontWeight: 600 }}
          >
            Are you sure you want to delete your product?
          </div>
          <div
            className="text-center mb-3"
            style={{ color: "#54575f", fontSize: 14 }}
          >
            If you wish to hide the product instead, simply uncheck the Display
            Product option.
          </div>
          <div className="d-flex">
            <Button
              variant="outlined"
              color="primary"
              className="mr-2"
              fullWidth
              onClick={() => props.setIsDelete({ open: false, product: "" })}
            >
              No, take me back
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="mr-2"
              fullWidth
              disableElevation
              onClick={props.handleDeleteProduct}
            >
              Yes, delete product
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
