import {
  handleError,
  handleSuccessMessage,
} from '../../../../../utils/Metamask';
import Services from './services';
import Cookies from 'js-cookie';
import apiConfig from '../../../../../common/apiConfig';
import hash from 'hash.js';

export const ActionTypes = {
  CONNECT_WALLET: 'CONNECT_WALLET',
  DISCONNET_WALLET: 'DISCONNET_WALLET',
  UPLOAD_IMAGE_IPFS: 'UPLOAD_IMAGE_IPFS',
  BUILD_METADATA_IPFS: 'BUILD_METADATA_IPFS',
};

class Actions {
  static CONNECT_WALLET(props) {
    return async (dispatch) => {
      try {
        const walletAddress = await Services.CONNECT_WALLET();
        if (walletAddress) {
          const token = Cookies.get('accesstoken');
          if (token) {
            const expectedPlainTextSignature = `${token}|||${walletAddress}||USDC|${apiConfig.nftWalletMapSecret}`;
            const signature = hash
              .sha256()
              .update(expectedPlainTextSignature)
              .digest('hex');
            const walletAddressMapping = await Services.MAP_WALLET(
              token,
              walletAddress,
              signature
            );
            if (walletAddressMapping) {
              dispatch({
                type: ActionTypes.CONNECT_WALLET,
                payload: walletAddress,
              });

              if (sessionStorage?.getItem('isWalletConnected') === 'true') {
                props?.onClose();
                return;
              }

              handleSuccessMessage('Wallet successfully connected.');
              sessionStorage?.setItem('isWalletConnected', true);

              props?.onClose();
            } else {
              props?.onClose();
            }
          } else {
            handleError(
              `Please login first to ${apiConfig.alamatSiteUrl} then try to reconnect wallet.`
            );
            props?.onClose();
          }
        }
        props?.onClose();
      } catch (error) {
        props?.onClose();
        if (typeof error == 'object') handleError(error.message);
        else handleError(error);
      }
    };
  }

  static DISCONNECT_WALLET() {
    return async (dispatch) => {
      try {
        if (Cookies.get('accesstoken')) {
          Cookies.remove('accesstoken');
          sessionStorage?.setItem('isWalletConnected', false);
        }
        dispatch({ type: ActionTypes.DISCONNET_WALLET });
      } catch (error) {
        if (typeof error == 'object') handleError(error.message);
        else handleError(error);
      }
    };
  }

  static CREATE_ORDER(data, uuid) {
    return async (dispatch) => {
      try {
        const res = await Services.CREATE_ORDER(data, uuid);
        dispatch({
          type: ActionTypes.CREATE_ORDER,
          payload: res.result,
        });
      } catch (error) {
        throw error;
      }
    };
  }

  static UPLOAD_IMAGE_IPFS(data, meta) {
    return async (dispatch) => {
      try {
        const res = await Services.UPLOAD_IMAGE_IPFS(data);
        dispatch({
          type: ActionTypes.UPLOAD_IMAGE_IPFS,
          payload: res.result,
        });
        if (res) {
          meta.asset = res.data.result.ipfs;
          const metadata = await Services.BUILD_METADATA_IPFS(meta);
          dispatch({
            type: ActionTypes.BUILD_METADATA_IPFS,
            payload: metadata,
          });
        }
      } catch (error) {
        if (typeof error == 'object') handleError(error.message);
        else handleError(error);
      }
    };
  }

  static REQUEST_MINT_PERMIT(buyer, seller) {
    return async (dispatch) => {
      try {
        const res = await Services.REQUEST_ACCESS_TOKEN();
        dispatch({
          type: ActionTypes.UPLOAD_IMAGE_IPFS,
          payload: res.result,
        });

        if (res) {
          const metadata = await Services.REQUEST_MINT_PERMIT(
            buyer,
            seller,
            res.data.access_token
          );
          dispatch({
            type: ActionTypes.BUILD_METADATA_IPFS,
            payload: metadata,
          });
        }
      } catch (error) {
        if (typeof error == 'object') handleError(error.message);
        else handleError(error);
      }
    };
  }
}

export default Actions;
