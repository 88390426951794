import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { useDispatch } from "react-redux";
import Actions from "../redux/actions";
import Cookies from "js-cookie";
import { handleError } from "../../../../../utils/Metamask";

import LevelFilter from "../components/levelFilter";
import SortFilter from "../components/sortFilter";
import TraitsFilter from "../components/traitsFilter";
import "../style/custom.css";

import apiConfig from "../../../../../common/apiConfig";

const Homepage = (props) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [data, setData] = useState([]);
  const [productListCopy, setProductListCopy] = useState([]);
  const [sortBy, setSortBy] = useState("highest");
  const [traitsList, setTraitsList] = useState({
    strength: [0, 25],
    spirit: [0, 25],
    smarts: [0, 25],
    stamina: [0, 25],
  });
  const [activeLevel, setActiveLevel] = useState(0);

  const CryptoJS = require("crypto-js");
  const dispatch = useDispatch();

  const itemsPerPage = 12;

  const onChangePage = (e, page) => {
    setPage(page);
  };

  const onClickSortNFT = (sortValue) => {
    setSortBy(sortValue);
    const result = sortNFTData(sortValue, productListCopy);
    setProductListCopy(result);
  };

  const onClickFilterTraitsNFT = (traits) => {
    setTraitsList(traits);
    const result = filterTraitsNFTData(traits);
    setProductListCopy(result);
  };

  const onClickFilterLevelNFT = (levelVal) => {
    const level = levelVal === activeLevel ? 0 : levelVal;
    setActiveLevel(level);
    const result = filterLevelNFTData(level);
    setProductListCopy(result);
  };

  const useStyles = makeStyles(() => ({
    rootPageItem: {
      color: "#5CA2D5 !important",
      fontSize: 20,
    },
    selectedPageItem: {
      backgroundColor: "#113764 !important",
      color: "#5CA2D5 !important",
    },
  }));

  const classes = useStyles();

  const paginateNFTData = () => {
    const dataList = productListCopy;
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setData(dataList.slice(start, end));
    setPageCount(Math.ceil(dataList.length / itemsPerPage));
  };

  const sortNFTData = (sortCondition, sortData) => {
    const sort = sortCondition;
    let newSortData = [...sortData];
    console.log(sortData);
    if (sort === "highest") {
      newSortData.sort((firstItem, secondItem) => {
        const aPrice = firstItem.has_variants
          ? firstItem.selections[0].price
          : firstItem.price;
        const bPrice = secondItem.has_variants
          ? secondItem.selections[0].price
          : secondItem.price;

        return bPrice - aPrice;
      });
    }
    if (sort === "lowest") {
      newSortData.sort((firstItem, secondItem) => {
        const aPrice = firstItem.has_variants
          ? firstItem.selections[0].price
          : firstItem.price;
        const bPrice = secondItem.has_variants
          ? secondItem.selections[0].price
          : secondItem.price;

        return aPrice - bPrice;
      });
    }
    if (sort === "a-z") {
      newSortData.sort((firstItem, secondItem) => {
        return ("" + firstItem.name).localeCompare(secondItem.name, undefined, {
          numeric: true,
        });
      });
    }
    newSortData = newSortData.filter(
      (item) => item.selections[0]?.quantity > 0
    );
    return newSortData;
  };

  const filterTraitsNFTData = (traits) => {
    let sortData = sortNFTData(sortBy, props.productList);

    if (activeLevel > 0) {
      sortData = filterLevelNFTData(activeLevel);
    }

    const filterTraits = sortData.filter((item) => {
      if (item.has_variants) {
        let variants = item.selections[0].variants;

        let strength = variants.find(
          (v) => v.group_name.toLowerCase() === "strength"
        );
        let spirit = variants.find(
          (v) => v.group_name.toLowerCase() === "spirit"
        );
        let smarts = variants.find(
          (v) => v.group_name.toLowerCase() === "smarts"
        );
        let stamina = variants.find(
          (v) => v.group_name.toLowerCase() === "stamina"
        );

        strength = strength === undefined ? 0 : strength.name;
        spirit = spirit === undefined ? 0 : spirit.name;
        smarts = smarts === undefined ? 0 : smarts.name;
        stamina = stamina === undefined ? 0 : stamina.name;

        return (
          strength >= traits.strength[0] &&
          strength <= traits.strength[1] &&
          spirit >= traits.spirit[0] &&
          spirit <= traits.spirit[1] &&
          smarts >= traits.smarts[0] &&
          smarts <= traits.smarts[1] &&
          stamina >= traits.stamina[0] &&
          stamina <= traits.stamina[1]
        );
      }
      return false;
    });

    return filterTraits;
  };

  const filterLevelNFTData = (level) => {
    let sortData = sortNFTData(sortBy, props.productList);

    if (level === 0) return sortData;

    const filterLevel = sortData.filter((item) => {
      if (item.has_variants) {
        let variants = item.selections[0].variants;

        let ItemLevel = variants.find(
          (v) => v.group_name.toLowerCase() === "level"
        );

        ItemLevel = ItemLevel === undefined ? 0 : parseInt(ItemLevel.name);

        return ItemLevel === level;
      }
      return false;
    });
    return filterLevel;
  };

  const connectWalletOnPageLoad = async () => {
    if (sessionStorage?.getItem("isWalletConnected") === "true") {
      dispatch(Actions.CONNECT_WALLET());
    }
  };

  useEffect(() => {
    if (props.productList.length > 0) {
      const sortData = sortNFTData(sortBy, props.productList);
      setProductListCopy(sortData);
    }
  }, [props.productList]);

  useEffect(() => {
    paginateNFTData();
  }, [page, productListCopy]);

  useEffect(() => {
    document.body.style.backgroundColor = "#051626";
    let search = window.location.search;
    let params = new URLSearchParams(search);

    let accessTokenParam = params.get("q");

    if (accessTokenParam != null && accessTokenParam.length > 0) {
      let decodedAccessTokenParam = decodeURIComponent(accessTokenParam);

      const bytes = CryptoJS.AES.decrypt(
        decodedAccessTokenParam,
        apiConfig.alamatSecretKey
      );

      try {
        const accessToken = bytes.toString(CryptoJS.enc.Utf8);

        if (accessToken) {
          document.cookie = `accesstoken=${accessToken}; path=/`;
          connectWalletOnPageLoad();
        }
      } catch (error) {
        handleError(
          `Please login first to ${apiConfig.alamatSiteUrl} then try to reconnect wallet.`
        );
      }
    } else {
      const token = Cookies.get("accesstoken");
      if (token) {
        connectWalletOnPageLoad();
      }
    }
  }, []);

  return (
    <>
      <div className="w-100 h-100 nft-container">
        <div style={{ paddingTop: "2rem" }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-5">
                {/*                 <div className="d-flex flex-row flex-wrap align-items-end w-100 h-100 pb-5 pb-md-0 pr-md-5">
                  <div className="flex-fill" style={{ paddingRight: '5px' }}>
                    <button className="nft-button-1">CARDS</button>
                  </div>
                  <div className="flex-fill">
                    <button className="nft-button-cut-corner">PACKS</button>
                  </div>
                </div> */}
              </div>
              <div className="col-sm-12 col-md-7">
                <div
                  className="nft-border-diamond-container text-center nft-font-barlow mx-auto"
                  style={{ width: "90%" }}
                >
                  <div className="nft-border-diamond nft-transaction-board">
                    <div>
                      <div style={{ color: "#5CA2D5" }}>VOLUME</div>
                      <div>$9,125.24</div>
                    </div>
                    <div
                      style={{
                        borderLeft: "3px solid #0f3344",
                        borderRight: "3px solid #0f3344",
                      }}
                    >
                      <div style={{ color: "#5CA2D5" }}>FLOOR PRICE</div>
                      <div>$357.00</div>
                    </div>
                    <div>
                      <div style={{ color: "#5CA2D5" }}>TOTAL SUPPLY</div>
                      <div>27,637</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <div className="leftsidebar">
                  <div>
                    <LevelFilter
                      activeLevel={activeLevel}
                      onClickFilterLevelNFT={onClickFilterLevelNFT}
                    />
                  </div>
                  <div style={{ paddingTop: "50px" }}>
                    <TraitsFilter
                      traitsList={traitsList}
                      onClickFilterTraitsNFT={onClickFilterTraitsNFT}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-8 pb-5">
                <div className="d-flex justify-content-end py-5">
                  <SortFilter sortBy={sortBy} onClickSortNFT={onClickSortNFT} />
                </div>
                <div className="card-container">
                  {data && data.length > 0 ? (
                    data
                      .filter((item) => item.status === "active")
                      .map((item, index) => {
                        return (
                          <div style={{ width: "100%" }} key={index}>
                            <div className="d-flex justify-content-center">
                              <ProductCard
                                onSelect={(value) => props.onSelect(value)}
                                item={item}
                                store={props.storeInfo}
                              />
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <p
                      className="text-center"
                      style={{ gridColumn: "1 / span 4" }}
                    >
                      No results found
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={onChangePage}
                    renderItem={(item) => (
                      <PaginationItem
                        classes={{
                          root: classes.rootPageItem,
                          selected: classes.selectedPageItem,
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
