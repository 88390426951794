import React from "react";
import {
  makeStyles,
  withStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import AppBar from "./AppBar";
import Footer from "./Footer";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import history from "../../../../store/history";
import StoreActions from "./StoreActions";
import { ADDRESS } from "./constants/address";
import { Radio } from "antd";
import { reset } from "redux-form";
import {
  Breadcrumbs,
  Link,
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  useTheme,
  useMediaQuery,
  InputAdornment,
} from "@material-ui/core";
import validations from "../../validations";
import { PromoBanner } from "./PromoBanner12.12";
import VoucherModal from "./voucher/voucherModal";
import CookiesModal, {
  saveCookie,
  unsaveCookie,
  useDelivery,
} from "../components/cookiesModal";
import StoreServices from "./StoreServices";
import StoreAlertBox from "./StoreAlertBox";
import FooterSection from "./footer/footer";
import Summary from "./cart/summary";
import RemarksComponent from "./Remarks";
import AddToCartLimit from "./addToCartLimit";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f8f8f8",
    width: "100%",
    padding: "6em 10vw",
    [theme.breakpoints.down("sm")]: {
      padding: "2em 0 10vh",
      height: "71vh",
      overflowY: "scroll",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2em 0 10vh",
      height: "55vh",
      overflowY: "scroll",
    },
  },
  breadcrumbs: {
    fontSize: 14,
    color: "rgba(43,45,50, 0.8)",
    "&:hover": {
      fontWeight: 600,
      textDecoration: "none",
      color: "#2b2d32",
    },
  },
  header: {
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: 28,
    color: "#2b2d32",
    [theme.breakpoints.down("sm")]: {
      padding: 24,
    },
  },
  details: {
    width: "65%",
    padding: 24,
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 24,
      marginBottom: 15,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: "rgba(43,45,50, 0.8)",
  },
  labelbold: {
    fontSize: 14,
    fontWeight: 600,
    color: "rgba(43,45,50, 0.8)",
  },
  label2: {
    fontSize: 10,
    fontWeight: 500,
    color: "rgba(43,45,50, 0.8)",
  },
  btnContinue: {
    borderColor: "1px solid #2b2d32",
    height: 40,
  },
  btnCheckout: {
    backgroundColor: "#2b2d32",
    color: "#fff",
    marginLeft: "1vw",
    height: 40,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 15,
    },
  },
  summary: {
    height: "fit-content",
    marginLeft: "2vw",
    padding: 24,
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 10,
      marginLeft: 0,
    },
  },
  columnSummary: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: "2vh",
    color: "rgba(43,45,50, 0.8)",
    border: "none",
  },
  column: {
    border: "none",
    width: "20%",
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 600,
    color: "rgba(43,45,50, 0.8)",
  },
  noItems: {
    width: "46vw",
    textAlign: "center",
    padding: 20,
    fontSize: 12,
  },
  detailsField: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  adornedEnd: {
    paddingRight: 0,
  },
}));

const Delivery = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/delivery", "");
  const storeName = param.replace("/shop/", "");
  const theme = useTheme();
  const store_theme = useSelector((state) => state.store.store.color);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const storeInfo = useSelector((state) => state.store.store);
  const loading = useSelector((state) => state.store.loading);
  const cartItems = useSelector((state) => state.store.items);
  const promo = useSelector((state) => state.store.promo);
  const customerDetails = useSelector((state) => state.store.customerDetails);
  let errorOpen = useSelector((state) => state.store.dialogOpen);
  const subtotal = useSelector((state) => state.store.subtotal);
  const total = useSelector((state) => state.store.total);
  const voucher_discount = useSelector((state) => state.store.voucher_discount);
  let vouchers = useSelector((state) => state.store.voucherList);
  let delivery_details = useSelector((state) => state.store.deliveryDetails);
  const shipping_fee = useSelector(
    (state) => state.store.deliveryDetails.shippingFee
  );
  const kyc = storeInfo ? storeInfo.kyc_level : 0;

  const token = sessionStorage.getItem("TOKEN");
  const current_user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const user_fullname = current_user
    ? current_user.first_name + " " + current_user.last_name
    : "";
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    streetAddress: "",
    email: "",
    barangayAddress: "",
    cityAddress: "",
    provinceAddress: "",
    postalCode: "",
    mobileNumber: "",
  });
  const [remember, setRemember] = React.useState(false);
  const [cookieModalOpen, setCookieModalOpen] = React.useState(false);
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const [checkoutDetailsOption, setCheckoutDetailsOption] =
    React.useState("guest");
  const [openCreateAccount, setOpenCreateAccount] = React.useState(false);
  const [password, setPassword] = React.useState({
    create_password: "",
    confirm_password: "",
  });
  const [showPassword, setShowPassword] = React.useState({
    createPassword: "",
    confirmPassword: "",
    loginPassword: "",
  });

  const [login, setLogin] = React.useState({
    email: "",
    password: "",
  });

  const [showAlert, setShowAlert] = React.useState({
    open: false,
    alertType: "error",
    alertMessage: "",
  });
  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [errors, setErrors] = React.useState({
    firstNameError: "",
    lastNameError: "",
    mobileError: "",
    emailError: "",
    createPasswordError: "",
    confirmPasswordError: "",
    loginEmailError: "",
    loginPasswordError: "",
    postalCodeError: "",
  });
  const [openBuxLimit, setOpenBuxLimit] = React.useState(false);

  const {
    firstName,
    lastName,
    streetAddress,
    email,
    barangayAddress,
    cityAddress,
    provinceAddress,
    postalCode,
    mobileNumber,
  } = values;

  let total_amount = total - voucher_discount;

  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold",
      },
      fontFamily: ["Inter", "sans-serif"],
    },
  });

  const totalAmt = parseFloat(
    cartItems.reduce((total, object) => {
      return (
        parseFloat(total) +
        (object.always_available
          ? parseFloat(object.quantity * object.price)
          : object.stock > 0
          ? parseFloat(object.quantity * object.price)
          : 0)
      );
    }, 0)
  );

  const cookieAccept = () => {
    setRemember(true);
    setCookieModalOpen(false);
  };
  const cookieDecline = () => {
    setRemember(false);
    setCookieModalOpen(false);
  };
  const saveCustomerDetails = () => {
    if (remember) {
      saveCookie({
        firstName,
        lastName,
        streetAddress,
        email,
        barangayAddress,
        cityAddress,
        provinceAddress,
        postalCode,
        mobileNumber,
      });
    } else {
      unsaveCookie();
    }
  };
  const details = useDelivery();
  React.useEffect(() => {
    if (details) {
      const json = JSON.parse(details);
      setValues({
        ...values,
        firstName: json["firstName"],
        lastName: json["lastName"],
        streetAddress: json["streetAddress"],
        email: json["email"],
        barangayAddress: json["barangayAddress"],
        cityAddress: json["cityAddress"],
        provinceAddress: json["provinceAddress"],
        postalCode: json["postalCode"],
        mobileNumber: json["mobileNumber"],
      });
    }
  }, [details]);

  React.useEffect(() => {
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(storeName));
    }
    fetchData();

    if (cartItems.length === 0) {
      history.push(`/shop/${storeName}`);
    }
  }, []);

  React.useEffect(() => {
    if (customerDetails) {
      setValues({ ...customerDetails });
    }
  }, [customerDetails]);

  React.useEffect(() => {
    if (promo.promo_code && promo.amount_gt > totalAmt) {
      dispatch(StoreActions.resetPromo());
    }
  }, [totalAmt]);

  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [errors]);

  React.useEffect(() => {
    setIsFormEnough(
      firstName &&
        lastName &&
        email &&
        streetAddress &&
        barangayAddress &&
        cityAddress &&
        provinceAddress &&
        mobileNumber
    );
  }, [values]);

  React.useEffect(() => {
    if (token && customerDetails === "") {
      let params = {
        firstName: current_user.first_name,
        lastName: current_user.last_name,
        email: current_user.email,
        mobileNumber: current_user.mobile_number,
        address: {
          street: current_user.streetAddress,
          brgy: current_user.barangayAddress,
          city: current_user.cityAddress,
          province: current_user.provinceAddress,
          zipcode: current_user.postalCode,
        },
      };
      dispatch(StoreActions.setCustomerDetails(params, storeInfo));
    }
  }, [token]);

  React.useEffect(() => {
    if (
      (storeInfo.kyc_level === 0 && parseInt(total_amount) > 100000) ||
      (storeInfo.kyc_level === 1 && parseInt(total - voucher_discount) > 500000)
    ) {
      setOpenBuxLimit(true);
    } else {
      setOpenBuxLimit(false);
    }
  }, [total_amount]);

  function setValueChanges(value, name) {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const getProvinces = () => {
    let provinces = [];
    for (var i in ADDRESS) {
      provinces.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return provinces;
  };

  const getCities = () => {
    let cities = [];
    if (provinceAddress in ADDRESS) {
      for (var i in ADDRESS[provinceAddress]["Municipality_list"]) {
        cities.push(<MenuItem value={i}>{i}</MenuItem>);
      }
    }
    return cities;
  };
  const getBarangay = () => {
    let baranggays = [];
    if (provinceAddress in ADDRESS) {
      if (cityAddress in ADDRESS[provinceAddress]["Municipality_list"]) {
        let baranggay_list =
          ADDRESS[provinceAddress]["Municipality_list"][cityAddress][
            "Barangay_list"
          ];
        for (var i = 0; i < baranggay_list.length; i++) {
          baranggays.push(
            <MenuItem value={baranggay_list[i]}>{baranggay_list[i]}</MenuItem>
          );
        }
      }
    }
    return baranggays;
  };

  function handleProvinceAddress(e) {
    setValues({
      ...values,
      provinceAddress: e.target.value,
      cityAddress: "",
      barangayAddress: "",
    });
  }

  function handleCityAddress(e) {
    setValues({
      ...values,
      cityAddress: e.target.value,
      barangayAddress: "",
    });
  }

  function handleBarangayAddress(e) {
    setValues({
      ...values,
      barangayAddress: e.target.value,
    });
  }

  function handleOpenCreateAccount() {
    setOpenCreateAccount(openCreateAccount ? false : true);
    setPassword({ create_password: "", confirm_password: "" });
    setErrors({
      ...errors,
      emailError: "",
      confirmPasswordError: "",
      createPasswordError: "",
    });
  }

  function handleCheckoutDetailsOption(e) {
    setCheckoutDetailsOption(e.target.value);
    setShowAlert({ open: false, alertMessage: "" });
    setLogin({ email: "", password: "" });
    setErrors({
      ...errors,
      loginEmailError: "",
      loginPasswordError: "",
    });
  }

  function disableProceedBtn() {
    if (openCreateAccount) {
      if (
        !isFormEnough ||
        formHasErrors ||
        cartItems.length === 0 ||
        password.create_password === "" ||
        password.confirm_password === ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (checkoutDetailsOption === "guest") {
        if (!isFormEnough || formHasErrors || cartItems.length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          login.email === "" ||
          login.password === "" ||
          errors.loginEmailError ||
          errors.loginPasswordError
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  async function proceed_to_checkout() {
    if (!openBuxLimit) {
      if (openCreateAccount) {
        let checkEmail = await StoreServices.uniqueEmail(values.email);

        if (checkEmail.data !== "") {
          setErrors({
            ...errors,
            emailError: "Email already exist",
          });
        } else {
          setErrors({ ...errors, emailError: "" });
          let params = {
            is_seller: false,
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            mobile_number: values.mobileNumber,
            password: password.create_password,
            address: {
              street: values.streetAddress,
              brgy: values.barangayAddress,
              city: values.cityAddress,
              province: values.provinceAddress,
              zipcode: values.postalCode,
              label: 'home',
            },
          };
          let response = await dispatch(
            StoreActions.createBuyerAccount(params)
          );

          try {
            if (response.status === 201) {
              saveCustomerDetails();
              dispatch(StoreActions.setCustomerDetails(values, storeInfo));
              history.push(`/shop/${storeInfo.site}/delivery_details`);
            }
          } catch {
            setShowAlert({
              open: true,
              alertType: "error",
              alertMessage:
                "Oops! There was an error processing your request. Please try again.",
            });
          }
        }
      } else {
        window.scrollTo(0, 0);
        saveCustomerDetails();
        dispatch(StoreActions.setCustomerDetails(values, storeInfo));
        dispatch(
          StoreActions.setDeliveryDetails({
            ...delivery_details,
            shipping: "",
            shippingFee: 0,
            delivery_detail: "",
            mrspeedy_delivery_address: {
              address: "",
              longitude: "",
              latitude: "",
            },
          })
        );
        dispatch(reset("courier_selection"));
        history.push(`/shop/${storeInfo.site}/delivery_details`);
      }
    }
  }

  async function handleLogin() {
    let res = await dispatch(StoreActions.buyerLogin(login));
    try {
      if (res.status === 200) {
        let user = res.data.user;
        let params = {
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          mobileNumber: user.mobile_number,
          streetAddress: user.address ? user.address.street : "",
          provinceAddress: user.address ? user.address.province : "",
          cityAddress: user.address ? user.address.city : "",
          barangayAddress: user.address ? user.address.brgy : "",
          postalCode: user.address ? user.address.zipcode : "",
        };
        dispatch(StoreActions.setCustomerDetails(params, storeInfo));
        setCheckoutDetailsOption("guest");
        setLogin({ email: "", password: "" });
        setErrors({ ...errors, loginEmailError: "", loginPasswordError: "" });
        setShowAlert({
          open: false,
          alertType: "success",
          alertMessage: "",
        });
      }
    } catch (e) {
      console.log(e);
      setShowAlert({
        open: true,
        alertType: "error",
        alertMessage: "Unable to log in with provided credentials.",
      });
    }
  }

  const ColoredCheckbox = withStyles({
    root: {
      color: "#2b2d32",
      "&$checked": {
        color: "#2b2d32",
      },
      margin: "10px 0",
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const breadcrumbLinks = [
    {
      label: "Home",
      path: `/shop/${storeInfo.site}`,
    },
    {
      label: "Cart",
      path: `/shop/${storeInfo.site}/cart`,
    },
    {
      label: "Details",
      path: `/shop/${storeInfo.site}/delivery`,
    },
    {
      label: "Delivery",
      path: `/shop/${storeInfo.site}/delivery_details`,
      isDisabled: true,
    },
    {
      label: "Payment",
      path: `/shop/${storeInfo.site}/payment`,
      isDisabled: true,
    },
  ];
  return (
    <div>
      {storeInfo && storeInfo.font.css_url ? (
        <link href={storeInfo.font.css_url} rel="stylesheet"></link>
      ) : (
        ""
      )}
      {openBuxLimit ? <AddToCartLimit kycLevel={storeInfo?.kyc_level} /> : ""}
      <ThemeProvider theme={store_generated_theme}>
        <div className={classes.root}>
          <CookiesModal
            open={cookieModalOpen}
            onClose={() => cookieModalOpen(false)}
            onAccept={cookieAccept}
            onDecline={cookieDecline}
          />
          <AppBar isPreview={false} storeInfo={storeInfo} />
          <Loading open={loading} />
          <div>
            <div style={{ padding: "0 3vw" }}>
              <PromoBanner />
            </div>
            <div>
              <Breadcrumbs
                style={{ display: isMobile ? "none" : "" }}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbLinks.map((item) => {
                  return (
                    <Link
                      disabled={item.isDisabled}
                      className={classes.breadcrumbs}
                      style={{
                        fontWeight:
                          item.label === "Details" ? "bold" : "normal",
                      }}
                      color="inherit"
                      onClick={() =>
                        item.isDisabled ? null : history.push(item.path)
                      }
                    >
                      {item.label}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            </div>
            <div style={{ marginTop: "5vh" }}>
              {/* <Button
                startIcon={<ArrowBackIosIcon />}
                variant="text"
                color="secondary"
                style={{ margin: "16px 24px", display: isMobile ? "" : "none" }}
                onClick={() => history.goBack()}
              >
                Back
              </Button> */}
              <div className={classes.header}>Customer Details</div>

              <div
                style={{
                  display: isMobile ? "block" : "flex",
                  marginTop: "3vh",
                }}
              >
                <Paper className={classes.details}>
                  <div style={{ marginBottom: showAlert.open ? "3vh" : 0 }}>
                    <StoreAlertBox alert={showAlert} />
                  </div>
                  {current_user && token ? (
                    <div>
                      <div className={classes.label}>
                        You are logged in as{" "}
                        <span
                          className={classes.labelbold}
                          style={{ textTransform: "capitalize" }}
                        >
                          {user_fullname}.
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <Radio.Group
                        value={checkoutDetailsOption}
                        onChange={(e) => handleCheckoutDetailsOption(e)}
                      >
                        <Radio value="guest">Continue as Guest</Radio>
                        <Radio value="login">Login</Radio>
                      </Radio.Group>
                    </div>
                  )}

                  {checkoutDetailsOption === "login" ? (
                    <div id="login-form" style={{ marginTop: " 4vh" }}>
                      <div className={classes.detailsField}>
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginBottom: 10,
                          }}
                        >
                          <div className={classes.label}>Email Address</div>
                          <TextField
                            color="secondary"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 0 }}
                            value={login.email}
                            error={errors.loginEmailError}
                            helperText={errors.loginEmailError}
                            onChange={(e) => {
                              let input = e.target.value;
                              setLogin({ ...login, email: input });
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  loginEmailError: "This field is required.",
                                });
                              } else if (
                                validations.email(input) ||
                                validations.verifyEmail(input)
                              ) {
                                setErrors({
                                  ...errors,
                                  loginEmailError:
                                    "Please enter a valid email address",
                                });
                              } else if (validations.noCaps(input)) {
                                setErrors({
                                  ...errors,
                                  loginEmailError: "Uppercase is not allowed",
                                });
                              } else {
                                setErrors({
                                  ...errors,
                                  loginEmailError: "",
                                });
                              }
                            }}
                            onBlur={(e) => {
                              let input = e.target.value;
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  loginEmailError: "This field is required.",
                                });
                              } else if (
                                validations.email(input) ||
                                validations.verifyEmail(input)
                              ) {
                                setErrors({
                                  ...errors,
                                  loginEmailError:
                                    "Please enter a valid email address",
                                });
                              } else if (validations.noCaps(input)) {
                                setErrors({
                                  ...errors,
                                  loginEmailError: "Uppercase is not allowed",
                                });
                              } else {
                                setErrors({
                                  ...errors,
                                  loginEmailError: "",
                                });
                              }
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginLeft: isMobile ? 0 : "2vw",
                          }}
                        >
                          <div className={classes.label}>Password</div>
                          <TextField
                            id="login_password"
                            color="secondary"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 0 }}
                            className={classes.textfieldPadding}
                            type={
                              showPassword.loginPassword ? "text" : "password"
                            }
                            value={login.password}
                            error={errors.loginPasswordError}
                            helperText={errors.loginPasswordError}
                            onChange={(e) => {
                              const input = e.target.value;
                              setLogin({ ...login, password: input });
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  loginPasswordError: "This field is required.",
                                });
                              } else {
                                setErrors({
                                  ...errors,
                                  loginPasswordError: "",
                                });
                              }
                            }}
                            onBlur={(e) => {
                              const input = e.target.value;
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  loginPasswordError: "This field is required.",
                                });
                              } else {
                                setErrors({
                                  ...errors,
                                  loginPasswordError: "",
                                });
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    color="secondary"
                                    style={{ fontSize: 10, height: 40 }}
                                    onClick={() =>
                                      setShowPassword({
                                        ...showPassword,
                                        loginPassword:
                                          !showPassword.loginPassword,
                                      })
                                    }
                                  >
                                    {showPassword.loginPassword
                                      ? "HIDE"
                                      : "SHOW"}
                                  </Button>
                                </InputAdornment>
                              ),
                              classes: {
                                adornedEnd: classes.adornedEnd,
                              },
                            }}
                          />
                          <div>
                            <Link
                              onClick={() =>
                                history.push(
                                  `/shop/${
                                    storeInfo && storeInfo.site
                                  }/forgot_password`
                                )
                              }
                              color="secondary"
                              style={{ fontSize: 10 }}
                            >
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginTop: "4vh" }}>
                      <div
                        style={{
                          flexDirection: isMobile
                            ? "column-reverse"
                            : "inherit",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginBottom: 10,
                          }}
                        >
                          <div className={classes.label}>Email Address</div>
                          <TextField
                            id="email"
                            color="secondary"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 0 }}
                            value={email}
                            error={errors.emailError}
                            helperText={errors.emailError}
                            onChange={(e) => {
                              let input = e.target.value;

                              setValueChanges(input, "email");
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  emailError: "This field is required.",
                                });
                              } else if (
                                validations.email(input) ||
                                validations.verifyEmail(input)
                              ) {
                                setErrors({
                                  ...errors,
                                  emailError:
                                    "Please enter a valid email address",
                                });
                              } else if (validations.noCaps(input)) {
                                setErrors({
                                  ...errors,
                                  emailError: "Uppercase is not allowed",
                                });
                              } else {
                                setErrors({
                                  ...errors,
                                  emailError: "",
                                });
                              }
                            }}
                            onBlur={(e) => {
                              let input = e.target.value;
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  emailError: "This field is required.",
                                });
                              } else if (
                                validations.email(input) ||
                                validations.verifyEmail(input)
                              ) {
                                setErrors({
                                  ...errors,
                                  emailError:
                                    "Please enter a valid email address",
                                });
                              } else if (validations.noCaps(input)) {
                                setErrors({
                                  ...errors,
                                  emailError: "Uppercase is not allowed",
                                });
                              } else {
                                setErrors({
                                  ...errors,
                                  emailError: "",
                                });
                              }
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginLeft: isMobile ? 0 : "2vw",
                            marginBottom: 10,
                          }}
                        >
                          <div className={classes.label}>Mobile Number</div>
                          <TextField
                            id="mobile_number"
                            color="secondary"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 0 }}
                            inputProps={{ maxLength: 11 }}
                            value={mobileNumber}
                            error={errors.mobileError}
                            helperText={errors.mobileError}
                            onChange={(e) => {
                              let input = e.target.value.replace(/[^0-9]/g, "");
                              setValueChanges(input, "mobileNumber");

                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  mobileError: "This field is required.",
                                });
                              } else {
                                setErrors({ ...errors, mobileError: "" });
                              }
                            }}
                            onBlur={(e) => {
                              let input = e.target.value;
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  mobileError: "This field is required.",
                                });
                              } else if (input.length < 11) {
                                setErrors({
                                  ...errors,
                                  mobileError:
                                    "Please enter a valid 11-digit mobile number.",
                                });
                              } else if (input.charAt(0) != 0) {
                                setErrors({
                                  ...errors,
                                  mobileError:
                                    "You have entered an invalid mobile number",
                                });
                              } else if (input.charAt(1) != 9) {
                                setErrors({
                                  ...errors,
                                  mobileError:
                                    "You have entered an invalid mobile number",
                                });
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className={classes.detailsField}>
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginBottom: 10,
                          }}
                        >
                          <div className={classes.label}>First Name</div>
                          <TextField
                            id="first_name"
                            color="secondary"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 0 }}
                            value={firstName}
                            inputProps={{ maxLength: 30 }}
                            error={errors.firstNameError}
                            helperText={errors.firstNameError}
                            onChange={(e) => {
                              let input = e.target.value;
                              setValueChanges(input, "firstName");
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  firstNameError: "This field is required.",
                                });
                              } else {
                                setErrors({ ...errors, firstNameError: "" });
                              }
                            }}
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                setErrors({
                                  ...errors,
                                  firstNameError: "This field is required.",
                                });
                              }
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginLeft: isMobile ? 0 : "2vw",
                            marginBottom: 10,
                          }}
                        >
                          <div className={classes.label}>Last Name</div>
                          <TextField
                            id="last_name"
                            color="secondary"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 0 }}
                            value={lastName}
                            inputProps={{ maxLength: 30 }}
                            error={errors.lastNameError}
                            helperText={errors.lastNameError}
                            onChange={(e) => {
                              let input = e.target.value;
                              setValueChanges(input, "lastName");
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  lastNameError: "This field is required.",
                                });
                              } else {
                                setErrors({ ...errors, lastNameError: "" });
                              }
                            }}
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                setErrors({
                                  ...errors,
                                  lastNameError: "This field is required.",
                                });
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className={classes.detailsField}>
                        <div style={{ width: "100%" }}>
                          <div className={classes.label}>Street Address</div>
                          <TextField
                            id="street_address"
                            color="secondary"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 0 }}
                            inputProps={{ maxLength: 128 }}
                            value={streetAddress}
                            onChange={(e) =>
                              setValueChanges(e.target.value, "streetAddress")
                            }
                          />
                        </div>
                      </div>

                      <div className={classes.detailsField}>
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginBottom: 10,
                          }}
                        >
                          <div className={classes.label}>Province</div>
                          <FormControl
                            style={{ width: "100%" }}
                            variant="outlined"
                          >
                            <Select
                              id="province"
                              color="secondary"
                              margin="dense"
                              value={provinceAddress}
                              onChange={handleProvinceAddress}
                            >
                              {getProvinces()}
                            </Select>
                          </FormControl>
                        </div>
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginLeft: isMobile ? 0 : "2vw",
                            marginBottom: 10,
                          }}
                        >
                          <div className={classes.label}>City</div>
                          <FormControl
                            style={{ width: "100%" }}
                            variant="outlined"
                            disabled={
                              provinceAddress
                                ? provinceAddress === ""
                                  ? true
                                  : false
                                : true
                            }
                          >
                            <Select
                              id="city"
                              color="secondary"
                              margin="dense"
                              value={cityAddress}
                              onChange={handleCityAddress}
                            >
                              {getCities()}
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      <div className={classes.detailsField}>
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginBottom: 10,
                          }}
                        >
                          <div className={classes.label}>Barangay</div>
                          <FormControl
                            style={{ width: "100%" }}
                            variant="outlined"
                            disabled={
                              cityAddress
                                ? cityAddress == ""
                                  ? true
                                  : false
                                : true
                            }
                          >
                            <Select
                              id="barangay"
                              color="secondary"
                              margin="dense"
                              value={barangayAddress}
                              onChange={handleBarangayAddress}
                            >
                              {getBarangay()}
                            </Select>
                          </FormControl>
                        </div>
                        <div
                          style={{
                            width: isMobile ? "auto" : "50%",
                            marginLeft: isMobile ? 0 : "2vw",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div className={classes.label}>Postal/ZIP Code</div>
                            <div className={classes.label2}>Optional</div>
                          </div>

                          <TextField
                            id="postal_code"
                            color="secondary"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 0 }}
                            inputProps={{ maxLength: 4 }}
                            value={postalCode}
                            error={errors.postalCodeError}
                            helperText={errors.postalCodeError}
                            onChange={(e) => {
                              const input = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              setValueChanges(input, "postalCode");
                              if (input === "") {
                                setErrors({
                                  ...errors,
                                  postalCodeError: "This field is required.",
                                });
                              } else {
                                setErrors({ ...errors, postalCodeError: "" });
                              }
                            }}
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                setErrors({
                                  ...errors,
                                  postalCodeError: "This field is required.",
                                });
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <RemarksComponent />
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display:
                        checkoutDetailsOption === "login" ||
                        (current_user && token)
                          ? "none"
                          : "",
                    }}
                  >
                    <div>
                      <FormControlLabel
                        label={
                          <div className={classes.label}>
                            Remember this information for next time
                          </div>
                        }
                        control={
                          <ColoredCheckbox
                            checked={remember}
                            onChange={(event) => {
                              setRemember(event.target.checked);
                              setCookieModalOpen(event.target.checked);
                            }}
                            name="remember"
                          />
                        }
                      />
                    </div>
                    {/* Create account section */}
                    <div>
                      <FormControlLabel
                        control
                        label={
                          <div className={classes.label}>
                            Create an account for faster transaction and order
                            monitoring
                          </div>
                        }
                        control={
                          <ColoredCheckbox
                            color="primary"
                            checked={openCreateAccount}
                            onChange={handleOpenCreateAccount}
                          />
                        }
                      />
                    </div>
                    <div
                      className={classes.detailsField}
                      style={{ display: openCreateAccount ? "" : "none" }}
                    >
                      <div
                        style={{
                          width: isMobile ? "auto" : "50%",
                          marginBottom: 10,
                        }}
                      >
                        <div className={classes.label}>Create a password</div>
                        <TextField
                          id="create_password"
                          color="secondary"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          style={{ marginTop: 0 }}
                          className={classes.textfieldPadding}
                          type={
                            showPassword.createPassword ? "text" : "password"
                          }
                          value={password.create_password}
                          error={errors.createPasswordError}
                          helperText={errors.createPasswordError}
                          onChange={(e) => {
                            const input = e.target.value;
                            setPassword({
                              ...password,
                              create_password: input,
                            });
                            if (input === "") {
                              setErrors({
                                ...errors,
                                createPasswordError: "This field is required.",
                              });
                            } else {
                              setErrors({ ...errors, createPasswordError: "" });
                            }
                          }}
                          onBlur={(e) => {
                            const input = e.target.value;
                            if (input === "") {
                              setErrors({
                                ...errors,
                                createPasswordError: "This field is required.",
                              });
                            } else if (
                              !input.match(
                                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
                              )
                            ) {
                              setErrors({
                                ...errors,
                                createPasswordError:
                                  "Please have at least 8 characters with a mix of numbers, letters and symbols.",
                              });
                            } else {
                              setErrors({ ...errors, createPasswordError: "" });
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  color="secondary"
                                  style={{ fontSize: 10, height: 40 }}
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      createPassword:
                                        !showPassword.createPassword,
                                    })
                                  }
                                >
                                  {showPassword.createPassword
                                    ? "HIDE"
                                    : "SHOW"}
                                </Button>
                              </InputAdornment>
                            ),
                            classes: {
                              adornedEnd: classes.adornedEnd,
                            },
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: isMobile ? "auto" : "50%",
                          marginLeft: isMobile ? 0 : "2vw",
                        }}
                      >
                        <div className={classes.label}>Confirm password</div>
                        <TextField
                          id="confirm_password"
                          color="secondary"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          style={{ marginTop: 0 }}
                          className={classes.textfieldPadding}
                          type={
                            showPassword.confirmPassword ? "text" : "password"
                          }
                          value={password.confirm_password}
                          error={errors.confirmPasswordError}
                          helperText={errors.confirmPasswordError}
                          onChange={(e) => {
                            const input = e.target.value;
                            setPassword({
                              ...password,
                              confirm_password: input,
                            });
                            if (input === "") {
                              setErrors({
                                ...errors,
                                confirmPasswordError: "This field is required.",
                              });
                            } else if (input !== password.create_password) {
                              setErrors({
                                ...errors,
                                confirmPasswordError:
                                  "Your passwords do not match",
                              });
                            } else {
                              setErrors({
                                ...errors,
                                confirmPasswordError: "",
                              });
                            }
                          }}
                          onBlur={(e) => {
                            const input = e.target.value;
                            if (input === "") {
                              setErrors({
                                ...errors,
                                confirmPasswordError: "This field is required.",
                              });
                            } else if (
                              !input.match(
                                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
                              )
                            ) {
                              setErrors({
                                ...errors,
                                confirmPasswordError:
                                  "Please have at least 8 characters with a mix of numbers, letters and symbols.",
                              });
                            } else if (input !== password.create_password) {
                              setErrors({
                                ...errors,
                                confirmPasswordError:
                                  "Your passwords do not match",
                              });
                            } else {
                              setErrors({
                                ...errors,
                                confirmPasswordError: "",
                              });
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  color="secondary"
                                  style={{ fontSize: 10, height: 40 }}
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      confirmPassword:
                                        !showPassword.confirmPassword,
                                    })
                                  }
                                >
                                  {showPassword.confirmPassword
                                    ? "HIDE"
                                    : "SHOW"}
                                </Button>
                              </InputAdornment>
                            ),
                            classes: {
                              adornedEnd: classes.adornedEnd,
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "5vh",
                      display: isMobile ? "none" : "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      className={classes.btnContinue}
                      onClick={() =>
                        history.push(`/shop/${storeInfo.site}/products`)
                      }
                    >
                      Continue Shopping
                    </Button>
                    <Button
                      id="proceed_to_checkout"
                      variant="contained"
                      disableElevation
                      className={classes.btnCheckout}
                      style={{
                        display:
                          checkoutDetailsOption === "guest" ? "" : "none",
                      }}
                      disabled={disableProceedBtn()}
                      onClick={proceed_to_checkout}
                    >
                      Proceed to Checkout
                    </Button>

                    <Button
                      id="login_btn"
                      variant="contained"
                      disableElevation
                      className={classes.btnCheckout}
                      color="secondary"
                      style={{
                        display:
                          checkoutDetailsOption === "login" ? "" : "none",
                      }}
                      disabled={
                        login.email === "" ||
                        login.password === "" ||
                        errors.loginEmailError ||
                        errors.loginPasswordError
                      }
                      onClick={handleLogin}
                    >
                      Log in
                    </Button>
                  </div>
                </Paper>

                <Summary
                  loading={loading}
                  storeInfo={storeInfo}
                  cartItems={cartItems}
                  subtotal={subtotal}
                  total={total}
                  delivery_details={delivery_details}
                  voucher_discount={voucher_discount}
                  shipping_fee={shipping_fee}
                  proceed_to_checkout={proceed_to_checkout}
                  disabled={disableProceedBtn()}
                  continue_shopping={true}
                />
              </div>
            </div>
          </div>
        </div>

        <VoucherModal
          id="voucher-modal"
          open={openVoucher}
          setOpen={setOpenVoucher}
        />
      </ThemeProvider>
    </div>
  );
};

export default Delivery;
