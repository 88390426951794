import React from "react";
import { TextField, FormHelperText } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import sentroTheme from "./sentroTheme";

const SentroTextField = ({
  input,
  label,
  maxLength,
  minLength,
  multiline,
  cut,
  noHelpText,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <ThemeProvider theme={sentroTheme}>
      <div>
        <TextField
          id="textfield"
          color="secondary"
          InputLabelProps={{
            shrink: true
          }}
          onInput={e => {
            if (cut && e.target.value.toString().length >= maxLength) {
              return (e.target.value = e.target.value
                .toString()
                .slice(0, maxLength));
            }
          }}
          helperText={
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <span> {touched && error ? error : ""}</span>
              <span>
                {noHelpText
                  ? ""
                  : minLength && input.value.length < 8
                  ? `${label} must be atleast ${minLength} characters`
                  : maxLength
                  ? `${input.value.length} / ${maxLength}`
                  : ""}
              </span>
            </span>
          }
          variant="outlined"
          margin="dense"
          multiline={multiline}
          rows={4}
          rowsMax={4}
          size={"small"}
          fullWidth
          error={touched && error}
          {...input}
          {...custom}
        />
      </div>
    </ThemeProvider>
  );
};

export default SentroTextField;
