import React from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";
import {
  Button,
  Tabs,
  AppBar,
  Tab,
  Box,
  Typography,
  useMediaQuery,
  Dialog,
  Toolbar,
  IconButton,
  Slide,
} from "@material-ui/core";
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import SwipeableViews from "react-swipeable-views";
import { useDispatch, useSelector, connect } from "react-redux";
import { reduxForm  } from 'redux-form';  // ES6
import { useHistory } from 'react-router';
import PropTypes from "prop-types";
import ProfilePane from './components/profile/panes/profile';
import PasswordPane from './components/profile/panes/password';
import AddressPane from './components/profile/panes/address';
import DashboardActions from './redux/actions';
import BuyerDashboardActions from './redux/actions';
import { ReduxInitializeFormActions as ReduxInit } from './redux/actions';
import { passwordAsyncValidation } from '../../reduxFields/validators';
import { stopSubmit } from 'redux-form'
import { animateScroll as scroll } from "react-scroll";


const DialogTransition = props => <Slide direction="up" {...props} />;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="p-0" p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: {
      minWidth: 100
    }
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 750,
    margin: "2em auto",
    overflow: "hidden"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  slideWrapper: {
    transition: "all 0.3s cubic-bezier(0, 0, 0.38, 1.1)",
    display: "flex",
    width: "200%"
  },
  headerSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    marginTop: "2em",
    left: "32vw",
    right: "10vw",
    borderTop: "1px solid #D4D5D8",
    borderLeft: "1px solid #D4D5D8",
    borderRight: "1px solid #D4D5D8",
    borderBottom: "none",
    borderRadius: 4,
    overflow: "visible"
  },
  btnDiv: {
    borderBottom: "1px solid #D4D5D8",
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    paddingTop: 0,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingBottom: "2em"
  },
  appbar: {
    backgroundColor: "white",
    boxShadow: "inset 0px -4px 0px 0px #eeeeee"
  },
  tabs: {
    position: "relative",
    backgroundColor: "white",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd",
    padding: "0 2em"
  },
  details: {
    backgroundColor: "white"
  },
  tabPanel: {
    "& > div": {
      padding: "0px !important"
    }
  },
  tabsBorderBottom: {
    borderBottom: "solid 4px #eeeeee"
  }
}));

const EditProfile = props => {

  const classes = useStyles();
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const history = useHistory();

  const CustomTabs = withStyles({
    root: {
      position: "relative",
      backgroundColor: "#FAFAFA",
      borderBottom: `${is_mobile ? 0: "1px"} solid ${theme.palette.primary.main}`,
      padding: "0 2em",
      marginTop: 0,
      width: "auto",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        position: "fixed",
        top: 56,
        width: "100%",
      },
      boxShadow: "inset 0px -4px 0px 0px #eeeeee"
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: 3
    },
  })(Tabs);

  const [tab, setTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  // retreive and load data
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  React.useEffect(() => {
    if (user) {
      const data = {
        first_name: user.first_name,
        last_name: user.last_name,
        mobile_number: user.mobile_number,
        email: user.email,
      }
      props.initializeProfileData(data)
    }
  }, [user]);

  const syncErrors = useSelector(state => state.form.edit_profile?.syncErrors);
  const asyncErrors = useSelector(state => state.form.edit_profile?.asyncErrors);
  const err = { ...syncErrors, ...asyncErrors };
  const values = useSelector(state => state.form.edit_profile?.values)
  const loading = useSelector(state => state.buyerDashboard.loading)
  const fields = useSelector(state => state.form.edit_profile?.fields)

  // addresses
  const [addresses, setAddresses] = React.useState([]);
  const [addressesForDeletion, setAddressesForDeletion] = React.useState([]);
  const addressOnChange = (data) => {
    setAddresses(data)
  }
  const addressOnDelete = (id) => {
    setAddressesForDeletion([...addressesForDeletion, id])
  }

  const onSubmit = async () => {
    const errKeys = Object.keys(err);
    let tab2_is_touched = false;
    if (fields) {
      tab2_is_touched = values.old_password || values.confirm_new_password;
    }

    const fieldnamesByTab = [
      ["first_name", "last_name", "mobile_number"], 
      [],
      tab2_is_touched ? ["old_password", "new_password", "confirm_new_password"]: []
    ]
    const field_names = fieldnamesByTab.reduce((a, b) => a.concat(b), []);
    if (!errKeys.some(fieldname => field_names.includes(fieldname))) {
      let pass_data = {};
      if(tab2_is_touched) {
        pass_data = {
          ...pass_data,
          old_password: values.old_password,
          new_password: values.new_password
        }
      }

      const res = await dispatch(DashboardActions.patchProfile({
        first_name: values.first_name,
        last_name: values.last_name,
        mobile_number: values.mobile_number,
      }, pass_data))

      await dispatch(BuyerDashboardActions.saveAddresses(addresses))
      await dispatch(BuyerDashboardActions.deleteAddresses(addressesForDeletion))

      if (typeof(res) === "object") {
        dispatch(stopSubmit("edit_profile", res))
      } else {
        if (is_mobile) {
          history.push(`/my_cart`)
        }
        else {
          scroll.scrollTo(0);
        }
      }
    } 
    else {
      for (let i in fieldnamesByTab) {
        console.log(fieldnamesByTab)
        if (errKeys.some(fieldname => fieldnamesByTab[i].includes(fieldname))) {
          setTab(parseInt(i))
        }
      }
      field_names.forEach(fieldname => props.touch(fieldname))
    }
  }

  return (

    is_mobile ? 
    <Dialog
      fullScreen
      open={is_mobile}
      TransitionComponent={DialogTransition}
    >
      <div 
      id="profile_content" />
      <AppBar className={classes.appbar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className="position-absolute"
            onClick={() => history.push(`/my_cart`)}
          >
            <ArrowBackRoundedIcon color="primary" />
          </IconButton>
          <div className={classes.header}>My account</div>
        </Toolbar>
      </AppBar>
      <CustomTabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="simple tabs example"
      >
        <Tab label="Profile" {...a11yProps(0, is_mobile)} classes={{root: classes.tabsBorderBottom}} />
        <Tab label="Addresses" {...a11yProps(1, is_mobile)} classes={{root: classes.tabsBorderBottom}} />
        <Tab label="Password" {...a11yProps(2, is_mobile)} classes={{root: classes.tabsBorderBottom}} />
      </CustomTabs>
      <div style={{paddingBottom: 64, paddingTop: 105}}>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={tab}
          enableMouseEvents
          onChangeIndex={(index) => handleTabChange("event", index)}
        >
          <TabPanel>
            <div id="profile-pane">
            <ProfilePane />
            </div>
          </TabPanel>
          <TabPanel>
            <div id="address-pane">
            <AddressPane onChange={addressOnChange} onDelete={addressOnDelete}/>
            </div>
          </TabPanel>
          <TabPanel>
            <div id="password-pane">
            <PasswordPane />
            </div>
          </TabPanel>
        </SwipeableViews>
      </div>
      <div className="position-fixed bg-white p-3 w-100" style={{ bottom: 0 }}>
        <Button color="primary" fullWidth variant="contained" 
          disabled={loading} disableElevation onClick={onSubmit}>
          {
            loading ? 
              <div className="d-flex my-5 mr-3">
                <div className="spinner-border mx-auto" role="status" style={{ 
                color: "white", fontSize: 12,
                height: 25, width: 25
                }}>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            :""
          }
          Save changes
        </Button>
      </div>
    </Dialog>
    :
    <div className={`${classes.root} content`}>
      <div data-comment="main page">
        <div className={classes.headerSection}>
          <div
            style={{
              fontSize: 28,
              color: "#002c42",
              fontWeight: 600
            }}
          >
            My account
          </div>
        </div>
        <div className={classes.content}>
        <div className={classes.details}>
          <AppBar className={classes.appbar} position="static" color="default">
            <Tabs
              classes={{root: classes.tabs}}
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
            >
              <Tab className="profile-tab" label="Profile" {...a11yProps(0)} />
              <Tab className="addresses-tab" label="Addresses" {...a11yProps(1)} />
              <Tab className="password-tab" label="Password" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <div className={`${tab === 0 ? 'd-block': "d-none"}`}>
            <div className="profile-pane">
              <ProfilePane />
            </div>
          </div>
          <div className={`${tab === 1 ? 'd-block': "d-none"}`}>
            <div className="addresses-pane">
              <AddressPane onChange={addressOnChange} onDelete={addressOnDelete}/>
            </div>
          </div>
          <div className={`${tab === 2 ? 'd-block': "d-none"}`}>
            <div className="password-pane">
              <PasswordPane />
            </div>
          </div>
          <div className={classes.btnDiv} >
            <Button className="submit-button" color="primary" fullWidth variant="contained" 
              disabled={loading} disableElevation onClick={onSubmit}>
              {
              loading ? 
              <div className="d-flex my-5 mr-3">
                <div className="spinner-border mx-auto" role="status" style={{ 
                color: "white", fontSize: 12,
                height: 25, width: 25
                }}>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              :""
              }
              Save changes
            </Button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
let Form = reduxForm({
  form: "edit_profile",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  submitAsSideEffect: true,
  asyncValidate: passwordAsyncValidation,
  asyncChangeFields: ['new_password', 'confirm_new_password']
})(EditProfile);

Form = connect(
  state => ({
    initialValues: state.buyerDashboard.reduxForm.customerDetailsForm
  }),
  { 
    initializeProfileData: ReduxInit.initializeCustomerDetailsData
  },
)(Form)

export default Form;
