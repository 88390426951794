import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  tableHead: {
    backgroundColor: "#eee",
    color: "#54575f",
    fontSize: 14,
    whiteSpace: "nowrap"
  },
  headIconWrapper: {
    direction: "rtl",
    flexGrow: 1,
    minWidth: 45,
    display: "flex",
    height: 20
  },
  headIconUp: {
    transform: "rotate(0deg)",
    position: "absolute",
    transform: "translateY(-8px)"
  },
  headIconDown: {
    transform: "rotate(180deg)",
    position: "absolute"
  },
  bodyRow: {
    whiteSpace: "nowrap",
    backgroundColor: "#fff",
    color: "#002c42",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#fafafa !important"
    }
  },
  sticky: {
    position: "sticky",
    right: 0,
    backgroundColor: "#eee",
    color: "#54575f",
    fontSize: 14
  },
  paymentStatus: {
    "& .paid": {
      backgroundColor: "#57b19f",
      fontSize: 12,
      color: "#fff"
    },
    "& .pending": {
      backgroundColor: "#FFC044",
      fontSize: 12,
      color: "#2b2d32"
    },
    "& .cancelled": {
      backgroundColor: "#54575F",
      fontSize: 12,
      color: "#fff"
    }
  }
}));
