import React from "react";
import {
  FormControl,
  FormControlLabel,
  Select,
  FormGroup,
  Checkbox,
  TextField,
  Button,
  MenuItem,
  Modal
} from "@material-ui/core";
import { Radio } from "antd";
import sentroLogo from "../../assets/images/badge.png";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./kycQuestionsStyles";
import {
  skipSurvey,
  saveKYCSurvey
} from "../websiteBuilder/websiteBuilderActions";

import { ADDRESS } from "../common/template/components/constants/address";
import DateDropdown from "./kycSurvey/dateDropdownField";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const KYCQuestions = props => {
  const isKYC_successful = useSelector(state => state.webBuilder.kyc_success);
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [pageTwo, setPageTwo] = React.useState(false);
  const [pageThree, setPageThree] = React.useState(false);
  const [sameAddress, setSameAddress] = React.useState(false);
  const [kycAnswer, setKycAnswer] = React.useState({
    birthdate: "",
    gender: "not_specified",
    civil_status: "",
    industry: "Please choose one...",
    selling_age: "Please choose one...",
    businessAge: "",
    other_stores: [],
    employee_size: "Please choose one...",
    business_province: "Select province",
    business_city: "Select city",
    business_needs: [],
    notSpecified: false,
    month: "",
    day: "",
    year: "",
    other_stores_others: false,
    other_stores_others_value: "",
    business_needs_others: false,
    business_needs_others_value: ""
  });

  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [checkboxError2, setCheckboxError2] = React.useState(false);
  const [errors, setErrors] = React.useState({
    birthdayError: "",
    nationalityError: "",
    typeOfProductsError: "",
    onlineSellingError: "",
    businessAddressError: "",
    homeAddressError: "",
    runBusinessError: "",
    businessNeedsError: ""
    // checkboxError: ""
  });

  React.useEffect(() => {
    window.onbeforeunload = function() {
      if (isKYC_successful) {
        let existing = sessionStorage.getItem("CURRENT_USER");
        existing = existing ? JSON.parse(existing) : {};
        existing["survey_answered"] = true;
        sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
      }
    };
  });

  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [errors]);

  const handleOtherStores = (e, val) => {
    if (e.target.checked) {
      if (!kycAnswer.other_stores.includes(val)) {
        setKycAnswer({
          ...kycAnswer,
          other_stores: [...kycAnswer.other_stores, val]
        });
      }
    } else {
      const array = kycAnswer.other_stores.filter(e => e !== val);
      setKycAnswer({ ...kycAnswer, other_stores: array });
    }
  };

  const getProvinces = () => {
    let provinces = [];
    for (var i in ADDRESS) {
      provinces.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return provinces;
  };

  const getCity = () => {
    let cities = [];
    if (kycAnswer.business_province in ADDRESS) {
      for (var i in ADDRESS[kycAnswer.business_province]["Municipality_list"]) {
        cities.push(<MenuItem value={i}>{i}</MenuItem>);
      }
    }

    return cities;
  };

  const handleBusinessNeedsChange = (e, val) => {
    if (e.target.checked) {
      if (!kycAnswer.business_needs.includes(val)) {
        setKycAnswer({
          ...kycAnswer,
          business_needs: [...kycAnswer.business_needs, val]
        });
      }
    } else {
      const array = kycAnswer.business_needs.filter(e => e !== val);
      setKycAnswer({ ...kycAnswer, business_needs: array });
    }
  };

  function setKycAnswerDetails(key, value) {
    setKycAnswer(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  function handlePageTwo() {
    if (kycAnswer.birthday === "" || kycAnswer.birthday === null) {
      setErrors({
        ...errors,
        birthdayError: "This field is required."
      });
      window.scrollTo(0, 0);
    } else {
      setPageTwo(true);
      window.scrollTo(0, 0);
    }
  }
  function handlePageThree() {
    if (
      kycAnswer.other_stores_others &&
      kycAnswer.other_stores_others_value === ""
    ) {
      setErrors({
        ...errors,
        onlineSellingError: "This field is required."
      });
    } else {
      setPageTwo(false);
      setPageThree(true);
      window.scrollTo(0, 0);
    }
  }

  function handleShowPage() {
    if (pageTwo) {
      return kycPageTwo();
    } else if (pageThree) {
      return kycPageThree();
    } else {
      return kycPageOne();
    }
  }

  function handleHomepage() {
    dispatch(skipSurvey());
  }
  function handleSubmit() {
    if (
      kycAnswer.business_needs_others &&
      kycAnswer.business_needs_others_value === ""
    ) {
      setErrors({
        ...errors,
        businessNeedsError: "This field is required."
      });
    } else {
      let value = {
        birthdate: kycAnswer.birthdate,
        gender: kycAnswer.gender,
        civil_status: kycAnswer.civil_status,
        industry: kycAnswer.industry,
        selling_age: kycAnswer.selling_age,
        other_stores: kycAnswer.other_stores_others
          ? [...kycAnswer.other_stores, kycAnswer.other_stores_others_value]
          : kycAnswer.other_stores,
        employee_size: kycAnswer.employee_size,
        business_province: kycAnswer.business_province,
        business_city: kycAnswer.business_city,
        business_needs: kycAnswer.business_needs_others
          ? [...kycAnswer.business_needs, kycAnswer.business_needs_others_value]
          : kycAnswer.business_needs
      };

      dispatch(saveKYCSurvey(value));
    }
  }

  function surveyAnswered() {
    if (user.is_facebookLogin) {
      if (user.survey_answered) {
        if (user.survey_answered === false || user.survey_answered === null) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      if (user.survey_answered === false || user.survey_answered === null) {
        return true;
      } else {
        return false;
      }
    }
  }

  function checkOtherStores() {
    if (!kycAnswer.other_stores.length) {
      if (
        kycAnswer.other_stores_others &&
        !kycAnswer.other_stores_others_value
      ) {
        return true;
      } else if (
        kycAnswer.other_stores_others &&
        kycAnswer.other_stores_others_value
      ) {
        return false;
      } else return true;
    } else {
      return false;
    }
  }

  function checkBusinessNeeds() {
    if (!kycAnswer.business_needs.length) {
      if (
        kycAnswer.business_needs_others &&
        !kycAnswer.business_needs_others_value
      ) {
        return true;
      } else if (
        kycAnswer.business_needs_others &&
        kycAnswer.business_needs_others_value
      ) {
        return false;
      } else return true;
    } else {
      return false;
    }
  }

  function handleClose() {
    dispatch(skipSurvey());
  }

  const gender = [
    { label: "Male", value: "Male" },
    { label: "Rather not say", value: "Rather not say" },
    { label: "Female", value: "Female" },
    { label: "Not Specified", value: "" }
  ];

  const civilStatus = [
    { value: "Single" },
    { value: "Married" },
    { value: "Separated" },
    { value: "Widowed" }
  ];

  const industryList = [
    { value: "Art & photography" },
    { value: "Beauty & wellness" },
    { value: "Clothing" },
    { value: "Footwear" },
    { value: "Digital services" },
    { value: "Electronics" },
    { value: "Food & drink" },
    { value: "Groceries" },
    { value: "Handicrafts" },
    { value: "Home & furniture" },
    { value: "Jewelry & accessories" },
    { value: "Sports & fitness" },
    { value: "Travel & leisure" },
    { value: "I haven't decided yet" }
  ];

  const businessAge = [
    { value: "I just started" },
    { value: "Less than a year" },
    { value: "1-2 years" },
    { value: "3-5 years" },
    { value: "5-10 years" },
    { value: "Over 10 years" }
  ];

  const businessSizeList = [
    { value: "I do all the work myself" },
    { value: "1-3 employees" },
    { value: "4-10 employees" },
    { value: "Over 10 employees" },
    { value: "Over 50 employees" },
    { value: "Over 100 employees" }
  ];
  const onlineSellingPlatform = [
    { label: "Facebook", name: "facebook" },
    { label: "Shopee", name: "shopee" },
    { label: "Physical store", name: "physicalStore" },
    { label: "Instagram", name: "instagram" },
    { label: "Lazada", name: "lazada" },
    { label: "Shopify", name: "shopify" },
    { label: "Own website", name: "ownWebsite" }
  ];

  const businessNeeds = [
    {
      label: "Additional capital",
      name: "additionalCapital"
    },
    { label: "Suppliers", name: "suppliers" },
    {
      label: "Digital advertising",
      name: "digitalAdvertising"
    },
    {
      label: "Government compliance",
      name: "complianceGovernment"
    },
    {
      label: "Payment solutions",
      name: "paymentSolution"
    },
    { label: "Delivery and logistics", name: "logistics" }
  ];

  // React.useEffect(() => {
  //   if (!kycAnswer.other_stores_others) {
  //     setKycAnswer({ ...kycAnswer, other_stores_others_value: "" });
  //     setErrors({
  //       ...errors,
  //       onlineSellingError: ""
  //     });
  //   }
  // }, [kycAnswer.other_stores]);

  function kycPageOne() {
    return (
      <div className={classes.form}>
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: 12 }}>1 of 3</div>
          <div className={classes.header}>Tell us about yourself</div>
          <div style={{ margin: "1vh 0 2vh", fontSize: 12, padding: "0 3vw" }}>
            Help us get to know you better so we can provide you the best
            experience possible. This will only take less than 5 minutes.
          </div>
        </div>

        <div className="py-3">
          <div className={classes.label}>1. When is your date of birth?</div>
          <div style={{ marginTop: 10, marginLeft: 20 }}>
            <DateDropdown
              id="date_field"
              state={kycAnswer}
              setState={setKycAnswer}
            />
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label}>2. Gender</div>
          <div style={{ marginLeft: 20, position: "relative" }}>
            <Radio.Group
              id="gender"
              style={{ margin: "15px 0 0", width: "100%" }}
              className={classes.radioGender}
              value={kycAnswer.notSpecified ? "" : kycAnswer.gender}
              onChange={e => {
                setKycAnswerDetails("gender", e.target.value);
                setKycAnswerDetails(
                  "notSpecified",
                  e.target.value !== "" ? false : true
                );
              }}
            >
              {gender.map(item => {
                return (
                  <Radio
                    style={{ width: "45%", marginBottom: 20 }}
                    value={item.value}
                  >
                    {item.label}
                  </Radio>
                );
              })}
            </Radio.Group>
            {kycAnswer.notSpecified ? (
              <div style={{ position: "absolute", bottom: 5, right: 10 }}>
                <TextField
                  id="gender_not_specified"
                  inputProps={{ maxLength: 16 }}
                  margin="dense"
                  variant="outlined"
                  color="secondary"
                  style={{ width: 120 }}
                  placeholder="Pls. specify"
                  value={kycAnswer.gender}
                  onChange={e => setKycAnswerDetails("gender", e.target.value)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <div className={classes.label}>3. Civil Status</div>
          <div style={{ marginLeft: 20 }}>
            <Radio.Group
              id="civil_status"
              style={{ margin: "15px 0 0", width: "100%" }}
              className={classes.radioStatus}
              value={kycAnswer.civil_status}
              onChange={e =>
                setKycAnswerDetails("civil_status", e.target.value)
              }
            >
              {civilStatus.map(item => {
                return (
                  <Radio
                    style={{ width: "45%", marginBottom: 20 }}
                    value={item.value}
                  >
                    {item.value}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <Button
            id="skip_btn"
            style={{ width: "50%" }}
            variant="text"
            color="primary"
            onClick={handleClose}
          >
            I'll do it later
          </Button>
          <Button
            color="primary"
            disableElevation
            variant="contained"
            style={{ width: "50%", marginLeft: 25 }}
            onClick={handlePageTwo}
            disabled={
              kycAnswer.birthdate === "" ||
              kycAnswer.day === "Day" ||
              kycAnswer.month === "" ||
              kycAnswer.year === "" ||
              kycAnswer.gender === "" ||
              kycAnswer.gender === "not_specified" ||
              kycAnswer.civil_status === ""
            }
          >
            Next page
          </Button>
        </div>
      </div>
    );
  }

  function kycPageTwo() {
    return (
      <div className={classes.form}>
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: 12 }}>2 of 3</div>
          <div className={classes.header}>Tell us about your business</div>
          <div style={{ margin: "1vh 0 3vh", fontSize: 12, padding: "0 3vw" }}>
            We'd like to know more about your business so we can build the
            features that would fit your needs.
          </div>
        </div>
        <div>
          <div className={classes.label}>
            4. Which type of industry do you plan to sell in?
          </div>
          <div style={{ margin: "10px 20px" }}>
            <FormControl
              id="industry"
              variant="outlined"
              style={{ width: "70%" }}
            >
              <Select
                margin="dense"
                color="secondary"
                value={kycAnswer.industry}
                onChange={e => setKycAnswerDetails("industry", e.target.value)}
              >
                <MenuItem disabled value={"Please choose one..."}>
                  Please choose one...
                </MenuItem>
                {industryList.map(item => (
                  <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div>
          <div className={classes.label}>
            5. How long have you been selling?
          </div>
          <div style={{ margin: "10px 20px" }}>
            <FormControl variant="outlined" style={{ width: "70%" }}>
              <Select
                margin="dense"
                color="secondary"
                value={kycAnswer.selling_age}
                onChange={e =>
                  setKycAnswerDetails("selling_age", e.target.value)
                }
              >
                <MenuItem disabled value={"Please choose one..."}>
                  Please choose one...
                </MenuItem>
                {businessAge.map(item => (
                  <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div style={{ marginTop: "10px 20px" }}>
          <div className={classes.label}>
            6. Where else do you sell your products? Select all that apply.
          </div>
          <div style={{ marginLeft: 20, position: "relative" }}>
            <FormGroup
              style={{
                flexDirection: "inherit"
              }}
            >
              {onlineSellingPlatform.map(item => {
                return (
                  <div style={{ width: "30%" }}>
                    <FormControlLabel
                      style={{
                        marginRight: 0,
                        display: "flex"
                      }}
                      control={
                        <Checkbox
                          name={item.name}
                          checked={kycAnswer.other_stores.includes(item.label)}
                          onChange={e => handleOtherStores(e, item.label)}
                          color="primary"
                        />
                      }
                      label={<div style={{ fontSize: 14 }}>{item.label}</div>}
                    />
                  </div>
                );
              })}
              <div style={{ width: "30%" }}>
                <FormControlLabel
                  style={{
                    marginRight: 0,
                    display: "flex"
                  }}
                  control={
                    <Checkbox
                      name="onlineChannelOthers"
                      checked={kycAnswer.other_stores_others}
                      onChange={() => {
                        setKycAnswer({
                          ...kycAnswer,
                          other_stores_others_value: "",
                          other_stores_others: kycAnswer.other_stores_others
                            ? false
                            : true
                        });
                        setErrors({ ...errors, onlineSellingError: "" });
                      }}
                      color="primary"
                    />
                  }
                  label={<div style={{ fontSize: 14 }}>Others</div>}
                />
              </div>
            </FormGroup>
            <TextField
              id="online_selling_others"
              variant="outlined"
              margin="dense"
              inputProps={{ maxLength: 32 }}
              disabled={!kycAnswer.other_stores_others}
              value={kycAnswer.other_stores_others_value}
              onChange={e => {
                setKycAnswer({
                  ...kycAnswer,
                  other_stores_others_value: e.target.value
                });
                if (e.target.value.length > 0) {
                  setErrors({
                    ...errors,
                    onlineSellingError: ""
                  });
                }
              }}
              onBlur={e => {
                if (e.target.value === "") {
                  setErrors({
                    ...errors,
                    onlineSellingError: "This field is required."
                  });
                } else {
                  setErrors({
                    ...errors,
                    onlineSellingError: ""
                  });
                }
              }}
              style={{
                width: 100,
                position: "absolute",
                right: "10em",
                bottom: 0
              }}
            />
            <div
              className={classes.errorText}
              style={{
                width: 120,
                position: "absolute",
                right: "12em",
                bottom: -18
              }}
            >
              {errors.onlineSellingError}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center mt-3">
          <Button
            style={{ width: "50%" }}
            variant="text"
            color="primary"
            onClick={() => setPageTwo(false)}
          >
            Previous page
          </Button>
          <Button
            color="primary"
            disableElevation
            variant="contained"
            style={{ width: "50%", marginLeft: 25 }}
            onClick={handlePageThree}
            disabled={
              errors.onlineSellingError ||
              kycAnswer.industry === "Please choose one..." ||
              kycAnswer.selling_age === "Please choose one..." ||
              checkOtherStores()
            }
          >
            Next page
          </Button>
        </div>
      </div>
    );
  }
  function kycPageThree() {
    return (
      <div className={classes.form}>
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: 12 }}>3 of 3</div>
          <div className={classes.header}>Tell us about your business</div>
          <div style={{ margin: "1vh 0 3vh", fontSize: 12, padding: "0 3vw" }}>
            Just a little bit more!
          </div>
        </div>
        <div>
          <div className={classes.label}>
            7. What is the current size of your business?
          </div>
          <div style={{ margin: "10px 20px" }}>
            <FormControl variant="outlined" style={{ width: "70%" }}>
              <Select
                margin="dense"
                color="secondary"
                value={kycAnswer.employee_size}
                onChange={e =>
                  setKycAnswerDetails("employee_size", e.target.value)
                }
              >
                <MenuItem disabled value={"Please choose one..."}>
                  Please choose one...
                </MenuItem>
                {businessSizeList.map(item => (
                  <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label}>
            8. Where is your business located?
          </div>
          <div style={{ margin: "10px 20px" }} className="d-flex">
            <FormControl style={{ width: "50%" }} variant="outlined">
              <Select
                id="province"
                color="secondary"
                margin="dense"
                value={kycAnswer.business_province}
                onChange={e =>
                  setKycAnswerDetails("business_province", e.target.value)
                }
              >
                <MenuItem disabled value={"Select province"}>
                  Select province
                </MenuItem>
                {getProvinces()}
              </Select>
            </FormControl>
            <FormControl
              style={{ width: "50%", marginLeft: 10 }}
              variant="outlined"
              disabled={
                kycAnswer
                  ? kycAnswer.business_province === "Select province"
                    ? true
                    : false
                  : true
              }
            >
              <Select
                id="city"
                color="secondary"
                margin="dense"
                value={kycAnswer.business_city}
                inputProps={{ style: { fontSize: 12 } }}
                onChange={e =>
                  setKycAnswerDetails("business_city", e.target.value)
                }
              >
                <MenuItem disabled value={"Select city"}>
                  Select city
                </MenuItem>
                {getCity()}
              </Select>
            </FormControl>
          </div>
        </div>

        <div>
          <div className={classes.label}>
            9. What are your other business needs? Select all that apply.
          </div>
          <div style={{ marginLeft: 20, position: "relative" }}>
            <FormGroup
              style={{
                flexDirection: "inherit"
              }}
            >
              {businessNeeds.map(item => {
                return (
                  <div style={{ width: "40%" }}>
                    <FormControlLabel
                      style={{
                        marginRight: 0,
                        marginBottom: 0,
                        display: "flex"
                      }}
                      control={
                        <Checkbox
                          name={item.name}
                          checked={kycAnswer.business_needs.includes(
                            item.label
                          )}
                          onChange={e =>
                            handleBusinessNeedsChange(e, item.label)
                          }
                          color="primary"
                        />
                      }
                      label={<div style={{ fontSize: 13 }}>{item.label}</div>}
                    />
                  </div>
                );
              })}
              <div style={{ width: "40%" }}>
                <FormControlLabel
                  style={{
                    marginRight: 0,
                    marginBottom: 0,
                    display: "flex"
                  }}
                  control={
                    <Checkbox
                      name={"businessNeedsOthers"}
                      checked={kycAnswer.business_needs_others}
                      onChange={() => {
                        setKycAnswer({
                          ...kycAnswer,
                          business_needs_others_value: "",
                          business_needs_others: kycAnswer.business_needs_others
                            ? false
                            : true
                        });
                        setErrors({ ...errors, businessNeedsError: "" });
                      }}
                      color="primary"
                    />
                  }
                  label={<div style={{ fontSize: 13 }}>Others</div>}
                />
              </div>
            </FormGroup>
            <TextField
              color="secondary"
              variant="outlined"
              margin="dense"
              value={kycAnswer.business_needs_others_value}
              disabled={!kycAnswer.business_needs_others}
              inputProps={{ maxLength: 32, style: { fontSize: 12 } }}
              onChange={e => {
                setKycAnswer({
                  ...kycAnswer,
                  business_needs_others_value: e.target.value
                });
                if (e.target.value.length > 0) {
                  setErrors({
                    ...errors,
                    businessNeedsError: ""
                  });
                }
              }}
              onBlur={e => {
                if (e.target.value === "") {
                  setErrors({
                    ...errors,
                    businessNeedsError: "This field is required."
                  });
                } else {
                  setErrors({
                    ...errors,
                    businessNeedsError: ""
                  });
                }
              }}
              style={{
                width: 120,
                position: "absolute",
                left: 100,
                bottom: 0
              }}
            />
            <div
              style={{
                width: 120,
                position: "absolute",
                left: 100,
                bottom: -18
              }}
              className={classes.errorText}
            >
              {errors.businessNeedsError}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center mt-3">
          <Button
            style={{ width: "50%" }}
            variant="text"
            color="primary"
            onClick={() => {
              setPageThree(false);
              setPageTwo(true);
            }}
          >
            Previous page
          </Button>
          <Button
            color="primary"
            disableElevation
            variant="contained"
            style={{ width: "50%", marginLeft: 25 }}
            onClick={handleSubmit}
            disabled={
              formHasErrors ||
              kycAnswer.employee_size === "Please choose one..." ||
              kycAnswer.business_province === "Select province" ||
              kycAnswer.business_city === "Select city" ||
              checkBusinessNeeds()
              // kycAnswer.business_needs.length === 0 || kycAnswer.business_needs_others && kycAnswer.bus
            }
          >
            Finish survey
          </Button>
        </div>
      </div>
    );
  }

  function kycFinish() {
    return (
      <div className={classes.form2}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={sentroLogo} style={{ width: 80, height: 80 }} />
        </div>
        <div className={classes.title2}>Thank you for answering!</div>
        <div
          style={{
            textAlign: "center",
            fontSize: 16,
            padding: "2vh 2vw",
            color: "rgba(43,45,50,0.8)"
          }}
        >
          Your answers will help us improve Sentro and build more helpful tools
          for your business needs.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "3vh"
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            style={{ height: 40, padding: "0 90px" }}
            onClick={() => handleHomepage()}
          >
            Continue to my dashboard
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Modal
      id="kyc_dialog"
      // maxWidth={isKYC_successful ? "xs" : "sm"}
      open={false}
    >
      <div style={modalStyle} className={classes.modal}>
        <div className="p-4" style={{ width: isKYC_successful ? 410 : 570 }}>
          {isKYC_successful ? kycFinish() : handleShowPage()}
        </div>
      </div>
    </Modal>
  );
};

export default KYCQuestions;
