import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles, Button } from '@material-ui/core/'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(theme => ({
  infoAlert: {
    background: "#1E4B83",
    border: "1px solid #3A73CC",
    color: "white",
    borderRadius: 4,
    padding: "0.5em 1em",
    fontSize: 14,
    "& button": {
      width: "content-fit",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
  }
}));
const Banner = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={`mt-2 mb-md-4 ${classes.infoAlert}`}>
      <div className="d-flex align-items-center flex-wrap">
        <div className="d-flex align-items-center">
          <InfoOutlinedIcon />
          <p className="mb-0 ml-2"> To get better sales, 
          add delivery locations for <strong>Luzon</strong>, 
          <strong>Visayas</strong>, and <strong>Mindanao</strong> regions.</p>
        </div>
        <Button disableElevation variant="outlined" color="secondary" 
          style={{ height: 38, color: "#1E4B83" }} className="ml-0 ml-md-auto mt-2 mt-md-0 "
          classes={{root: "bg-white"}} onClick={() => history.push("/delivery/settings")}>
          Manage delivery settings
        </Button>
      </div>
    </div>
  )
}

export default Banner;