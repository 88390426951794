import axios from "axios";
import apiConfig from "../../common/apiConfig";
import errorMessage from "../common/errorMessage";

const CURRENT_USER = "CURRENT_USER";
export const FORGOT_PASS_EMAIL = "FORGOT_PASS_EMAIL";
export const NEW_PASSWORD = "NEW_PASSWORD";
async function authUser(value) {
  let params = {
    username: value.username,
    password: value.password
  };

  try {
    let response = await axios.post(`${apiConfig.apiURL}/api/login`, params);
    let data = response.data;
    sessionStorage.setItem(CURRENT_USER, JSON.stringify(data));
    sessionStorage.setItem("TOKEN", JSON.stringify(data.token));
    return data;
  } catch (error) {
    throw new Error(errorMessage.invalidEmailorPass);
  }
}

async function logout() {
  try {
    localStorage.removeItem(CURRENT_USER);
    sessionStorage.removeItem(CURRENT_USER);
  } catch (error) {
    throw new Error(errorMessage.networkErrorMessage);
  }
}

async function changePassword(email) {
  let params = {
    email: email
  };
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/password-reset/`,
      params
    );
    localStorage.setItem("CHANGE_PASSWORD_EMAIL", params.email);
    return response;
  } catch (e) {
    throw e.response;
  }
}

async function verify(value) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/password-reset/?token=${value.token}`
    );
    return response;
  } catch (error) {
    if (error.message == errorMessage.networkError) {
      throw new Error(errorMessage.networkErrorMessage);
    } else {
      throw new Error(errorMessage.errorVerification);
    }
  }
}

async function confirmChangePassword(value) {
  let params = {
    new_password: value.password,
    token: value.token
  };
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/password-reset/`, params,
    );
    return response;
  } catch (error) {
    if (error.message == errorMessage.networkError) {
      throw new Error(errorMessage.networkErrorMessage);
    } else {
      throw new Error(error.response.data.error)
    }
  }
}

async function fbSignUp(value) {
  let params = {
    firstName: value.firstname,
    familyName: value.lastname,
    email: value.email,
    id: value.id,
    profilePic: value.profilePic,
    accessToken: value.accessToken
  };
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/auth/facebook-login`,
      params
    );
    let data = response.data;
    sessionStorage.setItem(CURRENT_USER, JSON.stringify(data));
    return data;
  } catch (error) {
    throw new Error(errorMessage.networkErrorMessage);
  }
}

async function uniqueEmail(value) {
  let response = await axios.get(`${apiConfig.apiURL}/api/unique-email/`, {
    params: {
      email: value
    }
  });
  if (response.data != "") {
    throw { email: "Email is already registered" };
  } else {
    return false;
  }
}

async function isExist(value) {
  let response = await axios.get(`${apiConfig.apiURL}/api/unique-email`, {
    params: {
      email: value
    }
  });
  return response;
}

export {
  authUser,
  fbSignUp,
  logout,
  changePassword,
  confirmChangePassword,
  uniqueEmail,
  verify,
  isExist
};
