import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  Dialog,
  Slide,
  RadioGroup,
  FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { ReactComponent as SortAsc } from "../../../../../../assets/images/enterprise/sortAsc.svg";
import { ReactComponent as SortDesc } from "../../../../../../assets/images/enterprise/sortDesc.svg";
import { Close, CalendarToday } from "@material-ui/icons";
import { setOrderFilters } from "../../webBuilder/websiteBuilderActions";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import BpRadio from "../../../../../delivery_settings/components/radioButtons";
import moment from "moment";
const Transition = props => <Slide direction="up" {...props} />;
const Filter = props => {
  const { open, setOpen } = props;
  const useStyles = makeStyles(theme => ({
    datepicker: {
      backgroundColor: "#fff",
      width: 155,
      marginRight: 5
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const filters = useSelector(state => state.mobileDashboard.orders.filters);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [payment_status, set_payment_status] = React.useState("all");
  const [delivery_status, set_delivery_status] = React.useState("all");

  const payment_statuses = [
    { label: "All", value: "all" },
    { label: "Paid", value: "paid" },
    { label: "Pending", value: "pending" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Expired", value: "expired" }
  ];

  const couriers = [
    { label: "Borzo", value: "mrspeedy" }
    // { label: "Entrego", value: "entrego" },
    // { label: "NinjaVan", value: "ninjavan" }
  ];

  const delivery_statuses = [
    { label: "All", value: "all" },
    { label: "Delivered", value: "completed" },
    { label: "For pick-up", value: "active" },
    { label: "Looking for rider", value: "available" },
    { label: "In transit", value: "courier_departed" },
    { label: "Cancelled", value: "canceled" }
  ];

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <div className="d-flex justify-content-between align-items-center p-4">
        <div style={{ fontSize: 18, fontWeight: 600, color: "#2b2d32" }}>
          Sort and filter
        </div>
        <IconButton onClick={() => setOpen(false)}>
          <Close style={{ color: "#54575f" }} />
        </IconButton>
      </div>
      <div
        className="px-4 py-2"
        style={{ height: "72vh", overflowY: "scroll" }}
      >
        <div>
          <div style={{ fontSize: 14, fontWeight: 500 }}>Date range</div>
          <div className="d-flex align-items-center">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                label="Start Date"
                margin="dense"
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                InputAdornmentProps={{ position: "start" }}
                orientation="portrait"
                color="secondary"
                className={`${classes.datepicker} w-50 mr-2`}
                value={startDate}
                maxDate={
                  endDate === null
                    ? new Date().toLocaleDateString("en-US")
                    : endDate
                }
                onChange={e => setStartDate(moment(e).format("MM/DD/YYYY"))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarToday
                        style={{ color: "#909196", fontSize: 18 }}
                      />
                    </InputAdornment>
                  )
                }}
              />
              <DatePicker
                autoOk
                label="End Date"
                margin="dense"
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                InputAdornmentProps={{ position: "start" }}
                orientation="portrait"
                color="secondary"
                className={`${classes.datepicker} w-50`}
                value={endDate}
                minDate={
                  startDate === null
                    ? new Date().toLocaleDateString("en-US")
                    : startDate
                }
                onChange={e => setEndDate(moment(e).format("MM/DD/YYYY"))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarToday
                        style={{ color: "#909196", fontSize: 18 }}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="border-bottom my-4">
          <div style={{ fontSize: 14, fontWeight: 500 }}>Payment status</div>
          <RadioGroup
            className="my-2"
            value={payment_status}
            onChange={e => set_payment_status(e.target.value)}
          >
            {payment_statuses.map(item => (
              <FormControlLabel
                value={item.value}
                control={<BpRadio color="primary" />}
                label={
                  <div style={{ fontSize: 14, color: "#54575f" }}>
                    {item.label}
                  </div>
                }
              />
            ))}
          </RadioGroup>
        </div>
        <div className="border-bottom my-4">
          <div style={{ fontSize: 14, fontWeight: 500 }}>Courier</div>
          <RadioGroup className="my-2">
            {couriers.map(item => (
              <FormControlLabel
                value={item.value}
                control={<BpRadio color="primary" />}
                label={
                  <div style={{ fontSize: 14, color: "#54575f" }}>
                    {item.label}
                  </div>
                }
              />
            ))}
          </RadioGroup>
        </div>

        <div className=" my-4">
          <div style={{ fontSize: 14, fontWeight: 500 }}>Delivery status</div>
          <RadioGroup
            className="my-2"
            value={delivery_status}
            onChange={e => set_delivery_status(e.target.value)}
          >
            {delivery_statuses.map(item => (
              <FormControlLabel
                value={item.value}
                control={<BpRadio color="primary" />}
                label={
                  <div style={{ fontSize: 14, color: "#54575f" }}>
                    {item.label}
                  </div>
                }
              />
            ))}
          </RadioGroup>
        </div>
      </div>
      <div
        className="position-fixed bg-white px-3 py-2"
        style={{ bottom: 0, left: 0, right: 0 }}
      >
        <Button variant="outlined" color="primary" fullWidth className="mb-2">
          Extract CSV
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          className="mb-2"
          onClick={() => {
            setOpen(false);
            dispatch(
              setOrderFilters({
                date_ordered_type:
                  startDate && endDate ? "custom" : filters.date_ordered_type,
                startDate: startDate,
                endDate: endDate,
                payment_status: payment_status,
                delivery_status: delivery_status
              })
            );
          }}
        >
          Apply filter
        </Button>
      </div>
    </Dialog>
  );
};
export default function OrderFilters(props) {
  const useStyles = makeStyles(theme => ({
    input: { backgroundColor: "#fff" },
    datepicker: {
      backgroundColor: "#fff",
      width: 155,
      marginRight: 5
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector(state => state.mobileDashboard.orders.filters);
  const loading = useSelector(state => state.mobileDashboard.orders.loading);
  const [open, setOpen] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const onSearch = e => {
    const val = e.target.value;
    setSearch(val);
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        dispatch(setOrderFilters({ ...filters, search: val, page: 0 }));
      })
    );
  };

  const onClear = () => {
    setSearch("");
    dispatch(setOrderFilters({ ...filters, search: "", page: 0 }));
  };

  const searchBar = (
    <OutlinedInput
      id="search_bar"
      className={`${classes.input} mt-1 mr-2`}
      fullWidth
      color="secondary"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon style={{ color: "#909196" }} />
        </InputAdornment>
      }
      endAdornment={
        loading ? (
          <InputAdornment position="end">
            <div
              className="spinner-border mx-auto"
              role="status"
              style={{
                color: "18B4A1",
                fontSize: 1,
                height: 15,
                width: 15
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </InputAdornment>
        ) : search ? (
          <InputAdornment position="end">
            <IconButton
              style={{ marginRight: "-0.5em" }}
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => onClear()}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ) : (
          ""
        )
      }
      value={search}
      margin="dense"
      placeholder="Search"
      onChange={e => onSearch(e)}
    />
  );

  const filter = (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      className="mt-1 ml-2"
      style={{ height: 40 }}
      onClick={() => setOpen(true)}
    >
      Filter
    </Button>
  );

  const sort = (
    <div className="d-flex mt-1">
      <IconButton
        onClick={() =>
          dispatch(setOrderFilters({ order: "asc", orderBy: "ref_id" }))
        }
      >
        <SortAsc fill={filters.order === "asc" ? "#f97663" : "#909196"} />
      </IconButton>
      <IconButton
        onClick={() =>
          dispatch(setOrderFilters({ order: "desc", orderBy: "ref_id" }))
        }
      >
        <SortDesc fill={filters.order === "desc" ? "#f97663" : "#909196"} />
      </IconButton>
    </div>
  );
  return (
    <div className="d-flex align-items-center">
      <div>{searchBar}</div>
      <div>{filter}</div>
      <div>{sort}</div>
      <Filter open={open} setOpen={setOpen} />
    </div>
  );
}
