import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dialog, CircularProgress } from "@material-ui/core";

import ProductStyles from "./ProductStyle";
import StoreActions from "./StoreActions";

function StoreLoading(props) {
  const classes = ProductStyles();

  return (
    <Dialog open={props.open}>
      <div
        style={{
          padding: 10,
          height: 100,
          width: 100,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div>
          <img
            height={50}
            width={50}
            src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/loading+icon2.gif"
          />
          <div style={{ fontWeight: "bold", fontSize: 14 }}>Loading</div>
        </div>
      </div>
      {/* <CircularProgress color="primary" /> */}
      {/* <img
        src={
          "https://sentro-store-bucker.s3.us-east-2.amazonaws.com/loading+icon2.gif"
        }
        width={50}
        height="auto"
      /> */}
    </Dialog>
  );
}

export default StoreLoading;
