import React, { Fragment, useEffect } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


class TableExport extends React.Component {
	
	render (){
		return (
			<div>
				<ReactHTMLTableToExcel
					id="test-table-xls-button"
					table="table"
					buttonText="Download"

					className={this.props.class}
					filename={this.props.filename}
					sheet={this.props.sheet}
				/>
				<div style={{ display: "none" }}>
					{this.props.table}
				</div>
			</div>
			
		);
	}
}

export default TableExport