import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import numeral from "numeral";
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from "@material-ui/core/Grid";

const useStyles = theme => ({
  paper: {
    overflow: "hidden",
    padding: theme.spacing(2),
    border: "1px solid #D1D5DD",
    boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    }
  },
  miniCardHeader: {
      fontSize: "16px",
      textAlign: "left",
      color: "#F97663",
      paddingBottom: "0.5em",
      [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
      }
  },
  miniCardBody: {
      fontSize: 32,
      fontWeight: "600",
      textAlign: "left",
      color: theme.palette.text.primary
  },
  miniCardFooter: {
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "right",
    color: "#F97663",
    minHeight: 17
  },
  miniCardFooterText: {
    fontSize: "12px",
    weight: "bold",
    textAlign: "left",
    color: theme.palette.text.secondary,
    minHeight: 17
  }

});

class Card extends React.Component {
	
	constructor(props) {
		super(props);
	}

  render() {
    const { history, title, data, link, footerText, hideLinkText, numeralFormat, classes, adminDashboard } = this.props;
    const content = (
      <div className={classes.paper}>
        <div className={classes.miniCardHeader}>{title}</div>
        {
          adminDashboard.loading ? 
          <Skeleton variant="text" height={45} />
          :
          adminDashboard.error ? 
          <div className={classes.miniCardBody}>-</div>
          :
          <div className={classes.miniCardBody}>{numeral(parseFloat(data)).format(numeralFormat)}</div>
        }
        {footerText ? <div className={classes.miniCardFooterText}>{footerText}</div> : ``}
        {
          !hideLinkText &&
          <div className={classes.miniCardFooter}>{link && 'VIEW LIST >' }</div>
        }

        
      </div>
    )
    return (
      <Grid item xs={6} sm={4} md={3} lg={3}>
        {
          link ? 
          <a onClick={() => history.push(link)}>
            {content}
          </a>
          :
          content
        }
      </Grid>
    )
  }
}

const mapStateToProps = state => ({ adminDashboard }) => ({ 
  adminDashboard
});
export default connect(mapStateToProps)(withStyles(useStyles)(Card));