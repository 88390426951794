import React from "react";
import ContentStyles from "./contentStyles";
import { Box, Container, Paper, Grid } from "@material-ui/core";
import Text from "../../../common/text";
import * as FAQ from "./dataSource";
import SentroButton from "../../common/sentroButton";
import Path from "../../../Paths";
import history from "../../../store/history";
import * as Scroll from "react-scroll";
import Footer from "../../homepage/components/footer";
const Content = props => {
  let Link = Scroll.Link;
  let Element = Scroll.Element;
  const checked = props.language;
  const classes = ContentStyles();
  const [clicked, setClicked] = React.useState("link1");

  const sideNav = [
    {
      label: "About Sentro",
      labelTagalog: "Tungkol sa Sentro",
      link: "link1"
    },
    {
      label: "Joining Sentro",
      labelTagalog: "Pagsali sa Sentro",
      link: "link2"
    },
    {
      label: "Who can use Sentro?",
      labelTagalog: "Sinong pwedeng gumamin ng Sentro",
      link: "link3"
    },
    {
      label: "Inside Sentro",
      labelTagalog: "Loob ng Sentro",
      link: "link4"
    },
    {
      label: "About Sentro Online Shop",
      labelTagalog: "Tungkol sa Sentro Online Shop",
      link: "link5"
    },
    {
      label: "Purchase and Payments in Sentro Online Shop",
      labelTagalog: "Pagbili at Pagbayad sa Sentro Online Shop",
      link: "link6"
    },
    {
      label: "Shipping and Delivery",
      labelTagalog: "Shipping at Delivery",
      link: "link7"
    },
    {
      label: "Apps and Services Marketplace",
      labelTagalog: "Apps at Services Marketplace",
      link: "link8"
    },
    {
      label: "Miscellaneous",
      labelTagalog: "Miscellaneous",
      link: "link9"
    },
    {
      label: "Facebook Messenger Integration",
      labelTagalog: "Facebook Messenger Integration",
      link: "link10"
    },
    {
      label: "Announcement Page",
      labelTagalog: "Announcement Page",
      link: "link11"
    }
  ];

  const handleSetActive = active => {
    setClicked(active.toString());
  };
  return (
    <div>
      <Box className={classes.root}>
        <Container>
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{ display: "flex", justifyContent: "flex-end" }}
          ></Grid>

          <Box className={classes.faq}>
            <Box className={classes.sideMenu}>
              <Text
                textType="h3"
                content={
                  checked === "Filipino"
                    ? "Anong maitutulong namin?"
                    : "What do you need help on?"
                }
                style={{ color: "#002C42" }}
              />
              <Box>
                {sideNav.map((item, label) => {
                  return (
                    <Link
                      onSetActive={handleSetActive}
                      id="side_navigation"
                      onClick={() => setClicked(item.label)}
                      activeClass="active"
                      to={item.link}
                      offset={-65}
                      spy={true}
                      smooth={true}
                      duration={500}
                      style={{
                        display: "block",
                        fontSize: 16,
                        color: clicked == item.link ? "#e57373" : "#54575F",
                        marginTop: 30,
                        fontWeight: clicked == item.link ? 600 : 300
                      }}
                    >
                      {checked === "English" ? item.label : item.labelTagalog}
                    </Link>
                  );
                })}
              </Box>
            </Box>
            <Box className={classes.box}>
              <Element name="link1">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Tungkol sa Sentro"
                        : "About Sentro"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq1.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>

              <Element name="link2">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Pagsali Sa Sentro"
                        : "Joining Sentro"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq2.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>

              <Element name="link3">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Sinong pwedeng gumamit ng Sentro"
                        : "Who can use Sentro?"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq3.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>

              <Element name="link4">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Loob ng Sentro"
                        : "Inside Sentro"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq4.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>

              <Element name="link5">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Tungkol sa Sentro Online Shop"
                        : "About Sentro Online Shop"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq5.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>

              <Element name="link6">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Pagbili at Pagbayad sa Sentro Online Shop"
                        : "Purchase and Payments in Sentro Online Shop"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq6.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>

              <Element name="link7">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Shipping at Delivery"
                        : "Shipping and Delivery"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq7.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>
              <Element name="link8">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Apps at Services Marketplace"
                        : "Apps and Services Marketplace"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq8.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>
              <Element name="link9">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino" ? "Miscellaneous" : "Miscellaneous"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq9.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>
              <Element name="link10">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Facebook Messenger Integration"
                        : "Facebook Messenger Integration"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq10.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>
              <Element name="link11">
                <Paper className={classes.content}>
                  <Text
                    textType="h3"
                    content={
                      checked === "Filipino"
                        ? "Announcement Page"
                        : "Announcement Page"
                    }
                    style={{ color: "#002C42" }}
                  />
                  {FAQ.sentro_faq11.map((item, key) => {
                    return (
                      <Box className={classes.faqContent}>
                        <Box style={{ marginTop: "5vh" }}>
                          <Text
                            textType="p1_Semibold"
                            content={
                              checked === "Filipino"
                                ? item.questionTagalog
                                : item.questionEnglish
                            }
                            style={{ color: "#54575F" }}
                          />

                          <Text
                            style={{ color: "#909196", marginTop: "3vh" }}
                            textType="p1"
                            content={
                              checked === "Filipino"
                                ? item.answerTagalog
                                : item.answerEnglish
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Paper>
              </Element>
            </Box>
          </Box>
        </Container>
        <Box
          style={{
            backgroundImage: `url('https://sentro-store-bucker.s3.us-east-2.amazonaws.com/sentro_swirls.png')`,
            position: "relative",
            backgroundSize: "cover"
            // backgroundRepeat: "no-repeat",
            // top: 50
          }}
        >
          <Box className={classes.content3}>
            <Container>
              <Box className={classes.centeredOnSuccess}>
                <Text
                  style={{ color: "#002C42" }}
                  textType="contentH1"
                  content="Centered on your success"
                />

                <Text
                  style={{ color: "#2B2D33" }}
                  textType="contentSh3"
                  content="Launch your online shop now"
                />
                <SentroButton
                  id="getStarted_button"
                  className={classes.btnSentro}
                  style={{ marginTop: "5vh" }}
                  variant="contained"
                  label="Get Started"
                  onClick={() => {
                    props.user
                      ? history.push(Path.DASHBOARD)
                      : history.push(Path.SIGN_UP);
                  }}
                />
              </Box>
            </Container>
          </Box>
          <Footer />
        </Box>
      </Box>
    </div>
  );
};

export default Content;
