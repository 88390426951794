import React from "react";
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  ButtonGroup,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import PreviewHome from "../../../common/template/components/PreviewHome";
import DemoFrame from "../../../common/layout/mobile/webBuilder/common/materialFrame";
const DialogTransition = props => <Slide direction="up" {...props} />;

const PreviewShop = props => {
  const {
    open,
    setOpen,
    handleClosePreview,
    storeInfo,
    setValues,
    productList
  } = props;

  const previewParent = React.createRef();
  const preview = React.createRef();

  const [state, setState] = React.useState({
    previewType: "DESKTOP",
    previewScale: 1,
    previewHeight: 100,
    previewMarginLeft: 0,
    previewParentWidth: 1,
    previewWidth: 1000,
    previewParentHeight: 0
  });

  const useStyles = makeStyles(theme => ({
    appbar: {
      position: "relative",
      backgroundColor: "#fff"
    },
    dialog: {
      padding: "50px 50px 0"
    },
    header: {
      width: "100%",
      textAlign: "center",
      fontSize: 18,
      color: "#002c42",
      fontWeight: 600,
      position: "absolute",
      left: 0,
      right: 0
    },
    buttonView: {
      height: 35,
      width: 145,
      fontSize: 12
    },
    previewSection: {
      position: "relative",
      backgroundColor: "#909196",
      "& > .preview": {
        transition: "all 0.25s ease-in-out",
        width: "95%",
        minWidth: 300,
        maxWidth: state.previewType === "MOBILE" ? 400 : 1080,
        marginLeft: "50%",
        transform: "translateX(-50%)",
        height: "100%",
        overflow: "hidden"
      }
    }
  }));

  const classes = useStyles();

  function handleResize() {
    setTimeout(() => {
      if (previewParent.current && preview.current) {
        const scale = previewParent.current.clientWidth / 1000;
        setState(
          {
            ...state,
            previewParentWidth: previewParent.current.clientWidth,
            previewParentHeight: window.innerWidth,
            previewScale: scale,
            previewHeight: (1 / scale) * 50,
            previewMarginLeft: (1000 - 1000 * scale) / 2,
            previewMarginTop: (600 - 600 * scale) / 4
          },
          () => {}
        );
      }
    }, 0);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      fullScreen
      maxWidth="xl"
      open={open.preview}
      TransitionComponent={DialogTransition}
      onClose={handleClosePreview}
    >
      <AppBar className={classes.appbar}>
        <Toolbar
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <IconButton
            style={{ zIndex: 3 }}
            edge="end"
            onClick={() => setOpen({ ...open, preview: false })}
          >
            <CloseIcon color="primary" />
          </IconButton>
          <div className={classes.header}>Preview</div>
          <div>
            <ButtonGroup color="primary">
              <Button
                onClick={() => setState({ ...state, previewType: "DESKTOP" })}
                className={classes.buttonView}
                style={{
                  color: state.previewType === "DESKTOP" ? "#fff" : "#F97663",
                  backgroundColor:
                    state.previewType === "DESKTOP" ? "#f97663" : "#fff"
                }}
              >
                Desktop view
              </Button>
              <Button
                onClick={() => setState({ ...state, previewType: "MOBILE" })}
                className={classes.buttonView}
                style={{
                  color: state.previewType === "MOBILE" ? "#fff" : "#F97663",
                  backgroundColor:
                    state.previewType === "MOBILE" ? "#f97663" : "#fff"
                }}
              >
                Mobile view
              </Button>
            </ButtonGroup>
          </div>
        </Toolbar>
      </AppBar>

      <div
        className={classes.previewSection}
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div
          className="preview"
          ref={previewParent}
          style={{
            overflow: state.previewType === "MOBILE" ? "auto" : "hidden"
          }}
        >
          <div ref={preview}>
            <DemoFrame
              style={{
                width: state.previewType === "MOBILE" ? 400 : 1080,
                height: state.previewType === "MOBILE" ? 512 : 520,
                transform: `scale(0.9)`,
                marginLeft: `-${
                  state.previewType === "MOBILE" ? 0 : state.previewMarginLeft
                }px`,
                marginTop: `-${
                  state.previewType === "MOBILE" ? 0 : state.previewMarginTop
                }px`
              }}
            >
              <div style={{ overflowX: "hidden" }}>
                <PreviewHome
                  style={{ overflowY: "auto" }}
                  storeInfo={storeInfo}
                  setValues={setValues}
                  productList={productList}
                />
              </div>
            </DemoFrame>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PreviewShop;
