import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Box, Container, Typography, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SentroButton from "../../../../common/sentroButton";
import { Field, reduxForm } from "redux-form";
import SentroTextField from "../../../../common/sentroTextField";
import logo from "../../../../../assets/images/sentro_vertical.png";
import Text from "../../../../../common/text";
import validations from "../../../../common/validations";
import Actions from "../../../Redux/Actions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SentroLoading from "../../../../common/loading";
import Path from "../../../../../Paths";

import XCircleIcon from "@material-ui/icons/Cancel";

const useStyles = theme => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },

  banner: {
    width: "60%",
    height: "100vh",
    background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  verification: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  box1: {
    marginTop: "35vh",
    [theme.breakpoints.down("sm")]: {
      padding: "8vh 5vh 0 "
    }
  },
  box2: {
    padding: "6vh 6vh 0 ",
    [theme.breakpoints.down("xs")]: {
      padding: "6vh 5vw"
    }
  },
  error: {
    color: "red"
  }
});

class AdminAppMarketplacePartnerVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: "",
      password: "",
      showPassword: false,
      passwordValid: false,
      formError: "",
      pageError: "",
      linkValid: true,
      success: false
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePasswordChange(event) {
    const { valid } = this.props;
    this.setState({
      password: event.target.value,
      passwordValid: valid
    });
  }
  setShowPassword(show) {
    this.setState({
      showPassword: show
    });
  }

  componentDidMount = async () => {
    const { location, dispatch } = this.props;
    const { password } = this.state;
    const data = await dispatch(
      Actions.nominatePasswordPartner("get", location.search, password)
    );
    if (data.status == 200) {
      this.setState({
        linkValid: true
      });
    } else {
      this.setState({
        linkValid: data.response.status != 400,
        formError:
          data.response.status == 406 ? data.response.data.message : "",
        pageError: data.response.status == 400 ? data.response.data.message : ""
      });
    }
  };

  handleSubmit = async () => {
    const { location, dispatch, history } = this.props;
    const { password } = this.state;
    const data = await dispatch(
      Actions.nominatePasswordPartner("post", location.search, password)
    );
    if (data.status == 200) {
      this.setState({
        linkValid: true,
        success: true
      });
      setTimeout(() => {
        history.push(Path.ADMIN_APP_MARKETPLACE_PARTNERS_SALES_VIEW);
      }, 3000);
    } else {
      this.setState({
        linkValid: data.response.status != 400,
        formError:
          data.response.status == 406 ? data.response.data.message : "",
        pageError: data.response.status == 400 ? data.response.data.message : ""
      });
    }
  };

  render() {
    const { classes, adminDashboard } = this.props;
    const {
      password,
      showPassword,
      passwordValid,
      linkValid,
      success,
      formError,
      pageError
    } = this.state;
    return (
      <div className={classes.root}>
        <Box className={classes.banner}>
          <Container>
            <Box className={classes.box1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img src={logo} style={{ width: 150 }} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    marginTop: "2vh"
                  }}
                  textType="h2"
                  content="Create your online shop here."
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                    color: "#fff",
                    position: "absolute",
                    bottom: 25
                  }}
                >
                  This works best in the latest Google desktop browser.
                </Typography>
              </div>
            </Box>
          </Container>
        </Box>
        <Box className={classes.verification}>
          <Container>
            <Box className={classes.box2}>
              <Text textType="h3" content="Nominate a Password" />

              {linkValid ? (
                success ? (
                  <Fragment>
                    <Box style={{ marginTop: "5vh", display: "flex" }}>
                      <CheckCircleIcon
                        style={{ color: "#38b99f", fontSize: 90 }}
                      />
                      <Typography style={{ fontSize: 16, padding: 10 }}>
                        Validation successful!
                        <br />
                        <br />
                        Redirecting you now to the Sales & Orders Dashboard...
                      </Typography>
                    </Box>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography
                      style={{
                        fontSize: 16,
                        padding: "10px 0",
                        marginTop: "2em"
                      }}
                    >
                      Please proceed to nominate your password. Ideal password
                      should at least have 8 characters with a mix of numbers,
                      letters and special characters.
                    </Typography>
                    <Box>
                      <div className={classes.error}>{formError}</div>
                      <Text
                        style={{ fontWeight: 600, color: "#2B2D33" }}
                        textType="cap"
                        content="Password"
                      />
                      <Field
                        id="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SentroButton
                                style={{ color: "#2B2D33", fontSize: 10 }}
                                label={showPassword ? "HIDE" : "SHOW"}
                                onClick={e =>
                                  this.setShowPassword(!showPassword)
                                }
                                // onMouseDown={e => this.setShowPassword(showPassword)}
                              />
                            </InputAdornment>
                          )
                        }}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        validate={[validations.required, validations.password]}
                        component={SentroTextField}
                        onChange={event => this.handlePasswordChange(event)}
                      />
                    </Box>
                    <Box style={{ marginTop: "3vh" }}>
                      <SentroButton
                        id="signup_button"
                        disabled={!passwordValid}
                        fullWidth
                        variant="contained"
                        label="Sign up"
                        onClick={() => this.handleSubmit()}
                        style={{ height: 48, fontSize: 16, fontWeight: 600 }}
                        // innerRef={signUpButtonRef}
                      />
                    </Box>
                  </Fragment>
                )
              ) : (
                <Fragment>
                  <Box style={{ marginTop: "3vh", display: "flex" }}>
                    <div className={classes.errorIcon}>
                      <XCircleIcon style={{ color: "red", fontSize: 90 }} />
                    </div>
                    <Typography style={{ fontSize: 16, padding: 10 }}>
                      {`${pageError}  Please contact the admin to request a new one.`}
                    </Typography>
                  </Box>
                </Fragment>
              )}
            </Box>
          </Container>
        </Box>
        <SentroLoading open={adminDashboard.loading} />
      </div>
    );
  }
}

export default connect(({ adminDashboard }) => ({ adminDashboard }))(
  reduxForm({
    form: "AdminAppMarketplacePartnerVerification",
    destroyOnUnmount: true
  })(withStyles(useStyles)(AdminAppMarketplacePartnerVerification))
);
