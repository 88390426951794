import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/styles";
import {
  useMediaQuery,
  ThemeProvider,
  createMuiTheme,
  TextField,
  Button,
  Link
} from "@material-ui/core";
import StoreLoading from "../Loading";
import Header from "../AppBar";
import StoreActions from "../StoreActions";
import StoreAlertBox from "../StoreAlertBox";
import validations from "../../../validations";
import StoreServices from "../StoreServices";
export default function ForgotPassword(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "#fafafa",
      padding: "8em 32vw",
      height: "100vh",
      [theme.breakpoints.up("xl")]: {
        padding: "2em 38vw"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "8em 6vw"
      }
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      color: "rgba(43,45,50, 0.8)"
    },
    input: {
      backgroundColor: "#fff"
    }
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/forgot_password", "");
  const storeName = param.replace("/shop/", "");
  const storeInfo = useSelector(state => state.store.store);
  const loading = useSelector(state => state.store.loading);
  const store_theme = useSelector(state => state.store.store.color);
  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold"
      },
      fontFamily: ["Inter", "sans-serif"]
    }
  });
  const [email, setEmail] = React.useState("");
  const [showResend, setShowResend] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState({
    open: false,
    alertType: "error",
    alertMessage: ""
  });

  const [error, setError] = React.useState({
    email: ""
  });

  async function handleSubmit() {
    let checkUniqueEmail = await StoreServices.isExist(email);
    if (checkUniqueEmail.data === "") {
      setShowAlert({
        open: true,
        alertType: "error",
        alertMessage: "Email is not registered with Sentro. Please sign up"
      });
    } else {
      let res = await dispatch(StoreActions.forgotPassword(email));
      try {
        if (res.status === 200) {
          setShowResend(true);
          setShowAlert({
            open: false,
            alertType: "success",
            alertMessage: ""
          });
        }
      } catch (e) {
        setShowAlert({
          open: true,
          alertType: "error",
          alertMessage: "An error occured. Please try again."
        });
      }
    }
  }

  async function handleResend() {
    let res = await dispatch(StoreActions.forgotPassword(email));
    try {
      if (res.status === 200) {
        setShowAlert({
          open: true,
          alertType: "success",
          alertMessage: "Email sent. Please check your inbox."
        });
      }
    } catch (e) {
      setShowAlert({
        open: true,
        alertType: "error",
        alertMessage: "An error occured. Please try again."
      });
    }
  }

  React.useEffect(() => {
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(storeName));
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (storeInfo.is_published === false) {
      history.push("/not-found");
    }
  });

  const resendComponent = () => {
    return (
      <div className={classes.content}>
        <div style={{ fontSize: 28, fontWeight: 600, color: "#2b2d32" }}>
          Reset Password
        </div>
        <div className="my-2">
          <StoreAlertBox alert={showAlert} />
        </div>
        <div className="my-3">
          <div style={{ fontSize: 16, color: "#2b2d32" }}>
            We’re almost there! Kindly check your e-mail to get the reset
            password link. If you did not get the e-mail within a few minutes,
            check your spam folder.
          </div>
          <div className="mt-5">
            <div style={{ fontSize: 14 }}>
              Did not receive the email?{" "}
              <Link onClick={handleResend} color="primary">
                Resend
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  console.log(showAlert);
  return (
    <div>
      {storeInfo && storeInfo.font.css_url ? (
        <link href={storeInfo.font.css_url} rel="stylesheet"></link>
      ) : (
        ""
      )}
      <ThemeProvider theme={store_generated_theme}>
        <StoreLoading open={loading} />
        <Header isPreview={false} storeInfo={storeInfo} />
        <div className={classes.root}>
          {showResend ? (
            resendComponent()
          ) : (
            <div>
              <div style={{ fontSize: 28, fontWeight: 600, color: "#2b2d32" }}>
                Forgot Password
              </div>
              <div className="my-2">
                <StoreAlertBox alert={showAlert} />
              </div>
              <div className="my-3">
                <div style={{ fontSize: 16, color: "#2b2d32" }}>
                  Enter your email address and we will send you instructions to
                  reset your password.
                </div>
                <div className="mt-3">
                  <div>
                    <div className={classes.label}>Email Address</div>
                    <TextField
                      id="email"
                      margin="dense"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      className="m-0"
                      value={email}
                      error={error.email}
                      helperText={error.email}
                      onChange={e => {
                        let input = e.target.value;
                        setEmail(input);
                        if (input === "") {
                          setError({
                            ...error,
                            email: "This field is required."
                          });
                        } else if (
                          validations.email(input) ||
                          validations.verifyEmail(input)
                        ) {
                          setError({
                            ...error,
                            email: "Please enter a valid email address"
                          });
                        } else if (validations.noCaps(input)) {
                          setError({
                            ...error,
                            email: "Uppercase is not allowed"
                          });
                        } else {
                          setError({ ...error, email: "" });
                        }
                      }}
                      onBlur={e => {
                        let input = e.target.value;
                        if (input === "") {
                          setError({
                            ...error,
                            email: "This field is required."
                          });
                        } else if (
                          validations.email(input) ||
                          validations.verifyEmail(input)
                        ) {
                          setError({
                            ...error,
                            email: "Please enter a valid email address"
                          });
                        } else if (validations.noCaps(input)) {
                          setError({
                            ...error,
                            email: "Uppercase is not allowed"
                          });
                        } else {
                          setError({ ...error, emailError: "" });
                        }
                      }}
                      InputProps={{ className: classes.input }}
                    />
                  </div>
                  <Button
                    variant="contained"
                    className="mt-5"
                    fullWidth
                    disableElevation
                    style={{ backgroundColor: "#54575f", color: "#fff" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <div className="mt-3">
                    <div style={{ fontSize: 12, textAlign: "center" }}>
                      Don't have an account?{" "}
                      <Link
                        style={{ color: "#54575f" }}
                        onClick={() => {
                          history.push(
                            `/shop/${storeInfo && storeInfo.site}/signup`
                          );
                          window.scrollTo(0, 0);
                        }}
                      >
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
}
