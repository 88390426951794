import React from "react";
import { Button,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {resendCode} from '../../register/registerActions';
require("dotenv").config();

const ActivateAccountBanner = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  let loading = useSelector(state => state.signUp.loading);
  let resendSuccess = useSelector(state => state.signUp.resendSuccess);

  const useStyles = makeStyles(theme => ({
    modalBtn: {
      height: 50,
      flexGrow: 0,
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1
      }
    },
    banner: {
      backgroundColor: "#1E4B83",
      borderRadius: 4,
      [theme.breakpoints.down("xs")]: {
        borderRadius: 0
      }
    },
    btnWrap: {
      borderRadius: 4,
      flexGrow: 0,
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1
      }
    },
    bannerBtn: {
      backgroundColor: "white",
      color: "#1E4B83",
      height: 32,
      padding: "0px 25px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        height: 40,
        textAlign: "center",
        marginTop: 5,
        width: "100%"
      }
    },
  }));
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.banner} d-flex py-2 px-4 align-items-center mb-2 mb-sm-3 flex-wrap`}>
        <p className="mb-0 text-white">Unlock more features by activating your account now.</p>
        <div className={`${classes.btnWrap} ml-0 ml-sm-auto bg-white my-2 my-sm-0`}>
          <Button
            className={classes.bannerBtn}
            // variant="outlined"
            onClick={() => setModalOpen(true)}
          >
            Activate my Account
          </Button>
        </div>
      </div>
      <Dialog maxWidth="sm" open={modalOpen} >
        <DialogTitle
          style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
        >
        </DialogTitle>
        <DialogContent style={{ padding: 20, maxWidth: 460}}>
          <div className="p-3">
            <div>
              <h2 style={{fontSize: 24, fontWeight: 600}} 
              className="mb-2">Activate your Sentro account to enjoy more features</h2>
              <p className="text-secondary mb-2">Please check your email to activate your account. 
              Click “Resend” button if you didn’t receive the email.</p>
            </div>
            <div className={`d-flex align-items-center mt-3 mb-0 ${window.innerWidth < 600 ? 'flex-wrap': ''}`}>
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                className={classes.modalBtn}
                onClick={() => dispatch(resendCode(true))}
                disabled={loading || resendSuccess}
              > 
                {
                  loading &&
                  <div className="mr-2">
                    <div className="spinner-border text-light" role="status" 
                    style={{ color: "#f97663 !important"}}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                }
                {resendSuccess ? "Sent": "Resend"}
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                className={`${classes.modalBtn} ml-0 ml-sm-3 mt-3 mt-sm-0`}
                onClick={() => setModalOpen(false)}
              >
                Done
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ActivateAccountBanner;
