import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import {
  makeStyles,
  Stepper,
  Step,
  Typography,
  StepLabel,
  Grid,
  Box,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Dialog
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
// import useForm from "react-hook-form";
import StoreInfo from "./forms/StoreInfo";
import StoreContact from "./forms/StoreContact";
import StoreBranding from "./forms/StoreBranding";
import ProductForm from "./forms/StoreProduct";
import PaymentForm from "./forms/PaymentForm";
import { createStore, goBackward, getProducts } from "./websiteBuilderActions";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SentroButton from "../common/sentroButton";
import sentroTheme from "../common/sentroTheme";
import Layout from "../common/template/LandingPage";
import history from "../../store/history";
import Path from "../../Paths";
import SentroLoading from "../common/loading";
import SentroAlert from "./builderAlert";
import PreviewHome from "../common/template/components/PreviewHome";
import PreviewProducts from "../common/template/components/PreviewProducts";
import PreviewProductDetails from "../common/template/components/PreviewProductDetails";
import DashboardActions from "../dashboard/dashboardActions";
import DocumentMeta from "react-document-meta";
import PreviewSite from "./PreviewSite";
import apiConfig from "../../common/apiConfig";
import { Icon } from "@iconify/react";
import facebookMessenger from "@iconify/icons-mdi/facebook-messenger";

let WebsiteBuilder = props => {
  const UseStyles = makeStyles(theme => ({
    builder: {
      overflow: "hidden",

      [theme.breakpoints.down("xs")]: {
        // display: "none"
      }
      // display: "none",
      // [theme.breakpoints.up("sm")]: {
      //   display: "",
      // },
    },
    expandButton: {
      display: "flex",
      marginTop: "13vh",
      height: "100vh",
      [theme.breakpoints.up("xl")]: {
        marginTop: "8vh"
      }
    },
    previewTab: {
      marginTop: "8vh",
      width: "100%",
      overflowX: "hidden",
      [theme.breakpoints.up("xl")]: {
        marginTop: "5vh"
      }
    },
    fbMessengerIcon: {
      marginRight: "1vw",
      fontSize: 40,
      background: "#0078FF",
      color: "white",
      position: "absolute",
      bottom: 12,
      right: 15,
      zIndex: 10,
      borderRadius: "50%",
      padding: 3
    },
    btnNext: {
      width: 100,
      margin: 5,
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      "&:hover": {
        backgroundColor: "#DD5e56"
      }
    },
    btnBack: {
      width: 100,
      margin: 5,
      "&:hover": {
        border: "1px solid #F97663"
      }
    },
    btnPreview: {
      fontSize: 24,
      width: 150,
      margin: 5,
      "&:hover": {
        border: "1px solid #f97663"
      }
    }
  }));

  const classes = UseStyles();
  const meta = {
    title: "Sentro | Online shop builder",
    description:
      "Simply provide your business details, upload your products, choose your payment method and publish your store! (Yep, it's that easy)",
    canonical: `${apiConfig.localHost}/store_builder`,
    meta: {
      charset: "utf-8"
      // name: {
      //   keywords: "react,meta,document,html,tags",
      // },
    }
  };
  const dispatch = useDispatch();
  let owner = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let inSession = sessionStorage.getItem("IN_SESSION");
  if (inSession != "CURRENT_USER") {
    history.push(Path.HOME);
  }
  let loading = useSelector(state => state.webBuilder.loading);
  let storeProductList = useSelector(state => state.webBuilder.products);
  let activeStep = useSelector(state => state.webBuilder.step);
  let isEdit = useSelector(state => state.webBuilder.isEdit);
  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  let products = useSelector(state => state.webBuilder.products);
  let currentStoreCreated = useSelector(state => state.webBuilder.storeInfo);

  localStorage.setItem(
    "STORE_UUID",
    currentStoreCreated ? currentStoreCreated.uuid : ""
  );
  let STORE_UUID = localStorage.getItem("STORE_UUID");
  const [values, setValues] = React.useState({
    owner: owner.id,
    name: "",
    template: "Classic",
    description: "",
    slogan: "",
    site: "",
    address: "",
    email: "",
    mobile: "",
    landline: "",
    facebook: "",
    instagram: "",
    shopee: "",
    lazada: "",
    kumu: "",
    color: "",
    logo_image: "",
    cover_image: "",
    aboutus_image: "",
    contactus_image: "",
    payment_deadline: 24,
    is_published: false,
    uuid: STORE_UUID,
    image_settings: {},
    fb_page_id: ""
  });

  function setImageSettings(name, value) {
    setValues(prevState => ({
      ...prevState,
      image_settings: {
        ...prevState.image_settings,
        [name]: value
      }
    }));
  }

  React.useState(async () => {
    if (isEdit) {
      Object.keys(currentStoreCreated).map(item => {
        setValues(prevState => ({
          ...prevState,
          [item]:
            String(currentStoreCreated[item]) === "null"
              ? ""
              : currentStoreCreated[item]
        }));
      });
      // setValues(prevState => ({
      //   ...prevState,
      //   ...currentStoreCreated,
      // }));
      // if(!products){
      dispatch(getProducts(currentStoreCreated.uuid));
      // }
    } else {
      const res = await dispatch(DashboardActions.getStores());
      if (res.length) history.push(Path.DASHBOARD);
    }
  }, []);

  React.useEffect(() => {
    if (currentStoreCreated) {
      Object.keys(currentStoreCreated).map(item => {
        setValues(prevState => ({
          ...prevState,
          [item]:
            String(currentStoreCreated[item]) === "null"
              ? ""
              : currentStoreCreated[item]
        }));
      });
    }
  }, [currentStoreCreated]);
  //   React.useEffect(() => {
  //     if (isEdit) {
  //
  //     }
  //   }, storeImageSettings);

  const [show, setShow] = React.useState(true);
  const steps = getSteps();

  function getSteps() {
    return [
      "Basic Info",
      "Contact Details",
      "Brand & design",
      "Product details",
      "Payment settings"
    ];
  }
  function handleNext() {
    dispatch(createStore(values, activeStep, isEdit, currentStoreCreated));
  }
  function handleBack() {
    dispatch(goBackward(activeStep));
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <StoreInfo
            isEdit={isEdit}
            storeInfo={values}
            setValues={setValues}
            setFormHasErrors={setFormHasErrors}
            setIsFormEnough={setIsFormEnough}
            language={props.language}
          />
        );
      case 1:
        return (
          <StoreContact
            storeInfo={values}
            setValues={setValues}
            setFormHasErrors={setFormHasErrors}
            setIsFormEnough={setIsFormEnough}
            language={props.language}
          />
        );
      case 2:
        return (
          <StoreBranding
            storeInfo={values}
            setValues={setValues}
            setIsFormEnough={setIsFormEnough}
            language={props.language}
          />
        );
      case 3:
        return (
          <ProductForm
            show={show}
            storeInfo={values}
            setValues={setValues}
            setIsFormEnough={setIsFormEnough}
            language={props.language}
          />
        );
      case 4:
        return (
          <PaymentForm
            storeInfo={values}
            setValues={setValues}
            language={props.language}
          />
        );
      //return <div>step 5</div>;
      default:
        return "";
    }
  }
  function handleSwitch() {
    setShow(!show);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClosePreview = () => {
    setOpen(false);
  };

  const stepperScrollRef = React.createRef();
  React.useEffect(() => {
    // Scroll to top of Steppers' container when activeStep is modified
    if ("scrollIntoView" in stepperScrollRef.current)
      stepperScrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
  }, [activeStep]);

  return (
    <DocumentMeta {...meta}>
      <div className={classes.builder}>
        <SentroLoading open={loading} />

        <div style={{ display: "flex", flex: 12 }}>
          <div style={{ flex: 6 }}></div>
          <Grid container spacing={0}>
            <Grid
              item
              xs={
                show
                  ? show && activeStep == 3
                    ? 11
                    : 6
                  : !show && activeStep == 3
                  ? 6
                  : 11
              }
            >
              <Paper
                style={{
                  padding: show
                    ? show && activeStep == 3
                      ? 65
                      : 65
                    : !show && activeStep == 3
                    ? 65
                    : "65px 25vw",
                  height: "97vh",
                  marginTop: 24,
                  overflowY: "scroll"
                }}
              >
                <Typography
                  variant="h5"
                  style={{ fontWeight: "600", marginBottom: 16 }}
                >
                  Online Shop Builder
                </Typography>

                <Box style={{ marginRight: 10 }}>
                  {getStepContent()}
                  <ThemeProvider theme={sentroTheme}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map(label => (
                        <Step key={label} innerRef={stepperScrollRef}>
                          <StepLabel
                            style={{
                              wordSpacing: label === "Brand & design" ? 0 : 20
                            }}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </ThemeProvider>
                  <SentroAlert />
                  <div>{getStepContent(activeStep)}</div>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 40
                    }}
                  >
                    <SentroButton
                      id="back_button"
                      buttonType="button2"
                      onClick={
                        activeStep != 0
                          ? handleBack
                          : () => history.push(Path.DASHBOARD)
                      }
                      className={classes.btnBack}
                      style={{ marginTop: 0 }}
                      label="Back"
                    />
                    <SentroButton
                      id="next_button"
                      loading={loading}
                      variant="contained"
                      onClick={handleNext}
                      label={"Next"}
                      className={classes.btnNext}
                      style={{ marginTop: 0 }}
                      disabled={formHasErrors || !isFormEnough}
                    />
                  </Box>
                  {/* </Formik> */}
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              xs={
                show
                  ? show && activeStep == 3
                    ? 1
                    : 6
                  : !show && activeStep == 3
                  ? 6
                  : 1
              }
            >
              <div style={{ display: "flex", height: "100vh" }}>
                <Box>
                  <div
                    onClick={handleSwitch}
                    style={{
                      backgroundColor: "#002C42",
                      width: 40,
                      color: "white",
                      fontSize: 10,
                      cursor: "pointer",
                      writingMode: "vertical-rl",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <div className={classes.expandButton}>
                      {show ? (
                        show && activeStep == 3 ? (
                          <NavigateBeforeIcon />
                        ) : (
                          <NavigateNextIcon />
                        )
                      ) : !show && activeStep == 3 ? (
                        <NavigateNextIcon />
                      ) : (
                        <NavigateBeforeIcon />
                      )}

                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          transform: "rotate(180deg)"
                        }}
                      >
                        ONLINE SHOP PREVIEW
                      </div>
                    </div>
                  </div>
                </Box>
                <Box className={classes.previewTab}>
                  {values.fb_page_id && (
                    <Icon
                      icon={facebookMessenger}
                      className={classes.fbMessengerIcon}
                    />
                  )}
                  <div>
                    <div
                      style={{
                        height: "100vh",
                        width: "100vw",
                        position: "relative",
                        transform: `scale(0.46)`,
                        transformOrigin: "top left",

                        backgroundColor: "white"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "50px 50px 0",
                          border: "1px solid #D1D5DD"
                        }}
                      >
                        <SentroButton
                          className={classes.btnPreview}
                          style={{ padding: 0, marginTop: 0 }}
                          label="Preview"
                          onClick={() => handleClickOpen()}
                          // onClick={() =>
                          //   window.open(
                          //     `${apiConfig.localHost}preview/${values.site}`
                          //   )
                          // }
                        />
                      </div>

                      <PreviewSite
                        open={open}
                        handleClosePreview={() => handleClosePreview()}
                        storeInfo={values}
                        productList={storeProductList}
                      />
                      <div
                        style={{
                          color: "#54575F",
                          fontSize: 32,
                          margin: 20,
                          fontWeight: 600,
                          textAlign: "center"
                        }}
                      >
                        Home Page
                      </div>
                      <PreviewHome
                        storeInfo={values}
                        productList={storeProductList}
                        setValues={setValues}
                        setImageSettings={setImageSettings}
                      />
                      <div
                        style={{
                          color: "#54575F",
                          fontSize: 32,
                          fontWeight: 600,
                          textAlign: "center",
                          margin: 30
                        }}
                      >
                        Product Page
                      </div>
                      <PreviewProducts
                        storeInfo={values}
                        productList={storeProductList}
                        setImageSettings={setImageSettings}
                      />
                      <div
                        style={{
                          color: "#54575F",
                          fontSize: 32,
                          fontWeight: 600,
                          textAlign: "center",
                          margin: 30
                        }}
                      >
                        Product Detail Page
                      </div>
                      <PreviewProductDetails
                        storeInfo={values}
                        productList={storeProductList}
                        setImageSettings={setImageSettings}
                      />
                    </div>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </DocumentMeta>
  );
};
export default WebsiteBuilder;
