import React, { useEffect } from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import defaultSRC from "../defaultResources";
import history from "../../../../../store/history";
import Blank from "../blank";
import defaultColor from "../colors";
import imgPlaceholder from "../../../../../assets/images/banner_placeholder.png";
import PinchZoomPan from "../../../../lib/image-zoom-pan/PinchZoomPan";
import classicBanner from "../../../../../assets/images/template assets/classicBanner.svg";
import contemporaryBanner from "../../../../../assets/images/template assets/contemporaryBanner.svg";
import minimalistBanner from "../../../../../assets/images/template assets/minimalistBanner.svg";
import setDefaultImage from "../../../layout/mobile/webBuilder/common/setDefaultImage";
import { useTheme } from "@material-ui/styles";

export function createPreview(file) {
  var blob = new Blob([file], { type: file.type });
  var blobUrl = URL.createObjectURL(blob);

  var xhr = new XMLHttpRequest();
  xhr.responseType = "blob";

  xhr.onload = function () {
    var recoveredBlob = xhr.response;

    var reader = new FileReader();

    reader.onload = function () {
      var blobAsDataUrl = reader.result;
      window.location = blobAsDataUrl;
    };

    reader.readAsDataURL(recoveredBlob);
  };

  return blobUrl;
}

const PreviewBannerTemplate = (props) => {
  let isPreview = props.isPreview;
  let storeInfo = props.storeInfo;
  let color = props.storeInfo && props.storeInfo.color;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function setImageSettings(name, value) {
    if (isPreview && props.setImageSettings) {
      props.setImageSettings(name, value);
    }
  }



  var pinchPropsBanner = {
    position: "center",
    initialScale: "auto",
    allowEdit: isPreview ? true : false,
    zoomButtons: isPreview ? true : false,
    maxScale: 15,
    onImageStateChange: (image_params) =>
      setImageSettings("banner", image_params),
  };

  if (props.storeInfo.image_settings) {
    let { banner } = props.storeInfo.image_settings;
    if (banner) {
      let { top, left, scale } = banner;
      delete pinchPropsBanner.position;
      pinchPropsBanner.initialTop = top;
      pinchPropsBanner.initialLeft = left;
      pinchPropsBanner.initialScale = scale || "auto";
    }
  }

  const getStyles = (template, theme) => {
    switch (template) {
      default:
        return <></>;
      case "Classic":
        return {
          banner: {
            color: "#fff",
            position: "relative",
            height: "100vh",
            backgroundImage: props.store.cover_image
              ? `url(${
                  typeof props.store.cover_image === "object"
                    ? createPreview(props.store.cover_image)
                    : props.store.cover_image
                })`
              : `url(${setDefaultImage(props.store.template, "cover_image")})`,

            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "0 15em",
            [theme.breakpoints.down("sm")]: {
              padding: "0 2em",
            },
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            fontSize: 48,
            color: color && color.primary.contrastText,
            [theme.breakpoints.down("xs")]: {
              fontSize: 24,
            },
          },
          slogan: {
            fontSize: 32,
            color: color && color.primary.contrastText,
            width: "100%",
            wordWrap: "break-word",

            [theme.breakpoints.down("xs")]: {
              fontSize: 16,
            },
          },
        };
      case "Contemporary":
        return {
          banner: {
            color: "#FAFAFA",
            position: "relative",
            height: "100vh",
            backgroundImage: props.store.cover_image
              ? `url(${
                  typeof props.store.cover_image === "object"
                    ? createPreview(props.store.cover_image)
                    : props.store.cover_image
                })`
              : `url(${setDefaultImage(props.store.template, "cover_image")})`,

            backgroundSize: "cover",
            backgroundPosition: "center",
            flexGrow: 1,
            [theme.breakpoints.down("sm")]: {},
          },

          bannerContent: {
            display: "flex",
            position: "absolute",
            bottom: "13vh",
            padding: "0 4vw",

            [theme.breakpoints.down("sm")]: {
              display: "block",
            },
          },
          content: {
            width: "50%",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
          buttonContainer: {
            margin: "auto 0 auto auto",
          },
          button: {
            border: "none",
            color: "white",
            fontSize: 16,
            fontWeight: 600,
            padding: "7px 52px",
            height: "55px",
            backgroundColor: "#F97663",
            marginTop: "3vh",
          },

          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            color: color && color.primary.contrastText,
            fontSize: 48,
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("xs")]: {
              fontSize: 24,
            },
          },
          slogan: {
            fontSize: 32,
            color: color && color.primary.contrastText,
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("xs")]: {
              fontSize: 16,
            },
          },
        };
      case "Minimalist":
        return {
          banner: {
            color: "#FAFAFA",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100vh",
            position: "relative",
            backgroundColor: "rgba(0,0,0,0.03)",
            backgroundImage: props.store.cover_image
              ? `url(${
                  typeof props.store.cover_image === "object"
                    ? createPreview(props.store.cover_image)
                    : props.store.cover_image
                })`
              : `url(${setDefaultImage(props.store.template, "cover_image")})`,
          },
          bannerContent: {
            padding: "0 14em",
            [theme.breakpoints.down("sm")]: {
              padding: 20,
            },
          },
          button: {
            border: "none",
            color: "white",
            fontSize: 16,
            fontWeight: 600,
            padding: "7px 52px",
            height: "55px",
            backgroundColor:
              defaultSRC[
                props.storeInfo ? props.storeInfo.template : "Classic"
              ],
            marginTop: "3vh",
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            color: color && color.primary.contrastText,
            fontSize: 48,
            textTransform: "uppercase",
            fontWeight: "bold",
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("xs")]: {
              fontSize: 24,
            },
          },
          slogan: {
            fontSize: 32,
            color: color && color.primary.contrastText,
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("xs")]: {
              fontSize: 16,
            },
          },
        };

      case "ClassicV2":
        return {
          root: {
            backgroundImage: props.store.cover_image
              ? `url(${
                  typeof props.store.cover_image === "object"
                    ? createPreview(props.store.cover_image)
                    : props.store.cover_image
                })`
              : `url(${setDefaultImage(props.store.template, "cover_image")})`,
            backgroundBlendMode: "overlay",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "100vh",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "4em",
            [theme.breakpoints.down("sm")]: {
              backgroundPosition: "center",
              flexDirection: "column",
              padding: "2em 4vw",
              backgroundPosition: "center",
              background: props.store.cover_image
                ? `url(${
                    typeof props.store.cover_image === "object"
                      ? createPreview(props.store.cover_image)
                      : props.store.cover_image
                  }),rgba(43, 45, 50, 0.5)`
                : `url(${setDefaultImage(
                    props.store.template,
                    "cover_image"
                  )})`,
            },
          },

          bannerContent: {
            display: "block",
            width: "45%",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              textAlign: "center",
            },
          },
          button: {
            border: "none",
            color: "white",
            fontSize: 16,
            fontWeight: 600,
            padding: "7px 52px",
            height: "55px",
            backgroundColor:
              defaultSRC[
                props.storeInfo ? props.storeInfo.template : "Classic"
              ],
            marginTop: "3vh",
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            color: color && color.primary.contrastText,
            fontSize: 48,
            fontWeight: "bold",
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              fontSize: 36,
            },
          },
          slogan: {
            fontSize: 28,
            marginTop: "2vh",
            color: color && color.primary.contrastText,
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              fontSize: 18,
            },
          },
          btnShopNow: {
            marginTop: "7vh",
            marginBottom: "3vh",
            color: "#fff",
            width: 250,
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              marginTop: "10vh",
            },
          },
          overlay: {
            backgroundColor: "#2b2d32",
            opacity: 0.5,
            width: "100%",
            height: "100vh",
          },
        };
      case "ContemporaryV2":
        return {
          root: {
            backgroundImage: props.store.cover_image
              ? `url(${
                  typeof props.store.cover_image === "object"
                    ? createPreview(props.store.cover_image)
                    : props.store.cover_image
                })`
              : `url(${setDefaultImage(props.store.template, "cover_image")})`,
            backgroundBlendMode: "overlay",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "100vh",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "10em 12vw 6em",
            [theme.breakpoints.down("sm")]: {
              padding: "2em 7vw",
              alignItems: "flex-start",
              justifyContent: "center",
            },
          },

          bannerContent: {
            display: "block",
            width: "50%",
            [theme.breakpoints.down("sm")]: {
              position: "absolute",
              padding: "0 20px",
              bottom: 48,
              width: "100%",
              textAlign: "center",
              marginTop: "15vh",
            },
          },
          button: {
            border: "none",
            color: "white",
            fontSize: 16,
            fontWeight: 600,
            padding: "7px 52px",
            height: "55px",
            backgroundColor:
              defaultSRC[
                props.storeInfo ? props.storeInfo.template : "Classic"
              ],
            marginTop: "3vh",
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            color: color && color.primary.contrastText,
            fontSize: 48,
            fontWeight: "bold",
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              fontSize: 36,
            },
          },
          slogan: {
            fontSize: 20,
            marginTop: "4vh",
            color: color && color.primary.contrastText,
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              fontSize: 18,
            },
          },
          btnShopNow: {
            marginTop: "8vh",
            color: "#fff",
            width: 290,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: "#2b2d32",
            opacity: 0.5,
            width: "100%",
            height: "100vh",
          },
        };
      case "Leftist":
        return {
          root: {
            backgroundImage: props.store.cover_image
              ? `url(${
                  typeof props.store.cover_image === "object"
                    ? createPreview(props.store.cover_image)
                    : props.store.cover_image
                })`
              : `url(${setDefaultImage(props.store.template, "cover_image")})`,
            backgroundBlendMode: "overlay",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "100vh",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "10em 12vw 6em",
            [theme.breakpoints.down("sm")]: {
              padding: "2em 7vw",
              alignItems: "flex-start",
              justifyContent: "center",
            },
          },

          bannerContent: {
            display: "block",
            width: "50%",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              textAlign: "center",
              position: "absolute",
              marginTop: "15vh",
              padding: "0 20px",
              bottom: 48,
            },
          },
          button: {
            border: "none",
            color: "white",
            fontSize: 16,
            fontWeight: 600,
            padding: "7px 52px",
            height: "55px",
            backgroundColor:
              defaultSRC[
                props.storeInfo ? props.storeInfo.template : "Classic"
              ],
            marginTop: "3vh",
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            color: color && color.primary.contrastText,
            fontSize: 48,
            fontWeight: "bold",
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              fontSize: 36,
            },
          },
          slogan: {
            fontSize: 22,
            marginTop: "4vh",
            color: color && color.primary.contrastText,
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              fontSize: 18,
            },
          },
          btnShopNow: {
            marginTop: "8vh",
            color: "#fff",
            width: 290,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: "#2b2d32",
            opacity: 0.5,
            width: "100%",
            height: "100vh",
          },
        };
      case "SariSari":
        return {
          root: {
            backgroundImage: props.store.cover_image
              ? `url(${
                  typeof props.store.cover_image === "object"
                    ? createPreview(props.store.cover_image)
                    : props.store.cover_image
                })`
              : `url(${setDefaultImage(props.store.template, "cover_image")})`,
            backgroundBlendMode: "overlay",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "35em",
          },
          storename: {
            fontSize: 48,
            fontWeight: "bold",
            fontFamily: storeInfo && storeInfo.font.family,
            color: color.secondary.contrastText,
          },
          slogan: {
            fontSize: 18,
            color: color.secondary.contrastText,
          },
          btn: {
            backgroundColor: color.secondary.main,
            color: color.secondary.contrastText,
            padding: "0 100px",
          },
        };
      case "Pastel":
        return {
          root: {
            backgroundImage: props.store.cover_image
              ? `url(${
                  typeof props.store.cover_image === "object"
                    ? createPreview(props.store.cover_image)
                    : props.store.cover_image
                })`
              : `url(${setDefaultImage(props.store.template, "cover_image")})`,
            backgroundBlendMode: "overlay",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "30em",
          },
          storename: {
            fontSize: 48,
            fontWeight: "bold",
            fontFamily: storeInfo && storeInfo.font.family,
            color: color.primary.contrastText,
          },
          slogan: {
            fontSize: 18,
            color: color.primary.contrastText,
          },
          btn: {
            backgroundColor: color.secondary.main,
            color: color.secondary.contrastText,
            padding: "0 100px",
            height: 40,
          },
        };
    }
  };

  let templateStyles = makeStyles((theme) =>
    getStyles(props.store ? props.store.template : "Blank", theme)
  );

  const classes = templateStyles();

  const setBannerTemplate = () => {
    switch (props.store ? props.store.template : "Blank") {
      default:
        return <></>;
      case "Blank":
        return <Blank />;
      case "Classic":
        return (
          <div className={classes.banner}>
            <div>
              <div className={classes.storeName}>
                {" "}
                {props.store
                  ? props.store.name
                    ? props.store.name
                    : "Your Shop's Name"
                  : "Your Shop's Name"}
              </div>
              <div className={`my-4 ${classes.slogan}`}>
                {" "}
                {props.store
                  ? props.store.slogan
                    ? props.store.slogan
                    : isPreview
                    ? "Your shop's tagline here. Make it catchy"
                    : ""
                  : isPreview
                  ? "Your shop's tagline here. Make it catchy"
                  : ""}
              </div>
              <Button
                id="view_products_button"
                disableElevation
                onClick={() =>
                  isPreview
                    ? null
                    : history.push(`/shop/${props.store.site}/products`)
                }
                variant="contained"
                size="large"
                color="secondary"
                style={{
                  marginTop: "7vh",
                  marginBottom: "3vh",
                  color: "#fff",
                  width: 220,
                }}
              >
                View Products
              </Button>
            </div>
          </div>
        );

      case "Contemporary":
        return (
          <div className={classes.banner}>
            {/* {props.store.cover_image ? (
              <PinchZoomPan {...pinchPropsBanner}>
                <img
                  src={
                    typeof props.store.cover_image === "object"
                      ? createPreview(props.store.cover_image)
                      : props.store.cover_image
                  }
                />
              </PinchZoomPan>
            ) : (
              ""
            )} */}
            {/* <Box> */}
            <Box className={classes.bannerContent}>
              <div className={classes.content}>
                <Typography className={classes.storeName}>
                  {props.store
                    ? props.store.name
                      ? props.store.name
                      : "Your Shop's Name"
                    : "Your Shop's Name"}
                </Typography>
                <Box style={{ paddingTop: "4vh" }}>
                  <Typography className={classes.slogan}>
                    {props.store
                      ? props.store.slogan
                        ? props.store.slogan
                        : isPreview
                        ? "Your shop's tagline here. Make it catchy so you can attract more clients"
                        : ""
                      : isPreview
                      ? "Your shop's tagline here. Make it catchy so you can attract more clients"
                      : ""}
                  </Typography>
                </Box>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  id="view_products_button"
                  onClick={() =>
                    isPreview
                      ? null
                      : history.push(`/shop/${props.store.site}/products`)
                  }
                  disableElevation
                  variant="contained"
                  size="large"
                  color="secondary"
                  style={{
                    marginTop: "5vh",
                    marginBottom: "3vh",
                    width: 250,
                  }}
                >
                  View Products
                </Button>
              </div>
            </Box>
            {/* </Box> */}
          </div>
        );
      case "Minimalist":
        return (
          <div
            className={`${classes.banner} d-flex align-items-center justify-content-center text-center`}
          >
            {/* {props.store.cover_image ? (
              <PinchZoomPan {...pinchPropsBanner}>
                <img
                  src={
                    typeof props.store.cover_image === "object"
                      ? createPreview(props.store.cover_image)
                      : props.store.cover_image
                  }
                />
              </PinchZoomPan>
            ) : (
              ""
            )} */}

            <Container className={classes.bannerContent}>
              <Typography className={classes.storeName}>
                {props.store
                  ? props.store.name
                    ? props.store.name
                    : "Your Shop's Name"
                  : "Your Shop's Name"}
              </Typography>
              <Box style={{ paddingTop: "3vh" }}>
                <Typography className={classes.slogan}>
                  {props.store
                    ? props.store.slogan
                      ? props.store.slogan
                      : isPreview
                      ? "Your shop's tagline here. Make it catchy"
                      : ""
                    : isPreview
                    ? "Your shop's tagline here. Make it catchy"
                    : ""}
                </Typography>
                <Button
                  id="view_products_button"
                  onClick={() =>
                    isPreview
                      ? null
                      : history.push(`/shop/${props.store.site}/products`)
                  }
                  disableElevation
                  variant="contained"
                  size="large"
                  color="secondary"
                  style={{
                    marginTop: "7vh",
                    marginBottom: "3vh",
                    color: "#fff",
                    width: 220,
                  }}
                >
                  VIEW PRODUCTS
                </Button>
              </Box>
            </Container>
            {/* </Container>
							</Box> */}
          </div>
        );

      case "ClassicV2":
        return (
          <div>
            <div className={classes.root}>
              <div className={classes.bannerContent}>
                <Typography className={classes.storeName}>
                  {props.store
                    ? props.store.name
                      ? props.store.name
                      : "Your Shop's Name"
                    : "Your Shop's Name"}
                </Typography>

                <Typography className={classes.slogan}>
                  {props.store
                    ? props.store.slogan
                      ? props.store.slogan
                      : isPreview
                      ? "Your shop's tagline here. Make it catchy"
                      : ""
                    : isPreview
                    ? "Your shop's tagline here. Make it catchy"
                    : ""}
                </Typography>

                <Button
                  id="view_products_button"
                  variant="contained"
                  size="large"
                  color="secondary"
                  disableElevation
                  onClick={() =>
                    isPreview
                      ? null
                      : history.push(
                          `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                        )
                  }
                  className={classes.btnShopNow}
                >
                  Shop Now
                </Button>
              </div>
            </div>
          </div>
        );

      case "ContemporaryV2":
        return (
          <div>
            <div className={classes.root}>
              <div className={classes.bannerContent}>
                <Typography className={classes.storeName}>
                  {props.store
                    ? props.store.name
                      ? props.store.name
                      : "Your Shop's Name"
                    : "Your Shop's Name"}
                </Typography>

                <Typography className={classes.slogan}>
                  {props.store
                    ? props.store.slogan
                      ? props.store.slogan
                      : isPreview
                      ? "Your shop's Tagline here. Make it catchy"
                      : ""
                    : isPreview
                    ? "Your shop's Tagline here. Make it catchy"
                    : ""}
                </Typography>

                <Button
                  id="view_products_button"
                  variant="contained"
                  size="large"
                  color="secondary"
                  disableElevation
                  onClick={() =>
                    isPreview
                      ? null
                      : history.push(
                          `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                        )
                  }
                  className={classes.btnShopNow}
                >
                  Shop Now
                </Button>
              </div>
            </div>
          </div>
        );

      case "Leftist":
        return (
          <div>
            <div className={classes.root}>
              <div className={classes.bannerContent}>
                <Typography className={classes.storeName}>
                  {props.store
                    ? props.store.name
                      ? props.store.name
                      : "Your Shop's Name"
                    : "Your Shop's Name"}
                </Typography>

                <Typography className={classes.slogan}>
                  {props.store
                    ? props.store.slogan
                      ? props.store.slogan
                      : isPreview
                      ? "Your shop's Tagline here. Make it catchy"
                      : ""
                    : isPreview
                    ? "Your shop's Tagline here. Make it catchy"
                    : ""}
                </Typography>

                <Button
                  id="view_products_button"
                  variant="contained"
                  size="large"
                  color="secondary"
                  disableElevation
                  onClick={() =>
                    isPreview
                      ? null
                      : history.push(
                          `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                        )
                  }
                  className={classes.btnShopNow}
                >
                  Shop Now
                </Button>
              </div>
            </div>
          </div>
        );
      case "SariSari":
        return (
          <div style={{ backgroundColor: color.primary.main }}>
            {isMobile ? (
              <div
                style={{ marginTop: "10vh" }}
                className={`${classes.root} d-flex justify-content-center align-items-center text-center col-12`}
              >
                <div>
                  <div
                    className={classes.storename}
                    style={{
                      color: storeInfo.site === "biyayaanimal" ? "#f1a055" : "",
                    }}
                  >
                    {storeInfo && storeInfo.name}
                  </div>
                  <div
                    className={`${classes.slogan} mt-3`}
                    style={{
                      color: storeInfo.site === "biyayaanimal" ? "#f1a055" : "",
                    }}
                  >
                    {storeInfo && storeInfo.slogan}
                  </div>
                  <Button
                    disableElevation
                    variant="contained"
                    className={`mt-5 ${classes.btn}`}
                    onClick={() =>
                      isPreview
                        ? null
                        : history.push(
                            `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                          )
                    }
                  >
                    Shop now
                  </Button>
                </div>
              </div>
            ) : (
              <Container>
                <div className={`${classes.root} d-flex col-12 p-0`}>
                  <div className="col-6 p-0"></div>
                  <div
                    className="col-6 d-flex align-items-center"
                    style={{ padding: "5em" }}
                  >
                    <div>
                      <div
                        className={classes.storename}
                        style={{
                          color:
                            storeInfo.site === "biyayaanimal" ? "#f1a055" : "",
                        }}
                      >
                        {storeInfo && storeInfo.name}
                      </div>
                      <div
                        className={`${classes.slogan} mt-3`}
                        style={{
                          color:
                            storeInfo.site === "biyayaanimal" ? "#f1a055" : "",
                        }}
                      >
                        {storeInfo && storeInfo.slogan}
                      </div>
                      <Button
                        disableElevation
                        variant="contained"
                        className={`mt-5 ${classes.btn}`}
                        onClick={() =>
                          isPreview
                            ? null
                            : history.push(
                                `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                              )
                        }
                      >
                        Shop now
                      </Button>
                    </div>
                  </div>
                </div>
              </Container>
            )}
          </div>
        );
      case "Pastel":
        return (
          <div className={`${classes.root} d-flex col-12 p-0`}>
            <div
              className="col-md-6 col-12 d-flex align-items-center"
              style={{ padding: isMobile ? "2em" : "5em" }}
            >
              <div>
                <div className={classes.storename}>
                  {storeInfo && storeInfo.name}
                </div>
                <div className={`${classes.slogan} mt-3`}>
                  {storeInfo && storeInfo.slogan}
                </div>
                <Button
                  size="small"
                  disableElevation
                  variant="contained"
                  className={`mt-5 ${classes.btn}`}
                  onClick={() =>
                    isPreview
                      ? null
                      : history.push(
                          `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                        )
                  }
                >
                  Shop now
                </Button>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div>{setBannerTemplate(props.store ? props.store.template : "Blank")}</div>
  );
};
export default PreviewBannerTemplate;
