import React from 'react';
import defaultSRC from '../defaultResources';
import defaultColor from '../colors';
import Blank from '../blank';
import history from '../../../../../store/history';
import { useTheme } from '@material-ui/styles';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import apiConfig from '../../../../../common/apiConfig';

const AppBarTemplate = (props) => {
  let theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let isPreview = props.isPreview;
  let logo = props.storeInfo.logo_image;
  let logo_image = props.storeInfo
    ? props.storeInfo.logo_image
      ? props.storeInfo.logo_image
      : defaultSRC[props.storeInfo ? props.storeInfo.template : 'Classic']
          .appBarLogo
    : defaultSRC[props.storeInfo ? props.storeInfo.template : 'Classic']
        .appBarLogo;

  let color = props.storeInfo && props.storeInfo.color;
  const alamatSiteUrl = apiConfig.alamatSiteUrl;


  function createPreview(file) {
    var blob = new Blob([file], { type: file.type });
    var blobUrl = URL.createObjectURL(blob);

    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = function () {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function () {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    return blobUrl;
  }

  function setAppBarTemplate() {
    switch (props.storeInfo ? props.storeInfo.template : 'Blank') {
      default:
        return <Blank />;
      case 'Blank':
        return <Blank />;
      case 'Leftist':
        return (
          <div
            id="logo_button"
            onClick={() =>
              isPreview ? null : history.push(`/shop/${props.storeInfo.site}`)
            }
            style={{
              width: isMobile ? 40 : 50,
              height: isMobile ? 40 : 50,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${
                props.storeInfo.logo_image
                  ? typeof logo === 'object'
                    ? createPreview(logo)
                    : logo
                  : '#f97663'
              })`,
              backgroundColor:
                props.storeInfo && !props.storeInfo.logo_image
                  ? color && color.primary.contrastText
                  : 'inherit',
              cursor: 'pointer',
            }}
          />
        );
      case 'Classic':
        return (
          <div
            id="logo_button"
            onClick={() =>
              isPreview ? null : history.push(`/shop/${props.storeInfo.site}`)
            }
            style={{
              width: isMobile ? 40 : 50,
              height: isMobile ? 40 : 50,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${
                props.storeInfo.logo_image
                  ? typeof logo === 'object'
                    ? createPreview(logo)
                    : logo
                  : '#f97663'
              })`,
              backgroundColor:
                props.storeInfo && !props.storeInfo.logo_image
                  ? color && color.primary.contrastText
                  : 'inherit',
              cursor: 'pointer',
            }}
          />
        );
      case 'Contemporary':
        return (
          <div
            id="logo_button"
            onClick={() =>
              isPreview ? null : history.push(`/shop/${props.storeInfo.site}`)
            }
            style={{
              width: isMobile ? 40 : 50,
              height: isMobile ? 40 : 50,
              marginLeft: '2vw',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${
                props.storeInfo.logo_image
                  ? typeof logo === 'object'
                    ? createPreview(logo)
                    : logo
                  : '#f97663'
              })`,
              backgroundColor:
                props.storeInfo && !props.storeInfo.logo_image
                  ? color && color.primary.contrastText
                  : 'inherit',
              cursor: 'pointer',
            }}
          />
        );
      case 'Minimalist':
        const UseStyles1 = makeStyles((theme) => ({
          logo: {
            width: isMobile ? 40 : 50,
            height: isMobile ? 40 : 50,
            marginLeft: '2vw',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${
              props.storeInfo.logo_image
                ? typeof logo === 'object'
                  ? createPreview(logo)
                  : logo
                : '#f97663'
            })`,
            backgroundColor:
              props.storeInfo && !props.storeInfo.logo_image
                ? color && color.primary.contrastText
                : 'inherit',
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
              marginLeft: 0,
            },
          },
        }));

        const classes3 = UseStyles1();
        return (
          <div
            id="logo_button"
            onClick={
              isPreview
                ? null
                : () => history.push(`/shop/${props.storeInfo.site}`)
            }
            className={classes3.logo}
          />
        );
      case 'ClassicV2':
        return (
          <div
            id="logo_button"
            onClick={() =>
              isPreview ? null : history.push(`/shop/${props.storeInfo.site}`)
            }
            style={{
              width: 40,
              height: 40,
              borderRadius: 20,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${
                props.storeInfo.logo_image
                  ? typeof logo === 'object'
                    ? createPreview(logo)
                    : logo
                  : '#f97663'
              })`,
              backgroundColor:
                props.storeInfo && !props.storeInfo.logo_image
                  ? color && color.primary.contrastText
                  : 'inherit',
              cursor: 'pointer',
            }}
          />
        );
      case 'ContemporaryV2':
        return (
          <div
            id="logo_button"
            onClick={() =>
              isPreview ? null : history.push(`/shop/${props.storeInfo.site}`)
            }
            style={{
              width: 40,
              height: 40,
              borderRadius: 20,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${
                props.storeInfo.logo_image
                  ? typeof logo === 'object'
                    ? createPreview(logo)
                    : logo
                  : '#f97663'
              })`,
              backgroundColor:
                props.storeInfo && !props.storeInfo.logo_image
                  ? color && color.primary.contrastText
                  : 'inherit',
              cursor: 'pointer',
            }}
          />
        );
      case 'SariSari':
        return (
          <div
            id="logo_button"
            onClick={() =>
              isPreview ? null : history.push(`/shop/${props.storeInfo.site}`)
            }
            style={{
              width: 40,
              height: 40,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${
                props.storeInfo.logo_image
                  ? typeof logo === 'object'
                    ? createPreview(logo)
                    : logo
                  : '#f97663'
              })`,
              backgroundColor:
                props.storeInfo && !props.storeInfo.logo_image
                  ? color && color.primary.contrastText
                  : 'inherit',
              cursor: 'pointer',
            }}
          />
        );
      case 'Pastel':
        return (
          <div
            id="logo_button"
            onClick={() =>
              isPreview ? null : history.push(`/shop/${props.storeInfo.site}`)
            }
            style={{
              width: 40,
              height: 40,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${
                props.storeInfo.logo_image
                  ? typeof logo === 'object'
                    ? createPreview(logo)
                    : logo
                  : '#f97663'
              })`,
              backgroundColor:
                props.storeInfo && !props.storeInfo.logo_image
                  ? color && color.primary.contrastText
                  : 'inherit',
              cursor: 'pointer',
            }}
          />
        );
      case 'Blocks':
        return (
          <div
            id="logo_button"
            onClick={() =>
              isPreview ? null : history.push(`/shop/${props.storeInfo.site}`)
            }
            style={{
              width: 40,
              height: 40,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${
                props.storeInfo.logo_image
                  ? typeof logo === 'object'
                    ? createPreview(logo)
                    : logo
                  : '#f97663'
              })`,
              backgroundColor:
                props.storeInfo && !props.storeInfo.logo_image
                  ? color && color.primary.contrastText
                  : 'inherit',
              cursor: 'pointer',
              borderRadius: 4,
            }}
          />
        );
      case 'NFT':
        const UseStyles2 = makeStyles((theme) => ({
          logo: {
            width: 130,
            height: 60,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${
              props.storeInfo.logo_image
                ? typeof logo === 'object'
                  ? createPreview(logo)
                  : logo
                : '#f97663'
            })`,
            backgroundColor:
              props.storeInfo && !props.storeInfo.logo_image
                ? color && color.primary.contrastText
                : 'inherit',
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
              marginLeft: 0,
            },
            marginTop: 25,
          },
        }));

        const classes4 = UseStyles2();
        return (
          <div
            id="logo_button"
            onClick={
              isPreview
                ? null
                : () => window.location.href = alamatSiteUrl
            }
            className={classes4.logo}
          />
        );
    }
  }

  return (
    <div>
      {setAppBarTemplate(props.storeInfo ? props.storeInfo.template : 'Blank')}
    </div>
  );
};
export default AppBarTemplate;
