const Paths = {
  home: "/order/:id",
  cart: "/order/:id/cart",
  delivery: "/order/:id/delivery",
  customer_details: "/order/:id/customer_details",
  payment: "/order/:id/payment",
  checkout: "/order/:id/checkout"
};

export default Paths;
