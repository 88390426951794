import React from "react";
import { makeStyles, useTheme, createStyles } from "@material-ui/core/styles";
import {
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Link,
  Chip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import imgPlaceholder from "../../../../../assets/images/product_placeholder.png";
import tags from "../../../../../assets/images/tags.svg";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Utils from "../../../utils";
import StoreActions from "../StoreActions";
import DeleteIcon from "@material-ui/icons/Delete";
import history from "../../../../../store/history";
import VoucherModal from "../voucher/voucherModal";
import PriceDisplay, { WishlistItemPriceDisplay } from "../PriceDisplay";
import VariantPicker from "../../../../enterprise/components/cart/variantPicker";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Skeleton from "@material-ui/lab/Skeleton";
import { Check } from "@material-ui/icons";
import { FreebieCartPage } from "../../../../quickOrder/components/products/freebie";
import Actions from "../../../../quickOrder/redux/actions";
import AddToCartLimit from "../addToCartLimit";
const CartDrawer = (props) => {
  const { open, toggleDrawer } = props;
  const useStyles = makeStyles((theme) => ({
    drawer: {
      backgroundColor: "#fff",
      width: 440,
      [theme.breakpoints.down("xs")]: {
        width: "100vw",
      },
    },
    header: {
      fontSize: 28,
      fontWeight: 600,
      color: "#2b2d33",
      fontFamily: "Inter",
    },
    closeSection: {
      display: "flex",
      justifyContent: "flex-end",
    },
    items: {
      marginTop: "2vh",
      height: "40vh",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 4,
      },
      "&::-webkit-scrollbar-track": {
        background: "#eee",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#f97663",
      },
    },
    itemSection: {
      display: "flex",
    },
    image: {
      borderRadius: 4,
      width: 65,
      height: 65,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    columnItem: {
      border: "none",
      padding: "16px 0",
    },
    labelbold: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#2b2d32",
    },
    labelbold2: {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#2b2d32",
    },
    btn: {
      borderRadius: 0,
      width: 25,
      height: 25,
      minWidth: 25,
    },
    priceSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      bottom: 0,
      width: "100%",
    },
    actionBtnSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "50%",
    },
    summary: {
      display: "flex",
      justifyContent: "space-between",
      padding: "15px 0",
    },
    btnCheckout: {
      color: "#fff",
      height: 40,
    },
    noItems: {
      textAlign: "center",
      padding: 20,
      fontSize: 12,
    },
    btnVariantCancel: {
      color: "#2b2d32",
      borderColor: "#2b2d32",
    },
    btnVariantConfirm: {
      backgroundColor: "#2b2d32",
      color: "#fff",
    },
  }));

  const dispatch = useDispatch();
  const classes = useStyles();
  const items = useSelector((state) => state.store.items);
  const storeInfo = useSelector((state) => state.store.store);
  const subtotal = useSelector((state) => state.store.subtotal);
  const total = useSelector((state) => state.store.total);
  const voucher_discount = useSelector((state) => state.store.voucher_discount);
  let vouchers = useSelector((state) => state.store.voucherList);
  const delivery_details = useSelector((state) => state.store.deliveryDetails);
  const fees = useSelector((state) => state.store.fees);
  const fees_loading = useSelector((state) => state.store.fees.loading);
  const courier_fees_loading = useSelector(
    (state) => state.store.couriers_shipping_fee.loading
  );
  const payment_channel = useSelector(
    (state) => state.store.selected_payment_channel
  );
  const freebie = useSelector((state) => state.quickOrder.freebie);
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [quantity, setQuantity] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openBuxLimit, setOpenBuxLimit] = React.useState(false);
  function productImage(item) {
    if (item.gallery && item.gallery.length) {
      return item.gallery[0].image;
    } else if (item.image) {
      return item.image;
    } else {
      return imgPlaceholder;
    }
  }

  function decrement(row) {
    let params = {
      ...row,
      quantity: row.quantity - 1,
    };
    dispatch(StoreActions.updateQuantity(params));
  }

  function increment(row) {
    let params = {
      ...row,
      quantity: row.quantity + 1,
    };
    dispatch(StoreActions.updateQuantity(params));
  }

  function removeFromCart(index) {
    dispatch(StoreActions.removeFromCart(index));
  }

  const checkStock = (product) => {
    if (product.has_variants) {
      if (product.productSelection) {
        if (product.quantity >= product.productSelection.quantity) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (product.always_available) {
        return false;
      } else {
        if (product.quantity >= product.stock) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const getStocks = (product) => {
    if (product.has_variants) {
      return product.productSelection ? product.productSelection.quantity : -2;
    } else {
      if (product.always_available) {
        return -1;
      } else {
        return product.stock;
      }
    }
  };

  const show_error = (product) => {
    const stocks = getStocks(product);
    if (stocks === -2 || stocks === -1) {
      return "";
    } else if (stocks === 0) {
      return (
        <div style={{ margin: "2vh 0 0 6em" }}>
          <small className="text-danger">Out of stock</small>
        </div>
      );
    } else if (product.quantity >= stocks) {
      return (
        <div style={{ margin: "2vh 1em 0" }}>
          <small className="text-danger d-flex align-items-center">
            {" "}
            <ErrorOutlineIcon className="mr-2" style={{ color: "red" }} /> You
            have reached the maximum quantity for this{" "}
            {product.has_variants ? "variant" : "product"}.
          </small>
        </div>
      );
    }
  };

  const isEqual = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  function handleConfirmVariant(product) {
    let productSelectionIds = product.productSelection.variants.map(
      (i) => i.id
    );
    let prevSelectionIds = product.prevSelection
      ? product.prevSelection.variants.map((i) => i.id)
      : [];
    if (isEqual(productSelectionIds, prevSelectionIds)) {
      setAnchorEl(null);
    } else {
      dispatch(StoreActions.updateVariant(product));
    }
  }

  function freebieQuantity(freebie) {
    dispatch(Actions.updateFreebieQuantity(freebie));
  }

  function handleCheckout() {
    if (!openBuxLimit) {
      history.push(`/shop/${storeInfo.site}/cart`);
      dispatch(StoreActions.showCartDrawer(false));
    }
  }

  const [errors, setErrors] = React.useState([]);
  React.useEffect(() => {
    errors.length = 0;
    for (let i in items) {
      if (items[i].has_variants) {
        if (items[i].has_variants && items[i].productSelection) {
          if (items[i].productSelection.quantity <= 0) {
            errors.push(true);
          } else if (items[i].quantity > items[i].productSelection.quantity) {
            errors.push(true);
          } else {
            errors.push(false);
          }
        } else if (items[i].has_variants && !items[i].productSelection) {
          errors.push(true);
        }
      } else {
        if (items[i].always_available) {
          errors.push(false);
        } else if (items[i].stock <= 0) {
          errors.push(true);
        } else if (items[i].quantity > items[i].stock) {
          errors.push(true);
        }
      }
    }

    if (errors.includes(true)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [items, props.open]);

  React.useEffect(() => {
    if (
      (storeInfo.kyc_level === 0 &&
        parseInt(total - voucher_discount) > 100000) ||
      (storeInfo.kyc_level === 1 && parseInt(total - voucher_discount) > 500000)
    ) {
      setOpenBuxLimit(true);
    } else {
      setOpenBuxLimit(false);
    }
  }, [total]);

  return (
    <div>
      <Drawer
        id="cart_drawer"
        anchor="right"
        open={open}
        onClose={toggleDrawer}
      >
        <div className={classes.drawer}>
          <div className={classes.closeSection}>
            <IconButton onClick={toggleDrawer}>
              <CloseIcon color="secondary" />
            </IconButton>
          </div>
          <div style={{ padding: "0 2em 3vh" }}>
            <div className={classes.header}>Your Cart</div>
            {openBuxLimit ? (
              <div className="my-2">
                <AddToCartLimit
                  kycLevel={storeInfo?.kyc_level}
                  isCartDrawer={true}
                />
              </div>
            ) : (
              ""
            )}

            <div className={classes.items}>
              <Table>
                <TableBody>
                  {items && items.length > 0 ? (
                    items.map((row, key) => (
                      <TableRow
                        id="cart_items"
                        key={key}
                        style={{ borderBottom: "1px solid #d1d5dd" }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.columnItem}
                        >
                          <div className={classes.itemSection}>
                            <div
                              className={classes.image}
                              style={{
                                backgroundImage: `url(${productImage(row)})`,
                              }}
                            />
                            <div
                              style={{
                                marginLeft: "1vw",
                                position: "relative",
                                width: "70%",
                              }}
                            >
                              <div className={classes.labelbold}>
                                {row.name}
                              </div>
                              <VariantPicker
                                index={key}
                                product={row}
                                productSelection={row.productSelection}
                                handleConfirmVariant={handleConfirmVariant}
                              />
                              <div className={classes.priceSection}>
                                <WishlistItemPriceDisplay
                                  symbol="₱"
                                  item={row}
                                  productSelection={row.productSelection}
                                />
                                <div className={classes.actionBtnSection}>
                                  <div>x</div>
                                  <div>{row.quantity}</div>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      className={classes.btn}
                                      style={{ borderRight: "none" }}
                                      onClick={() =>
                                        row.quantity === 1
                                          ? removeFromCart(key)
                                          : decrement(row)
                                      }
                                    >
                                      {row.quantity <= 1 ? (
                                        <DeleteIcon style={{ fontSize: 12 }} />
                                      ) : (
                                        <RemoveIcon style={{ fontSize: 12 }} />
                                      )}
                                    </Button>
                                    <Button
                                      disabled={checkStock(row)}
                                      variant="outlined"
                                      color="secondary"
                                      className={classes.btn}
                                      onClick={() => increment(row)}
                                    >
                                      <AddIcon style={{ fontSize: 12 }} />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {freebie.length
                            ? freebie
                                .filter((i) => row.uuid === i.minimum_x_product)
                                .map((freebie) => (
                                  <FreebieCartPage
                                    freebie={freebie}
                                    product={row}
                                    freebieQuantity={freebieQuantity}
                                  />
                                ))
                            : ""}
                          {show_error(row)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <div className={classes.noItems}>
                      No item(s) in your cart
                    </div>
                  )}
                </TableBody>
              </Table>
            </div>
            <div style={{ marginTop: "5vh" }}>
              <div
                className={classes.summary}
                style={{
                  display: vouchers.length ? "" : "none",
                }}
              >
                <div className={classes.labelbold}>Summary</div>
                <Link
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    color: "rgba(43,45,50, 0.8)",
                  }}
                  onClick={() => setOpenVoucher(true)}
                  id="vouchers-btn-desktop"
                >
                  <img
                    src={tags}
                    width="15"
                    height="15"
                    style={{ marginRight: 5 }}
                    alt=""
                  />
                  Vouchers
                </Link>
              </div>
              <div className={classes.summary}>
                <div className={classes.labelbold2}>Items</div>
                <div className={classes.labelbold2}>
                  {parseFloat(
                    items.reduce((total, object) => {
                      return object.has_variants
                        ? parseFloat(total) +
                            (object.always_available
                              ? parseFloat(object.quantity)
                              : object.quantity > 0
                              ? parseFloat(object.quantity)
                              : 0)
                        : parseFloat(total) +
                            (object.always_available
                              ? parseFloat(object.quantity)
                              : object.stock > 0
                              ? parseFloat(object.quantity)
                              : 0);
                    }, 0)
                  )}
                </div>
              </div>
              <div
                className={classes.summary}
                style={{ borderBottom: "1px dashed #d1d5dd" }}
              >
                <div className={classes.labelbold2}>Subtotal</div>
                <div className={classes.labelbold2}>
                  ₱ {Utils.commafy(subtotal)}
                </div>
              </div>
              {delivery_details.shipping ? (
                <div className={classes.summary}>
                  <div className={classes.labelbold2}>Delivery fee</div>
                  <div className={classes.labelbold2}>
                    ₱ {Utils.commafy(delivery_details.shippingFee)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {voucher_discount ? (
                <div
                  className={classes.summary}
                  style={{ borderBottom: "1px dashed #d1d5dd" }}
                >
                  <div className={classes.labelbold2}>Voucher Applied</div>
                  <div className={classes.labelbold2}>
                    {`- ₱${Utils.commafy(voucher_discount)}`}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className={classes.summary}>
                <div className={classes.labelbold}>Total</div>
                <div className={classes.labelbold}>
                  ₱ {Utils.commafy(total - voucher_discount)}
                </div>
              </div>

              <div style={{ marginTop: "5vh" }}>
                <Button
                  id="proceed_to_checkout"
                  disableElevation
                  fullWidth
                  variant="contained"
                  className={classes.btnCheckout}
                  color="secondary"
                  disabled={hasError}
                  onClick={handleCheckout}
                >
                  Proceed to Checkout
                </Button>
                <Button
                  style={{ marginTop: "1vh", height: 40 }}
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  onClick={() => {
                    history.push(`/shop/${storeInfo.site}/products`);
                    dispatch(StoreActions.showCartDrawer(false));
                  }}
                >
                  Continue Shopping
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <VoucherModal
        id="voucher-modal"
        open={openVoucher}
        setOpen={setOpenVoucher}
      />
    </div>
  );
};

export default CartDrawer;
