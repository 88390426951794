import React from "react";
import { useSelector } from "react-redux";

export const useAddresses = () => {
  const addresses = useSelector(state => state.buyerDashboard.addresses);
  return [addresses];
};

export const useDefaultAddress = () => {
  const addresses = useSelector(state => state.buyerDashboard.addresses);
  const default_address = addresses.filter(i => i.is_default);
  return [default_address.length ? default_address[0] : false];
};
