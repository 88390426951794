import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import logos from "../webBuilder/forms/logoImages";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  Link
} from "@material-ui/core";
import AddAccountForm from "./addAccountForm";
import { useSelector, useDispatch } from "react-redux";
import AlertDialogBox from "../webBuilder/common/AlertDialogBox";
import {
  addPaymentMethod,
  getPaymentMethods,
  editPayment
} from "../webBuilder/websiteBuilderActions";
import SentroLoading from "../../../loading";

import PaymentCard from "./paymentCard";
import ViewAccountForm from "./viewAccountForm";
import EditAccountForm from "./editAccountForm";
import {
  paymayaImg,
  gcashImg,
  payPaylImg
} from "../../../../websiteBuilder/forms/bankContants";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={{
            backgroundColor: "#fafafa",
            height: "83vh",
            padding: 20,
            overflowY: value === 0 ? "hidden" : "scroll"
          }}
          p={3}
        >
          <Typography style={{ height: "100%", position: "relative" }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}
const useStyles = makeStyles(theme => ({
  dialog: { backgroundColor: "#fafafa", height: "100%" },
  appBar: {
    position: "relative",
    backgroundColor: "#fafafa"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  header1: {
    fontSize: 14,
    fontWeight: 600,
    color: "#54575f"
  },
  link: {
    color: "#f97663",
    fontWeight: "600",
    fontSize: 14,
    "&:hover": {
      color: "#f97663"
    }
  },
  paper: {
    position: "relative",
    border: "1px solid #d1d5dd",
    borderRadius: 4,
    boxShadow: "0 8px 12px rgba(0,0,0,0.05)",
    backgroundColor: "#fff",
    padding: 15,
    display: "flex",
    marginBottom: "2vh"
  },
  closeBtn: { position: "absolute", left: 15 }
}));
const BuxPayment = props => {
  const { language } = props;
  const classes = useStyles();
  const bankLogos = logos.bankLogos;
  const otcLogos = logos.otcLogos;
  const ewallets = logos.ewallets;
  const cards = logos.cards;
  return (
    <div>
      <div className={classes.header1}>
        Online and over-the-counter payment partners
      </div>
      <div style={{ marginTop: "2vh", fontSize: 14, color: "#54575f" }}>
        {language === "English"
          ? "Don't have a bank or e-wallet account? Get paid via our Bux payment channels - automatically available upon publishing your shop."
          : "Walang bank account o e-wallet account? Tumanggap ng bayad gamit ang payment channels ng Bux."}
      </div>
      <div
        style={{
          backgroundColor: "#fff",
          marginTop: "2vh",
          border: "1px solid #d2d5d6",
          boxShadow: "0 8px 12px rgba(0,0,0,0.05)",
          borderRadius: 4,
          padding: 10,
          fontSize: 14,
          color: "#54575f"
        }}
      >
        <div>
          <div>1. Online payment channels</div>
          <div style={{ padding: "1vh 0 0 17px", fontSize: 12 }}>
            <div>Online Banking fee: PHP 15 per transaction</div>
            <div>GCash fee: PHP 10 + 2% of transaction price</div>
            <div style={{ paddingTop: "1vh" }}>
              {bankLogos.map(item => {
                return (
                  <img
                    src={item.logo}
                    style={{ width: "auto", height: 25, margin: 5 }}
                    alt=""
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "2vh" }}>
          <div>2. Cash payment (over the counter)</div>
          <div style={{ padding: "1vh 0 0 17px", fontSize: 12 }}>
            <div style={{ fontStyle: "italic" }}>
              Fee: PHP 20.00 per transaction
            </div>
            <div style={{ paddingTop: "1vh" }}>
              {otcLogos.map(item => {
                return (
                  <img
                    src={item.logo}
                    style={{ width: "auto", height: 40, margin: 5 }}
                    alt=""
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "2vh" }}>
          <div>3. E-wallets</div>
          <div style={{ padding: "1vh 0 0 17px", fontSize: 12 }}>
            <div style={{ fontStyle: "italic" }}>
              Fee: PHP 10 + 2.0% of transaction amount
            </div>
            <div style={{ paddingTop: "1vh" }}>
              {ewallets.map(item => {
                return (
                  <img
                    src={item.logo}
                    style={{ width: "auto", height: 25, margin: 5 }}
                    alt=""
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "2vh" }}>
          <div>4. Credit/Debit Cards</div>
          <div style={{ padding: "1vh 0 0 17px", fontSize: 12 }}>
            <div style={{ fontStyle: "italic" }}>
              Applicable to Bux Enterprise accounts
            </div>
            <div style={{ paddingTop: "1vh" }}>
              {cards.map(item => {
                return (
                  <img
                    src={item.logo}
                    style={{ width: "auto", height: 25, margin: 5 }}
                    alt=""
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "5vh" }}>
        <div className={classes.header1}>Bux Wallet</div>
        <div style={{ marginTop: "2vh", fontSize: 14, color: "#54575f" }}>
          {language === "English"
            ? "All payments done via Bux, regardless of the method chosen by customer, will go straight to your Bux Wallet, net of applicable fees. You can then withdraw from your Bux Wallet to your bank via online transfer."
            : "Lahat ng bayad sa kahit anong payment channel ay mapupunta sa iyong Bux Wallet (nakabawas na ang applicable fees).* Pwede mong i-withdraw ang laman ng iyong Bux Wallet (in real time) sa iyong bangko o online wallet gamit ang online transfer."}
        </div>
      </div>
      <div style={{ paddingTop: "3vh" }}>
        <div className={classes.header1}>Cash in and cash out limit</div>
        {language === "English" ? (
          <div style={{ marginTop: "2vh", fontSize: 14, color: "#54575f" }}>
            Amount transacted within your wallet is subject to limits depending
            on your account type with Bux. To know more,{" "}
            <Link
              className={classes.link}
              onClick={() => window.open("https://bux.ph/faq")}
            >
              click here.
            </Link>
          </div>
        ) : (
          <div style={{ marginTop: "2vh", fontSize: 14, color: "#54575f" }}>
            Ang amount na pwedeng macash-in o cash-out ay depende sa account
            type ng iyong Bux account. To know more,{" "}
            <Link
              className={classes.link}
              onClick={() => window.open("https://bux.ph/faq")}
            >
              click here.
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const DialogTransition = props => <Slide direction="up" {...props} />;
const Payments = props => {
  const { open, setOpen, language } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const STORE_UUID = localStorage.getItem("STORE_UUID");
  const [value, setValue] = React.useState(0);
  const storeInfo = useSelector(state => state.mobileDashboard.storeInfo);
  const payments = useSelector(state => state.mobileDashboard.payments);
  const alertMessage = useSelector(state => state.mobileDashboard.message);
  const dialogOpen = useSelector(state => state.mobileDashboard.dialogOpen);
  const loading = useSelector(state => state.mobileDashboard.loading);
  let defaultImg =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAUvSURBVHgB7d3Pi9R1HMfxTxE7F9eDsx3KQymYG4QHacFYURoIBA+rgWuQ3brUX6MXu3SrS0cDKQg2DJaCBQ/moQiSDq6X3YvrZfdi+xEGiuzFfHW+88N5PEA8ON9hGebpfHY/n31/X7p67frjAjzVywX4XwKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUyYTmeuMDkEMkGWlk6WKx9/VLrdbmEyCGRC1DiW3j355BPk8urFsrj4VmH8BDIB+nH8U+/9M+XEiXcK4yWQMTu9fOo/cfzr35ZOFsbnlcLY9HpnyuLxvJTqx7OxcbswegIZg/p9xrlzH5TDr7820ONFMj4CGbEax4WV841/UiWS8fA9yAjNzx94pjj6aiS93tnC6PgEGZF+HPPz8+V5LB4/9uTvtbVbhfb5BBmBYcXRVyNZ3d8rsevePoG0bNhx9C3sL9NWnjzvgUJ7BNKibvdQWb10cehx9NVILoikVQJpSY2jvnk7nU5pU41PJO0RSAvqOapRxNEnkvYIZMhqHPUc1aji6BNJOwQyRPVwYY1jXGokq5c+dFx+iOyDDMnTTuQmOzs7pYm5uc5AP9bt79R/9/0PZXPzQeH5CGRI+ht4g1pb+6ncb/AGrvENerK3RvL2/lJPIM/PEgsCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAwlUdN6i8KLZ8+VSZJ01+KWl4+VXb39gZ+/EGndMdiKgOpZ40GnSk1qRYWnLidBpZYEAgEAoFAIBAIBAKBQCAQCAQCgUAgEMzMVJP795tN+Dh8uN2d+q2t7bK72+CoycF5Q+HGYCYC2dl5VG58e7PRNZ9/9mlp0/r6L62N/WF4LLEgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEL129dv1xmTJ1osnKyvlG1zS95Vlb9zbv293dLXsNxv7Mzc01ujFo0+cfha++/qZMm5k5rNj2G76p+mZv8064bT//rLDEgkAgEAgEAoFAIBAIBAKBQCAQCAQCgWAmdtLreJ179/5qdM3i4rHSpj/3v569BmN/Fl7tloXuodKWOvmlyWikevTl6NE3yotuJgKpZ5LWfrzV6Jq2A/n1zt3GY3/aDOThw51Gr1Gd0TULgVhiQSAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCGZm7A/jd/2LL8u08QkCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgmJmxP+vrPze6ptc7W9q0sXG70V2vjhx5c/9Pe1NEtra2y507dwd+fKczV5aX3ysvupkZ+/Pb7380uqbtQDY3HzQa+1PvkNVmIPU/kSavUR37MwuBWGJBIBAIBAKBQCAQCAQCgUAgEAgEAoFAMBM76VXdiZ4k9euZn3808OM7nU5pUz060uQ1Ori/kz4LZiKQeizikyuXyyTp9c6USbKw0J2412gSWGJBIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIpvKoydb2drlx42aBtk1lIHVETZOROfCsLLEgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBD8DcdF1Hbi/9DMAAAAAElFTkSuQmCC";
  const [paymentMethod, setPaymentMethod] = React.useState({
    payment_method: "Bank",
    qr_code_img: "",
    bank_name: "",
    account_name: "",
    account_number: "",
    mobile_number: "",
    payment_link: "",
    addAccount: false,
    viewAccount: false,
    editAccount: false
  });
  const [newPaymentMethod] = React.useState({
    payment_method: "Bank",
    qr_code_img: "",
    bank_name: "",
    account_name: "",
    account_number: "",
    mobile_number: "",
    payment_link: "",
    addAccount: false,
    viewAccount: false,
    editAccount: false
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };
  function dataURLtoFile(dataurl, filename, ext) {
    console.log(dataurl);
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    console.log(new File([u8arr], filename, { type: mime }));
    return new File([u8arr], `${filename}.${ext}`, { type: mime });
  }

  function renderPaymentIcon(payment_method, qr_code_img) {
    if (payment_method === "Bank") {
      if (qr_code_img === "") {
        return dataURLtoFile(defaultImg, "bank", "png");
      } else {
        return qr_code_img;
      }
    } else if (payment_method === "PayMaya") {
      if (qr_code_img === "") {
        return dataURLtoFile(paymayaImg, "paymaya", "png");
      } else {
        return qr_code_img;
      }
    } else if (payment_method === "GCash") {
      if (qr_code_img === "") {
        return dataURLtoFile(gcashImg, "gcash", "png");
      } else {
        return qr_code_img;
      }
    } else if (payment_method === "PayPal") {
      return dataURLtoFile(payPaylImg, "paypal", "jpg");
    }
  }

  const submitPaymentMethod = async () => {
    let payment = {
      user: storeInfo ? storeInfo.owner : "",
      store: STORE_UUID,
      payment_method: paymentMethod.payment_method,
      qr_code_img: renderPaymentIcon(
        paymentMethod.payment_method,
        paymentMethod.qr_code_img
      ),
      bank_name: paymentMethod.bank_name,
      account_name: paymentMethod.account_name,
      account_number: paymentMethod.account_number,
      payment_link: paymentMethod.payment_link,
      mobile_number: paymentMethod.mobile_number
    };
    let response = await dispatch(addPaymentMethod(payment));
    try {
      if (response.status === 201) {
        setPaymentMethod({ ...newPaymentMethod, addAccount: false });
      }
    } catch {
      setPaymentMethod({ ...newPaymentMethod, addAccount: false });
      console.log(response.status);
    }
  };

  const saveEditPaymentMethod = async () => {
    let payment = {
      user: storeInfo ? storeInfo.owner : "",
      store: STORE_UUID,
      payment_method: paymentMethod.payment_method,
      qr_code_img: renderPaymentIcon(
        paymentMethod.payment_method,
        paymentMethod.qr_code_img
      ),
      bank_name: paymentMethod.bank_name,
      account_name: paymentMethod.account_name,
      account_number: paymentMethod.account_number,
      payment_link: paymentMethod.payment_link,
      mobile_number: paymentMethod.mobile_number,
      uuid: paymentMethod.uuid
    };
    let response = await dispatch(editPayment(payment));
    try {
      if (response.status === 200) {
        setPaymentMethod({ ...newPaymentMethod, editAccount: false });
      }
    } catch {
      setPaymentMethod({ ...newPaymentMethod, editAccount: false });
      console.log(response.status);
    }
  };

  function paymentsLayoutHeight() {
    if (payments.length === 0) {
      return 0;
    } else if (payments.length < 5) {
      return "57vh";
    } else if (payments.length >= 5) {
      return "65vh";
    }
  }
  return (
    <div>
      <SentroLoading open={loading} />
      <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
        <div className={classes.dialog}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                className={classes.closeBtn}
              >
                <CloseIcon style={{ color: "#f97663" }} />
              </IconButton>
              <div className={classes.header}>Payments</div>
            </Toolbar>
          </AppBar>
          <Tabs
            style={{ backgroundColor: "#fff" }}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label={
                language === "English" ? "Other Accounts" : "Ibang Accounts"
              }
              {...a11yProps(0)}
            />
            <Tab label="Bux" {...a11yProps(1)} />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <div>
                <div style={{ fontSize: 14, color: "#54575f" }}>
                  {language === "English"
                    ? "Add details of your Bank, PayMaya or PayPal account where your customers can pay. This will be displayed in your online shop."
                    : "Ilagay ang detalye ng iyong Bangko, PayMaya o Paypal account kung saan pwedeng magbayad ang iyong customers. Makikita ito sa payment instructions page kapag may bumili sa iyong shop."}
                </div>
                <div
                  style={{
                    paddingTop: "2vh",
                    overflowY: "scroll",
                    height: paymentsLayoutHeight()
                  }}
                >
                  {payments.map((item, key) => {
                    return (
                      <PaymentCard
                        item={item}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                      />
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: payments.length === 0 ? "auto" : 0,
                  display: payments
                    ? payments.length >= 5
                      ? "none"
                      : "flex"
                    : "flex"
                }}
              >
                <Button
                  id="add_account"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disableElevation
                  style={{ marginTop: "3vh" }}
                  onClick={() =>
                    setPaymentMethod({ ...paymentMethod, addAccount: true })
                  }
                >
                  {language === "English"
                    ? "Add Account"
                    : "Magdagdag ng Account"}
                </Button>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <BuxPayment language={language} />
            </TabPanel>
          </SwipeableViews>
        </div>
        <AlertDialogBox
          open={dialogOpen}
          alertType={alertMessage ? alertMessage.type : "success"}
          message={alertMessage ? alertMessage.message : ""}
        />
        <ViewAccountForm
          newPaymentMethod={newPaymentMethod}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
        <EditAccountForm
          newPaymentMethod={newPaymentMethod}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          saveEditPaymentMethod={saveEditPaymentMethod}
        />
      </Dialog>

      <AddAccountForm
        language={language}
        newPaymentMethod={newPaymentMethod}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        submitPaymentMethod={submitPaymentMethod}
      />
    </div>
  );
};

export default Payments;
