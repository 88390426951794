import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SideMenu from "../../../dashboard/sideMenu";
import history from "../../../../store/history";
import Path from "../../../../Paths";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "@material-ui/core";
import {
  getStoreInfo,
  getStore,
  patchStore
} from "../../websiteBuilderActions";
import PreviewShop from "./previewShop";
import CustomizeTemplateForm from "../components/shopStylesForm/form";
import CustomizeShopForm from "./shopStyleFormV2/index";
import TemplateConfirmationDialog from "../components/shopStylesForm/confirmationDialog";
import { showAlert } from "../../../common/commonActions";
import setDefaultImage from "../../../common/layout/mobile/webBuilder/common/setDefaultImage";
import { ReduxInitializeForm } from "../../websiteBuilderActions";
import SaveSuccessModal from "../forms/shopStyleFormV2/saveSuccessModal";
import PreviewTemplate from "./previewTemplate";
import { templates } from "../components/shopStylesForm/templateDisplayImage";
export default function ShopStylesForm(props) {
  const useStyles = makeStyles(theme => ({
    side: { zIndex: 3, display: "flex", position: "fixed", height: "100%" },
    content: {
      width: "100%",
      paddingLeft: "8vw",
      paddingRight: "8vw",
      paddingBottom: "10vh"
    }
  }));

  const dispatch = useDispatch();
  const classes = useStyles();
  const getLanguage = localStorage.getItem("language");
  const storeInfo = useSelector(state => state.webBuilder.storeInfo);
  const productList = useSelector(state => state.webBuilder.products);
  const loading = useSelector(state => state.webBuilder.loading);
  const data = useSelector(state => state.form.customizeShopForm?.values);
  const subscription = useSelector(state => state.subscription.subscription);
  const [language, setLanguage] = React.useState(getLanguage);
  const [open, setOpen] = React.useState({
    preview: false,
    dialog: false,
    customize: false,
    success_dialog: false,
    is_success: false,
    template: ""
  });
  const [values, setValues] = React.useState({});
  const [initialData, setInitalData] = React.useState({});
  const handleClosePreview = () => {
    setOpen({ ...open, preview: false });
  };

  const handleViewDemo = template => {
    // setValues({ ...values, template: template });
    setOpen({ ...open, preview: true, template: template });
  };

  const handleUseTemplate = async () => {
    const res = await dispatch(
      patchStore(
        "styles",
        storeInfo.site,
        { template: values.template },
        {
          image_crop_area: values.image_crop_area,
          image_settings: values.image_settings,
          font: values.font
        }
      )
    );

    try {
      if (res.status === 200) {
        window.scrollTo(0, 0);
        setOpen({
          ...open,
          dialog: false,
          success_dialog: true,
          is_success: true
        });
      }
    } catch (error) {
      setOpen({
        ...open,
        dialog: false,
        success_dialog: true,
        is_success: false
      });
    }
  };

  React.useEffect(() => {
    dispatch(getStoreInfo());
  }, []);

  React.useEffect(() => {
    if (storeInfo) {
      setValues({
        ...storeInfo,
        cover_image: !storeInfo.cover_image
          ? setDefaultImage(storeInfo.template, "cover_image")
          : storeInfo.cover_image,
        aboutus_image: !storeInfo.aboutus_image
          ? setDefaultImage(storeInfo.template, "aboutus_image")
          : storeInfo.aboutus_image,
        contactus_image: !storeInfo.contactus_image
          ? setDefaultImage(storeInfo.template, "contactus_image")
          : storeInfo.contactus_image
      });
    }
  }, [storeInfo]);

  React.useEffect(() => {
    if (data) {
      setValues({
        ...storeInfo,
        logo_image: data.logo_image?.length ? data?.logo_image[0] : "",
        cover_image: data.cover_image?.cropped_image,
        aboutus_image: data.aboutus_image?.cropped_image,
        contactus_image: data.contactus_image?.cropped_image,
        color: {
          primary: {
            ...data.color.primary,
            main: data.primary_background_color,
            contrastText: data.primary_text_color
          },
          secondary: {
            ...data.color.secondary,
            main: data.secondary_background_color,
            contrastText: data.secondary_text_color
          }
        },
        font: data.font
      });
    }
  }, [data]);

  React.useEffect(() => {
    setLanguage(getLanguage);
  }, [getLanguage]);

  return (
    <div
      id="shop_style"
      className="d-flex flex-wrap"
      style={{ backgroundColor: "#fafafa" }}
    >
      <div className={classes.content}>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            startIcon={<ArrowBackIcon />}
            variant="text"
            color="primary"
            onClick={() => history.push(Path.DASHBOARD)}
          >
            Back
          </Button>
          <strong style={{ fontSize: 28, color: "#002c42" }}>
            Customize template
          </strong>
          <Button
            variant="outlined"
            color="primary"
            id="preview_shop_btn"
            style={{ height: 40 }}
            disabled={loading}
            onClick={() =>
              handleViewDemo(
                templates.find(item => item.value === storeInfo.template)
              )
            }
          >
            Preview
          </Button>
        </div>
        <div style={{ marginTop: 55 }}>
          {open.customize ? (
            <CustomizeShopForm
              open={open}
              setOpen={setOpen}
              storeInfo={storeInfo}
            />
          ) : (
            <CustomizeTemplateForm
              storeInfo={storeInfo}
              values={values}
              setValues={setValues}
              open={open}
              setOpen={setOpen}
              handleViewDemo={handleViewDemo}
              handleUseTemplate={handleUseTemplate}
              subscription={subscription}
            />
          )}
        </div>
      </div>
      <PreviewTemplate open={open} setOpen={setOpen} />
      {/* <PreviewShop
        id="preview_shop"
        open={open}
        setOpen={setOpen}
        storeInfo={values}
        setValues={setValues}
        productList={productList}
        handleClosePreview={handleClosePreview}
      /> */}
      <TemplateConfirmationDialog
        open={open}
        setOpen={setOpen}
        handleUseTemplate={handleUseTemplate}
      />
      <SaveSuccessModal open={open} setOpen={setOpen} store={storeInfo} />
    </div>
  );
}
