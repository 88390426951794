import React from "react";
import { makeStyles } from "@material-ui/styles";
import Text from "../../../../../common/text";
import { TextField, Button, InputAdornment } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { changePassword } from "../webBuilder/websiteBuilderActions";
import moment from "moment";

const Password = props => {
  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    btnSection: {
      position: "absolute",
      width: "100%",
      bottom: 0,
      display: "flex",
      justifyContent: "space-between",
      padding: 20
    }
  }));
  const classes = useStyles();
  let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let dateChanged = data ? data.password_change : "";
  const [edit, setEdit] = React.useState(false);
  const [hasErrors, setHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState("");
  const [showNewPassword, setShowNewPassword] = React.useState("");
  const [password, setPassword] = React.useState({
    current_password: "",
    new_password: ""
  });

  const [errors, setErrors] = React.useState({
    currentPasswordError: "",
    newPasswordError: ""
  });

  function setPasswordChanges(value, name) {
    setPassword(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  React.useEffect(() => {
    for (let e in errors) {
      if (errors[e].length > 0) {
        setHasErrors(true);
        return;
      }
    }
    setHasErrors(false);
  }, [errors]);

  React.useEffect(() => {
    setIsFormEnough(
      password.current_password.length > 0 &&
        password.current_password.length > 8 &&
        password.new_password.length > 0 &&
        password.new_password.length > 8
    );
  });

  const handleSave = async () => {
    let response = await dispatch(changePassword(password));
    if (response.status === 400) {
      setErrors({
        ...errors,
        currentPasswordError: "Invalid Password. Please try again"
      });
    } else if (response.status === "Success") {
      setEdit(false);
    }
  };

  return (
    <div>
      {!edit ? (
        <div>
          <div style={{ fontSize: 14, color: "#54575f", padding: 20 }}>
            Your password was updated last{" "}
            {moment(dateChanged).format(`DD MMM YYYY`)}
          </div>
        </div>
      ) : (
        <div style={{ padding: 20 }}>
          <div>
            <Text textType="p3_Semibold" content="Current Password" />
            <TextField
              fullWidth
              margin="dense"
              id="password"
              color="secondary"
              variant="outlined"
              style={{ marginTop: 0 }}
              disabled={!edit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      style={{ color: "#2b2d33", fontSize: 10 }}
                      onClick={e => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? "HIDE" : "SHOW"}
                    </Button>
                  </InputAdornment>
                )
              }}
              value={password.current_password}
              onChange={value => {
                const input = value.target.value;
                setPasswordChanges(input, "current_password");
                if (input === "") {
                  setErrors({
                    ...errors,
                    currentPasswordError: "This field is required."
                  });
                } else {
                  setErrors({ ...errors, currentPasswordError: "" });
                }
              }}
              onBlur={value => {
                const input = value.target.value;
                if (input === "") {
                  setErrors({
                    ...errors,
                    currentPasswordError: "This field is required."
                  });
                } else if (
                  !input.match(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
                  )
                ) {
                  setErrors({
                    ...errors,
                    currentPasswordError:
                      "Please have at least 8 characters with a mix of numbers, letters and symbols."
                  });
                }
              }}
              error={errors.currentPasswordError}
              helperText={errors.currentPasswordError}
              type={showOldPassword ? "text" : "password"}
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <Text textType="p3_Semibold" content="New Password" />
            <TextField
              fullWidth
              margin="dense"
              id="password"
              color="secondary"
              variant="outlined"
              style={{ marginTop: 0 }}
              disabled={!edit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      style={{ color: "#2b2d33", fontSize: 10 }}
                      onClick={e => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? "HIDE" : "SHOW"}
                    </Button>
                  </InputAdornment>
                )
              }}
              value={password.new_password}
              onChange={value => {
                const input = value.target.value;
                setPasswordChanges(input, "new_password");
                if (input === "") {
                  setErrors({
                    ...errors,
                    newPasswordError: "This field is required."
                  });
                } else {
                  setErrors({ ...errors, newPasswordError: "" });
                }
              }}
              onBlur={value => {
                const input = value.target.value;
                if (input === "") {
                  setErrors({
                    ...errors,
                    newPasswordError: "This field is required."
                  });
                } else if (
                  !input.match(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
                  )
                ) {
                  setErrors({
                    ...errors,
                    newPasswordError:
                      "Please have at least 8 characters with a mix of numbers, letters and symbols."
                  });
                }
              }}
              error={errors.newPasswordError}
              helperText={errors.newPasswordError}
              type={showNewPassword ? "text" : "password"}
            />
          </div>
        </div>
      )}

      {!edit ? (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            padding: 20
          }}
        >
          <Button
            disabled={data.is_facebookLogin ? true : false}
            variant="outlined"
            fullWidth
            color="primary"
            onClick={() => {
              setEdit(true);
            }}
          >
            Edit
          </Button>
        </div>
      ) : (
        <div className={classes.btnSection}>
          <Button
            style={{ width: "45%" }}
            variant="text"
            color="secondary"
            onClick={() => setEdit(false)}
          >
            Cancel
          </Button>

          <Button
            style={{ width: "45%" }}
            variant="outlined"
            color="primary"
            disabled={hasErrors || !isFormEnough}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default Password;
