import React from "react";
import { connect } from "react-redux";
import { withStyles, TextField } from "@material-ui/core";
import SentroButton from "../../../../sentroButton";
import validation from "../../../../../common/validations";
import { patchStore } from "../websiteBuilderActions";

const useStyles = (theme) => ({
  rootBody: {
    padding: 15,
    position: "relative",
    height: "calc(100vh - 188px)",
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%",
  },
  title: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    width: "100%",
  },
  errorText: {
    fontSize: 12,
    color: "red",
  },
  closeBtn: { position: "absolute", left: 15 },
  previewButton: {
    height: 38,
  },
  formGroup: {
    margin: "15px 0",
    "& > .fieldTitle": {
      marginBottom: 0,
      fontSize: 14,
      fontWeight: 600,
      color: "#54575F",
    },
    "& > .fieldDescription": {
      fontSize: 12,
      color: "#54575F",
      marginBottom: 0,
    },
    "& > .field": {
      marginTop: 8,
    },
  },
  helperText: { position: "absolute", right: 0, textAlign: "right" },
  checkIcon: {
    textAlign: "center",
    width: "100%",
    color: "#38b99f",
    fontSize: 100,
  },
});

const FIELD_MAX_LENGTH = {
  order_instructions: 500,
};

class OrderInstructionsForm extends React.Component {
  constructor() {
    super();

    this.state = {
      errors: {
        order_instructions: false,
      },
      formIsValid: true,
      responseModalStat: false,
      responseStat: true,
    };
  }

  charactersLeft = (fieldname) => {
    const { values } = this.props;
    const fieldLength = values[fieldname] ? values[fieldname].length : 0;
    const charsRemaining = FIELD_MAX_LENGTH[fieldname] - fieldLength;
    return `${charsRemaining} characters left`;
  };

  setError = (fieldName, errorMessage) => {
    const { errors } = this.state;
    this.setState(
      {
        ...this.state,
        errors: {
          ...errors,
          [fieldName]: errorMessage,
        },
      },
      () => this.setFormValidity()
    );
  };

  setFormValidity = () => {
    const { values } = this.props;
    const { errors } = this.state;

    const errorKeys = Object.keys(errors);
    let hasError = false;

    for (let i in errorKeys) {
      if (errors[errorKeys[i]]) {
        hasError = true;
        break;
      }
    }

    const { order_instructions } = values;

    this.setState({
      ...this.state,
      errors: { ...errors },
      formIsValid: !hasError && order_instructions,
    });
  };

  handleSubmit = async () => {
    const { dispatch, setState, state, values, setValues } = this.props;

    const res = await dispatch(
      patchStore(values.site, { order_instructions: values.order_instructions })
    );
    try {
      if (res.status === 200) {
        setValues({ ...values, shop_details_form_open: false });
        setState({
          ...state,
          responseModalStat: true,
          responseStat: true,
        });
      }
    } catch {
      setState({
        ...state,
        responseModalStat: true,
        responseStat: false,
      });
    }
  };
  render() {
    const { classes, setValues, values } = this.props;
    const { errors, formIsValid } = this.state;

    return (
      <React.Fragment>
        <div className={classes.rootBody}>
          <div className={classes.formGroup}>
            <p className="fieldTitle">Put more details here</p>
            <p className="fieldDescription">
              If you have more details or instructions related to buying your
              items (ex. pre-order basis, order cut-offs, delivery schedules,
              warranties, return and/or exchange policies), you may put them
              here.
            </p>
            <TextField
              fullWidth
              multiline
              margin="dense"
              id="description"
              color="secondary"
              variant="outlined"
              className="field"
              value={values.order_instructions}
              error={errors.order_instructions && true}
              onChange={(e) => {
                const raw_input = e.target.value;
                setValues({ ...values, order_instructions: raw_input });
              }}
              rows={9}
              helperText={
                <span className={classes.helperText}>
                  {errors.order_instructions ||
                    this.charactersLeft("order_instructions")}
                </span>
              }
            />
          </div>
        </div>
        <div
          className="formGroup"
          style={{
            padding: 15,
            background: "white",
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 3,
            WebkitTransform: "translateZ(0)",
          }}
        >
          {/* <SentroButton
            disableElevation
            fullWidth
            label="Submit"
            variant="contained"
            onClick={() => this.handleSubmit()}
            disabled={!formIsValid}
          /> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => () => ({});

export default connect(mapStateToProps)(
  withStyles(useStyles, { withTheme: true })(OrderInstructionsForm)
);
