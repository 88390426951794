import React from "react";
import ImageUploading from "react-images-uploading";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import { ReactComponent as PenIcon } from "../../assets/images/enterprise/pen.svg";
import { ReactComponent as UploadIconSVG } from "../../assets/images/enterprise/upload_icon.svg";
import { ReactComponent as UploadIconWhiteSVG } from "../../assets/images/enterprise/upload_icon_white.svg";
import { ReactComponent as ImagePlaceholderSVG } from "../../assets/images/enterprise/image_placeholder.svg";
import { ReactComponent as TrashSVG } from "../../assets/images/enterprise/trash.svg";
import StockImages from "../stockImages/stockImages";
import ImageCropperModal from "./utilities/imageCropper";

export default function ImageUploader(props) {
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const primary_color = theme.palette.primary.main;
  const useStyles = makeStyles(theme => ({
    uploadImageWrapper: {
      border: `2px dashed ${primary_color}`,
      borderRadius: 4,
      cursor: "pointer",
      padding: 8
    },
    error: {
      fontSize: 12,
      color: "red"
    },
    label2: {
      fontSize: 14,
      fontWeight: 500,
      color: "rgba(43, 45, 50, 0.8)"
    },
    optionButtons: {
      fontWeight: "500",
      fontSize: 12,
      cursor: "pointer",
      color: primary_color
    },
    disabled: {
      color: "rgba(0,0,0,0.3) !important"
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const maxNumber = 69;

  // ImageUploading structure <array>
  // [
  //   {data_url: <str>, file: <File>},
  //   {data_url: <str>, file: <File>}
  // ]
  const [images, setImages] = React.useState([]);

  // LOADING INITIAL STATE; IF THERES EXISTING DATA
  React.useEffect(() => {
    // converting to ImageUploading structure
    if (props.input.value.cropped_image) {
      setImages([
        {
          data_url: props.input.value.cropped_image
        }
      ]);
    }
  }, [props.input.value]);

  // ONCHANGE
  const onChange = async (imageList, addUpdateIndex) => {
    const img = imageList.length ? imageList[0].file : "";
    setImages(imageList);
    imageList = imageList.map(item => item.file || item.data_url);
    if (props.input.onChange)
      props.input.onChange({
        cropped_image: img,
        raw_image: img
      });
  };

  // STOCK IMAGE SELECTION
  const onStockImageSelect = data => {
    setImages([data]);
    if (props.input.onChange)
      props.input.onChange({
        cropped_image: data.file,
        raw_image: data.file
      });
  };

  // CROPPER
  // crop the original photo
  const raw_image = props.input.value?.raw_image ?? "";
  const imageForCropping =
    typeof raw_image === "object" ? URL.createObjectURL(raw_image) : raw_image;
  const cropperOnSubmit = croppedFile => {
    setImages([
      {
        data_url: URL.createObjectURL(croppedFile),
        file: croppedFile
      }
    ]);
    if (props.input.onChange)
      props.input.onChange({
        cropped_image: croppedFile,
        raw_image: props.input.value.raw_image
      });
  };

  // OPTIONS - Use stock photo, Adjust image and Remove image
  const optionsColor = images.length ? primary_color : "rgba(0,0,0,0.3)";
  const optionsHeader = removeImgFunction => (
    <div className="d-flex align-items-center mt-3 mb-1">
      <p
        className={`${classes.label2} mb-0 d-none d-md-block large-image-field-label`}
      >
        {props.label}
      </p>
      <div
        className={`d-flex ml-0 ml-md-auto ${
          is_mobile ? "justify-content-center w-100" : ""
        }`}
      >
        {props.useStockPhoto ? (
          <StockImages
            color="rgba(43, 45, 50, 0.8)"
            onSelect={onStockImageSelect}
          >
            {setOpen => (
              <div
                className={`d-flex align-items-center ${classes.optionButtons} large-image-field-stock-photo-button`}
                onClick={() => setOpen(true)}
              >
                <ImagePlaceholderSVG
                  height="15"
                  width="15"
                  className="mb-0"
                  fill={primary_color}
                />
                <p className="mb-0 ml-1">
                  <strong>Use stock photo</strong>
                </p>
              </div>
            )}
          </StockImages>
        ) : (
          ""
        )}
        {props.useCropper ? (
          <ImageCropperModal
            image={imageForCropping}
            defaultCropperOrientation="landscape"
            onSubmit={cropperOnSubmit}
          >
            {setOpen => (
              <div
                className={`d-flex align-items-center ml-3 ${
                  classes.optionButtons
                } large-image-field-cropper-button
                ${images.length ? "" : classes.disabled}`}
                onClick={() => {
                  if (images.length) setOpen(true);
                }}
              >
                <PenIcon height="17" width="17" fill={optionsColor} />
                <p className="mb-0 ml-1">
                  <strong>Adjust Image</strong>
                </p>
              </div>
            )}
          </ImageCropperModal>
        ) : (
          ""
        )}
        <div
          className={`align-items-center ml-3 d-none d-md-flex large-image-field-remove-button
          ${classes.optionButtons} ${images.length ? "" : classes.disabled} `}
          id={`iconRemoveAll${props.name}`}
          onClick={removeImgFunction}
        >
          <TrashSVG height="15" width="15" fill={optionsColor} />
          <p className="mb-0 ml-1">
            <strong>Remove image</strong>
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="App">
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "png"]}
        maxFileSize={props.maxSizeValue || 5000000}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => (
          <>
            {!is_mobile ? optionsHeader(onImageRemoveAll) : ""}
            <p className={`${classes.label2} mb-2 d-block d-md-none`}>
              {props.label}
            </p>
            {/* image field */}
            <div
              className={`${classes.uploadImageWrapper} upload__image-wrapper large-image-field-field`}
              onClick={onImageUpload}
              {...dragProps}
              style={{
                height: props.height || 115
              }}
            >
              <div
                className="position-relative w-100 h-100 d-flex w-100 align-items-center
              justify-content-center"
              >
                {isDragging ? (
                  <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                  />
                ) : (
                  ""
                )}
                {imageList.length ? (
                  <div
                    className="w-100 h-100"
                    style={{
                      backgroundImage: `url(${imageList[0]["data_url"]})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center"
                    }}
                  ></div>
                ) : (
                  <div className="text-center" style={{ maxWidth: 300 }}>
                    <UploadIconSVG
                      height="33"
                      width="33"
                      className="mb-3"
                      fill={theme.palette.primary.main}
                    />
                    <p className="mb-1" style={{ fontSize: 14 }}>
                      Click or drag file to this area to upload
                    </p>
                    <p className="text-secondary mb-1" style={{ fontSize: 10 }}>
                      {props.prompt ||
                        `For best results, upload pictures below ${props.maxSize ||
                          "5 MB"} and in landscape format. (above 800x600 pixels)`}{" "}
                    </p>
                  </div>
                )}
                {imageList.length ? (
                  <div
                    className="position-absolute d-flex flex-column align-items-center h-100 w-100"
                    style={{
                      backgroundColor: "rgba(0,0,0,0.2)"
                    }}
                  >
                    <UploadIconWhiteSVG
                      height="33"
                      width="33"
                      className="mb-1 mt-auto"
                    />
                    <p
                      className="mt-2 text-white mb-auto"
                      style={{ fontSize: 14 }}
                    >
                      Click to replace image
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {is_mobile ? optionsHeader(onImageRemoveAll) : ""}
            {errors && (
              <div className={`${classes.error} ml-2 mt-2`}>
                {errors.acceptType && (
                  <span>Your selected file type is not allowed</span>
                )}
                {errors.maxFileSize && (
                  <span>
                    Image is too big. Maximum allowed size is{" "}
                    {props.maxSize || "5 MB"}.
                  </span>
                )}
              </div>
            )}
          </>
        )}
      </ImageUploading>
    </div>
  );
}
