import React from "react";
import OrderDeliveryForm from "./OrderDeliveryForm";
import DashboardLayout from "../dashboard/dashboardLayout";
import { Paper } from "@material-ui/core";
import { LocalShipping, ArrowBack } from "@material-ui/icons";
import SentroButton from "../common/sentroButton";
import history from "../../store/history";
import Path from "../../Paths";
import { makeStyles } from "@material-ui/styles";
import SentroLoading from "../common/loading";
import SideMenu from "../dashboard/sideMenu";

let OrderDeliveryRequestView = props => {
  const [open, setOpen] = React.useState(false);
  const getLanguage = localStorage.getItem("language");
  let [language, setLanguage] = React.useState(getLanguage);
  // let selectedOrder = useSelector(state => state.orders.selectedOrder)
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      backgroundColor: "#FAFAFA"
    },
    side: {
      zIndex: 3,
      display: "flex",
      position: "fixed",
      height: "100%"
    },
    content: {
      width: "100%",
      paddingLeft: "10vw",
      paddingRight: "10vw",
      paddingBottom: "10vh"
    },
    rootWrap: {
      maxWidth: 700,
      margin: "auto"
    }
  }));
  const classes = useStyles();

  React.useEffect(() => {
    setLanguage(getLanguage);
  }, [getLanguage]);
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.rootWrap}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative"
            }}
          >
            <SentroButton
              color="primary"
              onClick={() => history.push(Path.ORDER_DETAILS_VIEW)}
              startIcon={<ArrowBack color="primary" />}
              style={{ fontSize: 14, position: "absolute" }}
              label={"BACK"}
            />
            <div
              style={{
                fontSize: 28,
                color: "#002c42",
                fontWeight: 600,
                textAlign: "center",
                width: "100%"
              }}
            >
              Book for Delivery
            </div>
          </div>
          <OrderDeliveryForm language={language} />
        </div>
      </div>
    </div>
  );
};

export default OrderDeliveryRequestView;
