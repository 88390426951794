import { makeStyles } from "@material-ui/core";
const ProductDetailStyles = makeStyles(theme => ({
  root: {
    marginTop: "10vh",
    marginLeft: "7vw"
  },
  content: {
    marginTop: "4vh"
  },

  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingLeft: 0
    }
  },
  product: {
    marginLeft: "1vw",
    padding: theme.spacing(2, 2),
    width: "34%",
    border: "1px solid #909196",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 1)
      }
    }
  },
  image: {
    height: "350px",
    [theme.breakpoints.down("sm")]: {
      height: 300
    }
  },
  btnBuyNow: {
    backgroundColor: "#F97663",
    width: "250px",
    height: "7vh",
    color: "#fff",
    fontWeight: "600",
    marginTop: "2vh",
    fontSize: "12px",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    }
  },
  btnVisit: {
    backgroundColor: "#fff",
    width: "250px",
    height: "7vh",
    color: "#F97663",
    fontWeight: "600",
    marginLeft: "2vw",
    marginTop: "2vh",
    fontSize: "12px",
    border: "1px solid #F97663",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "90%"
    }
  },
  btnSend: {
    backgroundColor: "#F97663",
    width: "100%",
    height: "7vh",
    color: "#fff",
    fontWeight: "600",
    marginTop: "3vh",
    fontSize: "12px",
    boxShadow: "none"
  },

  contact: {
    flexGrow: 1,
    border: "1px solid #F97663",
    padding: theme.spacing(5, 5),
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2vh"
    }
  }
}));

export default ProductDetailStyles;
