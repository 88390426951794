import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  Link,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Modal
} from "@material-ui/core";
// import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { DeleteForeverTwoTone, ExpandMore } from "@material-ui/icons";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "../../store/history";
// import StoreActions from "./StoreActions";
import Utils from "../common/utils";
import validations from "../common/validations";
// import AppBar from "../common/components/AppBar";
import Footer from "../common/template/components/Footer";
import SentroLoading from "../common/loading";
import { ADDRESS } from "../common/template/components/constants/address";
import defaultSRC from "../common/template/components/defaultResources";
import defaultColor from "../common/template/components/colors";
import SentroButton from "../common/sentroButton";
import SentroAlert from "../common/alert";
import Alert from '@material-ui/lab/Alert';
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll'
import Path from "../../Paths";
import Actions from "./Redux/Actions";
const MarketplaceCheckout = props => {
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/delivery", "");
  const storeName = param.replace("/shop/", "");
  let storeInfo = useSelector(state => state.store.store);
  let color = storeInfo.color === "null" ? null : storeInfo.color;
  let selectedColor = color
    ? color.includes("/")
      ? defaultColor[storeInfo ? color : "funBlue"].colorA
      : defaultColor[storeInfo ? color : "funBlue"].color
    : defaultSRC[storeInfo ? storeInfo.template : "Classic"].headerColor;

  let buttonColor = color
    ? color.includes("/")
      ? defaultColor[storeInfo ? color : "funBlue"].colorB
      : defaultColor[storeInfo ? color : "funBlue"].color
    : defaultSRC[storeInfo ? storeInfo.template : "Classic"].buttonColor;
  const CartStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "#FAFAFA",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: 60,
        display: "none"
      }
    },
    paper: {
      width: 510,
      position: "absolute",
      backgroundColor: "#fff",
      borderRadius: "4px",
      padding: theme.spacing(4, 6, 4),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: theme.spacing(1, 1, 1)
      }
    },
    content1: {
      height: "100vh",
      width: "100%"
    },
    cart: {
      padding: "48px 0",
      [theme.breakpoints.down("sm")]: {
        padding: 25
      }
    },
    header: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },

    total: {
      flexGrow: 1,
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        marginTop: 20,
        display: "block"
      }
    },

    totalContent: {
      display: "flex",
      marginRight: 20,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 20
      }
    },
    cartTable: {
      flex: 7,
      marginRight: 20,
      [theme.breakpoints.down("md")]: {
        display: "block",
        marginRight: 0
      }
    },

    cartContent: {
      marginTop: "6vh",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    delivery: {
      margin: "3em 4em",

      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    deliveryField: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    summaryContainer: {
      flex: 3,
      [theme.breakpoints.down("sm")]: {
        padding: "0px 100px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0
      }
    },
    summary: {
      minWidth: "20vw",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 8,
      [theme.breakpoints.down("sm")]: {
        marginTop: "5vh",
        display: "none"
      }
    },

    summaryContent: {
      padding: 20,
      [theme.breakpoints.down("sm")]: {
        padding: 50
      },
      [theme.breakpoints.down("xs")]: {
        padding: 20
      }
    },

    productImage: {
      width: 90,
      height: 90,

      [theme.breakpoints.down("md")]: {
        width: 60,
        height: 60
      }
    },
    mobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginTop: 53,
        backgroundColor: "#FAFAFA"
      }
    },
    summaryMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "1vh 120px"
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
        margin: "0vh 0px 5vh",
        padding: 0
      }
    },
    deliveryDesktop: {
      borderRadius: 8,
      width: "62vw",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      padding: 30,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    deliveryMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "5vh 120px"
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
        margin: "5vh 0px"
      }
    },
    modalText: {
      color: selectedColor,
      fontWeight: "bold",
      fontSize: 28,
      color: "#002C42",
      [theme.breakpoints.down("xs")]: {
        fontSize: 26
      }
    },
    buttonCheckout: {
      width: 300,
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    backDivWrapper: {
      margin: "0 0 1em",
      display: "flex",
      "& > span": {
        marginLeft: "1em",
        fontWeight: 600
      },
      color: "#F97663 !important",
      alignItems: "center",
      cursor: "pointer",
      width: 100
    },
  }));

  const classes = CartStyles();
  const dispatch = useDispatch();

  let loading = useSelector(state => state.marketplace.loading);
  let productList = useSelector(state => state.marketplace.cart);
  let kyc = useSelector(state => state.marketplace.kyc);
  const shippingFee = 0; // #TODO useSelector(state => state.store.shipping_fee);
  let id = useSelector(state => state.store.store);
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [streetAddress, setStreetAddress] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [barangayAddress, setBarangayAddress] = React.useState("");
  const [cityAddress, setCityAddress] = React.useState("");
  const [provinceAddress, setProvinceAddress] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [errors, setErrors] = React.useState({
    firstNameError: "",
    lastNameError: "",
    mobileError: "",
    emailError: ""
  });

  React.useEffect(() => {
    dispatch(Actions.checkKYC())
    scroll.scrollTo(0)
    if (productList.length === 0) {
      history.push(Path.APP_MARKETPLACE);
    }
  }, []);


  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    checkOut();
    setOpen(false);
  };

  let totalAmt = parseFloat(
    productList.reduce((total, object) => {
      return total + object.unit_price * object.quantity;
    }, 0)
  );
  let amount = totalAmt;
  const kycError = kyc ? 
        kyc.cashin_limits ? 
          String(kyc.cashin_limits[1]) === "null" ? 
          false
          : totalAmt+kyc.cashin_limits[0] > kyc.cashin_limits[1] && true 
        : true
    : true

  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [errors]);

  React.useEffect(() => {
    setIsFormEnough(
      firstName.length > 0 &&
        lastName.length > 0 &&
        email.length > 0 &&
        streetAddress.length > 0 &&
        barangayAddress.length > 0 &&
        cityAddress.length > 0 &&
        provinceAddress.length > 0 &&
        mobileNumber.length > 0
    );
  });

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  function goToBuxModal() {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div style={modalStyle} className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 10
              }}
            >
              <div onClick={() => setOpen(false)} style={{ cursor: "pointer" }}>
                <CloseIcon />
              </div>
            </div>

            <h2 className={classes.modalText}>
              We will now proceed with payment processing
            </h2>
            <div id="simple-modal-description">
              <div>This page will redirect to Bux for payment processing.</div>
              <Box style={{ marginTop: 30, display: " flex" }}>
                <Button
                  variant="contained"
                  onClick={handleOpen}
                  color="primary"
                  style={{ width: "100%" }}
                > OK </Button>
              </Box>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  function checkOut(){
    let description = "";
    productList.map(item => {
      description += item.quantity + "x " + item.app_name + "(Variant: " + item.variant_name + ")\n"
    });

    let data = {
      site: storeInfo.site,
      full_name: firstName + " " + lastName,
      address: streetAddress + ", " + barangayAddress + ", " + cityAddress +
              ", " + provinceAddress + ", " + postalCode,
      mobile_number: mobileNumber,
      amount: totalAmt,
      other_fees: 0,
      subtotal: totalAmt,
      recipient: email,
      uuid: storeInfo.uuid,
      description,
      cart: productList
    };

    dispatch(Actions.marketplaceCheckout(data))
  }

  let errorOpen = useSelector(state => state.store.dialogOpen);
  let errorMessage = useSelector(state => state.store.message);

  function getProvinces() {
    let provinces = [];
    for (var i in ADDRESS) {
      provinces.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return provinces;
  }
  function getCities() {
    let cities = [];
    if (provinceAddress in ADDRESS) {
      for (var i in ADDRESS[provinceAddress]["Municipality_list"]) {
        cities.push(<MenuItem value={i}>{i}</MenuItem>);
      }
    }
    return cities;
  }
  function getBarangay() {
    let baranggays = [];
    if (provinceAddress in ADDRESS) {
      if (cityAddress in ADDRESS[provinceAddress]["Municipality_list"]) {
        let baranggay_list =
          ADDRESS[provinceAddress]["Municipality_list"][cityAddress][
            "Barangay_list"
          ];
        for (var i = 0; i < baranggay_list.length; i++) {
          baranggays.push(
            <MenuItem value={baranggay_list[i]}>{baranggay_list[i]}</MenuItem>
          );
        }
      }
    }
    return baranggays;
  }

  const handleProvinceAddress = event => {
    setProvinceAddress(event.target.value);
    setCityAddress("");
    setBarangayAddress("");
  };

  const handleCityAddress = event => {
    setCityAddress(event.target.value);
    setBarangayAddress("");
  };

  const handleBarangayAddress = event => {
    setBarangayAddress(event.target.value);
  };

  let secondaryFont =
    defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont;

  function renderDelivery() {
    return (
      <div>
        <Paper className={classes.deliveryDesktop}>
          <Typography
            variant="h4"
            style={{
            fontSize: 26,
            color: "#002C42",
            fontWeight: 600,
            paddingBottom: "0.5em",
            borderBottom: "solid 1px rgb(209, 213, 221)"
            }}
          >
            Customer Details
          </Typography>
          <div className={classes.delivery}>
            <div style={{ marginTop: "3vh" }}>
              <div style={{ width: "100%" }}>
                <div className={classes.deliveryField}>
                  <div
                    style={{ width: "100%", marginTop: 10, marginRight: 30 }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#212B36",
                        fontFamily: secondaryFont
                      }}
                    >
                      First name
                    </Typography>
                    <TextField
                      id="first_name"
                      color="secondary"
                      value={firstName}
                      onChange={value => {
                        setFirstName(value.target.value);
                        if (value.target.value === "") {
                          setErrors({
                            ...errors,
                            firstNameError: "This field is required."
                          });
                        } else {
                          setErrors({
                            ...errors,
                            firstNameError: ""
                          });
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      onBlur={value => {
                        if (value.target.value === "") {
                          setErrors({
                            ...errors,
                            firstNameError: "This field is required."
                          });
                        }
                      }}
                      error={errors.firstNameError}
                      helperText={errors.firstNameError}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      marginTop: 10
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#212B36",
                        fontFamily: secondaryFont
                      }}
                    >
                      Last name
                    </Typography>
                    <TextField
                      id="last_name"
                      color="secondary"
                      value={lastName}
                      onChange={value => {
                        setLastName(value.target.value);
                        if (value.target.value === "") {
                          setErrors({
                            ...errors,
                            lastNameError: "This field is required."
                          });
                        } else {
                          setErrors({
                            ...errors,
                            lastNameError: ""
                          });
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      onBlur={value => {
                        if (value.target.value === "") {
                          setErrors({
                            ...errors,
                            lastNameError: "This field is required."
                          });
                        }
                      }}
                      error={errors.lastNameError}
                      helperText={errors.lastNameError}
                    />
                  </div>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <div className={classes.deliveryField}>
                  <div style={{ width: "100%", marginTop: 10 }}>
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#212B36",
                        fontFamily: secondaryFont
                      }}
                    >
                      Street Address
                    </Typography>
                    <TextField
                      id="street_address"
                      color="secondary"
                      fullWidth
                      variant="outlined"
                      value={streetAddress}
                      onChange={value => setStreetAddress(value.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <div className={classes.deliveryField}>
                  <div
                    style={{ width: "100%", marginTop: 10, marginRight: 30 }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#212B36",
                        fontFamily: secondaryFont
                      }}
                    >
                      Province
                    </Typography>
                    <FormControl style={{ width: "100%" }} variant="outlined">
                      <Select
                        id="province"
                        color="secondary"
                        value={provinceAddress}
                        onChange={handleProvinceAddress}
                      >
                        {getProvinces()}
                      </Select>
                    </FormControl>
                  </div>

                  <div style={{ width: "100%", marginTop: 10 }}>
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#212B36",
                        fontFamily: secondaryFont
                      }}
                    >
                      City
                    </Typography>
                    <FormControl
                      style={{ width: "100%" }}
                      variant="outlined"
                      disabled={
                        provinceAddress
                          ? provinceAddress == ""
                            ? true
                            : false
                          : true
                      }
                    >
                      <Select
                        id="city"
                        color="secondary"
                        value={cityAddress}
                        onChange={handleCityAddress}
                      >
                        {getCities()}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <div className={classes.deliveryField}>
                  <div
                    style={{ width: "100%", marginTop: 10, marginRight: 30 }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#212B36",
                        fontFamily: secondaryFont
                      }}
                    >
                      Barangay
                    </Typography>
                    <FormControl
                      style={{ width: "100%" }}
                      variant="outlined"
                      disabled={
                        cityAddress ? (cityAddress == "" ? true : false) : true
                      }
                    >
                      <Select
                        id="barangay"
                        color="secondary"
                        value={barangayAddress}
                        onChange={handleBarangayAddress}
                      >
                        {getBarangay()}
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      marginTop: 10
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#212B36",
                        fontFamily: secondaryFont
                      }}
                    >
                      Postal / ZIP code (optional)
                    </Typography>
                    <TextField
                      id="postal_code"
                      color="secondary"
                      fullWidth
                      variant="outlined"
                      value={postalCode}
                      onChange={value => {
                        const raw_input = value.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        setPostalCode(raw_input);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <div className={classes.deliveryField}>
                  <div
                    style={{ width: "100%", marginTop: 10, marginRight: 30 }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#212B36",
                        fontFamily: secondaryFont
                      }}
                    >
                      Mobile Number
                    </Typography>
                    <TextField
                      id="mobile_number"
                      color="secondary"
                      value={mobileNumber}
                      inputProps={{
                        maxLength: 10
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              style={{
                                fontWeight: 600,
                                color: "rgba(0, 0, 0, 0.54)",
                                paddingRight: "8px"
                              }}
                            >
                              +63
                            </Typography>
                          </InputAdornment>
                        )
                      }}
                      onChange={value => {
                        const raw_input = value.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        setMobileNumber(raw_input);

                        if (raw_input === "") {
                          setErrors({
                            ...errors,
                            mobileError: "This field is required."
                          });
                        } else {
                          setErrors({ ...errors, mobileError: "" });
                        }
                      }}
                      onBlur={value => {
                        if (value.target.value === "") {
                          setErrors({
                            ...errors,
                            mobileError: "This field is required."
                          });
                        } else if (value.target.value.length < 10) {
                          setErrors({
                            ...errors,
                            mobileError:
                              "Please enter a valid 10-digit mobile number."
                          });
                        } else if (value.target.value.charAt(0) != 9) {
                          setErrors({
                            ...errors,
                            mobileError:
                              "You have entered an invalid mobile number"
                          });
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      error={errors.mobileError}
                      helperText={errors.mobileError}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      marginTop: 10
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#212B36",
                        fontFamily: secondaryFont
                      }}
                    >
                      Email Address
                    </Typography>
                    <TextField
                      id="email"
                      color="secondary"
                      value={email}
                      onChange={value => {
                        if (!validations.email(value.target.value)) {
                          setErrors({
                            ...errors,
                            emailError: ""
                          });
                        }
                        setEmail(value.target.value);
                      }}
                      onBlur={value => {
                        if (validations.email(value.target.value)) {
                          setErrors({
                            ...errors,
                            emailError: "Please enter a valid email address"
                          });
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      error={errors.emailError}
                      helperText={errors.emailError}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                padding: "25px 20px 0px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                id="checkout_button"
                onClick={() => amount > 0 ? setOpen(true): checkOut() }
                disabled={
                  !isFormEnough ||
                  formHasErrors ||
                  (kyc == 0 && amount > 2000) ||
                  amount > 30000 || kycError
                }
                className={classes.buttonCheckout}
                variant="contained"
                color="primary"
              >
                Proceed{amount > 0 ? ` to payment options` : ``}
              </Button>
              {goToBuxModal()}
            </div>
          </div>
        </Paper>
        <div className={classes.deliveryMobile}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              style={{ borderBottom: "1px solid #D1D5DD" }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                style={{
                  fontSize: 20,
                  color: "#002C42",
                  fontWeight: 600,
                }}
              >
                Customer Details
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ borderBottom: "1px solid #D1D5DD" }}
            >
              <div style={{ display: "block", width: "100%" }}>
                <div style={{ marginTop: "3vh", width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "block" }}>
                      <div
                        style={{
                          width: "100%",
                          marginTop: 10,
                          marginRight: 30
                        }}
                      >
                        <Typography style={{ fontSize: 12, color: "#212B36" }}>
                          First name
                        </Typography>
                        <TextField
                          id="first_name"
                          color="secondary"
                          size="small"
                          value={firstName}
                          onChange={value => {
                            setFirstName(value.target.value);
                            if (value.target.value === "") {
                              setErrors({
                                ...errors,
                                firstNameError: "This field is required."
                              });
                            } else {
                              setErrors({
                                ...errors,
                                firstNameError: ""
                              });
                            }
                          }}
                          fullWidth
                          variant="outlined"
                          onBlur={value => {
                            if (value.target.value === "") {
                              setErrors({
                                ...errors,
                                firstNameError: "This field is required."
                              });
                            }
                          }}
                          error={errors.firstNameError}
                          helperText={errors.firstNameError}
                        />
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginTop: 10
                        }}
                      >
                        <Typography style={{ fontSize: 12, color: "#212B36" }}>
                          Last name
                        </Typography>
                        <TextField
                          id="last_name"
                          color="secondary"
                          value={lastName}
                          onChange={value => {
                            setLastName(value.target.value);
                            if (value.target.value === "") {
                              setErrors({
                                ...errors,
                                lastNameError: "This field is required."
                              });
                            } else {
                              setErrors({
                                ...errors,
                                lastNameError: ""
                              });
                            }
                          }}
                          fullWidth
                          variant="outlined"
                          onBlur={value => {
                            if (value.target.value === "") {
                              setErrors({
                                ...errors,
                                lastNameError: "This field is required."
                              });
                            }
                          }}
                          error={errors.lastNameError}
                          helperText={errors.lastNameError}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div style={{ display: "block" }}>
                      <div style={{ width: "100%", marginTop: 10 }}>
                        <Typography style={{ fontSize: 12, color: "#212B36" }}>
                          Street Address
                        </Typography>
                        <TextField
                          id="street_address"
                          color="secondary"
                          fullWidth
                          variant="outlined"
                          value={streetAddress}
                          onChange={value =>
                            setStreetAddress(value.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div style={{ display: "block" }}>
                      <div
                        style={{
                          width: "100%",
                          marginTop: 10,
                          marginRight: 30
                        }}
                      >
                        <Typography style={{ fontSize: 12, color: "#212B36" }}>
                          Province
                        </Typography>
                        <FormControl
                          style={{ width: "100%" }}
                          variant="outlined"
                        >
                          <Select
                            id="province"
                            color="secondary"
                            value={provinceAddress}
                            onChange={handleProvinceAddress}
                          >
                            {getProvinces()}
                          </Select>
                        </FormControl>
                      </div>

                      <div style={{ width: "100%", marginTop: 10 }}>
                        <Typography style={{ fontSize: 12, color: "#212B36" }}>
                          City
                        </Typography>
                        <FormControl
                          style={{ width: "100%" }}
                          variant="outlined"
                          disabled={
                            provinceAddress
                              ? provinceAddress == ""
                                ? true
                                : false
                              : true
                          }
                        >
                          <Select
                            id="city"
                            color="secondary"
                            value={cityAddress}
                            onChange={handleCityAddress}
                          >
                            {getCities()}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div style={{ display: "block" }}>
                      <div
                        style={{
                          width: "100%",
                          marginTop: 10,
                          marginRight: 30
                        }}
                      >
                        <Typography style={{ fontSize: 12, color: "#212B36" }}>
                          Barangay
                        </Typography>
                        <FormControl
                          style={{ width: "100%" }}
                          variant="outlined"
                          disabled={
                            cityAddress
                              ? cityAddress == ""
                                ? true
                                : false
                              : true
                          }
                        >
                          <Select
                            id="barangay"
                            color="secondary"
                            value={barangayAddress}
                            onChange={handleBarangayAddress}
                          >
                            {getBarangay()}
                          </Select>
                        </FormControl>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginTop: 10
                        }}
                      >
                        <Typography style={{ fontSize: 12, color: "#212B36" }}>
                          Postal / ZIP code (optional)
                        </Typography>
                        <TextField
                          id="postal_code"
                          color="secondary"
                          fullWidth
                          variant="outlined"
                          value={postalCode}
                          onChange={value => {
                            const raw_input = value.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            setPostalCode(raw_input);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div style={{ display: "block" }}>
                      <div
                        style={{
                          width: "100%",
                          marginTop: 10,
                          marginRight: 30
                        }}
                      >
                        <Typography style={{ fontSize: 12, color: "#212B36" }}>
                          Mobile Number
                        </Typography>
                        <TextField
                          id="mobile_number"
                          color="secondary"
                          value={mobileNumber}
                          inputProps={{
                            maxLength: 10
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  style={{
                                    fontWeight: 600,
                                    color: "rgba(0, 0, 0, 0.54)",
                                    paddingRight: "8px"
                                  }}
                                >
                                  +63
                                </Typography>
                              </InputAdornment>
                            )
                          }}
                          onChange={value => {
                            const raw_input = value.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            setMobileNumber(raw_input);

                            if (raw_input === "") {
                              setErrors({
                                ...errors,
                                mobileError: "This field is required."
                              });
                            } else {
                              setErrors({ ...errors, mobileError: "" });
                            }
                          }}
                          onBlur={value => {
                            if (value.target.value === "") {
                              setErrors({
                                ...errors,
                                mobileError: "This field is required."
                              });
                            } else if (value.target.value.length < 10) {
                              setErrors({
                                ...errors,
                                mobileError:
                                  "Please enter a valid 10-digit mobile number."
                              });
                            } else if (value.target.value.charAt(0) != 9) {
                              setErrors({
                                ...errors,
                                mobileError:
                                  "You have entered an invalid mobile number"
                              });
                            }
                          }}
                          fullWidth
                          variant="outlined"
                          error={errors.mobileError}
                          helperText={errors.mobileError}
                        />
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginTop: 10
                        }}
                      >
                        <Typography style={{ fontSize: 12, color: "#212B36" }}>
                          Email Address
                        </Typography>
                        <TextField
                          id="email"
                          color="secondary"
                          value={email}
                          onChange={value => {
                            if (!validations.email(value.target.value)) {
                              setErrors({
                                ...errors,
                                emailError: ""
                              });
                            }
                            setEmail(value.target.value);
                          }}
                          onBlur={value => {
                            if (validations.email(value.target.value)) {
                              setErrors({
                                ...errors,
                                emailError: "Please enter a valid email address"
                              });
                            }
                          }}
                          fullWidth
                          variant="outlined"
                          error={errors.emailError}
                          helperText={errors.emailError}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    );
  }

  function renderSummary() {
    return (
      <div>
        <Paper variant="outlined" className={classes.summary}>
          <Box className={classes.summaryContent}>
            <Typography
                variant="h4"
                style={{
                fontSize: 26,
                color: "#002C42",
                fontWeight: 600,
                }}
            >
                Summary
            </Typography>

            <Box style={{ marginTop: 20 }}>
              {productList.map((row, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      borderTop: "1px solid #D1D5DD",
                      borderBottom: "1px solid #D1D5DD",
                      padding: 15
                    }}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        {row.quantity}
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ display: "block" }}>
                          <p
                            style={{ wordWrap: "break-word", fontWeight: 600 }}
                          >
                            {row.app_name} <span style={{ fontWeight: 400 }}> - {row.variant_name}</span>
                          </p>
                          <p>₱ {Utils.commafy(parseFloat(row.unit_price))}</p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        ₱ {Utils.commafy(parseFloat(row.quantity * row.unit_price))}
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
              <div
                style={{
                  padding: "10px 0",
                  borderBottom: "1px dashed #D1D5DD"
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily:
                          defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                            .secondaryFont
                      }}
                    >
                      Items:{" "}
                      {parseFloat(
                        productList.reduce((total, object) => {
                          return (
                            parseFloat(total) + parseFloat(object.quantity)
                          );
                        }, 0)
                      )}
                    </Typography>
                  </div>
                </div>
              </div>

              <div
                style={{
                  padding: "10px 0",
                  borderBottom: "1px dashed #D1D5DD"
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ flexGrow: 1 }}>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily:
                          defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                            .secondaryFont
                      }}
                    >
                      Subtotal
                    </Typography>
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily:
                          defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                            .secondaryFont
                      }}
                    >
                      ₱{" "}
                      {Utils.commafy(
                        parseFloat(
                          productList.reduce((total, object) => {
                            return (
                              parseFloat(total) +
                              parseFloat(object.quantity * object.unit_price)
                            );
                          }, 0)
                        )
                      )}
                    </Typography>
                  </div>
                </div>
              </div>

              <div
                style={{
                  padding: "15px 0 0",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#2B2D33",
                    fontFamily:
                      defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                        .secondaryFont
                  }}
                >
                  Total
                </Typography>

                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#2B2D33",
                    fontFamily:
                      defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                        .secondaryFont
                  }}
                >
                  ₱ {Utils.commafy(totalAmt)}
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <div className={classes.summaryMobile}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              style={{ borderBottom: "1px solid #D1D5DD" }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                style={{
                  fontSize: 20,
                  color: "#002C42",
                  fontWeight: 600,
                }}
              >
                Summary
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: "block" }}>
              {productList && productList.length > 0 ? (
                productList.map((row, key) => (
                  <div
                    style={{ borderBottom: "1px solid #D1D5DD", width: "100%" }}
                  >
                    <div
                      style={{
                        padding: "10px 0",
                        width: "100%",
                        display: "block"
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Grid container spacing={0}>
                          <Grid item xs={2} style={{ fontSize: 16 }}>
                            {row.quantity}
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            style={{ fontSize: 16, fontWeight: "bold" }}
                          >
                           {row.app_name} <span style={{ fontWeight: 400 }}>- {row.variant_name} </span>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            style={{
                              fontSize: 16,
                              display: "flex",
                              justifyContent: "flex-end"
                            }}
                          >
                            ₱{" "}
                            {Utils.commafy(
                              parseFloat(row.quantity * row.unit_price)
                            )}
                          </Grid>
                        </Grid>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Grid container spacing={0}>
                          <Grid item xs={2}></Grid>
                          <Grid
                            item
                            xs={5}
                            style={{ fontSize: 16, marginTop: "1vh" }}
                          >
                            ₱ {Utils.commafy(parseFloat(row.unit_price))}
                          </Grid>
                          {/* <Grid
                            item
                            xs={5}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end"
                            }}
                          >
                            <Button
                              id="remove_item"
                              style={{ margin: 0, padding: 0 }}
                              onClick={() => removeFromCart(key)}
                            >
                              <DeleteForeverTwoTone color="primary" />
                            </Button>
                          </Grid> */}
                        </Grid>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <div
                    style={{
                      padding: "10px 0",
                      width: "100%",
                      textAlign: "center",
                      fontFamily: secondaryFont
                    }}
                  >
                    No item(s) in your cart
                  </div>
                </div>
              )}

              <div
                style={{
                  padding: "10px 0",
                  borderBottom: "1px dashed #D1D5DD"
                }}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#54575F",
                    fontFamily:
                      defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                        .secondaryFont
                  }}
                >
                  Items:{" "}
                  {parseFloat(
                    productList.reduce((total, object) => {
                      return parseFloat(total) + parseFloat(object.quantity);
                    }, 0)
                  )}
                </Typography>
              </div>
              <div
                style={{
                  padding: "10px 0",
                  borderBottom: "1px dashed #D1D5DD"
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ flexGrow: 1 }}>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily:
                          defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                            .secondaryFont
                      }}
                    >
                      Subtotal
                    </Typography>
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily:
                          defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                            .secondaryFont
                      }}
                    >
                      ₱{" "}
                      {Utils.commafy(
                        parseFloat(
                          productList.reduce((total, object) => {
                            return (
                              parseFloat(total) +
                              parseFloat(object.quantity * object.unit_price)
                            );
                          }, 0)
                        )
                      )}
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{ padding: "10px 0" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ flexGrow: 1 }}>
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily:
                          defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                            .secondaryFont
                      }}
                    >
                      Total
                    </Typography>
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily:
                          defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                            .secondaryFont
                      }}
                    >
                      ₱ {Utils.commafy(totalAmt)}
                    </Typography>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  id="checkout_button"
                  className={classes.buttonCheckout}
                  onClick={() => setOpen(true)}
                  disabled={
                    !isFormEnough ||
                    formHasErrors ||
                    (kyc == 0 && amount > 2000) ||
                    amount > 30000
                  }
                  variant="contained"
                >
                  Proceed{amount > 0 ? ` to payment options` : ``}
                </Button>
                {goToBuxModal()}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    );
  }

  function removeFromCart(index) {
    // dispatch(StoreActions.removeFromCart(index));
  }
  return (
    <div>
      {/* <AppBar
        isPreview={false}
        storeInfo={storeInfo}
        color={true}
        logo={true}
        shadow={true}
        logo={true}
      /> */}
      <SentroLoading open={loading} />
      <Box className={classes.root}>
        <div style={{ padding: "0 3vw" }}>
          <Box className={classes.cart}>
            <div className={classes.backDivWrapper} onClick={() => history.goBack()}>
                <ArrowBackIcon />
                <span>BACK</span>
            </div>
            <Box className={classes.header}>
              <Box style={{ flex: 8 }}>
                <Typography
                  variant="h4"
                  style={{
                    fontSize: 30,
                    color: "#002C42",
                    fontWeight: 600
                  }}
                >
                  Checkout
                </Typography>
              </Box>
            </Box>
            {
                kycError && !loading && <Alert severity="error" style={{ marginTop: "1em"}}>
                    Oops! Looks like there is a problem with taking online orders for now. 
                    Kindly try again in a few minutes, or coordinate with us through any of the contact details below
                </Alert>
            }
            <Box className={classes.cartContent}>
              <div className={classes.cartTable}>{renderDelivery()}</div>
              <div className={classes.summaryContainer}>{renderSummary()}</div>
            </Box>
          </Box>
        </div>
      </Box>

      <Box className={classes.mobile}>
        {
            kycError && !loading && <Alert severity="error" style={{ margin: "7em 1em 1em"}}>
                Oops! Looks like there is a problem with taking online orders for now. 
                Kindly try again in a few minutes, or coordinate with us through any of the contact details below
            </Alert>
        }
        <div
          style={{
            textAlign: "center",
            padding: "30px 20px 20px",
            width: "100%",
            fontSize: 24,
            color: "#002C42",
            fontWeight: 600,
          }}
        >
          Checkout
        </div>
        {renderSummary()}
        {renderDelivery()}
      </Box>

      <Footer store={storeInfo} />
    </div>
  );
};

export default MarketplaceCheckout;
