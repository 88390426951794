import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../../layout"
import { Paper, Button } from '@material-ui/core';
import history from '../../../../store/history'
import { CheckCircle } from "@material-ui/icons";
import { OrderDetailsCard } from '../../components/shipping'
import { animateScroll as scroll } from "react-scroll";
import Actions from '../../Redux/Actions';
import qs from 'query-string';
import _ from 'lodash';

const useStyles = theme => ({
  root: {
    maxWidth: 420,
    margin: "auto"
  },
  checkIcon: {
    color: "#57B19F",
    width: 80, height: 80,
  },
  pr: {
    color: "#F97663",
  }
});

class Checkout extends React.Component {
  state = {
    order: ""
  }

  componentDidMount = async () => {
    const { location, dispatch } = this.props;
    scroll.scrollTo(0)
    const params = qs.parse(location.search)
    if (params.order_id) {
      const response = await dispatch(Actions.GET_ORDER({
        order_id: params.order_id
      }))
      if (!response) history.push("/proxtera")
    } else {
      history.push("/proxtera")
    }
  }

  render (){
    const { classes, location, orders } = this.props;
    const items = [
      {
        image: 'https://cdn.cichic.com/media/catalog/product/cache/1/image/5e06319eda06f020e43594a9c230972d/1/9/190104100971-1/white-patchwork-lace-pleated-v-neck-long-sleeve-ctue-boho-mini-dress.jpg',
        name: 'Premium Mt. Apo Arabica  Coffee Beans (1 kg.)',
        price: 7.50,
        unit: '1 kg',
        quantity: 1

      },
      {
        image: 'https://images.immediate.co.uk/production/volatile/sites/30/2020/08/health-benefits-of-almonds-hub-image-square-dcac827.jpg?quality=90&resize=504,458?quality=90&webp=true&resize=504,459',
        name: 'High quality Almonds',
        price: 7.50,
        unit: '1 kg',
        quantity: 1

      }
    ];
    const params = qs.parse(location.search)
    const order = _.find(orders, ['order_id', params.order_id]);
    const qty = order ? order.items.reduce((a, b) => a + parseInt(b.quantity), 0): 0;
    const total = order ? order.items.reduce((a, b) => a + (b.unit_price*b.quantity), 0).toFixed(2)/100: 0;
    return (
      <Layout noSideBar>
        <div className="d-flex">
          <div className={classes.root}>
            <Paper className="mb-4" elevation={3}>
              <div className="p-4">
                <div className="py-3 text-center">
                  <CheckCircle className={classes.checkIcon}/>
                  <p className="mt-3"><strong>Purchase Request successfully <br />submitted</strong></p>
                  <p className={`${classes.pr} mt-3`}>PR#{order ? order.order_id: ""}</p>
                  <div className="mt-4">
                    <Button variant="contained" color="primary" fullWidth onClick={() => {
                      history.push("/proxtera/products")
                    }}>
                      Browse more products
                    </Button>
                  </div>
                  <div className="mt-3">
                    <Button variant="outlined" color="primary" fullWidth onClick={() => {
                      history.push("/dashboard")
                    }}>
                      Return to Dashboard
                    </Button>
                  </div>
                </div>
              </div>
            </Paper>
            {
              order &&
              <OrderDetailsCard items={order.items} 
              qty={qty} 
              subtotal={total} total={total}/>
            }
            <p className="mt-2">*Exchange rates will be based on IDK</p>
          </div>
        </div>
      </Layout>
    )
  }
}


const mapStateToProps = state => ({ proxtera }) => ({ 
  orders: proxtera.orders
});
export default connect(mapStateToProps)(withStyles(useStyles)(Checkout));

