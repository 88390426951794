import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  TableBody,
  Link,
  Chip,
  Menu,
  MenuItem,
  TablePagination,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ArrowDropUp, MoreHoriz } from "@material-ui/icons";
import moment from "moment";
import DeactivateVoucherModal from "./deactivateModal";
import { editVoucher, setSelectedVoucher, setVoucherFilters } from "./actions";
import { showAlert } from "../common/commonActions";
function EnhancedTableHead(props) {
  const { classes, order, order_by, onSortRequest, hideActions } = props;
  const createSortHandler = (property) => (event) => {
    onSortRequest(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headcell) => (
          <TableCell
            className={classes.tableHead}
            key={headcell.id}
            align={headcell.align}
            sortDirection={order_by === headcell.id ? order : false}
          >
            <TableSortLabel
              active={order_by === headcell.id}
              direction={order_by === headcell.id ? order : "asc"}
              onClick={createSortHandler(headcell.id)}
              hideSortIcon={true}
              active={false}
            >
              <span>{headcell.label}</span>
              {headcell.has_sort ? (
                <span className={classes.headIconWrapper}>
                  <ArrowDropUp className={classes.headIconUp} />
                  <ArrowDropUp className={classes.headIconDown} />
                </span>
              ) : (
                ""
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const MenuOptions = (props) => {
  const { classes } = props;
  const subscription = useSelector((state) => state.subscription.subscription);
  const feature_settings = useSelector(
    (state) => state.dashboard.featureSettings?.features
  );
  return (
    <Menu
      anchorEl={props.menuAnchor}
      open={props.open}
      onClose={props.closeMenu}
      keepMounted
    >
      <MenuItem
        className={classes.item}
        onClick={() => props.handleView(props.row)}
      >
        View Details
      </MenuItem>
      <MenuItem
        className={classes.item}
        disabled={
          subscription.subscription_type === null ||
          (subscription.subscription_type && !subscription.features.vouchers) ||
          !feature_settings?.vouchers
        }
        onClick={() => props.handleEdit(props.row)}
      >
        Edit Details
      </MenuItem>
      <MenuItem
        className={classes.item}
        onClick={() => {
          !props.row.is_active
            ? props.handleDeactivate(true)
            : props.setOpenDeactivate(true);
          props.closeMenu();
        }}
      >
        {props.row.is_active ? "Deactivate" : "Activate"}
      </MenuItem>
    </Menu>
  );
};
export default function VoucherTable(props) {
  const useStyles = makeStyles((theme) => ({
    tableHead: {
      backgroundColor: "#eee",
      color: "#54575f",
      fontSize: 14,
    },
    headIconWrapper: {
      direction: "rtl",
      flexGrow: 1,
      minWidth: 45,
      display: "flex",
      height: 20,
    },
    headIconUp: {
      transform: "rotate(0deg)",
      position: "absolute",
      transform: "translateY(-8px)",
    },
    headIconDown: {
      transform: "rotate(180deg)",
      position: "absolute",
    },
    bodyRow: {
      backgroundColor: "#fff",
      color: "#002c42",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "#fafafa !important",
      },
    },
    more: {
      color: "gray",
      cursor: "pointer",
    },
    item: {
      color: "#54575f",
      backgroundColor: "#fff",
      "&: hover": {
        backgroundColor: "#e6eaf0 !important",
      },
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const stores = useSelector((state) => state.vouchers.stores);
  const vouchers = useSelector((state) => state.vouchers.voucherListFilter);
  const selectedVoucher = useSelector(
    (state) => state.vouchers.selectedVoucher
  );
  const filters = useSelector((state) => state.vouchers.voucherFilters);
  const [order, setOrder] = React.useState("asc");
  const [order_by, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(filters.page);
  const [openDeactivate, setOpenDeactivate] = React.useState(false);
  const [itemsPerPage, setItemsPerPage] = React.useState(filters.itemsPerPage);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [targetRow, setTargetRow] = React.useState(null);
  const setMenu = (event, targetRow) => {
    setMenuAnchor(event.target);
    setTargetRow(targetRow);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = order_by === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    dispatch(
      setVoucherFilters({
        order: newOrder,
        order_by: property,
        page,
        itemsPerPage,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setVoucherFilters({ page: newPage }));
  };

  async function handleDeactivate(value) {
    setOpenDeactivate(false);
    let params = {
      ...selectedVoucher,
      is_active: value,
    };
    let voucher_code = selectedVoucher ? selectedVoucher.voucher_code : "";

    const res = await dispatch(
      editVoucher(params, vouchers, stores, voucher_code)
    );

    try {
      if (res.status === 200) {
        window.scrollTo(0, 0);
        dispatch(
          showAlert({
            type: "success",
            message: "Voucher successfully updated!",
          })
        );
      }
    } catch {
      window.scrollTo(0, 0);
      dispatch(
        showAlert({
          type: "error",
          message: "Oops, something went wrong. Please try again.",
        })
      );
    }
  }

  function voucherType(type) {
    if (type === "free shipping" || type === "free_shipping") {
      return "Free shipping";
    } else if (type === "fixed_amount" || type === "fixed amount") {
      return "Fixed amount";
    } else if (type === "buy_x_get_y") {
      return "Buy X Get Y";
    } else {
      return "Percentage";
    }
  }

  const headCells = [
    { id: "voucher_code", label: "Code", align: "left", has_sort: true },
    { id: "type", label: "Type", align: "left", has_sort: false },
    { id: "used", label: "Used", align: "left", has_sort: false },
    {
      id: "start_date",
      label: "Start date",
      align: "center",
      has_sort: true,
    },
    {
      id: "end_date",
      label: "End date",
      align: "center",
      has_sort: true,
    },
    { id: "status", label: "Status", align: "center", has_sort: true },
    { id: "options", label: "Actions", align: "right", has_sort: false },
  ];

  return (
    <>
      <Table className={classes.table}>
        <EnhancedTableHead
          headCells={headCells}
          classes={classes}
          order={order}
          order_by={order_by}
          onSortRequest={handleRequestSort}
        />
        <TableBody>
          {vouchers.list.map((row, index) => (
            <TableRow
              key={index}
              hover
              tabIndex={-1}
              className={classes.bodyRow}
            >
              <TableCell
                component="th"
                scope="row"
                align="left"
                style={{ marginLeft: "1em" }}
              >
                <Link
                  color="primary"
                  className="font-weight-bold"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => props.handleViewVoucher(row)}
                >
                  {row.voucher_code}
                </Link>
              </TableCell>
              <TableCell align="left" style={{ textTransform: "capitalize" }}>
                {voucherType(row.voucher_type)}
              </TableCell>
              <TableCell align="left">{row.times_used}</TableCell>
              <TableCell align="center">
                {moment(row.start_datetime).format("D MMM YYYY")}
              </TableCell>
              <TableCell align="center">
                {moment(row.end_datetime).format("D MMM YYYY")}
              </TableCell>
              <TableCell>
                {row.is_active ? (
                  <Chip
                    label={"Active"}
                    style={{ backgroundColor: "#18b4a1", color: "#fff" }}
                  />
                ) : (
                  <Chip
                    label={"Inactive"}
                    style={{ backgroundColor: "gray", color: "#fff" }}
                  />
                )}
              </TableCell>
              <TableCell align="right">
                <MoreHoriz
                  className={classes.more}
                  onClick={(e) => {
                    setMenu(e, row);
                    dispatch(setSelectedVoucher(row));
                  }}
                />
                <MenuOptions
                  row={row}
                  key={index}
                  classes={classes}
                  menuAnchor={menuAnchor}
                  handleEdit={props.handleEditVoucher}
                  handleView={props.handleViewVoucher}
                  setOpenDeactivate={setOpenDeactivate}
                  open={
                    targetRow
                      ? targetRow.voucher_code === row.voucher_code
                      : false
                  }
                  closeMenu={() => {
                    setMenuAnchor(null);
                    setTargetRow(null);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {vouchers && vouchers.list.length ? (
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ color: "#54575f", fontSize: 13 }}>
            {vouchers.length} item(s) found
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={vouchers.length}
            rowsPerPage={filters.itemsPerPage}
            page={filters.page}
            onChangePage={handleChangePage}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center p-5">No items found</div>
      )}
      <DeactivateVoucherModal
        open={openDeactivate}
        setOpen={setOpenDeactivate}
        handleDeactivate={handleDeactivate}
      />
    </>
  );
}
