import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  InputAdornment,
  useTheme
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MobileProductImageUpload from "../../../common/layout/mobile/webBuilder/common/MobileProductImageUpload";
import Text from "../../../../common/text";
import { ExpandMore } from "@material-ui/icons";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import StarIcon from "@material-ui/icons/Stars";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import SunEditor from "suneditor-react";
import { useSelector, useDispatch } from "react-redux";
import ProductImageCropper from "../../../common/layout/mobile/webBuilder/common/productImageCropper";
import AddProductCategory from "./addProductCategory";
import {
  addProduct,
  setAddProductView,
  saveProductSelections,
  getStoreInfo
} from "../../websiteBuilderActions";
import AlertDialogBox from "../../../dashboard/common/AlertDialogBox";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import AddIcon from "@material-ui/icons/Add";
import AddVariantGroup from "./addVariantGroup";
import EditVariantGroup from "./editVariantGroup";
import defaultVariants from "./defaultVariants";
import VariantChoicesForm, {
  useProductSelectionAxiosData,
  useProductSelectionsToDelete,
  getAllNewlyCreatedVariantIds
} from "./variantChoicesForm";
const AddProductForm = props => {
  const {
    state,
    setState,
    product,
    setProduct,
    categories,
    language,
    currentProducts,
    setCurrentProducts
  } = props;
  const editorRef = React.useRef();
  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    content: {
      width: "100%",
      paddingLeft: "8vw",
      paddingRight: "8vw",
      paddingBottom: "10vh"
    },
    form: {
      backgroundColor: "#fff",
      border: "1px solid #d1d5dd",
      borderRadius: 10
    },
    label1: {
      fontSize: 14,
      color: "#54575f",
      fontWeight: 600
    },
    label2: {
      fontSize: 12,
      color: "#54575f"
    },
    imgLayout: {
      width: 95,
      height: 95,
      margin: 5,
      borderRadius: 5
    },
    actionBtn: {
      color: "#f97663",
      display: "flex",
      justifyContent: "flex-end",
      margin: 5,
      cursor: "pointer",
      position: "absolute",
      top: 5,
      right: 5
    },
    errorLayout: { display: "flex", justifyContent: "space-between" },
    removeTxt: {
      color: "#F97663",
      fontWeight: "bold",
      fontSize: 13,
      position: "absolute",
      top: 0,
      right: 0,
      cursor: "pointer"
    },
    inputWrap: {
      width: 311,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    variantHeaderSection: {
      marginTop: "3vh",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    btnColor: {
      fontSize: 10,
      height: 33,
      padding: "0 20px",
      marginRight: 10,
      fontWeight: 600,
      textTransform: "capitalize"
    },
    sectionPanel: {
      display: "flex",
      padding: "0 30px",
      width: "100%"
    }
  }));
  const classes = useStyles();
  const theme = useTheme();

  const stores = useSelector(state => state.webBuilder.stores);
  const currentStoreCreated = useSelector(state => state.webBuilder.storeInfo);
  const currentStoreProducts = useSelector(state => state.webBuilder.products);
  const addProductView = useSelector(
    state => state.webBuilder.productsViewVisible
  );
  const alertOpen = useSelector(state => state.webBuilder.dialogOpen);
  const alertType = useSelector(state => state.webBuilder.message);
  const [key, setKey] = React.useState("");
  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [cropState, setCropState] = React.useState(false);
  const [cropImage, setCropImage] = React.useState(false);

  const [variantModal, setVariantModal] = React.useState({
    addVariantGroup: false,
    editVariantGroup: false
  });

  const [productVariants, setProductVariants] = React.useState({
    data: []
  });
  const [variant, setVariant] = React.useState([]);
  const [errors, setErrors] = React.useState({
    nameError: "",
    priceError: "",
    discountedPriceError: "",
    descriptionError: "",
    categoryError: "",
    quantityError: "",
    imageError: ""
  });

  const images = product.productImages ? product.productImages : "";

  function setValueChanges(value, name) {
    setProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function handleChangeImage(file) {
    images.push(file);
    setValueChanges(images, "productImages");
    setCropState(false);
  }

  function handleRemoveItem(e) {
    images.splice(e, 1);
    setValueChanges(images, "productImages");
  }
  function closeCropper() {
    setCropState(false);
  }

  function onCropSave(image) {
    handleChangeImage(image, "productImages");
  }

  function handleAlwaysAvailable() {
    setValueChanges(
      product.always_available ? false : true,
      "always_available"
    );
    if (product.always_available === false) {
      setErrors({ ...errors, quantityError: "" });
    } else if (product.always_available === true) {
      setValueChanges(5, "quantity");
    }
  }

  function handleAddCategory(category) {
    let list = categories.map(e => e.toLowerCase());
    if (category === "") {
      setErrors({ ...errors, categoryError: "This field is required." });
    } else if (list.indexOf(category.toLowerCase()) !== -1) {
      setErrors({ ...errors, categoryError: "Category already exist!" });
    } else {
      setErrors({ ...errors, categoryError: "" });
      setAlert(true);
      setKey(category);
      setValueChanges(category, "category");
      categories.push(category);
    }
  }

  function handleEditVariant(variant, key) {
    setVariantModal({ ...variantModal, editVariantModal: true, index: key });
    setVariant(variant);
  }
  const productSelectionsData = useProductSelectionAxiosData();
  const productSelectionsToDelete = useProductSelectionsToDelete();
  const handleSaveProduct = async () => {
    const res = await dispatch(
      addProduct(product, productVariants, currentStoreCreated)
    );
    const data = productSelectionsData.map(i => ({
      ...i,
      product_variant_ids: getAllNewlyCreatedVariantIds(res.data, i)
    }));
    await dispatch(saveProductSelections(data, productSelectionsToDelete));
    dispatch(getStoreInfo());

    try {
      if (res) {
        window.scrollTo(0, 0);
        dispatch(setAddProductView(false));
        setState({
          ...state,
          openAlert: true,
          alertType: "success",
          message: "Product successfully added!"
        });
        resetProduct();
      }
    } catch {
      window.scrollTo(0, 0);
      setState({
        ...state,
        openAlert: true,
        alertType: "error",
        message: "Oops, something went wrong. Please try again."
      });
    }
  };

  const resetProduct = (stats = {}) => {
    setProduct({
      store: currentStoreCreated ? currentStoreCreated.uuid : "",
      name: "",
      price: "",
      discounted_price: "",
      description: "",
      category: "",
      featured: true,
      is_active: true,
      always_available: false,
      has_variants: false,
      quantity: 5,
      images: "",
      productImages: [],
      photo_primary: "0",
      product_code: "",
      ...stats
    });
  };

  const handleAddAnotherProduct = async () => {
    const res = await dispatch(
      addProduct(product, productVariants, currentStoreCreated)
    );
    const data = productSelectionsData.map(i => ({
      ...i,
      product_variant_ids: getAllNewlyCreatedVariantIds(res.data, i)
    }));
    await dispatch(saveProductSelections(data, productSelectionsToDelete));
    dispatch(getStoreInfo());

    try {
      if (res.status === 201) {
        window.scrollTo(0, 0);
        if (currentProducts.length >= 2) {
          resetProduct({ featured: false });
        } else {
          resetProduct();
        }
        setState({
          ...state,
          openAlert: true,
          message: "Product successfully added!"
        });
      }
    } catch {
      window.scrollTo(0, 0);
      setState({
        ...state,
        openAlert: true,
        alertType: "error",
        message: "Oops, something went wrong. Please try again."
      });
    }
  };

  function createPreview(file) {
    var blob = new Blob([file], { type: file.type });
    var blobUrl = URL.createObjectURL(blob);

    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function() {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function() {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    return blobUrl;
  }

  function handleImageUpload(value) {
    if (value === undefined || value === "undefined") {
      setErrors({
        ...errors,
        imageError: ""
      });
    } else {
      if (value.size > 5242880) {
        setErrors({
          ...errors,
          imageError: "Image is too big. Maximum allowed size is 5 MB."
        });
      } else {
        if (value.name.match(/\.(jpg|jpeg|png|heic)$/)) {
          setCropState(value ? true : false);
          setCropImage(value);
          setErrors({
            ...errors,
            imageError: ""
          });
        } else if (value.name.match(/\.(gif)$/)) {
          setCropImage(value);
          handleChangeImage(value, "productImages");
          setErrors({
            ...errors,
            imageError: ""
          });
        } else if (value.name.match(/\.(svg|html|pdf|zip)$/)) {
          setErrors({
            ...errors,
            imageError: value.name + " is not supported file extension"
          });
        }
      }
    }
  }

  function handleSetFeatured() {
    if (state.numOfFeaturedProducts.length >= 3 && !product.featured) {
      setState({ ...state, openFeaturedModal: true });
    } else if (state.numOfFeaturedProducts.length < 3) {
      setValueChanges(product.featured ? false : true, "featured");
    } else {
      setValueChanges(product.featured ? false : true, "featured");
      state.numOfFeaturedProducts.splice(1, 1);
    }
  }

  function setHasVariants(e) {
    if (e.target.checked) {
      setErrors(e => ({
        ...e,
        priceError: ""
      }));
    }
    setValueChanges(e.target.checked, "has_variants");
  }

  function addSelectedDefault(item, variant) {
    let variants = variant.variants.filter(e => e.name !== item.name);
    const param = {
      key: variant.key,
      name: variant.name,
      variants: [
        ...variants,
        {
          key: item.key,
          name: item.name,
          is_enabled: item.is_enabled ? false : true
        }
      ]
    };
    let newVariantsDefault = productVariants.filter(e => e.name !== param.name);
    setProductVariants([...newVariantsDefault, param]);
  }

  function countSelectedVariant(variant) {
    let variants = variant.variants;
    let numOfSelected = variants.filter(item => item.is_enabled === true);
    return variant.name + ` (${numOfSelected.length} Selected)`;
  }
  const field_max_lengths = {
    name: 50,
    category: 32,
    description: 512
  };

  const charactersLeft = fieldname => {
    const fieldlength = product[fieldname] ? product[fieldname].length : 0;
    const charsRemaining = field_max_lengths[fieldname] - fieldlength;
    return `${charsRemaining} characters left`;
  };

  let sanitize_price = raw_input => {
    const NOT_DOTS_NOR_NUMERICS_REGEX = /[^0-9\.]/g;
    const TWO_DOTS_REGEX = /\..*\./g;
    const ONE_DOT_REGEX = /\./g;

    let numerics_and_dots_only = raw_input.replace(
      NOT_DOTS_NOR_NUMERICS_REGEX,
      ""
    );
    // Allow at most 1 decimal place only
    let one_dot_at_most = TWO_DOTS_REGEX.test(numerics_and_dots_only)
      ? numerics_and_dots_only.slice(0, numerics_and_dots_only.lastIndexOf("."))
      : numerics_and_dots_only;
    // Limit to 2 decimal places
    let final_string = ONE_DOT_REGEX.test(one_dot_at_most)
      ? one_dot_at_most.slice(0, one_dot_at_most.lastIndexOf(".") + 3)
      : one_dot_at_most;

    return final_string;
  };

  React.useEffect(() => {
    if (product.category === "No Category (Others)" && key === 0) {
      setValueChanges("", "category");
    }
  }, [product.category]);

  React.useEffect(() => {
    for (let e in errors) {
      if (errors[e].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [errors]);

  const variant_combination_errors = useSelector(
    state => state.form.variant_choices?.syncErrors
  );
  React.useEffect(() => {
    setIsFormEnough(
      product.name &&
        (product.price || (product.has_variants && !variant_combination_errors))
    );
  }, [product]);

  React.useEffect(() => {
    if (openAddCategory === false) {
      setErrors({ ...errors, categoryError: "" });
    }
  }, [openAddCategory]);

  React.useEffect(() => {
    if (stores.length && addProductView) {
      if (currentStoreProducts.length >= 3) {
        resetProduct({ featured: false });
      } else {
        setProduct({ ...product });
      }
    }
    setProductVariants(defaultVariants);
  }, [stores, addProductView]);

  React.useEffect(() => {
    if (currentProducts.length >= 3) {
      resetProduct({ featured: false });
    } else {
      setProduct({ ...product });
    }
  }, [currentProducts]);

  const CustomRadio = withStyles({
    root: {
      color: "#ffcf00",
      "&$checked": { color: "#ffcf00" }
    },
    checked: {}
  })(props => <Radio color="default" {...props} />);

  const [withDiscount, setWithDiscount] = React.useState(false);

  const [
    productVariantsSelection,
    setProductVariantsSelection
  ] = React.useState([]);
  React.useEffect(() => {
    setProductVariantsSelection(
      productVariants.length ? [...productVariants] : []
    );
  }, [productVariants]);
  return (
    <>
      {addProductView ? (
        !cropState ? (
          <div className={classes.root}>
            <div className={classes.content}>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  alignItems: "center"
                }}
              >
                <Button
                  startIcon={<ArrowBackIcon />}
                  variant="text"
                  color="primary"
                  style={{ zIndex: 3 }}
                  onClick={() => {
                    dispatch(setAddProductView(false));
                    setState({
                      ...state,
                      addProduct: false
                    });
                    setWithDiscount(false);
                    resetProduct();
                  }}
                >
                  Back
                </Button>
                <div
                  style={{
                    fontSize: 28,
                    color: "#002c42",
                    fontWeight: 600,
                    position: "absolute",
                    textAlign: "center",
                    width: "100%"
                  }}
                >
                  {language === "English"
                    ? "Add Product"
                    : "Magdagdag ng produkto"}
                </div>
              </div>

              <div className={classes.form} style={{ marginTop: "3vh" }}>
                <div style={{ display: "flex", padding: "40px 30px" }}>
                  <div style={{ width: 280 }}>
                    <div
                      style={{
                        fontSize: 18,
                        color: "#f97663",
                        fontWeight: 600
                      }}
                    >
                      BASIC INFO
                    </div>
                    <div
                      style={{
                        fontSize: 13,
                        marginTop: "2vh",
                        color: "#54575f"
                      }}
                    >
                      {language === "English"
                        ? "Tips to make your products stand out:"
                        : "Mga tips kung paano mo pagagandahin ang mga produkto mo:"}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        marginTop: "1vh",
                        color: "#54575f"
                      }}
                    >
                      {language === "English" ? (
                        <div>
                          <div>- Upload a clear photo</div>
                          <div>- Provide a complete item name</div>
                          <div>- Ensure price is correct</div>
                          <div>- Add more details to describe your item</div>
                        </div>
                      ) : (
                        <div>
                          <div>- Mag-upload ng malinaw na picture</div>
                          <div>- Magbigay ng kumpletong item name</div>
                          <div>
                            - Siguraduhin na tama ang pag presyo sa mga produkto
                            mo
                          </div>
                          <div>
                            - Magbigay ng mas maraming mga detalye tungkol sa
                            produkto mo
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ marginLeft: 24, width: "100%" }}>
                    <div>
                      <div className={classes.label1}>Photo</div>
                      <div className={classes.label2}>
                        PNG, JPG, JPEG or GIF only. Up to 5 photos. Tap to edit
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <RadioGroup
                          style={{ flexDirection: "row" }}
                          value={product.photo_primary}
                          onChange={e =>
                            setValueChanges(e.target.value, "photo_primary")
                          }
                        >
                          {images
                            ? images.map((item, i) => (
                                <div key={i} style={{ position: "relative" }}>
                                  <img
                                    src={createPreview(item)}
                                    className={classes.imgLayout}
                                  />
                                  <span className={classes.actionBtn}>
                                    <CancelIcon
                                      onClick={() => handleRemoveItem(i)}
                                      style={{ fontSize: 20, color: "#f97663" }}
                                    />
                                  </span>
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: 0,
                                      left: 8
                                    }}
                                  >
                                    <FormControlLabel
                                      key={i}
                                      color="secondary"
                                      value={i.toString()}
                                      control={
                                        <CustomRadio
                                          icon={<CircleUnchecked />}
                                          checkedIcon={<StarIcon />}
                                        />
                                      }
                                    />
                                  </div>
                                </div>
                              ))
                            : ""}

                          {images ? (
                            images.length === 5 ? (
                              ""
                            ) : (
                              <div style={{ marginTop: "1vh" }}>
                                <MobileProductImageUpload
                                  defaultPreview
                                  onChange={value => handleImageUpload(value)}
                                />
                              </div>
                            )
                          ) : (
                            ""
                          )}
                        </RadioGroup>
                      </div>
                      <p className="text-danger mt-1" style={{ fontSize: 12 }}>
                        {errors.imageError}
                      </p>
                    </div>
                    <div style={{ marginTop: "2vh" }}>
                      <Text textType="p3_Semibold" content="*Name" />
                      <TextField
                        fullWidth
                        margin="dense"
                        id="name"
                        variant="outlined"
                        color="secondary"
                        style={{ marginTop: 0 }}
                        inputProps={{ maxLength: field_max_lengths.name }}
                        error={errors.nameError}
                        value={product.name}
                        onChange={e => {
                          let input = e.target.value;
                          setValueChanges(input, "name");
                          if (input === "") {
                            setErrors({
                              ...errors,
                              nameError: "This field is required."
                            });
                          } else {
                            setErrors({ ...errors, nameError: "" });
                          }
                        }}
                        onBlur={e => {
                          if (e.target.value === "") {
                            setErrors({
                              ...errors,
                              nameError: "This field is required."
                            });
                          }
                        }}
                        helperText={
                          <div className={classes.errorLayout}>
                            <div>{errors.nameError}</div>
                            <div style={{ color: "rgba(0,0,0,0.54)" }}>
                              {charactersLeft("name")}
                            </div>
                          </div>
                        }
                      />
                    </div>
                    <div>
                      <Text textType="p3_Semibold" content="*Price" />
                      <TextField
                        margin="dense"
                        id="price"
                        variant="outlined"
                        color="secondary"
                        style={{ marginTop: 0 }}
                        error={errors.priceError}
                        disabled={product.has_variants}
                        inputProps={{ maxLength: 8 }}
                        value={product.price}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              PHP
                            </InputAdornment>
                          )
                        }}
                        onChange={e => {
                          let input = sanitize_price(e.target.value);
                          setValueChanges(input, "price");
                          if (input === "") {
                            setErrors({
                              ...errors,
                              priceError: "This field is required."
                            });
                          } else {
                            setErrors({ ...errors, priceError: "" });
                          }
                        }}
                        onBlur={e => {
                          const input = e.target.value;
                          const ENDS_WITH_DECIMAL_REGEX = /\.$/g;
                          if (input === "") {
                            setErrors({
                              ...errors,
                              priceError: "This field is required."
                            });
                          } else if (ENDS_WITH_DECIMAL_REGEX.test(input)) {
                            setErrors({
                              ...errors,
                              priceError: "Please enter a valid price."
                            });
                          }
                        }}
                        helperText={errors.priceError}
                      />
                    </div>
                    {!withDiscount ? (
                      <Button
                        className="mt-3"
                        color="primary"
                        variant="outlined"
                        disableElevation
                        disabled={product.has_variants}
                        onClick={() => setWithDiscount(true)}
                      >
                        Add Discounted Price +
                      </Button>
                    ) : (
                      <div
                        className={`mt-3 position-relative ${classes.inputWrap}`}
                      >
                        <p
                          className={`mb-0 ${classes.removeTxt}`}
                          onClick={() => {
                            setErrors({ ...errors, discountedPriceError: "" });
                            setValueChanges("", "discounted_price");
                            setWithDiscount(false);
                          }}
                        >
                          Remove
                        </p>
                        <Text
                          textType="p3_Semibold"
                          content="*Discounted Price"
                        />
                        <TextField
                          margin="dense"
                          id="discounted_price"
                          variant="outlined"
                          color="secondary"
                          style={{ marginTop: 0 }}
                          disabled={product.has_variants}
                          error={errors.discountedPriceError}
                          inputProps={{ maxLength: 8 }}
                          value={product.discounted_price}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                PHP
                              </InputAdornment>
                            )
                          }}
                          onChange={e => {
                            let input = sanitize_price(e.target.value);
                            setValueChanges(input, "discounted_price");
                            if (input === "") {
                              setErrors({
                                ...errors,
                                discountedPriceError: "This field is required."
                              });
                            } else {
                              setErrors({
                                ...errors,
                                discountedPriceError: ""
                              });
                            }
                          }}
                          onBlur={e => {
                            const input = e.target.value;
                            const ENDS_WITH_DECIMAL_REGEX = /\.$/g;
                            if (input === "") {
                              setErrors({
                                ...errors,
                                discountedPriceError: "This field is required."
                              });
                            } else if (ENDS_WITH_DECIMAL_REGEX.test(input)) {
                              setErrors({
                                ...errors,
                                discountedPriceError:
                                  "Please enter a valid price."
                              });
                            }
                          }}
                          helperText={errors.discountedPriceError}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        marginTop: "2vh",
                        position: "relative",
                        display: "flex",
                        alignItems: "flex-end"
                      }}
                    >
                      <div>
                        <Text textType="p3_Semibold" content="*Stocks" />
                        <div className={classes.label2}>
                          Quantity will be reduced for every order made
                        </div>
                        <TextField
                          margin="dense"
                          id="stocks"
                          variant="outlined"
                          color="secondary"
                          style={{ marginTop: 0 }}
                          disabled={
                            product.always_available || product.has_variants
                          }
                          error={errors.quantityError}
                          value={product.quantity}
                          onChange={e => {
                            let input = e.target.value;
                            setValueChanges(input, "quantity");
                            if (input === "") {
                              setErrors({
                                ...errors,
                                quantityError: "This field is required."
                              });
                            } else if (input > 10000) {
                              setErrors({
                                ...errors,
                                quantityError: "This field must be less 10000"
                              });
                            } else {
                              setErrors({ ...errors, quantityError: "" });
                            }
                          }}
                          onBlur={e => {
                            let input = e.target.value;
                            if (input === "") {
                              setErrors({
                                ...errors,
                                quantityError: "This field is required."
                              });
                            } else if (input > 10000) {
                              setErrors({
                                ...errors,
                                quantityError: "This field must be less 10000"
                              });
                            } else {
                              setErrors({ ...errors, quantityError: "" });
                            }
                          }}
                          helperText={errors.quantityError}
                        />
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormControlLabel
                            labelPlacement="start"
                            label={
                              <div style={{ fontSize: 14, color: "#54575f" }}>
                                Always available?
                              </div>
                            }
                            control={
                              <Checkbox
                                color="primary"
                                checked={product.always_available}
                                onChange={() => handleAlwaysAvailable()}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "2vh" }}>
                  <ExpansionPanel
                    style={{ boxShadow: "none" }}
                    expanded={state.expandedPanelVariant}
                    onChange={() =>
                      setState({
                        ...state,
                        expandedPanelVariant: state.expandedPanelVariant
                          ? false
                          : true
                      })
                    }
                  >
                    <ExpansionPanelSummary
                      style={{
                        borderBottom: "1px solid #b7b9c0",
                        borderTop: "1px solid #b7b9c0"
                      }}
                      expandIcon={<ExpandMore />}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#f97663"
                        }}
                      >
                        Variants
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{
                        borderBottom: "1px solid #b7b9c0",
                        padding: "0 0 20px"
                      }}
                    >
                      <div className={classes.sectionPanel}>
                        <div style={{ width: "30%" }}></div>
                        <div style={{ marginLeft: 24, width: "70%" }}>
                          <FormControlLabel
                            className="mt-3"
                            style={{ marginLeft: -10 }}
                            label={
                              <div style={{ fontSize: 14, color: "#2B2D32" }}>
                                This product has multiple options (different
                                sizes, colors, etc.)
                              </div>
                            }
                            control={
                              <Checkbox
                                color="primary"
                                checked={product.has_variants}
                                onChange={setHasVariants}
                              />
                            }
                          />
                          <div className="position-relative">
                            {!product.has_variants && (
                              <div
                                className="position-absolute h-100 w-100"
                                style={{
                                  background: "rgba(255,255,255,0.5)",
                                  zIndex: 2
                                }}
                              ></div>
                            )}
                            {productVariants.length &&
                              productVariants
                                .sort(function(a, b) {
                                  if (
                                    a.name.toLowerCase() < b.name.toLowerCase()
                                  )
                                    return -1;
                                  if (
                                    a.name.toLowerCase() > b.name.toLowerCase()
                                  )
                                    return 1;
                                  return 0;
                                })
                                .map((variant, key) => {
                                  return (
                                    <div style={{ marginTop: "2vh" }}>
                                      <div
                                        className={classes.variantHeaderSection}
                                      >
                                        <Text
                                          textType="p3_Semibold"
                                          content={countSelectedVariant(
                                            variant
                                          )}
                                        />
                                        <Button
                                          startIcon={
                                            <BorderColorIcon color="primary" />
                                          }
                                          variant="text"
                                          color="primary"
                                          className={classes.btnColor}
                                          style={{ marginRight: 0 }}
                                          onClick={() =>
                                            handleEditVariant(variant, key)
                                          }
                                        >
                                          Edit
                                        </Button>
                                      </div>
                                      <div>
                                        {variant.variants
                                          .sort(function(a, b) {
                                            if (
                                              a.name.toLowerCase() <
                                              b.name.toLowerCase()
                                            )
                                              return -1;
                                            if (
                                              a.name.toLowerCase() >
                                              b.name.toLowerCase()
                                            )
                                              return 1;
                                            return 0;
                                          })
                                          .map((item, key) => {
                                            return (
                                              <Button
                                                size="small"
                                                variant={
                                                  item.is_enabled
                                                    ? "contained"
                                                    : "outlined"
                                                }
                                                color={
                                                  item.is_enabled
                                                    ? "primary"
                                                    : "secondary"
                                                }
                                                disableElevation
                                                className={classes.btnColor}
                                                onClick={() =>
                                                  addSelectedDefault(
                                                    item,
                                                    variant
                                                  )
                                                }
                                              >
                                                {item.name}
                                              </Button>
                                            );
                                          })}
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          color="primary"
                                          endIcon={<AddIcon />}
                                          className={classes.btnColor}
                                          style={{ padding: "0 10px" }}
                                          onClick={() =>
                                            handleEditVariant(variant, key)
                                          }
                                        >
                                          Add
                                        </Button>
                                      </div>
                                    </div>
                                  );
                                })}
                            {productVariants.length < 2 && (
                              <div
                                className="d-flex"
                                style={{ marginTop: "5vh" }}
                              >
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="primary"
                                  endIcon={<AddIcon />}
                                  className={`${classes.btnColor} ml-auto`}
                                  style={{ padding: "0 10px" }}
                                  onClick={() =>
                                    setVariantModal({
                                      ...variantModal,
                                      addVariantModal: true
                                    })
                                  }
                                >
                                  Add Variant Group
                                </Button>
                              </div>
                            )}
                            <VariantChoicesForm
                              data={productVariantsSelection}
                              product={product}
                            />
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div style={{ marginTop: "2vh" }}>
                  <ExpansionPanel
                    style={{ boxShadow: "none" }}
                    expanded={state.expandedPanelAdditional}
                    onChange={() =>
                      setState({
                        ...state,
                        expandedPanelAdditional: state.expandedPanelAdditional
                          ? false
                          : true
                      })
                    }
                  >
                    <ExpansionPanelSummary
                      style={{
                        borderBottom: "1px solid #b7b9c0",
                        borderTop: "1px solid #b7b9c0"
                      }}
                      expandIcon={<ExpandMore />}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#f97663"
                        }}
                      >
                        Additional Information
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{
                        borderBottom: "1px solid #b7b9c0",
                        padding: "0 0 20px"
                      }}
                    >
                      <div className={classes.sectionPanel}>
                        <div style={{ width: "30%" }}></div>
                        <div style={{ marginLeft: 24, width: "70%" }}>
                          <div style={{ marginTop: "2vh" }}>
                            <Text
                              textType="p3_Semibold"
                              content="Description"
                            />
                            <SunEditor
                              ref={editorRef}
                              setContents={product.description}
                              onChange={e => {
                                if (e === "<p><br></p>") {
                                  setValueChanges("", "description");
                                } else {
                                  setValueChanges(e, "description");
                                }
                              }}
                              setOptions={{
                                buttonList: [
                                  [
                                    "font",
                                    "fontSize",
                                    "formatBlock",
                                    "align",
                                    "list"
                                  ],
                                  ["bold", "underline", "italic"],
                                  ["fontColor", "hiliteColor"],
                                  ["outdent", "indent"]
                                ],
                                height: 200,
                                width: 450,
                                charCounter: true,
                                maxCharCount: 512
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "2vh" }}>
                            <Text textType="p3_Semibold" content="Category" />
                            <FormControl
                              style={{ width: "100%" }}
                              variant="outlined"
                              margin="dense"
                            >
                              <Select
                                id="category"
                                color="secondary"
                                value={key === 0 ? "No Category (Others)" : key}
                                onChange={e =>
                                  setValueChanges(e.target.value, "category")
                                }
                              >
                                {categories
                                  .sort(function(a, b) {
                                    if (a.toLowerCase() < b.toLowerCase())
                                      return -1;
                                    if (a.toLowerCase() > b.toLowerCase())
                                      return 1;
                                    return 0;
                                  })
                                  .map(item => {
                                    return item === "" ? (
                                      ""
                                    ) : (
                                      <MenuItem
                                        onClick={() => setKey(item)}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                                <MenuItem
                                  value="No Category (Others)"
                                  onClick={() => setKey(0)}
                                >
                                  No Category (Others)
                                </MenuItem>
                                <MenuItem
                                  onClick={() => setOpenAddCategory(true)}
                                  style={{ color: "#f97663", fontWeight: 600 }}
                                >
                                  + Add a new category
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div style={{ marginTop: "2vh" }}>
                            <FormControlLabel
                              style={{ marginLeft: 0 }}
                              labelPlacement="start"
                              label={
                                <div style={{ fontSize: 14, color: "#54575f" }}>
                                  Featured product?
                                </div>
                              }
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={product.featured}
                                  onChange={() => handleSetFeatured()}
                                />
                              }
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              style={{ marginLeft: 0 }}
                              labelPlacement="start"
                              label={
                                <div style={{ fontSize: 14, color: "#54575f" }}>
                                  Display product?
                                </div>
                              }
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={product.is_active}
                                  onChange={() =>
                                    setValueChanges(
                                      product.is_active ? false : true,
                                      "is_active"
                                    )
                                  }
                                />
                              }
                            />
                          </div>

                          <div style={{ marginTop: "2vh" }}>
                            <Text
                              textType="p3_Semibold"
                              content="Product Code"
                            />
                            <div className={classes.label2}>
                              Optional. Change only if you have SKU for this
                              product
                            </div>
                            <TextField
                              margin="dense"
                              id="product_code"
                              variant="outlined"
                              color="secondary"
                              style={{ marginTop: 0 }}
                              value={product.product_code}
                              inputProps={{ maxLength: 32 }}
                              onChange={e =>
                                setValueChanges(e.target.value, "product_code")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                    <div style={{ padding: 30 }}>
                      <Button
                        fullWidth
                        id="add_products"
                        color="primary"
                        variant="outlined"
                        disableElevation
                        disabled={!isFormEnough || formHasErrors}
                        onClick={() => {
                          handleAddAnotherProduct();
                          images.length = 0;
                        }}
                        style={{ marginBottom: "1vh" }}
                      >
                        Add another product
                      </Button>
                      <Button
                        id="submit"
                        fullWidth
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={
                          !isFormEnough ||
                          formHasErrors ||
                          Boolean(variant_combination_errors)
                        }
                        onClick={() => handleSaveProduct()}
                      >
                        Submit
                      </Button>
                    </div>
                  </ExpansionPanel>
                </div>
              </div>
              <AddProductCategory
                isDesktop={true}
                open={openAddCategory}
                setOpen={setOpenAddCategory}
                categories={categories}
                error={errors.categoryError}
                alert={alert}
                setAlert={setAlert}
                handleAddCategory={category => handleAddCategory(category)}
              />
              <AddVariantGroup
                open={variantModal}
                setOpen={setVariantModal}
                productVariants={productVariants}
                setProductVariants={setProductVariants}
              />
              <EditVariantGroup
                open={variantModal}
                setOpen={setVariantModal}
                variant={variant}
                setVariant={setVariant}
                productVariants={productVariants}
                setProductVariants={setProductVariants}
              />
            </div>
          </div>
        ) : (
          <div>
            <div style={{ width: "100%", position: "relative" }}>
              <ProductImageCropper
                isDesktop={true}
                image={cropImage}
                open={cropState}
                onClose={closeCropper}
                aspect={1 / 1}
                fieldname={cropImage.name}
                onSave={image => onCropSave(image)}
              />
            </div>
          </div>
        )
      ) : null}
      <AlertDialogBox
        open={alertOpen}
        alertType={alertType ? alertType.type : "success"}
        message={alertType ? alertType.message : ""}
      />
    </>
  );
};

export default AddProductForm;
