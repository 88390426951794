import moment from "moment";
import { uniqueStore } from "../websiteBuilder/websiteBuilderServices";
import { uniqueEmail } from "../register/registerServices";

function hasUpperCase(str) {
  return str.toLowerCase() != str;
}
const validations = {
  required: value => (value ? false : "This field is required."),
  //
  specialCharacters: value =>
    /[!@#_$%^&*(),.?~=[":{}'|<>/\\\];`]/g.test(value)
      ? 'This field must not contain any special characters !@#_$%^&*(),.?~=[":{}|<>/\\];'
      : false,
  uppercase: value =>
    /[A-Z]/g.test(value)
      ? "This field must not contain capital letters."
      : false,
  password: value =>
    !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g.test(
      value
    )
      ? "Please have at least 8 characters with a mix of numbers, letters and symbols (@$!%*#?&)."
      : false,

  spaceBetween: value =>
    /\s/g.test(value) ? "This field must not contain spaces." : false,
  verificationCode6: value =>
    value.length !== 6 ? "This code must contain exactly six digits." : false,
  uniqueStore: value =>
    uniqueStore(value) ? "Please provide another store name" : false,

  whiteSpace: value => (!/^\S/g.test(value) ? "Invalid Format" : false),
  requiredMobileNumber: value =>
    value === "+63" ? "This field is required" : false,
  requiredLandlineNumber: value =>
    value === "+63" ? "This field is required" : false,
  name: value =>
    !/^[a-zA-Z\.\-\sñ\']*$/g.test(value) ? "Please enter a valid name" : false,
  accountNumber: value =>
    !/^([0-9]*|[0-9]+(-[0-9]+)+)$/g.test(value)
      ? "Invalid Account Number"
      : false,
  birthLegalAge: value =>
    moment(new Date()).format("YYYY") - moment(value).format("YYYY") < 18
      ? "Must be in legal Age"
      : false,
  mobileNumber: value =>
    value && !/^09[0-9]{9}[:.,-]?$/g.test(value)
      ? "Please provide a valid mobile number"
      : false,
  mobileNumber10: value =>
    value && !/^9[0-9]{9}[:.,-]?$/g.test(value)
      ? "Please provide a valid mobile number"
      : false,
  mobileNumberValidation: value =>
    value.charAt(0) != 0 ? "You have entered invalid mobile number" : false,
  mobileNumberValidation2: value =>
    value.charAt(1) != 9 ? "You have entered invalid mobile number" : false,
  landlineNumber: value =>
    value && !/^[0-9]{11}[:.,-]?$/g.test(value)
      ? "Please provide a valid landline number"
      : false,
  referenceMobileNumber: value =>
    !/^\63[0-9]{3}\s[0-9]{3}\s[0-9]{4}|\63[0-9]{10}|\63$/g.test(value)
      ? "Invalid mobile number"
      : false,
  numeric: value => (isNaN(value) ? "Input must be completely numeric" : false),
  minimumOf: (value, min) =>
    parseFloat(value) < parseFloat(min).toFixed(2)
      ? `Amount must not be less than ₱ ${parseFloat(min).toFixed(2)}`
      : false,
  maximumOf: (value, max) =>
    parseFloat(value) > parseFloat(max).toFixed(2)
      ? `Amount must be less than ₱ ${parseFloat(max).toFixed(2)}`
      : false,
  limitWalletBalance: (value, max) =>
    parseFloat(value) > parseFloat(max).toFixed(2)
      ? `Amount is greater than available Wallet Balance`
      : false,
  limitStablecoinBalance: (value, max) =>
    parseFloat(value) > parseFloat(max).toFixed(2)
      ? `Amount is greater than available Stablecoin Balance (₱ ${parseFloat(
          max
        ).toFixed(2)})`
      : false,
  email: value =>
    value &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
      ? "Please provide a valid email"
      : false,
  maliciousCharacters: value =>
    /([<>:;"\(\)\$])/g.test(value) ? "Must not use invalid characters" : false,
  tin: value =>
    !/^([0-9]{3}-[0-9]{3}-[0-9]{3}-[0-9]{3})$/.test(value)
      ? "Invalid TIN number"
      : false,
  minimumChar: (value, min) =>
    value.length < min ? `Must be more than ${min} characters` : false,
  maximumChar: (value, max) =>
    value.length > max ? `Must be less than ${max} characters` : false,
  domain: value =>
    value
      ? /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
          value
        )
        ? false
        : "Please provide a valid domain"
      : false,
  noCaps: value => (hasUpperCase(value) ? "Uppercase is not allowed" : false),
  verifyEmail: value =>
    value.includes("+") ? "Please provide a valid email" : false,
  zipcode: val =>
    val
      ? /^\d{4}$/.test(val)
        ? false
        : "Please provide a valid zipcode."
      : false,
  stock: value =>
    isNaN(value) ||
    value % 1 ||
    value < 1 ||
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)
      ? "Please input a valid stock."
      : false,
  greaterThan3: value => (value < 4 ? "Value should be greater than 3" : false),
  leadTime: value =>
    value < 1 || value > 14
      ? "Please input a valid lead time (max of 14 calendar days)"
      : false,
  isNegativeNumber: value =>
    value < 0 ? "Input must be greater than 0" : false,
  isInteger: value =>
    isNaN(value) ||
    value % 1 ||
    value < 1 ||
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)
      ? "Input should not be decimal"
      : false
};

export default validations;
