import {
  websiteBuilderActionTypes,
  ReduxInitializeFormActionTypes
} from "./websiteBuilderActions";
const initialState = {
  loading: false,
  error: "",
  stores: [],
  storeInfo: "",
  buxInfo: 0,
  isEdit: false,
  step: 0,
  products: {
    loading: false,
    products: {
      results: []
    },
    bulk_actions: {
      selected: []
    },
    filters: {
      status: "active",
      search: "",
      sort: "asc",
      sortBy: "created_on",
      category: [],
      itemsPerPage: 10,
      page: 0
    },
    batch_upload: {
      loading: false,
      data: []
    }
  },
  productsViewVisible: false,
  has_product_filters: false,
  filtered_products: [],
  product_categories: {
    categories: [],
    loading: false,
    error: false
  },
  successCreateShop: false,
  successPublishShop: false,
  payments: [],
  paymentDetails: "",
  message: null,
  dialogOpen: false,
  kyc_success: false,
  tnc_agreed: null,
  productVariants: [],
  selectedVariant: {},
  productSelections: [],
  variantCombinations: [],
  reduxForm: {
    productSelection: "",
    customizeShopForm: "",
    productForm: "",
    quickOrderSettings: ""
  }
};
export default function webBuilderReducers(state = initialState, action) {
  let products_data;
  switch (action.type) {
    case websiteBuilderActionTypes.LOADING_ON:
      return {
        ...state,
        loading: true
      };
    case websiteBuilderActionTypes.LOADING_OFF:
      return {
        ...state,
        loading: false
      };
    case websiteBuilderActionTypes.BUILDER_REQUEST:
      return {
        ...state,
        loading: true,
        isEdit: true
      };
    case websiteBuilderActionTypes.FORWARD:
      return {
        ...state,
        step: action.payload + 1
      };
    case websiteBuilderActionTypes.BACKWARD:
      return {
        ...state,
        step: action.payload - 1
      };
    case websiteBuilderActionTypes.BUILDER_FAILED:
      return {
        ...state,
        error: action.payload.error,
        step: action.payload.step,
        isEdit: action.payload.isEdit
      };

    case websiteBuilderActionTypes.GET_STORE_INFO_WEB:
      return {
        ...state,
        // isEdit: true,
        // // loading: false,
        storeInfo: {
          ...action.payload,
          font:
            !action.payload.font || action.payload.font === {}
              ? { family: "Georgia", css_url: "" }
              : action.payload.font,
          color:
            typeof action.payload.color === "string"
              ? {
                  primary: {
                    dark: "#f2f2f2",
                    main: "#ffffff",
                    light: "white",
                    contrastText: "#9763c0"
                  },
                  secondary: {
                    dark: "#f2f2f2",
                    main: "#9763c0",
                    light: "white",
                    contrastText: "#ffffff"
                  }
                }
              : action.payload.color
        }
      };
    case websiteBuilderActionTypes.GET_STORE_SUCCESS:
      return {
        ...state,
        stores: action.payload
      };
    case websiteBuilderActionTypes.GET_BUX_STATUS:
      return {
        ...state,
        buxStatus: action.payload
      };
    case websiteBuilderActionTypes.EDIT_STORE:
      return {
        ...state,
        storeInfo: action.payload,
        loading: false
      };
    case websiteBuilderActionTypes.HIDE_ALERT:
      return {
        ...state,
        error: ""
      };
    case websiteBuilderActionTypes.DELETE_STORE:
      return {
        ...state,
        loading: false
      };
    case websiteBuilderActionTypes.CONTINUE_BUILD:
      return {
        ...state,
        loading: false,
        isEdit: true,
        step: 0
      };
    case websiteBuilderActionTypes.PUBLISH_STORE:
      products_data = !action.is_csv && { products: action.payload };
      return {
        ...state,
        successPublishShop: true,
        products: {
          ...state.products,
          loading: false,
          ...products_data
        }
      };
    case websiteBuilderActionTypes.CLOSE_PUBLISH_DIALOG:
      return {
        ...state,
        successPublishShop: false
      };
    case websiteBuilderActionTypes.SET_EDIT:
      return {
        ...state,
        isEdit: true
      };
    case websiteBuilderActionTypes.ADD_PRODUCT:
      return {
        ...state,
        products: action.payload,
        loading: false
      };
    case websiteBuilderActionTypes.SET_ADD_PRODUCT_VIEW:
      return {
        ...state,
        productsViewVisible: action.payload
      };
    case websiteBuilderActionTypes.GET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true
        }
      };
    case websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS:
      products_data = !action.is_csv && { products: action.payload };
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          ...products_data
        }
      };
    case websiteBuilderActionTypes.ADD_PAYMENT:
      return {
        ...state
      };
    case websiteBuilderActionTypes.GET_PAYMENTS_WEB:
      return {
        ...state,
        loading: false,
        payments: action.payload
      };

    case websiteBuilderActionTypes.EDIT_PAYMENT:
      return {
        ...state,
        loading: false,
        paymentDetails: action.payload
      };

    case websiteBuilderActionTypes.OPEN_DIALOG:
      return {
        ...state,

        dialogOpen: true
      };
    case websiteBuilderActionTypes.CLOSE_DIALOG:
      return {
        ...state,
        dialogOpen: false
      };
    case websiteBuilderActionTypes.SET_ALERT_MESSAGE:
      return {
        ...state,
        message: action.payload
      };
    case websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES:
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          error: false
        }
      };
    case websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES_SUCCESS:
      let categories = action.payload.filter(val => val);
      if (action.payload.includes("")) categories.push("");
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          categories,
          loading: false,
          error: false
        }
      };
    case websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES_FAILED:
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          error: true,
          loading: false
        }
      };
    case websiteBuilderActionTypes.KYC_SUCCESS:
      return {
        ...state,
        kyc_success: true
      };
    case websiteBuilderActionTypes.AGREED_TNC:
      return {
        ...state,
        tnc_agreed: action.payload
      };
    case websiteBuilderActionTypes.GET_PRODUCT_VARIANTS:
      return {
        ...state,
        productVariants: action.payload
      };
    case websiteBuilderActionTypes.SELECTED_VARIANT:
      return {
        ...state,
        selectedVariant: action.payload
      };
    case websiteBuilderActionTypes.GET_PRODUCT_SELECTIONS:
      return {
        ...state,
        productSelections: action.payload
      };
    case ReduxInitializeFormActionTypes.LOAD_PRODUCT_SELECTIONS_DATA:
      return {
        ...state,
        reduxForm: {
          ...state.reduxForm,
          productSelection: action.payload
        }
      };
    case websiteBuilderActionTypes.SET_VARIANT_COMBINATIONS:
      return {
        ...state,
        variantCombinations: action.payload
      };
    case websiteBuilderActionTypes.RESET_PRODUCT_SELECTIONS:
      return {
        ...state,
        productSelections: []
      };
    case websiteBuilderActionTypes.SET_PRODUCT_FILTERS:
      return {
        ...state,
        products: {
          ...state.products,
          filters: {
            ...state.products.filters,
            ...action.payload
          }
        }
      };
    case websiteBuilderActionTypes.SET_PRODUCT_SELECTED:
      return {
        ...state,
        products: {
          ...state.products,
          bulk_actions: {
            selected: action.payload
          }
        }
      };
    case websiteBuilderActionTypes.BATCH_UPLOAD_SET_LOADING:
      return {
        ...state,
        products: {
          ...state.products,
          batch_upload: {
            ...state.products.batch_upload,
            loading: action.payload
          }
        }
      };
    case websiteBuilderActionTypes.BATCH_UPLOAD_GET:
      return {
        ...state,
        products: {
          ...state.products,
          batch_upload: {
            ...state.products.batch_upload,
            data: action.payload
          }
        }
      };
    case websiteBuilderActionTypes.BATCH_UPLOAD_POST:
      const batch_upload_data =
        state.products.batch_upload.data.length === 5
          ? state.products.batch_upload.data.slice(0, -1)
          : state.products.batch_upload.data;
      return {
        ...state,
        products: {
          ...state.products,
          batch_upload: {
            ...state.products.batch_upload,
            data: [action.payload, ...batch_upload_data]
          }
        }
      };
    case websiteBuilderActionTypes.DELETE_STORE_PRODUCT_CATEGORY:
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          categories: state.product_categories.categories.filter(
            i => i !== action.payload
          )
        }
      };

    // redux forms
    case ReduxInitializeFormActionTypes.LOAD_CUSTOMIZE_SHOP_FORM_DATA:
      return {
        ...state,
        reduxForm: {
          ...state.reduxForm,
          customizeShopForm: action.payload
        }
      };
    case ReduxInitializeFormActionTypes.LOAD_PRODUCT_FORM_DATA:
      return {
        ...state,
        reduxForm: {
          ...state.reduxForm,
          productForm: action.payload
        }
      };
    case ReduxInitializeFormActionTypes.LOAD_QUICK_ORDER_SETTINGS_DATA:
      return {
        ...state,
        reduxForm: {
          ...state.reduxForm,
          quickOrderSettings: action.payload
        }
      };
    default:
      return state;
  }
}
