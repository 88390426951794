import React from "react";
import {
  makeStyles,
  Paper,
  Chip,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
  TableBody,
  useMediaQuery
} from "@material-ui/core";
import tags from "../../../assets/images/tags.svg";
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import CartCard from "./cartCard";
import Utils from "../../common/utils";
import SnackbarAlert from "../../common/SnackbarAlert";
import CartEmpty from "./cartEmpty";
const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    paddingTop: "5vh",
    fontSize: 28,
    fontWeight: 600,
    color: "#002c42",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  content: {
    marginTop: "5vh",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  labelbold: {
    fontSize: 14,
    fontWeight: 600,
    color: "54575f"
  },
  label: {
    fontSize: 14,
    color: "#54575f"
  },
  cartTotalSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3vh"
  },
  btnCart: {
    height: 40,
    fontWeight: 500,
    fontSize: 12
  },
  summary: {
    width: "35%",
    height: "fit-content",
    marginLeft: "2vw",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0
    }
  },
  columnHeader: {
    fontSize: 16,
    fontWeight: 600,
    color: "#3e4149",
    border: "none",
    padding: "0 0 2vh"
  },
  voucherLink: {
    color: "#54575f",
    fontSize: 14,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontWeight: 500
  },
  column: {
    borderBottom: "none",
    fontSize: 14,
    color: "#3e4149",
    border: "none",
    padding: "2vh 0"
  },
  columnFooter: {
    borderTop: "1px dashed #d1d5dd",
    fontSize: 16,
    fontWeight: 600,
    color: "#3e4149",
    border: "none",
    padding: "2vh 0 0"
  }
}));

const MyCart = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const cart_items = useSelector(state => state.buyerDashboard.cart_items.cart);
  const [voucher, setVoucher] = React.useState([]);
  const [alert, setAlert] = React.useState({
    open: false,
    alertType: "success",
    message: ""
  });

  function closeAlertBox() {
    setAlert({
      open: false,
      alertType: "success",
      message: ""
    });
  }

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if ((user.is_seller && user.is_buyer) || user.is_facebookLogin) {
  //     sessionStorage.removeItem("CURRENT_USER");
  //     sessionStorage.removeItem("TOKEN");
  //   }
  // }, []);

  // React.useEffect(() => {
  //   voucher.length = 0;
  //   let list = [];
  //   if (my_orders) {
  //     for (let i in my_orders) {
  //       if (my_orders[i].voucher !== "") {
  //         list.push(JSON.parse(my_orders[i].voucher));
  //       }
  //     }
  //     setVoucher(list);
  //   }
  // }, [my_orders]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>My Cart</div>
      {cart_items && cart_items.length ? (
        <div className={classes.content}>
          <div style={{ width: isMobile ? "100%" : "65%" }}>
            {cart_items.length
              ? cart_items.map((item, key) => {
                  return (
                    <div style={{ marginBottom: "3vh" }}>
                      <CartCard item={item} alert={alert} setAlert={setAlert} />
                    </div>
                  );
                })
              : ""}
          </div>
          <Paper className={classes.summary}>
            <Table style={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.columnHeader}>
                    Summary
                  </TableCell>
                  {/* <TableCell align="right" className={classes.columnHeader}>
                    <Link className={classes.voucherLink}>
                      <img
                        src={tags}
                        width="15"
                        height="15"
                        style={{ marginRight: 5 }}
                      />
                      Vouchers
                    </Link>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.column} align="left">
                    Items
                  </TableCell>
                  <TableCell className={classes.column} align="right">
                    {parseFloat(
                      cart_items.reduce((total, object) => {
                        return (
                          parseFloat(total) + parseFloat(object.num_of_items)
                        );
                      }, 0)
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.column} align="left">
                    Subtotal
                  </TableCell>
                  <TableCell className={classes.column} align="right">
                    ₱{" "}
                    {Utils.commafy(
                      parseFloat(
                        cart_items.reduce((total, object) => {
                          return (
                            parseFloat(total) + parseFloat(object.subtotal)
                          );
                        }, 0)
                      )
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.columnFooter} align="left">
                    Total
                  </TableCell>
                  <TableCell className={classes.columnFooter} align="right">
                    ₱{" "}
                    {Utils.commafy(
                      parseFloat(
                        cart_items.reduce((total, object) => {
                          return (
                            parseFloat(total) + parseFloat(object.subtotal)
                          );
                        }, 0)
                      )
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
      ) : (
        <CartEmpty id="cart_empty" />
      )}

      <SnackbarAlert
        open={alert.open}
        alertType={alert.alertType}
        message={alert.message}
        closeAlertBox={closeAlertBox}
      />
    </div>
  );
};

export default MyCart;
