import React from 'react';
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Button,
  FormHelperText,
  IconButton,
  TextField,
  Tabs,
  Tab,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import Blank from '../blank';
import facebookIcon from '../../../../../assets/images/facebook.svg';
import viberIcon from '../../../../../assets/images/viber.svg';
import whatsappIcon from '../../../../../assets/images/whatsapp.svg';
import twitterIcon from '../../../../../assets/images/twitter.svg';
import copyIcon from '../../../../../assets/images/copyButton.svg';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  ViberShareButton,
} from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import defaultSRC from '../defaultResources';
import defaultColor from '../colors';
import Utils from '../../../utils';
import DocumentMeta from 'react-document-meta';
import ImageGallery from 'react-image-gallery';
import { PromoBanner } from '../PromoBanner12.12';
import DescriptionTab from './descriptionTab';
import ReactHtmlParser from 'react-html-parser';
import SnackbarAlert from '../../../../common/SnackbarAlert';
import { useSelector } from 'react-redux';
import ProductCard from '../ProductCard';
import { Carousel, WingBlank } from 'antd-mobile';
import { getPrice } from '../ProductCard';
import { ProductDetailedPriceDisplay } from '../PriceDisplay';
import ProductForm from '../products/productForm';
import Footer from '../footer/footer';
import NFTDetails from '../../nft/pages/details';

const ProductDetailsTemplate = (props) => {
  const { mobileView, openAlert, setOpenAlert, productImages, product } = props;
  let isPreview = props.isPreview;
  let kyc = parseInt(props.store ? props.store.kyc_level : 0);
  let products = useSelector((state) => state.store.products);
  let variants = product ? product.variant_groups : [];

  let errors = props.errors;
  let storeInfo = props.store;
  let setErrors = props.setErrors;
  let color = props.store && props.store.color;

  function decrement() {
    if (props.quantity === 1) {
      props.setQuantity(1);
    } else {
      props.setQuantity(props.quantity - 1);
    }
  }

  function disabled() {
    if (props.product) {
      if (props.product.always_available) {
        return false;
      } else {
        if (props.product.quantity === 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  function stocks() {
    let stock = props.product.quantity - props.existingQuantity;
    if (stock < 0) {
      return (
        <Typography
          style={{
            fontSize: 16,

            color: 'red',
          }}
        >
          Out of stock
        </Typography>
      );
    } else {
      return 'Available stocks: ' + stock;
    }
  }

  function closeAlertBox() {
    setOpenAlert(false);
  }

  const handleAddtoCartNew = (params) => {
    props.addToCart(params);
  };

  const ShareLink = () => (
    <CopyToClipboard onCopy={() => setOpenAlert(true)} text={props.productLink}>
      <IconButton style={{ padding: 8 }}>
        <img
          src={copyIcon}
          width={props.mobileView ? '45' : '24'}
          height={props.mobileView ? '45' : '24'}
          alt="copy-to-clipboard"
        />
      </IconButton>
    </CopyToClipboard>
  );

  const meta = {
    title: `${props.product ? props.product.name : ''} | Sentro`,
    description: props.product ? props.product.description : '',
    canonical: props.productLink,

    meta: {
      charset: 'utf-8',
      property: {
        'og:title': 'Website',
        'og:title': `${props.product ? props.product.name : ''} | Sentro`,
        'og:url': props.productLink,
        'og:image': props.product ? props.product.images : '',
        'og:description': props.product ? props.product.description : '',
        'twitter:card': 'summary_large_image',
        'twitter:title': `${props.product ? props.product.name : ''} | Sentro`,
        'twitter:description': props.product ? props.product.description : '',
        'twitter:image': props.product ? props.product.images : '',
      },
      // name: {
      //   keywords: "react,meta,document,html,tags",
      // },
    },
  };

  const shareButtons = [
    {
      component: (
        <FacebookShareButton url={props.productLink}>
          <IconButton style={{ padding: 0, marginRight: 15 }}>
            <img
              src={facebookIcon}
              style={{ width: 25, height: 25 }}
              alt="facebook"
            />
          </IconButton>
        </FacebookShareButton>
      ),
    },
    {
      component: (
        <ViberShareButton url={props.productLink}>
          <IconButton style={{ padding: 0, marginRight: 15 }}>
            <img
              src={viberIcon}
              style={{ width: 25, height: 25 }}
              alt="viber"
            />
          </IconButton>
        </ViberShareButton>
      ),
    },
    {
      component: (
        <WhatsappShareButton url={props.productLink}>
          <IconButton style={{ padding: 0, marginRight: 15 }}>
            <img
              src={whatsappIcon}
              style={{ width: 25, height: 25 }}
              alt="whatsapp"
            />
          </IconButton>
        </WhatsappShareButton>
      ),
    },
    {
      component: (
        <TwitterShareButton url={props.productLink}>
          <IconButton style={{ padding: 0, marginRight: 15 }}>
            <img
              src={twitterIcon}
              style={{ width: 25, height: 25 }}
              alt="twitter"
            />
          </IconButton>
        </TwitterShareButton>
      ),
    },
    {
      component: (
        <CopyToClipboard
          onCopy={() => setOpenAlert(true)}
          text={props.productLink}
        >
          <img src={copyIcon} width={25} height={25} alt="copy-to-clipboard" />
        </CopyToClipboard>
      ),
    },
  ];

  const getStyles = (template, theme) => {
    switch (template) {
      case 'Classic':
        return {
          root: {},
          content1: {
            height: '100vh',
            width: '100%',
          },

          content: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              display: 'block',
            },
          },

          productCard: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            margin: '0 63px',
            [theme.breakpoints.down('sm')]: {
              margin: '0',
            },
          },

          productImage: {
            marginLeft: '1vw',
            margin: 20,
            width: '45%',
            borderRadius: '5px',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              height: 600,
              margin: 0,
              padding: '0 24px',
            },
            [theme.breakpoints.down('xs')]: {
              width: '100%',
              height: 330,
              margin: 0,
            },
          },

          productDetails: {
            margin: 50,
            // paddingTop: 80,
            width: '40%',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              padding: 0,
              marginTop: '15vh',
              marginLeft: 0,
            },

            [theme.breakpoints.down('xs')]: {
              padding: '2em 0',
              marginTop: 30,
            },
          },

          productName1: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              justifyContent: 'center',
              fontSize: 36,
              color: '#54575f',
              fontFamily: storeInfo && storeInfo.font.family,
              textTransform: 'uppercase',
              width: '100%',
              wordWrap: 'break-word',
              marginBottom: 10,
            },
          },
          productName2: {
            fontSize: 36,
            color: '#54575f',
            fontFamily: storeInfo && storeInfo.font.family,
            textTransform: 'uppercase',
            width: '100%',
            wordWrap: 'break-word',
            [theme.breakpoints.down('xs')]: {
              fontSize: 24,
            },
          },

          price: {
            fontSize: props.product.discounted_price ? 28 : 32,

            [theme.breakpoints.down('xs')]: {
              fontSize: props.product.discounted_price ? 22 : 24,
            },
            textDecoration: props.product.discounted_price
              ? 'line-through'
              : 'auto',
            color: props.product.discounted_price
              ? 'rgba(0,0,0,0.5)'
              : '#54575F',
          },

          discounted_price: {
            fontSize: 32,
            color: '#54575F',

            // marginTop: 20,
            [theme.breakpoints.down('xs')]: {
              fontSize: 24,
              //   marginTop: 10
            },
          },
          imageStyle: {
            width: '100%',
            height: 475,
            [theme.breakpoints.down('sm')]: {
              height: 575,
              backgroundSize: 'cover',
            },
            [theme.breakpoints.down('xs')]: {
              height: 310,
              backgroundSize: 'cover',
            },
          },
          gallery: {
            margin: props.mobileView ? 0 : '0 50px',
            border: '1px solid #d1d5dd',
            borderRadius: 5,
          },
          btnMobile: {
            position: 'fixed',
            width: '100%',
            bottom: 0,
            backgroundColor: '#fff',
            padding: 20,
            zIndex: 3,
          },
          icon: {
            width: 24,
            height: 24,
            [theme.breakpoints.down('sm')]: {
              width: 45,
              height: 45,
            },
          },
          input: {
            backgroundColor: !disabled() ? '' : '#e6eaf0',
          },
          shareLayout: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              justifyContent: '',
            },
            [theme.breakpoints.down('xs')]: {
              justifyContent: props.mobileView ? 'space-between' : '',
            },
          },
          label: {
            fontWeight: 600,
            fontSize: 16,
            color: '#2b2d33',
          },
          labe2: {
            fontWeight: 500,
            fontSize: 16,
            color: '#2b2d33',
          },
        };
      case 'Contemporary':
        return {
          root: {
            marginBottom: '10vh',
          },
          content1: {
            height: '100vh',
            width: '100%',
          },

          content: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexWrap: 'wrap-reverse',
            },
          },

          productCard: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            margin: '0 63px',
            [theme.breakpoints.down('sm')]: {
              margin: '0',
            },
          },

          productImage: {
            marginLeft: '1vw',
            margin: 20,
            width: '45%',
            borderRadius: '5px',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              height: 600,
              margin: 0,
              padding: '0 24px',
            },
            [theme.breakpoints.down('xs')]: {
              width: '100%',
              height: 330,
              margin: 0,
            },
          },

          productDetails: {
            margin: 'auto 7vw auto 2vw',
            // paddingTop: 80,
            width: '40%',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              padding: 0,
              margin: '15vh 0 0',
            },

            [theme.breakpoints.down('xs')]: {
              padding: '2em 0',
              marginTop: 30,
            },
          },

          productName1: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              justifyContent: 'center',
              fontSize: 36,
              color: '#54575f',
              fontFamily: storeInfo && storeInfo.font.family,
              textTransform: 'uppercase',
              width: '100%',
              wordWrap: 'break-word',
              marginBottom: 10,
            },
          },
          productName2: {
            fontSize: 36,
            color: '#54575f',
            fontFamily: storeInfo && storeInfo.font.family,
            width: '100%',
            wordWrap: 'break-word',
            [theme.breakpoints.down('xs')]: {
              fontSize: 24,
            },
          },

          price: {
            fontSize: props.product.discounted_price ? 28 : 32,

            [theme.breakpoints.down('xs')]: {
              fontSize: props.product.discounted_price ? 22 : 24,
            },
            textDecoration: props.product.discounted_price
              ? 'line-through'
              : 'auto',
            color: props.product.discounted_price
              ? 'rgba(0,0,0,0.5)'
              : '#54575F',
          },

          discounted_price: {
            fontSize: 32,
            color: '#54575F',

            // marginTop: 20,
            [theme.breakpoints.down('xs')]: {
              fontSize: 24,
              //   marginTop: 10
            },
          },
          imageStyle: {
            width: '100%',
            height: 475,
            [theme.breakpoints.down('sm')]: {
              height: 575,
              backgroundSize: 'cover',
            },
            [theme.breakpoints.down('xs')]: {
              height: 310,
              backgroundSize: 'cover',
            },
          },
          gallery: {
            margin: props.mobileView ? 0 : '0 50px',
            border: '1px solid #d1d5dd',
            borderRadius: 5,
          },
          btnMobile: {
            position: 'fixed',
            width: '100%',
            bottom: 0,
            backgroundColor: '#fff',
            padding: 20,
            zIndex: 3,
          },
          icon: {
            width: 24,
            height: 24,
            [theme.breakpoints.down('sm')]: {
              width: 45,
              height: 45,
            },
          },
          input: {
            backgroundColor: !disabled() ? '' : '#e6eaf0',
          },
          shareLayout: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              justifyContent: '',
            },
            [theme.breakpoints.down('xs')]: {
              justifyContent: mobileView ? 'space-between' : '',
            },
          },
          label: {
            fontWeight: 600,
            fontSize: 16,
            color: '#2b2d33',
          },
          labe2: {
            fontWeight: 500,
            fontSize: 16,
            color: '#2b2d33',
          },
        };
      case 'Minimalist':
        return {
          root: {
            paddingTop: '5em',
          },
          content1: {
            height: '100vh',
            width: '100%',
          },

          content: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              display: 'block',
            },
          },

          productCard: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            margin: '0 63px',
            [theme.breakpoints.down('sm')]: {
              margin: '0',
            },
          },

          productImage: {
            marginLeft: '1vw',
            margin: 20,
            width: '45%',
            borderRadius: '5px',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              height: 600,
              margin: 0,
              padding: '0 24px',
            },
            [theme.breakpoints.down('xs')]: {
              width: '100%',
              height: 330,
              margin: 0,
            },
          },

          productDetails: {
            margin: 50,
            // paddingTop: 80,
            width: '40%',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              padding: 0,
              marginLeft: 0,
            },

            [theme.breakpoints.down('xs')]: {
              padding: '2em 0',
            },
          },

          productName1: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              justifyContent: 'center',
              fontSize: 36,
              color: '#54575f',
              fontFamily: storeInfo && storeInfo.font.family,
              textTransform: 'uppercase',
              width: '100%',
              wordWrap: 'break-word',
              marginBottom: 10,
            },
          },
          productName2: {
            fontSize: 36,
            color: '#54575f',
            fontFamily: storeInfo && storeInfo.font.family,
            textTransform: 'uppercase',
            width: '100%',
            wordWrap: 'break-word',
            [theme.breakpoints.down('xs')]: {
              fontSize: 24,
            },
          },

          price: {
            fontSize: props.product.discounted_price ? 28 : 32,
            color: props.product.discounted_price
              ? 'rgba(0,0,0,0.5)'
              : '#54575F',

            [theme.breakpoints.down('xs')]: {
              fontSize: props.product.discounted_price ? 22 : 24,
              marginTop: 10,
            },
            textDecoration: props.product.discounted_price
              ? 'line-through'
              : 'auto',
          },

          discounted_price: {
            fontSize: 32,

            // marginTop: 20,
            color: '#54575F',
            [theme.breakpoints.down('xs')]: {
              fontSize: 24,
              //   marginTop: 10
            },
          },
          imageStyle: {
            width: '100%',
            height: 475,
            [theme.breakpoints.down('sm')]: {
              height: 575,
              backgroundSize: 'cover',
            },
            [theme.breakpoints.down('xs')]: {
              height: 310,
              backgroundSize: 'cover',
            },
          },
          gallery: {
            margin: props.mobileView ? 0 : '0 50px',
            border: '1px solid #d1d5dd',
            borderRadius: 5,
          },
          btnMobile: {
            position: 'fixed',
            width: '100%',
            bottom: 0,
            backgroundColor: '#fff',
            padding: 20,
            zIndex: 3,
          },
          icon: {
            width: 24,
            height: 24,
            [theme.breakpoints.down('sm')]: {
              width: 45,
              height: 45,
            },
          },
          input: {
            backgroundColor: !disabled() ? '' : '#e6eaf0',
          },
          shareLayout: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              justifyContent: '',
            },
            [theme.breakpoints.down('xs')]: {
              justifyContent: props.mobileView ? 'space-between' : '',
            },
          },
          label: {
            fontWeight: 600,
            fontSize: 16,
            color: '#2b2d33',
          },
          labe2: {
            fontWeight: 500,
            fontSize: 16,
            color: '#2b2d33',
          },
        };

      case 'ClassicV2':
        return {
          root: {
            marginTop: '5vh',
          },
          content: {
            display: 'flex',
            padding: '10vh 0',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              padding: 0,
            },
          },
          productImage: {
            width: '50%',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          },
          gallery: {
            padding: '0 50px',
            [theme.breakpoints.up('xl')]: {
              padding: '0 100px',
            },
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          productdetails: {
            width: '33%',
            marginLeft: '3vw',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              margin: 0,
              padding: '1em',
            },
          },
          name: {
            fontWeight: 'bold',
            fontSize: 36,
            color: '#54575f',
            fontFamily: storeInfo && storeInfo.font.family,
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
          },

          price: {
            fontSize: props.product.discounted_price ? 28 : 32,
            color: props.product.discounted_price
              ? 'rgba(0,0,0,0.5)'
              : '#3363bb',
            fontWeight: 500,

            [theme.breakpoints.down('sm')]: {
              fontSize: 18,
              marginTop: 0,
            },
            textDecoration: props.product.discounted_price
              ? 'line-through'
              : 'auto',
          },
          discounted_price: {
            fontSize: 32,
            color: '#3363bb',

            // marginTop: 20,
            [theme.breakpoints.down('xs')]: {
              fontSize: 24,
            },
          },
          label: {
            fontSize: 14,
            color: '#2b2d32',
            fontWeight: 500,
          },
          stock: {
            fontSize: 12,
            color: '#2b2d32',
          },
          input: {
            backgroundColor: !disabled() ? '' : '#e6eaf0',
            borderRadius: 0,
            margin: 0,
          },
          btnQuantity: {
            padding: 0,
            borderRadius: 0,
            height: 40,
            minWidth: 45,
          },
          addtocart: {
            marginTop: 30,
            color: '#fff',
            backgroundColor: color.secondary.main,
          },
          descriptionSection: {
            display: product ? (product.description ? '' : 'none') : 'none',
          },
          descriptionLabel: {
            fontSize: 16,
            color: '#2b2d32',
            fontWeight: 'bold',
          },
          description: {
            marginTop: 10,
            fontSize: 16,
            width: '100%',
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            color: '#2b2d32',
          },
          notesSection: {
            display: props.store
              ? props.store.order_instructions
                ? ''
                : 'none'
              : 'none',
          },
          related: {
            padding: '3em 5vw',
            backgroundColor: '#f1f1f1',
          },
          headerLabel: {
            fontSize: 28,
            color: '#2b2d32',
            fontWeight: 600,
            textAlign: 'center',
            width: '100%',
            fontFamily: storeInfo && storeInfo.font.family,
          },
          quantitySection: {
            [theme.breakpoints.down('sm')]: {
              alignItems: 'center',
            },
          },
          // stockSection: {
          //   [theme.breakpoints.down("sm")]: {
          //     display: "none"
          //   }
          // }
        };

      case 'ContemporaryV2':
        return {
          root: {
            marginTop: '5vh',
          },
          content: {
            display: 'flex',
            padding: '15vh 0',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              padding: '8vh 0',
            },
          },
          productImage: {
            width: '50%',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          },
          gallery: {
            padding: '0 50px',
            [theme.breakpoints.up('xl')]: {
              padding: '0 100px',
            },
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          productdetails: {
            width: '33%',
            marginLeft: '3vw',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              margin: 0,
              padding: '1em',
            },
          },
          name: {
            fontWeight: 300,
            fontSize: 36,
            color: '#54575f',
            fontFamily: storeInfo && storeInfo.font.family,
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
          },

          price: {
            fontSize: props.product.discounted_price ? 16 : 20,
            color: props.product.discounted_price
              ? 'rgba(0,0,0,0.5)'
              : color.secondary.main,
            fontWeight: 500,

            textDecoration: props.product.discounted_price
              ? 'line-through'
              : 'auto',
          },
          discounted_price: {
            fontSize: 32,
            // color: "#3363bb",

            // marginTop: 20,
            color: '#54575F',
            [theme.breakpoints.down('xs')]: {
              fontSize: 24,
            },
          },
          label: {
            fontSize: 14,
            color: '#2b2d32',
            fontWeight: 500,
          },
          stock: {
            fontSize: 12,
            color: '#2b2d32',
          },
          outOfStock: {
            fontSize: 12,
            color: 'red',
          },
          input: {
            backgroundColor: !disabled() ? '' : '#e6eaf0',
            borderRadius: 0,
            margin: 0,
          },
          btnQuantity: {
            padding: 0,
            borderRadius: 0,
            height: 40,
            minWidth: 45,
          },
          addtocart: {
            marginTop: 40,
            color: '#fff',
            borderRadius: 0,
            backgroundColor: color.secondary.main,
          },
          descriptionSection: {
            display: product ? (product.description ? '' : 'none') : 'none',
          },
          descriptionLabel: {
            fontSize: 16,
            color: '#2b2d32',
            fontWeight: 'bold',
          },
          description: {
            marginTop: 10,
            fontSize: 14,
            width: '100%',
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            color: '#2b2d32',
          },
          notesSection: {
            display: props.store
              ? props.store.order_instructions
                ? ''
                : 'none'
              : 'none',
          },
          related: {
            padding: '0 5vw 3em',
            backgroundColor: '#fff',
          },
          headerLabel: {
            fontSize: 28,
            color: '#2b2d32',
            fontWeight: 'normal',
            textAlign: 'center',
            width: '100%',
            fontFamily: storeInfo && storeInfo.font.family,
          },
          quantitySection: {
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          },
          // stockSection: {
          //   [theme.breakpoints.down("sm")]: {
          //     display: "none"
          //   }
          // },
          thumnails: {},
          originalImage: {
            width: '85%',
          },
        };
      case 'Leftist':
        return {
          root: {
            marginTop: 0,
          },
          content: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              display: 'block',
            },
          },
          productImage: {
            width: '50%',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          },
          gallery: {
            '& .image-gallery-thumbnail': {
              width: '33.3%',
            },
            '& .image-gallery-thumbnails': {
              padding: 0,
            },
          },
          productdetails: {
            width: '50%',
            padding: '3em',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              margin: 0,
              padding: '1em',
            },
          },
          name: {
            fontWeight: 300,
            fontSize: 36,
            color: '#54575f',
            fontFamily: storeInfo && storeInfo.font.family,
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
          },

          price: {
            fontSize: props.product.discounted_price ? 16 : 20,
            color: props.product.discounted_price
              ? 'rgba(0,0,0,0.5)'
              : color.secondary.main,
            fontWeight: 500,

            textDecoration: props.product.discounted_price
              ? 'line-through'
              : 'auto',
          },
          discounted_price: {
            fontSize: 32,
            // color: "#3363bb",

            // marginTop: 20,
            color: '#54575F',
            [theme.breakpoints.down('xs')]: {
              fontSize: 24,
            },
          },
          label: {
            fontSize: 14,
            color: '#2b2d32',
            fontWeight: 500,
          },
          stock: {
            fontSize: 12,
            color: '#2b2d32',
          },
          outOfStock: {
            fontSize: 12,
            color: 'red',
          },
          input: {
            backgroundColor: !disabled() ? '' : '#e6eaf0',
            borderRadius: 0,
            margin: 0,
          },
          btnQuantity: {
            padding: 0,
            borderRadius: 0,
            height: 40,
            minWidth: 45,
          },
          addtocart: {
            marginTop: 40,
            color: '#fff',
            borderRadius: 0,
            backgroundColor: color.secondary.main,
          },
          descriptionSection: {
            display: product ? (product.description ? '' : 'none') : 'none',
          },
          descriptionLabel: {
            fontSize: 16,
            color: '#2b2d32',
            fontWeight: 'bold',
          },
          description: {
            marginTop: 10,
            fontSize: 14,
            width: '100%',
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            color: '#2b2d32',
          },
          notesSection: {
            display: props.store
              ? props.store.order_instructions
                ? ''
                : 'none'
              : 'none',
          },
          related: {
            padding: '3em 5vw 3em',
            backgroundColor: '#fff',
          },
          headerLabel: {
            fontSize: 28,
            color: '#2b2d32',
            fontWeight: 700,
            textAlign: 'center',
            width: '100%',
            fontFamily: storeInfo && storeInfo.font.family,
          },
          quantitySection: {
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          },
          // stockSection: {
          //   [theme.breakpoints.down("sm")]: {
          //     display: "none"
          //   }
          // },
          thumnails: {},
          originalImage: {
            width: '85%',
          },
        };
      case 'SariSari':
        return {
          gallery: {
            padding: '0 50px',
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          name: {
            fontWeight: 'bold',
            fontSize: 36,
            color: color.primary.contrastText,
            fontFamily: storeInfo && storeInfo.font.family,
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
          },
          description: {
            fontSize: 16,
            fontWeight: 600,
            color: color.primary.contrastText,
          },
          label: {
            fontSize: 14,
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            color: color.primary.contrastText,
          },
          header: {
            fontSize: 18,
            fontWeight: 600,
            color: color.primary.contrastText,
          },
        };
      case 'Pastel':
        return {
          gallery: {
            marginRight: 50,
            [theme.breakpoints.down('sm')]: {
              margin: 0,
            },
          },
          name: {
            fontWeight: 'bold',
            fontSize: 36,
            color: color.primary.contrastText,
            fontFamily: storeInfo && storeInfo.font.family,
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
          },
          description: {
            fontSize: 16,
            fontWeight: 600,
            color: color.primary.contrastText,
          },
          label: {
            fontSize: 14,
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            color: color.primary.contrastText,
          },
          header: {
            fontSize: 18,
            fontWeight: 600,
            color: color.primary.contrastText,
          },
        };
      case 'MinimalistV2':
        return {
          gallery: {
            padding: '0 50px',
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          name: {
            fontWeight: 300,
            fontSize: 36,
            color: '#2b2d32',
            fontFamily: 'Inter',
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
          },
          description: {
            fontSize: 16,
            fontWeight: 600,
            color: '#2b2d32',
          },
          label: {
            fontSize: 14,
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            color: '#2b2d32',
          },
          header: {
            fontSize: 18,
            fontWeight: 600,
            color: '#2b2d32',
          },
          container: {
            [theme.breakpoints.down('sm')]: {
              padding: 0,
              margin: 0,
            },
          },
        };
      case 'Blocks':
        return {
          gallery: {
            padding: '0 50px',
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          name: {
            fontWeight: 700,
            fontSize: 36,
            color: '#2b2d32',
            fontFamily: 'Inter',
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
          },
          description: {
            fontSize: 16,
            fontWeight: 600,
            color: '#2b2d32',
          },
          label: {
            fontSize: 14,
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            color: '#2b2d32',
          },
          header: {
            fontSize: 18,
            fontWeight: 600,
            color: '#2b2d32',
          },
          container: {
            [theme.breakpoints.down('sm')]: {
              padding: 0,
              margin: 0,
            },
          },
        };
      case 'Greenery':
        return {
          gallery: {
            padding: '0 50px',
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          name: {
            fontWeight: 300,
            fontSize: 28,
            color: '#2b2d32',
            fontFamily: 'Inter',
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
          },
          description: {
            fontSize: 16,
            fontWeight: 600,
            color: '#2b2d32',
          },
          label: {
            fontSize: 14,
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            color: '#2b2d32',
          },
          header: {
            fontSize: 18,
            fontWeight: 600,
            color: '#2b2d32',
          },
          container: {
            [theme.breakpoints.down('sm')]: {
              padding: 0,
              margin: 0,
            },
          },
        };
      case 'Japandi':
        return {
          main: {
            width: '75%',
            left: '25%',
            position: 'absolute',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              position: 'inherit',
            },
          },
          gallery: {
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          name: {
            fontWeight: 300,
            fontSize: 36,
            color: '#2b2d32',
            fontFamily: 'Inter',
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
          },
          description: {
            fontSize: 14,
            fontWeight: 400,
            color: 'rgba(43, 45, 50, 0.64)',
          },
          label: {
            fontSize: 14,
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
            color: '#2b2d32',
          },
          header: {
            fontSize: 28,
            fontWeight: 300,
            color: '#2b2d32',
          },
          container: {
            [theme.breakpoints.down('sm')]: {
              padding: 0,
              margin: 0,
            },
          },
        };
      case 'NFT':
        return {
          main: {
            backgroundColor: '#051626',
            paddingTop: '2rem',
            color: '#fff',
            fontFamily: 'MedievalSharp, cursive !important',
            width: '75%',
            left: '25%',
            position: 'absolute',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              position: 'inherit',
            },
          },
          gallery: {
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          name: {
            fontWeight: 300,
            fontSize: 36,
            textTransform: 'capitalize',
            width: '100%',
            wordWrap: 'break-word',
            fontFamily: 'MedievalSharp, cursive !important',
          },
          description: {
            fontSize: 14,
            fontWeight: 400,
            color: 'rgba(43, 45, 50, 0.64)',
          },
          label: {
            fontSize: 14,
            wordWrap: 'break-word',
            whiteSpace: 'pre-line',
          },
          header: {
            fontSize: 28,
            fontWeight: 300,
          },
          container: {
            [theme.breakpoints.down('sm')]: {
              padding: 0,
              margin: 0,
            },
          },
        };
      default:
        return {};
    }
  };

  let templateStyles = makeStyles((theme) =>
    getStyles(props.store ? props.store.template : 'Blank', theme)
  );

  const classes = templateStyles();

  function setProductDetailsTemplate() {
    switch (props.store ? props.store.template : 'Blank') {
      case 'Blank':
        return <Blank />;
      case 'Classic':
        return (
          <Box className={classes.root}>
            <SnackbarAlert
              open={openAlert}
              alertType="success"
              message="Copied to clipboard!"
              closeAlertBox={closeAlertBox}
            />
            <Box className={classes.products}>
              <Container style={{ maxWidth: 1330, padding: 0 }}>
                <Box style={{ margin: '10vh 0' }}>
                  <div className={classes.content}>
                    <Typography variant="h4" className={classes.productName1}>
                      {props.product
                        ? props.product.name
                        : "Your Product's Name"}
                    </Typography>
                    <Box className={classes.productImage}>
                      <ImageGallery
                        additionalClass={classes.gallery}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={
                          props.productImages.length === 1 ? false : true
                        }
                        showThumbnails={
                          props.mobileView
                            ? false
                            : true && props.productImages.length === 1
                            ? false
                            : true
                        }
                        items={props.productImages}
                      />
                    </Box>

                    <Box className={classes.productDetails}>
                      <div style={{ padding: '0 24px' }}>
                        <Typography
                          variant="h4"
                          className={classes.productName2}
                        >
                          {props.product
                            ? props.product.name
                            : "Your Product's Name"}
                        </Typography>
                        <ProductForm
                          total={props.total}
                          store={storeInfo}
                          product={props.product}
                          onSubmit={handleAddtoCartNew}
                        />
                      </div>
                      <div
                        style={{
                          display: props.mobileView ? 'flex' : '',
                          flexDirection: props.mobileView
                            ? 'column-reverse'
                            : '',
                        }}
                      >
                        <div>
                          {mobileView ? (
                            <DescriptionTab
                              product={props.product}
                              store={props.store}
                            />
                          ) : props.product ? (
                            props.product.description ? (
                              <Box style={{ marginTop: 40, padding: '0 24px' }}>
                                <Typography
                                  display="block"
                                  gutterBottom
                                  className={classes.label}
                                >
                                  Description
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontFamily: 'Arimo, Sans-serif',
                                    color: '#54575F',
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {ReactHtmlParser(props.product.description)}
                                </Typography>
                              </Box>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                          {mobileView ? (
                            ''
                          ) : props.store ? (
                            props.store.order_instructions ? (
                              <Box style={{ marginTop: 40, padding: '0 24px' }}>
                                <Typography
                                  display="block"
                                  gutterBottom
                                  className={classes.label}
                                >
                                  Notes:
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontFamily: 'Arimo, Sans-serif',
                                    color: '#54575F',
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {props.store.order_instructions}
                                </Typography>
                              </Box>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </div>

                        <Box
                          style={{
                            padding: '0 24px',
                            marginTop: props.mobileView ? 20 : 40,
                            display: props.mobileView ? 'block' : 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            display="block"
                            gutterBottom
                            className={classes.label}
                          >
                            Share
                          </Typography>
                          <div className={classes.shareLayout}>
                            <Box
                              style={{ marginLeft: props.mobileView ? 0 : 20 }}
                            >
                              <FacebookShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={facebookIcon}
                                    className={classes.icon}
                                    alt="facebook"
                                  />
                                </IconButton>
                              </FacebookShareButton>
                            </Box>

                            <Box>
                              <ViberShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={viberIcon}
                                    className={classes.icon}
                                    alt="viber"
                                  />
                                </IconButton>
                              </ViberShareButton>
                            </Box>

                            <Box>
                              <WhatsappShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={whatsappIcon}
                                    className={classes.icon}
                                    alt="whatsapp"
                                  />
                                </IconButton>
                              </WhatsappShareButton>
                            </Box>

                            <Box>
                              <TwitterShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={twitterIcon}
                                    className={classes.icon}
                                    alt="twitter"
                                  />
                                </IconButton>
                              </TwitterShareButton>
                            </Box>
                            <Box>
                              <ShareLink />
                            </Box>
                          </div>
                        </Box>
                      </div>
                    </Box>
                  </div>
                </Box>
              </Container>
            </Box>
            {/* {props.mobileView ? (
              <Box className={classes.btnMobile}>
                <Button
                  id="add_to_cart"
                  disableElevation
                  onClick={() => props.addToCart(props.product)}
                  variant="contained"
                  fullWidth
                  style={{
                    fontWeight: "600",
                    backgroundColor: color.secondary.main,
                    color: "#fff"
                  }}
                  disabled={
                    props.product
                      ? props.product.always_available
                        ? false
                        : !props.quantity ||
                          props.quantityErrorText ||
                          props.formHasErrors ||
                          props.product.quantity - props.existingQuantity === 0
                      : !props.quantity ||
                        props.quantityErrorText ||
                        props.formHasErrors ||
                        props.product.quantity - props.existingQuantity === 0
                    // kyc === 0
                  }
                >
                  Add to cart
                </Button>
              </Box>
            ) : null} */}
          </Box>
        );
      case 'Contemporary':
        return (
          <Box className={classes.root}>
            <SnackbarAlert
              open={openAlert}
              alertType="success"
              message="Copied to clipboard!"
              closeAlertBox={closeAlertBox}
            />
            <Box className={classes.products}>
              <Container style={{ maxWidth: 1295, padding: 0 }}>
                <Box style={{ margin: '10vh 0' }}>
                  <Typography variant="h4" className={classes.productName1}>
                    {props.product ? props.product.name : "Your Product's Name"}
                  </Typography>
                  <div className={classes.content}>
                    <Box className={classes.productDetails}>
                      <div style={{ padding: '0 24px' }}>
                        <Typography
                          variant="h4"
                          className={classes.productName2}
                        >
                          {props.product
                            ? props.product.name
                            : "Your Product's Name"}
                        </Typography>
                        <ProductForm
                          total={props.total}
                          store={storeInfo}
                          product={props.product}
                          onSubmit={handleAddtoCartNew}
                        />
                      </div>
                      <div
                        style={{
                          display: props.mobileView ? 'flex' : '',
                          flexDirection: props.mobileView
                            ? 'column-reverse'
                            : '',
                        }}
                      >
                        <div>
                          {mobileView ? (
                            <DescriptionTab
                              product={props.product}
                              store={props.store}
                            />
                          ) : props.product ? (
                            props.product.description ? (
                              <Box style={{ marginTop: 40, padding: '0 24px' }}>
                                <Typography
                                  display="block"
                                  gutterBottom
                                  className={classes.label}
                                >
                                  Description
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontFamily: 'Arimo, Sans-serif',
                                    color: '#54575F',
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {ReactHtmlParser(props.product.description)}
                                </Typography>
                              </Box>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                          {mobileView ? (
                            ''
                          ) : props.store ? (
                            props.store.order_instructions ? (
                              <Box style={{ marginTop: 40, padding: '0 24px' }}>
                                <Typography
                                  display="block"
                                  gutterBottom
                                  className={classes.label}
                                >
                                  Notes:
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontFamily: 'Arimo, Sans-serif',
                                    color: '#54575F',
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {props.store.order_instructions}
                                </Typography>
                              </Box>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </div>

                        <Box
                          style={{
                            padding: '0 24px',
                            marginTop: props.mobileView ? 20 : 40,
                            display: props.mobileView ? 'block' : 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            display="block"
                            gutterBottom
                            className={classes.label}
                          >
                            Share
                          </Typography>
                          <div className={classes.shareLayout}>
                            <Box
                              style={{
                                marginLeft: props.mobileView ? 0 : 20,
                              }}
                            >
                              <FacebookShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={facebookIcon}
                                    className={classes.icon}
                                    alt="facebook"
                                  />
                                </IconButton>
                              </FacebookShareButton>
                            </Box>

                            <Box>
                              <ViberShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={viberIcon}
                                    className={classes.icon}
                                    alt="viber"
                                  />
                                </IconButton>
                              </ViberShareButton>
                            </Box>

                            <Box>
                              <WhatsappShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={whatsappIcon}
                                    className={classes.icon}
                                    alt="whatsapp"
                                  />
                                </IconButton>
                              </WhatsappShareButton>
                            </Box>

                            <Box>
                              <TwitterShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={twitterIcon}
                                    className={classes.icon}
                                    alt="twitter"
                                  />
                                </IconButton>
                              </TwitterShareButton>
                            </Box>
                            <Box>
                              <ShareLink />
                            </Box>
                          </div>
                        </Box>
                      </div>
                    </Box>

                    <Box className={classes.productImage}>
                      <ImageGallery
                        additionalClass={classes.gallery}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={
                          props.productImages.length === 1 ? false : true
                        }
                        showThumbnails={
                          props.mobileView
                            ? false
                            : true && props.productImages.length === 1
                            ? false
                            : true
                        }
                        items={props.productImages}
                      />
                    </Box>
                  </div>
                </Box>
              </Container>
            </Box>
            {/* {props.mobileView ? (
              <Box className={classes.btnMobile}>
                <Button
                  id="add_to_cart"
                  disableElevation
                  onClick={() => props.addToCart(props.product)}
                  variant="contained"
                  fullWidth
                  style={{
                    fontWeight: "600",
                    backgroundColor: color.secondary.main,
                    color: "#fff"
                  }}
                  disabled={
                    props.product
                      ? props.product.always_available
                        ? false
                        : !props.quantity ||
                          props.quantityErrorText ||
                          props.formHasErrors ||
                          props.product.quantity - props.existingQuantity === 0
                      : !props.quantity ||
                        props.quantityErrorText ||
                        props.formHasErrors ||
                        props.product.quantity - props.existingQuantity === 0
                    // kyc === 0
                  }
                >
                  Add to cart
                </Button>
              </Box>
            ) : null} */}
          </Box>
        );
      case 'Minimalist':
        return (
          <Box className={classes.root}>
            <SnackbarAlert
              open={openAlert}
              alertType="success"
              message="Copied to clipboard!"
              closeAlertBox={closeAlertBox}
            />
            <Box className={classes.products}>
              <Container style={{ maxWidth: 1330, padding: 0 }}>
                <Box>
                  <div className={classes.content}>
                    <Typography variant="h4" className={classes.productName1}>
                      {props.product
                        ? props.product.name
                        : "Your Product's Name"}
                    </Typography>
                    <Box className={classes.productImage}>
                      <ImageGallery
                        additionalClass={classes.gallery}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={
                          props.productImages.length === 1 ? false : true
                        }
                        showThumbnails={
                          props.mobileView
                            ? false
                            : true && props.productImages.length === 1
                            ? false
                            : true
                        }
                        items={props.productImages}
                      />
                    </Box>

                    <Box className={classes.productDetails}>
                      <div style={{ padding: '0 24px' }}>
                        <Typography
                          variant="h4"
                          className={classes.productName2}
                        >
                          {props.product
                            ? props.product.name
                            : "Your Product's Name"}
                        </Typography>
                        <ProductForm
                          total={props.total}
                          store={storeInfo}
                          product={props.product}
                          onSubmit={handleAddtoCartNew}
                        />
                      </div>
                      <div
                        style={{
                          display: props.mobileView ? 'flex' : '',
                          flexDirection: props.mobileView
                            ? 'column-reverse'
                            : '',
                        }}
                      >
                        <div>
                          {mobileView ? (
                            <DescriptionTab
                              product={props.product}
                              store={props.store}
                            />
                          ) : props.product ? (
                            props.product.description ? (
                              <Box style={{ marginTop: 40, padding: '0 24px' }}>
                                <Typography
                                  display="block"
                                  gutterBottom
                                  className={classes.label}
                                >
                                  Description
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    color: '#616161',
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {ReactHtmlParser(props.product.description)}
                                </Typography>
                              </Box>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}

                          {mobileView ? (
                            ''
                          ) : props.store ? (
                            props.store.order_instructions ? (
                              <Box style={{ marginTop: 40, padding: '0 24px' }}>
                                <Typography
                                  display="block"
                                  gutterBottom
                                  className={classes.label}
                                >
                                  Notes:
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    color: '#616161',
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {props.store.order_instructions}
                                </Typography>
                              </Box>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </div>
                        <Box
                          style={{
                            padding: '0 24px',
                            marginTop: props.mobileView ? 20 : 40,
                            display: props.mobileView ? 'block' : 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            display="block"
                            gutterBottom
                            className={classes.label}
                          >
                            Share
                          </Typography>
                          <div className={classes.shareLayout}>
                            <Box
                              style={{
                                marginLeft: props.mobileView ? 0 : 20,
                              }}
                            >
                              <FacebookShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={facebookIcon}
                                    className={classes.icon}
                                    alt="facebook"
                                  />
                                </IconButton>
                              </FacebookShareButton>
                            </Box>

                            <Box>
                              <ViberShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={viberIcon}
                                    className={classes.icon}
                                    alt="viber"
                                  />
                                </IconButton>
                              </ViberShareButton>
                            </Box>

                            <Box>
                              <WhatsappShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={whatsappIcon}
                                    className={classes.icon}
                                    alt="whatsapp"
                                  />
                                </IconButton>
                              </WhatsappShareButton>
                            </Box>

                            <Box>
                              <TwitterShareButton url={props.productLink}>
                                <IconButton style={{ padding: 8 }}>
                                  <img
                                    src={twitterIcon}
                                    className={classes.icon}
                                    alt="twitter"
                                  />
                                </IconButton>
                              </TwitterShareButton>
                            </Box>

                            <Box>
                              <ShareLink />
                            </Box>
                          </div>
                        </Box>
                      </div>
                    </Box>
                  </div>
                </Box>
              </Container>
            </Box>
            {/* {props.mobileView ? (
              <Box className={classes.btnMobile}>
                <Button
                  id="add_to_cart"
                  disableElevation
                  onClick={() => props.addToCart(props.product)}
                  // disabled={kyc == 0}
                  variant="contained"
                  fullWidth
                  style={{
                    fontWeight: "600",
                    backgroundColor: color.secondary.main,
                    color: "#fff"
                  }}
                  disabled={
                    props.product
                      ? props.product.always_available
                        ? false
                        : !props.quantity ||
                          props.quantityErrorText ||
                          props.formHasErrors ||
                          props.product.quantity - props.existingQuantity === 0
                      : !props.quantity ||
                        props.quantityErrorText ||
                        props.formHasErrors ||
                        props.product.quantity - props.existingQuantity === 0
                    // kyc === 0
                  }
                >
                  Add to cart
                </Button>{" "}
              </Box>
            ) : null} */}
          </Box>
        );

      case 'ClassicV2':
        return (
          <div className={classes.root}>
            <SnackbarAlert
              open={openAlert}
              alertType="success"
              message="Copied to clipboard!"
              closeAlertBox={closeAlertBox}
            />
            <div className={classes.content}>
              <div className={classes.productImage}>
                <ImageGallery
                  additionalClass={classes.gallery}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showBullets={props.productImages.length === 1 ? false : true}
                  showThumbnails={
                    props.mobileView
                      ? false
                      : true && productImages.length === 1
                      ? false
                      : true
                  }
                  items={productImages}
                />
              </div>
              <div className={classes.productdetails}>
                <div className={classes.name}>
                  {product ? product.name : "Your Product's Name"}
                </div>
                <ProductForm
                  total={props.total}
                  store={storeInfo}
                  product={props.product}
                  onSubmit={handleAddtoCartNew}
                />

                <div style={{ marginTop: props.mobileView ? 40 : 20 }}>
                  <div style={{ marginTop: 40 }}>
                    <div className={classes.descriptionSection}>
                      <div className={classes.descriptionLabel}>
                        Description
                      </div>
                      <div className={classes.description}>
                        {ReactHtmlParser(product.description)}
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 20 }}
                      className={classes.notesSection}
                    >
                      <div className={classes.descriptionLabel}>Notes</div>
                      <div className={classes.description}>
                        {props.store.order_instructions}
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: 45 }}>
                    <div className={classes.label}>Share</div>
                    <div style={{ display: 'flex', marginTop: 15 }}>
                      {shareButtons.map((item) => {
                        return <div>{item.component}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.related}>
              <div className={classes.headerLabel}>Related Products</div>
              {props.mobileView ? (
                <div
                  style={{
                    marginTop: '2vh',
                  }}
                >
                  {products ? (
                    <WingBlank>
                      <Carousel cellSpacing={10} slideWidth={0.8}>
                        {products
                          .filter(
                            (item) =>
                              item.category === product.category &&
                              item.name !== product.name
                          )
                          .slice(0, 4)
                          .map((item, label) => {
                            return (
                              <ProductCard
                                item={item}
                                key={label}
                                onSelect={props.onSelect}
                                store={props.store}
                              />
                            );
                          })}
                      </Carousel>
                    </WingBlank>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div
                  style={{
                    marginTop: '2vh',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {products && products.length
                    ? products
                        .filter(
                          (item) =>
                            item.category === product.category &&
                            item.name !== product.name
                        )
                        .slice(0, 4)
                        .map((item, label) => {
                          return (
                            <ProductCard
                              item={item}
                              key={label}
                              onSelect={props.onSelect}
                              store={props.store}
                            />
                          );
                        })
                    : ''}
                </div>
              )}
            </div>
          </div>
        );

      case 'ContemporaryV2':
        return (
          <div className={classes.root}>
            <SnackbarAlert
              open={openAlert}
              alertType="success"
              message="Copied to clipboard!"
              closeAlertBox={closeAlertBox}
            />
            <div className={classes.content}>
              <div className={classes.productImage}>
                <ImageGallery
                  thumbnailPosition={mobileView ? 'bottom' : 'left'}
                  additionalClass={classes.gallery}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showBullets={props.productImages.length === 1 ? false : true}
                  showThumbnails={
                    props.mobileView
                      ? false
                      : true && productImages.length === 1
                      ? false
                      : true
                  }
                  items={productImages}
                />
              </div>
              <div className={classes.productdetails}>
                <div className={classes.name}>
                  {product ? product.name : "Your Product's Name"}
                </div>
                <ProductForm
                  total={props.total}
                  store={storeInfo}
                  product={props.product}
                  onSubmit={handleAddtoCartNew}
                />

                <div style={{ marginTop: 40 }}>
                  <div style={{ marginTop: 40 }}>
                    <div className={classes.descriptionSection}>
                      <div className={classes.descriptionLabel}>
                        Description
                      </div>
                      <div className={classes.description}>
                        {ReactHtmlParser(product.description)}
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 20 }}
                      className={classes.notesSection}
                    >
                      <div className={classes.descriptionLabel}>Notes</div>
                      <div className={classes.description}>
                        {props.store.order_instructions}
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: 45 }}>
                    <div className={classes.label}>Share</div>
                    <div style={{ display: 'flex', marginTop: 15 }}>
                      {shareButtons.map((item) => {
                        return <div>{item.component}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.related}>
              <div className={classes.headerLabel}>Related Products</div>
              {props.mobileView ? (
                <div
                  style={{
                    marginTop: '2vh',
                  }}
                >
                  {products ? (
                    <WingBlank>
                      <Carousel cellSpacing={10} slideWidth={0.8}>
                        {products
                          .filter(
                            (item) =>
                              item.category === product.category &&
                              item.name !== product.name
                          )
                          .slice(0, 4)
                          .map((item, label) => {
                            return (
                              <ProductCard
                                item={item}
                                key={label}
                                onSelect={props.onSelect}
                                store={props.store}
                              />
                            );
                          })}
                      </Carousel>
                    </WingBlank>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div
                  style={{
                    marginTop: '4vh',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {products && products.length
                    ? products
                        .filter(
                          (item) =>
                            item.category === product.category &&
                            item.name !== product.name
                        )
                        .slice(0, 4)
                        .map((item, label) => {
                          return (
                            <div className="col-3">
                              <ProductCard
                                item={item}
                                key={label}
                                onSelect={props.onSelect}
                                store={props.store}
                              />
                            </div>
                          );
                        })
                    : ''}
                </div>
              )}
            </div>
          </div>
        );
      case 'Leftist':
        return (
          <div className={classes.root}>
            <SnackbarAlert
              open={openAlert}
              alertType="success"
              message="Copied to clipboard!"
              closeAlertBox={closeAlertBox}
            />
            <div className={classes.content}>
              <div className={classes.productImage}>
                <ImageGallery
                  thumbnailPosition="bottom"
                  additionalClass={classes.gallery}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showBullets={props.productImages.length === 1 ? false : true}
                  showThumbnails={
                    props.mobileView
                      ? false
                      : true && productImages.length === 1
                      ? false
                      : true
                  }
                  items={productImages}
                />
              </div>
              <div className={classes.productdetails}>
                <div className={classes.name}>
                  {product ? product.name : "Your Product's Name"}
                </div>
                <ProductForm
                  total={props.total}
                  store={storeInfo}
                  product={props.product}
                  onSubmit={handleAddtoCartNew}
                />

                <div style={{ marginTop: 40 }}>
                  <div style={{ marginTop: 40 }}>
                    <div className={classes.descriptionSection}>
                      <div className={classes.descriptionLabel}>
                        Description
                      </div>
                      <div className={classes.description}>
                        {ReactHtmlParser(product.description)}
                      </div>
                    </div>

                    <div
                      style={{ marginTop: 20 }}
                      className={classes.notesSection}
                    >
                      <div className={classes.descriptionLabel}>Notes</div>
                      <div className={classes.description}>
                        {props.store.order_instructions}
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: 45 }}>
                    <div className={classes.label}>Share</div>
                    <div style={{ display: 'flex', marginTop: 15 }}>
                      {shareButtons.map((item) => {
                        return <div>{item.component}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.related}>
              <div className={classes.headerLabel}>Related Products</div>
              {props.mobileView ? (
                <div
                  style={{
                    marginTop: '2vh',
                  }}
                >
                  {products ? (
                    <WingBlank>
                      <Carousel cellSpacing={10} slideWidth={0.8}>
                        {products
                          .filter(
                            (item) =>
                              item.category === product.category &&
                              item.name !== product.name
                          )
                          .slice(0, 3)
                          .map((item, label) => {
                            return (
                              <ProductCard
                                item={item}
                                key={label}
                                onSelect={props.onSelect}
                                store={props.store}
                              />
                            );
                          })}
                      </Carousel>
                    </WingBlank>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div
                  // style={{
                  //   marginTop: "4vh",
                  //   display: "flex",
                  //   justifyContent: "center"
                  // }}
                  className="row justify-content-center"
                >
                  {products && products.length
                    ? products
                        .filter(
                          (item) =>
                            item.category === product.category &&
                            item.name !== product.name
                        )
                        .slice(0, 3)
                        .map((item, label) => {
                          return (
                            <div className="col-4 pt-3">
                              <ProductCard
                                item={item}
                                key={label}
                                onSelect={props.onSelect}
                                store={props.store}
                              />
                            </div>
                          );
                        })
                    : ''}
                </div>
              )}
            </div>
          </div>
        );
      case 'SariSari':
        return (
          <div
            style={{ marginTop: '5em', backgroundColor: color.primary.main }}
          >
            <Container>
              <div className="py-4 d-md-flex col-12 p-0">
                <div className="col-md-6 col-12 px-0">
                  <ImageGallery
                    thumbnailPosition={'bottom'}
                    additionalClass={classes.gallery}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={
                      props.productImages.length === 1 ? false : true
                    }
                    showThumbnails={
                      props.mobileView
                        ? false
                        : true && productImages.length === 1
                        ? false
                        : true
                    }
                    items={productImages}
                  />
                </div>
                <div
                  className="col-md-6 col-12 px-md-5 px-0 pt-md-0 pt-4"
                  style={{ paddingRight: mobileView ? 0 : 120 }}
                >
                  <div className={classes.name}>
                    {product ? product.name : "Your Product's Name"}
                  </div>
                  <ProductForm
                    total={props.total}
                    store={storeInfo}
                    product={props.product}
                    onSubmit={handleAddtoCartNew}
                  />
                  <div className="mt-5">
                    {props.product && props.product.description ? (
                      <div>
                        <div className={`${classes.description} mb-3`}>
                          Description
                        </div>
                        <div className={classes.label}>
                          {ReactHtmlParser(product.description)}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {props.store && props.store.order_instructions ? (
                      <div>
                        <div className={`${classes.description} mt-4 mb-3`}>
                          Notes
                        </div>
                        <div className={classes.label}>
                          {props.store.order_instructions}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className={`${classes.description} mt-4 mb-3`}>
                      Share
                    </div>
                    <div className="d-flex mt-2">
                      {shareButtons.map((item) => {
                        return <div>{item.component}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-5 text-center">
                <div className={classes.header}>Related Products</div>
                {mobileView ? (
                  <div
                    style={{
                      marginTop: '2vh',
                    }}
                  >
                    {products ? (
                      <WingBlank>
                        <Carousel cellSpacing={10} slideWidth={0.8}>
                          {products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 5)
                            .map((item, label) => {
                              return (
                                <div className="text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })}
                        </Carousel>
                      </WingBlank>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mt-3 ">
                    <div className="row w-100">
                      {products && products.length
                        ? products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 5)
                            .map((item, label) => {
                              return (
                                <div className="d-flex justify-content-center px-0 py-2 col-3 text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </div>
        );
      case 'Pastel':
        return (
          <div
            className="px-md-5 p-0"
            style={{
              backgroundColor: mobileView
                ? '#fff'
                : color && color.primary.main,
            }}
          >
            <Container
              className="bg-white p-md-5 p-2"
              style={{ marginTop: '5em' }}
            >
              <div className="d-md-flex col-12 p-0">
                <div className="col-md-6 col-12 px-0">
                  <ImageGallery
                    thumbnailPosition={'bottom'}
                    additionalClass={classes.gallery}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={
                      props.productImages.length === 1 ? false : true
                    }
                    showThumbnails={
                      props.mobileView
                        ? false
                        : true && productImages.length === 1
                        ? false
                        : true
                    }
                    items={productImages}
                  />
                </div>
                <div
                  className="col-md-6 col-12 px-md-5 px-2 pt-md-0 pt-4"
                  style={{ paddingRight: mobileView ? 0 : 120 }}
                >
                  <div className={classes.name}>
                    {product ? product.name : "Your Product's Name"}
                  </div>
                  <ProductForm
                    total={props.total}
                    store={storeInfo}
                    product={props.product}
                    onSubmit={handleAddtoCartNew}
                  />
                  <div className="mt-5">
                    {props.product && props.product.description ? (
                      <div>
                        <div className={`${classes.description} mb-3`}>
                          Description
                        </div>
                        <div className={classes.label}>
                          {ReactHtmlParser(product.description)}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {props.store && props.store.order_instructions ? (
                      <div>
                        <div className={`${classes.description} mt-4 mb-3`}>
                          Notes
                        </div>
                        <div className={classes.label}>
                          {props.store.order_instructions}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className={`${classes.description} mt-4 mb-3`}>
                      Share
                    </div>
                    <div className="d-flex mt-2">
                      {shareButtons.map((item) => {
                        return <div>{item.component}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-5 text-center">
                <div className={classes.header}>Related Products</div>
                {mobileView ? (
                  <div
                    style={{
                      marginTop: '2vh',
                    }}
                  >
                    {products ? (
                      <WingBlank>
                        <Carousel cellSpacing={10} slideWidth={0.8}>
                          {products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 3)
                            .map((item, label) => {
                              return (
                                <ProductCard
                                  item={item}
                                  key={label}
                                  onSelect={props.onSelect}
                                  store={props.store}
                                />
                              );
                            })}
                        </Carousel>
                      </WingBlank>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mt-3">
                    {products && products.length
                      ? products
                          .filter(
                            (item) =>
                              item.category === product.category &&
                              item.name !== product.name
                          )
                          .slice(0, 3)
                          .map((item, label) => {
                            return (
                              <div className="m-2">
                                <ProductCard
                                  item={item}
                                  key={label}
                                  onSelect={props.onSelect}
                                  store={props.store}
                                />
                              </div>
                            );
                          })
                      : ''}
                  </div>
                )}
              </div>
            </Container>
          </div>
        );
      case 'MinimalistV2':
        return (
          <div style={{ marginTop: mobileView ? '3em' : '5em' }}>
            <Container className={classes.container}>
              <div className="pt-md-4 pt-0 pb-4 d-md-flex col-12 p-0">
                <div className="col-md-6 col-12 px-0">
                  <ImageGallery
                    thumbnailPosition={'bottom'}
                    additionalClass={classes.gallery}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={
                      props.productImages.length === 1 ? false : true
                    }
                    showThumbnails={
                      props.mobileView
                        ? false
                        : true && productImages.length === 1
                        ? false
                        : true
                    }
                    items={productImages}
                  />
                </div>
                <div
                  className="col-md-6 col-12 px-md-5 px-md-0 px-4 pt-md-0 pt-4"
                  style={{ paddingRight: mobileView ? 0 : 120 }}
                >
                  <div className={classes.name}>
                    {product ? product.name : "Your Product's Name"}
                  </div>
                  <ProductForm
                    total={props.total}
                    store={storeInfo}
                    product={props.product}
                    onSubmit={handleAddtoCartNew}
                    disableStoreColorText={true}
                  />
                  <div className="mt-5">
                    {props.product && props.product.description ? (
                      <div>
                        <div className={`${classes.description} mb-3`}>
                          Description
                        </div>
                        <div className={classes.label}>
                          {ReactHtmlParser(product.description)}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {props.store && props.store.order_instructions ? (
                      <div>
                        <div className={`${classes.description} mt-4 mb-3`}>
                          Notes
                        </div>
                        <div className={classes.label}>
                          {props.store.order_instructions}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className={`${classes.description} mt-4 mb-3`}>
                      Share
                    </div>
                    <div className="d-flex mt-2">
                      {shareButtons.map((item) => {
                        return <div>{item.component}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-5 text-center">
                <div className={classes.header}>Related Products</div>
                {mobileView ? (
                  <div
                    style={{
                      marginTop: '2vh',
                    }}
                  >
                    {products ? (
                      <WingBlank>
                        <Carousel cellSpacing={10} slideWidth={0.8}>
                          {products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 3)
                            .map((item, label) => {
                              return (
                                <div className="text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })}
                        </Carousel>
                      </WingBlank>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mt-3 ">
                    <div className="row w-100 justify-content-center">
                      {products && products.length
                        ? products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 3)
                            .map((item, label) => {
                              return (
                                <div className="d-flex justify-content-center px-0 py-2 col-3 text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </div>
        );
      case 'Blocks':
        return (
          <div style={{ marginTop: mobileView ? '3em' : '5em' }}>
            <Container className={classes.container}>
              <div className="pt-md-4 pt-0 pb-4 d-md-flex col-12 p-0">
                <div className="col-md-6 col-12 px-0">
                  <ImageGallery
                    thumbnailPosition={'bottom'}
                    additionalClass={classes.gallery}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={
                      props.productImages.length === 1 ? false : true
                    }
                    showThumbnails={
                      props.mobileView
                        ? false
                        : true && productImages.length === 1
                        ? false
                        : true
                    }
                    items={productImages}
                  />
                </div>
                <div
                  className="col-md-6 col-12"
                  style={{ padding: mobileView ? '2em' : '0 4em' }}
                >
                  <div className={classes.name}>
                    {product ? product.name : "Your Product's Name"}
                  </div>
                  <ProductForm
                    total={props.total}
                    store={storeInfo}
                    product={props.product}
                    onSubmit={handleAddtoCartNew}
                    disableStoreColorText={true}
                  />
                  <div className="mt-5">
                    {props.product && props.product.description ? (
                      <div>
                        <div className={`${classes.description} mb-3`}>
                          Description
                        </div>
                        <div className={classes.label}>
                          {ReactHtmlParser(product.description)}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {props.store && props.store.order_instructions ? (
                      <div>
                        <div className={`${classes.description} mt-4 mb-3`}>
                          Notes
                        </div>
                        <div className={classes.label}>
                          {props.store.order_instructions}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className={`${classes.description} mt-4 mb-3`}>
                      Share
                    </div>
                    <div className="d-flex mt-2">
                      {shareButtons.map((item) => {
                        return <div>{item.component}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-5 text-center">
                <div className={classes.header}>Related Products</div>
                {mobileView ? (
                  <div
                    style={{
                      marginTop: '2vh',
                    }}
                  >
                    {products ? (
                      <WingBlank>
                        <Carousel cellSpacing={10} slideWidth={0.8}>
                          {products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 4)
                            .map((item, label) => {
                              return (
                                <div className="text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })}
                        </Carousel>
                      </WingBlank>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mt-3 ">
                    <div className="row w-100 justify-content-center">
                      {products && products.length
                        ? products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 4)
                            .map((item, label) => {
                              return (
                                <div className="d-flex justify-content-center px-0 py-2 col-3 text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </div>
        );
      case 'Greenery':
        return (
          <div style={{ paddingTop: mobileView ? '6em' : '8em' }}>
            <Container className={classes.container}>
              <div className="pt-md-4 pt-0 pb-4 d-md-flex col-12 p-0">
                <div className="col-md-6 col-12 px-0">
                  <ImageGallery
                    thumbnailPosition={'bottom'}
                    additionalClass={classes.gallery}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={
                      props.productImages.length === 1 ? false : true
                    }
                    showThumbnails={
                      props.mobileView
                        ? false
                        : true && productImages.length === 1
                        ? false
                        : true
                    }
                    items={productImages}
                  />
                </div>
                <div
                  className="col-md-6 col-12"
                  style={{ padding: mobileView ? '1em' : '0 4em' }}
                >
                  <div className={classes.name}>
                    {product ? product.name : "Your Product's Name"}
                  </div>
                  <ProductForm
                    total={props.total}
                    store={storeInfo}
                    product={props.product}
                    onSubmit={handleAddtoCartNew}
                    disableStoreColorText={true}
                  />
                  <div className="mt-5">
                    {props.product && props.product.description ? (
                      <div>
                        <div className={`${classes.description} mb-3`}>
                          Description
                        </div>
                        <div className={classes.label}>
                          {ReactHtmlParser(product.description)}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {props.store && props.store.order_instructions ? (
                      <div>
                        <div className={`${classes.description} mt-4 mb-3`}>
                          Notes
                        </div>
                        <div className={classes.label}>
                          {props.store.order_instructions}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className={`${classes.description} mt-4 mb-3`}>
                      Share
                    </div>
                    <div className="d-flex mt-2">
                      {shareButtons.map((item) => {
                        return <div>{item.component}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-5 text-center">
                <div className={classes.header}>Related Products</div>
                {mobileView ? (
                  <div
                    style={{
                      marginTop: '2vh',
                    }}
                  >
                    {products ? (
                      <WingBlank>
                        <Carousel cellSpacing={10} slideWidth={0.8}>
                          {products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 4)
                            .map((item, label) => {
                              return (
                                <div className="text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })}
                        </Carousel>
                      </WingBlank>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mt-3 ">
                    <div className="row w-100 justify-content-center">
                      {products && products.length
                        ? products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 4)
                            .map((item, label) => {
                              return (
                                <div className="d-flex justify-content-center px-0 py-2 col-3 text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </div>
        );
      case 'Japandi':
        return (
          <div className={classes.main}>
            <Container className={classes.container}>
              <div className="pb-4 d-md-flex col-12 p-0">
                <div className="col-md-6 col-12 px-0">
                  <ImageGallery
                    thumbnailPosition={'bottom'}
                    additionalClass={classes.gallery}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={
                      props.productImages.length === 1 ? false : true
                    }
                    showThumbnails={
                      props.mobileView
                        ? false
                        : true && productImages.length === 1
                        ? false
                        : true
                    }
                    items={productImages}
                  />
                </div>
                <div
                  className="col-md-6 col-12"
                  style={{ padding: mobileView ? '1em' : '3em' }}
                >
                  <div className={classes.name}>
                    {product ? product.name : "Your Product's Name"}
                  </div>
                  <ProductForm
                    total={props.total}
                    store={storeInfo}
                    product={props.product}
                    onSubmit={handleAddtoCartNew}
                    disableStoreColorText={true}
                  />
                  <div className="mt-5">
                    {props.product && props.product.description ? (
                      <div>
                        <div className={`${classes.description} mb-3`}>
                          Description
                        </div>
                        <div className={classes.label}>
                          {ReactHtmlParser(product.description)}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {props.store && props.store.order_instructions ? (
                      <div>
                        <div className={`${classes.description} mt-4 mb-3`}>
                          Notes
                        </div>
                        <div className={classes.label}>
                          {props.store.order_instructions}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className={`${classes.description} mt-4 mb-3`}>
                      Share
                    </div>
                    <div className="d-flex mt-2">
                      {shareButtons.map((item) => {
                        return <div>{item.component}</div>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="py-5"
                style={{ textAlign: mobileView ? 'center' : 'left' }}
              >
                <div className={classes.header}>Related Products</div>
                {mobileView ? (
                  <div
                    style={{
                      marginTop: '2vh',
                    }}
                  >
                    {products ? (
                      <WingBlank>
                        <Carousel cellSpacing={10} slideWidth={0.8}>
                          {products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 3)
                            .map((item, label) => {
                              return (
                                <div className="text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })}
                        </Carousel>
                      </WingBlank>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div className="d-flex mt-3 ">
                    <div className="row w-100">
                      {products && products.length
                        ? products
                            .filter(
                              (item) =>
                                item.category === product.category &&
                                item.name !== product.name
                            )
                            .slice(0, 3)
                            .map((item, label) => {
                              return (
                                <div className="d-flex justify-content-center col-4 px-0 py-2 text-left">
                                  <ProductCard
                                    item={item}
                                    key={label}
                                    onSelect={props.onSelect}
                                    store={props.store}
                                  />
                                </div>
                              );
                            })
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            </Container>
            <Footer store={storeInfo} />
          </div>
        );
      case 'NFT':
        return <NFTDetails {...props} />;
      default:
        return <Blank />;
    }
  }

  return (
    <div>
      <DocumentMeta {...(isPreview ? '' : meta)}>
        <div>
          <Container>
            <PromoBanner />
          </Container>
        </div>
        {setProductDetailsTemplate(
          props.store ? props.store.template : 'Blank'
        )}
      </DocumentMeta>
    </div>
  );
};
export default ProductDetailsTemplate;
