import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import SentroLoading from "../../loading";
import voucherCard from "../../../../assets/images/voucher_webcard.svg";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import MobileVouchers from "./mobileVoucher/mobileVouchers";
import MobileCreateVoucher from "./mobileVoucher/mobileCreateVoucher";
import moment from "moment";
import MobileEditVoucher from "./mobileVoucher/mobileEditVoucher";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import MobileViewVoucher from "./mobileVoucher/mobileViewVoucher";
import {
  getVouchers,
  getStoreProductCategories,
  setSelectedVoucher,
  setVoucherFilters,
  filterVouchers
} from "./webBuilder/websiteBuilderActions";
import SnackbarAlert from "../../SnackbarAlert";
import history from "../../../../store/history";
import Path from "../../../../Paths";
import { Skeleton } from "antd";
import FreeTrial from "../../../subscription/freeTrial";
const CreateVoucherCard = props => {
  const { open, setOpen, loading } = props;
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 2em",
        marginTop: "8vh"
      },
      [theme.breakpoints.down("xs")]: {
        padding: 20,
        marginTop: "10vh"
      }
    },
    label: {
      padding: "0 15px",
      fontSize: 16,
      color: "#54575f",
      marginTop: "5vh",
      textAlign: "center"
    }
  }));

  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>Vouchers</div>
      <div style={{ padding: 20 }}>
        {loading ? (
          <Skeleton variant="rect" height="auto" width="100%" />
        ) : (
          <img src={voucherCard} style={{ width: "100%", height: "auto" }} />
        )}
        <div className={classes.label}>
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            "Boost your sales by creating your own promos using Sentro's vouchers feature. Start creating vouchers now!"
          )}
        </div>

        <Button
          disableElevation
          variant="contained"
          color="primary"
          fullWidth
          style={{
            height: 40,
            marginTop: "3vh",
            display: loading ? "none" : ""
          }}
          onClick={() => setOpen(true)}
        >
          Create a Voucher
        </Button>
      </div>
    </div>
  );
};

let WebVouchers = props => {
  let dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({}));
  const classes = useStyles();
  const theme = useTheme();
  const desktopView = useMediaQuery(theme.breakpoints.up("md"));
  let loading = useSelector(state => state.mobileDashboard.loading);
  const subscription = useSelector(state => state.subscription.subscription);
  const vouchers = useSelector(
    state => state.mobileDashboard.voucherListFilter
  );
  const voucherFilters = useSelector(
    state => state.mobileDashboard.voucherFilters
  );
  const stores = useSelector(state => state.mobileDashboard.stores);
  const productCategories = useSelector(
    state => state.mobileDashboard.product_categories
  );
  const [openCreateVoucher, setOpenCreateVoucher] = React.useState(false);
  const [openEditVoucher, setOpenEditVoucher] = React.useState(false);
  const [openViewVoucher, setOpenViewVoucher] = React.useState(false);
  const [categories, setCategories] = React.useState([]);

  const [state, setState] = React.useState({
    openAlert: false,
    alertType: "success",
    alertMessage: ""
  });
  const [values, setValues] = React.useState({
    voucher_code: "",
    voucher_type: "",
    discount_value: "",
    applies_to: "",
    category: "",
    minimum_requirements: "",
    minimum_purchase_amount: "",
    usage_limit_to_one: false,
    usage_limit_no_times: false,
    usage_limit_value: 0,
    start_datetime: new Date(),
    start_time: moment()
      .toDate()
      .getTime(),
    end_datetime: new Date(),
    end_time: moment()
      .toDate()
      .getTime()
  });

  function closeAlertBox() {
    setState({
      ...state,
      openAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  }

  function handleEditVoucher(voucher) {
    setValues(voucher);
    dispatch(setSelectedVoucher(voucher));
    setOpenEditVoucher(true);
  }

  function handleViewVoucher(voucher) {
    setValues(voucher);
    dispatch(setSelectedVoucher(voucher));
    setOpenViewVoucher(true);
  }
  const [position, setPosition] = React.useState(0);
  useScrollPosition(({ prevPos, currPos }) => {
    setPosition(currPos.y);
  });

  React.useEffect(() => {
    dispatch(getVouchers());
  }, []);

  React.useEffect(() => {
    if (voucherFilters.startDate && voucherFilters.endDate) {
      fetch_vouchers(voucherFilters);
    } else {
      fetch_vouchers(voucherFilters);
    }
  }, [voucherFilters]);
  React.useEffect(() => {
    if (desktopView) {
      history.push(Path.VOUCHERS);
    }
  }, [desktopView]);

  const setFilters = filters => {
    dispatch(setVoucherFilters(filters));
  };

  const fetch_vouchers = params => {
    dispatch(filterVouchers(params));
  };

  return (
    <div
      style={{
        backgroundColor: "#fafafa",
        overflowY: "hidden",
        height: "93vh"
      }}
    >
      <SentroLoading open={loading} />
      {!subscription.subscription_type ||
      (subscription.subscription_type && !subscription.features.vouchers) ? (
        <div className="px-3" style={{ paddingTop: "10vh" }}>
          <FreeTrial
            header="Give your buyers a discount"
            subheader="Attract sales when you create vouchers and plan the best deals for your products."
          />
        </div>
      ) : (
        <div>
          {vouchers.list.length > 0 ? (
            <div id="voucher_list">
              <MobileVouchers
                openCreateVoucher={openCreateVoucher}
                setOpenCreateVoucher={setOpenCreateVoucher}
                values={values}
                setValues={setValues}
                handleEditVoucher={handleEditVoucher}
                handleViewVoucher={handleViewVoucher}
                setAlert={setState}
                alert={state}
              />
            </div>
          ) : (
            <CreateVoucherCard
              open={openCreateVoucher}
              setOpen={setOpenCreateVoucher}
              loading={loading}
            />
          )}
        </div>
      )}

      <MobileCreateVoucher
        id="create_voucher"
        open={openCreateVoucher}
        setOpen={setOpenCreateVoucher}
        values={values}
        setValues={setValues}
        state={state}
        setState={setState}
        categories={categories}
      />

      <MobileEditVoucher
        id="edit_voucher"
        open={openEditVoucher}
        setOpen={setOpenEditVoucher}
        values={values}
        setValues={setValues}
        categories={categories}
        state={state}
        setState={setState}
      />

      <MobileViewVoucher
        id="view_voucher"
        open={openViewVoucher}
        setOpen={setOpenViewVoucher}
        values={values}
        setValues={setValues}
        categories={categories}
        handleEditVoucher={handleEditVoucher}
        state={state}
        setState={setState}
      />

      <SnackbarAlert
        open={state.openAlert}
        alertType={state.alertType}
        message={state.alertMessage}
        closeAlertBox={closeAlertBox}
      />
    </div>
  );
};

export default WebVouchers;
