import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { Close, Computer, Smartphone } from "@material-ui/icons";
import sample from "../../../../assets/images/template assets/preview/classicV2-desktop.jpg";
import sample2 from "../../../../assets/images/template assets/preview/classicV2-mobile.jpg";
import { useTheme } from "@material-ui/styles";
const Transition = props => <Slide direction="up" {...props} />;
export default function PreviewTemplate(props) {
  const [view, setView] = React.useState("desktop");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles(theme => ({
    dialog: {
      padding: "50px 50px 0",
      borderRadius: "8px 8px 0 0"
    },
    preview: {
      height: isMobile ? "100vh" : "82vh",
      backgroundColor: "#d4d5d8",
      padding: view === "desktop" ? "0 8vw" : "0 34vw",
      overflowY: "scroll",
      overflowX: "hidden"
    },
    image: {
      height: "auto",
      width: "100%"
    }
  }));

  const classes = useStyles();

  console.log(props.open);
  const form = (
    <>
      <AppBar
        className="bg-white position-relative"
        style={{ boxShadow: "none" }}
      >
        <Toolbar className="d-flex align-items-center justify-content-between">
          <div style={{ fontWeight: 600, fontSize: 18, color: "#2b2d32" }}>
            Preview template
          </div>
          {isMobile ? (
            ""
          ) : (
            <div className="d-flex position-absolute w-100 justify-content-center">
              <Button
                disableElevation
                color="primary"
                variant="contained"
                style={{
                  borderRadius: "4px 0 0 4px",
                  height: 40,
                  backgroundColor: view === "desktop" ? "#f97663" : "#fff",
                  color: view === "desktop" ? "#fff" : "#f97663",
                  border: `1px solid #f97663`
                }}
                onClick={() => setView("desktop")}
              >
                Desktop
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                style={{
                  borderLeft: "none",
                  borderRadius: "0 4px 4px 0",
                  height: 40,
                  backgroundColor: view === "mobile" ? "#f97663" : "#fff",
                  color: view === "mobile" ? "#fff" : "#f97663",
                  border: `1px solid #f97663`
                }}
                onClick={() => setView("mobile")}
              >
                Mobile
              </Button>
            </div>
          )}

          <IconButton
            onClick={() => props.setOpen({ ...props.open, preview: false })}
          >
            <Close color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.preview}>
        <img
          src={
            view === "desktop"
              ? props.open.template.desktop
              : props.open.template.mobile
          }
          className={classes.image}
        />
        {isMobile ? (
          <div
            className="position-absolute d-flex"
            style={{ right: 24, bottom: 24, flexDirection: "column" }}
          >
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => setView("desktop")}
              style={{
                backgroundColor: view === "desktop" ? "#f97663" : "#fff",
                border: `1px solid #f97663`
              }}
            >
              <Computer
                style={{ color: view === "desktop" ? "#fff" : "#f97663" }}
              />
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => setView("mobile")}
              style={{
                marginTop: 10,
                backgroundColor: view === "mobile" ? "#f97663" : "#fff",
                border: `1px solid #f97663`
              }}
            >
              <Smartphone
                style={{ color: view === "mobile" ? "#fff" : "#f97663" }}
              />
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );

  return (
    <div>
      {isMobile ? (
        <Dialog
          fullScreen
          open={props.open.preview}
          TransitionComponent={Transition}
        >
          {form}
        </Dialog>
      ) : (
        <Dialog
          fullWidth
          fullScreen
          maxWidth="xl"
          open={props.open.preview}
          className={classes.dialog}
          TransitionComponent={Transition}
          PaperProps={{
            style: { borderRadius: "8px 8px 0 0" }
          }}
        >
          {form}
        </Dialog>
      )}
    </div>
  );
}
