import React from "react";
import {
  Paper,
  Card,
  CardActions,
  Collapse,
  Box,
  Typography,
  IconButton,
  makeStyles,
  Divider,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button
} from "@material-ui/core";
import logo1 from "../../assets/images/OTC_channels/7Eleven.png";
import logo2 from "../../assets/images/OTC_channels/BayadCenterLogo.png";
import logo3 from "../../assets/images/OTC_channels/CebuanaLogo.png";
import logo4 from "../../assets/images/OTC_channels/ECPay.png";
import logo5 from "../../assets/images/OTC_channels/ExpressPay.png";
import logo6 from "../../assets/images/OTC_channels/LBC.png";
import logo7 from "../../assets/images/OTC_channels/mlhuillier.png";
import logo8 from "../../assets/images/OTC_channels/Palawan.png";
import logo9 from "../../assets/images/OTC_channels/RD.png";
import logo10 from "../../assets/images/OTC_channels/robinsons.png";
import logo11 from "../../assets/images/OTC_channels/Rural.png";
import logo12 from "../../assets/images/OTC_channels/SM.png";
import ussc from "../../assets/images/OTC_channels/ussc.png";
import rustan from "../../assets/images/OTC_channels/rustan.png";
import shopwise from "../../assets/images/OTC_channels/shopwise.png";
import wellcome from "../../assets/images/OTC_channels/wellcome.png";
import tmbntng from "../../assets/images/OTC_channels/tmbntng.png";
import cvm from "../../assets/images/OTC_channels/cvm.png";

import clsx from "clsx";
import { ExpandMore, Add, CheckCircle } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../common/template/components/Footer";
import Utils from "../common/utils";
import Actions from "./Redux/Actions";
import SentroLoading from "../common/loading";
import poweredbyBux from "../../assets/images/poweredbybux.png";
import { bankListing } from "../websiteBuilder/forms/bankContants";
import moment from "moment";
import defaultSRC from "../common/template/components/defaultResources";
import defaultColor from "../common/template/components/colors";
import history from "../../store/history";
import { useLocation } from "react-router-dom";
import Path from "../../Paths"
// let [expandedB, setExpandedA] = React.useState(true)
let MarketplaceCheckoutDetails = props => {
  const location = useLocation();
  const currentPath = location.search;
  const storeNamePath = location.pathname;
  const reference_id = currentPath.replace("?ref_id=", "");
  const removeParam = storeNamePath.replace("/checkout", "");
  const storeNameParam = removeParam.replace("/shop/", "");

  React.useEffect(() => {
    async function fetchData() {
    //   await dispatch(StoreActions.getMyStoreInfo(storeNameParam));
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    dispatch(Actions.marketplaceCheckoutDetails(reference_id));
  }, []);

  let checkout_details = useSelector(state => state.marketplace.checkout_details);
  console.log("checkout details ----", checkout_details)

  let template = useSelector(state =>
    state.store.store ? state.store.store.template : "Classic"
  );
  let storeInfo = useSelector(state => state.store.store);
  let color = storeInfo.color === "null" ? null : storeInfo.color;
  let selectedColor = color
    ? color.includes("/")
      ? defaultColor[storeInfo ? color : "funBlue"].colorA
      : defaultColor[storeInfo ? color : "funBlue"].color
    : defaultSRC[storeInfo ? storeInfo.template : "Classic"].headerColor;

  let buttonColor = color
    ? color.includes("/")
      ? defaultColor[storeInfo ? color : "funBlue"].colorB
      : defaultColor[storeInfo ? color : "funBlue"].color
    : defaultSRC[storeInfo ? storeInfo.template : "Classic"].buttonColor;

  let secondaryFont =
    defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont;

  const ContentStyles = makeStyles(theme => ({
    checkoutDisplay: {
      // display: "flex",
      // flexWrap: "wrap",
      // [theme.breakpoints.down("md")]: {
      //   flexWrap: "nowrap"
      // },
      // [theme.breakpoints.down("sm")]: {
      //   flexWrap: "wrap"
      // }
    },
    value: {
      color: "#2B2D33",
      fontWeight: "bold",
      fontSize: 16,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont
    },
    value2: {
      marginLeft: 10,
      color: "#2B2D33",
      fontWeight: "bold",
      fontSize: 16,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0
      }
    },

    valueLink: {
      color: "#2B2D33",
      fontWeight: "bold",
      fontSize: 16,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      [theme.breakpoints.down("xs")]: {
        width: "55%",
        wordWrap: "break-word"
      }
    },

    amountToPay: {
      marginBottom: 0,
      fontWeight: "bold",
      fontSize: 24,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      textAlign: "center",
      marginLeft: 10,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0
      }
    },
    amountToPay2: {
      marginTop: 10,
      fontSize: 16,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
    },
    label: {
      fontWeight: "normal",
      fontSize: 16,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont
    },
    customerLabel: {
      width: "45%",
      fontWeight: "normal",
      fontSize: 16,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      }
    },
    customerValue: {
      width: "55%",
      color: "#2B2D33",
      fontWeight: "bold",
      fontSize: 16,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      }
    },
    cards: {
      maxWidth: "50%",
      margin: "0 auto",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25);",
      // flex: 12,
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        // marginRight: 0,
        // marginLeft: 0,
        maxWidth: "100%",
      },
    },
    layout: {
      background: "#FAFAFA",
      marginTop: 100,
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "5vh",
        paddingRight: "5vh"
      }
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    flex: {
      // flex: 12,
      // [theme.breakpoints.up("md")]: {
      //   flex: 6
      // }
      margin: "0 auto"
    },
    detailsDivWrapper: {
      margin: "0 auto",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },
    forceDisplayBlock: {
      display: "block !important"
    },
    deliveryDetailsViewWrapper: {
      margin: "0 auto 0 10px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto 0 auto"
      }
    },
    mobility: {
      display: "block",

      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },

    logisticsImages: {
      backgroundSize: "cover",
      width: 129,
      height: 129,
      borderRadius: 5,
      margin: 6,
      [theme.breakpoints.down("xs")]: {
        height: 72,
        width: 72
      }
    },
    paymentSuccess: {
      backgroundColor: "#fff",
      padding: 50,
      textAlign: "center",
      borderRadius: 5,
      border: "2px solid",
      borderColor: selectedColor,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25);"
    },
    header: {
      textTransform: "uppercase",
      fontSize: 36,
      color: "#002C42",
      [theme.breakpoints.down("sm")]: {
        fontSize: 24
      }
    },
    header2: {
      // textTransform: "uppercase",
      fontSize: 30,
      fontWeight: 600,
      color: "#002C42",
      [theme.breakpoints.down("sm")]: {
        fontSize: 24
      }
    },
    subheader: {
      fontSize: 20,
      color: "#2B2D33",
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        textAlign: "left",
        padding: "0px 15px"
      }
    },
    subheader2: {
      fontSize: 18,
      color: "#2B2D33",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        textAlign: "left",
        padding: "0px 15px"
      }
    },
    button: {
      backgroundColor: buttonColor,
      color: "#fff",
      width: 400
    },
    sectionHead: {
      display: "flex",
      justifyContent: "space-between",
      flex: 6,
      padding: 18,
      [theme.breakpoints.down("xs")]: {
        padding: 10
      }
    },
    sectionHeader: {
      fontSize: 22,
      color: "#002C42",
      fontWeight: 600,
      paddingLeft: 10,
      [theme.breakpoints.down("xs")]: {
        fontSize: 18
      }
    },
    collapseArea: {
      padding: "20px 30px",
      backgroundColor: "#FFF",
      [theme.breakpoints.down("xs")]: {
        padding: 20
      }
    },
    tableColumnItem: {
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      width: 240,
      textWrap: "wrap",
      wordWrap: "break-word"
    },
    tableColumn: {
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      width: 120,
      textWrap: "wrap",
      wordWrap: "break-word"
    },
    contentDisplay: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    contentDisplay2: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 10,
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    contentDisplay3: {
      display: "flex",
      marginTop: 15,
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    amount: {
      marginLeft: 10,
      fontSize: 24,
      textAlign: "center",
      color: defaultSRC[storeInfo ? storeInfo.template : "Classic"].headerColor,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0
      }
    },
    orderDesktopView: {
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    orderMobileView: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    logisticsSection: {
      display: "flex",
      padding: "0px 30px 30px",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 15px 30px"
      }
    },
    paymentContent: {
      marginTop: 15,
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    paymentMethodDesktop: {
      fontFamily: secondaryFont,
      fontSize: 16,
      fontWeight: 600,
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    paymentMethodMobile: {
      display: "none",

      [theme.breakpoints.down("xs")]: {
        padding: "10px 10px 0px",
        fontSize: 16,
        fontWeight: 600,
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center"
      }
    },
    paymentDetails: {
      padding: 20,
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        padding: 10
      }
    },
    paymentDetailsContent: {
      padding: "10px 30px",
      width: 400,
      [theme.breakpoints.down("xs")]: {
        padding: "0px 10px"
      }
    },
    payment: {
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    paymentGrid1: {
      padding: "30px 20px 0 20px",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        padding: "20px 0"
      }
    },
    barcodeImg: {
      width: "auto",
      height: 120,
      [theme.breakpoints.down("xs")]: {
        height: 170
      }
    },
    amountToPayContainer: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 20
      }
    },
    logoOTC: {
      maxWidth: 170,
      width: "auto",
    } 
  }));

  const classes = ContentStyles();

  let loading = useSelector(state => state.marketplace.loading);
  let id = useSelector(state => state.store.store);
  let [expandedA, setExpandedA] = React.useState(true);
  let [expandedB, setExpandedB] = React.useState(true);
  let [expandedC, setExpandedC] = React.useState(true);
  let [expandedD, setExpandedD] = React.useState(true);
  let [expandedE, setExpandedE] = React.useState(true);
  let payments = useSelector(state => state.store.payments);

  let checkoutItem = useSelector(state => state.store.buxCheckout);
  // let checkoutItem = {
  //   ref_id: "PNGPNG2f",
  //   description: "PNGPNG2f",
  //   ref_code: "9920-1141-6011",
  //   ref_img_url:
  //     "https://bux-api-dev-storage.s3.amazonaws.com/media/barcodes/9920-1141-6011.png",
  //   expiry: "Apr 24 2020, 12:04 AM",
  //   cart: `"[{\"name\":\"pangpang\",\"unit_price\":\"100.00\",\"amount\":100,\"quantity\":1}, {\"name\":\"pangpang\",\"unit_price\":\"100.00\",\"amount\":100,\"quantity\":1}]"`,
  //   address: "9G Tower 3, Salagao, Ivana, Batanes",
  //   full_name: "Kerwin Mercadal",
  //   mobile_number: "9999999999",
  //   recipient: "k@K.cim",
  //   subtotal: "100.00",
  //   other_fees: "20.00",
  // };

  // let cartitems = checkoutItem
  //   ? JSON.parse(checkoutItem.cart.replace('"', "").replace(']"', "]"))
  //   : [];

  let cartitems = JSON.parse(checkout_details ? checkout_details.cart : "[]");
  let subtotal = checkoutItem
    ? Utils.commafy(parseFloat(checkoutItem.subtotal))
    : 0;
  let transactionFee = checkoutItem
    ? Utils.commafy(parseFloat(checkoutItem.other_fees))
    : 0;

  let totalPurchase = parseFloat(
    cartitems.reduce((total, object) => {
      return total + object.quantity * object.unit_price;
    }, 0)
  );
  let amount = Utils.commafy(
    parseFloat(checkout_details.amount) + parseFloat(0)
  );

  // let transactionFee = Utils.commafy(parseFloat(20));
  let quantity = parseFloat(
    cartitems.reduce((total, object) => {
      console.log(total, object);
      return parseFloat(total + object.quantity);
    }, 0)
  );
  let dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
    //   dispatch(StoreActions.resetCart());
    };
  }, []);

  let otcLogo;
  let channel = checkout_details.channel;

  if (channel === "7-Eleven") {
    otcLogo = logo1;
  } else if (channel === "Bayad Center") {
    otcLogo = logo2;
  } else if (channel === "Cebuana Lhuillier") {
    otcLogo = logo3;
  } else if (channel === "ECPay / Expresspay") {
    otcLogo = logo4;
  } else if (channel === "LBC") {
    otcLogo = logo6;
  } else if (channel === "Robinsons Dept Store") {
    otcLogo = logo10;
  } else if (channel === "SM / Supermarket / Savemore") {
    otcLogo = logo12;
  } else if (channel === "RD Pawnshop") {
    otcLogo = logo9;
  } else if (channel === "RuralNet Banks") {
    otcLogo = logo11;
  } else if (channel === "Palawan Pawnshop") {
    otcLogo = logo8;
  } else if (channel === "ussc") {
    otcLogo = ussc;
  } else if (channel === "rustan") {
    otcLogo = rustan;
  } else if (channel === "shopwise") {
    otcLogo = shopwise;
  } else if (channel === "wellcome") {
    otcLogo = wellcome;
  } else if (channel === "tmbntng") {
    otcLogo = tmbntng;
  } else if (channel === "cvm") {
    otcLogo = cvm;
  }

  function howToPayView() {
    return (
      <Paper className={classes.cards}>
        <Card
          className={classes.root}
          style={{
            backgroundColor: "#FAFAFA",
            marginBottom: 3
          }}
        >
          <Card
            style={{
              backgroundColor: "#FAFAFA",
              marginBottom: 1
            }}
          >
            <CardActions disableSpacing className={classes.sectionHead}>
              <div className={classes.sectionHeader}>How to Pay?</div>
              <IconButton
                className={clsx(classes.expand, classes.mobility, {
                  [classes.expandOpen]: expandedA
                })}
                onClick={() => setExpandedA(!expandedA)}
                aria-expanded={expandedA}
                aria-label="show more"
              >
                <ExpandMore />
              </IconButton>
            </CardActions>
          </Card>
          <Collapse
            in={expandedA}
            timeout="auto"
            unmountOnExit
            style={{ backgroundColor: "#fff" }}
          >
            <Box>
              <div style={{ padding: "20px 30px" }}>
                <div style={{ display: "flex" }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: "#000",
                      fontFamily:
                        defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                          .secondaryFont
                    }}
                  >
                    Please proceed to the nearest{" "}
                    <span
                      style={{
                        color: "#1D82B8",
                        fontWeight: 600
                      }}
                    >
                      {checkout_details.channel}
                    </span>{" "}
                    and present this barcode or reference number to the cashier.
                  </Typography>
                </div>

                <div className="row align-items-center no-gutters my-3">
                  <div className="col-12 col-md-6 text-center">
                    <img src={otcLogo} className={`${classes.logoOTC} w-100 mx-auto`} alt="channel_otc"/>
                    <div className={`${classes.amountToPayContainer} text-center`}>
                      <Typography className={classes.amountToPay2}>
                        Amount To Pay
                      </Typography>
                      <h2 className={classes.amountToPay}> PHP {checkout_details.amount}</h2>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div style={{ textAlign: "center" }}>
                      <img
                        // src={checkout ? checkout.ref_img_url : ""}
                        src={checkout_details.ref_img_url}
                        className={classes.barcodeImg}
                        alt="barcode"
                      />

                      <div
                        style={{
                          marginTop: 10,
                          textAlign: "center",
                          fontWeight: 600,
                          fontFamily: secondaryFont
                        }}
                      >
                        {checkout_details.ref_code}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  color: "#fff",
                  fontWeight: 600,
                  backgroundColor: selectedColor,
                  // template == "Classic"
                  //   ? "#d1d5dd"
                  //   : template == "Minimalist"
                  //   ? "#909196"
                  //   : "#F1F1F1",
                  padding: "13px 30px",
                  marginTop: 20,
                  fontSize: 16
                }}
              >
                Payment Details
              </div>
              <div style={{ padding: "20px 30px 0" }}>
                <div className={classes.contentDisplay2}>
                  <div className={classes.label}>Payment Deadline:</div>
                  <div className={classes.value}>{checkout_details.expiry}</div>
                </div>
                <div className={classes.contentDisplay2}>
                  <div className={classes.label}>Reference Number:</div>
                  <div className={classes.value}>{checkout_details.ref_code}</div>
                </div>

                <Divider style={{ marginTop: 20 }} />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center"
                  }}
                >
                  <img
                    // src={checkout ? checkout.ref_img_url : ""}
                    src={poweredbyBux}
                    style={{
                      width: "auto",
                      height: 30,
                      margin: "10px 30px 0px"
                    }}
                  />
                </div>
              </div>
            </Box>
          </Collapse>
        </Card>
      </Paper>
    );
  }

  function orderDetailsView() {
    return (
      <Paper className={classes.cards}>
        <Card
          className={classes.root}
          style={{
            backgroundColor: "#FAFAFA",
            marginBottom: 3
          }}
        >
          <Card
            style={{
              backgroundColor: "#FAFAFA",
              marginBottom: 1
            }}
          >
            <CardActions disableSpacing className={classes.sectionHead}>
              <div className={classes.sectionHeader}>Order Details</div>
              <IconButton
                className={clsx(classes.expand, classes.mobility, {
                  [classes.expandOpen]: expandedB
                })}
                onClick={() => setExpandedB(!expandedB)}
                aria-expanded={expandedB}
                aria-label="show more"
              >
                <ExpandMore />
              </IconButton>
            </CardActions>
          </Card>
          <Collapse
            in={expandedB}
            timeout="auto"
            unmountOnExit
            className={classes.collapseArea}
          >
            <div>
              <div style={{ marginBottom: 20 }}>
                <div className={classes.contentDisplay}>
                  <div className={classes.label}>Transaction Date: </div>
                  <div className={classes.value2}>
                    {moment(new Date()).format(`MMMM DD, YYYY hh:mm a`)}
                  </div>
                </div>
                <div className={classes.contentDisplay3}>
                  <div className={classes.label}>Transaction No: </div>
                  <div className={classes.value2}>{checkout_details.ref_id}</div>
                </div>
                <div className={classes.contentDisplay3}>
                  <div className={classes.label}>Items:</div>
                  <div className={classes.value2}>{quantity}</div>
                </div>
              </div>
              <div className={classes.orderDesktopView}>
                <Table style={{ marginTop: 10 }}>
                  <TableHead style={{ borderTop: "1px solid #D1D5DD" }}>
                    <TableRow>
                      <TableCell
                        className={classes.tableColumnItem}
                        align="left"
                      >
                        Item
                      </TableCell>
                      <TableCell className={classes.tableColumn} align="center">
                        Unit Price
                      </TableCell>
                      <TableCell className={classes.tableColumn} align="center">
                        Quantity
                      </TableCell>
                      <TableCell
                        className={classes.tableColumn}
                        align="right"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cartitems.map((it, key) => {
                      return (
                        <TableRow key={it.name}>
                          <TableCell
                            className={classes.tableColumnItem}
                            component="th"
                            scope="row"
                            align="left"
                          >
                            <p
                              style={{
                                wordWrap: "break-word",
                                width: 200,
                                fontFamily: secondaryFont
                              }}
                            >
                              {it.name}
                            </p>
                          </TableCell>
                          <TableCell
                            className={classes.tableColumn}
                            align="center"
                          >
                            ₱ {Utils.commafy(it.unit_price)}
                          </TableCell>
                          <TableCell
                            className={classes.tableColumn}
                            align="center"
                          >
                            {it.quantity}
                          </TableCell>
                          <TableCell
                            className={classes.tableColumn}
                            align="right"
                          >
                            ₱{" "}
                            {Utils.commafy(
                              parseFloat(it.quantity * it.unit_price)
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    <TableRow>
                      <TableCell
                        className={classes.tableColumnItem}
                        component="th"
                        scope="row"
                        align="left"
                      ></TableCell>
                      <TableCell
                        className={classes.tableColumn}
                        align="center"
                      ></TableCell>
                      <TableCell className={classes.tableColumn} align="left">
                        <p style={{ fontFamily: secondaryFont }}>Subtotal</p>
                        <p style={{ fontFamily: secondaryFont }}>Other fees</p>
                      </TableCell>
                      <TableCell className={classes.tableColumn} align="right">
                        <p style={{ fontFamily: secondaryFont }}>
                          ₱ {Utils.commafy(checkout_details.amount)}
                        </p>
                        <p style={{ fontFamily: secondaryFont }}>
                          ₱ {Utils.commafy(0)}
                        </p>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableColumnItem}
                        component="th"
                        scope="row"
                        align="left"
                      ></TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableColumn}
                        align="center"
                      ></TableCell>
                      <TableCell
                        className={classes.tableColumn}
                        style={{ fontWeight: 600, borderBottom: "none" }}
                        align="left"
                      >
                        Total
                      </TableCell>
                      <TableCell
                        className={classes.tableColumn}
                        style={{ fontWeight: 600, borderBottom: "none" }}
                        align="right"
                      >
                        ₱ {amount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div className={classes.orderMobileView}>
                {cartitems.map((it, key) => {
                  return (
                    <div
                      style={{
                        borderTop: "1px solid #D1D5DD",
                        borderBottom: "1px solid #D1D5DD",
                        padding: 15
                      }}
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={2}>
                          {it.quantity}
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ display: "block" }}>
                            <p style={{ wordWrap: "break-word" }}>{it.name}</p>
                            <p>₱ {Utils.commafy(it.unit_price)}</p>
                          </div>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                          item
                          xs={4}
                        >
                          ₱{" "}
                          {Utils.commafy(
                            parseFloat(it.quantity * it.unit_price)
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}

                <div
                  style={{
                    borderTop: "1px solid #D1D5DD",
                    borderBottom: "1px solid #D1D5DD",
                    padding: 15
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={6}>
                      <div style={{ display: "block" }}>
                        <p>Subtotal</p>
                        <p>Other fees</p>
                      </div>
                    </Grid>
                    <Grid
                      style={{ display: "block", justifyContent: "flex-end" }}
                      item
                      xs={4}
                    >
                      <p
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        ₱ {Utils.commafy(subtotal)}
                      </p>
                      <p
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        ₱ {Utils.commafy(transactionFee)}
                      </p>
                    </Grid>
                  </Grid>
                </div>

                <div
                  style={{
                    borderTop: "1px solid #D1D5DD",
                    padding: 15
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={6}>
                      <div style={{ display: "block", fontWeight: 600 }}>
                        Total
                      </div>
                    </Grid>
                    <Grid
                      style={{
                        display: "flex",
                        fontWeight: 600,
                        justifyContent: "flex-end"
                      }}
                      item
                      xs={4}
                    >
                      ₱ {amount}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Collapse>
        </Card>
      </Paper>
    );
  }

  console.log("amount", checkout_details.amount)

  function deliveryDetailsView() {
    return (
      <div className={checkout_details.amount > 0 ? `${classes.cards}` : `${classes.deliveryDetailsViewWrapper} ${classes.cards}`} style={{boxShadow: "none" }}>
        <Paper>
          <Card
            className={classes.root}
            style={{
              backgroundColor: "#FAFAFA",
              marginBottom: 3
            }}
          >
            <Card
              style={{
                backgroundColor: "#FAFAFA",
                marginBottom: 1
              }}
            >
              <CardActions disableSpacing className={classes.sectionHead}>
                <div className={classes.sectionHeader}>Customer Details</div>
                <IconButton
                  className={clsx(classes.expand, classes.mobility, {
                    [classes.expandOpen]: expandedC
                  })}
                  onClick={() => setExpandedC(!expandedC)}
                  aria-expanded={expandedC}
                  aria-label="show more"
                >
                  <ExpandMore />
                </IconButton>
              </CardActions>
            </Card>
            <Collapse
              in={expandedC}
              timeout="auto"
              unmountOnExit
              style={{
                // padding: 20,
                backgroundColor: "#FFF"
              }}
            >
              <div style={{ padding: 30 }}>
                <div className={classes.contentDisplay}>
                  <div className={classes.customerLabel}>Name of Recipient:</div>
                  <div className={classes.customerValue}>
                    {checkout_details ? checkout_details.full_name : ``}
                  </div>
                </div>
                <div className={classes.contentDisplay3}>
                  <div className={classes.customerLabel}>Billing Address:</div>
                  <div className={classes.customerValue}>
                    {checkout_details ? checkout_details.address:``}
                  </div>
                </div>
                <div className={classes.contentDisplay3}>
                  <div className={classes.customerLabel}>Mobile Number:</div>
                  <div className={classes.customerValue}>
                    {checkout_details ? checkout_details.mobile_number:``}
                  </div>
                </div>
                <div className={classes.contentDisplay3}>
                  <div className={classes.customerLabel}>Email Address:</div>
                  <div className={classes.customerValue}>
                    {checkout_details ? checkout_details.recipient:``}
                  </div>
                </div>
              </div>
            </Collapse>
          </Card>
        </Paper>
        <div
          style={{
            marginTop: 10,
            textAlign: "center",
            padding: "0px 10px 10px",
            fontSize: 14,
            fontFamily:
              defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                .secondaryFont,
            color: "#54575F"
          }}
        >
          We have also sent these details to your indicated email. For
          payment concerns please email{" "}
          <a
            href="mailto:support+bux@ubx.ph"
            style={{
              fontWeight: 600,
              color: "#2B2D33",
              textDecoration: "underline"
            }}
          >
            support+bux@ubx.ph
          </a>
        </div>
      </div>
    );
  }

  function suggestedDeliveryView() {
    return (
      <Paper className={classes.cards}>
        <Card
          className={classes.root}
          style={{
            backgroundColor: "#FAFAFA",
            marginBottom: 3
          }}
        >
          <Card
            style={{
              backgroundColor: "#FAFAFA",
              marginBottom: 1
            }}
          >
            <CardActions disableSpacing className={classes.sectionHead}>
              <div className={classes.sectionHeader}>Delivery Arrangement</div>
              <IconButton
                className={clsx(classes.expand, classes.mobility, {
                  [classes.expandOpen]: expandedD
                })}
                onClick={() => setExpandedD(!expandedD)}
                aria-expanded={expandedD}
                aria-label="show more"
              >
                <ExpandMore />
              </IconButton>
            </CardActions>
          </Card>
          <Collapse
            in={expandedD}
            timeout="auto"
            unmountOnExit
            style={{
              // padding: 20,
              backgroundColor: "#FFF"
            }}
          >
            <div
              style={{
                padding: "20px 30px",
                backgroundColor: selectedColor,
                // template == "Classic"
                //   ? "#D1D5DD"
                //   : template == "Minimalist"
                //   ? "#909196"
                //   : "#F1F1F1",
                marginBottom: 20
              }}
            >
              <div style={{ color: "#fff" }} className={classes.value}>
                Note:{" "}
                <span style={{ fontWeight: "normal" }}>
                  Delivery fee is not included in the payment.
                </span>
              </div>
            </div>
            <div
              style={{
                padding: "0px 25px 25px",
                fontSize: 16,
                fontFamily:
                  defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                    .secondaryFont
              }}
            >
              We will inform you once items are ready for pick-up or delivery.
              Kindly coordinate with us for the delivery arrangement.
            </div>

            {/* <div className={classes.logisticsSection}>
              <div
                className={classes.logisticsImages}
                style={{
                  backgroundImage: `url(${"https://sentro-store-bucker.s3.us-east-2.amazonaws.com/mrspeedy.png"})`
                }}
              ></div>

              <div
                className={classes.logisticsImages}
                style={{
                  backgroundImage: `url(${"https://sentro-store-bucker.s3.us-east-2.amazonaws.com/grab.png"})`
                }}
              ></div>

              <div
                className={classes.logisticsImages}
                style={{
                  backgroundImage: `url(${"https://sentro-store-bucker.s3.us-east-2.amazonaws.com/angkas.png"})`
                }}
              ></div>

              <div
                className={classes.logisticsImages}
                style={{
                  backgroundImage: `url(${"https://sentro-store-bucker.s3.us-east-2.amazonaws.com/lalamove.png"})`
                }}
              ></div>
            </div> */}
          </Collapse>
        </Card>
      </Paper>
    );
  }
  function paymentMethodsView() {
    return (
      <Paper className={classes.cards}>
        <Card
          className={classes.root}
          style={{
            backgroundColor: "#FAFAFA",
            marginBottom: 3
          }}
        >
          <Card
            style={{
              backgroundColor: "#FAFAFA",
              marginBottom: 1
            }}
          >
            <CardActions disableSpacing className={classes.sectionHead}>
              <div className={classes.sectionHeader}>Other Payment Methods</div>
              <IconButton
                className={clsx(classes.expand, classes.mobility, {
                  [classes.expandOpen]: expandedE
                })}
                onClick={() => setExpandedE(!expandedE)}
                aria-expanded={expandedE}
                aria-label="show more"
              >
                <ExpandMore />
              </IconButton>
            </CardActions>
          </Card>
          <Collapse
            in={expandedE}
            timeout="auto"
            unmountOnExit
            className={classes.collapseArea}
          >
            <div>
              {payments
                ? payments.map((item, key) => {
                    let bankABV = bankListing.find(
                      element => element.name == item.bank_name
                    );
                    return (
                      <div
                        key={key}
                        style={{
                          border: "1px solid #909196",
                          borderRadius: 5,
                          marginBottom: 10,
                          marginTop: 10,
                          width: "100%"
                        }}
                      >
                        <div className={classes.paymentMethodMobile}>
                          {item.payment_method == "Bank"
                            ? item.bank_name
                            : item.payment_method}
                        </div>
                        <div className={classes.paymentDetails}>
                          <div>
                            {item.email != "null" &&
                            item.payment_method == "Bank" ? (
                              <div
                                style={{
                                  fontSize: 16,
                                  fontWeight: 500,
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: 130,
                                  height: 130,
                                  color: "white",
                                  backgroundColor: "#54575F",
                                  borderRadius: 4
                                }}
                              >
                                {bankABV.abbv}
                              </div>
                            ) : (
                              <img
                                src={item.qr_code_img}
                                height="130"
                                width="130"
                                style={{ borderRadius: 4 }}
                              />
                            )}
                          </div>

                          <div className={classes.paymentDetailsContent}>
                            <div className={classes.paymentMethodDesktop}>
                              {item.payment_method == "Bank"
                                ? item.bank_name
                                : item.payment_method}
                            </div>
                            {item.payment_method == "Bank" ? (
                              <div>
                                <div className={classes.paymentContent}>
                                  <div className={classes.label}>
                                    Account Name:
                                  </div>
                                  <div className={classes.value}>
                                    {item.account_name}
                                  </div>
                                </div>
                                <div className={classes.paymentContent}>
                                  <div className={classes.label}>
                                    Account Number:
                                  </div>
                                  <div className={classes.value}>
                                    {item.account_number}
                                  </div>
                                </div>
                              </div>
                            ) : item.payment_method == "PayPal" ? (
                              <div>
                                <div className={classes.paymentContent}>
                                  <div className={classes.label}>Link:</div>
                                  <div className={classes.valueLink}>
                                    {item.payment_link}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className={classes.paymentContent}>
                                  <div className={classes.label}>
                                    Mobile Number
                                  </div>
                                  <div className={classes.value}>
                                    {item.mobile_number}
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* <div style={{ fontSize: 16 }}>
                              {item.account_name}
                            </div>
                            <div style={{ fontSize: 16 }}>
                              {item.account_name != "" ? "Account Name" : ""}
                            </div>
                            <div style={{ fontSize: 16 }}>
                              {" "}
                              {item.account_number ||
                                item.payment_link ||
                                `${"+63"}${item.mobile_number}`}
                            </div>
                            <div style={{ fontSize: 16 }}>
                              {item.accountName != ""
                                ? "Account Name"
                                : item.payment_link != ""
                                ? "Payment Link"
                                : "Mobile Name"}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </Collapse>
        </Card>
      </Paper>
    );
  }

  function paymentDetailsView() {
    return (
      <Paper className={classes.cards}>
        <Card
          className={classes.root}
          style={{
            backgroundColor: "#FAFAFA",
            marginBottom: 3
          }}
        >
          <Card
            style={{
              backgroundColor: "#FAFAFA",
              marginBottom: 1
            }}
          >
            <CardActions disableSpacing className={classes.sectionHead}>
              <div className={classes.sectionHeader}>Payment Details</div>
              <IconButton
                className={clsx(classes.expand, classes.mobility, {
                  [classes.expandOpen]: expandedC
                })}
                onClick={() => setExpandedC(!expandedC)}
                aria-expanded={expandedC}
                aria-label="show more"
              >
                <ExpandMore />
              </IconButton>
            </CardActions>
          </Card>
          <Collapse
            in={expandedC}
            timeout="auto"
            unmountOnExit
            style={{
              // padding: 20,
              backgroundColor: "#FFF"
            }}
          >
            <div style={{ padding: 30 }}>
              <div className={classes.contentDisplay}>
                <div className={classes.customerLabel}>Payment Channel:</div>
                <div className={classes.customerValue}>
                  {checkout_details.channel}
                </div>
              </div>
              <div className={classes.contentDisplay3}>
                <div className={classes.customerLabel}>Transaction number:</div>
                <div className={classes.customerValue}>
                  {checkout_details.ref_id}
                </div>
              </div>
              <div className={classes.contentDisplay3}>
                <div className={classes.customerLabel}>Amount Paid:</div>
                <div className={classes.customerValue}>₱ {amount}</div>
              </div>
              <div className={classes.contentDisplay3}>
                <div className={classes.customerLabel}>Transaction Date:</div>
                <div className={classes.customerValue}>
                  {moment(new Date()).format(`MMMM DD, YYYY hh:mm a`)}
                </div>
              </div>
            </div>
          </Collapse>
        </Card>
      </Paper>
    );
  }

  function transactionSuccessDiv(){
    return (
      <Paper>
        <div style={{ padding: "3em", textAlign: "center", marginBottom: 20}}>
          <div>
              <CheckCircle
                style={{ width: 80, height: 80, color: "#F97663" }}
              />
            </div>
            <h1 className={classes.header2}>Transaction Successful</h1>
            <Typography className={classes.subheader2} style={{ marginBottom: "1em"}}>
              Thank you for purchasing items from Sentro Marketplace. Details of this
              transaction are sent to your email.
            </Typography>
            <Button color="primary" variant="contained" style={{ minWidth: "20em"}}
            onClick={() => history.push(Path.APP_MARKETPLACE)}>Go back to homepage</Button>
        </div>
      </Paper>
    )
  }

  function paymentOTC() {
    return (
      <div>
        { !loading ?
            checkout_details.amount > 0 ?
            <div
              style={{
                textAlign: "center",
                marginBottom: 20
              }}
            >
              <h1 className={classes.header}>Payment Instructions</h1>
              <Typography className={classes.subheader}>
                You have successfully placed your orders. Please refer below on how
                you can pay for the ordered items.
              </Typography>
            </div>
            : 
            transactionSuccessDiv()
          :
          ``
        }
        <div className={classes.checkoutDisplay}>
          {
            checkout_details.amount > 0 &&
            <div className={classes.flex}>
              {howToPayView()}
            </div>
          }
          <div className={`${classes.detailsDivWrapper} ${checkout_details.amount > 0 && classes.forceDisplayBlock}`} >
            {orderDetailsView()}
            {deliveryDetailsView()}
            {/* {paymentMethodsView()} */}
          </div>
        </div>
      </div>
    );
  }

  function paymentBanking() {
    return (
      <div>
        <div className={classes.paymentSuccess}>
          <div>
            <CheckCircle
              style={{ width: 100, height: 100, color: selectedColor }}
            />
          </div>
          <h1 className={classes.header2}>PAYMENT SUCCESSFUL</h1>
          <Typography className={classes.subheader2}>
            Thank you for purchasing items from our shop. Details of this
            transaction are sent to your email.
          </Typography>
          <div style={{ padding: 20 }}>
            <Button
              onClick={() => history.push(`/marketplace`)}
              variant="contained"
              className={classes.button}
            >
              Go back to marketplace
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 40 }}>
          <div className={classes.checkoutDisplay}>
            <div className={classes.flex}>
              {orderDetailsView()}
              {deliveryDetailsView()}
            </div>

            <div className={classes.flex}>
              {paymentDetailsView()}
              {/* {suggestedDeliveryView()} */}
            </div>

            <div
              style={{
                margin: "0 auto",
                padding: 20,
                fontSize: 14,
                fontFamily:
                  defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                    .secondaryFont,
                color: "#54575F"
              }}
            >
              We have also sent these details to your indicated email. For
              payment concerns please email{" "}
              <a
                href="mailto:support+bux@ubx.ph"
                style={{
                  fontWeight: 600,
                  color: "#2B2D33",
                  textDecoration: "underline"
                }}
              >
                support+bux@ubx.ph
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <SentroLoading open={loading} />
      <div className={classes.layout}>
        {checkout_details.channel === "Unionbank Internet Banking" ||
        checkout_details.channel === "RCBC Online Banking" ||
        checkout_details.channel === "Bank of the Philippine Islands"
          ? paymentBanking()
          : paymentOTC()}
      </div>
      <Footer isPreview={false} store={storeInfo} />
    </>
  );
};

export default MarketplaceCheckoutDetails;
