import { makeStyles } from "@material-ui/styles";

const BannerMobileStyles = makeStyles(theme => ({
  headerMobile: {
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "5vh 3vw 0",
    marginBottom: "3vh",
    display: "flex",
    fontSize: 23,
    color: "white"
  },
  subHeaderMobile: {
    fontWeight: 500,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    fontSize: "12px",
    color: "white"
  },
  getstartedMobile: {
    backgroundColor: "#fff",
    color: "#f97663",
    textTransform: "uppercase"
  }
}));

export default BannerMobileStyles;
