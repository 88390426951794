import React from "react";
import {
  Button,
  Paper,
  Chip,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Checkbox,
  IconButton,
  Link,
  useMediaQuery
} from "@material-ui/core";
import BuyerSideMenu from "./buyerSideMenu";
import BuyerAppbar from "./buyerAppbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "../../../store/history";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import sampleImg from "../../../assets/images/imgPlaceholder2.svg";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import tags from "../../../assets/images/tags.svg";
import { useSelector, useDispatch } from "react-redux";
import Path from "../../../Paths";
import apiConfig from "../../../common/apiConfig";
import Utils from "../../common/utils";
import BuyerActions from "./redux/actions";
import { WishlistItemPriceDisplay } from "../../common/template/components/PriceDisplay";
import {
  checkProductAvailability,
  disableCheckbox
} from "./common/checkProductAvailability";
import StoreActions from "../../common/template/components/StoreActions";
import VoucherModal from "./common/voucherModal";
import CartViewOrderModal from "./components/cartViewOrderModal";
import VariantPicker from "../../enterprise/components/cart/variantPicker";

const CartViewOrder = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      backgroundColor: "#fafafa"
    },
    side: {
      display: "flex",
      position: "fixed",
      height: "100%",
      zIndex: 3
    },
    content: {
      width: "100%",
      marginTop: 55,
      paddingLeft: "23vw",
      paddingRight: "6vw",
      paddingBottom: "10vh"
    },
    header: {
      width: "65%",
      paddingTop: "5vh",
      display: "flex",
      alignItems: "center",
      position: "relative"
    },
    headerLabel: {
      fontSize: 28,
      fontWeight: 600,
      color: "#002c42",
      width: "100%",
      textAlign: "center"
    },
    orderSection: {
      width: "65%"
    },
    cartHeaderSection: {
      padding: "18px 24px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #d1d5dd"
    },
    labelTitle: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149"
    },
    btnViewShop: {
      padding: "0 20px",
      fontWeight: 500,
      borderRadius: 20,
      height: 40
    },
    columnItem: {
      padding: "16px 0 16px 0",
      border: "none",
      width: "40%",
      fontSize: 14,
      fontWeight: 600,
      color: "rgba(43,45,50,0.8)"
    },
    column1: {
      width: "10%",
      border: "none",
      fontSize: 14,
      fontWeight: 600,
      color: "rgba(43,45,50,0.8)"
    },
    column2: {
      position: "relative",
      width: "20%",
      border: "none",
      fontSize: 14,
      fontWeight: 600,
      color: "rgba(43,45,50,0.8)"
    },
    itemSection: {
      display: "flex"
    },
    productImage: {
      width: 60,
      height: 60,
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    labelbold: {
      fontSize: 12,
      fontWeight: 600,
      color: "#2b2d32"
    },
    label: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2b2d32"
    },
    variantChip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    },
    btnQuantity: {
      borderRadius: 0,
      width: 25,
      height: 25,
      minWidth: 25
    },
    summary: {
      marginTop: "5vh",
      height: "fit-content",
      padding: 24
    },
    voucherLink: {
      color: "#54575f",
      fontSize: 14,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: 500
    },
    columnHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "0 0 2vh"
    },
    column: {
      borderBottom: "none",
      fontSize: 12,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0"
    },
    columnFooter: {
      borderTop: "1px dashed #d1d5dd",
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0 0"
    }
  }));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart_items = useSelector(
    state => state.buyerDashboard.cart_items?.cart
  );
  const selected_order = useSelector(
    state => state.buyerDashboard.selected_order
  );
  const cart = useSelector(state => state.buyerDashboard.selected_order?.items);
  const product_selected = useSelector(
    state => state.buyerDashboard.selected_checkout.items
  );
  const vouchers = useSelector(state => state.buyerDashboard.voucher_list);
  const voucher_discount = useSelector(
    state => state.buyerDashboard.voucher_discount
  );
  const amount = useSelector(
    state => state.buyerDashboard.selected_checkout.amount
  );
  const subtotal = useSelector(
    state => state.buyerDashboard.selected_checkout.subtotal
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [hasError, setHasError] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const all_cart_products = cart ? [...cart] : [];
  function productImage(item) {
    if (item.gallery) {
      if (item.gallery.length) {
        return item.gallery[0].image;
      } else {
        return sampleImg;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return sampleImg;
      }
    }
  }

  function handleProductSelected(item) {
    if (product_selected.map(item => item.id).includes(item.id)) {
      const index = product_selected.map(item => item.id).indexOf(item.id);
      const newSelected = [...product_selected];
      newSelected.splice(index, 1);
      dispatch(BuyerActions.setSelectedCheckout(newSelected));
    } else {
      dispatch(BuyerActions.setSelectedCheckout([...product_selected, item]));
    }
  }

  function removeFromCart(index) {
    dispatch(BuyerActions.removeFromCart(index));
  }

  function decrement(item) {
    let selected_order_items = [];
    for (let i in cart) {
      selected_order_items.push({
        ...cart[i].product_details,
        quantity: cart[i].quantity,
        stock: cart[i].product_details.quantity,
        variants: cart[i].variant,
        productSelection: cart[i].productSelection,
        id: cart[i].id
      });
    }
    let params = {
      ...item.product_details,
      productSelection: item.productSelection,
      variants: item.variant,
      stock: item.product_details.quantity,
      quantity: item.quantity - 1,
      id: item.id
    };
    dispatch(BuyerActions.updateQuantity(params, selected_order_items));
  }

  function increment(item) {
    let selected_order_items = [];
    for (let i in cart) {
      selected_order_items.push({
        ...cart[i].product_details,
        quantity: cart[i].quantity,
        stock: cart[i].product_details.quantity,
        variants: cart[i].variant,
        productSelection: cart[i].productSelection,
        id: cart[i].id
      });
    }
    let params = {
      ...item.product_details,
      productSelection: item.productSelection,
      variants: item.variant,
      stock: item.product_details.quantity,
      quantity: item.quantity + 1,
      id: item.id
    };
    dispatch(BuyerActions.updateQuantity(params, selected_order_items));
  }

  function getProduct(product) {
    let params = {
      ...product.product_details,
      quantity: product.quantity,
      stock: product.product_details.quantity,
      variants: product.variant,
      productSelection: product.productSelection,
      id: product.id
    };

    return params;
  }

  function checkStock(product) {
    let product_details = product.product_details;
    if (product_details.has_variants) {
      if (product.productSelection) {
        if (product.quantity >= product.productSelection.quantity) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (product_details.always_available) {
        return false;
      } else {
        if (product.quantity >= product_details.quantity) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  function isEqual(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  function handleConfirmVariant(product) {
    let selected_order_items = [];
    let productSelectionIds = product.productSelection.variants.map(i => i.id);
    let prevSelectionIds = product.prevSelection
      ? product.prevSelection.variants.map(i => i.id)
      : [];
    for (let i in cart) {
      selected_order_items.push({
        ...cart[i].product_details,
        quantity: cart[i].quantity,
        stock: cart[i].product_details.quantity,
        variants: cart[i].variant,
        productSelection: cart[i].productSelection,
        id: cart[i].id
      });
    }
    if (isEqual(productSelectionIds, prevSelectionIds)) {
      setAnchorEl(null);
    } else {
      dispatch(BuyerActions.updateItemVariant(product, selected_order_items));
      setAnchorEl(null);
    }
  }

  function getProduct(product) {
    let params = {
      ...product.product_details,
      quantity: product.quantity,
      stock: product.product_details.quantity,
      variants: product.variant,
      productSelection: product.productSelection,
      id: product.id
    };

    return params;
  }

  function checkStock(product) {
    let product_details = product.product_details;
    if (product_details.has_variants) {
      if (product.productSelection) {
        if (product.quantity >= product.productSelection.quantity) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (product_details.always_available) {
        return false;
      } else {
        if (product.quantity >= product_details.quantity) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  function getProduct(product) {
    let params = {
      ...product.product_details,
      quantity: product.quantity,
      stock: product.product_details.quantity,
      variants: product.variant,
      productSelection: product.productSelection,
      id: product.id
    };

    return params;
  }

  function checkStock(product) {
    let product_details = product.product_details;
    if (product_details.has_variants) {
      if (product.productSelection) {
        if (product.quantity >= product.productSelection.quantity) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (product_details.always_available) {
        return false;
      } else {
        if (product.quantity >= product_details.quantity) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  React.useEffect(() => {
    if (selected_order === "") {
      history.push(Path.BUYER_DASHBOARD);
    }
  }, [selected_order]);

  let products =
    cart_items &&
    cart_items
      .filter(item => item.store_data.uuid !== selected_order.store_data.uuid)
      .map(item => item.items);
  React.useEffect(() => {
    if (products) {
      for (let i in products) {
        for (let e in products[i]) {
          all_cart_products.push(products[i][e]);
        }
      }
    }
  }, [products]);

  React.useEffect(() => {
    let params = [];
    if (all_cart_products) {
      for (let i in all_cart_products) {
        params.push({
          uuid: all_cart_products[i].uuid,
          quantity: all_cart_products[i].quantity,
          variant: all_cart_products[i].variant,
          productSelection: all_cart_products[i].productSelection
        });
      }
    }
    if (selected_order) {
      dispatch(
        StoreActions.patchBuyerCart(
          params && params.length === 0 ? [{}] : params
        )
      );
    }
  }, [cart]);

  React.useEffect(() => {
    errors.length = 0;
    let items = product_selected;
    for (let i in items) {
      if (items[i].product_details.has_variants && items[i].productSelection) {
        if (items[i].productSelection.quantity <= 0) {
          errors.push(true);
        } else if (items[i].quantity > items[i].productSelection.quantity) {
          errors.push(true);
        } else {
          errors.push(false);
        }
      } else {
        if (items[i].always_available) {
          errors.push(false);
        } else if (items[i].product_details.stock <= 0) {
          errors.push(true);
        } else if (items[i].quantity > items[i].product_details.stock) {
          errors.push(true);
        }
      }
    }
    if (errors.includes(true)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [product_selected]);
  return (
    <>
      {selected_order ? (
        isMobile ? (
          <CartViewOrderModal />
        ) : (
          <div id="cart_view_order">
            <div className={classes.header}>
              <Button
                startIcon={<ArrowBackIcon color="primary" />}
                variant="text"
                color="primary"
                onClick={() => {
                  history.goBack();
                  window.scrollTo(0, 0);
                }}
                style={{ position: "absolute" }}
              >
                Back
              </Button>
              <div className={classes.headerLabel}>View Orders</div>
            </div>
            <div style={{ display: "flex" }}>
              <Paper style={{ marginTop: "5vh", width: "65%" }}>
                <div className={classes.cartHeaderSection}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.labelTitle}>
                      {selected_order ? selected_order.store_data.name : ""}
                    </div>
                    {/* <Chip style={{ marginLeft: "2vw" }} label="Sports & Travel" /> */}
                  </div>
                  <Button
                    color="primary"
                    variant="text"
                    className={classes.btnViewShop}
                    startIcon={<StorefrontIcon color="primary" />}
                    onClick={() =>
                      window.open(
                        `${apiConfig.localHost}shop/${
                          selected_order ? selected_order.store_data.site : ""
                        }`
                      )
                    }
                  >
                    View shop
                  </Button>
                </div>
                <div style={{ padding: 24 }}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.column1}
                          align="left"
                        ></TableCell>
                        <TableCell className={classes.columnItem} align="left">
                          Item
                        </TableCell>
                        <TableCell className={classes.column2} align="center">
                          Quantity
                        </TableCell>
                        <TableCell className={classes.column2} align="center">
                          Subtotal
                        </TableCell>
                        <TableCell
                          className={classes.column1}
                          align="center"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cart && cart.length
                        ? cart.map((item, key) => {
                            return (
                              <TableRow
                                id="table-cart"
                                style={{
                                  borderBottom: "1px dashed #d1d5dd",
                                  position: "relative"
                                }}
                              >
                                <TableCell
                                  align="left"
                                  style={{ display: "flex", padding: "5px 0" }}
                                  className={classes.column1}
                                >
                                  <Checkbox
                                    disabled={
                                      disableCheckbox(item) ||
                                      (item.product_details.has_variants &&
                                        !item.productSelection)
                                    }
                                    checked={product_selected
                                      .map(item => item.id)
                                      .includes(item.id)}
                                    onChange={e => handleProductSelected(item)}
                                    color="primary"
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.columnItem}
                                  align="left"
                                >
                                  <div className={classes.itemSection}>
                                    <div
                                      className={classes.productImage}
                                      style={{
                                        backgroundImage: `url(${productImage(
                                          item
                                        )})`
                                      }}
                                    />
                                    <div
                                      style={{
                                        marginLeft: "1vw",
                                        width: "60%"
                                      }}
                                    >
                                      <div className={classes.labelbold}>
                                        {item.product_details.name}
                                      </div>
                                      <VariantPicker
                                        index={key}
                                        product={getProduct(item)}
                                        productSelection={item.productSelection}
                                        handleConfirmVariant={
                                          handleConfirmVariant
                                        }
                                      />
                                      <div
                                        style={{
                                          padding: "16px 0 0",
                                          display: "flex"
                                        }}
                                        className={classes.label}
                                      >
                                        <div>
                                          <WishlistItemPriceDisplay
                                            symbol="₱"
                                            item={getProduct(item)}
                                            productSelection={
                                              item.productSelection
                                            }
                                          />
                                          <div
                                            style={{
                                              position: "absolute",
                                              bottom: 0
                                            }}
                                          >
                                            {checkProductAvailability(item)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={classes.column2}
                                  align="center"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      position: "absolute",
                                      bottom: 16
                                    }}
                                  >
                                    <div className={classes.label}>
                                      {item.quantity}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",

                                        marginLeft: "1vw"
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.btnQuantity}
                                        style={{ borderRight: "none" }}
                                        disabled={item.quantity === 1}
                                        onClick={() => decrement(item)}
                                      >
                                        <RemoveIcon style={{ fontSize: 12 }} />
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.btnQuantity}
                                        disabled={checkStock(item)}
                                        onClick={() => increment(item)}
                                      >
                                        <AddIcon style={{ fontSize: 12 }} />
                                      </Button>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={classes.column2}
                                  align="center"
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: 16,
                                      left: 16,
                                      right: 16
                                    }}
                                  >
                                    <div className={classes.label}>
                                      ₱{" "}
                                      {Utils.commafy(
                                        parseFloat(
                                          item.quantity *
                                            (item.product_details
                                              .has_variants &&
                                            item.productSelection
                                              ? item.productSelection
                                                  .discounted_price ||
                                                item.productSelection.price
                                              : item.product_details
                                                  .discounted_price ||
                                                item.product_details.price)
                                        )
                                      )}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{ position: "relative" }}
                                  className={classes.column1}
                                  align="center"
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: 16,
                                      left: 16,
                                      right: 16
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => removeFromCart(key)}
                                      style={{ padding: 0 }}
                                    >
                                      <DeleteOutlineOutlinedIcon color="secondary" />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        : ""}
                    </TableBody>
                  </Table>
                </div>
              </Paper>
              <div style={{ width: "35%", marginLeft: "2vw" }}>
                <Paper className={classes.summary}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.columnHeader}
                        >
                          Summary
                        </TableCell>
                        {product_selected.length && vouchers.length ? (
                          <TableCell
                            id="voucher_link"
                            align="right"
                            className={classes.columnHeader}
                          >
                            <Link
                              className={classes.voucherLink}
                              onClick={() => setOpenVoucher(true)}
                            >
                              <img
                                src={tags}
                                width="15"
                                height="15"
                                style={{ marginRight: 5 }}
                              />
                              Vouchers
                            </Link>
                          </TableCell>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.column} align="left">
                          Items selected
                        </TableCell>
                        <TableCell className={classes.column} align="right">
                          {parseFloat(product_selected.length)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.column} align="left">
                          Subtotal
                        </TableCell>
                        <TableCell className={classes.column} align="right">
                          ₱ {Utils.commafy(subtotal)}
                        </TableCell>
                      </TableRow>
                      {voucher_discount ? (
                        <TableRow>
                          <TableCell className={classes.column} align="left">
                            Voucher Applied
                          </TableCell>
                          <TableCell className={classes.column} align="right">
                            {`- ₱${Utils.commafy(voucher_discount)}`}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}

                      <TableRow>
                        <TableCell
                          className={classes.columnFooter}
                          align="left"
                        >
                          Total
                        </TableCell>
                        <TableCell
                          className={classes.columnFooter}
                          align="right"
                        >
                          ₱ {Utils.commafy(amount - voucher_discount)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
                <div style={{ marginTop: "3vh" }}>
                  <Button
                    id="btn_checkout"
                    fullWidth
                    disableElevation
                    color="primary"
                    variant="contained"
                    style={{ height: 40 }}
                    disabled={!product_selected.length || hasError}
                    onClick={() => history.push(`/my_cart/details`)}
                  >
                    Proceed to checkout
                  </Button>
                </div>
              </div>
            </div>
            <VoucherModal
              id="voucher_modal"
              open={openVoucher}
              setOpen={setOpenVoucher}
            />
          </div>
        )
      ) : (
        ""
      )}
    </>
  );
};

export default CartViewOrder;
