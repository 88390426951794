import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ACL from "../common/utilities/ACL"
import Action from "./Redux/Actions"
import PartnerDashboardLayout from "./layout"
import PropTypes from 'prop-types';
import moment from "moment";
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import apiConfig from "../../common/apiConfig";
import Path from "../../Paths";
import TableExport from '../common/utilities/TableExport';
import { stringify_get_params } from "../common/utilities/DateFilter"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import Skeleton from '@material-ui/lab/Skeleton';
// import AdminTable from '../../components/table';

function descendingComparator(a, b, orderBy) {
if (b[orderBy] < a[orderBy]) {
	return -1;
}
if (b[orderBy] > a[orderBy]) {
	return 1;
}
return 0;
}

function getComparator(order, orderBy) {
return order === 'desc'
	? (a, b) => descendingComparator(a, b, orderBy)
	: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: 'first_name', numeric: false, disablePadding: true, label: 'Name' },
	{ id: 'email', numeric: false, disablePadding: false, label: 'Email' },
	// { id: 'partner_name', numeric: false, disablePadding: true, label: 'Partner' },
	{ id: 'app_name', numeric: false, disablePadding: true, label: 'App Name' },
	{ id: 'variant', numeric: false, disablePadding: false, label: 'Variant' },
	{ id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
	{ id: 'partner_fee', numeric: false, disablePadding: false, label: 'Partner Fee' },
	{ id: 'sentro_fee', numeric: false, disablePadding: false, label: 'Sentro Fee' },
	{ id: 'ref_id', numeric: false, disablePadding: false, label: 'Transaction ID' },
	// { id: 'ref_code', numeric: false, disablePadding: false, label: 'Bux Transaction Code' },
	// { id: 'channel', numeric: false, disablePadding: false, label: 'Channel' },
	{ id: 'status', numeric: false, disablePadding: false, label: 'Status' },
	{ id: 'datetime_paid', numeric: false, disablePadding: false, label: 'Date Paid' },
	// { id: 'expiry', numeric: false, disablePadding: false, label: 'Expiry' },
	{ id: 'created_on', numeric: false, disablePadding: false, label: 'Date Created' },
	// { id: 'is_fulfilled', numeric: false, disablePadding: false, label: 'Fulfilled' },
	// { id: 'fulfillment_date', numeric: false, disablePadding: false, label: 'Fulfillment Date' },
	// { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
				<TableCell
					className={classes.tableHead}
					key={headCell.id}
					align={headCell.numeric ? 'right' : 'left'}
					sortDirection={orderBy === headCell.id ? order : false}
				>
					<TableSortLabel
						active={orderBy === headCell.id}
						direction={orderBy === headCell.id ? order : 'asc'}
						onClick={createSortHandler(headCell.id)}
						hideSortIcon={true}
						active={false}
					>
						<span>{headCell.label}</span>
						<span className={classes.headIconWrapper}>
							<ArrowDropUpIcon className={classes.headIconUp} />
							<ArrowDropUpIcon className={classes.headIconDown} />
						</span>
					</TableSortLabel>
				</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	tableContainer: {
		overflowX: "auto"
	},
	table: {
		// minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	noResult: {
		width: "100%",
		display: "block",
		textAlign: "center",
		padding: "2em"
	},
	tableHead: {
		backgroundColor: "#F97663",
		color: "white !important",
		"&:hover": {
			color: 'white !important'
		},
		"&:active": {
			color: 'white !important'
		},
	},
	headIconWrapper: {
		direction: "rtl", 
		flexGrow: 1, 
		minWidth: 45, 
		display: "flex", 
		height: 20
	}, 
	headIconUp: {
		transform: "rotate(0deg)",
		position: "absolute",
		transform: "translateY(-8px)"
	},
	headIconDown: {
		transform: "rotate(180deg)",
		position: "absolute"
	},
	moreHorizIcon: {
		color: "gray",
		cursor: "pointer"
	},
	disabledText: {
		color: "rgba(0,0,0,0.6)",
		"&:hover":{
			backgroundColor: "white"
		},
		"&:active":{
			backgroundColor: "white"
		}
	}
}));

const menu = (row, selected, menuAnchor, handleClose, setDialogState) => (
	<Menu
		id="simple-menu"
		anchorEl={menuAnchor}
		keepMounted
		open={selected === row.id}
		onClose={handleClose}
	>
		<MenuItem disabled={!row.is_paid} onClick={() => {
			row.is_paid && handleClose()
			row.is_paid && setDialogState(true, row.id)
		}}>
			Mark as fulfilled
		</MenuItem>
	</Menu>
)

function EnhancedTable(props) {
	const classes = useStyles();
	const [order, setOrder] = React.useState('desc');
	const [orderBy, setOrderBy] = React.useState('pk');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [data, setData] = React.useState(props.list);
	const [menuSelected, setMenuSelected] = React.useState(null);
	const [menuAnchor, setMenuAnchor] = React.useState(null)

	useEffect(() => {
		setData(props.list);
	  }, [props.list]);

      useEffect(() => {
          const rowsCount = props.rowsPerPage ? props.rowsPerPage : 5
          setRowsPerPage(rowsCount)
      }, [props.rowsPerPage]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClose = () => {
		setMenuSelected(null)
	}
	const setMenu = (event, id) => {
		setMenuAnchor(event.target)
		setMenuSelected(id)
	}

	return (
		<Fragment>
			<div className={classes.tableContainer}>
				{
					data &&
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={'medium'}
						aria-label="enhanced table"
						id="table"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={data.length}
						/>
						<TableBody>
							{stableSort(data, getComparator(order, orderBy))
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								const labelId = `enhanced-table-checkbox-${index}`;
								
								return (
									<TableRow
										key={row.pk}
										hover
										// onClick={(event) => handleClick(event, row.first_name + " " + row.last_name)}
										role="checkbox"
										tabIndex={-1}
									>
										<TableCell>{`${row.full_name}`}</TableCell>
										<TableCell>{row.recipient}</TableCell>
										{/* <TableCell>{row.partner_name}</TableCell> */}
										<TableCell>{row.app_name}</TableCell>
										<TableCell>{row.variant_name}</TableCell>
										<TableCell>{row.amount}</TableCell>
										<TableCell>{row.partner_fee}</TableCell>
										<TableCell>{row.sentro_fee}</TableCell>
										<TableCell>{row.ref_id}</TableCell>
										{/* <TableCell>{row.ref_code}</TableCell>
										<TableCell>{row.channel}</TableCell> */}
										<TableCell>{row.status}</TableCell>
										<TableCell>{row.datetime_paid ? moment(new Date(row.datetime_paid)).format(`MMMM DD, YYYY hh:mm a`) : ``}</TableCell>
										{/* <TableCell>{row.expiry ? moment(new Date(row.expiry)).format(`MMMM DD, YYYY hh:mm a`) : ``}</TableCell> */}
										<TableCell>{moment(new Date(row.created_on)).format(`MMMM DD, YYYY hh:mm a`)}</TableCell>
										{/* <TableCell align="center">{row.is_fulfilled && <CheckCircleIcon style={{ color: "#F97663", fontSize: 24}}/>}</TableCell>
										<TableCell>{row.fulfillment_date ? moment(new Date(row.fulfillment_date)).format(`MMMM DD, YYYY hh:mm a`) : ``}</TableCell> */}
										{/* <TableCell align="center">
											{
												menu(
													row, 
													menuSelected, 
													menuAnchor, 
													handleClose,
													props.setDialogState
												)
											}
											<MoreHorizIcon onClick={(event) => {setMenu(event, row.id)}} className={classes.moreHorizIcon}/>
										</TableCell> */}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				}
				{
					props.loading ? 
					<>
					{
						[1, 2, 3].map((index) => (
							<div style={{ padding: "0px 15px 0"}} key={index}>
								<Skeleton width="100%" height={60} />
							</div>
						))
					}
					</>
					:
					``
				}
				{
					!props.loading && !data.length ?
					<div style={{ height: "40vh", width: "100%", overflow: "hidden" }}>
						<div style={{ paddingTop: "4em" }}>
							<div style={{
								display: "flex",
								justifyContent: "center"
							}}>
								<img
									style={{width: "7em", height: "6em"}}
									src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
								/>
							</div>
							<div style={{
									display: "flex",
									justifyContent: "center",
									marginTop: 40,
									fontSize: 18,
									fontWeight: 600,
									color: "#b8bcc3",
									padding: "0 3em",
									textAlign: "center"
							}}>
								Nothing to Show
							</div>
						</div>
					</div>
					:
					data && 
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				}
			</div>
		</Fragment>
	);
}

const pageStyles = theme => ({
	paper: {
		overflow: "hidden",
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(1),
		},
	},
	card: {
		backgroundColor: "white",
		padding: 0
	},
	cardHeader: {
		fontSize: "28px",
		marginBottom: "0.5em",
		textAlign: "left",
		color: "#F97663",
		fontWeight: 600
	},
	textDivider: {
		fontSize: "16px",
		padding: "1.5em 0",
		marginBottom: 0
	},
	miniCardHeader: {
		fontSize: "16px",
		textAlign: 'left',
		color: "#F97663",
		paddingBottom: "0.5em",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
		},
	},
	miniCardBody: {
		fontSize: "28px",
		weight: "bold",
		textAlign: 'left',
		color: theme.palette.text.primary,
	},
	lineChart: {
		paddingLeft: "auto",
		paddingRight: "auto",
		paddingTop: "2em",
		position: "relative",
		height: "400px",
		width: "100%",
		display: "block"
	},
	headerButtons: {
		height: "35px"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	searchBar: {
		background: "white",
		maxWidth: "240px",
		borderRadius: "4px",
		color: "gray",
		fontSize: 12,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "100%",
			width: "100%"
		},
	},
	excelExportButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		border: "solid 1px #F97663",
		background: " #F97663",
		borderRadius: 4,
		marginRight: 10,
		color: "white",
		transition: "all 0.3s linear",
		"&:hover": {
			background: "#f96a63"
		},
		"&:focus": {
			outline: 0
		},
		boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
	},
	headerActionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		},
	},
	searchInputWrapper: {
		display: "flex"
	},
	searchInput: {
		flexGrow: 1
	},
	tableWrapper: {
		overflowX: "auto"
	},
	primaryColor: {
		color: theme.palette.text.primary
	},
	dFlex: {
		display: "flex"
	},
	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
});

class PartnerSalesAndOrders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			filteredList: [],
			dialogStat : false,
			dialogType: "edit",
			target: '',
			fulfillment_date: new Date()
		};
		this.onSearch = this.onSearch.bind(this)
		this.onDateFilterCallback = this.onDateFilterCallback.bind(this)
		this.setDialogState = this.setDialogState.bind(this)
		this.handleEdit = this.handleEdit.bind(this)
	}
	
	onSearch(event) {
		const val = event.target.value
		let result = this.state.list.filter(data => {
			const lowerCaseQueryStr = val.toLowerCase()
			return (
					data.full_name.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.recipient.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.app_name.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.variant_name.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.amount.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.partner_fee.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.sentro_fee.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.ref_id.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.status.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					moment(new Date(data.created_on)).format(`MMMM DD, YYYY hh:mm a`)
					.toLowerCase().indexOf(lowerCaseQueryStr) != -1
				)
			});
		this.setState({
			filteredList: result
		})
	}

	componentDidMount = async () => {
		const { dispatch, location, adminDashboard } = this.props
		const filter_query = stringify_get_params(adminDashboard.dateFilter)
		const query_params = location.search.length ? 
			location.search + "&" + filter_query.slice(1)
			:
			filter_query
		const data = await dispatch(Action.fetchSubscriptions(query_params))
		this.setState({
			list: data,
			filteredList: data
		})
	}
	
	onDateFilterCallback = async (filter) => {
		const { dispatch, location } = this.props
		const filter_query = stringify_get_params(filter)
		const query_params = location.search.length ? 
			location.search + "&" + filter_query.slice(1)
			:
			filter_query
		const data = await dispatch(Action.fetchSubscriptions(query_params))
		this.setState({
			list: data,
			filteredList: data
		})
	}

	setDialogState(state, target, dialogType = "edit") {
		this.setState({
			target,
			dialogStat : state,
			dialogType: state ? dialogType: this.state.dialogType
		})
	}

	handleEdit = async () => {
		const { dispatch } = this.props;
		const is_success = await dispatch(Action.patchSubscriptions(this.state.target, {
			is_fulfilled: true,
			fulfillment_date: this.state.fulfillment_date
		}))
		this.state.filteredList.map((data) => {
			if (data.id === this.state.target) {
				data['is_fulfilled'] = true
				data['fulfillment_date'] = moment(new Date(this.state.fulfillment_date)).format(`MMMM DD, YYYY hh:mm a`)
			}
			return data
		})
		this.setDialogState(false)
	}
	
	render (){
		const {
			filteredList, dialogStat, dialogType
		} = this.state
		const { classes, adminDashboard, history, location } = this.props

		const dialogTitle = () => {
			if (dialogType == "edit") {
				return "Edit record"
			}
		}
		const dialogContent = () => {
			return (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<p><strong>Set Fulfillment Date & Time</strong></p>
					<KeyboardDateTimePicker
						inputVariant="outlined"
						value={this.state.fulfillment_date}
						onChange={e => this.setState({ fulfillment_date: e})}
						style={{ margin: 0, padding: 0 }}
						ampm={false}
						fullWidth
						disablePast
						format="dd MMMM yyyy hh:mm"
					/>
				</MuiPickersUtilsProvider>
			)
		}

		const actionButton = () => {
			if (dialogType==="edit") {
				return (
					<Button
						id="edit_button"
						onClick={(event) => {
							this.handleEdit()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						{
							adminDashboard.modalLoading ? 
							<CircularProgress color="secondary" style={{height: 25, width: 25, color: "white", marginRight: 5}}/>
							:
							'Save'
						}
					</Button>
				)
			}
		}

		return (
			<PartnerDashboardLayout onDateFilterCallback={this.onDateFilterCallback} backButtonHidden>
				<Dialog
					open={dialogStat}
					onClose={(event) => this.setDialogState(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth="xs"
				>	
					<div className={classes.modalDiv}>
						<div className={classes.modalTile}>
							{dialogTitle()}
						</div>
						<div className={classes.modalBody}>
							{dialogContent()}
						</div>
						<div className={classes.modalActionsWrapper}>
							<Button
								id="action_button"
								variant="outlined"
								onClick={(event) => this.setDialogState(false)} 
								color="primary"
								className={classes.modalActions}
							>
								{
									dialogType == "info" ?
									"Close"
									:
									"Cancel"
								}
							</Button>
							{actionButton()}
						</div>
					</div>
				</Dialog>
				<div style={{
					display: "flex", 
					flexWrap: "wrap",
					alignItems: "center",
					marginBottom: "1.5em",
					padding: "1.5em 1.5em 0"
				}}>
					<div className={classes.pageTitle}>
						Subscriptions
					</div>
					<div className={classes.headerActionsWrapper}>
						{
							filteredList ? 
							<TableExport
								class={classes.excelExportButton}
								filename="Sentro - Marketplace Subscriptions"
								sheet="Marketplace Subscriptions"
								table={<EnhancedTable list={filteredList} rowsPerPage={filteredList.length }/>}
							/>
							:
							null
						}
						<OutlinedInput
							className={classes.searchBar}
							id="outlined-adornment-weight"
							onChange={(event) => this.onSearch(event)}
							startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
							aria-describedby="outlined-weight-helper-text"
							inputProps={{
							'aria-label': 'weight',
							}}
							labelWidth={0}
							margin="dense"
							placeholder="Type here to search"
						/>
					</div>
				</div>
				<div style={{ padding: "0 1.5em 1em"}}>
					<Paper className={classes.card}>
						<EnhancedTable list={filteredList} setDialogState={this.setDialogState} loading={adminDashboard.loading} />
					</Paper>
				</div>
			</PartnerDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(pageStyles)(PartnerSalesAndOrders))
