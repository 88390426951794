import React from "react";
import { MenuItem, Badge } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Paths from "../../router/paths";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import { ReactComponent as FBIcon } from "../../../../assets/images/enterprise/fb.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/images/enterprise/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../../../assets/images/enterprise/twitter.svg";
import { ReactComponent as PinterestIcon } from "../../../../assets/images/enterprise/pinterest_inherit_color.svg";
import { ReactComponent as SearchSVG } from "../../../../assets/images/enterprise/search.svg";
import { ReactComponent as CartOutlinedSVG } from "../../../../assets/images/enterprise/cart-outlined.svg";
import ProductActions from "../../redux/actions/productActions";
import Panel from "./mobilePanel";
import DrawerMobile from "./drawer";
import Login from "../../pages/login";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import CartDrawer from "../cart/cartDrawer";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import MarketplaceActions from "../../redux/actions/marketplaceActions";

export default function Header(props) {
  const textColor = useSelector(
    state => state.enterprise.main.theme.palette.primary.contrastText
  );
  const useStyles = makeStyles(theme => ({
    root: {
      position: "unset",
      boxShadow: "none",
      zIndex: 99,
      [theme.breakpoints.down("sm")]: {
        position: "sticky",
        top: 0
      },
      transition: "all 0.2s linear"
    },
    toolbar: {
      alignItems: "flex-start"
    },
    title: {
      flexGrow: 1,
      alignSelf: "flex-end"
    },
    links: {
      fontWeight: 600,
      fontSize: 12,
      color: textColor
    },
    searchBar: {
      borderRadius: "4px"
    },
    name: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "capitalize"
    },
    popover: {
      pointerEvents: "none"
    },
    paper: {
      padding: theme.spacing(1)
    },
    badge: {
      backgroundColor: "red",
      color: "#fff"
    }
  }));
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const enterprise = useSelector(state => state.enterprise.main);
  const cart = useSelector(state => state.enterprise.main.cart.items);
  const cart_drawer = useSelector(
    state => state.enterprise.main.cart.cart_drawer
  );
  const filters = useSelector(state => state.enterprise.main.products.filters);
  const loading = useSelector(state => state.enterprise.main.products.loading);
  const [mobileSearchDialog, setMobileSearchDialog] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const themeColor = enterprise.theme.palette.primary.main;
  const [timeoutId, setTimeoutId] = React.useState(false);
  const token = sessionStorage.getItem("TOKEN");
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const name = user ? user.first_name + " " + user.last_name : "";
  const category = new URLSearchParams(useLocation().search).get("category");

  const toggleDrawer = () => {
    dispatch(ProductActions.showCartDrawer(false));
  };

  const handleCartBtn = () => {
    if (path.includes("cart") || cart.length === 0) {
      return () =>
        history.push(`/enterprise/${enterprise.enterprise.site_name}/cart`);
    } else {
      return () => dispatch(ProductActions.showCartDrawer(true));
    }
  };
  const onSearch = e => {
    const val = e.target.value;
    dispatch(
      ProductActions.setProductFilters({
        search: val
      })
    );
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        // dispatch api call here; 0.5sec after typing
        dispatch(
          ProductActions.getProducts(enterprise.enterprise.site_name, {
            ...filters,
            search: val,
            page: 1
          })
        );
        history.push(
          `/enterprise/${enterprise.enterprise.site_name}/products/${
            category ? `?category=${category}` : ""
          }`
        );
      }, 1000)
    );
  };

  const onClear = () => {
    dispatch(
      ProductActions.setProductFilters({
        search: ""
      })
    );
    dispatch(
      ProductActions.getProducts(enterprise.enterprise.site_name, {
        ...filters,
        search: ""
      })
    );
  };

  const search = useSelector(
    state => state.enterprise.main.products.filters.search
  );
  const searchBar = ({ is_mobile }) => (
    <OutlinedInput
      fullWidth
      className={classes.searchBar}
      style={{
        background: is_mobile ? "rgba(255,255,255,0.1)" : "white",
        height: is_mobile ? 60 : 38,
        fontSize: is_mobile ? 24 : 14,
        color: is_mobile ? "white" : "gray"
      }}
      id="outlined-adornment-weight"
      value={search}
      onChange={onSearch}
      startAdornment={
        <InputAdornment position="start">
          <SearchSVG
            style={{
              fontSize: is_mobile ? 40 : 25,
              height: 17,
              width: 17
            }}
            fill={is_mobile ? "white" : "#858997"}
          />
        </InputAdornment>
      }
      endAdornment={
        loading ? (
          <InputAdornment position="end">
            <div
              className="spinner-border mx-auto"
              role="status"
              style={{
                color: is_mobile ? "white" : themeColor,
                fontSize: 1,
                height: 15,
                width: 15
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </InputAdornment>
        ) : search ? (
          <InputAdornment position="end">
            <IconButton
              style={{ marginRight: "-0.5em" }}
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => onClear()}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          ""
        )
      }
      aria-describedby="outlined-weight-helper-text"
      inputProps={{
        "aria-label": "weight"
      }}
      labelWidth={0}
      margin="dense"
      placeholder={is_mobile ? "Type keyword" : "Search products or shops"}
    />
  );

  React.useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setMobileSearchDialog(false);
      }, 1000);
    }
  }, [loading]);

  const wishlist = useSelector(
    state => state.enterprise.wishlist.buyer_dashboard.wishlist
  );

  return (
    <div
      className={classes.root}
      style={{
        position: props.condensed ? "sticky" : "inherit",
        top: props.condensed ? 0 : "unset"
      }}
    >
      {enterprise.enterprise.tagline ? (
        <div
          className="bg-white text-dark text-center d-none 
          d-md-block py-1"
          style={{
            fontSize: 14
          }}
        >
          {enterprise.enterprise.tagline}
        </div>
      ) : (
        ""
      )}
      <AppBar position="static">
        {/* for desktop */}
        <div className="d-none d-md-block">
          <Container maxWidth="lg">
            <div
              className={`${classes.toolbar} ${
                props.condensed ? "py-3" : "py-md-3 pb-lg-4"
              } px-0`}
              style={{
                minHeight: props.condensed ? "min-content" : 120
              }}
            >
              <div
                className="d-flex no-gutters px-0 align-items-center"
                style={{
                  justifyContent: props.condensed ? "inherit" : "space-between"
                }}
              >
                {props.condensed ? (
                  <div
                    onClick={() =>
                      history.push(
                        `/enterprise/${enterprise.enterprise.site_name}`
                      )
                    }
                    className="d-flex h-100 align-items-end justify-content-center mr-5"
                  >
                    <img
                      alt=""
                      src={enterprise.enterprise.logo}
                      className="img-fluid"
                      style={{
                        maxHeight: 45,
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        history.push(
                          `/enterprise/${enterprise.enterprise.site_name}`
                        )
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
                {props.condensed ? (
                  ""
                ) : (
                  <div className="d-flex">
                    <div className="d-flex" style={{ marginLeft: -10 }}>
                      {enterprise.enterprise.facebook ? (
                        <IconButton
                          className="mr-3"
                          onClick={() =>
                            window.open(
                              `https://facebook.com/${enterprise.enterprise.facebook}`,
                              "_blank"
                            )
                          }
                        >
                          <FBIcon width="25" height="25" fill={textColor} />
                        </IconButton>
                      ) : (
                        ""
                      )}
                      {enterprise.enterprise.instagram ? (
                        <IconButton
                          className="mr-3"
                          onClick={() =>
                            window.open(
                              `https://instagram.com/${enterprise.enterprise.instagram}`,
                              "_blank"
                            )
                          }
                        >
                          <InstagramIcon
                            width="25"
                            height="25"
                            fill={textColor}
                          />
                        </IconButton>
                      ) : (
                        ""
                      )}
                      {enterprise.enterprise.twitter ? (
                        <IconButton
                          className="mr-3"
                          onClick={() =>
                            window.open(
                              `https://twitter.com/${enterprise.enterprise.twitter}`,
                              "_blank"
                            )
                          }
                        >
                          <TwitterIcon
                            width="25"
                            height="25"
                            fill={textColor}
                          />
                        </IconButton>
                      ) : (
                        ""
                      )}
                      {enterprise.enterprise.pinterest ? (
                        <IconButton
                          className="mr-3"
                          onClick={() =>
                            window.open(
                              `https://www.pinterest.ph/${enterprise.enterprise.pinterest}`,
                              "_blank"
                            )
                          }
                        >
                          <PinterestIcon
                            width="25"
                            height="25"
                            fill={textColor}
                          />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                <div className="d-flex">
                  {(enterprise.enterprise.menu.length &&
                    enterprise.enterprise.menu[0].active) ||
                  !enterprise.enterprise.menu.length ? (
                    <Link
                      to={`/enterprise/${enterprise.enterprise.site_name}/about`}
                      className={`mr-3 ${classes.links}`}
                    >
                      {enterprise.enterprise.menu.length
                        ? enterprise.enterprise.menu[0].text
                        : "About us"}
                    </Link>
                  ) : (
                    ""
                  )}
                  {(enterprise.enterprise.menu.length &&
                    enterprise.enterprise.menu[1].active) ||
                  !enterprise.enterprise.menu.length ? (
                    <Link
                      to={`/enterprise/${enterprise.enterprise.site_name}/directory`}
                      className={`mr-3 ${classes.links}`}
                    >
                      {enterprise.enterprise.menu.length
                        ? enterprise.enterprise.menu[1].text
                        : "Directory"}
                    </Link>
                  ) : (
                    ""
                  )}
                  <Link
                    to={`/enterprise/${enterprise.enterprise.site_name}/wishlist`}
                    className={`mr-3 ${classes.links}`}
                  >
                    Registry
                  </Link>

                  {enterprise.enterprise.menu ? (
                    <div className="d-flex">
                      {enterprise.enterprise.menu.length > 2
                        ? enterprise.enterprise.menu
                            .slice(2, enterprise.enterprise.menu.length + 1)
                            .map((item, index) =>
                              item.active ? (
                                item.link.includes("sentro") ? (
                                  <a
                                    href={`https://${item.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${classes.links} mr-3`}
                                    onClick={() => history.push(item.link)}
                                  >
                                    {item.text}
                                  </a>
                                ) : (
                                  <a
                                    href={`https://${item.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${classes.links} mr-3`}
                                  >
                                    {item.text}
                                  </a>
                                )
                              ) : (
                                ""
                              )
                            )
                        : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="d-flex justify-content-end align-items-center"
                  style={{ marginLeft: props.condensed ? "auto" : 0 }}
                >
                  {token && user ? (
                    <div className="d-flex align-items-center">
                      <div className={classes.name}>{name}</div>
                      <IconButton
                        onClick={() =>
                          history.push(
                            `/enterprise/${enterprise.enterprise.site_name}/buyer_dashboard`
                          )
                        }
                      >
                        <AccountCircleOutlinedIcon
                          style={{ color: textColor }}
                        />
                      </IconButton>
                    </div>
                  ) : (
                    <div className="d-flex">
                      <Link
                        to={`/enterprise/${enterprise.enterprise.site_name}/login`}
                        className={`ml-auto mr-5 ${classes.links}`}
                      >
                        Login
                      </Link>
                      <Link
                        to={`/enterprise/${enterprise.enterprise.site_name}/signup`}
                        className={`mr-4 ${classes.links}`}
                      >
                        Signup
                      </Link>
                    </div>
                  )}
                  <IconButton
                    onClick={() =>
                      history.push(
                        wishlist
                          ? `/enterprise/${enterprise.enterprise.site_name}/wishlist/${wishlist.ref_code}`
                          : `/enterprise/${enterprise.enterprise.site_name}/wishlist/create`
                      )
                    }
                  >
                    <FavoriteBorderIcon
                      className={classes.heart}
                      style={{ color: textColor }}
                    />
                  </IconButton>
                  <IconButton onClick={handleCartBtn()}>
                    <Badge
                      classes={{ badge: classes.badge }}
                      badgeContent={cart.length}
                    >
                      <CartOutlinedSVG fill={textColor} />
                    </Badge>
                  </IconButton>
                  {props.condensed ? (
                    <IconButton
                      className="ml-3"
                      edge="start"
                      color="inherit"
                      aria-label="search"
                      onClick={() => setMobileSearchDialog(true)}
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {!props.condensed ? (
                <div className="row no-gutters px-0 py-2">
                  <div className="col-3"></div>
                  <div className="col-6">
                    <div
                      onClick={() =>
                        history.push(
                          `/enterprise/${enterprise.enterprise.site_name}`
                        )
                      }
                      className="d-flex h-100 align-items-end justify-content-center px-5"
                    >
                      <img
                        alt=""
                        src={enterprise.enterprise.logo}
                        className="img-fluid"
                        style={{
                          maxHeight: 140,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          history.push(
                            `/enterprise/${enterprise.enterprise.site_name}`
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex flex-column h-100">
                      <div className="mt-auto">
                        {searchBar({ is_mobile: false })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Container>
        </div>
        {/* header 1st version */}
        {/* <div className="d-none d-md-block">
          <Container maxWidth="lg">
            <div
              className={`${classes.toolbar} ${
                props.condensed ? "py-3" : "py-md-3 py-lg-5"
              } px-0`}
              style={{
                minHeight: props.condensed ? "min-content" : 120
              }}
            >
              <div className="d-flex no-gutters px-0 align-items-center">
                {props.condensed ? (
                  <div
                    onClick={() =>
                      history.push(
                        `/enterprise/${enterprise.enterprise.site_name}`
                      )
                    }
                    className="d-flex h-100 align-items-end justify-content-center mr-5"
                  >
                    <img
                      alt=""
                      src={enterprise.enterprise.logo}
                      className="img-fluid"
                      style={{
                        maxHeight: 40,
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        history.push(
                          `/enterprise/${enterprise.enterprise.site_name}`
                        )
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex">
                  {(enterprise.enterprise.menu.length &&
                    enterprise.enterprise.menu[0].active) ||
                  !enterprise.enterprise.menu.length ? (
                    <Link
                      to={`/enterprise/${enterprise.enterprise.site_name}/about`}
                      className={`mr-3 ${classes.links}`}
                    >
                      {enterprise.enterprise.menu.length
                        ? enterprise.enterprise.menu[0].text
                        : "About us"}
                    </Link>
                  ) : (
                    ""
                  )}
                  {(enterprise.enterprise.menu.length &&
                    enterprise.enterprise.menu[1].active) ||
                  !enterprise.enterprise.menu.length ? (
                    <Link
                      to={`/enterprise/${enterprise.enterprise.site_name}/directory`}
                      className={`mr-3 ${classes.links}`}
                    >
                      {enterprise.enterprise.menu.length
                        ? enterprise.enterprise.menu[1].text
                        : "Directory"}
                    </Link>
                  ) : (
                    ""
                  )}
                  <Link
                    to={`/enterprise/${enterprise.enterprise.site_name}/wishlist`}
                    className={`mr-3 ${classes.links}`}
                  >
                    Registry
                  </Link>
                </div>
                {enterprise.enterprise.menu ? (
                  <div className="d-flex">
                    {enterprise.enterprise.menu.length > 2
                      ? enterprise.enterprise.menu
                          .slice(2, enterprise.enterprise.menu.length + 1)
                          .map((item, index) =>
                            item.active ? (
                              item.link.includes("sentro") ? (
                                <a
                                  href={`https://${item.link}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={`${classes.links} mr-3`}
                                  onClick={() => history.push(item.link)}
                                >
                                  {item.text}
                                </a>
                              ) : (
                                <a
                                  href={`https://${item.link}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={`${classes.links} mr-3`}
                                >
                                  {item.text}
                                </a>
                              )
                            ) : (
                              ""
                            )
                          )
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                <div className="ml-auto d-flex justify-content-end align-items-center">
                  {token && user ? (
                    <div className="d-flex mr-2 align-items-center">
                      <div className={classes.name}>{name}</div>
                      <IconButton
                        onClick={() =>
                          history.push(
                            `/enterprise/${enterprise.enterprise.site_name}/buyer_dashboard`
                          )
                        }
                      >
                        <AccountCircleOutlinedIcon
                          style={{ color: textColor }}
                        />
                      </IconButton>
                    </div>
                  ) : (
                    <div className="d-flex">
                      <Link
                        to={`/enterprise/${enterprise.enterprise.site_name}/login`}
                        className={`ml-auto mr-5 ${classes.links}`}
                      >
                        Login
                      </Link>
                      <Link
                        to={`/enterprise/${enterprise.enterprise.site_name}/signup`}
                        className={`mr-4 ${classes.links}`}
                      >
                        Signup
                      </Link>
                    </div>
                  )}
                  <IconButton onClick={handleCartBtn()}>
                    <Badge
                      classes={{ badge: classes.badge }}
                      badgeContent={cart.length}
                    >
                      <CartOutlinedSVG fill={textColor} />
                    </Badge>
                  </IconButton>
                  {props.condensed ? (
                    <IconButton
                      className="ml-3"
                      edge="start"
                      color="inherit"
                      aria-label="search"
                      onClick={() => setMobileSearchDialog(true)}
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {!props.condensed ? (
                <div className="row no-gutters px-0">
                  <div className="col-3">
                    <div className="d-flex flex-column h-100">
                      <div className="d-flex mt-3" style={{ marginLeft: -10 }}>
                        {enterprise.enterprise.facebook ? (
                          <IconButton
                            className="mr-3"
                            onClick={() =>
                              window.open(
                                `https://facebook.com/${enterprise.enterprise.facebook}`,
                                "_blank"
                              )
                            }
                          >
                            <FBIcon width="25" height="25" fill={textColor} />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        {enterprise.enterprise.instagram ? (
                          <IconButton
                            className="mr-3"
                            onClick={() =>
                              window.open(
                                `https://instagram.com/${enterprise.enterprise.instagram}`,
                                "_blank"
                              )
                            }
                          >
                            <InstagramIcon
                              width="25"
                              height="25"
                              fill={textColor}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        {enterprise.enterprise.twitter ? (
                          <IconButton
                            className="mr-3"
                            onClick={() =>
                              window.open(
                                `https://twitter.com/${enterprise.enterprise.twitter}`,
                                "_blank"
                              )
                            }
                          >
                            <TwitterIcon
                              width="25"
                              height="25"
                              fill={textColor}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        {enterprise.enterprise.pinterest ? (
                          <IconButton
                            className="mr-3"
                            onClick={() =>
                              window.open(
                                `https://www.pinterest.ph/${enterprise.enterprise.pinterest}`,
                                "_blank"
                              )
                            }
                          >
                            <PinterestIcon
                              width="25"
                              height="25"
                              fill={textColor}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      onClick={() =>
                        history.push(
                          `/enterprise/${enterprise.enterprise.site_name}`
                        )
                      }
                      className="d-flex h-100 align-items-end justify-content-center px-5"
                    >
                      <img
                        alt=""
                        src={enterprise.enterprise.logo}
                        className="img-fluid"
                        style={{
                          maxHeight: 80,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          history.push(
                            `/enterprise/${enterprise.enterprise.site_name}`
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex flex-column h-100">
                      <div className="mt-auto">
                        {searchBar({ is_mobile: false })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Container>
        </div> */}
        {/* for mobile */}
        <div className="d-block d-md-none px-4">
          <div className="d-flex my-2 justify-content-between align-items-center">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              style={{ zIndex: 3 }}
              onClick={() => setDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <div
              className="d-flex justify-content-center"
              style={{ position: "absolute", left: 0, right: 0 }}
            >
              <img
                alt=""
                src={enterprise.enterprise.logo}
                className="img-fluid"
                style={{ height: 35 }}
                onClick={() =>
                  history.push(`/enterprise/${enterprise.enterprise.site_name}`)
                }
              ></img>
            </div>
            <div className="d-flex">
              <IconButton onClick={handleCartBtn()}>
                <Badge
                  classes={{ badge: classes.badge }}
                  badgeContent={cart.length}
                >
                  <CartOutlinedSVG fill={textColor} />
                </Badge>
              </IconButton>
              <IconButton
                className="ml-2"
                edge="start"
                color="inherit"
                aria-label="search"
                onClick={() => setMobileSearchDialog(true)}
              >
                <SearchSVG fill={textColor} style={{ height: 20, width: 20 }} />
              </IconButton>
            </div>
          </div>
          <Panel
            open={mobileSearchDialog}
            onClose={() => setMobileSearchDialog(false)}
            style={{ backgroundColor: themeColor }}
          >
            <div className="w-100 h-100 d-flex align-items-center px-1">
              <div className="w-100 px-4 text-center">
                <p style={{ color: "white", fontSize: 16 }}>
                  Search for products or shops
                </p>
                <div className="mt-3 d-flex">
                  <div className="d-md-flex mx-auto w-0 w-md-50">
                    {searchBar({ is_mobile: true })}
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <DrawerMobile open={drawer} onClose={() => setDrawer(false)}>
            {user ? (
              <div className="mt-4">
                <div
                  onClick={() =>
                    history.push(
                      `/enterprise/${enterprise.enterprise.site_name}/buyer_dashboard`
                    )
                  }
                  className="d-flex my-4"
                >
                  <AccountCircleOutlinedIcon
                    style={{ color: themeColor }}
                    className="mr-3"
                  />

                  <div style={{ color: themeColor }} className={classes.name}>
                    {name}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setDrawer(false);
                    history.push(
                      wishlist
                        ? `/enterprise/${enterprise.enterprise.site_name}/wishlist/${wishlist.ref_code}`
                        : `/enterprise/${enterprise.enterprise.site_name}/wishlist/create`
                    );
                  }}
                  className="d-flex my-4"
                >
                  <FavoriteBorderIcon
                    style={{ color: themeColor }}
                    className="mr-3"
                  />

                  <div style={{ color: themeColor }} className={classes.name}>
                    Wishlist
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <Login
                  user={user}
                  setDrawer={setDrawer}
                  removeHeader={true}
                  site_name={enterprise.enterprise.site_name}
                />
              </div>
            )}

            <div style={{ borderTop: "1px solid #d1d5dd" }} className="mt-4">
              <div className="pt-4">
                <div className="d-flex flex-column">
                  {(enterprise.enterprise.menu.length &&
                    enterprise.enterprise.menu[0].active) ||
                  !enterprise.enterprise.menu.length ? (
                    <Link
                      to={`/enterprise/${enterprise.enterprise.site_name}/about`}
                      className={`my-3 ${classes.links}`}
                      style={{ color: themeColor }}
                      onClick={() => setDrawer(false)}
                    >
                      {enterprise.enterprise.menu.length
                        ? enterprise.enterprise.menu[0].text
                        : "About us"}
                    </Link>
                  ) : (
                    ""
                  )}
                  {(enterprise.enterprise.menu.length &&
                    enterprise.enterprise.menu[1].active) ||
                  !enterprise.enterprise.menu.length ? (
                    <Link
                      to={`/enterprise/${enterprise.enterprise.site_name}/directory`}
                      className={`my-3 ${classes.links}`}
                      style={{ color: themeColor }}
                      onClick={() => setDrawer(false)}
                    >
                      {enterprise.enterprise.menu.length
                        ? enterprise.enterprise.menu[1].text
                        : "Directory"}
                    </Link>
                  ) : (
                    ""
                  )}
                  <Link
                    to={`/enterprise/${enterprise.enterprise.site_name}/wishlist`}
                    className={`my-3 ${classes.links}`}
                    style={{ color: themeColor }}
                    onClick={() => setDrawer(false)}
                  >
                    Registry
                  </Link>

                  {enterprise.enterprise.menu ? (
                    <div className="d-flex flex-column">
                      {enterprise.enterprise.menu.length > 2
                        ? enterprise.enterprise.menu
                            .slice(2, enterprise.enterprise.menu.length + 1)
                            .map((item, index) =>
                              item.active ? (
                                item.link.includes("sentro") ? (
                                  <a
                                    href={`https://${item.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${classes.links} my-3`}
                                    style={{ color: themeColor }}
                                    onClick={() => history.push(item.link)}
                                  >
                                    {item.text}
                                  </a>
                                ) : (
                                  <a
                                    href={`https://${item.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${classes.links} my-3`}
                                    style={{ color: themeColor }}
                                  >
                                    {item.text}
                                  </a>
                                )
                              ) : (
                                ""
                              )
                            )
                        : ""}
                    </div>
                  ) : (
                    ""
                  )}
                  {token && user ? (
                    <Link
                      className={`my-3 ${classes.links}`}
                      style={{ color: themeColor }}
                      onClick={() => {
                        dispatch(
                          MarketplaceActions.logout(enterprise.enterprise)
                        );
                      }}
                    >
                      Log out
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex justify-content-center pt-4">
                  {enterprise.enterprise.facebook ? (
                    <IconButton
                      className="mr-3"
                      onClick={() =>
                        window.open(
                          `https://facebook.com/${enterprise.enterprise.facebook}`,
                          "_blank"
                        )
                      }
                    >
                      <FBIcon width="20" height="20" fill={themeColor} />
                    </IconButton>
                  ) : (
                    ""
                  )}
                  {enterprise.enterprise.instagram ? (
                    <IconButton
                      className="mr-3"
                      onClick={() =>
                        window.open(
                          `https://instagram.com/${enterprise.enterprise.instagram}`,
                          "_blank"
                        )
                      }
                    >
                      <InstagramIcon width="20" height="20" fill={themeColor} />
                    </IconButton>
                  ) : (
                    ""
                  )}
                  {enterprise.enterprise.twitter ? (
                    <IconButton
                      className="mr-3"
                      onClick={() =>
                        window.open(
                          `https://twitter.com/${enterprise.enterprise.twitter}`,
                          "_blank"
                        )
                      }
                    >
                      <TwitterIcon width="20" height="20" fill={themeColor} />
                    </IconButton>
                  ) : (
                    ""
                  )}

                  {enterprise.enterprise.pinterest ? (
                    <IconButton
                      className="mr-3"
                      onClick={() =>
                        window.open(
                          `https://www.pinterest.ph/${enterprise.enterprise.pinterest}`,
                          "_blank"
                        )
                      }
                    >
                      <PinterestIcon width="20" height="20" fill={themeColor} />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </DrawerMobile>
        </div>
        <CartDrawer open={cart_drawer} toggleDrawer={toggleDrawer} />
      </AppBar>
    </div>
  );
}
