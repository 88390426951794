import React from "react";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SentroButton from "../../../../../sentroButton";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../../../utilities/ImageCropper/getCroppedImage";
import Slider from "@material-ui/core/Slider";
import ReplaceImage from "./replaceImage";
import LoadImage from "blueimp-load-image";
const useStyles = theme => ({
  root: {
    padding: 15
  },
  appBar: {
    position: "relative",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  title: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    width: "100%"
  },
  errorText: {
    fontSize: 12,
    color: "red"
  },
  closeBtn: { position: "absolute", left: 15 },
  previewButton: {
    height: 38
  },
  labelDescription: {
    color: "#002c42",
    fontSize: 14,
    marginBottom: 0
  }
});
const DialogTransition = props => <Slide direction="up" {...props} />;
const SentroSlider = withStyles({
  root: {
    color: "#F97663",
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

class ImageCropperForm extends React.Component {
  state = {
    image: "",
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0,
    aspect: 1 / 1,
    croppedAreaPixels: null,
    loading: false,
    errors: ""
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  submit = () => {
    const randomString = Math.random()
      .toString(26)
      .substring(2);
    this.setState({ ...this.state, loading: true });
    getCroppedImg(
      this.state.image,
      this.state.croppedAreaPixels,
      this.state.rotation,
      `${this.props.fieldname}-${randomString}.${this.state.extentionName}`,
      this.valueCallback
    );

    this.props.onClose();
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  valueCallback = croppedImage => {
    this.setState({
      ...this.state,
      loading: false
    });
    this.props.onSave(croppedImage);
    this.props.setCroppedArea({
      rotation: this.state.rotation,
      zoom: this.state.zoom,
      croppedAreaPixels: this.state.croppedAreaPixels,
      crop: this.state.crop
    });
  };
  componentDidMount = () => {
    const filename = this.props.image.name;
    const periodSplitted = filename.split(".");
    const croppedArea = this.props.croppedArea;
    const setImage = imageSrc => {
      this.setState({
        ...this.state,
        extentionName: periodSplitted[periodSplitted.length - 1],
        image: imageSrc,
        aspect: this.props.aspect,
        crop: croppedArea.crop,
        zoom: croppedArea.zoom,
        rotation: croppedArea.rotation,
        croppedAreaPixels: croppedArea.croppedAreaPixels
      });
    };
    // use loadImage by blueimp-load-image to identify
    // and fix EXIF orientation for images taken in IOS
    LoadImage(
      this.props.image,
      img => {
        var base64data = img.toDataURL("image/jpeg");
        setImage(base64data);
      },
      { orientation: true, canvas: true }
    );
  };

  onReplaceError = errors => {
    this.setState({
      ...this.state,
      errors
    });
  };

  render() {
    const { open, onClose, classes, isDesktop } = this.props;
    return (
      <>
        <Dialog
          fullScreen={isDesktop ? false : true}
          fullWidth={isDesktop ? true : false}
          maxWidth={"sm"}
          open={open}
          TransitionComponent={DialogTransition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                className={classes.closeBtn}
                onClick={() => onClose()}
              >
                <CloseIcon style={{ color: "#f97663" }} />
              </IconButton>
              <div className={classes.header}>Adjust Image</div>
            </Toolbar>
          </AppBar>
          <section
            className={classes.root}
            style={{ position: "relative", height: window.innerWidth }}
          >
            <Cropper
              image={this.state.image}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={this.state.aspect}
              rotation={this.state.rotation}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
              minZoom={1}
              maxZoom={5}
              zoomSpeed={0.75}
              showGrid={false}
            />
          </section>
          <section
            className={classes.root}
            style={{ display: isDesktop ? "flex" : "" }}
          >
            <div
              style={{
                width: isDesktop ? "50%" : "100%",
                margin: isDesktop ? "0 20px" : 0
              }}
            >
              <p className={classes.labelDescription}>Zoom</p>
              <SentroSlider
                valueLabelDisplay="auto"
                aria-label="zoom_slider"
                defaultValue={0}
                value={this.state.zoom}
                step={0.01}
                min={1}
                max={5}
                onChange={(event, val) =>
                  this.setState({
                    ...this.state,
                    zoom: val
                  })
                }
              />
            </div>
            <div
              style={{
                width: isDesktop ? "50%" : "100%",
                margin: isDesktop ? "0 20px" : 0
              }}
            >
              <p className={classes.labelDescription}>Rotate</p>
              <SentroSlider
                valueLabelDisplay="auto"
                aria-label="rotation_slider"
                defaultValue={0}
                step={0.1}
                min={-180}
                max={180}
                onChange={(event, val) =>
                  this.setState({
                    ...this.state,
                    rotation: val
                  })
                }
              />
            </div>
          </section>
          <section
            className={classes.root}
            style={{ margin: isDesktop ? "0 20px" : 0 }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  this.props.handleResetDefault();
                  this.props.onClose();
                }}
                id="reset_image"
                variant="text"
                color="secondary"
                style={{ width: "45%", fontSize: 12 }}
              >
                Reset default image
              </Button>
              <div style={{ width: "45%" }}>
                <ReplaceImage
                  aspect={this.props.aspect}
                  fieldname={this.props.fieldname}
                  onChange={image => {
                    this.props.onSave(image);
                  }}
                  onClose={this.props.onClose}
                  openCropper={image => this.props.openCropper(image)}
                  setFieldname={fieldname => this.props.setFieldname(fieldname)}
                  onError={this.onReplaceError}
                />
              </div>
            </div>
          </section>
          <p className="text-danger my-1 text-center" style={{ fontSize: 12 }}>
            {this.state.errors.maxFileSize
              ? "Image is too big. Maximum allowed size is 5 MB."
              : ""}
          </p>
          <section
            className={classes.root}
            style={{ margin: isDesktop ? "0 20px" : 0 }}
          >
            <div className="formGroup">
              <Button
                id="done"
                disableElevation
                fullWidth
                disabled={this.state.loading}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.submit();
                }}
              >
                {this.state.loading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Done"
                )}
              </Button>
            </div>
          </section>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => () => ({});
export default connect(mapStateToProps)(
  withStyles(useStyles)(ImageCropperForm)
);
