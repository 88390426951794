import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  IconButton,
  useMediaQuery,
  Button,
  Link
} from "@material-ui/core";
import { ReactComponent as Shop } from "../../assets/images/dashboard/shop.svg";
import { ReactComponent as Edit } from "../../assets/images/dashboard/edit.svg";
import { ReactComponent as Customize } from "../../assets/images/dashboard/customize.svg";
import { ReactComponent as Add } from "../../assets/images/dashboard/add.svg";
import { ReactComponent as Delivery } from "../../assets/images/dashboard/delivery.svg";
import { ReactComponent as Order } from "../../assets/images/dashboard/order.svg";
import { ReactComponent as Share } from "../../assets/images/dashboard/share.svg";
import LaunchIcon from "@material-ui/icons/Launch";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useTheme } from "@material-ui/core/styles";
import apiConfig from "../../common/apiConfig";
import { useHistory } from "react-router";
import Path from "../../Paths";
import axios from "axios";
import OrderActions from "../orders/orderActions";
import { ShareOutlined } from "@material-ui/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { showAlert } from "../common/commonActions";
export default function DashboardCard(props) {
  const { stores, values, setValues } = props;
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles(themes => ({
    card: {
      borderRadius: 4,
      backgroundColor: "#fff",
      border: isMobile ? "none" : "1px solid #eee"
    },
    btn: {
      backgroundColor: "#fafafa",
      color: "#54575f",
      border: "1px solid #54575f"
    },
    btnText: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    name: {
      width: isMobile ? "50vw" : "16vw",
      fontWeight: 600,
      color: "#2b2d32",
      fontSize: 16,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }));
  const dispatch = useDispatch();

  const classes = useStyles();
  const site_url = `${apiConfig.localHost}order/${
    stores && stores.length ? stores[0].site : ""
  }`;
  const [orders, setOrders] = React.useState("");
  let params = {
    delivery_status: "to_fulfill",
    payment_status: "paid",
    order: "desc",
    orderBy: "ref_id",
    page: 0,
    rowsPerPage: 10,
    desktop: true
  };

  const handleOrder = () => {
    if (isMobile) {
      history.push(`/mobile/orders#to_fulfill`);
    } else {
      history.push(`/orders/list#to_fulfill`);
    }
  };
  React.useEffect(() => {
    const fetchOrders = async () => {
      let res = await axios.get(`${apiConfig.apiURL}/api/dashboard/orders/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        },
        params
      });
      console.log(res);
      setOrders(res.data.length);
    };
    fetchOrders();
  }, []);

  console.log(orders);
  return (
    <div className={`${classes.card} p-3`}>
      <div className="p-2 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Shop className="mr-2" />
          <div className={classes.name}>{stores && stores[0].name}</div>
        </div>
        {isMobile ? (
          <div className="d-flex align-items-center">
            <IconButton
              onClick={() =>
                window.open(
                  `${apiConfig.localHost}${
                    stores && stores[0].shop_type === "order" ? "order" : "shop"
                  }/${stores && stores.length ? stores[0].site : ""}`
                )
              }
            >
              <LaunchIcon style={{ color: "#002c42" }} />
            </IconButton>
            <IconButton
              onClick={() => setValues({ ...values, share_drawerbox: true })}
            >
              <ShareOutlinedIcon style={{ color: "#002c42" }} />
            </IconButton>
          </div>
        ) : (
          <Link
            id="shop_link"
            color="primary"
            variant="text"
            style={{ fontSize: 14 }}
            onClick={() =>
              window.open(
                `${apiConfig.localHost}${
                  stores && stores[0].shop_type === "order" ? "order" : "shop"
                }/${stores && stores.length ? stores[0].site : ""}`
              )
            }
          >
            {stores && stores.length ? stores[0].site : ""}.sentro.ph{" "}
            <LaunchIcon color="primary" style={{ fontSize: 14 }} />
          </Link>
        )}
      </div>
      <div className="my-2 p-2">
        <Button
          id="order_btn"
          fullWidth
          disableElevation
          color="secondary"
          variant="outlined"
          className={classes.btn}
          onClick={() => handleOrder()}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <div className="d-flex">
              <ListAltIcon />
              <div className="ml-2">
                {orders >= 0 ? (
                  `${orders} ${orders > 1 ? " orders" : " order"} to fulfill`
                ) : (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </div>
            <ArrowForwardIosIcon style={{ fontSize: 12 }} />
          </div>
        </Button>
      </div>
      <div className="p-2">
        <div className="d-flex">
          <Button
            id="shopinfo_btn"
            variant="outlined"
            color="primary"
            startIcon={<Edit />}
            style={{ width: "50%", marginRight: 10 }}
            className="justify-content-start"
            onClick={() =>
              isMobile
                ? setValues({
                    ...values,
                    shop_details_form_open: true,
                    submenu_open: false
                  })
                : history.push(Path.SHOP_INFO)
            }
          >
            <div className={classes.btnText}>Edit shop info</div>
          </Button>
          <Button
            id="shopstyle_btn"
            variant="outlined"
            color="primary"
            startIcon={<Customize />}
            style={{ width: "50%" }}
            className="justify-content-start"
            onClick={() =>
              isMobile
                ? setValues({
                    ...values,
                    customize_shop_style_form_open: true,
                    submenu_open: false
                  })
                : history.push(Path.SHOP_STYLE)
            }
          >
            <div className={classes.btnText}>Customize template</div>
          </Button>
        </div>
        <div className="d-flex mt-2">
          <Button
            id="addproduct_btn"
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            style={{ width: "50%", marginRight: 10 }}
            className="justify-content-start"
            onClick={() => history.push(Path.PRODUCTS_ADD)}
          >
            <div className={classes.btnText}>Add a product</div>
          </Button>
          <Button
            id="delivery_btn"
            variant="outlined"
            color="primary"
            startIcon={<Delivery />}
            style={{ width: "50%" }}
            className="justify-content-start"
            onClick={() => history.push(Path.DELIVERY_SETTINGS)}
          >
            <div className={classes.btnText}>Set delivery rates</div>
          </Button>
        </div>
        <div className="d-flex mt-2">
          <Button
            id="addproduct_btn"
            variant="outlined"
            color="primary"
            startIcon={<Order />}
            style={{ width: "50%", marginRight: 10 }}
            className="justify-content-start"
            onClick={() => history.push(Path.QUICK_ORDER_SETTINGS)}
          >
            <div className={classes.btnText}>Manage order form</div>
          </Button>
          <CopyToClipboard
            onCopy={() =>
              dispatch(
                showAlert({ type: "success", message: "Copied to clipboard!" })
              )
            }
            text={site_url}
          >
            <Button
              id="delivery_btn"
              variant="outlined"
              color="primary"
              startIcon={<Share className="mr-1" />}
              style={{ width: "50%" }}
              className="justify-content-start"
            >
              <div className={classes.btnText}>Share shop link</div>
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}
