import { ActionTypes } from "./Actions";
import _ from 'lodash'
const initialState = {
  products: [],
  cart: {
    total: 0,
    qty: 0,
    items: []
  },
  orders: [],
  supplier: "",
  loading: {
    get: false,
    post: false,
    patch: false,
    delete: false
  },
  stores: [],
  error: null
};

export default function proxteraReducers(state = initialState, action) {
  let items = [];
  switch (action.type) {

    
    case ActionTypes.GET_STORES_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      }
    case ActionTypes.GET_STORES_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: false,
        },
        stores: action.payload.stores
      }
    case ActionTypes.GET_PRODUCTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      }
    case ActionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: false,
        },
        products: action.payload.products
      }
    case ActionTypes.GET_PRODUCT:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      }
    case ActionTypes.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: false,
        },
        products: _.uniqBy([...state.products, action.payload.product], 'id')
      }
    case ActionTypes.CREATE_ORDER:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      }
    case ActionTypes.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          // get: false,
        },
        cart: {
          total: 0,
          qty: 0,
          items: []
        },
        orders: [...state.orders, action.payload.order]
        // orders: action.payload.orders
      }
    
    case ActionTypes.GET_ORDERS:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      }
    case ActionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: false,
        },
        orders: action.payload.orders
      }
    case ActionTypes.GET_ORDER:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      }
    case ActionTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: false,
        },
        orders: _.uniqBy([...state.orders, action.payload.order], 'order_id')
      }
    case ActionTypes.EDIT_ORDER:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      }
    case ActionTypes.EDIT_ORDER_SUCCESS:
      return {
          ...state,
          loading: {
          ...state.loading,
          get: false,
          },
          orders: _.uniqBy([action.payload.order, ...state.orders], 'order_id')
      }
    case ActionTypes.CONFIRM_ORDER:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      }
    case ActionTypes.CONFIRM_ORDER_SUCCESS:
      let order = _.find(state.orders, ['order_id', action.payload.order_id])
      order = {
        ...order,
        status: "Accept"
      }
      return {
          ...state,
          loading: {
            ...state.loading,
            get: false,
          },
          orders: _.uniqBy([order, ...state.orders], 'order_id')
      }
    // CART
    case ActionTypes.ADD_TO_CART:
      items = [...state.cart.items, action.payload.item];
      return {
        ...state,
        cart: {
          ...state.cart,
          total: String(items.reduce((a, b) => a + (parseInt(b.unit_price).toFixed(2)*parseInt(b.quantity)), 0)),
          qty: String(items.reduce((a, b) => a + parseInt(b.quantity), 0)),
          items
        },
      };
    case ActionTypes.REMOVE_FROM_CART:
      items = [...state.cart.items.splice(action.payload.index+1, 1)];
      return {
        ...state,
        cart: {
          ...state.cart,
          total: String(items.reduce((a, b) => a + (parseInt(b.unit_price).toFixed(2)*parseInt(b.quantity)), 0)),
          qty: String(items.reduce((a, b) => a + parseInt(b.quantity), 0)),
          items: [...items]
        },
      };
    // supplier
    case ActionTypes.GET_SUPPLIER:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      };
    case ActionTypes.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: {
          get: false,
        },
        supplier: action.payload.supplier
      };
    // SME
    case ActionTypes.REGISTER_SME:
      return {
        ...state,
        loading: {
          ...state.loading,
          get: true,
        }
      };
    case ActionTypes.REGISTER_SME_SUCCESS:
      return {
        ...state,
        loading: {
          get: false,
        },
      };
    // setting request status
    case ActionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        loading: {
          get: false,
          post: false,
          patch: false,
          delete: false
        },
        error: null
      };
    case ActionTypes.REQUEST_FAILED:
      return {
        ...state,
        loading: {
          get: false,
          post: false,
          patch: false,
          delete: false
        },
        error: action.payload
      };
    default:
      return initialState;
  }
}
