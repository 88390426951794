import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "../../../store/history";
import { Button, useMediaQuery } from "@material-ui/core";
import CardPaymentForm from "./common/cardPaymentForm";
import PaymentBankingForm from "./common/paymentBankingForm";
import { useLocation } from "react-router-dom";
import BuyerActions from "./redux/actions";
import Skeleton from "@material-ui/lab/Skeleton";
function Checkout(props) {
  const useStyles = makeStyles(theme => ({
    header: {
      width: "65%",
      paddingTop: "5vh",
      display: "flex",
      alignItems: "center",
      position: "relative"
    },
    headerLabel: {
      fontSize: 28,
      fontWeight: 600,
      color: "#002c42",
      width: "100%",

      textAlign: "center"
    },
    label: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2b2d32"
    }
  }));
  const location = useLocation();
  const currentPath = location.search;
  const reference_id = currentPath.replace("?ref_id=", "");
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const checkoutItem = useSelector(state => state.buyerDashboard.checkout);
  const storeInfo = checkoutItem ? checkoutItem.store : "";
  const cartItems = JSON.parse(checkoutItem ? checkoutItem.cart : "[]");
  const loading = useSelector(state => state.buyerDashboard.loading);
  React.useEffect(() => {
    if (reference_id) {
      dispatch(BuyerActions.buxCheckout(reference_id));
    }
  }, [reference_id]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {
        <>
          {checkoutItem.payment_option === "otc" ? (
            checkoutItem.paid ? (
              <CardPaymentForm
                storeInfo={storeInfo}
                checkoutItem={checkoutItem}
                cartitems={cartItems}
                loading={loading}
              />
            ) : (
              <PaymentBankingForm
                storeInfo={storeInfo}
                checkoutItem={checkoutItem}
                cartitems={cartItems}
                loading={loading}
              />
            )
          ) : (
            <PaymentBankingForm
              storeInfo={storeInfo}
              checkoutItem={checkoutItem}
              cartitems={cartItems}
              loading={loading}
            />
          )}
        </>
      }
    </div>
  );
}
export default Checkout;
