import React from "react";
import { makeStyles } from "@material-ui/styles";
import Text from "../../../../../common/text";
import { TextField, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { saveProfile } from "../webBuilder/websiteBuilderActions";

const BasicInformation = props => {
  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    btnSection: {
      position: "absolute",
      width: "100%",
      bottom: 0,
      display: "flex",
      justifyContent: "space-between",
      padding: 20
    }
  }));
  const classes = useStyles();
  const user = useSelector(state => state.mobileDashboard.profileInformation);
  const current_user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const [edit, setEdit] = React.useState(false);
  const [hasErrors, setHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [profile, setProfile] = React.useState({
    first_name: "",
    last_name: "",
    mobile_number: ""
  });

  const [errors, setErrors] = React.useState({
    firstNameError: "",
    lastNameError: "",
    mobileNumberError: ""
  });
  function setProfileChanges(value, name) {
    setProfile(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const FIELD_MAX_LENGTHS = {
    first_name: 30,
    last_name: 30,
    mobile_number: 11
  };
  let charactersLeft = fieldname => {
    const fieldLength = profile[fieldname] ? profile[fieldname].length : 0;
    const charsRemaining = FIELD_MAX_LENGTHS[fieldname] - fieldLength;
    return `${charsRemaining} characters left`;
  };

  const handleSave = async () => {
    await dispatch(saveProfile(profile));
    setEdit(false);
  };

  React.useEffect(() => {
    for (let e in errors) {
      if (errors[e].length > 0) {
        setHasErrors(true);
        return;
      }
    }
    setHasErrors(false);
  }, [errors]);

  React.useEffect(() => {
    setIsFormEnough(
      profile.first_name && profile.last_name && profile.mobile_number
    );
  }, [profile]);
  return (
    <div>
      <div
        style={{
          backgroundColor: "#1e4b83",
          color: "#fff",
          padding: 20,
          fontSize: 12,
          display: current_user.is_facebookLogin ? "" : "none"
        }}
      >
        You are logged in via your Facebook account
      </div>
      <div style={{ padding: 20 }}>
        <div>
          <Text textType="p3_Semibold" content="First Name" />
          <TextField
            fullWidth
            margin="dense"
            id="first_name"
            color="secondary"
            variant="outlined"
            style={{ marginTop: 0 }}
            disabled={!edit}
            inputProps={{ maxLength: 30 }}
            value={edit ? profile.first_name : user.first_name}
            onChange={value => {
              const input = value.target.value;
              setProfileChanges(input, "first_name");

              if (input === "") {
                setErrors({
                  ...errors,
                  firstNameError: "This field is required."
                });
              } else {
                setErrors({
                  ...errors,
                  firstNameError: ""
                });
              }
            }}
            onBlur={value => {
              const input = value.target.value;
              if (input === "") {
                setErrors({
                  ...errors,
                  firstNameError: "This field is required."
                });
              } else {
                setErrors({
                  ...errors,
                  firstNameError: ""
                });
              }
            }}
            error={edit ? errors.firstNameError : ""}
            helperText={
              <div
                style={{
                  display: edit ? "flex" : "none",
                  justifyContent: "space-between"
                }}
              >
                <div>{errors.firstNameError}</div>
                <div>{charactersLeft("first_name")}</div>
              </div>
            }
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <Text textType="p3_Semibold" content="Last Name" />
          <TextField
            fullWidth
            margin="dense"
            id="last_name"
            color="secondary"
            variant="outlined"
            style={{ marginTop: 0 }}
            disabled={!edit}
            inputProps={{ maxLength: 30 }}
            value={edit ? profile.last_name : user.last_name}
            onChange={value => {
              const input = value.target.value;
              setProfileChanges(input, "last_name");

              if (input === "") {
                setErrors({
                  ...errors,
                  lastNameError: "This field is required."
                });
              } else {
                setErrors({
                  ...errors,
                  lastNameError: ""
                });
              }
            }}
            onBlur={value => {
              const input = value.target.value;
              if (input === "") {
                setErrors({
                  ...errors,
                  lastNameError: "This field is required."
                });
              } else {
                setErrors({
                  ...errors,
                  lastNameError: ""
                });
              }
            }}
            error={edit ? errors.lastNameError : ""}
            helperText={
              <div
                style={{
                  display: edit ? "flex" : "none",
                  justifyContent: "space-between"
                }}
              >
                <div>{errors.lastNameError}</div>
                <div>{charactersLeft("last_name")}</div>
              </div>
            }
          />
        </div>

        <div style={{ marginTop: 15 }}>
          <Text textType="p3_Semibold" content="Mobile Number" />
          <TextField
            fullWidth
            margin="dense"
            id="mobile_number"
            color="secondary"
            variant="outlined"
            style={{ marginTop: 0 }}
            disabled={!edit}
            inputProps={{ maxLength: 11 }}
            value={edit ? profile.mobile_number : user.mobile_number}
            onChange={value => {
              const input = value.target.value.replace(/[^0-9]/g, "");
              setProfileChanges(input, "mobile_number");
              if (input === "") {
                setErrors({
                  ...errors,
                  mobileNumberError: "This field is required"
                });
              } else {
                setErrors({ ...errors, mobileNumberError: "" });
              }
            }}
            onBlur={value => {
              const input = value.target.value;
              if (input === "") {
                setErrors({
                  ...errors,
                  mobileNumberError: "This field is required"
                });
              } else if (input.length !== 11) {
                setErrors({
                  ...errors,
                  mobileNumberError:
                    "Please enter a valid 11-digit mobile number."
                });
              } else if (input.charAt(0) != 0) {
                setErrors({
                  ...errors,
                  mobileNumberError: "You have entered an invalid mobile number"
                });
              } else if (input.charAt(1) != 9) {
                setErrors({
                  ...errors,
                  mobileNumberError: "You have entered an invalid mobile number"
                });
              } else {
                setErrors({ ...errors, mobileNumberError: "" });
              }
            }}
            error={edit ? errors.mobileNumberError : ""}
            helperText={
              <div
                style={{
                  display: edit ? "flex" : "none",
                  justifyContent: "space-between"
                }}
              >
                <div>{errors.mobileNumberError}</div>
                <div>{charactersLeft("mobile_number")}</div>
              </div>
            }
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <Text textType="p3_Semibold" content="Email" />
          <TextField
            disabled
            fullWidth
            margin="dense"
            id="email"
            color="secondary"
            variant="outlined"
            style={{ marginTop: 0 }}
            value={current_user.email}
          />
        </div>
      </div>
      {!edit ? (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            padding: 20
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            color="primary"
            onClick={() => {
              setEdit(true);
              setProfile(user);
            }}
          >
            Edit
          </Button>
        </div>
      ) : (
        <div className={classes.btnSection}>
          <Button
            style={{ width: "45%" }}
            variant="text"
            color="secondary"
            onClick={() => {
              setEdit(false);
              setErrors({
                firstNameError: "",
                lastNameError: "",
                mobileNumberError: ""
              });
            }}
          >
            Cancel
          </Button>

          <Button
            style={{ width: "45%" }}
            variant="outlined"
            color="primary"
            disabled={hasErrors || !isFormEnough}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default BasicInformation;
