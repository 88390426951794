import React from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Button, FormControlLabel, Switch, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Add } from "@material-ui/icons";
import PickupAddressModalForm from "../pickupAddressModalForm";
import PickupAddressCard from "../pickupAddressCard";
import Actions from "../../redux/actions";
import { destroy, change, touch } from "redux-form";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  label2: {
    fontSize: 14,
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)",
    margin: 0,
  },
  note: {
    background: "#EEEEEE",
    borderRadius: 2,
    fontSize: 12,
    padding: "1em 1.5em",
    marginTop: "1em",
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40, //28,
    height: 24, //16,
    padding: 0,
    display: "flex",
    marginRight: 5,
  },
  switchBase: {
    padding: 4,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
}))(Switch);

const Pickup = (props) => {
  const { page, setPage } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const values = useSelector((state) => state.form.delivery_details?.values);
  const pickup_addresses = useSelector(
    (state) => state.deliverySettings.pickup_addresses?.results
  );
  const pickup_address_count = useSelector(
    (state) => state.deliverySettings.pickup_addresses?.count
  );
  const syncErrors = useSelector((state) =>
    state.form.delivery_details ? state.form.delivery_details.syncErrors : []
  );
  const loading = useSelector((state) => state.deliverySettings.loading);
  // switches
  const [open, setOpen] = React.useState(false);

  const handleAddPickupAddress = () => {
    dispatch(destroy("delivery_details"));
    dispatch(change("delivery_details", "store", props.store_uuid));
    dispatch(change("delivery_details", "email", user.email));
    setOpen(true);
  };

  const handleEditPickupAddress = (data) => {
    const dataKeys = Object.keys(data);
    for (let i in dataKeys) {
      dispatch(change("delivery_details", dataKeys[i], data[dataKeys[i]]));
    }
    dispatch(change("delivery_details", "email", user.email));
    setOpen(true);
  };

  const handleDeletePickupAddress = (item) => {
    dispatch(Actions.deletePickupAddress(item));
  };

  const pickupAddressCount = () => {
    let length = pickup_address_count / 5;
    if (length <= 1) {
      return 1;
    } else {
      if (length % 1 === 0) {
        return length;
      } else {
        return parseInt(length) + 1;
      }
    }
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleSave = () => {
    const fieldnames = [
      "google_address",
      "landmark",
      "contact_person",
      "mobile_number",
      "show_pickup_address",
      "street",
      "province",
      "city",
      "brgy",
      "zipcode",
      "is_default",
    ];
    let errorFields = [];
    if (syncErrors) {
      errorFields = Object.keys(syncErrors);
    }
    const submit = async () => {
      let addresses = [];
      if (values?.is_default === true) {
        addresses = pickup_addresses.map((item) =>
          item.id !== values?.id
            ? { ...item, is_default: false }
            : { ...item, is_default: values?.is_default }
        );
      }
      let res = await dispatch(
        Actions.savePickupAddress(
          {
            ...values,
            show_pickup_address: props.is_show_pickup,
          },
          addresses
        )
      );

      if (res) {
        dispatch(
          Actions.getPickupAddress({
            store_uuid: values?.store,
            page: 1,
            itemsPerPage: 10,
          })
        );
        setOpen(false);
        setPage(1);
      }
    };
    if (errorFields.some((i) => fieldnames.includes(i))) {
      errorFields.forEach((i) => dispatch(touch("delivery_details", i)));
    } else {
      submit();
    }
  };

  return (
    <>
      <div className={`p-3 p-md-5`}>
        <div className="d-md-flex d-block mb-4 align-items-center justify-content-between">
          <p className={`col-md-7 col-12 pl-0 ${classes.label2}`}>
            Set your default pick-up location so couriers know where to pick up
            your items.
          </p>
          <div className="col-md-5 col-12 d-flex justify-content-end p-0">
            <Button
              startIcon={<Add />}
              variant="outlined"
              color="primary"
              onClick={handleAddPickupAddress}
            >
              Add new address
            </Button>
            <PickupAddressModalForm
              open={open}
              setOpen={setOpen}
              handleSave={handleSave}
              loading={loading}
            />
          </div>
        </div>
        <div className="pb-3">
          {pickup_addresses?.length
            ? pickup_addresses.map((item, index) => (
                <div className="mb-3">
                  <PickupAddressCard
                    key={index}
                    item={item}
                    handleEdit={handleEditPickupAddress}
                    handleDelete={handleDeletePickupAddress}
                  />
                </div>
              ))
            : ""}
          {pickup_address_count > 5 ? (
            <div className="mt-3 d-flex justify-content-center">
              <Pagination
                page={page}
                count={pickupAddressCount()}
                onChange={handleChangePage}
                shape="rounded"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex pt-4 mt-2 border-top">
          <div className="d-flex align-items-start">
            <FormControlLabel
              className="m-0"
              control={
                <AntSwitch
                  name="show_pickup_address"
                  checked={props.is_show_pickup}
                  onChange={() =>
                    props.set_is_show_pickup(
                      props.is_show_pickup ? false : true
                    )
                  }
                />
              }
            />
            <div className="d-block ml-4">
              <div className="mb-2 text-dark" style={{ fontSize: 16 }}>
                Allow customers to book their own courier for pick-up.
              </div>
              <p className={classes.label3}>
                (Your pick-up address will be displayed to the customer upon
                checkout.)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pickup;
