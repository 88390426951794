import React from "react";
import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import AppBar from "./AppBar";
import Footer from "./Footer";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import history from "../../../../store/history";
import StoreActions from "./StoreActions";
import { Radio } from "antd";
import Utils from "../../utils";
import imgPlaceholder from "../../../../assets/images/imgPlaceholder2.svg";
import { ThemeProvider } from "@material-ui/styles";
import {
  Breadcrumbs,
  Link,
  Checkbox,
  useTheme,
  useMediaQuery,
  Chip,
} from "@material-ui/core";
import CardPaymentForm from "../../../dashboard/buyerDashboard/common/cardPaymentForm";
import PaymentBankingForm from "../../../dashboard/buyerDashboard/common/paymentBankingForm";
import Blank from "./blank";
import NFTCheckout from "../nft/pages/checkout";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f8f8f8",
    width: "100%",
    padding: "6em 10vw",
    [theme.breakpoints.down("sm")]: {
      padding: "4em 0",
    },
  },
  breadcrumbs: {
    fontSize: 14,
    color: "rgba(43,45,50, 0.8)",
    "&:hover": {
      fontWeight: 600,
      textDecoration: "none",
      color: "#2b2d32",
    },
  },
  header: {
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: 28,
    color: "#2b2d32",
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
    },
  },
  details: {
    width: "65%",
    padding: 24,
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 15,
    },
  },
  label: {
    fontSize: 16,
    fontWeight: "normal",
    color: "rgba(43,45,50, 0.8)",
  },
  label2: {
    fontSize: 14,
    fontWeight: "normal",
    color: "rgba(43,45,50, 0.8)",
  },
  labelbold: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#2b2d32",
  },
  labelbold2: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#2b2d32",
  },
  btnContinue: {
    borderColor: "1px solid #2b2d32",
    height: 40,
  },
  btnCheckout: {
    marginLeft: "1vw",
    color: "#fff",
    backgroundColor: "#2b2d32",
    height: 40,
  },
  summary: {
    height: "fit-content",
    marginLeft: "2vw",
    padding: 24,
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  transaction: {
    marginTop: "4vh",
    display: "flex",
    justifyContent: "space-between",
  },
  columnItem: {
    border: "none",
    width: "60%",
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 600,
    color: "rgba(43,45,50, 0.8)",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0",
      width: "70%",
    },
  },
  column: {
    border: "none",
    width: "20%",
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 600,
    color: "rgba(43,45,50, 0.8)",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0",
      width: "30%",
    },
  },
  productImage: {
    width: 60,
    height: 60,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      width: 70,
      height: 70,
    },
  },
  itemSection: {
    display: "flex",
    alignItems: "center",
  },
  otcLogo: {
    width: "auto",
    height: 85,
  },
  variantChip: {
    borderRadius: 0,
    fontWeight: 500,
    fontSize: 10,
    marginRight: 5,
  },
}));

const PaymentBanking = (props) => {
  const { cartitems } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const loading = useSelector((state) => state.store.loading);
  const storeInfo = useSelector((state) => state.store.store);
  const checkoutItem = useSelector((state) => state.store.checkout);

  function getProduct(product) {
    let params = {
      ...product,
      price: product.unit_price,
      has_variants: product.productSelection ? true : false,
    };
    return params;
  }

  const breadcrumbLinks = [
    {
      label: "Home",
      path: `/shop/${storeInfo.site}`,
    },
    {
      label: "Cart",
      path: `/shop/${storeInfo.site}/cart`,
    },
    {
      label: "Details",
      path: `/shop/${storeInfo.site}/delivery`,
    },
    {
      label: "Delivery",
      path: `/shop/${storeInfo.site}/delivery_details`,
    },
    {
      label: "Payment",
      path: `/shop/${storeInfo.site}/payment`,
    },
  ];
  return (
    <div>
      {storeInfo && storeInfo.font.css_url ? (
        <link href={storeInfo.font.css_url} rel="stylesheet"></link>
      ) : (
        ""
      )}
      <div>
        <Breadcrumbs
          style={{ display: isMobile ? "none" : "" }}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbLinks.map((item) => {
            return (
              <Link
                className={classes.breadcrumbs}
                style={{
                  fontWeight: item.label === "Payment" ? "bold" : "normal",
                }}
                color="inherit"
                onClick={() => history.push(item.path)}
              >
                {item.label}
              </Link>
            );
          })}
        </Breadcrumbs>
      </div>
      <PaymentBankingForm
        storeInfo={storeInfo}
        checkoutItem={checkoutItem}
        cartitems={cartitems}
        loading={loading}
      />
    </div>
  );
};

const Checkout = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.search;
  const storeNamePath = location.pathname;
  const reference_id = currentPath.replace("?ref_id=", "");
  const removeParam = storeNamePath.replace("/checkout", "");
  const storeNameParam = removeParam.replace("/shop/", "");
  const theme = useTheme();
  const store_theme = useSelector((state) => state.store.store.color);
  const storeInfo = useSelector((state) => state.store.store);
  const loading = useSelector((state) => state.store.loading);
  const checkoutItem = useSelector((state) => state.store.checkout);
  const cartitems = JSON.parse(checkoutItem ? checkoutItem.cart : "[]");

  React.useEffect(() => {
    if (reference_id) {
      dispatch(StoreActions.buxCheckout({ reference_id: reference_id }));
    }
  }, [reference_id]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(storeNameParam));
    }
    fetchData();

    // if (!checkoutItem) {
    //   history.push(`/shop/${storeNameParam}`);
    // }
  }, []);

  const isCardPayments =
    checkoutItem.payment_option === "otc" && checkoutItem.paid;

  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold",
      },
      fontFamily: ["Inter", "sans-serif"],
    },
  });

  function setCheckoutTemplate() {
    switch (storeInfo ? storeInfo.template : "Blank") {
      case "Blank":
        return <Blank />;
      case "NFT":
        return (
          <ThemeProvider theme={store_generated_theme}>
            <NFTCheckout storeInfo={storeInfo} checkoutItem={checkoutItem} />
          </ThemeProvider>
        );
      default:
        return (
          <ThemeProvider theme={store_generated_theme}>
            <div className={classes.root}>
              <Loading open={loading} />
              {loading ? (
                ""
              ) : (
                <>
                  {checkoutItem.payment_option === "otc" ? (
                    checkoutItem.paid ? (
                      <CardPaymentForm
                        storeInfo={storeInfo}
                        checkoutItem={checkoutItem}
                        cartitems={cartitems}
                      />
                    ) : (
                      <PaymentBanking
                        storeInfo={storeInfo}
                        checkoutItem={checkoutItem}
                        cartitems={cartitems}
                      />
                    )
                  ) : (
                    <PaymentBanking
                      storeInfo={storeInfo}
                      checkoutItem={checkoutItem}
                      cartitems={cartitems}
                    />
                  )}{" "}
                  {!isCardPayments && (
                    <AppBar isPreview={false} storeInfo={storeInfo} />
                  )}
                </>
              )}
            </div>
            {!isCardPayments && <Footer isPreview={false} store={storeInfo} />}
          </ThemeProvider>
        );
    }
  }
  return (
    <div>{setCheckoutTemplate(storeInfo ? storeInfo.template : "Blank")}</div>
  );
};

export default Checkout;
