import React from "react";
import { useHistory } from "react-router";
import { useDispatch, connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  AppBar,
  useTheme,
  useMediaQuery,
  Dialog,
  Slide,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { reduxForm, reset } from "redux-form";
import { animateScroll as scroll } from "react-scroll";
import {
  useProductSelectionAxiosData,
  useProductSelectionsToDelete,
  getAllNewlyCreatedVariantIds,
} from "../variantChoicesForm";
import Form from "./form";
import { showAlert } from "../../../../common/commonActions";
import {
  getProduct,
  addProductv2,
  editProductv2,
  patchVariantGroups,
  getVariants,
  resetVariantGroups,
  saveProductSelections,
  getStoreProductCategories,
  ReduxInitializeFormActions,
  getProducts,
} from "../../../websiteBuilderActions";
import { getStoreInfo } from "../../../websiteBuilderActions";
import PublishedShopSuccess from "../../publishShopSuccess";
import DeleteModal from "./deleteModal";
import { ReactComponent as TrashSVG } from "../../../../../assets/images/enterprise/trash.svg";
import Path from "../../../../../Paths";
import MaxProductsModal from "../../components/products/maxProductsModal";

const DialogTransition = (props) => <Slide direction="up" {...props} />;

export const ProductForm = (props) => {
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const filters = useSelector((state) => state.webBuilder.products.filters);
  const subscription = useSelector((state) => state.subscription.subscription);
  const product_count = useSelector(
    (state) => state.webBuilder.products.products
  );
  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 850,
      margin: "2em 6vw",
      overflow: "hidden",
    },
    headerSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    content: {
      marginTop: "2em",
      left: "32vw",
      right: "10vw",
      borderTop: "1px solid #D4D5D8",
      borderLeft: "1px solid #D4D5D8",
      borderRight: "1px solid #D4D5D8",
      borderBottom: "none",
      borderRadius: 4,
      overflow: "visible",
      backgroundColor: "white",
    },
    btnDiv: {
      borderBottom: "1px solid #D4D5D8",
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      paddingTop: 0,
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      paddingBottom: "1.5rem",
    },
    appbar: {
      backgroundColor: "white",
      boxShadow: "none",
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%",
    },
    closeBtn: {
      position: "absolute",
    },
    deleteButtonMobile: {
      position: "absolute",
      right: 18,
      top: 20,
      height: 18,
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const product_uuid = props.match.params.uuid;
  const [openMaxProduct, setOpenMaxProduct] = React.useState(false);
  // INITIALIZE FORM
  React.useEffect(() => {
    const onMount = async () => {
      await dispatch(getStoreInfo());
      // edit
      if (product_uuid) {
        try {
          const res = await dispatch(getProduct(product_uuid));
          dispatch(
            props.initializeData({
              ...res.data,
              img: res.data.gallery ? res.data.gallery.map((i) => i.image) : [],
              category: res.data.category || "Others",
              weight:
                res.data.weight === null || res.data.weight === ""
                  ? ""
                  : !res.data.weight.includes("-")
                  ? ">3"
                  : res.data.weight,
              free_form_weight:
                res.data.weight === null || res.data.weight === ""
                  ? ""
                  : !res.data.weight.includes("-")
                  ? res.data.weight
                  : "",
              length: res.data.length || "",
              width: res.data.width || "",
              height: res.data.height || "",
              lead_time: res.data.lead_time === 0 ? 1 : res.data.lead_time,
            })
          );
          dispatch(getVariants(product_uuid));
        } catch {
          // SHOW ALERT
          dispatch(
            showAlert({
              type: "error",
              message: `Something went wrong. Please try again!`,
            })
          );
          history.push("/products");
        }
      }
      // new
      else {
        dispatch(reset("product_form"));
        dispatch(
          props.initializeData({
            description: "",
            always_available: false,
            discounted_price: "",
            featured: false,
            has_variants: false,
            quantity: 5,
            category: "Others",
            status: "active",
            product_code: "",
            is_physical_product: true,
            length: "",
            width: "",
            height: "",
            made_to_order: true,
            lead_time: 1,
          })
        );
      }
    };
    onMount();
  }, []);

  React.useEffect(() => {
    if (props.store) {
      dispatch(getStoreProductCategories(props.store.uuid));
      dispatch(getProducts(props.store.uuid, filters));
    }
  }, [props.store]);

  let product_length = product_count.active_count + product_count.drafts_count;
  React.useEffect(() => {
    //SHOW IF THERE'S NO PRODUCT UUID
    if (!product_uuid) {
      if (
        (subscription.subscription_type === "advanced" ||
          subscription.subscription_type === "free_trial") &&
        product_length >= 300
      ) {
        setOpenMaxProduct(true);
      } else if (
        (subscription.free_trial_availed || !subscription.free_trial_availed) &&
        !subscription.subscription_type &&
        product_length >= 50
      ) {
        setOpenMaxProduct(true);
      }
    }
  }, [product_count]);

  const variantCombinationFields = useSelector(
    (state) => state.form.variant_choices?.registeredFields
  );
  const variantChoicesErrors = useSelector(
    (state) => state.form.variant_choices?.syncErrors
  );

  const productValues = useSelector((state) => state.form.product_form?.values);
  const [variantGroups, setVariantGroups] = React.useState();
  const productSelectionsData = useProductSelectionAxiosData();
  const productSelectionsToDelete = useProductSelectionsToDelete();
  const [clickedButton, setClickedButton] = React.useState("submit");

  const onSubmit = async (redirect_back = true) => {
    console.log("submit");
    if (
      props.errors ||
      variantChoicesErrors ||
      (productValues.has_variants && !variantCombinationFields)
    ) {
      if (props.errors) {
        props.touch(...Object.keys(props.errors));
      }
      scroll.scrollTo(0);
    } else {
      try {
        if (props.store.uuid) {
          // PRODUCT
          // edit product
          let res;
          if (product_uuid) {
            res = await dispatch(
              editProductv2(props.store, {
                ...productValues,
                price: productValues.price || 0,
                productImages: productValues.img,
                weight: productValues.weight.includes("-")
                  ? productValues.weight
                  : productValues.free_form_weight,
                store: props.store.uuid,
              })
            );
          }
          // new
          else {
            res = await dispatch(
              addProductv2(props.store, {
                ...productValues,
                price: productValues.price || 0,
                productImages: productValues.img,
                weight: !productValues.weight
                  ? ""
                  : productValues.weight.includes("-")
                  ? productValues.weight
                  : productValues.free_form_weight,
                store: props.store.uuid,
              })
            );
          }

          // VARIANT GROUP
          res = await dispatch(
            patchVariantGroups(res.data.uuid, variantGroups)
          );

          // VARIANT COMBINATIONS
          const data = productSelectionsData.map((i) => ({
            ...i,
            product_variant_ids: getAllNewlyCreatedVariantIds(res.data, i),
          }));

          // REMOVE DUPLICATES IN productSelectionsToDelete
          const removeDuplicatesPSTD = [
            ...new Map(
              productSelectionsToDelete.map((i) => [i.id, i])
            ).values(),
          ];

          // FILTERED removeDuplicatesPSTD
          const filteredPSTD = removeDuplicatesPSTD.filter(
            (i) =>
              !data
                .filter((i) => i.id)
                .map((i) => i.id)
                .includes(i.id)
          );

          await dispatch(saveProductSelections(data, filteredPSTD));

          // RESET FIELDS
          props.reset();
          dispatch(resetVariantGroups());
          if (redirect_back) {
            history.push("/products");
          } else {
            scroll.scrollTo(0);
          }
          // SHOW ALERT
          dispatch(
            showAlert({
              type: "success",
              message: `Product successfully ${
                product_uuid ? "updated" : "added"
              }!`,
            })
          );
        }
      } catch (err) {}
    }
  };

  const onDelete = async () => {
    if (product_uuid) {
      const res = await dispatch(
        editProductv2(props.store, {
          ...productValues,
          status: "deleted",
        })
      );
      if (res) {
        // SHOW ALERT
        dispatch(
          showAlert({
            type: "success",
            message: `Product successfully deleted!`,
          })
        );
        history.push("/products");
      }
    }
  };

  const loading = useSelector((state) => state.webBuilder.loading);
  const spinner = (
    <div className="d-flex my-5 mr-3" id="spinner">
      <div
        className="spinner-border mx-auto"
        role="status"
        style={{
          color: clickedButton === "submit" ? "white" : "gray",
          fontSize: 12,
          height: 25,
          width: 25,
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  return (
    <div id="content">
      <PublishedShopSuccess />
      {is_mobile ? (
        <Dialog
          id="product_form_dialog"
          fullScreen
          open={is_mobile}
          TransitionComponent={DialogTransition}
        >
          <div id="product_form_dialog_content" />
          <AppBar className={classes.appbar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                className={classes.closeBtn}
                onClick={() => history.goBack()}
              >
                <ArrowBackRoundedIcon style={{ color: "#f97663" }} />
              </IconButton>
              <div className={classes.header}>
                {product_uuid ? "Edit" : "Add"} a product
              </div>
              {product_uuid ? (
                <DeleteModal onConfirm={onDelete}>
                  {(setOpen) => (
                    <TrashSVG
                      fill={theme.palette.primary.main}
                      onClick={() => {
                        setOpen(true);
                      }}
                      className={classes.deleteButtonMobile}
                      disabled={loading}
                    />
                  )}
                </DeleteModal>
              ) : (
                ""
              )}
            </Toolbar>
          </AppBar>
          <div
            style={{
              height: `calc(100vh - 200px)`,
              marginTop: 60,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Form
              product_uuid={product_uuid}
              variantGroupOnChange={setVariantGroups}
            />
          </div>
          <div
            className="position-fixed bg-white p-3 w-100"
            style={{ bottom: 0, zIndex: 99 }}
          >
            {!product_uuid ? (
              <Button
                color="primary"
                fullWidth
                variant="outlined"
                id="submit_btn"
                className="mb-2"
                disableElevation
                onClick={() => {
                  setClickedButton("add_another");
                  onSubmit(false);
                }}
                disabled={loading}
              >
                {loading && clickedButton === "add_another" ? spinner : ""}
                Submit and add another product
              </Button>
            ) : (
              <DeleteModal onConfirm={onDelete}>
                {(setOpen) => (
                  <Button
                    color="primary"
                    fullWidth
                    variant="outlined"
                    id="delete_btn"
                    className="mb-2"
                    disableElevation
                    onClick={() => {
                      setClickedButton("delete_product");
                      setOpen(true);
                    }}
                    disabled={loading}
                  >
                    {loading && clickedButton === "delete_product"
                      ? spinner
                      : ""}
                    Delete product
                  </Button>
                )}
              </DeleteModal>
            )}
            <Button
              color="primary"
              fullWidth
              variant="contained"
              disableElevation
              onClick={() => {
                setClickedButton("submit");
                onSubmit();
              }}
              disabled={loading}
            >
              {loading && clickedButton === "submit" ? spinner : ""}
              {product_uuid ? "Save changes" : "Submit product"}
            </Button>
          </div>
        </Dialog>
      ) : (
        <div id="product_form_root" className={classes.root}>
          <div className={classes.headerSection}>
            <Button
              startIcon={<ArrowBackIcon />}
              variant="text"
              color="primary"
              id="back_button"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <div
              style={{
                fontSize: 28,
                color: "#002c42",
                fontWeight: 600,
              }}
            >
              {product_uuid ? "Edit" : "Add"} a product
            </div>
            <DeleteModal onConfirm={onDelete}>
              {(setOpen) => (
                <Button
                  style={{ opacity: product_uuid ? 100 : 0 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(true)}
                >
                  Delete
                </Button>
              )}
            </DeleteModal>
          </div>
          <div className={classes.content}>
            <div>
              <Form
                product_uuid={product_uuid}
                variantGroupOnChange={setVariantGroups}
              />
            </div>
            <div className={classes.btnDiv}>
              {!product_uuid ? (
                <Button
                  color="primary"
                  fullWidth
                  variant="outlined"
                  id="submit_another_btn"
                  className="mb-2"
                  disableElevation
                  onClick={() => {
                    setClickedButton("add_another");
                    onSubmit(false);
                  }}
                  disabled={loading}
                >
                  {loading && clickedButton === "add_another" ? spinner : ""}
                  Submit and add another product
                </Button>
              ) : (
                ""
              )}
              <Button
                color="primary"
                fullWidth
                variant="contained"
                id="submit_btn"
                disableElevation
                onClick={() => {
                  setClickedButton("submit");
                  onSubmit();
                }}
                disabled={loading}
              >
                {loading && clickedButton === "submit" ? spinner : ""}
                {product_uuid ? "Save changes" : "Submit product"}
              </Button>
            </div>
          </div>
        </div>
      )}
      <MaxProductsModal
        open={openMaxProduct}
        setOpen={setOpenMaxProduct}
        goToProductsPage={true}
        product_length={product_length}
      />
    </div>
  );
};

const validate = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((field) => {
    // discounted_price
    if (field.includes("discounted_price")) {
      // check if discounted is less than the orig
      const orig_price = values[`price`];
      const discounted_price = values[field];
      if (
        discounted_price !== 0 &&
        parseFloat(orig_price) <= parseFloat(discounted_price)
      ) {
        errors[field] =
          "Discounted price should be lower than the original price.";
      }
    }
  });
  return errors;
};

let ReduxProductForm = reduxForm({
  form: "product_form",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(ProductForm);

ReduxProductForm = connect(
  (state) => ({
    initialValues: state.webBuilder.reduxForm.productForm,
    store: state.webBuilder.storeInfo,
    errors: state.form.product_form?.syncErrors,
    regFields: state.form.product_form?.registeredFields,
  }),
  {
    initializeData: ReduxInitializeFormActions.initializeProductFormData,
  } // bind account loading action creator
)(ReduxProductForm);

export default ReduxProductForm;
