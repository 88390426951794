import React from "react";

import defaultSRC from "./defaultResources";
import apiConfig from "../../../../common/apiConfig";
import { useSelector } from "react-redux";
import FooterTemplate from "./footer/footerTemplate";
import { useTheme, useMediaQuery } from "@material-ui/core";

const Footer = props => {
  let theme = useTheme();
  let mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  let storeValue = useSelector(state => state.store.store);
  let storeInfoValue = useSelector(state => state.webBuilder.storeInfo);
  let products = useSelector(state => state.store.products);
  let storeInfo = props.isPreview ? props.store : storeValue;
  let contactus_image = storeInfo ? `${storeInfo.contactus_image}` : "";
  let isPreview = props.isPreview;
  const navBtn = [
    {
      label: "HOME",
      path: `/shop/${storeInfo ? storeInfo.site : ""}`
    },
    {
      label: "ABOUT US",
      path: `/shop/${storeInfo ? storeInfo.site : ""}/about_us`
    },
    {
      label: "PRODUCTS",
      path: `/shop/${storeInfo ? storeInfo.site : ""}/products`
    },
    {
      label: "CART",
      path: `/shop/${storeInfo ? storeInfo.site : ""}/cart`
    }
  ];
  return (
    <div>
      <FooterTemplate
        setImageSettings={props.setImageSettings}
        isPreview={isPreview}
        storeInfo={storeInfo}
        store={props.store}
        products={products}
        navBtn={navBtn}
        mobileView={mobileView}
      />
    </div>
  );
};

export default Footer;
