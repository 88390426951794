import {
  getProductCategoriesService,
  getStores,
  createVoucherServices,
  getVoucherService,
  editVoucherServices,
  filterVoucherService,
  getProductsServices,
} from "./services";
import { signOff } from "../auth/authActions";
import { showAlert } from "../common/template/components/Alert";

export const actionTypes = {
  LOADING_ON: "@@LOADING_ON",
  LOADING_OFF: "@@LOADING_OFF",
  GET_PRODUCT_CATEGORIES: "@@GET_PRODUCT_CATEGORIES",
  GET_PRODUCT_CATEGORIES_SUCCESS: "@@GET_PRODUCT_CATEGORIES_SUCCESS",
  GET_PRODUCT_CATEGORIES_FAILED: "@@GET_PRODUCT_CATEGORIES_FAILED",
  GET_STORE_SUCCESS: "@@GET_STORE_SUCCESS",
  GET_VOUCHER_LIST: "@@GET_VOUCHER_LIST",
  GET_FILTER_VOUCHERS: "@@GET_FILTER_VOUCHERS",
  CREATE_VOUCHER_SUCCESS: "@@CREATE_VOUCHER_SUCCESS",
  SET_VOUCHER_FILTERS: "@@SET_VOUCHER_FILTERS",
  SET_SELECTED_VOUCHER: "@@SET_SELECTED_VOUCHER",
  GET_PRODUCTS: "vouchers/GET_PRODUCTS",
};

export function getVouchers(params = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LOADING_ON });
      let response = await getStores();
      dispatch({
        type: actionTypes.GET_STORE_SUCCESS,
        payload: response,
      });
      if (response.length) {
        let vouchers = await getVoucherService();
        dispatch({
          type: actionTypes.GET_VOUCHER_LIST,
          payload: vouchers,
        });
      }
      dispatch({ type: actionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      }
    }
  };
}

export function filterVouchers(params) {
  return async (dispatch) => {
    try {
      let response = await filterVoucherService(params);
      dispatch({
        type: actionTypes.GET_FILTER_VOUCHERS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: actionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      }
    }
  };
}
export function getProductCategories(store_uuid) {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_PRODUCT_CATEGORIES,
      });
      let response = await getProductCategoriesService(store_uuid);
      dispatch({
        type: actionTypes.GET_PRODUCT_CATEGORIES_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: actionTypes.GET_PRODUCT_CATEGORIES_FAILED,
      });
    }
  };
}

export function createVoucher(params, filters, stores) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LOADING_ON });
      let response = await createVoucherServices(params, stores);
      if (response) {
        let filterVouchers = await filterVoucherService(filters);
        dispatch({
          type: actionTypes.GET_FILTER_VOUCHERS,
          payload: filterVouchers,
        });
      }
      dispatch({ type: actionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again",
          })
        );
      }
    }
  };
}

export function editVoucher(params, filters, stores, selectedVoucherCode) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LOADING_ON });
      let response = await editVoucherServices(
        params,
        stores,
        selectedVoucherCode
      );
      if (response) {
        let vouchers = await filterVoucherService(filters);
        dispatch({
          type: actionTypes.GET_FILTER_VOUCHERS,
          payload: vouchers,
        });
      }
      dispatch({ type: actionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again",
          })
        );
      }
    }
  };
}

export function setSelectedVoucher(voucher) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_VOUCHER,
      payload: voucher,
    });
  };
}

export function setVoucherFilters(payload) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_VOUCHER_FILTERS,
      payload,
    });
  };
}

export function getProducts(store_uuid, params) {
  return async (dispatch) => {
    let response = await getProductsServices(store_uuid, params);
    dispatch({
      type: actionTypes.GET_PRODUCTS,
      payload: response.data,
    });
  };
}
