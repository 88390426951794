import { makeStyles } from "@material-ui/core/styles";
import banner from "../../assets/images/banner2.png";
const regStyles = makeStyles(theme => ({
  banner: {
    width: "100%",
    overflow: "hidden",
    height: 330,
    background:
      "linear-gradient(90deg, rgba(70,9,92,1) 0%, rgba(197,76,99,1) 50%, rgba(255,156,142,1) 100%)",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      height: 500
    }
  },

  bannerImage: {
    width: 330,
    height: 400,
    position: "absolute",
    margin: "5%",
    top: 0,
    left: 0,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner})`,
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  btnFacebook: {
    backgroundColor: "#3B5998",
    color: "#fff",
    fontWeight: "bold",
    fontSize: 16,
    width: "50%"
  },

  root: {
    paddingTop: "7vh"
  },
  tou: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10vh",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingTop: "5vh"
    }
  },
  sideMenu: {
    position: "sticky",
    top: "30vh",
    height: "100vh",
    display: "block",
    width: "35%",
    position: "-webkit-sticky",
    position: "sticky",
    top: "12vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "static",
      height: 270
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      position: "static",
      height: "35vh"
    }
  },

  box: {
    // display: "block",
    // flexGrow: 1,
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  content: {
    backgroundColor: "#fff",
    padding: "55px 100px",

    marginBottom: "8vh",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 50px"
    }
  },
  footer: {
    height: "50px",
    width: "100%"
  },

  footerBox: {
    padding: "20px 65px",
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  footerContent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center"
    }
  },

  footerContent2: {
    marginLeft: 415,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: 0,
      textAlign: "center"
    }
  }
}));
export default regStyles;
