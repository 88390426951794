import React from "react";
import { Button, Chip, Badge, IconButton } from "@material-ui/core";
import apiConfig from "../../../../common/apiConfig";
import { useSelector, useDispatch } from "react-redux";
import { goToCompleteProfile } from "../../../payments/action";
import Utils from "../../../common/utils";
import { Launch } from "@material-ui/icons";
import wallet from "../../../../assets/images/wallet_filled.svg";
import buxLogo from "../../../../assets/images/buxLogo.svg";
export default function CODWallet(props) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.payments.loading);
  const codWallet = useSelector(state => state.payments.codWallet);
  const bux = useSelector(state => state.payments.buxParams);
  const kyc = useSelector(state => state.payments.buxStatus);
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const cashInLimit = bux ? bux.cashin_limits : "";
  const cashIn_maxValue = cashInLimit ? cashInLimit[1] : 0;
  const buxSegments = kyc => {
    if (cashIn_maxValue === 100000) {
      return "BUxME";
    } else if (cashIn_maxValue === 500000) {
      return "BUxGIG";
    } else if (cashIn_maxValue === 0) {
      return "BUxSME";
    } else {
      return "BUxME";
    }
  };
  return (
    <div
      className="bg-white rounded my-3"
      style={{ border: "1px solid #d1d5dd" }}
    >
      <div
        style={{ backgroundColor: "#3378B4" }}
        className="p-md-4 p-3 rounded-top d-flex"
      >
        <Badge
          badgeContent={
            <img
              src={buxLogo}
              width="20"
              height="20"
              alt=""
              className="mt-2 ml-2"
            />
          }
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <img src={wallet} alt="icon" width="50" height="50" />
        </Badge>

        <div className="w-100 pl-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="text-white" style={{ fontSize: 14 }}>
              Payout wallet
            </div>
            <Chip
              size="small"
              label={buxSegments()}
              style={{ backgroundColor: "#e5f2fd", color: "#2a3d85" }}
            />
          </div>
          <div
            className="font-weight-light text-white pt-4"
            style={{ fontSize: 12 }}
          >
            Balance
          </div>
          <div className="d-flex justify-content-between align-items-center w-100">
            <strong style={{ fontSize: 28 }} className="text-white">
              {loading ? (
                <div
                  id="loading"
                  className="spinner-border mx-auto"
                  role="status"
                  style={{
                    color: "white",
                    fontSize: 12,
                    height: 25,
                    width: 25
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                `₱ ${Utils.commafy(codWallet.usable_balance)}`
              )}
            </strong>
            <div
              className="text-white font-weight-light d-md-flex d-none align-items-center"
              style={{ fontSize: 14, cursor: "pointer" }}
              onClick={() => dispatch(goToCompleteProfile(user.id, kyc))}
            >
              Go to BUx{" "}
              <Launch
                style={{ color: "white", fontSize: 23 }}
                className="pl-2"
              />
            </div>
            <IconButton
              className="d-md-none d-flex"
              onClick={() => dispatch(goToCompleteProfile(user.id, kyc))}
            >
              {" "}
              <Launch style={{ color: "white", fontSize: 23 }} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="bg-white px-md-4 px-3 py-4">
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => window.open(apiConfig.bux_wallet_add_money)}
        >
          Top up
        </Button>
      </div>
    </div>
  );
}
