import { ForgotPasswordActionTypes } from "./forgotPasswordAction";
const initialState = {
  user: {},
  changePasswordDetails: {},
  loading: false,
  error: "",
  resendSuccess: false
};
export default function forgotPasswordReducers(state = initialState, action) {
  switch (action.type) {
    case ForgotPasswordActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ForgotPasswordActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    case ForgotPasswordActionTypes.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.payload
      };
    case ForgotPasswordActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ForgotPasswordActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        changePasswordDetails: action.payload
      };
    case ForgotPasswordActionTypes.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ForgotPasswordActionTypes.RESEND_SUCCESS:
      return {
        ...state,
        resendSuccess: true,
        loading: false
      };
    case ForgotPasswordActionTypes.CLEAR:
      return initialState;

    default:
      return initialState;
  }
}
