import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../../store/history";
import usdcLogo from "../../../../../assets/images/usdc-logo.png";
import strengthImg from "../img/strength.png";
import spiritImg from "../img/spirit.png";
import smartsImg from "../img/smarts.png";
import staminaImg from "../img/stamina.png";
import leftArrowImg from "../img/leftarrow.png";
import sunImg from "../img/sun.png";
import "../style/custom.css";
import Services from "../redux/services";
import { ethers } from "ethers";
import axios from "axios";
import Actions from "../redux/actions";

import SuccessModal from "../components/successModal";
import NFTConfirmModal from "../components/confirmationModal";
import METAMASK_NETWORKS from "../../../../../utils/MetamaskNetworks";
import { handleGetCurrentWallet } from "../../../../../utils/Metamask";
import {
  handleError,
  handleGetBalance,
  handleGetNonce,
  handleMintSingleBlocksWithPermit,
  handleSignTransaction,
  handleSuccessMessage,
} from "../../../../../utils/Metamask";
import { Alert } from "antd";
import apiConfig from "../../../../../common/apiConfig";
import hash from "hash.js";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import PaymentOptionModal from "../components/paymentOptionModal";
import StoreActions from "../../components/StoreActions";
import { showAlert } from "../../components/Alert";

const NFTDetails = (props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentOptionModal, setShowPaymentOptionModal] = useState(false);
  let walletAddress = useSelector((state) => state.nft.walletAddress);
  let ipfs = useSelector((state) => state.nft.ipfs);
  let uuid = useSelector((state) => state.store.store.uuid);
  let storeInfo = useSelector((state) => state.store.store);
  const dispatch = useDispatch();
  const CryptoJS = require("crypto-js");

  const [traits, setTraits] = useState([]);
  const traitsImage = [
    {
      name: "STRENGTH",
      image: strengthImg,
    },
    {
      name: "SPIRIT",
      image: spiritImg,
    },
    {
      name: "SMARTS",
      image: smartsImg,
    },
    {
      name: "STAMINA",
      image: staminaImg,
    },
  ];

  const goBack = () => {
    history.push(`/shop/${props.store.site}`);
  };

  const buyNow = () => {
    setShowConfirmModal(true);
  };

  const handleCryptoCheckout = async () => {
    if (walletAddress == null) {
      handleError("Please connect a wallet.");
      setShowPaymentOptionModal(false);
      return;
    }

    let response = await handleGetCurrentWallet();
    if (response) {
      openSuccess();
    }
  };

  const openSuccess = async () => {
    setIsLoading(true);
    let orderResponse = null;
    // let token = await Services.GET_AUTH_TOKEN_SENTRO();
    const { uuid: userId } = jwt_decode(Cookies.get("accesstoken"));
    let _metadata;
    let postbackDetails = {};
    let subtotal = 0;
    const currency = Number(apiConfig.chainId) === 137 ? "USDC" : "USDT";
    let tokenId;
    let transactionHash;

    try {
      let walletBalance = await handleGetBalance();
      subtotal = parseFloat(props.product.selections[0].price);
      let platformFee = parseFloat(apiConfig.platformFee);

      let platformFeeComputed = parseFloat(subtotal * platformFee);
      platformFeeComputed = platformFeeComputed.toFixed(6);

      let totalAmountDue =
        parseFloat(subtotal) + parseFloat(platformFeeComputed);
      totalAmountDue = totalAmountDue.toFixed(6);
      let parsedTotalAmount = parseInt(
        ethers.utils.parseUnits(totalAmountDue.toString(), 6)
      );
      // let userId = ""
      // if (localStorage.userId)
      //   userId = JSON.parse(localStorage.getItem('userId'))

      if (parsedTotalAmount > walletBalance) {
        handleError("Insufficient balance.");
        setShowConfirmModal(false);
        setShowPaymentOptionModal(false);
      } else {
        // re-arranged due to create order dependency for fiat payment
        let ipfs = await Services.UPLOAD_IMAGE_IPFS({
          fileUri: props.productImages[0].original,
          deleteOriginalFile: false,
        });
        let tokenUri = null;

        if (ipfs) {
          _metadata = buildMetaData(ipfs);
          tokenUri = await Services.BUILD_METADATA_IPFS(_metadata);
        }

        let order = {
          amount: totalAmountDue,
          recipient: apiConfig.recipientAddress,
          address: walletAddress,
          full_name: props.product.selections[0].sku + "::" + userId,
          mobile_number: "",
          cart: JSON.stringify([
            {
              uuid: props.product.uuid,
              quantity: 1,
              product_selection: props.product.selections[0].id,
            },
          ]),
          items: [
            {
              uuid: props.product.uuid,
              qty: 1,
              always_available: false,
              product_selection: props.product.selections[0].id,
            },
          ],
          subtotal: subtotal,
          other_fees: { cod_fee: 0.0 },
          channel: "PayMaya",
          payment_option: "deposit",
          is_read: false,
          shipping_fee: platformFee,
          delivery_method: "metamask",
          remarks: {
            contractAddress: apiConfig.contractAddress,
            userId,
            nftMetadata: JSON.stringify(_metadata),
            tokenUri: tokenUri.data.result.ipfs,
            sellerAdress: apiConfig.recipientWalletAddress,
            price_in_php: props.product.selections[0].price_in_php,
          },
        };

        orderResponse = await Services.CREATE_ORDER(order, uuid);
        let mintPermit = null;

        if (orderResponse) {
          mintPermit = await Services.GET_MINT_PERMIT(
            walletAddress,
            apiConfig.recipientWalletAddress
          );

          if (mintPermit) {
            let nonce = await handleGetNonce(walletAddress);
            let {
              permitSig: signTransaction,
              v,
              r,
              s,
            } = await handleSignTransaction(
              walletAddress,
              subtotal,
              nonce,
              mintPermit.data.result.permit.deadline,
              mintPermit.data.result.permit.address
            );

            let {
              permitSig: signPlatformFee,
              v: vPfee,
              r: rPfee,
              s: sPfee,
            } = await handleSignTransaction(
              walletAddress,
              platformFeeComputed,
              Number(nonce) + 1,
              mintPermit.data.result.permit.deadline,
              mintPermit.data.result.permit.address
            );

            if (signTransaction && signPlatformFee) {
              let mint = await handleMintSingleBlocksWithPermit(
                walletAddress,
                apiConfig.recipientWalletAddress,
                tokenUri.data.result.ipfs,
                subtotal,
                mintPermit.data.result.permit.deadline,
                mintPermit.data.result.permit.signature,
                v,
                r,
                s,
                mintPermit.data.result.permit.address,
                vPfee,
                rPfee,
                sPfee,
                platformFee
              );
              setShowConfirmModal(false);

              let expectedPlainTextSignature;
              let postbackSignature;
              if (mint) {
                tokenId = mint?.events?.PurchaseBlock?.returnValues?.tokenId;
                transactionHash = mint?.transactionHash;

                expectedPlainTextSignature = `${
                  orderResponse.data.ref_id
                }${"paid"}{${apiConfig.orderPostbackSecret}}`;
                postbackSignature = CryptoJS.SHA1(expectedPlainTextSignature);

                await Services.SEND_NFT_POSTBACK({
                  req_id: orderResponse.data.ref_id,
                  client_id: apiConfig.orderPostbackClientId,
                  status: "paid",
                  signature: `${postbackSignature.toString()}`,
                });

                postbackDetails.refId = props.product.selections[0].sku;
                postbackDetails.status = "SUCCESS";
                postbackDetails.metadata = {
                  userId,
                  nft: JSON.stringify(_metadata),
                  price: [
                    {
                      currency,
                      amount: subtotal,
                    },
                  ],
                  walletAddress,
                  tokenId,
                  transactionHash,
                  contractAddress: apiConfig.contractAddress,
                };

                postbackDetails.signature = hash
                  .sha256()
                  .update(
                    `${postbackDetails.refId}||${postbackDetails.status}|${apiConfig.alamatchClientSecret}`
                  )
                  .digest("hex");

                await Services.SEND_ALAMATCH_POSTBACK({
                  clientId: apiConfig.alamatchClientId,
                  refId: postbackDetails.refId,
                  status: postbackDetails.status,
                  metadata: postbackDetails.metadata,
                  signature: postbackDetails.signature,
                });

                setShowSuccessModal(true);
              } else {
                expectedPlainTextSignature = `${
                  orderResponse.data.ref_id
                }${"cancelled"}{${apiConfig.orderPostbackSecret}}`;
                postbackSignature = CryptoJS.SHA1(expectedPlainTextSignature);

                await Services.SEND_NFT_POSTBACK({
                  req_id: orderResponse.data.ref_id,
                  client_id: apiConfig.orderPostbackClientId,
                  status: "cancelled",
                  signature: `${postbackSignature.toString()}`,
                });

                postbackDetails.refId = props.product.selections[0].sku;
                postbackDetails.status = "FAILED";
                postbackDetails.metadata = {
                  userId,
                  nft: JSON.stringify(_metadata),
                  price: [
                    {
                      currency,
                      amount: subtotal,
                    },
                  ],
                  walletAddress,
                  tokenId,
                  transactionHash,
                  contractAddress: apiConfig.contractAddress,
                };
                postbackDetails.signature = hash
                  .sha256()
                  .update(
                    `${postbackDetails.refId}||${postbackDetails.status}|${apiConfig.alamatchClientSecret}`
                  )
                  .digest("hex");

                await Services.SEND_ALAMATCH_POSTBACK({
                  clientId: apiConfig.alamatchClientId,
                  refId: postbackDetails.refId,
                  status: postbackDetails.status,
                  metadata: postbackDetails.metadata,
                  signature: postbackDetails.signature,
                });
                setShowPaymentOptionModal(false);
                setShowSuccessModal(false);
              }
            }
          }
        }
      }
    } catch (error) {
      // For JWT Invalid Token
      if (error?.message?.includes("Invalid token specified")) {
        handleError(
          `Please login first to ${apiConfig.alamatSiteUrl} then try to reconnect wallet.`
        );
      } else if (error?.message?.includes(`Returned values aren't valid`)) {
        //If user change the network after confirm modal.
        handleError("Please switch to the right network");
      } else if (typeof error == "object") {
        handleError(error.message, error.code);
      } else handleError(error, error.code);

      if (orderResponse && orderResponse.data.id) {
        const expectedPlainTextSignature = `${
          orderResponse.data.ref_id
        }${"cancelled"}{${apiConfig.orderPostbackSecret}}`;
        const postbackSignature = CryptoJS.SHA1(expectedPlainTextSignature);

        await Services.SEND_NFT_POSTBACK({
          req_id: orderResponse.data.ref_id,
          client_id: apiConfig.orderPostbackClientId,
          status: "cancelled",
          signature: `${postbackSignature.toString()}`,
        });

        postbackDetails.refId = props.product.selections[0].sku;
        postbackDetails.status = "FAILED";
        postbackDetails.metadata = {
          userId,
          nft: JSON.stringify(_metadata),
          price: [
            {
              currency,
              amount: subtotal,
            },
          ],
          walletAddress,
          tokenId,
          transactionHash,
          contractAddress: apiConfig.contractAddress,
        };
        postbackDetails.signature = hash
          .sha256()
          .update(
            `${postbackDetails.refId}||${postbackDetails.status}|${apiConfig.alamatchClientSecret}`
          )
          .digest("hex");

        await Services.SEND_ALAMATCH_POSTBACK({
          clientId: apiConfig.alamatchClientId,
          refId: postbackDetails.refId,
          status: postbackDetails.status,
          metadata: postbackDetails.metadata,
          signature: postbackDetails.signature,
        });
      }
      setShowConfirmModal(false);
    }

    setIsLoading(false);
  };

  const handleCashCreditCheckout = async () => {
    setIsLoading(true);
    const userData = jwt_decode(Cookies.get("accesstoken"));
    let _metadata;
    let subtotal = 0;

    subtotal = parseFloat(props.product.selections[0].price);
    let platformFee = parseFloat(apiConfig.platformFee);

    let platformFeeComputed = parseFloat(subtotal * platformFee);
    platformFeeComputed = platformFeeComputed.toFixed(6);

    let totalAmountDue = parseFloat(subtotal) + parseFloat(platformFeeComputed);
    totalAmountDue = totalAmountDue.toFixed(6);

    // re-arranged due to create order dependency for fiat payment
    let ipfs = await Services.UPLOAD_IMAGE_IPFS({
      fileUri: props.productImages[0].original,
      deleteOriginalFile: false,
    });
    let tokenUri = null;

    if (ipfs) {
      _metadata = buildMetaData(ipfs);
      tokenUri = await Services.BUILD_METADATA_IPFS(_metadata);
    }

    let cart = [
      {
        name: props.product.name,
        unit_price: props.product.selections[0].price_in_php,
        amount: props.product.selections[0].price_in_php,
        discounted_price: props.product.selections[0].discounted_price,
        quantity: 1,
        gallery: props.product.gallery,
        uuid: props.product.uuid,
        store: uuid,
      },
    ];
    let items = [
      {
        uuid: props.product.uuid,
        qty: 1,
        always_available: props.product.always_available,
        product_selection: props.product.selections[0].id,
      },
    ];

    let params = {
      site: storeInfo.site,
      full_name: userData.name,
      address: "",
      mobile_number: userData.phone_number,
      amount: props.product.selections[0].price_in_php,
      other_fees: {
        cod_fee: 0,
        admin_fee: 0,
      },
      subtotal: props.product.selections[0].price_in_php,
      recipient: apiConfig.nftAlamatBuxRecipient,
      uuid,
      cart: JSON.stringify(cart),
      items,
      items_total_qty: 1,
      purchase_amount: props.product.selections[0].price_in_php,
      voucher: "",
      shipping_fee: 0,
      delivery_detail: {
        details: {},
        method: "",
      },
      remarks: {
        contractAddress: apiConfig.contractAddress,
        userId: userData.uuid,
        nftMetadata: JSON.stringify(_metadata),
        tokenUri: tokenUri.data.result.ipfs,
        sellerAdress: apiConfig.recipientWalletAddress,
        price_in_php: props.product.selections[0].price_in_php,
      },
    };

    let res = await dispatch(
      StoreActions.checkOut(params, { paymentOption: "otc" })
    );

    try {
      if (res.status === 200) {
        setIsLoading(false);
        let ref_id = res.data ? res.data.description : null;
        let access = res.data ? res.data.bux_uid : null;
        setTimeout(() => {
          if (access) {
            window.location.href = `${apiConfig.buxURL}/checkout/${access}/?redirect_url=${apiConfig.localHost}shop/${storeInfo.site}/checkout?ref_id=${ref_id}`;
          }
        });
      } else {
        setShowPaymentOptionModal(false);
        setIsLoading(false);
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again",
          })
        );
      }
    } catch (error) {
      setIsLoading(false);
      dispatch(
        showAlert({
          type: "error",
          message: "An error has occured. Please try again",
        })
      );
    }
  };

  const buildMetaData = (ipfs) => {
    let variants = props.product.selections[0].variants;
    let levelMap = {
      "Kasapi Level 1": {
        levelNum: 1,
        traitMaxValue: 17,
      },
      "Kanan Level 2": {
        levelNum: 1,
        traitMaxValue: 19,
      },
      "Kampeon Level 3": {
        levelNum: 1,
        traitMaxValue: 21,
      },
      "Bayani Level 4": {
        levelNum: 1,
        traitMaxValue: 23,
      },
      "Alamat Level 5": {
        levelNum: 1,
        traitMaxValue: 25,
      },
      Others: {
        levelNum: 1,
        traitMaxValue: 17,
      },
    };
    let cardTypeArray = [
      "Kasapi Level 1",
      "Kanan Level 2",
      "Kampeon Level 3",
      "Bayani Level 4",
      "Alamat Level 5",
    ];
    let _attributes = [];
    let cardLevel = variants.find((x) => x.group_name === "Level");
    let category = cardTypeArray[parseInt(cardLevel.name) - 1];

    _attributes.push({
      trait_type: "Card No.",
      value: props.product.selections[0].sku,
    });
    _attributes.push({
      trait_type: "Mint Date",
      value: Date.now(),
      display_type: "date",
    });

    for (let i = 0; i < variants.length; i++) {
      let maxValue = null;
      if (variants[i].group_name === "Level") {
        let cardType = cardTypeArray[parseInt(cardLevel.name) - 1];
        _attributes.push({ trait_type: "Card Type", value: cardType });
        maxValue = cardLevel.name;
      } else {
        maxValue = levelMap[category].traitMaxValue;
      }

      _attributes.push({
        trait_type: variants[i].group_name,
        value: parseInt(variants[i].name),
        display_type: "number",
        max_value: maxValue,
      });
    }

    let _metadata = {
      description: props.product.description,
      external_url: apiConfig.alamatSiteUrl,
      name: props.product.name,
      image: ipfs.data.result.ipfs,
      attributes: _attributes,
    };

    return _metadata;
  };
  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    goBack();
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const connectWalletOnPageLoad = async () => {
    if (sessionStorage?.getItem("isWalletConnected") === "true") {
      dispatch(Actions.CONNECT_WALLET());
    }
  };

  useEffect(() => {
    const variantGroups = props.product.variant_groups;
    const traitsTemplate = [];

    if (!props.product.has_variants) return;

    for (let variant of variantGroups) {
      traitsTemplate.push({
        element: variant.name,
        number: variant.variants[0].name,
      });
    }
    setTraits(traitsTemplate);
  }, [props.product.variant_groups, props.product.has_variants]);

  useEffect(() => {
    document.body.style.backgroundColor = "#051626";

    const token = Cookies.get("accesstoken");
    if (token) {
      connectWalletOnPageLoad();
    }
  }, []);

  return (
    <>
      <SuccessModal
        open={showSuccessModal}
        name={props.product ? props.product.name : ""}
        closeSuccessModal={closeSuccessModal}
      />
      <NFTConfirmModal
        image={props.productImages[0].original}
        address={walletAddress}
        title={props.product.name}
        open={showConfirmModal}
        isLoading={isLoading}
        openSuccessModal={openSuccess}
        price={
          props.product.selections &&
          props.product.selections.length > 0 &&
          props.product.has_variants
            ? props.product.selections[0].price
            : props.product.price
        }
        priceInPhp={
          props.product.selections &&
          props.product.selections.length > 0 &&
          props.product.has_variants
            ? props.product.selections[0].price_in_php
            : ""
        }
        name={props.product ? props.product.name : ""}
        closeSuccessModal={closeConfirmModal}
        setShowPaymentOptionModal={setShowPaymentOptionModal}
      />
      <SuccessModal />
      <PaymentOptionModal
        isLoading={isLoading}
        open={showPaymentOptionModal}
        setOpen={setShowPaymentOptionModal}
        openSuccessModal={openSuccess}
        handleCashCreditCheckout={handleCashCreditCheckout}
        product={props.product}
        handleCryptoCheckout={handleCryptoCheckout}
      />
      <div className="h-100 nft-container">
        <div style={{ paddingTop: "2rem" }}>
          <div className="container">
            <div className="row" style={{ paddingBottom: "5rem" }}>
              {/* LEFT SIDE */}
              <div className="col-md-3 offset-md-1 col-12 px-0">
                <div className="d-flex justify-content-center justify-content-md-end">
                  <img
                    src={props.productImages[0].original}
                    alt="img"
                    className="w-100"
                  />
                </div>
              </div>
              {/* RIGHT SIDE */}
              <div className="col-md-7 col-12">
                <div className="d-flex flex-row-reverse pt-4">
                  <button
                    type="button"
                    className="button-back"
                    onClick={() => goBack()}
                  >
                    <img src={leftArrowImg} alt="Left Arrow" className="pr-2" />
                    <img src={sunImg} alt="Sun" />
                  </button>
                </div>

                <h2>
                  {props.product ? props.product.name : "Your Product's Name"}
                </h2>
                <div>
                  <div className="d-flex align-items-center justify-content-between pt-5">
                    <div>
                      <img
                        src={usdcLogo}
                        alt="usdc logo"
                        style={{ height: "36px", marginRight: "15px" }}
                      />
                      <span
                        className="pr-5 align-middle"
                        style={{ fontSize: "1.2rem" }}
                      >
                        Fixed price:
                      </span>
                    </div>
                    <span style={{ fontSize: "1.5rem" }}>
                      {props.product.selections &&
                      props.product.selections.length > 0 &&
                      props.product.has_variants
                        ? props.product.selections[0].price
                        : props.product.price}{" "}
                      USDC
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ fontSize: "1.5rem" }}
                  >
                    {props.product.selections &&
                    props.product.selections.length > 0 &&
                    props.product.has_variants
                      ? `PHP ${props.product.selections[0].price_in_php}`
                      : ""}{" "}
                  </div>
                </div>

                <div className="col col-md-5 col-lg-4 mx-auto pt-5 pb-5">
                  <button
                    className="nft-button-cut-corner"
                    onClick={() => buyNow()}
                  >
                    BUY NOW
                  </button>
                </div>

                {/* STATS */}
                <div className="px-1 px-md-0">
                  <div className="nft-border-lightdiamond-container">
                    <div className="nft-border-lightdiamond">
                      <div className="traits-container px-lg-4">
                        {traitsImage && traitsImage.length >= 0
                          ? traitsImage.map((item, index) => {
                              return (
                                <div className="py-3" key={index}>
                                  <div className="traits-box">
                                    <img
                                      src={item.image}
                                      alt="strength"
                                      className="w-100"
                                    />
                                    <span className="traits-number">
                                      {traits && traits.length >= 0
                                        ? traits
                                            .filter(
                                              (trait) =>
                                                trait.element.toLowerCase() ===
                                                item.name.toLowerCase()
                                            )
                                            .map((trait, label) => {
                                              return trait.number;
                                            })
                                        : ""}
                                    </span>
                                  </div>
                                  <span>{item.name}</span>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                {/* STATS END */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTDetails;
