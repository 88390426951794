import {
  OrderActionTypes,
  ReduxInitializeFormActionTypes
} from "./orderActions";
const initialState = {
  loading: false,
  orders: {
    loading: false,
    orders: {
      length: 0,
      list: []
    },
    filters: {
      date_ordered_type: "",
      branch: "",
      payment_status: "all",
      delivery_status: ["all"],
      order: "desc",
      orderBy: "ref_id",
      search: "",
      page: 0,
      rowsPerPage: 10,
      desktop: true,
      startDate: null,
      endDate: null,
      deliveryDate: null
    }
  },
  selectedOrder: null,
  shippingFee: "0",
  shipped: "null",
  message: null,
  pickup_addresses: []
};
export default function orderReducers(state = initialState, action) {
  switch (action.type) {
    case OrderActionTypes.ORDER_LOADING:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true
        }
      };
    case OrderActionTypes.ORDER_LOADING_DONE:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false
        }
      };
    case OrderActionTypes.ORDER_GET:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          orders: action.payload
        }
      };
    case OrderActionTypes.ORDER_SET_ORDER_DETAIL:
      return {
        ...state,
        selectedOrder: action.payload
      };
    case OrderActionTypes.ORDER_BOOK_SHIPPING:
      return {
        ...state,
        shipped: action.payload
      };
    case OrderActionTypes.ORDER_CALCULATE_SHIPPING:
      return {
        ...state,
        shippingFee: action.payload
      };

    case OrderActionTypes.SET_ORDER_MESSAGE:
      return {
        ...state,
        message: action.payload
      };
    case OrderActionTypes.OPEN_ALERT:
      return {
        ...state,
        dialogOpen: true
      };
    case OrderActionTypes.CLOSE_ALERT:
      return {
        ...state,
        dialogOpen: false
      };
    case OrderActionTypes.SOURCE:
      return {
        ...state,
        source: action.payload
      };
    case OrderActionTypes.SET_ORDER_FILTERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          filters: {
            ...state.orders.filters,
            ...action.payload
          }
        }
      };
    case OrderActionTypes.GET_PICKUP_ADDRESS:
      return {
        ...state,
        pickup_addresses: action.payload
      };

    default:
      return state;
  }
}
