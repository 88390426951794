import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { createPreview } from "../../components/banner/previewBannerTemplate";
import buxLogo from "../../../../../assets/images/buxLOGO.png";
import OTCchannelLogo from "../../components/OTCchannels";
import moment from "moment";
import { Button } from "@material-ui/core";
import history from "../../../../../store/history";

export default function NFTCheckout(props) {
  let logo = props.storeInfo.logo_image;
  let color = props.storeInfo && props.storeInfo.color;
  const paymentOption = props.checkoutItem
    ? props.checkoutItem.payment_option
    : "";
  const useStyles = makeStyles((theme) => ({
    logo: {
      width: 130,
      height: 60,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage: `url(${
        props.storeInfo.logo_image
          ? typeof logo === "object"
            ? createPreview(logo)
            : logo
          : "#f97663"
      })`,
      backgroundColor:
        props.storeInfo && !props.storeInfo.logo_image
          ? color && color.primary.contrastText
          : "inherit",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
    otcLogo: {
      width: "auto",
      height: 50,
    },
    text: {
      fontSize: 16,
      fontWeight: "normal",
      color: "rgba(43,45,50, 0.8)",
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <div className="p-3 d-flex justify-content-center nft-header">
        <div className={classes.logo} />
      </div>
      <div style={{ padding: "8vh 30vw" }}>
        <div className="border py-5 px-4">
          <div className="d-flex justify-content-between align-items-center">
            <img src={buxLogo} style={{ width: "auto", height: 35 }} />
            {paymentOption === "otc" ? (
              <img
                src={OTCchannelLogo(props.checkoutItem.channel)}
                className={classes.otcLogo}
                alt="channelLogo"
              />
            ) : (
              ""
            )}
          </div>
          <div className="pt-5">
            <h5 className="font-weight-bold">Payment Instructions</h5>
            <div className={classes.text} style={{ marginTop: "3vh" }}>
              {props.checkoutItem.bux_uid ? (
                <p>
                  Check your email and follow the{" "}
                  <strong>Bux Payment instructions</strong>
                  {` `}to proceed with the payment. Make sure that the
                  transaction number is correct.
                </p>
              ) : (
                <p>
                  Check your email and follow the{" "}
                  <strong>Payment instructions</strong> to proceed with the
                  payment. Make sure that the transaction number is correct.
                </p>
              )}
            </div>
            <div>
              {paymentOption === "otc" ? (
                <div className="d-flex justify-content-center">
                  <div>
                    <img
                      src={props.checkoutItem.ref_img_url}
                      style={{ height: 120, width: "auto" }}
                      alt="refImg"
                    />
                    <div className="pt-3" style={{ textAlign: "center" }}>
                      {props.checkoutItem.ref_code}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <div
                className="px-5 mt-4 py-2 font-weight-bold"
                style={{ backgroundColor: "rgb(230, 234, 240)" }}
              >
                Payment Details
              </div>
              <div className="d-flex justify-content-between px-5 pt-4">
                Reference number <strong>{props.checkoutItem.ref_code}</strong>
              </div>
              <div className="d-flex justify-content-between px-5 pt-4">
                Merchant name <strong>{props.storeInfo.name}</strong>
              </div>
              <div className="d-flex justify-content-between px-5 pt-4">
                Transaction date{" "}
                <strong>
                  {moment(props.checkoutItem.created_on).format(
                    `MMM DD, YYYY hh:mm a`
                  )}
                </strong>
              </div>
              <div className="d-flex justify-content-between px-5 pt-4">
                Expiry{" "}
                <strong>
                  {moment(props.checkoutItem.expiry).format(
                    `MMM DD, YYYY hh:mm a`
                  )}
                </strong>
              </div>
            </div>
            <div className="py-4 mt-5 border-top border-bottom">
              <div className="font-weight-bold px-5">Note</div>
              <div className="mt-4 px-5">
                Transaction No: {props.checkoutItem.ref_id}
              </div>
            </div>
            <div className="mt-4 pr-5 d-flex justify-content-end">
              Amount to pay:{" "}
              <strong className="pl-3"> PHP {props.checkoutItem.amount}</strong>
            </div>
          </div>
        </div>
      </div>
      <div
        className="p-5 d-flex justify-content-center"
        style={{ backgroundColor: "rgb(230, 234, 240)" }}
      >
        <Button
          onClick={() => history.push(`/shop/${props.storeInfo.site}`)}
          disableElevation
          variant="contained"
          style={{
            color: "#fff",
            backgroundColor: "#0F3344",
            borderRadius: 50,
          }}
        >
          Go back to{" "}
          <span className="pl-1" style={{ textTransform: "capitalize" }}>
            {props.storeInfo.name}
          </span>
        </Button>
      </div>
    </div>
  );
}
