import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  makeStyles
} from "@material-ui/core";
import { CheckCircle, Close } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  checkIcon: {
    textAlign: "center",
    width: "100%",
    color: "#38b99f",
    fontSize: 100
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    color: "#002c42"
  },
  site: {
    fontSize: 14,
    color: "#54575d"
  }
}));

const Modal = props => {
  const classes = useStyles();
  return (
    <Dialog fullWidth maxWidth="xs" open={props.open.success_dialog}>
      <DialogTitle
        style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <IconButton
          onClick={() =>
            props.setOpen({ ...props.open, success_dialog: false })
          }
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "5px 20px 20px" }}>
        {props.open.is_success ? (
          <>
            <CheckCircle className={classes.checkIcon} />
            <div style={{ textAlign: "center" }}>
              <div className={classes.name}>{props.store.name}</div>
              <div className={classes.site}>{props.store.site}.sentro.ph</div>
              <div
                style={{ marginTop: 30, padding: "0 15px" }}
                className={classes.site}
              >
                You have successfully customized your shop style
              </div>
            </div>
          </>
        ) : (
          <div
            style={{ padding: "0 15px", textAlign: "center" }}
            className={classes.site}
          >
            Oops, something went wrong. Please try again.
          </div>
        )}
        <div style={{ marginTop: 40 }}>
          <Button
            disableElevation
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              window.scrollTo(0, 0);
              props.setOpen({ ...props.open, success_dialog: false });
            }}
          >
            OK
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
