import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { ThemeProvider } from "@material-ui/styles";
import { useSelector } from 'react-redux'
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

import Layout from '../components/layout';
import routes from './routes';
import '../styles/router.css';

const Router = () => {
  let theme = useSelector(state => state.enterprise.main.theme)
  theme = createMuiTheme(theme)
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={'zoom-fadein'}
                  timeout={150}
                >
                  <Switch location={location}>
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={props => {
                          return (
                            <div className="zoom-fadein" id="transition-div">
                              <Suspense fallback={null}>
                                <Component {...props}/>
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ThemeProvider>
  )
}

// const mapStateToProps = ({ }) => ({ })
// export default connect(mapStateToProps)(Router)
export default Router;
