import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  TablePagination,
  TableBody,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Fade,
} from "@material-ui/core";
import {
  ArrowDropUp,
  MoreHoriz,
  ErrorOutlineRounded,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "./orderStyles";
import { getDeliveryStatus } from "./orderStatuses";
import OrderActions from "./orderActions";
import icon from "../../assets/images/orderIcon.svg";
import { useHistory } from "react-router-dom";
import Path from "../../Paths";
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onSortRequest } = props;
  const createSortHandler = (property) => (event) => {
    onSortRequest(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headcell) => (
          <TableCell
            className={
              headcell.id === "actions" ? classes.sticky : classes.tableHead
            }
            key={headcell.id}
            align={headcell.align}
            sortDirection={orderBy === headcell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headcell.id}
              direction={orderBy === headcell.id}
              onClick={createSortHandler(headcell.id)}
              hideSortIcon={true}
              active={false}
            >
              <span>{headcell.label}</span>
              {headcell.has_sort ? (
                <span className={classes.headIconWrapper}>
                  <ArrowDropUp className={classes.headIconUp} />
                  <ArrowDropUp className={classes.headIconDown} />
                </span>
              ) : (
                ""
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function MenuOptions(props) {
  const history = useHistory();
  const handleBookDelivery = () => {
    if (props.row.delivery_detail.method === "mrspeedy") {
      history.push(Path.ORDER_DELIVERY_REQUEST);
    } else if (props.row.delivery_detail.method === "ninjavan") {
      history.push(Path.NINJAVAN_DELIVERY);
    }
  };
  return (
    <Menu
      anchorEl={props.menuAnchor}
      keepMounted
      open={props.open}
      onClose={props.onClose}
    >
      <MenuItem
        disabled={
          props.row.payment_option === "otc" ||
          props.row.channel === null ||
          props.row.is_cancelled ||
          props.row.paid
        }
        onClick={() => {
          props.markAsPaid(props.row.id);
          props.onClose();
        }}
      >
        Mark as paid
      </MenuItem>
      <MenuItem
        disabled={
          !props.row.delivery_detail ||
          props.row.delivery_detail.method === "flat_rate" ||
          props.row.delivery_detail.method === "pickup" ||
          !props.row.paid ||
          props.row.mrspeedy_book_datetime ||
          props.row.ninjavan_details
        }
        onClick={handleBookDelivery}
      >
        Book for delivery
      </MenuItem>
      <MenuItem
        disabled={props.row.is_cancelled}
        onClick={() => {
          props.cancelOrder(props.row);
          props.onClose();
        }}
      >
        Cancel order
      </MenuItem>
      <MenuItem>Download airwaybill</MenuItem>
    </Menu>
  );
}

export default function OrderTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order.orders.orders);
  const filters = useSelector((state) => state.order.orders.filters);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [targetRow, setTargetRow] = React.useState(null);
  const [page, setPage] = React.useState(filters.page);
  const [rowsPerPage, setRowsPerPage] = React.useState(filters.rowsPerPage);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    dispatch(
      OrderActions.setOrderFilters({
        order: newOrder,
        orderBy: property,
        page,
        rowsPerPage,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(OrderActions.setOrderFilters({ page: newPage }));
  };

  const setMenu = (event, targetRow) => {
    setMenuAnchor(event.target);
    setTargetRow(targetRow);
  };

  const handleViewOrder = (order) => {
    history.push(`/orders/details?id=${order}`);
  };

  const markAsPaid = (order) => {
    dispatch(OrderActions.setOrderDetails(order, "DETAILS"));
  };

  const cancelOrder = (order) => {
    dispatch(OrderActions.cancelOrder(order, filters));
  };

  const getCourier = (item) => {
    if (item.delivery_detail) {
      if (item.delivery_detail.method === "pick_up") {
        return "Own pickup";
      } else if (item.delivery_detail.method === "flat_rate") {
        return "Flat rate";
      } else if (item.delivery_detail.method === "mrspeedy") {
        return "Borzo";
      } else if (item.delivery_detail.method === "ninjavan") {
        return "NinjaVan";
      } else {
        return "--";
      }
    } else {
      return "--";
    }
  };

  const headCells = [
    { id: "ref_id", label: "Ref. No.", align: "left", has_sort: true },
    { id: "name", label: "Name", align: "left", has_sort: false },
    { id: "amount", label: "Amount", align: "right", has_sort: false },
    {
      id: "payment_status",
      label: "Status",
      align: "left",
      has_sort: true,
    },
    {
      id: "payment_channel",
      label: "Payment channel",
      align: "left",
      has_sort: false,
    },
    {
      id: "paid_date",
      label: "Payment date",
      align: "left",
      has_sort: true,
    },
    { id: "courier", label: "Courier", align: "left", has_sort: false },
    {
      id: "delivery_status",
      label: "Delivery status",
      align: "left",
      has_sort: true,
    },
    {
      id: "actions",
      label: <ErrorOutlineRounded style={{ color: "#54575f" }} />,
      align: "center",
      has_sort: false,
    },
  ];

  const otherHeadCells = [
    { id: "ref_id", label: "Ref. No.", align: "left", has_sort: true },
    { id: "branch", label: "Branch", align: "left", has_sort: false },
    { id: "amount", label: "Amount", align: "right", has_sort: false },
    {
      id: "payment_status",
      label: "Status",
      align: "left",
      has_sort: true,
    },
    {
      id: "payment_channel",
      label: "Payment channel",
      align: "left",
      has_sort: false,
    },
    {
      id: "paid_date",
      label: "Payment date",
      align: "left",
      has_sort: true,
    },
    {
      id: "shipment",
      label: "Shipment",
      align: "left",
      has_sort: true,
    },
    {
      id: "delivery_date",
      label: "Delivery Date",
      align: "left",
      has_sort: true,
    },
    {
      id: "actions",
      label: <ErrorOutlineRounded style={{ color: "#54575f" }} />,
      align: "center",
      has_sort: false,
    },
  ];

  return (
    <>
      <TableContainer
        style={{
          width: "100%",
          overflow: orders && orders.list.length ? "auto" : "hidden",
        }}
      >
        <Table stickyHeader>
          <EnhancedTableHead
            headCells={props.show_other_filters ? otherHeadCells : headCells}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onSortRequest={handleRequestSort}
          />
          <TableBody>
            {orders.list.map((row, index) => (
              <TableRow
                id="orders_list"
                key={index}
                hover
                tabIndex={-1}
                className={classes.bodyRow}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ marginLeft: "1em", cursor: "pointer" }}
                  onClick={() => handleViewOrder(row.id)}
                >
                  <div
                    className="font-weight-bold"
                    style={{ color: "#f97663" }}
                  >
                    {row.ref_id}
                  </div>
                </TableCell>
                <TableCell align="left" style={{ textTransform: "capitalize" }}>
                  {props.show_other_filters
                    ? row.delivery_detail
                      ? row.delivery_detail.details.city
                      : "--"
                    : row.full_name}
                </TableCell>
                <TableCell align="right">₱ {row.amount}</TableCell>
                <TableCell align="left" className={classes.paymentStatus}>
                  <Chip
                    size="small"
                    label={row.is_cancelled ? "Cancelled" : row.payment_status}
                    style={{ padding: "0 5px" }}
                    className={
                      row.is_cancelled
                        ? "cancelled"
                        : row.payment_status.toLowerCase().split(" ").join("_")
                    }
                  />
                </TableCell>
                <TableCell align="left" style={{ textTransform: "capitalize" }}>
                  {row.channel || "--"}
                </TableCell>
                <TableCell align="left">
                  {row.paid_date
                    ? moment(row.paid_date).format("D MMM YYYY")
                    : "--"}
                </TableCell>
                <TableCell align="left">{getCourier(row)}</TableCell>
                <TableCell align="left">
                  {props.show_other_filters ? (
                    row.delivery_detail ? (
                      moment(row.delivery_detail.details.delivery_date)
                        .utcOffset("-08:00")
                        .format("MMM D YYYY")
                    ) : (
                      "--"
                    )
                  ) : getDeliveryStatus(row) === "--" ? (
                    "--"
                  ) : (
                    <Chip
                      size="small"
                      label={getDeliveryStatus(row)}
                      style={{ padding: "0 5px" }}
                      className={
                        row.is_cancelled
                          ? "cancelled"
                          : row.payment_status
                              .toLowerCase()
                              .split(" ")
                              .join("_")
                      }
                    />
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  className={`${classes.sticky} bg-white border-left`}
                >
                  <IconButton
                    style={{ padding: 5 }}
                    onClick={(e) => setMenu(e, row)}
                  >
                    <MoreHoriz style={{ color: "#54575f" }} />
                  </IconButton>
                  <MenuOptions
                    row={row}
                    open={targetRow ? targetRow.id === row.id : false}
                    menuAnchor={menuAnchor}
                    markAsPaid={markAsPaid}
                    cancelOrder={cancelOrder}
                    onClose={() => {
                      setMenuAnchor(null);
                      setTargetRow(null);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {orders && orders.list.length ? (
        <div
          id="pagination"
          className="d-flex align-items-center justify-content-between"
        >
          <div style={{ color: "#54575f", fontSize: 13 }}>
            {orders.length} item(s) found
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={orders.length}
            rowsPerPage={filters.rowsPerPage}
            page={filters.page}
            onChangePage={handleChangePage}
          />
        </div>
      ) : (
        ""
      )}

      {orders && orders.list.length ? (
        ""
      ) : (
        <div
          id="empty_state"
          className="d-flex align-items-center justify-content-center text-center bg-white"
          style={{ minHeight: 350, padding: "0 24vw" }}
        >
          <div>
            <img
              src={icon}
              alt="icon"
              width="auto"
              height="70"
              className="mb-4"
            />
            <div style={{ fontWeight: 600 }}>No orders to display</div>
            <div className="my-3" style={{ color: "#909196", fontSize: 14 }}>
              When a customer places an order, their details will appear here.
            </div>
          </div>
        </div>
      )}
    </>
  );
}
