import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Button, FormHelperText, InputAdornment } from "@material-ui/core";
import GoogleAddressField from '../common/googleAddressForm';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    "& p": {
      textAlign: "right"
    }
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)",
    margin: 0
  },
}));

const ReduxTextField = ({
  input,
  label,
  maxLength,
  minLength,
  multiline,
  cut,
  noHelpText,
  meta: { asyncValidating, touched, error },
  ...custom
}) => {
  const classes = useStyles();
  return (
    <div className="w-100">
      <div className={classes.label3}>{label}</div>
      <GoogleAddressField
        id={input.name}
        color="secondary"
        style={{ marginTop: 0 }}
        InputLabelProps={{
          shrink: true
        }}
        classes={{
          root: classes.textFieldRoot
        }}
        helperText={
          <span> {touched && error ? 
            error : custom.inputProps ? 
              custom.inputProps.maxlength ? 
                `${input.value ? input.value.length: "0"}/${custom.inputProps.maxlength}`
              : ""
            : ""
          }</span>
        }
        variant="outlined"
        margin="dense"
        multiline={multiline}
        rows={7}
        size={"small"}
        fullWidth
        error={Boolean(touched && error)}
        {...input}
        {...custom}
        initialValue={input.value.address}
      />
    </div>
  );
};

export default ReduxTextField;
