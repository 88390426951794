import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  SwipeableDrawer,
  Chip,
  Link,
  IconButton,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Actions from "../../redux/actions";
import imgPlaceholder from "../../../../assets/images/imgPlaceholder2.svg";
import PriceDisplay from "./priceDisplay";
import {
  Add,
  RemoveCircle,
  AddCircle,
  KeyboardArrowDown,
} from "@material-ui/icons";
import VariantPicker from "./variantPicker";
import Swal from "sweetalert2";
import "./swal.css";
import Freebie from "./freebie";

export function productImage(product) {
  let item = product;
  if (item) {
    if (item.gallery) {
      if (item.gallery.length) {
        if (item.photo_primary === null || item.photo_primary === "") {
          return item.gallery[0].image;
        } else {
          if (
            item.gallery[item.photo_primary] === "undefined" ||
            item.gallery[item.photo_primary] === undefined
          ) {
            return item.gallery[0].image;
          } else {
            return item.gallery[item.photo_primary].image;
          }
        }
      } else {
        return imgPlaceholder;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return imgPlaceholder;
      }
    }
  } else {
    return imgPlaceholder;
  }
}

export default function ProductDetailsDrawer(props) {
  const useStyles = makeStyles((theme) => ({
    drawer: {
      borderRadius: "inherit",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "8px 8px 0 0",
        width: "auto",
        maxHeight: "95vh",
        overflowY: "auto",
      },
      width: 400,
    },
    swal: {
      zIndex: 100000,
    },
    fadeout: {
      width: "100%",
      position: "absolute",
      bottom: -2,
      left: 0,
      right: 0,
      height: 50,
      margin: 0,
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 70.27%)`,
    },
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.quickOrder.selectedProduct);
  const items = useSelector((state) => state.quickOrder.cart.items);
  const store = useSelector((state) => state.store.store);
  const [productSelection, setProductSelection] = React.useState("");
  const [quantity, setQuantity] = React.useState(1);
  const [error, setError] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [showDescription, setShowDescription] = React.useState(false);
  const [special_instructions, set_special_instructions] = React.useState({
    open: false,
    value: "",
  });
  const [freebie, set_freebie] = React.useState([]);

  const handleClose = () => {
    setQuantity(1);
    setError("");
    setProductSelection("");
    set_special_instructions({ open: false, value: "" });
    dispatch(Actions.setSelectedProduct(""));
  };

  const handleAddToCart = () => {
    let params = {
      ...product,
      quantity,
      stock: getStocks(),
      variants: productSelection ? productSelection.variants : [],
      productSelection,
      special_instructions: special_instructions.value,
    };

    // ADD TO CART ANIMATION
    let imgtodrag = document.getElementById("product-details");
    let viewcart = document.getElementById("shopping-cart");
    let imgtodragImage = imgtodrag.querySelector(".product-image");

    let disLeft = imgtodrag.getBoundingClientRect().left;
    let disTop = imgtodrag.getBoundingClientRect().top;
    let cartleft = viewcart.getBoundingClientRect().left;
    let carttop = viewcart.getBoundingClientRect().top;
    let image = imgtodragImage.cloneNode(true);

    image.style =
      "z-index: 9999; width: 100px; height: auto; opacity: 0.8; position:fixed; top:" +
      disTop +
      "px;left:" +
      disLeft +
      "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";
    var rechange = document.body.appendChild(image);
    setTimeout(function () {
      image.style.left = cartleft + "px";
      image.style.top = carttop + "px";
      image.style.width = "40px";
      image.style.opacity = "0";
    }, 200);
    setTimeout(function () {
      rechange.parentNode.removeChild(rechange);
    }, 2000);
    Swal.fire({
      text: "Added to cart!",
      icon: "success",
      showConfirmButton: false,
      timer: 1600,
      width: 280,
      zIndex: 100000,
    });
    //ADD TO CART
    setTimeout(() => {
      if (props.onSubmit) {
        props.onSubmit(params);
        if (freebie.length) {
          for (let i in freebie) {
            dispatch(
              Actions.setFreebie({
                ...freebie[i],
                is_requirement_applied:
                  quantity < parseInt(freebie[i].minimum_purchase_mount)
                    ? false
                    : true,
              })
            );
          }
        }
        handleClose();
      }
    }, 1400);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const getStocks = () => {
    if (product?.has_variants) {
      let array =
        props.cart.length && props.cart.filter((i) => i.uuid === product?.uuid);
      if (productSelection && array.length) {
        let item = array.filter(
          (i) => i.productSelection.id === productSelection.id
        );
        if (item.length) {
          return productSelection
            ? productSelection.quantity - parseInt(item[0].quantity)
            : -2;
        } else {
          return productSelection ? productSelection.quantity : -2;
        }
      } else {
        return productSelection ? productSelection.quantity : -2;
      }
    } else {
      if (product?.always_available === true) {
        return -1;
      } else {
        return product?.quantity;
      }
    }
  };

  const ref = React.useRef();

  const onScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    if (scrollTop > 0) {
      setShowDescription(true);
    } else {
      setShowDescription(false);
    }
  };

  const productSelectionOnChange = (val) => {
    setProductSelection(val);
  };

  const should_disable_quantity_field = product?.has_variants
    ? !productSelection
    : false;

  const disabled_add_to_cart_button =
    Boolean(error) ||
    Boolean(product?.has_variants ? !productSelection : false) ||
    getStocks() === 0;

  React.useEffect(() => {
    let errorMessage = "";
    const stocks = getStocks();
    if ([-2, -1, 0].includes(stocks)) {
      errorMessage = "";
    } else {
      const insufficient_stock = stocks < quantity;
      errorMessage = insufficient_stock ? `Maximum of ${stocks} item(s)` : "";
    }
    setError(errorMessage);
  }, [quantity, productSelection]);

  React.useEffect(() => {
    if (props.cart.length) {
      for (let i in props.cart) {
        if (props.cart[i].uuid === product?.uuid) {
          if (product.has_variants) {
            if (props.cart[i].productSelection && productSelection) {
              if (props.cart[i].productSelection.id === productSelection.id) {
                if (props.cart[i].quantity >= productSelection.quantity) {
                  setError("Out of stock");
                }
              } else {
                if (!productSelection.quantity) {
                  setError("Out of stock");
                }
              }
            }
          } else {
            if (
              props.cart[i].quantity >= product?.quantity &&
              !product?.always_available
            ) {
              setError("Out of stock");
            }
          }
        } else {
          if (product?.has_variants && productSelection) {
            if (!productSelection.quantity) {
              setError("Out of stock");
            }
          } else if (
            product?.has_variants === false &&
            !product?.always_available &&
            !product?.quantity
          ) {
            setError("Out of stock");
          }
        }
      }
    } else {
      if (productSelection) {
        if (!productSelection.quantity) {
          setError("Out of stock");
        }
      } else {
        if (
          product?.has_variants === false &&
          !product?.always_available &&
          !product?.quantity
        ) {
          setError("Out of stock");
        }
      }
    }
  }, [product, productSelection]);

  React.useEffect(() => {
    if (items.length) {
      let array = items.filter((i) => i.uuid === product.uuid);
      if (product.has_variants && productSelection) {
        let item = array.filter(
          (i) => i.productSelection.id === productSelection.id
        );
        if (item.length) {
          if (item[0].special_instructions) {
            set_special_instructions({
              open: true,
              value: item[0].special_instructions,
            });
          }
        } else {
          set_special_instructions({
            open: false,
            value: "",
          });
        }
      } else if (!product.has_variants) {
        if (array.length) {
          if (array[0].special_instructions) {
            set_special_instructions({
              open: true,
              value: array[0].special_instructions,
            });
          }
        } else {
          set_special_instructions({
            open: false,
            value: "",
          });
        }
      }
    }
  }, [product, productSelection]);

  console.log(freebie);

  const action_buttons = (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center col-6 p-0">
        <IconButton
          className="p-0"
          disabled={quantity < 1 || should_disable_quantity_field}
          onClick={() => setQuantity(quantity === 1 ? 1 : quantity - 1)}
        >
          <RemoveCircle
            style={{
              fontSize: 40,
              color:
                quantity < 1 || should_disable_quantity_field
                  ? "#d4d5d8"
                  : "#ff564d",
            }}
          />
        </IconButton>
        <div className="px-4" style={{ fontSize: 16 }}>
          {quantity}
        </div>
        <IconButton
          className="p-0"
          disabled={error ? true : false || should_disable_quantity_field}
          onClick={() => setQuantity(quantity + 1)}
        >
          <AddCircle
            style={{
              fontSize: 40,
              color:
                error || should_disable_quantity_field ? "#d4d5d8" : "#ff564d",
            }}
          />
        </IconButton>
      </div>
      <div className="col-6 pr-0">
        <Button
          disableElevation
          fullWidth
          variant="contained"
          size="small"
          style={{
            height: 40,
            backgroundColor: disabled_add_to_cart_button
              ? "#d4d5d8"
              : "#ff564d",
            color: "#fff",
          }}
          disabled={disabled_add_to_cart_button}
          onClick={handleAddToCart}
        >
          Add to cart
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor={isMobile ? "bottom" : "right"}
        open={product ? true : false}
        onOpen={toggleDrawer(true)}
        onClose={handleClose}
        ModalProps={{ keepMounted: true }}
        classes={{ paper: classes.drawer }}
      >
        <div id="product-details">
          <div
            className="d-flex justify-content-center"
            style={{
              width: "100%",
              height: 180,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)`,
            }}
          >
            <img
              src={productImage(product)}
              width="100%"
              height="200"
              style={{ objectFit: "cover", zIndex: -1 }}
              className="product-image"
            />
            {isMobile ? (
              <KeyboardArrowDown
                onClick={handleClose}
                className="position-absolute"
                style={{ color: "#fff", fontSize: 24 }}
              />
            ) : (
              ""
            )}
          </div>

          <div className="bg-white p-4">
            <div className="d-flex align-items-center justify-content-between">
              <div style={{ fontSize: 16, fontWeight: 600, color: "#2b2d32" }}>
                {product?.name}
              </div>
              {product && (
                <Chip
                  label={
                    <PriceDisplay
                      item={product}
                      productSelection={productSelection}
                    />
                  }
                  style={{
                    backgroundColor: "#f0f0f0",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                />
              )}
            </div>
            <div
              className="my-3"
              style={{
                height: isMobile ? "12vh" : "auto",
                overflowY: "auto",
              }}
            >
              <div
                className="my-3"
                style={{
                  fontSize: 12,
                }}
              >
                {product?.description}
              </div>
              {!showDescription ? (
                <div
                  className={`${
                    showDescription || !isMobile ? "" : classes.fadeout
                  }`}
                ></div>
              ) : (
                ""
              )}
            </div>
            <div className="my-3">
              <VariantPicker
                product={product}
                product_selection_value={productSelection}
                onSelectionChange={productSelectionOnChange}
              />
            </div>
            <div className="my-3">
              <Freebie
                store={store}
                product={product}
                freebie={freebie}
                set_freebie={set_freebie}
                product_quantity={quantity}
              />
            </div>
            {!special_instructions.open ? (
              <Link
                color="primary"
                className="d-flex align-items-center"
                style={{ fontSize: 12, fontWeight: 500 }}
                onClick={() =>
                  set_special_instructions({
                    ...special_instructions,
                    open: true,
                  })
                }
              >
                <Add
                  color="primary"
                  style={{ fontSize: 20, paddingRight: 5 }}
                />{" "}
                Add special instructions
              </Link>
            ) : (
              ""
            )}

            {special_instructions.open ? (
              <div>
                <div
                  style={{ fontSize: 14, fontWeight: 500, color: "#2b2d32" }}
                >
                  Special instructions
                </div>
                <div style={{ fontSize: 12 }}>
                  Example: remove nuts, less sugar, etc.
                </div>
                <TextField
                  variant="outlined"
                  color="secondary"
                  multiline
                  fullWidth
                  rows="4"
                  margin="dense"
                  className="mt-0"
                  inputProps={{ maxlength: 30 }}
                  helperText={
                    <div className="d-flex justify-content-end">
                      {special_instructions.value.length}/30
                    </div>
                  }
                  value={special_instructions.value}
                  onChange={(e) =>
                    set_special_instructions({
                      ...special_instructions,
                      value: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              ""
            )}

            <div className="mt-4">{action_buttons}</div>
            <div
              className="mt-2 p-0 col-5 text-center"
              style={{ fontSize: 12, color: "red" }}
            >
              {error}
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
