import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import imgPlaceholder from "../../../../assets/images/imgPlaceholder2.svg";
import { useDispatch, useSelector } from "react-redux";
import StoreActions from "../../../common/template/components/StoreActions";
import moment from "moment";
import { getProduct } from "../../../websiteBuilder/websiteBuilderActions";
import axios from "axios";
import apiConfig from "../../../../common/apiConfig";
import { productImage } from "./productDetails";
import { Check } from "@material-ui/icons";

function getFreeItem(purchaseCount, buyCount, freeCount) {
  // default paramters (10 items for 1 free)
  let rate = Math.floor(purchaseCount / buyCount);
  return rate * freeCount;
}

export default function Freebie(props) {
  const { store, product } = props;
  const useStyles = makeStyles((theme) => ({
    name: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: 14,
      fontWeight: 700,
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const store_uuid = store ? store.uuid : "";
  const [vouchers, setVouchers] = React.useState([]);
  const [free_products, set_free_products] = React.useState([]);
  const [freebies, setFreebies] = React.useState([]);

  async function getProduct(product_uuid) {
    try {
      let res = await axios.get(
        `${apiConfig.apiURL}/api/dashboard/product/${product_uuid}`
      );
      if (res.status === 200) {
        return res.data;
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    if (store_uuid && product) {
      async function fetchVouchers() {
        let response = await dispatch(StoreActions.getVouchers(store_uuid));
        if (response.length) {
          setVouchers(
            response.filter(
              (i) =>
                i.is_active === true &&
                i.voucher_type === "buy_x_get_y" &&
                i.minimum_x_product === product.uuid
            )
          );
        }
      }
      fetchVouchers();
    } else {
      set_free_products([]);
    }
  }, [product]);

  React.useEffect(() => {
    let array = [];
    async function fetchProducts() {
      for (let i in vouchers) {
        if (
          moment(vouchers[i].start_datetime).format("X") <=
            moment().format("X") &&
          moment().format("X") <= moment(vouchers[i].end_datetime).format("X")
        ) {
          array.push({
            ...vouchers[i],
            freebie: await getProduct(vouchers[i].free_product),
            freebie_quantity:
              getFreeItem(
                props.product_quantity,
                parseInt(vouchers[i].minimum_purchase_amount),
                vouchers[i].free_quantity
              ) || 1,
          });
        }
      }
      setFreebies(array);
      props.set_freebie(array);
    }
    if (vouchers.length) {
      fetchProducts();
    }
  }, [vouchers]);

  return (
    <>
      {freebies.length
        ? freebies.map((item, key) => (
            <div
              className="p-3 mb-2 bg-white d-flex justify-content-between align-items-center"
              style={{
                opacity:
                  props.product_quantity <
                  parseInt(item.minimum_purchase_amount)
                    ? 0.5
                    : 1,
                border: `1px solid ${
                  props.product_quantity <
                  parseInt(item.minimum_purchase_amount)
                    ? "#d1d5dd"
                    : "#f97663"
                }`,
                borderRadius: 4,
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
              }}
            >
              <div>
                <Chip
                  size="small"
                  label="FREE"
                  icon={
                    props.product_quantity <
                    parseInt(item.minimum_purchase_amount) ? (
                      false
                    ) : (
                      <Check style={{ color: "#fff" }} />
                    )
                  }
                  style={{
                    backgroundColor:
                      props.product_quantity <
                      parseInt(item.minimum_purchase_amount)
                        ? "#9A9EA9"
                        : "#f97663",
                    color: "#fff",
                  }}
                />
              </div>
              <div style={{ width: "45%" }}>
                <div className={classes.name}>{item.freebie.name}</div>
                <div style={{ fontSize: 10 }}>
                  For every {parseInt(item.minimum_purchase_amount)}, get{" "}
                  {item.free_quantity}
                </div>
              </div>
              <div style={{ fontSize: 14, fontWeight: 700 }}>
                x{" "}
                {getFreeItem(
                  props.product_quantity,
                  parseInt(item.minimum_purchase_amount),
                  item.free_quantity
                ) || 1}
              </div>
              <div>
                <img
                  src={productImage(item.freebie)}
                  width="40"
                  height="40"
                  style={{ objectFit: "cover" }}
                  className="product-image"
                />
              </div>
            </div>
          ))
        : ""}
    </>
  );
}

export const FreebieCartPage = (props) => {
  let freebie = props.freebie;
  let product = props.product;

  React.useEffect(() => {
    console.log(
      product.quantity < parseInt(freebie.minimum_purchase_amount)
        ? false
        : true
    );
    let item = {
      ...freebie,
      is_requirement_applied:
        product.quantity < parseInt(freebie.minimum_purchase_amount)
          ? false
          : true,
      freebie_quantity:
        getFreeItem(
          product.quantity,
          parseInt(freebie.minimum_purchase_amount),
          freebie.free_quantity
        ) || 1,
    };
    props.freebieQuantity(item);
  }, [product.quantity]);

  return (
    <>
      <div
        className="d-flex justify-content-end"
        style={{
          opacity:
            product.quantity < parseInt(freebie.minimum_purchase_amount)
              ? 0.5
              : 1,
        }}
      >
        <div
          className="col-10 d-flex align-items-center justify-content-between p-2 mt-2"
          style={{
            border: `1px solid ${
              product.quantity < parseInt(freebie.minimum_purchase_amount)
                ? "#d1d5dd"
                : "#f97663"
            }`,
            borderRadius: 4,
          }}
        >
          <div className="d-flex align-items-center">
            <img
              src={productImage(freebie.freebie)}
              alt="image"
              width={40}
              height={40}
              style={{
                objectFit: "cover",
                borderRadius: 8,
              }}
            />
            <div className="d-flex ml-3">
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {freebie.freebie.name}
              </div>
              <div className="ml-1" style={{ fontSize: 14, fontWeight: 600 }}>
                x{" "}
                {getFreeItem(
                  product.quantity,
                  parseInt(freebie.minimum_purchase_amount),
                  freebie.free_quantity
                ) || 1}
              </div>
            </div>
          </div>
          <Chip
            size="small"
            icon={
              product.quantity < parseInt(freebie.minimum_purchase_amount) ? (
                false
              ) : (
                <Check style={{ color: "#fff" }} />
              )
            }
            style={{
              backgroundColor:
                product.quantity < parseInt(freebie.minimum_purchase_amount)
                  ? "#9A9EA9"
                  : "#f97663",
              color: "#fff",
            }}
            label="FREE"
          />
        </div>
      </div>
      {product.quantity < parseInt(freebie.minimum_purchase_amount) ? (
        <>
          <div className="col-2"></div>
          <div
            className="col-10 text-center pt-2"
            style={{ fontSize: 10, color: "red" }}
          >
            Minimum quantity requirement not met
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
