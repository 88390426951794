import { ActionTypes } from "./actions";
import {
  add_to_cart,
  computeCartAmount,
  computeVoucherDiscount,
  combine_same_products,
  update_quantity,
  addFreebie,
} from "../../enterprise/components/cart/common/productFunctions";
import moment from "moment";

const initialState = {
  products: {
    loading: false,
    error: null,
    products: { results: [] },
    selectedProduct: "",
    filters: {
      status: "active",
      sort: "asc",
      sortBy: "created_on",
      category: [],
      itemsPerPage: 10,
      page: 1,
    },
  },
  vouchers: { voucherList: [], appliedVoucher: "" },
  cart: {
    items: [],
    selected_items: [],
    summary: { subtotal: 0, total: 0, voucher_discount: 0 },
  },
  selected_payment_channel: { paymentOption: "", account: "" },
  deliveryDetails: {
    loading: false,
    error: null,
    shipping: "",
    shippingFee: 0,
    delivery_detail: "",
    mrspeedy_delivery_address: {
      address: "",
      longitude: "",
      latitude: "",
    },
  },
  fees: {
    loading: false,
    cod_fee: 0,
    admin_fee: 0,
  },
  checkout: {
    loading: false,
    error: null,
    checkout: "",
  },
  deliveryMethod: {
    open: false,
    type: "",
  },
  error: {
    open: false,
    type: "",
    message: "",
  },
  preferred_date: {
    date: moment().add(1, "days"),
    key: 0,
  },
  calendar_date: "",
  freebie: [],
};

export default function QuickOrderReducers(state = initialState, action) {
  let itemToInsert = "";
  let newItemList = [];
  let isAlreadyListed = false;
  let new_items = [];
  let productSelection;
  let selected_items = [];
  switch (action.type) {
    case ActionTypes.QUICK_ORDER_GET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
        },
      };
    case ActionTypes.QUICK_ORDER_GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          products: {
            ...action.payload,
            results:
              action.mode === "append"
                ? [
                    ...state.products.products.results,
                    ...action.payload.results,
                  ]
                : action.payload.results,
          },
        },
      };
    case ActionTypes.QUICK_ORDER_GET_PRODUCTS_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          error: action.payload,
        },
      };
    case ActionTypes.QUICK_ORDER_SET_PRODUCT_FILTERS:
      return {
        ...state,
        products: {
          ...state.products,
          filters: {
            ...state.products.filters,
            ...action.payload,
          },
        },
      };
    case ActionTypes.QUICK_ORDER_SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case ActionTypes.QUICK_ORDER_ADD_TO_CART:
      itemToInsert = action.payload;
      newItemList = [...state.cart.items];
      new_items = add_to_cart(itemToInsert, newItemList);
      let array = [];
      for (let i in new_items) {
        array.push({ ...new_items[i], index: parseInt(i) });
      }
      new_items = [...array];
      return {
        ...state,
        cart: {
          ...state.cart,
          items: new_items,
          summary: {
            subtotal: computeCartAmount(state.cart.selected_items),
            voucher_discount: computeVoucherDiscount(
              state.vouchers.appliedVoucher,
              computeCartAmount(state.cart.selected_items)
            ),
            total:
              computeCartAmount(state.cart.selected_items) +
              parseFloat(state.deliveryDetails.shippingFee) +
              state.fees.admin_fee +
              state.fees.cod_fee,
          },
        },
      };
    case ActionTypes.QUICK_ORDER_UPDATE_VARIANT:
      itemToInsert = action.payload;
      productSelection = itemToInsert.productSelection;
      newItemList = [...state.cart.items];
      new_items = combine_same_products(
        itemToInsert,
        productSelection,
        newItemList
      );

      for (let product of new_items) {
        if (product["uuid"] === itemToInsert["uuid"]) {
          if (itemToInsert.has_variants && productSelection) {
            if (itemToInsert.prevSelection) {
              if (
                product.productSelection.id === itemToInsert.prevSelection.id
              ) {
                product.variants = itemToInsert.variants;
                product.productSelection = productSelection;
              }
            } else {
              product.variants = itemToInsert.variants;
              product.productSelection = productSelection;
            }
          }
        }
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          items: new_items,
          summary: {
            subtotal: computeCartAmount(state.cart.selected_items),
            voucher_discount: computeVoucherDiscount(
              state.vouchers.appliedVoucher,
              computeCartAmount(state.cart.selected_items)
            ),
            total:
              computeCartAmount(state.cart.selected_items) +
              parseFloat(state.deliveryDetails.shippingFee) +
              state.fees.admin_fee +
              state.fees.cod_fee,
          },
        },
      };

    case ActionTypes.QUICK_ORDER_UPDATE_QUANTITY:
      itemToInsert = action.payload;
      productSelection = itemToInsert.productSelection;
      newItemList = [...state.cart.items];
      isAlreadyListed = false;
      selected_items = [...state.cart.selected_items];
      new_items = update_quantity(itemToInsert, productSelection, newItemList);

      if (selected_items.length) {
        for (let product of selected_items) {
          if (product["id"] === itemToInsert["id"]) {
            product.quantity = itemToInsert.quantity;
          }
        }
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          items: new_items,
          selected_items: selected_items,
          summary: {
            subtotal: computeCartAmount(selected_items),
            voucher_discount: computeVoucherDiscount(
              state.vouchers.appliedVoucher,
              computeCartAmount(selected_items)
            ),
            total:
              computeCartAmount(selected_items) +
              parseFloat(state.deliveryDetails.shippingFee) +
              state.fees.admin_fee +
              state.fees.cod_fee,
          },
        },
      };

    case ActionTypes.QUICK_ORDER_REMOVE_FROM_CART:
      newItemList = [...state.cart.items];
      newItemList.splice(action.payload, 1);
      let newItemsSelected = state.cart.selected_items.filter(
        (i) => i.index !== action.product.index
      );
      return {
        ...state,
        cart: {
          ...state.cart,
          items: newItemList,
          selected_items: newItemsSelected,
          summary: {
            subtotal: computeCartAmount(newItemsSelected),
            voucher_discount: computeVoucherDiscount(
              state.vouchers.appliedVoucher,
              computeCartAmount(newItemsSelected)
            ),
            total:
              computeCartAmount(newItemsSelected) +
              parseFloat(state.deliveryDetails.shippingFee) +
              state.fees.admin_fee +
              state.fees.cod_fee,
          },
        },
      };

    case ActionTypes.QUICK_ORDER_SET_SELECTED_CHECKOUT:
      return {
        ...state,
        cart: {
          ...state.cart,
          selected_items: action.payload,
          summary: {
            subtotal: computeCartAmount(action.payload),
            voucher_discount: computeVoucherDiscount(
              state.vouchers.appliedVoucher,
              computeCartAmount(action.payload)
            ),
            total:
              computeCartAmount(action.payload) +
              parseFloat(state.deliveryDetails.shippingFee) +
              state.fees.admin_fee +
              state.fees.cod_fee,
          },
        },
      };

    case ActionTypes.QUICK_ORDER_SET_SELECTED_PAYMENT_CHANNEL:
      return {
        ...state,
        selected_payment_channel: action.payload,
      };

    case ActionTypes.QUICK_ORDER_SET_DELIVERY_DETAILS:
      return {
        ...state,
        deliveryDetails: action.payload,
        cart: {
          ...state.cart,
          summary: {
            ...state.cart.summary,
            subtotal: computeCartAmount(state.cart.selected_items),
            total:
              computeCartAmount(state.cart.selected_items) +
              parseFloat(action.payload.shippingFee) +
              state.fees.admin_fee +
              state.fees.cod_fee,
          },
        },
      };

    //CHECKOUT
    case ActionTypes.QUICK_ORDER_CHECKOUT_LOADING_ON:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          loading: true,
        },
      };
    case ActionTypes.QUICK_ORDER_CHECKOUT_FAILED:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          loading: false,
          error: action.payload,
        },
      };
    case ActionTypes.QUICK_ORDER_CHECKOUT_SUCCESS:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          loading: false,
          error: null,
          checkout: action.payload,
        },
      };

    //COMPUTE FEES
    case ActionTypes.QUICK_ORDER_COMPUTE_FEES:
      return {
        ...state,
        fees: {
          ...state.fees,
          loading: true,
        },
      };
    case ActionTypes.QUICK_ORDER_COMPUTE_FEES_SUCCESS:
      return {
        ...state,
        fees: {
          ...action.payload,
          loading: false,
        },
        cart: {
          ...state.cart,
          summary: {
            ...state.cart.summary,
            total:
              computeCartAmount(state.cart.selected_items) +
              parseFloat(state.deliveryDetails.shippingFee) +
              action.payload.admin_fee +
              action.payload.cod_fee,
          },
        },
      };
    case ActionTypes.QUICK_ORDER_COMPUTE_FEES_FAILED:
      return {
        ...state,
        fees: {
          ...state.fees,
          loading: false,
        },
      };
    case ActionTypes.QUICK_ORDER_DELIVERY_METHOD:
      return {
        ...state,
        deliveryMethod: action.payload,
      };
    case ActionTypes.QUICK_ORDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.QUICK_ORDER_SET_PREFERRED_DATE:
      return {
        ...state,
        preferred_date: action.payload,
      };
    case ActionTypes.QUICK_ORDER_SET_CALENDAR_DATE:
      return {
        ...state,
        calendar_date: action.payload,
      };
    case ActionTypes.QUICK_ORDER_SET_FREEBIE:
      itemToInsert = action.payload;
      newItemList = [...state.freebie];
      new_items = addFreebie(itemToInsert, newItemList);
      return {
        ...state,
        freebie: new_items,
      };
    case ActionTypes.QUICK_ORDER_UPDATE_FREEBIE_QUANTITY:
      itemToInsert = action.payload;
      newItemList = [...state.freebie];

      for (let product of newItemList) {
        if (product.id === itemToInsert.id) {
          product.is_requirement_applied = itemToInsert.is_requirement_applied;
          product.freebie_quantity = itemToInsert.freebie_quantity;
        }
      }

      return {
        ...state,
        freebie: newItemList,
      };

    case ActionTypes.QUICK_ORDER_RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
