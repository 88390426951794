import React, { useEffect } from "react";
import {
  Box,
  InputAdornment,
  Typography,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import SentroTextField from "../../common/sentroTextField";
import validations from "../../common/validations";
import { useDispatch, useSelector } from "react-redux";
import { login, fb_sign_up } from "../authActions";
import SentroButton from "../../common/sentroButton";
import Text from "../../../common/text";
import authStyles from "../authStyles";
import { Divider } from "antd";
import history from "../../../store/history";
import SocialButton from "../../social/socialButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import Path from "../../../Paths";
import { apiConfig } from "../../../common/apiConfig";
import { getStore } from "../../websiteBuilder/websiteBuilderActions";

let AuthForm = props => {
  const classes = authStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  let data = sessionStorage.getItem("TOKEN");
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  useEffect(() => {
    if (data) {
      async function fetchStore() {
        let res = await dispatch(getStore());
        if (user.is_admin || user.is_superuser) {
          history.push(Path.ADMIN_DASHBOARD);
        } else if (user.is_partner) {
          history.push(Path.ADMIN_APP_MARKETPLACE_PARTNERS_SALES_VIEW);
        } else if ((user.is_seller && user.is_buyer) || user.is_facebookLogin) {
          if (res.length) {
            if (mobileView) {
              history.push(Path.MOBILE_WEB_BUILDER);
            } else {
              history.push(Path.DASHBOARD);
            }
          } else {
            history.push(Path.SHOP_NEW_CREATE);
          }
        } else {
          history.push(Path.BUYER_DASHBOARD);
        }
      }
      fetchStore();
    } else {
      history.push(Path.LOGIN);
    }
  });

  const responseFacebook = response => {
    console.log(response);
  };
  let loading = useSelector(state => state.login.loading);
  let error = useSelector(state =>
    state.form.authForm ? state.form.authForm.syncErrors : true
  );
  let loginFormValues = useSelector(state =>
    state.form.authForm ? state.form.authForm.values : null
  );

  function submitCreds() {
    let params = {
      username: loginFormValues.username,
      password: loginFormValues.password
    };
    dispatch(login(params));
  }

  function fbSubmitCreds(token) {
    dispatch(fb_sign_up(token._token));
  }

  function loginViaEnterKeypress(event) {
    if (
      event.key !== "Enter" ||
      !loginFormValues.username ||
      !loginFormValues.password
    ) {
      return;
    }

    submitCreds();
  }

  const handleFBSocialLogin = user => {
    fbSubmitCreds(user);
  };

  return (
    <Box>
      <Box>
        <Text
          style={{ fontWeight: 600, color: "#2B2D33" }}
          textType="cap"
          content="Email"
        />
        <Field
          id="username"
          name="username"
          validate={[
            validations.required,
            validations.whiteSpace,
            validations.email
          ]}
          component={SentroTextField}
        />
      </Box>
      <Box
        style={{
          marginTop: "2vh",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Typography style={{ fontWeight: 600, color: "#2B2D33", fontSize: 12 }}>
          Password
        </Typography>
        <a
          onClick={() => history.push(Path.FORGOT_PASSWORD)}
          style={{ fontSize: 12, color: "#2B2D33" }}
        >
          Forgot Password?
        </a>
      </Box>
      <Box>
        <Field
          id="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SentroButton
                  style={{ color: "#2B2D33", fontSize: 10 }}
                  label={showPassword ? "HIDE" : "SHOW"}
                  onClick={e => setShowPassword(!showPassword)}
                  onMouseDown={e => setShowPassword(showPassword)}
                />
              </InputAdornment>
            )
          }}
          name="password"
          type={showPassword ? "text" : "password"}
          validate={[validations.required]}
          component={SentroTextField}
          onKeyPress={e => loginViaEnterKeypress(e)}
        />
      </Box>

      <Box style={{ marginTop: "3vh" }}>
        <SentroButton
          id="submit_button"
          disabled={error}
          fullWidth
          style={{ height: 48, fontSize: 16, fontWeight: 600 }}
          variant="contained"
          label="Login"
          onClick={() => submitCreds()}
          loading={loading}
        />
      </Box>

      <Box>
        <Divider style={{ fontSize: 12, color: "#333333" }}>or</Divider>
      </Box>

      <Box style={{ display: "flex", justifyContent: "center" }}>
        <SocialButton
          id="facebook_button"
          provider="facebook"
          appId={apiConfig.fbID}
          onLoginSuccess={handleFBSocialLogin}
          onLoginFailure={error => console.log(error)}
          className={classes.btnFacebook}
          startIcon={<FacebookIcon />}
          size="large"
        >
          Log in with Facebook
        </SocialButton>
      </Box>
    </Box>
  );
};

AuthForm = reduxForm({
  form: "authForm"
})(AuthForm);

export default AuthForm;
