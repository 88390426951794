import React from "react";
import logo from "../../assets/images/sentro_vertical.png";
import banner from "../../assets/images/sentro-login.jpg";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import AuthForm from "./authComponents/authForm";
import { ArrowBack } from "@material-ui/icons";
import SentroAlert from "../common/alert";
import SentroButton from "../common/sentroButton";
import Text from "../../common/text";
import Path from "../../Paths";
import history from "../../store/history";
import SentroLoading from "../common/loading";
import { useSelector } from "react-redux";
import DocumentMeta from "react-document-meta";
const Auth = props => {
  const meta = {
    title: "Sentro | Log-in",
    description:
      "Sign-up or log-in with Sentro via facebook to make things easier.",
    canonical: "https://sentro.ph/login",
    meta: {
      charset: "utf-8",
      name: {}
    }
  };

  React.useEffect(() => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("CURRENT_USER");
    localStorage.removeItem("CHANGE_PASSWORD_OBJECT");
    localStorage.removeItem("CHANGE_PASSWORD_EMAIL");
  }, []);
  const alert = useSelector(state => state.common.showAlert);

  const AuthStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60%",
      height: "100vh",
      backgroundImage: `url(${banner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      [theme.breakpoints.up("xl")]: {
        width: "100%"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    Auth: {
      position: "relative",
      height: "100vh",
      overflow: "auto",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    box1: {
      marginTop: "35vh",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },

    box2: {
      padding: "6vh 4vh 0 ",
      [theme.breakpoints.down("md")]: {
        padding: "6vh 2vw"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "6vh 15vw"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "6vh 5vw"
      }
    },
    bottomText: {
      fontSize: 13,
      textAlign: "center",
      color: "#2b2d33",
      position: "absolute",
      bottom: 5,
      padding: "0 4em",
      [theme.breakpoints.down("lg")]: {
        bottom: alert ? "-12vh" : 5
      },
      [theme.breakpoints.down("sm")]: {
        position: "inherit",
        padding: "2em 0 0"
      }
    }
  }));

  const classes = AuthStyles();
  const loading = useSelector(state => state.login.loading);

  return (
    <DocumentMeta {...meta}>
      <div className={classes.root}>
        <SentroLoading open={loading} />
        <Box className={classes.banner}>
          {/* <Container>
            <Box className={classes.box1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img src={logo} style={{ width: 150 }} />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Text
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    marginTop: "2vh"
                  }}
                  textType="h2"
                  content="Create your online shop here."
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                    color: "#fff",
                    position: "absolute",
                    bottom: 25
                  }}
                >
                  This works best in the latest Google desktop browser.
                </Typography>
              </div>
            </Box>
          </Container> */}
        </Box>
        <Box className={classes.Auth}>
          <Container>
            <Box style={{ marginTop: "2vh" }}>
              <SentroButton
                id="back_button"
                color="primary"
                onClick={() => history.push(Path.HOME)}
                startIcon={<ArrowBack color="primary" />}
                style={{ fontSize: 14 }}
                label={"BACK"}
              />
            </Box>
            <Box className={classes.box2}>
              <Text
                style={{ color: "#002C42" }}
                textType="h3"
                content="Login"
              />

              <Box style={{ marginTop: "4vh" }}>
                <SentroAlert />
              </Box>

              <Box style={{ marginTop: "3vh" }}>
                <AuthForm />
              </Box>
            </Box>
          </Container>
          <Box style={{ marginTop: "3vh", textAlign: "center" }}>
            <Typography style={{ fontSize: "13px" }}>
              Don't have an account yet?{" "}
              <a
                id="signup_button"
                style={{ color: "#F97663", fontSize: 14, fontWeight: "bold" }}
                onClick={() => props.history.push(Path.SIGN_UP)}
                variant="link"
              >
                Sign up
              </a>
            </Typography>
          </Box>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography className={classes.bottomText}>
              Have some concerns? Feel free to reach out to us at
              <a
                href="mailto:support@sentro.ph"
                style={{
                  color: "#F97663",
                  fontSize: 13,
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: 10
                }}
              >
                support@sentro.ph
              </a>
            </Typography>
          </div>
        </Box>
      </div>
    </DocumentMeta>
  );
};
export default Auth;
