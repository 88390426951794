import React from "react";
import _ from "lodash";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Link
} from "@material-ui/core";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { Field, change } from "redux-form"; // ES6
import ImageField from "../../../../reduxFields/imageField";
import TextField from "../../../../reduxFields/textField";
import NumberField from "../../../../reduxFields/numberField";
import RichTextField from "../../../../reduxFields/richTextField";
import SelectField from "../../../../reduxFields/selectField";
import CheckboxField from "../../../../reduxFields/checkbox";
import validations from "../../../../common/validations";
import AddProductCategory from "../addProductCategory";
import VariantChoicesForm from "../variantChoicesForm";
import Variants from "./variantGroups";
import FreeTrialModal from "../../../../subscription/components/products/freeTrialModal";
import { showAlert } from "../../../../common/commonActions";
import ImageTips from "../imageTips";

const statusChoices = [
  {
    value: "active",
    display: "Active"
  },
  {
    value: "draft",
    display: "Draft"
  }
];

const Form = props => {
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles(theme => ({
    label: {
      fontWeight: "600",
      fontSize: 16,
      color: "#F97663"
    },
    label2: {
      fontWeight: "500",
      fontSize: 14,
      color: "rgba(43, 45, 50, 0.8)",
      marginBottom: 0
    },
    label3: {
      fontWeight: "normal",
      fontSize: 12,
      color: "rgba(43, 45, 50, 0.8)",
      marginBottom: 0
    },
    discountedRemoveButton: {
      position: "absolute",
      top: 0,
      right: 10,
      fontWeight: "600",
      fontSize: 12,
      color: "#FF6B55",
      cursor: "pointer"
    },
    questionIcon: {
      "& > svg": {
        color: "#9A9EA9",
        height: 16,
        width: 16
      },
      position: "relative",
      "& > .qpopover": {
        background: "#3E4149",
        borderRadius: 4,
        padding: "5px 10px",
        fontSize: 12,
        color: "#FFFFFF",
        transition: "all ease-in-out 0.2s",
        transform: "translateX(-50%) scale(0)",
        top: -50,
        left: "50%",
        position: "absolute",
        zIndex: 100,
        width: 250
      },
      "& > .arrow": {
        content: "",
        height: 20,
        width: 20,
        position: "absolute",
        left: "50%",
        bottom: 0,
        transition: "all ease-in-out 0.2s",
        transform: "rotate(45deg) translate(-40px, -20px) scale(0)",
        background: "#3E4149",
        zIndex: 99
      },
      "&:hover": {
        "& > .qpopover": {
          transform: "translateX(-50%) scale(1)"
        },
        "& > .arrow": {
          transform: "rotate(45deg) translate(-25px, -10px) scale(1)"
        }
      }
    },
    backdrop: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 2,
      backgroundColor: "rgba(255,255,255,0.5)"
    },
    error: {
      color: "#f44336",
      fontSize: 12,
      marginTop: "1.5em"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const values = useSelector(state => state.form.product_form?.values);
  const subscription = useSelector(state => state.subscription.subscription);
  const variantCombinationFields = useSelector(
    state => state.form.variant_choices?.registeredFields
  );
  const productformInitialData = useSelector(
    state => state.webBuilder.reduxForm.productForm
  );

  // discounted_price
  const [hasDiscountedPrice, setHasDiscountedPrice] = React.useState(false);
  const hasDiscountedPriceOnChange = stat => {
    setHasDiscountedPrice(stat);
    if (!stat) dispatch(change("product_form", "discounted_price", ""));
  };
  React.useEffect(() => {
    if (productformInitialData) {
      setHasDiscountedPrice(
        Boolean(String(productformInitialData.discounted_price).length)
      );
    }
  }, [productformInitialData]);

  // lead time
  React.useEffect(() => {
    if (!values?.made_to_order) {
      dispatch(change("product_form", "lead_time", ""));
    }
  }, [values?.made_to_order]);

  // categories
  let existingCategories = useSelector(
    state => state.webBuilder.product_categories.categories
  );
  const [newCategories, setNewCategories] = React.useState([]);
  existingCategories = _.uniqBy(
    [
      { value: "Others", display: "Others" },
      ...existingCategories.map(val => ({
        value: val,
        display: val
      })),
      ...newCategories.map(val => ({
        value: val,
        display: val
      }))
    ],
    "value"
  );

  const [openCategoryModal, setOpenCategoryModal] = React.useState(false);
  const [openFreeTrial, setOpenFreeTrial] = React.useState(false);
  const [openImageTips, setOpenImageTips] = React.useState(false);

  function handleAddCategory(category) {
    setNewCategories(vals => [...vals, category]);
    dispatch(change("product_form", "category", category));
  }

  function handleDeleteCategory(category, categoriesToBeDeleted) {
    setNewCategories(
      newCategories.filter(i => !categoriesToBeDeleted.includes(i))
    );
    if (values.category === category) {
      dispatch(change("product_form", "category", "Others"));
    }
  }

  const addNewCategory = () => {
    if (
      (!subscription.subscription_type && existingCategories.length >= 5) ||
      (subscription.subscription_type &&
        !subscription.features.categories &&
        existingCategories.length >= 5)
    ) {
      setOpenFreeTrial(true);
    } else {
      setOpenCategoryModal(true);
      setTimeout(() => {
        dispatch(change("product_form", "category", "Others"));
      }, 0);
    }
  };

  // variant groups
  const productVariantGroup = useSelector(
    state => state.webBuilder.productVariants
  );
  const [variantGroups, setVariantGroups] = React.useState([]);
  const variantGroupOnChange = variantGroups => {
    setVariantGroups(variantGroups);
    if (props.variantGroupOnChange) props.variantGroupOnChange(variantGroups);
  };

  React.useEffect(() => {
    if (values?.quantity) {
      dispatch(
        change(
          "product_form",
          "quantity",
          parseInt(values?.quantity, 10) || null
        )
      );
    }
    if (values?.lead_time) {
      dispatch(
        change(
          "product_form",
          "lead_time",
          parseInt(values?.lead_time, 10) || null
        )
      );
    }
    if (values?.length) {
      dispatch(
        change("product_form", "length", parseInt(values?.length, 10) || null)
      );
    }
    if (values?.width) {
      dispatch(
        change("product_form", "width", parseInt(values?.width, 10) || null)
      );
    }
    if (values?.height) {
      dispatch(
        change("product_form", "height", parseInt(values?.height, 10) || null)
      );
    }
  }, [
    values?.quantity,
    values?.lead_time,
    values?.length,
    values?.width,
    values?.height
  ]);
  return (
    <div>
      <ImageTips
        is_product_image_tips={true}
        open={openImageTips}
        setOpen={setOpenImageTips}
      />
      <div className="border-bottom p-3 p-md-5 d-md-flex col-12">
        <p className={`${classes.label} col-3 p-0`}>Media</p>
        <div className="col-md-9 p-0">
          <p className={classes.label2}>Photos</p>
          <p className={classes.label3}>
            JPEG, PNG or GIF only. Up to 5 only.{" "}
            <Link color="primary" onClick={() => setOpenImageTips(true)}>
              Image tips
            </Link>
          </p>
          <Field
            id="img"
            name="img"
            component={ImageField}
            width={85}
            height={85}
            multiple
            cropOnImageSelect
            cropperProps={{
              defaultCropperOrientation: "square"
            }}
          />
        </div>
      </div>
      <div className="border-bottom p-3 p-md-5 d-md-flex col-12">
        <p className={`${classes.label} col-3 p-0`}>Details</p>
        {/* Name */}
        <div className="col-md-9 p-0">
          <p className={classes.label2}>Name</p>
          <Field
            id="name"
            name="name"
            validate={[validations.required]}
            component={TextField}
            inputProps={{
              maxlength: 30
            }}
          />
          {/* Price */}
          <div className="row align-items-center no-gutters position-relative">
            {values?.has_variants ? <span className={classes.backdrop} /> : ""}
            <div className="col-12 col-md-6 pr-2">
              <p className={classes.label2}>Price</p>
              <Field
                id="price"
                name="price"
                validate={
                  !values?.has_variants
                    ? [validations.required, validations.isNegativeNumber]
                    : null
                }
                component={NumberField}
                showHelperText
              />
            </div>
            <div className="col-12 col-md-6">
              <div
                className={`position-relative ${
                  hasDiscountedPrice ? "d-block" : "d-none"
                }`}
              >
                <p className={classes.label2}>Discounted Price (optional)</p>
                <p
                  className={classes.discountedRemoveButton}
                  onClick={() => hasDiscountedPriceOnChange(false)}
                >
                  Remove
                </p>
                <Field
                  id="discounted_price"
                  name="discounted_price"
                  validate={
                    !values?.has_variants && hasDiscountedPrice
                      ? [validations.required]
                      : null
                  }
                  component={NumberField}
                  showHelperText
                />
              </div>
              <Button
                id="add_discounted_price_button"
                className={`position-relative ${
                  hasDiscountedPrice ? "d-none" : "d-block"
                }`}
                color="primary"
                variant="outlined"
                style={{ height: 40, marginBottom: 10 }}
                onClick={() => hasDiscountedPriceOnChange(true)}
              >
                Add discounted price +
              </Button>
            </div>
          </div>
          {/* Product category */}
          <AddProductCategory
            open={openCategoryModal}
            setOpen={setOpenCategoryModal}
            subscription={subscription}
            categories={existingCategories.map(i => i.display)}
            handleAddCategory={category => handleAddCategory(category)}
            handleDeleteCategory={handleDeleteCategory}
            newCategories={newCategories}
            setNewCategories={setNewCategories}
          />
          <p className={`${classes.label2} mt-3`}>Product Category</p>
          <p className={classes.label3}>
            Define more specific categories for your own shop.
          </p>
          <Field
            id="category"
            name="category"
            component={SelectField}
            choices={[
              ...existingCategories,
              {
                value: "add",
                display: (
                  <Typography
                    color="primary"
                    onClick={addNewCategory}
                    className="w-100"
                  >
                    + Add new category
                  </Typography>
                )
              }
            ]}
            variant="outlined"
            fullWidth
          />
          <FreeTrialModal open={openFreeTrial} setOpen={setOpenFreeTrial} />
          {/* Description */}
          <p className={`${classes.label2} mt-3`}>Description</p>
          <Field
            id="description"
            name="description"
            component={TextField}
            inputProps={{
              maxlength: 500
            }}
            multiline
          />
          {/* Product status */}
          <p className={`${classes.label2} mt-3`}>Product status</p>
          <div className="row no-gutters align-items-center">
            <div className="col-12 col-md-6 pr-3">
              <Field
                id="status"
                name="status"
                component={SelectField}
                choices={statusChoices}
                variant="outlined"
                fullWidth
              />
            </div>
            <div className="col-12 col-md-6 mt-2 mt-md-0">
              <Field
                id="featured"
                name="featured"
                label="Feature on homepage"
                component={CheckboxField}
              />
            </div>
          </div>
          {/* Stocks */}
          <div className="position-relative">
            {values?.has_variants ? <span className={classes.backdrop} /> : ""}
            <p className={`${classes.label2} mt-3`}>Stocks</p>
            <p className={classes.label3}>
              Quantity will be reduced automatically for every order made
            </p>
            <div className="row no-gutters align-items-center">
              <div className="col-12 col-md-6 pr-3 position-relative">
                {values?.always_available ? (
                  <span className={classes.backdrop} />
                ) : (
                  ""
                )}
                <Field
                  id="quantity"
                  name="quantity"
                  validate={
                    !values?.has_variants && !values?.always_available
                      ? [
                          validations.required,
                          validations.stock,
                          validations.isNegativeNumber
                        ]
                      : null
                  }
                  component={TextField}
                />
              </div>
              <div className="col-12 col-md-6 mt-2 mt-md-0">
                <Field
                  id="always_available"
                  name="always_available"
                  label="Always available"
                  component={CheckboxField}
                />
              </div>
            </div>
          </div>
          {/* Product code */}
          <div className="row position-relative">
            {values?.has_variants ? <span className={classes.backdrop} /> : ""}
            <div className="col-12 col-md-6">
              <div className="mt-3 d-flex align-items-center">
                <p className={`${classes.label2} mr-2`}>Product code</p>
                <div className={classes.questionIcon}>
                  <div className="qpopover">
                    Stock Keeping Unit (SKU) is a unique number used to track a
                    shop’s inventory
                  </div>
                  <span className="arrow" />
                  <HelpOutlineRoundedIcon />
                </div>
              </div>
              <p className={classes.label3}>
                Optional. Change only if you have SKU for this item.
              </p>
              <Field
                id="product_code"
                name="product_code"
                component={TextField}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 p-md-5 d-md-flex col-12">
        <div className="col-md-3 col-12 p-0">
          <p className={`${classes.label}`}>Variants</p>
          <p className={classes.label3}>
            You can only add 2 variants per product
          </p>
        </div>
        <div className="col-md-9 p-0">
          <Field
            id="has_variants"
            name="has_variants"
            label="This product has multiple options (different sizes, colors, etc.)"
            component={CheckboxField}
          />
          <div className="position-relative">
            {!values?.has_variants ? <span className={classes.backdrop} /> : ""}
            <Variants
              product_uuid={props.product_uuid}
              value={productVariantGroup}
              onChange={variantGroupOnChange}
            />
            {!variantCombinationFields && values?.has_variants ? (
              <p className={classes.error}>Please enable atleast 1 variant.</p>
            ) : (
              ""
            )}
            <VariantChoicesForm
              product_uuid={props.product_uuid}
              disabled={!values?.has_variants}
              data={variantGroups}
            />
          </div>
        </div>
      </div>
      <div className="p-3 p-md-5 d-md-flex col-12">
        <p className={`${classes.label} col-3 p-0`}>Delivery</p>
        <div className="col-md-9 p-0">
          <div className="border-bottom">
            <Field
              id="is_physical_product"
              name="is_physical_product"
              label="This is a physical product"
              component={CheckboxField}
            />
          </div>
          <div
            className="py-3"
            style={{ opacity: !values?.is_physical_product ? 0.5 : 1 }}
          >
            <p className={`${classes.label2} mr-2`}>How heavy is the item?</p>
            <p className={classes.label3}>
              Note: Please use package weight, not product weight. Package
              weight is used to calculate shipping fee.
            </p>
            <div className="mt-2 d-flex align-items-center p-0">
              <div className="col-md-6 pr-2 pl-0">
                <Field
                  id="weight"
                  name="weight"
                  color="secondary"
                  fullWidth
                  component={SelectField}
                  choices={[
                    { value: "0-1", display: "0 - 1 kg" },
                    { value: "1-3", display: "1 - 3 kg" },
                    { value: ">3", display: "More than 3 kg" }
                  ]}
                  variant="outlined"
                  validate={
                    values?.is_physical_product ? [validations.required] : null
                  }
                  disabled={!values?.is_physical_product}
                  fullWidth
                />
              </div>
              <div
                className={`col-md-6 mt-2 ${
                  values?.weight === ">3" ? "d-block" : "d-none"
                }`}
              >
                <Field
                  id="free_form_weight"
                  name="free_form_weight"
                  color="secondary"
                  component={TextField}
                  validate={
                    values?.is_physical_product && values?.weight === ">3"
                      ? [
                          validations.required,
                          validations.numeric,
                          validations.greaterThan3
                        ]
                      : null
                  }
                  disabled={!values?.is_physical_product}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="py-3"
            style={{
              opacity: !values?.is_physical_product ? 0.5 : 1,
              borderBottom: "1px dashed #d1d5dd"
            }}
          >
            <p className={`${classes.label2} mr-2`}>Package size</p>
            <p className={classes.label3}>
              These will be used to compute for the volumetric weight of your
              package during shipping fee calculation.
            </p>
            <div className="mt-2 d-md-flex d-block align-items-center p-0">
              <div className="col-md-4 pr-md-3 pr-0 pl-0">
                <p className={`${classes.label2} mt-2`}>Length</p>
                <Field
                  id="length"
                  name="length"
                  color="secondary"
                  fullWidth
                  component={TextField}
                  variant="outlined"
                  validate={
                    values?.is_physical_product
                      ? [
                          validations.required,
                          validations.numeric,
                          validations.isNegativeNumber,
                          validations.isInteger
                        ]
                      : null
                  }
                  disabled={!values?.is_physical_product}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </div>
              <div className="col-md-4 pr-md-3 pr-0 pl-0">
                <p className={`${classes.label2} mt-2`}>Width</p>
                <Field
                  id="width"
                  name="width"
                  color="secondary"
                  fullWidth
                  component={TextField}
                  variant="outlined"
                  validate={
                    values?.is_physical_product
                      ? [
                          validations.required,
                          validations.numeric,
                          validations.isNegativeNumber,
                          validations.isInteger
                        ]
                      : null
                  }
                  disabled={!values?.is_physical_product}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </div>
              <div className="col-md-4 pr-md-3 pr-0 pl-0">
                <p className={`${classes.label2} mt-2`}>Height</p>
                <Field
                  id="height"
                  name="height"
                  color="secondary"
                  fullWidth
                  component={TextField}
                  variant="outlined"
                  validate={
                    values?.is_physical_product
                      ? [
                          validations.required,
                          validations.numeric,
                          validations.isNegativeNumber,
                          validations.isInteger
                        ]
                      : null
                  }
                  disabled={!values?.is_physical_product}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </div>
            </div>
          </div>
          <div className="py-3">
            <Field
              id="made_to_order"
              name="made_to_order"
              label="This product is made-to-order"
              component={CheckboxField}
            />
            <div
              className="pt-3"
              style={{ opacity: !values?.made_to_order ? 0.5 : 1 }}
            >
              <Field
                id="lead_time"
                name="lead_time"
                label="How much lead time do you need for this product?"
                validate={
                  values?.made_to_order
                    ? [
                        validations.numeric,
                        validations.leadTime,
                        validations.isInteger
                      ]
                    : null
                }
                disabled={!values?.made_to_order}
                component={TextField}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
