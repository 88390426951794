import React from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Text from "../../common/text";

let bg = "https://sentro-store-bucker.s3.us-east-2.amazonaws.com/FAQ_banner";
const Banner = props => {
  const BannerStyles = makeStyles(theme => ({
    banner: {
      // color: "#FAFAFA",
      textAlign: "center",
      width: "100%",
      height: "100vh",
      backgroundImage: `url(${bg})`,
      backgroundSize: "cover"
    },

    bannerContent: {
      color: "#f97663",
      paddingTop: "78vh",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "43vh"
      }
    }
  }));

  const classes = BannerStyles();

  return (
    <div className={classes.banner}>
      <Box>
        <Container>
          <Box className={classes.bannerContent}>
            <Text textType="h2" content="Privacy Policy" />

            <Text
              style={{
                textAlign: "center",
                color: "#f97663"
              }}
              textType="p1"
              content="By using the Sentro platform, you agree to our house rules"
            />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Banner;
