let colors = [
  { color: "#2b2d33", value: "bastille" },
  { color: "#d55e00", value: "redOrange" },
  { color: "#cc79a7", value: "violetRed" },
  { color: "#e69f00", value: "orange" },
  { color: "#f0e442", value: "goldenFizz" },
  { color: "#2f9395", value: "darkCyan" },
  { color: "#3e92cc", value: "skyBlue" },
  { color: "#9763c0", value: "funBlue" },

  {
    color: "linear-gradient(180deg, #3e92cc 50%, #1e0065 50%)",
    value: "blue/navy"
  },
  {
    color: "linear-gradient(180deg, #2f9395 50%,#e9a6b3  50%)",
    value: "pink/darkCyan"
  },
  {
    color: "linear-gradient(180deg, #f1c944 50%, #2b2d33 50%)",
    value: "goldYellow/bastille"
  },
  {
    color: "linear-gradient(180deg, #f1f1f1 50%,#373737 50%)",
    value: "lightGray/grey"
  },
  {
    color: "linear-gradient(180deg, #fcbfb7 50%, #334e58 50%)",
    value: "pink/darkGrey"
  },
  {
    color: "linear-gradient(180deg, #5cc5cf 50%, #4964af 50%)",
    value: "richBlue/turquoise"
  },
  {
    color: "linear-gradient(180deg, #514842 50%, #d55e00 50%)",
    value: "redOrange/Mondo"
  },
  {
    color: "linear-gradient(180deg, #5e5e5e 50%, #e69f00 50%)",
    value: "orange/dimGray"
  }
];

export default colors;
