import React from "react";
import { makeStyles } from "@material-ui/styles";
import validations from '../../../../../common/validations';
import { Field } from "redux-form";
import ReduxTextField from '../../../../../reduxFields/textField';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    "& p": {
      textAlign: "right"
    }
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)"
  },
  addBtn: {
    height: 38,
    marginLeft: "auto",
    minWidth: 100,
  },
  item: {
    padding: "1em",
    background: "#FFFFFF",
    border: "1px solid #EEEEEE",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: 4
  },
  label2: {
    fontSize: 14,
    fontWeight: 500,
    color: "rgba(43, 45, 50, 0.8)",
    marginBottom: 3
  },
  btn: {
    height: 38
  },
  note: {
    marginTop: "2em",
    padding: "0.5em 1em",
    color: "#2B2D32",
    background: "#EEEEEE",
    borderRadius: 2
  },
  field: {
    "& p": {
      textAlign: "right"
    }
  },
  checkboxLabel: {
    fontSize: 12
  },
  what_is_this: {
    fontSize: 12,
    color: "#18B4A1",
    position: "relative",
    marginLeft: 5,
    cursor: "pointer",
    "& .popup": {
      opacity: 0,
      zIndex: -1,
      transition: "all 0.2s ease-in-out",
      background: "#002C42",
      borderRadius: 4,
      padding: "1em",
      position: "absolute",
      bottom: 30,
      width: 232,
      left: "50%",
      marginLeft: "-116px",
      "& .arrow": {
        background: "#002C42",
        height: 10, width: 10,
        transform: "rotate(45deg)",
        left: "calc(50% - 5px)",
        position: "absolute",
        bottom: "-5px"
      }
    },
    "&:hover": {
      "& .popup": {
        opacity: 1,
        zIndex: 1
      }
    }
  }
}));

const PasswordPane = props => {

  const classes = useStyles();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));

  return (
    <div className="p-3 p-md-5">
      <p className={classes.label2}>Your password was last updated on 
        <strong>{` `}{moment(new Date(user.password_change)).format(`DD MMM YYYY`)}</strong>.</p>
      <div className="row mt-2">
        <div className="col-12 col-md-6">
          <p className={classes.label2}>Current password</p>
          <Field
            id="old_password"
            name="old_password"
            validate={[
              validations.required
            ]}
            component={ReduxTextField}
            type="password"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <p className={classes.label2}>New password</p>
          <Field
              id="new_password"
              name="new_password"
              validate={[
                validations.required,
                validations.password
              ]}
              component={ReduxTextField}
              type="password"
          />
        </div>
        <div className="col-12 col-md-6">
          <p className={classes.label2}>Confirm new password</p>
          <Field
              id="confirm_new_password"
              name="confirm_new_password"
              validate={[
                validations.required,
                validations.password
              ]}
              component={ReduxTextField}
              type="password"
          />
        </div>
      </div>
    </div>
  );
};



export default PasswordPane;