import axios from "axios";
import apiConfig from "../../../common/apiConfig";
import { createApi } from 'unsplash-js';
import { createClient } from 'pexels';
import sstk from 'shutterstock-api';

// unsplash
const unsplash = createApi({
  accessKey: apiConfig.unsplash_key,
});
// pexels
const pexels = createClient(apiConfig.pexel_key);
// shutterstock
sstk.setAccessToken(apiConfig.shutterstock_key);
const shutterstock = new sstk.ImagesApi();

class Services {

	static async searchPhotosUnsplash(q, page, callback) {
    unsplash.search.getPhotos({
      query: q,
      page,
      perPage: 15,
      // color: 'green',
      orientation: 'landscape',
    }).then(result => {
      if (result.errors) {
        // handle error here
        console.log('error occurred: ', result.errors[0]);
      } else {
        const feed = result.response;
        const { total_pages, results } = feed;
        console.log(results)
        const images = results.map(res => ({
          src: res.urls.regular,
          full: res.urls.full,
          attribution: {
            description: res.description,
            artist: res.user.name,
            artist_url: res.user.links.html,
            original_url: res.links.html,
            profile_image: res.user.profile_image.small,
            platform: "Unsplash"
          }
        }))
        callback({
          images,
          max_page: total_pages
        })
      }
    });
	}

	static async searchPhotosPexels(q, page, callback) {
    try {
      pexels.photos.search({ 
        query: q, 
        page,
        per_page: 15
      }).then(result => {
        // const res = result.response;
        const { total_results, photos } = result;
        console.log(photos)
        // console.log(photos)
        const images = photos.map(res => ({
          src: res.src.medium,
          full: res.src.large2x,
          attribution: {
            artist: res.photographer,
            artist_url: res.photographer_url,
            original_url: res.url,
            platform: "Pexels"
          }
        }))
        callback({
          images,
          max_page: Math.ceil(total_results/15)
        })
      });
    } catch (err) {
    }
  }

	static async searchPhotosShutterstock(q, callback) {
    try {
        shutterstock.searchImages({
          query: q,
          sort: 'popular',
          orientation: 'horizontal'
        }).then(({data}) => {
          const images = data.map(res => ({
            src: res.assets.preview.url,
            attribution: {
              description: res.description,
              original_url: "https://www.shutterstock.com",
              platform: "Shutterstock"
            }
          }))
          callback(images)
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
    }
  }

	static async searchPhotosAdobeStock(q, callback) {
    const page = 0;
    try {
      axios.get("https://stock.adobe.io/Rest/Media/1/Search/Files", {
        headers: {
          'x-api-key': apiConfig.adobestock_key,
          'x-product': "Sentro"
        },
        params: {
          locale: "en_US",
          'search_parameters[words]': q,
          'search_parameters[limit]': 20,
          'search_parameters[offset]': 20 * page
        }
      }).then(({data}) => {
        console.log(data)
        const images = data.files.map(res => ({
          src: res.thumbnail_url,
          attribution: {
            description: res.title,
            creator_name: res.creator_name,
            original_url: "https://stock.adobe.com/",
            platform: "Adobe Stock"
          }
        }))
        callback(images)
      })
    } catch (err) {
    }
  }
}
export default Services;
