import React from "react";
import {
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Text from "../../../../../common/text";
import { useSelector, useDispatch } from "react-redux";
const DialogTransition = props => <Slide direction="up" {...props} />;

const MobileVoucherFilter = props => {
  const { open, setOpen, state, setState } = props;
  const useStyles = makeStyles(theme => ({
    appbar: {
      position: "fixed",
      backgroundColor: "#fafafa",
      boxShadow: "none",
      borderBottom: "1px solid #d1d5dd"
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    closebtn: { position: "absolute", left: 15 },
    datepicker: {
      backgroundColor: "#fff",
      width: "50%",
      margin: 5,
      "& input": {
        fontSize: 12,
        color: "#54575f"
      }
    }
  }));

  const classes = useStyles();
  const statusValues = ["True", "False"];
  const typeValues = ["discount"];
  const filters = useSelector(state => state.mobileDashboard.voucherFilters);

  const [statusSelected, setStatusSelected] = React.useState([
    "all",
    ...statusValues
  ]);
  const [typeSelected, setTypeSelected] = React.useState([
    "all",
    ...typeValues
  ]);

  const setFilter = (key, val) => {
    if (props.onChange) props.onChange({ [key]: val });
  };

  const checkIfAllSelected = (listcategories = false, selected, values) => {
    const choices = values;
    const selected_choices = listcategories ? listcategories : selected;
    for (let i in choices) {
      const choice = choices[i];
      if (choice !== "all") {
        if (!selected_choices.includes(choice)) {
          return false;
        }
      }
    }
    return true;
  };

  const handleStatusChange = (e, val) => {
    if (val !== "all") {
      if (e.target.checked) {
        if (!statusSelected.includes(val)) {
          const array = addOrRemoveAll(
            [...statusSelected, val],
            statusSelected,
            statusValues
          );
          setStatusSelected(array);
        }
      } else {
        let array = statusSelected.filter(e => e !== val);
        array = addOrRemoveAll(array, statusSelected, statusValues);
        setStatusSelected(array);
      }
    } else {
      setStatusSelected(e.target.checked ? ["all", ...statusValues] : []);
    }
  };

  const handleTypeChange = (e, val) => {
    if (val === "all") {
      if (!e.target.checked) {
        setTypeSelected(["discount"]);
        setState({ ...state, voucher_type: "discount" });
      } else {
        setTypeSelected(["all", "discount"]);
        setState({ ...state, voucher_type: "all" });
      }
    }
    // if (val !== "all") {
    //   if (e.target.checked) {
    //     if (!typeSelected.includes(val)) {
    //       const array = addOrRemoveAll(
    //         [...typeSelected, val],
    //         typeSelected,
    //         typeValues
    //       );
    //       setTypeSelected(array);
    //     }
    //   } else {
    //     let array = typeSelected.filter(e => e !== val);
    //     setTypeSelected(array);
    //   }
    // } else {
    //   setTypeSelected(e.target.checked ? [...typeValues] : []);
    // }
  };

  const addOrRemoveAll = (filters, selected, values) => {
    let array = filters;
    if (checkIfAllSelected(array, selected, values) && !array.includes("all")) {
      array = ["all", ...array];
    } else {
      array = array.filter(e => e !== "all");
    }
    return array;
  };

  const handleFilter = () => {
    if (props.onChange) {
      props.onChange({
        startDate: moment(state.startDate).format("MM/DD/YYYY"),
        endDate: moment(state.endDate).format("MM/DD/YYYY"),
        voucher_type: state.voucher_type,
        is_active: state.is_active
      });
      props.fetch_vouchers();
    }
    setOpen(false);
  };

  const status = [
    { name: "Active", value: "True" },
    { name: "Inactive", value: "False" }
  ];

  React.useEffect(() => {
    if (statusSelected) {
      if (statusSelected.length === 1) {
        setState({ ...state, is_active: statusSelected[0] });
      } else {
        setState({ ...state, is_active: "all" });
      }
    }
  }, [statusSelected]);

  React.useEffect(() => {
    if (filters.voucher_type === "all") {
      setTypeSelected(["all", "discount"]);
    } else {
      setTypeSelected(["discount"]);
    }
  }, [filters]);

  return (
    <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
      <AppBar className={classes.appbar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className={classes.closebtn}
            onClick={() => setOpen(false)}
          >
            <CloseIcon color="primary" />
          </IconButton>
          <div className={classes.header}>Filter</div>
        </Toolbar>
      </AppBar>

      <div style={{ marginTop: "10vh", padding: 20, position: "relative" }}>
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid #d1d5dd",
            paddingBottom: 20
          }}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              label="Start Date"
              margin="dense"
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="MM/DD/YYYY"
              InputAdornmentProps={{ position: "start" }}
              orientation="portrait"
              color="secondary"
              className={classes.datepicker}
              value={state.startDate}
              maxDate={state.endDate}
              onChange={date => setState({ ...state, startDate: date })}
            />

            <KeyboardDatePicker
              label="End Date"
              margin="dense"
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="MM/DD/YYYY"
              InputAdornmentProps={{ position: "start" }}
              orientation="portrait"
              color="secondary"
              className={classes.datepicker}
              value={state.endDate}
              minDate={state.startDate}
              onChange={date => setState({ ...state, endDate: date })}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ padding: "20px 0", borderBottom: "1px solid #d1d5dd" }}>
          <Text textType="p3_Semibold" content="Status:" />
          <FormGroup>
            <FormControlLabel
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 0
              }}
              control={
                <Checkbox
                  checked={statusSelected.includes("all")}
                  onChange={e => handleStatusChange(e, "all")}
                  color="primary"
                />
              }
              label={<div style={{ fontSize: 14, color: "#54575f" }}>All</div>}
              labelPlacement="start"
            />

            {status.map((item, key) => {
              return (
                <FormControlLabel
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: 0
                  }}
                  control={
                    <Checkbox
                      checked={statusSelected.includes(item.value)}
                      onChange={e => handleStatusChange(e, item.value)}
                      color="primary"
                    />
                  }
                  label={
                    <div style={{ fontSize: 14, color: "#54575f" }}>
                      {item.name}
                    </div>
                  }
                  labelPlacement="start"
                />
              );
            })}
          </FormGroup>
        </div>

        <div style={{ padding: "20px 0" }}>
          <Text textType="p3_Semibold" content="Type:" />
          <FormGroup>
            <FormControlLabel
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 0
              }}
              control={
                <Checkbox
                  checked={typeSelected.includes("all")}
                  onChange={e => handleTypeChange(e, "all")}
                  color="primary"
                />
              }
              label={<div style={{ fontSize: 14, color: "#54575f" }}>All</div>}
              labelPlacement="start"
            />
            {typeValues.map((item, key) => {
              return (
                <FormControlLabel
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: 0
                  }}
                  control={
                    <Checkbox
                      checked={typeSelected.includes(item)}
                      onChange={e => handleTypeChange(e, item)}
                      color="primary"
                    />
                  }
                  label={
                    <div
                      style={{
                        fontSize: 14,
                        color: "#54575f",
                        textTransform: "capitalize"
                      }}
                    >
                      {item}
                    </div>
                  }
                  labelPlacement="start"
                />
              );
            })}
          </FormGroup>
        </div>
        <div style={{ position: "absolute", left: 20, right: 20 }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            style={{ height: 43 }}
            onClick={() => handleFilter()}
          >
            Apply Filter
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            fullWidth
            style={{ height: 43, marginTop: 10 }}
            onClick={() => {
              setState({
                status: "all",
                voucher_type: "all",
                search: "",
                startDate: new Date().toLocaleDateString("en-US"),
                endDate: new Date().toLocaleDateString("en-US")
              });
              setStatusSelected(["all", ...statusValues]);
              setTypeSelected(["all", ...typeValues]);
            }}
          >
            Clear All
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default MobileVoucherFilter;
