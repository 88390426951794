import React from "react";

import ProductDetailsTemplate from "./productDetails/productDetailsTemplate";
import Footer from "./Footer";
import Header from "./AppBar";
import PreviewHeader from "./PreviewAppBar";
const Products = props => {
  let storeInfo = props.storeInfo;
  let product = props.productList ? props.productList[0] : "";
  let gallery = product ? (product.gallery ? product.gallery : "") : "";
  let imgPlaceholder =
    "https://sentro-store-bucker.s3.us-east-2.amazonaws.com/Image+(2).png";
  let images = [];
  if (gallery === "" || gallery === "undefined") {
    if (product ? product.images : "") {
      let params = { original: product.images };
      images.push(params);
    } else {
      let params = { original: imgPlaceholder };
      images.push(params);
    }
  } else {
    for (let i in gallery) {
      let params = {
        original: gallery[i].image,
        thumbnail: gallery[i].image
      };
      images.push(params);
    }
  }
  return (
    <div>
      {/* <Header
        isPreview={true}
        storeInfo={storeInfo}
        color={true}
        logo={true}
        shadow={true}
        logo={true}
      /> */}
      <PreviewHeader
        isPreview={true}
        storeInfo={storeInfo}
        color={true}
        logo={true}
        shadow={true}
      />
      <ProductDetailsTemplate
        isPreview={true}
        store={storeInfo}
        product={product}
        productImages={images}
      />

      <Footer
        setImageSettings={props.setImageSettings}
        isPreview={true}
        store={storeInfo}
      />
    </div>
  );
};

export default Products;
