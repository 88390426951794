import React from "react";
import { Box } from "@material-ui/core";
import { composeAsyncValidator } from "redux-form-async-validator";
import { changePasswordAction } from "../forgotPasswordAction";
import Text from "../../../common/text";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import SentroTextField from "../../common/sentroTextField";
import SentroButton from "../../common/sentroButton";
import validations from "../../common/validations";
import SentroTheme from "../../common/sentroTheme";
import { isExist } from "../../forgotPassword/forgotPasswordServices";
import { useCountdownTimer } from '../../hooks/countdown';
let ChangePasswordForm = props => {
  const dispatch = useDispatch();

  let error = useSelector(state =>
    state.form.changePasswordForm
      ? state.form.changePasswordForm.syncErrors
      : true
  );

  let loading = useSelector(state => state.forgotPassword.loading);

  let asyncError = useSelector(state =>
    state.form.changePasswordForm
      ? state.form.changePasswordForm.asyncErrors
      : true
  );
  let changePasswordFormValues = useSelector(state =>
    state.form.changePasswordForm ? state.form.changePasswordForm.values : null
  );

  const signUpButtonRef = React.createRef();
  const [secondsLeft, setSecondsLeft, resendCountdownDisplay] = useCountdownTimer({
    save_to_local: true,
    module: 'sme/forgot_password',
    key: changePasswordFormValues?.email
  });

  function submitEmail() {
    setSecondsLeft(10*60);
    dispatch(
      changePasswordAction(
        changePasswordFormValues ? changePasswordFormValues.email : ""
      )
    );
  }

  const spinner = (
    <div className="d-flex justify-content-center loading-spinner">
      <div className="spinner-border mx-auto text-secondary" role="status" style={{ 
      color: SentroTheme.palette.primary.main, fontSize: 8,
      height: 15, width: 15
      }}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>)

  return (
    <Box>
      <Box>
        <Text
          style={{ fontWeight: 600, color: "#2B2D33" }}
          textType="cap"
          content="Email"
        />
        <Field
          id="email"
          // maxLength={25}
          autoComplete="off"
          name="email"
          validate={[
            validations.required,
            validations.whiteSpace,
            validations.email
          ]}
          component={SentroTextField}
        />
      </Box>

      <Box style={{ marginTop: "3vh" }}>
        <SentroButton
          id="submitEmail_button"
          disabled={error || asyncError || Boolean(secondsLeft) || loading}
          buttonType="button3"
          fullWidth
          variant="contained"
          label={
            loading ? 
              spinner
              :
              secondsLeft > 0 ? resendCountdownDisplay: "Submit"
          }
          onClick={() => submitEmail()}
          style={{ height: 48, fontSize: 16, fontWeight: 600 }}
          innerRef={signUpButtonRef}
        />
      </Box>
    </Box>
  );
};

let checkUniqueEmail = async value => await isExist(value);
ChangePasswordForm = reduxForm({
  form: "changePasswordForm",
  destroyOnUnmount: true,
  asyncValidate: composeAsyncValidator({
    email: [
      checkUniqueEmail,
      "Email is not registered with Sentro. Please sign up!"
    ]
  }),
  asyncBlurFields: ["email"]
})(ChangePasswordForm);

export default ChangePasswordForm;
