const admin_paths = {
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_ADD_ADMIN_USERS: "/admin/admin_users/add",
  ADMIN_ADMIN_USERS: "/admin/admin_users",
  ADMIN_ADMIN_USERS_OLD: "/admin/admin_users_old",
  ADMIN_DETAIL_ADMIN: "/admin/admin_users/:id",
  ADMIN_SHOPS: "/admin/dashboard/shops",
  ADMIN_SHOP_LOGS: "/admin/dashboard/shop/:id/logs",
  ADMIN_PRODUCTS: "/admin/dashboard/products",
  ADMIN_CHECKOUTS: "/admin/dashboard/checkouts",
  ADMIN_BUX_PAYMENTS: "/admin/bux_payments",
  ADMIN_SIGNUPS: "/admin/dashboard/signups",
  ADMIN_DETAIL_SIGNUPS: "/admin/dashboard/signups/:id",
  ADMIN_DAILY_UNVERIFIED_USERS: "/admin/dashboard/unverified_users",
  ADMIN_ACTIVE_USERS: "/admin/dashboard/active_users",
  ADMIN_DAILY_ACTIVE_USERS: "/admin/dashboard/daily_active",
  ADMIN_MONTHLY_ACTIVE_USERS: "/admin/dashboard/monthly_active",
  ADMIN_SIGNUP_VERIFY: "/admin/verify",
  ADMIN_APP_MARKETPLACE: "/admin/marketplace",
  ADMIN_APP_MARKETPLACE_CREATE: "/admin/marketplace/create",
  ADMIN_APP_MARKETPLACE_EDIT: "/admin/marketplace/:id",
  ADMIN_APP_MARKETPLACE_PARTNERS_SIGNUP_VERIFY: "/partner/verify",
  ADMIN_APP_MARKETPLACE_PARTNERS_SALES_VIEW: "/partner/sales",
  ADMIN_APP_MARKETPLACE_PARTNERS_EDIT: "/admin/marketplace/partners/:id/edit",
  ADMIN_APP_MARKETPLACE_PARTNERS_DETAILED: "/admin/marketplace/partners/:id",
  ADMIN_APP_MARKETPLACE_PARTNERS_ADD: "/admin/marketplace/partners/add",
  ADMIN_APP_MARKETPLACE_PARTNERS: "/admin/marketplace/partners",
  ADMIN_APP_MARKETPLACE_SUBSCRIPTIONS: "/admin/marketplace/subscriptions",
  ADMIN_PROMOS: "/admin/promos",
  ADMIN_LOGISTICS: "/admin/logistics",
  ADMIN_LOGISTICS_BOOKINGS: "/admin/logistics/bookings",
  ADMIN_LOGISTICS_BOOKING: "/admin/logistics/booking/:id"
};

const proxtera_paths = {
  PROXTERA_HOME: "/proxtera",
  PROXTERA_PRODUCT_LIST: "/proxtera/products",
  PROXTERA_PRODUCT_DETAILED: "/proxtera/product",
  PROXTERA_CART: "/proxtera/cart",
  PROXTERA_SHIPPING: "/proxtera/shipping",
  PROXTERA_CHECKOUT: "/proxtera/checkout",
  PROXTERA_ORDERS: "/proxtera/orders",
  PROXTERA_ORDER: "/proxtera/order/:id",
  PROXTERA_MY_ORDERS: "/proxtera/my_orders",
  PROXTERA_MY_ORDER: "/proxtera/my_order/:id"
};

const Path = {
  ...admin_paths,
  ...proxtera_paths,
  LOGIN: "/login",
  SIGN_UP: "/sign_up",
  VERIFICATION: "/verification",
  VERIFICATION_SUCCESS: "/success",
  VERIFICATION_FAILED: "/failed",
  DASHBOARD: "/dashboard",
  FB_CHAT_SETUP: "/dashboard/chat-setup",
  FB_PIXEL_SETUP: "/dashboard/facebook_pixel_setup",
  CUSTOM_DOMAIN_SETUP: "/dashboard/custom-domain-setup",
  ACCOUNT_SETTINGS_BASIC_INFORMATION: "/account_settings/basic_information",
  ACCOUNT_SETTINGS_PASSWORD: "/account_settings/password",
  BUILDER: "/store_builder",
  BUILDER_ENTRY: "/store_entry",
  PUBLISH: "/publish",
  STORE: "/sentro_marketplace",
  PRODUCT_DETAILS: "/details",
  APPS: "/apps",
  HOME: "/home",
  BUX: "/bux_dashboard",
  TNC: "/terms_and_conditions",
  PRIVACY_POLICY: "/privacy_policy",
  FAQ: "/things_you_should_know",
  ANNOUNCEMENTS: "/announcements",
  FORGOT_PASSWORD: "/forgot_password",
  RESET_VERIFY_EMAIL: "/reset_password_verification",
  INPUT_PASSWORD: "/input_password",
  CHANGE_PASSWORD_REQUEST: "/change_password_request",
  VERIFY_EMAIL: "/email_verification",
  RESET_PASSWORD_SUCCESS: "/reset_password_success",
  LINK_EXPIRED: "/link_expired",
  PUBLISHED: "/view_your_site_now",
  OPEN_MIC: "/order-thru-sentro",
  STORE_HOME: `/store/:id`,
  STORE_PRODUCTS: `/store/products`,
  STORE_PRODUCT: `/store/product`,
  STORE_CART: `/store/cart`,
  STORE_CHECKOUT: `/store/checkout`,
  ORDER_LIST_VIEW: `/orders/list`,
  ORDER_DETAILS_VIEW: `/orders/details`,
  ORDER_DELIVERY_REQUEST: "/orders/delivery/request",
  ORDER_DELIVERY_REQUEST_SUCCESS: "/orders/delivery_request_success",
  ORDER_DELIVERY_MAP: "/orders/delivery_map",
  MOBILE_WEB_BUILDER: "/mobile/shop",
  MOBILE_PREVIEW: "/mobile/preview",
  MOBILE_PRODUCTS: "/mobile/products",
  MOBILE_ORDERS: "/mobile/orders",
  MOBILE_VOUCHERS: "/mobile/vouchers",
  MOBILE_SIGN_UP: "/mobile/sign_up",
  MOBILE_CREATE_SHOP: "/mobile/create_shop",
  MOBILE_BUX_DASHBOARD: "/mobile/sales",
  MOBILE_FB_CHAT: "/mobile/fb_chat",
  MOBILE_FB_PIXEL: "/mobile/fb_pixel",
  APP_MARKETPLACE: "/marketplace",
  APP_MARKETPLACE_PACKAGES: "/marketplace/:id/packages",
  APP_MARKETPLACE_DETAILED: "/marketplace/:id",
  BUX_DASHBOARD: "/sales",
  ORDER_UPDATE_PAYMENT: "/orders/update_payment",
  APP_MARKETPLACE_CART: "/marketplace/cart",
  APP_MARKETPLACE_CHECKOUT: "/marketplace/checkout",
  APP_MARKETPLACE_CHECKOUT_DETAILS: "/marketplace/checkout/details",
  NOYPI_OYAT: "/noypi_oyat",
  SENTRO_SA_PAGASENSO_PASTRY: "/sentro_sa_pagasenso_pastry",
  SENTRO_SA_PAGASENSO_COSMETICS: "/sentro_sa_pagasenso_cosmetics",
  SENTRO_SA_PAGASENSO_GENERIC: "/sentro_sa_pagasenso_generic",
  SHOP_NEW_CREATE: "/webbuilder",
  SHOP_INFO: "/webbuilder/shop_info",
  SHOP_STYLE: "/webbuilder/shop_style",
  CUSTOM_DOMAIN: "/custom-domain",
  PRODUCTS: "/products",
  PRODUCTS_ADD: "/products/add",
  PRODUCTS_EDIT: "/products/:uuid",
  PAYMENTS: "/payments",
  PAYMENTS_ADD: "/payments/add",
  PAYMENTS_EDIT: "/payments/:uuid",
  VOUCHERS: "/vouchers",
  CREATE_VOUCHER: "/vouchers/create_voucher",
  CUSTOM_DOMAIN: "/custom-domain",
  BUYER_DASHBOARD: "/my_cart",
  CART_VIEW_ORDER: "/my_cart/view_cart",
  PURCHASED_PAGE: "/purchased",
  PURCHASED_VIEW_ORDER: "/purchased/view_order",
  DELIVERY_SETTINGS: "/delivery/settings",
  TEMPLATE_LIBRARY: "/webbuilder/templates",
  BUYER_DASHBOARD_PROFILE: "/profile",
  BATCH_UPLOAD: "/batch_upload",
  SENTRO_ADVANCED: "/advanced",
  MEDIA_LIBRARY: "/media",
  ADD_IMAGES: "/media/add",
  ANALYTICS: "/analytics",
  SUBSCRIPTION: "/subscription",
  WAYBILL: "/delivery/waybill",
  DISBURSEMENT_HISTORY: "/disbursement_history",
  SUBSCRIPTION_SUCCESS: "/subscription/success",
  QUICK_ORDER_SETTINGS: "/webbuilder/quick_order_settings"
};

export default Path;
