// Add path constants here
const Paths = {
  // Marketplace
  landing: "/enterprise",
  demo: "/enterprise/demo",
  sell: "/enterprise/sell",
  login: "/enterprise/:enterprise_id/login",
  signup: "/enterprise/:enterprise_id/signup",
  forgot_password: "/enterprise/:enterprise_id/forgot_password",
  input_password: "/enterprise/:enterprise_id/input_password",
  home: "/enterprise/:enterprise_id/",
  categories: "/enterprise/:enterprise_id/categories",
  products: "/enterprise/:enterprise_id/products",
  merchant_directory: "/enterprise/:enterprise_id/directory",
  about: "/enterprise/:enterprise_id/about",
  product: "/enterprise/:enterprise_id/product/:product_id",
  merchant_application: "/enterprise/:enterprise_id/apply",
  cart: "/enterprise/:enterprise_id/cart",
  customer_details: "/enterprise/:enterprise_id/details",
  marketplace_delivery: "/enterprise/:enterprise_id/delivery",
  marketplace_payment: "/enterprise/:enterprise_id/payment",
  marketplace_checkout: "/enterprise/:enterprise_id/checkout",
  wishlist: "/enterprise/:enterprise_id/wishlist",
  create_wishlist: "/enterprise/:enterprise_id/wishlist/create",
  public_wishlists: "/enterprise/:enterprise_id/wishlist/public_wishlists",
  wishlist_detailed: "/enterprise/:enterprise_id/wishlist/:ref_code",

  // Dashboard
  admin_login: "/enterprise/:enterprise_id/admin/login",
  admin_forgot_password: "/enterprise/:enterprise_id/admin/forgot_password",
  dashboard: "/enterprise/:enterprise_id/dashboard",
  manage_marketplace: "/enterprise/:enterprise_id/dashboard/manage",
  customize: "/enterprise/:enterprise_id/dashboard/customize",
  merchants: "/enterprise/:enterprise_id/dashboard/merchants",
  buyers: "/enterprise/:enterprise_id/dashboard/buyers",
  edit_profile: "/enterprise/:enterprise_id/dashboard/profile",
  custom_domain: "/enterprise/:enterprise_id/dashboard/domain",
  analytics: "/enterprise/:enterprise_id/dashboard/analytics",

  // Buyer Dashboard
  buyer_dashboard: "/enterprise/:enterprise_id/buyer_dashboard/",
  buyer_purchased: "/enterprise/:enterprise_id/buyer_dashboard/purchased",
  cart_view_order: "/enterprise/:enterprise_id/buyer_dashboard/view_cart",
  purchased_view_order:
    "/enterprise/:enterprise_id/buyer_dashboard/purchased/view_order",
  buyer_delivery_details: "/enterprise/:enterprise_id/buyer_dashboard/details",
  delivery: "/enterprise/:enterprise_id/buyer_dashboard/delivery",
  payment: "/enterprise/:enterprise_id/buyer_dashboard/payment",
  checkout: "/enterprise/:enterprise_id/buyer_dashboard/checkout",
  buyer_dashboard_wishlist:
    "/enterprise/:enterprise_id/buyer_dashboard/wishlist",

  merchant: "/enterprise/:enterprise_id/:merchant_id",
};

export default Paths;
