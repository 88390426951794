import React from "react";
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  DialogContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import Text from "../../../../../common/text";
import { Radio } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import ErrorIcon from "@material-ui/icons/Error";
import {
  createVoucher,
  getStoreProductCategories
} from "../webBuilder/websiteBuilderActions";
const DialogTransition = props => <Slide direction="up" {...props} />;

const useStyles = makeStyles(theme => ({
  appbar: {
    position: "fixed",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  closeBtn: { position: "absolute", left: 15 },
  radioStyles: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  icon: {
    width: "100%",
    textAlign: "center",
    color: "#ee2545",
    fontSize: 100
  }
}));

const UnsavedChangesModal = props => {
  const {
    initialValues,
    open,
    setOpen,
    setOpenCreateVoucher,
    values,
    setValues
  } = props;

  const classes = useStyles();

  function handleCancel() {
    setOpen(false);
    setOpenCreateVoucher(false);
    setValues(initialValues);
  }
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogContent style={{ padding: 20 }}>
        <div className={classes.form}>
          <ErrorIcon className={classes.icon} />
          <div style={{ textAlign: "center", marginTop: "2vh" }}>
            <div style={{ fontSize: 18, color: "#54575f", fontWeight: 600 }}>
              Unsaved Changes
            </div>
            <div style={{ fontSize: 14, marginTop: "2vh", color: "#54575f" }}>
              You will lose any unsaved changes in this form.
            </div>
            <div style={{ fontSize: 14, color: "#54575f", padding: "0 10px" }}>
              Are you sure you want to navigate away from this forms now?
            </div>
            <div
              style={{
                marginTop: "2vh",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{ width: "50%", fontSize: 12, height: 40 }}
                onClick={() => setOpen(false)}
              >
                No, take me back
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                style={{
                  width: "50%",
                  fontSize: 12,
                  height: 40,
                  marginLeft: 10
                }}
                onClick={handleCancel}
              >
                Yes, continue
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const MobileCreateVoucher = props => {
  const {
    open,
    setOpen,
    values,
    setValues,
    state,
    setState,
    categories
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const vouchers = useSelector(
    state => state.mobileDashboard.voucherListFilter?.list
  );
  const stores = useSelector(state => state.mobileDashboard.stores);
  const filters = useSelector(state => state.mobileDashboard.voucherFilters);
  const productCategories = useSelector(
    state => state.mobileDashboard.product_categories
  );
  const [openUnsaveChangesModal, setOpenUnsaveChangesModal] = React.useState(
    false
  );

  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [error, setError] = React.useState({
    voucher_code_error: "",
    typeError: "",
    discount_value_error: "",
    applies_to_error: "",
    category_error: "",
    minimum_requirements_error: "",
    minimum_purchase_error: "",
    usage_limit_error: "",
    usage_limit_value_error: ""
  });

  const initialValues = {
    voucher_code: "",
    voucher_type: "",
    discount_value: "",
    applies_to: "",
    category: "",
    minimum_requirements: "",
    minimum_purchase_amount: "",
    usage_limit_to_one: false,
    usage_limit_no_times: false,
    usage_limit_value: 0,
    start_datetime: new Date(),
    start_time: moment()
      .toDate()
      .getTime(),
    end_datetime: new Date(),
    end_time: moment()
      .toDate()
      .getTime()
  };

  function setValueChanges(value, name) {
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function handleUsageLimit(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.checked
    });
  }

  function handleCancel() {
    if (
      values.voucher_code ||
      values.voucher_type ||
      values.discount_value ||
      values.applies_to ||
      values.minimum_requirements ||
      values.minimum_purchase_amount ||
      values.usage_limit_to_one ||
      values.usage_limit_no_times
    ) {
      setOpenUnsaveChangesModal(true);
    } else {
      setOpen(false);
      setValues(initialValues);
      window.scrollTo(0, 0);
    }
  }

  async function handleSave() {
    let params = {
      store: stores ? stores[0].uuid : "",
      voucher_code: values.voucher_code,
      voucher_type: values.voucher_type,
      discount_value: parseFloat(values.discount_value).toFixed(2),
      applies_to: values.applies_to,
      category: values.category,
      minimum_requirements: values.minimum_requirements,
      minimum_purchase_amount: values.minimum_purchase_amount,
      usage_limit_to_one: values.usage_limit_to_one,
      usage_limit_no_times: values.usage_limit_no_times,
      usage_limit_value:
        values.usage_limit_value === "" ? 0 : values.usage_limit_value,
      start_datetime: moment(values.start_datetime).format(),
      end_datetime: moment(values.end_datetime).format(),
      is_active: true
    };

    const res = await dispatch(createVoucher(params, filters, stores));

    try {
      if (res.status === 201) {
        window.scrollTo(0, 0);
        setOpen(false);
        setState({
          ...state,
          openAlert: true,
          alertType: "success",
          alertMessage: "Voucher successfully created!"
        });
        setValues(initialValues);
      }
    } catch {
      window.scrollTo(0, 0);
    }
  }

  React.useEffect(() => {
    for (let e in error) {
      if (error[e].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [error]);

  React.useEffect(() => {
    setIsFormEnough(
      values.voucher_code &&
        values.voucher_type &&
        values.discount_value &&
        values.applies_to &&
        values.minimum_requirements
    );
  }, [values]);

  React.useEffect(() => {
    if (values.minimum_requirements === "None") {
      setError({
        ...error,
        minimum_purchase_error: ""
      });
    }
  }, [values.minimum_requirements]);

  React.useEffect(() => {
    if (values.applies_to === "All Products") {
      setValues({ ...values, category: "" });
    } else if (values.applies_to === "Specific Category") {
      dispatch(getStoreProductCategories(stores[0].uuid));
    }
  }, [values.applies_to]);

  React.useEffect(() => {
    if (!values.usage_limit_no_times) {
      setError({
        ...error,
        usage_limit_value_error: ""
      });
    }
  }, [values.usage_limit_no_times]);

  React.useEffect(() => {
    if (
      values.voucher_type === "percentage" &&
      (values.discount_value > 100 || values.discount_value <= 0)
    ) {
      setError({
        ...error,
        discount_value_error: "Please enter a valid percentage value"
      });
    } else {
      setError({
        ...error,
        discount_value_error: ""
      });
    }
  }, [values.voucher_type, values.discount_value]);

  React.useEffect(() => {
    if (values.usage_limit_value === "") {
      setValueChanges(0, "usage_limit_value");
    }
  }, [open]);

  const types = [
    { label: "Discount (Percentage %)", value: "percentage" },
    { label: "Discount (Fixed Amount)", value: "fixed_amount" }
    // { label: "Discount (Free shipping)", value: "free_shipping" }
  ];

  const minimumRequirements = [
    { value: "None" },
    { value: "Minimum Purchase Amount (PHP)" },
    { value: "Minimum Quantity of items" }
  ];

  const usageLimit = [
    // {
    //   label: "Limit to 1 customer per voucher",
    //   value: values.usage_limit_to_one,
    //   name: "usage_limit_to_one"
    // },
    {
      label: "Limit to number of times that it can be used in total",
      value: values.usage_limit_no_times,
      name: "usage_limit_no_times"
    }
  ];

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.closeBtn}
              onClick={handleCancel}
            >
              <CloseIcon color="primary" />
            </IconButton>
            <div className={classes.header}>Create a Voucher</div>
          </Toolbar>
        </AppBar>
        <div style={{ paddingTop: "8vh" }}>
          <div style={{ padding: 20 }}>
            <div
              style={{ borderBottom: "1px solid #d1d5dd", paddingBottom: 20 }}
            >
              <Text textType="p3_Semibold" content="Voucher Code" />
              <TextField
                id="voucher_code"
                variant="outlined"
                fullWidth
                color="secondary"
                margin="dense"
                placeholder="E.g. COOKIEVERSE"
                style={{ marginTop: 0 }}
                inputProps={{ maxLength: 64 }}
                value={values.voucher_code}
                error={error.voucher_code_error}
                helperText={error.voucher_code_error}
                onChange={e => {
                  const list = vouchers.length
                    ? vouchers.map(i => i.voucher_code.toLowerCase())
                    : [];
                  setValueChanges(e.target.value, "voucher_code");
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      voucher_code_error: "This field is required."
                    });
                  } else if (list.includes(e.target.value.toLowerCase())) {
                    setError({
                      ...error,
                      voucher_code_error:
                        "Voucher code is already taken. Please try again."
                    });
                  } else {
                    setError({ ...error, voucher_code_error: "" });
                  }
                }}
                onBlur={e => {
                  const list = vouchers.length
                    ? vouchers.map(i => i.voucher_code.toLowerCase())
                    : [];
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      voucher_code_error: "This field is required."
                    });
                  } else if (list.includes(e.target.value.toLowerCase())) {
                    setError({
                      ...error,
                      voucher_code_error:
                        "Voucher code is already taken. Please try again."
                    });
                  } else {
                    setError({ ...error, voucher_code_error: "" });
                  }
                }}
              />
            </div>
            <div
              style={{ padding: "20px 0", borderBottom: "1px solid #d1d5dd" }}
            >
              <Text textType="p3_Semibold" content="Type" />
              <Radio.Group
                id="type"
                className={classes.radioStyles}
                value={values.voucher_type}
                onChange={e => setValueChanges(e.target.value, "voucher_type")}
              >
                {types.map(item => {
                  return (
                    <Radio value={item.value} style={{ marginBottom: 15 }}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
              <div
                style={{
                  marginTop: 10,
                  display: values.voucher_type ? "" : "none"
                }}
              >
                <Text textType="p3_Semibold" content="Discount Value" />
                <TextField
                  id="discount_value"
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  type="number"
                  inputProps={{ min: 0 }}
                  InputProps={
                    values.voucher_type === "percentage"
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }
                      : ""
                  }
                  value={values.discount_value}
                  error={error.discount_value_error}
                  helperText={error.discount_value_error}
                  onChange={e => {
                    let input = e.target.value;
                    setValueChanges(e.target.value, "discount_value");
                    if (input === "") {
                      setError({
                        ...error,
                        discount_value_error: "This field is required."
                      });
                    } else if (
                      values.voucher_type === "percentage" &&
                      (e.target.value > 100 || e.target.value <= 0)
                    ) {
                      setError({
                        ...error,
                        discount_value_error:
                          "Please enter a valid percentage value"
                      });
                    } else {
                      setError({ ...error, discount_value_error: "" });
                    }
                  }}
                  onBlur={e => {
                    if (e.target.value === "") {
                      setError({
                        ...error,
                        discount_value_error: "This field is required."
                      });
                    } else if (
                      values.voucher_type === "percentage" &&
                      (e.target.value > 100 || e.target.value <= 0)
                    ) {
                      setError({
                        ...error,
                        discount_value_error:
                          "Please enter a valid percentage value"
                      });
                    } else {
                      setError({ ...error, discount_value_error: "" });
                    }
                  }}
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <Text textType="p3_Semibold" content="Applies To" />
                <Radio.Group
                  id="applies_to"
                  className={classes.radioStyles}
                  value={values.applies_to}
                  onChange={e => setValueChanges(e.target.value, "applies_to")}
                >
                  {["All Products"].map(item => {
                    return (
                      <Radio value={item} style={{ marginBottom: 15 }}>
                        {item}
                      </Radio>
                    );
                  })}
                </Radio.Group>
                <div
                  style={{
                    display:
                      values.applies_to === "Specific Category" ? "" : "none"
                  }}
                >
                  <Text textType="p3_Semibold" content="Choose category" />
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="category"
                      color="secondary"
                      value={values.category}
                      onChange={e =>
                        setValueChanges(e.target.value, "category")
                      }
                    >
                      {productCategories.categories
                        .filter(item => item != "")
                        .map(item => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div
              style={{ padding: "20px 0", borderBottom: "1px solid #d1d5dd" }}
            >
              <Text textType="p3_Semibold" content="Minimum Requirements" />
              <Radio.Group
                id="minimum_requirements"
                className={classes.radioStyles}
                value={values.minimum_requirements}
                onChange={e => {
                  setValueChanges(e.target.value, "minimum_requirements");
                  setValueChanges(0, "minimum_purchase_amount");
                }}
              >
                {minimumRequirements.map(item => {
                  return (
                    <Radio value={item.value} style={{ marginBottom: 15 }}>
                      {item.value}
                    </Radio>
                  );
                })}
              </Radio.Group>
              <div
                style={{
                  display:
                    values.minimum_requirements === "None" ||
                    values.minimum_requirements === ""
                      ? "none"
                      : ""
                }}
              >
                <Text
                  textType="p3_Semibold"
                  content={
                    values.minimum_requirements === "Minimum Quantity of items"
                      ? "Minimum Quantity of items"
                      : "Minimum Purchase Amount (PHP)"
                  }
                />
                <TextField
                  id="minimum_purchase_amount"
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  type="number"
                  fullWidth
                  inputProps={{ min: 0 }}
                  InputProps={
                    values.minimum_requirements === "Minimum Quantity of items"
                      ? ""
                      : {
                          startAdornment: (
                            <InputAdornment position="start">
                              PHP
                            </InputAdornment>
                          )
                        }
                  }
                  error={error.minimum_purchase_error}
                  helperText={error.minimum_purchase_error}
                  value={values.minimum_purchase_amount}
                  onChange={e => {
                    let input = e.target.value;
                    setValueChanges(e.target.value, "minimum_purchase_amount");
                    if (input === "") {
                      setError({
                        ...error,
                        minimum_purchase_error: "This field is required."
                      });
                    } else {
                      setError({ ...error, minimum_purchase_error: "" });
                    }
                  }}
                  onBlur={e => {
                    if (e.target.value === "") {
                      setError({
                        ...error,
                        minimum_purchase_error: "This field is required."
                      });
                    } else {
                      setError({ ...error, minimum_purchase_error: "" });
                    }
                  }}
                />
              </div>
            </div>
            <div
              style={{ padding: "20px 0", borderBottom: "1px solid #d1d5dd" }}
            >
              <Text textType="p3_Semibold" content="Usage Limit" />
              <FormGroup id="usage_limit">
                {usageLimit.map(item => {
                  return (
                    <FormControlLabel
                      style={{ marginRight: 0, display: "flex" }}
                      control={
                        <Checkbox
                          checked={item.value}
                          name={item.name}
                          onChange={handleUsageLimit}
                          color="primary"
                        />
                      }
                      label={
                        <div style={{ fontSize: 14, color: "#54575f" }}>
                          {item.label}
                        </div>
                      }
                    />
                  );
                })}
              </FormGroup>
              <div
                style={{ display: values.usage_limit_no_times ? "" : "none" }}
              >
                <Text textType="p3_Semibold" content="Usage Limit" />
                <TextField
                  id="usage_limit_value"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  color="secondary"
                  value={values.usage_limit_value}
                  error={error.usage_limit_value_error}
                  helperText={error.usage_limit_value_error}
                  onChange={e => {
                    let input = e.target.value;
                    setValueChanges(e.target.value, "usage_limit_value");
                    if (input === "") {
                      setError({
                        ...error,
                        usage_limit_value_error: "This field is required."
                      });
                    } else {
                      setError({ ...error, usage_limit_value_error: "" });
                    }
                  }}
                  onBlur={e => {
                    let input = e.target.value;
                    if (input === "") {
                      setError({
                        ...error,
                        usage_limit_value_error: "This field is required."
                      });
                    } else {
                      setError({ ...error, usage_limit_value_error: "" });
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ padding: "20px 0" }}>
              <Text textType="p3_Semibold" content="Active Dates" />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div style={{ marginTop: 15, display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <div className={classes.label1}>Start Date/Time</div>
                    <KeyboardDateTimePicker
                      id="start_date"
                      label=""
                      margin="dense"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY hh:mm A"
                      orientation="portrait"
                      color="secondary"
                      disablePast
                      style={{ marginTop: 0, width: "100%" }}
                      InputAdornmentProps={{ position: "start" }}
                      value={values.start_datetime}
                      onChange={e => setValueChanges(e, "start_datetime")}
                    />
                  </div>
                  {/* 
                  <div style={{ marginLeft: 20 }}>
                    <div className={classes.label1}>End Date/Time</div>
                    <KeyboardDateTimePicker
                      id="end_date"
                      label=""
                      margin="dense"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY hh:mm A"
                      orientation="portrait"
                      color="secondary"
                      minDate={values.start_datetime}
                      style={{ marginTop: 0 }}
                      InputAdornmentProps={{ position: "start" }}
                      value={values.end_datetime}
                      onChange={e => setValueChanges(e, "end_datetime")}
                    />
                  </div> */}
                </div>
                <div style={{ marginTop: 15, display: "flex" }}>
                  {/* <div>
                    <Text textType="p3_Semibold" content="End Date" />
                    <KeyboardDatePicker
                      id="end_date"
                      label=""
                      margin="dense"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY"
                      orientation="portrait"
                      color="secondary"
                      style={{ marginTop: 0 }}
                      InputAdornmentProps={{ position: "start" }}
                      value={values.end_date}
                      onChange={e => setValueChanges(e, "end_date")}
                    />
                  </div> */}
                  <div style={{ width: "100%" }}>
                    <div className={classes.label1}>End Date/Time</div>
                    <KeyboardDateTimePicker
                      fullWidth
                      id="end_date"
                      label=""
                      margin="dense"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY hh:mm A"
                      orientation="portrait"
                      color="secondary"
                      minDate={values.start_datetime}
                      style={{ marginTop: 0 }}
                      InputAdornmentProps={{ position: "start" }}
                      value={values.end_datetime}
                      onChange={e => setValueChanges(e, "end_datetime")}
                    />
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                id="save_btn"
                variant="contained"
                color="primary"
                disableElevation
                fullWidth
                disabled={
                  !isFormEnough ||
                  formHasErrors ||
                  (values.minimum_requirements !== "None" &&
                    !values.minimum_purchase_amount)
                    ? true
                    : false ||
                      (!values.usage_limit_no_times &&
                        !values.usage_limit_to_one)
                    ? true
                    : false ||
                      (values.usage_limit_no_times &&
                        (values.usage_limit_value === "" ||
                          values.usage_limit_value <= 0))
                    ? true
                    : false ||
                      (values.applies_to === "Specific Category" &&
                        values.category === "")
                    ? true
                    : false
                }
                onClick={handleSave}
              >
                Save
              </Button>

              <Button
                id="cancel_btn"
                variant="outlined"
                color="primary"
                disableElevation
                fullWidth
                style={{ marginTop: 12 }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <UnsavedChangesModal
          id="unsaved_changes_modal"
          initialValues={initialValues}
          values={values}
          setValues={setValues}
          open={openUnsaveChangesModal}
          setOpen={setOpenUnsaveChangesModal}
          setOpenCreateVoucher={setOpen}
        />
      </Dialog>
    </div>
  );
};

export default MobileCreateVoucher;
