import { makeStyles } from "@material-ui/core/styles";
const formStyles = makeStyles(theme => ({
  label: {
    marginBottom: "1vh"
  },
  input: {
    marginBottom: "2vh"
  },
  menu: {
    width: 200
  },
  btnStyles: {
    maxWidth: "40px",
    maxHeight: "40px",
    minWidth: "40px",
    minHeight: "40px",
    margin: "0 10px 10px"
  }
}));

export default formStyles;
