import React from "react";
import logo from "../../../../assets/images/sentro_logomark3.png";
import { Dialog, Button, Zoom } from "@material-ui/core";
import Path from "../../../../Paths";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const Transition = props => <Zoom direction="up" {...props} />;
export default function FreeTrialModal(props) {
  const history = useHistory();
  const subscription = useSelector(state => state.subscription.subscription);
  return (
    <div>
      <Dialog
        id="dialog"
        fullWidth
        maxWidth="xs"
        open={props.open}
        TransitionComponent={Transition}
      >
        <div className="d-flex justify-content-center py-4 px-md-5 px-3">
          <div className="text-center">
            <img
              src={logo}
              alt="logo"
              width="auto"
              height="50"
              className="mt-4"
            />
            <div
              style={{ fontSize: 18, fontWeight: 600, color: "#2b2d32" }}
              className="my-3"
            >
              Your current account is limited
            </div>
            <div style={{ fontSize: 14, color: "#54575f" }} className="px-3">
              This feature is available when you upgrade to a{" "}
              <span style={{ fontWeight: 500 }}>Sentro Advanced</span> account.
            </div>
            <div className="d-flex mt-4 mb-3">
              <Button
                id="close_dialog"
                variant="outlined"
                color="primary"
                className="w-50 mr-2"
                style={{ height: 40, fontSize: 12 }}
                onClick={() => props.setOpen(false)}
              >
                No, take me back
              </Button>
              <Button
                id="free_trial_btn"
                disableElevation
                variant="contained"
                color="primary"
                className="w-50"
                onClick={() => history.push(Path.SENTRO_ADVANCED)}
                style={{ height: 40, fontSize: 12 }}
              >
                {!subscription.subscription_type &&
                !subscription.free_trial_availed
                  ? "Start free trial"
                  : "Sign up for advanced"}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
