import React from "react";
import { Zoom, IconButton, Button, Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { goToCompleteProfile } from "../../action";
const ZoomTransition = props => <Zoom direction="up" {...props} />;
export const RedirectDialog = props => {
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const kyc = useSelector(state => state.payments.buxStatus);
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={ZoomTransition}
    >
      <div className="d-flex justify-content-end">
        <IconButton onClick={() => setOpen(false)}>
          <Close color="secondary" />
        </IconButton>
      </div>
      <div className="px-4 pb-4">
        <strong style={{ fontSize: 20, color: "#002c42" }}>
          This page will redirect to your BUX dashboard.
        </strong>
        <Button
          fullWidth
          disableElevation
          color="primary"
          variant="contained"
          className="mt-4"
          onClick={() => {
            dispatch(goToCompleteProfile(user.id, kyc));
            setOpen(false);
          }}
        >
          OK
        </Button>
      </div>
    </Dialog>
  );
};
