import React from "react";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles(theme => ({
  field: {
    padding: 4,
    borderRadius: 4,
    marginRight: 10,
    backgroundColor: "#fff"
  }
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #2b2d32",
    fontSize: 16,
    padding: "5px 25px 6px 10px"
  }
}))(InputBase);
export default function DateDropdown(props) {
  const classes = useStyles();
  const { state, setState } = props;

  const checkLeapYear = year => {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
  };

  const getMonthLength = value => {
    let date = new Date();
    let currentYear = date.getFullYear();
    let month = parseInt(value) + 1;
    const monthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return checkLeapYear(state.year ? state.year : currentYear) &&
      state.month === "1"
      ? 29
      : month
      ? monthLengths[month - 1]
      : 1;
  };
  const days = [
    ...new Array(getMonthLength(state.month ? state.month : 0))
  ].map((val, i) => i + 1);

  React.useEffect(() => {
    let month = parseInt(state.month) + 1;
    if (state.month !== "" && state.day !== "" && state.year !== "") {
      setState({
        ...state,
        birthdate: new Date(state.year + "-" + month + "-" + state.day)
      });
    }
  }, [state.year, state.month, state.day]);

  return (
    <div>
      <MonthPicker
        defaultValue={"Month"}
        short
        value={state.month}
        year={state.year}
        onChange={month => setState({ ...state, month: month })}
        classes={classes.field}
      />
      <FormControl
        variant="outlined"
        margin="dense"
        style={{ background: "white", margin: "0px 10px 0 0" }}
      >
        <NativeSelect
          IconComponent={ExpandMoreIcon}
          input={<BootstrapInput />}
          color="secondary"
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          value={state.day}
          onChange={e => setState({ ...state, day: e.target.value })}
        >
          <option value="Day">Day</option>
          {days.map(value => (
            <option value={value}>{value}</option>
          ))}
        </NativeSelect>
      </FormControl>
      {/* <DayPicker
        defaultValue={"Day"}
        month={state.month}
        year={state.year}
        value={state.day}
        onChange={day => setState({ ...state, day: day })}
        classes={classes.field}
      /> */}
      <YearPicker
        defaultValue={"Year"}
        reverse
        value={state.year}
        onChange={year => setState({ ...state, year: year })}
        classes={classes.field}
      />
    </div>
  );
}
