import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Slide,
  IconButton,
  TextField,
  Button,
  Tooltip
} from "@material-ui/core";
import moment from 'moment';
import { Close } from "@material-ui/icons";
import MediaActions from '../redux/actions';
import ImageCropper from '../../reduxFields/utilities/imageCropper';

const DialogTransition = props => <Slide direction="up" {...props} />;
export default function EditImage(props) {
  const { edit, setEdit } = props;
  const useStyles = makeStyles(theme => ({
    form: {
      padding: 24,
      backgroundColor: "#fff"
    },
    img: {
      width: "100%",
      height: 300,
      objectFit: "cover",
      cursor: "pointer"
    },
    label: {
      fontSize: 12,
      color: "#54575f"
    },
    btnSave: {
      bottom: 0,
      left: 0,
      right: 0
    },
    tag: {
      backgroundColor: "#eee",
      height: 32,
      fontSize: 10,
      fontWeight: "normal"
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tag, setTag] = React.useState("");
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    if (edit.item) {
      setTags(edit.item.tags);
    }
  }, [edit.open]);

  const removeTag = (item) => {
    const n_tags = tags.filter(i => i !== item);
    setTags(n_tags);
  }

  const addTag = () => {
    setTags(tags ? [...tags, tag]: [tag]);
    setTag("");
  }

  // cropper
  const [cropOpen, setCropOpen] = React.useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = React.useState("");
  const [croppedImageFile, setCroppedImageFile] = React.useState();
  const [imageUrlForCropping, setImageUrlForCropping] = React.useState('');
  const onCropSubmit = (file) => {
    setCroppedImageFile(file);
    setCroppedImageUrl(URL.createObjectURL(file));
    setCropOpen(false);
  }
  const imageOnClick = () => {
    setCropOpen(true);
    setImageUrlForCropping(edit.item.image);
  }


  // submission
  const editImage = async () => {
    await dispatch(MediaActions.editMediaImage({
      id: edit.item.id,
      tags: tags,
      image: croppedImageFile
    }));
    setEdit({ open: false, item: "" })
  }

  return (
    <div>
      <ImageCropper
        open={cropOpen}
        setOpen={setCropOpen}
        image={imageUrlForCropping} 
        defaultCropperOrientation={"square"}
        onSubmit={onCropSubmit}>
      </ImageCropper>
      <Dialog
        className="edit-image-modal"
        maxWidth="sm"
        open={edit.open}
        TransitionComponent={DialogTransition}
      >
        <div className={classes.form}>
          <div className="d-flex justify-content-between align-items-center">
            <strong style={{ fontSize: 18, color: "#2b2d32" }}>
              Edit image details
            </strong>
            <IconButton onClick={() => setEdit({ open: false, item: "" })}>
              <Close color="secondary" />
            </IconButton>
          </div>
          <div
            className="mt-3 d-flex col-12 p-0"
            style={{ position: "relative" }}
          >
            <div className="col-6 p-0">
              <Tooltip title="Click to crop image" placement="top">
                <img
                  src={croppedImageUrl || edit.item.image}
                  alt={edit.item.image}
                  className={classes.img}
                  onClick={imageOnClick}
                />
              </Tooltip>
            </div>
            <div
              className="col-6 flex-wrap"
              style={{ wordBreak: "break-word" }}
            >
              <p className="mb-2">
                <strong className={classes.label}>
                  {edit.item?.image?.split("/").splice(-1) || ""}
                </strong>
              </p>
              <div className={`${classes.label} `}>{moment(edit.item.created_on).format('MMMM DD YYYY')}</div>
              <div className={`${classes.label} `}>{`${(edit.item.size/1000000).toFixed(4)} MB`}</div>
              <div className={`${classes.label} `}>{`${edit.item.width} x ${edit.item.height} pixels`}{edit.item.pixels}</div>
              <div className="mt-3">
                <div className={classes.label}>Add tags</div>
                <Tooltip title={tags?.length >= 5 ? "Maximum of 5 tags": ""} placement="top">
                  <div className="d-flex">
                    <TextField
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      className="m-0"
                      onChange={(e) => setTag(e.target.value)}
                      value={tag}
                    />
                    
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ height: 40 }}
                        className="ml-2"
                        disabled={tags ? tags.includes(tag) || tags.length >= 5: false}
                        onClick={addTag}
                      >
                        Add
                      </Button>
                  </div>
                </Tooltip>
                <div className="mt-2">
                  {tags ? tags.map(item => (
                    <Button
                      variant="outlined"
                      color="secondary"
                      endIcon={<Close style={{ fontSize: 12 }} />}
                      className={`${classes.tag} mr-2 mb-2`}
                      onClick={() => removeTag(item)}
                    >
                      {item}
                    </Button>
                  )): ""}
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "5vh" }}>
            <Button
              disableElevation
              fullWidth
              color="primary"
              variant="contained"
              style={{ height: 42 }}
              onClick={editImage}
            >
              Save changes
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
