import React from "react";
import Utils from "../../../common/utils";

export function getPrice(item, param = false) {
  const commafy = price => Utils.commafy(parseFloat(price));
  if (item.has_variants) {
    const selection_prices = item.selections.map(
      selection =>
        parseFloat(selection.discounted_price) || parseFloat(selection.price)
    );
    const min = Math.min(...selection_prices);
    const max = Math.max(...selection_prices);
    return min === max ? min : `${commafy(min)} - ${commafy(max)}`;
  } else {
    return param?.original_price
      ? commafy(item.price)
      : commafy(item.discounted_price ? item.discounted_price : item.price);
  }
}

export default function PriceDisplay(props) {
  const has_slashed_price =
    props.item?.discounted_price && !props.item?.has_variants;

  return props.productSelection ? (
    <div
      style={{
        fontSize: props.font_size || 16,
        fontWeight: 500,
        color: "#2b2d32"
      }}
    >{`₱ ${Utils.commafy(
      props.productSelection.discounted_price || props.productSelection.price
    )}`}</div>
  ) : (
    <div style={{ fontSize: props.font_size || 16 }}>
      {has_slashed_price ? (
        <div>{`₱ ${getPrice(props.item)}`}</div>
      ) : (
        `₱ ${getPrice(props.item, { original_price: true })}`
      )}
    </div>
  );
}
