import React from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Button,
  Popover,
  MenuItem,
  useMediaQuery,
  IconButton
} from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { confirmLogOut } from "../../auth/authActions";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import sentro_logo from "../../../assets/images/sentro_logomark3.png";
import MoreIcon from "@material-ui/icons/Menu";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import BuyerSideMenu from "./buyerSideMenu";
import { useLocation } from "react-router-dom";
import history from "../../../store/history";
import Path from "../../../Paths";
const BuyerAppbar = props => {
  const location = useLocation();
  const currentLoc = location.pathname;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "#eee",
      width: "100%",
      boxShadow: "none",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#fff",
        borderBottom: "1px solid #d1d5dd"
      }
    },
    toolbar: {
      padding: "0 4vw",
      justifyContent: "flex-end",
      display: "flex",
      alignItems: "center"
    },
    name: {
      marginRight: "2vw",
      fontSize: 14,
      fontWeight: 600,
      textTransform: "capitalize",
      color: "#54575f"
    }
  }));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const first_name = data ? data.first_name : "";
  const [openDrawer, setOpenDrawer] = React.useState("");

  const toggleDrawer = () => {
    setOpenDrawer(false);
  };

  const handleOptions = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const dispatch = useDispatch();
  const popoverItems = [
    { label: "Profile", onClick: () => history.push("/profile") },
    { label: "Help Center" },
    { label: "Logout", onClick: () => dispatch(confirmLogOut()) }
  ];

  const header_display = () => {
    if (currentLoc.includes("/my_cart")) {
      return "My cart";
    } else if (currentLoc.includes("/purchased")) {
      return "Purchased";
    }
  };

  return (
    <div style={{ zIndex: 1 }}>
      <AppBar position="fixed" className={classes.root}>
        {isMobile ? (
          <Toolbar className="d-flex align-items-center justify-content-between px-0">
            <div style={{ zIndex: 3000 }}>
              <IconButton onClick={() => setOpenDrawer(true)}>
                <MoreIcon color="secondary" />
              </IconButton>
              <BuyerSideMenu open={openDrawer} toggleDrawer={toggleDrawer} />
              <img
                onClick={() => history.push(Path.BUYER_DASHBOARD)}
                src={sentro_logo}
                width="30"
                height="auto"
                alt="logo"
              />
            </div>
            <strong
              className="text-center"
              style={{ color: "#002c42", position: "absolute", width: "100%" }}
            >
              {header_display()}
            </strong>
            <IconButton>
              <NotificationsNoneIcon color="secondary" />
            </IconButton>
          </Toolbar>
        ) : (
          <Toolbar className={classes.toolbar}>
            <div className={classes.name}>Hello, {first_name}!</div>
            <Button
              startIcon={
                <AccountCircleOutlinedIcon
                  color="primary"
                  style={{ fontSize: 24 }}
                />
              }
              endIcon={<ArrowDropDownIcon color="secondary" />}
              variant="text"
              onClick={handleOptions}
            />
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              {popoverItems.map(item => {
                return (
                  <MenuItem button onClick={item.onClick}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Popover>
          </Toolbar>
        )}
      </AppBar>
    </div>
  );
};

export default BuyerAppbar;
