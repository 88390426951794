import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOff, confirmLogOut } from "../../auth/authActions";
import DashboardServices from "../../dashboard/dashboardServices";
import { SellerLayout, BuyerLayout } from "./dashboardLayout";
import { useLocation } from "react-router";
import SubscriptionActions from "../../subscription/redux/actions";
import moment from "moment";
import { showAlert } from "../commonActions";
let PrivateLayout = props => {
  const location = useLocation();
  const dispatch = useDispatch();
  let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let TOKEN = sessionStorage.getItem("TOKEN");
  let getLanguage = localStorage.getItem("language");
  const visible_header_footer = useSelector(
    state => state.marketplace.visible_header_footer
  );
  const [language, setLanguage] = React.useState(getLanguage);

  function fireGTM(value) {
    let status = DashboardServices.goBuxStatus(value);
    let key = status.then(data => {
      let keys = data.data.kyc_level;
      if (typeof keys == "number") {
        console.log("trigger");
        window.dataLayer.push({
          event: "trackUserId",
          userId: data.id
        });
      }
    });
  }

  //   const common = useSelector(state => state.common);
  const initErrorStat = {
    showAlert: false,
    alertType: "error",
    message: ""
  };
  const [error, setError] = React.useState(initErrorStat);
  const plain = children => children;
  const is_plain_layout =
    location.pathname.includes("/chat-setup") ||
    location.pathname.includes("/facebook_pixel_setup");

  React.useEffect(() => {
    dispatch(SubscriptionActions.getSubscription());
  }, [location]);

  if (TOKEN != null && data != null) {
    fireGTM(data.id);

    const children = React.Children.map(props.children, (child, index) => {
      return React.cloneElement(child, {
        language: language
      });
    });
    return (data.is_seller && data.is_buyer) || data.is_facebookLogin ? (
      is_plain_layout ? (
        plain(children)
      ) : (
        <SellerLayout
          language={language}
          setLanguage={setLanguage}
          children={children}
        />
      )
    ) : (
      <BuyerLayout children={children} />
    );
  } else {
    setTimeout(() => {
      dispatch(confirmLogOut());
    }, 3000);
    return (
      <div>
        <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
          <div style={{ marginTop: "30vh" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <img
                width={200}
                height="auto"
                src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                fontSize: 18,
                fontWeight: 600
              }}
            >
              Sorry! You are not allowed to view this page. Please log-in to use
              Sentro again.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                fontSize: 16
              }}
            >
              You will be redirected to the Home Page shortly.
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PrivateLayout;
