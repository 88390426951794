import React from "react";
import Home from "./components/Home";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import StoreActions from './components/StoreActions';

import "./App.css";
import history from "./../../../store/history";

// export const storePaths = {
//   STORE_HOME: "/store",
//   STORE_PRODUCTS: "/store/products",
//   STORE_PRODUCT: "/store/product",
//   STORE_CART: "/store/cart",
//   STORE_CHECKOUT: "/store/cart"
// };

const LandingPage = props => {
  const dispatch = useDispatch();
  const params = useParams();
  const store = useSelector(state => state.store.store);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (store) {
      dispatch(StoreActions.getStoreProductCategories(store.uuid));
    }
  }, [store, dispatch]);

  return <Home id={params.id} />;
};

export default LandingPage;
