export const productPrice = (object) => {
  if (object.has_variants && object.productSelection) {
    if (object.productSelection.discounted_price) {
      return parseFloat(
        object.quantity * object.productSelection.discounted_price
      );
    } else {
      return parseFloat(object.quantity * object.productSelection.price);
    }
  } else {
    if (object.always_available || object.stock > 0) {
      if (object.discounted_price) {
        return parseFloat(object.quantity * object.discounted_price);
      } else {
        return parseFloat(object.quantity * object.price);
      }
    } else {
      return 0;
    }
  }
};

export const computeCartAmount = (items) => {
  return parseFloat(
    items.reduce((total, object) => parseFloat(total) + productPrice(object), 0)
  );
};

export const computeBuyerCartAmount = (items) => {
  return parseFloat(
    items.reduce(
      (total, object) =>
        parseFloat(total) +
        (object.product_data.always_available ||
        object.product_data.quantity > 0
          ? parseFloat(
              object.quantity *
                (object.product_data.discounted_price
                  ? object.product_data.discounted_price
                  : object.product_data.price)
            )
          : 0),
      0
    )
  );
};

export const computeVoucherDiscount = (voucher, cart_amount) => {
  let voucher_discount = 0;
  if (cart_amount) {
    if (voucher.voucher_type === "fixed_amount") {
      voucher_discount = parseFloat(voucher.discount_value);
    } else if (voucher.voucher_type === "percentage") {
      voucher_discount =
        (parseFloat(voucher.discount_value) / 100) * cart_amount;
    }
  }
  return voucher_discount ? voucher_discount.toFixed(2) : 0;
};

export const isEqual = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const isVariantCombinationExist = (productToAdd, existingProduct) => {
  let productToAddIds = productToAdd.variants.map((i) => i.id);
  let existingIds = existingProduct.variants
    ? existingProduct.variants.map((i) => i.id)
    : [];
  return isEqual(productToAddIds, existingIds);
};

export const add_to_cart = (productToAdd, cart_items) => {
  let alreadyListed = false;
  let registry_items = cart_items.filter((i) => i.registry);
  let personal_items = cart_items.filter((i) => !i.registry);
  for (let product of personal_items) {
    if (product["uuid"] === productToAdd["uuid"]) {
      if (productToAdd.has_variants && productToAdd.productSelection) {
        if (
          isVariantCombinationExist(
            productToAdd.productSelection,
            product.productSelection
          )
        ) {
          alreadyListed = true;
          product.quantity += productToAdd.quantity;
          product.special_instructions = productToAdd.special_instructions;
        }
      } else {
        alreadyListed = true;
        product.quantity += productToAdd.quantity;
        product.special_instructions = productToAdd.special_instructions;
      }
    }
  }

  return alreadyListed
    ? [...registry_items, ...personal_items]
    : [...registry_items, ...personal_items, productToAdd];
};

export const add_to_cart_wishlist = (productToAdd, cart_items) => {
  let alreadyListed = false;
  let registry_items = cart_items.filter((i) => i.registry);
  let personal_items = cart_items.filter((i) => !i.registry);
  for (let product of registry_items) {
    if (product.registry["id"] === productToAdd.registry["id"]) {
      if (product["uuid"] === productToAdd["uuid"]) {
        if (productToAdd.has_variants && productToAdd.productSelection) {
          if (
            isVariantCombinationExist(
              productToAdd.productSelection,
              product.productSelection
            )
          ) {
            alreadyListed = true;
            product.quantity += productToAdd.quantity;
          }
        } else {
          alreadyListed = true;
          product.quantity += productToAdd.quantity;
        }
      }
    }
  }

  return alreadyListed
    ? [...registry_items, ...personal_items]
    : [...registry_items, ...personal_items, productToAdd];
};

export const update_quantity = (
  productToUpdate,
  productSelection,
  cart_items
) => {
  let alreadyListed = false;
  let registry_items = cart_items.filter((i) => i.registry);
  let personal_items = cart_items.filter((i) => !i.registry);
  for (let product of personal_items) {
    if (product["uuid"] === productToUpdate["uuid"]) {
      if (productToUpdate.has_variants && productSelection) {
        if (
          isVariantCombinationExist(productSelection, product.productSelection)
        ) {
          alreadyListed = true;
          product.quantity = productToUpdate.quantity;
        }
      } else {
        alreadyListed = true;
        product.quantity = productToUpdate.quantity;
      }
    }
  }

  return alreadyListed
    ? [...registry_items, ...personal_items]
    : [...registry_items, ...personal_items, productToUpdate];
};

export const update_quantity_wishlist = (
  productToUpdate,
  productSelection,
  cart_items
) => {
  let alreadyListed = false;
  let registry_items = cart_items.filter((i) => i.registry);
  let personal_items = cart_items.filter((i) => !i.registry);
  for (let product of registry_items) {
    if (product.registry["id"] === productToUpdate.registry["id"]) {
      if (product["uuid"] === productToUpdate["uuid"]) {
        if (productToUpdate.has_variants && productSelection) {
          if (
            isVariantCombinationExist(
              productSelection,
              product.productSelection
            )
          ) {
            alreadyListed = true;
            product.quantity = productToUpdate.quantity;
          }
        } else {
          alreadyListed = true;
          product.quantity = productToUpdate.quantity;
        }
      }
    }
  }
  return alreadyListed
    ? [...registry_items, ...personal_items]
    : [...registry_items, ...personal_items, productToUpdate];
};

export const combine_same_products = (
  productToUpdate,
  productSelection,
  cart_items
) => {
  let registry_items = cart_items.filter((i) => i.registry);
  let personal_items = cart_items.filter((i) => !i.registry);
  let new_items = [];
  let index;
  for (let i in personal_items) {
    new_items.push({
      ...personal_items[i],
      index: parseInt(i),
    });
  }
  index = new_items
    .filter(
      (i) =>
        i.productSelection &&
        i.productSelection.id === productToUpdate.prevSelection.id
    )
    .map((i) => i.index);

  for (let product of personal_items) {
    if (product["uuid"] === productToUpdate["uuid"]) {
      if (productToUpdate.has_variants && productSelection) {
        if (
          isVariantCombinationExist(
            productSelection,
            product.productSelection ? product.productSelection : {}
          )
        ) {
          personal_items.splice(index, 1);
          product.variants = productToUpdate.variants;
          product.productSelection = productSelection;
          product.quantity =
            product.quantity + productToUpdate.quantity >
            product.productSelection.quantity
              ? product.productSelection.quantity <= 0
                ? product.quantity + productToUpdate.quantity
                : product.productSelection.quantity
              : product.quantity + productToUpdate.quantity;
        }
      }
    }
  }

  console.log(personal_items);
  return [...registry_items, ...personal_items];
};

export const compute_subtotal = (items) => {
  return parseFloat(
    items.reduce(
      (total, object) =>
        parseFloat(total) +
        parseFloat(
          object.quantity *
            (object.product_data.has_variants && object.productSelection
              ? object.productSelection.discounted_price
                ? object.productSelection.discounted_price
                : object.productSelection.price
              : object.product_data.always_available ||
                object.product_data.quantity > 0
              ? object.product_data.discounted_price
                ? object.product_data.discounted_price
                : object.product_data.price
              : 0)
        ),
      0
    )
  );
};

export const addFreebie = (itemToInsert, freebies) => {
  let alreadyListed = false;
  for (let freebie of freebies) {
    if (freebie["id"] === itemToInsert["id"]) {
      alreadyListed = true;
      freebie.freebie_quantity += itemToInsert.freebie_quantity;
      freebie.is_requirement_applied = itemToInsert.is_requirement_applied;
    }
  }

  return alreadyListed ? [...freebies] : [...freebies, itemToInsert];
};
