import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Divider,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import Blank from "../blank";
import ShowMoreText from "react-show-more-text";
import history from "../../../../../store/history";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import ShopeeIcon from "../../../../../assets/images/Shopee.svg";
import { ReactComponent as ShopeeIcon2 } from "../../../../../assets/images/shopee_logo.svg";
import { ReactComponent as Lazada } from "../../../../../assets/images/lazadaLogo.svg";
import Kumu from "../../../../../assets/images/kumuLogo.png";
import setDefaultImage from "../../../layout/mobile/webBuilder/common/setDefaultImage";
import { useTheme } from "@material-ui/styles";
const PreviewAboutTemplate = props => {
  let { isPreview = false, largeDesktopView } = props;
  let storeInfo = props.store;
  let color = props.store && props.store.color;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  function setImageSettings(name, value) {
    if (isPreview && props.setImageSettings) {
      props.setImageSettings(name, value);
    }
  }

  function createPreview(file) {
    var blob = new Blob([file], { type: file.type });
    var blobUrl = URL.createObjectURL(blob);

    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function() {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function() {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    return blobUrl;
  }

  var pinchPropsAbout = {
    position: "center",
    initialScale: "auto",
    allowEdit: isPreview ? true : false,
    zoomButtons: isPreview ? true : false,
    maxScale: 15,
    onImageStateChange: image_params => setImageSettings("about", image_params)
  };

  if (props.store.image_settings) {
    let { about } = props.store.image_settings;
    if (about) {
      let { top, left, scale } = about;
      delete pinchPropsAbout.position;
      pinchPropsAbout.initialTop = top;
      pinchPropsAbout.initialLeft = left;
      pinchPropsAbout.initialScale = scale || "auto";
    }
  }

  const getStyles = (template, theme) => {
    switch (template) {
      case "Classic":
        return {
          root: {
            width: "100%",
            height: "100vh",
            backgroundImage: props.store.aboutus_image
              ? `url(${
                  typeof props.store.aboutus_image === "object"
                    ? createPreview(props.store.aboutus_image)
                    : props.store.aboutus_image
                })`
              : `url(${setDefaultImage(
                  props.store.template,
                  "aboutus_image"
                )})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          },

          about: {
            display: "flex",
            justifyContent: "center",
            padding: "12vw",
            [theme.breakpoints.down("md")]: {
              padding: "25vh 15vw"
            },
            [theme.breakpoints.down("sm")]: {
              padding: "20vh 5vw"
            },
            [theme.breakpoints.down("xs")]: {
              padding: "18vh 5vw"
            }
          },
          box: {
            background: "#fff",
            maxWidth: largeDesktopView ? 600 : 480,
            minWidth: largeDesktopView ? 600 : 350,
            minHeight: 400,
            padding: "4vw",
            borderRadius: 8,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            [theme.breakpoints.down("sm")]: {
              padding: "4vw",
              minWidth: 320,
              minHeight: 340
            },

            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)"
          },
          overlay: {
            backgroundColor: "rgba(0,0,0,0.3)",
            width: "100%",
            height: "100vh"
          }
        };

      case "Contemporary":
        return {
          root: {
            width: "100%",
            height: "100vh",
            color: "#A86550",
            backgroundColor: color.primary.main,
            backgroundSize: "cover",
            justifyContent: "center",
            display: "flex",
            alignItems: "center"
          },

          about: {
            display: "flex",
            width: "100%"
          },
          box: {
            background: "#fff",
            width: "30vw",
            padding: "4vw",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              padding: "4vw"
            }
          },

          boxAbout: {
            display: "flex",
            justifyContent: "center",
            paddingTop: "calc(25vh - 80px)",
            paddingBottom: "calc(25vh - 80px)",
            [theme.breakpoints.down("md")]: {
              padding: "0px 85px",
              display: "flex"
            },
            [theme.breakpoints.down("sm")]: {
              padding: "0px 5px",
              display: "block"
            },
            [theme.breakpoints.down("xs")]: {
              padding: 0,
              display: "block"
            }
          },
          boxAbout2: {
            flexGrow: 1,
            backgroundColor: "#fff",
            padding: 60,
            width: "50%",
            [theme.breakpoints.down("sm")]: {
              width: "auto",
              padding: 20
            }
          },
          boxAboutImage: {
            flexGrow: 1,
            height: "50vh",
            // padding: 60,
            width: "50%",
            backgroundImage: props.store.aboutus_image
              ? `url(${
                  typeof props.store.aboutus_image === "object"
                    ? createPreview(props.store.aboutus_image)
                    : props.store.aboutus_image
                })`
              : `url(${setDefaultImage(
                  props.store.template,
                  "aboutus_image"
                )})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: "30vh"
            },
            [theme.breakpoints.down("xs")]: {
              height: "30vh",
              width: "100%"
            },
            position: "relative"
          },
          draggableAboutImage: {},
          aboutUs: {
            fontSize: 36,
            fontFamily: storeInfo && storeInfo.font.family,
            letterSpacing: 3,
            color: "#54575f",
            [theme.breakpoints.down("sm")]: {
              fontSize: 24
            }
          }
        };
      case "Minimalist":
        return {
          root: {
            height: "80vh",
            width: "100%",
            color: "#000",

            display: "flex",
            backgroundSize: "cover",
            [theme.breakpoints.down("sm")]: {
              display: "block",
              height: "auto"
            }
          },

          aboutImg: {
            flexGrow: 1,

            // padding: 90,
            width: "50%",
            backgroundPosition: "center",
            backgroundImage: props.store.aboutus_image
              ? `url(${
                  typeof props.store.aboutus_image === "object"
                    ? createPreview(props.store.aboutus_image)
                    : props.store.aboutus_image
                })`
              : `url(${setDefaultImage(
                  props.store.template,
                  "aboutus_image"
                )})`,
            backgroundSize: "cover",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: "35vh"
            }
          },

          about: {
            flexGrow: 1,
            backgroundColor: color && color.primary.main,
            padding: 70,
            width: "50%",
            [theme.breakpoints.down("sm")]: {
              padding: "50px 30px",
              width: "auto",
              minHeight: 490
            }
          },
          box: {
            background: "#fff",
            width: "30vw",
            padding: "4vw",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              padding: "4vw"
            }
          }
        };

      case "ClassicV2":
        return {
          root: {
            padding: "0 5vw 4em",
            [theme.breakpoints.down("sm")]: {
              padding: 0
            }
          },
          content: {
            backgroundColor: color.primary.main,
            width: "100%",
            height: 500,
            display: "flex",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              height: "auto"
            }
          },
          aboutusSection: {
            padding: "3em 2em",
            width: "35%",
            height: "auto",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
              width: "auto"
            }
          },
          header: {
            fontSize: 28,
            fontWeight: 500,
            color: "#54575f",
            fontFamily: storeInfo && storeInfo.font.family
          },
          aboutus: {
            marginTop: "3vh",
            fontSize: 18,
            color: "#54575f"
          },
          aboutus_image: {
            backgroundImage: props.store.aboutus_image
              ? `url(${
                  typeof props.store.aboutus_image === "object"
                    ? createPreview(props.store.aboutus_image)
                    : props.store.aboutus_image
                })`
              : `url(${setDefaultImage(
                  props.store.template,
                  "aboutus_image"
                )})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "65%",
            height: "auto",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: 250
            }
          },
          contacts: {
            paddingTop: "10vh",
            [theme.breakpoints.down("sm")]: {
              position: "static",
              marginTop: "5vh"
            }
          }
        };

      case "ContemporaryV2":
        return {
          content: {
            backgroundColor: color.primary.main,
            width: "100%",
            height: 500,
            display: "flex",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              height: "auto"
            }
          },
          aboutusSection: {
            padding: "4em",
            width: "50%",
            height: "auto",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
              width: "auto",
              padding: "2em"
            }
          },
          header: {
            fontSize: 32,
            fontWeight: 600,
            color: "#54575f",
            fontFamily: storeInfo && storeInfo.font.family,
            [theme.breakpoints.down("sm")]: {
              fontSize: 28
            }
          },
          aboutus: {
            marginTop: "5vh",
            fontSize: 24,
            color: "#54575f",

            [theme.breakpoints.down("sm")]: {
              fontSize: 18
            }
          },
          aboutus_image: {
            backgroundImage: props.store.aboutus_image
              ? `url(${
                  typeof props.store.aboutus_image === "object"
                    ? createPreview(props.store.aboutus_image)
                    : props.store.aboutus_image
                })`
              : `url(${setDefaultImage(
                  props.store.template,
                  "aboutus_image"
                )})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "50%",
            height: "auto",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: 350
            }
          },
          contacts: {
            position: "absolute",
            bottom: "8vh",
            [theme.breakpoints.down("sm")]: {
              position: "static",
              marginTop: "5vh"
            }
          }
        };
      case "Leftist":
        return {
          content: {
            backgroundColor: color.primary.main,
            width: "100%",
            height: "85vh",
            [theme.breakpoints.down("sm")]: {
              height: "90vh"
            },
            backgroundImage: props.store.aboutus_image
              ? `url(${
                  typeof props.store.aboutus_image === "object"
                    ? createPreview(props.store.aboutus_image)
                    : props.store.aboutus_image
                })`
              : `url(${setDefaultImage(
                  props.store.template,
                  "aboutus_image"
                )})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative"
          },
          aboutusSection: {
            padding: "4em",
            width: "45%",
            height: "auto",
            position: "absolute",
            backgroundColor: "#3e4149b3",
            bottom: "40px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: "100%",
              bottom: 0,
              left: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              paddingBottom: "5em"
            }
          },
          header: {
            fontSize: 40,
            fontWeight: 700,
            color: "white",
            fontFamily: storeInfo && storeInfo.font.family,
            [theme.breakpoints.down("sm")]: {
              fontSize: 28
            }
          },
          aboutus: {
            marginTop: "1em",
            fontSize: 20,
            fontWeight: 300,
            color: "white",
            [theme.breakpoints.down("sm")]: {
              fontSize: 18
            }
          }
        };

      case "SariSari":
        return {
          header: {
            fontSize: 48,
            fontWeight: "bold",
            fontFamily: storeInfo && storeInfo.font.family,
            color: color.primary.contrastText
          },
          image: {
            backgroundImage: props.store.aboutus_image
              ? `url(${
                  typeof props.store.aboutus_image === "object"
                    ? createPreview(props.store.aboutus_image)
                    : props.store.aboutus_image
                })`
              : `url(${setDefaultImage(
                  props.store.template,
                  "aboutus_image"
                )})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          },
          aboutus: {
            marginTop: "3vh",
            fontSize: 18,
            color: color.primary.contrastText
          }
        };
      case "Pastel":
        return {
          header: {
            fontSize: 24,
            fontWeight: "bold",
            fontFamily: storeInfo && storeInfo.font.family,
            color: color.primary.contrastText
          },
          image: {
            backgroundImage: props.store.aboutus_image
              ? `url(${
                  typeof props.store.aboutus_image === "object"
                    ? createPreview(props.store.aboutus_image)
                    : props.store.aboutus_image
                })`
              : `url(${setDefaultImage(
                  props.store.template,
                  "aboutus_image"
                )})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            [theme.breakpoints.down("sm")]: {
              height: "20em"
            }
          },
          aboutus: {
            marginTop: "3vh",
            fontSize: 18,
            color: color.primary.contrastText
          }
        };
      default:
        return {};
    }
  };

  let templateStyles = makeStyles(theme =>
    getStyles(props.store ? props.store.template : "Blank", theme)
  );

  const classes = templateStyles();

  const social_medias = color => (
    <div className="d-flex align-items-center">
      {props.store ? (
        props.store.facebook ? (
          <IconButton
            id="facebook_button"
            className="p-2 mr-2"
            style={{
              color: color,
              display: props.store.facebook === "null" ? "none" : ""
            }}
            target="_blank"
            href={
              props.isPreview
                ? null
                : `http://facebook.com/${props.store.facebook}`
            }
          >
            <FacebookIcon style={{ fontSize: "25px" }} />
          </IconButton>
        ) : (
          " "
        )
      ) : (
        " "
      )}

      {props.store ? (
        props.store.instagram ? (
          <IconButton
            id="instagram_button"
            className="p-2 mr-2"
            style={{
              color: color,
              display: props.store.instagram === "null" ? "none" : ""
            }}
            target="_blank"
            href={
              props.isPreview
                ? null
                : `http://instagram.com/${props.store.instagram}`
            }
          >
            <InstagramIcon style={{ fontSize: "25px" }} />
          </IconButton>
        ) : (
          " "
        )
      ) : (
        " "
      )}
      {props.store ? (
        props.store.shopee ? (
          <IconButton
            id="shopee_button"
            className="p-2 mr-2"
            style={{
              color: color,
              paddingTop: "1vh",
              display: props.store.shopee === "null" ? "none" : ""
            }}
            target="_blank"
            href={
              props.isPreview ? null : `http://shopee.com/${props.store.shopee}`
            }
          >
            <ShopeeIcon2
              fill={color}
              style={{
                width: 25,
                height: "auto"
              }}
            />
          </IconButton>
        ) : (
          " "
        )
      ) : (
        " "
      )}
      {props.store ? (
        props.store.lazada ? (
          <IconButton
            id="lazada_button"
            className="p-2 mr-2"
            style={{
              color: color,
              paddingTop: "1vh",
              display: props.store.lazada === "null" ? "none" : ""
            }}
            target="_blank"
            href={
              props.isPreview
                ? null
                : `http://lazada.com.ph/shop/${props.store.lazada}`
            }
          >
            <Lazada
              fill={"white"}
              style={{ width: 25, height: 25, marginTop: 8 }}
            />
          </IconButton>
        ) : (
          " "
        )
      ) : (
        " "
      )}

      {props.store ? (
        props.store.kumu ? (
          <IconButton
            id="kumu_button"
            className="p-2 mr-2"
            style={{
              color: color,
              paddingTop: "1vh",
              display: props.store.kumu === "null" ? "none" : ""
            }}
            target="_blank"
            href={
              props.isPreview ? null : `http://app.kumu.ph/${props.store.kumu}`
            }
          >
            <img src={Kumu} style={{ width: 25, marginTop: 8 }} />
          </IconButton>
        ) : (
          " "
        )
      ) : (
        " "
      )}
    </div>
  );

  const setAboutUsTemplate = () => {
    switch (props.store ? props.store.template : "Blank") {
      default:
        return "";
      case "Blank":
        return <Blank />;
      case "Classic":
        return (
          <div>
            <Box className={classes.root}>
              <div className={classes.overlay}>
                <Box className={classes.box}>
                  <Typography
                    variant="h3"
                    style={{
                      textAlign: "center",
                      paddingBottom: "3vh",
                      fontFamily: storeInfo && storeInfo.font.family,
                      fontSize: 36,
                      color: "#54575f"
                    }}
                  >
                    About Us
                  </Typography>

                  <Divider />
                  <Box style={{ marginTop: 20 }}>
                    <div
                      style={{
                        wordWrap: "break-word",
                        width: "100%",

                        fontSize: 16,
                        color: "#54575f",
                        whiteSpace: "pre-line"
                      }}
                    >
                      <ShowMoreText
                        lines={8}
                        more="See more"
                        onClick={() =>
                          props.isPreview
                            ? null
                            : history.push(`/shop/${storeInfo.site}/about_us`)
                        }
                        expanded={false}
                      >
                        {props.store
                          ? props.store.description
                            ? props.store.description
                            : isPreview
                            ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                            : ""
                          : isPreview
                          ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                          : ""}
                      </ShowMoreText>
                    </div>
                  </Box>
                </Box>
                {/* </div>
          </Container> */}
              </div>
            </Box>
          </div>
        );
      case "Contemporary":
        return (
          <Box className={classes.root}>
            <Container>
              <Box>
                <Box className={classes.boxAbout}>
                  <Box className={classes.boxAbout2}>
                    <Typography variant="subtitle1" className={classes.aboutUs}>
                      About Us
                    </Typography>
                    <Divider />
                    <Box
                      style={{
                        marginTop: 20
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          wordWrap: "break-word",
                          fontSize: 16,
                          color: "#54575F",
                          whiteSpace: "pre-line"
                        }}
                      >
                        <ShowMoreText
                          lines={largeDesktopView ? 10 : 4}
                          more="See more"
                          onClick={() =>
                            props.isPreview
                              ? null
                              : history.push(`/shop/${storeInfo.site}/about_us`)
                          }
                          expanded={false}
                        >
                          {props.store
                            ? props.store.description
                              ? props.store.description
                              : isPreview
                              ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                              : ""
                            : isPreview
                            ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                            : ""}
                        </ShowMoreText>
                      </div>
                    </Box>
                  </Box>
                  <Box className={classes.boxAboutImage}>
                    {/* {props.store.aboutus_image ? (
                      <PinchZoomPan {...pinchPropsAbout}>
                        <img
                          src={
                            typeof props.store.aboutus_image === "object"
                              ? createPreview(props.store.aboutus_image)
                              : props.store.aboutus_image
                          }
                        />
                      </PinchZoomPan>
                    ) : (
                      ""
                    )} */}
                  </Box>
                  {/* <Box className={classes.boxAboutImage}></Box> */}
                </Box>
              </Box>
            </Container>
          </Box>
        );
      case "Minimalist":
        return (
          <Box className={classes.root}>
            <Box className={classes.aboutImg}>
              {/* {props.store.aboutus_image ? (
                <PinchZoomPan {...pinchPropsAbout}>
                  <img
                    src={
                      typeof props.store.aboutus_image === "object"
                        ? createPreview(props.store.aboutus_image)
                        : props.store.aboutus_image
                    }
                  />
                </PinchZoomPan>
              ) : (
                ""
              )} */}
            </Box>
            <Box className={classes.about}>
              <Typography
                style={{
                  fontFamily: storeInfo && storeInfo.font.family,
                  color: "#54575f",
                  fontSize: 36,
                  fontWeight: 500
                }}
              >
                ABOUT US
              </Typography>
              <Divider />
              <Box
                style={{
                  marginTop: 30,

                  letterSpacing: 2
                }}
              >
                <div
                  style={{
                    color: "#54575f",

                    fontSize: 16,
                    width: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-line"
                  }}
                >
                  <ShowMoreText
                    lines={largeDesktopView ? 12 : 6}
                    more="See more"
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(`/shop/${storeInfo.site}/about_us`)
                    }
                    expanded={false}
                  >
                    {props.store
                      ? props.store.description
                        ? props.store.description
                        : isPreview
                        ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                        : ""
                      : isPreview
                      ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                      : ""}
                  </ShowMoreText>
                </div>
                {/* <Typography
                  style={{
                    color: "#fff",
                    
                    fontSize: 16,
                    width: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-line"
                  }}
                >
                  {props.store
                    ? props.store.description
                      ? props.store.description
                      : isPreview
                      ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                      : ""
                    : isPreview
                    ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                    : ""}
                </Typography> */}
              </Box>
            </Box>
          </Box>
        );

      case "ClassicV2":
        return (
          <div className={classes.root}>
            <div className={classes.content}>
              <div className={classes.aboutusSection}>
                <div className={classes.header}>About Us</div>
                <div className={classes.aboutus}>
                  <ShowMoreText
                    lines={7}
                    more="See more"
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(`/shop/${storeInfo.site}/about_us`)
                    }
                    expanded={false}
                  >
                    {props.store
                      ? props.store.description
                        ? props.store.description
                        : isPreview
                        ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                        : ""
                      : isPreview
                      ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                      : ""}
                  </ShowMoreText>
                </div>
                <div className={classes.contacts}>
                  {social_medias(color.primary.contrastText)}
                </div>
              </div>
              <div className={classes.aboutus_image} />
            </div>
          </div>
        );

      case "ContemporaryV2":
        return (
          <div className={classes.root}>
            <div className={classes.content}>
              <div className={classes.aboutus_image} />
              <div className={classes.aboutusSection}>
                <div className={classes.header}>Why {storeInfo.name}?</div>
                <div className={classes.aboutus}>
                  <ShowMoreText
                    lines={5}
                    more="See more"
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(`/shop/${storeInfo.site}/about_us`)
                    }
                    expanded={false}
                  >
                    {props.store
                      ? props.store.description
                        ? props.store.description
                        : isPreview
                        ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                        : ""
                      : isPreview
                      ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                      : ""}
                  </ShowMoreText>
                </div>
                <div className={classes.contacts}>
                  {social_medias(color.primary.contrastText)}
                </div>
              </div>
            </div>
          </div>
        );
      case "Leftist":
        return (
          <div className={classes.content}>
            <div className={classes.aboutusSection}>
              <div className={classes.header}>About Us</div>
              <div className={classes.aboutus}>
                <ShowMoreText
                  lines={5}
                  more="See more"
                  onClick={() =>
                    props.isPreview
                      ? null
                      : history.push(`/shop/${storeInfo.site}/about_us`)
                  }
                  expanded={false}
                >
                  {props.store
                    ? props.store.description
                      ? props.store.description
                      : isPreview
                      ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                      : ""
                    : isPreview
                    ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                    : ""}
                </ShowMoreText>
              </div>
            </div>
          </div>
        );
      case "SariSari":
        return (
          <div style={{ backgroundColor: color.primary.main }}>
            {isMobile ? (
              <div
                className={`col-12 p-4 d-flex align-items-center ${classes.image}`}
              >
                <div className="w-100">
                  <div style={{ color: "#fff" }} className={classes.header}>
                    About Us
                  </div>
                  <div style={{ color: "#fff" }} className={classes.aboutus}>
                    <ShowMoreText
                      lines={5}
                      more="See more"
                      onClick={() =>
                        props.isPreview
                          ? null
                          : history.push(`/shop/${storeInfo.site}/about_us`)
                      }
                      expanded={false}
                    >
                      {props.store
                        ? props.store.description
                          ? props.store.description
                          : isPreview
                          ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                          : ""
                        : isPreview
                        ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                        : ""}
                    </ShowMoreText>
                  </div>
                </div>
              </div>
            ) : (
              <Container>
                <div
                  className="col-12 d-flex"
                  style={{ height: "30em", paddingBottom: "10vh" }}
                >
                  <div className="col-6 p-0 d-flex align-items-center">
                    <div style={{ padding: "5em" }}>
                      <div className={classes.header}>About Us</div>
                      <div className={classes.aboutus}>
                        <ShowMoreText
                          lines={5}
                          more="See more"
                          onClick={() =>
                            props.isPreview
                              ? null
                              : history.push(`/shop/${storeInfo.site}/about_us`)
                          }
                          expanded={false}
                        >
                          {props.store
                            ? props.store.description
                              ? props.store.description
                              : isPreview
                              ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                              : ""
                            : isPreview
                            ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                            : ""}
                        </ShowMoreText>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-6 p-0 d-flex align-items-center ${classes.image}`}
                  ></div>
                </div>
              </Container>
            )}
          </div>
        );
      case "Pastel":
        return (
          <div style={{ backgroundColor: "#fff" }}>
            {isMobile ? (
              <div>
                <div className="pb-5">
                  <div
                    className={`col-12 p-0 d-flex align-items-center ${classes.image}`}
                  ></div>
                  <div
                    style={{
                      backgroundColor: color.secondary.main
                    }}
                    className="col-12 p-3 d-flex"
                  >
                    <div>
                      <div className={classes.header}>About Us</div>
                      <div className={classes.aboutus}>
                        <ShowMoreText
                          lines={5}
                          more="See more"
                          onClick={() =>
                            props.isPreview
                              ? null
                              : history.push(`/shop/${storeInfo.site}/about_us`)
                          }
                          expanded={false}
                        >
                          {props.store
                            ? props.store.description
                              ? props.store.description
                              : isPreview
                              ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                              : ""
                            : isPreview
                            ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                            : ""}
                        </ShowMoreText>
                      </div>
                      <div className="mt-3">
                        {social_medias(color.primary.contrastText)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Container>
                <div
                  className="col-12 d-flex"
                  style={{ height: "25em", paddingBottom: "10vh" }}
                >
                  <div
                    className={`col-6 p-0 d-flex align-items-center ${classes.image}`}
                  ></div>
                  <div
                    style={{ backgroundColor: color.secondary.main }}
                    className="col-6 p-5 d-flex"
                  >
                    <div>
                      <div className={classes.header}>About Us</div>
                      <div className={classes.aboutus}>
                        <ShowMoreText
                          lines={5}
                          more="See more"
                          onClick={() =>
                            props.isPreview
                              ? null
                              : history.push(`/shop/${storeInfo.site}/about_us`)
                          }
                          expanded={false}
                        >
                          {props.store
                            ? props.store.description
                              ? props.store.description
                              : isPreview
                              ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                              : ""
                            : isPreview
                            ? "Your shop's short description. It could be a short history or interesting trivia about your business. You can also discuss here how many stores you have, what types of products you sell, or anything you want to highlight about your business."
                            : ""}
                        </ShowMoreText>
                      </div>
                      <div className="mt-3">
                        {social_medias(color.primary.contrastText)}
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            )}
          </div>
        );
    }
  };

  return (
    <div>
      {setAboutUsTemplate(props.store ? props.store.template : "Blank")}
    </div>
  );
};
export default PreviewAboutTemplate;
