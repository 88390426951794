import React from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import {
  Provinces,
  getCities,
} from "../../common/template/components/constants/address";
import NumberFormat from "react-number-format";
import Actions from "../redux/actions";

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    "& p": {
      textAlign: "right",
    },
  },
  addBtn: {
    height: 38,
    marginLeft: "auto",
    minWidth: 100,
  },
  item: {
    padding: "1em",
    background: "#FFFFFF",
    border: "1px solid #EEEEEE",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: "rgba(43, 45, 50, 0.8)",
  },
  label2: {
    fontSize: 16,
    fontWeight: 500,
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)",
  },
  btn: {
    height: 38,
  },
  note: {
    marginTop: "2em",
    padding: "0.5em 1em",
    color: "#2B2D32",
    background: "#EEEEEE",
    borderRadius: 2,
  },
  field: {
    "& p": {
      textAlign: "right",
    },
  },
}));

const LocationPickerModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialData = {
    province: "Metro Manila",
    selectCities: false,
    cities: [],
    rate: 0.0,
    delivery_information: "",
  };
  const stores = useSelector((state) => state.dashboard.stores);
  const [data, setData] = React.useState(initialData);
  const citiesChoices = getCities(data.province);

  const onChange = (name, val) => {
    let cities = data.cities;
    if (name === "province") {
      cities = [getCities(val)[0]];
    }
    setData((state) => ({
      ...state,
      cities,
      [name]: val,
    }));
  };

  React.useEffect(() => {
    if (props.data.mode === "edit") {
      const newData = {
        ...props.data,
        selectCities: props.data.cities.length ? true : false,
      };
      setData(newData);
    } else {
      setData(initialData);
    }
  }, [props.open]);

  const onSubmit = () => {
    dispatch(
      Actions.saveDeliveryLocation({
        ...data,
        cities: data.selectCities ? data.cities : [],
        store: stores[0].uuid,
      })
    );
    props.setOpen(false);
  };

  const loading = useSelector((state) => state.deliverySettings.loading);

  return (
    <Dialog
      id="location-picker-dialog"
      fullWidth
      maxWidth="xs"
      open={props.open}
    >
      <DialogContent className="p-4">
        <p className={`mb-4 ${classes.label2}`}>
          <strong>Delivery Location</strong>
        </p>
        <IconButton
          className="position-absolute"
          style={{ top: 5, right: 5 }}
          onClick={() => props.setOpen(false)}
        >
          <CloseIcon />
        </IconButton>

        <FormControl
          variant="outlined"
          margin="dense"
          className="w-100 m-0 mt-2"
        >
          <div className={classes.label}>Province</div>
          <Select
            fullWidth
            className="bg-white"
            value={data.province}
            onChange={(e) => onChange("province", e.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {Provinces.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          className="m-0 mt-2"
          control={
            <Checkbox
              checked={data.selectCities}
              onChange={(e) => onChange("selectCities", e.target.checked)}
              color="primary"
            />
          }
          label="Select Cities"
        />
        {data.selectCities ? (
          <FormControl
            variant="outlined"
            margin="dense"
            className="w-100 m-0 mt-2"
          >
            <div className={classes.label}>City</div>
            <Select
              fullWidth
              className="bg-white"
              multiple
              value={data.cities}
              onChange={(e) => onChange("cities", e.target.value)}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                style: {
                  maxHeight: 400,
                },
              }}
            >
              {citiesChoices.map((item, index) => (
                <MenuItem value={item} key={index}>
                  <Checkbox
                    checked={data.cities.indexOf(item) > -1}
                    color="primary"
                  />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          ""
        )}
        <FormControl
          variant="outlined"
          margin="dense"
          className="w-100 m-0 mt-2"
        >
          <div className={classes.label}>Rate</div>
          <NumberFormat
            customInput={TextField}
            className="mt-0"
            margin="dense"
            onValueChange={(values) => onChange("rate", values.value)}
            value={data.rate}
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₱</InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          margin="dense"
          className="w-100 m-0 mt-2"
        >
          <div className={classes.label}>Delivery information</div>
          <TextField
            fullWidth
            className="mt-0"
            margin="dense"
            variant="outlined"
            value={data.delivery_information}
            onChange={(e) => onChange("delivery_information", e.target.value)}
            placeholder="ex. Monday to Friday delivery only"
            inputProps={{
              maxlength: 100,
            }}
          />
        </FormControl>
        <Button
          fullWidth
          variant="contained"
          disableElevation
          className={`${classes.btn} mt-3 mb-2`}
          color="primary"
          disabled={Boolean(
            !data.province || !data.rate || !data.delivery_information
          )}
          onClick={onSubmit}
        >
          {loading ? (
            <div className="d-flex my-5 mr-3">
              <div
                className="spinner-border mx-auto"
                role="status"
                style={{
                  color: "white",
                  fontSize: 12,
                  height: 25,
                  width: 25,
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}
          {props.data.mode === "edit" ? "Save changes" : "Add location"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default LocationPickerModal;
