import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DeliveryIcon from '../../../assets/images/delivery-green.svg';


const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    "& p": {
      textAlign: "right"
    }
  },
  addBtn: {
    height: 38,
    marginLeft: "auto",
    minWidth: 100,
  },
  item: {
    padding: "1em",
    background: "#FFFFFF",
    border: "1px solid #EEEEEE",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: 4
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: "rgba(43, 45, 50, 0.8)"
  },
  label2: {
    fontSize: 16,
    fontWeight: 500
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)"
  },
  btn: {
    height: 38
  },
  title: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#F97663"
  }
}));

const WelcomeModal = props => {
  const classes = useStyles();

  return (
    <Dialog id="delivery_settings_dialog" fullWidth maxWidth="xs" open={props.open}>
      <DialogContent className="p-4">
        <IconButton className="position-absolute" style={{ top: 5, right: 5 }}
          onClick={() => props.setOpen(false)}>
          <CloseIcon />
        </IconButton>
        <div className="text-center">
          <img src={DeliveryIcon} alt="delivery-modal"/>
          <p className={`mb-4 mt-2 ${classes.title}`}><strong>NEW ON SENTRO</strong></p>
        </div>
        <h5><strong>Deliver anywhere you want!</strong></h5>
        <ul>
          <li>Allow your buyers to pick-up from your shop</li>
          <li>Set rates for different locations</li>
          <li>Let buyers compute for rates upon checkout</li>
        </ul>
        <Button
          fullWidth
          variant="contained"
          disableElevation
          className={`${classes.btn} mt-3 mb-2`}
          color="primary"
          onClick={() => props.setOpen(false)}
        >
          Manage delivery settings
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeModal;
