import React from 'react';
import {
  Button,
  useMediaQuery,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { connect, useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import ReduxTextField from '../../../../../reduxFields/textField';
import SelectField from '../../../../../reduxFields/selectField';
import RadioField from '../../../../../reduxFields/radioField';
import CheckBoxField from '../../../../../reduxFields/checkbox';
import validations from '../../../../../common/validations';
import { Provinces, getCities, getBrgy } from '../../../../../common/template/components/constants/address';


const labelChoices = [
  {value: 0, display: "Home"},
  {value: 1, display: "Work"},
];

const AddressForm = (props) => {
  const useStyles = makeStyles(theme => ({
    links: {
      color: "#18B4A1"
    },
    label2: {
      fontSize: 12,
      fontWeight: 500,
      marginBottom: 0,
      marginTop: 0,
      color: "rgba(0,0,0,0.8)"
    },
    fixedBottom: {
      position: "fixed",
      bottom: "1rem",
      left: 0,
      margin: "1.5rem",
      width: "calc(100% - 3rem)"
    },
    choices: {
      fontSize: 14,
      color: "rgba(43, 45, 50, 0.8)"
    },
    submitButton: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0
    }
  }));


  const classes = useStyles();
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const values = useSelector(state => state.form.address_form?.values);
  const errors = useSelector(state => state.form.address_form?.syncErrors)

  const today = new Date()
  let tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)


  const onSave = () => {
    if (errors) {
      // touch fields to show errors
      const field_errors = errors ? Object.keys(errors): [];
      field_errors.forEach((field) => {
          props.touch(field);
      });
    } else {
      props.onSubmit({
        ...values,
        label: parseInt(values.label) === 1 ? "work": "home"
      });
      props.reset();
      try {
          props.setOpen(false)
      } catch {}
    }
  }


  let city_choices = [];
  try {
    city_choices= getCities(values.province).map(city => ({
      value: city, display: city
    }));
  } catch {}

  let brgy_choices = [];
  try {
    brgy_choices = getBrgy(values.province, values.city).map(brgy => ({
      value: brgy, display: brgy
    }));
  } catch {}

  React.useEffect(() => {
    if (values) {
      if (!city_choices.map(i => i.value).includes(values.city)){
        dispatch(change("address_form", "city", ""))
      }
      if (!brgy_choices.map(i => i.value).includes(values.barangay)){
        dispatch(change("address_form", "barangay", ""))
      }
    }
  }, [values?.province, values?.city])


  return (
    <div className="h-100" style={{marginBottom: 80}}>
      <h5 className="mb-4">
        <strong>Address details</strong>
      </h5>
      <p className={classes.label2}>Street address</p>
      <div className="row no-gutters">
        <Field
          fullWidth
          id="street"
          name="street"
          component={ReduxTextField}
          validate={[
              validations.required
          ]}
          variant="outlined"
          inputProps={{
            maxlength: 25
          }}
          showHelperText={false}
        />
      </div>
      <div className="row no-gutters mt-3">
        <div className="col-6 pr-2">
          <p className={classes.label2}>Region / Province</p>
          <Field
            fullWidth
            id="province"
            name="province"
            component={SelectField}
            validate={[
                validations.required
            ]}
            choices={Provinces.map(province => ({
              value: province, display: province
            }))}
            variant="outlined"
            inputProps={{
              maxlength: 25
            }}
          />
        </div>
        <div className="col-6">
          <p className={classes.label2}>City</p>
          <Field
            fullWidth
            id="city"
            name="city"
            component={SelectField}
            validate={[
                validations.required
            ]}
            choices={city_choices}
            disabled={values ? !Boolean(values.province): true}
            variant="outlined"
            inputProps={{
              maxlength: 25
            }}
          />
        </div>
      </div>
      <div className="row no-gutters mt-3">
        <div className="col-6 pr-2">
          <p className={classes.label2}>Barangay</p>
          <Field
            fullWidth
            id="brgy"
            name="brgy"
            component={SelectField}
            validate={[
                validations.required
            ]}
            choices={brgy_choices}
            disabled={values ? !Boolean(values.city): true}
            variant="outlined"
            inputProps={{
              maxlength: 25
            }}
          />
        </div>
        <div className="col-6">
          <p className={classes.label2}>Postal / ZIP code</p>
          <Field
            fullWidth
            id="zipcode"
            name="zipcode"
            component={ReduxTextField}
            validate={[
                validations.zipcode
            ]}
            variant="outlined"
            inputProps={{
              maxlength: 4
            }}
            showHelperText={false}
          />
        </div>
      </div>
      <p className={`${classes.label2} mt-3`}>Label</p>
      <div className="my-2">
        <Field
          fullWidth
          id="label"
          name="label"
          component={RadioField}
          choices={labelChoices}
          inline
          choiceClass={classes.choices}
        />
      </div>
      <p className={`${classes.label2} mt-3`}>Mobile number</p>
      <Field
        fullWidth
        id="mobile_number"
        name="mobile_number"
        component={ReduxTextField}
        validate={[
            validations.mobileNumber10,
            validations.required
        ]}
        inputProps={{
          maxlength: 10
        }}
        showHelperText={false}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <span className="text-dark">+63</span>
            </InputAdornment>
          )
        }}
      />
      <Field
        fullWidth
        label="Set as default"
        id="is_default"
        name="is_default"
        component={CheckBoxField}
      />
      <div className={`p-4 ${classes.submitButton} bg-white`}>
        <Button
          fullWidth
          variant="contained"
          disableElevation
          className={`${classes.btn} ${is_mobile ? classes.fixedBottom: ""} mt-auto mb-2 submit_button`}
          color="primary"
          onClick={onSave}
        >
          Save address
        </Button>
      </div>
    </div>
  )
}

let Form = reduxForm({
  form: "address_form",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AddressForm);

Form = connect(
  state => ({
    initialValues: {
      label: 0
    }
  }),
  {},
)(Form)

export default Form;