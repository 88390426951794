import { verify, signUp, fbSignUp, resendVCode } from "./registerServices";
import { showAlert } from "../common/commonActions";
import history from "../../store/history";
import Path from "../../Paths";
import { getStore } from "../websiteBuilder/websiteBuilderActions";

export const RegisterActionTypes = {
  REGISTER_REQUEST: "@@REGISTER_REQUEST",
  REGISTER_SUCCESS: "@@REGISTER_SUCCESS",
  REGISTER_FAILED: "@@REGISTER_FAILED",
  RESEND_SUCCESS: "@@RESEND_SUCCESS",
  DONE: "@@DONE",
  VERIFICATION_REQUEST: "@@VERIFICATION_REQUEST",
  VERIFICATION_SUCCESS: "@@VERIFICATION_SUCCESS",
  VERIFICATION_FAILED: "@@VERIFICATION_FAILED"
};

export function register(value) {
  return async dispatch => {
    try {
      dispatch({ type: RegisterActionTypes.REGISTER_REQUEST });
      let response = await signUp(value);

      dispatch({
        type: RegisterActionTypes.REGISTER_SUCCESS,
        payload: response.data
      });
      let data = response.data;
      localStorage.setItem("TOKEN", data.token);
      localStorage.setItem("EMAIL_FOR_SIGNUP", data.email);
      localStorage.setItem("CURRENT_USER", JSON.stringify(response.data));
      sessionStorage.setItem("CURRENT_USER", JSON.stringify(response.data));
      history.push(Path.VERIFY_EMAIL);
    } catch (error) {
      dispatch({
        type: RegisterActionTypes.REGISTER_FAILED
      });
      dispatch(showAlert({ type: "error", message: error.message }));
    }
  };
}

export function verifyCode(value) {
  return async dispatch => {
    try {
      dispatch({ type: RegisterActionTypes.VERIFICATION_REQUEST });
      let response = await verify(value);

      console.log(response);
      dispatch({
        type: RegisterActionTypes.VERIFICATION_SUCCESS,
        payload: response.data
      });
      sessionStorage.setItem("TOKEN", response.data.token);
      sessionStorage.setItem(
        "CURRENT_USER",
        JSON.stringify(response.data.user)
      );
      sessionStorage.setItem(
        "subscription",
        JSON.stringify({ show_banner: true })
      );
      let res = await dispatch(getStore());
      if (res.length) {
        history.push(Path.DASHBOARD);
      } else {
        history.push(Path.SHOP_NEW_CREATE);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: RegisterActionTypes.VERIFICATION_FAILED,
        payload: error.data
      });
      history.push(Path.VERIFICATION_FAILED);
      // dispatch({
      //   type: RegisterActionTypes.VERIFICATION_FAILED
      // });
      // dispatch(showAlert({ type: "error", message: error.data }));
    }
  };
}

export function resendCode(isResend) {
  let currentUser = JSON.parse(localStorage.getItem("CURRENT_USER"));
  return async dispatch => {
    try {
      dispatch({ type: RegisterActionTypes.VERIFICATION_REQUEST });
      let response = await resendVCode(currentUser.email);

      if (!isResend) {
        dispatch({ type: RegisterActionTypes.DONE });
        history.push(Path.VERIFY_EMAIL);
      } else {
        dispatch({ type: RegisterActionTypes.RESEND_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      // dispatch({
      //   type: RegisterActionTypes.VERIFICATION_FAILED
      // });
      // dispatch(showAlert({ type: "error", message: error.data }));
    }
  };
}

export function fb_sign_up(value) {
  return async dispatch => {
    try {
      dispatch({ type: RegisterActionTypes.REGISTER_REQUEST });
      let response = await fbSignUp(value);

      dispatch({
        type: RegisterActionTypes.REGISTER_SUCCESS,
        payload: response.data
      });
      history.push(Path.DASHBOARD);
    } catch (error) {
      dispatch({
        type: RegisterActionTypes.REGISTER_FAILED
      });
      dispatch(showAlert({ type: "error", message: error ? error.data : "" }));
      history.push(Path.SIGN_UP);
    }
  };
}
