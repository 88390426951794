import axios from "axios";
import apiConfig from "../../../../../common/apiConfig";
import sentroAxios from "../../../../../utils/SentroAxios";
import moment from "moment";
async function getStore(value) {
  let response = await sentroAxios.get(
    `${apiConfig.apiURL}/api/store/${value.site}`,
    {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    }
  );
  return response;
}

async function saveStore(value) {
  try {
    let params = {
      owner: value.owner,
      template: value.template,
      name: value.name,
      site: value.site,
      mobile: value.mobile,
      is_published: false,
      is_white_labeled: false,
      font: { family: "Georgia", css_url: "" },
      color: {
        primary: {
          dark: "#f2f2f2",
          main: "#ffffff",
          light: "white",
          contrastText: "#9763c0"
        },
        secondary: {
          dark: "#f2f2f2",
          main: "#9763c0",
          light: "white",
          contrastText: "#ffffff"
        }
      }
    };
    let response = await axios.post(`${apiConfig.apiURL}/api/store/`, params, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    });
    return response;
  } catch (error) {
    throw error.response;
  }
}
async function addPayment(value) {
  let paramsFormData = new FormData();
  if (value.account_name !== "") {
    paramsFormData.append("account_name", value.account_name);
  }
  if (value.account_number !== "") {
    paramsFormData.append("account_number", value.account_number);
  }
  if (value.bank_name !== "") {
    paramsFormData.append("bank_name", value.bank_name);
  }
  if (value.email !== "") {
    paramsFormData.append("email", value.email);
  }
  if (value.mobile_number !== "") {
    paramsFormData.append("mobile_number", value.mobile_number);
  }
  if (value.payment_link !== "") {
    paramsFormData.append("payment_link", value.payment_link);
  }
  if (value.payment_method !== "") {
    paramsFormData.append("payment_method", value.payment_method);
  }
  if (typeof value.qr_code_img != "string") {
    paramsFormData.append("qr_code_img", value.qr_code_img);
  }

  if (value.store !== "") {
    paramsFormData.append("store", value.store);
  }

  if (value.user !== "") {
    paramsFormData.append("user", value.user);
  }

  // paramsFormData.append("account_name", value.account_name);
  // paramsFormData.append("account_number", value.account_number);
  // paramsFormData.append("bank_name", value.bank_name);
  // paramsFormData.append("email", value.email);
  // paramsFormData.append("mobile_number", value.mobile_number);
  // paramsFormData.append("payment_link", value.payment_link);
  // paramsFormData.append("payment_method", value.payment_method);
  // paramsFormData.append("qr_code_img", value.qr_code_img);
  // paramsFormData.append("store", value.store);
  // paramsFormData.append("product_code", value.product_code);
  // paramsFormData.append("user", value.user);
  //   account_name: "hihi"
  // account_number: "112345456"
  // bank_name: "UnionBank of the Philippines"
  // email: "null"
  // mobile_number: ""
  // payment_link: ""
  // payment_method: "Bank"b
  // qr_code_img: {}
  // store: "faa7b11e-13b5-428e-b101-1f100334d03f"
  // user: 2
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/payment_method/`,
      paramsFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function getPayments(value) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/payment_method/store/${value}`
      // {
      //   headers: {
      //     Authorization: `JWT ${localStorage.getItem("TOKEN")}`,
      //   },
      // }
    );
    return response;
  } catch (error) {
    console.log(error);
    // throw error.response;
  }
}

async function updatePayment(value) {
  let paramsFormData = new FormData();
  if (value.account_name !== "") {
    paramsFormData.append("account_name", value.account_name);
  }
  if (value.account_number !== "") {
    paramsFormData.append("account_number", value.account_number);
  }
  if (value.bank_name !== "") {
    paramsFormData.append("bank_name", value.bank_name);
  }
  if (value.email !== "") {
    paramsFormData.append("email", value.email);
  }
  if (value.mobile_number !== "") {
    paramsFormData.append("mobile_number", value.mobile_number);
  }
  if (value.payment_link !== "") {
    paramsFormData.append("payment_link", value.payment_link);
  }
  if (value.payment_method !== "") {
    paramsFormData.append("payment_method", value.payment_method);
  }
  if (typeof value.qr_code_img != "string") {
    paramsFormData.append("qr_code_img", value.qr_code_img);
  }

  if (value.store !== "") {
    paramsFormData.append("store", value.store);
  }

  if (value.user !== "") {
    paramsFormData.append("user", value.user);
  }
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/payment_method/${value.uuid}/`,
      paramsFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function deletePayment(value) {
  try {
    let response = await axios.delete(
      `${apiConfig.apiURL}/api/payment_method/${value.uuid}/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function updateStore(value, step) {
  let paramsFormData = new FormData();

  if (value.color === "null" && value.template === "Classic") {
    value.color = "funBlue";
  } else if (value.color === "null" && value.template === "Contemporary") {
    value.color = "pink/darkCyan";
  } else if (value.color === "null" && value.template === "Minimalist") {
    value.color = "goldYellow/bastille";
  } else if (value.color === null && value.template === "Classic") {
    value.color = "funBlue";
  } else if (value.color === null && value.template === "Contemporary") {
    value.color = "pink/darkCyan";
  } else if (value.color === null && value.template === "Minimalist") {
    value.color = "goldYellow/bastille";
  }

  if (value.owner) {
    paramsFormData.append("owner", value.owner);
  }
  if (value.name) {
    paramsFormData.append("name", value.name);
  }
  if (value.slogan) {
    paramsFormData.append("slogan", value.slogan);
  }
  if (value.template) {
    paramsFormData.append("template", value.template);
  }
  if (value.description) {
    paramsFormData.append("description", value.description);
  }
  if (value.site) {
    paramsFormData.append("site", value.site);
  }
  if (value.address) {
    paramsFormData.append("address", value.address);
  }
  if (value.email) {
    paramsFormData.append("email", value.email);
  } else if (value.email === "") {
    paramsFormData.append("email", "");
  }
  if (value.mobile) {
    paramsFormData.append("mobile", value.mobile);
  }
  if (value.landline) {
    paramsFormData.append("landline", value.landline);
  } else if (value.landline === "" || value.landline === "null") {
    paramsFormData.append("landline", "");
  }
  if (value.facebook) {
    paramsFormData.append("facebook", value.facebook);
  } else if (value.facebook === "") {
    paramsFormData.append("facebook", "");
  }

  if (value.instagram) {
    paramsFormData.append("instagram", value.instagram);
  } else if (value.instagram === "") {
    paramsFormData.append("instagram", "");
  }
  if (value.shopee) {
    paramsFormData.append("shopee", value.shopee);
  } else if (value.shopee === "") {
    paramsFormData.append("shopee", "");
  }
  if (value.color) {
    paramsFormData.append("color", value.color);
  }

  if (value.logo_image && typeof value.logo_image != "string") {
    paramsFormData.append("logo_image", value.logo_image);
  } else if (value.logo_image === null) {
    paramsFormData.append("logo_image", "");
  }
  if (value.cover_image && typeof value.cover_image != "string") {
    paramsFormData.append("cover_image", value.cover_image);
  } else if (value.cover_image === null) {
    paramsFormData.append("cover_image", "");
  }
  if (value.aboutus_image && typeof value.aboutus_image != "string") {
    paramsFormData.append("aboutus_image", value.aboutus_image);
  } else if (value.aboutus_image === null) {
    paramsFormData.append("aboutus_image", "");
  }
  if (value.contactus_image && typeof value.contactus_image != "string") {
    paramsFormData.append("contactus_image", value.contactus_image);
  } else if (value.contactus_image === null) {
    paramsFormData.append("contactus_image", "");
  }

  if (value.is_published) {
    paramsFormData.append("is_published", value.is_published);
  }
  if (value.url) {
    paramsFormData.append("url", value.url);
  }
  if (value.payment_deadline) {
    paramsFormData.append("payment_deadline", 24);
  }
  if (value.payment_bank) {
    paramsFormData.append("payment_bank", value.payment_bank);
  }
  if (value.account_name) {
    paramsFormData.append("account_name", value.account_name);
  }
  if (value.account_number) {
    paramsFormData.append("account_number", value.account_number);
  }
  paramsFormData.append(
    "fb_page_id",
    String(value.fb_page_id) === "null" ? "" : value.fb_page_id
  );
  if (value.image_settings) {
    await axios.put(
      `${apiConfig.apiURL}/api/store/${value.site}/`,
      { image_settings: value.image_settings },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
  }
  //   let params = {
  //     owner: value.owner,
  //     name: value.name,
  //     template: value.template,
  //     description: value.description,
  //     slogan: value.slogan,
  //     site: value.site,
  //     address: value.address,
  //     email: value.email,
  //     mobile: value.mobile,
  //     landline: value.landline,
  //     facebook: value.facebook,
  //     instagram: value.instagram,
  //     shopee: value.shopee,
  //     color: value.color,
  //     logo_image: value.logo_image,
  //     cover_image: value.cover_image,
  //     aboutus_image: value.aboutus_image,
  //     contactus_image: value.contactus_image,
  //     payment_deadline: 24,
  //     is_published: value.is_published,
  //     url: value.url,
  //     payment_bank: value.payment_bank,
  //     account_name: value.account_name,
  //     account_number: value.account_number,
  //     image_settings: value.image_settings
  //   };
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/store/${value.site}/`,
      paramsFormData,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function publish(value) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/store/${value.site}/`,
      { is_published: true },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );

    // GTM trigger
    let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    window.dataLayer.push(
      {
        eventAction: "Store Builder Published",
        eventCategory: "Store Builder",
        event: "storepublished",
        userId: currentUser.id
      },
      {
        eventAction: "Template Selection Success",
        eventCategory: "Template Selection",
        eventLabel: value.template,
        event: "templateselection"
      }
    );

    return response;
  } catch (error) {
    throw error.response;
  }
}

async function patchStore(site, values, otherParams) {
  if (otherParams) {
    await axios.put(
      `${apiConfig.apiURL}/api/store/${site}/`,
      {
        image_crop_area: otherParams.image_crop_area,
        image_settings: otherParams.image_settings,
        font: otherParams.font,
        color: otherParams.color
      },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
  }
  try {
    let request_data = new FormData();
    const valKeys = Object.keys(values);

    for (let key in valKeys) {
      const file_fields = [
        "logo_image",
        "cover_image",
        "aboutus_image",
        "contactus_image",
        "logo_original_image",
        "cover_original_image",
        "aboutus_original_image",
        "contactus_original_image"
      ];
      if (
        values[valKeys[key]] === "" ||
        values[valKeys[key]] === "null" ||
        values[valKeys[key]] === null
      ) {
        request_data.append(valKeys[key], "");
      } else {
        if (file_fields.includes(valKeys[key])) {
          if (typeof values[valKeys[key]] === "object") {
            request_data.append(valKeys[key], values[valKeys[key]]);
          }
        } else {
          if (
            values[valKeys[key]] === "" ||
            values[valKeys[key]] === "null" ||
            values[valKeys[key]] === null
          ) {
            request_data.append(valKeys[key], "");
          } else {
            request_data.append(valKeys[key], values[valKeys[key]]);
          }
        }
      }
    }

    let response = await axios.put(
      `${apiConfig.apiURL}/api/store/${site}/`,
      request_data,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function setImageSettings(site, image_crop_area) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/store/${site}/`,
      {
        image_crop_area: image_crop_area
      },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function uniqueStore(value, key) {
  let params = {
    storeName: value,
    key: key
  };
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/builder/check-existing-store`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    let res = response.data.data;
    let label = key == "storeName" ? "store name" : "store site";
    if (res) {
      throw {
        [key]: `Sorry, this is already taken. Please nominate another ${label}.`
      };
    } else {
      return false;
    }
  } catch (error) {
    throw error.response;
  }
}

async function getItems(store_uuid, params) {
  let sort;
  if (params.sort === "desc") {
    if (params.sortBy === "Sort by") {
      sort = "";
    } else if (params.sortBy === "name") {
      sort = "-name";
    } else if (params.sortBy === "created_on") {
      sort = "-created_on";
    } else if (params.sortBy === "quantity") {
      sort = "-quantity";
    } else if (params.sortBy === "price") {
      sort = "-price";
    }
  }
  params = {
    ...params,
    sortBy: params.sort === "asc" ? params.sortBy : sort,
    category: JSON.stringify(params.category)
  };
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/dashboard/${store_uuid}/products/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        },
        params
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function getStoreProductCategories(uuid) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/store/${uuid}/products-categories/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function addItem(product, store_uuid) {
  let paramsFormData = new FormData();
  paramsFormData.append("name", product.name);
  paramsFormData.append("price", product.price || 0);
  paramsFormData.append("discounted_price", product.discounted_price);
  paramsFormData.append("category", product.category);
  paramsFormData.append("description", product.description);
  paramsFormData.append("featured", product.featured);
  paramsFormData.append("always_available", product.always_available);
  paramsFormData.append("quantity", product.quantity);
  paramsFormData.append("is_active", product.is_active);
  paramsFormData.append("store", store_uuid);
  paramsFormData.append("product_code", product.product_code);
  paramsFormData.append("photo_primary", product.photo_primary);
  paramsFormData.append("has_variants", product.has_variants);
  for (let i in product["productImages"]) {
    paramsFormData.append(
      `gallery-${parseInt(i) + 1}`,
      product["productImages"][i],
      product["productImages"][i].name
    );
  }

  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/dashboard/${store_uuid}/products/`,
      paramsFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function removeItem(product) {
  try {
    let response = await axios.delete(
      `${apiConfig.apiURL}/api/dashboard/product/${product.uuid}`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data.data;
  } catch (error) {
    throw error.response;
  }
}

async function editItem(product, imageList, uuid) {
  let paramsFormData = new FormData();
  paramsFormData.append("name", product.name);
  paramsFormData.append("price", product.price || 0);
  paramsFormData.append("discounted_price", product.discounted_price);
  paramsFormData.append("category", product.category);
  paramsFormData.append("description", product.description);
  paramsFormData.append("featured", product.featured);
  paramsFormData.append("always_available", product.always_available);
  paramsFormData.append(
    "quantity",
    product.quantity === null ? 0 : product.quantity
  );
  paramsFormData.append("is_active", product.is_active);
  paramsFormData.append("store", uuid);
  paramsFormData.append("product_code", product.product_code);
  paramsFormData.append("photo_primary", product.photo_primary);
  paramsFormData.append("has_variants", product.has_variants);

  if (
    product["productImages"] === undefined ||
    product["productImages"] === "undefined"
  ) {
    for (let i in imageList) {
      paramsFormData.append(`gallery-${parseInt(i) + 1}`, imageList[i]);
    }
  } else {
    for (let i in product["productImages"]) {
      if (typeof product["productImages"][i] === "object") {
        paramsFormData.append(
          `gallery-${parseInt(i) + 1}`,
          product["productImages"][i],
          product["productImages"][i].name
        );
      } else {
        paramsFormData.append(
          `gallery-${parseInt(i) + 1}`,
          product["productImages"][i]
        );
      }
    }
  }
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/dashboard/product/${product.uuid}/`,
      paramsFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function getStores() {
  try {
    let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    let id = currentUser.id;
    let response = await axios.get(`${apiConfig.apiURL}/api/dashboard/store/`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      },
      params: { email: id }
    });

    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function getStoreDetails(value) {
  let response = await sentroAxios.get(
    `${apiConfig.apiURL}/api/store/${value.site}`,
    {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    }
  );
  return response;
}

async function buxStatus(value) {
  try {
    let response = await axios.get(`${apiConfig.apiURL}/api/kyc/?id=${value}`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    });
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function deleteShop(value) {
  try {
    let response = await axios.delete(
      `${apiConfig.apiURL}/api/store/${value.site}`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    // GTM trigger
    let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    window.dataLayer.push({
      eventAction: "Store Builder Deleted",
      eventCategory: "Store Builder",
      event: "storedeleted",
      userId: currentUser.id
    });
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function getOrder(params) {
  params = {
    ...params,
    page: params.page,
    delivery_status: params.delivery_status.toString()
  };
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/dashboard/orders/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        },
        params
      }
    );

    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function orderDetails(id) {
  try {
    let response = await axios.get(`${apiConfig.apiURL}/api/order/${id}`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function computeShipping(value) {
  let params = {
    src_address: value.src_address,
    dst_address: value.delivery_address,
    src_coord: { latitude: value.src_lat, longitude: value.src_long },
    dst_coord: { latitude: value.delivery_lat, longitude: value.delivery_long },
    matter: value.matter,
    vehicle_type: parseInt(value.vehicle_type),
    weight_kg: parseInt(value.weight_kg),
    motobox: value.motobox
  };
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/mrspeedy/calculate_order/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function bookShipping(value) {
  let params = {
    order: value.order_id,
    item_type: value.matter,
    sender_contact: value.src_mobile,
    sender_name: value.src_name,
    sender_address: value.src_address,
    src_coord: { latitude: value.src_lat, longitude: value.src_long },
    dst_coord: { latitude: value.delivery_lat, longitude: value.delivery_long },
    pickup_datetime: moment(value.date_time).format(),
    delivery_datetime: moment(value.date_time).format(),
    vehicle_type: parseInt(value.vehicle_type),
    weight_kg: parseInt(value.weight_kg),
    motobox: value.motobox
  };
  let response = await axios.post(
    `${apiConfig.apiURL}/api/mrspeedy/create_order/`,
    params,
    {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    }
  );
  return response.data;
}

async function orderPaymentUpdate(value) {
  try {
    let params = {
      paid: true,
      channel: value.channel,
      date: value.date
    };
    let response = await axios.put(
      `${apiConfig.apiURL}/api/order/${value.id}/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function orderDeliveryUpdate(value) {
  try {
    let params = {
      delivered: true,
      delivery_method: value.delivery_method,
      date: value.delivery_datetime
    };
    let response = await axios.put(
      `${apiConfig.apiURL}/api/order/${value.id}/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

export async function cancelDelivery(mrspeedyid) {
  let response = await axios.post(
    `${apiConfig.apiURL}/api/mrspeedy/cancel_order/${mrspeedyid}/`,
    {},
    {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    }
  );
  return response.data;
}

async function orderCancel(value) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/cancel-order/${value.id}/`,
      {},
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function tncAgreed(value) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/user/update/`,
      value,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    let data = response.data;
    let existing = sessionStorage.getItem("CURRENT_USER");
    existing = existing ? JSON.parse(existing) : {};
    existing["tnc_agreed"] = data.tnc_agreed;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function saveSurvey(value) {
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/user-survey/`,
      value,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error.response;
  }
}
async function fbPageID(store, fb_page_id) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/store/${store}/`,
      {
        fb_page_id
      },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function fbPixelID(store, fb_pixel_id) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/store/${store}/`,
      {
        fb_pixel_id
      },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function getProfileInfo() {
  try {
    let response = await axios.get(`${apiConfig.apiURL}/api/user/update/`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function saveProfileInfo(value) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/user/update/`,
      value,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    let data = response.data;
    let existing = sessionStorage.getItem("CURRENT_USER");
    existing = existing ? JSON.parse(existing) : {};

    existing["first_name"] = data.first_name;
    existing["last_name"] = data.last_name;
    existing["mobile_number"] = data.mobile_number;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function confirmChangePassword(value) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/password/`,
      {
        old_password: value.current_password,
        new_password: value.new_password
      },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );

    let data = response.data;
    let existing = sessionStorage.getItem("CURRENT_USER");
    existing = existing ? JSON.parse(existing) : {};
    existing["password_change"] = data.change_date;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function getVoucherService(params) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/promo/voucher_filter/?desktop=False&is_active=True`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function filterVoucherService(params) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/promo/voucher_filter/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        },
        params
      }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function createVoucherServices(params, stores) {
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/promo/voucher/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        },
        params: { store: stores[0].uuid }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}
async function patchVariantService(variant, uuid) {
  let params = [];
  for (let i in variant) {
    params.push({
      id: variant[i].id,
      product: uuid,
      name: variant[i].name,
      variants: variant[i].variants
    });
  }
  try {
    let response = await axios.patch(
      `${apiConfig.apiURL}/api/store/product/${uuid}/variants/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function getVariantsService(uuid) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/store/product/${uuid}/variants/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function editVoucherServices(params, stores, selectedVoucherCode) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/promo/voucher/${selectedVoucherCode}/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        },
        params: { store: stores[0].uuid }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function setOrderRead(values) {
  try {
    let params = {
      is_read: true
    };
    let response = await axios.put(
      `${apiConfig.apiURL}/api/order/${values.id}/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}
export {
  getStore,
  saveStore,
  getItems,
  updateStore,
  publish,
  uniqueStore,
  patchStore,
  addItem,
  removeItem,
  editItem,
  addPayment,
  getPayments,
  deletePayment,
  updatePayment,
  getStores,
  getStoreDetails,
  buxStatus,
  deleteShop,
  getOrder,
  orderDetails,
  computeShipping,
  bookShipping,
  orderPaymentUpdate,
  orderDeliveryUpdate,
  orderCancel,
  tncAgreed,
  saveSurvey,
  fbPageID,
  fbPixelID,
  getProfileInfo,
  saveProfileInfo,
  confirmChangePassword,
  getStoreProductCategories,
  setImageSettings,
  getVoucherService,
  createVoucherServices,
  editVoucherServices,
  setOrderRead,
  filterVoucherService,
  patchVariantService,
  getVariantsService
};
