import React from "react";
import sentroAdvancedImg from "../../assets/images/ads/sentrobanner.jpg";
import sentroAdvancedImg2 from "../../assets/images/ads/sentroAdvanced2.jpg";
import quickOrder from "../../assets/images/ads/quickorder.png";
import bux from "../../assets/images/ads/bux.jpg";
import { Carousel } from "antd";
import apiConfig from "../../common/apiConfig";
import { useHistory } from "react-router-dom";
import Path from "../../Paths";
import { useSelector } from "react-redux";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { getCODWallet } from "../payments/action";
import { useDispatch } from "react-redux";
import "./carousel.css";
export default function DashboardAds(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const subscription = useSelector(state => state.subscription.subscription);
  const codWallet = useSelector(state => state.payments.codWallet);
  const buxWallet = useSelector(state => state.webBuilder.buxStatus);
  const [values, setValues] = React.useState([]);

  // const data = [
  //   {
  //     path: "announcements",
  //     img: img
  //   },
  //   {
  //     path: "announcements",
  //     img: img
  //   },
  //   {
  //     path: "announcements",
  //     img: img
  //   }
  // ];

  React.useEffect(() => {
    if (subscription) {
      if (subscription.subscription_type === "advanced") {
        setValues([
          ...values,
          {
            name: "advanced",
            path: () => window.open(`${apiConfig.localHost}announcements`),
            img: sentroAdvancedImg
          }
        ]);
      } else {
        setValues([
          ...values,
          {
            name: "basic",
            path: () => history.push(Path.SENTRO_ADVANCED),
            img: sentroAdvancedImg2
          }
        ]);
      }
    }
  }, [subscription]);

  React.useEffect(() => {
    if (buxWallet === 0 && !codWallet) {
      setValues([
        ...values,
        { name: "bux", path: () => history.push(Path.PAYMENTS), img: bux }
      ]);
    }
  }, [codWallet]);

  React.useEffect(() => {
    dispatch(getCODWallet());
    setValues([
      { path: () => history.push(Path.QUICK_ORDER_SETTINGS), img: quickOrder }
    ]);
  }, []);
  return (
    <div>
      {/* {subscription && subscription.subscription_type === "advanced" ? ( */}
      <Carousel autoplay autoplaySpeed={5000}>
        {values.map((item, index) => (
          <img
            src={item.img}
            alt={index}
            // style={{ width: "auto", height: 400 }}
            onClick={item.path}
          />
        ))}
      </Carousel>
      {/* ) : buxWallet?.kyc_level === 0 && !codWallet ? (
        <img
          src={bux}
          alt="ads"
          width="100%"
          height="auto"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(Path.PAYMENTS)}
        />
      ) : (
        <img
          src={sentroAdvanced}
          alt="ads"
          width="100%"
          height="auto"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(Path.SENTRO_ADVANCED)}
        />
      )} */}
    </div>
  );
}
