import axios from "axios";
import apiConfig from "../../../common/apiConfig";

class Services {
  static async SEND_SMS_OTP(payload) {
    try {
      let response = await axios.post(`${apiConfig.apiURL}/api/ubx-services/sms/otp/`, payload, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        },
      });
      return response;
    } catch (error) {
      throw error.message;
    }
  }
  static async VERIFY_SMS_OTP(payload) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/ubx-services/sms/otp/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        },
        params: {...payload}
      });
      return response;
    } catch (error) {
      throw error.message;
    }
  }
}
export default Services;
