import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Dialog,
  Paper,
  Chip,
  LinearProgress,
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@material-ui/core";
import { getBuxStatus } from "../webBuilder/websiteBuilderActions";
import Utils from "../../../utils";
import buxLogo from "../../../../../assets/images/buxLOGO.png";
import image1 from "../../../../../assets/images/Bux_Card_Icon.svg";
import history from "../../../../../store/history";
import CloseIcon from "@material-ui/icons/Close";
import SentroButton from "../../../sentroButton";
import SalesAndOrdersActions from "../../../../salesAndOrders/actions";
import SentroLoading from "../../../loading";
import RedirectDialog from "../../../../dashboard/redirectDialog";
import DashboardActions from "../../../../dashboard/dashboardActions";
const MobileSalesDashboard = props => {
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));

  React.useEffect(() => {
    dispatch(getBuxStatus(user.id));
  }, []);
  const useStyles = makeStyles(theme => ({
    root: {
      height: "90vh",
      overflowY: "scroll",
      backgroundColor: "#f1f1f1",
      padding: 20
    },
    backDivWrapper: {
      display: "flex",
      "& > span": {
        marginLeft: "1em",
        fontWeight: 600
      },
      color: "#F97663 !important",
      alignItems: "center",
      cursor: "pointer",
      width: 100
    },
    layout: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    header: {
      fontSize: 16,
      color: "#002c42",
      fontWeight: "bold"
    },
    paper: {
      padding: 20,
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
      borderRadius: 8
    },
    buxAccnt: {
      width: 200,
      height: 40,
      borderRadius: 100,
      color: "#f5922f",
      backgroundColor: "#fff",
      boxShadow: "none",
      border: "1px solid #f5922f",
      "&:hover": {
        backgroundColor: "#fff"
      }
    },
    buxLevel: {
      color: "#F97663",
      fontWeight: "bold",
      "&:hover": {
        color: "#F97663",
        textDecoration: "underline"
      }
    }
  }));
  const classes = useStyles();
  const limitData = useSelector(state => state.salesAndOrders.cashLimit);
  const loading = useSelector(state => state.salesAndOrders.loading);
  const [open, setOpen] = React.useState(false);
  const [openBux, setOpenBux] = React.useState(false);
  const balance = limitData ? limitData.balance : "₱ 0.00";
  const kycLevel = limitData ? limitData.kyc_level : "";
  const cashInLimit = limitData ? limitData.cashin_limits : "";
  const cashOutLimit = limitData ? limitData.cashout_limits : "";
  const value_cashIn = cashInLimit ? cashInLimit[0] : 0;
  const cashIn_maxValue = cashInLimit ? cashInLimit[1] : 0;
  const value_cashOut = cashOutLimit ? cashOutLimit[0] : 0;
  const cashOut_maxValue = cashOutLimit ? cashOutLimit[1] : 0;
  const MIN = 0;
  const cashIn = value_cashIn =>
    ((value_cashIn - MIN) * 100) / (cashIn_maxValue - MIN);
  const cashOut = value_cashOut =>
    ((value_cashOut - MIN) * 100) / (cashOut_maxValue - MIN);
  let bgColor = "#d1d5dd";
  function barColor() {
    if (value_cashIn === cashIn_maxValue) {
      return (bgColor = "#cf3d1d");
    } else if (value_cashOut === cashOut_maxValue) {
      return (bgColor = "#cf3d1d");
    } else if (kycLevel === 3) {
      return bgColor;
    } else {
      return (bgColor = "#1dd28b");
    }
  }

  function proceedToCompleteProfile() {
    let status = kycLevel;
    dispatch(DashboardActions.goToCompleteProfile(user.id, status));
    setOpenBux(false);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const BorderLinearProgress = withStyles(theme => ({
    root: {
      height: 17,
      borderRadius: 15
    },
    colorPrimary: {
      backgroundColor: "#d1d5dd"
    },
    bar: {
      borderRadius: 5,
      backgroundColor: barColor()
    }
  }))(LinearProgress);
  function buxLevel() {
    let status = kycLevel;
    switch (status) {
      case 0:
        return "Basic";
      case 1:
        return "Pro";
      case 2:
        return "Advanced";
      case 3:
        return "Business";
      default:
        return "Basic";
    }
  }

  React.useEffect(() => {
    dispatch(SalesAndOrdersActions.getLimit(user.id));
  }, []);

  function statusLink() {
    return (
      <>
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
          <div style={{ padding: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10
              }}
            >
              <div
                style={{ fontSize: 20, fontWeight: "bold", color: "#002C42" }}
              >
                Wallet Size by Level
              </div>
              <div onClick={() => setOpen(false)} style={{ cursor: "pointer" }}>
                <CloseIcon />
              </div>
            </div>
            <div style={{ fontSize: 12, color: "#909196", marginBottom: 10 }}>
              Powered by Bux
            </div>
            <Table size="small" style={{ border: "1px solid" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: 600, fontSize: 12 }}
                    align="center"
                  >
                    Level
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 600, fontSize: 12 }}
                    align="center"
                  >
                    Period
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 600, fontSize: 12 }}
                    align="center"
                  >
                    Cash In
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 600, fontSize: 12 }}
                    align="center"
                  >
                    Cash Out
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={1}
                    style={{ fontWeight: 500, borderRight: "1px solid" }}
                  >
                    Basic
                  </TableCell>
                  <TableCell align="center">Monthly</TableCell>
                  <TableCell align="center">5,000</TableCell>
                  <TableCell align="center">0</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={1}
                    style={{ fontWeight: 500, borderRight: "1px solid" }}
                  >
                    Pro
                  </TableCell>

                  <TableCell align="center">Monthly</TableCell>
                  <TableCell align="center">100,000</TableCell>
                  <TableCell align="center">100,000</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={1}
                    style={{ fontWeight: 500, borderRight: "1px solid" }}
                  >
                    Advanced
                  </TableCell>

                  <TableCell align="center">Monthly</TableCell>
                  <TableCell align="center">500,000</TableCell>
                  <TableCell align="center">500,000</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 500, borderRight: "1px solid" }}
                  >
                    Business
                  </TableCell>
                  <TableCell align="center">Monthly</TableCell>
                  <TableCell align="center">1,000,000</TableCell>
                  <TableCell align="center">1,000,000</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div
              style={{
                margin: 15,
                padding: "0px 12vw",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <SentroButton
                style={{ padding: 0 }}
                onClick={() => setOpenBux(true)}
                label="Upgrade Wallet"
                variant="contained"
                fullWidth
              />
            </div>
          </div>
        </Dialog>
        <a
          id="status"
          className={classes.buxLevel}
          onClick={() => setOpen(true)}
        >
          {buxLevel()}
        </a>
      </>
    );
  }

  function buxCard() {
    return (
      <div>
        <RedirectDialog
          onClose={() => setOpenBux(false)}
          open={openBux}
          onProceed={() => proceedToCompleteProfile()}
          header={"Bux Dashboard"}
          text={"This page will redirect to your BUX dashboard"}
        />
        <Paper className={classes.paper}>
          <div
            className={classes.backDivWrapper}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
            <span>BACK</span>
          </div>
          <div className={classes.content}>
            <div
              style={{ display: "flex", padding: 5, justifyContent: "center" }}
            >
              <img src={image1} width="auto" height="75" alt="" />
            </div>
            <div style={{ flex: 10 }}>
              <div
                style={{
                  fontStyle: "normal",
                  fontWeight: "bold",
                  color: "#002c42",
                  fontSize: 16
                }}
              >
                {props.language === "Filipino"
                  ? "I-upgrade ang iyong online shop wallet"
                  : "Upgrade your online shop's wallet"}
              </div>
              <div style={{ fontSize: 12, marginTop: 8 }}>
                {props.language === "Filipino"
                  ? "Kasalukuyang nasa "
                  : "Your online shop's wallet is currently in "}{" "}
                {statusLink()}{" "}
                {props.language === "Filipino"
                  ? " ang iyong online shop wallet. I-upgrade ito ng walang bayad para tumaas ang pwede mong matanggap at ma-withdraw na pera"
                  : ". Upgrade it for free to increase the amount you can receive and withdraw so you can transact more."}
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
  return (
    <div>
      <SentroLoading open={loading} />
      <div className={classes.root}>
        <div style={{ paddingTop: "10vh" }}>
          <h2 style={{ fontSize: 20, color: "#002c42", fontWeight: 600 }}>
            Bux Dashboard
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "2vh 0"
            }}
          >
            <img src={buxLogo} style={{ width: 75, height: "auto" }} alt="" />
            <Button
              onClick={() => proceedToCompleteProfile()}
              className={classes.buxAccnt}
              variant="contained"
            >
              Go to my Bux account
            </Button>
          </div>
          <div>{buxCard()}</div>
          <div style={{ padding: "2vh 0" }}>
            <Paper className={classes.paper}>
              <div className={classes.layout}>
                <div className={classes.header}>Bux Balance</div>
                <div className={classes.header}>{balance}</div>
              </div>
              <div style={{ paddingTop: "2vh" }}>
                <Button
                  onClick={() => proceedToCompleteProfile()}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Cashout
                </Button>
              </div>
            </Paper>
          </div>
          <div style={{ paddingTop: "1vh" }}>
            <Paper className={classes.paper}>
              <div className={classes.layout}>
                <div className={classes.header}>Monthly limits tracker</div>
                <Chip
                  size="small"
                  style={{
                    color: "#fff",
                    backgroundColor: kycLevel === 3 ? "#ffb142" : "#65c1f2",
                    padding: "0 8px"
                  }}
                  label={buxLevel()}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <div className={classes.layout}>
                  <div className={classes.label1}>Cash-in</div>
                  <div className={classes.label2}>
                    PHP {Utils.commafy(value_cashIn)}/{" "}
                    {kycLevel === 3
                      ? "no limits"
                      : Utils.commafy(cashIn_maxValue)}
                  </div>
                </div>
                <div style={{ marginTop: 5 }}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={cashIn(value_cashIn)}
                  />
                </div>
              </div>

              <div style={{ marginTop: 10 }}>
                <div className={classes.layout}>
                  <div className={classes.label1}>Cash-out</div>
                  <div className={classes.label2}>
                    PHP {Utils.commafy(value_cashOut)}/{" "}
                    {kycLevel === 3
                      ? "no limits"
                      : Utils.commafy(cashOut_maxValue)}
                  </div>
                </div>
                <div style={{ marginTop: 5 }}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={cashOut(value_cashOut)}
                  />
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSalesDashboard;
