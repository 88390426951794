import { mediaActionTypes } from "./actions";

const initialState = {
  selected_images: [],
  images: [],
  searched_images: [],
  uploading_images_statuses: []
};

export default function mediaReducers(state = initialState, action) {
  switch (action.type) {
    case mediaActionTypes.SET_SELECTED_IMAGES:
      return {
        ...state,
        selected_images: action.payload
      };
    case mediaActionTypes.GET_MEDIA_IMAGES:
      return {
        ...state,
        images: action.payload,
        searched_images: action.params.search ? action.payload: []
      };
    case mediaActionTypes.DELETE_MEDIA_IMAGES:
      return {
        ...state,
        selected_images: [],
        images: state.images.filter(i => !action.payload.includes(i.id))
      };
    case mediaActionTypes.EDIT_MEDIA_IMAGE:
      
      const temp_images = state.images.map(i => action.payload.id === i.id ? action.payload: i);
      return {
        ...state,
        selected_images: [],
        images: temp_images
      }
    case mediaActionTypes.IMAGE_UPLOADING:
      return {
        ...state,
        uploading_images_statuses: [...state.uploading_images_statuses, 0]
      }
    case mediaActionTypes.SET_LOADING_PERCENTAGE:
      return {
        ...state,
        uploading_images_statuses: state.uploading_images_statuses.map(
            (val, index) => action.index === index ? action.payload: val)
      }
    case mediaActionTypes.ADD_IMAGE:
      return {
        ...state,
        images: [action.payload, ...state.images]
      }
    case mediaActionTypes.RESET:
      return {
        ...state,
        selected_images: [],
        searched_images: [],
        uploading_images_statuses: []
      }
    default:
      return state;
  }
}
