import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Menu,
  InputLabel
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useSelector, useDispatch } from "react-redux";
import StoreActions from "../StoreActions";
import history from "../../../../../store/history";
import { KeyboardArrowDown } from "@material-ui/icons";
const SortSection = props => {
  const useStyles = makeStyles(theme => ({
    input: {
      fontSize: 12
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  let store = useSelector(state => state.store.store);
  let product_categories = useSelector(state => state.store.product_categories);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [category, setCategory] = React.useState("All");
  const [sort, setSort] = React.useState("-created_on");
  const handleCategories = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSort = event => {
    setAnchorElSort(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleSelectCategory = (index, cat) => {
    setAnchorEl(null);
    setCategory(cat);
    if (props.categoryOnChange) props.categoryOnChange(categories[index]);
  };

  const handleSelectSort = (index, item) => {
    setAnchorElSort(null);
    setSort(item.name);
    if (props.sortOnChange) props.sortOnChange(item.value);
  };

  React.useEffect(() => {
    if (store.name) {
      dispatch(StoreActions.getStoreProductCategories(store.uuid));
    }
  }, [store]);
  React.useEffect(() => {
    if (product_categories.categories.length)
      setCategories(["All", ...product_categories.categories]);
  }, [product_categories]);

  const sortChoices = [
    { name: "Latest", value: "-created_on" },
    { name: "Oldest", value: "created_on" },
    { name: "A - Z", value: "name" },
    { name: "Z - A", value: "-name" },
    { name: "₱ - ₱₱₱ ", value: "price" },
    { name: "₱₱₱ - ₱ ", value: "-price" }
  ];

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <FormControl
        variant="outlined"
        className="mr-md-0 mr-3"
        margin="dense"
        style={{ width: 160 }}
      >
        {categories && categories.length ? (
          <Select
            className="bg-white"
            defaultValue=""
            IconComponent={KeyboardArrowDown}
            value={props.filters.category || ""}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            style={{ fontSize: 12 }}
          >
            {categories && categories.length
              ? categories
                  .filter(function(el) {
                    return el;
                  })
                  .map((item, index) => (
                    <MenuItem
                      value={item}
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        history.push(
                          `/shop/${
                            store.site
                          }/products/?category=${encodeURIComponent(
                            item
                          )}&sortBy=${encodeURIComponent(props.filters.sortBy)}`
                        );
                        setAnchorEl(null);
                      }}
                    >
                      {item || "Others"}
                    </MenuItem>
                  ))
              : ""}
          </Select>
        ) : (
          ""
        )}
      </FormControl>
      {/* <Button
        variant="text"
        style={{ fontSize: 12, color: props.color || "#2b2d32" }}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleCategories}
      >
        {props.filters.category || "Others"}
      </Button>
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {categories.length
          ? categories
              .filter(function(el) {
                return el;
              })
              .map((item, index) => (
                <MenuItem
                  value={item}
                  onClick={() => {
                    history.push(
                      `/shop/${
                        store.site
                      }/products/?category=${encodeURIComponent(
                        item
                      )}&sortBy=${encodeURIComponent(props.filters.sortBy)}`
                    );
                    setAnchorEl(null);
                  }}
                >
                  {item || "Others"}
                </MenuItem>
              ))
          : ""}
      </Menu> */}

      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className="d-md-flex d-none"
          style={{ fontSize: 12, marginRight: 10 }}
        >
          Sort by
        </div>
        <FormControl variant="outlined" margin="dense" style={{ width: 160 }}>
          <Select
            className="bg-white"
            defaultValue={sort}
            IconComponent={KeyboardArrowDown}
            value={sort}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            style={{ fontSize: 12 }}
          >
            {sortChoices.map((item, index) => (
              <MenuItem
                value={item.value}
                onClick={() => {
                  setSort(item.value);
                  history.push(
                    `/shop/${
                      store.site
                    }/products/?category=${encodeURIComponent(
                      props.filters.category
                    )}&sortBy=${encodeURIComponent(item.value)}`
                  );
                  setAnchorElSort(null);
                }}
              >
                {item.name || "Others"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <Button
          variant="text"
          color="secondary"
          style={{
            fontSize: 12,
            marginRight: 10,
            color: props.color || "#2b2d32"
          }}
          endIcon={<ArrowDropDownIcon />}
          onClick={handleSort}
        >
          {sort}
        </Button>
        <Menu
          keepMounted
          open={Boolean(anchorElSort)}
          anchorEl={anchorElSort}
          onClose={handleCloseSort}
        >
          {sortChoices.map((item, index) => (
            <MenuItem
              value={item.value}
              onClick={() => {
                setSort(item.name);
                history.push(
                  `/shop/${store.site}/products/?category=${encodeURIComponent(
                    props.filters.category
                  )}&sortBy=${encodeURIComponent(item.value)}`
                );
                setAnchorElSort(null);
              }}
            >
              {item.name || "Others"}
            </MenuItem>
          ))}
        </Menu> */}
      </div>
    </div>
  );
};

export default SortSection;
