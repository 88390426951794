import React from "react";
import {
  Dialog,
  IconButton,
  DialogContent,
  TextField,
  Button,
  Snackbar
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import FreeTrialModal from "../../../subscription/components/products/freeTrialModal";
import { showAlert } from "../../../common/commonActions";
import Chip from "@material-ui/core/Chip";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductCategory } from "../../websiteBuilderActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddProductCategory = props => {
  const { open, setOpen, categories, handleAddCategory } = props;
  const useStyles = makeStyles(theme => ({
    title: {
      color: "#002c42",
      fontSize: 24,
      fontWeight: 600
    },
    titleSection: {
      paddingTop: "2vh",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    alert: {
      display: "flex",
      alignItems: "center",
      fontSize: 12,
      backgroundColor: "#00e289",
      color: "#fff",
      padding: "0 5px"
    },
    categories: {
      marginTop: 10,
      padding: "0 30px",
      overflowY: "auto",
      height: "30vh"
    },
    chip: {
      background: "#EEEEEE",
      border: "1px solid #6F7585",
      borderRadius: 4,
      "& .deleteIcon": {
        height: 15,
        width: 15,
        color: "#6F7585"
      },
      fontSize: 14,
      fontWeight: 400,
      marginRight: 8,
      marginTop: 8
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const storeInfo = useSelector(state => state.webBuilder.storeInfo);
  const [openFreeTrial, setOpenFreeTrial] = React.useState(false);
  const [category, setCategory] = React.useState("");
  const [list, setList] = React.useState([]);
  const [categoriesToBeAdded, setCategoriesToBeAdded] = React.useState([]);
  const [alert, setAlert] = React.useState({
    open: false,
    severity: "success",
    message: ""
  });

  const add = category => {
    let array = list.map(val => val.toLowerCase());
    if (category === "") {
      setAlert({
        open: true,
        severity: "error",
        message: "This field is required."
      });
    } else if (array.indexOf(category.toLowerCase()) !== -1) {
      setAlert({
        open: true,
        severity: "error",
        message: "Category already exist!"
      });
    } else {
      setAlert({
        open: true,
        severity: "success",
        message: "Category successfully added!"
      });
      setCategory("");
      setList([...list, category]);
      setCategoriesToBeAdded([...categoriesToBeAdded, category]);
    }
  };

  const onClickAddCategory = () => {
    if (
      (!props.subscription.subscription_type && categories.length >= 5) ||
      (props.subscription.subscription_type &&
        !props.subscription.features.categories &&
        categories.length >= 5)
    ) {
      setOpenFreeTrial(true);
    } else {
      add(category);
    }
  };

  const [categoriesToBeDeleted, setCategoriesToBeDeleted] = React.useState([]);
  const onClickDelete = category => {
    setCategoriesToBeDeleted(val => [...val, category]);
    setList(list.filter(i => i !== category));
    setCategoriesToBeAdded(list.filter(i => i !== category));
  };

  const onSave = () => {
    categoriesToBeDeleted.forEach(category => {
      props.handleDeleteCategory(category, categoriesToBeDeleted);
      dispatch(deleteProductCategory(storeInfo.uuid, category));
    });

    categoriesToBeAdded.forEach(category => {
      handleAddCategory(category);
    });

    setOpen(false);
  };

  React.useEffect(() => {
    setList([]);
    setCategoriesToBeAdded([]);
    setCategoriesToBeDeleted([]);
    if (open && categories.length) {
      setList([...categories]);
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogContent style={{ padding: 0, position: "relative" }}>
        <div style={{ padding: "0 30px" }}>
          <div className={classes.titleSection}>
            <div className={classes.title}>Shop Categories</div>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon color="secondary" />
            </IconButton>
          </div>
          <div style={{ marginTop: 10 }}>
            <div style={{ fontSize: 12, color: "#54575f" }}>Name</div>
            <div className={classes.titleSection} style={{ paddingTop: 0 }}>
              <TextField
                style={{ width: "100%" }}
                variant="outlined"
                margin="dense"
                color="secondary"
                value={category}
                onChange={e => {
                  setCategory(e.target.value);
                }}
                inputProps={{ maxLength: 32 }}
                error={alert.severity === "error"}
                helperText={alert.severity === "error" ? alert.message : ""}
              />
              <Button
                style={{
                  marginLeft: 10,
                  height: 40,
                  marginTop: alert.severity === "error" ? -19 : 5
                }}
                variant="outlined"
                color="primary"
                onClick={onClickAddCategory}
              >
                Add
              </Button>
              <FreeTrialModal open={openFreeTrial} setOpen={setOpenFreeTrial} />
            </div>
          </div>
        </div>
        <div className={classes.categories}>
          {list
            .filter(i => !categoriesToBeDeleted.includes(i))
            .sort(function(a, b) {
              if (a && b) {
                if (a.toLowerCase() < b.toLowerCase()) return -1;
                if (a.toLowerCase() > b.toLowerCase()) return 1;
              }
              return 0;
            })
            .map(item => {
              return item === "" ? (
                ""
              ) : (
                <Chip
                  className={classes.chip}
                  label={item}
                  {...(item !== "Others"
                    ? { onDelete: () => onClickDelete(item) }
                    : {})}
                  deleteIcon={<CloseIcon className="deleteIcon" />}
                  variant="outlined"
                />
              );
            })}
        </div>
        <div style={{ padding: 12, position: "relative" }}>
          <Snackbar
            style={{ position: "relative" }}
            open={Boolean(alert.open)}
            autoHideDuration={5000}
            onClose={() => setAlert({ open: false })}
          >
            <Alert
              onClose={() => setAlert({ open: false })}
              severity={alert.severity}
            >
              {alert.message}
            </Alert>
          </Snackbar>
        </div>
        <div style={{ padding: "0 30px 30px", position: "relative" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="mb-2"
            disableElevation
            onClick={onSave}
          >
            Save categories
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
            fullWidth
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddProductCategory;
