import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  Slide,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  ButtonBase,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImageProcessor";
import Slider from "@material-ui/core/Slider";
import LoadImage from "blueimp-load-image";
import { ReactComponent as SquareSVG } from '../../../../assets/images/imageCropper/square.svg';
import { ReactComponent as PortraitSVG } from '../../../../assets/images/imageCropper/portrait.svg';
import { ReactComponent as LandscapeSVG } from '../../../../assets/images/imageCropper/landscape.svg';
import { ReactComponent as RotateSVG } from '../../../../assets/images/imageCropper/rotate.svg';


const useStyles = makeStyles(theme => ({
  root: {
    padding: 15
  },
  appBar: {
    position: "relative",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  title: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    width: "100%"
  },
  errorText: {
    fontSize: 12,
    color: "red"
  },
  closeBtn: { position: "absolute", left: 15 },
  previewButton: {
    height: 38
  },
  labelDescription: {
    fontSize: 14,
    marginBottom: 0,
    fontWeight: 500
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 10,
      left: 10,
      width: "calc(100vw - 20px)"
    }
  }
}));
const DialogTransition = props => <Slide direction="up" {...props} />;

const ImageCropperForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));


  const SentroSlider = withStyles({
    root: {
      color: primaryColor,
      height: 8
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit"
      }
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)"
    },
    track: {
      height: 8,
      borderRadius: 4
    },
    rail: {
      height: 8,
      borderRadius: 4
    }
  })(Slider);

  // CROPPER MODAL STATE
  const [open, setOpen] = React.useState(false);

  // STATE
  const aspectRatio = {
    square: 1/1,
    landscape: 16/9,
    portrait: 9/16
  }
  const initialState = {
    image: "",
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0,
    aspect: props.defaultCropperOrientation || "square",
    croppedAreaPixels: null,
    loading: false,
    errors: ""
  };
  const [state, setState] = React.useState(initialState)


  // SETTING ASPECT RATIO
  const setAspectRatio = (aspect) => {
    setState({ ...state, aspect })
  }


  // SETTING ROTATION
  const setRotation = (key) => {
    setState({
      ...state,
      rotation: state.rotation - 90
    })
  }


  // CROPPER FUNCTIONS
  const onCropChange = crop => {
    setState({ ...state, crop });
  };
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setState({ ...state, croppedAreaPixels });
  };
  const onZoomChange = zoom => {
    setState({ ...state, zoom });
  };


  // SUBMISSION
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
  }

  const submit = () => {
    // set loading
    setState({ ...state, loading: true });
    // get cropped image using croppedImage func
    const randomString = new Date().toISOString();
    getCroppedImg(
      props.image,
      state.croppedAreaPixels,
      state.rotation,
      `${randomString}.png`,
    )
    .then(file => {
      // use loadImage by blueimp-load-image to identify
      // and fix EXIF orientation for images taken in IOS
      LoadImage(
        file,
        img => {
          // Convert canvas image to Base64
          img = img.toDataURL();
          // Convert Base64 image to binary
          img = dataURItoBlob(img);
          // Convert to file
          img = new File([img], `${randomString}.png`, { type: "image/png" });
          props.onSubmit(img);
        },
        { orientation: true, canvas: true }
      );
      setOpen(false);
      setState(initialState);
    })
  };

  return (
    <>
      {
        props.children ? props.children(setOpen): ""
      }
      <Dialog
        className="image-cropper-modal"
        fullScreen={is_mobile ? true : false}
        fullWidth={is_mobile ? true : false}
        maxWidth={"sm"}
        open={open || props.open}
        TransitionComponent={DialogTransition}
      >
        <DialogContent className="px-4 py-4 px-md-5 py-md-4">
          <p className="mb-4">
            <strong>Adjust image</strong>
          </p>
          <IconButton className="ml-auto" style={{
            position: "absolute",
            top: 15, right: is_mobile ? 5: 28
          }} onClick={() => {
            setOpen(false);
            if (props.setOpen) props.setOpen(false);
          }}>
            <CloseIcon />
          </IconButton>
          <div className="px-3" style={{
              borderRadius: 4, border: "solid 1px #D4D5D8", backgroundColor: "#FAFAFA"}}>
            <div className="position-relative px-2" style={{
                height: "40vh", width: is_mobile ? "100%": 380}}>
              <Cropper
                image={props.image}
                crop={state.crop}
                zoom={state.zoom}
                aspect={aspectRatio[state.aspect]}
                rotation={state.rotation}
                objectFit="contain"
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                minZoom={1}
                maxZoom={5}
                zoomSpeed={0.75}
                showGrid={false}
                style={{
                  cropAreaStyle: {
                    color: "rgba(0,0,0,0.3)",
                    border: "dashed 1px white"
                  }
                }}
              />
            </div>
          </div>
          <div className="my-3 px-4 d-flex align-items-center justify-content-between">
            <ButtonBase>
              <SquareSVG 
                onClick={() => setAspectRatio("square")}
                fill={state.aspect === "square" ? primaryColor: "#858997"} />
            </ButtonBase>
            <ButtonBase>
              <PortraitSVG 
                onClick={() => setAspectRatio("portrait")}
                fill={state.aspect === "portrait" ? primaryColor: "#858997"} />
            </ButtonBase>
            <ButtonBase>
              <LandscapeSVG  
                onClick={() => setAspectRatio("landscape")}
                fill={state.aspect === "landscape" ? primaryColor: "#858997"} />
            </ButtonBase>
            <ButtonBase>
              <RotateSVG fill={"#858997"} onClick={setRotation} />
            </ButtonBase>
          </div>
          <div className="my-3">
            <p className={`${classes.labelDescription} mb-0`}>Zoom</p>
            <div>
              <SentroSlider
                valueLabelDisplay="auto"
                aria-label="zoom_slider"
                defaultValue={0}
                value={state.zoom}
                step={0.01}
                min={1}
                max={5}
                onChange={(event, val) =>
                  setState({
                    ...state,
                    zoom: val
                  })
                }
              />
            </div>
          </div>
          <div className="formGroup">
            <Button
              className={classes.submitButton}
              id="done"
              disableElevation
              fullWidth
              disabled={state.loading}
              variant="contained"
              color="primary"
              onClick={() => {
                submit();
              }}
            >
              {state.loading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : ( "Done" )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ImageCropperForm;
