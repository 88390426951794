import React, { Fragment } from "react";
import clsx from 'clsx';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from "@material-ui/core/Paper";
import ACL from "../../common/utilities/ACL";
import Action from "../Redux/Actions";
import AdminDashboardLayout from "../layout";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import Path from "../../../Paths";

function humanize(str) {
	var i, frags = str.split('_');
	for (i=0; i<frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join(' ');
}

const useStyles = theme => ({
	cardWrapper: {
		maxWidth: 410,
		margin: "0em 1.5em 1.5em",
		[theme.breakpoints.down("sm")]: {
			margin: "0em 1em 1em"
		},
	},
	card: {
		border: "1px solid #E6EAF0",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
		borderRadius: 4,
		backgroundColor: "white",
		padding: "2em",
		maxWidth: "100%"
	},
	pageHeader: {
		backgroundColor: "#F1F1F1",
		display: "flex !important",
		padding: "3em 2em 1.5em",
		alignItems: "center",
		fontWeight: 600,
		fontSize: 14,
		color: "#F97663 !important"
	},
	pageBackButton: {
		backgroundColor: "#F1F1F1",
		display: "flex !important",
		alignItems: "center",
		fontWeight: 600,
		fontSize: 14,
		padding: "3em 0em 1.5em",
		color: "#F97663 !important"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1
	},
	paddingTop: {
		marginTop: theme.spacing(2),
	},
	inputLabel: {
		color: "#212B36", 
		fontSize: 12,
		marginBottom: "0.5em !important"
	},
	input: {
		width: "100%"
	},
	radioGroup: {
		display: "flex",
		flexDirection: "row",
		width: "100%"
	},
	radio: {
		backgroundColor: 'transparent',
		border: "solid 1px rgb(249, 118, 99)",
		width: 24,
		height: 24,
		borderRadius: "50%",
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  margin: 3,
		  borderRadius: "50%",
		  background: 'white',
		  content: '""'
		}
	},
	checkedRadio: {
		backgroundColor: 'transparent',
		border: "solid 1px #d7d7d7",
		width: 24,
		height: 24,
		borderRadius: "50%",
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  margin: 3,
		  borderRadius: "50%",
		  background: "rgb(249, 118, 99)",
		  content: '""'
		}
	},
	deleteBtn: {
		height: 35
	},
	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
	formErrorMessage: {
		padding: "0.75em 1.5em",
		border: "solid 1px #C24A39",
		borderLeft: "solid 5px #C24A39",
		borderRadius: 4,
		background: "#c24a390f"
	}
});

class Form extends React.Component {

	componentDidMount(){
		localStorage.removeItem("USER_DETAIL_MODE")
	}

	render(){
		const {
			first_name,
			last_name,
			email,
			access_type,
		} = this.props.state;
		const { adminDashboard, mode = "edit", formErrors = [],
			classes, setPageMode, setDialogState, handleChange, handleAccessTypeChange } = this.props
		const { email: current_user_email } = adminDashboard.currentUser 
		return (
			<Fragment>
				<div style={{ padding: "0"}}>
					<div style={{
						display: "flex", 
						alignItems: "center",
						marginBottom: "1.5em"
					}}>
						<div className={classes.pageTitle}>
							{
								mode === "create" ? 
								"Add Admin User"
								:
								"Edit Admin User"
							}
						</div>
					</div>
					<Paper className={classes.card}>
						{
							Object.keys(formErrors).map(key => 
								<p className={classes.formErrorMessage}>{`${humanize(String(key))}: ${humanize(String(formErrors[key]))}`}</p>
							)
						}
						<FormControl className={`
							${classes.margin}
							${classes.textField}
							${classes.input}
						`} variant="outlined">
							<p className={classes.inputLabel}>First Name</p>
							<OutlinedInput
								id=""
								value={first_name}
								className={classes.input}
								onChange={(event) => handleChange(event, 'first_name')}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
								'aria-label': 'weight',
								}}
								labelWidth={0}
								margin="dense"
								fullWidth={true}
							/>
						</FormControl>
						<FormControl className={`
							${classes.margin} 
							${classes.textField} 
							${classes.paddingTop}
							${classes.input}
						`} variant="outlined">
							<p className={classes.inputLabel}>Last Name</p>
							<OutlinedInput
								id=""
								value={last_name}
								className={classes.input}
								onChange={(event) => handleChange(event, 'last_name')}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
								'aria-label': 'weight',
								}}
								labelWidth={0}
								margin="dense"
								fullWidth={true}
							/>
						</FormControl>
						<FormControl className={`
							${classes.margin} 
							${classes.textField} 
							${classes.paddingTop}
							${classes.input}
						`} variant="outlined">
							<p className={classes.inputLabel}>Email Address</p>
							<OutlinedInput
								disabled={mode !== "create"}
								id=""
								value={email}
								className={classes.input}
								onChange={(event) => handleChange(event, 'email')}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
								'aria-label': 'weight',
								}}
								labelWidth={0}
								fullWidth={true}
								margin="dense"
							/>
						</FormControl>
						<FormControl component="fieldset" className={`
							${classes.radioGroup} 
							${classes.paddingTop}
						`}>
							<p className={classes.inputLabel}>Access Type</p>
							<RadioGroup aria-label="access_type" name="access_type" className={classes.radioGroup}
									value={access_type} onChange={handleAccessTypeChange}
							>
								<FormControlLabel 
									style={{ flexGrow: 1}}
									value="is_superuser"
									control={
										<Radio 
											color="primary" 
											icon={<span className={classes.radio} />} 
											checkedIcon={<span className={clsx(classes.radio, classes.checkedRadio)} 
										/>
									}/>} 
									label="Admin"
								/>
								{
									current_user_email === email?
									""
									:
									<FormControlLabel
										style={{ flexGrow: 1}}
										value="is_admin"
										control={
											<Radio 
												color="primary" 
												icon={<span className={classes.radio} />} 
												checkedIcon={<span className={clsx(classes.radio, classes.checkedRadio)} 
											/>
										}/>} 
										label="View Only"
									/>
								}
							</RadioGroup>
						</FormControl>
						<Button variant="contained" color="primary" disableElevation fullWidth
							className={classes.paddingTop}
							onClick={(event) => setDialogState(true, mode === "create" ? "create" : "edit")}
						>
							Submit
						</Button>
					</Paper>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
const FormUser = connect(mapStateToProps)(withStyles(useStyles)(Form));
export { FormUser }


class AddUserAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			access_type: 'is_admin',
			first_name: '',
			last_name: '',
			email: '',
			is_admin: true,
			is_superuser: false,

			dialogType: "edit",
			dialogStat: false,
			dialogErrorMessage: '',
			dialogTitleText: '',
			dialogBody: '',

			formErrors: [],
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleAccessTypeChange = this.handleAccessTypeChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.setDialogState = this.setDialogState.bind(this)
	}
	
	handleChange = (event, stateName) => {
		
		this.setState({
			[stateName] : event.target.value
		}, () => {
			console.log(this.state)
		})
	}
	
	handleAccessTypeChange(event) {
		this.setState({
			is_admin: event.target.value === "is_admin",
			is_superuser: event.target.value === "is_superuser",
			access_type: event.target.value
		}, () => {
			console.log(this.state)
		})
	}
	
	setDialogState(state, dialogType = "edit") {
		this.setState({
			dialogStat : state,
			dialogType: state ? dialogType: this.state.dialogType
		})
	}

	handleSubmit = async () => {
		const { dispatch, history } = this.props
		const { is_admin, is_superuser, first_name, last_name, email } = this.state
		this.setDialogState(false)
		const response = await dispatch(Action.signupAdminUser({
			email, first_name, last_name, is_admin, is_superuser
		}))
		if (response.status == 201) {
			localStorage.setItem("ALERT_SUCCESS", `${first_name} ${last_name} (${email}) is added. Email verification sent.`)
			history.push(Path.ADMIN_ADMIN_USERS)
		} else {
			this.setState({
				formErrors: response.response ? response.response.data : response.message
			})
		}
	}

	handleDelete = async () => {
		const { dispatch, match, history } = this.props
		const admin_email = match.params.admin_email
		const response = await dispatch(Action.userDetailMethod("delete", admin_email, {}))
		if (response.status == 200 || response.status == 204) {
			this.setState({
				dialogType: 'info',
				dialogTitleText: 'Success',
				dialogBody: 'User Deleted Successfully. Redirecting...'
			})
			setTimeout(() => {
				history.push(Path.ADMIN_ADMIN_USERS)
			}, 2000)
		} else {
			this.setState({
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}
	
	render (){
		const {
			first_name,
			last_name,
			email,
			access_type,
			dialogStat,
			dialogType,
			dialogTitleText,
			dialogBody,
			dialogErrorMessage,
			formErrors
		} = this.state;
		const { classes, adminDashboard, history } = this.props
		const dialogTitle = () => {
			if (dialogType == "create") {
				return "Please confirm"
			} else if (dialogType==="info") {
				return dialogTitleText
			}
		}
		const dialogContent = () => {
			if (["create"].includes(dialogType)) {
				return `Add ${first_name} ${last_name} (${email}) as ${access_type==="is_superuser" ? "Admin" : "View Only"}?`
			} else if (dialogType==="info") {
				return dialogBody
			}
		}
		const actionButton = () => {
			if (dialogType==="create") {
				return (
					<Button
						id="submit_button"
						onClick={(event) => this.handleSubmit()} 
						color="primary" 
						autoFocus
						variant="contained"
						disableElevation 
						className={classes.modalActions}
						style={{marginLeft: 10}}
					>
						Submit
					</Button>
				)
			}
		}
		return (
			<AdminDashboardLayout>
				<ACL roles={["superuser"]} redirect_to="/admin/dashboard">
					<a className={classes.pageHeader} onClick={() => {
						history.push(Path.ADMIN_ADMIN_USERS)
					}}>
						<ArrowBackIcon/>
						<span style={{marginLeft: "1em"}}>{`  BACK`}</span>
					</a>
					<div>
						{ 
						adminDashboard.error ?
						"" 
						:
						<Fragment>
							<Dialog
								open={dialogStat}
								onClose={(event) => this.setDialogState(false)}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
								maxWidth="xs"
							>	
								<div className={classes.modalDiv}>
									<div className={classes.modalTile}>
										{dialogTitle()}
									</div>
									<div className={classes.modalBody}>
										{dialogContent()}
									</div>
									<div className={classes.modalActionsWrapper}>
										<Button
											variant="outlined"
											onClick={(event) => this.setDialogState(false)} 
											color="primary"
											className={classes.modalActions}
										>
											{
												dialogType == "info" ?
												"Close"
												:
												"Cancel"
											}
										</Button>
										{actionButton()}
									</div>
								</div>
							</Dialog>
							<div className={classes.cardWrapper}>
								<FormUser
									mode="create"
									formErrors={formErrors}
									state={this.state} 
									setPageMode={this.setPageMode}
									setDialogState={this.setDialogState}
									handleAccessTypeChange={this.handleAccessTypeChange}
									handleChange={this.handleChange}
									handleSubmit={this.handleSubmit}
									handleDelete={this.handleDelete}
								/>
							</div>
						</Fragment>
						}
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

export default connect(mapStateToProps)(withStyles(useStyles)(AddUserAdmin));
