import React from "react";

import ProductContentStyles from "./productContentStyles";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  CardContent,
  Button
} from "@material-ui/core";
import imgPlaceholder from "../../../../assets/images/product_placeholder.png";
import { useSelector } from "react-redux";
import Utils from "../../utils";
import defaultSRC from "./defaultResources";
export default function PreviewProductCard(props) {
  const { storeInfo } = props;
  let productList = useSelector(state => state.store.products);
  const ProductCardStyles = makeStyles(theme => ({
    card: {
      width: 320,
      height: 455,
      margin: 20,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 8,
      [theme.breakpoints.down("md")]: {
        margin: 10
      },
      [theme.breakpoints.down("sm")]: {
        width: "40%"
      },
      [theme.breakpoints.down("xs")]: {
        margin: "10px 2px",
        width: "48%",
        height: 250
      }
    },
    productImage: {
      height: 320,
      [theme.breakpoints.down("xs")]: {
        height: 155
      }
    },

    cardContent: {
      [theme.breakpoints.down("xs")]: {
        padding: "5px 10px"
      }
    },

    cardActions: {
      padding: "0 8px"
    },
    button: {
      fontSize: 14,
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        height: 30
      }
    }
  }));
  // const productImage = props.item
  //   ? props.item
  //     ? props.item.gallery.length
  //       ? props.item.gallery[0].image
  //       : imgPlaceholder
  //     : imgPlaceholder
  //   : imgPlaceholder;
  function productImage() {
    let item = props.item;
    if (item.gallery) {
      if (item.gallery.length) {
        return item.gallery[0].image;
      } else {
        return imgPlaceholder;
      }
    } else {
      return item.images;
    }
  }
  const classes = ProductCardStyles();
  return (
    <Card
      className={classes.card}
      onClick={() => {
        // props.onSelect(props.item);
        window.scrollTo(0, 0);
      }}
    >
      <CardActionArea>
        <CardMedia
          className={classes.productImage}
          image={productImage()}
          title={productList.name}
        />
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="body1"
            component="h2"
            style={{
              fontSize: 18,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontFamily:
                defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                  .headerFont
            }}
          >
            {props.item ? props.item.name : "Your Product's Name"}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{
              fontFamily:
                defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                  .secondaryFont
            }}
          >
            ₱ {Utils.commafy(parseFloat(props.item ? props.item.price : 0))}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Button
          id="view_details_button"
          onClick={() => {
            props.onSelect(props.item);
            window.scrollTo(0, 0);
          }}
          className={classes.button}
        >
          View details
        </Button>
      </CardActions>
    </Card>
  );
}
