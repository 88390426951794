import axios from "axios";
import apiConfig from "../../../../common/apiConfig";

require("dotenv").config();
class StoreServices {
  static async getStoreInfo(value) {
    let response = await axios.get(`${apiConfig.apiURL}/api/store/${value}`);

    return response;
  }

  static async getProducts(store_uuid, params) {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/dashboard/${store_uuid}/products`,
      {
        params
      }
    );
    return response;
  }

  static async getStoreProductCategories(uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/store/${uuid}/products-categories/`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getProduct(uuid) {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/store/product/${uuid}`
    );
    return response.data;
  }

  // let value = {
  //   full_name: firstName + " " + lastName,
  //   address:
  //     streetAddress +
  //     ", " +
  //     barangayAddress +
  //     ", " +
  //     cityAddress +
  //     ", " +
  //     provinceAddress,
  //   postalCode: postalCode,
  //   mobile_number: mobileNumber,
  //   amount: totalAmt + shippingFee,
  //   other_fees: shippingFee,
  //   subtotal: totalAmt,
  //   recipient: email,
  //   uuid: storeInfo.uuid,
  //   cart: JSON.stringify(cart),

  static async checkOut(value, paymentType) {
    let params;
    if (paymentType["paymentOption"] === "otc") {
      params = {
        ...value,
        channel: null,
        payment_option: paymentType["paymentOption"]
      };
    } else {
      params = {
        ...value,
        channel: paymentType["account"],
        payment_option: paymentType["paymentOption"]
      };
    }
    params = {
      ...params,
      voucher: value.voucher,
      items_total_qty: value.items_total_qty,
      purchase_amount: value.purchase_amount,
      is_read: false
    };

    try {
      let res;
      let token = sessionStorage.getItem("TOKEN");

      if (token) {
        res = await axios.post(
          `${apiConfig.apiURL}/api/order/${value.uuid}/`,
          params,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
            }
          }
        );
      } else {
        res = await axios.post(
          `${apiConfig.apiURL}/api/order/${value.uuid}/`,
          params
        );
      }

      // GTM trigger
      window.dataLayer.push({
        event: "checkout",
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: null },
            products: JSON.parse(value.cart).map(product => ({
              id: value.uuid,
              name: product.name,
              price: product.unit_price,
              quantity: product.quantity,
              [`dimension${apiConfig.indexStoreID}`]: product.store
            }))
          }
        }
      });
      return res;
    } catch (error) {
      throw error.response;
    }
  }

  static async buxCheckout(value) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/order/check_code/?ref_id=${value.reference_id}`
      );
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async maintenance() {
    let response = await axios.get(`${apiConfig.apiURL}/api/`);

    return response;
  }

  //   static async create1212Promo(payload) {
  //     try {
  //       let response = await axios.post(
  //         `${apiConfig.apiURL}/api/promo/promos/`,
  //         payload
  //       );
  //       return response;
  //     } catch (error) {
  //       throw new Error(error.message);
  //     }
  //   }

  static async checkEligibility(payload) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/promo/promo_eligibility/${payload.promo_code}/${payload.email}/`,
        {
          params: {
            amount: payload.amount
          }
        }
      );
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async getVouchers(uuid, params) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/promo/voucher/`, {
        params: { store: uuid, ...params }
      });
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async uniqueEmail(value) {
    let response = await axios.get(`${apiConfig.apiURL}/api/unique-email`, {
      params: {
        email: value
      }
    });
    return response;
  }

  static async createBuyerAccount(params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/signup/`,
        params
      );
      sessionStorage.setItem("TOKEN", response.data.token);
      sessionStorage.setItem("CURRENT_USER", JSON.stringify(response.data));
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async buyerLogin(params) {
    try {
      let response = await axios.post(`${apiConfig.apiURL}/api/login/`, params);
      let data = response.data;
      if (data.token != null && data.token != "") {
        sessionStorage.setItem("TOKEN", data.token);
        sessionStorage.setItem("CURRENT_USER", JSON.stringify(data.user));
      }
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async resend(value) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/resend-verification/`,
        { email: value }
        //   {
        //     headers: {
        //       Authorization: `JWT ${localStorage.getItem("TOKEN")}`
        //     }
        //   }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async forgotPassword(email) {
    let params = {
      email: email
    };
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/password-reset/`,
        params
      );
      localStorage.setItem("CHANGE_PASSWORD_EMAIL", params.email);
      return response;
    } catch (e) {
      throw e.response;
    }
  }

  static async isExist(value) {
    let response = await axios.get(`${apiConfig.apiURL}/api/unique-email`, {
      params: {
        email: value
      }
    });
    return response;
  }

  static async getDeliveryLocations(uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/delivery-locations/?store_uuid=${uuid}`
      );
      return response;
    } catch (e) {
      throw e.response;
    }
  }

  static async computeShipping(params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/mrspeedy/calculate_order/`,
        params
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getBuyerCart(items) {
    let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/cart/${user.id}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async patchBuyerCart(items) {
    let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    try {
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/cart/${user.id}/`,
        {
          items
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async ninjavanShippingFee(params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/ninjavan/shipping-fee/`,
        {
          params
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async computeFees(params) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/order-fees/`, {
        params
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }
}

export default StoreServices;
