import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../../../assets/images/enterprise/sentro_build_logo.png";
import { ReactComponent as marketplace } from "../../../../../assets/images/enterprise/marketplace.svg";
import { ReactComponent as merchant } from "../../../../../assets/images/enterprise/merchant.svg";
import { ReactComponent as customers } from "../../../../../assets/images/enterprise/customers.svg";
import { ReactComponent as users } from "../../../../../assets/images/enterprise/users.svg";
import { ReactComponent as analytics } from "../../../../../assets/images/enterprise/analytics.svg";
import { ReactComponent as vouchers } from "../../../../../assets/images/enterprise/vouchers.svg";
import { List, ListItem, ListItemIcon, Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import MuiListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/styles";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";
import { useSelector } from "react-redux";
const useStyles = makeStyles(theme => ({
  root: {
    width: 240,
    backgroundColor: "#002C42",
    borderRadius: "0px 16px 0px 0px",
    height: "100%",
    overflow: "auto",
    position: "relative",
    "&::-webkit-scrollbar": {
      height: 0,
      width: 0,
      background: "transparent"
    }
  },
  listLabel: {
    color: "#fff",
    fontWeight: 500,
    fontSize: 14
  },
  listBtn: {
    padding: "14px 14px 14px 0",
    backgroundColor: "#002C42",
    display: "flex",
    justifyContent: "space-between"
  },
  btnSeller: {
    height: 40,
    borderRadius: 0,
    color: "#fff",
    fontWeight: 500,
    border: "1px solid #fff"
  },
  logoWrap: {
    padding: "24px 40px",
    borderBottom: "1px solid #1E4B83",
    cursor: "pointer"
  }
}));

const CustomListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#1E4B83"
    },
    "&$selected:hover": {
      backgroundColor: "#1E4B83"
    },
    "&:hover": {
      backgroundColor: "#1E4B83"
    }
  },
  selected: {}
})(MuiListItem);

const Sidebar = props => {
  const classes = useStyles();
  let location = useLocation();
  const [active, setActive] = React.useState("marketplace");
  const enterprise = useSelector(state => state.enterprise.main.enterprise);

  const listContent = [
    {
      icon: marketplace,
      label: "My Marketplace",
      key: "marketplace",
      path: `/enterprise/${enterprise.site_name}/dashboard`,
      id: "marketplace"
    },
    {
      icon: merchant,
      label: "Merchants",
      key: "merchants",
      path: `/enterprise/${enterprise.site_name}/dashboard/merchants`,
      id: "merchants"
    },
    {
      icon: customers,
      label: "Buyers",
      key: "buyers",
      path: `/enterprise/${enterprise.site_name}/dashboard/buyers`,
      id: "buyers"
    },
    {
      icon: users,
      label: "Users",
      key: "users",
      path: `/enterprise/${enterprise.site_name}/dashboard/users`,
      id: "users",
      isDisabled: true
    },
    {
      icon: analytics,
      label: "Analytics",
      key: "analytics",
      path: `/enterprise/${enterprise.site_name}/dashboard/analytics`,
      id: "analytics",
      isDisabled: true
    },
    {
      icon: vouchers,
      label: "Vouchers",
      key: "vouchers",
      path: `/enterprise/${enterprise.site_name}/dashboard/vouchers`,
      id: "vouchers",
      isDisabled: true
    }
  ];

  return (
    <div className={classes.root}>
      <div className={classes.children}>
        <div
          className={classes.logoWrap}
          onClick={() =>
            history.push(`/enterprise/${enterprise.site_name}/dashboard`)
          }
        >
          <img src={logo} alt="sentro_logo" style={{ maxWidth: 150 }} />
        </div>
        <p
          style={{
            color: "#FFFFFF",
            opacity: 0.7,
            fontSize: 12,
            padding: "12px 20px",
            margin: 0
          }}
        >
          Manage
        </p>
        <List style={{ padding: 0 }}>
          <div
          // style={{ borderBottom: "1px solid #1E4B83" }}
          >
            {listContent.map((item, index) => (
              <CustomListItem
                key={index}
                button
                id={item.id}
                selected={active.includes(item.key)}
                disabled={item.isDisabled}
                className={classes.listBtn}
                onClick={() => {
                  history.push(item.path);
                  setActive(item.key);
                }}
              >
                <div style={{ display: "flex" }}>
                  <ListItemIcon style={{ minWidth: 56 }}>
                    {/* <img
                      src={item.icon}
                      alt={item.icon}
                      style={{ margin: "auto" }}
                    /> */}
                    <item.icon fill="white" style={{ margin: "auto" }} />
                  </ListItemIcon>
                  <div className={classes.listLabel}>{item.label}</div>
                </div>
              </CustomListItem>
            ))}
          </div>
        </List>
      </div>
    </div>
  );
};

export default Sidebar;
