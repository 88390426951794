import axios from "axios";
import apiConfig from "../../../common/apiConfig";

class SubscriptionServices {
  static async getSubscription() {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/subscription/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      });

      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async subscriptionPayment(params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/subscription/subscribe/`,
        params,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }
}

export default SubscriptionServices;
