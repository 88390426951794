import React from 'react';
import { makeStyles, Button, IconButton, Dialog, Slide,
  AppBar, Toolbar } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  btn: {
    color: "#F97663",
    background: "white",
    borderRadius: 4,
    border: "1px solid #F97663",
    height: "35px",
    minWidth: 70,
    fontSize: 12,
    fontWeight: "normal"
  },
  selected: {
    backgroundColor: "#C24A39 !important",
    color: "white",
  }
}));

const ChipGroup = (props) => {
  const [filters, setFilters] = React.useState([])
  const handleDelete = (index, choice) => {
    if (index !== 0) {
      let newFilters = props.choices;
      if (choice !== "All"){
        newFilters = props.choices.filter(e => e !== choice)
      } else {
        newFilters = [newFilters[0]]
      }
      if (props.handleDelete) props.handleDelete(newFilters)
    }
  }
  React.useEffect(() => {
    if (props.choices.includes("All")) {
      setFilters([props.choices[0], "All"])
    } else {
      setFilters(props.choices)
    }
  }, [props.choices])
  return (
    <div className="px-3 pb-2">
      {
        filters.map((choice, index) => (
          <Chip key={index} label={choice || "Others"} className="m-1"
            onDelete={() => handleDelete(index, choice)} color="primary" 
            variant="outlined" size="small" />
        ))
      }
    </div>
  )
}

export default ChipGroup;