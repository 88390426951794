import React from "react";
import {
  Typography,
  Box,
  Dialog,
  FormControl,
  Card,
  Collapse,
  CardActions,
  IconButton,
  makeStyles,
  Paper,
  Button,
  Divider
} from "@material-ui/core";
import { bankListing } from "./bankContants";
// import { red } from "@material-ui/core/colors";

import clsx from "clsx";
import Text from "../../../common/text";
import { useSelector, useDispatch } from "react-redux";
import { ExpandMore, Add } from "@material-ui/icons";
import SentroButton from "../../common/sentroButton";
import { red } from "@material-ui/core/colors";
import PaymentFormDialog from "./paymentFormDialog";
import {
  getPaymentMethods,
  selectPaymentMethod,
  removePayment
} from "../websiteBuilderActions";
import {
  unionbank,
  bpi,
  rcbc,
  otc1,
  otc2,
  otc3,
  otc4,
  otc5,
  otc6,
  otc7,
  otc8,
  otc9,
  otc10,
  otc11,
  otc12,
  otc13,
  otc14,
  otc15
} from "./logoImages";
import PaymentFormDialogEdit from "./paymentFormDialogEdit";
import { Alert } from "antd";
import storePaymentContent from "../formContent/storePaymentContent";

let PaymentForm = props => {
  // GTM trigger
  React.useEffect(() => {
    let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    window.dataLayer.push({
      eventAction: "Step - Payment",
      eventCategory: "Store Builder",
      event: "storebuildersteptrack",
      userId: currentUser.id
    });

    // HotJar heat map
    if (typeof window.hj === "function") {
      window.hj("trigger", "storeBuilderPayment");
    }
  }, []);

  let defaultImg =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAUvSURBVHgB7d3Pi9R1HMfxTxE7F9eDsx3KQymYG4QHacFYURoIBA+rgWuQ3brUX6MXu3SrS0cDKQg2DJaCBQ/moQiSDq6X3YvrZfdi+xEGiuzFfHW+88N5PEA8ON9hGebpfHY/n31/X7p67frjAjzVywX4XwKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUyYTmeuMDkEMkGWlk6WKx9/VLrdbmEyCGRC1DiW3j355BPk8urFsrj4VmH8BDIB+nH8U+/9M+XEiXcK4yWQMTu9fOo/cfzr35ZOFsbnlcLY9HpnyuLxvJTqx7OxcbswegIZg/p9xrlzH5TDr7820ONFMj4CGbEax4WV841/UiWS8fA9yAjNzx94pjj6aiS93tnC6PgEGZF+HPPz8+V5LB4/9uTvtbVbhfb5BBmBYcXRVyNZ3d8rsevePoG0bNhx9C3sL9NWnjzvgUJ7BNKibvdQWb10cehx9NVILoikVQJpSY2jvnk7nU5pU41PJO0RSAvqOapRxNEnkvYIZMhqHPUc1aji6BNJOwQyRPVwYY1jXGokq5c+dFx+iOyDDMnTTuQmOzs7pYm5uc5AP9bt79R/9/0PZXPzQeH5CGRI+ht4g1pb+6ncb/AGrvENerK3RvL2/lJPIM/PEgsCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAwlUdN6i8KLZ8+VSZJ01+KWl4+VXb39gZ+/EGndMdiKgOpZ40GnSk1qRYWnLidBpZYEAgEAoFAIBAIBAKBQCAQCAQCgUAgEMzMVJP795tN+Dh8uN2d+q2t7bK72+CoycF5Q+HGYCYC2dl5VG58e7PRNZ9/9mlp0/r6L62N/WF4LLEgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEL129dv1xmTJ1osnKyvlG1zS95Vlb9zbv293dLXsNxv7Mzc01ujFo0+cfha++/qZMm5k5rNj2G76p+mZv8064bT//rLDEgkAgEAgEAoFAIBAIBAKBQCAQCAQCgWAmdtLreJ179/5qdM3i4rHSpj/3v569BmN/Fl7tloXuodKWOvmlyWikevTl6NE3yotuJgKpZ5LWfrzV6Jq2A/n1zt3GY3/aDOThw51Gr1Gd0TULgVhiQSAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCGZm7A/jd/2LL8u08QkCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgmJmxP+vrPze6ptc7W9q0sXG70V2vjhx5c/9Pe1NEtra2y507dwd+fKczV5aX3ysvupkZ+/Pb7380uqbtQDY3HzQa+1PvkNVmIPU/kSavUR37MwuBWGJBIBAIBAKBQCAQCAQCgUAgEAgEAoFAMBM76VXdiZ4k9euZn3808OM7nU5pUz060uQ1Ori/kz4LZiKQeizikyuXyyTp9c6USbKw0J2412gSWGJBIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIpvKoydb2drlx42aBtk1lIHVETZOROfCsLLEgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBD8DcdF1Hbi/9DMAAAAAElFTkSuQmCC";

  function storeInfoChanges(value, name) {
    props.setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const dispatch = useDispatch();
  let id = props.storeInfo ? props.storeInfo.uuid : "";

  React.useEffect(() => {
    dispatch(getPaymentMethods(id));
  }, []);
  let btnDeadline = [
    { label: "6 hours", value: 6 },
    { label: "12 hours", value: 12 },
    { label: "24 hours (1 day)", value: 24 },
    { label: "48 hours (2 days)", value: 48 },
    { label: "72 hours (3 days)", value: 72 },
    { label: "168 hours (7 days)", value: 168 }
  ];

  function handleSelectedDeadline(e) {
    storeInfoChanges(e, "payment_deadline");
  }

  let defualtthumb =
    "https://sentro-store-images.s3.amazonaws.com/store_media/47/b4f41be5-d29.png";
  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: "50vw"
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatar: {
      backgroundColor: red[500]
    },
    logoStyle: {
      width: "auto",
      height: 32,
      margin: "0 6px"
    },
    link: {
      color: "#F97663",
      fontWeight: 600,
      "&:hover": { textDecoration: "underline", color: "#F97663" }
    },
    btnDelete: {
      margin: 5,
      width: 80,
      "&:hover": {
        border: "1px solid #54575f"
      }
    },
    btnEdit: { margin: 5, width: 80 },
    addPaymentMethodBtn: {
      marginLeft: "auto",
      minWidth: 180,
      maxWidth: 180,
      [theme.breakpoints.down("sm")]: {
        marginTop: "1em",
        minWidth: "100%",
        maxWidth: "100%"
      }
    },
    titleAndBtnWrapper: {
      marginTop: "2vh",
      marginBottom: "3vh",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap"
      }
    },
    smallText: {
      fontSize: 12,
      color: "rgba(0,0,0,0.7)"
    },
    paymentQRImage: {
      width: 200,
      height: 200,

      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "auto"
      }
    }
  }));

  // let PaymentForm = () => {
  const classes = useStyles();
  const [expandedA, setExpandedA] = React.useState(false);
  const [expandedB, setExpandedB] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogEdit, setOpenDialogEdit] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  let STORE_UUID = localStorage.getItem("STORE_UUID");
  let bux;

  const [openDelete, setOpenDelete] = React.useState(false);
  let payments = useSelector(state =>
    state.webBuilder ? state.webBuilder.payments : []
  );

  function handleEdit(value) {
    setPaymentMethod(value);
    dispatch(selectPaymentMethod(value));
    // localStorage.setItem("CURRENT_METHOD", JSON.stringify(value));
    setOpenDialogEdit(true);
  }

  const [paymentMethod, setPaymentMethod] = React.useState({
    user: props.storeInfo.owner,
    store: STORE_UUID,
    payment_method: "Bank",
    qr_code_img: "",
    bank_name: "UnionBank of the Philippines",
    account_name: "",
    account_number: "",
    email: "",
    payment_link: "",
    mobile_number: ""
  });

  function handleDelete(value) {
    setOpenDelete(true);
    setPaymentMethod(value);
  }

  function proceedDelete() {
    dispatch(removePayment(paymentMethod));
    setOpenDelete(false);
    setPaymentMethod({
      user: props.storeInfo.owner,
      store: STORE_UUID,
      payment_method: "Bank",
      qr_code_img: "",
      bank_name: "UnionBank of the Philippines",
      account_name: "",
      account_number: "",
      email: "",
      payment_link: "",
      mobile_number: ""
    });
  }

  function paymentDetails(item) {
    if (item.payment_method === "Bank") {
      return item.account_number;
    } else if (item.payment_method === "PayPal") {
      return item.payment_link;
    } else {
      return item.mobile_number;
    }
  }

  function renderPaymentMethods() {
    return (
      <div>
        {payments
          ? payments.map((item, key) => {
              let bankABV = bankListing.find(
                element => element.name == item.bank_name
              );
              console.log(item.qr_code_img.split("/").pop());
              return (
                // <PaymentCard item={item} key={key} />
                <div key={key}>
                  <Paper key={key} style={{ margin: "10px 0" }}>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div style={{ padding: 10, margin: 10 }}>
                        {item.qr_code_img.split("/").pop() == "bank.png" ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: 16,
                              fontWeight: 500,
                              textAlign: "center",
                              width: 200,
                              height: 200,
                              color: "white",
                              backgroundColor: "darkgrey"
                            }}
                          >
                            {bankABV.abbv}
                          </div>
                        ) : (
                          <img
                            src={item.qr_code_img}
                            className={classes.paymentQRImage}
                          />
                        )}
                      </div>

                      <div
                        style={{
                          padding: "20px",
                          minWidth: 250,
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              marginBottom: 25
                            }}
                          >
                            {item.payment_method === "Bank"
                              ? item.bank_name
                              : item.payment_method}
                          </div>
                          <div style={{ fontSize: 16, marginBottom: 10 }}>
                            {item.payment_method === "Bank"
                              ? item.account_name
                              : ""}
                          </div>
                          <div style={{ fontSize: 16, marginBottom: 10 }}>
                            {paymentDetails(item)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",

                            marginTop: "auto"
                          }}
                        >
                          <Button
                            className={classes.btnEdit}
                            id="edit_button"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleEdit(item)}
                            size="small"
                          >
                            Edit
                          </Button>
                          <Button
                            className={classes.btnDelete}
                            id="delete_button"
                            onClick={() => handleDelete(item)}
                            color="secondary"
                            size="small"
                          >
                            Delete{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </div>
              );
            })
          : ""}
      </div>
    );
  }

  return (
    <div>
      <PaymentFormDialog
        defaultImg={defaultImg}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        store={props.storeInfo}
        openDialog={openDialog}
        setOpenDialog={value => setOpenDialog(value)}
      />
      <PaymentFormDialogEdit
        // store={props.storeInfo}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        openDialog={openDialogEdit}
        // openDialog={true}
        setOpenDialogEdit={value => setOpenDialogEdit(value)}
      />
      <Dialog color="primary" open={openDelete}>
        <div style={{ padding: 30, marginLeft: 20 }}>
          <div>
            <div style={{ marginBottom: 20, fontSize: 28 }}>
              Are you sure you want to delete this payment method?
            </div>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontSize: 16
              }}
            >
              Once deleted, you will not be able to restore this payment method.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <SentroButton
              id="cancel_button"
              color="primary"
              variant="outlined"
              label="No, Do not delete"
              onClick={() => setOpenDelete(false)}
            />
            <SentroButton
              id="confirmDelete_button"
              color="primary"
              variant="contained"
              label="Yes, delete payment"
              onClick={() => proceedDelete()}
            />
          </div>
        </div>
      </Dialog>

      <Box className={classes.titleAndBtnWrapper}>
        <Text
          textType="p2_Semibold"
          content={
            storePaymentContent[props.language ? props.language : "English"]
              .paymentHeader
          }
        />
        <SentroButton
          id="addPayment_button"
          fullWidth
          startIcon={<Add />}
          className={classes.addPaymentMethodBtn}
          style={{ display: payments.length >= 4 ? "none" : "" }}
          onClick={() => setOpenDialog(true)}
          variant="outlined"
          label="Add an Account"
        />
      </Box>
      <p className={classes.smallText}>
        Indicate your bank or wallet accounts where your costumers can pay
      </p>
      <p className={classes.smallText}>
        You can add details of your other bank, GCash, PayMaya or PayPal
        accounts so you can accommodate more customers. Don’t have an account?
        Use our available online payment options - automatically available upon
        publishing your shop
      </p>
      <div>{renderPaymentMethods()}</div>
      <div style={{ borderTop: "2px solid #909196", marginTop: 25 }}>
        <p style={{ paddingTop: 15 }} className={classes.smallText}>
          Don't have an account? Get payments from your clients via our online
          payment options below - automatically available upon publishing your
          shop.
        </p>
      </div>
      <Card
        className={classes.root}
        style={{
          backgroundColor: "#FAFAFA",
          marginBottom: 3
        }}
      >
        <Card
          style={{
            backgroundColor: "#FAFAFA",
            marginBottom: 1
          }}
        >
          <CardActions
            onClick={() => setExpandedA(!expandedA)}
            style={{ cursor: "pointer" }}
            disableSpacing
          >
            <div
              style={{
                fontSize: 19,
                color: "#002C42",
                backgroundColor: "#FAFAFA",
                paddingLeft: 10,
                fontWeight: 600
              }}
            >
              Online Payment Options
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedA
              })}
              onClick={() => setExpandedA(!expandedA)}
              aria-expanded={expandedA}
              aria-label="show more"
            >
              <ExpandMore />
            </IconButton>
          </CardActions>
        </Card>
        <Collapse
          in={expandedA}
          timeout="auto"
          unmountOnExit
          style={{
            padding: 23,
            backgroundColor: "#FAFAFA",
            boxShadow: `0px 4px 4px red`
          }}
        >
          <Box>
            <FormControl style={{ width: "100%" }}>
              <div style={{ display: "flex" }}>
                <Text
                  textType="p1_Semibold"
                  content={
                    storePaymentContent[
                      props.language ? props.language : "English"
                    ].buxHeader
                  }
                />
                <Box>
                  <Typography style={{ fontSize: "12px", marginLeft: "3vw" }}>
                    Powered by
                  </Typography>
                  <img
                    style={{ width: "10vw", marginTop: "-4vh" }}
                    src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/standard_logomark_wordmark_horizontal.png
"
                  />
                </Box>
              </div>

              <div>
                <div>
                  <Typography style={{ fontSize: 14 }}>
                    {
                      storePaymentContent[
                        props.language ? props.language : "English"
                      ].buxContent1
                    }
                  </Typography>
                  <p style={{ margin: "1em 1.2em 0", fontStyle: "italic" }}>
                    <span>Online Banking fee: PHP 15 per transaction</span>
                    <br />
                    <span>GCash fee: PHP 15 + 2% of transaction price</span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10
                    }}
                  >
                    <img
                      src={unionbank}
                      style={{ width: "auto", height: 32, margin: "0 5px" }}
                    />
                    <img
                      src={bpi}
                      style={{ width: "auto", height: 32, margin: "0 5px" }}
                    />
                    <img
                      src={rcbc}
                      style={{ width: "auto", height: 32, margin: "0 5px" }}
                    />
                    <img
                      src={otc15}
                      style={{ width: "auto", height: 32, margin: "0 7px" }}
                    />
                  </div>
                </div>

                <div style={{ marginTop: 25 }}>
                  <Typography style={{ fontSize: 14 }}>
                    {
                      storePaymentContent[
                        props.language ? props.language : "English"
                      ].buxContent2
                    }
                  </Typography>
                  <p style={{ margin: "1em 1.2em 0", fontStyle: "italic" }}>
                    <span>OTC fee: PHP 20.00 per transaction</span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 15
                    }}
                  >
                    <img src={otc1} className={classes.logoStyle} />
                    <img src={otc2} className={classes.logoStyle} />
                    <img src={otc3} className={classes.logoStyle} />
                    <img src={otc4} className={classes.logoStyle} />
                    <img src={otc5} className={classes.logoStyle} />
                    <img src={otc6} className={classes.logoStyle} />
                    <img src={otc7} className={classes.logoStyle} />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 15
                    }}
                  >
                    <img src={otc8} className={classes.logoStyle} />
                    <img src={otc9} className={classes.logoStyle} />
                    <img src={otc10} className={classes.logoStyle} />
                    <img src={otc11} className={classes.logoStyle} />
                    <img src={otc12} className={classes.logoStyle} />
                    <img src={otc13} className={classes.logoStyle} />
                    <img src={otc14} className={classes.logoStyle} />
                  </div>
                </div>
              </div>
              <strong>Bux Wallet</strong>
              <Typography style={{ fontSize: 14, marginTop: 20 }}>
                {
                  storePaymentContent[
                    props.language ? props.language : "English"
                  ].buxSubheader1
                }
              </Typography>

              <strong style={{ fontSize: 14, marginTop: 16 }}>
                Cash in and cash out limit
              </strong>
              <Typography style={{ fontSize: 14, marginTop: 16 }}>
                {
                  storePaymentContent[
                    props.language ? props.language : "English"
                  ].buxSubheader2
                }{" "}
                <a
                  href="https://bux.ph/faq"
                  target="_blank"
                  className={classes.link}
                >
                  click here
                </a>
                .
              </Typography>
            </FormControl>
          </Box>
        </Collapse>
      </Card>
    </div>
  );
};

export default PaymentForm;
