import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles, Container, Typography } from "@material-ui/core";

import logo from "../../../assets/images/sentro_vertical.png";
import Text from "../../../common/text";

import SentroButton from "../../common/sentroButton";
import CheckCircleIcon from "@material-ui/icons/Cancel";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SentroLoading from "../../common/loading";

import { resendCode } from "../registerActions";
const VerificationFailed = props => {
  const VerificationStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60%",
      height: "100vh",
      background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    verification: {
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    box1: {
      marginTop: "35vh",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },
    box2: {
      padding: "10vh 5vh 0 ",
      [theme.breakpoints.down("xs")]: {
        padding: "6vh 5vw"
      }
    },

    errorIcon: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        display: "flex"
      }
    },
    errorAlert: {
      backgroundColor: "#f975631a",
      color: "#ff5a43",
      padding: 5,
      border: "solid 1px #ff7b6a",
      borderRadius: 3,
      fontSize: 12,
      fontWeight: "bold",
      margin: 10,
      display: "inline"
    }
  }));

  const classes = VerificationStyles();
  const loading = useSelector(state => state.signUp.loading);
  const error = useSelector(state => state.signUp.error);
  const dispatch = useDispatch();
  return (
    <div className={classes.root}>
      <Box className={classes.banner}>
        <Container>
          <Box className={classes.box1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={logo} style={{ width: 150 }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "2vh" }}
                textType="h2"
                content="Create your online shop here."
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#fff",
                  position: "absolute",
                  bottom: 25
                }}
              >
                This works best in the latest Google desktop browser.
              </Typography>
            </div>
          </Box>
        </Container>
      </Box>
      <Box className={classes.verification}>
        <Container>
          <Box className={classes.box2}>
            <Text textType="h3" content="Account Verification" />
            <Box>
              <Box style={{ marginTop: "4vh", display: "flex" }}>
                <div className={classes.errorIcon}>
                  <CheckCircleIcon style={{ color: "#E24C4C", fontSize: 90 }} />
                </div>
                <div>
                  <Typography
                    style={{
                      fontSize: 16,
                      padding: 10,
                      alignItems: "center"
                    }}
                  >
                    The account verification link is already expired. Please
                    request a new one.
                  </Typography>
                  {error.code ? (
                    <p className={classes.errorAlert}>Code: {error.code}</p>
                  ) : (
                    ``
                  )}
                </div>
              </Box>
            </Box>
            <Box style={{ marginTop: "4vh" }}>
              <SentroButton
                id="resend_button"
                onClick={() => dispatch(resendCode())}
                variant="contained"
                label="Request for a new verification code"
                style={{ margin: 0 }}
                fullWidth
              />
            </Box>
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Typography
              style={{
                fontSize: 13,
                textAlign: "center",
                color: "#2B2D33",
                position: "absolute",
                bottom: 35
              }}
            >
              Need help?
            </Typography>

            <div style={{ position: "absolute", bottom: 5, display: "flex" }}>
              <MailOutlineIcon style={{ color: "#828894", width: 17 }} />
              <a
                href="mailto:support@sentro.ph"
                style={{
                  color: "#F97663",
                  fontSize: 13,
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: 10
                }}
              >
                support@sentro.ph
              </a>
            </div>
          </div>
        </Container>
      </Box>
    </div>
  );
};

export default VerificationFailed;
