import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import history from "../../../../store/history";
import Paths from "../../router/paths";
export default function PublicWishlistsSection() {
  const enterprise = useSelector(state => state.enterprise.main.enterprise);
  const enterpriseTheme = useSelector(state => state.enterprise.main.theme);
  const useStyles = makeStyles(theme => ({
    root: {
      textAlign: "center",
      backgroundColor: "#fff",
      padding: "6em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "2em"
      }
    },
    header: {
      color: "#3e4149",
      textAlign: "center",
      fontWeight: 600,
      fontSize: 28,
      fontFamily: enterpriseTheme.typography.fontFamily
    },
    label: {
      fontSize: 14,
      color: "#54575f",
      padding: "5vh 0"
    },
    btnRegister: {
      padding: "0 35px",
      color: "#fff",
      "&:hover": {
        color: "#fff",
        filter:
          enterpriseTheme.palette.secondary.main !== "#18B4A1"
            ? "brightness(0.8)"
            : "none",
        backgroundColor:
          enterpriseTheme.palette.secondary.main !== "#18B4A1"
            ? enterpriseTheme.palette.secondary.main
            : enterpriseTheme.palette.secondary.defaultHoverColor
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <div
          className={classes.header}
          style={{
            fontFamily: enterprise.font.family
          }}
        >
          Let's get started on your list
        </div>
        <div className={classes.label}>
          Become a member of the Themes & Motifs community and enjoy exclusive
          perks!
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className={classes.btnRegister}
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() =>
              history.push(
                `/enterprise/${enterprise.site_name}/wishlist/create`
              )
            }
          >
            Create a wishlist
          </Button>
        </div>
      </div>
    </div>
  );
}
