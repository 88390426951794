import { actionTypes } from "./actions";

const initialState = {
  loading: false,
  stores: [],
  vouchers: "",
  voucherListFilter: {
    list: [],
    length: 0,
  },
  selectedVoucher: {},
  product_categories: {
    categories: [],
    loading: false,
    error: false,
  },
  voucherFilters: {
    is_active: "all",
    voucher_type: "type",
    order: "desc",
    order_by: "voucher_code",
    search: "",
    page: 0,
    itemsPerPage: 10,
    desktop: true,
  },
  products: [],
};

export default function voucherReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING_ON:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_STORE_SUCCESS:
      return {
        ...state,
        stores: action.payload,
      };
    case actionTypes.GET_VOUCHER_LIST:
      return {
        ...state,
        vouchers: action.payload.length,
      };
    case actionTypes.GET_FILTER_VOUCHERS:
      return {
        ...state,
        voucherListFilter: action.payload,
      };
    case actionTypes.GET_PRODUCT_CATEGORIES:
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          error: false,
        },
      };
    case actionTypes.GET_PRODUCT_CATEGORIES_SUCCESS:
      let categories = action.payload.filter((val) => val !== "");
      if (action.payload.includes("")) categories.push("");
      return {
        ...state,
        product_categories: {
          ...state.product_categories.categories,
          categories,
          loading: false,
          error: false,
        },
      };
    case actionTypes.GET_PRODUCT_CATEGORIES_FAILED:
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          error: true,
          loading: false,
        },
      };
    case actionTypes.SET_VOUCHER_FILTERS:
      return {
        ...state,
        voucherFilters: {
          ...state.voucherFilters,
          ...action.payload,
        },
      };
    case actionTypes.SET_SELECTED_VOUCHER:
      return {
        ...state,
        selectedVoucher: action.payload,
      };
    case actionTypes.GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
}
