

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { useDispatch } from "react-redux";
import AddressForm from './addressForm';
import CloseIcon from "@material-ui/icons/Close";

import {
  Tabs,
  Tab,
  IconButton,
  Button
} from "@material-ui/core";
import { useAddresses } from '../../../hooks/useAddresses';
import AddressItem from './addressItem';
import Actions from '../../../redux/actions';


const useStyles = makeStyles(theme => ({
  title: {
    color: "#2B2D32"
  },
  label: {
    fontSize: 14,
    color: "rgba(43, 45, 50, 0.8)"
  },
  header: {
    position: "sticky",
    top: 0,
    width: 450,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  searchBar: {
    marginLeft: "auto",
    background: "white",
    width: "100%",
    borderRadius: "4px",
    color: "gray",
    height: 40,
    fontSize: 15,
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      marginTop: "1em",
      maxWidth: "100%",
      width: "100%"
    },
  },
  list: {
    height: "calc(100vh - 120px)",
    width: 450,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    marginBottom: 85
  },
  borderBottom: {
    borderBottom: "2px solid #EEEEEE"
  },
  borderBottomMax: {
    marginTop: -2,
    borderBottom: "2px solid #EEEEEE",
    width: "100%"
  },
  selectedTab: {
    borderBottomWidth: "4px",
    borderBottomColor: "inherit",
    zIndex: 5
  },
  submitButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0
  }
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: {
      minWidth: 100
    }
  };
}

export default function TemporaryDrawer(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const [addresses] = useAddresses();
  React.useEffect(() => {
    for (let i in addresses) {
      if (addresses[i].is_default) {
        setSelected(addresses[i])
      }
    }
  }, [addresses])

  const [selected, setSelected] = React.useState();
  const addressOnClick = (index) => {
    setSelected(index)
  }

  React.useEffect(() => {
    if (open) {
      setSelected(props.value)
    }
  }, [open])

  const onSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit(selected);
      setOpen(false);
    }
  }

  const addressFormSubmit = async (details) => {
    console.log(details);
    const response = await dispatch(Actions.saveAddress(details))
    if (response.status === 201) {
      setTab(0);
    }
  }


  return (
    <div>
      {
        props.children ? props.children(setOpen): ""
      }
      <Drawer className="address-drawer" anchor="right" open={open} onClose={() => setOpen(false)}
        classes={{
          paper: is_mobile ? "w-100": ""
        }}>
        <div className={classes.header}>
          <div className={`p-4`}>
            <div className="d-flex align-items-center mb-3">
              <h5><strong>Delivery Address</strong></h5>
              <IconButton
                className="ml-auto"
                style={{ marginRight: "-0.5em", color: "#858997" }}
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Tabs
              className={`${classes.tabs} mt-3`}
              value={tab}
              onChange={(q, val) => setTab(val)}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Select" {...a11yProps(0)} classes={{selected: classes.selectedTab}} />
              <Tab label="Add new" {...a11yProps(1)} classes={{selected: classes.selectedTab}} />
            </Tabs>
            <div className={`${classes.borderBottomMax} px-4`}/>
          </div>
        </div>
        <div id="address-list-parent" className={`${classes.list} overflow-auto px-4 ${tab === 0? "d-block": "d-none"}`}>
          {
            addresses.map((data, index) => (
              <AddressItem data={data} hideActions active={selected ? selected.id === data.id: false} onClick={() => addressOnClick(data)}/>
            ))
          }
          <div className={`p-4 ${classes.submitButton}`}>
            <Button color="primary" variant="contained" fullWidth onClick={onSubmit}
              disableElevation>
              Use address
            </Button>
          </div>
        </div>
        <div id="address-form" className={`${classes.list} overflow-auto px-4 ${tab === 1 ? "d-block": "d-none"}`}>
          <AddressForm onSubmit={addressFormSubmit}/>
        </div>
      </Drawer>
    </div>
  );
}
