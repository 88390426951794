import React from 'react';
import ImageUploading from 'react-images-uploading';
import { makeStyles, useTheme } from "@material-ui/styles";
import {ReactComponent as UploadIconSVG} from "../../assets/images/enterprise/upload_icon.svg";
import { LinearProgress } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

export default function ImageUploader(props) {
  const theme = useTheme();
  const primary_color = theme.palette.primary.main;
  const useStyles = makeStyles(theme => ({
    uploadImageWrapper: {
      border: `2px dashed ${primary_color}`,
      borderRadius: 4,
      cursor: "pointer",
      padding: 8
    },
    error: {
      fontSize: 12,
      color: "red"
    },
    label2: {
      fontSize: 14,
      fontWeight: 500,
      color: "rgba(43, 45, 50, 0.8)"
    },
    optionButtons: {
      fontWeight: "500",
      fontSize: 12,
      cursor: "pointer",
      color: primary_color
    },
    disabled: {
      color: "rgba(0,0,0,0.3) !important"
    },
    imageCard: {
      position: "relative",
      flex: "0 0 12.5%",
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15
    },
    status: {
      top: "50%",
      left: "5%",
      position: "absolute",
      zIndex: 1,
      width: "90%"
    },
    statusIcon: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      height: 25, width: 25,
      zIndex: 2
    }
  }));
  const classes = useStyles();
  const maxNumber = 100;
  const [images, setImages] = React.useState([]);


  // LOADING INITIAL STATE; IF THERES EXISTING DATA
  React.useEffect(() => {
    // converting to ImageUploading structure
    if (props.input.value.cropped_image) {
      setImages([{
        data_url: props.input.value.cropped_image
      }])
    }
  }, [props.input.value])


  // ONCHANGE
  const onChange = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    if (props.onImageSelect) {
      props.onImageSelect(addUpdateIndex[0], imageList[addUpdateIndex[0]].file);
    }
  };

 
  return (
    <div className="App">
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={['jpg', 'jpeg', 'png']}
        maxFileSize={5000000}
        multiple
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => (
          <>
            <p className={`${classes.label2} mb-2 d-block d-md-none`}>{props.label}</p>
            {/* image field */}
            <div className={`${classes.uploadImageWrapper} upload__image-wrapper large-image-field-field`}
              onClick={onImageUpload}
              {...dragProps}
              style={{
                height: props.height || 115,
              }}
            >
              {
                imageList.length ? 
                  <div className="row">
                    {
                      imageList.map((img, index) => (
                        <div className={classes.imageCard}>
                          <div className="position-relative">
                            {
                              props.statuses[index] === 100 ?
                                <CheckCircleRoundedIcon className={classes.statusIcon}  style={{
                                  color: "#18B4A1",
                                }}/>
                              : props.statuses[index] < 0 ?
                                <CancelIcon className={classes.statusIcon}  style={{
                                  color: "red",
                                }}/>
                              :
                              <LinearProgress variant="determinate" value={props.statuses[index]} 
                                className={classes.status}/>
                            }
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "100%",
                                backgroundImage: `url(${img['data_url']})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                opacity: 0.5,
                                borderRadius: 5
                            }} />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                : 
                <div className="h-100 w-100 d-flex align-items-center
                justify-content-center">
                  <div className="text-center" style={{ maxWidth: 300 }}>
                    <UploadIconSVG height="33" width="33" className="mb-3"  fill={theme.palette.primary.main}/>
                    <p className="mb-1" style={{ fontSize: 14}}>Click or drag file to this area to upload</p>
                    <p className="text-secondary mb-1" style={{ fontSize: 10}}>
                      {props.prompt || `For best results, upload pictures below 5 MB and in landscape format.`} </p>
                  </div>
                </div>
              }
            </div>
            {
              errors && <div className={`${classes.error} ml-2 mt-2`}>
                {errors.acceptType && <span>Your selected file type is not allowed</span>}
                {errors.maxFileSize && <span>Image is too big. Maximum allowed size is 5 MB.</span>}
              </div>
            }
          </>
         )}
      </ImageUploading>
    </div>
  );
}