import React from "react";
import { Typography } from "@material-ui/core";
import Path from "../../../Paths";
import history from "../../../store/history";

let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
let keyData = data ? data.email : null;

export const aboutSentroAnswer1 = (
  <div>
    <Typography style={{ fontSize: 16 }}>
      Sentro is your go-to platform that will help you go digital in starting,
      running and growing your business. We have various digital tools and
      solutions to help you manage your business. One of Sentro's main features
      is the FREE online shop builder, which can be set up in just a matter of
      minutes!
    </Typography>
    <a
      onClick={() => {
        keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      Click here to get started
    </a>
    .
  </div>
);

export const joiningSentroAnswer1 = (
  <div>
    <Typography style={{ fontSize: 16 }}>
      Right now, creation of an account in Sentro is absolutely free! Don’t
      worry, if ever that we charge any fee, you will know about it first.{" "}
      <a
        onClick={() => {
          keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
        }}
        style={{ color: "#F97663", fontWeight: 600 }}
      >
        Sign up now
      </a>{" "}
      to get started!
    </Typography>
  </div>
);

export const aboutSentroShopAnswer1 = (
  <Typography style={{ fontSize: 16 }}>
    Sentro’s Online Shop allows you to post and advertise all your products and
    store details. It has its built-in payment feature (currently through Bux, a
    sister platform of Sentro) that allows you to immediately conduct online
    transactions with your clients. We will likewise be integrating with
    logistics partners so that the delivery of your products can likewise be
    done seamlessly from your online shop. Build yours today!
  </Typography>
);
export const aboutSentroShopAnswer2 = (
  <Typography style={{ fontSize: 16 }}>
    Currently, creating and running your Sentro Online Shop is FREE! Should we
    ever decide to charge you something for it, you will definitely know about
    it first!{" "}
    <a
      onClick={() => {
        keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      Sign up now
    </a>{" "}
    and let’s get started on your very own Online Shop!
  </Typography>
);

export const aboutSentroShopAnswer3 = (
  <Typography style={{ fontSize: 16 }}>
    The prohibited products and activities in Sentro are stated in our{" "}
    <a
      onClick={() => {
        history.push(Path.TNC);
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      Terms and Conditions
    </a>{" "}
    which you can find.
  </Typography>
);

export const aboutSentroShopAnswer4 = (
  <Typography style={{ fontSize: 16 }}>
    We’re happy to tell you that you can upload as many items as you want in
    your{" "}
    <a
      onClick={() => {
        history.push(Path.DASHBOARD);
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      Sentro Online Shop
    </a>
    ! Let’s get you started then!
  </Typography>
);

export const aboutSentroShopAnswer5 = (
  <Typography style={{ fontSize: 16 }}>
    To learn how to log in to Sentro and Create your own Online Shop,{" "}
    <a
      onClick={() => {
        window.open(
          "https://www.facebook.com/SentroofAttention/videos/538353023513620/"
        );
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      click here
    </a>
  </Typography>
);

export const purchaseAnswer1 = (
  <Typography style={{ fontSize: 16 }}>
    Your Sentro Online Shop has an integrated payment feature. At present, it
    can accept cash, bank transfers, and credit card and debit card payments via
    Bux. To know more about Bux, click{" "}
    <a
      href="https://bux.ph/faq"
      target="_blank"
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      here
    </a>
    .
  </Typography>
);
// export const gettingStartedAnswer3 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Sentro works best using your laptop or desktop and using the latest
//       version of Google Chrome.
//     </Typography>
//   </div>
// );

// /// --------- ////

// export const createShopAnswer1 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       By building your online shop in Sentro, you will be able to post all your
//       products and store details online. It also has a built-in payment feature
//       so your clients can immediately buy from your site and can pay online (via
//       online banking and soon credit card/debit card) or cash (via 7-Eleven,
//       Cebuana, etc). Within Sentro, you can also request to have your sold items
//       delivered via our delivery service partners. With these in place already,
//       having an online business is easy.{" "}
//       <a
//         onClick={() => {
//           keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
//         }}
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Create your shop
//       </a>
//     </Typography>
//   </div>
// );

// export const createShopAnswer2 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Creating and running an online shop in Sentro is completely free.{" "}
//       <a
//         onClick={() => {
//           keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
//         }}
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Sign up now to get started
//       </a>
//     </Typography>
//   </div>
// );

// export const createShopAnswer3 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Anyone can build their online shop in Sentro. No coding or design
//       background is needed. No need to hire a programmer to maintain your shop
//       as well. Simply provide the details you want to be shown in your online
//       shop, publish it, and you're set.
//     </Typography>
//   </div>
// );

// export const createShopAnswer4 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       No need. When you create an online shop, it automatically comes with its
//       own web address - a subdomain (e.g. xyzshop.sentro.ph).
//     </Typography>
//   </div>
// );

// export const createShopAnswer5 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Currently, Sentro online shop - including its payment and logistics
//       functions - can provide services within the Philippines. But your website
//       can be viewed from anywhere in the world.
//     </Typography>
//   </div>
// );

// /// --------- ////

// export const sellingProductsAnswer1 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       You can sell any product as long as it does not violate our{" "}
//       <a
//         onClick={() => {
//           history.push(Path.TOU);
//         }}
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Terms and Conditions
//       </a>{" "}
//       and any applicable laws and regulations.
//     </Typography>
//   </div>
// );

// export const sellingProductsAnswer2 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       You may upload as many items as you wish in your online shop.
//     </Typography>
//   </div>
// );

// export const sellingProductsAnswer3 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Yes, you can edit your online shop anytime and as may times as you want.
//     </Typography>
//   </div>
// );

// export const sellingProductsAnswer4 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Yes, you can close or delete your online shop. Once deleted, you cannot
//       recover it anymore. If you wish to restore or have a new one, just create
//       a new online shop.
//     </Typography>
//   </div>
// );

// /// --------- ////

// export const gettingPaymentsAnswer1 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       A Sentro online shop has an integrated payment feature that can accept
//       online payments (via credit cards and debit cards), as well as cash
//       payments (via payments in 7-Eleven branches). The payment feature is
//       powered by Bux.
//     </Typography>

//     <Typography style={{ marginTop: 20 }}>
//       Once you sign up in Sentro, a Bux account is automatically created for you
//       as well.{" "}
//       <a
//         href="https://bux.ph/faq"
//         target="_blank"
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Click here to learn more about Bux
//       </a>{" "}
//     </Typography>
//   </div>
// );

// export const gettingPaymentsAnswer2 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       All payments - whether online or cash - will be credited to your Bux
//       account. You can view the balance of your account by going to the Sales
//       and Orders menu inside your Sentro account.
//     </Typography>
//   </div>
// );

// export const gettingPaymentsAnswer3 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       You can claim the money from your online shop sales by withdrawing the
//       funds from your Bux account and having it deposited to your chosen bank.
//     </Typography>
//   </div>
// );

// export const joiningSentroAnswer1 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Sentro is owned by UBX Philippines Corporation (“UBX PH”). UBX PH operates
//       several platforms aimed for use of individuals, institutions and
//       businesses. UBX PH is registered with the Securities and Exchange
//       Commission and has office address at 33/F UnionBank Plaza, Meralco Avenue
//       corner Onyx and Sapphire Streets, Ortigas Center, Pasig City 1605,
//       Philippines.
//     </Typography>
//   </div>
// );

// export const joiningSentroAnswer2 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Right now, creation of an account in Sentro is absolutely free! Don’t
//       worry, if ever that we charge any fee, you will know about it first.{" "}
//       <a
//         onClick={() => {
//           keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
//         }}
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Sign up now
//       </a>{" "}
//       to get started!
//     </Typography>
//   </div>
// );

// export const joiningSentroAnswer3 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Sentro works best with the{" "}
//       <a
//         href="https://www.google.com/chrome/"
//         target="_blank"
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         latest version of Google Chrome
//       </a>{" "}
//       with your laptop or desktop. In the future, Sentro may also be accessible
//       via your mobile phone.{" "}
//     </Typography>
//   </div>
// );

// export const joiningSentroAnswer4 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Yes, as Sentro is web-based, you need an internet connection to access it.
//     </Typography>
//   </div>
// );

// export const useSentroAnswer1 = (
//   <Typography style={{ fontSize: 16 }}>
//     Sentro is ideal for small to medium enterprises who want to either
//     jump-start, run or grow their business digitally. We made Sentro very user
//     friendly. You don’t need to be a techie or an IT person to use it.
//   </Typography>
// );

// export const insideSentroAnswer1 = (
//   <Typography style={{ fontSize: 16 }}>
//     Sentro Partners are various local or international fintechs or third party
//     service providers that can improve your business management. At present, we
//     have the Monetary Authority of Singapore (MAS) and Infocomm Media
//     Development, Microsoft through Crayon, UnionBank of the Philippines and HR
//     Avatar, to name a few Sentro Partners. Check your Sentro account regularly
//     to see new Sentro Partners as we keep adding them for you.
//   </Typography>
// );

// export const aboutSentroShopAnswer1 = (
//   <Typography style={{ fontSize: 16 }}>
//     Sentro’s Online Shop allows you to post and advertise all your products and
//     store details. It has its built-in payment feature (currently through Bux, a
//     sister platform of Sentro) that allows you to immediately conduct online
//     transactions with your clients. We will likewise be integrating with
//     logistics partners so that the delivery of your products can likewise be
//     done seamlessly from your online shop. Build yours today!
//   </Typography>
// );

// export const aboutSentroShopAnswer2 = (
//   <Typography style={{ fontSize: 16 }}>
//     Currently, creating and running your Sentro Online Shop is FREE! Should we
//     ever decide to charge you something for it, you will definitely know about
//     it first!{" "}
//     <a
//       onClick={() => {
//         keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
//       }}
//       style={{ color: "#F97663", fontWeight: 600 }}
//     >
//       sign up now
//     </a>{" "}
//     and let’s get started on your very own Online Shop!
//   </Typography>
// );

// export const aboutSentroShopAnswer3 = (
//   <Typography style={{ fontSize: 16 }}>
//     It’s okay! We know that 99% of folks do not how to code or design an online
//     page. That is why Sentro is here to help your business exist not only
//     through brick and mortar but in cyberspace as well. In Sentro’s Online Shop,
//     simply provide the details you want to be shown in your Online Shop, publish
//     it and you’re all set! It’s as easy as 1-2-3…
//   </Typography>
// );

// export const aboutSentroShopAnswer4 = (
//   <Typography style={{ fontSize: 16 }}>
//     No need. Once you have created your Online Shop in Sentro, it comes with
//     your business’ unique web address (example: xyzshop.sentro.ph).
//   </Typography>
// );

// export const aboutSentroShopAnswer5 = (
//   <Typography style={{ fontSize: 16 }}>
//     As your created Sentro Online Shop is in the web, it can be viewed and
//     accessed anywhere in the world. However, at this time, its payment and
//     future logistics functions can only provide services within the Philippines.
//   </Typography>
// );

// export const aboutSentroShopAnswer6 = (
//   <Typography style={{ fontSize: 16 }}>
//     The prohibited products and activities in Sentro are stated in our{" "}
//     <a
//       onClick={() => {
//         history.push(Path.TOU);
//       }}
//       style={{ color: "#F97663", fontWeight: 600 }}
//     >
//       Terms and Conditions
//     </a>{" "}
//     which you can find.
//   </Typography>
// );

// export const aboutSentroShopAnswer7 = (
//   <Typography style={{ fontSize: 16 }}>
//     We’re happy to tell you that you can upload as many items as you want in
//     your{" "}
//     <a
//       onClick={() => {
//         history.push(Path.DASHBOARD);
//       }}
//       style={{ color: "#F97663", fontWeight: 600 }}
//     >
//       Sentro Online Shop
//     </a>
//     ! Let’s get you started then!
//   </Typography>
// );

// export const aboutSentroShopAnswer8 = (
//   <Typography style={{ fontSize: 16 }}>
//     Oh yes! You can edit and update your Sentro Online Shop any time and as
//     often as you wish.
//   </Typography>
// );
// export const aboutSentroShopAnswer9 = (
//   <Typography style={{ fontSize: 16 }}>
//     Not yet but this is another future feature that you should watch out for in
//     Sentro!
//   </Typography>
// );
// export const aboutSentroShopAnswer10 = (
//   <Typography style={{ fontSize: 16 }}>
//     Yes, of course. The power to create carries the power to delete. But once
//     you have deleted it, you cannot recover it anymore. You have to create a new
//     one all over again.
//   </Typography>
// );

// export const purchaseAndPaymentsAnswer1 = (
//   <Typography style={{ fontSize: 16 }}>
//     Your Sentro Online Shop has an integrated payment feature. At present, it
//     can accept cash payments via Bux. But in the very near future, your Sentro
//     Online Shop can accept debit or credit card payments as well. To know more
//     about Bux, click{" "}
//     <a
//       href="https://bux.ph/faq"
//       target="_blank"
//       style={{ color: "#F97663", fontWeight: 600 }}
//     >
//       here
//     </a>
//     .
//   </Typography>
// );

// export const purchaseAndPaymentsAnswer2 = (
//   <Typography style={{ fontSize: 16 }}>
//     The Sales and Orders tab is your dashboard that provides the number of
//     orders you have and if they have been paid or not. The Sales and Orders
//     dashboard is created by and is maintained by our sister platform, Bux.
//   </Typography>
// );

// export const purchaseAndPaymentsAnswer3 = (
//   <Typography style={{ fontSize: 16 }}>
//     Unfortunately, this is not totally free. You have to pay a certain amount
//     for every transaction that you make using the payment or logistics gateway.
//     You shall see the amount prior to confirmation of the transaction.
//   </Typography>
// );

// export const purchaseAndPaymentsAnswer4 = (
//   <Typography style={{ fontSize: 16 }}>
//     All payments made in your Sentro Online Shop, whether online or cash, will
//     be credited to your Bux account. You can view the balance of your account by
//     going to the Sales and Payments menu in your Sentro account.
//   </Typography>
// );

// export const purchaseAndPaymentsAnswer5 = (
//   <Typography style={{ fontSize: 16 }}>
//     You can withdraw the funds from your Bux account and have it transferred to
//     your own bank account. Your bank account has to be with a local bank in the
//     Philippines.
//   </Typography>
// );

// export const miscellaneousAnswer1 = (
//   <Typography style={{ fontSize: 16 }}>
//     You can contact us through email at:{" "}
//     <a
//       href="mailto:support@sentro.ph"
//       style={{ color: "#F97663", fontWeight: 600 }}
//     >
//       support@sentro.ph
//     </a>
//     . We reply during Mondays to Fridays, except holidays, from 9:00am to
//     5:00pm, Philippine Standard Time. Oh yes, we’d also love to hear your
//     experience and success stories with Sentro through the same channel.
//   </Typography>
// );
export default {
  aboutSentroAnswer1,
  joiningSentroAnswer1,
  aboutSentroShopAnswer1,
  aboutSentroShopAnswer2,
  aboutSentroShopAnswer3,
  aboutSentroShopAnswer4,
  aboutSentroShopAnswer5,
  purchaseAnswer1

  // gettingStartedAnswer1,
  // gettingStartedAnswer2,
  // gettingStartedAnswer3,
  // createShopAnswer1,
  // createShopAnswer2,
  // createShopAnswer3,
  // createShopAnswer4,
  // createShopAnswer5,
  // sellingProductsAnswer1,
  // sellingProductsAnswer2,
  // sellingProductsAnswer3,
  // sellingProductsAnswer4,
  // gettingPaymentsAnswer1,
  // gettingPaymentsAnswer2,
  // gettingPaymentsAnswer3
};
