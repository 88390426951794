import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { red, indigo, grey } from "@material-ui/core/colors";
const theme = createMuiTheme({
  palette: {
    primary: {
      light: red[300],
      main: `#f97663`,
      dark: red[400],
      contrastText: red[50]
    },
    secondary: {
      light: grey[300],
      main: grey[700],
      dark: grey[100]
    }
  },
  typography: {
    button: {
      textTransform: "none",
      height: "48px",
      fontSize: 14,
      fontWeight: "bold"
    },
    fontFamily: ["Inter", "sans-serif"]
  }
});

// theme = responsiveFontSizes(theme);

export default theme;
