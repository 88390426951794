import React from "react";
import { Box, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { confirmChangePasswordAction } from "./AccountSettingsAction";
import SentroButton from "../common/sentroButton";
import Text from "../../common/text";

let InputPasswordForm = props => {
  const useStyles = makeStyles(theme => ({
    paper2: {
      padding: 26,
      width: 430,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 5,
      border: "1px solid #D1D5DD",
      backgroundColor: "#fff",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: 15
      }
    }
  }));
  const classes = useStyles();
  const [showOldPassword, setOldShowPassword] = React.useState("");
  const [showNewPassword, setNewShowPassword] = React.useState("");
  const [currentPassword, setCurrentPasword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [hasErrors, setHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const dispatch = useDispatch();

  const [fieldReset] = React.useState({
    currentPassword: "",
    newPassword: ""
  });

  const [errors, setErrors] = React.useState({
    currentPasswordError: "",
    newPasswordError: ""
  });

  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        setHasErrors(true);
        return;
      }
    }
    setHasErrors(false);
  }, [errors]);

  React.useEffect(() => {
    setIsFormEnough(
      currentPassword.length > 0 &&
        currentPassword.length > 8 &&
        newPassword.length > 0 &&
        newPassword.length > 8
    );
  });
  function submitPassword() {
    let params = {
      current_password: currentPassword,
      new_password: newPassword
    };
    dispatch(confirmChangePasswordAction(params));
    setCurrentPasword(fieldReset.currentPassword);
    setNewPassword(fieldReset.newPassword);
    props.handlePassword();
  }

  return (
    <Box>
      <div className={classes.paper2}>
        <div style={{ fontSize: 20, fontWeight: 600, color: "#002C42" }}>
          Password
        </div>

        <div style={{ display: "block", marginTop: 20 }}>
          <div style={{ width: "100%" }}>
            <Text content="Current Password" />
            <TextField
              id="old_password"
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SentroButton
                      style={{ color: "#2B2D33", fontSize: 10 }}
                      label={showOldPassword ? "HIDE" : "SHOW"}
                      onClick={e => setOldShowPassword(!showOldPassword)}
                      onMouseDown={e => setOldShowPassword(showOldPassword)}
                    />
                  </InputAdornment>
                )
              }}
              value={currentPassword}
              onChange={value => {
                const raw_input = value.target.value;
                setCurrentPasword(raw_input);

                if (raw_input === "") {
                  setErrors({
                    ...errors,
                    currentPasswordError: "This field is required."
                  });
                } else {
                  setErrors({
                    ...errors,
                    currentPasswordError: ""
                  });
                }
              }}
              onBlur={value => {
                const raw_input = value.target.value;
                if (raw_input === "") {
                  setErrors({
                    ...errors,
                    currentPasswordError: "This field is required."
                  });
                } else if (
                  !raw_input.match(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
                  )
                ) {
                  setErrors({
                    ...errors,
                    currentPasswordError:
                      "Please have at least 8 characters with a mix of numbers, letters and symbols."
                  });
                } else {
                  setErrors({
                    ...errors,
                    currentPasswordError: ""
                  });
                }
              }}
              error={errors.currentPasswordError}
              helperText={
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <div>{errors.currentPasswordError}</div>
                </div>
              }
              type={showOldPassword ? "text" : "password"}
            />
          </div>
        </div>

        <div style={{ display: "block", marginTop: 20 }}>
          <div style={{ width: "100%" }}>
            <Text content="New Password" />
            <TextField
              id="new_password"
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SentroButton
                      style={{ color: "#2B2D33", fontSize: 10 }}
                      label={showNewPassword ? "HIDE" : "SHOW"}
                      onClick={e => setNewShowPassword(!showNewPassword)}
                      onMouseDown={e => setNewShowPassword(showNewPassword)}
                    />
                  </InputAdornment>
                )
              }}
              value={newPassword}
              onChange={value => {
                const raw_input = value.target.value;
                setNewPassword(raw_input);

                if (raw_input === "") {
                  setErrors({
                    ...errors,
                    newPasswordError: "This field is required."
                  });
                } else {
                  setErrors({
                    ...errors,
                    newPasswordError: ""
                  });
                }
              }}
              onBlur={value => {
                const raw_input = value.target.value;
                if (raw_input === "") {
                  setErrors({
                    ...errors,
                    newPasswordError: "This field is required."
                  });
                } else if (
                  !raw_input.match(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
                  )
                ) {
                  setErrors({
                    ...errors,
                    newPasswordError:
                      "Please have at least 8 characters with a mix of numbers, letters and symbols."
                  });
                } else {
                  setErrors({
                    ...errors,
                    newPasswordError: ""
                  });
                }
              }}
              error={errors.newPasswordError}
              helperText={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <div>{errors.newPasswordError}</div>
                </div>
              }
              type={showNewPassword ? "text" : "password"}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
            justifyContent: "flex-end",
            display: "flex"
          }}
        >
          <SentroButton
            id="cancel_button"
            onClick={() => props.handlePassword()}
            label="Cancel"
            style={{ width: 100, marginRight: 10 }}
          />
          <SentroButton
            id="save_button"
            disabled={hasErrors || !isFormEnough}
            onClick={() => submitPassword()}
            label="Save"
            variant="contained"
            style={{ width: 100 }}
          />
        </div>
      </div>
    </Box>
  );
};

export default InputPasswordForm;
