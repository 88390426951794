import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Radio } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  rootRadio: {
    marginTop: -9
  }
}));

const ReduxSelectField = ({
  input,
  label,
  maxLength,
  minLength,
  multiline,
  cut,
  noHelpText,
  meta: { asyncValidating, touched, error },
  ...custom
}) => {
  const classes = useStyles();
  const handleChange = (e) => {
    input.onChange(e)
  }
  return (
    <div className={custom.className}>
      {custom.choices.map((i, index) => (
        <div className="d-flex align-items-start" style={{ marginBottom: !index ? custom.marginBottom : 0}}>
          <Radio value={i.value}
            color="primary"
            checked={parseInt(input.value) === i.value}
            onChange={handleChange}
            name={input.name}
            classes={{root: classes.rootRadio}}
          />
          <p className="mb-0">{i.display}</p>
        </div>
      ))}
    </div>
  );
};

export default ReduxSelectField;
