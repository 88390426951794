import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import ACL from "../common/utilities/ACL"
import Action from "./Redux/Actions"
import AdminDashboardLayout from "./layout"
import CircularProgress from '@material-ui/core/CircularProgress';
import EnhancedTable, { stableSort, getComparator} from './components/unPaginatedTable'
import TableExport from '../common/utilities/TableExport';

const headCells = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'Channel' },
	{ id: 'count', numeric: false, disablePadding: false, label: 'Count' },
	{ id: 'countPercent', numeric: false, disablePadding: false, label: 'Count %' },
	{ id: 'volume', numeric: false, disablePadding: false, label: 'Volume' },
	{ id: 'volumePercent', numeric: false, disablePadding: false, label: 'Volume %' },
];

function TableBodyData (props) {
	console.log("databody", props.data)
	return (
		<TableBody>
			{stableSort(props.data, getComparator(props.order, props.orderBy))
			// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
			.map((row, index) => {
				const labelId = `enhanced-table-checkbox-${index}`;
				return (
					<TableRow
						key={index}
						hover
						role="checkbox"
						tabIndex={-1}
					>
						<TableCell align="left">{row.name}</TableCell>
						<TableCell align="left">{row.count}</TableCell>
						<TableCell align="left">{row.countPercent}</TableCell>
						<TableCell align="left">{row.volume}</TableCell>
						<TableCell align="left">{row.volumePercent}</TableCell>
					</TableRow>
				);
			})}
		</TableBody>
	)
}


const pageStyles = theme => ({
	paper: {
		overflow: "hidden",
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(1),
		},
	},
	card: {
		backgroundColor: "white",
		padding: 0
	},
	cardHeader: {
		fontSize: "28px",
		marginBottom: "0.5em",
		textAlign: "left",
		color: "#F97663",
		fontWeight: 600
	},
	textDivider: {
		fontSize: "16px",
		padding: "1.5em 0",
		marginBottom: 0
	},
	miniCardHeader: {
		fontSize: "16px",
		textAlign: 'left',
		color: "#F97663",
		paddingBottom: "0.5em",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
		},
	},
	miniCardBody: {
		fontSize: "28px",
		weight: "bold",
		textAlign: 'left',
		color: theme.palette.text.primary,
	},
	lineChart: {
		paddingLeft: "auto",
		paddingRight: "auto",
		paddingTop: "2em",
		position: "relative",
		height: "400px",
		width: "100%",
		display: "block"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	pageHeaderButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		marginRight: 20
	},
	searchBar: {
		background: "white",
		maxWidth: "240px",
		borderRadius: "4px",
		color: "gray",
		fontSize: 12,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "100%",
			width: "100%"
		},
	},
	excelExportButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		border: "solid 1px #F97663",
		background: " #F97663",
		borderRadius: 4,
		marginRight: 10,
		color: "white",
		transition: "all 0.3s linear",
		"&:hover": {
			background: "#f96a63"
		},
		"&:focus": {
			outline: 0
		},
		boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
	},
	headerActionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		},
	},
	searchInputWrapper: {
		display: "flex"
	},
	searchInput: {
		flexGrow: 1
	},
	tableWrapper: {
		overflowX: "auto"
	},
	primaryColor: {
		color: theme.palette.text.primary
	},
	dFlex: {
		display: "flex"
	}
});

class BuxPayments extends React.Component {
	constructor(props) {
		super(props);
		this.searchTimeout = 0; // trigger fetch after a typing inactivity
		this.state = {
			data: {
				channels: {}
			},
			filteredData: {
				channels: {}
			},
			order: "desc",
			orderBy: "volume",
		};
		this.onSearch = this.onSearch.bind(this)
	}

	onSearch(event) {
		const val = event.target.value
		if(this.searchTimeout) clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			const filtered_channel_name = Object.keys(this.state.data.channels).filter(channel_name => {
				const lowerCaseQueryStr = val.toLowerCase()
				return (
						channel_name.toLowerCase().indexOf(lowerCaseQueryStr) != -1
					)
				});
			let filtered_channel_data = {}
			for(let i in filtered_channel_name){
				filtered_channel_data[filtered_channel_name[i]] = this.state.data.channels[filtered_channel_name[i]]
			}
			console.log(filtered_channel_name, filtered_channel_data)
			this.setState({
				...this.state,
				filteredData: {
					...this.state.filteredData,
					channels: {
						...filtered_channel_data
					}
				}
			})
		}, 300);
	}

	componentDidMount = async () => {
		const { adminDashboard, dispatch } = this.props
		const data = await dispatch(Action.fetchBuxPaymentsList())
		console.log("response", data)
		this.setState({
			data,
			filteredData: data
		})
	}
	
	render (){
		const { classes, adminDashboard, history } = this.props;
		const channels_data = this.state.filteredData.channels;
		const bux_total_count = this.state.filteredData.total_count;
		const bux_total_amount = this.state.filteredData.total_amount;
		const data = Object.keys(channels_data).map(channel_name => ({
			name: channel_name,
			count: channels_data[channel_name].count,
			countPercent: parseFloat((channels_data[channel_name].count/bux_total_count)*100).toFixed(2),
			volume: channels_data[channel_name].amount,
			volumePercent: parseFloat((channels_data[channel_name].amount/bux_total_amount)*100).toFixed(2)
		}))
		const table = (
			<EnhancedTable 
				headCells={headCells}
				tableBody={
					<TableBodyData 
						order={this.state.order} 
						orderBy={this.state.orderBy} 
						data={data}
						classes={classes}
						history={history}
					/>
				}
				length={data.length}
				defaultOrder="desc"
				defaultOrderBy="volume"
				setOrderAndOrderBy={(order, orderBy) => this.setState({...this.state, order, orderBy}, () => console.log(this.state))}
				loading={false}
			/>
		)
		return (
			<AdminDashboardLayout filterHeaderHidden={true}>
				<ACL roles={["admin", "superuser"]} redirect_to="/login">
					<div style={{
						display: "flex", 
						flexWrap: "wrap",
						alignItems: "center",
						marginBottom: "1.5em",
						padding: "0 1.5em"
					}}>
						<div className={classes.pageTitle}>
							Bux Payments
						</div>
						<div className={classes.headerActionsWrapper}>
							{
								data ? 
								<TableExport
									class={classes.excelExportButton}
									filename="Sentro - Bux Payments"
									sheet="Bux Payments"
									table={table}
								/>
								:
								null
							}
							{
								adminDashboard.loading && 
								<CircularProgress color="secondary" style={{height: 25, width: 25, color: "#f97663", marginRight: 20}}/>
							}
							<OutlinedInput
								className={classes.searchBar}
								id="outlined-adornment-weight"
								onChange={(event) => this.onSearch(event)}
								startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
								'aria-label': 'weight',
								}}
								labelWidth={0}
								margin="dense"
								placeholder="Type here to search"
							/>
						</div>
					</div>
					<div style={{ padding: "0 1.5em 1em"}}>
						<Paper className={classes.card}>
							{table}
						</Paper>
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(pageStyles)(BuxPayments))
