import React from "react";
import {
  Paper,
  Chip,
  Button,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import sampleImg from "../../../assets/images/imgPlaceholder2.svg";
import tags from "../../../assets/images/tags.svg";
import history from "../../../store/history";
import Path from "../../../Paths";
import apiConfig from "../../../common/apiConfig";
import BuyerActions from "./redux/actions";
import { useSelector, useDispatch } from "react-redux";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import StorefrontIcon from "@material-ui/icons/Storefront";
import StoreActions from "../../common/template/components/StoreActions";
const PurchasedCard = props => {
  const { item, alert, setAlert, currentTab } = props;

  const useStyles = makeStyles(theme => ({
    cartHeaderSection: {
      padding: "18px 24px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #d1d5dd",
      [theme.breakpoints.down("sm")]: {
        padding: 18
      }
    },
    cartHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149"
    },
    btnViewShop: {
      padding: "0 20px",
      fontWeight: 500,
      borderRadius: 20,
      height: 40
    },
    labelbold: {
      fontSize: 14,
      fontWeight: 600,
      color: "54575f"
    },
    label: {
      fontSize: 14,
      color: "#54575f"
    },
    cartTotalSection: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "3vh"
    },
    btnCart: {
      height: 40,
      fontWeight: 500,
      fontSize: 12
    },
    productImg: {
      width: 80,
      height: 80,
      marginRight: 8,
      borderRadius: 4
    },
    morePhotoSection: {
      position: "absolute",
      color: "#fff",
      zIndex: 3,
      width: "100%",
      height: "100%",
      fontSize: 12
    },
    moreOverlay: {
      borderRadius: 4,
      marginRight: 8,
      position: "absolute",
      backgroundColor: "#000",
      opacity: 0.3,
      width: "100%",
      height: "100%"
    },
    moreLabel: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
    cardContent: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const cart = item ? JSON.parse(item.cart) : "";
  const products = useSelector(state => state.buyerDashboard.cart_items?.all);
  const voucher = item ? (item.voucher ? JSON.parse(item.voucher) : "") : "";
  const all_cart_products = [];
  function productImage(item) {
    if (item.gallery) {
      if (item.gallery.length) {
        return item.gallery[0].image;
      } else {
        return sampleImg;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return sampleImg;
      }
    }
  }

  function actionButton2Label() {
    if (currentTab === "To Pay") {
      return "Pay now";
    } else if (currentTab === "To Ship") {
      return "Cancel order";
    } else if (currentTab === "To Receive") {
      return "Order Received";
    } else if (currentTab === "Completed" || currentTab === "Cancelled") {
      return "Buy again";
    }
  }

  const actionOnClick = () => {
    if (currentTab === "To Pay") {
      handleToPay();
    } else if (currentTab === "To Ship") {
      dispatch(
        BuyerActions.cancelOrder(item.id, {
          from: "list_page"
        })
      );
    } else if (currentTab === "To Receive") {
      dispatch(
        BuyerActions.markAsReceived(item.id, {
          from: "list_page"
        })
      );
      return "Order Received";
    } else if (currentTab === "Completed" || currentTab === "Cancelled") {
      handleBuyAgain();
    }
  };

  async function handleToPay() {
    if (item.payment_option === "otc" && item.channel === null) {
      window.location.href = `${apiConfig.buxURL}/checkout/${item.bux_uid}/?redirect_url=${apiConfig.localHost}my_cart/checkout?ref_id=${item.ref_id}`;
    } else {
      let res = await dispatch(BuyerActions.viewCheckoutOrder(item.id));

      try {
        if (res.status === 200) {
          history.push(`/my_cart/checkout`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  async function handleBuyAgain() {
    for (let i in cart) {
      all_cart_products.push({
        uuid: cart[i].uuid,
        quantity: cart[i].quantity,
        variant: cart[i].variants ? cart[i].variants : [],
        productSelection: cart[i].productSelection
      });
    }
    dispatch(StoreActions.patchBuyerCart(all_cart_products));
    setTimeout(async () => {
      let res = await dispatch(
        BuyerActions.getSelectedOrder(item.store.store_uuid)
      );
      try {
        if (res.status === 200) {
          history.push(Path.CART_VIEW_ORDER);
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  }
  async function handleViewOrders() {
    let response = await dispatch(BuyerActions.purchasedViewOrder(item.id));
    try {
      if (response.status === 200) {
        window.scrollTo(0, 0);
        dispatch(BuyerActions.setPurchasedTab(currentTab));
        history.push(`/purchased/view_order`);
      }
    } catch {
      setAlert({
        open: true,
        alertType: "error",
        message:
          "Oops! There was an error processing your request. Please try again."
      });
    }
  }

  // React.useEffect(() => {
  //   let product_uuids = cart.map(i => i.uuid);
  //   if (products && products.length) {
  //     let items = products.filter(i => !product_uuids.includes(i.uuid));
  //     console.log(items);
  //   }
  // }, []);

  return (
    <Paper>
      <div className={classes.cartHeaderSection}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.cartHeader}>
            {item ? item.store.name : ""}
          </div>
          {/* <Chip style={{ marginLeft: "2vw" }} label="Sports & Travel" /> */}
        </div>
        {isMobile ? (
          <IconButton
            onClick={() =>
              window.open(
                `${apiConfig.localHost}shop/${item ? item.store.site : ""}`
              )
            }
          >
            <StorefrontIcon color="primary" />
          </IconButton>
        ) : (
          <Button
            color="primary"
            className={classes.btnViewShop}
            variant="text"
            startIcon={<StorefrontIcon color="primary" />}
            onClick={() =>
              window.open(
                `${apiConfig.localHost}shop/${item ? item.store.site : ""}`
              )
            }
          >
            View shop
          </Button>
        )}
      </div>
      <div style={{ padding: 24 }}>
        <div className={classes.cardContent}>
          <div style={{ width: isMobile ? "100%" : "50%" }}>
            <div className={classes.labelbold}>Items: {cart.length}</div>
            <div style={{ marginTop: "3vh", display: "flex" }}>
              {cart.length
                ? cart.slice(0, 3).map((item, key) => {
                    return key === 2 ? (
                      <div
                        className={classes.productImg}
                        style={{
                          backgroundSize: "cover",
                          backgroundImage: `url(${cart[2].gallery[0].image})`,
                          position: "relative"
                        }}
                      >
                        <div className={classes.morePhotoSection}>
                          <div className={classes.moreLabel}>
                            <MoreHorizIcon style={{ color: "#fff" }} />
                            {cart.length - 2} more
                          </div>
                        </div>
                        <div className={classes.moreOverlay}></div>
                      </div>
                    ) : (
                      <img
                        src={productImage(item)}
                        className={classes.productImg}
                      />
                    );
                  })
                : ""}
            </div>
          </div>
          <div
            style={{
              width: isMobile ? "100%" : "50%",
              marginTop: isMobile ? "2vh" : 0
            }}
          >
            <div className={classes.cartTotalSection}>
              <div className={classes.label}>Sub-total</div>
              <div className={classes.label}>₱ {item ? item.subtotal : ""}</div>
            </div>
            {voucher ? (
              <div className={classes.cartTotalSection}>
                <div className={classes.label}>Voucher</div>
                <div className={classes.label}>
                  - ₱ {voucher.discount_value}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className={classes.cartTotalSection}>
              <div className={classes.labelbold}>Total</div>
              <div className={classes.labelbold}>
                ₱ {item ? item.amount : ""}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: isMobile ? 0 : "7vh"
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.btnCart}
            onClick={handleViewOrders}
          >
            View orders
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            className={classes.btnCart}
            style={{ marginLeft: 10 }}
            onClick={actionOnClick}
          >
            {actionButton2Label()}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default PurchasedCard;
