import img1 from "../../../../assets/images/OTC_channels/v2/7eleven.png";
import img2 from "../../../../assets/images/OTC_channels/v2/bayad.png";
import img3 from "../../../../assets/images/OTC_channels/v2/cebuana.png";
import img4 from "../../../../assets/images/OTC_channels/v2/cvm.png";
import img5 from "../../../../assets/images/OTC_channels/v2/da5.png";
import img6 from "../../../../assets/images/OTC_channels/v2/ecpay.png";
import img7 from "../../../../assets/images/OTC_channels/v2/express.png";
import img8 from "../../../../assets/images/OTC_channels/v2/lbc.png";
import img9 from "../../../../assets/images/OTC_channels/v2/ml.png";
import img10 from "../../../../assets/images/OTC_channels/v2/palawan.png";
import img11 from "../../../../assets/images/OTC_channels/v2/pay.png";
import img12 from "../../../../assets/images/OTC_channels/v2/posible.png";
import img13 from "../../../../assets/images/OTC_channels/v2/rd.png";
import img14 from "../../../../assets/images/OTC_channels/v2/robinsons.png";
import img15 from "../../../../assets/images/OTC_channels/v2/rural.png";
import img16 from "../../../../assets/images/OTC_channels/v2/rustan.png";
import img17 from "../../../../assets/images/OTC_channels/v2/shopwise.png";
import img18 from "../../../../assets/images/OTC_channels/v2/sm.png";
import img19 from "../../../../assets/images/OTC_channels/v2/tambunting.png";
import img20 from "../../../../assets/images/OTC_channels/v2/wellcome.png";
import img21 from "../../../../assets/images/OTC_channels/v2/ussc.png";

export const otc = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21
];
