import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ACL from "../common/utilities/ACL";
import Action from "./Redux/Actions";
import AdminDashboardLayout from "./layout";
import apiConfig from "../../common/apiConfig";
import { stringify_get_params } from "../common/utilities/DateFilter";
import { CircularProgress, Button } from "@material-ui/core";
import AdminTable from "./components/adminTable";
import Dialog from '@material-ui/core/Dialog';
import history from '../../store/history';
const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Shop Name" },
  { id: "site", numeric: false, disablePadding: false, label: "Link" },
  {
    id: "owner_name",
    numeric: false,
    disablePadding: false,
    label: "Shop Owner"
  },
  { id: "owner_email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "products_count",
    numeric: false,
    disablePadding: false,
    label: "Products"
  },
  {
    id: "date_published",
    numeric: false,
    disablePadding: false,
    label: "Date Published"
  },
  {
    id: "updated_on",
    numeric: false,
    disablePadding: false,
    label: "Last Edited"
  },
	{ id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];


const menu = (row, is_open, menuAnchor, closeMenu, setDialogState) => (
  <Menu
    id="simple-menu"
    anchorEl={menuAnchor}
    keepMounted
    open={is_open && menuAnchor ? true:false}
    onClose={closeMenu}
  >
    {/* <MenuItem disabled>
      View Details
    </MenuItem> */}
    <ACL roles={['superuser']} child="deletebtn">
      <MenuItem 
        onClick={() => {
          setDialogState(true, "delete")
          closeMenu()
        }}
      >
        Delete
      </MenuItem>
    </ACL>
    <MenuItem onClick={() => history.push({
        pathname: `/admin/dashboard/shop/${row.pk}/logs`,
      })}>
      View Logs
    </MenuItem>
  </Menu>
)

const pageStyles = theme => ({
  paper: {
    overflow: "hidden",
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    }
  },
  card: {
    backgroundColor: "white",
    padding: 0
  },
  cardHeader: {
    fontSize: "28px",
    marginBottom: "0.5em",
    textAlign: "left",
    color: "#F97663",
    fontWeight: 600
  },
  textDivider: {
    fontSize: "16px",
    padding: "1.5em 0",
    marginBottom: 0
  },
  miniCardHeader: {
    fontSize: "16px",
    textAlign: "left",
    color: "#F97663",
    paddingBottom: "0.5em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  miniCardBody: {
    fontSize: "28px",
    weight: "bold",
    textAlign: "left",
    color: theme.palette.text.primary
  },
  lineChart: {
    paddingLeft: "auto",
    paddingRight: "auto",
    paddingTop: "2em",
    position: "relative",
    height: "400px",
    width: "100%",
    display: "block"
  },
  headerButtons: {
    height: "35px"
  },
  pageTitle: {
    fontSize: 26,
    fontWeight: 600,
    marginBottom: 0,
    color: "#002C42",
    flexGrow: 1,
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  pageHeaderButton: {
    height: 35,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginTop: "1em",
      flex: 1
    },
    marginRight: 20
  },
  searchBar: {
    background: "white",
    maxWidth: "240px",
    borderRadius: "4px",
    color: "gray",
    fontSize: 12,
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      marginTop: "1em",
      maxWidth: "100%",
      width: "100%"
    }
  },
  excelExportButton: {
    height: 35,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginTop: "1em",
      flex: 1
    },
    border: "solid 1px #F97663",
    background: " #F97663",
    borderRadius: 4,
    marginRight: 10,
    color: "white",
    transition: "all 0.3s linear",
    "&:hover": {
      background: "#f96a63"
    },
    "&:focus": {
      outline: 0
    },
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
  },
  headerActionsWrapper: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  searchInputWrapper: {
    display: "flex"
  },
  searchInput: {
    flexGrow: 1
  },
  tableWrapper: {
    overflowX: "auto"
  },
  primaryColor: {
    color: theme.palette.text.primary
  },
  dFlex: {
    display: "flex"
  },
	moreHorizIcon: {
		color: "gray",
		cursor: "pointer"
	},
	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
	alertSuccess: {
		padding: "0.75em 1em",
		border: "solid 1px #1DD28B",
		borderLeft: "solid 5px #1DD28B",
		borderRadius: 4,
		background: "#1dd28b14"
	},
});

class Shops extends React.Component {
  constructor(props) {
    super(props);
    this.searchTimeout = 0;
    this.state = {
      // menu
      targetRow: null,
      menuAnchor: null,
      // dialog
      dialogTitleText: '',
      dialogStat: false,
      dialogType: "delete",
      dialogBody: '',

      alert: "",

      shopStatus: "",
      shopsList: [],
      filteredList: [],
      date_filter_query: "",
      order: "",
      orderBy: "",
      search_query: "",
      page: 0,
      rowsPerPage: 5
    };
    this.onSearch = this.onSearch.bind(this);
    this.onDateFilterCallback = this.onDateFilterCallback.bind(this);
    this.fetch = this.fetch.bind(this);
    this.onSortRequest = this.onSortRequest.bind(this);
    this.constructFilterQuery = this.constructFilterQuery.bind(this);
  }

  fetch = (query_filters, is_csv = false, filename = "") => {
    const { dispatch } = this.props;
    dispatch(Action.fetchShops(query_filters, is_csv, filename));
  };

  downloadCSV() {
    this.fetch(
      this.constructFilterQuery() + "&csv_download=True",
      true,
      "Sentro - Shops.csv"
    );
  }

  constructFilterQuery() {
    return (
      this.state.date_filter_query +
      "&order=" +
      this.state.order +
      "&orderBy=" +
      this.state.orderBy +
      "&page=" +
      this.state.page +
      "&rowsPerPage=" +
      this.state.rowsPerPage +
      this.state.search_query +
      this.state.shopStatus
    );
  }

  onSearch(event) {
    const search_query = event.target.value;

    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState(
        {
          search_query: `&q=${search_query.toLowerCase()}`,
          page: 0
        },
        () => {
          console.log(this.constructFilterQuery());
          this.fetch(this.constructFilterQuery());
        }
      );
    }, 500);
  }

  componentDidMount = async () => {
    const { location, adminDashboard } = this.props;

    const date_filter_query = stringify_get_params(adminDashboard.dateFilter);
    this.setState(
      {
        date_filter_query
      },
      () => {
        console.log(this.constructFilterQuery());
        this.fetch(this.constructFilterQuery());
      }
    );

    if (location.search === "?is_published=True") {
      this.setState(
        {
          shopStatus: `&is_published=True`
        },
        () => {
          console.log(this.constructFilterQuery());
          this.fetch(this.constructFilterQuery());
        }
      );
    } else if (location.search === "?is_deleted=True") {
      this.setState(
        {
          shopStatus: `&is_deleted=True`
        },
        () => {
          console.log(this.constructFilterQuery());
          this.fetch(this.constructFilterQuery());
        }
      );
    }
  };

  onDateFilterCallback = async filter => {
    const date_filter_query = stringify_get_params(filter);
    this.setState(
      {
        date_filter_query,
        page: 0
      },
      () => {
        console.log(this.constructFilterQuery());
        this.fetch(this.constructFilterQuery());
      }
    );
  };

  onSortRequest(order, orderBy, page, rowsPerPage) {
    this.setState(
      {
        order,
        orderBy,
        page,
        rowsPerPage
      },
      () => {
        console.log(this.constructFilterQuery());
        this.fetch(this.constructFilterQuery());
      }
    );
  }

  setMenu = (event, targetRow) => {
    this.setState({
      menuAnchor: event.target,
      targetRow
    })
  }

  closeMenu = () => {
    this.setState({
      menuAnchor: null
    })
  }

  setDialogState = (state, dialogType = "edit") => {
    this.setState({
      dialogStat : state,
      dialogType: state ? dialogType: this.state.dialogType
    })
  }
  
  handleDelete = async () => {
    const { dispatch } = this.props;
    const response = await dispatch(Action.deleteShop(this.state.targetRow.pk));
    if (response) {
      this.setDialogState(false)
      this.setState({
        ...this.state,
        alert: "Shop deleted successfully"
      })
    } else {
      this.setState({
        dialogType: "info",
        dialogTitleText: "Error",
        dialogBody: "Something went wrong. Please try again."
      })
    }
  }

  render() {
    const { classes, adminDashboard, location } = this.props;

    const dialogTitle = () => {
      if (this.state.dialogType === "delete") {
        return "Are you sure you want to delete this record?"
      } else if (this.state.dialogType==="info") {
        return this.state.dialogTitleText
      }
    }
    const dialogContent = () => {
      if (["delete"].includes(this.state.dialogType)) {
        return "Click submit to confirm"
      } else if (this.state.dialogType==="info") {
        return this.state.dialogBody
      }
    }
    const actionButton = () => {
      if (this.state.dialogType==="delete") {
        return (
          <Button
            id="delete_button"
            onClick={(event) => {
              this.handleDelete()
            }} 
            color="primary"
            autoFocus
            disableElevation 
            className={classes.modalActions}
            variant="contained"
            style={{marginLeft: 10}}
          >
            Delete
          </Button>
        )
      }
    }
    return (
      <AdminDashboardLayout onDateFilterCallback={this.onDateFilterCallback}>
        <ACL roles={["admin", "superuser"]} redirect_to="/login">
        <Dialog
            open={this.state.dialogStat}
            onClose={(event) => this.setDialogState(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
          >	
            <div className={classes.modalDiv}>
              <div className={classes.modalTile}>
                {dialogTitle()}
              </div>
              <div className={classes.modalBody}>
                {dialogContent()}
              </div>
              <div className={classes.modalActionsWrapper}>
                <Button
                  id="action_button"
                  variant="outlined"
                  onClick={(event) => this.setDialogState(false)} 
                  color="primary"
                  className={classes.modalActions}
                >
                  {
                    this.state.dialogType == "info" ?
                    "Close"
                    :
                    "Cancel"
                  }
                </Button>
                {actionButton()}
              </div>
            </div>
          </Dialog>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginBottom: "1.5em",
              padding: "0 1.5em"
            }}
          >
            <div className={classes.pageTitle}>
              {location.search === "?is_published=True"
                ? "Published Shops"
                : location.search === "?is_deleted=True"
                ? "Deleted Shops"
                : "Created Shops"}
            </div>
            <div className={classes.headerActionsWrapper}>
              {adminDashboard.loading && (
                <CircularProgress
                  color="secondary"
                  style={{
                    height: 25,
                    width: 25,
                    color: "#f97663",
                    marginRight: 20
                  }}
                />
              )}
              <Button
                id="download_shops_button"
                variant="contained"
                color="primary"
                disableElevation
                className={classes.pageHeaderButton}
                onClick={() => this.downloadCSV()}
              >
                Download
              </Button>
              <OutlinedInput
                className={classes.searchBar}
                id="outlined-adornment-weight"
                onChange={event => this.onSearch(event)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight"
                }}
                labelWidth={0}
                margin="dense"
                placeholder="Type here to search"
              />
            </div>
          </div>
          <div style={{ padding: "0 1.5em 1em" }}>
            {
              this.state.alert ?
              <p className={classes.alertSuccess}>{this.state.alert}</p>
              :
              ""
            }
            <Paper className={classes.card}>
              <AdminTable
                loading={adminDashboard.loading}
                headCells={headCells}
                actionColRoles={['admin', 'superuser']}
                body={
                  <TableBody>
                    {adminDashboard.shopsList.map((row, index) => {
                      const date_published = row => {
                        if (row.is_published) {
                          return row.date_published
                            ? row.date_published
                            : row.created_on;
                        } else {
                          return row.date_published ? row.date_published : ``;
                        }
                      };
                      return (
                        <TableRow
                          key={row.ref_id}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ marginLeft: "1em" }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left">
                            <a
                              href={`${apiConfig.localHost}shop/${row.site}`}
                              target="_blank"
                            >
                              {`${row.site}.sentro.ph`}
                            </a>
                          </TableCell>
                          <TableCell align="left">{row.owner_name}</TableCell>
                          <TableCell align="left">{row.owner_email}</TableCell>
                          <TableCell align="left">
                            {row.products_count}
                          </TableCell>
                          <TableCell align="left">
                            {date_published(row)}
                          </TableCell>
                          <TableCell align="left">{row.updated_on}</TableCell>
                          <TableCell align="left">
                            {
                              menu(
                                row, 
                                this.state.targetRow ? row.pk === this.state.targetRow.pk:false,
                                this.state.menuAnchor, 
                                this.closeMenu,
                                this.setDialogState,
                              )
                            }
                            <MoreHorizIcon onClick={(event) => {this.setMenu(event, row)}} className={classes.moreHorizIcon}/>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                }
                length={adminDashboard.listLength}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
                onSortRequest={this.onSortRequest}
              />
            </Paper>
          </div>
        </ACL>
      </AdminDashboardLayout>
    );
  }
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(pageStyles)(Shops));
