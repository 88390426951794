import React from "react";
import { useDispatch, useSelector } from "react-redux";
import StoreActions from "./StoreActions";
import { useLocation } from "react-router-dom";
import Header from "./AppBar";
import Footer from "./Footer";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import StoreLoading from "./Loading";
import history from "../../../../store/history";
import Skeleton from "@material-ui/lab/Skeleton";
import setDefaultImage from "../../layout/mobile/webBuilder/common/setDefaultImage";
const AboutUs = props => {
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/about_us", "");
  const storeName = param.replace("/shop/", "");
  const dispatch = useDispatch();
  const storeInfo = useSelector(state => state.store.store);
  let loading = useSelector(state => state.store.loading);
  let color = storeInfo && storeInfo.color;
  let font = storeInfo && storeInfo.font;
  React.useEffect(() => {
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(storeName));
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (storeInfo.is_published == false) {
      history.push("/not-found");
    }
  });

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: 80,
      backgroundColor: "#FAFAFA",
      width: "100%"
    },
    header: {
      fontSize: 36,
      textAlign: "center",
      color: "#54575f",
      fontFamily: font && font.family
    },
    about: {
      wordWrap: "break-word",
      whiteSpace: "pre-line",
      width: "100%",
      fontSize: 16,
      color: "#54575f"
    },
    aboutContainer: {
      padding: "40px 12vw",
      [theme.breakpoints.down("sm")]: {
        padding: "40px 15vw"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "40px 10vw"
      }
    },
    imageContainer: {
      padding: "2vh 15vw",
      [theme.breakpoints.up("xl")]: {
        padding: "2vh 25vw"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "2vh 0"
      }
    },
    image: {
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: "100%",
      height: 450,
      [theme.breakpoints.up("xl")]: {
        height: 490
      },
      [theme.breakpoints.down("sm")]: {
        height: "37vh"
      },
      [theme.breakpoints.down("xs")]: {
        height: "30vh"
      }
    }
  }));

  const classes = useStyles();
  const store_theme = useSelector(state => state.store.store.color);
  function imgPlaceholder() {
    if (storeInfo && storeInfo.aboutus_image) {
      return storeInfo.aboutus_image;
    } else {
      return setDefaultImage(storeInfo && storeInfo.template, "aboutus_image");
    }
  }

  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold"
      },
      fontFamily: ["Inter", "sans-serif"]
    }
  });
  return (
    <div>
      <StoreLoading open={loading} />
      <div>
        {storeInfo && storeInfo.font.css_url ? (
          <link href={storeInfo.font.css_url} rel="stylesheet"></link>
        ) : (
          ""
        )}
        <ThemeProvider theme={store_generated_theme}>
          <Header
            isPreview={false}
            storeInfo={storeInfo}
            color={true}
            logo={true}
            shadow={true}
          />
          <div className={classes.root}>
            <div className={classes.header}>ABOUT US</div>
            <div className={classes.imageContainer}>
              {loading ? (
                <Skeleton variant="rect" className={classes.image} />
              ) : (
                <div
                  className={classes.image}
                  style={{
                    backgroundImage: `url(${imgPlaceholder()})`
                  }}
                />
              )}

              <div className={classes.aboutContainer}>
                <div className={classes.about}>
                  {loading ? (
                    <Skeleton variant="text" height={50} />
                  ) : storeInfo ? (
                    storeInfo.description
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <Footer store={storeInfo} />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default AboutUs;
