import React from "react";
import { Link, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
export default function HeaderFilter(props) {
  const { storeInfo } = props;
  let color = props.storeInfo && props.storeInfo.color;
  const useStyles = makeStyles(theme => ({
    label: {
      color: color.primary.contrastText,
      fontSize: 14,
      margin: 10
    }
  }));
  const history = useHistory();
  const classes = useStyles();
  const product_categories = useSelector(
    state => state.store.product_categories?.categories
  );
  return (
    <Container>
      <div className="py-3 d-flex align-items-center justify-content-center">
        {product_categories.map((item, index) => (
          <Link
            style={{ textTransform: "capitalize" }}
            className={classes.label}
            onClick={() =>
              props.isPreview
                ? null
                : history.push(
                    `/shop/${
                      storeInfo.site
                    }/products/?category=${encodeURIComponent(
                      item
                    )}&sortBy=-created_on`
                  )
            }
          >
            {item}
          </Link>
        ))}
      </div>
    </Container>
  );
}
