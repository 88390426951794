import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router";
import { animateScroll as scroll } from "react-scroll";

import { useParams } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Header from "./header";
import Footer from "./footer";
import { useSelector, useDispatch } from "react-redux";
import CategoriesHeader from "../categories/categoryHeaders";
import Loading from "../../../common/loading";
import DashboardSideBar from "./dashboard/sideBar";
import DashboardAppBar from "./dashboard/appbar";
import Section from "../home/section";
import MerchantSection from "../merchant/merchant_section";
import BuyerAppbar from "../layout/buyer_dashboard/appbar";
import BuyerSidebar from "../layout/buyer_dashboard/sideBar";
import { grey } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import MuiAlert from "@material-ui/lab/Alert";

import history from "../../../../store/history";
import MarketplaceActions from "../../redux/actions/marketplaceActions";
import ProductActions from "../../redux/actions/productActions";
import MetaTags from "react-meta-tags";
import { useMediaQuery } from "@material-ui/core";
import BottomNavigation from "./buyer_dashboard/bottomNavigation";
import PopupAlert from "../../../common/popupAlert";
import BuyerDashboardActions from "../../redux/actions/buyerDashboardActions";
import PublicWishlistsSection from "../public_wishlists/public_wishlists_section";
const useStyles = makeStyles(theme => ({
  root: {
    background: "#fafafa"
  },
  sideBar: {
    display: "flex",
    position: "fixed",
    height: "100%",
    zIndex: 1300,
    top: 0,
    left: 0
  },
  snackBarRoot: {
    top: 80,
    left: "calc(50% + 150px)"
  },
  mobileContent: {
    [theme.breakpoints.down("sm")]: {
      height: "87vh",
      overflowY: "auto"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px 16px",
      height: "80vh",
      overflowY: "auto"
    }
  }
}));

const dashboard_theme = {
  palette: {
    primary: {
      light: "#fff",
      main: `#18B4A1`,
      dark: "#1D9485",
      contrastText: "#fff"
    },
    secondary: {
      light: grey[300],
      main: grey[700],
      dark: grey[100],
      secondaryColor: "#18b4a1"
    },
    imgColorPlaceholder: "#e4f7ec"
  },
  typography: {
    button: {
      textTransform: "none",
      height: "48px",
      fontSize: 14,
      fontWeight: "bold"
    },
    fontFamily: ["Inter", "sans-serif"]
  }
};

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function Layout(props) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let loading = useSelector(state => state.enterprise.main.loading);
  let enterprise = useSelector(state => state.enterprise.main.enterprise);
  let buyerPopup = useSelector(
    state => state.enterprise.main.buyer_dashboard.showPopup
  );
  let cart = useSelector(state => state.enterprise.main.cart.items);
  let buyer_cart = useSelector(state => state.enterprise.main.cart.cart_items);
  let selected_view_order = useSelector(
    state => state.enterprise.main.cart.selectedViewOrder.items
  );
  const dashboard_generated_theme = createMuiTheme(dashboard_theme);
  const token = sessionStorage.getItem("TOKEN");
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const plain = children => children;
  const is_condensed_header_footer =
    location.pathname.includes("/merchant/") ||
    location.pathname.includes("/product/") ||
    location.pathname.includes("/cart") ||
    location.pathname.includes("/wishlist");
  const is_in_merchant_page = location.pathname.includes("/merchant/");
  const hide_section = location.pathname.includes("/wishlist");
  const is_in_public_wishlists_page = location.pathname.includes(
    "/public_wishlists"
  );
  const is_page_no_container =
    location.pathname.includes("/wishlist") &&
    !location.pathname.includes("/wishlist/create") &&
    !location.pathname.includes("/wishlist/public_wishlists");

  const hide_footer =
    isMobile &&
    (location.pathname.includes("/details") ||
      location.pathname.includes("/delivery") ||
      location.pathname.includes("/payment"));
  const marketplace = children => {
    let fav = document.getElementById("favicon");
    fav.href = enterprise.favicon;
    fav = document.getElementById("favicon-apple");
    fav.href = enterprise.favicon;
    return (
      <div>
        <Snackbar
          autoHideDuration={2000}
          open={error.open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          onClose={() => setError(val => ({ ...val, open: false }))}
          TransitionComponent={TransitionDown}
        >
          <MuiAlert severity={error.alertType}>{error.message}</MuiAlert>
        </Snackbar>
        <MetaTags>
          <title>{enterprise.name}</title>
        </MetaTags>
        {enterprise.font.css_url ? (
          <link href={enterprise.font.css_url} rel="stylesheet"></link>
        ) : (
            ""
          )}
        <Header condensed={is_condensed_header_footer} />
        <Container
          disableGutters={is_page_no_container ? true : false}
          maxWidth={is_page_no_container ? false : "lg"}
          className={is_page_no_container ? "py-0" : "py-3"}
          style={{ minHeight: "calc(100vh - 64px)" }}
        >
          <div
            className={`d-none d-md-block ${
              is_page_no_container ? "mb-0" : "mb-3"
              }`}
          >
            {/* <CategoriesHeader /> */}
          </div>
          <div>{children}</div>
        </Container>

        {enterprise.show_section && !hide_section ? <Section /> : ""}
        {is_in_merchant_page ? <MerchantSection /> : ""}
        {is_in_public_wishlists_page ? <PublicWishlistsSection /> : ""}
        <Footer condensed={is_condensed_header_footer} />
      </div>
    );
  };

  const [error, setError] = React.useState({
    open: false,
    alertType: "error",
    message: ""
  });
  const errorPopup = useSelector(
    state => state.enterprise.main.dashboard.errorPopup
  );

  function closePopup() {
    dispatch(
      BuyerDashboardActions.popupAlert({
        open: false,
        alertType: "",
        message: ""
      })
    );
  }
  React.useEffect(() => {
    if (errorPopup.open) {
      setError(errorPopup);
    }
  }, [errorPopup]);
  const dashboard = children => {
    return (
      <div style={{ minHeight: "calc(100vh)" }}>
        <Snackbar
          autoHideDuration={2000}
          classes={{ root: classes.snackBarRoot }}
          open={error.open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          onClose={() => setError(val => ({ ...val, open: false }))}
          TransitionComponent={TransitionDown}
        >
          <MuiAlert severity={error.alertType}>{error.message}</MuiAlert>
        </Snackbar>
        <ThemeProvider theme={dashboard_generated_theme}>
          <DashboardAppBar />
          <div className={classes.sideBar}>
            <DashboardSideBar />
          </div>
          <div style={{ paddingTop: 64, marginLeft: 240 }}>
            <Container maxWidth="lg" className="px-5 py-3">
              <div>{children}</div>
            </Container>
          </div>
        </ThemeProvider>
      </div>
    );
  };

  const buyer_dashboard = children => {
    return (
      <ThemeProvider theme={dashboard_generated_theme}>
        {isMobile ? (
          <div style={{ minHeight: "calc(100vh)" }}>
            <BuyerAppbar />
            <div style={{ paddingTop: 64 }}>
              <Container
                className={classes.mobileContent}
                id="buyer_dashboard_content"
              >
                <div>{children}</div>
              </Container>
            </div>
            <div style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
              <BottomNavigation />
            </div>
          </div>
        ) : (
            <div style={{ minHeight: "calc(100vh)" }}>
              <BuyerAppbar />
              <PopupAlert
                open={buyerPopup.open}
                alertType={buyerPopup.alertType}
                message={buyerPopup.message}
                onClose={closePopup}
              />
              <div className={classes.sideBar}>
                <BuyerSidebar />
              </div>
              <div
                style={{ paddingTop: 64, paddingRight: "2vw", marginLeft: 260 }}
              >
                <Container maxWidth="lg" className="px-5 py-3">
                  <div>{children}</div>
                </Container>
              </div>
            </div>
          )}
      </ThemeProvider>
    );
  };

  React.useEffect(() => {
    scroll.scrollTo(0);
    const is_on_products_page = location.pathname.includes("/products/");
    if (!is_on_products_page) {
      dispatch(
        ProductActions.setProductFilters({
          search: ""
        })
      );
    }
  }, [location.pathname, enterprise.name]);

  const is_in_buyer_dashboard = location.pathname.includes("/buyer_dashboard");
  React.useEffect(() => {
    if (user) {
      if (enterprise.site_name) {
        if (enterprise.site_name !== user.enterprise && is_in_buyer_dashboard) {
          history.push(`/enterprise/${enterprise.site_name}/login`);
        }
        dispatch(ProductActions.getBuyerCart(enterprise.site_name));
      }
    }
  }, [enterprise.site_name, selected_view_order]);

  React.useEffect(() => {
    let params = [];
    if (user) {
      for (let i in cart) {
        params.push({
          uuid: cart[i].uuid,
          quantity: cart[i].quantity,
          variant: cart[i].variants,
          productSelection: cart[i].productSelection,
          registry: cart[i].registry
        });
      }
      dispatch(
        ProductActions.patchBuyerCart(
          enterprise.site_name,
          !cart.length ? [{}] : params
        )
      );
    }
  }, [cart]);

  const is_plain_layout =
    [
      "login",
      "signup",
      "verify",
      "apply",
      "forgot_password",
      "input_password",
      "demo",
      "sell"
    ].some(i => location.pathname.includes(i)) ||
    ["/enterprise/", "/enterprise"].includes(location.pathname);

  const is_on_dashboard = location.pathname.includes("/dashboard");
  const is_on_buyer_dashboard = location.pathname.includes("/buyer_dashboard");

  return (
    <div className={classes.root}>
      {/* <Loading open={loading} /> */}
      {is_plain_layout
        ? plain(props.children)
        : is_on_dashboard
          ? dashboard(props.children)
          : is_on_buyer_dashboard
            ? buyer_dashboard(props.children)
            : marketplace(props.children)}
    </div>
  );
}
