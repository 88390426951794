import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import history from "../../store/history";
import LanguageIcon from '@material-ui/icons/Language';
import Path from "../../Paths";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from '@material-ui/icons/Error';
import SentroLoading from "../common/loading";
import DashboardActions from "./dashboardActions";
import { animateScroll as scroll } from "react-scroll";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import validations from '../common/validations'
import CopyToClipboard from "react-copy-to-clipboard";
import SentroLogo from '../../assets/images/sentro_logomark1.png'
import MarketplaceActions from '../marketplace/Redux/Actions'
import apiConfig from '../../common/apiConfig';
import axios from 'axios';
import {
  OutlinedInput
} from "@material-ui/core";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";

import CircularProgress from '@material-ui/core/CircularProgress';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AlertDialogBox = props => {
    const { state, setState } = props;
    return (
      <Snackbar
        open={state.alertStat}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setState(false)}
      >
        <Alert
          onClose={() => setState(false)}
          severity={state.alertType}
        >
          {state.alertMessage}
        </Alert>
      </Snackbar>
    );
  };

const useStyles = theme => ({
  root: {
    padding: "2em",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fbMessengerIcon: {
    color: "#0D3D76",
    fontSize: 100
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  content: {
    padding: "0 1.5em",
    "& > strong": {
      color: "#002C42"
    },
    "& > p": {
      marginTop: 10
    }
  }
});

class Card extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <LanguageIcon style={{ width: 60, height: "auto" }} />
        <div className={classes.content}>
          <strong>Want your own domain?</strong>
          <p>
            You can use your own domain (www.urshop.com) as your Sentro shop’s website address. 
          </p>
        </div>
        <Button
          className={classes.button}
          id="view"
          color="primary"
          disableElevation
          variant="outlined"
          onClick={() => {
            // const link =  data.variants ? `/marketplace/${data.id}/packages` : `marketplace/${data.id}/checkout`
            history.push(Path.CUSTOM_DOMAIN_SETUP);
          }}
        >
          Learn More
        </Button>
      </div>
    );
  }
}
const CustomDomainCard = withStyles(useStyles)(Card);
export { CustomDomainCard };

const useStylesSetup = theme => ({
  header: {
    padding: "1.5em 3em",
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "white",
      padding: 20,
      margin: '68px 20px 0',
      borderRadius: 8
    },
  },
  backDivWrapper: {
    display: "flex",
    "& > span": {
      marginLeft: "1em",
      fontWeight: 600
    },
    color: "#F97663 !important",
    alignItems: "center",
    cursor: "pointer",
    width: 100
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
    maxWidth: "80em",
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      alignContent: 'center'
    },
  },
  Icon: {
    color: "#0D3D76",
    margin: "10px 50px",
    fontSize: 85,
    [theme.breakpoints.down('sm')]: {
      margin: "auto"
    },
  },
  headerDetails: {
    paddingRight: 100,
    "& > strong": {
      fontSize: 30,
      color: "#2B2D33",
      [theme.breakpoints.down('sm')]: {
        margin: "auto",
        fontSize: 16,
      },
    },
    "& > p": {
      marginTop: 10,
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        margin: "auto",
        fontSize: 12,
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  mainDiv: {
    backgroundColor: "white",
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "#E5E5E5",
      padding: "20px 0 70px"
    },

  },
  root: {
    margin: "auto",
    maxWidth: "66em",
    padding: "0 2em 3em",
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "white",
      borderRadius: 8,
      margin: 20,
      padding: 20,
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
    },
  },
  inlineFormCard: {
    background: "#F1F1F1",
    padding: "2em 2em",
    display: "block",
    "& .root": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .button": {
      marginLeft: "1.5em !important",
      marginTop: 0,
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        // marginLeft: 0,
        marginTop: "1em"
      },
    },
    "& .ml-sm-auto": {
      marginLeft: "1.5em",
      [theme.breakpoints.down('xs')]: {
        marginLeft: "auto !important",
      },
    },
    "& .inputField": {
      flexGrow: 1,
      height: 40,
      width: "auto",
      backgroundColor: "white",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        maxWidth: "100%",
      },
    },
    "& .domainVerifiedContent": {
      maxWidth: 378,
      width: "auto",
      padding: "0 1.5em",
      [theme.breakpoints.down('sm')]: {
        padding: "1em 0 0",
        width: "100%",
        maxWidth: "100%",
      },
    },
  },
  d_flex: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginBottom: "0 !important"
    }
  },
  inlineTextButtonWrapper: {
    padding: "4px 15px",
    borderRadius: 5,
    display: "flex",
    backgroundColor: "#D1D5DD",
    alignItems: "center",
    flexWrap: "wrap",
    "& > p": {
      fontWeight: "bold",
      color: "#54575F",
      marginBottom: "0 !important",
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
        textAlign: "center"
      },
    },
    "& > button": {
      margin: "auto",
      height: 35,
      background: "white",
      borderColor: "#F97663",
      color: "#F97663",
      marginLeft: "2em"
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      "& > button": {
        margin: "auto !important"
      },
    },
  },
  checkIcon: {
    color: "#1DD28B",
    fontSize: 55
  },
  successAlert: {
    background: "#1dd28b7d",
    borderRadius: 4,
    borderLeft: "solid 5px #1DD28B",
    padding: "10px 25px"
  },
  checkDnsLoading: {
    height: "18px !important",
    width: "18px !important"
  }
});

class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      custom_domain: "",
      error: false,
      copied: [],
      checked: false,
      verified: false,

      unique_domain_checking: false,

      alertStat: false,
      alertType: "success",
      alertMessage: ""
    };
    this.inputChangeTimeout = 0;
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    let stores = await dispatch(DashboardActions.getStores());
    if (stores.length) {
        let response = await dispatch(
          DashboardActions.getCustomDomain({
            store_uuid: stores[0].uuid
          })
        );
        if (response){
          this.setState({
            ...this.state,
            custom_domain: response.data.name,
            verified: response.data.status === "Verified"
          })
          this.verifyDomain()
        }
    }
    scroll.scrollTo(0);
  };

  onSubmit = async () => {
    const { dispatch, stores } = this.props;
    const response = await dispatch(
      DashboardActions.saveCustomDomain(stores[0].uuid, this.state.custom_domain)
    );
    if (response) {
      this.setState({
        ...this.state,
        checked: true,
      })
    }
    scroll.scrollTo(500);
  };

  setUniqueDomainLoading = (stat) => {
    this.setState({
      ...this.state,
      unique_domain_checking: stat
    })
  }

  checkUniqueDomain = (value) => {
    // fetch only 500ms after typing
    clearTimeout(this.inputChangeTimeout);
    this.inputChangeTimeout = setTimeout(async () => {
      try {
        const res = await axios.get(`${apiConfig.apiURL}/api/aws/unique-domain/`, {
          params: { name: value }
        })
        if (res.data.toLowerCase() === value.toLowerCase()) {
          this.setState({
            ...this.state,
            error: "This is already taken, please try again."
          })
        }
      } catch {}
    }, 500)
  }

  verifyDomain = async () => {
    const { dispatch, stores } = this.props;
    const response = await dispatch(
      DashboardActions.checkDns(stores[0].uuid)
    );
    if (response) {
      this.setState({
        ...this.state,
        checked: true,
        verified: response.Verified 
      })
    } else {
      this.setState({
        ...this.state,
        checked: true,
        alertStat: true,
        alertType: "warning",
        alertMessage: "Oops. something went wrong. Please try again."
      })
    }
  }

  setAlert = (stat) => {
    this.setState({
      ...this.state,
      alertStat: stat
    });
  };

  goToCheckout = () => {
    const { dispatch, app } = this.props;
    // const { variants } = app;
    const selected_variant = {
      price: 1800
    };
    // const name = app.name + "-" + selected_variant.name;
    console.log(this.state)
    const temp_qty = 1;
    const item = {
      // app: app.id,
      app_name: "Custom Domain",
      // logo: app.logo,
      logo: SentroLogo,
      // variant: selected_variant.id,
      variant_name: this.state.custom_domain,
      unit_price: selected_variant.price,
      // timeframe: selected_variant.timeframe,
      quantity: temp_qty,
      name: `Custom Domain - ${this.state.custom_domain}`,
      amount: selected_variant.price * temp_qty
    }
    dispatch(MarketplaceActions.setToCart(item))
    history.push(Path.APP_MARKETPLACE_CART)
  }

  render() {
    const { classes, loading, custom_domain, stores } = this.props;
    return (
      <div className={classes.mainDiv} style={{ minHeight: `calc(100vh - 68px)` }}>
        {/* <Snackbar
          open={this.state.alertStat && this.state.alertType === "success"}
          autoHideDuration={2000}
          onClose={this.handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleCloseAlert}
            severity="success"
            icon={<InfoOutlinedIcon fontSize="inherit" />}
          >
            Your Facebook Pixel ID is now linked to your Sentro shop. Visit your
            shop to check it out.
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.alertStat && this.state.alertType === "warning"}
          autoHideDuration={2000}
          onClose={this.handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleCloseAlert}
            severity="warning"
            icon={<InfoOutlinedIcon fontSize="inherit" />}
          >
            You have unlinked a Facebook Pixel ID from your Sentro shop.
          </Alert>
        </Snackbar> */}
        <SentroLoading open={loading} />
        <div className={classes.header}>
          <div
            className={classes.backDivWrapper}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
            <span>BACK</span>
          </div>
          <div className={classes.headerContent}>
            <LanguageIcon className={classes.Icon} />
            <div className={classes.headerDetails}>
              <strong>Sentro Shop custom domain</strong>
              <p>If you purchased your domain name (www.yourshopname.com), 
              you can use this as your link to your Sentro shop by doing the following steps.</p>
            </div>
          </div>
        </div>
        <div className={classes.root}>
          {/* STEP 1 */}
          <p>1. Provide the domain name you want to link to your Sentro shop </p>
          <div
            className={classes.inlineFormCard}
            style={{ borderBottom: "1px solid #D1D5DD" }}
          >
            <strong>Domain Name (e.g. www.yourshop.com)</strong>
            <div className="root">
              <div style={{ flexGrow: 1, textAlign: "right" }}>
                <OutlinedInput
                  style={{ width: "100%"}}
                  className="inputField" value={this.state.custom_domain}
                  type="text" onChange={event => {
                    this.setState({
                      custom_domain: event.target.value,
                      error: validations.domain(event.target.value)
                    });
                    this.checkUniqueDomain(event.target.value);
                  }}
                  error={this.state.error.length}
                  disabled={this.state.custom_domain && true && this.state.checked}
                />
                {
                  this.state.error.length ?
                  <p style={{ color: "red", marginLeft: "auto", display: "inline-flex", fontSize: 12}}>
                    {this.state.error}
                  </p> : ``
                }
              </div>
              <Button
                className="button ml-sm-auto"  color="primary" disableElevation variant="contained"
                onClick={this.onSubmit}
                style={{ height: 40}}
                disabled={this.state.error.length || 
                  (this.state.custom_domain && true && this.state.checked) || 
                  !stores.length || this.state.unique_domain_checking}
              >
                Submit 
              </Button>
            </div>
          </div>
          {
            custom_domain.name_servers ?
            <>
              {/* STEP 2 */}
              <p style={{ marginTop: "3em" }}>2. Login to your domain provider account and go to DNS settings. 
                Look for Nameserver settings and paste nameserver values generated below.</p>
              <div className={classes.d_flex}>
                <p style={{marginRight: "1em"}}>Nameserver:</p>
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                {
                  custom_domain.name_servers.map((name, index) => (
                    <div key={index} style={{marginTop: 5}}>
                      <CopyToClipboard
                        onCopy={() => this.setState({
                          ...this.state,
                          copied: [...this.state.copied, index]
                        })}
                        text={name}
                      >
                        <div className={classes.inlineTextButtonWrapper}>
                          <p>{name}</p>
                          <Button
                              color="primary"
                              disableElevation
                              variant="outlined"
                              style={{background: "white"}}
                              // onClick={e => this.copyToClipboard(e, index)}
                          >
                              { this.state.copied.includes(index) ? "Copied" : 'Copy'}
                          </Button>
                        </div>
                      </CopyToClipboard>
                    </div>
                  ))
                }
                </div>
              </div>
              {/* STEP 3 */}
              <p style={{ marginTop: "3em" }}>3. Once you have provided the nameserver values to 
              your domain provider, click on the button below to check if our servers have already 
              verified the domain.</p>
              <div style={{ display: "flex" }}>
                <div className={classes.inlineTextButtonWrapper}
                >
                  <p>{custom_domain.domain_name}</p>
                  <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    style={{ background: "white" }}
                    onClick={this.verifyDomain}
                    disabled={custom_domain.checkDnsLoading}
                  >
                    {
                      custom_domain.checkDnsLoading &&
                      <div className="pr-2" style={{ marginTop: 5, marginRight: 5 }}>
                        <CircularProgress className={classes.checkDnsLoading} />
                      </div>
                    }
                    Check verification status
                  </Button>
                </div>
              </div>
              {
                this.state.checked ?
                  this.state.verified ? 
                  <div
                    className={classes.inlineFormCard}
                    style={{ borderBottom: "1px solid #D1D5DD", marginTop: "3em" }}
                  >
                    <div className="root">
                      <CheckCircleIcon className={classes.checkIcon}/>
                      <div className="domainVerifiedContent">
                        <strong>Your domain is now verified.</strong>
                        <p>To complete the process, kindly pay here</p>
                        <p style={{ fontSize: 12 }}>After confirmation of the payment. Please wait
                          for the new domain name to take effect. This may take up to 72 hours.</p>
                      </div>
                      <Button
                        style={{ background: "white", height: 40}}
                        className="button"  color="primary" disableElevation variant="outlined"
                        onClick={this.goToCheckout}
                      >
                        Proceed Payment
                      </Button>
                    </div>
                  </div>
                  :
                  <>
                    {/* IF NOT VERIFIED */}
                    <div style={{  display: "flex", marginTop: "3em" }} >
                      <ErrorIcon style={{color:"#E24C4C"}} />
                      <p>Your domain is not yet verified. Please refresh the page or try again. </p>
                    </div>
                  </>
                : ``
              }
              
            </>: ``
          }
        </div>

        <AlertDialogBox state={this.state} setState={this.setAlert} />
      </div>
    );
  }
}

const mapStateToProps = state => ({ dashboard }) => ({
  loading: dashboard.loading,
  stores: dashboard.stores,
  custom_domain: dashboard.custom_domain
});
const CustomDomain = connect(mapStateToProps)(withStyles(useStylesSetup)(Setup));
export default CustomDomain;
