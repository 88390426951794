import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ACL from "../../../common/utilities/ACL";
import AdminDashboardLayout from "../../layout";
import Actions from "../../Redux/Actions";
import Header from './components/filters';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BasicTable, { stableSort, getComparator}  from '../../components/unPaginatedTable';
import moment from 'moment';
import history from "../../../../store/history";
import ENVS from "../../../../common/apiConfig"

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    padding: "2.0em"
  },
  title: {
    color: "#002C42",
    fontWeight: "bold",
    fontSize: 28,
  },
  card: {
    border: "1px solid #E6EAF0",
    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
    margin: "1.5em",
    backgroundColor: "white",
    padding: "2.0em",
    [theme.breakpoints.down("sm")]: {
      margin: "1em"
    }
  },
  cardHeader: {
    fontSize: "28px",
    marginBottom: "0.5em",
    textAlign: "left",
    color: "#F97663",
    fontWeight: 600
  },
  textDivider: {
    fontSize: "18px",
    padding: "1.5em 0",
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    "& > span": {
      marginRight: "1.5em"
    },
    "& > hr": {
      marginTop: 5,
      flexGrow: 1,
      paddingLeft: "3em",
      borderTop: "1px solid rgba(84, 87, 95, 0.75)",
      backgroundColor: "none"
    }
  },
  pageHeaderButton: {
    height: 35,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginTop: "1em",
      flex: 1
    },
    marginRight: 20
  },
  searchBar: {
    height: 35,
    background: "white",
    maxWidth: "240px",
    borderRadius: "4px",
    color: "gray",
    fontSize: 12,
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      marginTop: "1em",
      maxWidth: "100%",
      width: "100%"
    },
  },
  table: {
    "& th": {
      background: "#F97663",
      color: "white !important",
      fontSize: 14,
      // fontWeight: "bold",
      padding: 15,
    },
    "& th:hover": {
      color: "#002C42 !important",
    },
    "& td": {
      padding: 15,
      background: "white",
      fontSize: 14,
      fontWeight: "normal"
    }
  },
  status: {
    display: "flex",
    fontWeight: "bold",
    color: "white",
    "& .looking_for_rider": {
      background: "#FFC044",
      color: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .for_pickup": {
      background: "#FFC044",
      color: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .otw_to_customer": {
      background: "#FFC044",
      color: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .delivered": {
      background: "#57B19F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .completed": {
      background: "#57B19F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .cancelled": {
      background: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    }
  },
  link: {
    fontWeight: "bold",
    color: "#F97663",
    fontSize: 14,
    marginBottom: 0,
    cursor: "pointer",
  }

});

const headCells = [
  { id: 'ref_id', numeric: false, disablePadding: true, label: 'Tracking No.' },
  { id: 'shop', numeric: false, disablePadding: false, label: 'Shop' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'full_name', numeric: false, disablePadding: false, label: 'Recipient' },
  { id: 'delivery_datetime', numeric: false, disablePadding: false, label: 'Booking Date & Time' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Delivery Status' },
  { id: 'options', numeric: false, disablePadding: false, label: 'Actions' },
];


function TableBodyData (props) {
  return (
    <TableBody>
      {stableSort(props.data, getComparator(props.order, props.orderBy))
      .map((row, index) => {
        const status = (deliveryStatus) => {
          if (deliveryStatus === "new" || deliveryStatus === "available") {
            return "Looking for rider";
          } else if (deliveryStatus === "active") {
            return "For pickup";
          } else if (deliveryStatus === "courier_departed") {
            return "OTW to Customer";
          } else if (deliveryStatus === "finished") {
            return "Delivered";
          } else if (deliveryStatus === "canceled") {
            return "Cancelled";
          } else if (deliveryStatus === "completed") {
            return "Completed";
          }
        }
        return (
          <TableRow
            key={index}
            hover
            role="checkbox"
            tabIndex={-1}
          >
            <TableCell align="left">{row.mrspeedy_id}</TableCell>
            <TableCell align="left">
              <a className={props.classes.link} href={`${ENVS.localHost}shop/${row.site}`}
              target="_blank" rel="noopener noreferrer">
                {row.shop}
              </a>
            </TableCell>
            <TableCell align="left">{ row.payment_amount ? `PHP ${parseInt(row.payment_amount).toFixed(2)}`: "--"}</TableCell>
            <TableCell align="left">{row.recipient_name}</TableCell>
            <TableCell align="left">{moment(row.created_datetime).format('lll')}</TableCell>
            <TableCell align="left">
              {
                row.status ? 
                <div className={`${props.classes.status} `}>
                  <div className={status(row.status).toLowerCase().split(" ").join("_")}>{status(row.status)}</div>
                </div>
                : `--`
              }
            </TableCell>
            <TableCell align="left">
              <p className={props.classes.link} onClick={() => {
                history.push(`/admin/logistics/booking/${row.id}`)
              }}>
                View details
              </p>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  )
}

const Logistics = (props) => {
  const { classes, history, dispatch, bookings } = props;
  const [state, setState] = React.useState({
    type: "today",
    status: "all",
    order: "asc",
    orderBy: "pr_no",
    search: ""
  })

  React.useEffect(() => {
    dispatch(Actions.fetchLogiscticsBookings(state))
  }, [])

  const setFilters = (filters) => {
    setState({
      ...state,
      ...filters
    })
  }

  React.useEffect(() => {
    console.log(state)
    dispatch(Actions.fetchLogiscticsBookings(state))
  }, [state])

  return (
    <AdminDashboardLayout filterHeaderHidden backLink="/admin/logistics">
      <ACL roles={["admin", "superuser"]} redirect_to="/login">
        <div className="mx-4">
          <Header title="Delivery Bookings" onChange={setFilters}
            filter={state}/>
        </div>
        <div className="mt-4 mx-4">
          {
            bookings.length > 0 ?
            <div className={classes.table}>
              <BasicTable 
                headCells={headCells}
                tableBody={
                  <TableBodyData 
                    order={state.order} 
                    orderBy={state.orderBy} 
                    data={bookings}
                    classes={classes}
                    history={history}
                  />
                }
                length={5}
                defaultOrder="asc"
                defaultOrderBy="pr_no"
                setOrderAndOrderBy={(order, orderBy) => setState({...state, order, orderBy})}
                loading={false}
              />
            </div> :
            <div className="text-center m-5 p-5">
              <p className="text-secondary">No orders found</p>
            </div>
          }
        </div>
      </ACL>
    </AdminDashboardLayout>
  );
}

const mapStateToProps = state => ({ adminDashboard }) => ({ bookings: adminDashboard.logisticsBookings });
export default connect(mapStateToProps)(withStyles(useStyles)(Logistics));
