import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import SentroButton from "../../common/sentroButton";
import moment from "moment";
import InputPasswordForm from "../inputPasswordForm";
const Content = props => {
  const useStyles = makeStyles(theme => ({
    paper: {
      padding: "26px 26px 45px 26px",
      width: 600,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 5,
      border: "1px solid #D1D5DD",
      backgroundColor: "#fff",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: 15
      }
    }
  }));
  const classes = useStyles();
  let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let dateChanged = data ? data.password_change : "";
  sessionStorage.setItem("IN_SESSION", "CURRENT_USER");
  return (
    <div style={{ marginTop: "2vh" }}>
      <div style={{ display: props.showPassword === true ? "block" : "none" }}>
        <div className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: 20, fontWeight: 600, color: "#002C42" }}>
              Password
            </div>
            <div>
              <SentroButton
                disabled={data.is_facebookLogin}
                label="Change Password"
                variant="outlined"
                onClick={() => props.handleEditPassword()}
              />
            </div>
          </div>
          {data.is_facebookLogin ? (
            <div style={{ color: "#909196" }}>
              You are logged in via your Facebook account.
            </div>
          ) : (
            <div>
              Your password was updated last{" "}
              {moment(dateChanged).format(`DD MMM YYYY`)}
            </div>
          )}
        </div>
      </div>
      <div
        style={{ display: props.showEditPassword === true ? "block" : "none" }}
      >
        <InputPasswordForm
          handlePassword={props.handlePassword}
          handleEditPassword={props.handleEditPassword}
        />
      </div>
    </div>
  );
};

export default Content;
