import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import Paths from "../../router/paths";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { Select, useMediaQuery } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Container from "@material-ui/core/Container";
import { animateScroll as scroll } from "react-scroll";
import { ReactComponent as FBIcon } from "../../../../assets/images/enterprise/fb.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/images/enterprise/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../../../assets/images/enterprise/twitter.svg";
import { ReactComponent as PinterestIcon } from "../../../../assets/images/enterprise/pinterest_inherit_color.svg";
import ProductActions from "../../redux/actions/productActions";
import { useTheme } from "@material-ui/styles";

export default function Footer(props) {
  const enterprise = useSelector(state => state.enterprise.main);
  const primaryColor = useSelector(
    state => state.enterprise.main.theme.palette.primary
  );
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    toolbar: {
      minHeight: 128,
      alignItems: "flex-start",
      paddingTop: "1em",
      paddingBottom: "1em"
    },
    title: {
      flexGrow: 1,
      alignSelf: "flex-end"
    },
    links: {
      fontWeight: 600,
      fontSize: 14,
      color: primaryColor.contrastText
    },
    yearFooter: {
      fontWeight: 400,
      fontSize: 13
    },
    subTitle: {
      fontWeight: 600,
      fontSize: 14,
      color: "#2B2D32"
    },
    logo: {
      maxHeight: 50
    },
    info: {
      textAlign: "left",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center"
      }
    }
  }));
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const filters = useSelector(state => state.enterprise.main.products.filters);
  const categories = enterprise.enterprise.categories.slice(0, 16);
  const merchant = useSelector(state => state.enterprise.main.merchant);

  const [selectedCategory, setSelectedCategory] = React.useState("");
  const categoryHandleChange = event => {
    dispatch(
      ProductActions.setProductFilters({
        ...filters,
        page: 1
      })
    );
    setSelectedCategory(event.target.value);
    history.push(
      `/enterprise/${enterprise.enterprise.site_name}/products/${
        event.target.value !== "All" ? `?category=${event.target.value}` : ""
      }`
    );
    scroll.scrollTo(0);
  };

  const category = new URLSearchParams(useLocation().search).get("category");
  React.useEffect(() => {
    if (categories.length) {
      setSelectedCategory(category || "All");
    }
  }, [categories]);

  const privacyAndTermsFooter = (
    <div>
      <div className="d-flex">
        <Link
          to={`/enterprise/${enterprise.enterprise.site_name}/`}
          className={`mr-3 ${classes.links}`}
        >
          Privacy
        </Link>
        <p>|</p>
        <Link
          to={`/enterprise/${enterprise.enterprise.site_name}/`}
          className={`ml-3 ${classes.links}`}
        >
          Terms
        </Link>
      </div>
      <p className={classes.yearFooter}>{enterprise.enterprise.footerYear}</p>
    </div>
  );

  const categoriesDiv = ({ is_mobile }) =>
    is_mobile ? (
      <div>
        <p
          className={classes.subTitle}
          style={{
            color: primaryColor.contrastText
          }}
        >
          CATEGORIES
        </p>
        <FormControl variant="outlined" className="w-100" margin="dense">
          <Select
            fullWidth
            variant="outlined"
            className="bg-white"
            value={selectedCategory}
            onChange={categoryHandleChange}
            margin="dense"
          >
            <MenuItem value="All">All</MenuItem>
            {enterprise.enterprise.categories.map((item, index) => (
              <MenuItem value={item.name} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    ) : (
      <div>
        <p
          className={classes.subTitle}
          style={{
            color: primaryColor.contrastText
          }}
        >
          CATEGORIES
        </p>
        <div className="row">
          {categories.map((item, index) => (
            <div className="col-6 mb-3" key={index}>
              <Link
                to={`/enterprise/${enterprise.enterprise.site_name}/products/?category=${item.name}`}
                className={`mt-3 ${classes.links}`}
              >
                {item.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    );

  const companyDiv = ({ is_mobile }) =>
    is_mobile ? (
      <div>
        <p
          className={classes.subTitle}
          style={{
            color: primaryColor.contrastText
          }}
        >
          COMPANY
        </p>
        <div className="row">
          <div className="col-6">
            <div className="d-flex flex-column pt-1">
              {(enterprise.enterprise.menu.length &&
                enterprise.enterprise.menu[0].active) ||
              !enterprise.enterprise.menu.length ? (
                <Link
                  to={`/enterprise/${enterprise.enterprise.site_name}/about`}
                  className={`mt-3 ${classes.links}`}
                >
                  {enterprise.enterprise.menu.length
                    ? enterprise.enterprise.menu[0].text
                    : "About us"}
                </Link>
              ) : (
                ""
              )}
              {(enterprise.enterprise.menu.length &&
                enterprise.enterprise.menu[1].active) ||
              !enterprise.enterprise.menu.length ? (
                <Link
                  to={`/enterprise/${enterprise.enterprise.site_name}/directory`}
                  className={`mt-3 ${classes.links}`}
                >
                  {enterprise.enterprise.menu.length
                    ? enterprise.enterprise.menu[1].text
                    : "Directory"}
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-6 mt-3">
            <div className="d-flex flex-column pt-1">
              <Link
                to={`/enterprise/${enterprise.enterprise.site_name}/apply`}
                className={`${classes.links}`}
              >
                Become a Seller
              </Link>
              <Link
                to={`/enterprise/${enterprise.enterprise.site_name}/wishlist`}
                className={`mt-3 ${classes.links}`}
              >
                Registry
              </Link>
            </div>
          </div>
          {enterprise.enterprise.menu.length > 2
            ? enterprise.enterprise.menu
                .slice(2, enterprise.enterprise.menu.length + 1)
                .filter(i => i.active)
                .map((item, index) =>
                  item.link.includes("sentro") ? (
                    <div className="col-6 mt-3">
                      <a
                        href={`https://${item.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${classes.links}`}
                        onClick={() => history.push(item.link)}
                      >
                        {item.text}
                      </a>
                    </div>
                  ) : (
                    <div className="col-6 mt-3">
                      <a
                        href={`https://${item.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${classes.links}`}
                      >
                        {item.text}
                      </a>
                    </div>
                  )
                )
            : ""}
        </div>
      </div>
    ) : (
      <div
        className={`d-flex ${
          props.condensed
            ? "align-items-center ml-auto flex-wrap"
            : "flex-column"
        }`}
      >
        {!props.condensed ? (
          <p
            className={classes.subTitle}
            style={{
              color: primaryColor.contrastText
            }}
          >
            COMPANY
          </p>
        ) : (
          ""
        )}

        {(enterprise.enterprise.menu.length &&
          enterprise.enterprise.menu[0].active) ||
        !enterprise.enterprise.menu.length ? (
          <Link
            to={`/enterprise/${enterprise.enterprise.site_name}/about`}
            className={`mt-3 ${classes.links} ${props.condensed ? "mr-3" : ""}`}
          >
            {enterprise.enterprise.menu.length
              ? enterprise.enterprise.menu[0].text
              : "About us"}
          </Link>
        ) : (
          ""
        )}
        {/* <Link
          to={`/enterprise/${enterprise.enterprise.site_name}/`}
          className={`mt-3 ${classes.links}
        ${props.condensed ? "mr-3" : ""}`}
        >
          Partners Portal
        </Link> */}
        <Link
          to={`/enterprise/${enterprise.enterprise.site_name}/apply`}
          className={`mt-3 ${classes.links}
        ${props.condensed ? "mr-3" : ""}`}
        >
          Become a Seller
        </Link>
        {(enterprise.enterprise.menu.length &&
          enterprise.enterprise.menu[0].active) ||
        !enterprise.enterprise.menu.length ? (
          <Link
            to={`/enterprise/${enterprise.enterprise.site_name}/directory`}
            className={`mt-3 ${classes.links} ${props.condensed ? "mr-3" : ""}`}
          >
            {enterprise.enterprise.menu.length
              ? enterprise.enterprise.menu[1].text
              : "Directory"}
          </Link>
        ) : (
          ""
        )}
        <Link
          to={`/enterprise/${enterprise.enterprise.site_name}/wishlist`}
          className={`mt-3 ${classes.links} ${props.condensed ? "mr-3" : ""}`}
        >
          Registry
        </Link>

        {enterprise.enterprise.menu
          ? enterprise.enterprise.menu.length > 2
            ? enterprise.enterprise.menu
                .slice(2, enterprise.enterprise.menu.length + 1)
                .filter(i => i.active)
                .map((item, index) =>
                    item.link.includes("sentro") ? (
                      <a
                        href={`https://${item.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${classes.links} mt-3
                    ${props.condensed ? "mr-3" : ""}`}
                        onClick={() => history.push(item.link)}
                      >
                        {item.text}
                      </a>
                    ) : (
                      <a
                        href={`https://${item.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${classes.links} mt-3
                    ${props.condensed ? "mr-3" : ""}`}
                      >
                        {item.text}
                      </a>
                    )
                )
            : ""
          : ""}
      </div>
    );

  return (
    <AppBar position="static" style={{ boxShadow: "none" }}>
      <Container maxWidth="lg">
        <div className="px-2 py-5">
          {props.condensed ? (
            <div>
              <div className="align-items-center d-none d-md-flex">
                <img
                  alt=""
                  src={enterprise.enterprise.logo}
                  className={`img-fluid mr-3`}
                  style={{
                    maxHeight: 80
                  }}
                  onClick={() =>
                    history.push(
                      `/enterprise/${enterprise.enterprise.site_name}`
                    )
                  }
                />
                {companyDiv({ is_mobile: false })}
              </div>
              <div className="d-none d-md-block mt-5">
                {privacyAndTermsFooter}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 col-md-4 col-lg-6">
                <div className="col-12 col-lg-6 h-100">
                  <div className={`d-flex flex-column h-100 ${classes.info}`}>
                    <div style={{ maxHeight: 88 }} className="d-flex">
                      <img
                        className="mx-auto mx-md-1"
                        alt=""
                        src={enterprise.enterprise.logo}
                        style={{
                          width: isMobile ? "100%" : "auto",
                          maxHeight: "100%",
                          objectFit: "contain"
                        }}
                        onClick={() =>
                          history.push(
                            `/enterprise/${enterprise.enterprise.site_name}`
                          )
                        }
                      />
                    </div>
                    <p className="mt-3">{enterprise.enterprise.page_title}</p>
                    <div className="d-flex mb-5" style={{ marginLeft: -10 }}>
                      <div className="d-flex mx-auto mx-md-0">
                        {enterprise.enterprise.facebook ? (
                          <IconButton
                            className="mr-3"
                            onClick={() =>
                              window.open(
                                `https://facebook.com/${enterprise.enterprise.facebook}`,
                                "_blank"
                              )
                            }
                          >
                            <FBIcon
                              width="25"
                              height="25"
                              fill={primaryColor.contrastText}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        {enterprise.enterprise.instagram ? (
                          <IconButton
                            className="mr-3"
                            onClick={() =>
                              window.open(
                                `https://instagram.com/${enterprise.enterprise.instagram}`,
                                "_blank"
                              )
                            }
                          >
                            <InstagramIcon
                              width="25"
                              height="25"
                              fill={primaryColor.contrastText}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        {enterprise.enterprise.twitter ? (
                          <IconButton
                            onClick={() =>
                              window.open(
                                `https://twitter.com/${enterprise.enterprise.twitter}`,
                                "_blank"
                              )
                            }
                          >
                            <TwitterIcon
                              width="25"
                              height="25"
                              fill={primaryColor.contrastText}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        {enterprise.enterprise.pinterest ? (
                          <IconButton
                            onClick={() =>
                              window.open(
                                `https://www.pinterest.ph/${enterprise.enterprise.pinterest}`,
                                "_blank"
                              )
                            }
                          >
                            <PinterestIcon
                              width="25"
                              height="25"
                              fill={primaryColor.contrastText}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="d-none d-md-block mt-auto">
                      {privacyAndTermsFooter}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-8 col-lg-6 d-none d-md-block">
                <div className="row">
                  <div className="col-7">
                    {categoriesDiv({ is_mobile: false })}
                  </div>
                  <div className="col-5">
                    {companyDiv({ is_mobile: false })}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="d-block d-md-none">
            {props.condensed ? (
              <div className="d-flex align-items-center justify-content-center w-100 mb-5">
                <div
                  style={{
                    height: 35,
                    width: 35,
                    backgroundImage: `url(${merchant.logo_image})`,
                    borderRadius: 4,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                  }}
                />
                <h5
                  className="mb-0 ml-3"
                  style={{
                    color: primaryColor.contrastText
                  }}
                >
                  <strong>{merchant.name}</strong>
                </h5>
              </div>
            ) : (
              <div className="mt-4 ">{categoriesDiv({ is_mobile: true })}</div>
            )}
            <div className="mt-4 ">{companyDiv({ is_mobile: true })}</div>
            <div className="d-block d-md-none mt-5">
              {privacyAndTermsFooter}
            </div>
          </div>
        </div>
      </Container>
    </AppBar>
  );
}
