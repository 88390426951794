import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PropTypes from 'prop-types';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Skeleton from '@material-ui/lab/Skeleton';

function descendingComparator(a, b, orderBy) {
if (b[orderBy] < a[orderBy]) {
	return -1;
}
if (b[orderBy] > a[orderBy]) {
	return 1;
}
return 0;
}

function getComparator(order, orderBy) {
return order === 'desc'
	? (a, b) => descendingComparator(a, b, orderBy)
	: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{props.headCells.map((headCell) => (
				<TableCell
					className={classes.tableHead}
					key={headCell.id}
					align={headCell.numeric ? 'right' : 'left'}
					style={{ paddingLeft: "1em" }}
					sortDirection={orderBy === headCell.id ? order : false}
				>
					<TableSortLabel
						active={orderBy === headCell.id}
						direction={orderBy === headCell.id ? order : 'asc'}
						onClick={createSortHandler(headCell.id)}
						hideSortIcon={true}
						active={false}
					>
						<span>{headCell.label}</span>
						{
							headCell.label.length > 0 && 
							<span className={classes.headIconWrapper}>
								<ArrowDropUpIcon className={classes.headIconUp} />
								<ArrowDropUpIcon className={classes.headIconDown} />
							</span>
						}
					</TableSortLabel>
				</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
    tableContainer: {
        overflowX: "auto",
        '&::-webkit-scrollbar': {
          height: 7        
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.2)',
          outline: '1px solid slategrey',
          borderRadius: 10
        }
    },
	table: {
		// minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	noResult: {
		width: "100%",
		display: "block",
		textAlign: "center",
		padding: "2em"
	},
	tableHead: {
		backgroundColor: "#FAFAFA",
		color: "#F97663 !important",
		"&:hover": {
			color: '#F97663 !important'
		},
		"&:active": {
			color: '#F97663 !important'
		},
	},
	headIconWrapper: {
		direction: "rtl", 
		flexGrow: 1, 
		minWidth: 65, 
		display: "flex", 
		height: 20
	}, 
	headIconUp: {
		transform: "rotate(0deg)",
		position: "absolute",
		transform: "translateY(-8px)"
	},
	headIconDown: {
		transform: "rotate(180deg)",
		position: "absolute"
	},
}));

export default function EnhancedTable(props) {
	const classes = useStyles();
	const [order, setOrder] = React.useState(props.defaultOrder || 'desc');
	const [orderBy, setOrderBy] = React.useState(props.defaultOrderBy || 'volume');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [data, setData] = React.useState(props.data);

	useEffect(() => {
        setData(props.data);
	  }, [props.data]);

	useEffect(() => {
		const rowsCount = props.rowsPerPage ? props.rowsPerPage : 5
		setRowsPerPage(rowsCount)
	}, [props.rowsPerPage]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		const newOrder = isAsc ? 'desc' : 'asc';
		setOrder(newOrder);
		setOrderBy(property);
		props.setOrderAndOrderBy(newOrder, property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Fragment>
			<div className={classes.tableContainer}>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size={'medium'}
					aria-label="enhanced table"
					id="table"
				>
					<EnhancedTableHead
						classes={classes}
						headCells={props.headCells}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={props.length}
					/>
					{props.tableBody}
				</Table>
				{
					props.loading ? 
					<>
					{
						[1, 2, 3].map((index) => (
							<div style={{ padding: "0px 15px 0"}} key={index}>
								<Skeleton width="100%" height={60} />
							</div>
						))
					}
					</>
					:
					``
				}
				{
					!props.loading && !props.length ?
					<div style={{ height: "40vh", width: "100%", overflow: "hidden" }}>
						<div style={{ paddingTop: "4em" }}>
							<div style={{
								display: "flex",
								justifyContent: "center"
							}}>
								<img
									style={{width: "7em", height: "6em"}}
									src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
								/>
							</div>
							<div style={{
									display: "flex",
									justifyContent: "center",
									marginTop: 40,
									fontSize: 18,
									fontWeight: 600,
									color: "#b8bcc3",
									padding: "0 3em",
									textAlign: "center"
							}}>
								Nothing to Show
							</div>
						</div>
					</div>
					:
					``
				}
			</div>
		</Fragment>
	);
}

export { getComparator, stableSort}
