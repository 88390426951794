import React from "react";
import { Route, Switch } from "react-router-dom";
import Path from "../Paths";
import Home from "../components/proxtera";
import ProductList from "../components/proxtera/pages/productList";
import ProductDetailed from "../components/proxtera/pages/productDetailed";
import Cart from "../components/proxtera/pages/cart";
import Shipping from "../components/proxtera/pages/shipping";
import Checkout from "../components/proxtera/pages/checkout";
import Orders from "../components/proxtera/pages/orderList";
import MyOrders from "../components/proxtera/pages/myOrders";
import MyOrderDetailed from "../components/proxtera/pages/myOrderDetailed";
import OrderDetailed from "../components/proxtera/pages/orderDetailed";


let ProxteraRoutes = ([
  <Route key="order" path={Path.PROXTERA_ORDER} component={OrderDetailed} />,
  <Route key="myorder" path={Path.PROXTERA_MY_ORDER} component={MyOrderDetailed} />,
  <Route key="myorders" path={Path.PROXTERA_MY_ORDERS} component={MyOrders} />,
  <Route key="orders" path={Path.PROXTERA_ORDERS} component={Orders} />,
  <Route key="checkout" path={Path.PROXTERA_CHECKOUT} component={Checkout} />,
  <Route key="shipping" path={Path.PROXTERA_SHIPPING} component={Shipping} />,
  <Route key="cart" path={Path.PROXTERA_CART} component={Cart} />,
  <Route key="product_list" path={Path.PROXTERA_PRODUCT_DETAILED} component={ProductDetailed} />,
  <Route key="product_list" path={Path.PROXTERA_PRODUCT_LIST} component={ProductList} />,
  <Route key="home" path={Path.PROXTERA_HOME} component={Home} />,
]);
export default ProxteraRoutes;
		
