import React from "react";
import Text from "../../../common/text";
import {
  TextField,
  Select,
  FormHelperText,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
  makeStyles
} from "@material-ui/core";
import { addPaymentMethod } from "./../websiteBuilderActions";

import SentroFileField from "../../common/sentroFileField";
import SentroButton from "../../common/sentroButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  bankListing,
  paymentMethods,
  gcashImg,
  payPaylImg,
  paymayaImg
} from "./bankContants";
import { useDispatch, useSelector } from "react-redux";
import { previewImage } from "antd/lib/upload/utils";

let payMayaImg = "";

let PaymentFormDialog = props => {
  function dataURLtoFile(dataurl, filename, ext) {
    console.log(dataurl);
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    console.log(new File([u8arr], filename, { type: mime }));
    return new File([u8arr], `${filename}.${ext}`, { type: mime });
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      backgroundColor: "#fff",
      borderRadius: 4
    },
    btnSave: {
      width: 80,
      margin: 5,
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      "&:hover": { backgroundColor: "#DD5e56" }
    },
    btnCancel: {
      width: 80,
      margin: 5,
      "&:hover": { border: "1px solid #F97663" }
    }
  }));

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [isDialogEnough, setIsDialogEnough] = React.useState(false);
  const [loading, setLoading] = React.useState("");
  React.useEffect(() => {
    const payment = props.paymentMethod;
    let paymentMethodUse;
    if (payment.payment_method === "Bank") {
      paymentMethodUse =
        payment.bank_name && payment.account_name && payment.account_number;
    } else if (payment.payment_method === "PayPal") {
      paymentMethodUse = payment.payment_link;
    } else {
      paymentMethodUse = payment.mobile_number;
    }
    setIsDialogEnough(paymentMethodUse);
  }, [props.paymentMethod]);
  function setPaymentValue(key, value) {
    if (key == "email") {
      if (!value || value == "") {
        setError(prevState => ({
          ...prevState,
          email: "This field is required",
          disabled: true
        }));
      } else {
        setError(prevState => ({
          ...prevState,
          email: "",
          disabled: false
        }));
      }
    } else if (key == "account_number") {
      if (!value || value == "") {
        setError(prevState => ({
          ...prevState,
          account_number: "This field is required",
          disabled: true
        }));
      } else if (isNaN(value)) {
        setError(prevState => ({
          ...prevState,
          account_number: "Account number must be numerical",
          disabled: true
        }));
      } else {
        setError(prevState => ({
          ...prevState,
          account_number: "",
          disabled: false
        }));
      }
    } else if (key == "account_name") {
      if (!value || value == "") {
        setError(prevState => ({
          ...prevState,
          account_name: "This field is required",
          disabled: true
        }));
      } else {
        setError(prevState => ({
          ...prevState,
          account_name: "",
          disabled: false
        }));
      }
    } else if (key == "mobile_number") {
      if (!value || value == "") {
        setError(prevState => ({
          ...prevState,
          mobile_number: "This field is required",
          disabled: true
        }));
      } else if (isNaN(value)) {
        setError(prevState => ({
          ...prevState,
          mobile_number: "This is an invalid mobile number",
          disabled: true
        }));
      } else if (value.charAt(0) != 0) {
        setError(prevState => ({
          ...prevState,
          mobile_number: "This is an invalid mobile number",
          disabled: true
        }));
      } else if (value.charAt(1) != 9) {
        setError(prevState => ({
          ...prevState,
          mobile_number: "This is an invalid mobile number",
          disabled: true
        }));
      } else if (value.length > 11) {
        props.setPaymentMethod(prevState => ({
          ...prevState,
          mobile_number: value.toString().slice(0, 11)
        }));
      } else {
        setError(prevState => ({
          ...prevState,
          mobile_number: "",
          disabled: false
        }));
      }
    } else if (key == "payment_link") {
      if (!value || value == "") {
        setError(prevState => ({
          ...prevState,
          payment_link: "This field is required",
          disabled: true
        }));
      } else {
        setError(prevState => ({
          ...prevState,
          payment_link: "",
          disabled: false
        }));
      }
    } else {
      setError({});
    }
    props.setPaymentMethod(prevState => ({
      ...prevState,
      [key]: value
    }));
  }
  function cutMobileNumber(value) {
    let cut = value.slice(0, 11);
    return cut.replace(/[^0-9]/g, "");
  }
  function createPreview(file) {
    var blob = new Blob([file], { type: file.type });
    var blobUrl = URL.createObjectURL(blob);

    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function() {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function() {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    return blobUrl;
  }
  function renderPaymentIcon(paymentMethod, qr_code_img) {
    console.log(paymentMethod, qr_code_img, "code");
    if (paymentMethod == "Bank") {
      if (qr_code_img == "") {
        return dataURLtoFile(defaultImg, "bank", "png");
      } else {
        return qr_code_img;
      }
    } else if (paymentMethod == "PayMaya") {
      if (qr_code_img == "") {
        return dataURLtoFile(paymayaImg, "paymaya", "png");
      } else {
        return qr_code_img;
      }
    } else if (paymentMethod == "GCash") {
      if (qr_code_img == "") {
        return dataURLtoFile(gcashImg, "gcash", "png");
      } else {
        return qr_code_img;
      }
    } else if (paymentMethod == "PayPal") {
      return dataURLtoFile(payPaylImg, "paypal", "jpg");
    }
  }

  let STORE_UUID = localStorage.getItem("STORE_UUID");
  const dispatch = useDispatch();
  let [error, setError] = React.useState({ disabled: true });

  let defaultImg =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAUvSURBVHgB7d3Pi9R1HMfxTxE7F9eDsx3KQymYG4QHacFYURoIBA+rgWuQ3brUX6MXu3SrS0cDKQg2DJaCBQ/moQiSDq6X3YvrZfdi+xEGiuzFfHW+88N5PEA8ON9hGebpfHY/n31/X7p67frjAjzVywX4XwKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUyYTmeuMDkEMkGWlk6WKx9/VLrdbmEyCGRC1DiW3j355BPk8urFsrj4VmH8BDIB+nH8U+/9M+XEiXcK4yWQMTu9fOo/cfzr35ZOFsbnlcLY9HpnyuLxvJTqx7OxcbswegIZg/p9xrlzH5TDr7820ONFMj4CGbEax4WV841/UiWS8fA9yAjNzx94pjj6aiS93tnC6PgEGZF+HPPz8+V5LB4/9uTvtbVbhfb5BBmBYcXRVyNZ3d8rsevePoG0bNhx9C3sL9NWnjzvgUJ7BNKibvdQWb10cehx9NVILoikVQJpSY2jvnk7nU5pU41PJO0RSAvqOapRxNEnkvYIZMhqHPUc1aji6BNJOwQyRPVwYY1jXGokq5c+dFx+iOyDDMnTTuQmOzs7pYm5uc5AP9bt79R/9/0PZXPzQeH5CGRI+ht4g1pb+6ncb/AGrvENerK3RvL2/lJPIM/PEgsCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAwlUdN6i8KLZ8+VSZJ01+KWl4+VXb39gZ+/EGndMdiKgOpZ40GnSk1qRYWnLidBpZYEAgEAoFAIBAIBAKBQCAQCAQCgUAgEMzMVJP795tN+Dh8uN2d+q2t7bK72+CoycF5Q+HGYCYC2dl5VG58e7PRNZ9/9mlp0/r6L62N/WF4LLEgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEL129dv1xmTJ1osnKyvlG1zS95Vlb9zbv293dLXsNxv7Mzc01ujFo0+cfha++/qZMm5k5rNj2G76p+mZv8064bT//rLDEgkAgEAgEAoFAIBAIBAKBQCAQCAQCgWAmdtLreJ179/5qdM3i4rHSpj/3v569BmN/Fl7tloXuodKWOvmlyWikevTl6NE3yotuJgKpZ5LWfrzV6Jq2A/n1zt3GY3/aDOThw51Gr1Gd0TULgVhiQSAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCGZm7A/jd/2LL8u08QkCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgmJmxP+vrPze6ptc7W9q0sXG70V2vjhx5c/9Pe1NEtra2y507dwd+fKczV5aX3ysvupkZ+/Pb7380uqbtQDY3HzQa+1PvkNVmIPU/kSavUR37MwuBWGJBIBAIBAKBQCAQCAQCgUAgEAgEAoFAMBM76VXdiZ4k9euZn3808OM7nU5pUz060uQ1Ori/kz4LZiKQeizikyuXyyTp9c6USbKw0J2412gSWGJBIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIpvKoydb2drlx42aBtk1lIHVETZOROfCsLLEgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBD8DcdF1Hbi/9DMAAAAAElFTkSuQmCC";

  const { paymentMethod } = props;

  function onCloseDialog() {
    props.setOpenDialog(false);
    props.setPaymentMethod({
      user: paymentMethod.user,
      store: STORE_UUID,
      payment_method: "Bank",
      qr_code_img: paymentMethod.qr_code_img,
      bank_name: "UnionBank of the Philippines",
      account_name: "",
      account_number: "",
      email: "no@pics.com",
      payment_link: "",
      mobile_number: ""
    });
  }
  function submitPaymentMethod() {
    let payment = {
      user: paymentMethod.user,
      store: paymentMethod.store,
      payment_method: paymentMethod.payment_method,
      qr_code_img: renderPaymentIcon(
        paymentMethod.payment_method,
        paymentMethod.qr_code_img
      ),
      bank_name: paymentMethod.bank_name,
      account_name: paymentMethod.account_name,
      account_number: paymentMethod.account_number,
      email:
        paymentMethod.qr_code_img == defaultImg &&
        paymentMethod.payment_method == "Bank"
          ? "no@pics.com"
          : paymentMethod.payment_method == "GCash" ||
            paymentMethod.payment_method == "PayMaya" ||
            paymentMethod.payment_method == "PayPal"
          ? "null"
          : "null",
      payment_link: paymentMethod.payment_link,
      mobile_number: paymentMethod.mobile_number
    };
    dispatch(addPaymentMethod(payment));
    onCloseDialog();
  }

  return (
    <div>
      <Modal
        open={props.openDialog}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={props.openDialog}>
          <div style={modalStyle} className={classes.paper}>
            <div style={{ padding: "25px 50px 25px 50px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Text
                  textType="h3"
                  content={"Your Other Payment Channels"}
                  style={{ color: "#1E4B83" }}
                />
                <div
                  onClick={() => {
                    onCloseDialog();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <CloseIcon />
                </div>
              </div>
              <div style={{ marginTop: 20, display: "flex" }}>
                <div style={{ width: 400, marginRight: 50 }}>
                  <div style={{ width: "100%", marginTop: "2vh" }}>
                    <div style={{ display: "flex" }}>
                      <Text textType="p1_Semibold" content="Payment Method" />
                      <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
                    </div>
                    <Select
                      id="payment_method"
                      onChange={e =>
                        setPaymentValue("payment_method", e.target.value)
                      }
                      value={paymentMethod.payment_method}
                      variant="outlined"
                      fullWidth
                      placeholder="Bank"
                    >
                      {paymentMethods.map((item, key) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                  </div>
                  {console.log(
                    paymentMethod.qr_code_img,
                    "paymentForm preview"
                  )}
                  {paymentMethod.payment_method != "PayPal" ? (
                    <div style={{ width: "100%", marginTop: 10 }}>
                      <Text textType="p1_Semibold" content="QR Code" />
                      <SentroFileField
                        id="upload_field"
                        name="qr_code_img"
                        loading={loading}
                        preview={
                          paymentMethod.qr_code_img
                            ? createPreview(paymentMethod.qr_code_img)
                            : null
                        }
                        onChange={value =>
                          setPaymentValue("qr_code_img", value)
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <div style={{ width: "100%", marginTop: 10 }}>
                        <div style={{ display: "flex" }}>
                          <Text textType="p1_Semibold" content="Payment Link" />
                          <span style={{ color: "#c24a39", marginLeft: 2 }}>
                            *
                          </span>
                        </div>
                        <TextField
                          id="payment_link"
                          error={error && error.payment_link}
                          value={paymentMethod.payment_link}
                          color="secondary"
                          onChange={e =>
                            setPaymentValue("payment_link", e.target.value)
                          }
                          fullWidth
                          variant="outlined"
                        />
                        <FormHelperText error id="component-error-text">
                          {error && error.payment_link}
                        </FormHelperText>
                      </div>
                    </div>
                  )}
                </div>
                {paymentMethod.payment_method == "Bank" ? (
                  <div style={{ width: 400 }}>
                    <div style={{ width: "100%", marginTop: 10 }}>
                      <div style={{ display: "flex" }}>
                        <Text textType="p1_Semibold" content="Bank Name" />
                        <span style={{ color: "#c24a39", marginLeft: 2 }}>
                          *
                        </span>
                      </div>
                      <Select
                        id="bank_name"
                        value={paymentMethod.bank_name}
                        variant="outlined"
                        error={error && error.bank_name}
                        onChange={e =>
                          setPaymentValue("bank_name", e.target.value)
                        }
                        fullWidth
                        placeholder="Bank"
                      >
                        {bankListing.map((item, key) => {
                          return (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </div>

                    <div style={{ width: "100%", marginTop: 10 }}>
                      <div style={{ display: "flex" }}>
                        <Text textType="p1_Semibold" content="Account Name" />
                        <span style={{ color: "#c24a39", marginLeft: 2 }}>
                          *
                        </span>
                      </div>
                      <TextField
                        id="account_name"
                        color="secondary"
                        fullWidth
                        value={paymentMethod.account_name}
                        error={error && error.account_name}
                        variant="outlined"
                        onChange={e =>
                          setPaymentValue("account_name", e.target.value)
                        }
                      />
                      <FormHelperText error id="component-error-text">
                        {error && error.account_name}
                      </FormHelperText>
                    </div>
                    <div style={{ width: "100%", marginTop: 10 }}>
                      <div style={{ display: "flex" }}>
                        <Text textType="p1_Semibold" content="Account Number" />
                        <span style={{ color: "#c24a39", marginLeft: 2 }}>
                          *
                        </span>
                      </div>
                      <TextField
                        id="account_number"
                        value={paymentMethod.account_number}
                        color="secondary"
                        error={error && error.account_number}
                        fullWidth
                        variant="outlined"
                        onChange={e =>
                          setPaymentValue("account_number", e.target.value)
                        }
                      />
                      <FormHelperText error id="component-error-text">
                        {error && error.account_number}
                      </FormHelperText>
                    </div>
                  </div>
                ) : paymentMethod.payment_method == "PayPal" ? (
                  <div style={{ width: 400 }} />
                ) : (
                  <div style={{ width: 400 }}>
                    <div style={{ width: "100%", marginTop: 10 }}>
                      <div style={{ display: "flex" }}>
                        <Text textType="p1_Semibold" content="Mobile Number" />
                        <span style={{ color: "#c24a39", marginLeft: 2 }}>
                          *
                        </span>
                      </div>
                      <TextField
                        id="mobile_number"
                        color="secondary"
                        fullWidth
                        value={paymentMethod.mobile_number}
                        error={error && error.mobile_number}
                        variant="outlined"
                        onChange={e =>
                          setPaymentValue(
                            "mobile_number",
                            cutMobileNumber(e.target.value)
                          )
                        }
                        onBlur={e => {
                          if (e.target.value.length < 11) {
                            setError(prevState => ({
                              ...prevState,
                              mobile_number:
                                "Please enter a valid 11-digit mobile number",
                              disabled: true
                            }));
                          }
                        }}
                      />
                      <FormHelperText error id="component-error-text">
                        {error && error.mobile_number}
                      </FormHelperText>
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <SentroButton
                  id="cancel_button"
                  style={{ marginTop: 0, padding: 0 }}
                  className={classes.btnCancel}
                  onClick={() => onCloseDialog()}
                  label="Cancel"
                />
                <SentroButton
                  id="save_button"
                  style={{ marginTop: 0, padding: 0 }}
                  className={classes.btnSave}
                  disabled={error.disabled || !isDialogEnough}
                  label="Save"
                  variant="contained"
                  onClick={() => submitPaymentMethod()}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default PaymentFormDialog;
