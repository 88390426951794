import React from "react";
import SentroAppBar from "../common/layout/components/appBar";
import Content from "./termsAndConditions";
import DocumentMeta from "react-document-meta";
import Banner from "./banner";
import { useTheme, useMediaQuery } from "@material-ui/core";
import SentroAppBarMobile from "../common/layout/components/appBarMobile";
import MobileTNC from "../common/layout/mobile/TNCandPrivacyPolicy/layout";
const TNC = props => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const meta = {
    title: "Sentro | Terms and Conditions",
    description: "Here are Sentro's house rules once you start using it.",
    canonical: "https://sentro.ph/terms_and_conditions",
    meta: {
      charset: "utf-8"
    }
  };
  return (
    <DocumentMeta {...meta}>
      {mobileView ? (
        <div>
          <SentroAppBarMobile />
          <MobileTNC />
        </div>
      ) : (
        <div>
          <SentroAppBar />
          <Banner />
          <div
            style={{
              backgroundColor: "#FAFAFA",
              backgroundImage: `url('https://sentro-store-bucker.s3.us-east-2.amazonaws.com/sentro_swirls.png')`,
              position: "relative",
              backgroundSize: "contain"
              // backgroundRepeat: "no-repeat",
              // top: 50
            }}
          >
            <Content />
          </div>
        </div>
      )}
    </DocumentMeta>
  );
};

export default TNC;
