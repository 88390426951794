import React from "react";
import { makeStyles, Box, Dialog } from "@material-ui/core";
import SentroButton from "./sentroButton";

export const IdleTimeOutModal = ({ showModal, handleLogout }) => {
  const useStyles = makeStyles(theme => ({
    paper: {
      backgroundColor: "#f5f5f4",
      borderRadius: "4px",
      padding: 40,
      [theme.breakpoints.down("xs")]: {
        padding: 20
      }
    },
    h2: {
      fontWeight: "bold",
      fontSize: 26,
      [theme.breakpoints.down("xs")]: {
        fontSize: 24
      }
    }
  }));

  const classes = useStyles();
  return (
    <Dialog fullWidth maxWidth="xs" open={showModal}>
      <div className={classes.paper}>
        <h2 className={classes.h2}>
          You have been logged out due to inactivity
        </h2>
        <p>
          <div>Please log in again to continue using Sentro</div>
          <Box style={{ marginTop: 30 }}>
            <SentroButton
              id="ok_btn"
              variant="contained"
              fullWidth
              label="Ok"
              onClick={handleLogout}
            />
          </Box>
        </p>
      </div>
    </Dialog>
  );
};
