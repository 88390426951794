import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { reduxForm, Field, touch, reset, change } from "redux-form";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  IconButton,
  AppBar,
  Toolbar
} from "@material-ui/core";
import Form from "../components/shopCreateForm/form";
import ActivateAccountBanner from "../../../dashboard/common/activateAccountBanner";
import ProductForm from "../components/shopCreateForm/productForm";
import { showAlert } from "../../../common/commonActions";
import { useDispatch, useSelector } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import OTPActions from "../../../otp/redux/actions";
import OTPModal from "../../../otp/OTPModal";
import {
  createStorev2,
  addProductv2,
  patchVariantGroups,
  saveProductSelections,
  resetVariantGroups,
  getStore,
  ReduxInitializeFormActions
} from "../../websiteBuilderActions";
import {
  useProductSelectionAxiosData,
  getAllNewlyCreatedVariantIds,
  useProductSelectionsToDelete
} from "./variantChoicesForm";
import Path from "../../../../Paths";
import PublishShopSuccess from "../publishShopSuccess";
import { ArrowBack } from "@material-ui/icons";
import SelectShop from "../components/shopCreateForm/selectShop";
export default function ShopCreateForm(props) {
  const useStyles = makeStyles(theme => ({}));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const stores = useSelector(state => state.webBuilder.stores);
  const errors = useSelector(state => state.form.create_shop?.syncErrors);
  const product_errors = useSelector(
    state => state.form.product_form?.syncErrors
  );
  const variantChoicesErrors = useSelector(
    state => state.form.variant_choices?.syncErrors
  );
  const values = useSelector(state => state.form.create_shop?.values);
  const productValues = useSelector(state => state.form.product_form?.values);
  const variantCombinationFields = useSelector(
    state => state.form.variant_choices?.registeredFields
  );
  const loading = useSelector(state => state.webBuilder.loading);

  const productSelectionsData = useProductSelectionAxiosData();
  const productSelectionsToDelete = useProductSelectionsToDelete();
  const [activeStep, setActiveStep] = React.useState(0);
  const [variantGroups, setVariantGroups] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  const [error, setError] = React.useState("");
  const [prevMobile, setPrevMobile] = React.useState("");
  React.useEffect(() => {
    async function fetchStores() {
      let res = await dispatch(getStore());
      if (res.length) {
        history.push(Path.DASHBOARD);
      }
    }
    fetchStores();
  }, []);

  const submitOTP = () => {
    setActiveStep(2);
    scroll.scrollTo(0);
  };

  const spinner = (
    <div className="d-flex my-5 mr-3" id="spinner">
      <div
        className="spinner-border mx-auto"
        role="status"
        style={{
          color: "white",
          fontSize: 12,
          height: 25,
          width: 25
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  React.useEffect(() => {
    if (values?.social_media === "0" && values?.instagram) {
      dispatch(change("create_shop", "instagram", undefined));
    } else if (values?.social_media === "1" && values?.facebook) {
      dispatch(change("create_shop", "facebook", undefined));
    }
  }, [values?.social_media]);

  const handleNext = async () => {
    if (!user.is_verified) {
      dispatch(
        showAlert({
          type: "warning",
          variant: "filled",
          message: "Activate your Sentro account to enjoy more features"
        })
      );
    } else {
      if (activeStep === 0) {
        if (type) {
          setActiveStep(1);
          setError("");
          scroll.scrollTo(0);
        } else {
          setError("Please select a shop type to continue");
        }
      } else if (activeStep === 1) {
        if (errors && Object.keys(errors).length) {
          scroll.scrollTo(0);
          let errorKeys = Object.keys(errors);
          const fieldNames = ["name", "industry", "mobile", "site"];
          for (let i in fieldNames) {
            if (errorKeys.some(r => fieldNames[i].includes(r))) {
              dispatch(touch("create_shop", fieldNames[i]));
            }
          }
        } else {
          if (values?.mobile === prevMobile) {
            setActiveStep(2);
          } else {
            setPrevMobile(values?.mobile);
            dispatch(OTPActions.SEND_SMS_OTP(values?.mobile));
          }
        }
      } else if (activeStep === 2) {
        if (
          product_errors ||
          variantChoicesErrors ||
          (productValues.has_variants && !variantCombinationFields)
        ) {
          if (product_errors) {
            dispatch(touch("product_form", ...Object.keys(product_errors)));
          }
          scroll.scrollTo(0);
        } else {
          let res;
          if (values?.name && values.industry && values.mobile) {
            res = await dispatch(
              createStorev2({ ...values, shop_type: type, owner: user.id })
            );
            if (res) {
              res = await dispatch(
                addProductv2(res, {
                  ...productValues,
                  price: productValues.price || 0,
                  productImages: productValues.img,
                  weight: !productValues.weight
                    ? ""
                    : productValues.weight.includes("-")
                    ? productValues.weight
                    : productValues.free_form_weight,
                  store: res.uuid || ""
                })
              );
              // VARIANT GROUP
              res = await dispatch(
                patchVariantGroups(res.data.uuid, variantGroups)
              );
              // VARIANT COMBINATIONS
              const data = productSelectionsData.map(i => ({
                ...i,
                product_variant_ids: getAllNewlyCreatedVariantIds(res.data, i)
              }));
              await dispatch(
                saveProductSelections(data, productSelectionsToDelete)
              );
              setOpen(true);
              dispatch(reset("create_shop"));
              dispatch(reset("product_form"));
              dispatch(resetVariantGroups());
            } else {
              dispatch(
                showAlert({
                  type: "error",
                  message: "An error has occured. Please try again"
                })
              );
            }
          }
        }
      }
    }
  };

  return (
    <>
      {isMobile ? (
        //MOBILE
        <Dialog open={true} fullScreen>
          <AppBar
            position="fixed"
            className="bg-white"
            style={{ boxShadow: "none", borderBottom: "1px solid #d1d5dd" }}
          >
            <Toolbar className="d-flex position-relative justify-content-center align-items-center">
              {activeStep !== 0 ? (
                <IconButton
                  className="position-absolute"
                  style={{ zIndex: 1, left: 10 }}
                  onClick={() => {
                    setActiveStep(activeStep === 2 ? 1 : 0);
                    window.scrollTo(0, 0);
                  }}
                >
                  <ArrowBack color="primary" />
                </IconButton>
              ) : (
                ""
              )}

              <div
                className="text-center d-flex justify-content-center"
                style={{ fontWeight: 600, fontSize: 16, color: "#002c42" }}
              >
                {activeStep === 0 ? "Welcome to Sentro!" : "Set up your shop"}
              </div>
            </Toolbar>
          </AppBar>
          <div
            className="my-5"
            style={{
              backgroundColor: activeStep === 2 ? "#fff" : "#fafafa",
              minHeight: activeStep === 2 ? "" : "110vh"
            }}
          >
            <div className="pt-4 pb-3">
              <Stepper
                className="p-0"
                style={{
                  backgroundColor: activeStep === 2 ? "#fff" : "#fafafa"
                }}
                activeStep={activeStep}
                alternativeLabel
              >
                <Step key="select_shop">
                  <StepLabel>Select shop</StepLabel>
                </Step>
                <Step key="shop_info">
                  <StepLabel>Shop info</StepLabel>
                </Step>
                <Step key="add_product">
                  <StepLabel>Add a product</StepLabel>
                </Step>
              </Stepper>
            </div>
            {!user.is_verified && (
              <div className="px-3">
                <ActivateAccountBanner />
              </div>
            )}
            <div>
              {/**STEP 1: SHOP TYPE */}
              {activeStep === 0 ? (
                <SelectShop type={type} setType={setType} />
              ) : (
                ""
              )}
              {/**STEP 2: SHOP INFORMATION*/}
              {activeStep === 1 ? (
                <div className="p-3">
                  <OTPModal proceedCallback={submitOTP} />
                  <Form type={type} />
                </div>
              ) : (
                ""
              )}
              {/**STEP 3: ADD PRODUCT FORM*/}
              {activeStep === 2 ? (
                <div>
                  <div className="text-center py-3" style={{ fontSize: 14 }}>
                    Add your <b>first product</b> to publish your online shop.
                  </div>
                  <ProductForm variantGroupOnChange={setVariantGroups} />
                </div>
              ) : (
                ""
              )}
            </div>
            {error ? (
              <div
                className="text-center"
                style={{ color: "#f97663", fontSize: 12 }}
              >
                {error}
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="w-100 bg-white p-3 position-fixed d-flex"
            style={{
              zIndex: 2,
              bottom: 0,
              filter: "drop-shadow(0px -4px 16px rgba(0, 0, 0, 0.12))",
              borderRadius: "8px 8px 0 0"
            }}
          >
            {activeStep !== 0 ? (
              <Button
                variant="outlined"
                color="primary"
                className="w-50 mr-2"
                onClick={() => {
                  setActiveStep(activeStep === 2 ? 1 : 0);
                  window.scrollTo(0, 0);
                }}
              >
                Back
              </Button>
            ) : (
              ""
            )}

            <Button
              disableElevation
              variant="contained"
              color="primary"
              className={`${activeStep !== 0 ? "w-50" : "w-100"}`}
              size="small"
              onClick={handleNext}
            >
              {loading ? spinner : ""}
              {activeStep === 0
                ? "Create my online shop"
                : activeStep === 2
                ? "Publish shop"
                : "Add a product"}
            </Button>
          </div>
          <PublishShopSuccess open={open} setOpen={setOpen} />
        </Dialog>
      ) : (
        //DESKTOP
        <div style={{ padding: activeStep === 2 ? "0 13em" : "0 17em" }}>
          <div>
            <div className="d-flex align-items-center position-relative">
              {activeStep !== 0 ? (
                <Button
                  startIcon={<ArrowBack color="primary" />}
                  color="primary"
                  style={{ zIndex: 1 }}
                  onClick={() => {
                    setActiveStep(activeStep === 2 ? 1 : 0);
                    window.scrollTo(0, 0);
                  }}
                >
                  Back
                </Button>
              ) : (
                ""
              )}
              <div
                style={{ fontSize: 28, fontWeight: 600, color: "#002c42" }}
                className={`text-center ${
                  activeStep !== 0 ? "position-absolute" : ""
                } w-100`}
              >
                {activeStep === 0 ? "Welcome to Sentro!" : "Set up your shop"}
              </div>
            </div>
            <div
              className={`bg-white mt-3 pt-2 border`}
              style={{ borderRadius: 4 }}
            >
              {/*STEPPER*/}
              <div className="px-4">
                <Stepper activeStep={activeStep} alternativeLabel>
                  <Step key="select_shop">
                    <StepLabel>Select shop</StepLabel>
                  </Step>
                  <Step key="shop_info">
                    <StepLabel>Shop information</StepLabel>
                  </Step>
                  <Step key="add_product">
                    <StepLabel>Add a product</StepLabel>
                  </Step>
                </Stepper>
              </div>
              {/**ACTIVATE ACCOUNT BANNER */}
              {!user.is_verified && (
                <div className="px-4">
                  <ActivateAccountBanner />
                </div>
              )}
              <div className={`px-4`}>
                {/**STEP 1: SHOP TYPE */}
                {activeStep === 0 ? (
                  <SelectShop type={type} setType={setType} />
                ) : (
                  ""
                )}
                {/**STEP 2: SHOP INFORMATION*/}
                {activeStep === 1 ? (
                  <div className="py-2">
                    <OTPModal proceedCallback={submitOTP} />
                    <Form type={type} />
                  </div>
                ) : (
                  ""
                )}
                {/**STEP 3: ADD PRODUCT FORM*/}
                {activeStep === 2 ? (
                  <div>
                    <div className="text-center py-3" style={{ fontSize: 14 }}>
                      Add your <b>first product</b> to publish your online shop.
                    </div>
                    <ProductForm variantGroupOnChange={setVariantGroups} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {error ? (
                <div
                  className="text-center"
                  style={{ color: "#f97663", fontSize: 12 }}
                >
                  {error}
                </div>
              ) : (
                ""
              )}

              {activeStep === 0 ? (
                <div className="d-flex px-4 pb-4 pt-3">
                  <div className="w-50 mr-3"></div>
                  <Button
                    disableElevation
                    className="w-50 mx-3"
                    color="primary"
                    variant="contained"
                    onClick={handleNext}
                  >
                    Create my online shop
                  </Button>
                </div>
              ) : (
                <div className="px-4 pb-4 pt-3 d-flex">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="w-50 mr-2"
                    onClick={() => {
                      setActiveStep(activeStep === 2 ? 1 : 0);
                      window.scrollTo(0, 0);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    className="w-50"
                    onClick={handleNext}
                  >
                    {loading ? spinner : ""}
                    {activeStep === 2 ? "Publish shop" : "Add a product"}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <PublishShopSuccess open={open} setOpen={setOpen} />
        </div>
      )}
    </>
  );
}
