import React from "react";
import { ArrowBack } from "@material-ui/icons";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  Dialog,
  Slide,
  IconButton,
  useTheme
} from "@material-ui/core";
import Form from "./form";
import Path from "../../../../Paths";
import { useHistory } from "react-router-dom";
import { getBuxStatus, getCODWallet } from "../../../payments/action";
import { useSelector, useDispatch } from "react-redux";
import { RedirectDialog } from "../../../payments/components/bux/redirectToBux";
import RedirectToBux from "./redirectToBux";
import SuccessSubscription from "./successSubscription";
import MobileBottomNavigation from "../../../common/layout/mobile/bottomNavigation";
import { Store, Fastfood, Receipt } from "@material-ui/icons";
import SubscriptionActions from "../../redux/actions";
import apiConfig from "../../../../common/apiConfig";
const Transition = props => <Slide direction="up" {...props} />;
export default function Subscription(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const loading = useSelector(state => state.payments.loading);
  const subscription_loading = useSelector(state => state.subscription.loading);
  const buxWallet = useSelector(state => state.payments.buxParams);
  const codWallet = useSelector(state => state.payments.codWallet);
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [subscription, setSubscription] = React.useState("2016");
  let menus = [
    {
      label: "My Shop",
      path: Path.MOBILE_WEB_BUILDER,
      icon: <Store />,
      key: 0
    },
    {
      label: "Products",
      path: Path.MOBILE_PRODUCTS,
      icon: <Fastfood />,
      key: 1
    },
    {
      label: "Orders",
      path: Path.MOBILE_ORDERS,
      icon: <Receipt />,
      key: 2
    },
    {
      label: "Vouchers",
      path: Path.MOBILE_VOUCHERS,
      icon: "",
      key: 3
    }
  ];

  const handleNext = () => {
    if (activeStep === 1) {
      setActiveStep(1);
      setOpen(true);
    } else {
      window.scrollTo(0, 0);
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handlePayment = async () => {
    let res = await dispatch(
      SubscriptionActions.subscriptionPayment({
        months: subscription === "2016" ? 3 : 1
      })
    );
    try {
      if (res.status === 200) {
        setOpen(false);
        window.location.href = `${res.data.payment_link}/?redirect_url=${apiConfig.localHost}subscription/success`;
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      let res = await dispatch(getBuxStatus(user.id));
      await dispatch(getCODWallet());
      try {
        if (res.status === 200) {
          console.log(res);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (buxWallet.kyc_level === 0 && !codWallet) {
      history.push(Path.DASHBOARD);
    }
  }, [buxWallet, codWallet]);
  return (
    <div>
      {isMobile ? (
        <>
          <Dialog open={true} fullScreen TransitionComponent={Transition}>
            <div className="position-relative" style={{ height: "100%" }}>
              <div
                className="bg-white border-bottom p-3 d-flex align-items-center position-fixed"
                style={{ top: 0, left: 0, right: 0, zIndex: 3 }}
              >
                <IconButton
                  className="position-absolute"
                  onClick={() => history.goBack()}
                >
                  <ArrowBack color="primary" />
                </IconButton>
                <div
                  style={{ fontSize: 16, fontWeight: 600, color: "#002c42" }}
                  className="w-100 text-center"
                >
                  Subscription
                </div>
              </div>
              <div
                className="my-4 px-4 pt-4"
                style={{ backgroundColor: "#fafafa", paddingBottom: "15vh" }}
              >
                {success ? (
                  <div className="mt-4">
                    <SuccessSubscription />
                  </div>
                ) : (
                  <Form
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    subscription={subscription}
                    setSubscription={setSubscription}
                  />
                )}
              </div>
              {success ? (
                <div
                  className="position-fixed"
                  style={{ bottom: 0, left: 0, right: 0 }}
                >
                  <MobileBottomNavigation menu={menus} />
                </div>
              ) : (
                <div
                  className="p-4 bg-white position-fixed"
                  style={{
                    zIndex: 1,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    boxShadow: "0px -8px 24px rgba(0,0,0,0.12)",
                    borderRadius: "8px 8px 0px 0px"
                  }}
                >
                  <Button
                    disableElevation
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={
                      loading ||
                      (activeStep === 1 &&
                        codWallet.usable_balance < subscription)
                    }
                    onClick={handleNext}
                  >
                    {activeStep === 0 ? "Proceed to payment" : "Pay now"}
                  </Button>
                </div>
              )}
            </div>
          </Dialog>
        </>
      ) : (
        <div style={{ padding: "0 10vw" }}>
          <div className="d-flex align-items-center position-relative">
            <Button
              color="primary"
              variant="text"
              startIcon={<ArrowBack />}
              style={{ zIndex: 3 }}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <div
              style={{ fontSize: 29, fontWeight: 600, color: "#002c42" }}
              className="w-100 text-center position-absolute"
            >
              Subscription
            </div>
          </div>

          <div
            className="mt-4 bg-white p-4"
            style={{ border: "1px solid #d1d5dd", borderRadius: 4 }}
          >
            <Form
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              subscription={subscription}
              setSubscription={setSubscription}
            />
            <Button
              disableElevation
              fullWidth
              color="primary"
              variant="contained"
              className="mt-4 mb-2"
              disabled={
                loading ||
                (activeStep === 1 && codWallet.usable_balance < subscription)
              }
              onClick={handleNext}
            >
              {activeStep === 0 ? "Proceed to payment" : "Pay now"}
            </Button>
          </div>
        </div>
      )}
      <RedirectToBux
        open={open}
        setOpen={setOpen}
        setSuccess={setSuccess}
        handleContinue={handlePayment}
        loading={subscription_loading}
      />
    </div>
  );
}
