import history from "../../store/history";
import Path from "../../Paths";
import { showAlert } from "../common/commonActions";
import {
  getProfileInfo,
  saveProfileInfo,
  confirmChangePassword,
  updatePassword
} from "./AccountSettingsServices";
export const actionTypes = {
  LOADING_ON: "@@LOADING_ON",
  LOADING_OFF: "@@LOADING_OFF",
  GET_PROFILE: "@@GET_PROFILE",
  SAVE_PROFILE_REQUEST: "@@SAVE_PROFILE_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "@@CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILED: "@@CHANGE_PASSWORD_FAILED",
  OPEN_ALERT: "@@OPEN_ALERT",
  CLOSE_ALERT: "@@CLOSE_ALERT",
  UPDATE_PASSWORD: "@@UPDATE_PASSWORD"
};

export function getProfile() {
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.LOADING_ON });
      let response = await getProfileInfo();
      dispatch({
        type: actionTypes.GET_PROFILE,
        payload: response
      });
      dispatch({ type: actionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: actionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        history.push(Path.LOGIN);
      } else {
        dispatch(showAlert({ type: "error", message: error.message }));
      }
    }
  };
}

export function saveProfile(value) {
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.SAVE_PROFILE_REQUEST });
      let response = await saveProfileInfo(value);
      dispatch(getProfile(value));
      dispatch({ type: actionTypes.LOADING_OFF });
      dispatch({ type: actionTypes.OPEN_ALERT });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        history.push(Path.LOGIN);
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function confirmChangePasswordAction(value) {
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.LOADING_ON });
      let response = await confirmChangePassword(value);
      dispatch({
        type: actionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: response
      });
      let update_password = await updatePassword(value);
      dispatch({ type: actionTypes.UPDATE_PASSWORD, payload: update_password });
      dispatch({ type: actionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        history.push(Path.LOGIN);
      } else if (error && error.status == 400) {
        dispatch({ type: actionTypes.CHANGE_PASSWORD_FAILED });
      } else {
        dispatch(showAlert({ type: "error", message: error.message }));
      }
      return error;
    }
  };
}

export function closeAlert() {
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.CLOSE_ALERT });
    } catch (error) {
      dispatch({ type: actionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        history.push(Path.LOGIN);
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}
