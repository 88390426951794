import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  useMediaQuery,
  Dialog,
  Slide,
  IconButton
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import Form from "./form";
import Path from "../../Paths";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionActions from "./redux/actions";
import ActivateBuxWalletModal from "../delivery_settings/components/activateBuxWalletModal";
import { getCODWallet, getBuxStatus } from "../payments/action";
const Transition = props => <Slide direction="up" {...props} />;
export default function SentroAdvanced(props) {
  const useStyles = makeStyles(theme => ({}));
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const loading = useSelector(state => state.payments.loading);
  const subscription = useSelector(state => state.subscription.subscription);
  const buxWallet = useSelector(state => state.payments.buxParams);
  const codWallet = useSelector(state => state.payments.codWallet);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(SubscriptionActions.getSubscription());
    dispatch(getBuxStatus(user.id));
    dispatch(getCODWallet());
  }, []);
  return (
    <>
      {isMobile ? (
        <Dialog open={isMobile} fullScreen TransitionComponent={Transition}>
          <div
            className="bg-white border-bottom p-3 d-flex align-items-center position-fixed"
            style={{ top: 0, left: 0, right: 0, zIndex: 3 }}
          >
            <IconButton
              className="position-absolute"
              onClick={() => history.goBack()}
            >
              <ArrowBack color="primary" />
            </IconButton>
            <div
              style={{ fontSize: 16, fontWeight: 600, color: "#002c42" }}
              className="w-100 text-center"
            >
              Sentro Advanced Plan
            </div>
          </div>
          <div
            style={{ fontSize: 14, color: "#54575f", paddingTop: "10vh" }}
            className="text-center px-3"
          >
            Whether you're just starting or already growing, Sentro has
            everything you need to make your business stand out. Be the center
            of attention with our <strong>Basic</strong> and{" "}
            <strong>Advanced</strong> plans.
          </div>
          {subscription && subscription.free_trial_availed ? (
            <div className="d-flex justify-content-center py-4">
              <Button
                disableElevation
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() =>
                  buxWallet?.kyc_level >= 1 && codWallet
                    ? history.push(Path.SUBSCRIPTION)
                    : setOpen(true)
                }
              >
                {loading ? (
                  <div
                    className="spinner-border mx-auto"
                    role="status"
                    style={{
                      color: "white",
                      fontSize: 12,
                      height: 25,
                      width: 25
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ""
                )}
                Subscribe to Advanced
              </Button>
            </div>
          ) : (
            ""
          )}
          <Form setOpen={setOpen} />
        </Dialog>
      ) : (
        <div style={{ padding: "0 9vw", position: "relative" }}>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              startIcon={<ArrowBack color="primary" />}
              variant="text"
              color="primary"
              className="position-absolute"
              onClick={() => history.push(Path.DASHBOARD)}
            >
              Back
            </Button>
            <div
              style={{ fontSize: 28, color: "#002c42" }}
              className={`font-weight-bold text-center w-100`}
            >
              Sentro Advanced Plan
            </div>
            {subscription && subscription.free_trial_availed ? (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                className="position-absolute"
                style={{ height: 40, right: "9vw" }}
                disabled={loading}
                onClick={() =>
                  buxWallet?.kyc_level >= 1 && codWallet
                    ? history.push(Path.SUBSCRIPTION)
                    : setOpen(true)
                }
              >
                {loading ? (
                  <div
                    className="spinner-border mx-auto"
                    role="status"
                    style={{
                      color: "rgba(0, 0, 0, 0.26)",
                      fontSize: 12,
                      height: 25,
                      width: 25
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ""
                )}
                Subscribe to Advanced
              </Button>
            ) : (
              ""
            )}
          </div>
          <div
            className="bg-white border rounded my-3"
            style={{ padding: "32px 32px 0" }}
          >
            <div
              style={{ fontSize: 14, color: "#54575f" }}
              className="text-center"
            >
              Whether you're just starting or already growing, Sentro has
              everything you need to make your business stand out. Be the center
              of attention with our <strong>Basic</strong> and{" "}
              <strong>Advanced</strong> plans.
            </div>
            <div>
              <Form setOpen={setOpen} />
            </div>
          </div>
          {subscription && subscription.free_trial_availed ? (
            <div className="d-flex justify-content-center py-4">
              <Button
                disableElevation
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() =>
                  buxWallet?.kyc_level >= 1 && codWallet
                    ? history.push(Path.SUBSCRIPTION)
                    : setOpen(true)
                }
              >
                {loading ? (
                  <div
                    className="spinner-border mx-auto"
                    role="status"
                    style={{
                      color: "white",
                      fontSize: 12,
                      height: 25,
                      width: 25
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ""
                )}
                Subscribe to Advanced
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <ActivateBuxWalletModal
        open={open}
        setOpen={setOpen}
        is_ninjavan={false}
      />
    </>
  );
}
