import React from "react";
import {
  Button,
  Paper,
  Chip,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Checkbox,
  IconButton,
  Link,
  useMediaQuery
} from "@material-ui/core";
import BuyerSideMenu from "./buyerSideMenu";
import BuyerAppbar from "./buyerAppbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "../../../store/history";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import sampleImg from "../../../assets/images/imgPlaceholder2.svg";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import tags from "../../../assets/images/tags.svg";
import { useSelector, useDispatch } from "react-redux";
import Path from "../../../Paths";
import apiConfig from "../../../common/apiConfig";
import Utils from "../../common/utils";
import BuyerActions from "./redux/actions";
import ViewOrderCard from "./viewOrderCard";
import PurchasedViewOrderModal from "./components/purchasedViewOrderModal";
import StoreActions from "../../common/template/components/StoreActions";
const PurchasedViewOrder = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      backgroundColor: "#fafafa"
    },
    side: {
      display: "flex",
      position: "fixed",
      height: "100%",
      zIndex: 3
    },
    header: {
      width: "65%",
      paddingTop: "5vh",
      display: "flex",
      alignItems: "center",
      position: "relative"
    },
    headerLabel: {
      fontSize: 28,
      fontWeight: 600,
      color: "#002c42",
      width: "100%",
      position: "absolute",
      textAlign: "center"
    },
    orderSection: {
      width: "65%"
    },
    cartHeaderSection: {
      padding: "18px 24px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #d1d5dd"
    },
    labelTitle: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149"
    },
    btnViewShop: {
      padding: "0 20px",
      fontWeight: 500,
      borderRadius: 20,
      height: 40
    },
    columnItem: {
      padding: "16px 0 16px 0",
      border: "none",
      width: "40%",
      fontSize: 14,
      fontWeight: 600,
      color: "rgba(43,45,50,0.8)"
    },
    column1: {
      width: "10%",
      border: "none",
      fontSize: 14,
      fontWeight: 600,
      color: "rgba(43,45,50,0.8)"
    },
    column2: {
      position: "relative",
      width: "20%",
      border: "none",
      fontSize: 14,
      fontWeight: 600,
      color: "rgba(43,45,50,0.8)"
    },
    itemSection: {
      display: "flex"
    },
    productImage: {
      width: 60,
      height: 60,
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    labelbold: {
      fontSize: 12,
      fontWeight: 600,
      color: "#2b2d32"
    },
    label: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2b2d32"
    },
    variantChip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    },
    btnQuantity: {
      borderRadius: 0,
      width: 25,
      height: 25,
      minWidth: 25
    },
    summary: {
      marginTop: "5vh",
      height: "fit-content",
      padding: 24
    },
    voucherLink: {
      color: "#54575f",
      fontSize: 14,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: 500
    },
    columnHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "0 0 2vh"
    },
    column: {
      borderBottom: "none",
      fontSize: 12,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0"
    },
    columnFooter: {
      borderTop: "1px dashed #d1d5dd",
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0 0"
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const selected_order = useSelector(
    state => state.buyerDashboard.selected_purchased_order
  );
  const cart = selected_order ? JSON.parse(selected_order.cart) : [];
  const status = useSelector(state => state.buyerDashboard.purchased_tab);
  const [selected, setSelected] = React.useState([]);
  const [productSelected, setProductSelected] = React.useState([]);
  const all_cart_products = [];
  const voucher = selected_order
    ? selected_order.voucher
      ? JSON.parse(selected_order.voucher)
      : ""
    : "";
  function actionButton2Label() {
    if (status === "To Pay") {
      return "Pay now";
    } else if (status === "To Ship") {
      return "Cancel order";
    } else if (status === "To Receive") {
      return "Order Received";
    } else if (status === "Completed" || status === "Cancelled") {
      return "Buy again";
    }
  }

  const actionOnClick = () => {
    if (status === "To Pay") {
      handleToPay();
    } else if (status === "To Ship") {
      dispatch(BuyerActions.cancelOrder(selected_order.id));
    } else if (status === "To Receive") {
      dispatch(BuyerActions.markAsReceived(selected_order.id));
      return "Order Received";
    } else if (status === "Completed" || status === "Cancelled") {
      handleBuyAgain();
    }
  };

  async function handleToPay() {
    if (
      selected_order.payment_option === "otc" &&
      selected_order.channel === null
    ) {
      window.location.href = `${apiConfig.buxURL}/checkout/${selected_order.bux_uid}/?redirect_url=${apiConfig.localHost}my_cart/checkout?ref_id=${selected_order.ref_id}`;
    } else {
      let res = await dispatch(
        BuyerActions.viewCheckoutOrder(selected_order.id)
      );

      try {
        if (res.status === 200) {
          history.push(`/my_cart/checkout`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function handleBuyAgain() {
    for (let i in cart) {
      all_cart_products.push({
        uuid: cart[i].uuid,
        quantity: cart[i].quantity,
        variant: cart[i].variants ? cart[i].variants : [],
        productSelection: cart[i].productSelection
      });
    }

    dispatch(StoreActions.patchBuyerCart(all_cart_products));
    setTimeout(async () => {
      dispatch(BuyerActions.getMyCart());
      let res = await dispatch(
        BuyerActions.getSelectedOrder(selected_order.store.store_uuid)
      );
      try {
        if (res.status === 200) {
          history.push(Path.CART_VIEW_ORDER);
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  }

  React.useEffect(() => {
    if (selected_order === "") {
      history.push(Path.PURCHASED_PAGE);
    }
  }, [selected_order]);

  React.useEffect(() => {
    productSelected.length = 0;
    if (selected) {
      for (let i in cart) {
        for (let e in selected) {
          if (cart[i].name === selected[e]) {
            productSelected.push(cart[i]);
            let newSelected = [...productSelected];
            setProductSelected(newSelected);
          }
        }
      }
    }
  }, [selected]);

  React.useEffect(() => {
    productSelected.length = 0;
    if (cart) {
      for (let i in cart) {
        for (let e in selected) {
          if (selected[e] === cart[i].name) {
            productSelected.push(cart[i]);
            let newSelected = [...productSelected];
            setProductSelected(newSelected);
          }
        }
      }
    }
  }, [cart]);
  return (
    <>
      {isMobile ? (
        <PurchasedViewOrderModal />
      ) : (
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.header}>
              <Button
                startIcon={<ArrowBackIcon color="primary" />}
                variant="text"
                color="primary"
                onClick={() => {
                  history.goBack();
                  window.scrollTo(0, 0);
                }}
                style={{ zIndex: 3 }}
              >
                Back
              </Button>
              <div className={classes.headerLabel}>{status}</div>
            </div>
            <div style={{ display: "flex" }}>
              <ViewOrderCard />
              <div style={{ width: "35%", marginLeft: "2vw" }}>
                <Paper className={classes.summary}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.columnHeader}
                        >
                          Summary
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.columnHeader}
                        >
                          <Link className={classes.voucherLink}>
                            <img
                              src={tags}
                              width="15"
                              height="15"
                              style={{ marginRight: 5 }}
                            />
                            Vouchers
                          </Link>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.column} align="left">
                          Items
                        </TableCell>
                        <TableCell className={classes.column} align="right">
                          {parseFloat(
                            cart.reduce((total, object) => {
                              return (
                                parseFloat(total) + parseFloat(object.quantity)
                              );
                            }, 0)
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.column} align="left">
                          Subtotal
                        </TableCell>
                        <TableCell className={classes.column} align="right">
                          ₱ {selected_order ? selected_order.subtotal : ""}
                        </TableCell>
                      </TableRow>
                      {selected_order && selected_order.shipping_fee ? (
                        <TableRow>
                          <TableCell className={classes.column} align="left">
                            Delivery fee
                          </TableCell>
                          <TableCell className={classes.column} align="right">
                            ₱{" "}
                            {selected_order ? selected_order.shipping_fee : ""}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}

                      {voucher ? (
                        <TableRow>
                          <TableCell className={classes.column} align="left">
                            Voucher
                          </TableCell>
                          <TableCell className={classes.column} align="right">
                            - ₱ {voucher.voucher_discount}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}

                      <TableRow>
                        <TableCell
                          className={classes.columnFooter}
                          align="left"
                        >
                          Total
                        </TableCell>
                        <TableCell
                          className={classes.columnFooter}
                          align="right"
                        >
                          ₱ {selected_order ? selected_order.amount : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
                <div style={{ marginTop: "3vh" }}>
                  <Button
                    fullWidth
                    disableElevation
                    color="primary"
                    variant="contained"
                    style={{ height: 40 }}
                    onClick={actionOnClick}
                  >
                    {actionButton2Label()}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchasedViewOrder;
