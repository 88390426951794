import { MarketplaceActionTypes } from "../actions/marketplaceActions";
import { ProductActionTypes } from "../actions/productActions";
import { AuthActionTypes } from "../actions/authActions";
import {
  DashboardActionTypes,
  ReduxInitializeFormActionTypes
} from "../actions/dashboardActions";
import { grey } from "@material-ui/core/colors";
import { BuyerDashboardActionTypes } from "../actions/buyerDashboardActions";
import DashboardUtils from "../../utils/dashboard";
import { getPromoCode } from "../../../common/template/components/PromoBanner12.12";
import {
  computeCartAmount,
  computeBuyerCartAmount,
  computeVoucherDiscount,
  add_to_cart,
  update_quantity,
  combine_same_products,
  compute_subtotal,
  add_to_cart_wishlist,
  update_quantity_wishlist
} from "../../components/cart/common/productFunctions";
const initialState = {
  theme: {
    palette: {
      primary: {
        light: "white",
        main: `#bfbfbf`, // <--- change with the enterprise theme color here!
        dark: grey[800],
        contrastText: "white"
      },
      secondary: {
        light: grey[400],
        main: "#18b4a1",
        dark: grey[600],
        secondaryColor: "#18b4a1",
        defaultHoverColor: "#1D9485"
      },
      imgColorPlaceholder: "#e4f7ec"
    },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold"
      },
      fontFamily: ["Inter", "sans-serif"]
    }
  },
  enterprise: {
    logo: "",
    name: "",
    description: "",
    footerYear: "",
    categories: [],
    banners: [],
    directory: {
      selectedCategory: "Fashion & Beauty"
    },
    section_title: "",
    section_description: "",
    section_button_text: "",
    colors: "",
    font: "",
    category_section_title: "",
    products_section_title: "",
    products_to_show: 4,
    menu: ""
  },
  products: {
    subcategory: [],
    location: ["Metro Manila", "Luzon", "Visayas", "Mindanao", "All"],
    deliversTo: ["Metro Manila", "Luzon", "Visayas", "Mindanao", "All"],
    length: 0,
    products: [],
    filters: {
      search: "",
      sortBy: "Date added",
      sort: "desc",
      page: 1,
      pageCount: 0,
      itemsPerPage: 12,
      category: [],
      subcategory: [],
      location: [],
      deliversTo: []
    },
    selectedProduct: ""
  },
  cart: {
    loading: false,
    items: [],
    cart_amount: 0,
    cart_drawer: false,
    cart_items: [],
    cart_items_wishlist: [],
    selectedViewOrder: "",
    selectedCheckout: {
      items: [],
      amount: "",
      subtotal: ""
    },
    checkout: "",
    payments: "",
    customerDetails: "",
    voucherList: [],
    applied_voucher: "",
    voucher_discount: 0,
    delivery_locations: [],
    deliveryDetails: {
      loading: false,
      error: "",
      shipping: "",
      shippingFee: 0,
      delivery_detail: "",
      mrspeedy_delivery_address: {
        address: "",
        longitude: "",
        latitude: ""
      }
    },
    promo: {
      promo_code: getPromoCode(),
      bannerOpen: false,
      checked: false,
      eligible: false,
      discount: 0,
      amount_gt: 0,
      loading: false
    }
  },
  merchants: [],
  merchant: {
    uuid: "",
    name: "",
    categories: [],
    location: "",
    global_category: "",
    products: {
      length: 0,
      products: [],
      filters: {
        sortBy: "Date added",
        sort: "desc",
        page: 1,
        pageCount: 0,
        itemsPerPage: 8,
        subcategory: [],
        location: [],
        deliversTo: []
      },
      loading: false
    }
  },
  loading: false,
  merchant_application: {
    error: "",
    authenticate_loading: false,
    prev_account: ""
  },
  buyer_dashboard: {
    loading: false,
    cart_items: [],
    selectedViewOrder: "",
    selectedPurchasedViewOrder: "",
    selectedCheckout: {
      items: [],
      amount: "",
      subtotal: ""
    },
    customerDetails: "",
    payments: "",
    checkout: "",
    purchased_orders: [],
    purchased_tab: "To Pay",
    voucherList: [],
    applied_voucher: "",
    voucher_discount: 0,
    cart_amount: 0,
    purchased_filter: {
      itemsPerPage: 5,
      page: 1
    },
    delivery_locations: [],
    deliveryDetails: {
      loading: false,
      error: "",
      shipping: "",
      shippingFee: 0,
      delivery_detail: "",
      mrspeedy_delivery_address: {
        address: "",
        longitude: "",
        latitude: ""
      }
    },
    promo: {
      promo_code: getPromoCode(),
      bannerOpen: false,
      checked: false,
      eligible: false,
      discount: 0,
      amount_gt: 0,
      loading: false
    },
    showPopup: {
      open: false,
      alertType: "success",
      message: ""
    }
  },
  error: {
    showAlert: false,
    alertType: "success",
    message: ""
  },
  dashboard: {
    loading: false,
    errorPopup: {
      open: false,
      alertType: "success",
      message: ""
    },
    customize: {
      errors_visible: false,
      formErrors: {
        branding: [],
        homepage: [],
        menu: [],
        ads: []
      }
    },
    fonts: [],
    merchants_page: {
      loading: false,
      filters: {
        tab: 0,
        search: "",
        category: "Category",
        sortBy: "Sort by",
        sort: "asc",
        page: 1,
        pageCount: 0,
        itemsPerPage: 8
      },
      merchants: [],
      filtered_merchants: [],
      requests_count: 0
    },
    manage_marketplace: {
      s3UploadingFields: [],
      categories: {
        loading: false,
        offset: 0,
        title: "Marketplace information"
      }
    },
    buyers_page: {
      loading: false,
      filters: {
        search: "",
        sortBy: "Sort by",
        sort: "asc",
        page: 0,
        itemsPerPage: 5
      },
      buyers: [],
      filtered_buyers: [],
      requests_count: 0
    },
    profile: {},
    analytics: {}
  },
  change_password: {
    loading: false,
    status: "",
    verify: ""
  }
};

export default function enterpriseReducers(state = initialState, action) {
  let subcategory;
  let cart_amount = 0;
  let filtered_merchants = [];
  let requests_count = 0;
  let itemCart;
  let cart = [];
  let cart_wishlist = [];
  let newList = [];
  let buyerItemToUpdate;
  let buyerItemList = [];
  let itemAlreadyListed = false;
  let buyerNewItems = [];
  let itemToUpdate;
  let itemList;
  let new_items = [];
  let productSelection;
  let selected_order_items = [];
  let selected_checkout_items = [];
  let selected_view_order_items = [];
  let itemToAdd;
  let newItemList = [];
  let personal_items = [];
  let registry_items = [];
  switch (action.type) {
    // MARKETPLACE
    case MarketplaceActionTypes.GET_MARKETPLACE:
      return {
        ...state,
        loading: true
      };
    case MarketplaceActionTypes.GET_MARKETPLACE_SUCCESS:
      let data = {};
      const keys = Object.keys(action.payload);
      for (let i in keys) {
        data[keys[i]] =
          action.payload[keys[i]] === null ? "" : action.payload[keys[i]];
      }
      return {
        ...state,
        loading: false,
        theme: {
          ...state.theme,
          palette: {
            ...state.theme.palette,
            primary: {
              ...state.theme.palette.primary,
              main: action.payload.colors
                ? action.payload.colors.primary_bg || "#83BBB9"
                : "#83BBB9",
              contrastText: action.payload.colors
                ? action.payload.colors.primary_text || "#FFFFFF"
                : "#FFFFFF"
            },
            secondary: {
              ...state.theme.palette.secondary,
              main: action.payload.colors
                ? action.payload.colors.secondary_bg || "#18B4A1"
                : "#18B4A1",
              secondaryColor: action.payload.colors
                ? action.payload.colors.secondary_text || "#FFFFFF"
                : "#FFFFFF",
              contrastText: action.payload.colors
                ? action.payload.colors.secondary_text || "#FFFFFF"
                : "#FFFFFF"
            }
          }
        },
        enterprise: {
          ...state.enterprise,
          logo: action.payload.logo_image,
          description: action.payload.description,
          name: action.payload.name,
          site_name: action.payload.site_name,
          footerYear: `© ${action.payload.name}`,
          facebook: action.payload.facebook,
          instagram: action.payload.instagram,
          twitter: action.payload.twitter,
          pageTitle: action.payload.page_title,
          companyPhoto: action.payload.company_photo,
          ...data,
          banners: action.payload.banners || []
        }
      };
    case MarketplaceActionTypes.GET_GLOBAL_CATEGORY_SUCCESS:
      return {
        ...state,
        enterprise: {
          ...state.enterprise,
          categories: action.payload
        }
        // loading: true
      };
    case MarketplaceActionTypes.REMOVE_CART_ITEMS:
      return {
        ...state,
        cart: {
          ...state.cart,
          items: []
        }
      };
    case MarketplaceActionTypes.LOADING_OFF:
      return {
        ...state,
        loading: false
      };
    // PRODUCTS
    case ProductActionTypes.SET_PRODUCT_FILTERS:
      return {
        ...state,
        products: {
          ...state.products,
          filters: {
            ...state.products.filters,
            ...action.payload
          }
        }
      };
    case ProductActionTypes.GET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true
        }
      };
    case ProductActionTypes.GET_PRODUCTS_SUCCESS:
      subcategory = action.payload.subcategories;
      subcategory.sort();
      return {
        ...state,
        products: {
          ...state.products,
          products: action.addtlOptions.appendToCurrentData
            ? [...state.products.products, ...action.payload.results]
            : action.payload.results,
          length: action.payload.count,
          subcategory,
          filters: {
            ...state.products.filters,
            pageCount: Math.ceil(
              action.payload.count / state.products.filters.itemsPerPage
            ),
            page: action.page
          },
          loading: false
        }
      };
    case ProductActionTypes.GET_PRODUCTS_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          products: [],
          subcategory: [],
          loading: false,
          length: 0,
          filters: {
            ...state.products.filters,
            page: 1,
            pageCount: 0
          }
        }
      };
    case ProductActionTypes.GET_SELECTED_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          selectedProduct: action.payload
        }
      };
    case ProductActionTypes.GET_BUYER_CART:
      let cartItems = action.payload.all;
      let newItems = [];
      let all_products = [];
      for (let i in cartItems) {
        all_products.push({
          ...cartItems[i].product_data,
          uuid: cartItems[i].uuid,
          quantity: cartItems[i].quantity,
          variants: cartItems[i].variant,
          stock: cartItems[i].product_data.quantity,
          productSelection: cartItems[i].productSelection,
          registry: cartItems[i].registry
        });
      }

      cart_amount = computeCartAmount(all_products);
      return {
        ...state,
        cart: {
          ...state.cart,
          items: all_products,
          cart_amount
        }
      };
    case ProductActionTypes.ADD_PRODUCT_TO_CART:
      itemToAdd = action.payload;
      newItemList = [...state.cart.items];
      new_items = itemToAdd.registry
        ? add_to_cart_wishlist(itemToAdd, newItemList)
        : add_to_cart(itemToAdd, newItemList);
      cart_amount = computeCartAmount(new_items);
      return {
        ...state,
        cart: {
          ...state.cart,
          items: new_items,
          cart_amount
        }
      };
    case ProductActionTypes.MARKETPLACE_UPDATE_QUANTITY:
      itemToUpdate = action.payload;
      productSelection = itemToUpdate.productSelection;
      itemList = [...state.cart.items];
      new_items =
        itemToUpdate && itemToUpdate.registry
          ? update_quantity_wishlist(itemToUpdate, productSelection, itemList)
          : update_quantity(itemToUpdate, productSelection, itemList);
      cart_amount = computeCartAmount(new_items);
      return {
        ...state,
        cart: {
          ...state.cart,
          items: new_items,
          cart_amount
        }
      };
    case ProductActionTypes.UPDATE_VARIANT:
      itemToUpdate = action.payload;
      productSelection = itemToUpdate.productSelection;
      itemList = [...state.cart.items];
      new_items = combine_same_products(
        itemToUpdate,
        productSelection,
        itemList
      );
      registry_items = new_items.filter(i => i.registry);
      personal_items = new_items.filter(i => !i.registry);
      for (let product of personal_items) {
        if (product["uuid"] === itemToUpdate["uuid"]) {
          if (itemToUpdate.has_variants && productSelection) {
            if (itemToUpdate.prevSelection) {
              if (
                product.productSelection.id === itemToUpdate.prevSelection.id
              ) {
                product.variants = itemToUpdate.variants;
                product.productSelection = productSelection;
              }
            } else {
              product.variants = itemToUpdate.variants;
              product.productSelection = productSelection;
            }
          }
        }
      }
      new_items = [...registry_items, ...personal_items];
      cart_amount = computeCartAmount(new_items);
      return {
        ...state,
        cart: {
          ...state.cart,
          items: new_items,
          cart_amount
        }
      };

    case ProductActionTypes.MARKETPLACE_REMOVE_FROM_CART:
      let newState = [...state.cart.items];
      newState.splice(action.payload, 1);
      cart_amount = computeCartAmount(newState);
      return {
        ...state,
        cart: {
          ...state.cart,
          items: newState,
          cart_amount
        }
      };
    case ProductActionTypes.CART_DRAWER:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_drawer: action.payload
        }
      };
    case ProductActionTypes.GET_MY_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          loading: true
        }
      };
    case ProductActionTypes.GET_MY_CART_SUCCESS:
      itemCart = action.payload;

      cart = [];
      cart_wishlist = [];
      for (let i in itemCart) {
        let items = itemCart[i].items;
        cart.push({
          id: parseInt(i),
          items: itemCart[i].items,
          store_data: itemCart[i].store_data,
          subtotal: compute_subtotal(items),
          registry: itemCart[i].registry,
          numOfItems: parseFloat(
            items.reduce((total, object) => {
              return object.product_data.has_variants
                ? parseFloat(total) +
                    (object.product_data.always_available
                      ? parseFloat(object.quantity)
                      : object.quantity > 0
                      ? parseFloat(object.quantity)
                      : 0)
                : parseFloat(total) +
                    (object.product_data.always_available
                      ? parseFloat(object.quantity)
                      : object.product_data.quantity > 0
                      ? parseFloat(object.quantity)
                      : 0);
            }, 0)
          )
        });
      }
      // cart = [];
      // cart_wishlist = [];
      // for (let i in itemCart.personal) {
      //   let items = itemCart.personal[i].items;
      //   cart.push({
      //     items: items,
      //     store_data: itemCart.personal[i].store_data,
      //     numOfItems: parseFloat(
      //       items.reduce((total, object) => {
      //         return (
      //           parseFloat(total) +
      //           (object.product_data.always_available
      //             ? parseFloat(object.quantity)
      //             : object.product_data.quantity > 0
      //             ? parseFloat(object.quantity)
      //             : 0)
      //         );
      //       }, 0)
      //     ),
      //     subtotal: compute_subtotal(items)
      //   });
      // }
      // for (let i in itemCart.wishlist) {
      //   let items = itemCart.wishlist[i].items;
      //   cart_wishlist.push({
      //     items: items,
      //     store_data: itemCart.wishlist[i].store_data,
      //     numOfItems: parseFloat(
      //       items.reduce((total, object) => {
      //         return (
      //           parseFloat(total) +
      //           (object.product_data.always_available
      //             ? parseFloat(object.quantity)
      //             : object.product_data.quantity > 0
      //             ? parseFloat(object.quantity)
      //             : 0)
      //         );
      //       }, 0)
      //     ),
      //     subtotal: compute_subtotal(items)
      //   });
      // }
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: cart,
          cart_items_wishlist: cart_wishlist,
          loading: false
        }
      };
    case ProductActionTypes.MARKETPLACE_VIEW_ORDER:
      selected_view_order_items = [];
      for (let i in action.payload.items) {
        selected_view_order_items.push({
          ...action.payload.items[i],
          id: parseInt(i)
        });
      }
      return {
        ...state,
        cart: {
          ...state.cart,
          loading: false,
          selectedViewOrder: !selected_view_order_items.length
            ? ""
            : {
                store_data: action.payload.store_data,
                items: selected_view_order_items,
                id: action.payload.id
              }
        }
      };
    case ProductActionTypes.MARKETPLACE_SET_SELECTED_CHECKOUT:
      return {
        ...state,
        cart: {
          ...state.cart,
          selectedCheckout: {
            items: action.payload,
            subtotal: compute_subtotal(action.payload),
            amount:
              compute_subtotal(action.payload) +
              parseFloat(state.cart.deliveryDetails.shippingFee)
          }
        }
      };
    case ProductActionTypes.MARKETPLACE_POST_CHECKOUT:
      return {
        ...state,
        cart: {
          ...state.cart,
          loading: false,
          checkout: action.payload
        }
      };
    case ProductActionTypes.MARKETPLACE_POST_CHECKOUT_FAILED:
      return {
        ...state,
        cart: {
          ...state.cart,
          loading: false
        }
      };
    case ProductActionTypes.MARKETPLACE_REMOVE_ITEM:
      newList = [...state.cart.selectedViewOrder.items];
      newList.splice(action.payload, 1);
      cart_amount = computeBuyerCartAmount(newList);
      return {
        ...state,
        cart: {
          ...state.cart,
          selectedViewOrder: {
            ...state.cart.selectedViewOrder,
            items: newList
          },
          cart_amount,
          voucher_discount: computeVoucherDiscount(
            state.cart.applied_voucher,
            cart_amount
          )
        }
      };
    case ProductActionTypes.MARKETPLACE_UPDATE_ITEM:
      buyerItemToUpdate = action.payload.params;
      productSelection = buyerItemToUpdate.productSelection;
      buyerItemList = [...action.payload.selected_order_items];
      selected_checkout_items = [...state.cart.selectedCheckout.items];
      new_items =
        buyerItemToUpdate && buyerItemToUpdate.registry
          ? update_quantity_wishlist(
              buyerItemToUpdate,
              productSelection,
              buyerItemList
            )
          : update_quantity(buyerItemToUpdate, productSelection, buyerItemList);
      for (let i in new_items) {
        selected_order_items.push({
          product_data: { ...new_items[i], quantity: new_items[i].stock },
          productSelection: new_items[i].productSelection,
          registry: new_items[i].registry ? new_items[i].registry : "",
          quantity: new_items[i].quantity,
          variant: new_items[i].variants,
          uuid: new_items[i].uuid,
          id: new_items[i].id
        });
      }

      if (selected_checkout_items.length) {
        for (let product of selected_checkout_items) {
          if (product["id"] === buyerItemToUpdate["id"]) {
            product.quantity = buyerItemToUpdate.quantity;
          }
        }
      }
      return {
        ...state,
        cart: {
          ...state.cart,
          selectedViewOrder: {
            ...state.cart.selectedViewOrder,
            items: selected_order_items
          },
          selectedCheckout: {
            items: selected_checkout_items,
            subtotal: compute_subtotal(selected_checkout_items),
            amount:
              compute_subtotal(selected_checkout_items) +
              parseFloat(state.cart.deliveryDetails.shippingFee)
          },
          voucher_discount: computeVoucherDiscount(
            state.cart.applied_voucher,
            state.cart.selectedCheckout.amount
          )
        }
      };
    case ProductActionTypes.MARKETPLACE_UPDATE_VARIANT:
      buyerItemToUpdate = action.payload.productToUpdate;
      productSelection = buyerItemToUpdate.productSelection;
      buyerItemList = [...action.payload.selected_order_items];
      selected_checkout_items = [...state.cart.selectedCheckout.items];
      new_items = combine_same_products(
        buyerItemToUpdate,
        productSelection,
        buyerItemList
      );
      registry_items = new_items.filter(i => i.registry);
      personal_items = new_items.filter(i => !i.registry);
      for (let product of personal_items) {
        if (product["uuid"] === buyerItemToUpdate["uuid"]) {
          if (buyerItemToUpdate.has_variants && productSelection) {
            if (buyerItemToUpdate.prevSelection) {
              if (
                product.productSelection.id ===
                buyerItemToUpdate.prevSelection.id
              ) {
                itemAlreadyListed = true;
                product.variant = buyerItemToUpdate.variant;
                product.productSelection = buyerItemToUpdate.productSelection;
              }
            } else {
              itemAlreadyListed = true;
              product.variant = buyerItemToUpdate.variant;
              product.productSelection = buyerItemToUpdate.productSelection;
            }
          }
        }
      }
      for (let i in personal_items) {
        selected_order_items.push({
          product_data: { ...new_items[i] },
          productSelection: new_items[i].productSelection,
          quantity: new_items[i].quantity,
          variant: new_items[i].variants,
          uuid: new_items[i].uuid,
          id: new_items[i].id
        });
      }
      if (selected_checkout_items.length) {
        for (let product of selected_checkout_items) {
          if (product["id"] === buyerItemToUpdate["id"]) {
            product.variant = buyerItemToUpdate.variants;
            product.productSelection = buyerItemToUpdate.productSelection;
          }
        }
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          selectedViewOrder: {
            ...state.cart.selectedViewOrder,
            items: selected_order_items
          },
          selectedCheckout: {
            items: selected_checkout_items,
            subtotal: compute_subtotal(selected_checkout_items),
            amount:
              compute_subtotal(selected_checkout_items) +
              parseFloat(state.cart.deliveryDetails.shippingFee)
          },
          voucher_discount: computeVoucherDiscount(
            state.cart.applied_voucher,
            state.cart.selectedCheckout.amount
          )
        }
      };
    case ProductActionTypes.MARKETPLACE_SET_CUSTOMER_DETAILS:
      return {
        ...state,
        cart: {
          ...state.cart,
          customerDetails: action.payload
        }
      };
    case ProductActionTypes.MARKETPLACE_SET_DELIVERY_DETAILS:
      return {
        ...state,
        cart: {
          ...state.cart,
          deliveryDetails: action.payload,
          selectedCheckout: {
            ...state.cart.selectedCheckout,
            subtotal: compute_subtotal(state.cart.selectedCheckout.items),
            amount:
              compute_subtotal(state.cart.selectedCheckout.items) +
              parseFloat(action.payload.shippingFee)
          }
        }
      };
    case ProductActionTypes.MARKETPLACE_GET_PAYMENTS:
      return {
        ...state,
        cart: {
          ...state.cart,
          payments: action.payload,
          loading: false
        }
      };

    case ProductActionTypes.MARKETPLACE_GET_VOUCHERS:
      return {
        ...state,
        cart: {
          ...state.cart,
          voucherList: action.payload.sort((a, b) => (b.is_applicable ? 1 : -1))
        }
      };
    case ProductActionTypes.MARKETPLACE_APPLY_VOUCHER:
      return {
        ...state,
        cart: {
          ...state.cart,
          applied_voucher: action.payload,
          voucher_discount: computeVoucherDiscount(
            action.payload,
            compute_subtotal(state.cart.selectedCheckout.items)
          )
        }
      };
    case ProductActionTypes.MARKETPLACE_GET_DELIVERY_LOCATIONS:
      return {
        ...state,
        cart: {
          ...state.cart,
          delivery_locations: action.payload
        }
      };
    case ProductActionTypes.MARKETPLACE_COMPUTE_SHIPPING:
      return {
        ...state,
        cart: {
          ...state.cart,
          deliveryDetails: {
            ...state.cart.deliveryDetails,
            loading: true
          }
        }
      };
    case ProductActionTypes.MARKETPLACE_COMPUTE_SHIPPING_FAILED:
      return {
        ...state,
        cart: {
          ...state.cart,
          deliveryDetails: {
            ...state.cart.deliveryDetails,
            loading: false,
            error: action.payload
          }
        }
      };
    case ProductActionTypes.MARKETPLACE_COMPUTE_SHIPPING_SUCCESS:
      return {
        ...state,
        cart: {
          ...state.cart,
          deliveryDetails: {
            ...state.cart.deliveryDetails,
            loading: false,
            shippingFee: action.payload.shipping_fee,
            delivery_detail: action.payload.delivery_detail
          },
          selectedCheckout: {
            ...state.cart.selectedCheckout,
            subtotal: compute_subtotal(state.cart.selectedCheckout.items),
            amount:
              compute_subtotal(state.cart.selectedCheckout.items) +
              parseFloat(action.payload.shipping_fee)
          }
        }
      };
    // MERCHANT_APPLICATION
    case AuthActionTypes.SET_MERCHANT_APPLICATION_LOGIN_LOADING:
      return {
        ...state,
        merchant_application: {
          ...state.merchant_application,
          authenticate_loading: action.payload
        }
      };
    case AuthActionTypes.SET_MERCHANT_APPLICATION_PREV_ACCOUNT:
      return {
        ...state,
        merchant_application: {
          ...state.merchant_application,
          prev_account: action.payload,
          error: ""
        }
      };
    case AuthActionTypes.AUTHENTICATION_FAILED:
      return {
        ...state,
        merchant_application: {
          ...state.merchant_application,
          error: action.payload
        }
      };
    // MERCHANT
    case ProductActionTypes.SET_MERCHANT_PRODUCT_FILTERS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          products: {
            ...state.merchant.products,
            filters: {
              ...state.merchant.products.filters,
              ...action.payload
            }
          }
        }
      };
    case ProductActionTypes.GET_MERCHANT_PRODUCTS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          products: {
            ...state.merchant.products,
            loading: true
          }
        }
      };
    case ProductActionTypes.GET_MERCHANT_PRODUCTS_SUCCESS:
      let merchant_categories = action.payload.subcategories;
      merchant_categories.sort();
      return {
        ...state,
        merchant: {
          ...state.merchant,
          categories: ["All", ...merchant_categories],
          products: {
            ...state.merchant.products,
            length: action.payload.count,
            products: action.addtlOptions.appendToCurrentData
              ? [...state.merchant.products.products, ...action.payload.results]
              : action.payload.results,
            filters: {
              ...state.merchant.products.filters,
              pageCount: Math.ceil(
                action.payload.count /
                  state.merchant.products.filters.itemsPerPage
              ),
              page: action.page
            },
            loading: false
          }
        }
      };
    case ProductActionTypes.GET_MERCHANT_PRODUCTS_FAILED:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          products: {
            ...state.merchant.products,
            products: [],
            loading: false,
            length: 0,
            filters: {
              ...state.products.filters,
              page: 1,
              pageCount: 0
            }
          }
        }
      };
    case MarketplaceActionTypes.GET_MERCHANT_SUCCESS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          global_category: action.payload[0].global_category,
          ...action.payload[0].merchant,
          location: action.payload[0].location
        }
      };
    case MarketplaceActionTypes.GET_MERCHANTS_SUCCESS:
      return {
        ...state,
        merchants: action.payload
      };
    case MarketplaceActionTypes.SET_DIRECTORY_CATEGORY:
      return {
        ...state,
        enterprise: {
          ...state.enterprise,
          directory: {
            ...state.enterprise.directory,
            selectedCategory: action.payload
          }
        }
      };
    case MarketplaceActionTypes.SHOW_ALERT:
      return {
        ...state,
        error: action.payload
      };
    case MarketplaceActionTypes.HIDE_ALERT:
      return {
        ...state,
        error: { showAlert: false, alertType: "success", message: "" }
      };
    // DASHBOARD
    case DashboardActionTypes.GET_FONTS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          fonts: action.payload
        }
      };
    case DashboardActionTypes.SET_CUSTOMIZE_ERRORS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          customize: {
            ...state.dashboard.customize,
            formErrors: {
              ...state.dashboard.customize.formErrors,
              [action.panel]: action.payload
            }
          }
        }
      };
    case DashboardActionTypes.SET_ERRORS_VISIBLE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [action.form]: {
            ...state.dashboard[action.form],
            errors_visible: action.payload
          }
        }
      };
    case DashboardActionTypes.PATCH_MARKETPLACE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: true
        }
      };
    case DashboardActionTypes.PATCH_MARKETPLACE_SUCCESS:
      const ent = action.payload ? { site_name: action.payload.site_name } : {};
      return {
        ...state,
        enterprise: {
          ...state.enterprise,
          ...ent
        },
        dashboard: {
          ...state.dashboard,
          loading: false
        }
      };
    case DashboardActionTypes.SET_NOTIFICATION_POPUP:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          errorPopup: action.payload
        }
      };

    case DashboardActionTypes.SET_MERCHANT_FILTERS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          merchants_page: {
            ...state.dashboard.merchants_page,
            filters: {
              ...state.dashboard.merchants_page.filters,
              ...action.payload
            },
            filtered_merchants: DashboardUtils.filterMerchants(
              state.dashboard.merchants_page.merchants,
              {
                ...state.dashboard.merchants_page.filters,
                ...action.payload
              }
            )
          }
        }
      };
    case DashboardActionTypes.GET_MERCHANTS_ADMIN_PAGE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          merchants_page: {
            ...state.dashboard.merchants_page,
            loading: true
          }
        }
      };
    case DashboardActionTypes.GET_MERCHANTS_SUCCESS_ADMIN_PAGE:
      filtered_merchants = action.payload.map(item => ({
        ...item,
        merchant_name: item.merchant.name,
        email: item.merchant.email,
        global_category_name: item.global_category.name,
        products_count: item.merchant.products_count
      }));
      requests_count = DashboardUtils.filterMerchants(filtered_merchants, {
        ...state.dashboard.merchants_page.filters,
        tab: 1
      }).length;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          merchants_page: {
            ...state.dashboard.merchants_page,
            merchants: filtered_merchants,
            filtered_merchants: DashboardUtils.filterMerchants(
              filtered_merchants,
              state.dashboard.merchants_page.filters
            ),
            requests_count,
            loading: false
          }
        }
      };
    case DashboardActionTypes.PATCH_MERCHANTS_SUCCESS_ADMIN_PAGE:
      // override existing data by the patch response
      filtered_merchants = state.dashboard.merchants_page.filtered_merchants.map(
        item => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        }
      );
      // do filtering
      filtered_merchants = DashboardUtils.filterMerchants(
        filtered_merchants,
        state.dashboard.merchants_page.filters
      );
      requests_count = DashboardUtils.filterMerchants(filtered_merchants, {
        ...state.dashboard.merchants_page.filters,
        tab: 1
      }).length;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          merchants_page: {
            ...state.dashboard.merchants_page,
            filtered_merchants: filtered_merchants,
            requests_count
          }
        }
      };
    case DashboardActionTypes.PATCH_MERCHANTS_FAILED_ADMIN_PAGE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          merchants_page: {
            ...state.dashboard.merchants_page,
            merchants: action.payload,
            loading: false
          }
        }
      };
    case DashboardActionTypes.DELETE_MERCHANT_ADMIN_PAGE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          merchants_page: {
            ...state.dashboard.merchants_page,
            loading: true
          }
        }
      };
    case DashboardActionTypes.DELETE_MERCHANT_SUCCESS_ADMIN_PAGE:
      filtered_merchants = state.dashboard.merchants_page.filtered_merchants.filter(
        item => item.id !== action.payload
      );
      requests_count = DashboardUtils.filterMerchants(filtered_merchants, {
        ...state.dashboard.merchants_page.filters,
        tab: 1
      }).length;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          merchants_page: {
            ...state.dashboard.merchants_page,
            filtered_merchants: filtered_merchants,
            requests_count,
            loading: false
          }
        }
      };
    case DashboardActionTypes.DELETE_MERCHANT_FAILED_ADMIN_PAGE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          merchants_page: {
            ...state.dashboard.merchants_page,
            merchants: action.payload,
            loading: false
          }
        }
      };
    case ReduxInitializeFormActionTypes.LOAD_MANAGE_MARKETPACE_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          manage_marketplace: {
            ...state.dashboard.manage_marketplace,
            manage_marketplace_initialValues: action.payload
          }
        }
      };
    case DashboardActionTypes.UPLOAD_S3:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          manage_marketplace: {
            ...state.dashboard.manage_marketplace,
            // add the field in s3UploadingFields
            s3UploadingFields: [
              ...state.dashboard.manage_marketplace.s3UploadingFields,
              action.payload
            ]
          }
        }
      };
    case DashboardActionTypes.UPLOAD_S3_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          manage_marketplace: {
            ...state.dashboard.manage_marketplace,
            // remove the field in s3UploadingFields
            s3UploadingFields: state.dashboard.manage_marketplace.s3UploadingFields.filter(
              field => field !== action.payload
            )
          }
        }
      };

    case DashboardActionTypes.SET_MANAGE_MARKETPLACE_CATEGORY_PAGE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          manage_marketplace: {
            ...state.dashboard.manage_marketplace,
            categories: {
              ...state.dashboard.manage_marketplace.categories,
              ...action.payload
            }
          }
        }
      };

    case ReduxInitializeFormActionTypes.LOAD_CATEGORY_FORM_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          manage_marketplace: {
            ...state.dashboard.manage_marketplace,
            category_form_initialValues: action.payload
          }
        }
      };

    case DashboardActionTypes.GLOBAL_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        enterprise: {
          ...state.enterprise,
          categories: state.enterprise.categories.filter(
            item => item.id !== action.payload
          )
        }
      };

    case DashboardActionTypes.GLOBAL_CATEGORY_LOADING:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          manage_marketplace: {
            ...state.dashboard.manage_marketplace,
            categories: {
              ...state.dashboard.manage_marketplace.categories,
              loading: action.payload
            }
          }
        }
      };
    case DashboardActionTypes.GLOBAL_CATEGORY_PATCH_SUCCESS:
      return {
        ...state,
        enterprise: {
          ...state.enterprise,
          categories: state.enterprise.categories.map(cat => {
            return cat.id === action.payload.id ? action.payload : cat;
          })
        },
        dashboard: {
          ...state.dashboard,
          manage_marketplace: {
            ...state.dashboard.manage_marketplace,
            categories: {
              ...state.dashboard.manage_marketplace.categories,
              offset: 0
            }
          }
        }
      };

    case DashboardActionTypes.GLOBAL_CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        enterprise: {
          ...state.enterprise,
          categories: [action.payload, ...state.enterprise.categories]
        },
        dashboard: {
          ...state.dashboard,
          manage_marketplace: {
            ...state.dashboard.manage_marketplace,
            categories: {
              ...state.dashboard.manage_marketplace.categories,
              offset: 0
            }
          }
        }
      };

    case DashboardActionTypes.SET_BUYER_FILTERS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          buyers_page: {
            ...state.dashboard.buyers_page,
            filters: {
              ...state.dashboard.buyers_page.filters,
              ...action.payload
            }
          }
        }
      };

    case DashboardActionTypes.GET_BUYERS_ADMIN_PAGE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          buyers_page: {
            ...state.dashboard.buyers_page,
            loading: true
          }
        }
      };

    case DashboardActionTypes.GET_BUYERS_SUCCESS_ADMIN_PAGE:
      // filtered_buyers = action.payload.map(item => ({
      //   ...item,
      //   merchant_name: item.merchant.name,
      //   email: item.merchant.email,
      //   global_category_name: item.global_category.name,
      //   products_count: item.merchant.products_count
      // }));
      // requests_count = DashboardUtils.filterMerchants(filtered_merchants, {
      //   ...state.dashboard.merchants_page.filters,
      //   tab: 1
      // }).length;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          buyers_page: {
            ...state.dashboard.buyers_page,
            buyers: action.payload.results,
            requests_count: action.payload.count,
            // filtered_merchants: DashboardUtils.filterMerchants(
            //   filtered_merchants,
            //   state.dashboard.merchants_page.filters
            // ),
            // requests_count,
            loading: false
          }
        }
      };
    case DashboardActionTypes.SET_PROFILE_LOADING:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: action.payload
        }
      };
    case ReduxInitializeFormActionTypes.LOAD_PROFILE_FORM_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          profile: {
            ...state.dashboard.profile,
            profile_form_initialValues: action.payload
          }
        }
      };
    case DashboardActionTypes.GET_ANALYTICS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          analytics: action.payload
        }
      };
    // Buyer Dashboard
    case BuyerDashboardActionTypes.BUYER_CART:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          loading: true
        }
      };
    case BuyerDashboardActionTypes.BUYER_CART_SUCCESS:
      itemCart = action.payload;
      cart = [];

      for (let i in itemCart) {
        let items = itemCart[i].items;
        cart.push({
          id: parseInt(i),
          items: itemCart[i].items,
          store_data: itemCart[i].store_data,
          subtotal: compute_subtotal(items),
          registry: itemCart[i].registry,
          numOfItems: parseFloat(
            items.reduce((total, object) => {
              return object.product_data.has_variants
                ? parseFloat(total) +
                    (object.product_data.always_available
                      ? parseFloat(object.quantity)
                      : object.quantity > 0
                      ? parseFloat(object.quantity)
                      : 0)
                : parseFloat(total) +
                    (object.product_data.always_available
                      ? parseFloat(object.quantity)
                      : object.product_data.quantity > 0
                      ? parseFloat(object.quantity)
                      : 0);
            }, 0)
          )
        });
      }
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          cart_items: cart,
          loading: false
        }
      };
    case BuyerDashboardActionTypes.POPUP_ALERT:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          showPopup: action.payload,
          loading: false
        }
      };
    case BuyerDashboardActionTypes.GET_VIEW_ORDER:
      selected_view_order_items = [];
      for (let i in action.payload.items) {
        selected_view_order_items.push({
          ...action.payload.items[i],
          id: parseInt(i)
        });
      }
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          loading: false,
          selectedViewOrder: !selected_view_order_items.length
            ? ""
            : {
                store_data: action.payload.store_data,
                items: selected_view_order_items,
                id: action.payload.id
              }
        }
      };
    case BuyerDashboardActionTypes.SET_SELECTED_CHECKOUT:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          selectedCheckout: {
            items: action.payload,
            subtotal: compute_subtotal(action.payload),
            amount:
              compute_subtotal(action.payload) +
              parseFloat(state.buyer_dashboard.deliveryDetails.shippingFee)
          }
        }
      };
    case BuyerDashboardActionTypes.SET_CUSTOMER_DETAILS:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          customerDetails: action.payload
        }
      };
    case BuyerDashboardActionTypes.GET_PAYMENTS:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          payments: action.payload,
          loading: false
        }
      };
    case BuyerDashboardActionTypes.POST_STORE_CHECKOUT:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          checkout: action.payload,
          loading: false
        }
      };
    case BuyerDashboardActionTypes.SET_PURCHASED_TAB:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          purchased_tab: action.payload
        }
      };
    case BuyerDashboardActionTypes.GET_PURCHASED_ORDERS:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          purchased_orders: action.payload,
          loading: false
        }
      };
    case BuyerDashboardActionTypes.GET_PURCHASED_VIEW_ORDER:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          selectedPurchasedViewOrder: action.payload,
          loading: false
        }
      };
    case BuyerDashboardActionTypes.REMOVE_ITEM:
      newList = [...state.buyer_dashboard.selectedViewOrder.items];
      newList.splice(action.payload, 1);
      cart_amount = computeBuyerCartAmount(newList);
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          selectedViewOrder: {
            ...state.buyer_dashboard.selectedViewOrder,
            items: newList
          },
          cart_amount,
          voucher_discount: computeVoucherDiscount(
            state.buyer_dashboard.applied_voucher,
            cart_amount
          )
        }
      };
    case BuyerDashboardActionTypes.UPDATE_ITEM:
      buyerItemToUpdate = action.payload.params;
      productSelection = buyerItemToUpdate.productSelection;
      buyerItemList = [...action.payload.selected_order_items];
      selected_checkout_items = [
        ...state.buyer_dashboard.selectedCheckout.items
      ];
      new_items =
        buyerItemToUpdate && buyerItemToUpdate.registry
          ? update_quantity_wishlist(
              buyerItemToUpdate,
              productSelection,
              buyerItemList
            )
          : update_quantity(buyerItemToUpdate, productSelection, buyerItemList);

      for (let i in new_items) {
        selected_order_items.push({
          product_data: { ...new_items[i], quantity: new_items[i].stock },
          productSelection: new_items[i].productSelection,
          registry: new_items[i].registry ? new_items[i].registry : "",
          quantity: new_items[i].quantity,
          variant: new_items[i].variants,
          uuid: new_items[i].uuid,
          id: new_items[i].id
        });
      }

      if (selected_checkout_items.length) {
        for (let product of selected_checkout_items) {
          if (product["id"] === buyerItemToUpdate["id"]) {
            product.quantity = buyerItemToUpdate.quantity;
          }
        }
      }
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          selectedViewOrder: {
            ...state.buyer_dashboard.selectedViewOrder,
            items: selected_order_items
          },
          selectedCheckout: {
            items: selected_checkout_items,
            subtotal: compute_subtotal(selected_checkout_items),
            amount:
              compute_subtotal(selected_checkout_items) +
              parseFloat(state.buyer_dashboard.deliveryDetails.shippingFee)
          },
          voucher_discount: computeVoucherDiscount(
            state.buyer_dashboard.applied_voucher,
            state.buyer_dashboard.selectedCheckout.amount
          )
        }
      };
    case BuyerDashboardActionTypes.BUYER_UPDATE_VARIANT:
      buyerItemToUpdate = action.payload.productToUpdate;
      productSelection = buyerItemToUpdate.productSelection;
      buyerItemList = [...action.payload.selected_order_items];
      selected_checkout_items = [
        ...state.buyer_dashboard.selectedCheckout.items
      ];
      new_items = combine_same_products(
        buyerItemToUpdate,
        productSelection,
        buyerItemList
      );
      registry_items = new_items.filter(i => i.registry);
      personal_items = new_items.filter(i => !i.registry);
      for (let product of personal_items) {
        if (product["uuid"] === buyerItemToUpdate["uuid"]) {
          if (buyerItemToUpdate.has_variants && productSelection) {
            if (buyerItemToUpdate.prevSelection) {
              if (
                product.productSelection.id ===
                buyerItemToUpdate.prevSelection.id
              ) {
                itemAlreadyListed = true;
                product.variant = buyerItemToUpdate.variant;
                product.productSelection = buyerItemToUpdate.productSelection;
              }
            } else {
              itemAlreadyListed = true;
              product.variant = buyerItemToUpdate.variant;
              product.productSelection = buyerItemToUpdate.productSelection;
            }
          }
        }
      }
      for (let i in personal_items) {
        selected_order_items.push({
          product_data: { ...new_items[i] },
          productSelection: new_items[i].productSelection,
          quantity: new_items[i].quantity,
          variant: new_items[i].variants,
          uuid: new_items[i].uuid,
          id: new_items[i].id
        });
      }
      if (selected_checkout_items.length) {
        for (let product of selected_checkout_items) {
          if (product["id"] === buyerItemToUpdate["id"]) {
            product.variant = buyerItemToUpdate.variants;
            product.productSelection = buyerItemToUpdate.productSelection;
          }
        }
      }
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          selectedViewOrder: {
            ...state.buyer_dashboard.selectedViewOrder,
            items: selected_order_items
          },
          selectedCheckout: {
            items: selected_checkout_items,
            subtotal: compute_subtotal(selected_checkout_items),
            amount:
              compute_subtotal(selected_checkout_items) +
              parseFloat(state.buyer_dashboard.deliveryDetails.shippingFee)
          },
          voucher_discount: computeVoucherDiscount(
            state.buyer_dashboard.applied_voucher,
            state.buyer_dashboard.selectedCheckout.amount
          )
        }
      };
    case BuyerDashboardActionTypes.SET_DELIVERY_DETAILS:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          deliveryDetails: action.payload,
          selectedCheckout: {
            ...state.buyer_dashboard.selectedCheckout,
            subtotal: compute_subtotal(
              state.buyer_dashboard.selectedCheckout.items
            ),
            amount:
              compute_subtotal(state.buyer_dashboard.selectedCheckout.items) +
              parseFloat(action.payload.shippingFee)
          }
        }
      };
    case BuyerDashboardActionTypes.GET_VOUCHERS:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          voucherList: action.payload.sort((a, b) => (b.is_applicable ? 1 : -1))
        }
      };
    case BuyerDashboardActionTypes.APPLY_VOUCHER:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          applied_voucher: action.payload,
          voucher_discount: computeVoucherDiscount(
            action.payload,
            compute_subtotal(state.buyer_dashboard.selectedCheckout.items)
          )
        }
      };
    case BuyerDashboardActionTypes.CANCEL_ORDER:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          selectedPurchasedViewOrder: {
            ...action.payload,
            status: "Cancelled"
          },
          purchased_tab: "Cancelled"
        }
      };
    case BuyerDashboardActionTypes.REMOVE_FROM_LIST:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          purchased_orders: state.buyer_dashboard.purchased_orders.results.filter(
            order => action.payload !== order.id
          )
        }
      };
    case BuyerDashboardActionTypes.SET_PURCHASED_FILTERS:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          purchased_filter: {
            ...state.buyer_dashboard.purchased_filter,
            ...action.payload
          }
        }
      };

    case BuyerDashboardActionTypes.GET_DELIVERY_LOCATIONS:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          delivery_locations: action.payload
        }
      };
    case BuyerDashboardActionTypes.COMPUTE_SHIPPING:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          deliveryDetails: {
            ...state.buyer_dashboard.deliveryDetails,
            loading: true
          }
        }
      };
    case BuyerDashboardActionTypes.COMPUTE_SHIPPING_FAILED:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          deliveryDetails: {
            ...state.buyer_dashboard.deliveryDetails,
            loading: false,
            error: action.payload
          }
        }
      };
    case BuyerDashboardActionTypes.COMPUTE_SHIPPING_SUCCESS:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          deliveryDetails: {
            ...state.buyer_dashboard.deliveryDetails,
            loading: false,
            shippingFee: action.payload.shipping_fee,
            delivery_detail: action.payload.delivery_detail
          },
          selectedCheckout: {
            ...state.buyer_dashboard.selectedCheckout,
            subtotal: compute_subtotal(
              state.buyer_dashboard.selectedCheckout.items
            ),
            amount:
              compute_subtotal(state.buyer_dashboard.selectedCheckout.items) +
              parseFloat(action.payload.shipping_fee)
          }
        }
      };
    // Change Password
    case MarketplaceActionTypes.CHANGE_PASSWORD_INITIAL_REDUCERS:
      return {
        ...state,
        change_password: {
          loading: false,
          status: "",
          verify: ""
        }
      };
    case MarketplaceActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        change_password: {
          ...state.change_password,
          loading: true
        }
      };
    case MarketplaceActionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        change_password: {
          ...state.change_password,
          loading: false,
          status: action.payload
        }
      };
    case MarketplaceActionTypes.VERIFY_TOKEN:
      return {
        ...state,
        change_password: {
          ...state.change_password,
          loading: false,
          verify: action.payload
        }
      };

    default:
      return state;
  }
}
