import React from "react";
import SnackbarAlert from "../../SnackbarAlert";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";
import MaterialUIAlert from "../../material-ui-alert";
import StoreActions from "./StoreActions";

export default function AddToCartLimit(props) {
  const { total, kycLevel } = props;
  const store = useSelector((state) => state.store.store);

  const buxAccountLimit = () => {
    if (kycLevel === 0) {
      return "₱ 100,000.00";
    } else if (kycLevel === 1) {
      return "₱ 500,000.00";
    } else {
      return "";
    }
  };

  return (
    <>
      {props.isCartDrawer ? (
        <MaterialUIAlert
          type={"warning"}
          message={`Your cart exceeds ${buxAccountLimit()} check-out limit. Please decrease item quantity to continue.`}
          style={{
            border: "1px solid #E89937",
            color: "#E3632A",
            backgroundColor: "#FCF7E1",
          }}
        />
      ) : (
        <Snackbar
          open={true}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ top: 99 }}
        >
          <MaterialUIAlert
            type={"warning"}
            message={`Your cart exceeds ${buxAccountLimit()} check-out limit. Please decrease item quantity to continue.`}
            style={{
              border: "1px solid #E89937",
              color: "#E3632A",
              backgroundColor: "#FCF7E1",
            }}
          />
        </Snackbar>
      )}
    </>
  );
}
