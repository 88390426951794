import React from "react";
import Iframe from "react-iframe";
import { Dialog, makeStyles, IconButton, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const ViewAnnouncement = props => {
  const { handleSelected, handleClose, selected } = props;
  const useStyles = makeStyles(theme => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      height: "100%"
    },
    title: {
      color: "#fff",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14
      }
    },
    titleSection: {
      backgroundColor: "#002c42",
      position: "fixed",
      width: 960,
      [theme.breakpoints.down("sm")]: {
        width: 703
      },
      [theme.breakpoints.down("xs")]: {
        width: "83vw"
      }
    },
    iframe: {
      width: "100%",
      height: "100%"
    },
    closeBtn: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1)
    }
  }));
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={handleSelected}
      style={{ overflowY: "hidden" }}
    >
      <DialogTitle className={classes.titleSection} onClose={handleClose}>
        <div className={classes.title}>
          {selected.title}
          <IconButton onClick={() => handleClose()}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
      </DialogTitle>
      <div style={{ height: "88vh", paddingTop: 78 }}>
        <div className={classes.form}>
          <Iframe className={classes.iframe} src={selected.src} />
        </div>
      </div>
    </Dialog>
  );
};

export default ViewAnnouncement;
