import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Popover,
  MenuItem,
} from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { confirmLogOut } from "../../../auth/authActions";
import history from "../../../../store/history";
import Path from "../../../../Paths";
import sentro_logo from "../../../../assets/images/sentro_logo2.png";
export default function SellerAppbar(props) {
  const useStyles = makeStyles((theme) => ({
    appbar: {
      zIndex: 799,
      backgroundColor: "#eee",
      boxShadow: "none",
    },
    toolbar: {
      padding: "0 4vw",
      display: "flex",
      alignItems: "center",
    },
    label: {
      fontSize: 14,
      color: "#54575f",
      textTransform: "capitalize",
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const stores = useSelector((state) => state.webBuilder.stores);
  const first_name = user ? user.first_name : "";
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopover = Boolean(anchorEl);

  const popoverItems = [
    {
      label: "Profile",
      onClick: () => history.push(Path.ACCOUNT_SETTINGS_BASIC_INFORMATION),
    },
    {
      label: "Help Center",
      onClick: () => window.open("https://faq.sentro.ph/hc/en-us"),
    },
    { label: "Logout", onClick: () => dispatch(confirmLogOut()) },
  ];
  return (
    <div>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar
          className={`${classes.toolbar} ${
            stores && stores.length > 0
              ? "justify-content-end"
              : "justify-content-between"
            }`}
        >
          {stores && stores.length > 0 ? null : (
            <img src={sentro_logo} alt="" width="auto" height="25" />
          )}
          <div className="d-flex align-items-center">
            <strong className={classes.label}>Hello, {first_name}!</strong>
            <div className="d-flex align-items-center ml-2">
              <Button
                endIcon={<KeyboardArrowDownIcon style={{ color: "#002c42" }} />}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <AccountCircleOutlinedIcon style={{ color: "#002c42" }} />
              </Button>
              <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                {popoverItems.map((item) => (
                  <MenuItem
                    button
                    onClick={() => {
                      item.onClick();
                      setAnchorEl(null);
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Popover>
              {/* <div className="px-2" style={{ color: "#a7a7a7" }}>
              |
            </div>
            <IconButton>
              <NotificationsNoneIcon style={{ color: "#002c42" }} />
            </IconButton> */}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
