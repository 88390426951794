import { ActionTypes } from "./Actions";
const initialState = {
  apps: [],
  app: "",
  activeHeaderTab: 0,
  cart: [],
  checkout_details: "",
  kyc: "",
  loading: false,
  modalLoading: false,
  error: null,
  visible_header_footer: true
};

export default function marketplaceReducers(state = initialState, action) {
  switch (action.type) {
    // App list
    case ActionTypes.FETCHING_APPS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCHING_APPS_SUCCESS:
      return {
        ...state,
        loading: false,
        apps: action.payload,
        error: null
      };
    // App detailed
    case ActionTypes.FETCHING_APP:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCHING_APP_SUCCESS:
      return {
        ...state,
        app: action.payload,
        loading: false,
        error: null
      };
    // Set header tab
    case ActionTypes.SET_HEADER_TAB:
      return {
        ...state,
        activeHeaderTab: action.payload
      };
    // Category list
    case ActionTypes.FETCHING_CATEGORIES:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCHING_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        error: null
      };
    // Set item to cart
    case ActionTypes.SET_TO_CART:
      return {
        ...state,
        cart: action.payload
      };

    // Check KYC
    case ActionTypes.CHECK_KYC:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.CHECK_KYC_SUCCESS:
      return {
        ...state,
        loading: false,
        kyc: action.payload
      };

    // Marketplace checkout
    case ActionTypes.MARKETPLACE_CHECKOUT:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.MARKETPLACE_CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false
      };

    // Marketplace checkout details
    case ActionTypes.MARKETPLACE_CHECKOUT_DETAILS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.MARKETPLACE_CHECKOUT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        checkout_details: action.payload
      };

    // setting request status
    case ActionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case ActionTypes.REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        modalLoading: false,
        error: action.payload
      };
    case ActionTypes.SET_HEADER_FOOTER_VISIBLE:
      return {
        ...state,
        visible_header_footer: action.payload
      };
    default:
      return initialState;
  }
}
