import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    "& p": {
      textAlign: "right"
    }
  },
  label3: {
    fontSize: 12,
    fontWeight: 500,
    color: "rgba(0,0,0,0.8)",
    margin: 0
  }
}));

const ReduxSelectField = ({
  input,
  label,
  maxLength,
  minLength,
  multiline,
  cut,
  noHelpText,
  disabled,
  meta: { asyncValidating, touched, error },
  ...custom
}) => {
  const classes = useStyles();
  return (
    <div className={custom.className}>
      <div className={classes.label3}>{label}</div>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        {...input}
        {...custom}
        disabled={disabled}
        classes={{ root: "text-left" }}
        margin="dense"
        disabled={disabled}
        error={Boolean(touched && error)}
      >
        {custom.choices
          .filter(i => i.value && i.display)
          .map(i => (
            <MenuItem value={i.value}>{i.display}</MenuItem>
          ))}
      </Select>
      <p className="text-danger mb-0 text-right" style={{ fontSize: 12 }}>
        {touched && error ? error : ""}
      </p>
    </div>
  );
};
export default ReduxSelectField;
