import React, { useEffect, useState } from 'react';

import '../style/custom.css';

const LevelFilter = (props) => {
  const [levelList, setLevelList] = useState([
    {
      id: 1,
      label: 'Level 1 (KASAPI)',
    },
    {
      id: 2,
      label: 'Level 2 (LAKAN)',
    },
    {
      id: 3,
      label: 'Level 3 (KAMPEON)',
    },
    {
      id: 4,
      label: 'Level 4 (BAYANI)',
    },
    {
      id: 5,
      label: 'Level 5 (ALAMAT)',
    },
  ]);
  const [activeLevel, setActiveLevel] = useState();

  const onClickLevelButton = (item) => {
    props.onClickFilterLevelNFT(item.id);
  };

  useEffect(() => {
    setActiveLevel(props.activeLevel);
  }, [props.activeLevel]);

  return (
    <>
      <h2 className="text-center">LEVEL</h2>
      <div className="option-box-container">
        <div className="option-box">
          {levelList && levelList.length > 0
            ? levelList.map((item, index) => {
                const className = `${
                  item.id === activeLevel
                    ? 'nft-button-1'
                    : 'nft-button-cut-corner'
                }`;
                return (
                  <button
                    className={className}
                    onClick={() => {
                      onClickLevelButton(item);
                    }}
                    key={index}
                  >
                    {item.label}
                  </button>
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
};

export default LevelFilter;
