import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Checkbox,
  Button,
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
  TableBody,
  useMediaQuery
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StorefrontIcon from "@material-ui/icons/Storefront";
import sampleImg from "../../../../assets/images/imgPlaceholder2.svg";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import tags from "../../../../assets/images/tags.svg";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { WishlistItemPriceDisplay } from "../../../common/template/components/PriceDisplay";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import BuyerActions from "../redux/actions";
import Utils from "../../../common/utils";
import history from "../../../../store/history";
import VoucherModal from "../common/voucherModal";
import StoreActions from "../../../common/template/components/StoreActions";
import {
  checkProductAvailability,
  disableCheckbox
} from "../common/checkProductAvailability";
import apiConfig from "../../../../common/apiConfig";
import VariantPicker from "../../../enterprise/components/cart/variantPicker";
const DialogTransition = props => <Slide direction="up" {...props} />;

const CartViewOrderModal = props => {
  const enterpriseTheme = useSelector(state => state.enterprise.main.theme);
  const useStyles = makeStyles(theme => ({
    appbar: {
      position: "fixed",
      backgroundColor: "#fff",
      boxShadow: "none",
      borderBottom: "1px solid #d1d5dd"
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    cartHeaderSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    labelTitle: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149"
    },
    productImage: {
      width: 40,
      height: 40,
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    labelbold: {
      fontSize: 14,
      fontWeight: 600,
      color: "#2b2d32"
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      color: "#2b2d32"
    },
    btnQuantity: {
      borderRadius: 0,
      width: 25,
      height: 25,
      minWidth: 25
    },
    summary: {
      padding: 18,
      borderRadius: "8px 8px 0px 0px",
      bottom: 0,
      left: 0,
      right: 0,
      borderTop: "1px solid #d1d5dd"
    },
    voucherLink: {
      color: "#54575f",
      fontSize: 14,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: 500
    },
    columnHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "0 0 2vh"
    },
    column: {
      borderBottom: "none",
      fontSize: 12,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0"
    },
    columnFooter: {
      borderTop: "1px dashed #d1d5dd",
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0 0"
    },
    variantChip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    },
    replaceVariantHeader: {
      padding: 12,
      fontSize: 14,
      fontWeight: 600,
      borderBottom: "1px solid #d1d5dd",
      color:
        enterpriseTheme.palette.secondary.main !== "#18B4A1"
          ? enterpriseTheme.palette.secondary.main
          : enterpriseTheme.palette.secondary.defaultHoverColor
    },
    btnSelectVariant: {
      borderRadius: 0,
      border: "1px solid red",
      color: "red",
      fontSize: 10,
      fontWeight: "normal",
      height: 25,
      padding: 8
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const selected_order = useSelector(
    state => state.buyerDashboard.selected_order
  );
  const product_selected = useSelector(
    state => state.buyerDashboard.selected_checkout.items
  );
  const amount = useSelector(
    state => state.buyerDashboard.selected_checkout.amount
  );
  const subtotal = useSelector(
    state => state.buyerDashboard.selected_checkout.subtotal
  );
  const vouchers = useSelector(state => state.buyerDashboard.voucher_list);
  const voucher_discount = useSelector(
    state => state.buyerDashboard.voucher_discount
  );
  const cart_items = useSelector(
    state => state.buyerDashboard.cart_items?.cart
  );
  const cart = useSelector(state => state.buyerDashboard.selected_order?.items);
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasError, setHasError] = React.useState(false);
  const all_cart_products = cart ? [...cart] : [];

  function productImage(item) {
    if (item.gallery) {
      if (item.gallery.length) {
        return item.gallery[0].image;
      } else {
        return sampleImg;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return sampleImg;
      }
    }
  }

  function handleProductSelected(item) {
    if (product_selected.map(item => item.id).includes(item.id)) {
      const index = product_selected.map(item => item.id).indexOf(item.id);
      const newSelected = [...product_selected];
      newSelected.splice(index, 1);
      dispatch(BuyerActions.setSelectedCheckout(newSelected));
    } else {
      dispatch(BuyerActions.setSelectedCheckout([...product_selected, item]));
    }
  }
  function removeFromCart(index) {
    dispatch(BuyerActions.removeFromCart(index));
  }

  function decrement(item) {
    let selected_order_items = [];
    for (let i in cart) {
      selected_order_items.push({
        ...cart[i].product_details,
        quantity: cart[i].quantity,
        stock: cart[i].product_details.quantity,
        variants: cart[i].variant,
        productSelection: cart[i].productSelection,
        id: cart[i].id
      });
    }
    let params = {
      ...item.product_details,
      productSelection: item.productSelection,
      variants: item.variant,
      stock: item.product_details.quantity,
      quantity: item.quantity - 1,
      id: item.id
    };
    dispatch(BuyerActions.updateQuantity(params, selected_order_items));
  }

  function increment(item) {
    let selected_order_items = [];
    for (let i in cart) {
      selected_order_items.push({
        ...cart[i].product_details,
        quantity: cart[i].quantity,
        stock: cart[i].product_details.quantity,
        variants: cart[i].variant,
        productSelection: cart[i].productSelection,
        id: cart[i].id
      });
    }
    let params = {
      ...item.product_details,
      productSelection: item.productSelection,
      variants: item.variant,
      stock: item.product_details.quantity,
      quantity: item.quantity + 1,
      id: item.id
    };
    dispatch(BuyerActions.updateQuantity(params, selected_order_items));
  }

  function getProduct(product) {
    let params = {
      ...product.product_details,
      quantity: product.quantity,
      stock: product.product_details.quantity,
      variants: product.variant,
      productSelection: product.productSelection,
      id: product.id
    };

    return params;
  }

  function checkStock(product) {
    let product_details = product.product_details;
    if (product_details.has_variants) {
      if (product.productSelection) {
        if (product.quantity >= product.productSelection.quantity) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (product_details.always_available) {
        return false;
      } else {
        if (product.quantity >= product_details.quantity) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  function isEqual(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  function handleConfirmVariant(product) {
    let selected_order_items = [];
    let productSelectionIds = product.productSelection.variants.map(i => i.id);
    let prevSelectionIds = product.prevSelection
      ? product.prevSelection.variants.map(i => i.id)
      : [];
    for (let i in cart) {
      selected_order_items.push({
        ...cart[i].product_details,
        quantity: cart[i].quantity,
        stock: cart[i].product_details.quantity,
        variants: cart[i].variant,
        productSelection: cart[i].productSelection,
        id: cart[i].id
      });
    }
    if (isEqual(productSelectionIds, prevSelectionIds)) {
      setAnchorEl(null);
    } else {
      dispatch(BuyerActions.updateItemVariant(product, selected_order_items));
      setAnchorEl(null);
    }
  }

  let products =
    cart_items &&
    cart_items
      .filter(item => item.store_data.uuid !== selected_order.store_data.uuid)
      .map(item => item.items);

  React.useEffect(() => {
    if (products) {
      for (let i in products) {
        for (let e in products[i]) {
          all_cart_products.push(products[i][e]);
        }
      }
    }
  }, [products]);

  React.useEffect(() => {
    let params = [];
    if (all_cart_products) {
      for (let i in all_cart_products) {
        params.push({
          uuid: all_cart_products[i].uuid,
          quantity: all_cart_products[i].quantity,
          variant: all_cart_products[i].variant,
          productSelection: all_cart_products[i].productSelection
        });
      }
    }
    if (selected_order) {
      dispatch(
        StoreActions.patchBuyerCart(
          params && params.length === 0 ? [{}] : params
        )
      );
    }
  }, [cart]);

  React.useEffect(() => {
    errors.length = 0;
    let items = product_selected;
    for (let i in items) {
      if (items[i].product_details.has_variants && items[i].productSelection) {
        if (items[i].productSelection.quantity <= 0) {
          errors.push(true);
        } else if (items[i].quantity > items[i].productSelection.quantity) {
          errors.push(true);
        } else {
          errors.push(false);
        }
      } else {
        if (items[i].always_available) {
          errors.push(false);
        } else if (items[i].product_details.stock <= 0) {
          errors.push(true);
        } else if (items[i].quantity > items[i].product_details.stock) {
          errors.push(true);
        }
      }
    }

    if (errors.includes(true)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [product_selected]);

  return (
    <div>
      <Dialog
        fullScreen
        open={isMobile && selected_order}
        TransitionComponent={DialogTransition}
      >
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: "absolute" }}
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIcon style={{ color: "#1e4b83" }} />
            </IconButton>
            <div className={classes.header}>View orders</div>
          </Toolbar>
        </AppBar>
        <div
          style={{
            padding: 18,
            marginTop: "8vh",
            height: "52vh",
            overflowY: "scroll"
          }}
        >
          <div className={classes.cartHeaderSection}>
            <div
              style={{
                display: "flex",
                alignItems: isMobile ? "start" : "center",
                flexDirection: isMobile ? "column" : "inherit"
              }}
            >
              <div className={classes.labelTitle}>
                {selected_order ? selected_order.store_data.name : ""}
              </div>
            </div>
            <IconButton
              onClick={() =>
                window.open(
                  `${apiConfig.localHost}shop/${
                    selected_order ? selected_order.store_data.site : ""
                  }`
                )
              }
            >
              <StorefrontIcon color="primary" />
            </IconButton>
          </div>
          <div style={{ marginTop: "3vh" }}>
            <div style={{ fontSize: 14, fontWeight: 600 }}>
              Items:{" "}
              {cart.length
                ? parseFloat(
                    cart.reduce((total, object) => {
                      return parseFloat(total) + parseFloat(object.quantity);
                    }, 0)
                  )
                : ""}
            </div>
          </div>
          <div style={{ marginTop: "5vh" }}>
            {cart && cart.length
              ? cart.map((item, key) => {
                  return (
                    <div
                      style={{ borderBottom: "1px dashed #d1d5dd" }}
                      className="col-12 py-3 px-0 d-flex"
                    >
                      <div className="col-2 p-0">
                        <Checkbox
                          disabled={
                            disableCheckbox(item) ||
                            (item.product_details.has_variants &&
                              !item.productSelection)
                          }
                          checked={product_selected
                            .map(item => item.id)
                            .includes(item.id)}
                          onChange={e => handleProductSelected(item)}
                          color="primary"
                        />
                      </div>
                      <div className="col-2 p-0">
                        <div
                          className={classes.productImage}
                          style={{
                            backgroundImage: `url(${productImage(
                              item.product_details
                            )})`
                          }}
                        />
                      </div>
                      <div className="col-8 p-0">
                        <div className="d-flex justify-content-between">
                          <div className={classes.labelbold}>
                            {item.product_details.name}
                          </div>
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => removeFromCart(key)}
                          >
                            <DeleteOutlineOutlinedIcon color="primary" />
                          </IconButton>
                        </div>
                        <VariantPicker
                          index={key}
                          product={getProduct(item)}
                          productSelection={item.productSelection}
                          handleConfirmVariant={handleConfirmVariant}
                        />
                        <div
                          style={{ marginTop: "3vh" }}
                          className="d-flex justify-content-between"
                        >
                          <div className="col-6 p-0 d-flex justify-content-between">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                              className={classes.label}
                            >
                              <WishlistItemPriceDisplay
                                symbol="₱"
                                item={getProduct(item)}
                                productSelection={item.productSelection}
                              />
                            </div>
                            <div className={classes.label}>x</div>
                            <div className={classes.label}>{item.quantity}</div>
                          </div>
                          <div className="d-flex">
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.btnQuantity}
                              style={{ borderRight: "none" }}
                              disabled={item.quantity === 1}
                              onClick={() => decrement(item)}
                            >
                              <RemoveIcon style={{ fontSize: 12 }} />
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.btnQuantity}
                              disabled={checkStock(item)}
                              onClick={() => increment(item)}
                            >
                              <AddIcon style={{ fontSize: 12 }} />
                            </Button>
                          </div>
                        </div>
                        {checkProductAvailability(item)}
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
          <div className="d-block pt-4">
            <div
              style={{
                fontSize: 14,
                fontWeight: 600,
                color: "rgba(43,45,50, 0.8)"
              }}
            >
              Contact:
            </div>
            {selected_order && selected_order.store_data.email ? (
              <div className="d-flex align-items-center mt-3">
                <MailOutlineIcon style={{ color: "rgba(43,45,50,0.8" }} />
                <div
                  className="ml-2"
                  style={{
                    fontSize: 12,
                    color: "rgba(43,45,50, 0.8)"
                  }}
                >
                  {selected_order && selected_order.store_data.email}
                </div>
              </div>
            ) : (
              ""
            )}
            {selected_order && selected_order.store_data.mobile ? (
              <div className="d-flex align-items-center mt-3">
                <PhoneIphoneIcon style={{ color: "rgba(43,45,50,0.8" }} />
                <div
                  className="ml-2"
                  style={{
                    fontSize: 12,
                    color: "rgba(43,45,50, 0.8)"
                  }}
                >
                  {selected_order && selected_order.store_data.mobile}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={classes.summary}>
          <Table style={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.columnHeader}>
                  Summary
                </TableCell>
                <TableCell align="right" className={classes.columnHeader}>
                  <Link
                    className={classes.voucherLink}
                    onClick={() => setOpenVoucher(true)}
                    style={{
                      display:
                        product_selected.length && vouchers.length ? "" : "none"
                    }}
                  >
                    <img
                      src={tags}
                      width="15"
                      height="15"
                      style={{ marginRight: 5 }}
                    />
                    Vouchers
                  </Link>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.column} align="left">
                  Items selected
                </TableCell>
                <TableCell className={classes.column} align="right">
                  {parseFloat(product_selected.length)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.column} align="left">
                  Subtotal
                </TableCell>
                <TableCell className={classes.column} align="right">
                  ₱ {Utils.commafy(subtotal)}
                </TableCell>
              </TableRow>
              {voucher_discount ? (
                <TableRow>
                  <TableCell className={classes.column} align="left">
                    Voucher Applied
                  </TableCell>
                  <TableCell className={classes.column} align="right">
                    {`- ₱${Utils.commafy(voucher_discount)}`}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}

              <TableRow>
                <TableCell className={classes.columnFooter} align="left">
                  Total
                </TableCell>
                <TableCell className={classes.columnFooter} align="right">
                  ₱ {Utils.commafy(amount - voucher_discount)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            style={{ marginTop: "3vh" }}
            disabled={!product_selected.length || hasError}
            onClick={() => history.push(`/my_cart/details`)}
          >
            Proceed to checkout
          </Button>
        </div>
        <VoucherModal
          open={openVoucher}
          setOpen={setOpenVoucher}
          cart={product_selected}
        />
      </Dialog>
    </div>
  );
};

export default CartViewOrderModal;
