import React from "react";
import { Box, InputAdornment, Typography } from "@material-ui/core";
import { composeAsyncValidator } from "redux-form-async-validator";
import { Divider } from "antd";
import authStyles from "../../auth/authStyles";
import FacebookLogin from "react-facebook-login";
import { apiConfig } from "../../../common/apiConfig";
import { register, fb_sign_up } from "../registerActions";
import { useDispatch, useSelector } from "react-redux";
import { handleFbError } from "../../auth/authActions";
import FacebookIcon from "@material-ui/icons/Facebook";
import SentroButton from "../../common/sentroButton";
import Text from "../../../common/text";
import Path from "../../../Paths";
import { Field, reduxForm } from "redux-form";
import SentroTextField from "../../common/sentroTextField";
import SentroCheckBox from "../../common/sentroCheckBox";
import validations from "../../common/validations";
import SocialButton from "../../social/socialButton";
import { uniqueEmail } from "../registerServices";
import { FacebookProvider, LoginButton } from "react-facebook";
let RegisterForm = props => {
  const dispatch = useDispatch();
  console.log(props.utms);
  const classes = authStyles();
  const [showPassword, setShowPassword] = React.useState("");
  const [FbErrorMsg, setFbError] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  let registerFormValues = useSelector(state =>
    state.form.registerForm ? state.form.registerForm.values : null
  );
  let utms = props.utms ? props.utms : null;

  let error = useSelector(state =>
    state.form.registerForm ? state.form.registerForm.syncErrors : true
  );
  let asyncError = useSelector(state =>
    state.form.registerForm ? state.form.registerForm.asyncErrors : true
  );

  function FbError(value) {
    dispatch(handleFbError(value));
  }

  function fbSubmitCreds(token) {
    dispatch(fb_sign_up(token._token));
  }

  const handleFBSocialLogin = user => {
    fbSubmitCreds(user);
  };

  const handleSocialLogin = user => {
    fbSubmitCreds(user);
  };

  const handleSocialLoginFailure = err => {
    console.error(err);
  };

  function submitCreds() {
    let params = {
      firstname: registerFormValues.firstName,
      lastname: registerFormValues.lastName,
      email: registerFormValues.email,
      phoneNumber: registerFormValues.phoneNumber,
      password: registerFormValues.password,
      utm_source: utms.utm_source,
      utm_medium: utms.utm_medium,
      utm_campaign: utms.utm_campaign,
      utm_content: utms.utm_content
    };

    dispatch(register(params));
  }

  function signUpViaEnter(e) {
    if (e.key !== "Enter") {
      return;
    }

    const cannotSubmit =
      (typeof error === "undefined" ? false : error) ||
      (typeof asyncError === "undefined" ? false : asyncError) ||
      !checked;
    if (cannotSubmit) {
      return;
    }

    submitCreds();
  }

  const signUpButtonRef = React.createRef();
  React.useEffect(() => {
    if (checked) {
      signUpButtonRef.current.focus();
    }
  }, [checked]);
  return (
    <Box>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <SocialButton
          provider="facebook"
          appId={apiConfig.fbID}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
          className={classes.btnFacebook}
          startIcon={<FacebookIcon />}
          size="large"
        >
          Sign up with Facebook
        </SocialButton>
      </Box>
      <Box>
        <Divider style={{ fontSize: 12, color: "#333333" }}>or</Divider>
      </Box>
      <Box>
        <Text
          style={{ fontWeight: 600, color: "#2B2D33" }}
          textType="cap"
          content="First Name"
        />
        <Field
          id="first_name"
          maxLength={99}
          cut
          noHelpText
          name="firstName"
          validate={[
            validations.required,
            validations.whiteSpace,
            validations.name
          ]}
          component={SentroTextField}
        />
      </Box>

      <Box>
        <Text
          style={{ fontWeight: 600, color: "#2B2D33" }}
          textType="cap"
          content="Last Name"
        />
        <Field
          id="last_name"
          maxLength={99}
          cut
          noHelpText
          name="lastName"
          validate={[
            validations.required,
            validations.whiteSpace,
            validations.name
          ]}
          component={SentroTextField}
        />
      </Box>
      <Box>
        <Text
          style={{ fontWeight: 600, color: "#2B2D33" }}
          textType="cap"
          content="Email"
        />
        <Field
          id="email"
          // maxLength={25}
          autoComplete="off"
          name="email"
          validate={[
            validations.required,
            validations.whiteSpace,
            validations.email,
            validations.verifyEmail,
            validations.noCaps
          ]}
          component={SentroTextField}
        />
      </Box>
      <Box>
        <Text
          style={{ fontWeight: 600, color: "#2B2D33" }}
          textType="cap"
          content="Mobile Number"
        />

        <Field
          id="phone_number"
          maxLength={11}
          cut={true}
          name="phoneNumber"
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment
          //       style={{ marginRight: 5, fontWeight: "bold" }}
          //       position="end"
          //     >
          //       <Typography
          //         style={{
          //           fontWeight: 600,
          //           color: "rgba(0, 0, 0, 0.54)",
          //           fontSize: 14
          //         }}
          //       >
          //         +63
          //       </Typography>
          //     </InputAdornment>
          //   ),
          //   style: { fontSize: 15 }
          // }}
          validate={[
            validations.required,
            validations.mobileNumber,
            validations.mobileNumberValidation,
            validations.mobileNumberValidation2
          ]}
          component={SentroTextField}
        />
      </Box>
      <Box>
        <Text
          style={{ fontWeight: 600, color: "#2B2D33" }}
          textType="cap"
          content="Password"
        />

        <Field
          id="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SentroButton
                  id="show_password"
                  style={{ color: "#2B2D33", fontSize: 10 }}
                  label={showPassword ? "HIDE" : "SHOW"}
                  onClick={e => setShowPassword(!showPassword)}
                  onMouseDown={e => setShowPassword(showPassword)}
                />
              </InputAdornment>
            )
          }}
          name="password"
          type={showPassword ? "text" : "password"}
          validate={[validations.required, validations.password]}
          component={SentroTextField}
          onKeyDown={signUpViaEnter}
        />
      </Box>
      <Box style={{ marginTop: "2vh" }}>
        <SentroCheckBox
          style={{ fontSize: 12 }}
          label={
            <div>
              By signing up, I agree with the{" "}
              <span
                className={classes.link}
                onClick={() => window.open(Path.TNC)}
              >
                Terms and Conditions
              </span>
              <span className={classes.link}> & </span>
              <span
                className={classes.link}
                onClick={() => window.open(Path.PRIVACY_POLICY)}
              >
                Privacy Policy
              </span>
            </div>
          }
          onChange={() => setChecked(!checked)}
        />
      </Box>
      <Box style={{ marginTop: "3vh" }}>
        <SentroButton
          id="signUp_button"
          disabled={error || !checked || asyncError}
          fullWidth
          variant="contained"
          label="Sign up"
          onClick={() => submitCreds()}
          style={{ height: 48, fontSize: 16, fontWeight: 600 }}
          innerRef={signUpButtonRef}
        />
      </Box>
    </Box>
  );
};

let checkUniqueEmail = async value => await uniqueEmail(value);
RegisterForm = reduxForm({
  form: "registerForm",
  destroyOnUnmount: true,
  asyncValidate: composeAsyncValidator({
    email: [checkUniqueEmail, "Email already registered!"]
  }),
  asyncBlurFields: ["email"]
})(RegisterForm);

export default RegisterForm;
