import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  IconButton,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment'
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

function DataTable(props) {
  const useStyles = makeStyles(theme => ({
    label: {
      fontSize: 13,
      color: "#54575f"
    },
    page: {
      fontSize: 14,
      color: "rgba(43, 45, 50, 0.8)"
    }
  }));
  const classes = useStyles();
  return (
    <div className="table">
      <Table>
        <TableHead className="header" style={{ backgroundColor: "#eee" }}>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">File name</TableCell>
            <TableCell align="center">Item</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((item, key) => {
            return (
              <TableRow hover className={`${classes.tableRow} row`}>
                <TableCell align="left" className={classes.label}>
                  {moment(item.created_on).format('DD MMM YYYY, hh:mm A')}
                </TableCell>
                <TableCell align="left" className={classes.label}>
                  {item.file.split("/").slice(-1)[0]}
                </TableCell>
                <TableCell align="center" className={classes.label}>
                  {item.success_count}/{item.rows_count}
                </TableCell>
                <TableCell align="left">
                  <Chip
                    size="small"
                    label={item.status}
                    style={{
                      color: item.status === "partial" ? "#2B2D32": "#fff",
                      backgroundColor:
                        item.status === "success" ? "#18B4A1": 
                        item.status === "partial" ? "#F6C064": 
                        "#C24A39"
                    }}
                  />{" "}
                </TableCell>
                <TableCell align="center">
                  <IconButton>
                    {
                      item.status === "success" ? 
                      <VisibilityOutlinedIcon className="success" style={{ fontSize: 16 }} onClick={props.successOnClick} /> :
                      <SystemUpdateAltIcon className="error" style={{ fontSize: 16 }} onClick={() => props.errorOnClick(item.error_file)} />
                    }
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div className="d-flex align-items-center justify-content-end pt-3 pagination">
        <p className={`mb-0 mr-2 ${classes.page} pagination-text`}>{props.filters.page}-{props.filters.maxPage} of {props.filters.maxPage}</p>
        <Pagination 
          count={props.filters.maxPage}
          rowsPerPage={props.filters.itemsPerPage}
          page={props.filters.page}
          showFirstButton showLastButton 
          onChange={props.pageOnChange}/>
      </div>
    </div>
  );
}

export default DataTable;