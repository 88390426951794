import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import TextField from '@material-ui/core/TextField';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AppCards from './cards';

import GalleryImageUpload from '../galleryImageUpload';

const useStyles = theme => ({
  root: {
    marginBottom: "1em",
    textAlign: "center",
    "& p.noVariations": {
      fontSize: 14,
      marginBottom: 0,
      padding: "1em",
      fontWeight: 'bold',
      color: 'gray'
    }
  },
  field: {
    fontSize: 12,
    margin: "1.5em 0 0",
    maxWidth: '50%',
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
    },
  },
  title: {
    margin: "1em 0",
    color: "#54575F",
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  divider: {
    margin: "1em 0"
  },
  addButtonDiv: {
    display: "flex",
    minHeight: 45,
    border: "1px dashed #f97663",
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 0.2s ease",
    cursor: "pointer",
    "&:hover": {
      background: '#f976631a'
    },
    "& > p": {
      marginBottom: 0,
      marginLeft: 5,
      color: "#f97663",
      fontWeight: "bold"
    },
    "& > svg": {
      color: "#f97663",
    }
  },
  modalDiv: {
    flexDirection: "column",
    padding: "2.5em",
    // minWidth: 500,
    [theme.breakpoints.down("xs")]: {
      // minWidth: "auto",
    },
  },
  modalTile: {
    fontSize: 26,
    color: "#2B2D33",
    fontWeight: 600,
    // marginBottom: 25,
    lineHeight: "1em"
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    color: "#54575F",
    marginBottom: 25
  },
  modalActionsWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  modalActions: {
    minWidth: 100,
    padding: "0 1.5em",
    height: 35,
    fontSize: 14,
    fontWeight: 600
  },
  actionIcons: {
    fontSize: 20,
    margin: 3,
    color: 'gray',
    cursor: 'pointer'
  },
  sectionDiv: {
    position: 'relative',
    "&:hover": {
      "& > .actionDiv": {
        opacity: 1
      }
    },
  },
  actionDiv: {
    opacity: 0,
    transition: "all 0.2s ease",
    position: 'absolute',
    top: 0,
    right: 0,
    "& .edit .delete": {
      height: '20px',
      color: 'gray',
      cursor: 'pointer'
    }
  },
  appCard: {
    position: 'relative',
    "& svg": {
      height: '15px',
    }
  }
});

class CardsSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dialogStat: false,
      dialogType: "add",
      targetIndex: 0,
      // fields
      sectionName: "",
    }
    this.setDialogState = this.setDialogState.bind(this)
    this.textFieldOnChange = this.textFieldOnChange.bind(this)
    this.handleChangeBillingTimeframe = this.handleChangeBillingTimeframe.bind(this)
    this.add = this.add.bind(this)
    this.edit = this.edit.bind(this)
    this.delete = this.delete.bind(this)
    this.resetFields = this.resetFields.bind(this)
    this.updateCard = this.updateCard.bind(this)
  }

  setDialogState(dialogStat, dialogType="add", targetIndex=0){
    this.setState({
      dialogStat,
      dialogType,
      targetIndex,
    })
    if (dialogType === "edit"){
      this.setState({
        sectionName: this.props.data[targetIndex].sectionName
      })
    }
  }

  textFieldOnChange(event, fieldname){
    this.setState({
      ...this.state,
      [fieldname]: event.target.value 
    })
  }

  handleChangeBillingTimeframe = (event) => {
    this.setState({
      variation: {
        ...this.state.variation,
        billingTimeframe: event.target.value
      } 
    });
  };

  add(){
    const data = this.props.data;
    data.push({
        sectionName: this.state.sectionName,
        apps: []
    });
    this.props.updateDetailedDescription([...data]);
    this.resetFields();
  }

  edit(index){
    let data = this.props.data;
    data[index]['sectionName'] = this.state.sectionName;
    this.props.updateDetailedDescription([...data]);
    this.resetFields();
  }

  delete(index, type){
    let data = this.props.data;
    data.splice(index, 1);
    this.props.updateDetailedDescription([...data]);
    this.resetFields();
  }

  resetFields(){
    this.setState({
      sectionName: '',
      dialogStat: false
    });
  }

  updateCard(data, index){
    const sections = this.props.data;
    sections[index].apps = data
    this.props.updateDetailedDescription([...sections]);
  }

  render (){
    const { classes, data, readOnly } = this.props;
    const { dialogStat, dialogType, targetIndex } = this.state;

    const dialogAction = (index=0) => {
      switch(dialogType){
        case "add":
          return this.add()
        case "edit": 
          return this.edit(index)
        case "delete":
          return this.delete(index)
        default:
          return this.add()
      }
    }

    const dialogTitle = () => {
      switch(dialogType){
        case "add":
          return `Add`
        case "edit": 
          return `Edit`
        case "delete":
          return `Delete`
        default:
          return `Add`
      }
    }

    const dialogContent = () => {
      switch(dialogType){
        case "delete":
          return `Delete section?`
        default:
          return (
            <div className={classes.modalBody}>
              <TextField
                // className={classes.field}
                onChange={(event) => this.textFieldOnChange(event, "sectionName")}
                label="Section Name"
                type="text"
                margin="dense"
                placeholder="Section Name"
                variant="outlined"
                value={this.state.sectionName}
              />
            </div>
          )
      }
    }

    return (
      <>
        <Dialog
          open={dialogStat}
          onClose={(event) => this.setDialogState(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth={true}
        >	
          <div className={classes.modalDiv}>
            <div className={classes.modalTile}>
              {dialogTitle()}
            </div>
            {/* <div className={classes.modalBody}> */}
              {dialogContent()}
            {/* </div> */}
            <div className={classes.modalActionsWrapper}>
              <Button
                id="action_button"
                variant="outlined"
                onClick={(event) => {
                  this.setDialogState(false)
                  this.resetFields()
                }} 
                color="primary"
                margin="dense"
                className={classes.modalActions}
                style={{ marginLeft: "auto" }}
              >
                Cancel
              </Button>
              <Button
                id="action_button"
                variant="contained"
                onClick={(event) => {
                  console.log(dialogAction)
                  dialogAction(targetIndex)
                }}
                color="primary"
                margin="dense"
                className={classes.modalActions}
                style={{ marginLeft: "1em" }}
              >
                {dialogTitle()}
              </Button>
            </div>
          </div>
        </Dialog>
        <div className={classes.root}>
          {
            data ?
            data.map((section, index) => (
              <div key={index} className={classes.sectionDiv}>
                {
                  (!readOnly && true)  &&
                  <div className={`${classes.actionDiv} actionDiv`}>
                    <EditOutlinedIcon className={`${classes.actionIcons} edit`}
                    onClick={() => {  this.setDialogState(true, "edit", index) }}/>
                    <HighlightOffIcon className={`${classes.actionIcons} delete`} color="disabled" 
                    onClick={() => this.setDialogState(true, "delete", index)}/>
                  </div>
                }
                <h2 className={classes.title}>{section.sectionName}</h2>
                <AppCards data={section.apps} key={index} updateCard={(data) => this.updateCard(data, index)} readOnly={readOnly}/>
              </div>
            ))
            :
            ``
          }
        </div>
        {
          (!readOnly && true)  && 
          <div className={classes.addButtonDiv} onClick={() => {
            this.setDialogState(true)
          }}>
            <AddCircleOutlineIcon /><p>Add Section</p>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({ marketplace }) => ({ 
  // apps: marketplace.apps
});
export default connect(mapStateToProps)(withStyles(useStyles)(CardsSection));

