

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import Grow from '@material-ui/core/Grow';
import { useSelector } from 'react-redux';
import {Drawer, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    top: 15,
    left: 15,
    zIndex: 10,
  },
  root: {
    width: "85%",
    padding: "26px 20px",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  name: {
    fontSize: 16
  },
}));


export default function DrawerMobile(props) {
  const classes = useStyles();
  const enterprise = useSelector(state => state.enterprise.main.enterprise)
  
  return (
    <Drawer anchor={"left"} open={props.open} onClose={props.onClose} classes={{paper: classes.root}}>
      <IconButton className={classes.closeButton} color="primary" onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
        <div className="text-center">
          <Typography className={classes.name} color="primary">
            {enterprise.name}
          </Typography>
        </div>
        {props.children}
    </Drawer>
  );
}
