import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Radio } from "antd";
import { useMediaQuery, Button, Link, IconButton } from "@material-ui/core";
import {
  PhoneIphone,
  MailOutline,
  ArrowBack,
  ArrowForwardIos,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import BorderColorIcon from "@material-ui/icons/BorderColorOutlined";
import CourierSelection from "./courierSelection";
import Utils from "../../../utils";
export default function DeliveryRates(props) {
  const {
    delivery_details,
    delivery_locations,
    pickup_address,
    mrspeedy_settings,
    ninjavan_settings,
    storeInfo,
    customer_details,
    pickup_google_address,
  } = props;
  const useStyles = makeStyles((theme) => ({
    box: {
      borderRadius: 4,
      backgroundColor: "#fafafa",
      padding: "24px 16px",
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [flatRate, setFlatRate] = React.useState("");
  const [openCourierSelection, setOpenCourierSelection] = React.useState(false);

  const setCourierDetails = (values) => {
    if (props.onChange) {
      props.onChange(values);
    }
  };

  const courierLabel = () => {
    if (delivery_details.delivery_detail.courier === "ninjavan") {
      return (
        <div style={{ fontWeight: 500, marginLeft: 10, fontSize: 13 }}>
          (NinjaVan - {delivery_details.delivery_detail.delivery_type} Delivery)
        </div>
      );
    } else if (delivery_details.delivery_detail.courier === "mr_speedy") {
      return (
        <div style={{ fontWeight: 500, marginLeft: 10, fontSize: 13 }}>
          (Borzo)
        </div>
      );
    }
  };

  const setShipping = (value) => {
    if (value === "flat_rate") {
      props.onChange({
        ...delivery_details,
        shipping: "flat_rate",
        shippingFee: flatRate ? flatRate.rate : 0,
        delivery_detail: flatRate ? flatRate : "",
      });
    } else if (value === "pick_up") {
      props.onChange({
        ...delivery_details,
        shipping: "pick_up",
        shippingFee: 0,
        delivery_detail: pickup_address[0],
      });
    } else if (value === "courier") {
      props.onChange({
        ...delivery_details,
        shipping: "courier",
        shippingFee: 0,
        delivery_detail: "",
      });
    }
  };

  const courier_address = () => {
    if (
      delivery_details.shipping === "courier" &&
      delivery_details.delivery_detail.courier === "mr_speedy"
    ) {
      return delivery_details.delivery_detail.google_address.address;
    } else if (
      delivery_details.shipping === "courier" &&
      delivery_details.delivery_detail.courier.includes("ninjavan")
    ) {
      return `${delivery_details.delivery_detail.landmark}, ${delivery_details.delivery_detail.barangay}, ${delivery_details.delivery_detail.city}, ${delivery_details.delivery_detail.province}`;
    }
  };

  React.useEffect(() => {
    let hasProvince = [];
    let hasCity = [];
    let hasNoCity = [];
    for (let i in delivery_locations) {
      if (delivery_locations[i].province === customer_details.provinceAddress) {
        hasProvince.push(delivery_locations[i]);
      }
    }

    if (hasProvince.length) {
      for (let i in hasProvince) {
        if (hasProvince[i].cities.length) {
          if (hasProvince[i].cities.includes(customer_details.cityAddress)) {
            hasCity.push(hasProvince[i]);
          }
        } else {
          hasNoCity.push(hasProvince[i]);
        }
      }
    }

    if (hasProvince.length && hasCity.length) {
      setFlatRate(hasCity[0]);
    } else if (hasProvince.length && !hasCity.length) {
      setFlatRate(hasNoCity[0]);
    }
  }, [delivery_locations]);

  console.log(mrspeedy_settings?.enabled, ninjavan_settings?.enabled);
  return (
    <div>
      <Radio.Group
        style={{ width: "100%", padding: "0 24px 24px" }}
        value={delivery_details.shipping}
        onChange={(e) => setShipping(e.target.value)}
      >
        {delivery_locations && delivery_locations.length ? (
          <div className="py-2" style={{ borderBottom: "1px dashed #d1d5dd" }}>
            <Radio className="py-3 w-100" value="flat_rate">
              Flat Rate shipping
            </Radio>
            {delivery_details.shipping === "flat_rate" ? (
              flatRate ? (
                <div
                  className={`col-12 ${classes.box}`}
                  style={{
                    flexDirection: isMobile ? "column" : "",
                    display: "flex",
                  }}
                >
                  <div
                    className="col-lg-2 col-xs-12"
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      marginBottom: isMobile ? 15 : 0,
                    }}
                  >
                    Address
                  </div>
                  <div
                    className="col-lg-8 col-xs-12"
                    style={{ marginBottom: isMobile ? 15 : 0 }}
                  >
                    <div style={{ fontSize: 12, fontWeight: 600 }}>
                      {`${customer_details.streetAddress}, ${customer_details.barangayAddress}, ${customer_details.cityAddress}, ${customer_details.provinceAddress}, ${customer_details.postalCode}`}
                    </div>
                    <div style={{ fontSize: 11, marginTop: 10 }}>
                      {flatRate ? flatRate.delivery_information : ""}
                    </div>
                  </div>
                  <div
                    className="col-lg-2 col-xs-12"
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: isMobile ? "left" : "right",
                    }}
                  >
                    ₱ {flatRate ? flatRate.rate : ""}
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <Alert
                      id="flat_rate_error"
                      style={{ fontSize: 12 }}
                      severity="warning"
                    >
                      Oops! Your address is not covered by the seller's delivery
                      locations. If you proceed with payment, seller will inform
                      you once items are ready for pick-up or delivery. Kindly
                      coordinate for the delivery arrangement.
                    </Alert>
                    <div className="pt-4">
                      <div style={{ fontSize: 14, fontWeight: 600 }}>
                        {storeInfo.name}
                      </div>
                      <div className="pt-3">
                        {storeInfo && storeInfo.email ? (
                          <div className="d-flex align-items-center">
                            <MailOutline
                              style={{
                                color: "rgba(43,45,50, 0.8)",
                              }}
                            />
                            <div
                              className="ml-3"
                              style={{
                                fontSize: 12,
                                color: "rgba(43,45,50, 0.8)",
                              }}
                            >
                              {storeInfo.email}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {storeInfo && storeInfo.mobile ? (
                          <div className="d-flex align-items-center mt-2">
                            <PhoneIphone
                              style={{
                                color: "rgba(43,45,50, 0.8)",
                              }}
                            />
                            <div
                              className="ml-3"
                              style={{
                                fontSize: 12,
                                color: "rgba(43,45,50, 0.8)",
                              }}
                            >
                              {storeInfo.mobile}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {mrspeedy_settings?.enabled || ninjavan_settings?.enabled ? (
          <div className="py-2 " style={{ borderBottom: "1px dashed #d1d5dd" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <Radio className="py-3 d-flex" value="courier">
                  <div className="d-flex align-items-center">
                    Select courier {!isMobile ? courierLabel() : ""}
                  </div>
                </Radio>
              </div>

              {delivery_details.shipping === "courier" ? (
                isMobile ? (
                  <IconButton>
                    <ArrowForwardIos
                      style={{ fontSize: 14, color: "#54575f" }}
                      onClick={() => setOpenCourierSelection(true)}
                    />
                  </IconButton>
                ) : (
                  <Link
                    onClick={() => setOpenCourierSelection(true)}
                    style={{
                      color: "#f97663",
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    <BorderColorIcon
                      style={{
                        color: "#f97663",
                        fontSize: 16,
                        marginRight: 10,
                      }}
                    />
                    Edit selection
                  </Link>
                )
              ) : (
                ""
              )}
              <CourierSelection
                open={openCourierSelection}
                setOpen={setOpenCourierSelection}
                pickup_address={pickup_address}
                mrspeedy_settings={mrspeedy_settings}
                ninjavan_settings={ninjavan_settings}
                delivery_details={delivery_details}
                setCourierDetails={setCourierDetails}
                customer_details={customer_details}
                items={props.items}
                pickup_google_address={pickup_google_address}
              />
            </div>
            {isMobile ? <div className="pb-3">{courierLabel()}</div> : ""}

            {delivery_details.shipping === "courier" &&
            delivery_details.delivery_detail ? (
              <div>
                <div
                  className={`col-12 ${classes.box}`}
                  style={{
                    flexDirection: isMobile ? "column" : "",
                    display: "flex",
                  }}
                >
                  <div
                    className="col-lg-2 col-xs-12"
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      marginBottom: isMobile ? 15 : 0,
                    }}
                  >
                    Address
                  </div>
                  <div
                    className="col-lg-8 col-xs-12"
                    style={{ marginBottom: isMobile ? 15 : 0 }}
                  >
                    <div style={{ fontSize: 12, fontWeight: 600 }}>
                      {courier_address()}
                    </div>
                  </div>
                  <div
                    className="col-lg-2 col-xs-12"
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      textAlign: isMobile ? "left" : "right",
                    }}
                  >
                    {`₱ ${Utils.commafy(delivery_details.shippingFee)}`}
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#eee",
                    fontSize: 10,
                    color: "#54575f",
                  }}
                  className="px-4 py-3 mt-3"
                >
                  <strong>Please note:</strong> You may be asked to pay
                  additional price by the seller if total package weight exceeds
                  5kg.
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {pickup_address &&
        pickup_address.length &&
        pickup_address[0].show_pickup_address ? (
          <div className="py-2">
            <Radio className="py-3 w-100" value="pick_up">
              Pick-up from seller
            </Radio>
            {delivery_details.shipping === "pick_up" ? (
              <div
                className={`mt-2 col-12 ${classes.box}`}
                style={{
                  flexDirection: isMobile ? "column" : "inherit",
                  display: "flex",
                }}
              >
                <div
                  className="col-lg-2 col-xs-12"
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginBottom: isMobile ? 15 : 0,
                  }}
                >
                  Pick-up Address
                </div>
                <div
                  className="col-lg-8 col-xs-12"
                  style={{ marginBottom: isMobile ? 15 : 0 }}
                >
                  <div style={{ fontSize: 12, fontWeight: 600 }}>
                    {pickup_address[0].google_address.address}
                  </div>
                  <div style={{ fontSize: 11, marginTop: 10 }}>
                    Delivery Fee shouldered by customer
                  </div>
                </div>
                <div
                  className="col-lg-2 col-xs-12"
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    textAlign: isMobile ? "left" : "right",
                  }}
                >
                  FREE
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </Radio.Group>
    </div>
  );
}
