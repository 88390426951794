// import { uploadFile } from "react-s3";
import apiConfig from "../common/apiConfig";
import sentroAxios from "../utils/SentroAxios";

class S3Utils {
  // static async fileUpload(file, name, site) {
  //   const config = {
  //     bucketName: apiConfig.s3Bucket,
  //     name: name,
  //     dirName: `${site}` /* optional */,
  //     region: apiConfig.awsRegion,
  //     accessKeyId: apiConfig.awsId,
  //     secretAccessKey: apiConfig.awsSecret
  //   };

  //   await uploadFile(file, config)
  //     .then(data => console.log(data))
  //     .catch(err => console.error(err));
  // }

  static async saveProductToDB(value) {
    let params = new FormData();
    params.append("images", [value.images]);
    // let params = {
    //   // store: value.store,
    //   // name: value.name,
    //   // price: value.price,
    //   // description: value.description,
    //   // category: value.category,
    //   images: [value.images]
    // };

    let response = await sentroAxios.put(
      `${apiConfig.apiURL}/api/dashboard/${value.store}/products/${value.product_code}`,
      params
    );
    console.log(response, "response");
    return response;
  }

  static async saveToDB(store, file, name) {
    console.log(file);
    let params = new FormData();
    params.append([name], file);
    let response = await sentroAxios.put(
      `${apiConfig.apiURL}/api/store/${store.site}/`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  }
}

export default S3Utils;
