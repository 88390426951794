import React from "react";
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import LensIcon from '@material-ui/icons/Lens';


const useStyles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    padding: "1.5em",
    border: "1px solid #e6eaf0d1",
    "& > p": {
      marginBottom: 0
    }
  },
  logo: {
    margin: "0 auto",
    height: 55,
    width: 55,
    padding: "2em",
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.23)",
  },
  titleWrapper: {
    textAlign: "center",
    margin: "1em 0"
  }
});

class FeaturesCard extends React.Component {
  
  render (){
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <img src={this.props.logo} className={classes.logo} />
        <div className={classes.titleWrapper}>
          <strong>{this.props.title}</strong>
        </div>
        <p>- Phasellus malesuada mollis suscipit. Phasellus malesuada mollis suscipit</p>
        <p>- Phasellus malesuada mollis suscipit. Phasellus malesuada mollis suscipit</p>
      </div>
    )
  }
}

export default withStyles(useStyles)(FeaturesCard);
