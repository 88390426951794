import React from "react";
import {
  Dialog,
  Zoom,
  Button,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { Error } from "@material-ui/icons";
import Path from "../../../Paths";
import { useHistory } from "react-router-dom";
const Transition = props => <Zoom {...props} />;
export default function ActivateBuxWalletModal(props) {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={props.open}
      TransitionComponent={Transition}
    >
      <div className="p-md-4 p-3 d-flex align-items-center justify-content-center text-center">
        <div>
          <Error style={{ color: "#cf4741", fontSize: 90 }} />
          <div
            className="py-3 px-md-3 px-0"
            style={{
              fontSize: isMobile ? 16 : 18,
              fontWeight: 600,
              color: "#002c42"
            }}
          >
            {props.is_ninjavan
              ? `Activate your BUx Payout wallet to use NinjaVan as a courier`
              : `Upgrade your BUx Payout wallet to continue with subscription`}
          </div>
          <div style={{ fontSize: 14 }} className="px-md-4 px-2">
            {props.is_ninjavan ? (
              <div>
                Your Payout wallet must be enabled and upgraded to <b>BUxMe</b>{" "}
                to use <b>NinjaVan</b> as a courier.
              </div>
            ) : (
              <div>
                Your Payout wallet must be enabled and upgraded to <b>BUxMe</b>{" "}
                to enjoy all new features of the <b>Sentro Advanced Plan</b>.
              </div>
            )}
          </div>
          <div className="d-flex pt-4">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className="w-50 mr-2"
              onClick={() => props.setOpen(false)}
            >
              No, take me back
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              size="small"
              className="w-50"
              onClick={() => history.push(Path.PAYMENTS)}
            >
              Go to Payments
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
