import React from "react";
import { makeStyles } from "@material-ui/styles";
import bux from "../../assets/images/Bux.svg";
import seekcap from "../../assets/images/Seekcap.svg";
import messenger from "../../assets/images/Messenger.svg";
import domain from "../../assets/images/Domain.svg";
import pixel from "../../assets/images/Pixel.svg";
import { Button } from "@material-ui/core";
import history from "../../store/history";
import Path from "../../Paths";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ToolsLogo from "../../assets/images/tools10.png";
import { useDispatch, useSelector } from "react-redux";

const ShopTools = (props) => {
  const { language } = props;
  const dispatch = useDispatch;
  const kyc_level = useSelector((state) => state.webBuilder.buxStatus);
  const stores = useSelector((state) => state.webBuilder.stores);
  const feature_settings = useSelector(
    (state) => state.dashboard.featureSettings?.features
  );
  const [toolsExtended, setToolsExtended] = React.useState(false);
  const useStyles = makeStyles((theme) => ({
    card: {
      backgroundColor: "#fff",
      border: "1px solid #d1d5dd",
      position: "relative",
      borderRadius: 10,
      padding: 12,
      width: "100%",
      height: language === "English" ? 245 : 260,
      margin: 5,
    },
    header: {
      color: "#002c42",
      fontWeight: 600,
      fontSize: 14,
      textAlign: "center",
      marginTop: 14,
    },
    subheader: {
      textAlign: "center",
      fontSize: 12,
    },
    btn: {
      height: 40,
      fontSize: 10,
    },
    toolsDiv: {
      transition: "all 0.6s ease-in-out",
      overflow: "hidden",
      maxHeight: "265px",
    },
    showMore: {
      fontWeight: 600,
      fontSize: 14,
      color: "#F97663",
      marginBottom: 0,
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
    },
    extended: {
      maxHeight: "999px !important",
    },
  }));

  const classes = useStyles();

  function buxLevel() {
    let status = kyc_level;
    switch (status) {
      case 0:
        return "Basic level";
      case 1:
        return "Pro level";
      case 2:
        return "Advanced level";
      case 3:
        return "Business level";
      case 5:
        return "Enterprise";
      default:
        return "Basic level";
    }
  }

  const shopTools = [
    {
      icon: bux,
      key: "bux",
      header:
        language === "English"
          ? "Upgrade your Bux wallet for free"
          : "Libreng pag-upgrade ng iyong wallet",
      subheader:
        language === "English" ? (
          <div className={classes.subheader}>
            Your wallet is currently in{" "}
            <span style={{ color: "#f97663", fontWeight: 600 }}>
              {buxLevel()}
            </span>
          </div>
        ) : (
          <div className={classes.subheader}>
            Ang wallet mo ay naka{" "}
            <span style={{ color: "#f97663", fontWeight: 600 }}>
              {buxLevel()}
            </span>{" "}
            palang
          </div>
        ),
      button: "Upgrade my wallet",
    },
    {
      icon: seekcap,
      key: "seekcap",
      header:
        language === "English"
          ? "Apply for a Business Loan at SeekCap"
          : "Mag-apply ng business loan sa SeekCap",
      subheader: (
        <div className={classes.subheader}>
          {language === "English"
            ? "Get approved for as fast as one day!"
            : "Ma-approve sa loob ng isang araw lang!"}
        </div>
      ),
      button: "Apply Now",
    },
    {
      icon: messenger,
      key: "messenger",
      header:
        language === "English"
          ? "Enable Messenger in your shop"
          : "Lagyan ng Messenger ang shop mo",
      subheader: (
        <div className={classes.subheader}>
          {language === "English"
            ? "Link your shop's Messenger account"
            : "I-link ang messenger account ng shop mo"}
        </div>
      ),
      button: "Learn More",
      isDisabled: stores ? (stores[0] ? false : true) : true,
    },
    {
      icon: domain,
      key: "domain",
      header: "Link your own domain",
      disabled: true,
      subheader: (
        <div className={classes.subheader}>
          Use your own domain for your Sentro shop
        </div>
      ),
      button: "Learn More",
      isDisabled: true,
    },
    {
      icon: pixel,
      key: "pixel",
      header:
        language === "English"
          ? "Add FB Pixel to your shop"
          : "Lagyan ng FB Pixel ang shop mo",
      subheader: (
        <div className={classes.subheader}>
          {language === "English"
            ? "Track your Facebook ads and more"
            : "I-track ang iyong Facebook ads at iba pa"}
        </div>
      ),
      button: "Learn More",
      isDisabled: stores ? (stores[0] ? false : true) : true,
    },
  ];

  function handleOnClick(key) {
    switch (key) {
      case "bux":
        history.push(Path.PAYMENTS);
        break;
      case "seekcap":
        window.open(
          "http://seekcap.ph/?&utm_source=sentro&utm_medium=referral&utm_campaign=sentro_inbound_112020"
        );
        break;
      case "messenger":
        history.push(Path.FB_CHAT_SETUP);
        break;
      case "pixel":
        history.push(Path.FB_PIXEL_SETUP);
        break;
    }
  }

  const MoreToolCard = (
    <div className="col-3 mt-2">
      <div className={classes.card}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 12,
          }}
        >
          <img src={ToolsLogo} style={{ width: "auto", height: 50 }} alt="" />
        </div>
        <div className={classes.header}>
          More exciting additional features to come!
        </div>
        <div style={{ marginTop: 12 }}>
          <div className={classes.subheader}>
            New features coming up in the next months
          </div>
        </div>
      </div>
    </div>
  );
  const showMore = (
    <div
      id="showMoreButton"
      className={`text-center mt-3 ${classes.showMore}`}
      onClick={() => setToolsExtended(!toolsExtended)}
    >
      <p className="mb-0">Show {toolsExtended ? "Less" : "More"}</p>
      <ExpandMoreIcon
        style={{
          transform: `rotate(${toolsExtended ? 180 : 0}deg)`,
        }}
      />
    </div>
  );

  return (
    <div>
      <div style={{ fontSize: 14, color: "rgb(84, 87, 95)" }}>
        Additional Features
      </div>
      <div
        className={`${classes.toolsDiv} ${
          toolsExtended ? classes.extended : ""
        }`}
        id="toolsDiv"
      >
        <div className="row">
          {shopTools.map((item, index) => {
            return (
              <div className="col-3 mt-2" key={index}>
                <div className={classes.card}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 12,
                    }}
                  >
                    <img
                      src={item.icon}
                      style={{ width: "auto", height: 50 }}
                      alt=""
                    />
                  </div>
                  <div className={classes.header}>{item.header}</div>
                  <div style={{ marginTop: 12 }}>{item.subheader}</div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 10,
                      right: 10,
                      left: 10,
                    }}
                  >
                    <Button
                      disabled={item.isDisabled}
                      variant="outlined"
                      fullWidth
                      color="primary"
                      className={classes.btn}
                      onClick={() => handleOnClick(item.key)}
                    >
                      {item.button}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
          {shopTools.length < 4 && MoreToolCard}
        </div>
      </div>
      {showMore}
    </div>
  );
};

export default ShopTools;
