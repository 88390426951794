import { ActionTypes } from "./actions";
const initialState = {
  walletAddress: null,
  ipfs: null,
  metadata: null,
};

export default function NFTReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CONNECT_WALLET:
      return {
        ...state,
        walletAddress: action.payload,
      }
    case ActionTypes.DISCONNET_WALLET:
      return {
        ...state,
        walletAddress: null,
      }
    case ActionTypes.UPLOAD_IMAGE_IPFS:
      return {
        ...state,
        ipfs: action.payload,
      }
    case ActionTypes.BUILD_METADATA_IPFS:
      return {
        ...state,
        metadata: action.payload,
      }
    default:
      return state;
  }
}
