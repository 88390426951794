import React from "react";
import { Route, Switch } from "react-router-dom";
import Path from "../Paths";
import AdminDashboard from "../components/admin/dashboard";
import Permissions from "../components/admin/permissions";
import AdminUsers from "../components/admin/admin_users";
import ShopLogs from "../components/admin/pages/shop/logs";
import Shops from "../components/admin/shops";
import Products from "../components/admin/products";
import Signups from "../components/admin/signups";
import Checkouts from "../components/admin/checkouts";
import BuxPayments from "../components/admin/bux_payments";
import UserDetail from "../components/admin/pages/user_detail";
import UnverifiedUsers from "../components/admin/unverified_users";
import ActiveUsers from "../components/admin/active_users";
import DailyActive from "../components/admin/daily_active";
import MonthlyActive from "../components/admin/monthly_active";
import AdminSigninVerification from "../components/admin/pages/verify";
import DetailUserAdmin from "../components/admin/pages/detail_admin_user";
import AddUserAdmin from "../components/admin/pages/form_admin_user";
import AdminAppMarketplace from "../components/admin/marketplace/list";
import AdminAppMarketplaceCreate from "../components/admin/marketplace/create";
import AdminAppMarketplacePartnerVerification from "../components/admin/marketplace/pages/partners/verify";
import PartnerSalesAndOrders from "../components/partner/salesAndOrders";
import AdminAppMarketplacePartnersDetail from "../components/admin/marketplace/pages/partners/detail";
import AdminAppMarketplacePartnersCreate from "../components/admin/marketplace/pages/partners/create";
import AdminAppMarketplacePartners from "../components/admin/marketplace/pages/partners/list";
import AdminAppMarketplaceSubscriptions from "../components/admin/marketplace/pages/subscriptions";
import Logistics from "../components/admin/pages/logistics/logistics";
import LogisticsBookings from "../components/admin/pages/logistics/logistics_bookings";
import LogisticsBooking from "../components/admin/pages/logistics/booking";
import AdminPromos from "../components/admin/promos";

let AdminRoutes = props => {
	return ([
		<Route key="shop_logs" path={Path.ADMIN_SHOP_LOGS} component={ShopLogs} />,
		<Route key="shops" path={Path.ADMIN_SHOPS} component={Shops} />,
		<Route key="products" path={Path.ADMIN_PRODUCTS} component={Products} />,
		<Route key="detail_signups" path={Path.ADMIN_DETAIL_SIGNUPS} component={UserDetail} />,
		<Route key="signups" path={Path.ADMIN_SIGNUPS} component={Signups} />,
		<Route key="checkouts" path={Path.ADMIN_CHECKOUTS} component={Checkouts} />,
		<Route key="bux_payments" path={Path.ADMIN_BUX_PAYMENTS} component={BuxPayments} />,
		<Route key="unverified_users" path={Path.ADMIN_DAILY_UNVERIFIED_USERS} component={UnverifiedUsers} />,
		<Route key="daily_active_users" path={Path.ADMIN_DAILY_ACTIVE_USERS} component={DailyActive} />,
		<Route key="monthly_active_users" path={Path.ADMIN_MONTHLY_ACTIVE_USERS} component={MonthlyActive} />,
		<Route key="admin_active_users" path={Path.ADMIN_ACTIVE_USERS} component={ActiveUsers} />,
		<Route key="dashboard" path={Path.ADMIN_DASHBOARD} component={AdminDashboard} />,
		<Route key="add_admin_users" path={Path.ADMIN_ADD_ADMIN_USERS} component={AddUserAdmin} />,
		<Route key="admin_detail" path={Path.ADMIN_DETAIL_ADMIN} component={DetailUserAdmin} />,
		<Route key="admin_users" path={Path.ADMIN_ADMIN_USERS} component={AdminUsers} />,
		<Route key="admin_users_old" path={Path.ADMIN_ADMIN_USERS_OLD} component={Permissions} />,
		<Route key="signup_verify" path={Path.ADMIN_SIGNUP_VERIFY} component={AdminSigninVerification} />,
		<Route key="marketplace_create" path={Path.ADMIN_APP_MARKETPLACE_CREATE} component={AdminAppMarketplaceCreate} />,
		<Route key="partner_signup_verify" path={Path.ADMIN_APP_MARKETPLACE_PARTNERS_SIGNUP_VERIFY} component={AdminAppMarketplacePartnerVerification} />,
		<Route key="partner_sales_view" path={Path.ADMIN_APP_MARKETPLACE_PARTNERS_SALES_VIEW} component={PartnerSalesAndOrders} />,
		<Route key="add_partner" path={Path.ADMIN_APP_MARKETPLACE_PARTNERS_ADD} component={AdminAppMarketplacePartnersCreate} />,
		<Route key="partner_edit" path={Path.ADMIN_APP_MARKETPLACE_PARTNERS_EDIT} component={AdminAppMarketplacePartnersCreate} />,
		<Route key="partner_detailed" path={Path.ADMIN_APP_MARKETPLACE_PARTNERS_DETAILED} component={AdminAppMarketplacePartnersDetail} />,
		<Route key="marketplace_partners" path={Path.ADMIN_APP_MARKETPLACE_PARTNERS} component={AdminAppMarketplacePartners} />,
		<Route key="marketplace_subscriptions" path={Path.ADMIN_APP_MARKETPLACE_SUBSCRIPTIONS} component={AdminAppMarketplaceSubscriptions}/>,
		<Route key="marketplace_app_edit" path={Path.ADMIN_APP_MARKETPLACE_EDIT} component={AdminAppMarketplaceCreate} />,
		<Route key="marketplace" path={Path.ADMIN_APP_MARKETPLACE} component={AdminAppMarketplace} />,
		<Route key="promos" path={Path.ADMIN_PROMOS} component={AdminPromos} />,
		<Route key="logistics_booking" path={Path.ADMIN_LOGISTICS_BOOKING} component={LogisticsBooking} />,
		<Route key="logistics_bookings" path={Path.ADMIN_LOGISTICS_BOOKINGS} component={LogisticsBookings} />,
		<Route key="logistics" path={Path.ADMIN_LOGISTICS} component={Logistics} />,
	]);
};
export default AdminRoutes;
		
