import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../redux/actions";
/* Image */
import rightDesignImg from "../img/rightdesign.png";
import leftDesignImg from "../img/leftdesign.png";
import infoIcon from "../img/info-circle.svg";
import usdcLogo from "../../../../../assets/images/usdc-logo.png";
import "../style/custom.css";
import apiConfig from "../../../../../common/apiConfig";
const PLATFORM_FEE = parseFloat(apiConfig.platformFee) * 100;
const NFTConfirmModal = (props) => {
  const dispatch = useDispatch();
  const useStyles = makeStyles(() => ({
    MuiDialogPaper: {
      backgroundColor: "#051626 !important",
      color: "#fff",
      position: "relative",
      overflow: "visible",
      border: "4px solid #B4C6F4",
      borderRadius: 0,
      "&::before": {
        content: `''`,
        position: "absolute",
        left: "-15px",
        top: "-15px",
        background: `transparent url(${leftDesignImg}) no-repeat`,
        backgroundSize: "contain",
        width: "56px",
        height: "90px",
      },
      "&::after": {
        content: `''`,
        position: "absolute",
        right: "-22px",
        top: "-15px",
        background: `transparent url(${rightDesignImg}) no-repeat`,
        backgroundSize: "contain",
        width: "56px",
        height: "90px",
      },
    },
  }));

  const classes = useStyles();

  const [openPaymentOptionModal, setOpenPaymentOptionModal] =
    React.useState(false);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.closeSuccessModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.MuiDialogPaper,
        }}
        className="nftFontFamily text-center"
        fullWidth={true}
        maxWidth="md"
      >
        <div className="nft-modal-border">
          <DialogTitle id="alert-dialog-title">
            <span style={{ fontSize: "24px" }}>Complete checkout</span>
          </DialogTitle>
          <div>
            <div className="row">
              <div
                className="col-6 text-left"
                style={{ fontSize: "20px", paddingLeft: "4rem" }}
              >
                Items
              </div>
              <div
                className="col-6 text-right"
                style={{ fontSize: "20px", paddingRight: "4rem" }}
              >
                Total
              </div>
            </div>
            <div
              className="horizontal-line-bluewhale"
              style={{ width: "90%" }}
            ></div>
            <div className="row py-3 ">
              <div className="col-4 text-left pl-5">
                <img src={props.image} alt="img" style={{ width: "80%" }} />
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-6 text-left pb-2">
                    <div className="pt-5" style={{ fontSize: "12px" }}>
                      {apiConfig.contractAddress}
                    </div>
                    <div className="pt-2" style={{ fontSize: "26px" }}>
                      {props.title}
                    </div>
                    <div className="pt-3" style={{ fontSize: "20px" }}>
                      Platform fee: {PLATFORM_FEE}%
                      <span className="nft-info">
                        <img
                          src={infoIcon}
                          alt="info icon"
                          className="nft-info-icon"
                        />
                        <div className="nft-tooltip-cut-corner nft-hide">
                          There will be a {PLATFORM_FEE}% deduction for every
                          transaction
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="col-6 text-right pr-5">
                    <div className="pt-5 ">
                      <img
                        src={usdcLogo}
                        alt="usdc logo"
                        style={{ height: "35px", marginRight: "15px" }}
                      />
                      <span
                        className="align-middle"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        {props.price} USDC
                      </span>
                    </div>
                    <div className="pt-3" style={{ fontSize: 28 }}>
                      {props.priceInPhp} PHP
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end mr-5">
                  <div className="w-50 row ">
                    <div className="col-6 col-xs-12">
                      <button
                        onClick={() => props.closeSuccessModal()}
                        disabled={props.isLoading}
                        className="nft-button-cut-corner"
                      >
                        CANCEL
                      </button>
                    </div>
                    <div className="col-6 col-xs-12">
                      <button
                        onClick={() => {
                          props.setShowPaymentOptionModal(true);
                          props.closeSuccessModal();
                        }}
                        disabled={props.isLoading}
                        className="nft-button-cut-corner"
                      >
                        <div
                          className="spinner-border"
                          role="status"
                          style={{
                            color: "white",
                            display: props.isLoading ? "inherit" : "none",
                            fontSize: 7,
                            height: 10,
                            width: 10,
                            marginRight: "2px",
                          }}
                        ></div>
                        BUY NOW
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default NFTConfirmModal;
