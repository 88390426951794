import React from "react";
import {
  makeStyles,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from "@material-ui/core";
import tags from "../../../../../assets/images/tags.svg";
import { useSelector, useDispatch } from "react-redux";
import Utils from "../../../utils";
import VoucherModal from "../voucher/voucherModal";
import { useHistory } from "react-router-dom";
export default function Summary(props) {
  const {
    loading,
    storeInfo,
    cartItems,
    subtotal,
    total,
    delivery_details,
    voucher_discount,
    shipping_fee
  } = props;
  const useStyles = makeStyles(theme => ({
    root: {
      zIndex: 1,
      height: "fit-content",
      marginLeft: "2vw",
      padding: 16,
      width: "35%",
      backgroundColor: "#fff",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: 0,
        position: "fixed",
        bottom: 0,
        filter: "drop-shadow(0px -4px 16px rgba(0, 0, 0, 0.12))",
        borderRadius: "8px 8px 0 0"
      }
    },
    label: {
      color: "rgba(43,45,50, 0.8)",
      fontSize: 16
    },
    label2: {
      color: "rgba(43,45,50, 0.8)",
      fontSize: 14
    },
    btnCheckout: {
      display: "none",
      height: 38,
      [theme.breakpoints.down("sm")]: {
        display: "inherit"
      }
    }
  }));
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <Table style={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              className={`${classes.label} font-weight-bold`}
              style={{ border: "none" }}
            >
              Summary
            </TableCell>
            <TableCell
              align="right"
              className={classes.label}
              style={{ border: "none" }}
            >
              <Link className={classes.label} onClick={() => setOpen(true)}>
                <img src={tags} width="15" height="15" className="mr-2" />
                Vouchers
              </Link>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              align="left"
              className={`${classes.label2} py-2`}
              style={{ border: "none" }}
            >
              Items
            </TableCell>
            <TableCell
              align="right"
              className={`${classes.label2} py-2`}
              style={{ border: "none" }}
            >
              {parseFloat(
                cartItems.reduce((total, object) => {
                  return object.has_variants
                    ? parseFloat(total) +
                        (object.always_available
                          ? parseFloat(object.quantity)
                          : object.quantity > 0
                          ? parseFloat(object.quantity)
                          : 0)
                    : parseFloat(total) +
                        (object.always_available
                          ? parseFloat(object.quantity)
                          : object.stock > 0
                          ? parseFloat(object.quantity)
                          : 0);
                }, 0)
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              className={`${classes.label2} py-2`}
              style={{ border: "none" }}
            >
              Subtotal
            </TableCell>
            <TableCell
              align="right"
              className={`${classes.label2} py-2`}
              style={{ border: "none" }}
            >
              ₱ {Utils.commafy(subtotal)}
            </TableCell>
          </TableRow>
          {delivery_details.shipping && delivery_details.delivery_detail ? (
            <TableRow>
              <TableCell
                align="left"
                className={`${classes.label2} py-2`}
                style={{ border: "none" }}
              >
                Delivery fee
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.label2} py-2`}
                style={{ border: "none" }}
              >
                ₱ {Utils.commafy(shipping_fee)}
              </TableCell>
            </TableRow>
          ) : (
            ""
          )}
          {voucher_discount ? (
            <TableRow>
              <TableCell
                align="left"
                className={`${classes.label2} py-2`}
                style={{ border: "none" }}
              >
                Voucher Applied
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.label2} py-2`}
                style={{ border: "none" }}
              >
                {`- ₱${Utils.commafy(voucher_discount)}`}
              </TableCell>
            </TableRow>
          ) : (
            ""
          )}
          <TableRow>
            <TableCell
              align="left"
              className={`${classes.label} font-weight-bold`}
              style={{ borderTop: "1px dashed #d1d5dd", borderBottom: "none" }}
            >
              Total
            </TableCell>
            <TableCell
              align="right"
              className={`${classes.label} font-weight-bold`}
              style={{ borderTop: "1px dashed #d1d5dd", borderBottom: "none" }}
            >
              ₱ {Utils.commafy(total - voucher_discount)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div>
        <Button
          id="proceed_to_checkout"
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          fullWidth
          className={`${classes.btnCheckout} mb-2`}
          disabled={props.disabled}
          onClick={props.proceed_to_checkout}
        >
          {loading ? (
            <div className="d-flex my-5 mr-3">
              <div
                className="spinner-border mx-auto"
                role="status"
                style={{
                  color: "white",
                  fontSize: 12,
                  height: 25,
                  width: 25
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}
          Proceed to Checkout
        </Button>
        {props.continue_shopping ? (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            disableElevation
            fullWidth
            className={`${classes.btnCheckout}`}
            onClick={() => history.push(`/shop/${storeInfo.site}/products`)}
          >
            Continue Shopping
          </Button>
        ) : (
          ""
        )}
      </div>
      <VoucherModal id="voucher-modal" open={open} setOpen={setOpen} />
    </div>
  );
}
