import { makeStyles } from "@material-ui/core";
import bg from "../../assets/images/kycPopup.jpg";
const useStyles = makeStyles(theme => ({
  paper: {
    width: "60em",
    height: "100vh",
    position: "absolute",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover"
  },
  title: {
    fontSize: 28,
    color: "#fff",
    fontWeight: "bold",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  title2: {
    textAlign: "center",
    fontSize: 18,
    color: "#2b2d33",
    fontWeight: 600
  },
  subTitle: {
    fontSize: 14,
    color: "#fff",
    padding: "0 30px",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  form: {
    width: "100%"
  },
  header: {
    marginTop: "1vh",
    color: "#2b2d32",
    fontSize: 28,
    fontWeight: 600
  },
  label: {
    fontSize: 14,
    fontWeight: 500
  },
  errorText: {
    color: "red",
    fontSize: 12
  },
  input: {
    fontSize: 14
  },
  modal: {
    position: "absolute",
    backgroundColor: "#fff"
  }
}));

export default useStyles;
