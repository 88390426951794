import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import Path from "../../Paths";
import { useDispatch } from "react-redux";
import { ReduxInitializeFormActions } from "./action";
const PaymentCard = props => {
  const { item, handleEditAccount } = props;
  const useStyles = makeStyles(theme => ({
    card: {
      position: "relative",
      border: "1px solid #d1d5dd",
      borderRadius: 4,
      display: "flex",
      width: "48%",
      margin: 5,
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: 10
      }
    },
    label1: {
      fontSize: 14,
      fontWeight: 600,
      color: "#54575f"
    },
    label2: {
      fontSize: 14,
      color: "#54575f"
    }
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  function accountType() {
    if (item.payment_method === "Bank") {
      return item.bank_name;
    } else {
      return item.payment_method;
    }
  }

  function paymentDetails1() {
    if (item.payment_method === "Bank" || item.payment_method === "PayMaya") {
      if (item.account_name === null || item.account_name === "null") {
        return "";
      } else {
        return item.account_name;
      }
    } else {
      return "";
    }
  }

  function paymentDetails2() {
    if (item.payment_method === "Bank") {
      return item.account_number;
    } else if (item.payment_method === "PayPal") {
      return item.payment_link;
    } else {
      return item.mobile_number;
    }
  }

  function paymentMethod() {
    if (item.payment_method === "Bank") return "0";
    else if (item.payment_method === "PayMaya") return "1";
    else if (item.payment_method === "PayPal") return "2";
  }

  function editAccount() {
    dispatch(
      ReduxInitializeFormActions.initializeAccountFormData({
        account_type: paymentMethod(),
        qr_code: item.qr_code_img,
        bank_name: item.bank_name,
        account_name: item.account_name,
        account_number: item.account_number,
        mobile_number: item.mobile_number,
        payment_link: item.payment_link,
        uuid: item.uuid
      })
    );
    history.push(`/payments/${item.uuid}`);
  }
  console.log(item);
  return (
    <div className={classes.card} onClick={editAccount}>
      <div
        style={{
          width: 120,
          height: 120,
          backgroundSize: "cover",
          backgroundImage: `url(${item.qr_code_img})`,
          borderRight: "1px solid #d1d5dd"
        }}
        className="m-md-0 m-2"
      />

      <div style={{ width: "70%", padding: "10px 20px", margin: "auto 0" }}>
        <div className={classes.label1} style={{ overflowWrap: "break-word" }}>
          {accountType()}
        </div>
        <div style={{ marginTop: 5 }}>
          <div
            className={classes.label2}
            style={{ overflowWrap: "break-word" }}
          >
            {paymentDetails1()}
          </div>
          <div
            style={{
              fontSize: 12,
              color: "#54575f",
              overflowWrap: "break-word"
            }}
          >
            {paymentDetails2()}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentCard;
