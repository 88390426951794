import React from "react";
import {
  Chip,
  Button,
  Link,
  useMediaQuery,
  Box,
  Typography
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { featuresList } from "./featuresList";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import { showAlert } from "../common/commonActions";
import axios from "axios";
import apiConfig from "../../common/apiConfig";
import { useHistory } from "react-router-dom";
import Path from "../../Paths";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionActions from "./redux/actions";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function Form(props) {
  const { setOpen } = props;
  const useStyles = makeStyles(theme => ({
    chip: {
      padding: "20px 6px",
      borderRadius: 24,
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        padding: "8px 16px",
        height: "fit-content",
        borderRadius: 24
      }
    },
    basicChip: {
      border: "1px solid #1e4b83",
      backgroundColor: "#fff",
      color: "#1e4b83"
    },
    basicSelected: {
      border: "1px solid #1e4b83",
      backgroundColor: "#1e4b83",
      color: "#fff"
    },
    advancedChip: {
      border: "1px solid #ff6b55",
      backgroundColor: "#fff",
      color: "#ff6b55"
    },
    advancedSelected: {
      border: "1px solid #ff6b55",
      backgroundColor: "#ff6b55",
      color: "#fff"
    }
  }));
  const styles = {
    tooltip: {
      width: "170px"
    }
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const subscription = useSelector(state => state.subscription.subscription);
  const buxWallet = useSelector(state => state.payments.buxParams);
  const codWallet = useSelector(state => state.payments.codWallet);
  const CustomTooltip = withStyles(styles)(Tooltip);
  const [selected, setSelected] = React.useState(0);
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState({
    open: false,
    index: ""
  });
  const handleChange = index => {
    setSelected(index);
  };

  const handleFreeTrial = async () => {
    try {
      let res = await axios.post(
        `${apiConfig.apiURL}/api/subscription/free_trial/`,
        {},
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      if (res.status === 200) {
        history.push(Path.DASHBOARD);
        dispatch(SubscriptionActions.getSubscription());
        setTimeout(() => {
          dispatch(
            showAlert({
              type: "success",
              variant: "filled",
              message: (
                <div>
                  Your Free Trial has started! You have{" "}
                  <strong>30 days left</strong> to use Sentro Advanced
                </div>
              )
            })
          );
        }, 2000);

        let existing = sessionStorage.getItem("subscription");
        existing = existing ? JSON.parse(existing) : {};
        existing["show_banner"] = false;
        sessionStorage.setItem("subscription", JSON.stringify(existing));
      }
    } catch {
      dispatch(
        showAlert({
          type: "error",
          message: "An error occured. Please try again"
        })
      );
    }
  };

  return (
    <>
      {isMobile ? (
        <div className="px-4">
          <div className="d-flex col-12 border-bottom">
            <div className="col-6 p-0 py-4 d-flex justify-content-center">
              <div
                className={`${classes.chip} ${
                  selected === 0 ? classes.basicSelected : classes.basicChip
                }`}
                onClick={() => setSelected(0)}
              >
                Basic
              </div>
            </div>
            <div className="col-6 p-0 py-4 d-flex justify-content-center">
              <div>
                <div className="d-flex justify-content-center">
                  <div
                    className={`${classes.chip} ${
                      selected === 1
                        ? classes.advancedSelected
                        : classes.advancedChip
                    }`}
                    onClick={() => setSelected(1)}
                  >
                    Advanced
                  </div>
                </div>
                <div
                  style={{ fontSize: 20, fontWeight: 600, color: "#2b2d32" }}
                  className="text-center py-2"
                >
                  <span style={{ fontWeight: 300 }}>₱</span> 600.00{" "}
                  <span style={{ fontSize: 14, fontWeight: 300 }}>/mo</span>
                </div>
                <div
                  style={{ fontSize: 12, color: "#54575f" }}
                  className="text-center"
                >
                  (₱ 1,800.00 one time payment for 3 months, subject to VAT)
                </div>
              </div>
            </div>
          </div>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={selected}
            onChangeIndex={handleChange}
          >
            <TabPanel value={0} index={0} dir={theme.direction}>
              {featuresList.map((item, key) => (
                <div className="col-12 pl-0 py-4 d-flex justify-content-between align-items-center border-bottom">
                  <div style={{ fontSize: 14, color: "#2b2d32" }}>
                    {item.feature}{" "}
                    <CustomTooltip
                      arrow
                      title={item.tooltip}
                      open={
                        key === tooltipIsOpen.index &&
                        tooltipIsOpen.open &&
                        selected === 0
                      }
                      placement="top"
                    >
                      <ErrorOutlineOutlinedIcon
                        color="secondary"
                        className="ml-2"
                        style={{ fontSize: 16 }}
                        onClick={() =>
                          setTooltipIsOpen({
                            open: !tooltipIsOpen.open,
                            index: key
                          })
                        }
                      />
                    </CustomTooltip>
                  </div>
                  {item.basic}
                </div>
              ))}
            </TabPanel>
            <TabPanel value={1} index={1} dir={theme.direction}>
              {featuresList.map((item, key) => (
                <div className="col-12 pl-0 py-4 d-flex justify-content-between align-items-center border-bottom">
                  <div style={{ fontSize: 14, color: "#2b2d32" }}>
                    {item.feature}{" "}
                    <CustomTooltip
                      arrow
                      title={item.tooltip}
                      open={
                        key === tooltipIsOpen.index &&
                        tooltipIsOpen.open &&
                        selected === 1
                      }
                      placement="top"
                    >
                      <ErrorOutlineOutlinedIcon
                        color="secondary"
                        className="ml-2"
                        style={{ fontSize: 16 }}
                        onClick={() =>
                          setTooltipIsOpen({
                            open: !tooltipIsOpen.open,
                            index: key
                          })
                        }
                      />
                    </CustomTooltip>
                  </div>
                  {item.advanced}
                </div>
              ))}
            </TabPanel>
          </SwipeableViews>
          <div className="d-flex justify-content-center">
            <div className="py-4 d-flex justify-content-center">
              <div>
                {subscription && !subscription.free_trial_availed ? (
                  <>
                    <Button
                      disableElevation
                      color="primary"
                      variant="contained"
                      style={{ height: 40 }}
                      onClick={handleFreeTrial}
                    >
                      Start free trial
                    </Button>
                    <div
                      className="text-center py-2"
                      style={{ fontSize: 12, color: "#54575f" }}
                    >
                      or
                    </div>

                    <Link
                      color="primary"
                      style={{ fontSize: 12 }}
                      onClick={() =>
                        buxWallet.kyc_level >= 1 && codWallet
                          ? history.push(Path.SUBSCRIPTION)
                          : setOpen(true)
                      }
                    >
                      Sign up for Advanced
                    </Link>
                  </>
                ) : (
                  ""
                )}

                <div className=" d-flex align-items-center justify-content-center">
                  {subscription && subscription.free_trial_availed ? (
                    <Button
                      disableElevation
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        buxWallet.kyc_level >= 1 && codWallet
                          ? history.push(Path.SUBSCRIPTION)
                          : setOpen(true)
                      }
                    >
                      Subscribe to Advanced
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="col-12 p-0 d-flex mt-4 border-bottom">
            <div className="col-6 d-flex align-items-center justify-content-center">
              {subscription && subscription.free_trial_availed ? (
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    buxWallet.kyc_level >= 1 && codWallet
                      ? history.push(Path.SUBSCRIPTION)
                      : setOpen(true)
                  }
                >
                  Subscribe to Advanced
                </Button>
              ) : (
                ""
              )}
            </div>
            <div
              className="col-3 d-flex justify-content-center px-3 py-4"
              style={{ backgroundColor: "#f7fbfe" }}
            >
              <div>
                <Chip
                  id="basic"
                  label="Basic"
                  className={classes.chip}
                  style={{ backgroundColor: "#1e4b83" }}
                />
                <div
                  style={{ fontSize: 20, fontWeight: 600, color: "#2b2d32" }}
                  className="text-center pt-3 pb-5"
                >
                  FREE
                </div>
              </div>
            </div>
            <div className="col-3 d-flex justify-content-center px-3 py-4">
              <div>
                <div className="d-flex justify-content-center">
                  <Chip
                    id="advanced"
                    label="Advanced"
                    className={classes.chip}
                    style={{ backgroundColor: "#ff6b55" }}
                  />
                </div>
                <div
                  style={{ fontSize: 20, fontWeight: 600, color: "#2b2d32" }}
                  className="text-center py-2"
                >
                  <span style={{ fontWeight: 300 }}>₱</span> 600.00{" "}
                  <span style={{ fontSize: 14, fontWeight: 300 }}>/mo</span>
                </div>
                <div
                  style={{ fontSize: 12, color: "#54575f" }}
                  className="text-center"
                >
                  (₱ 1,800.00 one time payment for 3 months, subject to VAT)
                </div>
              </div>
            </div>
          </div>
          {featuresList.map((item, key) => (
            <div className="col-12 p-0 d-flex border-bottom">
              <div
                className="col-6 pl-0 py-4 d-flex align-items-center"
                style={{ fontSize: 14, color: "#2b2d32" }}
              >
                {item.feature}
                <CustomTooltip
                  arrow
                  title={item.tooltip}
                  open={key === tooltipIsOpen.index && tooltipIsOpen.open}
                  placement="top"
                >
                  <ErrorOutlineOutlinedIcon
                    color="secondary"
                    className="ml-3"
                    style={{ fontSize: 16 }}
                    onClick={() =>
                      setTooltipIsOpen({
                        open: !tooltipIsOpen.open,
                        index: key
                      })
                    }
                  />
                </CustomTooltip>
              </div>
              <div
                className="col-3 py-4 d-flex align-items-center justify-content-center"
                style={{ backgroundColor: "#f7fbfe" }}
              >
                {item.basic}
              </div>
              <div className="col-3 py-4 d-flex align-items-center justify-content-center">
                {item.advanced}
              </div>
            </div>
          ))}
          {subscription && !subscription.free_trial_availed ? (
            <div className="col-12 p-0 d-flex">
              <div className="col-6"></div>
              <div
                className="col-3 p-4"
                style={{ backgroundColor: "#f7fbfe" }}
              ></div>
              <div className="col-3 px-3 py-5 d-flex justify-content-center">
                <div>
                  {!subscription.free_trial_availed ? (
                    <>
                      <Button
                        id="btnTrial"
                        disableElevation
                        color="primary"
                        variant="contained"
                        style={{ height: 40 }}
                        onClick={handleFreeTrial}
                      >
                        Start free trial
                      </Button>
                      <div
                        className="text-center py-2"
                        style={{ fontSize: 12, color: "#54575f" }}
                      >
                        or
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <Link
                    color="primary"
                    style={{ fontSize: 12 }}
                    onClick={() =>
                      buxWallet.kyc_level >= 1 && codWallet
                        ? history.push(Path.SUBSCRIPTION)
                        : setOpen(true)
                    }
                  >
                    Sign up for Advanced
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}
