import React from "react";
import { makeStyles } from "@material-ui/styles";
import SunEditor from "suneditor-react";

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    "& p": {
      textAlign: "right"
    }
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)",
    margin: 0
  },
}));

const RichTextField = ({
  input,
  label,
  maxLength,
  minLength,
  multiline,
  cut,
  noHelpText,
  id,
  meta: { asyncValidating, touched, error },
  ...custom
}) => {
  const classes = useStyles();
  const showHelperText = custom.showHelperText === undefined ? true: custom.showHelperText 
  return (
    <div className="w-100" id={id}>
      <div className={classes.label3}>{label}</div>
      <SunEditor
        setContents={input.value}
        {...input}
        setOptions={{
          buttonList: [
            [
              "font",
              "fontSize",
              "formatBlock",
              "align",
              "list"
            ],
            ["bold", "underline", "italic"],
            ["fontColor", "hiliteColor"],
            ["outdent", "indent"]
          ],
          height: 200,
          width: "100%",
          charCounter: true,
          maxCharCount: 500
        }}
      />
      {
        custom.inputProps && showHelperText ? 
          custom.inputProps.maxlength ? 
            <div className="align-right">
              <p>`${input.value ? input.value.length: "0"}/${custom.inputProps.maxlength}`</p>
            </div>
          : ""
        : ""
      }
    </div>
  );
};

export default RichTextField;
