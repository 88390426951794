import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Button
} from "@material-ui/core";

import Blank from "../blank";
import PreviewProductCard from "../PreviewProductCard";
import defaultSRC from "../defaultResources";
import defaultColor from "../colors";
const PreviewProductTemplate = props => {
  let color = props.store.color === "null" ? null : props.store.color;
  let selectedColor = props.store
    ? color
      ? color.includes("/")
        ? defaultColor[props.store ? color : "funBlue"].colorA
        : defaultColor[props.store ? color : "funBlue"].color
      : defaultSRC[props.store ? props.store.template : "Classic"].appColor
    : defaultSRC[props.store ? props.store.template : "Classic"].appColor;

  const sampleData = [
    {
      name: "Product 1",
      price: 100.0,
      gallery: []
    },
    {
      name: "Product 2",
      price: 100.0,
      gallery: []
    },
    {
      name: "Product 3",
      price: 100.0,
      gallery: []
    }
  ];

  function setProductTemplate() {
    switch (props.store ? props.store.template : "Blank") {
      case "Blank":
        return <Blank />;
      case "Classic":
        const ProductStyles1 = makeStyles(theme => ({
          root: {
            paddingTop: 80,
            minHeight: "90vh",
            [theme.breakpoints.down("md")]: {
              minHeight: 0
            },
            [theme.breakpoints.down("sm")]: {
              minHeight: 0
            },
            [theme.breakpoints.down("xs")]: {
              minHeight: 0
            }
          },
          content1: {
            height: "100vh",
            width: "100%"
          },
          productCard: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              justifyContent: "center"
            }
          }
        }));

        const classes1 = ProductStyles1();
        return (
          <Box className={classes1.root}>
            <Box className={classes1.products}>
              <Container>
                <Box>
                  <Typography
                    style={{
                      fontSize: 36,
                      textAlign: "center",
                      color: selectedColor,
                      fontFamily: "Georgia"
                    }}
                  >
                    Products
                  </Typography>
                  <Box className={classes1.productCard}>
                    {props.productList && props.productList.length > 0
                      ? props.productList
                          .filter(item => item.is_active)
                          .map((item, label) => {
                            return (
                              <PreviewProductCard
                                storeInfo={props.storeInfo}
                                item={item}
                                key={label}
                                onSelect={value => props.onSelect(value)}
                              />
                            );
                          })
                      : sampleData.map((item, label) => {
                          return <PreviewProductCard item={item} />;
                        })}
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        );
      case "Contemporary":
        const ProductStyles2 = makeStyles(theme => ({
          root: {
            paddingTop: 80,
            minHeight: "90vh",
            [theme.breakpoints.down("md")]: {
              minHeight: 0
            },
            [theme.breakpoints.down("sm")]: {
              minHeight: 0
            },
            [theme.breakpoints.down("xs")]: {
              minHeight: 0
            }
          },
          content1: {
            height: "100vh",
            width: "100%"
          },
          productCard: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              justifyContent: "center"
            }
          }
        }));

        const classes2 = ProductStyles2();
        return (
          <Box className={classes2.root}>
            <Box className={classes2.products}>
              <Container>
                <Box>
                  <Container>
                    <Typography
                      style={{
                        fontSize: 36,
                        color: selectedColor,
                        fontFamily: "Oswald",
                        textAlign: "center"
                      }}
                    >
                      Products
                    </Typography>
                  </Container>
                  <Box className={classes2.productCard}>
                    {props.productList && props.productList.length > 0
                      ? props.productList
                          .filter(item => item.is_active)
                          .map((item, label) => {
                            return (
                              <PreviewProductCard
                                item={item}
                                key={label}
                                onSelect={value => props.onSelect(value)}
                              />
                            );
                          })
                      : sampleData.map((item, label) => {
                          return <PreviewProductCard item={item} />;
                        })}
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        );
      case "Minimalist":
        const ProductStyles3 = makeStyles(theme => ({
          root: {
            paddingTop: 80,
            minHeight: "90vh",
            [theme.breakpoints.down("md")]: {
              minHeight: 0
            },
            [theme.breakpoints.down("sm")]: {
              minHeight: 0
            },
            [theme.breakpoints.down("xs")]: {
              minHeight: 0
            }
          },
          content1: {
            height: "100vh",
            width: "100%"
          },
          productCard: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              justifyContent: "center"
            }
          }
        }));
        const classes3 = ProductStyles3();
        return (
          <Box className={classes3.root}>
            <Box className={classes3.products}>
              <Container>
                <Box>
                  <Typography
                    style={{
                      fontSize: 36,
                      textAlign: "center",
                      color: selectedColor,
                      fontFamily: "Ubuntu"
                    }}
                  >
                    Products
                  </Typography>
                  <Box className={classes3.productCard}>
                    {props.productList && props.productList.length > 0
                      ? props.productList
                          .filter(item => item.is_active)
                          .map((item, label) => {
                            return (
                              <PreviewProductCard
                                item={item}
                                key={label}
                                onSelect={value => props.onSelect(value)}
                              />
                            );
                          })
                      : sampleData.map((item, label) => {
                          return <PreviewProductCard item={item} />;
                        })}
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        );
    }
  }

  return (
    <div>
      {setProductTemplate(props.store ? props.store.template : "Blank")}
    </div>
  );
};
export default PreviewProductTemplate;
