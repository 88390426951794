import React from "react";
import Content from "./content";
import useStyles from "./contentStyles";
import SideMenu from "../dashboard/sideMenu";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import apiConfig from "../../common/apiConfig";
const Announcements = props => {
  const classes = useStyles();
  const loading = useSelector(state => state.webBuilder.loading);
  const getLanguage = localStorage.getItem("language");
  const [language, setLanguage] = React.useState(getLanguage);
  return (
    <div style={{ display: "flex", backgroundColor: "#FAFAFA" }}>
      <div className={classes.content}>
        <Content />
      </div>
    </div>
  );
};

export default Announcements;
