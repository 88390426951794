import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ImageUploader from "react-images-upload";
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = theme => ({
  uploader: {
    "& .uploadPictureContainer": {
      margin: '3% !important'
    },
    "& .deleteImage": {
      background: '#f97663',
      fontSize: '12px',
      lineHeight: '27px',
      width: '25px',
      height: '25px'
    },
  },
  logoUploadWrapper: {
    width: 200,
    margin: '0 auto',
    position: 'relative'
  },
  label: {
    fontSize: 14,
    marginBottom: 0,
    paddingBottom: "1em",
    fontWeight: 'bold',
    color: 'gray',
    height: 0
  },
  previewWrapper: {
    overflowX: "auto"
  },
  flex: {
    display: "flex"
  },
  imageDiv: {
    margin: "12px",
    borderRadius: 10,
    position: "relative",
    "& .deleteIcon": {
      position: "absolute",
      transition: "all 0.2s ease",
      top: 0,
      right: 0,
      color: '#f97663',
      fontSize: 20,
      transform: 'translate(-20%, 19%)',
      zIndex: 2,
      pointer: "cursor",
      transition: "all 0.2s ease",
      backgroundColor: "white",
      padding: 1,
      borderRadius: "50%"
    },
    "& .deleteIcon:not(.disabled):hover": {
      transform: "scale(1.2) translate(-20%, 19%)"
    },
    "& .disabled": {
      color: "gray"
    }
  },
  delButton: {
    transform: 'translateY(20px) translateX(-10px)',
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: 0,
    fontSize: 20,
    transition: "all 0.2s ease",
    color: '#f97663',
    "& .deleteIcon": {
      position: "absolute",
      transition: "all 0.2s ease",
      top: 0,
      right: 0,
      color: '#f97663',
      fontSize: 20,
      transform: 'translate(-20%, 19%)',
      zIndex: 2,
      pointer: "cursor",
      transition: "all 0.2s ease",
      backgroundColor: "white",
      padding: 1,
      borderRadius: "50%"
    },
    "& .deleteIcon:not(.disabled):hover": {
      transform: "scale(1.2) translate(-20%, 19%)"
    },
    "& .disabled": {
      color: "gray"
    }
  }
});

class GalleryImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      pictures: [], 
      loaded: false
    };
    this.onDrop = this.onDrop.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.deleteSingleImage = this.deleteSingleImage.bind(this);
  }

  onDrop(pictureFiles, pictureDataURLs) {
    let pictures = [...new Set([...this.state.pictures ,...pictureFiles])];
    this.setState({
      pictures
    }, () => {
      this.props.onChange(pictures)
    });

    if(this.props.singleImage && pictureFiles[0]){
      const logo = pictureFiles[0]
      const blob = new Blob([logo], { type: logo.type });
      let blobUrl = URL.createObjectURL(blob);
      this.setState({preview: blobUrl})
    }
  }
  
  componentWillUpdate = (props) => {
    // set gallery props as initial pictures
    if (props.gallery && !this.state.loaded && props.isEdit){
        this.setState({
          pictures: props.gallery,
          loaded: true
        })
    }
  }

  onDelete(index){
    const pictures = this.state.pictures;
      pictures.splice(index, 1);
      this.setState({
        pictures
      })
  }

  deleteSingleImage(){
    this.setState({
      pictures: [],
      preview: []
    })
    this.props.onChange([])
  }
  

  render() {
    const { classes, preview, singleImage, uploaded, name, isRequired  } = this.props;
    const logoPreview = this.state.preview || preview;
    const fileDivStyle = {
      backgroundPosition: 'center',
      backgroundImage: `url('${logoPreview}')`,
      backgroundSize: 'cover',
      boxShadow: 'none',
      height: 200
    }
    const hasLogoPreview = singleImage ? this.state.preview || preview : false;
    const buttonStyle = {
      backgroundColor: "#1c1e2b3d",
    }
    const extraProps = {
      defaultImages:  
      preview || !uploaded ? !singleImage ? preview : [preview] 
      : 
      ``
    }
    return (
      <div className={singleImage ? classes.logoUploadWrapper : ``}>
        {
          singleImage && !isRequired && logoPreview ? 
          logoPreview.length > 0 && <CancelIcon className={`${classes.delButton} deleteIcon`} onClick={this.deleteSingleImage} />: `` 
        }
        <ImageUploader
          {...extraProps}
          name={name}
          fileContainerStyle={hasLogoPreview ? fileDivStyle: {}}
          buttonStyles={hasLogoPreview ? buttonStyle: {}}
          className={classes.uploader}
          withIcon={true}
          buttonText={singleImage ? `Upload image` : `Choose images`}
          onChange={(files, url) => this.onDrop(files, url)}
          imgExtension={[".jpg", ".gif", ".png", ".gif"]}
          maxFileSize={5242880}
          withPreview={false}
          withIcon={false}
          label={singleImage ? `` : `Upload multiple images`}
          labelClass={classes.label}
          singleImage={singleImage}
        />
        {
          !singleImage ?
          <div className={classes.previewWrapper}>
            <div className={classes.flex}>
              {
                this.state.pictures ? 
                this.state.pictures.map((image, index) => {
                  let url = image;
                  if(typeof(image) === "object"){
                    const blob = new Blob([image], { type: image.type });
                    url = URL.createObjectURL(blob);
                  }
                  return (
                    <div className={classes.imageDiv} key={index}>
                      <CancelIcon className={`deleteIcon`} 
                      onClick={() => this.onDelete(index)}/>
                      <img src={url} height="120"></img>
                    </div>
                  )
                })
                :
                ``
              }
            </div>
          </div>
          :
          ``
        }
      </div>
    );
  }
}


export default withStyles(useStyles)(GalleryImageUpload)
