import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { useDispatch } from "react-redux";
import StoreActions from "../StoreActions";
import StoreAlertBox from "../StoreAlertBox";
export default function VerifyEmail(props) {
  const useStyles = makeStyles(theme => ({
    content: {
      padding: "2em 32vw",
      height: "100vh",
      [theme.breakpoints.up("xl")]: {
        padding: "2em 38vw"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "1em 6vw"
      }
    },
    header: {
      marginTop: "3vh",
      fontSize: 28,
      fontWeight: 600,
      color: "#2b2d32"
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  async function handleResend() {
    let res = await dispatch(StoreActions.resend());
    try {
      if (res.status === 200) {
        props.setShowAlert({
          open: true,
          alertType: "success",
          alertMessage: "Email sent. Please check your inbox."
        });
      }
    } catch (e) {
      props.setShowAlert({
        open: true,
        alertType: "error",
        alertMessage: "An error occured. Please try again."
      });
    }
  }
  return (
    <div className={classes.content}>
      <div className={classes.header}>Account Verification</div>
      <div className="my-2">
        <StoreAlertBox alert={props.showAlert} />
      </div>
      <div className="my-3">
        <div style={{ fontSize: 16, color: "#2b2d32" }}>
          We’re almost there! Kindly check your e-mail to get the activation
          link. If you did not get the e-mail within a few minutes, check your
          spam folder.
        </div>
        <div className="mt-5">
          <div style={{ fontSize: 14 }}>
            Did not receive the email?{" "}
            <Link onClick={handleResend} style={{ color: "#54575f" }}>
              Resend
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
