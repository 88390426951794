import React from "react";
import { makeStyles, Box, useTheme, useMediaQuery } from "@material-ui/core";
import "./index.css";
import history from "../../../../store/history";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import StoreActions from "./StoreActions";
import Footer from "./Footer";
import FeaturedTemplate from "./featuredProducts/featuredTemplate";
import defaultSRC from "./defaultResources";
import Contact from "./contact";
import PreviewBanner from "./PreviewBanner";
import Header from "./AppBar";
import PreviewAboutTemplate from "./aboutus/previewAboutTemplate";
import DocumentMeta from "react-document-meta";
import apiConfig from "../../../../common/apiConfig";
import PromoBannerModal from "./PromoBanner12.12";
import StoreLoading from "./Loading";
import HomeContent from "./HomeContent";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const Home = props => {
  const ProductStyles = makeStyles(theme => ({
    content: {
      height: "100vh",
      width: "100%"
    },
    productCard: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      margin: "0 63px",
      [theme.breakpoints.down("sm")]: {
        margin: "0"
      }
    },
    errorMessage: {
      color: "#fff",
      position: "absolute",
      marginTop: "10vh",
      textAlign: "center",
      backgroundColor: "#909196",
      padding: "20px 196px",
      [theme.breakpoints.down("sm")]: {
        padding: 20,
        marginTop: "6vh"
      }
    }
  }));

  const classes = ProductStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const largeDesktopView = useMediaQuery(theme.breakpoints.up("xl"));
  const store_theme = useSelector(state => state.store.store.color);

  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold"
      },
      fontFamily: ["Inter", "sans-serif"]
    }
  });
  React.useEffect(() => {
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(props.id));
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (storeInfo.is_published == false) {
      history.push("/not-found");
    }
  });

  React.useEffect(() => {
    dispatch(StoreActions.maintenance);
  });

  let storeInfo = useSelector(state => state.store.store);
  let productList = useSelector(state => state.store.products);

  function onSelect(value) {
    dispatch(StoreActions.getSelectedProduct(value));
    history.push(`/shop/${props.id}/viewproduct?uuid=${value.uuid}`);
    window.scrollTo(0, 0);
  }

  let errorMaintenance = useSelector(state => state.store.errorMaintenance);
  let loading = useSelector(state => state.store.loading);
  const meta = {
    title: `${storeInfo ? storeInfo.name : ""} | Sentro`,
    description: `${storeInfo ? storeInfo.description : ""} | Sentro`,
    canonical: `${apiConfig.localHost}shop/${storeInfo ? storeInfo.site : ""}`,

    meta: {
      charset: "utf-8",
      property: {
        "og:title": `${storeInfo ? storeInfo.name : ""} | Sentro`,
        "og:url": `${apiConfig.localHost}shop/${
          storeInfo ? storeInfo.site : ""
        }`,
        "og:type": "website",
        "og:image": `${storeInfo ? storeInfo.logo_image : ""}`,
        "og:description": storeInfo ? storeInfo.description : "",
        "twitter:card": "summary_large_image",
        "twitter:title": `${storeInfo ? storeInfo.name : ""} | Sentro`,
        "twitter:description": `${
          storeInfo ? storeInfo.description : ""
        } | Sentro`,
        "twitter:image": `${storeInfo ? storeInfo.logo_image : ""}`
      }
      // name: {
      //   keywords: "react,meta,document,html,tags",
      // },
    }
  };

  return (
    <DocumentMeta {...meta}>
      <StoreLoading open={loading} />
      <div
        style={{
          backgroundColor:
            defaultSRC[storeInfo ? storeInfo.template : "Classic"].background
        }}
      >
        <Box>
          <ThemeProvider theme={store_generated_theme}>
            {storeInfo && storeInfo.font.css_url ? (
              <link href={storeInfo.font.css_url} rel="stylesheet"></link>
            ) : (
              ""
            )}
            <HomeContent
              storeInfo={storeInfo}
              onSelect={onSelect}
              productList={productList}
              largeDesktopView={largeDesktopView}
              errorMaintenance={errorMaintenance}
              classes={classes}
            />
          </ThemeProvider>
        </Box>
      </div>
    </DocumentMeta>
  );
};

export default Home;
