import React from "react";

export default function SpinnerLoading(props) {
  return (
    <div className="d-flex my-5 mr-3">
      <div
        className="spinner-border mx-auto"
        role="status"
        style={{
          color: "white",
          fontSize: 12,
          height: 25,
          width: 25
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
