import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../layout";
import Content from "./content";
import { Alert } from "antd";
import { closeAlert } from "../AccountSettingsAction";
const Password = props => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(true);
  const [showEditPassword, setShowEditPassword] = React.useState(false);
  const handlePassword = () => {
    setShowPassword(true);
    setShowEditPassword(false);
  };
  const handleEditPassword = () => {
    setShowEditPassword(true);
    setShowPassword(false);
  };
  let alert = useSelector(state => state.user.showAlert);
  let message = useSelector(state => state.user.message);
  let type = useSelector(state => state.user.type);

  if (alert) {
    setTimeout(() => {
      dispatch(closeAlert());
    }, 3000);
  }

  return (
    <Layout>
      <div style={{ display: "block" }}>
        {alert ? (
          <div style={{ marginTop: 15 }}>
            <Alert id="alert" type={type} message={message} />
          </div>
        ) : (
          ""
        )}
        <Content
          handlePassword={handlePassword}
          handleEditPassword={handleEditPassword}
          showPassword={showPassword}
          showEditPassword={showEditPassword}
        />
      </div>
    </Layout>
  );
};

export default Password;
