const errorMessage = {
  forgotPassword:
    "You seem to have forgotten your password. Redirecting you to the reset password page.",
  invalidEmailorPass: "Invalid email or password. Please try again.",
  registeredEmail: "Email is already registered",
  errorVerification: "Wrong verification code. Please check your email.",
  networkError: "Network Error",
  networkErrorMessage: "An error has occured. Please try again."
};

export default errorMessage;
