import React from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import history from "../../../../../../store/history";
import Path from "../../../../../../Paths";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDialog,
  getStore,
  closeCreateShopDialog
} from "../websiteBuilderActions";
import sentroLogo from "../../../../../../assets/images/sentro_logomark3.png";
const CreateShopSuccess = props => {
  const { open, handleAddProduct } = props;

  const useStyles = makeStyles(theme => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content"
    },
    content: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    icon: {
      width: "6em",
      display: "flex",
      margin: "10px auto"
    },
    name: {
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42"
    },
    site: {
      fontSize: 14,
      color: "#54575d"
    },
    btnProduct: {
      height: 50
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  let openModal = useSelector(state => state.mobileDashboard.successCreateShop);
  let storeInfo = useSelector(state => state.mobileDashboard.storeInfo);
  let storeName = storeInfo ? storeInfo.name : "";
  let storeLink = storeInfo ? storeInfo.site : "";
  function handleProducts() {
    dispatch(closeCreateShopDialog());
    handleAddProduct();
  }

  function handleCloseModal() {
    dispatch(closeDialog());
    dispatch(getStore());
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={openModal}>
      <DialogTitle
        style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        {/* <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton> */}
      </DialogTitle>
      <DialogContent style={{ padding: 20 }}>
        <div className={classes.form}>
          <img src={sentroLogo} className={classes.icon} />
          <div style={{ textAlign: "center" }}>
            <div className={classes.name}>{storeName}</div>
            <div className={classes.site}>{storeLink}.sentro.ph</div>

            <div
              style={{ marginTop: 30, padding: "0 15px" }}
              className={classes.site}
            >
              You have successfully registered your Sentro online shop
            </div>

            <div
              style={{ marginTop: 30, padding: "0 15px" }}
              className={classes.site}
            >
              Now, let's add at least one product so you can launch it.
            </div>
          </div>
          <div style={{ marginTop: 40 }}>
            <Button
              disableElevation
              fullWidth
              variant="contained"
              color="primary"
              className={classes.btnProduct}
              onClick={handleProducts}
            >
              Add Product
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default CreateShopSuccess;
