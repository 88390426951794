import React from "react";
import {
  Dialog,
  Button,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { useSelector } from "react-redux";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function TemplateConfirmationDialog(props) {
  const { open, setOpen, handleUseTemplate } = props;
  const loading = useSelector(
    state => state.webBuilder.loading || state.mobileDashboard.loading
  );

  const handleClose = () => {
    setOpen({ ...open, dialog: false });
  };
  return (
    <Dialog
      open={open.dialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle style={{ padding: 24 }}>
        <strong>Do you want to apply this template to your online shop?</strong>
      </DialogTitle>
      <DialogContent>
        <div style={{ fontSize: 14 }}>
          Colors, fonts, and images can be customized to match your brand.
        </div>
      </DialogContent>
      <DialogActions
        style={{
          padding: 24,
          display: "flex",
          justifyContent: "center",
          overflow: "hidden"
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          style={{ width: "50%", margin: 5 }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          style={{ width: "50%", margin: 5 }}
          onClick={handleUseTemplate}
        >
          {loading ? (
            <div className="d-flex my-5 mr-3">
              <div
                className="spinner-border mx-auto"
                role="status"
                style={{
                  color: "white",
                  fontSize: 10,
                  height: 20,
                  width: 20
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}
          Use template
        </Button>
      </DialogActions>
    </Dialog>
  );
}
