import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ACL from "../common/utilities/ACL"
import Action from "./Redux/Actions"
import AdminDashboardLayout from "./layout"
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import apiConfig from "../../common/apiConfig";
import Path from "../../Paths";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TableExport from '../common/utilities/TableExport';

function descendingComparator(a, b, orderBy) {
if (b[orderBy] < a[orderBy]) {
	return -1;
}
if (b[orderBy] > a[orderBy]) {
	return 1;
}
return 0;
}

function getComparator(order, orderBy) {
return order === 'desc'
	? (a, b) => descendingComparator(a, b, orderBy)
	: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: 'first_name', numeric: false, disablePadding: true, label: 'Name' },
	{ id: 'email', numeric: false, disablePadding: false, label: 'Email Address' },
	{ id: 'stores', numeric: false, disablePadding: false, label: 'Name of Shops' },
	{ id: 'id', numeric: false, disablePadding: false, label: 'Member Since' },
	{ id: 'last_login', numeric: false, disablePadding: false, label: 'Last Login' },
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	const tableHeadCell = (headCell) => (
		<TableCell
			className={classes.tableHead}
			key={headCell.id}
			align={headCell.numeric ? 'right' : 'left'}
			style={{ paddingLeft: "1em" }}
			sortDirection={orderBy === headCell.id ? order : false}
		>
			<TableSortLabel
				active={orderBy === headCell.id}
				direction={orderBy === headCell.id ? order : 'asc'}
				onClick={createSortHandler(headCell.id)}
				hideSortIcon={true}
				active={false}
			>
				<span>{headCell.label}</span>
				<span className={classes.headIconWrapper}>
					<ArrowDropUpIcon className={classes.headIconUp} />
					<ArrowDropUpIcon className={classes.headIconDown} />
				</span>
			</TableSortLabel>
		</TableCell>
	)

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					headCell.id === 'actions' ?
					<ACL roles={['superuser']} key={headCell.id}>
						{tableHeadCell(headCell)}
					</ACL>
					:
					tableHeadCell(headCell)
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	tableContainer: {
		overflowX: "auto"
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	noResult: {
		width: "100%",
		display: "block",
		textAlign: "center",
		padding: "2em"
	},
	
	tableHead: {
		backgroundColor: "#F97663",
		color: "white !important",
		"&:hover": {
			color: 'white !important'
		},
		"&:active": {
			color: 'white !important'
		},
	},
	headIconWrapper: {
		direction: "rtl", 
		flexGrow: 1, 
		minWidth: 65, 
		display: "flex", 
		height: 20
	}, 
	headIconUp: {
		transform: "rotate(0deg)",
		position: "absolute",
		transform: "translateY(-8px)"
	},
	headIconDown: {
		transform: "rotate(180deg)",
		position: "absolute"
	},
}));

function EnhancedTable(props) {
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [userList, setUserList] = React.useState([]);
	
	useEffect(() => {
		setUserList(props.unverifiedUserList);
	}, [props.unverifiedUserList]);

	useEffect(() => {
		const rowsCount = props.rowsPerPage ? props.rowsPerPage : 5
		setRowsPerPage(rowsCount)
	}, [props.rowsPerPage]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Fragment>
			<div className={classes.tableContainer}>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size={'medium'}
					aria-label="enhanced table"
					id="table"
				>
					<EnhancedTableHead
						classes={classes}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={userList.length}
					/>
					<TableBody>
						{stableSort(userList, getComparator(order, orderBy))
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((row, index) => {
							const labelId = `enhanced-table-checkbox-${index}`;

							return (
								<TableRow
									key={row.email}
									hover
									// onClick={(event) => handleClick(event, row.first_name + " " + row.last_name)}
									role="checkbox"
									tabIndex={-1}
								>
									<TableCell component="th" id={labelId} scope="row"
										style={{ marginLeft: "1em"}}
									>	
										{`${row.first_name} ${row.last_name}`}
									</TableCell>
									<TableCell align="left">{row.email}</TableCell>
									<TableCell align="left">
										{row.stores.map((shop) => (
											<a key={shop.site} href={`${apiConfig.localHost}shop/${shop.site}`} target="_blank">
												{shop.name}
											</a>
										))}
									</TableCell>
									<TableCell align="left">
										{row.created_on}
									</TableCell>
									<TableCell align="left">
										{row.last_login}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				{
					userList.length ?
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={userList.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
					:
					<div style={{ height: "40vh", width: "100%", overflow: "hidden" }}>
						<div style={{ paddingTop: "4em" }}>
							<div style={{
								display: "flex",
								justifyContent: "center"
							}}>
								<img
									style={{width: "7em", height: "6em"}}
									src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
								/>
							</div>
							<div style={{
									display: "flex",
									justifyContent: "center",
									marginTop: 40,
									fontSize: 18,
									fontWeight: 600,
									color: "#b8bcc3",
									padding: "0 3em",
									textAlign: "center"
							}}>
								Nothing to Show
							</div>
						</div>
					</div>
				}
			</div>
		</Fragment>
	);
}

const permissionStyles = theme => ({
	paper: {
		overflow: "hidden",
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(1),
		},
	},
	searchInputWrapper: {
		display: "flex"
	},
	searchInput: {
		flexGrow: 1
	},
	tableWrapper: {
		overflowX: "auto"
	},
	errorMessage: {
		color: "red"
	},
	primaryColor: {
		color: theme.palette.text.primary
	},
	dFlex: {
		display: "flex"
    },
	pageHeader: {
		backgroundColor: "#F1F1F1",
		display: "flex !important",
		padding: "1.5em 1.5em 1.5em",
		alignItems: "center",
		fontWeight: 600,
		fontSize: 14,
		color: "#F97663 !important"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	searchBar: {
		background: "white",
		maxWidth: "240px",
		borderRadius: "4px",
		color: "gray",
		fontSize: 12,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "100%",
			width: "100%"
		},
	},
	excelExportButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		border: "solid 1px #F97663",
		background: " #F97663",
		borderRadius: 4,
		marginRight: 10,
		color: "white",
		transition: "all 0.3s linear",
		"&:hover": {
			background: "#f96a63"
		},
		"&:focus": {
			outline: 0
		},
		boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
	},
	headerActionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		},
	}
});

function IsInArray(array, lookup){
	if(array.length){
		for(let i in array){
			if (array[i].name.toLowerCase().indexOf(lookup) != -1){
				return true
			}
		}
	}
	return false
}

class UnverifiedUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			usersList: [],
			filteredList: [],
		};
		this.onSearch = this.onSearch.bind(this)
	}
	
	onSearch(event) {
		const val = event.target.value
		let result = this.state.usersList.filter(user => {
			const lowerCaseQueryStr = val.toLowerCase()
			return (
					user.email.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					user.first_name.toLowerCase().indexOf(lowerCaseQueryStr) != -1 ||
					user.last_name.toLowerCase().indexOf(lowerCaseQueryStr) != -1 ||
					user.created_on.toLowerCase().indexOf(lowerCaseQueryStr) != -1 ||
					user.last_login.toLowerCase().indexOf(lowerCaseQueryStr) != -1 ||
					IsInArray(user.stores, lowerCaseQueryStr)
				)
			})
		this.setState({
			filteredList: result
		})
	}

	componentDidMount = async() => {
		const { dispatch, login } = this.props
		const extra_url_param = "?unverified_users=True"
		const data = await dispatch(Action.fetchUsers(extra_url_param))
		this.setState({
			usersList: data,
			filteredList: data
		})
	}
	
	render (){
		const {
			filteredList
		} = this.state
		const { classes, adminDashboard, history } = this.props
		return (
			<AdminDashboardLayout filterHeaderHidden={true}>
				<ACL roles={["admin", "superuser"]} redirect_to="/login">
					<a className={classes.pageHeader} onClick={() => {
						history.push(Path.ADMIN_DASHBOARD)
					}}>
						<ArrowBackIcon/>
						<span style={{marginLeft: "1em"}}>{`  BACK`}</span>
					</a>
					<div style={{
						display: "flex", 
						flexWrap: "wrap",
						alignItems: "center",
						marginBottom: "1.5em",
						padding: "0 1.5em"
					}}>
						<div className={classes.pageTitle}>
							Unverified Users
						</div>
						<div className={classes.headerActionsWrapper}>
							{
								filteredList ?
								<TableExport
									class={classes.excelExportButton}
									filename="Sentro - Unverified Users"
									sheet="UnverifiedUsers"
									table={<EnhancedTable unverifiedUserList={filteredList} rowsPerPage={filteredList.length }/>}
								/>
								:
								null
							}
							<OutlinedInput
								className={classes.searchBar}
								id="outlined-adornment-weight"
								onChange={(event) => this.onSearch(event)}
								startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
									'aria-label': 'weight',
								}}
								labelWidth={0}
								margin="dense"
								placeholder="Type here to search"
							/>
						</div>
					</div>
					<div style={{ padding: "0 1.5em 1em"}}>
						{ 
							adminDashboard.error ?
							"" :
							<Paper className={classes.card}>
								<EnhancedTable unverifiedUserList={filteredList}/>
							</Paper>
						}
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(permissionStyles)(UnverifiedUsers))