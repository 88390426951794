import { actionTypes, ReduxInitializeFormActionTypes } from "./actions";
import {
  compute_subtotal,
  computeBuyerCartAmount
} from "../common/productFunctions";
import {
  computeVoucherDiscount,
  combine_same_products,
  update_quantity
} from "../../../enterprise/components/cart/common/productFunctions";
import { getPromoCode } from "../../../common/template/components/PromoBanner12.12";

const initialState = {
  loading: false,
  my_orders: [],
  selected_order: "",
  selected_order_cart: [],
  purchased_orders: [],
  purchased_tab: "To Pay",
  selected_purchased_order: "",
  cart_items: [],
  cart_amount: 0,
  voucher_discount: 0,
  voucher_list: [],
  applied_voucher: "",
  selected_checkout: {
    items: [],
    amount: "",
    subtotal: ""
  },
  delivery_locations: [],
  delivery_details: {
    loading: false,
    error: "",
    shipping: "",
    shipping_fee: 0,
    delivery_detail: "",
    mrspeedy_delivery_address: {
      address: "",
      longitude: "",
      latitude: ""
    }
  },
  payments: "",
  promo: {
    promo_code: getPromoCode(),
    bannerOpen: false,
    checked: false,
    eligible: false,
    discount: 0,
    amount_gt: 0,
    loading: false
  },
  checkout: "",
  reduxForm: {
    customerDetailsForm: "",
    address_modal: ""
  },
  addresses: [],
  selected_payment_channel: "otc"
};

export default function buyerReducers(state = initialState, action) {
  let itemToUpdate;
  let itemList = [];
  let alreadyListed = false;
  let productSelection;
  let selected_checkout_items = [];
  let selected_order_items = [];
  let selected_view_order_items = [];
  let new_items = [];
  switch (action.type) {
    case actionTypes.LOADING_ON:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADING_OFF:
      return {
        ...state,
        loading: false
      };
    case actionTypes.GET_ORDERS:
      return {
        ...state,
        my_orders: action.payload
      };
    case actionTypes.GET_SELECTED_ORDER:
      selected_view_order_items = [];
      for (let i in action.payload.items) {
        selected_view_order_items.push({
          ...action.payload.items[i],
          id: parseInt(i)
        });
      }
      return {
        ...state,
        selected_order: !selected_view_order_items.length
          ? ""
          : {
              store_data: action.payload.store_data,
              items: selected_view_order_items,
              id: action.payload.order_id
            }
      };
    case actionTypes.PROCEED_TO_CHECKOUT:
      selected_view_order_items = [];
      for (let i in action.payload.items) {
        selected_view_order_items.push({
          ...action.payload.items[i],
          id: parseInt(i)
        });
      }
      return {
        ...state,
        selected_order: { ...action.payload, items: selected_view_order_items }
      };
    case actionTypes.REMOVE_FROM_CART:
      let newState = [...state.selected_order.items];
      newState.splice(action.payload, 1);
      return {
        ...state,
        selected_order: {
          ...state.selected_order,
          items: newState
        },
        cart_amount: computeBuyerCartAmount(newState),
        voucher_discount: computeVoucherDiscount(
          state.applied_voucher,
          compute_subtotal(state.selected_checkout.items)
        )
      };
    case actionTypes.UPDATE_QUANTITY:
      itemToUpdate = action.payload.value;
      productSelection = itemToUpdate.productSelection;
      itemList = [...action.payload.selected_order_items];
      selected_checkout_items = [...state.selected_checkout.items];
      new_items = update_quantity(itemToUpdate, productSelection, itemList);

      for (let i in new_items) {
        selected_order_items.push({
          gallery: new_items[i].gallery,
          product_details: { ...new_items[i], quantity: new_items[i].stock },
          productSelection: new_items[i].productSelection,
          quantity: new_items[i].quantity,
          variant: new_items[i].variants,
          uuid: new_items[i].uuid,
          id: new_items[i].id
        });
      }
      if (selected_checkout_items.length) {
        for (let product of selected_checkout_items) {
          if (product["id"] === itemToUpdate["id"]) {
            product.quantity = itemToUpdate.quantity;
          }
        }
      }

      // for (let product of itemList) {
      //   if (product["uuid"] === itemToUpdate["uuid"]) {
      //     alreadyListed = true;
      //     product.quantity = itemToUpdate.quantity;
      //   }
      // }

      return {
        ...state,
        selected_order: {
          ...state.selected_order,
          items: selected_order_items
        },
        selected_checkout: {
          items: selected_checkout_items,
          subtotal: compute_subtotal(selected_checkout_items),
          amount:
            compute_subtotal(selected_checkout_items) +
            parseFloat(state.delivery_details.shipping_fee)
        },
        voucher_discount: computeVoucherDiscount(
          state.applied_voucher,
          compute_subtotal(state.selected_checkout.items)
        )
      };
    case actionTypes.GET_PURCHASED_ORDERS:
      return {
        ...state,
        purchased_orders: action.payload
      };
    case actionTypes.SET_PURCHASED_TAB:
      return {
        ...state,
        purchased_tab: action.payload
      };
    case actionTypes.MARK_AS_RECEIVED:
      return {
        ...state,
        selected_order: {
          ...action.payload,
          status: "Completed"
        },
        purchased_tab: "Completed"
      };
    case actionTypes.CANCEL_ORDER:
      return {
        ...state,
        selected_order: {
          ...action.payload,
          status: "Cancelled"
        },
        purchased_tab: "Cancelled"
      };
    case actionTypes.REMOVE_FROM_LIST:
      return {
        ...state,
        purchased_orders: state.purchased_orders.filter(
          order => action.payload !== order.id
        )
      };
    case actionTypes.GET_CART:
      let cart = [];

      for (let i in action.payload.cart) {
        let items = action.payload.cart[i].items;
        cart.push({
          id: parseInt(i),
          items: items,
          store_data: action.payload.cart[i].store_data,
          subtotal: compute_subtotal(items),
          num_of_items: parseFloat(
            items.reduce((total, object) => {
              return parseFloat(total) + parseFloat(object.quantity);
            }, 0)
          )
        });
      }
      let params = {
        all: action.payload.all,
        cart: cart
      };
      return {
        ...state,
        cart_items: params
      };
    case actionTypes.SME_SET_SELECTED_CHECKOUT:
      return {
        ...state,
        selected_checkout: {
          items: action.payload,
          subtotal: compute_subtotal(action.payload),
          amount:
            compute_subtotal(action.payload) +
            parseFloat(state.delivery_details.shipping_fee)
        }
      };
    case actionTypes.GET_VOUCHERS:
      return {
        ...state,

        voucher_list: action.payload.sort((a, b) => (b.is_applicable ? 1 : -1))
      };
    case actionTypes.APPLY_VOUCHER:
      return {
        ...state,
        applied_voucher: action.payload,
        voucher_discount: computeVoucherDiscount(
          action.payload,
          compute_subtotal(state.selected_checkout.items)
        )
      };
    case actionTypes.GET_PURCHASED_VIEW_ORDER:
      return {
        ...state,
        selected_purchased_order: action.payload
      };
    case actionTypes.GET_DELIVERY_LOCATIONS:
      return {
        ...state,
        delivery_locations: action.payload
      };
    case actionTypes.SET_DELIVERY_DETAILS:
      return {
        ...state,
        delivery_details: action.payload,
        selected_checkout: {
          ...state.selected_checkout,
          subtotal: compute_subtotal(state.selected_checkout.items),
          amount:
            compute_subtotal(state.selected_checkout.items) +
            parseFloat(action.payload.shipping_fee)
        }
      };
    case actionTypes.COMPUTE_SHIPPING:
      return {
        ...state,
        delivery_details: {
          ...state.delivery_details,
          loading: true
        }
      };
    case actionTypes.COMPUTE_SHIPPING_FAILED:
      return {
        ...state,

        delivery_details: {
          ...state.delivery_details,
          loading: false,
          error: action.payload
        }
      };
    case actionTypes.COMPUTE_SHIPPING_SUCCESS:
      return {
        ...state,
        delivery_details: {
          ...state.delivery_details,
          loading: false,
          shipping_fee: action.payload.shipping_fee,
          delivery_detail: action.payload.delivery_detail
        },
        selected_checkout: {
          ...state.selected_checkout,
          subtotal: compute_subtotal(state.selected_checkout.items),
          amount:
            compute_subtotal(state.selected_checkout.items) +
            parseFloat(action.payload.shipping_fee)
        }
      };
    case actionTypes.GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload
      };
    case actionTypes.SME_POST_STORE_CHECKOUT:
      return {
        ...state,
        checkout: action.payload,
        loading: false
      };
    case actionTypes.SME_BUYER_UPDATE_VARIANT:
      itemToUpdate = action.payload.productToUpdate;
      productSelection = itemToUpdate.productSelection;
      itemList = [...action.payload.selected_order_items];
      selected_checkout_items = [...state.selected_checkout.items];
      new_items = combine_same_products(
        itemToUpdate,
        productSelection,
        itemList
      );
      for (let product of new_items) {
        if (product["uuid"] === itemToUpdate["uuid"]) {
          if (itemToUpdate.has_variants && productSelection) {
            if (itemToUpdate.prevSelection) {
              if (
                product.productSelection.id === itemToUpdate.prevSelection.id
              ) {
                alreadyListed = true;
                product.variant = itemToUpdate.variant;
                product.productSelection = itemToUpdate.productSelection;
              }
            } else {
              alreadyListed = true;
              product.variant = itemToUpdate.variant;
              product.productSelection = itemToUpdate.productSelection;
            }
          }
        }
      }
      for (let i in new_items) {
        selected_order_items.push({
          gallery: new_items[i].gallery,
          product_details: { ...new_items[i] },
          productSelection: new_items[i].productSelection,
          quantity: new_items[i].quantity,
          variant: new_items[i].variants,
          uuid: new_items[i].uuid,
          id: new_items[i].id
        });
      }
      if (selected_checkout_items.length) {
        for (let product of selected_checkout_items) {
          if (product["id"] === itemToUpdate["id"]) {
            product.variant = itemToUpdate.variants;
            product.productSelection = itemToUpdate.productSelection;
          }
        }
      }
      return {
        ...state,
        selected_order: {
          ...state.selected_order,
          items: selected_order_items
        },
        selected_checkout: {
          items: selected_checkout_items,
          subtotal: compute_subtotal(selected_checkout_items),
          amount:
            compute_subtotal(selected_checkout_items) +
            parseFloat(state.delivery_details.shipping_fee)
        },
        voucher_discount: computeVoucherDiscount(
          state.applied_voucher,
          compute_subtotal(state.selected_checkout.items)
        )
      };
    case actionTypes.GET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload
      };
    case actionTypes.SET_SELECTED_PAYMENT_CHANNEL:
      return {
        ...state,
        selected_payment_channel: action.payload
      };
    case ReduxInitializeFormActionTypes.CUSTOMER_DETAILS_FORM_DATA:
      return {
        ...state,
        reduxForm: {
          ...state.reduxForm,
          customerDetailsForm: action.payload
        }
      };
    case ReduxInitializeFormActionTypes.INITIALIZE_ADDRESS_MODAL:
      return {
        ...state,
        reduxForm: {
          ...state.reduxForm,
          address_modal: action.payload
        }
      };

    default:
      return state;
  }
}
