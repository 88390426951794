import React from "react";
import {
  Container,
  Box,
  Link,
  Paper,
  Typography,
  Button,
  Modal,
  makeStyles
} from "@material-ui/core";
import { publishStore, continueBuild } from "./websiteBuilderActions";
import { reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import SentroButton from "../common/sentroButton";
import dotenv from "dotenv";
import apiConfig from "../../common/apiConfig";
import PreviewHome from "../common/template/components/PreviewHome";
import PreviewProducts from "../common/template/components/PreviewProducts";
import PreviewProductsDetails from "../common/template/components/PreviewProductDetails";
import history from "../../store/history";
import Path from "../../Paths";
import DocumentMeta from "react-document-meta";
// import Template from "../../assets/templates/templates";

dotenv.config();
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

let PublishSite = props => {
  const meta = {
    title: "Sentro | Publish your sentro shop",
    description:
      "Publish your online shop instantly and share your sentro shop link to everyone!",
    canonical: "https://sentro.ph/publish",
    meta: {
      charset: "utf-8",
      property: {
        "og:title": "Sentro | Free Online Shop Builder with Payment Gateway.",
        "og:url": "https://sentro.ph",
        "og:image":
          "https://sentro-store-bucker.s3.us-east-2.amazonaws.com/Bux.ai_Sentro+Banner-9.jpg",
        "og:description":
          "Sentro is an online shop builder with a payment gateway and logistics platform for small businesses in the Philippines.",
        "twitter:card": "summary_large_image",
        "twitter:title":
          "Sentro | Free Online Shop Builder with Payment Gateway.",
        "twitter:description":
          "Sentro is an online shop builder with a payment gateway and logistics platform for small businesses in the Philippines.",
        "twitter:image":
          "https://sentro-store-bucker.s3.us-east-2.amazonaws.com/Bux.ai_Sentro+Banner-9.jpg"
      }
      // name: {
      //   keywords: "react,meta,document,html,tags",
      // },
    }
  };
  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: "#f5f5f4",
      borderRadius: "4px",
      padding: theme.spacing(4, 6, 4)
    }
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const storeInfo = useSelector(state => state.webBuilder.storeInfo);
  if (storeInfo == null) {
    history.push(Path.DASHBOARD);
  }
  let host = apiConfig.localHost.replace("https://", "").replace("app.", "");
  let storeLink = `https://${storeInfo ? storeInfo.site : ""}.${host}`;
  let storeProductList = useSelector(state => state.webBuilder.products);

  const dispatch = useDispatch();

  function continueStore() {
    // history.push(Path.DASHBOARD);
    dispatch(continueBuild(storeInfo));
  }

  function publish() {
    dispatch(publishStore(storeInfo));

    // history.push(Path.PUBLISHED);
    // dispatch(publishName(storeDetails));
  }

  return (
    <DocumentMeta>
      <div
        style={{
          height: "100vh",
          overflow: "hidden"
          // overflowY: "scroll",
          // overflowX: "hidden"
        }}
      >
        <div
          style={{
            height: "100vh"
          }}
        >
          <Paper
            style={{
              position: "sticky",
              backgroundColor: "#FAFAFA",
              height: "43vh",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
            }}
          >
            <Container>
              <Box style={{ display: "flex", paddingTop: "12vh" }}>
                <Box style={{ flex: 6, paddingTop: 20 }}>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: 600,
                        marginBottom: 10,
                        fontSize: 16
                      }}
                    >
                      Online Shop
                    </Typography>
                    <Typography
                      variant="h4"
                      color="primary"
                      style={{
                        color: "#2B2D33",
                        fontWeight: 600,
                        fontSize: 28
                      }}
                    >
                      {storeInfo ? storeInfo.name : ""}
                    </Typography>

                    <Typography
                      style={{
                        fontSize: 16,
                        color: "#2B2D33",
                        fontWeight: 600,
                        marginTop: 10
                      }}
                    >
                      {/* {`${apiConfig.localHost}shop/${storeInfo.site}`} */}
                      {storeLink}
                    </Typography>
                  </Box>
                  <Box style={{ marginTop: "3vh" }}>
                    <Typography style={{ fontSize: 14 }}>
                      {props.language === "Filipino"
                        ? "Kung kailangan mo ng tulong, i-email mo kami sa "
                        : " If you need assistance, please send a message to"}{" "}
                      <span style={{ color: "primary", fontWeight: "bold" }}>
                        <a
                          href="mailto:support@sentro.ph"
                          target="_top"
                          style={{ color: "#F97663" }}
                        >
                          {" "}
                          support@sentro.ph
                        </a>
                      </span>
                    </Typography>
                  </Box>
                </Box>
                <Box style={{ flex: 6 }}>
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ margin: 50 }}>
                      <SentroButton
                        id="publish_button"
                        style={{ backgroundColor: "#F97663" }}
                        fullWidth
                        variant="contained"
                        onClick={() => setOpen(true)}
                        size={"large"}
                        label={"Publish"}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "1vh"
                        }}
                      >
                        <Button
                          id="back_button"
                          color="primary"
                          variant="text"
                          onClick={() => continueStore()}
                          style={{
                            color: "#F97663",
                            fontWeight: "bold"
                          }}
                        >
                          {props.language === "Filipino"
                            ? "Bumalik at mag-edit pa"
                            : "Go back to continue editing"}
                        </Button>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Paper>

          <div>
            <Box>
              <Box
                style={{
                  height: "62vh",
                  transform: `scale(0.84)`,
                  overflowX: "hidden",

                  backgroundColor: "white"
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 32,
                    fontWeight: 600
                  }}
                >
                  Home Page
                </div>
                <PreviewHome
                  style={{ marginTop: "20vh" }}
                  storeInfo={storeInfo}
                  productList={storeProductList}
                />

                <div
                  style={{
                    textAlign: "center",
                    marginTop: 30,
                    fontSize: 32,
                    color: "#F97663",
                    fontWeight: 600
                  }}
                >
                  Product Page
                </div>
                <PreviewProducts
                  storeInfo={storeInfo}
                  productList={storeProductList}
                />

                <div
                  style={{
                    textAlign: "center",
                    marginTop: 30,
                    fontSize: 32,
                    color: "#F97663",
                    fontWeight: 600
                  }}
                >
                  Product Details Page
                </div>
                <PreviewProductsDetails
                  storeInfo={storeInfo}
                  productList={storeProductList}
                />
                {/* <img src={imgThumbnail} style={{ width: 430, height: "auto" }} /> */}
              </Box>
            </Box>

            {/* // <Template storeProducts={productsPreview} storeDetails={storeDetails} /> */}
          </div>
        </div>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={() => setOpen(false)}
        >
          <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title" style={{ fontWeight: "bold" }}>
              Ready to publish your online shop?
            </h2>
            <p id="simple-modal-description">
              <div>Please confirm to continue</div>
              <Box style={{ marginTop: 30, display: " flex" }}>
                <SentroButton
                  id="back_button"
                  variant="outlined"
                  style={{
                    width: "47%",
                    border: "2px solid #F97663",
                    color: "#F97663"
                  }}
                  label="Go back"
                  onClick={() => setOpen(false)}
                />
                <SentroButton
                  id="publish_button"
                  style={{}}
                  variant="contained"
                  style={{
                    width: "47%",
                    marginLeft: 10,
                    backgroundColor: "#F97663"
                  }}
                  label="Continue publishing"
                  onClick={publish}
                />
              </Box>
            </p>
          </div>
        </Modal>
      </div>
    </DocumentMeta>
  );
};

export default PublishSite;
