import React from "react";
import {
  Box,
  FormControl,
  Container,
  RadioGroup,
  Button,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { useSelector } from "react-redux";
import SentroFileField from "../../common/sentroFileField";
import Text from "../../../common/text";
import S3Utils from "../../../utils/S3utils";
import SentroButton from "../../common/sentroButton";
import formStyles from "./formStyles";
import colors from "./colorPalette";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
let BrandingForm = props => {
  // GTM trigger
  React.useEffect(() => {
    let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    window.dataLayer.push({
      eventAction: "Step - Branding",
      eventCategory: "Store Builder",
      event: "storebuildersteptrack",
      userId: currentUser.id
    });

    // HotJar heat map
    if (typeof window.hj === "function") {
      window.hj("trigger", "storeBuilderBranding");
    }
  }, []);

  function storeInfoChanges(value, name) {
    props.setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const [loading, setLoading] = React.useState("");
  const handleChangeImage = async (value, name) => {
    if (value) {
      setLoading(name);
    }
    let data = await S3Utils.saveToDB(props.storeInfo, value, name);
    if (data) {
      setLoading("");
    }
    storeInfoChanges(data.data[name], name);
  };

  React.useEffect(() => {
    props.setIsFormEnough(props.storeInfo.logo_image);
  }, [props.storeInfo]);

  const classes = formStyles();

  return (
    <Box>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <p
          style={{ color: "#54575f", fontSize: 12, fontWeight: 500, margin: 0 }}
        >
          <sup style={{ color: "red" }}>*</sup>Required
        </p>
      </div>
      <Box>
        <FormControl style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <Text
              textType="p2_Semibold"
              content={
                props.language === "Filipino"
                  ? "Anong gusto mong itsura ng online shop mo?"
                  : "Which template do you want to use?"
              }
            />
            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>
          <div
            style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
          >
            <RadioGroup
              value={
                props.storeInfo.template ? props.storeInfo.template : "Classic"
              }
              onChange={value =>
                storeInfoChanges(value.target.value, "template")
              }
            >
              <div style={{ display: "flex" }}>
                {["Classic", "Contemporary", "Minimalist"].map((item, key) => {
                  return (
                    <SentroButton
                      id="template_button"
                      variant="outlined"
                      fullWidth
                      label={item}
                      value={item}
                      onClick={() => storeInfoChanges(item, "template")}
                      style={{
                        width: 145,
                        height: 35,
                        margin: "10px 5px",
                        borderColor: "#F97663",
                        fontSize: 14,
                        backgroundColor:
                          props.storeInfo.template === item
                            ? "#F97663"
                            : "#fff",
                        color:
                          props.storeInfo.template === item ? "#fff" : "#F97663"
                      }}
                    />
                  );
                })}
              </div>
            </RadioGroup>
          </div>
        </FormControl>
      </Box>

      <Box>
        <FormControl style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <Text textType="p1_Semibold" content="Color" />

            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <RadioGroup
              value={props.storeInfo.color ? props.storeInfo.color : ""}
              onChange={value => storeInfoChanges(value.target.value, "color")}
            >
              <div style={{ display: "block" }}>
                <div style={{ display: "flex" }}>
                  {colors.slice(0, 8).map((item, key) => {
                    return (
                      <div>
                        <CheckIcon
                          style={{
                            display: "flex",
                            margin: "3px auto",
                            fontSize: 16,
                            color:
                              props.storeInfo.color === item.value
                                ? "grey"
                                : "white"
                          }}
                        />
                        <Button
                          className={classes.btnStyles}
                          id="template_button"
                          variant="contained"
                          value={item.value}
                          onClick={() => storeInfoChanges(item.value, "color")}
                          style={{
                            border:
                              props.storeInfo.color === item.value
                                ? "2px solid #F97663"
                                : "none",

                            background: item.color
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div style={{ display: "flex" }}>
                  {colors.slice(8, 16).map((item, key) => {
                    return (
                      <div>
                        <CheckIcon
                          style={{
                            display: "flex",
                            margin: "3px auto",
                            fontSize: 16,
                            color:
                              props.storeInfo.color === item.value
                                ? "grey"
                                : "white"
                          }}
                        />
                        <Button
                          className={classes.btnStyles}
                          id="template_button"
                          variant="contained"
                          value={item.value}
                          onClick={() => storeInfoChanges(item.value, "color")}
                          style={{
                            border:
                              props.storeInfo.color === item.value
                                ? "2px solid #F97663"
                                : "none",

                            background: item.color
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </RadioGroup>
          </div>
        </FormControl>
      </Box>
      <Box style={{ marginTop: 20 }}>
        <FormControl style={{ width: "100%" }}>
          <Text
            textType="p1_Semibold"
            content={
              props.language === "Filipino"
                ? "I-upload ang iyong online shop logo at iba pang mga pictures"
                : "Add your shop's logo and photos"
            }
          />
          <Text
            textType="p2"
            content={
              props.language === "Filipino"
                ? "Para mag-upload ng mas maayos, maglagay ng pictures na mas mababa sa 5MB"
                : "For best results, kindly upload images below 5MB."
            }
          />
          <div style={{ display: "flex", marginTop: "25px" }}>
            <Text textType="p2_Semibold" content="Store Logo" />
            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>

          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Box style={{ width: 260, border: "none" }}>
              <SentroFileField
                id="upload_logo"
                name="logo_image"
                loading={loading}
                style={{ width: 260, border: "none" }}
                preview={`${props.storeInfo.logo_image}`}
                onChange={value => handleChangeImage(value, "logo_image")}
              />
            </Box>
          </Box>
        </FormControl>
      </Box>
      <Box style={{ marginTop: "3vh" }}>
        <FormControl style={{ width: "100%" }}>
          <Text textType="p2_Semibold" content="Cover Photo" />
          <Box>
            {props.storeInfo.cover_image === null ||
            props.storeInfo.cover_image === "" ||
            loading === "cover_image" ? (
              ""
            ) : (
              <div style={{ position: "absolute", right: 0 }}>
                <Tooltip title="Delete image" placement="bottom">
                  <IconButton
                    onClick={() => storeInfoChanges(null, "cover_image")}
                  >
                    <CancelIcon style={{ fontSize: 20, color: "#F97663" }} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <SentroFileField
              id="upload_coverPhoto"
              name="cover_image"
              loading={loading}
              preview={`${props.storeInfo.cover_image}`}
              onChange={value => handleChangeImage(value, "cover_image")}
            />
          </Box>
        </FormControl>
      </Box>
      <Box style={{ marginTop: "3vh" }}>
        <FormControl style={{ width: "100%" }}>
          <Text
            textType="p2_Semibold"
            content="Section Photo - About your Business"
          />
          <Box>
            {props.storeInfo.aboutus_image === null ||
            props.storeInfo.aboutus_image === "" ||
            loading === "aboutus_image" ? (
              ""
            ) : (
              <div style={{ position: "absolute", right: 0 }}>
                <Tooltip title="Delete image" placement="bottom">
                  <IconButton
                    onClick={() => storeInfoChanges(null, "aboutus_image")}
                  >
                    <CancelIcon style={{ fontSize: 20, color: "#f97663" }} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <SentroFileField
              id="upload_aboutUsPhoto"
              name="aboutus_image"
              loading={loading}
              preview={`${props.storeInfo.aboutus_image}`}
              onChange={value => handleChangeImage(value, "aboutus_image")}
            />
          </Box>
        </FormControl>
      </Box>

      <Box style={{ marginTop: "3vh" }}>
        <FormControl style={{ width: "100%" }}>
          <Text textType="p2_Semibold" content="Section Photo - Contact Us" />
          <Box>
            {props.storeInfo.contactus_image === null ||
            props.storeInfo.contactus_image === "" ||
            loading === "contactus_image" ? (
              ""
            ) : (
              <div style={{ position: "absolute", right: 0 }}>
                <Tooltip title="Delete image" placement="bottom">
                  <IconButton
                    onClick={() => storeInfoChanges(null, "contactus_image")}
                  >
                    <CancelIcon style={{ fontSize: 20, color: "#f97663" }} />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            <SentroFileField
              id="upload_contactUsPhoto"
              name="contactus_image"
              loading={loading}
              preview={`${props.storeInfo.contactus_image}`}
              onChange={value => handleChangeImage(value, "contactus_image")}
            />
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
};
export default BrandingForm;
