import React from "react";
import {
  RadioGroup,
  Radio,
  Box,
  InputAdornment,
  TextField,
  Paper
} from "@material-ui/core";

import validations from "../../common/validations";
import Text from "../../../common/text";
import axios from "axios";
import apiConfig from "../../../common/apiConfig";
import SentroButton from "../../common/sentroButton";
import storeInfoContent from "../formContent/storeInfoContent";
import { makeStyles } from "@material-ui/styles";

let StoreInfoForm = props => {
  // GTM trigger
  React.useEffect(() => {
    let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    window.dataLayer.push({
      eventAction: "Step - Basic Info",
      eventCategory: "Store Builder",
      event: "storebuildersteptrack",
      userId: currentUser.id
    });
  }, []);

  const useStyles = makeStyles(theme => ({
    input: {
      backgroundColor: props.isEdit ? "#d2d4dd" : ""
    }
  }));
  const classes = useStyles();

  function storeInfoChanges(value, name) {
    props.setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const isEdit = props.isEdit;
  const language = props.language;
  const [errors, setErrors] = React.useState({
    storeNameError: "",
    storeSloganError: "",
    storeDescriptionError: "",
    storeSiteError: ""
  });
  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        props.setFormHasErrors(true);
        return;
      }
    }
    props.setFormHasErrors(false);
  }, [errors]);

  const storeInfo = props.storeInfo;
  React.useEffect(() => {
    if (isEdit) {
      storeInfoChanges(storeInfo.name, "new_name");
      storeInfoChanges(storeInfo.site, "new_site");
    }
  }, []);
  React.useEffect(() => {
    props.setIsFormEnough(
      storeInfo.name.length > 0 &&
        storeInfo.slogan.length > 0 &&
        storeInfo.description.length > 0 &&
        storeInfo.site.length > 0
    );
  }, [storeInfo]);

  const FIELD_MAX_LENGTHS = {
    name: 30,
    new_name: 30,
    slogan: 100,
    description: 500,
    site: 25,
    new_site: 25
  };

  let trimInput = (raw_input, maxLength) => {
    return maxLength > 0 && raw_input.length > maxLength
      ? raw_input.slice(0, maxLength)
      : raw_input;
  };

  let handleTextInput = params => {
    const { raw_input, fieldname } = params;
    const maxLength = FIELD_MAX_LENGTHS[fieldname] || 0;
    let input = trimInput(raw_input, maxLength);

    storeInfoChanges(input, fieldname);
  };

  let setStoreSiteError = errorString => {
    setErrors({
      ...errors,
      storeSiteError: errorString
    });
  };

  async function checkUniqueName(value) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/unique-store/`, {
        params: {
          name: value
        }
      });
      if (response.data === value) {
        setErrors({
          ...errors,
          storeNameError:
            "Sorry, this name is already taken. Please choose another one"
        });
      }
    } catch (error) {}
  }

  let charactersLeftFor = fieldname => {
    const charsRemaining =
      FIELD_MAX_LENGTHS[fieldname] - storeInfo[fieldname].length;
    return `${charsRemaining} characters left`;
  };

  async function checkUniqueSite(value) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/unique-store/`, {
        params: {
          site: value
        }
      });

      if (response.data === value) {
        setErrors({
          ...errors,
          storeSiteError:
            "Sorry, this site is already taken. Please choose another one"
        });
      }
    } catch (error) {}
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <p
          style={{ color: "#54575f", fontSize: 12, fontWeight: 500, margin: 0 }}
        >
          <sup style={{ color: "red" }}>*</sup>Required
        </p>
      </div>
      <Paper elevation={3} style={{ padding: 24, border: "1px solid #54575F" }}>
        <Text
          textType="p2_Semibold"
          content={storeInfoContent[language ? language : "English"].shopHeader}
        />
        <Text
          textType="label1"
          content={
            storeInfoContent[language ? language : "English"].shopSubheader
          }
        />
        <Box style={{ marginTop: "2vh" }}>
          <div style={{ display: "flex" }}>
            <Text
              textType="p2_Semibold"
              content={
                storeInfoContent[language ? language : "English"].storeName
              }
            />
            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>
          <TextField
            id="store_name"
            color="secondary"
            // disabled={isEdit}
            value={isEdit ? props.storeInfo.new_name : props.storeInfo.name}
            onChange={value => {
              const raw_input = value.target.value;
              handleTextInput({
                raw_input,
                fieldname: isEdit ? "new_name" : "name"
              });

              if (raw_input === "") {
                setErrors({
                  ...errors,
                  storeNameError: "This field is required."
                });
              } else {
                setErrors({ ...errors, storeNameError: "" });
                if (!isEdit || (isEdit && raw_input !== props.storeInfo.name)) {
                  checkUniqueName(raw_input);
                }
              }
            }}
            onBlur={value => {
              if (value.target.value === "") {
                setErrors({
                  ...errors,
                  storeNameError: "This field is required."
                });
              }
            }}
            fullWidth
            variant="outlined"
            // InputProps={{
            //   className: classes.input
            // }}
            error={errors.storeNameError}
            helperText={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{errors.storeNameError}</div>
                <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                  {props.storeInfo.new_name !== undefined
                    ? charactersLeftFor("new_name")
                    : charactersLeftFor("name")}
                </div>
              </div>
            }
          />
        </Box>
        <Box style={{ marginTop: "4vh" }}>
          <div style={{ display: "flex" }}>
            <Text
              textType="p2_Semibold"
              content={
                storeInfoContent[language ? language : "English"].storeSite
              }
            />
            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>

          <span>
            <TextField
              id="store_site"
              color="secondary"
              InputProps={{
                // className: classes.input,
                endAdornment: (
                  <InputAdornment position="end">.sentro.ph</InputAdornment>
                )
              }}
              //   disabled={isEdit}
              value={isEdit ? props.storeInfo.new_site : props.storeInfo.site}
              onChange={value => {
                let raw_input = value.target.value;
                handleTextInput({
                  raw_input,
                  fieldname: isEdit ? "new_site" : "site"
                });

                if (validations.required(raw_input)) {
                  setStoreSiteError(validations.required(raw_input));
                } else if (validations.uppercase(raw_input)) {
                  setStoreSiteError(validations.uppercase(raw_input));
                } else if (validations.spaceBetween(raw_input)) {
                  setStoreSiteError(validations.spaceBetween(raw_input));
                } else if (validations.specialCharacters(raw_input)) {
                  setStoreSiteError(validations.specialCharacters(raw_input));
                } else {
                  setStoreSiteError("");
                  if (
                    !isEdit ||
                    (isEdit && value.target.value !== props.storeInfo.site)
                  ) {
                    checkUniqueSite(value.target.value);
                  }
                }
              }}
              onBlur={value => {
                if (value.target.value === "") {
                  setErrors({
                    ...errors,
                    storeSiteError: "This field is required."
                  });
                }
              }}
              fullWidth
              variant="outlined"
              error={errors.storeSiteError}
              helperText={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{errors.storeSiteError}</div>
                  <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                    {props.storeInfo.new_site !== undefined
                      ? charactersLeftFor("new_site")
                      : charactersLeftFor("site")}
                  </div>
                </div>
              }
            />
          </span>
        </Box>
      </Paper>
      <Box style={{ marginTop: "4vh" }}>
        <div style={{ display: "flex" }}>
          <Text
            textType="p2_Semibold"
            content={
              storeInfoContent[language ? language : "English"].storeSlogan
            }
          />
          <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
        </div>
        <Text
          textType="label1"
          content={
            storeInfoContent[language ? language : "English"].sloganSubheader
          }
        />
        <TextField
          id="store_slogan"
          color="secondary"
          value={props.storeInfo.slogan}
          onChange={value => {
            const raw_input = value.target.value;
            handleTextInput({
              raw_input,
              fieldname: "slogan"
            });

            if (raw_input === "") {
              setErrors({
                ...errors,
                storeSloganError: "This field is required."
              });
            } else {
              setErrors({ ...errors, storeSloganError: "" });
            }
          }}
          onBlur={value => {
            if (value.target.value === "") {
              setErrors({
                ...errors,
                storeSloganError: "This field is required."
              });
            }
          }}
          fullWidth
          variant="outlined"
          error={errors.storeSloganError}
          helperText={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{errors.storeSloganError}</div>
              <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                {charactersLeftFor("slogan")}
              </div>
            </div>
          }
        />
      </Box>
      <Box style={{ marginTop: "4vh" }}>
        <div style={{ display: "flex" }}>
          <Text
            textType="p2_Semibold"
            content={
              storeInfoContent[language ? language : "English"].storeAbout
            }
          />
          <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
        </div>
        <Text
          textType="label1"
          content={
            storeInfoContent[language ? language : "English"].aboutSubheader
          }
        />
        <TextField
          id="store_description"
          color="secondary"
          value={props.storeInfo.description}
          onChange={value => {
            const raw_input = value.target.value;
            handleTextInput({
              raw_input,
              fieldname: "description"
            });

            if (value.target.value === "") {
              setErrors({
                ...errors,
                storeDescriptionError: "This field is required."
              });
            } else {
              setErrors({ ...errors, storeDescriptionError: "" });
            }
          }}
          onBlur={value => {
            if (value.target.value === "") {
              setErrors({
                ...errors,
                storeDescriptionError: "This field is required."
              });
            }
          }}
          multiline
          rows={8}
          fullWidth
          variant="outlined"
          error={errors.storeDescriptionError}
          helperText={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{errors.storeDescriptionError}</div>
              <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                {charactersLeftFor("description")}
              </div>
            </div>
          }
        />
      </Box>
    </div>
  );
};

export default StoreInfoForm;
