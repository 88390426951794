import React from "react";
import Banner from "./components/banner";
import Content from "./components/content";
import SentroAppBar from "../common/layout/components/appBar";
import { signOff } from "../auth/authActions";
import DocumentMeta from "react-document-meta";
import { useDispatch } from "react-redux";
import history from "../../store/history";
import Path from "../../Paths";

import { makeStyles } from "@material-ui/core/styles";
const authStyles = makeStyles(theme => ({
  bg: {
    backgroundImage: `url('https://sentro-store-bucker.s3.us-east-2.amazonaws.com/sentro_swirls.png')`,
    position: "relative",
    backgroundSize: "contain",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `none`,
      position: "relative",
      backgroundSize: "contain"
    }
  }
}));
const meta = {
  title: "Sentro | Free Online Shop Builder with Payment Gateway",
  description:
    "Sentro is a websiite builder for small businesses in the Philippines. Sentro automatically integrates payments so you can start accepting cash, over-the-counter, and online payments.",
  canonical: "https://sentro.ph/openmic",
  meta: {
    charset: "utf-8"
  }
};

const OpenMicPage = props => {
  let dispatch = useDispatch();
  const classes = authStyles();
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  return (
    <DocumentMeta {...meta}>
      <div>
        <SentroAppBar />
        <Banner />

        <div className={classes.bg}>
          <Content user={user} />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default OpenMicPage;
