import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DocumentMeta from "react-document-meta";
import SentroLoading from "../../common/loading";
import BuyerSideMenu from "./buyerSideMenu";
import BuyerAppbar from "./buyerAppbar";
import MyCart from "./MyCart";
import BuyerActions from "./redux/actions";
import { useSelector, useDispatch } from "react-redux";
import history from "../../../store/history";
import Path from "../../../Paths";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: "#fafafa"
  },
  side: {
    display: "flex",
    position: "fixed",
    height: "100%",
    zIndex: 3
  },
  content: {
    width: "100%",
    marginTop: 55,
    paddingLeft: "23vw",
    paddingRight: "6vw",
    paddingBottom: "10vh"
  }
}));

const BuyerDashboard = props => {
  const meta = {
    title: "Sentro | Welcome to Sentro",
    description:
      "Welcome to Sentro! Create your online shop and choose your payment options - it's that fast and easy.",
    canonical: "https://sentro.ph/dashboard",
    meta: {
      charset: "utf-8"
      // name: {
      //   keywords: "react,meta,document,html,tags",
      // },
    }
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = sessionStorage.getItem("TOKEN");
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const loading = useSelector(state => state.buyerDashboard.loading);
  React.useEffect(() => {
    if (!user) {
      history.push(Path.LOGIN);
    } else {
      dispatch(BuyerActions.getMyCart());
      dispatch(BuyerActions.setSelectedCheckout([]));
      dispatch(BuyerActions.setSelectedViewOrder(""));
      dispatch(
        BuyerActions.setDeliveryDetails({
          loading: false,
          error: "",
          shipping: "",
          shipping_fee: 0,
          delivery_detail: "",
          mrspeedy_delivery_address: {
            address: "",
            longitude: "",
            latitude: ""
          }
        })
      );
    }
  }, []);
  return (
    <DocumentMeta {...meta}>
      <SentroLoading open={loading} />
      <MyCart />
    </DocumentMeta>
  );
};

export default BuyerDashboard;
