import React from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import {
  AppBar,
  Box,
  makeStyles,
  useMediaQuery,
  IconButton,
  Dialog,
  Avatar,
  Link,
  Drawer,
  Popover,
  MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Close } from "@material-ui/icons";
import sentro1 from "../../../../assets/images/sentro_logo.png";
import sentro2 from "../../../../assets/images/sentro_logo2.png";
import sentroMobile from "../../../../assets/images/sentro_logomark3.png";
import buxLogo from "../../../../assets/images/Logomark.png";
import { confirmLogOut } from "../../../auth/authActions";
import Path from "../../../../Paths";
import history from "../../../../store/history";
import MoreIcon from "@material-ui/icons/Menu";
import { useTheme } from "@material-ui/core/styles";
import SentroButton from "../../sentroButton";
import { useLocation } from "react-router-dom";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import StoreIcon from "@material-ui/icons/Store";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import SettingsIcon from "@material-ui/icons/Settings";
import StorefrontIcon from "@material-ui/icons/Storefront";
import HelpIcon from "@material-ui/icons/Help";
import LanguageIcon from "@material-ui/icons/Language";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SideMenu from "../../../dashboard/sideMenu";
import Payments from "../mobile/mobilePayments/payments";
import TranslateIcon from "@material-ui/icons/Language";
import axios from "axios";
import {
  getPaymentMethods,
  getProfile,
} from "../mobile/webBuilder/websiteBuilderActions";
import AccountSettings from "../mobile/mobileAccountSettings/accountSettings";
import apiConfig from "../../../../common/apiConfig";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { ReactComponent as Shop } from "../../../../assets/images/sidebar_assets/v3_icons/shop.svg";
import { ReactComponent as Products } from "../../../../assets/images/sidebar_assets/v3_icons/products.svg";
import { ReactComponent as Orders } from "../../../../assets/images/sidebar_assets/v3_icons/orders.svg";
import { ReactComponent as PaymentIcon } from "../../../../assets/images/sidebar_assets/v3_icons/payments.svg";
import { ReactComponent as Vouchers } from "../../../../assets/images/sidebar_assets/v3_icons/vouchers.svg";
import { ReactComponent as Apps } from "../../../../assets/images/sidebar_assets/v3_icons/apps.svg";
import { ReactComponent as Marketplace } from "../../../../assets/images/sidebar_assets/v3_icons/marketplace.svg";
import { ReactComponent as Translate } from "../../../../assets/images/sidebar_assets/v3_icons/translate.svg";
import { ReactComponent as Cart } from "../../../../assets/images/sidebar_assets/cart.svg";
import { ReactComponent as Purchased } from "../../../../assets/images/sidebar_assets/purchased.svg";
import { ReactComponent as Discount } from "../../../../assets/images/sidebar_assets/discount.svg";
import { ReactComponent as Wishlist } from "../../../../assets/images/sidebar_assets/v3_icons/wishlist.svg";
let menuItems = [];

let loggedOutMenu = [
  {
    label: "Login",
    path: Path.LOGIN,
  },
  {
    label: "Sign up",
    path: Path.SIGN_UP,
    variant: "contained",
  },
];

function SentroAppBarMobile(props) {
  React.useEffect(() => {
    // HotJar heat map
    if (typeof window.hj === "function") {
      window.hj("trigger", "sentroMainMenu_mobile");
    }
  }, []);

  const [position, setPosition] = React.useState(0);
  useScrollPosition(({ prevPos, currPos }) => {
    setPosition(currPos.y);
  });
  const location = useLocation();
  const currentLoc = location.pathname;
  let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const appBarStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "2vw",
      marginRight: "2vw",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: 0,
        alignItems: "center",
      },
    },
    desktop: {
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: data ? "none" : "block",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    mobile: {
      [theme.breakpoints.down("md")]: {
        display: data ? "block" : "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    dialog: {
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    appBar: {
      position: "fixed",
      borderBottom: data ? "1px solid #d1d5dd" : "none",
      boxShadow: "none",
      backgroundColor:
        !props.private && position >= -40 ? "transparent" : "white",
    },
    buttons: {
      display: "block",
      margin: 16,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "0px 100px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: 0,
      },
    },
    btnSignup: {
      fontSize: 16,
      width: 100,
      height: 40,
      margin: 10,
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        height: 48,
        width: 200,
        marginRight: "3vw",
      },
      [theme.breakpoints.down("xs")]: {
        width: 100,
        height: 40,
        margin: 10,
      },
    },
    popoverIpad: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    dialogMobile: {
      [theme.breakpoints.only("md")]: {
        marginTop: "8vh",
        width: "50vw",
        position: "absolute",
        marginLeft: "50vw",
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: "8vh",
        width: "50vw",
        position: "absolute",
        marginLeft: "50vw",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "8vh",
        width: "100%",
      },
    },
    btn: {
      fontSize: 16,
      width: 135,
      height: 48,
      margin: 10,
      fontWeight: 600,
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      "&:hover": {
        backgroundColor: "#DD5e56",
      },
    },
    btnLogin: {
      marginTop: "1vh",
      fontSize: 16,
      width: 135,
      height: 48,
      margin: 10,
      fontWeight: 600,
      color: position >= -40 ? "white" : "",
      "&:hover": {
        border: "1px solid",
        borderColor: position >= -40 ? "white" : "#F97663",
      },
    },
    name: {
      textTransform: "capitalize",
      fontWeight: 600,
    },
    avatar: {
      width: 35,
      height: 35,
      background: `linear-gradient(106.17deg, #35B0AB 4.56%, #226B80 154.44%)`,
      fontSize: 14,
      fontWeight: 600,
      color: "#fff",
    },
    profileBtn: {
      display: "flex",
      justifyContent: "space-between",
      padding: "20px 16px",
    },
    drawerLabel: {
      color: "#909196",
      fontWeight: 500,
      fontSize: 14,
    },
    icon: {
      width: 30,
      height: 30,
      fill: "#f97663",
    },
    icon2: {
      width: 42,
      height: 42,
      fill: "#f97663",
    },
    icon3: {
      width: 30,
      height: 30,
      fill: "#fff",
    },
  }));
  let currentStoreCreated = useSelector(
    (state) => state.mobileDashboard.storeInfo
  );
  let user_name = data ? data.first_name + " " + data.last_name : "";
  let last_name = data ? data.last_name : "";
  let first_name = data ? data.first_name : "";
  let email = data ? data.email : "";
  let displayEmail = email ? email.split("@") : "";
  let displayName =
    first_name === "" && last_name === "" ? displayEmail[0] : user_name;

  const orders = useSelector(
    (state) => state.mobileDashboard.orders.orders.list
  );
  const classes = appBarStyles();
  let unreadOrders = sessionStorage.getItem("unread_orders");
  const [openPayment, setOpenPayment] = React.useState(false);
  const [openAccountSettings, setOpenAccountSettings] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorTranslate, setAnchorTranslate] = React.useState(null);
  const [orderList, setOrderList] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const STORE_UUID = localStorage.getItem("STORE_UUID");
  const logout = () => {
    dispatch(confirmLogOut());
  };
  const handleClickMenu3 = (e) => {
    setAnchorEl3(anchorEl3 ? null : e.currentTarget);
  };

  const handleClickMenu4 = (e) => {
    setAnchorEl4(anchorEl4 ? null : e.currentTarget);
  };

  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);

  const handleClose = (e) => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleClose3 = (e) => {
    setAnchorEl3(null);
    setOpen(false);
  };

  const handleClose4 = (e) => {
    setAnchorEl4(null);
    setOpen(false);
  };

  const handleTranslate = (e) => {
    setAnchorTranslate(e.currentTarget);
  };

  const handleTranslateMobile = (e) => {
    props.setLanguage(props.language === "English" ? "Filipino" : "English");
    localStorage.setItem(
      "language",
      props.language === "English" ? "Filipino" : "English"
    );
  };

  const handleCloseTranslate = (e) => {
    setAnchorTranslate(null);
  };

  React.useEffect(() => {
    if (currentLoc !== "/mobile/orders") {
      (async () => {
        let response = await axios.get(
          `${apiConfig.apiURL}/api/dashboard/orders/`,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
            },
          }
        );
        setOrderList(response.data);
      })();
    }
  }, [currentLoc]);

  React.useEffect(() => {
    if (orders) {
      setOrderList(orders);
    }
  }, [orders]);

  React.useEffect(() => {
    if (orderList.length) {
      const list = orderList.filter((item) => item.is_read === false);
      sessionStorage.setItem("unread_orders", list.length);
    }
  }, [orderList]);

  const listContent1 = [
    {
      icon: <Shop className={classes.icon} />,
      label: "My Shop",
      key: "shop",
      path: "/dashboard",
      id: "my_shop",
    },
    {
      icon: <Products className={classes.icon} />,
      label: "Products",
      key: "products",
      path: "/products",
      id: "product",
    },
    {
      icon: <Orders className={classes.icon} />,
      label: "Orders",
      key: "orders",
      path: "/orders/list",
      id: "order",
    },
    {
      icon: <PaymentIcon className={classes.icon} />,
      label: "Payments",
      key: "payments",
      path: "/payments",
      id: "payment",
      isDisabled: currentStoreCreated ? false : true,
    },
    {
      icon: <Vouchers className={classes.icon} />,
      label: "Vouchers",
      key: "vouchers",
      path: "/mobile/vouchers",
      id: "vouchers",
    },
    {
      icon: <Apps className={classes.icon} style={{ opacity: 0.5 }} />,
      label: "Apps",
      key: "apps",
      id: "apps",
      isDisabled: true,
    },
  ];

  const listContent2 = [
    {
      icon: <Cart className={classes.icon2} />,
      label: "My Cart",
      key: "cart",
      path: "/my_cart",
      id: "cart",
    },
    {
      icon: <Purchased className={classes.icon2} />,
      label: "Purchased",
      key: "purchased",
      path: "/purchased",
      id: "purchased",
    },
    // {
    //   icon: <Discount className={classes.icon} />,
    //   label: "Discounts",
    //   key: "discounts",
    //   path: "/discounts",
    //   id: "discounts",
    //   isDisabled: true
    // },
    {
      icon: <Wishlist className={classes.icon} style={{ opacity: 0.5 }} />,
      label: "Wishlist",
      key: "wishlist",
      id: "wishlist",
      isDisabled: true,
    },
  ];

  const drawerContent3 = [
    {
      icon: <HelpOutlineIcon className={classes.icon3} />,
      label: "Help Center",
      key: "faqs",
      onClick: () => window.open("https://faq.sentro.ph/hc/en-us"),
    },
    {
      icon: <AccountCircleOutlinedIcon className={classes.icon3} />,
      label: "Account",
      key: "account",
      onClick: () => {
        setOpenAccountSettings(true);
        dispatch(getProfile());
        handleClickMenu3();
      },
    },
    {
      icon: <ExitToAppIcon className={classes.icon3} />,
      label: "Log Out",
      key: "logout",
      onClick: logout,
    },
  ];

  function returnAvatar(word) {
    let newWord = word.split(/\W+/);
    if (newWord.length === 2) {
      return (
        newWord[0].charAt(0).toUpperCase() + newWord[1].charAt(0).toUpperCase()
      );
    } else {
      return word.charAt(0).toUpperCase();
    }
  }

  function sentroLogo() {
    if (data) {
      return sentroMobile;
    } else if (position >= -40 && anchorEl4 === null) {
      return sentro1;
    } else if (position >= -40 && anchorEl4 != null) {
      return sentro2;
    } else {
      return sentro2;
    }
  }
  function moreIcon() {
    if (data) {
      return "#f97663";
    } else if (position >= -40 && anchorEl4 === null) {
      return "#fff";
    } else if (position >= -40 && anchorEl4 != null) {
      return "#f97663";
    } else {
      return "#f97663";
    }
  }

  function handleTrigger(key) {
    switch (key) {
      case "shop":
        history.push(Path.MOBILE_WEB_BUILDER);
        break;
      case "products":
        history.push(Path.MOBILE_PRODUCTS);
        break;
      case "orders":
        history.push(Path.MOBILE_ORDERS);
        break;
      case "payments":
        history.push(Path.PAYMENTS);
        break;
      case "apps":
        history.push(Path.APP_MARKETPLACE);
        break;
      case "faqs":
        window.open("https://faq.sentro.ph/hc/en-us");
        break;
      case "notifications":
        history.push(Path.ANNOUNCEMENTS);
        break;
      case "translate":
        handleTranslateMobile();
        break;
      case "vouchers":
        history.push(`/mobile/vouchers`);
        break;
    }
    if (key) {
      handleClickMenu3();
    }
  }

  function header_display() {
    if (data) {
      if (currentLoc.includes("/shop")) {
        return "My Shop";
      } else if (currentLoc.includes("/products")) {
        return "Products";
      } else if (currentLoc.includes("/orders")) {
        return "Orders";
      } else if (currentLoc.includes("/Vouchers")) {
        return "Vouchers";
      } else {
        return "";
      }
    }
  }

  function drawerMenu() {
    return (
      <div style={{ width: "100vw", position: "relative", height: "100%" }}>
        <div
          style={{ borderBottom: "1px solid #d1d5dd" }}
          className="d-flex justify-content-between align-items-center p-3"
        >
          <img src={sentro2} width="auto" height="25" alt="logo" />
          <IconButton onClick={handleClickMenu3}>
            <Close color="primary" />
          </IconButton>
        </div>
        <div className="p-3">
          <div style={{ borderBottom: "1px solid #d1d5dd", width: "100%" }}>
            <div style={{ color: "#54575f", textAlign: "center" }}>SELL</div>
            <div className="d-flex align-items-center justify-content-around">
              {listContent1.slice(0, 3).map((item) => (
                <IconButton
                  onClick={() => handleTrigger(item.key)}
                  disabled={item.isDisabled}
                >
                  <div className="text-center">
                    {item.icon}
                    <div
                      className="pt-3"
                      style={{ fontSize: 14, fontWeight: 600 }}
                    >
                      {item.label}
                    </div>
                  </div>
                </IconButton>
              ))}
            </div>
            <div className="d-flex align-items-center justify-content-around mb-3">
              {listContent1.slice(3, 6).map((item) => (
                <IconButton
                  onClick={() => handleTrigger(item.key)}
                  disabled={item.isDisabled}
                >
                  <div className="text-center">
                    {item.icon}
                    <div
                      className="pt-3"
                      style={{ fontSize: 14, fontWeight: 600 }}
                    >
                      {item.label}
                    </div>
                  </div>
                </IconButton>
              ))}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#54575f",
                textAlign: "center",
                paddingTop: "3vh",
              }}
            >
              BUY
            </div>
            <div className="d-flex align-items-center justify-content-around">
              {listContent2.slice(0, 3).map((item) => (
                <IconButton
                  onClick={() => {
                    history.push(item.path);
                    handleClickMenu3();
                  }}
                  disabled={item.isDisabled}
                >
                  <div className="text-center">
                    {item.icon}
                    <div
                      className="pt-3"
                      style={{ fontSize: 14, fontWeight: 600 }}
                    >
                      {item.label}
                    </div>
                  </div>
                </IconButton>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f97663",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
          }}
          className="p-3"
        >
          <div className="d-flex justify-content-between">
            {drawerContent3.map((item) => (
              <IconButton onClick={() => item.onClick()}>
                <div className="text-center">
                  {item.icon}
                  <div
                    className="pt-1"
                    style={{ fontSize: 14, fontWeight: 600, color: "#fff" }}
                  >
                    {item.label}
                  </div>
                </div>
              </IconButton>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <AppBar className={classes.appBar}>
      <div className={classes.root}>
        <Box style={{ margin: data ? 0 : "0 auto" }}>
          <div style={{ padding: 18 }}>
            <a
              onClick={() => {
                if (data) {
                  history.push(Path.MOBILE_WEB_BUILDER);
                } else {
                  history.push(Path.HOME);
                }
                window.scrollTo(0, 0);
              }}
            >
              <img
                alt="sentro-light"
                src={sentroLogo()}
                style={{ width: data ? 35 : 120, height: "auto" }}
              />
            </a>
          </div>
        </Box>
        <strong style={{ fontSize: 18, color: "#002c42" }}>
          {header_display()}
        </strong>
        <Box className={classes.mobile}>
          <AccountSettings
            open={openAccountSettings}
            setOpen={setOpenAccountSettings}
          />
          <Payments
            open={openPayment}
            setOpen={setOpenPayment}
            store_uuid={STORE_UUID}
            language={props.language}
          />
          {data ? (
            <div style={{ padding: 8 }}>
              <IconButton onClick={handleClickMenu3} color="primary">
                <MoreIcon style={{ color: moreIcon() }} />
              </IconButton>
              <Drawer
                className={classes.drawer}
                anchor={"right"}
                open={open3}
                onClose={handleClose3}
              >
                {drawerMenu()}
              </Drawer>
            </div>
          ) : (
            <div style={{ position: "absolute", right: 10, top: 8 }}>
              {/* <IconButton onClick={handleTranslate} color="primary">
                <TranslateIcon style={{ color: moreIcon() }} />
              </IconButton> */}
              <Popover
                anchorEl={anchorTranslate}
                open={Boolean(anchorTranslate)}
                onClose={handleCloseTranslate}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem
                  onClick={() => {
                    props.setLanguage("English");
                    localStorage.setItem("language", "English");
                    setAnchorTranslate(null);
                  }}
                  id="english"
                  style={{ padding: "10px 25px" }}
                >
                  English
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.setLanguage("Filipino");
                    localStorage.setItem("language", "Filipino");
                    setAnchorTranslate(null);
                  }}
                  id="filipino"
                  style={{ padding: "10px 25px" }}
                >
                  Filipino
                </MenuItem>
              </Popover>
              <IconButton onClick={handleClickMenu4} color="primary">
                <MoreIcon style={{ color: moreIcon() }} />
              </IconButton>
              <Dialog
                style={{ zIndex: 1 }}
                className={classes.dialog}
                fullScreen={fullScreen}
                open={open4}
                onClose={handleClose4}
                aria-labelledby="responsive-dialog-title"
                anchorEl={anchorEl4}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {data ? (
                    <div
                      style={{
                        padding: 20,
                        marginTop: "20vh",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div className={classes.buttons}>
                        <SentroButton
                          onClick={() =>
                            window.open("https://faq.sentro.ph/hc/en-us")
                          }
                          fullWidth
                          variant="contained"
                          label="FAQs"
                          style={{ margin: "10px 0" }}
                        />

                        <SentroButton
                          onClick={logout}
                          variant="contained"
                          fullWidth
                          label="Log Out"
                          style={{ margin: "10px 0" }}
                        />

                        <SentroButton
                          onClick={handleClose}
                          // variant="contained"
                          fullWidth
                          label="Close"
                          style={{ margin: "10px 0" }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div className={classes.buttons}>
                          <SentroButton
                            label={"Login"}
                            onClick={() => history.push(Path.LOGIN)}
                            fullWidth
                            style={{ margin: "10px 0" }}
                          />
                          <SentroButton
                            label={"Sign up"}
                            onClick={() => history.push(Path.SIGN_UP)}
                            variant={"contained"}
                            style={{ margin: "10px 0" }}
                            fullWidth
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          bottom: 10,
                          padding: 15,
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            id="termsAndConditions_link"
                            style={{
                              fontSize: 12,
                              color: "#F97663",
                              fontWeight: 600,
                            }}
                            onClick={() => {
                              history.push(Path.TNC);
                              window.scrollTo(0, 0);
                            }}
                          >
                            TERMS AND CONDITIONS
                          </Link>
                          <Link
                            id="faq_link"
                            style={{
                              fontSize: 12,
                              color: "#F97663",
                              fontWeight: 600,
                            }}
                            onClick={() => {
                              window.open("https://faq.sentro.ph/hc/en-us");
                              window.scrollTo(0, 0);
                            }}
                          >
                            FAQS
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Dialog>
            </div>
          )}
        </Box>
      </div>
    </AppBar>
  );
}

export default SentroAppBarMobile;
