import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from 'react-redux';
import Actions, { ReduxInitializeFormActions as ReduxInit } from '../../../redux/actions';
import AddressModal from '../address/addressModal';
import AddressItem from '../address/addressItem';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import {
  Button,
} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
  addBtn: {
    height: 38,
    marginLeft: "auto",
    minWidth: 100,
  },
  warning: {
    color: "#E3632A",
    background: "#FCF7E1",
    border: "1px solid #E89937",
    borderRadius: 4,
    padding: "5px 15px",
    alignItems: "center",
    marginBottom: 0,
    fontSize: 14,
    display: "flex"
  },
  item: {
    padding: "1em",
    background: "#FFFFFF",
    border: "1px solid #EEEEEE",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: 4
  },
}));

const AddressPane = props => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const redux_addresses = useSelector(state => state.buyerDashboard.addresses);
  const [addresses, setAddresses] = React.useState([]);


  React.useEffect(() => {
    dispatch(Actions.getAddresses())
  }, [])

  React.useEffect(() => {
    setAddresses(redux_addresses)
  }, [redux_addresses])


  const setNotDefaultAll = () => {
    setAddresses(item => (
      item.map((address, i) => (
        {...address, is_default: false}
      ))
    ))
  }

  const onSubmitNewAddress = (values) => {
    if (values.is_default) setNotDefaultAll();
    setAddresses(addresses => ([
      ...addresses,
      {...values, is_default: addresses.length ? values.is_default: true}
    ]))
  }

  const onEditAddress = (index, values) => {
    const new_addresses = addresses.map((address, i) => (
      i === index ? values: {
        ...address,
        is_default: values.is_default ? false: address.is_default
      }));
    setAddresses(new_addresses)
  }


  const onDelete = (index) => {
    const is_default = addresses[index].is_default;
    setAddresses(addresses => (
      addresses.filter((data, i) => i !== index)
    ))
    if (is_default) {
      setDefault(0);
    }
    const address_instance_id = addresses[index].id;
    if (props.onDelete && address_instance_id) props.onDelete(address_instance_id)
  }

  const setDefault = (index) => {
    setAddresses(item => (
      item.map((address, i) => ({
        ...address,
        is_default: i !== index ? false: true
      }))
    ))
  }



  React.useEffect(() => {
    if (props.onChange) props.onChange(addresses)
  }, [addresses])

  return (
    <div className="p-0 p-md-5 content">
      <div className="p-3 p-md-0 mb-0 mb-md-4 d-flex flex-wrap">
        {
          addresses.length > 4 ?
          <p className={`${classes.warning} warning-message mb-3 mb-md-0`}>
            <ErrorOutlineRoundedIcon className="mr-2"/>
            <span>You are only allowed a maximum of 5 addresses.</span>
          </p>: ""
        }
        <AddressModal onSubmit={onSubmitNewAddress}>
          {
            (setOpen) => (
              <Button variant="outlined" color="primary" className={`add-button ${classes.addBtn}`}
                onClick={() => {
                  setOpen(true)
                  dispatch(ReduxInit.initializeAddressModal({
                    label: 0
                  }))
                }}
                disabled={addresses.length >= 5}
              >
                + Add new address
              </Button>
            )
          }
        </AddressModal>
      </div>
      {
        addresses.map((data, index) => (
        <AddressModal onSubmit={(data) => onEditAddress(index, data)}>
        {
          (setOpen) => (
            <AddressItem key={index} index={index} data={data} 
              addressCount={addresses.length}
              onEdit={() => {
                setOpen(true)
                dispatch(ReduxInit.initializeAddressModal({
                  ...data,
                  label: data.label === "work" ? 1: 0
                }))
              }}
              setDefault={setDefault}
              onDelete={onDelete}
            />
            )
          }
        </AddressModal>
        ))
      }
    </div>
  );
};





export default AddressPane;