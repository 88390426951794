import React from "react";
import { Field, reduxForm, reset } from "redux-form";
import Form from "../../forms/productForm/form";
import { useDispatch, useSelector, connect } from "react-redux";
import { ReduxInitializeFormActions } from "../../../websiteBuilderActions";

function ProductForm(props) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const onMount = async () => {
      dispatch(reset("product_form"));
      dispatch(
        props.initializeData({
          description: "",
          always_available: false,
          discounted_price: "",
          featured: false,
          has_variants: false,
          quantity: 5,
          category: "Others",
          status: "active",
          product_code: "",
          is_physical_product: true,
          length: "",
          width: "",
          height: "",
          made_to_order: true,
          lead_time: 1
        })
      );
    };
    onMount();
  }, []);
  return (
    <div>
      <Form variantGroupOnChange={props.variantGroupOnChange} />
    </div>
  );
}

let ReduxProductForm = reduxForm({
  form: "product_form",
  enableReinitialize: true,
  destroyOnUnmount: false
})(ProductForm);

ReduxProductForm = connect(
  state => ({
    initialValues: state.webBuilder.reduxForm.productForm,
    store: state.webBuilder.storeInfo,
    errors: state.form.product_form?.syncErrors,
    regFields: state.form.product_form?.registeredFields
  }),
  {
    initializeData: ReduxInitializeFormActions.initializeProductFormData
  } // bind account loading action creator
)(ReduxProductForm);

export default ReduxProductForm;
