import React from "react";
import SideMenu from "./sideMenu";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Content from "./content";
import { useSelector, useDispatch } from "react-redux";
import SentroLoading from "../common/loading";
import DeletedDialogBox from "./deletedDialogBox";
import BuxCard from "./buxCard";
import DocumentMeta from "react-document-meta";
import history from "../../store/history";
import Path from "../../Paths";
import { useMediaQuery } from "@material-ui/core";
import AlertDialogBox from "./common/AlertDialogBox";
import KYCQuestions from "./kycQuestions";
import UpdatePasswordNotice from "./updatePasswordNotice";
import TNCNotice from "./tncNotice";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: "#fafafa"
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: "#f5f5f4",
    borderRadius: "4px",
    padding: theme.spacing(4, 6, 4)
  },
  dashboard: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 55,
    [theme.breakpoints.down("sm")]: {
      display: "block"
      // overflow: "scroll",
      // marginTop: props.marginTop || 100,
    }
  },
  bux: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      display: "block"
    }
  }
}));

const Dashboard = props => {
  const meta = {
    title: "Sentro | Welcome to Sentro",
    description:
      "Welcome to Sentro! Create your online shop and choose your payment options - it's that fast and easy.",
    canonical: "https://sentro.ph/dashboard",
    meta: {
      charset: "utf-8"
      // name: {
      //   keywords: "react,meta,document,html,tags",
      // },
    }
  };
  const classes = useStyles();
  const theme = useTheme();
  sessionStorage.setItem("IN_SESSION", "CURRENT_USER");
  let getLanguage = localStorage.getItem("language");
  let [language, setLanguage] = React.useState(getLanguage);
  let loading = useSelector(state => state.webBuilder.loading);
  let stores = useSelector(state => state.webBuilder.stores);
  let dialogOpen = useSelector(state => state.webBuilder.dialogOpen);
  let alert = useSelector(state => state.webBuilder.message);
  let mobileView = useMediaQuery(theme.breakpoints.down("xs"));
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  React.useEffect(() => {
    if (mobileView) {
      history.push(Path.MOBILE_WEB_BUILDER);
    }
  }, [mobileView]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (user.is_buyer && !user.is_seller) {
      history.push(Path.BUYER_DASHBOARD);
    }
  }, []);

  React.useEffect(() => {
    setLanguage(getLanguage);
  }, [getLanguage]);
  return (
    <DocumentMeta {...meta}>
      <div className={classes.root}>
        <SentroLoading open={loading} />
        <div className={classes.content}>
          <Content language={language} />
        </div>
        <div className={classes.bux}>
          <BuxCard language={language} />
        </div>
        <div>
          {loading ? (
            ""
          ) : (
            <AlertDialogBox
              open={dialogOpen}
              alertType={alert ? alert.type : "success"}
              message={alert ? alert.message : ""}
            />
          )}
        </div>
      </div>
      <div>
        <KYCQuestions />
        <UpdatePasswordNotice />
        <TNCNotice />
      </div>
    </DocumentMeta>
  );
};

export default Dashboard;
