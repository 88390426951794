import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = theme => ({
  root: {
    display: 'flex', 
    alignItems: "center",
    "& > div": {
      marginLeft: "auto"
    },
    [theme.breakpoints.down("sm")]: {
      "& > div": {
        marginLeft: 0
      }
    },
  },
  picker: {
    backgroundColor: "white",
    border: 'none',
    width: 140,
    borderRadius: 3,
    height: 36.25,
    "& input": {
      padding: "11px 11px 11px 0",
      fontSize: 12,
      fontWeight: 400,
      color: "#002C42 !important"
    },
    "& > div": {
      paddingLeft: 0,
      backgroundColor: "white",
      boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.1)",
    },
    "& fieldset": {
      borderColor: 'rgba(0,0,0,0.1)'
    },
    "& button": {
      color: "rgba(3, 46, 68, 0.65)"
    },
    "& .Mui-error": {
      fontSize: 11
    }
  },
});

class DateRange extends React.Component {

  state = {
    start: this.props.value.start || new Date().toLocaleDateString("en-US"),
    end: this.props.value.end || new Date().toLocaleDateString("en-US")
  }

  setDateRangeFilter = (type, val) => {
    const date = new Date(val);
    const date_str = date.toLocaleDateString("en-US");
    this.setState({
      ...this.state,
      [type]: date_str
    }, () => {
      if(this.props.onChange) this.props.onChange(this.state);
    })
  }

  render (){
    const { classes } = this.props;
    const { start, end } = this.state;
    return (
      <div className={classes.root}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            className={classes.picker}
            label=""
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/DD/YYYY"
            value={start}
            maxDate={end}
            InputAdornmentProps={{ position: "start" }}
            onChange={(date) => this.setDateRangeFilter("start", date)}
            orientation="landscape"
          />
          <KeyboardDatePicker
            className={classes.picker}
            style={{marginLeft: "1em"}}
            label=""
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/DD/YYYY"
            minDate={start}
            value={end}
            InputAdornmentProps={{ position: "start" }}
            onChange={(date) => this.setDateRangeFilter("end", date)}
            orientation="landscape"
          />
        </MuiPickersUtilsProvider>
      </div>
    )
  }
}


export default withStyles(useStyles)(DateRange);

