import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import sentroLogo from "../../../assets/images/sentrobuild_light.svg";
import sentroLogo2 from "../../../assets/images/sentro_logo2.png";
import { useSelector, useDispatch } from "react-redux";
import { Field, reduxForm } from "redux-form";
import validations from "../../common/validations";
import SentroTextField from "../../common/sentroTextField";
import { InputAdornment, Button, Link, useMediaQuery } from "@material-ui/core";
import apiConfig from "../../../common/apiConfig";
import SocialButton from "../../social/socialButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Divider, Alert } from "antd";
import Paths from "../router/paths";
import history from "../../../store/history";
import MarketplaceActions from "../redux/actions/marketplaceActions";
import SentroAlert from "../../common/alert";
import qs from "query-string";

export default function Login(props) {
  const enterprise = useSelector(state => state.enterprise.main.enterprise);
  const themeColor = useSelector(state => state.enterprise.main.theme);
  const loginError = useSelector(state => state.enterprise.main.error);
  const token = sessionStorage.getItem("TOKEN");
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      height: "100vh",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        height: props.removeHeader ? "fit-content" : "85vh"
      }
    },
    banner: {
      backgroundColor: themeColor.palette.primary.main,
      width: "60%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "none"
      }
    },
    logo: {
      color: "#fff",
      width: "fit-content",
      borderRadius: 10
    },
    loginSection: {
      height: "100%",
      position: "relative",
      width: "40%",
      overflow: "auto",
      padding: "3em 5vw 0",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "3em 2vw 0"
      }
    },
    header: {
      fontSize: 28,
      fontWeight: 600,
      color: "#2b2d32",
      [theme.breakpoints.down("sm")]: {
        fontSize: 20
      }
    },
    btnFacebook: {
      backgroundColor: "#3b5998",
      color: "#fff",
      width: "100%",
      height: 48,
      fontSize: 16,
      fontWeight: 600,
      "&:hover": {
        backgroundColor: "#224080"
      }
    },
    sentroLogo: {
      [theme.breakpoints.down("sm")]: {
        height: 65,
        width: "auto"
      }
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      color: "rgba(43,45,50,0.8)"
    },
    adornedEnd: {
      paddingRight: 0
    },
    btnLogin: {
      backgroundColor: themeColor.palette.secondary.main,
      height: 48,
      color: "#fff",
      "&:hover": {
        color: "#fff",
        filter:
          themeColor.palette.secondary.main !== "#18B4A1"
            ? "brightness(0.8)"
            : "none",
        backgroundColor:
          themeColor.palette.secondary.main !== "#18B4A1"
            ? themeColor.palette.secondary.main
            : themeColor.palette.secondary.defaultHoverColor
      }
    },
    forgotPassword: {
      fontSize: 12,
      color: themeColor.palette.secondary.main,
      position: "absolute",
      bottom: -10,
      right: 0
    },
    footer: {
      textAlign: "center",
      width: "100%",
      fontSize: 12,
      position: "absolute",
      bottom: "1vh",
      left: 0,
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    bannerText: {
      display: "none",
      fontSize: 16,
      color: "#fafafa",
      padding: "3em",
      [theme.breakpoints.down("sm")]: {
        display: "flex"
      }
    },
    logoImg: {
      width: "auto",
      height: 80
    }
  }));
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showPassword, setShowPassword] = React.useState("");
  const loginValues = useSelector(state =>
    state.form.loginForm ? state.form.loginForm.values : null
  );
  const error = useSelector(state =>
    state.form.loginForm ? state.form.loginForm.syncErrors : true
  );

  async function handleLogin() {
    let params = {
      email: loginValues.email,
      password: loginValues.password
    };
    let res = await dispatch(
      MarketplaceActions.login(enterprise.site_name, params)
    );

    try {
      if (res.status === 200) {
        if (props.setDrawer && res.data.role === "buyer") {
          history.push(`/enterprise/${enterprise.site_name}/buyer_dashboard`);
        } else if (props.setDrawer && res.data.role === "admin") {
          history.push(`/enterprise/${enterprise.site_name}/dashboard`);
        }
      }
    } catch (e) {
      console.log(error);
    }
  }

  function handleFbLogin(user) {
    console.log(user);
  }

  function handleFbLoginFailure(error) {
    console.log(error);
  }

  function loginViaEnterKeypress(e) {
    if (e.key !== "Enter" || !loginValues.email || !loginValues.password) {
      return;
    }

    handleLogin();
  }

  function hideError() {
    dispatch(MarketplaceActions.hideError());
  }
  const site_name = props.site_name || props.match.params.enterprise_id;
  React.useEffect(() => {
    async function fetchData() {
      dispatch(MarketplaceActions.getMarketplace(site_name));
    }
    fetchData();
    dispatch(MarketplaceActions.hideAlert());
    sessionStorage.removeItem("TOKEN");
    sessionStorage.removeItem("CURRENT_USER");
  }, []);

  React.useEffect(() => {
    const location_search = qs.parse(props.location?.search);
    if (token && user.role === "buyer") {
      const link =
        location_search.next ||
        `/enterprise/${enterprise.site_name}/buyer_dashboard`;
      history.push(link);
    } else if (token && user.role === "admin") {
      history.push(`/enterprise/${enterprise.site_name}/dashboard`);
    }
  });

  return (
    <div className={classes.root}>
      <div className={classes.banner} bgcolor="primary">
        <div className={classes.logo}>
          <img alt="logo" src={enterprise.logo} className={classes.logoImg} />
        </div>
      </div>
      <div className={classes.loginSection}>
        <div>
          {props.removeHeader ? (
            ""
          ) : (
            <div className={classes.header}>Log in</div>
          )}

          <div style={{ marginTop: props.removeHeader ? 0 : "5vh" }}>
            {/* <SocialButton
              provider="facebook"
              appId={apiConfig.fbId}
              className={classes.btnFacebook}
              startIcon={<FacebookIcon />}
              onLoginSuccess={handleFbLogin}
              onLoginFailure={handleFbLoginFailure}
            >
              Log in with Facebook
            </SocialButton>

            <Divider style={{ fontSize: 12, color: "#333", padding: "2vh 0" }}>
              or
            </Divider> */}
            {loginError && loginError.showAlert ? (
              <div style={{ marginBottom: "3vh" }}>
                <Alert
                  onClose={() => hideError()}
                  message={loginError.message}
                  type={loginError.alertType}
                />
              </div>
            ) : (
              ""
            )}

            <div>
              <div className={classes.label}>Email Address</div>
              <Field
                id="email"
                name="email"
                validate={[
                  validations.required,
                  validations.whiteSpace,
                  validations.email,
                  validations.verifyEmail,
                  validations.noCaps
                ]}
                component={SentroTextField}
              />
            </div>

            <div style={{ marginTop: "3vh", position: "relative" }}>
              <div className={classes.label}>Password</div>
              <Field
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                validate={[validations.required]}
                component={SentroTextField}
                onKeyPress={e => loginViaEnterKeypress(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        style={{
                          color: "#2b2d32",
                          fontSize: 10,
                          height: 40
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "HIDE" : "SHOW"}
                      </Button>
                    </InputAdornment>
                  ),
                  classes: { adornedEnd: classes.adornedEnd }
                }}
              />
              <Link
                onClick={() =>
                  history.push(
                    `/enterprise/${enterprise.site_name}/forgot_password`
                  )
                }
                className={classes.forgotPassword}
              >
                Forgot password?
              </Link>
            </div>
            <div style={{ marginTop: isMobile ? "5vh" : "10vh" }}>
              <Button
                id="login_btn"
                fullWidth
                variant="contained"
                disableElevation
                className={classes.btnLogin}
                disabled={error}
                onKeyPress={e => loginViaEnterKeypress(e)}
                onClick={handleLogin}
              >
                Log in
              </Button>
            </div>
            <div style={{ marginTop: "2vh" }}>
              <div style={{ fontSize: 12, textAlign: "center" }}>
                Don't have an account?{" "}
                <Link
                  onClick={() =>
                    history.push(`/enterprise/${enterprise.site_name}/signup`)
                  }
                  style={{
                    color: themeColor.palette.secondary.main,
                    fontSize: 12,
                    fontWeight: "bold"
                  }}
                >
                  Sign up.
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          Powered by{" "}
          <Link
            id="sentro_link"
            style={{ marginRight: 5 }}
            onClick={() => window.open(`${apiConfig.localHost}`)}
          >
            <img src={sentroLogo2} width={65} height="auto" />
          </Link>
        </div>
      </div>
    </div>
  );
}

Login = reduxForm({
  form: "loginForm"
})(Login);
