import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as Cart } from "../../../assets/images/buyer_dashboard/cart.svg";
import { ReactComponent as Purchased } from "../../../assets/images/buyer_dashboard/purchased.svg";
import { ReactComponent as Discount } from "../../../assets/images/enterprise/discounts.svg";
import { ReactComponent as Wishlist } from "../../../assets/images/buyer_dashboard/wishlist.svg";
import { ReactComponent as Profile } from "../../../assets/images/buyer_dashboard/profile.svg";
import history from "../../../store/history";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  indicator: {
    top: 0,
    height: 4,
    borderRadius: 4
  }
}));

export default function BottomNavigation(props) {
  const location = useLocation();
  const currentLoc = location.pathname;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const enterprise = useSelector(state => state.enterprise.main.enterprise);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const menu = [
    {
      label: "My Cart",
      icon: (
        <Cart
          fill={value == 0 ? "#f97663" : "#858997"}
          style={{ width: "auto", height: 25 }}
        />
      ),
      path: `/my_cart`,
      key: 0
    },
    {
      label: "Purchased",
      icon: (
        <Purchased
          fill={value == 1 ? "#f97663" : "#858997"}
          style={{ width: "auto", height: 25 }}
        />
      ),
      path: `/purchased`,
      key: 1
    },
    {
      label: "Wishlist",
      icon: (
        <Wishlist
          fill={value == 2 ? "#f97663" : "#858997"}
          style={{ width: "auto", height: 25 }}
        />
      ),
      path: `/enterprise/${enterprise.site_name}/buyer_dashboard/wishlist`,
      key: 2,
      isDisabled: true
    },
    {
      label: "Profile",
      icon: (
        <Profile
          fill={value == 3 ? "#f97663" : "#858997"}
          style={{ width: "auto", height: 25 }}
        />
      ),
      path: `/profile`,
      key: 3
    }
  ];

  React.useEffect(() => {
    if (currentLoc.includes("/my_cart")) {
      setValue(0);
    } else if (currentLoc.includes("/purchased")) {
      setValue(1);
    } else if (currentLoc.includes("/profile")) {
      setValue(3);
    }
  }, [currentLoc]);

  return (
    <div className={classes.root}>
      <AppBar
        style={{
          backgroundColor: "#fff",
          boxShadow: "none",
          borderTop: "1px solid #d1d5dd"
        }}
        position="static"
      >
        <Tabs
          classes={{ indicator: classes.indicator }}
          indicatorColor={"primary"}
          style={{ display: "flex" }}
          onChange={handleChange}
          value={value}
          aria-label="simple tabs example"
        >
          {menu.map((item, key) => (
            <Tab
              disabled={item.isDisabled}
              icon={item.icon}
              style={{
                flexGrow: 3,
                color: value == key ? "#f97663" : "#858997"
              }}
              key={key}
              label={item.label}
              onClick={() => history.push(item.path)}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
