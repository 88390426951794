import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Button,
  Divider
} from "@material-ui/core";
import minimalistPlaceholder from "../../../../assets/images/template assets/minimalistFooter.svg";
import PinchZoomPan from "../../../lib/image-zoom-pan/PinchZoomPan";

import { LocationOn, PhoneAndroid, Phone, Email } from "@material-ui/icons";
import defaultSRC from "./defaultResources";
const Contact = props => {
  let { isPreview = false } = props;
  let storeInfo = props.store;

  function setImageSettings(name, value) {
    if (isPreview && props.setImageSettings) {
      props.setImageSettings(name, value);
    }
  }

  function createPreview(file) {
    var blob = new Blob([file], { type: file.type });
    var blobUrl = URL.createObjectURL(blob);

    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function() {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function() {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    return blobUrl;
  }

  var pinchPropsContact = {
    position: "center",
    initialScale: "auto",
    allowEdit: isPreview ? true : false,
    zoomButtons: isPreview ? true : false,
    maxScale: 15,
    onImageStateChange: image_params =>
      setImageSettings("contact", image_params)
  };

  if (props.store.image_settings) {
    let { contact } = props.store.image_settings;
    if (contact) {
      let { top, left, scale } = contact;
      delete pinchPropsContact.position;
      pinchPropsContact.initialTop = top;
      pinchPropsContact.initialLeft = left;
      pinchPropsContact.initialScale = scale || "auto";
    }
  }

  const ContactStyles = makeStyles(theme => ({
    contact: {
      position: "relative",
      color: "#000",
      width: "100%",
      height: "100vh",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 1,
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {}
    },

    about: {
      display: "flex",
      justifyContent: "center",
      padding: "12vw",
      [theme.breakpoints.down("md")]: {
        padding: "20vh 0"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "15vh 0"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "25vh 0"
      }
    },
    box: {
      maxWidth: 600,
      backgroundColor: "rgba(0,0,0,0.5)",
      padding: "4vw",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      [theme.breakpoints.down("sm")]: {
        padding: "4vw",
        minWidth: 500
      },
      [theme.breakpoints.down("xs")]: {
        padding: "4vw",
        minWidth: 320,
        maxWidth: 320
      },
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)"
    },
    button: {
      border: "none",
      color: "white",
      fontSize: 16,
      fontWeight: 600,
      padding: "7px 52px",
      height: "55px",
      backgroundColor: "#F97663",
      marginTop: "3vh"
    },

    contactDetails: {
      marginLeft: 5,
      width: 400,

      wordWrap: "break-word",
      [theme.breakpoints.down("sm")]: {
        width: "80%",
        fontSize: 16
      }
    },

    contactUs: {
      color: "#fff",
      paddingBottom: "3vh",
      fontFamily: storeInfo && storeInfo.font.family,
      textAlign: "center",
      fontSize: 36,
      [theme.breakpoints.down("xs")]: {
        fontSize: 24
      }
    }
  }));

  const classes = ContactStyles();

  return (
    <div
      className={classes.contact}
      style={{
        backgroundImage: props.store.contactus_image
          ? `url(${
              typeof props.store.contactus_image === "object"
                ? createPreview(props.store.contactus_image)
                : props.store.contactus_image
            })`
          : `url(${minimalistPlaceholder})`
      }}
    >
      {/* {props.store.aboutus_image ? (
        <PinchZoomPan {...pinchPropsContact}>
          <img src={props.store.contactus_image} />
        </PinchZoomPan>
      ) : (
        ""
      )} */}
      {/* <div
        style={{
					position: "absolute",
					top: 0,
          width: "100%",
          height: "100vh",
          zIndex: -5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      > */}
      {/* <Box>
          <Container>
            <div className={classes.about}> */}

      <Box className={classes.box}>
        <Box>
          <Box style={{ fontSize: 16, color: "#fff", marginBottom: "2vh" }}>
            <Typography className={classes.contactUs}>CONTACT US</Typography>
          </Box>
          {props.store ? (
            props.store.address ? (
              <Box
                style={{
                  fontSize: 16,
                  color: "#fff",
                  marginBottom: "4vh",
                  wordWrap: "break-word",
                  display: "flex",
                  width: "100%"
                }}
              >
                <LocationOn style={{ marginRight: "2vw" }} />
                <div
                  style={{
                    marginLeft: 5,
                    width: "95%",
                    wordWrap: "break-word"
                  }}
                >
                  {props.store.address}
                </div>
              </Box>
            ) : (
              <Box
                style={{
                  fontSize: 16,
                  color: "#fff",
                  marginBottom: "4vh",
                  wordWrap: "break-word",
                  display: isPreview ? "flex" : "",
                  width: "100%"
                }}
              >
                <LocationOn
                  style={{
                    marginRight: "2vw",
                    display: isPreview ? "" : "none"
                  }}
                />
                <div
                  style={{
                    marginLeft: 5,
                    width: "95%",
                    wordWrap: "break-word"
                  }}
                >
                  {props.isPreview ? "Business Address" : ""}
                </div>
              </Box>
            )
          ) : (
            ""
          )}

          {props.store ? (
            props.store.mobile ? (
              <Box
                style={{
                  fontSize: 16,
                  color: "#fff",
                  marginBottom: "4vh",
                  wordWrap: "break-word",
                  display: "flex"
                }}
              >
                <PhoneAndroid style={{ marginRight: "2vw" }} />
                <div
                  style={{
                    marginLeft: 5,
                    width: "100%",
                    wordWrap: "break-word"
                  }}
                >
                  {props.store.mobile.charAt(0) != 0
                    ? "+63" + props.store.mobile
                    : "+63" + props.store.mobile.substr(1)}
                </div>
              </Box>
            ) : (
              <Box
                style={{
                  fontSize: 16,
                  color: "#fff",
                  marginBottom: "4vh",
                  wordWrap: "break-word",
                  display: props.isPreview ? "flex" : "none"
                }}
              >
                <PhoneAndroid
                  style={{
                    marginRight: "2vw",
                    display: props.isPreview ? "" : "none"
                  }}
                />
                <div
                  style={{
                    marginLeft: 5,
                    width: "100%",
                    wordWrap: "break-word"
                  }}
                >
                  {props.isPreview ? "Business Mobile Number" : ""}
                </div>
              </Box>
            )
          ) : (
            " "
          )}

          {props.store ? (
            props.store.landline ? (
              <Box
                style={{
                  fontSize: 16,
                  color: "#fff",
                  marginBottom: "4vh",
                  wordWrap: "break-word",
                  display: props.store.landline === "null" ? "none" : "flex"
                }}
              >
                <Phone style={{ marginRight: "2vw" }} />

                <div
                  style={{
                    marginLeft: 5,
                    width: 300
                  }}
                >
                  {props.store.landline}
                </div>
              </Box>
            ) : (
              <Box
                style={{
                  fontSize: 16,
                  color: "#fff",
                  marginBottom: "4vh",
                  wordWrap: "break-word",
                  display: props.isPreview ? "flex" : "none"
                }}
              >
                <Phone
                  style={{
                    marginRight: "2vw",
                    display: props.isPreview ? "" : "none"
                  }}
                />

                <div
                  style={{
                    marginLeft: 5,
                    width: 300
                  }}
                >
                  {props.isPreview ? "Business Landline" : ""}
                </div>
              </Box>
            )
          ) : (
            ""
          )}
          {props.store ? (
            props.store.email ? (
              <Box
                style={{
                  fontSize: 16,
                  width: "95%",
                  color: "#fff",
                  marginBottom: "4vh",
                  wordWrap: "break-word",
                  display: props.store.email === "null" ? "none" : "flex"
                }}
              >
                <Email style={{ marginRight: "2vw" }} />{" "}
                <div
                  style={{
                    marginLeft: 5,

                    width: "85%",
                    wordWrap: "break-word"
                  }}
                >
                  {props.store.email}
                </div>
              </Box>
            ) : (
              <Box
                style={{
                  fontSize: 16,
                  width: "95%",
                  color: "#fff",
                  marginBottom: "4vh",
                  wordWrap: "break-word",
                  display: props.isPreview ? "flex" : "none"
                }}
              >
                <Email
                  style={{
                    marginRight: "2vw",
                    display: props.isPreview ? "" : "none"
                  }}
                />{" "}
                <div
                  style={{
                    marginLeft: 5,

                    width: "85%",
                    wordWrap: "break-word"
                  }}
                >
                  {props.isPreview ? "Business Email" : ""}
                </div>
              </Box>
            )
          ) : (
            " "
          )}
        </Box>
      </Box>

      {/* </div>
          </Container>
        </Box> */}
      {/* </div> */}
    </div>
  );
};

export default Contact;
