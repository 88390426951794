import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Chip,
  IconButton
} from '@material-ui/core';
import {
  Button,
} from "@material-ui/core";
import { ReactComponent as TrashSVG } from '../../../../../../assets/images/enterprise/trash.svg';
import { ReactComponent as PenSVG } from '../../../../../../assets/images/enterprise/pen.svg';



const AddressItem = props => {
  const theme = useTheme();
  const useStyles = makeStyles(theme => ({
    root: {
      border: `1px solid ${props.active ? theme.palette.primary.main: "#D4D5D8"}`,
      padding: "1em 1.5em",
      borderRadius: 4,
      marginBottom: "2em",
      [theme.breakpoints.down("sm")]: {
        ...(!props.active ? {
          borderLeft: "none",
          borderRight: "none",
          borderTop: "none",
          borderBottom: "1px solid #D4D5D8",
          marginTop: 0,
        }: {
          border: `1px solid ${props.active ? theme.palette.primary.main: "#D4D5D8"}`
        })
      }
    },
    label: {
      color: "rgba(43, 45, 50, 0.8)",
      fontSize: 12,
      marginRight: "2em",
      fontWeight: 600,
      minWidth: 70
    },
    value: {
      color: "#2B2D32",
      fontSize: 14,
      fontWeight: 500
    },
    addBtn: {
      height: 38,
      marginLeft: "auto",
      minWidth: 100,
    },
    chip: {
      height: 25
    }
  }));
  const classes = useStyles();
  const values = props.data;
  const address = `${values.street}, ${values.brgy}, ${values.city}, ${values.province}${values.zipcode ? ` ${values.zipcode}`: ""}`;
  const deleteDisabled = props.addressCount === 1;

  const onEdit = () => {
    if(props.onEdit) props.onEdit(props.index, props.data);
  }
  const onDelete = () => {
    if(props.onDelete) props.onDelete(props.index, props.data);
  }

  const rootOnClick = () => {
    if (props.onClick) props.onClick();
  }

  return (
    <div className={classes.root} onClick={rootOnClick}>
      <div className="d-flex align-items-center">
        <div className="d-flex">
          <div style={{ textTransform: "capitalize" }}>
            <Chip className={`mr-2 ${classes.chip} label-chip`} size="medium" label={props.data.label} />
          </div>
          {
            props.data.is_default ?
            <Chip className={classes.chip} label="Default" size="medium" variant="outlined" />
            : ""
          }
        </div>
        {
          !props.hideActions ? 
          <div className="d-flex ml-auto">
            <IconButton className="mr-2 edit-button" onClick={onEdit}>
              <PenSVG fill="#858997" style={{height: 20, width: 20}} />
            </IconButton>
            <IconButton className="delete-button" onClick={onDelete} disabled={deleteDisabled}>
              <TrashSVG fill={deleteDisabled ? `#cccccc`: `#858997`} style={{height: 20, width: 20}} />
            </IconButton>
          </div>
          : ""
        }
      </div>
      <div className="mt-3">
        <div className="d-flex align-items-start">
          <p className={classes.label}>
            Address
          </p>
          <p className={`address ${classes.value}`}>
            {address}
          </p>
        </div>
        <div className="d-flex align-items-start flex-wrap">
          <p className={classes.label}>
            Phone
          </p>
          <p className={`phone ${classes.value}`}>
            +63{' '}{props.data.mobile_number}
          </p>
          {
            !props.hideActions && !props.data.is_default ? 
            <Button variant="outlined" color="primary" className={classes.addBtn}
              onClick={() => props.setDefault(props.index)}>
              Set as default
            </Button>
            : ""
          }
        </div>
      </div>
    </div>
  );
};

export default AddressItem;