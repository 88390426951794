import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button, Link, useMediaQuery, Chip } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import templateDisplayImage, {
  recommended_templates,
} from './templateDisplayImage';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import history from '../../../../../store/history';
import Path from '../../../../../Paths';
import { ReduxInitializeFormActions } from '../../../websiteBuilderActions';
import setDefaultImage from '../../../../common/layout/mobile/webBuilder/common/setDefaultImage';

export default function CustomizeTemplateForm(props) {
  const {
    storeInfo,
    values,
    setValues,
    open,
    setOpen,
    handleViewDemo,
    handleUseTemplate,
  } = props;
  const useStyles = makeStyles((theme) => ({
    form: {
      backgroundColor: '#fff',
      border: '1px solid #d1d5dd',
      borderRadius: 5,
    },
    btn_recommend: {
      margin: 8,
      height: 38,
      fontSize: 12,
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const loading = useSelector((state) => state.webBuilder.loading);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const initialData = {
    logo_image: [storeInfo.logo_image ? storeInfo.logo_image : ''],
    color: storeInfo && storeInfo.color,
    primary_background_color: storeInfo && storeInfo.color.primary.main,
    primary_text_color: storeInfo && storeInfo.color.primary.contrastText,
    secondary_background_color: storeInfo && storeInfo.color.secondary.main,
    secondary_text_color: storeInfo && storeInfo.color.secondary.contrastText,
    font: storeInfo && storeInfo.font,
    cover_image: {
      cropped_image: !storeInfo.cover_image
        ? setDefaultImage(storeInfo.template, 'cover_image')
        : storeInfo.cover_image,
      raw_image: !storeInfo.cover_original_image
        ? setDefaultImage(storeInfo.template, 'cover_image')
        : storeInfo.cover_original_image,
    },
    aboutus_image: {
      cropped_image: !storeInfo.aboutus_image
        ? setDefaultImage(storeInfo.template, 'aboutus_image')
        : storeInfo.aboutus_image,
      raw_image: !storeInfo.aboutus_original_image
        ? setDefaultImage(storeInfo.template, 'aboutus_image')
        : storeInfo.aboutus_original_image,
    },
    contactus_image: {
      cropped_image: !storeInfo.contactus_image
        ? setDefaultImage(storeInfo.template, 'contactus_image')
        : storeInfo.contactus_image,
      raw_image: !storeInfo.contactus_original_image
        ? setDefaultImage(storeInfo.template, 'contactus_image')
        : storeInfo.contactus_original_image,
    },
  };

  return (
    <div>
      <div className={classes.form}>
        <div
          style={{
            borderBottom: '1px solid #d1d5dd',
            padding: isMobile ? 24 : 32,
          }}
        >
          <div
            className={`d-flex align-items-center ${
              isMobile ? 'justify-content-center' : 'justify-content-between'
            }`}
          >
            {loading ? (
              <Skeleton animation="wave" height={20} width={'30%'} />
            ) : (
              <div
                style={{
                  fontSize: 14,
                }}
              >
                You are using the{' '}
                <span style={{ fontWeight: 600 }} id="template">
                  {storeInfo.template}
                </span>{' '}
                template.
              </div>
            )}
            <div className={`${isMobile ? 'd-none' : 'd-flex'}`}>
              <Button
                id="replace"
                variant="outlined"
                color="primary"
                style={{ marginRight: 10, height: 40 }}
                disabled={loading}
                onClick={() =>
                  isMobile
                    ? props.setValues({
                        ...props.values,
                        template_library_open: true,
                      })
                    : history.push(Path.TEMPLATE_LIBRARY)
                }
              >
                Replace
              </Button>
              <Button
                disableElevation
                id="customize"
                variant="contained"
                color="primary"
                style={{ height: 40 }}
                disabled={loading}
                onClick={() => {
                  setOpen({ ...open, customize: true });
                  dispatch(
                    ReduxInitializeFormActions.initializeCustomizeShopData(
                      initialData
                    )
                  );
                }}
              >
                Customize
              </Button>
            </div>
          </div>
          <div className="my-3">
            {loading ? (
              ''
            ) : (
              <img
                src={templateDisplayImage(storeInfo.template)}
                id="display_img"
                alt="display_img"
                width="100%"
                height="auto"
              />
            )}
          </div>
          <div
            className={`${
              isMobile ? 'd-flex' : 'd-none'
            } justify-content-center`}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 10, height: 40 }}
              onClick={() =>
                setValues({ ...values, template_library_open: true })
              }
            >
              Replace
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              style={{ height: 40 }}
              onClick={() => {
                setOpen({ ...open, customize: true });
                dispatch(
                  ReduxInitializeFormActions.initializeCustomizeShopData(
                    initialData
                  )
                );
              }}
            >
              Customize
            </Button>
          </div>
        </div>
        <div style={{ padding: isMobile ? 24 : 32 }}>
          <strong style={{ fontSize: 16, color: '#f97663' }}>
            Recommended templates
          </strong>
          <div className="my-3" style={{ fontSize: 14 }}>
            Choose from any of the following templates{' '}
            {props.subscription && !props.subscription.subscription_type ? (
              <>
                or{' '}
                <Link
                  color="primary"
                  onClick={() => history.push(Path.SENTRO_ADVANCED)}
                >
                  upgrade to Sentro Advanced
                </Link>{' '}
                for more
              </>
            ) : (
              ''
            )}
            .
          </div>
          <div className="col-12 px-0 my-4">
            {recommended_templates.map((item) => (
              <div
                id="recommended_templates"
                className={`${isMobile ? 'd-block' : 'd-flex'} mb-3`}
              >
                <div className="col-xl-4 col-sm-12 pl-0">
                  <div>
                    <div
                      className="d-md-block d-flex align-items-center"
                      style={{
                        justifyContent: isMobile ? 'space-between' : 'start',
                      }}
                    >
                      <strong style={{ fontSize: 16 }}>{item.template}</strong>
                      <div className="my-2">
                        <Chip
                          size="small"
                          label={item.is_paid ? 'Premium' : 'FREE'}
                          style={{
                            backgroundColor: item.is_paid
                              ? '#CE6E61'
                              : '#EEEEEE',
                            color: item.is_paid ? '#fff' : '#2B2D32CC',
                          }}
                        />
                      </div>
                    </div>
                    {isMobile ? (
                      ''
                    ) : (
                      <div
                        className="my-2"
                        style={{
                          fontSize: 12,
                          color: 'rgba(43, 45, 50, 0.64)',
                          width: '80%',
                        }}
                      >
                        {item.is_paid
                          ? 'Available for Sentro Advanced users'
                          : 'Free to use'}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="col-xl-8 col-sm-12"
                  style={{ padding: isMobile ? '0 0 1em' : 'auto' }}
                >
                  <img
                    src={templateDisplayImage(item.value)}
                    width="100%"
                    height="auto"
                  />
                  <div className="d-flex justify-content-center">
                    {item.is_paid &&
                    props.subscription &&
                    !props.subscription.subscription_type ? (
                      ''
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.btn_recommend}
                        onClick={() => {
                          setValues({ ...values, template: item.value });
                          setOpen({ ...open, dialog: true });
                        }}
                      >
                        Use this template
                      </Button>
                    )}

                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.btn_recommend}
                      onClick={() => handleViewDemo(item)}
                    >
                      Preview
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end my-4">
            <Link
              color="primary"
              style={{ fontSize: 12 }}
              onClick={() =>
                isMobile
                  ? props.setValues({
                      ...props.values,
                      template_library_open: true,
                    })
                  : history.push(Path.TEMPLATE_LIBRARY)
              }
            >
              View more templates <ArrowForwardIcon style={{ fontSize: 12 }} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
