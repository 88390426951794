import React from "react";
import StoreProductDialog from "./StoreProductDialog";
import SentroButton from "../../common/sentroButton";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "./ProductCard";
import Text from "../../../common/text";
import { makeStyles, Dialog } from "@material-ui/core";
import AddProductDialogBox from "./AddProductDialogBox";
import {
  addProduct,
  getProducts,
  removeProduct,
  editProduct
} from "../websiteBuilderActions";
import StoreEditProductDialog from "./StoreEditProductDialog";
import moment from "moment";

let DeleteProductModal = props => {
  const { openDelete, setOpenDelete, product, deleteProduct } = props;
  return (
    <Dialog open={openDelete}>
      <div style={{ padding: 20 }}>
        <div style={{ fontSize: 24, fontWeight: 600, marginBottom: 20 }}>
          Delete Product
        </div>
        <div style={{ fontSize: 18, marginBottom: 20 }}>
          Are you sure you want to delete{" "}
          <span style={{ fontWeight: 800, marginBottom: 20 }}>
            {product.name}
          </span>{" "}
          ?
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SentroButton
            id="close_button"
            style={{ marginTop: 0, padding: 0 }}
            label="Close"
            onClick={() => setOpenDelete(false)}
          />
          <SentroButton
            id="delete_button"
            style={{ marginTop: 0, padding: 0 }}
            label="Delete"
            onClick={() => deleteProduct(product)}
          />
        </div>
      </div>
    </Dialog>
  );
};

let ProductTable = props => {
  // GTM trigger
  React.useEffect(() => {
    let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    window.dataLayer.push({
      eventAction: "Step - Products",
      eventCategory: "Store Builder",
      event: "storebuildersteptrack",
      userId: currentUser.id
    });

    // HotJar heat map
    if (typeof window.hj === "function") {
      window.hj("trigger", "storeBuilderProducts");
    }
  }, []);

  const dispatch = useDispatch();
  let currentStoreCreated = useSelector(state => state.webBuilder.storeInfo);
  let currentStoreProducts = useSelector(state => state.webBuilder.products);
  let dialogOpen = useSelector(state => state.webBuilder.dialogOpen);
  let message = useSelector(state => state.webBuilder.message);
  const ProductTableStyles = makeStyles(theme => ({
    products: {
      minHeight: "25vh",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center"
    },
    productSection: {
      padding: props.show ? "0 59px" : "0 30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    addProductBtn: {
      display: currentStoreProducts.length > 0 ? "flex" : "none"
    }
  }));

  const classes = ProductTableStyles();

  const [openDelete, setOpenDelete] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setEdit] = React.useState(false);
  const [imageList, setImageList] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    props.setIsFormEnough(currentStoreProducts.length > 0);
  }, [currentStoreProducts]);
  const [product, setProduct] = React.useState({
    store: currentStoreCreated ? currentStoreCreated.uuid : "",
    name: "",
    price: "",
    description: "",
    category: "",
    is_active: true,
    always_available: true,
    quantity: 5,
    images: "",
    productImages: [],
    photo_primary: "0",
    product_code: `${
      currentStoreCreated
        ? currentStoreCreated.site.toUpperCase() -
          (currentStoreProducts.length + 1)
        : ""
    }`
  });
  const [productDetails] = React.useState({
    store: currentStoreCreated ? currentStoreCreated.uuid : "",
    name: "",
    price: "",
    description: "",
    category: "",
    is_active: true,
    always_available: true,
    quantity: 5,
    images: "",
    productImages: [],
    photo_primary: "0",
    product_code: `${
      currentStoreCreated
        ? currentStoreCreated.site.toUpperCase() -
          (currentStoreProducts.length + 1)
        : ""
    }`
  });

  React.useEffect(() => {
    dispatch(getProducts(currentStoreCreated ? currentStoreCreated.uuid : ""));
  }, []);

  function handleEditProduct(product) {
    imageList.length = 0;
    for (let i in product["gallery"]) {
      imageList.push(product["gallery"][i].image);
    }
    setEdit(true);
    setProduct(product);
  }

  function handleDeleteProduct(product) {
    setOpenDelete(true);
    setProduct(product);
  }

  function handleSaveProduct() {
    dispatch(addProduct(product));
    setOpenAdd(false);
  }

  function handleSaveAddProduct() {
    dispatch(addProduct(product));
    setOpenAdd(false);
    setProduct(productDetails);
    setOpenAdd(true);
    setVisible(true);
  }

  function handleSaveEditProduct() {
    dispatch(editProduct(product, imageList));
    setEdit(false);
  }

  function deleteProduct(product) {
    dispatch(removeProduct(product));
    setOpenDelete(false);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: props.show ? "0 59px" : "0 30px"
        }}
      >
        <p
          style={{ color: "#54575f", fontSize: 12, fontWeight: 500, margin: 0 }}
        >
          <sup style={{ color: "red" }}>*</sup>Required
        </p>
      </div>
      <div className={classes.productSection}>
        <div style={{ display: "flex", margin: "20px 0" }}>
          <Text
            style={{ fontSize: 16, fontWeight: 600 }}
            content={
              props.language === "Filipino"
                ? "Ano ang iyong mga binebenta?"
                : "What products are you selling?"
            }
          />
          <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
        </div>
        <SentroButton
          id="addProduct_button"
          style={{ display: "flex", justifyContent: "flex-end" }}
          label={
            props.language === "Filipino"
              ? "+ Magdagdag ng produkto"
              : "+ Add new product"
          }
          variant="outlined"
          onClick={() => {
            setProduct(productDetails);
            productDetails.productImages.length = 0;
            setOpenAdd(true);
          }}
        />
      </div>

      <div>
        <div className={classes.products}>
          {currentStoreProducts
            ? currentStoreProducts
                .sort(
                  (a, b) =>
                    moment(b.updated_on).format("YYYYMMDDhhmmss") -
                    moment(a.updated_on).format("YYYYMMDDhhmmss")
                )
                .map((item, key) => {
                  return (
                    <ProductCard
                      handleEditProduct={value => handleEditProduct(value)}
                      handleDeleteProduct={value => handleDeleteProduct(value)}
                      item={item}
                    />
                  );
                })
            : ""}
        </div>
        <SentroButton
          id="addProduct_button"
          style={{ marginLeft: props.show ? "1vw" : 0 }}
          className={classes.addProductBtn}
          label={
            props.language === "Filipino"
              ? "+ Magdagdag ng produkto"
              : "+ Add new product"
          }
          variant="outlined"
          onClick={() => {
            setProduct(productDetails);
            productDetails.productImages.length = 0;
            setOpenAdd(true);
          }}
        />
      </div>

      <StoreProductDialog
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        productDetails={productDetails}
        product={product}
        setProduct={setProduct}
        handleSaveProduct={handleSaveProduct}
        handleSaveAddProduct={handleSaveAddProduct}
      />

      <StoreEditProductDialog
        openEdit={openEdit}
        setEdit={setEdit}
        product={product}
        setProduct={setProduct}
        imageList={imageList}
        handleSaveEditProduct={handleSaveEditProduct}
      />

      <AddProductDialogBox
        open={dialogOpen}
        alertType={message ? message.type : "success"}
        message={message ? message.message : ""}
      />

      <DeleteProductModal
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        product={product}
        deleteProduct={deleteProduct}
      />
    </div>
  );
};
export default ProductTable;
