import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "../../../store/history";
import { Button, useMediaQuery } from "@material-ui/core";
import DeliveryRates from "../../enterprise/components/delivery/deliveryRates";
import BuyerActions from "./redux/actions";
import SummaryForm from "./common/summaryForm";
import VoucherModal from "./common/voucherModal";
import Path from "../../../Paths";
function Delivery(props) {
  const useStyles = makeStyles(theme => ({
    header: {
      paddingTop: "5vh",
      width: "65%",
      display: "flex",
      alignItems: "center",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "block"
      }
    },
    headerLabel: {
      fontSize: 28,
      fontWeight: 600,
      color: "#002c42",
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        position: "inherit"
      }
    },
    label: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2b2d32"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [flat_rate, set_flat_rate] = React.useState("");
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const product_selected = useSelector(
    state => state.buyerDashboard.selected_checkout.items
  );
  const selected_order = useSelector(
    state => state.buyerDashboard.selected_order
  );
  const delivery_locations = useSelector(
    state => state.buyerDashboard.delivery_locations
  );
  const delivery_details = useSelector(
    state => state.buyerDashboard.delivery_details
  );
  const customer_details = useSelector(
    state => state.form.customer_details?.values
  );
  const pickup_address =
    selected_order && selected_order.store_data.pickup_address;

  const pickup_google_address =
    pickup_address && pickup_address.length
      ? pickup_address[0].google_address
      : "";

  const computeShipping = async params => {
    let values = {
      matter: "",
      vehicle_type: 8,
      weight_kg: 5,
      motobox: false,
      src_address: pickup_google_address.address,
      dst_address: params.address,
      src_coord: {
        latitude: pickup_google_address.lat,
        longitude: pickup_google_address.lng
      },
      dst_coord: {
        latitude: params.latitude,
        longitude: params.longitude
      }
    };
    dispatch(
      BuyerActions.setDeliveryDetails({
        ...delivery_details,
        mrspeedy_delivery_address: {
          address: params.address,
          longitude: params.longitude,
          latitude: params.latitude
        }
      })
    );
    await dispatch(
      BuyerActions.computeShipping(values, selected_order.store_data.uuid)
    );
  };

  const setDeliveryDetails = (shipping, flat_rate) => {
    let params = {
      ...delivery_details,
      shipping: shipping,
      shipping_fee: 0,
      delivery_detail: "",
      mrspeedy_delivery_address: { address: "", longitude: "", latitude: "" }
    };

    if (shipping === "flat_rate") {
      params = {
        ...params,
        shipping_fee: flat_rate ? flat_rate.rate : 0,
        delivery_detail: flat_rate ? flat_rate : ""
      };
    } else if (shipping === "mr_speedy") {
      params = { ...params, shipping_fee: 0 };
    } else if (shipping === "pick_up") {
      params = {
        ...params,
        shipping_fee: 0,
        delivery_detail: pickup_address[0]
      };
    }

    dispatch(BuyerActions.setDeliveryDetails(params));
  };

  const isDisabled = () => {
    let pickup = pickup_address && pickup_address.length ? pickup_address : [];

    if (!delivery_locations.length && !pickup.length) {
      return false;
    } else {
      if (!delivery_details.shipping) {
        return true;
      } else if (
        delivery_details.shipping === "mr_speedy" &&
        !delivery_details.delivery_detail
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  React.useEffect(() => {
    if (selected_order) {
      dispatch(
        BuyerActions.getDeliveryLocations(selected_order.store_data.uuid)
      );
    }
  }, [selected_order]);

  React.useEffect(() => {
    if (!product_selected.length) {
      history.push(Path.BUYER_DASHBOARD);
    }
  }, [product_selected]);
  return (
    <div style={{ paddingBottom: isMobile ? "50vh" : "auto" }}>
      <div className={classes.header}>
        <Button
          startIcon={<ArrowBackIcon color="primary" />}
          variant="text"
          color="primary"
          onClick={() => {
            history.goBack();
            window.scrollTo(0, 0);
          }}
          style={{ position: isMobile ? "inherit" : "absolute" }}
        >
          Back
        </Button>
        <div className={`${classes.headerLabel} d-md-block`}>
          Delivery Details
        </div>
      </div>
      <div style={{ display: isMobile ? "block" : "flex" }}>
        <DeliveryRates
          flat_rate={flat_rate}
          set_flat_rate={set_flat_rate}
          selected_order={selected_order}
          delivery_locations={delivery_locations}
          delivery_details={delivery_details}
          customer_details={{
            ...customer_details,
            streetAddress: customer_details && customer_details.street_address,
            barangayAddress: customer_details && customer_details.barangay,
            cityAddress: customer_details && customer_details.city,
            provinceAddress: customer_details && customer_details.province,
            postalCode: customer_details && customer_details.postal_code
          }}
          computeShipping={computeShipping}
          setDeliveryDetails={setDeliveryDetails}
        />
        <SummaryForm
          proceed_to_checkout={() => history.push(`/my_cart/payment`)}
          disabled={isDisabled() || delivery_details.loading}
        />
      </div>
      <VoucherModal open={openVoucher} setOpen={setOpenVoucher} />
    </div>
  );
}
export default Delivery;
