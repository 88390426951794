import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
} from "@material-ui/core";
import Text from "../../../../../common/text";
import AddIcon from "@material-ui/icons/Add";
import AddVariantGroup from "../addVariantGroup";
import EditVariantGroup from "../editVariantGroup";
import defaultVariants from "../defaultVariants";
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

const Variants = props => {
  const useStyles = makeStyles(theme => ({
    variantHeaderSection: {
      marginTop: "3vh",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    btnColor: {
      fontSize: 10,
      height: 33,
      padding: "0 20px",
      marginRight: 10,
      fontWeight: 600,
      textTransform: "capitalize"
    },
  }));
  const classes = useStyles();
  const [variantModal, setVariantModal] = React.useState({
    addVariantModal: false,
    editVariantModal: false,
    index: ""
  });
  const [productVariants, setProductVariants] = React.useState(defaultVariants);
  const [variant, setVariant] = React.useState([]);


  function handleEditVariant(variant, key) {
    setVariantModal({ ...variantModal, editVariantModal: true, index: key });
    setVariant(variant);
  }


  function countSelectedVariant(variant) {
    let variants = variant.variants;
    let numOfSelected = variants.filter(item => item.is_enabled === true);
    return variant.name + ` (${numOfSelected.length} Selected)`;
  }

  function addSelectedDefault(item, variant) {
    let variants = variant.variants.filter(e => e.name !== item.name);
    const param = {
      ...variant,
      variants: [
        ...variants,
        {
          key: item.key,
          name: item.name,
          is_enabled: !item.is_enabled
        }
      ]
    };
    let newVariantsDefault =
      productVariants.length &&
      productVariants.filter(e => e.name !== param.name);
    setProductVariants([...newVariantsDefault, param]);
  }

  React.useEffect(() => {
    if (props.onChange) props.onChange(productVariants);
  }, [props, productVariants])

  React.useEffect(() => {
    if (props.value.length && props.product_uuid) {
      setProductVariants(props.value);
    }
  }, [props.value])


  return (
    <>
      <div className="position-relative">
        {productVariants.length &&
          productVariants
            .sort(function(a, b) {
              if (a.name.toLowerCase() < b.name.toLowerCase())
                return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase())
                return 1;
              return 0;
            })
            .map((variant, key) => {
              return (
                <div style={{ marginTop: "2vh" }}>
                  <div
                    className={classes.variantHeaderSection}
                  >
                    <Text
                      textType="p3_Semibold"
                      content={countSelectedVariant(variant)}
                    />
                    <Button
                      startIcon={
                        <BorderColorOutlinedIcon color="primary" />
                      }
                      variant="text"
                      color="primary"
                      className={classes.btnColor}
                      style={{ marginRight: 0 }}
                      onClick={() =>
                        handleEditVariant(variant, key)
                      }
                    >
                      Edit
                    </Button>
                  </div>
                  <div>
                    {variant.variants
                      .sort(function(a, b) {
                        if (
                          a.name.toLowerCase() <
                          b.name.toLowerCase()
                        )
                          return -1;
                        if (
                          a.name.toLowerCase() >
                          b.name.toLowerCase()
                        )
                          return 1;
                        return 0;
                      })
                      .map((item, key) => {
                        return (
                          <Button
                            size="small"
                            variant={
                              item.is_enabled
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              item.is_enabled
                                ? "primary"
                                : "secondary"
                            }
                            disableElevation
                            className={`${classes.btnColor} mt-3 variant`}
                            onClick={() =>
                              addSelectedDefault(
                                item,
                                variant
                              )
                            }
                          >
                            {item.name}
                          </Button>
                        );
                      })}
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      endIcon={<AddIcon />}
                      className={`${classes.btnColor} mt-3`}
                      style={{ padding: "0 10px" }}
                      onClick={() =>
                        handleEditVariant(variant, key)
                      }
                    >
                      Add
                    </Button>
                  </div>
                </div>
              );
            })}
        {productVariants.length < 2 && (
          <div
            className="d-flex"
            style={{ marginTop: "5vh" }}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              endIcon={<AddIcon />}
              className={`${classes.btnColor} ml-auto`}
              style={{ padding: "0 10px" }}
              onClick={() =>
                setVariantModal({
                  ...variantModal,
                  addVariantModal: true
                })
              }
            >
              Add Variant Group
            </Button>
          </div>
        )}
      </div>
      <AddVariantGroup
        open={variantModal}
        setOpen={setVariantModal}
        productVariants={productVariants}
        setProductVariants={setProductVariants}
      />
      <EditVariantGroup
        open={variantModal}
        setOpen={setVariantModal}
        variant={variant}
        setVariant={setVariant}
        productVariants={productVariants}
        setProductVariants={setProductVariants}
      />
    </>
  );
};

export default Variants;
