import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button } from '@material-ui/core';
import Truncate from 'react-truncate';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const SummaryCard = (props) => {
  const classes = makeStyles(theme => ({
    title: {
      fontSize: 24,
      fontWeight: "bold",
      color: "#002C42",
      marginBottom: 0,
      borderBottom: "1px solid #D1D5DD !important",
    },
    items_qty: {
      fontSize: 14,
      borderBottom: "1px dashed #D1D5DD",
    },
    subtotal: {
      fontSize: 16,
      padding: "1em 0",
      borderBottom: "1px dashed #D1D5DD"
    },
    total: {
      fontSize: 20,
      fontWeight: 700,
      padding: "0.5em 0 0"
    }
  }))()
  return (
    <Paper className={classes.paper} elevation={3}>
      <h3 className={`${classes.title} p-4`}>Summary</h3> 
      <div className="p-3">
        <p className={`pb-3 mb-0 ${classes.items_qty}`}>
          Items: {props.qty}
        </p>
        {
          props.subtotal && 
          <div className={`d-flex ${classes.subtotal}`}>
            <p className="mb-0">Subtotal</p>
            <p className="mb-0 ml-auto">USD {props.subtotal}</p>
          </div>
        }
        <div className={`d-flex ${classes.total}`}>
          <p className="mb-0">Total</p>
          <p className="mb-0 ml-auto">USD {props.total}</p>
        </div>
        {
          props.onClick &&
          <Button
            disabled={!props.qty}
            fullWidth
            className="mt-3"
            variant="contained"
            color="primary"
            onClick={() => {
              if (props.onClick) props.onClick();
            }}
          >
            Proceed to shipping details
          </Button>
        }
      </div>
    </Paper>
  )
}


const CartTable = (props) => {
  const classes = makeStyles(theme => ({
    subtitle: {
      fontSize: 24,
      fontWeight: "bold",
      color: "#002C42",
      marginBottom: 0
    },
    imgThumbnail: {
      height: 75,
      width: 75,
      backgroundPosition: "center",
      backgroundSize: "cover"
    },
    productName: {
      fontSize: 16,
      fontWeight: "bold",
      color: "#2B2D33",
      marginBottom: 0
    },
    productPrice: {
      fontSize: 16,
      fontWeight: "normal",
      color: "#2B2D33"
    },
    table: {
      "& th": {
        borderTop: "1px solid #D1D5DD",
        borderBottom: "1px solid #D1D5DD !important",
        fontSize: 16,
        color: "#54575F",
        fontWeight: "normal",
      },
      "& th:first-child": {
        textAlign: "center"
      },
      "& td": {
        border: "none",
        verticalAlign: "middle",
        padding: "1rem 1.5rem"
      },
      "& .name": {
        maxWidth: 250
      },
      "& .img": {
        maxWidth: 100
      },
      "& .delete": {
        color: "#95989f",
        cursor: "pointer"
      }
    }
  }))()
  return (
    <Paper elevation={3}>
      <h3 className={`p-4 ${classes.subtitle}`}>{props.title}</h3>
      
      {
        props.items.length ?
        <table className={`table ${classes.table}`}>
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Unit</th>
              <th scope="col">Quantity</th>
              {
                props.sellerView ?
                <>
                  <th scope="col">Subtotal</th>
                  <th scope="col">Stocks</th>
                </>
                :
                <th scope="col"></th>
              }
              {
                props.onDelete &&
                <th scope="col"></th>
              }
            </tr>
          </thead>
          <tbody>
            {
              props.items.map((product, index) => (
                <tr key={index}>
                  <td className="name">
                    <div className="d-flex">
                      <div>
                        <div className={classes.imgThumbnail} style={{
                          backgroundImage: `url(${product.image})`
                        }} />
                      </div>
                      <div className="ml-3">
                        <strong>
                          <Truncate lines={3} ellipsis="...">
                              <p className={`mb-0 ${classes.productName}`}>{product.short_name}</p>
                          </Truncate>
                        </strong>
                        <p className={classes.productPrice}>USD {(parseInt(product.unit_price)/100).toFixed(2)}</p>
                      </div>
                    </div>
                  </td>
                  <td>{product.quantity}</td>
                  <td>{product.quantity}</td>
                  <td><strong>USD {product.subtotal}</strong></td>
                  {
                    props.sellerView &&
                    <td>{product.stock}</td>
                  }
                  {
                    props.onDelete &&
                    <td><DeleteOutlineOutlinedIcon className="delete"
                      onClick={() => props.onDelete(index)}
                    /></td>
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
        :
        <div className="text-center py-4 text-secondary">
          No items found
        </div> 
      }
    </Paper>
  )
}
  
export { SummaryCard, CartTable };
