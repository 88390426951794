import React from "react";
import { Typography } from "@material-ui/core";
import Path from "../../../Paths";
import history from "../../../store/history";

let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
let keyData = data ? data.email : null;

export const aboutSentroAnswer1 = (
  <div>
    <Typography style={{ fontSize: 16 }}>
      Binuo ang Sentro para tulungan ang mga maliliit na negosyante na simulan
      at palaguin ang kanilang negosyo sa pamamagitan ng mga digital tools na
      inoofer nito. Isa sa mga main features ng Sentro ay ang Online Shop.
      Madali lang ito gamitin at sa loob ng ilang minuto ay maaari ka nang
      magbenta online at tumanggap ng bayad ng customer sa alin man sa aming mga
      partner stores (7-11, Cebuana, atbp.), online banking, at debit/cards
      (soon).
    </Typography>
    <a
      onClick={() => {
        keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      Mag sign-up na para makapag online selling!
    </a>
    .
  </div>
);

export const joiningSentroAnswer1 = (
  <div>
    <Typography style={{ fontSize: 16 }}>
      100% libre ang paggawa ng Sentro account.{" "}
      <a
        onClick={() => {
          keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
        }}
        style={{ color: "#F97663", fontWeight: 600 }}
      >
        Mag sign-up na para makapag online selling!
      </a>
    </Typography>
  </div>
);

export const aboutSentroShopAnswer1 = (
  <Typography style={{ fontSize: 16 }}>
    Sa paggawa ng iyong Sentro Online Shop, maaari niyo ng i-post ang lahat ng
    detalye ng iyong produkto at mismong business online. Meron din itong
    built-in payment future kung saan pwedeng bumili ang customer deretso sa
    iyong site at magbayad online (via online banking at debit card/credit card)
    o cash (7-Eleven, Cebuana, atbp.). Sa loob ng Sentro, pwede ka ring
    mag-request na ipadala ang mga nabenta mong produkto gamit ang aming mga
    delivery service partners. Dahil sa mga ito, nagiging mas madali na ang
    pagpapatakbo mo ng iyong negosyo online.{" "}
    <a
      onClick={() => {
        keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      Mag sign-up na para makapag online selling!
    </a>
  </Typography>
);

export const aboutSentroShopAnswer2 = (
  <Typography style={{ fontSize: 16 }}>
    Libre ang paggawa ng Online Shop sa Sentro.{" "}
    <a
      onClick={() => {
        keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      Mag sign-up na para makapag online selling!
    </a>
    .
  </Typography>
);

export const aboutSentroShopAnswer3 = (
  <Typography style={{ fontSize: 16 }}>
    Pwede kang magbenta ng kahit anong produktong gusto mo basta't hindi ito
    labag sa aming{" "}
    <a
      onClick={() => {
        history.push(Path.TNC);
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      Terms and Conditions
    </a>{" "}
    at sa batas.
  </Typography>
);

export const aboutSentroShopAnswer4 = (
  <Typography style={{ fontSize: 16 }}>
    Pwede kang mag-upload ng kahit ilang produktong gusto mo sa iyong online
    shop.
  </Typography>
);

export const aboutSentroShopAnswer5 = (
  <Typography style={{ fontSize: 16 }}>
    Para malaman kung paano mag log in at gumawa ng sariling Online Shop sa
    Sentro, panoorin ang short tutorial video namin gamit ang link na ito,{" "}
    <a
      onClick={() => {
        window.open(
          "https://www.facebook.com/SentroofAttention/videos/538353023513620/"
        );
      }}
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      I-click ang link na ito
    </a>
  </Typography>
);

export const purchaseAnswer1 = (
  <Typography style={{ fontSize: 16 }}>
    May kasamang payment function ang iyong Sentro online shop na pwedeng
    tumanggap ng online payments (sa pamamagitan ng online banking o
    credit/debit card), pati na rin ang pagtanggap ng cash (sa pamamagitan ng
    pagbayad sa 7-Eleven, Cebuana, atbp.). Ang payment feature na ito ay hatid
    sa inyo ng Bux. Kapag nag-sign up ka sa Sentro, automatic na magkakaroon ka
    rin ng sarili mong Bux account.{" "}
    <a
      href="https://bux.ph/faq"
      target="_blank"
      style={{ color: "#F97663", fontWeight: 600 }}
    >
      Mag-click dito para higit pang malaman kung ano ang Bux
    </a>
    .
  </Typography>
);

// export const gettingStartedAnswer1 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Binuo ang Sentro para tulungan ang mga maliliit na negosyante na simulan
//       at palaguin ang kanilang negosyo sa pamamagitan ng mga digital tools na
//       inoofer nito.
//     </Typography>
//     <Typography style={{ marginTop: 20 }}>
//       Isa sa mga main features ng Sentro ay ang Online Shop. Madali lang ito
//       gamitin at sa loob ng ilang minuto ay maaari ka nang magbenta online at
//       tumanggap ng bayad ng customer sa alin man sa aming mga partner stores
//       (7-11, Cebuana, atbp.), online banking, at debit/cards (soon).{" "}
//       <a
//         onClick={() => {
//           keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
//         }}
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Mag sign-up na para makapag online selling!
//       </a>
//     </Typography>
//   </div>
// );

// export const gettingStartedAnswer2 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       100% libre ang paggawa ng Sentro account.{" "}
//       <a
//         onClick={() => {
//           keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
//         }}
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Mag sign-up na para makapag online selling!
//       </a>
//     </Typography>
//   </div>
// );

// export const gettingStartedAnswer3 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Mas mahusay ang Sentro kapag ang iyong ginamit ay laptop o desktop, at ang
//       pinakabagong bersyon ng Google Chrome.
//     </Typography>
//   </div>
// );

// /// --------- ////

// export const createShopAnswer1 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Sa paggawa ng iyong Sentro Online Shop, maaari niyo ng i-post ang lahat ng
//       detalye ng iyong produkto at mismong business online. Meron din itong
//       built-in payment future kung saan pwedeng bumili ang customer deretso sa
//       iyong site at magbayad online (via online banking at debit card/credit
//       card) o cash (7-Eleven, Cebuana, atbp.). Sa loob ng Sentro, pwede ka ring
//       mag-request na ipadala ang mga nabenta mong produkto gamit ang aming mga
//       delivery service partners. Dahil sa mga ito, nagiging mas madali na ang
//       pagpapatakbo mo ng iyong negosyo online.{" "}
//       <a
//         onClick={() => {
//           keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
//         }}
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Mag sign-up na para makapag online selling!
//       </a>
//     </Typography>
//   </div>
// );

// export const createShopAnswer2 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Libre ang paggawa ng Online Shop sa Sentro.{" "}
//       <a
//         onClick={() => {
//           keyData ? history.push(Path.HOME) : history.push(Path.SIGN_UP);
//         }}
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Mag sign-up na para makapag online selling!
//       </a>
//     </Typography>
//   </div>
// );

// export const createShopAnswer3 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Sa Sentro, kahit sino pwedeng gumawa ng kanilang Online Shop. Hindi mo
//       kailangan ng coding o design background para magamit ito. Hindi mo na rin
//       kailangang magbayad ng programmer upang panatilihing maayos at gumagana
//       ang iyong shop. Sagutin mo lamang ang mga hinihinging detalye sa Online
//       Shop, i-publish ang shop mo, at tapos ka na - pwede ka ng magbenta!
//     </Typography>
//   </div>
// );

// export const createShopAnswer4 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Hindi na kailangan. Kapag gumawa ka ng online shop, automatic na may
//       sarili na itong web address - subdmain (hal. xyzshop.sentro.ph).
//     </Typography>
//   </div>
// );

// export const createShopAnswer5 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Sa ngayon, ang Sentro Online Shop - pati ang mga payment at logistics
//       functions - ay maaaring magamit lamang sa loob ng Pilipinas. Ngunit, ang
//       iyong website ay maaaring makita kahit saan sa mundo.
//     </Typography>
//   </div>
// );

// /// --------- ////

// export const sellingProductsAnswer1 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Pwede kang magbenta ng kahit anong produktong gusto mo basta't hindi ito
//       labag sa aming{" "}
//       <a
//         onClick={() => {
//           history.push(Path.TOU);
//         }}
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Terms and Conditions
//       </a>{" "}
//       at sa batas.
//     </Typography>
//   </div>
// );

// export const sellingProductsAnswer2 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Pwede kang mag-upload ng kahit ilang produktong gusto mo sa iyong online
//       shop.
//     </Typography>
//   </div>
// );

// export const sellingProductsAnswer3 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Oo naman! Pwede mo pa ring i-edit ang iyong online shop anumang oras.
//     </Typography>
//   </div>
// );

// export const sellingProductsAnswer4 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Oo, maaari mong isara o i-delete ang iyong online shop. Kapag na-delete mo
//       na ito, hindi mo na ito mababawi. Kung nais mong ibalik o magkaroon ng
//       bago, kailangan mong gumawa ng bagong online shop.
//     </Typography>
//   </div>
// );

// /// --------- ////

// export const gettingPaymentsAnswer1 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       May kasamang payment function ang iyong Sentro online shop na pwedeng
//       tumanggap ng online payments (sa pamamagitan ng online banking o
//       credit/debit card), pati na rin ang pagtanggap ng cash (sa pamamagitan ng
//       pagbayad sa 7-Eleven, Cebuana, atbp.). Ang payment feature na ito ay hatid
//       sa inyo ng Bux.
//     </Typography>

//     <Typography style={{ marginTop: 20 }}>
//       Kapag nag-sign up ka sa Sentro, automatic na magkakaroon ka rin ng sarili
//       mong Bux account.{" "}
//       <a
//         href="https://bux.ph/faq"
//         target="_blank"
//         style={{ color: "#F97663", fontWeight: 600 }}
//       >
//         Mag-click dito para higit pang malaman kung ano ang Bux
//       </a>{" "}
//     </Typography>
//   </div>
// );

// export const gettingPaymentsAnswer2 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Lahat ng bayad - online man o cash - ay mapupunta sa iyong Bux account.
//       Maaari mong tingnan ang balanse ng iyong account sa pamamagitan ng
//       pag-click sa "Sales and Payments" sa loob ng iyong Sentro account.
//     </Typography>
//   </div>
// );

// export const gettingPaymentsAnswer3 = (
//   <div>
//     <Typography style={{ fontSize: 16 }}>
//       Maaari mong makuha ang pera mula sa iyong mga benta sa Online Shop sa
//       pamamagitan ng pag-withdraw ng pondo mula sa iyong Bux account at
//       pa-deposit deretso sa iyong napiling bangko o online wallet.
//     </Typography>
//   </div>
// );
export default {
  aboutSentroAnswer1,
  joiningSentroAnswer1,
  aboutSentroShopAnswer1,
  aboutSentroShopAnswer2,
  aboutSentroShopAnswer3,
  aboutSentroShopAnswer4,
  aboutSentroShopAnswer5,
  purchaseAnswer1
  // gettingStartedAnswer1,
  // gettingStartedAnswer2,
  // gettingStartedAnswer3,
  // createShopAnswer1,
  // createShopAnswer2,
  // createShopAnswer3,
  // createShopAnswer4,
  // createShopAnswer5,
  // sellingProductsAnswer1,
  // sellingProductsAnswer2,
  // sellingProductsAnswer3,
  // sellingProductsAnswer4,
  // gettingPaymentsAnswer1,
  // gettingPaymentsAnswer2,
  // gettingPaymentsAnswer3
};
