import React from "react";
import { Button,
  makeStyles,
  Dialog,
  DialogContent } from "@material-ui/core";

const CookiesModal = props => {
  const [remembered, setRemembered] = React.useState(false)
  const useStyles = makeStyles(theme => ({
    modalBtn: {
      height: 50,
      flexGrow: 0,
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1
      }
    },
  }));

  React.useEffect(() => {
    if (getCookie("delivery")) {
      setRemembered(true);
      accept();
    } else {
      decline();
    }
  }, [])

  React.useEffect(() => {
    if (props.open && remembered && props.onAccept) props.onAccept();
  }, [remembered, props])

  const classes = useStyles();
  const decline = () => {
    if (props.onDecline) props.onDecline();
  }
  const accept = () => {
    if (props.onAccept) props.onAccept();
  }
  return (
    <Dialog id="activateAccountModal" maxWidth="sm" open={!remembered && props.open} >
      <DialogContent style={{ padding: 20, maxWidth: 460}}>
        <div className="p-3">
          <h2 style={{fontSize: 24, fontWeight: 600}} 
              className="mb-2">Sentro Cookie Policy</h2>
          <div>
            <p className="text-secondary mb-2">By clicking continue, you agree to 
            Sentro using your cookies to enhance and improve your experience with 
            our website. We will be using your cookies to save your information for 
            future visits to our website. Sentro uses cookies to enhance the 
            functionality and performance of our website for our users. </p>
          </div>
          <div className={`d-flex align-items-center mt-3 mb-0 ${window.innerWidth < 600 ? 'flex-wrap': ''}`}>
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              id="resend-button"
              className={classes.modalBtn}
              onClick={() => decline()}
            > 
              Decline
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              id="done-button"
              className={`${classes.modalBtn} ml-0 ml-sm-3 mt-3 mt-sm-0`}
              onClick={() => accept()}
            >
              Accept
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CookiesModal;

export function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export const saveCookie = (details) => {
  const data = {
    ...details,
    remembered: true
  }
  setCookie('delivery', JSON.stringify(details), 365);
}
export const unsaveCookie = (details) => {
  setCookie('delivery', "", 0);
}

export function useDelivery(){
  const [delivery, setDelivery] = React.useState(false);
  React.useEffect(() => {
    setDelivery(getCookie("delivery"))
  }, []);
  return delivery;
}