import MediaServices from "./services";

export const mediaActionTypes = {
  SET_SELECTED_IMAGES: "@@SET_SELECTED_IMAGES",
  GET_MEDIA_IMAGES: "media-library/GET_MEDIA_IMAGES",
  DELETE_MEDIA_IMAGES: "media-library/DELETE_MEDIA_IMAGES",
  EDIT_MEDIA_IMAGE: "media-library/EDIT_MEDIA_IMAGE",
  UPLOAD_IMAGE: "media-library/UPLOAD_IMAGE",
  IMAGE_UPLOADING: "media-library/IMAGE_UPLOADING",
  SET_LOADING_PERCENTAGE: "media-library/SET_LOADING_PERCENTAGE",
  ADD_IMAGE: "media-library/ADD_IMAGE",
  RESET: "media-library/RESET"
};

class MediaActions {
  static setSelectedImages(values) {
    return async dispatch => {
      dispatch({ type: mediaActionTypes.SET_SELECTED_IMAGES, payload: values });
    };
  }
  static getMediaImages(params) {
    return async dispatch => {
      try {
        const data = await MediaServices.getMediaImages(params);
        dispatch({
          type: mediaActionTypes.GET_MEDIA_IMAGES,
          payload: data.results,
          params
        });
        return data;
      } catch (error) {
        return false;
        // dispatch(
        //   showAlert({
        //     open: true,
        //     type: "error",
        //     message: "An error occured. Please try again."
        //   })
        // );
      }
    };
  }

  static deleteMediaImages(ids) {
    return async dispatch => {
      try {
        await MediaServices.deleteMediaImages(ids);
        dispatch({
          type: mediaActionTypes.DELETE_MEDIA_IMAGES,
          payload: ids
        });
      } catch (error) {
        // dispatch(
        //   showAlert({
        //     open: true,
        //     type: "error",
        //     message: "An error occured. Please try again."
        //   })
        // );
      }
    };
  }

  static editMediaImage(params) {
    return async dispatch => {
      try {
        const res = await MediaServices.editMediaImageAttr(params);
        dispatch({
          type: mediaActionTypes.EDIT_MEDIA_IMAGE,
          payload: res.data
        });
      } catch (error) {
        // dispatch(
        //   showAlert({
        //     open: true,
        //     type: "error",
        //     message: "An error occured. Please try again."
        //   })
        // );
      }
    };
  }

  static uploadImage(index, file) {
    return async dispatch => {
      try {
        dispatch({
          type: mediaActionTypes.IMAGE_UPLOADING,
          payload: index
        });
        const progressCallback = progress => {
          dispatch({
            type: mediaActionTypes.SET_LOADING_PERCENTAGE,
            index,
            payload: progress
          });
        };
        const res = await MediaServices.uploadImage(file, progressCallback);
        dispatch({
          type: mediaActionTypes.ADD_IMAGE,
          payload: res.data
        });
        dispatch({
          type: mediaActionTypes.SET_LOADING_PERCENTAGE,
          index,
          payload: 100
        });
      } catch (error) {
        dispatch({
          type: mediaActionTypes.SET_LOADING_PERCENTAGE,
          index,
          payload: -1
        });
        // dispatch(
        //   showAlert({
        //     open: true,
        //     type: "error",
        //     message: "An error occured. Please try again."
        //   })
        // );
      }
    };
  }

  static reset() {
    return async dispatch => {
      dispatch({
        type: mediaActionTypes.RESET
      });
    };
  }
}

export default MediaActions;
