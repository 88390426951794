import axios from "axios";
import apiConfig from "../../../common/apiConfig";
import history from "../../../store/history";
import Path from "../../../Paths";

class Services {
  static async loadCurrentUser() {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/current_user/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  static async fetchSubscriptions(query_params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/subscriptions/${query_params}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
}
export default Services;
