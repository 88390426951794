import React from "react";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, ListItemIcon, Divider } from "@material-ui/core";
import bux from "../../../../../assets/images/Bux.svg";
import seekcap from "../../../../../assets/images/Seekcap.svg";
import messenger from "../../../../../assets/images/Messenger.svg";
import domain from "../../../../../assets/images/Domain.svg";
import pixel from "../../../../../assets/images/Pixel.svg";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";
import { useSelector, useDispatch } from "react-redux";
import { getBuxStatus } from "./websiteBuilderActions";
import MobileFBChat from "../mobileShopTools/mobileFBChat";
import MobileFBPixel from "../mobileShopTools/mobileFBPixel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const MobileShopTools = props => {
  const { data, language } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBuxStatus(data.id));
  }, []);

  const useStyles = makeStyles(theme => ({
    sectionLabel: {
      color: "#54575f",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        padding: "2em",
        fontSize: 18
      },
      [theme.breakpoints.down("xs")]: {
        padding: 20,
        fontSize: 16
      }
    },
    listBtn: {
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        padding: "2em"
      },
      [theme.breakpoints.down("xs")]: {
        padding: 16
      }
    },
    listTitle: {
      color: "#002c42",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 14
      }
    },
    listLabel: {
      color: "#54575f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 10
      }
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        width: 40,
        height: 40
      },
      [theme.breakpoints.down("xs")]: {
        width: 30,
        height: 30
      }
    },
    toolsDiv: {
      transition: "all 0.6s ease-in-out",
      overflow: "hidden"
    },
    showMore: {
      fontWeight: 600,
      fontSize: 14,
      color: "#F97663",
      marginBottom: 0,
      transition: "all 0.5s ease-in-out",
      cursor: "pointer"
    },
    extended: {
      maxHeight: "999px !important"
    }
  }));
  const classes = useStyles();
  const kyc_level = useSelector(state => state.mobileDashboard.buxStatus);
  let currentStoreCreated = useSelector(
    state => state.mobileDashboard.storeInfo
  );
  const [open, setOpen] = React.useState({
    fb_chat: false,
    fb_pixel: false
  });
  function buxLevel() {
    let status = kyc_level;
    switch (status) {
      case 0:
        return "Basic level";
      case 1:
        return "Pro level";
      case 2:
        return "Advanced level";
      case 3:
        return "Business";
      default:
        return "Basic level";
    }
  }
  const shopTools = [
    {
      icon: bux,
      header:
        language === "English"
          ? "Upgrade your wallet for free"
          : "Libreng pag-upgrade ng iyong wallet",
      key: "bux",
      subheader:
        language === "English" ? (
          <div className={classes.listLabel}>
            Your wallet is currently in{" "}
            <span style={{ color: "#f97663", fontWeight: 600 }}>
              {buxLevel()}
            </span>
            .
          </div>
        ) : (
          <div className={classes.listLabel}>
            Ang wallet mo ay naka{" "}
            <span style={{ color: "#f97663", fontWeight: 600 }}>
              {buxLevel()}
            </span>{" "}
            palang.
          </div>
        )
    },
    {
      icon: seekcap,
      header:
        language === "English"
          ? "Apply for a business loan at SeekCap"
          : "Mag-apply ng business loan sa SeekCap",
      key: "seekcap",
      subheader: (
        <div className={classes.listLabel}>
          {language === "English"
            ? "Get approved for as fast as one day!"
            : "Ma-approve sa loob ng isang araw lang!"}
        </div>
      )
    },
    {
      icon: messenger,
      header:
        language === "English"
          ? "Enable Messenger in your shop"
          : "Lagyan ng Messenger ang shop mo",
      key: "messenger",
      isDisabled: currentStoreCreated ? false : true,
      subheader: (
        <div className={classes.listLabel}>
          {language === "English"
            ? "Link your shop's Messenger account"
            : "I-link ang messenger account ng shop mo"}
        </div>
      )
    },
    // {
    //   icon: domain,
    //   header: "Link your own domain",
    //   key: "domain",
    //   subheader: (
    //     <div className={classes.listLabel}>
    //       Use your own domain for your Sentro shop
    //     </div>
    //   )
    // }
    {
      icon: pixel,
      header:
        language === "English"
          ? "Add FB Pixel to your shop"
          : "Lagyan ng FB Pixel ang shop mo",
      key: "pixel",
      isDisabled: currentStoreCreated ? false : true,
      subheader: (
        <div className={classes.listLabel}>
          {language === "English"
            ? "Track your Facebook ads and more"
            : "I-track ang iyong Facebook ads at iba pa"}
        </div>
      )
    }
  ];

  function handleOnClick(key) {
    switch (key) {
      case "bux":
        history.push(Path.MOBILE_BUX_DASHBOARD);
        break;
      case "seekcap":
        window.open(
          "http://seekcap.ph/?&utm_source=sentro&utm_medium=referral&utm_campaign=sentro_inbound_112020"
        );
        break;
      case "messenger":
        setOpen({ ...open, fb_chat: true });
        break;
      // case "domain":
      //   history.push(Path.CUSTOM_DOMAIN_SETUP);
      //   break;
      case "pixel":
        setOpen({ ...open, fb_pixel: true });
        break;
    }
  }

  const [toolsExtended, setToolsExtended] = React.useState(false);
  const showMore = (
    <div
      id="showMoreButton"
      className={`text-center my-4 ${classes.showMore}`}
      onClick={() => setToolsExtended(!toolsExtended)}
    >
      <p className="mb-0">Show {toolsExtended ? "Less" : "More"}</p>
      <ExpandMoreIcon
        style={{
          transform: `rotate(${toolsExtended ? 180 : 0}deg)`
        }}
      />
    </div>
  );

  return (
    <div>
      <div className={classes.sectionLabel}>Additional tools</div>
      <div
        className={`${classes.toolsDiv} ${
          toolsExtended ? classes.extended : ""
        }`}
        id="toolsDiv"
      >
        <List style={{ padding: 0 }}>
          {shopTools.map(item => {
            return (
              <div>
                <ListItem
                  disabled={item.isDisabled}
                  onClick={() => handleOnClick(item.key)}
                  button
                  className={classes.listBtn}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon>
                      <img src={item.icon} className={classes.logo} />
                    </ListItemIcon>
                    <div>
                      <div className={classes.listTitle}>{item.header}</div>
                      <div>{item.subheader}</div>
                    </div>
                  </div>
                  <div>
                    <ArrowForward style={{ color: "#909196", fontSize: 14 }} />
                  </div>
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      </div>
      {shopTools.length > 4 && showMore}
      <MobileFBChat open={open} setOpen={setOpen} language={language} />
      <MobileFBPixel open={open} setOpen={setOpen} language={language} />
    </div>
  );
};

export default MobileShopTools;
