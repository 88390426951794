import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import Grow from '@material-ui/core/Grow';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    top: 15,
    right: 15,
    zIndex: 10,
    color: "white"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow in={true} ref={ref} {...props} />;
});

export default function Panel(props) {
  const classes = useStyles();
  
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      fullScreen
      onClose={props.onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="h-100 w-100" style={props.style}>
        <IconButton className={classes.closeButton} onClick={props.onClose}>
          <CloseIcon />
          </IconButton>
        {props.children}
      </div>
    </Dialog>
  );
}
