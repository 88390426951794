import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Tab, Button, useTheme, useMediaQuery } from "@material-ui/core";
import OrderFilters from "./components/orders/filters";
import {
  getStoreProducts,
  getOrders,
  setOrderFilters
} from "./webBuilder/websiteBuilderActions";
import OrderList from "./components/orders/orderList";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import Path from "../../../../Paths";
import { useHistory } from "react-router-dom";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function Orders(props) {
  const useStyles = makeStyles(theme => ({
    borderBottom: {
      borderBottom: "3px solid #eee",
      minWidth: 100,
      width: 100
    },
    btn: {
      height: 42,
      borderRadius: 40,
      backgroundColor: "#FF6B55",
      boxShadow: "0px 8px 24px rgba(0, 35, 11, 0.12)"
    }
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const filters = useSelector(state => state.mobileDashboard.orders.filters);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    let status;
    if (newValue === 0) status = "all";
    else if (newValue === 1) status = "today";
    else if (newValue === 2) status = "yesterday";
    else if (newValue === 3) status = "1-week";
    setValue(newValue);
    dispatch(setOrderFilters({ ...filters, date_ordered_type: status }));
  };

  React.useEffect(() => {
    dispatch(getStoreProducts());
  }, []);

  React.useEffect(() => {
    dispatch(getOrders(filters));
  }, [filters]);

  React.useEffect(() => {
    if (isDesktop) {
      history.push(Path.ORDER_LIST_VIEW);
    }
  }, [isDesktop]);
  const tabs = [
    { label: "All" },
    { label: "Today" },
    { label: "Yesterday" },
    { label: "7 Days" }
  ];

  const tabsSection = (
    <div className="my-2">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        {tabs.map((item, key) => (
          <Tab
            classes={{ root: classes.borderBottom }}
            label={item.label}
            {...a11yProps(key)}
          />
        ))}
      </Tabs>
    </div>
  );
  return (
    <div
      style={{
        backgroundColor: "#fafafa",
        padding: "7vh 0",
        minHeight: "93vh"
      }}
    >
      <div>{tabsSection}</div>
      <div className="px-3 py-1">
        <OrderFilters />
      </div>
      <div className="px-3 py-2">
        <OrderList />
      </div>
      <div style={{ position: "absolute", bottom: 85, right: 20 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<LocalShippingOutlinedIcon style={{ color: "#fff" }} />}
          className={classes.btn}
          disableElevation
          onClick={() => history.push(Path.DELIVERY_SETTINGS)}
        >
          Settings
        </Button>
      </div>
    </div>
  );
}
