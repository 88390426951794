import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import DashboardLayout from "../dashboard/dashboardLayout";
import AppDetailedHeader from "./components/appDetailedHeader";
import FreaturesCard from "./components/featuresCard";
import ReviewCard from "./components/reviewCard";
import PackagesCard from "./components/packagesCard";
import CardsSection from "../admin/marketplace/components/cards/section";
import Carousel from "./components/imageCarousel";
import Actions from "./Redux/Actions";
import { animateScroll as scroll } from 'react-scroll'
import Utils from "../common/utils";


const useStyles = theme => ({
  root: {
    padding: "0.5em 13em 0",
    marginTop: 230,
    [theme.breakpoints.down("sm")]: {
      padding: "0 7em 0",
      marginTop: 340,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 5em 0",
      marginTop: 340,
    },
    paddingBottom: "0.5em",
    backgroundColor: "white"
  },
  overview: {
    "& > p": {
      marginBottom: 0
    },
    "& > p.bold": {
      margin: "1em 0",
      fontWeight: "bold"
    }
  },
  textCenterDiv: {
    textAlign: "center",
    padding: "2em"
  },
  reviewsDiv: {
    margin: "2em 0",
    borderTop: "2px solid #E6EAF0"
  },
  variationTable: {
    margin: '0 auto',
    background: '#E6EAF0',
    boxShadow: '0 0 0 1px #1E4B83',
    borderRadius: 8,
    borderSpacing: 0,
    borderCollapse: 'collapse',
    borderRadius: 8,
    borderStyle: 'hidden',
    "& th": {
      padding: "1.5em 5em",
      textAlign: "center",
      fontWeight: "bold",
      color: "#1E4B83"
    },
    "& td": {
      padding: "1em 5em",
      textAlign: "center",
      fontWeight: "bold"
    }
  }
});

class AppMarketplaceDetailed extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
    }
    this.trackScrolling = this.trackScrolling.bind(this)
  }

  componentDidMount(){
    const { dispatch, match } = this.props;
    dispatch(Actions.fetchApp(match.params.id));
    document.addEventListener('scroll', this.trackScrolling);
    scroll.scrollTo(0)
  }

  getElemDistance = ( elem ) => {
    var location = 0;
    if (elem.offsetParent) {
        do {
            location += elem.offsetTop;
            elem = elem.offsetParent;
        } while (elem);
    }
    return location >= 0 ? location : 0;
  };
	
  trackScrolling = () => {
    const { dispatch, adminDashboard, activeHeaderTab } = this.props
    const featuresDiv = document.getElementById('appsection') || 
      document.getElementById('features') || document.getElementById('packages');
    const reviewsDiv = document.getElementById('reviews');
    let tabActive = 0;
    const max = document.documentElement.scrollHeight-document.documentElement.clientHeight;
    try {
      switch(true){
        case(window.pageYOffset <= this.getElemDistance(featuresDiv)-135 && window.pageYOffset !== max):
          tabActive = 0;
          break;
        default:
          tabActive = 1;
          break;
      }
    } catch {}
    if(activeHeaderTab !== tabActive){
      dispatch(Actions.setHeaderTab(tabActive))
    }
  };

  componentWillUnmount(){
		document.removeEventListener('scroll', this.trackScrolling);
  }

  render (){
    const { classes, app, loading } = this.props;
    const overview = () => (
      <div className={classes.overview}>
        {
          app && <div dangerouslySetInnerHTML={{
            __html: app.overview
          }}></div>
        }
      </div>
    )
    const packages = (title) => (
      app ?
        app.variants ? 
          app.variants.length > 1 ?
            app.variants.length > 4 ?
            <div id="packages">
              <div className={classes.textCenterDiv}>
                <strong style={{ textTransform: "uppercase" }}>{title}</strong>
              </div>
              <table className={classes.variationTable}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    app.variants.map((variant, index) => (
                      <tr>
                        <td>{variant.name}</td>
                        <td>{variant.price > 0 ? `PHP ${Utils.commafy(variant.price)}` : `FREE`}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            :
            <div id="packages">
              <div className={classes.textCenterDiv}>
                <strong style={{ textTransform: "uppercase" }}>{title}</strong>
              </div>
              <Grid container spacing={2} justify="center">
                {
                    app.variants.map((variant, index) => (
                      <Grid key={index} item sm={12} md={6}>
                        <PackagesCard title={variant.name} description={variant.description} />
                      </Grid>
                    ))
                }
              </Grid>
            </div>
          :
          ``
        :
        ``
      :
      ``
    )

    const reviews = () => (
      <div className={classes.reviewsDiv} id="reviews">
        <ReviewCard name="Juan Dela Cruz" rating={4} date="Jan 1, 1111" 
        review="I like that it was simple and didn't ask you questions that we're the same over again but worded differently. Doesn't take up a lot of time for the applicants."/>
        <ReviewCard name="Ana Ramos" rating={3.5} date="Jan 1, 1111" 
        review="Easy to understand and offers great insight into one's character, love that it offers a guideline for how you relate to others in various roles. Was rather enjoyable and not time consuming... Well worth the time and effort..."/>
        <ReviewCard name="Lala Moob" rating={5} date="Jan 1, 1111" 
        review="Simplistic yet thorough, surprisingly accurate & results are informative without being overly lengthy with unnecessary run on verbage. In addition, the details have a pleasant yet matter of fact quality, while refraining from sugar coating or using judgemental statements. "/>
      </div>
    )
    
    return (
      <DashboardLayout margin="0" right="0" left="0" backgroundColor="white">
        {
          (!app && !loading) ? (
            <div style={{ height: "70vh", width: "100%", overflow: "hidden" }}>
              <div style={{ paddingTop: "10%" }}>
                <div style={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <img
                    style={{width: "15%", height: "15%"}}
                    src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
                  />
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 40,
                    fontSize: 18,
                    fontWeight: 600,
                    color: "#b8bcc3",
                    padding: "0 3em",
                    textAlign: "center"
                }}>
                  Not found
                </div>
              </div>
            </div>
          ) : (
            <div>
              <AppDetailedHeader data={app} />
              <div className={classes.root}>
                {
                    app && <Carousel embed_video={app.embed_video} images={app.gallery} />
                }
                <div>
                  {overview()}
                  {packages(`${app.name} PACKAGES`)}
                  {
                    app ? JSON.parse(app.detailed_description).length > 0 && <div id="appsection">
                      <CardsSection data={JSON.parse(app.detailed_description)} readOnly={true}/>
                    </div>
                    : ``
                  }
                </div>
                {/* {reviews()} */}
              </div>
            </div>
          )
        }
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({ marketplace }) => ({ 
  app: marketplace.app,
  loading: marketplace.loading,
  activeHeaderTab: marketplace.activeHeaderTab
});
export default connect(mapStateToProps)(withStyles(useStyles)(AppMarketplaceDetailed));

