import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Button, Tabs, Tab, Badge } from "@material-ui/core";
import { ReactComponent as Export } from "../../../../../assets/images/enterprise/export.svg";
import { ReactComponent as Upload } from "../../../../../assets/images/upload.svg";
import AddIcon from "@material-ui/icons/Add";
import ProductFilters from "./productFilters";
import { Field, reduxForm, destroy, reset } from "redux-form";
import {
  setAddProductView,
  setProductFilters,
  getProducts
} from "../../../websiteBuilderActions";
import moment from "moment";
import Path from "../../../../../Paths";
import { useHistory } from "react-router";
import UploadImageDrawer from "./uploadImageDrawer";
import apiConfig from "../../../../../common/apiConfig";
import axios from "axios";
import FreeTrialModal from "../../../../subscription/components/products/freeTrialModal";
import MaxProductsModal from "./maxProductsModal";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const ProductListHead = props => {
  const useStyles = makeStyles(theme => ({
    borderBottom: {
      borderBottom: "3px solid #eee",
      minWidth: 100,
      width: 100
    }
  }));
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const product_count = useSelector(
    state => state.webBuilder.products.products
  );
  const store = useSelector(state => state.webBuilder.storeInfo);
  const filters = useSelector(state => state.webBuilder.products.filters);
  const subscription = useSelector(state => state.subscription.subscription);
  const [value, setValue] = React.useState(0);
  const [draft_products, set_draft_products] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openMaxProduct, setOpenMaxProduct] = React.useState(false);
  const handleChange = (event, newValue) => {
    let status;
    if (newValue === 0) status = "active";
    else if (newValue === 1) status = "draft";
    else if (newValue === 2) status = "deleted";
    setValue(newValue);
    dispatch(setProductFilters({ ...filters, status: status }));
    // dispatch(destroy("product_form"));
  };

  const handleCSVExport = is_csv => {
    let date = new Date();
    let filename = `${store && store.name}-Products-${moment(date).format(
      `MM-DD-YYYY hh-mm-A`
    )}.csv`;
    dispatch(
      getProducts(
        store && store.uuid,
        { ...filters, csv_download: true },
        (is_csv = true),
        filename
      )
    );
  };

  const handleBatchUpload = () => {
    if (
      !subscription.subscription_type ||
      (subscription.subscription_type &&
        !subscription.features.product_batch_upload)
    ) {
      setOpen(true);
    } else if (
      (subscription.subscription_type === "advanced" ||
        subscription.subscription_type === "free_trial") &&
      product_length >= 300
    ) {
      setOpenMaxProduct(true);
    } else {
      history.push("/batch_upload");
    }
  };

  let product_length = product_count.active_count + product_count.drafts_count;

  const handleAddProduct = () => {
    if (
      (subscription.subscription_type === "advanced" ||
        subscription.subscription_type === "free_trial") &&
      product_length >= 300
    ) {
      setOpenMaxProduct(true);
    } else if (
      (subscription.free_trial_availed || !subscription.free_trial_availed) &&
      !subscription.subscription_type &&
      product_length >= 50
    ) {
      setOpenMaxProduct(true);
    } else {
      history.push(Path.PRODUCTS_ADD);
    }
  };

  React.useEffect(() => {
    let value;
    if (filters.status === "active") value = 0;
    else if (filters.status === "draft") value = 1;
    else if (filters.status === "deleted") value = 2;
    setValue(value);
  }, []);

  React.useEffect(() => {
    const get_draft_products = async () => {
      let params = {
        ...filters,
        status: "draft",
        page: filters.page + 1
      };
      let res = await axios.get(
        `${apiConfig.apiURL}/api/dashboard/${store.uuid}/products/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params
        }
      );
      set_draft_products(res.data.results);
    };
    get_draft_products();
  }, [product_count]);

  let drafts_count =
    draft_products &&
    draft_products.filter(i => !i.gallery || (i.gallery && !i.gallery.length));
  const tabs = [
    { label: "Active" },
    { label: "Drafts", count: drafts_count.length },
    { label: "Archives" }
  ];

  console.log(value);
  const headerButtons = (
    <>
      <Button
        id="export_btn"
        size="small"
        variant="outlined"
        color="primary"
        startIcon={<Export fill={"#f97663"} />}
        onClick={handleCSVExport}
      >
        Export CSV
      </Button>
      <Button
        id="upload"
        size="small"
        variant="outlined"
        color="primary"
        className="mx-2"
        startIcon={<Upload fill="#f97663" />}
        onClick={handleBatchUpload}
      >
        Batch upload
      </Button>
      <Button
        id="add_product"
        size="small"
        variant="contained"
        color="primary"
        disableElevation
        startIcon={<AddIcon style={{ color: "#fff" }} />}
        onClick={handleAddProduct}
      >
        Add product
      </Button>
      <FreeTrialModal open={open} setOpen={setOpen} />
    </>
  );

  const tabs_section = (
    <div className="my-2">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((item, key) => (
          <Tab
            classes={{ root: classes.borderBottom }}
            label={
              <Badge
                id="product_badge_count"
                badgeContent={item.count}
                color="primary"
              >
                {item.label}
              </Badge>
            }
            {...a11yProps(key)}
          />
        ))}
      </Tabs>
    </div>
  );
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <strong style={{ fontSize: 28, color: "#002c42" }}>Products</strong>
        <div>{headerButtons}</div>
      </div>
      <div>{tabs_section}</div>
      <div>
        <ProductFilters />
      </div>
      <MaxProductsModal
        open={openMaxProduct}
        setOpen={setOpenMaxProduct}
        product_length={product_length}
      />
    </div>
  );
};

export default ProductListHead;
