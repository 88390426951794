import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from '../../actions';

const useProducts = (props) => {
  const dispatch = useDispatch();
  const products_store = useSelector(state => state.vouchers.products);

  React.useEffect(() => {
    if (props.store_uuid) {
      dispatch(getProducts(props.store_uuid, props.params))
    }
  }, [dispatch, props.store_uuid, props.params])

  return [products_store]
}

export default useProducts;