import React from "react";
import {
  makeStyles,
  Button,
  ButtonGroup,
  TextField,
  OutlinedInput,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as SortAsc } from "../../assets/images/enterprise/sortAsc.svg";
import { ReactComponent as SortDesc } from "../../assets/images/enterprise/sortDesc.svg";
import { useSelector, useDispatch } from "react-redux";
import { setVoucherFilters } from "./actions";
import { CalendarToday } from "@material-ui/icons";
const VoucherSortSection = (props) => {
  const useStyles = makeStyles((theme) => ({
    btnStatus: {
      height: 37,
      fontSize: 12,
      padding: "0 30px",
    },
    searchbar: {
      backgroundColor: "#fff",
      width: 230,
    },
    datepicker: {
      backgroundColor: "#fff",
      width: 160,
      marginRight: 5,
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const voucherFilters = useSelector((state) => state.vouchers.voucherFilters);
  const [state, setState] = React.useState({
    is_active: "all",
    voucher_type: "type",
    search: "",
    start_date: null,
    end_date: null,
    sort: "desc",
  });

  const status = [
    { name: "All", value: "all" },
    { name: "Active", value: "True" },
    { name: "Inactive", value: "False" },
  ];

  const type = [
    { name: "All", value: "all" },
    { name: "Discount", value: "discount" },
  ];

  const [timeoutId, setTimeoutId] = React.useState(false);
  const onSearch = (e) => {
    const value = e.target.value;
    setState({ ...state, search: value });
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        dispatch(
          setVoucherFilters({
            search: value,
          })
        );
      }, 1000)
    );
  };

  const onClear = () => {
    setState({ ...state, search: "" });
    dispatch(setVoucherFilters({ search: "" }));
  };

  const setFilter = (key, val) => {
    dispatch(
      setVoucherFilters({
        [key]: val,
      })
    );
  };

  const handleFilter = () => {
    if (props.onChange)
      props.onChange({
        startDate: moment(state.startDate).format("MM/DD/YYYY"),
        endDate: moment(state.endDate).format("MM/DD/YYYY"),
        is_active: state.is_active,
        voucher_type: state.voucher_type,
        search: state.search,
      });
  };

  // React.useEffect(() => {
  //   setState({
  //     ...state,
  //     ...props.filter
  //   });
  // }, [props.filter]);

  console.log(voucherFilters);
  return (
    <div>
      <div
        style={{
          marginTop: "3vh",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="mr-2" style={{ marginTop: 4 }}>
            <OutlinedInput
              className={classes.searchbar}
              color="secondary"
              labelWidth={0}
              margin="dense"
              placeholder="Search vouchers"
              value={state.search}
              onChange={onSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                state.search ? (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ marginRight: "-0.5em" }}
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => onClear()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  ""
                )
              }
            />
          </div>
          <FormControl
            variant="outlined"
            margin="dense"
            className="mr-2 bg-white"
            color="secondary"
          >
            <Select
              value={voucherFilters.voucher_type}
              onChange={(e) => setFilter("voucher_type", e.target.value)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="type">Type</MenuItem>
              <MenuItem value="percentage">Percentage</MenuItem>
              <MenuItem value="fixed_amount">Fixed amount</MenuItem>
              <MenuItem value="free_shipping">Free shipping</MenuItem>
              <MenuItem value="buy_x_get_y">Buy X Get Y</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            margin="dense"
            className="mr-2 bg-white"
            color="secondary"
          >
            <Select
              value={voucherFilters.is_active}
              onChange={(e) => setFilter("is_active", e.target.value)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="all">Status</MenuItem>
              <MenuItem value="True">Active</MenuItem>
              <MenuItem value="False">Inactive</MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: "flex", alignItems: "center" }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                label="Start Date"
                margin="dense"
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                InputAdornmentProps={{ position: "start" }}
                orientation="portrait"
                color="secondary"
                className={classes.datepicker}
                value={state.start_date}
                maxDate={
                  state.end_date === null
                    ? new Date().toLocaleDateString("en-US")
                    : state.end_date
                }
                onChange={(date) => {
                  setState({ ...state, start_date: date });
                  setFilter("start_date", date);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarToday
                        style={{ color: "#909196", fontSize: 18 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <DatePicker
                label="End Date"
                margin="dense"
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                InputAdornmentProps={{ position: "start" }}
                orientation="portrait"
                color="secondary"
                className={classes.datepicker}
                value={state.end_date}
                minDate={
                  state.start_date === null
                    ? new Date().toLocaleDateString("en-US")
                    : state.start_date
                }
                onChange={(date) => {
                  setState({ ...state, end_date: date });
                  setFilter("end_date", date);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarToday
                        style={{ color: "#909196", fontSize: 18 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
            <div className="d-flex">
              <Tooltip title="Descending" placement="top">
                <IconButton
                  className="mr-2"
                  style={{ height: 50, width: 50 }}
                  onClick={() => setState({ ...state, sort: "desc" })}
                >
                  <SortDesc
                    fill={state.sort === "desc" ? "#f97663" : "#909196"}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ascending" placement="top">
                <IconButton
                  style={{ height: 50, width: 50 }}
                  onClick={() => setState({ ...state, sort: "desc" })}
                >
                  <SortDesc
                    fill={state.sort === "asc" ? "#f97663" : "#909196"}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherSortSection;
