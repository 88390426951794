import React from "react";
import { Alert } from "antd";
import "antd/dist/antd.css";
import { hideAlert } from "./commonActions";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";

function SentroAlert(props) {
  const dispatch = useDispatch();
  function hideError() {
    dispatch(hideAlert());
  }
  let error = useSelector(state => state.common.message);
  let message = [];

  if (error.error) {
    message.push({ error: error.error.access_token });
  } else if (error) {
    Object.keys(error).map((item, key) => {
      let msg = error[item];
      if (Array.isArray(msg)) {
        msg.map((its, key) => {
          message.push({ error: `${its}` });
        });
      }
    });
  } else {
    message = [];
  }
  return message.map((item, key) => {
    return (
      <Alert
        key={key}
        style={{
          // display: common.showAlert ? "block" : "none",
          padding: 20,
          marginTop: 20,
          marginBottom: 20
        }}
        closable
        onClose={() => hideError()}
        message={item.error}
        type={"error"}
      />
      // </Snackbar>
    );
  });
}

export default SentroAlert;
