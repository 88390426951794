import React from "react";
import { Paper, Button } from "@material-ui/core";
import icon from "../../../../assets/images/success.svg";
import Path from "../../../../Paths";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
export default function SuccessSubscription(props) {
  const history = useHistory();
  return (
    <div style={{ padding: "0 10vw" }}>
      <div className="d-flex align-items-center">
        <Button
          variant="text"
          color="primary"
          className="position-absolute"
          startIcon={<ArrowBack color="primary" />}
          onClick={() => history.push(Path.DASHBOARD)}
        >
          Back
        </Button>
        <div
          className="w-100 text-center"
          style={{ fontSize: 28, fontWeight: 600, color: "#002c42" }}
        >
          Subscription
        </div>
      </div>
      <Paper className="p-md-5 p-3 mt-4 bg-white">
        <div className="text-center d-flex justify-content-center">
          <div>
            <img
              src={icon}
              alt="icon"
              width="auto"
              height="200"
              className="my-3"
            />
            <div
              className="my-4"
              style={{ fontSize: 18, fontWeight: 600, color: "#2b2d32" }}
            >
              Your subscription is pending payment
            </div>
            <div style={{ fontSize: 14, color: "#54575f", padding: "0 10vw" }}>
              Follow the payment instructions sent to your email to continue
              with the subscription.
            </div>
            <Button
              fullWidth
              disableElevation
              color="primary"
              variant="contained"
              className="mt-4"
              onClick={() => history.push(Path.DASHBOARD)}
            >
              Return to dashboard
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
}
