import { Dialog, DialogTitle, makeStyles } from "@material-ui/core";
import React from "react";
import apiConfig from "../../../../../common/apiConfig";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
/* Image */
import rightDesignImg from "../img/rightdesign.png";
import leftDesignImg from "../img/leftdesign.png";
import cryptoIcon from "../img/crypto-icon.svg";
import cashcreditIcon from "../img/cashcredit-icon.svg";

export default function PaymentOptionModal(props) {
  const useStyles = makeStyles((theme) => ({
    MuiDialogPaper: {
      backgroundColor: "#051626 !important",
      color: "#fff",
      position: "relative",
      overflow: "visible",
      border: "4px solid #B4C6F4",
      borderRadius: 0,
      "&::before": {
        content: `''`,
        position: "absolute",
        left: "-15px",
        top: "-15px",
        background: `transparent url(${leftDesignImg}) no-repeat`,
        backgroundSize: "contain",
        width: "56px",
        height: "90px",
      },
      "&::after": {
        content: `''`,
        position: "absolute",
        right: "-22px",
        top: "-15px",
        background: `transparent url(${rightDesignImg}) no-repeat`,
        backgroundSize: "contain",
        width: "56px",
        height: "90px",
      },
    },
    card: {
      cursor: "pointer",
      backgroundColor: "#0D2C49",
    },
  }));

  const classes = useStyles();
  const { product } = props;

  const [selected, setSelected] = React.useState("");

  const handleContinue = () => {
    if (selected === "crypto") {
      props.handleCryptoCheckout();
    } else {
      if (apiConfig.fiatOption === "jotform") {
        const userData = jwtDecode(Cookies.get("accesstoken"));
        window.open(
          `${apiConfig.alamatOrderFormUrl}?email=${
            userData["cognito:username"]
          }&cardName=${encodeURIComponent(
            product.name
          )}&sku=${encodeURIComponent(product.product_code)}&price=${
            product.selections[0].price_in_php
          }`
        );
        props.setOpen(false);
      } else {
        props.handleCashCreditCheckout();
      }
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        classes={{ paper: classes.MuiDialogPaper }}
        className="nftFontFamily text-center"
        fullWidth={true}
        maxWidth="md"
      >
        <div className="nft-modal-border">
          <DialogTitle id="alert-dialog-title">
            <span style={{ fontSize: 24 }}>Buy NFT with</span>
          </DialogTitle>
          <div>
            <div className="row px-4">
              <div className="col-6 pl-5 py-5">
                <div
                  onClick={() => setSelected("crypto")}
                  className={`py-4 ${classes.card}`}
                  style={{ border: selected === "crypto" && "1px solid white" }}
                >
                  <img src={cryptoIcon} alt="" width="50" />
                  <div style={{ fontSize: 28 }}>Crypto</div>
                </div>
              </div>
              <div className="col-6 pr-5 py-5">
                <div
                  onClick={() => setSelected("cash/credit")}
                  className={`py-4 ${classes.card}`}
                  style={{
                    border: selected === "cash/credit" && "1px solid white",
                  }}
                >
                  <img src={cashcreditIcon} alt="" width="50" />
                  <div style={{ fontSize: 28 }}>Cash/Credit</div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end mr-5 mb-4">
              <div className="w-50 row ">
                <div className="col-6 col-xs-12">
                  <button
                    onClick={() => props.setOpen(false)}
                    className="nft-button-cut-corner"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-6 col-xs-12">
                  <button
                    onClick={() => handleContinue()}
                    disabled={!selected}
                    className="nft-button-cut-corner"
                    style={{ opacity: !selected ? 0.5 : 1 }}
                  >
                    <div
                      className="spinner-border"
                      role="status"
                      style={{
                        color: "white",
                        display: props.isLoading ? "inherit" : "none",
                        fontSize: 7,
                        height: 10,
                        width: 10,
                        marginRight: "2px",
                      }}
                    ></div>
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
