import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, Menu, MenuItem } from '@material-ui/core';
import history from "../../../../store/history";
import Layout from "../../layout"

import { animateScroll as scroll } from "react-scroll";
import { CartTable, SummaryCard } from '../../components/cart'
import OrderDetailedCard from '../../components/order/orderDetailCard'
import ConfirmModal from '../../components/order/confirmModal'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Actions from '../../Redux/Actions'
import _ from 'lodash';
import qs from 'query-string'
import moment from 'moment'

const useStyles = theme => ({
  title: {
    fontWeight: "bold",
    fontSize: 28,
    color: "#002C42"
  },
  backHeader: {
    width: 120,
    display: "flex !important",
    alignItems: "center",
    padding: "0 0 2em",
    fontWeight: 600,
    fontSize: 14,
    color: "#F97663 !important"
  },
  button: {
    height: 40,
    marginLeft: "auto",
    fontWeight: 500
  }
});

class MyOrderDetailed extends React.Component {

  state = {
    menuAnchor: "",
    cancelModal: false
  }

  setModal = (modal, stat) => {
    this.setState({
      ...this.state,
      [modal]: stat
    })
  }

  setMenu = (e) => {
    this.setState({
      menuAnchor: e.target
    })
  }

  componentDidMount = () => {
    const { match } = this.props;
    const order_id = match.params.id;
    scroll.scrollTo(0)
    this.props.dispatch(Actions.GET_ORDER({
        order_id
    }))
  }

  cancelOrder = async () => {
    const { match } = this.props;
    const order_id = match.params.id;
    const response = await this.props.dispatch(Actions.EDIT_ORDER({
      order_id,
      status: "Reject"
    }))
    if (response) {
      this.setModal("confirmModal", false)
    }
  }

  render (){
    const { classes, orders, match } = this.props;
    let order = _.find(orders, ['order_id', match.params.id])
    console.log(order, "order")
    const qty = order ? order.items.reduce((a, b) => a + parseInt(b.quantity), 0): 0;
    const items = order ? order.items: [];
    // const items = [
    //   {
    //     image: 'https://cdn.cichic.com/media/catalog/product/cache/1/image/5e06319eda06f020e43594a9c230972d/1/9/190104100971-1/white-patchwork-lace-pleated-v-neck-long-sleeve-ctue-boho-mini-dress.jpg',
    //     name: 'Premium Mt. Apo Arabica  Coffee Beans (1 kg.)',
    //     price: 7.50,
    //     unit: '1 kg',
    //     quantity: 1
    //   },
    //   {
    //     image: 'https://images.immediate.co.uk/production/volatile/sites/30/2020/08/health-benefits-of-almonds-hub-image-square-dcac827.jpg?quality=90&resize=504,458?quality=90&webp=true&resize=504,459',
    //     name: 'High quality Almonds',
    //     price: 7.50,
    //     unit: '1 kg',
    //     quantity: 1

    //   }
    // ];

    let status = "For Confirmation";
    if (order) {
      switch(order.status) {
        case "Accept":
          status = "Pending Payment";
          break;
        case "Reject":
          status = "Cancelled";
          break;
        default: 
          break;
      }  
      order = {
        ...order,
        status
      }
    }

    // const order_details = {
    //   pr_no: order.order_id,
    //   date_created: moment(order.timestamp).format("DD MMM YYYY"),
    //   status,
    //   store_name: "XYZ Store",
    //   flag: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg',
    //   email: order.billing_address.email
    // }
    return (
      <Layout noSideBar>
        <a className={classes.backHeader} href="#" onClick={(e) => {
            e.preventDefault();
            history.goBack();
        }}>
          <ArrowBackIcon/>
          <span style={{marginLeft: "1em"}}>{`  BACK`}</span>
        </a>
        {
          order  ?
          <>
            <div className="d-flex">
              <h2 className={`mb-4 ${classes.title}`}>Order Details</h2>
              <Button className={classes.button} variant="contained" color="primary" disabled>
                Make Payment
              </Button>
              <Button className={`${classes.button} ml-2`} variant="outlined" color="primary" 
              onClick={this.setMenu}>
                Other Options <ArrowDropDownIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={this.state.menuAnchor}
                keepMounted
                open={this.state.menuAnchor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={() => this.setState({...this.state, menuAnchor: ""})}
              >
                <MenuItem onClick={() => this.setModal('cancelModal', true)}
                disabled={["Pending Payment", "Cancelled"].includes(status)}>
                  Cancel Order
                </MenuItem>
              </Menu>
            </div>
            <div className="row">
              <div className="col-12 col-lg-8">
                <OrderDetailedCard data={order}/>
                <div className="mt-4">
                  <CartTable title="Item List" items={items} />
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <SummaryCard qty={qty} subtotal={parseInt(order.total.amount).toFixed(2)/100} total={parseInt(order.total.amount).toFixed(2)/100} />
              </div>
            </div>
            <ConfirmModal 
            title="Are you sure you want to cancel the order?" 
            body="The buyer will be notified of the cancellation of order. Provide reason of cancellation? Once cancelled, the buyer will be notified. Kindly provide details below on the reason for cancellation and the necessary edits needed for the buyer to send another purchase request.?" 
            open={this.state.cancelModal} 
            onClick={this.cancelOrder} onClose={() => this.setModal("cancelModal", false)} />
          </>
          :
          <div className="text-center m-5 p-5">
            <p className="text-secondary">Order not found</p>
          </div>
        }
      </Layout>
    )
  }
}


const mapStateToProps = state => ({ proxtera }) => ({ 
  orders: proxtera.orders
});
export default connect(mapStateToProps)(withStyles(useStyles)(MyOrderDetailed));

