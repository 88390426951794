import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Select,
  MenuItem,
  useMediaQuery,
  Button
} from "@material-ui/core";

const VariantPicker = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles(theme => ({
    label: {
      fontSize: 14,
      color: "#2b2d32",
      fontWeight: 500
    },
    btn: {
      height: 34,
      borderRadius: 0,
      fontSize: 12,
      fontWeight: 500,
      padding: "0 25px"
    }
  }));
  const classes = useStyles();

  const [selected_variant, set_selected_variant] = React.useState({});

  const onChange = (group_id, value) => {
    set_selected_variant(state => ({
      ...state,
      [group_id]: value
    }));
  };

  React.useEffect(() => {
    if (props.product_selection_value) {
      let data = {};
      const selection_variants = props.product_selection_value.variants;
      for (let i in selection_variants) {
        data[String(selection_variants[i].group)] = selection_variants[i].id;
      }
      set_selected_variant(data);
    }
  }, [props.product_selection_value]);

  const findProductSelectionMatch = selectedVariantSelectionIds => {
    // find selection
    const matched_selection = props.product?.selections?.filter(i =>
      i.variants.every(v => selectedVariantSelectionIds.some(id => v.id === id))
    );
    return matched_selection?.length ? matched_selection[0] : null;
  };

  React.useEffect(() => {
    const variant_temp_ids_int = Object.values(selected_variant);
    const matched_selection = findProductSelectionMatch(variant_temp_ids_int);
    if (props.onSelectionChange) props.onSelectionChange(matched_selection);
  }, [selected_variant]);

  const wrap = isMobile || props.wrap;

  const setVariantPicker = () => {
    switch (props.store ? props.store.template : "Blank") {
      default:
        return (
          <div className={`d-flex flex-wrap ${wrap ? "flex-column" : ""}`}>
            {props.product.has_variants && props.product?.variant_groups.length
              ? props.product.variant_groups.map((variant_group, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: isMobile ? "flex" : "block",
                        width: wrap ? "100%" : "50%",
                        alignItems: wrap ? "center" : "",
                        paddingRight: 10
                      }}
                    >
                      <div
                        style={{ width: wrap ? "35%" : "auto" }}
                        className={classes.label}
                      >
                        {variant_group.name}
                      </div>
                      <FormControl
                        style={{ width: "100%" }}
                        variant="outlined"
                        margin="dense"
                      >
                        {variant_group.variants.length ? (
                          <Select
                            fullWidth
                            style={{
                              fontSize: 12,
                              borderRadius: 0,
                              backgroundColor: "#fff"
                            }}
                            color="secondary"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                              }
                            }}
                            getContentAnchorEl={null}
                            value={
                              selected_variant[variant_group.id]
                                ? selected_variant[variant_group.id]
                                : ""
                            }
                            onChange={e =>
                              onChange(variant_group.id, e.target.value)
                            }
                            disabled={props.disabled}
                          >
                            {variant_group.variants
                              .filter(variant => variant.is_enabled)
                              .map((variant, index) => (
                                <MenuItem
                                  key={index}
                                  value={variant.id}
                                  style={{
                                    fontSize: 12,
                                    textTransform: "capitalize"
                                  }}
                                >
                                  {variant.name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </div>
                  );
                })
              : ""}
          </div>
        );
      case "ContemporaryV2":
        return (
          <div>
            {props.product.has_variants && props.product?.variant_groups.length
              ? props.product.variant_groups.map((variant_group, index) => {
                  return (
                    <div className="mb-3" key={index}>
                      <div style={{ fontSize: 12 }}>{variant_group.name}</div>
                      <div className="mt-2 d-flex flex-wrap">
                        {variant_group.variants.length
                          ? variant_group.variants
                              .filter(variant => variant.is_enabled)
                              .map((variant, index) => {
                                return (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    className={`${classes.btn} mr-2`}
                                    style={{
                                      backgroundColor:
                                        selected_variant[variant_group.id] ===
                                        variant.id
                                          ? "#ededed"
                                          : "#fff"
                                    }}
                                    onClick={e =>
                                      onChange(variant_group.id, variant.id)
                                    }
                                  >
                                    {variant.name}
                                  </Button>
                                );
                              })
                          : ""}
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        );
    }
  };

  return (
    <div>{setVariantPicker(props.store ? props.store.template : "Blank")}</div>
  );
};

export default VariantPicker;
