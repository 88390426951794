import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import sentroTheme from "./sentroTheme";

const SentroCheckBox = ({ onChange, label, style, checked }) => {
  return (
    <ThemeProvider theme={sentroTheme}>
      <FormControlLabel
        control
        label={<div style={{ fontSize: 12 }}>{label}</div>}
        style={{ marginRight: 0, fontSize: 12 }}
        control={
          <Checkbox checked={checked} color="primary" onChange={onChange} />
        }
      />
    </ThemeProvider>
  );
};

export default SentroCheckBox;
