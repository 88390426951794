import React from "react";
import Banner from "./components/banner";
import Content from "./components/content";
import SentroAppBar from "../../components/common/layout/components/appBar";
import { signOff } from "../auth/authActions";
import DocumentMeta from "react-document-meta";
import { useDispatch } from "react-redux";
import history from "../../store/history";
import Path from "../../Paths";

import { makeStyles } from "@material-ui/core/styles";
const authStyles = makeStyles(theme => ({
  bg: {
    backgroundImage: `url('https://sentro-store-bucker.s3.us-east-2.amazonaws.com/sentro_swirls.png')`,
    position: "relative",
    backgroundSize: "contain",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `none`,
      position: "relative",
      backgroundSize: "contain"
    }
  }
}));

const meta = {
  title: "Sentro | Free Online Shop Builder with Payment Gateway",
  description:
    "Sentro is an online shop builder with a payment gateway and logistics platform for small businesses in the Philippines.",
  canonical: "https://sentro.ph",
  meta: {
    charset: "utf-8",
    property: {
      "og:title": "Sentro | Free Online Shop Builder with Payment Gateway",
      "og:url": `https://sentro.ph`,
      "og:image": `https://sentro-store-bucker.s3.us-east-2.amazonaws.com/Bux.ai_Sentro+Banner-9.jpg`,
      "og:description":
        "Sentro is an online shop builder with a payment gateway and logistics platform for small businesses in the Philippines.",
      "twitter:card": "summary_large_image",
      "twitter:title": "Sentro | Free Online Shop Builder with Payment Gateway",
      "twitter:description":
        "Sentro is an online shop builder with a payment gateway and logistics platform for small businesses in the Philippines.",
      "twitter:image": `https://sentro-store-bucker.s3.us-east-2.amazonaws.com/Bux.ai_Sentro+Banner-9.jpg`
    }
  }
};

const Homepage = props => {
  let dispatch = useDispatch();
  const classes = authStyles();
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let getLanguage = localStorage.getItem("language");
  const [language, setLanguage] = React.useState(getLanguage);
  React.useEffect(() => {
    if (user) {
      history.push(Path.DASHBOARD);
    } else {
      dispatch(signOff());
    }
  }, []);
  return (
    <DocumentMeta {...meta}>
      <div>
        <SentroAppBar language={language} setLanguage={setLanguage} />
        <Banner language={language} />

        <div className={classes.bg}>
          <Content user={user} language={language} />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Homepage;
