import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "../../../store/history";
import { Button, useMediaQuery } from "@material-ui/core";
import DeliveryRates from "../../enterprise/components/delivery/deliveryRates";
import BuyerActions from "./redux/actions";
import SummaryForm from "./common/summaryForm";
import VoucherModal from "./common/voucherModal";
import Path from "../../../Paths";
import PaymentOptionsForm from "./common/paymentOptionsForm";
import { Modal } from "antd";
import apiConfig from "../../../common/apiConfig";
import StoreActions from "../../common/template/components/StoreActions";
import bux from "../../../assets/images/Bux.svg";
const RedirectToBuxModal = props => {
  const { open, setOpen, handleClose, handleBuxCheckout } = props;

  return (
    <Modal zIndex={3000} visible={open} onCancel={handleClose} footer={null}>
      <div style={{ padding: 24 }}>
        <div className="d-flex justify-content-center">
          <img src={bux} alt="bux" width="auto" height="70" />
        </div>
        <div
          className="text-center"
          style={{
            fontSize: 18,
            color: "#002c42",
            marginTop: "4vh",
            fontWeight: 600
          }}
        >
          You will be redirected to Bux for payment processing
        </div>

        <div style={{ marginTop: 30, display: "flex" }}>
          <Button
            variant="contained"
            fullWidth
            disableElevation
            style={{ backgroundColor: "#f97663", height: 40, color: "#fff" }}
            onClick={handleBuxCheckout}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

function Payment(props) {
  const useStyles = makeStyles(theme => ({
    header: {
      paddingTop: "5vh",
      width: "65%",
      display: "flex",
      alignItems: "center",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "block"
      }
    },
    headerLabel: {
      fontSize: 28,
      fontWeight: 600,
      color: "#002c42",
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        position: "inherit"
      }
    },
    label: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2b2d32"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openModal, setOpenModal] = React.useState(false);
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const [paymentType, setType] = React.useState({
    paymentOption: "otc",
    account: ""
  });
  const setPaymentType = type => {
    dispatch(BuyerActions.setSelectedPaymentChannel(type.paymentOption));
    setType(type);
  };
  React.useEffect(() => {
    dispatch(BuyerActions.setSelectedPaymentChannel("otc"));
  }, []);
  const loading = useSelector(state => state.buyerDashboard.loading);
  const cart_items = useSelector(
    state => state.buyerDashboard.cart_items?.cart
  );
  const product_selected = useSelector(
    state => state.buyerDashboard.selected_checkout.items
  );
  const selected_order = useSelector(
    state => state.buyerDashboard.selected_order
  );
  const delivery_details = useSelector(
    state => state.buyerDashboard.delivery_details
  );
  const customer_details = useSelector(
    state => state.form.customer_details?.values
  );
  const amount = useSelector(
    state => state.buyerDashboard.selected_checkout.amount
  );
  const subtotal = useSelector(
    state => state.buyerDashboard.selected_checkout.subtotal
  );
  const applied_voucher = useSelector(
    state => state.buyerDashboard.applied_voucher
  );
  const promo = useSelector(state => state.buyerDashboard.promo);
  const voucher_discount = useSelector(
    state => state.buyerDashboard.voucher_discount
  );

  const pickup_address =
    selected_order && selected_order.store_data.pickup_address;

  const payments = useSelector(state => state.buyerDashboard.payments);

  const handleProceed = () => {
    if (paymentType.paymentOption === "otc") {
      setOpenModal(true);
    } else {
      handleCheckout();
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleBuxCheckout = () => {
    handleCheckout();
    setOpenModal(false);
  };

  const handleCheckout = async () => {
    let cart = [];
    let items = [];

    product_selected.map(item => {
      if (item.productSelection) {
        cart.push({
          name: item.product_details.name,
          unit_price: item.productSelection.price,
          discounted_price: item.productSelection.discounted_price,
          amount:
            item.quantity * item.productSelection.discounted_price ||
            item.productSelection.price,
          gallery: item.product_details.gallery,
          quantity: item.quantity,
          variants: item.variant,
          productSelection: item.productSelection,
          uuid: item.uuid,
          store: selected_order.store_data.uuid
        });
      } else {
        cart.push({
          name: item.product_details.name,
          unit_price: item.product_details.price,
          discounted_price: item.product_details.discounted_price,
          amount:
            item.quantity * item.product_details.discounted_price ||
            item.product_details.price,
          gallery: item.product_details.gallery,
          quantity: item.quantity,
          variants: item.variant,
          productSelection: item.productSelection,
          uuid: item.uuid,
          store: selected_order.store_data.uuid
        });
      }
    });

    product_selected.map(item => {
      items.push({
        uuid: item.uuid,
        qty: item.quantity,
        always_available: item.product_details.always_available,
        product_selection: item.productSelection ? item.productSelection.id : ""
      });
    });
    const promo_extra_vals = promo.eligible ? { promo_code: "12TWELVE" } : {};
    const voucher = applied_voucher
      ? JSON.stringify({ ...applied_voucher, voucher_discount })
      : "";
    let values = {
      site: selected_order.store_data.site,
      full_name: customer_details.first_name + " " + customer_details.last_name,
      address:
        customer_details.street_address +
        ", " +
        customer_details.barangay +
        ", " +
        customer_details.city +
        ", " +
        customer_details.province +
        ", " +
        customer_details.postal_code,
      mobile_number: customer_details.mobile_number,
      amount: parseFloat(amount - voucher_discount),
      other_fees: parseFloat(0),
      subtotal: parseFloat(subtotal),
      recipient: customer_details.email,
      uuid: selected_order.store_data.uuid,
      cart: JSON.stringify(cart),
      items: items,
      items_total_qty: product_selected.reduce((a, b) => a + b.quantity, 0),
      purchase_amount: amount,
      voucher,
      shipping_fee: delivery_details.shipping_fee,
      delivery_detail: {
        details: { ...delivery_details.delivery_detail },
        method: delivery_details.shipping
      },
      ...promo_extra_vals
    };

    let res = await dispatch(BuyerActions.checkout(values, paymentType));

    try {
      if (res.status === 200) {
        let all_cart_products = [];
        let products = cart_items
          .filter(item => item.store_data.uuid)
          .map(item => item.items);
        let ref_id = res.data ? res.data.description : null;
        let access = res.data ? res.data.bux_uid : null;

        for (let i in products) {
          for (let e in products[i]) {
            all_cart_products.push(products[i][e]);
          }
        }

        let checkout_products = product_selected.map(i => i.id);
        let params = selected_order.items.filter(
          item => !checkout_products.includes(item.id)
        );
        let items = cart_items
          .filter(item => item.id !== selected_order.id)
          .map(item => item.items);

        if (items) {
          for (let i in items) {
            for (let e in items[i]) {
              params.push(items[i][e]);
            }
          }
        }
        dispatch(
          StoreActions.patchBuyerCart(
            params && params.length === 0 ? [{}] : params
          )
        );
        dispatch(BuyerActions.setSelectedViewOrder(""));
        if (paymentType["paymentOption"] === "otc") {
          if (access) {
            window.location.href = `${apiConfig.buxURL}/checkout/${access}/?redirect_url=${apiConfig.localHost}my_cart/checkout?ref_id=${ref_id}`;
          }
        } else {
          history.push(`/my_cart/checkout`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (selected_order) {
      dispatch(BuyerActions.getPayments(selected_order.store_data.uuid));
    }

    // }
  }, [selected_order]);

  React.useEffect(() => {
    if (!product_selected.length) {
      history.push(Path.BUYER_DASHBOARD);
    }
  }, [product_selected]);
  return (
    <div style={{ paddingBottom: isMobile ? "50vh" : "auto" }}>
      <div className={classes.header}>
        <Button
          startIcon={<ArrowBackIcon color="primary" />}
          variant="text"
          color="primary"
          onClick={() => {
            history.goBack();
            window.scrollTo(0, 0);
          }}
          style={{ position: isMobile ? "inherit" : "absolute" }}
        >
          Back
        </Button>
        <div className={classes.headerLabel}>Payment</div>
      </div>
      <div style={{ display: isMobile ? "block" : "flex" }}>
        <PaymentOptionsForm
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          payments={payments}
        />
        <SummaryForm
          loading={loading}
          proceed_to_checkout={handleProceed}
          disabled={
            paymentType.paymentOption !== "otc"
              ? paymentType.account === ""
              : false || !product_selected.length
          }
        />
      </div>
      <VoucherModal open={openVoucher} setOpen={setOpenVoucher} />
      <RedirectToBuxModal
        open={openModal}
        handleClose={handleClose}
        handleBuxCheckout={handleBuxCheckout}
      />
    </div>
  );
}
export default Payment;
