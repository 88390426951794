import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles, IconButton, useMediaQuery } from "@material-ui/core";
import DashboardCard from "./webCard";
import { ReactComponent as FacebookIcon } from "../../assets/images/icons/fb.svg";
import { ReactComponent as ViberIcon } from "../../assets/images/icons/viber.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/images/icons/whatsapp.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/icons/twitter.svg";
import { ReactComponent as ShareFilledIcon } from "../../assets/images/icons/copy.svg";
import {
  FacebookShareButton,
  ViberShareButton,
  WhatsappShareButton,
  TwitterShareButton
} from "react-share";
import apiConfig from "../../common/apiConfig";
import { showAlert } from "../common/commonActions";
import CopyToClipboard from "react-copy-to-clipboard";
import DashboardAds from "./dashboardAds";
import img from "../../assets/images/ads/ad1.png";
import { Carousel } from "antd";
import { useTheme } from "@material-ui/styles";
export default function Sections(props) {
  const { stores, language } = props;
  const useStyles = makeStyles(theme => ({
    card: {
      borderRadius: 4,
      border: "1px solid #eee",
      backgroundColor: "#fff"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const site_url = `${apiConfig.localHost}shop/${
    stores && stores.length ? stores[0].site : ""
  }`;
  const share_buttons = [
    {
      comp: (
        <FacebookShareButton url={site_url}>
          <IconButton className="p-2">
            <FacebookIcon />
          </IconButton>
        </FacebookShareButton>
      )
    },
    {
      comp: (
        <ViberShareButton url={site_url}>
          <IconButton className="p-2">
            <ViberIcon />
          </IconButton>
        </ViberShareButton>
      )
    },
    {
      comp: (
        <WhatsappShareButton url={site_url}>
          <IconButton className="p-2">
            <WhatsappIcon />
          </IconButton>
        </WhatsappShareButton>
      )
    },
    {
      comp: (
        <TwitterShareButton url={site_url}>
          <IconButton className="p-2">
            <TwitterIcon />
          </IconButton>
        </TwitterShareButton>
      )
    },
    {
      comp: (
        <CopyToClipboard
          onCopy={() =>
            dispatch(
              showAlert({ type: "success", message: "Copied to clipboard!" })
            )
          }
          text={site_url}
        >
          <IconButton className="p-2">
            <ShareFilledIcon />
          </IconButton>
        </CopyToClipboard>
      )
    }
  ];
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const subscription = useSelector(state => state.subscription.subscription);
  const ref = React.useRef(null);

  function useWindowSize() {
    const [size, setSize] = React.useState([0, 0]);
    React.useLayoutEffect(() => {
      function updateSize() {
        setSize([ref.current.offsetWidth - 22, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  return (
    <div className="d-flex col-12 p-0">
      <div className="col-6 pl-0">
        <DashboardCard stores={stores} language={language} />
      </div>
      <div ref={ref} className="col-6 pr-0">
        <div className={`${classes.card} p-3 d-flex align-items-center`}>
          <div style={{ fontSize: 14, color: "#54575f" }}>
            Share your online shop:
          </div>
          <div className="d-flex ml-3">
            {share_buttons.map((item, key) => (
              <div>{item.comp}</div>
            ))}
          </div>
        </div>
        <div
          className="my-3"
          style={{
            width: width
            // subscription && subscription.subscription_type === "advanced"
            //   ? width
            //   : "100%"
          }}
        >
          <DashboardAds />
        </div>
      </div>
    </div>
  );
}
