import { makeStyles } from "@material-ui/core";
import logosDesktop from "../../../assets/images/marketing page assets/logosDesktop2.png";
import logosMobile from "../../../assets/images/logosMobile4.png";
const ContentStyles = makeStyles(theme => ({
  root: {
    marginTop: "5%",
    color: "#002C42",
    [theme.breakpoints.down("md")]: {
      marginTop: "10%"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%"
    }
  },

  content1: {
    height: "100vh",
    padding: "100px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      height: "70vh"
    },
    [theme.breakpoints.down("sm")]: {
      padding: 5,
      height: 420
    },
    [theme.breakpoints.down("xs")]: {
      padding: 5,
      height: "125vh"
    }
  },

  content2: {
    height: "100vh",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "75vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: 500
    },
    [theme.breakpoints.down("xs")]: {
      height: "97vh"
    }
  },

  content3: {
    width: "100%",
    height: "82vh",
    [theme.breakpoints.down("md")]: {
      height: "60vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: 400
    },
    [theme.breakpoints.down("xs")]: {
      height: "50vh"
    }
  },

  content4: {
    height: "100vh",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "75vh"
    },
    [theme.breakpoints.down("sm")]: {
      height: 500
    },
    [theme.breakpoints.down("xs")]: {
      height: "70vh"
    }
  },

  button: {
    border: "none",
    color: "#fff",
    fontSize: 16,
    fontWeight: 600,
    padding: "7px 52px",
    height: "55px",
    backgroundColor: "#F97663",
    marginTop: "3vh"
  },

  footer: {
    width: "100%",
    height: 85,
    display: "flex",
    alignItems: "center",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto"
    }
  },

  title: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },

  contentPayment: {
    marginTop: 20,
    width: "101%",
    background: "red",
    [theme.breakpoints.down("sm")]: {
      background: "red"
    }
  },
  content1Image: {
    padding: "25px 0px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      padding: "25px 0px"
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: "1vh 0"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: "1vh 0"
    }
  },

  content1Image1: {
    textAlign: "center",
    padding: "16px 40px",
    [theme.breakpoints.down("md")]: {
      padding: "16px 55px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px 5px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5vh 0"
    }
  },
  content1Image2: {
    textAlign: "center",
    padding: "16px 40px",
    [theme.breakpoints.down("md")]: {
      padding: "16px 55px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px 5px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5vh 0"
    }
  },

  content2Details: {
    padding: "0 40px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: "1vh 0"
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap-reverse",
      padding: "1vh 0"
    }
  },

  content2Title: {
    padding: "110px 83px",
    [theme.breakpoints.down("md")]: {
      padding: "110px 0"
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: "9vh"
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      marginTop: "5vh"
    }
  },

  content2Image: {
    width: 496,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "5vh"
    }
  },

  content3Details: {
    padding: "0 40px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: "7vh 0"
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      display: "block"
    }
  },

  content3Image: {
    width: 496,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  content3Title: {
    padding: "110px 83px",
    [theme.breakpoints.down("md")]: {
      padding: "110px 0"
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: "5vh"
    }
  },

  content4Details: {
    padding: "0 40px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: "1vh 0"
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap-reverse",
      padding: "1vh 0"
    }
  },

  content4Title: {
    padding: "110px 83px",
    [theme.breakpoints.down("md")]: {
      padding: "110px 0"
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: "9vh"
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      marginTop: "5vh"
    }
  },

  content4Image: {
    width: 496,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "5vh"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1vh"
    }
  },

  content5Details: {
    padding: "0 40px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: "1vh 0"
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      display: "block"
    }
  },

  content5Image: {
    width: 496,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  content5Title: {
    padding: "110px 83px",
    [theme.breakpoints.down("md")]: {
      padding: "110px 0"
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: "5vh"
    }
  },

  logos: {
    width: "600px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "500px",
      height: "auto"
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      height: "auto"
    }
  },
  logosMargin: {
    marginTop: "7vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2vh"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "3vh"
    }
  },
  marketingLogos: {
    backgroundImage: `url(${logosDesktop})`,
    backgroundSize: "cover",
    width: 800,
    height: 270,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${logosMobile})`,
      width: 480,
      height: 365
    },
    [theme.breakpoints.down("xs")]: {
      width: 280,
      height: 208
    }
  },
  footerBox: {
    padding: "0 40px",
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  footerContent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center"
    }
  },

  footerContent2: {
    marginLeft: 160,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginLeft: 80
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: 0,
      textAlign: "center",
      marginTop: "2vh"
    }
  },

  footerContent3: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15
  },

  btnSentro: {
    backgroundColor: "#F97663",
    width: "300px",
    marginTop: "5vh",
    height: 64,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      height: 70
    }
  },

  btnGetStarted: {
    height: 64,
    width: 280,
    fontSize: 16,
    boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
    "&:hover": {
      backgroundColor: "#DD5e56"
    },

    [theme.breakpoints.down("xs")]: {
      height: 60,
      width: 250
    }
  },

  faqStyle: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },

  ubxLogo: {
    width: "auto",
    height: 22,
    "&:hover": {
      opacity: 0.7
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      height: 20
    }
  },

  bspRegulated: {
    margin: 20
  },

  touFAQ: {
    paddingLeft: 20,
    display: "flex"
  },
  copyright: {
    margin: 30,
    [theme.breakpoints.down("sm")]: {
      margin: 0
    }
  },
  terms: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginBottom: "2vh"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginBottom: "4vh"
    }
  },

  contentH2Text: {
    color: "#002C42",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 26
    }
  },
  contentContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  contentSh2: {
    textAlign: "center",
    padding: "2vh 12em",
    fontSize: 24,
    color: "#2B2D33",
    fontWeight: "300",
    [theme.breakpoints.down("md")]: {
      padding: "0px 55px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      marginTop: "2vh",
      padding: "0px 35px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      marginTop: "2vh",
      padding: 0
    }
  },
  link: {
    fontSize: 12,
    color: "#F97663",
    fontWeight: 600,
    "&:hover": {
      textDecoration: "underline",
      color: "#F97663"
    }
  }
}));

export default ContentStyles;
