import React from "react";
import {
  Paper,
  Chip,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
  TableBody,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import StorefrontIcon from "@material-ui/icons/Storefront";
import sampleImg from "../../../assets/images/imgPlaceholder2.svg";
import tags from "../../../assets/images/tags.svg";
import history from "../../../store/history";
import Path from "../../../Paths";
import apiConfig from "../../../common/apiConfig";
import BuyerActions from "./redux/actions";
import { useSelector, useDispatch } from "react-redux";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Utils from "../../common/utils";
import { useDefaultAddress } from "./hooks/useAddresses";

const CartCard = props => {
  const { item, alert, setAlert } = props;

  const useStyles = makeStyles(theme => ({
    cartHeaderSection: {
      padding: "18px 24px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #d1d5dd",
      [theme.breakpoints.down("sm")]: {
        padding: 18
      }
    },
    cartHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149"
    },
    btnViewShop: {
      padding: "0 20px",
      fontWeight: 500,
      borderRadius: 20,
      height: 40
    },
    labelbold: {
      fontSize: 14,
      fontWeight: 600,
      color: "54575f"
    },
    label: {
      fontSize: 14,
      color: "#54575f"
    },
    cartTotalSection: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "3vh"
    },
    btnCart: {
      height: 40,
      fontWeight: 500,
      fontSize: 12
    },
    productImg: {
      width: 80,
      height: 80,
      marginRight: 8,
      borderRadius: 4
    },
    morePhotoSection: {
      position: "absolute",
      color: "#fff",
      zIndex: 3,
      width: "100%",
      height: "100%",
      fontSize: 12
    },
    moreOverlay: {
      borderRadius: 4,
      marginRight: 8,
      position: "absolute",
      backgroundColor: "#000",
      opacity: 0.3,
      width: "100%",
      height: "100%"
    },
    moreLabel: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
    cardContent: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const cart = item ? item.items : [];
  // const voucher = item ? (item.voucher ? JSON.parse(item.voucher) : "") : "";
  function productImage(item) {
    if (item.gallery) {
      if (item.gallery.length) {
        return item.gallery[0].image;
      } else {
        return sampleImg;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return sampleImg;
      }
    }
  }

  const [has_default_address] = useDefaultAddress();

  function handleProceed() {
    let noVariantSelected = [];
    let notEnoughQuantity = [];
    let params = [];
    for (let i in cart) {
      if (cart[i].product_details.has_variants && !cart[i].productSelection) {
        noVariantSelected.push(cart[i]);
      } else if (
        cart[i].productSelection &&
        cart[i].quantity > cart[i].productSelection.quantity
      ) {
        notEnoughQuantity.push(cart[i]);
      } else if (!cart[i].product_details.always_available) {
        if (cart[i].quantity > cart[i].product_details.quantity) {
          notEnoughQuantity.push(cart[i]);
        }
      }
    }

    for (let i in cart) {
      params.push({
        ...cart[i],
        id: parseInt(i)
      });
    }

    if (noVariantSelected.length || notEnoughQuantity.length) {
      handleViewOrders();
    } else {
      dispatch(BuyerActions.setSelectedCheckout([...params]));
      dispatch(BuyerActions.setSelectedViewOrder(item));

      if (has_default_address) {
        history.push(`/my_cart/delivery`);
      } else {
        history.push(`/my_cart/details`);
      }
      window.scrollTo(0, 0);
    }
  }

  async function handleViewOrders() {
    let response = await dispatch(
      BuyerActions.getSelectedOrder(item.store_data.uuid, item.id)
    );
    try {
      if (response.status === 200) {
        window.scrollTo(0, 0);
        history.push(Path.CART_VIEW_ORDER);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Paper>
      <div className={classes.cartHeaderSection}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.cartHeader}>
            {item ? item.store_data.name : ""}
          </div>
          {/* <Chip style={{ marginLeft: "2vw" }} label="Sports & Travel" /> */}
        </div>
        {isMobile ? (
          <IconButton
            onClick={() =>
              window.open(
                `${apiConfig.localHost}shop/${item ? item.store_data.site : ""}`
              )
            }
          >
            <StorefrontIcon color="primary" />
          </IconButton>
        ) : (
          <Button
            color="primary"
            className={classes.btnViewShop}
            variant="text"
            startIcon={<StorefrontIcon color="primary" />}
            onClick={() =>
              window.open(
                `${apiConfig.localHost}shop/${item ? item.store_data.site : ""}`
              )
            }
          >
            View shop
          </Button>
        )}
      </div>
      <div style={{ padding: 24 }}>
        <div className={classes.cardContent}>
          <div style={{ width: isMobile ? "100%" : "50%" }}>
            <div className={classes.labelbold}>
              Items: {item ? item.num_of_items : ""}
            </div>
            <div style={{ marginTop: "3vh", display: "flex" }}>
              {cart.length
                ? cart.slice(0, 3).map((item, key) => {
                    return key === 2 ? (
                      <div
                        className={classes.productImg}
                        style={{
                          backgroundSize: "cover",
                          backgroundImage: `url(${cart[2].gallery[0].image})`,
                          position: "relative"
                        }}
                      >
                        <div className={classes.morePhotoSection}>
                          <div className={classes.moreLabel}>
                            <MoreHorizIcon style={{ color: "#fff" }} />
                            {cart.length - 2} more
                          </div>
                        </div>
                        <div className={classes.moreOverlay}></div>
                      </div>
                    ) : (
                      <img
                        src={productImage(item)}
                        className={classes.productImg}
                      />
                    );
                  })
                : ""}
            </div>
          </div>
          <div
            style={{
              width: isMobile ? "100%" : "50%",
              marginTop: isMobile ? "2vh" : 0
            }}
          >
            <div className={classes.cartTotalSection}>
              <div className={classes.label}>Sub-total</div>
              <div className={classes.label}>
                ₱ {Utils.commafy(item ? item.subtotal : "")}
              </div>
            </div>
            {/* {voucher ? (
              <div className={classes.cartTotalSection}>
                <div className={classes.label}>Voucher</div>
                <div className={classes.label}>
                  - ₱ {voucher.discount_value}
                </div>
              </div>
            ) : (
              ""
            )} */}

            <div className={classes.cartTotalSection}>
              <div className={classes.labelbold}>Total</div>
              <div className={classes.labelbold}>
                ₱ {Utils.commafy(item ? item.subtotal : "")}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: isMobile ? 0 : "7vh"
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.btnCart}
            onClick={handleViewOrders}
          >
            View orders
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            className={classes.btnCart}
            style={{ marginLeft: 10 }}
            onClick={handleProceed}
          >
            Proceed to checkout
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default CartCard;
