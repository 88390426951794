import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import "./index.css";
import HomeContent from "./HomeContent";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const PreviewHome = props => {
  const ProductStyles = makeStyles(theme => ({
    content: {
      height: "100vh",
      width: "100%"
    },
    productCard: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      margin: "0 63px",
      [theme.breakpoints.down("sm")]: {
        margin: "0"
      }
    }
  }));

  const classes = ProductStyles();

  let storeInfo = props.storeInfo;
  let productList = props.productList;

  const store_generated_theme = createMuiTheme({
    palette: { ...storeInfo.color }
  });

  return (
    <div
      style={{
        backgroundColor: "#fff"
      }}
    >
      <ThemeProvider theme={store_generated_theme}>
        {storeInfo && storeInfo.font.css_url ? (
          <link href={storeInfo.font.css_url} rel="stylesheet"></link>
        ) : (
          ""
        )}
        <HomeContent
          storeInfo={storeInfo}
          productList={productList}
          color={true}
          isPreview={true}
        />
      </ThemeProvider>
    </div>
  );
};

export default PreviewHome;
