import { Alert } from "antd";
import React from "react";
const StoreAlertBox = props => {
  const { alert } = props;
  return (
    <div style={{ display: alert.open ? "" : "none" }}>
      <Alert type={alert.alertType} message={alert.alertMessage} />
    </div>
  );
};

export default StoreAlertBox;
