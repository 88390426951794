import axios from "axios";
import apiConfig from "../../../common/apiConfig";
import history from "../../../store/history";
import Path from "../../../Paths";

class Services {
  static async loadCurrentUser() {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/current_user/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      });
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async userDetailMethod(method, id, data) {
    return await axios({
      method: method,
      url: `${apiConfig.apiURL}/api/admin/users/${id}/`,
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      },
      data
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
  static async getAdminDashboardData(query_params = "") {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/dashboard/${query_params}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async fetchUsersList(extra_url_param) {
    const extra_param = extra_url_param ? extra_url_param : "";
    try {
      const response = await axios.get(
        `${apiConfig.apiURL}/api/admin/users/${extra_param}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async deleteUser(email) {
    return await axios
      .delete(`${apiConfig.apiURL}/api/admin/users/${email}/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
  static async setUserPermission(email, is_admin) {
    try {
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/admin/users/${email}/`,
        {
          is_admin: is_admin
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async fetchShopsList(query_params, is_csv = false, filename = "") {
    try {
      const extra_req_props = is_csv && {
        responseType: "blob"
      };
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/dashboard/shops/${query_params}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          ...extra_req_props
        }
      );

      if (is_csv) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async fetchShop(uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/dashboard/shops/${uuid}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async deleteShop(uuid) {
    try {
      let response = await axios.delete(
        `${apiConfig.apiURL}/api/admin/dashboard/shops/${uuid}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async fetchProductsList(query_params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/dashboard/products/${query_params}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async checkIfEmailIsUnique(email) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/unique-email/?email=${email}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async signupAdminUser(data) {
    return await axios
      .post(`${apiConfig.apiURL}/api/admin/signup/`, data, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
  static async resendVerification(email) {
    return await axios
      .post(
        `${apiConfig.apiURL}/api/admin/signup/resend/`,
        {
          email
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
  static async nominatePasswordAdmin(method, get_params, password) {
    return await axios({
      method: method,
      url: `${apiConfig.apiURL}/api/admin/signup/verify/${get_params}`,
      data: {
        password: password
      }
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
  static async fetchOrdersList(query_params, is_csv = false, filename = "") {
    try {
      const extra_req_props = is_csv && {
        responseType: "blob" // important
      };
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/dashboard/orders/${query_params}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          ...extra_req_props
        }
      );
      if (is_csv) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async fetchBuxPaymentsList() {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/bux_payments/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async fetchLatestReport(category) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/reports/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params: {
            category
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  // MARKETPLACE
  static async createApp(data) {
    try {
      /// create app
      let request_data = new FormData();
      for (var key in data) {
        switch (key) {
          case "variants":
            request_data.append("variants", JSON.stringify(data["variants"]));
            break;
          case "images":
            for (let i in data["images"]) {
              request_data.append(
                `gallery-${parseInt(i) + 1}`,
                data["images"][i],
                data["images"][i].name
              );
            }
            break;
          default:
            request_data.append(key, data[key]);
            break;
        }
      }
      let response = await axios.post(
        `${apiConfig.apiURL}/api/marketplace/apps/`,
        request_data,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      history.push(Path.ADMIN_APP_MARKETPLACE);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async deleteApp(id) {
    try {
      let response = await axios.delete(
        `${apiConfig.apiURL}/api/marketplace/apps/${id}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      history.push(Path.ADMIN_APP_MARKETPLACE);
      return response.status === 204;
    } catch (error) {
      throw error.message;
    }
  }

  static async editApp(id, data) {
    try {
      /// create app
      let request_data = new FormData();
      for (var key in data) {
        switch (key) {
          case "variants":
            request_data.append("variants", JSON.stringify(data["variants"]));
            break;
          case "images":
            for (let i in data["images"]) {
              if (typeof data["images"][i] === "object") {
                request_data.append(
                  `gallery-${parseInt(i) + 1}`,
                  data["images"][i],
                  data["images"][i].name
                );
              } else {
                request_data.append(
                  `gallery-${parseInt(i) + 1}`,
                  data["images"][i]
                );
              }
            }
            break;
          default:
            request_data.append(key, data[key]);
            break;
        }
      }
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/marketplace/apps/${id}/`,
        request_data,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      history.push(Path.ADMIN_APP_MARKETPLACE);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async fetchApps(payload) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/apps`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params: {
            ...payload.extra_params
          }

        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async fetchApp(id, payload) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/apps/${id}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params: {
            ...payload.extra_params
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async fetchPartners(query_params = "?") {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/marketplace/partners/${query_params}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async fetchPartner(query_params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/marketplace/partners/${query_params}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async checkPartner(email) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/unique-partner/?email=${email}`,
        {},
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return false;
    } catch (response) {
      return response.response.status === 404;
    }
  }

  static async createPartner(data) {
    return await axios
      .post(`${apiConfig.apiURL}/api/marketplace/partners/`, data, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static async nominatePasswordPartner(method, get_params, password) {
    return await axios({
      method: method,
      url: `${apiConfig.apiURL}/api/marketplace/partner/signup/verify/${get_params}`,
      data: {
        password: password
      }
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static async resendVerificationPartner(email) {
    return await axios
      .post(
        `${apiConfig.apiURL}/api/marketplace/partner/signup/resend/`,
        {
          email
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static async patchPartner(id, data) {
    return await axios
      .patch(`${apiConfig.apiURL}/api/marketplace/partners/${id}/`, data, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static async deletePartner(id) {
    return await axios
      .delete(`${apiConfig.apiURL}/api/marketplace/partners/${id}/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static async fetchCategories() {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/category`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async checkCategory(name) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/unique-category/?name=${name}`,
        {},
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return false;
    } catch (response) {
      return response.response.status === 404;
    }
  }

  static async createCategory(data) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/marketplace/category/`,
        data,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async uploadS3(file, path) {
    try {
      let data = new FormData();
      console.log(path.slice(1));
      data.append("key", path.slice(1));
      data.append("file", file);
      console.log(
        "SERVICES",
        `https://sentro-marketplace-images.s3.amazonaws.com${path}`
      );
      let response = await axios.post(
        `https://sentro-marketplace-images.s3.amazonaws.com`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      return `https://sentro-marketplace-images.s3.amazonaws.com${path}`;
    } catch (error) {
      throw error.message;
    }
  }
  static async fetchSubscriptions(query_params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/marketplace/subscriptions/${query_params}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async patchSubscriptions(id, data) {
    try {
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/admin/marketplace/subscriptions/${id}/`,
        data,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return true;
    } catch (error) {
      throw error.message;
    }
  }

  // PROMO
  static async getPromoRecipients() {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/promo/promo_recipient/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async getLogiscticsHomeData(query_params=false) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/logistics/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params: {...query_params}
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async fetchLogiscticsBookings(query_params=false) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/logistics/bookings/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params: {...query_params}
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async fetchLogiscticsBooking(id) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/admin/logistics/bookings/${id}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
export default Services;
