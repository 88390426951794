import React from "react";
import {
  makeStyles,
  Button,
  TextField,
  FormControlLabel,
  InputAdornment,
  FormGroup,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Dialog,
  DialogContent
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
const DeactivateVoucherModal = props => {
  const { open, setOpen, handleDeactivate } = props;

  const useStyles = makeStyles(theme => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content"
    },
    icon: {
      width: "100%",
      textAlign: "center",
      color: "#ee2545",
      fontSize: 100
    },
    subheader: {
      fontSize: 18,
      fontWeight: 600,
      color: "#54575f"
    }
  }));

  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogContent style={{ padding: 24 }}>
        <div className={classes.form}>
          <ErrorIcon className={classes.icon} />
          <div style={{ textAlign: "center", marginTop: "2vh" }}>
            <div className={classes.subheader}>
              Are you sure you want to deactivate this voucher?
            </div>
            <div style={{ fontSize: 14, marginTop: "2vh", color: "#54575f" }}>
              This voucher is currently active. Deactivating this now might
              confuse your users.
            </div>
            <div
              style={{
                marginTop: "2vh",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{ width: "50%", fontSize: 12, height: 40 }}
                onClick={() => setOpen(false)}
              >
                No, take me back
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                style={{
                  width: "50%",
                  fontSize: 12,
                  height: 40,
                  marginLeft: 10
                }}
                onClick={() => handleDeactivate(false)}
              >
                Yes, deactivate
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeactivateVoucherModal;
