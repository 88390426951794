import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import SentroButton from "../common/sentroButton";
import { Modal, Button } from "antd";
import { useDispatch } from "react-redux";

const RedirectDialog = props => {
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      width: 470,
      backgroundColor: "#f5f5f4",
      borderRadius: "4px",
      padding: theme.spacing(4, 6, 4)
    },

    sidebar: {
      color: "#54575F",
      fontWeight: 600,
      "&hover": {
        color: "#F97663"
      }
    }
  }));
  //

  const dispatch = useDispatch();
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <Modal
      visible={props.open}
      onCancel={props.onClose}
      zIndex={2000}
      centered={true}
      footer={null}
      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
      // open={props.open}
      // onClose={props.onClose}
    >
      <div>
        <h2 id="simple-modal-title" style={{ fontWeight: "bold" }}>
          {props.header}
        </h2>
        <p id="simple-modal-description">
          <div>{props.text}</div>
          <Box style={{ marginTop: 40 }}>
            <SentroButton
              name="proceed-button"
              id="proceed_button"
              style={{ backgroundColor: "#F97663" }}
              variant="contained"
              fullWidth
              label="OK"
              onClick={() => props.onProceed()}
            />
          </Box>
        </p>
      </div>
    </Modal>
  );
};

export default RedirectDialog;
