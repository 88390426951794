import React from "react";
import { useSelector } from "react-redux";

const RemarksComponent = () => {
  let remarks =  useSelector(state => state.store.remarks);
  return (
    <div>
    { Object.keys(remarks).length > 0 ? (
      <div
        style={{
          width: "100%",
          backgroundColor: "#eee",
          padding: 16
        }}
      >
        <div style={{ fontSize: 14, fontWeight: 500, paddingBottom: 15 }}>
          Additional Notes
        </div>
      {
        Object.keys(remarks).map((key, index) => (
          <div style={{ display: "flex", 
                        fontSize: 12,
                        fontWeight: 600 }}>
            <div style={{ textTransform: "capitalize", width: "30%"}}>
              {key.replaceAll('_', ' ')}
            </div>
            <div style={{ width: "70%"}}>
              {remarks[key]}
            </div>
          </div>
        ))
      }
    </div>) : ""
    } 
    </div>
  )
}

export default RemarksComponent;