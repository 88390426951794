import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ACL from "../common/utilities/ACL"
import Action from "./Redux/Actions"
import AdminDashboardLayout from "./layout"
import apiConfig from "../../common/apiConfig";
import AdminTable from './components/adminTable'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ReportsPopover from "./components/reports_popover";

const headCells = [
	{ id: 'first_name', numeric: false, disablePadding: true, label: 'Name' },
	{ id: 'email', numeric: false, disablePadding: false, label: 'Email Address' },
	{ id: 'stores', numeric: false, disablePadding: false, label: 'Name of Shops' },
	{ id: 'id', numeric: false, disablePadding: false, label: 'Member Since' },
	{ id: 'last_login', numeric: false, disablePadding: false, label: 'Last Login' },
];

const permissionStyles = theme => ({
	paper: {
		overflow: "hidden",
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(1),
		},
	},
	searchInputWrapper: {
		display: "flex"
	},
	searchInput: {
		flexGrow: 1
	},
	tableWrapper: {
		overflowX: "auto"
	},
	errorMessage: {
		color: "red"
	},
	primaryColor: {
		color: theme.palette.text.primary
	},
	dFlex: {
		display: "flex"
    },
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	searchBar: {
		background: "white",
		maxWidth: "240px",
		borderRadius: "4px",
		color: "gray",
		fontSize: 12,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "100%",
			width: "100%"
		},
	},
	excelExportButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		border: "solid 1px #F97663",
		background: " #F97663",
		borderRadius: 4,
		marginRight: 10,
		color: "white",
		transition: "all 0.3s linear",
		"&:hover": {
			background: "#f96a63"
		},
		"&:focus": {
			outline: 0
		},
		boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
	},
	headerActionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		},
	},
	pageHeaderButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		marginRight: 20
	},
});

class MonthlyActive extends React.Component {
	constructor(props) {
		super(props);
		this.searchTimeout = 0; // trigger fetch after a typing inactivity
		this.state = {
			// filters
			date_filter_query: "",
			order: "",
			orderBy: "",
			search_query: "",
			page: 0,
			rowsPerPage: 5,
		};
		this.onSearch = this.onSearch.bind(this)
		this.fetch = this.fetch.bind(this)
		this.onSortRequest = this.onSortRequest.bind(this)
		this.constructFilterQuery = this.constructFilterQuery.bind(this)
	}

	fetch = (query_filters, is_csv=false, filename="") => {
		const { dispatch } = this.props
		dispatch(Action.fetchUsers(query_filters, is_csv, filename))
	}

	handleOpenPopover = (event) => {
		this.props.dispatch(Action.fetchLatestReport("Users"))
		this.setState({
			...this.state,
			anchorEl: event.currentTarget
		})
	}

	handleClosePopover = () => {
		this.setState({
			...this.state,
			anchorEl: null
		})
	}

	downloadCSV = () => {
		const { dispatch } = this.props
		dispatch(Action.fetchUsers(
			this.constructFilterQuery()+"&csv_download=True&filename=MonthlyActiveUsers", 
			true
		))
	}

	constructFilterQuery(){
		return (
			"?order=" + this.state.order + 
			"&orderBy=" + this.state.orderBy + 
			"&page=" + this.state.page +
			"&rowsPerPage=" + this.state.rowsPerPage +
			this.state.search_query+
			"&monthly_active=True")
	}
	
	onSearch(event) {
		const search_query = event.target.value;
		if(this.searchTimeout) clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.setState({
				search_query: `&q=${search_query.toLowerCase()}`,
				page: 0
			}, () => {
				console.log(this.constructFilterQuery())
				this.fetch(this.constructFilterQuery());
			})
		}, 500);
	}

	componentDidMount = async() => {
		this.fetch(this.constructFilterQuery());
	}

	onSortRequest(order, orderBy, page, rowsPerPage){
		this.setState({
			order,
			orderBy,
			page,
			rowsPerPage
		}, () => {
			console.log(this.constructFilterQuery())
			this.fetch(this.constructFilterQuery());
		})
	}
	
	render (){
		const {
			filteredList
		} = this.state
		const { classes, adminDashboard, history } = this.props
		return (
			<AdminDashboardLayout filterHeaderHidden={true}>
				<ACL roles={["admin", "superuser"]} redirect_to="/login">
					<ReportsPopover 
						reports={adminDashboard.reports.data} 
						anchorEl={this.state.anchorEl}
						handleReportPopoverClose={this.handleClosePopover}
						onButtonClick={this.downloadCSV}
						loading={adminDashboard.reports.loading}
					/>
					<div style={{
						display: "flex", 
						flexWrap: "wrap",
						alignItems: "center",
						marginBottom: "1.5em",
						padding: "0 1.5em"
					}}>
						<div className={classes.pageTitle}>
							Monthly Active Users
						</div>
						<div className={classes.headerActionsWrapper}>
							{
								adminDashboard.loading && 
								<CircularProgress color="secondary" style={{height: 25, width: 25, color: "#f97663", marginRight: 20}}/>
							}
							<Button
								id="add_user_button" 
								variant="contained" 
								color="primary" 
								disableElevation 
								className={classes.pageHeaderButton}
								onClick={(event) => this.handleOpenPopover(event)}
							>
								Downloads
							</Button>
							<OutlinedInput
								className={classes.searchBar}
								id="outlined-adornment-weight"
								onChange={(event) => this.onSearch(event)}
								startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
									'aria-label': 'weight',
								}}
								labelWidth={0}
								margin="dense"
								placeholder="Type here to search"
							/>
						</div>
					</div>
					<div style={{ padding: "0 1.5em 1em"}}>
						<Paper className={classes.card}>
							<AdminTable 
									loading={adminDashboard.loading}
									headCells={headCells}
									body={<TableBody>
											{adminDashboard.usersList.map((row, index) => {
												return (
													<TableRow
														key={index}
														hover
														// onClick={(event) => handleClick(event, row.first_name + " " + row.last_name)}
														role="checkbox"
														tabIndex={-1}
													>
														<TableCell component="th" scope="row"
															style={{ marginLeft: "1em"}}
														>	
															{`${row.first_name} ${row.last_name}`}
														</TableCell>
														<TableCell align="left">{row.email}</TableCell>
														<TableCell align="left">
															{row.stores.map((shop, index) => (
																<a key={index} href={`${apiConfig.localHost}shop/${shop.site}`} target="_blank">
																	{shop.name}
																</a>
															))}
														</TableCell>
														<TableCell align="left">
															{row.created_on}
														</TableCell>
														<TableCell align="left">
															{row.last_login}
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									}
									length={adminDashboard.listLength}
									page={this.state.page}
									rowsPerPage={this.state.rowsPerPage}
									onSortRequest={this.onSortRequest}
								/>
						</Paper>
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(permissionStyles)(MonthlyActive))
