import React from "react";
import { Input } from "antd";
import { Box, Container, Typography, Divider, Link } from "@material-ui/core";
import { Button } from "antd";
import ProductContentStyles from "./productContentStyles";
import ProductCard from "./productCard";
import { getAll, getSelectProduct, getProduct } from "./productActions";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import Path from "../../Paths";
import history from "../../store/history";
import Footer from "../common/layout/components/footer";
import Text from "../../common/text";
const { Search } = Input;
const ProductContent = props => {
  const classes = ProductContentStyles();
  const dispatch = useDispatch();
  let products = useSelector(state => state.products.products);

  let loading = useSelector(state => state.products.loading);

  let sidebar = [
    { label: "All" },
    { label: "Food" },
    { label: "Clothing" },
    { label: "Others" }
  ];

  React.useEffect(() => {
    onSearch("");
  }, []);

  function onSearch(value) {
    dispatch(getProduct(value));
  }

  function onSelect(value) {
    dispatch(getSelectProduct(value));
  }
  return (
    <div>
      <Box className={classes.banner}>
        <Container className={classes.container}>
          <div className={classes.bannerImage} />
          <Text textType="h2" content="Product Marketplace" />
          <Text
            style={{ color: "#fff" }}
            textType="p1"
            content="Get the best deals from Asian entrepeneurs here"
          />

          <div className={classes.search}>
            <Search
              size="large"
              onBlur={e => onSearch(e.target.value)}
              placeholder="Type the name of the item here"
              onSearch={value => onSearch(value)}
              className={classes.inputSearch}
            />
          </div>
          <Button size="large" type="submit" className={classes.searchBtn2}>
            Search
          </Button>
        </Container>
      </Box>

      <Box className={classes.content}>
        <Container className={classes.container2}>
          <Box className={classes.products}>
            <Box style={{ flexGrow: 2 }}>
              <Text textType="h3" content="Explore" />
              <Text
                type="sh2"
                content="Shop for the latest items by Asian entrepreneurs"
              />

              <div className={classes.sidebar}>
                <ul className={classes.ul}>
                  {sidebar.map(item => {
                    return (
                      <li style={{ padding: "8px 0" }}>
                        <Link>
                          <Text textType="p1" content={item.label} />
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Box>

            <Box className={classes.productContent}>
              {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <img
                      style={{ width: 100, height: 100 }}
                      src="https://digitalsynopsis.com/wp-content/uploads/2016/06/loading-animations-preloader-gifs-ui-ux-effects-18.gif"
                    />
                  </div>
                </div>
              ) : products != [] ? (
                products.map((item, key) => {
                  return (
                    <ProductCard
                      onSelect={value => onSelect(value)}
                      key={key}
                      item={item}
                    />
                  );
                })
              ) : (
                "No"
              )}
            </Box>
          </Box>
          <Divider />
          <Footer />
        </Container>
      </Box>
    </div>
  );
};

export default ProductContent;
