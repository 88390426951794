import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ACL from "../../../common/utilities/ACL";
import AdminDashboardLayout from "../../layout";
import Actions from "../../Redux/Actions";
import moment from 'moment';
import ENVS from '../../../../common/apiConfig'

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    padding: "2.0em"
  },
  title: {
    color: "#002C42",
    fontWeight: "bold",
    fontSize: 28,
  },
  card: {
    border: "1px solid #E6EAF0",
    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
    margin: "1.5em",
    backgroundColor: "white",
    padding: "1.5em",
    [theme.breakpoints.down("sm")]: {
      margin: "1em"
    },
    fontSize: 16,
    maxWidth: 520
  },
  status: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    color: "white",
    "& .looking_for_rider": {
      background: "#FFC044",
      color: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .for_pickup": {
      background: "#FFC044",
      color: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .otw_to_customer": {
      background: "#FFC044",
      color: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .delivered": {
      background: "#57B19F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .completed": {
      background: "#57B19F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .cancelled": {
      background: "#54575F",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    }
  },
  key: {
    minWidth: 150
  },
  link: {
    fontWeight: "bold",
    color: "#F97663",
    marginBottom: 0,
    cursor: "pointer",
  }
});



const Logistics = (props) => {
  const { classes, history, dispatch, match, bookings } = props;

  React.useEffect(() => {
    dispatch(Actions.fetchLogiscticsBooking(match.params.id))
  }, [])

  let booking = {};
  try {
    booking = bookings.filter((order) => order.id === parseInt(match.params.id))[0]
  } catch {}

  const status = (deliveryStatus) => {
    if (deliveryStatus === "new" || deliveryStatus === "available") {
      return "Looking for rider";
    } else if (deliveryStatus === "active") {
      return "For pickup";
    } else if (deliveryStatus === "courier_departed") {
      return "OTW to Customer";
    } else if (deliveryStatus === "finished") {
      return "Delivered";
    } else if (deliveryStatus === "canceled") {
      return "Cancelled";
    } else if (deliveryStatus === "completed") {
      return "Completed";
    }
  }
  return (
    <AdminDashboardLayout filterHeaderHidden>
      <ACL roles={["admin", "superuser"]} redirect_to="/login">
        <h2 className={`${classes.title} mx-4`}>Delivery Details</h2>
        {
          booking ?
          <div className="mt-4 mx-1">
            <div className={classes.card}>
              <div className="d-flex">
                <p className={classes.key}>Tracking No:</p>
                <p><strong>{booking.mrspeedy_id}</strong></p>
                  <div className={`${classes.status} ml-auto`}>
                  {
                    booking.status ? 
                    <div className={status(booking.status).toLowerCase().split(" ").join("_")}>{status(booking.status)}</div>
                    : `--`
                  }
                  </div>
              </div>
              <div className="d-flex">
                <p className={classes.key}>Rider:</p>
                {
                  booking.courier ?
                    <p><strong>{booking.courier.name} {booking.courier.middlename} {booking.courier.surname}</strong></p>
                  : `--`
                }
              </div>
              <div className="d-flex">
                <p className={classes.key}>Contact No:</p>
                {
                  booking.courier ?
                    <p><strong>{booking.courier.phone}</strong></p>
                  : `--`
                }
              </div>
              <div className="d-flex">
                <p className={classes.key}>Pickup Point:</p>
                <p><strong>{booking.sender_address}</strong></p>
              </div>
              <div className="d-flex">
                <p className={classes.key}>Destination Point:</p>
                <p><strong>{booking.recipient_address}</strong></p>
              </div>
              <div className="d-flex">
                <p className={classes.key}>Receiver:</p>
                <p><strong>{booking.recipient_name}</strong></p>
              </div>
              <div className="d-flex">
                <p className={classes.key}>Delivery Fee:</p>
                <p><strong>PHP {booking.payment_amount}</strong></p>
              </div>
              <div className="d-flex">
                <p className={classes.key}>Date Delivered:</p>
                <p><strong>{booking.finish_datetime ? moment(booking.finish_datetime).format('lll'): "--"}</strong></p>
              </div>
              <hr />
              <h5 className="mb-3"><strong>Order Details</strong></h5>
              <div className="d-flex">
                <p className={classes.key}>Transaction No:</p>
                <p><strong>{booking.order_ref_id}</strong></p>
              </div>
              <div className="d-flex">
                <p className={classes.key}>Seller:</p>
                <p>
                  <strong>
                    <a className={classes.link} href={`${ENVS.localHost}shop/${booking.site}`} 
                    target="_blank">{booking.shop}</a>
                  </strong>
                </p>
              </div>
              <div className="d-flex">
                <p className={classes.key}>Buyer:</p>
                <p><strong>{booking.recipient_name}</strong></p>
              </div>
              <div className="d-flex">
                <p className={classes.key}>Amount: </p>
                <p><strong>PHP {booking.order_amount}</strong></p>
              </div>
            </div>
          </div>
          : '' 
        }
      </ACL>
    </AdminDashboardLayout>
  );
}

const mapStateToProps = state => ({ adminDashboard }) => ({ bookings: adminDashboard.logisticsBookings});
export default connect(mapStateToProps)(withStyles(useStyles)(Logistics));
