import {
  saveStore,
  saveStorev2,
  updateStore,
  addItem,
  getItems,
  removeItem,
  editItem,
  publish,
  addPayment,
  getPayments,
  updatePayment,
  deletePayment,
  patchStore as servicePatchStore,
  getStoreDetails,
  getStores,
  buxStatus,
  getStoreProductCategories as getStoreProductCategoriesService,
  deleteStoreServices,
  saveSurvey,
  extractProductsCSVService,
  tncAgreed,
  getProduct as getProductService,
  saveVariantService,
  getVariantsService,
  enableVariantService,
  addVariantGroupService,
  patchVariantService,
  getProductSelectionsService,
  saveProductSelectionsService,
  deleteProductSelectionsService,
  bulkActionsServices,
  getProductBatchUpload as getProductBatchUploadService,
  postProductBatchUpload as postProductBatchUploadService,
  deleteProductCategory as deleteProductCategoryService
} from "./websiteBuilderServices";
import { showAlert } from "../common/commonActions";
import history from "../../store/history";
import Path from "../../Paths";
import { change } from "redux-form";
import { signOff } from "../auth/authActions";
import { DashboardActionTypes } from "../dashboard/dashboardActions";
import { reset } from "redux-form";
import defaultVariants from "./desktop/forms/defaultVariants";
import { getVoucherService } from "../vouchers/services";

export const websiteBuilderActionTypes = {
  GET_STORE_REQUEST: "@@GET_STORE_REQUEST",
  GET_STORE_SUCCESS: "@@GET_STORE_SUCCESS",
  GET_STORE_INFO_WEB: "@@GET_STORE_INFO_WEB",
  SET_EDIT: "@@SET_EDIT",
  SAVE_STORE: "@@SAVE_STORE",
  EDIT_STORE: "@@EDIT_STORE",
  DELETE_STORE: "@@DELETE_STORE",
  PUBLISH_STORE: "@@PUBLISH_STORE",
  CLOSE_PUBLISH_DIALOG: "CLOSE_PUBLISH_DIALOG",
  BUILDER_REQUEST: "@@BUILDER_REQUEST",
  CONTINUE_BUILD: "@@CONTINUE_BUILD",
  BUILDER_FAILED: "@@BUILDER_FAILED",
  GET_PRODUCTS: "@@GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "@@GET_PRODUCTS_SUCCESS",
  ADD_PRODUCTS: "@@ADD_PRODUCTS",
  SET_ADD_PRODUCT_VIEW: "SET_ADD_PRODUCT_VIEW",
  REMOVE_PRODUCT: "@@REMOVE_PRODUCT",
  BUILDER_FAILED: "@@BUILDER_FAILED",
  HIDE_ALERT: "@@HIDE_ALERT",
  BACKWARD: "@@BACKWARD",
  FORWARD: "@@FORWARD",
  OPEN_DIALOG: "@@OPEN_DIALOG",
  CLOSE_DIALOG: "@@CLOSE_DIALOG",
  ADD_PAYMENT: "@@ADD_PAYMENT",
  GET_PAYMENTS_WEB: "@@GET_PAYMENTS_WEB",
  EDIT_PAYMENT: "@@EDIT_PAYMENT",
  DELETE_PAYMENT: "@@EDIT_PAYMENT",
  LOADING_OFF: "@@LOADING_OFF",
  LOADING_ON: "@@LOADING_ON",
  SET_ALERT_MESSAGE: "@@SET_ALERT_MESSAGE",
  GET_BUX_STATUS: "@@GET_BUX_STATUS",
  GET_STORE_PRODUCT_CATEGORIES: "@@GET_STORE_PRODUCT_CATEGORIES",
  GET_STORE_PRODUCT_CATEGORIES_SUCCESS:
    "@@GET_STORE_PRODUCT_CATEGORIES_SUCCESS",
  GET_STORE_PRODUCT_CATEGORIES_FAILED: "@@GET_STORE_PRODUCT_CATEGORIES_FAILED",
  DELETE_STORE_PRODUCT_CATEGORY: "DELETE_STORE_PRODUCT_CATEGORY",
  KYC_SUCCESS: "@@KYC_SUCCESS",
  AGREED_TNC: "@@AGREED_TNC",
  GET_VOUCHERS: "@@GET_VOUCHERS",
  GET_PRODUCT_VARIANTS: "@@GET_PRODUCT_VARIANTS",
  GET_PRODUCT_SELECTIONS: "@@GET_PRODUCT_SELECTIONS",
  SET_VARIANT_COMBINATIONS: "@@SET_VARIANT_COMBINATIONS",
  RESET_PRODUCT_SELECTIONS: "@@RESET_PRODUCT_SELECTIONS",
  SET_PRODUCT_FILTERS: "@@SET_PRODUCT_FILTERS",
  SET_PRODUCT_SELECTED: "@@SET_PRODUCT_SELECTED",
  BATCH_UPLOAD_SET_LOADING: "BATCH_UPLOAD_SET_LOADING",
  BATCH_UPLOAD_GET: "BATCH_UPLOAD_GET",
  BATCH_UPLOAD_POST: "BATCH_UPLOAD_POST"
};

// export function getStoreDetail(value) {
//   return async dispatch => {
//     try {
//       // dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
//       let response = await getStore(value);
//       dispatch({
//         type: websiteBuilderActionTypes.GET_STORE_INFO_WEB,
//         payload: response.data
//       });

//       history.push(Path.EDIT_SHOP_INFO);
//     } catch (error) {
//       dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
//       if (error && error.status == 401) {
//         dispatch(signOff());
//       } else {
//         dispatch({
//           type: websiteBuilderActionTypes.BUILDER_FAILED,
//           payload: error
//         });
//         dispatch(showAlert({ type: "error", message: error.message }));
//       }
//     }
//   };
// }

export function addPaymentMethod(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await addPayment(value);
      dispatch({
        type: websiteBuilderActionTypes.ADD_PAYMENT,
        payload: response.data
      });
      let payments = await getPayments(value.store);
      dispatch({
        type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
        payload: payments.data
      });
      // GTM trigger
      let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
      window.dataLayer.push({
        eventAction: "Added payment method",
        eventCategory: "Add payment method",
        event: "paymentmethodadded",
        userId: currentUser.id
      });

      // history.push(Path.BUILDER);
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log(error);
        // dispatch({
        //   type: websiteBuilderActionTypes.BUILDER_FAILED,
        //   payload: error,
        // });
        // dispatch(showAlert({ type: "error", message: error.message }));
      }
    }
  };
}

export function getPaymentMethods(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await getPayments(value);
      dispatch({
        type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
          payload: []
        });
      }

      // else {
      //   dispatch(showAlert({ type: "error", message: error }));
      // }
    }
  };
}

export function selectPaymentMethod(value) {
  return async dispatch => {
    try {
      dispatch({
        type: websiteBuilderActionTypes.EDIT_PAYMENT,
        payload: value
      });
      // history.push(Path.BUILDER);
    } catch (error) {
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(showAlert({ type: "error", message: error }));
      }
    }
  };
}

export function editPayment(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await updatePayment(value);
      let payments = await getPayments(value.store);
      dispatch({
        type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
        payload: payments.data
      });

      // history.push(Path.BUILDER);
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log(error);
        // dispatch(showAlert({ type: "error", message: error.message }));
      }
    }
  };
}

export function removePayment(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await deletePayment(value);
      let payments = await getPayments(value.store);
      dispatch({
        type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
        payload: payments.data
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(showAlert({ type: "error", message: error.message }));
      }
    }
  };
}

export function continueBuild(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      history.push(Path.BUILDER);
      dispatch({
        type: websiteBuilderActionTypes.CONTINUE_BUILD
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: error
        });
        dispatch(showAlert({ type: "error", message: error.message }));
      }
    }
  };
}

export function createStore(value, step, isEdit, currentStoreCreated) {
  //Step Index
  // switch(step){
  //   case 0 : 'Shop Info'
  //   case 1 : 'Shop Contact Info'
  //   case 2 : 'Shop Branding'
  //   case 3 : 'Product Page'
  //   case 4 : 'Payment Channels'
  // }

  return async dispatch => {
    let response = "";
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      if (isEdit || step > 0) {
        response = await updateStore(value, step);
        // GTM trigger
        if (value.is_published && step === 2) {
          const {
            template: oldTemplate,
            color: oldColor
          } = currentStoreCreated;
          const { template: newTemplate, color: newColor } = value;
          const hasTemplateChanged = oldTemplate !== newTemplate;
          const hasColorChanged = oldColor !== newColor;
          const currentUser = JSON.parse(
            sessionStorage.getItem("CURRENT_USER")
          );

          if (hasTemplateChanged) {
            window.dataLayer.push({
              eventAction: "Successful template change",
              eventCategory: "Template change",
              eventLabel: value.template,
              event: "republishtemplate",
              userId: currentUser.id
            });
          }

          if (hasColorChanged) {
            if (hasTemplateChanged) {
              window.dataLayer.push({
                eventAction: "Successful template color change",
                eventCategory: "Template Color change",
                eventLabel: value.color,
                event: "republishtemplatecolor",
                userId: currentUser.id
              });
            }
          }
        }
      } else {
        console.log(value, "save");
        response = await saveStore(value, step);
        dispatch({ type: websiteBuilderActionTypes.SET_EDIT });
      }
      dispatch({
        type: websiteBuilderActionTypes.EDIT_STORE,
        payload: response.data
      });
      if (step == 4) {
        dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
        history.push(Path.PUBLISH);

        // GTM trigger
        let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
        window.dataLayer.push({
          eventAction: "Store Builder Completed",
          eventCategory: "Store Builder",
          event: "storecompleted",
          userId: currentUser.id
        });
      } else {
        dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
        dispatch({ type: websiteBuilderActionTypes.FORWARD, payload: step });
      }
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log(error);
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: {
            step: step,
            isEdit: isEdit,
            error: error ? error.data : ""
          }
        });
        // dispatch(showAlert({ type: "error", message: error ? error.data : "" }));
      }
    }
  };
}
export function createStorev2(values) {
  return async dispatch => {
    let response = "";
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      response = await saveStorev2(values);
      console.log(response);
      dispatch({
        type: websiteBuilderActionTypes.EDIT_STORE,
        payload: response.data
      });
      return response.data;
    } catch (error) {}
  };
}

export function extractProductsCSV(store_uuid) {
  return async dispatch => {
    extractProductsCSVService(store_uuid);
  };
}

export function setAddProductView(bool) {
  return async dispatch => {
    dispatch({
      type: websiteBuilderActionTypes.SET_ADD_PRODUCT_VIEW,
      payload: bool
    });
  };
}

export function publishStore(value) {
  return async dispatch => {
    let response = "";
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });

      response = await publish(value);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      history.push(Path.PUBLISHED);
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: {
            error: error ? error.data : ""
          }
        });
        // dispatch(showAlert({ type: "error", message: error ? error.data : "" }));
      }
    }
  };
}

export function closePublishDialog() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.CLOSE_PUBLISH_DIALOG });
  };
}

export function goBackward(step) {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.BACKWARD, payload: step });
  };
}

export function hideAlert() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.HIDE_ALERT });
  };
}

export function getProducts(
  store_uuid,
  params = false,
  is_csv = false,
  filename = ""
) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.GET_PRODUCTS });
      let response = await getItems(store_uuid, params, is_csv, filename);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
        payload: response.data,
        is_csv
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again"
          })
        );
      }
    }
  };
}

export function addProduct(value, productVariants, storeInfo) {
  let uuid = localStorage.getItem("STORE_UUID");
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await addItem(value, uuid);
      let products = await getItems(uuid);
      if (response && productVariants) {
        let uuid = response ? response.data.uuid : "";
        response = await patchVariantService(productVariants, uuid);
        dispatch({
          type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
          payload: response.data
        });
      }
      if (storeInfo.is_published === false) {
        let response = await publish(storeInfo);
        dispatch({
          type: websiteBuilderActionTypes.GET_STORE_INFO_WEB,
          payload: response.data
        });
        dispatch({
          type: websiteBuilderActionTypes.PUBLISH_STORE,
          payload: products.data
        });
      } else {
        dispatch({
          type: websiteBuilderActionTypes.GET_PRODUCTS,
          payload: products.data
        });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log("error");
        // dispatch(
        //   showAlert({ type: "error", message: error ? error.data : "" })
        // );
      }
    }
  };
}

export function addProductv2(store, data, params = "") {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await addItem(data, store.uuid);
      let products = await getItems(store.uuid, params);
      if (store.is_published === false) {
        let response = await publish(store);
        dispatch({
          type: websiteBuilderActionTypes.GET_STORE_INFO_WEB,
          payload: response.data
        });
        dispatch({
          type: websiteBuilderActionTypes.PUBLISH_STORE,
          payload: products.data
        });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        dispatch(signOff());
      } else {
        console.log("error");
        dispatch(
          showAlert({
            type: "error",
            message: "Something went wrong. Please try again."
          })
        );
      }
    }
  };
}

export function editProductv2(store, data, params = "") {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await editItem(data, data.img, store.uuid);
      let products = await getItems(store.uuid, params);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
        payload: products.data
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        history.push(Path.LOGIN);
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "Something went wrong. Please try again."
          })
        );
      }
    }
  };
}

export function patchVariantGroups(product_uuid, data) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      const response = await patchVariantService(data, product_uuid);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
        payload: response.data
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        dispatch(signOff());
      } else {
        console.log("error");
        dispatch(
          showAlert({
            type: "error",
            message: "Something went wrong. Please try again."
          })
        );
      }
    }
  };
}

export function closeDialog() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.CLOSE_DIALOG });
  };
}

export function getProduct(product_uuid) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await getProductService(product_uuid);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch(
        showAlert({
          type: "error",
          message: "Something went wrong. Please try again."
        })
      );
      console.log("error", error);
    }
  };
}

export function editProduct(value, productVariants, imageList, params) {
  let uuid = localStorage.getItem("STORE_UUID");
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await editItem(value, imageList, uuid);
      let products = await getItems(uuid, params);
      if (response && productVariants) {
        let uuid = response ? response.data.uuid : "";
        response = await patchVariantService(productVariants, uuid);
        dispatch({
          type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
          payload: response.data
        });
      }
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
        payload: products.data
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        history.push(Path.LOGIN);
      } else {
        console.log("error", error);
      }
    }
  };
}

export function removeProduct(value, params = "") {
  let uuid = localStorage.getItem("STORE_UUID");
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await removeItem(value);
      let products = await getItems(uuid, params);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCTS,
        payload: products.data
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        history.push(Path.LOGIN);
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    }
  };
}

export function getStore() {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function getStoreInfo(params = false) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: response
      });

      let data = response ? response[0] : "";
      if (response.length > 0) {
        let storeDetails = await getStoreDetails(data);
        dispatch({
          type: websiteBuilderActionTypes.GET_STORE_INFO_WEB,
          payload: storeDetails.data
        });
        let uuid = storeDetails.data.uuid;
        // let products = await getItems(uuid, params);
        // dispatch({
        //   type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
        //   payload: products.data
        // });

        // dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    }
  };
}

export function getBuxStatus(value) {
  return async dispatch => {
    try {
      let response = await buxStatus(value);
      let access = response ? response.data : "";
      dispatch({
        type: websiteBuilderActionTypes.GET_BUX_STATUS,
        payload: access.kyc_level
      });
      return access;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function patchStore(form, site, values, image_settings) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      const response = await servicePatchStore(site, values, image_settings);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (response.status === 200) {
        dispatch({
          type: websiteBuilderActionTypes.EDIT_STORE,
          payload: response.data
        });
        // dispatch(
        //   showAlert({
        //     type: "success",
        //     message: "You have successfully updated your template"
        //   })
        // );
      }

      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch(
        showAlert({
          type: "error",
          message: "An error has occured. Please try again"
        })
      );
      if (error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: {
            error: error ? error.data : ""
          }
        });
      }
    }
  };
}

export function getStoreProductCategories(store_uuid) {
  return async dispatch => {
    try {
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES
      });
      let response = await getStoreProductCategoriesService(store_uuid);
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES_SUCCESS,
        payload: response.data
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES_FAILED
      });
    }
  };
}

export function deleteStore(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await deleteStoreServices(value);
      let getStore = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: getStore
      });
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_INFO_WEB,
        payload: ""
      });
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCTS,
        payload: []
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function skipSurvey() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
    let existing = sessionStorage.getItem("CURRENT_USER");
    existing = existing ? JSON.parse(existing) : {};
    existing["survey_answered"] = true;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
    dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
  };
}

export function saveKYCSurvey(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await saveSurvey(value);
      dispatch({
        type: websiteBuilderActionTypes.KYC_SUCCESS,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function agreedTNC(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await tncAgreed(value);
      dispatch({
        type: websiteBuilderActionTypes.AGREED_TNC,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function resetVariantGroups() {
  return async dispatch => {
    dispatch({
      type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
      payload: defaultVariants
    });
  };
}

export function getVariants(uuid) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await getVariantsService(uuid);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        dispatch(signOff());
      } else if (error && error.status === 400) {
        return error;
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function enableVariant(variant, uuid) {
  return async dispatch => {
    try {
      await enableVariantService(variant);
      let response = await getVariantsService(uuid);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
        payload: response
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        dispatch(signOff());
      } else if (error && error.status === 400) {
        return error;
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}
export function addVariantGroupAction(variant, uuid) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await patchVariantService(variant, uuid);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
        payload: response.data
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        dispatch(signOff());
      } else if (error && error.status === 400) {
        return error;
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}
export function getVouchers(params = false) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.GET_STORE_REQUEST });
      let response = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: response
      });
      if (response.length) {
        let vouchers = await getVoucherService(response, params);
        dispatch({
          type: websiteBuilderActionTypes.GET_VOUCHERS,
          payload: vouchers
        });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}
// export function publishName(value) {
//   return async dispatch => {
//     try {
//       dispatch({ type: websiteBuilderActionTypes.BUILDER_REQUEST });
//       await publishStore(value);
//       dispatch({ type: websiteBuilderActionTypes.PUBLISH_STORE });
//       history.push(Path.PUBLISHED);
//     } catch (error) {
//       dispatch({
//         type: websiteBuilderActionTypes.BUILDER_FAILED,
//         payload: error.message
//       });
//     }
//   };
// }

export function getProductSelections(uuid) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await getProductSelectionsService(uuid);
      console.log("data", response.data);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCT_SELECTIONS,
        payload: response.data
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        dispatch(signOff());
      } else if (error && error.status === 400) {
        return error;
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again."
          })
        );
      }
    }
  };
}
export function setProductVariantCombinations(payload) {
  return async dispatch => {
    dispatch({
      type: websiteBuilderActionTypes.SET_VARIANT_COMBINATIONS,
      payload
    });
  };
}
export function saveProductSelections(data, instanceToDelete) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      await saveProductSelectionsService(data);
      await deleteProductSelectionsService(instanceToDelete);
      dispatch({ type: websiteBuilderActionTypes.RESET_PRODUCT_SELECTIONS });
      dispatch(reset("variant_choices"));
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        dispatch(signOff());
      } else if (error && error.status === 400) {
        return error;
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}
export function resetProductSelections(data, instanceToDelete) {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.RESET_PRODUCT_SELECTIONS });
  };
}

export function setProductFilters(payload) {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.SET_PRODUCT_FILTERS, payload });
  };
}

export function setProductSelected(payload) {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.SET_PRODUCT_SELECTED, payload });
  };
}

export function bulkActions(products, action, store_uuid, params, category) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.GET_PRODUCTS });
      await bulkActionsServices(products, action, category);
      dispatch(
        showAlert({
          open: true,
          type: "success",
          message: "Your products has been successfully updated"
        })
      );
      dispatch(getProducts(store_uuid, params));
    } catch (error) {
      dispatch(
        showAlert({
          open: true,
          type: "error",
          message: "An error occured. Please try again."
        })
      );
    }
  };
}

export function getProductBatchUpload(store_uuid, params) {
  return async dispatch => {
    try {
      dispatch({
        type: websiteBuilderActionTypes.BATCH_UPLOAD_SET_LOADING,
        payload: true
      });
      const res = await getProductBatchUploadService(store_uuid, params);
      console.log("res.data.results", res.data.results);
      dispatch({
        type: websiteBuilderActionTypes.BATCH_UPLOAD_GET,
        payload: res.data.results
      });
      dispatch({
        type: websiteBuilderActionTypes.BATCH_UPLOAD_SET_LOADING,
        payload: false
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: websiteBuilderActionTypes.BATCH_UPLOAD_SET_LOADING,
        payload: false
      });
      dispatch(
        showAlert({
          open: true,
          type: "error",
          message: "An error occured. Please try again."
        })
      );
    }
  };
}

export function postProductBatchUpload(store_uuid, file) {
  return async dispatch => {
    try {
      dispatch({
        type: websiteBuilderActionTypes.BATCH_UPLOAD_SET_LOADING,
        payload: true
      });
      const res = await postProductBatchUploadService(store_uuid, file);
      dispatch({
        type: websiteBuilderActionTypes.BATCH_UPLOAD_POST,
        payload: res.data
      });
      if (res.data.status === "failed") {
        throw {
          response: {
            data: {
              file: ["No valid entries found"]
            }
          }
        };
      } else {
        dispatch(
          showAlert({
            open: true,
            type: "success",
            message: "Your products has been successfully updated"
          })
        );
        dispatch({
          type: websiteBuilderActionTypes.BATCH_UPLOAD_SET_LOADING,
          payload: false
        });
      }
      return res.data;
    } catch (error) {
      dispatch({
        type: websiteBuilderActionTypes.BATCH_UPLOAD_SET_LOADING,
        payload: false
      });
      // dispatch(
      //   showAlert({
      //     open: true,
      //     type: "error",
      //     message: "An error occured. Please try again."
      //   })
      // );
      throw error;
    }
  };
}

export function deleteProductCategory(store_uuid, category) {
  return async dispatch => {
    try {
      await deleteProductCategoryService(store_uuid, category);
      dispatch({
        type: websiteBuilderActionTypes.DELETE_STORE_PRODUCT_CATEGORY,
        payload: category
      });
    } catch (error) {}
  };
}

// REDUX FORMS -----------------------
export const ReduxInitializeFormActionTypes = {
  LOAD_PRODUCT_SELECTIONS_DATA: "LOAD_PRODUCT_SELECTIONS_DATA",
  LOAD_CUSTOMIZE_SHOP_FORM_DATA: "LOAD_CUSTOMIZE_SHOP_FORM_DATA",
  LOAD_PRODUCT_FORM_DATA: "LOAD_PRODUCT_FORM_DATA",
  LOAD_QUICK_ORDER_SETTINGS_DATA: "LOAD_QUICK_ORDER_SETTINGS_DATA"
};
export class ReduxInitializeFormActions {
  static initializeProductSelectionsData(data) {
    return {
      type: ReduxInitializeFormActionTypes.LOAD_PRODUCT_SELECTIONS_DATA,
      payload: data
    };
  }
  static initializeCustomizeShopData(data) {
    return {
      type: ReduxInitializeFormActionTypes.LOAD_CUSTOMIZE_SHOP_FORM_DATA,
      payload: data
    };
  }
  static initializeProductFormData(data) {
    return {
      type: ReduxInitializeFormActionTypes.LOAD_PRODUCT_FORM_DATA,
      payload: data
    };
  }
  static initializeQuickOrderSettingsData(data) {
    return {
      type: ReduxInitializeFormActionTypes.LOAD_QUICK_ORDER_SETTINGS_DATA,
      payload: data
    };
  }
}
// REDUX FORMS -----------------------
