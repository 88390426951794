import React from "react";
import Truncate from 'react-truncate';
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import Proxtera from '../../../../assets/images/proxtera/proxtera.png'
import PhilippinesFlag from '../../../../assets/images/proxtera/flags/philippines.png'
import getFlag from '../../utils'


const ProductCard = (props) => {
  const [flag, setFlag] = React.useState("");
  React.useEffect(() => {
    const flag = getFlag(props.data.territory);
    if (flag) {
      setFlag(flag.file_url)
    }
  }, [])
  const classes = makeStyles(theme => ({
    paper: {
      // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
      transition: "all 0.1s linear",
      transform: "translateY(0) scale(1)",
      margin: "0 5px",
      "&:hover": {
        // boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
        transform: "translateY(-1px) scale(1.01)",
      },
      height: "100%",
      display: "flex",
      flexDirection: "column",
      cursor: "pointer"
    },
    banner: {
      width: "100%",
      height: 195
    },
    avatar: {
      height: 32,
      width: 32,
      borderRadius: '50%',
      backgroundSize: "cover",
      backgroundPosition: "left",
      boxShadow: "0px 1px 5px 0 rgba(0,0,0,0.05)"
    },
    name: {
      fontSize: 16,
      fontWeight: "bold"
    },
    price: {
      fontSize: 14,
      fontWeight: 700,
      color: "#F97663"
    },
    store: {
      fontSize: 14,
      color: "#909196"
    }

  }))()
  return (
    <Paper className={classes.paper}>
        <div className={classes.banner}  style={{
          backgroundImage: `url(${props.data.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}/>
        <div className="p-3 d-flex flex-column flex-grow-1">
          <Truncate lines={1} ellipsis="...">
            <p className={`mb-0 ${classes.name}`}>{props.data.name}</p>
          </Truncate>
          <p className={`mb-2 ${classes.price}`}>USD {props.data.unit_price}</p>
          <div className="d-flex align-items-center mt-auto">
            {
              props.data.part_id &&
              <img src={Proxtera} style={{height: 20}} alt=""/>
            }
            {/* <p className={`flex-grow-1 mb-0 ${classes.store}`}>{props.data.store || "StoreName"}</p> */}
            {
              flag &&
              <div className={`${classes.avatar} img-fluid ml-auto`} style={{
                backgroundImage: `url(${flag})`,
              }} alt=""/>
            }
          </div>
        </div>
    </Paper>
  )
}

export default ProductCard;
