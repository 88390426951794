import axios from "axios";
import apiConfig from "../../common/apiConfig";
const CURRENT_USER = "CURRENT_USER";
async function signUp(value) {
  let params = {
    email: value.email,
    password: value.password,
    first_name: value.firstname,
    last_name: value.lastname,
    utm_source: value.utm_source,
    utm_medium: value.utm_medium,
    utm_campaign: value.utm_campaign,
    utm_content: value.utm_content,
    mobile_number: value.phoneNumber
  };

  try {
    let response = await axios.post(`${apiConfig.apiURL}/api/signup/`, params);

    let campaignMapping = {
      "/sentro_sa_pagasenso_cosmetics": {
        event: "signupsuccesscosmetics",
        category: "Create an account: (Cosmetics)",
        action: "Sentro sa Pag Asenso Sign Up (Cosmetics)"
      },
      "/sentro_sa_pagasenso_pastry": {
        event: "signupsuccesspastry",
        category: "Create an account: (Pastry)",
        action: "Sentro sa Pag Asenso Sign Up (Pastry)"
      },
      "/sentro_sa_pagasenso_generic": {
        event: "signupsuccessgeneric",
        category: "Create an account (Generic)",
        action: "Sentro sa Pag Asenso Sign Up (Generic)"
      }
    };
    let campaign = campaignMapping[window.location.pathname];

    // GTM Trigger
    if (campaign) {
      window.dataLayer.push(
        {
          eventAction: `FB Pixel: ${campaign.action}`,
          eventCategory: `FB Pixel: ${campaign.category}`,
          userId: response.data.id,
          event: campaign.event
        },
        {
          eventAction: campaign.action,
          eventCategory: campaign.category,
          userId: response.data.id,
          event: "signupsuccess"
        }
      );
    } else {
      window.dataLayer.push({
        eventAction: "Successful sign up",
        eventCategory: "Create an Account",
        userId: response.data.id,
        event: "signupsuccess"
      });
    }
    return response;
  } catch (e) {
    throw e.response;
  }
}

async function verify(value) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/verification?email=${value.email}&code=${value.code}`
    );

    // GTM trigger
    window.dataLayer.push({
      eventAction: "Successful email verification",
      eventCategory: "Verify an email",
      event: "emailverified"
    });

    return response;
  } catch (error) {
    throw error.response;
    // if (error.message == errorMessage.networkError) {
    //   throw new Error(errorMessage.networkErrorMessage);
    // } else {
    //   throw new Error(errorMessage.errorVerification);
    // }
  }
}

async function resendVCode(value) {
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/resend-verification/`,
      { email: value }
      //   {
      //     headers: {
      //       Authorization: `JWT ${localStorage.getItem("TOKEN")}`
      //     }
      //   }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function fbSignUp(value) {
  let params = {
    provider: "facebook",
    access_token: value.accessToken
  };

  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/oauth/login/`,
      params
    );
    let data = response.data;

    let campaignMapping = {
      "/sentro_sa_pagasenso_cosmetics": {
        event: "signupsuccesscosmetics",
        category: "Sentro sa Pag Asenso Sign Up (Cosmetics): Create an account",
        action: "FB Sign up: Sentro sa Pag Asenso Sign Up (Cosmetics)"
      },
      "/sentro_sa_pagasenso_pastry": {
        event: "signupsuccesspastry",
        category: "Sentro sa Pag Asenso Sign Up (Pastry): Create an account",
        action: "FB Sign up: Sentro sa Pag Asenso Sign Up (Pastry)"
      },
      "/sentro_sa_pagasenso_generic": {
        event: "signupsuccessgeneric",
        category: "Sentro sa Pag Asenso Sign Up (Generic): Create an account",
        action: "FB Sign up: Sentro sa Pag Asenso Sign Up (Generic)"
      }
    };

    let campaign = campaignMapping[window.location.pathname];

    // GTM trigger
    if (campaign) {
      window.dataLayer.push(
        {
          eventAction: `FB Pixel: ${campaign.action}`,
          eventCategory: `FB Pixel: ${campaign.category}`,
          event: campaign.event
        },
        {
          eventAction: `FB Sign Up: ${campaign.action}`,
          eventCategory: `FB Sign Up: ${campaign.category}`,
          event: "signupsuccess"
        },
        {
          eventAction: "FB: Pixel: Successful facebook email verification",
          eventCategory: "FB Pixel: Verify an email",
          event: "emailverified"
        }
      );
    } else {
      window.dataLayer.push(
        {
          eventAction: "FB Sign Up: Successful sign up",
          eventCategory: "FB Sign Up: Create an Account",
          userId: response.data.id,
          event: "signupsuccess"
        },
        {
          eventAction: "Successful facebook email verification",
          eventCategory: "Verify an email",
          event: "emailverified"
        }
      );
    }

    sessionStorage.setItem("TOKEN", data.token);
    sessionStorage.setItem(CURRENT_USER, JSON.stringify(data.user));

    let user = sessionStorage.getItem("CURRENT_USER");
    user = user ? JSON.parse(user) : {};

    user["is_facebookLogin"] = true;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(user));
    return data;
  } catch (error) {
    throw error.response;
  }
}

async function uniqueEmail(value) {
  let response = await axios.get(`${apiConfig.apiURL}/api/unique-email`, {
    params: {
      email: value
    }
  });
  if (response.data !== "") {
    throw { email: "Email is already registered" };
  } else {
    return { email: "" };
  }
}

async function saveUTM(value) {
  // https://sentro.ph/sign_up?&utm_source=facebook&utm_medium=cpc&utm_campaign=sentroofattentionads&utm_content=sell_online
  let params = {
    utm_source: value.utm_source,
    utm_medium: value.utm_medium,
    utm_campaign: value.utm_campaign,
    utm_content: value.utm_content
  };
  let response = await axios.post(
    `${apiConfig.apiURL}/api/unique-email`,
    params
  );
  return response;
}

export { signUp, verify, fbSignUp, uniqueEmail, resendVCode, saveUTM };
