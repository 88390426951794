import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { Field } from "redux-form";
import LargeImageField from "../../../../../reduxFields/largeImageField";

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 600,
    fontSize: 18
  },
  label2: {
    color: "#54575F",
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 0
  },
  smallText: {
    fontWeight: "normal",
    fontSize: 12,
    color: "rgba(43, 45, 50, 0.8)"
  },
  colorPreview: {
    padding: "1em 3em",
    borderRadius: 4
  }
}));

const BrandingPane = props => {
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const data = useSelector(state => state.form.customizeShopForm?.values);

  const hide_footer_section =
    props.store &&
    (props.store.template === "ClassicV2" ||
      props.store.template === "ContemporaryV2" ||
      props.store.template === "SariSari" ||
      props.store.template === "Leftist" ||
      props.store.template === "Pastel");

  return (
    <div className="bg-white px-3 px-md-5 pt-5 pb-3">
      <p className="mb-4">For best results, upload photos using your laptop.</p>
      <div className="mb-5">
        <Field
          fullWidth
          id="cover_image"
          name="cover_image"
          label="Cover Photo"
          component={LargeImageField}
          //   useStockPhoto
          useCropper
          defaultCropperRatio="landscape"
          height={280}
        />
      </div>
      <div className="mb-5">
        <Field
          fullWidth
          id="aboutus_image"
          name="aboutus_image"
          label="Section photo - About"
          component={LargeImageField}
          //   useStockPhoto
          useCropper
          defaultCropperRatio="landscape"
          height={280}
        />
      </div>
      {hide_footer_section ? (
        ""
      ) : (
        <div className="mb-5">
          <Field
            fullWidth
            id="contactus_image"
            name="contactus_image"
            label="Section photo - Footer"
            component={LargeImageField}
            //   useStockPhoto
            useCropper
            defaultCropperOrientation="landscape"
            height={280}
          />
        </div>
      )}
    </div>
  );
};

export default BrandingPane;
