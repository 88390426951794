import axios from "axios";
import apiConfig from "../../../../common/apiConfig";
import history from "../../../../store/history";

const sortByMaps = [
  {
    name: "Alphabetical",
    value: "name"
  },
  {
    name: "Date added",
    value: "created_on"
  },
  //   {
  //     name: "Popularity",
  //     value: "created_on"
  //   },
  {
    name: "Price",
    value: "price"
  }
];

const getSortVal = name => {
  const filtered = sortByMaps.filter(item => item.name === name);
  return filtered[0].value;
};

class Services {
  static async getProducts(enterprise, filters) {
    const category = filters.category.length
      ? { category: filters.category.join(",") }
      : {};
    const subcategories =
      filters.subcategory.length && !filters.subcategory.includes("All")
        ? {
            subcategories: filters.subcategory.join(",")
          }
        : {};
    const location = filters.location.length
      ? {
          location: filters.location
            .map(item => item.toLowerCase().replace(" ", "_"))
            .join(",")
        }
      : {};
    const delivers_to = filters.deliversTo.length
      ? {
          delivers_to: filters.deliversTo
            .map(item => item.toLowerCase().replace(" ", "_"))
            .join(",")
        }
      : {};
    delete filters.category;
    delete filters.subcategory;
    delete filters.location;
    delete filters.deliversTo;
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/products/`,
        {
          params: {
            ...filters,
            sortBy: getSortVal(filters.sortBy),
            ...category,
            // ...subcategories,
            ...location,
            ...delivers_to
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getMerchantProducts(enterprise, filters) {
    const subcategories =
      filters.subcategory.length && !filters.subcategory.includes("All")
        ? {
            subcategories: filters.subcategory.join(",")
          }
        : {};
    const location = filters.location.length
      ? {
          location: filters.location
            .map(item => item.toLowerCase().replace(" ", "_"))
            .join(",")
        }
      : {};
    const delivers_to = filters.deliversTo.length
      ? {
          delivers_to: filters.deliversTo
            .map(item => item.toLowerCase().replace(" ", "_"))
            .join(",")
        }
      : {};
    delete filters.subcategory;
    delete filters.location;
    delete filters.deliversTo;
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/products/?merchant=${filters.uuid}`,
        {
          params: {
            ...filters,
            sortBy: getSortVal(filters.sortBy),
            ...subcategories,
            ...location,
            ...delivers_to
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async signUp(params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/signup/`,
        params
      );
      return response;
    } catch (error) {
      throw error.message;
    }
  }

  static async login(params) {
    try {
      let response = await axios.post(`${apiConfig.apiURL}/api/login/`, params);
      let data = response.data;
      if (data.token != null && data.token != "") {
        sessionStorage.setItem("TOKEN", data.token);
        sessionStorage.setItem("CURRENT_USER", JSON.stringify(data.user));
      }
      return response;
    } catch (error) {
      throw error.message;
    }
  }

  static async getSelectedProduct(site, product) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${site}/products/${product}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async patchBuyerCart(site, items) {
    try {
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/enterprise/${site}/cart/`,
        {
          items
        },
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getBuyerCart(site) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${site}/cart`,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getMyCart(site) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${site}/cart/`,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async viewOrder(site, type, params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${site}/cart/${type}/`,
        {
          params: { registry_ref_code: params },
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async checkout(values, paymentType, enterprise) {
    let params = {
      ...values,
      payment_option: paymentType["paymentOption"],
      channel:
        paymentType["paymentOption"] === "otc" ? null : paymentType["account"],
      is_read: false
    };

    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/order/`,
        params,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async buxCheckout(value) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/order/check_code/?ref_id=${value.reference_id}`
      );
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async getPayments(uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/payment_method/store/${uuid}`
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getVouchers(uuid, params) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/promo/voucher/`, {
        params: { store: uuid, ...params }
      });
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async getDeliveryLocations(uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/delivery-locations/?store_uuid=${uuid}`
      );
      return response;
    } catch (e) {
      throw e.response;
    }
  }

  static async computeShipping(params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/mrspeedy/calculate_order/`,
        params
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }
}
export default Services;
