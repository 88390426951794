import React from "react";
import {
  Dialog,
  Zoom,
  Button,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { ErrorOutlined, Error } from "@material-ui/icons";
import { useSelector } from "react-redux";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";
const Transition = props => <Zoom {...props} />;
export default function MaxProductsModal(props) {
  const subscription = useSelector(state => state.subscription.subscription);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function handleBack() {
    if (props.goToProductsPage) {
      history.push(Path.PRODUCTS);
    } else {
      props.setOpen(false);
    }
  }
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="xs"
      TransitionComponent={Transition}
    >
      <div className="py-4 px-md-5 px-3 d-flex align-items-center justify-content-center text-center">
        <div>
          <Error style={{ color: "#cb3631", fontSize: 90 }} />
          <div className="py-3" style={{ fontSize: 18, fontWeight: 600 }}>
            You have reached the maximum of adding a product
          </div>

          {(subscription.subscription_type === "advanced" ||
            subscription.subscription_type === "free_trial") &&
          props.product_length >= 300 ? (
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              className="mt-3"
              onClick={handleBack}
            >
              Ok
            </Button>
          ) : (
            <div className="d-flex mt-3 mb-3">
              <Button
                variant="outlined"
                color="primary"
                className="w-50 mr-2"
                style={{ height: 40, fontSize: 12 }}
                onClick={handleBack}
              >
                No, take me back
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                className="w-50"
                style={{ height: 40, fontSize: isMobile ? 10 : 12 }}
                onClick={() => history.push(Path.SENTRO_ADVANCED)}
              >
                {!subscription.subscription_type &&
                !subscription.free_trial_availed
                  ? "Start free trial"
                  : "Sign up for advanced"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
