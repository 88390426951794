import React from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  Typography, IconButton, Button
} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
// import { ReactComponent as PenIcon } from '../../../../../../assets/images/enterprise/pen.svg';
// import { ReactComponent as LinkIcon } from '../../../../../../assets/images/enterprise/link.svg';
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import validations from '../../../../../common/validations';
// import LargeImageUploader from '../largeImageUpload';
// import DashboardActions from '../../../../redux/actions/dashboardActions';
import { useDispatch, useSelector } from 'react-redux';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeliveryIcon from '../../../../assets/images/delivery.svg';

import LocationPickerModal from '../locationPickerModal';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as PenIcon } from '../../../../assets/images/enterprise/pen.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/images/enterprise/trash.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Actions from '../../redux/actions';

import Truncate from 'react-truncate';

const useStyles = makeStyles(theme => ({
  label2: {
    fontSize: 14,
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)"
  },
  item: {
    padding: "1em",
    background: "#FFFFFF",
    border: "1px solid #EEEEEE",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "start"
    }
  },
  textSecondary: {
    color: "rgba(43, 45, 50, 0.8)",
    fontSize: 14,
    marginBottom: 0
  },
  addLocBtn: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left"
    }
  },
  infoAlert: {
    background: "#E5F2FD",
    border: "1px solid #3A73CC",
    color: "#25459B",
    borderRadius: 4,
    padding: "0.5em 1em",
    fontSize: 14
  }
}));

const Location = props => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const stores = useSelector(state => state.dashboard.stores);
  const delivery_locations = useSelector(state => state.deliverySettings.delivery_locations);

  React.useEffect(() => {
    if (stores.length) {
      dispatch(Actions.getDeliveryLocations(stores[0].uuid))
    }
  }, [stores])

  // switches
  const [selectedItem, setSelectedItem] = React.useState(false);
  const editOnClick = (item) => {
    setSelectedItem({
      ...item,
      mode: "edit"
    });
  }
  const deleteOnClick = (item) => {
    dispatch(Actions.deleteDeliveryLocation(item))
  }
  const onClickAdd = () => {
    setSelectedItem({ mode: "add" });
  }
  const datas = [
    {
      province: "Metro Manila", 
      cities: ["Caloocan City", "Makati City", "Makati City", 
       "Makati City", "Makati City", "Makati City", 
       "Makati City", "Makati City", "Makati City"], 
      rate: 120.00,
      delivery_info: "Monday to Friday only"
    }
  ]


  return (
    <>
      <LocationPickerModal open={Boolean(selectedItem)} setOpen={setSelectedItem}
        data={selectedItem}/>
      <div className={`p-3 p-md-5`}>
        {
          delivery_locations.length ? 
          <>

            <p className={classes.label2}>Choose where you 
              deliver and how much you charge for each during checkout.</p>
            <div className={`my-4 ${classes.infoAlert}`}>
              <div className="d-flex align-items-start">
                <InfoOutlinedIcon />
                <p className="mb-0 ml-2"> To get better sales, 
                  add delivery locations for <strong>Luzon</strong>, 
                  <strong>Visayas</strong>, and <strong>Mindanao</strong> regions.</p>
              </div>
            </div>
            <p className={`${classes.label2} d-none d-md-block`}><strong>Delivery options</strong></p>
          </>
          :""
        }
        {
          delivery_locations.length ?
          delivery_locations.map((item, index) =>(
              <div key={index} className={`${classes.item} d-flex mt-2`}>
                <div>
                  <p className="mb-0">
                    <Truncate lines={1} style={{fontWeight: 600}}>
                      {item.cities.join(", ") || item.province}{` `}
                    </Truncate>
                    <strong> (₱ {item.rate})</strong>
                  </p>
                  {
                    item.delivery_information ?
                      <p className={classes.textSecondary}>{item.delivery_information}</p> 
                    : ""
                  }
                </div>
                <IconButton className="ml-auto mr-3" size="small"
                  onClick={() => editOnClick(item)}>
                  <PenIcon fill="#9A9EA9" height="20" width="20" />
                </IconButton>
                <IconButton 
                  onClick={() => deleteOnClick(item)}  size="small">
                  <TrashIcon fill={"#9A9EA9"} height="20" width="20" />
                </IconButton>
              </div>
            ))
          :
          <div className="d-flex align-items-center justify-content-center flex-column mt-5">
            <img src={DeliveryIcon} alt="deliveryicon"/>
            <strong className="mt-3">Set your delivery locations</strong>
            <p className="text-secondary w-100 w-md-50 mt-2 text-center" style={{ fontSize: 14 }}>
            Choose where you deliver and how much you charge for each location during checkout.
            </p>
            <div className={` mt-2 ${classes.addLocBtn}`}>
              <Button color="primary" variant="contained" style={{
                height: 38
              }} onClick={() => onClickAdd()} disableElevation>
                <AddIcon /> Add delivery location
              </Button>
            </div>
          </div>
        }
        {
          delivery_locations.length ? 
          <div className={` mt-3 ${classes.addLocBtn}`}>
            <Button color="primary" variant="outlined" style={{
              height: 38
            }} onClick={() => onClickAdd()}>
              <AddIcon /> Add location
            </Button>
          </div>
          : ""
        }
      </div>
    </>
  );
};

export default Location;
