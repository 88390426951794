import React from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogContent
} from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';

export default function CancelDeliveryDialog(props){

    const useStyles = makeStyles(theme => ({
      modalBtn: {
        height: 50,
        flexGrow: 0,
        width: "100%",
        fontSize: 14,
        [theme.breakpoints.down("xs")]: {
          flexGrow: 1,
          fontSize: 12,
          height: 40
        }
      },
      errorIcon: {
        fontSize: 75,
        color: "#C24A39"
      }
    }));
  
    const classes = useStyles();
    const onCancel = () => {
      if (props.onCancel) props.onCancel()
    }
    const onContinue = () => {
      if (props.onContinue) props.onContinue()
    }
    return (
      <Dialog id="cancel-delivery-dialog" maxWidth="sm" open={props.open} >
        <DialogContent style={{ padding: 20, maxWidth: 460}}>
          <div className="p-2 p-md-3">
            <div className="text-center">
              <ErrorIcon className={classes.errorIcon}></ErrorIcon>
              <h2 style={{fontSize: 22, fontWeight: 600}} 
                  className="mb-2 mt-3">Are you sure you want
                  to cancel delivery?</h2>
              <p className="text-secondary mb-3">Are you sure you want to cancel your booked delivery?</p>
            </div>
            <div className={`d-flex align-items-center mt-3 mb-0 ${window.innerWidth < 600 ? 'flex-wrap': ''}`}>
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                id="resend-button"
                className={classes.modalBtn}
                onClick={() => onCancel()}
              > 
                No, take me back
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                id="done-button"
                className={`${classes.modalBtn} ml-0 ml-sm-3 mt-3 mt-sm-0`}
                onClick={() => onContinue()}
              >
                Yes, cancel it
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }


export const minutesPassed = (date) => {
  return (new Date() - Date.parse(date)) / 60000
}