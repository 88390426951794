import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Box,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PreviewHome from "../common/template/components/PreviewHome";
import PreviewProducts from "../common/template/components/PreviewProducts";
import PreviewProductsDetails from "../common/template/components/PreviewProductDetails";
import sentroLogo from "../../assets/images/sentro_logomark.png";
const DialogTransition = props => <Slide direction="up" {...props} />;

const PreviewSite = props => {
  const useStyles = makeStyles(theme => ({
    appBar: {
      position: "relative",
      backgroundColor: "#002C42"
    },
    dialog: {
      padding: "50px 50px 0",
      [theme.breakpoints.down("sm")]: {
        padding: "50px 0 0"
      }
    }
  }));

  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialog}
      fullScreen
      fullWidth={true}
      maxWidth="xl"
      open={props.open}
      TransitionComponent={DialogTransition}
      onClose={props.handleClosePreview}
    >
      <AppBar className={classes.appBar}>
        <Toolbar
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <img
            alt="sentro-logo"
            src={sentroLogo}
            style={{ width: 65, height: "auto" }}
          />

          <Typography style={{ color: "#fff", fontSize: 20 }}>
            Preview
          </Typography>
          <IconButton
            id="close_button"
            edge="end"
            style={{
              color: "#fff"
            }}
            onClick={props.handleClosePreview}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        style={{
          overflowY: "scroll",
          position: "relative"
          // transform: `scale(0.9)`,
          // transformOrigin: "top"
        }}
      >
        {/* <div
          style={{
            fontSize: 32,
            fontWeight: 600,
            textAlign: "center",
            margin: 30
          }}
        >
          Home Page
        </div> */}

        <div
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <PreviewHome
            storeInfo={props.storeInfo}
            productList={props.productList}
          />
        </div>
        <div
          style={{
            fontSize: 32,
            fontWeight: 600,
            textAlign: "center",
            margin: 30
          }}
        >
          Product Page
        </div>
        <div
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <PreviewProducts
            storeInfo={props.storeInfo}
            productList={props.productList}
          />
        </div>

        <div
          style={{
            fontSize: 32,
            fontWeight: 600,
            textAlign: "center",
            margin: 30
          }}
        >
          Product Details Page
        </div>
        <div
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <PreviewProductsDetails
            storeInfo={props.storeInfo}
            productList={props.productList}
          />
        </div>
      </Box>
    </Dialog>
  );
};

export default PreviewSite;
