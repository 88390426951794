import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Chip } from "@material-ui/core";
import { getDeliveryStatus } from "../../../../../orders/orderStatuses";

export default function OrderCard(props) {
  const { row } = props;
  const useStyles = makeStyles(theme => ({
    card: {
      boxShadow: "0px 1px 4px rgba(0,0,0,0.05)",
      borderRadius: 4,
      border: "1px solid #eee"
    },
    paymentStatus: {
      "& .paid": {
        backgroundColor: "#57b19f",
        fontSize: 12,
        color: "#fff"
      },
      "& .pending": {
        backgroundColor: "#FFC044",
        fontSize: 12,
        color: "#2b2d32"
      },
      "& .cancelled": {
        backgroundColor: "#54575F",
        fontSize: 12,
        color: "#fff"
      }
    }
  }));

  const classes = useStyles();

  const getCourier = item => {
    if (item.delivery_detail) {
      if (item.delivery_detail.method === "pick_up") {
        return "Own pickup";
      } else if (item.delivery_detail.method === "courier") {
        if (item.delivery_detail.courier === "mrspeedy") {
          return "Borzo";
        } else if (item.delivery_detail.courier === "ninjavan") {
          return "NinjaVan";
        }
      } else {
        return "--";
      }
    } else {
      return "--";
    }
  };

  return (
    <div
      onClick={() => props.handleViewOrder(row)}
      className={`${classes.card} bg-white p-3 mb-3`}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div style={{ color: "#f97663", fontSize: 16, fontWeight: 600 }}>
          {row.ref_id}
        </div>
        <div className={`d-flex align-items-center ${classes.paymentStatus}`}>
          <Chip
            size="small"
            label={row.is_cancelled ? "Cancelled" : row.payment_status}
            style={{ padding: "0 5px" }}
            className={
              row.is_cancelled
                ? "cancelled"
                : row.payment_status
                    .toLowerCase()
                    .split(" ")
                    .join("_")
            }
          />

          {getDeliveryStatus(row) === "--" ? (
            ""
          ) : (
            <Chip
              size="small"
              label={getDeliveryStatus(row)}
              style={{ padding: "0 5px" }}
              className={
                row.is_cancelled
                  ? "cancelled"
                  : row.payment_status
                      .toLowerCase()
                      .split(" ")
                      .join("_")
              }
            />
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between pt-4">
        <div className="d-flex">
          <div
            style={{ color: "#2b2d32", fontSize: 14, fontWeight: 500 }}
            className="mr-3"
          >
            ₱ {row.amount}
          </div>
          <div
            style={{
              color: "#54575f",
              fontSize: 14,
              textTransform: "capitalize"
            }}
          >
            {row.full_name}
          </div>
        </div>
        <div style={{ color: "#54575f", fontSize: 14, fontWeight: 500 }}>
          {getCourier(row)}
        </div>
      </div>
    </div>
  );
}
