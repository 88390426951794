import axios from "axios";
import apiConfig from "../../../common/apiConfig";

class MediaServices {
  static async getMediaImages(params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/media-library/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
  static async deleteMediaImages(ids) {
    let axiosRequests = [];
    ids.forEach(id => {
      // patch
      axiosRequests.push(
        axios.delete(
          `${apiConfig.apiURL}/api/media-library/${id}/`,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
            },
          }
        )
      );
    });
    axios.all(axiosRequests)
  }

  static async editMediaImageAttr(params) {
    try {
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/media-library/${params.id}/`, 
        {
          tags: params.tags
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
        }
      );
      if (params.image) {
        const data = new FormData();
        data.append("image", params.image);
        response = await axios.patch(
          `${apiConfig.apiURL}/api/media-library/${params.id}/`, 
          data,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
            },
          }
        );
      }
      return response;
    } catch (error) {
      throw error.message;
    }
  }
  
  static async uploadImage(file, progressCallback) {
    try {
      let data = new FormData();
      data.append("image", file);
      let response = await axios.post(
        `${apiConfig.apiURL}/api/media-library/`, 
        data,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          onUploadProgress: (progressEvent) => {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            progressCallback(Math.min(percentCompleted, 90));
          }
        }
      );
      return response;
    } catch (error) {
      throw error.message;
    }
  }
}
export default MediaServices;