import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../../layout"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from '../../../../store/history'
import { CartTable, SummaryCard } from '../../components/cart'
import { animateScroll as scroll } from "react-scroll";
import Actions from '../../Redux/Actions'

const useStyles = theme => ({
  title: {
    fontWeight: "bold",
    fontSize: 36,
    color: "#002C42"
  },
  paper: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
  },
  backHeader: {
    width: 120,
    display: "flex !important",
    alignItems: "center",
    padding: "0 0 2em",
    fontWeight: 600,
    fontSize: 14,
    color: "#F97663 !important"
  },
});

class Cart extends React.Component {

  componentDidMount = () => {
    scroll.scrollTo(0)
  }

  onDelete = (index) => {
    const { dispatch } = this.props;
    dispatch(Actions.REMOVE_FROM_CART({
      index
    }))
  }

  render (){
    const { classes, cart } = this.props;
    // const items = [
    //   {
    //     image: 'https://cdn.cichic.com/media/catalog/product/cache/1/image/5e06319eda06f020e43594a9c230972d/1/9/190104100971-1/white-patchwork-lace-pleated-v-neck-long-sleeve-ctue-boho-mini-dress.jpg',
    //     name: 'Premium Mt. Apo Arabica  Coffee Beans (1 kg.)',
    //     price: 7.50,
    //     unit: '1 kg',
    //     quantity: 1

    //   },
    //   {
    //     image: 'https://images.immediate.co.uk/production/volatile/sites/30/2020/08/health-benefits-of-almonds-hub-image-square-dcac827.jpg?quality=90&resize=504,458?quality=90&webp=true&resize=504,459',
    //     name: 'High quality Almonds',
    //     price: 7.50,
    //     unit: '1 kg',
    //     quantity: 1

    //   }
    // ];
    return (
      <Layout noSideBar>
        <a className={classes.backHeader} href="#" onClick={(e) => {
            e.preventDefault();
            history.goBack();
        }}>
          <ArrowBackIcon/>
          <span style={{marginLeft: "1em"}}>{`  BACK`}</span>
        </a>
        <h2 className={`mb-4 ${classes.title}`}>Shopping Cart</h2>
        <div className="row">
          <div className="col-12 col-md-8">
            <CartTable title="Orders" items={cart.items} onDelete={(cart_item_index) => this.onDelete(cart_item_index)}/>
          </div>
          <div className="col-12 col-md-4">
            <SummaryCard qty={cart.qty} subtotal={(parseInt(cart.total)/100).toFixed(2)} total={(parseInt(cart.total)/100).toFixed(2)} onClick={() => history.push("/proxtera/shipping")}/>
          </div>
        </div>
      </Layout>
    )
  }
}


const mapStateToProps = state => ({ proxtera }) => ({ 
    cart: proxtera.cart
});
export default connect(mapStateToProps)(withStyles(useStyles)(Cart));

