import React from "react";
import {
  Button,
  Dialog
} from "@material-ui/core";
const DeleteModal = props => {
  const [open, setOpen] = React.useState(false);

  const onConfirm = () => {
    props.onConfirm();
    setOpen(false);
  }
  return (
    <div>
      {
        props.children(setOpen)
      }
      <Dialog open={open}>
        <div style={{ padding: 24 }}>
          <div
            style={{
              fontSize: 28,
              fontWeight: 600,
              marginBottom: 20,
              color: "#002c42"
            }}
          >
            Delete Product
          </div>
          <div style={{ fontSize: 16, marginBottom: 20, color: "#54575f" }}>
            Are you sure you want to delete this product?
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              id="closeBtn"
              variant="text"
              color="secondary"
              style={{ padding: "0 30px" }}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
            <Button
              id="deleteBtn"
              variant="text"
              color="primary"
              style={{ padding: "0 30px" }}
              onClick={onConfirm}
            >
              Delete
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
