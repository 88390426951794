import React from "react";
import Alert from "@material-ui/lab/Alert";

export default function MaterialUIAlert(props) {
  return (
    <Alert
      severity={props.type}
      icon={props.icon}
      onClose={props.onClose}
      {...props}
    >
      {props.message}
    </Alert>
  );
}
