import { makeStyles } from "@material-ui/styles";
import shop1 from "../../../assets/images/marketing page assets/shop1.png";
import shop2 from "../../../assets/images/marketing page assets/90sbakers.gif";
import shop3 from "../../../assets/images/marketing page assets/shop3.png";
import shop4 from "../../../assets/images/marketing page assets/shop4.png";
import shop5 from "../../../assets/images/marketing page assets/shop5.png";
import shop6 from "../../../assets/images/marketing page assets/shop6.png";
import shop7 from "../../../assets/images/marketing page assets/shop7.png";
export const HomepageStyles = makeStyles(theme => ({
  section1: {
    backgroundColor: "#ffcfc8"
  },
  section1Header: {
    textAlign: "center",
    padding: "20px 4em",
    fontSize: 16,
    color: "#002c42",
    fontWeight: 600
  },
  section2: {
    backgroundColor: "#fff",
    padding: 20
  },
  section2Header: {
    padding: "0px 20px",
    color: "#002c42",
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center"
  },
  section2Subheader: {
    paddingTop: 10,
    fontSize: 14,
    color: "#54575f",
    textAlign: "center"
  },
  section3: {
    padding: "20px 20px 0"
  },
  featureImg: {
    width: "85%",
    height: "auto",
    display: "flex",
    margin: "auto"
  },
  logos: {
    width: "100%",
    height: "auto",
    display: "flex",
    margin: "auto",
    paddingTop: "3vh"
  },
  paper: {
    margin: 5,
    padding: 20,
    backgroundColor: "#fff",
    borderRadius: 8,
    boxShadow: "0px 4px 4px rgba(0,0,0,0.25)"
  },
  owner: {
    fontSize: 12,
    color: "#54575f",
    display: "flex",
    justifyContent: "flex-end"
  },
  shop: {
    fontSize: 14,
    color: "#f97663",
    fontWeight: 600,
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "2vh"
  },
  getStarted: {
    height: 40,
    marginTop: "2vh",
    textTransform: "capitalize"
  }
  // carousel: {
  //   filter: "drop-shadow(0px 4.7907px 4.7907px rgba(0,0,0,0.25))"
  // }
}));

export const section1Images = [
  { original: shop1 },
  { original: shop2 },
  { original: shop3 },
  { original: shop4 },
  { original: shop5 },
  { original: shop6 },
  { original: shop7 }
];

export default { HomepageStyles, section1Images };
