import { actionTypes } from "./AccountSettingsAction";

const initialState = {
  loading: false,
  error: "",
  profile: "",
  changePasswordDetails: "",
  showAlert: false,
  message: "",
  type: ""
};

export default function accountSettingsReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING_ON:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADING_OFF:
      return {
        ...state,
        loading: false
      };
    case actionTypes.GET_PROFILE:
      return {
        ...state,
        loading: false,
        profile: action.payload
      };
    case actionTypes.SAVE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        changePasswordDetails: action.payload,
        showAlert: true,
        message: "Your password is successfully updated",
        type: "success"
      };
    case actionTypes.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        showAlert: true,
        message: "Invalid Current Password. Please try again.",
        type: "error"
      };
    case actionTypes.OPEN_ALERT:
      return {
        ...state,
        showAlert: true,
        message: "Your details are successfully updated.",
        type: "success"
      };
    case actionTypes.CLOSE_ALERT:
      return {
        ...state,
        showAlert: false
      };
    case actionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        profile: action.payload
      };
    default:
      return initialState;
  }
}
