import React from "react";
import { useSelector, useDispatch } from "react-redux";
import icon from "../../../../../../assets/images/orderIcon.svg";
import OrderCard from "./orderCard";
import {
  getOrderDetails,
  getOrders,
  setOrderFilters
} from "../../webBuilder/websiteBuilderActions";
import MobileOrderDetails from "../../webBuilder/forms/MobileOrderDetails";
import { useHistory } from "react-router-dom";
export default function OrderList(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const orders = useSelector(state => state.mobileDashboard.orders.orders);
  const loading = useSelector(state => state.mobileDashboard.orders.loading);
  const filters = useSelector(state => state.mobileDashboard.orders.filters);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const handleViewOrder = order => {
    history.push(`/orders/details?id=${order.id}`);
  };
  const pages = parseInt(orders.length / 10);
  const scrollListener = React.useCallback(
    e => {
      const maxScroll = e.target.scrollHeight - e.target.offsetHeight;
      const currentScroll = e.target.scrollTop;

      if (maxScroll === currentScroll) {
        if (page < pages) {
          dispatch(getOrders({ ...filters, page: page + 1 }, "append"));
          setPage(page + 1);
        }
      }
    },
    [page, pages]
  );

  React.useEffect(() => {
    setPage(0);
  }, [filters]);
  return (
    <div>
      {orders && orders.list.length ? (
        <div
          style={{ height: "62vh", overflowY: "scroll" }}
          onScroll={scrollListener}
        >
          {orders.list
            .filter(i => i.ref_id)
            .map((row, index) => (
              <OrderCard row={row} handleViewOrder={handleViewOrder} />
            ))}
          {loading ? (
            <div className="text-center my-3">
              <div
                class="spinner-border text-secondary m-auto"
                role="status"
                style={{
                  height: 20,
                  width: 20
                }}
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ padding: "3em" }}
        >
          <div>
            <img
              src={icon}
              alt="icon"
              width="auto"
              height="80"
              className="mb-4"
            />
            <div style={{ fontWeight: 600 }}>No orders to display</div>
            <div className="my-3" style={{ color: "#909196", fontSize: 14 }}>
              When a customer places an order, their details will appear here.
            </div>
          </div>
        </div>
      )}

      <MobileOrderDetails open={open} setOpen={setOpen} />
    </div>
  );
}
