import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Chip } from '@material-ui/core';
import img from '../../../../../assets/images/template assets/display_contemporaryV2.png';
export default function TemplateCard(props) {
  const { item, subscription } = props;
  const useStyles = makeStyles((theme) => ({
    card: {
      border: props.isMobile ? 'none' : '1px solid #d1d5dd',
      borderRadius: 4,
      backgroundColor: '#fff',
      padding: 12,
    },
    btn: {
      height: 32,
      fontSize: 10,
      margin: 4,
    },
  }));
  const classes = useStyles();
  return (
    <div className="col-xl-4 col-sm-12 my-3">
      <div className={`${classes.card}`}>
        <div className="d-flex align-items-center justify-content-between">
          <strong style={{ fontSize: 16, color: '#2b2d32' }}>
            {item.template}
          </strong>
          {item.is_paid ? (
            <Chip
              size="small"
              style={{ backgroundColor: '#CE6E61', color: '#fff' }}
              label="Premium"
            />
          ) : (
            ''
          )}
        </div>
        <div className="my-2">
          <img src={item.image} alt="template_img" width="100%" height="auto" />
        </div>
        <div
          className={`d-flex  ${
            props.isMobile ? "justify-content-between" : "justify-content-end"
          }`}
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.btn}
            disabled={item.is_paid && !subscription.subscription_type}
            onClick={() => {
              props.setValues({ ...props.values, template: item.value });
              props.setOpen({ ...props.open, dialog: true });
            }}
          >
            Use this template
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className={classes.btn}
            onClick={() => props.handleViewDemo(item)}
            style={{
              width: "50%"
            }}
          >
            Preview
          </Button>
        </div>
      </div>
    </div>
  );
}
