import React from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Button
} from "@material-ui/core";
import RegisterForm from "../registerComponents/registerForm";
import SentroAlert from "../../common/alert";
import Text from "../../../common/text";
import Path from "../../../Paths";
import SentroLoading from "../../common/loading";
import { useSelector } from "react-redux";
import DocumentMeta from "react-document-meta";
import sentroLogo from "../../../assets/images/sentro_logo2.png";
import bannerDesktop from "../../../assets/images/landing-page-images/cosmetics-desktop.png";
import iconsDesktop from "../../../assets/images/landing-page-images/icons-desktop.svg";
import iconsMobile from "../../../assets/images/landing-page-images/icons-mobile.svg";
import bannerMobile from "../../../assets/images/landing-page-images/cosmetics-mobile.png";
import MetaTags from "react-meta-tags";
const LandingCosmetics = props => {
  const meta = {
    title: "Sentro | Sign-up for an account",
    description: `Signing up with Sentro for free!`,
    canonical: "https://sentro.ph/sign_up",
    meta: {
      charset: "utf-8"
    }
  };
  let param = props.location.search.split("&");
  let utms = {
    utm_source: param[1] ? param[1].replace("utm_source=", "") : "",
    utm_medium: param[2] ? param[2].replace("utm_medium=", "") : "",
    utm_campaign: param[3] ? param[3].replace("utm_campaign=", "") : "",
    utm_content: param[4] ? param[4].replace("utm_content=", "") : ""
  };
  React.useEffect(() => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("CURRENT_USER");
    sessionStorage.removeItem("TOKEN");
    sessionStorage.removeItem("CURRENT_USER");
  }, []);

  const [clicked, setClicked] = React.useState(false);
  const RegisterStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60%",
      height: "100vh",
      backgroundSize: "cover",
      backgroundPositionY: "15%",
      backgroundPositionX: "center",
      backgroundImage: `url(${bannerDesktop})`,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    register: {
      height: "100vh",
      overflow: "auto",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    box1: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },

    box2: {
      padding: "4vh 2vw 0 ",

      [theme.breakpoints.down("sm")]: {
        padding: "3vh 12vw"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "3vh 5vw"
      }
    },
    link2: {
      color: "#F97663",
      fontSize: 14,
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "underline",
        color: "#F97663"
      }
    },
    label: {
      fontSize: 14,
      color: "#f97663",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        margin: "0 30px"
      }
    },
    label2: {
      fontSize: 12,
      color: "#f97663",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        margin: "0 30px"
      }
    },
    icon: {
      width: 45,
      height: "auto",
      margin: "12px 0"
    },
    iconSection: {
      display: "block",
      flex: 4,
      margin: "0px 10px",
      [theme.breakpoints.up("xl")]: {
        margin: "0px 35px"
      },
      [theme.breakpoints.down("lg")]: {
        margin: "0px 10px"
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0px 10px",
        display: "flex",
        alignItems: "center"
      }
    },
    section: {
      padding: "30px 40px 0px",
      display: "flex",
      flex: 12,
      [theme.breakpoints.down("md")]: {
        padding: "30px 0px 0px"
      },

      [theme.breakpoints.down("sm")]: {
        padding: "140px 140px 0",
        display: "block"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "30px 30px 0",
        display: "block"
      }
    },
    desktop: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: clicked ? "" : "none"
      }
    },
    mobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: clicked ? "none" : "block"
      }
    },
    appBar: {
      backgroundColor: "#fff"
    },
    mobileContent: {
      backgroundPositionY: "center",
      backgroundImage: `url(${bannerMobile})`,
      backgroundSize: "cover",
      width: "100%",
      height: "100vh",
      [theme.breakpoints.down("sm")]: {
        backgroundPositionY: "20%"
      }
    },
    sentroLogo: {
      marginTop: "4vh",
      marginLeft: "2vw",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "12vw"
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "5vw"
      }
    },
    icons: {
      width: "100%",
      height: 145,
      position: "absolute",
      bottom: 10,
      [theme.breakpoints.down("md")]: {
        bottom: 40
      },
      [theme.breakpoints.up("xl")]: {
        bottom: 40,
        height: 225
      }
    }
  }));

  const classes = RegisterStyles();
  const loading = useSelector(state => state.signUp.loading);

  function mobile() {
    return (
      <div>
        <div className={classes.mobileContent}>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <img
                alt="sentro-logo"
                src={sentroLogo}
                style={{ width: 120, height: "auto" }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={() => setClicked(true)}
              >
                Sign up
              </Button>
            </Toolbar>
          </AppBar>

          <div
            style={{ position: "absolute", bottom: 40, left: 10, right: 10 }}
          >
            <div className={classes.section}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={iconsMobile} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "3vh"
                }}
              >
                <Button
                  style={{ maxHeight: 40 }}
                  variant="contained"
                  color="primary"
                  onClick={() => setClicked(true)}
                >
                  Create free account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <MetaTags>
        <title>Sentro | Online Shop Builder</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Naka Sentro sa pag asenso ng business mo!"
        />

        <meta itemprop="name" content="Sentro | Online Shop Builder" />
        <meta
          itemprop="description"
          content="Naka Sentro sa pag asenso ng business mo!"
        />
        <meta
          itemprop="image"
          content="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/Landing_OG-Cosmetics.jpg"
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sentro | Online Shop Builder" />
        <meta
          property="og:description"
          content="Naka Sentro sa pag asenso ng business mo!"
        />
        <meta
          property="og:image"
          content="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/Landing_OG-Cosmetics.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sentro | Online Shop Builder" />
        <meta
          name="twitter:description"
          content="Naka Sentro sa pag asenso ng business mo!"
        />
        <meta
          name="twitter:image"
          content="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/Landing_OG-Cosmetics.jpg"
        />
      </MetaTags>
      <div className={classes.root}>
        <SentroLoading open={loading} />
        <div className={classes.desktop}>
          <Box className={classes.banner}>
            <Container>
              <Box className={classes.box1}>
                <img
                  src={iconsDesktop}
                  alt="icons-desktop"
                  className={classes.icons}
                />
              </Box>
            </Container>
          </Box>
          <Box className={classes.register}>
            <Container>
              <Box className={classes.sentroLogo}>
                <img src={sentroLogo} style={{ width: 140, height: "auto" }} />
              </Box>
              <Box className={classes.box2}>
                <Text
                  style={{ color: "#002C42" }}
                  textType="h3"
                  content="Create an account"
                />

                <Box style={{ marginTop: "4vh" }}>
                  <SentroAlert />
                </Box>

                <Box style={{ marginTop: "3vh" }}>
                  <RegisterForm utms={utms} />
                </Box>
                <Box
                  style={{
                    marginTop: "3vh",
                    textAlign: "center",
                    marginBottom: "4vh"
                  }}
                >
                  <Typography style={{ fontSize: "13px" }}>
                    Already have an account?{" "}
                    <a
                      id="login_link"
                      className={classes.link2}
                      onClick={() => props.history.push(Path.LOGIN)}
                      variant="link"
                    >
                      Login
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        </div>
        <div className={classes.mobile}>{mobile()}</div>
      </div>
    </div>
  );
};
export default LandingCosmetics;
