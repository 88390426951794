import { LoginActionTypes } from "./authActions";
const initialState = {
  user: {},
  loading: false,
  error: "",
  loggedOut: false,
};
export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case LoginActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case LoginActionTypes.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.payload,
      };
    case LoginActionTypes.VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case LoginActionTypes.VERIFICATION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case LoginActionTypes.LOGGED_OUT:
      return {
        ...state,
        loggedOut: true,
      };
    case LoginActionTypes.CLEAR:
      return {
        initialState,
      };
    default:
      return initialState;
  }
}
