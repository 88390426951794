import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Chip,
  IconButton,
  TableFooter,
  Tooltip,
  TablePagination,
  Button,
  Box
} from "@material-ui/core";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import imgPlaceholder from "../../../../../assets/images/imgPlaceholder2.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/images/upload_icon_outlined.svg";
import { usePriceRangeDisplay } from "../../../../hooks/priceDisplay";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { showAlert } from "../../../../common/commonActions";
import { destroy, reset } from "redux-form";
import {
  editProduct,
  setProductFilters,
  editProductv2,
  setProductSelected,
  ReduxInitializeFormActions,
  getVariants
} from "../../../websiteBuilderActions";
import FeaturedProductWarning from "./featuredProductModal";
import productIcon from "../../../../../assets/images/product_icon.svg";
import AddIcon from "@material-ui/icons/Add";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteProductModal from "./deleteProductModal";
import Path from "../../../../../Paths";
import UploadImageDrawer from "./uploadImageDrawer";

const EnhancedTableHead = props => {
  const { numSelected, rowCount, onSelectAllChange, filters } = props;
  return (
    <TableHead style={{ backgroundColor: "#eee" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount === 0 ? "" : numSelected === rowCount}
            onChange={onSelectAllChange}
            color="primary"
          />
        </TableCell>
        <TableCell style={{ width: 90 }}>Image</TableCell>
        <TableCell align="left" style={{ width: 200 }}>
          Name
        </TableCell>
        <TableCell>Category</TableCell>
        <TableCell align="right" style={{ width: 160 }}>
          Price
        </TableCell>
        <TableCell align="center">Stock</TableCell>
        <TableCell align="left">Variants</TableCell>
        <TableCell align="right">
          {filters.status === "draft" ? "Actions" : "Featured"}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
export default function ProductTable(props) {
  const useStyles = makeStyles(theme => ({
    img: {
      width: 50,
      height: 50,
      borderRadius: 4
    },
    chip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    },
    name: {
      width: 200,
      color: "#f97663",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    tableRow: {
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#fafafa !important"
      }
    }
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const store = useSelector(state => state.webBuilder.storeInfo);
  const products = useSelector(
    state => state.webBuilder.products.products.results
  );
  const filters = useSelector(state => state.webBuilder.products.filters);
  const product_count = useSelector(
    state => state.webBuilder.products.products
  );
  const loading = useSelector(state => state.webBuilder.products.loading);
  const selected = useSelector(
    state => state.webBuilder.products.bulk_actions.selected
  );
  const values = useSelector(state => state.form.product_form?.values);
  const [open, setOpen] = React.useState(false);
  const [imageUpload, setImageUpload] = React.useState({
    open: false,
    product: ""
  });
  const [isDelete, setIsDelete] = React.useState({
    open: false,
    product: ""
  });
  const imageList = [];
  const numOfFeaturedProducts = [];

  const PriceRow = item => {
    const [priceDisplay] = usePriceRangeDisplay(item.item);
    return (
      <TableCell align="right" style={{ width: 160 }}>
        <div className="d-flex justify-content-end" style={{ fontSize: 13 }}>
          ₱ {priceDisplay}
        </div>
      </TableCell>
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setProductFilters({ page: newPage }));
  };

  const handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value, 10);
    dispatch(setProductFilters({ itemsPerPage: rowsPerPage }));
  };

  const handleSelected = (e, product) => {
    let product_uuids = selected.map(i => i.uuid);
    if (e.target.checked) {
      if (!product_uuids.includes(product.uuid)) {
        dispatch(setProductSelected([...selected, product]));
      }
    } else {
      const array = selected.filter(e => e.uuid !== product.uuid);
      dispatch(setProductSelected(array));
    }
  };

  const onSelectAllChange = event => {
    if (event.target.checked) {
      dispatch(setProductSelected(products));
    } else {
      dispatch(setProductSelected([]));
    }
  };

  const handleDeleteProduct = async () => {
    if (products && products.length) {
      const res = await dispatch(
        editProductv2(
          store,
          {
            ...isDelete.product,
            status: "deleted"
          },
          filters
        )
      );
      if (res) {
        // SHOW ALERT
        dispatch(
          showAlert({
            type: "success",
            message: "Product successfully deleted!"
          })
        );
        setIsDelete({ open: false, product: "" });
      } else {
        setIsDelete({ ...isDelete, open: false });
      }
    }
  };

  const handleFeatured = async (item, featured) => {
    imageList.length = 0;
    const variants = item.variant_groups;
    for (let i in products) {
      if (products[i].featured) {
        numOfFeaturedProducts.push(products[i]);
      }
    }

    if (numOfFeaturedProducts.length >= 4 && featured) {
      setOpen(true);
    } else {
      for (let i in item["gallery"]) {
        imageList.push(item["gallery"][i].image);
      }
      const res = await dispatch(
        editProduct(
          {
            ...item,
            store: store && store.uuid,
            featured: featured
          },
          variants,
          imageList,
          filters
        )
      );

      try {
        if (res.status === 200) {
          window.scrollTo(0, 0);
          dispatch(
            showAlert({
              type: "success",
              message: "Product successfully updated!"
            })
          );
        }
      } catch {
        window.scrollTo(0, 0);
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    }
  };

  const handleUploadImage = async product => {
    setImageUpload({ open: true, product: product });
    try {
      dispatch(
        ReduxInitializeFormActions.initializeProductFormData({
          ...product,
          img: product.gallery.map(i => i.image),
          category: product.category || "Others"
        })
      );
      dispatch(getVariants(product.uuid));
    } catch {
      // SHOW ALERT
      dispatch(
        showAlert({
          type: "error",
          message: `An error occured. Please try again`
        })
      );
    }
  };

  function productImage(item) {
    if (item.gallery) {
      if (item.gallery.length) {
        if (item.photo_primary === null || item.photo_primary === "") {
          return item.gallery[0].image;
        } else {
          if (
            item.gallery[item.photo_primary] === "undefined" ||
            item.gallery[item.photo_primary] === undefined
          ) {
            return item.gallery[0].image;
          } else {
            return item.gallery[item.photo_primary].image;
          }
        }
      } else {
        return imgPlaceholder;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return imgPlaceholder;
      }
    }
  }

  function stock_display(item) {
    if (item.has_variants) {
      if (item.selections.length) {
        const stocks = item.selections.map(item => item.quantity);
        let sumOfStocks = stocks.reduce(
          (total, currentValue) => (total = total + currentValue),
          0
        );
        if (sumOfStocks <= 5)
          return (
            <span style={{ color: "red", fontWeight: 600 }}>{sumOfStocks}</span>
          );
        else return sumOfStocks;
      }
    } else {
      if (item.always_available) return "∞";
      else {
        if (item.quantity <= 5)
          return (
            <span style={{ color: "red", fontWeight: 600 }}>
              {item.quantity}
            </span>
          );
        else return item.quantity;
      }
    }
  }

  React.useEffect(() => {
    dispatch(setProductSelected([]));
  }, [filters.status]);

  React.useEffect(() => {
    if (!imageUpload.open) {
      dispatch(reset("product_form"));
      dispatch(ReduxInitializeFormActions.initializeProductFormData(""));
    }
  }, [imageUpload]);

  return (
    <div className="my-3">
      <Table className={classes.table} aria-labelledby="tableTitle">
        <EnhancedTableHead
          numSelected={selected.length}
          rowCount={products && products.length}
          onSelectAllChange={onSelectAllChange}
          filters={filters}
        />
        <TableBody>
          {products && products.length
            ? products.map((item, index) => {
                return (
                  <TableRow hover role="checkbox" className={classes.tableRow}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        className="selectCheckbox"
                        color="primary"
                        checked={selected.map(i => i.uuid).includes(item.uuid)}
                        onChange={e => handleSelected(e, item)}
                      />
                    </TableCell>
                    <TableCell style={{ width: 90 }}>
                      <img
                        src={productImage(item)}
                        className={classes.img}
                        style={{
                          cursor:
                            filters.status === "draft" ? "pointer" : "inherit"
                        }}
                        onClick={
                          filters.status === "draft"
                            ? () => handleUploadImage(item)
                            : null
                        }
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        cursor:
                          filters.status !== "deleted" ? "pointer" : "inherit"
                      }}
                      onClick={
                        filters.status === "deleted"
                          ? null
                          : () => {
                              history.push(`/products/${item.uuid}`);
                            }
                      }
                    >
                      <div className={classes.name}>{item.name}</div>
                    </TableCell>
                    <TableCell>{item.category}</TableCell>
                    <PriceRow item={item} />
                    <TableCell align="center"> {stock_display(item)}</TableCell>
                    <TableCell align="left">
                      <div className="d-flex">
                        {item.variant_groups &&
                        item.variant_groups.length &&
                        item.has_variants
                          ? item.variant_groups.map(item => (
                              <Chip
                                size="small"
                                className={classes.chip}
                                label={item.name}
                              />
                            ))
                          : ""}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {filters.status === "draft" ? (
                        <div className="d-flex justify-content-end">
                          <IconButton
                            className="p-2"
                            onClick={() => handleUploadImage(item)}
                          >
                            <UploadIcon />
                          </IconButton>
                          <IconButton
                            className="p-2"
                            onClick={() =>
                              setIsDelete({ open: true, product: item })
                            }
                          >
                            <DeleteOutlineIcon
                              style={{ fontSize: 18, color: "#909196" }}
                            />
                          </IconButton>
                        </div>
                      ) : (
                        <Tooltip
                          title="You can feature a maximum of 4 products on your homepage"
                          placement="top"
                        >
                          <IconButton
                            onClick={() =>
                              handleFeatured(item, item.featured ? false : true)
                            }
                          >
                            {item.featured ? (
                              <BookmarkIcon style={{ color: "#f97663" }} />
                            ) : (
                              <BookmarkBorderIcon
                                style={{ color: "#909196" }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            : ""}
        </TableBody>
      </Table>
      {products && products.length ? (
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ color: "#54575f", fontSize: 13 }}>
            {product_count.count} item(s) found
          </div>

          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={product_count.count}
            rowsPerPage={filters.itemsPerPage}
            page={filters.page}
            onChangePage={handleChangePage}
          />
        </div>
      ) : (
        ""
      )}

      {products && products.length ? (
        ""
      ) : (
        <div
          className="d-flex align-items-center justify-content-center text-center"
          style={{ minHeight: 350, backgroundColor: "#fff" }}
        >
          <div>
            <img
              src={productIcon}
              alt="product-icon"
              width="auto"
              height="70"
              className="mb-4"
            />
            <div style={{ fontWeight: 600 }}>No products to display</div>
            <div className="my-3" style={{ color: "#909196", fontSize: 14 }}>
              Add your products so you can start selling.
            </div>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              startIcon={<AddIcon style={{ color: "#fff" }} />}
              style={{ height: 40 }}
              onClick={() => history.push(Path.PRODUCTS_ADD)}
            >
              Add product
            </Button>
          </div>
        </div>
      )}

      <FeaturedProductWarning open={open} setOpen={setOpen} />
      <DeleteProductModal
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        handleDeleteProduct={handleDeleteProduct}
      />
      {imageUpload.open ? (
        <UploadImageDrawer
          state={imageUpload}
          setState={setImageUpload}
          store={store}
          filters={filters}
        />
      ) : (
        ""
      )}
    </div>
  );
}
