import React from "react";
import { Route, Switch } from "react-router-dom";
import Path from "../Paths";
import BuyerDashboard from "../components/dashboard/buyerDashboard/buyerDashboard";
import CartViewOrder from "../components/dashboard/buyerDashboard/cartViewOrder";
import PurchasedPage from "../components/dashboard/buyerDashboard/purchasedPage";
import PurchasedViewOrder from "../components/dashboard/buyerDashboard/purchasedViewOrder";
import BuyerDeliveryDetails from "../components/dashboard/buyerDashboard/customer_details";
import Delivery from "../components/dashboard/buyerDashboard/delivery";
import Payment from "../components/dashboard/buyerDashboard/payment";
import Checkout from "../components/dashboard/buyerDashboard/checkout";
import Profile from "../components/dashboard/buyerDashboard/profile";
let BuyerDashboardRoutes = [
  <Route exact path={Path.BUYER_DASHBOARD} component={BuyerDashboard} />,
  <Route exact path={Path.BUYER_DASHBOARD_PROFILE} component={Profile} />,
  <Route path={Path.CART_VIEW_ORDER} component={CartViewOrder} />,
  <Route exact path={Path.PURCHASED_PAGE} component={PurchasedPage} />,
  <Route path={Path.PURCHASED_VIEW_ORDER} component={PurchasedViewOrder} />,
  <Route path={`/my_cart/details`} component={BuyerDeliveryDetails} />,
  <Route path={`/my_cart/delivery`} component={Delivery} />,
  <Route path={`/my_cart/payment`} component={Payment} />,
  <Route path={`/my_cart/checkout`} component={Checkout} />
];

export default BuyerDashboardRoutes;
