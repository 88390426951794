import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as Cart } from "../../../../../assets/images/enterprise/cart.svg";
import { ReactComponent as Purchased } from "../../../../../assets/images/enterprise/purchased.svg";
import { ReactComponent as Discount } from "../../../../../assets/images/enterprise/discounts.svg";
import { ReactComponent as Wishlist } from "../../../../../assets/images/enterprise/wishlist.svg";
import history from "../../../../../store/history";
import { useSelector } from "react-redux";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function BottomNavigation(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const enterprise = useSelector(state => state.enterprise.main.enterprise);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const menu = [
    {
      label: "My Cart",
      icon: <Cart style={{ width: "auto", height: 35 }} />,
      path: `/enterprise/${enterprise.site_name}/buyer_dashboard`,
      key: 0
    },
    {
      label: "Purchased",
      icon: <Purchased style={{ width: "auto", height: 35 }} />,
      path: `/enterprise/${enterprise.site_name}/buyer_dashboard/purchased`,
      key: 1
    },
    {
      label: "Wishlist",
      icon: <Wishlist style={{ width: "auto", height: 35 }} />,
      path: `/enterprise/${enterprise.site_name}/buyer_dashboard/wishlist`,
      key: 2,
    }
  ];

  return (
    <div className={classes.root}>
      <AppBar
        style={{
          backgroundColor: "#fff",
          boxShadow: "none",
          borderTop: "1px solid #d1d5dd"
        }}
        position="static"
      >
        <Tabs
          indicatorColor={"primary"}
          style={{ display: "flex" }}
          onChange={handleChange}
          value={value}
          aria-label="simple tabs example"
        >
          {menu.map((item, key) => (
            <Tab
              disabled={item.isDisabled}
              icon={item.icon}
              style={{
                flexGrow: 3,
                color: value == key ? "#002c42" : "#54575f"
              }}
              key={key}
              label={item.label}
              onClick={() => history.push(item.path)}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
