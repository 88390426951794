import React from "react";
import {
  makeStyles,
  Button,
  IconButton,
  Dialog,
  Slide,
  AppBar,
  Toolbar
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector } from "react-redux";
import FilterListIcon from "@material-ui/icons/FilterList";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SortSettings from "./index";
import ChipGroup from "./chipGroup";

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 16,
    fontWeight: 600,
    color: "#002c42",
    textTransform: "capitalize",
    padding: "20px 20px 5px"
  },
  filterIcon: {
    color: "#F97663"
  },
  infoText: {
    color: "#54575F",
    fontSize: 12
  }
}));

const ProductListHead = props => {
  const classes = useStyles();
  const [sortOpen, setSortOpen] = React.useState(false);
  const ref = React.createRef();
  const [sortBy, setSortBy] = React.useState({
    name: "Latest",
    value: "-created_on"
  });
  const [categoryFilters, setCategoryFilters] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const storeProducts = useSelector(
    state => state.mobileDashboard.products.list
  );
  const filteredProducts = useSelector(
    state => state.mobileDashboard.filtered_products
  );
  const has_product_filters = useSelector(
    state => state.mobileDashboard.has_product_filters
  );
  const products = has_product_filters ? filteredProducts : storeProducts;
  let searchTimeout = 0;
  const deleteFilters = newFilters => {
    const categories = newFilters.slice(1);
    setCategoryFilters(categories);
    fetch({
      search,
      sortBy: sortBy.value,
      category: JSON.stringify(categories)
    });
  };
  const onSearch = event => {
    const val = event.target.value;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setSearch(val);
      fetch({
        search: val,
        sortBy: sortBy.value,
        category: JSON.stringify(categoryFilters)
      });
    }, 700);
  };
  const onChange = (sortBy, categoryFilters) => {
    setSortBy(sortBy);
    setCategoryFilters(categoryFilters);
    fetch({
      search,
      sortBy: sortBy.value,
      category: JSON.stringify(categoryFilters)
    });
  };
  const fetch = params => {
    if (props.onChange) props.onChange(params);
    console.log("fetch", params);
  };
  React.useEffect(() => {
    if (props.heightUpdate) props.heightUpdate(ref.current.clientHeight);
  }, [ref]);
  return (
    <div ref={ref}>
      <div className="d-flex align-items-center">
        <div className={classes.header}>Products</div>
        <IconButton
          className="ml-auto mr-2"
          color="primary"
          onClick={() => setSortOpen(true)}
        >
          <FilterListIcon className={`${classes.filterIcon}`} />
        </IconButton>
      </div>
      <ChipGroup
        choices={[sortBy.name, ...categoryFilters]}
        handleDelete={deleteFilters}
      />
      <div style={{ padding: "0 20px" }}>
        <OutlinedInput
          className={classes.searchBar}
          fullWidth
          id="outlined-adornment-weight"
          onChange={event => onSearch(event)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight"
          }}
          labelWidth={0}
          margin="dense"
          placeholder="Type here to search"
        />
        <p className={`${classes.infoText} mt-2 mb-1`}>
          {products.length} item(s) found
        </p>
      </div>
      <SortSettings
        open={sortOpen}
        setOpen={setSortOpen}
        sortBy={sortBy}
        categoryFilters={categoryFilters}
        onChange={onChange}
      />
    </div>
  );
};

export default ProductListHead;
