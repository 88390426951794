import OrderServices from "./orderServices";
import history from "../../store/history";
import Path from "../../Paths";
import moment from "moment";
import { showAlert } from "../common/commonActions";
export const OrderActionTypes = {
  ORDER_LOADING: "@@ORDER_LOADING",
  ORDER_LOADING_DONE: "@@ORDER_LOADING_DONE",
  ORDER_GET: "@@ORDER_GET",
  ORDER_SET_ORDER_DETAIL: "@@ORDER_SET_ORDER_DETAIL",
  ORDER_CALCULATE_SHIPPING: "@@ORDER_CALCULATE_SHIPPING",
  ORDER_BOOK_SHIPPING: "@@ORDER_BOOK_SHIPPING",
  SET_ORDER_MESSAGE: "@@SET_ORDER_MESSAGE",
  OPEN_ALERT: "@@OPEN_ALERT",
  CLOSE_ALERT: "@@CLOSE_ALERT",
  SOURCE: "@@SOURCE",
  SET_ORDER_FILTERS: "SET_ORDER_FILTERS",
  GET_PICKUP_ADDRESS: "GET_PICKUP_ADDRESS"
};

class OrderActions {
  static getOrders(params = false) {
    return async dispatch => {
      try {
        dispatch({ type: OrderActionTypes.ORDER_LOADING });
        let response = await OrderServices.getOrders(params);
        dispatch({ type: OrderActionTypes.ORDER_GET, payload: response.data });
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    };
  }
  static setOrder(id) {
    return async dispatch => {
      dispatch({ type: OrderActionTypes.ORDER_LOADING });
      try {
        let order = await OrderServices.getOrder(id);
        if (order.is_read === false) {
          await OrderServices.setOrderRead(order);
          order = await OrderServices.getOrder(id);
        }
        dispatch({
          type: OrderActionTypes.ORDER_SET_ORDER_DETAIL,
          payload: order
        });
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
        history.push(`/orders/details?id=${id}`);
        return order;
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
      }
    };
  }

  static setDeliveryDetails(id) {
    return async dispatch => {
      dispatch({ type: OrderActionTypes.ORDER_LOADING });
      try {
        let order = await OrderServices.getOrder(id);
        order.amount =
          parseFloat(order.subtotal) + parseFloat(order.other_fees);

        dispatch({
          type: OrderActionTypes.ORDER_SET_ORDER_DETAIL,
          payload: order
        });
        // history.push(Path.ORDER_DETAILS_VIEW);
        history.push(`/orders/delivery_map?id=${id}`);
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
      }
    };
  }

  static setOrderDetails(id, source) {
    return async dispatch => {
      dispatch({ type: OrderActionTypes.ORDER_LOADING });
      try {
        let order = await OrderServices.getOrder(id);

        dispatch({
          type: OrderActionTypes.ORDER_SET_ORDER_DETAIL,
          payload: order
        });
        history.push(Path.ORDER_UPDATE_PAYMENT);
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
      }
    };
  }

  static requestDelivery(id) {
    return async dispatch => {
      dispatch({ type: OrderActionTypes.ORDER_LOADING });
      try {
        let order = await OrderServices.getOrder(id);
        order.amount =
          parseFloat(order.subtotal) + parseFloat(order.other_fees);

        dispatch({
          type: OrderActionTypes.ORDER_SET_ORDER_DETAIL,
          payload: order
        });
        history.push(Path.ORDER_DELIVERY_REQUEST);
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
      }
    };
  }
  static cancelDelivery(values) {
    return async dispatch => {
      dispatch({ type: OrderActionTypes.ORDER_LOADING });
      try {
        await OrderServices.cancelDelivery(values.mrspeedy_id);
        let order = await OrderServices.getOrder(values.id);
        order.amount =
          parseFloat(order.subtotal) + parseFloat(order.other_fees);
        dispatch({
          type: OrderActionTypes.ORDER_SET_ORDER_DETAIL,
          payload: order
        });
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
        dispatch({ type: OrderActionTypes.OPEN_ALERT });
        dispatch({
          type: OrderActionTypes.SET_ORDER_MESSAGE,
          payload: {
            message: "Delivery order successfully canceled.",
            type: "success"
          }
        });
        return true;
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
        dispatch({ type: OrderActionTypes.OPEN_ALERT });
        dispatch({
          type: OrderActionTypes.SET_ORDER_MESSAGE,
          payload: {
            message: "Your order cannot be processed. Please try again ",
            type: "error"
          }
        });
        return false;
      }
    };
  }

  static cancelOrder(values, filters) {
    return async dispatch => {
      try {
        dispatch({ type: OrderActionTypes.ORDER_LOADING });
        let proceed_cancelling_order = true;
        if (values.mrspeedy_id && values.mrspeedy_status !== "canceled") {
          proceed_cancelling_order = await dispatch(
            this.cancelDelivery(values)
          );
        }
        if (proceed_cancelling_order) {
          await OrderServices.cancelOrder(values);
          let response = await OrderServices.getOrder(values.id);
          response.amount =
            parseFloat(response.subtotal) + parseFloat(response.other_fees);
          dispatch({
            type: OrderActionTypes.ORDER_SET_ORDER_DETAIL,
            payload: response
          });
          dispatch({
            type: OrderActionTypes.ORDER_LOADING_DONE
          });
          dispatch(
            showAlert({
              type: "success",
              message: "Your order has been successfully cancelled."
            })
          );
        }
        dispatch(this.getOrders(filters));
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
        dispatch(
          showAlert({
            type: "error",
            message: "Your order cannot be processed. Please try again "
          })
        );
      }
    };
  }

  static setMessageNull() {
    return async dispatch => {
      dispatch({ type: OrderActionTypes.SET_ORDER_MESSAGE, payload: null });
    };
  }

  static updatePayment(values, source) {
    console.log("VALUES: ", values);
    return async dispatch => {
      dispatch({ type: OrderActionTypes.ORDER_LOADING });
      try {
        await OrderServices.updatePayment(values);
        if (source === "DETAILS") {
          let order = await OrderServices.getOrder(values.id);
          console.log("ORDER: ", order);
          order.amount =
            parseFloat(order.subtotal) + parseFloat(order.other_fees);
          dispatch({
            type: OrderActionTypes.ORDER_SET_ORDER_DETAIL,
            payload: order
          });
        } else if (source === "LIST") {
          let orders = await OrderServices.getOrder(values.id);
          console.log("ORDERS: ", orders);
          orders.map(item => {
            item.amount =
              parseFloat(item.subtotal) + parseFloat(item.other_fees);
            item.paid = item.paid ? "Paid" : true;
            item.paid_date = item.paid_date == null ? "--" : item.paid_date;
          });
          dispatch({
            type: OrderActionTypes.ORDER_SET_ORDER_DETAIL,
            payload: orders
          });
        }
        dispatch(
          showAlert({
            type: "success",
            message: "Transaction successfully marked as paid"
          })
        );
        history.push(Path.ORDER_LIST_VIEW);
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    };
  }

  static updateDelivery(values, source, orderFilters) {
    console.log(values, source);
    return async dispatch => {
      dispatch({ type: OrderActionTypes.ORDER_LOADING });
      try {
        await OrderServices.updateDelivery(values);
        if (source === "DETAILS") {
          let order = await OrderServices.getOrders(orderFilters);
          console.log(order);
          order.amount =
            parseFloat(order.subtotal) + parseFloat(order.other_fees);
          dispatch({
            type: OrderActionTypes.ORDER_SET_ORDER_DETAIL,
            payload: order
          });
        } else if (source === "LIST") {
          let orders = await OrderServices.getOrders(orderFilters);
          orders.list.map(item => {
            item.amount =
              parseFloat(item.subtotal) + parseFloat(item.other_fees);
            item.paid = item.paid ? "Paid" : true;
            item.paid_date = item.paid_date == null ? "--" : item.paid_date;
          });
          dispatch({ type: OrderActionTypes.ORDER_GET, payload: orders });
        }
        dispatch({ type: OrderActionTypes.OPEN_ALERT });
        dispatch({
          type: OrderActionTypes.SET_ORDER_MESSAGE,
          payload: {
            message: "Transaction successfully marked as delivered",
            type: "success"
          }
        });
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
        dispatch({ type: OrderActionTypes.OPEN_ALERT });
        dispatch({
          type: OrderActionTypes.SET_ORDER_MESSAGE,
          payload: {
            message: "Your order cannot be processed. Please try again ",
            type: "error"
          }
        });
      }
    };
  }
  static calculateShipping(value) {
    return async dispatch => {
      dispatch({ type: OrderActionTypes.ORDER_LOADING });
      try {
        let shipping = await OrderServices.calculateShipping(value);
        dispatch({
          type: OrderActionTypes.ORDER_CALCULATE_SHIPPING,
          payload: shipping
        });
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
        dispatch({ type: OrderActionTypes.OPEN_ALERT });
        dispatch({
          type: OrderActionTypes.SET_ORDER_MESSAGE,
          payload: {
            message: "Your order cannot be processed. Please try again ",
            type: "error"
          }
        });
      }
    };
  }
  static bookShipping(value) {
    console.log(value);
    return async dispatch => {
      dispatch({ type: OrderActionTypes.ORDER_LOADING });
      try {
        let shipping = await OrderServices.bookShipping(value);
        dispatch({
          type: OrderActionTypes.ORDER_BOOK_SHIPPING,
          payload: shipping
        });
        history.push(Path.ORDER_DELIVERY_REQUEST_SUCCESS);
      } catch (error) {
        dispatch({ type: OrderActionTypes.ORDER_LOADING_DONE });
        dispatch({ type: OrderActionTypes.OPEN_ALERT });
        dispatch({
          type: OrderActionTypes.SET_ORDER_MESSAGE,
          payload: {
            message: "Your order cannot be processed. Please try again ",
            type: "error"
          }
        });
      }
    };
  }
  static setOrderFilters(payload) {
    return async dispatch => {
      dispatch({
        type: OrderActionTypes.SET_ORDER_FILTERS,
        payload
      });
    };
  }

  static closeAlert() {
    return async dispatch => {
      dispatch({ type: OrderActionTypes.CLOSE_ALERT });
    };
  }

  static getPickupAddress(store_uuid) {
    return async dispatch => {
      try {
        let response = await OrderServices.getPickupAddresses(store_uuid);
        dispatch({
          type: OrderActionTypes.GET_PICKUP_ADDRESS,
          payload: response.data
        });
        return response.data;
      } catch (error) {
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    };
  }
}

export default OrderActions;
