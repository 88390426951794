import React from "react";
import HomeMobile from "./homeMobile";
import Homepage from "./homepage";
import { useTheme, useMediaQuery } from "@material-ui/core";

const Home = props => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    localStorage.setItem("language", "English");
  }, []);
  return <div>{mobileView ? <HomeMobile /> : <Homepage />}</div>;
};

export default Home;
