import {
  changePassword,
  confirmChangePassword,
  verify
} from "./forgotPasswordServices";
import { showAlert } from "../common/commonActions";
import history from "../../store/history";
import Path from "../../Paths";

export const ForgotPasswordActionTypes = {
  LOGIN_REQUEST: "@@LOGIN_REQUEST",
  LOGIN_SUCCESS: "@@LOGIN_SUCCESS",
  LOGIN_FAILED: "@@LOGIN_FAILED",
  CLEAR: "@@CLEAR",

  CHANGE_PASSWORD_REQUEST: "@@CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "@@CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILED: "@@CHANGE_PASSWORD_FAILED",
  RESEND_SUCCESS: "@@RESEND_SUCCESS"
};

export function changePasswordAction(email) {
  return async dispatch => {
    try {
      dispatch({ type: ForgotPasswordActionTypes.CHANGE_PASSWORD_REQUEST });
      let response = await changePassword(email);

      dispatch({
        type: ForgotPasswordActionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: response.data
      });
      history.push(Path.RESET_VERIFY_EMAIL);
    } catch (error) {
      dispatch({
        type: ForgotPasswordActionTypes.CHANGE_PASSWORD_FAILED,
        payload: error.data
      });
      dispatch(showAlert({ type: "error", message: error ? error.data : "" }));
    }
  };
}

export function verifyCode(value) {
  return async dispatch => {
    try {
      dispatch({ type: ForgotPasswordActionTypes.CHANGE_PASSWORD_REQUEST });
      let response = await verify(value);
      dispatch({
        type: ForgotPasswordActionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: response.data
      });
      let data = response.data;
      sessionStorage.setItem("CURRENT_USER", JSON.stringify(data.user));
      sessionStorage.setItem("TOKEN", data.token);
    } catch (error) {
      dispatch(showAlert({ type: "error", message: error.message }));

      history.push(Path.LINK_EXPIRED);
    }
  };
}

export function resendCode(email) {
  return async dispatch => {
    try {
      dispatch({ type: ForgotPasswordActionTypes.CHANGE_PASSWORD_REQUEST });
      let response = await changePassword(email);

      dispatch({
        type: ForgotPasswordActionTypes.RESEND_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch(showAlert({ type: "error", message: error.message }));
    }
  };
}

export function confirmChangePasswordAction(value) {
  return async dispatch => {
    try {
      dispatch({ type: ForgotPasswordActionTypes.CHANGE_PASSWORD_REQUEST });
      await confirmChangePassword(value);
      history.push(Path.RESET_PASSWORD_SUCCESS);
      localStorage.removeItem("CHANGE_PASSWORD_EMAIL");
    } catch (error) {
      dispatch(showAlert({ type: "error", message: error.message }));
    }
  };
}
