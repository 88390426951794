import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import history from "../../../../store/history";
import { useLocation } from "react-router-dom";
import voucher1 from "../../../../assets/images/sidebar_assets/voucher_mobile1.svg";
import voucher2 from "../../../../assets/images/sidebar_assets/voucher_mobile2.svg";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  indicator: {
    top: 0
  }
}));

export default function MobileBottomNavigation(props) {
  let location = useLocation();
  let currentLoc = location.pathname;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleIcon(item, key) {
    if (item.label === "Vouchers") {
      if (value === 3) {
        return <img src={voucher2} style={{ height: 26, width: "auto" }} />;
      } else {
        return <img src={voucher1} style={{ height: 26, width: "auto" }} />;
      }
    } else {
      return item.icon;
    }
  }

  React.useEffect(() => {
    if (currentLoc.includes("shop")) {
      setValue(0);
    } else if (currentLoc.includes("products")) {
      setValue(1);
    } else if (currentLoc.includes("orders")) {
      setValue(2);
    } else if (currentLoc.includes("vouchers")) {
      setValue(3);
    }
  }, [currentLoc]);

  return (
    <div className={classes.root}>
      <AppBar style={{ backgroundColor: "white" }} position="static">
        <Tabs
          //indicatorColor={"#F97663"}
          indicatorColor={"primary"}
          style={{ display: "flex" }}
          onChange={handleChange}
          value={value}
          classes={{ indicator: classes.indicator }}
          aria-label="simple tabs example"
        >
          {props.menu.map((item, key) => {
            return (
              <Tab
                icon={handleIcon(item, key)}
                style={{
                  flexGrow: 3,
                  color: value == key ? "#F97663" : "#6f7585"
                }}
                key={key}
                label={item.label}
                onClick={() => {
                  history.push(item.path);
                }}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </div>
  );
}
