import React from "react";
import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core";
import ContentStyles from "./contentStyles";
import Text from "../../../common/text";
import Path from "../../../Paths";
import history from "../../../store/history";

const Footer = props => {
  const classes = ContentStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box className={classes.footer}>
      <Container>
        <Box className={classes.footerBox}>
          <div className={classes.footerContent}>
            <div className={classes.terms}>
              <div style={{ margin: mobileView ? 10 : 30 }}>
                <a
                  id="faq_link"
                  className={classes.link}
                  onClick={() => {
                    window.open("https://faq.sentro.ph/hc/en-us");
                    window.scrollTo(0, 0);
                  }}
                >
                  FAQS
                </a>
              </div>
              <div style={{ margin: mobileView ? 10 : 30 }}>
                <a
                  id="termsAndConditions_link"
                  style={{ fontSize: 12, color: "#F97663", fontWeight: 600 }}
                  onClick={() => {
                    history.push(Path.TNC);
                    window.scrollTo(0, 0);
                  }}
                >
                  TERMS AND CONDITIONS
                </a>
              </div>
              <div style={{ margin: mobileView ? 10 : 30 }}>
                <a
                  id="privacy_policy_link"
                  style={{ fontSize: 12, color: "#F97663", fontWeight: 600 }}
                  onClick={() => {
                    history.push(Path.PRIVACY_POLICY);
                    window.scrollTo(0, 0);
                  }}
                >
                  PRIVACY POLICY
                </a>
              </div>
            </div>
            <div className={classes.footerContent2}>
              <div className={classes.copyright}>
                <Text
                  textType="label1"
                  content={`Copyright © ${new Date().getFullYear()} UBX Philippines Corporation`}
                />
              </div>
              <div style={{ margin: mobileView ? 10 : 30 }}>
                <a id="ubx_link" href="https://ubx.ph/" target="_blank">
                  <img
                    className={classes.ubxLogo}
                    src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/PoweredByUBX.png"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={classes.footerContent3}>
              <div className={classes.bspRegulated}>
                <Text
                  textType="label1"
                  content={"Sentro is operated by UBX Philippines Corporation. UBX is regulated by the Bangko Sentral ng Pilipinas (BSP). \n https://www.bsp.gov.ph"}
                />
              </div>
          </div>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
