import SalesAndOrderServices from "./services";
import apiConfig from "../../common/apiConfig";
import { signOff } from "../auth/authActions";

export const ActionTypes = {
  GET_DATA_REQUEST: "@@GET_DATA_REQUEST",
  GET_DATA_SUCCESS: "@@GET_DATA_SUCCESS",
  GET_DATA_FAILED: "@@GET_DATA_FAILED",
  GET_LIMIT: "@@GET_LIMIT"
};

class SalesAndOrdersActions {
  // static getDashboardData() {
  //   return async dispatch => {
  //     try {
  //       dispatch({ type: ActionTypes.GET_DATA_REQUEST });
  //       let response = await SalesAndOrderServices.getDashboardData();
  //       dispatch({
  //         type: ActionTypes.GET_DATA_SUCCESS,
  //         payload: response
  //       });
  //       return response;
  //     } catch (error) {
  //       dispatch({ type: ActionTypes.GET_DATA_FAILED });
  //     }
  //   };
  // }

  static goToCompleteProfile(value, status) {
    return async dispatch => {
      try {
        let response = await SalesAndOrderServices.goToBux(value);
        let access = response.data ? response.data.access : null;
        if (access) {
          window.location.href = `${apiConfig.buxURL}/sentro/login?access=${access}`;
        }
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        }
      }
    };
  }

  static getLimit(value) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.GET_DATA_REQUEST });
        let response = await SalesAndOrderServices.getLimit(value);
        let access = response ? response.data : "";

        dispatch({
          type: ActionTypes.GET_LIMIT,
          payload: response.data
        });
        return access;
      } catch (error) {
        dispatch({ type: ActionTypes.GET_DATA_FAILED });
      }
    };
  }
}

export default SalesAndOrdersActions;
