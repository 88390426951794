import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Chip,
} from '@material-ui/core';
import { ReactComponent as PenSVG } from '../../../../../../assets/images/enterprise/pen.svg';
import AddressDrawer from './addressDrawer';
import { useDefaultAddress } from '../../../hooks/useAddresses';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import StoreActions from '../../../../../common/template/components/StoreActions';


const AddressItem = props => {
  const theme = useTheme();
  const useStyles = makeStyles(theme => ({
    root: {
      border: `1px solid #D4D5D8`,
      padding: "1em 1.5em",
      borderRadius: 4,
      marginBottom: "2em",
      [theme.breakpoints.down("sm")]: {
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "1px solid #D4D5D8",
        marginTop: 0,
      }
    },
    label: {
      color: "rgba(43, 45, 50, 0.8)",
      fontSize: 12,
      marginRight: "2em",
      fontWeight: 600,
      minWidth: 70
    },
    value: {
      color: "#2B2D32",
      fontSize: 14,
      fontWeight: 500
    },
    chip: {
      height: 25
    },
    button: {
      color: theme.palette.primary.main,
      fontSize: 12,
      cursor: "pointer",
      fontWeight: 600
    }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  const [defaultAddress] = useDefaultAddress();

  const [selectedAddress, setSelectedAddress] = React.useState(defaultAddress);
  React.useEffect(() => {
    setSelectedAddress(defaultAddress);
    if (defaultAddress) {
      set_details_form(defaultAddress);
    }
  }, [defaultAddress])

  const onSubmit = (address) => {
    setSelectedAddress(address);
    set_details_form(address);
  }

  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const set_details_form = (address) => {
    const details = {
      ...address,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    }
    const keys = Object.keys(details);
    for (let i in keys) {
      if (keys[i] === "street") {
        dispatch(change("customer_details", "street_address", details[keys[i]]))
      } 
      else if (keys[i] === "brgy") {
        dispatch(change("customer_details", "barangay", details[keys[i]]))
      } 
      else if (keys[i] === "zipcode") {
        dispatch(change("customer_details", "postal_code", details[keys[i]]))
      } 
      else if (keys[i] === "mobile_number") {
        dispatch(change("customer_details", "mobile_number", `0${details[keys[i]]}`))
      } 
      else {
        dispatch(change("customer_details", keys[i], details[keys[i]]))
      }
    }
    dispatch(StoreActions.setCustomerDetails({
        firstName: details.first_name,
        lastName: details.last_name,
        streetAddress: details.street,
        email: details.email,
        barangayAddress: details.brgy,
        cityAddress: details.city,
        provinceAddress: details.province,
        postalCode: details.zipcode,
        mobileNumber: `0${details.mobile_number}`
    }, ""))
  }

  const address = `${selectedAddress.street}, ${selectedAddress.brgy}, ${selectedAddress.city}, ${selectedAddress.province}${selectedAddress.zipcode ? ` ${selectedAddress.zipcode}`: ""}`;

  return (
    defaultAddress ? 
    <AddressDrawer value={selectedAddress} onSubmit={onSubmit}>
      {
        (setOpen) => (
          <div className={classes.root}>
            <div className="d-flex align-items-center">
              <div className="d-flex">
                <div style={{ textTransform: "capitalize" }}>
                  <Chip className={`mr-2 ${classes.chip} label-chip`} size="medium" label={selectedAddress.label} />
                </div>
                {
                  selectedAddress.is_default ?
                  <Chip className={classes.chip} label="Default" size="medium" variant="outlined" />
                  : ""
                }
              </div>
              <div className="d-flex ml-auto">
                <div className={`mr-2 edit-button d-flex align-items-center ${classes.button}`} onClick={setOpen}>
                  <PenSVG fill={theme.palette.primary.main} style={{height: 15, width: 15}} /> <p className="mb-0 ml-2">Change address</p>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex align-items-start">
                <p className={classes.label}>
                  Address
                </p>
                <p className={`address ${classes.value}`}>
                  {address}
                </p>
              </div>
              <div className="d-flex align-items-start flex-wrap">
                <p className={classes.label}>
                  Phone
                </p>
                <p className={`phone ${classes.value}`}>
                  +63{' '}{selectedAddress.mobile_number}
                </p>
              </div>
            </div>
          </div>
        )
      }
    </AddressDrawer>
    : ""
  );
};

export default AddressItem;