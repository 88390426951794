import React from 'react';
import { makeStyles, Button, IconButton, Dialog, Slide,
  AppBar, Toolbar } from "@material-ui/core";

const RadioButtons = (props) => {
  const [selected, setSelected ] = React.useState(props.value)
  const useStyles = makeStyles(theme => ({
    btn: {
      color: props.color || "#F97663",
      background: "white",
      borderRadius: 4,
      border: `1px solid ${props.color || "#F97663"}`,
      height: "35px",
      minWidth: 70,
      fontSize: 12,
      fontWeight: "normal"
    },
    selected: {
      backgroundColor: `${props.color || "#C24A39"} !important`,
      color: "white",
    }
  }));
  const classes = useStyles();
  const onChange = (selected) => {
    setSelected(selected)
    if (props.onChange) props.onChange(selected)
  }
  return (
    <div className="d-flex flex-wrap pb-2">
      {
        props.choices.map((choice, index) => (
          <Button size="small" className={`${classes.btn} flex-grow-1
            ${selected.value === choice.value ? classes.selected: ``} m-1`} 
            style={{

            }}
            key={index}
            onClick={() => onChange(choice)}
          >
            {choice.name}
          </Button>
        ))
      }
    </div>
  )
}

export default RadioButtons;