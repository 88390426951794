import React from "react";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery, Snackbar, Slide, Container } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import { useParams } from "react-router-dom";
import StoreActions from "../../../common/template/components/StoreActions";
import { hideAlert } from "../../../common/layout/mobile/webBuilder/websiteBuilderActions";
import Actions from "../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}
export default function Layout(props) {
  const useStyles = makeStyles(theme => ({
    container: {
      "&::-webkit-scrollbar": {
        width: 4
      },
      "&::-webkit-scrollbar-track": {
        background: "#eee"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#f97663"
      }
    }
  }));
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const common = useSelector(state => state.common);
  const store = useSelector(state => state.store.store);
  const filters = useSelector(state => state.quickOrder.products.filters);
  const products = useSelector(state => state.quickOrder.products.products);
  const [page, setPage] = React.useState(1);
  const [error, setError] = React.useState({
    open: false,
    alertType: "error",
    message: ""
  });

  const is_plain_layout = location.pathname.includes("/checkout");

  const isAuto = ["cart", "delivery", "customer_details"].some(i =>
    location.pathname.includes(i)
  );

  const closePopup = () => {
    dispatch(hideAlert());
  };
  // React.useEffect(() => {
  //   async function fetchData() {
  //     await dispatch(StoreActions.getMyStoreInfo(params.id));
  //   }
  //   fetchData();
  // }, []);

  const scrollListener = React.useCallback(
    e => {
      const maxScroll = e.target.scrollHeight - e.target.offsetHeight;
      const currentScroll = e.target.scrollTop;

      if (maxScroll === currentScroll) {
        if (page < products.total_pages) {
          console.log("desktop");
          dispatch(
            Actions.getProducts(
              store && store.uuid,
              { ...filters, page: page + 1 },
              "append"
            )
          );
          setPage(page + 1);
        }
      }
    },
    [page, products.total_pages]
  );

  React.useEffect(() => {
    setPage(1);
  }, [filters.category]);

  // React.useEffect(() => {
  //   if (store && store.shop_type !== "order") {
  //     history.push("/not-found");
  //   }
  // }, [store]);

  const plain = children => children;

  const quick_order_layout = (
    <div>
      {isMobile ? (
        <div>
          {" "}
          <Snackbar
            autoHideDuration={2000}
            open={common.showAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={closePopup}
            TransitionComponent={TransitionDown}
          >
            <MuiAlert severity={common.alertType}>{common.message}</MuiAlert>
          </Snackbar>
          <Header />
          <div style={{ padding: "8vh 0 12vh" }}>{props.children}</div>
        </div>
      ) : (
        <Container
          maxWidth="md"
          style={{
            padding: "0 8vw",
            height: isAuto ? "auto" : "100vh",
            overflowY: "scroll"
          }}
          className={classes.container}
          onScroll={scrollListener}
        >
          <Snackbar
            autoHideDuration={2000}
            open={common.showAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={closePopup}
            TransitionComponent={TransitionDown}
          >
            <MuiAlert severity={common.alertType}>{common.message}</MuiAlert>
          </Snackbar>
          <Header />
          <div style={{ padding: "12vh 0 0" }}>{props.children}</div>
        </Container>
      )}
    </div>
  );
  return is_plain_layout ? plain(props.children) : quick_order_layout;
}
