import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import history from "../../../../store/history";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import StoreActions from "./StoreActions";
import Footer from "./Footer";
import ProductTemplate from "./products/productTemplate";
import Header from "./AppBar";
import apiConfig from "./apiConfig";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import SideBar from "./SideBar";

const Products = props => {
  let id = props.id;

  const ProductStyles = makeStyles(theme => ({
    root: {
      marginTop: "10vh"
    },
    content1: {
      height: "100vh",
      width: "100%"
    },

    productCard: {
      minHeight: "100vh",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      margin: "0 63px",
      [theme.breakpoints.down("sm")]: {
        margin: "0"
      }
    }
  }));

  const classes = ProductStyles();
  const dispatch = useDispatch();
  let theme = useTheme();
  let mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  let storeInfo = useSelector(state => state.store.store);
  let productList = useSelector(state => state.store.products);
  const store_theme = useSelector(state => state.store.store.color);
  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold"
      },
      fontFamily: ["Inter", "sans-serif"]
    }
  });
  const location = useLocation();
  React.useEffect(() => {
    async function fetchData() {
      const category_filter = new URLSearchParams(location.search).get(
        "category"
      );
      await dispatch(
        StoreActions.getMyStoreInfo(id, {
          getProducts: !Boolean(category_filter)
        })
      );
    }
    fetchData();

    // GTM trigger
    if (productList.length > 0) {
      const maxProductLength = 30;
      while (productList.length) {
        const product = productList.splice(0, maxProductLength);
        window.dataLayer.push({
          event: "productImpressions",
          ecommerce: {
            impressions: product.map(product => ({
              id: product.uuid,
              name: product.name,
              price: product.price,
              [`dimension${apiConfig.indexStoreID}`]: product.store
            }))
          }
        });
      }
    }
  }, []);

  React.useEffect(() => {
    const urlParams = location.search.split("&");
    if (urlParams[0] === "?category=") {
      history.push(
        `/shop/${storeInfo.site}/products/?category=All&sortBy=-created_on`
      );
    }
  }, [location.search]);

  React.useEffect(() => {
    if (storeInfo.name && storeInfo.is_published === false) {
      history.push("/not-found");
    }
  }, [storeInfo]);

  function onSelect(value) {
    console.log(value);
    dispatch(StoreActions.getSelectedProduct(value));
    history.push(`/shop/${props.id}/viewproduct?uuid=${value.uuid}`);
  }

  const LeftistLayout = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    appBar: {
      display: "block",
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default
    }
  }));
  const leftistStyles = LeftistLayout();

  const component = () => {
    switch (storeInfo.template) {
      default:
        return (
          <div>
            {storeInfo && storeInfo.font.css_url ? (
              <link href={storeInfo.font.css_url} rel="stylesheet"></link>
            ) : (
              ""
            )}{" "}
            <ThemeProvider theme={store_generated_theme}>
              <Header
                isPreview={false}
                storeInfo={storeInfo}
                color={true}
                logo={true}
                shadow={true}
                logo={true}
              />
              <ProductTemplate
                onSelect={onSelect}
                store={storeInfo}
                productList={productList}
                mobileView={mobileView}
              />
              <Footer store={storeInfo} />
            </ThemeProvider>
          </div>
        );
      case "Leftist":
        return (
          <div className={leftistStyles.root}>
            <ThemeProvider theme={store_generated_theme}>
              {storeInfo && storeInfo.font.css_url ? (
                <link href={storeInfo.font.css_url} rel="stylesheet"></link>
              ) : (
                ""
              )}{" "}
              <SideBar isPreview={props.isPreview} storeInfo={storeInfo} />
              <main className={leftistStyles.content}>
                <div className={leftistStyles.appBar}>
                  <Header
                    isPreview={false}
                    storeInfo={storeInfo}
                    color={true}
                    logo={true}
                    shadow={true}
                  />
                </div>
                <ProductTemplate
                  onSelect={onSelect}
                  store={storeInfo}
                  productList={productList}
                  mobileView={mobileView}
                />
                <Footer store={storeInfo} />
              </main>
            </ThemeProvider>
          </div>
        );
    }
  };
  return component();
};

export default Products;
