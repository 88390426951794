import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  useMediaQuery,
  Button,
  useTheme,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import Form from "./components/orderDetails/form";
import OrderActions from "./orderActions";
import Path from "../../Paths";
const Transition = (props) => <Slide direction="up" {...props} />;
export default function OrderDetails(props) {
  const useStyles = makeStyles((theme) => ({}));
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const ref_id = location.search.replace("?id=", "");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const order = useSelector((state) => state.order.selectedOrder);
  React.useEffect(() => {
    if (ref_id) {
      window.scrollTo(0, 0);
      dispatch(OrderActions.setOrder(ref_id));
    } else {
      history.push(Path.ORDER_LIST_VIEW);
    }
  }, []);
  return (
    <>
      {order ? (
        isMobile ? (
          <div>
            <Dialog open={true} fullScreen TransitionComponent={Transition}>
              <AppBar
                className="bg-white border-bottom position-fixed"
                style={{ boxShadow: "none" }}
              >
                <Toolbar>
                  <IconButton
                    edge="start"
                    style={{ position: "absolute" }}
                    onClick={() => history.push(Path.MOBILE_ORDERS)}
                  >
                    <ArrowBack color="primary" />
                  </IconButton>
                  <div
                    className="text-center w-100"
                    style={{ fontSize: 16, fontWeight: 600, color: "#2b2d32" }}
                  >
                    Order details
                  </div>
                </Toolbar>
              </AppBar>
              <div className="mt-5">
                <Form />
              </div>
            </Dialog>
          </div>
        ) : (
          <div style={{ padding: "0 12vw" }}>
            <div className="d-flex position-relative align-items-center">
              <Button
                variant="text"
                color="primary"
                startIcon={<ArrowBack color="primary" />}
                style={{ zIndex: 3 }}
                onClick={() => history.push(Path.ORDER_LIST_VIEW)}
              >
                Back
              </Button>
              <div
                className="position-absolute w-100 text-center"
                style={{ fontSize: 28, fontWeight: 600, color: "#002c42" }}
              >
                Order details
              </div>
            </div>
            <div className="mt-3">
              <Form />
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </>
  );
}
