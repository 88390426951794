import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Slide,
  Dialog,
  Button,
  AppBar,
  IconButton,
  Toolbar
} from "@material-ui/core";
import {
  Computer,
  Smartphone,
  ZoomOutMap,
  ExpandMore,
  CheckCircle,
  ArrowBack,
  Close
} from "@material-ui/icons";
import { templates } from "../../../../../websiteBuilder/desktop/components/shopStylesForm/templateDisplayImage";
import TemplateCard from "../../../../../websiteBuilder/desktop/components/shopStylesForm/templateCard";
import PreviewShop from "./previewShop";
import TemplateConfirmationDialog from "../../../../../websiteBuilder/desktop/components/shopStylesForm/confirmationDialog";
import { patchStore } from "../websiteBuilderActions";
import PreviewTemplate from "../../../../../websiteBuilder/desktop/forms/previewTemplate";
const DialogTransition = props => <Slide direction="up" {...props} />;

export default function TemplateLibrary(props) {
  const { values, setValues, state, setState } = props;
  const useStyles = makeStyles(theme => ({
    appbar: {
      height: 55,
      position: "relative",
      backgroundColor: "#fff",
      boxShadow: "none",
      borderBottom: "1px solid #d1d5dd"
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    closeBtn: { position: "absolute", left: 15 }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const productList = useSelector(state => state.mobileDashboard.products.list);
  const subscription = useSelector(state => state.subscription.subscription);
  const [open, setOpen] = React.useState({
    dialog: false,
    preview: false,
    template: ""
  });
  const handleViewDemo = template => {
    // setValues({ ...values, template: template });
    setOpen({ ...open, preview: true, template: template });
  };

  const handleUseTemplate = async () => {
    const res = await dispatch(
      patchStore(
        values.site,
        {
          template: values.template
        },
        {
          image_crop_area: values.image_crop_area,
          image_settings: values.image_settings,
          font: values.font
        }
      )
    );

    try {
      if (res.status === 200) {
        setOpen({
          ...open,
          dialog: false,
          success_dialog: true,
          is_success: true
        });
        setValues({
          ...values,
          template_library_open: false
        });
      }
    } catch (error) {
      setValues({
        ...values,
        template_library_open: false
      });
      setOpen({
        ...open,
        dialog: false,
        success_dialog: true,
        is_success: false
      });
    }
  };
  return (
    <Dialog
      fullScreen
      open={props.values.template_library_open}
      TransitionComponent={DialogTransition}
    >
      <div>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.closeBtn}
              onClick={() =>
                props.setValues({
                  ...props.values,
                  template_library_open: false
                })
              }
            >
              <ArrowBack style={{ color: "#f97663" }} />
            </IconButton>
            <div className={classes.header}>Template library</div>
          </Toolbar>
        </AppBar>
        <div style={{ padding: 24, backgroundColor: "#fafafa" }}>
          <div style={{ fontSize: 14 }}>
            Choose from any of the following free templates
          </div>
        </div>
        <div style={{ backgroundColor: "#fff" }}>
          {templates.map(item => (
            <TemplateCard
              item={item}
              isMobile={true}
              handleViewDemo={handleViewDemo}
              open={open}
              setOpen={setOpen}
              setValues={setValues}
              values={values}
              subscription={subscription}
            />
          ))}
        </div>
      </div>
      <PreviewTemplate open={open} setOpen={setOpen} />
      {/* <PreviewShop
        open={open}
        setOpen={setOpen}
        values={values}
        setValues={setValues}
        productList={productList}
      /> */}
      <TemplateConfirmationDialog
        open={open}
        setOpen={setOpen}
        handleUseTemplate={handleUseTemplate}
      />
    </Dialog>
  );
}
