const storeInfoContent = {
  English: {
    shopHeader: "How do we call your shop?",
    shopSubheader:
      "Please indicate your online shop’s name and website. Once you submit and go to the next page, these will be exclusively assigned to you and may no longer be edited.",
    storeName: "What's the name of your shop?",
    storeSite: "What will be your online shop's website?",
    storeSlogan: "What is your shop's slogan or tagline?",
    sloganSubheader: 'Examples are "Bida ang Saya" or "Love ko \'to".',
    storeAbout: "Please tell us a bit about your business.",
    aboutSubheader:
      "It could be a short history or description about your business or product"
  },
  Filipino: {
    shopHeader: "Anong itatawag namin sa shop mo?",
    shopSubheader:
      "Ilagay ang pangalan ng iyong online shop at and gusto mong website link. Kapag nasubmit mo na itong dalawa at mag-next page ka, ikaw lang ang tanging may Sentro Shop at link na may ganyang pangalan at hindi mo na ito pwedeng palitan.",
    storeName: "Anong pangalan ng store mo?",
    storeSite: "Anong gusto mong website link para sa online shop mo?",
    storeSlogan: "Anong slogan o tagline ng store mo?",
    sloganSubheader: 'Halimbawa: "Bida ang Saya" o "Love ko to"',
    storeAbout: "Kwentuhan mo kami tungkol sa iyong business",
    aboutSubheader:
      "Pwede mong ilagay rito kung paano kayo nagsimula o kung ano ang business mo o mga binebenta mo"
  }
};

export default storeInfoContent;
