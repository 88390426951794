import React from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
// import { ReactComponent as PenIcon } from '../../../../../../assets/images/enterprise/pen.svg';
// import { ReactComponent as LinkIcon } from '../../../../../../assets/images/enterprise/link.svg';
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import validations from '../../../../../common/validations';
// import LargeImageUploader from '../largeImageUpload';
// import DashboardActions from '../../../../redux/actions/dashboardActions';
import { useDispatch, useSelector } from "react-redux";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { showAlert } from "../../../common/commonActions";
import Actions from "../../redux/actions";
import NinjaVan from "../ninjavan/form";
const useStyles = makeStyles(theme => ({
  label2: {
    color: "#54575f",
    fontSize: 14,
    fontWeight: 500
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)"
  },
  note: {
    background: "#EEEEEE",
    borderRadius: 2,
    fontSize: 12,
    padding: "1em 1.5em",
    marginTop: "1em"
  }
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 40, //28,
    height: 24, //16,
    padding: 0,
    display: "flex",
    marginRight: 5
  },
  switchBase: {
    padding: 4,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500]
  },
  checked: {}
}))(Switch);

const Courier = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const stores = useSelector(state => state.dashboard.stores);
  const deliverySettings = useSelector(state => state.deliverySettings);

  // switches
  const [mrspeedy, setMrspeedy] = React.useState(false);
  const onChange = checked => {
    if (deliverySettings.pickup_addresses.count > 0) {
      setMrspeedy(checked);
      dispatch(
        Actions.saveCourier("mrspeedy", {
          id: deliverySettings.couriers.mrspeedy.length
            ? deliverySettings.couriers.mrspeedy[0].id
            : null,
          enabled: checked
        })
      );
    } else {
      setTimeout(() => {
        dispatch(
          showAlert({
            type: "warning",
            message:
              "Please set a pick-up address before enabling this feature."
          })
        );
      }, 100);
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      if (stores.length) {
        await dispatch(Actions.getNinjavan());
        let res = await dispatch(Actions.getMrspeedy());
        try {
          if (res.status === 200) {
            setMrspeedy(res.data[0].enabled);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    fetchData();
  }, [stores]);

  return (
    <>
      <div className={`p-3 p-md-5`}>
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Typography color="primary">
                  <strong>Borzo</strong>
                </Typography>
                <div
                  className={`${classes.label2} ml-2`}
                  style={{ color: "#54575f" }}
                >
                  (Mr. Speedy)
                </div>
              </div>
              <FormControlLabel
                className="m-0"
                control={
                  <AntSwitch
                    checked={mrspeedy}
                    onChange={e => onChange(e.target.checked)}
                    name="category"
                  />
                }
              />
            </div>
            <div className={`d-block my-3 ${classes.label2}`}>
              Enable <strong>same-day delivery</strong> within Metro Manila
            </div>
          </div>
          <div className="col-12 mt-md-0">
            <p className={classes.note}>
              <strong>Please note:</strong> By enabling Borzo, delivery rates
              will be auto-computed upon checkout but will not include total
              package weight and type of vehicle.
            </p>
          </div>
        </div>
      </div>
      {/* <NinjaVan pickup_addresses={deliverySettings.pickup_addresses} /> */}
    </>
  );
};

export default Courier;
