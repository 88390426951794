import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as FBIcon } from "../../../../assets/images/enterprise/fb.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/images/enterprise/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../../../assets/images/enterprise/twitter.svg";
export default function MerchantSection() {
  const merchant = useSelector(state => state.enterprise.main.merchant);
  const enterpriseTheme = useSelector(state => state.enterprise.main.theme);
  // const useStyles = makeStyles(theme => ({
  // }));
  // const classes = useStyles();

  return (
    merchant ? 
    <div className="row">
      <div className="col-12 col-md-6" style={{
        backgroundImage: `url(${merchant.cover_image})`,
        backgroundSize: "cover",
        backgroundPosition: "center", 
        minHeight: 400
      }} />
      <div className="col-12 col-md-6 p-5" style={{
        backgroundColor: enterpriseTheme.palette.secondary.main,
        color: enterpriseTheme.palette.secondary.contrastText,
        minHeight: 400
      }}>
        <div className="py-2 px-4 d-flex flex-column">
          <h4 className="mb-3 d-block d-md-none" style={{
            color: enterpriseTheme.palette.secondary.contrastText
          }}>About Us</h4>
          <h2 className="mb-3 d-none d-md-block" style={{
            color: enterpriseTheme.palette.secondary.contrastText
          }}>About Us</h2>
          <p>{merchant.description}</p>
          <div className="d-flex mt-auto">
            {merchant.facebook ? (
              <IconButton
                className="mr-3"
                onClick={() =>
                  window.open(
                    `https://facebook.com/${merchant.facebook}`,
                    "_blank"
                  )
                }
              >
                <FBIcon
                  width="25"
                  height="25"
                  fill={enterpriseTheme.palette.secondary.contrastText}
                />
              </IconButton>
            ) : (
              ""
            )}
            {merchant.instagram ? (
              <IconButton
                className="mr-3"
                onClick={() =>
                  window.open(
                    `https://instagram.com/${merchant.instagram}`,
                    "_blank"
                  )
                }
              >
                <InstagramIcon
                  width="25"
                  height="25"
                  fill={enterpriseTheme.palette.secondary.contrastText}
                />
              </IconButton>
            ) : (
              ""
            )}
            {merchant.twitter ? (
              <IconButton
                onClick={() =>
                  window.open(
                    `https://twitter.com/${merchant.twitter}`,
                    "_blank"
                  )
                }
              >
                <TwitterIcon
                  width="25"
                  height="25"
                  fill={enterpriseTheme.palette.secondary.contrastText}
                />
              </IconButton>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
    : ""
  );
}
