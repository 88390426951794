import axios from 'axios';
import apiConfig from '../../../../../common/apiConfig';
import {
  handleError,
  handleGetCurrentWallet,
} from '../../../../../utils/Metamask';

class Services {
  static async CONNECT_WALLET(payload) {
    try {
      let response = await handleGetCurrentWallet();
      return response;
    } catch (error) {
      throw error.message;
    }
  }

  static async MAP_WALLET(token, walletAddress, signature) {
    try {
      var data = JSON.stringify({
        token: token,
        walletAddress: walletAddress,
        symbol: 'USDC',
        signature: signature,
      });

      var config = {
        method: 'post',
        url: `${apiConfig.nftApiUrl}nfts/wallet-address-mapping`,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: data,
      };
      const res = await axios.request(config);
      return res;
    } catch (error) {
      if (error?.response?.data?.result?.details?.message) {
        handleError(error?.response?.data?.result?.details?.message);
      }

      if (error?.response?.data?.result?.message) {
        handleError(error?.response?.data?.result?.message);
      }

      if (error === undefined) {
        handleError(
          `Please login first to ${apiConfig.alamatSiteUrl} then try to reconnect wallet.`
        );
      }
      return false;
    }
  }

  static async UPLOAD_IMAGE_IPFS(payload) {
    try {
      let response = await axios.post(
        `${apiConfig.nftApiUrl}ipfs/file`,
        payload
      );

      return response;
    } catch (error) {
      throw error.message;
    }
  }

  static async BUILD_METADATA_IPFS(payload) {
    try {
      let response = await axios.post(
        `${apiConfig.nftApiUrl}ipfs/object`,
        payload
      );
      return response;
    } catch (error) {
      if (typeof error == 'object') handleError(error.message);
      else handleError(error);
      throw error.message;
    }
  }

  static async CREATE_ORDER(params, uuid) {
    try {
      let res = await axios.post(
        `${apiConfig.apiURL}/api/order/${uuid}/`,
        params
      );
      return res;
    } catch (error) {
      if (typeof error == 'object') handleError(error.message);
      else handleError(error);
      throw error.message;
    }
  }

  static async GET_AUTH_TOKEN_SENTRO() {
    try {
      let value = {
        email: apiConfig.storeUsername,
        password: apiConfig.storeUserPassword,
      };
      let response = await axios.post(`${apiConfig.apiURL}/api/login/`, value);
      return response.data.token;
    } catch (error) {
      throw error.message;
    }
  }
  static async CANCEL_ORDER(orderid, token) {
    try {
      let res = await axios.put(
        `${apiConfig.apiURL}/api/cancel-order/${orderid}/`,
        {},
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );
      return res;
    } catch (error) {
      if (typeof error == 'object') handleError(error.message);
      else handleError(error);
      throw error.message;
    }
  }

  static async UPDATE_ORDER(orderid, token) {
    try {
      let res = await axios.put(
        `${apiConfig.apiURL}/api/order/${orderid}/`,
        {
          paid: true,
          channel: 'PayMaya',
        },
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );
      return res;
    } catch (error) {
      if (typeof error == 'object') handleError(error.message);
      else handleError(error);
      throw error.message;
    }
  }

  static async REQUEST_ACCESS_TOKEN() {
    try {
      var config = {
        method: 'post',
        url: `${apiConfig.mawbebsApiUrl}orchestrator/oauth2/token`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${apiConfig.mawbebsAuthKey}`,
          Cookie: 'XSRF-TOKEN=11926ee3-7c83-482a-a2b6-156a4be776dd',
        },
        data: 'grant_type=client_credentials&scope=orchestrate/channel',
      };

      const res = await axios.request(config);

      return res;
    } catch (error) {
      if (typeof error == 'object') handleError(error.message);
      else handleError(error);
      throw error.message;
    }
  }

  static async REQUEST_MINT_PERMIT(buyerAddress, sellerAddress, token) {
    var data = {
      channelId: apiConfig.mawbebsChannelId,
      buyerAddress: buyerAddress,
      sellerAddress: sellerAddress,
    };
    var config = {
      method: 'post',
      url: `${apiConfig.mawbebsApiUrl}orchestrator/nft/mintpermit`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': ' application/json',
      },
      data: data,
    };
    const res = await axios.request(config);
    return res;
  }

  static async GET_MINT_PERMIT(buyer, seller) {
    try {
      const res = await Services.REQUEST_ACCESS_TOKEN();
      if (res) {
        const metadata = await Services.REQUEST_MINT_PERMIT(
          buyer,
          seller,
          res.data.result.access_token
        );
        return metadata;
      } else {
        throw new Error('failed to get access token');
      }
    } catch (error) {
      if (typeof error == 'object') handleError(error.message);
      else handleError(error);
      throw error;
    }
  }

  static async SEND_NFT_POSTBACK(payload) {
    try {
      const response = await axios.post(
        `${apiConfig.nftApiUrl}nfts/postback`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async SEND_ALAMATCH_POSTBACK(payload) {
    try {
      const response = await axios.post(
        `${apiConfig.alamatApiUrl}callback/purchase-notify-status`,
        payload
      );

      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
}
export default Services;
