import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import Text from "../../../../common/text";
import history from "../../../../store/history";
import Path from "../../../../Paths";
const Footer = props => {
  const FooterStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      padding: 15,
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "center"
      }
    },
    tou: {
      fontSize: "14px",
      fontWeight: "600",
      color: "#2B2D33",
      marginLeft: "6vw",
      [theme.breakpoints.down("sm")]: {
        margin: 0
      }
    },
    questions: {
      fontSize: "14px",
      marginLeft: "26%",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
        fontSize: "14px"
      }
    }
  }));

  const classes = FooterStyles();
  return (
    <Box className={classes.root}>
      <Text
        textType="p2"
        content={`Copyright © Sentro ${new Date().getFullYear()}`}
      />
      <a
        id="terms_of_use"
        onClick={() => history.push(Path.TNC)}
        className={classes.tou}
      >
        Terms of Use
      </a>
      <Typography className={classes.questions}>
        Got questions? Send us a message at sentro@ubx.ph
      </Typography>
    </Box>
  );
};
export default Footer;
