import React from "react";
import AboutUs from "./components/AboutUs";
import "./App.css";

const AboutusPage = props => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <AboutUs />;
};

export default AboutusPage;
