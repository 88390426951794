import { showAlert } from "../../common/commonActions";
import SubscriptionServices from "./services";
import moment from "moment";
export const actionTypes = {
  GET_SUBSCRIPTION: "GET_SUBSCRIPTION",
  CLOSE_BANNER: "CLOSE_BANNER",
  POST_SUBSCRIPTION_PAYMENT: "POST_SUBSCRIPTION_PAYMENT",
  POST_SUBSCRIPTION_PAYMENT_SUCCESS: "POST_SUBSCRIPTION_PAYMENT_SUCCESS",
  POST_SUBSCRIPTION_PAYMENT_FAILED: "POST_SUBSCRIPTION_PAYMENT_FAILED"
};

class SubscriptionActions {
  static getSubscription() {
    return async dispatch => {
      try {
        let res = await SubscriptionServices.getSubscription();
        dispatch({ type: actionTypes.GET_SUBSCRIPTION, payload: res.data });
        return res;
      } catch (error) {
        console.log(error);
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    };
  }

  static closeDashboardBanner() {
    return async dispatch => {
      dispatch({ type: actionTypes.CLOSE_BANNER });
    };
  }

  static subscriptionPayment(params) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.POST_SUBSCRIPTION_PAYMENT });
        let res = await SubscriptionServices.subscriptionPayment(params);
        dispatch({
          type: actionTypes.POST_SUBSCRIPTION_PAYMENT_SUCCESS,
          payload: res.data
        });
        return res;
      } catch (error) {
        console.log(error);
        dispatch({ type: actionTypes.POST_SUBSCRIPTION_PAYMENT_FAILED });
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    };
  }
}

export default SubscriptionActions;
