import React from "react";
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import LensIcon from '@material-ui/icons/Lens';
import history from "../../../store/history";
import Path from "../../../Paths";
import AppBarTemplate from "../../common/template/components/appBar/appBarTemplate";


const useStyles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    padding: "0.75em",
    display: "flex",
    marginTop: "1em",
    border: "1px solid #e6eaf0d1",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: 4
  },
  logo: {
    height: "auto",
    height: 120,
    width: 120,
    backgroundColor: "rgba(0,0,0,0.02)"
  },
  infoWrapper: {
    paddingLeft: "1.5em",
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  titleAndButtonWrapper: {
    display: "flex",
    width: "100%"
  },
  title: {
    color: "#2B2D33",
    fontSize: 18,
    marginBottom: 0,
    marginRight: "auto",
    fontWeight: 600
  },
  button: {
    padding: "0 1.5em",
    height: 30,
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 5
  },
  description: {
    color: "#54575F",
    fontSize: 16,
    fontWeight: 400,
    paddingRight: "5em"
  },
  footerWrapper: {
    display: "flex",
    fontSize: 12,
    color: "#2b2d337d",
    fontWeight: 400,
    paddingTop: "1em",
    alignItems: "center",
    marginTop: 'auto'
  },
  footerText: {
    marginBottom: 0
  },
  footerDotDivider: {
    fontSize: 5,
    color: "#2b2d337d",
    margin: "0 5px"
  },
  deleteBtn: {
      color: "red",
  }
});

class AppCard extends React.Component {
  
  render (){
    const { classes, data, link, editButtonVisible, editLink } = this.props;
    return (
      <div className={classes.root}>
        <img src={data.logo} className={classes.logo} />
        <div className={classes.infoWrapper}>
          <div className={classes.titleAndButtonWrapper}>
            <p className={classes.title}>{data.name}</p>
            {
              (editButtonVisible || false) && <Button
                id="view"
                color="primary"
                disableElevation 
                className={`${classes.button}`}
                variant="outlined"
                disableElevation={true}
                onClick={() => {
                 this.props.onClickDelete()
                }}
              >
                DELETE
              </Button>
            }
            {
              (editButtonVisible || false) && <Button
                id="view"
                color="primary"
                disableElevation 
                className={classes.button}
                variant="outlined"
                disableElevation={true}
                onClick={() => {
                history.push(`/admin/marketplace/${data.id}`);
                }}
              >
                EDIT
              </Button>
            }
            <Button
              id="view"
              color="primary"
              disableElevation 
              className={classes.button}
              variant="contained"
              disableElevation={true}
              onClick={() => {
                history.push(`/marketplace/${data.id}`);
              }}
            >
              VIEW
            </Button>
          </div>
          <div  className={classes.description}>
            {data.short_description}
          </div>
          <div  className={classes.footerWrapper}>
            <p className={classes.footerText}>{
                data.category_names.map((category, index) => {
                    return data.category_names.length-1 > index ? category + ", " : category
                })
            }</p>
            <LensIcon className={classes.footerDotDivider} />
            <p className={classes.footerText}>{data.supported_devices}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(useStyles)(AppCard);
