import React from "react";

function setDefaultImage(template, fieldname) {
  if (fieldname === "cover_image") {
    if (template === "Classic") {
      return "https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/template-default/classicBanner.png";
    } else if (template === "Contemporary") {
      return "https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/template-default/contemporaryBanner.png";
    } else if (template === "Minimalist") {
      return "https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/template-default/minimalistBanner.png";
    } else if (template === "ClassicV2") {
      return "https://templates-sentro.s3.amazonaws.com/Defaultv2/classicV2Banner.png";
    } else if (template === "ContemporaryV2") {
      return "https://templates-sentro.s3.amazonaws.com/Defaultv2/contemporaryV2Banner.png";
    }
  } else if (fieldname === "aboutus_image") {
    if (template === "Classic") {
      return "https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/template-default/classicAbout.png";
    } else if (template === "Contemporary") {
      return "https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/template-default/contemporaryAbout.png";
    } else if (template === "Minimalist") {
      return "https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/template-default/minimalistAbout.png";
    } else if (template === "ClassicV2") {
      return "https://templates-sentro.s3.amazonaws.com/Defaultv2/classicV2About.png";
    } else if (template === "ContemporaryV2") {
      return "https://templates-sentro.s3.amazonaws.com/Defaultv2/contemporaryV2About.png";
    }
  } else if (fieldname === "contactus_image") {
    if (template === "Classic") {
      return "https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/template-default/classicFooter.png";
    } else if (template === "Contemporary") {
      return "https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/template-default/contemporaryFooter.png";
    } else if (template === "Minimalist") {
      return "https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/template-default/minimalistFooter.png";
    }
  }
}

export default setDefaultImage;
