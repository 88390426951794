import axios from "axios";
import apiConfig from "../../../../common/apiConfig";
import moment from "moment";

class Services {
  static async getFonts() {
    try {
      let response = await axios.get(
        `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyC-zXqrYCK7fx5BuuzrzERQhyT7sLV72iA`
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async patchMarketplace(name, data) {
    try {
      let formData = new FormData();
      const dataKeys = Object.keys(data);
      for (let i in dataKeys) {
        const key = dataKeys[i];
        if (["logo", "ad_image", "company_photo", "favicon"].includes(key)) {
          const val = data[key];
          if (typeof val !== "string") {
            formData.append(key === "logo" ? "logo_image" : key, val);
          }
        }
        if (key === "banners") {
          const images = data[key];
          for (let i in images) {
            formData.append(`banner_image-${parseInt(i) + 1}`, images[i]);
          }
        }
      }

      delete data["ad_image"];
      delete data["banners"];
      delete data["company_photo"];
      delete data["favicon"];

      let response = await axios.patch(
        `${apiConfig.apiURL}/api/enterprise/marketplace/${name}/`,
        data,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      response = await axios.patch(
        `${apiConfig.apiURL}/api/enterprise/marketplace/${response.data.site_name}/`,
        formData,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async favoriteGlobalCategory(id, marketplace, is_favorite) {
    try {
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/enterprise/global_category/${id}/`,
        { is_favorite: is_favorite, marketplace: marketplace },
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async getMerchants(enterprise) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/merchants`,
        {
          params: {
            admin: true
          }
        }
        // {
        //   headers: {
        //     Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        //   }
        // }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async patchMerchant(data) {
    try {
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/enterprise/merchants/${data.id}/`,
        data,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteMerchant(data) {
    try {
      await axios.delete(
        `${apiConfig.apiURL}/api/enterprise/merchants/${data.id}/`,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return true;
    } catch (error) {
      throw error;
    }
  }

  static async inviteMerchant(enterprise, params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/merchants/invite/`,
        params,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async createS3PreSignedUrl(object_name) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/create_s3_presigned_url/`,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          },
          params: {
            object_name
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async uploadS3(signed_url_data, file) {
    try {
      let data = new FormData();
      const urlKeys = Object.keys(signed_url_data.fields);
      for (let i in urlKeys) {
        data.append(urlKeys[i], signed_url_data.fields[urlKeys[i]]);
      }
      data.append("file", file);
      await axios.post(signed_url_data.url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-amz-acl": "public-read"
        }
      });
      return `${signed_url_data.url}${signed_url_data.fields.key}`.replace(
        / /g,
        "%20"
      );
    } catch (error) {
      throw error.message;
    }
  }

  static async deleteGlobalCategory(id, payload) {
    try {
      await axios.delete(
        `${apiConfig.apiURL}/api/enterprise/global_category/${id}/`,
        {
          data: payload,
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return true;
    } catch (error) {
      throw error;
    }
  }

  static async patchGlobalCategory(id, payload) {
    try {
      let formData = new FormData();
      const dataKeys = Object.keys(payload);
      dataKeys.forEach(key => {
        if (key === "display_image") {
          if (typeof payload[key] !== "string") {
            formData.append(key, payload[key]);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const res = await axios.patch(
        `${apiConfig.apiURL}/api/enterprise/global_category/${id}/`,
        formData,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return res;
    } catch (error) {
      throw error;
    }
  }

  static async createGlobalCategory(payload) {
    try {
      let formData = new FormData();
      const dataKeys = Object.keys(payload);
      dataKeys.forEach(key => {
        formData.append(key, payload[key]);
      });
      const res = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/global_category/`,
        formData,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return res;
    } catch (error) {
      throw error;
    }
  }

  static async getBuyers(enterprise, params) {
    params = {
      ...params,
      page: params.page + 1,
      sortBy: params.sortBy === "Sort by" ? "" : params.sortBy
    };
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/buyers/`,
        {
          params,
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async patchProfile(data) {
    try {
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/enterprise/user/update/`,
        data,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async changePassword(enterprise, data) {
    try {
      let response = await axios.put(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/change_password/`,
        data,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );

      let current_user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
      sessionStorage.setItem(
        "CURRENT_USER",
        JSON.stringify({
          ...current_user,
          password_change: moment(new Date())
        })
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getAnalytics(enterprise) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/analytics/`,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getMerchantSales(enterprise, params, is_csv, filename) {
    let sort;
    let extra_req_props = is_csv && {
      responseType: "blob"
    };
    if (params.sort === "asc") {
      if (params.sort_by === "Sort by") {
        sort = "";
      } else if (params.sort_by === "fulfillment") {
        sort = "paid_orders_count";
      } else if (params.sort_by === "sales") {
        sort = "paid_orders_sum";
      } else if (params.sort_by === "merchant") {
        sort = "merchant__name";
      }
    } else if (params.sort === "desc") {
      if (params.sort_by === "Sort by") {
        sort = "";
      } else if (params.sort_by === "fulfillment") {
        sort = "-paid_orders_count";
      } else if (params.sort_by === "sales") {
        sort = "-paid_orders_sum";
      } else if (params.sort_by === "merchant") {
        sort = "-merchant__name";
      }
    }
    params = {
      ...params,
      page: params.page + 1,
      category: params.category === "Category" ? "" : params.category,
      sort_by: sort
    };
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/analytics/merchants/`,
        {
          params,
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          },
          ...extra_req_props
        }
      );
      if (is_csv) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getMerchantDetailed(
    enterprise,
    params,
    merchant_id,
    is_csv,
    filename
  ) {
    let sort;
    let extra_req_props = is_csv && {
      responseType: "blob"
    };
    if (params.sort === "asc") {
      sort = "channel";
    } else if (params.sort === "desc") {
      sort = "-channel";
    }
    params = {
      ...params,
      page: params.page + 1,
      channel: params.channel === "Channel" ? "" : params.channel,
      status: params.status === "Status" ? "" : params.status,
      sort_by: sort
    };
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/analytics/merchants/${merchant_id}/`,
        {
          params,
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          },
          ...extra_req_props
        }
      );
      if (is_csv) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
      return response;
    } catch (error) {
      throw error;
    }
  }
}
export default Services;
