import { RegisterActionTypes } from "./registerActions";

const initialState = {
  user: {},
  resendSuccess: false,
  loading: false,
  error: ""
};

export default function regReducers(state = initialState, action) {
  switch (action.type) {
    case RegisterActionTypes.REGISTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RegisterActionTypes.DONE:
      return {
        ...state,
        loading: false
      };
    case RegisterActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    case RegisterActionTypes.REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        user: {}
      };
    case RegisterActionTypes.VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RegisterActionTypes.VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    case RegisterActionTypes.VERIFICATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case RegisterActionTypes.RESEND_SUCCESS:
      console.log("true");
      return {
        ...state,
        loading: false,
        resendSuccess: true
      };

    default:
      return initialState;
  }
}
