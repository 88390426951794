import axios from "axios";
import apiConfig from "../../../../common/apiConfig";
import history from "../../../../store/history";

class Services {
  static async authenticate(value) {
    try {
      let response = await axios.post(`${apiConfig.apiURL}/api/login/`, value);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async getStores(token, email) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/dashboard/store/`,
        {
          headers: {
            Authorization: `JWT ${token}`
          },
          params: {
            email
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async applyMerchant(enterprise, token, params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/merchants/apply/`, params,
        {
          headers: {
            Authorization: `JWT ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }



  static async fbSignup(value) {
    let params = {
      provider: "facebook",
      access_token: value.accessToken
    };
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/oauth/login/`,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
export default Services;