import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import MaterialUIAlert from "../../../common/material-ui-alert";

export default function Extended(props) {
  const subscription = useSelector(state => state.subscription.subscription);
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let is_extended_notified = localStorage.getItem(
    `${user.email}:subscription_extension_notified`
  );

  const [show_is_extended, set_show_is_extended] = React.useState(
    is_extended_notified
  );

  const handleClose = () => {
    if (subscription.is_extended && !is_extended_notified) {
      set_show_is_extended(true);
      localStorage.setItem(
        `${user.email}:subscription_extension_notified`,
        true
      );
    }
  };
  return (
    <div>
      {typeof show_is_extended === "object" && subscription.is_extended ? (
        <div className="pb-3 px-md-0 px-3 pt-md-0 pt-3">
          <MaterialUIAlert
            type={"info"}
            icon={false}
            message={
              <div>
                To celebrate another year with Sentro, your{" "}
                <strong>Free Trial</strong> has been extended until{" "}
                <strong>{moment(subscription.expiry_date).format("MMMM D, YYYY")}</strong>.
              </div>
            }
            style={{ border: "1px solid #3a73cc", color: "#25459b" }}
            onClose={handleClose}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
