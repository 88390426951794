import axios from "axios";
import apiConfig from "../../../common/apiConfig";

class Services {
  static async getPickupAddress(params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/pickup-addresses/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
          },
          params: {
            ...params,
            page: params.page,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  static async savePickupAddress(data) {
    try {
      let response;
      if (data.id) {
        response = await axios.patch(
          `${apiConfig.apiURL}/api/pickup-addresses/${data.id}/`,
          data,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
            },
            params: {
              store_uuid: data.store,
            },
          }
        );
      } else {
        response = await axios.post(
          `${apiConfig.apiURL}/api/pickup-addresses/`,
          data,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
            },
          }
        );
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getDeliveryLocations(store_uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/delivery-locations/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
          },
          params: {
            store_uuid,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  static async saveDeliveryLocation(data) {
    try {
      let response;
      if (data.id) {
        response = await axios.patch(
          `${apiConfig.apiURL}/api/delivery-locations/${data.id}/`,
          data,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
            },
            params: {
              store_uuid: data.store,
            },
          }
        );
      } else {
        response = await axios.post(
          `${apiConfig.apiURL}/api/delivery-locations/`,
          data,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
            },
          }
        );
      }
      return response;
    } catch (error) {
      throw error;
    }
  }
  static async deleteDeliveryLocation(item) {
    try {
      await axios.delete(
        `${apiConfig.apiURL}/api/delivery-locations/${item.id}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
          },
          params: {
            store_uuid: item.store,
          },
        }
      );
      return true;
    } catch (error) {
      throw error;
    }
  }

  static async getMrspeedy() {
    try {
      let res = await axios.get(`${apiConfig.apiURL}/api/mrspeedy/settings/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  static async getNinjavan() {
    try {
      let res = await axios.get(`${apiConfig.apiURL}/api/ninjavan/settings/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  static async getBuxSubwallet() {
    try {
      let res = await axios.get(`${apiConfig.apiURL}/api/bux/subwallet/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }

  static async createBuxSubwallet() {
    try {
      let res = await axios.post(
        `${apiConfig.apiURL}/api/bux/subwallet/`,
        {},
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
          },
        }
      );
      return res;
    } catch (error) {
      throw error;
    }
  }
  static async saveCourier(courier, params) {
    try {
      let res;
      if (params.id) {
        res = await axios.patch(
          `${apiConfig.apiURL}/api/${courier}/settings/${params.id}/`,
          params,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
            },
          }
        );
      } else {
        res = await axios.post(
          `${apiConfig.apiURL}/api/${courier}/settings/`,
          params,
          {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
            },
          }
        );
      }

      return res;
    } catch (error) {
      throw error.response;
    }
  }

  static async showPickupAddresses(values, show_pickup_address) {
    try {
      let axiosRequests = [];
      values.forEach((data) => {
        //patch
        axiosRequests.push(
          axios.patch(
            `${apiConfig.apiURL}/api/pickup-addresses/${data.id}/`,
            { ...data, show_pickup_address },
            {
              headers: {
                Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
              },
              params: {
                store_uuid: data.store,
              },
            }
          )
        );
      });
      axios
        .all(axiosRequests)
        .then(
          axios.spread((...responses) => {
            return responses;
          })
        )
        .catch((errors) => {
          console.log(errors);
        });
    } catch (e) {
      throw e.response;
    }
  }

  static async setDefaultPickupAddress(values) {
    try {
      let axiosRequests = [];
      values.forEach((data) => {
        axiosRequests.push(
          axios.patch(
            `${apiConfig.apiURL}/api/pickup-addresses/${data.id}/`,
            { ...data },
            {
              headers: {
                Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
              },
              params: {
                store_uuid: data.store,
              },
            }
          )
        );
      });
      axios
        .all(axiosRequests)
        .then(
          axios.spread((...responses) => {
            return responses;
          })
        )
        .catch((errors) => {
          console.log(errors);
        });
    } catch (e) {
      throw e.response;
    }
  }

  static async deletePickupAddresses(values) {
    try {
      let axiosRequests = [];
      values.forEach((data) => {
        //delete
        axiosRequests.push(
          axios.delete(`${apiConfig.apiURL}/api/pickup-addresses/${data.id}/`, {
            headers: {
              Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
            },
            params: {
              store_uuid: data.store,
            },
          })
        );
      });
      axios
        .all(axiosRequests)
        .then(
          axios.spread((...responses) => {
            return responses;
          })
        )
        .catch((errors) => {
          console.log(errors);
        });
    } catch (e) {
      throw e.response;
    }
  }
}
export default Services;
