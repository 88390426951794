import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Button, FormHelperText, InputAdornment } from "@material-ui/core";
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    "& p": {
      textAlign: "right"
    }
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)",
    margin: 0
  },
  error: {
    color: "#f44336",
    fontSize: 12,
    height: 12,
    fontWeight: 400
  }
}));

const ReduxNumberField = ({
  input,
  label,
  maxLength,
  minLength,
  multiline,
  cut,
  noHelpText,
  meta,
  ...custom
}) => {
  const classes = useStyles();
  const showHelperText = custom.showHelperText;
  return (
    <div>
      <div className={classes.label3}>{label}</div>
      <NumberFormat
        isNumericString
        id={input.name}
        customInput={TextField}
        className="mt-0 w-100"
        margin="dense"
        classes={{
          root: classes.textFieldRoot
        }}
        // onValueChange={(values) => input.onChange({target: {value: values}})}
        variant="outlined"
        inputProps={{ maxLength: 8 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              ₱
            </InputAdornment>
          )
        }}
        error={Boolean(meta.touched && meta.error)}
        {...input}
      />
      <p className={`text-right ${classes.error}`}>
        {meta.touched && showHelperText && meta.error ? 
        meta.error : ""
        } 
      </p>
    </div>
  );
};

export default ReduxNumberField;
