import React, { Suspense } from "react";
import { useTheme, ThemeProvider } from "@material-ui/styles";
import { Route, Switch } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Layout from "../components/layout";
import routes from "./routes";

const Router = () => {
  let theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Route
          render={state => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames="zoom-fadein"
                  timeout={150}
                >
                  <Switch location={location}>
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={props => {
                          return (
                            <div className="zoom-fadein" id="transition-div">
                              <Suspense fallback={null}>
                                <Component {...props} />
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ThemeProvider>
  );
};

export default Router;
