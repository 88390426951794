import Classic from '../../../../../assets/images/template assets/display_classic.png';
import Contemporary from '../../../../../assets/images/template assets/display_contemporary.png';
import Minimalist from '../../../../../assets/images/template assets/display_minimalist.png';
import ClassicV2 from '../../../../../assets/images/template assets/display_classicV2.png';
import ContemporaryV2 from '../../../../../assets/images/template assets/display_contemporaryV2.png';
import MinimalistV2 from '../../../../../assets/images/template assets/display_minimalistV2.png';
import Leftist from '../../../../../assets/images/template assets/display_leftist.png';
import Pastel from '../../../../../assets/images/template assets/display_pastel.png';
import SariSari from '../../../../../assets/images/template assets/display_sarisari.png';
import Blocks from '../../../../../assets/images/template assets/display_blocks.png';
import Greenery from '../../../../../assets/images/template assets/display_greenery.png';
import Japandi from '../../../../../assets/images/template assets/display_japandi.png';

import ClassicDesktop from '../../../../../assets/images/template assets/preview/classic-desktop.jpg';
import ClassicMobile from '../../../../../assets/images/template assets/preview/classic-mobile.png';

import ContemporaryDesktop from '../../../../../assets/images/template assets/preview/contemporary-desktop.jpg';
import ContemporaryMobile from '../../../../../assets/images/template assets/preview/contemporary-mobile.png';

import MinimalistDesktop from '../../../../../assets/images/template assets/preview/minimalist-desktop.png';
import MinimalistMobile from '../../../../../assets/images/template assets/preview/minimalist-mobile.png';

import ClassicV2Desktop from '../../../../../assets/images/template assets/preview/classicV2-desktop.jpg';
import ClassicV2Mobile from '../../../../../assets/images/template assets/preview/classicV2-mobile.jpg';

import ContemporaryV2Desktop from '../../../../../assets/images/template assets/preview/contemporaryV2-desktop.jpg';
import ContemporaryV2Mobile from '../../../../../assets/images/template assets/preview/contemporaryV2-mobile.jpg';

import MinimalistV2Desktop from '../../../../../assets/images/template assets/preview/minimalistV2-desktop.jpg';
import MinimalistV2Mobile from '../../../../../assets/images/template assets/preview/minimalistV2-mobile.jpg';

import SariSariDesktop from '../../../../../assets/images/template assets/preview/sarisari-desktop.jpg';
import SariSariMobile from '../../../../../assets/images/template assets/preview/sarisari-mobile.jpg';

import LeftistDesktop from '../../../../../assets/images/template assets/preview/leftist-desktop.jpg';
import LeftistMobile from '../../../../../assets/images/template assets/preview/leftist-mobile.jpg';

import PastelDesktop from '../../../../../assets/images/template assets/preview/pastel-desktop.jpg';
import PastelMobile from '../../../../../assets/images/template assets/preview/pastel-mobile.jpg';

import BlocksDesktop from '../../../../../assets/images/template assets/preview/blocks-desktop.jpg';
import BlocksMobile from '../../../../../assets/images/template assets/preview/blocks-mobile.jpg';

import GreeneryDesktop from '../../../../../assets/images/template assets/preview/greenery-desktop.jpg';
import GreeneryMobile from '../../../../../assets/images/template assets/preview/greenery-mobile.jpg';

import JapandiDesktop from '../../../../../assets/images/template assets/preview/japandi-desktop.jpg';
import JapandiMobile from '../../../../../assets/images/template assets/preview/japandi-mobile.jpg';
export default function templateDisplayImage(template) {
  const templates = [
    { template: 'Classic', value: Classic },
    { template: 'Contemporary', value: Contemporary },
    { template: 'Minimalist', value: Minimalist },
    { template: 'ClassicV2', value: ClassicV2 },
    { template: 'ContemporaryV2', value: ContemporaryV2 },
    { template: 'MinimalistV2', value: MinimalistV2 },
    { template: 'Leftist', value: Leftist },
    { template: 'SariSari', value: SariSari },
    { template: 'Pastel', value: Pastel },
    { template: 'Blocks', value: Blocks },
    { template: 'Greenery', value: Greenery },
    { template: 'Japandi', value: Japandi },
    { template: 'NFT', value: '' },
  ];

  for (let i in templates) {
    if (templates[i].template === template) {
      console.log(templates[i].value);
      return templates[i].value;
    }
  }
}

export const templates = [
  {
    template: 'Classic',
    image: Classic,
    value: 'Classic',
    desktop: ClassicDesktop,
    mobile: ClassicMobile,
  },
  {
    template: 'Contemporary',
    image: Contemporary,
    value: 'Contemporary',
    desktop: ContemporaryDesktop,
    mobile: ContemporaryMobile,
  },
  {
    template: 'Minimalist',
    image: Minimalist,
    value: 'Minimalist',
    desktop: MinimalistDesktop,
    mobile: MinimalistMobile,
  },
  {
    template: 'Classic V2',
    image: ClassicV2,
    value: 'ClassicV2',
    is_paid: true,
    desktop: ClassicV2Desktop,
    mobile: ClassicV2Mobile,
  },
  {
    template: 'Contemporary V2',
    image: ContemporaryV2,
    value: 'ContemporaryV2',
    is_paid: true,
    desktop: ContemporaryV2Desktop,
    mobile: ContemporaryV2Mobile,
  },
  {
    template: 'Leftist',
    image: Leftist,
    value: 'Leftist',
    is_paid: true,
    desktop: LeftistDesktop,
    mobile: LeftistMobile,
  },
  {
    template: 'Sari Sari',
    image: SariSari,
    value: 'SariSari',
    is_paid: true,
    desktop: SariSariDesktop,
    mobile: SariSariMobile,
  },
  {
    template: 'Pastel',
    image: Pastel,
    value: 'Pastel',
    is_paid: true,
    desktop: PastelDesktop,
    mobile: PastelMobile,
  },
  {
    template: 'Minimalist V2',
    image: MinimalistV2,
    value: 'MinimalistV2',
    is_paid: true,
    desktop: MinimalistV2Desktop,
    mobile: MinimalistV2Mobile,
  },
  {
    template: 'Blocks',
    image: Blocks,
    value: 'Blocks',
    is_paid: true,
    desktop: BlocksDesktop,
    mobile: BlocksMobile,
  },
  {
    template: 'Greenery',
    image: Greenery,
    value: 'Greenery',
    is_paid: true,
    desktop: GreeneryDesktop,
    mobile: GreeneryMobile,
  },
  {
    template: 'Japandi',
    image: Japandi,
    value: 'Japandi',
    is_paid: true,
    desktop: JapandiDesktop,
    mobile: JapandiMobile,
  },
  {
    template: 'NFT',
    image: '',
    value: 'NFT',
    is_paid: false,
    desktop: '',
    mobile: '',
  },
];

export const recommended_templates = [
  {
    template: 'Contemporary V2',
    image: ContemporaryV2,
    value: 'ContemporaryV2',
    is_paid: true,
    desktop: ContemporaryV2Desktop,
    mobile: ContemporaryV2Mobile,
    description: 'Insert template description here',
  },
  {
    template: 'Minimalist',
    image: Minimalist,
    value: 'Minimalist',
    desktop: MinimalistV2Desktop,
    mobile: MinimalistV2Mobile,
    description: 'Insert template description here',
  },
];
