import React from "react";
import website from "../../../../../assets/images/website.svg";
import quick_order from "../../../../../assets/images/quick_order.svg";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  RadioGroup,
  FormControlLabel,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import BpRadio from "../../../../delivery_settings/components/radioButtons";
import { HelpOutline } from "@material-ui/icons";
export default function SelectShop(props) {
  const useStyles = makeStyles(theme => ({
    option: {
      border: "1px solid #d1d5dd",
      borderRadius: 4,
      width: "50%",
      margin: 10
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <div className="p-3 text-center d-flex align-items-center justify-content-center">
      <div>
        <div
          style={{
            fontSize: isMobile ? 16 : 20,
            fontWeight: 600,
            color: "#2b2d32"
          }}
        >
          Which shop type do you want to build?
        </div>
        <div className="mt-2" style={{ fontSize: 14 }}>
          Sentro allows you to have two types of shop in one platform! Select
          from one of the options below to start building an online shop.
        </div>
        <div className="mt-3 d-flex col-12 p-0 w-100">
          <RadioGroup
            value={props.type}
            className="w-100"
            style={{
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "inherit"
            }}
            onChange={e => props.setType(e.target.value)}
          >
            <div
              onClick={() => props.setType("website")}
              className={`${
                isMobile ? "w-100" : "w-50"
              } bg-white p-md-3 p-2 mr-md-3 mr-0 mb-md-0 mb-4 d-md-block d-flex align-items-center`}
              style={{
                height: "100%",
                cursor: "pointer",
                border: `1px solid ${
                  props.type === "website" ? "#f97663" : "#d1d5dd"
                }`,
                borderRadius: 4
              }}
            >
              <img
                src={website}
                alt=""
                width="auto"
                height={isMobile ? 60 : 100}
                className="mr-md-0 mr-3"
              />
              <div style={{ textAlign: isMobile ? "left" : "center" }}>
                <div className="mt-3" style={{ fontSize: 14, fontWeight: 600 }}>
                  Website
                </div>
                <div
                  className="my-3"
                  style={{ fontSize: 12, color: "#54575f" }}
                >
                  Build and manage an online shop with customizable and
                  mobile-friendly templates that fit your brand.
                </div>
              </div>
              <FormControlLabel
                value="website"
                className="m-0 d-md-flex d-none justify-content-center"
                control={<BpRadio color="primary" />}
              />
            </div>
            <div
              onClick={() => props.setType("order")}
              className={`${
                isMobile ? "w-100" : "w-50"
              } bg-white p-md-3 p-2 d-md-block d-flex align-items-center`}
              style={{
                height: "100%",
                cursor: "pointer",
                border: `1px solid ${
                  props.type === "order" ? "#f97663" : "#d1d5dd"
                }`,
                borderRadius: 4
              }}
            >
              <img
                src={quick_order}
                alt=""
                width="auto"
                height={isMobile ? 75 : 100}
                className="mr-md-0 mr-4"
              />
              <div style={{ textAlign: isMobile ? "left" : "center" }}>
                <div
                  className={`mt-3 d-flex ${
                    isMobile ? "" : "justify-content-center"
                  } align-items-center`}
                  style={{ fontSize: 14, fontWeight: 600 }}
                >
                  Quick order form{" "}
                  <IconButton className="p-2">
                    <HelpOutline color="secondary" style={{ fontSize: 16 }} />
                  </IconButton>
                </div>
                <div
                  className="my-3"
                  style={{ fontSize: 12, color: "#54575f" }}
                >
                  Let your customers order your products directly from any
                  social media channel by setting up this simple order form.
                </div>
              </div>
              <FormControlLabel
                value="order"
                className="m-0 d-md-flex d-none justify-content-center"
                control={<BpRadio color="primary" />}
              />
            </div>
          </RadioGroup>
        </div>
      </div>
    </div>
  );
}
