import { showAlert } from "./Alert";
import ProductServices from "./StoreServices";
import history from "../../../../store/history";
import { getPayments } from "../../../websiteBuilder/websiteBuilderServices";
import apiConfig from "../../../../common/apiConfig";
import { useSelector } from "react-redux";
import Path from "../../../../Paths";
import ProductActions from "../../../enterprise/redux/actions/productActions";
export const ProductActionTypes = {
  LOADING_ON: "@@LOADING_ON",
  LOADING_OFF: "@@LOADING_OFF",
  GET_STORE_INFO: "@@GET_STORE_INFO",
  GET_STORE_PRODUCTS: "@@GET_STORE_PRODUCTS",
  GET_STORE_PRODUCTS_SUCCESS: "@GET_STORE_PRODUCTS_SUCCESS",
  GET_STORE_PRODUCTS_FAILED: "@GET_STORE_PRODUCTS_FAILED",
  GET_SELECTED_PRODUCT: "@@GET_SELECTED_PRODUCT",
  POST_STORE_CHECKOUT: "@@POST_STORE_CHECKOUT",
  POST_STORE_BUXCHECKOUT: "@@POST_STORE_BUXCHECKOUT",
  ADD_TO_CART: "@@ADD_TO_CART",
  SME_UPDATE_QUANTITY: "@@SME_UPDATE_QUANTITY",
  SME_REMOVE_FROM_CART: "@@SME_REMOVE_FROM_CART",
  STORE_ERROR: "@@STORE_ERROR",
  STORE_REQUEST: "@@STORE_REQUEST",
  RESET_CART: "@@RESET_CART",
  GET_TEMPLATE: "@@GET_TEMPLATE",
  TEMPLATE_ERROR: "@@TEMPLATE_ERROR",
  ERROR_MAINTENANCE: "@@ERROR_MAINTENANCE",
  GET_STORE_PRODUCT_CATEGORIES: "GET_STORE_PRODUCT_CATEGORIES",
  GET_STORE_PRODUCT_CATEGORIES_SUCCESS: "GET_STORE_PRODUCT_CATEGORIES_SUCCESS",
  GET_STORE_PRODUCT_CATEGORIES_FAILED: "GET_STORE_PRODUCT_CATEGORIES_FAILED",
  SET_CUSTOMER_DETAILS: "@@SET_CUSTOMER_DETAILS",
  SET_DELIVERY_DETAILS: "SET_DELIVERY_DETAILS",
  CART_DRAWER: "CART_DRAWER",
  GET_DELIVERY_LOCATIONS: "GET_DELIVERY_LOCATIONS",
  COMPUTE_SHIPPING: "COMPUTE_SHIPPING",
  COMPUTE_SHIPPING_SUCCESS: "COMPUTE_SHIPPING_SUCCESS",
  COMPUTE_SHIPPING_FAILED: "COMPUTE_SHIPPING_FAILED",
  // PROMO
  CREATE_PROMO: "CREATE_PROMO",
  CREATE_PROMO_SUCCESS: "CREATE_PROMO_SUCCESS",
  CREATE_PROMO_FAILED: "CREATE_PROMO_FAILED",
  CHECK_PROMO_ELIGIBILITY: "CHECK_PROMO_ELIGIBILITY",
  CHECK_PROMO_ELIGIBILITY_SUCCESS: "CHECK_PROMO_ELIGIBILITY_SUCCESS",
  RESET_PROMO: "RESET_PROMO",
  SET_PROMO_MODAL: "SET_PROMO_MODAL",

  GET_PAYMENTS: "@@GET_PAYMENTS",
  OPEN_ERROR: "@@OPEN_ERROR",
  OPEN_SERVER_ERROR: "@@OPEN_SERVER_ERROR",
  CLOSE_ERROR: "@@CLOSE_ERROR",

  GET_VOUCHERS: "@@GET_VOUCHERS",
  APPLY_VOUCHER: "APPLY_VOUCHER",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SME_GET_BUYER_CART: "SME_GET_BUYER_CART",
  SME_UPDATE_VARIANT: "SME_UPDATE_VARIANT",

  SET_SELECTED_PAYMENT_CHANNEL: "SET_SELECTED_PAYMENT_CHANNEL",
  SHIPPING_FEE_LOADING_ON: "SHIPPING_FEE_LOADING_ON",
  SHIPPING_FEE_LOADING_OFF: "SHIPPING_FEE_LOADING_OFF",
  NINJAVAN_SHIPPING_FEE: "NINJAVAN_SHIPPING_FEE",
  MRSPEEDY_SHIPPING_FEE: "MRSPEEDY_SHIPPING_FEE",
  COMPUTE_FEES: "COMPUTE_FEES",
  COMPUTE_FEES_SUCCESS: "COMPUTE_FEES_SUCCESS",
  COMPUTE_FEES_FAILED: "COMPUTE_FEES_FAILED",
  RESET_FEES: "RESET_FEES",

  SET_REMARKS: "SET_REMARKS",
};

class StoreActions {
  static getMyStoreInfo(value, params = { getProducts: true }) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.STORE_REQUEST });
        let response = await ProductServices.getStoreInfo(value);
        dispatch({
          type: ProductActionTypes.GET_STORE_INFO,
          payload: response.data,
        });

        let uuid = response.data.uuid;
        dispatch({
          type: ProductActionTypes.GET_STORE_INFO,
          payload: response.data,
        });
        let payments = await getPayments(uuid);
        dispatch({
          type: ProductActionTypes.GET_PAYMENTS,
          payload: payments.data,
        });
        if (params.getProducts === true) {
          let products = await ProductServices.getProducts(uuid);
          dispatch({
            type: ProductActionTypes.GET_STORE_PRODUCTS,
            payload: products.data,
          });
        }
        return response.data;
      } catch (error) {
        history.push("/not-found");
      }
    };
  }

  static getVouchers(store_uuid, params) {
    return async (dispatch) => {
      try {
        let vouchers = await ProductServices.getVouchers(store_uuid, params);
        dispatch({
          type: ProductActionTypes.GET_VOUCHERS,
          payload: vouchers.data,
        });
        return vouchers.data;
      } catch (error) {}
    };
  }

  static applyVoucher(voucher) {
    return async (dispatch) => {
      try {
        dispatch({
          type: ProductActionTypes.APPLY_VOUCHER,
          payload: voucher,
        });
      } catch (error) {}
    };
  }

  static getAllProducts(isMobile, store_uuid, params = false) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.STORE_REQUEST });
        let response = await ProductServices.getProducts(store_uuid, params);
        dispatch({
          type: ProductActionTypes.GET_STORE_PRODUCTS,
          payload: response.data,
          isMobile,
        });
      } catch (error) {
        dispatch({
          type: ProductActionTypes.STORE_ERROR,
        });
        dispatch(showAlert({ type: "error", message: error.message }));
        console.log("ERROR: ", error);
      }
    };
  }

  static getStoreProductCategories(store_uuid) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.GET_STORE_PRODUCT_CATEGORIES });
        let response = await ProductServices.getStoreProductCategories(
          store_uuid
        );
        dispatch({
          type: ProductActionTypes.GET_STORE_PRODUCT_CATEGORIES_SUCCESS,
          payload: response.data,
        });
        return response.data;
      } catch (error) {
        dispatch({
          type: ProductActionTypes.GET_STORE_PRODUCT_CATEGORIES_FAILED,
        });
      }
    };
  }

  static getSelectedProduct(value) {
    return async (dispatch) => {
      try {
        dispatch({
          type: ProductActionTypes.GET_SELECTED_PRODUCT,
          payload: value,
        });
        // GTM trigger
        window.dataLayer.push({
          event: "productClick",
          ecommerce: {
            click: {
              products: [
                {
                  id: value.uuid,
                  name: value.name,
                  price: value.price,
                  [`dimension${apiConfig.indexStoreID}`]: value.store,
                },
              ],
            },
          },
        });
      } catch (error) {
        dispatch({
          type: ProductActionTypes.STORE_ERROR,
        });
      }
    };
  }

  static getProduct(uuid) {
    return async (dispatch) => {
      try {
        let value = await ProductServices.getProduct(uuid);
        dispatch({
          type: ProductActionTypes.GET_SELECTED_PRODUCT,
          payload: value,
        });
        // GTM trigger
        window.dataLayer.push({
          event: "productClick",
          ecommerce: {
            click: {
              products: [
                {
                  id: value.uuid,
                  name: value.name,
                  price: value.price,
                  [`dimension${apiConfig.indexStoreID}`]: value.store,
                },
              ],
            },
          },
        });
      } catch (error) {
        dispatch({
          type: ProductActionTypes.STORE_ERROR,
        });
      }
    };
  }

  static addToCart(value, open_drawer) {
    return async (dispatch) => {
      try {
        let product = await ProductServices.getProduct(value.uuid);
        let params = {
          ...value,
          quantity: value.quantity,
          name: value.name,
          productDescription: value.description,
          price: value.price,
          discounted_price: value.discounted_price,
          gallery: value.gallery,
          image: value.images,
          uuid: value.uuid,
          store: value.store,
          variants: value.variants,
          productSelection: value.productSelection,
          always_available: product.always_available,
          stock: product.quantity,
        };
        dispatch({ type: ProductActionTypes.ADD_TO_CART, payload: params });
        if (open_drawer) {
          dispatch({ type: ProductActionTypes.CART_DRAWER, payload: true });
        }
        // GTM trigger
        window.dataLayer.push({
          event: "addToCart",
          ecommerce: {
            add: {
              products: [
                {
                  id: value.uuid,
                  name: value.name,
                  price: value.price,
                  [`dimension${apiConfig.indexStoreID}`]: value.store,
                },
              ],
            },
          },
        });
      } catch (error) {
        dispatch({
          type: ProductActionTypes.STORE_ERROR,
        });
        console.log(error);
        dispatch(showAlert({ type: "error", message: error.message }));
      }
    };
  }

  static updateQuantity(value) {
    return async (dispatch) => {
      try {
        dispatch({
          type: ProductActionTypes.SME_UPDATE_QUANTITY,
          payload: value,
        });
      } catch (error) {
        dispatch({ type: ProductActionTypes.STORE_ERROR });
        dispatch(showAlert({ type: "error", message: error.message }));
      }
    };
  }

  static resetCart() {
    return async (dispatch) => {
      dispatch({ type: ProductActionTypes.RESET_CART });
    };
  }

  static removeFromCart(value) {
    return async (dispatch) => {
      try {
        dispatch({
          type: ProductActionTypes.SME_REMOVE_FROM_CART,
          payload: value,
        });
        dispatch({ type: ProductActionTypes.CLOSE_ERROR });
        // GTM trigger
        window.dataLayer.push({
          event: "removeFromCart",
          ecommerce: {
            click: {
              products: [
                {
                  id: value.uuid,
                  name: value.name,
                  price: value.price,
                  [`dimension${apiConfig.indexStoreID}`]: value.store,
                },
              ],
            },
          },
        });
        // history.push(Path.CART);
      } catch (error) {
        dispatch({
          type: ProductActionTypes.STORE_ERROR,
        });
        console.log(error);
        dispatch(showAlert({ type: "error", message: error.message }));
      }
    };
  }

  static checkOut(value, paymentType) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.STORE_REQUEST });
        let response = await ProductServices.checkOut(value, paymentType);
        dispatch({
          type: ProductActionTypes.POST_STORE_CHECKOUT,
          payload: response.data,
        });
        return response;
      } catch (error) {
        console.log("ERROR: ", error);
        if (error && error.status === 500) {
          dispatch({
            type: ProductActionTypes.STORE_ERROR,
          });
          dispatch({ type: ProductActionTypes.OPEN_ERROR });
        } else {
          dispatch({
            type: ProductActionTypes.STORE_ERROR,
          });
          dispatch({ type: ProductActionTypes.OPEN_SERVER_ERROR });
        }

        // dispatch(showAlert({ type: "error", message: error.message }));
      }
    };
  }

  static buxCheckout(value) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.STORE_REQUEST });
        let response = await ProductServices.buxCheckout(value);
        dispatch({
          type: ProductActionTypes.POST_STORE_CHECKOUT,
          payload: response.data,
        });
        console.log("RESPONSE: ", response);
      } catch (error) {
        dispatch({
          type: ProductActionTypes.STORE_ERROR,
        });
      }
    };
  }

  static maintenance() {
    return async (dispatch) => {
      try {
        dispatch({
          type: ProductActionTypes.STORE_REQUEST,
        });
        let response = await ProductServices.maintenance;
        dispatch({
          type: ProductActionTypes.ERROR_MAINTENANCE,
          payload: response,
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  // static create1212Promo() {
  //   return async dispatch => {
  //     try {
  //       dispatch({ type: ProductActionTypes.CREATE_PROMO });
  //       let response = await ProductServices.create1212Promo({
  //         name: "12TWELVE",
  //         promo_code: "12TWELVE",
  //         discount: 50,
  //         is_enabled: true
  //       });
  //       dispatch({
  //         type: ProductActionTypes.CREATE_PROMO_SUCCESS,
  //         payload: response
  //       });
  //     } catch (error) {
  //       dispatch({
  //         type: ProductActionTypes.CREATE_PROMO_FAILED,
  //         payload: error
  //       });
  //     }
  //   };
  // }
  static checkEligibility(payload) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.CHECK_PROMO_ELIGIBILITY });
        let response = await ProductServices.checkEligibility(payload);
        console.log(response.data);
        dispatch({
          type: ProductActionTypes.CHECK_PROMO_ELIGIBILITY_SUCCESS,
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: ProductActionTypes.CREATE_PROMO_FAILED,
          payload: error,
        });
      }
    };
  }

  static resetPromo(payload) {
    return async (dispatch) => {
      dispatch({ type: ProductActionTypes.RESET_PROMO, payload });
    };
  }

  static setPromoModal(payload) {
    return async (dispatch) => {
      dispatch({
        type: ProductActionTypes.SET_PROMO_MODAL,
        payload,
      });
    };
  }

  static setCustomerDetails(value, storeInfo) {
    return async (dispatch) => {
      dispatch({
        type: ProductActionTypes.SET_CUSTOMER_DETAILS,
        payload: value,
      });
    };
  }

  static createBuyerAccount(params) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.LOADING_ON });
        let response = await ProductServices.createBuyerAccount(params);
        dispatch({ type: ProductActionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({ type: ProductActionTypes.LOADING_OFF });
        console.log(error);
      }
    };
  }

  static buyerLogin(params) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.LOADING_ON });
        let response = await ProductServices.buyerLogin(params);
        dispatch({ type: ProductActionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({ type: ProductActionTypes.LOADING_OFF });
        console.log(error);
      }
    };
  }

  static resend() {
    let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.LOADING_ON });
        let response = await ProductServices.resend(user.email);
        dispatch({ type: ProductActionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({ type: ProductActionTypes.LOADING_OFF });
        console.log(error);
      }
    };
  }

  static forgotPassword(email) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.LOADING_ON });
        let response = await ProductServices.forgotPassword(email);
        dispatch({ type: ProductActionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({ type: ProductActionTypes.LOADING_OFF });
        console.log(error);
      }
    };
  }

  static showCartDrawer(value) {
    return async (dispatch) => {
      dispatch({ type: ProductActionTypes.CART_DRAWER, payload: value });
    };
  }

  static setDeliveryDetails(value) {
    return async (dispatch) => {
      dispatch({
        type: ProductActionTypes.SET_DELIVERY_DETAILS,
        payload: value,
      });
    };
  }
  static getDeliveryLocations(store_uuid) {
    return async (dispatch) => {
      try {
        let response = await ProductServices.getDeliveryLocations(store_uuid);
        dispatch({
          type: ProductActionTypes.GET_DELIVERY_LOCATIONS,
          payload: response.data,
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static computeShipping(value, uuid) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.COMPUTE_SHIPPING });
        let response = await ProductServices.computeShipping(value);
        let params = {
          delivery_detail: {
            ...value,
            store: uuid,
          },
          shipping_fee: response.data,
        };
        dispatch({
          type: ProductActionTypes.COMPUTE_SHIPPING_SUCCESS,
          payload: params,
        });
        return response;
      } catch (error) {
        dispatch({
          type: ProductActionTypes.COMPUTE_SHIPPING_FAILED,
          payload: error.status,
        });
      }
    };
  }

  static getBuyerCart(site) {
    return async (dispatch) => {
      try {
        let response = await ProductServices.getBuyerCart();
        let data = response.data.all;
        let current_store_items = [];
        let other_store_items = [];
        for (let i in data) {
          if (data[i].store_details.site === site) {
            current_store_items.push({
              ...data[i].product_details,
              gallery: data[i].gallery,
              quantity: data[i].quantity,
              stock: data[i].product_details.quantity,
              variants: data[i].variant,
              productSelection: data[i].productSelection,
            });
          } else {
            other_store_items.push({
              ...data[i].product_details,
              gallery: data[i].gallery,
              quantity: data[i].quantity,
              stock: data[i].product_details.quantity,
            });
          }
        }
        dispatch({
          type: ProductActionTypes.SME_GET_BUYER_CART,
          payload: { current_store_items, other_store_items },
        });
      } catch (e) {
        console.log(e);
      }
    };
  }

  static patchBuyerCart(params) {
    return async (dispatch) => {
      try {
        let response = await ProductServices.patchBuyerCart(params);
        return response;
      } catch (e) {
        console.log(e);
      }
    };
  }

  static updateVariant(value) {
    return async (dispatch) => {
      dispatch({ type: ProductActionTypes.SME_UPDATE_VARIANT, payload: value });
    };
  }

  static setSelectedPaymentChannel(value) {
    return async (dispatch) => {
      dispatch({
        type: ProductActionTypes.SET_SELECTED_PAYMENT_CHANNEL,
        payload: value,
      });
    };
  }

  static ninjavanShippingFee(params) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.SHIPPING_FEE_LOADING_ON });
        let response = await ProductServices.ninjavanShippingFee(params);
        dispatch({
          type: ProductActionTypes.NINJAVAN_SHIPPING_FEE,
          payload: response.data.shipping_fee,
        });
        return response;
      } catch (e) {
        console.log(e);
        dispatch({ type: ProductActionTypes.SHIPPING_FEE_LOADING_OFF });
        dispatch({
          type: ProductActionTypes.NINJAVAN_SHIPPING_FEE,
          payload: e,
        });
      }
    };
  }

  static mrspeedyShippingFee(params) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.SHIPPING_FEE_LOADING_ON });
        let response = await ProductServices.computeShipping(params);
        dispatch({
          type: ProductActionTypes.MRSPEEDY_SHIPPING_FEE,
          payload: response.data,
        });
        return response;
      } catch (e) {
        console.log(e);
        dispatch({ type: ProductActionTypes.SHIPPING_FEE_LOADING_OFF });
        dispatch({
          type: ProductActionTypes.MRSPEEDY_SHIPPING_FEE,
          payload: e,
        });
      }
    };
  }

  static computeFees(values) {
    return async (dispatch) => {
      try {
        dispatch({ type: ProductActionTypes.COMPUTE_FEES });
        let response = await ProductServices.computeFees(values);
        dispatch({
          type: ProductActionTypes.COMPUTE_FEES_SUCCESS,
          payload: response.data,
        });
        return response;
      } catch (e) {
        console.log(e);
        dispatch({ type: ProductActionTypes.COMPUTE_FEES_FAILED });
      }
    };
  }

  static resetFees() {
    return async (dispatch) => {
      dispatch({ type: ProductActionTypes.RESET_FEES });
    };
  }

  static setRemarks(values) {
    return async (dispatch) => {
      dispatch({ 
        type: ProductActionTypes.SET_REMARKS,
        payload: values
      });
    }
  }
}
export default StoreActions;
