import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import {
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons";
import "./sentroColor.css";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
export default function BlockDates(props) {
  const { values, setValues } = props;
  const useStyles = makeStyles((theme) => ({
    label: {
      fontSize: 14,
      fontWeight: 400,
      color: "#2b2d32",
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const CustomComponent = (props) => {
    return (
      <TextField
        fullWidth
        color="secondary"
        margin="dense"
        variant="outlined"
        placeholder="YYYY-MM-DD"
        value={props.value}
        onClick={props.openCalendar}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarTodayOutlined style={{ color: "#858997" }} />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <div className="p-3 p-md-5">
      <div className={classes.label}>
        Block certain dates when you cannot deliver orders or accommodate store
        pickup.
      </div>
      <div className="my-4">
        <div className={classes.label}>Select dates (holidays, etc.)</div>
        <DatePicker
          multiple
          arrow={false}
          value={values}
          onChange={setValues}
          numberOfMonths={isMobile ? 1 : 2}
          minDate={tomorrow}
          plugins={
            isMobile
              ? [
                  <Toolbar
                    position="bottom"
                    sort={["close"]}
                    names={{ close: "Confirm dates" }}
                  />,
                ]
              : [
                  <DatePanel />,
                  <Toolbar
                    position="bottom"
                    sort={["close"]}
                    names={{ close: "Confirm dates" }}
                  />,
                ]
          }
          className="sentroColor"
          style={{ width: "100%", padding: 20 }}
          containerStyle={{ width: "100%" }}
          format="YYYY-MM-DD"
          render={<CustomComponent />}
          calendarPosition="bottom"
          fixMainPosition={true}
        />
      </div>
    </div>
  );
}
