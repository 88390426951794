import MarketplaceActions from "./marketplaceActions";
import ProductServices from "../services/productServices";
import MarketplaceServices from "../services/marketplaceServices";
import apiConfig from "../../../../common/apiConfig";
import history from "../../../../store/history";
import { DashboardActionTypes } from "./dashboardActions";
export const ProductActionTypes = {
  SET_PRODUCT_FILTERS: "SET_PRODUCT_FILTERS",
  SET_MERCHANT_PRODUCT_FILTERS: "SET_MERCHANT_PRODUCT_FILTERS",
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILED: "GET_PRODUCTS_FAILED",
  GET_MERCHANT_PRODUCTS: "GET_MERCHANT_PRODUCTS",
  GET_MERCHANT_PRODUCTS_SUCCESS: "GET_MERCHANT_PRODUCTS_SUCCESS",
  GET_MERCHANT_PRODUCTS_FAILED: "GET_MERCHANT_PRODUCTS_FAILED",
  GET_SELECTED_PRODUCT: "GET_SELECTED_PRODUCT",
  ADD_PRODUCT_TO_CART: "ADD_PRODUCT_TO_CART",
  MARKETPLACE_UPDATE_QUANTITY: "MARKETPLACE_UPDATE_QUANTITY",
  UPDATE_VARIANT: "UPDATE_VARIANT",
  MARKETPLACE_REMOVE_FROM_CART: "MARKETPLACE_REMOVE_FROM_CART",
  CART_DRAWER: "@@CART_DRAWER",
  GET_BUYER_CART: "GET_BUYER_CART",
  GET_MY_CART: "GET_MY_CART",
  GET_MY_CART_SUCCESS: "GET_MY_CART_SUCCESS",
  MARKETPLACE_VIEW_ORDER: "MARKETPLACE_VIEW_ORDER",
  MARKETPLACE_SET_SELECTED_CHECKOUT: "MARKETPLACE_SET_SELECTED_CHECKOUT",
  MARKETPLACE_POST_CHECKOUT: "MARKETPLACE_POST_CHECKOUT",
  MARKETPLACE_POST_CHECKOUT_FAILED: "MARKETPLACE_POST_CHECKOUT_FAILED",
  MARKETPLACE_REMOVE_ITEM: "MARKETPLACE_REMOVE_ITEM",
  MARKETPLACE_UPDATE_ITEM: "MARKETPLACE_UPDATE_ITEM",
  MARKETPLACE_UPDATE_VARIANT: "MARKETPLACE_UPDATE_VARIANT",
  MARKETPLACE_SET_CUSTOMER_DETAILS: "MARKETPLACE_SET_CUSTOMER_DETAILS",
  MARKETPLACE_GET_PAYMENTS: "MARKETPLACE_GET_PAYMENTS",
  MARKETPLACE_GET_VOUCHERS: "MARKETPLACE_GET_VOUCHERS",
  MARKETPLACE_APPLY_VOUCHER: "MARKETPLACE_APPLY_VOUCHER",
  MARKETPLACE_GET_DELIVERY_LOCATIONS: "MARKETPLACE_GET_DELIVERY_LOCATIONS",
  MARKETPLACE_SET_DELIVERY_DETAILS: "MARKETPLACE_SET_DELIVERY_DETAILS",
  MARKETPLACE_COMPUTE_SHIPPING: "MARKETPLACE_COMPUTE_SHIPPING",
  MARKETPLACE_COMPUTE_SHIPPING_SUCCESS: "MARKETPLACE_COMPUTE_SHIPPING_SUCCESS",
  MARKETPLACE_COMPUTE_SHIPPING_FAILED: "MARKETPLACE_COMPUTE_SHIPPING_FAILED",
  ADD_PRODUCT_TO_CART_WISHLIST: "ADD_PRODUCT_TO_CART_WISHLIST"
};

class ProductActions {
  static setProductFilters(filters) {
    return async dispatch => {
      dispatch({
        type: ProductActionTypes.SET_PRODUCT_FILTERS,
        payload: filters
      });
    };
  }

  static setMerchantProductFilters(filters) {
    return async dispatch => {
      dispatch({
        type: ProductActionTypes.SET_MERCHANT_PRODUCT_FILTERS,
        payload: filters
      });
    };
  }

  static getProducts(
    enterprise,
    filters,
    addtlOptions = { appendToCurrentData: false }
  ) {
    return async dispatch => {
      try {
        dispatch(MarketplaceActions.getMarketplace(enterprise));
        dispatch({ type: ProductActionTypes.GET_PRODUCTS });
        const response = await ProductServices.getProducts(enterprise, filters);
        if (response) {
          dispatch({
            type: ProductActionTypes.GET_PRODUCTS_SUCCESS,
            payload: response,
            page: filters.page,
            addtlOptions
          });
        }
        return response;
      } catch (error) {
        console.log(error);
        // if (error.response.status === 404) {
        //   dispatch({ type: ProductActionTypes.GET_PRODUCTS_FAILED });
        // }
        // dispatch({
        //   type: ProductActionTypes.REQUEST_FAILED,
        //   payload: error
        // });
      }
    };
  }

  static getMerchantProducts(
    enterprise,
    merchant,
    filters,
    addtlOptions = { appendToCurrentData: false }
  ) {
    return async dispatch => {
      try {
        dispatch({ type: ProductActionTypes.GET_MERCHANT_PRODUCTS });
        const response = await ProductServices.getMerchantProducts(enterprise, {
          ...filters,
          uuid: merchant
        });
        if (response) {
          dispatch({
            type: ProductActionTypes.GET_MERCHANT_PRODUCTS_SUCCESS,
            payload: response,
            page: filters.page,
            addtlOptions
          });
        }
        return response;
      } catch (error) {
        if (error.status === 404) {
          dispatch({ type: ProductActionTypes.GET_MERCHANT_PRODUCTS_FAILED });
        }
        // dispatch({
        //   type: ProductActionTypes.REQUEST_FAILED,
        //   payload: error
        // });
      }
    };
  }

  static getSelectedProduct(site, product) {
    return async dispatch => {
      try {
        dispatch({ type: ProductActionTypes.GET_MERCHANT_PRODUCTS });
        const response = await ProductServices.getSelectedProduct(
          site,
          product
        );
        dispatch({
          type: ProductActionTypes.GET_SELECTED_PRODUCT,
          payload: response.data
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static addToCart(site, value, open_drawer) {
    let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    return async dispatch => {
      try {
        let product = await ProductServices.getSelectedProduct(
          site,
          value.uuid
        );
        product = product.data;
        let params = {
          ...value,
          always_available: product.always_available,
          stock: product.quantity
        };
        dispatch({
          type: ProductActionTypes.ADD_PRODUCT_TO_CART,
          payload: params
        });

        if (open_drawer) {
          dispatch({
            type: ProductActionTypes.CART_DRAWER,
            payload: true
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
  }

  // static addToCartWishlist(site, value, open_drawer) {
  //   let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  //   return async dispatch => {
  //     try {
  //       let product = await ProductServices.getSelectedProduct(
  //         site,
  //         value.uuid
  //       );
  //       product = product.data;
  //       let params = {
  //         ...value,
  //         always_available: product.always_available,
  //         stock: product.quantity
  //       };
  //       dispatch({
  //         type: ProductActionTypes.ADD_PRODUCT_TO_CART_WISHLIST,
  //         payload: params
  //       });

  //       if (open_drawer) {
  //         dispatch({
  //           type: ProductActionTypes.CART_DRAWER,
  //           payload: true
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  // }

  static updateQuantity(value) {
    return async dispatch => {
      try {
        dispatch({
          type: ProductActionTypes.MARKETPLACE_UPDATE_QUANTITY,
          payload: value
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static updateVariant(value) {
    return async dispatch => {
      try {
        dispatch({ type: ProductActionTypes.UPDATE_VARIANT, payload: value });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static removeFromCart(value) {
    return async dispatch => {
      try {
        dispatch({
          type: ProductActionTypes.MARKETPLACE_REMOVE_FROM_CART,
          payload: value
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static showCartDrawer(value) {
    console.log(value);
    return async dispatch => {
      try {
        dispatch({
          type: ProductActionTypes.CART_DRAWER,
          payload: value
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static getBuyerCart(site) {
    return async dispatch => {
      try {
        let response = await ProductServices.getBuyerCart(site);
        dispatch({
          type: ProductActionTypes.GET_BUYER_CART,
          payload: response.data
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static patchBuyerCart(site, params) {
    return async dispatch => {
      try {
        let response = await ProductServices.patchBuyerCart(site, params);
        return response;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static getMyCart(site) {
    return async dispatch => {
      try {
        dispatch({ type: ProductActionTypes.GET_MY_CART });
        let response = await ProductServices.getMyCart(site);
        let params = response.data;
        let personal = [];
        let wishlist = [];
        for (let i in params.personal) {
          personal.push(params.personal[i]);
        }
        for (let i in params.wishlist) {
          let items = params.wishlist[i].items;
          wishlist.push({
            ...params.wishlist[i],
            registry: items[0].registry && items[0].registry.id
          });
        }
        dispatch({
          type: ProductActionTypes.GET_MY_CART_SUCCESS,
          payload: [...personal, ...wishlist]
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static viewOrder(site, type, params, id) {
    return async dispatch => {
      try {
        dispatch({ type: ProductActionTypes.GET_MY_CART });
        let response = await ProductServices.viewOrder(site, type, params);
        dispatch({
          type: ProductActionTypes.MARKETPLACE_VIEW_ORDER,
          payload: { ...response.data, id }
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static setSelectedCheckout(values) {
    console.log(values);
    return async dispatch => {
      dispatch({
        type: ProductActionTypes.MARKETPLACE_SET_SELECTED_CHECKOUT,
        payload: values
      });
    };
  }

  static checkout(values, paymentType, enterprise) {
    return async dispatch => {
      try {
        dispatch({ type: ProductActionTypes.GET_MY_CART });
        let response = await ProductServices.checkout(
          values,
          paymentType,
          enterprise
        );
        dispatch({
          type: ProductActionTypes.MARKETPLACE_POST_CHECKOUT,
          payload: response.data.order
        });
        let ref_id = response.data ? response.data.order.description : null;
        let access = response.data ? response.data.order.bux_uid : null;

        if (paymentType["paymentOption"] === "otc") {
          if (access) {
            window.location.href = `${apiConfig.buxURL}/checkout/${access}/?redirect_url=${apiConfig.localHost}enterprise/${enterprise}/checkout?ref_id=${ref_id}`;
          }
        } else {
          history.push(`/enterprise/${enterprise}/checkout`);
        }
        return response;
      } catch (error) {
        dispatch({ type: ProductActionTypes.MARKETPLACE_POST_CHECKOUT_FAILED });
        if (error.status === 400) {
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "error",
              message:
                "Oops! Looks like there is a problem with taking online orders for now. Kindly try again in a few minutes, or coordinate with us through any of the contact details below."
            }
          });
        } else {
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "error",
              message: "An error occured. Please try again."
            }
          });
        }
      }
    };
  }

  static buxCheckout(value) {
    return async dispatch => {
      try {
        dispatch({ type: ProductActionTypes.GET_MY_CART });
        let response = await ProductServices.buxCheckout(value);
        dispatch({
          type: ProductActionTypes.MARKETPLACE_POST_CHECKOUT,
          payload: response.data
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static removeItem(index) {
    return async dispatch => {
      dispatch({
        type: ProductActionTypes.MARKETPLACE_REMOVE_ITEM,
        payload: index
      });
    };
  }

  static updateItem(params, selected_order_items) {
    return async dispatch => {
      dispatch({
        type: ProductActionTypes.MARKETPLACE_UPDATE_ITEM,
        payload: {
          params,
          selected_order_items
        }
      });
    };
  }

  static updateItemVariant(productToUpdate, selected_order_items) {
    return async dispatch => {
      dispatch({
        type: ProductActionTypes.MARKETPLACE_UPDATE_VARIANT,
        payload: {
          productToUpdate,
          selected_order_items
        }
      });
    };
  }

  static setCustomerDetails(values) {
    return async dispatch => {
      dispatch({
        type: ProductActionTypes.MARKETPLACE_SET_CUSTOMER_DETAILS,
        payload: values
      });
    };
  }

  static setDeliveryDetails(values) {
    return async dispatch => {
      dispatch({
        type: ProductActionTypes.MARKETPLACE_SET_DELIVERY_DETAILS,
        payload: values
      });
    };
  }

  static getPayments(uuid) {
    return async dispatch => {
      try {
        dispatch({ type: ProductActionTypes.GET_MY_CART });
        let response = await ProductServices.getPayments(uuid);
        dispatch({
          type: ProductActionTypes.MARKETPLACE_GET_PAYMENTS,
          payload: response.data
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static setSelectedViewOrder(values) {
    return async dispatch => {
      dispatch({
        type: ProductActionTypes.MARKETPLACE_VIEW_ORDER,
        payload: values
      });
    };
  }

  static getVouchers(store_uuid, params) {
    return async dispatch => {
      try {
        let vouchers = await ProductServices.getVouchers(store_uuid, params);
        dispatch({
          type: ProductActionTypes.MARKETPLACE_GET_VOUCHERS,
          payload: vouchers.data
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static applyVoucher(voucher) {
    return async dispatch => {
      try {
        dispatch({
          type: ProductActionTypes.MARKETPLACE_APPLY_VOUCHER,
          payload: voucher
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static getDeliveryLocations(uuid) {
    return async dispatch => {
      try {
        let response = await ProductServices.getDeliveryLocations(uuid);
        dispatch({
          type: ProductActionTypes.MARKETPLACE_GET_DELIVERY_LOCATIONS,
          payload: response.data
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static computeShipping(value, uuid) {
    return async dispatch => {
      try {
        dispatch({ type: ProductActionTypes.MARKETPLACE_COMPUTE_SHIPPING });
        let response = await ProductServices.computeShipping(value);
        let params = {
          delivery_detail: {
            ...value,
            store: uuid
          },
          shipping_fee: response.data
        };
        dispatch({
          type: ProductActionTypes.MARKETPLACE_COMPUTE_SHIPPING_SUCCESS,
          payload: params
        });
        return response;
      } catch (error) {
        dispatch({
          type: ProductActionTypes.MARKETPLACE_COMPUTE_SHIPPING_FAILED,
          payload: error.status
        });
      }
    };
  }
  // static setToCart(item) {
  // 	return async dispatch => {
  // 		dispatch({
  // 			type: ActionTypes.SET_TO_CART,
  // 			payload: [item]
  // 		});
  // 	};
  // }
}

export default ProductActions;
