import { signOff } from "../../../auth/authActions";
import { showAlert } from "../../../common/commonActions";
import BuyerServices from "./services";
import history from "../../../../store/history";
import apiConfig from "../../../../common/apiConfig";

export const actionTypes = {
  LOADING_ON: "LOADING_ON",
  LOADING_OFF: "LOADING_OFF",
  GET_CART: "GET_CART",
  GET_ORDERS: "GET_ORDERS",
  GET_SELECTED_ORDER: "GET_SELECTED_ORDER",
  PROCEED_TO_CHECKOUT: "PROCEED_TO_CHECKOUT",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  UPDATE_QUANTITY: "UPDATE_QUANTITY",
  SET_PURCHASED_TAB: "SET_PURCHASED_TAB",
  GET_PURCHASED_ORDERS: "GET_PURCHASED_ORDERS",
  MARK_AS_RECEIVED: "MARK_AS_RECEIVED",
  CANCEL_ORDER: "CANCEL_ORDER",
  REMOVE_FROM_LIST: "REMOVE_FROM_LIST",
  UPDATE_ITEM: "UPDATE_ITEM",
  SME_SET_SELECTED_CHECKOUT: "SME_SET_SELECTED_CHECKOUT",
  GET_VOUCHERS: "GET_VOUCHERS",
  APPLY_VOUCHER: "APPLY_VOUCHER",
  GET_PURCHASED_VIEW_ORDER: "GET_PURCHASED_VIEW_ORDER",
  GET_DELIVERY_LOCATIONS: "GET_DELIVERY_LOCATIONS",
  SET_DELIVERY_DETAILS: "SET_DELIVERY_DETAILS",
  COMPUTE_SHIPPING: "COMPUTE_SHIPPING",
  COMPUTE_SHIPPING_SUCCESS: "COMPUTE_SHIPPING_SUCCESS",
  COMPUTE_SHIPPING_FAILED: "COMPUTE_SHIPPING_FAILED",
  GET_PAYMENTS: "GET_PAYMENTS",
  SME_POST_STORE_CHECKOUT: "SME_POST_STORE_CHECKOUT",
  POST_STORE_BUXCHECKOUT: "POST_STORE_BUXCHECKOUT",
  SME_BUYER_UPDATE_VARIANT: "SME_BUYER_UPDATE_VARIANT",
  SET_PROFILE_LOADING: "SET_PROFILE_LOADING",
  GET_ADDRESSES: "GET_ADDRESSES",
  SAVE_ADDRESSES: "SAVE_ADDRESSES",

  SET_SELECTED_PAYMENT_CHANNEL: "SET_SELECTED_PAYMENT_CHANNEL"
};

class BuyerActions {
  static patchProfile(data, pass_data) {
    return async dispatch => {
      try {
        dispatch({
          type: actionTypes.LOADING_ON,
          payload: true
        });
        let res = await BuyerServices.patchProfile(data);
        const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
        sessionStorage.setItem(
          "CURRENT_USER",
          JSON.stringify({
            ...user,
            ...res
          })
        );
        if (Object.keys(pass_data).length) {
          res = await BuyerServices.changePassword(pass_data);
        }
        dispatch({
          type: actionTypes.LOADING_OFF,
          payload: false
        });
        dispatch(
          showAlert({
            open: true,
            type: "success",
            message: "Your profile has been updated."
          })
        );
        return true;
      } catch (err) {
        dispatch({
          type: actionTypes.LOADING_OFF,
          payload: false
        });
        dispatch(
          showAlert({
            open: true,
            type: "error",
            message: "An error occured. Please try again"
          })
        );
        if (err.response) {
          if (Object.keys(err.response.data).includes("old_password")) {
            return { old_password: "Invalid password. Please try again." };
          }
        }
      }
    };
  }
  static getMyOrders() {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.getMyOrders();
        dispatch({ type: actionTypes.GET_ORDERS, payload: response.data });
        dispatch({ type: actionTypes.LOADING_OFF });
      } catch (error) {
        dispatch({ type: actionTypes.LOADING_OFF });
        if (error && error.status === 401) {
          dispatch(signOff());
        } else if (error && error.status === 400) {
          return error;
        } else {
          dispatch(
            showAlert({ type: "error", message: error ? error.data : "" })
          );
        }
      }
    };
  }

  static getSelectedOrder(id, order_id) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.getSelectedOrder(id);
        dispatch({
          type: actionTypes.GET_SELECTED_ORDER,
          payload: { ...response.data, order_id }
        });
        dispatch({ type: actionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({ type: actionTypes.LOADING_OFF });
        if (error && error.status === 401) {
          dispatch(signOff());
        } else if (error && error.status === 400) {
          return error;
        } else {
          dispatch(
            showAlert({
              type: "error",
              message: "An error occured. Please try again"
            })
          );
        }
      }
    };
  }

  static removeFromCart(value) {
    return async dispatch => {
      dispatch({ type: actionTypes.REMOVE_FROM_CART, payload: value });
    };
  }

  static updateQuantity(value, selected_order_items) {
    return async dispatch => {
      dispatch({
        type: actionTypes.UPDATE_QUANTITY,
        payload: { value, selected_order_items }
      });
    };
  }

  static setPurchasedTab(value) {
    return async dispatch => {
      dispatch({
        type: actionTypes.SET_PURCHASED_TAB,
        payload: value
      });
    };
  }

  static purchasedViewOrder(order_id) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.purchasedViewOrder(order_id);
        dispatch({
          type: actionTypes.GET_PURCHASED_VIEW_ORDER,
          payload: response.data
        });
        dispatch({ type: actionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({ type: actionTypes.LOADING_OFF });
        if (error && error.status === 401) {
          dispatch(signOff());
        } else if (error && error.status === 400) {
          return error;
        } else {
          dispatch(
            showAlert({
              type: "error",
              message: "An error occured. Please try again"
            })
          );
        }
      }
    };
  }

  static getPurchasedOrders(status) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.getPurchasedOrders(status);
        dispatch({
          type: actionTypes.SET_PURCHASED_TAB,
          payload: status
        });
        dispatch({
          type: actionTypes.GET_PURCHASED_ORDERS,
          payload: response.data
        });
        dispatch({ type: actionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({ type: actionTypes.LOADING_OFF });
        if (error && error.status === 401) {
          dispatch(signOff());
        } else if (error && error.status === 400) {
          return error;
        } else {
          dispatch(
            showAlert({ type: "error", message: error ? error.data : "" })
          );
        }
      }
    };
  }

  static markAsReceived(id, params = { from: "detailed_view" }) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.markAsReceived(id);
        dispatch({
          type: actionTypes.MARK_AS_RECEIVED,
          payload: response.data
        });
        if (params.from === "list_page") {
          dispatch({
            type: actionTypes.REMOVE_FROM_LIST,
            payload: response.data.id
          });
        }

        dispatch({ type: actionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({ type: actionTypes.LOADING_OFF });
        // if (error && error.status === 401) {
        //   dispatch(signOff());
        // } else if (error && error.status === 400) {
        //   return error;
        // } else {
        //   dispatch(
        //     showAlert({ type: "error", message: error ? error.data : "" })
        //   );
        // }
      }
    };
  }

  static cancelOrder(id, params = { from: "detailed_view" }) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.cancelOrder(id);
        dispatch({
          type: actionTypes.CANCEL_ORDER,
          payload: response.data
        });
        if (params.from === "list_page") {
          dispatch({
            type: actionTypes.REMOVE_FROM_LIST,
            payload: response.data.id
          });
        }
        dispatch(
          showAlert({
            type: "success",
            message: "You have successfully cancelled your order"
          })
        );
        dispatch({ type: actionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({ type: actionTypes.LOADING_OFF });
        // if (error && error.status === 401) {
        //   dispatch(signOff());
        // } else if (error && error.status === 400) {
        //   return error;
        // } else {
        //   dispatch(
        //     showAlert({ type: "error", message: error ? error.data : "" })
        //   );
        // }
      }
    };
  }

  static getMyCart() {
    return async dispatch => {
      try {
        let response = await BuyerServices.getMyCart();
        let cart = [];
        for (let i in response.data.cart) {
          cart.push(response.data.cart[i]);
        }
        dispatch({
          type: actionTypes.GET_CART,
          payload: { cart, all: response.data.all }
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static updateItem(params, selected_order_items) {
    return async dispatch => {
      dispatch({
        type: actionTypes.UPDATE_ITEM,
        payload: {
          params,
          selected_order_items
        }
      });
    };
  }

  static setSelectedCheckout(values) {
    return async dispatch => {
      dispatch({
        type: actionTypes.SME_SET_SELECTED_CHECKOUT,
        payload: values
      });
    };
  }

  static setSelectedViewOrder(values) {
    return async dispatch => {
      dispatch({
        type: actionTypes.PROCEED_TO_CHECKOUT,
        payload: values
      });
    };
  }

  static setDeliveryDetails(values) {
    return async dispatch => {
      dispatch({
        type: actionTypes.SET_DELIVERY_DETAILS,
        payload: values
      });
    };
  }

  static resetVoucherList() {
    return async dispatch => {
      dispatch({
        type: actionTypes.GET_VOUCHERS,
        payload: []
      });
    };
  }

  static getVouchers(store_uuid, params) {
    return async dispatch => {
      try {
        let vouchers = await BuyerServices.getVouchers(store_uuid, params);
        dispatch({
          type: actionTypes.GET_VOUCHERS,
          payload: vouchers.data
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static applyVoucher(voucher) {
    return async dispatch => {
      try {
        dispatch({
          type: actionTypes.APPLY_VOUCHER,
          payload: voucher
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static getDeliveryLocations(uuid) {
    return async dispatch => {
      try {
        let response = await BuyerServices.getDeliveryLocations(uuid);
        dispatch({
          type: actionTypes.GET_DELIVERY_LOCATIONS,
          payload: response.data
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static computeShipping(value, uuid) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.COMPUTE_SHIPPING });
        let response = await BuyerServices.computeShipping(value);
        let params = {
          delivery_detail: {
            ...value,
            store: uuid
          },
          shipping_fee: response.data
        };
        dispatch({
          type: actionTypes.COMPUTE_SHIPPING_SUCCESS,
          payload: params
        });
        return response;
      } catch (error) {
        dispatch({
          type: actionTypes.COMPUTE_SHIPPING_FAILED,
          payload: error.status
        });
      }
    };
  }

  static getPayments(uuid) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.getPayments(uuid);
        dispatch({
          type: actionTypes.GET_PAYMENTS,
          payload: response.data
        });
        dispatch({ type: actionTypes.LOADING_OFF });
      } catch (error) {
        dispatch({ type: actionTypes.LOADING_OFF });
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    };
  }

  static checkout(values, paymentType) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.checkout(values, paymentType);
        dispatch({
          type: actionTypes.SME_POST_STORE_CHECKOUT,
          payload: response.data
        });
        return response;
      } catch (e) {
        dispatch({ type: actionTypes.LOADING_OFF });
        if (e.status === 400) {
          dispatch(
            showAlert({
              type: "error",
              message:
                "Oops! Looks like there is a problem with taking online orders for now. Kindly try again in a few minutes, or coordinate with us through any of the contact details below."
            })
          );
        } else {
          dispatch(
            showAlert({
              type: "error",
              message: "An error has occured. Please try again"
            })
          );
        }
      }
    };
  }

  static viewCheckoutOrder(order_id) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.viewCheckoutOrder(order_id);
        dispatch({
          type: actionTypes.SME_POST_STORE_CHECKOUT,
          payload: response.data
        });
        dispatch({ type: actionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    };
  }

  static buxCheckout(ref_id) {
    return async dispatch => {
      try {
        dispatch({ type: actionTypes.LOADING_ON });
        let response = await BuyerServices.buxCheckout(ref_id);
        dispatch({
          type: actionTypes.SME_POST_STORE_CHECKOUT,
          payload: response.data
        });
        dispatch({ type: actionTypes.LOADING_OFF });
      } catch (error) {
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    };
  }

  static updateItemVariant(productToUpdate, selected_order_items) {
    return async dispatch => {
      dispatch({
        type: actionTypes.SME_BUYER_UPDATE_VARIANT,
        payload: {
          productToUpdate,
          selected_order_items
        }
      });
    };
  }

  static getAddresses() {
    return async dispatch => {
      try {
        let response = await BuyerServices.getAddresses();
        dispatch({ type: actionTypes.GET_ADDRESSES, payload: response.data });
      } catch (error) {
        dispatch(
          showAlert({
            open: true,
            type: "error",
            message: "An error occured. Please try again."
          })
        );
      }
    };
  }

  static saveAddresses(addresses) {
    return async dispatch => {
      try {
        await BuyerServices.saveAddresses(addresses);
        dispatch(
          showAlert({
            open: true,
            type: "success",
            message: "Your profile has been updated."
          })
        );
        dispatch(BuyerActions.getAddresses());
      } catch (error) {
        dispatch(
          showAlert({
            open: true,
            type: "error",
            message: "An error occured. Please try again."
          })
        );
      }
    };
  }

  static saveAddress(address) {
    return async dispatch => {
      try {
        const response = await BuyerServices.saveAddress(address);
        await dispatch(BuyerActions.getAddresses());
        return response;
      } catch (error) {
        dispatch(
          showAlert({
            open: true,
            type: "error",
            message: "An error occured. Please try again."
          })
        );
      }
    };
  }

  static deleteAddresses(address_ids) {
    return async dispatch => {
      try {
        await BuyerServices.deleteAddresses(address_ids);
        dispatch(
          showAlert({
            open: true,
            type: "success",
            message: "Your profile has been updated."
          })
        );
      } catch (error) {
        dispatch(
          showAlert({
            open: true,
            type: "error",
            message: "An error occured. Please try again."
          })
        );
      }
    };
  }
  static setSelectedPaymentChannel(value) {
    return async dispatch => {
      dispatch({
        type: actionTypes.SET_SELECTED_PAYMENT_CHANNEL,
        payload: value
      });
    };
  }
}

export default BuyerActions;

export const ReduxInitializeFormActionTypes = {
  CUSTOMER_DETAILS_FORM_DATA: "CUSTOMER_DETAILS_FORM_DATA",
  INITIALIZE_ADDRESS_MODAL: "INITIALIZE_ADDRESS_MODAL"
};

export class ReduxInitializeFormActions {
  static initializeCustomerDetailsData(data) {
    return {
      type: ReduxInitializeFormActionTypes.CUSTOMER_DETAILS_FORM_DATA,
      payload: data
    };
  }

  static initializeAddressModal(values) {
    return async dispatch => {
      dispatch({
        type: ReduxInitializeFormActionTypes.INITIALIZE_ADDRESS_MODAL,
        payload: values
      });
    };
  }
}
