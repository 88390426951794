import React from "react";
import { InputAdornment } from "@material-ui/core";
import validations from "../../../../common/validations";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import ReduxTextField from "../../../../reduxFields/textField";
import ReduxSelectField from "../../../../reduxFields/selectField";
import ReduxRadioField from "../../../../reduxFields/radioField";
import { asyncStoreValidations } from "../../../../reduxFields/validators";
import { makeStyles } from "@material-ui/styles";

function ShopForm(props) {
  // const { values, setValues, errors, setErrors } = props;
  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    input: {
      backgroundColor: "#fff"
    },
    radio: {
      marginBottom: 10
    }
  }));
  const classes = useStyles();
  const values = useSelector(state => state.form.create_shop?.values);
  const industry_list = [
    { value: "Beauty" },
    { value: "Clothing" },
    { value: "Electronics" },
    { value: "Furniture" },
    { value: "Handicrafts" },
    { value: "Jewelry" },
    { value: "Art & Photography" },
    { value: "Restaurants" },
    { value: "Groceries" },
    { value: "Other food & drink" },
    { value: "Sports" },
    { value: "Toys & Collectibles" },
    { value: "Services" },
    { value: "Virtual Services" },
    { value: "Other" },
    { value: "I haven't decided yet" }
  ];

  const getIndustry = () => {
    const values = [];
    for (let i in industry_list) {
      values.push({
        display: industry_list[i].value,
        value: industry_list[i].value
      });
    }
    return values;
  };

  const social_media_choices = [
    {
      value: 0,
      display: <div style={{ fontSize: 12, color: "#54575f" }}>Facebook</div>
    },
    {
      value: 1,
      display: <div style={{ fontSize: 12, color: "#54575f" }}>Instagram</div>
    }
  ];

  return (
    <div>
      <div>
        <div style={{ fontSize: 12 }}>What is the name of your shop?</div>
        <Field
          id="name"
          name="name"
          validate={[validations.required]}
          component={ReduxTextField}
          inputProps={{ maxlength: 30 }}
          InputProps={{ className: classes.input }}
        />
      </div>
      <div className="pt-3">
        <div style={{ fontSize: 12 }}>What industry is your shop in?</div>
        <Field
          fullWidth
          id="industry"
          name="industry"
          color="secondary"
          choices={getIndustry()}
          validate={[validations.required]}
          component={ReduxSelectField}
          variant="outlined"
          InputProps={{ className: classes.input }}
        />
      </div>
      {props.type === "order" ? (
        <div className="pt-4">
          <div style={{ fontSize: 12, marginBottom: 10 }}>
            Select primary social media account to use. You can edit this later.
          </div>
          <Field
            fullWidth
            id="social_media"
            name="social_media"
            color="primary"
            choices={social_media_choices}
            component={ReduxRadioField}
            inline
            className={classes.radio}
          />
        </div>
      ) : (
        ""
      )}
      {props.type === "order" && values?.social_media ? (
        <div className="pt-4">
          <div style={{ fontSize: 12, display: "flex" }}>
            What is your{" "}
            {
              <span className="mx-1">
                {values?.social_media === "0" ? "Facebook" : "Instagram"}
              </span>
            }{" "}
            account name?
          </div>
          <Field
            id={values?.social_media === "0" ? "facebook" : "instagram"}
            name={values?.social_media === "0" ? "facebook" : "instagram"}
            validate={[
              validations.spaceBetween,
              validations.uppercase,
              validations.specialCharacters
            ]}
            component={ReduxTextField}
            inputProps={{ maxlength: 30 }}
            InputProps={{
              className: classes.input,
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              )
            }}
          />
        </div>
      ) : (
        ""
      )}

      <div className="pt-4">
        <div style={{ fontSize: 12 }}>
          What will be your website address?
          <Field
            id="site"
            name="site"
            validate={[
              validations.required,
              validations.spaceBetween,
              validations.uppercase,
              validations.specialCharacters
            ]}
            component={ReduxTextField}
            inputProps={{ maxlength: 25 }}
            InputProps={{
              className: classes.input,
              endAdornment: (
                <InputAdornment position="end">{`.sentro.ph${
                  props.type === "order" ? "/orders" : ""
                }`}</InputAdornment>
              )
            }}
          />
        </div>
      </div>
      <div className="pt-3">
        <div style={{ fontSize: 12 }}>Mobile number</div>
        <Field
          id="mobile"
          name="mobile"
          validate={[validations.required, validations.mobileNumber]}
          component={ReduxTextField}
          inputProps={{ maxlength: 11 }}
          InputProps={{ className: classes.input }}
        />
      </div>
    </div>
  );
}

let Form = reduxForm({
  form: "create_shop",
  enableReinitialize: true,
  destroyOnUnmount: false,
  asyncValidate: asyncStoreValidations,
  asyncChangeFields: ["name", "site"]
})(ShopForm);

export default Form;
