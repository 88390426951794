import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { ReactComponent as ShopeeIcon } from "../../../../assets/images/shopee_logo.svg";
import { ReactComponent as Lazada } from "../../../../assets/images/lazadaLogo.svg";
import Kumu from "../../../../assets/images/kumuLogo.png";

import {
  Drawer,
  IconButton,
  Badge,
} from "@material-ui/core";

import Path from "../../../../Paths";

import StoreActions from "./StoreActions";


const SideBar = props => {
  const { isPreview } = props;
  const {
    storeInfo,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const drawerWidth = 280;
  let color = props.storeInfo && props.storeInfo.color;
  const LeftistLayout = makeStyles(theme => ({
    drawer: {
      width: 0,
      flexShrink: 0,
      display: "none",
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
        display: "block",
      },
    },
    drawerPaper: {
      width: 0,
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      backgroundColor: color?.primary?.main
    },
    logoDiv: {

    },
    category: {
      color: color?.primary?.contrastText,
      padding: "10px 0",
      cursor: "pointer"
    },
    storeName: {
      fontFamily: storeInfo?.font?.family,
      flexGrow: 1,
      paddingLeft: 10,
      fontWeight: 500,
      fontSize: 24,
      cursor: "pointer",
      textTransform: "uppercase",
      color: color?.primary?.contrastText,
      [theme.breakpoints.down("xs")]: {
        fontSize: 16
      },
      marginBottom: 0
    },
    icon: {
      color: color?.primary?.contrastText,
    },
    copyright: {
      fontSize: 14,
      color: color?.primary?.contrastText,
    },
    poweredBySentro: {
      fontSize: 12,
      color: color?.primary?.contrastText,
      marginTop: 5
    },
  }));
  const classes = LeftistLayout();
  const location = useLocation();
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const path = location.pathname;
  let productList = useSelector(state => state.store.items);

  function createPreview(file) {
    var blob = new Blob([file], { type: file.type });
    var blobUrl = URL.createObjectURL(blob);

    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function() {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function() {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    return blobUrl;
  }


  function cartButton() {
    if (isPreview) {
      return null;
    } else {
      if (path.includes("cart") || productList.length === 0) {
        return () => {
          history.push(`/shop/${storeInfo.site}/cart`);
        };
      } else {
        return () => dispatch(StoreActions.showCartDrawer(true));
      }
    }
  }

  function handleGoToDashboard() {
    if (user) {
      if (user.is_seller && user.is_buyer) {
        history.push(Path.DASHBOARD);
      } else {
        history.push(Path.BUYER_DASHBOARD);
      }
    } else {
      history.push(`${path}/login`);
    }
  }

  const product_categories = useSelector(state => state.store.product_categories?.categories)

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <section className="d-flex align-items-center justify-content-center flex-column" style={{height: 140}}>
        <div className={`${classes.logoDiv} d-flex`}>
          <div
            id="logo_button"
            onClick={() =>
              isPreview ? null : history.push(`/shop/${props.storeInfo.site}`)
            }
            style={{
              width: 50,
              height: 50,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${
                props.storeInfo.logo_image
                  ? typeof logo === "object"
                    ? createPreview(props.storeInfo.logo_image)
                    : props.storeInfo.logo_image
                  : "#f97663"
              })`,
              backgroundColor:
                props.storeInfo && !props.storeInfo.logo_image
                  ? color && color.primary.contrastText
                  : "inherit",
              cursor: "pointer"
            }}
          />
          <Typography
            onClick={() =>
              props.isPreview
                ? null
                : history.push(`/shop/${storeInfo.site}`)
            }
            variant="h6"
            className={classes.storeName}
          >
            {props.storeInfo
              ? props.storeInfo.name
                ? props.storeInfo.name
                : ""
              : ""}
          </Typography>
        </div>
      </section>
      <section className="d-flex align-items-center justify-content-center" style={{height: 80}}>
        <div className="d-flex">
          <IconButton
            onClick={() =>
              props.isPreview ? null : handleGoToDashboard()
            }
          >
            <AccountCircleOutlinedIcon className={classes.icon} />
          </IconButton>
          <IconButton onClick={cartButton()}>
            <Badge
              color="secondary"
              badgeContent={productList.length}
            >
              <ShoppingCartOutlinedIcon className={classes.icon} />
            </Badge>
          </IconButton>
        </div>
      </section>
      <section className="d-flex align-items-center justify-content-center flex-column pt-2">
        {
          product_categories.map((category, index) => (
            <Typography
              key={index}
              onClick={() =>
                props.isPreview
                  ? null
                  : history.push(`/shop/${storeInfo.site}/products/?category=${encodeURIComponent(category)}&sortBy=-created_on`)
              }
              variant="p"
              className={classes.category}
            >
              {category}
            </Typography>
          ))
        }
      </section>
      <section className="py-3">
        <Box className="d-flex align-items-center justify-content-center"  style={{height: 80}}>
            {props.storeInfo ? (
              props.storeInfo.facebook ? (
                <IconButton
                  id="facebook_button"
                  style={{
                    color: color && color.primary.contrastText,
                    display:
                      props.storeInfo.facebook === "null" ? "none" : ""
                  }}
                  target="_blank"
                  href={
                    props.isPreview
                      ? null
                      : `http://facebook.com/${props.storeInfo.facebook}`
                  }
                >
                  <FacebookIcon style={{ fontSize: "20px" }} />
                </IconButton>
              ) : (
                " "
              )
            ) : (
              " "
            )}

            {props.storeInfo ? (
              props.storeInfo.instagram ? (
                <IconButton
                  id="instagram_button"
                  style={{
                    color: color && color.primary.contrastText,
                    display:
                      props.storeInfo.instagram === "null" ? "none" : ""
                  }}
                  target="_blank"
                  href={
                    props.isPreview
                      ? null
                      : `http://instagram.com/${props.storeInfo.instagram}`
                  }
                >
                  <InstagramIcon style={{ fontSize: "20px" }} />
                </IconButton>
              ) : (
                " "
              )
            ) : (
              " "
            )}
            {props.storeInfo ? (
              props.storeInfo.shopee ? (
                <IconButton
                  id="shopee_button"
                  style={{
                    color: color && color.primary.contrastText,
                    paddingTop: "1vh",
                    display: props.storeInfo.shopee === "null" ? "none" : ""
                  }}
                  target="_blank"
                  href={
                    props.isPreview
                      ? null
                      : `http://shopee.com/${props.storeInfo.shopee}`
                  }
                >
                  <ShopeeIcon
                    fill={color && color.primary.contrastText}
                    style={{
                      width: 20,
                      height: 20
                    }}
                  />
                </IconButton>
              ) : (
                " "
              )
            ) : (
              " "
            )}
            {props.storeInfo ? (
              props.storeInfo.lazada ? (
                <IconButton
                  id="lazada_button"
                  style={{
                    color: color && color.primary.contrastText,
                    paddingTop: "1vh",
                    display: props.storeInfo.lazada === "null" ? "none" : ""
                  }}
                  target="_blank"
                  href={
                    props.isPreview
                      ? null
                      : `http://lazada.com.ph/shop/${props.storeInfo.lazada}`
                  }
                >
                  <Lazada
                    fill={color && color.primary.contrastText}
                    style={{
                      width: 22,
                      height: 22
                    }}
                  />
                </IconButton>
              ) : (
                " "
              )
            ) : (
              " "
            )}

            {props.storeInfo ? (
              props.storeInfo.kumu ? (
                <IconButton
                  id="kumu_button"
                  style={{
                    color: color && color.primary.contrastText,
                    paddingTop: "1vh",
                    display: props.storeInfo.kumu === "null" ? "none" : ""
                  }}
                  target="_blank"
                  href={
                    props.isPreview
                      ? null
                      : `http://app.kumu.ph/${props.storeInfo.kumu}`
                  }
                >
                  <img
                    alt="kumu"
                    src={Kumu}
                    style={{ width: 22, height: 22, marginTop: 0 }}
                  />
                </IconButton>
              ) : (
                " "
              )
            ) : (
              " "
            )}
        </Box>
      </section>
      <section className="d-flex flex-column align-items-center justify-content-center">
        <Typography
          onClick={() =>
            props.isPreview
              ? null
              : history.push(`/shop/${storeInfo.site}`)
          }
          variant="p"
          className={classes.copyright}
        >
         
         Copyright © 2021 
            {props.storeInfo
              ? props.storeInfo.name
                ? props.storeInfo.name
                : ""
              : ""}
        </Typography>
        <Typography
          onClick={() =>
            props.isPreview
              ? null
              : history.push(`/shop/${storeInfo.site}`)
          }
          variant="p"
          className={classes.poweredBySentro}
        >
         Powered by <strong>Sentro</strong>
        </Typography>
      </section>
    </Drawer>
  )
};

export default SideBar;
