import React from "react";
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';

const useStyles = theme => ({
  root: {
    position: "fixed",
    // height: 200,
    backgroundColor: "white",
    top: 68,
    left: 300,
    width: "calc(100% - 300px)",
    padding: "2em 4em",
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      left: 0
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
  },
  marginDivider: {
    marginTop: "1em"
  },
  flex: {
    display: "flex",
  },
  title: {
    fontSize: 28, 
    color: "#002C42",
    fontWeight: "bold",
    marginRight: "auto",
    marginBottom: 0
  },
  searchBar: {
		background: "white",
		maxWidth: "240px",
		borderRadius: "4px",
		color: "gray",
    fontSize: 12,
    height: 35,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "100%",
			width: "100%"
		},
  },
  button: {
    padding: "0 2em",
    height: 35
  },
  filterWrapper: {
    minWidth: 100,
    display: "flex",
    marginRight: "1.5em",
    cursor: "pointer",
    transition: "all 0.25s ease-in",
    padding: "0 5px",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.03)"
    }
  },
  filterTitle: {
    marginBottom: 0,
    marginRight: "1.5em",
    fontSize: 16,
    marginRight: "auto",
  },
  filterChip: {
    margin: "0 5px"
  }
});

class FilterHeader extends React.Component {
  
  render (){
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={clsx(classes.flex, classes.marginDivider)}>
          <h2 className={classes.title}>Plugins and Apps</h2>
          <OutlinedInput
            className={classes.searchBar}
            id="app_marketplace_search"
            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
            aria-describedby="app_marketplace_search"
            inputProps={{
              'aria-label': 'weight',
            }}
            labelWidth={0}
            margin="dense"
            placeholder="Payroll and HR system"
          />
          <Button
            id="delete_button"
            color="primary"
            disableElevation 
            className={classes.button}
            variant="outlined"
            style={{marginLeft: 10}}
          >
            Search
          </Button>
        </div>
        <div className={clsx(classes.flex, classes.marginDivider)}>
          <div className={classes.filterWrapper}>
            <p className={classes.filterTitle}>Sort by</p>
            <ExpandMoreIcon />
          </div>
          <Chip
            variant="outlined"
            size="small"
            label={"Alphabetical: A-Z"}
            onDelete={() => console.log("clicked delete")}
            className={classes.filterChip}
          />
          <Chip
            variant="outlined"
            size="small"
            label={"Newest first"}
            onDelete={() => console.log("clicked delete")}
            className={classes.filterChip}
          />
        </div>
        <div className={clsx(classes.flex, classes.marginDivider)}>
          <div className={classes.filterWrapper}>
            <p className={classes.filterTitle}>Filter by</p>
            <ExpandMoreIcon />
          </div>
          <Chip
            variant="outlined"
            size="small"
            label={"No filters applied"}
            onDelete={() => console.log("clicked delete")}
            className={classes.filterChip}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(useStyles)(FilterHeader);
