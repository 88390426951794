import React from 'react';
import Blank from './blank';
import Header from './AppBar';
import PromoBannerModal from './PromoBanner12.12';
import PreviewBanner from './PreviewBanner';
import FeaturedTemplate from './featuredProducts/featuredTemplate';
import PreviewAboutTemplate from './aboutus/previewAboutTemplate';
import Footer from './Footer';
import { useDispatch } from 'react-redux';
import Contact from './contact';
import { makeStyles, useMediaQuery, Container } from '@material-ui/core';
import SideBar from './SideBar';
import HeaderFilter from './headerFilter/filter';
import { useTheme } from '@material-ui/styles';
import JapandiHomepage from '../japandi/pages/home';
import NFTHomepage from '../nft/pages/home';

const HomeContent = (props) => {
  const {
    storeInfo,
    onSelect,
    productList,
    largeDesktopView,
    errorMaintenance,
    classes,
  } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let color = props.storeInfo && props.storeInfo.color;
  const LeftistLayout = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
    },
  }));
  const leftistStyles = LeftistLayout();

  function setHomeContent() {
    switch (storeInfo ? storeInfo.template : 'Blank') {
      case 'Blank':
        return <Blank />;

      case 'Leftist':
        return (
          <div className={leftistStyles.root}>
            <SideBar isPreview={props.isPreview} storeInfo={storeInfo} />
            <main className={leftistStyles.content}>
              <div className={leftistStyles.appBar}>
                <Header
                  isPreview={props.isPreview}
                  storeInfo={storeInfo}
                  color={true}
                  logo={true}
                  shadow={true}
                />
              </div>
              {errorMaintenance ? (
                <div className={classes.errorMessage}>
                  ADVISORY: We are currently undergoing system maintenance.
                  Online purchase is currently deactivated. Kindly coordinate
                  with us directly through any of our contact details below if
                  you want to inquire or purchase anything. Thank you for your
                  understanding and continued support.
                </div>
              ) : (
                ''
              )}
              <PromoBannerModal />
              <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
              <FeaturedTemplate
                onSelect={onSelect}
                store={storeInfo}
                productList={productList}
                isPreview={props.isPreview}
              />
              <PreviewAboutTemplate
                largeDesktopView={largeDesktopView}
                isPreview={props.isPreview}
                store={storeInfo}
              />
              <Footer isPreview={props.isPreview} store={storeInfo} />
            </main>
          </div>
        );
      case 'Classic':
        return (
          <div>
            <Header
              isPreview={props.isPreview}
              storeInfo={storeInfo}
              color={true}
              logo={true}
              shadow={true}
            />
            {errorMaintenance ? (
              <div className={classes.errorMessage}>
                ADVISORY: We are currently undergoing system maintenance. Online
                purchase is currently deactivated. Kindly coordinate with us
                directly through any of our contact details below if you want to
                inquire or purchase anything. Thank you for your understanding
                and continued support.
              </div>
            ) : (
              ''
            )}
            <PromoBannerModal />
            <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
            <PreviewAboutTemplate
              largeDesktopView={largeDesktopView}
              isPreview={props.isPreview}
              store={storeInfo}
            />
            {storeInfo.template === 'Minimalist' ? (
              <Contact isPreview={props.isPreview} store={storeInfo} />
            ) : (
              ''
            )}
            <div>
              <FeaturedTemplate
                onSelect={onSelect}
                store={storeInfo}
                productList={productList}
                isPreview={props.isPreview}
              />
            </div>
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );
      case 'Contemporary':
        return (
          <div>
            <Header
              isPreview={props.isPreview}
              storeInfo={storeInfo}
              color={true}
              logo={true}
              shadow={true}
            />
            {errorMaintenance ? (
              <div className={classes.errorMessage}>
                ADVISORY: We are currently undergoing system maintenance. Online
                purchase is currently deactivated. Kindly coordinate with us
                directly through any of our contact details below if you want to
                inquire or purchase anything. Thank you for your understanding
                and continued support.
              </div>
            ) : (
              ''
            )}
            <PromoBannerModal />
            <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
            <PreviewAboutTemplate
              largeDesktopView={largeDesktopView}
              isPreview={props.isPreview}
              store={storeInfo}
            />
            {storeInfo.template === 'Minimalist' ? (
              <Contact isPreview={props.isPreview} store={storeInfo} />
            ) : (
              ''
            )}
            <div>
              <FeaturedTemplate
                onSelect={onSelect}
                store={storeInfo}
                productList={productList}
                isPreview={props.isPreview}
              />
            </div>
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );
      case 'Minimalist':
        return (
          <div>
            <Header
              isPreview={props.isPreview}
              storeInfo={storeInfo}
              color={true}
              logo={true}
              shadow={true}
            />
            {errorMaintenance ? (
              <div className={classes.errorMessage}>
                ADVISORY: We are currently undergoing system maintenance. Online
                purchase is currently deactivated. Kindly coordinate with us
                directly through any of our contact details below if you want to
                inquire or purchase anything. Thank you for your understanding
                and continued support.
              </div>
            ) : (
              ''
            )}
            <PromoBannerModal />
            <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
            <PreviewAboutTemplate
              largeDesktopView={largeDesktopView}
              isPreview={props.isPreview}
              store={storeInfo}
            />
            {storeInfo.template === 'Minimalist' ? (
              <Contact isPreview={props.isPreview} store={storeInfo} />
            ) : (
              ''
            )}
            <div>
              <FeaturedTemplate
                onSelect={onSelect}
                store={storeInfo}
                productList={productList}
                isPreview={props.isPreview}
              />
            </div>
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );

      case 'ClassicV2':
        return (
          <div style={{ backgroundColor: '#f3f3f3' }}>
            <Header isPreview={props.isPreview} storeInfo={storeInfo} />
            {errorMaintenance ? (
              <div className={classes.errorMessage}>
                ADVISORY: We are currently undergoing system maintenance. Online
                purchase is currently deactivated. Kindly coordinate with us
                directly through any of our contact details below if you want to
                inquire or purchase anything. Thank you for your understanding
                and continued support.
              </div>
            ) : (
              ''
            )}
            <PromoBannerModal />
            <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
            <FeaturedTemplate
              onSelect={onSelect}
              store={storeInfo}
              productList={productList}
              isPreview={props.isPreview}
            />
            <PreviewAboutTemplate
              largeDesktopView={largeDesktopView}
              isPreview={props.isPreview}
              store={storeInfo}
            />
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );

      case 'ContemporaryV2':
        return (
          <div style={{ backgroundColor: '#fff' }}>
            <Header isPreview={props.isPreview} storeInfo={storeInfo} />
            {errorMaintenance ? (
              <div className={classes.errorMessage}>
                ADVISORY: We are currently undergoing system maintenance. Online
                purchase is currently deactivated. Kindly coordinate with us
                directly through any of our contact details below if you want to
                inquire or purchase anything. Thank you for your understanding
                and continued support.
              </div>
            ) : (
              ''
            )}
            <PromoBannerModal />
            <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
            <FeaturedTemplate
              onSelect={onSelect}
              store={storeInfo}
              productList={productList}
              isPreview={props.isPreview}
            />
            <PreviewAboutTemplate
              largeDesktopView={largeDesktopView}
              isPreview={props.isPreview}
              store={storeInfo}
            />
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );
      case 'SariSari':
        return (
          <div style={{ backgroundColor: '#fff' }}>
            <Header isPreview={props.isPreview} storeInfo={storeInfo} />
            {errorMaintenance ? (
              <div className={classes.errorMessage}>
                ADVISORY: We are currently undergoing system maintenance. Online
                purchase is currently deactivated. Kindly coordinate with us
                directly through any of our contact details below if you want to
                inquire or purchase anything. Thank you for your understanding
                and continued support.
              </div>
            ) : (
              ''
            )}
            <PromoBannerModal />
            {isMobile ? (
              ''
            ) : (
              <div
                style={{
                  marginTop: '5em',
                  backgroundColor: color.primary.main,
                }}
              >
                <HeaderFilter storeInfo={storeInfo} />
              </div>
            )}

            <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
            <FeaturedTemplate
              onSelect={onSelect}
              store={storeInfo}
              productList={productList}
              isPreview={props.isPreview}
            />
            <PreviewAboutTemplate
              largeDesktopView={largeDesktopView}
              isPreview={props.isPreview}
              store={storeInfo}
            />
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );
      case 'Pastel':
        return (
          <div style={{ backgroundColor: '#fff' }}>
            <Header isPreview={props.isPreview} storeInfo={storeInfo} />
            <div
              className="px-md-5 px-0"
              style={{ backgroundColor: color && color.primary.main }}
            >
              {isMobile ? (
                <div className="mt-5">
                  <PreviewBanner
                    store={storeInfo}
                    isPreview={props.isPreview}
                  />
                  <FeaturedTemplate
                    onSelect={onSelect}
                    store={storeInfo}
                    productList={productList}
                    isPreview={props.isPreview}
                  />
                  <PreviewAboutTemplate
                    largeDesktopView={largeDesktopView}
                    isPreview={props.isPreview}
                    store={storeInfo}
                  />
                </div>
              ) : (
                <Container
                  className="bg-white p-5"
                  style={{ marginTop: '5em' }}
                >
                  <PreviewBanner
                    store={storeInfo}
                    isPreview={props.isPreview}
                  />
                  <FeaturedTemplate
                    onSelect={onSelect}
                    store={storeInfo}
                    productList={productList}
                    isPreview={props.isPreview}
                  />
                  <PreviewAboutTemplate
                    largeDesktopView={largeDesktopView}
                    isPreview={props.isPreview}
                    store={storeInfo}
                  />
                </Container>
              )}
            </div>
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );
      case 'MinimalistV2':
        return (
          <div style={{ backgroundColor: '#fff' }}>
            <Header isPreview={props.isPreview} storeInfo={storeInfo} />
            <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
            <FeaturedTemplate
              onSelect={onSelect}
              store={storeInfo}
              productList={productList}
              isPreview={props.isPreview}
            />
            <PreviewAboutTemplate
              largeDesktopView={largeDesktopView}
              isPreview={props.isPreview}
              store={storeInfo}
            />
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );
      case 'Blocks':
        return (
          <div style={{ backgroundColor: '#fff' }}>
            <Header isPreview={props.isPreview} storeInfo={storeInfo} />
            <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
            <FeaturedTemplate
              onSelect={onSelect}
              store={storeInfo}
              productList={productList}
              isPreview={props.isPreview}
            />
            <PreviewAboutTemplate
              largeDesktopView={largeDesktopView}
              isPreview={props.isPreview}
              store={storeInfo}
            />
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );
      case 'Greenery':
        return (
          <div style={{ backgroundColor: '#fff' }}>
            <Header isPreview={props.isPreview} storeInfo={storeInfo} />
            <PreviewBanner store={storeInfo} isPreview={props.isPreview} />
            <FeaturedTemplate
              onSelect={onSelect}
              store={storeInfo}
              productList={productList}
              isPreview={props.isPreview}
            />
            <PreviewAboutTemplate
              largeDesktopView={largeDesktopView}
              isPreview={props.isPreview}
              store={storeInfo}
            />
            <Footer isPreview={props.isPreview} store={storeInfo} />
          </div>
        );
      case 'Japandi':
        return (
          <div className="bg-white d-md-flex d-block position-relative">
            <Header isPreview={props.isPreview} storeInfo={storeInfo} />
            <JapandiHomepage
              isPreview={props.isPreview}
              storeInfo={storeInfo}
              productList={productList}
              onSelect={onSelect}
            />
          </div>
        );
      case 'NFT':
        return (
          <>
            <Header
              isPreview={props.isPreview}
              storeInfo={storeInfo}
              color={true}
              logo={true}
              shadow={true}
            />
            <NFTHomepage
              isPreview={props.isPreview}
              storeInfo={storeInfo}
              productList={productList}
              onSelect={onSelect}
            />
          </>
        );
      default:
        return <Blank />;
    }
  }

  return <div>{setHomeContent(storeInfo ? storeInfo.template : 'Blank')}</div>;
};

export default HomeContent;
