import React from "react";
import SentroAlert from "../../common/alert";
import {
  Box,
  makeStyles,
  Container,
  Typography,
  Link
} from "@material-ui/core";
import logo from "../../../assets/images/sentro_vertical.png";
import Text from "../../../common/text";
import { useDispatch, useSelector } from "react-redux";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { resendCode } from "../registerActions";
import { Alert } from "antd";
import SentroLoading from "../../common/loading";
import { useCountdownTimer } from "../../hooks/countdown";


const Verification = props => {
  const VerificationStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60%",
      height: "100vh",
      background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    verification: {
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    box1: {
      marginTop: "35vh",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },
    box2: {
      padding: "10vh 5vh 0 ",
      [theme.breakpoints.down("xs")]: {
        padding: "6vh 5vw"
      }
    }
  }));

  const classes = VerificationStyles();
  const dispatch = useDispatch();
  let resendSuccess = useSelector(state => state.signUp.resendSuccess);
  let loading = useSelector(state => state.signUp.loading);


  let email = localStorage.getItem("EMAIL_FOR_SIGNUP");
  const [secondsLeft, setSecondsLeft, resendCountdownDisplay] = useCountdownTimer({
    save_to_local: true,
    module: 'sme/signup_resend_verification',
    key: email
  });
  const resend = () => {
    dispatch(resendCode(true));
    setSecondsLeft(60*10);
  }

  const spinner = (
    <div className="loading-spinner text-secondary mb-0 d-flex align-items-center">
      <div className="spinner-border mx-auto text-secondary" role="status" style={{ 
        fontSize: 8, height: 15, width: 15
      }}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>)

  return (
    <div className={classes.root}>
      <SentroLoading open={loading} />
      <Box className={classes.banner}>
        <Container>
          <Box className={classes.box1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={logo} style={{ width: 150 }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "2vh" }}
                textType="h2"
                content="Create your online shop here."
              />
            </div>
          </Box>
        </Container>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            style={{
              fontSize: 13,
              textAlign: "center",
              color: "#fff",
              position: "absolute",
              bottom: 25
            }}
          >
            This works best in the latest Google desktop browser.
          </Typography>
        </div>
      </Box>
      <Box className={classes.verification}>
        <Container>
          <Box className={classes.box2}>
            <Text
              style={{ color: "#002C42" }}
              textType="h3"
              content="Account Verification"
            />
            {resendSuccess ? (
              <Alert
                id="alert_message"
                closable
                message="Email sent. Please check your inbox."
                type="success"
                style={{ marginTop: "1vh", marginBottom: "2vh" }}
              />
            ) : (
              ""
            )}
            <Text
              style={{ marginTop: "3vh", color: "#54575F" }}
              textType="p1"
              content="We’re almost there! Kindly check your e-mail to get the activation link. If you did not get the e-mail within a few minutes, check your spam folder."
            />
            <Box style={{ marginTop: "4vh" }}>
              <div className="d-flex">
                <p className="mb-0 mr-2">Did not receive the email?{" "}</p>
                {
                  loading ? 
                    spinner
                    :
                    secondsLeft > 0 ?
                    resendCountdownDisplay :
                    <Link
                      id="resend_link"
                      style={{
                        fontWeight: "bold",
                        marginLeft: 1,
                        textDecoration: "none"
                      }}
                      onClick={resend}
                    >
                      Resend
                    </Link>
                }
              </div>
            </Box>

            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#2B2D33",
                  position: "absolute",
                  bottom: 35
                }}
              >
                Need help?
              </Typography>

              <div style={{ position: "absolute", bottom: 5, display: "flex" }}>
                <MailOutlineIcon style={{ color: "#828894", width: 17 }} />
                <a
                  href="mailto:support@sentro.ph"
                  style={{
                    color: "#F97663",
                    fontSize: 13,
                    cursor: "pointer",
                    fontWeight: 600,
                    marginLeft: 10
                  }}
                >
                  support@sentro.ph
                </a>
              </div>
            </div>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Verification;
