import axios from "axios";
import { signOff } from "../components/auth/authActions";
import history from "../store/history";
import Path from "../Paths";
import { useDispatch } from "react-redux";

let sentroAxios = axios.create({
  headers: {
    Authorization: `JWT ${localStorage.getItem("TOKEN")}`
  }
});

sentroAxios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status == 401) {
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("CURRENT_USER");
      sessionStorage.removeItem("CURRENT_USER");
      sessionStorage.removeItem("TOKEN");
      history.push(Path.LOGIN);
    } else {
      throw error;
    }
  }
);

export default sentroAxios;
