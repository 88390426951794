import axios from "axios";
import apiConfig from "../../../common/apiConfig";
import history from "../../../store/history";
import Path from "../../../Paths";

class Services {
  static async fetchApps() {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/apps`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async fetchApp(id) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/apps/${id}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async createApp(data) {
    try {
      /// create app
      let request_data = new FormData();
      for (var key in data) {
        switch (key) {
          case "variants":
            request_data.append("variants", JSON.stringify(data["variants"]));
            break;
          case "images":
            for (let i in data["images"]) {
              request_data.append(
                `gallery-${parseInt(i) + 1}`,
                data["images"][i],
                data["images"][i].name
              );
            }
            break;
          default:
            request_data.append(key, data[key]);
            break;
        }
      }
      let response = await axios.post(
        `${apiConfig.apiURL}/api/marketplace/apps/`,
        request_data,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      history.push(Path.ADMIN_APP_MARKETPLACE);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async editApp(id, data) {
    try {
      /// create app
      let request_data = new FormData();
      for (var key in data) {
        switch (key) {
          case "variants":
            request_data.append("variants", JSON.stringify(data["variants"]));
            break;
          case "images":
            for (let i in data["images"]) {
              if (typeof data["images"][i] === "object") {
                request_data.append(
                  `gallery-${parseInt(i) + 1}`,
                  data["images"][i],
                  data["images"][i].name
                );
              } else {
                request_data.append(
                  `gallery-${parseInt(i) + 1}`,
                  data["images"][i]
                );
              }
            }
            break;
          default:
            request_data.append(key, data[key]);
            break;
        }
      }
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/marketplace/apps/${id}/`,
        request_data,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      history.push(Path.ADMIN_APP_MARKETPLACE);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async checkKYC() {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/bux-kyc/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async marketplaceCheckout(data) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/marketplace/checkout/`,
        data,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );

      window.location.href =
        data.amount > 0
          ? `${apiConfig.buxURL}/checkout/${response.data.uid}/?redirect_url=${apiConfig.localHost}marketplace/checkout/details?ref_id=${response.data.ref_id}`
          : `${apiConfig.localHost}marketplace/checkout/details?ref_id=${response.data.ref_id}`;
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  static async marketplaceCheckoutDetails(ref_id) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/marketplace/checkout/details/?ref_id=${ref_id}`,
        {},
        {
          headers: {
            // Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
}
export default Services;
