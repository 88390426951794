import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FilterSection from "./filterSection";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Button, Checkbox, TablePagination } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import sample_img from "../../../assets/images/imgPlaceholder2.svg";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import { withStyles } from "@material-ui/styles";
import { ReactComponent as Unchecked } from "../../../assets/images/checkbox_unchecked.svg";
import { ReactComponent as Copy } from "../../../assets/images/copy.svg";
import { ReactComponent as Edit } from "../../../assets/images/edit.svg";
import MediaActions from "../redux/actions";
import CopyToClipboard from "react-copy-to-clipboard";
import { showAlert } from "../../common/commonActions";
import EditImage from "./editImage";
export default function List(props) {
  const useStyles = makeStyles(theme => ({
    form: {
      backgroundColor: "#fff",
      borderRadius: 4,
      minHeight: "70vh"
    },
    img: {
      width: 128,
      height: 128,
      borderRadius: 4,
      objectFit: "cover"
    },
    url: {
      fontSize: 8,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    },
    urlBtn: {
      color: "#858997",
      borderColor: "#858997",
      padding: 7,
      width: "58%",
      height: 30
    },
    editBtn: {
      minWidth: 35,
      width: 35,
      padding: 7,
      height: 30
    }
  }));

  const dispatch = useDispatch();
  const classes = useStyles();
  const [edit, setEdit] = React.useState({
    open: false,
    item: ""
  });
  const selected_images = useSelector(
    state => state.mediaLibrary.selected_images
  );

  const handleSelected = item => {
    if (selected_images.map(item => item.id).includes(item.id)) {
      const index = selected_images.map(item => item.id).indexOf(item.id);
      const newSelected = [...selected_images];
      newSelected.splice(index, 1);
      dispatch(MediaActions.setSelectedImages(newSelected));
    } else {
      dispatch(MediaActions.setSelectedImages([...selected_images, item]));
    }
  };

  const onPageChange = (e, page) => {
    props.setParams(state => ({
      ...state,
      page: page+1
    }))
  }
  return (
    <>
      <Paper className={`${classes.form} p-4 mt-4`}>
        <FilterSection value={props.params} onChange={props.setParams} onDelete={() => {
          props.onDelete(selected_images.map(i => i.id))
        }}/>
        <div className="row p-3">
          {props.images.map((item) => {
            const url = item.thumbnail || item.image;
            return (
              <div key={item.id} className="col-sm-4 col-md-2 p-0 mb-3" style={{ position: "relative" }}>
                <Checkbox
                  color="primary"
                  icon={<Unchecked />}
                  style={{ position: "absolute" }}
                  checked={selected_images.map(item => item.id).includes(item.id)}
                  onChange={() => handleSelected(item)}
                />
                <img src={url} alt={url} className={classes.img} />
                <div className="d-flex align-items-center">
                  <CopyToClipboard
                    onCopy={() =>
                      dispatch(
                        showAlert({
                          type: "success",
                          message: "Copied to clipboard!"
                        })
                      )
                    }
                    text={item.image}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      endIcon={<Copy style={{ fontSize: 14 }} />}
                      className={`${classes.urlBtn} mt-2 mr-1`}
                    >
                      <div className={classes.url}>{item.image}</div>
                    </Button>
                  </CopyToClipboard>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={`${classes.editBtn} mt-2  edit-image-button`}
                    onClick={() => setEdit({ open: true, item: item })}
                  >
                    <Edit color="secondary" style={{ fontSize: 14 }} />
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </Paper>
      <div className="d-flex justify-content-between align-items-center">
        <div style={{ color: "#54575f", fontSize: 13 }}>{props.totalItemCount} item(s) found</div>
        <TablePagination
          className="pagination"
          component="div"
          rowsPerPageOptions={[]}
          count={props.totalItemCount}
          rowsPerPage={props.params.itemsPerPage}
          page={props.params.page-1}
          onPageChange={onPageChange}
        />
      </div>
      <EditImage edit={edit} setEdit={setEdit} />
    </>
  );
}
