import React from "react";
import { reduxForm, Field } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import { ReduxInitializeFormActions } from "../../../websiteBuilderActions";
import { useSelector, connect, useDispatch } from "react-redux";
import LargeImageField from "../../../../reduxFields/largeImageField";
function VariantImageUpload(props) {
  const { variantSelections } = props;
  const useStyles = makeStyles(theme => ({}));
  const classes = useStyles();
  const dispatch = useDispatch();
  const values = useSelector(state => state.form.variant_choices?.values);
  const transformToReduxFormData = data => {
    // transform data for redux form;
    // convert to flat data for redux form
    // [
    //   {
    //     price: -
    //     quantity: -
    //     sku: -
    //   },
    //   {
    //     price: -
    //     quantity: -
    //     sku: -
    //   }
    // ]
    // --------- to ------------
    // {
    //   price-0
    //   quantity-0
    //   sku-0
    //   price-1
    //   quantity-1
    //   sku-1
    // }
    let reduxFormTransformedData = {};
    data.forEach((item, index) => {
      Object.keys(item).forEach(key => {
        const data = item[key];
        reduxFormTransformedData[`${key}-${index}`] =
          key === "images"
            ? data
              ? { cropped_image: data, raw_image: data }
              : []
            : data;
      });
    });
    return reduxFormTransformedData;
  };
  React.useEffect(() => {
    let reduxFormTransformedData = transformToReduxFormData(variantSelections);
    props.initializeData(reduxFormTransformedData);
  }, [variantSelections]);

  return (
    <div>
      {variantSelections && variantSelections.length ? (
        variantSelections.map((item, key) => {
          return (
            <div key={key}>
              <Field
                fullWidth
                id={`images-${key}`}
                name={`images-${key}`}
                label={`${item.variants[0].name} / ${item.variants[1].name}`}
                useCropper
                defaultCropperRatio="landscape"
                component={LargeImageField}
                height={240}
              />
            </div>
          );
        })
      ) : (
        <div style={{ fontSize: 12, color: "#909196" }} className="mt-3">
          This product has no variants.
        </div>
      )}
    </div>
  );
}

let Form = reduxForm({
  form: "variant_choices",
  enableReinitialize: true
})(VariantImageUpload);

Form = connect(
  state => ({
    initialValues: state.webBuilder.reduxForm.productSelection
  }),
  {
    initializeData: ReduxInitializeFormActions.initializeProductSelectionsData
  }
)(Form);

export default Form;
