import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  Dialog,
  useMediaQuery,
  useTheme,
  Button,
  Badge,
  Popover,
  Container,
  Link,
  Menu,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/Menu';
import { useLocation } from 'react-router-dom';
import defaultSRC from './defaultResources';
import history from './../../../../store/history';
import { useSelector, useDispatch } from 'react-redux';
import AppBarTemplate from './appBar/appBarTemplate';
import SentroButton from '../../sentroButton';
import defaultColor from './colors';
import { PromoBannerSticky } from './PromoBanner12.12';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { makeStyles } from '@material-ui/core/styles';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CartDrawer from './cart/cartDrawer';
import Path from '../../../../Paths';
import StoreActions from './StoreActions';
import NFTActions from '../nft/redux/actions';
import { getProductsWeight } from './cart/getProductWeight';
import {
  Facebook,
  Instagram,
  AccountCircleOutlined,
  ShoppingCartOutlined,
} from '@material-ui/icons';
import { ReactComponent as ShopeeIcon } from '../../../../assets/images/shopee_logo.svg';
import { ReactComponent as Lazada } from '../../../../assets/images/lazadaLogo.svg';
import Kumu from '../../../../assets/images/kumuLogo.png';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import CategoriesDialog from './appBar/categories';
import Japandi from '../japandi/components/sidebar';
import NFTConnectMetamaskModal from '../nft/components/connectWalletModal';
import NFTConfirmationModal from '../nft/components/confirmationModal';
import { getDisplayWallet } from '../../../../utils/Metamask';
import apiConfig from '../../../../common/apiConfig';

const Header = (props) => {
  let dispatch = useDispatch();
  let storeValue = useSelector(state => state.store.store);
  let storeInfoValue = props.storeInfo;
  let storeInfo = props.isPreview ? storeInfoValue : storeValue;
  let color = storeInfo ? storeInfo.color : '';
  let token = sessionStorage.getItem('TOKEN');
  let user = JSON.parse(sessionStorage.getItem('CURRENT_USER'));

  function secondaryFont() {
    if (storeInfo) {
      if (storeInfo.font === null || storeInfo.font === undefined) {
        return defaultSRC[storeInfo ? storeInfo.template : 'Classic']
          .secondaryFont;
      } else {
        return storeInfo.font.body;
      }
    } else {
      return defaultSRC[storeInfo ? storeInfo.template : 'Classic']
        .secondaryFont;
    }
  }

  const location = useLocation();
  const path = location.pathname;
  const param = path.replace('/cart', '');
  const storeName = param.replace('/shop/', '');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [openConnectMetamask, setOpenConnectMetamask] = React.useState(false);
  const [showDisconnectWallet, setShowDisconnectWallet] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCart, setOpenCart] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let productList = useSelector((state) => state.store.items);
  let customer_details = useSelector((state) => state.store.customerDetails);
  let delivery_details = useSelector((state) => state.store.deliveryDetails);
  let other_store_items = useSelector((state) => state.store.other_store_items);
  let walletAddress = useSelector((state) => state.nft.walletAddress);
  const pickup_address = useSelector(
    (state) => state.store.store.pickup_address
  );
  const cart_drawer = useSelector((state) => state.store.cart_drawer);
  const couriers_shipping_fee = useSelector(
    (state) => state.store.couriers_shipping_fee
  );
  const product_categories = useSelector(
    state => state.store.product_categories?.categories
  );
  const handleClickMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const alamatSiteUrl = apiConfig.alamatSiteUrl;

  const handleClose = (e) => {
    setAnchorEl(null);
    setOpen(false);
  };

  const [position, setPosition] = React.useState(0);
  useScrollPosition(({ prevPos, currPos }) => {
    setPosition(currPos.y);
  });

  const transparent_bg = location.pathname === `/shop/${storeInfo.site}`;

  const handleOpenConnectWallet= () => {
    setOpenConnectMetamask(true);
  };

  const handleCloseConnectWallet = () => {
    setOpenConnectMetamask(false);
  };

  const handleShowLogout = () => {
    setShowDisconnectWallet(true);
  };

  const handleDisconnectWallet = () => {
    dispatch(NFTActions.DISCONNECT_WALLET());
    setShowDisconnectWallet(false);
  };

  function handleClickOpen(event) {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }

  function toggleDrawer() {
    dispatch(StoreActions.showCartDrawer(false));
  }

  function cartButton() {
    if (isPreview) {
      return null;
    } else {
      if (
        ['cart', 'delivery', 'delivery_details', 'payment'].some((i) =>
          location.pathname.includes(i)
        ) ||
        productList.length === 0
      ) {
        return () => {
          history.push(`/shop/${storeInfo.site}/cart`);
        };
      } else {
        return () => dispatch(StoreActions.showCartDrawer(true));
      }
    }
  }

  function handleGoToDashboard() {
    if (user.is_seller && user.is_buyer) {
      history.push(Path.DASHBOARD);
    } else {
      history.push(Path.BUYER_DASHBOARD);
    }
  }

  let isPreview = props.isPreview;

  React.useEffect(() => {
    if (user) {
      if (storeValue.site) {
        dispatch(StoreActions.getBuyerCart(storeValue.site));
      }
    }
  }, [storeValue.site]);

  React.useEffect(() => {
    let params = [...other_store_items];
    if (user) {
      if (storeValue.site) {
        for (let i in productList) {
          params.push({
            uuid: productList[i].uuid,
            quantity: productList[i].quantity,
            variant: productList[i].variants,
            productSelection: productList[i].productSelection,
          });
        }
        dispatch(
          StoreActions.patchBuyerCart(
            !productList.length && !other_store_items.length ? [{}] : params
          )
        );
      }
    }
    //COMPUTE NINJAVAN SHIPPING FEE IF CART IS UPDATED
    if (
      delivery_details.shipping === 'courier' &&
      delivery_details.delivery_detail.courier === 'ninjavan'
    ) {
      async function fetchNinjavanFee() {
        dispatch(
          StoreActions.ninjavanShippingFee({
            origin_province: pickup_address && pickup_address[0].province,
            destination_province: customer_details.provinceAddress,
            weight_kg: getProductsWeight(productList),
            l_cm: productList.length
              ? Math.max(...productList.map((item) => item.length))
              : 1,
            w_cm: productList.length
              ? Math.max(...productList.map((item) => item.width))
              : 1,
            h_cm: productList.length
              ? Math.max(...productList.map((item) => item.height))
              : 1,
          })
        );
      }
      fetchNinjavanFee();
    }
  }, [productList]);

  React.useEffect(() => {
    if (
      delivery_details.shipping === 'courier' &&
      delivery_details.delivery_detail.courier === 'ninjavan'
    ) {
      dispatch(
        StoreActions.setDeliveryDetails({
          ...delivery_details,
          shippingFee: couriers_shipping_fee.ninjavan,
        })
      );
    }
  }, [couriers_shipping_fee]);

  const getStyles = (template, theme) => {
    switch (template) {
      case 'Leftist':
        return {
          desktop: {
            display: 'none',
            flex: 4,
            justifyContent: 'flex-end',
            marginRight: 32,
            [theme.breakpoints.up('md')]: {
              display: 'flex',
            },
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            flexGrow: 1,
            paddingLeft: 10,
            fontWeight: 500,
            fontSize: 24,
            cursor: 'pointer',
            textTransform: 'uppercase',
            color: color.primary.contrastText,
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          },
          mobile: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              alignItems: 'center',
            },
          },
          dialog: {
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          },

          buttons: {
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              padding: '0px 100px',
            },
            [theme.breakpoints.down('xs')]: {
              padding: '0px 20px',
            },
          },
          cartIcon: {
            color: color.primary.contrastText,
            [theme.breakpoints.down('sm')]: {
              fontSize: 20,
            },
          },
        };
      case 'Classic':
        return {
          desktop: {
            display: 'none',
            flex: 4,
            justifyContent: 'flex-end',
            marginRight: 32,
            [theme.breakpoints.up('md')]: {
              display: 'flex',
            },
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            flexGrow: 1,
            paddingLeft: 10,
            fontWeight: 500,
            fontSize: 24,
            cursor: 'pointer',
            textTransform: 'uppercase',
            color: color.primary.contrastText,
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'inherit',
            },
          },
          mobile: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              alignItems: 'center',
            },
          },
          dialog: {
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          },

          buttons: {
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              padding: '0px 100px',
            },
            [theme.breakpoints.down('xs')]: {
              padding: '0px 20px',
            },
          },
          cartIcon: {
            color: color.primary.contrastText,
            [theme.breakpoints.down('sm')]: {
              fontSize: 20,
            },
          },
        };
      case 'Contemporary':
        return {
          desktop: {
            display: 'none',
            flex: 4,
            justifyContent: 'flex-end',
            marginRight: 32,
            [theme.breakpoints.up('md')]: {
              display: 'flex',
            },
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            flexGrow: 1,
            paddingLeft: 10,
            fontWeight: 500,
            fontSize: 24,
            cursor: 'pointer',
            textTransform: 'uppercase',
            color: color.primary.contrastText,
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'inherit',
            },
          },
          mobile: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              alignItems: 'center',
            },
          },
          dialog: {
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          },

          buttons: {
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              padding: '0px 100px',
            },
            [theme.breakpoints.down('xs')]: {
              padding: '0px 20px',
            },
          },
          cartIcon: {
            color: color.primary.contrastText,
            [theme.breakpoints.down('sm')]: {
              fontSize: 20,
            },
          },
        };
      case 'Minimalist':
        return {
          desktop: {
            display: 'none',
            flex: 4,
            justifyContent: 'flex-end',
            marginRight: 32,
            [theme.breakpoints.up('md')]: {
              display: 'flex',
            },
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            flexGrow: 1,
            paddingLeft: 10,
            color: color.primary.contrastText,
            fontWeight: 500,
            fontSize: 24,
            cursor: 'pointer',
            textTransform: 'uppercase',
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          },
          mobile: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              alignItems: 'center',
            },
          },
          dialog: {
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          },

          buttons: {
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              padding: '0px 100px',
            },
            [theme.breakpoints.down('xs')]: {
              padding: '0px 20px',
            },
          },
          cartIcon: {
            color: color.primary.contrastText,
            [theme.breakpoints.down('sm')]: {
              fontSize: 20,
            },
          },
        };
      case 'ClassicV2':
        return {
          menu: {
            color: color && color.primary.contrastText,
            fontSize: 14
          },
          toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 5vw',
            [theme.breakpoints.down('sm')]: {
              padding: '0 2vw',
            },
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            paddingLeft: 15,
            color: color.primary.contrastText,
            fontWeight: 500,
            fontSize: 24,
            cursor: 'pointer',
            textTransform: 'uppercase',
            [theme.breakpoints.down('xs')]: {
              fontSize: 14,
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          },
          cartIcon: {
            color: color.primary.contrastText
          },
          menuBtn: {
            fontSize: 14,
            color: color.primary.contrastText,
            marginTop: 0,
            fontWeight: 'normal',
            padding: '0 30px',
          },
          menuDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
              display: 'flex',
            },
          },
          menuMobile: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'block',
            },
          },
        };
      case 'ContemporaryV2':
        return {
          menu: {
            color: color && color.primary.contrastText,
            fontSize: 14
          },
          appbar: {
            backgroundColor: color && color.primary.main,
            height: 85,
            boxShadow: 'none',
            borderBottom: '1px solid #f3dfc6',
          },
          toolbar: {
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 4vw',
            [theme.breakpoints.down('sm')]: {
              padding: '0 2vw',
            },
          },
          menuMobile: {
            display: 'none',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              display: 'block',
            },
          },
          desktop: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          },
          menuBtn: {
            fontSize: 14,
            color: color.primary.contrastText,
            marginTop: 0,
            fontWeight: 'normal',
            padding: '0 30px',
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            paddingLeft: 15,
            color: color.primary.contrastText,
            fontWeight: 500,
            fontSize: 24,
            cursor: 'pointer',
            textTransform: 'uppercase',
            [theme.breakpoints.down('xs')]: {
              fontSize: 14,
              padding: 5,
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          },
          cartIcon: {
            color: color.primary.contrastText,
            [theme.breakpoints.down('sm')]: {
              fontSize: 20,
            },
          },
        };
      case 'SariSari':
        return {
          appbar: {
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: color && color.primary.main,
          },
          storeName: {
            marginLeft: 10,
            fontSize: 24,
            fontWeight: 600,
            textTransform: 'capitalize',
            color: color && color.primary.contrastText,
            fontFamily: storeInfo && storeInfo.font.family,
            [theme.breakpoints.down('sm')]: {
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          },
          colorPrimary: {
            color: color && color.primary.contrastText
          }
        };
      case 'Pastel':
        return {
          appbar: {
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: color && color.primary.main,
          },
          storeName: {
            marginLeft: 10,
            fontSize: 24,
            fontWeight: 600,
            textTransform: 'capitalize',
            color: color && color.primary.contrastText,
            fontFamily: storeInfo && storeInfo.font.family,
            [theme.breakpoints.down('sm')]: {
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          },
          colorPrimary: {
            color: color && color.primary.contrastText
          },
          menu: {
            color: color && color.primary.contrastText,
            fontSize: 14
          }
        };
      case 'MinimalistV2':
        return {
          appbar: {
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor:
              position >= -40 && transparent_bg
                ? 'transparent'
                : color && color.primary.main,
            position: 'fixed',
            transition: 'all 0.3s ease-in-out',
          },
          storename: {
            fontSize: 18,
            fontWeight: 700,
            textTransform: 'capitalize',
            color: color && color.primary.contrastText,
            position: 'absolute',
            textAlign: 'center',
            width: '100%',
            left: 0,
            right: 0,
            [theme.breakpoints.down('xs')]: {
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'inherit',
            },
          },
          customBadge: {
            backgroundColor: '#C0492C',
            color: '#fff',
          },
        };
      case 'Blocks':
        return {
          appbar: {
            boxShadow: 'none',
            position: 'fixed',
            transition: 'all 0.3s ease-in-out',
            backgroundColor:
              position >= -40 && transparent_bg
                ? 'transparent'
                : color && color.primary.main,
          },
          storename: {
            fontSize: 18,
            fontWeight: 700,
            fontFamily: storeInfo && storeInfo.font.family,
            color: color && color.primary.contrastText,
            [theme.breakpoints.down('xs')]: {
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'inherit',
            },
          },
          menu: {
            textDecoration: 'none',
            fontSize: 14,
            fontWeight: 500,
            color: color && color.primary.contrastText,
            '&:hover': {
              color: color && color.primary.contrastText,
            },
          },
          colorPrimary: color && color.primary.contrastText,
          customBadge: {
            backgroundColor: '#C0492C',
            color: 'white',
          },
        };
      case 'Greenery':
        return {
          root: {
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            zIndex: 1,
          },
          bgColor: {
            width: '100%',
            height: 20,
            backgroundColor: color && color.primary.main,
            display: position >= -40 ? 'inherit' : 'none',
            transition: 'all 0.3s ease-in-out',
          },
          appbar: {
            transition: 'all 0.3s ease-in-out',
            backgroundColor:
              position >= -40 ? 'transparent' : color && color.primary.main,
            padding: '2vh 5vw',
          },
          storename: {
            position: !transparent_bg ? 'absolute' : 'inherit',
            left: 0,
            right: 0,
            width: !transparent_bg ? '100%' : 'auto',
            textAlign: 'center',
            fontSize: 28,
            fontWeight: 300,
            fontFamily: storeInfo && storeInfo.font.family,
            color:
              !transparent_bg && position >= -40
                ? color && color.secondary.main
                : color && color.primary.contrastText,
            [theme.breakpoints.down('xs')]: {
              fontSize: 18,
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'inherit',
            },
          },
          customBadge: {
            backgroundColor: '#C0492C',
            color: 'white',
          },
        };
      case 'Japandi':
        return {
          appbar: {
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
            position: 'fixed',
            transition: 'all 0.3s ease-in-out',
          },
          storename: {
            fontSize: 18,
            fontWeight: 700,
            textTransform: 'capitalize',
            color: '#2b2d32',
            position: 'absolute',
            textAlign: 'center',
            width: '100%',
            left: 0,
            right: 0,
            [theme.breakpoints.down('xs')]: {
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'inherit',
            },
          },
          customBadge: {
            backgroundColor: '#C0492C',
            color: '#fff',
          },
        };
      case 'NFT':
        return {
          appbar: {
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
            position: 'relative',
            transition: 'all 0.3s ease-in-out',
          },
          desktop: {
            display: 'none',
            flex: 4,
            justifyContent: 'flex-end',

            [theme.breakpoints.up('md')]: {
              display: 'flex',
            },
          },
          storeName: {
            fontFamily: storeInfo && storeInfo.font.family,
            flexGrow: 1,
            paddingLeft: 10,
            color: color.primary.contrastText,
            fontWeight: 500,
            fontSize: 24,
            cursor: 'pointer',
            textTransform: 'uppercase',
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
              width: 120,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          },
          mobile: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              alignItems: 'center',
            },
          },
          dialog: {
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          },

          buttons: {
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              padding: '0px 100px',
            },
            [theme.breakpoints.down('xs')]: {
              padding: '0px 20px',
            },
          },
          cartIcon: {
            color: color.primary.contrastText,
            [theme.breakpoints.down('sm')]: {
              fontSize: 20,
            },
          },
        };
      default:
        return {};
    }
  };

  let templateStyles = makeStyles((theme) =>
    getStyles(storeInfo ? storeInfo.template : 'Blank', theme)
  );

  const classes = templateStyles();
  const mobileView = useMediaQuery;
  const menuButtons = [
    {
      id: 'home_btn',
      label: 'Home',
      path: `/shop/${storeInfo.site}`,
    },
    {
      id: 'aboutus_btn',
      label: 'About Us',
      path: `/shop/${storeInfo.site}/about_us`,
    },
    {
      id: 'products_btn',
      label: 'Products',
      path: `/shop/${storeInfo.site}/products`,
    },
  ];

  const social_medias = (
    <>
      {props.isPreview || (!props.isPreview && !storeInfo.facebook) ? (
        ''
      ) : (
        <IconButton
          onClick={() =>
            props.isPreview
              ? null
              : window.open(`http://facebook.com/${storeInfo.facebook}`)
          }
        >
          <Facebook style={{ color: color && color.primary.contrastText }} />
        </IconButton>
      )}
      {props.isPreview || (!props.isPreview && !storeInfo.instagram) ? (
        ''
      ) : (
        <IconButton
          onClick={() =>
            props.isPreview
              ? null
              : window.open(`http://facebook.com/${storeInfo.instagram}`)
          }
        >
          <Instagram style={{ color: color && color.primary.contrastText }} />
        </IconButton>
      )}
      {props.isPreview || (!props.isPreview && !storeInfo.shopee) ? (
        ''
      ) : (
        <IconButton
          onClick={() =>
            props.isPreview
              ? null
              : window.open(`http://shopee.com/${storeInfo.shopee}`)
          }
        >
          <ShopeeIcon
            fill={color && color.primary.contrastText}
            style={{ width: 24, height: 24 }}
          />
        </IconButton>
      )}
      {props.isPreview || (!props.isPreview && !storeInfo.lazada) ? (
        ''
      ) : (
        <IconButton
          onClick={() =>
            props.isPreview
              ? null
              : window.open(`http://shopee.com/${storeInfo.lazada}`)
          }
        >
          <Lazada
            fill={color && color.primary.contrastText}
            style={{ width: 24, height: 24 }}
          />
        </IconButton>
      )}
      {props.isPreview || (!props.isPreview && !storeInfo.kumu) ? (
        ''
      ) : (
        <IconButton
          onClick={() =>
            props.isPreview
              ? null
              : window.open(`http://shopee.com/${storeInfo.kumu}`)
          }
        >
          <img src={Kumu} style={{ width: 22, height: 22, marginTop: 8 }} />
        </IconButton>
      )}
    </>
  );

  const menuDialog = (
    <Dialog
      className={classes.dialog}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
    >
      <div
        style={{
          padding: 20,
          marginTop: '20vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={classes.buttons}>
          {menuButtons.map(item => {
            return (
              <SentroButton
                onClick={
                  isPreview
                    ? null
                    : () => {
                        history.push(item.path);
                      }
                }
                fullWidth
                id={item.id}
                key={item.id}
                variant="contained"
                label={item.label}
                style={{
                  margin: '10px 0',
                  backgroundColor: color && color.secondary.main,
                }}
              />
            );
          })}
          {storeInfo.site === 'biyayaanimal' ? <>
          <SentroButton
                onClick={
                  () => window.open('https://calendly.com/biyayaanimalcare/')
                }
                fullWidth
                variant="contained"
                label='Schedule'
                style={{
                  margin: "10px 0",
                  backgroundColor: color && color.secondary.main
                }}
              />
          <SentroButton
                onClick={
                  () => window.open('https://biyayaanimalcare.wordpress.com/')
                }
                fullWidth
                variant="contained"
                label='Blog'
                style={{
                  margin: "10px 0",
                  backgroundColor: color && color.secondary.main
                }}
              />
             
          <SentroButton
            onClick={handleClose}
            // variant="contained"
            fullWidth
            label="Close"
            style={{
              margin: '10px 0',
              color: color && color.secondary.main,
            }}
          />
          </> : ''}
          
        </div>
      </div>
    </Dialog>
  );

  const setAppbarTemplate = () => {
    switch (storeInfo ? storeInfo.template : '') {
      default:
        return <div />;
      case 'Leftist':
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AppBar
              style={{
                boxShadow: 'none',
                backgroundColor: color && color.primary.main,
              }}
            >
              <Toolbar
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {isMobile ? (
                  ''
                ) : (
                  <AppBarTemplate
                    storeInfo={props.storeInfo}
                    isPreview={props.isPreview}
                  />
                )}
                {isMobile ? (
                  <IconButton
                    onClick={handleClickOpen}
                    color="secondary"
                    style={{ padding: 5 }}
                  >
                    <MoreIcon
                      style={{
                        color: color.primary.contrastText,
                        fontSize: 20
                      }}
                    />
                  </IconButton>
                ) : (
                  ''
                )}
                <Typography
                  onClick={() =>
                    props.isPreview
                      ? null
                      : history.push(`/shop/${storeInfo.site}`)
                  }
                  variant="h6"
                  className={classes.storeName}
                >
                  {props.storeInfo
                    ? props.storeInfo.name
                      ? props.storeInfo.name
                      : ''
                    : ''}
                </Typography>

                <Box className={classes.desktop}>
                  {/* <Link to="/shop/pangpang/products">Products</Link> */}
                  <SentroButton
                    id="home_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}`);
                          }
                    }
                    label="Home"
                    style={{
                      fontSize: 16,

                      marginTop: 0,
                      color: color.primary.contrastText,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  <SentroButton
                    id="about_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/about_us`);
                          }
                    }
                    label="About Us"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  <SentroButton
                    id="products_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/products`);
                          }
                    }
                    label="Products"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlinedIcon className={classes.cartIcon} />
                    </IconButton>
                  ) : (
                    <SentroButton
                      id="login"
                      label="Log in"
                      style={{
                        fontSize: 16,

                        color: color.primary.contrastText,
                        marginTop: 0,
                        fontWeight: 'normal',
                        padding: '0 30px',
                      }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    />
                  )}

                  <div style={{ marginLeft: '2vw' }}>
                    <IconButton onClick={cartButton()}>
                      <Badge
                        color="secondary"
                        badgeContent={productList.length}
                      >
                        <LocalMallOutlinedIcon className={classes.cartIcon} />
                      </Badge>
                    </IconButton>
                  </div>
                </Box>
                {/* LOGIN */}
                <div className={classes.mobile}>
                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlinedIcon className={classes.cartIcon} />
                    </IconButton>
                  ) : (
                    <Button
                      style={{
                        color: color.primary.contrastText,
                        fontWeight: 'normal',
                      }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Button>
                  )}

                  <IconButton onClick={cartButton()}>
                    <Badge color="secondary" badgeContent={productList.length}>
                      <LocalMallOutlinedIcon className={classes.cartIcon} />
                    </Badge>
                  </IconButton>

                  <div>
                    <Dialog
                      className={classes.dialog}
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                    >
                      <div
                        style={{
                          padding: 20,
                          marginTop: '20vh',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div className={classes.buttons}>
                          {menuButtons.map(item => {
                            return (
                              <SentroButton
                                onClick={
                                  isPreview
                                    ? null
                                    : () => {
                                        history.push(item.path);
                                      }
                                }
                                fullWidth
                                id={item.id}
                                variant="contained"
                                label={item.label}
                                style={{
                                  margin: '10px 0',
                                  backgroundColor: color.primary.main,
                                }}
                              />
                            );
                          })}
                          <SentroButton
                            onClick={handleClose}
                            // variant="contained"
                            fullWidth
                            label="Close"
                            style={{
                              margin: '10px 0',
                              color: color.primary.main,
                            }}
                          />
                        </div>
                      </div>
                    </Dialog>
                  </div>
                </div>
              </Toolbar>
              <PromoBannerSticky />
            </AppBar>
          </div>
        );
      case 'Classic':
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AppBar
              style={{
                boxShadow: 'none',
                backgroundColor: color && color.primary.main,
              }}
            >
              <Toolbar
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <AppBarTemplate
                  storeInfo={props.storeInfo}
                  isPreview={props.isPreview}
                />
                <Typography
                  onClick={() =>
                    props.isPreview
                      ? null
                      : history.push(`/shop/${storeInfo.site}`)
                  }
                  variant="h6"
                  className={classes.storeName}
                >
                  {props.storeInfo
                    ? props.storeInfo.name
                      ? props.storeInfo.name
                      : ''
                    : ''}
                </Typography>

                <Box className={classes.desktop}>
                  {/* <Link to="/shop/pangpang/products">Products</Link> */}
                  <SentroButton
                    id="home_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}`);
                          }
                    }
                    label="Home"
                    style={{
                      fontSize: 16,

                      marginTop: 0,
                      color: color.primary.contrastText,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  <SentroButton
                    id="about_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/about_us`);
                          }
                    }
                    label="About Us"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  <SentroButton
                    id="products_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/products`);
                          }
                    }
                    label="Products"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlinedIcon className={classes.cartIcon} />
                    </IconButton>
                  ) : (
                    <SentroButton
                      id="login"
                      label="Log in"
                      style={{
                        fontSize: 16,

                        color: color.primary.contrastText,
                        marginTop: 0,
                        fontWeight: 'normal',
                        padding: '0 30px',
                      }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    />
                  )}

                  <div style={{ marginLeft: '2vw' }}>
                    <IconButton onClick={cartButton()}>
                      <Badge
                        color="secondary"
                        badgeContent={productList.length}
                      >
                        <LocalMallOutlinedIcon className={classes.cartIcon} />
                      </Badge>
                    </IconButton>
                  </div>
                </Box>

                <div className={classes.mobile}>
                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlinedIcon className={classes.cartIcon} />
                    </IconButton>
                  ) : (
                    <Button
                      style={{
                        color: color.primary.contrastText,
                        fontWeight: 'normal',
                      }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Button>
                  )}

                  <IconButton onClick={cartButton()}>
                    <Badge color="secondary" badgeContent={productList.length}>
                      <LocalMallOutlinedIcon className={classes.cartIcon} />
                    </Badge>
                  </IconButton>

                  <div>
                    <IconButton
                      onClick={handleClickOpen}
                      color="secondary"
                      style={{ padding: 5 }}
                    >
                      <MoreIcon
                        style={{
                          color: color.primary.contrastText,
                          fontSize: 20
                        }}
                      />
                    </IconButton>
                    <Dialog
                      className={classes.dialog}
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                    >
                      <div
                        style={{
                          padding: 20,
                          marginTop: '20vh',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div className={classes.buttons}>
                          {menuButtons.map(item => {
                            return (
                              <SentroButton
                                onClick={
                                  isPreview
                                    ? null
                                    : () => {
                                        history.push(item.path);
                                      }
                                }
                                fullWidth
                                id={item.id}
                                variant="contained"
                                label={item.label}
                                style={{
                                  margin: '10px 0',
                                  backgroundColor: color.primary.main,
                                }}
                              />
                            );
                          })}
                          <SentroButton
                            onClick={handleClose}
                            // variant="contained"
                            fullWidth
                            label="Close"
                            style={{
                              margin: '10px 0',
                              color: color.primary.main,
                            }}
                          />
                        </div>
                      </div>
                    </Dialog>
                  </div>
                </div>
              </Toolbar>
              <PromoBannerSticky />
            </AppBar>
          </div>
        );
      case 'Contemporary':
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AppBar
              style={{
                boxShadow: 'none',
                backgroundColor: color && color.primary.main,
              }}
            >
              <Toolbar
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <AppBarTemplate
                  storeInfo={props.storeInfo}
                  isPreview={props.isPreview}
                />
                <Typography
                  onClick={() =>
                    props.isPreview
                      ? null
                      : history.push(`/shop/${storeInfo.site}`)
                  }
                  variant="h6"
                  className={classes.storeName}
                >
                  {props.storeInfo
                    ? props.storeInfo.name
                      ? props.storeInfo.name
                      : ''
                    : ''}
                </Typography>

                <Box className={classes.desktop}>
                  {/* <Link to="/shop/pangpang/products">Products</Link> */}
                  <SentroButton
                    id="home_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}`);
                          }
                    }
                    label="Home"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  <SentroButton
                    id="about_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/about_us`);
                          }
                    }
                    label="About Us"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  <SentroButton
                    id="products_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/products`);
                          }
                    }
                    label="Products"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlinedIcon className={classes.cartIcon} />
                    </IconButton>
                  ) : (
                    <SentroButton
                      id="login"
                      label="Log in"
                      style={{
                        fontSize: 16,

                        color: color.primary.contrastText,
                        marginTop: 0,
                        fontWeight: 'normal',
                        padding: '0 30px',
                      }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    />
                  )}

                  <div style={{ marginLeft: '2vw' }}>
                    <IconButton onClick={cartButton()}>
                      <Badge
                        color="secondary"
                        badgeContent={productList.length}
                      >
                        <LocalMallOutlinedIcon className={classes.cartIcon} />
                      </Badge>
                    </IconButton>
                  </div>
                </Box>

                <div className={classes.mobile}>
                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlinedIcon className={classes.cartIcon} />
                    </IconButton>
                  ) : (
                    <Button
                      style={{
                        color: color.primary.contrastText,
                        fontWeight: 'normal',
                      }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Button>
                  )}

                  <IconButton onClick={cartButton()}>
                    <Badge color="secondary" badgeContent={productList.length}>
                      <LocalMallOutlinedIcon className={classes.cartIcon} />
                    </Badge>
                  </IconButton>

                  <div>
                    <IconButton
                      onClick={handleClickOpen}
                      color="secondary"
                      style={{ padding: 5 }}
                    >
                      <MoreIcon
                        style={{
                          color: color.primary.contrastText,
                          fontSize: 20
                        }}
                      />
                    </IconButton>
                    <Dialog
                      className={classes.dialog}
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                    >
                      <div
                        style={{
                          padding: 20,
                          marginTop: '20vh',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div className={classes.buttons}>
                          {menuButtons.map(item => {
                            return (
                              <SentroButton
                                onClick={
                                  isPreview
                                    ? null
                                    : () => {
                                        history.push(item.path);
                                      }
                                }
                                fullWidth
                                id={item.id}
                                variant="contained"
                                label={item.label}
                                style={{
                                  margin: '10px 0',
                                  backgroundColor: color.primary.main,
                                }}
                              />
                            );
                          })}
                          <SentroButton
                            onClick={handleClose}
                            // variant="contained"
                            fullWidth
                            label="Close"
                            style={{
                              margin: '10px 0',
                              color: color.primary.main,
                            }}
                          />
                        </div>
                      </div>
                    </Dialog>
                  </div>
                </div>
              </Toolbar>
              <PromoBannerSticky />
            </AppBar>
          </div>
        );
      case 'Minimalist':
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AppBar
              style={{
                boxShadow: 'none',
                backgroundColor: color && color.primary.main,
              }}
            >
              <Toolbar
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <AppBarTemplate
                  storeInfo={props.storeInfo}
                  isPreview={props.isPreview}
                />
                <Typography
                  onClick={() =>
                    props.isPreview
                      ? null
                      : history.push(`/shop/${storeInfo.site}`)
                  }
                  variant="h6"
                  className={classes.storeName}
                >
                  {props.storeInfo
                    ? props.storeInfo.name
                      ? props.storeInfo.name
                      : ''
                    : ''}
                </Typography>
                <div className={classes.mobile}>
                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlinedIcon className={classes.cartIcon} />
                    </IconButton>
                  ) : (
                    <Button
                      style={{
                        color: color.primary.contrastText,
                        fontWeight: 'normal',
                      }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Button>
                  )}

                  <IconButton onClick={cartButton()}>
                    <Badge color="secondary" badgeContent={productList.length}>
                      <LocalMallOutlinedIcon className={classes.cartIcon} />
                    </Badge>
                  </IconButton>

                  <div>
                    <IconButton
                      onClick={handleClickOpen}
                      color="secondary"
                      style={{ padding: 5 }}
                    >
                      <MoreIcon
                        style={{
                          color: color.primary.contrastText,
                          fontSize: 20
                        }}
                      />
                    </IconButton>
                    <Dialog
                      className={classes.dialog}
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                    >
                      <div
                        style={{
                          padding: 20,
                          marginTop: '20vh',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div className={classes.buttons}>
                          {menuButtons.map(item => {
                            return (
                              <SentroButton
                                onClick={
                                  isPreview
                                    ? null
                                    : () => {
                                        history.push(item.path);
                                      }
                                }
                                fullWidth
                                id={item.id}
                                variant="contained"
                                label={item.label}
                                style={{
                                  margin: '10px 0',
                                  backgroundColor: color.primary.main,
                                }}
                              />
                            );
                          })}
                          <SentroButton
                            onClick={handleClose}
                            // variant="contained"
                            fullWidth
                            label="Close"
                            style={{
                              margin: '10px 0',
                              color: color.primary.main,
                            }}
                          />
                        </div>
                      </div>
                    </Dialog>
                  </div>
                </div>
                <Box className={classes.desktop}>
                  <SentroButton
                    id="home_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}`);
                          }
                    }
                    label="Home"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  <SentroButton
                    id="about_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/about_us`);
                          }
                    }
                    label="About Us"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  <SentroButton
                    id="products_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/products`);
                          }
                    }
                    label="Products"
                    style={{
                      fontSize: 16,

                      color: color.primary.contrastText,
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlinedIcon className={classes.cartIcon} />
                    </IconButton>
                  ) : (
                    <SentroButton
                      id="login"
                      label="Log in"
                      style={{
                        fontSize: 16,

                        color: color.primary.contrastText,
                        marginTop: 0,
                        fontWeight: 'normal',
                        padding: '0 30px',
                      }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    />
                  )}

                  <div style={{ marginLeft: '2vw' }}>
                    <IconButton onClick={cartButton()}>
                      <Badge
                        color="secondary"
                        badgeContent={productList.length}
                      >
                        <LocalMallOutlinedIcon className={classes.cartIcon} />
                      </Badge>
                    </IconButton>
                  </div>
                </Box>
              </Toolbar>
              <PromoBannerSticky />
            </AppBar>
          </div>
        );

      case 'ClassicV2':
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AppBar
              style={{
                backgroundColor: color && color.primary.main,
                boxShadow: 'none',
              }}
            >
              <Toolbar className={classes.toolbar}>
                <div style={{ display: 'flex' }}>
                  <div className={classes.menuMobile}>
                    <Box className={classes.mobile}>
                      <IconButton onClick={handleClickOpen} color="secondary">
                        <MoreIcon
                          style={{
                            color: color.primary.contrastText,
                            fontSize: 20
                          }}
                        />
                      </IconButton>

                      <Dialog
                        className={classes.dialog}
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                      >
                        <div
                          style={{
                            padding: 20,
                            marginTop: '20vh',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <div className={classes.buttons}>
                            {menuButtons.map(item => {
                              return (
                                <SentroButton
                                  onClick={
                                    isPreview
                                      ? null
                                      : () => {
                                          history.push(item.path);
                                        }
                                  }
                                  fullWidth
                                  id={item.id}
                                  variant="contained"
                                  label={item.label}
                                  style={{
                                    margin: '10px 0',
                                    color: '#fff',
                                    backgroundColor: color.primary.main,
                                  }}
                                />
                              );
                            })}

                            <SentroButton
                              onClick={handleClose}
                              // variant="contained"
                              fullWidth
                              label="Close"
                              style={{
                                margin: '10px 0',
                                color: color.primary.main,
                              }}
                            />
                          </div>
                        </div>
                      </Dialog>
                    </Box>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <AppBarTemplate
                        storeInfo={storeInfo}
                        isPreview={props.isPreview}
                      />
                      <Typography
                        variant="h6"
                        className={classes.storeName}
                        onClick={() =>
                          props.isPreview
                            ? null
                            : history.push(`/shop/${storeInfo.site}`)
                        }
                      >
                        {storeInfo
                          ? storeInfo.name
                            ? storeInfo.name
                            : ''
                          : ''}
                      </Typography>
                    </div>
                    <div
                      style={{ paddingLeft: '4vw' }}
                      className={classes.menuDesktop}
                    >
                      {product_categories.map(item => (
                        <Link
                          style={{ textTransform: 'capitalize' }}
                          className={`${classes.menu} mx-2`}
                          onClick={() =>
                            history.push(
                              `/shop/${
                                props.storeInfo?.site
                              }/products/?category=${encodeURIComponent(
                                item
                              )}&sortBy=-created_on`
                            )
                          }
                        >
                          {item}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* LOGIN */}
                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlinedIcon className={classes.cartIcon} />
                    </IconButton>
                  ) : (
                    <SentroButton
                      id="login"
                      label="Log in"
                      className={classes.menuBtn}
                      style={{ marginTop: 0, padding: 0 }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    />
                  )}
                  <div style={{ marginLeft: '2vw' }}>
                    <IconButton onClick={cartButton()} style={{ padding: 5 }}>
                      <Badge
                        color="secondary"
                        badgeContent={productList.length}
                      >
                        <ShoppingCartOutlined className={classes.cartIcon} />
                      </Badge>
                    </IconButton>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
          </div>
        );

      case 'ContemporaryV2':
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AppBar className={classes.appbar}>
              <Toolbar className={classes.toolbar}>
                <div className={classes.menuMobile}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <AppBarTemplate
                        storeInfo={storeInfo}
                        isPreview={props.isPreview}
                      />

                      <Typography
                        variant="h6"
                        className={classes.storeName}
                        onClick={() =>
                          props.isPreview
                            ? null
                            : history.push(`/shop/${storeInfo.site}`)
                        }
                      >
                        {storeInfo
                          ? storeInfo.name
                            ? storeInfo.name
                            : ''
                          : ''}
                      </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* LOGIN */}
                      {token && user ? (
                        <IconButton
                          onClick={() =>
                            props.isPreview ? null : handleGoToDashboard()
                          }
                        >
                          <AccountCircleOutlinedIcon
                            className={classes.cartIcon}
                          />
                        </IconButton>
                      ) : (
                        <Button
                          style={{
                            color: color.primary.contrastText,
                            fontWeight: 'normal',
                          }}
                          onClick={
                            isPreview
                              ? null
                              : () =>
                                  history.push(`/shop/${storeInfo.site}/login`)
                          }
                        >
                          Log in
                        </Button>
                      )}

                      <IconButton onClick={cartButton()}>
                        <Badge
                          color="secondary"
                          badgeContent={productList.length}
                        >
                          <LocalMallOutlinedIcon className={classes.cartIcon} />
                        </Badge>
                      </IconButton>

                      <div className={classes.mobile}>
                        <IconButton
                          onClick={props.isPreview ? null : handleClickOpen}
                          color="secondary"
                          style={{ padding: 5 }}
                        >
                          <MoreIcon
                            style={{
                              color: color.primary.contrastText,
                              fontSize: 20
                            }}
                          />
                        </IconButton>
                        <Dialog
                          className={classes.dialog}
                          fullScreen={fullScreen}
                          open={open}
                          onClose={handleClose}
                        >
                          <div
                            style={{
                              padding: 20,
                              marginTop: '20vh',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div className={classes.buttons}>
                              {menuButtons.map(item => {
                                return (
                                  <SentroButton
                                    onClick={
                                      isPreview
                                        ? null
                                        : () => {
                                            history.push(item.path);
                                          }
                                    }
                                    fullWidth
                                    id={item.id}
                                    variant="contained"
                                    label={item.label}
                                    style={{
                                      margin: '10px 0',
                                      backgroundColor: color.primary.main,
                                    }}
                                  />
                                );
                              })}
                              <SentroButton
                                onClick={handleClose}
                                // variant="contained"
                                fullWidth
                                label="Close"
                                style={{
                                  margin: '10px 0',
                                  color: color.primary.main,
                                }}
                              />
                            </div>
                          </div>
                        </Dialog>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.desktop}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AppBarTemplate
                      storeInfo={storeInfo}
                      isPreview={props.isPreview}
                    />
                    <Typography
                      variant="h6"
                      className={classes.storeName}
                      onClick={() =>
                        props.isPreview
                          ? null
                          : history.push(`/shop/${storeInfo.site}`)
                      }
                    >
                      {storeInfo ? (storeInfo.name ? storeInfo.name : '') : ''}
                    </Typography>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {product_categories.slice(0, 4).map((item) => (
                      <Link
                        style={{ textTransform: 'capitalize' }}
                        className={`${classes.menu} mx-3`}
                        onClick={() =>
                          history.push(
                            `/shop/${
                              props.storeInfo?.site
                            }/products/?category=${encodeURIComponent(
                              item
                            )}&sortBy=-created_on`
                          )
                        }
                      >
                        {item}
                      </Link>
                    ))}
                    {/* LOGIN */}
                    {token && user ? (
                      <IconButton
                        onClick={() =>
                          props.isPreview ? null : handleGoToDashboard()
                        }
                      >
                        <AccountCircleOutlinedIcon
                          className={classes.cartIcon}
                        />
                      </IconButton>
                    ) : (
                      <SentroButton
                        id="login"
                        label="Log in"
                        className={classes.menuBtn}
                        style={{ marginTop: 0 }}
                        onClick={
                          isPreview
                            ? null
                            : () =>
                                history.push(`/shop/${storeInfo.site}/login`)
                        }
                      />
                    )}

                    <div style={{ marginLeft: '2vw' }}>
                      <IconButton onClick={cartButton()}>
                        <Badge
                          color="secondary"
                          badgeContent={productList.length}
                        >
                          <ShoppingCartOutlined className={classes.cartIcon} />
                        </Badge>
                      </IconButton>
                    </div>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
          </div>
        );
      case 'SariSari':
        return (
          <AppBar className={classes.appbar}>
            <Container>
              <Toolbar
                className="py-3 px-0 w-100 d-flex justify-content-between"
                style={{
                  borderBottom: `1px solid ${color.primary.contrastText}`
                }}
              >
                {isMobile ? '' : <div>{social_medias}</div>}
                {isMobile ? (
                  <IconButton
                    onClick={props.isPreview ? null : handleClickOpen}
                  >
                    <MoreIcon style={{ color: color.primary.contrastText }} />
                  </IconButton>
                ) : (
                  ''
                )}
                {menuDialog}
                <div className="d-flex align-items-center">
                  <AppBarTemplate
                    storeInfo={props.storeInfo}
                    isPreview={props.isPreview}
                  />
                  <Typography
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(`/shop/${storeInfo.site}`)
                    }
                    variant="h6"
                    className={classes.storeName}
                  >
                    {props.storeInfo
                      ? props.storeInfo.name
                        ? props.storeInfo.name
                        : ''
                      : ''}
                  </Typography>
                </div>
                <div className="d-flex align-items-center">
                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlined className={classes.colorPrimary} />
                    </IconButton>
                  ) : (
                    <Link
                      className="px-4"
                      style={{ color: color.primary.contrastText }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Link>
                  )}
                  <IconButton onClick={cartButton()}>
                    <Badge color="secondary" badgeContent={productList.length}>
                      <ShoppingCartOutlined className={classes.colorPrimary} />
                    </Badge>
                  </IconButton>
                </div>
              </Toolbar>
            </Container>
          </AppBar>
        );
      case 'Pastel':
        return (
          <AppBar className={classes.appbar}>
            <Container>
              <Toolbar className="py-3 px-0 w-100 d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <AppBarTemplate
                      storeInfo={props.storeInfo}
                      isPreview={props.isPreview}
                    />
                    <Typography
                      onClick={() =>
                        props.isPreview
                          ? null
                          : history.push(`/shop/${storeInfo.site}`)
                      }
                      variant="h6"
                      className={classes.storeName}
                    >
                      {props.storeInfo
                        ? props.storeInfo.name
                          ? props.storeInfo.name
                          : ''
                        : ''}
                    </Typography>
                  </div>
                  <div className="ml-4 d-md-flex d-none align-items-center">
                    {menuButtons.map(item => (
                      <Link
                        className={`mx-2 ${classes.menu}`}
                        onClick={() => history.push(item.path)}
                      >
                        {item.label}
                      </Link>
                    ))}
                    {storeInfo.site === 'biyayaanimal' ? <>
                    <Link
                      className={`mx-2 ${classes.menu}`}
                      onClick={() => window.open('https://calendly.com/biyayaanimalcare/')}
                    >
                      Schedule
                    </Link>
                    <Link
                        className={`mx-2 ${classes.menu}`}
                        onClick={() => window.open('https://biyayaanimalcare.wordpress.com/')}
                      >
                        Blog
                    </Link>
                  
                    </> : ''}
                    
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-md-flex d-none">
                    {product_categories.slice(0, 4).map(item => (
                      <Link
                        style={{ textTransform: 'capitalize' }}
                        className={`${classes.menu} mx-2`}
                        onClick={() =>
                          props.isPreview
                            ? null
                            : history.push(
                                `/shop/${
                                  storeInfo.site
                                }/products/?category=${encodeURIComponent(
                                  item
                                )}&sortBy=-created_on`
                              )
                        }
                      >
                        {item}
                      </Link>
                    ))}
                  </div>
                  {token && user ? (
                    <IconButton
                      className="ml-3"
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlined className={classes.colorPrimary} />
                    </IconButton>
                  ) : (
                    <Link
                      className="px-2"
                      style={{ color: color.primary.contrastText }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Link>
                  )}
                  <IconButton className="ml-2" onClick={cartButton()}>
                    <Badge color="secondary" badgeContent={productList.length}>
                      <ShoppingCartOutlined className={classes.colorPrimary} />
                    </Badge>
                  </IconButton>
                  {isMobile ? (
                    <>
                      {menuDialog}
                      <IconButton onClick={handleClickOpen}>
                        <MoreIcon
                          style={{ color: color.primary.contrastText }}
                        />
                      </IconButton>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Toolbar>
            </Container>
          </AppBar>
        );
      case 'MinimalistV2':
        return (
          <AppBar className={classes.appbar}>
            {isMobile ? (
              <Container className="py-2 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <IconButton
                    onClick={props.isPreview ? null : handleClickOpen}
                  >
                    <MoreIcon style={{ color: color.primary.contrastText }} />
                  </IconButton>
                  <Typography
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(`/shop/${storeInfo.site}`)
                    }
                    variant="h6"
                    className={classes.storename}
                  >
                    {props.storeInfo
                      ? props.storeInfo.name
                        ? props.storeInfo.name
                        : ''
                      : ''}
                  </Typography>
                </div>
                <div className="d-flex align-items-center">
                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlined
                        style={{ color: color.primary.contrastText }}
                      />
                    </IconButton>
                  ) : (
                    <Link
                      className="px-md-4 px-2"
                      style={{ color: color.primary.contrastText }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Link>
                  )}
                  <IconButton onClick={cartButton()}>
                    <Badge
                      classes={{ badge: classes.customBadge }}
                      badgeContent={productList.length}
                    >
                      <ShoppingCartOutlined
                        style={{ color: color.primary.contrastText }}
                      />
                    </Badge>
                  </IconButton>
                </div>
              </Container>
            ) : (
              <Container className="d-flex py-3 align-items-center justify-content-between">
                <IconButton
                  style={{ zIndex: 1 }}
                  onClick={props.isPreview ? null : handleClickOpen}
                >
                  <MoreIcon style={{ color: color.primary.contrastText }} />
                </IconButton>
                <Typography
                  onClick={() =>
                    props.isPreview
                      ? null
                      : history.push(`/shop/${storeInfo.site}`)
                  }
                  variant="h6"
                  className={classes.storename}
                >
                  {props.storeInfo
                    ? props.storeInfo.name
                      ? props.storeInfo.name
                      : ''
                    : ''}
                </Typography>
                <div
                  className="d-flex align-items-center"
                  style={{ zIndex: 1 }}
                >
                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlined
                        style={{ color: color.primary.contrastText }}
                      />
                    </IconButton>
                  ) : (
                    <Link
                      className="px-md-4 px-2"
                      style={{ color: color.primary.contrastText }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Link>
                  )}
                  <IconButton onClick={cartButton()}>
                    <Badge
                      classes={{ badge: classes.customBadge }}
                      badgeContent={productList.length}
                    >
                      <ShoppingCartOutlined
                        style={{ color: color.primary.contrastText }}
                      />
                    </Badge>
                  </IconButton>
                </div>
              </Container>
            )}
            <CategoriesDialog
              classes={classes}
              productList={productList}
              handleGoToDashboard={handleGoToDashboard}
              cartButton={cartButton}
              color={color}
              storeInfo={storeInfo}
              categories={product_categories}
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
            />
          </AppBar>
        );
      case 'Blocks':
        return (
          <AppBar
            className={`${classes.appbar} d-flex align-items-center justify-content-between`}
          >
            {isMobile ? (
              <Container className="d-flex p-3 justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <IconButton
                    onClick={props.isPreview ? null : handleClickOpen}
                  >
                    <MoreIcon style={{ color: color.primary.contrastText }} />
                  </IconButton>
                  <Typography
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(`/shop/${storeInfo.site}`)
                    }
                    variant="h6"
                    className={classes.storename}
                  >
                    {props.storeInfo
                      ? props.storeInfo.name
                        ? props.storeInfo.name
                        : ''
                      : ''}
                  </Typography>
                </div>
                <div className="d-flex align-items-center">
                  {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlined
                        style={{ color: color.primary.contrastText }}
                      />
                    </IconButton>
                  ) : (
                    <Link
                      className="px-md-4 px-2"
                      style={{ color: color.primary.contrastText }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Link>
                  )}
                  <IconButton onClick={cartButton()}>
                    <Badge
                      classes={{ badge: classes.customBadge }}
                      badgeContent={productList.length}
                    >
                      <ShoppingCartOutlined
                        style={{ color: color.primary.contrastText }}
                      />
                    </Badge>
                  </IconButton>
                </div>
                <CategoriesDialog
                  classes={classes}
                  productList={productList}
                  handleGoToDashboard={handleGoToDashboard}
                  cartButton={cartButton}
                  color={color}
                  storeInfo={storeInfo}
                  categories={product_categories}
                  open={open}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                />
              </Container>
            ) : (
              <div
                style={{ padding: isMobile ? 20 : '2vh 4em' }}
                className="w-100 d-flex align-items-center justify-content-between"
              >
                <div className="d-flex align-items-center">
                  <AppBarTemplate
                    storeInfo={props.storeInfo}
                    isPreview={props.isPreview}
                  />
                  <Typography
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(`/shop/${storeInfo.site}`)
                    }
                    variant="h6"
                    className={`${classes.storename} ml-3`}
                  >
                    {props.storeInfo
                      ? props.storeInfo.name
                        ? props.storeInfo.name
                        : ''
                      : ''}
                  </Typography>
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-md-flex d-none">
                    {product_categories.slice(0, 4).map((item) => (
                      <Link
                        style={{
                          textTransform: 'capitalize',
                          textDecoration: 'none',
                        }}
                        className={`${classes.menu} mr-4`}
                        onClick={() =>
                          history.push(
                            `/shop/${
                              props.storeInfo?.site
                            }/products/?category=${encodeURIComponent(
                              item
                            )}&sortBy=-created_on`
                          )
                        }
                      >
                        {item}
                      </Link>
                    ))}
                  </div>
                  {token && user ? (
                    <IconButton
                      className="ml-3"
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                    >
                      <AccountCircleOutlined
                        style={{ color: color && color.primary.contrastText }}
                      />
                    </IconButton>
                  ) : (
                    <Link
                      className={`${classes.menu} px-2`}
                      style={{ textDecoration: 'none' }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Link>
                  )}
                  <IconButton className="ml-2" onClick={cartButton()}>
                    <Badge
                      color="secondary"
                      badgeContent={productList.length}
                      classes={{ badge: classes.customBadge }}
                    >
                      <ShoppingCartOutlined
                        style={{
                          color: color && color.primary.contrastText,
                          fontSize: 20,
                        }}
                      />
                    </Badge>
                  </IconButton>
                </div>
              </div>
            )}
          </AppBar>
        );

      case 'Greenery':
        return (
          <div className={classes.root}>
            <div className={classes.bgColor} />
            <div
              className={`${classes.appbar} position-relative d-flex align-items-center justify-content-between`}
            >
              <div
                className={`${
                  !transparent_bg || isMobile ? 'd-flex' : 'd-none'
                } align-items-center`}
              >
                {!transparent_bg || isMobile ? (
                  <IconButton
                    style={{ zIndex: 1 }}
                    onClick={props.isPreview ? null : handleClickOpen}
                  >
                    <MoreIcon
                      style={{
                        color:
                          !transparent_bg && position >= -40
                            ? color.secondary.main
                            : color.primary.contrastText,
                      }}
                    />
                  </IconButton>
                ) : (
                  ''
                )}
                <Typography
                  onClick={() =>
                    props.isPreview
                      ? null
                      : history.push(`/shop/${storeInfo.site}`)
                  }
                  variant="h6"
                  style={{ display: isMobile ? 'block' : 'none' }}
                  className={`${classes.storename} ml-3`}
                >
                  {props.storeInfo
                    ? props.storeInfo.name
                      ? props.storeInfo.name
                      : ''
                    : ''}
                </Typography>
              </div>

              <Typography
                onClick={() =>
                  props.isPreview
                    ? null
                    : history.push(`/shop/${storeInfo.site}`)
                }
                variant="h6"
                style={{ display: isMobile ? 'none' : 'block' }}
                className={`${classes.storename}`}
              >
                {props.storeInfo
                  ? props.storeInfo.name
                    ? props.storeInfo.name
                    : ''
                  : ''}
              </Typography>
              <div className="d-flex align-items-center">
                {token && user ? (
                  <IconButton
                    onClick={() =>
                      props.isPreview ? null : handleGoToDashboard()
                    }
                  >
                    <AccountCircleOutlined
                      style={{
                        color:
                          !transparent_bg && position >= -40
                            ? color.secondary.main
                            : color.primary.contrastText,
                      }}
                    />
                  </IconButton>
                ) : (
                  <Link
                    className="px-md-4 px-2"
                    style={{
                      color:
                        !transparent_bg && position >= -40
                          ? color.secondary.main
                          : color.primary.contrastText,
                    }}
                    onClick={
                      isPreview
                        ? null
                        : () => history.push(`/shop/${storeInfo.site}/login`)
                    }
                  >
                    Log in
                  </Link>
                )}
                <IconButton onClick={cartButton()}>
                  <Badge
                    classes={{ badge: classes.customBadge }}
                    badgeContent={productList.length}
                  >
                    <ShoppingCartOutlined
                      style={{
                        color:
                          !transparent_bg && position >= -40
                            ? color.secondary.main
                            : color.primary.contrastText,
                      }}
                    />
                  </Badge>
                </IconButton>
              </div>
            </div>
            <CategoriesDialog
              classes={classes}
              productList={productList}
              handleGoToDashboard={handleGoToDashboard}
              cartButton={cartButton}
              color={color}
              storeInfo={storeInfo}
              categories={product_categories}
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
            />
          </div>
        );
      case 'Japandi':
        return (
          <>
            {location.pathname === `/shop/${storeInfo.site}` ||
            location.pathname.includes('/products') ||
            location.pathname.includes('viewproduct') ? (
              <Japandi
                storeInfo={storeInfo}
                product_categories={product_categories}
                productList={productList}
                handleGoToDashboard={handleGoToDashboard}
                cartButton={cartButton}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                handleClickOpen={handleClickOpen}
              />
            ) : (
              <AppBar className={classes.appbar}>
                {isMobile ? (
                  <Container className="py-2 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <IconButton
                        onClick={props.isPreview ? null : handleClickOpen}
                      >
                        <MoreIcon style={{ color: '#2b2d32' }} />
                      </IconButton>
                      <Typography
                        onClick={() =>
                          props.isPreview
                            ? null
                            : history.push(`/shop/${storeInfo.site}`)
                        }
                        variant="h6"
                        className={classes.storename}
                      >
                        {props.storeInfo
                          ? props.storeInfo.name
                            ? props.storeInfo.name
                            : ''
                          : ''}
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center">
                      {token && user ? (
                        <IconButton
                          onClick={() =>
                            props.isPreview ? null : handleGoToDashboard()
                          }
                        >
                          <AccountCircleOutlined style={{ color: '#2b2d32' }} />
                        </IconButton>
                      ) : (
                        <Link
                          className="px-md-4 px-2"
                          style={{ color: '#2b2d32' }}
                          onClick={
                            isPreview
                              ? null
                              : () =>
                                  history.push(`/shop/${storeInfo.site}/login`)
                          }
                        >
                          Log in
                        </Link>
                      )}
                      <IconButton onClick={cartButton()}>
                        <Badge
                          classes={{ badge: classes.customBadge }}
                          badgeContent={productList.length}
                        >
                          <ShoppingCartOutlined style={{ color: '#2b2d32' }} />
                        </Badge>
                      </IconButton>
                    </div>
                  </Container>
                ) : (
                  <Container className="d-flex py-3 align-items-center justify-content-between">
                    <IconButton
                      style={{ zIndex: 1 }}
                      onClick={props.isPreview ? null : handleClickOpen}
                    >
                      <MoreIcon style={{ color: '#2b2d32' }} />
                    </IconButton>
                    <Typography
                      onClick={() =>
                        props.isPreview
                          ? null
                          : history.push(`/shop/${storeInfo.site}`)
                      }
                      variant="h6"
                      className={classes.storename}
                    >
                      {props.storeInfo
                        ? props.storeInfo.name
                          ? props.storeInfo.name
                          : ''
                        : ''}
                    </Typography>
                    <div
                      className="d-flex align-items-center"
                      style={{ zIndex: 1 }}
                    >
                      {token && user ? (
                        <IconButton
                          onClick={() =>
                            props.isPreview ? null : handleGoToDashboard()
                          }
                        >
                          <AccountCircleOutlined style={{ color: '#2b2d32' }} />
                        </IconButton>
                      ) : (
                        <Link
                          className="px-md-4 px-2"
                          style={{ color: '#2b2d32' }}
                          onClick={
                            isPreview
                              ? null
                              : () =>
                                  history.push(`/shop/${storeInfo.site}/login`)
                          }
                        >
                          Log in
                        </Link>
                      )}
                      <IconButton onClick={cartButton()}>
                        <Badge
                          classes={{ badge: classes.customBadge }}
                          badgeContent={productList.length}
                        >
                          <ShoppingCartOutlined style={{ color: '#2b2d32' }} />
                        </Badge>
                      </IconButton>
                    </div>
                  </Container>
                )}
                <CategoriesDialog
                  classes={classes}
                  productList={productList}
                  handleGoToDashboard={handleGoToDashboard}
                  cartButton={cartButton}
                  color={color}
                  storeInfo={storeInfo}
                  categories={product_categories}
                  open={open}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                />
              </AppBar>
            )}
          </>
        );

      case 'NFT':
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="nft-header container"
          >
            <AppBar
              style={{
                boxShadow: 'none',
                backgroundColor: 'transparent',
                position: 'relative',
              }}
            >
              <Toolbar
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 0,
                }}
              >
                <AppBarTemplate
                  storeInfo={props.storeInfo}
                  isPreview={props.isPreview}
                />
                <div className={classes.mobile} style={{ padding: '20px 0' }}>
                  {/* LOGIN */}
{/*                   {token && user ? (
                    <IconButton
                      onClick={() =>
                        props.isPreview ? null : handleGoToDashboard()
                      }
                      style={{
                        color: '#fff',
                      }}
                    >
                      Login
                    </IconButton>
                  ) : (
                    <Button
                      style={{
                        color: '#fff',
                        fontWeight: 'normal',
                      }}
                      onClick={
                        isPreview
                          ? null
                          : () => history.push(`/shop/${storeInfo.site}/login`)
                      }
                    >
                      Log in
                    </Button>
                  )} */}

                  <SentroButton
                    id="connectwallet_button"
                    onClick={handleOpenConnectWallet}
                    label="Connect Wallet"
                    style={{
                      display: walletAddress ? 'none' : 'block',
                      fontSize: 16,
                      color: '#5CA2D5',
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />
                  <button
                    onClick={handleShowLogout}
                    className="nft-button-cut-corner-transparent"
                    style={{
                      display: walletAddress ? 'block' : 'none',
                      fontSize: 16,
                      color: '#5CA2D5',
                      marginTop: 0,
                      fontWeight: 'normal',
                      width: '120px',
                    }}
                  >
                    {getDisplayWallet(walletAddress)}
                  </button>

                  {/* <IconButton onClick={cartButton()}>
                    <Badge color="secondary" badgeContent={productList.length}>
                      <LocalMallOutlinedIcon className={classes.cartIcon} />
                    </Badge>
                  </IconButton> */}

                  <div>
                    <IconButton
                      onClick={handleClickOpen}
                      color="secondary"
                      style={{ padding: 5 }}
                    >
                      <MoreIcon
                        style={{
                          color: '#fff',
                          fontSize: 20,
                        }}
                      />
                    </IconButton>
                    <Dialog
                      className={classes.dialog}
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                    >
                      <div
                        style={{
                          padding: 20,
                          marginTop: '20vh',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div className={classes.buttons}>
                          <SentroButton
                            onClick={
                              isPreview
                                ? null
                                : () => {
                                    history.push(`/shop/${storeInfo.site}`);
                                  }
                            }
                            fullWidth
                            id="marketplace_button"
                            variant="contained"
                            label="Marketplace"
                            style={{
                              margin: '10px 0',
                              backgroundColor: color.primary.main,
                            }}
                          />
                          <SentroButton
                            /* onClick={
                                  isPreview
                                    ? null
                                    : () => {
                                        history.push(item.path);
                                      }
                                } */
                            fullWidth
                            id="leaderboard_button"
                            variant="contained"
                            label="Leaderboard"
                            style={{
                              margin: '10px 0',
                              backgroundColor: color.primary.main,
                            }}
                          />
                          <SentroButton
                            /* onClick={
                                  isPreview
                                    ? null
                                    : () => {
                                        history.push(item.path);
                                      }
                                } */
                            fullWidth
                            id="gallery_button"
                            variant="contained"
                            label="Gallery"
                            style={{
                              margin: '10px 0',
                              backgroundColor: color.primary.main,
                            }}
                          />
                          <SentroButton
                            onClick={
                              isPreview
                              ? null
                              : () => {
                                window.location.href = alamatSiteUrl + '/dapps/profile'
                              }
                            }
                            fullWidth
                            id="profile_button"
                            variant="contained"
                            label="Profile"
                            style={{
                              margin: '10px 0',
                              backgroundColor: color.primary.main,
                            }}
                          />
                          {/* <SentroButton
                            onClick={
                                  isPreview
                                    ? null
                                    : () => {
                                        history.push(item.path);
                                      }
                                }
                            fullWidth
                            id="signup_button"
                            variant="contained"
                            label="Signup"
                            style={{
                              margin: '10px 0',
                              backgroundColor: color.primary.main,
                            }}
                          /> */}

                          <SentroButton
                            onClick={handleClose}
                            // variant="contained"
                            fullWidth
                            label="Close"
                            style={{
                              margin: '10px 0',
                              color: '#0F3344',
                            }}
                          />
                        </div>
                      </div>
                    </Dialog>
                  </div>
                </div>
                <Box className={classes.desktop} style={{ padding: '20px 0' }}>
                  <SentroButton
                    id="marketplace_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}`);
                          }
                    }
                    label="Marketplace"
                    style={{
                      fontSize: 16,
                      color: '#fff',
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />

                  <SentroButton
                    id="leaderboard_button"
                    /* onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/products`);
                          }
                    } */
                    label="Leaderboard"
                    style={{
                      fontSize: 16,
                      color: '#fff',
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />
                  <SentroButton
                    id="gallery_button"
                    /* onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/products`);
                          }
                    } */
                    label="Gallery"
                    style={{
                      fontSize: 16,
                      color: '#fff',
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />
                  <SentroButton
                    id="profile_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                          window.location.href = alamatSiteUrl + '/dapps/profile'
                          }
                    }
                    label="Profile"
                    style={{
                      fontSize: 16,
                      color: '#fff',
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />                  
                  {/* LOGIN */}

                  {/* <SentroButton
                    id="products_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/products`);
                          }
                    }
                    label="Login"
                    style={{
                      fontSize: 16,
                      color: '#5CA2D5',
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />
                  {/* <SentroButton
                    id="products_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/products`);
                          }
                    } 
                    label="Signup"
                    style={{
                      fontSize: 16,
                      color: '#5CA2D5',
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />  */}
                  <NFTConnectMetamaskModal
                    open={openConnectMetamask}
                    onClose={handleCloseConnectWallet}
                  />
                  <SentroButton
                    id="products_button"
                    onClick={handleOpenConnectWallet}
                    label="Connect Wallet"
                    style={{
                      display: walletAddress ? 'none' : 'block',
                      fontSize: 16,
                      color: '#5CA2D5',
                      marginTop: 0,
                      fontWeight: 'normal',
                      padding: '0 30px',
                    }}
                  />
                  <button
                    onClick={handleShowLogout}
                    className="nft-button-cut-corner-transparent"
                    style={{
                      display: walletAddress ? 'block' : 'none',
                      fontSize: 16,
                      color: '#5CA2D5',
                      marginTop: 0,
                      fontWeight: 'normal',
                      width: '120px',
                    }}
                  >
                    {getDisplayWallet(walletAddress)}
                  </button>
                  <button
                    className="nft-button-2"
                    onClick={handleDisconnectWallet}
                    style={{
                      display: showDisconnectWallet ? 'block' : 'none',
                      fontSize: 19,
                      color: 'white',
                      marginTop: 0,
                      position: 'absolute',
                      margin: '0 auto',
                      fontWeight: 'normal',
                      width: '120px',
                      top: '60px',
                    }}
                  >
                    Log out
                  </button>
                </Box>
              </Toolbar>
              <PromoBannerSticky />
            </AppBar>
          </div>
        );
    }
  };

  return (
    <div>
      {setAppbarTemplate(storeInfo ? storeInfo.template : '')}
      <div>
        <CartDrawer open={cart_drawer} toggleDrawer={toggleDrawer} />
      </div>
    </div>
  );
};

export default Header;
