import React from "react";
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  DialogContent,
  Chip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import Text from "../../../../../common/text";
import { Radio } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import ErrorIcon from "@material-ui/icons/Error";
import { editVoucher } from "../webBuilder/websiteBuilderActions";
import DeactivateVoucherModal from "./deactivateModal";
const DialogTransition = props => <Slide direction="up" {...props} />;

const useStyles = makeStyles(theme => ({
  appbar: {
    position: "fixed",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  closeBtn: { position: "absolute", left: 15 },
  radioStyles: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column"
  }
}));

const MobileViewVoucher = props => {
  const {
    open,
    setOpen,
    values,
    setValues,
    handleEditVoucher,
    state,
    setState
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openUnsaveChangesModal, setOpenUnsaveChangesModal] = React.useState(
    false
  );
  const stores = useSelector(state => state.mobileDashboard.stores);
  const filters = useSelector(state => state.mobileDashboard.voucherFilters);
  const selectedVoucher = useSelector(
    state => state.mobileDashboard.selectedVoucher
  );
  let selectedVoucherCode = selectedVoucher ? selectedVoucher.voucher_code : "";
  const [openDeactivateModal, setOpenDeactivateModal] = React.useState(false);

  const initialValues = {
    voucher_code: "",
    type: "",
    discount_value: 0,
    applies_to: "",
    category: "",
    minimum_requirements: "",
    minimum_purchase_amount: 0,
    usage_limit_to_one: false,
    usage_limit_no_times: false,
    usage_limit_value: 0,
    start_date: new Date(),
    start_time: moment()
      .toDate()
      .getTime(),
    end_date: new Date(),
    end_time: moment()
      .toDate()
      .getTime()
  };

  function setValueChanges(value, name) {
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function handleUsageLimit(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.checked
    });
  }

  function handleCancel() {
    setOpen(false);
    setValues(initialValues);
    window.scrollTo(0, 0);
  }

  async function handleDeactivate(value) {
    setOpenDeactivateModal(false);
    let params = {
      ...values,
      is_active: value
    };

    const res = await dispatch(
      editVoucher(params, filters, stores, selectedVoucherCode)
    );

    try {
      if (res.status === 200) {
        window.scrollTo(0, 0);
        setOpen(false);
        setState({
          ...state,
          openAlert: true,
          alertType: "success",
          alertMessage: "Voucher successfully updated!"
        });
        setValues(initialValues);
      }
    } catch {
      window.scrollTo(0, 0);
      setState({
        ...state,
        openAlert: true,
        alertType: "error",
        alertMessage: "Oops, something went wrong. Please try again."
      });
    }
  }

  const types = [
    { label: "Discount (Percentage %)", value: "percentage" },
    { label: "Discount (Fixed Amount)", value: "fixed_amount" }
    // { label: "Discount (Free shipping)", value: "free shipping" }
  ];

  const minimumRequirements = [
    { value: "None" },
    { value: "Minimum Purchase Amount (PHP)" },
    { value: "Minimum Quantity of items" }
  ];

  const usageLimit = [
    // {
    //   label: "Limit to 1 customer per voucher",
    //   value: values.usage_limit_to_one,
    //   name: "usage_limit_to_one"
    // },
    {
      label: "Limit to number of times that it can be used in total",
      value: values.usage_limit_no_times,
      name: "usage_limit_no_times"
    }
  ];

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.closeBtn}
              onClick={handleCancel}
            >
              <CloseIcon color="primary" />
            </IconButton>
            <div className={classes.header}>View Voucher</div>
          </Toolbar>
        </AppBar>
        <div style={{ paddingTop: "8vh" }}>
          <div style={{ padding: 20 }}>
            <div
              style={{ borderBottom: "1px solid #d1d5dd", paddingBottom: 20 }}
            >
              <Text textType="p3_Semibold" content="Voucher Code" />
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  id="voucher_code"
                  disabled
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  margin="dense"
                  placeholder="E.g. COOKIEVERSE"
                  style={{ marginTop: 0 }}
                  value={values.voucher_code}
                />
                <Chip
                  size="medium"
                  label={values.is_active ? "Active" : "Inactive"}
                  style={{
                    backgroundColor: values.is_active ? "#18b4a1" : "gray",
                    color: "#fff",
                    marginLeft: 60,
                    padding: "0 8px"
                  }}
                />
              </div>
              <Button
                id="deactivate_btn"
                variant="outlined"
                color="primary"
                style={{ marginTop: 10, height: 40, fontSize: 12 }}
                onClick={() =>
                  values.is_active
                    ? setOpenDeactivateModal(true)
                    : handleDeactivate(true)
                }
              >
                {values.is_active ? "Deactivate" : "Activate"}
              </Button>
            </div>
            <div
              style={{ padding: "20px 0", borderBottom: "1px solid #d1d5dd" }}
            >
              <Text textType="p3_Semibold" content="Type" />
              <Radio.Group
                id="type"
                disabled
                className={classes.radioStyles}
                value={values.voucher_type}
              >
                {types.map(item => {
                  return (
                    <Radio value={item.value} style={{ marginBottom: 15 }}>
                      {item.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
              <div style={{ marginTop: 10 }}>
                <Text textType="p3_Semibold" content="Discount Value" />
                <TextField
                  id="discount_value"
                  disabled
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  type="number"
                  InputProps={
                    values.voucher_type === "percentage"
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }
                      : ""
                  }
                  value={parseFloat(values.discount_value)}
                  onChange={e =>
                    setValueChanges(e.target.value, "discount_value")
                  }
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <Text textType="p3_Semibold" content="Applies To" />
                <Radio.Group
                  id="applies_to"
                  disabled
                  className={classes.radioStyles}
                  value={values.applies_to}
                  onChange={e => setValueChanges(e.target.value, "applies_to")}
                >
                  {["All Products"].map(item => {
                    return (
                      <Radio value={item} style={{ marginBottom: 15 }}>
                        {item}
                      </Radio>
                    );
                  })}
                </Radio.Group>

                <div
                  style={{
                    display:
                      values.applies_to === "Specific Category" ? "" : "none"
                  }}
                >
                  <Text textType="p3_Semibold" content="Choose category" />
                  <TextField
                    fullWidth
                    disabled
                    value={values.category}
                    variant="outlined"
                    margin="dense"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ padding: "20px 0", borderBottom: "1px solid #d1d5dd" }}
            >
              <Text textType="p3_Semibold" content="Minimum Requirements" />
              <Radio.Group
                id="minimum_requirements"
                disabled
                className={classes.radioStyles}
                value={values.minimum_requirements}
                onChange={e =>
                  setValueChanges(e.target.value, "minimum_requirements")
                }
              >
                {minimumRequirements.map(item => {
                  return (
                    <Radio value={item.value} style={{ marginBottom: 15 }}>
                      {item.value}
                    </Radio>
                  );
                })}
              </Radio.Group>
              <div
                style={{
                  display:
                    values.minimum_requirements === "None" ||
                    values.minimum_requirements === ""
                      ? "none"
                      : ""
                }}
              >
                <Text
                  textType="p3_Semibold"
                  content={
                    values.minimum_requirements === "Minimum Quantity of items"
                      ? "Minimum Quantity of items"
                      : "Minimum Purchase Amount (PHP)"
                  }
                />
                <TextField
                  id="minimum_purchase_amount"
                  disabled
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  type="number"
                  fullWidth
                  InputProps={
                    values.minimum_requirements === "Minimum Quantity of items"
                      ? ""
                      : {
                          startAdornment: (
                            <InputAdornment position="start">
                              PHP
                            </InputAdornment>
                          )
                        }
                  }
                  value={values.minimum_purchase_amount}
                  onChange={e =>
                    setValueChanges(e.target.value, "minimum_purchase_amount")
                  }
                />
              </div>
            </div>
            <div
              style={{ padding: "20px 0", borderBottom: "1px solid #d1d5dd" }}
            >
              <Text textType="p3_Semibold" content="Usage Limit" />
              <FormGroup id="usage_limit" disabled>
                {usageLimit.map(item => {
                  return (
                    <FormControlLabel
                      style={{ marginRight: 0, display: "flex" }}
                      control={
                        <Checkbox
                          disabled
                          checked={item.value}
                          name={item.name}
                          onChange={handleUsageLimit}
                          color="primary"
                        />
                      }
                      label={
                        <div style={{ fontSize: 14, color: "#54575f" }}>
                          {item.label}
                        </div>
                      }
                    />
                  );
                })}
              </FormGroup>
              <div
                style={{ display: values.usage_limit_no_times ? "" : "none" }}
              >
                <Text textType="p3_Semibold" content="Usage Limit" />
                <TextField
                  id="usage_limit_value"
                  disabled
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  color="secondary"
                  value={values.usage_limit_value}
                  onChange={e =>
                    setValueChanges(e.target.value, "usage_limit_value")
                  }
                />
              </div>
            </div>
            <div style={{ padding: "20px 0" }}>
              <Text textType="p3_Semibold" content="Active Dates" />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div style={{ marginTop: 15, display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <div className={classes.label1}>Start Date/Time</div>
                    <KeyboardDateTimePicker
                      id="start_date"
                      disabled
                      label=""
                      margin="dense"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY hh:mm A"
                      orientation="portrait"
                      color="secondary"
                      style={{ marginTop: 0, width: "100%" }}
                      InputAdornmentProps={{ position: "start" }}
                      value={values.start_datetime}
                    />
                  </div>
                  {/* <div style={{ marginLeft: 20 }}>
                    <Text textType="p3_Semibold" content="Start Time" />
                    <KeyboardTimePicker
                      id="start_time"
                      disabled
                      label=""
                      margin="dense"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      orientation="portrait"
                      color="secondary"
                      style={{ marginTop: 0 }}
                      keyboardIcon={<QueryBuilderIcon />}
                      InputAdornmentProps={{ position: "start" }}
                      value={values.start_time}
                      onChange={e => setValueChanges(e, "start_time")}
                    />
                  </div> */}
                </div>
                <div style={{ marginTop: 15, display: "flex" }}>
                  {/* <div>
                    <Text textType="p3_Semibold" content="End Date" />
                    <KeyboardDatePicker
                      id="end_date"
                      disabled
                      label=""
                      margin="dense"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY"
                      orientation="portrait"
                      color="secondary"
                      style={{ marginTop: 0 }}
                      InputAdornmentProps={{ position: "start" }}
                      value={values.end_date}
                      onChange={e => setValueChanges(e, "end_date")}
                    />
                  </div> */}
                  <div style={{ width: "100%" }}>
                    <div className={classes.label1}>End Date/Time</div>
                    <KeyboardDateTimePicker
                      disabled
                      id="end_date"
                      label=""
                      margin="dense"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/YYYY hh:mm A"
                      orientation="portrait"
                      color="secondary"
                      style={{ marginTop: 0, width: "100%" }}
                      InputAdornmentProps={{ position: "start" }}
                      value={values.end_datetime}
                    />
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                id="edit_btn"
                variant="contained"
                color="primary"
                disableElevation
                fullWidth
                onClick={() => handleEditVoucher(values)}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
        <DeactivateVoucherModal
          id="deactivate_modal"
          open={openDeactivateModal}
          setOpen={setOpenDeactivateModal}
          handleDeactivate={handleDeactivate}
        />
      </Dialog>
    </div>
  );
};

export default MobileViewVoucher;
