import React from "react";
import { Paper } from "@material-ui/core";
import history from "../../store/history";
import Path from "../../Paths";
import { destroy } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import SentroButton from "../common/sentroButton";
import { showAlert } from "../common/commonActions";

require("dotenv").config();

const CreateWebCard = props => {
  const dispatch = useDispatch();
  let loading = useSelector(state => state.dashboard.loading);
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let [error, setError] = React.useState(false);

  function createNew() {
    if (!user.is_verified) {
      setError(true);
    } else {
      dispatch(destroy("storeInfo"));
      history.push(Path.SHOP_NEW_CREATE);
    }
  }
  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #d1d5dd",
        padding: 32
      }}
    >
      <div className="d-flex">
        <div style={{ width: "50%", padding: 32 }}>
          <img
            id="card_img"
            src={`https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/create_shop_logo.png`}
            alt="card-img2"
            width="100%"
            height="auto"
          />
        </div>
        <div style={{ width: "50%", padding: 32 }}>
          <h3>
            <strong>Start building your shop</strong>
          </h3>
          <div className="my-4" style={{ color: "#54575f", fontSize: 14 }}>
            {props.language === "Filipino"
              ? "Magkaroon ng sariling online shop sa ilang clicks lang! Sagutin lamang ang ilang mga katanungan at kami na ang bahala sa iba."
              : "Having your own online shop is just a few clicks away! Simply answer a few questions and we’ll do the rest for you."}
          </div>
          <div>
            <SentroButton
              name="createShop_button"
              id="createShop_button"
              color="primary"
              variant="contained"
              onClick={createNew}
              disableElevation
              fullWidth
              label={
                props.language === "Filipino"
                  ? "Gumawa ng online shop"
                  : "Create my online shop"
              }
            />
            {error && (
              <div
                id="error"
                className="text-center mt-2"
                style={{ color: "red", fontSize: 12 }}
              >
                Activate your Sentro account to enjoy more features.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWebCard;
