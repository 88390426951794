import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  TextField,
  Switch,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  Typography,
  Button,
  AppBar,
  Tabs,
  Tab,
  Box,
  Checkbox,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SnackBarAlert from "./snackbarAlert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Text from "../../common/text";
import SentroButton from "../common/sentroButton";
import history from "../../store/history";
import Path from "../../Paths";
import Utils from "../common/utils";
import OrderActions from "./orderActions";
import SentroLoading from "../common/loading";
import GoogleDeliveryField from "../common/googleAddressForm";
import SentroCheckBox from "../common/sentroCheckBox";
import { error } from "winston";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import deliveryIcon from "../../assets/images/delivery.png";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ backgroundColor: "#fff" }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}
let useStyles = makeStyles(theme => ({
  form: {
    backgroundColor: "#fff",
    border: "1px solid #d1d5dd",
    borderRadius: 8,
    padding: "2em",
    marginTop: "2em"
  },
  appbar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd",
    borderRadius: 8
  },
  label :{
    color: "#F97663",
    fontSize: 18,
    fontWeight: 600
  },
  label1: {
    fontSize: 14,
    color: "#2B2D32",
    fontWeight: 500
  },
  label2: {
    fontSize: 12,
    color: "#54575f"
  },
  motobox: {
    alignItems: "center",
    display: "flex",
    marginTop: "2vh"
  },
  fee: {
    fontSize: 28,
    color: "#2B2D32",
    fontWeight: 600
  }
}));

let Sender = props => {
  const { delivery, setDeliveryDetails, language } = props;
  const classes = useStyles();
  const [error, setError] = React.useState({
    mobileError: ""
  });

  return (
    <div>
      <div>
        <p className={classes.label}>
          Order
        </p>
        <div className={classes.label1}>
          {language === "English"
            ? "What kind of item/s are you shipping?"
            : "Anong klaseng item/mga item ang iyong ipapadala?"}
        </div>
        <div className={classes.label2}>
          e.g. documents, food, boxed fragile item, groceries.
        </div>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          placeholder="Type here"
          color="secondary"
          inputProps={{ maxLength: 32 }}
          value={delivery.matter}
          onChange={e => setDeliveryDetails("matter", e.target.value)}
        />
      </div>
      <div style={{ marginTop: "3vh" }}>
        <div className={classes.label1}>
          {language === "English"
            ? "When do you want the package picked up?"
            : "Kailan mo gusto ipasundo ang pakete?"}
        </div>
        <div className={classes.label2}>Date</div>
        <KeyboardDateTimePicker
          ampm={false}
          disablePast
          margin="dense"
          color="secondary"
          format="dd MMMM yyyy hh:mm"
          inputVariant="outlined"
          style={{ margin: 0, padding: 0 }}
          value={delivery.date_time}
          onChange={e => setDeliveryDetails("date_time", e)}
        />
      </div>
      <div style={{ marginTop: "3vh" }}>
        <div className={classes.label1}>
          {language === "English"
            ? "What type of vehicle do you need?"
            : "Anong klaseng sasakyan ang gusto mong gamitin?"}
        </div>
        <RadioGroup
          style={{ display: "flex", flexDirection: "inherit" }}
          value={delivery.vehicle_type}
          onChange={e => setDeliveryDetails("vehicle_type", e.target.value)}
        >
          <FormControlLabel
            value="8"
            control={<Radio color="primary" />}
            label="Motorbike"
          />
          <FormControlLabel
            value="7"
            style={{ marginLeft: 40 }}
            control={<Radio color="primary" />}
            label="Car"
          />
        </RadioGroup>
      </div>

      <div style={{ marginTop: "1vh" }}>
        <div className={classes.label1}>
          {language === "English"
            ? "How heavy is the package?"
            : "Gaano kabigat ang iyong pakete?"}
        </div>
        <RadioGroup
          defaultValue={delivery.vehicle_type === "8" ? "5" : "300"}
          onChange={e => setDeliveryDetails("weight_kg", e.target.value)}
        >
          {delivery.vehicle_type === "8" ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                value="5"
                control={<Radio color="primary" />}
                label="up to 5 kilos"
              />
              <FormControlLabel
                value="10"
                control={<Radio color="primary" />}
                label="up to 10 kilos"
              />
              <FormControlLabel
                value="20"
                control={<Radio color="primary" />}
                label="up to 20 kilos"
              />
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                value="300"
                control={<Radio color="primary" />}
                label="up to 300 kilos"
              />
              <FormControlLabel
                value="600"
                control={<Radio color="primary" />}
                label="up to 600 kilos"
              />
            </div>
          )}
        </RadioGroup>
      </div>
      <p className={`mt-5 ${classes.label}`}>
        Pick-up
      </p>
      <div style={{ marginTop: 10 }}>
        <div className={classes.label2}>Pick-up Address</div>
        <GoogleDeliveryField
          margin="dense"
          initialValue={delivery.src_address}
          onChange={value => {
            setDeliveryDetails("src_address", value.address);
            setDeliveryDetails("src_long", value.lng);
            setDeliveryDetails("src_lat", value.lat);
          }}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <div className={classes.label2}>
          Location details, landmark, specific instructions, etc.
        </div>
        <TextField
          margin="dense"
          fullWidth
          variant="outlined"
          color="secondary"
          style={{ marginTop: 0 }}
          value={delivery.src_location}
          onChange={e => setDeliveryDetails("src_location", e.target.value)}
        />
      </div>

      <div className={`mt-3 ${classes.label1}`}>
        {language === "English"
          ? "Please provide your contact details"
          : "Paki lagay ang iyong contact details"}
      </div>
      <div className="row pt-1">
        <div className="col-12 col-sm-6">
          <div className={classes.label2}>Name</div>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            color="secondary"
            inputProps={{ maxLength: 64 }}
            style={{ marginTop: 0 }}
            value={delivery.src_name}
            onChange={e => setDeliveryDetails("src_name", e.target.value)}
          />
        </div>
        <div className="col-12 col-sm-6">
          <div className={classes.label2}>Mobile Number</div>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            color="secondary"
            style={{ marginTop: 0 }}
            value={delivery.src_mobile}
            inputProps={{ maxLength: 11 }}
            error={error.mobileError}
            helperText={error.mobileError}
            onChange={e => {
              const input = e.target.value.replace(/[^0-9]/g, "");
              setDeliveryDetails("src_mobile", input);
              if (input === "") {
                setError({
                  ...error,
                  mobileError: "This field is required."
                });
              } else if (input.length < 11) {
                setError({
                  ...error,
                  mobileError: "Please enter a valid 11-digit mobile number"
                });
              } else if (input.charAt(0) != 0) {
                setError({
                  ...error,
                  mobileError: "You have entered an invalid mobile number"
                });
              } else if (input.charAt(1) != 9) {
                setError({
                  ...error,
                  mobileError: "You have entered an invalid mobile number"
                });
              } else {
                setError({ ...error, mobileError: "" });
              }
            }}
          />
        </div>
        
      </div>
    </div>
  );
};

let Recipient = props => {
  const { delivery, setDeliveryDetails, language } = props;
  const classes = useStyles();
  const [error, setError] = React.useState({
    mobileError: ""
  });

  return (
    <div>
      <p className={`mt-5 ${classes.label}`}>
        Drop-off
      </p>
      <div style={{ marginTop: 10 }}>
        <div className={classes.label2}><strong>Drop-off Address</strong></div>
        <p className={`${classes.label2} mb-0`}>Indicate the nearest location on the map</p>
        <GoogleDeliveryField
          margin="dense"
          initialValue={delivery.delivery_address}
          onChange={value => {
            setDeliveryDetails("delivery_address", value.address);
            setDeliveryDetails("delivery_long", value.lng);
            setDeliveryDetails("delivery_lat", value.lat);
          }}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <div className={classes.label2}>
          Location details, landmark, specific instructions, etc.
        </div>
        <TextField
          margin="dense"
          fullWidth
          variant="outlined"
          color="secondary"
          style={{ marginTop: 0 }}
          value={delivery.delivery_location}
          onChange={e =>
            setDeliveryDetails("delivery_location", e.target.value)
          }
        />
      </div>
      <div className="row">
        <div className="col-12 col-sm-6" style={{ marginTop: 10 }}>
          <div className={classes.label2}>Name</div>
          <TextField
            margin="dense"
            variant="outlined"
            color="secondary"
            inputProps={{ maxLength: 64 }}
            style={{ marginTop: 0 }}
            value={delivery.delivery_name}
            onChange={e => setDeliveryDetails("delivery_name", e.target.value)}
          />
        </div>
        <div className="col-12 col-sm-6" style={{ marginTop: 10 }}>
          <div className={classes.label2}>Mobile Number</div>
          <TextField
            margin="dense"
            variant="outlined"
            color="secondary"
            style={{ marginTop: 0 }}
            inputProps={{ maxLength: 11 }}
            value={delivery.delivery_mobile}
            error={error.mobileError}
            helperText={error.mobileError}
            onChange={e => {
              const input = e.target.value.replace(/[^0-9]/g, "");
              setDeliveryDetails("delivery_mobile", input);
              if (input === "") {
                setError({
                  ...error,
                  mobileError: "This field is required."
                });
              } else if (input.length < 11) {
                setError({
                  ...error,
                  mobileError: "Please enter a valid 11-digit mobile number."
                });
              } else if (input.charAt(0) != 0) {
                setError({
                  ...error,
                  mobileError: "You have entered an invalid mobile number"
                });
              } else if (input.charAt(1) != 9) {
                setError({
                  ...error,
                  mobileError: "You have entered an invalid mobile number"
                });
              } else {
                setError({ ...error, mobileError: "" });
              }
            }}
          />
        </div>
      </div>
      <div
        className={classes.motobox}
        style={{ display: delivery.vehicle_type === "8" ? "" : "none" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={delivery.motobox}
              onChange={e => setDeliveryDetails("motobox", !delivery.motobox)}
              name="motobox"
              color="primary"
            />
          }
          label={
            language === "English"
              ? "Do you require a motobox?"
              : "Kailangan mo ba ng motobox?"}
        />
        {/* <div className={classes.label1}>
          {language === "English"
            ? "Do you require a motobox?"
            : "Kailangan mo ba ng motobox?"}
        </div>
        <Switch
          size="medium"
          checked={delivery.motobox}
          onChange={e => setDeliveryDetails("motobox", !delivery.motobox)}
          name="motobox"
          color="primary"
        /> */}
      </div>
      <p className={`mb-0 ${classes.label2}`}><strong>Do you want to insure your package?</strong></p>
      <div className={classes.label2}>
        Items can be insured up to PHP 10,000. Optional
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={delivery.insurance}
            onChange={e => setDeliveryDetails("insurance", !delivery.insurance)}
            name="insurance"
            color="primary"
          />
        }
        label={"Insure Package"}
      />
      {/* <div className={classes.motobox}>
        <div className={classes.label1}>
          {language === "English"
            ? "Do you want to insure your package?"
            : "Gusto mo bang i-insure ang pakete mo?"}
        </div>
        <Switch
          size="medium"
          checked={delivery.insurance}
          onChange={e => setDeliveryDetails("insurance", !delivery.insurance)}
          name="insurance"
          color="primary"
        />
      </div> */}

      <div
        style={{ display: delivery.insurance ? "" : "none"}}
      >
        <div className={classes.label2}>Declared value of the package</div>
        <TextField
          InputProps={{
            startAdornment: <p className="text-secondary mr-2 mb-0">PHP</p>
          }}
          margin="dense"
          color="secondary"
          variant="outlined"
          type="number"
          style={{ width: "11em", marginTop: 0 }}
          value={delivery.insurance_value}
          onChange={e => setDeliveryDetails("insurance_value", e.target.value)}
        />
      </div>
    </div>
  );
};

let BookShippingNotice = props => {
  const { open, setOpen, setOpenDeliveryForm, delivery } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle
        style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 20px 20px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content"
          }}
        >
          <img
            src={deliveryIcon}
            style={{ width: "6em", display: "flex", margin: "10px auto" }}
          />
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <div
              style={{
                fontSize: 20,
                color: "#002c42",
                fontWeight: 600,
                padding: "0 20px"
              }}
            >
              Ready to Book?
            </div>
            <div style={{ marginTop: 15, fontSize: 12, textAlign: "left" }}>
              Deliveries are handled by a third party. Sentro is neither
              responsible nor accountable for the delivery of the items, its
              condition, and other delivery-related concerns. Read{" "}
              <span
                onClick={() => window.open("https://mrspeedy.ph/tc")}
                style={{ color: "#f97663", fontWeight: 600, cursor: "pointer" }}
              >
                {" "}
                Mr.Speedy's Terms and Conditions
              </span>{" "}
              and{" "}
              <span
                onClick={() =>
                  window.open("https://mrspeedy.ph/privacy-policy")
                }
                style={{ color: "#f97663", fontWeight: 600, cursor: "pointer" }}
              >
                {" "}
                Privacy Policy
              </span>
            </div>
            <div style={{ marginTop: "5vh" }}>
              <Button
                variant="contained"
                fullWidth
                disableElevation
                color="primary"
                onClick={() => {
                  setOpen(false);
                  dispatch(OrderActions.bookShipping(delivery));
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

let OrderDeliveryForm = props => {
  const { language } = props;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let selectedOrder = useSelector(state =>
    state.order ? state.order.selectedOrder : null
  );
  // let loading = useSelector((state) => state.order.loading);
  if (selectedOrder == null) {
    history.push(Path.ORDER_LIST_VIEW);
  }
  let classes = useStyles();
  let message = useSelector(state => state.order.message);
  let alert = useSelector(state => state.order.dialogOpen);
  let rows = JSON.parse(selectedOrder ? selectedOrder["cart"] : "[]");
  let vehicle = useSelector(state =>
    state.form.orderDeliveryForm
      ? state.form.orderDeliveryForm.values
        ? state.form.orderDeliveryForm.values.vehicle
        : 8
      : 8
  );
  let shippingFee = parseFloat(useSelector(state => state.order.shippingFee));
  let loading = parseFloat(useSelector(state => state.order.loading));
  let [value, setValue] = React.useState(0);
  let [openBookNotice, setOpenBookNotice] = React.useState(false);
  let [formHasErrors, setFormHasErrors] = React.useState(true);
  let [error, setError] = React.useState({
    mobileError: ""
  });
  const [delivery, setDelivery] = React.useState({
    order_id: selectedOrder ? selectedOrder["id"] : "",
    matter: "",
    date_time: moment(new Date()).add("30", "m"),
    vehicle_type: "8",
    weight_kg: "5",
    motobox: true,
    insurance: true,
    insurance_value: 0,
    src_location: selectedOrder ? selectedOrder.sender_address : "",
    src_name: selectedOrder ? selectedOrder.sender_name : "",
    src_long: selectedOrder ? selectedOrder.src_long : 0,
    src_lat: selectedOrder ? selectedOrder.src_lat : 0,
    src_address: "",
    src_mobile: selectedOrder ? selectedOrder.sender_mobile : "",
    delivery_name: selectedOrder ? selectedOrder.full_name : "",
    delivery_long: selectedOrder ? selectedOrder.delivery_long : 0,
    delivery_lat: selectedOrder ? selectedOrder.delivery_lat : 0,
    delivery_mobile: selectedOrder ? selectedOrder.mobile_number : "",
    delivery_address: "",
    delivery_location: selectedOrder ? selectedOrder.address : "",
    paid: selectedOrder ? selectedOrder.paid : false
  });
  let dispatch = useDispatch();

  React.useEffect(() => {
    for (let entry in error) {
      if (error[entry].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [error]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [checkShippingTimeoutId, setCheckShippingTimeoutId] = React.useState("")
  function checkShipping() {
    clearTimeout(checkShippingTimeoutId);
    setCheckShippingTimeoutId(setTimeout(() => {
      if(delivery.matter && delivery.src_address &&
        delivery.delivery_address && delivery.paid){
          dispatch(OrderActions.calculateShipping(delivery));
        }
    }, 1000))
  }

  function bookShipping() {
    setOpenBookNotice(true);
  }



  function setDeliveryDetails(key, value) {
    setDelivery(prevState => ({
      ...prevState,
      [key]: value
    }));
  }
  React.useEffect(() => {
    checkShipping();
  }, [delivery]);

  const {
    matter,
    src_name,
    src_address,
    src_mobile,
    delivery_name,
    delivery_mobile,
    delivery_address,
    paid
  } = delivery;

  return (
    <div>
      <SentroLoading open={loading} />
      <SnackBarAlert
        open={alert}
        alertType={message ? message.type : "success"}
        message={message ? message.message : ""}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.form}>
          <Sender
            language={language}
            delivery={delivery}
            setDeliveryDetails={setDeliveryDetails}
          />
          <Recipient
            language={language}
            delivery={delivery}
            setDeliveryDetails={setDeliveryDetails}
          />
        </div>
        <div className={classes.form}>
          <div className="d-flex mt-3 align-items-center">
            <div>
              <p className="text-secondary">
                Delivery fee
              </p>
              <div className={classes.fee}>
                {shippingFee === 0
                  ? "--"
                  : "PHP " + Utils.commafy(shippingFee)}
              </div>
            </div>
            {/* <Button
              variant="outlined"
              color="primary"
              style={{ height: 40, width: "50%", margin: 5 }}
              onClick={() => checkShipping()}
              disabled={
                matter === "" ||
                src_address === "" ||
                delivery_address === "" ||
                paid === false
                  ? true
                  : false
              }
            >
              Compute
            </Button> */}
            <Button
              className="ml-auto"
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              style={{ maxWidth: 280 }}
              onClick={() => bookShipping()}
              disabled={
                matter === "" ||
                src_name === "" ||
                src_address === "" ||
                src_mobile === "" ||
                delivery_name === "" ||
                delivery_mobile === "" ||
                delivery_address === "" ||
                paid === false ||
                shippingFee === 0
                  ? true
                  : false
              }
            >
              Book for Delivery
            </Button>
          </div>
        </div>
        <BookShippingNotice
          open={openBookNotice}
          setOpen={setOpenBookNotice}
          delivery={delivery}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default OrderDeliveryForm;
