const for_pickup = ["Pending Pickup", "Pickup fail", "Staging"];
const in_transit = [
  "Successful Pickup",
  "En-route to Sorting Hub",
  "Arrived at Sorting Hub",
  "Transferred to 3PL",
  "Arrived at Origin Hub",
  "On Vehicle for Delivery",
  "Pending Reschedule",
  "First Attempt Delivery Fail",
  "On Vehicle for Delivery(RTS)",
  "Arrived at Distribution Point",
  "Pending Pickup at Distribution Point",
  "Parcel Size",
  "Parcel Weight",
  "Parcel Measurements Update"
];
const return_to_sender = [
  "Returned to Sender",
  "Return to Sender Triggered",
  "Van en-route to pickup"
];
export const getDeliveryStatus = item => {
  if (item.delivery_detail) {
    if (item.delivery_detail.courier === "mrspeedy") {
      if (
        item.mrspeedy_status === "new" ||
        item.mrspeedy_status === "available"
      ) {
        return "Looking for rider";
      } else if (item.mrspeedy_status === "active") {
        return "For pickup";
      } else if (item.mrspeedy_status === "courier_departed") {
        return "OTW to Customer";
      } else if (item.mrspeedy_status === "finished") {
        return "Delivered";
      } else if (item.mrspeedy_status === "canceled") {
        return "Cancelled";
      } else if (item.mrspeedy_status === "completed") {
        return "Completed";
      } else {
        return "--";
      }
    } else if (item.delivery_detail.courier === "ninjavan") {
      if (for_pickup.includes(item.mrspeedy_status)) {
        return "For pickup";
      } else if (in_transit.includes(item.mrspeedy_status)) {
        return "In transit";
      } else if (return_to_sender.includes(item.mrspeedy_status)) {
        return "Return to Sender";
      } else if (
        item.mrspeedy_status === "Completed" ||
        item.mrspeedy_status === "Successful Delivery"
      ) {
        return "Delivered";
      } else if (item.mrspeedy_status === "Cancelled") {
        return "Cancelled";
      } else {
        return "--";
      }
    } else {
      return "--";
    }
  } else {
    return "--";
  }
};
