import React, { Fragment } from "react";
import clsx from 'clsx';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ACL from "../../../../common/utilities/ACL";
import apiConfig from "../../../../../common/apiConfig";
import SentroTextField from "../../../../common/sentroTextField";
import Action from "../../../Redux/Actions";
import AdminDashboardLayout from "../../../layout";
import moment from "moment";
import { Divider } from "@material-ui/core";
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Link, Element, animateScroll as scroll } from "react-scroll";
import Path from "../../../../../Paths";

const useStyles = theme => ({
	cardWrapper: {
		margin: "0em 1.5em 1.5em",
		[theme.breakpoints.down("sm")]: {
			margin: "0em 1em 1em"
		},
	},
	userDetailsDiv: {
		padding: "1.5em"
	},
	formWrapper: {
		maxWidth: 410
	},
	card: {
		border: "1px solid #E6EAF0",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
		borderRadius: 4,
		backgroundColor: "white",
		padding: "2.0em",
		maxWidth: "100%"
	},
	pageHeader: {
		backgroundColor: "#F1F1F1",
		display: "flex !important",
		padding: "3em 1.5em 1.5em",
		alignItems: "center",
		fontWeight: 600,
		fontSize: 14,
		color: "#F97663 !important"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	paddingTop: {
		marginTop: theme.spacing(2),
	},
	inputLabel: {
		color: "#212B36", 
		fontSize: 12,
		marginBottom: "0.5em !important"
	},
	input: {
		width: "100%"
	},
	radioGroup: {
		display: "flex",
		flexDirection: "row",
		width: "100%"
	},
	radio: {
		backgroundColor: 'transparent',
		border: "solid 1px rgb(249, 118, 99)",
		width: 24,
		height: 24,
		borderRadius: "50%",
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  margin: 3,
		  borderRadius: "50%",
		  background: 'white',
		  content: '""'
		}
	},
	checkedRadio: {
		backgroundColor: 'transparent',
		border: "solid 1px #d7d7d7",
		width: 24,
		height: 24,
		borderRadius: "50%",
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  margin: 3,
		  borderRadius: "50%",
		  background: "rgb(249, 118, 99)",
		  content: '""'
		}
	},
	headerButtons: {
		height: 35,
		marginLeft: 7,
		flexGrow: 0,
		marginTop: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: 5,
		},
	},
	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
	dFlex: {
		display: "flex"
	},
	detailTitle: {
		minWidth: 200,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			minWidth: "auto"
		},
	}, 
	detailData: {
		fontWeight: 600,
		textAlign: "left",
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			textAlign: "right"
		},
	},
	alertSuccess: {
		padding: "0.75em 1em",
		border: "solid 1px #1DD28B",
		borderLeft: "solid 5px #1DD28B",
		borderRadius: 4,
		background: "#1dd28b14",
		margin: "0em 1.5em 2em"
	}
});

class AdminAppMarketplacePartnersDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// dialog vars
			dialogType: "edit",
			dialogStat: false,
			dialogErrorMessage: '',
			dialogTitleText: '',
			dialogBody: '',

			alert: ''
		}
		this.setDialogState = this.setDialogState.bind(this)

		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount = () => {
		const { dispatch, login, match } = this.props
		const id = match.params.id
		dispatch(Action.fetchPartner(id));
	}
	
	setDialogState(state, dialogType = "edit") {
		console.log(state, dialogType)
		this.setState({
			dialogStat : state,
			dialogType: state ? dialogType: this.state.dialogType
		})
	}

	handleDelete = async () => {
		const { dispatch, match, history } = this.props
		const id = match.params.id
		const response = await dispatch(Action.deletePartner(id))
		this.setDialogState(false)
		if (response.status == 200 || response.status == 204) {
			localStorage.setItem("ALERT_SUCCESS", `Partner successfully deleted.`)
			history.push(Path.ADMIN_APP_MARKETPLACE_PARTNERS)
		} else {
			this.setState({
				dialogStat: true,
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}
	
	handleChange = (event, stateName) => {
		this.setState({
			[stateName] : event.target.value
		})
	}

	handleSubmit = async () => {
		const { is_active } = this.state;
		const { dispatch, match, history } = this.props
		const id = match.params.id
		this.setDialogState(false)
		const response = await dispatch(Action.userDetailMethod("patch", id, {
			is_active: !is_active
		}))
		if (response.status == 200) {
			this.setState({
				alert: "User successfully updated",
				is_active: !is_active
			})
		} else {
			this.setState({
				dialogStat: true,
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}

	resendVerificationEmail = async () => {
		const { dispatch, adminDashboard } = this.props
		const response = await dispatch(Action.resendVerificationPartner(adminDashboard.partner.email));
		if (response.status == 200) {
			this.setState({
				alert: `Activation link sent to partner ${adminDashboard.partner.name} (${adminDashboard.partner.user.first_name} ${adminDashboard.partner.user.last_name} - ${adminDashboard.partner.email})`
			})
			this.setDialogState(false)
		} else {
			this.setState({
				dialogStat: true,
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}
	
	render (){
		const {
			has_admin_role,
			first_name,
			last_name,
			email,
			access_type,
			created_on,
			last_login,
			stores,
			is_active,
			is_verified,
			dialogStat,
			dialogType,
			dialogTitleText,
			dialogBody,
			dialogErrorMessage,
			alert
		} = this.state;
		const { classes, adminDashboard, history } = this.props
		const dialogTitle = () => {
			if (dialogType === "edit") {
				return "Are you sure you want to edit this record?"
			} else if (dialogType == "delete") {
				return "Are you sure you want to delete this record?"
			} else if (dialogType == "disable") {
				return `Are you sure you want to ${is_active ? 'disable':'enable'} this user?`
			} else if (dialogType==="info") {
				return dialogTitleText
			} else if (dialogType == "resend_verification") {
				return "Please confirm"
			}
		}
		const dialogContent = () => {
			if (["edit", "delete", "disable"].includes(dialogType)) {
				return "Click submit to confirm"
			}  else if (dialogType == "resend_verification") {
				return `Resend activation link to partner ${adminDashboard.partner.name} (${adminDashboard.partner.user.first_name} ${adminDashboard.partner.user.last_name} - ${adminDashboard.partner.email})?`
			} else if (dialogType==="info") {
				return dialogBody
			}
		}
		const actionButton = () => {
			if (dialogType==="edit") {
				return (
					<Button
						id="submit_button" 
						onClick={(event) => this.handleSubmit()} 
						color="primary" 
						autoFocus
						variant="contained"
						disableElevation 
						className={classes.modalActions}
						style={{marginLeft: 10}}
					>
						Submit
					</Button>
				)
			} else if (dialogType==="delete") {
				return (
					<Button 
						id="delete_button"
						onClick={(event) => {
							this.handleDelete()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Delete
					</Button>
				)
			} else if (dialogType==="disable") {
				return (
					<Button 
						id="disable_button"
						onClick={(event) => {
							this.handleSubmit()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Submit
					</Button>
				)
			} else if (dialogType==="resend_verification") {
				return (
					<Button 
						id="confirm_button"
						onClick={(event) => {
							this.resendVerificationEmail()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Confirm
					</Button>
				)
			}
		}
		return (
			<AdminDashboardLayout filterHeaderHidden={true}>
				<ACL roles={["superuser"]} redirect_to="/admin/dashboard">
					<Dialog
						open={dialogStat}
						onClose={(event) => this.setDialogState(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						maxWidth="xs"
					>	
						<div className={classes.modalDiv}>
							<div className={classes.modalTile}>
								{dialogTitle()}
							</div>
							<div className={classes.modalBody}>
								{dialogContent()}
							</div>
							<div className={classes.modalActionsWrapper}>
								<Button
									id="action_button"
									variant="outlined"
									onClick={(event) => this.setDialogState(false)} 
									color="primary"
									className={classes.modalActions}
								>
									{ dialogType == "info" ? "Close": "Cancel" }
								</Button>
								{actionButton()}
							</div>
						</div>
					</Dialog>
					<Fragment>
						<div style={{
							display: "flex",
							flexWrap: "wrap",
							alignItems: "center",
							marginBottom: "2em",
							padding: "0 2em"
						}}>
							<div className={classes.pageTitle}>
								Partner Details
							</div>
							<Button
								id="edit_button" 
								variant="contained" 
								color="primary" 
								disableElevation 
								className={classes.headerButtons}
								onClick={(event) => history.push(`/admin/marketplace/partners/${this.props.match.params.id}/edit`)}
								style={{ marginLeft: 0 }}
							>
								Edit
							</Button>
							<Button
								id="delete_button"
								variant="outlined" 
								color="primary" 
								disableElevation 
								className={classes.headerButtons}
								onClick={(event) => this.setDialogState(true, "delete")}
							>
								Delete
							</Button>
							{
								adminDashboard.partner.user && 
								<Button 
									id="resend_button"
									variant="outlined" 
									color="primary" 
									disableElevation
									disabled={adminDashboard.partner.user.is_verified || !adminDashboard.partner.user.email}
									className={classes.headerButtons}
									onClick={(event) => this.setDialogState(true, "resend_verification")}
								>
									Resend Activation Link
								</Button>
							}
						</div>
						<div>
							<Fragment>
								{
									adminDashboard.partner.email ?
									<Fragment>
										{
											alert ?
											<p className={classes.alertSuccess}>{alert}</p>
											:
											""
										}
										<Paper className={classes.cardWrapper}>
											<Grid container spacing={3} className={classes.userDetailsDiv}>
												<Grid item md={6} xs={12}>
													<div className={classes.dFlex}>
														<p className={classes.detailTitle}>Name:</p>
														<strong className={classes.detailData}>{`${adminDashboard.partner.user.first_name} ${adminDashboard.partner.user.last_name}`}</strong>
													</div>
													<div className={classes.dFlex}>
														<p className={classes.detailTitle}>Company Name:</p>
														<strong className={classes.detailData}>{adminDashboard.partner.name}</strong>
													</div>
													<div className={classes.dFlex}>
														<p className={classes.detailTitle}>Apps & Services:</p>
														<strong className={classes.detailData}>{
															adminDashboard.partner.apps.length?
															adminDashboard.partner.apps.map((app, index) => (
																<a href={`${apiConfig.localHost}marketplace/${app.id}`} target="_blank">
																	{`${app.name}`}{index !== 0 && adminDashboard.partner.apps.length ? ", ": ``}
																</a>
															))
															:
															"-"
														}</strong>
													</div>
													<div className={classes.dFlex}>
														<p className={classes.detailTitle}>Email:</p>
														<strong className={classes.detailData}>{adminDashboard.partner.email}</strong>
													</div>
													<div className={classes.dFlex}>
														<p className={classes.detailTitle}> Account Status</p>
														<strong className={classes.detailData}>{
															adminDashboard.partner.user.is_active ?
															"Active"
															:
															"Disabled"
														}</strong>
													</div>
													<div className={classes.dFlex}>
														<p className={classes.detailTitle}>Member Since:</p>
														<strong className={classes.detailData}>{adminDashboard.partner.user.created_on}</strong>
													</div>
													<div className={classes.dFlex}>
														<p className={classes.detailTitle}>Last Login</p>
														<strong className={classes.detailData}>{adminDashboard.partner.user.last_login}</strong>
													</div>
												</Grid>
												<Grid item md={6} xs={12}>
													<div className={classes.dFlex}>
														<p className={classes.detailTitle}>No of Apps Owned:</p>
														<strong className={classes.detailData}>{adminDashboard.partner.apps_count}</strong>
													</div>
												</Grid>
											</Grid>
										</Paper>
									</Fragment>
									:
									<div style={{ height: "70vh", width: "100%", overflow: "hidden" }}>
										<div style={{ paddingTop: "10%" }}>
											<div style={{
												display: "flex",
												justifyContent: "center"
											}}>
												<img
													style={{width: "15%", height: "15%"}}
													src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
												/>
											</div>
											<div style={{
													display: "flex",
													justifyContent: "center",
													marginTop: 40,
													fontSize: 16,
													fontWeight: 600,
													color: "#b8bcc3",
													padding: "0 3em",
													textAlign: "center"
											}}>
												Data not found
											</div>
										</div>
									</div>
								}
							</Fragment>
						</div>
					</Fragment>
					{/* // :
					// <div className={`${classes.cardWrapper} ${classes.formWrapper}`}>
					// 	<FormUser 
					// 		state={this.state} 
					//         setPageMode={this.setPageMode}
					// 		setDialogState={this.setDialogState}
					// 		handleChange={this.handleChange}
					// 		handleSubmit={this.handleSubmit}
					// 		handleDelete={this.handleDelete}
					// 	/>
					// </div> */}
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(useStyles)(AdminAppMarketplacePartnersDetail));
