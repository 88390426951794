import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import wallet from "../../../../assets/images/wallet.svg";
import { Launch, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Button, useMediaQuery, IconButton } from "@material-ui/core";
import Utils from "../../../common/utils";
import { showAlert } from "../../../common/commonActions";
import { createCODWallet, goToCompleteProfile } from "../../action";

export default function CODWallet(props) {
  const useStyles = makeStyles(theme => ({
    limitDiv: {
      [theme.breakpoints.down("sm")]: {
        maxHeight: 0,
        overflow: "hidden",
        transition: "all 0.5s ease-in-out"
      }
    },
    extended: {
      maxHeight: "200px !important"
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const loading = useSelector(state => state.payments.loading);
  const codWallet = useSelector(state => state.payments.codWallet);
  const kycLevel = useSelector(state => state.payments.buxStatus);
  const [hide, setHide] = React.useState(false);
  const handleActivate = () => {
    if (kycLevel === 0) {
      dispatch(
        showAlert({
          type: "warning",
          message: "Upgrade to Pro level first to activate COD Wallet"
        })
      );
    } else {
      dispatch(createCODWallet());
    }
  };
  return (
    <div className="bg-white rounded" style={{ border: "1px solid #d1d5dd" }}>
      <div
        style={{ backgroundColor: "#d4d5d8" }}
        className="p-md-4 p-3 rounded-top d-flex"
      >
        <img src={wallet} alt="wallet" width="50" height="50" />
        <div className="w-100 pl-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="text-white" style={{ fontSize: 14 }}>
              Payout wallet
            </div>
          </div>
          <div
            className="font-weight-light text-white pt-4"
            style={{ fontSize: 12 }}
          >
            Balance
          </div>
          <div className="d-flex justify-content-between align-items-center w-100">
            <strong style={{ fontSize: 28 }} className="text-white">
              {loading ? (
                <div
                  className="spinner-border mx-auto"
                  role="status"
                  style={{
                    color: "white",
                    fontSize: 12,
                    height: 25,
                    width: 25
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                `₱ 0.00`
              )}
            </strong>
            <div
              className="text-white font-weight-light d-flex align-items-center"
              style={{ fontSize: 14 }}
            >
              Go to BUx{" "}
              <Launch
                onClick={() => dispatch(goToCompleteProfile(user.id, kycLevel))}
                style={{ color: "white", fontSize: 23 }}
                className="pl-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`bg-white pb-3 pt-5 px-5 text-center`}>
        <div className={`${classes.limitDiv} ${hide ? classes.extended : ""}`}>
          <div style={{ fontSize: 14, fontWeight: 600, color: "#54575f" }}>
            Receive payments from your Payout wallet
          </div>
          <div style={{ fontSize: 12, color: "#54575f" }} className="py-3">
            By activating your BUx Payout wallet, you can receive payments from
            BUx transactions.
          </div>
        </div>
        <div className="pt-md-5 pt-2 pb-3">
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            style={{ height: 40 }}
            onClick={handleActivate}
          >
            {loading ? (
              <div className="d-flex my-5 mr-3">
                <div
                  className="spinner-border mx-auto"
                  role="status"
                  style={{
                    color: "white",
                    fontSize: 12,
                    height: 25,
                    width: 25
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              ""
            )}
            Activate Payout wallet
          </Button>
        </div>
        <div className="d-md-inherit d-md-none d-flex justify-content-center">
          <IconButton
            onClick={() => setHide(hide ? false : true)}
            className="p-1"
          >
            {hide ? (
              <KeyboardArrowDown color="secondary" />
            ) : (
              <KeyboardArrowUp color="secondary" />
            )}
          </IconButton>
        </div>
      </div>
    </div>
  );
}
