import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ACL from "../../common/utilities/ACL";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import AdminDashboardLayout from "../layout";
import Variation from './components/variations';
import CardsSection from './components/cards/section';
import CategorySelect from './components/categorySelect';
import PartnerSelect from './components/partnerSelect';
import GalleryImageUpload from './components/galleryImageUpload';
import Actions from "../Redux/Actions";
import TextField from '@material-ui/core/TextField';
import CKEditor from "react-ckeditor-component";

let config = {
    toolbarGroups: [
      { name: "document", groups: ["mode", "document", "doctools"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"]
      },
      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
      },
      "/",
      { name: "links", groups: ["links"] },
      { name: "insert", groups: ["insert"] },
      { name: "styles", groups: ["styles"] },
      { name: "colors", groups: ["colors"] },
      { name: "tools", groups: ["tools"] },
      "/",
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
      { name: "about", groups: ["about"] }
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    allowedContent: true
    // disableNativeSpellChecker: false
    // skin: "moono",
    // plugins:
    //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc"
  };
const useStyles = theme => ({
  root: {
    padding: "0 2em",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2em",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 1em"
    },
    paddingBottom: "1em",
    backgroundColor: "#f1f1f1"
  },
  pageTitle: {
    fontSize: 26,
    fontWeight: 600,
    margin: "0.5em 0",
    color: "#002C42",
    flexGrow: 1,
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  createNewButton: {
    padding: "0 1.5em",
    height: 35,
    fontSize: 14,
    fontWeight: 600
  },
  flex: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    padding: "2em",
    display: "flex",
    flexDirection: "column"
  },
  field: {
    fontSize: 12,
    margin: "1.5em 0 0",
    maxWidth: '50%',
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
    },
  },
  divider: {
    margin: "1em 0"
  },
  fieldLabel:{
    margin: "1em 0"
  },
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 35,
    height: 20,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

class AdminAppMarketplaceCreate extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      // fields
      partner: '',
      name: '',
      logo: '',
      category: [],
      short_description: '',
      distributor: '',
      supported_devices: '',
      overview: "",
      detailed_description: '[]',
      features: "",
      general_rating: 0,
      variants_banner: [],
      variants: [],
      images: [],
      embed_video: '',
      is_active: false
    }
    this.partnerOnChange = this.partnerOnChange.bind(this)
    this.categoryOnChange = this.categoryOnChange.bind(this)
    this.updateVariants = this.updateVariants.bind(this)
    this.updateDetailedDescription = this.updateDetailedDescription.bind(this)
    this.imageOnChange = this.imageOnChange.bind(this)
    this.logoOnChange = this.logoOnChange.bind(this)
    this.variantBannerOnChange = this.variantBannerOnChange.bind(this)
    this.overviewEditorOnChange = this.overviewEditorOnChange.bind(this)
    this.variantDescriptionEditorOnChange = this.variantDescriptionEditorOnChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  fieldOnChange(event, fieldname){
    this.setState({
      ...this.state,
      [fieldname]: event.target.value
    })
  }

  overviewEditorOnChange = (evt) => {
      var overview = evt.editor.getData();
      this.setState({
        overview
      })
  }

  variantDescriptionEditorOnChange = (evt) => {
    var features = evt.editor.getData();
    this.setState({
      features
    })
}
  
  partnerOnChange(partner){
    this.setState({
      partner
    })
  }

  categoryOnChange(category){
    this.setState({
      category: [category]
    })
  }

  updateVariants(variants){
    this.setState({
      ...this.state,
      variants
    })
  }

  updateDetailedDescription(detailed_description){
    this.setState({
      ...this.state,
      detailed_description: JSON.stringify(detailed_description)
    })
  }

  imageOnChange(images){
    this.setState({
      ...this.state,
      images,
    })
  }

  variantBannerOnChange(image){
    this.setState({
      ...this.state,
      variants_banner: image.length ? image[0] : ``
    })
  }

  logoOnChange(logo){
    this.setState({
      ...this.state,
      logo: logo[0]
    })
  }
  isActiveOnChange = (e) => {
    this.setState({
      ...this.state,
      is_active: e.target.checked
    })
  }

  componentDidMount = async () => {
    const { dispatch, match, location } = this.props;
    const path = location.pathname.split("/")
    const is_edit =  path[1] === "admin" && path[path.length-2] === "marketplace" && path[path.length-1] !== "create"
    await dispatch(Actions.fetchPartnersAndCategories())
    if(is_edit){
      // if edit fetch app data
      const app = await dispatch(Actions.fetchApp(match.params.id, {
        extra_params: {
          is_admin_view: true
        }
      }));
      if(app){
        this.setState({
            isEdit: true,
            ...app,
            variants: app.variants || [],
            images: app.gallery.map((data) => data.image)
        })
      }
    }
  }

  submit(){
    const { dispatch, match, location } = this.props;
    const path = location.pathname.split("/")
    const is_edit =  path[1] === "admin" && path[path.length-2] === "marketplace" && path[path.length-1] !== "create"
    if(is_edit){
      dispatch(Actions.editApp(this.state.id, this.state))
    } else {
      dispatch(Actions.createApp(this.state))
    }
  }

  render (){
    const { classes, dispatch, app, loading } = this.props;
    const { variants, detailed_description, images, isEdit } = this.state;
    return (
      <AdminDashboardLayout filterHeaderHidden={true}>
        <ACL roles={["superuser"]} redirect_to="/admin/dashboard">
            <div className={classes.root}>
              {
                !app && !loading && isEdit ?
                  <div style={{ height: "50vh", width: "100%", overflow: "hidden", marginTop: "2em" }}>
                    <div style={{ paddingTop: "10%" }}>
                      <div style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        <img
                          style={{width: "15%", height: "15%"}}
                          src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
                        />
                      </div>
                      <div style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 40,
                          fontSize: 18,
                          fontWeight: 600,
                          color: "#b8bcc3",
                          padding: "0 3em",
                          textAlign: "center"
                      }}>
                        App not found
                      </div>
                    </div>
                  </div>
                :
                <>
                  <div className={classes.flex}>
                    <h2 className={classes.pageTitle}>{this.state.isEdit ? `Edit App`: `Create New App`}</h2>
                    <Button
                      id="view"
                      color="primary"
                      disableElevation 
                      className={classes.createNewButton}
                      variant="contained"
                      disableElevation={true}
                      onClick={() => {
                      this.submit()
                      }}
                    >
                      Save
                    </Button>
                  </div>
                  <Paper className={classes.body}>
                    <p className={classes.fieldLabel}><strong>Partner</strong></p>
                    <PartnerSelect onChange={this.partnerOnChange} initialValue={this.state.partner}/>
                    {/* <Divider className={classes.divider} /> */}
                    <p className={classes.fieldLabel}><strong>Logo</strong></p>
                    <GalleryImageUpload onChange={this.logoOnChange} isRequired singleImage={true} preview={this.state.logo} isEdit={isEdit}/>
                    <p className={classes.fieldLabel}><strong>Basic</strong></p>
                    <TextField
                      className={classes.field}
                      onChange={(event) => this.fieldOnChange(event, "name")}
                      label="App Name"
                      margin="dense"
                      placeholder="App name"
                      variant="outlined"
                      value={this.state.name}
                    />
                    <TextField
                      className={classes.field}
                      onChange={(event) => this.fieldOnChange(event, "short_description")}
                      label="Short Description"
                      margin="dense"
                      placeholder="Short Description"
                      variant="outlined"
                      value={this.state.short_description}
                    />
                    <CategorySelect onChange={this.categoryOnChange} initialValue={this.state.category} />
                    <TextField
                      className={classes.field}
                      onChange={(event) => this.fieldOnChange(event, "supported_devices")}
                      label="Supported Devices"
                      margin="dense"
                      placeholder="Supported Devices"
                      variant="outlined"
                      value={this.state.supported_devices}
                    />
                    <TextField
                      className={classes.field}
                      onChange={(event) => this.fieldOnChange(event, "distributor")}
                      label="Distributor"
                      margin="dense"
                      placeholder="Distributor"
                      variant="outlined"
                      value={this.state.distributor}
                    />
                    <p className={classes.fieldLabel}><strong>Detailed Informations</strong></p>
                    <CKEditor 
                      config={config}
                      activeClass="p10"
                      content={this.state.overview}
                      events={{
                        // "blur": this.onBlur,
                        // "afterPaste": this.afterPaste,
                        "change": this.overviewEditorOnChange
                      }}
                    />
                    <p className={classes.fieldLabel}><strong>Variants Page Banner</strong></p>
                    <GalleryImageUpload
                        onChange={this.variantBannerOnChange}
                        singleImage={true} 
                        preview={this.state.variants_banner} 
                        isEdit={isEdit}
                    />
                    <p className={classes.fieldLabel}><strong>Variant Description</strong></p>
                    <CKEditor 
                      config={config}
                      activeClass="p10"
                      content={this.state.features}
                      events={{
                        // "blur": this.onBlur,
                        // "afterPaste": this.afterPaste,
                        "change": this.variantDescriptionEditorOnChange
                      }}
                    />
                    <p className={classes.fieldLabel}><strong>Variants</strong></p>
                    <Variation variants={variants} updateVariants={this.updateVariants} />
                    <p className={classes.fieldLabel}><strong>App Cards</strong></p>
                    <CardsSection data={JSON.parse(detailed_description)} updateDetailedDescription={this.updateDetailedDescription} />
                    <p className={classes.fieldLabel}><strong>Gallery</strong></p>
                    <GalleryImageUpload onChange={this.imageOnChange} gallery={images} singleImage={false} isEdit={isEdit}/>
                    <p className={classes.fieldLabel}><strong>Embed Video</strong></p>
                    <TextField
                      className={classes.field}
                      multiline
                      fullWidth
                      onChange={(event) => this.fieldOnChange(event, "embed_video")}
                      label="Embed Video"
                      margin="dense"
                      placeholder="Embed Video"
                      variant="outlined"
                      value={this.state.embed_video}
                      rows={4}
                    />
                    <div className="d-flex align-items-center my-3">
                      <p className={classes.fieldLabel}><strong>Is Active?</strong></p>
                      <p className="mb-0 mx-3 text-secondary">Disabled</p>
                      <AntSwitch checked={this.state.is_active} onChange={this.isActiveOnChange} name="checkedC" />
                      <p className="mb-0 ml-3 text-secondary">Active</p>
                    </div>
                  </Paper>
                </>
              }
            </div>
            
        </ACL>
      </AdminDashboardLayout>
    )
  }
}

const mapStateToProps = state => ({ adminDashboard }) => ({ 
  app: adminDashboard.app,
  loading: adminDashboard.loading
});
export default connect(mapStateToProps)(withStyles(useStyles)(AdminAppMarketplaceCreate));
