import _ from 'lodash';

export default class DashboardUtils {
  static filterMerchants = (data, filters) => {
    // filter by tab
    data = data.filter((item) => 
      filters.tab === 0 ? 
        !["requested", "declined"].includes(item.status): 
        item.status === "requested"
    )

    // sortBy
    if (filters.sortBy !== "Sort by" && filters.category.length) {
      data = _.orderBy(data, [filters.sortBy],[filters.sort])
    }

    // category
    if (filters.category !== "Category" && filters.category.length) {
      data = data.filter((item) => 
        item.global_category.name === filters.category
      )
    }

    // search
    if (filters.search) {
      data = data.filter((item) => 
        filters.tab === 0 ? 
          [item.merchant.name, item.global_category.name, 
            item.exclusivity].join().toLowerCase().includes(filters.search.toLowerCase()):
          [item.merchant.name, item.global_category.name, 
            item.merchant.email, `${item.merchant.site}.sentro.ph`]
            .join().toLowerCase().includes(filters.search.toLowerCase()) 
      )
    }

    return data
  }
}