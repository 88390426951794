import React from "react";
import {
  Button,
  Paper,
  Chip,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody
} from "@material-ui/core";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { makeStyles } from "@material-ui/core/styles";
import sampleImg from "../../../assets/images/imgPlaceholder2.svg";
import tags from "../../../assets/images/tags.svg";
import { useSelector, useDispatch } from "react-redux";
import Path from "../../../Paths";
import apiConfig from "../../../common/apiConfig";
import Utils from "../../common/utils";
import BuyerActions from "./redux/actions";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { WishlistItemPriceDisplay } from "../../common/template/components/PriceDisplay";
const ViewOrderCard = props => {
  const useStyles = makeStyles(theme => ({
    cartHeaderSection: {
      padding: "18px 24px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #d1d5dd"
    },
    labelTitle: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149"
    },
    btnViewShop: {
      padding: "0 20px",
      fontWeight: 500,
      borderRadius: 20,
      height: 40
    },
    columnItem: {
      padding: "16px 0 16px 0",
      border: "none",
      width: "40%",
      fontSize: 14,
      fontWeight: 600,
      color: "rgba(43,45,50,0.8)"
    },
    column1: {
      width: "10%",
      border: "none",
      fontSize: 14,
      fontWeight: 600,
      color: "rgba(43,45,50,0.8)"
    },
    column2: {
      position: "relative",
      width: "20%",
      border: "none",
      fontSize: 14,
      fontWeight: 600,
      color: "rgba(43,45,50,0.8)"
    },
    itemSection: {
      display: "flex"
    },
    productImage: {
      width: 60,
      height: 60,
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    labelbold: {
      fontSize: 12,
      fontWeight: 600,
      color: "#2b2d32"
    },
    label: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2b2d32"
    },
    variantChip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    },
    btnQuantity: {
      borderRadius: 0,
      width: 25,
      height: 25,
      minWidth: 25
    },
    variantChip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const selected_order = useSelector(
    state => state.buyerDashboard.selected_purchased_order
  );
  const cart = selected_order ? JSON.parse(selected_order.cart) : [];

  function productImage(item) {
    if (item.gallery) {
      if (item.gallery.length) {
        return item.gallery[0].image;
      } else {
        return sampleImg;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return sampleImg;
      }
    }
  }

  function getProduct(product) {
    let params = {
      ...product,
      price: product.unit_price,
      has_variants: product.productSelection ? true : false
    };
    return params;
  }

  return (
    <Paper style={{ marginTop: "5vh", width: "65%" }}>
      <div className={classes.cartHeaderSection}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.labelTitle}>
            {selected_order ? selected_order.store.name : ""}
          </div>
          {/* <Chip style={{ marginLeft: "2vw" }} label="Sports & Travel" /> */}
        </div>
        <Button
          color="primary"
          variant="text"
          className={classes.btnViewShop}
          startIcon={<StorefrontIcon color="primary" />}
          onClick={() =>
            window.open(
              `${apiConfig.localHost}shop/${
                selected_order ? selected_order.store.site : ""
              }`
            )
          }
        >
          View shop
        </Button>
      </div>
      <div style={{ padding: 24 }}>
        <Table style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnItem} align="left">
                Item
              </TableCell>
              <TableCell className={classes.column2} align="center">
                Quantity
              </TableCell>
              <TableCell className={classes.column2} align="center">
                Subtotal
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cart.map((item, key) => {
              return (
                <TableRow
                  id="table-cart"
                  style={{ borderBottom: "1px dashed #d1d5dd" }}
                >
                  <TableCell className={classes.columnItem} align="left">
                    <div className={classes.itemSection}>
                      <div
                        className={classes.productImage}
                        style={{
                          backgroundImage: `url(${productImage(item)})`
                        }}
                      />
                      <div style={{ marginLeft: "1vw", width: "60%" }}>
                        <div className={classes.labelbold}>{item.name}</div>
                        <div style={{ display: "flex", marginTop: "1vh" }}>
                          {item.productSelection
                            ? item.productSelection.variants.map(variant => (
                                <Chip
                                  size="small"
                                  label={variant.name}
                                  className={classes.variantChip}
                                />
                              ))
                            : ""}
                        </div>
                        <div
                          style={{ padding: "16px 0 0" }}
                          className={classes.label}
                        >
                          <WishlistItemPriceDisplay
                            symbol="₱"
                            item={getProduct(item)}
                            productSelection={item.productSelection}
                          />
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className={classes.column2} align="center">
                    <div
                      style={{
                        position: "absolute",
                        bottom: 16,
                        left: 16,
                        right: 16
                      }}
                    >
                      <div className={classes.label}>{item.quantity}</div>
                    </div>
                  </TableCell>
                  <TableCell className={classes.column2} align="center">
                    <div
                      style={{
                        position: "absolute",
                        bottom: 16,
                        left: 16,
                        right: 16
                      }}
                    >
                      <div className={classes.label}>
                        ₱{" "}
                        {Utils.commafy(
                          parseFloat(
                            item.quantity *
                              (item.discounted_price || item.unit_price)
                          )
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className="d-flex pt-4">
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: "rgba(43,45,50, 0.8)"
            }}
          >
            Contact:
          </div>
          {selected_order && selected_order.store.email ? (
            <div className="d-flex align-items-center ml-3">
              <MailOutlineIcon style={{ color: "rgba(43,45,50,0.8" }} />
              <div
                className="ml-2"
                style={{
                  fontSize: 12,
                  color: "rgba(43,45,50, 0.8)"
                }}
              >
                {selected_order && selected_order.store.email}
              </div>
            </div>
          ) : (
            ""
          )}
          {selected_order && selected_order.store.mobile ? (
            <div className="d-flex align-items-center ml-3">
              <PhoneIphoneIcon style={{ color: "rgba(43,45,50,0.8" }} />
              <div
                className="ml-2"
                style={{
                  fontSize: 12,
                  color: "rgba(43,45,50, 0.8)"
                }}
              >
                {selected_order && selected_order.store.mobile}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Paper>
  );
};

export default ViewOrderCard;
