import axios from "axios";
import apiConfig from "../../common/apiConfig";

async function getStores() {
  try {
    let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    let id = currentUser.id;
    let response = await axios.get(`${apiConfig.apiURL}/api/dashboard/store/`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
      },
      params: { email: id },
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function getProductCategoriesService(uuid) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/store/${uuid}/products-categories/`,

      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function getVoucherService() {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/promo/voucher_filter/?desktop=True&is_active=True`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function filterVoucherService(params) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/promo/voucher_filter/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
        params,
      }
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function createVoucherServices(params, stores) {
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/promo/voucher/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
        params: { store: stores[0].uuid },
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function editVoucherServices(params, stores, selectedVoucherCode) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/promo/voucher/${selectedVoucherCode}/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
        params: { store: stores[0].uuid },
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function getProductsServices(store_uuid, params) {
  try {
    const response = await axios.get(
      `${apiConfig.apiURL}/api/dashboard/${store_uuid}/products/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
        params: {
          ...params,
          category:
            params.category === "Category"
              ? ""
              : JSON.stringify([params.category]),
        },
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

export {
  getStores,
  getProductCategoriesService,
  getVoucherService,
  createVoucherServices,
  editVoucherServices,
  filterVoucherService,
  getProductsServices,
};
