import React from "react";
import {
  makeStyles,
  Button,
  IconButton,
  Dialog,
  Slide,
  AppBar,
  Toolbar
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
  btn: {
    color: "#F97663",
    background: "white",
    borderRadius: 4,
    border: "1px solid #F97663",
    height: "35px",
    minWidth: 70,
    fontSize: 12,
    fontWeight: "normal"
  },
  selected: {
    backgroundColor: "#C24A39 !important",
    color: "white"
  }
}));

const SelectGroup = props => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(props.value);
  const [isAllSelected, setIsAllSelected] = React.useState(true);
  const checkIfAllSelected = (categories = false) => {
    // const choices = props.choices.map((choice) => choice.name)
    const choices = props.choices;
    const selected_choices = categories ? categories : selected;
    for (let i in choices) {
      const choice = choices[i];
      if (choice !== "All") {
        if (!selected_choices.includes(choice)) {
          return false;
        }
      }
    }
    return true;
  };
  const onChange = (e, val) => {
    if (val !== "All") {
      if (e.target.checked) {
        // add
        if (!selected.includes(val)) {
          const array = addOrRemoveAll([...selected, val]);
          setSelected(array);
        }
      } else {
        // remove
        let array = selected.filter(e => e !== val);
        array = addOrRemoveAll(array);
        setSelected(array);
      }
    } else {
      setSelected(e.target.checked ? ["All", ...props.choices] : []);
    }
  };
  const addOrRemoveAll = filters => {
    let array = filters;
    if (checkIfAllSelected(array) && !array.includes("All")) {
      array = ["All", ...array];
    } else {
      array = array.filter(e => e !== "All");
    }
    return array;
  };
  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(selected);
    }
  }, [selected]);
  return (
    <div className="d-flex flex-column flex-wrap pb-2">
      {!props.choices.length && props.loading ? (
        <div class="text-center w-100">
          <div className="spinner-border spinner-border-sm text-secondary">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : props.error ? (
        <div
          className="text-center w-100 text-secondary my-4"
          style={{ cursor: "pointer" }}
          onClick={props.fetchCategories}
        >
          Try again...
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center">
            <Checkbox
              size="medium"
              checked={selected.includes("All")}
              onChange={e => onChange(e, "All")}
              name="All"
              color="primary"
            />
            <p className="mb-0">All</p>
          </div>
          {props.choices.map((choice, index) => (
            <div key={index} className="d-flex align-items-center">
              <Checkbox
                size="medium"
                checked={selected.includes(choice)}
                onChange={e => onChange(e, choice)}
                name={choice}
                color="primary"
              />
              <p className="mb-0">{choice || "Others"}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default SelectGroup;
