import Services from "../services/wishlistServices";
import { DashboardActionTypes } from "./dashboardActions";
import { BuyerDashboardActionTypes } from './buyerDashboardActions';

export const WishlistActionTypes = {
  CREATE_WISHLISH: "CREATE_WISHLISH",
  GET_PUBLIC_WISHLISTS: "GET_PUBLIC_WISHLISTS",
  GET_PUBLIC_WISHLISTS_SUCCESS: "GET_PUBLIC_WISHLISTS_SUCCESS",
  GET_PUBLIC_WISHLISTS_FAILED: "GET_PUBLIC_WISHLISTS_FAILED",
  SET_WISHLIST_FILTER: "SET_WISHLIST_FILTER",
  GET_USER_WISHLIST: "GET_USER_WISHLIST",
  GET_USER_WISHLIST_ITEMS: "GET_USER_WISHLIST_ITEMS",
  SET_WISHLIST_LOADING: "SET_WISHLIST_LOADING",
  SET_GET_USER_WISHLIST_ITEMS_LOADING: "SET_GET_USER_WISHLIST_ITEMS_LOADING",
  SET_DELETE_USER_WISHLIST_ITEM_LOADING: "SET_DELETE_USER_WISHLIST_ITEM_LOADING",
  DELETE_WISHLIST_ITEM_SUCCESS: "DELETE_WISHLIST_ITEM_SUCCESS",
  SET_ADD_WISHLIST_ITEM_LOADING: "SET_ADD_WISHLIST_ITEM_LOADING",
  PATCH_WISHLIST: "PATCH_WISHLIST",
  GET_USER_WISHLIST_ADDED_PRODUCTS: "GET_USER_WISHLIST_ADDED_PRODUCTS",

  // for marketplace wishlist; wishlist detailed view
  GET_WISHLIST: "GET_WISHLIST",
  GET_WISHLIST_ITEMS: "GET_WISHLIST_ITEMS",
  SET_GET_WISHLIST_ITEMS_LOADING: "SET_GET_WISHLIST_ITEMS_LOADING",
};

class Actions {
  static createWishlist(marketplace, data) {
    return async dispatch => {
      try {
        let res = await Services.createWishlist(marketplace, data);
        if (res) {
          dispatch({
            type: WishlistActionTypes.CREATE_WISHLISH,
            payload: res
          });
        }
        setTimeout(() => {
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "success",
              message: "Wishlist Created"
            }
          });
        }, 100);
        return res;
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
        return false;
      }
    };
  }

  static patchWishlist(marketplace, data) {
    return async dispatch => {
      try {
        dispatch({ type: WishlistActionTypes.SET_WISHLIST_LOADING, payload: true });
        let res = await Services.patchWishlist(marketplace, data);
        if (res) {
          dispatch({
            type: WishlistActionTypes.PATCH_WISHLIST,
            payload: res
          });
        }
        setTimeout(() => {
          dispatch({
            type: BuyerDashboardActionTypes.POPUP_ALERT,
            payload: {
              open: true,
              alertType: "success",
              message: "Success"
            }
          });
        }, 100);
        setTimeout(() => {
            dispatch({
              type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
              payload: {
                open: true,
                alertType: "success",
                message: "Success"
              }
            });
          }, 100);
          dispatch({ type: WishlistActionTypes.SET_WISHLIST_LOADING, payload: false });
        return res;
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.POPUP_ALERT,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
        dispatch({ type: WishlistActionTypes.SET_WISHLIST_LOADING, payload: false });
        return false;
      }
    };
  }

  static getPublicWishlists(marketplace, params) {
    return async dispatch => {
      try {
        dispatch({ type: WishlistActionTypes.GET_PUBLIC_WISHLISTS });
        let response = await Services.getPublicWishlists(marketplace, params);
        dispatch({
          type: WishlistActionTypes.SET_WISHLIST_FILTER,
          payload: params
        });
        dispatch({
          type: WishlistActionTypes.GET_PUBLIC_WISHLISTS_SUCCESS,
          payload: response.data
        });
        return response;
      } catch (error) {
        dispatch({ type: WishlistActionTypes.GET_PUBLIC_WISHLISTS_FAILED });
      }
    };
  }

  static setWishlistFilters(params) {
    return async dispatch => {
      dispatch({
        type: WishlistActionTypes.SET_WISHLIST_FILTER,
        payload: params
      });
    };
  }

  static getUserWishlistItems(marketplace, user_id, filters={
    page: 1, itemsPerPage: 8
  }) {
    return async dispatch => {
      try {
        let response = await Services.getWishlists(marketplace, {
          "dashboard_view": true
        });
        const registry = response.data.filter(item => item.user === user_id)
        if (registry.length) {
          dispatch({
            type: WishlistActionTypes.GET_USER_WISHLIST,
            payload: registry[0]
          });
          dispatch({
            type: WishlistActionTypes.SET_GET_USER_WISHLIST_ITEMS_LOADING,
            payload: true
          });
          response = await Services.getWishlistItems(marketplace, registry[0].id, filters);
          dispatch({
            type: WishlistActionTypes.GET_USER_WISHLIST_ITEMS,
            payload: response.data,
            method: filters.method
          });
          dispatch({
            type: WishlistActionTypes.SET_GET_USER_WISHLIST_ITEMS_LOADING,
            payload: false
          });
          response = await Services.getWishlistItems(marketplace, registry[0].id, {});
          dispatch({ type: WishlistActionTypes.GET_USER_WISHLIST_ADDED_PRODUCTS, payload: response.data });
        }
      } catch (error) {
        // dispatch({ type: WishlistActionTypes.GET_PUBLIC_WISHLISTS_FAILED });
      }
    };
  }

  static deleteWishlistItem(data, from="marketplace") {
    return async dispatch => {
      try {
        dispatch({ type: WishlistActionTypes.SET_DELETE_USER_WISHLIST_ITEM_LOADING, payload: true });
        await Services.deleteWishlistItem(data);
        dispatch({
          type: WishlistActionTypes.DELETE_WISHLIST_ITEM_SUCCESS,
          payload: {
            registry_item_id: data.registry_item_id,
            product_uuid: data.product_uuid,
            from
          }
        });
        dispatch({ type: WishlistActionTypes.SET_DELETE_USER_WISHLIST_ITEM_LOADING, payload: false });
        return true
      } catch (error) {
        dispatch({ type: WishlistActionTypes.SET_DELETE_USER_WISHLIST_ITEM_LOADING, payload: false });
        // dispatch({ type: WishlistActionTypes.GET_PUBLIC_WISHLISTS_FAILED });
      }
    };
  }


  static getWishlistItems(marketplace, ref_code, filters) {
    return async dispatch => {
      try {
        let response = await Services.getWishlists(marketplace, {
            ref_code
        });
        const registry = response.data;
        if (registry.length) {
          dispatch({
            type: WishlistActionTypes.GET_WISHLIST,
            payload: registry[0]
          });
          dispatch({
            type: WishlistActionTypes.SET_GET_WISHLIST_ITEMS_LOADING,
            payload: true
          });
          response = await Services.getWishlistItems(marketplace, registry[0].id, filters);
          dispatch({
            type: WishlistActionTypes.GET_WISHLIST_ITEMS,
            payload: response.data,
            method: filters.method
          });
          dispatch({
            type: WishlistActionTypes.SET_GET_WISHLIST_ITEMS_LOADING,
            payload: false
          });
        }
      } catch (error) {
        dispatch({
          type: WishlistActionTypes.SET_GET_WISHLIST_ITEMS_LOADING,
          payload: false
        });
        // dispatch({ type: WishlistActionTypes.GET_PUBLIC_WISHLISTS_FAILED });
      }
    };
  }

  static addWishlistItem(data) {
    return async dispatch => {
      try {
        dispatch({ type: WishlistActionTypes.SET_ADD_WISHLIST_ITEM_LOADING, payload: true });
        await Services.addWishlistItem(data);
        dispatch({ type: WishlistActionTypes.SET_ADD_WISHLIST_ITEM_LOADING, payload: false });
        setTimeout(() => {
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "success",
              message: "Item was added to your wishlist!"
            }
          });
        }, 100);
        const response = await Services.getWishlistItems(data.marketplace, data.registry_id, {});
        dispatch({ type: WishlistActionTypes.GET_USER_WISHLIST_ADDED_PRODUCTS, payload: response.data });
        return true
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
        dispatch({ type: WishlistActionTypes.SET_ADD_WISHLIST_ITEM_LOADING, payload: false });
      }
    };
  }
}

export default Actions;


export const ReduxInitializeFormActionTypes = {
  LOAD_WISHLIST_DATA: "LOAD_WISHLIST_DATA",
};
export class ReduxInitializeFormActions {
  static initializeEditWishlist(data) {
    return {
      type: ReduxInitializeFormActionTypes.LOAD_WISHLIST_DATA,
      payload: data
    };
  }
}
  