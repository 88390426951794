import React from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import SwipeableViews from "react-swipeable-views";
import {
  Button,
  Tabs,
  AppBar,
  Tab,
  Box,
  Typography,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { reduxForm } from "redux-form";
import BrandingPane from "./panes/branding";
import UploadImagesPane from "./panes/upload_images";
import { ReduxInitializeFormActions } from "../../../websiteBuilderActions";
import setDefaultImage from "../../../../common/layout/mobile/webBuilder/common/setDefaultImage";
import { patchStore } from "../../../websiteBuilderActions";
import { patchStore as mobilePatchStore } from "../../../../common/layout/mobile/webBuilder/websiteBuilderActions";
import SaveSuccessModal from "./saveSuccessModal";
import { showAlert } from "../../../../common/commonActions";

function a11yProps(index, is_mobile) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: {
      minWidth: is_mobile ? "33%" : 100
    }
  };
}

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const CustomizeShopForm = props => {
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles(theme => ({
    content: {
      marginTop: "2em",
      left: "32vw",
      right: "10vw",
      border: "1px solid #D4D5D8",
      borderRadius: 4,
      overflow: "visible",
      backgroundColor: "white",
      marginBottom: "3em",
      [theme.breakpoints.down("sm")]: {
        marginTop: "3em",
        border: "none"
      }
    },
    appbar: {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 4
    },
    tabs: {
      position: "relative",
      backgroundColor: "#ffffff00",
      boxShadow: "none",
      borderBottom: `${is_mobile ? 0 : "1px"} solid #d1d5dd`,
      padding: "0 2em",
      marginTop: 0,
      width: "auto",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        position: "fixed",
        top: 54,
        width: "100%",
        backgroundColor: "white"
      }
    },
    submitButton: {
      position: "block",
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0
      }
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();

  // TAB
  const [tab, setTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setTab(newValue);
  };

  // initialize data
  // React.useEffect(() => {
  //   if (props.initialData) {
  //     const initialData = {
  //       logo_image: [props.initialData.logo_image ?? ""],
  //       color: props.initialData.color,
  //       primary_background_color: props.initialData.color.primary.main,
  //       primary_text_color: props.initialData.color.primary.contrastText,
  //       secondary_background_color: props.initialData.color.secondary.main,
  //       secondary_text_color: props.initialData.color.secondary.contrastText,
  //       font: {
  //         family: 'Basic',
  //         variants: [
  //           'regular'
  //         ],
  //         subsets: [
  //           'latin',
  //           'latin-ext'
  //         ],
  //         version: 'v10',
  //         lastModified: '2020-09-02',
  //         files: {
  //           regular: 'http://fonts.gstatic.com/s/basic/v10/xfu_0WLxV2_XKQN34lDVyR7D.ttf'
  //         },
  //         category: 'sans-serif',
  //         kind: 'webfonts#webfont',
  //         css_url: 'https://fonts.googleapis.com/css?family=Basic:wght@600'
  //       },
  //       cover_image: {
  //         cropped_image: props.initialData.cover_image ?? setDefaultImage(props.initialData.template, "cover_image"),
  //         raw_image: props.initialData.cover_original_image ?? setDefaultImage(props.initialData.template, "cover_image")
  //       },
  //       aboutus_image: {
  //         cropped_image: props.initialData.aboutus_image ?? setDefaultImage(props.initialData.template, "aboutus_image"),
  //         raw_image: props.initialData.aboutus_original_image ?? setDefaultImage(props.initialData.template, "aboutus_image")
  //       },
  //       contactus_image: {
  //         cropped_image: props.initialData.contactus_image ?? setDefaultImage(props.initialData.template, "contactus_image"),
  //         raw_image: props.initialData.contactus_original_image ?? setDefaultImage(props.initialData.template, "contactus_image")
  //       },
  //     }
  //     dispatch(props.initializeData(initialData))
  //   }
  // }, [props.initialData])

  const store = useSelector(
    state => state.webBuilder.storeInfo || state.mobileDashboard.storeInfo
  );
  const data = useSelector(state => state.form.customizeShopForm?.values);
  const loading = useSelector(
    state => state.webBuilder.loading || state.mobileDashboard.loading
  );
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(true);
  const submit = async () => {
    let payload = {
      logo_image: data.logo_image?.length ? data?.logo_image[0] : "",
      cover_image: data.cover_image?.cropped_image,
      cover_original_image: data.cover_image?.raw_image,
      aboutus_image: data.aboutus_image?.cropped_image,
      aboutus_original_image: data.aboutus_image?.raw_image,
      contactus_image: data.contactus_image?.cropped_image,
      contactus_original_image: data.contactus_image?.raw_image
    };
    let json_payloads = {
      color: {
        primary: {
          ...data.color.primary,
          main: data.primary_background_color,
          contrastText: data.primary_text_color
        },
        secondary: {
          ...data.color.secondary,
          main: data.secondary_background_color,
          contrastText: data.secondary_text_color
        }
      },
      font: data.font
    };
    const res = await dispatch(
      props.isMobile
        ? mobilePatchStore(store ? store.site : "", payload, json_payloads)
        : patchStore("styles", store ? store.site : "", payload, json_payloads)
    );

    try {
      if (res.status === 200) {
        window.scrollTo(0, 0);
        props.setOpen({
          ...props.open,
          customize: false,
          success_dialog: true,
          is_success: true
        });
      }
    } catch (e) {
      window.scrollTo(0, 0);
      props.setOpen({ ...props.open, success_dialog: true, is_success: false });
    }
    // setOpen(true);
    // setSuccess(res);
  };

  return (
    <div className={classes.content}>
      {/* <SaveSuccessModal
        open={open}
        setOpen={setOpen}
        success={success}
        store={store}
      /> */}
      <AppBar className={classes.appbar} position="static" color="default">
        <Tabs
          classes={{ root: classes.tabs }}
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
        >
          <Tab
            label="Branding"
            {...a11yProps(0, is_mobile)}
            id="branding-tab"
          />
          <Tab
            label="Upload images"
            {...a11yProps(1, is_mobile)}
            id="upload-tab"
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tab}
        enableMouseEvents={is_mobile}
        onChangeIndex={index => handleTabChange("event", index)}
        animateHeight
      >
        <TabPanel>
          <div id="branding-pane">
            <BrandingPane />
          </div>
        </TabPanel>
        <TabPanel>
          <div id="upload-pane">
            <UploadImagesPane store={props.storeInfo} />
          </div>
        </TabPanel>
      </SwipeableViews>
      <div className={`px-3 px-md-5 py-4 py-md-5 ${classes.submitButton}`}>
        {props.isMobile ? (
          <Button
            color="primary"
            fullWidth
            variant="outlined"
            id="preview_btn"
            className="my-2"
            onClick={() => props.setOpen({ ...props.open, preview: true })}
          >
            Preview
          </Button>
        ) : (
          ""
        )}

        <Button
          color="primary"
          fullWidth
          variant="contained"
          id="submit_btn"
          disableElevation
          onClick={submit}
        >
          {loading ? (
            <div className="d-flex my-5 mr-3">
              <div
                className="spinner-border mx-auto"
                role="status"
                style={{
                  color: "white",
                  fontSize: 10,
                  height: 20,
                  width: 20
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}
          Save changes
        </Button>
      </div>
    </div>
  );
};

let Form = reduxForm({
  form: "customizeShopForm",
  enableReinitialize: true,
  // keepDirtyOnReinitialize: true,
  destroyOnUnmount: false
})(CustomizeShopForm);

Form = connect(
  state => ({
    initialValues: state.webBuilder.reduxForm.customizeShopForm
  }),
  {
    initializeData: ReduxInitializeFormActions.initializeCustomizeShopData
  }
)(Form);

export default Form;
