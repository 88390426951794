import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  Zoom,
  IconButton,
  useMediaQuery,
  Slide,
  AppBar,
  Toolbar
} from "@material-ui/core";
import { ArrowBack, Close, Error } from "@material-ui/icons";
import history from "../../../../store/history";
import { reduxForm, reset, change, untouch, destroy } from "redux-form";
import Form from "./form";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  ReduxInitializeFormActions,
  getBuxStatus,
  addPaymentMethod,
  editPaymentMethod,
  removePayment
} from "../../action";
import Path from "../../../../Paths";
import { showAlert, hideAlert } from "../../../common/commonActions";
import {
  paymayaImg,
  payPaylImg
} from "../../../websiteBuilder/forms/bankContants";
import DashboardActions from "../../../dashboard/dashboardActions";
import { useLocation } from "react-router-dom";
const ZoomTransition = props => <Zoom direction="up" {...props} />;
const Transition = props => <Slide direction="up" {...props} />;
function AccountForm(props) {
  const useStyles = makeStyles(theme => ({
    icon: {
      width: "100%",
      textAlign: "center",
      color: "#ee2545",
      fontSize: 100
    },
    text: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 11
      }
    }
  }));
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  let defaultImg =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAUvSURBVHgB7d3Pi9R1HMfxTxE7F9eDsx3KQymYG4QHacFYURoIBA+rgWuQ3brUX6MXu3SrS0cDKQg2DJaCBQ/moQiSDq6X3YvrZfdi+xEGiuzFfHW+88N5PEA8ON9hGebpfHY/n31/X7p67frjAjzVywX4XwKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUyYTmeuMDkEMkGWlk6WKx9/VLrdbmEyCGRC1DiW3j355BPk8urFsrj4VmH8BDIB+nH8U+/9M+XEiXcK4yWQMTu9fOo/cfzr35ZOFsbnlcLY9HpnyuLxvJTqx7OxcbswegIZg/p9xrlzH5TDr7820ONFMj4CGbEax4WV841/UiWS8fA9yAjNzx94pjj6aiS93tnC6PgEGZF+HPPz8+V5LB4/9uTvtbVbhfb5BBmBYcXRVyNZ3d8rsevePoG0bNhx9C3sL9NWnjzvgUJ7BNKibvdQWb10cehx9NVILoikVQJpSY2jvnk7nU5pU41PJO0RSAvqOapRxNEnkvYIZMhqHPUc1aji6BNJOwQyRPVwYY1jXGokq5c+dFx+iOyDDMnTTuQmOzs7pYm5uc5AP9bt79R/9/0PZXPzQeH5CGRI+ht4g1pb+6ncb/AGrvENerK3RvL2/lJPIM/PEgsCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAwlUdN6i8KLZ8+VSZJ01+KWl4+VXb39gZ+/EGndMdiKgOpZ40GnSk1qRYWnLidBpZYEAgEAoFAIBAIBAKBQCAQCAQCgUAgEMzMVJP795tN+Dh8uN2d+q2t7bK72+CoycF5Q+HGYCYC2dl5VG58e7PRNZ9/9mlp0/r6L62N/WF4LLEgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEL129dv1xmTJ1osnKyvlG1zS95Vlb9zbv293dLXsNxv7Mzc01ujFo0+cfha++/qZMm5k5rNj2G76p+mZv8064bT//rLDEgkAgEAgEAoFAIBAIBAKBQCAQCAQCgWAmdtLreJ179/5qdM3i4rHSpj/3v569BmN/Fl7tloXuodKWOvmlyWikevTl6NE3yotuJgKpZ5LWfrzV6Jq2A/n1zt3GY3/aDOThw51Gr1Gd0TULgVhiQSAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCGZm7A/jd/2LL8u08QkCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgmJmxP+vrPze6ptc7W9q0sXG70V2vjhx5c/9Pe1NEtra2y507dwd+fKczV5aX3ysvupkZ+/Pb7380uqbtQDY3HzQa+1PvkNVmIPU/kSavUR37MwuBWGJBIBAIBAKBQCAQCAQCgUAgEAgEAoFAMBM76VXdiZ4k9euZn3808OM7nU5pUz060uQ1Ori/kz4LZiKQeizikyuXyyTp9c6USbKw0J2412gSWGJBIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIpvKoydb2drlx42aBtk1lIHVETZOROfCsLLEgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBAIBAKBQCAQCAQCgUAgEAgEAoFAIBD8DcdF1Hbi/9DMAAAAAElFTkSuQmCC";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const loading = useSelector(state => state.payments.loading);
  const alert = useSelector(state => state.common.showAlert);
  const stores = useSelector(state => state.payments.stores);
  const initial = useSelector(state => state.form.account_form?.initial);
  const values = useSelector(state => state.form.account_form?.values);
  const paymentMethods = useSelector(state => state.payments.paymentMethods);
  const errors = useSelector(
    state => state.form.account_form?.syncErrors || false
  );
  const payment_uuid = props.match.params.payment_uuid;
  const STORE_UUID = localStorage.getItem("STORE_UUID");
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const paymentMethod = () => {
    switch (values?.account_type) {
      case "0":
        return "Bank";
      case "1":
        return "PayMaya";
      case "2":
        return "PayPal";
      default:
        return "";
    }
  };

  function dataURLtoFile(dataurl, filename, ext) {
    console.log(dataurl);
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    console.log(new File([u8arr], filename, { type: mime }));
    return new File([u8arr], `${filename}.${ext}`, { type: mime });
  }

  function renderPaymentIcon(payment_method, qr_code_img) {
    if (payment_method === "0") {
      if (!qr_code_img) {
        return dataURLtoFile(defaultImg, "bank", "png");
      } else {
        return qr_code_img;
      }
    } else if (payment_method === "1") {
      if (!qr_code_img) {
        return dataURLtoFile(paymayaImg, "paymaya", "png");
      } else {
        return qr_code_img;
      }
    } else if (payment_method === "2") {
      return dataURLtoFile(payPaylImg, "paypal", "jpg");
    }
  }

  const onSubmit = async () => {
    let stores = await dispatch(DashboardActions.getStores());
    let params = {
      ...values,
      user: stores ? (stores[0] ? stores[0].owner : "") : "",
      store: STORE_UUID,
      payment_method: paymentMethod(),
      qr_code_img: renderPaymentIcon(
        values?.account_type,
        typeof values?.qr_code === "object" ? values?.qr_code[0] : ""
      )
    };
    let res;
    if (location.pathname.includes("add")) {
      res = await dispatch(addPaymentMethod(params));
    } else {
      res = await dispatch(editPaymentMethod(params));
    }

    try {
      if (res.status === 201 || res.status === 200) {
        dispatch(
          showAlert({
            type: "success",
            message: `Your account is successfully ${
              location.pathname.includes("add") ? "added" : "updated"
            }!`
          })
        );
        history.push(Path.PAYMENTS);
      }
    } catch {
      dispatch(
        showAlert({
          type: "error",
          message: "An error occured. Please try again"
        })
      );
    }
  };

  const handleDelete = async () => {
    const params = {
      uuid: values?.uuid,
      store: STORE_UUID
    };
    let res = await dispatch(removePayment(params));
    try {
      if (res.status === 204) {
        dispatch(
          showAlert({
            type: "success",
            message: "You have successfully deleted an account!"
          })
        );
        history.push(Path.PAYMENTS);
      }
    } catch {
      dispatch(
        showAlert({
          type: "error",
          message: "Oops, something went wrong. Please try again."
        })
      );
    }
  };

  const disableBtn = () => {
    if (errors && values?.account_type === "0") {
      if (errors?.bank_name || errors?.account_name || errors?.account_number)
        return true;
      else return false;
    } else if (errors && values?.account_type === "1") {
      if (errors?.account_name || errors?.mobile_number) return true;
      else return false;
    } else if (errors && values?.account_type === "2") {
      if (errors?.payment_link) return true;
      else return false;
    }
  };
  // INITIALIZE FORM
  React.useEffect(() => {
    dispatch(getBuxStatus(user.id));
    if (location.pathname.includes("add")) {
      dispatch(
        props.initializeData({
          account_type: "0"
        })
      );
    } else {
      if (!initial) {
        history.push(Path.PAYMENTS);
      } else {
        dispatch(
          props.initializeData({
            ...initial
          })
        );
      }
    }
  }, []);

  React.useEffect(() => {
    let params = [
      { field: "bank_name", value: initial?.bank_name },
      { field: "account_name", value: initial?.account_name },
      { field: "account_number", value: initial?.account_number },
      { field: "mobile_number", value: initial?.mobile_number },
      { field: "payment_link", value: initial?.payment_link },
      { field: "qr_code", value: initial?.qr_code }
    ];
    if (location.pathname.includes("add") && values?.account_type) {
      for (let i in params) {
        dispatch(change("account_form", params[i].field, ""));
        dispatch(untouch("account_form", params[i].field));
      }
    } else {
      for (let i in params) {
        dispatch(change("account_form", params[i].field, params[i].value));
        dispatch(untouch("account_form", params[i].field));
      }
    }
  }, [values?.account_type]);

  React.useEffect(() => {
    if (location.pathname.includes("add")) {
      if (paymentMethods && paymentMethods.length >= 5) {
        history.push(Path.PAYMENTS);
      }
    }
  }, [paymentMethods]);

  const DeleteDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openDelete}
        TransitionComponent={ZoomTransition}
      >
        <div className="d-flex justify-content-end">
          <IconButton onClick={() => setOpenDelete(false)}>
            <Close color="secondary" />
          </IconButton>
        </div>
        <div className="px-md-4 px-3 pb-md-4 pb-3">
          <Error className={`${classes.icon} mb-3`} />
          <div
            style={{ fontSize: 20, color: "#002c42", fontWeight: 600 }}
            className="text-center px-5"
          >
            Are you sure you want to delete the record?
          </div>
          <div className="d-flex mt-4">
            <Button
              color="primary"
              variant="outlined"
              className={`${classes.text} w-50 mr-2`}
              onClick={() => setOpenDelete(false)}
            >
              No, take me back
            </Button>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              className={`${classes.text} w-50`}
              onClick={() => {
                handleDelete();
                setOpenDelete(false);
              }}
            >
              Yes, delete the record
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      {isMobile ? (
        <div>
          <Dialog open={isMobile} fullScreen TransitionComponent={Transition}>
            <AppBar
              className="position-relative bg-white border-bottom"
              style={{ boxShadow: "none" }}
            >
              <Toolbar>
                <div
                  className="text-center w-100"
                  style={{ fontSize: 16, fontWeight: 600, color: "#002c42" }}
                >
                  {location.pathname.includes("add")
                    ? "Add Account"
                    : "Edit Account"}
                </div>
                <IconButton
                  edge="end"
                  color="inherit"
                  className="position-absolute"
                  style={{ right: 16 }}
                  onClick={() => history.push(Path.PAYMENTS)}
                >
                  <Close color="primary" />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className="p-3 position-relative">
              <Form edit={edit} setEdit={setEdit} />
              {edit || location.pathname.includes("add") ? (
                <div
                  className="position-fixed p-3"
                  style={{ bottom: 0, right: 0, left: 0 }}
                >
                  <Button
                    fullWidth
                    disableElevation
                    color="primary"
                    variant="contained"
                    disabled={disableBtn()}
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <div className="d-flex my-5 mr-3">
                        <div
                          className="spinner-border mx-auto"
                          role="status"
                          style={{
                            color: "white",
                            fontSize: 12,
                            height: 25,
                            width: 25
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {location.pathname.includes("add") ? "Submit" : "Save"}
                  </Button>
                </div>
              ) : null}
              {!edit && !location.pathname.includes("add") ? (
                <div
                  className="d-flex position-fixed justify-content-between p-3"
                  style={{ bottom: 0, right: 0, left: 0 }}
                >
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => setOpenDelete(true)}
                  >
                    Delete
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setEdit(true)}
                  >
                    Edit
                  </Button>
                </div>
              ) : null}
            </div>
          </Dialog>
        </div>
      ) : (
        <div style={{ padding: "0 10vw" }}>
          <div
            className={`d-flex position-relative align-items-center justify-content-between`}
          >
            <Button
              color="primary"
              variant="text"
              className="position-absolute"
              startIcon={<ArrowBack />}
              onClick={() => {
                history.push(Path.PAYMENTS);
                dispatch(destroy("account_form"));
              }}
            >
              Back
            </Button>
            <div
              style={{ fontSize: 28, fontWeight: 600, color: "#002c42" }}
              className={`text-center w-100`}
            >
              {location.pathname.includes("add")
                ? "Add Account"
                : "Edit Account"}
            </div>
            {!location.pathname.includes("add") ? (
              <Button
                variant="text"
                color="secondary"
                onClick={() => setOpenDelete(true)}
              >
                Delete
              </Button>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ border: "1px solid #d1d5dd", borderRadius: 4 }}
            className="bg-white p-4 mt-3"
          >
            <Form />
            <div className="pt-4">
              <Button
                disableElevation
                variant="contained"
                color="primary"
                fullWidth
                disabled={disableBtn()}
                onClick={onSubmit}
              >
                {loading ? (
                  <div className="d-flex my-5 mr-3">
                    <div
                      className="spinner-border mx-auto"
                      role="status"
                      style={{
                        color: "white",
                        fontSize: 12,
                        height: 25,
                        width: 25
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {location.pathname.includes("add") ? "Submit" : "Save"}
              </Button>
            </div>
          </div>
        </div>
      )}
      <DeleteDialog open={openDelete} setOpen={setOpenDelete} />
    </>
  );
}

let ReduxForm = reduxForm({
  form: "account_form",
  destroyOnUnmount: false
})(AccountForm);

ReduxForm = connect(
  state => ({
    initialValues: state.payments.reduxForm.accountDetailsForm
  }),
  {
    initializeData: ReduxInitializeFormActions.initializeAccountFormData
  }
)(ReduxForm);

export default ReduxForm;
