import Services from "./services";

export const ActionTypes = {
  SET_VISIBILITY: "SET_VISIBILITY",
  SEND_SMS_OTP: "SEND_SMS_OTP",
  SEND_SMS_OTP_SUCCESS: "SEND_SMS_OTP_SUCCESS",
  VERIFY_SMS_OTP: "VERIFY_SMS_OTP",
  VERIFY_SMS_OTP_SUCCESS: "VERIFY_SMS_OTP_SUCCESS",
  VERIFY_FAILED: "VERIFY_FAILED",
  REQUEST_FAILED: "REQUEST_FAILED",
  SET_ERROR: "SET_ERROR"
};

class Actions {

  static SEND_SMS_OTP(mobile) {
    return async dispatch => {
      try {
        dispatch({
          type: ActionTypes.SEND_SMS_OTP,
          payload: { mobile }
        });
        const res = await Services.SEND_SMS_OTP({ mobile })
        dispatch({
          type: ActionTypes.SEND_SMS_OTP_SUCCESS,
          payload: res.data
        });
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }

  static VERIFY_SMS_OTP(payload, callback) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.VERIFY_SMS_OTP });
        const res = await Services.VERIFY_SMS_OTP(payload)
        if (res.data.status !== "VERIFIED") {
          dispatch({
            type: ActionTypes.SET_ERROR,
            payload: res.data.status
          });
        } else {
          dispatch({ type: ActionTypes.VERIFY_SMS_OTP_SUCCESS });
          if (callback) callback();
        }
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }

  static SET_VISIBILITY(payload) {
    return async dispatch => {
      dispatch({
        type: ActionTypes.SET_VISIBILITY,
        payload
      });
    };
  }

  static RESET_ERROR() {
    return async dispatch => {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: ""
      });
    };
  }
}

export default Actions;
