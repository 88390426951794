import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button
} from "@material-ui/core";
import SentroLoading from "../../../../loading";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import Utils from "../../../../utils";
import { bankListing } from "../../../../../websiteBuilder/forms/bankContants";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  orderUpdatePayment,
  orderUpdateDelivery
} from "../websiteBuilderActions";
const DialogTransition = props => <Slide direction="up" {...props} />;
const MobilePickupDelivery = props => {
  const dispatch = useDispatch();
  const { open, setOpen } = props;
  const useStyles = makeStyles(theme => ({
    appBar: {
      position: "fixed",
      backgroundColor: "#fafafa",
      boxShadow: "none",
      borderBottom: "1px solid #d1d5dd"
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    closeBtn: { position: "absolute", left: 15 },
    refId: {
      fontSize: 14,
      fontWeight: 600,
      color: "#002c42"
    }
  }));
  const classes = useStyles();
  const loading = useSelector(state => state.mobileDashboard.loading);
  const selectedOrder = useSelector(
    state => state.mobileDashboard.selectedOrder
  );

  const [delivery, setDelivery] = React.useState({
    id: "",
    delivery_method: "",
    delivery_datetime: new Date()
  });
  function updateDelivery() {
    let params = {
      id: selectedOrder.id,
      delivery_method: delivery.delivery_method,
      is_delivered: true,
      delivery_datetime: moment(delivery.delivery_datetime).format()
    };

    dispatch(orderUpdateDelivery(params));
    setOpen(false);
  }

  React.useEffect(() => {
    setDelivery({
      id: selectedOrder ? selectedOrder.id : "",
      delivery_method: selectedOrder
        ? selectedOrder.delivery_method
        : "Own Delivery",
      delivery_datetime: new Date()
    });
  }, [open]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
        <SentroLoading open={loading} />
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.closeBtn}
              onClick={() => setOpen(false)}
            >
              <CloseIcon color="primary" />
            </IconButton>
            <div className={classes.header}>For Buyer pickup/ own delivery</div>
          </Toolbar>
        </AppBar>
        <div
          style={{ paddingTop: "8vh", position: "relative", height: "100vh" }}
        >
          <div style={{ padding: 20 }}>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={classes.refId}>
                  Reference No. {selectedOrder ? selectedOrder.ref_id : ""}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    color: "#002c42",
                    fontWeight: "bold",
                    textTransform: "capitalize"
                  }}
                >
                  {selectedOrder ? selectedOrder.full_name : ""}
                </div>
              </div>
              <div
                style={{ fontSize: 14, color: "#0d3d76", fontWeight: "bold" }}
              >
                PHP {Utils.commafy(selectedOrder ? selectedOrder.amount : 0)}
              </div>
            </div>
            <div style={{ paddingTop: "5vh" }}>
              <div style={{ fontSize: 14, color: "#2b2d33", fontWeight: 600 }}>
                What was the delivery method used?
              </div>
              <RadioGroup
                onChange={e =>
                  setDelivery({
                    ...delivery,
                    delivery_method: e.target.value
                  })
                }
                defaultValue={
                  selectedOrder ? selectedOrder.delivery_method : "Own Delivery"
                }
                color="primary"
              >
                <FormControlLabel
                  color="secondary"
                  value="Buyer pickup"
                  control={<Radio color="primary" />}
                  label="Buyer pickup"
                />

                <FormControlLabel
                  color="secondary"
                  value="Own delivery"
                  control={<Radio color="primary" />}
                  label="Own delivery"
                />
              </RadioGroup>
              <div style={{ marginTop: "2vh" }}>
                <div
                  style={{ fontSize: 14, color: "#2b2d33", fontWeight: 600 }}
                >
                  When was the the item pickep up/delivered?
                </div>
                <KeyboardDateTimePicker
                  margin="dense"
                  inputVariant="outlined"
                  value={delivery.delivery_datetime}
                  onChange={e =>
                    setDelivery({ ...delivery, delivery_datetime: e })
                  }
                  style={{ margin: 0, padding: 0 }}
                  disablePast
                  ampm={false}
                  format="dd MMMM yyyy hh:mm"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              padding: 20,
              bottom: 0
            }}
          >
            <Button
              fullWidth
              disableElevation
              variant="contained"
              color="primary"
              style={{ height: 40 }}
              onClick={updateDelivery}
            >
              Submit
            </Button>
          </div>
        </div>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

export default MobilePickupDelivery;
