import React from "react";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress
} from "react-google-places-autocomplete";

// If you want to use the provided css
import "react-google-places-autocomplete/dist/index.min.css";
import scriptLoader from "react-async-script-loader";
import { TextField } from "@material-ui/core";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import apiConfig from "../../common/apiConfig";
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
const GoogleDeliveryField = props => {
  function getLongLat(address) {
    geocodeByAddress(address.description)
      .then(results => getLatLng(results[0]))
      .then(value => {
        props.onChange({
          address: address.description,
          lng: value.lng,
          lat: value.lat
        });
      });
  }

  return (
      <GooglePlacesAutocomplete
        autocompletionRequest={{
          location: [{ lat: 14.599512, lng: 120.984222 }],
          bounds: [{ lat: 14.599512, lng: 120.984222 }],
          country: ["ph"]
        }}
        disabled={props.disabled}
        renderInput={props => (
          <div className="custom-wrapper">
            <TextField
              InputProps={{
                startAdornment: (
                  <RoomOutlinedIcon
                    style={{ marginRight: 10, color: "#54575f" }}
                  />
                )
              }}
              size="small"
              color="secondary"
              {...props}
              fullWidth
              variant="outlined"
              disabled={props.disabled}
            />
            {/* <input
              // Custom properties
       
            /> */}
          </div>
        )}
        renderSuggestions={(active, suggestions, onSelectSuggestion) => (
          <div className="suggestions-container">
            {suggestions.map(suggestion => (
              <div
                style={{ padding: 20, margin: 10 }}
                className="suggestion"
                onClick={event => onSelectSuggestion(suggestion, event)}
              >
                {suggestion.description}
              </div>
            ))}
          </div>
        )}
        initialValue={props.initialValue}
        // renderInput={(props) => <SentroTextField />}
        onSelect={value => getLongLat(value)}
      />
  );
};

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=AIzaSyCL77F7zUuqVxO4k4kffBzux3QaMpm5pxA&libraries=places`
])(GoogleDeliveryField);
