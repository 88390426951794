import React from "react";
import { Paper, makeStyles } from "@material-ui/core";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";
import { destroy } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import builder from "../../../../../assets/images/builder2.png";
import SentroButton from "../../../sentroButton";
import MobileCreateShop from "./forms/MobileCreateShop";

require("dotenv").config();

const CreateMobileWebCard = props => {
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 2em"
      },
      [theme.breakpoints.down("xs")]: {
        padding: 20
      }
    },
    cardPadding: {
      [theme.breakpoints.down("sm")]: {
        padding: "0 2em"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0 20px 20px"
      }
    },
    paper: {
      [theme.breakpoints.down("sm")]: {
        padding: "4em",
        borderRadius: 10
      },
      [theme.breakpoints.down("xs")]: {
        padding: "40px 20px 20px",
        borderRadius: 10
      }
    },
    img: {
      [theme.breakpoints.down("sm")]: {
        height: 250,
        width: "auto"
      },
      [theme.breakpoints.down("xs")]: {
        height: 125,
        width: "auto"
      }
    },
    label: {
      marginTop: "2vh",
      color: "#2b2d33",
      justifyContent: "center",
      textAlign: "center",
      display: "flex",

      [theme.breakpoints.down("sm")]: {
        fontSize: 18
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  }));
  const { data } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  function onClose() {
    setOpen(false);
  }

  function CreateNew() {
    dispatch(destroy("storeInfo"));
    history.push(Path.MOBILE_CREATE_SHOP);
  }
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const openModal = useSelector(state => state.dashboard.dialogOpen);

  return (
    <div>
      <div className={classes.header}>My Shop</div>
      <div className={classes.cardPadding}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img alt="" src={builder} className={classes.img} />
          </div>
          <div style={{ justifyContent: "center" }}>
            <div className={classes.label}>
              {props.language === "Filipino"
                ? "Magkaroon ng sariling online shop sa ilang clicks lang! Sagutin lamang ang ilang mga katanungan at kami na ang bahala sa iba."
                : "Having your own online shop is just a few clicks away! Just answer some questions and we'll do the rest for you."}
            </div>
            <div style={{ marginTop: "2vh" }}>
              <SentroButton
                id="createShop_button"
                disabled={
                  user ? (user.is_verified == true ? false : true) : true
                }
                style={{ backgroundColor: "#F97663", height: 43 }}
                variant="contained"
                disableElevation
                fullWidth
                onClick={() => setOpen(true)}
                label={
                  props.language === "Filipino"
                    ? "Gumawa ng online shop"
                    : "Create my online shop"
                }
              />
              {error && (
                <div
                  className="text-center mt-2"
                  style={{ color: "red", fontSize: 12 }}
                >
                  Activate your Sentro account to enjoy more features.
                </div>
              )}
            </div>
          </div>
        </Paper>
        <MobileCreateShop
          open={open}
          setOpen={setOpen}
          onClose={onClose}
          values={props.values}
          setValues={props.setValues}
          language={props.language}
        />
        {/* <CreateShopSuccess open={openModal} /> */}
      </div>
    </div>
  );
};

export default CreateMobileWebCard;
