import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import moment from 'moment'
import getFlag from '../../utils'

const useStyles = theme => ({
  title: {
    fontSize: 16,
    color: "#2B2D33",
    marginBottom: 5
  },
  value: {
    color: "#2B2D33",
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 0
  },
  avatar: {
    height: 25,
    width: 25,
    borderRadius: '50%',
    backgroundSize: "cover",
    backgroundPosition: "left"
  },
  status: {
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    paddingTop: "1em",
    marginLeft: "auto",
    "& .order_sent": {
      padding: "5px 10px",
      borderRadius: 100,
      background: "#E6EAF0",
      color: "#2B2D33"
    },
    "& .for_confirmation": {
      padding: "5px 10px",
      borderRadius: 100,
      background: "#F5922F",
      color: "white"
    },
    "& .pending_payment": {
      color: "white",
      background: "#1E4B83",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    },
    "& .cancelled": {
      color: "white",
      background: "#D1D5DD",
      padding: "5px 10px",
      borderRadius: 100,
      fontSize: 14,
      textAlign: "center"
    }
  }
});

class OrderDetailCard extends React.Component {
  state = {
    flag: ""
  }

  componentDidMount = () => {
    const flag = getFlag(this.props.data.supplier_address.country)
    if (flag) {
      this.setState({
        flag: flag.file_url
      })
    }
  }

  render (){
    const { classes, data } = this.props;
    return (
      <Paper elevation={3}>
        <div className="p-4">
          <div className="d-sm-block d-md-none">
            <div className={classes.status}>
              <p className={data.status.toLowerCase().replace(" ", "_")}>{data.status}</p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <p className={classes.title}>Purchase Request No.:</p>
              <h4 className={classes.value}>{data.order_id}</h4>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <p className={classes.title}>Date Created:</p>
              <h4 className={classes.value}>{moment(data.date_created).format("DD MMM YYYY")}</h4>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 d-sm-none d-md-block">
              <div className="d-flex">
                <div className={classes.status}>
                  <p className={data.status.toLowerCase().replace(" ", "_")}>{data.status}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <p className={classes.title}>Store Name:</p>
              <div className="d-flex align-items-center">
                <h4 className={classes.value}>{data.supplier_address.company_name}</h4>
                <div className={`${classes.avatar} ml-2`} style={{
                  backgroundImage: `url(${this.state.flag})`,
                }} />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <p className={classes.title}>Email:</p>
              <h4 className={classes.value}>{data.billing_address.email}</h4>
            </div>
          </div>
        </div>
      </Paper>
    )
  }
}

export default withStyles(useStyles)(OrderDetailCard);

