import { ActionTypes } from "./actions";
const initialState = {
  loading: false,
  data: "",
  cashLimit: null,
  error: ""
};

export default function salesAndOrdersReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case ActionTypes.GET_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ActionTypes.GET_LIMIT:
      return {
        ...state,
        loading: false,
        cashLimit: action.payload
      };
    default:
      return initialState;
  }
}
