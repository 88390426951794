import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Checkbox,
  Button,
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
  TableBody,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StorefrontIcon from "@material-ui/icons/Storefront";
import sampleImg from "../../../../assets/images/imgPlaceholder2.svg";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { WishlistItemPriceDisplay } from "../../../common/template/components/PriceDisplay";
import Utils from "../../../common/utils";
import history from "../../../../store/history";
import BuyerActions from "../redux/actions";
import apiConfig from "../../../../common/apiConfig";
import StoreActions from "../../../common/template/components/StoreActions";
import Path from "../../../../Paths";
const DialogTransition = props => <Slide direction="up" {...props} />;

const PurchasedViewOrderModal = props => {
  const enterprise = useSelector(state => state.enterprise.main.enterprise);
  const purchased_tab = useSelector(
    state => state.buyerDashboard.purchased_tab
  );
  const selected_order = useSelector(
    state => state.buyerDashboard.selected_purchased_order
  );
  const items = selected_order ? JSON.parse(selected_order.cart) : "";
  const voucher = selected_order.voucher
    ? JSON.parse(selected_order.voucher)
    : "";
  const useStyles = makeStyles(theme => ({
    appbar: {
      position: "fixed",
      backgroundColor: "#fff",
      boxShadow: "none",
      borderBottom: "1px solid #d1d5dd"
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    cartHeaderSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    labelTitle: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149"
    },
    productImage: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      [theme.breakpoints.down("sm")]: {
        width: 90,
        height: 90
      },
      [theme.breakpoints.down("xs")]: {
        width: 40,
        height: 40
      }
    },
    labelbold: {
      fontSize: 14,
      fontWeight: 600,
      color: "#2b2d32"
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      color: "#2b2d32"
    },
    btnQuantity: {
      borderRadius: 0,
      width: 25,
      height: 25,
      minWidth: 25
    },
    summary: {
      padding: 18,
      borderRadius: "8px 8px 0px 0px",
      bottom: 0,
      left: 0,
      right: 0,
      borderTop: "1px solid #d1d5dd"
    },
    voucherLink: {
      color: "#54575f",
      fontSize: 14,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: 500
    },
    columnHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "0 0 2vh"
    },
    column: {
      borderBottom: "none",
      fontSize: 12,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0"
    },
    columnFooter: {
      borderTop: "1px dashed #d1d5dd",
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0 0"
    },
    variantChip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    },
    content: {
      overflowY: "scroll"
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [productSelected, setProductSelected] = React.useState([]);

  const all_cart_products = [];

  function productImage(item) {
    if (item.gallery) {
      if (item.gallery.length) {
        return item.gallery[0].image;
      } else {
        return sampleImg;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return sampleImg;
      }
    }
  }

  function actionButton2Label() {
    if (purchased_tab === "To Pay") {
      return "Pay now";
    } else if (purchased_tab === "To Ship") {
      return "Cancel order";
    } else if (purchased_tab === "To Receive") {
      return "Order Received";
    } else if (purchased_tab === "Completed" || purchased_tab === "Cancelled") {
      return "Buy again";
    }
  }

  function actionOnClick() {
    if (purchased_tab === "To Pay") {
      handleToPay();
    } else if (purchased_tab === "To Ship") {
      dispatch(BuyerActions.cancelOrder(selected_order.id));
    } else if (purchased_tab === "To Receive") {
      dispatch(BuyerActions.markAsReceived(selected_order.id));
      return "Order Received";
    } else if (purchased_tab === "Completed" || purchased_tab === "Cancelled") {
      handleBuyAgain();
    }
  }

  function getProduct(product) {
    let params = {
      ...product,
      price: product.unit_price,
      has_variants: product.productSelection ? true : false
    };
    return params;
  }

  async function handleToPay() {
    if (
      selected_order.payment_option === "otc" &&
      selected_order.channel === null
    ) {
      window.location.href = `${apiConfig.buxURL}/checkout/${selected_order.bux_uid}/?redirect_url=${apiConfig.localHost}my_cart/checkout?ref_id=${selected_order.ref_id}`;
    } else {
      let res = await dispatch(
        BuyerActions.viewCheckoutOrder(selected_order.id)
      );

      try {
        if (res.status === 200) {
          history.push(`/my_cart/checkout`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function handleBuyAgain() {
    for (let i in items) {
      all_cart_products.push({
        uuid: items[i].uuid,
        quantity: items[i].quantity,
        variant: items[i].variants ? items[i].variants : [],
        productSelection: items[i].productSelection
      });
    }

    dispatch(StoreActions.patchBuyerCart(all_cart_products));
    setTimeout(async () => {
      dispatch(BuyerActions.getMyCart());
      let res = await dispatch(
        BuyerActions.getSelectedOrder(selected_order.store.store_uuid)
      );
      try {
        if (res.status === 200) {
          history.push(Path.CART_VIEW_ORDER);
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isMobile && selected_order}
        TransitionComponent={DialogTransition}
      >
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              style={{ position: "absolute" }}
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon style={{ color: "#1e4b83" }} />
            </IconButton>
            <div className={classes.header}>View orders</div>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <div
            style={{
              padding: 18,
              marginTop: "8vh",
              backgroundColor: "#fafafa"
            }}
          >
            <div className={classes.labelTitle}>{purchased_tab}</div>
          </div>

          <div style={{ padding: 18 }}>
            <div className={classes.cartHeaderSection}>
              <div className={classes.labelTitle}>
                {selected_order ? selected_order.store.name : ""}
              </div>
              {/* <Chip label="Sports & Travel" /> */}
              <IconButton
                onClick={() =>
                  window.open(
                    `${apiConfig.localHost}shop/${
                      selected_order ? selected_order.store.site : ""
                    }`
                  )
                }
              >
                <StorefrontIcon color="primary" />
              </IconButton>
            </div>
            <div style={{ marginTop: "3vh" }}>
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                Items:{" "}
                {items
                  ? parseFloat(
                      items.reduce((total, object) => {
                        return parseFloat(total) + parseFloat(object.quantity);
                      }, 0)
                    )
                  : ""}
              </div>
            </div>
            <div>
              {items && items.length
                ? items.map((item, key) => (
                    <div
                      style={{ borderBottom: "1px dashed #d1d5dd" }}
                      className="col-12 py-3 px-0 d-flex"
                    >
                      <div className="col-2 p-0">
                        <div
                          className={classes.productImage}
                          style={{
                            backgroundImage: `url(${productImage(item)})`
                          }}
                        />
                      </div>
                      <div className="col-10 p-0">
                        <div className="d-flex justify-content-between">
                          <div className={classes.labelbold}>{item.name}</div>
                        </div>
                        <div>
                          {item.productSelection
                            ? item.productSelection.variants.map(variant => (
                                <Chip
                                  size="small"
                                  label={variant.name}
                                  className={classes.variantChip}
                                />
                              ))
                            : ""}
                        </div>
                        <div
                          style={{ marginTop: "3vh" }}
                          className="d-flex justify-content-between"
                        >
                          <div className="col-6 p-0 d-flex justify-content-between">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                              className={classes.label}
                            >
                              <WishlistItemPriceDisplay
                                symbol="₱"
                                item={getProduct(item)}
                                productSelection={item.productSelection}
                              />
                            </div>
                            <div className={classes.label}>x</div>
                            <div className={classes.label}>{item.quantity}</div>
                          </div>
                          <div className="d-flex">
                            <div className={classes.label}>
                              ₱{" "}
                              {Utils.commafy(
                                parseFloat(
                                  item.quantity *
                                    (item.discounted_price || item.unit_price)
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
            <div className="d-block pt-4">
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "rgba(43,45,50, 0.8)"
                }}
              >
                Contact:
              </div>
              {selected_order && selected_order.store.email ? (
                <div className="d-flex align-items-center mt-3">
                  <MailOutlineIcon style={{ color: "rgba(43,45,50,0.8" }} />
                  <div
                    className="ml-2"
                    style={{
                      fontSize: 12,
                      color: "rgba(43,45,50, 0.8)"
                    }}
                  >
                    {selected_order && selected_order.store.email}
                  </div>
                </div>
              ) : (
                ""
              )}
              {selected_order && selected_order.store.mobile ? (
                <div className="d-flex align-items-center mt-3">
                  <PhoneIphoneIcon style={{ color: "rgba(43,45,50,0.8" }} />
                  <div
                    className="ml-2"
                    style={{
                      fontSize: 12,
                      color: "rgba(43,45,50, 0.8)"
                    }}
                  >
                    {selected_order && selected_order.store.mobile}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className={classes.summary}>
          <Table style={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.columnHeader}>
                  Summary
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.column} align="left">
                  Items
                </TableCell>
                <TableCell className={classes.column} align="right">
                  {items
                    ? parseFloat(
                        items.reduce((total, object) => {
                          return (
                            parseFloat(total) + parseFloat(object.quantity)
                          );
                        }, 0)
                      )
                    : ""}
                </TableCell>
              </TableRow>
              {selected_order && selected_order.shipping_fee ? (
                <TableRow>
                  <TableCell className={classes.column} align="left">
                    Delivery fee
                  </TableCell>
                  <TableCell className={classes.column} align="right">
                    ₱ {selected_order ? selected_order.shipping_fee : ""}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <TableRow>
                <TableCell className={classes.column} align="left">
                  Subtotal
                </TableCell>
                <TableCell className={classes.column} align="right">
                  ₱ {selected_order.subtotal}
                </TableCell>
              </TableRow>
              {voucher ? (
                <TableRow>
                  <TableCell className={classes.column} align="left">
                    Voucher Applied
                  </TableCell>
                  <TableCell className={classes.column} align="right">
                    {`- ₱${Utils.commafy(voucher.discount_value)}`}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <TableRow>
                <TableCell className={classes.columnFooter} align="left">
                  Total
                </TableCell>
                <TableCell className={classes.columnFooter} align="right">
                  ₱ {selected_order.amount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {purchased_tab === "To Ship" || purchased_tab === "To Receive" ? (
            ""
          ) : (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              style={{ marginTop: "3vh" }}
              onClick={actionOnClick}
            >
              {actionButton2Label()}
            </Button>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default PurchasedViewOrderModal;
