import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Icon } from "@iconify/react";
import facebookMessenger from "@iconify/icons-mdi/facebook-messenger";
import CloseIcon from "@material-ui/icons/Close";
import { ExpandMore } from "@material-ui/icons";
import img1 from "../../../../../assets/images/FB_Chat/fb_chat_1.gif";
import img2 from "../../../../../assets/images/FB_Chat/fb_chat_2.gif";
import checkedIcon from "../../../../../assets/images/checked.svg";
import { animateScroll as scroll } from "react-scroll";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  OutlinedInput,
  Dialog,
  Toolbar,
  IconButton,
  Slide,
  AppBar,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import Path from "../../../../../Paths";
import SentroLoading from "../../../loading";
import history from "../../../../../store/history";
import {
  saveFBPageID,
  getStoreProducts
} from "../webBuilder/websiteBuilderActions";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = theme => ({
  root: {
    padding: "2em",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  fbMessengerIcon: {
    color: "#0D3D76",
    fontSize: 100
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  content: {
    padding: "0 1.5em",
    "& > strong": {
      color: "#002C42"
    },
    "& > p": {
      marginTop: 10
    }
  }
});

class Card extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Icon icon={facebookMessenger} className={classes.fbMessengerIcon} />
        <div className={classes.content}>
          <strong>Chat to your clients on your shop</strong>
          <p>
            Link your shop’s FB Messenger account so your clients can
            immediately chat with you while browsing your Sentro shop.{" "}
          </p>
        </div>
        <Button
          className={classes.button}
          id="view"
          color="primary"
          disableElevation
          variant="outlined"
          onClick={() => {
            // const link =  data.variants ? `/marketplace/${data.id}/packages` : `marketplace/${data.id}/checkout`
            history.push(Path.FB_CHAT_SETUP);
          }}
        >
          Learn More
        </Button>
      </div>
    );
  }
}
const FBChatCard = withStyles(useStyles)(Card);
export { FBChatCard };

const useStylesSetup = theme => ({
  main: {
    display: "block",
    backgroundColor: "#f1f1f1",
    height: "88vh",
    overflowY: "scroll"
  },
  header: {
    padding: 20,
    marginTop: 68
  },
  backDivWrapper: {
    display: "flex",
    "& > span": {
      marginLeft: "1em",
      fontWeight: 600
    },
    color: "#F97663 !important",
    alignItems: "center",
    cursor: "pointer",
    width: 100
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
    maxWidth: "80em",
    margin: "auto"
  },
  fbMessengerIcon: {
    color: "#0D3D76",
    width: 70,
    height: "auto"
  },
  headerDetails: {
    "& > strong": {
      fontSize: 20,
      color: "#2B2D33"
    },
    "& > p": {
      marginTop: 10,
      fontSize: 16
    }
  },
  root: {
    margin: "auto",
    maxWidth: "60em",
    paddingBottom: "3em"
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  expansionTitle: {
    color: "#002C42",
    fontSize: 14
  },
  expansionForm: {
    background: "#F1F1F1",
    display: "block",
    "& .expansionForm": {
      display: "flex",
      alignItems: "center"
    },
    "& img": {
      margin: "1em 0"
    }
  },
  inputFieldButton: {
    height: 40
  },
  inputField: {
    width: "100%",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    height: 40,
    backgroundColor: "white"
  },
  howToGetFBPage: {
    margin: "1.5em 0"
  },
  step2Details: {
    backgroundColor: "white",
    padding: "1.5em"
  },
  paper: {
    padding: 20,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8
  },
  appbar: {
    position: "fixed",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd"
  },
  closeBtn: { position: "absolute", left: 15 },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  }
});
const DialogTransition = props => <Slide direction="up" {...props} />;
class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section1: true,
      section2: false,
      fbPageId: "",
      disableField: false,
      alertStat: false,
      alertType: "success",
      openWarning: false,
      blueBox: false
    };
    this.expansionChange = this.expansionChange.bind(this);
    this.cardOnClick = this.cardOnClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.unlink = this.unlink.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.buttonSubmit = this.buttonSubmit.bind(this);
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    const stores = await dispatch(getStoreProducts());
    if (stores[0]) {
      this.setState({
        fbPageId: stores[0].fb_page_id
      });
      if (stores[0].fb_page_id) {
        this.setState({
          blueBox: true,
          disableField: true
        });
      }
    }
    scroll.scrollTo(0);
  };

  expansionChange(section, stat) {
    this.setState({
      [section]: stat
    });
  }

  cardOnClick() {
    this.setState({
      section1: true,
      section2: true
    });
  }

  onSubmit = async () => {
    const { dispatch, stores } = this.props;
    await dispatch(saveFBPageID(stores[0].site, this.state.fbPageId));
    this.setState({
      alertStat: true,
      alertType: "success",
      disableField: true,
      blueBox: true
    });
    scroll.scrollTo(0);
  };

  unlink = async () => {
    const { dispatch, stores } = this.props;
    await dispatch(saveFBPageID(stores[0].site, ""));
    this.setState({
      fbPageId: "",
      alertStat: true,
      alertType: "warning",
      disableField: false,
      openWarning: false,
      blueBox: false
    });
    scroll.scrollTo(0);
  };

  handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      alertStat: false
    });
  };

  buttonSubmit = () => {
    const { stores, classes } = this.props;
    return (
      <div style={{ paddingTop: "3vh" }}>
        <Button
          className={classes.inputFieldButton}
          color="primary"
          disableElevation
          variant="contained"
          disabled={!this.state.fbPageId || !stores.length}
          onClick={this.onSubmit}
          fullWidth
        >
          Submit
        </Button>
      </div>
    );
  };

  render() {
    const {
      classes,
      loading,
      stores,
      open,
      setOpen,
      DialogTransition
    } = this.props;

    return (
      <div>
        <Dialog
          fullScreen
          open={open.fb_chat}
          TransitionComponent={DialogTransition}
        >
          <Snackbar
            style={{ top: 70 }}
            open={this.state.alertStat && this.state.alertType === "success"}
            autoHideDuration={2000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={this.handleCloseAlert}
              severity="success"
              icon={<InfoOutlinedIcon fontSize="inherit" />}
            >
              Your Facebook ID is now linked to your Sentro shop. Visit your
              shop to check it out.
            </Alert>
          </Snackbar>
          <Snackbar
            style={{ top: 70 }}
            open={this.state.alertStat && this.state.alertType === "warning"}
            autoHideDuration={2000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={this.handleCloseAlert}
              severity="warning"
              icon={<InfoOutlinedIcon fontSize="inherit" />}
            >
              You have unlinked a Facebook ID from your Sentro shop.
            </Alert>
          </Snackbar>
          <SentroLoading open={loading} />
          <AppBar className={classes.appbar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                className={classes.closeBtn}
                onClick={() => setOpen({ ...open, fb_chat: false })}
              >
                <CloseIcon color="primary" />
              </IconButton>
              <div className={classes.header}>FB Messenger in Sentro</div>
            </Toolbar>
          </AppBar>
          <div
            style={{
              backgroundColor: "#1e4b83",
              color: "#fff",
              padding: 20,
              marginTop: "9vh",
              display: this.state.blueBox ? "" : "none"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <img
                src={checkedIcon}
                style={{ height: "auto", width: 50 }}
                alt=""
              />
              <div style={{ fontSize: 12, paddingLeft: 10 }}>
                You have currently linked a Facebook page to your Sentro Shop
              </div>
              <IconButton
                style={{ display: "flex", bottom: 10 }}
                onClick={() => this.setState({ ...this.state, blueBox: false })}
              >
                <CloseIcon style={{ color: "#fff" }} />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              padding: 20,
              borderBottom: "1px solid #d1d5dd",
              marginTop: this.state.blueBox ? 0 : "10vh"
            }}
          >
            <div
              style={{
                fontSize: 14,
                color: "#54575f",
                display: this.state.blueBox ? "none" : ""
              }}
            >
              Chat with your customers directly from your Sentro shop by linking
              your Facebook page and enabling Live Chat.
            </div>
            <div style={{ display: "block", paddingTop: "2vh" }}>
              <div style={{ fontSize: 14, color: "#54575f" }}>Facebook ID</div>
              <div className="expansionForm">
                <OutlinedInput
                  color="secondary"
                  disabled={this.state.disableField || stores.length === 0}
                  className={classes.inputField}
                  value={this.state.fbPageId}
                  type="number"
                  onChange={event => {
                    this.setState({
                      fbPageId: event.target.value
                    });
                  }}
                />
              </div>

              {stores.length ? (
                stores[0] ? (
                  stores[0].fb_page_id ? (
                    this.state.disableField ? (
                      <div
                        style={{
                          paddingTop: "3vh",
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <Button
                          onClick={() =>
                            this.setState({ ...this.state, openWarning: true })
                          }
                          style={{ width: "45%", height: 40 }}
                          variant="text"
                        >
                          Unlink
                        </Button>{" "}
                        <Button
                          style={{ width: "45%", height: 40 }}
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              disableField: false
                            })
                          }
                        >
                          Edit
                        </Button>
                      </div>
                    ) : (
                      this.buttonSubmit()
                    )
                  ) : (
                    this.buttonSubmit()
                  )
                ) : (
                  this.buttonSubmit()
                )
              ) : (
                this.buttonSubmit()
              )}
            </div>
          </div>
          <ExpansionPanel
            style={{ boxShadow: "none", marginTop: 0, marginBottom: 0 }}
            expanded={this.state.section1}
            onChange={(obj, stat) => this.expansionChange("section1", stat)}
          >
            <ExpansionPanelSummary
              style={{
                padding: "0 20px",

                borderTop: "1px solid #002c42",
                borderBottom: "1px solid #002c42"
              }}
              expandIcon={<ExpandMore />}
            >
              <strong className={classes.expansionTitle}>
                STEP 1: LINK YOUR FACEBOOK ID TO YOUR SHOP
              </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ borderBottom: "1px solid #D1D5DD" }}
            >
              <div style={{ padding: "10px 30px" }}>
                <div style={{ color: "#54575f", fontSize: 14 }}>
                  How to find your Facebook ID?
                </div>
                <div
                  style={{ color: "#54575f", fontSize: 14, paddingTop: "2vh" }}
                >
                  Go to{" "}
                  <span
                    style={{ color: "#f97663" }}
                    onClick={() => window.open("https://findmyfbid.in/")}
                  >
                    findmyfbid.in
                  </span>
                  . Type your shop's Facebook page in the text box, then click
                  the Find Facebook ID button to proceed. Your FB ID consists of
                  numbers.
                </div>
                <img
                  src={img1}
                  style={{ width: "100%", paddingTop: "2vh" }}
                  alt=""
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            style={{ boxShadow: "none", marginTop: 0 }}
            expanded={this.state.section2}
            onChange={(obj, stat) => this.expansionChange("section2", stat)}
          >
            <ExpansionPanelSummary
              style={{
                padding: "0 20px",

                borderTop: "1px solid #002c42",
                borderBottom: "1px solid #002c42"
              }}
              expandIcon={<ExpandMore />}
            >
              <strong className={classes.expansionTitle}>
                STEP 2: LINK YOUR SHOP TO YOUR FB PAGE
              </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ borderBottom: "1px solid #D1D5DD" }}
            >
              <div style={{ padding: "10px 30px" }}>
                <div style={{ fontSize: 14, color: "#54575f" }}>
                  <p>
                    1. Go to your Facebook page’s <strong>Settings</strong>,
                    then click the <strong>Messaging</strong> tab. Look for the{" "}
                    <strong>Add Messenger to your website</strong> and click{" "}
                    <strong>Get Started</strong>
                  </p>
                  <p>
                    2. A <strong>Set Up Chat Plugin</strong> window will appear.
                    Fill up your preferences until step 3.
                  </p>
                  <p>
                    3. In step 3, under the{" "}
                    <strong>Add Website Domain Name section</strong>, add your
                    Sentro online shop’s link. Type in both the HTTP:// and
                    HTTPS:// variants of your Sentro shop to ensure it works
                    properly. Then click <strong>Save</strong> and{" "}
                    <strong>Finish</strong>
                  </p>
                </div>
                <img
                  src={img2}
                  style={{ width: "100%", paddingTop: "2vh" }}
                  alt=""
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <Dialog fullWidth maxWidth="md" open={this.state.openWarning}>
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 0
              }}
            >
              <IconButton
                onClick={() =>
                  this.setState({ ...this.state, openWarning: false })
                }
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                  width: "fit-content"
                }}
              >
                <ErrorIcon
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "#ee2545",
                    fontSize: 100
                  }}
                />
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{
                      padding: "10px 0",
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#002c42"
                    }}
                  >
                    Are you sure you want to unlink your Facebook ID?
                  </div>
                </div>
                <div style={{ paddingTop: "5vh" }}>
                  <Button
                    onClick={() => this.unlink()}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disableElevation
                  >
                    Yes, please unlink
                  </Button>
                  <Button
                    onClick={() =>
                      this.setState({ ...this.state, openWarning: false })
                    }
                    variant="text"
                    color="primary"
                    fullWidth
                  >
                    No, take me back
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({ mobileDashboard }) => ({
  loading: mobileDashboard.loading,
  stores: mobileDashboard.stores,
  DialogTransition: DialogTransition
});
const MobileFBChat = connect(mapStateToProps)(
  withStyles(useStylesSetup)(Setup)
);
export default MobileFBChat;
