const PDFs = [
  {
    title: "Upcoming Promo",
    date: "Oct 29, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/10292020%20Upcoming%20Promo.pdf"
  },
  {
    title: "Official Group",
    date: "Oct 23, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/10232020%20Official%20Group.pdf"
  },
  {
    title: "Sprout Solutions Event",
    date: "Oct 21, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/10212020%20Sprout%20Solutions%20Event.pdf"
  },
  {
    title: "Export Documents",
    date: "Oct 19, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/10192020%20Export%20Documents.pdf"
  },
  {
    title: "Kwentong Tagumpay sa i2i",
    date: "Oct 12, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/10122020%20Kwentong%20Tagumpay%20sa%20i2i.pdf"
  },
  {
    title: "Usap Tayo",
    date: "Oct 9, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/10092020%20Usap%20Tayo.pdf"
  },
  {
    title: "Lazada Seller Loan",
    date: "Oct 7, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/10072020%20Lazada%20Seller%20Loan.pdf"
  },
  {
    title: "We heard you! l Sentro’s New Features",
    date: "Oct 5, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/We+heard+you!+l+Sentro%E2%80%99s+New+Features.pdf"
  },
  {
    title: "Introducing Bux Payment Gateway",
    date: "Sep 28, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/_Introducing%20Bux%20Payment%20Gateway%20.pdf"
  },
  {
    title: "Add Facebook Messenger to your Sentro Shop",
    date: "Sep 25, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/_%20Add%20Facebook%20Messenger%20to%20your%20Sentro%20Shop_.pdf"
  },
  {
    title: "Apps and Services is now live on Sentro",
    date: "Sep 16, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Apps_and_Services_is_now_live_on_Sentro___.pdf"
  },
  {
    title: "Mr. Speedy Now Live in Sentro",
    date: "Sep 9, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Mr._Speedy_Now_Live_in_Sentro___.pdf"
  },
  {
    title: "Gusto mo bang matuto kung paano mag export sa US?",
    date: "Sep 7, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Gusto_mo_bang_matuto_kung_paano_mag_export_sa_US_.pdf"
  },
  {
    title: "WATCH 🎥 l Sentro’s Best: MukBayanihan Episode 2",
    date: "Aug 26, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/WATCH___l_Sentro_s_Best__MukBayanihan_Episode_2.pdf"
  },
  {
    title: "🤩 MUST WATCH! Sentro's Best: MukBayanihan 🇵🇭",
    date: "Aug 15, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/__MUST_WATCH__Sentro_s_Best__MukBayanihan___.pdf"
  },
  {
    title: "Hi mga Noypi! 🇵🇭",
    date: "Aug 3, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Hi_mga_Noypi______1_.pdf"
  },
  {
    title: "Free Online Webinar: Exporting 101",
    date: "Jul 30, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Free_Online_Webinar__Exporting_101.pdf"
  },
  {
    title: "Let's talk about investments",
    date: "Jul 27, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Let_s_talk_about_investments.pdf"
  },
  {
    title: "Let us know you better! 😁",
    date: "Jul 10, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Let_us_know_you_better___.pdf"
  },
  {
    title: "Update your Stock!",
    date: "Jul 9, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Update_your_Stock___3_.pdf"
  },
  {
    title: "May sasabihin kami sa'yo, fren. 🤫",
    date: "Jun 25, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/May_sasabihin_kami_sa_yo__fren.__.pdf"
  },
  {
    title: "Publish your shop now! 😁",
    date: "Jun 10, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Publish_your_shop_now_____.pdf"
  },
  {
    title: "Verify your Sentro account now",
    date: "Jun 9, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Verify_your_Sentro_account_now.pdf"
  },
  {
    title: "We want to hear from you",
    date: "Jun 8, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/We_want_to_hear_from_you_.pdf"
  },
  {
    title: "Hmm.. Looks like you haven't published your online shop yet.",
    date: "Jun 3, 2020",
    src:
      "https://sentro-announcements.s3.amazonaws.com/Hmm.._Looks_like_you_haven_t_published_your_online_shop_yet..pdf"
  }
];

export default PDFs;
