import axios from "axios";
import apiConfig from "../../common/apiConfig";
const CURRENT_USER = "CURRENT_USER";
async function getProfileInfo() {
  try {
    let response = await axios.get(`${apiConfig.apiURL}/api/user/update/`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function saveProfileInfo(value) {
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/user/update/`,
      value,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    let data = response.data;
    let existing = sessionStorage.getItem("CURRENT_USER");
    existing = existing ? JSON.parse(existing) : {};

    existing["first_name"] = data.first_name;
    existing["last_name"] = data.last_name;
    existing["mobile_number"] = data.mobile_number;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function confirmChangePassword(value) {
  let params = {
    current_password: value.current_password,
    new_password: value.new_password
  };
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/password/`,
      {
        old_password: params.current_password,
        new_password: params.new_password
      },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );

    let data = response.data;
    let existing = sessionStorage.getItem("CURRENT_USER");
    existing = existing ? JSON.parse(existing) : {};

    existing["password_change"] = data.change_date;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));

    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function updatePassword(value) {
  let params = {
    password_update: value.password_update,
    skip_password: false
  };
  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/user/update/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    let data = response.data;
    let existing = sessionStorage.getItem("CURRENT_USER");
    existing = existing ? JSON.parse(existing) : {};
    existing["password_update"] = data.password_update;
    existing["skip_password"] = data.skip_password;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
    return response.data;
  } catch (error) {
    throw error.response;
  }
}
export {
  getProfileInfo,
  saveProfileInfo,
  confirmChangePassword,
  updatePassword
};
