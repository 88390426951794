import React, { useState } from 'react';
import RangeSlider from './rangeSlider';

import '../style/custom.css';

const TraitsFilter = (props) => {
  const [traitsStrength, setTraitsStrength] = useState(
    props.traitsList.strength
  );
  const [traitsSpirit, setTraitsSpirit] = useState(props.traitsList.spirit);
  const [traitsSmarts, setTraitsSmarts] = useState(props.traitsList.smarts);
  const [traitsStamina, setTraitsStamina] = useState(props.traitsList.stamina);

  const onChangeTraitsStrength = (newValue) => {
    setTraitsStrength(newValue);
  };

  const onChangeTraitsSpirit = (newValue) => {
    setTraitsSpirit(newValue);
  };

  const onChangeTraitsSmarts = (newValue) => {
    setTraitsSmarts(newValue);
  };

  const onChangeTraitsStamina = (newValue) => {
    setTraitsStamina(newValue);
  };

  const onClickApply = () => {
    props.onClickFilterTraitsNFT({
      strength: traitsStrength,
      spirit: traitsSpirit,
      smarts: traitsSmarts,
      stamina: traitsStamina,
    });
  };

  return (
    <>
      <h2 className="text-center">TRAITS</h2>
      <div className="option-box-container">
        <div className="option-box">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <span className="nft-button-cut-corner traits-label-filter">
                STRENGTH
              </span>
              <span style={{ fontSize: '14px' }}>
                {`${traitsStrength[0]} - ${traitsStrength[1]}`}
              </span>
            </div>
            <RangeSlider
              value={traitsStrength}
              onChange={onChangeTraitsStrength}
            />
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <span className="nft-button-cut-corner traits-label-filter">
                SPIRIT
              </span>
              <span style={{ fontSize: '14px' }}>
                {`${traitsSpirit[0]} - ${traitsSpirit[1]}`}
              </span>
            </div>
            <RangeSlider value={traitsSpirit} onChange={onChangeTraitsSpirit} />
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <span className="nft-button-cut-corner traits-label-filter">
                SMARTS
              </span>
              <span style={{ fontSize: '14px' }}>
                {`${traitsSmarts[0]} - ${traitsSmarts[1]}`}
              </span>
            </div>
            <RangeSlider value={traitsSmarts} onChange={onChangeTraitsSmarts} />
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <span className="nft-button-cut-corner traits-label-filter">
                STAMINA
              </span>
              <span style={{ fontSize: '14px' }}>
                {`${traitsStamina[0]} - ${traitsStamina[1]}`}
              </span>
            </div>
            <RangeSlider
              value={traitsStamina}
              onChange={onChangeTraitsStamina}
            />
          </div>
          <button
            className="nft-button-cut-corner"
            onClick={() => {
              onClickApply();
            }}
          >
            APPLY
          </button>
        </div>
      </div>
    </>
  );
};

export default TraitsFilter;
