import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import MaterialUIAlert from "../../../common/material-ui-alert";
import { Link, useTheme, useMediaQuery } from "@material-ui/core";
import Path from "../../../../Paths";
import { useHistory } from "react-router-dom";

export default function BuxUpgrade(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const subscription = useSelector(state => state.subscription.subscription);
  const history = useHistory();
  const bux_level = useSelector(state =>
    isMobile
      ? state.mobileDashboard.storeInfo?.kyc_level
      : state.webBuilder.buxStatus
  );
  const bux = JSON.parse(sessionStorage.getItem("bux"));
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem("bux", JSON.stringify({ show_banner: false }));
  };

  React.useEffect(() => {
    if (bux_level === 0 && subscription.free_trial_availed && !bux) {
      setShow(true);
      // sessionStorage.setItem("bux", JSON.stringify({ show_banner: true }));
    }
  }, [bux_level]);

  React.useEffect(() => {
    if (bux && !bux.show_banner) {
      setShow(false);
    }
  }, []);

  return (
    <div>
      {bux_level === 0 && show ? (
        <div className="pb-3 px-md-0 px-3 pt-md-0 pt-3">
          <MaterialUIAlert
            type={"info"}
            icon={false}
            message={
              <div>
                Upgrade your <strong>BUx wallet</strong> to enjoy Sentro
                Advanced and all its features.{" "}
                <Link
                  style={{ fontWeight: 600, color: "#25459b" }}
                  onClick={() => history.push(Path.PAYMENTS)}
                >
                  Learn more
                </Link>
                .
              </div>
            }
            style={{ border: "1px solid #3a73cc", color: "#25459b" }}
            onClose={handleClose}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
