const storeContactContent = {
  English: {
    storeAddress: "Where is your shop located?",
    storeMobile: "Where can your customers contact you?",
    socialMedia:
      "Do you have other social media accounts? Provide your links here",
    socialSubheader:
      "To achieve better digital reach, please set your accounts to Public"
  },
  Filipino: {
    storeAddress: "Ano'ng address ng business mo?",
    storeMobile: "Saan ka pwedeng ma-contact ng iyong mga customers?",
    socialMedia:
      "Meron ka bang social media accounts and business mo? Ilagay mo rito ang mga link",
    socialSubheader:
      "Siguraduhing naka-Public ang iyong mga accounts para makita ng iba"
  }
};

export default storeContactContent;
