import React from "react";
import { makeStyles } from "@material-ui/styles";
import { MenuItem, IconButton, Chip, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import moment from "moment";
import { setSelectedVoucher } from "../webBuilder/websiteBuilderActions";
import { useSelector, useDispatch } from "react-redux";
const MobileVoucherCard = props => {
  const {
    item,
    handleEditVoucher,
    handleViewVoucher,
    handleDeactivate
  } = props;
  const useStyles = makeStyles(theme => ({
    card: {
      position: "relative",
      width: "100%",
      marginBottom: 15,
      border: "1px solid #d1d5dd",
      borderRadius: 4,
      backgroundColor: "#fff",
      boxShadow: "0 8px 12px rgba(0,0,0,0.05)",
      [theme.breakpoints.down("sm")]: {
        padding: 30
      },
      [theme.breakpoints.down("xs")]: {
        padding: 15
      }
    },
    code: {
      color: "#2b2d32",
      fontWeight: "bold",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 17
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 14
      }
    },
    label2: {
      fontSize: 12,
      color: "#616675"
    },
    menuitem: {
      color: "#54575f",
      fontSize: 12,
      minHeight: 0,
      "&: hover": {
        backgroundColor: "#e6eaf0 !important"
      }
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [targetRow, setTargetRow] = React.useState(null);
  const setMenu = (event, targetRow) => {
    setMenuAnchor(event.target);
    setTargetRow(targetRow);
  };

  function voucherType(type) {
    if (type === "free shipping" || type === "free_shipping") {
      return "Free shipping";
    } else if (type === "fixed_amount" || type === "fixed amount") {
      return "Fixed amount";
    } else {
      return "Percentage";
    }
  }

  return (
    <div className={classes.card}>
      <div style={{ width: "100%", height: 74 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div className={classes.code}>{item.voucher_code}</div>
          <MoreVertIcon
            color="secondary"
            onClick={e => {
              dispatch(setSelectedVoucher(item));
              setMenu(e, item);
            }}
          />
          <Menu
            anchorEl={menuAnchor}
            keepMounted
            open={
              targetRow ? targetRow.voucher_code === item.voucher_code : false
            }
            onClose={() => {
              setMenuAnchor(null);
              setTargetRow(null);
            }}
          >
            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                handleViewVoucher(props.item);
                setMenuAnchor(null);
                setTargetRow(null);
              }}
            >
              View Details
            </MenuItem>
            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                handleEditVoucher(props.item);
                setMenuAnchor(null);
                setTargetRow(null);
              }}
            >
              Edit Details
            </MenuItem>
            <MenuItem
              className={classes.menuitem}
              onClick={() => {
                !item.is_active
                  ? handleDeactivate(true)
                  : props.setOpenDeactivateModal(true);
                setMenuAnchor(null);
                setTargetRow(null);
              }}
            >
              {item.is_active ? "Deactivate" : "Activate"}
            </MenuItem>
          </Menu>
        </div>
        <div className={classes.label2}>
          {moment(item.start_datetime).format("D MMM YYYY") +
            "-" +
            moment(item.end_datetime).format("D MMM YYYY")}
        </div>
        <div style={{ position: "absolute", bottom: 10, left: 15, right: 15 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div
              className={classes.label2}
              style={{ display: "flex", textTransform: "capitalize" }}
            >
              {voucherType(item.voucher_type)}
              <FiberManualRecordIcon
                color="secondary"
                style={{ fontSize: 8, margin: 5 }}
              />
              {item.times_used} used
            </div>

            <Chip
              label={item.is_active ? "Active" : "Inactive"}
              style={{
                backgroundColor: item.is_active ? "#18b4a1" : "gray",
                color: "#fff"
              }}
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileVoucherCard;
