import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Blank from "../blank";
import { ProductDetailedPriceDisplay } from "../PriceDisplay";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  useMediaQuery,
  TextField,
  Button,
  FormHelperText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import VariantPicker from "./variantPicker";
import Freebie from "../../../../quickOrder/components/products/freebie";
import Actions from "../../../../quickOrder/redux/actions";
import AddToCartLimit, { checkLimit } from "../addToCartLimit";
import Utils from "../../../utils";
export default function ProductForm(props) {
  const { product } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const color = props.store ? props.store.color : "";
  const useStyles = makeStyles((theme) => ({
    quantitySection: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        alignItems: "center",
      },
    },
    btnQuantity: {
      padding: 0,
      borderRadius: 0,
      height: 35,
      minWidth: 35,
    },
    quantityField: {
      height: 35,
      width: 80,
      borderRadius: 0,
      paddingTop: 0,
      backgroundColor: "#fff",
    },
    label: {
      fontSize: 14,
      color: "#2b2d32",
      fontWeight: 500,
      color: color.primary.contrastText,
      [theme.breakpoints.down("sm")]: {
        width: "25%",
      },
    },
    colorPrimary: { color: color.primary.contrastText },
  }));
  const classes = useStyles();
  const [productSelection, setProductSelection] = React.useState("");
  const [quantity, setQuantity] = React.useState(1);
  const [error, setError] = React.useState("");
  const [freebie, set_freebie] = React.useState([]);
  const [openBuxLimit, setOpenBuxLimit] = React.useState(false);

  function handleQuantity(input) {
    let sanitizedInput =
      parseInt(input.replace(/[^0-9]|(^0+)/g, "").substring(0, 3)) || 0;
    setQuantity(sanitizedInput);
  }

  const getStocks = () => {
    if (product.has_variants) {
      return productSelection ? productSelection.quantity : -2;
    } else {
      if (product.always_available) {
        return -1;
      } else {
        return product.quantity;
      }
    }
  };

  const stocks_display = () => {
    const stocks = getStocks();
    if (stocks === -2) {
      return "";
    } else if (stocks === -1) {
      return (
        <div className={classes.colorPrimary} style={{ fontSize: 10 }}>
          Always available
        </div>
      );
    } else if (stocks === 0) {
      return <div style={{ fontSize: 10, color: "red" }}>Out of stock</div>;
    } else {
      return (
        <div className={classes.colorPrimary} style={{ fontSize: 10 }}>
          Available stocks: {stocks}
        </div>
      );
    }
  };

  const should_disable_quantity_field = product.has_variants
    ? !productSelection
    : false;

  const disabled_add_to_cart_button =
    Boolean(error) ||
    Boolean(product.has_variants ? !productSelection : false) ||
    getStocks() === 0;
  const productSelectionOnChange = (val) => {
    setProductSelection(val);
  };

  let computeTotal =
    parseFloat(
      quantity *
        (productSelection
          ? productSelection.discounted_price || productSelection.price
          : product.discounted_price || product.price)
    ) + props.total;

  const handleAddToCart = () => {
    let params = {
      ...product,
      quantity,
      stock: getStocks(),
      variants: productSelection ? productSelection.variants : [],
      productSelection,
    };
    //CHECK TOTAL IF IT REACHES BUX LIMIT
    if (
      (props.store.kyc_level === 0 && parseInt(computeTotal) > 100000) ||
      (props.store.kyc_level === 1 && parseInt(computeTotal) > 500000)
    ) {
      setOpenBuxLimit(true);
    } else {
      setOpenBuxLimit(false);
      if (props.onSubmit) {
        props.onSubmit(params);
        if (freebie.length) {
          for (let i in freebie) {
            dispatch(
              Actions.setFreebie({
                ...freebie[i],
                is_requirement_applied:
                  quantity < parseInt(freebie[i].minimum_purchase_amount)
                    ? false
                    : true,
              })
            );
          }
        }
      }
    }
  };

  React.useEffect(() => {
    setProductSelection(props.product_selection_value);
  }, [props.product_selection_value]);

  React.useEffect(() => {
    let errorMessage = "";
    const stocks = getStocks();
    if ([-2, -1, 0].includes(stocks)) {
      errorMessage = "";
    } else {
      const insufficient_stock = stocks < quantity;
      errorMessage = insufficient_stock ? `Maximum of ${stocks} item(s)` : "";
    }
    setError(errorMessage);
  }, [quantity, productSelection]);

  return (
    <div>
      {openBuxLimit ? <AddToCartLimit kycLevel={props.store?.kyc_level} /> : ""}
      <div className="d-flex align-items-center">
        <ProductDetailedPriceDisplay
          item={props.product}
          theme={theme}
          productSelection={productSelection}
          store={props.store}
        />
      </div>
      <div
        style={{ marginTop: 20 }}
        className="d-md-block d-flex align-items-center"
      >
        <div className={classes.label}>Quantity</div>
        <div className="d-md-flex d-none">{stocks_display()}</div>
        <div>
          <div className={`d-flex mt-1`}>
            <Button
              variant="outlined"
              style={{ borderRight: "none", backgroundColor: "#fff" }}
              className={classes.btnQuantity}
              onClick={() => setQuantity(quantity === 1 ? 1 : quantity - 1)}
              disabled={should_disable_quantity_field}
            >
              <RemoveIcon fontSize="small" />
            </Button>
            <TextField
              id="quantity"
              variant="outlined"
              margin="dense"
              style={{ marginTop: 0, color: color.primary.contrastText }}
              InputProps={{ className: classes.quantityField }}
              error={error}
              value={quantity}
              onChange={(e) => handleQuantity(e.target.value)}
              disabled={should_disable_quantity_field}
            />
            <Button
              variant="outlined"
              style={{ borderLeft: "none", backgroundColor: "#fff" }}
              className={classes.btnQuantity}
              onClick={() => setQuantity(quantity + 1)}
              disabled={should_disable_quantity_field}
            >
              <AddIcon fontSize="small" />
            </Button>
          </div>
          <FormHelperText error={error}>{error}</FormHelperText>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <VariantPicker
          product={product}
          product_selection_value={productSelection}
          onSelectionChange={productSelectionOnChange}
          disabled={props.disable_variants_picker}
          store={props.store}
        />
      </div>
      <div className="my-2">
        <Freebie
          store={props.store}
          product={props.product}
          freebie={freebie}
          set_freebie={set_freebie}
          product_quantity={quantity}
        />
      </div>
      <div style={{ marginTop: "5vh" }}>
        <Button
          disableElevation
          fullWidth
          variant="contained"
          color="secondary"
          disabled={disabled_add_to_cart_button || props.disableSubmit}
          onClick={handleAddToCart}
        >
          Add to cart
        </Button>
      </div>
    </div>
  );
}
