import Services from "./services";
import ProductServices from "../../common/template/components/StoreServices";
import apiConfig from "../../../common/apiConfig";
import { showAlert } from "../../common/commonActions";
export const ActionTypes = {
  QUICK_ORDER_GET_PRODUCTS: "QUICK_ORDER_GET_PRODUCTS",
  QUICK_ORDER_GET_PRODUCTS_SUCCESS: "QUICK_ORDER_GET_PRODUCTS_SUCCESS",
  QUICK_ORDER_GET_PRODUCTS_FAILED: "QUICK_ORDER_GET_PRODUCTS_FAILED",
  QUICK_ORDER_SET_PRODUCT_FILTERS: "QUICK_ORDER_SET_PRODUCT_FILTERS",
  QUICK_ORDER_SET_SELECTED_PRODUCT: "QUICK_ORDER_SET_SELECTED_PRODUCT",
  QUICK_ORDER_ADD_TO_CART: "QUICK_ORDER_ADD_TO_CART",
  QUICK_ORDER_UPDATE_VARIANT: "QUICK_ORDER_UPDATE_VARIANT",
  QUICK_ORDER_UPDATE_QUANTITY: "QUICK_ORDER_UPDATE_QUANTITY",
  QUICK_ORDER_REMOVE_FROM_CART: "QUICK_ORDER_REMOVE_FROM_CART",
  QUICK_ORDER_SET_SELECTED_CHECKOUT: "QUICK_ORDER_SET_SELECTED_CHECKOUT",
  QUICK_ORDER_SET_SELECTED_PAYMENT_CHANNEL:
    "QUICK_ORDER_SET_SELECTED_PAYMENT_CHANNEL",
  QUICK_ORDER_SET_DELIVERY_DETAILS: "QUICK_ORDER_SET_DELIVERY_DETAILS",
  QUICK_ORDER_SHIPPING_FEE_LOADING_ON: "QUICK_ORDER_SHIPPING_FEE_LOADING_ON",
  QUICK_ORDER_SHIPPING_FEE_LOADING_OFF: "QUICK_ORDER_SHIPPING_FEE_LOADING_OFF",
  QUICK_ORDER_NINJAVAN_SHIPPING_FEE: "QUICK_ORDER_NINJAVAN_SHIPPING_FEE",
  QUICK_ORDER_MRSPEEDY_SHIPPING_FEE: "QUICK_ORDER_MRSPEEDY_SHIPPING_FEE",

  QUICK_ORDER_CHECKOUT_LOADING_ON: "QUICK_ORDER_CHECKOUT_LOADING_ON",
  QUICK_ORDER_CHECKOUT_SUCCESS: "QUICKORDER_CHECKOUT_SUCCESS",
  QUICK_ORDER_CHECKOUT_FAILED: "QUICK_ORDER_CHECKOUT_FAILED",
  //COMPUTE FEES
  QUICK_ORDER_COMPUTE_FEES: "QUICK_ORDER_COMPUTE_FEES_LOADING_ON",
  QUICK_ORDER_COMPUTE_FEES_SUCCESS: "QUICK_ORDER_COMPUTE_FEES_SUCCESS",
  QUICK_ORDER_COMPUTE_FEES_FAILED: "QUICK_ORDER_COMPUTE_FEES_FAILED",

  QUICK_ORDER_DELIVERY_METHOD: "QUICK_ORDER_DELIVERY_METHOD",
  QUICK_ORDER_ERROR: "QUICK_ORDER_ERROR",
  QUICK_ORDER_RESET_STATE: "QUICK_ORDER_RESET_STATE",

  QUICK_ORDER_SET_PREFERRED_DATE: "QUICK_ORDER_SET_PREFERRED_DATE",
  QUICK_ORDER_SET_CALENDAR_DATE: "QUICK_ORDER_SET_CALENDAR_DATE",

  QUICK_ORDER_SET_FREEBIE: "QUICK_ORDER_SET_FREEBIE",
  QUICK_ORDER_UPDATE_FREEBIE_QUANTITY: "QUICK_ORDER_UPDATE_FREEBIE_QUANTITY",
};

class Actions {
  static getProducts(store_uuid, params = false, mode) {
    return async (dispatch) => {
      try {
        dispatch({ type: ActionTypes.QUICK_ORDER_GET_PRODUCTS });
        let response = await Services.getProducts(store_uuid, params);
        dispatch({
          type: ActionTypes.QUICK_ORDER_GET_PRODUCTS_SUCCESS,
          payload: response.data,
          mode,
        });
      } catch (error) {
        dispatch({
          type: ActionTypes.QUICK_ORDER_GET_PRODUCTS_FAILED,
          payload: error,
        });
      }
    };
  }

  static setProductFilters(payload) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_SET_PRODUCT_FILTERS,
        payload,
      });
    };
  }

  static setSelectedProduct(params) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_SET_SELECTED_PRODUCT,
        payload: params,
      });
    };
  }

  static addToCart(params) {
    return async (dispatch) => {
      try {
        let product = await ProductServices.getProduct(params.uuid);
        let values = {
          ...params,
          stock: product.quantity,
        };
        dispatch({
          type: ActionTypes.QUICK_ORDER_ADD_TO_CART,
          payload: values,
        });
        // GTM trigger
        window.dataLayer.push({
          event: "addToCart",
          ecommerce: {
            add: {
              products: [
                {
                  id: params.uuid,
                  name: params.name,
                  price: params.price,
                  [`dimension${apiConfig.indexStoreID}`]: params.store,
                },
              ],
            },
          },
        });
      } catch (error) {
        console.log(error);
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again",
          })
        );
        dispatch({
          type: ActionTypes.QUICK_ORDER_GET_PRODUCTS_FAILED,
          payload: error,
        });
      }
    };
  }

  static updateVariant(value) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_UPDATE_VARIANT,
        payload: value,
      });
    };
  }

  static updateQuantity(value) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_UPDATE_QUANTITY,
        payload: value,
      });
    };
  }

  static removeFromCart(value, product) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_REMOVE_FROM_CART,
        payload: value,
        product,
      });

      // GTM trigger
      window.dataLayer.push({
        event: "removeFromCart",
        ecommerce: {
          click: {
            products: [
              {
                id: value.uuid,
                name: value.name,
                price: value.price,
                [`dimension${apiConfig.indexStoreID}`]: value.store,
              },
            ],
          },
        },
      });
    };
  }
  static resetState() {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_RESET_STATE,
      });
    };
  }

  static setSelectedCheckout(values) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_SET_SELECTED_CHECKOUT,
        payload: values,
      });
    };
  }

  static setSelectedPaymentChannel(value) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_SET_SELECTED_PAYMENT_CHANNEL,
        payload: value,
      });
    };
  }

  static ninjavanShippingFee(params) {
    return async (dispatch) => {
      try {
        dispatch({ type: ActionTypes.QUICK_ORDER_SHIPPING_FEE_LOADING_ON });
        let response = await ProductServices.ninjavanShippingFee(params);
        dispatch({
          type: ActionTypes.QUICK_ORDER_NINJAVAN_SHIPPING_FEE,
          payload: response.data.shipping_fee,
        });
        return response;
      } catch (e) {
        console.log(e);
        dispatch({ type: ActionTypes.QUICK_ORDER_SHIPPING_FEE_LOADING_OFF });
        dispatch({
          type: ActionTypes.QUICK_ORDER_NINJAVAN_SHIPPING_FEE,
          payload: e,
        });
      }
    };
  }

  static setDeliveryDetails(value) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_SET_DELIVERY_DETAILS,
        payload: value,
      });
    };
  }

  static checkout(value, paymentType) {
    return async (dispatch) => {
      try {
        dispatch({ type: ActionTypes.QUICK_ORDER_CHECKOUT_LOADING_ON });
        let response = await ProductServices.checkOut(value, paymentType);
        dispatch({
          type: ActionTypes.QUICK_ORDER_CHECKOUT_SUCCESS,
          payload: response.data,
        });
        return response;
      } catch (e) {
        console.log(e);
        dispatch({ type: ActionTypes.QUICK_ORDER_CHECKOUT_FAILED });
      }
    };
  }

  static computeFees(values) {
    return async (dispatch) => {
      try {
        dispatch({ type: ActionTypes.QUICK_ORDER_COMPUTE_FEES });
        let response = await ProductServices.computeFees(values);
        dispatch({
          type: ActionTypes.QUICK_ORDER_COMPUTE_FEES_SUCCESS,
          payload: response.data,
        });
        return response;
      } catch (e) {
        console.log(e);
        dispatch({ type: ActionTypes.QUICK_ORDER_COMPUTE_FEES_FAILED });
      }
    };
  }

  static setDeliveryMethod(value) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_DELIVERY_METHOD,
        payload: value,
      });
    };
  }
  static setError(value) {
    return async (dispatch) => {
      dispatch({ type: ActionTypes.QUICK_ORDER_ERROR, payload: value });
    };
  }

  static buxCheckout(value) {
    return async (dispatch) => {
      try {
        dispatch({ type: ActionTypes.QUICK_ORDER_CHECKOUT_LOADING_ON });
        let response = await ProductServices.buxCheckout(value);
        dispatch({
          type: ActionTypes.QUICK_ORDER_CHECKOUT_SUCCESS,
          payload: response.data,
        });
        return response;
      } catch (e) {
        console.log(e);
        dispatch({ type: ActionTypes.QUICK_ORDER_CHECKOUT_FAILED });
      }
    };
  }

  static setPreferredDate(value) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_SET_PREFERRED_DATE,
        payload: value,
      });
    };
  }

  static setCalendarDate(value) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_SET_CALENDAR_DATE,
        payload: value,
      });
    };
  }

  static setFreebie(value) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_SET_FREEBIE,
        payload: value,
      });
    };
  }

  static updateFreebieQuantity(value) {
    return async (dispatch) => {
      dispatch({
        type: ActionTypes.QUICK_ORDER_UPDATE_FREEBIE_QUANTITY,
        payload: value,
      });
    };
  }
}
export default Actions;
