import axios from "axios";
import apiConfig from "../../common/apiConfig";

async function buxStatus(value) {
  try {
    let response = await axios.get(`${apiConfig.apiURL}/api/kyc/?id=${value}`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    });
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function getStore() {
  try {
    let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    let response = await axios.get(`${apiConfig.apiURL}/api/dashboard/store/`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      },
      params: { email: user.id }
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
}

async function getPayments(value) {
  try {
    let response = await axios.get(
      `${apiConfig.apiURL}/api/payment_method/store/${value}`
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function addPayment(value) {
  let paramsFormData = new FormData();

  const valKeys = Object.keys(value);
  for (let key in valKeys) {
    if (
      value[valKeys[key]] === "" ||
      value[valKeys[key]] === "null" ||
      value[valKeys[key]] === null ||
      value[valKeys[key]] === "undefined"
    ) {
      paramsFormData.append(valKeys[key], "");
    } else {
      paramsFormData.append(valKeys[key], value[valKeys[key]]);
    }
  }

  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/payment_method/`,
      paramsFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function updatePayment(value) {
  let paramsFormData = new FormData();

  const valKeys = Object.keys(value);
  for (let key in valKeys) {
    if (
      value[valKeys[key]] === "" ||
      value[valKeys[key]] === "null" ||
      value[valKeys[key]] === null ||
      value[valKeys[key]] === "undefined"
    ) {
      paramsFormData.append(valKeys[key], "");
    } else {
      paramsFormData.append(valKeys[key], value[valKeys[key]]);
    }
  }

  try {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/payment_method/${value.uuid}/`,
      paramsFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function deletePayment(value) {
  try {
    let response = await axios.delete(
      `${apiConfig.apiURL}/api/payment_method/${value.uuid}/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function goToBux(value) {
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/bux/login/`,
      { id: value },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response;
  } catch (error) {
    throw error.response;
  }
}

async function getCODWalletServices() {
  try {
    let response = await axios.get(`${apiConfig.apiURL}/api/bux/subwallet/`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    });
    return response;
  } catch (error) {
    throw error.response;
  }
}

export {
  buxStatus,
  getStore,
  getPayments,
  addPayment,
  updatePayment,
  deletePayment,
  goToBux,
  getCODWalletServices
};
