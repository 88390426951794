import React from "react";

const SuperLink = props => {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ padding: "50px 15vw" }}>
        <img
          src="https://s3-ats-migration-test.s3.eu-west-3.amazonaws.com/test.jpg"
          style={{ width: "100%", height: "auto" }}
        />

        <img
          src="https://euwest3-test.s3.eu-west-3.amazonaws.com/create_shop_logo.png"
          style={{ width: "100%", height: "auto", marginTop: "10vh" }}
        />
      </div>
    </div>
  );
};

export default SuperLink;
