import React from "react";
import { makeStyles, Dialog, Button, Link } from "@material-ui/core";
import logo from "../../assets/images/sentro_logomark1.png";
import history from "../../store/history";
import Path from "../../Paths";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DashboardActions from "./dashboardActions";

const UpdatePasswordNotice = props => {
  const useStyles = makeStyles(theme => ({
    form: {
      padding: "30px 65px",
      backgroundColor: "#fff",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        padding: "30px 10px"
      }
    },
    form2: {
      padding: "30px 106px 50px",
      backgroundColor: "#fff",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        padding: "15px 10px 30px"
      }
    },
    layout: {
      display: "flex",
      justifyContent: "center"
    },
    logo: {
      width: 100,
      height: 100
    },
    header: {
      fontSize: 28,
      color: "#2b2d33",
      fontWeight: "bold"
    },
    label: {
      fontSize: 16,
      color: "#54575f"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const openSkipModal = useSelector(state => state.dashboard.skipModal);
  const data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const is_password_skip = data.skip_password;
  const current_date = new Date();
  const password_change = is_password_skip
    ? data.password_update
    : data.password_change;
  const formattedDate = new Date(password_change);
  const numOfDays = 90; //number of days
  const updatePasswordDate = formattedDate.setDate(
    formattedDate.getDate() + numOfDays
  );
  const parseCurrentDate = Date.parse(current_date);

  function showModal() {
    if (parseCurrentDate >= updatePasswordDate) {
      return true;
    } else {
      return false;
    }
  }

  function handleUpdatePassword() {
    history.push(Path.ACCOUNT_SETTINGS_PASSWORD);
  }
  function handleSkip() {
    let params = {
      password_update: moment(current_date).format(),
      skip_password: true
    };
    dispatch(DashboardActions.skipUpdatePassword(params));
  }
  function handleProceed() {
    dispatch(DashboardActions.closeSkipModal());
  }

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={showModal()}>
        <div className={classes.form}>
          <div className={classes.layout}>
            <img src={logo} className={classes.logo} />
          </div>
          <div className={classes.header}>Let's keep your account secure</div>
          <div style={{ paddingTop: 10 }}>
            <div className={classes.label}>
              To ensure that your account is safe, we encourage you to update
              your password regularly.
            </div>
          </div>
          <div style={{ paddingTop: 50 }}>
            <Button
              name="update-password"
              disableElevation
              variant="contained"
              color="primary"
              style={{ width: 200 }}
              onClick={handleUpdatePassword}
            >
              Update Password
            </Button>
          </div>
          <div style={{ paddingTop: 30 }}>
            <Link
              onClick={handleSkip}
              style={{ fontSize: 12, color: "#909196", fontWeight: 600 }}
            >
              Skip Password Update
            </Link>
          </div>
        </div>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={openSkipModal}>
        <div className={classes.form2}>
          <div className={classes.layout}>
            <img src={logo} className={classes.logo} />
          </div>
          <div className={classes.header}>Update Password Skipped</div>
          <div style={{ paddingTop: 10 }}>
            <div className={classes.label}>
              We will ask you again to update your password after 90 day(s). If
              you wish to change your password soon, please go to your profile
              settings.
            </div>
          </div>
          <div style={{ paddingTop: 40 }}>
            <Button
              onClick={handleProceed}
              disableElevation
              variant="contained"
              color="primary"
            >
              OK, proceed to dashboard
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdatePasswordNotice;
