import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Field, change } from "redux-form";
import ReduxRadioField from "../../../reduxFields/radioField";
import ReduxImageField from "../../../reduxFields/imageField";
import ReduxSelectField from "../../../reduxFields/selectField";
import ReduxTextField from "../../../reduxFields/textField";
import { bankListing } from "../../../websiteBuilder/forms/bankContants";
import validations from "../../../common/validations";
import { useLocation } from "react-router-dom";
import { Cancel } from "@material-ui/icons";
import { useMediaQuery } from "@material-ui/core";
const Form = props => {
  const useStyles = makeStyles(theme => ({}));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const values = useSelector(state => state.form.account_form?.values);
  const account_type_choices = [
    {
      value: 0,
      display: <div style={{ fontSize: 14, color: "#54575f" }}>Bank</div>
    },
    {
      value: 1,
      display: <div style={{ fontSize: 14, color: "#54575f" }}>PayMaya</div>
    },
    {
      value: 2,
      display: <div style={{ fontSize: 14, color: "#54575f" }}>PayPal</div>
    }
  ];

  const bank_choices = () => {
    const list = [];
    for (let i in bankListing) {
      list.push({
        display: bankListing[i].name,
        value: bankListing[i].name
      });
    }
    return list;
  };

  return (
    <div>
      <div>
        <div className="d-flex">
          <div style={{ fontSize: 14, color: "#54575f" }}>Account Type</div>
          <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
        </div>
        <Field
          id="account_type"
          name="account_type"
          color="primary"
          className="d-flex mt-3"
          component={ReduxRadioField}
          disabled={!location.pathname.includes("add")}
          choices={account_type_choices}
        />
      </div>
      {values?.account_type !== "2" ? (
        <div className="pt-3">
          <div className="d-flex">
            <div style={{ fontSize: 14, color: "#54575f" }}>QR Code</div>
          </div>
          {typeof values?.qr_code === "string" && values?.qr_code !== "" ? (
            <div
              className="position-relative"
              style={{ width: 90, height: 90 }}
            >
              <Cancel
                color="primary"
                className="position-absolute"
                style={{ right: 0 }}
                onClick={
                  isMobile && !props.edit
                    ? null
                    : () => dispatch(change("account_form", "qr_code", ""))
                }
              />
              <img src={values?.qr_code} alt="" width="90" height="90" />
            </div>
          ) : (
            <Field
              id="qr_code"
              name="qr_code"
              component={ReduxImageField}
              height={90}
              width={90}
            />
          )}
        </div>
      ) : (
        ""
      )}

      {values?.account_type === "0" ? (
        <div className="pt-4">
          <div className="d-flex">
            <div style={{ fontSize: 14, color: "#54575f" }}>Bank Name</div>
            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>
          <div className={`${isMobile ? "w-100" : "w-50"}`}>
            <Field
              id="bank_name"
              name="bank_name"
              margin="dense"
              variant="outlined"
              className="w-100"
              color="secondary"
              validate={[validations.required]}
              component={ReduxSelectField}
              choices={bank_choices()}
              disabled={
                isMobile && !props.edit && !location.pathname.includes("add")
              }
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {values?.account_type !== "2" ? (
        <div className="pt-3">
          <div className="d-flex">
            <div style={{ fontSize: 14, color: "#54575f" }}>Account Name</div>
            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>
          <div className={`${isMobile ? "w-100" : "w-50"}`}>
            <Field
              id="account_name"
              name="account_name"
              margin="dense"
              variant="outlined"
              className="w-100"
              color="secondary"
              inputProps={{ maxlength: 64 }}
              validate={[validations.required]}
              component={ReduxTextField}
              disabled={
                isMobile && !props.edit && !location.pathname.includes("add")
              }
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {values?.account_type === "0" ? (
        <div className="pt-3">
          <div className="d-flex">
            <div style={{ fontSize: 14, color: "#54575f" }}>Account Number</div>
            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>
          <div className={`${isMobile ? "w-100" : "w-50"}`}>
            <Field
              id="account_number"
              name="account_number"
              margin="dense"
              variant="outlined"
              className="w-100"
              color="secondary"
              inputProps={{ maxlength: 20 }}
              validate={[validations.required, validations.numeric]}
              component={ReduxTextField}
              disabled={
                isMobile && !props.edit && !location.pathname.includes("add")
              }
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {values?.account_type === "2" ? (
        <div className="pt-3">
          <div className="d-flex">
            <div style={{ fontSize: 14, color: "#54575f" }}>Payment Link</div>
            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>
          <div className={`${isMobile ? "w-100" : "w-50"}`}>
            <Field
              id="payment_link"
              name="payment_link"
              margin="dense"
              variant="outlined"
              className="w-100"
              color="secondary"
              inputProps={{ maxlength: 64 }}
              validate={[validations.required]}
              component={ReduxTextField}
              disabled={
                isMobile && !props.edit && !location.pathname.includes("add")
              }
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {values?.account_type === "1" ? (
        <div className="pt-3">
          <div className="d-flex">
            <div style={{ fontSize: 14, color: "#54575f" }}>Mobile Number</div>
            <span style={{ color: "#c24a39", marginLeft: 2 }}>*</span>
          </div>
          <div className={`${isMobile ? "w-100" : "w-50"}`}>
            <Field
              id="mobile_number"
              name="mobile_number"
              margin="dense"
              variant="outlined"
              className="w-100"
              color="secondary"
              validate={[
                validations.required,
                validations.mobileNumber,
                validations.mobileNumberValidation,
                validations.mobileNumberValidation2
              ]}
              inputProps={{ maxlength: 11 }}
              component={ReduxTextField}
              disabled={
                isMobile && !props.edit && !location.pathname.includes("add")
              }
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Form;
