import React from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Button
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Utils from "../../../common/utils";
import { useSelector } from "react-redux";
import tags from "../../../../assets/images/tags.svg";
import VoucherModal from "./voucherModal";
export default function SummaryForm(props) {
  const useStyles = makeStyles(theme => ({
    summary: {
      height: "fit-content",
      width: "35%",
      marginTop: "5vh",
      marginLeft: "2vw",
      backgroundColor: "#fff",
      borderRadius: 4,
      padding: 18,
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "8px 8px 0px 0px",
        width: "auto",
        position: "fixed",
        zIndex: 3,
        margin: 0,
        filter: "drop-shadow(0px -4px 16px rgba(0, 0, 0, 0.12))"
      }
    },
    voucherLink: {
      color: "#54575f",
      fontSize: 14,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: 500
    },
    columnHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "0 0 2vh"
    },
    column: {
      borderBottom: "none",
      fontSize: 12,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0"
    },
    columnFooter: {
      borderTop: "1px dashed #d1d5dd",
      fontSize: 16,
      fontWeight: 600,
      color: "#3e4149",
      border: "none",
      padding: "2vh 0 0"
    }
  }));

  const classes = useStyles();
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const product_selected = useSelector(
    state => state.buyerDashboard.selected_checkout.items
  );
  const delivery_details = useSelector(
    state => state.buyerDashboard.delivery_details
  );
  const vouchers = useSelector(state => state.buyerDashboard.voucher_list);
  const voucher_discount = useSelector(
    state => state.buyerDashboard.voucher_discount
  );
  const amount = useSelector(
    state => state.buyerDashboard.selected_checkout.amount
  );
  const subtotal = useSelector(
    state => state.buyerDashboard.selected_checkout.subtotal
  );
  return (
    <>
      <div id="summary" className={classes.summary}>
        <Table style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.columnHeader}>
                Summary
              </TableCell>
              <TableCell align="right" className={classes.columnHeader}>
                <Link
                  id="voucher_link"
                  className={classes.voucherLink}
                  onClick={() => setOpenVoucher(true)}
                  style={{
                    display:
                      product_selected.length && vouchers.length ? "" : "none"
                  }}
                >
                  <img
                    src={tags}
                    width="15"
                    height="15"
                    style={{ marginRight: 5 }}
                  />
                  Vouchers
                </Link>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.column} align="left">
                Items
              </TableCell>
              <TableCell className={classes.column} align="right">
                {parseFloat(
                  product_selected.reduce((total, object) => {
                    return parseFloat(total) + parseFloat(object.quantity);
                  }, 0)
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.column} align="left">
                Subtotal
              </TableCell>
              <TableCell className={classes.column} align="right">
                ₱ {Utils.commafy(subtotal)}
              </TableCell>
            </TableRow>
            {delivery_details.shipping && delivery_details.delivery_detail ? (
              <TableRow id="delivery_fee">
                <TableCell className={classes.column} align="left">
                  Delivery fee
                </TableCell>
                <TableCell className={classes.column} align="right">
                  ₱ {Utils.commafy(delivery_details.shipping_fee)}
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}

            {voucher_discount ? (
              <TableRow id="voucher_discount">
                <TableCell className={classes.column} align="left">
                  Voucher Applied
                </TableCell>
                <TableCell className={classes.column} align="right">
                  {`- ₱${Utils.commafy(voucher_discount)}`}
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            <TableRow>
              <TableCell className={classes.columnFooter} align="left">
                Total
              </TableCell>
              <TableCell className={classes.columnFooter} align="right">
                ₱ {Utils.commafy(amount - voucher_discount)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div style={{ marginTop: "3vh" }}>
          <Button
            fullWidth
            disableElevation
            color="primary"
            variant="contained"
            className={classes.btnCheckout}
            onClick={props.proceed_to_checkout}
            style={{ height: 40 }}
            disabled={props.disabled}
          >
            {props.loading ? (
              <div className="d-flex my-5 mr-3">
                <div
                  className="spinner-border mx-auto"
                  role="status"
                  style={{
                    color: "white",
                    fontSize: 12,
                    height: 25,
                    width: 25
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              ""
            )}
            Proceed to checkout
          </Button>
        </div>
        <VoucherModal open={openVoucher} setOpen={setOpenVoucher} />
      </div>
    </>
  );
}
