import React from "react";
import {
  Paper,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Zoom
} from "@material-ui/core";
import history from "../../../store/history";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import { setAddProductView } from "../websiteBuilderActions";
import { closePublishDialog } from "../websiteBuilderActions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import apiConfig from "../../../common/apiConfig";
import Path from "../../../Paths";
const Transition = props => <Zoom {...props} />;
let PublishedShopSuccess = props => {
  let dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const successPublish = useSelector(
    state => state.webBuilder.successPublishShop
  );
  const storeInfo = useSelector(state => state.webBuilder.storeInfo);
  const useStyles = makeStyles(theme => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: 450,
      padding: 20
    },
    content: { width: "100%", display: "flex", justifyContent: "center" },
    icon: {
      width: "100%",
      textAlign: "center",
      color: "#38b99f",
      fontSize: 100
    },
    name: { fontSize: 16, fontWeight: 600, color: "#002c42" },
    site: { fontSize: 14, color: "#54575f" },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 10
    },
    btn: {
      height: 40,
      fontSize: 12,
      flex: 1,
      padding: "5px 10px",
      width: "100%"
    }
  }));
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="xs"
      TransitionComponent={Transition}
    >
      <div className="p-4 d-flex align-items-center justify-content-center">
        <div className="text-center">
          <CheckCircleIcon className={classes.icon} />
          <div className="py-3" style={{ fontSize: 18, fontWeight: 600 }}>
            {storeInfo && storeInfo.site
              ? "Your shop is now online!"
              : "Your shop is now set up!"}
          </div>
          {storeInfo && storeInfo.site ? (
            <div
              style={{
                fontSize: 16,
                fontWeight: 600,
                textTransform: "capitalize"
              }}
            >
              {storeInfo.name}
            </div>
          ) : (
            ""
          )}

          <div className="py-2" style={{ fontSize: 14 }}>
            {storeInfo && storeInfo.site
              ? `${storeInfo.site}.sentro.ph`
              : "Add more products, customize themes, and set delivery options from your Sentro dashboard."}
          </div>
        </div>
      </div>
      {storeInfo && storeInfo.site ? (
        <div className={`${isMobile ? "d-block" : "d-flex"} px-4 pb-4 pt-3`}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className={` ${isMobile ? "w-100" : "w-50 mr-2"}`}
            onClick={() => {
              window.open(
                `${storeInfo.shop_type === "order" ? "order" : "shop"}/${
                  storeInfo.site
                }`
              );
              history.push(Path.DASHBOARD);
            }}
          >
            View online shop
          </Button>
          <Button
            disableElevation
            size="small"
            variant="contained"
            color="primary"
            className={` ${isMobile ? "w-100 mt-2" : "w-50"}`}
            onClick={() => history.push(Path.DASHBOARD)}
          >
            Go to dashboard
          </Button>
        </div>
      ) : (
        <div className="px-4 pb-4 pt-3">
          <Button
            fullWidth
            disableElevation
            size="small"
            variant="contained"
            color="primary"
            onClick={() => history.push(Path.DASHBOARD)}
          >
            Go to dashboard
          </Button>
        </div>
      )}
    </Dialog>
    // <Dialog open={successPublish}>
    //   <DialogTitle
    //     style={{ display: "flex", justifyContent: "start", padding: 0 }}
    //   >
    //     <IconButton onClick={() => dispatch(closePublishDialog())}>
    //       <CloseIcon style={{ color: "#f97663" }} />
    //     </IconButton>
    //   </DialogTitle>
    //   <DialogContent style={{ padding: "1em" }}>
    //     <div className={classes.form} >
    //       <div
    //         style={{
    //           textAlign: "center",
    //           fontSize: 14,
    //           color: "#54575f",
    //           marginBottom: 10
    //         }}
    //       >
    //         Your shop is now published!
    //       </div>
    //       <CheckCircleIcon className={classes.icon} />
    //       <div style={{ textAlign: "center" }}>
    //         <div className={classes.name}>{storeInfo ? storeInfo.name: ""}</div>
    //         <div className={classes.site}>{storeInfo ? storeInfo.site: ""}.sentro.ph</div>

    //         <div style={{ marginTop: 50, color: "#54575f", fontWeight: 600 }}>
    //           Do more with your shop!
    //         </div>
    //       </div>
    //       <div style={{ marginTop: 10 }} className="d-flex">
    //         <div>
    //           <div style={{ color: "#54575f", fontSize: 12, flex: 2,
    //           marginBottom: "1em" }}>
    //             Have more items to sell?
    //           </div>
    //           <Button
    //             id="add-products"
    //             className="w-100"
    //             onClick={() => {
    //               dispatch(closePublishDialog());
    //               dispatch(setAddProductView(true))
    //               history.push("/products/add")
    //             }}
    //             style={{
    //               height: 40,
    //               fontSize: 12,
    //               flex: 1,
    //               padding: "5px 10px"
    //             }}
    //             variant="outlined"
    //             color="primary"
    //           >
    //             Add Products
    //           </Button>
    //         </div>
    //         <div className="ml-auto">
    //           <div style={{ color: "#54575f", fontSize: 12, flex: 2,
    //           marginBottom: "1em" }}>
    //             Want to customize your shop?
    //           </div>
    //           <Button
    //             fullWidth
    //             onClick={() => {
    //               dispatch(closePublishDialog());
    //               history.push("/webbuilder/shop_style")
    //             }}
    //             id="styleShop"
    //             className={classes.btn}
    //             variant="outlined"
    //             color="primary"
    //           >
    //             Style Shop
    //           </Button>
    //         </div>
    //       </div>
    //     </div>
    //   </DialogContent>
    // </Dialog>
  );
};
export default PublishedShopSuccess;
