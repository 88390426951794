import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  MenuItem,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
  useMediaQuery,
  useTheme,
  Button,
  Badge,
  Popover
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";

import defaultSRC from "./defaultResources";
import history from "./../../../../store/history";
import { useSelector } from "react-redux";
import AppBarTemplate from "./appBar/appBarTemplate";
import SentroButton from "../../sentroButton";
import Path from "../../../../Paths";
import defaultColor from "./colors";
const PreviewHeader = props => {
  let storeValue = useSelector(state => state.store.store);
  let storeInfoValue = props.storeInfo;
  let storeInfo = props.isPreview ? storeInfoValue : storeValue;
  let checkout_active = storeValue.checkout_active;
  let color = storeInfo
    ? storeInfo.color === "null"
      ? null
      : storeInfo.color
    : null;
  let selectedColor = color
    ? color.includes("/")
      ? defaultColor[storeInfo ? color : "funBlue"].colorA
      : defaultColor[storeInfo ? color : "funBlue"].color
    : defaultSRC[storeInfo ? storeInfo.template : "Classic"].appColor;

  let selectedButtonColor = color
    ? color.includes("/")
      ? defaultColor[storeInfo ? color : "funBlue"].colorA
      : defaultColor[storeInfo ? color : "funBlue"].color
    : defaultSRC[storeInfo ? storeInfo.template : "Classic"].appButtonColor;

  function headerFont() {
    if (storeInfo) {
      if (storeInfo.font === null || storeInfo.font === undefined) {
        return defaultSRC[storeInfo ? storeInfo.template : "Classic"]
          .headerFont;
      } else {
        return storeInfo.font.title;
      }
    } else {
      return defaultSRC[storeInfo ? storeInfo.template : "Classic"].headerFont;
    }
  }

  const HeaderStyles = makeStyles(theme => ({
    desktop: {
      display: "none",
      flex: 4,
      justifyContent: "flex-end",
      marginRight: "3vw",
      [theme.breakpoints.up("md")]: {
        display: "flex"
      }
    },
    storeName: {
      fontFamily:
        defaultSRC[storeInfo ? storeInfo.template : "Classic"].headerFont,
      flexGrow: 1,
      paddingLeft: 10,
      color: selectedColor,
      fontWeight: 500,
      fontSize: 24,
      cursor: "pointer",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: 16
      }
    },
    mobile: {
      display: "none",
      justifyContent: "flex-end",

      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },
    dialog: {
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    },

    buttons: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "0px 100px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0px 20px"
      }
    }
  }));
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = HeaderStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let productList = useSelector(state => state.store.items);

  const handleClickMenu = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = e => {
    setAnchorEl(null);
    setOpen(false);
  };

  function handleClickOpen() {
    setOpen(true);
  }

  let isPreview = props.isPreview;
  let secondaryFont =
    defaultSRC[storeInfo ? storeInfo.template : "Classic"].secondaryFont;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <AppBar
        style={{
          position: "relative",
          backgroundColor:
            defaultSRC[storeInfo ? storeInfo.template : "Classic"]
              .appBarBackground
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <AppBarTemplate
            isPreview={props.isPreview}
            storeInfo={props.storeInfo}
          />
          <Typography
            // onClick={() => history.push(`/shop/${storeInfo.site}`)}
            variant="h6"
            className={classes.storeName}
          >
            {props.storeInfo
              ? props.storeInfo.name
                ? props.storeInfo.name
                : "Your Shop's Name"
              : "Your Shop's Name"}
          </Typography>

          <Box className={classes.desktop}>
            {/* <Link to="/shop/pangpang/products">Products</Link> */}

            <SentroButton
              id="home_button"
              onClick={
                isPreview
                  ? null
                  : () => {
                      history.push(`/shop/${storeInfo.site}`);
                    }
              }
              label="Home"
              style={{
                fontSize: 16,
                fontFamily: secondaryFont,
                color: selectedColor,
                marginTop: 0,
                fontWeight: "normal",
                padding: "0 30px"
              }}
            />

            <SentroButton
              id="about_button"
              onClick={
                isPreview
                  ? null
                  : () => {
                      history.push(`/shop/${storeInfo.site}/about_us`);
                    }
              }
              label="About Us"
              style={{
                fontSize: 16,
                fontFamily: secondaryFont,
                color: selectedColor,
                marginTop: 0,
                fontWeight: "normal",
                padding: "0 30px"
              }}
            />

            <SentroButton
              id="products_button"
              onClick={
                isPreview
                  ? null
                  : () => {
                      history.push(`/shop/${storeInfo.site}/products`);
                    }
              }
              label="Products"
              style={{
                fontSize: 16,
                fontFamily: secondaryFont,
                color: selectedColor,
                marginTop: 0,
                fontWeight: "normal",
                padding: "0 30px"
              }}
            />

            <Badge color="primary" badgeContent={productList.length}>
              <SentroButton
                id="cart_button"
                onClick={
                  isPreview
                    ? null
                    : () => {
                        history.push(`/shop/${storeInfo.site}/cart`);
                      }
                }
                label="Cart"
                style={{
                  fontSize: 16,
                  fontFamily: secondaryFont,
                  color: selectedColor,
                  marginTop: 0,
                  fontWeight: "normal",
                  padding: "0 30px"
                }}
              />
            </Badge>
          </Box>

          <Box className={classes.mobile}>
            <IconButton onClick={handleClickOpen} color="secondary">
              <MoreIcon
                style={{
                  color:
                    defaultSRC[storeInfo ? storeInfo.template : "Classic"]
                      .appColor
                }}
              />
            </IconButton>

            <Dialog
              className={classes.dialog}
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
            >
              <div
                style={{
                  padding: 20,
                  marginTop: "20vh",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <div className={classes.buttons}>
                  <SentroButton
                    id="home_button"
                    onClick={
                      isPreview
                        ? () => {
                            props.setOnClick("Home");
                            handleClose();
                          }
                        : () => {
                            history.push(`/shop/${storeInfo.site}`);
                          }
                    }
                    fullWidth
                    variant="contained"
                    label="Home"
                    style={{
                      margin: "10px 0",
                      backgroundColor: selectedButtonColor
                    }}
                  />

                  <SentroButton
                    id="about_button"
                    onClick={
                      isPreview
                        ? ""
                        : () => {
                            history.push(`/shop/${storeInfo.site}/about`);
                          }
                    }
                    fullWidth
                    variant="contained"
                    label="About Us"
                    style={{
                      margin: "10px 0",
                      backgroundColor: selectedButtonColor
                    }}
                  />

                  <SentroButton
                    id="products_button"
                    onClick={
                      isPreview
                        ? () => {
                            props.setOnClick("Products");
                            handleClose();
                          }
                        : () => {
                            history.push(`/shop/${storeInfo.site}/products`);
                          }
                    }
                    fullWidth
                    variant="contained"
                    label="Products"
                    style={{
                      margin: "10px 0",
                      backgroundColor: selectedButtonColor
                    }}
                  />

                  <SentroButton
                    id="cart_button"
                    onClick={
                      isPreview
                        ? null
                        : () => {
                            history.push(`/shop/${storeInfo.site}/cart`);
                          }
                    }
                    fullWidth
                    variant="contained"
                    label="Cart"
                    style={{
                      margin: "10px 0",
                      backgroundColor: selectedButtonColor
                    }}
                  />

                  <SentroButton
                    id="close_button"
                    onClick={handleClose}
                    // variant="contained"
                    fullWidth
                    label="Close"
                    style={{
                      margin: "10px 0",
                      color: selectedButtonColor
                    }}
                  />
                </div>
              </div>
            </Dialog>
          </Box>
        </Toolbar>
        {/* {checkout_active ? (
          " "
        ) : (
          <div
            style={{
              fontSize: 14,
              textAlign: "center",
              backgroundColor: "grey",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 5,
              paddingBottom: 5,
              fontWeight: 500
            }}
          >
            ADVISORY: We are currently undergoing system maintenance. Online
            purchase is currently deactivated. Kindly coordinate with us
            directly through any of our contact details below if you want to
            inquire or purchase anything. Thank you for your understanding and
            continued support.
          </div>
        )} */}
      </AppBar>
    </div>
  );
};

export default PreviewHeader;
