import React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider } from "@material-ui/styles";
import sentroTheme from "./sentroTheme";
import ImageUploader from "react-images-upload";
import "./antdesign.css";

const SentroFileField = props => {
  const { loading, name, preview } = props;
  let imgPreview =
    props.preview && props.preview !== "null" ? props.preview : null;
  const UseStyles = makeStyles(theme => ({
    uploadBorder: {
      padding: preview === "" || preview === "null" ? 0 : "50px 0",
      border: "2px dashed #F97663",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      borderRadius: 5,
      backgroundImage: `url(${
        preview === "" || preview === "null" ? "" : [imgPreview]
      })`
    },
    uploadLabel: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      fontSize: 12,
      color: "#f97663",
      margin: 10
    },
    defaultImg: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 10
    }
  }));

  const classes = UseStyles();
  const containerStyle = {
    padding: 0,
    boxShadow: "none",
    background: "none"
  };
  const errorStyle = {
    width: "100%",
    textWrap: "wrap",
    padding: 0,
    margin: 10,
    wordBreak: "break-all"
  };
  const buttonStyle = {
    backgroundColor: "#fff",
    color: "#f97663",
    borderRadius: 5,
    fontWeight: 600,
    border: "1px solid #f97663"
  };
  const buttonText =
    preview === "" || preview === "null"
      ? "Upload photo"
      : "Click to replace image";
  const DEFAULT_IMAGE =
    "https://sentro-store-bucker.s3.us-east-2.amazonaws.com/Highlighted+Image+Icon.png";
  const imgDefault =
    preview === "" || preview === "null" ? [DEFAULT_IMAGE] : "";

  return (
    <ThemeProvider theme={sentroTheme}>
      {name === loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "60px 0",
            border: "2px dashed #f97663"
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className={classes.uploadBorder}>
            <div className={classes.defaultImg}>
              <img src={imgDefault} style={{ width: 90 }} />
            </div>

            <ImageUploader
              withIcon={false}
              singleImage={true}
              withPreview={false}
              withLabel={false}
              fileContainerStyle={containerStyle}
              errorStyle={errorStyle}
              buttonStyles={buttonStyle}
              fileSizeError={<div>File is too big</div>}
              buttonText={buttonText}
              onChange={e => props.onChange(e[0])}
              imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
              maxFileSize={5242880}
            />
            <div className={classes.uploadLabel}>
              {preview === "" || preview === "null"
                ? "Upload below 5MB | .JPEG | .PNG | .GIF"
                : ""}
            </div>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default SentroFileField;
