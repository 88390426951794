import React from "react";
import { Alert } from "antd";
import "antd/dist/antd.css";
import { hideAlert } from "./websiteBuilderActions";
import { useSelector, useDispatch } from "react-redux";

function SentroAlert(props) {
  const dispatch = useDispatch();
  function hideError() {
    dispatch(hideAlert());
  }
  let error = useSelector(state => state.webBuilder.error);
  let message = [];

  if (error) {
    Object.keys(error).map((item, key) => {
      let msg = error[item];
      if (Array.isArray(msg)) {
        msg.map((its, key) => {
          message.push({ error: `${item} : ${its}` });
        });
      }
    });
  } else {
    message = [];
  }
  //  {landline: array}

  //   if (Array.isArray(error)) {
  //     console.log(error);
  //   }

  return message.map((item, key) => {
    return (
      <Alert
        style={{
          // display: common.showAlert ? "block" : "none",
          padding: 20,
          marginTop: 20,
          marginBottom: 20
        }}
        closable
        onClose={() => hideError()}
        message={item.error}
        type={"error"}
      />
    );
  });
}

export default SentroAlert;
