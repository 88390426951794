import React from "react";
import { makeStyles, Chip, IconButton } from "@material-ui/core";
import imgPlaceholder from "../../../../../../assets/images/imgPlaceholder2.svg";
import { useSelector, useDispatch } from "react-redux";
import { editProduct } from "../websiteBuilderActions";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { usePriceRangeDisplay } from "../../../../../hooks/priceDisplay";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { useHistory } from "react-router";
const MobileProductCard = props => {
  const history = useHistory();
  const { item, handleEditProduct, handleDeleteProduct, open, setOpen } = props;
  const dispatch = useDispatch();
  const products = useSelector(
    state => state.mobileDashboard.products.products.results
  );
  const currentStoreCreated = useSelector(
    state => state.mobileDashboard.storeInfo
  );
  const filters = useSelector(state => state.mobileDashboard.products.filters);
  function productImage() {
    if (item.gallery) {
      if (item.gallery.length) {
        if (item.photo_primary === null || item.photo_primary === "") {
          return item.gallery[0].image;
        } else {
          if (
            item.gallery[item.photo_primary] === "undefined" ||
            item.gallery[item.photo_primary] === undefined
          ) {
            return item.gallery[0].image;
          } else {
            return item.gallery[item.photo_primary].image;
          }
        }
      } else {
        return imgPlaceholder;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return imgPlaceholder;
      }
    }
  }

  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      backgroundColor: "#fff",
      border: "1px solid #d1d5dd",
      borderRadius: 4
    },
    img: {
      width: "auto",
      borderRadius: 5,
      [theme.breakpoints.down("sm")]: {
        height: 100
      },
      [theme.breakpoints.down("xs")]: {
        height: 75
      }
    },
    label: {
      fontWeight: 600,
      color: "#2b2d32"
    }
  }));

  const classes = useStyles();
  const imageList = [];
  const numOfFeaturedProducts = [];
  const [missingProductInfo, setMissingProductInfo] = React.useState([]);
  const handleFeatured = async featured => {
    imageList.length = 0;
    const variants = item.variant_groups;
    for (let i in products) {
      if (products[i].featured) {
        numOfFeaturedProducts.push(products[i]);
      }
    }
    if (numOfFeaturedProducts.length >= 4 && featured) {
      setOpen(true);
    } else {
      for (let i in item["gallery"]) {
        imageList.push(item["gallery"][i].image);
      }
      await dispatch(
        editProduct(
          {
            ...item,
            store: currentStoreCreated ? currentStoreCreated.uuid : "",
            featured: featured
          },
          variants,
          imageList,
          filters
        )
      );
    }
  };

  React.useEffect(() => {
    missingProductInfo.length = 0;
    let array = [item.images ? item.images : item.gallery, item.description];

    for (let i in array) {
      if (
        array[i] === "" ||
        array[i] === null ||
        array[i].length === 0 ||
        array[i] === "<p><br></p>"
      ) {
        missingProductInfo.push(array[i]);
      }
    }
  }, [item]);

  const [priceDisplay] = usePriceRangeDisplay(item);

  return (
    <div className={`${classes.root} mb-2`}>
      <div className="d-flex col-12 p-2">
        <div className="col-sm-2 col-3 px-0">
          <img src={productImage()} alt="product-img" className={classes.img} />
        </div>
        <div
          className="col-sm-9 col-8 px-0"
          style={{ position: "relative" }}
          onClick={() => history.push(`/products/${item.uuid}`)}
        >
          <div className="d-flex justify-content-between">
            <div
              className={classes.label}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical"
              }}
            >
              {item.name}
            </div>
          </div>
          <div className="d-flex" style={{ position: "absolute", bottom: 0 }}>
            <div className={`${classes.label} d-flex`}>₱ {priceDisplay}</div>
            <div className="ml-4" style={{ color: "#54575f" }}>
              Stock:{" "}
              {item.always_available ? (
                "∞"
              ) : item.quantity <= 5 ? (
                <span style={{ color: "red" }}>{item.quantity}</span>
              ) : (
                item.quantity
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-1 col-1 px-0">
          <div className="d-flex">
            {item.featured ? (
              <BookmarkIcon
                onClick={() => handleFeatured(false)}
                style={{ color: "#f97663" }}
              />
            ) : (
              <BookmarkBorderIcon
                onClick={() => handleFeatured(true)}
                style={{ color: "#909196" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileProductCard;
