import { makeStyles } from "@material-ui/core";

const ContentStyles = makeStyles(theme => ({
  root: {
    paddingTop: "7vh"
  },

  faq: {
    display: "flex",
    paddingTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingTop: "5vh"
    }
  },

  sideMenu: {
    position: "sticky",
    top: "30vh",
    height: "100vh",
    display: "block",
    width: "35%",
    position: "-webkit-sticky",
    position: "sticky",
    top: "12vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "static",
      height: 350
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      position: "static",
      height: "55vh"
    }
  },

  sMenu: {
    display: "block",
    fontSize: 16,
    color: "#54575F",
    marginTop: 30,
    fontWeight: 300,
    "&:hover": {
      color: "#e57373",
      fontSize: 17,
      fontWeight: 600
    }
  },

  box: {
    display: "block",
    flexGrow: 1,
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  content: {
    backgroundColor: "#fff",
    padding: "55px 90px",

    marginBottom: "8vh",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 50px"
    }
  },

  faqContent: {
    marginTop: "7vh"
  },

  content3: {
    width: "100%",
    height: "82vh",
    [theme.breakpoints.down("sm")]: {
      height: 400
    },
    [theme.breakpoints.down("xs")]: {
      height: "60vh"
    }
  },

  footer: {
    width: "100%"
  },

  footerBox: {
    padding: "0 65px",
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  footerContent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center"
    }
  },

  footerContent2: {
    marginLeft: 383,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: 0,
      textAlign: "center"
    }
  },
  ubxLogo: {
    width: "auto",
    height: 20,
    marginLeft: 100,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  faqStyle: {
    marginLeft: 50,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  btnSentro: {
    height: 64,
    width: 280,
    fontSize: 16,

    [theme.breakpoints.down("xs")]: {
      height: 48,
      width: 175
    }
  },
  centeredOnSuccess: {
    color: "#F97663",
    textAlign: "center",
    paddingTop: "20vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5vh"
    }
  }
}));

export default ContentStyles;
