import React from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import AppBar from "./AppBar";
import Footer from "./Footer";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import history from "../../../../store/history";
import StoreActions from "./StoreActions";
import { ADDRESS } from "./constants/address";
import { Radio, Modal, Alert } from "antd";
import Utils from "../../utils";
import tags from "../../../../assets/images/tags.svg";
import {
  bankLogos,
  otcLogos,
  ewallets,
} from "../../../websiteBuilder/forms/logoImages";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import bank from "../../../../assets/images/icons/bank.svg";
import gcash from "../../../../assets/images/icons/gcash.svg";
import paymaya from "../../../../assets/images/icons/paymaya.svg";
import paypal from "../../../../assets/images/icons/paypal.svg";
import bux from "../../../../assets/images/Bux.svg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  Breadcrumbs,
  Link,
  Paper,
  Checkbox,
  FormControlLabel,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  useTheme,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import validations from "../../validations";
import VoucherModal from "./voucher/voucherModal";
import apiConfig from "../../../../common/apiConfig";
import Summary from "./cart/summary";
import AddToCartLimit from "./addToCartLimit";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f8f8f8",
    width: "100%",
    padding: "6em 10vw",
    [theme.breakpoints.down("sm")]: {
      padding: "2em 0",
      height: "71vh",
      overflowY: "scroll",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2em 0",
      height: "55vh",
      overflowY: "scroll",
    },
  },
  breadcrumbs: {
    fontSize: 14,
    color: "rgba(43,45,50, 0.8)",
    "&:hover": {
      fontWeight: 600,
      textDecoration: "none",
      color: "#2b2d32",
    },
  },
  header: {
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: 28,
    color: "#2b2d32",
    [theme.breakpoints.down("sm")]: {
      padding: 24,
    },
  },
  details: {
    width: "65%",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 15,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: "normal",
    color: "rgba(43,45,50, 0.8)",
  },
  labelbold: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#2b2d32",
  },
  btnContinue: {
    borderColor: "1px solid #2b2d32",
    height: 40,
  },
  btnCheckout: {
    marginLeft: "1vw",
    height: 40,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 15,
    },
  },
  summary: {
    height: "fit-content",
    marginLeft: "2vw",
    padding: 24,
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 10,
      marginLeft: 0,
    },
  },
  columnSummary: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: "2vh",
    color: "rgba(43,45,50, 0.8)",
    border: "none",
  },
  column: {
    border: "none",
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 600,
    color: "rgba(43,45,50, 0.8)",
  },
  noItems: {
    width: "46vw",
    textAlign: "center",
    padding: 20,
    fontSize: 12,
  },
  sectionHeader: {
    display: "flex",
  },
  bux: {
    width: "10vw",
    marginTop: "-4vh",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: 70,
      marginTop: "-3vh",
    },
  },
  logostyle1: {
    width: "auto",
    height: 40,
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      height: 35,
    },
  },
  // logostyle2: {
  //   width: "auto",
  //   height: 60,
  //   margin: 10,
  //   [theme.breakpoints.down("sm")]: {
  //     height: 50,
  //     margin: 5
  //   }
  // },
  logostyle2: {
    display: "flex",
    maxWidth: 60,
    maxHeight: 60,
    margin: 10,
    [theme.breakpoints.down("xs")]: {
      height: 50,
      maxWidth: 50,
    },
  },
  layout: {
    marginTop: 15,
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  layout2: {
    margin: 15,
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  mobileWalletImg: {
    width: 60,
    height: 60,
    marginBottom: 5,
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
    },
  },
  btnStyle: {
    fontSize: 12,
    fontWeight: "normal;",
    width: 160,
    height: 150,
    display: "block",
    margin: 10,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px 0",
      fontSize: 16,
    },
  },
  btnWalletSection: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
}));

const CustomRadio = withStyles({
  root: {
    color: "#1dd28b",
    "&$checked": {
      color: "#1dd28b",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CustomCheckbox = withStyles({
  root: {
    color: "#1dd28b",
    "&$checked": {
      color: "#1dd28b",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RedirectToBuxModal = (props) => {
  const { open, setOpen, handleClose, handleBuxCheckout } = props;
  const loading = useSelector((state) => state.store.loading);
  return (
    <Modal zIndex={3000} visible={open} onCancel={handleClose} footer={null}>
      <div style={{ padding: 24 }}>
        <div className="d-flex justify-content-center">
          <img src={bux} alt="bux" width="auto" height="70" />
        </div>
        <div
          className="text-center"
          style={{
            fontSize: 18,
            color: "#002c42",
            marginTop: "4vh",
            fontWeight: 600,
          }}
        >
          You will be redirected to Bux for payment processing
        </div>

        <div style={{ marginTop: 30, display: "flex" }}>
          <Button
            variant="contained"
            fullWidth
            disableElevation
            color="primary"
            style={{ height: 40, color: "#fff", backgroundColor: "#2b2d32" }}
            onClick={handleBuxCheckout}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const PaymentOptions = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/payment", "");
  const storeName = param.replace("/shop/", "");
  const theme = useTheme();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const store_theme = useSelector((state) => state.store.store.color);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const storeInfo = useSelector((state) => state.store.store);
  let kyc = storeInfo ? storeInfo.kyc_level : 0;
  const other_items = useSelector((state) => state.store.other_store_items);
  const loading = useSelector((state) => state.store.loading);
  const cartItems = useSelector((state) => state.store.items);
  const payments = useSelector((state) => state.store.payments);
  const customerDetails = useSelector((state) => state.store.customerDetails);
  const promo = useSelector((state) => state.store.promo);
  const subtotal = useSelector((state) => state.store.subtotal);
  const total = useSelector((state) => state.store.total);
  const voucher_discount = useSelector((state) => state.store.voucher_discount);
  const applied_voucher = useSelector((state) => state.store.applied_voucher);
  let vouchers = useSelector((state) => state.store.voucherList);
  let errorOpen = useSelector((state) => state.store.dialogOpen);
  let delivery_details = useSelector((state) => state.store.deliveryDetails);
  const shipping_fee = useSelector(
    (state) => state.store.deliveryDetails.shippingFee
  );
  let pickup = useSelector((state) => state.store.store.pickup_address);
  const ninjavan_settings = useSelector(
    (state) => state.store.store.ninjavan_settings
  );
  const fees = useSelector((state) => state.store.fees);
  const freebie = useSelector((state) => state.quickOrder.freebie);
  const [selected, setSelected] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState({
    paymentOption: "otc",
    account: "",
  });
  const [openBuxLimit, setOpenBuxLimit] = React.useState(false);
  let remarks = useSelector((state) => state.store.remarks);

  const {
    firstName,
    lastName,
    streetAddress,
    email,
    barangayAddress,
    cityAddress,
    provinceAddress,
    postalCode,
    mobileNumber,
  } = customerDetails;

  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold",
      },
      fontFamily: ["Inter", "sans-serif"],
    },
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(storeName));
    }
    fetchData();

    if (cartItems.length === 0) {
      history.push(`/shop/${storeName}`);
    }
  }, []);

  React.useEffect(() => {
    if (
      (storeInfo.kyc_level === 0 &&
        parseInt(total - voucher_discount) > 100000) ||
      (storeInfo.kyc_level === 1 && parseInt(total - voucher_discount) > 500000)
    ) {
      setOpenBuxLimit(true);
    } else {
      setOpenBuxLimit(false);
    }
  }, [total]);

  function handleOffline(item, key) {
    setSelected(key);
    setPaymentType({
      ...paymentType,
      account:
        item.payment_method === "Bank" ? item.bank_name : item.payment_method,
    });
  }

  function handleClose() {
    setOpenModal(false);
  }

  function logoImage(value) {
    if (value === "Bank") {
      return bank;
    } else if (value === "GCash") {
      return gcash;
    } else if (value === "PayMaya") {
      return paymaya;
    } else if (value === "PayPal") {
      return paypal;
    }
  }

  let total_amount = total - voucher_discount;

  React.useEffect(() => {
    if (promo.promo_code && promo.amount_gt > total_amount) {
      dispatch(StoreActions.resetPromo());
    }
  }, [total_amount]);

  async function handleCheckout() {
    let cart = [];
    let items = [];

    cartItems.map((item) => {
      if (item.productSelection) {
        cart.push({
          name: item.name,
          unit_price: item.productSelection.price,
          discounted_price: item.productSelection.discounted_price,
          amount:
            item.quantity * item.productSelection.discounted_price ||
            item.productSelection.price,
          gallery: item.gallery,
          variants: item.varaints,
          quantity: item.quantity,
          productSelection: item.productSelection,
          freebie: item.freebie,
          uuid: item.uuid,
          store: item.store,
        });
      } else {
        cart.push({
          name: item.name,
          unit_price: item.price,
          discounted_price: item.discounted_price,
          amount: item.quantity * item.discounted_price || item.price,
          gallery: item.gallery,
          variants: item.varaints,
          quantity: item.quantity,
          productSelection: item.productSelection,
          freebie: item.freebie,
          uuid: item.uuid,
          store: item.store,
        });
      }
    });

    if (freebie.length) {
      freebie
        .filter((i) => i.is_requirement_applied)
        .map((item) => {
          cart.push({
            name: item.freebie.name,
            unit_price: 0,
            discounted_price: 0,
            amount: 0,
            gallery: item.freebie.gallery,
            variants: item.freebie.variants,
            quantity: item.freebie_quantity,
            productSelection: item.freebie.productSelection,
            uuid: item.freebie.uuid,
            store: item.freebie.store,
          });
        });
    }

    cartItems.map((item) => {
      items.push({
        uuid: item.uuid,
        qty: item.quantity,
        always_available: item.always_available,
        product_selection: item.productSelection
          ? item.productSelection.id
          : "",
      });
    });
    const promo_extra_vals = promo.eligible ? { promo_code: "12TWELVE" } : {};
    const voucher = applied_voucher
      ? JSON.stringify({
          ...applied_voucher,
          voucher_discount,
        })
      : "";
    let value = {
      site: storeInfo.site,
      full_name: firstName + " " + lastName,
      address:
        streetAddress +
        ", " +
        barangayAddress +
        ", " +
        cityAddress +
        ", " +
        provinceAddress +
        ", " +
        postalCode,
      mobile_number: mobileNumber,
      amount: total_amount,
      other_fees: 0,
      subtotal: subtotal,
      recipient: email,
      uuid: storeInfo.uuid,
      cart: JSON.stringify(cart),
      items: items,
      items_total_qty: cartItems.reduce((a, b) => a + b.quantity, 0),
      purchase_amount: total,
      voucher,
      shipping_fee: delivery_details.shippingFee,
      delivery_detail: {
        details: { ...delivery_details.delivery_detail },
        method: delivery_details.shipping,
      },
      remarks: remarks,
      ...promo_extra_vals,
    };
    let res = await dispatch(StoreActions.checkOut(value, paymentType));
    try {
      if (res.status === 200) {
        setOpenModal(false);
        let ref_id = res.data ? res.data.description : null;
        let access = res.data ? res.data.bux_uid : null;
        if (user) {
          dispatch(StoreActions.patchBuyerCart([...other_items]));
        }
        setTimeout(() => {
          if (paymentType["paymentOption"] === "otc") {
            if (access) {
              window.location.href = `${
                apiConfig.buxURL
              }/checkout/${access}/?redirect_url=${apiConfig.localHost}shop/${
                storeInfo && storeInfo.site
              }/checkout?ref_id=${ref_id}`;
            }
          } else {
            history.push(`/shop/${storeInfo && storeInfo.site}/checkout`);
          }
        }, 500);
        dispatch(StoreActions.resetCart());
      }
    } catch (e) {
      window.scrollTo(0, 0);
      setOpenModal(false);
      console.log(e);
    }
  }
  React.useEffect(() => {
    dispatch(StoreActions.setSelectedPaymentChannel("otc"));
  }, []);
  const handleChange = (event) => {
    dispatch(StoreActions.setSelectedPaymentChannel(event.target.value));
    if (event.target.value === "otc") {
      setSelected("");
    } else {
      dispatch(StoreActions.resetPromo({ loading: true }));
    }
    if (paymentType.paymentOption === "otc") {
      setPaymentType({
        paymentOption: event.target.value,
        account: "",
      });
    } else if (paymentType.paymentOption === "deposit") {
      setPaymentType({
        paymentOption: event.target.value,
      });
    }
  };

  const handlePayment = () => {
    if (!openBuxLimit) {
      if (paymentType.paymentOption === "otc") {
        setOpenModal(true);
      } else {
        handleCheckout();
      }
    }
  };

  function handleBuxCheckout() {
    handleCheckout();
  }

  function cashInLimits() {
    return (
      <div id="error-message" style={{ marginTop: 25 }}>
        {errorOpen == true ? (
          <div>
            <Alert
              type="error"
              message="Oops! Looks like there is a problem with taking online orders for now. Kindly try again in a few minutes, or coordinate with us through any of the contact details below."
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  const breadcrumbLinks = [
    {
      label: "Home",
      path: `/shop/${storeInfo.site}`,
    },
    {
      label: "Cart",
      path: `/shop/${storeInfo.site}/cart`,
    },
    {
      label: "Details",
      path: `/shop/${storeInfo.site}/delivery`,
    },
    {
      label: "Delivery",
      path: `/shop/${storeInfo.site}/delivery_details`,
    },
    {
      label: "Payment",
      path: `/shop/${storeInfo.site}/payment`,
    },
  ];

  return (
    <div>
      {storeInfo && storeInfo.font.css_url ? (
        <link href={storeInfo.font.css_url} rel="stylesheet"></link>
      ) : (
        ""
      )}
      {openBuxLimit ? <AddToCartLimit kycLevel={storeInfo?.kyc_level} /> : ""}
      <ThemeProvider theme={store_generated_theme}>
        <div className={classes.root}>
          <AppBar isPreview={false} storeInfo={storeInfo} />
          {/* <Loading open={loading} /> */}
          <div>
            <div>
              <Breadcrumbs
                style={{ display: isMobile ? "none" : "" }}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbLinks.map((item) => {
                  return (
                    <Link
                      className={classes.breadcrumbs}
                      style={{
                        fontWeight:
                          item.label === "Payment" ? "bold" : "normal",
                      }}
                      color="inherit"
                      onClick={() => history.push(item.path)}
                    >
                      {item.label}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            </div>
            <div style={{ marginTop: "3vh" }}>
              {/* <Button
                startIcon={<ArrowBackIosIcon />}
                variant="text"
                color="secondary"
                style={{ margin: "16px 24px", display: isMobile ? "" : "none" }}
                onClick={() => history.goBack()}
              >
                Back
              </Button> */}
              <div className={classes.header}>Payment</div>
              {cashInLimits()}
              <div
                style={{
                  display: isMobile ? "block" : "flex",
                  marginTop: "3vh",
                }}
              >
                <Paper className={classes.details}>
                  <div className={classes.labelbold} style={{ padding: 24 }}>
                    Payment Options
                  </div>
                  <Radio.Group
                    style={{ width: "100%" }}
                    value={paymentType.paymentOption}
                    onChange={handleChange}
                  >
                    <div style={{ backgroundColor: "#f5f5f5", padding: 24 }}>
                      <Radio value="otc">Online and Over-the-Counter</Radio>
                    </div>
                    <div style={{ padding: 24, backgroundColor: "#fff" }}>
                      <div className={classes.sectionHeader}>
                        <div className={classes.label}>
                          Pay through our online and over-the-counter partners.
                          You will be asked to select the preferred online bank
                          or establishment on the next page. Payment can be
                          accomplished within 24 hours.
                        </div>
                        <div>
                          <div style={{ fontSize: 12, textAlign: "center" }}>
                            Powered by
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              className={classes.bux}
                              src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/standard_logomark_wordmark_horizontal.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: "2vh" }}>
                        <div className={classes.labelbold}>
                          1. Online payment via partner banks:
                        </div>
                        <div className={classes.layout}>
                          {bankLogos.map((item) => {
                            return (
                              <img
                                src={item.logo}
                                className={classes.logostyle1}
                                alt=""
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div style={{ marginTop: "2vh" }}>
                        <div className={classes.labelbold}>
                          2. Cash payment via our partner establishments:
                        </div>
                        <div className={classes.layout2}>
                          {otcLogos.map((item) => {
                            return (
                              <div className={classes.logostyle2}>
                                <img
                                  className="img-fluid my-auto"
                                  src={item.logo}
                                  alt=""
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div style={{ marginTop: "2vh" }}>
                        <div className={classes.labelbold}>3. E-wallets</div>
                        <div className={classes.layout}>
                          {ewallets.map((item) => {
                            return (
                              <img
                                src={item.logo}
                                className={classes.logostyle1}
                                alt=""
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div style={{ backgroundColor: "#f5f5f5", padding: 24 }}>
                      <Radio value="deposit">Mobile Wallet</Radio>
                    </div>
                    <div
                      style={{
                        padding: 24,
                        display: isMobile ? "block" : "flex",
                      }}
                    >
                      {payments.map((item, key) => {
                        return (
                          <Button
                            key={key}
                            className={classes.btnStyle}
                            disabled={paymentType.paymentOption === "otc"}
                            variant="outlined"
                            value={item.payment_method}
                            onClick={() => handleOffline(item, key)}
                            style={{
                              border: "2px solid #d1d5dd",
                              boxShadow:
                                key === selected
                                  ? "0px 4px 10px rgba(0, 0, 0, 0.25)"
                                  : "none",
                              borderColor:
                                key === selected ? "#1dd28b" : "#d1d5dd",
                            }}
                          >
                            <div className={classes.btnWalletSection}>
                              <FormControlLabel
                                control={
                                  <CustomCheckbox
                                    icon={<CircleUnchecked />}
                                    checkedIcon={<CircleCheckedFilled />}
                                    checked={true}
                                  />
                                }
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 10,
                                  display: key === selected ? "" : "none",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={logoImage(item.payment_method)}
                                  className={classes.mobileWalletImg}
                                  style={{
                                    opacity:
                                      paymentType.paymentOption === "otc"
                                        ? 0.3
                                        : 1,
                                  }}
                                  alt=""
                                />
                              </div>
                              <div style={{ textAlign: "center" }}>
                                {item.payment_method === "Bank"
                                  ? item.bank_name
                                  : ""}
                              </div>
                              {item.payment_method === "Bank"
                                ? ""
                                : item.payment_method}
                            </div>
                          </Button>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        display: isMobile ? "none" : "flex",
                        justifyContent: "flex-end",
                        padding: 24,
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.btnContinue}
                        onClick={() =>
                          history.push(`/shop/${storeInfo.site}/products`)
                        }
                      >
                        Continue Shopping
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        className={classes.btnCheckout}
                        color="secondary"
                        disabled={
                          paymentType.paymentOption !== "otc"
                            ? paymentType.account === ""
                            : false ||
                              cartItems.length == 0 ||
                              (kyc == 0 && total_amount > 5000) ||
                              total_amount > 30000
                        }
                        onClick={() => handlePayment()}
                      >
                        {loading ? (
                          <div className="d-flex my-5 mr-3">
                            <div
                              className="spinner-border mx-auto"
                              role="status"
                              style={{
                                color: "white",
                                fontSize: 12,
                                height: 25,
                                width: 25,
                              }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        Proceed to Checkout
                      </Button>
                    </div>
                  </Radio.Group>
                </Paper>
                <Summary
                  loading={loading}
                  storeInfo={storeInfo}
                  cartItems={cartItems}
                  subtotal={subtotal}
                  total={total}
                  delivery_details={delivery_details}
                  voucher_discount={voucher_discount}
                  shipping_fee={shipping_fee}
                  proceed_to_checkout={() => handlePayment()}
                  disabled={
                    paymentType.paymentOption !== "otc"
                      ? paymentType.account === ""
                      : false ||
                        cartItems.length == 0 ||
                        (kyc == 0 && total_amount > 5000) ||
                        total_amount > 30000
                  }
                  continue_shopping={true}
                />
              </div>
            </div>
          </div>
        </div>

        <Footer store={storeInfo} />
        <RedirectToBuxModal
          open={openModal}
          handleClose={handleClose}
          handleBuxCheckout={handleBuxCheckout}
        />
        <VoucherModal
          id="voucher-modal"
          open={openVoucher}
          setOpen={setOpenVoucher}
        />
      </ThemeProvider>
    </div>
  );
};

export default PaymentOptions;
