import React from 'react';
import { LinearProgress } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import {ReactComponent as UploadIconSVG} from "../../assets/images/enterprise/upload_icon.svg";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';



export default function FileUploader(props) {
  const theme = useTheme();
  const primary_color = theme.palette.primary.main;
  const useStyles = makeStyles(theme => ({
    wrapper: {
      border: `2px dashed ${primary_color}`,
      borderRadius: 4,
      padding: 8,
      height: 260
    },
    error: {
      fontSize: 12,
      color: "red"
    },
    label2: {
      fontSize: 14,
      fontWeight: 500,
      color: "rgba(43, 45, 50, 0.8)"
    },
    optionButtons: {
      fontWeight: "500",
      fontSize: 12,
      cursor: "pointer",
      color: primary_color
    },
    disabled: {
      color: "rgba(0,0,0,0.3) !important"
    }
  }));
  const classes = useStyles();
  const [error, setError] = React.useState("");

  const onChange = (e) => {
    const MAX_MB = 10;
    const file = e.target.files[0];
    if (file) {
      if (file.size/1000000 > MAX_MB) {
        setError("Maximum of 10mb only");
      }
      else if (!props.acceptedTypes.some(i => i === file.name.split(".").slice(-1)[0])) {
        setError("XLSX format only");
      }
      else {
        setError("");
        props.onChange(file);
      }
    }
  }

  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    if (props.status === "loading") {
      setProgress(0);
      setInterval(() => {
        setProgress(val => val < 90 ? val+Math.floor(Math.random() * 7): val);
      }, 200)
    } else if (props.status === "success"){
      setProgress(100);
    }
  }, [props.status])

  const errorMessage = (
      props.errorMessage || error ? 
      <p className="text-danger mb-1" style={{ fontSize: 12}}> {props.errorMessage || error} </p>: ""
   )
  
  const [drag, setDrag] = React.useState(false);
  const [dragCounter, setDragCounter] = React.useState(0);
  const dropRef = React.createRef()
  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragCounter(val => val++);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  }
  const handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragCounter(val => val--);
    if (dragCounter === 0) {
      setDrag(false);
    }
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props.onChange(e.dataTransfer.files[0])
      e.dataTransfer.clearData()
      setDragCounter(0);
    }
  }
  React.useEffect(() => {
    let div = dropRef.current
    div.addEventListener('dragenter', handleDragIn)
    div.addEventListener('dragleave', handleDragOut)
    div.addEventListener('dragover', handleDrag)
    div.addEventListener('drop', handleDrop)
    return () => {
      div.removeEventListener('dragenter', handleDragIn)
      div.removeEventListener('dragleave', handleDragOut)
      div.removeEventListener('dragover', handleDrag)
      div.removeEventListener('drop', handleDrop)
    }
  })

  return (
    <>
      <p className={`${classes.label2} mb-2`}>{props.label}</p>
      <input type="file" accept=".xlsx, .xls" value="" onChange={onChange} id="upload-button" className="d-none"
        disabled={props.status === "loading"}/>
      <label htmlFor={'upload-button'} className={`${classes.wrapper} w-100 position-relative`} 
        style={{
          cursor: props.status === "loading" ? "not-allowed": "pointer"
        }}>
        <div className="position-absolute w-100 h-100" style={{zIndex: 2, top: 0, left: 0}} 
          ref={dropRef} /> 
        {
          drag ?
          <div className="position-absolute w-100 h-100" style={{
            backgroundColor: "rgba(255,255,255,0.8)", 
            zIndex: 1, top: 0, left: 0}} /> 
          : ""
        }
        <div className="position-relative w-100 h-100 d-flex w-100 align-items-center
        justify-content-center">
          {
            props.status === "loading" ? 
            <div className="w-100 px-5">
              <LinearProgress variant="determinate" value={progress} />
              <div className="text-right text-secondary" style={{ fontSize: 14}}>{progress}%</div>
            </div>
            :
            props.status === "success" ? 
            <div className="text-center" style={{ maxWidth: 500 }}>
              <CheckCircleRoundedIcon className="mb-3"  style={{
                color: "#18B4A1",
                height: 40, width: 40
              }}/>
              <p className="mb-1" style={{ fontSize: 14}}>File uploaded successfully</p>
              <p className="text-secondary mb-1" style={{ fontSize: 10}}>Click or drag another file to this area to upload</p>
              {errorMessage}
            </div>
            :
            <div className="text-center" style={{ maxWidth: 500 }}>
              <UploadIconSVG height="33" width="33" className="mb-3"  fill={theme.palette.primary.main}/>
              <p className="mb-1" style={{ fontSize: 14}}>Click or drag file to this area to upload</p>
              <p className="text-secondary mb-1" style={{ fontSize: 10}}>
                (Max file size of 10 MB in .xlsx format only) </p>
              {errorMessage}
            </div>
          }
        </div>
      </label>
    </>
  );
}