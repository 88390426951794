import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  useMediaQuery,
  InputAdornment,
  OutlinedInput,
  Slide,
  Select,
  MenuItem,
  Checkbox
} from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as SearchSVG } from '../../../../assets/images/enterprise/search.svg';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { getProductCategories } from "../../actions";
import useProduct from './useProducts';
import {ReactComponent as ProductSVG} from '../../../../assets/images/product_icon.svg';
import {ReactComponent as SearchContainedSVG} from '../../../../assets/images/search.svg';
import ProductItem from './productItem';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductModalPicker = (props) => {
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles(theme => ({
    links: {
      color: "#18B4A1"
    },
    label2: {
      fontSize: 12,
      fontWeight: 500,
      marginBottom: 0,
      marginTop: 0,
      color: "rgba(0,0,0,0.8)"
    },
    fixedBottom: {
      position: "fixed",
      bottom: 0,
      left: 0,
      margin: "1.5rem",
      width: "calc(100% - 3rem)"
    },
    choices: {
      fontSize: 14,
      color: "rgba(43, 45, 50, 0.8)"
    },
    productList: {
      backgroundColor: "#FAFAFA",
      borderRadius: 5,
      height: `calc(${is_mobile? "88vh": "60vh"} - 200px)`,
      padding: 5,
      marginTop: 15,
      overflowY: "auto"
    },
    blank: {
      "& > .title": {
        color: "#2B2D32",
        fontWeight: "600",
        fontSize: 18
      },
      "& > .subtitle": {
        fontSize: 16
      },
      maxWidth: 300
    },
    productSelected: {
      fontWeight: "500",
      fontSize: 14,
      color: "#2B2D32",
      marginBottom: 0
    },
    closeBtn: {
      position: "absolute",
      top: 10, right: 10
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const stores = useSelector(state => state.vouchers.stores);
  const productCategories = useSelector(
    state => state.vouchers.product_categories.categories
  );

  // GET PRODUCT CATEGORIES ONMOUNT
  React.useEffect(() => {
    if (stores[0]) {
      dispatch(getProductCategories(stores[0].uuid));
    }
  }, [stores, dispatch])


  // FILTERS
  const [timeoutId, setTimeoutId] = React.useState("");
  const searchOnChange = (e) => {
    const value = e.target.value;
    clearTimeout(timeoutId);
    setTimeoutId(setTimeout(() => {
      setParams(f => ({...f, search: value}))
    }, 300));
  }
  const [params, setParams] = React.useState({
    search: "",
    category: "Category"
  })

  // GET PRODUCT HOOK
  const [products] = useProduct({
    "store_uuid": stores[0]?.uuid,
    params
  })

  // CHECKED ITEMS
  const [selectedProducts, setSelectedProducts] = React.useState([])
  const checkOnChange = (checked, uuid) => {
    if (checked) {
      setSelectedProducts(prev => ([...prev, uuid]))
    }
    else {
      setSelectedProducts(prev => prev.filter(i => i !== uuid))
    }
  }
  React.useEffect(() => {
    if (props.value && props.value !== selectedProducts) {
      setSelectedProducts(props.value)
    }
  }, [props.value])

  // SELECT ALL
  const setSelectAll = (e) => {
    const val = e.target.checked;
    if (val) {
      setSelectedProducts(products.map(i => i.uuid));
    } else {
      setSelectedProducts(val => []);
    }
  }

  // SUBMIT
  const onSubmit = () => {
    props.onSubmit(selectedProducts[0]);
    props.setOpen(false);
  }
  return (
    <Dialog
      className="product-picker-modal"
      fullWidth
      maxWidth="sm"
      open={props.open}
      fullScreen={is_mobile}
      TransitionComponent={Transition}
    >
      <DialogContent className="p-4 p-md-5">
        <div>
          <div className="d-flex">
            <OutlinedInput
              className={`${classes.searchbar} w-50 mr-2 search`}
              startAdornment={
                <InputAdornment position="start">
                  {
                    params.search ? 
                    <HighlightOffRoundedIcon style={{ height: 18, width: 18, cursor: "pointer", color: "#9A9EA9" }}
                      onClick={() => setParams(val => ({...val, search: ""}))}/>
                    : 
                    <SearchSVG style={{ height: 15, width: 15 }} fill="#9A9EA9"/>
                  }
                </InputAdornment>
              }
              color="secondary"
              labelWidth={0}
              margin="dense"
              placeholder="Type here to search"
              onChange={searchOnChange}
            />
            {
              productCategories ? 
              <Select
                className="bg-white w-50 categories"
                labelId="demo-simple-select-label"
                classes={{ root: "text-left" }}
                margin="dense"
                variant="outlined"
                value={params.category}
                onChange={(e) => setParams(f => ({
                  ...f, category: e.target.value
                }))}
              >
                <MenuItem value="Category">Category</MenuItem>
                {productCategories.map(i => (
                  <MenuItem value={i}>{i}</MenuItem>
                ))}
              </Select>
              :""
            }
          </div>
          {/* <div className="d-flex align-items-center justify-content-between mt-3">
            <p className={classes.productSelected}>{selectedProducts.length} products selected</p>
            <div className=" d-flex align-items-center">
              <p className="text-secondary mb-0">Select all</p>
              <Checkbox 
                className="select-all"
                color="primary" 
                indeterminate={(products.length > selectedProducts.length) && selectedProducts.length} 
                checked={products.length === selectedProducts.length} onChange={setSelectAll}/>
            </div>
          </div> */}
          <div className={`${classes.productList} product-list`}>
            {
              products.length ? 
                products.map(product => (
                  <ProductItem 
                    key={product.uuid} 
                    product={product} 
                    checked={selectedProducts.includes(product.uuid)} 
                    onChange={(checked) => checkOnChange(checked, product.uuid)} />
                ))
              : 
              <div className={`${classes.blank} text-center h-100 d-flex flex-column mx-auto`}>
                {
                  params.search ? 
                  <SearchContainedSVG style={{ height: 65, width: 65}} className="mx-auto mb-3 mt-auto"/>
                  : 
                  <ProductSVG style={{ height: 65, width: 65}} className="mx-auto mb-3 mt-auto"/>
                }
                <p className="title">No products found</p>
                <p className="subtitle text-secondary mb-auto">
                  {
                    params.search ? 
                    "Try using another keyword to search." :
                    "You have no products yet. Add a product first to use this feature."
                  }
                </p>
              </div>
            }
          </div>
          <div className={is_mobile ? classes.fixedBottom: ""}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              className={`${classes.btn}  mt-3 submit-button`}
              color="primary"
              onClick={onSubmit}
              disabled={selectedProducts.length > 1}
              products={selectedProducts}
            >
              Save
            </Button>
            <Button
              fullWidth
              variant="outlined"
              disableElevation
              className={`${classes.btn}  mt-2 cancel-button`}
              color="primary"
              onClick={() => props.setOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
} 

export default ProductModalPicker;
