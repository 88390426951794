import React from "react";
import img from "../../assets/images/freeTrialBanner.svg";
import { Paper, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Path from "../../Paths";
export default function FreeTrial(props) {
  const history = useHistory();

  return (
    <Paper
      className="bg-white d-flex justify-content-center my-4"
      style={{ padding: "5em" }}
    >
      <div className="text-center">
        <img
          src={img}
          alt="banner"
          width="auto"
          height="160"
          className="mb-5"
        />
        <div style={{ fontSize: 18, fontWeight: 600, color: "#2b2d32" }}>
          {props.header}
        </div>
        <div
          className="my-3"
          style={{ fontSize: 14, color: "#54575f", padding: "0 16vw" }}
        >
          {props.subheader}
        </div>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className="mt-4"
          style={{ padding: "0 55px", height: 40 }}
          onClick={() => history.push(Path.SENTRO_ADVANCED)}
        >
          Start free trial
        </Button>
      </div>
    </Paper>
  );
}
