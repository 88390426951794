import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Paper, useMediaQuery } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Utils from "../../../common/utils";
import { Radio } from "antd";
import GoogleAddressForm from "../../../common/googleAddressForm";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
export default function DeliveryRates(props) {
  const {
    flat_rate,
    set_flat_rate,
    selected_order,
    delivery_locations,
    delivery_details,
    customer_details,
    computeShipping,
    setDeliveryDetails
  } = props;
  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: "5vh",
      width: "65%",
      height: "fit-content",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: 15
      }
    },
    labelbold: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#2b2d32"
    },
    box: {
      transition: "all .5s ease-in-out",
      borderRadius: 4,
      backgroundColor: "#fafafa",
      padding: "24px 16px"
    }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const pickup_address =
    selected_order && selected_order.store_data.pickup_address;

  const is_mrspeedy_autocompute =
    pickup_address && pickup_address.length
      ? pickup_address[0].is_mrspeedy_auto_compute
      : "";

  const storeInfo = selected_order ? selected_order.store_data : "";

  React.useEffect(() => {
    let hasProvince = [];
    let hasCity = [];
    let hasNoCity = [];
    for (let i in delivery_locations) {
      if (delivery_locations[i].province === customer_details.provinceAddress) {
        hasProvince.push(delivery_locations[i]);
      }
    }

    if (hasProvince.length) {
      for (let i in hasProvince) {
        if (hasProvince[i].cities.length) {
          if (hasProvince[i].cities.includes(customer_details.cityAddress)) {
            hasCity.push(hasProvince[i]);
          }
        } else {
          hasNoCity.push(hasProvince[i]);
        }
      }
    }

    if (hasProvince.length && hasCity.length) {
      set_flat_rate(hasCity[0]);
    } else if (hasProvince.length && !hasCity.length) {
      set_flat_rate(hasNoCity[0]);
    }
  }, [delivery_locations]);

  const noShippingSelections = () => {
    return (
      <div id="no_shipping_selections" style={{ padding: "0 24px 24px" }}>
        <Alert
          id="flat_rate_error"
          style={{ fontSize: 12, border: "1px solid #e89937" }}
          severity="warning"
        >
          Oops! This seller has not included delivery fees in the total amount.
          If you proceed with payment, seller will inform you once items are
          ready for pick-up or delivery. Kindly coordinate for the delivery
          arrangement.
        </Alert>

        <div className="pt-4">
          <div style={{ fontSize: 14, fontWeight: 600 }}>{storeInfo.name}</div>
          <div className="pt-3">
            {storeInfo && storeInfo.email ? (
              <div className="d-flex align-items-center">
                <MailOutlineIcon style={{ color: "rgba(43,45,50, 0.8)" }} />
                <div
                  className="ml-3"
                  style={{ fontSize: 12, color: "rgba(43,45,50, 0.8)" }}
                >
                  {storeInfo.email}
                </div>
              </div>
            ) : (
              ""
            )}
            {storeInfo && storeInfo.mobile ? (
              <div className="d-flex align-items-center mt-2">
                <PhoneIphoneIcon style={{ color: "rgba(43,45,50, 0.8)" }} />
                <div
                  className="ml-3"
                  style={{ fontSize: 12, color: "rgba(43,45,50, 0.8)" }}
                >
                  {storeInfo.mobile}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Paper id="shipping" className={classes.paper}>
      <div className={classes.labelbold} style={{ padding: 24 }}>
        Shipping
      </div>
      {(pickup_address && pickup_address.length) ||
      (delivery_locations && delivery_locations.length) ? (
        <Radio.Group
          id="shipping_radio_group"
          value={delivery_details.shipping}
          onChange={e => setDeliveryDetails(e.target.value, flat_rate)}
          style={{ width: "100%", padding: "0 24px 24px" }}
        >
          {delivery_locations && delivery_locations.length ? (
            <div
              className="py-2"
              style={{ borderBottom: "1px dashed #d1d5dd" }}
            >
              <Radio
                className="py-3"
                style={{ width: "100%" }}
                value="flat_rate"
              >
                Flat Rate shipping
              </Radio>
              {delivery_details.shipping === "flat_rate" ? (
                flat_rate ? (
                  <div
                    id="flat_rate"
                    style={{
                      flexDirection: isMobile ? "column" : "",
                      display:
                        delivery_details.shipping === "flat_rate"
                          ? "flex"
                          : "none"
                    }}
                    className={`mt-2 col-12 ${classes.box}`}
                  >
                    <div
                      className="col-lg-2 col-xs-12 "
                      style={{
                        fontSize: 12,
                        fontWeight: 500,
                        marginBottom: isMobile ? 15 : 0
                      }}
                    >
                      Address
                    </div>
                    <div
                      className="col-lg-8 col-xs-12 "
                      style={{
                        marginBottom: isMobile ? 15 : 0
                      }}
                    >
                      <div style={{ fontSize: 12, fontWeight: 600 }}>
                        {`${customer_details.streetAddress}, ${customer_details.barangayAddress}, ${customer_details.cityAddress}, ${customer_details.provinceAddress}, ${customer_details.postalCode}`}
                      </div>{" "}
                      <div style={{ fontSize: 11, marginTop: 10 }}>
                        {flat_rate ? flat_rate.delivery_information : ""}
                      </div>
                    </div>

                    <div
                      className="col-lg-2 col-xs-12 "
                      style={{
                        fontSize: 11,
                        fontWeight: 600,
                        color: "#002c42",
                        textAlign: isMobile ? "left" : "right"
                      }}
                    >
                      ₱ {flat_rate ? flat_rate.rate : ""}
                    </div>
                  </div>
                ) : (
                  <div>
                    <Alert
                      id="flat_rate_error"
                      style={{ fontSize: 12 }}
                      severity="warning"
                    >
                      Oops! Your address is not covered by the seller's delivery
                      locations. If you proceed with payment, seller will inform
                      you once items are ready for pick-up or delivery. Kindly
                      coordinate for the delivery arrangement.
                    </Alert>
                    <div className="pt-4">
                      <div style={{ fontSize: 14, fontWeight: 600 }}>
                        {storeInfo.name}
                      </div>
                      <div className="pt-3">
                        {storeInfo && storeInfo.email ? (
                          <div className="d-flex align-items-center">
                            <MailOutlineIcon
                              style={{ color: "rgba(43,45,50, 0.8)" }}
                            />
                            <div
                              className="ml-3"
                              style={{
                                fontSize: 12,
                                color: "rgba(43,45,50, 0.8)"
                              }}
                            >
                              {storeInfo.email}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {storeInfo && storeInfo.mobile ? (
                          <div className="d-flex align-items-center mt-2">
                            <PhoneIphoneIcon
                              style={{ color: "rgba(43,45,50, 0.8)" }}
                            />
                            <div
                              className="ml-3"
                              style={{
                                fontSize: 12,
                                color: "rgba(43,45,50, 0.8)"
                              }}
                            >
                              {storeInfo.mobile}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {pickup_address &&
          pickup_address.length &&
          is_mrspeedy_autocompute ? (
            <div
              className="py-2"
              style={{ borderBottom: "1px dashed #d1d5dd" }}
            >
              <Radio
                id="mr_speedy"
                className="py-3"
                style={{ width: "100%" }}
                value="mr_speedy"
              >
                Select courier (Mr. Speedy)
              </Radio>

              <div
                style={{
                  display:
                    delivery_details.shipping === "mr_speedy" ? "" : "none"
                }}
              >
                {" "}
                <GoogleAddressForm
                  margin="dense"
                  fullWidth
                  initialValue={
                    delivery_details.mrspeedy_delivery_address.address
                  }
                  onChange={value => {
                    let params = {
                      address: value.address,
                      longitude: value.lng,
                      latitude: value.lat
                    };
                    computeShipping(params);
                  }}
                />
                {delivery_details.mrspeedy_delivery_address.address &&
                !delivery_details.loading ? (
                  delivery_details.error === "" ? (
                    <div>
                      <div
                        style={{
                          flexDirection: isMobile ? "column" : "inherit"
                        }}
                        className={`mt-2 d-flex col-12 ${classes.box}`}
                      >
                        <div
                          className="col-lg-2 col-xs-12 "
                          style={{
                            fontSize: 12,
                            fontWeight: 500,
                            marginBottom: isMobile ? 15 : 0
                          }}
                        >
                          Address
                        </div>
                        <div
                          className="col-lg-8 col-xs-12 "
                          style={{
                            fontSize: 12,
                            fontWeight: 600,
                            marginBottom: isMobile ? 15 : 0
                          }}
                        >
                          {delivery_details.mrspeedy_delivery_address.address}
                        </div>
                        <div
                          className="col-lg-2 col-xs-12 "
                          style={{
                            fontWeight: 600,
                            color: "#002c42",
                            textAlign: isMobile ? "left" : "right"
                          }}
                        >
                          ₱ {Utils.commafy(delivery_details.shippingFee)}
                        </div>
                      </div>
                      <div
                        className="mt-3 mb-2 py-2 px-3"
                        style={{ background: "#eee" }}
                      >
                        <div style={{ fontSize: 12 }}>
                          <strong>Please note:</strong> You may be asked to pay
                          additional price by the seller if total package weight
                          exceeds 5kg.
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="py-3">
                      <Alert style={{ fontSize: 12 }} severity="error">
                        An error has occured. Please try again.
                      </Alert>
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {pickup_address &&
          pickup_address.length &&
          pickup_address[0].show_pickup_address ? (
            <div className="py-2">
              <Radio
                id="pick_up"
                className="py-3"
                style={{ width: "100%" }}
                value="pick_up"
              >
                Pick-up from seller
              </Radio>
              <div
                style={{
                  flexDirection: isMobile ? "column" : "inherit",
                  display:
                    delivery_details.shipping === "pick_up" ? "flex" : "none"
                }}
                className={`mt-2 col-12 ${classes.box}`}
              >
                <div
                  className={`col-lg-2 col-xs-12 `}
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    marginBottom: isMobile ? 15 : 0
                  }}
                >
                  Pick-up Address
                </div>
                <div
                  className="col-lg-8 col-xs-12"
                  style={{ marginBottom: isMobile ? 15 : 0 }}
                >
                  <div style={{ fontSize: 12, fontWeight: 600 }}>
                    {pickup_address[0].google_address.address}
                  </div>
                  <div style={{ fontSize: 11, marginTop: 10 }}>
                    Delivery Fee shouldered by customer
                  </div>
                </div>
                <div
                  className="col-lg-2 col-xs-12"
                  style={{
                    fontWeight: 600,
                    color: "#002c42",
                    textAlign: isMobile ? "left" : "right"
                  }}
                >
                  FREE
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Radio.Group>
      ) : (
        noShippingSelections()
      )}
    </Paper>
  );
}
