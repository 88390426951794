import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  FormControlLabel,
  Link,
  RadioGroup,
  useTheme,
  useMediaQuery,
  StepButton,
  Chip,
  Button
} from "@material-ui/core";
import icon1 from "../../../../assets/images/store.svg";
import icon2 from "../../../../assets/images/badge2.png";
import BpRadio from "../../../delivery_settings/components/radioButtons";
import Path from "../../../../Paths";
import Utils from "../../../common/utils";
import { useSelector, useDispatch } from "react-redux";
import { Launch } from "@material-ui/icons";
import { goToCompleteProfile } from "../../../payments/action";
import MaterialUIAlert from "../../../common/material-ui-alert";
import apiConfig from "../../../../common/apiConfig";
import CODWallet from "./codWallet";
import BuxWallet from "./buxWallet";
import { Divider } from "antd";
export default function Form(props) {
  const { activeStep, setActiveStep, subscription, setSubscription } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const codWallet = useSelector(state => state.payments.codWallet);
  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(subscription);
    }
  }, [subscription]);

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ backgroundColor: isMobile ? "#fafafa" : "#fff" }}
      >
        <Step key={0}>
          <StepButton color="inherit" onClick={() => setActiveStep(0)}>
            Select your plan
          </StepButton>
        </Step>
        <Step key={1}>
          <StepButton color="inherit" onClick={() => setActiveStep(1)}>
            Payment
          </StepButton>
        </Step>
      </Stepper>
      {activeStep === 0 ? (
        <>
          <div id="plan" className="mt-2 d-flex col-12 p-0">
            <RadioGroup
              style={{
                flexDirection: isMobile ? "column" : "row",
                flexWrap: "inherit"
              }}
              value={subscription}
              onChange={e => setSubscription(e.target.value)}
            >
              <div
                className={`${
                  isMobile ? "w-100" : "w-50"
                } bg-white p-4 mr-3 mb-md-0 mb-4`}
                style={{
                  height: "100%",
                  cursor: "pointer",
                  border: `1px solid ${
                    subscription === "2016" ? "#f97663" : "#d1d5dd"
                  }`,
                  borderRadius: 4
                }}
                onClick={() => setSubscription("2016")}
              >
                <div
                  className="text-center"
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#f97663",
                    opacity: subscription === "2016" ? 1 : 0.5,
                    transition: "opacity 0.3s"
                  }}
                >
                  RECOMMENDED
                </div>
                <div className="d-flex justify-content-center mt-md-4 mt-2">
                  <div className="text-center d-md-block d-flex">
                    <div
                      style={{
                        opacity: subscription === "2016" ? 1 : 0.5,
                        transition: "opacity 0.3s"
                      }}
                      className="d-md-block d-flex align-items-center"
                    >
                      <img
                        src={icon1}
                        alt="icon"
                        width="auto"
                        height={isMobile ? 50 : 70}
                      />
                      <div>
                        <div
                          style={{ fontSize: 16, color: "#2b2d32" }}
                          className="mt-4"
                        >
                          ₱{" "}
                          <span style={{ fontWeight: 600, fontSize: 18 }}>
                            600.00
                          </span>{" "}
                          /mo
                        </div>
                        <div
                          style={{ fontSize: 12, padding: "0 4vw" }}
                          className="mt-3"
                        >
                          (₱ 1,800.00 one time payment for 3 months, subject to
                          VAT)
                        </div>
                      </div>
                    </div>
                    <FormControlLabel
                      value="2016"
                      className="m-0 pt-3"
                      control={<BpRadio color="primary" />}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${isMobile ? "w-100" : "w-50"} bg-white p-4`}
                style={{
                  cursor: "pointer",
                  border: `1px solid ${
                    subscription === "1344" ? "#f97663" : "#d1d5dd"
                  }`,
                  borderRadius: 4
                }}
                onClick={() => setSubscription("1344")}
              >
                <div className="d-flex justify-content-center mt-md-5 mt-0">
                  <div className="text-center d-md-block d-flex">
                    <div
                      style={{
                        opacity: subscription === "1344" ? 1 : 0.5,
                        transition: "opacity 0.3s"
                      }}
                      className="d-md-block d-flex align-items-center"
                    >
                      <img
                        src={icon2}
                        alt="icon"
                        width="auto"
                        height={isMobile ? 50 : 70}
                      />
                      <div>
                        <div
                          style={{ fontSize: 16, color: "#2b2d32" }}
                          className="mt-md-4 mt-2"
                        >
                          ₱{" "}
                          <span style={{ fontWeight: 600, fontSize: 18 }}>
                            1344.00
                          </span>{" "}
                          /mo
                        </div>
                        <div
                          style={{ fontSize: 12, padding: "0 4vw" }}
                          className="mt-3"
                        >
                          (Monthly recurring payment, subject to VAT, cancel
                          anytime)
                        </div>
                      </div>
                    </div>
                    <FormControlLabel
                      value="1344"
                      className="m-0 pt-3"
                      control={<BpRadio color="primary" />}
                    />
                  </div>
                </div>
              </div>
            </RadioGroup>
          </div>
          <div
            className="mt-4 text-center"
            style={{ fontSize: 12, color: "#54575f" }}
          >
            Read Sentro's{" "}
            <Link color="primary" onClick={() => window.open(Path.TNC)}>
              Terms and Conditions
            </Link>
          </div>
        </>
      ) : (
        // PAYMENT
        <div id="payment" style={{ padding: isMobile ? "3vh 0" : "3vh 5em" }}>
          <div style={{ borderBottom: "2px dashed #d1d5dd" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ fontSize: 14, color: "#2b2d32" }}>Plan</div>
              {subscription === "2016" ? (
                <div
                  style={{ fontWeight: 600, fontSize: 14, color: "#2b2d32" }}
                >
                  ₱ 600.00{" "}
                  <span style={{ fontWeight: "normal" }}>/mo for 3 months</span>
                </div>
              ) : (
                <div
                  style={{ fontWeight: 600, fontSize: 14, color: "#2b2d32" }}
                >
                  ₱ 1,200.00 <span style={{ fontWeight: "normal" }}>/mo</span>
                </div>
              )}
            </div>
            <div className="d-flex pt-3 justify-content-between align-items-center">
              <div style={{ fontSize: 14, color: "#2b2d32" }}>12% VAT</div>
              <div style={{ fontWeight: 600, fontSize: 14, color: "#2b2d32" }}>
                {`₱ ${subscription === "2016" ? 216 : 144}.00`}
              </div>
            </div>
            <div className="d-flex justify-content-between py-4">
              <div style={{ fontSize: 16, color: "#2b2d32" }}>Total</div>
              <div style={{ fontWeight: 600, fontSize: 24, color: "#2b2d32" }}>
                {subscription === "2016" ? "₱ 2,016.00" : "₱ 1,344.00"}
              </div>
            </div>
          </div>
          {/*ERROR MESSAGE*/}
          {codWallet.usable_balance < subscription ? (
            <div className="mt-3">
              <MaterialUIAlert
                type="error"
                style={{ border: "1px solid #c0492c" }}
                message={
                  <div style={{ fontSize: 12, color: "#a83c23" }}>
                    Oops! Your <b>Payout wallet</b> has insufficient balance. Do
                    you want to use your <b>BUx wallet</b> to continue with the
                    payment?
                  </div>
                }
              />
            </div>
          ) : (
            ""
          )}

          <BuxWallet subscription={subscription} />
          <Divider
            className="py-2"
            style={{
              fontSize: 12,
              fontWeight: 300,
              color: "rgba(43, 45, 50, 0.64)"
            }}
          >
            or top up your Payout wallet
          </Divider>
          <CODWallet />
        </div>
      )}
    </div>
  );
}
