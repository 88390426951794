import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Layout from "./layout"
import CardHead from './components/cardHead'
import CountriesCard from './components/countriesCard'
import Path from '../../Paths'
import history from '../../store/history'
import { animateScroll as scroll } from "react-scroll";
import Proxtera from '../../assets/images/proxtera/proxtera.png'

const useStyles = theme => ({
  title: {
    fontWeight: "bold",
    fontSize: 28
  },
  paper: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
  },
  poweredBy: {
    fontSize: 12,
    color: "gray",
    marginRight: "1.5em"
  },
  proxteraIcon: {
    height: 32
  }
});

class Home extends React.Component {

  componentDidMount = () => {
    scroll.scrollTo(0)
  }

  render (){
    const { classes, children } = this.props;
    return (
      <Layout>
        <div className="d-flex align-items-center pb-3">
          <h2 className={`${classes.title}`}>Product Marketplace</h2>
          <div className="ml-auto d-flex align-items-center">
            <span className={classes.poweredBy}>Powered by</span>
            <img src={Proxtera} className={classes.proxteraIcon} alt=""/>
          </div>
        </div>
        <div className="mb-3">
          <CardHead onBrowse={() => history.push(Path.PROXTERA_PRODUCT_LIST)} />
        </div>
        <CountriesCard />
      </Layout>
    )
  }
}


const mapStateToProps = state => ({ marketplace }) => ({ 
//   apps: marketplace.apps
});
export default connect(mapStateToProps)(withStyles(useStyles)(Home));

