import * as english from "./faqAnswersEnglish";
import * as tagalog from "./faqAnswersTagalog";

export const sentro_faq1 = [
  {
    questionEnglish: `What is Sentro?`,
    questionTagalog: `Ano ang Sentro?`,
    answerEnglish: english.aboutSentroAnswer1,
    answerTagalog: tagalog.aboutSentroAnswer1
  },
  {
    questionEnglish: `Why was Sentro created?`,
    questionTagalog: `Bakit ginawa ang Sentro?`,
    answerEnglish: `Now that is a question worthy of a beauty pageant contest!  But seriously, Sentro was created to address the problems or issues of small business entrepreneurs, like you, here in the Philippines. Now that e-commerce has taken off in our country, we want to solve your concerns revolving around starting an online business, making your business digital, running your business day-to-day activities, increasing your reach and sales, making your business known locally and internationally, and penetrating overseas markets.  Yes, it’s true, Sentro was created for business owners like you!`,
    answerTagalog: `Grabe mars pang Ms. U ang tanong mo! Pero seryoso, ginawa ang Sentro para tulungan ang mga maliliit na negosyanteng gaya mo, rito sa Pilipinas. Ngayong umuusbong ang eCommerce sa bansa, gusto naming bigyan solusyon ang mga problema mong tungkol sa pagsisimula ng business online, pagiging digital ng iyong business, pagpapatakbo ng iyong negosyo araw-araw, pagpapataas ng iyong reach at sales, pagpapasikat ng iyong negosyo locally at internationally, at pagbebenta ng iyong produkto sa ibang bansa. Totoo, ginawa namin ang Sentro para sa'yo!`
  }
];

export const sentro_faq2 = [
  {
    questionEnglish: `Who owns Sentro?`,
    questionTagalog: `Sino ang may-ari ng Sentro?`,
    answerEnglish: `Sentro is owned by UBX Philippines Corporation (“UBX PH”).  UBX PH operates several platforms aimed for use of individuals, institutions and businesses.  UBX PH is registered with the Securities and Exchange Commission and has office address at 33/F UnionBank Plaza, Meralco Avenue corner Onyx and Sapphire Streets, Ortigas Center, Pasig City 1605, Philippines.`,
    answerTagalog: `Ang Sentro ay nasa paggmamay-ari ng UBX Philippines Corporation (“UBX PH”). UBX PH ay nagpapatakbo ng iba't-ibang platform na maaaring gamitin ng mga indibidwal, institusyon at negosyo. Ang UBX PH ay nakarehistro sa Securities and Exchange Commission at ang address ng opisina ay sa 33/F UnionBank Plaza, Meralco Avenue corner Onyx and Sapphire Streets, Ortigas Center, Pasig City 1605, Philippines.`
  },
  {
    questionEnglish: `How much does it cost to have a Sentro account?`,
    questionTagalog: `May bayad ba ito?`,
    answerEnglish: english.joiningSentroAnswer1,
    answerTagalog: tagalog.joiningSentroAnswer1
  },
  {
    questionEnglish: `What do I need to join Sentro?`,
    questionTagalog: `Ano ang mga kakailanganin ko upang magamit ang Sentro?`,
    answerEnglish: `Sentro works best with the latest version of Google Chrome with your laptop or desktop. In the future, Sentro may also be accessible via your mobile phone.`,
    answerTagalog: `Mas mahusay ang Sentro kapag ang iyong ginamit ay laptop o desktop, at ang pinakabagong bersyon ng Google Chrome.`
  },
  {
    questionEnglish: `Do I need to have an internet connection to access Sentro?`,
    questionTagalog: `Kaylangan ba ng Internet Connection ang paggamit ng Sentro?`,
    answerEnglish: `Yes, as Sentro is web-based, you need an internet connection to access it.`,
    answerTagalog: `Yes Fren, web-based po si Sentro kaya kailangan po nito ng Internet Connection.`
  }
];

export const sentro_faq3 = [
  {
    questionEnglish: `Who is the target market of Sentro?`,
    questionTagalog: `Sino ang target market ni Sentro?`,
    answerEnglish: `Sentro is ideal for small to medium enterprises who want to either jump-start, run or grow their business digitally. We made Sentro very user friendly.  You don’t need to be a techie or an IT person to use it.`,
    answerTagalog: `Pinaka-swak ang Sentro para sa mga maliliit na negosyanteng gustong magsimula, magpatakbo at magpalago ng kanilang negosyo. Ginawa naming user friendly ang Sentro kaya hindi mo na kailangang maging techie o IT-expert para gamitin si Sentro.`
  }
];

export const sentro_faq4 = [
  {
    questionEnglish: `Who are Sentro Partners?`,
    questionTagalog: `Sino-sino ang mga partners ni Sentro?`,
    answerEnglish: `Sentro Partners are various local or international fintechs or third party service providers that can improve your business management.  At present, we have the Monetary Authority of Singapore (MAS) and Infocomm Media Development, Microsoft through Crayon, UnionBank of the Philippines and HR Avatar, to name a few Sentro Partners.  Check your Sentro account regularly to see new Sentro Partners as we keep adding them for you.`,
    answerTagalog: `Mga local, international fintechs, o third party service providers ang aming mga partners. Ang current partners namin ay ang: Monetary Authority of Singapore (MAS) and Infocomm Media Development (IMDA), Microsoft through Crayon, Union Bank of the Philippines, HR Avatar, Ecfulfill, at Mr. Speedy. Magdadagdag pa kami ng iba pang mga partners para bigyan kayo ng mas bonggang serbisyo. Mag-login lang sa Sentro para makita ang iba't-iba naming mga partners.`
  }
];

export const sentro_faq5 = [
  {
    questionEnglish: `What are the features of Sentro Online Shop?`,
    questionTagalog: `Ano mga makukuha ko sa paggamit ng Sentro Online Shop?`,
    answerEnglish: english.aboutSentroShopAnswer1,
    answerTagalog: tagalog.aboutSentroShopAnswer1
  },
  {
    questionEnglish: `Do I have to pay anything to create my Online Shop?`,
    questionTagalog: `May bayad ba ang paggawa ng Online Shop?`,
    answerEnglish: english.aboutSentroShopAnswer2,
    answerTagalog: tagalog.aboutSentroShopAnswer2
  },
  {
    questionEnglish: `Do I need to know how to code or design to be able to create my online shop?`,
    questionTagalog: `Kailangan bang marunong akong mag-code o mag-disenyo upang makagawa ng aking online shop?`,
    answerEnglish: `Anyone can build their online shop in Sentro. No coding or design background is needed. No need to hire a programmer to maintain your shop as well. Simply provide the details you want to be shown in your online shop, publish it, and you're set.`,
    answerTagalog: `Sa Sentro, kahit sino pwedeng gumawa ng kanilang Online Shop. Hindi mo kailangan ng coding o design background para magamit ito. Hindi mo na rin kailangang magbayad ng programmer upang panatilihing maayos at gumagana ang iyong shop. Sagutin mo lamang ang mga hinihinging detalye sa Online Shop, i-publish ang shop mo, at tapos ka na - pwede ka nang magbenta!`
  },
  {
    questionEnglish: `I am not a techie, how can I create my Online Shop?`,
    questionTagalog: `Hindi ako techie, paano ako gagawa ng Online Shop?`,
    answerEnglish: `It’s okay! We know that 99% of folks do not how to code or design an online page. That is why Sentro is here to help your business exist not only through brick and mortar but in cyberspace as well.  In Sentro’s Online Shop, simply provide the details you want to be shown in your Online Shop, publish it and you’re all set! It’s as easy as 1-2-3…`,
    answerTagalog: `Okay lang yan! Alam namin na hindi pamilyar ang 99% ng tao sa coding at web design, kaya nandito si Sentro para tulungan kang gawing online ang business mo. Sa Online Shop ni Sentro, ilalagay mo lang ang mga importanteng detalye tungkol sa iyong negosyo, tapos pwede mo na itong i-publish at pwede ka nang magbenta. It's as easy as 1, 2, 3!`
  },
  {
    questionEnglish: `Do I need to have my own web address (domain) first before I build my Online Shop in Sentro?`,
    questionTagalog: `Kailangan ko bang magkaroon muna ng sariling web address (domain) bago ko magamit ang Online Shop builder?`,
    answerEnglish: `No need.  Once you have created your Online Shop in Sentro, it comes with your business’ unique web address (example: xyzshop.sentro.ph).`,
    answerTagalog: `Hindi na kailangan. Kapag gumawa ka ng online shop, automatic na may sarili na itong web address - subdmain (hal. xyzshop.sentro.ph).`
  },

  {
    questionEnglish: `In which countries can I operate my Sentro Online Shop?`,
    questionTagalog: `Saang mga bansa ko pwedeng mapatakbo ang aking ginawang online shop?`,
    answerEnglish: `As your created Sentro Online Shop is in the web, it can be viewed and accessed anywhere in the world.  However, at this time, its payment and future logistics functions can only provide services within the Philippines.`,
    answerTagalog: `Sa ngayon, ang Sentro Online Shop - pati ang mga payment at logistics functions - ay maaaring magamit lamang sa loob ng Pilipinas. Ngunit, ang iyong website ay maaaring makita kahit saan sa mundo.`
  },
  {
    questionEnglish: `What products can I sell in my Sentro Online Shop?`,
    questionTagalog: `Anong klaseng mga produkto ang pwede kong ibenta?`,
    answerEnglish: english.aboutSentroShopAnswer3,
    answerTagalog: tagalog.aboutSentroShopAnswer3
  },
  {
    questionEnglish: `Is there a limit as to the number of items I can upload in my Sentro Online Shop?`,
    questionTagalog: `Ilang produkto ang pwede kong i-upload sa aking online shop?`,
    answerEnglish: english.aboutSentroShopAnswer4,
    answerTagalog: tagalog.aboutSentroShopAnswer4
  },
  {
    questionEnglish: `Can I edit or update my Sentro Online Shop after I publish it?`,
    questionTagalog: `Pwede ko pa bang i-edit o i-update ang aking online shop pagkatapos ko itong i-publish?`,
    answerEnglish: `Oh yes! You can edit and update your Sentro Online Shop any time and as often as you wish.`,
    answerTagalog: `Oo naman! Pwede mo pa ring i-edit ang iyong online shop anumang oras.`
  },
  {
    questionEnglish: `Can I create a 2nd online shop in Sentro?`,
    questionTagalog: `Pwede ba ako gumawa ng 2nd Online Shop?`,
    answerEnglish: `We are continuously working to add more features like multi-shops, so stay tuned!`,
    answerTagalog: `Hindi po fren, pero baka sa future maging kayo na ng crush mo, este, pwede ka na gumawa ng pangalawang Online Shop!`
  },
  {
    questionEnglish: `Can I delete my Sentro Online Shop?`,
    questionTagalog: `Pwede ko bang i-delete ang aking online shop?`,
    answerEnglish: `Yes, of course. The power to create carries the power to delete.  But once you have deleted it, you cannot recover it anymore.  You have to create a new one all over again.`,
    answerTagalog: `Oo, maaari mong isara o i-delete ang iyong online shop. Kapag na-delete mo na ito, hindi mo na ito mababawi. Kung nais mong ibalik o magkaroon ng bago, kailangan mong gumawa ng bagong online shop.`
  },
  {
    questionEnglish: `How do I change my Online Shop's Color Pattern?`,
    questionTagalog: `Paano palitan yung color pattern ng Online Shop ko?`,
    answerEnglish: `To change your Online Shop's Color Pattern, click on the "Edit" button in your previously created Online Shop, then proceed to the "Branding" step and choose your preferred color pattern under "Color".`,
    answerTagalog: `Para mapalitan ang Color Pattern ng Online Shop mo, i-click ang "Edit" button sa iyong nagawang Online Shop. Pagkatapos, pumunta sa "Branding" na step at pumili ng gusto mong Color Pattern sa "Color" section.`
  },
  {
    questionEnglish: `How do I change my Product's Stock Quantity?`,
    questionTagalog: `Paano palitan ang Stock Quantity ng products ko?`,
    answerEnglish: `To change or update your Product's stock quantity, click on the "Edit" button in your Online Shop and proceed to the "Products" step, then click on the "Edit" button of a specific product,  and edit the value under "Stocks on hand". If you always have stocks on hand, you can simply toggle the "Always available" button so that you don't need to keep changing your stock quantity.`,
    answerTagalog: `Para mapalitan o i-update ang stock quantity ng products mo, i-click ang "Edit" button sa iyong Sentro dashboard at pumunta sa "Products" step. Pagkatapos nun, click mo yung "Edit" button sa ilalim ng product mo para mapalitan ang "Stocks on Hand". Kung palagi namang may stocks ang produkto mo, puwede mong i-toggle ang "Always available" bbutton para hindi mo na kaylangang i-update ang "Stocks on hand" mo.`
  },
  {
    questionEnglish: `Do you have a short tutorial video on how to use Sentro?`,
    questionTagalog: `May tutorial video ba si Sentro?`,
    answerEnglish: english.aboutSentroShopAnswer5,
    answerTagalog: tagalog.aboutSentroShopAnswer5
  },
  {
    questionEnglish: `Can I add more photos for my products?`,
    questionTagalog: `Pwede ba akong mag-add ng maraming pictures para sa isang product?`,
    answerEnglish: `Yes, you can! Click the Edit Online Shop button then proceed to the products step. Choose which product you wish to edit and begin uploading different pictures of your product. You can upload up to 5 photos per product.`,
    answerTagalog: `Pwedeng pwede! I-click ang Edit Online Shop button tapus pumunta sa Products step. Piliin kung anong product ang gusto mong i-edit at magsimula nang mag upload ng iba't-ibang pictures ng iyong product. Pwede kang maglagay ng 5 pictures per product.`
  }
];

export const sentro_faq6 = [
  {
    questionEnglish: `If my Sentro Online Shop has buyers, how can they pay for their purchases?`,
    questionTagalog: `Paano magbabayad ang aking mga customer mula sa aking online shop?`,
    answerEnglish: english.purchaseAnswer1,
    answerTagalog: tagalog.purchaseAnswer1
  },
  {
    questionEnglish: `Are the payment and logistics gateway also for free?`,
    questionTagalog: `Libre din po ba ang kasamang payment gateway (Bux) at logistics service?`,
    answerEnglish: `There are no set-up fees to have a payment and logistics gateway in your Sentro Online Shop; however, there are fees when these gateways are used. You have to pay a certain amount for every transaction that you make using the payment or logistics gateway. You shall see the amount prior to confirmation of the transaction.`,
    answerTagalog: `Walang set-up fee ang pagkakaroon mo ng payment gateway at logistics service sa iyong Sentro Online Shop. Ngunit, may bayad ang mga ito kung gagamitin mo. May mga transaction fees kay Bux depende sa payment channel na pinili ng customer mo. Depende naman sa distance ng delivery ang bayad sa logistics service. Makikita mo kung magkano ang transaction fees sa checkout page.`
  },
  {
    questionEnglish: `Where will my buyers’ payment go?`,
    questionTagalog: `Saan napupunta ang pera kapag nagbayad ang customer?`,
    answerEnglish: `All payments made in your Sentro Online Shop, whether bank transfer, credit card or debit card, or cash, will be credited to your Bux account. You can view the balance of your account by going to the Sales and Payments menu in your Sentro account.`,
    answerTagalog: `Lahat ng bayad - online man o cash - ay mapupunta sa iyong Bux account. Maaari mong tingnan ang balanse ng iyong account sa pamamagitan ng pag-click sa "Sales and Payments" sa loob ng iyong Sentro account.`
  },
  {
    questionEnglish: `How can I withdraw the money that has been paid to me from my Sentro Online Shop sales?`,
    questionTagalog: `Paano ko makukuha ang pera kong galing sa online shop sales?`,
    answerEnglish: `You can withdraw the funds from your Bux account and have it transferred to your own bank account.  Your bank account has to be with a local bank in the Philippines.`,
    answerTagalog: `Maaari mong makuha ang pera mula sa iyong mga benta sa Online Shop sa pamamagitan ng pag-withdraw ng pondo mula sa iyong Bux account at pa-deposit deretso sa iyong napiling bangko o online wallet.`
  }
];

export const sentro_faq7 = [
  {
    questionEnglish: `How do I ship my orders?`,
    questionTagalog: `Paano ko maipapa-ship ang aking orders?`,
    answerEnglish: `Go to your Orders and Deliveries Dashboard found on the left side of the screen on Desktop or on the bottom right-hand corner of your Dashboard on Mobile. For Desktop, click on the three dots on your specific order then click on "Request for Delivery". For Mobile, on the specific order you wish to book for delivery and tap "Book for delivery". Then proceed to fill out the necessary details to complete your delivery request. Once you're done filling out the necessary details, click on "Book Delivery" and wait for the rider to arrive.`,
    answerTagalog: `Sa iyong Sentro dashboard, i-click ang "Orders and Deliveries" tab, tapos i-click mo ang tatlong dots sa tabi ng iyong order. Piliin ang "Request for Delivery" at sagutan ang mga detalyeng hinihingi para sa delivery request. Pagkatapos mong sagutan lahat, i-click mo lang ang "Book Delivery" at hintaying dumating ang rider.`
  },
  {
    questionEnglish: `How can I see a list of all my orders?`,
    questionTagalog: `Saan ko makikita ang listahan ng aking mga orders?`,
    answerEnglish: `Go to your Orders and Deliveries Dashboard found on the left side of the screen on Desktop or on the bottom right-hand corner of your Dashboard on Mobile. You will then be redirected to your Orders and Deliveries dashboard where you will see a list of all your orders and the status of each order.`,
    answerTagalog: `Sa iyong Sentro dashboard, i-click ang "Orders and Deliveries" tab (nasa kaliwang bahagi ng screen) at doon mo makikita ang listahan ng orders ng iyong customers.`
  },
  {
    questionEnglish: `Who is your partnered logistics provider?`,
    questionTagalog: `Sino ang partnered logistics service provider ni Sentro?`,
    answerEnglish: `Sentro has partnered with Mr. Speedy so you can instantly ship the orders of your customers. Soon, we'll have more logistics partners so you have the option to choose.`,
    answerTagalog: `Nakipag-partner kami kay Mr Speedy para pwede niyo ng i-ship agad ang orders ng inyong mga customers. Magdadagdag pa kami ng iba pang logistics service provider para mas marami kayong pagpipilian.`
  },
  {
    questionEnglish: `Do I need to create a Mr. Speedy account to book my deliveries?`,
    questionTagalog: `Kailangan ko bang gumawa ng account kay Mr. Speedy para mag book ng deliveries?`,
    answerEnglish: `You don't need to create your own Mr Speedy account to book your deliveries as long as you book them within the Sentro site.`,
    answerTagalog: `Hindi mo na kailangang gumawa ng account kay Mr. Speedy. Pwede mo na itong magamit sa loob ng Sentro.`
  },
  {
    questionEnglish: `Where can I see my Order's delivery status?`,
    questionTagalog: `Saan ko makikita ang delivery status ng aking orders?`,
    answerEnglish: `(For Desktop) On your Sentro Dashboard you will see your Orders and Deliveries tab on the left side of your browser. (For Mobile) On the top-right part of your screen, you will see beside your name a button with three lines on top of each other. Tap on that to see your Orders and Deliveries tab.`,
    answerTagalog: `(Sa Desktop) Sa iyong Sentro Dashboard may makikita kang Orders and Deliveries tab sa kaliwang bahagi ng screen. (Sa Mobile) Sa top right ng screen, i-tap ang tatlong magkakapatanong na linya. Pagkatapos, i-tap ang "Orders and Deliveries" para mapunta sa dashboard nito.`
  }
];

export const sentro_faq8 = [
  {
    questionEnglish: `What is the Apps and Services Marketplace?`,
    questionTagalog: `Ano ang Apps and Services Marketplace?`,
    answerEnglish: `The Apps and Services Marketplace, is where you can avail of various apps and tools from our partners that can help you with running your business. `,
    answerTagalog: `Ang Apps and Services Marketplace, ay kung saan ka pwede kang mamili ng iba't-ibang mga applications at tools galing sa aming mga partners na makatutulong sa iyo sa pagpapatakbo ng iyong negosyo. `
  },
  {
    questionEnglish: `How can I buy services from the Apps and Services Marketplace?`,
    questionTagalog: `Paano bumili ng products sa App and Services Marketplace?`,
    answerEnglish: `Click on the Apps and Services Marketplace tab located on the left side of your Sentro Dashboard, choose among the available brands, select a product, proceed to checkout, and then fulfill payment via Bux.`,
    answerTagalog: `I-click ang Apps & Services tab sa kaliwang bahagi ng iyong Sentro dashboard, pumili ng brand, pumili ng product, proceed to checkout, at magbayad gamit ang Bux.`
  },
  {
    questionEnglish: `I did not receive the service I purchased from the Apps and Services Marketplace`,
    questionTagalog: `Hindi ko natanggap ang binili ko na produkto sa App Marketplace`,
    answerEnglish: `To report an issue with your Apps and Services Marketplace purchase, kindly send an email to support@sentro.ph for further assistance.`,
    answerTagalog: `Para mag report ng issue tungkol sa Apps and Services Marketplace, mag send ng email sa support@sentro.ph.`
  },
  {
    questionEnglish: `I am unable to checkout the service(s) in my cart`,
    questionTagalog: `Hindi ako makapag checkout ng aking cart`,
    answerEnglish: `To report an issue with your Apps and Services Marketplace purchase, kindly send an email to support@sentro.ph for further assistance.`,
    answerTagalog: `Para mag report ng issue tungkol sa Apps and Services Marketplace, mag send ng email sa support@sentro.ph.`
  },
  {
    questionEnglish: `I am unable to pay using Bux`,
    questionTagalog: `Hindi ako makapagbayad gamit ang Bux`,
    answerEnglish: `For Bux related concerns, kindly send an email to support+bux@ubx.ph so that they may assist you with your query.`,
    answerTagalog: `Para sa mga Bux-related concerns, mag send ng email sa support+bux@ubx.ph.`
  }
];

export const sentro_faq9 = [
  {
    questionEnglish: `How can I contact Sentro for comments, issues or suggestions?`,
    questionTagalog: `Paano ako pwedeng magbigay ng comments, issues at suggestions tungkol sa Sentro?`,
    answerEnglish: `You can contact us through email at: support@sentro.ph. We reply during Mondays to Fridays, except holidays, from 9:00am to 5:00pm, Philippine Standard Time. Oh yes, we’d also love to hear your experience and success stories with Sentro through the same channel.`,
    answerTagalog: `Pwede kang mag send ng email sa support@sentro.ph.  Nagrereply kami tuwing Lunes hanggang Biyernes, bukod sa mga holidays, mula 9:00am hanggang 5:00pm, Philippine Standard Time. Gusto rin naming marinig ang inyong mga karanasan at success stories gamit ang aming email address.`
  }
];

export const sentro_faq10 = [
  {
    questionEnglish: `How to add Facebook Messenger to my Sentro Online Shop?`,
    questionTagalog: `Paano i-add ang Facebook Messenger sa aking Sentro Online Shop?`,
    answerEnglish: `On your Sentro dashboard, look for the "Chat to your clients on your shop" and click on "Learn More". Simply follow the steps provided and click submit when you're done.`,
    answerTagalog: `Sa iyong Sentro dashboard, hanapin ang "Chat to your clients on your shop" at i-click and "Learn More." Sundin lamang ang mga steps na ibinigay at i-click ang submit kapag tapos ka na.`
  }
];

export const sentro_faq11 = [
  {
    questionEnglish: `Where can I see all of Sentro's emails, announcements?`,
    questionTagalog: `Saan ko makikita ang mga emails at announcements ng Sentro?`,
    answerEnglish: `In your Sentro dashboard, click on your name on the top right-hand corner and choose "Announcements" in the dropdown. You will be redirected to a page with all of Sentro's current and previous emails on features, issues, and other announcements. `,
    answerTagalog: `Sa iyong Sentro dashboard, i-click ang iyong pangalan sa top right corner ng page at piliin ang "Announcements" sa dropdown. Dito, makikita mo ang lahat ng mga bago at lumang emails tungkol sa features, issues at iba pang announcements ng Sentro.`
  }
];
// export const gettingStarted = [
//   {
//     questionEnglish: `What is Sentro?`,
//     questionTagalog: `Ano ang Sentro?`,
//     answerEnglish: english.gettingStartedAnswer1,
//     answerTagalog: tagalog.gettingStartedAnswer1
//   },
//   {
//     questionEnglish: `Do I need to pay to create an account?`,
//     questionTagalog: `May bayad ba ito?`,
//     answerEnglish: english.gettingStartedAnswer2,
//     answerTagalog: tagalog.gettingStartedAnswer2
//   },
//   {
//     questionEnglish: `What do I need to have so I can use Sentro? `,
//     questionTagalog: `Ano ang mga kakailangin ko upang magamit ang Sentro?`,
//     answerEnglish: english.gettingStartedAnswer3,
//     answerTagalog: tagalog.gettingStartedAnswer3
//   }
// ];

// export const createShop = [
//   {
//     questionEnglish: `What are the features of a Sentro online shop?`,
//     questionTagalog: `Ano mga makukuha ko sa paggamit ng Sentro Online Shop?`,
//     answerEnglish: english.createShopAnswer1,
//     answerTagalog: tagalog.createShopAnswer1
//   },
//   {
//     questionEnglish: `How much is it to create an online shop? `,
//     questionTagalog: `May bayad ba ang paggawa ng Online Shop?`,
//     answerEnglish: english.createShopAnswer2,
//     answerTagalog: tagalog.createShopAnswer2
//   },
//   {
//     questionEnglish: `Do I need to know how to code or design to be able to create my online shop?`,
//     questionTagalog: `Kailangan bang marunong akong mag-code o mag-disenyo upang makagawa ng aking online shop?`,
//     answerEnglish: english.createShopAnswer3,
//     answerTagalog: tagalog.createShopAnswer3
//   },
//   {
//     questionEnglish: `Do I need to have my web address (domain) first before I can use the online shop builder? `,
//     questionTagalog: `Kailangan ko bang magkaroon muna ng sariling web address (domain) bago ko magamit ang Online Shop builder?`,
//     answerEnglish: english.createShopAnswer4,
//     answerTagalog: tagalog.createShopAnswer4
//   },
//   {
//     questionEnglish: `In which countries can I operate my online shop?`,
//     questionTagalog: `Saang mga bansa ko pwedeng mapatakbo ang aking ginawang online shop?`,
//     answerEnglish: english.createShopAnswer5,
//     answerTagalog: tagalog.createShopAnswer5
//   }
// ];

// export const sellingProducts = [
//   {
//     questionEnglish: `What type of products can I sell?`,
//     questionTagalog: `Anong klaseng mga produkto ang pwede kong ibenta?`,
//     answerEnglish: english.sellingProductsAnswer1,
//     answerTagalog: tagalog.sellingProductsAnswer1
//   },
//   {
//     questionEnglish: `Up to how many products can I upload in my online shop?`,
//     questionTagalog: `Ilang produkto ang pwede kong i-upload sa aking online shop?`,
//     answerEnglish: english.sellingProductsAnswer2,
//     answerTagalog: tagalog.sellingProductsAnswer2
//   },
//   {
//     questionEnglish: `Can I still edit or update my online shop after I publish it?`,
//     questionTagalog: `Pwede ko pa bang i-edit o i-update ang aking online shop pagkatapos ko itong i-publish?`,
//     answerEnglish: english.sellingProductsAnswer3,
//     answerTagalog: tagalog.sellingProductsAnswer3
//   },
//   {
//     questionEnglish: `Can I delete my online shop?`,
//     questionTagalog: `Pwede ko bang i-delete ang aking online shop?`,
//     answerEnglish: english.sellingProductsAnswer4,
//     answerTagalog: tagalog.sellingProductsAnswer4
//   }
// ];

// export const gettingPayments = [
//   {
//     questionEnglish: `How can the buyers pay their purchases from my online shop?`,
//     questionTagalog: `Paano magbabayad ang aking mga customer mula sa aking online shop?`,
//     answerEnglish: english.gettingPaymentsAnswer1,
//     answerTagalog: tagalog.gettingPaymentsAnswer1
//   },
//   {
//     questionEnglish: `Where does the money go when buyers make the payment?`,
//     questionTagalog: `Saan napupunta ang pera kapag nagbayad ang customer?`,
//     answerEnglish: english.gettingPaymentsAnswer2,
//     answerTagalog: tagalog.gettingPaymentsAnswer2
//   },
//   {
//     questionEnglish: `How can I claim the money I've earned from my online shop sales?`,
//     questionTagalog: `Paano ko makukuha ang pera kong galing sa online shop sales?`,
//     answerEnglish: english.gettingPaymentsAnswer3,
//     answerTagalog: tagalog.gettingPaymentsAnswer3
//   }
// ];

// export const shippingAndDelivery = [
//   {
//     questionEnglish: `How do I ship my orders?`,
//     questionTagalog: ``,
//     answerEnglish: `Go to your Orders and Deliveries Dashboard and click on the options button. Click on Request for Delivery then proceed to fill out the necessary details to complete your delivery request.`,
//     answerTagalog: ``
//   },
//   {
//     questionEnglish: `How do I access my Orders and Deliveries Dashboard?`,
//     questionTagalog: ``,
//     answerEnglish: `To access your Orders and Deliveries Dashboard, click on your Orders and Deliveries tab and you will be directed to the dashboard where you will see the statuses of all your orders.`,
//     answerTagalog: ``
//   },
//   {
//     questionEnglish: `Who is your partnered logistics provider?`,
//     questionTagalog: ``,
//     answerEnglish: `Sentro has partnered with Mr. Speedy to provide you a built-in logistics service provider where you can handle shipping of your products to your customers within your Sentro account.`,
//     answerTagalog: ``
//   },
//   {
//     questionEnglish: `Do I need to create a Mr. Speedy account to book my deliveries?`,
//     questionTagalog: ``,
//     answerEnglish: `A Mr. Speedy account is not necessary for you to be able to fulfill your shipping needs.`,
//     answerTagalog: ``
//   },
//   {
//     questionEnglish: `Where can I see my Order's delivery status?`,
//     questionTagalog: ``,
//     answerEnglish: `(For Desktop) On your Sentro Dashboard you will see your Orders and Deliveries tab on the left side of your browser. (For Mobile) On the top-right part of your screen, you will see beside your name a "more" button. Tap on that to see your Orders and Deliveries tab.`,
//     answerTagalog: ``
//   }
// ];

// export const createShop = [
//   {
//     question: `What are the features of a Sentro online shop?`,
//     answer: FAQ.joiningSentroAnswer1
//   },
//   {
//     question: `How much is it to create an online shop? `,
//     answer: FAQ.joiningSentroAnswer2
//   },
//   {
//     question: `Do I need to know how to code or design to be able to create my online shop?`,
//     answer: FAQ.joiningSentroAnswer3
//   },
//   {
//     question: `Do I need to have my web address (domain) first before I can use the online shop builder? `,
//     answer: FAQ.joiningSentroAnswer4
//   },
//   {
//     question: `In which countries can I operate my online shop? `,
//     answer: FAQ.joiningSentroAnswer5
//   }
// ];

// export const sellingProducts = [
//   {
//     question: `What type of products can I sell?`,
//     answer: FAQ.useSentroAnswer1
//   },
//   {
//     question: `Up to how many products can I upload in my online shop?`,
//     answer: FAQ.useSentroAnswer2
//   },
//   {
//     question: `Can I still edit or update my online shop after I publish it?`,
//     answer: FAQ.useSentroAnswer3
//   },
//   {
//     question: `Can I delete my online shop?`,
//     answer: FAQ.useSentroAnswer4
//   }
// ];

// export const gettingPayments = [
//   {
//     question: `How can the buyers pay their purchases from my online shop?`,
//     answer: FAQ.insideSentroAnswer1
//   },
//   {
//     question: `Where does the money go when buyers make the payment?`,
//     answer: FAQ.insideSentroAnswer1
//   },
//   {
//     question: `How can I claim the money I've earned from my online shop sales?`,
//     answer: FAQ.insideSentroAnswer1
//   }
// ];

// export const aboutSentroShop = [
//   {
//     question: `What are the features of Sentro Online Shop?`,
//     answer: FAQ.aboutSentroShopAnswer1
//   },
//   {
//     question: `Do I have to pay anything to create my Online Shop?`,
//     answer: FAQ.aboutSentroShopAnswer2
//   },
//   {
//     question: `But I do not know anything about building an Online Shop...!!!`,
//     answer: FAQ.aboutSentroShopAnswer3
//   },
//   {
//     question: `Do I need to have my own web address (domain) first before I build my Online Shop in Sentro?`,
//     answer: FAQ.aboutSentroShopAnswer4
//   },
//   {
//     question: `In which countries can I operate my Sentro Online Shop?`,
//     answer: FAQ.aboutSentroShopAnswer5
//   },
//   {
//     question: `What products can I sell in my Sentro Online Shop?`,
//     answer: FAQ.aboutSentroShopAnswer6
//   },
//   {
//     question: `Is there a limit as to the number of items I can upload in my Sentro Online Shop?`,
//     answer: FAQ.aboutSentroShopAnswer7
//   },
//   {
//     question: `Can I edit or update my Sentro Online Shop after I publish it?`,
//     answer: FAQ.aboutSentroShopAnswer8
//   },
//   {
//     question: `Can I create a 2nd online shop in Sentro?`,
//     answer: FAQ.aboutSentroShopAnswer9
//   },
//   {
//     question: `Can I delete my Sentro Online Shop?`,
//     answer: FAQ.aboutSentroShopAnswer10
//   }
// ];

// export const purchaseAndPayments = [
//   {
//     question: `If my Sentro Online Shop has buyers, how can they pay for their purchases?`,
//     answer: FAQ.purchaseAndPaymentsAnswer1
//   },
//   {
//     question: `What is the Sales and Orders tab in Sentro Online Shop?`,
//     answer: FAQ.purchaseAndPaymentsAnswer2
//   },
//   {
//     question: `Are the payment and logistics gateway also for free?`,
//     answer: FAQ.purchaseAndPaymentsAnswer3
//   },
//   {
//     question: `Where will my buyers’ payment go?`,
//     answer: FAQ.purchaseAndPaymentsAnswer4
//   },
//   {
//     question: `How can I withdraw the money that has been paid to me from my Sentro Online Shop sales?`,
//     answer: FAQ.purchaseAndPaymentsAnswer5
//   }
// ];

// export const miscellaneous = [
//   {
//     question: `How can I contact Sentro for comments, issues or suggestions?`,
//     answer: FAQ.miscellaneousAnswer1
//   }
// ];
export default {
  sentro_faq1,
  sentro_faq2,
  sentro_faq3,
  sentro_faq4,
  sentro_faq5,
  sentro_faq6,
  sentro_faq7,
  sentro_faq8,
  sentro_faq9,
  sentro_faq10,
  sentro_faq11
  // gettingStarted,
  // createShop,
  // sellingProducts,
  // gettingPayments
  // createShop,
  // sellingProducts,
  // gettingPayments
  // useSentro,
  // insideSentro,
  // aboutSentroShop,
  // purchaseAndPayments,
  // miscellaneous
};
