import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import DashboardLayout from "../dashboard/dashboardLayout";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from "../../store/history";
import VariantDetailed from "./components/variantDetailed";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Actions from "./Redux/Actions";
import Path from "../../Paths";
import Utils from '../common/utils'
import { animateScroll as scroll } from 'react-scroll'


const useStyles = theme => ({
  root: {
    padding: "1em 5em 0",
    [theme.breakpoints.down("sm")]: {
      padding: "1em 3em 0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1em 2em 0",
    },
    paddingBottom: "0.5em",
    backgroundColor: "white"
  },
  backDivWrapper: {
    margin: "0 0 1em",
    display: "flex",
    "& > span": {
      marginLeft: "1em",
      fontWeight: 600
    },
    color: "#F97663 !important",
    alignItems: "center",
    cursor: "pointer",
    width: 100
  },
  cover: {
    // backgroundImage: "url('https://businesssimulations.com/img/covers/2019/screen.jpg')",
    minHeight: "100%", width: "100%", backgroundSize: 'cover',
    backgroundPosition: 'center', borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopRightRadius: 0,
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 8,
    },
  },
  variationdiv: {
    padding: "3.5em",
    minHeight: "85vh"
  },
  title: {
    fontSize: 34,
    fontWeight: "bold",
    color: '#002C42'
  },
  radioSet: {
    display: "flex",
    flexDirection: "column",
  },
  radio: {
    transition: "all 0.2s ease",
    cursor: "pointer",
    margin: "5px 0",
    background: '#FFFFFF',
    border: '2px solid #F97663',
    borderRadius: 4,
    color: "#F97663",
    fontWeight: "bold",
    fontSize: 16,
    padding: "0.65em 1em",
    display: "flex",
    alignItems: "center",
    "& span:last-child": {
      marginLeft: "auto"
    }
  },
  selectedRadio: {
    background: "#C24A39",
    color: "white"
  },
  disabled: {
    color: "rgba(0,0,0,0.15)",
    border: '2px solid rgba(0,0,0,0.15)',
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  price: {
    color: "#54575F",
    fontSize: 32,
    fontWeight: 600
  },
  priceAndCheckoutBtnWrapper: {
    display: "flex",
    margin: "1.5em 0"
  },
  checkoutButton: {
    marginLeft: "auto"
  },
  variantDetailedDiv: {
    padding: "3em 6em",
    [theme.breakpoints.down("md")]: {
      padding: "3em 0"
    },
  }
});

class MarketplacePackages extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selected: 0
    }
    this.proceedToCheckout = this.proceedToCheckout.bind(this)
  }

  preSelectValidVariant(variants){
    let index = 0;
    while(1){
      const variant = variants[index];
      if(!(parseFloat(variant.price) === 0 && variant.is_availed)) {
        break;
      }
      index++;
    }
    this.setState({
      selected: index
    })
  }

  componentDidMount = async () => {
    const { dispatch, match } = this.props;
    const app = await dispatch(Actions.fetchApp(match.params.id));
    if(app.variants){
      this.preSelectValidVariant(app.variants)
    }
    scroll.scrollTo(0)
  }

  proceedToCheckout(){
    const { dispatch, app } = this.props;
    const { variants } = app;
    const selected_variant = variants[this.state.selected];
    const name = app.name + "-" + selected_variant.name;
    const temp_qty = 1;
    const item = {
      app: app.id,
      app_name: app.name,
      logo: app.logo,
      variant: selected_variant.id,
      variant_name: selected_variant.name,
      unit_price: selected_variant.price,
      timeframe: selected_variant.timeframe,
      quantity: temp_qty,
      name,
      amount: selected_variant.price * temp_qty
    }
    dispatch(Actions.setToCart(item))
    history.push(Path.APP_MARKETPLACE_CART)
  }

  render (){
    const { classes, app, loading } = this.props;
    const { selected } = this.state;
    return (
      <DashboardLayout margin="0" marginTop="68px" right="0" left="0" backgroundColor="white">
        {
          (!app && !loading) ? (
            <div style={{ height: "70vh", width: "100%", overflow: "hidden" }}>
              <div style={{ paddingTop: "10%" }}>
                <div style={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <img
                    style={{width: "15%", height: "15%"}}
                    src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
                  />
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 40,
                    fontSize: 18,
                    fontWeight: 600,
                    color: "#b8bcc3",
                    padding: "0 3em",
                    textAlign: "center"
                }}>
                  Not found
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className={classes.root}>
                <div className={classes.backDivWrapper} onClick={() => history.goBack()}>
                  <ArrowBackIcon />
                  <span>BACK</span>
                </div>
                <h3><strong>{app.name} / Products</strong></h3>
                <Paper elevation={6} style={{ width: "100%", borderRadius: 8 }}>
                  <Grid container spacing={0} style={{ height: "100%", borderRadius: 8}}>
                    {
                      // if has banner image
                      app.variants_banner ?
                      <>
                        <Grid item sm={12} md={6}>
                          <div className={classes.cover} style={{ backgroundImage: `url('${app.variants_banner}')` }} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <div className={classes.variationdiv}>
                            <h1 className={classes.title}>{app.name}</h1>
                            {
                              app && <div dangerouslySetInnerHTML={{
                                  __html: app.features
                              }}></div>
                            }
                            {
                              app.variants.length > 1 &&
                              <p>Select package</p>
                            }
                            <div className={classes.radioSet}>
                              {
                                app ? 
                                  app.variants.length > 1 ?
                                  app.variants.map((variant, index) => {
                                    const price = Utils.commafy(variant.price);
                                    const is_disabled = parseFloat(variant.price) === 0 && variant.is_availed;
                                    return (
                                      <div key={index} className={`${classes.radio} ${selected===index ? classes.selectedRadio: ``}
                                      ${is_disabled && classes.disabled}`}
                                      onClick={() => !is_disabled && this.setState({selected: index})}>
                                        <span>{variant.name}</span>
                                        <span className="pl-3" style={{ minWidth: 140 }}>{
                                        variant.price > 0 ? 
                                          variant.timeframe==="one_time" ? 
                                          `PHP ${price}`: 
                                              ["daily", "monthly", "yearly"].includes(variant.timeframe) ? 
                                              `PHP ${price} per ${variant.timeframe.slice(0,-2)}`
                                              :
                                              `PHP ${price} ${["3_months", "6_months", "9_months", "12_months"].includes(variant.timeframe) ? `for` : `per`} ${variant.timeframe.split("_").join(" ")}`
                                        : 
                                        "FREE"
                                        }</span>
                                      </div>
                                    )})
                                  :
                                  ``
                                :
                                ``
                              }
                            </div>
                            <div className={classes.priceAndCheckoutBtnWrapper}>
                              {
                                app ? 
                                  app.variants ?
                                  <p className={classes.price}>{app.variants[selected].price > 0 ? `PHP ${Utils.commafy(app.variants[selected].price)}`: "FREE"}</p>
                                  :
                                  ``
                                :
                                ``
                              }
                              <Button variant="contained" color="primary" className={classes.checkoutButton}
                              onClick={() => {
                                this.proceedToCheckout()
                              }}>Proceed to checkout</Button>
                            </div>
                            {/* <p>Want to test it first? No problem.</p>
                            <Button variant="outlined" color="primary" disabled>Try for Free</Button> */}
                          </div>
                        </Grid>
                      </>
                      :
                      <div className={classes.variationdiv} style={{ width: "100%", marginTop: "1.5em"}}>
                        <Grid item sm={12}>
                          <h1 className={classes.title}>{app.name}</h1>
                          <p>{app.short_description}</p><br />
                          <p>Select package</p>
                        </Grid>
                        <Grid container direction="row">
                          {
                            app ? 
                              app.variants.length > 1 ?
                              app.variants.map((variant, index) => {
                                const price = Utils.commafy(variant.price);
                                const is_disabled = parseFloat(variant.price) === 0 && variant.is_availed;
                                return (
                                  <Grid item sm={12} md={6}>
                                    <div key={index} className={`${classes.radio} ${selected===index ? classes.selectedRadio: ``}
                                    ${is_disabled && classes.disabled}`}
                                    onClick={() => !is_disabled && this.setState({selected: index})} style={{ margin: 5}}>
                                      <span>{variant.name}</span>
                                      <span>{
                                      variant.price > 0 ? 
                                        variant.timeframe==="one_time" ? 
                                        `PHP ${price}`: 
                                            ["daily", "monthly", "yearly"].includes(variant.timeframe) ? 
                                            `PHP ${price} per ${variant.timeframe.slice(0,-2)}`
                                            :
                                            `PHP ${price} ${["3_months", "6_months", "9_months", "12_months"].includes(variant.timeframe) ? `for` : `per`} ${variant.timeframe.split("_").join(" ")}`
                                      : 
                                      "FREE"
                                      }</span>
                                    </div>
                                  </Grid>
                                )})
                              :
                              ``
                            :
                            ``
                          }
                        </Grid>
                        <Grid item sm={12} md={6} style={{ marginLeft: "auto"}}>
                            <div className={classes.priceAndCheckoutBtnWrapper}>
                              {
                                app ? 
                                  app.variants ?
                                  <p className={classes.price}>{app.variants[selected].price > 0 ? `PHP ${Utils.commafy(app.variants[selected].price)}`: "FREE"}</p>
                                  :
                                  ``
                                :
                                ``
                              }
                              <Button variant="contained" color="primary" className={classes.checkoutButton}
                              onClick={() => {
                                this.proceedToCheckout()
                              }}>Proceed to checkout</Button>
                            </div>
                            {/* <p>Want to test it first? No problem.</p>
                            <Button variant="outlined" color="primary" disabled>Try for Free</Button> */}
                        </Grid>
                        
                      </div>
                    }
                  </Grid>
                </Paper>
                <div className={classes.variantDetailedDiv}>
                  {
                    app ? 
                      app.variants ?
                      app.variants.map((variant, index) => (
                        variant.description ? 
                        <VariantDetailed data={variant} onGet={() => {
                          this.setState({selected: index}, () => {
                            this.proceedToCheckout()
                          })
                        }}/>
                        :
                        ``
                      ))
                      :
                      ``
                    :
                    ``
                  }
                </div>
              </div>
            </div>
          )
        }
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({ marketplace }) => ({ 
  app: marketplace.app,
  loading: marketplace.loading
});
export default connect(mapStateToProps)(withStyles(useStyles)(MarketplacePackages));

