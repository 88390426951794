import React from 'react'


// countdown custom hook
// config: {
//    save_to_local: <bool>,
//    module: <str>,         <-- used as key in saving to localstorage if save_to_local true
//    key: <str>             <-- used as key in saving to localstorage if save_to_local true
//    endTimerAction: <fn>   <-- will be called once timer reaches zero
// }
// returns [
//    secondsLeft: <int>,           <-- returns seconds
//    setSecondsLeft: <fn>,         <-- functions for re-setting seconds
//    resendCountdownDisplay: <dom> <-- returns DOM countdown display <p>minute:seconds</p>
// ]
export const useCountdownTimer = (config) => {
  const [secondsLeft, setSecondsLeft] = React.useState(0);
  const [countdownStarted, setCountdownStarted] = React.useState(false);
  const [intervalTimerId, setIntervalTimerId] = React.useState("");
  const [countdownDisplay, setCountdownDisplay] = React.useState("");

  const setupCountdownTimer = () => {
    clearInterval(intervalTimerId);
    setIntervalTimerId(
      setInterval(() => {
        setSecondsLeft(s => s > 0 ? s-1: s); 
        setCountdownStarted(true)
    }, 1000));
  }

  React.useEffect(() => {
    if (config.save_to_local && config.key) {
      if (localStorage.getItem("timer")) {
        const savedTimers = JSON.parse(localStorage.getItem("timer"));
        const endtime = new Date(savedTimers[`${config.module}/${config.key}`]);
        const diff_seconds = parseInt((endtime - new Date()) / 1000);
        setSecondsLeft(diff_seconds);
        setupCountdownTimer();
      }
    }
  }, [config.save_to_local, config.key])

  React.useEffect(() => { 
    if (secondsLeft > 0) {
      const minutes = Math.floor(secondsLeft / 60);
      const seconds = secondsLeft % 60;
      setCountdownDisplay(<p className="mb-0 text-secondary">{minutes}:{seconds > 9 ? seconds: `0${seconds}`}</p>);
    } else {
      setCountdownDisplay("");
      clearInterval(intervalTimerId);
      if (countdownStarted && config.endTimerAction) {
        config.endTimerAction();
      }
    }
  }, [secondsLeft])

  const setTimer = (seconds) => {
    setSecondsLeft(seconds);
    setupCountdownTimer();
    if (config.save_to_local) {
      let time = new Date();
      time.setSeconds(time.getSeconds() + seconds);
      localStorage.setItem("timer", JSON.stringify({
        ...JSON.parse(localStorage.getItem("timer")),
        [`${config.module}/${config.key}`]: time
      }));
    }
  }

  return [Math.max(secondsLeft, 0), setTimer, countdownDisplay]
}