import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Button
} from "@material-ui/core";
import { Search, Remove } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { ReactComponent as TrashIcon } from "../../../assets/images/enterprise/trash.svg";
import { ReactComponent as SortAsc } from "../../../assets/images/enterprise/sortAsc.svg";
import { ReactComponent as SortDesc } from "../../../assets/images/enterprise/sortDesc.svg";
export default function FilterSection(props) {
  const useStyles = makeStyles(theme => ({
    form: {
      backgroundColor: "#fff"
    }
  }));

  const classes = useStyles();
  const selected_images = useSelector(
    state => state.mediaLibrary.selected_images
  );
  
  // search with debounce
  const [timeoutId, setTimeoutId] = React.useState();
  const onSearch = (e) => {
    const val = e.target.value;
    clearTimeout(timeoutId);
    setTimeoutId(setTimeout(() => {
      props.onChange(state => ({
        ...state,
        search: val,
        page: 1
      }))
    }, [500]))
  }

  // set order
  const onOrder = (order) => {
    props.onChange(state => ({
      ...state,
      order
    }))
  }

  //sort by 
  const onSortBy = (e) => {
    const val = e.target.value;
    props.onChange(state => ({
      ...state,
      sort_by: val === "Sort by" ? "": val
    }))
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div>
            <OutlinedInput
              className="mr-2 mt-1 search"
              startAdornment={
                <InputAdornment position="start">
                  <Search style={{ fontSize: 25, color: "gray" }} />
                </InputAdornment>
              }
              labelWidth={0}
              margin="dense"
              color="secondary"
              placeholder="Search images"
              onChange={onSearch}
            />
          </div>
          <div>
            <FormControl
              variant="outlined"
              margin="dense"
              className="mr-2 bg-white sort"
            >
              <Select
                color="secondary"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                onChange={onSortBy}
                value={props.value.sort_by || "Sort by"}
              >
                <MenuItem value="Sort by">Sort by</MenuItem>
                <MenuItem value="created_on">Date Added</MenuItem>
                <MenuItem value="size">File Size</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Tooltip title="Descending" placement="top">
              <IconButton className="mr-2 descending" style={{ height: 50, width: 50 }}
                onClick={() => onOrder("")}>
                <SortDesc fill={props.value.order === "" ? "#f97663": "#858997"} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ascending" placement="top">
              <IconButton className="ascending" style={{ height: 50, width: 50 }}
                onClick={() => onOrder("-")}>
                <SortAsc fill={props.value.order === "-" ? "#f97663": "#858997"} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<TrashIcon style={{ fontSize: 16 }} fill="#f97663" />}
          style={{ height: 40 }}
          onClick={props.onDelete}
          className="delete"
        >
          Delete {selected_images.length ? `(${selected_images.length})` : ""}
        </Button>
      </div>
    </div>
  );
}
