import React from 'react';
import {
  Checkbox,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {usePriceRangeDisplay} from '../../../hooks/priceDisplay';
import ProductImagePlaceholder from '../../../../assets/images/product_placeholder_icon.svg';

const ProductItem = (props) => {
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles(theme => ({
    img: {
      height: 55,
      width: 55,
      borderRadius: 4,
      backgroundColor: "rgba(43, 45, 50, 0.8)",
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    name: {
      fontWeight: 600,
      fontSize: 14,
      color: "#2B2D32",
      marginBottom: 0
    },
    stocks: {
      fontSize: 14,
      color: "rgba(43, 45, 50, 0.8)"
    }
  }));
  const classes = useStyles();
  const [priceDisplay] = usePriceRangeDisplay(props.product);

  return (
    <div className="d-flex border-bottom py-3 align-items-start">
      <div className={`${classes.img} image`} style={{
        backgroundImage: `url(${props.product.gallery[0]?.image || ProductImagePlaceholder})`
      }} />
      <div className="ml-4">
        <p className={`${classes.name} name`}>{props.product.name}</p>
        <div className={`${is_mobile? "d-block": "d-flex"} justify-content-between align-items-center price`}>
          P {priceDisplay}
          <p className={`${classes.stocks} ml-0 ml-md-3 mb-0 quantity`}>Stocks: {` ${props.product.quantity}`}</p>
        </div>
      </div>
      <Checkbox className="ml-auto product-checkbox" color="primary" checked={props.checked} onChange={(e) => props.onChange(e.target.checked)}/>
    </div>
  )
} 

export default ProductItem;
