import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Rating from '@material-ui/lab/Rating';


const useStyles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    padding: "1.5em 0 0",
  },
  ratingDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    marginTop: "1em",
    "& > p": {
      marginLeft: "2em",
      marginBottom: 0
    }
  },
  date: {
    marginLeft: "3em"
  }
});

class ReviewCard extends React.Component {
  
  render (){
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <strong>{this.props.name}</strong>
        <div className={classes.ratingDiv}>
          <Rating name="size-medium" defaultValue={this.props.rating} precision={0.5} readOnly />
          <p>{this.props.date}</p>
        </div>
        <div className={classes.titleWrapper}>
          <strong>{this.props.title}</strong>
        </div>
        <p>{this.props.review}</p>
      </div>
    )
  }
}

export default withStyles(useStyles)(ReviewCard);
