import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}
export default function PopupAlert(props) {
  return (
    <div>
      <Snackbar
        open={props.open}
        onClose={props.onClose}
        TransitionComponent={TransitionDown}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
      >
        <MuiAlert severity={props.alertType}>{props.message}</MuiAlert>
      </Snackbar>
    </div>
  );
}
