import React from "react";
import ImageUploader from "react-images-upload";
import upload_icon from "../../../../../../assets/images/upload_icon.svg";
class MobileProductImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pictures: [] };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(pictureFiles) {
    this.setState(
      {
        pictures: pictureFiles
      },
      () => {
        this.props.onChange(this.state.pictures);
      }
    );
  }
  render() {
    const labelStyle = {
      color: "#f97663",
      fontSize: 12,
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignContent: "center",
      margin: 10
    };
    const containerStyle = {
      width: 95,
      height: 95,
      border: "2px dashed #f97663",
      borderRadius: 5,
      boxShadow: "none",
      margin: 0
    };
    const button = {
      position: "absolute",
      top: 0,
      padding: 0,
      color: "#fff",
      borderRadius: 1,
      backgroundImage: `url(${upload_icon})`,
      backgroundSize: "cover",
      backgroundColor: "transparent",
      width: 40,
      height: 40
    };
    return (
      <div style={containerStyle}>
        <ImageUploader
          buttonText={false}
          buttonStyles={button}
          withLabel={false}
          fileContainerStyle={{
            padding: 0,
            boxShadow: "none",
            background: "none"
          }}
          labelStyles={labelStyle}
          onChange={e => this.props.onChange(e[0])}
          imgExtension={[
            ".jpg",
            ".gif",
            ".png",
            ".jpeg",
            ".svg",
            ".heic",
            ".heif",
            ".zip",
            ".html",
            ".pdf"
          ]}
          maxFileSize={104857600}
          withPreview={false}
          withIcon={false}
          singleImage={true}
          errorStyle={{
            position: "relative",
            bottom: "-7em",
            width: "35em",
            left: "8em"
          }}
        />
      </div>
    );
  }
}
export default MobileProductImageUpload;
