import React from "react";
import { makeStyles } from "@material-ui/styles";
import ImageUpload from './utilities/imageUpload';

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    "& p": {
      textAlign: "right"
    }
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)",
    margin: 0
  },
}));

const ReduxNumberField = ({
  input,
  label,
  maxLength,
  minLength,
  multiline,
  cut,
  noHelpText,
  meta,
  ...custom
}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.label3}>{label}</div>
      <ImageUpload name={input.name} width={custom.width || 75} height={custom.height || 75} value={input.value} 
        onChange={(val) => input.onChange(val)} {...custom} {...input}/>
    </div>
  );
};

export default ReduxNumberField;
