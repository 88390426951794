import React, { Fragment, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import TableRow from "@material-ui/core/TableRow";
import ACL from "../../common/utilities/ACL";
import { makeStyles } from "@material-ui/core/styles";

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    // numSelected, rowCount,
    onSortRequest,
    hideActions
  } = props;
  const createSortHandler = property => event => {
    onSortRequest(event, property);
  };

  const tableHeadCell = headCell => (
    <TableCell
      className={classes.tableHead}
      key={headCell.id}
      align={headCell.numeric ? "right" : "left"}
      sortDirection={orderBy === headCell.id ? order : false}
    >
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : "asc"}
        onClick={createSortHandler(headCell.id)}
        hideSortIcon={true}
        active={false}
      >
        <span>{headCell.label}</span>
        {["actions", "options"].includes(headCell.id) ? (
          ""
        ) : (
          <span className={classes.headIconWrapper}>
            <ArrowDropUpIcon className={classes.headIconUp} />
            <ArrowDropUpIcon className={classes.headIconDown} />
          </span>
        )}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map(headCell =>
          headCell.id === "actions" ? (
            hideActions ? (
              <th></th>
            ) : (
              <ACL
                roles={
                  props.actionColRoles ? props.actionColRoles : ["superuser"]
                }
                key={headCell.id}
              >
                {tableHeadCell(headCell)}
              </ACL>
            )
          ) : (
            tableHeadCell(headCell)
          )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onSortRequest: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
  // rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  tableContainer: {
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: 7
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 3px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 3px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.2)",
      outline: "1px solid slategrey",
      borderRadius: 10
    }
  },
  hideScroll: {
    "&::-webkit-scrollbar": {
      height: 0
    }
  },
  table: {},
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  noResult: {
    width: "100%",
    display: "block",
    textAlign: "center",
    padding: "2em"
  },
  deleteBtn: {
    color: "red",
    cursor: "pointer"
  },
  tableHead: {
    backgroundColor: "#F97663",
    color: "white !important",
    "&:hover": {
      color: "white !important"
    },
    "&:active": {
      color: "white !important"
    }
  },
  headIconWrapper: {
    direction: "rtl",
    flexGrow: 1,
    minWidth: 45,
    display: "flex",
    height: 20
  },
  headIconUp: {
    transform: "rotate(0deg)",
    position: "absolute",
    transform: "translateY(-8px)"
  },
  headIconDown: {
    transform: "rotate(180deg)",
    position: "absolute"
  },
  moreHorizIcon: {
    color: "gray",
    cursor: "pointer"
  }
}));

export default function AdminTable(props) {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(props.page);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  useEffect(() => {
    const rowsCount = props.rowsPerPage ? props.rowsPerPage : 5;
    setRowsPerPage(rowsCount);
  }, [props.rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    props.onSortRequest(newOrder, property, page, rowsPerPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.onSortRequest(order, orderBy, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = event => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    props.onSortRequest(order, orderBy, 0, rowsPerPage);
  };

  return (
    <>
      <div
        className={`${classes.tableContainer} ${(props.loading ||
          !props.length) &&
          classes.hideScroll}`}
      >
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
          id="table"
        >
          <EnhancedTableHead
            headCells={props.headCells}
            classes={props.classes || classes}
            actionColRoles={props.actionColRoles}
            // numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSortRequest={handleRequestSort}
            // rowCount={data.length}
          />
          {props.body}
        </Table>
      </div>
      {!props.loading && !props.length && (
        <div style={{ height: "30vh", width: "100%", overflow: "hidden" }}>
          <div style={{ paddingTop: "4em" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <img
                alt=""
                style={{ width: "4em" }}
                src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                fontSize: 12,
                fontWeight: 400,
                color: "#b8bcc3",
                padding: "0 2em",
                textAlign: "center"
              }}
            >
              No result
            </div>
          </div>
        </div>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props.length}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
