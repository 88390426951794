import React from "react";
import logo from "../../assets/images/sentro_vertical.png";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import InputPasswordForm from "./forgotPasswordComponents/inputPasswordForm";
import SentroAlert from "../common/alert";
import Text from "../../common/text";
import { verifyCode } from "./forgotPasswordAction";
import { useDispatch, useSelector } from "react-redux";
import SentroLoading from "../common/loading";
const ForgotPassword = props => {
  const dispatch = useDispatch();
  let param = props.location.search;
  let param2 = props.location.search.split(":");
  let email = param2[0].replace("?token=", "");
  let token = param.replace("?token=", "");

  localStorage.setItem("CHANGE_PASSWORD_EMAIL", email);
  React.useEffect(() => {
    // dispatch(verifyCode({ token: token }));
  }, []);

  const forgotPasswordStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60vw",
      height: "100vh",
      background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    ForgotPassword: {
      height: "100vh",
      overflow: "auto",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    box1: {
      marginTop: "35vh",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },

    box2: {
      padding: "6vh 8vh 0 ",
      [theme.breakpoints.down("xs")]: {
        padding: "6vh 5vw"
      }
    }
  }));

  const classes = forgotPasswordStyles();
  const loading = useSelector(state => state.forgotPassword.loading);
  return (
    <div className={classes.root}>
      <SentroLoading open={loading} />
      <Box className={classes.banner}>
        <Container>
          <Box className={classes.box1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={logo} style={{ width: 150 }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "2vh" }}
                textType="h2"
                content="Create your online shop here."
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#fff",
                  position: "absolute",
                  bottom: 25
                }}
              >
                This works best in the latest Google desktop browser.
              </Typography>
            </div>
          </Box>
        </Container>
      </Box>
      <Box className={classes.ForgotPassword}>
        <Container>
          <Box className={classes.box2}>
            <Text textType="h3" content="Reset Password" />

            <Box>
              <Box style={{ marginTop: "2vh" }}>
                <Text
                  style={{ color: "#2B2D33" }}
                  textType="p1"
                  content="Please proceed to nominate your new password. Ideal password should at least have 8 characters with a mix of numbers, letters and special characters."
                />
              </Box>

              <Box style={{ marginTop: "4vh" }}>
                <SentroAlert />
              </Box>

              <Box style={{ marginTop: "3vh" }}>
                <InputPasswordForm token={token}/>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};
export default ForgotPassword;
