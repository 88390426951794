import { getAllProducts, findProduct } from "./productServices";
import { showAlert } from "../common/commonActions";
import history from "../../store/history";
import Path from "../../Paths";

export const ProductActionTypes = {
  GET_REQUEST: "@@GET_REQUEST",
  GET_PRODUCT_SUCCESS: "@@GET_PRODUCT_SUCCESS",
  PRODUCT_FAILED: "@@PRODUCT_FAILED",
  PRODUCT_SELECT: "@@PRODUCT_SELECT",
  CLEAR: "@@CLEAR"
};
export function getAll() {
  return async dispatch => {
    try {
      dispatch({ type: ProductActionTypes.GET_REQUEST });
      let response = await getAllProducts();

      dispatch({
        type: ProductActionTypes.GET_PRODUCT_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ProductActionTypes.PRODUCT_FAILED
      });
      dispatch(showAlert({ type: "error", message: error.message }));
    }
  };
}

export function getProduct(value) {
  return async dispatch => {
    try {
      dispatch({ type: ProductActionTypes.GET_REQUEST });
      let response = await findProduct(value);
      dispatch({
        type: ProductActionTypes.GET_PRODUCT_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: ProductActionTypes.PRODUCT_FAILED
      });
      dispatch(showAlert({ type: "error", message: error.message }));
    }
  };
}

export function getSelectProduct(value) {
  return async dispatch => {
    try {
      dispatch({
        type: ProductActionTypes.PRODUCT_SELECT,
        payload: value
      });
      history.push(Path.PRODUCT_DETAILS);
    } catch (error) {
      dispatch({
        type: ProductActionTypes.PRODUCT_FAILED
      });
      dispatch(showAlert({ type: "error", message: error.message }));
    }
  };
}
