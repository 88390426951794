import React from 'react';
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Button } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Alert from '@material-ui/lab/Alert';
import history from '../../../../store/history'
import StoreActions from './StoreActions'
import moment from 'moment';
import ENV from '../../../../common/apiConfig'

const moreDetailsLink = "https://drive.google.com/file/d/1sQI20cbKFNB2CAhHmOCs_YdDIutRlzIB/view";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow in ref={ref} {...props} />;
});

const isPromoActive = (code) => {
  try {
    const envPromo = JSON.parse(ENV.activePromo)
    const { promo_code, start, end } = envPromo;
    const now = moment();
    if (promo_code === code && moment(start) <= now && now <= moment(end)) {
      return true
    }
    return false
  } catch {
    return false
  }
}

const PromoBannerModal = (props) => {
  const classes = makeStyles(theme => ({
    root: {
      backgroundImage: `url(https://sentro-announcements.s3.amazonaws.com/12-12/banner.png)`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      [theme.breakpoints.down("xs")]: {
        backgroundPosition: "right",
        backgroundSize: "205%",
      },
    },
    title: {
      fontWeight: 600,
      fontSize: 42,
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      color: 'white'
    },
    content: {
      fontSize: 22,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "18px",
      },
      color: 'white',
      lineHeight: "24px",
      "& a": {
        color: 'white',
        textDecoration: "none"
      }
    },
    button: {
      background: "white",
      color: '#F97663',
      fontSize: 20,
      height: 55,
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        height: 45,
      },
    },
    leftDiv: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: "none"
      },
    },
    aspectRatioDiv: {
      paddingTop: "44.75%",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "100%",
      },
    }
  }))();
  const dispatch = useDispatch();
  const promo = useSelector(state => state.store.promo);
  React.useEffect(() => {
    if (isPromoActive("12TWELVE")) {
      setTimeout(() => {
        dispatch(StoreActions.setPromoModal({open: true}))
      }, 1000)
    }
  }, []);

  const onClick =() => {
    dispatch(StoreActions.setPromoModal({open: false}))
  }

  return (
    <Dialog
      open={promo.bannerOpen}
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      scroll="body"
      onClose={onClick}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      {/* <DialogContent> */}
          {/* <DialogContentText> */}
        <div className="position-relative">
          <div className={classes.root}>
            <div>
              <div className={`w-100 h-100 position-relative ${classes.aspectRatioDiv}`}>
                <div className="position-absolute h-100 w-100" style={{
                  top: 0,
                  left: 0,
                }}>
                  <div className="row no-gutters h-100">
                    <div className={`${classes.leftDiv} col-12 col-sm-6`}>

                    </div>
                    <div className="col-12 col-sm-6">
                      <div className={`p-3 p-sm-4 h-50 d-flex align-items-center mb-0`} style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 30
                      }}>
                        <div className="text-center pb-2">
                          <p className={`${classes.content} pt-5 mb-1 mb-sm-3`}><strong>Get Php 50.00 off</strong> for at least Php 500.00 
                            worth of purchase. Discount will be applied before payment.  
                            Payment should be done via Bux. </p>
                          <Button 
                            size="large"
                            variant="contained"
                            fullWidth
                            disableElevation
                            className={`${classes.button}`}
                            onClick={onClick}
                          >
                            Adutucart now!
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          {/* </DialogContentText> */}
      {/* </DialogContent> */}
    </Dialog>
  )
}


export default PromoBannerModal;


const PromoBannerSticky = (props) => {
  const classes = makeStyles(theme => ({
      promoBanner: {
        backgroundImage: `url(https://sentro-announcements.s3.amazonaws.com/12-12/sticky.png)`,
        backgroundPosition: "left",
        backgroundSize: "cover",
        [theme.breakpoints.down("sm")]: {
          backgroundPosition: "right",
        },
        transition: "all 0.5s linear",
        color: "white",
        background: '#F97663',
        maxHeight: 300,
        overflow: "hidden",
        boxShadow: "inset 0 5px 5px 1px rgba(0,0,0,0.12)",
        "& .title": {
          fontSize: 24,
          color: "white",
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          weight: "bold"
        },
        "& .content": {
          fontSize: 14,
          [theme.breakpoints.down("md")]: {
            fontSize: 13
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 13
          },
          [theme.breakpoints.down("xs")]: {
            fontSize: 13
          },
        },
        "& a": {
          color: 'white',
          textDecoration: "none"
        }
      },
      noHeight: {
        maxHeight: "0px !important"
      },
      aspectRatioDiv: {
        paddingTop: "6.26%",
        [theme.breakpoints.down("sm")]: {
          paddingTop: 100,
        },
      },
      textDiv: {
        width: "50%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }
  }))();

  const [visible, setVisible ] = React.useState(false)
  const promo = useSelector(state => state.store.promo);
  const setModal = () => {
    const split_path = history.location.pathname.split('/');
    setTimeout(() => {
      setVisible(
        split_path[1] === 'shop' && 
        (split_path.length === 3 || (split_path.length === 4 && split_path[3]===""))
      );
    }, 1000)
  }
  React.useEffect(() => {
    setModal();
  }, []);
  React.useEffect(() => {
    setModal();
  }, [promo.bannerOpen]);

  return (
    isPromoActive("12TWELVE") ?
    <div className={`${visible && !promo.bannerOpen ? ``: classes.noHeight} w-100 ${classes.promoBanner}`}>
      
      <div className={`w-100 h-100 position-relative ${classes.aspectRatioDiv}`}>
            <div className="position-absolute h-100 w-100 d-flex" style={{
              top: 0,
              left: 0,
            }}>
              <div className={`text-left d-flex align-items-center ml-auto p-4 p-md-1 ${classes.textDiv}`}>
                <p className="content mb-0"><strong>Get Php 50.00 off</strong> for at least Php 500.00 worth of purchase. 
                  Discount will be applied before payment. Payment should be done via Bux. 
                  <strong>
                    <a href={moreDetailsLink} target="_blank"
                    rel="noopener noreferrer">{` `}Click here for more details</a>
                  </strong>.</p>
              </div>
          </div>
      </div>
    </div>
    :
    <></>
  )
}

const PromoBanner = (props) => {
  const classes = makeStyles(theme => ({
    root: {
      backgroundImage: `url(https://sentro-announcements.s3.amazonaws.com/12-12/header.png)`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.1)"
    },
    aspectRatioDiv: {
      paddingTop: "14.31%",
      [theme.breakpoints.down("xs")]: {
        paddingTop: 110,
      },
      color: "white",
      fontSize: 14,
      lineHeight: "18px",
      "& a": {
        color: 'white',
        textDecoration: "none"
      },
      "& .content": {
        fontSize: 15,
        lineHeight: "17px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 13,
          lineHeight: "15px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 13,
          lineHeight: "15px",
        },
      },
    },
  }))();

  return (
    isPromoActive("12TWELVE") ?
      <div className={`w-100 position-relative ${classes.root}`}>
        <div className={`w-100 position-relative ${classes.aspectRatioDiv}`}>
          <div className="position-absolute h-100 w-100 d-flex" style={{
            top: 0,
            left: 0,
          }}>
            <div className={`row no-gutters w-100`}>
              <div className={`col-0 col-md-3`}></div>
              <div className="col-12 col-md-6 text-center d-flex position-relative pt-4">
                <p className="content mb-2 mb-md-4 mt-auto pt-4"><strong>Get Php 50.00 off</strong> for at least 
                  Php 500.00 worth of purchase. Discount will be applied before payment. 
                  Payment should be done via Bux. 
                  <br /><strong>  
                    <a href={moreDetailsLink} target="_blank"
                    rel="noopener noreferrer">{` `}Click here for more details</a>
                  </strong>.
                </p>
              </div>
              <div className={`col-0 col-md-3`}></div>
            </div>
          </div>
        </div>

    </div>
    :
    <></>
  )
}

const PromoBannerClaim = (props) => {
  const classes = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
      border: "2px solid #F97663",
      borderRadius: 4,
    },
    center: {
      color: "white",
      flexGrow: 1,
      background: '#F97663',
      "& .title": {
        fontSize: 24,
        color: "white",
        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        weight: "bold"
      },
      "& .content": {
        fontSize: 16
      },
      "& a": {
        color: 'white',
        textDecoration: "none"
      }
    },
    right: {
      background: "white",
      width: 600,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
      "& .title": {
        color: '#54575F',
        fontSize: 22,
        fontWeight: "bold"
      }
    }
  }))();
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);
  const promo = useSelector(state => state.store.promo);
  React.useEffect(() => {
    setChecked(false)
    dispatch(StoreActions.resetPromo());
  }, []);
  React.useEffect(() => {
    if (!promo.checked) {
      setChecked(false)
    }
  }, [promo.checked]);
  const claim = () => {
    dispatch(StoreActions.checkEligibility({
      promo_code: promo.promo_code,
      email: props.email,
      amount: props.amount
    }))
    setChecked(true)
  }

  return (
    isPromoActive("12TWELVE") ?
    <>
      <div className={`w-100 ${classes.root}`}>
        <div className={`${classes.center} text-left p-3 p-md-4`}>
          <h3 className="title mb-2">12.12 Christmas Promo!</h3>
          <p className="content mb-0">Get Php 50.00 off for at least 
          Php 500.00 worth of purchase. Discount will be applied before payment. 
          Payment should be done via Bux. 
          <strong>
            <a href={moreDetailsLink} target="_blank"
            rel="noopener noreferrer">{` `}Click here for more details</a>
          </strong>.</p>
        </div>
        <div className={classes.right}>
          <div className="d-flex align-items-center h-100">
            <div className="p-4 text-center w-100">
              <h4 className="mb-3 title">Claim your Php 50 voucher</h4>
              <Button
                style={{ minWidth: 220 }}
                disableElevation
                variant="contained"
                color="primary"
                onClick={claim}
                disabled={props.disabled || (promo.eligible && checked)}
              >
                {
                  checked && promo.eligible ?
                  `Claimed`: `Click here`
                } 
              </Button>
            </div>
          </div>
        </div>
      </div>
      {
        checked && !promo.loading &&
        <div className="mt-4 mb-2 mb-sm-0">
          {
            props.amount < promo.amount_gt ?
              <Alert variant="filled" severity="error">
                Please have at least Php 500.00 worth of items upon checkout to qualify for the voucher.
              </Alert>
              :
              promo.eligible ?
                <Alert variant="filled" severity="success">
                  Voucher successfully applied! Please select your preferred Bux payment channel in the next step
                </Alert>
                :
                <Alert variant="filled" severity="error">
                  Sorry, the voucher can only be claimed once. Thank you for understanding.
                </Alert>
          }
        </div>
      }
    </>
    :
    <></>
  )
}

const getPromoCode = () => {
  let promo_code = "";
  try {
    const envPromo = JSON.parse(ENV.activePromo)
    promo_code = envPromo.promo_code
  } catch { }
  return promo_code
}

export { PromoBannerSticky, PromoBanner, PromoBannerClaim, getPromoCode }