import axios from "axios";
import apiConfig from "../../../common/apiConfig";
import history from "../../../store/history";
import Path from "../../../Paths";

class Services {



  static async getStores() {
    localStorage.removeItem("STORE_UUID");
    try {
      let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
      let id = currentUser.id;
      let response = await axios.get(
        `${apiConfig.apiURL}/api/dashboard/store/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params: {
            email: id
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

	static async getProducts(payload) {
		try {
			let response = await axios.get(`${apiConfig.apiURL}/api/export/catalog_search/`, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
				},
				params: payload.params
			});
			return response;
			// return []
		} catch (error) {
			throw error.message;
		}
	}

	static async getProduct(payload) {
		try {
			// let response = await axios.get(`${apiConfig.apiURL}/api/marketplace/apps`, {
			// 	headers: {
			// 		Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
			// 	}
			// });
			// return response.data;
			return {}
		} catch (error) {
			throw error.message;
		}
    }

	static async createOrder(payload) {
		try {
			let response = await axios.post(`${apiConfig.apiURL}/api/export/purchase_orders/`, payload, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
				}
			});
			return response;
		} catch (error) {
			throw error.message;
		}
	}
	static async postOrder(payload) {
		try {
			let response = await axios.post(`${apiConfig.apiURL}/api/export/post_order/${payload.order_id}/`, {}, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
				}
			});
			return response;
		} catch (error) {
			throw error.message;
		}
	}

	static async getOrders(payload) {
		try {
			let response = await axios.get(`${apiConfig.apiURL}/api/export/purchase_orders/`, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
				},
				params: payload.params
			});
			return response;
			// return []
		} catch (error) {
			throw error.message;
		}
	}

	static async getOrder(payload) {
		try {
			let response = await axios.get(`${apiConfig.apiURL}/api/export/purchase_orders/${payload.order_id}/`, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
				}
			});
			return response;
		} catch (error) {
			throw error.message;
		}
	}

	static async editOrder(payload) {
		try {
			let response = await axios.patch(`${apiConfig.apiURL}/api/export/purchase_orders/${payload.order_id}/`, payload, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
				}
			});
			return response;
		} catch (error) {
			throw error.message;
		}
	}

	static async confirmOrder(payload) {
		try {
			let response = await axios.put(`${apiConfig.apiURL}/api/export/confirm_order/${payload.order_id}/`, payload, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
				}
			});
			return response;
		} catch (error) {
			throw error.message;
		}
    }

	static async getSupplier(payload) {
		try {
			let response = await axios.get(`${apiConfig.apiURL}/api/export/supplier/${payload.supplier_id}/`, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
				}
			});
			return response;
		} catch (error) {
			throw error.message;
		}
    }


	static async registerSME(payload) {
		try {
			let response = await axios.post(`${apiConfig.apiURL}/api/export/register_sme/${payload.store_uuid}/`,
            {
                sme_details: {
                    is_supplier: true,
                    is_buyer: true
                }
            }, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
				}
			});
			return response;
		} catch (error) {
			throw error.message;
		}
    }








	static async fetchApp(id) {
		try {
			let response = await axios.get(`${apiConfig.apiURL}/api/marketplace/apps/${id}/`, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
				}
			});
			return response.data;
		} catch (error) {
			throw error.message;
		}
	}

	static async createApp(data) {
		try {
			/// create app
			let request_data = new FormData(); 
			for ( var key in data ) {
				switch(key){
					case "variants":
						request_data.append("variants", JSON.stringify(data["variants"]))
						break;
					case "images":
						for(let i in data['images']){
							request_data.append(`gallery-${parseInt(i)+1}`, data['images'][i], data['images'][i].name);
						}
						break;
					default:
						request_data.append(key, data[key]);
						break;
				}
			}
			let response = await axios.post(`${apiConfig.apiURL}/api/marketplace/apps/`, request_data, {
				headers: {
					Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
				}
			});
			history.push(Path.ADMIN_APP_MARKETPLACE);
			return response.data;
		} catch (error) {
			throw error.message;
		}
	}
}
export default Services;
