import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import StoreActions from "./StoreActions";
import AppBar from "./AppBar";
import Loading from "./Loading";
import imgPlaceholder from "../../../../assets/images/product_placeholder.png";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Utils from "../../utils";
import Footer from "./Footer";
import { WishlistItemPriceDisplay } from "./PriceDisplay";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import {
  Breadcrumbs,
  Link,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import history from "../../../../store/history";
import { Alert } from "antd";
import VoucherModal from "./voucher/voucherModal";
import { useDefaultAddress } from "../../../dashboard/buyerDashboard/hooks/useAddresses";
import VariantPicker from "../../../enterprise/components/cart/variantPicker";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Summary from "./cart/summary";
import { FreebieCartPage } from "../../../quickOrder/components/products/freebie";
import Actions from "../../../quickOrder/redux/actions";
import AddToCartLimit from "./addToCartLimit";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f8f8f8",
    width: "100%",
    padding: "6em 10vw",
    [theme.breakpoints.down("sm")]: {
      padding: "2em 0 10vh",
      height: "71vh",
      overflowY: "scroll",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2em 0 15vh",
      height: "64vh",
      overflowY: "scroll",
    },
  },
  breadcrumbs: {
    fontSize: 14,
    color: "rgba(43,45,50, 0.8)",
    "&:hover": {
      fontWeight: 600,
      textDecoration: "none",
      color: "#2b2d32",
    },
  },
  header: {
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: 28,
    color: "#2b2d32",
    [theme.breakpoints.down("sm")]: {
      padding: 24,
    },
  },
  cart: {
    padding: 24,
    width: "65%",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 10,
      marginBottom: 30,
    },
  },
  columnItem: {
    border: "none",
    width: "50%",
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 600,
    color: "rgba(43,45,50, 0.8)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  column: {
    border: "none",
    width: "25%",
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 600,
    color: "rgba(43,45,50, 0.8)",
  },
  noItems: {
    width: "46vw",
    textAlign: "center",
    padding: 20,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  productImage: {
    width: 50,
    height: 50,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  itemSection: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  labelbold: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#2b2d32",
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: "rgba(43,45,50, 0.8)",
  },
  label2: {
    fontSize: 14,
    fontWeight: 500,
    color: "rgba(43,45,50, 0.8)",
  },
  btnContinue: {
    borderColor: "1px solid #2b2d32",
    height: 40,
  },
  btnCheckout: {
    marginLeft: "1vw",
    height: 40,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 15,
    },
  },
  actionSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  summary: {
    height: "fit-content",
    marginLeft: "2vw",
    padding: 24,
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 10,
      marginLeft: 0,
    },
  },
  columnSummary: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: "2vh",
    color: "rgba(43,45,50, 0.8)",
    border: "none",
  },
  quantityField: { width: 70 },
  btnQuantity: {
    borderColor: "#2b2d32",
    borderRadius: 0,
    width: 25,
    height: 25,
    minWidth: 25,
  },
  btnIncrement: {
    borderRadius: 0,
    width: 35,
    height: 35,
    minWidth: 35,
  },
  priceSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  btnVariantCancel: {
    color: "#2b2d32",
    borderColor: "#2b2d32",
  },
  btnVariantConfirm: {
    backgroundColor: "#2b2d32",
    color: "#fff",
  },
}));

const Cart = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/cart", "");
  const storeName = param.replace("/shop/", "");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const store_theme = useSelector((state) => state.store.store.color);
  React.useEffect(() => {
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(storeName));
    }
    fetchData();
  }, []);

  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold",
      },
      fontFamily: ["Inter", "sans-serif"],
    },
  });

  let storeInfo = useSelector((state) => state.store.store);
  let loading = useSelector((state) => state.store.loading);
  let cartItems = useSelector((state) => state.store.items);
  let products = useSelector((state) => state.store.products);
  let selectedProduct = useSelector((state) => state.store.selectedProduct);
  let customerDetails = useSelector((state) => state.store.customerDetails);
  let vouchers = useSelector((state) => state.store.voucherList);
  const subtotal = useSelector((state) => state.store.subtotal);
  const total = useSelector((state) => state.store.total);
  const voucher_discount = useSelector((state) => state.store.voucher_discount);
  let delivery_details = useSelector((state) => state.store.deliveryDetails);
  const shipping_fee = useSelector(
    (state) => state.store.deliveryDetails.shippingFee
  );
  let errorOpen = useSelector((state) => state.store.dialogOpen);
  const freebie = useSelector((state) => state.quickOrder.freebie);
  let kyc = storeInfo ? storeInfo.kyc_level : 0;
  let [quantity, setQuantity] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [editQuantity, setEditQuantity] = React.useState(false);
  const [openVoucher, setOpenVoucher] = React.useState(false);
  let [selected, setSelected] = React.useState(0);
  const [error, setError] = React.useState({
    quantityError: "",
  });
  const [openBuxLimit, setOpenBuxLimit] = React.useState(false);
  let productQuantity = 0;

  const {
    firstName,
    lastName,
    streetAddress,
    email,
    barangayAddress,
    cityAddress,
    provinceAddress,
    postalCode,
    mobileNumber,
  } = customerDetails;

  let totalAmt = parseFloat(
    cartItems.reduce((total, object) => {
      return total + object.price * object.quantity;
    }, 0)
  );

  for (let existingProduct of products) {
    if (existingProduct["uuid"] === selectedProduct["uuid"]) {
      productQuantity = existingProduct.quantity;
    }
  }

  function productImage(item) {
    if (item.gallery && item.gallery.length) {
      return item.gallery[0].image;
    } else if (item.image) {
      return item.image;
    } else {
      return imgPlaceholder;
    }
  }

  function saveEdit(value) {
    let itemQuantity = isNaN(quantity) ? 1 : quantity;
    let params = {
      quantity: itemQuantity,
      name: value.name,
      productDescription: value.description,
      price: value.price,
      images: value.images,
      uuid: value.uuid,
      store: value.store,
    };
    dispatch(StoreActions.updateQuantity(params));
    setEditQuantity(false);
  }

  function editValue(value, key) {
    dispatch(StoreActions.getSelectedProduct(value));
    setEditQuantity(true);
    setSelected(key);
    setQuantity(value.quantity);
  }

  function removeFromCart(index) {
    dispatch(StoreActions.removeFromCart(index));
  }

  function handleQuantity(input) {
    let sanitizedInput = parseInt(
      input.replace(/[^0-9]|(^0+)/g, "").substring(0, 3)
    );
    setQuantity(sanitizedInput);
  }

  function decrement(item) {
    let params = {
      ...item,
      quantity: item.quantity - 1,
    };
    dispatch(StoreActions.updateQuantity(params));
  }

  function increment(item) {
    let params = {
      ...item,
      quantity: item.quantity + 1,
    };
    dispatch(StoreActions.updateQuantity(params));
  }

  function freebieQuantity(freebie) {
    dispatch(Actions.updateFreebieQuantity(freebie));
  }

  function getProduct(item) {
    let params = {
      product_details: {
        ...item,
        quantity: item.stock,
      },
      quantity: item.quantity,
      uuid: item.uuid,
    };
    return params;
  }

  function cashInLimits() {
    return (
      <div id="error-message" style={{ marginTop: 25 }}>
        {(kyc == 0 && totalAmt > 5000) ||
        totalAmt > 30000 ||
        errorOpen == true ? (
          <div>
            <Alert
              type="error"
              message="Oops! Looks like there is a problem with taking online orders for now. Kindly try again in a few minutes, or coordinate with us through any of the contact details below."
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  const isEqual = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  function handleConfirmVariant(product) {
    let productSelectionIds = product.productSelection.variants.map(
      (i) => i.id
    );
    let prevSelectionIds = product.prevSelection
      ? product.prevSelection.variants.map((i) => i.id)
      : [];
    if (isEqual(productSelectionIds, prevSelectionIds)) {
      setAnchorEl(null);
    } else {
      dispatch(StoreActions.updateVariant(product));
    }
  }

  const checkStock = (product) => {
    if (product.has_variants) {
      if (product.productSelection) {
        if (product.quantity >= product.productSelection.quantity) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (product.always_available) {
        return false;
      } else {
        if (product.quantity >= product.stock) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const getStocks = (product) => {
    if (product.has_variants) {
      return product.productSelection ? product.productSelection.quantity : -2;
    } else {
      if (product.always_available) {
        return -1;
      } else {
        return product.stock;
      }
    }
  };

  const show_error = (product) => {
    const stocks = getStocks(product);
    if (stocks === -2 || stocks === -1) {
      return "";
    } else if (stocks === 0) {
      return (
        <div style={{ margin: "2vh 0 0 6em" }}>
          <small className="text-danger">Out of stock</small>
        </div>
      );
    } else if (product.quantity >= stocks) {
      return (
        <div>
          <small
            style={{ fontSize: 10 }}
            className="text-danger d-flex align-items-center"
          >
            {" "}
            <ErrorOutlineIcon
              className="mr-2"
              style={{ color: "red", fontSize: 16 }}
            />{" "}
            You have reached the maximum quantity for this{" "}
            {product.has_variants ? "variant" : "product"}.
          </small>
        </div>
      );
    }
  };

  const [errors, setErrors] = React.useState([]);
  React.useEffect(() => {
    errors.length = 0;
    for (let i in cartItems) {
      if (cartItems[i].has_variants) {
        if (cartItems[i].has_variants && cartItems[i].productSelection) {
          if (cartItems[i].productSelection.quantity <= 0) {
            errors.push(true);
          } else if (
            cartItems[i].quantity > cartItems[i].productSelection.quantity
          ) {
            errors.push(true);
          } else {
            errors.push(false);
          }
        } else if (
          cartItems[i].has_variants &&
          !cartItems[i].productSelection
        ) {
          errors.push(true);
        }
      } else {
        if (cartItems[i].always_available) {
          errors.push(false);
        } else if (cartItems[i].stock <= 0) {
          errors.push(true);
        } else if (cartItems[i].quantity > cartItems[i].stock) {
          errors.push(true);
        }
      }
    }

    if (errors.includes(true)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [cartItems]);

  React.useEffect(() => {
    if (
      (storeInfo.kyc_level === 0 &&
        parseInt(total - voucher_discount) > 100000) ||
      (storeInfo.kyc_level === 1 && parseInt(total - voucher_discount) > 500000)
    ) {
      setOpenBuxLimit(true);
    } else {
      setOpenBuxLimit(false);
    }
  }, [total]);

  const breadcrumbLinks = [
    {
      label: "Home",
      path: `/shop/${storeInfo.site}`,
    },
    {
      label: "Cart",
      path: `/shop/${storeInfo.site}/cart`,
    },
    {
      label: "Details",
      path: `/shop/${storeInfo.site}/delivery`,
      isDisabled:
        firstName &&
        lastName &&
        email &&
        streetAddress &&
        barangayAddress &&
        cityAddress &&
        provinceAddress &&
        mobileNumber
          ? false
          : true,
    },
    {
      label: "Delivery",
      path: `/shop/${storeInfo.site}/delivery_details`,
      isDisabled: true,
    },
    {
      label: "Payment",
      path: `/shop/${storeInfo.site}/payment`,
      isDisabled: true,
    },
  ];

  const [has_default_address] = useDefaultAddress();
  const proceed_to_checkout = () => {
    window.scrollTo(0, 0);
    if (!openBuxLimit) {
      if (has_default_address) {
        history.push(
          `/shop/${storeInfo ? storeInfo.site : ""}/delivery_details`
        );
      } else {
        history.push(`/shop/${storeInfo ? storeInfo.site : ""}/delivery`);
      }
    }
  };

  const cartMobile = () => {
    function decrement(row) {
      let params = {
        ...row,
        quantity: row.quantity - 1,
      };
      dispatch(StoreActions.updateQuantity(params));
    }

    function increment(row) {
      let params = {
        ...row,
        quantity: row.quantity + 1,
      };
      dispatch(StoreActions.updateQuantity(params));
    }

    return (
      <TableBody>
        {cartItems && cartItems.length > 0 ? (
          cartItems.map((row, key) => (
            <TableRow key={key} style={{ borderBottom: "1px dashed #d1d5dd" }}>
              <TableCell
                className={classes.columnItem}
                component="th"
                scope="row"
              >
                <div className={classes.itemSection}>
                  <div
                    className={classes.productImage}
                    style={{
                      backgroundImage: `url(${productImage(row)})`,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "3vw",
                      width: "80vw",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "65%" }}>
                        <div className={classes.labelbold}>
                          {row.name}
                          <VariantPicker
                            index={key}
                            product={row}
                            productSelection={row.productSelection}
                            handleConfirmVariant={handleConfirmVariant}
                          />
                        </div>
                      </div>

                      <DeleteIcon
                        onClick={() => removeFromCart(key)}
                        style={{
                          fontSize: 24,
                          color: "#2b2d32",
                        }}
                      />
                    </div>
                    <div className={classes.priceSection}>
                      <div>
                        <WishlistItemPriceDisplay
                          symbol="₱"
                          item={row}
                          productSelection={row.productSelection}
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: -15,
                          }}
                        >
                          {show_error(row)}
                        </div>
                      </div>
                      <div>x</div>
                      <div>{row.quantity}</div>
                      <div style={{ display: "flex" }}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.btnIncrement}
                          style={{ borderRight: "none" }}
                          disabled={row.quantity === 1}
                          onClick={() => decrement(row)}
                        >
                          <RemoveIcon style={{ fontSize: 12 }} />
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.btnIncrement}
                          disabled={checkStock(row)}
                          onClick={() => increment(row)}
                        >
                          <AddIcon style={{ fontSize: 12 }} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                {freebie.length
                  ? freebie
                      .filter((i) => row.uuid === i.minimum_x_product)
                      .map((freebie) => (
                        <FreebieCartPage
                          freebie={freebie}
                          product={row}
                          freebieQuantity={freebieQuantity}
                        />
                      ))
                  : ""}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <div className={classes.noItems}>No item(s) in your cart.</div>
        )}
      </TableBody>
    );
  };

  return (
    <div>
      {storeInfo && storeInfo.font.css_url ? (
        <link href={storeInfo.font.css_url} rel="stylesheet"></link>
      ) : (
        ""
      )}
      {openBuxLimit ? <AddToCartLimit kycLevel={storeInfo?.kyc_level} /> : ""}
      <ThemeProvider theme={store_generated_theme}>
        <div className={classes.root}>
          <AppBar isPreview={false} storeInfo={storeInfo} />
          <Loading open={loading} />
          <div>
            <div>
              <Breadcrumbs
                style={{ display: isMobile ? "none" : "" }}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbLinks.map((item) => {
                  return (
                    <Link
                      disabled={item.isDisabled}
                      className={classes.breadcrumbs}
                      style={{
                        fontWeight: item.label === "Cart" ? "bold" : "normal",
                      }}
                      color="inherit"
                      onClick={() =>
                        item.isDisabled ? null : history.push(item.path)
                      }
                    >
                      {item.label}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            </div>

            <div style={{ marginTop: "3vh" }}>
              {/* <Button
                startIcon={<ArrowBackIosIcon />}
                variant="text"
                color="secondary"
                style={{ margin: "16px 24px", display: isMobile ? "" : "none" }}
                onClick={() => history.goBack()}
              >
                Back
              </Button> */}
              <div className={classes.header}>Your Cart</div>
              {/* {cashInLimits()} */}
              <div
                style={{
                  display: isMobile ? "block" : "flex",
                  marginTop: "3vh",
                }}
              >
                <Paper className={classes.cart}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      {isMobile ? (
                        <TableRow>
                          <TableCell
                            className={classes.columnItem}
                            align="left"
                          >
                            Item/s
                          </TableCell>
                        </TableRow>
                      ) : (
                        <div className="d-flex justify-content-between py-3">
                          <div
                            className={`${classes.columnItem} text-left col-4`}
                          >
                            Item
                          </div>
                          <div
                            className={`${classes.column} text-center col-3`}
                          >
                            Quantity
                          </div>
                          <div
                            className={`${classes.column} text-center col-3`}
                          >
                            Subtotal
                          </div>
                          <div className={`${classes.column} col-2`}></div>
                        </div>
                      )}
                    </TableHead>
                    {isMobile ? (
                      cartMobile()
                    ) : (
                      <TableBody>
                        {cartItems && cartItems.length > 0 ? (
                          cartItems.map((row, key) => (
                            <div
                              style={{ borderBottom: "1px dashed #d1d5dd" }}
                              className="py-2"
                            >
                              <div className="d-flex align-items-center justify-content-between">
                                <div className={`col-4 ${classes.itemSection}`}>
                                  <div
                                    className={classes.productImage}
                                    style={{
                                      backgroundImage: `url(${productImage(
                                        row
                                      )})`,
                                    }}
                                  />
                                  <div
                                    style={{
                                      marginLeft: "2vw",
                                      minWidth: "max-content",
                                    }}
                                  >
                                    <div className={classes.labelbold}>
                                      {row.name}
                                    </div>
                                    <VariantPicker
                                      index={key}
                                      product={row}
                                      productSelection={row.productSelection}
                                      handleConfirmVariant={
                                        handleConfirmVariant
                                      }
                                    />
                                    <div className="mb-2">
                                      <WishlistItemPriceDisplay
                                        symbol="₱"
                                        item={row}
                                        productSelection={row.productSelection}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-3 d-flex align-items-center justify-content-center">
                                  <div className={classes.labelbold}>
                                    {row.quantity}
                                  </div>
                                  <div className="d-flex ml-2">
                                    <Button
                                      variant="outlined"
                                      className={classes.btnQuantity}
                                      style={{ borderRight: "none" }}
                                      disabled={row.quantity === 1}
                                      onClick={() => decrement(row)}
                                    >
                                      <RemoveIcon style={{ fontSize: 12 }} />
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      className={classes.btnQuantity}
                                      disabled={checkStock(row)}
                                      onClick={() => increment(row)}
                                    >
                                      <AddIcon style={{ fontSize: 12 }} />
                                    </Button>
                                  </div>
                                </div>
                                <div
                                  className={`col-3 text-center ${classes.labelbold}`}
                                >
                                  ₱{" "}
                                  {Utils.commafy(
                                    parseFloat(
                                      row.quantity *
                                        (row.has_variants &&
                                        row.productSelection
                                          ? row.productSelection
                                              .discounted_price ||
                                            row.productSelection.price
                                          : row.discounted_price || row.price)
                                    )
                                  )}
                                </div>
                                <div className="col-2 d-flex justify-content-end">
                                  <Tooltip title="Remove" placement="top">
                                    <IconButton
                                      onClick={() => removeFromCart(key)}
                                    >
                                      <DeleteIcon
                                        style={{
                                          fontSize: 18,
                                          color: "#2b2d32",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                              {freebie.length
                                ? freebie
                                    .filter(
                                      (i) => row.uuid === i.minimum_x_product
                                    )
                                    .map((freebie) => (
                                      <FreebieCartPage
                                        freebie={freebie}
                                        product={row}
                                        freebieQuantity={freebieQuantity}
                                      />
                                    ))
                                : ""}
                              <div className="pt-2">{show_error(row)}</div>
                            </div>
                          ))
                        ) : (
                          <div className={classes.noItems}>
                            No item(s) in your cart.
                          </div>
                        )}
                      </TableBody>
                    )}
                  </Table>
                  <div
                    style={{
                      display: isMobile
                        ? "none"
                        : cartItems && cartItems.length > 0
                        ? "flex"
                        : "none",
                      justifyContent: "flex-end",
                      padding: "20px 0 0",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.btnContinue}
                      onClick={() =>
                        history.push(`/shop/${storeInfo.site}/products`)
                      }
                    >
                      Continue Shopping
                    </Button>
                    <Button
                      id="proceed_to_checkout"
                      variant="contained"
                      disableElevation
                      className={classes.btnCheckout}
                      color="secondary"
                      disabled={
                        cartItems.length == 0 || hasError
                        // (kyc == 0 && totalAmt > 5000) ||
                        // totalAmt > 30000 ||
                        // errorOpen == true ||
                      }
                      onClick={proceed_to_checkout}
                    >
                      Proceed to Checkout
                    </Button>
                  </div>
                </Paper>
                <Summary
                  loading={loading}
                  storeInfo={storeInfo}
                  cartItems={cartItems}
                  subtotal={subtotal}
                  total={total}
                  delivery_details={delivery_details}
                  voucher_discount={voucher_discount}
                  shipping_fee={shipping_fee}
                  proceed_to_checkout={proceed_to_checkout}
                  disabled={cartItems.length === 0 || hasError}
                  continue_shopping={true}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer store={storeInfo} />
        <VoucherModal
          id="voucher-modal"
          open={openVoucher}
          setOpen={setOpenVoucher}
        />
      </ThemeProvider>
    </div>
  );
};
export default Cart;
