import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import xenditFooterLogo from "../../../../assets/images/logo30.png";
import Utils from "../../../common/utils";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Chip
} from "@material-ui/core";
import PriceDisplay, {
  WishlistItemPriceDisplay
} from "../../../common/template/components/PriceDisplay";
export default function CardPaymentForm(props) {
  const { storeInfo, checkoutItem, cartitems } = props;
  const useStyles = makeStyles(theme => ({
    logo: {
      borderRadius: "50%",
      height: 45,
      width: 45,
      backgroundSize: "cover",
      backgroundPosition: "middle"
    },
    header: {
      textTransform: "uppercase",
      fontSize: 36,
      color: "#2b2d32",
      fontFamily: "Inter",
      [theme.breakpoints.down("sm")]: {
        fontSize: 24
      }
    },
    card: {
      border: "1px solid #E6EAF0",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
      background: "white",
      borderRadius: 4,
      [theme.breakpoints.down("md")]: {
        boxShadow: "none",
        borderRadius: 0
      }
    },
    key: {
      width: "30%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    orderDesktopView: {
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    orderMobileView: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    variantChip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    }
  }));
  const classes = useStyles();
  const voucher = checkoutItem.voucher ? JSON.parse(checkoutItem.voucher) : "";
  const amount = Utils.commafy(
    parseFloat(checkoutItem.amount) + parseFloat(checkoutItem.other_fees)
  );
  const subtotal = checkoutItem
    ? Utils.commafy(parseFloat(checkoutItem.subtotal))
    : 0;
  const quantity = parseFloat(
    cartitems.reduce((total, object) => {
      return parseFloat(total + object.quantity);
    }, 0)
  );

  function getProduct(product) {
    let params = {
      ...product,
      price: product.unit_price,
      has_variants: product.productSelection ? true : false
    };
    return params;
  }
  const orderTable = props => {
    return (
      <>
        <div className={classes.orderDesktopView}>
          <Table className={classes.table} style={{ marginTop: 10 }}>
            <TableHead style={{ borderTop: "1px solid #D1D5DD" }}>
              <TableRow>
                <TableCell
                  align="left"
                  style={{ color: "#2B2D33", maxWidth: 100 }}
                >
                  Item
                </TableCell>
                <TableCell align="center" style={{ color: "#2B2D33" }}>
                  Quantity
                </TableCell>
                <TableCell
                  align="center"
                  style={{ minWidth: 90, color: "#2B2D33" }}
                >
                  Unit Price
                </TableCell>
                <TableCell align="right" style={{ minWidth: 90 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartitems.map((it, key) => {
                return (
                  <TableRow key={it.name}>
                    <TableCell component="th" scope="row" align="left">
                      <p
                        className="mb-0"
                        style={{
                          wordWrap: "break-word",
                          fontFamily: "Inter"
                        }}
                      >
                        {it.name}
                      </p>
                      <div className="mt-2">
                        {it.productSelection
                          ? it.productSelection.variants.map(variant => (
                              <Chip
                                size="small"
                                label={variant.name}
                                className={classes.variantChip}
                              />
                            ))
                          : ""}
                      </div>
                    </TableCell>
                    <TableCell align="left">{it.quantity}</TableCell>
                    <TableCell align="center">
                      <WishlistItemPriceDisplay
                        symbol="₱"
                        item={getProduct(it)}
                        productSelection={it.productSelection}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {"₱ "}
                      {Utils.commafy(parseFloat(it.amount))}
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow>
                <TableCell
                  className="border-bottom-0"
                  component="th"
                  scope="row"
                  align="left"
                >
                  <p className="mb-0" style={{ color: "#2B2D33" }}>
                    Total items:
                  </p>
                </TableCell>
                <TableCell className="border-bottom-0" align="left">
                  <p className="mb-0">
                    <strong>{quantity}</strong>
                  </p>
                </TableCell>
                <TableCell className="border-bottom-0" align="left">
                  <p className="mb-0" style={{ color: "#2B2D33" }}>
                    Subtotal
                  </p>
                </TableCell>
                <TableCell className="border-bottom-0" align="right">
                  <p className="mb-0">
                    ₱ {Utils.commafy(checkoutItem.subtotal)}
                  </p>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  className="border-bottom-0"
                  component="th"
                  scope="row"
                  align="left"
                ></TableCell>
                <TableCell
                  className="border-bottom-0"
                  align="center"
                ></TableCell>
                <TableCell className="border-bottom-0" align="left">
                  <p className="mb-0">Other fees</p>
                </TableCell>
                <TableCell className="border-bottom-0" align="right">
                  <p className="mb-0">
                    {" "}
                    ₱ {Utils.commafy(checkoutItem.shipping_fee)}
                  </p>
                </TableCell>
              </TableRow>

              {voucher ? (
                <TableRow>
                  <TableCell
                    className="border-bottom-0"
                    component="th"
                    scope="row"
                    align="left"
                  ></TableCell>
                  <TableCell
                    className="border-bottom-0"
                    align="center"
                  ></TableCell>
                  <TableCell className="border-bottom-0" align="left">
                    <p className="mb-0">Voucher Applied</p>
                  </TableCell>
                  <TableCell className="border-bottom-0" align="right">
                    <p className="mb-0" id="voucher-discount">
                      - ₱ {Utils.commafy(voucher.voucher_discount)}
                    </p>
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}

              <TableRow>
                <TableCell
                  style={{ borderBottom: "none" }}
                  className="border-bottom-0"
                  component="th"
                  scope="row"
                  align="left"
                ></TableCell>
                <TableCell
                  style={{ borderBottom: "none" }}
                  className="border-bottom-0"
                  align="center"
                ></TableCell>
                <TableCell
                  className="border-bottom-0"
                  style={{ fontWeight: 600, borderBottom: "none" }}
                  align="left"
                >
                  Total
                </TableCell>
                <TableCell
                  className="border-bottom-0"
                  style={{ fontWeight: 600, borderBottom: "none" }}
                  align="right"
                >
                  ₱ {amount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div className={classes.orderMobileView}>
          {cartitems.map((it, key) => {
            return (
              <div
                style={{
                  borderTop: "1px solid #D1D5DD",
                  borderBottom: "1px solid #D1D5DD",
                  padding: 15
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    {it.quantity}x
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "block" }}>
                      <div>
                        <p style={{ wordWrap: "break-word" }}>{it.name}</p>
                        {it.productSelection
                          ? it.productSelection.variants.map(variant => (
                              <Chip
                                size="small"
                                label={variant.name}
                                className={`${classes.variantChip} mb-2`}
                              />
                            ))
                          : ""}
                      </div>
                      <p>
                        {" "}
                        <WishlistItemPriceDisplay
                          symbol="₱"
                          item={getProduct(it)}
                          productSelection={it.productSelection}
                        />
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                    item
                    xs={4}
                  >
                    <strong>
                      {"₱ "}
                      {Utils.commafy(parseFloat(it.amount))}
                    </strong>
                  </Grid>
                </Grid>
              </div>
            );
          })}

          <div className="mt-3">
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <div style={{ display: "block" }}>
                  <p>Total items</p>
                  <p>Subtotal</p>
                  <p>Other fees</p>
                  {voucher ? <p>Voucher Applied</p> : ""}
                  <div style={{ display: "block", fontWeight: 600 }}>Total</div>
                </div>
              </Grid>
              <Grid
                style={{ display: "block", justifyContent: "flex-end" }}
                item
                xs={4}
              >
                <p style={{ display: "flex", justifyContent: "flex-end" }}>
                  {quantity}
                </p>
                <p style={{ display: "flex", justifyContent: "flex-end" }}>
                  ₱ {Utils.commafy(subtotal)}
                </p>
                <p style={{ display: "flex", justifyContent: "flex-end" }}>
                  ₱ {Utils.commafy(checkoutItem.shipping_fee)}
                </p>
                {voucher ? (
                  <p
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    id="voucher-discount"
                  >
                    - ₱ {Utils.commafy(voucher.voucher_discount)}
                  </p>
                ) : (
                  ""
                )}
                <p style={{ display: "flex", justifyContent: "flex-end" }}>
                  <strong>₱ {amount}</strong>
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      id="card-landing-div"
      className="d-flex flex-column px-0 py-5"
      style={{ minHeight: "100vh", backgroundColor: "f8f8f8" }}
    >
      <div>
        <div className="d-flex w-100">
          <div className="d-flex mx-auto align-items-center">
            <div
              className={classes.logo}
              style={{
                backgroundImage: `url(${storeInfo.logo_image})`
              }}
            />
            <h5 className={`ml-3 mb-0 ${classes.header}`}>{storeInfo.name}</h5>
          </div>
        </div>
        {/* <div className="container"> */}
        <div className="row mt-4 mt-lg-5">
          <div className="col-12 col-lg-6">
            <div className={`px-3 px-lg-5 py-4 py-lg-5 ${classes.card}`}>
              <h4>
                <strong>Payment Successful</strong>
              </h4>
              <p className="mt-3">
                Thank you for purchasing items from our shop. Details of this
                transaction are sent to your email.
              </p>
              <div className="border-bottom mt-4">
                <h5>
                  <strong>Payment Details</strong>
                </h5>
              </div>
              <div className="d-flex my-4 flex-wrap">
                <p className={`mb-0 w-lg-auto ${classes.key}`}>
                  Reference Number:
                </p>
                <p className="mb-0 ml-0 ml-lg-auto mt-2 mt-lg-0">
                  <strong>{checkoutItem.ref_id}</strong>
                </p>
              </div>
              <div className="d-flex my-4 flex-wrap">
                <p className={`mb-0 w-lg-auto ${classes.key}`}>
                  Merchant Name:
                </p>
                <p className="mb-0 ml-0 ml-lg-auto mt-2 mt-lg-0">
                  <strong>{checkoutItem.sender_name}</strong>
                </p>
              </div>
              <div className="d-flex mb-4 flex-wrap">
                <p className={`mb-0 w-lg-auto ${classes.key}`}>Date Paid:</p>
                <p className="mb-0 ml-0 ml-lg-auto mt-2 mt-lg-0">
                  <strong>
                    {moment(checkoutItem.datetime_subscribed).format(
                      `MMMM DD, YYYY hh:mm a`
                    )}
                  </strong>
                </p>
              </div>
              <div className="d-flex mb-4 flex-wrap align-items-center">
                <p className={`mb-0 w-lg-auto ${classes.key}`}>Amount Paid:</p>
                <h2
                  className="mb-0 ml-0 ml-lg-auto mt-2 mt-lg-0"
                  style={{ color: "#CF3D1D" }}
                >
                  <strong>
                    ₱ {Utils.commafy(parseFloat(checkoutItem.amount))}
                  </strong>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-3 mt-lg-0">
            <div className={`px-3 px-lg-5 py-4 py-lg-5 ${classes.card}`}>
              <h5 className="mb-2">
                <strong>Order Details</strong>
              </h5>
              <div style={{ overflowX: "auto" }}>{orderTable(cartitems)}</div>
              {/* <h5 className="my-2">
                <strong>Delivery Arrangement</strong>
              </h5> */}
              {/* <hr></hr>
              <div>
                <p className={classes.deliveryNote}>
                  Note: Delivery fee is not included in the payment.
                </p>
                {storeInfo.email || storeInfo.mobile ? (
                  <>
                    <p>
                      We will inform you once items are ready for pick-up or
                      delivery. Kindly coordinate with us for the delivery
                      arrangement:
                    </p>
                    {storeInfo.email ? (
                      <div className="d-flex">
                        <MailOutlineIcon />
                        <span className="ml-2">{storeInfo.email}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    {storeInfo.mobile ? (
                      <div className="d-flex mt-1">
                        <PhoneOutlinedIcon />
                        <span className="ml-2">{storeInfo.mobile}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <p>
                    We will inform you once items are ready for pick-up or
                    delivery.
                  </p>
                )}
              </div> */}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="text-center mt-lg-auto mt-4">
        <p className="mb-3">Powered by</p>
        <img src={xenditFooterLogo} style={{ height: 25 }} alt="xendit" />
      </div>
    </div>
  );
}
