import {
  ProductActionTypes,
  ReduxInitializeFormActionTypes,
} from "./StoreActions";
import { getPromoCode } from "./PromoBanner12.12";
import {
  computeCartAmount,
  combine_same_products,
  add_to_cart,
  update_quantity,
} from "../../../enterprise/components/cart/common/productFunctions";

const initialState = {
  store: "",
  products: [],
  product_count: 0,
  selectedProduct: "",
  loading: false,
  error: "",
  checkout: "",
  buxCheckout: "",
  items: [],
  other_store_items: [],
  subtotal: "",
  total: "",
  errorMaintenance: false,
  payments: [],
  message: "",
  promo: {
    promo_code: getPromoCode(),
    bannerOpen: false,
    checked: false,
    eligible: false,
    discount: 0,
    amount_gt: 0,
    loading: false,
  },
  product_categories: {
    categories: [],
    loading: false,
    error: false,
  },
  voucherList: [],
  applied_voucher: "",
  voucher_discount: 0,
  customerDetails: "",
  deliveryLocations: [],
  deliveryDetails: {
    loading: false,
    error: "",
    shipping: "",
    shippingFee: 0,
    delivery_detail: "",
    mrspeedy_delivery_address: {
      address: "",
      longitude: "",
      latitude: "",
    },
  },
  cart_drawer: false,
  selected_payment_channel: "",
  couriers_shipping_fee: {
    loading: false,
    mrspeedy: 0,
    ninjavan: 0,
  },
  fees: {
    loading: false,
    cod_fee: 0,
    admin_fee: 0,
  },
  remarks: {},
};

const computeVoucherDiscount = (voucher, cart_amount) => {
  let voucher_discount = 0;
  if (cart_amount) {
    if (voucher.voucher_type === "fixed_amount") {
      voucher_discount = parseFloat(voucher.discount_value);
    } else if (voucher.voucher_type === "percentage") {
      voucher_discount =
        (parseFloat(voucher.discount_value) / 100) * cart_amount;
    }
  }
  return voucher_discount ? voucher_discount.toFixed(2) : 0;
};

export default function productReducers(state = initialState, action) {
  let cart_amount = 0;
  let itemToUpdate;
  let itemList;
  let alreadyListed = false;
  let new_items = [];
  let productSelection;
  switch (action.type) {
    case ProductActionTypes.GET_STORE_INFO:
      return {
        ...state,
        loading: false,
        store: action.payload,
      };
    case ProductActionTypes.GET_STORE_PRODUCTS:
      return {
        ...state,
        loading: false,
        products: action.payload.results
          ? action.payload.results
          : action.payload,
        product_count: action.payload.total_pages,
      };

    case ProductActionTypes.GET_SELECTED_PRODUCT:
      return {
        ...state,
        loading: false,
        selectedProduct: action.payload,
      };
    case ProductActionTypes.STORE_REQUEST:
      return {
        ...state,
        loading: true,
        buxOrderFetchLoading: true,
      };
    case ProductActionTypes.STORE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        buxOrderFetchLoading: false,
      };
    case ProductActionTypes.POST_STORE_CHECKOUT:
      return {
        ...state,
        loading: false,
        checkout: action.payload,
      };
    case ProductActionTypes.POST_STORE_BUXCHECKOUT:
      return {
        ...state,
        loading: false,
        buxCheckout: action.payload,
        buxOrderFetchLoading: false,
      };
    case ProductActionTypes.GET_PAYMENTS:
      return {
        ...state,

        payments: action.payload,
      };
    case ProductActionTypes.ADD_TO_CART:
      let itemToInsert = action.payload;
      let newItemList = [...state.items];
      let isAlreadyListed = false;
      new_items = add_to_cart(itemToInsert, newItemList);
      return {
        ...state,
        items: new_items,
        subtotal: computeCartAmount(new_items),
        total:
          computeCartAmount(new_items) +
          parseFloat(state.deliveryDetails.shippingFee) +
          state.fees.admin_fee +
          state.fees.cod_fee,
        voucher_discount: computeVoucherDiscount(
          state.applied_voucher,
          computeCartAmount(new_items)
        ),
      };

    case ProductActionTypes.SME_REMOVE_FROM_CART:
      let newState = [...state.items];
      newState.splice(action.payload, 1);
      return {
        ...state,
        items: newState,
        subtotal: computeCartAmount(newState),
        total:
          computeCartAmount(newState) +
          parseFloat(state.deliveryDetails.shippingFee) +
          state.fees.admin_fee +
          state.fees.cod_fee,
        voucher_discount: computeVoucherDiscount(
          state.applied_voucher,
          computeCartAmount(newState)
        ),
      };
    case ProductActionTypes.SME_UPDATE_QUANTITY:
      itemToUpdate = action.payload;
      productSelection = itemToUpdate.productSelection;
      itemList = [...state.items];
      alreadyListed = false;
      new_items = update_quantity(itemToUpdate, productSelection, itemList);
      return {
        ...state,
        items: new_items,
        subtotal: computeCartAmount(new_items),
        total:
          computeCartAmount(new_items) +
          parseFloat(state.deliveryDetails.shippingFee) +
          state.fees.admin_fee +
          state.fees.cod_fee,
        voucher_discount: computeVoucherDiscount(
          state.applied_voucher,
          computeCartAmount(new_items)
        ),
      };
    case ProductActionTypes.GET_TEMPLATE:
      return {
        ...state,
        loading: false,
        store: action.payload,
      };
    case ProductActionTypes.RESET_CART:
      return {
        ...state,
        items: [],
        deliveryDetails: {
          loading: false,
          error: "",
          shipping: "",
          shippingFee: 0,
          delivery_detail: "",
          mrspeedy_delivery_address: {
            address: "",
            longitude: "",
            latitude: "",
          },
        },
      };
    case ProductActionTypes.ERROR_MAINTENANCE:
      return {
        ...state,
        errorMaintenance: true,
        loading: false,
      };
    case ProductActionTypes.OPEN_ERROR:
      return {
        ...state,
        dialogOpen: true,
        message:
          "Oops! Looks like there is a problem with taking online orders for now. Kindly try again in a few minutes, or coordinate with us through any of the contact details below",
      };
    case ProductActionTypes.OPEN_SERVER_ERROR:
      return {
        ...state,
        dialogOpen: true,
        message: "An error has occured. Please try again.",
      };
    case ProductActionTypes.CLOSE_ERROR:
      return {
        ...state,
        dialogOpen: false,
      };

    case ProductActionTypes.CREATE_PROMO:
      return {
        ...state,
      };
    case ProductActionTypes.CREATE_PROMO_SUCCESS:
      return {
        ...state,
        promo: {
          code: action.payload.promoCode,
          created: true,
        },
      };
    case ProductActionTypes.CREATE_PROMO_FAILED:
      return {
        ...state,
        promo: {
          code: "",
          created: false,
        },
      };
    case ProductActionTypes.CHECK_PROMO_ELIGIBILITY:
      return {
        ...state,
        promo: {
          ...state.promo,
          loading: true,
        },
      };
    case ProductActionTypes.CHECK_PROMO_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        promo: {
          ...state.promo,
          eligible: action.payload.is_eligible,
          discount: action.payload.discount,
          amount_gt: action.payload.amount_gt,
          checked: true,
          loading: false,
        },
      };
    case ProductActionTypes.SET_PROMO_MODAL:
      return {
        ...state,
        promo: {
          ...state.promo,
          bannerOpen: action.payload.open,
        },
      };
    case ProductActionTypes.RESET_PROMO:
      return {
        ...state,
        promo: {
          ...state.promo,
          // promo_code: "12TWELVE",
          // bannerOpen: false,
          // checked: false,
          eligible: false,
          // discount: 0,
          // amount_gt: 0,
          // loading: true
          ...action.payload,
        },
      };
    case ProductActionTypes.GET_STORE_PRODUCT_CATEGORIES:
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          error: false,
          loading: true,
        },
      };
    case ProductActionTypes.GET_STORE_PRODUCT_CATEGORIES_SUCCESS:
      let categories = action.payload.filter((val) => val !== "");
      if (action.payload.includes("")) categories.push("");
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          categories,
          loading: false,
          error: false,
        },
      };
    case ProductActionTypes.GET_STORE_PRODUCT_CATEGORIES_FAILED:
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          error: true,
          loading: false,
        },
      };
    case ProductActionTypes.GET_VOUCHERS:
      return {
        ...state,
        voucherList: action.payload.sort((a, b) => (b.is_applicable ? 1 : -1)),
      };
    case ProductActionTypes.APPLY_VOUCHER:
      return {
        ...state,
        applied_voucher: action.payload,
        voucher_discount: computeVoucherDiscount(
          action.payload,
          computeCartAmount(state.items)
        ),
      };
    case ProductActionTypes.SET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload,
      };
    case ProductActionTypes.LOADING_ON:
      return {
        ...state,
        loading: true,
      };
    case ProductActionTypes.LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    case ProductActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        customerDetails: action.payload,
      };
    case ProductActionTypes.CART_DRAWER:
      return {
        ...state,
        cart_drawer: action.payload,
      };
    case ProductActionTypes.SET_DELIVERY_DETAILS:
      return {
        ...state,
        deliveryDetails: action.payload,
        subtotal: computeCartAmount(state.items),
        total:
          computeCartAmount(state.items) +
          parseFloat(action.payload.shippingFee) +
          state.fees.admin_fee +
          state.fees.cod_fee,
      };
    case ProductActionTypes.GET_DELIVERY_LOCATIONS:
      return {
        ...state,
        deliveryLocations: action.payload,
      };
    case ProductActionTypes.COMPUTE_SHIPPING:
      return {
        ...state,
        deliveryDetails: {
          ...state.deliveryDetails,
          loading: true,
        },
      };
    case ProductActionTypes.COMPUTE_SHIPPING_FAILED:
      return {
        ...state,
        deliveryDetails: {
          ...state.deliveryDetails,
          loading: false,
          error: action.payload,
        },
      };
    case ProductActionTypes.COMPUTE_SHIPPING_SUCCESS:
      return {
        ...state,
        deliveryDetails: {
          ...state.deliveryDetails,
          loading: false,
          shippingFee: action.payload.shipping_fee,
          delivery_detail: action.payload.delivery_detail,
        },
        subtotal: computeCartAmount(state.items),
        total:
          computeCartAmount(state.items) +
          parseFloat(action.payload.shipping_fee) +
          state.fees.admin_fee +
          state.fees.cod_fee,
      };
    case ProductActionTypes.SME_GET_BUYER_CART:
      const current_store_items = action.payload.current_store_items;
      return {
        ...state,
        items: current_store_items.length
          ? current_store_items
          : [...state.items],
        other_store_items: action.payload.other_store_items,
        subtotal: computeCartAmount(action.payload.current_store_items),
        total:
          computeCartAmount(action.payload.current_store_items) +
          parseFloat(state.deliveryDetails.shippingFee) +
          state.fees.admin_fee +
          state.fees.cod_fee,
      };
    case ProductActionTypes.SME_UPDATE_VARIANT:
      itemToUpdate = action.payload;
      productSelection = itemToUpdate.productSelection;
      itemList = [...state.items];
      new_items = combine_same_products(
        itemToUpdate,
        productSelection,
        itemList
      );
      console.log(new_items);
      for (let product of new_items) {
        if (product["uuid"] === itemToUpdate["uuid"]) {
          if (itemToUpdate.has_variants && productSelection) {
            if (itemToUpdate.prevSelection) {
              if (
                product.productSelection.id === itemToUpdate.prevSelection.id
              ) {
                product.variants = itemToUpdate.variants;
                product.productSelection = productSelection;
              }
            } else {
              product.variants = itemToUpdate.variants;
              product.productSelection = productSelection;
            }
          }
        }
      }
      return {
        ...state,
        items: new_items,
        subtotal: computeCartAmount(new_items),
        total:
          computeCartAmount(new_items) +
          parseFloat(state.deliveryDetails.shippingFee) +
          state.fees.admin_fee +
          state.fees.cod_fee,
        voucher_discount: computeVoucherDiscount(
          state.applied_voucher,
          computeCartAmount(new_items)
        ),
      };
    case ProductActionTypes.SET_SELECTED_PAYMENT_CHANNEL:
      return {
        ...state,
        selected_payment_channel: action.payload,
      };
    case ProductActionTypes.SHIPPING_FEE_LOADING_ON:
      return {
        ...state,
        couriers_shipping_fee: {
          ...state.couriers_shipping_fee,
          loading: true,
        },
      };
    case ProductActionTypes.SHIPPING_FEE_LOADING_OFF:
      return {
        ...state,
        couriers_shipping_fee: {
          ...state.couriers_shipping_fee,
          loading: false,
        },
      };
    case ProductActionTypes.NINJAVAN_SHIPPING_FEE:
      return {
        ...state,
        couriers_shipping_fee: {
          ...state.couriers_shipping_fee,
          loading: false,
          ninjavan: action.payload,
        },
      };
    case ProductActionTypes.MRSPEEDY_SHIPPING_FEE:
      return {
        ...state,
        couriers_shipping_fee: {
          ...state.couriers_shipping_fee,
          loading: false,
          mrspeedy: action.payload,
        },
      };
    case ProductActionTypes.COMPUTE_FEES:
      return {
        ...state,
        fees: {
          ...state.fees,
          loading: true,
        },
      };
    case ProductActionTypes.COMPUTE_FEES_FAILED:
      return {
        ...state,
        fees: {
          ...state.fees,
          loading: false,
        },
      };
    case ProductActionTypes.COMPUTE_FEES_SUCCESS:
      return {
        ...state,
        fees: { ...action.payload, loading: false },
        total:
          computeCartAmount(state.items) +
          parseFloat(state.deliveryDetails.shippingFee) +
          action.payload.admin_fee +
          action.payload.cod_fee,
      };
    case ProductActionTypes.RESET_FEES:
      return {
        ...state,
        fees: { cod_fee: 0, admin_fee: 0 },
      };
    case ProductActionTypes.SET_REMARKS:
      return {
        ...state,
        remarks: action.payload,
      };
    default:
      return state;
  }
}
