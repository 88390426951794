import React from "react";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  Paper,
  useMediaQuery
} from "@material-ui/core";
import BuyerSideMenu from "./buyerSideMenu";
import BuyerAppbar from "./buyerAppbar";
import PropTypes from "prop-types";
import CartCard from "./cartCard";
import { useSelector, useDispatch } from "react-redux";
import BuyerActions from "./redux/actions";
import SentroLoading from "../../common/loading";
import PurchasedCard from "./purchasedCard";
import IsPurchasedEmpty from "./isPurchasedEmpty";
import Path from "../../../Paths";
import history from "../../../store/history";
import { useTheme } from "@material-ui/core/styles";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "24px 0" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const PurchasedPage = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      backgroundColor: "#fafafa"
    },
    side: {
      display: "flex",
      position: "fixed",
      height: "100%",
      zIndex: 3
    },
    content: {
      width: "100%",
      marginTop: 55,
      paddingLeft: "35vw",
      paddingRight: "18vw",
      paddingBottom: "10vh"
    },
    header: {
      paddingTop: "5vh",
      fontSize: 28,
      fontWeight: 600,
      color: "#002c42",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    appbar: {
      boxShadow: "none",
      fontSize: 14,
      backgroundColor: "transparent",
      color: "#f97663"
    },
    tabs: {
      minWidth: 110,
      width: 110,
      fontSize: 12,
      borderBottom: "2px solid #d1d5dd"
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const token = sessionStorage.getItem("TOKEN");
  const [value, setValue] = React.useState(0);
  const purchased_orders = useSelector(
    state => state.buyerDashboard.purchased_orders
  );
  const loading = useSelector(state => state.buyerDashboard.loading);
  const tab = useSelector(state => state.buyerDashboard.purchased_tab);
  const [purchasedStatus, setPurchasedStatus] = React.useState("To Pay");
  const [alert, setAlert] = React.useState({
    open: false,
    alertType: "success",
    message: ""
  });

  function closeAlertBox() {
    setAlert({
      open: false,
      alertType: "success",
      message: ""
    });
  }

  const handleChange = (event, newValue) => {
    let status;
    if (newValue === 0) status = "To Pay";
    else if (newValue === 1) status = "To Ship";
    else if (newValue === 2) status = "To Receive";
    else if (newValue === 3) status = "Completed";
    else if (newValue === 4) status = "Cancelled";
    dispatch(BuyerActions.getPurchasedOrders(status));
  };

  React.useEffect(() => {
    if (token) {
      dispatch(BuyerActions.getPurchasedOrders(tab));
    }
  }, [token]);

  React.useEffect(() => {
    if (tab === "To Pay") setValue(0);
    else if (tab === "To Ship") setValue(1);
    else if (tab === "To Receive") setValue(2);
    else if (tab === "Completed") setValue(3);
    else if (tab === "Cancelled") setValue(4);
  }, [tab]);

  React.useEffect(() => {
    if (!user) {
      history.push(Path.LOGIN);
    } else {
      dispatch(BuyerActions.getMyCart());
      dispatch(BuyerActions.setSelectedCheckout([]));
      dispatch(BuyerActions.setSelectedViewOrder(""));
    }
  }, []);
  return (
    <div style={{ margin: isMobile ? 0 : "0 12vw" }}>
      <div className={classes.header}>Purchased</div>

      <div style={{ marginTop: isMobile ? 0 : "3vh" }}>
        <AppBar className={classes.appbar} position="static">
          <Tabs
            fullWidth
            indicatorColor="primary"
            textColor="primary"
            value={value}
            onChange={handleChange}
            variant="scrollable"
          >
            <Tab className={classes.tabs} label="To Pay" {...a11yProps(0)} />
            <Tab className={classes.tabs} label="To Ship" {...a11yProps(1)} />
            <Tab
              className={classes.tabs}
              label="To Receive"
              {...a11yProps(2)}
            />
            <Tab className={classes.tabs} label="Completed" {...a11yProps(3)} />
            <Tab className={classes.tabs} label="Cancelled" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={value}>
          <div>
            {purchased_orders && purchased_orders.length ? (
              purchased_orders.map((item, key) => {
                return (
                  <div style={{ marginBottom: "3vh" }}>
                    <PurchasedCard
                      item={item}
                      alert={alert}
                      setAlert={setAlert}
                      currentTab={tab}
                    />
                  </div>
                );
              })
            ) : (
              <IsPurchasedEmpty />
            )}
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

export default PurchasedPage;
