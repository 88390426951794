const defaultVariants = [
  {
    key: 1,
    name: "Color",
    variants: [
      { key: 1, name: "Black", is_enabled: false },
      { key: 2, name: "White", is_enabled: false }
    ]
  },
  {
    key: 2,
    name: "Size",
    variants: [
      { key: 1, name: "Small", is_enabled: false },
      { key: 2, name: "Medium", is_enabled: false },
      { key: 3, name: "Large", is_enabled: false }
    ]
  }
];

export default defaultVariants;
