import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { handleGetCurrentWallet } from "../../../../../utils/Metamask";
import { useDispatch, useSelector } from "react-redux";
import Actions from '../redux/actions';
/* Image */
import rightDesignImg from '../img/rightdesign.png';
import leftDesignImg from '../img/leftdesign.png';
import '../style/custom.css';

const NFTConnectMetamaskModal = props => {
  const dispatch = useDispatch();
  const useStyles = makeStyles(() => ({
    MuiDialogPaper: {
      backgroundColor: '#051626 !important',
      color: '#fff',
      position: 'relative',
      overflow: 'visible',
      border: '4px solid #B4C6F4',
      borderRadius: 0,
      '&::before': {
        content: `''`,
        position: 'absolute',
        left: '-15px',
        top: '-15px',
        background: `transparent url(${leftDesignImg}) no-repeat`,
        backgroundSize: 'contain',
        width: '56px',
        height: '90px',
      },
      '&::after': {
        content: `''`,
        position: 'absolute',
        right: '-22px',
        top: '-15px',
        background: `transparent url(${rightDesignImg}) no-repeat`,
        backgroundSize: 'contain',
        width: '56px',
        height: '90px',
      },
    },
  }));

  const classes = useStyles();

  const getCurrentWallet = async() => {
    dispatch(Actions.CONNECT_WALLET(props));

  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.MuiDialogPaper,
        }}
        className="nftFontFamily text-center"
        fullWidth={true}
        maxWidth="xs"
      >
        <div className="nft-modal-border">
          <DialogTitle id="alert-dialog-title">
            <span style={{ fontSize: '24px' }}>Connecting Wallet</span>
          </DialogTitle>
          <div className="w-50 mx-auto">
            <button className="nft-button-1 mt-3" onClick={getCurrentWallet}>METAMASK</button>
            <button className="nft-button-cut-corner mt-4" onClick={props.onClose}>Connect Later</button>
          </div>
          <div className="py-4 px-5 pt-1" style={{fontSize: '12px'}}>By connecting a wallet, you agree to AEW’s Terms of Use and acknowledge that you have read and understood the Disclaimer and Privacy Policy</div>
        
        </div>
      </Dialog>
    </>
  );
};

export default NFTConnectMetamaskModal;

