import React from "react";
import history from "../../store/history";
import Path from "../../Paths";
import { useDispatch } from "react-redux";
import DashboardActions from "../dashboard/dashboardActions";

// https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png
const ErrorPage = props => {
  let dispatch = useDispatch();

  setTimeout(() => {
    dispatch(DashboardActions.getStores());
  }, 3000);

  return (
    <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
      <div style={{ marginTop: "30vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            width={200}
            height="auto"
            src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            fontSize: 18,
            fontWeight: 600
          }}
        >
          Sorry! The page you visited is currently unavailable.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            fontSize: 16
          }}
        >
          Kindly double check the link, or try again after a few minutes.
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
