import Services from "./services";
import { showAlert } from "../../common/commonActions";
export const ActionTypes = {
  GET_PICKUP_ADDRESSES: "GET_PICKUP_ADDRESSES",
  SAVE_PICKUP_ADDRESSES: "SAVE_PICKUP_ADDRESSES",
  DELETE_PICKUP_ADDRESS: "DELETE_PICKUP_ADDRESS",
  GET_DELIVERY_LOCATIONS: "GET_DELIVERY_LOCATIONS",
  SAVE_DELIVERY_LOCATION: "SAVE_DELIVERY_LOCATION",
  DELETE_DELIVERY_LOCATION: "DELETE_DELIVERY_LOCATION",
  PATCH_STORE: "PATCH_STORE",
  SET_LOADING: "SET_LOADING",
  GET_MRSPEEDY: "GET_MRSPEEDY",
  GET_NINJAVAN: "GET_NINJAVAN",
  GET_BUX_SUBWALLET: "GET_BUX_SUBWALLET",
  SAVE_COURIER: "SAVE_COURIER",
  INITIAL_STATE: "INITIAL_STATE",
};

class Actions {
  static getPickupAddress(params) {
    return async (dispatch) => {
      try {
        let response = await Services.getPickupAddress(params);
        dispatch({
          type: ActionTypes.GET_PICKUP_ADDRESSES,
          payload: response.data,
        });
        return response.data;
      } catch (error) {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again.",
          })
        );
      }
    };
  }
  static savePickupAddress(data, addresses) {
    return async (dispatch) => {
      try {
        dispatch({ type: ActionTypes.SET_LOADING, payload: true });
        await Services.setDefaultPickupAddress(addresses);
        let response = await Services.savePickupAddress(data);
        dispatch({
          type: ActionTypes.SAVE_PICKUP_ADDRESSES,
          payload: response.data,
        });
        dispatch(
          showAlert({ type: "success", message: "Changes has been saved" })
        );
        return response.data;
      } catch (error) {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again.",
          })
        );
      }
    };
  }
  static getDeliveryLocations(store_uuid) {
    return async (dispatch) => {
      try {
        let response = await Services.getDeliveryLocations(store_uuid);
        dispatch({
          type: ActionTypes.GET_DELIVERY_LOCATIONS,
          payload: response.data,
        });
        return response.data;
      } catch (error) {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again.",
          })
        );
      }
    };
  }
  static saveDeliveryLocation(data) {
    return async (dispatch) => {
      try {
        let response = await Services.saveDeliveryLocation(data);
        dispatch({
          type: ActionTypes.SAVE_DELIVERY_LOCATION,
          payload: response.data,
        });
        dispatch(showAlert({ type: "success", message: "Changes saved" }));
        return response.data;
      } catch (error) {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again.",
          })
        );
      }
    };
  }
  static deleteDeliveryLocation(item) {
    return async (dispatch) => {
      try {
        await Services.deleteDeliveryLocation(item);
        dispatch({
          type: ActionTypes.DELETE_DELIVERY_LOCATION,
          payload: { id: item.id },
        });
        dispatch(
          showAlert({ type: "success", message: "Delivery location deleted" })
        );
        return true;
      } catch (error) {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again.",
          })
        );
        return false;
      }
    };
  }

  static getMrspeedy() {
    return async (dispatch) => {
      try {
        let res = await Services.getMrspeedy();
        dispatch({
          type: ActionTypes.GET_MRSPEEDY,
          payload: res.data,
        });
        return res;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static getNinjavan() {
    return async (dispatch) => {
      try {
        let res = await Services.getNinjavan();
        dispatch({
          type: ActionTypes.GET_NINJAVAN,
          payload: res.data,
        });
        return res;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static getBuxSubwallet() {
    return async (dispatch) => {
      try {
        let res = await Services.getBuxSubwallet();
        dispatch({
          type: ActionTypes.GET_BUX_SUBWALLET,
          payload: res.data,
        });
        return res;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static createBuxSubwallet() {
    return async (dispatch) => {
      try {
        let res = await Services.createBuxSubwallet();
        if (res.status === 200) {
          res = await Services.getBuxSubwallet();
          dispatch({
            type: ActionTypes.GET_BUX_SUBWALLET,
            payload: res.data,
          });
        }
        return res;
      } catch (error) {
        console.log(error);
      }
    };
  }
  static saveCourier(courier, values) {
    return async (dispatch) => {
      try {
        let res = await Services.saveCourier(courier, values);
        if (courier === "mrspeedy") {
          dispatch({ type: ActionTypes.GET_MRSPEEDY, payload: [res.data] });
        } else {
          dispatch({ type: ActionTypes.GET_NINJAVAN, payload: [res.data] });
        }
        // dispatch(showAlert({ type: "success", message: "Changes saved" }));
        return res;
      } catch (error) {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again.",
          })
        );
      }
    };
  }

  //TOGGLE PICKUP ADDRESSES
  static showPickupAddresses(values, show_pickup_address) {
    return async (dispatch) => {
      try {
        dispatch({ type: ActionTypes.SET_LOADING, payload: true });
        let res = await Services.showPickupAddresses(
          values,
          show_pickup_address
        );
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({ type: "success", message: "Changes has been saved" })
        );
        return res;
      } catch (error) {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again.",
          })
        );
      }
    };
  }

  //DELETE PICKUP ADDRESS
  static deletePickupAddress(value) {
    return async (dispatch) => {
      dispatch({ type: ActionTypes.DELETE_PICKUP_ADDRESS, payload: value });
    };
  }

  static deletePickupAddresses(values) {
    return async (dispatch) => {
      try {
        dispatch({ type: ActionTypes.SET_LOADING, payload: true });
        let res = await Services.deletePickupAddresses(values);
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({ type: "success", message: "Changes has been saved" })
        );
        return res;
      } catch (error) {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again.",
          })
        );
      }
    };
  }

  static setDefaultPickupAddress(values) {
    return async (dispatch) => {
      try {
        let res = await Services.setDefaultPickupAddress(values);
        console.log(res);
        return res;
      } catch (error) {
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again.",
          })
        );
      }
    };
  }

  static initialState() {
    return async (dispatch) => {
      dispatch({ type: ActionTypes.INITIAL_STATE });
    };
  }
}

export default Actions;

export const ReduxInitializeFormActionTypes = {
  LOAD_PICKUP_ADDRESS_DATA: "LOAD_PICKUP_ADDRESS_DATA",
};
export class ReduxInitializeFormActions {
  static initializePickupAddressData(data) {
    return {
      type: ReduxInitializeFormActionTypes.LOAD_PICKUP_ADDRESS_DATA,
      payload: data,
    };
  }
}
