import React, { useState } from "react";
import {
  Avatar,
  ListItemIcon,
  List,
  Chip,
  Button,
  Popover,
  MenuItem,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Path from "../../Paths";
import history from "../../store/history";
import { withStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { signOff, confirmLogOut } from "../auth/authActions";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import apiConfig from "../../common/apiConfig";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { ReactComponent as Shop } from "../../assets/images/sidebar_assets/v3_icons/shop.svg";
import { ReactComponent as Products } from "../../assets/images/sidebar_assets/v3_icons/products.svg";
import { ReactComponent as Orders } from "../../assets/images/sidebar_assets/v3_icons/orders.svg";
import { ReactComponent as Payments } from "../../assets/images/sidebar_assets/v3_icons/payments.svg";
import { ReactComponent as Vouchers } from "../../assets/images/sidebar_assets/v3_icons/vouchers.svg";
import { ReactComponent as Apps } from "../../assets/images/sidebar_assets/v3_icons/apps.svg";
import { ReactComponent as Marketplace } from "../../assets/images/sidebar_assets/v3_icons/marketplace.svg";
import { ReactComponent as Translate } from "../../assets/images/sidebar_assets/v3_icons/translate.svg";
import { ReactComponent as Cart } from "../../assets/images/sidebar_assets/cart.svg";
import { ReactComponent as Purchased } from "../../assets/images/sidebar_assets/purchased.svg";
import { ReactComponent as Discount } from "../../assets/images/sidebar_assets/discount.svg";
import { ReactComponent as Wishlist } from "../../assets/images/sidebar_assets/v3_icons/wishlist.svg";
import { ReactComponent as Media } from "../../assets/images/sidebar_assets/v3_icons/media.svg";
import { ReactComponent as Analytics } from "../../assets/images/sidebar_assets/v3_icons/analytics.svg";
import logo from "../../assets/images/sidebar_assets/sentro-logo.svg";
import MuiListItem from "@material-ui/core/ListItem";

import axios from "axios";
const SideMenu = (props) => {
  const { language, setLanguage } = props;
  const dispatch = useDispatch();
  let location = useLocation();
  let currentLoc = location.pathname;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: 240,
      backgroundColor: "#ce6e61",
      borderRadius: "0px 16px 0px 0px",
      height: "100%",
      overflow: "auto",
      position: "relative",
      "&::-webkit-scrollbar": {
        height: 0,
        width: 0,
        background: "transparent",
      },
    },

    avatar: {
      width: 35,
      height: 35,
      background: `linear-gradient(106.17deg, #35B0AB 4.56%, #226B80 154.44%)`,
      fontSize: 14,
      fontWeight: "bold",
      color: "#fff",
    },
    profile: {
      display: "flex",
      justifyContent: "space-between",
      padding: "20px 14px",
      borderBottom: "1px solid #c24a39",
    },
    listLabel: {
      color: "#fff",
      fontWeight: 500,
      fontSize: 14,
    },
    listBtn: {
      padding: "14px 14px 14px 0",
      backgroundColor: "#ce6e61",
      // "&$selected": {
      //   backgroundColor: "#c24a39"
      // },
      // "&:hover": {
      //   backgroundColor: "#c24a39"
      // }
    },
    icon: {
      fill: "white",
      [theme.breakpoints.down("sm")]: {
        fill: "#616161",
      },
    },
  }));

  const ListItem = withStyles({
    root: {
      "&$selected": {
        backgroundColor: "#c24a39",
      },
      "&$selected:hover": {
        backgroundColor: "#c24a39",
      },
      "&:hover": {
        backgroundColor: "#c24a39",
      },
    },
    selected: {},
  })(MuiListItem);

  const classes = useStyles();
  const subscription = useSelector((state) => state.subscription.subscription);
  let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let unreadOrders = sessionStorage.getItem("unread_orders");
  const [stores, setStores] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  let user_name = data ? data.first_name + " " + data.last_name : "";
  let isValid = data ? data.is_verified : false;
  let last_name = data ? data.last_name : "";
  let first_name = data ? data.first_name : "";
  let email = data ? data.email : "";
  let displayEmail = email ? email.split("@") : "";
  let displayName =
    first_name === "" && last_name === "" ? displayEmail[0] : user_name;
  const [active, setActive] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopover = Boolean(anchorEl);
  function handleAvatar(word) {
    let newWord = word.split(/\W+/);
    if (newWord.length === 2) {
      return (
        newWord[0].charAt(0).toUpperCase() + newWord[1].charAt(0).toUpperCase()
      );
    } else {
      return word.charAt(0).toUpperCase();
    }
  }

  const listContent1 = [
    {
      icon: <Shop className={classes.icon} />,
      label: "My Shop",
      key: "shop",
      path: "/dashboard",
      id: "my_shop",
    },
    {
      icon: <Products className={classes.icon} />,
      label: "Products",
      key: "products",
      path: "/products",
      id: "product",
    },
    {
      icon: <Orders className={classes.icon} />,
      label: "Orders",
      key: "orders",
      path: "/orders/list",
      id: "order",
    },
    {
      icon: <Payments className={classes.icon} />,
      label: "Payments",
      key: "payments",
      path: "/payments",
      id: "payment",
      isDisabled: stores ? (stores[0] ? false : true) : true,
    },
    {
      icon: <Vouchers className={classes.icon} />,
      label: "Vouchers",
      key: "vouchers",
      path: "/vouchers",
      id: "vouchers",
    },
    {
      icon: <Media className={classes.icon} />,
      label: "Media",
      key: "media",
      path: "/media",
      id: "media",
    },
    {
      icon: <Marketplace className={classes.icon} />,
      label: "Marketplace",
      key: "marketplace",
      id: "market_place",
      isDisabled: true,
    },
    {
      icon: <Analytics className={classes.icon} />,
      label: "Analytics",
      key: "analytics",
      id: "analytics",
      path: "/analytics",
      isDisabled: false,
    },
  ];

  const listContent2 = [
    {
      icon: <Cart className={classes.icon} />,
      label: "My Cart",
      key: "cart",
      path: "/my_cart",
      id: "cart",
    },
    {
      icon: <Purchased className={classes.icon} />,
      label: "Purchased",
      key: "purchased",
      path: "/purchased",
      id: "purchased",
    },
    {
      icon: <Discount className={classes.icon} />,
      label: "Discounts",
      key: "discounts",
      path: "/discounts",
      id: "discounts",
      isDisabled: true,
    },
    {
      icon: <Wishlist className={classes.icon} />,
      label: "Wishlist",
      key: "wishlist",
      id: "wishlist",
      isDisabled: true,
    },
  ];

  // const listContent3 = [
  //   {
  //     icon: <Apps className={classes.icon} />,
  //     label: "Apps & Other Services",
  //     key: "apps",
  //     path: "/marketplace",
  //     id: "app"
  //   }
  // ];

  function handleTrigger(key) {
    switch (key) {
      case "shop":
        history.push(Path.DASHBOARD);
        break;
      case "products":
        history.push(Path.PRODUCTS);
        break;
      case "orders":
        history.push(Path.ORDER_LIST_VIEW);
        break;
      case "payments":
        history.push(Path.PAYMENTS);
        break;
      case "vouchers":
        history.push(Path.VOUCHERS);
        break;
      case "apps":
        history.push(Path.APP_MARKETPLACE);
        break;
      case "cart":
        history.push(Path.BUYER_DASHBOARD);
        break;
      default:
        break;
    }
  }

  function subscriptionType() {
    if (!subscription.subscription_type) {
      return "Basic";
    } else if (subscription.subscription_type === "free_trial") {
      return "Free Trial";
    } else if (subscription.subscription_type === "advanced") {
      return "Advanced";
    }
  }

  React.useEffect(() => {
    setActive(currentLoc);
  }, [location]);

  React.useEffect(() => {
    (async () => {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/dashboard/store/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
          },
          params: { email: data.id },
        }
      );
      setStores(response.data);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/dashboard/orders/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
          },
        }
      );
      setOrderList(response.data);
    })();
  }, []);

  React.useEffect(() => {
    if (orderList.length) {
      const list = orderList.filter((item) => item.is_read === false);
      sessionStorage.setItem("unread_orders", list.length);
    }
  }, [orderList]);

  return (
    <div className={classes.root}>
      <div className={classes.children}>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ padding: 24, borderBottom: "1px solid #c24a39" }}
        >
          <img
            src={logo}
            alt="sentro_logo"
            style={{ width: "auto", height: 35 }}
            onClick={() => history.push(Path.DASHBOARD)}
          />
          {subscription && (
            <Chip
              size="small"
              label={subscriptionType()}
              variant="outlined"
              style={{ color: "#fff", borderColor: "#fff" }}
            />
          )}
        </div>
        <List style={{ padding: 0 }}>
          {/* <ListItem
            button
            selected={currentLoc.includes("/account_settings/")}
            className={classes.profile}
            onClick={() =>
              history.push(Path.ACCOUNT_SETTINGS_BASIC_INFORMATION)
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <Avatar className={classes.avatar}>
                  {handleAvatar(displayName)}
                </Avatar>
              </ListItemIcon>
              <div>
                <div
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 600,
                    color: "#fff"
                  }}
                >
                  {displayName}
                </div>
                <div style={{ fontSize: 12, color: "#fff" }}>Edit Profile</div>
              </div>
            </div>
            <div>
              <ArrowForward style={{ color: "#fff", fontSize: 14 }} />
            </div>
          </ListItem> */}
          <div style={{ borderBottom: "1px solid #c24a39" }}>
            <div className="px-4 py-2" style={{ fontSize: 12, color: "#fff" }}>
              SELL
            </div>
            {listContent1.map((item) => (
              <ListItem
                button
                id={item.id}
                selected={active.includes(item.path)}
                disabled={item.isDisabled}
                className={classes.listBtn}
                onClick={() => {
                  history.push(item.path);
                  setActive(item.path);
                }}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }}>
                  <ListItemIcon
                    style={{
                      minWidth: 56,
                      paddingLeft: item.key === "media" ? 17 : 20,
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <div className={classes.listLabel}>{item.label}</div>
                </div>
                {unreadOrders >= 1 ? (
                  <Chip
                    size="small"
                    label={unreadOrders}
                    style={{
                      fontSize: 10,
                      color: "#ce6e61",
                      backgroundColor: "#fff",
                      display: item.key === "orders" ? "" : "none",
                    }}
                  />
                ) : (
                  ""
                )}
                <Chip
                  size="small"
                  label="Soon!"
                  style={{
                    fontSize: 10,
                    color: "#909196",
                    backgroundColor: "#fff",
                    display: item.key === "marketplace" ? "" : "none",
                  }}
                />
              </ListItem>
            ))}
          </div>
          <div style={{ borderBottom: "1px solid #c24a39" }}>
            <div className="px-4 py-2" style={{ fontSize: 12, color: "#fff" }}>
              BUY
            </div>
            {listContent2.map((item) => (
              <ListItem
                button
                id={item.id}
                selected={item.path === active}
                disabled={item.isDisabled}
                className={classes.listBtn}
                onClick={() => {
                  history.push(item.path);
                  setActive(item.path);
                }}
              >
                <ListItemIcon style={{ minWidth: 56, paddingLeft: 17 }}>
                  {item.icon}
                </ListItemIcon>
                <div className={classes.listLabel}>{item.label}</div>
              </ListItem>
            ))}
          </div>
          <div>
            {/* {listContent3.map(item => (
              <ListItem
                button
                id={item.id}
                selected={item.path === active}
                className={classes.listBtn}
                onClick={() => {
                  history.push(item.path);
                  setActive(item.path);
                }}
              >
                <ListItemIcon style={{ minWidth: 56, paddingLeft: 17 }}>
                  {item.icon}
                </ListItemIcon>
                <div className={classes.listLabel}>{item.label}</div>
              </ListItem>
            ))} */}
            <div className="p-4">
              <Button
                disableElevation
                fullWidth
                variant="contained"
                className="d-flex justify-content-between align-items-center"
                style={{
                  backgroundColor: "#cb3631",
                  color: "#fff",
                  height: 40,
                }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <div>
                  <Translate style={{ color: "#fff", marginRight: 10 }} />
                  {language}
                </div>
                <KeyboardArrowDownIcon style={{ color: "#fff" }} />
              </Button>
              <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem
                  button
                  onClick={() => {
                    localStorage.setItem("language", "English");
                    setLanguage("English");
                    setAnchorEl(null);
                  }}
                  style={{ padding: "10px 68px", fontSize: 12 }}
                >
                  English
                </MenuItem>
                <MenuItem
                  button
                  onClick={() => {
                    localStorage.setItem("language", "Filipino");
                    setLanguage("Filipino");
                    setAnchorEl(null);
                  }}
                  style={{ padding: "10px 68px", fontSize: 12 }}
                >
                  Filipino
                </MenuItem>
              </Popover>
            </div>
          </div>
        </List>
      </div>
    </div>
  );
};

export default SideMenu;
