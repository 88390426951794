import React from "react";
import { Modal, Box, Dialog, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import SentroButton from "../../../../sentroButton";
import { closeDeleteDialog, getStoreProducts } from "../websiteBuilderActions";

require("dotenv").config();

const DeleteShopModal = props => {
  const useStyles = makeStyles(theme => ({
    paper: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content"
    },
    h2: {
      fontWeight: "bold",
      fontSize: 26,
      [theme.breakpoints.down("xs")]: {
        fontSize: 24
      }
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();

  function closeDialog() {
    dispatch(closeDeleteDialog());
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={props.open}>
      <DialogContent style={{ padding: 20 }}>
        <div className={classes.paper}>
          <h2 id="simple-modal-title" className={classes.h2}>
            Your online shop is now deleted
          </h2>
          <p id="simple-modal-description">
            <Box style={{ marginTop: 30, display: " flex" }}>
              <SentroButton
                id="close_button"
                variant="outlined"
                style={{
                  width: "100%",
                  border: "2px solid #F97663",
                  color: "#F97663"
                }}
                label="Go back to dashboard"
                onClick={() => closeDialog()}
              />
            </Box>
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteShopModal;
