import React from "react";
import Text from "../../../common/text";
import SentroButton from "../../common/sentroButton";
import CloseIcon from "@material-ui/icons/Close";
import ImageCropper from "../../common/utilities/ImageCropper/index";
import CancelIcon from "@material-ui/icons/Cancel";
import ProductImageUpload from "../../common/productImageUpload";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import StarIcon from "@material-ui/icons/Stars";
import {
  TextField,
  Switch,
  Modal,
  makeStyles,
  Backdrop,
  Fade,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
let StoreEditProductDialog = props => {
  const {
    openEdit,
    setEdit,
    product,
    setProduct,
    imageList,
    handleSaveEditProduct
  } = props;
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      backgroundColor: "#fff",
      borderRadius: "4px"
    },
    btnCancel: {
      margin: 5,
      width: 100,
      "&:hover": {
        border: "1px solid #F97663"
      }
    },
    btnSave: {
      margin: 5,
      width: 100,
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      "&:hover": {
        backgroundColor: "#DD5e56"
      }
    },
    imgLayout: {
      width: 110,
      height: 110,
      margin: 5,
      borderRadius: 5
    }
  }));
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  function setProductChanges(value, name) {
    setProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const images = imageList ? imageList : "";
  const [cropState, setCropState] = React.useState(false);
  const [cropImage, setCropImage] = React.useState(false);
  const [dialogHasErrors, setDialogHasErrors] = React.useState(true);
  const [isDialogEnough, setIsDialogEnough] = React.useState(false);
  const [dialogErrors, setDialogErrors] = React.useState({
    productNameError: "",
    productPriceError: "",
    productDescriptionError: "",
    productCategoryError: "",
    productImageError: ""
  });

  function handleActive() {
    setProductChanges(product.is_active === true ? false : true, "is_active");
  }
  function handleAlwaysAvailable() {
    setProductChanges(
      product.always_available === true ? false : true,
      "always_available"
    );
    if (product.always_available === false) {
      setDialogErrors({ ...dialogErrors, productQuantityError: "" });
    } else if (product.always_available === true) {
      setProductChanges(5, "quantity");
    }
  }

  const handleChangeImage = file => {
    images.push(file);
    setProductChanges(images, "productImages");
    setCropState(false);
  };

  const handleRemoveItem = e => {
    if (product.photo_primary === "0") {
      setProductChanges("0", "photo_primary");
    } else {
      setProductChanges(
        parseInt(product.photo_primary - 1).toString(),
        "photo_primary"
      );
    }
    images.splice(e, 1);
    setProductChanges(images, "productImages");
  };

  React.useEffect(() => {
    for (let entry in dialogErrors) {
      if (dialogErrors[entry].length > 0) {
        setDialogHasErrors(true);
        return;
      }
    }
    setDialogHasErrors(false);
  }, [dialogErrors]);

  React.useEffect(() => {
    setIsDialogEnough(
      product.name && product.price && product.description && images
        ? images.length !== 0
        : ""
    );
  }, [product]);

  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  function createPreview(file) {
    if (validURL(file)) {
      return file;
    } else {
      var blob = new Blob([file], { type: file.type });
      var blobUrl = URL.createObjectURL(blob);

      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";

      xhr.onload = function() {
        var recoveredBlob = xhr.response;

        var reader = new FileReader();

        reader.onload = function() {
          var blobAsDataUrl = reader.result;
          window.location = blobAsDataUrl;
        };

        reader.readAsDataURL(recoveredBlob);
      };

      return blobUrl;
    }
  }
  const FIELD_MAX_LENGTHS = {
    name: 50,
    category: 32,
    description: 512
  };

  let trimInput = (raw_input, maxLength) => {
    return maxLength > 0 && raw_input.length > maxLength
      ? raw_input.slice(0, maxLength)
      : raw_input;
  };

  let handleTextInput = params => {
    const { raw_input, fieldname } = params;
    const maxLength = FIELD_MAX_LENGTHS[fieldname] || 0;
    let input = trimInput(raw_input, maxLength);

    setProductChanges(input, fieldname);
  };

  let charactersLeftFor = fieldname => {
    const fieldLength = product[fieldname] ? product[fieldname].length : 0;
    const charsRemaining = FIELD_MAX_LENGTHS[fieldname] - fieldLength;
    return `${charsRemaining} characters left`;
  };

  let sanitize_price = raw_input => {
    const NOT_DOTS_NOR_NUMERICS_REGEX = /[^0-9\.]/g;
    const TWO_DOTS_REGEX = /\..*\./g;
    const ONE_DOT_REGEX = /\./g;

    let numerics_and_dots_only = raw_input.replace(
      NOT_DOTS_NOR_NUMERICS_REGEX,
      ""
    );
    // Allow at most 1 decimal place only
    let one_dot_at_most = TWO_DOTS_REGEX.test(numerics_and_dots_only)
      ? numerics_and_dots_only.slice(0, numerics_and_dots_only.lastIndexOf("."))
      : numerics_and_dots_only;
    // Limit to 2 decimal places
    let final_string = ONE_DOT_REGEX.test(one_dot_at_most)
      ? one_dot_at_most.slice(0, one_dot_at_most.lastIndexOf(".") + 3)
      : one_dot_at_most;

    return final_string;
  };

  React.useEffect(() => {
    if (product.quantity === "") {
      setDialogErrors({
        ...dialogErrors,
        productQuantityError: "This field is required."
      });
    } else if (product.quantity > 10000) {
      setDialogErrors({
        ...dialogErrors,
        productQuantityError: "This field must be less 10000."
      });
    } else {
      setDialogErrors({
        ...dialogErrors,
        productQuantityError: ""
      });
    }
  }, [product.quantity]);

  const CustomRadio = withStyles({
    root: {
      color: "#ffcf00",
      "&$checked": {
        color: "#ffcf00"
      }
    },
    checked: {}
  })(props => <Radio color="default" {...props} />);
  return (
    <div>
      <Modal
        open={openEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openEdit}>
          <div style={modalStyle} className={classes.paper}>
            <div style={{ padding: "25px 50px 25px 50px" }}>
              {!cropState ? (
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Text textType="h3" content="Edit product" />
                    <div
                      onClick={() => {
                        setEdit(false);
                        setCropImage(false);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: 400, marginRight: 50 }}>
                      <div style={{ width: "100%", marginTop: "2vh" }}>
                        <div style={{ display: "flex" }}>
                          <Text textType="p2_Semibold" content="Name" />
                          <span style={{ color: "#c24a39", marginLeft: 2 }}>
                            *
                          </span>
                        </div>
                        <TextField
                          margin="dense"
                          id="product_name"
                          color="secondary"
                          value={product.name}
                          fullWidth
                          onChange={value => {
                            const raw_input = value.target.value;
                            handleTextInput({
                              raw_input,
                              fieldname: "name"
                            });

                            if (raw_input === "") {
                              setDialogErrors({
                                ...dialogErrors,
                                productNameError: "This field is required."
                              });
                            } else {
                              setDialogErrors({
                                ...dialogErrors,
                                productNameError: ""
                              });
                            }
                          }}
                          onBlur={value => {
                            if (value.target.value === "") {
                              setDialogErrors({
                                ...dialogErrors,
                                productNameError: "This field is required."
                              });
                            }
                          }}
                          variant="outlined"
                          error={dialogErrors.productNameError}
                          helperText={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <div>{dialogErrors.productNameError}</div>
                              <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                {charactersLeftFor("name")}
                              </div>
                            </div>
                          }
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "70%" }}>
                          <div style={{ display: "flex" }}>
                            <Text textType="p1_Semibold" content="Price" />
                            <span style={{ color: "#c24a39", marginLeft: 2 }}>
                              *
                            </span>
                          </div>
                          <TextField
                            margin="dense"
                            id="product_price"
                            color="secondary"
                            value={product.price}
                            fullWidth
                            onChange={value => {
                              let input = sanitize_price(value.target.value);

                              handleTextInput({
                                raw_input: input,
                                fieldname: "price"
                              });

                              if (input === "") {
                                setDialogErrors({
                                  ...dialogErrors,
                                  productPriceError: "This field is required."
                                });
                              } else {
                                setDialogErrors({
                                  ...dialogErrors,
                                  productPriceError: ""
                                });
                              }
                            }}
                            onBlur={value => {
                              const raw_input = value.target.value;
                              const ENDS_WITH_DECIMAL_REGEX = /\.$/g;
                              if (raw_input === "") {
                                setDialogErrors({
                                  ...dialogErrors,
                                  productPriceError: "This field is required."
                                });
                              } else if (
                                ENDS_WITH_DECIMAL_REGEX.test(raw_input)
                              ) {
                                setDialogErrors({
                                  ...dialogErrors,
                                  productPriceError:
                                    "Please enter a valid price."
                                });
                              }
                            }}
                            variant="outlined"
                            error={dialogErrors.productPriceError}
                            helperText={dialogErrors.productPriceError}
                          />
                        </div>
                        <div style={{ width: "100%", marginLeft: 10 }}>
                          <div style={{ display: "flex" }}>
                            <Text textType="p1_Semibold" content="Category" />
                          </div>
                          <TextField
                            margin="dense"
                            id="product_category"
                            color="secondary"
                            value={product.category}
                            fullWidth
                            onChange={value => {
                              const raw_input = value.target.value;
                              handleTextInput({
                                raw_input,
                                fieldname: "category"
                              });
                            }}
                            multiline
                            variant="outlined"
                            error={dialogErrors.productCategoryError}
                            helperText={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <div>{dialogErrors.productCategoryError}</div>
                                <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                  {charactersLeftFor("category")}
                                </div>
                              </div>
                            }
                          />
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex" }}>
                          <Text textType="p2_Semibold" content="Description" />
                          <span style={{ color: "#c24a39", marginLeft: 2 }}>
                            *
                          </span>
                        </div>
                        <TextField
                          margin="dense"
                          id="product_description"
                          multiline
                          rows="4"
                          color="secondary"
                          value={product.description}
                          fullWidth
                          onChange={value => {
                            handleTextInput({
                              raw_input: value.target.value,
                              fieldname: "description"
                            });

                            if (value.target.value === "") {
                              setDialogErrors({
                                ...dialogErrors,
                                productDescriptionError:
                                  "This field is required."
                              });
                            } else {
                              setDialogErrors({
                                ...dialogErrors,
                                productDescriptionError: ""
                              });
                            }
                          }}
                          onBlur={value => {
                            if (value.target.value === "") {
                              setDialogErrors({
                                ...dialogErrors,
                                productDescriptionError:
                                  "This field is required."
                              });
                            }
                          }}
                          multiline
                          variant="outlined"
                          error={dialogErrors.productDescriptionError}
                          helperText={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <div>{dialogErrors.productDescriptionError}</div>
                              <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                {charactersLeftFor("description")}
                              </div>
                            </div>
                          }
                        />
                      </div>

                      <div style={{ display: "block" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <Text
                              textType="p2_Semibold"
                              content="Publish product?"
                            />
                            <span style={{ color: "#c24a39", marginLeft: 2 }}>
                              *
                            </span>
                          </div>
                          <Switch
                            checked={product.is_active}
                            onChange={() => handleActive()}
                            color="primary"
                          />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <Text
                              textType="p2_Semibold"
                              content="Always available?"
                            />
                            <span style={{ color: "#c24a39", marginLeft: 2 }}>
                              *
                            </span>
                          </div>
                          <Switch
                            checked={product.always_available}
                            onChange={() => handleAlwaysAvailable()}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ width: 400 }}>
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex" }}>
                          <Text textType="p2_Semibold" content="Photo" />
                          <span style={{ color: "#c24a39", marginLeft: 2 }}>
                            *
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ display: "flex" }}>
                            <div>
                              {images ? (
                                images.length === 5 ? (
                                  ""
                                ) : (
                                  <ProductImageUpload
                                    id="product_upload"
                                    onChange={value => {
                                      setCropState(value ? true : false);
                                      setCropImage(value);
                                    }}
                                  />
                                )
                              ) : (
                                <ProductImageUpload
                                  id="product_upload"
                                  onChange={value => {
                                    setCropState(value ? true : false);
                                    setCropImage(value);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <RadioGroup
                            style={{ flexDirection: "row" }}
                            value={product.photo_primary}
                            onChange={e =>
                              setProductChanges(e.target.value, "photo_primary")
                            }
                          >
                            {images
                              ? images.map((item, i) => (
                                  <div>
                                    <div
                                      key={i}
                                      style={{
                                        position: "relative"
                                      }}
                                    >
                                      <img
                                        src={
                                          typeof item === "string"
                                            ? item
                                            : createPreview(item)
                                        }
                                        className={classes.imgLayout}
                                      />
                                      <span
                                        style={{
                                          color: "#f97663",
                                          position: "absolute",
                                          top: 0,
                                          right: 0,
                                          margin: 5,
                                          cursor: "pointer"
                                        }}
                                      >
                                        <CancelIcon
                                          onClick={() => handleRemoveItem(i)}
                                          style={{
                                            fontSize: 28,
                                            color: "#f97663"
                                          }}
                                        />
                                      </span>
                                      <div
                                        style={{
                                          position: "absolute",
                                          bottom: 0,
                                          left: 8
                                        }}
                                      >
                                        <FormControlLabel
                                          key={i}
                                          color="secondary"
                                          value={i.toString()}
                                          control={
                                            <CustomRadio
                                              icon={<CircleUnchecked />}
                                              checkedIcon={<StarIcon />}
                                            />
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : ""}
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: product.always_available
                        ? "flex-end"
                        : "space-between"
                    }}
                  >
                    <div
                      style={{
                        display: product.always_available ? "none" : "flex"
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex" }}>
                          <Text
                            textType="p2_Semibold"
                            content="Stocks on hand"
                          />
                          <span style={{ color: "#c24a39", marginLeft: 2 }}>
                            *
                          </span>
                        </div>
                        <Text
                          textType="label1"
                          content="Quantity will be reduced for every order marked as paid."
                        />

                        <TextField
                          margin="dense"
                          id="product_quantity"
                          color="secondary"
                          value={product.quantity}
                          fullWidth
                          onChange={value => {
                            let input = sanitize_price(value.target.value);
                            handleTextInput({
                              raw_input: input,
                              fieldname: "quantity"
                            });

                            if (input === "") {
                              setDialogErrors({
                                ...dialogErrors,
                                productQuantityError: "This field is required."
                              });
                            } else if (input > 10000) {
                              setDialogErrors({
                                ...dialogErrors,
                                productQuantityError:
                                  "This field must be less 10000."
                              });
                            } else {
                              setDialogErrors({
                                ...dialogErrors,
                                productQuantityError: ""
                              });
                            }
                          }}
                          onBlur={value => {
                            const raw_input = value.target.value;
                            const ENDS_WITH_DECIMAL_REGEX = /\.$/g;
                            if (raw_input === "") {
                              setDialogErrors({
                                ...dialogErrors,
                                productQuantityError: "This field is required."
                              });
                            } else if (raw_input > 10000) {
                              setDialogErrors({
                                ...dialogErrors,
                                productQuantityError:
                                  "This field must be less 10000."
                              });
                            } else {
                              setDialogErrors({
                                ...dialogErrors,
                                productQuantityError: ""
                              });
                            }
                          }}
                          variant="outlined"
                          error={dialogErrors.productQuantityError}
                          helperText={dialogErrors.productQuantityError}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: props.always_available ? 0 : 30
                      }}
                    >
                      <SentroButton
                        id="cancel_button"
                        style={{ marginTop: 0, padding: 0 }}
                        className={classes.btnCancel}
                        onClick={() => {
                          setEdit(false);
                          setCropImage(false);
                        }}
                        label="Cancel"
                      />
                      <SentroButton
                        id="save_button"
                        style={{ marginTop: 0, padding: 0 }}
                        className={classes.btnSave}
                        disabled={
                          (!props.always_available && dialogHasErrors) ||
                          !isDialogEnough
                        }
                        label="Save"
                        variant="contained"
                        onClick={() => handleSaveEditProduct()}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      width: "80vw",
                      position: "relative",
                      margin: "2em 0"
                    }}
                  >
                    <ImageCropper
                      filename={cropImage.name}
                      image={URL.createObjectURL(cropImage)}
                      onSave={value =>
                        handleChangeImage(value, "productImages")
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default StoreEditProductDialog;
