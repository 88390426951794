import React from "react";
import { Snackbar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import { closeDialog } from "../../websiteBuilder/websiteBuilderActions";
require("dotenv").config();

const useStyles = makeStyles(theme => ({
  snackBar: {
    backgroundColor: "#fff",
    color: "#F97663"
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertDialogBox = props => {
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  const dispatch = useDispatch();

  function closeAlertBox() {
    dispatch(closeDialog());
  }

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => closeAlertBox()}
    >
      <Alert onClose={() => closeAlertBox()} severity={props.alertType}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertDialogBox;
