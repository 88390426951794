import BuyerDashboardServices from "../services/buyerDashboardServices";
import apiConfig from "../../../../common/apiConfig";
import history from "../../../../store/history";

export const BuyerDashboardActionTypes = {
  BUYER_CART: "BUYER_CART",
  BUYER_CART_SUCCESS: "BUYER_CART_SUCCESS",
  BUYER_DASHBOARD_FAILED: "BUYER_DASHBOARD_FAILED",
  GET_VIEW_ORDER: "GET_VIEW_ORDER",
  SET_SELECTED_CHECKOUT: "SET_SELECTED_CHECKOUT",
  SET_CUSTOMER_DETAILS: "SET_CUSTOMER_DETAILS",
  SET_DELIVERY_DETAILS: "SET_DELIVERY_DETAILS",
  GET_PAYMENTS: "GET_PAYMENTS",
  POST_STORE_CHECKOUT: "POST_STORE_CHECKOUT",
  SET_PURCHASED_TAB: "SET_PURCHASED_TAB",
  GET_PURCHASED_ORDERS: "GET_PURCHASED_ORDERS",
  GET_PURCHASED_VIEW_ORDER: "GET_PURCHASED_VIEW_ORDER",
  GET_VOUCHERS: "GET_VOUCHERS",
  APPLY_VOUCHER: "APPLY_VOUCHER",
  CANCEL_ORDER: "CANCEL_ORDER",
  REMOVE_FROM_LIST: "REMOVE_FROM_LIST",
  SET_PURCHASED_FILTERS: "SET_PURCHASED_FILTERS",
  SET_DELIVERY_DETAILS: "SET_DELIVERY_DETAILS",
  GET_DELIVERY_LOCATIONS: "GET_DELIVERY_LOCATIONS",
  COMPUTE_SHIPPING: "COMPUTE_SHIPPING",
  COMPUTE_SHIPPING_SUCCESS: "COMPUTE_SHIPPING_SUCCESS",
  COMPUTE_SHIPPING_FAILED: "COMPUTE_SHIPPING_FAILED",
  REMOVE_ITEM: "REMOVE_ITEM",
  UPDATE_ITEM: "UPDATE_ITEM",
  BUYER_UPDATE_VARIANT: "BUYER_UPDATE_VARIANT",
  POPUP_ALERT: "POPUP_ALERT"
};

class BuyerDashboardActions {
  static getMyCart(site) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.BUYER_CART });
        let response = await BuyerDashboardServices.getMyCart(site);
        let params = response.data;
        let personal = [];
        let wishlist = [];
        for (let i in params.personal) {
          personal.push(params.personal[i]);
        }
        for (let i in params.wishlist) {
          let items = params.wishlist[i].items;
          wishlist.push({
            ...params.wishlist[i],
            registry: items[0].registry && items[0].registry.id
          });
        }
        dispatch({
          type: BuyerDashboardActionTypes.BUYER_CART_SUCCESS,
          payload: [...personal, ...wishlist]
        });
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.POPUP_ALERT,
          payload: {
            open: true,
            alertType: "error",
            message: "An error occured. Please try again."
          }
        });
        console.log(error);
      }
    };
  }

  static viewOrder(site, type, params, id) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.BUYER_CART });
        let response = await BuyerDashboardServices.viewOrder(
          site,
          type,
          params
        );
        dispatch({
          type: BuyerDashboardActionTypes.GET_VIEW_ORDER,
          payload: { ...response.data, id }
        });
        return response;
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.POPUP_ALERT,
          payload: {
            open: true,
            alertType: "error",
            message: "An error occured. Please try again."
          }
        });
      }
    };
  }
  static setSelectedCheckout(values) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.SET_SELECTED_CHECKOUT,
        payload: values
      });
    };
  }

  static setSelectedViewOrder(values) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.GET_VIEW_ORDER,
        payload: values
      });
    };
  }

  static setSelectedPurchasedViewOrder(values) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.GET_PURCHASED_VIEW_ORDER,
        payload: values
      });
    };
  }

  static setCustomerDetails(values) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.SET_CUSTOMER_DETAILS,
        payload: values
      });
    };
  }

  static setDeliveryDetails(values) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.SET_DELIVERY_DETAILS,
        payload: values
      });
    };
  }

  static getPayments(uuid) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.BUYER_CART });
        let response = await BuyerDashboardServices.getPayments(uuid);
        dispatch({
          type: BuyerDashboardActionTypes.GET_PAYMENTS,
          payload: response.data
        });
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.POPUP_ALERT,
          payload: {
            open: true,
            alertType: "error",
            message: "An error occured. Please try again."
          }
        });
      }
    };
  }

  static checkout(values, paymentType, enterprise) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.BUYER_CART });
        let response = await BuyerDashboardServices.checkout(
          values,
          paymentType,
          enterprise
        );
        dispatch({
          type: BuyerDashboardActionTypes.POST_STORE_CHECKOUT,
          payload: response.data.order
        });
        let ref_id = response.data ? response.data.order.description : null;
        let access = response.data ? response.data.order.bux_uid : null;

        if (paymentType["paymentOption"] === "otc") {
          if (access) {
            window.location.href = `${apiConfig.buxURL}/checkout/${access}/?redirect_url=${apiConfig.localHost}enterprise/${enterprise}/buyer_dashboard/checkout?ref_id=${ref_id}`;
          }
        } else {
          history.push(`/enterprise/${enterprise}/buyer_dashboard/checkout`);
        }
        return response;
      } catch (error) {
        if (error.status === 400) {
          dispatch({
            type: BuyerDashboardActionTypes.POPUP_ALERT,
            payload: {
              open: true,
              alertType: "error",
              message:
                "Oops! Looks like there is a problem with taking online orders for now. Kindly try again in a few minutes, or coordinate with us through any of the contact details below."
            }
          });
        } else {
          dispatch({
            type: BuyerDashboardActionTypes.POPUP_ALERT,
            payload: {
              open: true,
              alertType: "error",
              message: "An error occured. Please try again."
            }
          });
        }
      }
    };
  }

  static buxCheckout(value) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.BUYER_CART });
        let response = await BuyerDashboardServices.buxCheckout(value);
        dispatch({
          type: BuyerDashboardActionTypes.POST_STORE_CHECKOUT,
          payload: response.data
        });
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.POPUP_ALERT,
          payload: {
            open: true,
            alertType: "error",
            message: "An error occured. Please try again."
          }
        });
      }
    };
  }

  static setPurchasedTab(value) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.SET_PURCHASED_TAB,
        payload: value
      });
    };
  }

  static getPurchasedOrders(enterprise, status, filters) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.BUYER_CART });
        let response = await BuyerDashboardServices.getPurchasedOrders(
          enterprise,
          status,
          filters
        );
        dispatch({
          type: BuyerDashboardActionTypes.SET_PURCHASED_TAB,
          payload: status
        });
        dispatch({
          type: BuyerDashboardActionTypes.GET_PURCHASED_ORDERS,
          payload: response.data
        });
        return response;
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.POPUP_ALERT,
          payload: {
            open: true,
            alertType: "error",
            message: "An error occured. Please try again."
          }
        });
      }
    };
  }

  static purchasedViewOrder(enterprise, id) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.BUYER_CART });
        let response = await BuyerDashboardServices.purchasedViewOrder(
          enterprise,
          id
        );
        dispatch({
          type: BuyerDashboardActionTypes.GET_PURCHASED_VIEW_ORDER,
          payload: response.data
        });
        return response;
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.POPUP_ALERT,
          payload: {
            open: true,
            alertType: "error",
            message: "An error occured. Please try again."
          }
        });
      }
    };
  }

  static removeItem(index) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.REMOVE_ITEM,
        payload: index
      });
    };
  }

  static updateItem(params, selected_order_items) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.UPDATE_ITEM,
        payload: {
          params,
          selected_order_items
        }
      });
    };
  }

  static updateItemVariant(productToUpdate, selected_order_items) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.BUYER_UPDATE_VARIANT,
        payload: {
          productToUpdate,
          selected_order_items
        }
      });
    };
  }

  static popupAlert(values) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.POPUP_ALERT,
        payload: values
      });
    };
  }

  static getVouchers(store_uuid, params) {
    return async dispatch => {
      try {
        let vouchers = await BuyerDashboardServices.getVouchers(
          store_uuid,
          params
        );
        dispatch({
          type: BuyerDashboardActionTypes.GET_VOUCHERS,
          payload: vouchers.data
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static applyVoucher(voucher) {
    return async dispatch => {
      try {
        dispatch({
          type: BuyerDashboardActionTypes.APPLY_VOUCHER,
          payload: voucher
        });
      } catch (error) {
        console.log(error);
      }
    };
  }

  static cancelOrder(id, params = { from: "detailed_view" }) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.BUYER_CART });
        let response = await BuyerDashboardServices.cancelOrder(id);
        dispatch({
          type: BuyerDashboardActionTypes.CANCEL_ORDER,
          payload: response.data
        });
        if (params.from === "list_page") {
          dispatch({
            type: BuyerDashboardActionTypes.REMOVE_FROM_LIST,
            payload: response.data.id
          });
        }
        return response;
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.POPUP_ALERT,
          payload: {
            open: true,
            alertType: "error",
            message: "An error occured. Please try again."
          }
        });
      }
    };
  }

  static viewCheckoutOrder(id) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.BUYER_CART });
        let response = await BuyerDashboardServices.viewCheckoutOrder(id);
        dispatch({
          type: BuyerDashboardActionTypes.POST_STORE_CHECKOUT,
          payload: response.data
        });
        return response;
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.POPUP_ALERT,
          payload: {
            open: true,
            alertType: "error",
            message: "An error occured. Please try again."
          }
        });
      }
    };
  }

  static setPurchasedFilters(filters) {
    return async dispatch => {
      dispatch({
        type: BuyerDashboardActionTypes.SET_PURCHASED_FILTERS,
        payload: filters
      });
    };
  }

  static getDeliveryLocations(uuid) {
    return async dispatch => {
      try {
        let response = await BuyerDashboardServices.getDeliveryLocations(uuid);
        dispatch({
          type: BuyerDashboardActionTypes.GET_DELIVERY_LOCATIONS,
          payload: response.data
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    };
  }
  static computeShipping(value, uuid) {
    return async dispatch => {
      try {
        dispatch({ type: BuyerDashboardActionTypes.COMPUTE_SHIPPING });
        let response = await BuyerDashboardServices.computeShipping(value);
        let params = {
          delivery_detail: {
            ...value,
            store: uuid
          },
          shipping_fee: response.data
        };
        dispatch({
          type: BuyerDashboardActionTypes.COMPUTE_SHIPPING_SUCCESS,
          payload: params
        });
        return response;
      } catch (error) {
        dispatch({
          type: BuyerDashboardActionTypes.COMPUTE_SHIPPING_FAILED,
          payload: error.status
        });
      }
    };
  }
}

export default BuyerDashboardActions;
