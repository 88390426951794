import { paymentActionTypes, ReduxInitializeFormActionTypes } from "./action";

const initialState = {
  loading: false,
  error: "",
  buxStatus: 0,
  buxParams: "",
  stores: [],
  paymentMethods: [],
  codWallet: "",
  reduxForm: {
    accountDetailsForm: ""
  }
};

export default function paymentReducers(state = initialState, action) {
  switch (action.type) {
    case paymentActionTypes.LOADING_ON:
      return {
        ...state,
        loading: true
      };
    case paymentActionTypes.LOADING_OFF:
      return {
        ...state,
        loading: false
      };
    case paymentActionTypes.GET_BUX_STATUS:
      return {
        ...state,
        buxStatus: action.payload
      };
    case paymentActionTypes.GET_BUX_PARAMS:
      return {
        ...state,
        buxParams: action.payload
      };
    case paymentActionTypes.GET_STORE:
      return {
        ...state,
        stores: action.payload
      };
    case paymentActionTypes.GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload
      };
    case paymentActionTypes.GET_COD_WALLET:
      return {
        ...state,
        loading: false,
        codWallet: action.payload
      };
    // redux forms
    case ReduxInitializeFormActionTypes.ACCOUNT_DETAILS_FORM_DATA:
      return {
        ...state,
        reduxForm: {
          ...state.reduxForm,
          accountDetailsForm: action.payload
        }
      };
    default:
      return state;
  }
}
