import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup } from '@material-ui/core';

const useStyles = theme => ({
  buttonGroup: {
    backgroundColor: "white",
    "& .button": {
      transition: "all 0.15s linear",
      padding: "0.5em 1em",
      height: "35px",
      color: '#F97663',
      background: 'white',
      border: "1px solid #F97663",
      fontWeight: 400,
      boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.1)",
      minWidth: 70
    },
    "& .selected": {
      background: '#C24A39',
      color: "white"
    },
  },
});

class SentroButtonGroup extends React.Component {

  select = (choice) => {
    if (this.props.onChange) this.props.onChange(choice);
  }

  render (){
    const { classes } = this.props;
    return (
      <ButtonGroup
          className={classes.buttonGroup}
          color="primary"
          aria-label="outlined primary button group"
      >
        {
          this.props.choices.map((choice, index) => (
          <Button
            key={index}
            size="small" 
            className={`${
              this.props.value===choice && `selected` } button`
            }
            onClick={() => {
              this.select(choice);
            }}
          >{choice}</Button>
          ))
        }
      </ButtonGroup>
    )
  }
}


export default withStyles(useStyles)(SentroButtonGroup);

