import React from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
export const checkProductAvailability = product => {
  console.log(product);
  const getStocks = product => {
    if (product.product_details.has_variants) {
      return product.productSelection ? product.productSelection.quantity : -2;
    } else {
      if (product.product_details.always_available) {
        return -1;
      } else {
        return product.product_details.quantity;
      }
    }
  };

  const stocks = getStocks(product);
  if (stocks === -2 || stocks === -1) {
    return "";
  } else if (stocks === 0) {
    return <small className="text-danger">Out of stock</small>;
  } else if (product.quantity >= stocks) {
    return (
      <small className="text-danger" style={{ fontSize: 10 }}>
        {" "}
        <ErrorOutlineIcon
          className="mr-1"
          style={{ color: "red", fontSize: 16 }}
        />{" "}
        You have reached the maximum quantity for this{" "}
        {product.product_details.has_variants ? "variant" : "product"}.
      </small>
    );
  }
};

export const disableCheckbox = product => {
  const getStocks = product => {
    if (product.product_details.has_variants) {
      return product.productSelection ? product.productSelection.quantity : -2;
    } else {
      if (product.product_details.always_available) {
        return -1;
      } else {
        return product.product_details.quantity;
      }
    }
  };

  const stocks = getStocks(product);
  if (stocks === -2 || stocks === -1) {
    return false;
  } else if (stocks === 0) {
    return true;
  } else if (product.quantity > stocks) {
    return true;
  }
};
