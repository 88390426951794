import { ActionTypes } from "./Actions";
const initialState = {
	currentUser: null,
	menuActive: "sales",
	loading: false,
	userLoading: false,
	modalLoading: false,
	dateFilter: {
		type: null,
		startDate: null,
		endDate: null
	},
	error: null,
	alert: '',
	subscriptions: []
};

export default function partnerDashboardReducers(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_MENU_ACTIVE:
			return {
				...state,
				menuActive: action.payload
			}
		// date filter
		case ActionTypes.SET_DATE_FILTER:
			return {
				...state,
				dateFilter: action.payload
			};
		// current user
		case ActionTypes.FETCHING_CURRENT_USER_DATA:
			return {
				...state,
				userLoading: true
			};
		case ActionTypes.FETCH_CURRENT_USER_SUCCESS:
			return {
				...state,
				userLoading: false,
				currentUser: {
					...action.payload, 
					user_roles: action.user_roles
				},
				error: null
			};
		case ActionTypes.FETCH_CURRENT_USER_FAILED:
			return {
				...state,
				userLoading: false,
				error: action.payload,
				currentUser: {
					...action.payload, 
					user_roles: []
				},
			};
		// Subscriptions list
		case ActionTypes.FETCHING_SUBSCRIPTIONS:
			return {
				...state,
				loading: true
			};
		case ActionTypes.FETCHING_SUBSCRIPTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				subscriptions: action.payload,
				error: null
			};

		// setting request status
		case ActionTypes.REQUEST_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		default:
			return initialState;
	}
}
