import React from "react";

import ProductContentStyles from "./productContentStyles";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  CardContent,
  Button,
  Divider,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import Utils from "../../utils";
import defaultSRC from "./defaultResources";
import imgPlaceholder from "../../../../assets/images/product_placeholder.png";
import usdcLogo from "../../../../assets/images/usdc-logo.png";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { ProductCardPriceDisplay } from "./PriceDisplay";
import { PriceDisplay } from "./products/priceDisplay";

export function getPrice(item, param = false) {
  const commafy = (price) => Utils.commafy(parseFloat(price));
  if (item.has_variants) {
    const selection_prices = item.selections.map((selection) =>
      parseFloat(selection.price)
    );
    const min = Math.min(...selection_prices);
    const max = Math.max(...selection_prices);
    return min === max ? min : `${commafy(min)} - ${commafy(max)}`;
  } else {
    return param?.original_price
      ? commafy(item.price)
      : commafy(item.discounted_price ? item.discounted_price : item.price);
  }
}

export default function ProductCard(props) {
  let productList = useSelector((state) => state.store.products);

  let storeInfo = useSelector((state) => state.store.store);
  let color = storeInfo && storeInfo.color;
  function productImage() {
    let item = props.item;
    if (item.gallery) {
      if (item.gallery.length) {
        if (item.photo_primary === null || item.photo_primary === "") {
          return item.gallery[0].image;
        } else {
          if (
            item.gallery[item.photo_primary] === "undefined" ||
            item.gallery[item.photo_primary] === undefined
          ) {
            return item.gallery[0].image;
          } else {
            return item.gallery[item.photo_primary].image;
          }
        }
      } else {
        return imgPlaceholder;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return imgPlaceholder;
      }
    }
  }

  function headerFont() {
    if (storeInfo) {
      if (storeInfo.font === null || storeInfo.font === undefined) {
        return defaultSRC[storeInfo ? storeInfo.template : "Classic"]
          .headerFont;
      } else {
        return storeInfo.font.title;
      }
    } else {
      return defaultSRC[storeInfo ? storeInfo.template : "Classic"].headerFont;
    }
  }
  function secondaryFont() {
    if (storeInfo) {
      if (storeInfo.font === null || storeInfo.font === undefined) {
        return defaultSRC[storeInfo ? storeInfo.template : "Classic"]
          .secondaryFont;
      } else {
        return storeInfo.font.body;
      }
    } else {
      return defaultSRC[storeInfo ? storeInfo.template : "Classic"]
        .secondaryFont;
    }
  }

  const getStyles = (template, theme) => {
    switch (template) {
      case "Classic":
        return {
          card: {
            borderRadius: 8,
            cursor: "pointer",
            transition: "all 0.1s linear",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            height: 345,
            borderRadius: "8px 8px 0 0",
            width: "100%",
            objectFit: "contain",
            backgroundColor: "#eee",
            [theme.breakpoints.down("sm")]: {
              height: 155,
            },
          },
          name: {
            fontSize: 20,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.87)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
          price: {
            fontSize: 16,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
          },
          sold_out: {
            borderRadius: "8px 0 0 0",
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
          sale: {
            borderRadius: "8px 0 0 0",
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#f0684a",
            color: "#fff",
          },
        };

      case "Contemporary":
        return {
          card: {
            borderRadius: 8,
            cursor: "pointer",
            transition: "all 0.1s linear",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            height: 345,
            borderRadius: "8px 8px 0 0",
            width: "100%",
            objectFit: "contain",
            backgroundColor: "#eee",
            [theme.breakpoints.down("sm")]: {
              height: 155,
            },
          },
          name: {
            fontSize: 20,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.87)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
          price: {
            fontSize: 16,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
          },
          sold_out: {
            borderRadius: "8px 0 0 0",
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
          sale: {
            borderRadius: "8px 0 0 0",
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#f0684a",
            color: "#fff",
          },
        };

      case "Minimalist":
        return {
          card: {
            borderRadius: 8,
            cursor: "pointer",
            transition: "all 0.1s linear",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            height: 345,
            borderRadius: "8px 8px 0 0",
            width: "100%",
            objectFit: "contain",
            backgroundColor: "#eee",
            [theme.breakpoints.down("sm")]: {
              height: 155,
            },
          },
          name: {
            fontSize: 20,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.87)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
          price: {
            fontSize: 16,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
          },
          sold_out: {
            borderRadius: "8px 0 0 0",
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
          sale: {
            borderRadius: "8px 0 0 0",
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#f0684a",
            color: "#fff",
          },
        };

      case "ClassicV2":
        return {
          card: {
            height: 350,
            backgroundColor: "#fff",
            cursor: "pointer",
            filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1))",
            transition: "all 0.1s linear",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            height: "75%",
            width: "100%",
            objectFit: "contain",
            backgroundColor: "#f5f5f5",
          },
          name: {
            fontSize: 20,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.87)",
          },
          price: {
            fontSize: 16,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
          },
          sold_out: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            top: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
          sale: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            top: 0,
            backgroundColor: "#f0684a",
            color: "#fff",
          },
        };
      case "ContemporaryV2":
        return {
          card: {
            cursor: "pointer",
            transition: "all 0.1s linear",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            width: "100%",
            height: 380,
            objectFit: "contain",
            backgroundColor: "#f5f5f5",
          },
          name: {
            fontSize: 28,
            fontWeight: 300,
            color: "#3E4149",
          },
          price: {
            fontSize: 16,
            fontWeight: 700,
            color: "#3E4149",
          },
          sold_out: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
          sale: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#DF53A6",
            color: "#fff",
          },
        };

      case "Leftist":
        return {
          card: {
            cursor: "pointer",
            transition: "all 0.1s linear",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            width: "100%",
            height: 330,
            objectFit: "contain",
            backgroundColor: "#f5f5f5",
          },
          name: {
            fontSize: 28,
            fontWeight: 300,
            color: "#3E4149",
          },
          sale: {
            width: "fit-content",
            right: 0,
            top: 20,
            backgroundColor: "#FB567D",
            color: "#fff",
          },
          sold_out: {
            fontSize: 14,
            width: "fit-content",
            right: 0,
            top: 20,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
          price: {
            fontSize: 16,
            fontWeight: 700,
            color: "#2b2d32",
          },
          slashed_price: {
            fontSize: 14,
            color: "#2b2d32",
          },
        };
      case "SariSari":
        return {
          card: {
            cursor: "pointer",
            transition: "all 0.1s linear",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            width: "100%",
            height: 330,
            objectFit: "contain",
            backgroundColor: "#f5f5f5",
          },
          name: {
            fontSize: 18,
            fontWeight: 400,
            color: color.primary.contrastText,
          },
          sale: {
            width: "fit-content",
            right: 0,
            backgroundColor: "#e4432a",
            color: "#fff",
          },
          sold_out: {
            fontSize: 14,
            width: "fit-content",
            right: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
          price: {
            fontSize: 16,
            fontWeight: 700,
            color: color.primary.contrastText,
          },
          slashed_price: {
            fontSize: 14,
            color: color.primary.contrastText,
          },
        };
      case "Pastel":
        return {
          card: {
            cursor: "pointer",
            transition: "all 0.1s linear",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            width: "100%",
            height: 250,
            objectFit: "contain",
            backgroundColor: "#f5f5f5",
          },
          name: {
            fontSize: 16,
            fontWeight: 500,
            color: "#3E4149",
          },
          sale: {
            width: "fit-content",
            left: 0,
            backgroundColor: "#f0684a",
            color: "#fff",
          },
          sold_out: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
          price: {
            fontSize: 16,
            fontWeight: 700,
            color: "#3E4149",
          },
          slashed_price: {
            fontSize: 14,
            color: "#3E4149",
          },
        };
      case "MinimalistV2":
        return {
          card: {
            cursor: "pointer",
            transition: "all 0.1s linear",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            width: "100%",
            objectFit: "contain",
            backgroundColor: "#f5f5f5",
          },
          name: {
            fontSize: 24,
            fontWeight: 300,
            color: "#2b2d32",
          },
          sale: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#f0684a",
            color: "#fff",
          },
          price: {
            fontSize: 18,
            fontWeight: 500,
            color: "#2b2d32",
          },
          slashed_price: {
            fontSize: 16,
            color: "#2b2d32",
          },
          sold_out: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
        };
      case "Blocks":
        return {
          card: {
            cursor: "pointer",
            transition: "all 0.1s linear",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            width: "100%",
            objectFit: "contain",
            backgroundColor: "#f5f5f5",
          },
          name: {
            fontSize: 18,
            fontWeight: 400,
            color: "#2b2d32",
          },
          sale: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#CD4F31",
            color: "#fff",
          },
          price: {
            fontSize: 18,
            fontWeight: 600,
            color: "#2b2d32",
          },
          slashed_price: {
            fontSize: 16,
            color: "#2b2d32",
          },
          sold_out: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
        };
      case "Greenery":
        return {
          card: {
            cursor: "pointer",
            transition: "all 0.1s linear",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            width: "100%",
            height: 500,
            objectFit: "cover",
            backgroundColor: "#f5f5f5",
          },
          name: {
            fontSize: 16,
            fontWeight: 500,
            color: "#2b2d32",
          },
          sale: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#CD4F31",
            color: "#fff",
          },
          price: {
            fontSize: 18,
            fontWeight: 600,
            color: "#2b2d32",
          },
          slashed_price: {
            fontSize: 16,
            color: "#2b2d32",
          },
          sold_out: {
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
        };
      case "Japandi":
        return {
          card: {
            cursor: "pointer",
            transition: "all 0.1s linear",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          },
          image: {
            width: "100%",
            height: 401,
            objectFit: "cover",
            backgroundColor: "#f5f5f5",
            [theme.breakpoints.down("sm")]: {
              height: 424,
            },
          },
          name: {
            fontStyle: "normal",
            fontSize: 16,
            fontWeight: 400,
            color: "#2b2d32",
          },
          sale: {
            fontSize: 14,
            fontWeight: 700,
            width: "fit-content",
            right: 0,
            backgroundColor: "rgba(62, 65, 73, 1)",
            color: "#fff",
          },
          price: {
            fontSize: 28,
            fontWeight: 400,
            color: "#2b2d32",
          },
          slashed_price: {
            fontSize: 20,
            color: "#2b2d32",
          },
          sold_out: {
            fontSize: 14,
            fontWeight: 700,
            width: "fit-content",
            right: 0,
            backgroundColor: "rgba(62, 65, 73, 1)",
            color: "#fff",
          },
        };
      case "NFT":
        return {
          card: {
            borderRadius: 8,
            cursor: "pointer",
            transition: "all 0.1s linear",
          },
          image: {
            height: 345,
            borderRadius: "8px 8px 0 0",
            width: "100%",
            objectFit: "contain",
            /* backgroundColor: '#eee', */
            [theme.breakpoints.down("sm")]: {
              height: 380,
            },
          },
          name: {
            fontSize: 20,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.87)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
          price: {
            fontSize: 16,
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
          },
          sold_out: {
            borderRadius: "8px 0 0 0",
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#3e4149",
            color: "#fff",
          },
          sale: {
            borderRadius: "8px 0 0 0",
            fontSize: 14,
            width: "fit-content",
            left: 0,
            backgroundColor: "#f0684a",
            color: "#fff",
          },
        };
      default:
        return {};
    }
  };

  let templateStyles = makeStyles((theme) =>
    getStyles(props.store ? props.store.template : "Blank", theme)
  );

  const classes = templateStyles();

  const setProductCardTemplate = () => {
    switch (props.store ? props.store.template : "Blank") {
      case "Classic":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-2 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div className="p-3">
              <div className={classes.name}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className={`${classes.price} d-flex mt-2`}>
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "Contemporary":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-2 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div className="p-3">
              <div className={classes.name}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className={`${classes.price} d-flex mt-2`}>
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "Minimalist":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-2 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div className="p-3">
              <div className={classes.name}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className={`${classes.price} d-flex mt-2`}>
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "ClassicV2":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-2 p-2 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div className="py-2">
              <div className={classes.name}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className={`${classes.price} d-flex mt-2`}>
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "ContemporaryV2":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-2 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div className="p-3 text-center">
              <div
                style={{
                  borderBottom: "2px solid #d4d5d8",
                  margin: "10px 90px",
                }}
              ></div>
              <div className={classes.name}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div
                className={`${classes.price} d-flex mt-2 justify-content-center`}
              >
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "Leftist":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative mb-3 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div>
              <div className={`${classes.name} mt-3 text-center`}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className="d-flex justify-content-center my-2">
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "SariSari":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-2 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div>
              <div className={`${classes.name} mt-3`}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className="d-flex justify-content-start my-2">
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "Pastel":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-2 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div>
              <div className={`${classes.name} mt-3`}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className="d-flex justify-content-start my-2">
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "MinimalistV2":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-md-2 m-0 py-3 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div className="text-center">
              <div className="d-flex justify-content-center mt-3 mb-2">
                <PriceDisplay item={props.item} classes={classes} />
                {/* <ProductCardPriceDisplay
                  storeInfo={storeInfo}
                  item={props.item}
                  peso_sign={true}
                /> */}
              </div>
              <div className={`${classes.name}`}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
            </div>
          </div>
        );
      case "Blocks":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-md-2 m-0 py-3 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div className="text-left">
              <div className={`${classes.name} mt-3 mb-2`}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className="d-flex justify-content-start ">
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "Greenery":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-md-2 m-0 py-3 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div className="text-left">
              <div className={`${classes.name} mt-3 mb-2`}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className="d-flex justify-content-start ">
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "Japandi":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative m-0 py-3 w-100`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />
            <div className="text-left  px-md-0 px-3">
              <div className={`${classes.name} mt-3 mb-2`}>
                {props.item ? props.item.name : "Your Product's Name"}
              </div>
              <div className="d-flex justify-content-start ">
                <PriceDisplay item={props.item} classes={classes} />
              </div>
            </div>
          </div>
        );
      case "NFT":
        return (
          <div
            onClick={() =>
              props.isPreview ? null : props.onSelect(props.item)
            }
            className={`${classes.card} position-relative w-auto`}
          >
            {props.item.discounted_price && props.item.quantity >= 1 ? (
              <div className={`${classes.sale} p-2 position-absolute`}>
                SALE
              </div>
            ) : (
              ""
            )}
            {props.item.quantity <= 0 && !props.item.has_variants ? (
              <div className={`${classes.sold_out} p-2 position-absolute`}>
                SOLD OUT
              </div>
            ) : (
              ""
            )}
            <img src={productImage()} className={classes.image} alt="img" />

            <div className="nft-border-card-diamond-container text-center">
              <div className="nft-border-card-diamond">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={usdcLogo}
                    alt="usdc logo"
                    style={{ height: "20px", marginRight: "5px" }}
                  />
                  {props.item.selections &&
                  props.item.selections.length > 0 &&
                  props.item.has_variants
                    ? props.item.selections[0].price
                    : props.item.price}
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="nft-border-card-diamond-price">
                    {props.item.selections &&
                    props.item.selections.length > 0 &&
                    props.item.has_variants
                      ? `PHP ${props.item.selections[0].price_in_php}`
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return "";
    }
  };

  return setProductCardTemplate(props.store ? props.store.template : "");
}
