import axios from "axios";
import apiConfig from "../../common/apiConfig";
import errorMessage from "../common/errorMessage";
import sentroAxios from "../../utils/SentroAxios";
class DashboardServices {
  static async getStoreInfo(storeSite) {
    try {
      let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
      let id = currentUser.id;
      let response = await axios.get(`${apiConfig.apiURL}/store/${storeSite}`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      });
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  }

  static async getStores() {
    localStorage.removeItem("STORE_UUID");
    try {
      let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
      let id = currentUser.id;
      let response = await axios.get(
        `${apiConfig.apiURL}/api/dashboard/store/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params: {
            email: id
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteStore(value) {
    try {
      let response = await axios.delete(
        `${apiConfig.apiURL}/api/store/${value.site}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      // GTM trigger
      let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
      window.dataLayer.push({
        eventAction: "Store Builder Deleted",
        eventCategory: "Store Builder",
        event: "storedeleted",
        userId: currentUser.id
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async goToBux(value) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/bux/login/`,
        { id: value },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async goBuxStatus(value) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/kyc/?id=${value}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }
  static async saveFBPageID(store, fb_page_id) {
    try {
      let response = await axios.put(
        `${apiConfig.apiURL}/api/store/${store}/`,
        {
          fb_page_id
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }
  static async saveFBPixelID(store, fb_pixel_id) {
    try {
      let response = await axios.put(
        `${apiConfig.apiURL}/api/store/${store}/`,
        {
          fb_pixel_id
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  static async saveKYCSurvey(value) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/user_survey/`,
        value,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );

      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  static async agreedTNC(value) {
    try {
      let response = await axios.put(
        `${apiConfig.apiURL}/api/user/update/`,
        value,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      let data = response.data;
      let existing = sessionStorage.getItem("CURRENT_USER");
      existing = existing ? JSON.parse(existing) : {};
      existing["tnc_agreed"] = data.tnc_agreed;
      sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  static async skipPassword(value) {
    try {
      let response = await axios.put(
        `${apiConfig.apiURL}/api/user/update/`,
        value,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      let data = response.data;
      let existing = sessionStorage.getItem("CURRENT_USER");
      existing = existing ? JSON.parse(existing) : {};
      existing["password_update"] = data.password_update;
      existing["skip_password"] = data.skip_password;
      sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }static async getCustomDomain({store_uuid}) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/aws/check-domain/${store_uuid}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }
  static async saveCustomDomain(store_uuid, domain_name) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/aws/custom-domain/`,
        {
          domain_name,
          store_uuid
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }
  static async checkDns(store_uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/aws/check-dns/${store_uuid}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  static async getAnalytics(params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/analytics/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }
}
export default DashboardServices;
