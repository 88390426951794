import React from "react";
import { Button,
  makeStyles,
  Dialog,
  DialogContent } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Actions from './redux/actions';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CloseIcon from '@material-ui/icons/Close';
require("dotenv").config();

const OTPModal = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left"
      }
    },
    closeIcon: {
      position: "absolute",
      top: 15,
      right: 15
    },
    heading: {
      fontWeight: 600,
      fontSize: 28,
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
      }
    },
    subhead: {
      fontSize: 16,
      color: "#2B2D32",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
      }
    },
    texthelp: {
      fontSize: 14,
    },
    resendText: {
      fontWeight: 600,
      fontSize: 14,
      color: "#F97663",
      cursor: "pointer"
    },
    digitBox: {
      fontSize: 28,
      borderRadius: 4,
      transition: "all 0.5s linear",
      background: "#EEEEEE",
      color: "#2B2D32",
      padding: "15px 10px",
      margin: 10,
      cursor: "text",
      caretColor: "transparent",
      textAlign: "center",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
        padding: "16px 5px",
        margin: 3,
        "& > input": {
          padding: 0
        }
      },
      "& input::-webkit-outer-spin-button": {
        '-webkit-appearance': 'none',
        margin: 0
      },
      "& input::-webkit-inner-spin-button": {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '-moz-appearance': 'textfield'
    },
    activeBox: {
      background: "white !important",
      padding: "15px 10px",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
        padding: "16px 5px",
        margin: 3,
      },
      "& > fieldset": {
        border: "solid 2px #F97663",
      }
    },
    progress: {
      cursor: "progress"
    }
  }));

  const dispatch = useDispatch();
  let loading = useSelector(state => state.otp.loading);
  let visible = useSelector(state => state.otp.visible);
  let mobile = useSelector(state => state.otp.mobile);
  let errorType = useSelector(state => state.otp.errorType);
  let uid = useSelector(state => state.otp.uid);
  const [activeBox, setActiveBox] = React.useState(0);
  const [otp, setotp] = React.useState(new Array(6).fill(""));
  const [timer, setTimer] = React.useState(30);
  const classes = useStyles();
  const refs = React.useRef([...Array(6)].map(() => React.createRef()));

  const focus = next => {
    if(refs.current[next]) {
      refs.current[next].current.focus();
      refs.current[next].current.select();
    }
  }
  const keypresslistener = (e) => {
    let values = otp;
    const val = e.target.value;
    if (errorType) {
      dispatch(Actions.RESET_ERROR());
    }
    const is_digit = [...Array(10).keys()].includes(parseInt(val));
    if (is_digit && val) {
      values[activeBox] = val;
      setotp(values);
      if (activeBox === 5) {
        const code = values.join("");
        dispatch(Actions.VERIFY_SMS_OTP({uid, code}, () => {
          if (props.proceedCallback) props.proceedCallback();
        }));
      }
    } else {
      values[activeBox] = "";
      setotp(values);
    }
  }

  const keyUp = (e) => {
    let next = activeBox;
    if ([...Array(10).keys()].includes(parseInt(e.key))) {
      next = activeBox < 5 ? activeBox + 1: activeBox
    } else if (e.key === "Backspace") {
      next = activeBox ? activeBox-1: 0;
    }
    setActiveBox(next);
    focus(next);
  }

  React.useEffect(() => {
    if (visible) {
      setActiveBox(0);
      setTimer(30);
      setotp(new Array(6).fill(""));
    }
  }, [visible])
  
  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer => timer > 0 ? timer - 1: timer);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer])

  const resend = () => {
    if (!loading && !timer) {
      setotp(new Array(6).fill(""));
      dispatch(Actions.SEND_SMS_OTP(mobile, () => {
        setTimer(30);
      }));
    }
  }

  return (
    <Dialog id="otp-dialog" fullWidth={true} maxWidth="sm" open={visible} >
      <DialogContent>
        <div className={`p-0 p-md-3 ${loading ? classes.progress: ""}`}>
          <CloseIcon id="close-icon" className={classes.closeIcon} 
            onClick={() => dispatch(Actions.SET_VISIBILITY(false))}/>
          <div className={classes.root}>
              <h2 className={`mb-2 ${classes.heading}`}>Verify Your Mobile Number</h2>
              <p id="mobile-display" className={`mb-2 ${classes.subhead}`}>
                We sent a One-Time Password to {`${mobile.slice(0,2)}*****${mobile.slice(-4)}`}.
              </p>
              {
                errorType !== "EXPIRED" ?
                <div className="d-flex">
                  <div className="d-flex my-4 mx-auto">
                    {[...Array(6).keys()].map((val, index) => (
                      <OutlinedInput
                        id={`InputDigit${index}`}
                        key={index}
                        type="number"
                        value={otp[index]}
                        inputRef={refs.current[index]}
                        className={`${classes.digitBox} 
                          ${index === activeBox ? classes.activeBox: ""} inputbox`}
                        placeholder="-"
                        disabled={loading}
                        margin="dense"
                        onClick={(e) => {
                          setActiveBox(index);
                          refs.current[index].current.select();
                        }}
                        inputProps={{
                          autoComplete: 'new-password',
                          form: { autoComplete: 'off' },
                          style: { textAlign: "center" },
                          maxLength: 1
                        }}
                        onChange={keypresslistener}
                        onKeyUp={keyUp}
                      />
                    ))}
                  </div>
                </div> : ""
              }
              {
                errorType ? 
                <p id="errorMessage" className={`text-danger ${errorType === "WAITING" ? "mb-4": "my-5"}`} style={{ fontSize: 14}}>
                  {
                    errorType === "WAITING" ? 
                    "Invalid OTP. Please try again." :
                    "OTP has already expired. Please click resend link below to get a new OTP."
                  }
                </p> : ""
              }
              <p className={`text-secondary ${classes.texthelp} mb-1`}>Didn't receive code?</p>
              <p id="resendBtn" className={classes.resendText} onClick={resend}>
                RESEND {timer ? `IN (${timer} secs)`: "OTP NOW"}
              </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OTPModal;
