import React, { Fragment } from "react";
import clsx from 'clsx';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ACL from "../../common/utilities/ACL";
import SentroTextField from "../../common/sentroTextField";
import Action from "../Redux/Actions";
import AdminDashboardLayout from "../layout";
import moment from "moment";
import { Divider } from "@material-ui/core";
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Link, Element, animateScroll as scroll } from "react-scroll";
import Path from "../../../Paths";
import { FormUser } from "./form_admin_user";

const useStyles = theme => ({
	cardWrapper: {
		margin: "0em 1.5em 1.5em",
		[theme.breakpoints.down("sm")]: {
			margin: "0em 1em 1em"
		},
	},
	userDetailsDiv: {
		padding: "1.5em"
	},
	formWrapper: {
		maxWidth: 410
	},
	card: {
		border: "1px solid #E6EAF0",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
		borderRadius: 4,
		backgroundColor: "white",
		padding: "2.0em",
		maxWidth: "100%"
	},
	pageHeader: {
		backgroundColor: "#F1F1F1",
		display: "flex !important",
		padding: "3em 1.5em 1.5em",
		alignItems: "center",
		fontWeight: 600,
		fontSize: 14,
		color: "#F97663 !important"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	paddingTop: {
		marginTop: theme.spacing(2),
	},
	inputLabel: {
		color: "#212B36", 
		fontSize: 12,
		marginBottom: "0.5em !important"
	},
	input: {
		width: "100%"
	},
	radioGroup: {
		display: "flex",
		flexDirection: "row",
		width: "100%"
	},
	radio: {
		backgroundColor: 'transparent',
		border: "solid 1px rgb(249, 118, 99)",
		width: 24,
		height: 24,
		borderRadius: "50%",
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  margin: 3,
		  borderRadius: "50%",
		  background: 'white',
		  content: '""'
		}
	},
	checkedRadio: {
		backgroundColor: 'transparent',
		border: "solid 1px #d7d7d7",
		width: 24,
		height: 24,
		borderRadius: "50%",
		'&:before': {
		  display: 'block',
		  width: 16,
		  height: 16,
		  margin: 3,
		  borderRadius: "50%",
		  background: "rgb(249, 118, 99)",
		  content: '""'
		}
	},
	headerButtons: {
		height: 35,
		marginLeft: 7,
		flexGrow: 0,
		marginTop: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: 5,
		},
	},
	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
	dFlex: {
		display: "flex"
	},
	detailTitle: {
		minWidth: 200,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			minWidth: "auto"
		},
	}, 
	detailData: {
		fontWeight: 600,
		textAlign: "left",
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			textAlign: "right"
		},
	},
	alertSuccess: {
		padding: "0.75em 1em",
		border: "solid 1px #1DD28B",
		borderLeft: "solid 5px #1DD28B",
		borderRadius: 4,
		background: "#1dd28b14",
		margin: "0em 1.5em 2em"
	}
});

class DetailUserAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// page details vars
			has_admin_role: false,
			access_type: 'is_admin',
			page_mode: localStorage.getItem("USER_DETAIL_MODE") ? localStorage.getItem("USER_DETAIL_MODE") : "detail_view",
			
			// user details
			first_name: '',
			last_name: '',
			email: '',
			is_admin: true,
			is_superuser: false,
			created_on: '',
			is_verified: false,

			// dialog vars
			dialogType: "edit",
			dialogStat: false,
			dialogErrorMessage: '',
			dialogTitleText: '',
			dialogBody: '',

			alert: ''
		}
		this.setDialogState = this.setDialogState.bind(this)
		this.setPageMode = this.setPageMode.bind(this)

		this.handleChange = this.handleChange.bind(this)
		this.handleAccessTypeChange = this.handleAccessTypeChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount = async () => {
		const { dispatch, login, match } = this.props
		const id = match.params.id
		const response = await dispatch(Action.userDetailMethod("get", id, {}));
		if (response.data) {
			const { is_admin, is_superuser, first_name,
					last_name, email, created_on, is_verified } = response.data
			this.setState({
				has_admin_role: response.data ? is_admin || is_superuser : false,
				access_type: is_superuser ? "is_superuser" :  "is_admin",
				email,
				first_name,
				last_name,
				created_on,
				is_verified,
				is_superuser,
				is_admin
			})
		}
	}
	
	setDialogState(state, dialogType = "edit") {
		console.log(state, dialogType)
		this.setState({
			dialogStat : state,
			dialogType: state ? dialogType: this.state.dialogType
		})
	}

	handleDelete = async () => {
		const { dispatch, match, history } = this.props
		const id = match.params.id
		const response = await dispatch(Action.userDetailMethod("delete", id, {}))
		this.setDialogState(false)
		if (response.status == 200 || response.status == 204) {
			localStorage.setItem("ALERT_SUCCESS", "Admin user deleted successfully")
			history.push(Path.ADMIN_ADMIN_USERS)
		} else {
			this.setState({
				dialogStat: true,
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}
	
	setPageMode(page_mode){
		this.setState({
			page_mode
		})
		if (page_mode === "detail_view") {
			this.componentDidMount()
		}
	}
	
	handleChange = (event, stateName) => {
		this.setState({
			[stateName] : event.target.value
		})
	}
	
	handleAccessTypeChange(event) {
		this.setState({
			is_admin: event.target.value === "is_admin",
			is_superuser: event.target.value === "is_superuser",
			access_type: event.target.value
		})
	}

	handleSubmit = async () => {
		const { dispatch, match, history } = this.props
		const id = match.params.id
		const { is_admin, is_superuser, first_name, last_name, email } = this.state
		this.setDialogState(false)
		const response = await dispatch(Action.userDetailMethod("patch", id, {
			first_name,
			last_name,
			email,
			is_admin,
			is_superuser
		}))
		if (response.status == 200) {
			localStorage.setItem("ALERT_SUCCESS", "Admin user successfully updated")
			history.push(Path.ADMIN_ADMIN_USERS)
		} else {
			this.setState({
				dialogStat: true,
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}

	resendVerificationEmail = async () => {
		const { email } = this.state
		const { dispatch } = this.props
		const response = await dispatch(Action.resendVerification(email));
		const { first_name, last_name } = this.state
		if (response.status == 200) {
			this.setState({
				alert: `Activation link sent to ${first_name} ${last_name} (${email})`
			})
			this.setDialogState(false)
		} else {
			this.setState({
				dialogStat: true,
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}
	
	render (){
		const {
			page_mode,
			has_admin_role,
			first_name,
			last_name,
			email,
			access_type,
			created_on,
			is_verified,
			dialogStat,
			dialogType,
			dialogTitleText,
			dialogBody,
			dialogErrorMessage,
			alert
		} = this.state;
		const { classes, adminDashboard, history } = this.props
		const dialogTitle = () => {
			if (dialogType === "edit") {
				return "Are you sure you want to edit this record?"
			} else if (dialogType == "delete") {
				return "Are you sure you want to delete this record?"
			} else if (dialogType==="info") {
				return dialogTitleText
			} else if (dialogType == "resend_verification") {
				return "Please confirm"
			}
		}
		const dialogContent = () => {
			if (["edit", "delete"].includes(dialogType)) {
				return "Click submit to confirm"
			} else if (dialogType==="info") {
				return dialogBody
			} else if (dialogType == "resend_verification") {
				return `Resend activation link to email ${email}?`
			}
		}
		const actionButton = () => {
			if (dialogType==="edit") {
				return (
					<Button
						id="submit_button" 
						onClick={(event) => this.handleSubmit()} 
						color="primary" 
						autoFocus
						variant="contained"
						disableElevation 
						className={classes.modalActions}
						style={{marginLeft: 10}}
					>
						Submit
					</Button>
				)
			} else if (dialogType==="delete") {
				return (
					<Button 
						id="delete_button"
						onClick={(event) => {
							this.handleDelete()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Delete
					</Button>
				)
			} else if (dialogType==="resend_verification") {
				return (
					<Button 
						id="confirm_button"
						onClick={(event) => {
							this.resendVerificationEmail()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Confirm
					</Button>
				)
			}
		}
		return (
			<AdminDashboardLayout>
				<ACL roles={["superuser"]} redirect_to="/admin/dashboard">
					<Dialog
						open={dialogStat}
						onClose={(event) => this.setDialogState(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						maxWidth="xs"
					>	
						<div className={classes.modalDiv}>
							<div className={classes.modalTile}>
								{dialogTitle()}
							</div>
							<div className={classes.modalBody}>
								{dialogContent()}
							</div>
							<div className={classes.modalActionsWrapper}>
								<Button
									id="action_button"
									variant="outlined"
									onClick={(event) => this.setDialogState(false)} 
									color="primary"
									className={classes.modalActions}
								>
									{
										dialogType == "info" ?
										"Close"
										:
										"Cancel"
									}
								</Button>
								{actionButton()}
							</div>
						</div>
					</Dialog>
					<div>
						<a className={classes.pageHeader} onClick={() => {
							if (page_mode === "detail_view"){
								history.push(Path.ADMIN_ADMIN_USERS)
							} else {
								this.setPageMode("detail_view")
							}
						}}>
							<ArrowBackIcon/>
							<span style={{marginLeft: "1em"}}>{`  BACK`}</span>
						</a>
					</div>
					{ 
						page_mode === "detail_view" ?
						<Fragment>
							{
								has_admin_role ?
								<div style={{
									display: "flex",
									flexWrap: "wrap",
									alignItems: "center",
									marginBottom: "2em",
									padding: "0 1.5em"
								}}>
									<div className={classes.pageTitle}>
										View Details
									</div>
									<Button
										id="edit_button" 
										variant="contained" 
										color="primary" 
										disableElevation 
										className={classes.headerButtons}
										onClick={(event) => this.setPageMode("form_view")}
										style={{ marginLeft: 0 }}
									>
										Edit
									</Button>
									<Button
										id="delete_button"
										variant="outlined" 
										color="primary" 
										disableElevation 
										className={classes.headerButtons}
										onClick={(event) => this.setDialogState(true, "delete")}
									>
										Delete
									</Button>
									{
										is_verified ?
										<Button 
											id="reset_button"
											variant="outlined" 
											color="primary" 
											disableElevation 
											className={classes.headerButtons}
											disabled
											// onClick={(event) => this.setDialogState(true, "delete")}
										>
											Reset Password
										</Button>
										:
										<Button 
											id="resend_button"
											variant="outlined" 
											color="primary" 
											disableElevation 
											className={classes.headerButtons}
											onClick={(event) => this.setDialogState(true, "resend_verification")}
										>
											Resend Activation Link
										</Button>
									}
								</div>
								:
								""
							}
							<div>
								{ 
								adminDashboard.error ?
								"" 
								:
								<Fragment>
									{
										has_admin_role ?
										<Fragment>
											{
												alert ?
												<p className={classes.alertSuccess}>{alert}</p>
												:
												""
											}
											<Paper className={classes.cardWrapper}>
												<Grid container spacing={3} className={classes.userDetailsDiv}>
													<Grid item md={6} xs={12}>
														<div className={classes.dFlex}>
															<p className={classes.detailTitle}>Name:</p>
															<strong className={classes.detailData}>{`${first_name} ${last_name}`}</strong>
														</div>
														<div className={classes.dFlex}>
															<p className={classes.detailTitle}>Email:</p>
															<strong className={classes.detailData}>{email}</strong>
														</div>
														<div className={classes.dFlex}>
															<p className={classes.detailTitle}>Date Added:</p>
															<strong className={classes.detailData}>{created_on}</strong>
														</div>
														<div className={classes.dFlex}>
															<p className={classes.detailTitle}>Enrolled By:</p>
															<strong className={classes.detailData}>-</strong>
														</div>
													</Grid>
													<Grid item md={6} xs={12}>
														<div className={classes.dFlex}>
															<p className={classes.detailTitle}>Acess Type:</p>
															<strong className={classes.detailData}>{ access_type === "is_superuser" ? "Admin" : "View Only"}</strong>
														</div>
														<div className={classes.dFlex}>
															<p className={classes.detailTitle}>Status:</p>
															<strong className={classes.detailData}>{ is_verified ? "Active" : "For Activation"}</strong>
														</div>
													</Grid>
												</Grid>
											</Paper>
										</Fragment>
										:
										<div style={{ height: "70vh", width: "100%", overflow: "hidden" }}>
											<div style={{ paddingTop: "10%" }}>
												<div style={{
													display: "flex",
													justifyContent: "center"
												}}>
													<img
														style={{width: "25%", height: "25%"}}
														src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
													/>
												</div>
												<div style={{
														display: "flex",
														justifyContent: "center",
														marginTop: 40,
														fontSize: 18,
														fontWeight: 600,
														color: "#b8bcc3",
														padding: "0 3em",
														textAlign: "center"
												}}>
													Sorry! This user does not have an admin role or doesn't even exist.
												</div>
											</div>
										</div>
									}
								</Fragment>
								}
							</div>
						</Fragment>
						:
						<div className={`${classes.cardWrapper} ${classes.formWrapper}`}>
							<FormUser 
								state={this.state} 
                                setPageMode={this.setPageMode}
								setDialogState={this.setDialogState}
								handleAccessTypeChange={this.handleAccessTypeChange}
								handleChange={this.handleChange}
								handleSubmit={this.handleSubmit}
								handleDelete={this.handleDelete}
							/>
						</div>
					}
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(useStyles)(DetailUserAdmin));
