import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import icon from "../../../assets/images/purchasedRoundedIcon.svg";
import { useSelector } from "react-redux";
const IsPurchasedEmpty = props => {
  const useStyles = makeStyles(theme => ({
    paper: {
      backgroundColor: "#fff"
    },
    label1: {
      fontSize: 18,
      fontWeight: 600,
      color: "#2b2d32"
    },
    label2: {
      fontSize: 14,
      color: "#54575f"
    }
  }));

  const classes = useStyles();

  const status = useSelector(state => state.buyerDashboard.purchased_tab);

  function label1() {
    if (status === "To Pay") return "No orders to pay";
    else if (status === "To Ship") return "No orders to be shipped";
    else if (status === "To Receive") return "No orders to received";
    else if (status === "Completed") return "No completed orders";
    else if (status === "Cancelled") return "No cancelled orders";
  }

  function label2() {
    if (status === "To Pay")
      return "Orders that are pending payment will be displayed here.";
    else if (status === "To Ship")
      return "Orders that have been paid and are ready to be shipped  will be displayed here.";
    else if (status === "To Receive")
      return "Orders that have been shipped or are ready for buyer pick-up will be displayed here.";
    else if (status === "Completed")
      return "Orders that you've marked as received will be displayed here.";
    else if (status === "Cancelled")
      return "Orders that have been cancelled will be displayed here";
  }
  return (
    <Paper className={classes.paper}>
      <div style={{ padding: "4em", textAlign: "center" }}>
        <img src={icon} width="80" height="80" alt="cart_icon" />
        <div style={{ marginTop: " 5vh" }}>
          <div className={classes.label1}>{label1()}</div>
          <div className={classes.label2} style={{ marginTop: "2vh" }}>
            {label2()}
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default IsPurchasedEmpty;
