import React from "react";
import OrderTable from "./OrderTable";
import Text from "../../common/text";
import frame from "../../assets/images/Frame.png";
import { Paper } from "@material-ui/core";
import SentroButton from "../common/sentroButton";
import history from "../../store/history";
import Path from "../../Paths";
import { useDispatch, useSelector } from "react-redux";
import OrderActions from "./orderActions";

let OrderBookingSuccess = props => {
  let dispatch = useDispatch();
  let selectedOrder = useSelector(state =>
    state.order ? state.order.selectedOrder : null
  );

  function getOrderDetails() {
    dispatch(OrderActions.setOrder(selectedOrder.id));
  }

  return (
    <div
      style={{
        display: "flex",
        padding: 100,
        flex: 12,
        flexWrap: "wrap"
      }}
    >
      <div
        style={{
          flex: 6,
          marginRight: "3vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            height: "70vh",
            margin: 20,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            width: 500,
            backgroundImage: `url(${frame})`
          }}
        />
      </div>
      <div style={{ flex: 6 }}>
        <Paper style={{ margin: 60 }}>
          <div style={{ padding: 40 }}>
            <Text
              textType="p2_Semibold"
              content="We are now looking for drivers"
            />
            <Text
              textType="label1"
              content={"We will update you once a driver is assigned."}
            />
            <div style={{ marginTop: 32 }}>
              <SentroButton
                fullWidth
                variant="contained"
                onClick={() => getOrderDetails()}
                label="View Booking Status"
              />
              <SentroButton
                fullWidth
                variant="outlined"
                onClick={() => history.push(Path.ORDER_LIST_VIEW)}
                label="Return to Order list"
              />
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default OrderBookingSuccess;
