import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  Switch,
} from "@material-ui/core";
import { ReactComponent as Export } from "../../assets/images/enterprise/export.svg";
import { ReactComponent as Delivery } from "../../assets/images/dashboard/delivery.svg";
import OrderFilters from "./orderFilters";
import OrderTable from "./OrderTable";
import OrderActions from "./orderActions";
import Path from "../../Paths";
import { useHistory } from "react-router-dom";
import { getStore } from "../websiteBuilder/websiteBuilderActions";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40, //28,
    height: 24, //16,
    padding: 0,
    display: "flex",
    marginRight: 5,
  },
  switchBase: {
    padding: 4,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
}))(Switch);

export default function Orders(props) {
  const useStyles = makeStyles((theme) => ({
    borderBottom: {
      borderBottom: "3px solid #eee",
      minWidth: 100,
      width: 100,
    },
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const loading = useSelector((state) => state.order.orders.loading);
  const filters = useSelector((state) => state.order.orders.filters);
  const [value, setValue] = React.useState(0);
  const [showOtherFilters, setShowOtherFilters] = useState(0);

  React.useEffect(() => {
    dispatch(getStore());
  }, []);

  let stores = useSelector((state) => state.webBuilder.stores);
  let storeUUID = stores.length ? stores[0].uuid : null;

  React.useEffect(() => {
    dispatch(OrderActions.getPickupAddress(storeUUID));
  }, [showOtherFilters]);

  React.useEffect(() => {
    dispatch(OrderActions.getOrders(filters));
  }, [filters]);

  React.useEffect(() => {
    if (isMobile) {
      history.push(Path.MOBILE_ORDERS);
    }
  }, [isMobile]);

  const handleChange = (event, newValue) => {
    let status;
    if (newValue === 0) status = "";
    else if (newValue === 1) status = "today";
    else if (newValue === 2) status = "yesterday";
    else if (newValue === 3) status = "1-week";
    setValue(newValue);
    dispatch(
      OrderActions.setOrderFilters({
        ...filters,
        date_ordered_type: status,
        startDate: null,
        endDate: null,
      })
    );
  };
  const tabs = [
    { label: "All" },
    { label: "Today" },
    { label: "Yesterday" },
    { label: "7 Days" },
  ];

  const headerButtons = (
    <>
      {/* <Button
        size="small"
        variant="outlined"
        color="primary"
        startIcon={<Export fill="#f97663" />}
      >
        Export CSV
      </Button> */}
      <Button
        size="small"
        variant="outlined"
        color="primary"
        className="mx-2"
        startIcon={<Delivery fill="#f97663" />}
        onClick={() => history.push(Path.DELIVERY_SETTINGS)}
      >
        Delivery Settings
      </Button>
    </>
  );

  const tabsSection = (
    <div className="my-2" style={{ display: "flex" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((item, key) => (
          <Tab
            classes={{ root: classes.borderBottom }}
            label={item.label}
            {...a11yProps(key)}
          />
        ))}
      </Tabs>
      <div style={{ marginLeft: "auto", marginTop: "10px" }}>
        <AntSwitch
          id="show_other_filters"
          name="show_other_filters"
          checked={showOtherFilters}
          onChange={() => setShowOtherFilters(!showOtherFilters)}
        />
      </div>
    </div>
  );
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <strong style={{ fontSize: 28, color: "#002c42" }}>Orders</strong>
        <div>{headerButtons}</div>
      </div>
      <div>{tabsSection}</div>
      <div>
        <OrderFilters show_other_filters={showOtherFilters} />
      </div>
      <div className="mt-3">
        <OrderTable show_other_filters={showOtherFilters} />
      </div>
    </div>
  );
}
