import React from "react";
import {
  makeStyles,
  Button,
  IconButton,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  InputAdornment,
  OutlinedInput
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import RadioButtons from "./radioButtons";
import CheckboxGroup from "./checkboxGroup";
import { useSelector, useDispatch } from "react-redux";
import {
  getStoreProductCategories,
  getStore,
  setProductFilters
} from "../../websiteBuilderActions";
import { Field, reduxForm } from "redux-form";
import SearchIcon from "@material-ui/icons/Search";
import ReduxTextField from "../../../../../../reduxFields/textField";
const DialogTransition = props => <Slide direction="up" {...props} />;

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: "#fff"
  }
}));

const sortChoices = [
  { name: "Alphabetical", value: "name" },
  { name: "Date added", value: "created_on" },
  { name: "Stock", value: "quantity" },
  { name: "Price", value: "price" }
];

const SortSettings = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const stores = useSelector(state => state.mobileDashboard.stores);
  const product_categories = useSelector(
    state => state.mobileDashboard.product_categories
  );
  const filters = useSelector(state => state.mobileDashboard.products.filters);
  const [timeoutId, setTimeoutId] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [categoryFilters, setCategoryFilters] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [sortBy, setSortBy] = React.useState({
    name: "Date added",
    value: "created_on"
  });

  const fetchCategories = () => {
    if (stores.length && props.open) {
      dispatch(getStoreProductCategories(stores[0].uuid));
    }
  };
  const handleApplyFilter = () => {
    dispatch(
      setProductFilters({
        ...filters,
        search: search,
        category: categoryFilters,
        sortBy: sortBy.value,
        page: 1
      })
    );
    props.setOpen(false);
  };

  React.useEffect(() => {
    setSearch(filters.search);
  }, [filters.search]);

  React.useEffect(() => {
    fetchCategories();
  }, [props.open, stores]);

  React.useEffect(() => {
    setCategories(product_categories.categories);
  }, [product_categories]);

  const searchBar = (
    <OutlinedInput
      className={classes.input}
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      value={search}
      margin="dense"
      placeholder="Type here to search"
      onChange={e => setSearch(e.target.value)}
    />
  );

  const categoryFilter = (
    <div
      className="my-4"
      style={{
        borderBottom: "1px solid #d1d5dd",
        overflow: "scroll",
        height: "48vh"
      }}
    >
      <div style={{ color: "#54575f" }}>Filter by Category</div>
      <div className="my-2">
        <CheckboxGroup
          loading={product_categories.loading}
          choices={categories}
          error={product_categories.error}
          value={categoryFilters}
          fetchCategories={fetchCategories}
          onChange={filters => setCategoryFilters(filters)}
        />
      </div>
    </div>
  );

  const sortFilter = (
    <div className="my-4">
      <div style={{ color: "#54575f" }}>Sort</div>
      <div className="my-2">
        <RadioButtons
          choices={sortChoices}
          value={sortBy}
          onChange={val => setSortBy(val)}
          color={"#f97663"}
        />
      </div>
    </div>
  );
  return (
    <Dialog
      fullScreen
      open={props.open}
      TransitionComponent={DialogTransition}
      className={classes.root}
    >
      <div>
        <div className="px-4 py-2 d-flex justify-content-between align-items-center">
          <strong style={{ color: "#2b2d32", fontSize: 20 }}>
            Sort and filter
          </strong>
          <IconButton onClick={() => props.setOpen(false)}>
            <Close />
          </IconButton>
        </div>
        <div className="p-4">
          <div>{searchBar}</div>
          <div>{categoryFilter}</div>
          <div>{sortFilter}</div>
        </div>
        <div
          className="px-4 py-2"
          style={{ position: "absolute", left: 0, right: 0, bottom: 20 }}
        >
          <Button
            fullWidth
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleApplyFilter}
          >
            Apply filter
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SortSettings;
