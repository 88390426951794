import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  AppBar,
  Tabs,
  Box,
  Typography,
  TextField,
  InputAdornment
} from "@material-ui/core";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import { useLocation } from "react-router-dom";
import Footer from "../../../../homepage/components/footer";
import SearchIcon from "@material-ui/icons/Search";
import FAQ from "./dataSource";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  banner: {
    backgroundColor: "#002c42",
    width: "100%",
    textAlign: "center",
    padding: "12vh 5vw 4vh"
  },
  header: {
    fontSize: 14,
    color: "#54575f",
    fontWeight: 600
  },
  content: {
    fontSize: 14,
    color: "#54575f",
    marginTop: "2vh"
  },
  appBar: {
    zIndex: 1,
    position: "sticky",
    top: "4em",
    boxShadow: "none"
  },
  search: {
    height: 50,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "bold",
    width: "100%",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderBottom: "1px solid #54575f"
  }
}));
const MobileFAQ = props => {
  var Highlight = require("react-highlighter");
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [search, setSearch] = React.useState("");
  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const items = FAQ.filter(data => {
    if (search === "") {
      return data;
    } else if (
      data.question.toLowerCase().includes(search.toLowerCase()) ||
      data.answerString.toLowerCase().includes(search.toLowerCase())
    ) {
      return data;
    }
  }).map((data, key) => {
    return (
      <TabPanel
        value={search === "" ? value : null}
        index={search === "" ? data.key : null}
      >
        <div className={classes.header}>
          <Highlight
            matchStyle={{
              backgroundColor: "#ffd54f",
              fontWeight: 600,
              color: "#54575f"
            }}
            search={search.toLowerCase()}
          >
            {data.question}
          </Highlight>
        </div>
        <div className={classes.content}>
          <Highlight
            matchStyle={{
              backgroundColor: "#ffd54f",
              fontWeight: "normal",
              color: "#54575f"
            }}
            search={search.toLowerCase()}
          >
            {data.answer}
          </Highlight>
        </div>
      </TabPanel>
    );
  });
  return (
    <div>
      <div className={classes.banner}>
        <div style={{ fontSize: 24, color: "#fff", fontWeight: "bold" }}>
          Hi, how can we help you?
        </div>
        <div style={{ marginBottom: "1vh" }}>
          <TextField
            onChange={e => handleSearch(e)}
            color="secondary"
            margin="dense"
            style={{ backgroundColor: "#fff", borderRadius: 5 }}
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "#54575f" }} />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div style={{ fontSize: 12, color: "#fff", fontWeight: 500 }}>
          Here are some guides to help you learn more about Sentro and its
          features
        </div>
      </div>
      <div className={classes.root}>
        <AppBar className={classes.appBar} color="default">
          {search !== "" ? (
            <div className={classes.search}>Search results</div>
          ) : (
            <Tabs
              style={{ backgroundColor: "#fff" }}
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={search === "" ? "on" : "off"}
              aria-label="scrollable auto tabs example"
            >
              <Tab label="About Sentro" {...a11yProps(0)} />
              <Tab label="Joining Sentro" {...a11yProps(1)} />
              <Tab label="Who can use Sentro?" {...a11yProps(2)} />
              <Tab label="Inside Sentro" {...a11yProps(3)} />
              <Tab label="About Sentro Online Shop" {...a11yProps(4)} />
              <Tab
                label="Purchase and Payments in Sentro Online Shop"
                {...a11yProps(5)}
              />
              <Tab label="Shipping and Delivery" {...a11yProps(6)} />
              <Tab label="Apps and Services Marketplace" {...a11yProps(7)} />
              <Tab label="Miscellaneous" {...a11yProps(8)} />
              <Tab label="Facebook Messenger Integration" {...a11yProps(9)} />
              <Tab label="Announcement Page" {...a11yProps(10)} />
            </Tabs>
          )}
        </AppBar>
        {items ? (
          items.length === 0 ? (
            <div style={{ padding: "20px 20px 30vh" }}>No results found...</div>
          ) : (
            items
          )
        ) : (
          items
        )}
      </div>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <Footer />
      </div>
    </div>
  );
};

export default MobileFAQ;
