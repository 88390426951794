import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Slide,
  Dialog,
  Link,
  Button,
  IconButton
} from "@material-ui/core";
import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import BpRadio from "../radioButtons";
import icon from "../../../../assets/images/delivery.svg";
import { Close } from "@material-ui/icons";
import Path from "../../../../Paths";
import { useHistory } from "react-router-dom";
import { getBuxStatus } from "../../../payments/action";
import Actions from "../../redux/actions";
import CourierServiceModal from "../courierServiceModal";
import { is_courier_serviceable } from "../../../common/template/components/cart/isServiceable";
import { showAlert } from "../../../common/commonActions";
import ActivateBuxWalletModal from "../activateBuxWalletModal";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NinjavanModal = props => {
  const history = useHistory();
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="xs"
      TransitionComponent={Transition}
      onClose={() => props.setOpen(false)}
    >
      <div className="d-flex justify-content-end">
        <IconButton onClick={() => props.setOpen(false)}>
          <Close color="secondary" />
        </IconButton>
      </div>
      <div className="px-3 px-md-5 pb-5">
        <div className="d-flex justify-content-center mb-3">
          <img src={icon} alt="icon" width="80" height="80" />
        </div>
        <div className="bg-white text-center">
          <strong style={{ fontSize: 18, color: "#54575f" }}>
            Add product weight
          </strong>
          <p
            className="mt-3"
            style={{ fontSize: 13, fontWeight: 300, color: "#54575f" }}
          >
            Make sure all your products have corresponding package weight in
            order to compute for the right delivery fee. View NinjaVan's{" "}
            <Link
              color="primary"
              onClick={() =>
                window.open(
                  `https://ph-packs.ninjavan.co/pages/packaging-guidelines`
                )
              }
            >
              shipping rates
            </Link>
            .
          </p>
          <div className="d-flex mt-4">
            <Button
              color="primary"
              variant="outlined"
              className="w-50 mr-2"
              onClick={props.disableNinjaVan}
            >
              Disable NinjaVan
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="w-50 mr-2"
              disableElevation
              onClick={() => history.push(Path.PRODUCTS)}
            >
              Go to Products
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
const AntSwitch = withStyles(theme => ({
  root: {
    width: 40, //28,
    height: 24, //16,
    padding: 0,
    display: "flex",
    marginRight: 5
  },
  switchBase: {
    padding: 4,
    color: "white",
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500]
  },
  checked: {}
}))(Switch);

export default function Form(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      borderTop: "1px solid #d1d5dd"
    },
    label: {
      fontSize: 14,
      color: "#54575f"
    },
    label2: {
      fontSize: 14,
      color: "rgba(43, 45, 50, 0.8)"
    },
    note: {
      background: "#EEEEEE",
      borderRadius: 2,
      fontSize: 12,
      padding: "1em 1.5em",
      marginTop: "1em"
    }
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const deliverySettings = useSelector(state => state.deliverySettings);
  const bux_subwallet = useSelector(
    state => state.deliverySettings.bux_subwallet
  );
  const [open, setOpen] = React.useState(false);
  const [openCourierService, setOpenCourierService] = React.useState(false);
  const [openUpgradeBux, setOpenUpgradeBux] = React.useState(false);
  const [bux, setBux] = React.useState("");
  const [ninjavan, setNinjavan] = React.useState({
    enabled: false,
    same_day_delivery: false,
    standard_delivery: false,
    cash_on_delivery: ""
  });

  const onChange = async (field, value) => {
    if (props.pickup_addresses.length) {
      let address = {
        barangay: props.pickup_addresses[0].brgy,
        city: props.pickup_addresses[0].city,
        province: props.pickup_addresses[0].province,
        address_type: "pickup"
      };
      if (bux.kyc_level >= 1 && bux_subwallet) {
        if (value === true && field === "enabled") {
          if ((await is_courier_serviceable(address)) === true) {
            setOpen(value);
            setNinjavan(state => ({
              ...state,
              [field]: value
            }));
            dispatch(
              Actions.saveCourier("ninjavan", {
                [field]:
                  field === "cash_on_delivery" ? JSON.parse(value) : value,
                id:
                  deliverySettings.couriers.ninjavan.length &&
                  deliverySettings.couriers.ninjavan[0].id
              })
            );
          } else {
            setOpenCourierService(true);
          }
        } else {
          setNinjavan(state => ({
            ...state,
            [field]: value
          }));
          dispatch(
            Actions.saveCourier("ninjavan", {
              [field]: field === "cash_on_delivery" ? JSON.parse(value) : value,
              id:
                deliverySettings.couriers.ninjavan.length &&
                deliverySettings.couriers.ninjavan[0].id
            })
          );
        }
      } else {
        setOpenUpgradeBux(true);
      }
    } else {
      dispatch(
        showAlert({
          type: "warning",
          message: "Please set a pick-up address before enabling this feature."
        })
      );
    }
  };

  const disableNinjaVan = () => {
    setOpen(false);
    setNinjavan({ ...ninjavan, enabled: false });
    dispatch(
      Actions.saveCourier("ninjavan", {
        enabled: false,
        id:
          deliverySettings.couriers.ninjavan.length &&
          deliverySettings.couriers.ninjavan[0].id
      })
    );
  };

  React.useEffect(() => {
    async function fetchBuxwallet() {
      let res = await dispatch(getBuxStatus(user.id));
      try {
        if (res.status === 200) {
          if (res.data.kyc_level >= 1) {
            setBux(res.data);
            res = await dispatch(Actions.getBuxSubwallet());
            if (!res) {
              dispatch(Actions.createBuxSubwallet());
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchBuxwallet();
  }, []);

  React.useEffect(() => {
    if (deliverySettings.couriers.ninjavan.length) {
      let params = deliverySettings.couriers.ninjavan[0];
      setNinjavan({
        ...deliverySettings.couriers.ninjavan[0],
        cash_on_delivery: params.cash_on_delivery.toString()
      });
    }
  }, [deliverySettings.couriers.ninjavan]);

  React.useEffect(() => {
    async function fetchBuxwallet() {
      let res = await dispatch(getBuxStatus(user.id));
      try {
        if (res.status === 200) {
          setBux(res.data);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchBuxwallet();
  }, []);

  React.useEffect(() => {
    if (deliverySettings.couriers.ninjavan.length) {
      setNinjavan(deliverySettings.couriers.ninjavan[0]);
    }
  }, [deliverySettings.couriers.ninjavan]);

  return (
    <div className={`${classes.root} p-3 p-md-5`}>
      <div className="d-flex align-items-center justify-content-between">
        <Typography color="primary">
          <strong>NinjaVan</strong>
        </Typography>
        <FormControlLabel
          id="ninjavan_toggle"
          className="m-0"
          control={
            <AntSwitch
              checked={ninjavan.enabled}
              // onChange={e => setOpenCourierService(true)}
              onChange={e => {
                onChange("enabled", e.target.checked);
              }}
            />
          }
        />
      </div>
      <div className={`d-block my-3 my-md-4 ${classes.label2}`}>
        Enable <strong>same-day delivery</strong> within Metro Manila and{" "}
        <strong>standard delivery</strong> nationwide.
      </div>
      <div
        style={{
          opacity: bux.kyc_level >= 1 && bux_subwallet ? 1 : 0.5
        }}
      >
        <div className="mt-4">
          <div style={{ fontWeight: 500 }} className={classes.label}>
            Delivery type
          </div>
          <div className="mt-2">
            {/* <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="same_day"
                  color="primary"
                  disabled={!ninjavan.enabled}
                  checked={ninjavan.same_day_delivery}
                  onChange={e =>
                    onChange(
                      "same_day_delivery",
                      ninjavan.same_day_delivery ? false : true
                    )
                  }
                />
              }
              label={
                <div className={classes.label2}>
                  Same-day Delivery (for Metro Manila)
                </div>
              }
            />
          </FormGroup> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="standard"
                    color="primary"
                    disabled={!ninjavan.enabled}
                    checked={ninjavan.standard_delivery}
                    onChange={e =>
                      onChange(
                        "standard_delivery",
                        ninjavan.standard_delivery ? false : true
                      )
                    }
                  />
                }
                label={
                  <div className={classes.label2}>
                    Standard Delivery (nationwide)
                  </div>
                }
              />
            </FormGroup>
          </div>
        </div>
        <div className="mt-2">
          <div style={{ fontWeight: 500 }} className={classes.label}>
            Accept Cash On Delivery (COD)?
          </div>
          {bux.kyc_level >= 1 && bux_subwallet ? (
            <div className="mt-2">
              <RadioGroup
                className="flex-row"
                value={ninjavan.cash_on_delivery.toString()}
                onChange={e => onChange("cash_on_delivery", e.target.value)}
              >
                <FormControlLabel
                  value="true"
                  control={
                    <BpRadio
                      color="primary"
                      disabled={
                        !ninjavan.enabled ||
                        (!ninjavan.same_day_delivery &&
                          !ninjavan.standard_delivery)
                      }
                    />
                  }
                  label={<div className={classes.label2}>Yes</div>}
                />
                <FormControlLabel
                  className="ml-3"
                  value="false"
                  control={
                    <BpRadio
                      color="primary"
                      disabled={
                        !ninjavan.enabled ||
                        (!ninjavan.same_day_delivery &&
                          !ninjavan.standard_delivery)
                      }
                    />
                  }
                  label={<div className={classes.label2}>No</div>}
                />
              </RadioGroup>
            </div>
          ) : (
              <p className={`${classes.label2} my-3`}>
                Activate your <strong>BUxME Payout wallet</strong> to accept COD
              payments.{" "}
                <Link
                  color="primary"
                  className="font-weight-bold"
                  onClick={() => history.push(Path.PAYMENTS)}
                >
                  Learn more.
              </Link>
              </p>
            )}
        </div>
      </div>
      <div className="col-12 mt-md-4 p-0">
        <p className={classes.note}>
          <strong>Please note:</strong> Merchants are responsible for own
          packaging. <strong>Ninja Packs</strong> can be purchased separately on
          Shopee or the nearest Ministop branch.
        </p>
      </div>
      <NinjavanModal
        open={open}
        setOpen={setOpen}
        disableNinjaVan={disableNinjaVan}
      />
      <CourierServiceModal
        courier="NinjaVan"
        open={openCourierService}
        setOpen={setOpenCourierService}
      />
      <ActivateBuxWalletModal
        open={openUpgradeBux}
        setOpen={setOpenUpgradeBux}
      />
    </div>
  );
}
