import React from "react";
import { useSelector, useDispatch } from "react-redux";
import buxLogo from "../../../../assets/images/buxLogo.svg";
import { Chip, Button, IconButton } from "@material-ui/core";
import Utils from "../../../common/utils";
import { Launch } from "@material-ui/icons";
import { goToCompleteProfile } from "../../../payments/action";
import RedirectToBux from "./redirectToBux";
import SubscriptionActions from "../../redux/actions";
import { useHistory } from "react-router-dom";
import apiConfig from "../../../../common/apiConfig";
export default function BuxWallet(props) {
  const { subscription } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const loading = useSelector(state => state.payments.loading);
  const subscription_loading = useSelector(state => state.subscription.loading);
  const kyc = useSelector(state => state.payments.buxStatus);
  const bux = useSelector(state => state.payments.buxParams);
  const balance = bux ? bux.balance : "₱ 0.00";
  const cashInLimit = bux ? bux.cashin_limits : "";
  const cashIn_maxValue = cashInLimit ? cashInLimit[1] : 0;
  const [open, setOpen] = React.useState(false);

  const handlePayment = async () => {
    let res = await dispatch(
      SubscriptionActions.subscriptionPayment({
        months: subscription === "2016" ? 3 : 1
      })
    );
    try {
      if (res.status === 200) {
        setOpen(false);
        window.location.href = `${res.data.payment_link}/?redirect_url=${apiConfig.localHost}subscription/success`;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const buxSegments = kyc => {
    if (cashIn_maxValue === 100000) {
      return "BUxME";
    } else if (cashIn_maxValue === 500000) {
      return "BUxGIG";
    } else if (cashIn_maxValue === 0) {
      return "BUxSME";
    } else {
      return "BUxME";
    }
  };

  return (
    <div
      className="bg-white rounded my-3"
      style={{ border: "1px solid #d1d5dd" }}
    >
      <div
        style={{ backgroundColor: "#2a3d85" }}
        className="p-md-4 p-3 rounded-top d-flex"
      >
        <img src={buxLogo} alt="bux" width="50" height="50" />
        <div className="w-100 pl-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="text-white" style={{ fontSize: 14 }}>
              BUx wallet
            </div>
            <Chip
              size="small"
              label={buxSegments(kyc)}
              style={{ backgroundColor: "#e5f2fd", color: "#2a3d85" }}
            />
          </div>
          <div
            className="font-weight-light text-white pt-4"
            style={{ fontSize: 12 }}
          >
            Balance
          </div>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <strong style={{ fontSize: 28 }} className="text-white">
                {loading ? (
                  <div
                    id="loading"
                    className="spinner-border mx-auto"
                    role="status"
                    style={{
                      color: "white",
                      fontSize: 12,
                      height: 25,
                      width: 25
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  `₱ ${Utils.commafy(balance)}`
                )}
              </strong>
            </div>
            <div
              className="text-white font-weight-light d-md-flex d-none align-items-center"
              style={{ fontSize: 14, cursor: "pointer" }}
              onClick={() => dispatch(goToCompleteProfile(user.id, kyc))}
            >
              Go to BUx
              <Launch
                style={{ color: "white", fontSize: 23 }}
                className="pl-2"
              />
            </div>
            <IconButton
              className="d-md-none d-flex"
              onClick={() => dispatch(goToCompleteProfile(user.id, kyc))}
            >
              <Launch style={{ color: "white", fontSize: 23 }} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="bg-white px-md-4 px-3 py-4">
        <Button
          id="btn_pay"
          variant="contained"
          color="primary"
          size="small"
          disableElevation
          fullWidth
          onClick={() => setOpen(true)}
        >
          Pay now with BUx wallet
        </Button>
      </div>
      <RedirectToBux
        open={open}
        setOpen={setOpen}
        handleContinue={handlePayment}
        loading={subscription_loading}
      />
    </div>
  );
}
