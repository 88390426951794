import { signOff } from "../auth/authActions";
import { showAlert } from "../common/commonActions";
import {
  buxStatus,
  getStore,
  getPayments,
  addPayment,
  updatePayment,
  deletePayment,
  goToBux,
  getCODWalletServices
} from "./services";
import apiConfig from "../../common/apiConfig";
import Services from "../delivery_settings/redux/services";

export const paymentActionTypes = {
  LOADING_ON: "LOADING_ON",
  LOADING_OFF: "LOADING_OFF",
  GET_BUX_STATUS: "GET_BUX_STATUS",
  GET_BUX_PARAMS: "GET_BUX_PARAMS",
  GET_PAYMENT_METHODS: "GET_PAYMENT_METHODS",
  GET_STORE: "GET_STORE",
  ADD_PAYMENT: "ADD_PAYMENT",
  GET_COD_WALLET: "GET_COD_WALLET"
};

export function getBuxStatus(value) {
  return async dispatch => {
    try {
      dispatch({ type: paymentActionTypes.LOADING_ON });
      let response = await buxStatus(value);
      let access = response ? response.data : "";
      let store = await getStore();
      let store_uuid = store ? (store[0] ? store[0].uuid : "") : "";
      let payments = await getPayments(store_uuid);
      localStorage.setItem("STORE_UUID", store_uuid);
      dispatch({
        type: paymentActionTypes.GET_BUX_STATUS,
        payload: access.kyc_level
      });
      dispatch({
        type: paymentActionTypes.GET_BUX_PARAMS,
        payload: response.data
      });
      dispatch({
        type: paymentActionTypes.GET_STORE,
        payload: store
      });
      dispatch({
        type: paymentActionTypes.GET_PAYMENT_METHODS,
        payload: payments.data
      });
      dispatch({ type: paymentActionTypes.LOADING_OFF });
      console.log(response);
      return response;
    } catch (error) {
      dispatch({ type: paymentActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      }
    }
  };
}

export function addPaymentMethod(value) {
  return async dispatch => {
    try {
      dispatch({ type: paymentActionTypes.LOADING_ON });
      let response = await addPayment(value);
      let payments = await getPayments(value.store);
      dispatch({
        type: paymentActionTypes.GET_PAYMENT_METHODS,
        payload: payments.data
      });
      dispatch({ type: paymentActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: paymentActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log(error);
      }
    }
  };
}

export function editPaymentMethod(value) {
  console.log(value);
  return async dispatch => {
    try {
      dispatch({ type: paymentActionTypes.LOADING_ON });
      let response = await updatePayment(value);
      let payments = await getPayments(value.store);
      dispatch({
        type: paymentActionTypes.GET_PAYMENT_METHODS,
        payload: payments.data
      });
      dispatch({ type: paymentActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: paymentActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log(error);
      }
    }
  };
}

export function removePayment(value) {
  return async dispatch => {
    try {
      dispatch({
        type: paymentActionTypes.LOADING_ON
      });
      let response = await deletePayment(value);
      let payments = await getPayments(value.store);
      dispatch({
        type: paymentActionTypes.GET_PAYMENT_METHODS,
        payload: payments.data
      });
      dispatch({ type: paymentActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: paymentActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log(error);
      }
    }
  };
}

export function goToCompleteProfile(value, status) {
  return async dispatch => {
    try {
      let response = await goToBux(value);
      let access = response.data ? response.data.access : null;
      if (access) {
        if (parseInt(status) != 0) {
          window.open(`${apiConfig.buxURL}/sentro/login?access=${access}`);
        } else {
          window.open(`${apiConfig.buxURL}/sentro/login?access=${access}`);
        }
      }
    } catch (error) {
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log(error);
      }
    }
  };
}

export function getCODWallet() {
  return async dispatch => {
    try {
      dispatch({ type: paymentActionTypes.LOADING_ON });
      let response = await getCODWalletServices();
      dispatch({
        type: paymentActionTypes.GET_COD_WALLET,
        payload: response.data
      });
      return response;
    } catch (error) {
      console.log(error);
      dispatch({ type: paymentActionTypes.LOADING_OFF });
    }
  };
}

export function createCODWallet() {
  return async dispatch => {
    try {
      dispatch({ type: paymentActionTypes.LOADING_ON });
      let response = await Services.createBuxSubwallet();
      if (response.status === 200) {
        dispatch({
          type: paymentActionTypes.GET_COD_WALLET,
          payload: response.data
        });
      }
      dispatch(
        showAlert({
          type: "success",
          message: "You have successfully activated your COD Wallet"
        })
      );
      return response;
    } catch (error) {
      console.log(error);
      dispatch({ type: paymentActionTypes.LOADING_OFF });
    }
  };
}

// REDUX FORMS -------------------------------
export const ReduxInitializeFormActionTypes = {
  ACCOUNT_DETAILS_FORM_DATA: "ACCOUNT_DETAILS_FORM_DATA"
};

export class ReduxInitializeFormActions {
  static initializeAccountFormData(data) {
    return {
      type: ReduxInitializeFormActionTypes.ACCOUNT_DETAILS_FORM_DATA,
      payload: data
    };
  }
}
