import React from "react";
import {
  Paper,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import SentroButton from "../../sentroButton";
import products from "../../../../assets/images/products.png";
import { useSelector, useDispatch } from "react-redux";
import {
  addProduct,
  getStoreProducts,
  editProduct,
  removeProduct,
  closePublishDialog,
  getProducts
} from "./webBuilder/websiteBuilderActions";
import SentroLoading from "../../loading";
import MobileAddProducts from "./webBuilder/forms/MobileAddProducts";
import { isMobile } from "react-device-detect";
import history from "../../../../store/history";
import Path from "../../../../Paths";
import MobileProductList from "./webBuilder/forms/MobileProductList";
import MobileEditProducts from "./webBuilder/forms/MobileEditProducts";
import Skeleton from "@material-ui/lab/Skeleton";
import AlertDialogBox from "./webBuilder/common/AlertDialogBox";
import MobileCreateShop from "./webBuilder/forms/MobileCreateShop";
import CreateShopSuccess from "./webBuilder/common/createShopSuccess";
import { makeStyles, useTheme } from "@material-ui/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import "../../antdesign.css";
import CustomizeShopStyleForm from "./webBuilder/forms/customizeShopStyleForm";
import {
  useProductSelectionAxiosData,
  useProductSelectionsToDelete,
  getAllNewlyCreatedVariantIds
} from "../../../websiteBuilder/desktop/forms/variantChoicesForm";
import { saveProductSelections } from "../../../websiteBuilder/websiteBuilderActions";
import MaxProductsModal from "../../../websiteBuilder/desktop/components/products/maxProductsModal";

let CreateProductCard = props => {
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 2em",
        marginTop: "8vh"
      },
      [theme.breakpoints.down("xs")]: {
        padding: 20,
        marginTop: "10vh"
      }
    },
    cardPadding: {
      marginTop: "14vh",
      [theme.breakpoints.down("sm")]: {
        padding: "0 2em"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0 20px 20px"
      }
    },
    paper: {
      [theme.breakpoints.down("sm")]: {
        padding: "4em"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "40px 20px 20px"
      }
    },
    img: {
      [theme.breakpoints.down("sm")]: {
        height: 250,
        width: "auto"
      },
      [theme.breakpoints.down("xs")]: {
        height: 180,
        width: "auto"
      }
    },
    label: {
      color: "#54575F",
      justifyContent: "center",
      textAlign: "center",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        marginTop: "2vh"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        marginTop: "3vh"
      }
    }
  }));
  const classes = useStyles();
  const { user, handleAddProduct, values, setValues, language } = props;
  let sites = useSelector(state => state.mobileDashboard.stores);
  let loading = useSelector(state => state.mobileDashboard.loading);
  let data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const [open, setOpen] = React.useState(false);

  function onClose() {
    setOpen(false);
  }

  function handleCreateShop() {
    history.push(Path.SHOP_NEW_CREATE);
  }

  return (
    <div>
      <div className={classes.cardPadding}>
        <Paper className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={products} className={classes.img} />
          </div>
          <div style={{ justifyContent: "center" }}>
            {loading ? (
              <Skeleton variant="text" height={50} />
            ) : (
              <div>
                <div
                  className="mt-3 text-center"
                  style={{ fontSize: 20, fontWeight: 600, color: "#54575f" }}
                >
                  Start adding products
                </div>
                <div className={classes.label}>
                  {sites.length > 0
                    ? language === "English"
                      ? "Your shop is almost ready to open! Upload your products so you can start selling."
                      : "Kaunti nalang at pwede ng magbukas ang online shop mo! I-upload mo na ang mga produkto mo at simulan na ang pagbebenta!"
                    : language === "Filipino"
                    ? "Magkaroon ng sariling online shop sa ilang clicks lang! Sagutin lamang ang ilang mga katanungan at kami na ang bahala sa iba."
                    : "Ready to sell your products? No problem! Let’s create your online shop and you’re good to go!"}
                </div>
              </div>
            )}

            <div style={{ marginTop: "5vh" }}>
              <SentroButton
                id="createShop_button"
                disabled={
                  user ? (user.is_verified == true ? false : true) : true
                }
                disableElevation
                style={{ backgroundColor: "#F97663" }}
                variant="contained"
                fullWidth
                onClick={sites.length > 0 ? handleAddProduct : handleCreateShop}
                label={
                  sites.length > 0
                    ? language === "English"
                      ? "Add Product"
                      : "Magdagdag ng produkto"
                    : language === "Filipino"
                    ? "Gumawa ng online shop"
                    : "Create my online shop"
                }
              />
            </div>
          </div>
        </Paper>
      </div>
      <MobileCreateShop
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        values={values}
        setValues={setValues}
      />
    </div>
  );
};

let DeleteProductModal = props => {
  const { openDelete, setOpenDelete, product, deleteProduct, language } = props;
  return (
    <Dialog fullWidth maxWidth="md" open={openDelete}>
      <div style={{ padding: 20 }}>
        <div style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>
          Delete Product
        </div>
        <div style={{ fontSize: 14, marginBottom: 20 }}>
          {language === "English"
            ? "Are you sure you want to delete"
            : "Sigurado ka ba na gusto mo tanggalin ang"}{" "}
          <span style={{ fontWeight: 800, marginBottom: 20 }}>
            {product.name}
          </span>{" "}
          ?
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SentroButton
            id="close_button"
            style={{ marginTop: 0, padding: 0 }}
            label="Close"
            onClick={() => setOpenDelete(false)}
          />
          <SentroButton
            id="delete_button"
            style={{ marginTop: 0, padding: 0 }}
            label="Delete"
            onClick={() => deleteProduct(product)}
          />
        </div>
      </div>
    </Dialog>
  );
};

let PublishedShopSuccess = props => {
  let dispatch = useDispatch();
  const { storeName, storeLink, handleAddProduct, handleCustomizeShop } = props;
  const successPublish = useSelector(
    state => state.mobileDashboard.successPublishShop
  );
  const useStyles = makeStyles(theme => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content"
    },
    content: { width: "100%", display: "flex", justifyContent: "center" },
    icon: {
      width: "100%",
      textAlign: "center",
      color: "#38b99f",
      fontSize: 100
    },
    name: { fontSize: 16, fontWeight: 600, color: "#002c42" },
    site: { fontSize: 14, color: "#54575f" },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 10
    },
    btn: { height: 40, fontSize: 12, width: 120, flex: 1, padding: "5px 10px" }
  }));
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="md" open={successPublish}>
      <DialogTitle
        style={{ display: "flex", justifyContent: "start", padding: 0 }}
      >
        <IconButton onClick={() => dispatch(closePublishDialog())}>
          <CloseIcon style={{ color: "#f97663" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "1em" }}>
        <div className={classes.form}>
          <div
            style={{
              textAlign: "center",
              fontSize: 14,
              color: "#54575f",
              marginBottom: 10
            }}
          >
            Your shop is now published!
          </div>
          <CheckCircleIcon className={classes.icon} />
          <div style={{ textAlign: "center" }}>
            <div className={classes.name}>{storeName}</div>
            <div className={classes.site}>{storeLink}.sentro.ph</div>

            <div style={{ marginTop: 50, color: "#54575f", fontWeight: 600 }}>
              Do more with your shop!
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <div className={classes.flex}>
              <div style={{ color: "#54575f", fontSize: 12, flex: 2 }}>
                Have more items to sell?
              </div>
              <Button
                onClick={() => {
                  dispatch(closePublishDialog());
                  handleAddProduct();
                }}
                style={{
                  height: 40,
                  fontSize: 12,
                  flex: 1,
                  padding: "5px 10px"
                }}
                variant="outlined"
                color="primary"
              >
                Add Products
              </Button>
            </div>
            <div className={classes.flex}>
              <div style={{ color: "#54575f", fontSize: 12, flex: 2 }}>
                Want to customize your shop?
              </div>
              <Button
                onClick={() => {
                  dispatch(closePublishDialog());
                  handleCustomizeShop();
                }}
                id="style_shop"
                className={classes.btn}
                variant="outlined"
                color="primary"
              >
                Style Shop
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

let ExitShopCreationModal = props => {
  const { openExitShopModal, setOpenExitShopModal, setOpen } = props;
  const useStyles = makeStyles(theme => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content"
    },
    icon: {
      width: "100%",
      textAlign: "center",
      color: "#ee2545",
      fontSize: 100
    },
    subheader: {
      padding: "10px 0",
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42"
    },
    label: { fontSize: 14, color: "#54575f" }
  }));
  const classes = useStyles();
  return (
    <Dialog fullWidth maxWidth={"md"} open={openExitShopModal}>
      <DialogTitle
        style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <IconButton onClick={() => setOpenExitShopModal(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: 20 }}>
        <div className={classes.form}>
          <ErrorIcon className={classes.icon} />
          <div style={{ textAlign: "center" }}>
            <div className={classes.subheader}>
              Are you sure you want to exit the shop creation process?
            </div>
            <div className={classes.label}>
              Someone else might take your shop name eventually :(
            </div>
          </div>
          <div style={{ paddingTop: "5vh" }}>
            <Button
              onClick={() => setOpenExitShopModal(false)}
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
            >
              No, resume to creating my shop
            </Button>
            <Button
              onClick={() => {
                setOpenExitShopModal(false);
                setOpen(false);
              }}
              variant="text"
              color="primary"
              fullWidth
            >
              Yes, exit for now
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

let FeaturedWarningModal = props => {
  const { open, setOpen } = props;
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle
        style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 20px 40px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content"
          }}
        >
          <ErrorIcon
            style={{
              width: "100%",
              textAlign: "center",
              color: "#ee2545",
              fontSize: 100
            }}
          />
          <div
            style={{
              fontSize: 18,
              fontWeight: 600,
              color: "#002c42",
              padding: "10px 0"
            }}
          >
            You can only select a maximum of 4 products to feature on your
            online shop
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
let WebProducts = props => {
  const { language } = props;
  let dispatch = useDispatch();
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let data = useSelector(state => state.mobileDashboard.store);
  let loading = useSelector(state => state.mobileDashboard.loading);
  let currentStoreProducts = useSelector(
    state => state.mobileDashboard.products.products.results
  );
  let product_count = useSelector(
    state => state.mobileDashboard.products.products
  );
  let currentStoreCreated = useSelector(
    state => state.mobileDashboard.storeInfo
  );
  const filters = useSelector(state => state.mobileDashboard.products.filters);
  const subscription = useSelector(state => state.subscription.subscription);
  localStorage.setItem(
    "STORE_UUID",
    currentStoreCreated ? currentStoreCreated.uuid : ""
  );
  const theme = useTheme();
  const desktopView = useMediaQuery(theme.breakpoints.up("md"));
  let storeName = currentStoreCreated ? currentStoreCreated.name : "";
  let storeLink = currentStoreCreated ? currentStoreCreated.site : "";
  const [hide, setHide] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [productCategories, setProductCategories] = React.useState([]);
  const [openExitShopModal, setOpenExitShopModal] = React.useState(false);
  const [openFeaturedModal, setOpenFeaturedModal] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [imageList, setImageList] = React.useState([]);
  const [numOfFeaturedProducts, setNumOfFeaturedProducts] = React.useState([]);
  const [currentProducts, setCurrentProducts] = React.useState([]);
  const [productVariants, setProductVariants] = React.useState([]);
  const [openMaxProduct, setOpenMaxProduct] = React.useState(false);
  const [product, setProduct] = React.useState({
    store: currentStoreCreated ? currentStoreCreated.uuid : "",
    name: "",
    price: "",
    discounted_price: "",
    description: "",
    category: "",
    featured: true,
    is_active: true,
    has_variants: false,
    always_available: false,
    quantity: 5,
    images: "",
    productImages: [],
    photo_primary: "0",
    product_code: ""
  });
  const [newProduct] = React.useState({
    store: currentStoreCreated ? currentStoreCreated.uuid : "",
    name: "",
    price: "",
    discounted_price: "",
    description: "",
    category: "",
    featured: true,
    is_active: true,
    has_variants: false,
    always_available: false,
    quantity: 5,
    images: "",
    productImages: [],
    photo_primary: "0",
    product_code: ""
  });
  let STORE_UUID = localStorage.getItem("STORE_UUID");
  const [values, setValues] = React.useState({
    owner: user.id,
    name: "",
    industry: "",
    template: "Classic",
    description: "",
    slogan: "",
    site: "",
    address: "",
    email: "",
    mobile: "",
    landline: "",
    facebook: "",
    instagram: "",
    shopee: "",
    color: "funBlue",
    font: {},
    logo_image: "",
    cover_image: "",
    aboutus_image: "",
    contactus_image: "",
    payment_deadline: 24,
    is_published: false,
    uuid: STORE_UUID,
    image_settings: {},
    image_crop_area: {},
    fb_page_id: "",
    submenu_open: null,
    preview_open: null
  });

  function handleCustomizeShop() {
    setValues({
      ...values,
      customize_shop_style_form_open: true
    });
  }
  function onClose() {
    let is_published = currentStoreCreated
      ? currentStoreCreated.is_published
      : "";
    if (is_published === false) {
      setOpenExitShopModal(true);
    } else {
      setOpen(false);
    }
  }
  function onCloseEdit() {
    setOpenEdit(false);
  }

  function handleCreateShop() {
    history.push(Path.MOBILE_WEB_BUILDER);
  }
  let product_length = product_count.active_count + product_count.drafts_count;

  function handleAddProduct() {
    if (
      (subscription.subscription_type === "advanced" ||
        subscription.subscription_type === "free_trial") &&
      product_length >= 300
    ) {
      setOpenMaxProduct(true);
    } else if (
      (subscription.free_trial_availed || !subscription.free_trial_availed) &&
      !subscription.subscription_type &&
      product_length >= 50
    ) {
      setOpenMaxProduct(true);
    } else {
      history.push(Path.PRODUCTS_ADD);
    }
  }
  function handleEditProduct(product) {
    imageList.length = 0;
    for (let i in product["gallery"]) {
      imageList.push(product["gallery"][i].image);
    }

    setOpenEdit(true);
    setProduct({
      ...product,
      product_code: ["NaN", "null"].includes(String(product.product_code))
        ? ""
        : product.product_code
    });
  }
  function handleDeleteProduct(product) {
    setOpenDelete(true);
    setProduct(product);
  }

  const productSelectionsData = useProductSelectionAxiosData();
  const productSelectionsToDelete = useProductSelectionsToDelete();
  async function handleSaveEditProduct() {
    const res = await dispatch(
      editProduct(product, productVariants, imageList, filters)
    );
    const data = productSelectionsData.map(i => ({
      ...i,
      product_variant_ids: getAllNewlyCreatedVariantIds(res.data, i)
    }));
    await dispatch(saveProductSelections(data, productSelectionsToDelete));
    dispatch(getStoreProducts());
    setOpenEdit(false);
  }
  function deleteProduct(product) {
    dispatch(removeProduct(product));
    setOpenDelete(false);
  }

  React.useEffect(() => {
    if (currentStoreCreated) {
      dispatch(
        getProducts(currentStoreCreated && currentStoreCreated.uuid, {
          ...filters
        })
      );
    }
  }, [filters]);

  React.useEffect(() => {
    let params = {
      ...filters,
      sortBy: "created_on"
    };
    dispatch(getStoreProducts(params));
  }, []);

  React.useEffect(() => {
    if (currentStoreProducts && currentStoreProducts.length) {
      for (let i in currentStoreProducts) {
        productCategories.push(currentStoreProducts[i].category);
      }
    }
  }, [currentStoreProducts]);

  console.log(product);
  React.useEffect(() => {
    if (currentStoreCreated ? currentStoreCreated.logo_image !== null : "") {
      setHide(true);
    }
  }, [currentStoreCreated]);

  React.useEffect(() => {
    if (currentStoreProducts) {
      setCurrentProducts(currentStoreProducts);
    }
  }, [currentStoreProducts]);

  React.useEffect(() => {
    numOfFeaturedProducts.length = 0;
    if (currentProducts) {
      for (let i in currentProducts) {
        if (currentProducts[i].featured) {
          numOfFeaturedProducts.push(currentProducts[i]);
        }
      }
    }
  }, [currentProducts]);

  React.useEffect(() => {
    if (desktopView) {
      history.push(Path.PRODUCTS);
    }
  }, [desktopView]);

  return (
    <div
      style={{
        overflowY: "hidden",
        height: "93vh",
        backgroundColor: "#fafafa",
        position: "relative"
      }}
    >
      {/* <SentroLoading open={loading} /> */}

      {product_count &&
      (product_count.active_count > 0 ||
        product_count.drafts_count > 0 ||
        product_count.deleted_count > 0) ? (
        <>
          <MobileProductList
            // filtersOnChange={getProductFilters}
            handleEditProduct={value => handleEditProduct(value)}
            handleDeleteProduct={value => handleDeleteProduct(value)}
            handleCustomizeShop={handleCustomizeShop}
            handleAddProduct={handleAddProduct}
            hide={hide}
            setHide={setHide}
            user={user}
            openFeaturedModal={openFeaturedModal}
            setOpenFeaturedModal={setOpenFeaturedModal}
          />
          <CustomizeShopStyleForm
            values={values}
            setValues={setValues}
            storeInfo={currentStoreCreated}
          />
          <MobileEditProducts
            openEdit={openEdit}
            onCloseEdit={onCloseEdit}
            product={product}
            setProduct={setProduct}
            imageList={imageList}
            handleSaveEditProduct={handleSaveEditProduct}
            language={language}
            numOfFeaturedProducts={numOfFeaturedProducts}
            currentProducts={currentProducts}
            setCurrentProducts={setCurrentProducts}
            productVariants={productVariants}
            setProductVariants={setProductVariants}
          />
          <DeleteProductModal
            openDelete={openDelete}
            setOpenDelete={setOpenDelete}
            product={product}
            deleteProduct={deleteProduct}
            language={language}
          />
          <FeaturedWarningModal
            open={openFeaturedModal}
            setOpen={setOpenFeaturedModal}
          />
        </>
      ) : (
        <CreateProductCard
          user={user}
          handleAddProduct={handleAddProduct}
          handleCreateShop={handleCreateShop}
          hide={hide}
          values={values}
          setValues={setValues}
          language={language}
        />
      )}
      <MobileAddProducts
        open={open}
        setOpen={setOpen}
        newProduct={newProduct}
        onClose={onClose}
        product={product}
        setProduct={setProduct}
        storeInfo={currentStoreCreated}
        language={language}
        numOfFeaturedProducts={numOfFeaturedProducts}
        currentProducts={currentProducts}
        setCurrentProducts={setCurrentProducts}
      />
      <CreateShopSuccess handleAddProduct={handleAddProduct} />
      <PublishedShopSuccess
        storeName={storeName}
        storeLink={storeLink}
        handleAddProduct={handleAddProduct}
        handleCustomizeShop={handleCustomizeShop}
      />
      <ExitShopCreationModal
        openExitShopModal={openExitShopModal}
        setOpenExitShopModal={setOpenExitShopModal}
        storeInfo={currentStoreCreated}
        setOpen={setOpen}
      />
      <MaxProductsModal
        open={openMaxProduct}
        setOpen={setOpenMaxProduct}
        product_length={product_length}
      />
    </div>
  );
};

export default WebProducts;
