import React from "react";
import {
  makeStyles, IconButton
} from "@material-ui/core";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Actions from '../StoreActions'
import { useSelector, useDispatch } from "react-redux";
import SortIcon from '@material-ui/icons/Sort';
import { useHistory, useLocation } from "react-router-dom";

export default function CategoriesTab(props){
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  let store = useSelector(state => state.store.store);
  let product_categories = useSelector(state => state.store.product_categories);
  const [tab, setTab] = React.useState(0);
  const [categories, setCategories] = React.useState([]);
  const getFont = (template) => {
    switch(template) {
      case "Classic": 
        return "Georgia";
      case "Contemporary":
        return "Oswald";
      case "Minimalist":
        return "Ubuntu"
      default: 
        return "Georgia"
    }
  }
  const tabsStyles = makeStyles(theme => ({
    root: {
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight: "normal",
    },
    indicator: {
      backgroundColor: props.color || "#f97663"
    },
  }));
  const tabsclasses = tabsStyles();
  const tabStyles = makeStyles(theme => ({
    root: {
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight: "normal",
    },
    selected: {
      color: `${props.color} !important` || "#2B2D33"
    }
  }));
  const tabclasses = tabStyles();

  const sortBy_filter = new URLSearchParams(location.search).get("sortBy");
  const setTabFunc = (index) => {
    setTab(index)
    if (props.onChange) {
      props.onChange(categories[index])
      history.push({
        pathname: location.pathname,
        search: `?category=${encodeURIComponent(
          categories[index] 
        )}&sortBy=${encodeURIComponent(sortBy_filter)}`,
      });
      
    }
  }
  React.useEffect(() => {
    if (store.name) {
      dispatch(Actions.getStoreProductCategories(store.uuid))
    }
  }, [store])
  React.useEffect(() => {
    if (product_categories.categories.length)
    setCategories(["All", ...product_categories.categories])
  }, [product_categories])
  return (
    product_categories.loading ? 
    <div class="text-center my-2">
      <div className="spinner-border spinner-border-sm text-secondary">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    :
    <div className="bg-white">
      <Tabs
        style={{
          fontFamily: `"${getFont(props.template)}" !important`
        }}
        classes={tabsclasses}
        value={tab}
        onChange={(e, val) => setTabFunc(val)}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        aria-label="full width tabs example"
      >
        {
          categories.map((cat, index) => (
            <Tab classes={tabclasses} key={index} label={cat || "Others"} 
            style={{
              fontFamily: `"${getFont(props.template)}" !important`
            }}/>        
          ))
        }
      </Tabs>
    </div>
  )
}


export const SortDiv = (props) => {
    return (
      <div className="d-flex align-items-center px-3 bg-white" style={{boxShadow: "inset 0px 7px 5px -5px rgba(0, 0, 0, 0.25)" }}>
        <p className={`${props.classes.infoText} mb-0`}>
          {props.product_count} item(s) found
        </p>
        <div className="d-flex ml-auto align-items-center">
          <p className={`${props.classes.infoText} mb-0 mr-3`}>
            Sort
          </p>
          <IconButton
            onClick={() => props.setOpen(true)}
            edge="start"
            // color="primary"
          >
            <SortIcon />
          </IconButton>
        </div>
      </div>
    )
  }