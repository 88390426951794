import React from "react";
import useStyles from "./contentStyles";
import { Paper, Link } from "@material-ui/core";
import ViewAnnouncement from "./viewAnnouncement";
import PDFs from "./announcementsPDFs";
const Content = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  function handleSelected(item) {
    setSelected(item);
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>Announcements</div>
      <div style={{ marginTop: "2em" }}>
        {PDFs.map(item => {
          return (
            <Paper className={classes.card}>
              <Link
                className={classes.title}
                onClick={() => handleSelected(item)}
              >
                {item.title}
              </Link>
              <div className={classes.date}>{item.date}</div>
            </Paper>
          );
        })}
      </div>

      <ViewAnnouncement
        handleSelected={open}
        handleClose={handleClose}
        selected={selected}
      />
    </div>
  );
};

export default Content;
