import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import sentroTheme from "./components/common/sentroTheme";
import Path from "./Paths";
import "./App.css";
import Auth from "./components/auth/auth";
import Register from "./components/register/register";
import Verification from "./components/register/registerComponents/verification";
import PrivateLayout from "./components/common/layout/privateLayout";
import history from "./store/history";
import OpenMic from "./components/homepage/openmic";
import VerificationSuccess from "./components/register/registerComponents/verificationSuccess";
import VerificationFailed from "./components/register/registerComponents/verificationFailed";
import FAQ from "./components/faq/faq";
import ForgotPassword from "./components/forgotPassword/forgotPassword";
import InputPassword from "./components/forgotPassword/inputPassword";
import ChangePasswordRequest from "./components/forgotPassword/changePasswordRequest";
import ResetPasswordSuccess from "./components/forgotPassword/forgotPasswordComponents/resetPasswordSuccess";
import LandingPage from "./components/common/template/LandingPage";
import ProductPage from "./components/common/template/ProductPage";
import ViewProduct from "./components/common/template/ViewProduct";
import Cart from "./components/common/template/CartPage";
import PrivateRoutes from "./routes/PrivateRoutes";
import Checkout from "./components/common/template/Checkout";
import ErrorPage from "./components/common/ErrorPage";
import ResetVerification from "./components/forgotPassword/forgotPasswordComponents/resetVerification";
import LinkExpired from "./components/forgotPassword/linkExpired";
import Delivery from "./components/common/template/components/Delivery";
import AdminRoutes from "./routes/AdminRoutes";
import withClearCache from "./ClearCache";

import FBMessenger from "./components/lib/FBMessenger/FBMessenger";
import NoypiOyat from "./components/register/noypiOyat";
import LandingPastry from "./components/register/sentroSaPagasenso/LandingPastry";
import LandingCosmetics from "./components/register/sentroSaPagasenso/LandingCosmetics";
import LandingGeneric from "./components/register/sentroSaPagasenso/LandingGeneric";
import TNC from "./components/termsAndConditions/touLayout";
import PrivacyPolicy from "./components/privacyPolicy/layout";
import Home from "./components/homepage/home";
import AboutusPage from "./components/common/template/AboutusPage";
import SuperLink from "./components/common/superlink";
import EnterpriseRouter from "./components/enterprise/router";
import PaymentOptions from "./components/common/template/components/PaymentOptions";
import DeliveryDetails from "./components/common/template/components/DeliveryDetails";
import Login from "./components/common/template/components/Login";
import Signup from "./components/common/template/components/Signup";
import ShopForgotPassword from "./components/common/template/components/login/forgotPassword";
import QuickOrderRouter from "./components/quickOrder/router";
const MainApp = () => (
  <ThemeProvider theme={sentroTheme}>
    <FBMessenger>
      <Router history={history}>
        <Switch>
          <Redirect exact from="/" to={Path.HOME} component={Home} />
          <Route path={Path.HOME} component={Home} />
          <Route path={Path.OPEN_MIC} component={OpenMic} />
          <Route path={Path.LOGIN} component={Auth} />
          <Route path={Path.SIGN_UP} component={Register} />
          <Route path={Path.TNC} component={TNC} />
          <Route path={Path.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route path={Path.VERIFY_EMAIL} component={Verification} />
          <Route path={Path.VERIFICATION} component={VerificationSuccess} />
          <Route
            path={Path.VERIFICATION_FAILED}
            component={VerificationFailed}
          />

          <Route path={Path.NOYPI_OYAT} component={NoypiOyat} />
          <Route
            path={Path.SENTRO_SA_PAGASENSO_PASTRY}
            component={LandingPastry}
          />
          <Route
            path={Path.SENTRO_SA_PAGASENSO_COSMETICS}
            component={LandingCosmetics}
          />
          <Route
            path={Path.SENTRO_SA_PAGASENSO_GENERIC}
            component={LandingGeneric}
          />

          {/* <Route
          path={Path.VERIFICATION_SUCCESS}
          component={VerificationSuccess}
          /> */}
          <Route path={Path.FAQ} component={FAQ} />
          <Route path={Path.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route path={Path.RESET_VERIFY_EMAIL} component={ResetVerification} />
          <Route path={Path.INPUT_PASSWORD} component={InputPassword} />
          <Route
            path={Path.CHANGE_PASSWORD_REQUEST}
            component={ChangePasswordRequest}
          />
          <Route path={Path.LINK_EXPIRED} component={LinkExpired} />
          <Route
            path={Path.RESET_PASSWORD_SUCCESS}
            component={ResetPasswordSuccess}
          />

          <Route path={"/superlink"} component={SuperLink} />

          {/* <Route component={ErrorPage} /> */}
          <Route path="/not-found" component={ErrorPage} />

          <Route exact path={"/shop/:id"} component={LandingPage} />
          <Route exact path={"/shop/:id/products"} component={ProductPage} />
          <Route exact path={"/shop/:id/cart"} component={Cart} />
          <Route exact path={"/shop/:id/delivery"} component={Delivery} />
          <Route exact path={"/shop/:id/viewproduct"} component={ViewProduct} />
          <Route exact path={"/shop/:id/checkout/"} component={Checkout} />
          <Route exact path={"/shop/:id/about_us"} component={AboutusPage} />
          <Route
            exact
            path={"/shop/:id/delivery_details"}
            component={DeliveryDetails}
          />
          <Route exact path={"/shop/:id/payment"} component={PaymentOptions} />
          <Route exact path={"/shop/:id/login"} component={Login} />
          <Route exact path={"/shop/:id/signup"} component={Signup} />
          <Route
            exact
            path={"/shop/:id/forgot_password"}
            component={ShopForgotPassword}
          />
          {AdminRoutes()}
          <Route path="/enterprise" component={EnterpriseRouter} />
          <Route path="/order" component={QuickOrderRouter} />
          <PrivateLayout>
            <PrivateRoutes />
          </PrivateLayout>
        </Switch>
      </Router>
    </FBMessenger>
  </ThemeProvider>
);

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

export default App;
