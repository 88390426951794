import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  Link,
  useMediaQuery
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import sentroLogo from "../../../../../assets/images/sentro_logo2.png";
import {
  LocationOn,
  PhoneAndroid,
  Phone,
  Email,
  Instagram,
  EmailOutlined
} from "@material-ui/icons";
import history from "../../../../../store/history";
import Blank from "../blank";
import { apiConfig } from "../../../../../common/apiConfig";
import defaultSRC from "../defaultResources";
import defaultColor from "../colors";
import imgPlaceholder from "../../../../../assets/images/banner_placeholder.png";
import { ReactComponent as ShopeeIcon } from "../../../../../assets/images/shopee_logo.svg";
import { ReactComponent as Lazada } from "../../../../../assets/images/lazadaLogo.svg";
import { ReactComponent as KumuLogo } from "../../../../../assets/images/kumu-dark.svg";
import Kumu from "../../../../../assets/images/kumuLogo.png";
import PinchZoomPan from "../../../../lib/image-zoom-pan/PinchZoomPan";
import { useSelector } from "react-redux";
import classicPlaceholder from "../../../../../assets/images/template assets/classicFooter.svg";
import contemporaryPlaceholder from "../../../../../assets/images/template assets/contemporaryFooter.svg";
import minimalistPlaceholder from "../../../../../assets/images/template assets/minimalistFooter.svg";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
function createPreview(file) {
  var blob = new Blob([file], { type: file.type });
  var blobUrl = URL.createObjectURL(blob);

  var xhr = new XMLHttpRequest();
  xhr.responseType = "blob";

  xhr.onload = function() {
    var recoveredBlob = xhr.response;

    var reader = new FileReader();

    reader.onload = function() {
      var blobAsDataUrl = reader.result;
      window.location = blobAsDataUrl;
    };

    reader.readAsDataURL(recoveredBlob);
  };

  return blobUrl;
}
const FooterTemplate = props => {
  const { products, mobileView } = props;
  const isPreview = props.isPreview;
  const storeInfo = props.store;
  let color = props.store && props.store.color;
  let logo = props.storeInfo.logo_image;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const product_categories = useSelector(
    state => state.store.product_categories?.categories
  );
  function setImageSettings(name, value) {
    if (isPreview && props.setImageSettings) {
      props.setImageSettings(name, value);
    }
  }

  function createPreview(file) {
    var blob = new Blob([file], { type: file.type });
    var blobUrl = URL.createObjectURL(blob);

    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function() {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function() {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    return blobUrl;
  }
  var pinchPropsFooter = {
    position: "center",
    initialScale: "auto",
    allowEdit: isPreview ? true : false,
    zoomButtons: isPreview ? true : false,
    maxScale: 15,
    onImageStateChange: image_params => setImageSettings("footer", image_params)
  };

  if (props.store.image_settings) {
    let { footer } = props.store.image_settings;
    if (footer) {
      let { top, left, scale } = footer;
      pinchPropsFooter.position = top && left;
      pinchPropsFooter.initialTop = top;
      pinchPropsFooter.initialLeft = left;
      pinchPropsFooter.initialScale = scale || "auto";
    }
  }
  const location = useLocation();
  const hide_if_mobileview =
    mobileView &&
    (location.pathname.includes(`/cart`) ||
      location.pathname.includes(`/delivery`) ||
      location.pathname.includes(`/delivery_details`) ||
      location.pathname.includes(`/payment`));

  const getStyles = (template, theme) => {
    switch (template) {
      default:
        return "";
      case "Classic":
        return {
          footerDisplay: {
            width: "100%",
            display: "flex",
            height: "35em",
            [theme.breakpoints.down("md")]: {
              height: "40em"
            },
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              flexWrap: "wrap-reverse",
              height: "auto"
            }
          },
          footer: {
            padding: "40px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#303030",
            width: "50%",
            backgroundColor: color.primary.main,
            flexGrow: 1,
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            },
            [theme.breakpoints.down("xs")]: {
              padding: "20px 0"
            }
          },
          footerContent: {
            display: "flex",
            marginTop: 60,
            width: "100%",
            [theme.breakpoints.down("xs")]: {
              display: "block"
            }
          },
          contactDetails: {
            flexGrow: 1,
            width: "60%",
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          },

          footerImage: {
            flexGrow: 1,
            width: "50%",
            // padding: "15vw",
            backgroundImage: props.store.contactus_image
              ? `url(${
                  typeof props.store.contactus_image === "object"
                    ? createPreview(props.store.contactus_image)
                    : props.store.contactus_image
                })`
              : `url(${classicPlaceholder})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: "50vh"
            }
          },
          footerBtn: {
            flexGrow: 1,
            width: "40%",
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              display: "flex",
              marginBottom: 10
            }
          },
          footerBtnStyles: {
            fontWeight: "bold",
            fontSize: "16px",
            display: "block",
            marginBottom: "4vh",
            cursor: "pointer",
            color: color && color.primary.contrastText,
            [theme.breakpoints.down("xs")]: {
              flexGrow: 1,
              textAlign: "center"
            }
          },
          btnSocial: {
            display: "flex",
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              justifyContent: "center"
            }
          },
          footerContainer: {
            padding: "0px 15px",
            [theme.breakpoints.down("xs")]: {
              padding: "30px 0px"
            }
          }
        };
      case "Contemporary":
        return {
          footerDisplay: {
            width: "100%",
            display: "flex",
            height: "35em",
            [theme.breakpoints.down("md")]: {
              height: "40em"
            },
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              flexWrap: "wrap-reverse",
              height: "auto"
            }
          },
          footer: {
            padding: "40px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#303030",
            width: "50%",
            backgroundColor: color.primary.main,
            flexGrow: 1,
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            },
            [theme.breakpoints.down("xs")]: {
              padding: "20px 0"
            }
          },
          footerContent: {
            display: "flex",
            marginTop: 60,
            width: "100%",
            [theme.breakpoints.down("xs")]: {
              display: "block"
            }
          },
          contactDetails: {
            flexGrow: 1,
            width: "60%",
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          },

          footerImage: {
            flexGrow: 1,
            width: "50%",
            // padding: "15vw",
            backgroundImage: props.store.contactus_image
              ? `url(${
                  typeof props.store.contactus_image === "object"
                    ? createPreview(props.store.contactus_image)
                    : props.store.contactus_image
                })`
              : `url(${contemporaryPlaceholder})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: "50vh"
            }
          },
          footerBtn: {
            flexGrow: 1,
            width: "40%",
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              display: "flex",
              marginBottom: 10
            }
          },
          footerBtnStyles: {
            fontWeight: "bold",
            fontSize: "16px",
            display: "block",
            marginBottom: "4vh",
            cursor: "pointer",
            color: color && color.primary.contrastText,
            [theme.breakpoints.down("xs")]: {
              flexGrow: 1,
              textAlign: "center"
            }
          },
          btnSocial: {
            display: "flex",
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              justifyContent: "center"
            }
          },
          footerContainer: {
            padding: "0px 15px",
            [theme.breakpoints.down("xs")]: {
              padding: "30px 0px"
            }
          }
        };
      case "Minimalist":
        return {
          footerDisplay: {
            width: "100%",
            display: "flex",
            height: "35em",
            [theme.breakpoints.down("md")]: {
              height: "40em"
            },
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              flexWrap: "wrap-reverse",
              height: "auto"
            }
          },
          footer: {
            padding: "40px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#303030",
            width: "50%",
            backgroundColor: color.primary.main,
            flexGrow: 1,
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            },
            [theme.breakpoints.down("xs")]: {
              padding: "20px 0"
            }
          },
          footerContent: {
            display: "flex",
            marginTop: 60,
            width: "100%",
            [theme.breakpoints.down("xs")]: {
              display: "block"
            }
          },
          contactDetails: {
            flexGrow: 1,
            width: "60%",
            [theme.breakpoints.down("xs")]: {
              width: "100%"
            }
          },
          footerImage: {
            flexGrow: 1,
            width: "50%",
            // padding: "15vw",
            backgroundImage: props.store.contactus_image
              ? `url(${
                  typeof props.store.contactus_image === "object"
                    ? createPreview(props.store.contactus_image)
                    : props.store.contactus_image
                })`
              : `url(${minimalistPlaceholder})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: "50vh"
            }
          },
          footerBtn: {
            flexGrow: 1,
            width: "40%",
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              display: "flex",
              marginBottom: 10
            }
          },
          footerBtnStyles: {
            fontWeight: "bold",
            fontSize: "16px",
            display: "block",
            marginBottom: "4vh",
            cursor: "pointer",
            color: color && color.primary.contrastText,
            [theme.breakpoints.down("xs")]: {
              flexGrow: 1,
              textAlign: "center"
            }
          },
          btnSocial: {
            display: "flex",
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              justifyContent: "center"
            }
          },
          footerContainer: {
            padding: "0px 15px",
            [theme.breakpoints.down("xs")]: {
              padding: "30px 0px"
            }
          }
        };

      case "ClassicV2":
        return {
          root: {
            backgroundColor: color.secondary.main
          },
          storename: {
            fontSize: 28,
            fontWeight: 500,
            color: color && color.primary.contrastText
          },
          colorPrimary: {
            color: color && color.primary.contrastText
          }
        };
      case "ContemporaryV2":
        return {
          root: {
            backgroundColor: color.secondary.main
          },
          storename: {
            fontSize: 28,
            fontWeight: 500,
            color: color && color.primary.contrastText
          },
          colorPrimary: {
            color: color && color.primary.contrastText
          }
        };
      case "Leftist":
        return {
          root: {
            width: "100%",
            height: 50,
            backgroundColor: color.secondary.main
          }
        };
      case "SariSari":
        return {
          footer: {
            backgroundColor: "#f1f1f1"
          },
          colorPrimary: {
            color: "#54575f"
          },
          storename: {
            fontSize: 24,
            fontWeight: 600,
            textTransform: "capitalize",
            color: "#54575f",
            fontFamily: storeInfo && storeInfo.font.family
          },
          logo: {
            width: 40,
            height: 40,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${
              props.storeInfo.logo_image
                ? typeof logo === "object"
                  ? createPreview(logo)
                  : logo
                : "#f97663"
            })`,
            backgroundColor:
              props.storeInfo && !props.storeInfo.logo_image
                ? color && color.primary.contrastText
                : "inherit",
            cursor: "pointer"
          }
        };
      case "Pastel":
        return {
          footer: {
            backgroundColor: color && color.primary.main
          },
          colorPrimary: {
            color: "#54575f"
          },
          storename: {
            fontSize: 24,
            fontWeight: 600,
            textTransform: "capitalize",
            color: "#54575f",
            fontFamily: storeInfo && storeInfo.font.family
          },
          logo: {
            width: 40,
            height: 40,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${
              props.storeInfo.logo_image
                ? typeof logo === "object"
                  ? createPreview(logo)
                  : logo
                : "#f97663"
            })`,
            backgroundColor:
              props.storeInfo && !props.storeInfo.logo_image
                ? color && color.primary.contrastText
                : "inherit",
            cursor: "pointer"
          }
        };
    }
  };

  let templateStyles = makeStyles(theme =>
    getStyles(props.store ? props.store.template : "Blank", theme)
  );

  const classes = templateStyles();
  function setFooterTemplate() {
    switch (props.store ? props.store.template : "Blank") {
      default:
        return "";
      case "Blank":
        return <Blank />;
      case "Classic":
        return (
          <div className={classes.footerWrapper}>
            <div className={classes.footerDisplay}>
              <Box className={classes.footer}>
                <Container>
                  <Box className={classes.footerContainer}>
                    <Typography
                      variant="h4"
                      style={{
                        color: color && color.primary.contrastText,
                        fontFamily: storeInfo && storeInfo.font.family,
                        width: "100%",
                        wordWrap: "break-word"
                      }}
                    >
                      {props.storeInfo
                        ? props.storeInfo.name
                          ? props.storeInfo.name
                          : "Your Shop's Name"
                        : "Your Shop's Name"}
                    </Typography>
                    <div className={classes.footerContent}>
                      <div className={classes.footerBtn}>
                        {props.navBtn.slice(0, 4).map((item, label) => {
                          return (
                            <Link
                              id="footer_navigation"
                              onClick={
                                props.isPreview
                                  ? null
                                  : () => {
                                      history.push(item.path);
                                      window.scrollTo(0, 0);
                                    }
                              }
                              variant="text"
                              className={classes.footerBtnStyles}
                              style={{
                                textDecoration: "none"
                              }}
                            >
                              {item.label}
                            </Link>
                          );
                        })}
                      </div>
                      <div className={classes.contactDetails}>
                        {props.store ? (
                          props.store.address ? (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: "flex"
                              }}
                            >
                              <LocationOn style={{ marginRight: "2vw" }} />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "95%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.store.address}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <LocationOn
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "95%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.isPreview ? "Business Address" : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          ""
                        )}

                        {props.store ? (
                          props.store.mobile ? (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: "flex"
                              }}
                            >
                              <PhoneAndroid style={{ marginRight: "2vw" }} />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "100%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.store.mobile.charAt(0) != 0
                                  ? "+63" + props.store.mobile
                                  : "+63" + props.store.mobile.substr(1)}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <PhoneAndroid
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "100%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.isPreview
                                  ? "Business Mobile Number"
                                  : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          " "
                        )}
                        {props.store ? (
                          props.store.landline ? (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display:
                                  props.store.landline === "null"
                                    ? "none"
                                    : "flex"
                              }}
                            >
                              <Phone style={{ marginRight: "2vw" }} />

                              <div
                                style={{
                                  marginLeft: 5,
                                  width: 300
                                }}
                              >
                                {props.store.landline}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <Phone
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />

                              <div
                                style={{
                                  marginLeft: 5,
                                  width: 300
                                }}
                              >
                                {props.isPreview ? "Business Landline" : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          ""
                        )}

                        {props.store ? (
                          props.store.email ? (
                            <Box
                              style={{
                                fontSize: 16,
                                width: "95%",
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display:
                                  props.store.email === "null" ? "none" : "flex"
                              }}
                            >
                              <Email style={{ marginRight: "2vw" }} />{" "}
                              <div
                                style={{
                                  marginLeft: 5,

                                  width: "85%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.store.email}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                width: "95%",
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <Email
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />{" "}
                              <div
                                style={{
                                  marginLeft: 5,

                                  width: "85%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.isPreview ? "Business Email" : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </Box>
                  <Box className={classes.btnSocial}>
                    {props.store ? (
                      props.store.facebook ? (
                        <IconButton
                          id="facebook_button"
                          style={{
                            color: color && color.primary.contrastText,
                            display:
                              props.store.facebook === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://facebook.com/${props.store.facebook}`
                          }
                        >
                          <FacebookIcon style={{ fontSize: "35px" }} />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}

                    {props.store ? (
                      props.store.instagram ? (
                        <IconButton
                          id="instagram_button"
                          style={{
                            color: color && color.primary.contrastText,
                            display:
                              props.store.instagram === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://instagram.com/${props.store.instagram}`
                          }
                        >
                          <InstagramIcon style={{ fontSize: "35px" }} />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}
                    {props.store ? (
                      props.store.shopee ? (
                        <IconButton
                          id="shopee_button"
                          style={{
                            color: color && color.primary.contrastText,
                            paddingTop: "1vh",
                            display: props.store.shopee === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://shopee.com/${props.store.shopee}`
                          }
                        >
                          <ShopeeIcon
                            fill={color && color.primary.contrastText}
                            style={{
                              width: 35,
                              height: 35
                            }}
                          />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}
                    {props.store ? (
                      props.store.lazada ? (
                        <IconButton
                          id="lazada_button"
                          style={{
                            color: color && color.primary.contrastText,
                            paddingTop: "1vh",
                            display: props.store.lazada === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://lazada.com.ph/shop/${props.store.lazada}`
                          }
                        >
                          <Lazada
                            fill={color && color.primary.contrastText}
                            style={{
                              width: 35,
                              height: 35
                            }}
                          />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}

                    {props.store ? (
                      props.store.kumu ? (
                        <IconButton
                          id="kumu_button"
                          style={{
                            color: color && color.primary.contrastText,
                            paddingTop: "1vh",
                            display: props.store.kumu === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://app.kumu.ph/${props.store.kumu}`
                          }
                        >
                          <img
                            src={Kumu}
                            style={{ width: 35, height: 35, marginTop: 8 }}
                          />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}
                  </Box>
                </Container>
              </Box>
              <Box className={classes.footerImage}>
                {/* {props.store.contactus_image ? (
                <PinchZoomPan {...pinchPropsFooter}>
                  <img
                    src={
                      typeof props.store.contactus_image === "object"
                        ? createPreview(props.store.contactus_image)
                        : props.store.contactus_image
                    }
                  />
                </PinchZoomPan>
              ) : (
                ""
              )} */}
              </Box>
            </div>
            {!storeInfo.is_white_labeled && !hide_if_mobileview ? (
              <Footer />
            ) : (
              ""
            )}
          </div>
        );
      case "Contemporary":
        return (
          <div className={classes.footerWrapper}>
            <div className={classes.footerDisplay}>
              <Box className={classes.footer}>
                <Container>
                  <Box className={classes.footerContainer}>
                    <Typography
                      variant="h4"
                      style={{
                        color: color && color.primary.contrastText,
                        fontFamily: storeInfo && storeInfo.font.family,
                        width: "100%",
                        wordWrap: "break-word"
                      }}
                    >
                      {props.storeInfo
                        ? props.storeInfo.name
                          ? props.storeInfo.name
                          : "Your Shop's Name"
                        : "Your Shop's Name"}
                    </Typography>
                    <div className={classes.footerContent}>
                      <div className={classes.footerBtn}>
                        {props.navBtn.slice(0, 4).map((item, label) => {
                          return (
                            <Link
                              id="footer_navigation"
                              onClick={
                                props.isPreview
                                  ? null
                                  : () => {
                                      history.push(item.path);
                                      window.scrollTo(0, 0);
                                    }
                              }
                              variant="text"
                              className={classes.footerBtnStyles}
                              style={{
                                textDecoration: "none"
                              }}
                            >
                              {item.label}
                            </Link>
                          );
                        })}
                      </div>
                      <div className={classes.contactDetails}>
                        {props.store ? (
                          props.store.address ? (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: "flex"
                              }}
                            >
                              <LocationOn style={{ marginRight: "2vw" }} />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "95%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.store.address}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <LocationOn
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "95%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.isPreview ? "Business Address" : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          ""
                        )}

                        {props.store ? (
                          props.store.mobile ? (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: "flex"
                              }}
                            >
                              <PhoneAndroid style={{ marginRight: "2vw" }} />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "100%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.store.mobile.charAt(0) != 0
                                  ? "+63" + props.store.mobile
                                  : "+63" + props.store.mobile.substr(1)}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <PhoneAndroid
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "100%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.isPreview
                                  ? "Business Mobile Number"
                                  : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          " "
                        )}

                        {props.store ? (
                          props.store.landline ? (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display:
                                  props.store.landline === "null"
                                    ? "none"
                                    : "flex"
                              }}
                            >
                              <Phone style={{ marginRight: "2vw" }} />

                              <div
                                style={{
                                  marginLeft: 5,
                                  width: 300
                                }}
                              >
                                {props.store.landline}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <Phone
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />

                              <div
                                style={{
                                  marginLeft: 5,
                                  width: 300
                                }}
                              >
                                {props.isPreview ? "Business Landline" : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          ""
                        )}
                        {props.store ? (
                          props.store.email ? (
                            <Box
                              style={{
                                fontSize: 16,
                                width: "95%",
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display:
                                  props.store.email === "null" ? "none" : "flex"
                              }}
                            >
                              <Email style={{ marginRight: "2vw" }} />{" "}
                              <div
                                style={{
                                  marginLeft: 5,

                                  width: "85%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.store.email}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                width: "95%",
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <Email
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />{" "}
                              <div
                                style={{
                                  marginLeft: 5,

                                  width: "85%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.isPreview ? "Business Email" : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </Box>
                  <Box className={classes.btnSocial}>
                    {props.store ? (
                      props.store.facebook ? (
                        <IconButton
                          id="facebook_button"
                          style={{
                            color: color && color.primary.contrastText,
                            display:
                              props.store.facebook === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://facebook.com/${props.store.facebook}`
                          }
                        >
                          <FacebookIcon style={{ fontSize: "35px" }} />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}

                    {props.store ? (
                      props.store.instagram ? (
                        <IconButton
                          id="instagram_button"
                          style={{
                            color: color && color.primary.contrastText,
                            display:
                              props.store.instagram === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://instagram.com/${props.store.instagram}`
                          }
                        >
                          <InstagramIcon style={{ fontSize: "35px" }} />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}
                    {props.store ? (
                      props.store.shopee ? (
                        <IconButton
                          id="shopee_button"
                          style={{
                            color: color && color.primary.contrastText,
                            paddingTop: "1vh",
                            display: props.store.shopee === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://shopee.com/${props.store.shopee}`
                          }
                        >
                          <ShopeeIcon
                            fill={color && color.primary.contrastText}
                            style={{
                              width: 35,
                              height: 35
                            }}
                          />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}

                    {props.store ? (
                      props.store.lazada ? (
                        <IconButton
                          id="lazada_button"
                          style={{
                            color: color && color.primary.contrastText,
                            paddingTop: "1vh",
                            display: props.store.lazada === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://lazada.com.ph/shop/${props.store.lazada}`
                          }
                        >
                          <Lazada
                            fill={color && color.primary.contrastText}
                            style={{
                              width: 35,
                              height: 35
                            }}
                          />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}

                    {props.store ? (
                      props.store.kumu ? (
                        <IconButton
                          id="kumu_button"
                          style={{
                            color: color && color.primary.contrastText,
                            paddingTop: "1vh",
                            display: props.store.kumu === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://app.kumu.ph/${props.store.kumu}`
                          }
                        >
                          <img
                            src={Kumu}
                            style={{ width: 35, height: 35, marginTop: 8 }}
                          />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}
                  </Box>
                </Container>
              </Box>
              <Box className={classes.footerImage}>
                {/* {props.store.contactus_image ? (
                  <PinchZoomPan {...pinchPropsFooter}>
                    <img
                      src={
                        typeof props.store.contactus_image === "object"
                          ? createPreview(props.store.contactus_image)
                          : props.store.contactus_image
                      }
                    />
                  </PinchZoomPan>
                ) : (
                  ""
                )} */}
              </Box>
            </div>
            {!storeInfo.is_white_labeled && !hide_if_mobileview ? (
              <Footer />
            ) : (
              ""
            )}
          </div>
        );

      case "Minimalist":
        return (
          <div className={classes.footerWrapper}>
            <div className={classes.footerDisplay}>
              <Box className={classes.footer}>
                <Container>
                  <Box className={classes.footerContainer}>
                    <Typography
                      variant="h4"
                      style={{
                        color: color && color.primary.contrastText,
                        fontFamily: storeInfo && storeInfo.font.family,
                        width: "100%",
                        wordWrap: "break-word",
                        textTransform: "uppercase"
                      }}
                    >
                      {props.storeInfo
                        ? props.storeInfo.name
                          ? props.storeInfo.name
                          : "Your Shop's Name"
                        : "Your Shop's Name"}
                    </Typography>
                    <div className={classes.footerContent}>
                      <div className={classes.footerBtn}>
                        {props.navBtn.slice(0, 4).map((item, label) => {
                          return (
                            <Link
                              id="footer_navigation"
                              onClick={
                                props.isPreview
                                  ? null
                                  : () => {
                                      history.push(item.path);
                                      window.scrollTo(0, 0);
                                    }
                              }
                              variant="text"
                              className={classes.footerBtnStyles}
                              style={{
                                textDecoration: "none"
                              }}
                            >
                              {item.label}
                            </Link>
                          );
                        })}
                      </div>
                      <div className={classes.contactDetails}>
                        {props.store ? (
                          props.store.address ? (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: "flex"
                              }}
                            >
                              <LocationOn style={{ marginRight: "2vw" }} />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "95%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.store.address}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <LocationOn
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "95%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.isPreview ? "Business Address" : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          ""
                        )}
                        {props.store ? (
                          props.store.mobile ? (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: "flex"
                              }}
                            >
                              <PhoneAndroid style={{ marginRight: "2vw" }} />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "100%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.store.mobile.charAt(0) != 0
                                  ? "+63" + props.store.mobile
                                  : "+63" + props.store.mobile.substr(1)}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <PhoneAndroid
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: 5,
                                  width: "100%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.isPreview
                                  ? "Business Mobile Number"
                                  : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          " "
                        )}
                        {props.store ? (
                          props.store.landline ? (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display:
                                  props.store.landline === "null"
                                    ? "none"
                                    : "flex"
                              }}
                            >
                              <Phone style={{ marginRight: "2vw" }} />

                              <div
                                style={{
                                  marginLeft: 5,
                                  width: 300
                                }}
                              >
                                {props.store.landline}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <Phone
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />

                              <div
                                style={{
                                  marginLeft: 5,
                                  width: 300
                                }}
                              >
                                {props.isPreview ? "Business Landline" : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          ""
                        )}
                        {props.store ? (
                          props.store.email ? (
                            <Box
                              style={{
                                fontSize: 16,
                                width: "95%",
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display:
                                  props.store.email === "null" ? "none" : "flex"
                              }}
                            >
                              <Email style={{ marginRight: "2vw" }} />{" "}
                              <div
                                style={{
                                  marginLeft: 5,

                                  width: "85%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.store.email}
                              </div>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                fontSize: 16,
                                width: "95%",
                                color: color && color.primary.contrastText,
                                marginBottom: "4vh",
                                wordWrap: "break-word",
                                display: props.isPreview ? "flex" : "none"
                              }}
                            >
                              <Email
                                style={{
                                  marginRight: "2vw",
                                  display: props.isPreview ? "" : "none"
                                }}
                              />{" "}
                              <div
                                style={{
                                  marginLeft: 5,

                                  width: "85%",
                                  wordWrap: "break-word"
                                }}
                              >
                                {props.isPreview ? "Business Email" : ""}
                              </div>
                            </Box>
                          )
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </Box>
                  <Box className={classes.btnSocial}>
                    {props.store ? (
                      props.store.facebook ? (
                        <IconButton
                          id="facebook_button"
                          style={{
                            color: color && color.primary.contrastText,
                            display:
                              props.store.facebook === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://facebook.com/${props.store.facebook}`
                          }
                        >
                          <FacebookIcon style={{ fontSize: "35px" }} />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}

                    {props.store ? (
                      props.store.instagram ? (
                        <IconButton
                          id="instagram_button"
                          style={{
                            color: color && color.primary.contrastText,
                            display:
                              props.store.instagram === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://instagram.com/${props.store.instagram}`
                          }
                        >
                          <InstagramIcon style={{ fontSize: "35px" }} />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}
                    {props.store ? (
                      props.store.shopee ? (
                        <IconButton
                          id="shopee_button"
                          style={{
                            color: color && color.primary.contrastText,
                            paddingTop: "1vh",
                            display: props.store.shopee === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://shopee.com/${props.store.shopee}`
                          }
                        >
                          <ShopeeIcon
                            fill={color && color.primary.contrastText}
                            style={{
                              width: 35,
                              height: 35
                            }}
                          />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}

                    {props.store ? (
                      props.store.lazada ? (
                        <IconButton
                          id="lazada_button"
                          style={{
                            color: color && color.primary.contrastText,
                            paddingTop: "1vh",
                            display: props.store.lazada === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://lazada.com.ph/shop/${props.store.lazada}`
                          }
                        >
                          <Lazada
                            fill={color && color.primary.contrastText}
                            style={{
                              width: 35,
                              height: 35
                            }}
                          />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}

                    {props.store ? (
                      props.store.kumu ? (
                        <IconButton
                          id="kumu_button"
                          style={{
                            color: color && color.primary.contrastText,
                            paddingTop: "1vh",
                            display: props.store.kumu === "null" ? "none" : ""
                          }}
                          target="_blank"
                          href={
                            props.isPreview
                              ? null
                              : `http://app.kumu.ph/${props.store.kumu}`
                          }
                        >
                          <img
                            src={Kumu}
                            style={{ width: 35, height: 35, marginTop: 8 }}
                          />
                        </IconButton>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )}
                  </Box>
                </Container>
              </Box>
              <Box className={classes.footerImage}>
                {/* {props.store.contactus_image ? (
                  <PinchZoomPan {...pinchPropsFooter}>
                    <img
                      src={
                        typeof props.store.contactus_image === "object"
                          ? createPreview(props.store.contactus_image)
                          : props.store.contactus_image
                      }
                    />
                  </PinchZoomPan>
                ) : (
                  ""
                )} */}
              </Box>
            </div>
            {!storeInfo.is_white_labeled && !hide_if_mobileview ? (
              <Footer />
            ) : (
              ""
            )}
          </div>
        );

      case "ClassicV2":
        return (
          <div>
            <div className={classes.root}>
              <Container className="d-md-flex py-5">
                <div className="col-md-8 col-12">
                  <div className={classes.storename}>
                    {storeInfo ? storeInfo.name : "Your Shop's Name"}
                  </div>
                  <div className="row d-md-flex d-block">
                    <div className="col-md-6 col-12 mt-4 d-flex flex-md-column">
                      <Link
                        onClick={
                          isPreview
                            ? null
                            : () => {
                                history.push(`/shop/${storeInfo.site}`);
                              }
                        }
                        className={`${classes.colorPrimary}`}
                      >
                        Home
                      </Link>
                      <Link
                        onClick={
                          isPreview
                            ? null
                            : () => {
                                history.push(
                                  `/shop/${storeInfo.site}/about_us`
                                );
                              }
                        }
                        className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                      >
                        About Us
                      </Link>
                      <Link
                        onClick={
                          isPreview
                            ? null
                            : () => {
                                history.push(
                                  `/shop/${storeInfo.site}/products`
                                );
                              }
                        }
                        className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                      >
                        Products
                      </Link>
                    </div>
                    <div className="mt-4 col-md-6 col-12">
                      <div className="row">
                        {product_categories.map((category, index) => (
                          <div className="col-md-12 col-4 mb-3">
                            <Link
                              className={`${classes.colorPrimary} pb-3`}
                              onClick={() =>
                                props.isPreview
                                  ? null
                                  : history.push(
                                      `/shop/${
                                        storeInfo.site
                                      }/products/?category=${encodeURIComponent(
                                        category
                                      )}&sortBy=-created_on`
                                    )
                              }
                            >
                              {category}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12 mt-md-0 mt-4 d-flex flex-column">
                  <div className={classes.storename}>Contact Us</div>
                  <div className="mt-4 d-flex flex-column">
                    <Link
                      href={
                        props.isPreview ? null : `mailto:${storeInfo.email}`
                      }
                      className={classes.colorPrimary}
                    >
                      <EmailOutlined className={classes.colorPrimary} />{" "}
                      <span className="ml-3">
                        {storeInfo && storeInfo.email}
                      </span>
                    </Link>
                    {storeInfo.facebook ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(
                            `http://facebook.com/${props.store.facebook}`
                          )
                        }
                      >
                        <FacebookIcon className={classes.colorPrimary} />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.facebook}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.instagram ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(
                            `http://instagram.com/${props.store.instagram}`
                          )
                        }
                      >
                        <Instagram className={classes.colorPrimary} />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.instagram}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.shopee ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(`http://shopee.com/${props.store.shopee}`)
                        }
                      >
                        <ShopeeIcon
                          fill={"#54575f"}
                          style={{ width: 24, height: 24 }}
                        />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.shopee}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.lazada ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(
                            `http://lazada.com.ph/shop/${props.store.lazada}`
                          )
                        }
                      >
                        <Lazada
                          fill={"#54575f"}
                          style={{ width: 24, height: 24 }}
                        />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.lazada}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.kumu ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(`http://app.kumu.ph/${props.store.kumu}`)
                        }
                      >
                        <img
                          src={Kumu}
                          style={{ width: 22, height: 22, marginTop: 8 }}
                        />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.kumu}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Container>
            </div>
            {!storeInfo.is_white_labeled && !hide_if_mobileview ? (
              <Footer />
            ) : (
              ""
            )}
          </div>
        );
      case "ContemporaryV2":
        return (
          <div>
            <div className={classes.root}>
              <Container className="d-md-flex py-5">
                <div className="col-md-8 col-12">
                  <div className={classes.storename}>
                    {storeInfo ? storeInfo.name : "Your Shop's Name"}
                  </div>
                  <div className="row d-md-flex d-block">
                    <div className="col-md-6 col-12 mt-4 d-flex flex-md-column">
                      <Link
                        onClick={
                          isPreview
                            ? null
                            : () => {
                                history.push(`/shop/${storeInfo.site}`);
                              }
                        }
                        className={`${classes.colorPrimary}`}
                      >
                        Home
                      </Link>
                      <Link
                        onClick={
                          isPreview
                            ? null
                            : () => {
                                history.push(
                                  `/shop/${storeInfo.site}/about_us`
                                );
                              }
                        }
                        className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                      >
                        About Us
                      </Link>
                      <Link
                        onClick={
                          isPreview
                            ? null
                            : () => {
                                history.push(
                                  `/shop/${storeInfo.site}/products`
                                );
                              }
                        }
                        className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                      >
                        Products
                      </Link>
                    </div>
                    <div className="mt-4 col-md-6 col-12">
                      <div className="row">
                        {product_categories.map((category, index) => (
                          <div className="col-md-12 col-4 mb-3">
                            <Link
                              className={`${classes.colorPrimary} pb-3`}
                              onClick={() =>
                                props.isPreview
                                  ? null
                                  : history.push(
                                      `/shop/${
                                        storeInfo.site
                                      }/products/?category=${encodeURIComponent(
                                        category
                                      )}&sortBy=-created_on`
                                    )
                              }
                            >
                              {category}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12 mt-md-0 mt-4 d-flex flex-column">
                  <div className={classes.storename}>Contact Us</div>
                  <div className="mt-4 d-flex flex-column">
                    <Link
                      href={
                        props.isPreview ? null : `mailto:${storeInfo.email}`
                      }
                      className={classes.colorPrimary}
                    >
                      <EmailOutlined className={classes.colorPrimary} />{" "}
                      <span className="ml-3">
                        {storeInfo && storeInfo.email}
                      </span>
                    </Link>
                    {storeInfo.facebook ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(
                            `http://facebook.com/${props.store.facebook}`
                          )
                        }
                      >
                        <FacebookIcon className={classes.colorPrimary} />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.facebook}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.instagram ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(
                            `http://instagram.com/${props.store.instagram}`
                          )
                        }
                      >
                        <Instagram className={classes.colorPrimary} />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.instagram}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.shopee ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(`http://shopee.com/${props.store.shopee}`)
                        }
                      >
                        <ShopeeIcon
                          fill={"#54575f"}
                          style={{ width: 24, height: 24 }}
                        />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.shopee}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.lazada ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(
                            `http://lazada.com.ph/shop/${props.store.lazada}`
                          )
                        }
                      >
                        <Lazada
                          fill={"#54575f"}
                          style={{ width: 24, height: 24 }}
                        />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.lazada}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.kumu ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(`http://app.kumu.ph/${props.store.kumu}`)
                        }
                      >
                        <img
                          src={Kumu}
                          style={{ width: 22, height: 22, marginTop: 8 }}
                        />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.kumu}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Container>
            </div>
            {!storeInfo.is_white_labeled && !hide_if_mobileview ? (
              <Footer />
            ) : (
              ""
            )}
          </div>
        );
      case "Leftist":
        return <div className={classes.root} />;
      case "SariSari":
        return (
          <div>
            <div className={`${classes.footer} py-4`}>
              <Container>
                <div className="col-12 d-md-flex align-items-center p-0">
                  <div className="col-md-8 col-12 d-flex align-items-center p-0">
                    <div className={classes.logo} />
                    <div className={`${classes.storename} pl-3`}>
                      {storeInfo ? storeInfo.name : ""}
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-12 d-md-flex d-none"
                    style={{ fontSize: 22, color: "#54575f" }}
                  >
                    Contact Us
                  </div>
                </div>
                <div className="col-12 p-0 mt-4 d-md-flex">
                  <div
                    className={`col-md-3 col-12 pl-md-0 pl-0 pb-4 d-flex ${
                      isMobile ? "flex-wrap" : "flex-column"
                    }`}
                  >
                    <Link
                      onClick={
                        isPreview
                          ? null
                          : () => {
                              history.push(`/shop/${storeInfo.site}`);
                            }
                      }
                      className={`${classes.colorPrimary}`}
                    >
                      Home
                    </Link>
                    <Link
                      onClick={
                        isPreview
                          ? null
                          : () => {
                              history.push(`/shop/${storeInfo.site}/about_us`);
                            }
                      }
                      className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                    >
                      About Us
                    </Link>
                    <Link
                      onClick={
                        isPreview
                          ? null
                          : () => {
                              history.push(`/shop/${storeInfo.site}/products`);
                            }
                      }
                      className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                    >
                      Products
                    </Link>
                  </div>
                  <div className="col-md-5 col-12 pl-0">
                    <div className="row">
                      {product_categories.map((category, index) => (
                        <div className="col-md-12 col-4 mb-3">
                          <Link
                            className={`${classes.colorPrimary} pb-3`}
                            onClick={() =>
                              props.isPreview
                                ? null
                                : history.push(
                                    `/shop/${
                                      storeInfo.site
                                    }/products/?category=${encodeURIComponent(
                                      category
                                    )}&sortBy=-created_on`
                                  )
                            }
                          >
                            {category}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-4 col-12 mt-md-0 mt-4  d-flex flex-column">
                    <div
                      className="col-md-4 col-12 p-0 mb-md-0 mb-3 d-md-none d-flex"
                      style={{ fontSize: 22, color: "#54575f" }}
                    >
                      Contact Us
                    </div>
                    <Link
                      href={
                        props.isPreview ? null : `mailto:${storeInfo.email}`
                      }
                      className={classes.colorPrimary}
                    >
                      <EmailOutlined className={classes.colorPrimary} />{" "}
                      <span className="ml-3">
                        {storeInfo && storeInfo.email}
                      </span>
                    </Link>
                    {storeInfo.facebook ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(
                            `http://facebook.com/${props.store.facebook}`
                          )
                        }
                      >
                        <FacebookIcon className={classes.colorPrimary} />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.facebook}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.instagram ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(
                            `http://instagram.com/${props.store.instagram}`
                          )
                        }
                      >
                        <Instagram className={classes.colorPrimary} />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.instagram}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.shopee ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(`http://shopee.com/${props.store.shopee}`)
                        }
                      >
                        <ShopeeIcon
                          fill={"#54575f"}
                          style={{ width: 24, height: 24 }}
                        />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.shopee}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.lazada ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(
                            `http://lazada.com.ph/shop/${props.store.lazada}`
                          )
                        }
                      >
                        <Lazada
                          fill={"#54575f"}
                          style={{ width: 24, height: 24 }}
                        />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.lazada}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                    {storeInfo.kumu ? (
                      <Link
                        className={`${classes.colorPrimary} pt-3`}
                        onClick={() =>
                          window.open(`http://app.kumu.ph/${props.store.kumu}`)
                        }
                      >
                        <img
                          src={Kumu}
                          style={{ width: 22, height: 22, marginTop: 8 }}
                        />{" "}
                        <span className="ml-3">
                          {storeInfo && storeInfo.kumu}
                        </span>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Container>
            </div>
            {!storeInfo.is_white_labeled && !hide_if_mobileview ? (
              <Footer />
            ) : (
              ""
            )}
          </div>
        );
      case "Pastel":
        return (
          <div>
            <div className={`${classes.footer} py-5`}>
              <Container>
                <div className="col-12 d-md-flex align-items-center p-0">
                  <div className="col-md-9 col-12 d-flex align-items-center p-0">
                    <div className={classes.logo} />
                    <div className={`${classes.storename} pl-3`}>
                      {storeInfo ? storeInfo.name : ""}
                    </div>
                  </div>
                  <div
                    className="col-md-3 col-12 p-0 d-md-flex d-none"
                    style={{ fontSize: 22, color: "#54575f" }}
                  >
                    Contact Us
                  </div>
                </div>
                <div className="col-12 p-0 mt-4 d-md-flex">
                  <div
                    className={`col-md-3 col-12 p-md-0 px-0 pb-4 d-flex ${
                      isMobile ? "flex-wrap" : "flex-column"
                    }`}
                  >
                    <Link
                      onClick={
                        isPreview
                          ? null
                          : () => {
                              history.push(`/shop/${storeInfo.site}`);
                            }
                      }
                      className={`${classes.colorPrimary}`}
                    >
                      Home
                    </Link>
                    <Link
                      onClick={
                        isPreview
                          ? null
                          : () => {
                              history.push(`/shop/${storeInfo.site}/about_us`);
                            }
                      }
                      className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                    >
                      About Us
                    </Link>
                    <Link
                      onClick={
                        isPreview
                          ? null
                          : () => {
                              history.push(`/shop/${storeInfo.site}/products`);
                            }
                      }
                      className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                    >
                      Products
                    </Link>
                    {storeInfo.site === 'biyayaanimal' ? <>
                    <Link
                      onClick={
                        isPreview
                          ? null
                          : () => {
                             window.open("https://calendly.com/biyayaanimalcare/")
                            }
                      }
                      className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                    >
                      Schedule
                    </Link>
                    <Link
                      onClick={
                        isPreview
                          ? null
                          : () => {
                            window.open("https://biyayaanimalcare.wordpress.com/")
                            }
                      }
                      className={`${classes.colorPrimary} pt-md-3 pl-md-0 pl-3`}
                    >
                      Blog
                    </Link>
                  
                    </> : ''}
                  </div>
                  <div className="col-md-6 col-12 pl-0">
                    <div className="row">
                      {product_categories.map((category, index) => (
                        <div className="col-12 mb-3 ">
                          <Link
                            className={`${classes.colorPrimary} pb-3`}
                            onClick={() =>
                              props.isPreview
                                ? null
                                : history.push(
                                    `/shop/${
                                      storeInfo.site
                                    }/products/?category=${encodeURIComponent(
                                      category
                                    )}&sortBy=-created_on`
                                  )
                            }
                          >
                            {category}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3 col-12 p-0 mt-md-0 mt-4  d-flex flex-column">
                    <div
                      className="col-md-4 col-12 p-0 mb-md-0 mb-3 d-md-none d-flex"
                      style={{ fontSize: 22, color: "#54575f" }}
                    >
                      Contact Us
                    </div>
                    <Link
                      href={
                        props.isPreview ? null : `mailto:${storeInfo.email}`
                      }
                      className={classes.colorPrimary}
                    >
                      <EmailOutlined className={classes.colorPrimary} />{" "}
                      <span className="ml-3">
                        {storeInfo && storeInfo.email}
                      </span>
                    </Link>
                    <div className="pt-3 d-flex align-items-center">
                      {storeInfo.facebook ? (
                        <Link
                          className={`${classes.colorPrimary} mr-3`}
                          onClick={() =>
                            window.open(
                              `http://facebook.com/${props.store.facebook}`
                            )
                          }
                        >
                          <FacebookIcon className={classes.colorPrimary} />
                        </Link>
                      ) : (
                        ""
                      )}
                      {storeInfo.instagram ? (
                        <Link
                          className={`${classes.colorPrimary} mr-3`}
                          onClick={() =>
                            window.open(
                              `http://instagram.com/${props.store.instagram}`
                            )
                          }
                        >
                          <Instagram className={classes.colorPrimary} />{" "}
                        </Link>
                      ) : (
                        ""
                      )}
                      {storeInfo.shopee ? (
                        <Link
                          className={`${classes.colorPrimary} mr-3`}
                          onClick={() =>
                            window.open(
                              `http://shopee.com/${props.store.shopee}`
                            )
                          }
                        >
                          <ShopeeIcon
                            fill={"#54575f"}
                            style={{ width: 24, height: 24 }}
                          />{" "}
                        </Link>
                      ) : (
                        ""
                      )}
                      {storeInfo.lazada ? (
                        <Link
                          className={`${classes.colorPrimary} mr-3`}
                          onClick={() =>
                            window.open(
                              `http://lazada.com.ph/shop/${props.store.lazada}`
                            )
                          }
                        >
                          <Lazada
                            fill={"#54575f"}
                            style={{ width: 24, height: 24 }}
                          />{" "}
                        </Link>
                      ) : (
                        ""
                      )}
                      {storeInfo.kumu ? (
                        <Link
                          className={`${classes.colorPrimary} mr-3`}
                          onClick={() =>
                            window.open(
                              `http://app.kumu.ph/${props.store.kumu}`
                            )
                          }
                        >
                          <KumuLogo
                            fill={"#54575f"}
                            style={{ width: 24, height: 24 }}
                          />{" "}
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            {!storeInfo.is_white_labeled && !hide_if_mobileview ? (
              <Footer bg_color={color.primary.main} />
            ) : (
              ""
            )}
          </div>
        );
    }
  }

  return (
    <div>{setFooterTemplate(props.store ? props.store.template : "Blank")}</div>
  );
};
export default FooterTemplate;
