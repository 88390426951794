import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import DashboardLayout from "../dashboard/dashboardLayout";
import Loading from "../common/loading"

const useStyles = theme => ({
  root: {
    minHeight: "120vh",
    backgroundColor: "#FAFAFA",
  },
  content: {
    padding: "2em",
    maxWidth: 1300,
    margin: "0 auto",
  }
});

class Layout extends React.Component {

  render (){
    const { classes, children, noSideBar, proxtera } = this.props;
    const body = (
      <div className={classes.root}>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    )
    const extraProps = {
      backgroundColor: noSideBar ? "#fafafa": "transparent", 
      left: noSideBar ? "0" : "300px"
    }
    return (
      <DashboardLayout margin="0" marginTop="68px" right="0" {...extraProps}>
        <Loading open={proxtera.loading.get} />
        {body}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({ proxtera }) => ({ 
    proxtera
});
export default connect(mapStateToProps)(withStyles(useStyles)(Layout));

