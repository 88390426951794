import React from "react";
import Checkout from "./components/Checkout";

import "./App.css";

const CheckOut = props => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Checkout />;
};

export default CheckOut;
