import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const mainStyles = theme => ({
	popover: {
		padding: 5,
		minWidth: 300
	}
});

class ReportPopover extends React.Component {
	render (){
		const { 
			classes,
			anchorEl, 
			handleReportPopoverClose,
			reports,
			onButtonClick,
			loading
		} = this.props
		return (
			<Popover
				id="reports"
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => handleReportPopoverClose()}
				anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
				}}
				transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
				}}
			>
				<div className={classes.popover}>
					{
						loading ?
						<div style={{
							padding: "0 15px 15px",
							textAlign: "center"
						}}>
							<CircularProgress color="secondary" style={{
								height: 25, 
								width: 25, 
								color: "rgba(0,0,0,0.25)",
								textAlign: "center"
							}}/>
						</div>
						:
						!reports.length ?
						<div style={{ textAlign: "center", color: "rgba(0,0,0,0.25)", margin: "15px 0px"}}>
							<p>No items</p>
						</div> :``
					}
					<List dense={true}>
						{
							reports.length > 0 ?
							reports.map((report, index) => (
								<ListItem key={index}>
									<ListItemText
										primary={`${report.name}.csv`}
										secondary={
											<span style={{fontSize: 12}}>
												{new Date(report.created_on).toLocaleString()}
											</span>
										}
									/>
									<ListItemSecondaryAction>
										{
											report.processed ?
												report.file_report ?
												<a href={report.file_report} target="_blank" rel="noopener noreferrer">
													<IconButton edge="end" aria-label="download"
													onClick={() => {

													}}>
														<GetAppRoundedIcon style={{
															color: "#f97663",
															height: 20, 
															width: 20, 
														}}/>
													</IconButton>
												</a>
												:
												<PriorityHighIcon  style={{
													height: 15, 
													width: 15, 
													color: "rgba(0,0,0,0.25)", 
												}}/>
											: 
											<CircularProgress color="secondary" style={{
												height: 15, 
												width: 15, 
												color: "rgba(0,0,0,0.25)"
											}}/> 
										}
									</ListItemSecondaryAction>
								</ListItem>
							))
							:
							``
						}
					</List>
					<div style={{ padding: "0 10px 5px"}}>
						<Button
							fullWidth
							id="action_button"
							variant="contained"
							color="primary"
							className={classes.pageHeaderButton}
							style={{marginBottom: 5}}
							onClick={() => onButtonClick()}
							disabled={loading}
						>
							Generate CSV
						</Button>
					</div>
				</div>
			</Popover>
		);
	}
}

export default withStyles(mainStyles)(ReportPopover)