import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ACL from "../../../../common/utilities/ACL"
import Action from "../../../Redux/Actions"
import AdminDashboardLayout from "../../../layout"
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import apiConfig from "../../../../../common/apiConfig";
import Path from "../../../../../Paths";
import TableExport from '../../../../common/utilities/TableExport';
import moment from "moment";
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '@material-ui/core/Dialog';
import history from "../../../../../store/history";
import { stringify_get_params } from "../../../../common/utilities/DateFilter"

function descendingComparator(a, b, orderBy) {
if (b[orderBy] < a[orderBy]) {
	return -1;
}
if (b[orderBy] > a[orderBy]) {
	return 1;
}
return 0;
}

function getComparator(order, orderBy) {
return order === 'desc'
	? (a, b) => descendingComparator(a, b, orderBy)
	: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'Name' },
	{ id: 'email', numeric: false, disablePadding: false, label: 'Email' },
	{ id: 'apps_count', numeric: false, disablePadding: false, label: 'No of owned Apps' },
	{ id: 'sentro_fee', numeric: false, disablePadding: false, label: 'Sentro Fee' },
	{ id: 'id', numeric: false, disablePadding: false, label: 'Date Created' },
	{ id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
				<TableCell
					className={classes.tableHead}
					key={headCell.id}
					align={headCell.numeric ? 'right' : 'left'}
					sortDirection={orderBy === headCell.id ? order : false}
				>
					{
						headCell.id === "actions" ?
						headCell.label 
						:
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
							hideSortIcon={true}
							active={false}
						>
							<span>{headCell.label}</span>
							<span className={classes.headIconWrapper}>
								<ArrowDropUpIcon className={classes.headIconUp} />
								<ArrowDropUpIcon className={classes.headIconDown} />
							</span>
						</TableSortLabel>
					}
				</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	tableContainer: {
		overflowX: "auto"
	},
	table: {
		// minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	noResult: {
		width: "100%",
		display: "block",
		textAlign: "center",
		padding: "2em"
	},
	tableHead: {
		backgroundColor: "#F97663",
		color: "white !important",
		"&:hover": {
			color: 'white !important'
		},
		"&:active": {
			color: 'white !important'
		},
	},
	headIconWrapper: {
		direction: "rtl", 
		flexGrow: 1, 
		minWidth: 45, 
		display: "flex", 
		height: 20
	}, 
	headIconUp: {
		transform: "rotate(0deg)",
		position: "absolute",
		transform: "translateY(-8px)"
	},
	headIconDown: {
		transform: "rotate(180deg)",
		position: "absolute"
	},
	moreHorizIcon: {
		color: "gray",
		cursor: "pointer"
	}
}));


const menu = (row, selected, menuAnchor, handleClose, setDialogState, setTarget) => (
	<Menu
		id="simple-menu"
		anchorEl={menuAnchor}
		keepMounted
		open={selected === row.id}
		onClose={handleClose}
	>
		<MenuItem onClick={() => {
			history.push({
				pathname: `/admin/marketplace/partners/${row.id}`, 
			})
		}}>
			View Details
		</MenuItem>
		<MenuItem onClick={() => {
			history.push({
				pathname: `/admin/marketplace/partners/${row.id}/edit`, 
			})
		}}>
			Edit
		</MenuItem>
		<MenuItem 
			onClick={() => {
				setTarget(row)
				setDialogState(true, "delete")
				handleClose()
			}}
		>
			Delete
		</MenuItem>
		<MenuItem 
			disabled={row.user.is_verified || !row.user.email}
			onClick={() => {
				setTarget(row)
				setDialogState(true, "resend_verification")
				handleClose()
			}}
		>
			Resend Activation Link
		</MenuItem>
	</Menu>
)

function EnhancedTable(props) {
	const classes = useStyles();
	const [order, setOrder] = React.useState('desc');
	const [orderBy, setOrderBy] = React.useState('pk');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [data, setData] = React.useState(props.list);
	const [menuSelected, setMenuSelected] = React.useState(null);
	const [menuAnchor, setMenuAnchor] = React.useState(null)

	useEffect(() => {
		setData(props.list);
	  }, [props.list]);

      useEffect(() => {
          const rowsCount = props.rowsPerPage ? props.rowsPerPage : 5
          setRowsPerPage(rowsCount)
      }, [props.rowsPerPage]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClose = () => {
		setMenuSelected(null)
	}
	const setMenu = (event, id) => {
		console.log(id)
		setMenuAnchor(event.target)
		setMenuSelected(id)
	}

	return (
		<Fragment>
			<div className={classes.tableContainer}>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size={'medium'}
					aria-label="enhanced table"
					id="table"
				>
					<EnhancedTableHead
						classes={classes}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={data.length}
					/>
					<TableBody>
						{stableSort(data, getComparator(order, orderBy))
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((row, index) => {
							const labelId = `enhanced-table-checkbox-${index}`;
							
							return (
								<TableRow
									key={row.pk}
									hover
									// onClick={(event) => handleClick(event, row.first_name + " " + row.last_name)}
									role="checkbox"
									tabIndex={-1}
								>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.email}</TableCell>
									<TableCell align="left">{row.apps_count}</TableCell>
									<TableCell>{row.sentro_percentage_fee}%</TableCell>
									<TableCell>{moment(new Date(row.created_on)).format(`MMMM DD, YYYY hh:mm a`)}</TableCell>
									<TableCell align="left">
										{
											menu(
												row, 
												menuSelected, 
												menuAnchor, 
												handleClose,
												props.setDialogState,
												props.setTarget
											)
										}
										<MoreHorizIcon onClick={(event) => {setMenu(event, row.id)}} className={classes.moreHorizIcon}/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				{
					props.loading ? 
					<>
					{
						[1, 2, 3].map((index) => (
							<div style={{ padding: "0px 15px 0"}} key={index}>
								<Skeleton width="100%" height={60} />
							</div>
						))
					}
					</>
					:
					``
				}
				{
					!props.loading && !data.length ?
					<div style={{ height: "40vh", width: "100%", overflow: "hidden" }}>
						<div style={{ paddingTop: "4em" }}>
							<div style={{
								display: "flex",
								justifyContent: "center"
							}}>
								<img
									style={{width: "7em", height: "6em"}}
									src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
								/>
							</div>
							<div style={{
									display: "flex",
									justifyContent: "center",
									marginTop: 40,
									fontSize: 18,
									fontWeight: 600,
									color: "#b8bcc3",
									padding: "0 3em",
									textAlign: "center"
							}}>
								Nothing to Show
							</div>
						</div>
					</div>
					:
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				}
			</div>
		</Fragment>
	);
}

const pageStyles = theme => ({
	paper: {
		overflow: "hidden",
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(1),
		},
	},
	card: {
		backgroundColor: "white",
		padding: 0
	},
	cardHeader: {
		fontSize: "28px",
		marginBottom: "0.5em",
		textAlign: "left",
		color: "#F97663",
		fontWeight: 600
	},
	textDivider: {
		fontSize: "16px",
		padding: "1.5em 0",
		marginBottom: 0
	},
	miniCardHeader: {
		fontSize: "16px",
		textAlign: 'left',
		color: "#F97663",
		paddingBottom: "0.5em",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
		},
	},
	miniCardBody: {
		fontSize: "28px",
		weight: "bold",
		textAlign: 'left',
		color: theme.palette.text.primary,
	},
	lineChart: {
		paddingLeft: "auto",
		paddingRight: "auto",
		paddingTop: "2em",
		position: "relative",
		height: "400px",
		width: "100%",
		display: "block"
	},
	headerButtons: {
		height: "35px"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	searchBar: {
		background: "white",
		maxWidth: "240px",
		borderRadius: "4px",
		color: "gray",
		fontSize: 12,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "100%",
			width: "100%"
		},
	},
	excelExportButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		border: "solid 1px #F97663",
		background: " #F97663",
		borderRadius: 4,
		marginRight: 10,
		color: "white",
		transition: "all 0.3s linear",
		"&:hover": {
			background: "#f96a63"
		},
		"&:focus": {
			outline: 0
		},
		boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
	},
	headerActionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		},
	},
	searchInputWrapper: {
		display: "flex"
	},
	searchInput: {
		flexGrow: 1
	},
	tableWrapper: {
		overflowX: "auto"
	},
	primaryColor: {
		color: theme.palette.text.primary
	},
	dFlex: {
		display: "flex"
	},

	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
    },
	alertSuccessMessage: {
		padding: "0.75em 1.5em",
		border: "solid 1px #1DD28B",
		borderLeft: "solid 5px #1DD28B",
		borderRadius: 4,
		background: "#1dd28b14"
	},
});

class AdminAppMarketplacePartners extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			filteredList: [],
			// for actions
			target: '',
			alert: localStorage.getItem("ALERT_SUCCESS")
		};
		this.onSearch = this.onSearch.bind(this)
		this.onDateFilterCallback = this.onDateFilterCallback.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.setDialogState = this.setDialogState.bind(this)
		this.resendVerificationEmail = this.resendVerificationEmail.bind(this)
		this.setTarget = this.setTarget.bind(this)
	}
	
	onSearch(event) {
		const val = event.target.value
		console.log(val)
		let result = this.state.list.filter(data => {
			const lowerCaseQueryStr = val.toLowerCase()
			return (
					data.name.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.email.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					data.sentro_percentage_fee.toLowerCase().indexOf(lowerCaseQueryStr) != -1 || 
					moment(new Date(data.created_on)).format(`MMMM DD, YYYY hh:mm a`)
					.toLowerCase().indexOf(lowerCaseQueryStr) != -1
				)
			});
		this.setState({
			filteredList: result
		})
	}

	componentDidMount = async () => {
		localStorage.removeItem("ALERT_SUCCESS")
		const { dispatch, location, adminDashboard } = this.props
		const filter_query = stringify_get_params(adminDashboard.dateFilter)
		const query_params = location.search.length ? 
			location.search + "&" + filter_query.slice(1)
			:
			filter_query
		const data = await dispatch(Action.fetchPartners(query_params))
		this.setState({
			list: data,
			filteredList: data
		})
	}
	
	onDateFilterCallback = async (filter) => {
		const { dispatch, location } = this.props
		const filter_query = stringify_get_params(filter)
		const query_params = location.search.length ? 
			location.search + "&" + filter_query.slice(1)
			:
			filter_query
		const data = await dispatch(Action.fetchPartners(query_params))
		this.setState({
			list: data,
			filteredList: data
		})
	}

	setTarget(row){
		this.setState({
			target: row
		})
	}

	setDialogState(state, dialogType = "edit") {
		this.setState({
			dialogStat : state,
			dialogType: state ? dialogType: this.state.dialogType
		})
	}

	resendVerificationEmail = async () => {
		const { dispatch } = this.props
		const { target } = this.state
		const response = await dispatch(Action.resendVerificationPartner(target.email));
		if (response.status == 200){
			this.setState({
				dialogStat: false,
				alert: `Activation link sent to partner ${target.name} (${target.user.first_name} ${target.user.last_name} - ${target.email})`
			});
		} else {
			this.setState({
				dialogBody: response.response ? response.response.data.message : response.response.data.message,
				dialogStat: true,
				dialogTitle: "Error",
				dialogType: "info",
			})
		}
	}

	handleDelete = async () => {
		const { dispatch, match, history } = this.props
		const { target, filteredList } = this.state
		const response = await dispatch(Action.deletePartner(target.id))
		if (response.status == 200 || response.status == 204) {
			const list = filteredList.filter((partner) => partner.id !== target.id);
			this.setState({
				filteredList: list,
				dialogStat: false,
				alert: "Partner successfully deleted"
			});
		} else {
			this.setState({
				dialogStat: true,
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}
	
	render (){
		const {
			filteredList,
			dialogStat,
			dialogType,
			dialogTitleText,
			dialogBody,
			alert
		} = this.state
		const { classes, adminDashboard, history, location } = this.props
		const dialogTitle = () => {
			if (dialogType == "delete") {
				return "Are you sure you want to delete this record?"
			} else if (dialogType==="info") {
				return dialogTitleText
			} else if (dialogType == "resend_verification") {
				return "Please confirm"
			}
		}
		const dialogContent = () => {
			if (["delete"].includes(dialogType)) {
				return "Click submit to confirm"
			} else if (dialogType==="info") {
				return dialogBody
			} else if (dialogType == "resend_verification") {
				return `Resend activation link to partner ${this.state.target.name} (${this.state.target.user.first_name} ${this.state.target.user.last_name} - ${this.state.target.email})?`
			}
		}
		const actionButton = () => {
			if (dialogType==="delete") {
				return (
					<Button
						id="delete_button"
						onClick={(event) => {
							this.handleDelete()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Delete
					</Button>
				)
			} else if (dialogType==="resend_verification") {
				return (
					<Button
						id="confirm_button"
						onClick={(event) => {
							this.resendVerificationEmail()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Confirm
					</Button>
				)
			}
		}
		return (
			<AdminDashboardLayout onDateFilterCallback={this.onDateFilterCallback}>
				<ACL roles={["admin", "superuser"]} redirect_to="/login">
					<Dialog
						open={dialogStat}
						onClose={(event) => this.setDialogState(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						maxWidth="xs"
					>	
						<div className={classes.modalDiv}>
							<div className={classes.modalTile}>
								{dialogTitle()}
							</div>
							<div className={classes.modalBody}>
								{dialogContent()}
							</div>
							<div className={classes.modalActionsWrapper}>
								<Button
									id="action_button"
									variant="outlined"
									onClick={(event) => this.setDialogState(false)} 
									color="primary"
									className={classes.modalActions}
								>
									{
										dialogType == "info" ?
										"Close"
										:
										"Cancel"
									}
								</Button>
								{actionButton()}
							</div>
						</div>
					</Dialog>
					<div style={{
						display: "flex", 
						flexWrap: "wrap",
						alignItems: "center",
						marginBottom: "1.5em",
						padding: "0 2em"
					}}>
						<div className={classes.pageTitle}>
							Partners
						</div>
						<div className={classes.headerActionsWrapper}>
							<Button
								id="add_user_button" 
								variant="contained" 
								color="primary" 
								disableElevation 
								className={classes.excelExportButton}
								onClick={(event) => history.push(Path.ADMIN_APP_MARKETPLACE_PARTNERS_ADD)}
							>
								Add Partner
							</Button>
							{
								filteredList ? 
								<TableExport
									class={classes.excelExportButton}
									filename="Sentro - Marketplace Partners"
									sheet="Marketplace Partners"
									table={<EnhancedTable list={filteredList} rowsPerPage={filteredList.length }/>}
								/>
								:
								null
							}
							<OutlinedInput
								className={classes.searchBar}
								id="outlined-adornment-weight"
								onChange={(event) => this.onSearch(event)}
								startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
								'aria-label': 'weight',
								}}
								labelWidth={0}
								margin="dense"
								placeholder="Type here to search"
							/>
						</div>
					</div>
					<div style={{ padding: "0 2em 1em"}}>
						{
							alert &&
							<p className={classes.alertSuccessMessage}>{alert}</p>
						}
						<Paper className={classes.card}>
							<EnhancedTable 
								list={filteredList} 
								loading={adminDashboard.loading}
								setTarget={this.setTarget}
								setDialogState={this.setDialogState}
								resendVerificationEmail={this.resendVerificationEmail}
							/>
						</Paper>
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(pageStyles)(AdminAppMarketplacePartners))
