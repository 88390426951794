import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Button,
  Link,
  useMediaQuery,
} from "@material-ui/core";
import Stepper from "./mobileFeatured";
import ProductCard from "../ProductCard";
import history from "../../../../../store/history";
import Blank from "../blank";
import defaultSRC from "../defaultResources";
import defaultColor from "../colors";
import { useTheme } from "@material-ui/styles";
const FeaturedTemplate = (props) => {
  let color = props.store && props.store.color;
  let storeInfo = props.store;
  let theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  function featuredProducts(column) {
    let productList = [];
    for (let i in props.productList) {
      if (
        props.productList[i].status === "active" &&
        props.productList[i].featured
      ) {
        productList.push(props.productList[i]);
      }
    }
    if (productList.length) {
      return productList
        .filter((item) => item.status === "active" && item.featured)
        .slice(0, 5)
        .map((item, label) => {
          return (
            <div
              className={`d-flex justify-content-center  px-0 py-2 ${
                isMobile ? "col-12" : column || "col-4"
              }`}
            >
              <ProductCard
                key={label}
                onSelect={(value) => props.onSelect(value)}
                item={item}
                store={props.store}
                isPreview={props.isPreview}
              />
            </div>
          );
        });
    } else {
      return props.productList.slice(0, 3).map((item, label) => {
        return (
          <div
            className={`d-flex justify-content-center  px-0 py-2 ${
              isMobile ? "col-12" : column || "col-4"
            }`}
          >
            <ProductCard
              key={label}
              onSelect={(value) => props.onSelect(value)}
              item={item}
              store={props.store}
              isPreview={props.isPreview}
            />
          </div>
        );
      });
    }
  }

  const getStyles = (template, theme) => {
    switch (template) {
      case "Classic":
        return {
          content: {
            height: "100vh",
            width: "100%",
          },
          productCard: {
            minHeight: "30vh",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            margin: "0 63px",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              justifyContent: "center",
            },
          },
          featured: {
            fontSize: 36,
            textAlign: "center",
            color: "#54575f",
            marginTop: 20,
            marginBottom: 20,
            fontFamily: storeInfo && storeInfo.font.family,
            [theme.breakpoints.down("sm")]: {
              fontSize: 24,
            },
          },
          desktop: {
            display: "flex",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          },

          mobile: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              justifyContent: "center",
            },
          },
        };

      case "Contemporary":
        return {
          content: {
            height: "100vh",
            width: "100%",
          },
          productCard: {
            minHeight: "30vh",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            margin: "0 63px",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
            },
          },
          featured: {
            fontSize: 36,
            textAlign: "center",
            color: "#54575f",
            marginTop: 20,
            marginBottom: 20,
            fontFamily: storeInfo && storeInfo.font.family,

            [theme.breakpoints.down("sm")]: {
              fontSize: 24,
            },
          },
          desktop: {
            display: "flex",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          },

          mobile: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              justifyContent: "center",
            },
          },
        };

      case "Minimalist":
        return {
          content: {
            height: "100vh",
            width: "100%",
          },
          productCard: {
            minHeight: "30vh",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            margin: "0 63px",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
            },
          },
          featured: {
            fontSize: 36,
            textAlign: "center",
            color: "#54575f",
            marginTop: 20,
            marginBottom: 20,
            fontFamily: storeInfo && storeInfo.font.family,

            [theme.breakpoints.down("sm")]: {
              fontSize: 24,
            },
          },
          desktop: {
            display: "flex",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          },

          mobile: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              justifyContent: "center",
            },
          },
        };

      case "ClassicV2":
        return {
          header: {
            fontSize: 28,
            color: "#54575f",
            textAlign: "center",
            fontWeight: 500,
            fontFamily: storeInfo && storeInfo.font.family,
          },
          products: {
            marginTop: "2vh",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          },
          viewproducts: {
            fontSize: 16,
            fontWeight: 500,
            color: color.primary.contrastText,
            "&:hover": {
              color: color.primary.contrastText,
            },
          },
          viewproductsSection: {
            display: "flex",
            justifyContent: "center",
            padding: "50px 0",
            [theme.breakpoints.down("sm")]: {
              padding: "30px 0",
            },
          },
        };

      case "ContemporaryV2":
        return {
          header: {
            fontSize: 28,
            color: "#54575f",
            textAlign: "center",
            fontWeight: 500,
            fontFamily: storeInfo && storeInfo.font.family,
          },
          products: {
            marginTop: "4vh",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          },
          viewproducts: {
            fontSize: 16,
            fontWeight: 500,
            color: color.primary.contrastText,
            "&:hover": {
              color: color.primary.contrastText,
            },
          },
          viewproductsSection: {
            display: "flex",
            justifyContent: "center",
            padding: "50px 0",
            [theme.breakpoints.down("sm")]: {
              padding: "30px 0",
            },
          },
        };
      case "Leftist":
        return {
          header: {
            fontSize: 28,
            color: "#54575f",
            textAlign: "center",
            fontWeight: 500,
            fontFamily: storeInfo && storeInfo.font.family,
          },
          products: {
            marginTop: "4vh",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          },
          viewproducts: {
            fontSize: 16,
            fontWeight: 500,
            color: color.secondary.main,
          },
          viewproductsSection: {
            display: "flex",
            justifyContent: "center",
            padding: "50px 0",
            [theme.breakpoints.down("sm")]: {
              padding: "30px 0",
            },
          },
        };
      case "SariSari":
        return {
          header: {
            fontSize: 24,
            color: color.primary.contrastText,
            textAlign: "center",
            fontWeight: 500,
            fontFamily: storeInfo && storeInfo.font.family,
          },
          products: {
            marginTop: "4vh",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          },
          viewproducts: {
            fontSize: 16,
            fontWeight: 500,
            color: color.primary.contrastText,
            "&:hover": {
              color: color.primary.contrastText,
            },
          },
          viewproductsSection: {
            display: "flex",
            justifyContent: "center",
            padding: "50px 0",
            [theme.breakpoints.down("sm")]: {
              padding: "30px 0",
            },
          },
        };
      case "Pastel":
        return {
          header: {
            fontSize: 24,
            color: color.primary.contrastText,
            textAlign: "center",
            fontWeight: 500,
            fontFamily: storeInfo && storeInfo.font.family,
          },
          products: {
            marginTop: "4vh",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          },
          viewproducts: {
            fontSize: 16,
            fontWeight: 500,
            color: color.primary.contrastText,
            "&:hover": {
              color: color.primary.contrastText,
            },
          },
          viewproductsSection: {
            display: "flex",
            justifyContent: "center",
            padding: "50px 0",
            [theme.breakpoints.down("sm")]: {
              padding: "30px 0",
            },
          },
          btnMore: {
            backgroundColor: color.secondary.contrastText,
            borderColor: color.secondary.main,
            color: color.secondary.main,
          },
        };
      default:
        return {};
    }
  };

  let templateStyles = makeStyles((theme) =>
    getStyles(props.store ? props.store.template : "Blank", theme)
  );

  const classes = templateStyles();

  function setFeaturedTemplate() {
    switch (props.store ? props.store.template : "Blank") {
      case "Blank":
        return <Blank />;
      case "Classic":
        return (
          <div>
            <Box>
              <Container>
                <Box style={{ paddingTop: "30px" }}>
                  <Typography className={classes.featured}>
                    Featured Items
                  </Typography>
                  <Box className={classes.productCard}>
                    {isMobile ? (
                      <div className={classes.mobile}>
                        <Stepper
                          onSelect={(value) => props.onSelect(value)}
                          productList={props.productList}
                          isPreview={props.isPreview}
                        />
                      </div>
                    ) : (
                      <div
                        className={`col-12 d-flex justify-content-center flex-wrap`}
                      >
                        {props.productList && props.productList.length >= 0
                          ? featuredProducts()
                          : ""}
                      </div>
                    )}
                  </Box>
                  <Box
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: "7vh",
                      marginBottom: "5vh",
                    }}
                  >
                    <Button
                      id="view_products_button"
                      onClick={() =>
                        props.isPreview
                          ? null
                          : history.push(`/shop/${props.store.site}/products`)
                      }
                      variant="outlined"
                      size="medium"
                      color="secondary"
                      style={{
                        marginTop: "3vh",
                        marginBottom: "3vh",
                        width: 220,
                      }}
                    >
                      View all products
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          </div>
        );
      case "Contemporary":
        return (
          <div>
            <Box>
              <Container>
                <Box style={{ paddingTop: "30px" }}>
                  <Typography className={classes.featured}>
                    Featured Items
                  </Typography>
                  <Box className={classes.productCard}>
                    {isMobile ? (
                      <div className={classes.mobile}>
                        <Stepper
                          onSelect={(value) => props.onSelect(value)}
                          productList={props.productList}
                          isPreview={props.isPreview}
                        />
                      </div>
                    ) : (
                      <div
                        className={`col-12 d-flex justify-content-center flex-wrap`}
                      >
                        {props.productList && props.productList.length >= 0
                          ? featuredProducts()
                          : ""}
                      </div>
                    )}
                  </Box>
                  <Box
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: "7vh",
                      marginBottom: "5vh",
                    }}
                  >
                    <Button
                      id="view_products_button"
                      onClick={() =>
                        props.isPreview
                          ? null
                          : history.push(`/shop/${props.store.site}/products`)
                      }
                      disableElevation
                      variant="contained"
                      size="medium"
                      color="secondary"
                      style={{
                        marginTop: "3vh",
                        marginBottom: "3vh",
                        width: 220,
                      }}
                    >
                      View Products
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          </div>
        );

      case "Minimalist":
        return (
          <div>
            <Box>
              <Container>
                <Box style={{ paddingTop: "30px" }}>
                  <Typography className={classes.featured}>
                    FEATURED ITEMS
                  </Typography>
                  <Box className={classes.productCard}>
                    {isMobile ? (
                      <div className={classes.mobile}>
                        <Stepper
                          onSelect={(value) => props.onSelect(value)}
                          productList={props.productList}
                          isPreview={props.isPreview}
                        />
                      </div>
                    ) : (
                      <div
                        className={`col-12 d-flex justify-content-center flex-wrap`}
                      >
                        {props.productList && props.productList.length >= 0
                          ? featuredProducts()
                          : ""}
                      </div>
                    )}
                  </Box>
                  <Box
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: "7vh",
                      marginBottom: "5vh",
                    }}
                  >
                    <Button
                      id="view_products_button"
                      onClick={() =>
                        props.isPreview
                          ? null
                          : history.push(`/shop/${props.store.site}/products`)
                      }
                      disableElevation
                      variant="contained"
                      size="medium"
                      color="secondary"
                      style={{
                        marginTop: "3vh",
                        marginBottom: "3vh",
                        width: 220,
                      }}
                    >
                      VIEW ALL PRODUCTS
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          </div>
        );

      case "ClassicV2":
        return (
          <div style={{ backgroundColor: "#f3f3f3" }}>
            <Container>
              <div style={{ padding: "2em 0" }}>
                <div className={classes.header}>Featured Products</div>
                <div className={`col-12 d-flex flex-wrap`}>
                  {props.productList && props.productList.length >= 0
                    ? featuredProducts("col-4")
                    : ""}
                </div>
                <div className={classes.viewproductsSection}>
                  <Link
                    className={classes.viewproducts}
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(
                            `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                          )
                    }
                  >
                    View All Products
                  </Link>
                </div>
              </div>
            </Container>
          </div>
        );

      case "ContemporaryV2":
        return (
          <div>
            <Container>
              <div style={{ padding: "2em 0" }}>
                <div className={classes.header}>Featured Products</div>
                <div className={`col-12 d-flex flex-wrap`}>
                  {props.productList && props.productList.length >= 0
                    ? featuredProducts("col-3")
                    : ""}
                </div>
                <div className={classes.viewproductsSection}>
                  <Link
                    className={classes.viewproducts}
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(
                            `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                          )
                    }
                  >
                    View All Products
                  </Link>
                </div>
              </div>
            </Container>
          </div>
        );
      case "Leftist":
        let products_to_display = props.productList
          ?.filter((item) => item.status === "active" && item.featured)
          .slice(0, 4);
        if (!products_to_display.length) {
          products_to_display = props.productList
            ?.filter((item) => item.status === "active")
            .slice(0, 4);
        }
        return (
          <div>
            <div className={`row no-gutters`}>
              {products_to_display.map((item, label) => {
                return (
                  <div className="col-12 col-md-4">
                    <ProductCard
                      key={label}
                      onSelect={(value) => props.onSelect(value)}
                      item={item}
                      store={props.store}
                      isPreview={props.isPreview}
                    />
                  </div>
                );
              })}
            </div>
            <div className={classes.viewproductsSection}>
              <Link
                className={classes.viewproducts}
                onClick={() =>
                  props.isPreview
                    ? null
                    : history.push(
                        `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                      )
                }
              >
                View All Products
              </Link>
            </div>
          </div>
        );
      case "SariSari":
        return (
          <div style={{ backgroundColor: color.primary.main }}>
            <Container>
              <div style={{ padding: "2em 0" }}>
                <div className={classes.header}>Featured Products</div>
                <div
                  className={`col-12 mt-3 d-flex flex-wrap justify-content-center`}
                >
                  <div className="d-flex flex-wrap w-100 justify-content-center">
                    {props.productList && props.productList.length >= 0
                      ? featuredProducts("col-3")
                      : ""}
                  </div>
                </div>
                <div className={classes.viewproductsSection}>
                  <Button
                    disableElevation
                    variant={
                      storeInfo.site === "biyayaanimal"
                        ? "contained"
                        : "outlined"
                    }
                    color="secondary"
                    style={{
                      padding: "0 80px",
                      color: storeInfo.site === "biyayaanimal" ? "#fff" : "",
                    }}
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(
                            `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                          )
                    }
                  >
                    View all products
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        );
      case "Pastel":
        return (
          <div style={{ backgroundColor: "#fff" }}>
            <Container>
              <div style={{ padding: "2em 0" }}>
                <div className={classes.header}>Featured Products</div>
                <div
                  className={`col-12 p-md-2 p-0 mt-3 d-flex flex-wrap justify-content-center`}
                >
                  <div className="d-flex flex-wrap w-100">
                    {props.productList && props.productList.length >= 0
                      ? featuredProducts("col-4")
                      : ""}
                  </div>
                </div>
                <div className={classes.viewproductsSection}>
                  <Button
                    variant="outlined"
                    className={classes.btnMore}
                    style={{ padding: "0 80px", height: 40 }}
                    onClick={() =>
                      props.isPreview
                        ? null
                        : history.push(
                            `/shop/${props.store.site}/products/?category=All&sortBy=-created_on`
                          )
                    }
                  >
                    View More Products
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        );
      default:
        return <Blank />;
    }
  }

  return (
    <div>
      {setFeaturedTemplate(props.store ? props.store.template : "Blank")}
    </div>
  );
};
export default FeaturedTemplate;
