import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Button, FormHelperText, InputAdornment } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    "& p": {
      textAlign: "right"
    }
  },
  label3: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgba(43, 45, 50, 0.8)",
    margin: 0
  },
}));

const ReduxTextField = ({
  input,
  label,
  maxLength,
  minLength,
  multiline,
  cut,
  noHelpText,
  meta: { asyncValidating, touched, error },
  ...custom
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const showHelperText = input.showHelperText === undefined ? true: input.showHelperText 
  return (
    <div>
      <div className={classes.label3}>{label}</div>
      <TextField
        id={input.name}
        color="secondary"
        style={{ marginTop: 0 }}
        InputLabelProps={{
          shrink: true
        }}
        classes={{
          root: classes.textFieldRoot
        }}
        helperText={
          <span> {touched && showHelperText && error ? 
            error : custom.inputProps ? 
              custom.inputProps.maxlength ? 
                `${input.value ? input.value.length: "0"}/${custom.inputProps.maxlength}`
              : ""
            : ""
          }</span>
        }
        variant="outlined"
        margin="dense"
        multiline={multiline}
        rows={7}
        size={"small"}
        fullWidth
        error={Boolean(touched && error)}
        {...input}
        {...custom}
        InputProps={asyncValidating ? {
          endAdornment: (
            <div className="spinner-border mx-auto" role="status" style={{ 
              color: "#18B4A1", fontSize: 10,
              height: 15, width: 15
            }}>
              <span className="sr-only">Loading...</span>
            </div>
          )
        } : custom.type === "password" ? 
          {
            endAdornment: (
              <Button color="primary" style={{height: 35, fontSize: 12}} onClick={() => {
                setShowPassword(!showPassword)
              }}>SHOW</Button>
            )
          }
        : custom.InputProps}
        type={
          custom.type === "password" ?
            showPassword ? "text": "password"
          : custom.type || "text"
        }
      />
    </div>
  );
};

export default ReduxTextField;
