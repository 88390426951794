import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import history from "../../../../store/history";
import Layout from "../../layout"
import ProductCard from "../../components/productCard"
import _ from 'lodash'
import { animateScroll as scroll } from "react-scroll";
import qs from 'query-string';
import Actions from '../../Redux/Actions'

const useStyles = theme => ({
  title: {
    fontWeight: "bold",
    fontSize: 28
  },
  backHeader: {
    width: 120,
    display: "flex !important",
    alignItems: "center",
    padding: "0 0 2em",
    fontWeight: 600,
    fontSize: 14,
    color: "#F97663 !important"
  },
  searchBar: {
    background: "white",
    maxWidth: 280,
    borderRadius: "4px",
    color: "gray",
    fontSize: 12,
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      marginTop: "1em",
      maxWidth: "100%",
      width: "100%"
    },
  },
  radio: {
    backgroundColor: 'transparent',
    border: "solid 1px rgb(249, 118, 99)",
    width: 24,
    height: 24,
    borderRadius: "50%",
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      margin: 3,
      borderRadius: "50%",
      background: 'white',
      content: '""'
    }
  },
  checkedRadio: {
    backgroundColor: 'transparent',
    border: "solid 1px #d7d7d7",
    width: 24,
    height: 24,
    borderRadius: "50%",
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      margin: 3,
      borderRadius: "50%",
      background: "rgb(249, 118, 99)",
      content: '""'
    }
  },
});

class ProductList extends React.Component {

  state = {
    sort: "a-z",
    country: 'all',
    query_string: ""
  }
  searchTimeout = ""

  getProducts = (params) => {
    this.props.dispatch(Actions.GET_PRODUCTS({
      params: params
    }))
  }

  componentDidMount = async () => {
    scroll.scrollTo(0)
    const params = qs.parse(this.props.location.search);
    this.setState({
      sort: params.sort || "a-z",
      country: params.country || "all",
      query_string: params.query_string || "",
    }, () => {
      this.setFiltersToUrl(this.state);
      this.getProducts(this.state)
    })
  }

  setFilter = (e, stateName) => {
    this.setState({
      ...this.state,
      [stateName]: e.target.value
    }, () => {
      this.setFiltersToUrl(this.state)
    })
  }

  search = (e) => {
    const value = e.target.value;
    this.setState({
      ...this.state,
      query_string: value
    })
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setFiltersToUrl(this.state)
      if (value) this.getProducts(this.state)
    }, 700)
  }

  setFiltersToUrl = (queries) => {
    const searchString = qs.stringify(queries);
    console.log(searchString)
    history.push({
      // pathname: '/proxtera/products',
      search: searchString
    })
  }

  render (){
    const { classes, products } = this.props;
    const sortSettings = [
      {
        name: "A-Z",
        value: "a-z"
      },
      {
        name: "Z-A",
        value: "z-a"
      },
    ]
    const countrySettings = [
      {
        name: "All",
        value: "all"
      },
      {
        name: "Africa",
        value: "africa"
      },
      {
        name: "India",
        value: "india"
      },
      {
        name: "Philippines",
        value: "philippines"
      },
      {
        name: "Singapore",
        value: "singapore"
      },
    ]
    // let Products = [
    //   {
    //     image: "https://images.immediate.co.uk/production/volatile/sites/30/2020/08/health-benefits-of-almonds-hub-image-square-dcac827.jpg?quality=90&resize=504,458?quality=90&webp=true&resize=504,459",
    //     name: "almonds almonds almonds almonds almonds almonds",
    //     price: 48,
    //     store: "ABC Corp",
    //     avatar: "https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg"
    //   },
    //   {
    //     image: "https://images-gmi-pmc.edge-generalmills.com/087d17eb-500e-4b26-abd1-4f9ffa96a2c6.jpg",
    //     name: "Chocolate cookies",
    //     price: 25,
    //     store: "DEF enterprise",
    //     avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_the_Philippines.svg/1200px-Flag_of_the_Philippines.svg.png"
    //   },
    //   {
    //     image: "https://cdn.cichic.com/media/catalog/product/cache/1/image/5e06319eda06f020e43594a9c230972d/1/9/190104100971-1/white-patchwork-lace-pleated-v-neck-long-sleeve-ctue-boho-mini-dress.jpg",
    //     name: "Cute Boho Button up",
    //     price: 250,
    //     store: "XYZ Store",
    //     avatar: "https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg"
    //   },
    //   {
    //     image: "https://i0.wp.com/post.healthline.com/wp-content/uploads/2020/06/cashews-nuts-1296x728-header.jpg?w=1155&h=1528",
    //     name: "Cashew nuts",
    //     price: 6,
    //     store: "alingnena",
    //     avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_the_Philippines.svg/1200px-Flag_of_the_Philippines.svg.png"
    //   },
    //   {
    //     image: "https://ginginanddry.net.au/wp-content/uploads/2020/02/GinGinandDry-product-mango.jpg",
    //     name: "Dried Mango (1 pound bag)",
    //     price: 17,
    //     store: "XYZ Store",
    //     avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_the_Philippines.svg/1200px-Flag_of_the_Philippines.svg.png"
    //   }
    // ]
    let Products = products;
    // Transformations; TEMPORARY
    Products = Products.map(product => ({
      ...product,
      _name: product.short_name.replace(/\s/g, "").toLowerCase(),
      name: product.short_name,
      unit_price: product.unit_price/100
    }))
    Products =  _.orderBy(Products, '_name', this.state.sort === "a-z" ? 'asc': 'desc');
    
    return (
      <Layout noSideBar>
        <a className={classes.backHeader} href="#" onClick={(e) => {
            e.preventDefault();
            history.push("/proxtera");
        }}>
          <ArrowBackIcon/>
          <span style={{marginLeft: "1em"}}>{`  BACK`}</span>
        </a>
        <div className="d-flex mb-2">
          <h2 className={`mb-3 flex-grow-1 ${classes.title}`}>Product Marketplace</h2>
          <Button
            id="start_selling"
            variant="contained"
            color="primary"
            disableElevation
            className="d-flex"
            style={{ minWidth: 300, height: 40 }}
            onClick={() => history.push("/proxtera/orders")}
          >
            Customer’s Orders
          </Button>
        </div>
        <div className="d-flex mb-2">
          <div className="flex-grow-1">
            <OutlinedInput
              className={classes.searchBar}
              onChange={this.search}
              value={this.state.query_string}
              startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
              'aria-label': 'weight',
              }}
              labelWidth={0}
              margin="dense"
              placeholder="Type here to search"
            />
          </div>
          <div className="d-flex">
            <Button
              id="start_selling" 
              variant="outlined" 
              color="primary" 
              disableElevation 
              className="d-flex"
              style={{ minWidth: 190, height: 40 }}
              onClick={() => history.push("/proxtera/my_orders")}
            >
              My Orders
            </Button>
            <Button
              id="start_selling" 
              color="primary" 
              disableElevation 
              className="d-flex ml-2"
              disabled
              style={{ minWidth: 180, height: 40 }}
              // onClick={() => this.props.onStart ? this.props.onStart(): ``}
            >
              Manage my Products
            </Button>
          </div>
        </div>
        <div className="d-flex mb-2" id="filters_body">
          <div className="d-flex align-items-center flex-grow-1" id="sort">
            <p className="mb-0 mr-2">Sort:</p>
            <FormGroup row>
              {
                sortSettings.map((sort, index) => (
                  <FormControlLabel 
                    key={index}
                    style={{ marginBottom: 0 }}
                    value={sort.value}
                    control={
                      <Checkbox
                      color="primary" 
                      checked={this.state.sort===sort.value}
                      // onChange={handleChange} 
                      onChange={e => this.setFilter(e, "sort")} 
                      name={sort.name} />
                    } 
                    label={sort.name}
                  />
                ))
              }
            </FormGroup>
          </div>
          <div className="d-flex align-items-center" id="country">
            <FormGroup row>
              {
                countrySettings.map((country, index) => (
                  <FormControlLabel 
                    key={index}
                    style={{ marginBottom: 0 }}
                    value={country.value}
                    control={
                      <Checkbox
                      color="primary" 
                      checked={this.state.country===country.value}
                      // onChange={handleChange} 
                      onChange={e => this.setFilter(e, "country")} 

                      name={country.name} />
                    } 
                    label={country.name}
                  />
                ))
              }
            </FormGroup>
          </div>
        </div>
        {
          Products.length ? 
          <div className="row no-gutters row-eq-height flex-wrap">
            {
              Products.map((product, index) => (
                <div className="col-6 col-sm-4 col-md-3" key={index}>
                  <div className="p-2 h-100"  onClick={() => {
                    localStorage.setItem('PROXTERA_PRODUCT', JSON.stringify(product));
                    history.push({
                      pathname: '/proxtera/product',
                    //   search: qs.stringify({
                    //     part_id: product.part_id,
                    //     id: product.id
                    //   })
                    })
                  }}>
                    <ProductCard key={index} data={product} />
                  </div>
                </div>
              ))
            }
          </div>
          :
          <div className="text-center">
            <p className="text-secondary my-5 py-5">No products found</p>
          </div>
        }
      </Layout>
    )
  }
}


const mapStateToProps = state => ({ proxtera }) => ({ 
  products: proxtera.products
});
export default connect(mapStateToProps)(withStyles(useStyles)(ProductList));

