import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Radio } from "antd";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import MobileProductImageUpload from "../webBuilder/common/MobileProductImageUpload";
import { bankListing } from "../../../../websiteBuilder/forms/bankContants";
import SentroButton from "../../../sentroButton";
import { useSelector, useDispatch } from "react-redux";
const DialogTransition = props => <Slide direction="up" {...props} />;
const AddAccountForm = props => {
  const {
    newPaymentMethod,
    paymentMethod,
    setPaymentMethod,
    submitPaymentMethod,
    language
  } = props;
  const useStyles = makeStyles(theme => ({
    appBar: { position: "absolute", backgroundColor: "#fafafa" },
    closeBtn: { position: "absolute", left: 15 },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    imgLayout: {
      width: 90,
      height: 90,
      margin: 5,
      borderRadius: 5
    },
    label: { fontSize: 14, color: "#54575f" },
    actionBtn: {
      color: "#f97663",
      display: "flex",
      justifyContent: "flex-end",
      margin: 5,
      cursor: "pointer",
      position: "absolute",
      top: 5,
      right: 5
    }
  }));
  const payments = useSelector(state => state.mobileDashboard.payments);
  const [isDialogEnough, setIsDialogEnough] = React.useState(false);
  const [hasErrors, setHasErrors] = React.useState(true);
  const [error, setError] = React.useState({
    accountNameError: "",
    accountNumberError: "",
    mobileNumberError: "",
    imageError: "",
    linkError: "",
    disabled: true
  });

  function setPaymentMethodDetails(key, value) {
    setPaymentMethod(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  function createPreview(file) {
    var blob = new Blob([file], { type: file.type });
    var blobUrl = URL.createObjectURL(blob);

    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function() {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function() {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    return blobUrl;
  }

  function handleQrCode(image) {
    if (image.size > 5242880) {
      setError({
        ...error,
        imageError: "Image is too big. Maximum allowed size is 5 MB"
      });
    } else {
      if (!image.name.match(/\.(jpg|jpeg|png|heic)$/)) {
        setError({
          ...error,
          imageError: image.name + " is not supported file extension"
        });
      } else {
        setError({
          ...error,
          imageError: ""
        });
        setPaymentMethodDetails("qr_code_img", image);
      }
    }
  }

  function submitPayment() {
    if (
      paymentMethod.payment_method === "GCash" ||
      paymentMethod.payment_method === "PayMaya"
    ) {
      if (paymentMethod.mobile_number.length !== 11) {
        setError({
          ...error,
          mobileNumberError: "Please enter a valid 11-digit mobile number.",
          disabled: true
        });
      } else {
        setError({
          ...error,
          mobileNumberError: "",
          disabled: false
        });
        submitPaymentMethod();
      }
    } else {
      submitPaymentMethod();
    }
  }

  function setAccountType(e) {
    setPaymentMethod({
      ...paymentMethod,
      payment_method: "Bank",
      qr_code_img: "",
      bank_name: "",
      account_name: "",
      account_number: "",
      mobile_number: "",
      payment_link: ""
    });
    setError({
      accountNameError: "",
      accountNumberError: "",
      mobileNumberError: "",
      imageError: "",
      linkError: ""
    });
    setPaymentMethodDetails("payment_method", e.target.value);
  }
  const classes = useStyles();

  const accountType = [
    { value: "Bank" },
    { value: "PayMaya" },
    { value: "PayPal" }
  ];

  React.useEffect(() => {
    let payment_method = paymentMethod.payment_method;
    let paymentMethodUse;
    if (payment_method === "Bank") {
      paymentMethodUse =
        paymentMethod.bank_name &&
        paymentMethod.account_name &&
        paymentMethod.account_number;
    } else if (payment_method === "PayPal") {
      paymentMethodUse = paymentMethod.payment_link;
    } else {
      paymentMethodUse = paymentMethod.mobile_number;
    }
    setIsDialogEnough(paymentMethodUse);
  }, [paymentMethod]);

  React.useEffect(() => {
    for (let e in error) {
      if (error[e].length > 0) {
        setHasErrors(true);
        return;
      }
    }
    setHasErrors(false);
  }, [error]);

  return (
    <Dialog
      fullScreen
      open={paymentMethod.addAccount}
      TransitionComponent={DialogTransition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() =>
              setPaymentMethod({ ...newPaymentMethod, addAccount: false })
            }
            className={classes.closeBtn}
          >
            <CloseIcon style={{ color: "#f97663" }} />
          </IconButton>
          <div className={classes.header}>
            {language === "English" ? "Add Account" : "Magdagdag ng Account"}
          </div>
        </Toolbar>
      </AppBar>
      <div style={{ padding: "12vh 20px 25vh", overflowY: "scroll" }}>
        <div>
          <div className={classes.label}>Account Type</div>
          <Radio.Group
            style={{ width: "100%" }}
            defaultValue={paymentMethod.payment_method}
            onChange={e => setAccountType(e)}
          >
            {accountType.map(item => {
              return (
                <Radio
                  style={{ width: "50%", margin: "1vh 0" }}
                  value={item.value}
                >
                  {item.value}
                </Radio>
              );
            })}
          </Radio.Group>
        </div>
        {paymentMethod.payment_method === "PayPal" ? (
          ""
        ) : (
          <div style={{ marginTop: "3vh" }}>
            <div
              style={{ fontSize: 14, color: "#54575f", marginBottom: "1vh" }}
            >
              QR Code
            </div>
            <div style={{ display: "flex" }}>
              {paymentMethod.qr_code_img ? (
                <div style={{ position: "relative" }}>
                  <img
                    src={createPreview(paymentMethod.qr_code_img)}
                    className={classes.imgLayout}
                  />
                  <span className={classes.actionBtn}>
                    <CancelIcon
                      onClick={() => setPaymentMethodDetails("qr_code_img", "")}
                      style={{ fontSize: 20, color: "#f97663" }}
                    />
                  </span>
                </div>
              ) : (
                <MobileProductImageUpload
                  defaultPreview
                  onChange={value => handleQrCode(value)}
                />
              )}
            </div>
          </div>
        )}
        <div style={{ fontSize: 10, color: "red", overflowWrap: "break-word" }}>
          {error.imageError}
        </div>

        {paymentMethod.payment_method === "Bank" ? (
          <div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Bank Name</div>
              <Select
                color="secondary"
                margin="dense"
                id="bank_name"
                variant="outlined"
                fullWidth
                value={paymentMethod.bank_name}
                onChange={e =>
                  setPaymentMethodDetails("bank_name", e.target.value)
                }
              >
                {bankListing.map((item, key) => {
                  return <MenuItem value={item.name}>{item.name}</MenuItem>;
                })}
              </Select>
            </div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Account Name</div>
              <TextField
                style={{ marginTop: 0 }}
                margin="dense"
                variant="outlined"
                fullWidth
                color="secondary"
                inputProps={{ maxLength: 64 }}
                value={paymentMethod.account_name}
                error={error.accountNameError}
                onChange={e => {
                  setPaymentMethodDetails("account_name", e.target.value);

                  if (e.target.value === "") {
                    setError({
                      ...error,
                      accountNameError: "This field is required."
                    });
                  } else {
                    setError({
                      ...error,
                      accountNameError: ""
                    });
                  }
                }}
                onBlur={e => {
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      accountNameError: "This field is required."
                    });
                  } else {
                    setError({
                      ...error,
                      accountNameError: ""
                    });
                  }
                }}
                helperText={<div>{error.accountNameError}</div>}
              />
            </div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Account Number</div>
              <TextField
                style={{ marginTop: 0 }}
                margin="dense"
                variant="outlined"
                fullWidth
                color="secondary"
                inputProps={{ maxLength: 20 }}
                value={paymentMethod.account_number}
                error={error.accountNumberError}
                onChange={e => {
                  setPaymentMethodDetails("account_number", e.target.value);
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      accountNumberError: "This field is required."
                    });
                  } else if (isNaN(e.target.value)) {
                    setError({
                      ...error,
                      accountNumberError: "Account number must be numerical."
                    });
                  } else {
                    setError({
                      ...error,
                      accountNumberError: ""
                    });
                  }
                }}
                helperText={<div>{error.accountNumberError}</div>}
              />
            </div>
          </div>
        ) : paymentMethod.payment_method === "PayPal" ? (
          <div style={{ marginTop: "3vh" }}>
            <div className={classes.label}>Payment Link</div>
            <TextField
              style={{ marginTop: 0 }}
              margin="dense"
              variant="outlined"
              fullWidth
              color="secondary"
              inputProps={{ maxLength: 64 }}
              value={paymentMethod.payment_link}
              error={error.linkError}
              onChange={e => {
                setPaymentMethodDetails("payment_link", e.target.value);
                let list = payments.map(e =>
                  e.payment_link ? e.payment_link.toLowerCase() : ""
                );
                let value = e.target.value;
                if (e.target.value === "") {
                  setError({
                    ...error,
                    linkError: "This field is required."
                  });
                } else if (list.indexOf(value.toLowerCase()) !== -1) {
                  setError({
                    ...error,
                    linkError: "PayPal link already exist!"
                  });
                } else {
                  setError({
                    ...error,
                    linkError: ""
                  });
                }
              }}
              helperText={<div>{error.linkError}</div>}
            />
          </div>
        ) : (
          <div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Account Name</div>
              <TextField
                style={{ marginTop: 0 }}
                margin="dense"
                variant="outlined"
                fullWidth
                color="secondary"
                inputProps={{ maxLength: 64 }}
                value={paymentMethod.account_name}
                error={error.accountNameError}
                onChange={e => {
                  setPaymentMethodDetails("account_name", e.target.value);

                  if (e.target.value === "") {
                    setError({
                      ...error,
                      accountNameError: "This field is required."
                    });
                  } else {
                    setError({
                      ...error,
                      accountNameError: ""
                    });
                  }
                }}
                onBlur={e => {
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      accountNameError: "This field is required."
                    });
                  } else {
                    setError({
                      ...error,
                      accountNameError: ""
                    });
                  }
                }}
                helperText={<div>{error.accountNameError}</div>}
              />
            </div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Mobile Number</div>
              <TextField
                style={{ marginTop: 0 }}
                margin="dense"
                variant="outlined"
                fullWidth
                color="secondary"
                inputProps={{ maxLength: 11 }}
                value={paymentMethod.mobile_number}
                error={error.mobileNumberError}
                onChange={e => {
                  setPaymentMethodDetails("mobile_number", e.target.value);
                  let list = payments.map(e => e.mobile_number);
                  let value = e.target.value;
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      mobileNumberError: "This field is required."
                    });
                  } else if (isNaN(e.target.value)) {
                    setError({
                      ...error,
                      mobileNumberError: "This is an invalid mobile number."
                    });
                  } else if (e.target.value.charAt(0) != 0) {
                    setError({
                      ...error,
                      mobileNumberError: "This is an invalid mobile number."
                    });
                  } else if (e.target.value.charAt(1) != 9) {
                    setError({
                      ...error,
                      mobileNumberError: "This is an invalid mobile number."
                    });
                  } else if (list.indexOf(value) !== -1) {
                    setError({
                      ...error,
                      mobileNumberError: "Mobile number already exist!"
                    });
                  } else {
                    setError({
                      ...error,
                      mobileNumberError: ""
                    });
                  }
                }}
                helperText={<div>{error.mobileNumberError}</div>}
              />
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          padding: 20,
          width: "100%",
          bottom: 0
        }}
      >
        <SentroButton
          disableElevation
          fullWidth
          variant="contained"
          label="Submit"
          disabled={hasErrors || !isDialogEnough}
          onClick={() => submitPayment()}
        />
      </div>
    </Dialog>
  );
};

export default AddAccountForm;
