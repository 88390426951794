import Services from "./Services";

export const ActionTypes = {
	// App list
	FETCHING_APPS: "FETCHING_APPS",
	FETCHING_APPS_SUCCESS: "FETCHING_APPS_SUCCESS",

	// App detailed
	FETCHING_APP: "FETCHING_APP",
	FETCHING_APP_SUCCESS: "FETCHING_APP_SUCCESS",

	// Set Header Tab
	SET_HEADER_TAB: "SET_HEADER_TAB",

	// Set item to cart
    SET_TO_CART: "SET_TO_CART",

	// Marketplace Checkout
	CHECK_KYC: "CHECK_KYC",
	CHECK_KYC_SUCCESS: "CHECK_KYC_SUCCESS",
	
	// Marketplace Checkout
	MARKETPLACE_CHECKOUT: "MARKETPLACE_CHECKOUT",
    MARKETPLACE_CHECKOUT_SUCCESS: "MARKETPLACE_CHECKOUT_SUCCESS",
    
	// Marketplace Checkout Details
	MARKETPLACE_CHECKOUT_DETAILS: "MARKETPLACE_CHECKOUT_DETAILS",
	MARKETPLACE_CHECKOUT_DETAILS_SUCCESS: "MARKETPLACE_CHECKOUT_DETAILS_SUCCESS",

	// Setting fetch status
	REQUEST_SUCCESS: "REQUEST_SUCCESS",
	REQUEST_FAILED: "REQUEST_FAILED"
};

class Actions {

	static fetchApps() {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_APPS });
				let response = await Services.fetchApps();
				if (response){
					dispatch({
						type: ActionTypes.FETCHING_APPS_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	static fetchApp(id) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_APP });
				let response = await Services.fetchApp(id);
				if (response){
					dispatch({
						type: ActionTypes.FETCHING_APP_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	static setHeaderTab(data) {
		return async dispatch => {
			dispatch({ type: ActionTypes.SET_HEADER_TAB,
			payload: data });
		};
	}

	static setToCart(item) {
		return async dispatch => {
			dispatch({
				type: ActionTypes.SET_TO_CART,
				payload: [item]
			});
		};
	}
	
	static checkKYC() {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.CHECK_KYC });
				let response = await Services.checkKYC();
				if (response){
					dispatch({
						type: ActionTypes.CHECK_KYC_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	static marketplaceCheckout(data) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.MARKETPLACE_CHECKOUT });
				let response = await Services.marketplaceCheckout(data);
				if (response){
					dispatch({
						type: ActionTypes.MARKETPLACE_CHECKOUT_SUCCESS,
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	static marketplaceCheckoutDetails(ref_id) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.MARKETPLACE_CHECKOUT_DETAILS });
				let response = await Services.marketplaceCheckoutDetails(ref_id);
				if (response){
					dispatch({
						type: ActionTypes.MARKETPLACE_CHECKOUT_DETAILS_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}
}

export default Actions;
