import React from "react";
import { Dialog } from "@material-ui/core";
import { useSelector } from "react-redux";

const SentroLoading = props => {
  return (
    <Dialog open={props.open}>
      <div
        style={{
          padding: 10,
          height: 100,
          width: 100,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div>
          <img
            height={50}
            width={50}
            src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/loading+icon2.gif"
          />
          <div style={{ fontWeight: "bold", fontSize: 14 }}>Loading</div>
        </div>
      </div>
    </Dialog>
  );
};

export default SentroLoading;
