import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import FileUploaderField from "../../../../reduxFields/fileUploaderField";
import DataTable from "./table";
import {
  getProductBatchUpload,
  postProductBatchUpload,
  getStore
} from "../../../websiteBuilderActions";

function BatchUpload(props) {
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 28,
      fontWeight: 600,
      color: "#002c42",
      width: "100%",
      textAlign: "center"
    },
    card: {
      backgroundColor: "#fff",
      border: "1px solid #d1d5dd",
      borderRadius: 4,
      margin: "3vh 0"
    },
    label: {
      fontSize: 13,
      color: "#54575f"
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const stores = useSelector(state => state.webBuilder.stores);
  const batch_uploads = useSelector(
    state => state.webBuilder.products.batch_upload.data
  );

  const [filters, setFilters] = React.useState({
    page: 1,
    itemsPerPage: 5,
    maxPage: 1
  });

  React.useEffect(() => {
    const initialize = async () => {
      let res = await dispatch(getStore());
      try {
        const store_uuid = res[0].uuid;
        res = await dispatch(getProductBatchUpload(store_uuid, filters));
        setFilters(filters => ({
          ...filters,
          maxPage: res.total_pages
        }));
      } catch {
        history.push("/dashboard");
      }
    };
    initialize();
  }, [dispatch, history]);

  const pageOnChange = async (e, page) => {
    setFilters(filters => ({
      ...filters,
      page
    }));
    const store = stores[0];
    if (store) {
      const res = await dispatch(
        getProductBatchUpload(store.uuid, {
          ...filters,
          page
        })
      );
      console.log(res);
      setFilters(filters => ({
        ...filters,
        maxPage: res.total_pages
      }));
    }
  };

  const successOnClick = () => {
    history.push("/products");
  };

  const errorOnClick = link => {
    window.open(link);
  };

  const [errorMessage, setErrorMessage] = React.useState("");
  const [uploadStatus, setUploadStatus] = React.useState("standby");

  const fileOnChange = async file => {
    setUploadStatus("loading");
    setErrorMessage("");
    const store = stores[0];
    if (store) {
      try {
        await dispatch(postProductBatchUpload(store.uuid, file));
        setUploadStatus("success");
      } catch (error) {
        setErrorMessage(error.response.data.file[0]);
        setUploadStatus("standby");
      }
    }
  };

  const template_link =
    "https://sentro-instructions.s3.ap-southeast-1.amazonaws.com/Product-Batch-Upload-Template.xlsx";

  return (
    <div style={{ padding: "0 10vw" }}>
      <div className="d-flex">
        <Button
          className="back_button"
          color="primary"
          startIcon={<ArrowBack />}
          variant="text"
          style={{ zIndex: 2, position: "absolute" }}
          onClick={() => history.push("/products")}
        >
          Back
        </Button>
        <div className={classes.header}>Batch upload</div>
      </div>
      <div className={classes.card}>
        <div
          style={{ borderBottom: "1px solid #d1d5dd" }}
          className="p-4 d-flex justify-content-between align-items-center"
        >
          <div style={{ fontSize: 14, color: "#2b2d32" }}>
            Please download the excel template for offline editing.
          </div>
          <Button
            className="download_template_button"
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => window.open(template_link)}
          >
            Download template
          </Button>
        </div>
        <div
          className="p-4 file_upload"
          style={{ borderBottom: "1px solid #d1d5dd" }}
        >
          <FileUploaderField
            label="Upload template"
            acceptedTypes={["xlsx"]}
            status={uploadStatus}
            errorMessage={errorMessage}
            onChange={fileOnChange}
          />
        </div>
        <div className="p-4">
          <strong style={{ fontSize: 16, color: "#f97663" }}>
            Upload history
          </strong>
          <div className="py-3" style={{ fontSize: 14, color: "#54575f" }}>
            Products that have been uploaded using{" "}
            <span style={{ fontWeight: 600 }}>Batch upload</span> are stored in
            Drafts. Review product details and add product photos before
            publishing them.
          </div>
          <div>
            <DataTable
              data={batch_uploads}
              filters={filters}
              pageOnChange={pageOnChange}
              successOnClick={successOnClick}
              errorOnClick={errorOnClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BatchUpload;
