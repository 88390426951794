import React, { Fragment } from 'react'
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import history from "../../../store/history";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Action from "../../admin/Redux/Actions";

const useStyles = theme => ({
	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
});

// Role-Based Access Control
class ACL extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			currentUser: props.adminDashboard.currentUser,
			loading: props.adminDashboard.userLoading,
			authorized: false
		}
		this.roleCheck = this.roleCheck.bind(this)
	}

	roleCheck(allowed_roles) {
		const { currentUser } = this.state
		const { user_roles } = currentUser
		let authorized = false
		for (var i in user_roles) {
			if (allowed_roles.includes(user_roles[i])) {
				authorized = true
				break
			}
		}
		return authorized
	}

	componentDidMount = async () => {
		const { currentUser } = this.state
		if (currentUser){
			const { roles:allowed_roles=[] } = this.props
			const authorized = this.roleCheck(allowed_roles)
			this.setState({
				authorized
			})
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			loading: nextProps.adminDashboard.userLoading,
        })
		if (nextProps.adminDashboard.currentUser){
			this.setState({
				currentUser: nextProps.adminDashboard.currentUser,
			}, () => {
				const authorized = this.roleCheck(this.props.roles)
				this.setState({
					authorized
				})
			})
		}
	}

	render() {
		const {
			children, classes, redirect_to
		} = this.props
		const { authorized, loading } = this.state
		const Children = () => {
			if (!authorized) {
				return null
			}
			return children

		}
		return ( 
			<Fragment>
				{
					redirect_to ? 
					<Dialog
						open={ !loading && !authorized }
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						maxWidth="xs"
					>	
						<div className={classes.modalDiv}>
							<div className={classes.modalTile}>
								You are not authorized to access this page
							</div>
							<div className={classes.modalBody}>
								Make sure your account has enough permission access
							</div>
							<div className={classes.modalActionsWrapper}>
								<Button
									variant="contained"
									color="primary"
									className={classes.modalActions}
									onClick={() => {
										history.push(redirect_to)
									}}
								>
									Understood
								</Button>
							</div>
						</div>
					</Dialog>
					:
					null
				}
				{Children()}
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(useStyles)(ACL));
