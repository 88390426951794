
import React, { useEffect, Fragment } from "react";
import { useSelector } from "react-redux";

export default function FBMessenger(props) {
  const is_live_shop_view =
    props.children.props.history.location.pathname.split("/")[1] === "shop";
  let storeInfo = useSelector(state => state.store.store);
  useEffect(() => {
    if (storeInfo.fb_page_id && is_live_shop_view) {
      if (storeInfo.fb_page_id.length) {
        window.fbAsyncInit = function() {
          window.FB.init({
            xfbml: true,
            version: "v5.0"
          });
        };
        (function(d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src =
            "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      }
    }
  });
  const load_pixel = (pixel_id) => {
    let script = document.createElement('script')
    script.innerHTML = `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixel_id}');
        fbq('track', 'PageView');`;
      document.head.appendChild(script);
      console.log("pixel loaded", pixel_id)
    // }
  };

  useEffect(() => {
    // function fbPixel() {
      if (storeInfo.fb_pixel_id && is_live_shop_view) {
        if (storeInfo.fb_pixel_id.length) {
          load_pixel(storeInfo.fb_pixel_id)
          // return 
          // (function(f, b, e, v, n, t, s) {
          //   if (f.fbq) return;
          //   n = f.fbq = function() {
          //     n.callMethod
          //       ? n.callMethod.apply(n, arguments)
          //       : n.queue.push(arguments);
          //   };
          //   if (!f._fbq) f._fbq = n;
          //   n.push = n;
          //   n.loaded = !0;
          //   n.version = "2.0";
          //   n.queue = [];
          //   t = b.createElement(e);
          //   t.async = !0;
          //   t.src = v;
          //   s = b.getElementsByTagName(e)[0];
          //   s.parentNode.insertBefore(t, s);
          //   f.fbq("init", storeInfo.fb_pixel_id);
          //   f.fbq("track", "PageView");
          // })(
          //   window,
          //   document,
          //   "script",
          //   "https://connect.facebook.net/en_US/fbevents.js"
          // );
        }
      }
    // }
    // fbPixel();
    
  });
  return (
    <Fragment>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id={`${storeInfo.fb_page_id}`}
      />
      <noscript>
        <img
          alt=""
          height="1"
          width="1"
          style="display:none"
          src={`https://www.facebook.com/tr?id=${storeInfo.fb_pixel_id}&ev=PageView&noscript=1`}
        />
      </noscript>
      {props.children}
    </Fragment>
  );
}
