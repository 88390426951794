import Utils from "../common/utils";
import moment from 'moment';
import { DashboardActionTypes } from "./dashboardActions";
const initialState = {
  stores: [],
  loading: false,
  error: null,
  selectedStore: null,
  dialogOpen: false,
  status: null,
  kyc_survey: null,
  kyc_survey_success: false,
  tnc_agreed: null,
  password_change: null,
  skipModal: false,
  custom_domain: {
      checkDnsLoading: false
  },
  analytics: {
    chart: [],
    top_products: [],
    top_categories: [],
    sales: [],
    sales_per_order: []
  }

};
export default function appStoreReducers(state = initialState, action) {
  switch (action.type) {
    case DashboardActionTypes.GET_STORES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DashboardActionTypes.GET_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: action.payload
      };
    case DashboardActionTypes.GET_STORES_FAILED:
      return {
        ...state,
        loading: true,
        error: action.payload
      };
    case DashboardActionTypes.GET_STORES_CLEAR:
      return {
        ...state,
        loading: false,
        selectedStore: action.payload
      };
    case DashboardActionTypes.OPEN_DELETE_DIALOG:
      return {
        ...state,
        dialogOpen: true
      };
    case DashboardActionTypes.CLOSE_DELETE_DIALOG:
      return {
        ...state,
        dialogOpen: false
      };
    case DashboardActionTypes.GET_STATUS:
      return {
        ...state,
        loading: false,
        status: action.payload
      };
    case DashboardActionTypes.SAVE_FB_PAGE_ID:
      return {
        ...state,
        loading: true
      };
    case DashboardActionTypes.SAVE_FB_PAGE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: [action.payload]
      };
    case DashboardActionTypes.SAVE_FB_PIXEL_ID:
      return {
        ...state,
        loading: true
      };
    case DashboardActionTypes.SAVE_FB_PIXEL_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: [action.payload]
      };
    case DashboardActionTypes.SAVE_KYC_SURVEY:
      return {
        ...state,
        loading: true
      };
    case DashboardActionTypes.SAVE_KYC_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        kyc_survey: action.payload,
        kyc_survey_success: true
      };
    case DashboardActionTypes.SKIP_SURVEY:
      return {
        ...state,
        loading: false
      };
    case DashboardActionTypes.AGREED_TNC:
      return {
        ...state,
        tnc_agreed: action.payload,
        loading: false
      };
    case DashboardActionTypes.SKIP_UPDATE_PASSWORD:
      return {
        ...state,
        password_change: action.payload,
        loading: false
      };
    case DashboardActionTypes.OPEN_SKIP_MODAL:
      return {
        ...state,
        skipModal: true
      };
    case DashboardActionTypes.CLOSE_SKIP_MODAL:
      return {
        ...state,
        skipModal: false
      };
    case DashboardActionTypes.GET_CUSTOM_DOMAIN:
      return {
          ...state,
          loading: true
      };
    case DashboardActionTypes.GET_CUSTOM_DOMAIN_SUCCESS:
      return {
          ...state,
          loading: !action.removeLoadingAfter,
          custom_domain: {
            ...state.custom_domain,
            domain_name: action.payload.name,
            name_servers: action.payload.name_servers
          },
      };
    case DashboardActionTypes.SAVE_CUSTOM_DOMAIN:
      return {
        ...state,
        custom_domain: {
          ...state.custom_domain,
          domain_name: action.payload.domain_name
        },
        loading: true
      };
    case DashboardActionTypes.SAVE_CUSTOM_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        custom_domain: {
          ...state.custom_domain,
          name_servers: action.payload.name_servers
        }
      };
    case DashboardActionTypes.CHECK_DNS:
      return {
        ...state,
        custom_domain: {
          ...state.custom_domain,
          checkDnsLoading: true
        }
      };
    case DashboardActionTypes.CHECK_DNS_SUCCESS:
      return {
        ...state,
        custom_domain: {
          ...state.custom_domain,
          checkDnsLoading: false
        },
        loading: false
      };
    case DashboardActionTypes.CLOSE_LOADING:
      return {
          ...state,
          loading: false,
          custom_domain: {
            ...state.custom_domain,
            checkDnsLoading: false
          }
      };
    case DashboardActionTypes.GET_ANALYTICS:
      const chart = action.payload.chart.map((i, index) => ({
        ...i,
        x: moment(i.date).toDate(),
      }))
      const top_products = action.payload.top_products.map((i, index) => ({
        ...i,
        id: index+1,
        value: action.params.top_products_sort_by === "total_sales" ?
          `₱ ${Utils.commafy(i.total_sales)}`: i.quantity,
        description: i.name
      }))
      const top_categories = action.payload.top_categories.map((i, index) => ({
        ...i,
        id: index+1,
        value: action.params.top_category_sort_by === "total_sales" ?
          `₱ ${Utils.commafy(i.total_sales)}`: i.quantity,
        category: i.name
      }))
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...action.payload,
          chart,
          top_products,
          top_categories
        }
      };
  
    default:
      return state;
  }
}
