import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import MobileVoucherCard from "./mobileVoucherCard";
import MobileVoucherFilter from "./mobileVoucherFilter";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useSelector, useDispatch } from "react-redux";
import {
  setVoucherFilters,
  getVouchers,
  editVoucher
} from "../webBuilder/websiteBuilderActions";
import DeactivateVoucherModal from "./deactivateModal";
const MobileVouchers = props => {
  const {
    openCreateVoucher,
    setOpenCreateVoucher,
    handleEditVoucher,
    handleViewVoucher
  } = props;
  const useStyles = makeStyles(theme => ({
    root: {
      height: "100vh",
      padding: 20,
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        padding: "2em"
      },
      [theme.breakpoints.down("xs")]: {
        padding: 20
      }
    },
    headerSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: "8vh"
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10vh"
      }
    },
    header: {
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42"
    },
    createBtn: {
      borderRadius: 50,
      padding: "0 18px",
      position: "fixed",
      right: 20,
      bottom: 90,
      boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)"
    },
    searchbar: {
      backgroundColor: "#fff"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const stores = useSelector(state => state.mobileDashboard.stores);
  const selectedVoucher = useSelector(
    state => state.mobileDashboard.selectedVoucher
  );
  const vouchers = useSelector(
    state => state.mobileDashboard.voucherListFilter
  );
  const voucherFilters = useSelector(
    state => state.mobileDashboard.voucherFilters
  );
  const [openFilter, setOpenFilter] = React.useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = React.useState(false);
  const [state, setState] = React.useState({
    status: "all",
    voucher_type: "all",
    search: "",
    startDate: new Date().toLocaleDateString("en-US"),
    endDate: new Date().toLocaleDateString("en-US")
  });

  let searchTimeout = 0;
  const onSearch = value => {
    setState({
      ...state,
      search: value
    });
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setFilters({ search: value });
    });
  };

  const setFilters = filters => {
    dispatch(setVoucherFilters(filters));
  };

  const fetch_vouchers = () => {
    dispatch(getVouchers(voucherFilters));
  };

  async function handleDeactivate(value) {
    setOpenDeactivateModal(false);
    let params = {
      ...selectedVoucher,
      is_active: value
    };
    const res = await dispatch(
      editVoucher(params, voucherFilters, stores, selectedVoucher.voucher_code)
    );

    try {
      if (res.status === 200) {
        props.setAlert({
          ...props.alert,
          openAlert: true,
          alertType: "success",
          alertMessage: "Voucher successfully updated!"
        });
      }
    } catch {}
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerSection}>
        <div className={classes.header}>Vouchers</div>
        <IconButton onClick={() => setOpenFilter(true)}>
          <FilterListIcon color="primary" />
        </IconButton>
      </div>
      <div style={{ marginTop: "2vh" }}>
        <OutlinedInput
          className={classes.searchbar}
          fullWidth
          labelWidth={0}
          margin="dense"
          color="secondary"
          placeholder="Type here to search"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          onChange={e => onSearch(e.target.value)}
        />
      </div>
      <div
        style={{
          marginTop: "2vh",
          position: "relative",
          height: "59vh",
          overflowY: "scroll"
        }}
      >
        {vouchers && vouchers.list.length > 0 ? (
          vouchers.list.map(item => (
            <MobileVoucherCard
              item={item}
              handleEditVoucher={handleEditVoucher}
              handleViewVoucher={handleViewVoucher}
              handleDeactivate={handleDeactivate}
              setOpenDeactivateModal={setOpenDeactivateModal}
            />
          ))
        ) : (
          <div style={{ textAlign: "center", fontSize: 13, color: "#54575f" }}>
            No results found
          </div>
        )}
        <Button
          variant="contained"
          disableElevation
          color="primary"
          className={classes.createBtn}
          startIcon={<AddIcon />}
          onClick={() => setOpenCreateVoucher(true)}
        >
          Create
        </Button>
      </div>

      <MobileVoucherFilter
        open={openFilter}
        setOpen={setOpenFilter}
        onChange={setFilters}
        state={state}
        setState={setState}
        fetch_vouchers={fetch_vouchers}
      />

      <DeactivateVoucherModal
        open={openDeactivateModal}
        setOpen={setOpenDeactivateModal}
        handleDeactivate={handleDeactivate}
      />
    </div>
  );
};

export default MobileVouchers;
