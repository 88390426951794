import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles, Container, Typography } from "@material-ui/core";

import logo from "../../../assets/images/sentro_vertical.png";
import Text from "../../../common/text";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { register, verifyCode } from "../registerActions";

const VerificationSuccess = props => {
  let param = props.location.search.split("&");
  let email = param[0].replace("?email=", "");
  let code = param[1].replace("code=", "");
  localStorage.setItem("CURRENT_USER", JSON.stringify({email}))
  React.useEffect(() => {
    dispatch(verifyCode({ code: code, email: email }));
  }, []);

  const VerificationStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60%",
      height: "100vh",
      background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    verification: {
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    box1: {
      marginTop: "35vh",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },
    box2: {
      padding: "10vh 5vh 0 ",
      [theme.breakpoints.down("xs")]: {
        padding: "6vh 5vw"
      }
    },
    successIcon: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        display: "flex"
      }
    }
  }));

  const classes = VerificationStyles();

  const dispatch = useDispatch();
  return (
    <div className={classes.root}>
      <Box className={classes.banner}>
        <Container>
          <Box className={classes.box1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={logo} style={{ width: 150 }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "2vh" }}
                textType="h2"
                content="Create your online shop here."
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#fff",
                  position: "absolute",
                  bottom: 25
                }}
              >
                This works best in the latest Google desktop browser.
              </Typography>
            </div>
          </Box>
        </Container>
      </Box>
      <Box className={classes.verification}>
        <Container>
          <Box className={classes.box2}>
            <Text textType="h3" content="Account Verification" />
            <Box style={{ marginTop: "4vh", display: "flex" }}>
              <div className={classes.successIcon}>
                <CheckCircleIcon style={{ color: "#38b99f", fontSize: 90 }} />
              </div>
              <Typography
                style={{
                  fontSize: 16,
                  padding: 10,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                Validation successful!
                <br />
                Redirecting you now to the dashboard
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default VerificationSuccess;
