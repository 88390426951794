import { lazy } from "react";
import Paths from "./paths";

const routes = [
  // Marketplace
  {
    path: Paths.landing,
    Component: lazy(() => import("../pages/landing_page")),
    exact: true
  },
  {
    path: Paths.demo,
    Component: lazy(() => import("../pages/schedule_demo")),
    exact: true
  },
  {
    path: Paths.sell,
    Component: lazy(() => import("../pages/sell")),
    exact: true
  },
  {
    path: Paths.login,
    Component: lazy(() => import("../pages/login")),
    exact: true
  },
  {
    path: Paths.signup,
    Component: lazy(() => import("../pages/signup")),
    exact: true
  },
  {
    path: Paths.forgot_password,
    Component: lazy(() => import("../pages/forgot_password/forgot_password")),
    exact: true
  },
  {
    path: Paths.input_password,
    Component: lazy(() => import("../pages/forgot_password/input_password")),
    exact: true
  },
  {
    path: Paths.home,
    Component: lazy(() => import("../pages/home")),
    exact: true
  },
  {
    path: Paths.categories,
    Component: lazy(() => import("../pages/categories")),
    exact: true
  },
  {
    path: Paths.products,
    Component: lazy(() => import("../pages/products")),
    exact: true
  },
  {
    path: Paths.merchant_directory,
    Component: lazy(() => import("../pages/merchant_directory")),
    exact: true
  },
  {
    path: Paths.about,
    Component: lazy(() => import("../pages/about")),
    exact: true
  },

  {
    path: Paths.product,
    Component: lazy(() => import("../pages/product")),
    exact: true
  },
  {
    path: Paths.merchant_application,
    Component: lazy(() => import("../pages/merchant_application")),
    exact: true
  },
  {
    path: Paths.cart,
    Component: lazy(() => import("../pages/cart")),
    exact: true
  },
  {
    path: Paths.customer_details,
    Component: lazy(() => import("../pages/customer_details")),
    exact: true
  },
  {
    path: Paths.marketplace_delivery,
    Component: lazy(() => import("../pages/delivery")),
    exact: true
  },
  {
    path: Paths.marketplace_payment,
    Component: lazy(() => import("../pages/payment")),
    exact: true
  },
  {
    path: Paths.marketplace_checkout,
    Component: lazy(() => import("../pages/checkout")),
    exact: true
  },
  {
    path: Paths.wishlist,
    Component: lazy(() => import("../pages/wishlist/wishlist")),
    exact: true
  },
  {
    path: Paths.create_wishlist,
    Component: lazy(() => import("../pages/wishlist/create_wishlist")),
    exact: true
  },
  {
    path: Paths.public_wishlists,
    Component: lazy(() => import("../pages/wishlist/public_wishlists")),
    exact: true
  },
  {
    path: Paths.wishlist_detailed,
    Component: lazy(() => import("../pages/wishlist/wishlist_details")),
    exact: true
  },
  //   Dashboard
  {
    path: Paths.admin_login,
    Component: lazy(() => import("../pages/admin_login")),
    exact: true
  },
  {
    path: Paths.admin_forgot_password,
    Component: lazy(() =>
      import("../pages/dashboard/forgot_password/forgot_password")
    ),
    exact: true
  },
  {
    path: Paths.dashboard,
    Component: lazy(() => import("../pages/dashboard/home")),
    exact: true
  },
  {
    path: Paths.customize,
    Component: lazy(() => import("../pages/dashboard/customize")),
    exact: true
  },
  {
    path: Paths.merchants,
    Component: lazy(() => import("../pages/dashboard/merchants")),
    exact: true
  },
  {
    path: Paths.manage_marketplace,
    Component: lazy(() => import("../pages/dashboard/manage_marketplace")),
    exact: true
  },
  {
    path: Paths.buyers,
    Component: lazy(() => import("../pages/dashboard/buyers")),
    exact: true
  },
  {
    path: Paths.edit_profile,
    Component: lazy(() => import("../pages/dashboard/edit_profile")),
    exact: true
  },
  {
    path: Paths.custom_domain,
    Component: lazy(() => import("../pages/dashboard/domain")),
    exact: true
  },
  {
    path: Paths.analytics,
    Component: lazy(() => import("../pages/dashboard/analytics")),
    exact: true
  },
  // Buyer Dashboard
  {
    path: Paths.buyer_dashboard,
    Component: lazy(() => import("../pages/buyer_dashboard/my_cart")),
    exact: true
  },
  {
    path: Paths.buyer_purchased,
    Component: lazy(() => import("../pages/buyer_dashboard/purchased")),
    exact: true
  },
  {
    path: Paths.cart_view_order,
    Component: lazy(() => import("../pages/buyer_dashboard/cartViewOrder")),
    exact: true
  },
  {
    path: Paths.purchased_view_order,
    Component: lazy(() =>
      import("../pages/buyer_dashboard/purchasedViewOrder")
    ),
    exact: true
  },
  {
    path: Paths.buyer_delivery_details,
    Component: lazy(() => import("../pages/buyer_dashboard/customer_details")),
    exact: true
  },
  {
    path: Paths.delivery,
    Component: lazy(() => import("../pages/buyer_dashboard/delivery")),
    exact: true
  },
  {
    path: Paths.payment,
    Component: lazy(() => import("../pages/buyer_dashboard/payment")),
    exact: true
  },
  {
    path: Paths.checkout,
    Component: lazy(() => import("../pages/buyer_dashboard/checkout")),
    exact: true
  },
  {
    path: Paths.buyer_dashboard_wishlist,
    Component: lazy(() => import("../pages/buyer_dashboard/wishlist")),
    exact: true
  },
  {
    path: Paths.merchant,
    Component: lazy(() => import("../pages/merchant")),
    exact: true
  }

  // Add pages here
];
export default routes;
