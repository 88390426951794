import React from "react";
import Cart from "./components/Cart";

import "./App.css";

const CartPage = props => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Cart />;
};

export default CartPage;
