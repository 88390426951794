import AuthServices from "../services/authServices";
import { setCookie } from "../../../common/template/components/cookiesModal/index";

export const AuthActionTypes = {
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  SET_MERCHANT_APPLICATION_LOGIN_LOADING:
    "SET_MERCHANT_APPLICATION_LOGIN_LOADING",
  SET_MERCHANT_APPLICATION_PREV_ACCOUNT:
    "SET_MERCHANT_APPLICATION_PREV_ACCOUNT",

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILED: "REGISTER_FAILED"
};

class AuthActions {
  static setMerchantPrevAccount(val) {
    return async dispatch => {
      dispatch({
        type: AuthActionTypes.SET_MERCHANT_APPLICATION_PREV_ACCOUNT,
        payload: val
      });
    };
  }
  static authenticate(values) {
    return async dispatch => {
      try {
        dispatch({
          type: AuthActionTypes.SET_MERCHANT_APPLICATION_LOGIN_LOADING,
          payload: true
        });
        let data = await AuthServices.authenticate(values);
        const stores = await AuthServices.getStores(data.token, data.user.id);
        if (stores.length) {
          const context = {
            site_name: stores[0].site,
            store_uuid: stores[0].uuid,
            name: stores[0].name,
            email: data.user.email,
            token: data.token
          };
          dispatch({
            type: AuthActionTypes.SET_MERCHANT_APPLICATION_PREV_ACCOUNT,
            payload: context
          });
          setCookie("merchant_application", JSON.stringify(context), 7);
        } else {
          dispatch({
            type: AuthActionTypes.AUTHENTICATION_FAILED,
            payload: "You need to create your store first."
          });
        }
        dispatch({
          type: AuthActionTypes.SET_MERCHANT_APPLICATION_LOGIN_LOADING,
          payload: false
        });
      } catch (error) {
        dispatch({
          type: AuthActionTypes.AUTHENTICATION_FAILED,
          payload: "Unable to log in with provided credentials."
        });
      }
    };
  }

  static fb_sign_up(value) {
    return async dispatch => {
      try {
        dispatch({
          type: AuthActionTypes.SET_MERCHANT_APPLICATION_LOGIN_LOADING,
          payload: true
        });
        let data = await AuthServices.fbSignup(value);
        const stores = await AuthServices.getStores(data.token, data.user.id);
        if (stores.length) {
          const context = {
            site_name: stores[0].site,
            store_uuid: stores[0].uuid,
            name: stores[0].name,
            email: data.user.email,
            token: data.token
          };
          dispatch({
            type: AuthActionTypes.SET_MERCHANT_APPLICATION_PREV_ACCOUNT,
            payload: context
          });
          setCookie("merchant_application", JSON.stringify(context), 7);
        } else {
          dispatch({
            type: AuthActionTypes.AUTHENTICATION_FAILED,
            payload: "You need to create your store first."
          });
        }
        dispatch({
          type: AuthActionTypes.SET_MERCHANT_APPLICATION_LOGIN_LOADING,
          payload: false
        });
      } catch (error) {
        dispatch({
          type: AuthActionTypes.AUTHENTICATION_FAILED,
          payload: "Unable to log in with provided credentials."
        });
      }
    };
  }

  static applyMerchant(enterprise, token, params) {
    return async dispatch => {
      try {
        let data = await AuthServices.applyMerchant(enterprise, token, params);
        if (data) {
          dispatch({
            type: AuthActionTypes.AUTHENTICATION_FAILED,
            payload: ""
          });
        }
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({
            type: AuthActionTypes.SET_MERCHANT_APPLICATION_PREV_ACCOUNT,
            payload: ""
          });
          setCookie("merchant_application", "", 0);
        } else if (error.response.status === 409) {
          dispatch({
            type: AuthActionTypes.AUTHENTICATION_FAILED,
            payload: ["requested", "pending"].includes(error.response.data) ? 
              "You already have a pending application.": 
              "You already have a merchant account."
          });
        } else {
          dispatch({
            type: AuthActionTypes.AUTHENTICATION_FAILED,
            payload: "Something went wrong. Please try again."
          });
        }
      }
    };
  }
}

export default AuthActions;
