import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import LensIcon from '@material-ui/icons/Lens';
import Rating from '@material-ui/lab/Rating';
import history from "../../../store/history";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { animateScroll as scroll } from "react-scroll";

const useStyles = theme => ({
  root: {
    position: "fixed",
    width: "100%",
    backgroundColor: "white",
    top: 0,
    left: 0,
    padding: "1.5em 4em 0",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    zIndex: 2,
    transition: "all 0.25s ease-in-out",
    boxShadow: "none",
    borderBottom: "solid 1px white"
  },
  smallRoot: {
    "& #description": {
      maxHeight: 0,
      overflow: "hidden",
      opacity: 0
    },
    "& #footer": {
      maxHeight: 0,
      paddingTop: 0,
      opacity: 0
    },
    "& #logo": {
      height: 90,
      width: 90,
    },
    "& #tabs": {
      marginTop: 0
    },
    borderBottom: "solid 1px rgba(0, 0, 0, 0.1)",
    padding: "1em 4em 0",
    boxShadow: "0 2px 15px rgba(0, 0, 0, 0.2)"
  },
  backDivWrapper: {
    display: "flex",
    "& > span": {
      marginLeft: "1em",
      fontWeight: 600
    },
    color: "#F97663 !important",
    alignItems: "center",
    cursor: "pointer",
    width: 100
  },
  appHeader: {
    display: "flex",
    marginTop: "1em"
  },
  logo: {
    height: "auto",
    height: 110,
    width: 110,
    backgroundColor: "rgba(0,0,0,0.02)",
    border: "1px solid #D1D5DD",
    transition: "all 0.35s ease-in-out",
  },
  infoWrapper: {
    paddingLeft: "1.5em",
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  titleAndButtonWrapper: {
    display: "flex",
    width: "100%"
  },
  title: {
    color: "#2B2D33",
    fontSize: 28,
    marginBottom: 0,
    marginRight: "auto",
    fontWeight: 600
  },
  button: {
    padding: "0 3em",
    height: 35,
    fontSize: 12,
    fontWeight: 600
  },
  description: {
    transition: "all 0.35s ease-in-out",
    overflow: "hidden",
    maxHeight: '500px',
    opacity: 1
  },
  footerWrapper: {
    paddingTop: "1em",
    maxHeight: "500px",
    overflow: "hidden",
    display: "flex",
    fontSize: 12,
    color: "#2b2d337d",
    fontWeight: 400,
    alignItems: "center",
    marginTop: 'auto',
    opacity: 1,
    transition: "all 0.35s ease-in-out",
  },
  footerText: {
    marginBottom: 0
  },
  footerDotDivider: {
    fontSize: 5,
    color: "#2b2d337d",
    margin: "0 5px"
  },
  ratingDiv: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  tabs: {
    marginTop: "0.5em",
    transition: "all 0.35s ease-in-out",
  }
});

class AppDetailedHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabActive: 0,
      isInTopPosition: true
    };
    this.tabChange = this.tabChange.bind(this)
    this.scroll = this.scroll.bind(this)
  }

  getElemDistance = ( elem ) => {
    var location = 0;
    if (elem.offsetParent) {
        do {
            location += elem.offsetTop;
            elem = elem.offsetParent;
        } while (elem);
    }
    return location >= 0 ? location : 0;
  };

  tabChange = (event, tabActive) => {
    this.setState({ tabActive })
    const featuresDiv = document.getElementById('appsection') || 
    document.getElementById('features') || document.getElementById('packages');
    const reviewsDiv = document.getElementById('reviews');
    try {
      switch(tabActive){
        case(0):
          scroll.scrollTo(0)
          break;
        case(1):
          scroll.scrollTo(this.getElemDistance(featuresDiv)-135)
          break;
        case(2):
          scroll.scrollTo(this.getElemDistance(reviewsDiv)-135)
          break;
      }
    } catch {}
  }

  componentDidMount = () => {
    document.addEventListener('scroll', this.scroll);
  }

  componentWillReceiveProps(newProps){
    this.setState({tabActive: newProps.activeHeaderTab})
  }

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.scroll);
  }

  scroll = () => {
    this.setState({ isInTopPosition : !window.pageYOffset && true})
  };
  
  render (){
    const { classes, data } = this.props;
    const { tabActive, isInTopPosition } = this.state;
    return (
      <div className={`${classes.root} ${!isInTopPosition && classes.smallRoot}`}>
        <div className={classes.backDivWrapper} onClick={() => history.goBack()}>
          <ArrowBackIcon />
          <span>BACK</span>
        </div>
        <div className={classes.appHeader}>
          <img id="logo" src={data.logo} className={classes.logo} />
          <div className={classes.infoWrapper}>
            <div className={classes.titleAndButtonWrapper}>
              <p className={classes.title}>{data.name}</p>
              <Button
                id="view"
                color="primary"
                disableElevation 
                className={classes.button}
                variant="contained"
                disableElevation={true}
                onClick={() => {
                  const link =  data.variants ? `/marketplace/${data.id}/packages` : `marketplace/${data.id}/checkout`
                  history.push(link)
                }}
              >
                {
                 data.variants ? 
                 `Select Package` : `Purchase`
                }
              </Button>
            </div>
            <div  className={classes.description} id="description">
              {data.short_description}
            </div>
            <div className={classes.ratingDiv} style={{ height: 18}}>
              {/* {
                (data && !this.props.loading) &&
                <Rating name="size-medium" defaultValue={0} value={parseFloat(data.general_rating)} precision={0.1} readOnly />
              } */}
            </div>
            <div  className={classes.footerWrapper} id="footer">
              <p className={classes.footerText}>
                {
                  data && data.category_names.map((category, index) => {
                    return data.category_names.length-1 > index ? category + ", " : category
                  })
                }
              </p>
              <LensIcon className={classes.footerDotDivider} />
              <p className={classes.footerText}>{data.supported_devices}</p>
              <LensIcon className={classes.footerDotDivider} />
              <p className={classes.footerText}>{data.distributor}</p>
            </div>
            <div  className={classes.tabs} id="tabs">
              <Tabs
                value={tabActive}
                onChange={this.tabChange}
                indicatorColor="primary"
                textColor="secondary"
              >
                <Tab label="OVERVIEW" />
                <Tab label="FEATURES" />
                {/* <Tab label="REVIEWS" /> */}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({ marketplace }) => ({ 
    activeHeaderTab: marketplace.activeHeaderTab
  });
export default connect(mapStateToProps)(withStyles(useStyles)(AppDetailedHeader));
