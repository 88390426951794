import React from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import StoreLoading from "./Loading";
import Header from "./AppBar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import StoreActions from "./StoreActions";
import history from "../../../../store/history";
import {
  TextField,
  Button,
  InputAdornment,
  Link,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import validations from "../../validations";
import SocialButton from "../../../social/socialButton";
import apiConfig from "../../../../common/apiConfig";
import FacebookIcon from "@material-ui/icons/Facebook";
import StoreAlertBox from "./StoreAlertBox";
import Path from "../../../../Paths";
import StoreServices from "./StoreServices";
import VerifyEmail from "./signup/verifyEmail";
import { ThemeProvider } from "@material-ui/styles";
export default function Signup(props) {
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/signup", "");
  const storeName = param.replace("/shop/", "");
  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: 80,
      backgroundColor: "#fafafa",
      width: "100%"
    },
    header: {
      marginTop: "3vh",
      fontSize: 28,
      fontWeight: 600,
      color: "#2b2d32"
    },
    content: {
      padding: "2em 32vw",
      [theme.breakpoints.up("xl")]: {
        padding: "2em 38vw"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "1em 6vw"
      }
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      color: "rgba(43,45,50, 0.8)"
    },
    adornedEnd: {
      paddingRight: 0
    },
    input: {
      backgroundColor: "#fff"
    },
    btnFacebook: {
      backgroundColor: "#3B5998",
      "&:hover": {
        backgroundColor: "#224080"
      },
      color: "#fff",
      width: "100%",
      height: 48,
      fontSize: 16,
      fontWeight: 600
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const storeInfo = useSelector(state => state.store.store);
  const loading = useSelector(state => state.store.loading);
  const [showVerify, setShowVerify] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState("");
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [hasErrors, setHasErrors] = React.useState(true);
  const [checked, setChecked] = React.useState(false);
  const [values, setValues] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    password: "",
    is_seller: false
  });
  const [showAlert, setShowAlert] = React.useState({
    open: false,
    alertType: "error",
    alertMessage: ""
  });
  const [error, setError] = React.useState({
    first_name_error: "",
    last_name_error: "",
    email_error: "",
    mobile_number_error: "",
    password_error: ""
  });

  const handleFbSocialLogin = user => {
    console.log(user);
  };

  async function handleSignup() {
    let checkEmail = await StoreServices.uniqueEmail(values.email);

    if (checkEmail.data !== "") {
      setError({
        ...error,
        email_error: "Email already exist!"
      });
    } else {
      let res = await dispatch(StoreActions.createBuyerAccount(values));
      try {
        if (res.status === 201) {
          setShowAlert({
            open: false,
            alertType: "success",
            alertMessage: ""
          });
          setShowVerify(true);
          window.scrollTo(0, 0);
        }
      } catch (e) {
        console.log(e);
        setShowAlert({
          open: true,
          alertType: "error",
          alertMessage:
            "Oops! There was an error processing your request. Please try again."
        });
      }
    }
  }

  React.useEffect(() => {
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(storeName));
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (storeInfo.is_published == false) {
      history.push("/not-found");
    }
  });

  React.useEffect(() => {
    for (let entry in error) {
      if (error[entry].length > 0) {
        setHasErrors(true);
        return;
      }
    }
    setHasErrors(false);
  }, [error]);

  React.useEffect(() => {
    setIsFormEnough(
      values.first_name.length > 0 &&
        values.last_name.length > 0 &&
        values.email.length > 0 &&
        values.mobile_number.length > 0 &&
        values.password.length > 8
    );
  });

  const store_theme = useSelector(state => state.store.store.color);

  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold"
      },
      fontFamily: ["Inter", "sans-serif"]
    }
  });
  return (
    <div>
      <ThemeProvider theme={store_generated_theme}>
        {storeInfo && storeInfo.font.css_url ? (
          <link href={storeInfo.font.css_url} rel="stylesheet"></link>
        ) : (
          ""
        )}
        <StoreLoading open={loading} />
        <div>
          <Header isPreview={false} storeInfo={storeInfo} />
          <div className={classes.root}>
            {showVerify ? (
              <VerifyEmail showAlert={showAlert} setShowAlert={setShowAlert} />
            ) : (
              <div className={classes.content}>
                <Button
                  style={{ color: "#54575f" }}
                  startIcon={<ArrowBackIcon style={{ color: "#54575f" }} />}
                  onClick={() => history.push(`/shop/${storeName}`)}
                >
                  Go back to shopping
                </Button>
                <div className={classes.header}>Create an account</div>
                <div className="my-2">
                  <StoreAlertBox alert={showAlert} />
                </div>
                <div className="mt-3">
                  <div>
                    <div className={classes.label}>First Name</div>
                    <TextField
                      id="first_name"
                      margin="dense"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: 0 }}
                      inputProps={{ maxLength: 30 }}
                      value={values.first_name}
                      error={error.first_name_error}
                      helperText={error.first_name_error}
                      onChange={e => {
                        let input = e.target.value;
                        setValues({ ...values, first_name: input });
                        if (input === "") {
                          setError({
                            ...error,
                            first_name_error: "This field is required."
                          });
                        } else {
                          setError({ ...error, first_name_error: "" });
                        }
                      }}
                      onBlur={e => {
                        let input = e.target.value;
                        if (input === "") {
                          setError({
                            ...error,
                            first_name_error: "This field is required."
                          });
                        } else {
                          setError({ ...error, first_name_error: "" });
                        }
                      }}
                      InputProps={{
                        className: classes.input
                      }}
                    />
                  </div>

                  <div className="mt-3">
                    <div className={classes.label}>Last Name</div>

                    <TextField
                      id="last_name"
                      margin="dense"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: 0 }}
                      inputProps={{ maxLength: 30 }}
                      value={values.last_name}
                      error={error.last_name_error}
                      helperText={error.last_name_error}
                      onChange={e => {
                        let input = e.target.value;
                        setValues({ ...values, last_name: input });
                        if (input === "") {
                          setError({
                            ...error,
                            last_name_error: "This field is required."
                          });
                        } else {
                          setError({ ...error, last_name_error: "" });
                        }
                      }}
                      onBlur={e => {
                        let input = e.target.value;
                        if (input === "") {
                          setError({
                            ...error,
                            last_name_error: "This field is required."
                          });
                        } else {
                          setError({ ...error, last_name_error: "" });
                        }
                      }}
                      InputProps={{
                        className: classes.input
                      }}
                    />
                  </div>
                  <div className="mt-3">
                    <div className={classes.label}>Email Address</div>

                    <TextField
                      id="email"
                      margin="dense"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: 0 }}
                      value={values.email}
                      error={error.email_error}
                      helperText={error.email_error}
                      onChange={e => {
                        let input = e.target.value;
                        setValues({ ...values, email: input });
                        if (input === "") {
                          setError({
                            ...error,
                            email_error: "This field is required."
                          });
                        } else if (
                          validations.email(input) ||
                          validations.verifyEmail(input)
                        ) {
                          setError({
                            ...error,
                            email_error: "Please enter a valid email address"
                          });
                        } else if (validations.noCaps(input)) {
                          setError({
                            ...error,
                            email_error: "Uppercase is not allowed"
                          });
                        } else {
                          setError({ ...error, email_error: "" });
                        }
                      }}
                      onBlur={e => {
                        let input = e.target.value;
                        if (input === "") {
                          setError({
                            ...error,
                            email_error: "This field is required."
                          });
                        } else if (
                          validations.email(input) ||
                          validations.verifyEmail(input)
                        ) {
                          setError({
                            ...error,
                            email_error: "Please enter a valid email address"
                          });
                        } else if (validations.noCaps(input)) {
                          setError({
                            ...error,
                            email_error: "Uppercase is not allowed"
                          });
                        } else {
                          setError({ ...error, email_error: "" });
                        }
                      }}
                      InputProps={{
                        className: classes.input
                      }}
                    />
                  </div>

                  <div className="mt-3">
                    <div className={classes.label}>Mobile Number</div>

                    <TextField
                      id="mobile"
                      margin="dense"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: 0 }}
                      inputProps={{ maxLength: 11 }}
                      value={values.mobile_number}
                      error={error.mobile_number_error}
                      helperText={error.mobile_number_error}
                      onChange={e => {
                        let input = e.target.value.replace(/[^0-9]/g, "");
                        setValues({ ...values, mobile_number: input });
                        if (input === "") {
                          setError({
                            ...error,
                            mobile_number_error: "This field is required."
                          });
                        } else {
                          setError({ ...error, mobile_number_error: "" });
                        }
                      }}
                      onBlur={e => {
                        let input = e.target.value;
                        if (input === "") {
                          setError({
                            ...error,
                            mobile_number_error: "This field is required."
                          });
                        } else if (input.length < 11) {
                          setError({
                            ...error,
                            mobile_number_error:
                              "Please enter a valid 11-digit mobile number."
                          });
                        } else if (input.charAt(0) != 0) {
                          setError({
                            ...error,
                            mobile_number_error:
                              "You have entered an invalid mobile number"
                          });
                        } else if (input.charAt(1) != 9) {
                          setError({
                            ...error,
                            mobile_number_error:
                              "You have entered an invalid mobile number"
                          });
                        } else {
                          setError({ ...error, emailError: "" });
                        }
                      }}
                      InputProps={{
                        className: classes.input
                      }}
                    />
                  </div>
                  <div className="mt-3">
                    <div className={classes.label}>Password</div>

                    <TextField
                      id="password"
                      margin="dense"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: 0 }}
                      value={values.password}
                      error={error.password_error}
                      helperText={error.password_error}
                      type={showPassword ? "text" : "password"}
                      onChange={e => {
                        let input = e.target.value;
                        setValues({ ...values, password: input });
                        if (input === "") {
                          setError({
                            ...error,
                            password_error: "This field is required."
                          });
                        } else {
                          setError({ ...error, password_error: "" });
                        }
                      }}
                      onBlur={e => {
                        let input = e.target.value;
                        if (input === "") {
                          setError({
                            ...error,
                            password_error: "This field is required."
                          });
                        } else if (
                          !input.match(
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
                          )
                        ) {
                          setError({
                            ...error,
                            password_error:
                              "Please have at least 8 characters with a mix of numbers, letters and symbols."
                          });
                        } else {
                          setError({ ...error, password_error: "" });
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              color="secondary"
                              style={{ fontSize: 10, height: 40 }}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? "HIDE" : "SHOW"}
                            </Button>
                          </InputAdornment>
                        ),
                        classes: {
                          adornedEnd: classes.adornedEnd
                        },
                        className: classes.input
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "3vh" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => setChecked(!checked)}
                          style={{ color: "#54575f" }}
                        />
                      }
                      label={
                        <div style={{ fontSize: 12, color: "#54575f" }}>
                          By signing up, I agree with the{" "}
                          <span
                            style={{ fontWeight: 600, color: "#f97663" }}
                            onClick={() => window.open(Path.TNC)}
                          >
                            Terms and Conditions
                          </span>
                        </div>
                      }
                    />
                  </div>
                  <div className="mt-3">
                    <Button
                      id="signup_btn"
                      disableElevation
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={hasErrors || !isFormEnough || !checked}
                      onClick={handleSignup}
                    >
                      Sign up
                    </Button>
                    {/* <div
                      className="my-3"
                      style={{ textAlign: "center", fontSize: 12 }}
                    >
                      or
                    </div>
                    <SocialButton
                      id="fb_login"
                      provider="facebook"
                      appId={apiConfig.fbID}
                      onLoginSuccess={handleFbSocialLogin}
                      onLoginFailure={error => console.log(error)}
                      className={classes.btnFacebook}
                      startIcon={<FacebookIcon />}
                      size="large"
                    >
                      Sign up with Facebook
                    </SocialButton> */}
                  </div>
                  <div className="my-5" style={{ textAlign: "center" }}>
                    <div style={{ fontSize: 12 }}>
                      Already have an account?{" "}
                      <Link
                        style={{ color: "#54575f" }}
                        onClick={() => {
                          history.push(`/shop/${storeName}/login`);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Log in
                      </Link>
                      .
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer store={storeInfo} />
        </div>
      </ThemeProvider>
    </div>
  );
}
