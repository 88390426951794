import MarketplaceServices from "../services/marketplaceServices";
import ProductActions from "./productActions";
import { showAlert } from "../../../common/commonActions";
import history from "../../../../store/history";
import WishlistActions from "./wishlistActions";
import { DashboardActionTypes } from "./dashboardActions";

export const MarketplaceActionTypes = {
  GET_MARKETPLACE: "GET_MARKETPLACE",
  GET_MARKETPLACE_SUCCESS: "GET_MARKETPLACE_SUCCESS",
  GET_GLOBAL_CATEGORY_SUCCESS: "GET_GLOBAL_CATEGORY_SUCCESS",
  GET_MERCHANT_SUCCESS: "GET_MERCHANT_SUCCESS",
  SET_DIRECTORY_CATEGORY: "SET_DIRECTORY_CATEGORY",
  GET_MERCHANTS_SUCCESS: "GET_MERCHANTS_SUCCESS",
  SHOW_ALERT: "SHOW_ALERT",
  HIDE_ALERT: "HIDE_ALERT",
  LOADING_ON: "LOADING_ON",
  LOADING_OFF: "LOADING_OFF",
  CHANGE_PASSWORD_INITIAL_REDUCERS: "CHANGE_PASSWORD_INITIAL_REDUCERS",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  VERIFY_TOKEN: "VERIFY_TOKEN",
  REMOVE_CART_ITEMS: "REMOVE_CART_ITEMS"
};

class MarketplaceActions {
  static getMarketplace(enterprise, wishlistFilterParams = {}) {
    return async dispatch => {
      try {
        dispatch({ type: MarketplaceActionTypes.GET_MARKETPLACE });
        let response = await MarketplaceServices.getMarketplace(enterprise);
        if (response) {
          dispatch({
            type: MarketplaceActionTypes.GET_MARKETPLACE_SUCCESS,
            payload: response
          });
          response = await MarketplaceServices.filteredGlobalCategories(
            enterprise
          );
          dispatch({
            type: MarketplaceActionTypes.GET_GLOBAL_CATEGORY_SUCCESS,
            payload: response
          });
          try {
            const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
            dispatch(
              WishlistActions.getUserWishlistItems(
                enterprise,
                user.id,
                wishlistFilterParams
              )
            );
          } catch (err) {}
        }
        return response;
      } catch (error) {
        if (error.status === 404) {
          console.log("marketplace not found");
        }
      }
    };
  }

  static getMerchant(enterprise, merchant) {
    return async dispatch => {
      try {
        dispatch({ type: MarketplaceActionTypes.GET_MARKETPLACE });
        let response = await MarketplaceServices.getMarketplace(enterprise);
        if (response) {
          dispatch({
            type: MarketplaceActionTypes.GET_MARKETPLACE_SUCCESS,
            payload: response
          });
          response = await MarketplaceServices.getGlobalCategories(enterprise);
          dispatch({
            type: MarketplaceActionTypes.GET_GLOBAL_CATEGORY_SUCCESS,
            payload: response
          });
          //   response = await MarketplaceServices.getMerchants(enterprise);
          //   console.log("merchants", response)
          response = await MarketplaceServices.getMerchant(
            enterprise,
            merchant
          );
          dispatch({
            type: MarketplaceActionTypes.GET_MERCHANT_SUCCESS,
            payload: response
          });
        }
        return response;
      } catch (error) {
        console.log(error);
        // dispatch({
        //   type: ActionTypes.REQUEST_FAILED,
        //   payload: error
        // });
      }
    };
  }

  static getGlobalCategories(enterprise) {
    return async dispatch => {
      try {
        dispatch({ type: MarketplaceActionTypes.GET_MARKETPLACE });
        let response = await MarketplaceServices.getMarketplace(enterprise);
        if (response) {
          dispatch({
            type: MarketplaceActionTypes.GET_MARKETPLACE_SUCCESS,
            payload: response
          });
          response = await MarketplaceServices.filteredGlobalCategories(
            enterprise
          );
          dispatch({
            type: MarketplaceActionTypes.GET_GLOBAL_CATEGORY_SUCCESS,
            payload: response
          });
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    };
  }

  static setSelectedCategory(category) {
    return async dispatch => {
      dispatch({
        type: MarketplaceActionTypes.SET_DIRECTORY_CATEGORY,
        payload: category
      });
    };
  }

  static getMerchants(site, category) {
    return async dispatch => {
      try {
        dispatch({ type: MarketplaceActionTypes.GET_MARKETPLACE });
        let response = await MarketplaceServices.getMerchants(site, category);
        dispatch({
          type: MarketplaceActionTypes.GET_MERCHANTS_SUCCESS,
          payload: response
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    };
  }

  //  SIGNUP
  static signup(site, params) {
    return async dispatch => {
      try {
        dispatch({ type: MarketplaceActionTypes.GET_MARKETPLACE });
        let response = await MarketplaceServices.signup(site, params);
        if (response.data.error) {
          dispatch({
            type: MarketplaceActionTypes.SHOW_ALERT,
            payload: {
              showAlert: true,
              alertType: "error",
              message: "Email already registered."
            }
          });
        } else {
          let data = response.data;
          sessionStorage.setItem("CURRENT_USER", JSON.stringify(data.data));
          sessionStorage.setItem("TOKEN", data.token);
          history.push(`/enterprise/${site}`);
        }
        dispatch({ type: MarketplaceActionTypes.LOADING_OFF });
        return response;
      } catch (error) {
        dispatch({
          type: MarketplaceActionTypes.SHOW_ALERT,
          payload: {
            showAlert: true,
            alertType: "error",
            message: "Something went wrong. Please try again."
          }
        });
      }
    };
  }
  // LOGIN
  static login(site, params) {
    return async dispatch => {
      try {
        dispatch({ type: MarketplaceActionTypes.GET_MARKETPLACE });
        let response = await MarketplaceServices.login(site, params);
        if (response.status === 200) {
          dispatch({ type: MarketplaceActionTypes.HIDE_ALERT });
        }

        return response;
      } catch (error) {
        console.log(error);
        if (error && (error.status === 403 || error.status === 404)) {
          dispatch({
            type: MarketplaceActionTypes.SHOW_ALERT,
            payload: {
              showAlert: true,
              alertType: "error",
              message: "Unable to login with provided credentials."
            }
          });
        } else {
          dispatch({
            type: MarketplaceActionTypes.SHOW_ALERT,
            payload: {
              showAlert: true,
              alertType: "error",
              message: "Something went wrong. Please try again."
            }
          });
        }
      }
    };
  }

  static logout(enterprise, redirectUrl = false) {
    return async dispatch => {
      try {
        dispatch({ type: "USER_LOGOUT" });
        sessionStorage.removeItem("CURRENT_USER");
        sessionStorage.removeItem("TOKEN");
        history.push(redirectUrl || `/enterprise/${enterprise.site_name}`);
        dispatch({ type: MarketplaceActionTypes.REMOVE_CART_ITEMS });
      } catch (error) {
        console.log(error);
      }
    };
  }

  // CHANGE PASSWORD
  static forgotPassword(site, email) {
    return async dispatch => {
      try {
        dispatch({ type: MarketplaceActionTypes.CHANGE_PASSWORD_REQUEST });
        let response = await MarketplaceServices.forgotPassword(site, email);
        dispatch({
          type: MarketplaceActionTypes.CHANGE_PASSWORD,
          payload: response.status
        });
        return response;
      } catch (error) {
        dispatch({
          type: MarketplaceActionTypes.CHANGE_PASSWORD,
          payload: error.status
        });
        console.log(error);
      }
    };
  }

  static changePassword(site, password) {
    return async dispatch => {
      let response;
      try {
        dispatch({ type: MarketplaceActionTypes.CHANGE_PASSWORD_REQUEST });
        response = await MarketplaceServices.changePassword(site, password);
        dispatch({
          type: MarketplaceActionTypes.CHANGE_PASSWORD,
          payload: response.status
        });
        localStorage.removeItem("CHANGE_PASSWORD_EMAIL");
        return response;
      } catch (error) {
        dispatch({
          type: MarketplaceActionTypes.CHANGE_PASSWORD,
          payload: error
        });
        console.log(error);
      }
    };
  }

  static verifyCode(site, token) {
    return async dispatch => {
      try {
        dispatch({ type: MarketplaceActionTypes.CHANGE_PASSWORD_REQUEST });
        let response = await MarketplaceServices.verifyCode(site, token);
        dispatch({
          type: MarketplaceActionTypes.VERIFY_TOKEN,
          payload: "success"
        });
        let data = response.data;
        sessionStorage.setItem("CURRENT_USER", JSON.stringify(data.user));
        sessionStorage.setItem("TOKEN", data.token);

        let existing = sessionStorage.getItem("CURRENT_USER");
        existing = existing ? JSON.parse(existing) : {};
        existing["enterprise"] = site;
        existing["role"] = data.role;
        sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
        return response;
      } catch (error) {
        dispatch({
          type: MarketplaceActionTypes.VERIFY_TOKEN,
          payload: "error"
        });
        console.log(error);
      }
    };
  }

  static hideAlert() {
    return async dispatch => {
      dispatch({ type: MarketplaceActionTypes.HIDE_ALERT });
    };
  }

  static changePasswordInitialReducers() {
    return async dispatch => {
      dispatch({
        type: MarketplaceActionTypes.CHANGE_PASSWORD_INITIAL_REDUCERS
      });
    };
  }

  static scheduleDemo(values) {
    return async dispatch => {
      try {
        dispatch({ type: MarketplaceActionTypes.GET_MARKETPLACE });
        let res = await MarketplaceServices.scheduleDemo(values);
        dispatch({ type: MarketplaceActionTypes.LOADING_OFF });
        return res;
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
      }
    };
  }

  // static fetchApps() {
  // 	return async dispatch => {
  // 		try {
  // 			dispatch({ type: ActionTypes.FETCHING_APPS });
  // 			let response = await Services.fetchApps();
  // 			if (response){
  // 				dispatch({
  // 					type: ActionTypes.FETCHING_APPS_SUCCESS,
  // 					payload: response
  // 				});
  // 			}
  // 			return response
  // 		} catch (error) {
  // 			dispatch({
  // 				type: ActionTypes.REQUEST_FAILED,
  // 				payload: error
  // 			});
  // 		}
  // 	};
  // }

  // static setToCart(item) {
  // 	return async dispatch => {
  // 		dispatch({
  // 			type: ActionTypes.SET_TO_CART,
  // 			payload: [item]
  // 		});
  // 	};
  // }
}

export default MarketplaceActions;
