import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Chip,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  Grid,
  Drawer,
  DialogTitle,
  DialogContent,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch
} from "@material-ui/core";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, useTheme } from "@material-ui/styles";
import deliveryIcon from "../../../../../../assets/images/delivery.png";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GoogleDeliveryField from "../../../../googleAddressForm";
import { useSelector, useDispatch } from "react-redux";
import SentroLoading from "../../../../loading";
import { calculateShipping, bookDelivery } from "../websiteBuilderActions";
import AlertDialogBox from "../common/AlertDialogBox";
import Utils from "../../../../utils";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ backgroundColor: "#fff", marginTop: "8vh" }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  closeBtn: { position: "absolute", left: 15 },
  appBar2: {
    zIndex: 1,
    position: "sticky",
    top: "4em",
    boxShadow: "none"
  },
  tabs: {
    backgroundColor: "#fff"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  icon: {
    width: "6em",
    display: "flex",
    margin: "10px auto"
  },
  header2: {
    fontSize: 14,
    color: "#2b2d33",
    fontWeight: 500
  },
  label2: {
    fontSize: 12,
    color: "#54575f"
  },
  motobox: {
    alignItems: "center",
    display: "flex",
    marginTop: "2vh"
  }
}));

const Sender = props => {
  const { delivery, setDeliveryDetails } = props;
  const [error, setError] = React.useState({
    mobileError: ""
  });
  const classes = useStyles();
  return (
    <div>
      <div>
        <div className={classes.header2}>
          What kind of item/s are you shipping?
        </div>
        <div className={classes.label2}>
          e.g. documents, food, boxed fragile item, groceries.
        </div>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          placeholder="Type here"
          color="secondary"
          inputProps={{ maxLength: 32 }}
          value={delivery.matter}
          onChange={e => setDeliveryDetails("matter", e.target.value)}
        />
      </div>
      <div style={{ marginTop: "3vh" }}>
        <div className={classes.header2}>
          When do you want the package picked up?
        </div>
        <div className={classes.label2}>Date</div>
        <KeyboardDateTimePicker
          ampm={false}
          fullWidth
          disablePast
          margin="dense"
          color="secondary"
          format="dd MMMM yyyy hh:mm"
          inputVariant="outlined"
          style={{ margin: 0, padding: 0 }}
          value={delivery.date_time}
          onChange={e => setDeliveryDetails("date_time", e)}
        />
      </div>
      <div style={{ marginTop: "3vh" }}>
        <div className={classes.header2}>What type of vehicle do you need?</div>
        <RadioGroup
          style={{ display: "flex", flexDirection: "inherit" }}
          value={delivery.vehicle_type}
          onChange={e => setDeliveryDetails("vehicle_type", e.target.value)}
        >
          <FormControlLabel
            value="8"
            control={<Radio color="primary" />}
            label="Motorbike"
          />
          <FormControlLabel
            value="7"
            style={{ marginLeft: 40 }}
            control={<Radio color="primary" />}
            label="Car"
          />
        </RadioGroup>
      </div>

      <div style={{ marginTop: "3vh" }}>
        <div className={classes.header2}>How heavy is the package?</div>
        <RadioGroup
          defaultValue={delivery.vehicle_type === "8" ? "5" : "300"}
          onChange={e => setDeliveryDetails("weight_kg", e.target.value)}
        >
          {delivery.vehicle_type === "8" ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                value="5"
                control={<Radio color="primary" />}
                label="up to 5 kilos"
              />
              <FormControlLabel
                value="10"
                control={<Radio color="primary" />}
                label="up to 10 kilos"
              />
              <FormControlLabel
                value="20"
                control={<Radio color="primary" />}
                label="up to 20 kilos"
              />
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                value="300"
                control={<Radio color="primary" />}
                label="up to 300 kilos"
              />
              <FormControlLabel
                value="600"
                control={<Radio color="primary" />}
                label="up to 600 kilos"
              />
            </div>
          )}
        </RadioGroup>
      </div>

      <div
        className={classes.motobox}
        style={{ display: delivery.vehicle_type === "8" ? "" : "none" }}
      >
        <div className={classes.header2}>Do you require a motobox?</div>
        <Switch
          size="medium"
          checked={delivery.motobox}
          onChange={e => setDeliveryDetails("motobox", !delivery.motobox)}
          name="motobox"
          color="primary"
        />
      </div>

      <div className={classes.motobox}>
        <div className={classes.header2}>
          Do you want to insure your package?
        </div>
        <Switch
          size="medium"
          checked={delivery.insurance}
          onChange={e => setDeliveryDetails("insurance", !delivery.insurance)}
          name="insurance"
          color="primary"
        />
      </div>
      <div className={classes.label2}>
        Items can be insured up to PHP 10,000. Optional
      </div>
      <div style={{ display: delivery.insurance ? "" : "none" }}>
        <div className={classes.label2}>Declared value of the package</div>
        <TextField
          margin="dense"
          color="secondary"
          variant="outlined"
          type="number"
          style={{ width: "7em" }}
          value={delivery.insurance_value}
          onChange={e => setDeliveryDetails("insurance_value", e.target.value)}
        />
      </div>

      <div style={{ marginTop: "3vh" }}>
        <div className={classes.header2}>
          Please provide your contact details
        </div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label2}>Name</div>
          <TextField
            margin="dense"
            fullWidth
            variant="outlined"
            color="secondary"
            inputProps={{ maxLength: 64 }}
            style={{ marginTop: 0 }}
            value={delivery.src_name}
            onChange={e => setDeliveryDetails("src_name", e.target.value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label2}>Mobile Number</div>
          <TextField
            margin="dense"
            fullWidth
            variant="outlined"
            color="secondary"
            style={{ marginTop: 0 }}
            value={delivery.src_mobile}
            inputProps={{ maxLength: 11 }}
            error={error.mobileError}
            helperText={error.mobileError}
            onChange={e => {
              const input = e.target.value.replace(/[^0-9]/g, "");
              setDeliveryDetails("src_mobile", input);
              if (input === "") {
                setError({
                  ...error,
                  mobileError: "This field is required."
                });
              } else if (input.length < 11) {
                setError({
                  ...error,
                  mobileError: "Please enter a valid 11-digit mobile number"
                });
              } else if (input.charAt(0) != 0) {
                setError({
                  ...error,
                  mobileError: "You have entered an invalid mobile number"
                });
              } else if (input.charAt(1) != 9) {
                setError({
                  ...error,
                  mobileError: "You have entered an invalid mobile number"
                });
              } else {
                setError({ ...error, mobileError: "" });
              }
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label2}>Pickup point (via Google Maps)</div>
          <GoogleDeliveryField
            margin="dense"
            initialValue={delivery.src_address}
            onChange={value => {
              setDeliveryDetails("src_address", value.address);
              setDeliveryDetails("src_long", value.lng);
              setDeliveryDetails("src_lat", value.lat);
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label2}>
            Location details (street number, landmark, etc.)
          </div>
          <TextField
            margin="dense"
            fullWidth
            variant="outlined"
            color="secondary"
            style={{ marginTop: 0 }}
            value={delivery.src_location}
            onChange={e => setDeliveryDetails("src_location", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

const Recipient = props => {
  const { delivery, setDeliveryDetails } = props;
  const [error, setError] = React.useState({
    mobileError: ""
  });
  const classes = useStyles();
  return (
    <div>
      <div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label2}>Name</div>
          <TextField
            margin="dense"
            fullWidth
            variant="outlined"
            color="secondary"
            inputProps={{ maxLength: 64 }}
            style={{ marginTop: 0 }}
            value={delivery.delivery_name}
            onChange={e => setDeliveryDetails("delivery_name", e.target.value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label2}>Mobile Number</div>
          <TextField
            margin="dense"
            fullWidth
            variant="outlined"
            color="secondary"
            style={{ marginTop: 0 }}
            inputProps={{ maxLength: 11 }}
            value={delivery.delivery_mobile}
            error={error.mobileError}
            helperText={error.mobileError}
            onChange={e => {
              const input = e.target.value.replace(/[^0-9]/g, "");
              setDeliveryDetails("delivery_mobile", input);
              if (input === "") {
                setError({
                  ...error,
                  mobileError: "This field is required."
                });
              } else if (input.length < 11) {
                setError({
                  ...error,
                  mobileError: "Please enter a valid 11-digit mobile number."
                });
              } else if (input.charAt(0) != 0) {
                setError({
                  ...error,
                  mobileError: "You have entered an invalid mobile number"
                });
              } else if (input.charAt(1) != 9) {
                setError({
                  ...error,
                  mobileError: "You have entered an invalid mobile number"
                });
              } else {
                setError({ ...error, mobileError: "" });
              }
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label2}>Pickup point (via Google Maps)</div>
          <GoogleDeliveryField
            margin="dense"
            initialValue={delivery.delivery_address}
            onChange={value => {
              setDeliveryDetails("delivery_address", value.address);
              setDeliveryDetails("delivery_long", value.lng);
              setDeliveryDetails("delivery_lat", value.lat);
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <div className={classes.label2}>
            Location details (street number, landmark, etc.)
          </div>
          <TextField
            margin="dense"
            fullWidth
            variant="outlined"
            color="secondary"
            style={{ marginTop: 0 }}
            value={delivery.delivery_location}
            onChange={e =>
              setDeliveryDetails("delivery_location", e.target.value)
            }
          />
        </div>
      </div>
    </div>
  );
};

const DeliveryWarning = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle
        style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 20px 20px" }}>
        <div className={classes.form}>
          <img src={deliveryIcon} className={classes.icon} />
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <div
              style={{
                fontSize: 16,
                color: "#2b2d33",
                fontWeight: 600,
                padding: "0 20px"
              }}
            >
              Mr.Speedy can deliver within Metro Manila
            </div>
            <div style={{ marginTop: 15, fontSize: 12 }}>
              Due to the quarantine restrictions in place, Mr.Speedy's delivery
              area coverage will be only within Metro Manila. Thank you for
              understanding.
            </div>
            <div style={{ marginTop: "5vh" }}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                onClick={() => setOpen(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const BookShippingWarning = props => {
  const { open, setOpen, setOpenDeliveryForm, delivery } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle
        style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 20px 20px" }}>
        <div className={classes.form}>
          <img src={deliveryIcon} className={classes.icon} />
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <div
              style={{
                fontSize: 16,
                color: "#2b2d33",
                fontWeight: 600,
                padding: "0 20px"
              }}
            >
              Ready to Book?
            </div>
            <div style={{ marginTop: 15, fontSize: 12, textAlign: "left" }}>
              Deliveries are handled by a third party. Sentro is neither
              responsible nor accountable for the delivery of the items, its
              condition, and other delivery-related concerns. Read{" "}
              <span
                onClick={() => window.open("https://mrspeedy.ph/tc")}
                style={{ color: "#f97663", fontWeight: 600, cursor: "pointer" }}
              >
                {" "}
                Mr.Speedy's Terms and Conditions here
              </span>{" "}
              and{" "}
              <span
                onClick={() =>
                  window.open("https://mrspeedy.ph/privacy-policy")
                }
                style={{ color: "#f97663", fontWeight: 600, cursor: "pointer" }}
              >
                {" "}
                Privacy Policy
              </span>
            </div>
            <div style={{ marginTop: "5vh" }}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                onClick={() => {
                  setOpen(false);
                  dispatch(bookDelivery(delivery));
                  setOpenDeliveryForm(false);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
const DialogTransition = props => <Slide direction="up" {...props} />;

const MobileOrderDeliveryForm = props => {
  const { open, setOpen, delivery, setDelivery } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const order = useSelector(state =>
    state.mobileDashboard ? state.mobileDashboard.selectedOrder : null
  );
  const loading = useSelector(state => state.mobileDashboard.loading);
  const [value, setValue] = React.useState(0);
  const [openBookShipping, setOpenBookShipping] = React.useState(false);
  let alertMessage = useSelector(state => state.mobileDashboard.message);
  let dialogOpen = useSelector(state => state.mobileDashboard.dialogOpen);
  let shippingFee = parseFloat(
    useSelector(state => state.mobileDashboard.shippingFee)
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  function setDeliveryDetails(key, value) {
    setDelivery(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  function computeShipping() {
    dispatch(calculateShipping(delivery));
  }
  function bookShipping() {
    setOpenBookShipping(true);
  }

  const {
    matter,
    src_name,
    src_address,
    src_mobile,
    delivery_name,
    delivery_mobile,
    delivery_address,
    paid
  } = delivery;
  return (
    <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
      <DeliveryWarning />
      <BookShippingWarning
        open={openBookShipping}
        setOpen={setOpenBookShipping}
        setOpenDeliveryForm={setOpen}
        delivery={delivery}
      />
      <SentroLoading open={loading} />
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            className={classes.closeBtn}
            onClick={() => setOpen(false)}
          >
            <CloseIcon color="primary" />
          </IconButton>
          <div className={classes.header}>Order Details</div>
        </Toolbar>
      </AppBar>
      <div style={{ overflowY: "scroll", height: "90vh" }}>
        <AppBar className={classes.appBar2} color="default">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Sender" {...a11yProps(0)} />
            <Tab label="Recipient" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Sender
                delivery={delivery}
                setDeliveryDetails={setDeliveryDetails}
              />
            </MuiPickersUtilsProvider>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Recipient
              delivery={delivery}
              setDeliveryDetails={setDeliveryDetails}
            />
          </TabPanel>
        </SwipeableViews>
      </div>
      <div
        style={{
          padding: "10px 20px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
          border: "1px solid #d1d5dd"
        }}
      >
        <div style={{ justifyContent: "center", display: "flex" }}>
          <div style={{ fontSize: 14, color: "#54575f", fontWeight: 500 }}>
            Delivery fee:
          </div>
          <div
            style={{
              marginLeft: 5,
              fontSize: 14,
              fontWeight: 600,
              color: "#002c42"
            }}
          >
            PHP {Utils.commafy(shippingFee)}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2vh"
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            style={{ height: 40, width: "50%", margin: 5 }}
            onClick={() => computeShipping()}
            disabled={
              matter === "" ||
              src_address === "" ||
              delivery_address === "" ||
              paid === false
                ? true
                : false
            }
          >
            Compute
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            style={{ height: 40, width: "50%", margin: 5 }}
            onClick={() => bookShipping()}
            disabled={
              matter === "" ||
              src_name === "" ||
              src_address === "" ||
              src_mobile === "" ||
              delivery_name === "" ||
              delivery_mobile === "" ||
              delivery_address === "" ||
              paid === false ||
              shippingFee === 0
                ? true
                : false
            }
          >
            Book
          </Button>
        </div>
      </div>
      <AlertDialogBox
        open={dialogOpen}
        alertType={alertMessage ? alertMessage.type : "success"}
        message={alertMessage ? alertMessage.message : ""}
      />
    </Dialog>
  );
};
export default MobileOrderDeliveryForm;
