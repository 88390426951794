import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  useMediaQuery,
  Paper,
  Chip
} from "@material-ui/core";
import moment from "moment";
import Utils from "../../../common/utils";
import OTCchannelLogo from "../../../common/template/components/OTCchannels";
import imgPlaceholder from "../../../../assets/images/imgPlaceholder2.svg";
import PriceDisplay, {
  WishlistItemPriceDisplay
} from "../../../common/template/components/PriceDisplay";
import Skeleton from "@material-ui/lab/Skeleton";
export default function PaymentBankingForm(props) {
  const { storeInfo, checkoutItem, cartitems } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const useStyles = makeStyles(theme => ({
    header: {
      fontWeight: 600,
      fontFamily: "Inter",
      fontSize: 28,
      color: "#2b2d32",
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px"
      }
    },
    details: {
      width: "65%",
      padding: 24,
      height: "fit-content",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: 15
      }
    },
    label: {
      fontSize: 16,
      fontWeight: "normal",
      color: "rgba(43,45,50, 0.8)"
    },
    label2: {
      fontSize: 14,
      fontWeight: "normal",
      color: "rgba(43,45,50, 0.8)"
    },
    labelbold: {
      fontSize: 16,
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#2b2d32"
    },
    labelbold2: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#2b2d32"
    },
    transaction: {
      marginTop: "4vh",
      display: "flex",
      justifyContent: "space-between"
    },
    otcLogo: {
      width: "auto",
      height: 85
    },
    columnItem: {
      border: "none",
      width: "60%",
      fontSize: 16,
      fontFamily: "Inter",
      fontWeight: 600,
      color: "rgba(43,45,50, 0.8)",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 0",
        width: "70%"
      }
    },
    column: {
      border: "none",
      width: "20%",
      fontSize: 16,
      fontFamily: "Inter",
      fontWeight: 600,
      color: "rgba(43,45,50, 0.8)",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 0",
        width: "30%"
      }
    },
    productImage: {
      width: 60,
      height: 60,
      backgroundSize: "cover",
      backgroundPosition: "center",
      [theme.breakpoints.down("sm")]: {
        width: 70,
        height: 70
      }
    },
    itemSection: {
      display: "flex",
      alignItems: "center"
    },
    summary: {
      height: "fit-content",
      marginLeft: "2vw",
      padding: 24,
      width: "35%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: 0
      }
    },
    variantChip: {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 10,
      marginRight: 5
    }
  }));

  const classes = useStyles();
  const voucher = checkoutItem.voucher ? JSON.parse(checkoutItem.voucher) : "";
  const paymentOption = checkoutItem ? checkoutItem.payment_option : "";
  const amount = Utils.commafy(
    parseFloat(checkoutItem.amount) + parseFloat(checkoutItem.other_fees)
  );

  function productImage(item) {
    if (item.gallery && item.gallery.length) {
      return item.gallery[0].image;
    } else if (item.image) {
      return item.image;
    } else {
      return imgPlaceholder;
    }
  }

  function getProduct(product) {
    let params = {
      ...product,
      price: product.unit_price,
      has_variants: product.productSelection ? true : false
    };
    return params;
  }
  return (
    <div style={{ marginTop: "3vh" }}>
      <div className={classes.header}>Payment</div>
      <div style={{ display: isMobile ? "block" : "flex", marginTop: "3vh" }}>
        <Paper className={classes.details}>
          <div className={classes.labelbold}>Payment Instructions</div>
          <div className={classes.label2} style={{ marginTop: "3vh" }}>
            {checkoutItem.bux_uid ? (
              <p>
                Check your email and follow the{" "}
                <strong>Bux Payment instructions</strong>
                {` `}to proceed with the payment. Make sure that the transaction
                number is correct.
              </p>
            ) : (
              <p>
                Check your email and follow the{" "}
                <strong>Payment instructions</strong> to proceed with the
                payment. Make sure that the transaction number is correct.
              </p>
            )}
          </div>
          <div
            style={{
              marginTop: "3vh",
              paddingBottom: 24,
              borderBottom: "1px solid #d1d5dd"
            }}
          >
            <div className={classes.labelbold}>Order Details</div>
          </div>
          {paymentOption === "otc" ? (
            <div className={classes.transaction}>
              <img
                id="OTClogo"
                src={OTCchannelLogo(checkoutItem.channel)}
                className={classes.otcLogo}
                alt="channelLogo"
              />
              <div>
                <img
                  src={checkoutItem.ref_img_url}
                  style={{ height: 75, width: "auto" }}
                  alt="refImg"
                />
                <div
                  className={classes.labelbold2}
                  style={{ textAlign: "center" }}
                >
                  {checkoutItem.ref_code}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={classes.transaction}>
            <div className={classes.label2}>Transaction Date:</div>
            <div className={classes.labelbold2}>
              {props.loading ? (
                <Skeleton variant="text" height={30} width={150} />
              ) : (
                moment(checkoutItem.created_on).format(`MMMM DD, YYYY hh:mm a`)
              )}
            </div>
          </div>

          <div className={classes.transaction}>
            <div className={classes.label2}>Transaction No:</div>
            <div className={classes.labelbold2}>{checkoutItem.ref_id}</div>
          </div>

          <div style={{ marginTop: "4vh" }}>
            <Table style={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnItem} align="left">
                    Items:{" "}
                    {props.loading ? (
                      <Skeleton variant="text" height={25} width={25} />
                    ) : (
                      parseFloat(
                        cartitems.reduce((total, object) => {
                          return (
                            parseFloat(total) + parseFloat(object.quantity)
                          );
                        }, 0)
                      )
                    )}
                  </TableCell>
                  <TableCell className={classes.column} align="center">
                    Quantity
                  </TableCell>
                  <TableCell className={classes.column} align="right">
                    Subtotal
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartitems && cartitems.length > 0
                  ? cartitems.map((row, key) => (
                      <TableRow
                        key={key}
                        style={{ borderBottom: "1px dashed #d1d5dd" }}
                      >
                        <TableCell
                          className={classes.columnItem}
                          component="th"
                          scope="row"
                        >
                          <div className={classes.itemSection}>
                            <div
                              className={classes.productImage}
                              style={{
                                backgroundImage: `url(${productImage(row)})`
                              }}
                            />
                            <div style={{ marginLeft: "2vw" }}>
                              <div className={classes.labelbold2}>
                                {row.name}
                              </div>
                              <div className="d-flex align-items-center my-2">
                                {row.productSelection
                                  ? row.productSelection.variants.map(
                                      variant => (
                                        <Chip
                                          size="small"
                                          label={variant.name}
                                          className={classes.variantChip}
                                        />
                                      )
                                    )
                                  : ""}
                              </div>
                              <WishlistItemPriceDisplay
                                symbol="₱"
                                item={getProduct(row)}
                                productSelection={row.productSelection}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.column} align="center">
                          {row.quantity}
                        </TableCell>
                        <TableCell className={classes.column} align="right">
                          {"₱ "}
                          {Utils.commafy(parseFloat(row.amount))}
                        </TableCell>
                      </TableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
            <div className={classes.transaction}>
              <div className={classes.label2}>Subtotal</div>
              <div className={classes.labelbold2}>
                {props.loading ? (
                  <Skeleton variant="text" height={30} width={100} />
                ) : (
                  `₱ ` + checkoutItem.subtotal
                )}
              </div>
            </div>
            <div className={classes.transaction}>
              <div className={classes.label2}>Delivery Fee:</div>
              <div className={classes.labelbold2}>
                ₱ {checkoutItem.shipping_fee}
              </div>
            </div>

            {voucher ? (
              <div
                className={classes.transaction}
                style={{ alignItems: "center" }}
                id="voucher-discount"
              >
                <div className={classes.label2}>Voucher Applied</div>
                <div className={classes.labelbold2}>
                  - ₱ {Utils.commafy(voucher.voucher_discount)}
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              className={classes.transaction}
              style={{ alignItems: "center" }}
            >
              <div className={classes.label2}>Total</div>
              <div className={classes.header} style={{ padding: 0 }}>
                {props.loading ? (
                  <Skeleton variant="text" height={50} width={120} />
                ) : (
                  `₱ ` + amount
                )}
              </div>
            </div>
          </div>
        </Paper>

        <Paper className={classes.summary}>
          <div className={classes.labelbold}>Customer Details</div>
          <div style={{ marginTop: "3vh" }}>
            <div className={classes.labelbold2}>Name of Recipient:</div>
            <div className={classes.label2}>
              {" "}
              {props.loading ? (
                <Skeleton variant="text" height={25} width={"100%"} />
              ) : (
                checkoutItem.full_name
              )}
            </div>
          </div>
          <div style={{ marginTop: "3vh" }}>
            <div className={classes.labelbold2}>Delivery Address:</div>
            <div className={classes.label2}>
              {" "}
              {props.loading ? (
                <Skeleton variant="text" height={25} width={"100%"} />
              ) : (
                checkoutItem.address
              )}
            </div>
          </div>
          <div style={{ marginTop: "3vh" }}>
            <div className={classes.labelbold2}>Mobile Number:</div>
            <div className={classes.label2}>
              {" "}
              {props.loading ? (
                <Skeleton variant="text" height={25} width={"100%"} />
              ) : (
                checkoutItem.mobile_number
              )}
            </div>
          </div>
          <div style={{ marginTop: "3vh" }}>
            <div className={classes.labelbold2}>Email Address:</div>
            <div className={classes.label2}>
              {" "}
              {props.loading ? (
                <Skeleton variant="text" height={25} width={"100%"} />
              ) : (
                checkoutItem.recipient
              )}
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}
