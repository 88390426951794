import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Chip,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  Grid,
  Drawer
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import SentroLoading from "../../../../loading";
import CheckIcon from "@material-ui/icons/Check";
import Utils from "../../../../utils";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import moment from "moment";
import MobileOrderDeliveryForm from "./MobileOrderDeliveryForm";
import MobileUpdatePayment from "./MobileUpdatePayment";
import AlertDialogBox from "../common/AlertDialogBox";
import MobilePickupDelivery from "./MobilePickupDelivery";
import {
  getOrders,
  cancelOrder,
  cancelDelivery as cancelDeliveryAction
} from "../websiteBuilderActions";
import CancelDeliveryModal, {
  minutesPassed
} from "../../../../../../components/orders/components/cancelDeliveryDialog";
import PriceDisplay from "../../../../template/components/PriceDisplay";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ minHeight: "62vh", backgroundColor: "#fafafa" }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}
const useStyles = makeStyles(theme => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  closeBtn: { position: "absolute", left: 15 },
  refId: {
    fontSize: 14,
    fontWeight: 600,
    color: "#002c42"
  },
  btnSection: {
    marginTop: "2vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  appBar2: {
    zIndex: 1,
    position: "sticky",
    top: "4em",
    boxShadow: "none"
  },
  tabs: {
    backgroundColor: "#fff"
  },
  paper: {
    backgroundColor: "#fff",
    boxShadow: "0 4px 10px rgba(0,0,0,0.25)",
    borderRadius: 5,
    marginBottom: "2vh"
  },
  name: {
    wordWrap: "break-word",
    fontWeight: 600,
    fontSize: 14,
    color: "#2b2d33"
  },
  customerLabel: {
    fontSize: 14,
    color: "#2b2d33"
  },
  customerLabel2: {
    fontWeight: 600,
    fontSize: 14,
    color: "#2b2d33",
    textTransform: "capitalize"
  }
}));

const Items = props => {
  const { order } = props;
  const classes = useStyles();
  const itemCart = order
    ? order["cart"].includes(`"discounted_price":,`)
      ? order["cart"].replace(`"discounted_price":,`, `"discounted_price":"",`)
      : order["cart"]
    : "[]";
  const cart = JSON.parse(itemCart);
  const itemCount = parseFloat(
    cart.reduce((total, object) => {
      return parseFloat(total) + parseFloat(object.quantity);
    }, 0)
  );
  return (
    <div className={classes.paper}>
      <div
        style={{
          padding: 15,
          fontSize: 14,
          fontWeight: "bold",
          color: "#2b2d33",
          borderBottom: "1px solid #d1d5dd"
        }}
      >
        {itemCount + " Item(s)"}
      </div>
      <div>
        {cart.map(e => (
          <div style={{ borderBottom: "1px solid #d1d5dd", padding: 15 }}>
            <Grid container spacing={0}>
              <Grid item xs={2} style={{ fontSize: 14, color: "#2b2d33" }}>
                {e.quantity}
              </Grid>
              <Grid item xs={6}>
                <div style={{ display: "block" }}>
                  <div className={classes.name}>{e.name}</div>
                  <PriceDisplay
                    price={e.unit_price}
                    discounted_price={e.discounted_price}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div style={{ fontSize: 14, color: "#2b2d33" }}>
                  ₱{" "}
                  {Utils.commafy(
                    parseFloat(
                      e.quantity * (e.discounted_price || e.unit_price)
                    )
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

const Customer = props => {
  const { order } = props;
  const classes = useStyles();
  const itemCart = order
    ? order["cart"].includes(`"discounted_price":,`)
      ? order["cart"].replace(`"discounted_price":,`, `"discounted_price":"",`)
      : order["cart"]
    : "[]";
  const cart = JSON.parse(itemCart);
  const itemCount = parseFloat(
    cart.reduce((total, object) => {
      return parseFloat(total) + parseFloat(object.quantity);
    }, 0)
  );
  return (
    <div className={classes.paper}>
      <div style={{ padding: 15 }}>
        <div>
          <div className={classes.customerLabel}>Customer Name:</div>
          <div className={classes.customerLabel2}>{order.full_name}</div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Delivery Address:</div>
          <div className={classes.customerLabel2}>{order.address}</div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Mobile Number:</div>
          <div className={classes.customerLabel2}>{order.mobile_number}</div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Email Address:</div>
          <div style={{ fontSize: 14, color: "#2b2d33", fontWeight: 600 }}>
            {order.recipient}
          </div>
        </div>
      </div>
    </div>
  );
};

const Payment = props => {
  const { order } = props;
  const classes = useStyles();
  const itemCart = order
    ? order["cart"].includes(`"discounted_price":,`)
      ? order["cart"].replace(`"discounted_price":,`, `"discounted_price":"",`)
      : order["cart"]
    : "[]";
  const cart = JSON.parse(itemCart);
  const itemCount = parseFloat(
    cart.reduce((total, object) => {
      return parseFloat(total) + parseFloat(object.quantity);
    }, 0)
  );
  return (
    <div className={classes.paper}>
      <div style={{ padding: 15 }}>
        <div>
          <div className={classes.customerLabel}>Payment Channel:</div>
          <div className={classes.customerLabel2}>
            {order.channel ? order.channel : "--"}
          </div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Transaction No:</div>
          <div className={classes.customerLabel2}>
            {order.ref_id ? order.ref_id : "--"}
          </div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Amount Paid:</div>
          <div className={classes.customerLabel2}>
            {order.amount != null ? Utils.commafy(order.amount) : "--"}
          </div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Payment Date:</div>
          <div style={{ fontSize: 14, color: "#2b2d33", fontWeight: 600 }}>
            {order.paid_date != null
              ? moment(order.paid_date).format("DD MMMM YYYY hh:mmA")
              : "--"}
          </div>
        </div>
      </div>
    </div>
  );
};

const Delivery = props => {
  const { order } = props;
  const classes = useStyles();
  const courier = order ? order.mrspeedy_courier : "";
  let courier_firstName = courier ? courier["name"] : "";
  let courier_middleName = courier ? courier["middlename"] : "";
  let courier_surName = courier ? courier["surname"] : "";
  let rider =
    courier_firstName + " " + courier_middleName + " " + courier_surName;
  function deliveryStatus() {
    let status = order ? order.mrspeedy_status : "";
    if (status === "new" || status === "available") {
      return "Looking for courier";
    } else if (status === "active") {
      return "For pickup";
    } else if (status === "courier_departed") {
      return "OTW to customer";
    } else if (status === "finished") {
      return "Delivered";
    } else if (status === "canceled") {
      return "Canceled";
    }
  }
  return (
    <div className={classes.paper}>
      <div style={{ padding: 15 }}>
        {/* <div>
          <div className={classes.customerLabel}>Tracking Number:</div>
          <div className={classes.customerLabel2}>
            {order.mrspeedy_id ? order.mrspeedy_id : "--"}
          </div>
        </div> */}
        {/* <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Rider:</div>
          <div className={classes.customerLabel2}>
            {deliveryStatus() === "Looking for courier" ? (
              <div style={{ fontSize: 14, fontStyle: "italic", opacity: 0.5 }}>
                Looking for rider
              </div>
            ) : courier ? (
              rider != null ? (
                rider
              ) : (
                "--"
              )
            ) : (
              "--"
            )}
          </div>
        </div> */}
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Contact Number:</div>
          <div className={classes.customerLabel2}>
            {order.phone ? order.phone : "--"}
          </div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Pickup Point:</div>
          <div style={{ fontSize: 14, color: "#2b2d33", fontWeight: 600 }}>
            {order.sender_address ? order.sender_address : "--"}
          </div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Destination Point:</div>
          <div style={{ fontSize: 14, color: "#2b2d33", fontWeight: 600 }}>
            {order.address ? order.address : "--"}
          </div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Delivery Fee:</div>
          <div style={{ fontSize: 14, color: "#2b2d33", fontWeight: 600 }}>
            {order.mrspeedy_delivery_fee ? order.mrspeedy_delivery_fee : "--"}
          </div>
        </div>
        <div style={{ marginTop: "2vh" }}>
          <div className={classes.customerLabel}>Date Delivered:</div>
          <div style={{ fontSize: 14, color: "#2b2d33", fontWeight: 600 }}>
            {order.mrspeedy_delivered != null
              ? moment(order.mrspeedy_delivered).format("DD MMMM YYYY hh:mmA")
              : "--"}
          </div>
        </div>
      </div>
    </div>
  );
};

const DialogTransition = props => <Slide direction="up" {...props} />;
const MobileOrderDetails = props => {
  const { open, setOpen, language } = props;

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.mobileDashboard.loading);
  const selectedOrder = useSelector(
    state => state.mobileDashboard.orders.selectedOrder
  );
  let alertMessage = useSelector(state => state.mobileDashboard.message);
  let dialogOpen = useSelector(state => state.mobileDashboard.dialogOpen);
  let filters = useSelector(state => state.mobileDashboard.orders.filters);
  const [value, setValue] = React.useState(0);
  const [openDelivery, setOpenDelivery] = React.useState(false);
  const [openOptions, setOpenOptions] = React.useState(false);
  const [openUpdatePayment, setOpenUpdatePayment] = React.useState(false);
  const [openUpdateDelivery, setOpenUpdateDelivery] = React.useState(false);
  const delivery_status = selectedOrder ? selectedOrder.mrspeedy_status : "";
  let is_cancelled = selectedOrder ? selectedOrder["is_cancelled"] : "";
  const [delivery, setDelivery] = React.useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  function handleOpenDelivery() {
    setDelivery({
      ...delivery,
      order_id: selectedOrder ? selectedOrder["id"] : "",
      matter: "",
      date_time: moment(new Date()).add("30", "m"),
      vehicle_type: "8",
      weight_kg: "5",
      motobox: true,
      insurance: true,
      insurance_value: 0,
      src_location: selectedOrder ? selectedOrder.sender_address : "",
      src_name: selectedOrder ? selectedOrder.sender_name : "",
      src_long: selectedOrder ? selectedOrder.src_long : 0,
      src_lat: selectedOrder ? selectedOrder.src_lat : 0,
      src_address: "",
      src_mobile: selectedOrder ? selectedOrder.sender_mobile : "",
      delivery_name: selectedOrder ? selectedOrder.full_name : "",
      delivery_long: selectedOrder ? selectedOrder.delivery_long : 0,
      delivery_lat: selectedOrder ? selectedOrder.delivery_lat : 0,
      delivery_mobile: selectedOrder ? selectedOrder.mobile_number : "",
      delivery_address: "",
      delivery_location: selectedOrder ? selectedOrder.address : "",
      paid: selectedOrder ? selectedOrder.paid : false
    });
    setOpenDelivery(true);
  }

  function paymentStatus() {
    let isPaid = selectedOrder ? selectedOrder["paid"] : "";
    let currentDate = moment().format(`MM DD YYYY, HH:mm A`);
    let expiryDate = selectedOrder
      ? moment(selectedOrder["expiry"]).format(`MM DD YYYY, HH:mm A`)
      : "";
    if (is_cancelled) {
      return "Order cancelled";
    } else {
      if (isPaid === true) {
        return "Paid";
      } else if (isPaid === null) {
        return "--";
      } else if (isPaid === false && currentDate > expiryDate) {
        return "Expired";
      } else {
        return "Pending Payment";
      }
    }
  }
  function statusColor(status) {
    if (status === "Paid") {
      return "#18b4a1";
    } else if (status === "--") {
      return "#fff";
    } else if (status === "Expired") {
      return "#e25643";
    } else if (status === "Order cancelled") {
      return "#e25643";
    } else {
      return "#f4c10a";
    }
  }
  function deliveryStatus() {
    let status = delivery_status;
    if (status === "new" || status === "available") {
      return "Looking for courier";
    } else if (status === "active") {
      return "For pickup";
    } else if (status === "courier_departed") {
      return "OTW to customer";
    } else if (status === "finished") {
      return "Delivered";
    } else if (status === "canceled") {
      return "Canceled";
    }
  }
  function deliveryColor(status) {
    if (status === "finished") {
      return "#18b4a1";
    } else if (status === "canceled") {
      return "#e25643";
    } else if (status === null) {
      return "#d1d5d6";
    } else {
      return "#f4c10a";
    }
  }

  function markAsPaidButton() {
    let payment_option = selectedOrder ? selectedOrder["payment_option"] : "";
    if (is_cancelled) {
      return true;
    } else {
      if (payment_option === "otc") {
        return true;
      } else {
        if (paymentStatus() === "Paid") {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  function bookDeliveryBtn() {
    const payment_status = selectedOrder ? selectedOrder.paid : "";
    if (loading) {
      return true;
    } else {
      if (is_cancelled) {
        return true;
      } else {
        if (payment_status === false) {
          return true;
        } else {
          if (delivery_status === null || delivery_status === "canceled") {
            return false;
          } else {
            return true;
          }
        }
      }
    }
  }

  function handleCancelOrder() {
    dispatch(cancelOrder(selectedOrder));
    setOpenOptions(false);
  }

  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);

  const handleCancelDelivery = () => {
    setCancelModalOpen(true);
  };
  const cancelDelivery = () => {
    setCancelModalOpen(false);
    dispatch(cancelDeliveryAction(selectedOrder));
  };
  return (
    <div>
      <CancelDeliveryModal
        open={cancelModalOpen}
        onContinue={cancelDelivery}
        onCancel={() => setCancelModalOpen(false)}
      />
      <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
        <SentroLoading open={loading} />
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.closeBtn}
              onClick={() => {
                setOpen(false);
                setValue(0);
                dispatch(getOrders(filters));
              }}
            >
              <CloseIcon color="primary" />
            </IconButton>
            <div className={classes.header}>Order Details</div>
          </Toolbar>
        </AppBar>
        <div style={{ paddingTop: "8vh" }}>
          <div style={{ padding: 20 }}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div className={classes.refId}>
                  Ref.No.{" "}
                  {loading ? "--" : selectedOrder ? selectedOrder.ref_id : ""}
                </div>
                <div style={{ display: loading ? "none" : "flex" }}>
                  <Chip
                    size="small"
                    label={paymentStatus()}
                    icon={
                      paymentStatus() === "Paid" ? (
                        <CheckIcon style={{ color: "#fff" }} />
                      ) : (
                        ""
                      )
                    }
                    style={{
                      color:
                        paymentStatus() === "Pending Payment"
                          ? "#002c42"
                          : "#fff",
                      backgroundColor: statusColor(paymentStatus())
                    }}
                  />

                  {selectedOrder.delivery_method != null ? (
                    <Chip
                      size="small"
                      label={selectedOrder.delivery_method}
                      style={{
                        marginLeft: 5,
                        color: "#fff",

                        backgroundColor: "#18b4a1"
                      }}
                    />
                  ) : (
                    <Chip
                      icon={
                        delivery === "Delivered" ? (
                          <CheckIcon style={{ color: "#fff" }} />
                        ) : (
                          ""
                        )
                      }
                      size="small"
                      label={deliveryStatus()}
                      style={{
                        display: delivery_status === null ? "none" : "flex",
                        marginLeft: 5,
                        color:
                          delivery_status === "canceled" ||
                          delivery_status === "finished"
                            ? "#fff"
                            : "#2b2d33",
                        backgroundColor: deliveryColor(delivery_status)
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                style={{ fontSize: 14, color: "#0d3d76", fontWeight: "bold" }}
              >
                PHP{" "}
                {loading
                  ? "--"
                  : Utils.commafy(selectedOrder ? selectedOrder["amount"] : 0)}
              </div>
            </div>
            <div className={classes.btnSection}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{ height: 40, fontSize: 12, marginRight: 8 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenUpdatePayment(true)}
                  disabled={loading ? true : markAsPaidButton()}
                >
                  {language === "English" ? "Mark as paid" : "Bayad Na"}
                </Button>
                {selectedOrder ? (
                  selectedOrder.mrspeedy_book_datetime ? (
                    <Button
                      style={{ height: 40, fontSize: 12 }}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleCancelDelivery()}
                      disabled={
                        minutesPassed(selectedOrder.mrspeedy_book_datetime) >
                          30 ||
                        selectedOrder.is_cancelled ||
                        selectedOrder.mrspeedy_status === "canceled"
                      }
                    >
                      Cancel Delivery
                    </Button>
                  ) : (
                    <Button
                      style={{ height: 40, fontSize: 12 }}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenDelivery()}
                      disabled={bookDeliveryBtn()}
                    >
                      {language === "English"
                        ? "Book for delivery"
                        : "Mag-book ng delivery"}
                    </Button>
                  )
                ) : (
                  ""
                )}
              </div>
              <IconButton onClick={() => setOpenOptions(true)}>
                <MoreHorizIcon color="primary" />
              </IconButton>
            </div>
          </div>
          <div>
            <AppBar className={classes.appBar2} color="default">
              <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Items" {...a11yProps(0)} />
                <Tab label="Customer" {...a11yProps(1)} />
                <Tab label="Payment" {...a11yProps(2)} />
                <Tab label="Delivery" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <div>
                  <Items order={selectedOrder} />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Customer order={selectedOrder} />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <Payment order={selectedOrder} />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <Delivery order={selectedOrder} />
                {selectedOrder ? (
                  selectedOrder.mrspeedy_book_datetime ? (
                    <Button
                      className="my-2"
                      fullWidth
                      style={{ height: 40, fontSize: 12 }}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleCancelDelivery()}
                      disabled={
                        minutesPassed(selectedOrder.mrspeedy_book_datetime) >
                          30 || selectedOrder.is_cancelled
                      }
                    >
                      Cancel Delivery
                    </Button>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </TabPanel>
            </SwipeableViews>
          </div>
        </div>
        <Drawer anchor="bottom" open={openOptions}>
          <div>
            <IconButton onClick={() => setOpenOptions(false)}>
              <CloseIcon color="primary" />
            </IconButton>
            <div style={{ padding: "0 20px 20px" }}>
              <div style={{ marginTop: "2vh" }}>
                <Button
                  onClick={() => {
                    setOpenUpdateDelivery(true);
                    setOpenOptions(false);
                  }}
                  disabled={bookDeliveryBtn()}
                  variant="outlined"
                  fullWidth
                  color="primary"
                >
                  {language === "English"
                    ? "For Buyer Pickup/ Own delivery"
                    : "Para sa Buyer Pickup/ Sariling Delivery"}
                </Button>
              </div>
              <div style={{ marginTop: "2vh" }}>
                <Button
                  onClick={() => handleCancelOrder()}
                  variant="outlined"
                  fullWidth
                  color="primary"
                  disabled={
                    is_cancelled ||
                    minutesPassed(selectedOrder.mrspeedy_book_datetime) > 30
                  }
                >
                  {language === "English"
                    ? "Cancel Order"
                    : "Kanselahin ang Order"}
                </Button>
              </div>
              <div style={{ marginTop: "2vh" }}>
                <Button disabled variant="outlined" fullWidth color="primary">
                  Archive Order
                </Button>
              </div>
            </div>
          </div>
        </Drawer>
        <MobileOrderDeliveryForm
          open={openDelivery}
          setOpen={setOpenDelivery}
          delivery={delivery}
          setDelivery={setDelivery}
          language={language}
        />
        <MobileUpdatePayment
          open={openUpdatePayment}
          setOpen={setOpenUpdatePayment}
        />
        <MobilePickupDelivery
          language={language}
          open={openUpdateDelivery}
          setOpen={setOpenUpdateDelivery}
        />
        <AlertDialogBox
          open={dialogOpen}
          alertType={alertMessage ? alertMessage.type : "success"}
          message={alertMessage ? alertMessage.message : ""}
        />
      </Dialog>
    </div>
  );
};
export default MobileOrderDetails;
