import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Grow from '@material-ui/core/Grow';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 14,
    color: "rgba(43, 45, 50, 0.64)",
    cursor: "pointer"
  }
}));

export default function CategoriesHeader(props) {
  const classes = useStyles();
  const history = useHistory();
  const enterprise = useSelector(state => state.enterprise.main);
  const categoriesToShow = enterprise.enterprise.categories.slice(0,6);
  
  const Text = (props) => (
    <p className={`mr-3 ${classes.text}`} onClick={() => history.push(props.link)}>{props.text}</p>
  )
  return (
    categoriesToShow.length ?
    <div className="d-flex justify-content-center">
      {
        categoriesToShow.map((item, index) => <Text text={item.name} key={index}
            link={`/enterprise/${enterprise.enterprise.site_name}/products/?category=${item.name}`} />)
      }
      {
        enterprise.enterprise.categories.length > 6 ? 
        <Text text="Show All" link={`/enterprise/${enterprise.enterprise.site_name}/categories/`} />: ""
      }
    </div>
    : ""
  );
}
