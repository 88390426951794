import React from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  useMediaQuery,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Radio } from "antd";
import {
  bankLogos,
  ewallets,
  otcLogos
} from "../../../websiteBuilder/forms/logoImages";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import bank from "../../../../assets/images/icons/bank.svg";
import gcash from "../../../../assets/images/icons/gcash.svg";
import paymaya from "../../../../assets/images/icons/paymaya.svg";
import paypal from "../../../../assets/images/icons/paypal.svg";
export default function PaymentOptionsForm(props) {
  const { paymentType, setPaymentType, payments } = props;
  const useStyles = makeStyles(theme => ({
    labelbold: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#2b2d32"
    },
    label: {
      fontSize: 14,
      fontFamily: "Inter",
      color: "rgba(43,45,50, 0.8)"
    },
    bux: {
      width: "10vw",
      marginTop: "-4vh",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        height: 70,
        marginTop: "-3vh"
      }
    },
    layout: {
      marginTop: 15,
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start"
      }
    },
    logoStyle1: {
      width: "auto",
      height: 35,
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        height: 35
      }
    },
    logoStyle2: {
      display: "flex",
      maxWidth: 50,
      maxHeight: 50,
      margin: 10,
      [theme.breakpoints.down("xs")]: {
        height: 50,
        maxWidth: 50
      }
    },
    btnStyle: {
      fontSize: 12,
      fontWeight: "normal;",
      width: 160,
      height: 150,
      display: "block",
      margin: 10,
      borderRadius: 10,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: "10px 0",
        fontSize: 16
      }
    }
  }));

  const CustomCheckbox = withStyles({
    root: {
      color: "#1dd28b",
      "&$checked": {
        color: "#1dd28b"
      }
    },
    checked: {}
  })(props => <Checkbox color="default" {...props} />);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selected, setSelected] = React.useState([]);
  function logoImage(value) {
    if (value === "Bank") {
      return bank;
    } else if (value === "GCash") {
      return gcash;
    } else if (value === "PayMaya") {
      return paymaya;
    } else if (value === "PayPal") {
      return paypal;
    }
  }

  function handleOffline(item, key) {
    setSelected(key);
    setPaymentType({
      ...paymentType,
      account:
        item.payment_method === "Bank" ? item.bank_name : item.payment_method
    });
  }

  const handleChange = event => {
    if (event.target.value === "otc") {
      setSelected("");
    } else {
      // dispatch(StoreActions.resetPromo({ loading: true }));
    }
    if (paymentType.paymentOption === "otc") {
      setPaymentType({
        paymentOption: event.target.value,
        account: ""
      });
    } else if (paymentType.paymentOption === "deposit") {
      setPaymentType({
        paymentOption: event.target.value
      });
    }
  };

  return (
    <Paper style={{ marginTop: "5vh", width: isMobile ? "100%" : "65%" }}>
      <div className={classes.labelbold} style={{ padding: 24 }}>
        Payment Options
      </div>
      <Radio.Group
        value={paymentType.paymentOption}
        onChange={handleChange}
        style={{ width: "100%" }}
      >
        <div style={{ backgroundColor: "#f5f5f5", padding: 24 }}>
          <Radio value="otc">Online and Over-the-Counter</Radio>
        </div>
        <div style={{ padding: 24, backgroundColor: "#fff" }}>
          <div className="d-flex">
            <div className={classes.label}>
              Pay through our online and over-the-counter partners. You will be
              asked to select the preferred online bank or establishment on the
              next page. Payment can be accomplished within 24 hours.
            </div>
            <div>
              <div style={{ fontSize: 12, textAlign: "center" }}>
                Powered by
              </div>
              <div className="d-flex justify-content-center">
                <img
                  className={classes.bux}
                  src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/standard_logomark_wordmark_horizontal.png"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "2vh" }}>
            <div className={classes.labelbold}>
              1. Online payment via partner banks:
            </div>
            <div className={classes.layout}>
              {bankLogos.map(item => (
                <img src={item.logo} className={classes.logoStyle1} alt="" />
              ))}
            </div>
          </div>
          <div style={{ marginTop: "2vh" }}>
            <div className={classes.labelbold}>
              2. Cash payment via our partner establishments:
            </div>
            <div className={classes.layout}>
              {otcLogos.map(item => (
                <img src={item.logo} className={classes.logoStyle2} alt="" />
              ))}
            </div>
          </div>
          <div style={{ marginTop: "2vh" }}>
            <div className={classes.labelbold}>3. E-wallets</div>
            <div className={classes.layout}>
              {ewallets.map(item => (
                <img src={item.logo} className={classes.logoStyle1} alt="" />
              ))}
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "#f5f5f5", padding: 24 }}>
          <Radio value="deposit">Mobile Wallet</Radio>
        </div>
        <div
          style={{
            padding: 24,
            display: "flex",
            flexWrap: "wrap",
            borderBottom: "1px solid #d1d5dd"
          }}
        >
          {payments.length
            ? payments.map((item, key) => {
                return (
                  <Button
                    key={key}
                    className={classes.btnStyle}
                    disabled={paymentType.paymentOption === "otc"}
                    variant="outlined"
                    value={item.payment_method}
                    onClick={() => handleOffline(item, key)}
                    style={{
                      border: "2px solid #d1d5dd",
                      boxShadow:
                        key === selected
                          ? "0px 4px 10px rgba(0, 0, 0, 0.25)"
                          : "none",
                      borderColor: key === selected ? "#1dd28b" : "#d1d5dd"
                    }}
                  >
                    <div className={classes.btnWalletSection}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={true}
                          />
                        }
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 10,
                          display: key === selected ? "" : "none"
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <img
                          src={logoImage(item.payment_method)}
                          className={classes.mobileWalletImg}
                          style={{
                            opacity:
                              paymentType.paymentOption === "otc" ? 0.3 : 1
                          }}
                          alt=""
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {item.payment_method === "Bank" ? item.bank_name : ""}
                      </div>
                      {item.payment_method === "Bank"
                        ? ""
                        : item.payment_method}
                    </div>
                  </Button>
                );
              })
            : ""}
        </div>
      </Radio.Group>
    </Paper>
  );
}
