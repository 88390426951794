import { combineReducers } from "redux";
import adminDashboardReducers from "../components/admin/Redux/Reducers";
import authReducers from "../components/auth/authReducers";
import commonReducers from "../components/common/commonReducer";
import regReducers from "../components/register/regReducers";
import dashboardReducers from "../components/dashboard/dashboardReducer";
import webBuilderReducers from "../components/websiteBuilder/websiteBuilderReducers";
import storeReducers from "../components/common/template/components/StoreReducer";
import { reducer as formReducer } from "redux-form";
import orderReducers from "../components/orders/orderReducers";
import productReducers from "../components/productSearch/productReducers";
import forgotPasswordReducers from "../components/forgotPassword/forgotPasswordReducers";
import accountSettingsReducers from "../components/accountSettings/AccountSettingsReducer";
import marketplaceReducers from "../components/marketplace/Redux/Reducers";
import partnerDashboardReducers from "../components/partner/Redux/Reducers";
import mobileDashboardReducers from "../components/common/layout/mobile/webBuilder/websiteBuilderReducers";
import salesAndOrdersReducers from "../components/salesAndOrders/reducers";
import ProxteraReducers from "../components/proxtera/Redux/Reducers";
import paymentReducers from "../components/payments/reducers";
import stockImagesReducers from "../components/stockImages/redux/reducers";
import OTPReducers from "../components/otp/redux/reducers";
import voucherReducers from "../components/vouchers/reducers";
import enterpriseReducers from "../components/enterprise/redux/reducers";
import deliverySettingsReducer from "../components/delivery_settings/redux/reducers";
import buyerReducers from "../components/dashboard/buyerDashboard/redux/reducers";
import subscriptionReducers from "../components/subscription/redux/reducers";
import mediaReducers from "../components/mediaLibrary/redux/reducers";
import QuickOrderReducers from "../components/quickOrder/redux/reducers";
import NFTReducers from "../components/common/template/nft/redux/reducers";
const rootReducer = combineReducers({
  login: authReducers,
  common: commonReducers,
  signUp: regReducers,
  forgotPassword: forgotPasswordReducers,
  products: productReducers,
  webBuilder: webBuilderReducers,
  dashboard: dashboardReducers,
  store: storeReducers,
  form: formReducer,
  adminDashboard: adminDashboardReducers,
  user: accountSettingsReducers,
  order: orderReducers,
  otp: OTPReducers,
  marketplace: marketplaceReducers,
  salesAndOrders: salesAndOrdersReducers,
  partnerDashboard: partnerDashboardReducers,
  mobileDashboard: mobileDashboardReducers,
  proxtera: ProxteraReducers,
  payments: paymentReducers,
  stockImages: stockImagesReducers,
  vouchers: voucherReducers,
  buyerDashboard: buyerReducers,
  enterprise: enterpriseReducers,
  deliverySettings: deliverySettingsReducer,
  mediaLibrary: mediaReducers,
  subscription: subscriptionReducers,
  quickOrder: QuickOrderReducers,
  nft: NFTReducers,
});

export default rootReducer;
