import React from "react";
import { useTheme, makeStyles } from "@material-ui/styles";
import SentroLoading from "../../../loading";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Box,
  Typography,
  Tabs,
  Tab,
  useMediaQuery
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useSelector, useDispatch } from "react-redux";
import BasicInformation from "./basicInformation";
import { getProfile } from "../webBuilder/websiteBuilderActions";
import Password from "./password";
import MuiAlert from "@material-ui/lab/Alert";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={{
            backgroundColor: "#fafafa",
            height: "83vh",
            padding: 0,
            overflowY: value === 0 ? "hidden" : "scroll"
          }}
          p={3}
        >
          <Typography
            style={{
              height: "100%",
              position: "relative",
              backgroundColor: "#fff"
            }}
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}
const useStyles = makeStyles(theme => ({
  dialog: { backgroundColor: "#fafafa", height: "100%" },
  appbar: {
    position: "relative",
    backgroundColor: "#fafafa"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  header1: {
    fontSize: 14,
    fontWeight: 600,
    color: "#54575f"
  },
  link: {
    color: "#f97663",
    fontWeight: "600",
    fontSize: 14,
    "&:hover": {
      color: "#f97663"
    }
  },
  paper: {
    position: "relative",
    border: "1px solid #d1d5dd",
    borderRadius: 4,
    boxShadow: "0 8px 12px rgba(0,0,0,0.05)",
    backgroundColor: "#fff",
    padding: 15,
    display: "flex",
    marginBottom: "2vh"
  },
  closeBtn: { position: "absolute", left: 15 }
}));
const DialogTransition = props => <Slide direction="up" {...props} />;
const AccountSettings = props => {
  const { open, setOpen } = props;
  const theme = useTheme();
  const classes = useStyles();
  const loading = useSelector(state => state.mobileDashboard.loading);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <div>
      <SentroLoading open={loading} />
      <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
        <div className={classes.dialog}>
          <AppBar className={classes.appbar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                className={classes.closeBtn}
              >
                <CloseIcon style={{ color: "#f97663" }} />
              </IconButton>
              <div className={classes.header}>Account Settings</div>
            </Toolbar>
          </AppBar>
          <Tabs
            style={{ backgroundColor: "#fff" }}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Basic Information" {...a11yProps(0)} />
            <Tab label="Password" {...a11yProps(1)} />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <div>
                <BasicInformation open={open} />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <div>
                <Password open={open} />
              </div>
            </TabPanel>
          </SwipeableViews>
        </div>
      </Dialog>
    </div>
  );
};

export default AccountSettings;
