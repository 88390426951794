import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const useStyles = theme => ({
  wrap: {
    position: "relative",
    width: '100%',
    padding: "1em 0",
    "& img": {
      width: "100%"
    }
  },
  carousel: {
    backgroundColor: 'white',
    "& .BrainhubCarousel__arrows": {
        borderRadius: '50%',
        backgroundColor: '#f97663',
        padding: 15,
        margin: 5
    },
    "& .BrainhubCarousel__arrows:disabled": {
        backgroundColor: 'rgba(0,0,0,0.2)',
    },
    "& .BrainhubCarousel__arrows:hover:enabled": {
        backgroundColor: '#f97663',
    }
  }
});

class ImgCarousel extends React.Component {
  
  render(){
    let { classes, images, embed_video } = this.props;
    try {
      if (embed_video.length) {
        const embed_videos = embed_video.split(",");
        if (embed_videos.length) {
          const vids = embed_videos.map(e => ({
            html: <div dangerouslySetInnerHTML={{
              __html: e
            }}></div>
          }))
          images = [
            ...vids, ...images
          ]
        }
      }
    } catch { }
    const img_comp = () => (
      images.map((image, index) => (
        image.html ? 
          image.html
          : 
          <img key={index} src={image.image} alt={index} />
      ))
    )
    return (
      <div className={classes.wrap}>
        {
          images && images.length ?
            <Carousel slidesPerPage={images.length > 1 ? 2: 1} className={classes.carousel} arrows>
            {img_comp()}
            </Carousel>
          : ""
        }
      </div>
    )
  }
}

export default  withStyles(useStyles)(ImgCarousel);
