import { ActionTypes } from "./actions";
import { ReduxInitializeFormActionTypes } from "./actions";
const initialState = {
  loading: false,
  pickup_addresses: { results: [] },
  to_delete_pickup_address: [],
  delivery_locations: [],
  bux_subwallet: "",
  couriers: {
    mrspeedy: "",
    ninjavan: "",
  },
  reduxForm: {
    pickupAddress: "",
  },
};

export default function Reducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionTypes.GET_PICKUP_ADDRESSES:
      return {
        ...state,
        pickup_addresses: {
          ...action.payload,
          results: action.payload.results?.length
            ? action.payload.results.sort((a, b) =>
                a.is_default < b.is_default ? 1 : -1
              )
            : [],
        },
      };
    case ActionTypes.SAVE_PICKUP_ADDRESSES:
      return {
        ...state,
        loading: false,
        pickup_addresses: [action.payload],
        reduxForm: {
          ...state.reduxForm,
          pickupAddress: action.payload,
        },
      };
    case ActionTypes.GET_DELIVERY_LOCATIONS:
      return {
        ...state,
        delivery_locations: action.payload,
      };
    case ActionTypes.SAVE_DELIVERY_LOCATION:
      const already_in_store = state.delivery_locations
        .map((item) => item.id)
        .includes(action.payload.id);
      const delivery_locations = already_in_store
        ? state.delivery_locations.map((item) =>
            item.id === action.payload.id ? action.payload : item
          )
        : [...state.delivery_locations, action.payload];
      return {
        ...state,
        delivery_locations,
      };
    case ActionTypes.DELETE_DELIVERY_LOCATION:
      return {
        ...state,
        delivery_locations: state.delivery_locations.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case ActionTypes.GET_MRSPEEDY:
      return {
        ...state,
        couriers: {
          ...state.couriers,
          mrspeedy: action.payload,
        },
      };
    case ActionTypes.GET_NINJAVAN:
      return {
        ...state,
        couriers: {
          ...state.couriers,
          ninjavan: action.payload,
        },
      };
    case ActionTypes.GET_BUX_SUBWALLET:
      return {
        ...state,
        bux_subwallet: action.payload,
      };
    //DELETE PICKUP ADDRESS
    case ActionTypes.DELETE_PICKUP_ADDRESS:
      let array = [...state.pickup_addresses.results];
      let index = array.indexOf(action.payload);
      if (index !== -1) {
        array.splice(index, 1);
      }
      return {
        ...state,
        pickup_addresses: { ...state.pickup_addresses, results: array },
        to_delete_pickup_address: [
          ...state.to_delete_pickup_address,
          action.payload,
        ],
      };
    case ActionTypes.INITIAL_STATE:
      return initialState;
    // redux form
    case ReduxInitializeFormActionTypes.LOAD_PICKUP_ADDRESS_DATA:
      return {
        ...state,
        reduxForm: {
          ...state.reduxForm,
          pickupAddress: action.payload,
        },
      };
    default:
      return state;
  }
}
