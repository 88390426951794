import React from "react";
import { makeStyles } from "@material-ui/styles";
import GoogleFontSelector from '../enterprise/components/dashboard/customize/googleFontSelector';


const useStyles = makeStyles(theme => ({
  label2: {
    fontSize: 14,
    fontWeight: 500,
    color: "#54575F"
  },
  fontPreviewDiv: {
    padding: "1em",
    textAlign: "center",
    border: "1px solid #B7B9C0",
    fontWeight: "600",
    fontSize: 32,
    marginTop: "0.5em",
    borderRadius: 4
  }
}));

const Main = ({
    input,
    label,
    maxLength,
    minLength,
    multiline,
    cut,
    noHelpText,
    meta,
    ...custom
  }) => {
  const classes = useStyles();

  return (
    <div>
      <div className="d-flex align-items-center">
        <p className={`${classes.label2} mb-0 font-picker-label`}>{label}</p>
        <div className="ml-auto">
          <GoogleFontSelector onChange={input.onChange}/>
        </div>
      </div>
      {
        input.value.css_url ? 
        <link href={input.value.css_url} rel="stylesheet"></link>
        : ""
      }
      <p className={`${classes.fontPreviewDiv} font-picker-preview`} style={{
        fontFamily: input.value.family
      }}>
        { input.value.family || "Helvetica Bold" }
      </p>
    </div>
  );
}
export default Main;
