import React from "react";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { reduxForm, Field } from "redux-form";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  getProductSelections,
  ReduxInitializeFormActions,
  setProductVariantCombinations,
  resetProductSelections
} from "../../websiteBuilderActions";
import ReduxNumberField from "../../../reduxFields/numberField";
import ReduxTextField from "../../../reduxFields/textField";
import ReduxImageField from "../../../reduxFields/imageField";
import validations from "../../../common/validations";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import VariantCombinationModal from "./variantCombinationFieldModal";
import Utils from "../../../common/utils";

// combinations and matching ====================================
function getPossibleCombinations(list, n = 0, result = [], current = []) {
  // accepts 2dimensional array
  // [
  //   [1,2]
  //   [3,4]
  // ]
  // ------ to ------
  // [
  //   [1,3]
  //   [1,4]
  //   [2,3]
  //   [2,4]
  // ]
  if (list.length) {
    if (n === list.length) result.push(current);
    else
      list[n]
        .filter(item => item.is_enabled)
        .forEach(item => {
          getPossibleCombinations(list, n + 1, result, [...current, item]);
        });
  }
  return result;
}

const findMatchSelectionInstances = (instances, combination) =>
  // combinations
  // instances = [ [1,2], [3,4], [5,6] ]
  // combination = [1,2]
  // returns [1,2]
  instances.filter(
    item =>
      item.variants.length === combination.length &&
      item.variants.every(variant =>
        combination.some(
          comb =>
            comb.group_name === variant.group_name && comb.name === variant.name
        )
      )
  );

// data transformations ====================================
const transformToReduxFormData = data => {
  // transform data for redux form;
  // convert to flat data for redux form
  // [
  //   {
  //     price: -
  //     quantity: -
  //     sku: -
  //   },
  //   {
  //     price: -
  //     quantity: -
  //     sku: -
  //   }
  // ]
  // --------- to ------------
  // {
  //   price-0
  //   quantity-0
  //   sku-0
  //   price-1
  //   quantity-1
  //   sku-1
  // }
  let reduxFormTransformedData = {};
  data.forEach((item, index) => {
    Object.keys(item).forEach(key => {
      const data = item[key];

      reduxFormTransformedData[`${key}-${index}`] =
        key === "images" ? (data ? [data] : []) : data;
    });
  });
  return reduxFormTransformedData;
};

export const useProductSelectionAxiosData = () => {
  // transform flat redux form data to axios data;
  // {
  //   price-0
  //   quantity-0
  //   sku-0
  //   price-1
  //   quantity-1
  //   sku-1
  // }
  // --------- to ------------
  // [
  //   {
  //     price: -
  //     quantity: -
  //     sku: -
  //   },
  //   {
  //     price: -
  //     quantity: -
  //     sku: -
  //   }
  // ]
  const productSelectionsReduxData = useSelector(
    state => state.form.variant_choices?.values
  );
  const [
    axiosFormTransformedData,
    setAxiosFormTransformedData
  ] = React.useState([]);
  React.useEffect(() => {
    let newData = [],
      temp = {};
    if (productSelectionsReduxData) {
      Object.keys(productSelectionsReduxData).forEach((key, index) => {
        if (key.includes("-")) {
          try {
            temp[Number(key.split('-')[1])][
              key.split('-')[0]
            ] = productSelectionsReduxData[key];
          } catch (err) {
            temp[Number(key.split('-')[1])] = {
              [key.split('-')[0]]: productSelectionsReduxData[key]
            };
          }
        }
      });
      Object.keys(temp).forEach(key => newData.push(temp[key]));
      setAxiosFormTransformedData(newData);
    }
  }, [productSelectionsReduxData]);
  return axiosFormTransformedData;
};

export const getAllNewlyCreatedVariantIds = (
  variantGroupInstances,
  variantsInstances
) => {
  // get variant instance ids
  let variants = [];
  variantGroupInstances.forEach(item => {
    item.variants.forEach(i => {
      variants.push(i);
    });
  });

  const filteredVariants = variants.filter(variant =>
    variantsInstances.variants.some(
      i => i.group_name === variant.group_name && i.name === variant.name
    )
  );

  return filteredVariants.map(i => i.id);
};

export const useProductSelectionsToDelete = () => {
  // get all variant instance ids that doesnt match any combinations
  // for deletion
  const existingProductSelections = useSelector(
    state => state.webBuilder.productSelections
  );
  const productSelectionsNewData = useSelector(
    state => state.form.variant_choices?.values
  );
  const productSelectionsData = useProductSelectionAxiosData();

  const [toDelete, setToDelete] = React.useState([]);

  React.useEffect(() => {
    if (productSelectionsNewData) {
      setToDelete(
        existingProductSelections.filter(
          (item, index) => !productSelectionsData.some(i => item.id === i?.id)
        )
      );
    }
  }, [productSelectionsNewData, existingProductSelections]);

  return toDelete;
};

const VariantChoicesForm = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      border: "1px solid #EEEEEE",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
      borderRadius: 4,
      padding: isMobile ? "0.75em" : "1em",
      marginBottom: "1em"
    },
    header: {
      color: "rgba(43, 45, 50, 0.8)",
      fontWeight: 600,
      fontSize: 12,
      flexBasis: "50%",
      marginBottom: 5
    },
    mobileKey: {
      marginLeft: 5,
      color: "rgba(43, 45, 50, 0.64)",
      marginBottom: 0
    },
    mobileData: {
      color: "#2B2D32",
      marginBottom: 0
    },
    error: {
      color: "#f44336",
      fontSize: 12
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const productSelections = useSelector(
    state => state.webBuilder.productSelections
  );

  React.useEffect(() => {
    dispatch(resetProductSelections());
  }, []);

  React.useEffect(() => {
    if (props.product_uuid) {
      dispatch(getProductSelections(props.product_uuid));
    }
  }, [props.product_uuid]);

  const [combinations, setCombinations] = React.useState([]);
  React.useEffect(() => {
    const variantsByGroup = props.data
      .map(item => item.variants.map(i => ({ ...i, group_name: item.name })))
      .filter(arr => arr.some(i => i.is_enabled));
    const newCombinations = getPossibleCombinations(variantsByGroup);
    setCombinations(newCombinations);
    dispatch(setProductVariantCombinations(newCombinations));
  }, [props.data]);

  const [values, setValues] = React.useState({ data: [] });
  const formValues = useSelector(state => state.form.variant_choices?.values);

  React.useEffect(() => {
    const tempVal = combinations.map(combi => {
      let matchedSelection = findMatchSelectionInstances(
        productSelections,
        combi
      );
      matchedSelection = matchedSelection[0] || {
        images: "",
        price: 0.0,
        discounted_price: "",
        quantity: 1,
        sku: "",
        variants: combi
      };
      return matchedSelection;
    });
    setValues({ data: tempVal });
  }, [props.data, productSelections, formValues, combinations]);

  React.useEffect(() => {
    // transform data for redux form
    let reduxFormTransformedData = transformToReduxFormData(values.data);
    props.initializeData(reduxFormTransformedData);
  }, [values]);

  // for mobile
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [
    selectedCombinationForMobile,
    setSelectedCombinationForMobile
  ] = React.useState("");
  const setSelectedCombination = (index, item) => {
    setSelectedCombinationForMobile(item);
    setModalOpen(true);
    setSelectedIndex(index);
  };

  const onProductSelectionChange = (index, data) => {
    Object.keys(data).forEach(i => {
      props.change(`${i}-${index}`, data[i]);
    });
  };

  const errors = useSelector(state => state.form.variant_choices?.syncErrors);
  const getError = index => {
    try {
      const keys = Object.keys(errors);
      for (let i in keys) {
        if (keys[i].includes(`-${index}`)) {
          return errors[keys[i]];
        }
      }
    } catch {}
  };

  const renderFields = () => {
    return values.data.map((item, index) => (
      <div className={classes.root} key={index}>
        <div style={{ minWidth: 100 }}>
          <Field
            fullWidth
            id={`images-${index}`}
            name={`images-${index}`}
            label=""
            component={ReduxImageField}
            showHelperText={false}
            cropOnImageSelect
            cropperProps={{
              defaultCropperOrientation: "square"
            }}
          />
        </div>
        {isMobile ? (
          <div className="d-flex flex-column w-100">
            <div className="d-flex">
              <p>{item.variants.map(i => i.name).join(" / ")}</p>
              <div
                className="d-flex ml-auto d-block d-md-none"
                onClick={() => setSelectedCombination(index, item)}
              >
                <BorderColorOutlinedIcon
                  style={{ color: "#FF6B55", fontSize: 18, marginRight: 4 }}
                />
                <strong style={{ color: "#FF6B55", fontSize: 12 }}>Edit</strong>
              </div>
            </div>
            <div className="d-none">
              <Field
                fullWidth
                id={`price-${index}`}
                name={`price-${index}`}
                label=""
                component={ReduxNumberField}
                validate={
                  !props.disabled
                    ? [validations.required, validations.isNegativeNumber]
                    : null
                }
                showHelperText={false}
              />
            </div>
            <div className="mr-1 flex-grow-1 mb-2">
              {errors && errors[`price-${index}`] ? (
                <p className="text-danger">Price is required.</p>
              ) : (
                <div className="d-flex">
                  <p className={classes.mobileKey}>₱</p>
                  <p
                    className={classes.mobileData}
                    style={{
                      textDecoration: formValues[`discounted_price-${index}`]
                        ? "line-through"
                        : "none"
                    }}
                  >
                    {Utils.commafy(formValues[`price-${index}`])}
                  </p>
                  {formValues[`discounted_price-${index}`] ? (
                    <p className={`${classes.mobileData} ml-2`}>
                      <strong>
                        {Utils.commafy(formValues[`discounted_price-${index}`])}
                      </strong>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
            <div className="d-flex mt-auto flex-wrap">
              <div className="mr-1 flex-grow-1">
                <div className="d-flex">
                  <p className={classes.mobileKey}>Qty:</p>
                  <p className={classes.mobileData}>
                    {formValues[`quantity-${index}`]}
                  </p>
                </div>
              </div>
              <div className="mr-1 flex-grow-1">
                <p className={classes.mobileKey}>
                  {formValues[`sku-${index}`]}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex">
              <p>{item.variants.map(i => i.name).join(" / ")}</p>
              <div
                className="d-flex ml-auto d-block d-md-none"
                onClick={() => setSelectedCombination(index, item)}
              >
                <BorderColorOutlinedIcon
                  style={{ color: "#FF6B55", fontSize: 18, marginRight: 4 }}
                />
                <strong style={{ color: "#FF6B55", fontSize: 12 }}>Edit</strong>
              </div>
            </div>
            <div className="d-flex mt-auto">
              <div className="mr-1">
                <Field
                  fullWidth
                  id={`price-${index}`}
                  name={`price-${index}`}
                  label=""
                  component={ReduxNumberField}
                  validate={
                    !props.disabled
                      ? [validations.required, validations.isNegativeNumber]
                      : null
                  }
                  showHelperText={false}
                />
              </div>
              <div className="mr-1">
                <Field
                  fullWidth
                  id={`discounted_price-${index}`}
                  name={`discounted_price-${index}`}
                  label=""
                  component={ReduxNumberField}
                  validate={[validations.isNegativeNumber]}
                  showHelperText={false}
                />
              </div>
              <div className="mr-1" style={{ flexBasis: 180 }}>
                <Field
                  fullWidth
                  className="mr-1"
                  id={`quantity-${index}`}
                  name={`quantity-${index}`}
                  label=""
                  validate={
                    !props.disabled
                      ? [validations.required, validations.stock]
                      : null
                  }
                  component={ReduxTextField}
                  showHelperText={false}
                  // type="number"
                  // inputProps={{
                  //   min: 1
                  // }}
                />
              </div>
              <div className="mr-1">
                <Field
                  fullWidth
                  id={`sku-${index}`}
                  name={`sku-${index}`}
                  label=""
                  component={ReduxTextField}
                  showHelperText={false}
                  placeholder="SKU"
                />
              </div>
            </div>
            <p className={classes.error}>{getError(index)}</p>
          </div>
        )}
      </div>
    ));
  };

  return values.data.length ? (
    <div
      style={{
        marginTop: "2em",
        paddingTop: "2em",
        borderTop: "1px dashed #B7B9C0"
      }}
    >
      <p className={`d-flex mb-2 ${classes.header}`} style={{ fontSize: 14 }}>
        Details
      </p>
      <div className="d-md-flex flex-grow-1 d-none" style={{ margin: "0 1em" }}>
        <div style={{ minWidth: 100 }} />
        <p className={`d-flex ${classes.header}`}>Price</p>
        <p className={`d-flex ${classes.header}`}>Discounted Price</p>
        <p className={`d-flex ${classes.header}`} style={{ flexBasis: 180 }}>
          Qty
        </p>
        <p className={`d-flex ${classes.header}`}>Product Code</p>
      </div>
      {renderFields()}
      <VariantCombinationModal
        index={selectedIndex}
        open={modalOpen}
        setOpen={setModalOpen}
        data={selectedCombinationForMobile}
        onChange={onProductSelectionChange}
        change={props.change}
        touch={props.touch}
      />
    </div>
  ) : (
    ""
  );
};

const validate = (values, props) => {
  const errors = {};
  Object.keys(values).forEach(field => {
    // variants
    if (field.includes("discounted_price-")) {
      // check if discounted is less than the orig
      const index = field.split("-")[1];
      const orig_price = values[`price-${index}`];
      const discounted_price = values[field];
      if (
        discounted_price !== 0 &&
        parseFloat(orig_price) <= parseFloat(discounted_price)
      ) {
        errors[field] =
          "Discounted price should be lower than the original price.";
      }
    }

    // mobile modal
    if (field === "discounted_price") {
      // check if discounted is less than the orig
      const orig_price = values[`price`];
      const discounted_price = values[field];
      if (
        discounted_price !== 0 &&
        parseFloat(orig_price) <= parseFloat(discounted_price)
      ) {
        errors[field] =
          "Discounted price should be lower than the original price.";
      }
    }
  });
  return errors;
};

let Form = reduxForm({
  form: "variant_choices",
  enableReinitialize: true,
  validate
})(VariantChoicesForm);

Form = connect(
  state => ({
    initialValues: state.webBuilder.reduxForm.productSelection // pull initial values
  }),
  {
    initializeData: ReduxInitializeFormActions.initializeProductSelectionsData
  } // bind account loading action creator
)(Form);

export default Form;
