import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Icon, InlineIcon } from "@iconify/react";
import history from "../../store/history";
import facebookMessenger from "@iconify/icons-mdi/facebook-messenger";
import Path from "../../Paths";
import apiConfig from "../../common/apiConfig";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ExpandMore } from "@material-ui/icons";
import img1 from "../../assets/images/FB_Chat/fb_chat_1.gif";
import img2 from "../../assets/images/FB_Chat/fb_chat_2.gif";
import SentroLoading from "../common/loading";
import DashboardActions from "./dashboardActions";
import { animateScroll as scroll } from "react-scroll";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  OutlinedInput
} from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = theme => ({
  root: {
    padding: "2em",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  fbMessengerIcon: {
    color: "#0D3D76",
    fontSize: 100,
    width: 65
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  content: {
    padding: "0 1.5em",
    "& > strong": {
      color: "#002C42"
    },
    "& > p": {
      marginTop: 10
    }
  }
});

class Card extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div style={{ width: 65 }}>
          <Icon icon={facebookMessenger} className={classes.fbMessengerIcon} />
        </div>
        <div className={classes.content}>
          <strong>Chat to your clients on your shop</strong>
          <p style={{ fontSize: 12 }}>
            Link your shop’s FB Messenger account so your clients can
            immediately chat with you while browsing your Sentro shop.{" "}
          </p>
        </div>
        <Button
          className={classes.button}
          id="view"
          color="primary"
          disableElevation
          className={classes.button}
          variant="outlined"
          disableElevation={true}
          onClick={() => {
            // const link =  data.variants ? `/marketplace/${data.id}/packages` : `marketplace/${data.id}/checkout`
            history.push(Path.FB_CHAT_SETUP);
          }}
        >
          Learn More
        </Button>
      </div>
    );
  }
}
const FBChatCard = withStyles(useStyles)(Card);
export { FBChatCard };

const useStylesInstructionCard = theme => ({
  root: {
    padding: "2em",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    color: "#F97663",
    fontSize: 50
  },
  iconGreen: {
    color: "#57B19F",
    fontSize: 50
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  content: {
    padding: "0 1.5em",
    "& > strong": {
      color: "#002C42"
    },
    "& > p": {
      marginTop: 5
    }
  },
  pinkLink: {
    color: "#F97663"
  },
  footerText: {
    color: "#909196",
    fontSize: 12,
    paddingTop: 5
  }
});

class InstructionCardComponent extends React.Component {
  render() {
    const { classes, onClick, store } = this.props;
    return (
      <div className={classes.root}>
        {store.length ? (
          store[0] ? (
            store[0].fb_page_id ? (
              <>
                <CheckCircleIcon className={classes.iconGreen} />
                <div className={classes.content}>
                  <strong>
                    Your have currently linked to a Facebook Page to your Sentro
                    Shop
                  </strong>
                  <p>
                    <a
                      href={`${apiConfig.localHost}shop/${store[0].site}`}
                      target="_blank"
                      className={classes.pinkLink}
                    >
                      Visit your shop
                    </a>{" "}
                    to check if FB chat is working.
                  </p>
                  <p className={classes.footerText}>
                    Having problems? Kindly check the steps below.
                  </p>
                </div>
                <Button
                  className={classes.button}
                  id="view"
                  color="primary"
                  disableElevation
                  className={classes.button}
                  variant="outlined"
                  disableElevation={true}
                  onClick={() => {
                    onClick();
                  }}
                >
                  Update
                </Button>
              </>
            ) : (
              <>
                <AddCircleIcon className={classes.icon} />
                <div className={classes.content}>
                  <strong>
                    Enable your FB Chat to your Sentro Online Shop
                  </strong>
                  <p>Simply follow the steps below</p>
                </div>
                <Button
                  className={classes.button}
                  id="view"
                  color="primary"
                  disableElevation
                  className={classes.button}
                  variant="outlined"
                  disableElevation={true}
                  onClick={() => {
                    onClick();
                  }}
                >
                  Proceed
                </Button>
              </>
            )
          ) : (
            ``
          )
        ) : (
          <>
            <AddCircleIcon className={classes.icon} />
            <div className={classes.content}>
              <strong>Create your Sentro Online Shop First</strong>
              {/* <p>Simply follow the steps below</p> */}
            </div>
            <Button
              className={classes.button}
              id="view"
              color="primary"
              disableElevation
              className={classes.button}
              variant="outlined"
              disableElevation={true}
              onClick={() => {
                // onClick()
                history.push("/dashboard");
              }}
            >
              Dashboard
            </Button>
          </>
        )}
      </div>
    );
  }
}

const mapStateToPropsInstruction = state => ({ dashboard }) => ({
  store: dashboard.stores
});
const InstructionCard = connect(mapStateToPropsInstruction)(
  withStyles(useStylesInstructionCard)(InstructionCardComponent)
);

const useStylesSetup = theme => ({
  main: {
    display: "block",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1.5em"
    }
  },
  header: {
    padding: "1.5em 3em",
    marginTop: 68
  },
  backDivWrapper: {
    display: "flex",
    "& > span": {
      marginLeft: "1em",
      fontWeight: 600
    },
    color: "#F97663 !important",
    alignItems: "center",
    cursor: "pointer",
    width: 100
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
    maxWidth: "80em",
    margin: "auto"
  },
  fbMessengerIcon: {
    color: "#0D3D76",
    margin: "10px 50px",
    width: 100,
    height: 100
  },
  headerDetails: {
    paddingRight: 100,
    "& > strong": {
      fontSize: 30,
      color: "#2B2D33"
    },
    "& > p": {
      marginTop: 10,
      fontSize: 16
    }
  },
  root: {
    margin: "auto",
    maxWidth: "60em",
    paddingBottom: "3em"
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  expansionTitle: {
    color: "#002C42",
    fontSize: 16,
    padding: "0 1em"
  },
  expansionForm: {
    background: "#F1F1F1",
    padding: "2em 6em",
    display: "block",
    "& .expansionForm": {
      display: "flex",
      alignItems: "center"
    },
    "& img": {
      margin: "1em 0"
    }
  },
  inputFieldButton: {
    marginLeft: "1em",
    height: 40
  },
  inputField: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    height: 40,
    backgroundColor: "white"
  },
  howToGetFBPage: {
    margin: "1.5em 0"
  },
  step2Details: {
    backgroundColor: "white",
    padding: "1.5em"
  }
});

class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section1: false,
      section2: false,
      fbPageId: "",

      alertStat: false,
      alertType: "success"
    };
    this.expansionChange = this.expansionChange.bind(this);
    this.cardOnClick = this.cardOnClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.unlink = this.unlink.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    const stores = await dispatch(DashboardActions.getStores());
    console.log(stores, "componentdidmount");
    if (stores[0]) {
      this.setState({
        fbPageId: stores[0].fb_page_id
      });
    }
    scroll.scrollTo(0);
  };

  expansionChange(section, stat) {
    console.log(section, stat);
    this.setState({
      [section]: stat
    });
  }

  cardOnClick() {
    this.setState({
      section1: true,
      section2: true
    });
  }

  onSubmit = async () => {
    const { dispatch, stores } = this.props;
    const store = await dispatch(
      DashboardActions.saveFBPageID(stores[0].site, this.state.fbPageId)
    );
    this.setState({
      alertStat: true,
      alertType: "success"
    });
    scroll.scrollTo(0);
  };

  unlink = async () => {
    const { dispatch, stores } = this.props;
    const store = await dispatch(
      DashboardActions.saveFBPageID(stores[0].site, "")
    );
    this.setState({
      fbPageId: "",
      alertStat: true,
      alertType: "warning"
    });
    scroll.scrollTo(0);
  };

  handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      alertStat: false
    });
  };

  render() {
    const { classes, loading, stores } = this.props;
    return (
      <div style={{ backgroundColor: "white" }} className={classes.main}>
        <Snackbar
          open={this.state.alertStat && this.state.alertType === "success"}
          autoHideDuration={2000}
          onClose={this.handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleCloseAlert}
            severity="success"
            icon={<InfoOutlinedIcon fontSize="inherit" />}
          >
            Your Facebook ID is now linked to your Sentro shop. Visit your shop
            to check it out.
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.alertStat && this.state.alertType === "warning"}
          autoHideDuration={2000}
          onClose={this.handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleCloseAlert}
            severity="warning"
            icon={<InfoOutlinedIcon fontSize="inherit" />}
          >
            You have unlinked a Facebook ID from your Sentro shop.
          </Alert>
        </Snackbar>
        <SentroLoading open={loading} />
        <div className={classes.header}>
          <div
            className={classes.backDivWrapper}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
            <span>BACK</span>
          </div>
          <div className={classes.headerContent}>
            <Icon
              icon={facebookMessenger}
              className={classes.fbMessengerIcon}
            />
            <div className={classes.headerDetails}>
              <strong>Add FB Chat to your Sentro Online Shop</strong>
              <p>
                Chat with your customers directly from your online shop by
                linking your Facebook page and enabling Live Chat.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className={classes.root}>
          <InstructionCard onClick={this.cardOnClick} />
          <ExpansionPanel
            expanded={this.state.section1}
            onChange={(obj, stat) => this.expansionChange("section1", stat)}
          >
            <ExpansionPanelSummary
              style={{ borderBottom: "1px solid #D1D5DD" }}
              expandIcon={<ExpandMore />}
            >
              <strong className={classes.expansionTitle}>
                Step 1: Link your Facebook ID to your Sentro Shop
              </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={classes.expansionForm}
              style={{ borderBottom: "1px solid #D1D5DD" }}
            >
              <strong>Facebook ID</strong>
              <div className="expansionForm">
                <OutlinedInput
                  className={classes.inputField}
                  value={this.state.fbPageId}
                  type="number"
                  onChange={event => {
                    this.setState({
                      fbPageId: event.target.value
                    });
                  }}
                />
                <Button
                  className={classes.inputFieldButton}
                  color="primary"
                  disableElevation
                  variant="contained"
                  disabled={!this.state.fbPageId || !stores.length}
                  disableElevation={true}
                  onClick={this.onSubmit}
                >
                  Submit
                </Button>
                <Button
                  // className={classes.button}
                  disableElevation
                  className={classes.button}
                  style={{ marginLeft: 5 }}
                  disableElevation={true}
                  disabled={!stores.length}
                  onClick={() => {
                    this.unlink();
                  }}
                >
                  Unlink
                </Button>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div className={classes.howToGetFBPage}>
            <strong>How to find your Facebook ID?</strong>
            <p>
              Go to{" "}
              <span
                style={{ color: "#f97663", cursor: "pointer" }}
                onClick={() => window.open("https://findmyfbid.in/")}
              >
                findmyfbid.in
              </span>
              . Type your shop's Facebook page in the text box, then click the
              Find Facebook ID button to proceed. Your FB ID consists of
              numbers.
            </p>
            <img src={img1} style={{ width: "100%" }} />
          </div>
          <ExpansionPanel
            expanded={this.state.section2}
            onChange={(obj, stat) => this.expansionChange("section2", stat)}
          >
            <ExpansionPanelSummary
              style={{ borderBottom: "1px solid #D1D5DD" }}
              expandIcon={<ExpandMore />}
            >
              <strong className={classes.expansionTitle}>
                Step 2: Link your Sentro Shop to your Facebook Account
              </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={`${classes.expansionForm} ${classes.step2Details}`}
              style={{ borderBottom: "1px solid #D1D5DD" }}
            >
              <p>
                1. Go to your Facebook page’s <strong>Settings</strong>, then
                click the <strong>Messaging</strong> tab. Look for the{" "}
                <strong>Add Messenger to your website</strong> and click{" "}
                <strong>Get Started</strong>
              </p>
              <p>
                2. A <strong>Set Up Chat Plugin</strong> window will appear.
                Fill up your preferences until step 3.
              </p>
              <p>
                3. In step 3, under the{" "}
                <strong>Add Website Domain Name section</strong>, add your
                Sentro online shop’s link. Type in both the HTTP:// and HTTPS://
                variants of your Sentro shop to ensure it works properly. Then
                click <strong>Save</strong> and <strong>Finish</strong>
              </p>
              <img src={img2} style={{ width: "100%" }} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ dashboard }) => ({
  loading: dashboard.loading,
  stores: dashboard.stores
});
const FBChatSetup = connect(mapStateToProps)(withStyles(useStylesSetup)(Setup));
export default FBChatSetup;
