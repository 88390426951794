import React from "react";
import { makeStyles, Paper, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import cartIcon from "../../../assets/images/cartRoundedIcon.svg";
const CartEmpty = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const useStyles = makeStyles(theme => ({
    paper: {
      backgroundColor: "#fff",
      padding: "7em 0",
      marginTop: "3vh"
    },
    label1: {
      fontSize: 18,
      fontWeight: 600,
      color: "#2b2d32"
    },
    label2: {
      fontSize: 14,
      color: "#54575f"
    }
  }));

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div
        style={{ padding: isMobile ? "0 12vw" : "0 25vw", textAlign: "center" }}
      >
        <img src={cartIcon} width="80" height="80" alt="cart_icon" />
        <div style={{ marginTop: " 5vh" }}>
          <div className={classes.label1}>Your cart is empty</div>
          <div className={classes.label2} style={{ marginTop: "2vh" }}>
            Everytime you buy from a Sentro shop, your orders will appear here.
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default CartEmpty;
