import React from "react";
import Alert from "@material-ui/lab/Alert";
import MaterialUIAlert from "../../../common/material-ui-alert";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Path from "../../../../Paths";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionActions from "../../redux/actions";
import moment from "moment";
import { useLocation } from "react-router-dom";
export default function Banner(props) {
  let user = sessionStorage.getItem("CURRENT_USER");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const subscription = useSelector(state => state.subscription.subscription);
  const getSubscription = JSON.parse(sessionStorage.getItem("subscription"));
  const [showBanner, setShowBanner] = React.useState(
    (getSubscription && getSubscription.show_banner) || false
  );

  React.useEffect(() => {
    if (subscription && subscription.expiry_date) {
      let current_date = new Date();
      let expiration = new Date(subscription.expiry_date);
      let start = Math.floor(current_date.getTime() / (3600 * 24 * 1000));
      let end = Math.floor(expiration.getTime() / (3600 * 24 * 1000));
      let days = end - start;
      if (days > 5) {
        setShowBanner(false);
      }
    }
  }, [subscription.expiry_date]);

  React.useEffect(() => {
    setShowBanner(getSubscription && getSubscription.show_banner);
  }, [location]);

  const handleClose = () => {
    setShowBanner(false);
    let existing = sessionStorage.getItem("subscription");
    existing = existing ? JSON.parse(existing) : {};
    existing["show_banner"] = false;
    sessionStorage.setItem("subscription", JSON.stringify(existing));
  };

  const subscription_message = () => {
    if (!subscription.subscription_type && !subscription.free_trial_availed) {
      return (
        <div>
          Track your sales performance, and access more templates with a{" "}
          <strong>Sentro Premium</strong> account.{" "}
          <Link
            style={{ fontWeight: 600, color: "#25459b" }}
            onClick={() => history.push(Path.SENTRO_ADVANCED)}
          >
            Learn more
          </Link>
          .
        </div>
      );
    } else if (subscription.expiry_date || subscription.free_trial_availed) {
      let current_date = new Date();
      let expiration = new Date(subscription.expiry_date);
      let start = Math.floor(current_date.getTime() / (3600 * 24 * 1000));
      let end = Math.floor(expiration.getTime() / (3600 * 24 * 1000));
      let days = end - start;
      return (
        <div>
          {subscription.expiry_date ? (
            <>
              You have <strong>{days} days left</strong> on your Free Trial.
            </>
          ) : (
            <>Your Free Trial has expired.</>
          )}{" "}
          <Link
            style={{ fontWeight: 600, color: "#25459b" }}
            onClick={() => history.push(Path.SENTRO_ADVANCED)}
          >
            Subscribe to the Advanced
          </Link>{" "}
          to continue using the additional features.
        </div>
      );
    }
  };

  return (
    <>
      {showBanner === true ? (
        <div className="pb-3 px-md-0 px-3 pt-md-0 pt-3">
          <MaterialUIAlert
            type={"info"}
            icon={false}
            message={subscription_message()}
            style={{ border: "1px solid #3a73cc", color: "#25459b" }}
            onClose={handleClose}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
