import React from "react";
import ImageUploader from "react-images-upload";

class ProductImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pictures: [] };
    this.onDrop = this.onDrop.bind(this);
  }
  onDrop(pictureFiles) {
    this.setState(
      {
        pictures: pictureFiles
      },
      () => {
        this.props.onChange(this.state.pictures);
      }
    );
  }
  render() {
    const DEFAULT_IMAGE =
      "https://sentro-store-bucker.s3.us-east-2.amazonaws.com/add_photo_alternate_24px.png";
    const labelStyle = {
      color: "#f97663",
      fontSize: 12,
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignContent: "center",
      margin: 10
    };
    const containerStyle = {
      width: 180,
      border: "2px dashed #f97663",
      borderRadius: 5,
      boxShadow: "none",
      margin: 0
    };
    const button = {
      padding: 0,
      color: "#fff",
      borderRadius: 1,
      backgroundImage: `url(${DEFAULT_IMAGE})`,
      backgroundSize: "cover",
      backgroundColor: "transparent",
      width: 70,
      height: 70
    };
    return (
      <div style={containerStyle}>
        <ImageUploader
          buttonText={false}
          buttonStyles={button}
          withLabel={false}
          fileContainerStyle={{
            padding: 0,
            boxShadow: "none",
            background: "none"
          }}
          labelStyles={labelStyle}
          onChange={e => this.props.onChange(e[0])}
          imgExtension={[".jpg", ".gif", ".png", ".gif"]}
          maxFileSize={5242880}
          withPreview={true}
          withIcon={false}
          singleImage={false}
        />
        <div style={labelStyle}>
          Upload below 5MB | Square(1:1) .JPEG | .PNG
        </div>
      </div>
    );
  }
}
export default ProductImageUpload;
