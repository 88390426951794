import React from "react";
import { Modal, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import SentroButton from "../common/sentroButton";

import DashboardActions from "./dashboardActions";

require("dotenv").config();

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: "#f5f5f4",
    borderRadius: "4px",
    padding: theme.spacing(4, 6, 4)
  }
}));

const DeletedDialogBox = props => {
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  const classes = useStyles();
  const dispatch = useDispatch();

  const [modalStyle] = React.useState(getModalStyle);

  function closeDialog() {
    dispatch(DashboardActions.closeDialog());
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title" style={{ fontWeight: "bold" }}>
          Your online shop is now deleted
        </h2>
        <p id="simple-modal-description">
          <Box style={{ marginTop: 30, display: " flex" }}>
            <SentroButton
              name="close-button"
              id="close_button"
              variant="outlined"
              style={{
                width: "100%",
                border: "2px solid #F97663",
                color: "#F97663"
              }}
              label="Go back to dashboard"
              onClick={() => closeDialog()}
            />
          </Box>
        </p>
      </div>
    </Modal>
  );
};

export default DeletedDialogBox;
