import React from "react";
import { useSelector } from "react-redux";
import PreviewBannerTemplate from "./banner/previewBannerTemplate";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
const PreviewBanner = props => {
  let storeValue = useSelector(state => state.store.store);

  let storeInfo = props.isPreview ? props.store : storeValue;

  let isPreview = props.isPreview;
  let theme = useTheme();
  let mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <PreviewBannerTemplate
        mobile={mobile}
        setOnClick={props.setOnClick}
        setImageSettings={props.setImageSettings}
        storeInfo={storeInfo}
        store={props.store}
        isPreview={isPreview}
      />
    </div>
  );
};

export default PreviewBanner;
