import DashboardServices from "./dashboardServices";
import { showAlert } from "../common/commonActions";
import history from "../../store/history";
import Path from "../../Paths";
import apiConfig from "../../common/apiConfig";
import { signOff } from "../auth/authActions";

export const DashboardActionTypes = {
  GET_STORES_REQUEST: "@@GET_STORES_REQUEST",
  GO_TO_BUX: "@@GO_TO_BUX",
  GET_STORES_SUCCESS: "@@GET_STORES_SUCCESS",
  GET_STORES_FAILED: "@@GET_STORES_FAILED",
  GET_STORES_CLEAR: "@@GET_STORES_CLEAR",
  OPEN_DELETE_DIALOG: "@@OPEN_DELETE_DIALOG",
  CLOSE_DELETE_DIALOG: "@@CLOSE_DELETE_DIALOG",
  GET_STATUS: "@@GET_STATUS",

  // FB chat
  SAVE_FB_PAGE_ID: "SAVE_FB_PAGE_ID",
  SAVE_FB_PAGE_ID_SUCCESS: "SAVE_FB_PAGE_ID_SUCCESS",

  // FB Pixl
  SAVE_FB_PIXEL_ID: "SAVE_FB_PIXEL_ID",
  SAVE_FB_PIXEL_ID_SUCCESS: "SAVE_FB_PIXEL_ID_SUCCESS",

  SAVE_KYC_SURVEY: "SAVE_KYC_SURVEY",
  SAVE_KYC_SURVEY_SUCCESS: "SAVE_KYC_SURVEY_SUCCESS",
  SAVE_KYC_SURVEY_FAILED: "SAVE_KYC_SURVEY_FAILED",
  SKIP_SURVEY: "SKIP_SURVEY",

  GET_CUSTOM_DOMAIN: "GET_CUSTOM_DOMAIN",
  GET_CUSTOM_DOMAIN_SUCCESS: "GET_CUSTOM_DOMAIN_SUCCESS",
  SAVE_CUSTOM_DOMAIN: "SAVE_CUSTOM_DOMAIN",
  SAVE_CUSTOM_DOMAIN_SUCCESS: "SAVE_CUSTOM_DOMAIN_SUCCESS",
  CHECK_DNS: "CHECK_DNS",
  CHECK_DNS_SUCCESS: "CHECK_DNS_SUCCESS",
  CLOSE_LOADING: "CLOSE_LOADING",

  AGREED_TNC: "AGREED_TNC",
  SKIP_UPDATE_PASSWORD: "SKIP_UPDATE_PASSWORD",
  OPEN_SKIP_MODAL: "OPEN_SKIP_MODAL",
  CLOSE_SKIP_MODAL: "CLOSE_SKIP_MODAL",

  GET_ANALYTICS: "dashboard/GET_ANALYTICS"
};

class DashboardActions {
  static getStores() {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.GET_STORES_REQUEST });
        let response = await DashboardServices.getStores();
        dispatch({
          type: DashboardActionTypes.GET_STORES_SUCCESS,
          payload: response
        });
        return response;
      } catch (error) {
        console.log(error.message);
        if (error && error.status == 401) {
          dispatch(signOff());
        } else {
          dispatch({
            type: DashboardActionTypes.GET_STORES_FAILED,
            payload: error
          });
          // dispatch(
          //   showAlert({ type: "error", message: error ? error.message : "" })
          // );
        }
      }
    };
  }

  static closeDialog() {
    return async dispatch => {
      dispatch({ type: DashboardActionTypes.CLOSE_DELETE_DIALOG });
    };
  }
  static deleteStore(value) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.GET_STORES_REQUEST });
        await DashboardServices.deleteStore(value);
        dispatch({ type: DashboardActionTypes.OPEN_DELETE_DIALOG });
        let response = await DashboardServices.getStores();
        dispatch({
          type: DashboardActionTypes.GET_STORES_SUCCESS,
          payload: response
        });
        history.push(Path.DASHBOARD);
        return response;
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        } else {
          dispatch({
            type: DashboardActionTypes.GET_STORES_FAILED,
            payload: error.message
          });
        }
      }
    };
  }

  static goToBux(value) {
    return async dispatch => {
      try {
        let response = await DashboardServices.goToBux(value);
        let access = response.data ? response.data.access : null;
        if (access) {
          window.location.href = `${apiConfig.buxURL}/sentro/login?access=${access}`;
        }
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        }
      }
    };
  }

  static goToCompleteProfile(value, status) {
    return async dispatch => {
      try {
        let response = await DashboardServices.goToBux(value);
        let access = response.data ? response.data.access : null;
        if (access) {
          if (parseInt(status) != 0) {
            window.open(`${apiConfig.buxURL}/sentro/login?access=${access}`);
          } else {
            window.open(`${apiConfig.buxURL}/sentro/login?access=${access}`);
          }
        }
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        }
      }
    };
  }

  static getBuxStatus(value) {
    return async dispatch => {
      try {
        let response = await DashboardServices.goBuxStatus(value);
        let access = response ? response.data : "";

        dispatch({
          type: DashboardActionTypes.GET_STATUS,
          payload: access.kyc_level
        });
        return access;
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        }
      }
    };
  }

  static saveFBPageID(store, fb_page_id) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.SAVE_FB_PAGE_ID });
        let response = await DashboardServices.saveFBPageID(store, fb_page_id);
        dispatch({
          type: DashboardActionTypes.SAVE_FB_PAGE_ID_SUCCESS,
          payload: response
        });
        return response;
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        } else {
          dispatch(
            showAlert({ type: "error", message: error ? error.message : "" })
          );
        }
      }
    };
  }

  static saveFBPixelID(store, fb_pixel_id) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.SAVE_FB_PIXEL_ID });
        let response = await DashboardServices.saveFBPixelID(
          store,
          fb_pixel_id
        );
        dispatch({
          type: DashboardActionTypes.SAVE_FB_PIXEL_ID_SUCCESS,
          payload: response
        });
        return response;
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        } else {
          dispatch(
            showAlert({ type: "error", message: error ? error.message : "" })
          );
        }
      }
    };
  }

  static saveKYCSurvey(value) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.SAVE_KYC_SURVEY });
        let response = await DashboardServices.saveKYCSurvey(value);
        dispatch({
          type: DashboardActionTypes.SAVE_KYC_SURVEY_SUCCESS,
          payload: response
        });
        return response;
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        } else {
          dispatch({ type: "error", message: error ? error.message : "" });
        }
      }
    };
  }

  static skipSurvey() {
    return async dispatch => {
      dispatch({ type: DashboardActionTypes.SAVE_KYC_SURVEY });
      let existing = sessionStorage.getItem("CURRENT_USER");
      existing = existing ? JSON.parse(existing) : {};
      existing["survey_answered"] = true;
      sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
      dispatch({ type: DashboardActionTypes.SKIP_SURVEY });
    };
  }

  static agreedTNC(value) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.SAVE_KYC_SURVEY });
        let response = await DashboardServices.agreedTNC(value);
        dispatch({
          type: DashboardActionTypes.AGREED_TNC,
          payload: response
        });
        return response;
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        } else {
          dispatch({ type: "error", message: error ? error.message : "" });
        }
      }
    };
  }

  static skipUpdatePassword(value) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.SAVE_KYC_SURVEY });
        let response = await DashboardServices.skipPassword(value);
        dispatch({
          type: DashboardActionTypes.SKIP_UPDATE_PASSWORD,
          payload: response
        });
        dispatch({ type: DashboardActionTypes.OPEN_SKIP_MODAL });
        return response;
      } catch (error) {
        if (error && error.status == 401) {
          dispatch(signOff());
        } else {
          dispatch({ type: "error", message: error ? error.message : "" });
        }
      }
    };
  }

  static closeSkipModal() {
    return async dispatch => {
      dispatch({ type: DashboardActionTypes.CLOSE_SKIP_MODAL });
    };
  }


  static saveCustomDomain(store_uuid, domain_name) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.SAVE_CUSTOM_DOMAIN,
        payload: {
            domain_name
        } });
        let response = await DashboardServices.saveCustomDomain(store_uuid, domain_name);
        dispatch({
          type: DashboardActionTypes.SAVE_CUSTOM_DOMAIN_SUCCESS,
          payload: response
        });
        return response;
      } catch (error) {
        if (error && error.status === 401) {
          dispatch(signOff());
        } else {
          dispatch({
            type: DashboardActionTypes.CLOSE_LOADING
          });
        }
      }
    };
  }
  static getCustomDomain(payload) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.GET_CUSTOM_DOMAIN });
        let response = await DashboardServices.getCustomDomain(payload);
        dispatch({
          type: DashboardActionTypes.GET_CUSTOM_DOMAIN_SUCCESS,
          payload: response.data,
          removeLoadingAfter: payload.removeLoadingAfter
        });
        console.log("actions", response)
        return response;
      } catch (error) {
        if (error && error.status === 401) {
          dispatch(signOff());
        } else {
          dispatch({
            type: DashboardActionTypes.CLOSE_LOADING
          });
        }
      }
    };
  }
  static checkDns(store_uuid) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.CHECK_DNS });
        let response = await DashboardServices.checkDns(store_uuid);
        dispatch({
          type: DashboardActionTypes.CHECK_DNS_SUCCESS,
          payload: response
        });
        return response;
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.CLOSE_LOADING
        });
        return false
      }
    };
  }
  static getAnalytics(params) {
    return async dispatch => {
      try {
        let response = await DashboardServices.getAnalytics(params);
        dispatch({ 
            type: DashboardActionTypes.GET_ANALYTICS,
            payload: response.data,
            params
        });
        return response;
      } catch (error) {
      }
    };
  }

}

export default DashboardActions;
