import axios from "axios";
import apiConfig from "../../common/apiConfig";

class SalesAndOrdesServices {
  // static async getDashboardData() {
  //   try {
  //     let response = await axios.get(
  //       `https://api-dev.bux.ubx.ph/api/dashboard`
  //     );
  //   }
  // }

  static async getLimit(value) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/kyc/?id=${value}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async goToBux(value) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/bux/login/`,
        { id: value },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }
}

export default SalesAndOrdesServices;
