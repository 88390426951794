import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import ACL from "../common/utilities/ACL"
import Action from "./Redux/Actions"
import AdminDashboardLayout from "./layout"
import apiConfig from "../../common/apiConfig";
import { stringify_get_params } from "../common/utilities/DateFilter"
import AdminTable from './components/adminTable'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const headCells = [
	{ id: 'paid', numeric: false, disablePadding: false, label: 'Status' },
	{ id: 'channel', numeric: false, disablePadding: false, label: 'Channel' },
	{ id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
	{ id: 'ref_id', numeric: false, disablePadding: true, label: 'Ref ID' },
	{ id: 'ref_code', numeric: false, disablePadding: false, label: 'Ref Code' },
	{ id: 'paid_date', numeric: false, disablePadding: false, label: 'Paid Date' },
	{ id: 'store__name', numeric: false, disablePadding: false, label: 'Shop' },
	{ id: 'store__owner__email', numeric: false, disablePadding: false, label: 'Owner' },
	{ id: 'recipient', numeric: false, disablePadding: false, label: 'Recipient' },
	{ id: 'created_on', numeric: false, disablePadding: false, label: 'Date Created' },
];

const pageStyles = theme => ({
	paper: {
		overflow: "hidden",
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(1),
		},
	},
	card: {
		backgroundColor: "white",
		padding: 0
	},
	cardHeader: {
		fontSize: "28px",
		marginBottom: "0.5em",
		textAlign: "left",
		color: "#F97663",
		fontWeight: 600
	},
	textDivider: {
		fontSize: "16px",
		padding: "1.5em 0",
		marginBottom: 0
	},
	miniCardHeader: {
		fontSize: "16px",
		textAlign: 'left',
		color: "#F97663",
		paddingBottom: "0.5em",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
		},
	},
	miniCardBody: {
		fontSize: "28px",
		weight: "bold",
		textAlign: 'left',
		color: theme.palette.text.primary,
	},
	lineChart: {
		paddingLeft: "auto",
		paddingRight: "auto",
		paddingTop: "2em",
		position: "relative",
		height: "400px",
		width: "100%",
		display: "block"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	pageHeaderButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		marginRight: 20
	},
	searchBar: {
		background: "white",
		maxWidth: "240px",
		borderRadius: "4px",
		color: "gray",
		fontSize: 12,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "100%",
			width: "100%"
		},
	},
	excelExportButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		border: "solid 1px #F97663",
		background: " #F97663",
		borderRadius: 4,
		marginRight: 10,
		color: "white",
		transition: "all 0.3s linear",
		"&:hover": {
			background: "#f96a63"
		},
		"&:focus": {
			outline: 0
		},
		boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
	},
	headerActionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		},
	},
	searchInputWrapper: {
		display: "flex"
	},
	searchInput: {
		flexGrow: 1
	},
	tableWrapper: {
		overflowX: "auto"
	},
	primaryColor: {
		color: theme.palette.text.primary
	},
	dFlex: {
		display: "flex"
	}
});

class Checkouts extends React.Component {
	constructor(props) {
		super(props);
		this.searchTimeout = 0; // trigger fetch after a typing inactivity
		this.state = {
			// filters
			date_filter_query: "",
			order: "",
			orderBy: "",
			search_query: "",
			page: 0,
			rowsPerPage: 5,
		};
		this.onSearch = this.onSearch.bind(this)
		this.onDateFilterCallback = this.onDateFilterCallback.bind(this)
		this.fetch = this.fetch.bind(this)
		this.onSortRequest = this.onSortRequest.bind(this)
		this.constructFilterQuery = this.constructFilterQuery.bind(this)
	}

	fetch = (query_filters, is_csv=false, filename="") => {
		const { dispatch } = this.props
		dispatch(Action.fetchOrders(query_filters, is_csv, filename))
	}

	downloadCSV(){
		this.fetch(
			this.constructFilterQuery()+"&csv_download=True", 
			true,
			"Sentro - Orders.csv"
		);
	}

	constructFilterQuery(){
		const { location } = this.props;
		return (this.state.date_filter_query + 
			"&order=" + this.state.order + 
			"&orderBy=" + this.state.orderBy + 
			"&page=" + this.state.page +
			"&rowsPerPage=" + this.state.rowsPerPage +
			this.state.search_query + "&" +
			location.search.slice(1)
		)
	}

	onSearch(event) {
		const search_query = event.target.value;

		if(this.searchTimeout) clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.setState({
				search_query: `&q=${search_query.toLowerCase()}`,
				page: 0
			}, () => {
				console.log(this.constructFilterQuery())
				this.fetch(this.constructFilterQuery());
			})
		}, 500);
	}

	componentDidMount = async () => {
		const { adminDashboard } = this.props
		const date_filter_query = stringify_get_params(adminDashboard.dateFilter)
		this.setState({
			date_filter_query
		}, () => {
			console.log(this.constructFilterQuery());
			this.fetch(this.constructFilterQuery());
		})
	}
	
	onDateFilterCallback = async (filter) => {
		const date_filter_query = stringify_get_params(filter)
		this.setState({
			date_filter_query,
			page: 0
		}, () => {
			console.log(this.constructFilterQuery());
			this.fetch(this.constructFilterQuery());
		})
	}

	onSortRequest(order, orderBy, page, rowsPerPage){
		this.setState({
			order,
			orderBy,
			page,
			rowsPerPage
		}, () => {
			console.log(this.constructFilterQuery())
			this.fetch(this.constructFilterQuery());
		})
	}
	
	render (){
		const { classes, adminDashboard, history } = this.props
		return (
			<AdminDashboardLayout onDateFilterCallback={this.onDateFilterCallback}>
				<ACL roles={["admin", "superuser"]} redirect_to="/login">
					<div style={{
						display: "flex", 
						flexWrap: "wrap",
						alignItems: "center",
						marginBottom: "1.5em",
						padding: "0 1.5em"
					}}>
						<div className={classes.pageTitle}>
							Orders
						</div>
						<div className={classes.headerActionsWrapper}>
							{/* {
								filteredList ? 
								<TableExport
									class={classes.excelExportButton}
									filename="Sentro - Checkout List"
									sheet="Checkouts"
									table={<EnhancedTable checkoutsList={filteredList} rowsPerPage={filteredList.length }/>}
								/>
								:
								null
							} */}
							{
								adminDashboard.loading && 
								<CircularProgress color="secondary" style={{height: 25, width: 25, color: "#f97663", marginRight: 20}}/>
							}
							<Button
								id="add_user_button" 
								variant="contained" 
								color="primary" 
								disableElevation 
								className={classes.pageHeaderButton}
								onClick={() => this.downloadCSV()}
							>
								Download
							</Button>
							<OutlinedInput
								className={classes.searchBar}
								id="outlined-adornment-weight"
								onChange={(event) => this.onSearch(event)}
								startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
								'aria-label': 'weight',
								}}
								labelWidth={0}
								margin="dense"
								placeholder="Type here to search"
							/>
						</div>
					</div>
					<div style={{ padding: "0 1.5em 1em"}}>
						<Paper className={classes.card}>
							<AdminTable 
								loading={adminDashboard.loading}
								headCells={headCells}
								body={<TableBody>
										{adminDashboard.ordersList.map((row, index) => {
											return (
												<TableRow
													key={row.ref_id}
													hover
													role="checkbox"
													tabIndex={-1}
												>
													<TableCell align="left">{row.status}</TableCell>
													<TableCell align="left">{row.channel}</TableCell>
													<TableCell component="th" scope="row" style={{ marginLeft: "1em"}}>	
														{row.amount}
													</TableCell>
													<TableCell align="left">{row.ref_id}</TableCell>
													<TableCell align="left">{row.ref_code ? row.ref_code : "-"}</TableCell>
													<TableCell align="left">{row.paid_date}</TableCell>
													<TableCell align="left">
														<a href={`${apiConfig.localHost}shop/${row.shop_link}`} target="_blank">
															{row.shop}
														</a>
													</TableCell>
													<TableCell align="left">
														<a href={`mailto:${row.shop_owner_email}`} target="_blank">
															{row.shop_owner_email}
														</a>
													</TableCell>
													<TableCell align="left">
														<a href={`mailto:${row.recipient}`} target="_blank">
															{row.recipient}
														</a>
													</TableCell>
													<TableCell align="left">{row.created_on}</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								}
								length={adminDashboard.listLength}
								page={this.state.page}
								rowsPerPage={this.state.rowsPerPage}
								onSortRequest={this.onSortRequest}
							/>
						</Paper>
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(pageStyles)(Checkouts))
