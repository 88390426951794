import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Radio } from "antd";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  TextField,
  Select,
  MenuItem,
  Button,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { bankListing } from "../../../../websiteBuilder/forms/bankContants";
import { useSelector, useDispatch } from "react-redux";
import { removePayment } from "../webBuilder/websiteBuilderActions";
import ErrorIcon from "@material-ui/icons/Error";
const DeleteWarningForm = props => {
  const { open, setOpen, handleDeleteAccount } = props;
  const useStyles = makeStyles(theme => ({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content"
    },
    icon: {
      width: "100%",
      textAlign: "center",
      color: "#ee2545",
      fontSize: 100
    },
    subheader: {
      padding: "10px 0",
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42"
    },
    label: { fontSize: 14, color: "#54575f" }
  }));
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle
        style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
      >
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: 20 }}>
        <div className={classes.form}>
          <ErrorIcon className={classes.icon} />
          <div style={{ textAlign: "center" }}>
            <div className={classes.subheader}>
              Are you sure you want to delete the record?
            </div>
          </div>
          <div style={{ paddingTop: "5vh" }}>
            <Button
              onClick={() => handleDeleteAccount()}
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
            >
              Yes, please delete my record
            </Button>
            <Button
              onClick={() => setOpen(false)}
              variant="text"
              color="primary"
              fullWidth
            >
              No, take me back
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const DialogTransition = props => <Slide direction="up" {...props} />;
const ViewAccountForm = props => {
  const { newPaymentMethod, paymentMethod, setPaymentMethod } = props;
  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    appBar: { position: "absolute", backgroundColor: "#fafafa" },
    closeBtn: { position: "absolute", left: 15 },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    imgLayout: {
      width: 90,
      height: 90,
      margin: 5,
      borderRadius: 5
    },
    label: { fontSize: 14, color: "#54575f" },
    actionBtn: {
      color: "#f97663",
      display: "flex",
      justifyContent: "flex-end",
      margin: 5,
      cursor: "pointer",
      position: "absolute",
      top: 5,
      right: 5
    }
  }));

  const [openDelete, setOpenDelete] = React.useState(false);
  function setPaymentMethodDetails(key, value) {
    setPaymentMethod(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  const handleDeleteAccount = async () => {
    let res = await dispatch(removePayment(paymentMethod));
    try {
      if (res.status === 204) {
        setOpenDelete(false);
        setPaymentMethod({ ...newPaymentMethod, viewAccount: false });
      }
    } catch {
      setOpenDelete(false);
      setPaymentMethod({ ...newPaymentMethod, viewAccount: false });
      console.log(res.status);
    }
  };
  const classes = useStyles();

  const accountType = [
    { value: "Bank" },
    { value: "GCash" },
    { value: "PayMaya" },
    { value: "PayPal" }
  ];

  return (
    <Dialog
      fullScreen
      open={paymentMethod.viewAccount}
      TransitionComponent={DialogTransition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setPaymentMethod({ ...newPaymentMethod })}
            className={classes.closeBtn}
          >
            <CloseIcon style={{ color: "#f97663" }} />
          </IconButton>
          <div className={classes.header}>View Account</div>
        </Toolbar>
      </AppBar>
      <div style={{ padding: "12vh 20px", overflowY: "scroll" }}>
        <div>
          <div className={classes.label}>Account Type</div>
          <Radio.Group
            style={{ width: "100%" }}
            defaultValue={paymentMethod.payment_method}
            onChange={e =>
              setPaymentMethodDetails("account_type", e.target.value)
            }
          >
            {accountType.map(item => {
              return (
                <Radio
                  disabled
                  style={{ width: "50%", margin: "1vh 0" }}
                  value={item.value}
                >
                  {item.value}
                </Radio>
              );
            })}
          </Radio.Group>
        </div>
        {paymentMethod.payment_method === "PayPal" ? (
          ""
        ) : (
          <div style={{ marginTop: "3vh" }}>
            <div
              style={{ fontSize: 14, color: "#54575f", marginBottom: "1vh" }}
            >
              QR Code
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ position: "relative" }}>
                <img
                  src={paymentMethod.qr_code_img}
                  className={classes.imgLayout}
                />
              </div>
            </div>
          </div>
        )}

        {paymentMethod.payment_method === "Bank" ? (
          <div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Bank Name</div>
              <Select
                disabled
                color="secondary"
                margin="dense"
                id="bank_name"
                variant="outlined"
                fullWidth
                value={paymentMethod.bank_name}
                onChange={e =>
                  setPaymentMethodDetails("bank_name", e.target.value)
                }
              >
                {bankListing.map((item, key) => {
                  return <MenuItem value={item.name}>{item.name}</MenuItem>;
                })}
              </Select>
            </div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Account Name</div>
              <TextField
                disabled
                style={{ marginTop: 0 }}
                margin="dense"
                variant="outlined"
                fullWidth
                color="secondary"
                value={paymentMethod.account_name}
                onChange={e =>
                  setPaymentMethodDetails("account_name", e.target.value)
                }
              />
            </div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Account Number</div>
              <TextField
                disabled
                style={{ marginTop: 0 }}
                margin="dense"
                variant="outlined"
                fullWidth
                color="secondary"
                value={paymentMethod.account_number}
                onChange={e =>
                  setPaymentMethodDetails("account_number", e.target.value)
                }
              />
            </div>
          </div>
        ) : paymentMethod.payment_method === "PayPal" ? (
          <div style={{ marginTop: "3vh" }}>
            <div className={classes.label}>Payment Link</div>
            <TextField
              disabled
              style={{ marginTop: 0 }}
              margin="dense"
              variant="outlined"
              fullWidth
              color="secondary"
              value={paymentMethod.payment_link}
              onChange={e =>
                setPaymentMethodDetails("payment_link", e.target.value)
              }
            />
          </div>
        ) : (
          <div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Account Name</div>
              <TextField
                disabled
                style={{ marginTop: 0 }}
                margin="dense"
                variant="outlined"
                fullWidth
                color="secondary"
                value={paymentMethod.account_name}
                onChange={e =>
                  setPaymentMethodDetails("account_name", e.target.value)
                }
              />
            </div>
            <div style={{ marginTop: "3vh" }}>
              <div className={classes.label}>Mobile Number</div>
              <TextField
                disabled
                style={{ marginTop: 0 }}
                margin="dense"
                variant="outlined"
                fullWidth
                color="secondary"
                value={paymentMethod.mobile_number}
                onChange={e =>
                  setPaymentMethodDetails("mobile_number", e.target.value)
                }
              />
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          padding: 20,
          width: "100%",
          bottom: 0,
          position:
            paymentMethod.account_type === "Bank" ? "relative" : "absolute"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Button
            style={{ height: 38 }}
            variant="text"
            color="secondary"
            onClick={() => setOpenDelete(true)}
          >
            Delete
          </Button>

          <Button
            style={{ height: 38, width: 85 }}
            variant="outlined"
            color="primary"
            onClick={() =>
              setPaymentMethod({
                ...paymentMethod,
                viewAccount: false,
                editAccount: true
              })
            }
          >
            Edit
          </Button>
        </div>
      </div>
      <DeleteWarningForm
        open={openDelete}
        setOpen={setOpenDelete}
        handleDeleteAccount={handleDeleteAccount}
      />
    </Dialog>
  );
};

export default ViewAccountForm;
