import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DashboardLayout from "../dashboard/dashboardLayout";
import FilterHeader from "./components/filterheader";
import AppCard from "./components/appCard";
import Actions from "./Redux/Actions";
import { animateScroll as scroll } from 'react-scroll'


const useStyles = theme => ({
  root: {
    padding: "1em 5em 0",
    // marginTop: 188,
    backgroundColor: 'rgb(250, 250, 250)',
    // boxShadow: 'inset 10px 0 10px -10px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down("sm")]: {
      padding: "0 2em",
    //   marginTop: 310
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 1em"
    },
    paddingBottom: "1em",
    // backgroundColor: "#fafafa",
    minHeight: '120vh'
  }
});

class AppMarketplace extends React.Component {

  componentDidMount(){
    const { dispatch } = this.props;
    dispatch(Actions.fetchApps());
    scroll.scrollTo(0)
  }

  render (){
    const { classes, apps } = this.props;
    return (
      <DashboardLayout margin="0" marginTop="68px" right="0" left="280px">
        {/* <FilterHeader /> */}
        <div className={classes.root}>
          {
            apps && apps.map((app) => (
              <AppCard key={app.id} data={app} />
            ))
          }
        </div>
      </DashboardLayout>
    )
  }
}


const mapStateToProps = state => ({ marketplace }) => ({ 
  apps: marketplace.apps
});
export default connect(mapStateToProps)(withStyles(useStyles)(AppMarketplace));

