import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { addVariantGroupAction } from "../../websiteBuilderActions";
import FreeTrialModal from "../../../subscription/components/products/freeTrialModal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddVariantGroup = props => {
  const {
    open,
    setOpen,
    isMobile,
    productVariants,
    setProductVariants
  } = props;
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 16,
      fontWeight: 600,
      color: "#2b2d32"
    },
    layout: {
      display: "flex",
      alignItems: "center"
    },
    btn: {
      fontSize: 12,
      padding: "5px 10px",
      width: "fit-content",
      height: 35,
      marginBottom: 5,
      backgroundColor: "#eee",
      textTransform: "capitalize"
    },
    variantList: {
      maxHeight: 140,
      height: "auto",
      overflowY: "auto",
      marginTop: "2vh",
      display: "flex",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#f97663"
      }
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = React.useState({
    group_name_error: "",
    variant_error: ""
  });
  const [variant, setVariant] = React.useState({
    name: "",
    variants: []
  });
  const variants = variant ? variant.variants : [];
  const [addVariant, setAddVariant] = React.useState("");
  const [openFreeTrial, setOpenFreeTrial] = React.useState(false);
  const group_names =
    productVariants.length &&
    productVariants
      .filter(item => item.name !== variant.name)
      .map(item => item.name.toLowerCase());

  console.log(variants);
  function handleAddVariant() {
    if (variants ? variants.length > 2 : "") {
      setOpenFreeTrial(true);
    } else {
      let list = variants ? variants.map(e => e.name.toLowerCase()) : [];
      if (addVariant === "") {
        setError({
          ...error,
          variant_error: "This field is required."
        });
      } else if (list.indexOf(addVariant.toLowerCase()) !== -1) {
        setError({
          ...error,
          variant_error: "Variant already exist"
        });
      } else {
        setError({ ...error, variant_error: "" });
        setVariant({
          ...variant,
          variants: [...variants, { name: addVariant, is_enabled: false }]
        });
        setAddVariant("");
      }
    }
  }

  function handleRemoveVariant(index) {
    variants.splice(index, 1);
    setVariant({ ...variant, variants: variants });
  }

  async function handleAddVariants() {
    let newProductVariants = productVariants.filter(
      e => e.name !== variant.name
    );
    setProductVariants([...newProductVariants, variant]);
    setOpen({ ...open, addVariantModal: false });
    setVariant({
      name: "",
      variants: []
    });
    setAddVariant("");
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open.addVariantModal}>
      <DialogContent style={{ padding: 0, position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => {
              setOpen({ ...open, addVariantModal: false });
              setVariant({
                name: "",
                variants: []
              });
              setAddVariant("");
            }}
          >
            <CloseIcon color="secondary" />
          </IconButton>
        </div>
        <div style={{ padding: isMobile ? "0 25px 40px" : "0 40px 40px" }}>
          <div className={classes.header}>Variants</div>
          <div style={{ marginTop: "2vh" }}>
            <div style={{ fontSize: 12, color: "#54575f" }}>Group Name</div>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              color="secondary"
              style={{ marginTop: 0 }}
              value={variant.name}
              error={error.group_name_error}
              helperText={error.group_name_error}
              onChange={e => {
                setVariant({ ...variant, name: e.target.value });
                if (e.target.value === "") {
                  setError({
                    ...error,
                    group_name_error: "This field is required."
                  });
                } else if (group_names.includes(e.target.value.toLowerCase())) {
                  setError({
                    ...error,
                    group_name_error: "Group name already exist."
                  });
                } else {
                  setError({ ...error, group_name_error: "" });
                }
              }}
            />
          </div>

          <div style={{ marginTop: "2vh" }}>
            <div style={{ fontSize: 12, color: "#54575f" }}>Variant Name</div>
            <div className={classes.layout}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                color="secondary"
                style={{ marginTop: 0, marginBottom: 0 }}
                value={addVariant}
                onChange={e => setAddVariant(e.target.value)}
                error={error.variant_error}
                helperText={error.variant_error}
              />
              <Button
                style={{ marginLeft: 5, height: 40 }}
                variant="outlined"
                color="primary"
                onClick={handleAddVariant}
                disabled={variants ? variants.length > 2 : false}
              >
                Add
              </Button>
              <FreeTrialModal open={openFreeTrial} setOpen={setOpenFreeTrial} />
            </div>
            <div className={classes.variantList}>
              {variants && variants.length > 0
                ? variants.map((item, index) => {
                    return (
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.btn}
                        endIcon={<CloseIcon />}
                        onClick={() => handleRemoveVariant(index)}
                      >
                        {item.name}
                      </Button>
                    );
                  })
                : ""}
            </div>
          </div>
          <div style={{ marginTop: "5vh" }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              style={{ height: 40 }}
              onClick={handleAddVariants}
              disabled={error.group_name_error || error.variant_error}
            >
              Add Variants
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              style={{ height: 40, marginTop: 8 }}
              onClick={() => {
                setVariant({
                  name: "",
                  variants: []
                });
                setAddVariant("");
                setOpen({ ...open, addVariantModal: false });
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddVariantGroup;
