import axios from "axios";
import apiConfig from "../../common/apiConfig";

export const asyncValidations = (values, dispatch, props, field) => {
  if (field === "name" && values.name !== props.initialValues.name) {
    // check only if diff from initialValue
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiConfig.apiURL}/api/enterprise/is-unique/`, {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          },
          params: {
            name: values.name
          }
        })
        .then(res => {
          throw { name: `${values.name} already exists` };
        })
        .catch(err => resolve(err));
    }).then(err => {
      if (err.response) {
        delete props.asyncErrors["name"];
        throw props.asyncErrors;
      } else {
        throw { ...props.asyncErrors, ...err };
      }
    });
  } else if (
    field === "site_name" &&
    values.site_name !== props.initialValues.site_name
  ) {
    // check only if diff from initialValue
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiConfig.apiURL}/api/enterprise/is-unique/`, {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          },
          params: {
            site_name: values.site_name
          }
        })
        .then(res => {
          throw { site_name: `${values.site_name} already exists` };
        })
        .catch(err => resolve(err));
    }).then(err => {
      if (err.response) {
        delete props.asyncErrors["site_name"];
        throw props.asyncErrors;
      } else {
        throw { ...props.asyncErrors, ...err };
      }
    });
  }
  return new Promise((resolve, reject) => resolve());
};

export const passwordAsyncValidation = (values, dispatch, props, field) => {
  if (["new_password", "confirm_new_password"].includes(field)) {
    return new Promise((resolve, reject) => {
      if (values.new_password !== values.confirm_new_password) {
        throw {
          new_password: `Passwords do not match.`,
          confirm_new_password: `Passwords do not match.`
        };
      } else {
        resolve({});
      }
    });
  }
  return new Promise((resolve, reject) => resolve());
};

export const asyncStoreValidations = (values, dispatch, props, field) => {
  if (field === "name" && values.name) {
    // check only if diff from initialValue
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiConfig.apiURL}/api/unique-store/`, {
          params: {
            name: values.name
          }
        })
        .then(res => {
          throw { name: `This name is already taken` };
        })
        .catch(err => resolve(err));
    }).then(err => {
      if (err.response) {
        delete props.asyncErrors["name"];
        throw props.asyncErrors;
      } else {
        throw { ...props.asyncErrors, ...err };
      }
    });
  } else if (field === "site" && values.site) {
    // check only if diff from initialValue
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiConfig.apiURL}/api/unique-store/`, {
          params: {
            site: values.site
          }
        })
        .then(res => {
          throw { site: `This address is already registered in Sentro` };
        })
        .catch(err => resolve(err));
    }).then(err => {
      if (err.response) {
        delete props.asyncErrors["site"];
        throw props.asyncErrors;
      } else {
        throw { ...props.asyncErrors, ...err };
      }
    });
  }
  return new Promise((resolve, reject) => resolve());
};
