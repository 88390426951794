import apiConfig from "../../common/apiConfig";
import axios from "axios";
import moment from "moment";

class OrderServices {
  static async getOrders(params) {
    params = {
      ...params,
      delivery_status: params.delivery_status.toString()
    };
    console.log(params);
    let response = await axios.get(
      `${apiConfig.apiURL}/api/dashboard/orders/`,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        },
        params
      }
    );
    return response;
    // return orderList;
  }

  static async getOrder(id) {
    let response = await axios.get(`${apiConfig.apiURL}/api/order/${id}`, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
      }
    });
    return response.data;
    // return orderList;
  }

  static async cancelDelivery(mrspeedyid) {
    // let response = await axios.post(
    //   `${apiConfig.apiURL}/api/mrspeedy/cancel_order/${mrspeedyid}/`,
    //   {
    //     headers: {
    //       Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
    //     },
    //   }
    // );
    let response = await axios.post(
      `${apiConfig.apiURL}/api/mrspeedy/cancel_order/${mrspeedyid}/`,
      {},
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
    // return orderList;
  }

  static async cancelOrder(value) {
    let response = await axios.put(
      `${apiConfig.apiURL}/api/cancel-order/${value.id}/`,
      {},
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
    // return orderList;
  }

  static async updatePayment(values) {
    let params = {
      paid: true,
      channel: values.channel,
      date: values.date
    };
    let response = await axios.put(
      `${apiConfig.apiURL}/api/order/${values.id}/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
    // return orderList;
  }

  static async updateDelivery(values) {
    let params = {
      delivered: true,
      delivery_method: values.delivery_method,
      date: values.date
    };
    let response = await axios.put(
      `${apiConfig.apiURL}/api/order/${values.id}/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
    // return orderList;
  }

  static async calculateShipping(value) {
    let params = {
      src_address: value.src_address,
      dst_address: value.delivery_address,
      src_coord: { latitude: value.src_lat, longitude: value.src_long },
      dst_coord: {
        latitude: value.delivery_lat,
        longitude: value.delivery_long
      },
      matter: value.matter,
      vehicle_type: parseInt(value.vehicle_type),
      weight_kg: parseInt(value.weight_kg),
      motobox: value.motobox
    };
    let response = await axios.post(
      `${apiConfig.apiURL}/api/mrspeedy/calculate_order/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
  }

  static async bookShipping(value) {
    let params = {
      order: value.order_id,
      item_type: value.matter,
      sender_contact: value.src_mobile,
      sender_name: value.src_name,
      sender_address: value.src_address,
      src_coord: { latitude: value.src_lat, longitude: value.src_long },
      dst_coord: {
        latitude: value.delivery_lat,
        longitude: value.delivery_long
      },
      pickup_datetime: moment(value.date_time).format(),
      delivery_datetime: moment(value.date_time).format(),
      vehicle_type: parseInt(value.vehicle_type),
      weight_kg: parseInt(value.weight),
      motobox: value.motobox
    };
    let response = await axios.post(
      `${apiConfig.apiURL}/api/mrspeedy/create_order/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
  }

  static async setOrderRead(values) {
    let params = {
      is_read: true
    };
    let response = await axios.put(
      `${apiConfig.apiURL}/api/order/${values.id}/`,
      params,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      }
    );
    return response.data;
  }

  static async getPickupAddresses(store_uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/pickup-addresses/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params: {
            store_uuid
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default OrderServices;
