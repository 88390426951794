import React, { useState } from 'react';
import { makeStyles, useMediaQuery, useTheme, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DashboardActions from './dashboardActions';
import CreateWebCard from './createWebCard';
import WebCard from './webCard';
import BuxCard from './buxCard';
import { FBChatCard } from './FBChat';
import { FBPixelCard } from './FBPixel';
import { CustomDomainCard } from './CustomDomain';
import KYCQuestions from './kycQuestions';
import UpdatePasswordNotice from './updatePasswordNotice';
import TNCNotice from './tncNotice';
import SentroBanner from './sentroBanner';
import SeekcapBanner from './seekcapBanner';
import PaymentIcon from '@material-ui/icons/Payment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {
  getStoreInfo,
  getStore,
  getBuxStatus,
} from '../websiteBuilder/websiteBuilderActions';
import ShopTools from './shopTools';
import history from '../../store/history';
import Path from '../../Paths';
import ActivateAccountBanner from './common/activateAccountBanner';
import ManageDeliveryBanner from './common/manageDeliveryBanner';
import Sections from './sections';
import Banner from '../subscription/components/dashboard/banner';
import MaterialUIAlert from '../common/material-ui-alert';
import Extended from '../subscription/components/dashboard/extended';
import BuxUpgrade from '../subscription/components/dashboard/buxUpgrade';
require('dotenv').config();

const Content = (props) => {
  const dispatch = useDispatch();
  let sites = useSelector((state) => state.webBuilder.stores);
  const subscription = useSelector((state) => state.subscription.subscription);
  let user = JSON.parse(sessionStorage.getItem('CURRENT_USER'));

  const [storeName, setStoreName] = useState('');

  React.useEffect(() => {
    async function fetchStore() {
      let res = await dispatch(getStore());
      if (res && !res.length) {
        history.push(Path.SHOP_NEW_CREATE);
      }
    }
    fetchStore();
  }, []);

  React.useEffect(() => {
    dispatch(getBuxStatus(user.id));
  }, []);

  const mobileBannerStyles = makeStyles((theme) => ({
    webcard: {
      [theme.breakpoints.down('md')]: {
        // display: "noneƒ"
      },
    },
    buxCard: {
      marginTop: 24,
      // [theme.breakpoints.only("md")]: {
      //   display: "none",
      // },
    },
  }));

  const classes = mobileBannerStyles();

  function triggerDelete(value) {
    // setDialog(true);
    setStoreName(value);
  }

  const has_pickup_address = sites.some((shop) =>
    shop.pickup_address ? shop.pickup_address.length : false
  );

  let language = props.language;
  return (
    <div className={classes.webcard}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div
          style={{
            fontSize: 28,
            fontWeight: 'bold',
            color: '#002C42',
          }}
        >
          My Shop
        </div>
      </div>
      {/*BANNERS*/}
      <BuxUpgrade />
      <Extended />
      <Banner />

      {!user.is_verified && (
        <div className="my-1 activate-banner">
          <ActivateAccountBanner />
        </div>
      )}
      <div className={classes.webcard}>
        {sites.length > 0 ? (
          sites.map((item, key) => {
            return <Sections stores={sites} language={language} />;
          })
        ) : (
          <CreateWebCard language={language} />
        )}
      </div>
      {/* HIDE SHOP TOOLS IF NO SHOP OR NOT VERIFIED */}
      {!user.is_verified || !sites.length ? (
        ''
      ) : (
        <div className={classes.buxCard}>
          <ShopTools user={user} language={language} />
        </div>
      )}
    </div>
  );
};

export default Content;
