import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Paper } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import icon from "../../assets/images/mediaLibraryIcon.svg";
import List from "./forms/list";
import AddImages from "./forms/addImagesForm";
import FreeTrial from "../subscription/freeTrial";
import MediaActions from "./redux/actions";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

export default function MediaLibrary(props) {
  const useStyles = makeStyles(theme => ({
    form: {
      backgroundColor: "#fff",
      borderRadius: 4,
      padding: "7em"
    },
    label: {
      "& .text": {
        color: "#2B2D32CC",
        fontSize: 14,
        fontWeight: "500",
        marginRight: "auto"
      },
      "& .button": {
        color: theme.palette.primary.main,
        cursor: "pointer",
        fontSize: 12,
        fontWeight: "600",
        "& svg": {
          height: 20,
          width: 20
        }
      }
    }
  }));

  const classes = useStyles();
  const subscription = useSelector(state => state.subscription.subscription);
  const dispatch = useDispatch();
  const [add, setAdd] = React.useState(false);
  const [params, setParams] = React.useState({
    page: 1,
    itemsPerPage: 12,
    search: "",
    sort_by: "",
    order: "-"
  });
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalItemCount, setTotalItemCount] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const images = useSelector(state => state.mediaLibrary.images);
  const uploading_images_statuses = useSelector(
    state => state.mediaLibrary.uploading_images_statuses
  );

  React.useEffect(() => {
    dispatch(MediaActions.reset());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(MediaActions.getMediaImages(params)).then(res => {
      setTotalPages(res.total_pages);
      setTotalItemCount(res.count);
    });
  }, [dispatch, params]);

  const onDelete = ids => {
    dispatch(MediaActions.deleteMediaImages(ids, params));
  };

  const onImageSelect = (index, file) => {
    dispatch(MediaActions.uploadImage(index, file));
  };

  const empty_state = (
    <Paper className={`${classes.form} mt-4 empty-state`}>
      <div className="text-center">
        <img src={icon} alt="icon" width="70" height="70" />
        <div
          className="my-3"
          style={{ fontWeight: 600, fontSize: 18, color: "#2b2d32" }}
        >
          Upload product images
        </div>
        <div style={{ fontWeight: 300, fontSize: 14, color: "#54575f" }}>
          Store all product images here to use for later
        </div>
        <Button
          className="mt-3"
          disableElevation
          color="primary"
          variant="contained"
          style={{ height: 40 }}
          startIcon={<Add />}
          onClick={() => {
            setAdd(true);
            window.scrollTo(0, 0);
          }}
        >
          Add images
        </Button>
      </div>
    </Paper>
  );

  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <strong style={{ fontSize: 28, color: "#002c42" }}>Media</strong>
          {!subscription.subscription_type ||
          (subscription.subscription_type &&
            !subscription.features.media_library) ? (
            ""
          ) : (
            <Button
              disableElevation
              variant="contained"
              color="primary"
              style={{ height: 40 }}
              startIcon={<Add />}
              onClick={() => {
                setAdd(true);
                window.scrollTo(0, 0);
              }}
              className="mb-3 add-image-button"
            >
              Add images
            </Button>
          )}
        </div>
        {!subscription.subscription_type ||
        (subscription.subscription_type &&
          !subscription.features.media_library) ? (
          <FreeTrial
            header="Store all your images in one place"
            subheader="Upload product photos in bulk and tag them for easy filtering."
          />
        ) : images.length || params.search || add ? (
          <>
            {add ? (
              <>
                <div className={`d-flex mb-1 ${classes.label}`}>
                  <p className="text">Upload images</p>
                  <div
                    className="d-flex align-items-center button"
                    onClick={() => setAdd(false)}
                  >
                    <CloseRoundedIcon />
                    <p className="ml-2 mb-0">Close</p>
                  </div>
                </div>
                <div className="add-image-area-field">
                  <AddImages
                    onImageSelect={onImageSelect}
                    statuses={uploading_images_statuses}
                  />
                </div>
              </>
            ) : (
              ""
            )}
            <List
              images={images}
              params={params}
              setParams={setParams}
              totalPages={totalPages}
              totalItemCount={totalItemCount}
              onDelete={onDelete}
            />
          </>
        ) : (
          empty_state
        )}
      </div>
    </>
  );
}
