import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Utils from "../../../utils";
import { getPrice } from "../PriceDisplay";

export const PriceDisplay = props => {
  const useStyles = makeStyles(theme => ({}));

  const classes = useStyles();

  const has_slashed_price =
    props.item.discounted_price && !props.item.has_variants;

  return props.productSelection ? (
    <div className={`${props.classes.price} mr-2`}>
      {`₱ ${Utils.commafy(props.productSelection.price)}`}
    </div>
  ) : (
    <div className="d-flex align-items-start flex-wrap">
      {has_slashed_price ? (
        <div className={`${props.classes.price} mr-2`}>{`₱ ${getPrice(
          props.item
        )}`}</div>
      ) : (
        ""
      )}
      <div
        className={props.classes.price}
        style={{
          fontSize: props.item && has_slashed_price ? 14 : 18,
          fontWeight: props.item && has_slashed_price ? "normal" : 500,
          textDecoration: props.item
            ? has_slashed_price
              ? "line-through"
              : "auto"
            : ""
        }}
      >{`₱ ${getPrice(props.item, { original_price: true })}`}</div>
    </div>
  );
};
