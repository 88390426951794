import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import Path from '../../../../Paths'
import history from '../../../../store/history'
import India from '../../../../assets/images/proxtera/india.png'
import IndiaFlag from '../../../../assets/images/proxtera/flags/india.png'
import Philippines from '../../../../assets/images/proxtera/philippines.png'
import PhilippinesFlag from '../../../../assets/images/proxtera/flags/philippines.png'
import Singapore from '../../../../assets/images/proxtera/singapore.png'
import SingaporeFlag from '../../../../assets/images/proxtera/flags/singapore.png'
import Africa from '../../../../assets/images/proxtera/africa.png'
import AfricaFlag from '../../../../assets/images/proxtera/flags/africa.png'

const ImageCard = (props) => {
  const classes = makeStyles(theme => ({
    paper: {
      // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
      transition: "all 0.1s linear",
      transform: "translateY(0) scale(1)",
      margin: "0 5px",
      "&:hover": {
        // boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
        transform: "translateY(-1px) scale(1.01)",
      },
      cursor: "pointer"
    },
    banner: {
      width: "100%",
      height: 185
    },
    avatar: {
      height: 35,
      width: 35,
      borderRadius: '50%'
    },
    name: {
      fontSize: 16
    },

  }))()
  return (
    <Paper className={classes.paper} onClick={() => {
      if (props.link) history.push(props.link)
    }}>
      <div className={classes.banner}  style={{
        backgroundImage: `url(${props.banner})`,
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}/>
      <div className="d-flex p-2 align-items-center">
        <p className={`flex-grow-1 mb-0 ${classes.name}`}>{props.name}</p>
        <div className={`${classes.avatar} img-fluid`} style={{
          backgroundImage: `url(${props.avatar})`,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }} alt=""/>
      </div>
    </Paper>
  )
}

const useStyles = theme => ({
  paper: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
  },
  link: {
    color: "#F97663"
  }
});

class CountriesCard extends React.Component {

  render (){
    const { classes, children } = this.props;
    return (
      <Paper className={classes.paper}>
        <div className="p-4">
          <div className="d-flex mb-2">
            <h5 className="flex-grow-1 font-weight-bold">Trade in these Countries</h5>
            <a className={classes.link} href="#" onClick={(e) => {
              e.preventDefault();
              history.push(Path.PROXTERA_PRODUCT_LIST);
            }}>View All Products</a>
          </div>
          <div className="row no-gutters">
            <div className="col-6 col-sm-4 col-md-3">
              <ImageCard name="Africa" 
              link="/proxtera/products?country=africa"
              banner={Africa}
              avatar={AfricaFlag} />
            </div>
            <div className="col-6 col-sm-4 col-md-3">
              <ImageCard name="India" 
              link="/proxtera/products?country=india"
              banner={India} 
              avatar={IndiaFlag} />
            </div>
            <div className="col-6 col-sm-4 col-md-3">
              <ImageCard name="Philippines" 
              link="/proxtera/products?country=philippines"
              banner={Philippines}
              avatar={PhilippinesFlag} />
            </div>
            <div className="col-6 col-sm-4 col-md-3">
              <ImageCard name="Singapore" 
              link="/proxtera/products?country=singapore"
              banner={Singapore}
              avatar={SingaporeFlag} />
            </div>
          </div>
        </div>
      </Paper>
    )
  }
}
export default withStyles(useStyles)(CountriesCard);

