import React from "react";
import {
  FacebookShareButton,
  ViberShareButton,
  WhatsappShareButton,
  TwitterShareButton
} from "react-share";
import { IconButton, Drawer, useTheme, useMediaQuery } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import apiConfig from "../../../../common/apiConfig";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import { showAlert } from "../../../common/commonActions";
import facebookIcon from "../../../../assets/images/facebook.svg";
import viberIcon from "../../../../assets/images/viber.svg";
import whatsappIcon from "../../../../assets/images/whatsapp.svg";
import twitterIcon from "../../../../assets/images/twitter.svg";
import copyIcon from "../../../../assets/images/copyButton.svg";
export default function ShareDrawer(props) {
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const store = useSelector(state => state.store.store);
  return (
    <Drawer anchor={isMobile ? "bottom" : "right"} open={open}>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton style={{ zIndex: 1 }} onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
          <div
            style={{ fontSize: 18, fontWeight: 600 }}
            className="text-center position-absolute w-100"
          >
            Share
          </div>
        </div>
        <div style={{ padding: 20, display: "flex", justifyContent: "center" }}>
          <div>
            <FacebookShareButton
              url={`${apiConfig.localHost}order/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img src={facebookIcon} width="40" height="40" alt="facebook" />
              </IconButton>
            </FacebookShareButton>
          </div>

          <div>
            <WhatsappShareButton
              url={`${apiConfig.localHost}order/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img src={whatsappIcon} width="40" height="40" alt="whatsapp" />
              </IconButton>
            </WhatsappShareButton>
          </div>
          <div>
            <TwitterShareButton
              url={`${apiConfig.localHost}order/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img src={twitterIcon} width="40" height="40" alt="twitter" />
              </IconButton>
            </TwitterShareButton>
          </div>
          <div>
            <ViberShareButton
              url={`${apiConfig.localHost}order/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img src={viberIcon} width="40" height="40" alt="viber" />
              </IconButton>
            </ViberShareButton>
          </div>
          <div>
            <CopyToClipboard
              onCopy={() =>
                dispatch(
                  showAlert({
                    type: "success",
                    message: "Copied to clipboard!"
                  })
                )
              }
              text={`${apiConfig.localHost}order/${store ? store.site : ""}`}
            >
              <IconButton style={{ padding: 8 }}>
                <img
                  src={copyIcon}
                  width="40"
                  height="40"
                  alt="copy-to-clipboard"
                />
              </IconButton>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
