import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Utils from "../../utils";
import defaultSRC from "./defaultResources";
// import { getPrice } from '../../../common/template/components/productDetails/productDetailsTemplate';

export function getPrice(item, param = false) {
  const commafy = price => Utils.commafy(parseFloat(price));
  if (item.has_variants) {
    const selection_prices = item.selections.map(
      selection =>
        parseFloat(selection.discounted_price) || parseFloat(selection.price)
    );
    const min = Math.min(...selection_prices);
    const max = Math.max(...selection_prices);
    return min === max ? min : `${commafy(min)} - ${commafy(max)}`;
  } else {
    return param?.original_price
      ? commafy(item.price)
      : commafy(item.discounted_price ? item.discounted_price : item.price);
  }
}

export const ProductCardPriceDisplay = props => {
  let color = props.storeInfo && props.storeInfo.color;
  function secondaryFont() {
    if (props.storeInfo) {
      if (props.storeInfo.font === null || props.storeInfo.font === undefined) {
        return defaultSRC[props.storeInfo?.template || "Classic"].secondaryFont;
      } else {
        return props.storeInfo.font.body;
      }
    } else {
      return defaultSRC[props.storeInfo?.template || "Classic"].secondaryFont;
    }
  }
  // const getStyles = (template, theme) => {
  //   switch (template) {
  //     case "ClassicV2":
  //       return {
  //       };

  //     case "ContemporaryV2":
  //       return {
  //       };

  //     case "Minimalist":
  //       return {
  //         productPrice: {
  //           fontSize: 18,
  //           fontWeight: 600,
  //           color: "#3e4149",
  //           fontFamily: secondaryFont()
  //         }
  //       };

  //     case "Classic":
  //       return {
  //         productPrice: {
  //           fontSize: 18,
  //           fontWeight: 600,
  //           color: "#3e4149",
  //           fontFamily: secondaryFont()
  //         }
  //       };

  //     case "Contemporary":
  //       return {
  //         productPrice: {
  //           fontSize: 18,
  //           fontWeight: "bold",
  //           color: "#3e4149",
  //           fontFamily: secondaryFont()
  //         }
  //       };

  //     default:
  //       return {};
  //   }
  // };

  // let templateStyles = makeStyles(theme =>
  //   getStyles(props.store?.template ||  "Classic", theme)
  // );

  // const classes = templateStyles();

  const has_slashed_price =
    props.item.discounted_price && !props.item.has_variants;

  return props.productSelection ? (
    <Typography component="p" color="secondary">
      {`₱ ${Utils.commafy(props.productSelection.price)}`}
    </Typography>
  ) : (
    <div className="d-flex align-items-start flex-clumn flex-wrap">
      {has_slashed_price ? (
        <Typography
          className="mr-2"
          variant="body2"
          color={`${
            props.theme
              ? "secondary"
              : props.storeInfo
              ? "textSecondary"
              : "black"
          }`}
          component="p"
          style={{
            fontFamily: secondaryFont(),
            fontSize: 16
          }}
        >
          {`PHP `}
          <strong>{getPrice(props.item)}</strong>
        </Typography>
      ) : (
        ""
      )}
      <Typography
        variant="body2"
        color={`${
          props.theme
            ? "secondary"
            : props.storeInfo && props.storeInfo.template !== "Leftist"
            ? "textSecondary"
            : "black"
        }`}
        component="p"
        style={{
          fontFamily: secondaryFont(),
          textDecoration: props.item
            ? has_slashed_price
              ? "line-through"
              : "auto"
            : "",
          fontSize: props.item ? (has_slashed_price ? 12 : 14) : ""
        }}
      >
        {`PHP `}
        {getPrice(props.item, { original_price: true })}
      </Typography>
    </div>
  );
};
export const ProductDetailedPriceDisplay = props => {
  function secondaryFont() {
    if (props.storeInfo) {
      if (props.storeInfo.font === null || props.storeInfo.font === undefined) {
        return defaultSRC[props.storeInfo?.template || "Classic"].secondaryFont;
      } else {
        return props.storeInfo.font.body;
      }
    } else {
      return defaultSRC[props.storeInfo?.template || "Classic"].secondaryFont;
    }
  }

  const has_slashed_price =
    props.item.discounted_price && !props.item.has_variants;
  const primary_price_color = props.theme?.palette.secondary.main || "#54575F";
  const getStyles = (template, theme) => {
    switch (template) {
      case "ClassicV2":
        return {
          price: {
            fontSize: has_slashed_price ? 28 : 32,
            fontFamily: secondaryFont(),
            [theme.breakpoints.down("xs")]: {
              fontSize: has_slashed_price ? 22 : 24
            },
            textDecoration: has_slashed_price ? "line-through" : "auto",
            color: has_slashed_price ? "rgba(0,0,0,0.5)" : primary_price_color
          },

          discounted_price: {
            fontSize: 32,
            color: primary_price_color,
            fontFamily: secondaryFont(),
            // marginTop: 20,
            [theme.breakpoints.down("xs")]: {
              fontSize: 24
              //   marginTop: 10
            }
          }
        };
      case "ContemporaryV2":
        return {
          price: {
            fontSize: has_slashed_price ? 28 : 32,
            fontFamily: secondaryFont(),
            [theme.breakpoints.down("xs")]: {
              fontSize: has_slashed_price ? 22 : 24
            },
            textDecoration: has_slashed_price ? "line-through" : "auto",
            color: has_slashed_price ? "rgba(0,0,0,0.5)" : primary_price_color
          },

          discounted_price: {
            fontSize: 32,
            color: primary_price_color,
            fontFamily: secondaryFont(),
            // marginTop: 20,
            [theme.breakpoints.down("xs")]: {
              fontSize: 24
              //   marginTop: 10
            }
          }
        };
      case "Minimalist":
        return {
          price: {
            fontSize: has_slashed_price ? 28 : 32,
            color: has_slashed_price ? "rgba(0,0,0,0.5)" : primary_price_color,
            fontFamily: secondaryFont(),
            [theme.breakpoints.down("xs")]: {
              fontSize: has_slashed_price ? 22 : 24,
              marginTop: 10
            },
            textDecoration: has_slashed_price ? "line-through" : "auto"
          },

          discounted_price: {
            fontSize: 32,
            fontFamily: secondaryFont(),
            // marginTop: 20,
            color: primary_price_color,
            [theme.breakpoints.down("xs")]: {
              fontSize: 24
              //   marginTop: 10
            }
          }
        };

      case "Classic":
        return {
          price: {
            fontSize: has_slashed_price ? 28 : 32,
            color: has_slashed_price ? "rgba(0,0,0,0.5)" : primary_price_color,
            fontWeight: 500,
            fontFamily: secondaryFont(),
            [theme.breakpoints.down("sm")]: {
              fontSize: 18,
              marginTop: 0
            },
            textDecoration: has_slashed_price ? "line-through" : "auto"
          },
          discounted_price: {
            fontSize: 32,
            color: primary_price_color,
            fontFamily: secondaryFont(),
            // marginTop: 20,
            [theme.breakpoints.down("xs")]: {
              fontSize: 24
            }
          }
        };

      case "Contemporary":
        return {
          price: {
            fontSize: has_slashed_price ? 16 : 20,
            color: has_slashed_price ? "rgba(0,0,0,0.5)" : primary_price_color,
            fontWeight: 500,
            fontFamily: secondaryFont(),
            textDecoration: has_slashed_price ? "line-through" : "auto"
          },
          discounted_price: {
            fontSize: 32,
            fontFamily: secondaryFont(),
            color: primary_price_color,
            [theme.breakpoints.down("xs")]: {
              fontSize: 24
            }
          }
        };

      default:
        return {};
    }
  };

  let templateStyles = makeStyles(theme =>
    getStyles(props.store?.template || "Classic", theme)
  );

  const classes = templateStyles();
  console.log(props.productSelection);
  return (
    <div>
      {props.productSelection ? (
        <div className={classes.price}>
          {`PHP `}
          {Utils.commafy(
            props.productSelection.discounted_price ||
              props.productSelection.price
          )}
        </div>
      ) : (
        <div className="d-flex align-items-center">
          {has_slashed_price ? (
            <Typography
              variant="h6"
              className={`${classes.discounted_price} mr-2`}
            >
              <strong>
                {`PHP `}
                {Utils.commafy(getPrice(props.item))}
              </strong>
            </Typography>
          ) : (
            ""
          )}
          <div className={classes.price}>
            {`PHP `}
            {Utils.commafy(getPrice(props.item, { original_price: true }))}
          </div>
        </div>
      )}
    </div>
  );
};

export const WishlistItemPriceDisplay = props => {
  const has_slashed_price =
    props.item.discounted_price && !props.item.has_variants;

  let styles = makeStyles(theme => ({
    discounted_price: {
      fontWeight: 500,
      fontSize: 14
    },
    price: {
      fontSize: has_slashed_price ? 12 : 14,
      [theme.breakpoints.down("xs")]: {
        fontSize: has_slashed_price ? 12 : 14
      },
      textDecoration: has_slashed_price ? "line-through" : "auto",
      color: has_slashed_price ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.8)"
    }
  }));

  const classes = styles();
  console.log(props.productSelection);
  return (
    <div className={classes.root}>
      {props.productSelection ? (
        <div className={classes.price}>
          {`${props.symbol} ` || `PHP `}
          {Utils.commafy(
            props.productSelection.discounted_price ||
              props.productSelection.price
          )}
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-end flex-wrap">
          {has_slashed_price ? (
            <Typography
              variant="h6"
              className={`${classes.discounted_price} mr-2`}
            >
              <strong>
                {`${props.symbol} ` || `PHP `}
                {getPrice(props.item)}
              </strong>
            </Typography>
          ) : (
            ""
          )}
          <div className={classes.price}>
            {`${props.symbol} ` || `PHP `}
            {getPrice(props.item, { original_price: true })}
          </div>
        </div>
      )}
    </div>
  );
};

const PriceDisplay = props => {
  return (
    <div
      className={`${
        !props.newLine
          ? props.newlineOnMobile
            ? "d-block d-md-flex"
            : "d-flex"
          : "d-block"
      } align-items-end`}
    >
      {props.discounted_price ? (
        <div
          className={props.discounted_price_class}
          style={{
            marginRight: "1em"
          }}
        >
          ₱ {Utils.commafy(parseFloat(props.discounted_price))}
        </div>
      ) : (
        ""
      )}
      <div
        className={props.price_class}
        style={{
          [props.discounted_price ? "color" : ""]: props.discounted_price
            ? "rgba(43, 45, 50, 0.64)"
            : "inherit",
          textDecoration: props.discounted_price ? "line-through" : "none",
          fontSize: props.discounted_price ? 12 : 16
        }}
      >
        ₱ {Utils.commafy(parseFloat(props.price))}
      </div>
    </div>
  );
};

export default PriceDisplay;
