import React, { useState } from "react";
import {
  makeStyles,
  Button,
  TextField,
  FormControlLabel,
  InputAdornment,
  FormGroup,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Radio } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
  TimePicker,
  DatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import ProductModalPicker from "./components/productModalPicker";

import { useSelector, useDispatch } from "react-redux";
import { editVoucher, getProductCategories } from "./actions";
import DeactivateVoucherModal from "./deactivateModal";
import { CalendarToday, AccessTime } from "@material-ui/icons";
import history from "../../store/history";
import Path from "../../Paths";
import { showAlert } from "../common/commonActions";
import { ReactComponent as SelectProductIcon } from "../../assets/images/selectProduct.svg";

const EditVoucher = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { open, setOpen, values, setValues, state, setState } = props;
  const useStyles = makeStyles((theme) => ({
    content: {
      width: "100%",
      paddingBottom: "10vh",
      paddingLeft: "10vw",
      paddingRight: "10vw",
    },
    title: {
      fontSize: 28,
      color: "#2b2d33",
      fontWeight: 600,
    },
    form: {
      border: "1px solid #d1d5dd",
      borderRadius: 8,
      backgroundColor: "#fff",
      marginTop: "5vh",
    },
    label1: {
      fontSize: 14,
      color: "#54575f",
      fontWeight: 500,
    },
    input: {
      backgroundColor: "#fff",
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const stores = useSelector((state) => state.vouchers.stores);
  const filters = useSelector((state) => state.vouchers.voucherFilters);
  const vouchers = useSelector(
    (state) => state.vouchers.voucherListFilter?.list
  );
  const selectedVoucher = useSelector(
    (state) => state.vouchers.selectedVoucher
  );
  const productCategories = useSelector(
    (state) => state.vouchers.product_categories
  );
  let selectedVoucherCode = selectedVoucher ? selectedVoucher.voucher_code : "";
  const voucherFilters = useSelector((state) => state.vouchers.voucherFilters);
  const [openDeactivateModal, setOpenDeactivateModal] = React.useState(false);
  const [openUnsaveChangesModal, setOpenUnsaveChangesModal] =
    React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [error, setError] = React.useState({
    voucher_code_error: "",
    typeError: "",
    discount_value_error: "",
    applies_to_error: "",
    category_error: "",
    minimum_requirements_error: "",
    minimum_purchase_error: "",
    usage_limit_error: "",
    usage_limit_value_error: "",
  });

  const initialValues = {
    voucher_code: "",
    voucher_type: "",
    discount_value: "",
    applies_to: "",
    category: "",
    minimum_requirements: "",
    minimum_purchase_amount: 0,
    minimum_x_product: "",
    free_product: "",
    free_quantity: 0,
    usage_limit_to_one: false,
    usage_limit_no_times: false,
    usage_limit_value: 0,
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
  };

  function setValueChanges(value, name) {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleUsageLimit(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    });
  }

  function handleCancel() {
    if (state.alertType === "error") {
    }
    setOpen(false);
    setValues(initialValues);
    window.scrollTo(0, 0);
  }

  async function handleDeactivate(value) {
    setOpenDeactivateModal(false);
    let params = {
      store: stores ? stores[0].uuid : "",
      voucher_code: values.voucher_code,
      voucher_type: values.voucher_type,
      discount_value: values.discount_value,
      applies_to: values.applies_to,
      category: values.category,
      minimum_requirements: values.minimum_requirements,
      minimum_purchase_amount: values.minimum_purchase_amount,
      usage_limit_to_one: values.usage_limit_to_one,
      usage_limit_no_times: values.usage_limit_no_times,
      usage_limit_value:
        values.usage_limit_value === "" ? 0 : values.usage_limit_value,
      start_datetime: moment({
        year: values.start_date.year(),
        month: values.start_date.month(),
        day: values.start_date.date(),
        hours: values.start_time.hours(),
        minute: values.start_time.minutes(),
      }).format(),
      end_datetime: moment({
        year: values.end_date.year(),
        month: values.end_date.month(),
        day: values.end_date.date(),
        hours: values.end_time.hours(),
        minute: values.end_time.minutes(),
      }).format(),
      is_active: value,
    };

    const res = await dispatch(
      editVoucher(params, filters, stores, selectedVoucherCode)
    );

    try {
      if (res.status === 200) {
        window.scrollTo(0, 0);
        setOpen(false);
        setState({
          ...state,
          openAlert: true,
          alertType: "success",
          alertMessage: "Voucher successfully updated!",
        });
        setValues(initialValues);
      }
    } catch {
      window.scrollTo(0, 0);
      setState({
        ...state,
        openAlert: true,
        alertType: "error",
        alertMessage: "Oops, something went wrong. Please try again.",
      });
    }
  }

  async function handleSave() {
    let params = {
      store: stores ? stores[0].uuid : "",
      voucher_code: values.voucher_code,
      voucher_type: values.voucher_type,
      discount_value: values.discount_value
        ? parseFloat(values.discount_value).toFixed(2)
        : 0,
      applies_to: values.applies_to,
      category: values.category,
      minimum_x_product: values.minimum_x_product,
      free_product: values.free_product === "" ? 0 : values.free_product,
      free_quantity: values.free_quantity,
      minimum_requirements: values.minimum_requirements,
      minimum_purchase_amount: values.minimum_purchase_amount,
      usage_limit_to_one: values.usage_limit_to_one,
      usage_limit_no_times: values.usage_limit_no_times,
      usage_limit_value:
        values.usage_limit_value === "" ? 0 : values.usage_limit_value,
      start_datetime: moment({
        year: values.start_date.year(),
        month: values.start_date.month(),
        day: values.start_date.date(),
        hours: values.start_time.hours(),
        minute: values.start_time.minutes(),
      }).format(),
      end_datetime: moment({
        year: values.end_date.year(),
        month: values.end_date.month(),
        day: values.end_date.date(),
        hours: values.end_time.hours(),
        minute: values.end_time.minutes(),
      }).format(),
      is_active: values.is_active,
    };
    const res = await dispatch(
      editVoucher(params, filters, stores, selectedVoucherCode)
    );

    try {
      if (res.status === 200) {
        window.scrollTo(0, 0);
        setValues(initialValues);
        setOpen(false);
        dispatch(
          showAlert({
            type: "success",
            message: "Voucher successfully updated!",
          })
        );
      }
    } catch {
      window.scrollTo(0, 0);
    }
  }

  React.useEffect(() => {
    for (let e in error) {
      if (error[e].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [error]);

  React.useEffect(() => {
    setIsFormEnough(
      values.voucher_code &&
        values.voucher_type &&
        (values.discount_value || values.free_product) &&
        values.applies_to &&
        values.minimum_requirements
    );
  }, [values]);

  React.useEffect(() => {
    if (values.minimum_requirements === "None") {
      setError({
        ...error,
        minimum_purchase_error: "",
      });
    }
  }, [values.minimum_requirements]);

  React.useEffect(() => {
    if (values.applies_to === "All Products") {
      setValues({ ...values, category: "" });
    } else if (values.applies_to === "Specific Category") {
      dispatch(getProductCategories(stores[0].uuid));
    }
  }, [values.applies_to]);

  React.useEffect(() => {
    if (!values.usage_limit_no_times) {
      setError({
        ...error,
        usage_limit_value_error: "",
      });
    }
  }, [values.usage_limit_no_times]);

  React.useEffect(() => {
    if (
      values.voucher_type === "percentage" &&
      (values.discount_value > 100 || values.discount_value <= 0)
    ) {
      setError({
        ...error,
        discount_value_error: "Please enter a valid percentage value",
      });
    } else {
      setError({
        ...error,
        discount_value_error: "",
      });
    }
  }, [values.voucher_type, values.discount_value]);

  React.useEffect(() => {
    if (values.usage_limit_value === "") {
      setValueChanges(0, "usage_limit_value");
    }
  }, [open]);

  const types = [
    { label: "Discount (Percentage %)", value: "percentage" },
    { label: "Discount (Fixed Amount)", value: "fixed_amount" },
    { label: "Buy X, Get Y", value: "buy_x_get_y" },
    // { label: "Discount (Free shipping)", value: "free_shipping" }
  ];

  const minimumRequirements = [
    { value: "None" },
    { value: "Minimum Purchase Amount (PHP)" },
    { value: "Minimum Quantity of items" },
  ];

  const usageLimit = [
    {
      label: "Limit to 1 customer per voucher",
      value: values.usage_limit_to_one,
      name: "usage_limit_to_one",
    },
    {
      label: "Limit to number of times that it can be used in total",
      value: values.usage_limit_no_times,
      name: "usage_limit_no_times",
    },
  ];

  const [productPickerOpen, setProductPickerOpen] = useState(false);
  const [freebiePickerOpen, setFreebiePickerOpen] = useState(false);
  const onProductPickerSubmit = (val) => {
    setValueChanges(val, "minimum_x_product");
  };
  const onFreebiePickerSubmit = (val) => {
    setValueChanges(val, "free_product");
  };

  return (
    <div style={{ display: open ? "" : "none" }}>
      <div className={classes.content}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            variant="text"
            color="primary"
            onClick={() => handleCancel()}
          >
            Back
          </Button>
          <div className={classes.title}>Edit Voucher</div>
          <Button
            variant="outlined"
            color="primary"
            style={{ height: 40, fontSize: 12 }}
            onClick={() =>
              values.is_active
                ? setOpenDeactivateModal(true)
                : handleDeactivate(true)
            }
          >
            {values.is_active ? "Deactivate" : "Activate"}
          </Button>
        </div>
        <div className={classes.form}>
          <div
            className="d-flex col-12 p-4"
            style={{ borderBottom: "1px solid #d1d5dd" }}
          >
            <div className="col-3 p-0">
              <div style={{ fontSize: 18, fontWeight: 600, color: "#f97663" }}>
                Details
              </div>
            </div>
            <div className="col-9">
              <div>
                <div className={classes.label1}>Voucher Code</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    placeholder="E.g. COOKIEVERSE"
                    color="secondary"
                    style={{ marginTop: 5 }}
                    inputProps={{ maxLength: 64 }}
                    value={values.voucher_code}
                    error={error.voucher_code_error}
                    helperText={error.voucher_code_error}
                    onChange={(e) => {
                      const input = e.target.value;

                      const list =
                        vouchers.length &&
                        vouchers
                          .filter(
                            (i) =>
                              i.voucher_code.toLowerCase() !==
                              selectedVoucher.voucher_code.toLowerCase()
                          )
                          .map((i) => i.voucher_code.toLowerCase());
                      setValueChanges(input, "voucher_code");
                      if (input === "") {
                        setError({
                          ...error,
                          voucher_code_error: "This field is required.",
                        });
                      } else if (list.includes(input.toLowerCase())) {
                        setError({
                          ...error,
                          voucher_code_error:
                            "Voucher code is already taken. Please try again.",
                        });
                      } else {
                        setError({ ...error, voucher_code_error: "" });
                      }
                    }}
                    onBlur={(e) => {
                      const list =
                        vouchers.length &&
                        vouchers
                          .filter(
                            (i) =>
                              i.voucher_code.toLowerCase() !==
                              selectedVoucher.voucher_code.toLowerCase()
                          )
                          .map((i) => i.voucher_code.toLowerCase());
                      if (e.target.value === "") {
                        setError({
                          ...error,
                          voucher_code_error: "This field is required.",
                        });
                      } else if (list.includes(e.target.value.toLowerCase())) {
                        setError({
                          ...error,
                          voucher_code_error:
                            "Voucher code is already taken. Please try again.",
                        });
                      } else {
                        setError({ ...error, voucher_code_error: "" });
                      }
                    }}
                  />
                  <Chip
                    size="medium"
                    label={values.is_active ? "Active" : "Inactive"}
                    style={{
                      backgroundColor: values.is_active ? "#18b4a1" : "gray",
                      color: "#fff",
                      marginLeft: 60,
                      padding: "0 8px",
                    }}
                  />
                </div>
              </div>

              <div style={{ padding: "25px 0" }}>
                <div className={classes.label1}>Type</div>
                <Radio.Group
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  value={values.voucher_type}
                  onChange={(e) => {
                    setValueChanges(e.target.value, "voucher_type");
                    if (e.target.value === "buy_x_get_y") {
                      setValueChanges("Specific Product", "applies_to");
                    }
                  }}
                >
                  {types.map((item) => {
                    return (
                      <Radio value={item.value} style={{ marginBottom: 15 }}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
                {["percentage", "fixed_amount"].includes(
                  values.voucher_type
                ) ? (
                  <div
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <div className={classes.label1}>Discount Value</div>
                    <TextField
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      inputProps={{ min: 0 }}
                      InputProps={
                        values.voucher_type === "percentage"
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }
                          : ""
                      }
                      value={values.discount_value}
                      error={error.discount_value_error}
                      helperText={error.discount_value_error}
                      onChange={(e) => {
                        let input = e.target.value;
                        setValueChanges(e.target.value, "discount_value");
                        if (input === "") {
                          setError({
                            ...error,
                            discount_value_error: "This field is required.",
                          });
                        } else {
                          setError({ ...error, discount_value_error: "" });
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setError({
                            ...error,
                            discount_value_error: "This field is required.",
                          });
                        } else if (
                          values.voucher_type === "percentage" &&
                          (e.target.value > 100 || e.target.value <= 0)
                        ) {
                          setError({
                            ...error,
                            discount_value_error:
                              "Please enter a valid percentage value",
                          });
                        } else {
                          setError({ ...error, discount_value_error: "" });
                        }
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div style={{ marginTop: 15 }}>
                  <div className={classes.label1}>Applies To</div>
                  <Radio.Group
                    style={{
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    value={values.applies_to}
                    onChange={(e) =>
                      setValueChanges(e.target.value, "applies_to")
                    }
                  >
                    {[
                      "All Products",
                      "Specific Category",
                      "Specific Product",
                    ].map((item) => {
                      return (
                        <Radio
                          value={item}
                          disabled={
                            ["buy_x_get_y"].includes(values.voucher_type)
                              ? item === "Specific Product"
                                ? false
                                : true
                              : false
                          }
                          style={{ marginBottom: 15 }}
                        >
                          {item}
                        </Radio>
                      );
                    })}
                  </Radio.Group>

                  <div
                    style={{
                      display:
                        values.applies_to === "Specific Category" ? "" : "none",
                    }}
                  >
                    <div className={classes.label1}>Choose category</div>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: "50%" }}
                    >
                      <Select
                        id="category"
                        color="secondary"
                        value={values.category}
                        onChange={(e) =>
                          setValueChanges(e.target.value, "category")
                        }
                      >
                        {productCategories.categories
                          .filter((item) => item != "")
                          .map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                    <div style={{ color: "red", fontSize: 14 }}>
                      {error.category_error}
                    </div>
                  </div>
                  {["buy_x_get_y"].includes(values.voucher_type) ? (
                    <div>
                      <div>
                        <FormGroup
                          margin="dense"
                          style={{
                            width: "100%",
                            height: 176,
                            backgroundColor: "#F5F5F5",
                            borderRadius: 4,
                            display: "block",
                            padding: 16,
                          }}
                        >
                          <div className={classes.label1}>Customer buys</div>
                          <Radio
                            value="Minimum Quantity of items"
                            size="small"
                            checked={
                              values.minimum_requirements ===
                              "Minimum Quantity of items"
                                ? true
                                : false
                            }
                            style={{
                              marginBottom: 15,
                              width: "100%",
                              marginTop: 15,
                            }}
                            onChange={(e) => {
                              setValueChanges(
                                e.target.value,
                                "minimum_requirements"
                              );
                              setValueChanges(0, "minimum_purchase_amount");
                            }}
                          >
                            Minimum quantity of items
                          </Radio>
                          <div style={{ display: "flex" }}>
                            <div style={{ width: "40%" }}>
                              <div className={classes.label1}>Quantity</div>
                              <TextField
                                id="usage_limit_value"
                                style={{ width: "90%" }}
                                variant="outlined"
                                margin="dense"
                                color="secondary"
                                type="number"
                                value={values.minimum_purchase_amount}
                                error={error.minimum_purchase_error}
                                helperText={error.minimum_purchase_error}
                                InputProps={{ className: classes.input }}
                                onChange={(e) => {
                                  let input = e.target.value;
                                  setValueChanges(
                                    e.target.value,
                                    "minimum_purchase_amount"
                                  );
                                  if (input === "") {
                                    setError({
                                      ...error,
                                      minimum_purchase_error:
                                        "This field is required.",
                                    });
                                  } else {
                                    setError({
                                      ...error,
                                      minimum_purchase_error: "",
                                    });
                                  }
                                }}
                                onBlur={(e) => {
                                  let input = e.target.value;
                                  if (input === "") {
                                    setError({
                                      ...error,
                                      minimum_purchase_error:
                                        "This field is required.",
                                    });
                                  } else {
                                    setError({
                                      ...error,
                                      minimum_purchase_error: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div>
                              <div className={classes.label1}>
                                Select Product
                              </div>
                              <TextField
                                id="free_product"
                                variant="outlined"
                                color="secondary"
                                margin="dense"
                                placeholder="Click to select product"
                                value={values.minimum_x_product}
                                InputProps={{
                                  className: classes.input,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <SelectProductIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onClick={() => {
                                  setProductPickerOpen(true);
                                }}
                              />
                              <ProductModalPicker
                                open={productPickerOpen}
                                setOpen={setProductPickerOpen}
                                onSubmit={onProductPickerSubmit}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                      <div>
                        <div>
                          <FormGroup
                            margin="dense"
                            style={{
                              width: "100%",
                              height: 144,
                              backgroundColor: "#F5F5F5",
                              borderRadius: 4,
                              marginTop: 8,
                              padding: 16,
                              display: "block",
                            }}
                          >
                            <div className={classes.label1}>Customer gets</div>
                            <div style={{ display: "flex", marginTop: "15px" }}>
                              <div style={{ width: "40%" }}>
                                <div className={classes.label1}>Quantity</div>
                                <TextField
                                  id="free_quantity"
                                  style={{ width: "90%" }}
                                  variant="outlined"
                                  margin="dense"
                                  color="secondary"
                                  type="number"
                                  value={values.free_quantity}
                                  error={error.free_quantity_error}
                                  helperText={error.free_quantity_error}
                                  InputProps={{ className: classes.input }}
                                  onChange={(e) => {
                                    let input = e.target.value;
                                    setValueChanges(
                                      e.target.value,
                                      "free_quantity"
                                    );
                                    if (input === "") {
                                      setError({
                                        ...error,
                                        usage_limit_value_error:
                                          "This field is required.",
                                      });
                                    } else if (input < 0) {
                                      setValueChanges(0, "free_quantity");
                                    } else {
                                      setError({
                                        ...error,
                                        usage_limit_value_error: "",
                                      });
                                    }
                                  }}
                                  onBlur={(e) => {
                                    let input = e.target.value;
                                    if (input === "") {
                                      setError({
                                        ...error,
                                        usage_limit_value_error:
                                          "This field is required.",
                                      });
                                    } else if (input < 0) {
                                      setValueChanges(0, "free_quantity");
                                    } else {
                                      setError({
                                        ...error,
                                        usage_limit_value_error: "",
                                      });
                                    }
                                  }}
                                />
                              </div>

                              <div>
                                <div className={classes.label1}>
                                  Select product
                                </div>
                                <TextField
                                  id="free_product"
                                  variant="outlined"
                                  color="secondary"
                                  margin="dense"
                                  placeholder="Click to select product"
                                  value={values.free_product}
                                  style={{ width: "100%" }}
                                  InputProps={{
                                    className: classes.input,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <SelectProductIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  onClick={() => {
                                    setFreebiePickerOpen(true);
                                  }}
                                />
                                <ProductModalPicker
                                  open={freebiePickerOpen}
                                  setOpen={setFreebiePickerOpen}
                                  onSubmit={onFreebiePickerSubmit}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex col-12 p-4"
            style={{ borderBottom: "1px solid #d1d5dd" }}
          >
            <div className="col-3 p-0">
              <div style={{ fontSize: 18, fontWeight: 600, color: "#f97663" }}>
                Limits
              </div>
            </div>
            <div className="col-9">
              <div>
                <div className={classes.label1}>
                  Add a maximum redeemable limit to your vouchers. Leave max
                  redemption to 0 if there is no limit.
                </div>
                <div style={{ padding: "25px 0" }}>
                  <div className={classes.label1}>Usage Limit</div>
                  <FormGroup id="usage_limit">
                    {usageLimit.map((item) => {
                      return (
                        <FormControlLabel
                          style={{
                            marginRight: 0,
                            display: "flex",
                          }}
                          control={
                            <Checkbox
                              checked={item.value}
                              onChange={handleUsageLimit}
                              name={item.name}
                              color="primary"
                            />
                          }
                          label={
                            <div style={{ fontSize: 14, color: "#54575f" }}>
                              {item.label}
                            </div>
                          }
                        />
                      );
                    })}
                  </FormGroup>

                  <div
                    style={{
                      display: values.usage_limit_no_times ? "" : "none",
                    }}
                  >
                    <TextField
                      id="usage_limit_value"
                      style={{ width: "50%" }}
                      variant="outlined"
                      margin="dense"
                      color="secondary"
                      type="number"
                      value={values.usage_limit_value}
                      error={error.usage_limit_value_error}
                      helperText={error.usage_limit_value_error}
                      onChange={(e) => {
                        let input = e.target.value;
                        setValueChanges(e.target.value, "usage_limit_value");
                        if (input === "") {
                          setError({
                            ...error,
                            usage_limit_value_error: "This field is required.",
                          });
                        } else {
                          setError({ ...error, usage_limit_value_error: "" });
                        }
                      }}
                      onBlur={(e) => {
                        let input = e.target.value;
                        if (input === "") {
                          setError({
                            ...error,
                            usage_limit_value_error: "This field is required.",
                          });
                        } else {
                          setError({ ...error, usage_limit_value_error: "" });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex col-12 p-4"
            style={{
              borderBottom: "1px solid #d1d5dd",
              opacity: values?.voucher_type === "buy_x_get_y" ? 0.5 : 1,
            }}
          >
            <div className="col-3 p-0">
              <div style={{ fontSize: 18, fontWeight: 600, color: "#f97663" }}>
                Requirements
              </div>
            </div>
            <div className="col-9">
              <div className={classes.label1}>Minimum Requirements</div>
              <Radio.Group
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
                value={values.minimum_requirements}
                disabled={values?.voucher_type === "buy_x_get_y"}
                onChange={(e) => {
                  setValueChanges(e.target.value, "minimum_requirements");
                  setValueChanges(0, "minimum_purchase_amount");
                }}
              >
                {minimumRequirements.map((item) => {
                  return (
                    <Radio value={item.value} style={{ marginBottom: 15 }}>
                      {item.value}
                    </Radio>
                  );
                })}
              </Radio.Group>

              <div
                style={{
                  display:
                    values.minimum_requirements === "None" ||
                    values.minimum_requirements === "" ||
                    values.voucher_type === "buy_x_get_y"
                      ? "none"
                      : "",
                }}
              >
                <div className={classes.label1}>
                  {values.minimum_requirements === "Minimum Quantity of items"
                    ? "Minimum Quantity of items"
                    : "Minimum Purchase Amount (PHP)"}
                </div>
                <TextField
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  type="number"
                  inputProps={{ min: 0 }}
                  InputProps={
                    values.minimum_requirements === "Minimum Quantity of items"
                      ? ""
                      : {
                          startAdornment: (
                            <InputAdornment position="start">
                              PHP
                            </InputAdornment>
                          ),
                        }
                  }
                  error={error.minimum_purchase_error}
                  helperText={error.minimum_purchase_error}
                  value={values.minimum_purchase_amount}
                  onChange={(e) => {
                    let input = e.target.value;
                    setValueChanges(e.target.value, "minimum_purchase_amount");
                    if (input === "") {
                      setError({
                        ...error,
                        minimum_purchase_error: "This field is required.",
                      });
                    } else {
                      setError({ ...error, minimum_purchase_error: "" });
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setError({
                        ...error,
                        minimum_purchase_error: "This field is required.",
                      });
                    } else {
                      setError({ ...error, minimum_purchase_error: "" });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex col-12 p-4">
            <div className="col-3 p-0">
              <div style={{ fontSize: 18, fontWeight: 600, color: "#f97663" }}>
                Duration
              </div>
            </div>
            <div className="col-9">
              <div className={`mb-3 ${classes.label1}`}>
                Set the date and time of your voucher’s duration
              </div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                  <div className={classes.label1}>Starts on</div>
                  <div>
                    <div className="d-flex">
                      <DatePicker
                        autoOk
                        label="Date"
                        margin="dense"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/YYYY"
                        orientation="portrait"
                        color="secondary"
                        className="mr-2"
                        disablePast
                        value={values.start_date}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(e) => setValueChanges(e, "start_date")}
                        renderInput={(params) => <TextField {...params} />}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarToday
                                style={{ color: "#909196", fontSize: 18 }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TimePicker
                        autoOk
                        label="Time"
                        margin="dense"
                        variant="inline"
                        inputVariant="outlined"
                        format="hh:mm A"
                        orientation="portrait"
                        color="secondary"
                        disablePast
                        value={values.start_time}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(e) => setValueChanges(e, "start_time")}
                        renderInput={(params) => <TextField {...params} />}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTime
                                style={{ color: "#909196", fontSize: 18 }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <div className={classes.label1}>Ends on</div>
                  <div>
                    <div className="d-flex">
                      <DatePicker
                        autoOk
                        label="Date"
                        margin="dense"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/YYYY"
                        orientation="portrait"
                        color="secondary"
                        className="mr-2"
                        disablePast
                        value={values.end_date}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(e) => setValueChanges(e, "end_date")}
                        renderInput={(params) => <TextField {...params} />}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarToday
                                style={{ color: "#909196", fontSize: 18 }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TimePicker
                        autoOk
                        label="Time"
                        margin="dense"
                        variant="inline"
                        inputVariant="outlined"
                        format="hh:mm A"
                        orientation="portrait"
                        color="secondary"
                        disablePast
                        value={values.end_time}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(e) => setValueChanges(e, "end_time")}
                        renderInput={(params) => <TextField {...params} />}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTime
                                style={{ color: "#909196", fontSize: 18 }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div style={{ marginTop: 30 }} className="p-4">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              disabled={
                !isFormEnough ||
                formHasErrors ||
                (values.minimum_requirements !== "None" &&
                  !values.minimum_purchase_amount)
                  ? true
                  : false ||
                    (!values.usage_limit_no_times && !values.usage_limit_to_one)
                  ? true
                  : false ||
                    (values.applies_to === "Specific Category" &&
                      values.category === "")
                  ? true
                  : false
              }
              onClick={handleSave}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              color="primary"
              disableElevation
              fullWidth
              style={{ marginTop: 15 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <DeactivateVoucherModal
        open={openDeactivateModal}
        setOpen={setOpenDeactivateModal}
        handleDeactivate={handleDeactivate}
      />
    </div>
  );
};

export default EditVoucher;
