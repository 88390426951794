import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import { useDispatch, useSelector } from "react-redux";
import { addVariantGroupAction } from "../../websiteBuilderActions";
import FreeTrialModal from "../../../subscription/components/products/freeTrialModal";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 16,
    fontWeight: 600,
    color: "#2b2d32"
  },
  layout: {
    display: "flex",
    alignItems: "center"
  },
  header2: {
    marginTop: "2vh",
    fontSize: 16,
    fontWeight: 600,
    color: "#002c42"
  },
  label: {
    fontSize: 12,
    color: "#54575f"
  },
  btn: {
    fontSize: 12,
    padding: "5px 10px",
    width: "fit-content",
    height: 35,
    marginBottom: 5,
    backgroundColor: "#eee",
    textTransform: "capitalize"
  },
  variantList: {
    maxHeight: 140,
    height: "auto",
    overflowY: "auto",
    marginTop: "2vh",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      width: 5
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#f97663"
    }
  }
}));
const DeleteVariantModal = props => {
  const { open, setOpen, handleDeleteVariant } = props;
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogContent style={{ padding: "50px 30px" }}>
        <div style={{ textAlign: "center" }}>
          <ErrorIcon
            style={{
              width: "100%",
              textAlign: "center",
              color: "#ee2545",
              fontSize: 100
            }}
          />
          <div style={{ padding: "0 45px" }}>
            <div className={classes.header2}>
              Are you sure you want to delete these variants?
            </div>
            <div className={classes.label} style={{ marginTop: "2vh" }}>
              Once confirmed, these variants will be permanently deleted.
            </div>
          </div>
          <div className={classes.layout} style={{ marginTop: "5vh" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ height: 40, width: "50%" }}
              onClick={() => setOpen(false)}
            >
              No, take me back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              style={{ height: 40, width: "50%", marginLeft: 10 }}
              onClick={handleDeleteVariant}
            >
              Yes, delete
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const EditVariantGroup = props => {
  const {
    open,
    setOpen,
    isMobile,
    variant,
    setVariant,
    productVariants,
    setProductVariants
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const subscription = useSelector(state => state.subscription.subscription);
  const [selectedVariant, setSelectedVariant] = React.useState({});
  const [openDelete, setOpenDelete] = React.useState(false);
  const [error, setError] = React.useState({
    group_name_error: "",
    variant_error: ""
  });
  const [addVariant, setAddVariant] = React.useState("");
  const [openFreeTrial, setOpenFreeTrial] = React.useState(false);
  const group_names =
    productVariants.length &&
    productVariants
      .filter(item => item.name !== variant.name)
      .map(item => item.name.toLowerCase());
  const variants = selectedVariant ? selectedVariant.variants : "";
  function handleAddVariant() {
    if (variants.length > 2 && !subscription.subscription_type) {
      setOpenFreeTrial(true);
    } else {
      let list = variants.map(e => e.name.toLowerCase());
      if (addVariant === "") {
        setError({
          ...error,
          variant_error: "This field is required."
        });
      } else if (list.indexOf(addVariant.toLowerCase()) !== -1) {
        setError({
          ...error,
          variant_error: "Variant already exist"
        });
      } else {
        setError({ ...error, variant_error: "" });
        setSelectedVariant({
          ...selectedVariant,
          variants: [
            ...selectedVariant.variants,
            { name: addVariant, is_enabled: false }
          ]
        });
        setAddVariant("");
      }
    }
  }

  function handleRemoveVariant(index) {
    variants.splice(index, 1);
    setSelectedVariant({ ...selectedVariant, variants: variants });
  }

  function handleDeleteVariant() {
    setOpenDelete(false);
    setProductVariants(variantGroups =>
      variantGroups.filter((variantGroup, idx) => idx !== open.index)
    );
    setOpen({ ...open, editVariantModal: false, key: "" });
  }

  async function handleSaveVariant() {
    let newProductVariants = productVariants.filter(
      e => e.name !== variant.name
    );
    setProductVariants([...newProductVariants, selectedVariant]);
    setOpen({ ...open, editVariantModal: false });
    setAddVariant("");
  }

  React.useEffect(() => {
    if (variant) {
      setSelectedVariant(variant);
    }
  }, [variant]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open.editVariantModal}>
      <DialogContent style={{ padding: 0, position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => {
              setOpen({ ...open, editVariantModal: false });
              setAddVariant("");
            }}
          >
            <CloseIcon color="secondary" />
          </IconButton>
        </div>
        <div style={{ padding: isMobile ? "0 25px 40px" : "0 40px 40px" }}>
          <div className={classes.header}>Variants</div>
          <div style={{ marginTop: "2vh" }}>
            <div style={{ fontSize: 12, color: "#54575f" }}>Group Name</div>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              color="secondary"
              style={{ marginTop: 0 }}
              error={error.group_name_error}
              helperText={error.group_name_error}
              value={selectedVariant.name}
              onChange={e => {
                setSelectedVariant({
                  ...selectedVariant,
                  name: e.target.value
                });
                if (e.target.value === "") {
                  setError({
                    ...error,
                    group_name_error: "This field is required."
                  });
                } else if (group_names.includes(e.target.value.toLowerCase())) {
                  setError({
                    ...error,
                    group_name_error: "Group name already exist."
                  });
                } else {
                  setError({ ...error, group_name_error: "" });
                }
              }}
            />
          </div>

          <div style={{ marginTop: "2vh" }}>
            <div style={{ fontSize: 12, color: "#54575f" }}>Variant Name</div>
            <div className={classes.layout}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                color="secondary"
                style={{ marginTop: 0, marginBottom: 0 }}
                value={addVariant}
                onChange={e => setAddVariant(e.target.value)}
                error={error.variant_error}
                helperText={error.variant_error}
              />
              <Button
                style={{ marginLeft: 5, height: 40 }}
                variant="outlined"
                color="primary"
                onClick={handleAddVariant}
              >
                Add
              </Button>
              <FreeTrialModal open={openFreeTrial} setOpen={setOpenFreeTrial} />
            </div>
            <div className={classes.variantList}>
              {variants && variants.length > 0
                ? variants.map((item, index) => {
                    return (
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.btn}
                        endIcon={<CloseIcon />}
                        onClick={() => handleRemoveVariant(index)}
                      >
                        {item.name}
                      </Button>
                    );
                  })
                : ""}
            </div>
          </div>
          <div style={{ marginTop: "2vh" }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              style={{ height: 40 }}
              onClick={handleSaveVariant}
              disabled={error.group_name_error || error.variant_error}
            >
              Save Variants
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              style={{ height: 40, marginTop: 8 }}
              onClick={() => setOpenDelete(true)}
            >
              Delete Variants
            </Button>
          </div>
        </div>
      </DialogContent>
      <DeleteVariantModal
        open={openDelete}
        setOpen={setOpenDelete}
        handleDeleteVariant={handleDeleteVariant}
      />
    </Dialog>
  );
};

export default EditVariantGroup;
