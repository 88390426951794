import React from "react";
import SocialLogin from "react-social-login";
import { Button } from "@material-ui/core";

class SocialButton extends React.Component {
  render() {
    return (
      <Button
        onClick={this.props.triggerLogin}
        {...this.props}
        startIcon={this.props.startIcon}
        className={this.props.className}
        size={this.props.size}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default SocialLogin(SocialButton);
