import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  useMediaQuery,
  Zoom,
} from "@material-ui/core";
import { Field } from "redux-form";
import ReduxTextField from "../../reduxFields/textField";
import ReduxGoogleAddressForm from "../../reduxFields/googleAddressForm";
import ReduxSelectField from "../../reduxFields/selectField";
import ReduxCheckbox from "../../reduxFields/checkbox";
import validations from "../../common/validations";
import { ADDRESS } from "../../common/template/components/constants/address";
import { useSelector, useDispatch } from "react-redux";
import SpinnerLoading from "../../common/spinnerLoading";
import { Close } from "@material-ui/icons";
import { destroy } from "redux-form";

const Transition = (props) => <Zoom {...props} />;

export default function PickupAddressModalForm(props) {
  const useStyles = makeStyles((theme) => ({
    label: {
      fontSize: 18,
      fontWeight: 600,
      color: "#2b2d32",
    },
    label2: {
      fontSize: 14,
      fontWeight: 500,
      color: "#2b2d32",
    },
    content: {
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: 6,
      },
      "&::-webkit-scrollbar-track": {
        background: "#eee",
        borderRadius: 8,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#909196",
      },
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const values = useSelector((state) => state.form.delivery_details?.values);
  const errors = useSelector(
    (state) => state.form.delivery_details?.syncErrors
  );
  const anyTouched = useSelector(
    (state) => state.form.delivery_details?.anyTouched
  );
  const [googleAddressOnEdit, setGoogleAddressOnEdit] = React.useState(false);

  const getProvinces = () => {
    let provinces = [];
    for (var i in ADDRESS) {
      provinces.push({
        display: i,
        value: i,
      });
    }
    return provinces;
  };

  const getCities = () => {
    let cities = [];
    let province = values && values.province;
    if (province in ADDRESS) {
      for (var i in ADDRESS[province]["Municipality_list"]) {
        cities.push({
          display: i,
          value: i,
        });
      }
    }
    return cities;
  };

  const getBarangay = () => {
    let baranggays = [];
    let province = values && values.province;
    let city = values && values.city;
    if (province in ADDRESS) {
      if (city in ADDRESS[province]["Municipality_list"]) {
        let baranggay_list =
          ADDRESS[province]["Municipality_list"][city]["Barangay_list"];
        for (var i = 0; i < baranggay_list.length; i++) {
          baranggays.push({
            display: baranggay_list[i],
            value: baranggay_list[i],
          });
        }
      }
    }
    return baranggays;
  };

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="sm"
      TransitionComponent={Transition}
    >
      <div className={`${classes.content} p-4`}>
        <div className="d-flex align-items-center justify-content-between">
          <div className={classes.label}>
            {is_mobile
              ? "Address details"
              : values?.id
              ? "Edit pickup address"
              : "Add new pickup address"}
          </div>
          <IconButton
            className="d-md-none d-flex"
            onClick={() => props.setOpen(false)}
          >
            <Close color="secondary" />
          </IconButton>
        </div>
        <div className="mt-4">
          <div className={classes.label2}>Geolocation</div>
          <div className="d-flex align-items-end">
            <Field
              fullWidth
              id="google_address"
              name="google_address"
              label="for Same-day Delivery couriers"
              component={ReduxGoogleAddressForm}
              validate={[validations.required]}
              disabled={!googleAddressOnEdit}
            />
            <Button
              variant="outlined"
              color="primary"
              className="ml-2"
              style={{
                height: 40,
                minWidth: is_mobile ? 80 : 130,
              }}
              onClick={() => setGoogleAddressOnEdit(!googleAddressOnEdit)}
            >
              {googleAddressOnEdit
                ? `Save ${is_mobile ? "" : `Address`}`
                : `Edit ${is_mobile ? "" : `Address`}`}
            </Button>
          </div>
          {anyTouched ? (
            <div style={{ fontSize: 12, color: "#f44336" }}>
              {errors?.google_address}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row mt-2">
          <div className="col-12 col-md-6 mt-2 d-md-none d-flex">
            <Field
              id="zipcode"
              name="zipcode"
              label="Postcode"
              variant="outlined"
              margin="dense"
              component={ReduxTextField}
              validate={[validations.zipcode, validations.required]}
              inputProps={{ maxlength: 4 }}
              fullWidth
            />
          </div>
          <div className="col-12 col-md-6 mt-2">
            <Field
              id="street"
              name="street"
              label="Street address"
              component={ReduxTextField}
              validate={[validations.required]}
            />
          </div>
          <div className="col-12 col-md-6 mt-2">
            <Field
              id="province"
              name="province"
              label={
                <div style={{ color: "#54575f", fontWeight: "normal" }}>
                  Region / Province
                </div>
              }
              variant="outlined"
              margin="dense"
              component={ReduxSelectField}
              validate={[validations.required]}
              choices={getProvinces()}
              fullWidth
            />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12 col-md-6 mt-2">
            <Field
              id="city"
              name="city"
              label={
                <div style={{ color: "#54575f", fontWeight: "normal" }}>
                  City
                </div>
              }
              variant="outlined"
              margin="dense"
              component={ReduxSelectField}
              choices={getCities()}
              disabled={values && !values.province}
              fullWidth
              validate={[validations.required]}
            />
          </div>
          <div className="col-12 col-md-6 mt-2">
            <Field
              id="brgy"
              name="brgy"
              label={
                <div style={{ color: "#54575f", fontWeight: "normal" }}>
                  Barangay
                </div>
              }
              variant="outlined"
              margin="dense"
              component={ReduxSelectField}
              choices={getBarangay()}
              validate={[validations.required]}
              disabled={values && !values.city}
              fullWidth
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 col-md-6 mt-2 d-md-flex d-none">
            <Field
              id="zipcode"
              name="zipcode"
              label="Postcode"
              variant="outlined"
              margin="dense"
              component={ReduxTextField}
              validate={[validations.zipcode, validations.required]}
              inputProps={{ maxlength: 4 }}
              fullWidth
            />
          </div>
          <div className="col-12 col-md-6 mt-2">
            <Field
              id="contact_person"
              name="contact_person"
              label="Contact person"
              component={ReduxTextField}
              validate={[validations.required]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mt-2">
            <Field
              id="mobile_number"
              name="mobile_number"
              label="Mobile number"
              component={ReduxTextField}
              validate={[validations.required, validations.mobileNumber10]}
              inputProps={{
                maxlength: 10,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <span className="text-dark">+63</span>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="col-12 col-md-6 mt-2">
            <Field
              id="email"
              name="email"
              label="Email address"
              variant="outlined"
              margin="dense"
              component={ReduxTextField}
              disabled
              fullWidth
            />
          </div>
        </div>
        <div className="mt-2">
          <Field
            id="landmark"
            name="landmark"
            label="Location details, landmark, specific instructions, etc."
            component={ReduxTextField}
            validate={[validations.required]}
          />
        </div>
        <div className="mt-2">
          <Field
            id="is_default"
            name="is_default"
            label="Set as default pickup address"
            component={ReduxCheckbox}
          />
        </div>
        <div
          className={`d-flex mt-3 ${is_mobile ? "flex-column-reverse" : ""}`}
        >
          <Button
            variant="outlined"
            color="primary"
            className={`${is_mobile ? "w-100 mt-2" : "w-50 mr-2"}`}
            onClick={() => {
              dispatch(destroy("delivery_details"));
              props.setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={props.loading}
            disableElevation
            variant="contained"
            color="primary"
            className={`${is_mobile ? "w-100" : "w-50"}`}
            onClick={() => props.handleSave()}
          >
            {props.loading ? <SpinnerLoading /> : ""}
            Save pickup address
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
