import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { Button } from "@material-ui/core";
export default function HeaderFilter(props) {
  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    datepicker: {
      backgroundColor: "#fff",
      width: 160,
      "& input": {
        fontSize: 12,
        color: "#54575f"
      }
    }
  }));
  const classes = useStyles();
  const loading = useSelector(
    state => state.enterprise.main.dashboard.analytics.loading
  );
  let date = new Date();
  let default_startdate = new Date();
  default_startdate.setDate(date.getDate() - 30);
  const [state, setState] = React.useState({
    start_date: moment(new Date()).subtract(7,'d').format("MM/DD/YYYY"),
    end_date: moment(new Date()).format("MM/DD/YYYY")
  });

  const handleFilter = () => {
    if (props.onChange)
      props.onChange({
        start_date: moment(state.start_date).format("MM/DD/YYYY"),
        end_date: moment(state.end_date).format("MM/DD/YYYY")
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mt-4 mb-3">
          <strong>Analytics</strong>
        </h3>
        <div className="d-flex align-items-center">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              margin="dense"
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="DD MMM YYYY"
              InputAdornmentProps={{ position: "start" }}
              orientation="portrait"
              color="secondary"
              className={classes.datepicker}
              value={state.start_date}
              maxDate={state.end_date}
              onChange={date => setState({ ...state, start_date: date })}
            />
            <div className="m-1">-</div>
            <KeyboardDatePicker
              margin="dense"
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="DD MMM YYYY"
              InputAdornmentProps={{ position: "start" }}
              orientation="portrait"
              color="secondary"
              className={classes.datepicker}
              value={state.end_date}
              minDate={state.start_date}
              onChange={date => setState({ ...state, end_date: date })}
            />
          </MuiPickersUtilsProvider>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            style={{ height: 40, marginLeft: 10 }}
            onClick={() => handleFilter()}
            className="date-filter-button"
          >
            Apply dates
          </Button>
        </div>
      </div>
    </div>
  );
}
