import axios from "axios";
import apiConfig from "../../../common/apiConfig";

require("dotenv").config();

class Services {
  static async getProducts(store_uuid, params) {
    params = {
      ...params,
      category: JSON.stringify(params.category)
    };
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/dashboard/${store_uuid}/products`,
        {
          params
        }
      );
      return response;
    } catch (e) {
      throw e.response;
    }
  }
}

export default Services;
