import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ACL from "../../../common/utilities/ACL";
import Action from "../../Redux/Actions";
import AdminDashboardLayout from "../../layout";
import { Divider } from "@material-ui/core";
import Path from "../../../../Paths";
import Card from "../../components/dashboardCards";
import numeral from "numeral";


const useStyles = theme => ({
  root: {
    flexGrow: 1,
    padding: "2.0em"
  },
  card: {
    border: "1px solid #E6EAF0",
    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
    margin: "1.5em",
    backgroundColor: "white",
    padding: "2.0em",
    [theme.breakpoints.down("sm")]: {
      margin: "1em"
    }
  },
  cardHeader: {
    fontSize: "28px",
    marginBottom: "0.5em",
    textAlign: "left",
    color: "#F97663",
    fontWeight: 600
  },
  textDivider: {
    fontSize: "18px",
    padding: "1.5em 0",
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    "& > span": {
      marginRight: "1.5em"
    },
    "& > hr": {
      marginTop: 5,
      flexGrow: 1,
      paddingLeft: "3em",
      borderTop: "1px solid rgba(84, 87, 95, 0.75)",
      backgroundColor: "none"
    }
  },

});

const Logistics = (props) => {
  const { classes, history } = props;

  const data = 1500;

  React.useEffect(() => {
    const { dispatch, dateFilter } = props;
    dispatch(Action.loadLogiscticsHome(dateFilter));
    localStorage.setItem("adminMenuActive", 'Logistics');
  }, [])

  const onDateFilterCallback = (filter) => {
    console.log(filter);
    const { dispatch } = props;
    dispatch(Action.loadLogiscticsHome(filter));
  }
  return (
    <AdminDashboardLayout onDateFilterCallback={onDateFilterCallback} backLink="/admin/dashboard">
      <ACL roles={["admin", "superuser"]} redirect_to="/login">
        <div style={{ backgroundColor: "#f1f1f1" }}>
            <div className={classes.card} id="marketing">
              <div className={`${classes.cardHeader}`}>Logistics</div>
              <h3 className={classes.textDivider}>
                <span>Bookings</span>
                <Divider />
              </h3>
              <Grid container spacing={2}>
                <Card
                  title="Total Booking Count"
                  data={props.logistics.total_bookings}
                  numeralFormat="0,0"
                  history={history}
                  link={Path.ADMIN_LOGISTICS_BOOKINGS} 
                />
                <Card
                  title="Completed Bookings"
                  data={props.logistics.completed_bookings}
                  numeralFormat="0,0"
                  history={history}
                  hideLinkText
                  footerText={`${numeral(parseFloat((props.logistics.completed_bookings/props.logistics.total_bookings)*100)).format("0,0")}% of total bookings`}
                />
                <Card
                  title="Ongoing Bookings"
                  data={props.logistics.ongoing_bookings}
                  numeralFormat="0,0"
                  hideLinkText
                  footerText={`${numeral(parseFloat((props.logistics.ongoing_bookings/props.logistics.total_bookings)*100)).format("0,0")}% of total bookings`}
                  history={history}
                />
                <Card title="Cancelled Bookings" 
                  data={props.logistics.cancelled_bookings}
                  numeralFormat="0,0" 
                  hideLinkText
                  footerText={`${numeral(parseFloat((props.logistics.cancelled_bookings/props.logistics.total_bookings)*100)).format("0,0")}% of total bookings`}
                  history={history} 
                />
                <Card
                  title="Total Completed Booking Amount"
                  data={props.logistics.total_completed_bookings_amount}
                  numeralFormat="0,0.00"
                  history={history}
                />
                <Card title="Average Booking Amount" 
                  data={props.logistics.average_booking_amount}
                  numeralFormat="0,0.00" 
                  history={history} 
                />
              </Grid>
            </div>
        </div>
      </ACL>
    </AdminDashboardLayout>
  );
}

const mapStateToProps = state => ({ adminDashboard }) => ({
    dateFilter: adminDashboard.dateFilter, 
    logistics: adminDashboard.logisticsData 
});
export default connect(mapStateToProps)(withStyles(useStyles)(Logistics));
