import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  Link
} from "@material-ui/core";
import Text from "../../../../../common/text";
import { makeStyles } from "@material-ui/styles";
const PaymentCard = props => {
  const { item, paymentMethod, setPaymentMethod } = props;
  const useStyles = makeStyles(theme => ({
    dialog: { backgroundColor: "#fafafa", height: "100%" },
    appBar: {
      position: "relative",
      backgroundColor: "#fafafa"
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    header1: {
      fontSize: 14,
      fontWeight: 600,
      color: "#54575f"
    },
    link: {
      color: "#f97663",
      fontWeight: "600",
      fontSize: 14,
      "&:hover": {
        color: "#f97663"
      }
    },
    paper: {
      position: "relative",
      border: "1px solid #d1d5dd",
      borderRadius: 4,
      boxShadow: "0 8px 12px rgba(0,0,0,0.05)",
      backgroundColor: "#fff",
      padding: 15,
      display: "flex",
      marginBottom: "2vh"
    },
    closeBtn: { position: "absolute", left: 15 }
  }));
  const classes = useStyles();

  function accountType() {
    if (item.payment_method === "Bank") {
      return item.bank_name;
    } else {
      return item.payment_method;
    }
  }

  function paymentDetails1() {
    if (item.payment_method === "Bank" || item.payment_method === "PayMaya") {
      if (item.account_name === null || item.account_name === "null") {
        return "";
      } else {
        return item.account_name;
      }
    } else {
      return "";
    }
  }

  function paymentDetails2() {
    if (item.payment_method === "Bank") {
      return item.account_number;
    } else if (item.payment_method === "PayPal") {
      return item.payment_link;
    } else {
      return item.mobile_number;
    }
  }

  return (
    <div className={classes.paper}>
      <div
        style={{
          width: 90,
          height: 90,
          backgroundSize: "cover",
          backgroundImage: `url(${item.qr_code_img})`
        }}
      />
      <div
        style={{
          padding: "0 10px",
          width: "65%",
          flex: 1
        }}
      >
        <div style={{ display: "block" }}>
          <Text
            textType="p3_Bold"
            content={accountType()}
            style={{ paddingBottom: "1vh" }}
          />
          <Text
            textType="p2"
            content={paymentDetails1()}
            style={{
              paddingBottom: "1vh",
              overflowWrap: "break-word"
            }}
          />
          <Text
            style={{
              overflowWrap: "break-word",
              width: "70%"
            }}
            textType="p2"
            content={paymentDetails2()}
          />
        </div>
        <div style={{ position: "absolute", right: 10, bottom: 10 }}>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: 75, height: 35 }}
            onClick={() => setPaymentMethod({ ...item, viewAccount: true })}
          >
            View
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
