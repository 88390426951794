import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grow,
  Zoom,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import { goToCompleteProfile } from "../../action";
import { RedirectDialog } from "./redirectToBux";
import buxMe from "../../../../assets/images/bux_levels/bux_me.svg";
import buxGig from "../../../../assets/images/bux_levels/bux_gig.svg";
import buxSme from "../../../../assets/images/bux_levels/bux_sme.svg";
import buxBiz from "../../../../assets/images/bux_levels/bux_biz.svg";
import { Carousel } from "antd";
import { styled } from "@material-ui/styles";
const Transition = props => <Grow direction="up" {...props} />;

export default function BuxLimit(props) {
  const useStyles = makeStyles(theme => ({
    head: {
      backgroundColor: "#f5f5f5"
    },
    body: {
      backgroundColor: "#fff",
      fontSize: 12
    },
    bodyBottom: {
      borderBottom: "none",
      backgroundColor: "#fff",
      fontSize: 12
    },
    paper: {
      minWidth: 700
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const data = [
    {
      img: buxMe,
      daily_cashin: "₱ 100,000.00",
      daily_cashout: "₱ 100,000.00",
      monthly_cashin: "₱ 100,000.00",
      monthly_cashout: "₱ 100,000.00",
      annual_cashin: "₱ 1,200,000.00",
      annual_cashout: "₱ 1,200,000.00"
    },
    {
      img: buxGig,
      daily_cashin: "₱ 500,000.00",
      daily_cashout: "₱ 500,000.00",
      monthly_cashin: "₱ 500,000.00",
      monthly_cashout: "₱ 500,000.00",
      annual_cashin: "₱ 6,000,000.00",
      annual_cashout: "₱ 6,000,000.00"
    },
    {
      img: buxSme,
      daily_cashin: "No limits",
      daily_cashout: "No limits",
      monthly_cashin: "No limits",
      monthly_cashout: "No limits",
      annual_cashin: "No limits",
      annual_cashout: "No limits"
    },
    {
      img: buxBiz,
      daily_cashin: "No limits",
      daily_cashout: "No limits",
      monthly_cashin: "No limits",
      monthly_cashout: "No limits",
      annual_cashin: "No limits",
      annual_cashout: "No limits"
    }
  ];

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        TransitionComponent={Transition}
        classes={isMobile ? "" : { paper: classes.paper }}
        fullWidth
        maxWidth="sm"
      >
        <div className="d-flex px-4 pt-2 align-items-center justify-content-between">
          <div style={{ fontSize: 18, fontWeight: 600, color: "#002c42" }}>
            BUx account limits
          </div>
          <IconButton onClick={() => props.setOpen(false)}>
            <Close />
          </IconButton>
        </div>
        {isMobile ? (
          <div className="p-3">
            <Carousel infinite={false}>
              {data.map(item => (
                <div>
                  <div className="d-flex justify-content-center">
                    <img src={item.img} width="auto" height="40" />
                  </div>
                  <div className="border-bottom py-3 d-flex justify-content-between">
                    <div style={{ fontSize: 12 }}>Daily cash-in</div>
                    <div style={{ fontSize: 12 }}>{item.daily_cashin}</div>
                  </div>
                  <div className="border-bottom py-3 d-flex justify-content-between">
                    <div style={{ fontSize: 12 }}>Daily cash-out</div>
                    <div style={{ fontSize: 12 }}>{item.daily_cashout}</div>
                  </div>
                  <div className="border-bottom py-3 d-flex justify-content-between">
                    <div style={{ fontSize: 12 }}>Monthly cash-in</div>
                    <div style={{ fontSize: 12 }}>{item.monthly_cashin}</div>
                  </div>
                  <div className="border-bottom py-3 d-flex justify-content-between">
                    <div style={{ fontSize: 12 }}>Monthly cash-out</div>
                    <div style={{ fontSize: 12 }}>{item.monthly_cashin}</div>
                  </div>
                  <div className="border-bottom py-3 d-flex justify-content-between">
                    <div style={{ fontSize: 12 }}>Annual cash-in</div>
                    <div style={{ fontSize: 12 }}>{item.annual_cashin}</div>
                  </div>
                  <div className="border-bottom py-3 d-flex justify-content-between">
                    <div style={{ fontSize: 12 }}>Annual cash-out</div>
                    <div style={{ fontSize: 12 }}>{item.annual_cashout}</div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        ) : (
          <div className="px-4 pb-5 pt-2  ">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.head}
                    style={{ borderRadius: "5px 0 0 0" }}
                  ></TableCell>
                  <TableCell align="right" className={classes.head}>
                    <img src={buxMe} width="auto" height="30" />
                  </TableCell>
                  <TableCell align="right" className={classes.head}>
                    <img src={buxGig} width="auto" height="30" />
                  </TableCell>
                  <TableCell align="right" className={classes.head}>
                    <img src={buxSme} width="auto" height="30" />
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.head}
                    style={{ borderRadius: "0 5px 0 0" }}
                  >
                    <img src={buxBiz} width="auto" height="30" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.body}>Daily cash-in</TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 100,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 500,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.body}>Daily cash-out</TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 100,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 500,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.body}>
                    Monthly cash-in
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 100,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 500,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.body}>
                    Monthly cash-out
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 100,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 500,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.body}>Annual cash-in</TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 1,200,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    ₱ 6,000,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                  <TableCell align="right" className={classes.body}>
                    No limits
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.bodyBottom}>
                    Annual cash-out
                  </TableCell>
                  <TableCell align="right" className={classes.bodyBottom}>
                    ₱ 1,200,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.bodyBottom}>
                    ₱ 6,000,000.00
                  </TableCell>
                  <TableCell align="right" className={classes.bodyBottom}>
                    No limits
                  </TableCell>
                  <TableCell align="right" className={classes.bodyBottom}>
                    No limits
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
      </Dialog>
      <RedirectDialog open={open} setOpen={setOpen} />
    </>
  );
}
