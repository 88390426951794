import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Paper,
  Link,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent
} from "@material-ui/core";
import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DeleteForeverTwoTone, ExpandMore } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "../../store/history";
import Actions from "./Redux/Actions";
import Utils from "../common/utils";
import defaultSRC from "../common/template/components/defaultResources";
import { useLocation } from "react-router-dom";
import defaultColor from "../common/template/components/colors";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import imgPlaceholder from "../../assets/images/product_placeholder.png";
import Path from "../../Paths";
const MarketplaceCart = props => {
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/cart", "");
  const storeName = param.replace("/shop/", "");

  React.useEffect(() => {
    // async function fetchData() {
    //   await dispatch(StoreActions.getMyStoreInfo(storeName));
    // }
    // fetchData();
  }, []);

  const CartStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "#FAFAFA",
      minHeight: "95vh",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: 60,
        display: "none"
      }
    },

    content1: {
      height: "100vh",
      width: "100%"
    },
    cart: {
      padding: "48px 0",
      [theme.breakpoints.down("sm")]: {
        padding: 25
      }
    },
    header: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },

    total: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        marginTop: 20,
        display: "block"
      }
    },

    totalContent: {
      display: "flex",
      marginRight: 20,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 20
      }
    },
    cartTable: {
      flex: 7,
      marginRight: 20,
      [theme.breakpoints.down("md")]: {
        display: "block",
        marginRight: 0
      }
    },

    cartContent: {
      marginTop: "6vh",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },
    summaryContainer: {
      flex: 3,
      [theme.breakpoints.down("sm")]: {
        padding: "0px 100px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0
      }
    },
    summary: {
      minWidth: "20vw",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 8,
      [theme.breakpoints.down("sm")]: {
        marginTop: "5vh",
        display: "none"
      }
    },

    summaryContent: {
      padding: 20,
      [theme.breakpoints.down("sm")]: {
        padding: 50
      },
      [theme.breakpoints.down("xs")]: {
        padding: 20
      }
    },
    buttonCheckout: {
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      }
    },

    buttonCheckout2: {
      color: "#fff",
      width: "100%",
      marginTop: 10,
      backgroundColor: "#F97663"
    },
    tableColumnItem: {
      width: 220,
      textWrap: "wrap",
      wordWrap: "break-word",
      [theme.breakpoints.down("md")]: {
        width: 180
      }
    },

    tableColumn: {
      width: 120,
      textWrap: "wrap",
      textAlign: "center",
      wordWrap: "break-word",
      [theme.breakpoints.down("md")]: {
        width: 90
      }
    },

    productImage: {
      width: 90,
      height: 90,

      [theme.breakpoints.down("md")]: {
        width: 60,
        height: 60
      }
    },
    mobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginTop: 53,
        backgroundColor: "#FAFAFA"
      }
    },
    summaryMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        margin: "20px 120px",
        padding: 50,
        display: "block",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
      },
      [theme.breakpoints.down("xs")]: {
        margin: 0,
        padding: 20,
        display: "block",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
      }
    },
    orderTable: {
      overflow: "auto",
      padding: 30,
      width: "63vw",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 8,
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    orderMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "1vh 120px"
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
        margin: "5vh 0px",
        padding: 0
      }
    },
    btnQuantity: {
      minWidth: 40,
      maxWidth: 40,
      maxHeight: 37,
      margin: 2
    },
    quantityField: {
      width: 70
    },
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      minWidth: 120
    },
    backDivWrapper: {
      margin: "0 0 1em",
      display: "flex",
      "& > span": {
        marginLeft: "1em",
        fontWeight: 600
      },
      color: "#F97663 !important",
      alignItems: "center",
      cursor: "pointer",
      width: 100
    }
  }));

  const classes = CartStyles();
  const dispatch = useDispatch();
  let productList = useSelector(state => state.marketplace.cart);
  console.log("productList", productList);
  const [quantity, setQuantity] = React.useState(0);
  const [selected, setSelected] = React.useState(0);
  const [edit, setEdit] = React.useState(false);
  const [editMobile, setEditMobile] = React.useState(false);
  const [error, setError] = React.useState({
    quantityError: ""
  });
  let productQuantity = 0;
  // for (let existingProduct of products) {
  //   if (existingProduct["uuid"] === selectedProduct["uuid"]) {
  //     productQuantity = existingProduct.quantity;
  //   }
  // }
  let handleQuantity = input => {
    let sanitizedInput = parseInt(
      input.replace(/[^0-9]|(^0+)/g, "").substring(0, 3)
    );
    setQuantity(sanitizedInput);
  };
  //   function editValue(value, key) {
  //     dispatch(StoreActions.getSelectedProduct(value));
  //     setEdit(true);
  //     setSelected(key);
  //     setQuantity(value.quantity);
  //   }

  function decrement() {
    if (quantity === 1) {
      setQuantity(1);
    } else {
      setQuantity(quantity - 1);
    }
  }

  function increment() {
    setQuantity(quantity + 1);
    // if (productList.made_to_order ? "" : quantity === productQuantity) {
    //   setQuantity(productQuantity);
    // } else {
    //   setQuantity(quantity + 1);
    // }
  }

  //   function saveEdit(value) {
  //     let itemQuantity = isNaN(quantity) ? 1 : quantity;
  //     let params = {
  //       quantity: itemQuantity,
  //       name: value.name,
  //       productDescription: value.description,
  //       price: value.price,
  //       images: value.images,
  //       uuid: value.uuid,
  //       store: value.store
  //     };
  //     dispatch(StoreActions.updateQuantity(params));
  //     setEdit(false);
  //   }

  // let handleQuantity = input => {
  //   let sanitizedInput = parseInt(
  //     input.replace(/[^0-9]|(^0+)/g, "").substring(0, 3)
  //   );
  //   setQuantity(sanitizedInput);
  // };
  let totalAmt = parseFloat(
    productList.reduce((total, object) => {
      return total + object.unit_price * object.quantity;
    }, 0)
  );
  let amount = totalAmt;

  function renderTable() {
    return (
      <div>
        <Paper className={classes.orderTable}>
          <Typography
            style={{
              fontSize: 26,
              fontWeight: 600,
              color: "#002C42"
            }}
          >
            Orders
          </Typography>

          <Table style={{ tableLayout: "fixed", marginTop: "2vh" }}>
            <TableHead style={{ borderTop: "1px solid #D1D5DD" }}>
              <TableRow>
                <TableCell className={classes.tableColumn}></TableCell>
                <TableCell className={classes.tableColumnItem} align="left">
                  Item
                </TableCell>
                <TableCell style={{ width: 200 }} align="center">
                  Quantity
                </TableCell>
                <TableCell align="center">Subtotal</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productList && productList.length > 0 ? (
                productList.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell
                      className={classes.tableColumn}
                      component="th"
                      scope="row"
                    >
                      <div
                        className={classes.productImage}
                        style={{
                          backgroundImage: `url(${
                            row.logo ? row.logo : imgPlaceholder
                          })`,
                          backgroundSize: "cover"
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.tableColumnItem} align="left">
                      <div style={{ display: "block" }}>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 600
                          }}
                        >
                          {row.app_name} - {row.variant_name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            marginTop: "2vh"
                          }}
                        >
                          ₱ {Utils.commafy(parseFloat(row.unit_price))}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {edit && selected === key ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip
                            title={
                              quantity > productQuantity
                                ? "Maximum of " + productQuantity + " items"
                                : ""
                            }
                            placement="top"
                          >
                            <TextField
                              type="number"
                              color="secondary"
                              margin="dense"
                              variant="outlined"
                              value={quantity}
                              onChange={value => {
                                handleQuantity(value.target.value);

                                if (value.target.value === "") {
                                  setError({
                                    ...error,
                                    quantityError: "This field is required."
                                  });
                                } else {
                                  setError({
                                    ...error,
                                    quantityError: ""
                                  });
                                }
                              }}
                              inputProps={{
                                step: 1,
                                min: 1,
                                max: productQuantity
                              }}
                              InputProps={{
                                className: classes.quantityField
                              }}
                            />
                          </Tooltip>
                          <Button
                            onClick={() => decrement()}
                            className={classes.btnQuantity}
                            variant="outlined"
                          >
                            <RemoveIcon style={{ color: "#828894" }} />
                          </Button>
                          <Tooltip
                            title={
                              row.always_available
                                ? ""
                                : quantity >= productQuantity
                                ? "Maximum of " + productQuantity + " items"
                                : ""
                            }
                            placement="top"
                          >
                            <Button
                              onClick={() => increment()}
                              className={classes.btnQuantity}
                              variant="outlined"
                            >
                              <AddIcon style={{ color: "#828894" }} />
                            </Button>
                          </Tooltip>
                        </div>
                      ) : (
                        row.quantity
                      )}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 600 }}>
                      ₱{" "}
                      {Utils.commafy(parseFloat(row.quantity * row.unit_price))}
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: "flex" }}>
                        {/* <Tooltip title="Edit" placement="top">
                        <Button onClick={() => editValue(row, key)}>
                            <EditIcon style={{ color: "#909196" }} />
                        </Button>
                        </Tooltip> */}
                        <Tooltip title="Remove" placement="top">
                          <Button onClick={() => removeFromCart(key)}>
                            <DeleteForeverTwoTone
                              style={{ color: "#909196" }}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <div
                  style={{
                    width: "55vw",
                    textAlign: "center",
                    padding: 20
                  }}
                >
                  No item(s) in your cart.
                </div>
              )}
            </TableBody>
          </Table>
        </Paper>

        <div className={classes.orderMobile}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              style={{ borderBottom: "1px solid #D1D5DD" }}
              expandIcon={<ExpandMore />}
            >
              <Typography
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: "rgb(0, 44, 66)"
                }}
              >
                Orders
              </Typography>
            </ExpansionPanelSummary>
            {productList && productList.length > 0 ? (
              productList.map((row, key) => (
                <ExpansionPanelDetails
                  key={row.name}
                  style={{ borderBottom: "1px solid #D1D5DD" }}
                >
                  <div
                    style={{
                      padding: "10px 0",
                      width: "100%",
                      display: "block"
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={2} style={{ fontSize: 16 }}>
                          {edit && selected === key ? (
                            <TextField
                              type="number"
                              color="secondary"
                              margin="dense"
                              variant="outlined"
                              value={quantity}
                              onChange={value => {
                                handleQuantity(value.target.value);

                                if (value.target.value === "") {
                                  setError({
                                    ...error,
                                    quantityError: "This field is required."
                                  });
                                } else {
                                  setError({
                                    ...error,
                                    quantityError: ""
                                  });
                                }
                              }}
                              inputProps={{
                                step: 1,
                                min: 1,
                                max: productQuantity
                              }}
                              InputProps={{
                                className: classes.quantityField
                              }}
                            />
                          ) : (
                            row.quantity
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          style={{
                            fontSize: 16,
                            fontWeight: "bold"
                          }}
                        >
                          {row.app_name} - {row.variant_name}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            fontSize: 16,
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                        >
                          ₱{" "}
                          {Utils.commafy(parseFloat(row.quantity * row.price))}
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={2}></Grid>
                        <Grid
                          item
                          xs={5}
                          style={{ fontSize: 16, marginTop: "1vh" }}
                        >
                          ₱ {Utils.commafy(parseFloat(row.price))}
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                        >
                          <div style={{ display: "block" }}>
                            <Button
                              style={{ margin: 0, padding: 0 }}
                              onClick={() => removeFromCart(key)}
                            >
                              <DeleteForeverTwoTone
                                style={{ color: "#909196" }}
                              />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              ))
            ) : (
              <ExpansionPanelDetails>
                <div
                  style={{
                    padding: "10px 0",
                    width: "100%",
                    textAlign: "center"
                  }}
                >
                  No item(s) in your cart
                </div>
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>
        </div>
      </div>
    );
  }

  function renderSummary() {
    return (
      <div>
        <Paper variant="outlined" className={classes.summary}>
          <Box className={classes.summaryContent}>
            <Typography
              variant="h6"
              style={{
                fontSize: 26,
                fontWeight: 600,
                color: "#002C42"
              }}
            >
              Summary
            </Typography>

            <Typography
              style={{ marginTop: 20, fontSize: 14 }}
              variant="caption"
              display="block"
              gutterBottom
            >
              Total number of items:{" "}
              {parseFloat(
                productList.reduce((total, object) => {
                  return parseFloat(total) + parseFloat(object.quantity);
                }, 0)
              )}
            </Typography>

            <Box style={{ marginTop: 20 }}>
              <Box style={{ display: "flex" }}>
                <Typography style={{ flexGrow: 1 }}>Subtotal</Typography>
                <Typography
                  style={{
                    flexGrow: 1,
                    justifyContent: "flex-end",
                    display: "flex"
                  }}
                >
                  ₱ {Utils.commafy(parseFloat(totalAmt))}
                </Typography>
              </Box>
              <Box style={{ display: "flex", marginTop: 10 }}>
                <Typography variant="h6">Total</Typography>
                <Typography
                  variant="h6"
                  style={{
                    flexGrow: 1,
                    justifyContent: "flex-end",
                    display: "flex"
                  }}
                >
                  ₱ {Utils.commafy(parseFloat(totalAmt))}
                </Typography>
              </Box>
            </Box>

            <Button
              id="checkout_button"
              onClick={() => history.push(Path.APP_MARKETPLACE_CHECKOUT)}
              disabled={productList.length == 0}
              variant="contained"
              color="primary"
              className={classes.buttonCheckout2}
            >
              Proceed to checkout
            </Button>
          </Box>
        </Paper>
        <Paper className={classes.summaryMobile}>
          <div
            style={{
              paddingBottom: 10,
              borderBottom: "1px solid #D1D5DD"
            }}
          >
            <Typography
              style={{
                fontsize: 26,
                fontWeight: 600,
                color: "rgb(0, 44, 66)"
              }}
            >
              Summary
            </Typography>
          </div>
          <div
            style={{ padding: "10px 0", borderBottom: "1px dashed #D1D5DD" }}
          >
            <Typography
              style={{
                fontSize: 14,
                color: "#54575F"
              }}
            >
              Items:{" "}
              {parseFloat(
                productList.reduce((total, object) => {
                  return parseFloat(total) + parseFloat(object.quantity);
                }, 0)
              )}
            </Typography>
          </div>
          <div
            style={{ padding: "10px 0", borderBottom: "1px dashed #D1D5DD" }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <Typography
                  style={{
                    fontSize: 16
                  }}
                >
                  Subtotal
                </Typography>
              </div>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <Typography
                  style={{
                    fontSize: 16
                  }}
                >
                  ₱{" "}
                  {Utils.commafy(
                    parseFloat(
                      productList.reduce((total, object) => {
                        return parseFloat(total) + parseFloat(object.amount);
                      }, 0)
                    )
                  )}
                </Typography>
              </div>
            </div>
          </div>
          <div style={{ padding: "10px 0" }}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold"
                  }}
                >
                  Total
                </Typography>
              </div>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold"
                  }}
                >
                  ₱ {Utils.commafy(totalAmt)}
                </Typography>
              </div>
            </div>
            <Button
              id="checkout_button"
              onClick={() => history.push(Path.APP_MARKETPLACE_CHECKOUT)}
              disabled={productList.length == 0}
              variant="contained"
              color="primary"
              className={classes.buttonCheckout2}
            >
              Proceed to checkout
            </Button>
          </div>
        </Paper>
      </div>
    );
  }

  function removeFromCart(index) {
    dispatch(Actions.removeItemFromCart(index));
  }
  return (
    <div>
      <Box className={classes.root}>
        <div style={{ padding: "0 3vw" }}>
          <Box className={classes.cart}>
            <div
              className={classes.backDivWrapper}
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
              <span>BACK</span>
            </div>
            <Box className={classes.header}>
              <Box style={{ flex: 8 }}>
                <Typography
                  variant="h4"
                  style={{
                    fontSize: 30,
                    color: "#002C42",
                    fontWeight: 600
                  }}
                >
                  Shopping Cart
                </Typography>
              </Box>
            </Box>

            {/* {totalAmt > 10000 ? (
              <div style={{ marginTop: 10, marginBottom: 20 }}>
                <Alert
                  // message="Error Text"
                  description="Transactions are limited up to a total amount of ₱10,000.00 only. We are continuously working to improve on this. Thank you for your understanding."
                  type="error"
                />
              </div>
            ) : (
              ""
            )} */}
            <Box className={classes.cartContent}>
              <div className={classes.cartTable}>{renderTable()}</div>
              <div className={classes.summaryContainer}>{renderSummary()}</div>
            </Box>
          </Box>
        </div>
      </Box>

      <Box className={classes.mobile}>
        <div
          style={{
            textAlign: "center",
            padding: "30px 20px 20px",
            width: "100%",
            fontSize: 24,
            fontWeight: 600,
            color: "rgb(0, 44, 66)",
            borderBottom: "1px solid #D1D5DD"
          }}
        >
          Shopping Cart
        </div>
        {renderSummary()}
        {renderTable()}
      </Box>
    </div>
  );
};

export default MarketplaceCart;
