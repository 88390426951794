import React from "react";
import { Snackbar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import OrderActions from "./orderActions";
require("dotenv").config();

const useStyles = makeStyles(theme => ({
  snackBar: {
    backgroundColor: "#fff",
    color: "#F97663"
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBarAlert = props => {
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  const classes = useStyles();
  const dispatch = useDispatch();

  function closeDialogBox() {
    dispatch(OrderActions.closeAlert());
  }

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => closeDialogBox()}
    >
      <Alert onClose={() => closeDialogBox()} severity={props.alertType}>
        {props.message}
      </Alert>
    </Snackbar>
    // <Snackbar
    //   ContentProps={{
    //     classes: {
    //       root: classes.snackBar
    //     }
    //   }}
    //   anchorOrigin={{ vertical: "top", horizontal: "center" }}
    //   open={props.open}
    //   autoHideDuration={5000}
    //   onClose={() => closeDialogBox()}
    //   message={props.message}
    //   action={
    //     <React.Fragment>
    //       <IconButton
    //         id="close_button"
    //         size="small"
    //         aria-label="close"
    //         color="inherit"
    //         onClick={() => closeDialogBox()}
    //       >
    //         <CloseIcon fontSize="small" />
    //       </IconButton>
    //     </React.Fragment>
    //   }
    // />
  );
};

export default SnackBarAlert;
