import React from "react";
import logo from "../../../../../assets/images/sentro_logo2.png";
import apiConfig from "../../../../../common/apiConfig";
import {
  Link,
  Dialog,
  IconButton,
  useMediaQuery,
  Container
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Path from "../../../../../Paths";
import CloseIcon from "@material-ui/icons/Close";
import PrivacyPolicy from "../../../privacyPolicy";
import { makeStyles } from "@material-ui/core/styles";
import TermsAndConditions from "../../../termsAndConditions";
import { useTheme } from "@material-ui/styles";
export default function Footer(props) {
  const history = useHistory();
  const store = useSelector(
    state => state.store.store || state.enterprise.main.enterprise
  );
  const current_year = new Date().getFullYear();
  const useStyles = makeStyles(theme => ({
    privacy_policy: {
      padding: 32,
      overflowY: "scroll",
      height: "70vh",
      backgroundColor: "#fafafa",
      "&::-webkit-scrollbar": {
        width: 6
      },
      "&::-webkit-scrollbar-track": {
        background: "#eee"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "gray"
      },
      [theme.breakpoints.down("sm")]: {
        padding: 12
      }
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [open, setOpen] = React.useState({
    privacy_policy: false,
    terms_and_conditions: false
  });

  const handleClose = () => {
    setOpen({ privacy_policy: false, terms_and_conditions: false });
  };

  const privacy_policy = (
    <Dialog
      id="privacy_policy"
      fullWidth
      maxWidth="md"
      open={open.privacy_policy}
      onClose={handleClose}
    >
      <div style={{ overflow: "hidden" }}>
        <div className="d-flex justify-content-end">
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "#54575f" }} />
          </IconButton>
        </div>
        <div className={`${isMobile ? "px-3 pb-3" : "px-5 pb-5"}`}>
          <h3>
            <strong>Privacy Policy</strong>
          </h3>
          <div className={classes.privacy_policy}>
            <PrivacyPolicy labelFontSize={14} />
          </div>
        </div>
      </div>
    </Dialog>
  );

  const terms_and_conditions = (
    <Dialog
      id="terms_and_conditions"
      fullWidth
      maxWidth="md"
      open={open.terms_and_conditions}
      onClose={handleClose}
    >
      <div style={{ overflow: "hidden" }}>
        <div className="d-flex justify-content-end">
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "#54575f" }} />
          </IconButton>
        </div>
        <div className={`${isMobile ? "px-3 pb-3" : "px-5 pb-5"}`}>
          <h3>
            <strong>Terms & Conditions</strong>
          </h3>
          <div className={classes.privacy_policy}>
            <TermsAndConditions labelFontSize={14} />
          </div>
        </div>
      </div>
    </Dialog>
  );
  return (
    <div style={{ backgroundColor: props.bg_color ? props.bg_color : "#fff" }}>
      {store && (
        <Container>
          <div
            className={`${
              isMobile
                ? "justify-content-center flex-column"
                : "justify-content-between"
            } d-flex align-items-center py-3`}
          >
            <div
              className={`${isMobile &&
                "flex-column"} d-flex align-items-center`}
            >
              <div
                className={`d-flex align-items-center ${
                  isMobile ? "mr-0 my-3" : "mr-5"
                }`}
              >
                <div
                  style={{ fontSize: 12, color: "#54575f" }}
                  className="mr-2"
                >
                  Powered by
                </div>
                <img
                  src={logo}
                  alt="logo"
                  width="auto"
                  height="15"
                  onClick={() => window.open(Path.HOME)}
                />
              </div>
              <div className="d-flex ml-2">
                <Link
                  id="privacy_policy_btn"
                  style={{ fontSize: 12, color: "#f97663" }}
                  className="mr-4"
                  variant="text"
                  onClick={() => setOpen({ ...open, privacy_policy: true })}
                >
                  Privacy Policy
                </Link>
                <Link
                  id="terms_and_conditions_btn"
                  style={{ fontSize: 12, color: "#f97663" }}
                  variant="text"
                  onClick={() =>
                    setOpen({ ...open, terms_and_conditions: true })
                  }
                >
                  Terms and Conditions
                </Link>
                {privacy_policy}
                {terms_and_conditions}
              </div>
            </div>

            <div
              className={`${isMobile && "mt-3"}`}
              style={{ fontSize: 12, color: "#54575f" }}
            >
              Copyright © {current_year} {store.name}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
