import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme, makeStyles } from "@material-ui/styles";
import { Box, Typography, Tabs, Tab } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={{
            padding: 20,
            overflowY: value === 0 ? "hidden" : "scroll"
          }}
          p={3}
        >
          <Typography style={{ height: "100%", position: "relative" }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const DescriptionTab = props => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };
  const useStyles = makeStyles(theme => ({}));
  const classes = useStyles();
  console.log(props.product);
  return (
    <div style={{ marginTop: "2vh" }}>
      <Tabs
        style={{ backgroundColor: "#fff" }}
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab
          style={{ fontSize: 16, color: "#2b2d33" }}
          label="Description"
          {...a11yProps(0)}
        />
        <Tab
          style={{ fontSize: 16, color: "#2b2d33" }}
          label="Notes"
          {...a11yProps(1)}
        />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div
            style={{
              width: "100%",
              wordWrap: "break-word",
              whiteSpace: "pre-line"
            }}
          >
            {ReactHtmlParser(props.product ? props.product.description : "")}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div
            style={{
              width: "100%",
              wordWrap: "break-word",
              whiteSpace: "pre-line"
            }}
          >
            {props.store ? props.store.order_instructions : ""}
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default DescriptionTab;
