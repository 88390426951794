import React from "react";
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";


const useStyles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    padding: "2em",
    border: "1px solid #D1D5DD",
    borderRadius: 4,
    marginBottom: "2em"
  },
  title: {
    color: "#1E4B83",
    marginBottom: 10,
    fontWeight: "bold"
  },
  flex: {
    display: "flex"
  },
  marginBottom: {
    marginBottom: "1em"
  },
  titleDiv: {
    paddingRight: 30
  },
  actionDiv: {
    marginLeft: "auto",
    minWidth: 220,
  },
  button: {
    minWidth: 100,
    height: 35
  },
  featuresDiv: {
    width: "50%",
    paddingRight: "1.5em"
  },
  requirementsDiv: {
    width: "50%",
    paddingLeft: "1.5em"
  },
  noMarginBottom: {
    marginBottom: 0
  }
});

class VariantDetailed extends React.Component {
  
  render (){
    const { classes, data } = this.props;
    return (
      <div className={classes.root}>
        <div className={`${classes.flex} ${classes.marginBottom}`}>
          <div className={classes.titleDiv}>
            <p className={classes.title}>{data.name}</p>
            <p>{data.description}</p>
          </div>
          <div className={`${classes.actionDiv} ${classes.flex}`}>
            <div className={classes.price}>
              <p className={classes.title}>{data.price > 0 ? `PHP ${data.price}`: "FREE" }</p>
              <p>{data.timeframe==="one_time" ? `` : 
              ["daily", "monthly", "yearly"].includes(data.timeframe) ? 
              `per ${data.timeframe.slice(0,-2)}`
              :
              `${["3_months", "6_months", "9_months", "12_months"].includes(data.timeframe) ? `for` : `per`} ${data.timeframe.split("_").join(" ")}`}</p>
            </div>
            <Button
              id="action_button"
              variant="outlined"
              onClick={(event) => {
                this.props.onGet()
              }} 
              color="primary"
              margin="dense"
              className={classes.button}
              style={{ marginLeft: "auto" }}
            >
              Get
            </Button>
          </div>
        </div>
        <div className={`${classes.flex}`}>
          {
            data.features ?
            <div className={classes.featuresDiv}>
              <p className={classes.title}>Feature</p>
              {
                data ?
                data.features.split("\n").map((text) => (
                  <p className={classes.noMarginBottom}>{text}</p>
                ))
                :
                ``
              }
            </div>
            :
            ``
          }

          {
            data.requirements ?
            <div className={classes.requirementsDiv}>
              <p className={classes.title}>Requirements</p>
              {
                data ?
                data.requirements.split("\n").map((text) => (
                  <p className={classes.noMarginBottom}>{text}</p>
                ))
                :
                ``
              }
            </div>
            :
            ``
          }
        </div>
      </div>
    )
  }
}

export default withStyles(useStyles)(VariantDetailed);
