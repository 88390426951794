import React from "react";
import { Button } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import sentroTheme from "./sentroTheme";
import SpinnerLoading from "./spinnerLoading";

const SentroButton = props => {
  return (
    <ThemeProvider theme={sentroTheme}>
      <Button
        id="button"
        style={{
          padding: "10px 27px",

          marginTop: "1vh"
        }}
        color="primary"
        {...props}
      >
        {props.loading ? <SpinnerLoading /> : ""}
        {props.label}
      </Button>
    </ThemeProvider>
  );
};

export default SentroButton;
