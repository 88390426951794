export const weightRange = weight => {
  if (weight === "0-1") {
    return 1;
  } else if (weight === "1-3") {
    return 2;
  } else {
    return weight;
  }
};

export const getProductsWeight = items => {
  return items.length
    ? items.reduce(
        (total, object) =>
          total + parseInt(weightRange(object.weight) * object.quantity),
        0
      )
    : 1;
};
