import React from 'react';

import '../style/custom.css';

const SortFilter = (props) => {
  return (
    <>
      <div className="sort-button-wrap">
        <div className="sort-button nft-font-barlow">
          <span className="pl-4 pr-1 py-1">SORT</span>
          <div className="sort-button-divider"></div>
          <select
            defaultValue={props.sortBy}
            onChange={(e) => props.onClickSortNFT(e.target.value)}
          >
            <option value="highest">HIGHEST PRICE</option>
            <option value="lowest">LOWEST PRICE</option>
            <option value="a-z">A-Z</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default SortFilter;
