import React from "react";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../store/history";
import StoreActions from "./StoreActions";
import AppBar from "./AppBar";
import Footer from "./Footer";
import ProductDetailsTemplate from "./productDetails/productDetailsTemplate";
import apiConfig from "../../../../common/apiConfig";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import imgPlaceholder from "../../../../assets/images/product_placeholder.png";
import SideBar from "./SideBar";
import moment from "moment";

const ViewProduct = (props) => {
  let theme = useTheme();
  let mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  let product = useSelector((state) => state.store.selectedProduct);
  let store = useSelector((state) => state.store.store);
  let total = useSelector((state) => state.store.total);
  let location = useLocation();
  let split = location.search.split("&");
  let uuid = split[0].replace("?uuid=", "");
  let storeLink = `${apiConfig.localHost}shop/${store.site}`;
  let productLink = `${apiConfig.localHost}shop/${store.site}/viewproduct?uuid=${uuid}`;

  React.useEffect(() => {
    let querystring = window.location.search;
    const urlParams = new URLSearchParams(querystring);
    const params = {};
    const misc = [
      "uuid",
      "assigned_to",
      "event_type_uuid",
      "event_type_name",
      "invitee_uuid",
      "event_end_time",
    ];
    for (const param of urlParams) {
      if (!misc.includes(param[0])) {
        if (param[0] === "event_start_time") {
          params["selected_schedule"] = moment(param[1]).format(
            "h:mmA dddd, MMMM DD, YYYY"
          );
        } else {
          params[param[0]] = param[1];
        }
      }
    }
    if(Object.keys(params).length > 0){
      async function setRemarks() {
        await dispatch(StoreActions.setRemarks(params));
      }
      setRemarks();   
    }

  }, []);

  React.useEffect(() => {
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(props.id));
    }
    fetchData();
    dispatch(StoreActions.getProduct(uuid));

    window.dataLayer.push({
      event: "productDetails",
      ecommerce: {
        detail: {
          products: [
            {
              id: product.uuid,
              name: product.name,
              price: product.price,
              [`dimension${apiConfig.indexStoreID}`]: product.store,
            },
          ],
        },
      },
    });
  }, []);

  React.useEffect(() => {
    if (store) {
      dispatch(StoreActions.getStoreProductCategories(store.uuid));
    }
  }, [store, dispatch]);

  const CartStyles = makeStyles((theme) => ({
    root: {
      marginTop: "10vh",
      height: "100vh",
      width: "100%",
    },

    content1: {
      height: "100vh",
      width: "100%",
    },
    menu: {
      width: 200,
    },
  }));
  let storeInfo = useSelector((state) => state.store.store);
  const shippingFee = parseFloat(0); // #TODO useSelector(state => state.store.shipping_fee);

  function addToCart(params) {
    console.log(params);
    dispatch(StoreActions.addToCart(params, true));
  }

  const [quantity, setQuantity] = React.useState(1);
  const [quantityErrorText, setQuantityErrorText] = React.useState("");
  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [errors, setErrors] = React.useState({
    quantityError: "",
  });
  let handleQuantity = (input) => {
    let sanitizedInput = parseInt(
      input.replace(/[^0-9]|(^0+)/g, "").substring(0, 3)
    );
    setQuantity(sanitizedInput);

    // if (sanitizedInput > 100) {
    //   setQuantityErrorText(
    //     "You are allowed to order up to a maximum of 100 pieces"
    //   );
    // } else if ((sanitizedInput * product.price) + getValueInCart() + shippingFee > 10000) {
    //   setQuantityErrorText(
    //     "Your desired quantity has exceeded the maximum of P9,985.00 for this item"
    //   );
    // } else {
    //   setQuantityErrorText("");
    // }
  };

  function onSelect(value) {
    console.log(value);
    dispatch(StoreActions.getSelectedProduct(value));
    history.push(`/shop/${props.id}/viewproduct?uuid=${value.uuid}`);
  }

  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [errors]);

  let productList = useSelector((state) => state.store.items);
  let existingQuantity = 0;

  for (let existingProduct of productList) {
    if (existingProduct["uuid"] === product["uuid"]) {
      existingQuantity = existingProduct.quantity;
    }
  }

  let gallery = product ? (product.gallery ? product.gallery : "") : "";
  var images = [];
  if (gallery.length === 0) {
    if (product.images) {
      let params = { original: product.images };
      images.push(params);
    } else {
      let params = { original: imgPlaceholder, thumbnail: imgPlaceholder };
      images.push(params);
    }
  } else {
    for (let i in gallery) {
      let params = {
        original: gallery[i].image,
        thumbnail: gallery[i].image,
      };
      images.push(params);
    }
  }

  React.useEffect(() => {
    if (
      quantity > product.quantity - existingQuantity &&
      product.quantity - existingQuantity != 0
    ) {
      setErrors({
        ...errors,
        quantityError: product.always_available
          ? ""
          : "Maximum of " +
            `${product.quantity - existingQuantity}` +
            " item(s)",
      });
    } else {
      setErrors({
        ...errors,
        quantityError: "",
      });
    }
  }, [quantity]);

  const store_theme = useSelector((state) => state.store.store.color);

  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold",
      },
      fontFamily: ["Inter", "sans-serif"],
    },
  });

  const LeftistLayout = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBar: {
      display: "block",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
    },
  }));
  const leftistStyles = LeftistLayout();

  const content = (
    <ProductDetailsTemplate
      storeLink={storeLink}
      productLink={productLink}
      quantity={quantity}
      setQuantity={setQuantity}
      quantityErrorText={quantityErrorText}
      handleQuantity={handleQuantity}
      store={storeInfo}
      addToCart={addToCart}
      product={product}
      errors={errors}
      setErrors={setErrors}
      formHasErrors={formHasErrors}
      setFormHasErrors={setFormHasErrors}
      existingQuantity={existingQuantity}
      productImages={images}
      mobileView={mobileView}
      openAlert={openAlert}
      setOpenAlert={setOpenAlert}
      onSelect={onSelect}
      total={total}
    />
  );

  const set_layout = () => {
    switch (storeInfo ? storeInfo.template : "Blank") {
      default:
        return (
          <div>
            {storeInfo && storeInfo.font.css_url ? (
              <link href={storeInfo.font.css_url} rel="stylesheet"></link>
            ) : (
              ""
            )}
            <ThemeProvider theme={store_generated_theme}>
              <AppBar
                isPreview={false}
                storeInfo={storeInfo}
                color={true}
                logo={true}
                shadow={true}
                logo={true}
              />
              {content}
              <Footer store={storeInfo} />
            </ThemeProvider>
          </div>
        );
      case "Leftist":
        return (
          <>
            {mobileView ? (
              <div>
                {storeInfo && storeInfo.font.css_url ? (
                  <link href={storeInfo.font.css_url} rel="stylesheet"></link>
                ) : (
                  ""
                )}
                <ThemeProvider theme={store_generated_theme}>
                  <AppBar
                    isPreview={false}
                    storeInfo={storeInfo}
                    color={true}
                    logo={true}
                    shadow={true}
                    logo={true}
                  />
                  {content}
                  <Footer store={storeInfo} />
                </ThemeProvider>
              </div>
            ) : (
              <div className={leftistStyles.root}>
                <ThemeProvider theme={store_generated_theme}>
                  {storeInfo && storeInfo.font.css_url ? (
                    <link href={storeInfo.font.css_url} rel="stylesheet"></link>
                  ) : (
                    ""
                  )}
                  <SideBar isPreview={props.isPreview} storeInfo={storeInfo} />
                  <main className={leftistStyles.content}>
                    <div className={leftistStyles.appBar}>
                      <AppBar
                        isPreview={false}
                        storeInfo={storeInfo}
                        color={true}
                        logo={true}
                        shadow={true}
                        logo={true}
                      />
                    </div>
                    {content}
                    <Footer store={storeInfo} />
                  </main>
                </ThemeProvider>
              </div>
            )}
          </>
        );
    }
  };

  return <div> {set_layout()}</div>;
};

export default ViewProduct;
