import React from "react";
import { makeStyles, useTheme, MobileStepper, Button } from "@material-ui/core";
import ProductCard from "../ProductCard";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ProductCardFeatured from "../productCardFeatured";

const Stepper = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 400,
      flexGrow: 1
    },
    header: {
      display: "flex",
      alignItems: "center",
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default
    },
    img: {
      height: 255,
      maxWidth: 400,
      overflow: "hidden",
      display: "block",
      width: "100%"
    }
  }));
  const productList = [];

  for (let i in props.productList) {
    if (
      props.productList[i].status === "active" &&
      props.productList[i].featured
    ) {
      productList.push(props.productList[i]);
    }
  }

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = productList.length
    ? productList.length >= 4
      ? 4
      : productList.length
    : props.productList.length >= 4
    ? 4
    : props.productList.length;

  const handleNext = () =>
    props.isPreview
      ? null
      : setActiveStep(prevActiveStep => prevActiveStep + 1);

  const handleBack = () =>
    props.isPreview
      ? null
      : setActiveStep(prevActiveStep => prevActiveStep - 1);

  return (
    <div className={classes.root}>
      <ProductCardFeatured
        onSelect={value => props.onSelect(value)}
        isPreview={props.isPreview}
        item={{
          name: productList.length
            ? productList[activeStep]
              ? productList[activeStep].name
              : ""
            : props.productList[activeStep]
            ? props.productList[activeStep].name
            : "",
          price: productList.length
            ? productList[activeStep]
              ? productList[activeStep].price
              : ""
            : props.productList[activeStep]
            ? props.productList[activeStep].price
            : "",
          discounted_price: productList.length
            ? productList[activeStep]
              ? productList[activeStep].discounted_price
              : ""
            : props.productList[activeStep]
            ? props.productList[activeStep].discounted_price
            : "",
          images: productList.length
            ? productList[activeStep]
              ? productList[activeStep].images
              : ""
            : props.productList[activeStep]
            ? props.productList[activeStep].images
            : "",
          gallery: productList.length
            ? productList[activeStep]
              ? productList[activeStep].gallery
              : ""
            : props.productList[activeStep]
            ? props.productList[activeStep].gallery
            : "",
          uuid: productList.length
            ? productList[activeStep]
              ? productList[activeStep].uuid
              : ""
            : props.productList[activeStep]
            ? props.productList[activeStep].uuid
            : "",
          photo_primary: productList.length
            ? productList[activeStep]
              ? productList[activeStep].photo_primary
              : ""
            : props.productList[activeStep]
            ? props.productList[activeStep].photo_primary
            : ""
        }}
      />
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            style={{
              color: activeStep === maxSteps - 1 ? "#ccc" : "#2B2D33"
            }}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <span
              style={{
                color: activeStep === maxSteps - 1 ? "#ccc" : "#2B2D33"
              }}
            >
              Next
            </span>
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            style={{ color: activeStep === 0 ? "#ccc" : "#2B2D33" }}
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            <span style={{ color: activeStep === 0 ? "#ccc" : "#2B2D33" }}>
              Back
            </span>
          </Button>
        }
      />
    </div>
  );
};
export default Stepper;
