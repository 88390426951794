import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "redux-form";
import {
  InputAdornment,
  OutlinedInput,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
  InputLabel
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as SortAsc } from "../../../../../assets/images/enterprise/sortAsc.svg";
import { ReactComponent as SortDesc } from "../../../../../assets/images/enterprise/sortDesc.svg";
import {
  setProductFilters,
  bulkActions,
  setProductSelected
} from "../../../websiteBuilderActions";
import ChangeCategory from "./changeCategoryModal";
import { showAlert } from "../../../../common/commonActions";
export default function ProductFilters(props) {
  const useStyles = makeStyles(theme => ({
    input: {
      backgroundColor: "#fff"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const product_categories = useSelector(
    state => state.webBuilder.product_categories?.categories
  );
  const store = useSelector(state => state.webBuilder.storeInfo);
  const filters = useSelector(state => state.webBuilder.products.filters);
  const selected = useSelector(
    state => state.webBuilder.products.bulk_actions.selected
  );
  const loading = useSelector(state => state.webBuilder.products.loading);
  const [search, setSearch] = React.useState("");
  const [timeoutId, setTimeoutId] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [categoryFilters, setCategoryFilters] = React.useState([]);
  const [action, setAction] = React.useState({
    open: false,
    value: "actions"
  });
  const onSearch = e => {
    const val = e.target.value;
    setSearch(val);
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        dispatch(setProductFilters({ ...filters, search: val, page: 0 }));
      }, 1000)
    );
  };

  const onClear = () => {
    setSearch("");
    dispatch(setProductFilters({ ...filters, search: "", page: 0 }));
  };

  const handleChange = (field, value) => {
    dispatch(setProductFilters({ [field]: value, page: 0 }));
  };

  const onChange = (field, val) => {
    setCategoryFilters(val);
  };

  const handleActions = value => {
    if (!selected.length) {
      dispatch(showAlert({ type: "warning", message: "Select a product" }));
    } else {
      setAction({
        value: value,
        open: value === "category" ? true : false
      });
      if (value !== "category") {
        dispatch(bulkActions(selected, value, store.uuid, filters));
        dispatch(setProductSelected([]));
      }
    }
  };

  const handleChangeCategory = category => {
    setAction({
      ...action,
      open: false
    });
    dispatch(
      bulkActions(selected, action.value, store.uuid, filters, category)
    );
    dispatch(setProductSelected([]));
  };
  React.useEffect(() => {
    dispatch(
      setProductFilters({ ...filters, category: categoryFilters, page: 0 })
    );
  }, [categoryFilters]);

  React.useEffect(() => {
    setCategories(product_categories);
  }, [product_categories]);

  const searchBar = (
    <OutlinedInput
      id="search_bar"
      className={`${classes.input} mt-1`}
      fullWidth
      color="secondary"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon style={{ color: "#909196" }} />
        </InputAdornment>
      }
      endAdornment={
        loading ? (
          <InputAdornment position="end">
            <div
              className="spinner-border mx-auto"
              role="status"
              style={{
                color: "18B4A1",
                fontSize: 1,
                height: 15,
                width: 15
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </InputAdornment>
        ) : search ? (
          <InputAdornment position="end">
            <IconButton
              style={{ marginRight: "-0.5em" }}
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => onClear()}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          ""
        )
      }
      value={search}
      margin="dense"
      placeholder="Search all products"
      onChange={e => onSearch(e)}
    />
  );

  const categoryFilter = (
    <FormControl
      variant="outlined"
      color="secondary"
      margin="dense"
      style={{ width: 160 }}
    >
      <InputLabel id="demo-simple-select-filled-label">Category</InputLabel>
      <Select
        id="category_field"
        multiple
        value={categoryFilters}
        onChange={e => onChange("category", e.target.value)}
        renderValue={selected => selected.join(", ")}
        className="bg-white"
        label="Category"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        {product_categories.map((item, index) => {
          return (
            <MenuItem value={item}>
              <Checkbox
                checked={categoryFilters.indexOf(item) > -1}
                color="primary"
              />
              <ListItemText
                primary={item === "" ? "No Category (Others)" : item}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const actionFilter = (
    <FormControl variant="outlined" color="secondary" margin="dense">
      <Select
        id="actions_field"
        className="bg-white"
        value={"Actions"}
        onChange={e => handleActions(e.target.value)}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        disabled={filters.status === "deleted"}
      >
        <MenuItem value="Actions">Actions</MenuItem>
        <MenuItem value="category">Change category</MenuItem>
        {filters.status !== "active" ? (
          <MenuItem value="active">Move to active</MenuItem>
        ) : null}
        {filters.status !== "draft" ? (
          <MenuItem value="draft">Move to drafts</MenuItem>
        ) : null}
        <MenuItem value="deleted">Send to archives</MenuItem>
      </Select>
    </FormControl>
  );

  const sortByFilter = (
    <FormControl
      variant="outlined"
      color="secondary"
      margin="dense"
      style={{ width: 160 }}
    >
      <Select
        id="sortBy"
        className="bg-white"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        value={filters.sortBy}
        onChange={e => handleChange("sortBy", e.target.value)}
      >
        {/* <MenuItem value="Sort by">Sort by</MenuItem> */}
        <MenuItem value="name">Name</MenuItem>
        <MenuItem value="created_on">Date added</MenuItem>
        <MenuItem value="price">Price</MenuItem>
        <MenuItem value="quantity">Stock availability</MenuItem>
      </Select>
    </FormControl>
  );

  const sortFilter = (
    <div className="d-flex">
      <Tooltip title="Descending" placement="top">
        <IconButton
          className="mr-2"
          style={{ height: 50, width: 50 }}
          onClick={() => handleChange("sort", "desc")}
        >
          <SortDesc
            id="sort_desc"
            fill={filters.sort === "desc" ? "#f97663" : "#909196"}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Ascending" placement="top">
        <IconButton
          style={{ height: 50, width: 50 }}
          onClick={() => handleChange("sort", "asc")}
        >
          <SortAsc
            id="sort_asc"
            fill={filters.sort === "asc" ? "#f97663" : "#909196"}
          />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div className="mr-2">{searchBar}</div>
        <div className="mr-2">{categoryFilter}</div>
        <div className="mr-2">{actionFilter}</div>
        <div>{sortByFilter}</div>
      </div>
      <div>{sortFilter}</div>
      <ChangeCategory
        action={action}
        setAction={setAction}
        product_categories={product_categories}
        handleChangeCategory={handleChangeCategory}
      />
    </div>
  );
}
