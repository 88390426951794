import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Switch from '@material-ui/core/Switch';
import Badge from '@material-ui/core/Badge';

import TextField from '@material-ui/core/TextField';

const useStyles = theme => ({
  field: {
    fontSize: 12,
    margin: "1.5em 0 0",
    maxWidth: '50%',
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
    },
  },
  divider: {
    margin: "1em 0"
  },
  addVariationDiv: {
    display: "flex",
    minHeight: 45,
    border: "1px dashed #f97663",
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 0.2s ease",
    cursor: "pointer",
    "&:hover": {
      background: '#f976631a'
    },
    "& > p": {
      marginBottom: 0,
      marginLeft: 5,
      color: "#f97663",
      fontWeight: "bold"
    },
    "& > svg": {
      color: "#f97663",
    }
  },
  variationsDiv: {
    marginBottom: "1em",
    textAlign: "center",
    "& p.noVariations": {
      fontSize: 14,
      marginBottom: 0,
      padding: "1em",
      fontWeight: 'bold',
      color: 'gray'
    }
  },
  variationsTable: {
    width: '100%',
    "& th": {
      fontSize: 16,
      fontWeight: 300,
      padding: '0.75em 1em',
      background: '#f7f7f7',
      border: "1px solid #ebebeb",
      width: "28%"
    },
    "& th:last-child": {
      padding: '0.5em 1em',
      background: 'none',
      border: "none",
    },
    "& td": {
      fontSize: 15,
      fontWeight: 300,
      background: 'white',
      padding: '1em 1em',
      border: "1px solid #ebebeb",
      width: "28%"
    },
    "& td:last-child": {
      padding: '0.5em 1em',
      background: 'none',
      border: "none",
    },
  },
  modalDiv: {
    flexDirection: "column",
    padding: "2.5em",
    // minWidth: 500,
    [theme.breakpoints.down("xs")]: {
      // minWidth: "auto",
    },
  },
  modalTile: {
    fontSize: 26,
    color: "#2B2D33",
    fontWeight: 600,
    // marginBottom: 25,
    lineHeight: "1em"
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    color: "#54575F",
    marginBottom: 25
  },
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
  },
  modalActions: {
    minWidth: 100,
    padding: "0 1.5em",
    height: 35,
    fontSize: 14,
    fontWeight: 600
  },
  actionIcons: {
    fontSize: 20,
    margin: 3,
    color: 'gray',
    cursor: 'pointer'
  }
});

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 36,
      height: 22,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 18,
      height: 18,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 11,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);  

class Variations extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dialogStat: false,
      dialogType: "add",
      // variations
      targetIndex: 0,
      variation: {
        name: '',
        price: 0,
        has_free_trial: false,
        timeframe: `monthly`,
        description: '',
        features: '',
        requirements: ''
      }
    }
		this.setDialogState = this.setDialogState.bind(this)
		this.textFieldOnChange = this.textFieldOnChange.bind(this)
		this.handleChangetimeframe = this.handleChangetimeframe.bind(this)
		this.addVariation = this.addVariation.bind(this)
		this.editVariation = this.editVariation.bind(this)
		this.deleteVariation = this.deleteVariation.bind(this)
		this.resetFields = this.resetFields.bind(this)
  }

  setDialogState(dialogStat, dialogType="add", targetIndex=0){
    this.setState({
      dialogStat,
      dialogType,
      targetIndex
    })
    if (dialogType === "edit"){
      this.setState({
        variation: this.props.variants[targetIndex]
      })
    }
  }

  textFieldOnChange(event, fieldname){
    this.setState({
      ...this.state,
      variation: {
        ...this.state.variation,
        [fieldname]: event.target.value
      }
    })
  }

  handleChangetimeframe = (event) => {
    this.setState({
      variation: {
        ...this.state.variation,
        timeframe: event.target.value
      } 
    });
  };

  addVariation(){
    const variants = this.props.variants;
    variants.push(this.state.variation);
    this.props.updateVariants(variants);
    this.resetFields();
  }

  editVariation(variationIndex){
    let variants = this.props.variants;
    variants[variationIndex] = this.state.variation;
    this.props.updateVariants(variants);
    this.resetFields();
  }

  deleteVariation(variationIndex){
    let variants = this.props.variants;
    variants.splice(variationIndex, 1);
    this.props.updateVariants(variants);
    this.resetFields();
  }

  resetFields(){
    this.setState({
      variation: {
        name: '',
        price: 0,
        timeframe: `monthly`,
        description: '',
        features: '',
        requirements: ''
      },
      dialogStat: false
    });
  }

  handleHasFreeTrial(event){
    this.setState({
      ...this.state,
      variation: {
        ...this.state.variation,
        has_free_trial: event.target.checked
      }
    })
  }

  render (){
    const { classes } = this.props;
    const { dialogStat, variation, dialogType, targetIndex } = this.state;
    const dialogAction = (index=0) => {
      switch(dialogType){
        case "add":
          return this.addVariation()
        case "edit": 
          return this.editVariation(index)
        case "delete":
          return this.deleteVariation(index)
        default:
          return this.addVariation()
      }
    }

    const dialogTitle = () => {
      switch(dialogType){
        case "add":
          return `Add Variation`
        case "edit": 
          return `Edit Variation`
        case "delete":
          return `Delete Variation`
        default:
          return `Add Variation`
      }
    }

    const dialogContent = () => {
      switch(dialogType){
        case "delete":
          return `Delete variation?`
        default:
          return (
            <div className={classes.modalBody}>
              <TextField
                className={classes.field}
                onChange={(event) => this.textFieldOnChange(event, "name")}
                label="Name"
                margin="dense"
                placeholder="Name"
                variant="outlined"
                value={variation.name}
              />
              <TextField
                className={classes.field}
                onChange={(event) => this.textFieldOnChange(event, "price")}
                label="Price"
                type="number"
                margin="dense"
                placeholder="Price"
                variant="outlined"
                value={variation.price}
              />
              
              <div style={{ display: "flex", alignItems: "center"}}>
                <p style={{ margin: "1em 1em 1em 0"}}>Has Free Trial?</p>
                <AntSwitch checked={this.state.variation.has_free_trial} onChange={(event) => this.handleHasFreeTrial(event)}name="has_free_trial" />
                <p style={{marginLeft: "1em", marginBottom: 0}}><strong>{
                  this.state.variation.has_free_trial ?
                  `Yes` : `No`
                }</strong></p>
              </div>
              <FormControl variant="outlined" className={classes.field} style={{ marginBottom: 5, marginTop: "0.7em"}}>
                <InputLabel id="demo-simple-select-outlined-label">Billing Timeframe</InputLabel>
                <Select
                  
                  margin="dense"
                  value={variation.timeframe}
                  onChange={this.handleChangetimeframe}
                  label="Billing Timeframe"
                >
                  <MenuItem value={`one_time`}>One Time</MenuItem>
                  <MenuItem value={`daily`}>Daily</MenuItem>
                  <MenuItem value={`monthly`}>Monthly</MenuItem>
                  <MenuItem value={`yearly`}>Yearly</MenuItem>
                  <MenuItem value={`3_months`}>3 Months</MenuItem>
                  <MenuItem value={`6_months`}>6 Months</MenuItem>
                  <MenuItem value={`9_months`}>9 Months</MenuItem>
                  <MenuItem value={`12_months`}>12 Months</MenuItem>
                </Select>
              </FormControl>
              {/* <Divider className={classes.divider} /> */}
              <TextField
                onChange={(event) => this.textFieldOnChange(event, "description")}
                label="Description"
                margin="dense"
                placeholder="Description"
                variant="outlined"
                multiline
                rows={5}
                value={variation.description}
              />
              <TextField
                onChange={(event) => this.textFieldOnChange(event, "features")}
                label="Features"
                margin="dense"
                placeholder="Features"
                variant="outlined"
                multiline
                rows={5}
                value={variation.features}
              />
              <TextField
                onChange={(event) => this.textFieldOnChange(event, "requirements")}
                label="Requirements"
                margin="dense"
                placeholder="Requirements"
                variant="outlined"
                multiline
                rows={5}
                value={variation.requirements}
              />
            </div>
          )
      }
    }

    return (
      <>
        <Dialog
          open={dialogStat}
          onClose={(event) => this.setDialogState(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth={true}
        >	
          <div className={classes.modalDiv}>
            <div className={classes.modalTile}>
              {dialogTitle()}
            </div>
            {/* <div className={classes.modalBody}> */}
              {dialogContent()}
            {/* </div> */}
            <div className={classes.modalActionsWrapper}>
              <Button
                id="action_button"
                variant="outlined"
                onClick={(event) => {
                  this.setDialogState(false)
                  this.resetFields()
                }} 
                color="primary"
                margin="dense"
                className={classes.modalActions}
                style={{ marginLeft: "auto" }}
              >
                Cancel
              </Button>
              <Button
                id="action_button"
                variant="contained"
                onClick={(event) => {
                  console.log(dialogAction)
                  dialogAction(targetIndex)
                }}
                color="primary"
                margin="dense"
                className={classes.modalActions}
                style={{ marginLeft: "1em" }}
              >
                Save
              </Button>
            </div>
          </div>
        </Dialog>
        <div className={classes.variationsDiv}>
          {
            this.props.variants ?
              this.props.variants.length > 0 ? 
              <table className={classes.variationsTable}>
                <thead>
                  <tr align="center">
                    <th>Name</th>
                    <th>Billing Period</th>
                    <th>Price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.props.variants.map((variation, index) => (
                      <tr align="center" key={index}>
                        <td>{variation.has_free_trial ? <Badge badgeContent="Free Trial" color="primary"
                        style={{ minWidth: 70}}>
                          {variation.name}</Badge>: variation.name}
                        </td>
                        <td>{
                          variation.timeframe.split("_").map((text, index) => (
                          <span key={index} style={{ textTransform: "capitalize" }}>{`${text} `}</span>
                          ))  
                        }</td>
                        <td>{variation.price}</td>
                        <td>
                          <EditOutlinedIcon className={classes.actionIcons} 
                          onClick={() => {
                            this.setDialogState(true, "edit", index)
                          }}/> 
                          <DeleteOutlineOutlinedIcon className={classes.actionIcons} 
                          onClick={() => {
                            this.setDialogState(true, "delete", index)
                          }}/>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              :
              <p className="noVariations">No variations</p>
            :
            <p className="noVariations">No variations</p>
          }
        </div>
        <div className={classes.addVariationDiv} onClick={() => {
          this.setDialogState(true)
        }}>
          <AddCircleOutlineIcon /><p>Add Variation</p>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ marketplace }) => ({ 
  // apps: marketplace.apps
});
export default connect(mapStateToProps)(withStyles(useStyles)(Variations));

