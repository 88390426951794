import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, TextField, Typography, InputAdornment } from "@material-ui/core";
import SentroButton from "../../common/sentroButton";
import { useSelector } from "react-redux";
import Text from "../../../common/text";
const Content = props => {
  const useStyles = makeStyles(theme => ({
    paper: {
      padding: "26px 26px 45px 26px",
      width: 600,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 5,
      border: "1px solid #D1D5DD",
      backgroundColor: "#fff",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: 15
      }
    },
    paper2: {
      padding: 26,
      width: 430,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 5,
      border: "1px solid #D1D5DD",
      backgroundColor: "#fff",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: 15
      }
    },
    profileContent: {
      fontSize: 16,
      color: "#2B2D33",
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    desktop: {
      marginTop: "3vh",
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    mobile: {
      display: "none",
      marginTop: "3vh",
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    }
  }));

  function setProfileChanges(value, name) {
    props.setProfile(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const [errors, setErrors] = React.useState({
    firstnameError: "",
    lastnameError: "",
    mobilenumberError: ""
  });

  const [hasErrors, setHasErrors] = React.useState(true);
  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        setHasErrors(true);
        return;
      }
    }
    setHasErrors(false);
  }, [errors]);

  const profile = props.profile;
  const [isEnough, setIsEnough] = React.useState(false);
  React.useEffect(() => {
    setIsEnough(
      profile.first_name && profile.last_name && profile.mobile_number
    );
  }, [profile]);

  const FIELD_MAX_LENGTHS = {
    first_name: 99,
    last_name: 99,
    mobile_number: 11
  };

  let trimInput = (raw_input, maxLength) => {
    return maxLength > 0 && raw_input.length > maxLength
      ? raw_input.slice(0, maxLength)
      : raw_input;
  };

  let handleTextInput = params => {
    const { raw_input, fieldname } = params;
    const maxLength = FIELD_MAX_LENGTHS[fieldname] || 0;
    let input = trimInput(raw_input, maxLength);

    setProfileChanges(input, fieldname);
  };

  let charactersLeftFor = fieldname => {
    const fieldLength = profile[fieldname] ? profile[fieldname].length : 0;
    const charsRemaining = FIELD_MAX_LENGTHS[fieldname] - fieldLength;
    return `${charsRemaining}/11`;
  };

  const classes = useStyles();
  const data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let user = useSelector(state => state.user.profile);
  let first_name = user ? user.first_name : "";
  let last_name = user ? user.last_name : "";
  let email = data ? data.email : "";
  let mobile_number = user ? user.mobile_number : "";
  let displayName = first_name + " " + last_name;
  sessionStorage.setItem("IN_SESSION", "CURRENT_USER");
  return (
    <div>
      <div style={{ display: props.showProfile ? "block" : "none" }}>
        <div className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: 20, fontWeight: 600, color: "#002C42" }}>
              Basic Information
            </div>
            <div>
              <SentroButton
                style={{ marginTop: 0, width: 100, height: 40 }}
                label="Edit"
                variant="outlined"
                onClick={() => props.onSelect(user)}
              />
            </div>
          </div>

          <div className={classes.desktop}>
            {data.is_facebookLogin ? (
              <div style={{ marginBottom: 20, color: "#909196" }}>
                You are logged in via your Facebook account.
              </div>
            ) : (
              ""
            )}
            <Grid container spacing={0} className={classes.profileContent}>
              <Grid item xs={4}>
                Name:
              </Grid>
              <Grid
                item
                xs={8}
                style={{ fontWeight: 600, textTransform: "capitalize" }}
              >
                {displayName}
              </Grid>

              <Grid item xs={4} style={{ marginTop: 16 }}>
                Mobile number:
              </Grid>
              <Grid item xs={8} style={{ fontWeight: 600, marginTop: 16 }}>
                {mobile_number.charAt(0) != 0
                  ? "+63" + mobile_number
                  : "+63" + mobile_number.substr(1)}
              </Grid>

              <Grid item xs={4} style={{ marginTop: 16 }}>
                Email:
              </Grid>
              <Grid item xs={8} style={{ fontWeight: 600, marginTop: 16 }}>
                {email}
              </Grid>
            </Grid>
          </div>

          <div className={classes.mobile}>
            <Grid container spacing={0} className={classes.profileContent}>
              <Grid item xs={12}>
                Name:
              </Grid>
              <Grid
                item
                xs={12}
                style={{ fontWeight: 600, textTransform: "capitalize" }}
              >
                {displayName}
              </Grid>

              <Grid item xs={12} style={{ marginTop: 16 }}>
                Mobile number:
              </Grid>
              <Grid item xs={12} style={{ fontWeight: 600 }}>
                {mobile_number.charAt(0) != 0
                  ? "+63" + mobile_number
                  : "+63" + mobile_number.substr(1)}
              </Grid>

              <Grid item xs={12} style={{ marginTop: 16 }}>
                Email:
              </Grid>
              <Grid item xs={12} style={{ fontWeight: 600 }}>
                {email}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <div style={{ display: props.edit === true ? "block" : "none" }}>
        <div className={classes.paper2}>
          <div style={{ fontSize: 20, fontWeight: 600, color: "#002C42" }}>
            Basic Information
          </div>

          <div style={{ display: "block", marginTop: 20 }}>
            <div style={{ width: "100%" }}>
              <Text
                style={{ fontSize: 12, color: "#212B36" }}
                content="First name"
              />
              <TextField
                id="first_name"
                variant="outlined"
                fullWidth
                value={profile.first_name}
                onChange={value => {
                  const raw_input = value.target.value;
                  handleTextInput({
                    raw_input,
                    fieldname: "first_name"
                  });

                  if (raw_input === "") {
                    setErrors({
                      ...errors,
                      firstnameError: "This field is required."
                    });
                  } else {
                    setErrors({
                      ...errors,
                      firstnameError: ""
                    });
                  }
                }}
                onBlur={value => {
                  if (value.target.value === "") {
                    setErrors({
                      ...errors,
                      firstnameError: "This field is required."
                    });
                  } else {
                    setErrors({
                      ...errors,
                      firstnameError: ""
                    });
                  }
                }}
                error={errors.firstnameError}
                helperText={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div>{errors.firstnameError}</div>
                  </div>
                }
              />
            </div>
          </div>

          <div style={{ display: "block" }}>
            <div style={{ width: "100%" }}>
              <Text
                style={{ fontSize: 12, color: "#212B36" }}
                content="Last name"
              />
              <TextField
                id="last_name"
                variant="outlined"
                fullWidth
                value={profile.last_name}
                onChange={value => {
                  const raw_input = value.target.value;
                  handleTextInput({
                    raw_input,
                    fieldname: "last_name"
                  });

                  if (raw_input === "") {
                    setErrors({
                      ...errors,
                      lastnameError: "This field is required."
                    });
                  } else {
                    setErrors({
                      ...errors,
                      lastnameError: ""
                    });
                  }
                }}
                onBlur={value => {
                  if (value.target.value === "") {
                    setErrors({
                      ...errors,
                      lastnameError: "This field is required."
                    });
                  } else {
                    setErrors({
                      ...errors,
                      lastnameError: ""
                    });
                  }
                }}
                error={errors.lastnameError}
                helperText={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div>{errors.lastnameError}</div>
                  </div>
                }
              />
            </div>
          </div>

          <div style={{ display: "block" }}>
            <div style={{ width: "100%" }}>
              <Text
                style={{ fontSize: 12, color: "#212B36" }}
                content="Mobile number"
              />
              <TextField
                id="mobile_number"
                variant="outlined"
                fullWidth
                value={profile.mobile_number}
                onChange={value => {
                  const raw_input = value.target.value.replace(/[^0-9]/g, "");

                  handleTextInput({
                    raw_input,
                    fieldname: "mobile_number"
                  });

                  if (raw_input === "") {
                    setErrors({
                      ...errors,
                      mobilenumberError: "This field is required."
                    });
                  } else {
                    setErrors({
                      ...errors,
                      mobilenumberError: ""
                    });
                  }
                }}
                onBlur={value => {
                  if (value.target.value === "") {
                    setErrors({
                      ...errors,
                      mobilenumberError: "This field is required."
                    });
                  } else if (value.target.value.length !== 11) {
                    setErrors({
                      ...errors,
                      mobilenumberError:
                        "Please enter a valid 11-digit mobile number."
                    });
                  } else if (value.target.value.charAt(0) != 0) {
                    setErrors({
                      ...errors,
                      mobilenumberError:
                        "You have entered an invalid mobile number"
                    });
                  } else if (value.target.value.charAt(1) != 9) {
                    setErrors({
                      ...errors,
                      mobilenumberError:
                        "You have entered an invalid mobile number"
                    });
                  } else {
                    setErrors({
                      ...errors,
                      mobilenumberError: ""
                    });
                  }
                }}
                error={errors.mobilenumberError}
                helperText={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div>{errors.mobilenumberError}</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                      {charactersLeftFor("mobile_number")}
                    </div>
                  </div>
                }
              />
            </div>
          </div>

          <div style={{ display: "block" }}>
            <div style={{ width: "100%" }}>
              <Text
                style={{ fontSize: 12, color: "#212B36" }}
                content="Email"
              />
              <TextField
                id="email"
                value={email}
                disabled
                variant="outlined"
                fullWidth
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 30,
              justifyContent: "flex-end",
              display: "flex"
            }}
          >
            <SentroButton
              id="cancel_button"
              onClick={() => props.handleProfile()}
              label="Cancel"
              style={{ width: 100, marginRight: 10 }}
            />
            <SentroButton
              id="save_button"
              disabled={hasErrors || !isEnough}
              label="Save"
              onClick={props.onSaveProfile}
              variant="contained"
              style={{ width: 100 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
