import React, { memo } from "react";
import history from "../../../store/history";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ACL from "../../common/utilities/ACL";
import Button from '@material-ui/core/Button';
import AdminDashboardLayout from "../layout";
import AppCard from "../../marketplace/components/appCard";
import Actions from "../Redux/Actions";
import Dialog from '@material-ui/core/Dialog';


const useStyles = theme => ({
  root: {
    padding: "0 2em",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2em",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 1em"
    },
    paddingBottom: "1em",
    backgroundColor: '#f1f1f1'
  },
  pageTitle: {
    fontSize: 26,
    fontWeight: 600,
    margin: "0.5em 0",
    color: "#002C42",
    flexGrow: 1,
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center"
  },
  createNewButton: {
    padding: "0 1.5em",
    height: 35,
    fontSize: 14,
    fontWeight: 600
  },
	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
});

class AdminAppMarketplace extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogStat: false,
      targetIndex: 0,
    }
    this.setDialogState = this.setDialogState.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  componentDidMount(){
    const { dispatch } = this.props;
    dispatch(Actions.fetchApps({
      extra_params: {
        is_admin_view: true
      }
    }));
    localStorage.setItem("adminMenuActive", 'Apps & Services');
  }

  setDialogState(dialogStat, targetIndex=this.state.targetIndex){
    this.setState({
      dialogStat,
      targetIndex
    })
  }

  onDelete(id){
    const { dispatch } = this.props;
    dispatch(Actions.deleteApp(id));
    this.setDialogState(false)
  }

  render (){
    const { classes, apps } = this.props;
    return (
      <AdminDashboardLayout filterHeaderHidden={true}>
        <ACL roles={["superuser"]} redirect_to="/admin/dashboard">
          <Dialog
            open={this.state.dialogStat}
            onClose={(event) => this.setDialogState(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth={true}
          >	
            <div className={classes.modalDiv}>
              <div className={classes.modalTile}>
                Delete App
              </div>
              {/* <div className={classes.modalBody}> */}
                {/* {dialogContent()} */}
              {/* </div> */}
              <div className={classes.modalActionsWrapper}>
                <Button
                  id="action_button"
                  variant="outlined"
                  onClick={(event) => {
                    this.setDialogState(false)
                  }} 
                  color="primary"
                  margin="dense"
                  className={classes.modalActions}
                  style={{ marginLeft: "auto" }}
                >
                  Cancel
                </Button>
                <Button
                  id="action_button"
                  variant="contained"
                  onClick={(event) => {
                    this.onDelete(this.state.targetIndex)
                  }}
                  color="primary"
                  margin="dense"
                  className={classes.modalActions}
                  style={{ marginLeft: "1em" }}
                >
                  Delete
                </Button>
              </div>
            </div>
          </Dialog>
          <div className={classes.root}>
            <div className={classes.flex}>
                <h2 className={classes.pageTitle}>App Marketplace</h2>
                <Button
                id="view"
                color="primary"
                disableElevation 
                className={classes.createNewButton}
                variant="contained"
                disableElevation={true}
                onClick={() => {
                    history.push(`/admin/marketplace/create`);
                }}
                >
                Create
                </Button>
            </div>
            {
                apps.map((app) => (
                <AppCard key={app.id} data={app} editButtonVisible={true} onClickDelete={() => 
                  this.setDialogState(true, app.id)}/>
                ))
            }
          </div>
        </ACL>
      </AdminDashboardLayout>
    )
  }
}

const mapStateToProps = state => ({ adminDashboard }) => ({ 
  apps: adminDashboard.apps
});
export default connect(mapStateToProps)(withStyles(useStyles)(AdminAppMarketplace));

