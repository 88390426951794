import React from "react";
import { makeStyles } from "@material-ui/styles";
import Layout from "../layout";
import Content from "./content";
import { saveProfile, getProfile, closeAlert } from "../AccountSettingsAction";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
const BasicInformation = props => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getProfile());
  }, []);

  const useStyles = makeStyles(theme => ({}));
  const classes = useStyles();
  const [edit, setEdit] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(true);
  const [profile, setProfile] = React.useState({
    first_name: "",
    last_name: "",
    mobile_number: ""
  });

  function handleProfile() {
    setEdit(false);
    setShowProfile(true);
  }
  function onSaveProfile() {
    dispatch(saveProfile(profile));
    setShowProfile(true);
    setEdit(false);
  }
  function onSelect(value) {
    setProfile(value);
    setEdit(true);
    setShowProfile(false);
  }

  let alert = useSelector(state => state.user.showAlert);
  let message = useSelector(state => state.user.message);
  let type = useSelector(state => state.user.type);

  if (alert) {
    setTimeout(() => {
      dispatch(closeAlert());
    }, 3000);
  }
  return (
    <Layout>
      <div style={{ display: "block" }}>
        {alert ? (
          <div style={{ marginBottom: 15 }}>
            <Alert id="alert" type={type} message={message} />
          </div>
        ) : (
          ""
        )}
        <Content
          profile={profile}
          setProfile={setProfile}
          handleProfile={handleProfile}
          showProfile={showProfile}
          edit={edit}
          onSelect={value => onSelect(value)}
          onSaveProfile={onSaveProfile}
        />
      </div>
    </Layout>
  );
};

export default BasicInformation;
