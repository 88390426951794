import React from "react";
import ViewProduct from "./components/ViewProduct";

import "./App.css";

const ViewProductPage = (props) => {
  let params = props.match ? props.match.params : "";

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <ViewProduct id={params.id} />;
};

export default ViewProductPage;
