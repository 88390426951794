import { WishlistActionTypes, ReduxInitializeFormActionTypes } from "../actions/wishlistActions";
import _ from 'lodash';

const initialState = {
  wishlists: [],
  loading: false,
  delete_item_loading: false,
  add_item_loading: false,
  buyer_dashboard: {
    addedProducts: [],
    wishlist: "",
    items: [],
    count: 0
  },
  marketplace: {
    loading: false,
    wishlist: "",
    items: [],
    count: 0
  },
  public_wishlists: {
    loading: false,
    wishlists: [],
    count: "",
    filters: {
      celebrants: "",
      itemsPerPage: 10,
      page: 1
    }
  },
  reduxFormInitialValues: {
    create_edit_wishlist_form: ""
  },
};

export default function wishlistReducers(state = initialState, action) {
  let items = [];
  let addedProducts = [];
  switch (action.type) {
    case WishlistActionTypes.SET_WISHLIST_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case WishlistActionTypes.CREATE_WISHLISH:
      return {
        ...state,
        wishlists: [action.payload, ...state.wishlists],
        buyer_dashboard: {
          ...state.buyer_dashboard,
          wishlist: action.payload
        },
        marketplace: {
          ...state.marketplace,
          wishlist: action.payload
        },
      };
    case WishlistActionTypes.GET_PUBLIC_WISHLISTS:
      return {
        ...state,
        public_wishlists: {
          ...state.public_wishlists,
          loading: true
        }
      };
    case WishlistActionTypes.GET_PUBLIC_WISHLISTS_SUCCESS:
      return {
        ...state,
        public_wishlists: {
          ...state.public_wishlists,
          loading: false,
          wishlists: action.payload.results,
          count: action.payload.count
        }
      };
    case WishlistActionTypes.GET_PUBLIC_WISHLISTS_FAILED:
      return {
        ...state,
        public_wishlists: {
          ...state.public_wishlists,
          loading: false
        }
      };
    case WishlistActionTypes.SET_WISHLIST_FILTER:
      return {
        ...state,
        public_wishlists: {
          ...state.public_wishlists,
          filters: action.payload
        }
      };
    case WishlistActionTypes.GET_USER_WISHLIST:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          wishlist: action.payload
        }
      };
    case WishlistActionTypes.PATCH_WISHLIST:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          wishlist: action.payload
        },
        marketplace: {
          ...state.marketplace,
          wishlist: action.payload
        },
      };
    case WishlistActionTypes.SET_GET_USER_WISHLIST_ITEMS_LOADING:
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          loading: action.payload
        }
      };
    case WishlistActionTypes.GET_USER_WISHLIST_ITEMS:
      items = action.method === "append" ? 
        [...state.buyer_dashboard.items, ...action.payload.results]:
        action.payload.results;
      items = _.uniqBy(items, 'id');
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          items,
          count: action.payload.count
        }
      };
    case WishlistActionTypes.SET_DELETE_USER_WISHLIST_ITEM_LOADING:
      return {
        ...state,
        delete_item_loading: action.payload,
      };
    case WishlistActionTypes.DELETE_WISHLIST_ITEM_SUCCESS:
      items = state[action.payload.from].items;
      const new_items = items.filter(item => 
        item.id !== action.payload.registry_item_id
      );
      return {
        ...state,
        delete_item_loading: false,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          items: [...new_items],
          addedProducts: [
            ...state.buyer_dashboard.addedProducts.filter((i) => i !== action.payload.product_uuid)
          ]
        },
        marketplace: {
          ...state.marketplace,
          items: [...new_items],
        }
      };
    case WishlistActionTypes.SET_ADD_WISHLIST_ITEM_LOADING: 
      return {
        ...state,
        add_item_loading: action.payload
      };

    case WishlistActionTypes.GET_USER_WISHLIST_ADDED_PRODUCTS:
      addedProducts = action.payload.map((item, index) => item.product.uuid)
      return {
        ...state,
        buyer_dashboard: {
          ...state.buyer_dashboard,
          addedProducts: [...addedProducts]
        },
      };

    // for marketplace wishlist; wishlist detailed view
    case WishlistActionTypes.GET_WISHLIST:
      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          wishlist: action.payload
        }
      };
    case WishlistActionTypes.SET_GET_WISHLIST_ITEMS_LOADING:
      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          loading: action.payload
        }
      };
    case WishlistActionTypes.GET_WISHLIST_ITEMS:
      const list = action.payload.results || action.payload;
      items = action.method === "append" ? 
        [...state.marketplace.items, ...list]:
        list;
      items = _.uniqBy(items, 'id');
      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          items,
          count: action.payload.count
        }
      };

    case "USER_LOGOUT":
      return {
        ...state,
        buyer_dashboard: {
          addedProducts: [],
          wishlist: "",
          items: [],
          count: 0
        },
        marketplace: {
          loading: false,
          wishlist: "",
          items: [],
          count: 0
        },
      };
    
    // load wishlist data
    case ReduxInitializeFormActionTypes.LOAD_WISHLIST_DATA:
      return {
        ...state,
        reduxFormInitialValues: {
          ...state.reduxFormInitialValues,
          create_edit_wishlist_form: action.payload
        }
      }
    default:
      return state;
  }
}
