import React from "react";
import { Dialog, Grid, Typography, Chip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import StoreEditProductDialog from "./StoreEditProductDialog";
import SentroButton from "../../common/sentroButton";
import Utils from "../../common/utils";
import { makeStyles } from "@material-ui/styles";
import imgPlaceholder from "../../../assets/images/product_placeholder.png";
let ProductCard = props => {
  const { handleEditProduct, handleDeleteProduct, item } = props;
  const useStyles = makeStyles(theme => ({
    btnDelete: {
      margin: 5,
      height: 35,
      width: 90,
      color: "#54575F",
      "&:hover": {
        border: "1px solid #54575F"
      }
    },
    btnDelete2: {
      margin: 5,
      height: 45,
      width: 90,
      "&:hover": {
        border: "1px solid #F97663"
      }
    },
    btnEdit: {
      margin: 5,
      height: 35,
      width: 90
    }
  }));
  const classes = useStyles();

  function productImage() {
    if (item.gallery) {
      if (item.gallery.length) {
        if (item.photo_primary === null || item.photo_primary === "") {
          return item.gallery[0].image;
        } else {
          if (
            item.gallery[item.photo_primary] === "undefined" ||
            item.gallery[item.photo_primary] === undefined
          ) {
            return item.gallery[0].image;
          } else {
            return item.gallery[item.photo_primary].image;
          }
        }
      } else {
        return imgPlaceholder;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return imgPlaceholder;
      }
    }
  }
  return (
    <div>
      <div
        style={{
          marginRight: 15,
          padding: 25,
          width: 490,
          borderRadius: 5,
          border: "1px solid #E6EAF0",
          boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.05)",
          marginBottom: 25,
          flexGrow: 1
        }}
      >
        <div style={{ display: "block" }}>
          <Grid container spacing={0} style={{ height: 135, display: "flex" }}>
            <Grid item xs={4}>
              <div
                style={{
                  width: 130,
                  height: 130,
                  backgroundSize: "cover",
                  backgroundImage: `url(${productImage()})`
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: "#2B2D33",
                  width: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap"
                }}
              >
                {item.name}
              </Typography>
              <div style={{ display: "flex" }}>
                <Grid container spacing={0}>
                  <Grid style={{ marginTop: "1vh" }} item xs={12}>
                    <Typography
                      style={{
                        fontSize: 18,
                        color: "#2B2D33",
                        width: "100%",
                        wordWrap: "break-word"
                      }}
                    >
                      PHP {Utils.commafy(item.price)}
                    </Typography>
                  </Grid>

                  <Grid
                    style={{
                      marginTop: 5,
                      display: "flex",
                      alignItems: "center"
                    }}
                    item
                    xs={12}
                  >
                    <Typography
                      style={{
                        fontSize: 16,
                        color: "#909196",
                        width: "100%",
                        wordWrap: "break-word"
                      }}
                    >
                      {item.always_available ? "Always available" : "Stocks: "}

                      {item.always_available
                        ? ""
                        : item.quantity === 0
                        ? "none"
                        : item.quantity + " pc(s)."}
                    </Typography>
                  </Grid>
                  <Grid style={{ marginTop: "1vh" }} item xs={6}>
                    <Typography
                      style={{
                        fontSize: 16,
                        color: "#909196",
                        width: "100%",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {item.category}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      marginTop: "1vh",
                      display: "flex",
                      justifyContent: "center"
                    }}
                    item
                    xs={6}
                  >
                    <Chip
                      size="small"
                      label={item.is_active ? "on display" : "hidden"}
                      style={{
                        color: "#fff",
                        backgroundColor: item.is_active ? "#18B4A1" : "#909096"
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1vh"
            }}
          >
            <SentroButton
              id="edit_button"
              className={classes.btnEdit}
              style={{ marginTop: 0, padding: 0 }}
              variant="outlined"
              label="Edit"
              onClick={() => handleEditProduct(item)}
            />
            <SentroButton
              id="delete_button"
              className={classes.btnDelete}
              style={{ marginTop: 0, padding: 0 }}
              label="Delete"
              onClick={() => handleDeleteProduct(item)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductCard;
