import React from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import buxLogo from "../../../../assets/images/buxLogo.svg";
import {
  Chip,
  IconButton,
  LinearProgress,
  Button,
  useMediaQuery,
  Tooltip
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import {
  HelpOutline,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@material-ui/icons";
import Utils from "../../../common/utils";
import BuxLimit from "./buxLimit";
import { RedirectDialog } from "./redirectToBux";
import { goToCompleteProfile } from "../../action";
export default function BuxWallet(props) {
  const useStyles = makeStyles(theme => ({
    btnText: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 11
      }
    },
    limitDiv: {
      maxHeight: 0,
      overflow: "hidden",
      transition: "all 0.5s ease-in-out"
    },
    extended: {
      maxHeight: "200px !important"
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const loading = useSelector(state => state.payments.loading);
  const kyc = useSelector(state => state.payments.buxStatus);
  const bux = useSelector(state => state.payments.buxParams);
  const [open, setOpen] = React.useState(false);
  const [openBux, setOpenBux] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const balance = bux ? bux.balance : "₱ 0.00";
  const cashInLimit = bux ? bux.cashin_limits : "";
  const cashOutLimit = bux ? bux.cashout_limits : "";
  const value_cashIn = cashInLimit ? cashInLimit[0] : 0;
  const value_cashOut = cashOutLimit ? cashOutLimit[0] : 0;
  const cashIn_maxValue = cashInLimit ? cashInLimit[1] : 0;
  const cashOut_maxValue = cashOutLimit ? cashOutLimit[1] : 0;
  const MIN = 0;
  const cashIn = value_cashIn =>
    ((value_cashIn - MIN) * 100) / (cashIn_maxValue - MIN);
  const cashOut = value_cashOut =>
    ((value_cashOut - MIN) * 100) / (cashOut_maxValue - MIN);

  const buxSegments = kyc => {
    if (cashIn_maxValue === 100000) {
      return "BUxME";
    } else if (cashIn_maxValue === 500000) {
      return "BUxGIG";
    } else if (cashIn_maxValue === 0) {
      return "BUxSME";
    } else {
      return "BUxME";
    }
  };

  const kycLevel = kyc => {
    switch (kyc) {
      case 0:
        return "Basic";
      case 1:
        return "Pro";
      case 2:
        return "Advanced";
      case 3:
        return "Business";
      case 4:
        return "Enterprise";
      default:
        return "Basic";
    }
  };

  const BorderLineProgress = withStyles(theme => ({
    root: {
      height: 8,
      borderRadius: 15
    },
    colorPrimary: {
      backgroundColor: "#d1d5dd"
    },
    bar: {
      borderRadius: 5,
      backgroundColor: value_cashIn === cashIn_maxValue ? "#f97663" : "#00bda2"
    }
  }))(LinearProgress);

  const BorderLineProgress2 = withStyles(theme => ({
    root: {
      height: 8,
      borderRadius: 15
    },
    colorPrimary: {
      backgroundColor: "#d1d5dd"
    },
    bar: {
      borderRadius: 5,
      backgroundColor:
        value_cashOut === cashOut_maxValue ? "#f97663" : "#00bda2"
    }
  }))(LinearProgress);

  return (
    <div className={`bg-white rounded`} style={{ border: "1px solid #d1d5dd" }}>
      <div
        style={{ backgroundColor: "#2a3d85" }}
        className="p-md-4 p-3 rounded-top d-flex"
      >
        <img src={buxLogo} alt="bux" width="50" height="50" />
        <div className="w-100 pl-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="text-white" style={{ fontSize: 14 }}>
              BUx wallet
            </div>
            <Chip
              size="small"
              label={buxSegments(kyc)}
              style={{ backgroundColor: "#e5f2fd", color: "#2a3d85" }}
            />
          </div>
          <div
            className="font-weight-light text-white pt-4"
            style={{ fontSize: 12 }}
          >
            Balance
          </div>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <strong style={{ fontSize: 28 }} className="text-white">
                {loading ? (
                  <div
                    className="spinner-border mx-auto"
                    role="status"
                    style={{
                      color: "white",
                      fontSize: 12,
                      height: 25,
                      width: 25
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  `₱ ${Utils.commafy(balance)}`
                )}
              </strong>
            </div>
            <div
              className="text-white font-weight-light d-flex align-items-center"
              style={{ fontSize: 14 }}
            >
              Go to BUx{" "}
              <LaunchIcon
                onClick={() => dispatch(goToCompleteProfile(user.id, kyc))}
                style={{ color: "white", fontSize: 23 }}
                className="pl-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-md-4 px-3 py-3">
        <div className={`${classes.limitDiv} ${show ? classes.extended : ""}`}>
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ fontSize: 14, fontWeight: 500, color: "#54575f" }}>
              Limits tracker
            </div>
            <Tooltip
              arrow
              title="Track your daily cash-in and cash-out limits"
              placement="top"
            >
              <IconButton onClick={() => setOpen(true)}>
                <HelpOutline color="secondary" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            <BuxLimit open={open} setOpen={setOpen} />
          </div>
          <div className="pt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ fontSize: 12, fontWeight: 500, color: "#54575f" }}>
                Cash in
              </div>
              <div style={{ fontSize: 12, fontWeight: 500, color: "#54575f" }}>
                ₱ {Utils.commafy(value_cashIn)} / ₱{" "}
                {Utils.commafy(cashIn_maxValue)}
              </div>
            </div>
            <div className="mt-2">
              <BorderLineProgress
                variant="determinate"
                value={cashIn(value_cashIn)}
                color="primary"
              />
            </div>
          </div>

          <div className="pt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ fontSize: 12, fontWeight: 500, color: "#54575f" }}>
                Cash out
              </div>
              <div style={{ fontSize: 12, fontWeight: 500, color: "#54575f" }}>
                ₱ {Utils.commafy(value_cashOut)} / ₱{" "}
                {Utils.commafy(cashOut_maxValue)}
              </div>
            </div>
            <div className="mt-2">
              <BorderLineProgress2
                variant="determinate"
                value={cashOut(value_cashOut)}
              />
            </div>
          </div>
        </div>
        <div className="pt-4 pb-3 d-flex">
          <Button
            variant="outlined"
            color="primary"
            className={`${kyc >= 1 ? "w-100" : "w-50 mr-2"} `}
            style={{ height: 40 }}
            onClick={() => setOpenBux(true)}
          >
            <div className={classes.btnText}>Cash out</div>
          </Button>
          {kyc >= 1 ? (
            ""
          ) : (
            <Button
              disableElevation
              variant="contained"
              color="primary"
              className="w-50 ml-2"
              style={{ height: 40 }}
              onClick={() => setOpenBux(true)}
            >
              <div className={classes.btnText}>
                Upgrade to {kycLevel(kyc + 1)}
              </div>
            </Button>
          )}
        </div>
        <div className="d-md-inherit d-md-none d-flex justify-content-center">
          <IconButton
            onClick={() => setShow(show ? false : true)}
            className="p-1"
          >
            {show ? (
              <KeyboardArrowDown color="secondary" />
            ) : (
              <KeyboardArrowUp color="secondary" />
            )}
          </IconButton>
        </div>
      </div>
      <RedirectDialog open={openBux} setOpen={setOpenBux} />
    </div>
  );
}
