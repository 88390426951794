import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import {
  Box,
  makeStyles,
  Container,
  Typography,
  Link
} from "@material-ui/core";
import logo from "../../../assets/images/sentro_vertical.png";
import Text from "../../../common/text";
import { resendCode } from "../forgotPasswordAction";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SentroLoading from "../../common/loading";
import SentroTheme from '../../common/sentroTheme';
import { useCountdownTimer } from "../../hooks/countdown";
export const FORGOT_PASS_EMAIL = "FORGOT_PASS_EMAIL";
export const NEW_PASSWORD = "NEW_PASSWORD";

const ResetVerification = props => {
  let email = localStorage.getItem("CHANGE_PASSWORD_EMAIL");
  const VerificationStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60vw",
      height: "100vh",
      background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    verification: {
      height: "100vh",
      overflow: "auto",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    box1: {
      marginTop: "35vh",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },
    box2: {
      padding: "6vh 8vh 0 ",
      [theme.breakpoints.down("xs")]: {
        padding: "6vh 5vw"
      }
    }
  }));

  const classes = VerificationStyles();
  let resendSuccess = useSelector(state => state.forgotPassword.resendSuccess);
  const dispatch = useDispatch();
  let loading = useSelector(state => state.forgotPassword.loading);


  let changepassEmail = localStorage.getItem("CHANGE_PASSWORD_EMAIL");
  const [secondsLeft, setSecondsLeft, resendCountdownDisplay] = useCountdownTimer({
    save_to_local: true,
    module: 'sme/forgot_password',
    key: changepassEmail
  });

  const resend = () => {
    setSecondsLeft(60*10);
    dispatch(resendCode(email));
  }


  const spinner = (
    <div className="d-flex justify-content-center loading-spinner">
      <div className="spinner-border mx-auto text-secondary" role="status" style={{ 
      color: SentroTheme.palette.primary.main, fontSize: 8,
      height: 15, width: 15
      }}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>)


  return (
    <div className={classes.root}>
      <SentroLoading open={loading} />
      <Box className={classes.banner}>
        <Container>
          <Box className={classes.box1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={logo} style={{ width: 150 }} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "2vh" }}
                textType="h2"
                content="Create your online shop here."
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#fff",
                  position: "absolute",
                  bottom: 25
                }}
              >
                This works best in the latest Google desktop browser.
              </Typography>
            </div>
          </Box>
        </Container>
      </Box>
      <Box className={classes.verification}>
        <Container>
          <Box className={classes.box2}>
            <Text textType="h3" content="Reset Password" />
            <Box style={{ marginTop: "3vh" }}>
              {resendSuccess ? (
                <Alert
                  id="alert_message"
                  closable
                  message="Email sent. Please check your inbox."
                  type="success"
                  style={{ marginTop: "1vh", marginBottom: "2vh" }}
                />
              ) : (
                ""
              )}
              <Text
                style={{ marginTop: "3vh", color: "#54575F" }}
                textType="p1"
                content="We’re almost there! Kindly check your e-mail to get the reset password link. If you did not get the e-mail within a few minutes, check your spam folder."
              />
            </Box>

            <Box style={{ marginTop: "4vh" }}>
              <div className="d-flex align-items-center" 
                style={{ fontsize: 13, color: "#2B2D33" }}>
                  <p className="mb-0 mr-2">Did not receive the email?{" "}</p>
                  {
                    loading ? 
                    spinner
                    :
                    secondsLeft > 0 ? 
                      resendCountdownDisplay: 
                      <Link
                        id="resend_link"
                        style={{
                          fontWeight: "bold",
                          marginLeft: "1vh",
                          textDecoration: "none"
                        }}
                        onClick={resend}
                      >
                        Resend
                      </Link>
                  }
              </div>
            </Box>

            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#2B2D33",
                  position: "absolute",
                  bottom: 35
                }}
              >
                Need help?
              </Typography>

              <div style={{ position: "absolute", bottom: 5, display: "flex" }}>
                <MailOutlineIcon style={{ color: "#828894", width: 17 }} />
                <a
                  href="mailto:support@sentro.ph"
                  style={{
                    color: "#F97663",
                    fontSize: 13,
                    cursor: "pointer",
                    fontWeight: 600,
                    marginLeft: 10
                  }}
                >
                  support@sentro.ph
                </a>
              </div>
            </div>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ResetVerification;
