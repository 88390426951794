export const compute_subtotal = items => {
  return parseFloat(
    items.reduce(
      (total, object) =>
        parseFloat(total) +
        parseFloat(
          object.quantity *
            (object.product_details.has_variants && object.productSelection
              ? object.productSelection.discounted_price
                ? object.productSelection.discounted_price
                : object.productSelection.price
              : object.product_details.always_available ||
                object.product_details.quantity > 0
              ? object.product_details.discounted_price
                ? object.product_details.discounted_price
                : object.product_details.price
              : 0)
        ),
      0
    )
  );
};

export const computeBuyerCartAmount = items => {
  return parseFloat(
    items.reduce(
      (total, object) =>
        parseFloat(total) +
        (object.product_details.always_available ||
        object.product_details.quantity > 0
          ? parseFloat(
              object.quantity *
                (object.product_details.discounted_price
                  ? object.product_details.discounted_price
                  : object.product_details.price)
            )
          : 0),
      0
    )
  );
};
