import React from "react";
import apiConfig from "../../../../../common/apiConfig";
import axios from "axios";
import { showAlert } from "../../../commonActions";
export async function is_courier_serviceable(params) {
  try {
    let res = await axios.get(
      `${apiConfig.apiURL}/api/ninjavan/is-serviceable/`,
      {
        params
      }
    );

    return res.data.serviceable;
  } catch (e) {
    console.log(e);
  }
}
