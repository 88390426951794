import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ACL from "../common/utilities/ACL";
import Action from "./Redux/Actions";
import AdminDashboardLayout from "./layout";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Dialog from '@material-ui/core/Dialog';
import Path from "../../Paths";
import history from "../../store/history";
import AdminTable from './components/adminTable'
import CircularProgress from '@material-ui/core/CircularProgress';
import { stringify_get_params } from "../common/utilities/DateFilter"

const headCells = [
	{ id: 'first_name', numeric: false, disablePadding: true, label: 'Name' },
	{ id: 'email', numeric: false, disablePadding: false, label: 'Email' },
	{ id: 'is_superuser', numeric: false, disablePadding: false, label: 'Access Type' },
	{ id: 'is_verified', numeric: false, disablePadding: false, label: 'Status' },
	{ id: 'id', numeric: false, disablePadding: false, label: 'Date Enrolled' },
	{ id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

const menu = (row, is_open, menuAnchor, handleClose, setDialogState) => (
	<Menu
		id="simple-menu"
		anchorEl={menuAnchor}
		keepMounted
		open={is_open && menuAnchor ? true:false}
		onClose={handleClose}
	>
		<MenuItem onClick={() => {
			history.push({
				pathname: `/admin/admin_users/${row.id}`, 
			})
		}}>
			View Details
		</MenuItem>
		<MenuItem onClick={() => {
			history.push({
				pathname: `/admin/admin_users/${row.id}`, 
			})
			localStorage.setItem("USER_DETAIL_MODE", "edit_view")
		}}>
			Edit
		</MenuItem>
		<MenuItem 
			onClick={() => {
				setDialogState(true, "delete")
				handleClose()
			}}
		>
			Delete
		</MenuItem>
		<MenuItem 
			disabled={row.is_verified}
			onClick={() => {
				setDialogState(true, "resend_verification")
				handleClose()
			}}
		>
			Resend Activation Link
		</MenuItem>
	</Menu>
)

const mainStyles = theme => ({
	card: {
		backgroundColor: "white",
		padding: 0
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	pageHeaderButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
	},
	searchInputWrapper: {
		display: "flex"
	},
	searchInput: {
		flexGrow: 1
	},
	tableWrapper: {
		overflowX: "auto"
	},
	marginTopSm: {
		[theme.breakpoints.down("sm")]: {
			marginTop: "0.5em",
		},
	},
	searchBar: {
		background: "white",
		maxWidth: "240px",
		marginLeft: "1em",
		borderRadius: "4px",
		color: "gray",
		fontSize: 12,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "auto",
		},
	},
	alertSuccess: {
		padding: "0.75em 1em",
		border: "solid 1px #1DD28B",
		borderLeft: "solid 5px #1DD28B",
		borderRadius: 4,
		background: "#1dd28b14"
	},

	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
	moreHorizIcon: {
		color: "gray",
		cursor: "pointer"
	}
});

class AdminUsers extends React.Component {
	constructor(props) {
		super(props);
		this.searchTimeout = 0; // trigger fetch after typing inactivity
		this.state = {
			targetRow: "",

			// dialog
			dialogTitleText: '',
			dialogStat: false,
			dialogType: "add",
			dialogBody: '',

			// filters
			date_filter_query: "",
			order: "",
			orderBy: "",
			search_query: "",
			page: 0,
			rowsPerPage: 5,

			alert: localStorage.getItem("ALERT_SUCCESS")
		};
		this.onSearch = this.onSearch.bind(this)
		this.handleDelete = this.handleDelete.bind(this)
		this.setDialogState = this.setDialogState.bind(this)
		this.resendVerificationEmail = this.resendVerificationEmail.bind(this)
		this.setMenu = this.setMenu.bind(this)
		this.closeMenu = this.closeMenu.bind(this)
		this.onSortRequest = this.onSortRequest.bind(this)
	}

	setMenu(event, targetRow){
		this.setState({
			menuAnchor: event.target,
			targetRow
		})
	}

	closeMenu(){
		this.setState({
			menuAnchor: null
		})
	}

	fetch = (query_filters) => {
		const { dispatch } = this.props
		dispatch(Action.fetchUsers(query_filters))
	}

	constructFilterQuery(){
		return (this.state.date_filter_query + 
			"&order=" + this.state.order + 
			"&orderBy=" + this.state.orderBy + 
			"&page=" + this.state.page +
			"&rowsPerPage=" + this.state.rowsPerPage +
			this.state.search_query +
			"&admins_only=True")
	}

	componentDidMount = async() => {
		localStorage.removeItem("ALERT_SUCCESS")
		const { adminDashboard } = this.props;
		const date_filter_query = stringify_get_params(adminDashboard.dateFilter);
		this.setState({
			date_filter_query
		}, () => {
			console.log(this.constructFilterQuery());
			this.fetch(this.constructFilterQuery());
        })
        localStorage.setItem("adminMenuActive", 'Users');
	}
	
	onSearch(event) {
		const search_query = event.target.value;
		if(this.searchTimeout) clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.setState({
				search_query: `&q=${search_query}`,
				page: 0
			}, () => {
				console.log(this.constructFilterQuery())
				this.fetch(this.constructFilterQuery());
			})
		}, 500);
	}

	handleDelete = async () => {
		const { dispatch, match, history } = this.props
		const admin_email = match.params.admin_email
		const { targetRow } = this.state
		const response = await dispatch(Action.userDetailMethod("delete", targetRow.id, {}))
		if (response.status == 200 || response.status == 204) {
			this.fetch(this.constructFilterQuery());
			this.setState({
				dialogStat: false,
				alert: "Admin user successfully deleted"
			});
		} else {
			this.setState({
				dialogStat: true,
				dialogType: 'info',
				dialogTitleText: 'Failed',
				dialogBody: response.response ? response.response.data.detail : response.message
			})
		}
	}

	setDialogState(state, dialogType = "edit") {
		this.setState({
			dialogStat : state,
			dialogType: state ? dialogType: this.state.dialogType
		})
	}

	resendVerificationEmail = async () => {
		const { dispatch } = this.props
		const { targetRow } = this.state
		const response = await dispatch(Action.resendVerification(targetRow.email));
		if (response.status == 200){
			this.setState({
				dialogStat: false,
				alert: `Activation link sent to ${targetRow.first_name} ${targetRow.last_name} (${targetRow.email})`
			});
		} else {
			this.setState({
				dialogBody: response.response ? response.response.data.message : response.response.data.message,
				dialogStat: true,
				dialogTitle: "Error",
				dialogType: "info",
			})
		}
	}

	onSortRequest(order, orderBy, page, rowsPerPage){
		this.setState({
			order,
			orderBy,
			page,
			rowsPerPage
		}, () => {
			console.log(this.constructFilterQuery())
			this.fetch(this.constructFilterQuery());
		})
	}
	
	render (){
		const {
			targetRow,
			dialogTitleText,
			dialogType,
			dialogStat,
			dialogBody,
			alert,
			page,
			rowsPerPage
		} = this.state
		const { classes, adminDashboard, history } = this.props
		const dialogTitle = () => {
			if (dialogType == "delete") {
				return "Are you sure you want to delete this record?"
			} else if (dialogType==="info") {
				return dialogTitleText
			} else if (dialogType == "resend_verification") {
				return "Please confirm"
			}
		}
		const dialogContent = () => {
			if (["delete"].includes(dialogType)) {
				return "Click submit to confirm"
			} else if (dialogType==="info") {
				return dialogBody
			} else if (dialogType == "resend_verification") {
				return `Resend activation link to email ${targetRow.email}?`
			}
		}
		const actionButton = () => {
			if (dialogType==="delete") {
				return (
					<Button
						id="delete_button"
						onClick={(event) => {
							this.handleDelete()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Delete
					</Button>
				)
			} else if (dialogType==="resend_verification") {
				return (
					<Button
						id="confirm_button"
						onClick={(event) => {
							this.resendVerificationEmail()
						}} 
						color="primary"
						autoFocus
						disableElevation 
						className={classes.modalActions}
						variant="contained"
						style={{marginLeft: 10}}
					>
						Confirm
					</Button>
				)
			}
		}
		return (
			<AdminDashboardLayout filterHeaderHidden={true}>
				<ACL roles={["superuser"]} redirect_to="/admin/dashboard">
					<Dialog
						open={dialogStat}
						onClose={(event) => this.setDialogState(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						maxWidth="xs"
					>	
						<div className={classes.modalDiv}>
							<div className={classes.modalTile}>
								{dialogTitle()}
							</div>
							<div className={classes.modalBody}>
								{dialogContent()}
							</div>
							<div className={classes.modalActionsWrapper}>
								<Button
									id="action_button"
									variant="outlined"
									onClick={(event) => this.setDialogState(false)} 
									color="primary"
									className={classes.modalActions}
								>
									{
										dialogType == "info" ?
										"Close"
										:
										"Cancel"
									}
								</Button>
								{actionButton()}
							</div>
						</div>
					</Dialog>
					<div style={{
						display: "flex", 
						flexWrap: "wrap",
						alignItems: "center",
						marginBottom: "1.5em",
						padding: "0 1.5em"
					}}>
						<div className={classes.pageTitle}>
							Admin User
						</div>
						{
							adminDashboard.loading && 
							<CircularProgress color="secondary" style={{height: 25, width: 25, color: "#f97663", marginRight: 20}}/>
						}
						<Button
							id="add_user_button" 
							variant="contained" 
							color="primary" 
							disableElevation 
							className={classes.pageHeaderButton}
							onClick={(event) => history.push(Path.ADMIN_ADD_ADMIN_USERS)}
						>
							Add Users
						</Button>
						<OutlinedInput
							className={classes.searchBar}
							id="outlined-adornment-weight"
							// value={values.weight}
							onChange={(event) => this.onSearch(event)}
							startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
							aria-describedby="outlined-weight-helper-text"
							inputProps={{
							'aria-label': 'weight',
							}}
							labelWidth={0}
							margin="dense"
							placeholder="Type here to search"
						/>
					</div>
					<div style={{ padding: "0 1.5em", backgroundColor: "#f1f1f1"}}>
						{
							alert ?
							<p className={classes.alertSuccess}>{alert}</p>
							:
							""
						}
						<Paper className={classes.card}>
							<AdminTable 
								loading={adminDashboard.loading}
								headCells={headCells}
								body={<TableBody>
										{adminDashboard.usersList.map((row, index) => {
											return (
												<TableRow
													key={row.email}
													hover
													role="checkbox"
													tabIndex={-1}
												>
													<TableCell component="th" scope="row">	
														{`${row.first_name} ${row.last_name}`}
													</TableCell>
													<TableCell align="left" component="th" scope="row">	
														{row.email}
													</TableCell>
													<TableCell align="left">{
														row.is_superuser ?
														'Admin'
														:
														'View Only'
													}</TableCell>
													<TableCell align="left">
														{
															row.is_verified ?
															'Active'
															:
															'For Activation'
														}
													</TableCell>
													<TableCell align="left">
														{row.created_on}
													</TableCell>
													<TableCell align="left">
														{
															menu(
																row, 
																this.state.targetRow ? row.id === this.state.targetRow.id:false,
																this.state.menuAnchor, 
																this.closeMenu,
																this.setDialogState,
															)
														}
														<MoreHorizIcon onClick={(event) => this.setMenu(event, row)} className={classes.moreHorizIcon}/>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								}
								length={adminDashboard.listLength}
								page={page}
								rowsPerPage={rowsPerPage}
								onSortRequest={this.onSortRequest}
							/>
						</Paper>
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(mainStyles)(AdminUsers))
