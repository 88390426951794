import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, makeStyles, Container, Typography } from "@material-ui/core";
import logo from "../../../assets/images/sentro_vertical.png";
import Text from "../../../common/text";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { login } from "../../auth/authActions";
import history from "../../../store/history";
import Path from "../../../Paths";
export const FORGOT_PASS_EMAIL = "FORGOT_PASS_EMAIL";
export const NEW_PASSWORD = "NEW_PASSWORD";

const ResetPasswordSuccess = props => {
  React.useEffect(() => {
    const time = setTimeout(() => {
      history.push(Path.LOGIN);
    }, 2000);
    return () => clearTimeout(time);
  });

  const VerificationStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60%",
      height: "100vh",
      background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    verification: {
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    box1: {
      padding: "29vh 18vh 0 ",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },
    box2: {
      padding: "6vh 6vh 0 ",
      [theme.breakpoints.down("xs")]: {
        padding: "6vh 3vw"
      }
    }
  }));

  const classes = VerificationStyles();

  const user = useSelector(state => state.signUp);

  return (
    <div className={classes.root}>
      <Box className={classes.banner}>
        <Container>
          <Box className={classes.box1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={logo} style={{ width: 150 }} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "2vh" }}
                textType="h2"
                content="Create your online shop here."
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#fff",
                  position: "absolute",
                  bottom: 25
                }}
              >
                This works best in the latest Google desktop browser.
              </Typography>
            </div>
          </Box>
        </Container>
      </Box>
      <Box className={classes.verification}>
        <Container>
          <Box className={classes.box2}>
            <Text textType="h3" content="Reset Password" />
            <Box style={{ marginTop: "5vh", display: "flex" }}>
              <CheckCircleIcon style={{ color: "#38b99f", fontSize: 90 }} />
              <Typography style={{ fontSize: 16, padding: 10, marginTop: 10 }}>
                Password reset successful.
                <br />
                Redirecting you now to the dashboard
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ResetPasswordSuccess;
