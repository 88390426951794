import React from "react";
import {
  Dialog,
  Slide,
  IconButton,
  Link,
  Badge,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
  Popover
} from "@material-ui/core";
import {
  Close,
  AccountCircleOutlined,
  ShoppingCartOutlined
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const Transition = props => <Slide direction="up" {...props} />;
export default function CategoriesDialog(props) {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let token = sessionStorage.getItem("TOKEN");
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));

  console.log(props.open);
  return (
    <>
      {isMobile ? (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
          <div
            style={{
              backgroundColor: props.color.primary.main,
              left: 0,
              right: 0
            }}
            className="p-3 d-flex align-items-center justify-content-between position-fixed"
          >
            <IconButton onClick={props.onClose}>
              <Close style={{ color: props.color.primary.contrastText }} />{" "}
            </IconButton>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                {token && user ? (
                  <IconButton onClick={() => props.handleGoToDashboard()}>
                    <AccountCircleOutlined
                      className={props.classes.colorPrimary}
                    />
                  </IconButton>
                ) : (
                  <Link
                    className="px-md-4 px-2"
                    style={{ color: props.color.primary.contrastText }}
                    onClick={() =>
                      history.push(`/shop/${props.storeInfo.site}/login`)
                    }
                  >
                    Log in
                  </Link>
                )}
                <IconButton onClick={props.cartButton()}>
                  <Badge
                    classes={{ badge: props.classes.customBadge }}
                    badgeContent={props.productList.length}
                  >
                    <ShoppingCartOutlined
                      style={{ color: props.color.primary.contrastText }}
                    />
                  </Badge>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column" style={{ marginTop: "5em" }}>
            {props.categories.map(item => (
              <Link
                style={{ color: "#2b2d32", fontSize: 18, fontWeight: 500 }}
                className="py-4 text-center"
                onClick={() => {
                  history.push(
                    `/shop/${
                      props.storeInfo?.site
                    }/products/?category=${encodeURIComponent(
                      item
                    )}&sortBy=-created_on`
                  );
                  props.onClose();
                }}
              >
                {item}
              </Link>
            ))}
          </div>
        </Dialog>
      ) : (
        <Popover
          open={props.open}
          onClose={props.onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          anchorEl={props.anchorEl}
        >
          {props.categories.map(item => (
            <MenuItem
              style={{ fontSize: 12 }}
              onClick={() => {
                history.push(
                  `/shop/${
                    props.storeInfo?.site
                  }/products/?category=${encodeURIComponent(
                    item
                  )}&sortBy=-created_on`
                );
                props.onClose();
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Popover>
      )}
    </>
  );
}
