import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { CalendarToday, Close, LiveTvSharp } from "@material-ui/icons";
import OrderActions from "./orderActions";
import moment from "moment";
import { result } from "lodash";


export default function OrderFilters(props) {
  const useStyles = makeStyles(theme => ({
    input: { backgroundColor: "#fff" },
    datepicker: {
      backgroundColor: "#fff",
      width: 155,
      marginRight: 5
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.order.orders.loading);
  const filters = useSelector(state => state.order.orders.filters);
  const pickupAddressList = useSelector(state => state.order.pickup_addresses);
  const [timeoutId, setTimeoutId] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const onSearch = e => {
    const val = e.target.value;
    setSearch(val);
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        dispatch(
          OrderActions.setOrderFilters({ ...filters, search: val, page: 0 })
        );
      })
    );
  };

  const onClear = () => {
    setSearch("");
    dispatch(OrderActions.setOrderFilters({ ...filters, search: "", page: 0 }));
  };

  const handleChange = (field, value) => {
    dispatch(OrderActions.setOrderFilters({ [field]: value, page: 0 }));
  };

  const searchBar = (
    <OutlinedInput
      id="search_bar"
      className={`${classes.input} mt-1`}
      fullWidth
      color="secondary"
      style={{ width: 155 }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon style={{ color: "#909196" }} />
        </InputAdornment>
      }
      endAdornment={
        loading ? (
          <InputAdornment position="end">
            <div
              className="spinner-border mx-auto"
              role="status"
              style={{
                color: "18B4A1",
                fontSize: 1,
                height: 15,
                width: 15
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </InputAdornment>
        ) : search ? (
          <InputAdornment position="end">
            <IconButton
              style={{ marginRight: "-0.5em" }}
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => onClear()}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ) : (
          ""
        )
      }
      value={search}
      margin="dense"
      placeholder="Search"
      onChange={e => onSearch(e)}
    />
  );

  const paymentStatusFilter = (
    <FormControl
      variant="outlined"
      color="secondary"
      margin="dense"
      style={{ width: 160 }}
    >
      <InputLabel>Payment Status</InputLabel>
      <Select
        value={filters.payment_status}
        className="bg-white"
        label="Payment Status"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        onChange={e => handleChange("payment_status", e.target.value)}
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="paid">Paid</MenuItem>
        <MenuItem value="pending">Pending</MenuItem>
        <MenuItem value="cancelled">Cancelled</MenuItem>
        <MenuItem value="expired">Expired</MenuItem>
      </Select>
    </FormControl>
  );

  const courierFilter = (
    <FormControl
      variant="outlined"
      color="secondary"
      margin="dense"
      style={{ width: 155 }}
    >
      <InputLabel>Courier</InputLabel>
      <Select
        value={filters.paymentStatus}
        className="bg-white"
        label="Courier"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="borzo">Borzo</MenuItem>
        {/* <MenuItem value="ninjavan">Ninjavan</MenuItem> */}
      </Select>
    </FormControl>
  );

  const deliveryStatusFilter = (
    <FormControl
      variant="outlined"
      color="secondary"
      margin="dense"
      style={{ width: 155 }}
    >
      <InputLabel>Delivery Status</InputLabel>
      <Select
        value={filters.paymentStatus}
        className="bg-white"
        label="Delivery Status"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        onChange={e => handleChange("delivery_status", e.target.value)}
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="completed">Delivered</MenuItem>
        <MenuItem value="active">For pick-up</MenuItem>
        <MenuItem value="available">Looking for rider</MenuItem>
        <MenuItem value="courier_departed">In transit</MenuItem>
        <MenuItem value="canceled">Cancelled</MenuItem>
      </Select>
    </FormControl>
  );

  const dateFilter = (
    <div className="d-flex align-items-center">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          autoOk
          label="Start Date"
          margin="dense"
          variant="inline"
          inputVariant="outlined"
          format="MM/DD/YYYY"
          InputAdornmentProps={{ position: "start" }}
          orientation="portrait"
          color="secondary"
          className={`${classes.datepicker}`}
          value={filters.startDate}
          maxDate={
            filters.endDate === null
              ? new Date().toLocaleDateString("en-US")
              : filters.endDate
          }
          onChange={e => {
            handleChange("startDate", moment(e).format("MM/DD/YYYY"));
            // handleChange("date_ordered_type", "custom");
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarToday style={{ color: "#909196", fontSize: 18 }} />
              </InputAdornment>
            )
          }}
        />
        <DatePicker
          autoOk
          label="End Date"
          margin="dense"
          variant="inline"
          inputVariant="outlined"
          format="MM/DD/YYYY"
          InputAdornmentProps={{ position: "start" }}
          orientation="portrait"
          color="secondary"
          className={`${classes.datepicker}`}
          value={filters.endDate}
          minDate={
            filters.startDate === null
              ? new Date().toLocaleDateString("en-US")
              : filters.startDate
          }
          onChange={e => {
            handleChange("endDate", moment(e).format("MM/DD/YYYY"));
            handleChange("date_ordered_type", "custom");
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarToday style={{ color: "#909196", fontSize: 18 }} />
              </InputAdornment>
            )
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );

  const deliveryDateFilter = (
    <div className="d-flex align-items-center">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          autoOk
          label="Delivery Date"
          margin="dense"
          variant="inline"
          style={{ width: 180 }}
          inputVariant="outlined"
          format="MMM DD YYYY"
          InputAdornmentProps={{ position: "start" }}
          orientation="portrait"
          color="secondary"
          className={`${classes.datepicker}`}
          value={filters.deliveryDate}
          minDate={
            filters.startDate === null
              ? new Date().toLocaleDateString("en-US")
              : filters.startDate
          }
          onChange={e => {
            handleChange("deliveryDate", moment(e).format("YYYY-MM-DD"));
            handleChange("date_ordered_type", "custom");
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarToday style={{ color: "#909196", fontSize: 18 }} />
              </InputAdornment>
            )
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );

  // const branches = [...orderList.values()]
  // let finalBranches = []
  // branches.forEach((item) => {
  //   if (item.delivery_detail) {
  //     var data = {
  //       id: item.delivery_detail.details.id,
  //       city: item.delivery_detail.details.city
  //     }
  //   }
  //   else {
  //     data = {}
  //   }
  //   if (data.city !== undefined) {
  //     if(!finalBranches.some(e => e.id === data.id)) {
  //       finalBranches.push(data);
  //     }
  //   }
  // })

  const branchFilter = (
    <FormControl
      variant="outlined"
      color="secondary"
      margin="dense"
      style={{ width: 200 }}
    >
      <InputLabel>Branch</InputLabel>
      <Select
        value={filters.branch}
        className="bg-white"
        label="Branch"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        onChange={e => handleChange("branch", e.target.value)}
      >
        <MenuItem value="">All</MenuItem>
        {pickupAddressList.map((item,index) => (
          <MenuItem value={item.id}>{item.city}</MenuItem>
        ) )}
      </Select>
    </FormControl>
  );

  return (
    <div className="d-flex align-items-center" style={{ display: "flex"}}>
      <div className="mr-2">{searchBar}</div>
      {props.show_other_filters ?
        <div style={{ display: "flex"}}>
          <div className="mr-2">{branchFilter}</div>
          <div className="mr-2">{paymentStatusFilter}</div>
          <div className="mr-2">{deliveryStatusFilter}</div>
          <div className="mr-2">{deliveryDateFilter}</div>
        </div>
      :
        <div style={{ display: "flex"}}>
          <div className="mr-2">{paymentStatusFilter}</div>
          <div className="mr-2">{courierFilter}</div>
          <div className="mr-2">{deliveryStatusFilter}</div>
          <div className="mr-2">{dateFilter}</div>
        </div>
      }
    </div>
  );
}
