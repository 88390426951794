import React, { Fragment } from "react";
import clsx from 'clsx';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from "@material-ui/core/Paper";
import ACL from "../../../../common/utilities/ACL";
import Action from "../../../Redux/Actions";
import AdminDashboardLayout from "../../../layout";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import Path from "../../../../../Paths";

function humanize(str) {
	var i, frags = str.split('_');
	for (i=0; i<frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join(' ');
}

const useStyles = theme => ({
	cardWrapper: {
		maxWidth: 500,
		margin: "0em 2em 1.5em",
	},
	card: {
		border: "1px solid #E6EAF0",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
		borderRadius: 4,
		backgroundColor: "white",
		maxWidth: "100%",
		'& > .body': {
			padding: "1em 2em 2em",
		},
		'& > .header': {
			'&:first-child': {
				borderTop: "none"
			},
			'& > h2': {
				color: "#002C42",
				fontSize: 22,
				fontWeight: 600,
				marginBottom: 0
			},
			padding: "1em 2em",
			borderTop: "solid 1px rgba(0,0,0,0.1)",
			borderBottom: "solid 1px rgba(0,0,0,0.1)"
		}
	},
	pageBackButton: {
		backgroundColor: "#F1F1F1",
		display: "flex !important",
		alignItems: "center",
		fontWeight: 600,
		fontSize: 14,
		padding: "3em 0em 1.5em",
		color: "#F97663 !important"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1
	},
	paddingTop: {
		marginTop: theme.spacing(2),
	},
	inputLabel: {
		color: "#212B36", 
		fontSize: 12,
		marginBottom: "0.5em !important"
	},
	input: {
		width: "100%"
	},
	radioGroup: {
		// display: "flex",
		// flexDirection: "column",
		width: "100%"
	},
	radio: {
		backgroundColor: 'transparent',
		border: "solid 1px rgb(249, 118, 99)",
		width: 22,
		height: 22,
		borderRadius: "50%",
		'&:before': {
		  display: 'block',
		  width: 14,
		  height: 14,
		  margin: 3,
		  borderRadius: "50%",
		  background: 'white',
		  content: '""'
		}
	},
	checkedRadio: {
		backgroundColor: 'transparent',
		border: "solid 1px #d7d7d7",
		width: 22,
		height: 22,
		borderRadius: "50%",
		'&:before': {
		  display: 'block',
		  width: 14,
		  height: 14,
		  margin: 3,
		  borderRadius: "50%",
		  background: "rgb(249, 118, 99)",
		  content: '""'
		}
	},
	deleteBtn: {
		height: 35
	},
	modalDiv: {
		padding: "2.5em",
		minWidth: 400,
		[theme.breakpoints.down("xs")]: {
			minWidth: "auto",
		},
	},
	modalTile: {
		fontSize: 26,
		color: "#2B2D33",
		fontWeight: 600,
		marginBottom: 25,
		lineHeight: "1em"
	},
	modalBody: {
		fontSize: 14,
		color: "#54575F",
		marginBottom: 25
	},
	modalActionsWrapper: {
		display: "flex",
		flexDirection: "row"
	},
	modalActions: {
		flexGrow: 1,
	},
	formErrorMessage: {
		padding: "0.75em 1.5em",
		border: "solid 1px #C24A39",
		borderLeft: "solid 5px #C24A39",
		borderRadius: 4,
		background: "#c24a390f"
	},
	rowInput: {
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
		"& > p": {
			marginRight: "0.5em",
			marginLeft: "2.5em",
			fontSize: 12
		},
		"& > div": {
			width: "5em",
			"& > input": {
				padding: "0.5em",
			}
		},
		"& .Mui-disabled": {
			background: "rgba(0,0,0,0.05)"
		}
	}
});

class Form extends React.Component {

	componentDidMount(){
		localStorage.removeItem("USER_DETAIL_MODE")
	}

	render(){
		const {
			first_name,
			last_name,
			name,
			email,
			sentro_percentage_fee,
			sentro_fixed_fee,
			revenue_setup
		} = this.props.state;
		const { adminDashboard, mode = "edit", formErrors = [],
			classes, setPageMode, setDialogState, handleChange, handleRadioChange } = this.props
		return (
			<Fragment>
				<div style={{ padding: "0"}}>
					<div style={{
						display: "flex", 
						alignItems: "center",
						marginBottom: "1.5em"
					}}>
						<div className={classes.pageTitle}>
							{
								mode === "create" ? 
								"Add a Partner"
								:
								"Edit a Partner"
							}
						</div>
					</div>
					{
						Object.keys(formErrors).map((key, index) => 
							<p key={index} className={classes.formErrorMessage}>{`${humanize(String(key))}: ${humanize(String(formErrors[key]))}`}</p>
						)
					}
					<Paper className={classes.card}>
						<div className="header">
							<h2>Partner Details</h2>
						</div>
						<div className="body">
							<FormControl className={`
								${classes.margin}
								${classes.textField}
								${classes.input}
							`} variant="outlined">
								<p className={classes.inputLabel}>Name</p>
								<OutlinedInput
									id=""
									value={name}
									className={classes.input}
									onChange={(event) => handleChange(event, 'name')}
									aria-describedby="outlined-weight-helper-text"
									inputProps={{
									'aria-label': 'weight',
									}}
									labelWidth={0}
									margin="dense"
									fullWidth={true}
								/>
							</FormControl>
							<FormControl className={`
								${classes.margin}
								${classes.textField}
								${classes.paddingTop}
								${classes.input}
							`} variant="outlined">
								<p className={classes.inputLabel}>First Name</p>
								<OutlinedInput
									id=""
									value={first_name}
									className={classes.input}
									onChange={(event) => handleChange(event, 'first_name')}
									aria-describedby="outlined-weight-helper-text"
									inputProps={{
									'aria-label': 'weight',
									}}
									labelWidth={0}
									margin="dense"
									fullWidth={true}
								/>
							</FormControl>
							<FormControl className={`
								${classes.margin} 
								${classes.textField} 
								${classes.paddingTop}
								${classes.input}
							`} variant="outlined">
								<p className={classes.inputLabel}>Last Name</p>
								<OutlinedInput
									id=""
									value={last_name}
									className={classes.input}
									onChange={(event) => handleChange(event, 'last_name')}
									aria-describedby="outlined-weight-helper-text"
									inputProps={{
									'aria-label': 'weight',
									}}
									labelWidth={0}
									margin="dense"
									fullWidth={true}
								/>
							</FormControl>
							<FormControl className={`
								${classes.margin} 
								${classes.textField} 
								${classes.paddingTop}
								${classes.input}
							`} variant="outlined">
								<p className={classes.inputLabel}>Email Address</p>
								<OutlinedInput
									disabled={mode !== "create"}
									id=""
									value={email}
									className={classes.input}
									onChange={(event) => handleChange(event, 'email')}
									aria-describedby="outlined-weight-helper-text"
									inputProps={{
									'aria-label': 'weight',
									}}
									labelWidth={0}
									fullWidth={true}
									margin="dense"
								/>
							</FormControl>
						</div>
						<div className="header">
							<h2>Revenue share setup</h2>
						</div>
						<div className="body">
							<FormControl component="fieldset" className={`
								${classes.radioGroup} 
							`}>
								<RadioGroup aria-label="revenue_setup" name="revenue_setup" className={classes.radioGroup}
										value={revenue_setup} onChange={handleRadioChange}
								>
									<FormControlLabel 
										// style={{ flexGrow: 1}}
										value="percentage"
										control={
											<Radio 
												color="primary" 
												icon={<span className={classes.radio} />} 
												checkedIcon={<span className={clsx(classes.radio, classes.checkedRadio)} 
											/>
										}/>} 
										label="Percentage Based"
									/>
									<FormControl className={`
										${classes.margin} 
										${classes.textField}
										${classes.rowInput}
									`} variant="outlined"  margin="none">
										<p className={classes.inputLabel}>Value:</p>
										<OutlinedInput
											disabled={revenue_setup!=="percentage"}
											id=""
											value={revenue_setup==="percentage" ? sentro_percentage_fee: ""}
											className={classes.input}
											onChange={(event) => handleChange(event, 'sentro_percentage_fee')}
											aria-describedby="outlined-weight-helper-text"
											inputProps={{
											'aria-label': 'weight',
											}}
											type="number"
											labelWidth={0}
											fullWidth={true}
											margin="dense"
										/>
									</FormControl>
									<FormControlLabel
										// style={{ flexGrow: 1}}
										value="fee"
										control={
											<Radio 
												color="primary" 
												icon={<span className={classes.radio} />} 
												checkedIcon={<span className={clsx(classes.radio, classes.checkedRadio)} 
											/>
										}/>} 
										label="Fee Based"
									/>
									<FormControl className={`
										${classes.margin} 
										${classes.textField}
										${classes.rowInput}
									`} variant="outlined">
										<p className={classes.inputLabel}>Value:</p>
										<OutlinedInput
											disabled={revenue_setup!=="fee"}
											id=""
											value={revenue_setup==="fee" ? sentro_fixed_fee: ""}
											className={classes.input}
											onChange={(event) => handleChange(event, 'sentro_fixed_fee')}
											aria-describedby="outlined-weight-helper-text"
											inputProps={{
											'aria-label': 'weight',
											}}
											type="number"
											labelWidth={0}
											fullWidth={true}
											margin="dense"
										/>
									</FormControl>
									<FormControlLabel
										// style={{ flexGrow: 1}}
										value="percentage+fee"
										control={
											<Radio 
												color="primary" 
												icon={<span className={classes.radio} />} 
												checkedIcon={<span className={clsx(classes.radio, classes.checkedRadio)} 
											/>
										}/>} 
										label="Percentage + fee based"
									/>
									<div style={{ display: "flex", flexDirection: "row"}}>
										<FormControl className={`
											${classes.margin} 
											${classes.textField}
											${classes.rowInput}
										`} variant="outlined">
											<p className={classes.inputLabel}>Percentage Value:</p>
											<OutlinedInput
												disabled={revenue_setup!=="percentage+fee"}
												id=""
												value={revenue_setup==="percentage+fee" ? sentro_percentage_fee: ""}
												className={classes.input}
												onChange={(event) => handleChange(event, 'sentro_percentage_fee')}
												aria-describedby="outlined-weight-helper-text"
												inputProps={{
												'aria-label': 'weight',
												}}
												type="number"
												labelWidth={0}
												fullWidth={true}
												margin="dense"
											/>
										</FormControl>
										<FormControl className={`
											${classes.margin} 
											${classes.textField}
											${classes.rowInput}
										`} variant="outlined">
											<p className={classes.inputLabel}>Fee Value:</p>
											<OutlinedInput
												disabled={revenue_setup!=="percentage+fee"}
												id=""
												value={revenue_setup==="percentage+fee" ? sentro_fixed_fee: ""}
												className={classes.input}
												onChange={(event) => handleChange(event, 'sentro_fixed_fee')}
												aria-describedby="outlined-weight-helper-text"
												inputProps={{
												'aria-label': 'weight',
												}}
												type="number"
												labelWidth={0}
												fullWidth={true}
												margin="dense"
											/>
										</FormControl>
									</div>
								</RadioGroup>
							</FormControl>
							<Button variant="contained" color="primary" disableElevation fullWidth
								className={classes.paddingTop}
								onClick={(event) => setDialogState(true, mode === "create" ? "create" : "edit")}
							>
								Submit
							</Button>
						</div>
					</Paper>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
const FormUser = connect(mapStateToProps)(withStyles(useStyles)(Form));
export { FormUser }


class AdminAppMarketplacePartnersCreate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			first_name: '',
			last_name: '',
			email: '',
			revenue_setup: 'percentage',
			sentro_percentage_fee: 0,
			sentro_fixed_fee: 0,

			dialogType: "edit",
			dialogStat: false,
			dialogErrorMessage: '',
			dialogTitleText: '',
			dialogBody: '',

			formErrors: [],
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.setDialogState = this.setDialogState.bind(this)
		this.handleRadioChange = this.handleRadioChange.bind(this)
	}

	componentDidMount = async () => {
		const { dispatch, login, match } = this.props
		const id = match.params.id
		if (id) {
			const response = await dispatch(Action.fetchPartner(id));
			if (response) {
				const { user, name, email, sentro_percentage_fee, sentro_fixed_fee } = response
				let revenue_setup = "percentage+fee";
				console.log(sentro_percentage_fee , sentro_fixed_fee, sentro_percentage_fee && sentro_fixed_fee)
				if(parseFloat(sentro_percentage_fee) && parseFloat(sentro_fixed_fee)){
					revenue_setup  = "percentage+fee";
				} else if (parseFloat(sentro_percentage_fee)){
					revenue_setup  = "percentage";
				} else if (parseFloat(sentro_fixed_fee)){
					revenue_setup  = "fee";
				}
				this.setState({
					name,
					email,
					sentro_percentage_fee,
					sentro_fixed_fee,
					revenue_setup,
					user_id: user.id,
					first_name: user.first_name,
					last_name: user.last_name,
				})
			}
		}
	}
	
	handleChange = (event, stateName) => {
		this.setState({
			[stateName] : event.target.value
		})
	}

	handleRadioChange = (event) => {
		this.setState({
			revenue_setup : event.target.value
		}, () => console.log(this.state))
	}
	
	setDialogState(state, dialogType = "edit") {
		this.setState({
			dialogStat : state,
			dialogType: state ? dialogType: this.state.dialogType
		})
	}

	handleSubmit = async () => {
		const { dispatch, history, match } = this.props
		const partner_pk = match.params.id
		const { user_id, sentro_percentage_fee, sentro_fixed_fee, 
			first_name, last_name, name, email, revenue_setup } = this.state
		this.setDialogState(false)
		let response;
		const feesRate = {
			sentro_percentage_fee: ["percentage", "percentage+fee"].includes(revenue_setup) ? sentro_percentage_fee : 0,
			sentro_fixed_fee: ["fee", "percentage+fee"].includes(revenue_setup) ? sentro_fixed_fee : 0
		}
		if (partner_pk) {
			response = await dispatch(Action.patchPartner(partner_pk, {
				name,
				...feesRate,
				user_data: {
					id: user_id, 
					email,
					first_name, 
					last_name,
					mobile_number: '-',
					is_admin: false, 
					is_superuser: false
				}
			}))
		} else {
			response = await dispatch(Action.createPartner({
				name,
				email,
				...feesRate,
				user_data: { 
					email,
					first_name, 
					last_name,
					mobile_number: '-',
					is_admin: false, 
					is_superuser: false
				}
			}))
		}
		if (response.status == 201 || response.status == 200) {
			localStorage.setItem("ALERT_SUCCESS", partner_pk ? 
			`Partner successfully edited.` :
			`${name} (${first_name} ${last_name} - ${email}) is added. Email verification sent.`)
			history.push(Path.ADMIN_APP_MARKETPLACE_PARTNERS)
		} else {
			this.setState({
				formErrors: response.response ? response.response.data : response.message
			})
		}
	}
	
	render (){
		const {
			first_name,
			last_name,
			email,
			name,
			dialogStat,
			dialogType,
			dialogTitleText,
			dialogBody,
			dialogErrorMessage,
			formErrors
		} = this.state;
		const { classes, adminDashboard, history } = this.props
		const dialogTitle = () => {
			return "Please confirm"
		}
		const dialogContent = () => {
			return this.props.match.params.id ?
				`Edit Partner ${name} (${first_name} ${last_name} - ${email})` :
				`Add Partner ${name} (${first_name} ${last_name} - ${email})`
		}
		const actionButton = () => {
			return (
				<Button
					id="submit_button"
					onClick={(event) => this.handleSubmit()} 
					color="primary" 
					autoFocus
					variant="contained"
					disableElevation 
					className={classes.modalActions}
					style={{marginLeft: 10}}
				>
					Submit
				</Button>
			)
		}
		return (
			<AdminDashboardLayout filterHeaderHidden>
				<ACL roles={["superuser"]} redirect_to="/admin/dashboard">
					<div>
						<Fragment>
							<Dialog
								open={dialogStat}
								onClose={(event) => this.setDialogState(false)}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
								maxWidth="xs"
							>	
								<div className={classes.modalDiv}>
									<div className={classes.modalTile}>
										{dialogTitle()}
									</div>
									<div className={classes.modalBody}>
										{dialogContent()}
									</div>
									<div className={classes.modalActionsWrapper}>
										<Button
											variant="outlined"
											onClick={(event) => this.setDialogState(false)} 
											color="primary"
											className={classes.modalActions}
										>
											{
												dialogType == "info" ?
												"Close"
												:
												"Cancel"
											}
										</Button>
										{actionButton()}
									</div>
								</div>
							</Dialog>
							<div className={classes.cardWrapper}>
								<FormUser
									mode={this.props.match.params.id ? `edit`: `create`}
									formErrors={formErrors}
									state={this.state} 
									setPageMode={this.setPageMode}
									setDialogState={this.setDialogState}
									handleChange={this.handleChange}
									handleRadioChange={this.handleRadioChange}
									handleSubmit={this.handleSubmit}
								/>
							</div>
						</Fragment>
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

export default connect(mapStateToProps)(withStyles(useStyles)(AdminAppMarketplacePartnersCreate));
