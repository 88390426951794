import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import logo from "../../../../assets/images/sentro_logo2.png";
import {
  Button,
  IconButton,
  useMediaQuery,
  Container
} from "@material-ui/core";
import SpinnerLoading from "../../../common/spinnerLoading";
import { ExpandMore } from "@material-ui/icons";
import Utils from "../../../common/utils";
import Actions from "../../redux/actions";

export default function Footer(props) {
  const history = useHistory();
  const useStyles = makeStyles(theme => ({
    main: {
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: "0px -8px 24px rgba(0, 0, 0, 0.12)",
      borderRadius: "8px 8px 0 0"
    },
    limitDiv: {
      [theme.breakpoints.down("sm")]: {
        maxHeight: 0,
        overflow: "hidden",
        transition: "all 0.3s ease-in-out"
      }
    },
    extended: {
      maxHeight: "200px !important"
    },
    label: {
      fontSize: 12,
      color: "#2b2d32"
    },
    label2: {
      fontSize: 18,
      fontWeight: 600,
      color: "#2b2d32"
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const selected_items = useSelector(
    state => state.quickOrder.cart.selected_items
  );
  const summary = useSelector(state => state.quickOrder.cart.summary);
  const shipping_fee = useSelector(
    state => state.quickOrder.deliveryDetails.shippingFee
  );
  const fees = useSelector(state => state.quickOrder.fees);
  const selected_payment_channel = useSelector(
    state => state.quickOrder.selected_payment_channel
  );
  const delivery_details = useSelector(
    state => state.quickOrder.deliveryDetails
  );
  const customer_details = useSelector(
    state => state.form.customer_details?.values
  );
  const delivery_address = useSelector(
    state => state.form.delivery_address?.values
  );
  const vouchers = useSelector(state => state.quickOrder.vouchers);

  function item_count() {
    return parseFloat(
      selected_items.reduce((total, object) => {
        return object.has_variants
          ? parseFloat(total) +
              (object.always_available
                ? parseFloat(object.quantity)
                : object.quantity > 0
                ? parseFloat(object.quantity)
                : 0)
          : parseFloat(total) +
              (object.always_available
                ? parseFloat(object.quantity)
                : object.stock > 0
                ? parseFloat(object.quantity)
                : 0);
      }, 0)
    );
  }

  // React.useEffect(() => {
  //   let payment_channel = ["otc", "online_banking", "e-wallets"].includes(
  //     selected_payment_channel.paymentOption
  //   )
  //     ? "otc"
  //     : selected_payment_channel.paymentOption;

  //   if (selected_payment_channel) {
  //     let params = {
  //       payment_channel: payment_channel,
  //       subtotal: summary.subtotal,
  //       delivery_method:
  //         delivery_details.shipping === "courier"
  //           ? delivery_details.delivery_detail.courier
  //           : delivery_details.shipping,
  //       shipping_fee: delivery_details.shippingFee
  //     };
  //     dispatch(Actions.computeFees(params));
  //   }
  // }, [
  //   selected_items,
  //   selected_payment_channel.paymentOption,
  //   delivery_details
  // ]);

  const summary_comp = (
    <div id="summary_comp" className="position-relative">
      <div
        className="d-md-none d-flex justify-content-center position-absolute"
        style={{ left: 0, right: 0, top: -20 }}
        onClick={() => setShow(show ? false : true)}
      >
        <IconButton className="p-1">
          <ExpandMore
            style={{
              transform: `rotate(${show ? "0deg" : "180deg"})`,
              transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            }}
          />
        </IconButton>
      </div>
      <div className="m-2">
        <div className={`${classes.limitDiv} ${show ? classes.extended : ""}`}>
          <div
            className="text-md-center"
            style={{ fontSize: 16, fontWeight: 600 }}
          >
            Summary
          </div>
          <div className="my-3 d-flex justify-content-between">
            <div className={classes.label}>Items</div>
            <div className={classes.label}>{item_count()}</div>
          </div>
          <div className="my-3 d-flex justify-content-between">
            <div className={classes.label}>Subtotal</div>
            <div className={classes.label}>
              ₱ {Utils.commafy(summary.subtotal)}
            </div>
          </div>
          {delivery_details.shipping && delivery_details.delivery_detail ? (
            <div className="my-3 d-flex justify-content-between">
              <div className={classes.label}>Delivery fee</div>
              <div className={classes.label}>
                ₱ {Utils.commafy(shipping_fee)}
              </div>
            </div>
          ) : (
            ""
          )}
          {/**COD FEE */}
          {selected_payment_channel === "cod" ? (
            <div className="my-3 d-flex justify-content-between">
              <div className={`${classes.label} d-flex align-items-center`}>
                COD fee{" "}
              </div>
              <div className={classes.label}>
                ₱ {Utils.commafy(fees.cod_fee)}
              </div>
            </div>
          ) : (
            ""
          )}
          {/**ADMIN FEE */}
          {fees.admin_fee === 0 ? (
            ""
          ) : (
            <div className="my-3 d-flex justify-content-between">
              <div className={`${classes.label} d-flex align-items-center`}>
                Sentro fee{" "}
              </div>
              <div className={classes.label}>
                ₱ {Utils.commafy(fees.admin_fee)}
              </div>
            </div>
          )}
          {/**VOUCHER DISCOUNT */}
          {summary.voucher_discount ? (
            <div className="my-3 d-flex justify-content-between">
              <div className={classes.label}>Voucher Applied</div>
              <div className={classes.label}>
                - ₱ {Utils.commafy(summary.voucher_discount)}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/**TOTAL */}
        <div
          style={{ borderTop: show ? "2px dashed #d1d5dd" : "none" }}
          className="py-2 d-flex justify-content-between"
        >
          <div className={classes.label2}>Total</div>
          <div className={classes.label2}>
            ₱ {Utils.commafy(summary.total - summary.voucher_discount)}
          </div>
        </div>
      </div>
    </div>
  );

  const show_summary =
    location.pathname.includes("/cart") ||
    location.pathname.includes("/delivery") ||
    location.pathname.includes("/customer_details") ||
    location.pathname.includes("/payment");

  return (
    <>
      {isMobile ? (
        <div className={`${classes.main} p-3 bg-white position-fixed`}>
          {show_summary ? summary_comp : ""}
          <div className="mb-3 mt-md-5 mt-0 bg-white">
            <Button
              id="btn_footer"
              fullWidth
              disableElevation
              color="primary"
              variant="contained"
              onClick={props.buttonOnclick}
            >
              {props.loading ? <SpinnerLoading /> : ""}
              {props.button_text}
            </Button>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div style={{ fontSize: 12 }} className="mr-2">
              Powered by:
            </div>
            <img src={logo} alt="" width="auto" height="20" />
          </div>
        </div>
      ) : (
        <div style={{ padding: "2vh 0" }}>
          <Container maxWidth="md">
            {show_summary ? summary_comp : ""}
            <div className="mb-3 mt-md-5 mt-0 bg-white">
              <Button
                id="btn_footer"
                fullWidth
                disableElevation
                color="primary"
                variant="contained"
                onClick={props.buttonOnclick}
              >
                {props.loading ? <SpinnerLoading /> : ""}
                {props.button_text}
              </Button>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div style={{ fontSize: 12 }} className="mr-2">
                Powered by:
              </div>
              <img src={logo} alt="" width="auto" height="20" />
            </div>
          </Container>
        </div>
      )}
    </>
  );
}
