import React from "react";
import Pagination from "@material-ui/lab/Pagination";

export default function ProductPagination(props) {
  return (
    <div className="my-3">
      <Pagination
        color="secondary"
        count={props.count}
        page={props.page}
        onChange={props.onChange}
      />
    </div>
  );
}
