import React from "react";
import logo from "../../assets/images/sentro_vertical.png";
import { Box, Container, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBack } from "@material-ui/icons";
import SentroButton from "../common/sentroButton";
import Text from "../../common/text";
import history from "../../store/history";
import Path from "../../Paths";
const ForgotPassword = props => {
  let forgetPasswordDetails = useSelector(
    state => state.login.forgetPasswordDetails
  );
  const forgotPasswordStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "70vw",
      height: "100vh",
      background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
      [theme.breakpoints.down("sm")]: {
        //  width: "100%",
        display: "none"
        // height: "50vh"
      }
    },

    ForgotPassword: {
      height: "100vh",
      overflow: "auto",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    box1: {
      display: "flex",
      justifyContent: "center",
      margin: 60,
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },

    box2: {
      marginTop: "10vh",
      padding: 16
    },

    bannerImage: {
      marginTop: "5vh",
      width: 200,
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    }
  }));

  const classes = forgotPasswordStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.banner}>
        <Container>
          <Box className={classes.box1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={logo} style={{ width: 150 }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "2vh" }}
                textType="h2"
                content="Create your online shop here."
              />
            </div>
          </Box>
        </Container>
      </Box>
      <Box className={classes.ForgotPassword}>
        <Container>
          <Box style={{ marginTop: "2vh" }}>
            <SentroButton
              id="back_button"
              color="primary"
              onClick={() => history.go(-1)}
              startIcon={<ArrowBack color="primary" />}
              style={{ fontSize: 14 }}
              label={"BACK"}
            />
          </Box>
          <Box className={classes.box2}>
            <Text textType="h3" content="Reset password request" />
            <Box style={{ marginTop: "2vh" }}>
              <Text
                style={{ color: "#2B2D33" }}
                textType="p1"
                content={`We just received a request to reset the password of your Sentro account. Please check your email to proceed.`}
              />
            </Box>
          </Box>
          <Box className={classes.box2}>
            <SentroButton
              id="continue_button"
              fullWidth
              label="Continue"
              variant="contained"
              onClick={() => history.push(Path.INPUT_PASSWORD)}
            />
          </Box>
        </Container>
      </Box>
    </div>
  );
};
export default ForgotPassword;
