import { Link } from "@material-ui/core";
import React from "react";
const content = {
  English: {
    banner: "Build a website for your business today",
    subheader:
      "Welcome to Sentro, the Philippines’ first homegrown online shop builder. Sell online, manage inventory, accept payments and book deliveries in one platform today.",
    bannerButton: "Get started for free",
    sectionHeader1: "Build your online shop with our easy-to-use shop builder",
    leftContentTitle: "Simple yet effective",
    rightContentTitle: "Design and manage",
    leftContent:
      "Sentro provides a more spacious and customizable home for your products online. We make selling easy for you so you can focus more on growing your business.",
    rightContent:
      "Show off your brand's unique identity using our stylish templates and themes. Track orders and sales on-the-go with our mobile and desktop friendly dashboard.",
    contentTitle1: "Build a complete website fast",
    contentBody1:
      "Easily create a fully-functioning online store, complete with payments and logistics, using any device. No need to search for third party plugins!",
    contentTitle2: "Offer multiple payment options",
    contentBody2: (
      <div>
        Give customers access to over 50,000 over-the-counter locations, mobile
        wallets, online banking and more with Sentro’s built-in payment gateway
        feature powered by{" "}
        <Link
          onClick={() =>
            window.open(
              `https://bux.ph/?&utm_source=pr&utm_medium=media&utm_campaign=sentroshopify`
            )
          }
          color="primary"
        >
          BUx
        </Link>
        .
      </div>
    ),
    contentTitle3: "Enjoy built-in delivery services",
    contentBody3:
      "Deliver your products to hundreds and thousands of customers in Luzon, Visayas and Mindanao through Sentro’s integrated courier services powered by Borzo and NinjaVan.",
    contentTitle4: "Connect your shop on social media",
    contentBody4:
      "Promote your products by connecting your Sentro shop to Facebook and Instagram. Easily tag and showcase your shop to your social media community.",
    sectionHeader2: "Sentro brings instant access to industry-leading partners",
    sectionBody2:
      "We make things easier so you can focus more on engaging with your customers.",
    bottomSectionTitle: "Centered on your business’ ultimate success",
    bottomSectionBody: "Build your online shop for free today",
    bottomSectionButton: "SIGN UP"
  },

  Filipino: {
    banner: "Naka-Sentro sa iyong pag-asenso. Gumawa ng online shop.",
    subheader:
      "Kahit gaano pa kaliit ang business mo, papahalagahan ka pa rin namin! Tutulungan ka ng Sentro na maging online ang business mo at makapagbenta. 'Di kailangang techie ka. Libre pa 'to! Oh 'di ba, kabog!",
    bannerButton: "Magsimula Na",
    sectionHeader1: "Kunting kembot lang at online na ang business mo",
    leftContentTitle:
      "Simple lang kami - walang baggage, walang naghahabol na past",
    rightContentTitle: "Madali at praktikal - hindi high maintenance",
    leftContent:
      "Hindi ka na imbyerna sa maraming mga chenes dahil mabilis lang gumawa ng online shop at makapagbebenta ka agad-agad - as in now na!",
    rightContent:
      "Pasok sa banga ang mga maliliit na negosyante sa aming mga tools upang makabenta at makatanggap agad kayo ng bayad online",
    contentTitle1: "Pumili ng style na kaya mong ipagmalaki",
    contentBody1:
      "Mas importante sa amin ang maipagmalaki ka at ang mga binebenta mo",
    contentTitle2:
      "Ipakita ang iyong mga binebenta (gaya nung bag na binigay ng ex mo)",
    contentBody2:
      "Hindi kami maghahanap ng hindi mo kayang ibigay. Sapat na sa amin kung ano ang meron ka. Dahil sa Sentro, mga importanteng bagay lang ang hihingin namin para mas mapadali ang paggawa mo ng online shop (Sentro pa rin pinag-uusapan natin ah)",
    contentTitle3:
      "Hindi mo na kailangang maghanap ng paraan dahil dito marami kang payment options",
    contentBody3:
      "Mas marami nang makakapansin sa online shop mo dahil ang dami mong options options options!!!! Tumanggap ng cash, over-the-counter at online payments agad-agad. ('Di lang namin tinatanggap yung mga bumabalik na toxic na ex)",
    contentTitle4: "Madaling pagpapadala ng produkto",
    contentBody4:
      "Pagkatapos magbayad, syempre may follow through, may closure. Kapag gumawa ka ng Sentro online shop, connected ka na sa aming mga delivery partners. Piliin mo na lang kung sinong delivery ang bet mo",
    sectionHeader2: "Puro havey ang aming mga partners",
    sectionBody2:
      "Para ang iisipin mo na lang ay ang alindog program ng iyong online shop at paghahanap ng mas maraming customers",
    bottomSectionTitle: "Naka-Sentro sa iyong pag-asenso",
    bottomSectionBody: "Gumawa na ng online shop ngayon",
    bottomSectionButton: "Magsimula Na"
  }
};
export default content;
