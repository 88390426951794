import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import TextField from '@material-ui/core/TextField';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Actions from "../../../Redux/Actions";
import CircularProgress from '@material-ui/core/CircularProgress';

import GalleryImageUpload from '../galleryImageUpload';

const useStyles = theme => ({
  root: {
    marginBottom: "1em",
    textAlign: "center",
    "& p.noVariations": {
      fontSize: 14,
      marginBottom: 0,
      padding: "1em",
      fontWeight: 'bold',
      color: 'gray'
    }
  },
  field: {
    fontSize: 12,
    margin: "1.5em 0 0",
    maxWidth: '50%',
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
    },
  },
  title: {
    color: "#54575F",
    fontSize: 16,
    fontWeight: 600
  },
  description: {
    color: "#54575F",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 0
  },
  divider: {
    margin: "1em 0"
  },
  addButtonDiv: {
    height: "100%",
    display: "flex",
    minHeight: 45,
    border: "1px dashed #d0d0d0",
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 0.2s ease",
    cursor: "pointer",
    "&:hover": {
      background: '#f976631a'
    },
    "& > p": {
      marginBottom: 0,
      marginLeft: 5,
      color: "#f97663",
      fontWeight: "bold"
    },
    "& > svg": {
      color: "#f97663",
    }
  },
  modalDiv: {
    flexDirection: "column",
    padding: "2.5em",
    // minWidth: 500,
    [theme.breakpoints.down("xs")]: {
      // minWidth: "auto",
    },
  },
  modalTile: {
    fontSize: 26,
    color: "#2B2D33",
    fontWeight: 600,
    // marginBottom: 25,
    lineHeight: "1em"
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    color: "#54575F",
    marginBottom: 25
  },
  modalActionsWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  modalActions: {
    minWidth: 100,
    padding: "0 1.5em",
    height: 35,
    fontSize: 14,
    fontWeight: 600
  },
  actionIcons: {
    fontSize: 20,
    margin: 3,
    color: 'gray',
    cursor: 'pointer'
  },
  actionDiv: {
    opacity: 0,
    transition: "all 0.2s ease",
    position: 'absolute',
    top: 0,
    right: 0,
    "& .edit .delete": {
      height: '20px',
      color: 'gray',
      cursor: 'pointer'
    }
  },
  appCard: {
    height: "100%",
    padding: "1em",
    border: "1px solid #D1D5DD",
    borderRadius: 4,
    position: 'relative',
    "& svg": {
      height: '15px',
    },
    "&:hover": {
      "& .actionDiv": {
        opacity: 1
      }
    },
  }
});

class AppCards extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dialogStat: false,
      dialogType: "add",
      targetIndex: 0,
      // fields
      logoFile: "",
      logo: "",
      name: "",
      description: ""
    }
    this.setDialogState = this.setDialogState.bind(this)
    this.textFieldOnChange = this.textFieldOnChange.bind(this)
    this.handleChangeBillingTimeframe = this.handleChangeBillingTimeframe.bind(this)
    this.add = this.add.bind(this)
    this.edit = this.edit.bind(this)
    this.delete = this.delete.bind(this)
    this.resetFields = this.resetFields.bind(this)
    this.logoOnChange = this.logoOnChange.bind(this)
  }

  setDialogState(dialogStat, dialogType="add", targetIndex=0){
    this.setState({
      dialogStat,
      dialogType,
      targetIndex,
    })
    if (dialogType === "edit"){
      this.setState({
        name: this.props.data[targetIndex].name,
        description: this.props.data[targetIndex].description,
        logo: this.props.data[targetIndex].logo,
        logoFile: this.props.data[targetIndex].logoFile
      })
    }
    if(!dialogStat) this.resetFields()
  }

  textFieldOnChange(event, fieldname){
    this.setState({
      ...this.state,
      [fieldname]: event.target.value 
    }, () => {
      console.log(this.state)
    })
  }

  handleChangeBillingTimeframe = (event) => {
    this.setState({
      variation: {
        ...this.state.variation,
        billingTimeframe: event.target.value
      } 
    });
  };

  add = async() => {
    const { dispatch } = this.props;
    const logo_url = await dispatch(Actions.uploadS3(this.state.logoFile, `/app_cards/${this.state.logoFile.name}`))
    const cards = this.props.data;
    cards.push({
      name: this.state.name,
      logo: logo_url,
      description: this.state.description,
    });
    this.props.updateCard(cards);
    this.resetFields();
  }

  edit = async(index) => {
    const { dispatch } = this.props;
    const logo_url = this.state.logoFile ? 
    await dispatch(Actions.uploadS3(this.state.logoFile, `/app_cards/${this.state.logoFile.name}`)):
    this.state.logo ? this.state.logo : ``;
    let data = this.props.data;
    data[index]['name'] = this.state.name;
    data[index]['logo'] = logo_url;
    data[index]['description'] = this.state.description;
    this.props.updateCard(data);
    this.resetFields();
  }

  delete(index, type){
    let data = this.props.data;
    data.splice(index, 1);
    this.props.updateCard(data);
    this.resetFields();
  }

  resetFields(){
    this.setState({
      dialogStat: false,
      logoFile: "",
      logo: "",
      name: "",
      description: ""
    });
  }

  logoOnChange(file){
    if (file[0]){
      this.setState({
        logoFile: file[0]
      })
    } else {
      this.setState({
        logoFile: ''
      })
    }
  }

  render (){
    const { classes, data, modalLoading, readOnly } = this.props;
    const { dialogStat, dialogType, targetIndex } = this.state;

    const dialogAction = (index=0) => {
      switch(dialogType){
        case "add":
          return this.add()
        case "edit": 
          return this.edit(index)
        case "delete":
          return this.delete(index)
        default:
          return this.add()
      }
    }

    const dialogTitle = () => {
      switch(dialogType){
        case "add":
          return `Add`
        case "edit": 
          return `Edit`
        case "delete":
          return `Delete`
        default:
          return `Add`
      }
    }

    const dialogContent = () => {
      switch(dialogType){
        case "delete":
          return `Delete Item?`
        default:
          return (
            <div className={classes.modalBody}>
              <GalleryImageUpload onChange={this.logoOnChange} singleImage={true} preview={this.state.logo} isRequired/>
              <TextField
                onChange={(event) => this.textFieldOnChange(event, "name")}
                label="Item Name"
                type="text"
                margin="dense"
                placeholder="Item Name"
                variant="outlined"
                value={this.state.name}
              />
              <TextField
                onChange={(event) => this.textFieldOnChange(event, "description")}
                label="Description"
                margin="dense"
                placeholder="Description"
                variant="outlined"
                multiline
                rows={3}
                value={this.state.description}
              />
            </div>
          )
      }
    }

    return (
      <>
        <Dialog
          open={dialogStat}
          onClose={(event) => {
            modalLoading? console.log("Uploading. Can't close."):
            this.setDialogState(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth={true}
        >	
          <div className={classes.modalDiv}>
            <div className={classes.modalTile}>
              {dialogTitle()}
            </div>
            {dialogContent()}
            <div className={classes.modalActionsWrapper}>
              <Button
                id="action_button"
                variant="outlined"
                onClick={(event) => {
                  this.setDialogState(false)
                  this.resetFields()
                }} 
                color="primary"
                margin="dense"
                className={classes.modalActions}
                style={{ marginLeft: "auto" }}
              >
                Cancel
              </Button>
              <Button
                id="action_button"
                variant="contained"
                onClick={(event) => {
                  console.log(dialogAction)
                  dialogAction(targetIndex)
                }}
                color="primary"
                margin="dense"
                className={classes.modalActions}
                style={{ marginLeft: "1em" }}
                disabled={modalLoading}
              >
                {modalLoading ? <>
                  <CircularProgress color="secondary" style={{height: 20, width: 20, color: "white", marginRight: 5}}/> 
                  <span>Uploading...</span> 
                </>: dialogTitle()}
              </Button>
            </div>
          </div>
        </Dialog>
        <div className={classes.root}>
          <Grid container spacing={1} justify="center">
            {
              data ?
              data.map((app, index) => (
                <Grid key={index} item sm={12} md={4}>
                  <div className={classes.appCard} key={index}>
                    {
                      (!readOnly && true)  && 
                      <div className={`${classes.actionIcons} ${classes.actionDiv} actionDiv`}>
                        <EditOutlinedIcon className={`${classes.actionIcons} edit`}
                        onClick={() => {  this.setDialogState(true, "edit", index) }}/>
                        <HighlightOffIcon className={`${classes.actionIcons} delete`} color="disabled" 
                        onClick={() => this.setDialogState(true, "delete", index)}/>
                      </div>
                    }
                    <img src={app.logo} height={80}/>
                    <p className={classes.title}>{app.name}</p>
                    {
                      app ?
                      app.description.split("\n").map((text, index) => (
                          <p key={index} className={classes.description}>{text}</p>
                      ))
                      :
                      ``
                    }
                  </div>
                </Grid>
              ))
              :
              ``
            }
            {
              (!readOnly && true) && 
              <Grid item sm={12} md={4}>
                <div className={`${classes.addButtonDiv}`}  onClick={() => {this.setDialogState(true)}}>
                  <AddCircleOutlineIcon /><p>Add Item</p>
                </div>
              </Grid>
            }
          </Grid>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ adminDashboard }) => ({ 
  modalLoading: adminDashboard.modalLoading
});
export default connect(mapStateToProps)(withStyles(useStyles)(AppCards));

