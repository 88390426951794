import { makeStyles } from "@material-ui/core";
import banner from "../../assets/images/banner2.png";
const ProductContentStyles = makeStyles(theme => ({
  banner: {
    width: "100%",
    overflow: "hidden",
    height: 400,
    background:
      "linear-gradient(90deg, rgba(70,9,92,1) 0%, rgba(197,76,99,1) 50%, rgba(255,156,142,1) 100%)",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      height: 500
    }
  },

  container: {
    textAlign: "center",
    paddingTop: 107,
    paddingLeft: "34vw",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    }
  },

  container2: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    }
  },
  bannerImage: {
    width: 400,
    height: 500,
    position: "absolute",
    margin: "5%",
    top: 0,
    left: 0,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner})`,
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  title: {
    fontWeight: 600,
    color: "#fff"
  },
  search: {
    width: "100%",
    position: "relative",
    marginTop: "4vh",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "3vh"
    }
  },

  inputSearch: {
    width: "30vw",
    height: "50px",
    borderRadius: "5px",
    color: "#000",
    border: "none",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    }
  },

  searchBtn: {
    width: "50px",
    height: "50px",
    textAlign: "center",
    borderRadius: "5px 0 0 5px",
    cursor: "pointer",
    backgroundColor: "#fff"
  },
  searchBtn2: {
    border: "none",
    color: "white",
    fontSize: "16px",
    fontWeight: "600",
    padding: "7px 40px",
    height: "50px",
    backgroundColor: "#1E4B83",
    marginLeft: "1vw",
    marginTop: "2vh",
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    }
  },
  content: {
    marginTop: "4vh",
    marginLeft: "8vw"
  },

  explore: {
    fontWeight: 600,
    fontSize: "30px"
  },
  ul: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "89%"
    }
  },
  sidebar: {
    marginTop: "2vh",
    flexGrow: "4",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  link: {
    textDecoration: "none",
    color: "#000",
    fontSize: "17px",
    [theme.breakpoints.down("sm")]: {
      padding: 50,
      fontSize: "20px"
    }
  },
  products: {
    marginTop: "3vh",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },

  productContent: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginTop: "1vh",
    // flexGrow: 10,
    // paddingLeft: "3vw",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      display: "block"
    }
  },

  product: {
    cursor: "pointer",
    padding: theme.spacing(2, 1),
    width: "15vw",
    marginRight: "1vw",
    marginBottom: "10vh",
    border: "1px solid #d2d2d2",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "95%"
    }
  },

  image: {
    height: "25vh",
    [theme.breakpoints.down("sm")]: {
      height: "45vh"
    }
  }
}));

export default ProductContentStyles;
