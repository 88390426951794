import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Button } from '@material-ui/core';
import ProxteraMap from '../../../../assets/images/proxtera/map.png';

const useStyles = theme => ({
  paper: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
  },
  imgContainer: {
    width: 210,
    height: 210
  },
  title: {
    fontWeight: "bold",
    fontSize: 28
  },
  description: {
    color: "#54575F",
    marginBottom: "2em"
  }
});

class CardHead extends React.Component {

  render (){
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <div className="d-flex p-4 text-align-center">
          <div className="px-4">
            <div className={classes.imgContainer} style={{
              backgroundImage: `url(${ProxteraMap})`,
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}/>
          </div>
          <div className="p-3 px-5 text-center flex-grow-1 flex-direction-column d-flex">
            <div className="my-auto w-100">
              <p className={classes.description}>Go global. Sell and export 
              your items to other countries or look for<br /> 
              the best trade deals in the ASEAN region and import them.</p>
              <div className="d-flex">
                <Button
                    id="start_selling" 
                    variant="outlined" 
                    color="primary" 
                    disableElevation 
                    className="d-flex flex-grow-1"
                    onClick={() => this.props.onStart ? this.props.onStart(): ``}
                >
                    Start Selling
                </Button>
                <Button
                    id="browse_items" 
                    variant="outlined" 
                    color="primary" 
                    disableElevation 
                    className="ml-3 flex-grow-1"
                    onClick={() => this.props.onBrowse ? this.props.onBrowse(): ``}
                >
                    Browse Items
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    )
  }
}
export default withStyles(useStyles)(CardHead);

