import React from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './getCroppedImage'
import SentroButton from "../../../common/sentroButton";

 
export default class ImageCropper extends React.Component {
  state = {
    image: this.props.image,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1 / 1,
    croppedAreaPixels: null
	}
	
  onCropChange = crop => {
    this.setState({ crop })
  }
 
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({croppedAreaPixels})
  }

  submit = async () => {
		const croppedImage = await getCroppedImg(
			this.props.image,
			this.state.croppedAreaPixels,
			0,
			this.props.filename
		)
		this.props.onSave(croppedImage)
  }
 
  onZoomChange = zoom => {
    this.setState({ zoom })
  }
 
  render() {
    return (
        <div>
          <div style={{ width: '100%', height: '80vh', position: 'relative' }}>
            <Cropper
              image={this.state.image}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={this.state.aspect}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
            />
          </div>
          <div style={{ display: 'flex', margin: "2em 0" }}>
            <SentroButton
              id="ok_crop_button"
              style={{
                padding: "0px 25px",
                marginLeft: 'auto'
              }}
              variant="outlined"
              onClick={() => this.submit()}
              label="Save Image"
            />
          </div>
        </div>
    )
  }
}