import Services from "./Services";

export const ActionTypes = {
	SET_MENU_ACTIVE: "SET_MENU_ACTIVE",
	// date filter
	SET_DATE_FILTER: "SET_DATE_FILTER",
	// getting current user
	FETCHING_CURRENT_USER_DATA: "FETCHING_CURRENT_USER_DATA",
	FETCH_CURRENT_USER_SUCCESS: "FETCH_CURRENT_USER_SUCCESS",
	FETCH_CURRENT_USER_FAILED: "FETCH_CURRENT_USER_FAILED",
	// Subscription list
	FETCHING_SUBSCRIPTIONS: "FETCHING_SUBSCRIPTIONS",
	FETCHING_SUBSCRIPTIONS_SUCCESS: "FETCHING_SUBSCRIPTIONS_SUCCESS",
	// Setting fetch status
	REQUEST_SUCCESS: "REQUEST_SUCCESS",
	REQUEST_FAILED: "REQUEST_FAILED"
};

function setUserRoles(current_user) {
	const { is_admin, is_superuser } = current_user
	let user_roles = []
	if (is_admin) {
		user_roles.push("admin");
	}
	if (is_superuser) {
		user_roles.push("superuser");
	}
	return user_roles
}

class Actions {

	static setMenuActive(menu){
		return dispatch => {
			dispatch({
				type: ActionTypes.SET_MENU_ACTIVE,
				payload: menu
			})
		}
	}

	static setDateFilter(filter) {
		return async dispatch => {
			dispatch({ 
				type: ActionTypes.SET_DATE_FILTER,
				payload: filter
			});
		}
	}

	static loadCurrentUser() {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_CURRENT_USER_DATA });
				let response = await Services.loadCurrentUser();
				if (response.data){
					dispatch({
						type: ActionTypes.FETCH_CURRENT_USER_SUCCESS,
						payload: response.data,
						user_roles: setUserRoles(response.data)
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.FETCH_CURRENT_USER_FAILED,
					payload: error
				});
				return error
			}
		};
	}

	static fetchSubscriptions(query_params) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_SUBSCRIPTIONS });
				let response = await Services.fetchSubscriptions(query_params);
				if (response){
					dispatch({
						type: ActionTypes.FETCHING_SUBSCRIPTIONS_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}
}

export default Actions;
