import DashboardServices from "../services/dashboardServices";

export const DashboardActionTypes = {
  GET_FONTS: "GET_FONTS",
  SET_CUSTOMIZE_ERRORS: "SET_CUSTOMIZE_ERRORS",
  SET_ERRORS_VISIBLE: "SET_ERRORS_VISIBLE",
  SET_NOTIFICATION_POPUP: "SET_NOTIFICATION_POPUP",
  PATCH_MARKETPLACE: "PATCH_MARKETPLACE",
  PATCH_MARKETPLACE_SUCCESS: "PATCH_MARKETPLACE_SUCCESS",
  SET_MERCHANT_FILTERS: "SET_MERCHANT_FILTERS",
  GET_MERCHANTS_ADMIN_PAGE: "GET_MERCHANTS_ADMIN_PAGE",
  GET_MERCHANTS_SUCCESS_ADMIN_PAGE: "GET_MERCHANTS_SUCCESS_ADMIN_PAGE",
  PATCH_MERCHANTS_ADMIN_PAGE: "PATCH_MERCHANTS_ADMIN_PAGE",
  PATCH_MERCHANTS_SUCCESS_ADMIN_PAGE: "PATCH_MERCHANTS_SUCCESS_ADMIN_PAGE",
  PATCH_MERCHANTS_FAILED_ADMIN_PAGE: "PATCH_MERCHANTS_FAILED_ADMIN_PAGE",
  DELETE_MERCHANT_ADMIN_PAGE: "DELETE_MERCHANT_ADMIN_PAGE",
  DELETE_MERCHANT_SUCCESS_ADMIN_PAGE: "DELETE_MERCHANT_SUCCESS_ADMIN_PAGE",
  DELETE_MERCHANT_FAILED_ADMIN_PAGE: "DELETE_MERCHANT_FAILED_ADMIN_PAGE",
  UPLOAD_S3: "UPLOAD_S3",
  UPLOAD_S3_SUCCESS: "UPLOAD_S3_SUCCESS",
  SET_MANAGE_MARKETPLACE_CATEGORY_PAGE: "SET_MANAGE_MARKETPLACE_CATEGORY_PAGE",
  GLOBAL_CATEGORY_DELETE_SUCCESS: "GLOBAL_CATEGORY_DELETE_SUCCESS",
  GLOBAL_CATEGORY_LOADING: "GLOBAL_CATEGORY_LOADING",
  GLOBAL_CATEGORY_PATCH_SUCCESS: "GLOBAL_CATEGORY_PATCH_SUCCESS",
  GLOBAL_CATEGORY_CREATE_SUCCESS: "GLOBAL_CATEGORY_CREATE_SUCCESS",
  SET_BUYER_FILTERS: "SET_BUYER_FILTERS",
  GET_BUYERS_ADMIN_PAGE: "GET_BUYERS_ADMIN_PAGE",
  GET_BUYERS_SUCCESS_ADMIN_PAGE: "GET_BUYERS_SUCCESS_ADMIN_PAGE",
  SET_PROFILE_LOADING: "SET_PROFILE_LOADING",
  PATCH_USER_SUCCESS: "PATCH_USER_SUCCESS",
  GET_ANALYTICS: "GET_ANALYTICS"
};

class DashboardActions {
  static getFonts(category) {
    return async dispatch => {
      try {
        let data = await DashboardServices.getFonts();
        if (data) {
          dispatch({
            type: DashboardActionTypes.GET_FONTS,
            payload: data.items
              .map(item => {
                // construct css query url
                let apiUrl = [];
                apiUrl.push("https://fonts.googleapis.com/css?family=");
                apiUrl.push(item.family.replace(/ /g, "+"));
                // if (item.variants.includes('italic')) {
                //   apiUrl.push(':');
                //   apiUrl.push('italic');
                // }
                apiUrl.push(":wght@600");
                if (item.subsets.includes("greek")) {
                  apiUrl.push("&subset=");
                  apiUrl.push("greek");
                }
                let css_url = apiUrl.join("");
                return {
                  ...item,
                  css_url
                };
              })
              .filter(item => item.category === category)
          });
        }
        return data;
      } catch (error) {}
    };
  }

  static setErrors(panel, errors = []) {
    return async dispatch => {
      dispatch({
        type: DashboardActionTypes.SET_CUSTOMIZE_ERRORS,
        payload: errors,
        panel
      });
    };
  }

  static setErrorsVisible(form, val) {
    return async dispatch => {
      dispatch({
        type: DashboardActionTypes.SET_ERRORS_VISIBLE,
        payload: val,
        form
      });
    };
  }

  static patchMarketplace(enterprise, data) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.PATCH_MARKETPLACE });
        let res = await DashboardServices.patchMarketplace(enterprise, data);
        if (res) {
          dispatch({
            type: DashboardActionTypes.PATCH_MARKETPLACE_SUCCESS,
            payload: res
          });
        }
        return res;
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
        dispatch({ type: DashboardActionTypes.PATCH_MARKETPLACE_SUCCESS });
        return false;
      }
    };
  }

  static favoriteGlobalCategory(id, marketplace, is_favorite) {
    return async dispatch => {
      // try {
      let res = await DashboardServices.favoriteGlobalCategory(
        id,
        marketplace,
        is_favorite
      );
      if (res) {
        console.log(res);
      }
      return res;
      // } catch (error) {
      // }
    };
  }

  static setErrorPopup(payload) {
    return async dispatch => {
      dispatch({
        type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
        payload
      });
    };
  }

  static setMerchantFilters(filters) {
    return async dispatch => {
      dispatch({
        type: DashboardActionTypes.SET_MERCHANT_FILTERS,
        payload: filters
      });
    };
  }

  static getMerchants(enterprise) {
    return dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.GET_MERCHANTS_ADMIN_PAGE });
        DashboardServices.getMerchants(enterprise).then(res => {
          dispatch({
            type: DashboardActionTypes.GET_MERCHANTS_SUCCESS_ADMIN_PAGE,
            payload: res
          });
        });
      } catch (err) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
      }
    };
  }

  static patchMerchant(data) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.PATCH_MERCHANTS_ADMIN_PAGE });
        let res = await DashboardServices.patchMerchant(data);
        if (res) {
          dispatch({
            type: DashboardActionTypes.PATCH_MERCHANTS_SUCCESS_ADMIN_PAGE,
            payload: res
          });
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "success",
              message: "Changes saved"
            }
          });
        }
        return res;
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
        dispatch({
          type: DashboardActionTypes.PATCH_MERCHANTS_FAILED_ADMIN_PAGE
        });
        return false;
      }
    };
  }

  static uploadS3(
    field,
    file,
    callbackError = false,
    base_folder = "custom_links"
  ) {
    return async dispatch => {
      try {
        dispatch({
          type: DashboardActionTypes.UPLOAD_S3,
          payload: field
        });
        const path = `${base_folder}/${file.name}`;
        const signed_url_data = await DashboardServices.createS3PreSignedUrl(
          path
        );
        const response = await DashboardServices.uploadS3(
          signed_url_data,
          file
        );
        if (response) {
          dispatch({
            type: DashboardActionTypes.UPLOAD_S3_SUCCESS,
            payload: field
          });
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "success",
              message: "Image uploaded"
            }
          });
        }
        return response;
      } catch {
        if (callbackError) callbackError();
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message:
              "An error has occured when uploading the icon. Please try again."
          }
        });
        dispatch({
          type: DashboardActionTypes.UPLOAD_S3_SUCCESS,
          payload: field
        });
      }
    };
  }

  static deleteMerchant(data) {
    return async dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.DELETE_MERCHANT_ADMIN_PAGE });
        let res = await DashboardServices.deleteMerchant(data);
        if (res) {
          dispatch({
            type: DashboardActionTypes.DELETE_MERCHANT_SUCCESS_ADMIN_PAGE,
            payload: data.id
          });
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "success",
              message: "Changes saved"
            }
          });
        }
        return res;
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
        dispatch({
          type: DashboardActionTypes.DELETE_MERCHANT_FAILED_ADMIN_PAGE
        });
        return false;
      }
    };
  }

  static inviteMerchant(enterprise, params) {
    return async dispatch => {
      try {
        let res = await DashboardServices.inviteMerchant(enterprise, params);
        if (res.status === 200) {
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "success",
              message: "Email sent"
            }
          });
        }
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
      }
    };
  }

  static setManageMarketplaceCategoryPage(payload) {
    return async dispatch => {
      dispatch({
        type: DashboardActionTypes.SET_MANAGE_MARKETPLACE_CATEGORY_PAGE,
        payload
      });
    };
  }

  static setBuyerFilters(filters) {
    return async dispatch => {
      dispatch({
        type: DashboardActionTypes.SET_BUYER_FILTERS,
        payload: filters
      });
    };
  }

  static deleteGlobalCategory(cat, payload) {
    return async dispatch => {
      try {
        let res = await DashboardServices.deleteGlobalCategory(cat.id, payload);
        if (res) {
          dispatch({
            type: DashboardActionTypes.GLOBAL_CATEGORY_DELETE_SUCCESS,
            payload: cat.id
          });
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "success",
              message: "Category deleted"
            }
          });
        }
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
      }
    };
  }

  static patchGlobalCategory(id, payload) {
    return async dispatch => {
      try {
        dispatch({
          type: DashboardActionTypes.GLOBAL_CATEGORY_LOADING,
          payload: true
        });
        let res = await DashboardServices.patchGlobalCategory(id, payload);
        if (res.data) {
          dispatch({
            type: DashboardActionTypes.GLOBAL_CATEGORY_PATCH_SUCCESS,
            payload: res.data
          });
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "success",
              message: "Changes saved"
            }
          });
        }
        dispatch({
          type: DashboardActionTypes.GLOBAL_CATEGORY_LOADING,
          payload: false
        });
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.GLOBAL_CATEGORY_LOADING,
          payload: false
        });
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
      }
    };
  }

  static createGlobalCategory(payload) {
    return async dispatch => {
      try {
        dispatch({
          type: DashboardActionTypes.GLOBAL_CATEGORY_LOADING,
          payload: true
        });
        let res = await DashboardServices.createGlobalCategory(payload);
        if (res.data) {
          dispatch({
            type: DashboardActionTypes.GLOBAL_CATEGORY_CREATE_SUCCESS,
            payload: res.data
          });
          dispatch({
            type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
            payload: {
              open: true,
              alertType: "success",
              message: "Category created"
            }
          });
        }
        dispatch({
          type: DashboardActionTypes.GLOBAL_CATEGORY_LOADING,
          payload: false
        });
      } catch (error) {
        dispatch({
          type: DashboardActionTypes.GLOBAL_CATEGORY_LOADING,
          payload: false
        });
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
      }
    };
  }

  static getBuyers(enterprise, filters) {
    return dispatch => {
      try {
        dispatch({ type: DashboardActionTypes.GET_BUYERS_ADMIN_PAGE });
        DashboardServices.getBuyers(enterprise, filters).then(res => {
          dispatch({
            type: DashboardActionTypes.GET_BUYERS_SUCCESS_ADMIN_PAGE,
            payload: res
          });
        });
      } catch (err) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
      }
    };
  }

  static patchProfile(enterprise_name, data, pass_data) {
    return async dispatch => {
      try {
        dispatch({
          type: DashboardActionTypes.SET_PROFILE_LOADING,
          payload: true
        });
        let res = await DashboardServices.patchProfile(data);
        const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
        sessionStorage.setItem(
          "CURRENT_USER",
          JSON.stringify({
            ...user,
            ...res
          })
        );
        if (Object.keys(pass_data).length) {
          res = await DashboardServices.changePassword(
            enterprise_name,
            pass_data
          );
        }
        dispatch({
          type: DashboardActionTypes.SET_PROFILE_LOADING,
          payload: false
        });
        return true;
      } catch (err) {
        dispatch({
          type: DashboardActionTypes.SET_PROFILE_LOADING,
          payload: false
        });
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
        if (err.response) {
          if (Object.keys(err.response.data).includes("old_password")) {
            return { old_password: "Invalid password. Please try again." };
          }
        }
      }
    };
  }

  static getAnalytics(enterprise_name) {
    return async dispatch => {
      try {
        let res = await DashboardServices.getAnalytics(enterprise_name);
        dispatch({
          type: DashboardActionTypes.GET_ANALYTICS,
          payload: res.data
        });
      } catch (err) {
        dispatch({
          type: DashboardActionTypes.SET_NOTIFICATION_POPUP,
          payload: {
            open: true,
            alertType: "error",
            message: "An error has occured. Please try again."
          }
        });
      }
    };
  }
}

export default DashboardActions;

export const ReduxInitializeFormActionTypes = {
  LOAD_MANAGE_MARKETPACE_DATA: "LOAD_MANAGE_MARKETPACE_DATA",
  LOAD_CATEGORY_FORM_DATA: "LOAD_CATEGORY_FORM_DATA",
  LOAD_PROFILE_FORM_DATA: "LOAD_PROFILE_FORM_DATA"
};
export class ReduxInitializeFormActions {
  static initializeManageMarketplaceData(data) {
    return {
      type: ReduxInitializeFormActionTypes.LOAD_MANAGE_MARKETPACE_DATA,
      payload: data
    };
  }
  static initializeCategoryFormData(data) {
    return {
      type: ReduxInitializeFormActionTypes.LOAD_CATEGORY_FORM_DATA,
      payload: data
    };
  }
  static initializeProfileData(data) {
    return {
      type: ReduxInitializeFormActionTypes.LOAD_PROFILE_FORM_DATA,
      payload: data
    };
  }
}
