import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Tabs,
  AppBar,
  Tab,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DashboardLayout from "../../../dashboard/dashboardLayout";
import SentroLoading from "../../../common/loading";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Text from "../../../../common/text";
import fbIcon from "../../../../assets/images/icons/fb.png";
import instagramIcon from "../../../../assets/images/icons/insta.png";
import shopeeIcon from "../../../../assets/images/icons/shopee.png";
import lazadaIcon from "../../../../assets/images/icons/lazada.png";
import kumuIcon from "../../../../assets/images/icons/kumu.png";
import DashboardActions from "../../../dashboard/dashboardActions";
import history from "../../../../store/history";
import Path from "../../../../Paths";
import {
  getProducts,
  patchStore,
  getStoreInfo,
} from "../../websiteBuilderActions";
import apiConfig from "../../../../common/apiConfig";
import axios from "axios";
import validations from "../../../common/validations";
import SideMenu from "../../../dashboard/sideMenu";
import PreviewShop from "./previewShop";
import OTPActions from "../../../otp/redux/actions";
import OTPModal from "../../../otp/OTPModal";
import LanguageIcon from "@material-ui/icons/Language";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "24px 50px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  shopDetails: {
    marginTop: "3vh",
    backgroundColor: "#fff",
    border: "1px solid #d1d5dd",
    borderRadius: 10,
  },
  appbar: {
    backgroundColor: "#fff",
    color: "#f97663",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd",
    borderRadius: "10px 10px 0 0",
  },
  label1: {
    fontSize: 16,
    color: "#002c42",
    fontWeight: 600,
  },
  label2: {
    fontSize: 14,
    fontWeight: 600,
    color: "#54575f",
  },
  label3: {
    fontSize: 12,
    color: "#54575f",
  },
  side: { zIndex: 3, position: "fixed", top: 0, left: 0, bottom: 0 },
  content: {
    width: "100%",
    paddingLeft: "8vw",
    paddingRight: "8vw",
    paddingBottom: "10vh",
  },
}));

const AlertBox = (props) => {
  const classes = useStyles();
  const { values, closeAlertBox } = props;
  return (
    <Snackbar
      open={values.alertBox}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => closeAlertBox()}
    >
      <Alert onClose={() => closeAlertBox()} severity={values.alertType}>
        {values.alertMessage}
      </Alert>
    </Snackbar>
  );
};

const ShopDetailsForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  let getLanguage = localStorage.getItem("language");
  let [language, setLanguage] = React.useState(getLanguage);
  const loading = useSelector((state) => state.webBuilder.loading);
  let currentStoreCreated = useSelector((state) => state.webBuilder.storeInfo);
  let store = useSelector((state) => state.webBuilder.stores);
  let productList = useSelector((state) => state.webBuilder.products);
  let isEdit = useSelector((state) => state.webBuilder.isEdit);
  let otp = useSelector((state) => state.otp);
  localStorage.setItem(
    "STORE_UUID",
    currentStoreCreated ? currentStoreCreated.uuid : ""
  );
  let STORE_UUID = localStorage.getItem("STORE_UUID");
  const [value, setValue] = React.useState(0);
  const [hasErrors, setHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState({
    nameError: "",
    siteError: "",
    sloganError: "",
    descriptionError: "",
    addressError: "",
    mobileError: "",
    emailError: "",
    orderInstructionError: "",
  });
  const [values, setValues] = React.useState({
    owner: user.id,
    name: "",
    template: "Classic",
    description: "",
    slogan: "",
    site: "",
    address: "",
    email: "",
    mobile: "",
    landline: "",
    facebook: "",
    instagram: "",
    shopee: "",
    lazada: "",
    kumu: "",
    color: "",
    logo_image: "",
    cover_image: "",
    aboutus_image: "",
    contactus_image: "",
    payment_deadline: 24,
    is_published: false,
    uuid: STORE_UUID,
    image_settings: {},
    fb_page_id: "",
    alertBox: false,
    alertType: "success",
    alertMessage: "",
  });

  const field_max_length = {
    name: 30,
    site: 25,
    slogan: 100,
    address: 100,
    mobile: 11,
    landline: 30,
    email: 128,
    facebook: 32,
    instagram: 32,
    shopee: 32,
    lazada: 32,
    kumu: 32,
    order_instructions: 500,
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const submit = async () => {
    if (error.nameError || error.siteError) {
      setValues({
        ...values,
        alertBox: true,
        alertType: "error",
        alertMessage: "Please nominate a valid shop name and/or website",
      });
    } else {
      const res = await dispatch(
        patchStore(
          "info",
          currentStoreCreated ? currentStoreCreated.site : "",
          {
            name: values.name,
            site: values.site,
            slogan: values.slogan,
            description: values.description,
            address: values.address,
            mobile: values.mobile,
            landline: values.landline,
            email: values.email,
            facebook: values.facebook,
            instagram: values.instagram,
            shopee: values.shopee,
            lazada: values.lazada,
            kumu: values.kumu,
            order_instructions: values.order_instructions,
          },
          {
            image_crop_area: values.image_crop_area,
            image_settings: values.image_settings,
          }
        )
      );
      try {
        if (res.status === 200) {
          history.push(Path.DASHBOARD);
        }
      } catch {
        setValues({
          ...values,
          alertBox: true,
          alertType: "error",
          alertMessage: "Oops, something went wrong. Please try again.",
        });
      }
    }
  };

  const handleSubmit = () => {
    if (currentStoreCreated.mobile !== values.mobile) {
      dispatch(OTPActions.SEND_SMS_OTP(values.mobile));
    } else {
      submit();
    }
  };

  function closeAlertBox() {
    setValues({ ...values, alertBox: false });
  }

  function setValueChanges(value, name) {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleClosePreview() {
    setOpen(false);
  }

  const charactersLeft = (fieldname) => {
    const charsRemaining =
      field_max_length[fieldname] - values[fieldname].length;
    return `${charsRemaining} characters left`;
  };

  let setStoreSiteError = (errorString) => {
    setError({ ...error, siteError: errorString });
  };

  async function checkUniqueName(value) {
    let res = await axios.get(`${apiConfig.apiURL}/api/unique-store/`, {
      params: { name: value },
    });
    if (res.data === value) {
      setError({ nameError: "This is already taken, please try again." });
    }
  }
  const [siteTimeoutId, setSiteTimeoutId] = React.useState("");
  const [validatingSite, setValidatingSite] = React.useState("");
  function validateSite(value) {
    clearTimeout(siteTimeoutId);
    setValidatingSite(true);
    if (value !== currentStoreCreated.site) {
      setSiteTimeoutId(
        setTimeout(async () => {
          let uniqueError = "";
          let changeLimiterror = "";
          let res1 = await axios.get(`${apiConfig.apiURL}/api/change-site/`, {
            params: { site: currentStoreCreated.site },
          });
          try {
            let res2 = await axios.get(
              `${apiConfig.apiURL}/api/unique-store/`,
              {
                params: { site: value },
              }
            );
            if (res2.data === value)
              uniqueError = "This is already taken, please try again.";
            else uniqueError = "";
          } catch {}
          if (res1.data) {
            if (!res1.data.allowed) {
              changeLimiterror = `You have changed your shop’s 
          URL within the last 30 days. You may 
          try again in ${res1.data.days_left} day${
                res1.data.days_left > 1 ? `s` : ""
              }.`;
            } else changeLimiterror = "";
          }
          setError({ siteError: uniqueError || changeLimiterror });
          setValidatingSite(false);
        }, 1000)
      );
    } else {
      setStoreSiteError("");
      setValidatingSite(false);
    }
  }

  React.useEffect(() => {
    setIsFormEnough(
      values.slogan && values.description && values.address && values.mobile
    );
  }, [values]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    dispatch(getStoreInfo());
  }, []);

  React.useEffect(() => {
    setLanguage(getLanguage);
  }, [getLanguage]);

  React.useEffect(() => {
    if (currentStoreCreated && !values.name) {
      Object.keys(currentStoreCreated).map((item) => {
        setValues((prevState) => ({
          ...prevState,
          [item]:
            String(currentStoreCreated[item]) === "null"
              ? ""
              : currentStoreCreated[item],
        }));
      });
    }
  }, [currentStoreCreated, values]);

  const {
    sloganError,
    descriptionError,
    addressError,
    mobileError,
    emailError,
    orderInstructionError,
  } = error;

  return (
    // <DashboardLayout marginTop={55}>
    <div className={classes.root} style={{ height: value === 1 ? "90em" : "" }}>
      <OTPModal proceedCallback={submit} />
      <div className={classes.content}>
        <SentroLoading open={loading} />
        <div className={classes.headerSection}>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="text"
            color="primary"
            className="position-absolute"
            style={{ zIndex: 1 }}
            onClick={() => history.push(Path.DASHBOARD)}
          >
            Back
          </Button>
          <div
            style={{
              fontSize: 28,
              color: "#002c42",
              fontWeight: 600,
            }}
            className="text-center w-100"
          >
            Shop Information
          </div>
        </div>
        <div className={classes.shopDetails}>
          <AppBar className={classes.appbar} position="static" color="default">
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
            >
              <Tab label="Name" {...a11yProps(0)} />
              <Tab label="Details" {...a11yProps(1)} />
              <Tab label="Order Instructions" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <div>
              <div className={classes.label1}>
                {language === "English"
                  ? "How do we call your shop?"
                  : "Anong itatawag namin sa shop mo?"}
              </div>
              <div className={classes.label3}>
                {language === "English"
                  ? "Please put your shop's name and website"
                  : "Ilagay ang pangalan ng iyong online shop at ang gusto mong website link."}
              </div>
              <div style={{ marginTop: 25 }}>
                <div className={classes.label2}>
                  {language === "English"
                    ? "What's the name of your shop?"
                    : "Anong pangalan ng store mo?"}
                </div>
                <TextField
                  fullWidth
                  id="store_name"
                  color="secondary"
                  variant="outlined"
                  style={{ marginTop: 0 }}
                  value={values.name}
                  error={error.nameError}
                  inputProps={{ maxLength: 25 }}
                  onChange={(e) => {
                    const input = e.target.value;
                    setValueChanges(input, "name");
                    if (input === "") {
                      setError({
                        ...error,
                        nameError: "This field is required.",
                      });
                    } else {
                      setError({ ...error, nameError: "" });
                      if (input !== currentStoreCreated.name) {
                        checkUniqueName(input);
                      }
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setError({
                        ...error,
                        nameError: "This field is required.",
                      });
                    }
                  }}
                  helperText={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{error.nameError}</div>
                      <div style={{ color: "rgba(0,0,0,0.54)" }}>
                        {charactersLeft("name")}
                      </div>
                    </div>
                  }
                />
              </div>
              <div style={{ marginTop: 25 }}>
                <div className={classes.label2}>
                  {language === "English"
                    ? "What will be your shop's website?"
                    : "Anong gusto mong website link para sa online shop mo?"}
                </div>
                <TextField
                  fullWidth
                  id="store_site"
                  color="secondary"
                  variant="outlined"
                  style={{ marginTop: 0 }}
                  value={values.site}
                  error={error.siteError}
                  inputProps={{ maxLength: 25 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">.sentro.ph</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    let input = e.target.value;
                    setValueChanges(input, "site");
                    if (validations.required(input)) {
                      setStoreSiteError(validations.required(input));
                    } else if (validations.uppercase(input)) {
                      setStoreSiteError(validations.uppercase(input));
                    } else if (validations.spaceBetween(input)) {
                      setStoreSiteError(validations.spaceBetween(input));
                    } else if (validations.specialCharacters(input)) {
                      setStoreSiteError(validations.specialCharacters(input));
                    } else {
                      validateSite(input);
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setError({
                        ...error,
                        siteError: "This field is required.",
                      });
                    }
                  }}
                  helperText={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{error.siteError}</div>
                      <div style={{ color: "rgba(0,0,0,0.54" }}>
                        {charactersLeft("site")}
                      </div>
                    </div>
                  }
                />
              </div>
              <p className="bg-light p-4 mt-4 mb-0" style={{ fontSize: 14 }}>
                <strong>Please note:</strong> If you change your shop’s website,
                you won’t be able to change it again for 30 days.
              </p>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div>
              <div>
                <div className={classes.label2}>
                  {language === "English"
                    ? "What is your shop's slogan or tagline?"
                    : "Anong slogan o tagline ng shop mo?"}
                </div>
                <div className={classes.label3}>
                  {language === "English"
                    ? 'Examples are: "Bida ang Saya" or "Love ko to"'
                    : 'Halimbawa: "Bida ang Saya" o "Love ko to"'}
                </div>
                <TextField
                  fullWidth
                  id="store_slogan"
                  color="secondary"
                  variant="outlined"
                  style={{ marginTop: 0 }}
                  inputProps={{ maxLength: 100 }}
                  value={values.slogan}
                  error={error.sloganError}
                  onChange={(e) => {
                    const input = e.target.value;
                    setValueChanges(input, "slogan");
                    if (input === "") {
                      setError({
                        ...error,
                        sloganError: "This field is required.",
                      });
                    } else {
                      setError({ ...error, sloganError: "" });
                    }
                  }}
                  helperText={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{error.sloganError}</div>
                      <div style={{ color: "rgba(0,0,0,0.54)" }}>
                        {charactersLeft("slogan")}
                      </div>
                    </div>
                  }
                />
              </div>
              <div style={{ marginTop: 25 }}>
                <div className={classes.label2}>
                  {language === "English"
                    ? "Please tell us a bit about your business"
                    : "Kwentuhan mo kami tungkol sa iyong business"}
                </div>
                <div className={classes.label3}>
                  {language === "English"
                    ? "A short history or description about your business"
                    : "Pwede mong ilagay rito kung paano kayo nagsimula o kung ano ang business mo"}
                </div>
                <TextField
                  fullWidth
                  id="store_description"
                  color="secondary"
                  variant="outlined"
                  multiline
                  rows={8}
                  style={{ marginTop: 0 }}
                  value={values.description}
                  error={error.descriptionError}
                  onChange={(e) => {
                    const input = e.target.value;
                    setValueChanges(input, "description");
                    if (input === "") {
                      setError({
                        ...error,
                        descriptionError: "This field is required.",
                      });
                    } else {
                      setError({ ...error, descriptionError: "" });
                    }
                  }}
                  helperText={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{error.descriptionError}</div>
                    </div>
                  }
                />
              </div>
              <div style={{ marginTop: 25 }}>
                <div className={classes.label2}>
                  {language === "English"
                    ? "Where is your store located?"
                    : "Ano'ng address ng business mo?"}
                </div>
                <div className={classes.label3}>Business Address</div>
                <TextField
                  fullWidth
                  id="store_address"
                  color="secondary"
                  variant="outlined"
                  style={{ marginTop: 0 }}
                  value={values.address}
                  error={error.addressError}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => {
                    const input = e.target.value;
                    setValueChanges(input, "address");
                    if (input === "") {
                      setError({
                        ...error,
                        addressError: "This field is required.",
                      });
                    } else {
                      setError({ ...error, addressError: "" });
                    }
                  }}
                  helperText={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{error.addressError}</div>
                      <div style={{ color: "rgba(0,0,0,0.54)" }}>
                        {charactersLeft("address")}
                      </div>
                    </div>
                  }
                />
              </div>
              <div style={{ marginTop: 25 }}>
                <div className={classes.label2}>
                  {language === "English"
                    ? "Link your social media accounts? (Optional)"
                    : "Meron bang social media accounts ang business mo?"}
                </div>
                <div className={classes.label2}>
                  {language === "English"
                    ? "Please set your accounts to public"
                    : "Siguraduhing naka-set sa Public ang iyong mga social media accounts para mas malawak ang iyong digital reach"}
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <div style={{ width: "50%", marginRight: 20 }}>
                    <div className={classes.label3}>Facebook</div>
                    <TextField
                      fullWidth
                      id="facebook"
                      color="secondary"
                      variant="outlined"
                      style={{ marginTop: 0 }}
                      value={values.facebook}
                      inputProps={{ maxLength: 32 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ marginRight: 0 }}>
                            <img src={fbIcon} alt="fbicon" />
                            <span style={{ marginLeft: "1vw" }}>
                              facebook.com/
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setValueChanges(e.target.value, "facebook")
                      }
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className={classes.label3}>Instagram</div>
                    <TextField
                      fullWidth
                      id="instagram"
                      color="secondary"
                      variant="outlined"
                      style={{ marginTop: 0 }}
                      value={values.instagram}
                      inputProps={{ maxLength: 32 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ marginRight: 0 }}>
                            <img src={instagramIcon} alt="instagramicon" />
                            <span style={{ marginLeft: "1vw" }}>
                              instagram.com/
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setValueChanges(e.target.value, "instagram")
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <div style={{ width: "50%", marginRight: 20 }}>
                    <div className={classes.label3}>Shopee</div>
                    <TextField
                      id="shopee"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: 0 }}
                      value={values.shopee}
                      inputProps={{ maxLength: 32 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ marginRight: 0 }}>
                            <img src={shopeeIcon} alt="shopeeicon" width="24" />
                            <span style={{ marginLeft: "1vw" }}>
                              shopee.ph/
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setValueChanges(e.target.value, "shopee")
                      }
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className={classes.label3}>Lazada</div>
                    <TextField
                      id="lazada"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: 0 }}
                      value={values.lazada}
                      inputProps={{ maxLength: 32 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ marginRight: 0 }}>
                            <img src={lazadaIcon} alt="lazadaicon" />
                            <span style={{ marginLeft: "1vw" }}>
                              lazada.com.ph/shop/
                            </span>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setValueChanges(e.target.value, "lazada")
                      }
                    />
                  </div>
                </div>
                <div style={{ marginTop: 10, width: "50%" }}>
                  <div className={classes.label3}>Kumu username</div>
                  <TextField
                    id="kumu"
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 0 }}
                    value={values.kumu}
                    inputProps={{ maxLength: 32 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ marginRight: 0 }}>
                          <img src={kumuIcon} alt="kumuicon" />
                          <span style={{ marginLeft: "1vw" }}>@</span>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setValueChanges(e.target.value, "kumu")}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div>
              <div className={classes.label2}>
                {language === "English"
                  ? "Where can your customers contact you?"
                  : "Saan ka pwedeng ma-contact ng iyong mga customers?"}
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <div className={classes.label3}>Mobile Number</div>
                  <TextField
                    fullWidth
                    id="store_mobile"
                    color="secondary"
                    variant="outlined"
                    style={{ marginTop: 0 }}
                    value={values.mobile}
                    inputProps={{ maxLength: 11 }}
                    error={error.mobileError}
                    onChange={(e) => {
                      const input = e.target.value;
                      setValueChanges(input, "mobile");
                      if (input === "") {
                        setError({
                          ...error,
                          mobileError: "This field is required.",
                        });
                      } else if (validations.mobileNumber(input)) {
                        setError({
                          ...error,
                          mobileError: validations.mobileNumber(input),
                        });
                      } else {
                        setError({ ...error, mobileError: "" });
                      }
                    }}
                    onBlur={(e) => {
                      const input = e.target.value;
                      if (input === "") {
                        setError({
                          ...error,
                          mobileError: "This field is required.",
                        });
                      } else if (validations.mobileNumber(input)) {
                        setError({
                          ...error,
                          mobileError: validations.mobileNumber(input),
                        });
                      } else {
                        setError({ ...error, mobileError: "" });
                      }
                    }}
                    helperText={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{error.mobileError}</div>
                        <div style={{ color: "rgba(0,0,0,0.54)" }}>
                          {charactersLeft("mobile")}
                        </div>
                      </div>
                    }
                  />
                </div>
                <div style={{ width: "50%", marginLeft: 20 }}>
                  <div className={classes.label3}>Email Address</div>
                  <TextField
                    fullWidth
                    id="store_email"
                    color="secondary"
                    variant="outlined"
                    style={{ marginTop: 0 }}
                    value={values.email}
                    inputProps={{ maxLength: 128 }}
                    error={error.emailError}
                    onChange={(e) => {
                      const input = e.target.value;
                      setValueChanges(input, "email");
                      if (input.length === 0) {
                        setError({ ...error, emailError: "" });
                      } else {
                        setError({
                          ...error,
                          emailError: validations.email(input),
                        });
                      }
                    }}
                    helperText={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{error.emailError}</div>
                      </div>
                    }
                  />
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div className={classes.label3}>Landline Number</div>
                <TextField
                  fullWidth
                  id="store_landline"
                  color="secondary"
                  variant="outlined"
                  style={{ marginTop: 0 }}
                  value={values.landline}
                  inputProps={{ maxLength: 30 }}
                  onChange={(e) => {
                    const input = e.target.value.replace(/[^0-9]/g, "");
                    setValueChanges(input, "landline");
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className={classes.label2}>
                Put more details here (Optional)
              </div>
              <div className={classes.label3}>
                If you have more details or instructions related to buying your
                items (ex. pre-order basis, order cut-offs, delivery schedules,
                warranties, return and/or exchange policies), you may put them
                here.
              </div>
              <TextField
                fullWidth
                id="store_order_instructions"
                color="secondary"
                variant="outlined"
                multiline
                rows={8}
                style={{ marginTop: 10 }}
                value={values.order_instructions}
                inputProps={{ maxLength: 500 }}
                error={error.orderInstructionError}
                onChange={(e) => {
                  const input = e.target.value;
                  setValueChanges(input, "order_instructions");
                }}
                helperText={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>{error.orderInstructionError}</div>
                  </div>
                }
              />
            </div>
          </TabPanel>
          <div style={{ padding: "24px 50px" }}>
            <Button
              fullWidth
              id="submit_btn"
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => handleSubmit()}
              disabled={
                !isFormEnough ||
                sloganError ||
                descriptionError ||
                addressError ||
                mobileError ||
                emailError ||
                orderInstructionError ||
                error.siteError ||
                validatingSite
              }
            >
              <div className="d-flex align-items-center">
                {otp.loading ? (
                  <div class="spinner-border text-light mr-2" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  ""
                )}
                <span>Submit</span>
              </div>
            </Button>
          </div>
        </div>
        <AlertBox values={values} closeAlertBox={closeAlertBox} />
        <PreviewShop
          id="preview_shop"
          open={open}
          setOpen={setOpen}
          storeInfo={values}
          setValues={setValues}
          productList={productList}
          handleClosePreview={handleClosePreview}
        />
      </div>
    </div>
    // </DashboardLayout>
  );
};

export default ShopDetailsForm;
