import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import logo from "../../../assets/images/sidebar_assets/sentro-logo.svg";
import logo2 from "../../../assets/images/sentro_logomark3.png";
import { ReactComponent as Cart } from "../../../assets/images/sidebar_assets/cart.svg";
import { ReactComponent as Purchased } from "../../../assets/images/sidebar_assets/purchased.svg";
import { ReactComponent as Discount } from "../../../assets/images/sidebar_assets/discount.svg";
import { ReactComponent as Wishlist } from "../../../assets/images/sidebar_assets/wishlist.svg";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import {
  List,
  ListItem,
  ListItemIcon,
  Button,
  useMediaQuery,
  IconButton,
  Drawer
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import MuiListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/styles";
import history from "../../../store/history";
import CloseIcon from "@material-ui/icons/Close";
import Path from "../../../Paths";
import { useDispatch, useSelector } from "react-redux";
import { confirmLogOut } from "../../auth/authActions";
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#ce6e61",
    borderRadius: "0px 16px 0px 0px",
    height: "100%",
    overflow: "auto",
    position: "relative",
    "&::-webkit-scrollbar": {
      height: 0,
      width: 0,
      background: "transparent"
    }
  },
  listLabel: {
    color: "#fff",
    fontWeight: 500,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      color: "#616161",
      fontWeight: 500,
      fontSize: 14
    }
  },
  listBtn: {
    padding: "14px 14px 14px 0",
    backgroundColor: "#ce6e61",
    display: "flex",
    justifyContent: "space-between"
  },
  btnSeller: {
    height: 40,
    borderRadius: 0,
    color: "#fff",
    fontWeight: 500,
    border: "1px solid #fff",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#f97663",
      color: "#fff",
      border: "none",
      borderRadius: 5
    }
  },

  icon: {
    fill: "white",
    [theme.breakpoints.down("sm")]: {
      fill: "#616161"
    }
  },
  drawer: {
    backgroundColor: "#fff",
    width: 220
  }
}));

const BuyerSideMenu = props => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  let location = useLocation();
  let currentLoc = location.pathname;
  const [active, setActive] = React.useState("");

  const listContent = [
    {
      icon: <Cart className={classes.icon} />,
      label: "My Cart",
      key: "cart",
      path: `/my_cart`,
      id: "cart"
    },
    {
      icon: <Purchased className={classes.icon} />,
      label: "Purchased",
      key: "purchased",
      path: "/purchased",
      id: "purchased"
    },
    {
      icon: <Discount className={classes.icon} />,
      label: "Discounts",
      key: "discount",
      path: "/dashboard/discounts",
      id: "discount",
      isDisabled: true
    },
    {
      icon: <Wishlist className={classes.icon} />,
      label: "Wishlist",
      key: "wishlist",
      path: "/dashboard/wishlist",
      id: "wishlist",
      isDisabled: true
    }
  ];

  const listContent2 = [
    {
      icon: (
        <AccountCircleOutlinedIcon style={{ color: "#616161", fontSize: 18 }} />
      ),
      label: "Manage Profile",
      onClick: () => history.push("/profile")
    },
    {
      icon: <HelpOutlinedIcon style={{ color: "#616161", fontSize: 18 }} />,
      label: "Help Center"
    },
    {
      icon: (
        <ExitToAppOutlinedIcon style={{ color: "#616161", fontSize: 18 }} />
      ),
      label: "Log Out",
      onClick: () => dispatch(confirmLogOut())
    }
  ];

  const ListItem = withStyles({
    root: {
      "&$selected": {
        backgroundColor: "#c24a39",
        [theme.breakpoints.down("sm")]: {
          backgroundColor: "#e5f2fd"
        }
      },
      "&$selected:hover": {
        backgroundColor: "#c24a39",
        [theme.breakpoints.down("sm")]: {
          backgroundColor: "#e5f2fd"
        }
      },
      "&:hover": {
        backgroundColor: "#c24a39",
        [theme.breakpoints.down("sm")]: {
          backgroundColor: "#e5f2fd"
        }
      }
    },
    selected: {}
  })(MuiListItem);

  function handleTrigger(key) {
    switch (key) {
      case "cart":
        history.push(Path.BUYER_DASHBOARD);
        break;
      case "purchased":
        history.push(Path.PURCHASED_PAGE);
        break;
      default:
        break;
    }
  }

  function drawerMenu() {
    return (
      <div>
        <List style={{ borderBottom: "1px solid #d1d5dd" }}>
          {listContent.map(item => (
            <ListItem
              disabled={item.isDisabled}
              onClick={() => {
                history.push(item.path);
                props.toggleDrawer();
              }}
              button
              className="d-flex justify-content-between p-3"
            >
              <div className="d-flex">
                <ListItemIcon style={{ minWidth: 40 }}>
                  {item.icon}
                </ListItemIcon>
                <div className={classes.listLabel}>{item.label}</div>
              </div>
            </ListItem>
          ))}
        </List>
        <List>
          {listContent2.map(item => (
            <ListItem
              disabled={item.isDisabled}
              onClick={item.onClick}
              button
              className="d-flex justify-content-between p-3"
            >
              <div className="d-flex">
                <ListItemIcon style={{ minWidth: 40 }}>
                  {item.icon}
                </ListItemIcon>
                <div className={classes.listLabel}>{item.label}</div>
              </div>
            </ListItem>
          ))}
        </List>
        {/* <div style={{ padding: 20 }}>
          <Button variant="outlined" fullWidth className={classes.btnSeller}>
            Become a seller
          </Button>
        </div> */}
      </div>
    );
  }

  React.useEffect(() => {
    if (currentLoc.includes(`/my_cart`)) {
      setActive(`/my_cart`);
    } else if (currentLoc.includes(`/purchased`)) {
      setActive(`/purchased`);
    }
  }, [currentLoc]);

  return (
    <>
      {isMobile ? (
        <Drawer anchor="left" open={props.open} onClose={props.toggleDrawer}>
          <div className={classes.drawer}>
            <div
              style={{ borderBottom: "1px solid #d1d5dd" }}
              className="d-flex justify-content-between align-items-center pl-2"
            >
              <img src={logo2} width="auto" height="25" alt="logo" />
              <IconButton onClick={props.toggleDrawer}>
                <CloseIcon color="secondary" />
              </IconButton>
            </div>
          </div>
          {drawerMenu()}
        </Drawer>
      ) : (
        <div className={classes.root}>
          <div className={classes.children}>
            <div
              style={{
                padding: "24px 60px",
                borderBottom: "1px solid #c24a39"
              }}
            >
              <img src={logo} alt="sentro_logo" />
            </div>
            <List style={{ padding: 0 }}>
              <div style={{ borderBottom: "1px solid #c24a39" }}>
                {listContent.map(item => (
                  <ListItem
                    button
                    id={item.id}
                    selected={active === item.path}
                    disabled={item.isDisabled}
                    className={classes.listBtn}
                    onClick={() => {
                      handleTrigger(item.key);
                      setActive(item.path);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <ListItemIcon style={{ minWidth: 56, paddingLeft: 15 }}>
                        {item.icon}
                      </ListItemIcon>
                      <div className={classes.listLabel}>{item.label}</div>
                    </div>
                  </ListItem>
                ))}
              </div>
            </List>
            {/* <div style={{ padding: 16 }}>
              <Button
                variant="outlined"
                fullWidth
                className={classes.btnSeller}
              >
                Become a seller
              </Button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default BuyerSideMenu;
