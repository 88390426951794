import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

/* Image */
import rightDesignImg from '../img/rightdesign.png';
import leftDesignImg from '../img/leftdesign.png';

import '../style/custom.css';

const SuccessModal = (props) => {
  const useStyles = makeStyles(() => ({
    MuiDialogPaper: {
      backgroundColor: '#051626 !important',
      color: '#fff',
      position: 'relative',
      overflow: 'visible',
      border: '4px solid #B4C6F4',
      borderRadius: 0,
      '&::before': {
        content: `''`,
        position: 'absolute',
        left: '-15px',
        top: '-15px',
        background: `transparent url(${leftDesignImg}) no-repeat`,
        backgroundSize: 'contain',
        width: '56px',
        height: '90px',
      },
      '&::after': {
        content: `''`,
        position: 'absolute',
        right: '-22px',
        top: '-15px',
        background: `transparent url(${rightDesignImg}) no-repeat`,
        backgroundSize: 'contain',
        width: '56px',
        height: '90px',
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.closeSuccessModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.MuiDialogPaper,
        }}
        className="nftFontFamily text-center"
        fullWidth={true}
        maxWidth="sm"
      >
        <div className="nft-modal-border">
          <DialogTitle id="alert-dialog-title">
            <span style={{ fontSize: '24px' }}>CONGRATULATIONS!</span>
          </DialogTitle>
          <div>
            <div className="horizontal-line-bluewhale"></div>
            <div className="py-5">
              <p style={{ fontSize: '20px' }}>
                You successfully bought {props.name}!
              </p>
              <p>
                Check and refresh your wallet as it might not appear immediately.
              </p>
            </div>
            <div className="w-50 mx-auto pb-3">
              <button
                onClick={() => props.closeSuccessModal()}
                className="nft-button-cut-corner"
              >
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SuccessModal;
