import React from "react";
import SideMenu from "./sideMenu";
import { makeStyles } from "@material-ui/core/styles";
import Content from "./content";
import { useSelector, useDispatch } from "react-redux";
import SentroLoading from "../common/loading";
import DeletedDialogBox from "./deletedDialogBox";
import BuxCard from "./buxCard";
import DocumentMeta from "react-document-meta";
import { Alert } from "antd";
import OrderActions from "../orders/orderActions";

const DashboardLayout = props => {
  let message = useSelector(state => state.order.message);
  let dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: "#f5f5f4",
      borderRadius: "4px",
      padding: theme.spacing(4, 6, 4)
    },
    dashboard: {
      display: "flex",
      flexWrap: "wrap",
      //   marginTop: props.marginTop || 100,
      [theme.breakpoints.down("sm")]: {
        display: "block"
        // overflow: "scroll",
        // marginTop: props.marginTop || 100,
      }
    },
    content: {
      backgroundColor: props.backgroundColor || "none",
      flex: 8,
      minHeight: "120vh",
      width: `calc(100% - ${props.left + `px` || "30vw"})`,
      [theme.breakpoints.up("lg")]: {
        position: "absolute",
        left: props.left || "30vw",
        right: props.right || "10vw"
      },
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },

    bux: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        marginTop: 10,
        display: "block"
      }
    },
    side: {
      zIndex: 3,
      [theme.breakpoints.up("lg")]: {
        flex: 3,
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0
      },
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
        display: "none"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    }
  }));
  const meta = {
    title: "Sentro | Welcome to Sentro",
    description:
      "Welcome to Sentro! Create your online shop and choose your payment options - it's that fast and easy.",
    canonical: "https://sentro.ph/dashboard",
    meta: {
      charset: "utf-8"

      // name: {
      //   keywords: "react,meta,document,html,tags",
      // },
    }
  };
  const classes = useStyles();
  sessionStorage.setItem("IN_SESSION", "CURRENT_USER");
  let loading = useSelector(state => state.dashboard.loading);
  let marketplace_loading = useSelector(state => state.marketplace.loading);
  loading = loading || marketplace_loading;

  let dialogOpen = useSelector(state => state.dashboard.dialogOpen);

  function handleClose(e) {
    dispatch(OrderActions.setMessageNull());
  }

  // React.useEffect(() => {
  //   return () => {
  //     dispatch(OrderActions.setMessageNull());
  //   };
  // }, []);

  return (
    <DocumentMeta {...meta}>
      <div style={{ height: "120vh" }}>
        {/* <MobileBanner style={{ margin: 0 }} /> */}
        <div className={classes.dashboard}>
          <SentroLoading open={loading} />

          <div className={classes.side}>
            <SideMenu
              language={props.language}
              setLanguage={props.setLanguage}
            />
          </div>

          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default DashboardLayout;
