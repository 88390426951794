import React from "react";
import DocumentMeta from "react-document-meta";
import SentroAppBar from "../common/layout/components/appBar";
import Banner from "./components/banner";
import Content from "./components/content";
import { useTheme, useMediaQuery } from "@material-ui/core";
import MobileFAQ from "../common/layout/mobile/mobileFAQ/mobileFAQ";
import SentroAppBarMobile from "../common/layout/components/appBarMobile";
const FAQ = props => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const meta = {
    title: "Sentro | FAQ",
    description:
      "Here are some of the things you need to know about Sentro to get started.",
    canonical: "https://sentro.ph/things_you_should_know",
    meta: {
      charset: "utf-8"
    }
  };

  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const [language, setLanguage] = React.useState("English");
  return (
    <DocumentMeta {...meta}>
      {mobileView ? (
        <div>
          <SentroAppBarMobile />
          <MobileFAQ />
        </div>
      ) : (
        <div>
          <SentroAppBar language={language} setLanguage={setLanguage} />
          <Banner />
          <div
            style={{
              backgroundColor: "#FAFAFA",
              backgroundImage: `url('https://sentro-store-bucker.s3.us-east-2.amazonaws.com/sentro_swirls.png')`,
              position: "relative",
              backgroundSize: "contain"
            }}
          >
            <Content user={user} language={language} />
          </div>
        </div>
      )}
    </DocumentMeta>
  );
};

export default FAQ;
