import React from "react";
import {
  makeStyles,
  Button,
  TextField,
  FormControlLabel,
  InputAdornment,
  FormGroup,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  Chip,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Radio } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  TimePicker,
  DatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import ErrorIcon from "@material-ui/icons/Error";
import { editVoucher } from "./actions";
import { useSelector, useDispatch } from "react-redux";
import DeactivateVoucherModal from "./deactivateModal";
import { ReactComponent as SelectProductIcon } from "../../assets/images/selectProduct.svg";

const ViewVoucher = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    open,
    setOpen,
    values,
    setValues,
    handleEditVoucher,
    state,
    setState,
  } = props;
  const useStyles = makeStyles((theme) => ({
    content: {
      width: "100%",
      paddingBottom: "10vh",
      paddingLeft: "10vw",
      paddingRight: "10vw",
    },
    title: {
      fontSize: 28,
      color: "#2b2d33",
      fontWeight: 600,
    },
    form: {
      border: "1px solid #d1d5dd",
      borderRadius: 8,
      backgroundColor: "#fff",
      marginTop: "5vh",
    },
    label1: {
      fontSize: 14,
      color: "#54575f",
      fontWeight: 500,
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const stores = useSelector((state) => state.vouchers.stores);
  const filters = useSelector((state) => state.vouchers.voucherFilters);
  const selectedVoucher = useSelector(
    (state) => state.vouchers.selectedVoucher
  );
  const subscription = useSelector((state) => state.subscription.subscription);
  const feature_settings = useSelector(
    (state) => state.dashboard.featureSettings?.features
  );
  let selectedVoucherCode = selectedVoucher ? selectedVoucher.voucher_code : "";
  const [openDeactivateModal, setOpenDeactivateModal] = React.useState(false);
  const initialValues = {
    voucher_code: "",
    voucher_type: "",
    discount_value: 0,
    applies_to: "",
    category: "",
    minimum_requirements: "",
    minimum_purchase_amount: 0,
    usage_limit_to_one: false,
    usage_limit_no_times: false,
    usage_limit_value: "",
    start_datetime: new Date(),
    start_time: moment().toDate().getTime(),
    end_datetime: new Date(),
    end_time: moment().toDate().getTime(),
  };
  function setValueChanges(value, name) {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleUsageLimit(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    });
  }

  function handleBack() {
    setOpen(false);
    setValues(initialValues);
    window.scrollTo(0, 0);
  }

  async function handleDeactivate(value) {
    setOpenDeactivateModal(false);
    let params = {
      ...values,
      is_active: value,
    };

    const res = await dispatch(
      editVoucher(params, filters, stores, selectedVoucherCode)
    );

    try {
      if (res.status === 200) {
        window.scrollTo(0, 0);
        setOpen(false);
        setState({
          ...state,
          openAlert: true,
          alertType: "success",
          alertMessage: "Voucher successfully updated!",
        });
        setValues(initialValues);
      }
    } catch {
      window.scrollTo(0, 0);
      // setState({
      //   ...state,
      //   openAlert: true,
      //   alertType: "error",
      //   alertMessage: "Oops, something went wrong. Please try again."
      // });
    }
  }

  const types = [
    { label: "Discount (Percentage %)", value: "percentage" },
    { label: "Discount (Fixed Amount)", value: "fixed_amount" },
    { label: "Buy X, Get Y", value: "buy_x_get_y" },
    // { label: "Discount (Free shipping)", value: "free_shipping" }
  ];

  const minimumRequirements = [
    { value: "None" },
    { value: "Minimum Purchase Amount (PHP)" },
    { value: "Minimum Quantity of items" },
  ];

  const usageLimit = [
    {
      label: "Limit to 1 customer per voucher",
      value: values.usage_limit_to_one,
      name: "usage_limit_to_one",
    },
    {
      label: "Limit to number of times that it can be used in total",
      value: values.usage_limit_no_times,
      name: "usage_limit_no_times",
    },
  ];

  return (
    <div style={{ display: open ? "" : "none" }}>
      <div className={classes.content}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            variant="text"
            color="primary"
            onClick={() => handleBack()}
          >
            Back
          </Button>
          <div className={classes.title}>View Voucher</div>
          <Button
            variant="outlined"
            color="primary"
            style={{ height: 40, fontSize: 12 }}
            onClick={() =>
              values.is_active
                ? setOpenDeactivateModal(true)
                : handleDeactivate(true)
            }
          >
            {values.is_active ? "Deactivate" : "Activate"}
          </Button>
        </div>
        <div className={classes.form}>
          <div
            className="d-flex col-12 p-4"
            style={{ borderBottom: "1px solid #d1d5dd" }}
          >
            <div className="col-3 p-0">
              <div style={{ fontSize: 18, fontWeight: 600, color: "#f97663" }}>
                Details
              </div>
            </div>
            <div className="col-9">
              <div>
                <div className={classes.label1}>Voucher Code</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="voucher_code"
                    disabled
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    placeholder="E.g. COOKIEVERSE"
                    color="secondary"
                    style={{ marginTop: 5 }}
                    value={values.voucher_code}
                  />
                  <Chip
                    id="status"
                    size="medium"
                    label={values.is_active ? "Active" : "Inactive"}
                    style={{
                      backgroundColor: values.is_active ? "#18b4a1" : "gray",
                      color: "#fff",
                      marginLeft: 60,
                      padding: "0 8px",
                    }}
                  />
                </div>
              </div>

              <div style={{ padding: "25px 0" }}>
                <div className={classes.label1}>Type</div>
                <Radio.Group
                  id="type"
                  disabled
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  value={values.voucher_type}
                >
                  {types.map((item) => {
                    return (
                      <Radio value={item.value} style={{ marginBottom: 15 }}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
                {["percentage", "fixed_amount"].includes(
                  values.voucher_type
                ) ? (
                  <div style={{ marginTop: 10 }}>
                    <div className={classes.label1}>Discount Value</div>
                    <TextField
                      id="discount_value"
                      disabled
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      InputProps={
                        values.voucher_type === "percentage"
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }
                          : ""
                      }
                      value={parseFloat(values.discount_value)}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div style={{ marginTop: 15 }}>
                  <div className={classes.label1}>Applies To</div>
                  <Radio.Group
                    id="applies_to"
                    disabled
                    style={{
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    value={values.applies_to}
                    onChange={(e) =>
                      setValueChanges(e.target.value, "applies_to")
                    }
                  >
                    {[
                      "All Products",
                      "Specific Category",
                      "Specific Product",
                    ].map((item) => {
                      return (
                        <Radio value={item} style={{ marginBottom: 15 }}>
                          {item}
                        </Radio>
                      );
                    })}
                  </Radio.Group>

                  <div
                    style={{
                      display:
                        values.applies_to === "Specific Category" ? "" : "none",
                    }}
                  >
                    <div className={classes.label1}>Choose category</div>
                    <TextField
                      disabled
                      id="category"
                      value={values.category}
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                  {["buy_x_get_y"].includes(values.voucher_type) ? (
                    <div style={{ opacity: 0.5 }}>
                      <div>
                        <FormGroup
                          margin="dense"
                          style={{
                            width: "100%",
                            height: 176,
                            backgroundColor: "#F5F5F5",
                            borderRadius: 4,
                            display: "block",
                            padding: 16,
                          }}
                        >
                          <div className={classes.label1}>Customer buys</div>
                          <Radio
                            disabled
                            value="Minimum Quantity of items"
                            size="small"
                            checked={
                              values.minimum_requirements ===
                              "Minimum Quantity of items"
                                ? true
                                : false
                            }
                            style={{
                              marginBottom: 15,
                              width: "100%",
                              marginTop: 15,
                            }}
                          >
                            Minimum quantity of items
                          </Radio>
                          <div style={{ display: "flex" }}>
                            <div style={{ width: "40%" }}>
                              <div className={classes.label1}>Quantity</div>
                              <TextField
                                disabled
                                id="usage_limit_value"
                                style={{ width: "90%" }}
                                variant="outlined"
                                margin="dense"
                                color="secondary"
                                type="number"
                                value={values.minimum_purchase_amount}
                                InputProps={{ className: classes.input }}
                              />
                            </div>
                            <div>
                              <div className={classes.label1}>
                                Select Product
                              </div>
                              <TextField
                                disabled
                                id="free_product"
                                variant="outlined"
                                color="secondary"
                                margin="dense"
                                placeholder="Click to select product"
                                value={values.minimum_x_product}
                                InputProps={{
                                  className: classes.input,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <SelectProductIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                      <div>
                        <div>
                          <FormGroup
                            margin="dense"
                            style={{
                              width: "100%",
                              height: 144,
                              backgroundColor: "#F5F5F5",
                              borderRadius: 4,
                              marginTop: 8,
                              padding: 16,
                              display: "block",
                            }}
                          >
                            <div className={classes.label1}>Customer gets</div>
                            <div style={{ display: "flex", marginTop: "15px" }}>
                              <div style={{ width: "40%" }}>
                                <div className={classes.label1}>Quantity</div>
                                <TextField
                                  disabled
                                  id="free_quantity"
                                  style={{ width: "90%" }}
                                  variant="outlined"
                                  margin="dense"
                                  color="secondary"
                                  type="number"
                                  value={values.free_quantity}
                                  InputProps={{ className: classes.input }}
                                />
                              </div>

                              <div>
                                <div className={classes.label1}>
                                  Select product
                                </div>
                                <TextField
                                  disabled
                                  id="free_product"
                                  variant="outlined"
                                  color="secondary"
                                  margin="dense"
                                  placeholder="Click to select product"
                                  value={values.free_product}
                                  style={{ width: "100%" }}
                                  InputProps={{
                                    className: classes.input,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <SelectProductIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex col-12 p-4"
            style={{ borderBottom: "1px solid #d1d5dd" }}
          >
            <div className="col-3 p-0">
              <div style={{ fontSize: 18, fontWeight: 600, color: "#f97663" }}>
                Limits
              </div>
            </div>
            <div className="col-9">
              <div>
                <div className={classes.label1}>
                  Add a maximum redeemable limit to your vouchers. Leave max
                  redemption to 0 if there is no limit.
                </div>
                <div style={{ padding: "25px 0" }}>
                  <div className={classes.label1}>Usage Limit</div>
                  <FormGroup id="usage_limit">
                    {usageLimit.map((item) => {
                      return (
                        <FormControlLabel
                          style={{
                            marginRight: 0,
                            display: "flex",
                          }}
                          control={
                            <Checkbox
                              disabled
                              checked={item.value}
                              onChange={handleUsageLimit}
                              name={item.name}
                              color="primary"
                            />
                          }
                          label={
                            <div style={{ fontSize: 14, color: "#54575f" }}>
                              {item.label}
                            </div>
                          }
                        />
                      );
                    })}
                  </FormGroup>

                  <div
                    style={{
                      display: values.usage_limit_no_times ? "" : "none",
                    }}
                  >
                    <TextField
                      disabled
                      id="usage_limit_value"
                      style={{ width: "50%" }}
                      variant="outlined"
                      margin="dense"
                      color="secondary"
                      type="number"
                      value={values.usage_limit_value}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex col-12 p-4"
            style={{ borderBottom: "1px solid #d1d5dd" }}
          >
            <div className="col-3 p-0">
              <div style={{ fontSize: 18, fontWeight: 600, color: "#f97663" }}>
                Requirements
              </div>
            </div>
            <div className="col-9">
              <div>
                <div className={classes.label1}>Minimum Requirements</div>
                <Radio.Group
                  id="minimum_requirements"
                  disabled
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  value={values.minimum_requirements}
                  onChange={(e) =>
                    setValueChanges(e.target.value, "minimum_requirements")
                  }
                >
                  {minimumRequirements.map((item) => {
                    return (
                      <Radio value={item.value} style={{ marginBottom: 15 }}>
                        {item.value}
                      </Radio>
                    );
                  })}
                </Radio.Group>

                <div
                  style={{
                    display:
                      values.minimum_requirements === "None" ||
                      values.minimum_requirements === ""
                        ? "none"
                        : "",
                  }}
                >
                  <div className={classes.label1}>
                    {values.minimum_requirements === "Minimum Quantity of items"
                      ? "Minimum Quantity of items"
                      : "Minimum Purchase Amount (PHP)"}
                  </div>
                  <TextField
                    id="minimum_purchase_amount"
                    disabled
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    InputProps={
                      values.minimum_requirements ===
                      "Minimum Quantity of items"
                        ? ""
                        : {
                            startAdornment: (
                              <InputAdornment position="start">
                                PHP
                              </InputAdornment>
                            ),
                          }
                    }
                    value={values.minimum_purchase_amount}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex col-12 p-4">
            <div className="col-3 p-0">
              <div style={{ fontSize: 18, fontWeight: 600, color: "#f97663" }}>
                Duration
              </div>
            </div>
            <div className="col-9">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                  <div className={classes.label1}>Starts on</div>
                  <div>
                    <div className="d-flex">
                      <DatePicker
                        disabled
                        autoOk
                        label="Date"
                        margin="dense"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/YYYY"
                        orientation="portrait"
                        color="secondary"
                        className="mr-2"
                        disablePast
                        value={values.start_datetime}
                        InputAdornmentProps={{ position: "start" }}
                      />
                      <TimePicker
                        disabled
                        autoOk
                        label="Time"
                        margin="dense"
                        variant="inline"
                        inputVariant="outlined"
                        format="hh:mm A"
                        orientation="portrait"
                        color="secondary"
                        disablePast
                        value={values.start_datetime}
                        InputAdornmentProps={{ position: "start" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <div className={classes.label1}>Ends on</div>
                  <div>
                    <div className="d-flex">
                      <DatePicker
                        disabled
                        autoOk
                        label="Date"
                        margin="dense"
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/YYYY"
                        orientation="portrait"
                        color="secondary"
                        className="mr-2"
                        disablePast
                        value={values.end_datetime}
                        InputAdornmentProps={{ position: "start" }}
                      />
                      <TimePicker
                        disabled
                        autoOk
                        label="Time"
                        margin="dense"
                        variant="inline"
                        inputVariant="outlined"
                        format="hh:mm A"
                        orientation="portrait"
                        color="secondary"
                        disablePast
                        value={values.end_datetime}
                        InputAdornmentProps={{ position: "start" }}
                      />
                    </div>
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div style={{ marginTop: 30 }} className="p-4">
            <Button
              id="edit_btn"
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              disabled={
                subscription.subscription_type === null ||
                (subscription.subscription_type &&
                  !subscription.features.vouchers)
              }
              onClick={() => {
                handleEditVoucher(values);
                setOpen(false);
                window.scrollTo(0, 0);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
      <DeactivateVoucherModal
        id="deactivate_modal"
        open={openDeactivateModal}
        setOpen={setOpenDeactivateModal}
        handleDeactivate={handleDeactivate}
      />
    </div>
  );
};

export default ViewVoucher;
