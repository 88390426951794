import React from "react";
import {
  makeStyles,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  CardContent,
  Button
} from "@material-ui/core";
import imgPlaceholder from "../../../../assets/images/product_placeholder.png";
import { useSelector } from "react-redux";
import Utils from "../../utils";
export default function ProductCardFeatured(props) {
  let productList = useSelector(state => state.store.products);
  const ProductCardStyles = makeStyles(theme => ({
    card: {
      width: 320,
      height: 470,
      marginLeft: 35,
      marginTop: 25,
      marginBottom: 40,
      boxShadow: "none",
      border: "1px solid #c4c4c4",
      [theme.breakpoints.down("sm")]: {
        margin: 0
      },
      [theme.breakpoints.down("xs")]: {
        width: "85vw"
      }
    },
    productImage: {
      height: 320
    }
  }));

  const classes = ProductCardStyles();
  function productImage() {
    let item = props.item;
    if (item.gallery) {
      if (item.gallery.length) {
        if (item.photo_primary === null || item.photo_primary === "") {
          return item.gallery[0].image;
        } else {
          if (
            item.gallery[item.photo_primary] === "undefined" ||
            item.gallery[item.photo_primary] === undefined
          ) {
            return item.gallery[0].image;
          } else {
            return item.gallery[item.photo_primary].image;
          }
        }
      } else {
        return imgPlaceholder;
      }
    } else {
      if (item.images) {
        return item.images;
      } else {
        return imgPlaceholder;
      }
    }
  }

  return (
    <Card
      className={classes.card}
      onClick={() => (props.isPreview ? null : props.onSelect(props.item))}
    >
      <CardActionArea>
        <CardMedia
          className={classes.productImage}
          image={productImage()}
          title={productList.name}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="body1"
            component="h2"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            {props.item ? props.item.name : "Your Product's Name"}
          </Typography>
          <div className="d-flex align-items-end">
            {props.item.discounted_price ? (
              <Typography
                className="mr-2"
                variant="body2"
                color="textSecondary"
                component="p"
              >
                PHP{" "}
                <strong>
                  {Utils.commafy(
                    parseFloat(props.item ? props.item.discounted_price : 0)
                  )}
                </strong>
              </Typography>
            ) : (
              ""
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{
                marginTop: 10,
                textDecoration: props.item
                  ? props.item.discounted_price
                    ? "line-through"
                    : "auto"
                  : "",
                fontSize: props.item
                  ? props.item.discounted_price
                    ? 12
                    : 14
                  : "",
                color: props.item
                  ? props.item.discounted_price
                    ? "rgba(0,0,0,0.5)"
                    : "auto"
                  : ""
              }}
            >
              ₱ {Utils.commafy(parseFloat(props.item ? props.item.price : 0))}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          id="view_details_button"
          onClick={() => (props.isPreview ? null : props.onSelect(props.item))}
          size="medium"
          color="secondary"
          style={{ height: 40 }}
        >
          View details
        </Button>
      </CardActions>
    </Card>
  );
}
