import React from "react";
import logo from "../../assets/images/sentro_vertical.png";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Link
} from "@material-ui/core";
import ForgotPasswordForm from "./forgotPasswordComponents/forgotPasswordForm";
import { ArrowBack } from "@material-ui/icons";
import SentroAlert from "../common/alert";
import SentroButton from "../common/sentroButton";
import Text from "../../common/text";
import Path from "../../Paths";
import history from "../../store/history";
import SentroLoading from "../common/loading";
import { useSelector } from "react-redux";
const ForgotPassword = props => {
  const forgotPasswordStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60vw",
      height: "100vh",
      background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    ForgotPassword: {
      height: "100vh",
      overflow: "auto",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    box1: {
      marginTop: "35vh",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },

    box2: {
      padding: "6vh 8vh 0 ",
      [theme.breakpoints.down("xs")]: {
        padding: "6vh 5vw"
      }
    }
  }));

  const classes = forgotPasswordStyles();
  const loading = useSelector(state => state.forgotPassword.loading);
  return (
    <div className={classes.root}>
      <SentroLoading open={loading} />
      <Box className={classes.banner}>
        <Container>
          <Box className={classes.box1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={logo} style={{ width: 150 }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "2vh" }}
                textType="h2"
                content="Create your online shop here."
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#fff",
                  position: "absolute",
                  bottom: 25
                }}
              >
                This works best in the latest Google desktop browser.
              </Typography>
            </div>
          </Box>
        </Container>
      </Box>

      <Box className={classes.ForgotPassword}>
        <Container>
          <Box style={{ marginTop: "2vh" }}>
            <SentroButton
              id="back_button"
              color="primary"
              onClick={() => history.go(-1)}
              startIcon={<ArrowBack color="primary" />}
              style={{ fontSize: 14 }}
              label={"BACK"}
            />
          </Box>
          <Box className={classes.box2}>
            <Text textType="h3" content="Forgot Password?" />
            <Box style={{ marginTop: "2vh" }}>
              <Text
                style={{ color: "#2B2D33" }}
                textType="p1"
                content="Enter your email address and we will send you instructions to reset your password."
              />
            </Box>
            <Box style={{ marginTop: "3vh" }}>
              <ForgotPasswordForm />
            </Box>
          </Box>
        </Container>
        <Box style={{ marginTop: "3vh", textAlign: "center" }}>
          <Typography style={{ fontSize: "13px" }}>
            Don't have an account yet?{" "}
            <Link
              id="signUp_link"
              style={{ color: "#F97663", fontSize: 14, fontWeight: "bold" }}
              onClick={() => props.history.push(Path.SIGN_UP)}
              variant="link"
            >
              Sign up
            </Link>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
export default ForgotPassword;
