import ubLogo from "../../../../../../assets/images/unionbank.png";
import bpiLogo from "../../../../../../assets/images/BPI.png";
import rcbcLogo from "../../../../../../assets/images/rcbc.png";

import logo1 from "../../../../../../assets/images/OTC_channels/7Eleven.png";
import logo2 from "../../../../../../assets/images/OTC_channels/BayadCenterLogo.png";
import logo3 from "../../../../../../assets/images/OTC_channels/CebuanaLogo.png";
import logo4 from "../../../../../../assets/images/OTC_channels/DA5.png";
import logo5 from "../../../../../../assets/images/OTC_channels/ECPay.png";
import logo6 from "../../../../../../assets/images/OTC_channels/ExpressPay.png";
import logo7 from "../../../../../../assets/images/OTC_channels/LBC.png";
import logo8 from "../../../../../../assets/images/OTC_channels/Palawan.png";
import logo9 from "../../../../../../assets/images/OTC_channels/PayGo.png";
import logo10 from "../../../../../../assets/images/OTC_channels/Posible.png";
import logo11 from "../../../../../../assets/images/OTC_channels/RD.png";
import logo12 from "../../../../../../assets/images/OTC_channels/robinsons.png";
import logo13 from "../../../../../../assets/images/OTC_channels/Rural.png";
import logo14 from "../../../../../../assets/images/OTC_channels/SM.png";
import logo15 from "../../../../../../assets/images/OTC_channels/gcash.svg";
import grabpay from "../../../../../../assets/images/OTC_channels/grabpay.png";
import gcash from "../../../../../../assets/images/OTC_channels/gcash2.png";
import visa from "../../../../../../assets/images/OTC_channels/visa.png";
import mastercard from "../../../../../../assets/images/OTC_channels/mastercard.png";

export const bankLogos = [
  { logo: ubLogo },
  { logo: bpiLogo },
  { logo: rcbcLogo },
  { logo: logo15 }
];
export const otcLogos = [
  { logo: logo1 },
  { logo: logo2 },
  { logo: logo3 },
  { logo: logo4 },
  { logo: logo5 },
  { logo: logo6 },
  { logo: logo7 },
  { logo: logo8 },
  { logo: logo9 },
  { logo: logo10 },
  { logo: logo11 },
  { logo: logo12 },
  { logo: logo13 },
  { logo: logo14 }
];
export const ewallets = [{ logo: gcash }, { logo: grabpay }];
export const cards = [{ logo: visa }, { logo: mastercard }];
export default { bankLogos, otcLogos, ewallets, cards };

export const unionbank = ubLogo;
export const bpi = bpiLogo;
export const rcbc = rcbcLogo;
export const otc1 = logo1;
export const otc2 = logo2;
export const otc3 = logo3;
export const otc4 = logo4;
export const otc5 = logo5;
export const otc6 = logo6;
export const otc7 = logo7;
export const otc8 = logo8;
export const otc9 = logo9;
export const otc10 = logo10;
export const otc11 = logo11;
export const otc12 = logo12;
export const otc13 = logo13;
export const otc14 = logo14;
export const otc15 = logo15;
