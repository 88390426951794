import React from "react";
import {
  Box,
  Paper,
  Typography,
  Link,
  IconButton,
  Snackbar,
  Slide
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SentroButton from "../common/sentroButton";
import history from "../../store/history";
import apiConfig from "../../common/apiConfig";
import LinkIcon from "@material-ui/icons/Link";
import CloseIcon from "@material-ui/icons/Close";
import Path from "../../Paths";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import DocumentMeta from "react-document-meta";
import facebookIcon from "../../assets/images/facebook.svg";
import viberIcon from "../../assets/images/viber.svg";
import whatsappIcon from "../../assets/images/whatsapp.svg";
import twitterIcon from "../../assets/images/twitter.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  ViberShareButton
} from "react-share";
// let imgThumbnail =
//   "https://sentro-store-bucker.s3.us-east-2.amazonaws.com/publish_thumbnail.png";

let PublishedSite = props => {
  const meta = {
    title: "Sentro | View your sentro shop",
    description:
      "You've successfully created your Sentro shop. Now, you can start sharing your link.",
    canonical: "https://sentro.ph/view_your_site_now",
    meta: {
      charset: "utf-8",
      // name: {
      //   keywords: "react,meta,document,html,tags",
      // },
    }
  };

  const useStyles = makeStyles(theme => ({
    snackBar: {
      backgroundColor: "#fff",
      color: "#F97663",
      width: 50
    }
  }));
  const classes = useStyles();
  const storeInfo = useSelector(state => state.webBuilder.storeInfo);
  if (!storeInfo) {
    history.push(Path.HOME);
  }
  let host = apiConfig.localHost.replace("https://", "").replace("app.", "");
  let storeLink = `https://${storeInfo.site}.${host}`;
  const [open, setOpen] = React.useState(false);
  const DialogTransition = props => <Slide direction="up" {...props} />;
  function closeAlertBox() {
    setOpen(false);
  }

  function AlertBox() {
    return (
      <Snackbar
        ContentProps={{
          classes: {
            root: classes.snackBar
          }
        }}
        TransitionComponent={DialogTransition}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={() => closeAlertBox()}
        message="Copied to clipboard!"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeAlertBox()}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  }

  const ShareLink = () => (
    <CopyToClipboard
      onCopy={() => setOpen(true)}
      text={`${apiConfig.localHost}shop/${storeInfo.site}`}
    >
      <IconButton style={{ padding: 8 }}>
        <LinkIcon
          style={{
            color: "#002C42"
          }}
          width="45"
          height="45"
        />
      </IconButton>
    </CopyToClipboard>
  );

  return (
    <DocumentMeta {...meta}>
      <div
        style={{
          margin: "5vw",
          height: "100vh",
          overflowY: "hidden",
          overflowX: "hidden"
        }}
      >
        <Box style={{ display: "flex", marginTop: 30 }}>
          <AlertBox />
          <Box style={{ width: "60vw", marginRight: 10 }}>
            <Box
              style={{
                overflow: "hidden",
                // padding: 20,
                transform: "scale(.9)",
                transformOrigin: "top left"
                // position: "absolute",
                // [theme.breakpoints.up("xl")]: {
                //   transform: `scale(0.08)`
                // }
              }}
            >
              <embed
                // src={"https://www.lazada.com.ph/"}
                src={storeLink}
                width={"100%"}
                height={600}
              />
            </Box>
          </Box>
          <Box style={{ width: "30vw" }}>
            <Paper
              style={{
                padding: "40px 45px",
                borderRadius: 10
              }}
            >
              <Box>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: 5,
                    color: "#2B2D33"
                  }}
                >
                  {props.language === "Filipino"
                    ? "Solid! Live na ang iyong online shop!"
                    : "Success! Your online shop is now live!"}
                </Typography>
                <Typography
                  style={{
                    marginBottom: "3vh",
                    fontSize: 16,
                    // fontWeight: "bold",
                    marginTop: 2,
                    color: "#2B2D33"
                  }}
                >
                  {props.language === "Filipino"
                    ? "I-share mo na ang iyong online shop at magbenta!"
                    : "Share your site and start selling!"}
                  {/* {storeInfo ? storeInfo.name : "Store Name"} */}
                </Typography>

                <Typography
                  style={{
                    fontSize: 28,
                    marginBottom: "1vh",
                    fontWeight: "bold",
                    marginTop: 5,
                    color: "#2B2D33",
                    width: "100%",
                    wordWrap: "break-word"
                  }}
                >
                  {storeInfo ? storeInfo.name : "Store Name"}
                </Typography>
                <div
                  style={{
                    color: "#828282",
                    fontSize: 16,
                    fontWeight: 600,
                    width: "100%",
                    wordWrap: "break-word"
                  }}
                >
                  {storeLink}
                </div>
              </Box>

              <div
                style={{ marginTop: 15, display: "flex", alignItems: "center" }}
              >
                <Typography
                  display="block"
                  gutterBottom
                  style={{ fontSize: 14, color: "#54575F", marginRight: 10 }}
                >
                  Share
                </Typography>
                <div>
                  <FacebookShareButton
                    url={`${apiConfig.localHost}shop/${
                      storeInfo ? storeInfo.site : ""
                    }`}
                  >
                    <IconButton style={{ padding: 8 }}>
                      <img
                        src={facebookIcon}
                        width="30"
                        height="30"
                        alt="facebook"
                      />
                    </IconButton>
                  </FacebookShareButton>
                </div>

                <div>
                  <TwitterShareButton
                    url={`${apiConfig.localHost}shop/${
                      storeInfo ? storeInfo.site : ""
                    }`}
                  >
                    <IconButton style={{ padding: 8 }}>
                      <img
                        src={twitterIcon}
                        width="30"
                        height="30"
                        alt="twitter"
                      />
                    </IconButton>
                  </TwitterShareButton>
                </div>

                <div>
                  <ViberShareButton
                    url={`${apiConfig.localHost}shop/${
                      storeInfo ? storeInfo.site : ""
                    }`}
                  >
                    <IconButton style={{ padding: 8 }}>
                      <img src={viberIcon} width="30" height="30" alt="viber" />
                    </IconButton>
                  </ViberShareButton>
                </div>

                <div>
                  <WhatsappShareButton
                    url={`${apiConfig.localHost}shop/${
                      storeInfo ? storeInfo.site : ""
                    }`}
                  >
                    <IconButton style={{ padding: 8 }}>
                      <img
                        src={whatsappIcon}
                        width="30"
                        height="30"
                        alt="whatsapp"
                      />
                    </IconButton>
                  </WhatsappShareButton>
                </div>

                <div>
                  <ShareLink />
                </div>
              </div>

              <Box style={{ marginTop: "4vh" }}>
                <SentroButton
                  id="visit_button"
                  style={{
                    height: 48,
                    backgroundColor: "#F97663"
                  }}
                  size="large"
                  onClick={() => window.open(storeLink)}
                  variant="contained"
                  fullWidth
                  label={
                    props.language === "Filipino"
                      ? "Puntahan ang aking online shop"
                      : "Visit my online shop"
                  }
                />
              </Box>

              <Box style={{ marginTop: "1vh" }}>
                <SentroButton
                  id="return_button"
                  style={{
                    height: 48
                    // backgroundColor: "#F97663"
                  }}
                  size="large"
                  onClick={() => history.push(Path.DASHBOARD)}
                  variant="outlined"
                  fullWidth
                  label={
                    props.language === "Filipino"
                      ? "Bumalik sa dashboard"
                      : "Return to dashboard"
                  }
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      </div>
    </DocumentMeta>
  );
};

export default PublishedSite;
