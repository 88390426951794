import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Chip,
  Button,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import moment from "moment";
import Utils from "../../../common/utils";
import OrderActions from "../../orderActions";
import CancelDeliveryModal, {
  minutesPassed,
} from "../../components/cancelDeliveryDialog";
import Path from "../../../../Paths";
import { useHistory } from "react-router-dom";
import { showAlert } from "../../../common/commonActions";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Items = (props) => {
  const { items } = props;

  const priceDisplay = (item) => {
    if (item.productSelection) {
      if (item.productSelection.discounted_price) {
        return `₱ ${Utils.commafy(
          parseFloat(item.productSelection.discounted_price)
        )}`;
      } else {
        return `₱ ${Utils.commafy(parseFloat(item.productSelection.price))}`;
      }
    } else {
      if (item.discounted_price) {
        return `₱ ${Utils.commafy(parseFloat(item.discounted_price))}`;
      } else {
        return `₱ ${Utils.commafy(parseFloat(item.unit_price))}`;
      }
    }
  };
  return (
    <>
      {items.map((item, key) => (
        <div className="px-4 pt-4 pb-0">
          <div className="border-bottom">
            <div>
              <strong
                style={{
                  fontSize: 14,
                  color: "#54575f",
                  textTransform: "capitalize",
                }}
              >
                {item.name}
              </strong>
            </div>
            <div className="d-flex">
              {item.productSelection
                ? item.productSelection.variants.map((item, key) => (
                    <div className="d-flex mt-2">
                      <Chip
                        size="small"
                        label={item.name}
                        className="mr-2"
                        style={{ borderRadius: 0 }}
                      />
                    </div>
                  ))
                : ""}
            </div>
            {item.special_instructions ? (
              <div
                style={{ fontSize: 12, color: "#54575f" }}
                className="mt-3 col-8 p-0"
              >
                <b>Note:</b> {item.special_instructions}
              </div>
            ) : (
              ""
            )}

            <div className="d-flex align-items-center col-12 mt-3 mb-4 p-0">
              <div
                className="col-6 p-0"
                style={{ fontSize: 14, color: "#54575f" }}
              >
                {priceDisplay(item)}
              </div>
              <div
                className="col-3 p-0 text-center"
                style={{ fontSize: 14, color: "#54575f" }}
              >
                {item.quantity}
              </div>
              <div
                className="col-3 p-0 text-right"
                style={{ fontSize: 14, fontWeight: 600, color: "#54575f" }}
              >
                ₱ {Utils.commafy(parseFloat(item.amount))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const Customer = (props) => {
  const { order } = props;
  return (
    <div className="p-4">
      <div className="border-bottom">
        <div style={{ fontSize: 12, color: "#54575f" }}>Customer Name:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {order.full_name}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Delivery Address:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {order.address}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Mobile No.:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
          }}
          className="pb-3"
        >
          {order.mobile_number}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Email Address:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
          }}
          className="pb-3"
        >
          {order.recipient}
        </div>
      </div>
    </div>
  );
};
const Payment = (props) => {
  const { order } = props;
  return (
    <div className="p-4">
      <div className="border-bottom">
        <div style={{ fontSize: 12, color: "#54575f" }}>Payment channel:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {order.channel ? order.channel : "--"}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Transaction No.:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {order.ref_id ? order.ref_id : "--"}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Amount Paid:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {order.amount != null ? `Php ${Utils.commafy(order.amount)} ` : "--"}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Payment Date:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {order.paid_date != null
            ? moment(order.paid_date).format("DD MMMM YYYY hh:mmA")
            : "--"}
        </div>
      </div>
    </div>
  );
};

const Delivery = (props) => {
  const { order, deliveryMode } = props;

  const pickup_point = () => {
    if (order.delivery_detail) {
      if (order.delivery_detail?.method === "pick_up") {
        return `${order.delivery_detail.details.landmark}, ${order.delivery_detail.details.google_address.address}`;
      }
    }
  };

  // const destination_point = () => {
  //   if (order.delivery_detail) {
  //     if (order.delivery_detail?.method === "pick_up" || order.deliv) {
  //       return `${order.address}`;
  //     }
  //   }
  // };
  return (
    <div className="p-4">
      <div className="border-bottom">
        <div style={{ fontSize: 12, color: "#54575f" }}>Delivery mode:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {deliveryMode()}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Tracking No.:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          --
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Rider:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          --
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Contact No.:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          --
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Pick-up Point:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {order.sender_address ? order.sender_address : "--"}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Destination Point:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {order.address ? order.address : "--"}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Delivery Fee:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          {order.shipping_fee ? order.shipping_fee : "--"}
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div style={{ fontSize: 12, color: "#54575f" }}>Date Delivered:</div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: "#54575f",
            textTransform: "capitalize",
          }}
          className="pb-3"
        >
          --
        </div>
      </div>
    </div>
  );
};

const Remarks = (props) => {
  const { remarks } = props;
  // const data = {
  //   schedule: "5:00pm - 6:00pm, Thursday, September 29, 2022",
  //   address:
  //     "Emerald Street, Veterans Crown, Brgy. Pasig City, Pasig City, Metro Manila",
  //   type: "New Customer",
  // };
  return (
    <div className="px-4">
      {Object.keys(remarks).map((key) => (
        <div className="border-bottom py-4 w-100">
          <div
            style={{
              fontSize: 12,
              fontWeight: 400,
              textTransform: "capitalize",
            }}
          >
            {key.replaceAll("_", " ")}
          </div>
          <div style={{ fontSize: 16, fontWeight: 500 }}>{remarks[key]}</div>
        </div>
      ))}
    </div>
  );
};

export default function Form(props) {
  const useStyles = makeStyles((theme) => ({
    borderBottom: {
      width: "20%",
      minWidth: "20%",
      borderBottom: "3px solid #eee",
    },
    ref_no: {
      "& .cancelled": {
        backgroundColor: "#54575F",
        color: "#fff",
      },
      "& .Paid": {
        backgroundColor: "#57b19f",
        color: "#fff",
      },
      "& .Pending": {
        backgroundColor: "#FFC044",
        color: "#2b2d32",
      },
    },
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const order = useSelector((state) => state.order.selectedOrder);
  const loading = useSelector((state) => state.order.loading);
  const cart = JSON.parse(order ? order["cart"] : "[]");
  const [value, setValue] = React.useState("Items");
  const [openCancel, setOpenCancel] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const paymentStatus = () => {
    let currentDate = moment().format("MM DD YYYY, HH:mm A");
    let expiryDate = order
      ? moment(order && order.expiry).format("MM DD YYYY, HH:mm A")
      : "";
    let isPaid = order ? order.paid : "";

    if (order && order.is_cancelled) return "Order cancelled";
    else {
      if (isPaid) return "Paid";
      else if (!isPaid && currentDate > expiryDate) return "Expired";
      else return "Pending";
    }
  };

  const deliveryMode = () => {
    if (order) {
      if (order.delivery_detail?.method === "mrspeedy") {
        return "Borzo";
      } else if (order.delivery_detail?.method === "ninjavan") {
        return "NinjaVan";
      } else if (order.delivery_detail?.method === "pick_up") {
        return "Own pickup";
      } else if (order.delivery_detail?.method === "flat_rate") {
        return "Flat rate";
      } else return "--";
    } else return "--";
  };

  const markAsPaid = () => {
    dispatch(OrderActions.setOrderDetails(order.id, "DETAILS"));
  };

  const bookDeliveryBtn = (props) => {
    if (props === "label") {
      if (order && order.delivery_detail?.method === "mrspeedy") {
        if (order.mrspeedy_book_datetime) {
          return "Cancel delivery";
        } else return "Book for delivery";
      } else if (order && order.delivery_detail?.method === "ninjavan") {
        return "Book for delivery";
      } else {
        return "Book for delivery";
      }
    } else if (props === "disabled") {
      if (order && order.mrspeedy_book_datetime) {
        return (
          minutesPassed(order.mrspeedy_book_datetime) > 30 ||
          order.is_cancelled ||
          order.mrspeedy_status === "canceled"
        );
      } else if (
        order &&
        order.delivery_detail?.method === "ninjavan" &&
        order.ninjavan_details
      ) {
        return true;
      } else if (
        (order && order.is_cancelled) ||
        (order && order.delivery_detail?.method === "pick_up") ||
        (order && order.delivery_detail?.method === "flat_rate")
      ) {
        return true;
      } else {
        return false;
      }
    } else if (props === "onclick") {
      if (order && order.delivery_detail?.method === "mrspeedy") {
        if (order.mrspeedy_book_datetime) {
          setOpenCancel(true);
        } else {
          history.push(Path.ORDER_DELIVERY_REQUEST);
        }
      } else if (order && order.delivery_detail?.method === "ninjavan") {
        if (order.paid) {
          history.push(Path.NINJAVAN_DELIVERY);
        } else {
          dispatch(
            showAlert({
              type: "warning",
              variant: "filled",
              message:
                "Order must be marked as paid before booking for delivery",
            })
          );
        }
      }
    }
  };

  const cancelOrderBtn = () => {
    return order
      ? order.is_cancelled ||
          (order.mrspeedy_book_datetime
            ? minutesPassed(order.mrspeedy_book_datetime) > 30
            : false)
      : false;
  };

  const cancelDelivery = () => {
    setOpenCancel(false);
    dispatch(OrderActions.cancelDelivery(order));
  };

  const tabs = [
    { label: "Items" },
    { label: "Customer" },
    { label: "Payment" },
    { label: "Delivery" },
  ];

  const menuButtons = (
    <div className="d-flex mt-4">
      <Button
        variant="outlined"
        color="primary"
        className="mr-2"
        style={{ fontWeight: 500, fontSize: 12, height: 40 }}
        onClick={() => markAsPaid()}
        disabled={
          (order && order.is_cancelled) ||
          (order && order.payment_option === "otc") ||
          (order && order.paid)
            ? true
            : false
        }
      >
        Mark as paid
      </Button>
      {isMobile ? (
        ""
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className="mr-2"
          style={{ fontWeight: 500, fontSize: 12, height: 40 }}
          onClick={() => bookDeliveryBtn("onclick")}
          disabled={bookDeliveryBtn("disabled")}
        >
          {bookDeliveryBtn("label")}
        </Button>
      )}

      <Button
        variant="outlined"
        color="primary"
        className="mr-2"
        style={{ fontWeight: 500, fontSize: 12, height: 40 }}
        onClick={() => dispatch(OrderActions.cancelOrder(order))}
        disabled={cancelOrderBtn()}
      >
        {loading ? (
          <div className="d-flex my-5 mr-3">
            <div
              className="spinner-border mx-auto"
              role="status"
              style={{
                color: "white",
                fontSize: 12,
                height: 25,
                width: 25,
              }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          ""
        )}
        Cancel order
      </Button>
      {isMobile ? (
        ""
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className="mr-2"
          style={{ fontWeight: 500, fontSize: 12, height: 40 }}
          disabled
        >
          Archive order
        </Button>
      )}
    </div>
  );

  return (
    <div
      className="position-relative"
      style={{
        height: isMobile ? "80vh" : "auto",
        overflowY: isMobile ? "scroll" : "hidden",
      }}
    >
      <div
        className={`${isMobile ? "" : "border"} bg-white p-4`}
        style={{ borderRadius: 4 }}
      >
        <div
          className={`d-flex align-items-center justify-content-between ${classes.ref_no}`}
        >
          <div style={{ fontSize: 14, color: "#2b2d32" }}>
            Ref. No: {order ? order.ref_id : "--"}
          </div>
          <Chip
            id="payment_status"
            size="small"
            label={paymentStatus()}
            icon={
              order && order.paid && !order.is_cancelled ? (
                <Check style={{ color: "#fff" }} />
              ) : (
                false
              )
            }
            className={
              paymentStatus() === "Order cancelled"
                ? "cancelled"
                : paymentStatus()
            }
          />
        </div>
        {isMobile ? (
          <div style={{ fontSize: 16, color: "#2b2d32", fontWeight: 300 }}>
            PHP{" "}
            <span style={{ fontWeight: 600, fontSize: 24 }}>
              {Utils.commafy(order && order.amount)}
            </span>
          </div>
        ) : (
          ""
        )}
        <div style={{ fontSize: 14, color: "#2b2d32" }} className="mt-2">
          Delivery mode: {deliveryMode()}
        </div>
        <div className="d-flex align-items-center justify-content-between mt-md-0 mt-2">
          <div style={{ fontSize: 14, color: "#2b2d32" }}>
            Payment mode: {order ? order.channel : "--"}
          </div>
          {isMobile ? (
            ""
          ) : (
            <div style={{ fontSize: 16, color: "#2b2d32", fontWeight: 300 }}>
              PHP{" "}
              <span style={{ fontWeight: 600, fontSize: 24 }}>
                {Utils.commafy(order && order.amount)}
              </span>
            </div>
          )}
        </div>
        {menuButtons}
      </div>
      <div
        className={`${isMobile ? "" : "border"} bg-white mt-md-4 mt-0 `}
        style={{ borderRadius: 4 }}
      >
        <Tabs
          id="tabs"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={true}
          variant={isMobile ? "scrollable" : "fullWidth"}
        >
          {tabs.map((item, key) => (
            <Tab
              value={item.label}
              classes={{ root: classes.borderBottom }}
              label={<div style={{ fontSize: 12 }}>{item.label}</div>}
              {...a11yProps(key)}
            />
          ))}
          {order && Object.keys(order.remarks).length > 0 ? (
            <Tab
              value="Remarks"
              classes={{ root: classes.borderBottom }}
              label={<div style={{ fontSize: 12 }}>Additional Notes</div>}
            />
          ) : (
            ""
          )}
        </Tabs>
        <div>{value === "Items" ? <Items items={cart} /> : ""}</div>
        <div>{value === "Customer" ? <Customer order={order} /> : ""}</div>
        <div>{value === "Payment" ? <Payment order={order} /> : ""}</div>
        <div>
          {value === "Delivery" ? (
            <Delivery order={order} deliveryMode={deliveryMode} />
          ) : (
            ""
          )}
        </div>

        <div>
          {value === "Remarks" &&
          order &&
          Object.keys(order.remarks).length > 0 ? (
            <Remarks remarks={order.remarks} />
          ) : (
            ""
          )}
        </div>
        {isMobile ? (
          ""
        ) : (
          <div style={{ padding: value === 4 ? "4vh 6em" : "3em" }}>
            {value !== "Airwaybill" && order && !order.ninjavan_details ? (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disableElevation
                onClick={() =>
                  value === "Airwaybill"
                    ? window.open(order && order.ninjavan_details?.waybill)
                    : bookDeliveryBtn("onclick")
                }
                disabled={bookDeliveryBtn("disabled")}
              >
                {value === "Airwaybill"
                  ? "Download airwaybill"
                  : bookDeliveryBtn("label")}
              </Button>
            ) : (
              ""
            )}
            {value === "Airwaybill" && order && order.ninjavan_details ? (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disableElevation
                onClick={() =>
                  window.open(order && order.ninjavan_details.waybill)
                }
              >
                Download airwaybill
              </Button>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {isMobile ? (
        value !== "Airwaybill" && !order.ninjavan_details ? (
          <div
            className={`p-4 bg-white ${isMobile ? "position-fixed" : ""}`}
            style={{
              bottom: 0,
              left: 0,
              right: 0,
              boxShadow: isMobile
                ? "0px -8px 24px rgba(0, 0, 0, 0.12)"
                : "none",
            }}
          >
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => bookDeliveryBtn("onclick")}
              disabled={bookDeliveryBtn("disabled")}
            >
              {value === "Airwaybill"
                ? "Download airwaybill"
                : bookDeliveryBtn("label")}
            </Button>
          </div>
        ) : value === "Airwaybill" && order.ninjavan_details ? (
          <div
            className={`p-4 bg-white ${isMobile ? "position-fixed" : ""}`}
            style={{
              bottom: 0,
              left: 0,
              right: 0,
              boxShadow: isMobile
                ? "0px -8px 24px rgba(0, 0, 0, 0.12)"
                : "none",
            }}
          >
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => bookDeliveryBtn("onclick")}
              disabled={bookDeliveryBtn("disabled")}
            >
              {value === "Airwaybill"
                ? "Download airwaybill"
                : bookDeliveryBtn("label")}
            </Button>
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <CancelDeliveryModal
        open={openCancel}
        onContinue={cancelDelivery}
        onCancel={() => setOpenCancel(false)}
      />
    </div>
  );
}
