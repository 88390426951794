import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import wallet from "../../../../assets/images/wallet_filled.svg";
import {
  Launch,
  HelpOutline,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@material-ui/icons";
import {
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Badge,
  useMediaQuery,
  Tooltip
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Utils from "../../../common/utils";
import { showAlert } from "../../../common/commonActions";
import buxLogo from "../../../../assets/images/buxLogo.svg";
import BuxLimit from "./buxLimit";
import { RedirectDialog } from "./redirectToBux";
import { goToCompleteProfile } from "../../action";
export default function CODWalletActive(props) {
  const useStyles = makeStyles(theme => ({
    limitDiv: {
      [theme.breakpoints.down("sm")]: {
        maxHeight: 0,
        overflow: "hidden",
        transition: "all 0.5s ease-in-out"
      }
    },
    extended: {
      maxHeight: "200px !important"
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openBux, setOpenBux] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const loading = useSelector(state => state.payments.loading);
  const kyc = useSelector(state => state.payments.buxStatus);
  const codWallet = useSelector(state => state.payments.codWallet);
  const bux = useSelector(state => state.payments.buxParams);
  const cashInLimit = bux ? bux.cashin_limits : "";
  const cashIn_maxValue = cashInLimit ? cashInLimit[1] : 0;
  const BorderLineProgress = withStyles(theme => ({
    root: {
      height: 8,
      borderRadius: 15
    },
    colorPrimary: {
      backgroundColor: "#d1d5dd"
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#00bda2"
    }
  }))(LinearProgress);

  const BorderLineProgress2 = withStyles(theme => ({
    root: {
      height: 8,
      borderRadius: 15
    },
    colorPrimary: {
      backgroundColor: "#d1d5dd"
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#f97663"
    }
  }))(LinearProgress);

  const buxSegments = kyc => {
    if (cashIn_maxValue === 100000) {
      return "BUxME";
    } else if (cashIn_maxValue === 500000) {
      return "BUxGIG";
    } else if (cashIn_maxValue === 0) {
      return "BUxSME";
    } else {
      return "BUxME";
    }
  };

  const kycLevel = () => {
    switch (codWallet.kyc_level) {
      case 0:
        return "BUxME";
      case 1:
        return "BUxGIG";
      case 2:
        return "BUxSME";
      case 3:
        return "BUxBIZ";
      default:
        return "BUxME";
    }
  };

  return (
    <div className="bg-white rounded" style={{ border: "1px solid #d1d5dd" }}>
      <div
        style={{ backgroundColor: "#3378B4" }}
        className="p-md-4 p-3 rounded-top d-flex"
      >
        <Badge
          badgeContent={
            <img
              src={buxLogo}
              width="20"
              height="20"
              alt=""
              className="mt-2 ml-2"
            />
          }
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <img src={wallet} alt="wallet" width="50" height="50" />
        </Badge>
        <div className="w-100 pl-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="text-white" style={{ fontSize: 14 }}>
              Payout wallet
            </div>
            <Chip
              size="small"
              label={buxSegments()}
              style={{ backgroundColor: "#e5f2fd", color: "#2a3d85" }}
            />
          </div>
          <div
            className="font-weight-light text-white pt-4"
            style={{ fontSize: 12 }}
          >
            Balance
          </div>
          <div className="d-flex justify-content-between align-items-center w-100">
            <strong style={{ fontSize: 28 }} className="text-white">
              {loading ? (
                <div
                  className="spinner-border mx-auto"
                  role="status"
                  style={{
                    color: "white",
                    fontSize: 12,
                    height: 25,
                    width: 25
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                `₱ ${Utils.commafy(codWallet.balance)}`
              )}
            </strong>
            <div
              className="text-white font-weight-light d-flex align-items-center"
              style={{ fontSize: 14 }}
            >
              Go to BUx{" "}
              <Launch
                onClick={() => dispatch(goToCompleteProfile(user.id, kyc))}
                style={{ color: "white", fontSize: 23 }}
                className="pl-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-md-4 px-3 py-3">
        <div className={`${classes.limitDiv} ${hide ? classes.extended : ""}`}>
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ fontSize: 14, fontWeight: 500, color: "#54575f" }}>
              Limits tracker
            </div>
            <Tooltip
              arrow
              title="Track your daily cash-in and cash-out limits"
              placement="top"
            >
              <IconButton onClick={() => setOpen(true)}>
                <HelpOutline color="secondary" style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            <BuxLimit open={open} setOpen={setOpen} />
          </div>

          <div className="pt-3 pb-4">
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ fontSize: 12, fontWeight: 500, color: "#54575f" }}>
                Cash out
              </div>
              <div style={{ fontSize: 12, fontWeight: 500, color: "#54575f" }}>
                ₱ 0.00 / ₱ 0.00
              </div>
            </div>
            <div className="mt-2">
              <BorderLineProgress2 variant="determinate" value={0} />
            </div>
          </div>
        </div>
        <div className="pt-5 pb-3 d-flex">
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            style={{ height: 40 }}
            onClick={() => setOpenBux(true)}
          >
            Cash out
          </Button>
          {/* <Button
            disableElevation
            variant="contained"
            color="primary"
            className="w-50 ml-2"
            style={{ height: 40 }}
            onClick={() =>
              dispatch(
                showAlert({ type: "warning", message: "Select a product" })
              )
            }
          >
            Upgrade to Pro
          </Button> */}
        </div>
        <div className="d-md-inherit d-md-none d-flex justify-content-center">
          <IconButton
            onClick={() => setHide(hide ? false : true)}
            className="p-1"
          >
            {hide ? (
              <KeyboardArrowDown color="secondary" />
            ) : (
              <KeyboardArrowUp color="secondary" />
            )}
          </IconButton>
        </div>
      </div>
      <RedirectDialog open={openBux} setOpen={setOpenBux} />
    </div>
  );
}
