import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, IconButton, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ReduxImageField from "../../../../reduxFields/imageField";
import LargeImageField from "../../../../reduxFields/largeImageField";
import { reduxForm, Field, reset } from "redux-form";
import {
  ReduxInitializeFormActions,
  patchVariantGroups,
  editProductv2,
  saveProductSelections,
  getProducts
} from "../../../websiteBuilderActions";
import { connect, useSelector, useDispatch } from "react-redux";
import VariantImageUpload from "./variantImageUpload";
import {
  useProductSelectionAxiosData,
  getAllNewlyCreatedVariantIds,
  useProductSelectionsToDelete
} from "../../forms/variantChoicesForm";
import { showAlert } from "../../../../common/commonActions";
function UploadImageDrawer(props) {
  const useStyles = makeStyles(theme => ({
    drawer: {
      backgroundColor: "#fff",
      padding: "0 12px",
      overflow: "hidden",
      width: 450
    },
    content: {
      padding: "0 24px",
      width: "100%",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 4
      },
      "&::-webkit-scrollbar-track": {
        background: "#eee"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#909196"
      }
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const values = useSelector(state => state.form.product_form?.values);
  const variantSelections = useSelector(
    state => state.form.product_form?.values.selections
  );
  const variantGroups = useSelector(
    state => state.form.product_form?.values.variant_groups
  );
  const productformInitialData = useSelector(
    state => state.webBuilder.reduxForm.productForm
  );
  const productSelectionsData = useProductSelectionAxiosData();
  const productSelectionsToDelete = useProductSelectionsToDelete();
  const toggleDrawer = () => {
    props.setState({ open: false, product: "" });
  };

  const handleAddImages = async () => {
    let res;
    res = await dispatch(
      editProductv2(
        props.store,
        {
          ...values,
          productImages: values.img
        },
        props.filters
      )
    );

    res = await dispatch(patchVariantGroups(res.data.uuid, variantGroups));
    const data = productSelectionsData.map(i => ({
      ...i,
      images: [i.images.cropped_image],
      product_variants_ids: getAllNewlyCreatedVariantIds(res.data, i)
    }));

    await dispatch(saveProductSelections(data, productSelectionsToDelete));
    dispatch(reset("product_form"));

    try {
      if (res) {
        dispatch(getProducts(props.store.uuid, props.filters));
        dispatch(
          showAlert({
            type: "success",
            message: "Product successfully updated!"
          })
        );
        props.setState({ open: false, product: "" });
      }
    } catch (err) {}
  };

  return (
    <div>
      <Drawer
        id="drawer"
        anchor="right"
        open={props.state.open}
        onClose={toggleDrawer}
      >
        <div className={classes.drawer}>
          <div
            style={{ padding: "12px 24px" }}
            className="d-flex justify-content-between align-items-center"
          >
            <strong style={{ fontSize: 18, color: "#2b2d32" }}>
              Upload images
            </strong>
            <IconButton
              onClick={() => props.setState({ open: false, product: "" })}
            >
              <Close />
            </IconButton>
          </div>
          <div
            className={classes.content}
            style={{ height: `calc(84vh - 5em)` }}
          >
            <div className="mb-5">
              <Field
                fullWidth
                id="img"
                name="img"
                label="JPEG, PNG or GIF only. Up to 5 images only."
                component={ReduxImageField}
                cropOnImageSelect
                cropperProps={{
                  defaultCropperOrientation: "square"
                }}
                multiple
                height={90}
                width={90}
              />
            </div>
            <div className="mb-5">
              <strong style={{ color: "#f97663", fontWeight: 500 }}>
                Variants
              </strong>
              <VariantImageUpload variantSelections={variantSelections} />
            </div>
          </div>
          <div
            className="py-3"
            style={{
              padding: 24,
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0
            }}
          >
            <Button
              fullWidth
              disableElevation
              variant="contained"
              color="primary"
              onClick={handleAddImages}
            >
              Add images
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

let Form = reduxForm({
  form: "product_form",
  destroyOnUnmount: true,
  keepDirtyOnReinitialize: true
})(UploadImageDrawer);

Form = connect(
  state => ({
    initialValues: state.webBuilder.reduxForm.productForm,
    store: state.webBuilder.storeInfo,
    regFields: state.form.product_form?.registeredFields
  }),
  {
    initializeData: ReduxInitializeFormActions.initializeProductFormData
  }
)(Form);

export default Form;
