import Services from "./Services";

export const ActionTypes = {
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCT: "GET_PRODUCT",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  CREATE_ORDER: "CREATE_ORDER",
  CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
  GET_ORDERS: "GET_ORDERS",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDER: "GET_ORDER",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  EDIT_ORDER: "EDIT_ORDER",
  EDIT_ORDER_SUCCESS: "EDIT_ORDER_SUCCESS",
  CONFIRM_ORDER: "CONFIRM_ORDER",
  CONFIRM_ORDER_SUCCESS: "CONFIRM_ORDER_SUCCESS",
  GET_SUPPLIER: "GET_SUPPLIER",
  GET_SUPPLIER_SUCCESS: "GET_SUPPLIER_SUCCESS",
  REGISTER_SME: "REGISTER_SME",
  REGISTER_SME_SUCCESS: "REGISTER_SME_SUCCESS",

  GET_STORES_REQUEST: "GET_STORES_REQUEST",
  GET_STORES_SUCCESS: "GET_STORES_SUCCESS",





  // Set item to cart
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",

  // Setting fetch status
  REQUEST_SUCCESS: "REQUEST_SUCCESS",
  REQUEST_FAILED: "REQUEST_FAILED"
};

class Actions {

  static getStores() {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.GET_STORES_REQUEST });
        let response = await Services.getStores();
        dispatch({
          type: ActionTypes.GET_STORES_SUCCESS,
          payload: {
            stores: response.data
          }
        });
        return response;
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
  static GET_PRODUCTS(payload) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.GET_PRODUCTS });
        let response = await Services.getProducts(payload);
        dispatch({
            type: ActionTypes.GET_PRODUCTS_SUCCESS,
            payload: {
            products: response.data.results || []
            }
        });
        return response
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
  static GET_PRODUCT(payload) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.GET_PRODUCT });
        let response = await Services.getProduct(payload);
        if (response){
          setTimeout(() => {
            dispatch({
              type: ActionTypes.GET_PRODUCT_SUCCESS,
              payload: {
                product: response
              }
            });
          }, 1000)
        }
        return response
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
  static CREATE_ORDER(payload, callback) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.CREATE_ORDER });
        let response = await Services.createOrder(payload);
        console.log(response, "Actions")
        if (response.status === 201){
          const order = response.data;
          const order_id = response.data.order_id;
          response = await Services.postOrder({
            order_id
          });
          if (response.status === 200) {
            console.log("SUCCESS", response)
            dispatch({
              type: ActionTypes.CREATE_ORDER_SUCCESS,
              payload: {
                order
              }
            });
            if (callback) callback(order_id);
          } else {
            console.log("POST ORDER ERROR!", response)
          }
        } else {
          console.log("CREATE ORDER ERROR!", response)
        }
        return response
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
  static GET_ORDERS(payload) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.GET_ORDERS });
        let response = await Services.getOrders(payload);
        if (response.status === 200){
        //   setTimeout(() => {
            dispatch({
              type: ActionTypes.GET_ORDERS_SUCCESS,
              payload: {
                orders: response.data
              }
            });
        //   }, 1000)
        }
        return response.data
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
  static GET_ORDER(payload) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.GET_ORDER });
        let response = await Services.getOrder(payload);
        if (response.data){
          setTimeout(() => {
            dispatch({
              type: ActionTypes.GET_ORDER_SUCCESS,
              payload: {
                order: response.data
              }
            });
          }, 1000)
        }
        return response
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
  static EDIT_ORDER(payload) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.EDIT_ORDER });
        let response = await Services.editOrder(payload);
        if (response){
          // setTimeout(() => {
            dispatch({
              type: ActionTypes.EDIT_ORDER_SUCCESS,
              payload: {
                order: response.data
              }
            });
          // }, 1000)
        }
        return response
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
  static CONFIRM_ORDER(payload) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.CONFIRM_ORDER });
        let response = await Services.confirmOrder(payload);
        if (response){
          // setTimeout(() => {
            dispatch({
              type: ActionTypes.CONFIRM_ORDER_SUCCESS,
              payload: {
                order_id: payload.order_id
              }
            });
          // }, 1000)
        }
        return response
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
  static ADD_TO_CART(payload) {
    return async dispatch => {
      dispatch({
        type: ActionTypes.ADD_TO_CART,
        payload
      });
    };
  }

  static REMOVE_FROM_CART(payload) {
    return async dispatch => {
      dispatch({
        type: ActionTypes.REMOVE_FROM_CART,
        payload
      });
    };
  }
  static GET_SUPPLIER(payload) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.GET_SUPPLIER });
        let response = await Services.getSupplier(payload);

        console.log(response, 'supplier')
        if (response){
          // setTimeout(() => {
            dispatch({
              type: ActionTypes.GET_SUPPLIER_SUCCESS,
              payload: {
                supplier: response.data
              }
            });
          // }, 1000)
        }
        return response
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
  static REGISTER_SME(payload) {
    return async dispatch => {
      try {
        dispatch({ type: ActionTypes.REGISTER_SME });
        let response = await Services.registerSME(payload);
        console.log(payload, response)
        if (response){
          // setTimeout(() => {
            dispatch({
              type: ActionTypes.REGISTER_SME_SUCCESS,
              payload: {
                // order_id: payload.order_id
              }
            });
          // }, 1000)
        }
        return response
      } catch (error) {
        dispatch({
          type: ActionTypes.REQUEST_FAILED,
          payload: error
        });
      }
    };
  }
}

export default Actions;
