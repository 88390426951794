import React from "react";
import bux from "../../../../assets/images/Bux.svg";
import { Dialog, Button, IconButton, Zoom } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { goToCompleteProfile } from "../../../payments/action";
import { useDispatch, useSelector } from "react-redux";
const Transition = props => <Zoom direction="up" {...props} />;

export default function RedirectToBux(props) {
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const kyc = useSelector(state => state.payments.buxStatus);

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="xs"
      TransitionComponent={Transition}
    >
      <div className="d-flex justify-content-end">
        <IconButton onClick={() => props.setOpen(false)}>
          <Close color="secondary" />
        </IconButton>
      </div>
      <div className="px-md-4 px-3 pb-4" style={{ overflow: "hidden" }}>
        <div className="d-flex justify-content-center">
          <img src={bux} alt="bux" width="auto" height="70" />
        </div>
        <div
          className="text-center mt-4 mb-5 px-md-5 px-3"
          style={{ fontSize: 18, fontWeight: 600, color: "#002c42" }}
        >
          You will be redirected to Bux for payment processing
        </div>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          onClick={props.handleContinue}
        >
          {props.loading ? (
            <div className="d-flex my-5 mr-3">
              <div
                className="spinner-border mx-auto"
                role="status"
                style={{
                  color: "white",
                  fontSize: 12,
                  height: 25,
                  width: 25
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}
          Continue
        </Button>
      </div>
    </Dialog>
  );
}
