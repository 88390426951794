import React from "react";
import { CheckOutlined, Clear } from "@material-ui/icons";

export const featuresList = [
  {
    feature: "Shop Customization",
    tooltip: "Style and brand your shop to the way you want it to look",
    basic: <CheckOutlined style={{ color: "#59c378" }} />,
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  {
    feature: "Pre-integrated payment gateway (Bux)",
    tooltip:
      "Accept a variety of payment methods from your customers with Bux.",
    basic: <CheckOutlined style={{ color: "#59c378" }} />,
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  {
    feature: "Built-in Logistics Service",
    tooltip:
      "Book and schedule deliveries for your customer's orders within Sentro.",
    basic: <CheckOutlined style={{ color: "#59c378" }} />,
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  {
    feature: "Delivery settings management",
    tooltip:
      "Customize your shipping options and include your own flat-rate shipping fees",
    basic: <CheckOutlined style={{ color: "#59c378" }} />,
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  {
    feature: "Buyer accounts",
    tooltip: "Create an account as a buyer.",
    basic: <CheckOutlined style={{ color: "#59c378" }} />,
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  {
    feature: "Facebook Messenger integration",
    tooltip: "Integrate your Facebook messenger to your Sentro online shop.",
    basic: <CheckOutlined style={{ color: "#59c378" }} />,
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  {
    feature: "Access to templates",
    tooltip:
      "Choose from a selection of basic and premium templates that would best suit your online shop.",
    basic: (
      <div style={{ fontSize: 12, color: "rgba(43, 45, 50, 0.8)" }}>
        Limited
      </div>
    ),
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  {
    feature: "Product variants",
    tooltip: "Add different variations to a product.",
    basic: (
      <div style={{ fontSize: 12, color: "rgba(43, 45, 50, 0.8)" }}>
        Limited
      </div>
    ),
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  {
    feature: "Product categories",
    tooltip: "Add categories to your products.",
    basic: (
      <div style={{ fontSize: 12, color: "rgba(43, 45, 50, 0.8)" }}>
        Limited
      </div>
    ),
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  // {
  //   feature: "Custom domain linking",
  //   tooltip: "Use your own custom domain for your Sentro online shop.",
  //   basic: <Clear style={{ color: "#C24A39" }} />,
  //   advanced: <CheckOutlined style={{ color: "#59c378" }} />
  // },
  // {
  //   feature: "Analytics dashboard",
  //   tooltip: "Track your sales performance, top products and categories",
  //   basic: <Clear style={{ color: "#C24A39" }} />,
  //   advanced: <CheckOutlined style={{ color: "#59c378" }} />
  // },
  // {
  //   feature: "Cash on delivery",
  //   tooltip:
  //     "Accept Cash on Delivery as a payment method. Made possible by our courier partners.",
  //   basic: <Clear style={{ color: "#C24A39" }} />,
  //   advanced: <CheckOutlined style={{ color: "#59c378" }} />
  // },
  {
    feature: "Discounts and vouchers",
    tooltip: "Create vouchers for your customers to enjoy.",
    basic: <Clear style={{ color: "#C24A39" }} />,
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  },
  {
    feature: "Batch upload",
    tooltip: "Upload numerous products in bulk.",
    basic: <Clear style={{ color: "#C24A39" }} />,
    advanced: <CheckOutlined style={{ color: "#59c378" }} />
  }
  // {
  //   feature: "Media library",
  //   tooltip:
  //     "Upload new and existing product and branding photos to so that you don't need to reupload everytime.",
  //   basic: <Clear style={{ color: "#C24A39" }} />,
  //   advanced: <CheckOutlined style={{ color: "#59c378" }} />
  // }
];
