import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Button,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { Close, AccessTime, ArrowBack, Store } from "@material-ui/icons";
import { Field, reduxForm, change } from "redux-form";
import ReduxGoogleField from "../../../../reduxFields/googleAddressForm";
import ReduxTextField from "../../../../reduxFields/textField";
import BpRadio from "../../../../delivery_settings/components/radioButtons";
import StoreActions, {
  ReduxInitializeFormActions
} from "../../components/StoreActions";
import Skeleton from "@material-ui/lab/Skeleton";
import ReduxSelectField from "../../../../reduxFields/selectField";
import validations from "../../../validations";
import { is_courier_serviceable } from "./isServiceable";
import Utils from "../../../utils";
import { getProductsWeight } from "./getProductWeight";
function CourierSelection(props) {
  const {
    pickup_address,
    mrspeedy_settings,
    ninjavan_settings,
    delivery_details,
    customer_details
  } = props;
  const useStyles = makeStyles(theme => ({
    content: {
      height: "78vh",
      padding: "0 8px",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: 4
      },
      "&::-webkit-scrollbar-track": {
        background: "#eee"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#f97663"
      },
      [theme.breakpoints.down("sm")]: {
        overflowY: "hidden",
        padding: "0 24px"
      }
    },
    drawerPaper: {
      width: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    label: {
      fontSize: 12,
      fontWeight: 600,
      color: "#54575f"
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [is_serviceable, set_is_serviceable] = React.useState({
    ninjavan: true
  });
  const values = useSelector(state => state.form.courier_selection ?.values);
  const errors = useSelector(
    (state => state.form.courier_selection ?.syncErrors : true)
  );
  const loading = useSelector(
    state => state.store.couriers_shipping_fee.loading
  );
  const mrspeedy_shipping_fee = useSelector(
    state => state.store.couriers_shipping_fee.mrspeedy
  );
  const ninjavan_shipping_fee = useSelector(
    state => state.store.couriers_shipping_fee.ninjavan
  );
  const onChange = value => {
    if (!value.includes("Standard")) {
      dispatch(change("courier_selection", "time", undefined));
    }
    dispatch(change("courier_selection", "courier", value));
  };

  const getPickupTime = () => {
    let sizes = [
      { display: "9:00 - 12:00", value: "09:00-12:00" },
      { display: "9:00 - 18:00", value: "09:00-18:00" },
      { display: "9:00 - 22:00", value: "09:00-22:00" },
      { display: "12:00 - 15:00", value: "12:00-15:00" },
      { display: "15:00 - 18:00", value: "15:00-18:00" },
      { display: "18:00 - 22:00", value: "18:00-22:00" }
    ];
    return sizes;
  };

  const handleConfirm = () => {
    let params = {
      ...delivery_details,
      shipping: "courier",
      shippingFee:
        values ?.courier === "mr_speedy"
          ? mrspeedy_shipping_fee
          : ninjavan_shipping_fee,
      delivery_detail: {
        courier: values ?.courier.includes("ninjavan")
          ? "ninjavan"
          : "mr_speedy",
        delivery_type: values ?.courier.includes("Standard")
          ? "Standard"
          : "Same-day" || ""
      }
    };
    if (props.setCourierDetails) {
      props.setCourierDetails(
        values ?.courier === "mr_speedy"
          ? {
            ...params,
            delivery_detail: {
              ...params.delivery_detail,
              landmark: values ?.landmark,
              google_address: values ?.pickup_address
              }
          }
          : {
            ...params,
            delivery_detail: {
              ...params.delivery_detail,
              landmark: customer_details.streetAddress,
              barangay: customer_details.barangayAddress,
              city: customer_details.cityAddress,
              province: customer_details.provinceAddress,
              post_code: customer_details.postalCode,
              weight: getProductsWeight(props.items),
              pickup_time: values ?.time
              }
          }
      );
      props.setOpen(false);
    }
  };

  const isDisabled = () => {
    if (values ?.courier === "mr_speedy") {
      if (
        !values ?.landmark ||
          !values ?.pickup_address ||
          typeof mrspeedy_shipping_fee === "object"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (values ?.courier === "ninjavan-Standard") {
      if (!values ?.time) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  //GET MRSPEEDY SHIPPING FEE
  React.useEffect(() => {
    async function fetchData() {
      if (values ?.pickup_address) {
        dispatch(
          StoreActions.mrspeedyShippingFee({
            matter: '',
            vehicle_type: 8,
            weight_kg: getProductsWeight(props.items),
            motobox: false,
            src_address: props.pickup_google_address.address,
            dst_address: values ?.pickup_address.address,
            src_coord: {
              latitude: props.pickup_google_address ?.lat,
              longitude: props.pickup_google_address.lng
            },
            dst_coord: {
              latitude: values ?.pickup_address.lat,
              longitude: values ?.pickup_address.lng
            }
          })
        );
      }
    }
    fetchData();
  }, [values ?.pickup_address, props.open]);

  //GET NINJAVAN SHIPPING FEE
  React.useEffect(() => {
    if (customer_details) {
      let address = {
        barangay: customer_details.barangayAddress,
        city: customer_details.cityAddress,
        province: customer_details.provinceAddress,
        address_type: "delivery"
      };
      async function fetchNinjavanFee() {
        if ((await is_courier_serviceable(address)) === true) {
          set_is_serviceable({ ...is_serviceable, ninjavan: true });
          dispatch(
            StoreActions.ninjavanShippingFee({
              origin_province: pickup_address && pickup_address[0].province,
              destination_province: customer_details.provinceAddress,
              // weight_kg: 2,
              // l_cm: 1,
              // h_cm: 1,
              // w_cm: 1
              weight_kg: getProductsWeight(props.items),
              l_cm: props.items.length
                ? Math.max(...props.items.map(item => item.length))
                : 1,
              w_cm: props.items.length
                ? Math.max(...props.items.map(item => item.width))
                : 1,
              h_cm: props.items.length
                ? Math.max(...props.items.map(item => item.height))
                : 1
            })
          );
        } else {
          set_is_serviceable({ ...is_serviceable, ninjavan: false });
        }
      }
      fetchNinjavanFee();
    }
  }, [customer_details, props.open]);

  return (
    <Drawer
      id="drawer"
      anchor="right"
      open={props.open}
      classes={{ paper: classes.drawerPaper }}
    >
      <div
        className="px-md-4 px-0 py-md-3 pt-0 position-relative"
        style={{
          width: isMobile ? "auto" : 430,
          height: "100%",
          overflow: "hidden"
        }}
      >
        {isMobile ? (
          <div className="p-3 bg-white border-bottom d-flex position-relative align-items-center">
            <IconButton
              style={{ zIndex: 1 }}
              onClick={() => props.setOpen(false)}
            >
              <ArrowBack color="primary" />
            </IconButton>
            <div
              className="position-absolute w-100 text-center"
              style={{ fontSize: 18, fontWeight: 600, color: "#2b2d32" }}
            >
              Select courier
            </div>
          </div>
        ) : (
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ fontSize: 18, fontWeight: 600, color: "#2b2d32" }}>
                Select courier
            </div>
              <IconButton onClick={() => props.setOpen(false)}>
                <Close style={{ color: "#54575f" }} />
              </IconButton>
            </div>
          )}

        <div className={classes.content}>
          <RadioGroup
            className="mt-3"
            value={values ?.courier}
            onChange={e => onChange(e.target.value)}
          >
            {/*SAME-DAY DELIVERY*/}
            {mrspeedy_settings && mrspeedy_settings.enabled ? (
              <div style={{ borderBottom: "1px dashed #d1d5dd" }}>
                <strong style={{ fontSize: 12, color: "#f97663" }}>
                  Same-day Delivery
                </strong>
                {/*BORZO*/}
                {mrspeedy_settings && mrspeedy_settings.enabled ? (
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      opacity:
                        typeof mrspeedy_shipping_fee === "object" ? 0.7 : 1
                    }}
                    className="pr-3 pl-2 py-3 my-3"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <FormControlLabel
                        id="mr_speedy"
                        value={"mr_speedy"}
                        className="m-0"
                        control={
                          <BpRadio
                            color="primary"
                            disabled={
                              typeof mrspeedy_shipping_fee === "object"
                                ? true
                                : false
                            }
                          />
                        }
                        label={
                          <div className={classes.label}>
                            Borzo{" "}
                            <span style={{ fontWeight: "normal" }}>
                              (Mr. Speedy)
                            </span>
                          </div>
                        }
                      />
                      <div className={classes.label}>
                        {`₱ ${
                          typeof mrspeedy_shipping_fee === "object"
                            ? 0
                            : Utils.commafy(mrspeedy_shipping_fee)
                          }`}
                      </div>
                    </div>
                    {values ?.courier === "mr_speedy" ? (
                      <div>
                        <div style={{ paddingLeft: 36, paddingTop: 12 }}>
                          <Field
                            id="pickup_address"
                            name="pickup_address"
                            label="Delivery address"
                            component={ReduxGoogleField}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                          />
                        </div>
                        <div style={{ paddingLeft: 36, paddingTop: 12 }}>
                          <Field
                            id="landmark"
                            name="landmark"
                            label="Location details, landmark, specific instructions, etc."
                            component={ReduxTextField}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            style={{ backgroundColor: "#fff" }}
                          />
                        </div>
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            ) : (
                ""
              )}

            {/*STANDARD DELIVERY*/}
            {/* <div className="pt-3">
              <strong style={{ fontSize: 12, color: "#f97663" }}>
                Standard Delivery
              </strong> */}
            {/*NINJAVAN*/}
            {/* {ninjavan_settings &&
              ninjavan_settings.enabled &&
              ninjavan_settings.standard_delivery ? (
                <div>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      opacity:
                        typeof ninjavan_shipping_fee === "object" ||
                        !is_serviceable.ninjavan
                          ? 0.7
                          : 1
                    }}
                    className="pr-3 pl-2 py-3 my-3"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <FormControlLabel
                        id="ninjavan-standard"
                        value={"ninjavan-Standard"}
                        className="m-0"
                        control={
                          <BpRadio
                            color="primary"
                            disabled={
                              typeof ninjavan_shipping_fee === "object" ||
                              !is_serviceable.ninjavan
                                ? true
                                : false
                            }
                          />
                        }
                        label={<div className={classes.label}>NinjaVan</div>}
                      />
                      <div className={classes.label}>
                        {loading ? (
                          <Skeleton variant="text" height={25} width={50} />
                        ) : (
                          `₱ ${
                            typeof ninjavan_shipping_fee === "object"
                              ? 0
                              : Utils.commafy(ninjavan_shipping_fee)
                          }`
                        )}
                      </div>
                    </div>
                    {values?.courier === "ninjavan-Standard" ? (
                      <div className="ml-4 mt-2">
                        <Field
                          id="time"
                          name="time"
                          label={
                            <div className={classes.label}>
                              Preferred time of delivery
                            </div>
                          }
                          fullWidth
                          component={ReduxSelectField}
                          variant="outlined"
                          margin="dense"
                          IconComponent={AccessTime}
                          choices={getPickupTime()}
                          validate={
                            values?.courier === "ninjavan-Standard"
                              ? [validations.required]
                              : undefined
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {!is_serviceable.ninjavan ? (
                    <div style={{ fontSize: 10, color: "#f97663" }}>
                      This courier does not service your delivery location.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div> */}
          </RadioGroup>
        </div>

        <div
          className="position-absolute"
          style={{ bottom: 16, left: 24, right: 24 }}
        >
          <Button
            id="confirm-courier"
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            onClick={handleConfirm}
            disabled={isDisabled()}
          >
            Confirm courier
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

let Form = reduxForm({
  form: "courier_selection",
  enableReinitialize: true,
  destroyOnUnmount: false
})(CourierSelection);

export default Form;
