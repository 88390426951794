import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import BuyerActions from "../redux/actions";
const VoucherModal = props => {
  const { open, setOpen } = props;
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 20,
      fontWeight: "bold",
      color: "#2b2d32",
      textAlign: "center"
    },
    card: {
      backgroundColor: "#fcf7e1",
      border: "1px solid #edc143",
      borderRadius: 4,
      boxShadow: "0px 8px 12px rgba(0,0,0,0.05)",
      marginBottom: 10,
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      height: 105,
      cursor: "pointer"
    },
    disabled: {
      opacity: 0.5,
      cursor: "not-allowed"
    },
    voucherContent: {
      padding: 16,
      width: "100%"
    },
    code: {
      fontSize: 14,
      color: "#2b2d32",
      fontWeight: "bold"
    },
    date: {
      fontSize: 12,
      color: "#616675"
    },
    discountSection: {
      backgroundColor: "#f9e9b5",
      width: 160,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    discount: {
      fontSize: 18,
      color: "#2b2d32",
      fontWeight: 500,
      textAlign: "center"
    },
    dialogContent: {
      padding: "0 35px 35px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 20px 20px"
      }
    },
    requirements: {
      position: "absolute",
      bottom: 16,
      right: 16,
      left: 16,
      fontSize: 12,
      color: "#616675"
    }
  }));

  const dispatch = useDispatch();
  const classes = useStyles();
  const store_uuid = useSelector(
    state => state.buyerDashboard.selected_order.store_data?.uuid
  );
  const vouchers = useSelector(state => state.buyerDashboard.voucher_list);
  const cart_amount = useSelector(state => state.buyerDashboard.cart_amount);
  const applied_voucher = useSelector(
    state => state.buyerDashboard.applied_voucher
  );
  const cart = useSelector(
    state => state.buyerDashboard.selected_checkout.items
  );
  function discount(voucher) {
    if (voucher.voucher_type === "fixed_amount") {
      return `${parseFloat(voucher.discount_value) + " OFF"}`;
    } else if (voucher.voucher_type === "percentage") {
      return `${parseFloat(voucher.discount_value)}% OFF`;
    } else {
      return "FREE SHIPPING";
    }
  }

  function voucherRequirements(voucher) {
    if (voucher.minimum_requirements === "Minimum Purchase Amount (PHP)") {
      return `${`Purchase a minimum amount of PHP ${voucher.minimum_purchase_amount}`}`;
    } else if (voucher.minimum_requirements === "Minimum Quantity of items") {
      return `${`Purchase a minimum quantity of ${parseFloat(
        voucher.minimum_purchase_amount
      )} items`}`;
    } else {
      return "Applicable to all purchases";
    }
  }

  function handleSelected(item) {
    if (item.is_applicable) {
      dispatch(BuyerActions.applyVoucher(item));
    }
  }

  React.useEffect(() => {
    let purchase_amount, items_total_qty;
    if (cart.length || open) {
      purchase_amount = cart_amount;
      items_total_qty = cart.reduce((a, b) => a + b.quantity, 0);
    }
    if (store_uuid && cart.length) {
      dispatch(
        BuyerActions.getVouchers(store_uuid, {
          purchase_amount,
          items_total_qty
        })
      );
    }
  }, [store_uuid, cart, open, cart_amount]);

  React.useEffect(() => {
    if (cart.length) {
      const currently_selected_voucher = vouchers.filter(
        a => applied_voucher.voucher_code === a.voucher_code
      );
      if (currently_selected_voucher.length) {
        if (!currently_selected_voucher[0].is_applicable) {
          const applicable_vouchers = vouchers.filter(a => a.is_applicable);
          if (applicable_vouchers.length) {
            dispatch(BuyerActions.applyVoucher(applicable_vouchers[0]));
          } else {
            dispatch(BuyerActions.applyVoucher(""));
          }
        }
      }
    }
  }, [vouchers, cart, applied_voucher, open]);

  return (
    <div>
      <Dialog id="voucher-dialog" fullWidth maxWidth="sm" open={open}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 24px"
          }}
        >
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="secondary" />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.header}>Select a Voucher</div>

          <div
            id="vouchers-div"
            style={{ marginTop: "2vh", overflowY: "auto", height: 400 }}
          >
            {vouchers
              ? vouchers.length
                ? vouchers.map(item => {
                    return (
                      <div
                        className={`${classes.card} voucher-item
                          ${!item.is_applicable ? classes.disabled : ""}`}
                        onClick={() => handleSelected(item)}
                      >
                        <div className={classes.voucherContent}>
                          <div
                            style={{
                              display: "flex",

                              justifyContent: "space-between"
                            }}
                          >
                            <div className={classes.code}>
                              {item.voucher_code}
                            </div>
                            <div
                              style={{
                                display:
                                  applied_voucher.voucher_code ===
                                  item.voucher_code
                                    ? "flex"
                                    : "none",
                                alignItems: "center"
                              }}
                            >
                              <CircleCheckedFilled
                                style={{ color: "#1dd28b", fontSize: 14 }}
                              />
                              <div
                                style={{
                                  marginLeft: 5,
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  color: "#1dd28b"
                                }}
                              >
                                {" "}
                                Applied
                              </div>
                            </div>
                          </div>
                          <div className={classes.date}>
                            {moment(item.start_datetime).format("D MMM YYYY") +
                              " - " +
                              moment(item.end_datetime).format("D MMM YYYY")}
                          </div>
                        </div>
                        <div className={classes.discountSection}>
                          <div className={classes.discount}>
                            {discount(item)}
                          </div>
                        </div>
                        <div className={classes.requirements}>
                          {voucherRequirements(item)}
                        </div>
                      </div>
                    );
                  })
                : ""
              : ""}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VoucherModal;
