import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Button,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Path from "../../../Paths";
import history from "../../../store/history";
import bannerMovie from "../../../assets/images/banner.mp4";
import mobileBanner from "../../../assets/images/mobilePhone.png";
import mobileBG from "../../../assets/images/marketing page assets/banner.png";
import content from "./translation";
import BannerMobileStyles from "./bannerStyles";

let bg = "https://sentro-store-bucker.s3.us-east-2.amazonaws.com/banner.gif";
const Banner = props => {
  const theme = useTheme();
  const classesMobile = BannerMobileStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
  const BannerStyles = makeStyles(theme => ({
    banner: {
      color: "#FAFAFA",
      textAlign: "center",
      width: "100%",
      position: "absolute",
      [theme.breakpoints.down("xl")]: {
        bottom: 200
      },
      [theme.breakpoints.down("lg")]: {
        bottom: 140
      },
      [theme.breakpoints.down("md")]: {
        bottom: "7vh"
      }
    },
    mobileView: {
      display: "flex",
      backgroundImage: `url(${mobileBG})`,
      height: "100vh"

      // display: "flex",
      // alignItems: "center",
      // [theme.breakpoints.down("sm")]: {
      //   backgroundImage: `url(${mobileBG})`,
      //   height: "100vh"
      // },
      // [theme.breakpoints.up("sm")]: {
      //   display: "none"
      // }
    },
    mobileBanner: {
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#C24A39",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      [theme.breakpoints.up("lg")]: {
        display: "none"
      }
    },
    webView: {
      position: "relative"
    },
    bannerContent: {
      [theme.breakpoints.up("xl")]: {
        paddingTop: "65vh"
      },
      [theme.breakpoints.down("lg")]: {
        paddingTop: "70vh"
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "35%"
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "24vh"
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: "40vh"
      }
    },
    bannerText: {
      fontWeight: 600,
      fontSize: 32,
      [theme.breakpoints.down("md")]: {
        fontSize: 32
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 26
      },
      [theme.breakpoints.down("xs")]: {
        color: "#002C42",
        fontSize: 26
      }
    },

    bannerSubText: {
      fontSize: 16,
      padding: "0 18em",
      [theme.breakpoints.down("md")]: {
        fontSize: 16
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      },

      [theme.breakpoints.down("xs")]: {
        color: "#002C42",
        fontSize: 12
      }
    },

    bannerSlogan: {
      color: "#FAFAFA",
      padding: "0 180px",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        padding: "0 115px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: 0
      },
      [theme.breakpoints.down("xs")]: {
        color: "#002C42",
        padding: "0 18px",
        marginBottom: "2vh",
        marginTop: "1vh"
      }
    },
    btnSentro: {
      width: "300px",
      marginTop: 30,
      height: 64,
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      "&:hover": {
        backgroundColor: "#DD5e56"
      },

      [theme.breakpoints.down("sm")]: {
        height: 48
      },
      [theme.breakpoints.down("xs")]: {
        width: "320px"
      }
    },

    container: {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    mobileBannerSize: {
      width: "auto",
      [theme.breakpoints.down("sm")]: {
        height: "35vh"
      },
      [theme.breakpoints.down("xs")]: {
        height: "40vh"
      }
    },
    bannerPadding: {
      padding: "0 8em",
      [theme.breakpoints.down("xs")]: {
        padding: 0
      }
    },
    bannerSUBText: {
      padding: "0 18em",
      [theme.breakpoints.up("xl")]: {
        padding: "0 32em"
      }
    }
  }));

  const classes = BannerStyles();

  return (
    <>
      {!mobileView ? (
        <div className={classes.webView}>
          <video
            loop="loop"
            autoPlay="autoplay"
            style={{
              zIndex: -10,
              width: "100%"
            }}
          >
            <source src={bannerMovie} type="video/mp4" />
          </video>
          <div className={classes.banner}>
            <div className={classes.bannerText}>
              {content[props.language ? props.language : "English"].banner}
            </div>
            <div className={classes.bannerSUBText}>
              {content[props.language ? props.language : "English"].subheader}
            </div>
            <Button
              onClick={() => history.push(Path.SIGN_UP)}
              color="primary"
              id="getStarted_button"
              style={{
                // marginTop: "2vh",
                fontSize: 16,
                fontWeight: 600,
                height: 60
              }}
              variant="contained"
              className={classes.btnSentro}
              size="large"
            >
              <div style={{ display: "block" }}>
                {
                  content[props.language ? props.language : "English"]
                    .bannerButton
                }
                <br />
                <div style={{ fontSize: 10 }}>
                  {props.language === "Filipino"
                    ? "Bago pa maging huli ang lahat chos"
                    : ""}
                </div>
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.mobileView}>
          <div style={{ display: "block", width: "100%", marginTop: "8vh" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "6vh"
              }}
            >
              <img className={classes.mobileBannerSize} src={mobileBanner} />
            </div>
            <div className={classes.bannerPadding}>
              <Box>
                <div className={classesMobile.headerMobile}>
                  {content[props.language ? props.language : "English"].banner}
                </div>
              </Box>
              <Box style={{ padding: "0 1em" }}>
                <div className={classesMobile.subHeaderMobile}>
                  {
                    content[props.language ? props.language : "English"]
                      .subheader
                  }
                </div>
              </Box>
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Button
                  fullWidth
                  onClick={() => history.push(Path.SIGN_UP)}
                  id="getStarted_button"
                  variant="contained"
                  className={classesMobile.getstartedMobile}
                  size="large"
                >
                  <div style={{ display: "block" }}>
                    {
                      content[props.language ? props.language : "English"]
                        .bannerButton
                    }
                    <br />
                    <div style={{ fontSize: 10 }}>
                      {props.language === "Filipino"
                        ? "Bago pa maging huli ang lahat chos"
                        : ""}
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
