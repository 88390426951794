import axios from "axios";
import apiConfig from "../../common/apiConfig";
import SubscriptionServices from "../subscription/redux/services";
import moment from "moment";
export const USERNAME = "CURRENT_USERNAME_APP";
export const PASSWORD = "CURRENT_PASSWORD_APP";
const CURRENT_USER = "CURRENT_USER";
let count = 0;
async function authUser(value) {
  let params = {
    email: value.username,
    password: value.password
  };

  try {
    let response = await axios.post(`${apiConfig.apiURL}/api/login/`, params);
    let data = response.data;
    if (data.token != null && data.token != "") {
      sessionStorage.setItem("TOKEN", data.token);
      sessionStorage.setItem(CURRENT_USER, JSON.stringify(data.user));
      localStorage.setItem("TOKEN", data.token);
      localStorage.setItem(CURRENT_USER, JSON.stringify(data.user));
      localStorage.setItem("language", "English");

      let subscription = await SubscriptionServices.getSubscription();
      if (
        !subscription.data.subscription_type &&
        (!subscription.data.free_trial_availed ||
          subscription.data.free_trial_availed)
      ) {
        sessionStorage.setItem(
          "subscription",
          JSON.stringify({ show_banner: true })
        );
      }
      // } else if (subscription.data.expiry_date) {
      //   let current_date = new Date();
      //   let expiration = new Date(subscription.data.expiry_date);
      //   let start = Math.floor(current_date.getTime() / (3600 * 24 * 1000));
      //   let end = Math.floor(expiration.getTime() / (3600 * 24 * 1000));
      //   let daysDiff = end - start;
      //   //DISPLAY BANNER IF FREE TRIAL WILL END IN 5 days below
      //   // if (daysDiff <= 5) {
      //   //   sessionStorage.setItem(
      //   //     "subscription",
      //   //     JSON.stringify({ show_banner: true })
      //   //   );
      //   // }
      // }
    }

    return data;
  } catch (error) {
    count = count + 1;

    if (count == 5) {
      throw error.response;
    } else {
      throw error.response;
    }
  }
}

async function logout() {
  try {
    localStorage.removeItem(CURRENT_USER);
    localStorage.removeItem("TOKEN");
    sessionStorage.removeItem(CURRENT_USER);
    sessionStorage.removeItem("TOKEN");
  } catch (error) {
    throw new error.response();
  }
}

async function fbSignUp(value) {
  let params = {
    provider: "facebook",
    access_token: value.accessToken
  };
  try {
    let response = await axios.post(
      `${apiConfig.apiURL}/api/oauth/login/`,
      params
    );
    let data = response.data;
    sessionStorage.setItem("TOKEN", data.token);
    sessionStorage.setItem(CURRENT_USER, JSON.stringify(data.user));
    localStorage.setItem("TOKEN", data.token);
    localStorage.setItem(CURRENT_USER, JSON.stringify(data.user));

    let user = sessionStorage.getItem("CURRENT_USER");
    user = user ? JSON.parse(user) : {};

    user["is_facebookLogin"] = true;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(user));

    return data;
  } catch (error) {
    throw error.response;
  }
}

export { authUser, fbSignUp, logout };
