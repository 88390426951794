import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { AppBar, Tabs, Box, Typography } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import TNC from "./tnc";
import PrivacyPolicy from "./privacyPolicy";
import { useLocation } from "react-router-dom";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#fff",
    width: "100%"
  },
  tabs: {
    backgroundColor: "#fff"
  },
  appBar: {
    zIndex: 1,
    position: "sticky",
    top: "4em",
    boxShadow: "none"
  },
  banner: {
    backgroundColor: "#002c42",
    width: "100%",
    textAlign: "center",
    padding: "15vh 5vw 4vh"
  }
}));
const Content = props => {
  const location = useLocation();
  const currentLoc = location.pathname;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const bodyFontColor = "#54575F";
  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  React.useEffect(() => {
    if (currentLoc === "/privacy_policy") {
      setValue(1);
    } else if (currentLoc === "/terms_and_conditions") {
      setValue(0);
    }
  }, [currentLoc]);

  return (
    <div>
      <div className={classes.banner}>
        <div style={{ fontSize: 24, color: "#fff", fontWeight: "bold" }}>
          Terms and Policies
        </div>
        <div style={{ fontSize: 12, color: "#fff", fontWeight: 500 }}>
          By using Sentro, you agree to our house rules
        </div>
      </div>
      <div className={classes.root}>
        <AppBar className={classes.appBar} color="default">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Terms and Conditions" {...a11yProps(0)} />
            <Tab label="Privacy Policy" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div>
              <TNC />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <div>
              <PrivacyPolicy />
            </div>
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
};

export default Content;
