import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";
import ReduxTextField from "../../../../reduxFields/textField";
import LargeImageField from "../../../../reduxFields/largeImageField";
import validations from "../../../../common/validations";
import QuickOrderSettings from ".";
import {
  Button,
  FormControlLabel,
  RadioGroup,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { LocalShippingOutlined } from "@material-ui/icons";
import squareIcon from "../../../../../assets/images/square.png";
import landscapeIcon from "../../../../../assets/images/landscape.png";
import portraitIcon from "../../../../../assets/images/portrait.png";
import BpRadio from "../../../../delivery_settings/components/radioButtons";
import { ReduxInitializeFormActions } from "../../../websiteBuilderActions";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Path from "../../../../../Paths";
function QuickOrderForm(props) {
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 16,
      fontWeight: 600,
      color: "#FF6B55"
    },
    label1: {
      fontSize: 14,
      fontWeight: 500,
      color: "rgba(43, 45, 50, 0.8)"
    },
    label2: {
      fontSize: 12,
      fontWeight: 400,
      color: "rgba(43, 45, 50, 0.8)"
    },
    btnProduct: {
      cursor: "pointer",
      border: "1px solid",
      borderRadius: 4,
      width: 138,
      maxWidth: 138,
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        maxWidth: "inherit"
      }
    },
    input: {
      backgroundColor: "#fff"
    }
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [product_orientation, set_product_orientation] = React.useState("");

  const product_display_orientation = [
    { label: "Freeform", orientation: "Mixed ratio", icon: squareIcon },
    { label: "Square", orientation: "600x600 px", icon: squareIcon },
    { label: "Portrait", orientation: "600x800 px", icon: portraitIcon },
    { label: "Landscape", orientation: "800x600 px", icon: landscapeIcon }
  ];

  React.useEffect(() => {
    if (product_orientation) {
      dispatch(
        change(
          "quick_order_settings",
          "product_orientation",
          product_orientation.toLowerCase()
        )
      );
    }
  }, [product_orientation]);

  return (
    <>
      <div
        style={{
          padding: isMobile ? 24 : 36,
          borderBottom: "1px solid #d4d5d8"
        }}
      >
        <div className={classes.header}>General</div>
        <div>
          <div className={`${classes.label1} pt-3`}>Form intro / write-up</div>
          <div className={classes.label2}>
            This will appear as basic instructions or introduction at the start
            of your form.
          </div>
          <Field
            id="write_up"
            name="write_up"
            component={ReduxTextField}
            multiline
            validate={[validations.required]}
            inputProps={{ maxlength: 255 }}
          />
        </div>
        <div className="py-3">
          <Field
            fullWidth
            id="quick_order_cover_image"
            name="quick_order_cover_image"
            label="Cover Photo"
            component={LargeImageField}
            useStockPhoto
            useCropper
            maxSize="2 MB"
            maxSizeValue={2000000}
            defaultCropperRatio="landscape"
            height={280}
          />
        </div>
      </div>
      <div
        style={{
          padding: isMobile ? 24 : 36
          // borderBottom: "1px solid #d4d5d8"
        }}
      >
        <div className={classes.header}>Delivery</div>
        <div>
          <div className={`${classes.label1} pt-2`}>
            Add fixed rates, enable couriers, and set pickup location.
          </div>
          <Button
            id="delivery_settings_btn"
            className="mt-3"
            style={{ height: 40 }}
            variant="outlined"
            color="primary"
            startIcon={<LocalShippingOutlined />}
            onClick={() => props.gotoDeliverySettings(true)}
          >
            Manage Delivery Settings
          </Button>
        </div>
      </div>
      {/* <div style={{ padding: isMobile ? 24 : 36 }}>
        <div className={classes.header}>Product display</div>
        <div>
          <div className={`${classes.label1} pt-2`}>
            Select your preferred product display orientation.
          </div>
          <div className="mt-3 pt-2">
            <RadioGroup
              style={{
                flexDirection: isMobile ? "column" : "row",
                flexWrap: "inherit"
              }}
              value={product_orientation}
              onChange={e => set_product_orientation(e.target.value)}
            >
              {product_display_orientation.map(item => (
                <div
                  className={`${classes.btnProduct} d-flex align-items-center justify-content-center text-center mr-md-3 mr-0 mb-md-0 mb-3 pt-md-4 pt-0 pb-2`}
                  style={{
                    borderColor:
                      product_orientation === item.label ? "#ff6b55" : "#d4d5d8"
                  }}
                  onClick={() => set_product_orientation(item.label)}
                >
                  <div
                    className="align-items-center p-md-0 p-3"
                    style={{
                      width: "100%",
                      display: isMobile ? "flex" : "block",
                      justifyContent: "space-between",
                      flexDirection: isMobile ? "row-reverse" : "column"
                    }}
                  >
                    <img src={item.icon} alt="" width="auto" height="20" />
                    <div className="d-md-block d-none">
                      <div
                        className="my-1"
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#2b2d32"
                        }}
                      >
                        {item.label}
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: "#858997"
                        }}
                      >
                        {item.orientation}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <FormControlLabel
                        value={item.label}
                        className="m-0 pt-2"
                        control={<BpRadio color="primary" />}
                      />
                      <div className="ml-3 d-md-none text-left d-block">
                        <div
                          className="my-1"
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#2b2d32"
                          }}
                        >
                          {item.label}
                        </div>
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#858997"
                          }}
                        >
                          {item.orientation}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </RadioGroup>
          </div>
        </div>
      </div> */}
    </>
  );
}

let Form = reduxForm({
  form: "quick_order_settings",
  keepDirtyOnReinitialize: true
})(QuickOrderForm);

Form = connect(
  state => ({
    initialValues: state.webBuilder.reduxForm.quickOrderSettings
  }),
  {
    initializeData: ReduxInitializeFormActions.initializeQuickOrderSettingsData
  }
)(Form);

export default Form;
