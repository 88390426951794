const storePaymentContent = {
  English: {
    paymentHeader: "How can your customers pay you?",
    buxHeader: "Accept various kinds of payment methods",
    buxContent1: "1. Online payment via online banking of our partner banks",
    buxContent2: "2. Cash payment via our partner establishments",
    buxSubheader1:
      "All payments, regardless of the method chosen by the customer, will go straight to your Bux Wallet, net of applicable fees.* You can then withdraw from your Bux Wallet to your bank or online wallet via real time online transfer",
    buxSubheader2:
      "The amount you can receive and withdraw in your wallet is subject to limits depending on your account type with Bux. To know more ",
    otherPaymentHeader:
      "Indicate your other accounts where your customers can pay",
    otherPaymentSubheader:
      "You can add details of your other bank, GCash, Paymaya or PayPal accounts so you can accommodate more customers.",
    otherPaymentNote:
      "Take note that payments made through these channels cannot be tracked real-time via your Sales and Orders dashboard"
  },
  Filipino: {
    paymentHeader: "Paano magbabayad ang iyong mga customer?",
    buxHeader:
      "Tumanggap ng iba't ibang paraan ng bayad galing sa iyong mga customers",
    buxContent1:
      "1. Online payment gamit ang online banking ng aming mga partner banks",
    buxContent2: "2. Cash payments gamit ang aming mga partner establishments",
    buxSubheader1:
      "Lahat ng bayad, kahit sa anong payment channel nanggaling, ay mapupunta deretso sa iyong Bux Wallet (nakabawas na ang mga applicable fees).* Pwede mong i-withdraw ang laman ng iyong Bux Wallet (in real time) sa iyong bangko o online wallet gamit ang real time online transfer",
    buxSubheader2:
      "Ang amount na pwede mong matanggap at i-withdraw sa iyong wallet ay depende sa iyong account type sa Bux. Para sa ibang detalye, ",
    otherPaymentHeader:
      "Ilagay rito ang iba pang paraan kung paano ka babayaran ng iyong mga customers",
    otherPaymentSubheader:
      "Pwede mong ilagay ang detalye ng iyong bank, GCash, Paymaya o Paypal account para mas marami ka pang mabentahan. ",
    otherPaymentNote:
      "Take note: kapag nagbayad ang customer gamit ang mga channels na ito, hindi ito makikita sa iyong real-time Sales and Orders Dashboard"
  }
};

export default storePaymentContent;
