import React from "react";
import { Dialog, Grow, IconButton } from "@material-ui/core";
import icon from "../../../../assets/images/mediaLibraryIcon.svg";
import { Close } from "@material-ui/icons";
import square from "../../../../assets/images/square.png";
import portrait from "../../../../assets/images/portrait.png";
import landscape from "../../../../assets/images/landscape.png";
import { makeStyles } from "@material-ui/styles";
const Transition = props => <Grow {...props} />;

export default function ImageTips(props) {
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 14,
      fontWeight: 600,
      color: "#54575f",
      margin: "5px 0"
    }
  }));
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="xs"
      TransitionComponent={Transition}
    >
      <div className="d-flex justify-content-end">
        <IconButton onClick={() => props.setOpen(false)}>
          <Close color="secondary" />
        </IconButton>
      </div>
      <div className="px-md-4 px-2 pb-4 d-flex align-items-center justify-content-center text-center">
        <div>
          <img src={icon} alt="img" width="70" height="70" />
          <div className="my-3" style={{ fontSize: 20, fontWeight: 600 }}>
            {props.is_product_image_tips
              ? "Product dimensions"
              : "Logo dimensions"}
          </div>
          <div className="px-4" style={{ fontSize: 14, color: "#54575f" }}>
            {props.is_product_image_tips
              ? "For best photo quality, upload product images larger than the following measurements."
              : "Upload a logo with transparent background for better results."}
          </div>
          {props.is_product_image_tips ? (
            <div className="mt-4 pb-4 col-12 d-flex justify-content-center align-items-center text-center">
              <div className="col-4 p-0">
                <img src={square} alt="square" width="auto" height="15" />
                <div className={classes.header}>Square</div>
                <div style={{ fontSize: 12, color: "#54575f" }}>600x600 px</div>
              </div>
              <div className="col-4 p-0">
                <img src={portrait} alt="square" width="auto" height="20" />
                <div className={classes.header}>Portrait</div>
                <div style={{ fontSize: 12, color: "#54575f" }}>600x800 px</div>
              </div>
              <div className="col-4 p-0">
                <img src={landscape} alt="square" width="auto" height="15" />
                <div className={classes.header}>Landscape</div>
                <div style={{ fontSize: 12, color: "#54575f" }}>800x600 px</div>
              </div>
            </div>
          ) : (
            <div className="mt-4 pb-4 col-12 d-flex justify-content-center align-items-center text-center">
              <div className="col-6 p-0">
                <img src={square} alt="square" width="auto" height="15" />
                <div className={classes.header}>Square</div>
                <div style={{ fontSize: 12, color: "#54575f" }}>600x600 px</div>
              </div>
              <div className="col-6 p-0">
                <img src={landscape} alt="square" width="auto" height="15" />
                <div className={classes.header}>Landscape</div>
                <div style={{ fontSize: 12, color: "#54575f" }}>800x600 px</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
