import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Menu,
  MenuItem
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import moment from "moment";
import HeaderFilter from "../enterprise/components/dashboard/analytics/headerFilters";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Tooltip
} from "@devexpress/dx-react-chart-material-ui";
import { scaleTime } from "d3-scale";
import { ArgumentScale } from "@devexpress/dx-react-chart";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing
} from "@devexpress/dx-react-grid-material-ui";
import { Animation, EventTracker } from "@devexpress/dx-react-chart";
import FreeTrial from "../subscription/freeTrial";
import DashboardActions from "../dashboard/dashboardActions";
import Utils from "../common/utils";

const AnalyticsPage = props => {
  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "#FAFAFA",
      overflowX: "hidden"
    },
    paper: {
      backgroundColor: "#fff",
      border: "1px solid #eee",
      marginTop: 32,
      borderRadius: 4
    },
    merchants: {
      display: "flex",
      flexDirection: "column",
      "& .number": {
        color: "#1e4b83",
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 5
      },
      "& .divider": {
        borderRadius: 4,
        marginBottom: "1em",
        marginRight: "2em"
      },
      "& .sales": {
        border: `4px solid #18b4a1`
      },
      "& .orders": {
        border: `4px solid #3378b4`
      },
      "& .sales-per-order": {
        border: `4px solid #ebb83f`
      }
    },
    summary: {
      marginTop: 20,
      marginLeft: 32,
      marginRight: "20em"
    },
    trends: {
      margin: 32,
      "& .header": {
        color: "#2B2D32",
        fontWeight: 600,
        fontSize: 18
      }
    },
    rankHeader: {
      margin: 20,
      color: "#2B2D32",
      fontWeight: 600,
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& sorter": {
        fontSize: 12,
        height: 16,
        color: "#2B2D32"
      }
    },
    ranks: {
      "& thead": {
        fontSize: 12,
        color: "rgba(43, 45, 50, 0.8)",
        background: "#F5F5F5"
      },
      margin: 20,
      "& .data": {
        fontSize: 14
      }
    },
    chartTooltipSheet: {
      padding: 15,
      background: "#002C42",
      borderRadius: 4
    },
    chartTooltipContent: {
      "& p": {
        color: "white",
        fontSize: 12,
        marginBottom: 0
      }
    },
    chartTooltipArrow: {
      height: 15,
      width: 15,
      background: "#002C42",
      transform: "rotate(45deg) translateX(-50%) translateY(-5px)",
      marginLeft: "45%"
    },
    datetime_display: {
      fontSize: 13
    }
  }));
  const classes = useStyles();
  const subscription = useSelector(state => state.subscription.subscription);
  const analytics = useSelector(state => state.dashboard.analytics);

  const [params, setParams] = React.useState({
    start_date: moment(new Date())
      .subtract(7, "d")
      .format("MM/DD/YYYY"),
    end_date: moment(new Date()).format("MM/DD/YYYY"),
    top_products_sort_by: "total_sales",
    top_category_sort_by: "total_sales"
  });

  const [chart, setChartTemp] = React.useState({
    sales: true,
    sales_per_order: true
  });
  const setChart = (key, checked) => {
    setChartTemp(state => ({
      ...state,
      [key]: checked
    }));
  };

  React.useEffect(() => {
    dispatch(DashboardActions.getAnalytics(params));
  }, [dispatch, params]);

  const setFilters = filters => {
    setParams(state => ({
      ...params,
      ...filters
    }));
  };

  const sales = (
    <div className="pt-3 px-0 col-12 d-flex">
      <div className={`${classes.merchants} col-6 p-0`}>
        <p className="number sales-number">
          ₱ {Utils.commafy(analytics.sales)}
        </p>
        <div className="divider sales"></div>
        <div style={{ fontSize: 12, textAlign: "left" }}>Sales</div>
      </div>
      <div className={`${classes.merchants} col-6 p-0`}>
        <p className="number sales_per_order-number">
          ₱ {Utils.commafy(analytics.sales_per_order)}
        </p>
        <div className="divider sales-per-order"></div>
        <div style={{ fontSize: 12, textAlign: "left" }}>Sales per order</div>
      </div>
    </div>
  );

  const trends = (
    <div className="chart">
      <Chart data={analytics.chart} height={400}>
        <ArgumentScale factory={scaleTime} />
        <ArgumentAxis />
        <LineSeries
          name="Sales per order"
          valueField="sales_per_order"
          argumentField="x"
          color={chart.sales_per_order ? "#ebb83f" : "#fff"}
        />
        <LineSeries
          name="Sales"
          valueField="sales"
          argumentField="x"
          color={chart.sales ? "#18b4a1" : "#fff"}
        />
        {/* <Animation /> */}
        <EventTracker />
        <Tooltip
          contentComponent={e => {
            return (
              <div className={classes.chartTooltipContent}>
                <p>
                  Fulfilled: {analytics.chart[e.targetItem.point].orders_count}
                </p>
                {e.targetItem.series === "Sales" ? (
                  <p>Amount: ₱ {Utils.commafy(e.text)}</p>
                ) : (
                  <p>
                    Sales per order: ₱{" "}
                    {Utils.commafy(
                      analytics.chart[e.targetItem.point].sales_per_order
                    )}
                  </p>
                )}
                <p>Date: {analytics.chart[e.targetItem.point].date}</p>
              </div>
            );
          }}
          sheetComponent={e => (
            <div className={classes.chartTooltipSheet}>{e.children}</div>
          )}
          arrowComponent={e => {
            return <div className={classes.chartTooltipArrow} />;
          }}
        />

        <ValueAxis />
      </Chart>
    </div>
  );

  const [anchorElProduct, setAnchorElProduct] = React.useState(null);
  const [anchorElCategory, setAnchorElCategory] = React.useState(null);

  const handleClick = event => {
    setAnchorElProduct(event.currentTarget);
  };
  const handleClickCategory = event => {
    setAnchorElCategory(event.currentTarget);
  };

  const handleClose = (key, value) => {
    setAnchorElProduct(null);
    setAnchorElCategory(null);
    setParams(state => ({
      ...state,
      [key]: value
    }));
  };

  const product_columns = [
    { name: "id", title: " " },
    { name: "description", title: "Description" },
    { name: "value", title: "Total Sales" }
  ];

  const [productColumnWidths] = useState([
    { columnName: "id", width: 70 },
    { columnName: "description", width: 180 },
    { columnName: "value", width: 120 }
  ]);

  const category_columns = [
    { name: "id", title: " " },
    { name: "category", title: "Category" },
    { name: "value", title: "Total Sales" }
  ];

  const [categoryColumnWidths] = useState([
    { columnName: "id", width: 70 },
    { columnName: "category", width: 180 },
    { columnName: "value", width: 120 }
  ]);

  const [productColumnExtensions] = useState([
    { columnName: "description", fontSize: 14, fontWeight: 600 }
  ]);

  const top_products = (
    <div className="topProducts">
      <Grid rows={analytics.top_products} columns={product_columns}>
        <Table columnExtensions={productColumnExtensions} />
        <TableColumnResizing columnWidths={productColumnWidths} />
        <TableHeaderRow
          cellComponent={props => (
            <TableHeaderRow.Cell {...props} resizingEnabled={false} />
          )}
        />
      </Grid>
    </div>
  );

  const top_categories = (
    <div className="topCategories">
      <Grid rows={analytics.top_categories} columns={category_columns}>
        <Table />
        <TableColumnResizing defaultColumnWidths={categoryColumnWidths} />
        <TableHeaderRow
          cellComponent={props => (
            <TableHeaderRow.Cell {...props} resizingEnabled={false} />
          )}
        />
      </Grid>
    </div>
  );

  const datetime_display = () => {
    let startdate = params.start_date;
    let enddate = params.end_date;
    const now = moment(new Date()).format("DD MMM YYYY");
    if (moment(params.start_date).isSame(now) && 
        moment(params.end_date).isSame(now)) {
      startdate = "Today";
      enddate = "Today";
    }
    return (
      <p className={`text-secondary ${classes.datetime_display}`}>
        {startdate} 0:00 AM - {enddate} 12:59 PM
      </p>
    )
  }

  return (
    <>
      {!subscription.subscription_type ||
      (subscription.subscription_type && !subscription.features.analytics) ? (
        <>
          <div
            style={{ fontSize: 28, fontWeight: 600, color: "#002c42" }}
            className={"mb-4"}
          >
            Analytics
          </div>
          <FreeTrial
            header={"Track how your products perform"}
            subheader={
              "Get daily insights on your best-selling products, top categories, and site visits"
            }
          />
        </>
      ) : (
        <div className={classes.root}>
          <HeaderFilter onChange={setFilters} />
          {datetime_display()}
          <div className={classes.paper}>
            <div className={classes.summary}>{sales}</div>
            <div className={classes.trends}>
              <div className="d-flex">
                <div className="header mr-auto align-items-center">Trends</div>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chart.sales}
                        onChange={e => setChart("sales", e.target.checked)}
                        name="sales"
                        color="primary"
                      />
                    }
                    label="Sales"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chart.sales_per_order}
                        onChange={e =>
                          setChart("sales_per_order", e.target.checked)
                        }
                        name="sales_per_order"
                        color="primary"
                      />
                    }
                    label="Sales per order"
                  />
                </FormGroup>
              </div>
              {analytics.chart.length ? trends : ""}
            </div>
          </div>
          <div className="d-flex space-between">
            <div
              className={`${classes.paper} col-6`}
              style={{ flex: 1, marginRight: 24 }}
            >
              <div className={classes.rankHeader}>
                <span>Top products</span>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <span>
                    {params.top_products_sort_by === "total_sales"
                      ? "By sales"
                      : "By quantity"}
                  </span>
                  <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorElProduct}
                  keepMounted
                  open={Boolean(anchorElProduct)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose("top_products_sort_by", "total_sales");
                    }}
                  >
                    By sales
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("top_products_sort_by", "quantity");
                    }}
                  >
                    By quantity
                  </MenuItem>
                </Menu>
              </div>
              <div className={classes.ranks}>{top_products}</div>
            </div>
            <div
              className={`${classes.paper} col-6`}
              style={{ flex: 1, marginLeft: 24 }}
            >
              <div className={classes.rankHeader}>
                <span>Top categories</span>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClickCategory}
                >
                  <span>
                    {params.top_category_sort_by === "total_sales"
                      ? "By sales"
                      : "By quantity"}
                  </span>
                  <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorElCategory}
                  keepMounted
                  open={Boolean(anchorElCategory)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose("top_category_sort_by", "total_sales");
                    }}
                  >
                    By sales
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose("top_category_sort_by", "quantity");
                    }}
                  >
                    By quantity
                  </MenuItem>
                </Menu>
              </div>
              <div className={classes.ranks}>{top_categories}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AnalyticsPage;
