import React from "react";
import { useHistory } from "react-router";
import { useDispatch, connect, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/styles";
import SwipeableViews from "react-swipeable-views";
import {
  Button,
  Tabs,
  AppBar,
  Tab,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  Slide,
  Toolbar,
  IconButton,
  Zoom,
} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DashboardLayout from "../dashboard/dashboardLayout";
import CourierPane from "./components/panes/courier";
import LocationPane from "./components/panes/location";
import PickupPane from "./components/panes/pickup";
import { reduxForm, stopSubmit, change } from "redux-form"; // ES6
import DashboardActions from "../dashboard/dashboardActions";
import Actions, { ReduxInitializeFormActions } from "./redux/actions";
import SentroLoading from "../common/loading";
import WelcomeModal from "./components/welcomeModal";
import BlockDatesPane from "./components/panes/blockDates";
import axios from "axios";
import apiConfig from "../../common/apiConfig";
import { showAlert } from "../common/commonActions";
import moment from "moment";
import { Error } from "@material-ui/icons";

const DialogTransition = (props) => <Slide direction="up" {...props} />;
const ZoomTransition = (props) => <Zoom {...props} />;
function a11yProps(index, is_mobile) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: {
      minWidth: is_mobile ? "33%" : 100,
    },
  };
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const SaveChangesModal = (props) => {
  const history = useHistory();
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="xs"
      TransitionComponent={ZoomTransition}
    >
      <div className="p-4">
        <div className="d-flex justify-content-center">
          <Error style={{ color: "#C24A39", fontSize: 80 }} />
        </div>
        <div
          className="text-center pt-3"
          style={{ fontSize: 18, fontWeight: 600 }}
        >
          Changes will not be saved if you leave. Do you want to continue?
        </div>
        <div className="d-flex pt-4">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className="w-50 mr-2"
            onClick={() => {
              props.setOpen(false);
              history.push("/dashboard");
            }}
          >
            Exit
          </Button>
          <Button
            disableElevation
            size="small"
            variant="contained"
            color="primary"
            className="w-50"
            onClick={() => {
              props.onSubmit();
              history.push("/dashboard");
            }}
          >
            Save changes
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const DeliveryPage = (props) => {
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const CustomTabs = withStyles({
    root: {
      position: "relative",
      backgroundColor: "white",
      boxShadow: "none",
      borderBottom: `${is_mobile ? 0 : "1px"} solid #d1d5dd`,
      padding: "0 2em",
      marginTop: 0,
      width: "auto",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        position: "fixed",
        top: 56,
        width: "100%",
      },
    },
    indicator: {
      backgroundColor: "#f97663",
      height: 3,
    },
  })(Tabs);
  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 750,
      margin: "2em auto",
      overflow: "hidden",
    },
    slideWrapper: {
      transition: "all 0.3s cubic-bezier(0, 0, 0.38, 1.1)",
      display: "flex",
      width: "200%",
    },
    headerSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    content: {
      marginTop: "2em",
      left: "32vw",
      right: "10vw",
      borderTop: "1px solid #D4D5D8",
      borderLeft: "1px solid #D4D5D8",
      borderRight: "1px solid #D4D5D8",
      borderBottom: "none",
      borderRadius: 4,
      overflow: "visible",
    },
    btnDiv: {
      borderBottom: "1px solid #D4D5D8",
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      paddingTop: 0,
      paddingLeft: "3rem",
      paddingRight: "3rem",
      paddingBottom: "2em",
    },
    appbar: {
      backgroundColor: "white",
      boxShadow: "none",
    },
    tabs: {
      position: "relative",
      backgroundColor: "white",
      boxShadow: "none",
      borderBottom: `${is_mobile ? 0 : "1px"} solid #d1d5dd`,
      padding: "0 2em",
      marginTop: 0,
      width: "auto",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        position: "fixed",
        top: 56,
        width: "100%",
      },
    },
    details: {
      backgroundColor: "white",
    },
    tabPanel: {
      "& > div": {
        padding: "0px !important",
      },
    },
    tabsBorderBottom: {
      borderBottom: "3px solid #EEEEEE",
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%",
    },
    closeBtn: {
      position: "absolute",
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const webBuilderLoading = useSelector((state) => state.webBuilder.loading);
  const deliverySettings = useSelector((state) => state.deliverySettings);
  const pickup_addresses_count = deliverySettings?.pickup_addresses.count;
  const to_delete_pickup_address = deliverySettings?.to_delete_pickup_address;
  const [blockDates, setBlockDates] = React.useState([]);
  const [site, setSite] = React.useState("");
  const [store_uuid, set_store_uuid] = React.useState("");
  const [is_show_pickup, set_is_show_pickup] = React.useState(false);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    dispatch(Actions.initialState());
  }, []);

  React.useEffect(() => {
    const getStores = async () => {
      let res = await dispatch(DashboardActions.getStores());
      if (res.length) {
        set_store_uuid(res[0].uuid);
        const store_uuid = res[0].uuid;
        setSite(res[0].site);
        if (res[0].blocked_dates?.length) {
          let removePastDates = res[0].blocked_dates.filter(
            (i) => moment(i).format("X") > moment().format("X")
          );
          setBlockDates(removePastDates);
        } else {
          setBlockDates([]);
        }
        res = await dispatch(
          Actions.getPickupAddress({
            store_uuid: store_uuid,
            page: page,
            itemsPerPage: 5,
          })
        );
        if (typeof res === "object" && res.length) {
          let show_pickup_address = res.map((i) => i.show_pickup_address);
          let allEqual = show_pickup_address.every(
            (val, i, arr) => val === arr[0]
          );
          if (allEqual) {
            set_is_show_pickup(show_pickup_address[0]);
          } else {
            set_is_show_pickup(false);
          }
        }
      } else {
        history.push("/dashboard");
      }
    };
    getStores();
  }, [page]);

  const [tab, setTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setTab(newValue);
  };

  const values = useSelector((state) =>
    state.form.delivery_details ? state.form.delivery_details.values : {}
  );

  async function handleSaveBlockedDates() {
    let res = await axios.put(
      `${apiConfig.apiURL}/api/store/${site}/`,
      {
        blocked_dates: blockDates.length
          ? blockDates.map((date, index) => {
              return typeof date === "string" ? date : date.format();
            })
          : [],
      },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`,
        },
      }
    );
    if (res.status === 200) {
      dispatch(
        showAlert({ type: "success", message: "Changes has been saved" })
      );
      history.push("/dashboard");
    } else {
      dispatch(
        showAlert({
          type: "error",
          message: "An error occured. Please try again.",
        })
      );
    }
  }

  const onSubmit = () => {
    if (tab === 3) {
      handleSaveBlockedDates();
    } else {
      if (deliverySettings.to_delete_pickup_address.length) {
        const handleDeletePickupAddreses = async () => {
          await dispatch(
            Actions.deletePickupAddresses(to_delete_pickup_address)
          );
        };
        handleDeletePickupAddreses();
      }
      const handleShowPickupAddress = async () => {
        await dispatch(
          Actions.showPickupAddresses(
            deliverySettings.pickup_addresses?.results,
            is_show_pickup
          )
        );
      };
      handleShowPickupAddress();
      history.push("/dashboard");
    }
  };

  let loading = useSelector((state) => state.dashboard.loading);
  const delivery_locations = useSelector(
    (state) => state.deliverySettings.delivery_locations
  );
  const [welcomeOpen, setWelcomeOpen] = React.useState(true);
  const [openSaveChanges, setOpenSaveChanges] = React.useState(false);

  React.useEffect(() => {
    if (deliverySettings.pickup_addresses.results.length) {
      if (
        values?.province !==
        deliverySettings.pickup_addresses?.results[0].province
      ) {
        dispatch(change("delivery_details", "city", ""));
        dispatch(change("delivery_details", "brgy", ""));
      }
    }
  }, [values?.province]);

  return (
    <div id="content">
      <SentroLoading open={loading} />
      <SaveChangesModal
        open={openSaveChanges}
        setOpen={setOpenSaveChanges}
        onSubmit={onSubmit}
      />
      {is_mobile ? (
        <Dialog
          id="delivery_settings_mobile_content"
          fullScreen
          open={is_mobile}
          TransitionComponent={DialogTransition}
        >
          <div id="delivery_settings_mobile_content" />
          <WelcomeModal open={welcomeOpen} setOpen={setWelcomeOpen} />
          <AppBar className={classes.appbar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                className={classes.closeBtn}
                onClick={() => {
                  if (to_delete_pickup_address.length > 0) {
                    setOpenSaveChanges(true);
                  } else {
                    history.goBack();
                  }
                }}
              >
                <ArrowBackRoundedIcon style={{ color: "#f97663" }} />
              </IconButton>
              <div className={classes.header}>Delivery Settings</div>
            </Toolbar>
          </AppBar>
          <CustomTabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab
              label="Pickup"
              {...a11yProps(0, is_mobile)}
              classes={{ root: classes.tabsBorderBottom }}
            />
            <Tab
              label="Location"
              {...a11yProps(1, is_mobile)}
              classes={{ root: classes.tabsBorderBottom }}
            />
            <Tab
              label="Couriers"
              {...a11yProps(2, is_mobile)}
              classes={{ root: classes.tabsBorderBottom }}
            />
            <Tab
              label="Block Dates"
              {...a11yProps(3, is_mobile)}
              classes={{ root: classes.tabsBorderBottom }}
            />
          </CustomTabs>
          <div style={{ paddingBottom: 64, paddingTop: 124 }}>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={tab}
              enableMouseEvents
              onChangeIndex={(index) => handleTabChange("event", index)}
            >
              <TabPanel>
                <div id="pickup-pane">
                  <PickupPane
                    store_uuid={store_uuid}
                    is_show_pickup={is_show_pickup}
                    set_is_show_pickup={set_is_show_pickup}
                    page={page}
                    setPage={setPage}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div id="location-pane">
                  <LocationPane />
                </div>
              </TabPanel>
              <TabPanel>
                <div id="courier-pane">
                  <CourierPane />
                </div>
              </TabPanel>
              <TabPanel>
                <div id="blockdates-pane">
                  <BlockDatesPane
                    values={blockDates}
                    setValues={setBlockDates}
                  />
                </div>
              </TabPanel>
            </SwipeableViews>
          </div>
          {tab === 1 && !delivery_locations.length ? (
            ""
          ) : (
            <div
              className="position-fixed bg-white p-3 w-100"
              style={{ bottom: 0 }}
            >
              <Button
                color="primary"
                fullWidth
                variant="contained"
                disabled={Boolean(webBuilderLoading)}
                disableElevation
                onClick={onSubmit}
              >
                {deliverySettings.loading ? (
                  <div className="d-flex my-5 mr-3">
                    <div
                      className="spinner-border mx-auto"
                      role="status"
                      style={{
                        color: "white",
                        fontSize: 12,
                        height: 25,
                        width: 25,
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                Save changes
              </Button>
            </div>
          )}
        </Dialog>
      ) : (
        <div className={classes.root} id="delivery_settings_desk_content">
          <div className={classes.headerSection}>
            <Button
              startIcon={<ArrowBackIcon />}
              variant="text"
              color="primary"
              onClick={() => {
                if (to_delete_pickup_address.length > 0) {
                  setOpenSaveChanges(true);
                } else {
                  history.goBack();
                }
              }}
            >
              Back
            </Button>
            <div
              style={{
                fontSize: 28,
                color: "#002c42",
                fontWeight: 600,
              }}
            >
              Delivery settings
            </div>

            <Button style={{ opacity: 0 }} />
          </div>
          <div className={classes.content}>
            <div className={classes.details}>
              <AppBar
                className={classes.appbar}
                position="static"
                color="default"
              >
                <Tabs
                  classes={{ root: classes.tabs }}
                  value={tab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="simple tabs example"
                >
                  <Tab
                    label="Pickup"
                    {...a11yProps(0, is_mobile)}
                    id="pickup-tab"
                  />
                  <Tab
                    label="Location"
                    {...a11yProps(1, is_mobile)}
                    id="location-tab"
                  />
                  <Tab
                    label="Couriers"
                    {...a11yProps(2, is_mobile)}
                    id="couriers-tab"
                  />
                  <Tab
                    label="Block Dates"
                    {...a11yProps(3, is_mobile)}
                    id="blockdates-tab"
                  />
                </Tabs>
              </AppBar>

              <div
                className={`${tab === 0 ? "d-block" : "d-none"}`}
                id="pickup-pane"
              >
                <PickupPane
                  store_uuid={store_uuid}
                  is_show_pickup={is_show_pickup}
                  set_is_show_pickup={set_is_show_pickup}
                  page={page}
                  setPage={setPage}
                />
              </div>
              <div
                className={`${tab === 1 ? "d-block" : "d-none"}`}
                id="location-pane"
              >
                <LocationPane />
              </div>
              <div
                className={`${tab === 2 ? "d-block" : "d-none"}`}
                id="couriers-pane"
              >
                <CourierPane />
              </div>
              <div
                className={`${tab === 3 ? "d-block" : "d-none"}`}
                id="blockdates-pane"
              >
                <BlockDatesPane values={blockDates} setValues={setBlockDates} />
              </div>
              <div className={classes.btnDiv}>
                {tab === 1 && !delivery_locations.length ? (
                  ""
                ) : (
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    id="submit_btn"
                    disabled={Boolean(webBuilderLoading)}
                    disableElevation
                    onClick={onSubmit}
                  >
                    {deliverySettings.loading || webBuilderLoading ? (
                      <div className="d-flex my-5 mr-3" id="spinner">
                        <div
                          className="spinner-border mx-auto"
                          role="status"
                          style={{
                            color: "white",
                            fontSize: 12,
                            height: 25,
                            width: 25,
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    Save changes
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// export default DeliveryPage;

let Form = reduxForm({
  form: "delivery_details",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DeliveryPage);

Form = connect(
  (state) => ({
    initialValues: state.deliverySettings.reduxForm.pickupAddress, // pull initial values
  }),
  {
    initializeData: ReduxInitializeFormActions.initializePickupAddressData,
  } // bind account loading action creator
)(Form);

export default Form;
