import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  Slide,
  Dialog,
  IconButton,
  Toolbar,
  AppBar,
  Button,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import { ArrowBack, CheckCircle, Close } from "@material-ui/icons";
import CustomizeTemplateForm from "../../../../../websiteBuilder/desktop/components/shopStylesForm/form";
import PreviewShop from "./previewShop";
import TemplateConfirmationDialog from "../../../../../websiteBuilder/desktop/components/shopStylesForm/confirmationDialog";
import { patchStore } from "../websiteBuilderActions";
import TemplateLibrary from "./templateLibrary";
import CustomizeShopForm from "../../../../../websiteBuilder/desktop/forms/shopStyleFormV2/index";
import setDefaultImage from "../common/setDefaultImage";
import SaveSuccessModal from "../../../../../websiteBuilder/desktop/forms/shopStyleFormV2/saveSuccessModal";
import PreviewTemplate from "../../../../../websiteBuilder/desktop/forms/previewTemplate";
import { templates } from "../../../../../websiteBuilder/desktop/components/shopStylesForm/templateDisplayImage";
const DialogTransition = props => <Slide direction="up" {...props} />;
export default function CustomizeShopStyleForm(props) {
  const { values, setValues, storeInfo, productList } = props;
  const useStyles = makeStyles(theme => ({
    appbar: {
      height: 55,
      backgroundColor: "#fafafa",
      boxShadow: "none",
      borderBottom: "1px solid #d1d5dd"
    },
    header: {
      color: "#002c42",
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      width: "100%"
    },
    closeBtn: { position: "absolute", left: 15 },
    footerButtons: {
      padding: 24,
      backgroundColor: "#fff",
      borderTop: "1px solid #d1d5dd"
    },
    form: {
      height: "84vh",
      overflowY: "scroll",
      paddingTop: "5vh",
      [theme.breakpoints.down("xs")]: {
        height: "75vh",
        paddingTop: "8vh"
      }
    },
    checkIcon: {
      textAlign: "center",
      width: "100%",
      color: "#38b99f",
      fontSize: 100
    },
    errorIcon: {
      textAlign: "center",
      width: "100%",
      color: "red",
      fontSize: 100
    },
    name: {
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42"
    },
    site: {
      fontSize: 14,
      color: "#54575d"
    }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.mobileDashboard.loading);
  const data = useSelector(state => state.form.customizeShopForm?.values);
  const subscription = useSelector(state => state.subscription.subscription);
  const [open, setOpen] = React.useState({
    preview: false,
    dialog: false,
    customize: false,
    success_dialog: false,
    is_success: false,
    template: ""
  });
  const [state, setState] = React.useState({
    responseModalStat: false,
    responseStat: false
  });
  const handleClosePreview = () => {
    setOpen({ ...open, preview: false });
  };

  const handleViewDemo = template => {
    console.log(template);
    // setValues({ ...values, template: template });
    setOpen({ ...open, preview: true, template: template });
  };

  const handleUseTemplate = async () => {
    const res = await dispatch(
      patchStore(
        values.site,
        {
          template: values.template
        },
        {
          image_crop_area: values.image_crop_area,
          image_settings: values.image_settings
        }
      )
    );

    try {
      if (res.status === 200) {
        setOpen({
          ...open,
          dialog: false,
          success_dialog: true,
          is_success: true
        });
      }
    } catch (error) {
      setOpen({
        ...open,
        dialog: false,
        success_dialog: true,
        is_success: false
      });
    }
  };

  React.useEffect(() => {
    if (storeInfo && values.customize_shop_style_form_open) {
      setValues({
        ...values,
        cover_image: !storeInfo.cover_image
          ? setDefaultImage(storeInfo.template, "cover_image")
          : storeInfo.cover_image,
        aboutus_image: !storeInfo.aboutus_image
          ? setDefaultImage(storeInfo.template, "aboutus_image")
          : storeInfo.aboutus_image,
        contactus_image: !storeInfo.contactus_image
          ? setDefaultImage(storeInfo.template, "contactus_image")
          : storeInfo.contactus_image
      });
    }
  }, [storeInfo, values.customize_shop_style_form_open]);

  React.useEffect(() => {
    if (data) {
      setValues({
        ...values,
        logo_image: data.logo_image?.length ? data?.logo_image[0] : "",
        cover_image: data.cover_image?.cropped_image,
        aboutus_image: data.aboutus_image?.cropped_image,
        contactus_image: data.contactus_image?.cropped_image,
        color: {
          primary: {
            ...data.color.primary,
            main: data.primary_background_color,
            contrastText: data.primary_text_color
          },
          secondary: {
            ...data.color.secondary,
            main: data.secondary_background_color,
            contrastText: data.secondary_text_color
          }
        },
        font: data.font
      });
    }
  }, [data]);

  return (
    <div>
      <Dialog
        fullScreen
        open={values.customize_shop_style_form_open}
        TransitionComponent={DialogTransition}
      >
        <div>
          <AppBar className={classes.appbar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                className={classes.closeBtn}
                onClick={() => {
                  setValues({
                    ...values,
                    customize_shop_style_form_open: false
                  });
                  setOpen({ ...open, customize: false });
                }}
              >
                <ArrowBack style={{ color: "#f97663" }} />
              </IconButton>
              <div className={classes.header}>Customize template</div>
            </Toolbar>
          </AppBar>
          <div className={classes.form}>
            {open.customize ? (
              <CustomizeShopForm
                open={open}
                setOpen={setOpen}
                isMobile={true}
                storeInfo={storeInfo}
              />
            ) : (
              <CustomizeTemplateForm
                values={values}
                setValues={setValues}
                storeInfo={storeInfo}
                handleViewDemo={handleViewDemo}
                open={open}
                setOpen={setOpen}
                handleUseTemplate={handleUseTemplate}
                subscription={subscription}
              />
            )}
          </div>
          {open.customize ? (
            ""
          ) : (
            <div className={classes.footerButtons}>
              <Button
                disableElevation
                fullWidth
                color="primary"
                variant="outlined"
                className="mb-2"
                onClick={() =>
                  setValues({
                    ...values,
                    customize_shop_style_form_open: false
                  })
                }
              >
                Back
              </Button>
              <Button
                disableElevation
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => {
                  // setValues({ ...values, template: storeInfo.template });
                  setOpen({
                    ...open,
                    preview: true,
                    template: templates.find(
                      item => item.value === storeInfo.template
                    )
                  });
                }}
              >
                Preview
              </Button>
            </div>
          )}
        </div>
        <PreviewTemplate open={open} setOpen={setOpen} />
        {/* <PreviewShop
          open={open}
          setOpen={setOpen}
          values={values}
          setValues={setValues}
          productList={productList}
        /> */}
        <TemplateConfirmationDialog
          open={open}
          setOpen={setOpen}
          handleUseTemplate={handleUseTemplate}
        />

        <TemplateLibrary
          values={values}
          setValues={setValues}
          state={state}
          setState={setState}
          productList={productList}
          storeInfo={storeInfo}
        />
        <SaveSuccessModal open={open} setOpen={setOpen} store={storeInfo} />
      </Dialog>
    </div>
  );
}
