import { ProductActionTypes } from "./productActions";
const initialState = {
  products: [],
  loading: false,
  error: "",
  selectedProduct: ""
};
export default function productReducers(state = initialState, action) {
  switch (action.type) {
    case ProductActionTypes.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ProductActionTypes.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload
      };
    case ProductActionTypes.PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        products: [],
        error: action.payload
      };
    case ProductActionTypes.PRODUCT_SELECT:
      return {
        ...state,
        loading: false,
        selectedProduct: action.payload
      };

    default:
      return initialState;
  }
}
