import React from "react";
import { Box, InputAdornment, IconButton, Typography } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import SentroTextField from "../../common/sentroTextField";
import validations from "../../common/validations";
import { useDispatch, useSelector } from "react-redux";
import { confirmChangePasswordAction } from "../forgotPasswordAction";
import SentroButton from "../../common/sentroButton";
import Text from "../../../common/text";

let ChangePasswordForm = props => {
  const [showPassword, setShowPassword] = React.useState("");
  const dispatch = useDispatch();

  let loading = useSelector(state => state.login.loading);
  let error = useSelector(state =>
    state.form.changePasswordForm
      ? state.form.changePasswordForm.syncErrors
      : true
  );
  let loginFormValues = useSelector(state =>
    state.form.changePasswordForm ? state.form.changePasswordForm.values : null
  );

  function submitPassword() {
    let params = {
      password: loginFormValues.password,
      token: props.token
    };
    dispatch(confirmChangePasswordAction(params));
  }

  return (
    <Box>
      <Box>
        <Text
          style={{ fontWeight: 600, color: "#2B2D33" }}
          textType="cap"
          content="New Password"
        />

        <Field
          id="new_password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SentroButton
                  style={{ color: "#2B2D33", fontSize: 10 }}
                  label={showPassword ? "HIDE" : "SHOW"}
                  onClick={e => setShowPassword(!showPassword)}
                  onMouseDown={e => setShowPassword(showPassword)}
                />
              </InputAdornment>
            )
          }}
          name="password"
          type={showPassword ? "text" : "password"}
          validate={[validations.required, validations.password]}
          component={SentroTextField}
        />

        {/* <Field
          // maxLength={25}
          name="password"
          validate={[
            validations.required,
            validations.whiteSpace,
            validations.password
          ]}
          component={SentroTextField}
        /> */}
      </Box>

      <Box style={{ marginTop: "2vh" }}>
        <SentroButton
          id="submitPassword_button"
          onClick={() => submitPassword()}
          disabled={error || loading}
          size="large"
          variant="contained"
          fullWidth
          label="Change Password"
          style={{ height: 48, fontSize: 16, fontWeight: 600 }}
        />
      </Box>
    </Box>
  );
};

ChangePasswordForm = reduxForm({
  form: "changePasswordForm"
})(ChangePasswordForm);

export default ChangePasswordForm;
