import {
  faLessThanEqual,
  faPlusSquare
} from "@fortawesome/free-solid-svg-icons";
import { websiteBuilderActionTypes } from "./websiteBuilderActions";
const initialState = {
  stores: [],
  loading: false,
  error: "",
  storeInfo: null,
  isEdit: false,
  step: 0,
  products: {
    loading: false,
    products: {
      results: []
    },
    filters: {
      status: "active",
      search: "",
      sort: "asc",
      sortBy: "created_on",
      category: [],
      itemsPerPage: 5,
      page: 1
    }
  },
  product_categories: {
    categories: [],
    loading: false,
    error: false
  },
  payments: [],
  paymentDetails: "",
  dialogOpen: false,
  message: "",
  buxStatus: 0,
  successCreateShop: false,
  successPublishShop: false,
  deleteModal: false,
  kyc_success: false,
  profileInformation: "",
  visible_header_footer: true,
  vouchers: {},
  voucherListFilter: {
    list: [],
    length: 0
  },
  selectedVoucher: {},
  voucherFilters: {
    status: "all",
    voucher_type: "all",
    search: "",
    is_active: "all",
    desktop: false
  },
  productVariants: [],
  orderFilters: {
    date_ordered_type: "",
    payment_status: "all",
    delivery_status: ["all"],
    order: "desc",
    orderBy: "ref_id",
    search: "",
    page: 1,
    rowsPerPage: 10,
    desktop: false
  },
  orders: {
    loading: false,
    selectedOrder: "",
    shippingFee: 0,
    orders: {
      list: [],
      length: 0
    },
    filters: {
      date_ordered_type: "today",
      payment_status: "all",
      delivery_status: ["all"],
      order: "asc",
      orderBy: "ref_id",
      search: "",
      page: 0,
      rowsPerPage: 10,
      desktop: false,
      startDate: null,
      endDate: null
    }
  },
  showPopup: {
    open: false,
    alertType: "success",
    message: ""
  },
  add_product: false,
  voucherList: []
};
export default function webBuilderReducers(state = initialState, action) {
  switch (action.type) {
    case websiteBuilderActionTypes.LOADING_ON:
      return {
        ...state,
        loading: true
      };
    case websiteBuilderActionTypes.LOADING_OFF:
      return {
        ...state,
        loading: false
      };
    case websiteBuilderActionTypes.BUILDER_REQUEST:
      return {
        ...state,
        loading: true,
        isEdit: true
      };
    case websiteBuilderActionTypes.FORWARD:
      return {
        ...state,
        step: action.payload + 1
      };
    case websiteBuilderActionTypes.BACKWARD:
      return {
        ...state,
        step: action.payload - 1
      };
    case websiteBuilderActionTypes.BUILDER_FAILED:
      return {
        ...state,
        error: action.payload.error,
        step: action.payload.step,
        isEdit: action.payload.isEdit
      };
    case websiteBuilderActionTypes.GET_STORE_INFO_WEB:
      return {
        ...state,
        storeInfo: action.payload
      };
    case websiteBuilderActionTypes.EDIT_STORE:
      const stores = state.stores.map(store => {
        return store.uuid === action.payload.uuid ? action.payload : store;
      });
      return {
        ...state,
        stores,
        loading: false,
        storeInfo: action.payload
      };
    case websiteBuilderActionTypes.HIDE_ALERT:
      return {
        ...state,
        error: ""
      };
    case websiteBuilderActionTypes.DELETE_STORE:
      return {
        ...state,
        loading: false
      };
    case websiteBuilderActionTypes.CONTINUE_BUILD:
      return {
        ...state,
        loading: false,
        isEdit: true,
        step: 0
      };
    case websiteBuilderActionTypes.PUBLISH_STORE:
      return {
        ...state,
        successPublishShop: true,
        products: {
          ...state.products,
          list: action.payload
        }
      };
    case websiteBuilderActionTypes.SET_EDIT:
      return {
        ...state,
        isEdit: true
      };
    case websiteBuilderActionTypes.ADD_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          list: action.payload
        },
        loading: false
      };
    case websiteBuilderActionTypes.GET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true
        }
      };
    case websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          products: {
            ...action.payload,
            results:
              action.mode === "append"
                ? [
                    ...state.products.products.results,
                    ...action.payload.results
                  ]
                : action.payload.results
          }
        }
      };
    case websiteBuilderActionTypes.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        dialogOpen: true
      };
    case websiteBuilderActionTypes.ADD_PAYMENT:
      return {
        ...state
      };
    case websiteBuilderActionTypes.GET_PAYMENTS_WEB:
      return {
        ...state,
        loading: false,
        payments: action.payload
      };

    case websiteBuilderActionTypes.EDIT_PAYMENT:
      return {
        ...state,
        loading: false,
        paymentDetails: action.payload
      };

    case websiteBuilderActionTypes.OPEN_DIALOG:
      return {
        ...state,

        dialogOpen: true
      };
    case websiteBuilderActionTypes.CLOSE_DIALOG:
      return {
        ...state,
        dialogOpen: false
      };
    case websiteBuilderActionTypes.GET_STORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case websiteBuilderActionTypes.GET_STORE_SUCCESS:
      return {
        ...state,
        stores: action.payload
      };
    case websiteBuilderActionTypes.GET_STORE_INFO:
      return {
        ...state,
        storeInfo: {
          ...action.payload,
          font:
            !action.payload.font || action.payload.font === {}
              ? { family: "Georgia", css_url: "" }
              : action.payload.font,
          color:
            typeof action.payload.color === "string"
              ? {
                  primary: {
                    dark: "#f2f2f2",
                    main: "#ffffff",
                    light: "white",
                    contrastText: "#9763c0"
                  },
                  secondary: {
                    dark: "#f2f2f2",
                    main: "#9763c0",
                    light: "white",
                    contrastText: "#ffffff"
                  }
                }
              : action.payload.color
        }
      };
    case websiteBuilderActionTypes.GET_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case websiteBuilderActionTypes.SET_ALERT_MESSAGE:
      return {
        ...state,
        message: action.payload
      };
    case websiteBuilderActionTypes.GET_BUX_STATUS:
      return {
        ...state,
        buxStatus: action.payload
      };
    case websiteBuilderActionTypes.CREATE_SHOP_SUCCESS:
      return {
        ...state,
        successCreateShop: true
      };
    case websiteBuilderActionTypes.CLOSE_CREATE_DIALOG:
      return {
        ...state,
        successCreateShop: false
      };
    case websiteBuilderActionTypes.CLOSE_PUBLISH_DIALOG:
      return {
        ...state,
        successPublishShop: false
      };
    case websiteBuilderActionTypes.OPEN_DELETE_DIALOG:
      return {
        ...state,
        deleteModal: true
      };
    case websiteBuilderActionTypes.CLOSE_DELETE_DIALOG:
      return {
        ...state,
        deleteModal: false
      };
    case websiteBuilderActionTypes.GET_ORDERS_REQUEST:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true
        }
      };
    case websiteBuilderActionTypes.GET_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          orders: {
            ...action.payload,
            list:
              action.mode === "append"
                ? [...state.orders.orders.list, ...action.payload.list]
                : action.payload.list
          }
        }
      };
    case websiteBuilderActionTypes.GET_ORDER_DETAILS:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          selectedOrder: action.payload
        }
      };
    case websiteBuilderActionTypes.GET_DELIVERY_SHIPPING:
      return {
        ...state,
        shippingFee: action.payload
      };
    case websiteBuilderActionTypes.KYC_SUCCESS:
      return {
        ...state,
        kyc_success: true
      };
    case websiteBuilderActionTypes.GET_PROFILE:
      return {
        ...state,
        profileInformation: action.payload
      };
    case websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES:
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          error: false,
          loading: true
        }
      };
    case websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES_SUCCESS:
      let categories = action.payload.filter(val => val !== "");
      if (action.payload.includes("")) categories.push("");
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          categories,
          loading: false,
          error: false
        }
      };
    case websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES_FAILED:
      return {
        ...state,
        product_categories: {
          ...state.product_categories,
          error: true,
          loading: false
        }
      };
    case websiteBuilderActionTypes.SET_HEADER_FOOTER_VISIBLE:
      return {
        ...state,
        visible_header_footer: action.payload
      };
    case websiteBuilderActionTypes.GET_VOUCHERS_DASHBOARD:
      return {
        ...state,
        voucherList: action.payload.length
      };
    case websiteBuilderActionTypes.GET_FILTER_VOUCHERS:
      return {
        ...state,
        voucherListFilter: action.payload
      };
    case websiteBuilderActionTypes.SET_SELECTED_VOUCHER:
      return {
        ...state,
        selectedVoucher: action.payload
      };
    case websiteBuilderActionTypes.SET_VOUCHER_FILTERS:
      return {
        ...state,
        voucherFilters: {
          ...state.voucherFilters,
          ...action.payload
        }
      };
    case websiteBuilderActionTypes.GET_PRODUCT_VARIANTS:
      return {
        ...state,
        productVariants: action.payload
      };
    case websiteBuilderActionTypes.SET_ORDER_FILTERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          filters: {
            ...state.orders.filters,
            ...action.payload
          }
        }
      };
    case websiteBuilderActionTypes.SET_PRODUCT_FILTERS:
      return {
        ...state,
        products: {
          ...state.products,
          filters: {
            ...state.products.filters,
            ...action.payload
          }
        }
      };
    default:
      return state;
  }
}
