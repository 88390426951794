import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  IconButton,
  Link,
  Badge,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  ShoppingCartOutlined,
  Menu
} from "@material-ui/icons";
import social_medias from "../../components/aboutus/previewAboutTemplate";
import "../sidebar.css";
import CategoriesDialog from "../../components/appBar/categories";
export default function Sidebar(props) {
  const {
    storeInfo,
    product_categories,
    handleGoToDashboard,
    cartButton
  } = props;
  const useStyles = makeStyles(theme => ({
    storename: {
      fontSize: 18,
      fontWeight: 700,
      textTransform: "capitalize",
      color: "rgba(43, 45, 50, 1)",
      position: "absolute",
      textAlign: "center",
      width: "100%",
      left: 0,
      right: 0,
      [theme.breakpoints.down("xs")]: {
        width: 120,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        position: "inherit"
      }
    },
    customBadge: {
      backgroundColor: "#C0492C",
      color: "white"
    },
    categoryList: {
      height: 300,
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: 3
      },
      "&::-webkit-scrollbar-track": {
        background: "#eee"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#54575f"
      }
    }
  }));
  let color = storeInfo ? storeInfo.color : "";
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  let cart = useSelector(state => state.store.items);
  let token = sessionStorage.getItem("TOKEN");
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {isMobile ? (
        <div className="bg-white d-flex align-items-center justify-content-between p-3">
          <div className="d-flex align-items-center">
            <IconButton
              style={{ zIndex: 1 }}
              onClick={props.isPreview ? null : props.handleClickOpen}
            >
              <Menu style={{ color: "rgba(43, 45, 50, 1)" }} />
            </IconButton>
            <div
              className={classes.storename}
              onClick={() => history.push(`/shop/${storeInfo.site}`)}
            >
              {storeInfo && storeInfo.name}
            </div>
          </div>
          <div className="d-flex align-items-center">
            {token && user ? (
              <IconButton
                onClick={() => (props.isPreview ? null : handleGoToDashboard())}
              >
                <AccountCircleOutlined
                  style={{ color: "rgba(43, 45, 50, 1)" }}
                />
              </IconButton>
            ) : (
              <Link
                className="px-md-4 px-2"
                style={{ color: "rgba(43, 45, 50, 1)" }}
                onClick={() => history.push(`/shop/${storeInfo.site}/login`)}
              >
                Log in
              </Link>
            )}
            <IconButton onClick={cartButton()}>
              <Badge
                classes={{ badge: classes.customBadge }}
                badgeContent={cart.length}
              >
                <ShoppingCartOutlined style={{ color: "#2b2d32" }} />
              </Badge>
            </IconButton>
          </div>
          <CategoriesDialog
            classes={classes}
            productList={cart}
            handleGoToDashboard={handleGoToDashboard}
            cartButton={props.cartButton}
            color={color}
            storeInfo={storeInfo}
            categories={product_categories}
            open={props.open}
            onClose={props.onClose}
            anchorEl={props.anchorEl}
          />
        </div>
      ) : (
        <div className="homepage-frame223827150">
          <div
            style={{ minHeight: "100vh" }}
            className="pt-5 px-5 position-relative"
          >
            <span
              className="homepage-text05 boldH5"
              onClick={() => history.push(`/shop/${storeInfo.site}`)}
            >
              {storeInfo && storeInfo.name}
            </span>
            <div className="d-flex align-items-center justify-content-end py-4">
              {token && user ? (
                <IconButton onClick={() => handleGoToDashboard()}>
                  <AccountCircleOutlined
                    style={{
                      color: "rgba(62, 65, 73, 1)"
                    }}
                  />
                </IconButton>
              ) : (
                <Link
                  className="px-2"
                  style={{
                    fontSize: 12,
                    color: "rgba(62, 65, 73, 1)"
                  }}
                  onClick={() => history.push(`/shop/${storeInfo.site}/login`)}
                >
                  Log in
                </Link>
              )}
              <IconButton onClick={cartButton()}>
                <Badge
                  classes={{ badge: classes.customBadge }}
                  badgeContent={cart.length}
                >
                  <ShoppingCartOutlined
                    style={{
                      fontSize: 18,
                      color: "rgba(62, 65, 73, 1)"
                    }}
                  />
                </Badge>
              </IconButton>
            </div>
            <div className="d-flex justify-content-end">
              <div className={`homepage-group327158 text-right`}>
                {product_categories.slice(0, 8).map(item => (
                  <div
                    style={{ textTransform: "capitalize", cursor: "pointer" }}
                    className="regularXsmall pb-3"
                    onClick={() =>
                      history.push(
                        `/shop/${
                          props.storeInfo?.site
                        }/products/?category=${encodeURIComponent(
                          item
                        )}&sortBy=-created_on`
                      )
                    }
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div
              className="d-flex justify-content-end position-absolute"
              style={{ bottom: "4vh", right: "3vw" }}
            >
              <div>
                <div className="d-flex align-items-center justify-content-end pb-3">
                  {social_medias("rgba(62, 65, 73, 1)", storeInfo)}
                </div>
                <span className="homepage-text lightXsmall d-flex">
                  Copyright © {new Date().getFullYear()}{" "}
                  {storeInfo && storeInfo.name}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
