import React from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Button,
  Popover,
  MenuItem
} from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import { confirmLogOut } from "../../auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import MarketplaceActions from "../../../redux/actions/marketplaceActions";
import { useHistory } from 'react-router';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#eee",
    width: "100%",
    boxShadow: "none"
  },
  toolbar: {
    padding: "0 4vw",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center"
  },
  name: {
    marginRight: "2vw",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "capitalize",
    color: "#54575f"
  },
  menu: {
    padding: "0.75em 1em",
    backgroundColor: "#002C42",
    color: "white",
    minWidth: 150,
    "&:hover": {
      backgroundColor: "#1E4B83 !important"
    }
  }
}));

const Appbar = props => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const enterprise = useSelector(state => state.enterprise.main.enterprise);
  const data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const first_name = data ? data.first_name : "";
  const handleOptions = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const dispatch = useDispatch();
  const popoverItems = [
    { label: "Profile",
      onClick: () => history.push(`/enterprise/${enterprise.site_name}/dashboard/profile`)
    },
    { label: "Help Center" },
    {
      label: "Logout",
      onClick: () => {
        dispatch(MarketplaceActions.logout(
          enterprise, `/enterprise/${enterprise.site_name}/admin/login`))
      }
    }
  ];

  return (
    <div style={{ zIndex: 1 }}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.name}>Hello, {first_name}!</div>
          <Button
            startIcon={
              <AccountCircleOutlinedIcon
                color="primary"
                style={{ fontSize: 24 }}
              />
            }
            endIcon={<ArrowDropDownIcon color="secondary" />}
            variant="text"
            onClick={handleOptions}
          />
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {popoverItems.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  button
                  onClick={item.onClick}
                  classes={{
                    root: classes.menu
                  }}
                >
                  {item.label}
                </MenuItem>
              );
            })}
          </Popover>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Appbar;
