import {
  saveStore,
  updateStore,
  addItem,
  getItems,
  removeItem,
  editItem,
  publish,
  addPayment,
  getPayments,
  updatePayment,
  deletePayment,
  getStores,
  getStoreDetails,
  patchStore as servicePatchStore,
  setImageSettings as imageSettings,
  buxStatus,
  deleteShop,
  getOrder,
  orderDetails,
  computeShipping,
  bookShipping,
  orderPaymentUpdate,
  orderDeliveryUpdate,
  orderCancel,
  cancelDelivery as cancelDeliveryService,
  tncAgreed,
  saveSurvey,
  fbPageID,
  fbPixelID,
  getProfileInfo,
  saveProfileInfo,
  confirmChangePassword,
  getStoreProductCategories as getStoreProductCategoriesService,
  setOrderRead,
  filterVoucherService,
  patchVariantService,
  getVariantsService,
  getVoucherService,
  createVoucherServices,
  editVoucherServices
} from "./websiteBuilderServices";
import { showAlert } from "../../../commonActions";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";
import { change } from "redux-form";
import { signOff } from "../../../../auth/authActions";
import moment from "moment";
export const websiteBuilderActionTypes = {
  GET_STORE_INFO_WEB: "@@GET_STORE_INFO_WEB",
  SET_EDIT: "@@SET_EDIT",
  SAVE_STORE: "@@SAVE_STORE",
  EDIT_STORE: "@@EDIT_STORE",
  DELETE_STORE: "@@DELETE_STORE",
  PUBLISH_STORE: "@@PUBLISH_STORE",
  BUILDER_REQUEST: "@@BUILDER_REQUEST",
  CONTINUE_BUILD: "@@CONTINUE_BUILD",
  BUILDER_FAILED: "@@BUILDER_FAILED",
  GET_PRODUCTS: "@@GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "@@GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILED: "@@GET_PRODUCTS_FAILED",
  ADD_PRODUCTS: "@@ADD_PRODUCTS",
  REMOVE_PRODUCT: "@@REMOVE_PRODUCT",
  HIDE_ALERT: "@@HIDE_ALERT",
  BACKWARD: "@@BACKWARD",
  FORWARD: "@@FORWARD",
  OPEN_DIALOG: "@@OPEN_DIALOG",
  CLOSE_DIALOG: "@@CLOSE_DIALOG",
  ADD_PAYMENT: "@@ADD_PAYMENT",
  GET_PAYMENTS_WEB: "@@GET_PAYMENTS_WEB",
  EDIT_PAYMENT: "@@EDIT_PAYMENT",
  DELETE_PAYMENT: "@@EDIT_PAYMENT",
  LOADING_OFF: "@@LOADING_OFF",
  LOADING_ON: "@@LOADING_ON",
  CREATE_SHOP_SUCCESS: "@@CREATE_SHOP_SUCCESS",
  GET_STORE_REQUEST: "@@GET_STORE_REQUEST",
  GET_STORE_SUCCESS: "@@GET_STORE_SUCCESS",
  GET_STORE_INFO: "@@GET_STORE_INFO",
  GET_STORE_FAILED: "@@GET_STORE_FAILED",
  SET_ALERT_MESSAGE: "@@SET_ALERT_MESSAGE",
  GET_BUX_STATUS: "@@GET_BUX_STATUS",
  CLOSE_CREATE_DIALOG: "@@CLOSE_CREATE_DIALOG",
  CLOSE_PUBLISH_DIALOG: "@@CLOSE_PUBLISH_DIALOG",
  OPEN_DELETE_DIALOG: "@@OPEN_DELETE_DIALOG",
  CLOSE_DELETE_DIALOG: "@@CLOSE_DELETE_DIALOG",
  GET_ORDERS_REQUEST: "GET_ORDERS_REQUEST",
  GET_ORDERS: "@@GET_ORDERS",
  GET_ORDER_DETAILS: "@@GET_ORDER_DETAILS",
  GET_DELIVERY_SHIPPING: "@@GET_DELIVERY_SHIPPING",
  KYC_SUCCESS: "@@KYC_SUCCESS",
  GET_PROFILE: "@@GET_PROFILE",
  GET_STORE_PRODUCT_CATEGORIES: "GET_STORE_PRODUCT_CATEGORIES",
  GET_STORE_PRODUCT_CATEGORIES_SUCCESS: "GET_STORE_PRODUCT_CATEGORIES_SUCCESS",
  GET_STORE_PRODUCT_CATEGORIES_FAILED: "GET_STORE_PRODUCT_CATEGORIES_FAILED",
  GET_VOUCHERS_DASHBOARD: "@@GET_VOUCHERS_DASHBOARD",
  GET_FILTER_VOUCHERS: "@@GET_FILTER_VOUCHERS",
  SET_SELECTED_VOUCHER: "@@SET_SELECTED_VOUCHER",
  SET_VOUCHER_FILTERS: "@@SET_VOUCHER_FILTERS",
  GET_PRODUCT_VARIANTS: "GET_PRODUCT_VARIANTS",
  SET_ORDER_FILTERS: "SET_ORDER_FILTERS",
  SET_PRODUCT_FILTERS: "SET_PRODUCT_FILTERS"
};

export function addPaymentMethod(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await addPayment(value);
      dispatch({
        type: websiteBuilderActionTypes.ADD_PAYMENT,
        payload: response.data
      });
      let payments = await getPayments(value.store);
      dispatch({
        type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
        payload: payments.data
      });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your account is now added",
          type: "success"
        }
      });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log(error);
      }
    }
  };
}
export function getPaymentMethods(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await getPayments(value);
      dispatch({
        type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
          payload: []
        });
      }

      // else {
      //   dispatch(showAlert({ type: "error", message: error }));
      // }
    }
  };
}

export function selectPaymentMethod(value) {
  return async dispatch => {
    try {
      dispatch({
        type: websiteBuilderActionTypes.EDIT_PAYMENT,
        payload: value
      });
      // history.push(Path.BUILDER);
    } catch (error) {
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(showAlert({ type: "error", message: error }));
      }
    }
  };
}

export function editPayment(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await updatePayment(value);
      let payments = await getPayments(value.store);
      dispatch({
        type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
        payload: payments.data
      });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your account is succesfully edited.",
          type: "success"
        }
      });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log(error);
        // dispatch(showAlert({ type: "error", message: error.message }));
      }
    }
  };
}

export function removePayment(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await deletePayment(value);
      let payments = await getPayments(value.store);
      dispatch({
        type: websiteBuilderActionTypes.GET_PAYMENTS_WEB,
        payload: payments.data
      });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your account is successfully deleted",
          type: "success"
        }
      });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(showAlert({ type: "error", message: error.message }));
      }
    }
  };
}

export function continueBuild(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      history.push(Path.BUILDER);
      dispatch({
        type: websiteBuilderActionTypes.CONTINUE_BUILD
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: error
        });
        dispatch(showAlert({ type: "error", message: error.message }));
      }
    }
  };
}

export function createStore(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await saveStore(value);
      dispatch({
        type: websiteBuilderActionTypes.EDIT_STORE,
        payload: response.data
      });
      dispatch({ type: websiteBuilderActionTypes.CREATE_SHOP_SUCCESS });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: { error: error ? error.data : "" }
        });
      }
    }
  };
}

// export function createStore(value, step, isEdit, currentStoreCreated) {
//   return async dispatch => {
//     let response = "";
//     try {
//       dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
//       response = await saveStore(value, step);
//       dispatch({
//         type: websiteBuilderActionTypes.EDIT_STORE,
//         payload: response.data
//       });
//       dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
//       if (step == 4) {
//         dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
//         history.push(Path.PUBLISH);

//         // GTM trigger
//         let currentUser = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
//         window.dataLayer.push({
//           eventAction: "Store Builder Completed",
//           eventCategory: "Store Builder",
//           event: "storecompleted",
//           userId: currentUser.id
//         });
//       } else {
//         dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
//         dispatch({ type: websiteBuilderActionTypes.FORWARD, payload: step });
//       }
//     } catch (error) {
//       dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
//       if (error && error.status == 401) {
//         dispatch(signOff());
//       } else {
//         console.log(error);
//         dispatch({
//           type: websiteBuilderActionTypes.BUILDER_FAILED,
//           payload: {
//             step: step,
//             isEdit: isEdit,
//             error: error ? error.data : ""
//           }
//         });
//         // dispatch(showAlert({ type: "error", message: error ? error.data : "" }));
//       }
//     }
//   };
// }

export function patchStore(site, values, image_settings) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      const response = await servicePatchStore(site, values, image_settings);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (response.status === 200) {
        dispatch({
          type: websiteBuilderActionTypes.EDIT_STORE,
          payload: response.data
        });
      }
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: {
            error: error ? error.data : ""
          }
        });
      }
    }
  };
}

export function setImageSettings(site, image_crop_area) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      const response = await imageSettings(site, image_crop_area);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (response.status === 200) {
        dispatch({
          type: websiteBuilderActionTypes.EDIT_STORE,
          payload: response.data
        });
      }
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: {
            error: error ? error.data : ""
          }
        });
      }
    }
  };
}

export function publishStore(value) {
  return async dispatch => {
    let response = "";
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });

      response = await publish(value);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      history.push(Path.PUBLISHED);
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: {
            error: error ? error.data : ""
          }
        });
        // dispatch(showAlert({ type: "error", message: error ? error.data : "" }));
      }
    }
  };
}

export function goBackward(step) {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.BACKWARD, payload: step });
  };
}

export function hideAlert() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.HIDE_ALERT });
  };
}

export function getProducts(store_uuid, params = false, mode) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.GET_PRODUCTS });
      let response = await getItems(store_uuid, params);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
        payload: response.data,
        mode
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again"
          })
        );
      }
    }
  };
}

export function addProduct(value, productVariants, storeInfo, params) {
  let store_uuid = localStorage.getItem("STORE_UUID");
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await addItem(value, store_uuid);
      let products = await getItems(store_uuid, params);
      if (response && productVariants) {
        let uuid = response ? response.data.uuid : "";
        response = await patchVariantService(productVariants, uuid);
        dispatch({
          type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
          payload: response.data
        });
      }
      if (storeInfo.is_published === false) {
        let response = await publish(storeInfo);
        dispatch({
          type: websiteBuilderActionTypes.GET_STORE_INFO,
          payload: response.data
        });
        dispatch({
          type: websiteBuilderActionTypes.PUBLISH_STORE,
          payload: products.data
        });
      } else {
        dispatch({
          type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
          payload: products.data
        });
        dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
        dispatch({
          type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
          payload: {
            message: "You have successfully added a product!",
            type: "success"
          }
        });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Oops, something went wrong. Please try again.",
          type: "error"
        }
      });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        console.log("error");
        // dispatch(
        //   showAlert({ type: "error", message: error ? error.data : "" })
        // );
      }
    }
  };
}

export function closeDialog() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.CLOSE_DIALOG });
  };
}
export function closeCreateShopDialog() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.CLOSE_CREATE_DIALOG });
  };
}

export function closePublishDialog() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.CLOSE_PUBLISH_DIALOG });
  };
}

export function closeDeleteDialog() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.CLOSE_DELETE_DIALOG });
  };
}

export function editProduct(value, productVariants, imageList, params) {
  let uuid = localStorage.getItem("STORE_UUID");
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await editItem(value, imageList, uuid);
      let products = await getItems(uuid, params);
      if (response && productVariants) {
        let uuid = response ? response.data.uuid : "";
        response = await patchVariantService(productVariants, uuid);
        dispatch({
          type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
          payload: response.data
        });
      }
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
        payload: products.data
      });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch(
        showAlert({
          type: "success",
          message: "You have successfully updated a product!"
        })
      );
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        history.push(Path.LOGIN);
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again"
          })
        );
      }
    }
  };
}

export function removeProduct(value, params) {
  let uuid = localStorage.getItem("STORE_UUID");
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      await removeItem(value);
      let products = await getItems(uuid, params);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
        payload: products.data
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        history.push(Path.LOGIN);
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function getStore() {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.GET_STORE_REQUEST });
      let response = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      if (error && error.status == 401) {
        history.push(Path.LOGIN);
      } else {
        dispatch({ type: websiteBuilderActionTypes.GET_STORE_FAILED });
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function getStoreProducts(params = false) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.GET_STORE_REQUEST });
      let response = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: response
      });

      let data = response ? response[0] : "";
      if (response.length > 0) {
        let storeDetails = await getStoreDetails(data);
        dispatch({
          type: websiteBuilderActionTypes.GET_STORE_INFO,
          payload: storeDetails.data
        });
        let uuid = storeDetails.data.uuid;
        let products = await getItems(uuid, params);
        dispatch({
          type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
          payload: products.data,
          filtered: params && true
        });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error occured. Please try again"
          })
        );
      }
    }
  };
}
export function getStoreProductCategories(store_uuid) {
  return async dispatch => {
    try {
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES
      });
      let response = await getStoreProductCategoriesService(store_uuid);
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES_SUCCESS,
        payload: response.data
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_PRODUCT_CATEGORIES_FAILED
      });
    }
  };
}

export function getStoreDetail(value) {
  return async dispatch => {
    try {
      let response = await getStoreDetails(value);
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_INFO_WEB,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: error
        });
        dispatch(showAlert({ type: "error", message: error.message }));
      }
    }
  };
}

export function getBuxStatus(value) {
  return async dispatch => {
    try {
      let response = await buxStatus(value);
      let access = response ? response.data : "";

      dispatch({
        type: websiteBuilderActionTypes.GET_BUX_STATUS,
        payload: access
      });
      return access;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch({
          type: websiteBuilderActionTypes.BUILDER_FAILED,
          payload: error
        });
      }
    }
  };
}

export function deleteStore(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.GET_STORE_REQUEST });
      await deleteShop(value);
      dispatch({ type: websiteBuilderActionTypes.OPEN_DELETE_DIALOG });
      let response = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: response
      });
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_INFO,
        payload: ""
      });
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
        payload: []
      });

      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function getOrders(filters, mode) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.GET_ORDERS_REQUEST });
      let orders = await getOrder(filters);
      dispatch({
        type: websiteBuilderActionTypes.GET_ORDERS,
        payload: orders,
        mode
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch(
        showAlert({
          type: "error",
          message: "An error has occured. Please try again"
        })
      );
    }
  };
}

export function getOrderDetails(id) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await orderDetails(id);

      if (response.is_read === false) {
        await setOrderRead(response);
        response = await orderDetails(id);
      }
      //   response.amount =
      //     parseFloat(response.subtotal) + parseFloat(response.other_fees);
      dispatch({
        type: websiteBuilderActionTypes.GET_ORDER_DETAILS,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function calculateShipping(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let shipping = await computeShipping(value);
      dispatch({
        type: websiteBuilderActionTypes.GET_DELIVERY_SHIPPING,
        payload: shipping
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your order cannot be processed. Please try again ",
          type: "error"
        }
      });
    }
  };
}

export function bookDelivery(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      await bookShipping(value);
      let order = await orderDetails(value.order_id);
      order.amount = parseFloat(order.subtotal) + parseFloat(order.other_fees);
      dispatch({
        type: websiteBuilderActionTypes.GET_ORDER_DETAILS,
        payload: order
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your order has been successfully booked",
          type: "success"
        }
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your order cannot be processed. Please try again ",
          type: "error"
        }
      });
    }
  };
}

export function orderUpdatePayment(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      await orderPaymentUpdate(value);
      let response = await orderDetails(value.id);
      response.amount =
        parseFloat(response.subtotal) + parseFloat(response.other_fees);
      dispatch({
        type: websiteBuilderActionTypes.GET_ORDER_DETAILS,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Transaction successfully marked as paid.",
          type: "success"
        }
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your order cannot be processed. Please try again ",
          type: "error"
        }
      });
    }
  };
}

export function orderUpdateDelivery(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      await orderDeliveryUpdate(value);
      let response = await orderDetails(value.id);
      response.amount =
        parseFloat(response.subtotal) + parseFloat(response.other_fees);
      dispatch({
        type: websiteBuilderActionTypes.GET_ORDER_DETAILS,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Transaction successfully marked as delivered.",
          type: "success"
        }
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your order cannot be processed. Please try again ",
          type: "error"
        }
      });
    }
  };
}

export function cancelDelivery(values) {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
    try {
      await cancelDeliveryService(values.mrspeedy_id);
      let order = await orderDetails(values.id);
      order.amount = parseFloat(order.subtotal) + parseFloat(order.other_fees);
      dispatch({
        type: websiteBuilderActionTypes.GET_ORDER_DETAILS,
        payload: order
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Delivery order successfully canceled.",
          type: "success"
        }
      });
      return true;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your order cannot be processed. Please try again ",
          type: "error"
        }
      });
      return false;
    }
  };
}

export function cancelOrder(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });

      let proceed_cancelling_order = true;
      if (value.mrspeedy_id && value.mrspeedy_status !== "canceled") {
        proceed_cancelling_order = await dispatch(cancelDelivery(value));
      }
      if (proceed_cancelling_order) {
        await orderCancel(value);
        let response = await orderDetails(value.id);
        response.amount =
          parseFloat(response.subtotal) + parseFloat(response.other_fees);
        dispatch({
          type: websiteBuilderActionTypes.GET_ORDER_DETAILS,
          payload: response
        });
        dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
        dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
        dispatch({
          type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
          payload: {
            message: "Your order has been successfully cancelled.",
            type: "success"
          }
        });
      }
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your order cannot be processed. Please try again ",
          type: "error"
        }
      });
    }
  };
}

export function agreedTNCMobile(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await tncAgreed(value);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function saveKYCSurvey(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await saveSurvey(value);
      dispatch({
        type: websiteBuilderActionTypes.KYC_SUCCESS,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      }
    }
  };
}

export function skipSurvey() {
  return async dispatch => {
    dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
    let existing = sessionStorage.getItem("CURRENT_USER");
    existing = existing ? JSON.parse(existing) : {};
    existing["survey_answered"] = true;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(existing));
    dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
  };
}

export function saveFBPageID(store, fb_page_id, params) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await fbPageID(store, fb_page_id);
      let stores = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: stores
      });

      let data = stores ? stores[0] : "";
      if (stores.length > 0) {
        let storeDetails = await getStoreDetails(data);
        dispatch({
          type: websiteBuilderActionTypes.GET_STORE_INFO,
          payload: storeDetails.data
        });
        let uuid = storeDetails.data.uuid;
        let products = await getItems(uuid, params);
        dispatch({
          type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
          payload: products.data
        });
        dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function saveFBPixelID(store, fb_pixel_id, params) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await fbPixelID(store, fb_pixel_id);
      let stores = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: stores
      });

      let data = stores ? stores[0] : "";
      if (stores.length > 0) {
        let storeDetails = await getStoreDetails(data);
        dispatch({
          type: websiteBuilderActionTypes.GET_STORE_INFO,
          payload: storeDetails.data
        });
        let uuid = storeDetails.data.uuid;
        let products = await getItems(uuid, params);
        dispatch({
          type: websiteBuilderActionTypes.GET_PRODUCTS_SUCCESS,
          payload: products.data
        });
        dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function getProfile() {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await getProfileInfo();
      dispatch({
        type: websiteBuilderActionTypes.GET_PROFILE,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function saveProfile(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await saveProfileInfo(value);
      dispatch({
        type: websiteBuilderActionTypes.GET_PROFILE,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your details are successfully updated",
          type: "success"
        }
      });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function changePassword(value) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await confirmChangePassword(value);

      let param = { skip_password: false };
      let password_updated = await saveProfileInfo(param);
      dispatch({
        type: websiteBuilderActionTypes.GET_PROFILE,
        payload: password_updated
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      dispatch({ type: websiteBuilderActionTypes.OPEN_DIALOG });
      dispatch({
        type: websiteBuilderActionTypes.SET_ALERT_MESSAGE,
        payload: {
          message: "Your password is successfully updated",
          type: "success"
        }
      });
      return response;
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        dispatch(signOff());
      } else if (error && error.status === 400) {
        return error;
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}
export function popupAlert(values) {
  return async dispatch => {
    dispatch({
      type: websiteBuilderActionTypes.POPUP_ALERT,
      payload: values
    });
  };
}

export function openAddProduct(values) {
  return async dispatch => {
    dispatch({
      type: websiteBuilderActionTypes.OPEN_ADD_PRODUCT,
      payload: values
    });
  };
}
export function getVariants(uuid) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await getVariantsService(uuid);
      dispatch({
        type: websiteBuilderActionTypes.GET_PRODUCT_VARIANTS,
        payload: response
      });
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status === 401) {
        dispatch(signOff());
      } else if (error && error.status === 400) {
        return error;
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function setSelectedVoucher(voucher) {
  return async dispatch => {
    dispatch({
      type: websiteBuilderActionTypes.SET_SELECTED_VOUCHER,
      payload: voucher
    });
  };
}

export function setVoucherFilters(payload) {
  return async dispatch => {
    dispatch({
      type: websiteBuilderActionTypes.SET_VOUCHER_FILTERS,
      payload
    });
  };
}

export function setOrderFilters(filters) {
  return async dispatch => {
    dispatch({
      type: websiteBuilderActionTypes.SET_ORDER_FILTERS,
      payload: filters
    });
  };
}

export function setProductFilters(payload) {
  return async dispatch => {
    dispatch({
      type: websiteBuilderActionTypes.SET_PRODUCT_FILTERS,
      payload
    });
  };
}

export function getVouchers(params = false) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.GET_STORE_REQUEST });
      let response = await getStores();
      dispatch({
        type: websiteBuilderActionTypes.GET_STORE_SUCCESS,
        payload: response
      });
      if (response.length) {
        let vouchers = await getVoucherService(params);
        dispatch({
          type: websiteBuilderActionTypes.GET_VOUCHERS_DASHBOARD,
          payload: vouchers
        });
        console.log(vouchers);
      }

      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
    } catch (error) {
      console.log(error);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      }
    }
  };
}

export function filterVouchers(params) {
  return async dispatch => {
    try {
      let response = await filterVoucherService(params);
      dispatch({
        type: websiteBuilderActionTypes.GET_FILTER_VOUCHERS,
        payload: response
      });
    } catch (error) {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({ type: "error", message: error ? error.data : "" })
        );
      }
    }
  };
}

export function createVoucher(params, filters, stores) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await createVoucherServices(params, stores);
      if (response) {
        let getVouchers = await getVoucherService();
        dispatch({
          type: websiteBuilderActionTypes.GET_VOUCHERS_DASHBOARD,
          payload: getVouchers
        });
        let filterVouchers = await filterVoucherService(filters);
        dispatch({
          type: websiteBuilderActionTypes.GET_FILTER_VOUCHERS,
          payload: filterVouchers
        });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      console.log(error);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "Oops, something went wrong. Please try again."
          })
        );
      }
    }
  };
}

export function editVoucher(params, filters, stores, selectedVoucherCode) {
  return async dispatch => {
    try {
      dispatch({ type: websiteBuilderActionTypes.LOADING_ON });
      let response = await editVoucherServices(
        params,
        stores,
        selectedVoucherCode
      );
      if (response) {
        let vouchers = await filterVoucherService(filters);
        dispatch({
          type: websiteBuilderActionTypes.GET_FILTER_VOUCHERS,
          payload: vouchers
        });
      }
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      return response;
    } catch (error) {
      console.log(error);
      dispatch({ type: websiteBuilderActionTypes.LOADING_OFF });
      if (error && error.status == 401) {
        dispatch(signOff());
      } else {
        dispatch(
          showAlert({
            type: "error",
            message: "An error has occured. Please try again"
          })
        );
      }
    }
  };
}

// export function publishName(value) {
//   return async dispatch => {
//     try {
//       dispatch({ type: websiteBuilderActionTypes.BUILDER_REQUEST });
//       await publishStore(value);
//       dispatch({ type: websiteBuilderActionTypes.PUBLISH_STORE });
//       history.push(Path.PUBLISHED);
//     } catch (error) {
//       dispatch({
//         type: websiteBuilderActionTypes.BUILDER_FAILED,
//         payload: error.message
//       });
//     }
//   };
// }
