import React from "react";
import { makeStyles } from "@material-ui/styles";
import SideMenu from "../../../dashboard/sideMenu";
import SentroLoading from "../../../common/loading";
import SearchIcon from "@material-ui/icons/Search";
import {
  getStoreInfo,
  getStoreProductCategories,
  setAddProductView,
  getProducts
} from "../../websiteBuilderActions";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import AddProductForm from "./addProductForm";
import EditProductForm from "./editProductForm";
import MuiAlert from "@material-ui/lab/Alert";
import PublishShopSuccessModal from "../publishShopSuccess";
import ProductListHead from "../components/products/productListHead";
import productcard from "../../../../assets/images/productCard.png";
import Path from "../../../../Paths";
import { destroy } from "redux-form";
import { useHistory } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import ProductTable from "../components/products/productTable";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AlertDialogBox = props => {
  const { state, setState } = props;
  return (
    <Snackbar
      open={state.openAlert}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => setState({ ...state, openAlert: false })}
    >
      <Alert
        onClose={() => setState({ ...state, openAlert: false })}
        severity={state.alertType}
      >
        {state.message}
      </Alert>
    </Snackbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#FAFAFA",
    overflowX: "hidden"
  },
  side: { zIndex: 3, position: "fixed", display: "flex", height: "100%" },

  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  sortSection: {
    marginTop: "3vh"
  },
  card: {
    marginTop: "3vh",
    backgroundColor: "#fff",
    border: "1px solid #d1d5dd",
    padding: 36
  },
  errorIcon: {
    width: "100%",
    textAlign: "center",
    color: "#ee2545",
    fontSize: 100
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  }
}));

const CreateShopCard = props => {
  const { state, setState, disabled, language } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const stores = useSelector(state => state.webBuilder.stores);
  const history = useHistory();
  function createNew() {
    dispatch(destroy("storeInfo"));
    history.push(Path.SHOP_NEW_CREATE);
  }

  return (
    <div className={classes.card}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "50%", padding: 30 }}>
          <img
            src={productcard}
            height={"auto"}
            width="100%"
            alt="productcard"
          />
        </div>
        <div style={{ width: "50%", padding: 30 }}>
          <strong style={{ color: "#2b2d32", fontSize: 28 }}>
            Start adding products
          </strong>
          <div
            className="my-4"
            style={{ color: "#54575f", fontSize: 14, display: "flex" }}
          >
            {stores.length > 0
              ? language === "English"
                ? "Your shop is almost ready to open! Upload your products so you can start selling."
                : "Kaunti nalang at pwede ng magbukas ang online shop mo! I-upload mo na ang mga produkto mo at simulan na ang pagbebenta!"
              : language === "English"
              ? "Ready to sell your products? No problem! Let's create your online shop and you're good to go!"
              : "Magkaroon ng sariling online shop sa ilang clicks lang! Sagutin lamang ang ilang mga katanungan at kami na ang bahala sa iba."}
          </div>
          <div style={{ marginTop: "5vh" }}>
            <Button
              id="add_product_btn"
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              disabled={disabled}
              onClick={() =>
                stores.length > 0
                  ? history.push(Path.PRODUCTS_ADD)
                  : createNew()
              }
            >
              {stores.length > 0
                ? language === "English"
                  ? "Add Product"
                  : "Magdagdag ng produkto"
                : language === "English"
                ? " Create my online shop"
                : "Gumawa ng online shop"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductsPage = props => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const filters = useSelector(state => state.webBuilder.products.filters);
  const classes = useStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const largeDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const loading = useSelector(state => state.webBuilder.loading);
  const stores = useSelector(state => state.webBuilder.stores);
  const addProductView = useSelector(
    state => state.webBuilder.productsViewVisible
  );
  const user =
    JSON.parse(sessionStorage.getItem("CURRENT_USER")) ||
    JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const getLanguage = localStorage.getItem("language");
  const [language, setLanguage] = React.useState(getLanguage);
  const currentStoreCreated = useSelector(state => state.webBuilder.storeInfo);
  const products = useSelector(state => state.webBuilder.products.products);
  localStorage.setItem(
    "STORE_UUID",
    currentStoreCreated ? currentStoreCreated.uuid : ""
  );

  const productCategories = useSelector(
    state => state.webBuilder.product_categories
  );
  const [state, setState] = React.useState({
    addProduct: false,
    editProduct: false,
    expandedPanelVariant: true,
    expandedPanelAdditional: true,
    openAlert: false,
    alertType: "success",
    message: "",
    openDelete: false,
    openFeaturedModal: false,
    numOfFeaturedProducts: []
  });
  const countNumOfFeatured = [];
  const [currentProducts, setCurrentProducts] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [imageList, setImageList] = React.useState([]);
  const [product, setProduct] = React.useState({
    store: currentStoreCreated ? currentStoreCreated.uuid : "",
    name: "",
    price: "",
    discounted_price: "",
    description: "",
    category: "",
    featured: true,
    is_active: true,
    has_variants: false,
    always_available: false,
    quantity: 5,
    images: "",
    productImages: [],
    photo_primary: "0",
    product_code: "",
    variants: {}
  });

  React.useEffect(() => {
    dispatch(getStoreInfo());
    dispatch(destroy("product_form"));
  }, []);

  React.useEffect(() => {
    if (stores && stores.length) {
      dispatch(getProducts(stores && stores[0].uuid, filters));
    }
  }, [filters]);

  React.useEffect(() => {
    if (stores.length) {
      dispatch(getStoreProductCategories(stores[0].uuid));
    }
  }, [stores]);

  React.useEffect(() => {
    setCategories(productCategories.categories);
  }, [productCategories]);

  React.useEffect(() => {
    if (products) {
      setCurrentProducts(products);
    }
  }, [products]);

  React.useEffect(() => {
    state.numOfFeaturedProducts.length = 0;
    if (currentProducts) {
      for (let i in currentProducts) {
        if (currentProducts[i].featured) {
          state.numOfFeaturedProducts.push(currentProducts[i]);
        }
      }
    }
  }, [currentProducts]);

  React.useEffect(() => {
    if (mobileView) {
      history.push(Path.MOBILE_PRODUCTS);
    }
  }, [mobileView]);

  React.useEffect(() => {
    setLanguage(getLanguage);
  }, [getLanguage]);

  return (
    <div className={classes.root}>
      <SentroLoading open={loading} />
      {stores && stores.length ? (
        <div id="show_product_list">
          <ProductListHead />
          <ProductTable />
        </div>
      ) : (
        <div>
          <div style={{ fontSize: 28, color: "#002c42", fontWeight: 600 }}>
            Products
          </div>
          <CreateShopCard
            state={state}
            setState={setState}
            disabled={!user.is_verified}
            language={language}
          />
        </div>
      )}
      {/* <div>
        {products &&
        (products.active_count > 0 ||
          products.drafts_count > 0 ||
          products.deleted_count > 0) ? (
          <div id="show_product_list">
            <ProductListHead />
            <ProductTable />
          </div>
        ) : (
          <div>
            <div style={{ fontSize: 28, color: "#002c42", fontWeight: 600 }}>
              Products
            </div>
            <CreateShopCard
              state={state}
              setState={setState}
              disabled={!user.is_verified}
              language={language}
            />
          </div>
        )}
      </div> */}

      <AlertDialogBox state={state} setState={setState} />
      <PublishShopSuccessModal />
    </div>
  );
};

export default ProductsPage;
