import React from "react";
import { authUser, logout, fbSignUp } from "./authServices";
import { showAlert, hideAlert } from "../common/commonActions";
import history from "../../store/history";
import Path from "../../Paths";
import SubscriptionActions from "../subscription/redux/actions";
let count = 0;
export const LoginActionTypes = {
  LOGIN_REQUEST: "@@LOGIN_REQUEST",
  LOGIN_SUCCESS: "@@LOGIN_SUCCESS",
  LOGIN_FAILED: "@@LOGIN_FAILED",
  CLEAR: "@@CLEAR",
  LOGIN_FB_REQUEST: "@@LOGIN_FB_REQUEST",
  LOGIN_FB_SUCCESS: "@@LOGIN_FB_SUCCESS",
  LOGIN_FB_FAILED: "@@LOGIN_FB_FAILED",

  VERIFICATION_REQUEST: "@@VERIFICATION_REQUEST",
  VERIFICATION_SUCCESS: "@@VERIFICATION_SUCCESS",
  VERIFICATION_FAILED: "@@VERIFICATION_FAILED",

  LOGGED_OUT: "@@LOGGED_OUT"
};

export function login(value) {
  return async dispatch => {
    try {
      dispatch({ type: LoginActionTypes.LOGIN_REQUEST });
      let response = await authUser(value);
      dispatch({
        type: LoginActionTypes.LOGIN_SUCCESS,
        payload: response
      });
      dispatch(hideAlert());
    } catch (error) {
      console.log(error);
      dispatch({
        type: LoginActionTypes.LOGIN_FAILED
      });
      count = count + 1;
      dispatch(showAlert({ type: "error", message: error ? error.data : "" }));
      if (count == 5) {
        setTimeout(() => {
          history.push(Path.FORGOT_PASSWORD);
        }, 5000);
      }
    }
  };
}

export function signOff() {
  localStorage.removeItem("TOKEN");
  localStorage.removeItem("CURRENT_USER");
  localStorage.removeItem("MERCHANT");
  localStorage.removeItem("success_delete");
  localStorage.removeItem("site");
  sessionStorage.removeItem("TOKEN");
  sessionStorage.removeItem("CURRENT_USER");
  sessionStorage.removeItem("unread_orders");
  sessionStorage.removeItem("subscription");
  sessionStorage.removeItem("bux");
  return async dispatch => {
    try {
      // dispatch({ type: LoginActionTypes.LOGGED_OUT });
      dispatch({ type: LoginActionTypes.CLEAR });
    } catch (error) {
      dispatch(showAlert({ type: "error", message: error.message }));
    }
  };
}

export function confirmLogOut() {
  return async dispatch => {
    try {
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("CURRENT_USER");
      localStorage.removeItem("MERCHANT");
      localStorage.removeItem("success_delete");
      localStorage.removeItem("site");
      sessionStorage.removeItem("TOKEN");
      sessionStorage.removeItem("CURRENT_USER");
      sessionStorage.removeItem("unread_orders");
      sessionStorage.removeItem("subscription");
      sessionStorage.removeItem("bux");
      dispatch({ type: LoginActionTypes.CLEAR });
      history.push(Path.HOME);
    } catch (error) {
      dispatch(showAlert({ type: "error", message: error.message }));
    }
  };
}

export function handleFbError(value) {
  return async dispatch => {
    dispatch(showAlert({ type: "error", message: value }));
  };
}

export function fb_sign_up(value) {
  return async dispatch => {
    try {
      dispatch({ type: LoginActionTypes.LOGIN_REQUEST });
      let response = await fbSignUp(value);
      dispatch({
        type: LoginActionTypes.LOGIN_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch(showAlert({ type: "error", message: error ? error.data : "" }));
    }
  };
}
