import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Grow,
  Link,
  Button,
  Checkbox,
  useMediaQuery,
  Slide,
  IconButton
} from "@material-ui/core";
import { confirmLogOut } from "../auth/authActions";
import { useSelector, useDispatch } from "react-redux";
import { agreedTNC } from "../websiteBuilder/websiteBuilderActions";
import { ArrowBack } from "@material-ui/icons";
import TermsAndConditions from "../common/termsAndConditions";
import { useTheme } from "@material-ui/styles";

const Transition = props => <Grow {...props} />;
const SlideTransition = props => <Slide direction="up" {...props} />;
const useStyles = makeStyles(theme => ({
  tnc_updates: {
    backgroundColor: "#fafafa",
    borderRadius: 4,
    height: "15em",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      background: "#eee"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#909196"
    },
    [theme.breakpoints.down("sm")]: {
      height: "45vh"
    }
  },
  content: {
    overflowY: "scroll",
    height: "60vh",
    backgroundColor: "#fafafa",
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      background: "#eee"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#909196"
    },
    [theme.breakpoints.down("sm")]: {
      height: "75vh"
    }
  }
}));
const TNC = props => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div id="tnc">
      <div className="p-4 d-flex align-items-center">
        {isMobile ? (
          <IconButton
            onClick={() => props.setOpen(false)}
            className="position-absolute"
          >
            <ArrowBack color="secondary" />
          </IconButton>
        ) : (
          <Button
            color="primary"
            className="position-absolute"
            startIcon={<ArrowBack color="primary" />}
            onClick={() => props.setOpen(false)}
          >
            Back
          </Button>
        )}

        <div
          style={{ fontSize: isMobile ? 20 : 28, fontWeight: 600 }}
          className="w-100 text-center"
        >
          Terms and Conditions
        </div>
      </div>
      <div className={`mx-4 mb-4 p-4 ${classes.content}`}>
        <TermsAndConditions labelFontSize={14} />
      </div>
      <div
        style={{ fontSize: 12, color: "#54575f" }}
        className="mt-3 mx-4 mb-4 text-center"
      >
        Have some concerns? Feel free to reach out to us at{" "}
        <Link href="mailto:support@sentro.ph" color="primary">
          support@sentro.ph
        </Link>
      </div>
    </div>
  );
};
export default function TNCNotice(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [checked, setChecked] = React.useState([0, 1]);
  const [showTnc, setShowTnc] = React.useState(false);
  let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const tnc_updates = [
    "Once you sign up for use of Sentro, you are giving your continuing consent and permission for Sentro, UBX PH or its authorized representatives or third parties to conduct checking, inquiry, validation or verification of the information you have provided in your Sentro account.",
    "It is your duty to keep your password and other profile information confidential and secure at all times. The website provides tips on confidentiality and security."
  ];

  function openTNC() {
    if (user.is_facebookLogin) {
      if (user.tnc_agreed) {
        if (user.tnc_agreed === false) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      if (user.tnc_agreed === false) {
        return true;
      } else {
        return false;
      }
    }
  }

  function handleSelected(value) {
    if (checked.map(item => item).includes(value)) {
      const index = checked.map(item => item).indexOf(value);
      const newSelected = [...checked];
      newSelected.splice(index, 1);
      setChecked(newSelected);
    } else {
      setChecked([...checked, value]);
    }
  }

  function handleAgree() {
    let params = { tnc_agreed: true };
    dispatch(agreedTNC(params));
  }

  return (
    <>
      <Dialog
        id="tnc_dialog"
        open={openTNC()}
        fullWidth={isMobile ? false : true}
        fullScreen={isMobile ? true : false}
        maxWidth="sm"
        TransitionComponent={isMobile ? SlideTransition : Transition}
      >
        {showTnc ? (
          <TNC setOpen={setShowTnc} />
        ) : (
          <div className="p-md-4 p-2 d-flex justify-content-center align-items-center">
            <div className="p-3">
              <div
                style={{ fontSize: isMobile ? 20 : 28, fontWeight: 600 }}
                className="text-center"
              >
                We've updated some house rules
              </div>
              <div
                style={{ fontSize: 14, color: "#54575f" }}
                className="mt-4 px-4 text-center"
              >
                To continue using Sentro, you need to confirm that you
                understand and agree to our updated{" "}
                <Link
                  onClick={() => setShowTnc(true)}
                  color="primary"
                  style={{ fontWeight: 600 }}
                >
                  Terms and Conditions
                </Link>
              </div>
              <div className={`${classes.tnc_updates} p-3 my-3`}>
                <div style={{ fontSize: 14, fontWeight: 600 }}>
                  What changed: March 24, 2022
                </div>
                {tnc_updates.map((tnc, index) => (
                  <div className="my-2 d-flex">
                    <div className="col-1 p-0">
                      <Checkbox
                        defaultChecked
                        checked={checked.includes(index)}
                        onChange={() => handleSelected(index)}
                        color="primary"
                        className="p-0"
                      />
                    </div>
                    <div
                      className="col-11 p-0"
                      style={{ fontSize: 14, color: "#54575f" }}
                    >
                      {tnc}
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-md-flex mt-md-0 mt-5">
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={`${isMobile ? "w-100" : "w-50 mr-2"}`}
                  onClick={() => dispatch(confirmLogOut())}
                >
                  Log out
                </Button>
                <Button
                  disableElevation
                  size="small"
                  variant="contained"
                  color="primary"
                  className={`${isMobile ? "w-100 mt-2" : "w-50"}`}
                  disabled={tnc_updates.length !== checked.length}
                  onClick={handleAgree}
                >
                  I have read and agree
                </Button>
              </div>
              <div
                style={{ fontSize: 12, color: "#54575f" }}
                className="mt-3 text-center"
              >
                Have some concerns? Feel free to reach out to us at{" "}
                <Link href="mailto:support@sentro.ph" color="primary">
                  support@sentro.ph
                </Link>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
}
