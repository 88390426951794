import React from "react";
import {
  Paper,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
  Button,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import DashboardLayout from "../dashboard/dashboardLayout";
import Utils from "../common/utils";
import { useSelector, useDispatch } from "react-redux";
import Text from "../../common/text";
import { bankListing } from "../websiteBuilder/forms/bankContants";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import OrderActions from "./orderActions";
import moment from "moment";
import SnackBarAlert from "./snackbarAlert";
import Path from "../../Paths";
import history from "../../store/history";
import SentroButton from "../common/sentroButton";
import { ArrowBack } from "@material-ui/icons";
import SideMenu from "../dashboard/sideMenu";
let OrderUpdatePayment = props => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let theme = useTheme();
  let mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  let dispatch = useDispatch();
  let message = useSelector(state => state.order.message);
  let alert = useSelector(state => state.order.dialogOpen);
  let selectedOrder = useSelector(state =>
    state.order ? state.order.selectedOrder : null
  );
  let source = useSelector(state => (state.order ? state.order.source : null));
  let payments = [
    "Other Banks",
    "Cash",
    // "GCash",
    "PayMaya",
    "PayPal"
  ];
  let [bankName, setBankName] = React.useState("UnionBank of the Philippines");
  let [update, setUpdate] = React.useState({
    id: "",
    channel: selectedOrder ? selectedOrder["channel"] : "Cash",
    date: new Date()
  });

  if (selectedOrder == null) {
    history.push(Path.ORDER_LIST_VIEW);
  }

  function setDeliveryDetails(key, value) {
    setUpdate(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  function updatePayment() {
    let channel = update.channel;
    if (update.channel === "Other Banks") {
      channel = bankName;
    }

    let params = {
      id: selectedOrder["id"],
      channel: channel,
      date: moment(update.date).format()
    };
    dispatch(OrderActions.updatePayment(params, source));
  }
  // const useStyles = makeStyles(theme => ({
  //   cards: {

  //   }
  // }))
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ backgroundColor: "#fafafa" }}>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SentroButton
              color="primary"
              onClick={() => history.push(Path.ORDER_LIST_VIEW)}
              startIcon={<ArrowBack color="primary" />}
              style={{ fontSize: 14, position: "absolute" }}
              label={"BACK"}
            />
            <div
              style={{
                fontSize: 28,
                color: "#002c42",
                fontWeight: 600,
                textAlign: "center",
                width: "100%"
              }}
            >
              Mark as Paid
            </div>
          </div>
          <div>
            <SnackBarAlert
              open={alert}
              alertType={message ? message.type : "success"}
              message={message ? message.message : ""}
            />
            <div style={{ minWidth: "100%", marginTop: mobileView ? 80 : 0 }}>
              <div style={{ padding: 20 }}>
                <Paper
                  style={{
                    display: mobileView ? "block" : "flex",
                    padding: 20,
                    flex: 12,
                    flexWrap: "wrap",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ display: "block" }}>
                    <Typography style={{ fontSize: 20, flexGrow: 1 }}>
                      Reference No.:{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#002c42"
                      }}
                    >
                      {selectedOrder ? selectedOrder["ref_id"] : ""}
                    </Typography>
                  </div>
                  <div
                    style={{ display: "block", marginTop: mobileView ? 10 : 0 }}
                  >
                    <Typography style={{ fontSize: 20, flexGrow: 1 }}>
                      Amount:{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#002c42"
                      }}
                    >
                      PHP{" "}
                      {Utils.commafy(
                        selectedOrder ? selectedOrder["amount"] : 0
                      )}
                    </Typography>
                  </div>
                  <div
                    style={{ display: "block", marginTop: mobileView ? 10 : 0 }}
                  >
                    <Typography style={{ fontSize: 20, flexGrow: 1 }}>
                      Customer:{" "}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "#002c42",
                        textTransform: "capitalize"
                      }}
                    >
                      {selectedOrder ? selectedOrder["sender_name"] : ""}
                    </Typography>
                  </div>
                </Paper>
              </div>

              <div
                style={{
                  padding: 20,
                  flex: 12,
                  justifyContent: "center",
                  display: "flex"
                }}
              >
                <Paper
                  style={{
                    padding: 20,
                    width: mobileView ? "100%" : "26vw",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)"
                  }}
                >
                  <Typography style={{ fontWeight: 600 }}>
                    What was the payment method used?
                  </Typography>
                  <RadioGroup
                    onChange={e =>
                      setDeliveryDetails("channel", e.target.value)
                    }
                    defaultValue={update.channel}
                    color="primary"
                  >
                    {payments.map((item, key) => {
                      return (
                        <FormControlLabel
                          key={key}
                          color="secondary"
                          value={item}
                          control={<Radio color="primary" />}
                          label={item}
                        />
                      );
                    })}
                    {update.channel === "Other Banks" ? (
                      <div style={{ marginTop: 10 }}>
                        <Text textType="p1_Semibold" content="Select bank" />
                        <Select
                          fullWidth
                          id="bank_name"
                          value={bankName}
                          variant="outlined"
                          onChange={e => setBankName(e.target.value)}
                        >
                          {bankListing.map((item, key) => {
                            return (
                              <MenuItem value={item.name}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    ) : (
                      ""
                    )}
                  </RadioGroup>

                  <div style={{ marginTop: "2vh" }}>
                    <Text
                      textType="p2_Semibold"
                      content="When was the payment done?"
                    />

                    <KeyboardDateTimePicker
                      inputVariant="outlined"
                      value={update.date}
                      onChange={e => setDeliveryDetails("date", e)}
                      style={{ margin: 0, padding: 0, width: "100%" }}
                      ampm={false}
                      disablePast
                      format="dd MMMM yyyy hh:mm"
                    />
                  </div>

                  <div style={{ paddingTop: 10 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={updatePayment}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default OrderUpdatePayment;
