import React from 'react';
import ImageUploading from 'react-images-uploading';
import { makeStyles, useTheme } from "@material-ui/styles";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {ReactComponent as UploadIcon} from "../../../assets/images/enterprise/upload_icon.svg";
import ImageCropper from './imageCropper';


export default function MultipleUpload(props) {

const useStyles = makeStyles(theme => ({
    imageDiv: {
      position: "relative",
      height: props.height || 100,
      width: props.width || 100,
      backgroundPosition: "center",
      backgroundSize: "cover",
      cursor: "pointer"
    },
    deleteButton: {
      position: "absolute",
      top: 5,
      right: 5
    },
    uploaderBtnDiv: {
      height: props.height || 100,
      width: props.width || 100,
      border: `2px dashed ${theme.palette.primary.main}`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      cursor: "pointer",
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    error: {
      fontSize: 12,
      color: "red"
    }
  }));
  const classes = useStyles();
  const theme = useTheme();
  const [images, setImages] = React.useState([]);
  // for cropping
  const [imageIndexForCropping, setImageIndexForCropping] = React.useState(); 
  const [cropOpen, setCropOpen] = React.useState(); 
 
  const onChange = (imageList, addUpdateIndex) => {
    // for cropping
    setImageIndexForCropping(addUpdateIndex ? addUpdateIndex[0]: "");
    setCropOpen(true);
    // data for submit
    setImages(imageList);
    imageList = imageList.map(item => item.file || item.data_url)
    if (props.onChange) props.onChange(imageList)
  };

  React.useEffect(() => {
    try {
      if (props.value && !images.length) {
        setImages(props.value.map(item => ({
          data_url: item
        })))
        if (props.onChange) props.onChange(props.value)
      } else if (!props.value) {
        setImages([])
      }
    } catch {}
  }, [props.value])

  const imageOnClick = (index) => {
    // open cropper
    setImageIndexForCropping(index);
    setCropOpen(true);
  }
  

  const onCropSubmit = (file) => {
    console.log(file);
    let newImages = images.map((item, index) => index === imageIndexForCropping? {
      file,
      data_url: URL.createObjectURL(file)
    }: item);
    setImages(newImages);
    // for cropping
    setCropOpen(false);
    newImages = newImages.map(item => item.file || item.data_url)
    if (props.onChange) props.onChange(newImages);
  }
  let imageForCropping = images[imageIndexForCropping] ? 
    images[imageIndexForCropping].file || images[imageIndexForCropping].data_url: "";
  imageForCropping = typeof(imageForCropping) === "object" ?  URL.createObjectURL(imageForCropping): imageForCropping;

  const IMG_LEN = 5;

  return (
    <div>
      {
        props.cropOnImageSelect ? 
        <ImageCropper
          open={cropOpen && Boolean(imageForCropping)}
          setOpen={setCropOpen}
          image={imageForCropping} 
          defaultCropperOrientation={props.cropperProps.defaultCropperOrientation || "landscape"}
          onSubmit={onCropSubmit}>
        </ImageCropper>
        : ""
      }
      <ImageUploading
        name={props.name || "image_upload"}
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
        acceptType={['jpg', 'png', 'jpeg', 'gif']}
        maxFileSize={5000000}
        multiple={props.multiple || false}
        maxNumber={IMG_LEN}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => (
          <>
            <div className={`d-flex flex-wrap align-items-center
                position-relative upload__image-wrapper`} id={props.id}>
              {imageList.map((image, index) => (
                <div key={index} className={`${classes.imageDiv} ${props.multiple && `ml-2 mt-2`}`} style={{
                  backgroundImage: `url(${image['data_url']})`
                }} onClick={() => imageOnClick(index)}>
                  <IconButton className={classes.deleteButton}
                    onClick={() => onImageRemove(index)} size="small" style={{
                      background: "rgba(0, 0, 0, 0.35)",
                    }}>
                    <CloseIcon style={{fontSize: 12, color: "white"}}/>
                  </IconButton>
                </div>
              ))}
              {
                ((props.multiple && imageList.length < IMG_LEN) || (!props.multiple && !imageList.length)) ?
                <div
                  className={`${classes.uploaderBtnDiv} ${props.multiple && `ml-2 mt-2`}`}
                  onClick={onImageUpload}
                >
                  <UploadIcon height="30" width="30" fill={theme.palette.primary.main}/>
                </div>
                : ""
              }
            </div>
            {
              errors && <div className={`${classes.error} ml-2 mt-2`}>
                {errors.acceptType && <span>Your selected file type is not allowed</span>}
                {errors.maxFileSize && <span>Selected file size exceeds 5mb</span>}
              </div>
            }
          </>
        )}
      </ImageUploading>
    </div>
  );
}