import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, connect, useDispatch } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "../../../store/history";
import { Button, useMediaQuery, Paper, MenuItem } from "@material-ui/core";
import ReduxTextField from "../../reduxFields/textField";
import { Field, reduxForm } from "redux-form";
import validations from "../../common/validations";
import ReduxSelectField from "../../reduxFields/selectField";
import {
  ADDRESS,
  getCities
} from "../../common/template/components/constants/address";
import { ReduxInitializeFormActions } from "./redux/actions";
import Path from "../../../Paths";
import VoucherModal from "./common/voucherModal";
import SummaryForm from "./common/summaryForm";
function BuyerDeliveryDetails(props) {
  const useStyles = makeStyles(theme => ({
    header: {
      width: "65%",
      paddingTop: "5vh",
      display: "flex",
      alignItems: "center",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "block",
        paddingTop: 0
      }
    },
    headerLabel: {
      fontSize: 28,
      fontWeight: 600,
      color: "#002c42",
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        position: "inherit"
      }
    },
    label: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2b2d32"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const values = useSelector(state => state.form.customer_details?.values);
  const errors = useSelector(state => state.form.customer_details?.syncErrors);
  const product_selected = useSelector(
    state => state.buyerDashboard.selected_checkout.items
  );
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const [formHasErrors, setFormHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);

  const getProvinces = () => {
    let provinces = [];
    for (var i in ADDRESS) {
      provinces.push({
        display: i,
        value: i
      });
    }
    return provinces;
  };

  const getCities = () => {
    let cities = [];
    let province = values && values.province;
    if (province in ADDRESS) {
      for (var i in ADDRESS[province]["Municipality_list"]) {
        cities.push({
          display: i,
          value: i
        });
      }
    }
    return cities;
  };

  const getBarangay = () => {
    let baranggays = [];
    let province = values && values.province;
    let city = values && values.city;
    if (province in ADDRESS) {
      if (city in ADDRESS[province]["Municipality_list"]) {
        let baranggay_list =
          ADDRESS[province]["Municipality_list"][city]["Barangay_list"];
        for (var i = 0; i < baranggay_list.length; i++) {
          baranggays.push({
            display: baranggay_list[i],
            value: baranggay_list[i]
          });
        }
      }
    }
    return baranggays;
  };

  const handleSubmit = () => {
    history.push(`/my_cart/delivery`);
  };

  React.useEffect(() => {
    for (let entry in errors) {
      if (errors[entry].length > 0) {
        setFormHasErrors(true);
        return;
      }
    }
    setFormHasErrors(false);
  }, [errors]);

  React.useEffect(() => {
    setIsFormEnough(
      values &&
        values.first_name &&
        values.last_name &&
        values.email &&
        values.street_address &&
        values.barangay &&
        values.city &&
        values.province &&
        values.mobile_number
    );
  }, [values]);

  React.useEffect(() => {
    if (!product_selected.length) history.push(Path.BUYER_DASHBOARD);
  }, [product_selected]);

  React.useEffect(() => {
    if (values) {
      dispatch(
        ReduxInitializeFormActions.initializeCustomerDetailsData({ ...values })
      );
    }
  }, [values]);

  React.useEffect(() => {
    dispatch(
      ReduxInitializeFormActions.initializeCustomerDetailsData({
        ...values,
        province: values && values.province,
        city: "",
        barangay: ""
      })
    );
  }, [values && values.province]);

  React.useEffect(() => {
    dispatch(
      ReduxInitializeFormActions.initializeCustomerDetailsData({
        ...values,
        city: values && values.city,
        barangay: ""
      })
    );
  }, [values && values.city]);

  React.useEffect(() => {
    if (values) {
      dispatch(
        ReduxInitializeFormActions.initializeCustomerDetailsData({ ...values })
      );
    } else {
      dispatch(
        ReduxInitializeFormActions.initializeCustomerDetailsData({
          first_name: user.first_name,
          last_name: user.last_name,
          mobile_number: `0${user.mobile_number}`,
          email: user.email,
          street_address: "",
          province: "",
          city: "",
          barangay: "",
          postal_code: ""
        })
      );
    }
  }, []);

  return (
    <div style={{ paddingBottom: isMobile ? "50vh" : "auto" }}>
      <div className={classes.header}>
        <Button
          startIcon={<ArrowBackIcon color="primary" />}
          variant="text"
          color="primary"
          onClick={() => {
            history.goBack();
            window.scrollTo(0, 0);
          }}
          style={{ position: isMobile ? "inherit" : "absolute" }}
        >
          Back
        </Button>
        <div className={classes.headerLabel}>Buyer Details</div>
      </div>
      <div style={{ display: isMobile ? "block" : "flex" }}>
        <Paper
          style={{
            marginTop: "5vh",
            width: isMobile ? "100%" : "65%",
            padding: 24
          }}
        >
          <div className={`d-flex ${isMobile ? "flex-column-reverse" : ""}`}>
            <div style={{ width: isMobile ? "auto" : "50%" }}>
              <div className={classes.label}>Email Address</div>
              <Field
                id="email"
                name="email"
                validate={[
                  validations.required,
                  validations.whiteSpace,
                  validations.email,
                  validations.verifyEmail,
                  validations.noCaps
                ]}
                component={ReduxTextField}
              />
            </div>
            <div
              style={{
                marginLeft: isMobile ? 0 : "2vw",

                width: isMobile ? "auto" : "50%"
              }}
            >
              <div className={classes.label}>Mobile Number</div>
              <Field
                id="mobile_number"
                name="mobile_number"
                component={ReduxTextField}
                inputProps={{
                  maxlength: 11
                }}
                validate={[
                  validations.required,
                  validations.mobileNumber,
                  validations.mobileNumberValidation,
                  validations.mobileNumberValidation2
                ]}
              />
            </div>
          </div>
          <div className={`d-flex ${isMobile ? "flex-column" : ""}`}>
            <div
              style={{
                width: isMobile ? "auto" : "50%",
                marginTop: isMobile ? "3vh" : 0
              }}
            >
              <div className={classes.label}>First Name</div>
              <Field
                id="first_name"
                name="first_name"
                component={ReduxTextField}
                inputProps={{
                  maxlength: 30
                }}
                validate={[validations.required]}
              />
            </div>
            <div
              style={{
                marginLeft: isMobile ? 0 : "2vw",
                width: isMobile ? "auto" : "50%"
              }}
            >
              <div className={classes.label}>Last Name</div>
              <Field
                id="last_name"
                name="last_name"
                component={ReduxTextField}
                inputProps={{
                  maxlength: 30
                }}
                validate={[validations.required]}
              />
            </div>
          </div>
          <div className={`d-flex ${isMobile ? "flex-column" : ""}`}>
            <div style={{ width: "100%" }}>
              <div className={classes.label}>Street Address</div>
              <Field
                id="street_address"
                name="street_address"
                component={ReduxTextField}
                inputProps={{
                  maxlength: 128
                }}
              />
            </div>
          </div>
          <div className={`d-flex ${isMobile ? "flex-column" : ""} mb-4`}>
            <div style={{ width: isMobile ? "auto" : "50%" }}>
              <div className={classes.label}>Province</div>
              <Field
                id="province"
                name="province"
                component={ReduxSelectField}
                variant="outlined"
                margin="dense"
                choices={getProvinces()}
                fullWidth
              />
            </div>
            <div
              style={{
                marginLeft: isMobile ? 0 : "2vw",
                width: isMobile ? "auto" : "50%",
                marginTop: isMobile ? "3vh" : 0
              }}
            >
              <div className={classes.label}>City</div>
              <Field
                id="city"
                name="city"
                margin="dense"
                variant="outlined"
                component={ReduxSelectField}
                disabled={values && !values.province}
                choices={getCities()}
                fullWidth
              />
            </div>
          </div>

          <div className={`d-flex ${isMobile ? "flex-column" : ""}`}>
            <div style={{ width: isMobile ? "auto" : "50%" }}>
              <div className={classes.label}>Barangay</div>
              <Field
                id="barangay"
                name="barangay"
                component={ReduxSelectField}
                variant="outlined"
                margin="dense"
                disabled={values && !values.city}
                choices={getBarangay()}
                fullWidth
              />
            </div>
            <div
              style={{
                marginLeft: isMobile ? 0 : "2vw",
                width: isMobile ? "auto" : "50%",
                marginTop: isMobile ? "3vh" : 0
              }}
            >
              <div className={classes.label}>Postal/ZIP Code</div>
              <Field
                id="postal_code"
                name="postal_code"
                component={ReduxTextField}
                inputProps={{
                  maxlength: 32
                }}
              />
            </div>
          </div>
        </Paper>

        <SummaryForm
          proceed_to_checkout={handleSubmit}
          disabled={!isFormEnough || formHasErrors || !product_selected}
        />
      </div>
      <VoucherModal open={openVoucher} setOpen={setOpenVoucher} />
    </div>
  );
}

let Form = reduxForm({
  form: "customer_details",
  enableReinitialize: true,
  destroyOnUnmount: false
})(BuyerDeliveryDetails);

Form = connect(
  state => ({
    initialValues: state.buyerDashboard.reduxForm.customerDetailsForm
  }),
  {
    initializeData: ReduxInitializeFormActions.initializeCustomerDetailsData
  }
)(Form);

export default Form;
