import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import SentroLoading from "../common/loading";
import {
  useMediaQuery,
  Button,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  InputAdornment
} from "@material-ui/core";
import history from "../../store/history";
import Path from "../../Paths";
import SideMenu from "../dashboard/sideMenu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PropTypes from "prop-types";
import {
  getProfile,
  saveProfile,
  confirmChangePasswordAction
} from "./AccountSettingsAction";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { useLocation } from "react-router-dom";
import axios from "axios";
import apiConfig from "../../common/apiConfig";
import SnackbarAlert from "../../common/SnackbarAlert";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const Layout = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const param = location.pathname;
  const currentLoc = param.replace("/account_settings/", "");

  React.useEffect(() => {
    dispatch(getProfile());
  }, []);
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "#fafafa"
    },
    side: {
      zIndex: 3,
      display: "flex",
      position: "fixed",
      height: "100%"
    },
    content: {
      width: "100%",
      paddingLeft: "10vw",
      paddingRight: "10vw",
      paddingBottom: "10vh"
    },
    headerSection: {
      display: "flex",
      alignItems: "center",
      position: "relative"
    },
    appbar: {
      backgroundColor: "#fff",
      color: "#f97663",
      boxShadow: "none",
      borderBottom: "1px solid #d1d5dd",
      borderRadius: "10px 10px 0 0"
    },
    form: {
      marginTop: "3vh",
      backgroundColor: "#fff",
      border: "1px solid #d1d5dd",
      borderRadius: 10
    },
    label1: {
      fontSize: 14,
      color: "#54575f"
    }
  }));
  const classes = useStyles();
  const loading = useSelector(state => state.user.loading);
  const theme = useTheme();
  const current_user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const passwordDateChanged = current_user ? current_user.password_change : "";
  const user = useSelector(state => state.user.profile);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);
  const [hasErrors, setHasErrors] = React.useState(true);
  const [isFormEnough, setIsFormEnough] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState("");
  const [showNewPassword, setShowNewPassword] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState({
    open: false,
    alertType: "success",
    message: ""
  });
  const [profile, setProfile] = React.useState({
    first_name: "",
    last_name: "",
    mobile_number: ""
  });

  const [password, setPassword] = React.useState({
    current_password: "",
    new_password: ""
  });

  const [errors, setErrors] = React.useState({
    firstNameError: "",
    lastNameError: "",
    mobileNumberError: ""
  });

  const [errorPassword, setErrorPassword] = React.useState({
    currentPasswordError: "",
    newPasswordError: ""
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function setProfileChanges(value, name) {
    setProfile(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function setPasswordChanges(value, name) {
    setPassword(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const FIELD_MAX_LENGTHS = {
    first_name: 30,
    last_name: 30,
    mobile_number: 11
  };
  let charactersLeft = fieldname => {
    const fieldLength = profile[fieldname] ? profile[fieldname].length : 0;
    const charsRemaining = FIELD_MAX_LENGTHS[fieldname] - fieldLength;
    return `${charsRemaining} characters left`;
  };

  function closeAlertBox() {
    setOpenDialog({
      open: false,
      alertType: "success",
      message: ""
    });
  }

  const handleSaveProfile = async () => {
    let response = await dispatch(saveProfile(profile));

    try {
      if (response.status === 200) {
        window.scrollTo(0, 0);
        setOpenDialog({
          open: true,
          alertType: "success",
          message: "Your details are successfully updated."
        });
      }
    } catch {
      setOpenDialog({
        open: true,
        alertType: "error",
        message: "Oops, something went wrong. Please try again."
      });
    }
  };

  const handleSavePassword = async () => {
    let response = await dispatch(confirmChangePasswordAction(password));
    if (response.status === 400) {
      window.scrollTo(0, 0);
      setErrorPassword({
        ...errorPassword,
        currentPasswordError: "Invalid Password. Please try again."
      });
    } else if (response.status === "Success") {
      window.scrollTo(0, 0);
      setChangePassword(false);
      setErrorPassword({
        ...errorPassword,
        currentPasswordError: ""
      });
      setPassword({ current_password: "", new_password: "" });
      setOpenDialog({
        open: true,
        alertType: "success",
        message: "Your password is successfully updated."
      });
    }
  };

  React.useEffect(() => {
    if (mobileView) {
      history.push(Path.MOBILE_WEB_BUILDER);
    }
  }, [mobileView]);

  React.useEffect(() => {
    Object.keys(user).map(item => {
      setProfile(prevState => ({
        ...prevState,
        [item]: user[item]
      }));
    });
  }, [user]);

  React.useEffect(() => {
    for (let e in errors) {
      if (errors[e].length > 0) {
        setHasErrors(true);
        return;
      }
    }
    setHasErrors(false);
  }, [errors]);

  React.useEffect(() => {
    setIsFormEnough(
      profile.first_name && profile.last_name && profile.mobile_number
    );
  }, [profile]);

  React.useEffect(() => {
    if (currentLoc === "password") {
      setValue(1);
    }
  }, [currentLoc]);

  return (
    <div className={classes.root}>
      <SentroLoading open={loading} />
      <div className={classes.content}>
        <div className={classes.header}>
          {/* <Button
            startIcon={<ArrowBackIcon />}
            variant="text"
            color="primary"
            style={{ position: "absolute" }}
            onClick={() => history.push(Path.DASHBOARD)}
          >
            Back
          </Button> */}
          <div
            style={{
              fontSize: 28,
              fontWeight: 600,
              color: "#002c42",
              textAlign: "center",
              width: "100%"
            }}
          >
            Profile
          </div>
        </div>
        <div className={classes.form}>
          <AppBar className={classes.appbar} position="static" color="default">
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
            >
              <Tab label="Basic Information" {...a11yProps(0)} />
              <Tab label="Password" {...a11yProps(1)} />
              <Tab disabled label="OTP" {...a11yProps(2)} />
              <Tab disabled label="Notification" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <div
              style={{
                backgroundColor: "#1e4b83",
                color: "#fff",
                padding: 20,
                fontSize: 12,
                display: current_user.is_facebookLogin ? "" : "none"
              }}
            >
              You are logged in via your Facebook account
            </div>
            <div style={{ padding: 40 }}>
              <div>
                <div style={{ display: "flex" }}>
                  <div className={classes.label1}>First Name</div>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <TextField
                  style={{ width: "50%", marginTop: 0 }}
                  color="secondary"
                  margin="dense"
                  id="first_name"
                  variant="outlined"
                  inputProps={{ maxLength: 30 }}
                  value={profile.first_name}
                  error={errors.firstNameError}
                  disabled={current_user.is_facebookLogin}
                  onChange={value => {
                    const input = value.target.value;
                    setProfileChanges(input, "first_name");
                    if (input === "") {
                      setErrors({
                        ...errors,
                        firstNameError: "This field is required."
                      });
                    } else {
                      setErrors({
                        ...errors,
                        firstNameError: ""
                      });
                    }
                  }}
                  onBlur={value => {
                    const input = value.target.value;
                    if (input === "") {
                      setErrors({
                        ...errors,
                        firstNameError: "This field is required."
                      });
                    } else {
                      setErrors({
                        ...errors,
                        firstNameError: ""
                      });
                    }
                  }}
                  helperText={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div>{errors.firstNameError}</div>
                      <div>{charactersLeft("first_name")}</div>
                    </div>
                  }
                />
              </div>

              <div style={{ marginTop: "1vh" }}>
                <div style={{ display: "flex" }}>
                  <div className={classes.label1}>Last Name</div>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <TextField
                  style={{ width: "50%", marginTop: 0 }}
                  color="secondary"
                  margin="dense"
                  id="last_name"
                  variant="outlined"
                  inputProps={{ maxLength: 30 }}
                  value={profile.last_name}
                  error={errors.lastNameError}
                  disabled={current_user.is_facebookLogin}
                  onChange={value => {
                    const input = value.target.value;
                    setProfileChanges(input, "last_name");
                    if (input === "") {
                      setErrors({
                        ...errors,
                        lastNameError: "This field is required."
                      });
                    } else {
                      setErrors({
                        ...errors,
                        lastNameError: ""
                      });
                    }
                  }}
                  onBlur={value => {
                    const input = value.target.value;
                    if (input === "") {
                      setErrors({
                        ...errors,
                        lastNameError: "This field is required."
                      });
                    } else {
                      setErrors({
                        ...errors,
                        lastNameError: ""
                      });
                    }
                  }}
                  helperText={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div>{errors.lastNameError}</div>
                      <div>{charactersLeft("last_name")}</div>
                    </div>
                  }
                />
              </div>

              <div style={{ marginTop: "1vh" }}>
                <div style={{ display: "flex" }}>
                  <div className={classes.label1}>Mobile Number</div>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <TextField
                  style={{ width: "50%", marginTop: 0 }}
                  color="secondary"
                  margin="dense"
                  id="mobile_number"
                  variant="outlined"
                  inputProps={{ maxLength: 11 }}
                  value={profile.mobile_number}
                  error={errors.mobileNumberError}
                  disabled={current_user.is_facebookLogin}
                  onChange={value => {
                    const input = value.target.value.replace(/[^0-9]/g, "");
                    setProfileChanges(input, "mobile_number");
                    if (input === "") {
                      setErrors({
                        ...errors,
                        mobileNumberError: "This field is required."
                      });
                    } else {
                      setErrors({
                        ...errors,
                        mobileNumberError: ""
                      });
                    }
                  }}
                  onBlur={value => {
                    const input = value.target.value;
                    if (input === "") {
                      setErrors({
                        ...errors,
                        mobileNumberError: "This field is required."
                      });
                    } else if (input.length !== 11) {
                      setErrors({
                        ...errors,
                        mobileNumberError:
                          "Please enter a valid 11-digit mobile number."
                      });
                    } else if (input.charAt(0) != 0) {
                      setErrors({
                        ...errors,
                        mobileNumberError:
                          "You have entered an invalid mobile number"
                      });
                    } else if (input.charAt(1) != 9) {
                      setErrors({
                        ...errors,
                        mobileNumberError:
                          "You have entered an invalid mobile number"
                      });
                    } else {
                      setErrors({
                        ...errors,
                        mobileNumberError: ""
                      });
                    }
                  }}
                  helperText={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div>{errors.mobileNumberError}</div>
                      {/* <div>{charactersLeft("mobile_number")}</div> */}
                    </div>
                  }
                />
              </div>
              <div style={{ marginTop: "1vh" }}>
                <div style={{ display: "flex" }}>
                  <div className={classes.label1}>Email Address</div>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <TextField
                  disabled
                  style={{ marginTop: 0, width: "50%" }}
                  color="secondary"
                  variant="outlined"
                  value={current_user.email}
                />
              </div>
              <Button
                style={{ marginTop: "5vh" }}
                variant="contained"
                disableElevation
                color="primary"
                fullWidth
                disabled={
                  hasErrors || !isFormEnough || current_user.is_facebookLogin
                }
                onClick={() => handleSaveProfile()}
              >
                Save
              </Button>
            </div>
          </TabPanel>

          <TabPanel value={value} index={1}>
            {!changePassword ? (
              <div
                style={{
                  padding: 100,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <div>
                  <div className={classes.label1}>
                    Your password was updated last{" "}
                    {moment(passwordDateChanged).format(`DD MMM YYYY`)}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{ marginTop: "3vh", width: 280 }}
                      variant="outlined"
                      color="primary"
                      onClick={() => setChangePassword(true)}
                      disabled={current_user.is_facebookLogin ? true : false}
                    >
                      Change Password
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ padding: 40 }}>
                <div>
                  <div style={{ display: "flex" }}>
                    <div className={classes.label1}>Current Password</div>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <TextField
                    style={{ width: "50%", marginTop: 0 }}
                    color="secondary"
                    margin="dense"
                    id="current_password"
                    variant="outlined"
                    inputProps={{ maxLength: 30 }}
                    value={password.current_password}
                    error={errorPassword.currentPasswordError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            style={{ color: "#2b2d33", fontSize: 10 }}
                            onClick={e => setShowOldPassword(!showOldPassword)}
                          >
                            {showOldPassword ? "HIDE" : "SHOW"}
                          </Button>
                        </InputAdornment>
                      )
                    }}
                    onChange={value => {
                      const input = value.target.value;
                      setPasswordChanges(input, "current_password");
                      if (input === "") {
                        setErrorPassword({
                          ...errorPassword,
                          currentPasswordError: "This field is required."
                        });
                      } else {
                        setErrorPassword({
                          ...errorPassword,
                          currentPasswordError: ""
                        });
                      }
                    }}
                    onBlur={value => {
                      const input = value.target.value;
                      if (input === "") {
                        setErrorPassword({
                          ...errorPassword,
                          currentPasswordError: "This field is required."
                        });
                      } else if (
                        !input.match(
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
                        )
                      ) {
                        setErrorPassword({
                          ...errorPassword,
                          currentPasswordError:
                            "Please have at least 8 characters with a mix of numbers, letters and symbols."
                        });
                      }
                    }}
                    helperText={errorPassword.currentPasswordError}
                    type={showOldPassword ? "text" : "password"}
                  />
                </div>

                <div style={{ marginTop: "2vh" }}>
                  <div style={{ display: "flex" }}>
                    <div className={classes.label1}>New Password</div>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <TextField
                    style={{ width: "50%", marginTop: 0 }}
                    color="secondary"
                    margin="dense"
                    id="new_password"
                    variant="outlined"
                    inputProps={{ maxLength: 30 }}
                    value={password.new_password}
                    error={errorPassword.newPasswordError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            style={{ color: "#2b2d33", fontSize: 10 }}
                            onClick={e => setShowNewPassword(!showNewPassword)}
                          >
                            {showNewPassword ? "HIDE" : "SHOW"}
                          </Button>
                        </InputAdornment>
                      )
                    }}
                    onChange={value => {
                      const input = value.target.value;
                      setPasswordChanges(input, "new_password");
                      if (input === "") {
                        setErrorPassword({
                          ...errorPassword,
                          newPasswordError: "This field is required."
                        });
                      } else {
                        setErrorPassword({
                          ...errorPassword,
                          newPasswordError: ""
                        });
                      }
                    }}
                    onBlur={value => {
                      const input = value.target.value;
                      if (input === "") {
                        setErrorPassword({
                          ...errorPassword,
                          newPasswordError: "This field is required."
                        });
                      } else if (
                        !input.match(
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
                        )
                      ) {
                        setErrorPassword({
                          ...errorPassword,
                          newPasswordError:
                            "Please have at least 8 characters with a mix of numbers, letters and symbols."
                        });
                      }
                    }}
                    helperText={errorPassword.newPasswordError}
                    type={showNewPassword ? "text" : "password"}
                  />
                </div>
                <Button
                  style={{ marginTop: "5vh" }}
                  variant="contained"
                  disableElevation
                  color="primary"
                  fullWidth
                  disabled={
                    !password.current_password ||
                    !password.new_password ||
                    errorPassword.currentPasswordError ||
                    errorPassword.newPasswordError
                  }
                  onClick={() => handleSavePassword()}
                >
                  Save
                </Button>
              </div>
            )}
          </TabPanel>
        </div>
      </div>
      {/* <div className={classes.root}>
        <div className={classes.header}>Account Settings</div>
        <div className={classes.content}>
          <div style={{ display: "block", width: "25%" }}>
            <SideMenu />
          </div>
          <div>{props.children}</div>
        </div>
      </div> */}
      <SnackbarAlert
        open={openDialog.open}
        alertType={openDialog.alertType}
        message={openDialog.message}
        closeAlertBox={closeAlertBox}
      />
    </div>
  );
};

export default Layout;
