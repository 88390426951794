import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Paper, Button, OutlinedInput } from '@material-ui/core';
import history from "../../../../store/history";
import Layout from "../../layout"
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { animateScroll as scroll } from "react-scroll";
import qs from 'query-string';
import Actions from "../../Redux/Actions"
import _ from 'lodash'
import getFlag from '../../utils';


const useStyles = theme => ({
  locationAndStore: {
    fontWeight: "bold",
    fontSize: 14
  },
  backHeader: {
    width: 120,
    display: "flex !important",
    alignItems: "center",
    padding: "0 0 2em",
    fontWeight: 600,
    fontSize: 14,
    color: "#F97663 !important"
  },
  store: {
    color: "#F97663",
    fontWeight: "bold",
    fontSize: 16 
  },
  name: {
    fontSize: 40,
    fontWeight: "bold"
  },
  avatar: {
    height: 30,
    width: 30,
    borderRadius: '50%',
    backgroundSize: "cover",
    backgroundPosition: "left",
    boxShadow: "0px 1px 5px 0 rgba(0,0,0,0.05)"
  },
  country: {
    marginLeft: "1em",
    color: "#909196"
  },
  price: {
    fontSize: 32,
    fontWeight: 600
  },
  quantityInput: {
    maxWidth: 100,
    height: 45
  },
  button: {
    height: 45
  },
  productDetails: {
    whiteSpace: "pre-line"
  },
  carousel: {

    "& .BrainhubCarousel__dot": {
      background: "white",
      opacity: "1 !important"
    },
    "& .BrainhubCarousel__dot:active": {
      border: "none"
    },
    "& .BrainhubCarousel__dot:before": {
      width: 13,
      height: 13,
      border: "solid 2px #909196",
      borderRadius: "50%",
      background: "white",

    },
    "& .BrainhubCarousel__dot--selected:before": {
      background: "#909196",
    },
    "& .BrainhubCarousel__arrows": {
      background: "transparent !important",
      "& span": {
        borderColor: "#909196 !important"
      }
    }
  }
});

class ProductDetailed extends React.Component {

  state = {
    quantity: 1,
    carouselIndex: 0,
    flag: ""
  }

  componentDidMount = () => {
    scroll.scrollTo(0)
    const product = JSON.parse(localStorage.getItem("PROXTERA_PRODUCT"));
    this.props.dispatch(Actions.GET_SUPPLIER({
        supplier_id: product.supplier_id
    }))
    const data = getFlag(product.territory);
    if(data) {
      this.setState({
        ...this.state,
        flag: data.file_url
      })
    }
  }

  onChange = (carouselIndex) => {
    this.setState({
      ...this.state,
      carouselIndex
    })
  }

  qtyOnChange = (e) => {
    this.setState({
      ...this.state,
      quantity: e.target.value
    })
  }

  addToCart = (product) => {
    const { dispatch } = this.props;
    dispatch(Actions.ADD_TO_CART({
      item: {
        ...product,
        subtotal: (product.unit_price * this.state.quantity).toFixed(2),
        quantity: String(this.state.quantity),
        unit_price: String(product.unit_price*100)
      }
    }))
    history.push("/proxtera/cart")
  }

  render (){
    const { classes, products, supplier } = this.props;
    // const product = {
    //   country: "Philippines",
    //   store: "XYZ Store",
    //   name: "Premium Mt. Apo Arabica  Coffee Beans (1 kg.)",
    //   price: "125.5",
    //   country_flag: "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg",
    //   images: [
    //   "https://i.ndtvimg.com/i/2016-06/nuts_625x350_41464956952.jpg",
    //   "https://ginginanddry.net.au/wp-content/uploads/2020/02/GinGinandDry-product-mango.jpg",
    //   "https://i0.wp.com/post.healthline.com/wp-content/uploads/2020/06/cashews-nuts-1296x728-header.jpg?w=1155&h=1528",
    //   ],
    //   description: `Produced Locally
    //     Freshly Roasted Daily by us in Brgy. Bolbok, Lipa City
    //     Freshly Ground and Sealed upon Order

    //     Grind Sizes :
    //     Fine           -   (Panlaga, Stove Brewing, Aeropress)
    //     Medium    -   (Coffee Maker Grind)
    //     Coarse      -   (French Press or Percolator)
    //     Beans        -   If you wand to grind yourself :)`,
      
    // }

    let product = JSON.parse(localStorage.getItem("PROXTERA_PRODUCT"));
    product = {
      ...product,
      name: product.short_name,
    }
    const images = [product ? product.image:null] || []
    return (
      <Layout noSideBar>
        <a className={classes.backHeader} href="#" onClick={(e) => {
            e.preventDefault();
            history.goBack();
        }}>
          <ArrowBackIcon/>
          <span style={{marginLeft: "1em"}}>{`  BACK`}</span>
        </a>
        <h5 className={`mb-3 flex-grow-1 ${classes.locationAndStore}`}>{product.country || "Country"}/ {supplier ? supplier.company_name: ``}</h5>
        <Paper className="d-flex p-4" style={{ minHeight: "100vh"}}>
          <div className={`h-100 w-50 ${classes.carousel}`} id="product_image">
            <Carousel slidesPerPage={1}
            arrows dots onChange={this.onChange} value={this.state.carouselIndex}
            >
              {
                images.map((image, index) => (
                  <div key={index} style={{
                    width: "100%",
                    paddingTop: "100%",
                    position: "relative"
                  }}>
                    <div style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url(${image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover"
                    }} />
                  </div>
                ))
              }
            </Carousel>
            <Dots
            style={{
              overflowX: "auto"
            }}
              value={this.state.carouselIndex}
              onChange={this.onChange}
              number={images.length}
              thumbnails={[...images.map(image => (
                <div style={{
                  width: 70,
                  paddingTop: "100%",
                  position: "relative"
                }}>
                  <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${image})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                  }} />
                </div>
              ))]}
            />
          </div>
          <div className="h-100 w-50" id="product_details">
            {
              supplier &&
              <p className={`${classes.store} mb-4`}>{supplier.company_name}</p>
            }
            <h2 className={`${classes.name} mb-4`}>{product.short_name}</h2>
            <div className="d-flex mb-4">
              {
                this.state.flag &&
                <div className={classes.avatar} style={{
                  backgroundImage: `url('${this.state.flag}')`,
                }}/>
              }
              {
                product.territory &&
                <p className={classes.country}>{product.territory}</p>
              }
            </div>
            <h2 className={`${classes.price} mb-4`}>USD {product.unit_price}</h2>
            <p>Quantity</p>
            <div className="d-flex mb-4">
              <OutlinedInput
                className={classes.quantityInput}
                id="outlined-number"
                type="number"
                variant="outlined"
                value={this.state.quantity}
                onChange={this.qtyOnChange}
                inputProps={{
                  min: 1
                }}
              />
              <Button
                id="start_selling" 
                variant="contained" 
                color="primary" 
                disableElevation 
                className={`flex-grow-1 ml-3 ${classes.button}`}
                onClick={() => this.addToCart(product)}
              >
                Add to Cart
              </Button>
            </div>
            <p><strong>Product Details</strong></p>
            <p className={classes.productDetails}>
              {product.description}
            </p>
          </div>
        </Paper>
      </Layout>
    )
  }
}


const mapStateToProps = state => ({ proxtera }) => ({ 
    products: proxtera.products,
    supplier: proxtera.supplier
});
export default connect(mapStateToProps)(withStyles(useStyles)(ProductDetailed));

