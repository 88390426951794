import { Chip, IconButton } from "@material-ui/core";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trashbin.svg";
import React from "react";

export default function PickupAddressCard(props) {
  const { item, handleEdit, handleDelete } = props;

  return (
    <div className="border bg-white px-3 pb-3 pt-2" style={{ borderRadius: 4 }}>
      <div className="d-flex align-items-center justify-content-between">
        {item.is_default ? (
          <Chip
            size="small"
            label="Default"
            style={{ backgroundColor: "#9a9ea9", color: "#fff" }}
          />
        ) : (
          <Chip
            size="small"
            label={`Address ${item.id}`}
            style={{
              backgroundColor: "#fff",
              color: "#9a9ea9",
              borderColor: "#9a9ea9",
              border: "1px solid ",
            }}
          />
        )}

        <div className="d-flex">
          <IconButton onClick={() => handleEdit(item)}>
            <EditIcon style={{ width: 24, height: 24 }} />
          </IconButton>
          <IconButton onClick={() => handleDelete(item)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <div className="d-flex col-12 p-0 mt-2">
        <div
          className="col-md-2 col-3 pl-0"
          style={{ fontSize: 12, fontWeight: 600 }}
        >
          Address
        </div>
        <div
          className="col-md-10 col-9 pr-0"
          style={{ fontSize: 14, fontWeight: 500 }}
        >
          {`${item.street}, ${item.brgy}, ${item.city}, ${item.zipcode} ${item.province}`}
        </div>
      </div>
      <div className="d-flex col-12 p-0 my-3">
        <div
          className="col-md-2 col-3 pl-0"
          style={{ fontSize: 12, fontWeight: 600 }}
        >
          Phone
        </div>
        <div
          className="col-md-10 col-9 pr-0"
          style={{ fontSize: 14, fontWeight: 500 }}
        >
          0{item.mobile_number}
        </div>
      </div>
    </div>
  );
}
