import sevenEleven from "../../../../assets/images/OTC_channels/7Eleven.png";
import bayadCenter from "../../../../assets/images/OTC_channels/BayadCenterLogo.png";
import cebuana from "../../../../assets/images/OTC_channels/CebuanaLogo.png";
import ecPay from "../../../../assets/images/OTC_channels/ECPay.png";
import expressPay from "../../../../assets/images/OTC_channels/ExpressPay.png";
import lbc from "../../../../assets/images/OTC_channels/LBC.png";
import mlhuillier from "../../../../assets/images/OTC_channels/mlhuillier.png";
import palawan from "../../../../assets/images/OTC_channels/Palawan.png";
import rd from "../../../../assets/images/OTC_channels/RD.png";
import robinsons from "../../../../assets/images/OTC_channels/robinsons.png";
import rural from "../../../../assets/images/OTC_channels/Rural.png";
import sm from "../../../../assets/images/OTC_channels/SM.png";

function OTCchannelLogo(channel) {
  if (channel === "7-Eleven") {
    return sevenEleven;
  } else if (channel === "Bayad Center") {
    return bayadCenter;
  } else if (channel === "Cebuana Lhuillier") {
    return cebuana;
  } else if (channel === "ECPay / Expresspay") {
    return ecPay;
  } else if (channel === "LBC") {
    return lbc;
  } else if (channel === "Robinsons Dept Store") {
    return robinsons;
  } else if (channel === "RD Pawnshop") {
    return rd;
  } else if (channel === "RuralNet Banks") {
    return rural;
  } else if (channel === "Palawan Pawnshop") {
    return palawan;
  } else if (channel === "SM / Supermarke / Savemore") {
    return sm;
  }
}

export default OTCchannelLogo;
