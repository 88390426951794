import React from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Button
} from "@material-ui/core";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import ReduxNumberField from "../../../reduxFields/numberField";
import ReduxTextField from "../../../reduxFields/textField";
import validations from "../../../common/validations";

const VariantCombinationModal = props => {
  const useStyles = makeStyles(theme => ({
    label: {
      color: "rgba(43, 45, 50, 0.8)",
      fontSize: 12,
      fontWeight: 600
    },
    removeBtn: {
      cursor: "pointer",
      color: "#FF6B55",
      marginLeft: "auto",
      fontSize: 12,
      fontWeight: 600
    }
  }));
  const classes = useStyles();

  const formValues = useSelector(state => state.form.variant_choices?.values);
  React.useEffect(() => {
    if (props.open) {
      Object.keys(formValues).forEach(key => {
        if (key.includes(`-${props.index}`) && !key.includes(`images`)) {
          const fieldname = key.slice(0, parseInt(key.length) - 2);
          props.change(fieldname, formValues[key]);
          if (fieldname === "discounted_price") {
            set_discounted_price(Boolean(formValues[key]));
          }
        }
      });
    }
  }, [props.open]);

  const errors = useSelector(state => state.form.variant_choices?.syncErrors);
  const onSubmit = () => {
    const formFields = ["price", "discounted_price", "quantity", "sku"];
    let has_errors = false;
    try {
      has_errors = Object.keys(errors).some(fieldname =>
        formFields.includes(fieldname)
      );
    } catch {}
    if (has_errors) {
      formFields.forEach(fieldname => {
        props.touch(fieldname);
      });
    } else {
      props.setOpen(false);
      formFields.forEach(key => {
        if (!key.includes(`-`) && !key.includes(`images`)) {
          props.change(`${key}-${props.index}`, formValues[key] ?? "");
        }
      });
    }
  };

  const [has_discounted_price, set_discounted_price] = React.useState(false);
  const removeDiscountedPrice = () => {
    props.change(`discounted_price`, "");
    set_discounted_price(false);
  };

  return (
    <Dialog
      id="location-picker-dialog"
      fullWidth
      maxWidth="xs"
      open={props.open}
    >
      <DialogContent className="p-4">
        <p className={`mb-4 ${classes.label2}`}>
          {props.data ? (
            <strong>{props.data.variants.map(i => i.name).join(" / ")}</strong>
          ) : (
            ""
          )}
        </p>
        <IconButton
          className="position-absolute"
          style={{ top: 5, right: 5 }}
          onClick={() => props.setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <FormControl
          variant="outlined"
          margin="dense"
          className="w-100 m-0 mt-2"
        >
          <div className={classes.label}>Price</div>
          <Field
            fullWidth
            id={`price`}
            name={`price`}
            label=""
            component={ReduxNumberField}
            validate={[validations.required, validations.isNegativeNumber]}
            showHelperText={false}
          />
        </FormControl>
        {has_discounted_price ? (
          <FormControl
            variant="outlined"
            margin="dense"
            className="w-100 m-0 mt-2"
          >
            <div className="d-flex align-items-center">
              <div className={classes.label}>Discounted Price (optional)</div>
              <div
                className={classes.removeBtn}
                onClick={removeDiscountedPrice}
              >
                Remove
              </div>
            </div>
            <Field
              fullWidth
              className="mr-1"
              id="discounted_price"
              name="discounted_price"
              label=""
              component={ReduxTextField}
              validate={[validations.isNegativeNumber]}
              showHelperText={true}
              type="number"
              inputProps={{
                min: 1,
                max: formValues?.price
              }}
            />
          </FormControl>
        ) : (
          <Button
            id="add_discounted_price_button"
            className="mt-3"
            color="primary"
            variant="outlined"
            disableElevation
            onClick={() => set_discounted_price(true)}
          >
            Add Discounted Price +
          </Button>
        )}
        <FormControl
          variant="outlined"
          margin="dense"
          className="w-100 m-0 mt-2"
        >
          <div className={classes.label}>Quantity</div>
          <Field
            fullWidth
            className="mr-1"
            id={`quantity`}
            name={`quantity`}
            label=""
            component={ReduxTextField}
            validate={[validations.required, validations.stock]}
            showHelperText={false}
            // type="number"
            // inputProps={{
            //   min: 1
            // }}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          margin="dense"
          className="w-100 m-0 mt-2"
        >
          <div className={classes.label}>Product Code</div>
          <p
            style={{ color: "rgba(43, 45, 50, 0.8)", fontSize: 12, margin: 0 }}
          >
            Optional. Change only if you have SKU for this item.
          </p>
          <Field
            fullWidth
            id={`sku`}
            name={`sku`}
            label=""
            component={ReduxTextField}
            showHelperText={false}
          />
        </FormControl>
        <div className="d-flex mt-3">
          <Button
            fullWidth
            disableElevation
            className={`${classes.btn} ml-2 flex-grow-1`}
            color="primary"
            style={{ height: 38 }}
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            className={`${classes.btn} flex-grow-1`}
            color="primary"
            style={{ height: 38 }}
            disabled={Boolean(!formValues?.price || !formValues?.quantity)}
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default VariantCombinationModal;
