import axios from "axios";
import apiConfig from "../../../../common/apiConfig";

class BuyerServices {
  static async getMyOrders() {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/buyer-orders/`, {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
        }
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getSelectedOrder(store_uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/cart/store/${store_uuid}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getPurchasedOrders(value) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/buyer-orders/?status=${value}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async purchasedViewOrder(order_id) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/buyer-orders/${order_id}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async markAsReceived(id) {
    try {
      let response = await axios.patch(
        `${apiConfig.apiURL}/api/buyer-orders/${id}/`,
        {
          is_received: true
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async cancelOrder(id) {
    try {
      let response = await axios.put(
        `${apiConfig.apiURL}/api/cancel-order/${id}/`,
        {
          is_received: true
        },
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getMyCart() {
    let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/cart/${user.id}/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getVouchers(uuid, params) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/promo/voucher/`, {
        params: { store: uuid, ...params }
      });
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async getDeliveryLocations(uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/delivery-locations/?store_uuid=${uuid}`
      );
      return response;
    } catch (e) {
      throw e.response;
    }
  }

  static async computeShipping(params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/mrspeedy/calculate_order/`,
        params
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getPayments(uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/payment_method/store/${uuid}`
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async checkout(value, paymentType) {
    let params = {
      ...value,
      payment_option: paymentType["paymentOption"],
      channel:
        paymentType["paymentOption"] === "otc" ? null : paymentType["account"],
      is_read: false
    };

    try {
      let res = await axios.post(
        `${apiConfig.apiURL}/api/order/${value.uuid}/`,
        params,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );

      // GTM trigger
      window.dataLayer.push({
        event: "checkout",
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: null },
            products: JSON.parse(value.cart).map(product => ({
              id: value.uuid,
              name: product.name,
              price: product.unit_price,
              quantity: product.quantity,
              [`dimension${apiConfig.indexStoreID}`]: product.store
            }))
          }
        }
      });

      return res;
    } catch (error) {
      throw error.response;
    }
  }

  static async viewCheckoutOrder(order_id) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/order/${order_id}`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async buxCheckout(ref_id) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/order/check_code/?ref_id=${ref_id}`
      );
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }
}
export default BuyerServices;
