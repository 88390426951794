import React from "react";
import { Button } from "@material-ui/core";

export default function VariantPicker(props) {
  const [selected_variant, set_selected_variant] = React.useState({});

  const onChange = (group_id, value) => {
    set_selected_variant(state => ({
      ...state,
      [group_id]: value
    }));
  };

  React.useEffect(() => {
    if (props.product_selection_value) {
      let data = {};
      const selection_variants = props.product_selection_value.variants;
      for (let i in selection_variants) {
        data[String(selection_variants[i].group)] = selection_variants[i].id;
      }
      set_selected_variant(data);
    }
  }, [props.product_selection_value]);

  const findProductSelectionMatch = selectedVariantSelectionIds => {
    // find selection
    const matched_selection = props.product?.selections?.filter(i =>
      i.variants.every(v => selectedVariantSelectionIds.some(id => v.id === id))
    );
    return matched_selection?.length ? matched_selection[0] : null;
  };

  React.useEffect(() => {
    const variant_temp_ids_int = Object.values(selected_variant);
    const matched_selection = findProductSelectionMatch(variant_temp_ids_int);
    if (props.onSelectionChange) props.onSelectionChange(matched_selection);
  }, [selected_variant]);

  React.useEffect(() => {
    if (!props?.product) {
      set_selected_variant({});
    }
  }, [props?.product]);

  return (
    <div>
      {props.product?.has_variants && props.product?.variant_groups.length
        ? props.product?.variant_groups.map((variant_group, index) => {
            return (
              <div className="d-flex flex-wrap align-items-center mb-2">
                {variant_group.variants.length
                  ? variant_group.variants
                      .filter(variant => variant.is_enabled)
                      .map((variant, index) => (
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          className="mr-2"
                          onClick={e => onChange(variant_group.id, variant.id)}
                          style={{
                            height: 30,
                            fontWeight: 500,
                            color:
                              selected_variant[variant_group.id] === variant.id
                                ? "#f97663"
                                : "#54575f",
                            borderColor:
                              selected_variant[variant_group.id] === variant.id
                                ? "#f97663"
                                : "#54575f"
                          }}
                        >
                          {variant.name}
                        </Button>
                      ))
                  : ""}
              </div>
            );
          })
        : ""}
    </div>
  );
}
