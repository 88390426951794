import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Button,
  Popover,
  MenuItem,
  TextField,
  InputAdornment,
  useMediaQuery,
  IconButton
} from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import ProductActions from "../../../redux/actions/productActions";
import MarketplaceActions from "../../../redux/actions/marketplaceActions";
import SearchIcon from "@material-ui/icons/Search";
import { useTheme } from "@material-ui/styles";
import MoreIcon from "@material-ui/icons/Menu";
import Sidebar from "./sideBar";
import history from "../../../../../store/history";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
export default function Appbar() {
  const enterprise = useSelector(state => state.enterprise.main.enterprise);
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "#eee",
      width: "100%",
      boxShadow: "none",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#fff",
        borderBottom: "1px solid #d1d5dd"
      }
    },
    toolbar: {
      padding: "0 4vw",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    name: {
      marginRight: "2vw",
      fontSize: 14,
      fontWeight: 600,
      textTransform: "capitalize",
      color: "#002c42"
    },
    textfield: {
      fontSize: 12
    },
    sitename: {
      fontSize: 18,
      color: "#1e4b83",
      fontWeight: 600,
      textTransform: "capitalize"
    }
  }));
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const data = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const first_name = data ? data.first_name : "";

  const handleOptions = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpenDrawer(false);
  };

  const openPopover = Boolean(anchorEl);
  const popoverItems = [
    { label: "Profile" },
    { label: "Help Center" },
    {
      label: "Logout",
      onClick: () => dispatch(MarketplaceActions.logout(enterprise))
    }
  ];

  const filters = useSelector(state => state.enterprise.main.products.filters);
  const [timeoutId, setTimeoutId] = React.useState("");
  const onSearch = e => {
    const val = e.target.value;
    dispatch(
      ProductActions.setProductFilters({
        search: val
      })
    );
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        // dispatch api call here; 0.5sec after typing
        dispatch(
          ProductActions.getProducts(enterprise.site_name, {
            ...filters,
            search: val,
            category: ""
          })
        );
        history.push(`/enterprise/${enterprise.site_name}/products/`);
      }, 1000)
    );
  };

  const goToMarketplace = () => {
    history.push(`/enterprise/${enterprise.site_name}`);
    dispatch(ProductActions.getBuyerCart(enterprise.site_name));
  };

  return (
    <div style={{ zIndex: 1 }}>
      <AppBar position="fixed" className={classes.root}>
        {isMobile ? (
          <Toolbar className={classes.toolbar}>
            <div className="d-flex align-items-center">
              <ArrowBackIosIcon
                onClick={goToMarketplace}
                style={{ color: "#1e4b83", fontSize: 16, marginRight: "1vw" }}
              />
              <div
                onClick={() =>
                  history.push(
                    `/enterprise/${enterprise.site_name}/buyer_dashboard`
                  )
                }
                className={classes.sitename}
              >
                {enterprise.name}
              </div>
            </div>
            <IconButton onClick={() => setOpenDrawer(true)}>
              <MoreIcon style={{ color: "#1e4b83" }} />
            </IconButton>
            <Sidebar open={openDrawer} toggleDrawer={toggleDrawer} />
          </Toolbar>
        ) : (
          <Toolbar className={classes.toolbar}>
            <TextField
              placeholder="Search the marketplace..."
              margin="dense"
              variant="outlined"
              style={{ backgroundColor: "#fafafa", marginLeft: 260 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                classes: { input: classes.textfield }
              }}
              onChange={onSearch}
            />
            <div className="d-flex justify-content-end align-items-center">
              <div className={classes.name}>Hello, {first_name}</div>
              <Button
                startIcon={
                  <AccountCircleOutlinedIcon
                    style={{ color: "#002c42", fontSize: 24 }}
                  />
                }
                endIcon={<ArrowDropDownIcon color="secondary" />}
                variant="text"
                onClick={handleOptions}
              />
              <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                {popoverItems.map(item => {
                  return (
                    <MenuItem button onClick={item.onClick}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Popover>
            </div>
          </Toolbar>
        )}
      </AppBar>
    </div>
  );
}
