import React from "react";
import ImageUploading from "react-images-uploading";
import { makeStyles } from "@material-ui/core";
import AddPhoto from "../../../../../../../assets/images/randoms/addphoto.png";
import CancelIcon from "@material-ui/icons/Cancel";
import CropperForm from "./cropperForm";

export default function ReplaceImage(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      height: "100%",
      cursor: "pointer",
      width: "100%"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content"
    },
    content: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    icon: {
      textAlign: "center",
      width: "100%",
      color: "#38b99f",
      fontSize: 100
    },
    name: {
      fontSize: 16,
      fontWeight: 600,
      color: "#002c42"
    },
    site: {
      fontSize: 14,
      color: "#54575d"
    },
    btnProduct: {
      height: 50
    },
    uploaderDiv: {
      color: "#f97663",
      fontSize: 12,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      borderRadius: 4,
      position: "relative",
      overflow: "hidden",
      "& > .icon": {
        height: 20,
        width: 20,
        left: "50%",
        top: "50%",
        position: "absolute",
        transform: "translateX(-50%) translateY(-50%)"
      }
    },
    dashed: {
      border: props.label ? "none" : "1px solid #F97663"
    },
    removeButton: {
      color: "#F97663",
      position: "absolute",
      top: 5,
      right: 5,
      backgroundColor: "white",
      borderRadius: "50%"
    }
  }));
  const classes = useStyles();
  const { multiple = false, aspect = 1 / 1 } = props;
  const [images, setImages] = React.useState([]);
  const [cropperForm, setCropperFormStat] = React.useState({
    open: false,
    image: ""
  });

  React.useEffect(() => {
    if (props.preview) {
      setImages([
        {
          data_url: props.preview
        }
      ]);
    }
  }, []);

  const closeCropper = () => {
    setCropperFormStat({
      ...cropperForm,
      open: false
    });
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    // setCropperFormStat({
    //   ...cropperForm,
    //   open: true,
    //   image: ""
    // });
    // setCropperFormStat({
    //   ...cropperForm,
    //   open: true,
    //   image: imageList.length ? imageList[imageList.length - 1].file : ""
    // });
    if (props.setFieldname) {
      props.setFieldname(props.fieldname);
    }
    props.onChange(
      imageList.length ? imageList[imageList.length - 1].file : ""
    );
    props.onClose();
    props.openCropper(
      imageList.length ? imageList[imageList.length - 1].file : ""
    );
    if (props.onError) props.onError("");
  };

  const onCropSave = image => {
    setImages([
      {
        data_url: URL.createObjectURL(image),
        image
      }
    ]);
    props.onChange(image);
    props.onClose();
  };

  const onError = (errors, files) => {
    if (props.onError) props.onError(errors);
    console.log(errors);
  };

  return (
    <div className={classes.root}>
      <ImageUploading
        multiple={multiple}
        value={images}
        onChange={onChange}
        maxNumber={10}
        maxFileSize={5242880}
        dataURLKey="data_url"
        acceptType={["jpg", "png", "jpeg", "gif"]}
        onError={onError}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => (
          <div
            className="upload__image-wrapper"
            style={{ height: "100%", width: "100%" }}
          >
            <div
              className={`${classes.uploaderDiv}
              ${
                !multiple
                  ? !(imageList.length > 0) && classes.dashed
                  : classes.dashed
              }`}
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              <div style={{ fontSize: 14, fontWeight: "bold" }}>
                {props.label ? "Edit" : "Replace"}
              </div>
            </div>
            <div>
              {errors && (
                <div style={{ width: "30em", marginBottom: "2vh" }}>
                  {errors.acceptType && (
                    <span style={{ color: "red", fontSize: 12 }}>
                      The selected file type is not allowed
                      {console.log(errors)}
                    </span>
                  )}
                </div>
              )}
            </div>
            &nbsp;
          </div>
        )}
      </ImageUploading>
      {/* {cropperForm.image &&
        (cropperForm.image.name.endsWith(".gif") ? (
          ""
        ) : (
          <CropperForm
            image={cropperForm.image}
            open={cropperForm.open}
            aspect={aspect}
            onClose={closeCropper}
            fieldname={props.fieldname}
            onSave={image => onCropSave(image)}
          />
        ))} */}
    </div>
  );
}
