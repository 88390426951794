import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Button, useMediaQuery } from "@material-ui/core";
import PaymentCard from "../../paymentCard";
import history from "../../../../store/history";
import Path from "../../../../Paths";

export default function OtherAccountsPane(props) {
  const useStyles = makeStyles(theme => ({
    content: {
      [theme.breakpoints.down("sm")]: {
        overflow: "scroll",
        height: "72vh"
      }
    }
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const language = localStorage.getItem("language");
  const paymentMethods = useSelector(state => state.payments.paymentMethods);

  return (
    <div>
      {paymentMethods && paymentMethods.length ? (
        <div
          className={`${classes.content} py-4 px-md-5 px-3 position-relative`}
        >
          <div className="d-md-flex justify-content-between align-items-center">
            <div
              style={{ fontSize: 14, color: "#54575f" }}
              className={`${isMobile ? "w-100" : "w-50"}`}
            >
              {language === "English"
                ? "Add details of your Bank, PayMaya or PayPal account where your customers can pay. This will be displayed in your online shop."
                : "Ilagay ang detalye ng iyong Bangko, PayMaya o Paypal account kung saan pwedeng magbayad ang iyong customers. Makikita ito sa payment instructions page kapag may bumili sa iyong shop."}
            </div>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              style={{ height: 40 }}
              className="px-5 d-md-block d-none"
              disabled={paymentMethods && paymentMethods.length >= 5}
              onClick={() => history.push(Path.PAYMENTS_ADD)}
            >
              {language === "English" ? "Add Account" : "Magdagdag ng Account"}
            </Button>
          </div>
          <div className="d-flex flex-wrap pt-4">
            {paymentMethods.map(item => (
              <PaymentCard item={item} />
            ))}
          </div>
          <div
            className="position-fixed p-3 d-md-none"
            style={{ bottom: 0, right: 0, left: 0 }}
          >
            <Button
              fullWidth
              disableElevation
              variant="contained"
              color="primary"
              disabled={paymentMethods && paymentMethods.length >= 5}
              onClick={() => history.push(Path.PAYMENTS_ADD)}
            >
              {language === "English" ? "Add Account" : "Magdagdag ng Account"}
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{ padding: isMobile ? "9em 9vw" : "9em 20vw" }}
          className="text-center"
        >
          <div style={{ fontSize: 16, color: "#54575f" }}>
            {language === "English"
              ? "Add details of your Bank, PayMaya or PayPal account where your customers can pay. This will be displayed in your online shop."
              : "Ilagay ang detalye ng iyong Bangko, PayMaya o Paypal account kung saan pwedeng magbayad ang iyong customers. Makikita ito sa payment instructions page kapag may bumili sa iyong shop."}
          </div>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            style={{ height: 40 }}
            className="mt-4 px-5"
            disabled={paymentMethods && paymentMethods.length >= 5}
            onClick={() => history.push(Path.PAYMENTS_ADD)}
          >
            {language === "English" ? "Add Account" : "Magdagdag ng Account"}
          </Button>
        </div>
      )}
    </div>
  );
}
