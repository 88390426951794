import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../../layout"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from '../../../../store/history'
import { OrderDetailsCard, AddressFormCard } from '../../components/shipping'
import { animateScroll as scroll } from "react-scroll";
import Actions from '../../Redux/Actions'
import qs from 'query-string';
import DashboardActions from '../../../dashboard/dashboardActions';

const useStyles = theme => ({
  title: {
    fontWeight: "bold",
    fontSize: 36,
    color: "#002C42"
  },
  paper: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
  },
  backHeader: {
    width: 120,
    display: "flex !important",
    alignItems: "center",
    padding: "0 0 2em",
    fontWeight: 600,
    fontSize: 14,
    color: "#F97663 !important"
  },
});

class Shipping extends React.Component {

  componentDidMount = async () => {
    scroll.scrollTo(0)
    if (this.props.cart.items.length) {
      await this.props.dispatch(Actions.GET_SUPPLIER({
        supplier_id: this.props.cart.items[0].supplier_id
      }))
      await this.props.dispatch(Actions.getStores())
    }
  }

  generateCompanyCode = (s="Test Company 1") => {
    let name = Array.from(s.replace(" ", "_")).reduce((a, b) => {
      if (!(['a', 'e', 'e', 'o', 'u'].includes(b.toLowerCase()))) {
        return a + b
      }
      return a
    }, "")
    return name;
  }

  checkout = async (payload) => {
    const customerDetails = {
      city: payload.city,
      company_name: "ProxteraBuyer",
      country: "PH",
      email: payload.email,
      fax: "",
      name: `${payload.first_name} ${payload.last_name}`,
      office_location: "",
      phone: payload.mobile,
      mobile: payload.mobile,
      postal_code: payload.zip_code,
      state: payload.province,
      street: payload.street,
      address: `${payload.street}, ${payload.city}, ${payload.province}, ${payload.country} ${payload.zip_code}`,
      first_name: payload.first_name,
      last_name: payload.last_name
    }
    const { dispatch, cart, supplier, stores } = this.props;

    await dispatch(Actions.REGISTER_SME({
      store_uuid: stores[0].uuid
    }))
    dispatch(Actions.CREATE_ORDER({
      attachment: "",
      company_code: this.generateCompanyCode(supplier.company_name),
      logistics_terms: "",
      order_name: this.generateCompanyCode(supplier.company_name),
      payment_terms: "",
      purchasing_unit: this.generateCompanyCode(supplier.company_name),
      terms_and_conditions: "",
      buyer_order_id: "",
      cost_center:"",
      gl_account: "",
      gl_description: "",
      billing_address: customerDetails,
      shipping_address: customerDetails,
      supplier_address: {
        ...supplier,
        email: supplier.orders_email,
        name: supplier.company_name,
        office_location: `${supplier.address1}, ${supplier.addres2}`,
        street: supplier.address1
      },
      total: { total: cart.total, amount: cart.total, currency: "USD" },
      items: cart.items,
      timestamp: new Date(),
      needed_by: new Date(),
      comments: payload.notes,
      supplier_id: cart.items[0].supplier_id,
      supplier_tax_id: supplier.tax_id,
      // supplier_id: '49c77007-a98a-43ca-bcd3-0c42b5fd6fbb',
      order_type: "Buyer"
    }, (order_id) => {
      history.push({
        pathname: "/proxtera/checkout",
        search: qs.stringify({
          order_id
        })
      })
    }))
  }

  render (){
    const { classes, cart } = this.props;
    // const items = [
    //   {
    //     image: 'https://cdn.cichic.com/media/catalog/product/cache/1/image/5e06319eda06f020e43594a9c230972d/1/9/190104100971-1/white-patchwork-lace-pleated-v-neck-long-sleeve-ctue-boho-mini-dress.jpg',
    //     name: 'Premium Mt. Apo Arabica  Coffee Beans (1 kg.)',
    //     price: 7.50,
    //     unit: '1 kg',
    //     quantity: 1

    //   },
    //   {
    //     image: 'https://images.immediate.co.uk/production/volatile/sites/30/2020/08/health-benefits-of-almonds-hub-image-square-dcac827.jpg?quality=90&resize=504,458?quality=90&webp=true&resize=504,459',
    //     name: 'High quality Almonds',
    //     price: 7.50,
    //     unit: '1 kg',
    //     quantity: 1
    //   }
    // ];
    return (
      <Layout noSideBar>
        <a className={classes.backHeader} href="#" onClick={(e) => {
            e.preventDefault();
            history.goBack();
        }}>
          <ArrowBackIcon/>
          <span style={{marginLeft: "1em"}}>{`  BACK`}</span>
        </a>
        <h2 className={`mb-4 ${classes.title}`}>Checkout</h2>
        <div className="row">
          <div className="col-12 col-lg-4">
            <OrderDetailsCard items={cart.items} qty={cart.qty} subtotal={(parseInt(cart.total)/100).toFixed(2)} total={(parseInt(cart.total)/100).toFixed(2)}/>
            <p className="mt-3">*Exchange rates will be based on IDK</p>
          </div>
          <div className="col-12 col-lg-8">
            <AddressFormCard onClick={this.checkout}/>
          </div>
        </div>
      </Layout>
    )
  }
}


const mapStateToProps = state => ({ proxtera, dashboard }) => ({ 
    cart: proxtera.cart,
    supplier: proxtera.supplier,
    stores: proxtera.stores
});
export default connect(mapStateToProps)(withStyles(useStyles)(Shipping));

