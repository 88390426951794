import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  useMediaQuery,
  Container
} from "@material-ui/core";
import {
  ArrowBack,
  Share,
  ShareOutlined,
  ShoppingCartOutlined
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import ShareDrawer from "../header/shareDrawer";
export default function Header(props) {
  const useStyles = makeStyles(theme => ({
    appbar: {
      boxShadow: "none",
      borderBottom: "1px solid #d1d5dd"
    },
    label: {
      fontSize: 18,
      fontWeight: 600,
      color: "#2b2d32"
    },
    customBadge: {
      backgroundColor: "#c24a39",
      color: "#fff"
    }
  }));
  const theme = useTheme();
  const store = useSelector(state => state.store.store);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const cart = useSelector(state => state.quickOrder.cart.items);
  const [open, setOpen] = React.useState(false);
  const share_and_cart_btns =
    location.pathname.includes("/delivery") ||
    location.pathname.includes("/customer_details") ||
    location.pathname.includes("/payment");

  const header_title = () => {
    if (location.pathname.includes("/cart")) {
      return "2. Review cart";
    } else if (location.pathname.includes("/delivery")) {
      return "3. Choose delivery";
    } else if (location.pathname.includes("/customer_details")) {
      return "4. Enter details";
    } else if (location.pathname.includes("/payment")) {
      return "5. Choose payment";
    } else {
      return "1. Select order";
    }
  };

  function item_count() {
    return parseFloat(
      cart.reduce((total, object) => {
        return object.has_variants
          ? parseFloat(total) +
              (object.always_available
                ? parseFloat(object.quantity)
                : object.quantity > 0
                ? parseFloat(object.quantity)
                : 0)
          : parseFloat(total) +
              (object.always_available
                ? parseFloat(object.quantity)
                : object.stock > 0
                ? parseFloat(object.quantity)
                : 0);
      }, 0)
    );
  }

  const content = (
    <>
      <Toolbar id="content" className="p-0 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <IconButton id="back_btn" onClick={() => history.goBack()}>
            <ArrowBack color="primary" />
          </IconButton>
          <div className={classes.label}>{header_title()}</div>
        </div>
        {!share_and_cart_btns ? (
          <div className="d-flex align-items-center">
            <IconButton onClick={() => setOpen(true)}>
              <ShareOutlined style={{ color: "#2b2d32" }} />
            </IconButton>
            <IconButton
              id="cart_btn"
              onClick={() => history.push(`/order/${store.site}/cart`)}
            >
              <Badge
                badgeContent={item_count()}
                classes={{ badge: classes.customBadge }}
              >
                <ShoppingCartOutlined
                  id="shopping-cart"
                  style={{ color: "#2b2d32" }}
                />
              </Badge>
            </IconButton>
          </div>
        ) : (
          ""
        )}
      </Toolbar>
      <ShareDrawer open={open} setOpen={setOpen} />
    </>
  );
  return isMobile ? (
    <AppBar position="fixed" className={`bg-white p-2 ${classes.appbar}`}>
      {content}
    </AppBar>
  ) : (
    <AppBar position="fixed" className={`bg-white p-2 ${classes.appbar}`}>
      <Container maxWidth="md" style={{ padding: "0 6vw" }}>
        {content}
      </Container>
    </AppBar>
  );
}
