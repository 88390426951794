import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Tabs,
  Tab,
  Link,
  useMediaQuery,
  useTheme,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Dialog
} from "@material-ui/core";
import BuxPane from "./components/bux/buxPane";
import { getBuxStatus, getCODWallet } from "./action";
import Path from "../../Paths";
import history from "../../store/history";
import OtherAccountForm from "./otherAccountForm";
import OtherAccountsPane from "./components/otherAccount/otherAccountsPane";
import { useLocation } from "react-router";
import { Close } from "@material-ui/icons";
import { destroy } from "redux-form";
const Transition = props => <Slide direction="up" {...props} />;
export default function PaymentsPage(props) {
  const useStyles = makeStyles(theme => ({
    form: {
      backgroundColor: "#fff",
      border: "1px solid #d1d5dd",
      borderRadius: 4
    }
  }));
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const language = localStorage.getItem("language");
  const user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    async function fetchData() {
      let res = await dispatch(getBuxStatus(user.id));
      await dispatch(getCODWallet());
      try {
        if (res.status === 200) {
          console.log(res);
        }
      } catch (e) {
        history.push(Path.DASHBOARD);
      }
    }
    fetchData();
    dispatch(destroy("account_form"));
  }, []);

  // React.useEffect(() => {
  //   if (location.pathname.includes("/other_account")) {
  //     setValue(1);
  //   }
  // }, [location]);
  return (
    <div>
      {isMobile ? (
        <div>
          <Dialog
            open={location.pathname.includes("/payments")}
            fullScreen
            TransitionComponent={Transition}
          >
            <AppBar
              className="position-relative bg-white border-bottom"
              style={{ boxShadow: "none" }}
            >
              <Toolbar>
                <div
                  className="text-center w-100"
                  style={{ fontSize: 16, fontWeight: 600, color: "#002c42" }}
                >
                  Payments
                </div>
                <IconButton
                  edge="end"
                  color="inherit"
                  className="position-absolute"
                  style={{ right: 16 }}
                  onClick={() => history.push(Path.MOBILE_WEB_BUILDER)}
                >
                  <Close color="primary" />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              color="primary"
              className="border-bottom"
              variant="fullWidth"
            >
              {["Bux", "Other accounts"].map((item, key) => (
                <Tab
                  style={{ color: value === key ? "#f97663" : "#54575f" }}
                  label={item}
                />
              ))}
            </Tabs>
            <div className={`${value === 0 ? "d-block" : "d-none"}`}>
              <div className="bux-pane">
                <BuxPane />
              </div>
            </div>
            <div className={`${value === 1 ? "d-block" : "d-none"}`}>
              <div className="other-accounts-pane">
                <OtherAccountsPane />
              </div>
            </div>
          </Dialog>
        </div>
      ) : (
        <div>
          <strong style={{ fontSize: 28, color: "#002c42" }}>Payments</strong>
          <div className={`${classes.form} mt-4`}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              color="primary"
              className="border-bottom"
            >
              {["Bux", "Other accounts"].map((item, key) => (
                <Tab
                  style={{ color: value === key ? "#f97663" : "#54575f" }}
                  label={item}
                />
              ))}
            </Tabs>
            <div className={`${value === 0 ? "d-block" : "d-none"}`}>
              <div className="bux-pane">
                <BuxPane />
              </div>
            </div>
            <div className={`${value === 1 ? "d-block" : "d-none"}`}>
              <div className="other-accounts-pane">
                <OtherAccountsPane />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
