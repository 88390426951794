import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const textStyles = makeStyles(theme => ({
  h1: {
    fontSize: 48,
    fontWeight: "bold"
  },
  h2: {
    fontSize: 36,
    fontWeight: "bold"
  },
  h3: {
    color: "#002C42",
    fontSize: 28,
    fontWeight: "bold"
  },

  sh2: {
    fontSize: 24,
    color: "#2B2D33",
    fontWeight: "300"
  },
  sh3: {
    fontSize: 20
  },
  sh5: {
    fontSize: 18
  },
  sh4: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#2B2D33"
  },
  p1: {
    fontSize: 16,
    color: "#54575F"
  },
  p1_Semibold: {
    fontSize: 16,
    color: "#2B2D33",
    fontWeight: "bold"
  },
  p2: {
    fontSize: 14,
    color: "#54575F"
  },
  p2_Semibold: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2B2D33"
  },
  p3_Semibold: {
    fontSize: 14,
    fontWeight: 500,
    color: "#54575f"
  },
  p3_Bold: {
    fontSize: 14,
    fontWeight: 600,
    color: "#54575f"
  },
  cap: {
    color: "#909196",
    fontSize: 12,
    marginTop: "2vh"
  },
  cap2: {
    color: "#909196",
    fontSize: 12
  },
  label1: {
    color: "#54575F",
    fontSize: 12
  },
  label2: {
    color: "#54575F",
    fontSize: 10
  },

  contentH2: {
    fontSize: 36,
    fontWeight: "bold",
    textAlign: "center",
    color: "#002C42",
    [theme.breakpoints.down("sm")]: {
      fontSize: 26
    }
  },
  contentH3: {
    color: "#002C42",
    fontSize: 28,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 26
    }
  },

  contentSh2: {
    fontSize: 24,
    color: "#2B2D33",
    fontWeight: "300",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      marginTop: "2vh",
      padding: "0px 2px"
    }
  },
  contentSh3: {
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      margin: "3vh 0px"
    }
  },
  contentH1: {
    fontSize: 48,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 26
    }
  },
  collab: {
    paddingTop: "13vh",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0
    }
  },

  lastContent: {
    color: "#F97663",
    textAlign: "center",
    paddingTop: "20vh",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0
    }
  }
}));

const Text = props => {
  const classes = textStyles();
  const { textType, ...rest } = props;
  return (
    <div {...rest} className={classes[props.textType]} style={props.style}>
      {props.content}
    </div>
  );
};
export default Text;
