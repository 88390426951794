import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";
import { ExpandMore } from "@material-ui/icons";
import checkedIcon from "../../../../../assets/images/checked.svg";
import SentroLoading from "../../../loading";
import { animateScroll as scroll } from "react-scroll";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import fbPixel from "../../../../../assets/images/pixel.png";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  OutlinedInput,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import {
  saveFBPixelID,
  getStoreProducts
} from "../webBuilder/websiteBuilderActions";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = theme => ({
  root: {
    padding: "2em",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  fbMessengerIcon: {
    color: "#0D3D76",
    fontSize: 100
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  content: {
    padding: "0 1.5em",
    "& > strong": {
      color: "#002C42"
    },
    "& > p": {
      marginTop: 10
    }
  }
});

class Card extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <img src={fbPixel} style={{ width: 60, height: "auto" }} alt="" />
        <div className={classes.content}>
          <strong>Track visitor activity on your Sentro shop</strong>
          <p>
            Link your shop’s FB Messenger account so your clients can
            immediately chat with you while browsing your Sentro shop.{" "}
          </p>
        </div>
        <Button
          className={classes.button}
          id="view"
          color="primary"
          disableElevation
          variant="outlined"
          onClick={() => {
            // const link =  data.variants ? `/marketplace/${data.id}/packages` : `marketplace/${data.id}/checkout`
            history.push(Path.FB_PIXEL_SETUP);
          }}
        >
          Learn More
        </Button>
      </div>
    );
  }
}
const FBPixelCard = withStyles(useStyles)(Card);
export { FBPixelCard };

const useStylesSetup = theme => ({
  header: {
    padding: 20,
    marginTop: 68
  },
  backDivWrapper: {
    display: "flex",
    "& > span": {
      marginLeft: "1em",
      fontWeight: 600
    },
    color: "#F97663 !important",
    alignItems: "center",
    cursor: "pointer",
    width: 100
  },
  headerContent: {
    display: "flex",
    alignItems: "center"
  },
  fbMessengerIcon: {
    color: "#0D3D76",
    margin: "10px 50px",
    fontSize: 85
  },
  headerDetails: {
    paddingRight: 100,
    "& > strong": {
      fontSize: 30,
      color: "#2B2D33"
    },
    "& > p": {
      marginTop: 10
    }
  },
  root: {
    padding: "0em 10em 2em"
  },
  button: {
    marginLeft: "auto",
    minWidth: 120,
    height: 40
  },
  expansionTitle: {
    color: "#002C42",
    fontSize: 12
  },
  expansionForm: {
    background: "#F1F1F1",
    display: "block",
    "& .expansionForm": {
      display: "flex",
      alignItems: "center"
    },
    "& img": {
      margin: "1em 0"
    }
  },
  inputFieldButton: {
    height: 40
  },
  inputField: {
    width: "100%",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    height: 40,
    backgroundColor: "white"
  },
  howToGetFBPage: {
    margin: "1.5em 0"
  },
  step2Details: {
    backgroundColor: "white",
    padding: "1.5em"
  },
  paper: {
    padding: 20,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8
  },
  appbar: {
    position: "fixed",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd"
  },
  closeBtn: { position: "absolute", left: 15 },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  label: {
    color: "#54575f",
    fontSize: 14,
    padding: "0 25px"
  }
});

const DialogTransition = props => <Slide direction="up" {...props} />;
class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section1: true,
      section2: false,
      fbPixelId: "",
      disableField: false,
      alertStat: false,
      alertType: "success",
      openWarning: false,
      blueBox: false
    };
    this.expansionChange = this.expansionChange.bind(this);
    this.cardOnClick = this.cardOnClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.unlink = this.unlink.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.buttonSubmit = this.buttonSubmit.bind(this);
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    const stores = await dispatch(getStoreProducts());
    if (stores[0]) {
      this.setState({
        fbPixelId: stores[0].fb_pixel_id
      });
      if (stores[0].fb_pixel_id) {
        this.setState({
          blueBox: true,
          disableField: true
        });
      }
    }
    scroll.scrollTo(0);
  };

  expansionChange(section, stat) {
    this.setState({
      [section]: stat
    });
  }

  cardOnClick() {
    this.setState({
      section1: true,
      section2: true
    });
  }

  onSubmit = async () => {
    const { dispatch, stores } = this.props;
    await dispatch(saveFBPixelID(stores[0].site, this.state.fbPixelId));
    this.setState({
      alertStat: true,
      alertType: "success",
      disableField: true,
      blueBox: true
    });
    scroll.scrollTo(0);
  };

  unlink = async () => {
    const { dispatch, stores } = this.props;
    await dispatch(saveFBPixelID(stores[0].site, ""));
    this.setState({
      fbPixelId: "",
      alertStat: true,
      alertType: "warning",
      disableField: false,
      openWarning: false,
      blueBox: false
    });
    scroll.scrollTo(0);
  };

  handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      alertStat: false
    });
  };

  buttonSubmit = () => {
    const { stores, classes } = this.props;
    return (
      <div style={{ paddingTop: "3vh" }}>
        <Button
          className={classes.inputFieldButton}
          color="primary"
          disableElevation
          variant="contained"
          disabled={!this.state.fbPixelId || !stores.length}
          onClick={this.onSubmit}
          fullWidth
        >
          Submit
        </Button>
      </div>
    );
  };

  render() {
    const {
      classes,
      loading,
      stores,
      open,
      setOpen,
      DialogTransition
    } = this.props;
    return (
      <div>
        <Dialog
          fullScreen
          open={open.fb_pixel}
          TransitionComponent={DialogTransition}
        >
          <Snackbar
            style={{ top: 70 }}
            open={this.state.alertStat && this.state.alertType === "success"}
            autoHideDuration={2000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={this.handleCloseAlert}
              severity="success"
              icon={<InfoOutlinedIcon fontSize="inherit" />}
            >
              Your Facebook Pixel ID is now linked to your Sentro shop. Visit
              your shop to check it out.
            </Alert>
          </Snackbar>
          <Snackbar
            open={this.state.alertStat && this.state.alertType === "warning"}
            autoHideDuration={2000}
            onClose={this.handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={this.handleCloseAlert}
              severity="warning"
              icon={<InfoOutlinedIcon fontSize="inherit" />}
            >
              You have unlinked a Facebook Pixel ID from your Sentro shop.
            </Alert>
          </Snackbar>
          <SentroLoading open={loading} />
          <AppBar className={classes.appbar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                className={classes.closeBtn}
                onClick={() => setOpen({ ...open, fb_pixel: false })}
              >
                <CloseIcon color="primary" />
              </IconButton>
              <div className={classes.header}>FB Pixel</div>
            </Toolbar>
          </AppBar>
          <div
            style={{
              backgroundColor: "#1e4b83",
              color: "#fff",
              padding: 20,
              marginTop: "9vh",
              display: this.state.blueBox ? "" : "none"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <img
                src={checkedIcon}
                style={{ height: "auto", width: 50 }}
                alt=""
              />
              <div style={{ fontSize: 12, paddingLeft: 10 }}>
                You have currently linked a Facebook Pixel to your Sentro Shop
              </div>
              <IconButton
                style={{ display: "flex", bottom: 10 }}
                onClick={() => this.setState({ ...this.state, blueBox: false })}
              >
                <CloseIcon style={{ color: "#fff" }} />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              padding: 20,
              borderBottom: "1px solid #d1d5dd",
              marginTop: this.state.blueBox ? 0 : "10vh"
            }}
          >
            <div
              style={{
                fontSize: 14,
                color: "#54575f",
                display: this.state.blueBox ? "none" : ""
              }}
            >
              If you are placing ads on Facebook for your Sentro shop, Facebook
              Pixel will help you track its conversion, optimize it, build
              targeted audiences for future ads, and remarket to people who have
              already taken some kind of action on your website.
            </div>
            <div style={{ display: "block", paddingTop: "2vh" }}>
              <div style={{ fontSize: 14, color: "#54575f" }}>
                Facebook Pixel ID
              </div>
              <div className="expansionForm">
                <OutlinedInput
                  color="secondary"
                  disabled={this.state.disableField || stores.length === 0}
                  className={classes.inputField}
                  value={this.state.fbPixelId}
                  type="number"
                  onChange={event => {
                    this.setState({
                      fbPixelId: event.target.value
                    });
                  }}
                />
              </div>
              {stores.length ? (
                stores[0] ? (
                  stores[0].fb_pixel_id ? (
                    this.state.disableField ? (
                      <div
                        style={{
                          paddingTop: "3vh",
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <Button
                          onClick={() =>
                            this.setState({ ...this.state, openWarning: true })
                          }
                          style={{ width: "45%", height: 40 }}
                          variant="text"
                        >
                          Unlink
                        </Button>
                        <Button
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              disableField: false
                            })
                          }
                          style={{ width: "45%", height: 40 }}
                          variant="outlined"
                          color="primary"
                        >
                          Edit
                        </Button>
                      </div>
                    ) : (
                      this.buttonSubmit()
                    )
                  ) : (
                    this.buttonSubmit()
                  )
                ) : (
                  this.buttonSubmit()
                )
              ) : (
                this.buttonSubmit()
              )}
            </div>
          </div>
          <ExpansionPanel
            style={{ boxShadown: "none", marginTop: 0, marginBottom: 0 }}
            expanded={this.state.section1}
            onChange={(obj, stat) => this.expansionChange("section1", stat)}
          >
            <ExpansionPanelSummary
              style={{
                padding: "0 20px",
                borderTop: "1px solid #002c42",
                borderBottom: "1px solid #002c42"
              }}
              expandIcon={<ExpandMore />}
            >
              <strong className={classes.expansionTitle}>
                STEP 1: LINK YOUR FB PIXEL ID TO YOUR SHOP
              </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ borderBottom: "1px solid #d1d5dd" }}
            >
              <div style={{ padding: "10px 0" }}>
                <div style={{ marginBottom: "3vh" }}>
                  <div className={classes.label}>
                    1. Go to your FB page. Under the “Manage Page” section,
                    select “Ad Center”. Once Ad Center page loads, click “Visit
                    Ads Manager.” It will redirect you to your FB Ads Manager
                    account.
                  </div>
                  <img
                    src="https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/FB_Pixel_1+%281%29.gif"
                    style={{ width: "100%", paddingTop: "2vh" }}
                    alt=""
                  />
                </div>
                <div style={{ marginBottom: "3vh" }}>
                  <div className={classes.label}>
                    2. In your FB Ads Manager page, select the Ad account to
                    manage. After it loads, click the Business tools icon then
                    select the Events Manager shortcut
                  </div>
                  <img
                    src="https://sentro-instructions.s3-ap-southeast-1.amazonaws.com/FB_Pixel_2+%281%29.gif"
                    style={{ width: "100%", paddingTop: "2vh" }}
                    alt=""
                  />
                </div>
                <div style={{ marginBottom: "3vh" }}>
                  <div className={classes.label}>
                    3. Create a new data source. When prompted, select Web as
                    the source of data and Facebook Pixel as connection method.
                    Then indicate a name for your Facebook Pixel.
                  </div>
                  <img
                    src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_3.gif"
                    style={{ width: "100%", paddingTop: "2vh" }}
                    alt=""
                  />
                </div>
                <div style={{ marginBottom: "3vh" }}>
                  <div className={classes.label}>
                    4. Close the next pop-up window of the setup. Copy the ID of
                    the Facebook Pixel you just created.
                  </div>
                  <img
                    src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_4.gif"
                    style={{ width: "100%", paddingTop: "2vh" }}
                    alt=""
                  />
                </div>
                <div style={{ marginBottom: "3vh" }}>
                  <div className={classes.label}>
                    5. Login to your Sentro account and paste your Facebook
                    Pixel ID on the text field above.
                  </div>
                  <img
                    src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_5.gif"
                    style={{ width: "100%", paddingTop: "3vh" }}
                    alt=""
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            style={{ boxShadown: "none", marginTop: 0, marginBottom: 0 }}
            expanded={this.state.section2}
            onChange={(obj, stat) => this.expansionChange("section2", stat)}
          >
            <ExpansionPanelSummary
              style={{
                padding: "0 20px",
                borderTop: "1px solid #002c42",
                borderBottom: "1px solid #002c42"
              }}
              expandIcon={<ExpandMore />}
            >
              <strong className={classes.expansionTitle}>
                STEP 2: CONNECT YOUR SHOP TO YOUR FB PIXEL
              </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ borderBottom: "1px solid #d1d5dd" }}
            >
              <div style={{ padding: "10px 0" }}>
                <div style={{ marginBottom: "3vh" }}>
                  <div className={classes.label}>
                    1. Go back to your Facebook Ad Manager and refresh the page.
                    If linking to Sentro Shop is successful, the page should
                    look like the one below,
                  </div>
                  <img
                    src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_6.gif"
                    style={{ width: "100%", paddingTop: "2vh" }}
                    alt=""
                  />
                </div>
                <div style={{ marginBottom: "3vh" }}>
                  <div className={classes.label}>
                    2. Click Add Events, then select From the Pixel. In the
                    pop-up window that will appear, click the Open Event Setup
                    Tool button. In the next window, type your Sentro Shop link
                    and click Open Website. It will redirect you to your Sentro
                    Shop.
                  </div>
                  <img
                    src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_7.gif"
                    style={{ width: "100%", paddingTop: "2vh" }}
                    alt=""
                  />
                </div>
                <div style={{ marginBottom: "3vh" }}>
                  <div className={classes.label}>
                    3. When your Sentro shop is opened, a Setup Event Tool will
                    be shown on the top left. Click which parts of your online
                    shop you want to track (i.e. Track New Button or Track a
                    URL) and identify the type of event or behavior you want to
                    associate to that part. Once done, click Finish Setup, and
                    you will be returned to your FB Ad Manager account.
                  </div>
                  <img
                    src="https://www-sentro-ph.s3-ap-southeast-1.amazonaws.com/FB_Pixel_8.gif"
                    style={{ width: "100%", paddingTop: "2vh" }}
                    alt=""
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <Dialog fullWidth maxWidth="md" open={this.state.openWarning}>
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 0
              }}
            >
              <IconButton
                onClick={() =>
                  this.setState({ ...this.state, openWarning: false })
                }
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                  width: "fit-content"
                }}
              >
                <ErrorIcon
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "#ee2545",
                    fontSize: 100
                  }}
                />
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{
                      padding: "10px 0",
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#002c42"
                    }}
                  >
                    Are you sure you want to unlink your Facebook Pixel ID?
                  </div>
                </div>
                <div style={{ paddingTop: "5vh" }}>
                  <Button
                    onClick={() => this.unlink()}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disableElevation
                  >
                    Yes, please unlink
                  </Button>
                  <Button
                    onClick={() =>
                      this.setState({ ...this.state, openWarning: false })
                    }
                    variant="text"
                    color="primary"
                    fullWidth
                  >
                    No, take me back
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({ mobileDashboard }) => ({
  loading: mobileDashboard.loading,
  stores: mobileDashboard.stores,
  DialogTransition: DialogTransition
});
const MobileFBPixel = connect(mapStateToProps)(
  withStyles(useStylesSetup)(Setup)
);
export default MobileFBPixel;
