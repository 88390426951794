import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Button,
  useMediaQuery,
} from "@material-ui/core";

import Blank from "../blank";
import ProductCard from "../ProductCard";
import { PromoBanner } from "../PromoBanner12.12";
import Actions from "../StoreActions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SortBy from "../filters";
import CategoriesTab, { SortDiv } from "./categoriesTab";
import SortSection from "./sortSection";
import setDefaultImage from "../../../layout/mobile/webBuilder/common/setDefaultImage";
import { useTheme } from "@material-ui/styles";
import { ExpandMore } from "@material-ui/icons";
import ProductPagination from "./pagination";

const ProductTemplate = (props) => {
  const { mobileView } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let storeInfo = props.store;
  let color = props.store && props.store.color;

  const [sortOpen, setSortOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({
    sortBy: "-created_on",
    category: "",
  });
  const [openShowMore, setOpenShowMore] = React.useState(false);

  // STYLES ==============
  // styles placed here instead in setProductTemplate(); AVOID putting hooks(makeStyles) in a conditional block(eg: switch case)
  const getStyles = (template, theme) => {
    switch (template) {
      case "Classic":
        return {
          root: {
            // paddingTop: 80,
            minHeight: "90vh",
            [theme.breakpoints.down("md")]: {
              minHeight: 0,
            },
            [theme.breakpoints.down("sm")]: {
              minHeight: 0,
            },
            [theme.breakpoints.down("xs")]: {
              minHeight: 0,
            },
          },
          content1: {
            height: "100vh",
            width: "100%",
          },
          productCard: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            },
          },
          infoText: {
            fontSize: 14,
            color: "#4B4948",
          },
          sticktop: {
            transition: "all 0.5s linear",
            position: "sticky",
            top: 56,
            left: 0,
            right: 0,
            zIndex: 99,
          },
        };
      case "Contemporary":
        return {
          root: {
            // paddingTop: 80,
            minHeight: "90vh",
            [theme.breakpoints.down("md")]: {
              minHeight: 0,
            },
            [theme.breakpoints.down("sm")]: {
              minHeight: 0,
            },
            [theme.breakpoints.down("xs")]: {
              minHeight: 0,
            },
          },
          content1: {
            height: "100vh",
            width: "100%",
          },
          productCard: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            },
          },
          infoText: {
            fontSize: 14,
            color: "#4B4948",
          },
          sticktop: {
            transition: "all 0.5s linear",
            position: "sticky",
            top: 56,
            left: 0,
            right: 0,
            zIndex: 99,
          },
        };
      case "Minimalist":
        return {
          root: {
            // paddingTop: 80,
            minHeight: "90vh",
            [theme.breakpoints.down("md")]: {
              minHeight: 0,
            },
            [theme.breakpoints.down("sm")]: {
              minHeight: 0,
            },
            [theme.breakpoints.down("xs")]: {
              minHeight: 0,
            },
          },
          content1: {
            height: "100vh",
            width: "100%",
          },
          productCard: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              justifyContent: "center",
            },
          },
          infoText: {
            fontSize: 14,
            color: "#4B4948",
          },
          sticktop: {
            transition: "all 0.5s linear",
            position: "sticky",
            top: 56,
            left: 0,
            right: 0,
            zIndex: 99,
          },
        };
      case "ClassicV2":
        return {
          root: {
            width: "100%",
            height: "auto",
            backgroundColor: "#fff",
          },
          banner: {
            marginTop: "6vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "65vh",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundImage: `url(${
              props.store.contactus_image
                ? props.store.contactus_image
                : setDefaultImage(
                    props.store && props.store.template,
                    "cover_image"
                  )
            })`,
            [theme.breakpoints.down("sm")]: {
              height: 180,
            },
          },
          header: {
            fontFamily: storeInfo && storeInfo.font.family,
            color: color.primary.contrastText,
            fontSize: 48,
            fontWeight: "bold",
            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
              fontSize: 28,
            },
          },
          subheader: {
            fontSize: 18,
            marginTop: "2vh",
            color: color.primary.contrastText,
            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          },
          products: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            padding: "0 2vw 4vh",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              padding: "0 2em 1em",
              width: "100%",
            },
          },
          content1: {
            height: "100vh",
            width: "100%",
          },
          productCard: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            },
          },
          infoText: {
            fontSize: 14,
            color: "#4B4948",
          },
          sticktop: {
            transition: "all 0.5s linear",
            position: "sticky",
            top: 56,
            left: 0,
            right: 0,
            zIndex: 99,
          },
          showmore: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
              cursor: "pointer",
              display: openShowMore ? "none" : "block",
            },
          },
        };
      case "ContemporaryV2":
        return {
          root: {
            width: "100%",
            height: "auto",
            backgroundColor: "#fff",
          },
          banner: {
            marginTop: "10vh",
            padding: "0 4vw",
            backgroundColor: color.primary.contrastText,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "16em",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
              height: 150,
            },
          },
          header: {
            fontFamily: storeInfo && storeInfo.font.family,
            color: "#fff",
            fontSize: 48,
            fontWeight: "bold",
            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
            },
          },
          subheader: {
            fontSize: 28,
            marginTop: "2vh",
            color: "#fff",

            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          },
          subheader2: {
            display: "none",
            fontSize: 28,
            marginTop: "2vh",
            color: "#fff",

            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              justifyContent: "center",
            },
          },
          products: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            padding: "0 5vw 4vh",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              padding: "0 2em 1em",
              width: "100%",
            },
          },
          content1: {
            height: "100vh",
            width: "100%",
          },
          productCard: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            },
          },
          infoText: {
            fontSize: 14,
            color: "#4B4948",
          },
          sticktop: {
            transition: "all 0.5s linear",
            position: "sticky",
            top: 56,
            left: 0,
            right: 0,
            zIndex: 99,
          },
          showmore: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
              cursor: "pointer",
              display: openShowMore ? "none" : "block",
            },
          },
        };
      case "Leftist":
        return {
          root: {
            width: "100%",
            height: "auto",
            backgroundColor: "#fff",
          },
          banner: {
            padding: "0 12vw",
            backgroundColor: color.primary.contrastText,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "16em",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
              height: 150,
            },
          },
          header: {
            fontFamily: storeInfo && storeInfo.font.family,
            color: "#fff",
            fontSize: 48,
            fontWeight: "bold",
            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
            },
          },
          subheader: {
            fontSize: 28,
            marginTop: "2vh",
            color: "#fff",

            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          },
          subheader2: {
            display: "none",
            fontSize: 28,
            marginTop: "2vh",
            color: "#fff",

            width: "100%",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              justifyContent: "center",
            },
          },
          products: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            padding: "0 5vw 4vh",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              padding: "0 2em 1em",
              width: "100%",
            },
          },
          content1: {
            height: "100vh",
            width: "100%",
          },
          productCard: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              margin: "0",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            },
          },
          infoText: {
            fontSize: 14,
            color: "#4B4948",
          },
          sticktop: {
            transition: "all 0.5s linear",
            position: "sticky",
            top: 56,
            left: 0,
            right: 0,
            zIndex: 99,
          },
          showmore: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
              cursor: "pointer",
              display: openShowMore ? "none" : "block",
            },
          },
        };
      case "SariSari":
        return {
          header: {
            fontSize: 38,
            fontWeight: "bold",
            fontFamily: storeInfo && storeInfo.font.family,
            color: color.primary.contrastText,
          },
          subheader: {
            fontSize: 18,
            color: color.primary.contrastText,
          },
        };
      case "Pastel":
        return {
          header: {
            fontSize: 38,
            fontWeight: "bold",
            fontFamily: storeInfo && storeInfo.font.family,
            color: color.primary.contrastText,
          },
          subheader: {
            fontSize: 18,
            color: color.primary.contrastText,
          },
        };
      default:
        return {};
    }
  };
  let templateStyles = makeStyles((theme) =>
    getStyles(props.store ? props.store.template : "Blank", theme)
  );
  const classes = templateStyles();

  console.log(props.productList);
  // FILTERS =============
  const location = useLocation();
  const categoriesOnChange = (category) => {
    setFilters((filters) => ({
      ...filters,
      category,
    }));
  };

  const sortOnChange = (sortBy) => {
    setFilters((filters) => ({
      ...filters,
      sortBy,
    }));
  };

  const [page, setPage] = React.useState(1);
  const count = useSelector((state) => state.store.product_count);
  const category_filter = new URLSearchParams(location.search).get("category");
  const sortBy_filter = new URLSearchParams(location.search).get("sortBy");

  const onChangePage = (e, page) => {
    // scroll.scrollTo(0);
    setPage(page);
  };

  React.useEffect(() => {
    if (props.store) {
      categoriesOnChange(category_filter);
      sortOnChange(sortBy_filter);
      dispatch(
        Actions.getAllProducts(
          isMobile ? true : false,
          props.store.uuid,
          isMobile
            ? {
                status: "active",
                sortBy: sortBy_filter,
                category: category_filter
                  ? JSON.stringify([category_filter])
                  : JSON.stringify(["All"]),
              }
            : {
                itemsPerPage: 12,
                page: page,
                status: "active",
                sortBy: sortBy_filter,
                category: category_filter
                  ? JSON.stringify([category_filter])
                  : JSON.stringify(["All"]),
              }
        )
      );
    }
  }, [props.store, category_filter, sortBy_filter, page, isMobile]);

  React.useEffect(() => {
    history.replace({
      pathname: location.pathname,
      search: `?category=${encodeURIComponent(
        category_filter || filters.category
      )}&sortBy=${encodeURIComponent(sortBy_filter || filters.sortBy)}`,
    });
  }, []);

  let loading = useSelector((state) => state.store.loading);

  const Loading = (props) => (
    <div
      class="text-center my-2"
      style={{
        maxHeight: props.open ? 300 : 0,
        overflow: "hidden",
        transition: "all 0.5s linear",
      }}
    >
      <div className="spinner-border spinner-border-sm text-secondary">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );

  const setProductTemplate = () => {
    switch (props.store ? props.store.template : "Blank") {
      case "Blank":
        return <Blank />;
      case "Classic":
        return (
          <Box className={classes.root}>
            <Box className={classes.products}>
              <Box style={{ padding: "80px 0" }}>
                <Typography
                  style={{
                    fontSize: 36,
                    textAlign: "center",
                    color: "#2b2d32",
                    fontFamily: storeInfo && storeInfo.font.family,
                  }}
                >
                  PRODUCTS
                </Typography>

                <div className={`${classes.sticktop} d-block d-sm-none`}>
                  <CategoriesTab
                    onChange={categoriesOnChange}
                    color="#2B2D33"
                    template={props.store ? props.store.template : "Blank"}
                  />
                  <SortDiv
                    classes={classes}
                    product_count={props.productList.length}
                    setOpen={setSortOpen}
                  />
                </div>
                <Loading open={loading} />
                <div id="productsDiv">
                  <Container>
                    <div
                      className="col-12"
                      style={{ padding: mobileView ? 0 : "0 7em" }}
                    >
                      <div style={{ display: mobileView ? "none" : "inherit" }}>
                        <SortSection
                          store={props.store}
                          categoryOnChange={categoriesOnChange}
                          sortOnChange={sortOnChange}
                          filters={filters}
                          setPage={setPage}
                        />
                      </div>
                      <div
                        className={`d-flex justify-content-center flex-wrap`}
                      >
                        {props.productList && props.productList.length >= 0
                          ? props.productList
                              .slice(
                                0,
                                isMobile
                                  ? openShowMore
                                    ? props.productList.length
                                    : 4
                                  : props.productList.length
                              )
                              .map((item, label) => {
                                return (
                                  <div
                                    className={`d-flex justify-content-center  px-0 py-2 ${
                                      mobileView ? "col-6" : "col-4"
                                    }`}
                                  >
                                    <ProductCard
                                      item={item}
                                      key={label}
                                      onSelect={(value) =>
                                        props.onSelect(value)
                                      }
                                      store={props.store}
                                    />
                                  </div>
                                );
                              })
                          : ""}
                      </div>
                    </div>
                  </Container>
                </div>
                {isMobile && !openShowMore && props.productList.length > 4 ? (
                  <div
                    className="my-2 d-flex justify-content-center text-center"
                    onClick={() => setOpenShowMore(true)}
                  >
                    <div>
                      <div style={{ color: "#2b2d32", fontSize: 14 }}>
                        Show More
                      </div>
                      <ExpandMore style={{ color: "#2b2d32" }} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-md-flex d-none justify-content-center my-3">
                  <ProductPagination
                    count={count}
                    page={page}
                    onChange={onChangePage}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        );
      case "Contemporary":
        return (
          <Box className={classes.root}>
            <Box className={classes.products}>
              <Box style={{ padding: "80px 0" }}>
                <Typography
                  style={{
                    fontSize: 36,
                    color: "#2b2d32",
                    fontFamily: storeInfo && storeInfo.font.family,
                    textAlign: "center",
                  }}
                >
                  Products
                </Typography>
                <div className={`${classes.sticktop} d-block d-sm-none`}>
                  <CategoriesTab
                    onChange={categoriesOnChange}
                    color="#2B2D33"
                    template={props.store ? props.store.template : "Blank"}
                  />
                  <SortDiv
                    classes={classes}
                    product_count={props.productList.length}
                    setOpen={setSortOpen}
                  />
                </div>
                <Loading open={loading} />
                <div id="productsDiv">
                  <Container>
                    <div
                      className={`col-12`}
                      style={{ padding: mobileView ? 0 : "0 7em" }}
                    >
                      <div style={{ display: mobileView ? "none" : "inherit" }}>
                        <SortSection
                          store={props.store}
                          categoryOnChange={categoriesOnChange}
                          sortOnChange={sortOnChange}
                          filters={filters}
                          setPage={setPage}
                        />
                      </div>
                      <div
                        className={`d-flex justify-content-center flex-wrap`}
                      >
                        {props.productList && props.productList.length >= 0
                          ? props.productList
                              .slice(
                                0,
                                isMobile
                                  ? openShowMore
                                    ? props.productList.length
                                    : 4
                                  : props.productList.length
                              )
                              .map((item, label) => {
                                return (
                                  <div
                                    className={`d-flex justify-content-center  px-0 py-2 ${
                                      mobileView ? "col-6" : "col-4"
                                    }`}
                                  >
                                    <ProductCard
                                      item={item}
                                      key={label}
                                      onSelect={(value) =>
                                        props.onSelect(value)
                                      }
                                      store={props.store}
                                    />
                                  </div>
                                );
                              })
                          : ""}
                      </div>
                    </div>
                  </Container>
                </div>
                {isMobile && !openShowMore && props.productList.length > 4 ? (
                  <div
                    className="my-2 d-flex justify-content-center text-center"
                    onClick={() => setOpenShowMore(true)}
                  >
                    <div>
                      <div style={{ color: "#2b2d32", fontSize: 14 }}>
                        Show More
                      </div>
                      <ExpandMore style={{ color: "#2b2d32" }} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-md-flex d-none justify-content-center my-3">
                  <ProductPagination
                    count={count}
                    page={page}
                    onChange={onChangePage}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        );
      case "Minimalist":
        return (
          <Box className={classes.root}>
            <Box className={classes.products}>
              <Box style={{ padding: "80px 0" }}>
                <Typography
                  style={{
                    fontSize: 36,
                    textAlign: "center",
                    color: "#2b2d32",
                    fontFamily: storeInfo && storeInfo.font.family,
                  }}
                >
                  PRODUCTS
                </Typography>
                <div className={`${classes.sticktop} d-block d-sm-none`}>
                  <CategoriesTab
                    onChange={categoriesOnChange}
                    color="#2B2D33"
                    template={props.store ? props.store.template : "Blank"}
                  />
                  <SortDiv
                    classes={classes}
                    product_count={props.productList.length}
                    setOpen={setSortOpen}
                  />
                </div>
                <Loading open={loading} />
                <div id="productsDiv">
                  <Container>
                    <div
                      className={`col-12`}
                      style={{ padding: mobileView ? 0 : "0 7em" }}
                    >
                      <div style={{ display: mobileView ? "none" : "inherit" }}>
                        <SortSection
                          store={props.store}
                          categoryOnChange={categoriesOnChange}
                          sortOnChange={sortOnChange}
                          filters={filters}
                          setPage={setPage}
                        />
                      </div>
                      <div
                        className={`d-flex justify-content-center flex-wrap`}
                      >
                        {props.productList && props.productList.length >= 0
                          ? props.productList
                              .slice(
                                0,
                                isMobile
                                  ? openShowMore
                                    ? props.productList.length
                                    : 4
                                  : props.productList.length
                              )
                              .map((item, label) => {
                                return (
                                  <div
                                    className={`d-flex justify-content-center  px-0 py-2 ${
                                      mobileView ? "col-6" : "col-4"
                                    }`}
                                  >
                                    <ProductCard
                                      item={item}
                                      key={label}
                                      onSelect={(value) =>
                                        props.onSelect(value)
                                      }
                                      store={props.store}
                                    />
                                  </div>
                                );
                              })
                          : ""}
                      </div>
                    </div>
                  </Container>
                </div>
                {isMobile && !openShowMore && props.productList.length > 4 ? (
                  <div
                    className="my-2 d-flex justify-content-center text-center"
                    onClick={() => setOpenShowMore(true)}
                  >
                    <div>
                      <div style={{ color: "#2b2d32", fontSize: 14 }}>
                        Show More
                      </div>
                      <ExpandMore style={{ color: "#2b2d32" }} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-md-flex d-none justify-content-center my-3">
                  <ProductPagination
                    count={count}
                    page={page}
                    onChange={onChangePage}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        );

      case "ClassicV2":
        return (
          <div className={classes.root}>
            <div className={classes.banner}>
              <Container className="d-flex justify-content-end">
                <div
                  style={{
                    display: "block",
                    width: mobileView ? "100%" : "45%",
                  }}
                >
                  <div className={classes.header}>Products</div>
                  <div className={classes.subheader}>
                    {props.store.slogan
                      ? props.store.slogan
                      : "Your shop's tagline here. Make it catchy"}
                  </div>
                </div>
              </Container>
            </div>
            <div>
              <div style={{ padding: mobileView ? "1em 2em 0" : "4vh 6vw 0" }}>
                {/* <div className={`${classes.sticktop} d-block d-sm-none`}>
                  <CategoriesTab
                    onChange={categoriesOnChange}
                    color="#2B2D33"
                    template={props.store ? props.store.template : "Blank"}
                  />
                  <SortDiv
                    classes={classes}
                    product_count={props.productList.length}
                    setOpen={setSortOpen}
                  />
                </div> */}

                <SortSection
                  store={props.store}
                  categoryOnChange={categoriesOnChange}
                  sortOnChange={sortOnChange}
                  filters={filters}
                  setPage={setPage}
                />
              </div>
              <Loading open={loading} />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: mobileView ? 0 : "2vh 3em",
                }}
              >
                <div
                  className={`col-12 d-flex flex-wrap`}
                  style={{ padding: mobileView ? "0 2em" : 0 }}
                >
                  {props.productList && props.productList.length >= 0
                    ? props.productList
                        .slice(
                          0,
                          isMobile
                            ? openShowMore
                              ? props.productList.length
                              : 4
                            : props.productList.length
                        )
                        .map((item, label) => {
                          return (
                            <div
                              className={`d-flex justify-content-center  px-0 py-2 ${
                                mobileView ? "col-12" : "col-4"
                              }`}
                            >
                              <ProductCard
                                item={item}
                                key={label}
                                onSelect={(value) => props.onSelect(value)}
                                store={props.store}
                              />
                            </div>
                          );
                        })
                    : ""}
                </div>
              </div>
              {isMobile && !openShowMore && props.productList.length > 4 ? (
                <div
                  className="my-2 d-flex justify-content-center text-center"
                  onClick={() => setOpenShowMore(true)}
                >
                  <div>
                    <div style={{ color: "#2b2d32", fontSize: 14 }}>
                      Show More
                    </div>
                    <ExpandMore style={{ color: "#2b2d32" }} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="d-md-flex d-none justify-content-center my-3">
                <ProductPagination
                  count={count}
                  page={page}
                  onChange={onChangePage}
                />
              </div>
            </div>
          </div>
        );
      case "ContemporaryV2":
        return (
          <div className={classes.root}>
            <div className={classes.banner}>
              <div
                style={{ display: "block", width: mobileView ? "100%" : "50%" }}
              >
                <div className={classes.subheader}>
                  {props.store.slogan
                    ? props.store.slogan
                    : "Your shop's tagline here. Make it catchy"}
                </div>
                <div className={classes.subheader2}>Products</div>
              </div>
            </div>
            <div>
              <div style={{ padding: mobileView ? "1em 2em 0" : "4vh 4vw 0" }}>
                <SortSection
                  categoryOnChange={categoriesOnChange}
                  sortOnChange={sortOnChange}
                  filters={filters}
                  setPage={setPage}
                />
              </div>
              <Loading open={loading} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: mobileView ? 0 : "2vh 3em",
                }}
              >
                <div
                  className={`col-12 d-flex flex-wrap`}
                  style={{ padding: mobileView ? "0 2em" : 0 }}
                >
                  {props.productList && props.productList.length >= 0
                    ? props.productList
                        .slice(
                          0,
                          isMobile
                            ? openShowMore
                              ? props.productList.length
                              : 4
                            : props.productList.length
                        )
                        .map((item, label) => {
                          return (
                            <div
                              className={`d-flex justify-content-center  px-0 py-2 ${
                                mobileView ? "col-12" : "col-3"
                              }`}
                            >
                              <ProductCard
                                item={item}
                                key={label}
                                onSelect={(value) => props.onSelect(value)}
                                store={props.store}
                              />
                            </div>
                          );
                        })
                    : ""}
                </div>
              </div>
              {isMobile && !openShowMore && props.productList.length > 4 ? (
                <div
                  className="my-2 d-flex justify-content-center text-center"
                  onClick={() => setOpenShowMore(true)}
                >
                  <div>
                    <div style={{ color: "#2b2d32", fontSize: 14 }}>
                      Show More
                    </div>
                    <ExpandMore style={{ color: "#2b2d32" }} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="d-md-flex d-none justify-content-center my-3">
                <ProductPagination
                  count={count}
                  page={page}
                  onChange={onChangePage}
                />
              </div>
            </div>
          </div>
        );
      case "Leftist":
        return (
          <div className="container">
            <div style={{ padding: mobileView ? "4em 2em 0" : "2em" }}>
              <div>
                <SortSection
                  categoryOnChange={categoriesOnChange}
                  sortOnChange={sortOnChange}
                  filters={filters}
                  setPage={setPage}
                />
              </div>
              <Loading open={loading} />
              <div className="row">
                {props.productList && props.productList.length >= 0
                  ? props.productList
                      .slice(
                        0,
                        isMobile
                          ? openShowMore
                            ? props.productList.length
                            : 4
                          : props.productList.length
                      )
                      .map((item, label) => {
                        return (
                          <div className="col-12 col-md-4">
                            <ProductCard
                              item={item}
                              key={label}
                              onSelect={(value) => props.onSelect(value)}
                              store={props.store}
                            />
                          </div>
                        );
                      })
                  : ""}
              </div>
            </div>
            {isMobile && !openShowMore && props.productList.length > 4 ? (
              <div
                className="my-2 d-flex justify-content-center text-center"
                onClick={() => setOpenShowMore(true)}
              >
                <div>
                  <div style={{ color: "#2b2d32", fontSize: 14 }}>
                    Show More
                  </div>
                  <ExpandMore style={{ color: "#2b2d32" }} />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="d-md-flex d-none justify-content-center my-3">
              <ProductPagination
                count={count}
                page={page}
                onChange={onChangePage}
              />
            </div>
          </div>
        );
      case "SariSari":
        return (
          <div
            style={{ marginTop: "5em", backgroundColor: color.primary.main }}
          >
            <Container>
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="text-center"
                  style={{ padding: isMobile ? "2em" : "2em 20em" }}
                >
                  <div className={classes.header}>Products</div>
                  <div className={`${classes.subheader} mt-3 d-md-flex d-none`}>
                    {props.store.slogan
                      ? props.store.slogan
                      : "Your shop's tagline here. Make it catchy"}
                  </div>
                </div>
              </div>
              <div className="py-4">
                <SortSection
                  categoryOnChange={categoriesOnChange}
                  sortOnChange={sortOnChange}
                  filters={filters}
                  color={color.primary.contrastText}
                  setPage={setPage}
                />
                <div className="d-flex flex-wrap">
                  {props.productList && props.productList.length >= 0
                    ? props.productList
                        .slice(
                          0,
                          isMobile
                            ? openShowMore
                              ? props.productList.length
                              : 4
                            : props.productList.length
                        )
                        .map((item, index) => (
                          <div
                            className={`d-flex justify-content-center  px-0 py-2 col-md-3 col-12`}
                          >
                            <ProductCard
                              item={item}
                              onSelect={(value) => props.onSelect(value)}
                              store={props.store}
                            />
                          </div>
                        ))
                    : ""}
                </div>
              </div>
              {isMobile && !openShowMore && props.productList.length > 4 ? (
                <div
                  className="my-2 d-flex justify-content-center text-center"
                  onClick={() => setOpenShowMore(true)}
                >
                  <div>
                    <div style={{ color: "#2b2d32", fontSize: 14 }}>
                      Show More
                    </div>
                    <ExpandMore style={{ color: "#2b2d32" }} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="d-md-flex d-none justify-content-center my-3">
                <ProductPagination
                  count={count}
                  page={page}
                  onChange={onChangePage}
                />
              </div>
            </Container>
          </div>
        );
      case "Pastel":
        return (
          <div
            className="px-md-5 px-2"
            style={{
              backgroundColor: isMobile ? "#fff" : color && color.primary.main,
            }}
          >
            <Container
              className="bg-white p-md-5 p-2"
              style={{ marginTop: "5em" }}
            >
              <div
                style={{ fontSize: 20, fontWeight: 600 }}
                className="text-center"
              >
                Products
              </div>
              <div className="py-4">
                <SortSection
                  categoryOnChange={categoriesOnChange}
                  sortOnChange={sortOnChange}
                  filters={filters}
                  setPage={setPage}
                />
                <div className="d-flex flex-wrap">
                  {props.productList && props.productList.length >= 0
                    ? props.productList
                        .slice(
                          0,
                          isMobile
                            ? openShowMore
                              ? props.productList.length
                              : 4
                            : props.productList.length
                        )
                        .map((item, index) => (
                          <div
                            className={`d-flex justify-content-center  px-0 py-2 col-md-4 col-12`}
                          >
                            <ProductCard
                              item={item}
                              onSelect={(value) => props.onSelect(value)}
                              store={props.store}
                            />
                          </div>
                        ))
                    : ""}
                </div>
              </div>
              {isMobile && !openShowMore && props.productList.length > 4 ? (
                <div
                  className="my-2 d-flex justify-content-center text-center"
                  onClick={() => setOpenShowMore(true)}
                >
                  <div>
                    <div style={{ color: "#2b2d32", fontSize: 14 }}>
                      Show More
                    </div>
                    <ExpandMore style={{ color: "#2b2d32" }} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="d-md-flex d-none justify-content-center my-3">
                <ProductPagination
                  count={count}
                  page={page}
                  onChange={onChangePage}
                />
              </div>
            </Container>
          </div>
        );
      default:
        return <Blank />;
    }
  };

  return (
    <div>
      <Container className="p-0 p-sm-auto">
        <PromoBanner />
      </Container>
      {setProductTemplate(props.store ? props.store.template : "Blank")}
      <SortBy
        open={sortOpen}
        setOpen={setSortOpen}
        onChange={sortOnChange}
        color={"#2B2D33"}
      />
    </div>
  );
};
export default ProductTemplate;
