import React from "react";
import {
  Slide,
  makeStyles,
  Dialog,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import CancelIcon from "@material-ui/icons/Cancel";
import { ExpandMore } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../../../../common/text";
import SentroButton from "../../../../sentroButton";
import MobileProductImageUpload from "../common/MobileProductImageUpload";
import { withStyles } from "@material-ui/styles";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import StarIcon from "@material-ui/icons/Stars";
import CloseIcon from "@material-ui/icons/Close";
import {
  addProduct,
  getStoreProductCategories,
  openAddProduct,
  getStoreProducts
} from "../websiteBuilderActions";
import CropperForm from "../common/imageUploader/cropperForm";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import ProductImageCropper from "../common/productImageCropper";
import AddProductCategory from "./MobileAddProductCategory";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import AddIcon from "@material-ui/icons/Add";
import AddVariantGroup from "../../../../../websiteBuilder/desktop/forms/addVariantGroup";
import EditVariantGroup from "../../../../../websiteBuilder/desktop/forms/editVariantGroup";
import defaultVariants from "../../../../../websiteBuilder/desktop/forms/defaultVariants";
import VariantChoicesForm, {
  useProductSelectionAxiosData,
  useProductSelectionsToDelete,
  getAllNewlyCreatedVariantIds
} from "../../../../../websiteBuilder/desktop/forms/variantChoicesForm";
import { saveProductSelections } from "../../../../../websiteBuilder/websiteBuilderActions";
const DialogTransition = props => <Slide direction="up" {...props} />;
const useStyles = makeStyles(theme => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd"
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  header2: {
    color: "#f97663",
    fontSize: 14,
    fontWeight: 600
  },
  title: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    width: "100%"
  },
  errorText: {
    fontSize: 12,
    color: "red"
  },
  errorLayout: { display: "flex", justifyContent: "space-between" },
  closeBtn: { position: "absolute", left: 15 },
  blueWarning: {
    backgroundColor: "#1e4b83",
    color: "#fff",
    padding: "10px 20px 20px"
  },
  layoutDisplay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  imgLayout: {
    width: 90,
    height: 90,
    margin: 5,
    borderRadius: 5
  },
  actionBtn: {
    color: "#f97663",
    display: "flex",
    justifyContent: "flex-end",
    margin: 5,
    cursor: "pointer",
    position: "absolute",
    top: 5,
    right: 5
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  icon: {
    width: "100%",
    textAlign: "center",
    color: "#ee2545",
    fontSize: 100
  },
  subheader: {
    padding: "10px 0",
    fontSize: 16,
    fontWeight: 600,
    color: "#002c42"
  },
  label: { fontSize: 14, color: "#54575f" },
  removeTxt: {
    color: "#F97663",
    fontWeight: "bold",
    fontSize: 13,
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer"
  },
  inputWrap: {
    width: 311,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  variantHeaderSection: {
    marginTop: "3vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  btnColor: {
    fontSize: 12,
    height: 33,
    padding: "0 16px",
    marginRight: 10,
    fontWeight: 600,
    textTransform: "capitalize"
  }
}));

const BlueWarning = props => {
  const { language } = props;
  const classes = useStyles();
  const [hide, setHide] = React.useState(false);
  return (
    <div
      style={{ display: hide ? "none" : "block" }}
      className={classes.blueWarning}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div style={{ fontSize: 13, fontWeight: 600 }}>
          {language === "English"
            ? "Tips to make your products stand out:"
            : "Mga tips kung paano mo pagagandahin ang mga produkto mo:"}
        </div>
        <IconButton onClick={() => setHide(true)}>
          <CloseIcon style={{ color: "#fff", fontSize: 14 }} />
        </IconButton>
      </div>
      <div style={{ fontSize: 12 }}>
        {language === "English" ? (
          <div>
            <div>- Upload a clear photo</div>
            <div>- Provide a complete item name</div>
            <div>- Ensure price is correct</div>
            <div>- Add more details to describe your item</div>
          </div>
        ) : (
          <div>
            <div>- Mag-upload ng malinaw na picture</div>
            <div>- Magbigay ng kumpletong item name</div>
            <div>- Siguraduhin na tama ang pag presyo sa mga produkto mo</div>
            <div>
              - Magbigay ng mas maraming mga detalye tungkol sa produkto mo
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MobileAddProducts = props => {
  React.useEffect(() => {
    // HotJar heat map
    if (typeof window.hj === "function") {
      window.hj("trigger", "sentroAddProduct_mobile");
    }
  }, []);

  const {
    product,
    setProduct,
    onClose,
    storeInfo,
    language,
    numOfFeaturedProducts,
    currentProducts
  } = props;
  const dispatch = useDispatch();
  let uuid = localStorage.getItem("STORE_UUID");
  const classes = useStyles();
  const currentStoreProducts = useSelector(
    state => state.mobileDashboard.products.list
  );
  const stores = useSelector(state => state.mobileDashboard.stores);
  const productCategories = useSelector(
    state => state.mobileDashboard.product_categories
  );
  const open = useSelector(state => state.mobileDashboard.add_product);
  const filters = useSelector(state => state.mobileDashboard.products.filters);
  const editorRef = React.useRef();
  const images = product.productImages ? product.productImages : "";
  const [expandedVariant, setExpandedVariant] = React.useState(true);
  const [expandedAdditional, setExpandedAdditional] = React.useState(true);
  const [dialogHasErrors, setDialogHasErrors] = React.useState(true);
  const [isDialogEnough, setIsDialogEnough] = React.useState(false);
  const [cropState, setCropState] = React.useState(false);
  const [cropImage, setCropImage] = React.useState(false);
  const [convertedHeic, setConvertedHeic] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [key, setKey] = React.useState("");
  const [openFeaturedModal, setOpenFeaturedModal] = React.useState(false);
  const [color, setColor] = React.useState(["black", "white"]);
  const [size, setSize] = React.useState(["small", "medium", "large"]);
  const [variantModal, setVariantModal] = React.useState({
    addVariantModal: false,
    editVariantModal: false,
    index: ""
  });
  const [productVariants, setProductVariants] = React.useState([]);
  const [variant, setVariant] = React.useState([]);
  const [errors, setErrors] = React.useState({
    nameError: "",
    priceError: "",
    descriptionError: "",
    categoryError: "",
    quantityError: "",
    imageError: ""
  });

  const field_max_length = {
    name: 50,
    category: 32,
    description: 512
  };
  const handleChangeImage = file => {
    images.push(file);
    setProductChanges(images, "productImages");
    setCropState(false);
  };

  const handleRemoveItem = e => {
    if (product.photo_primary === "0") {
      setProductChanges("0", "photo_primary");
    } else {
      setProductChanges(
        parseInt(product.photo_primary - 1).toString(),
        "photo_primary"
      );
    }
    images.splice(e, 1);
    setProductChanges(images, "productImages");
  };

  const handleExpandedVariant = () => {
    setExpandedVariant(expandedVariant === true ? false : true);
  };

  const handleExpandedAdditional = () => {
    setExpandedAdditional(expandedAdditional === true ? false : true);
  };
  function setProductChanges(value, name) {
    setProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function handleAddCategory(category) {
    let list = categories.map(e => e.toLowerCase());
    if (category === "") {
      setErrors({ ...errors, categoryError: "This field is required." });
    } else if (list.indexOf(category.toLowerCase()) !== -1) {
      setErrors({ ...errors, categoryError: "Category already exist!" });
    } else {
      setErrors({ ...errors, categoryError: "" });
      setAlert(true);
      setKey(category);
      setProductChanges(category, "category");
      categories.push(category);
    }
  }

  function handleFeatured() {
    if (numOfFeaturedProducts.length >= 3 && !product.featured) {
      setOpenFeaturedModal(true);
    } else if (numOfFeaturedProducts.length < 3) {
      setProductChanges(product.featured === true ? false : true, "featured");
    } else {
      setProductChanges(product.featured === true ? false : true, "featured");
      numOfFeaturedProducts.splice(1, 1);
    }
  }
  function handleActive() {
    setProductChanges(product.is_active === true ? false : true, "is_active");
  }
  function handleAlwaysAvailable() {
    setProductChanges(
      product.always_available === true ? false : true,
      "always_available"
    );
    if (product.always_available === false) {
      setErrors({ ...errors, quantityError: "" });
    } else if (product.always_available === true) {
      setProductChanges(5, "quantity");
    }
  }

  const productSelectionsData = useProductSelectionAxiosData();
  const productSelectionsToDelete = useProductSelectionsToDelete();

  async function handleSaveProduct() {
    console.log(product);
    const res = await dispatch(
      addProduct(product, productVariants, storeInfo, filters)
    );
    const data = productSelectionsData.map(i => ({
      ...i,
      product_variant_ids: getAllNewlyCreatedVariantIds(res.data, i)
    }));

    dispatch(saveProductSelections(data, productSelectionsToDelete));
    dispatch(openAddProduct(false));
    dispatch(getStoreProducts());
  }

  async function handleAddAnotherProduct() {
    const res = await dispatch(addProduct(product, productVariants, storeInfo));
    const data = productSelectionsData.map(i => ({
      ...i,
      product_variant_ids: getAllNewlyCreatedVariantIds(res.data, i)
    }));
    dispatch(saveProductSelections(data, productSelectionsToDelete));
    dispatch(openAddProduct(false));
    dispatch(getStoreProducts());
    if (currentStoreProducts.length >= 3) {
      setProduct({
        store: storeInfo ? storeInfo.uuid : "",
        name: "",
        price: "",
        discounted_price: "",
        description: "",
        category: "",
        featured: false,
        is_active: true,
        always_available: true,
        quantity: 5,
        images: "",
        productImages: [],
        photo_primary: "0",
        product_code: ""
      });
    } else {
      setProduct({
        store: storeInfo ? storeInfo.uuid : "",
        name: "",
        price: "",
        discounted_price: "",
        description: "",
        category: "",
        featured: true,
        is_active: true,
        always_available: true,
        quantity: 5,
        images: "",
        productImages: [],
        photo_primary: "0",
        product_code: ""
      });
    }
    dispatch(openAddProduct(true));
  }

  function createPreview(file) {
    var blob = new Blob([file], { type: file.type });
    var blobUrl = URL.createObjectURL(blob);

    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    xhr.onload = function() {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function() {
        var blobAsDataUrl = reader.result;
        window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    return blobUrl;
  }

  function handleImageUpload(value) {
    if (value === undefined || value === "undefined") {
      setErrors({
        ...errors,
        imageError: ""
      });
    } else {
      if (value.size > 5242880) {
        setErrors({
          ...errors,
          imageError: "Image is too big. Maximum allowed size is 5 MB."
        });
      } else {
        if (value.name.match(/\.(jpg|jpeg|png|heic)$/)) {
          setCropState(value ? true : false);
          setCropImage(value);
          setErrors({
            ...errors,
            imageError: ""
          });
        } else if (value.name.match(/\.(gif)$/)) {
          setCropImage(value);
          handleChangeImage(value, "productImages");
          setErrors({
            ...errors,
            imageError: ""
          });
        } else if (value.name.match(/\.(svg|html|pdf|zip)$/)) {
          setErrors({
            ...errors,
            imageError: value.name + " is not supported file extension"
          });
        }
      }
    }
  }

  function setHasVariants(e) {
    if (e.target.checked) {
      setErrors(e => ({
        ...e,
        priceError: ""
      }));
    }
    setProductChanges(e.target.checked, "has_variants");
  }

  function countSelectedVariant(variant) {
    let variants = variant.variants;
    let numOfSelected = variants.filter(item => item.is_enabled === true);
    return variant.name + ` (${numOfSelected.length} Selected)`;
  }

  function handleEditVariant(variant, key) {
    setVariantModal({ ...variantModal, editVariantModal: true, index: key });
    setVariant(variant);
  }

  function addSelectedDefault(item, variant) {
    let variants = variant.variants.filter(e => e.name !== item.name);
    const param = {
      key: variant.key,
      name: variant.name,
      variants: [
        ...variants,
        {
          key: item.key,
          name: item.name,
          is_enabled: item.is_enabled ? false : true
        }
      ]
    };
    let newVariantsDefault = productVariants.filter(e => e.name !== param.name);
    setProductVariants([...newVariantsDefault, param]);
  }

  let charactersLeft = fieldname => {
    const fieldlength = product[fieldname] ? product[fieldname].length : 0;
    const charsRemaining = field_max_length[fieldname] - fieldlength;
    return `${charsRemaining} characters left`;
  };

  let sanitize_price = raw_input => {
    const NOT_DOTS_NOR_NUMERICS_REGEX = /[^0-9\.]/g;
    const TWO_DOTS_REGEX = /\..*\./g;
    const ONE_DOT_REGEX = /\./g;

    let numerics_and_dots_only = raw_input.replace(
      NOT_DOTS_NOR_NUMERICS_REGEX,
      ""
    );
    // Allow at most 1 decimal place only
    let one_dot_at_most = TWO_DOTS_REGEX.test(numerics_and_dots_only)
      ? numerics_and_dots_only.slice(0, numerics_and_dots_only.lastIndexOf("."))
      : numerics_and_dots_only;
    // Limit to 2 decimal places
    let final_string = ONE_DOT_REGEX.test(one_dot_at_most)
      ? one_dot_at_most.slice(0, one_dot_at_most.lastIndexOf(".") + 3)
      : one_dot_at_most;

    return final_string;
  };

  const closeCroppper = () => {
    setCropState(false);
  };

  const onCropSave = image => {
    handleChangeImage(image, "productImages");
  };

  const handleDescription = description => {
    setProductChanges(description, "description");
  };

  React.useEffect(() => {
    if (stores.length && open)
      dispatch(getStoreProductCategories(stores[0].uuid));
    setProductVariants(defaultVariants);
  }, [stores, open]);

  React.useEffect(() => {
    setCategories(productCategories.categories);
  }, [productCategories]);

  React.useEffect(() => {
    setCropState(convertedHeic ? true : false);
    setCropImage(convertedHeic);
  }, [convertedHeic]);

  React.useEffect(() => {
    for (let e in errors) {
      if (errors[e].length > 0) {
        setDialogHasErrors(true);
        return;
      }
    }
    setDialogHasErrors(false);
  }, [errors]);

  const variant_combination_errors = useSelector(
    state => state.form.variant_choices?.syncErrors
  );
  React.useEffect(() => {
    setIsDialogEnough(
      product.name &&
        (product.price || (product.has_variants && !variant_combination_errors))
    );
  }, [product]);

  React.useEffect(() => {
    if (product.category === "No Category (Others)" && key === 0) {
      setProductChanges("", "category");
    }
  }, [product.category]);

  const CustomRadio = withStyles({
    root: {
      color: "#ffcf00",
      "&$checked": {
        color: "#ffcf00"
      }
    },
    checked: {}
  })(props => <Radio color="default" {...props} />);

  const [withDiscount, setWithDiscount] = React.useState(false);
  React.useEffect(() => {
    if (open) {
      setWithDiscount(props.product.discounted_price);
    }
  }, [open, props.product]);

  const [
    productVariantsSelection,
    setProductVariantsSelection
  ] = React.useState([]);
  React.useEffect(() => {
    setProductVariantsSelection(
      productVariants.length ? [...productVariants] : []
    );
  }, [productVariants]);

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={DialogTransition}>
        {!cropState ? (
          <div
          // style={{
          //   overflowY: expandedVariant ? "visible" : "scroll",
          //   overflowX: expandedVariant ? "visible" : "hidden"
          // }}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    categories.length = 0;
                    onClose();
                    setKey("");
                    setExpandedVariant(false);
                    setExpandedAdditional(false);
                  }}
                  className={classes.closeBtn}
                >
                  <CloseIcon style={{ color: "#f97663" }} />
                </IconButton>
                <div className={classes.header}>
                  {language === "English"
                    ? "Add a product"
                    : "Magdagdag ng produkto"}
                </div>
              </Toolbar>
            </AppBar>
            <div style={{ paddingTop: "6vh" }}>
              <BlueWarning language={language} />
              <div style={{ padding: 20 }}>
                <Text textType="p3_Semibold" content="Photos" />
                <div
                  style={{ fontSize: 12, color: "#54575f", marginBottom: 5 }}
                >
                  PNG, JPG, JPEG or GIF only. Up to 5 photos. Tap to edit
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <RadioGroup
                    style={{ flexDirection: "row" }}
                    value={product.photo_primary}
                    onChange={e =>
                      setProductChanges(e.target.value, "photo_primary")
                    }
                  >
                    {images
                      ? images.map((item, i) => (
                          <div key={i} style={{ position: "relative" }}>
                            <img
                              src={createPreview(item)}
                              className={classes.imgLayout}
                            />
                            <span className={classes.actionBtn}>
                              <CancelIcon
                                onClick={() => handleRemoveItem(i)}
                                style={{ fontSize: 20, color: "#f97663" }}
                              />
                            </span>
                            <div
                              style={{
                                position: "absolute",
                                bottom: 0,
                                left: 8
                              }}
                            >
                              <FormControlLabel
                                key={i}
                                color="secondary"
                                value={i.toString()}
                                control={
                                  <CustomRadio
                                    icon={<CircleUnchecked />}
                                    checkedIcon={<StarIcon />}
                                  />
                                }
                              />
                            </div>
                          </div>
                        ))
                      : ""}
                    {images ? (
                      images.length === 5 ? (
                        ""
                      ) : (
                        <MobileProductImageUpload
                          defaultPreview
                          onChange={value => handleImageUpload(value)}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </RadioGroup>
                </div>
                <p className="text-danger mt-1" style={{ fontSize: 12 }}>
                  {errors.imageError}
                </p>
                <div style={{ marginTop: "1em" }}>
                  <Text textType="p3_Semibold" content="*Name" />
                  <TextField
                    fullWidth
                    margin="dense"
                    id="name"
                    color="secondary"
                    variant="outlined"
                    style={{ marginTop: 0 }}
                    value={product.name}
                    error={errors.nameError}
                    inputProps={{ maxLength: field_max_length.name }}
                    onChange={e => {
                      let input = e.target.value;
                      setProductChanges(input, "name");
                      if (input === "") {
                        setErrors({
                          ...errors,
                          nameError: "This field is required."
                        });
                      } else {
                        setErrors({ ...errors, nameError: "" });
                      }
                    }}
                    onBlur={e => {
                      let input = e.target.value;
                      if (input === "") {
                        setErrors({
                          ...errors,
                          nameError: "This field is required."
                        });
                      }
                    }}
                    helperText={
                      <div className={classes.errorLayout}>
                        <div>{errors.nameError}</div>
                        <div style={{ color: "rgba(0,0,0,0.54)" }}>
                          {charactersLeft("name")}
                        </div>
                      </div>
                    }
                  />
                </div>
                <div>
                  <Text textType="p3_Semibold" content="*Price" />
                  <TextField
                    fullWidth
                    margin="dense"
                    id="price"
                    color="secondary"
                    variant="outlined"
                    style={{ marginTop: 0 }}
                    value={product.price}
                    disabled={product.has_variants}
                    inputProps={{ maxLength: 8 }}
                    error={errors.priceError}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">PHP</InputAdornment>
                      )
                    }}
                    onChange={e => {
                      let input = sanitize_price(e.target.value);
                      setProductChanges(input, "price");
                      if (input === "") {
                        setErrors({
                          ...errors,
                          priceError: "This field is required."
                        });
                      } else {
                        setErrors({ ...errors, priceError: "" });
                      }
                    }}
                    onBlur={e => {
                      const input = e.target.value;
                      const ENDS_WITH_DECIMAL_REGEX = /\.$/g;
                      if (input === "") {
                        setErrors({
                          ...errors,
                          priceError: "This field is required."
                        });
                      } else if (ENDS_WITH_DECIMAL_REGEX.test(input)) {
                        setErrors({
                          ...errors,
                          priceError: "Please enter a valid price."
                        });
                      }
                    }}
                    helperText={errors.priceError}
                  />
                </div>
                {!withDiscount ? (
                  <Button
                    className="mt-3"
                    color="primary"
                    variant="outlined"
                    disableElevation
                    disabled={product.has_variants}
                    onClick={() => setWithDiscount(true)}
                  >
                    Add Discounted Price +
                  </Button>
                ) : (
                  <div
                    className={`mt-3 position-relative ${classes.inputWrap}`}
                  >
                    <p
                      className={`mb-0 ${classes.removeTxt}`}
                      onClick={() => {
                        setProductChanges("", "discounted_price");
                        setWithDiscount(false);
                      }}
                    >
                      Remove
                    </p>
                    <Text textType="p3_Semibold" content="*Discounted Price" />
                    <TextField
                      fullWidth
                      margin="dense"
                      id="discounted_price"
                      variant="outlined"
                      color="secondary"
                      style={{ marginTop: 0 }}
                      error={errors.discountedPriceError}
                      inputProps={{ maxLength: 8 }}
                      value={product.discounted_price}
                      disabled={product.has_variants}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">PHP</InputAdornment>
                        )
                      }}
                      onChange={e => {
                        let input = sanitize_price(e.target.value);
                        setProductChanges(input, "discounted_price");
                        if (input === "") {
                          setErrors({
                            ...errors,
                            discountedPriceError: "This field is required."
                          });
                        } else {
                          setErrors({ ...errors, discountedPriceError: "" });
                        }
                      }}
                      onBlur={e => {
                        const input = e.target.value;
                        const ENDS_WITH_DECIMAL_REGEX = /\.$/g;
                        if (input === "") {
                          setErrors({
                            ...errors,
                            discountedPriceError: "This field is required."
                          });
                        } else if (ENDS_WITH_DECIMAL_REGEX.test(input)) {
                          setErrors({
                            ...errors,
                            discountedPriceError: "Please enter a valid price."
                          });
                        }
                      }}
                      helperText={errors.discountedPriceError}
                    />
                  </div>
                )}

                <div
                  style={{
                    marginTop: 15,
                    position: "relative",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <Text textType="p3_Semibold" content="*Stocks" />
                    <Text
                      style={{ position: "absolute" }}
                      textType="label2"
                      content="Quantity will be reduced for every order made"
                    />
                    <TextField
                      disabled={
                        product.always_available || product.has_variants
                      }
                      margin="dense"
                      id="description"
                      color="secondary"
                      variant="outlined"
                      style={{ marginTop: 20, marginRight: 20 }}
                      value={product.quantity}
                      error={errors.quantityError}
                      onChange={e => {
                        let input = e.target.value;
                        setProductChanges(input, "quantity");
                        if (input === "") {
                          setErrors({
                            ...errors,
                            quantityError: "This field is required."
                          });
                        } else if (input > 10000) {
                          setErrors({
                            ...errors,
                            quantityError: "This field must be less 10000."
                          });
                        } else {
                          setErrors({ ...errors, quantityError: "" });
                        }
                      }}
                      onBlur={e => {
                        let input = e.target.value;
                        if (input === "") {
                          setErrors({
                            ...errors,
                            quantityError: "This field is required."
                          });
                        } else if (input > 10000) {
                          setErrors({
                            ...errors,
                            quantityError: "This field must be less 10000."
                          });
                        } else {
                          setErrors({ ...errors, quantityError: "" });
                        }
                      }}
                      helperText={errors.quantityError}
                    />
                  </div>
                  <div>
                    <div className={classes.layoutDisplay}>
                      {/* <Text
                        textType="p3_Semibold"
                        content="Always available?"
                      />
                      <Switch
                        checked={product.always_available}
                        onChange={() => handleAlwaysAvailable()}
                        color="primary"
                      /> */}
                      <FormControlLabel
                        labelPlacement="start"
                        label={
                          <div style={{ fontSize: 14, color: "#54575f" }}>
                            Always available?
                          </div>
                        }
                        control={
                          <Checkbox
                            color="primary"
                            checked={product.always_available}
                            onChange={() => handleAlwaysAvailable()}
                            name={product.always_available}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 15 }}>
                <ExpansionPanel
                  expanded={expandedVariant}
                  onChange={() => handleExpandedVariant()}
                  style={{ boxShadow: "none" }}
                >
                  <ExpansionPanelSummary
                    style={{
                      borderBottom: "1px solid #002c42",
                      borderTop: "1px solid #002c42"
                    }}
                    expandIcon={<ExpandMore />}
                  >
                    <div className={classes.header2}>Variants</div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    style={{ borderBottom: "1px solid #d1d5dd", padding: 20 }}
                  >
                    <div style={{ width: "100%" }}>
                      <FormControlLabel
                        className="my-3"
                        style={{ marginLeft: -10 }}
                        label={
                          <div style={{ fontSize: 14, color: "#2B2D32" }}>
                            This product has multiple options (different sizes,
                            colors, etc.)
                          </div>
                        }
                        control={
                          <Checkbox
                            color="primary"
                            checked={product.has_variants}
                            onChange={setHasVariants}
                          />
                        }
                      />

                      <div className="position-relative">
                        {!product.has_variants && (
                          <div
                            className="position-absolute h-100 w-100"
                            style={{
                              background: "rgba(255,255,255,0.5)",
                              zIndex: 2
                            }}
                          ></div>
                        )}
                        {productVariants.length &&
                          productVariants
                            .sort(function(a, b) {
                              if (a.name.toLowerCase() < b.name.toLowerCase())
                                return -1;
                              if (a.name.toLowerCase() > b.name.toLowerCase())
                                return 1;
                              return 0;
                            })
                            .map((variant, key) => {
                              return (
                                <div>
                                  <div
                                    className={classes.variantHeaderSection}
                                    style={{ marginTop: 0 }}
                                  >
                                    <Text
                                      textType="p3_Semibold"
                                      content={countSelectedVariant(variant)}
                                    />
                                    <Button
                                      startIcon={
                                        <BorderColorIcon color="primary" />
                                      }
                                      variant="text"
                                      color="primary"
                                      className={classes.btnColor}
                                      style={{ marginRight: 0 }}
                                      onClick={() =>
                                        handleEditVariant(variant, key)
                                      }
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                  <div>
                                    {variant.variants
                                      .sort(function(a, b) {
                                        if (
                                          a.name.toLowerCase() <
                                          b.name.toLowerCase()
                                        )
                                          return -1;
                                        if (
                                          a.name.toLowerCase() >
                                          b.name.toLowerCase()
                                        )
                                          return 1;
                                        return 0;
                                      })
                                      .map((item, key) => {
                                        return (
                                          <Button
                                            variant={
                                              item.is_enabled
                                                ? "contained"
                                                : "outlined"
                                            }
                                            color={
                                              item.is_enabled
                                                ? "primary"
                                                : "secondary"
                                            }
                                            disableElevation
                                            className={classes.btnColor}
                                            onClick={() =>
                                              addSelectedDefault(item, variant)
                                            }
                                          >
                                            {item.name}
                                          </Button>
                                        );
                                      })}
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="primary"
                                      endIcon={<AddIcon />}
                                      className={classes.btnColor}
                                      style={{ padding: "0 10px" }}
                                      onClick={() =>
                                        handleEditVariant(variant, key)
                                      }
                                    >
                                      Add
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}

                        {productVariants.length < 2 && (
                          <div style={{ marginTop: "3vh" }}>
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              endIcon={<AddIcon />}
                              className={classes.btnColor}
                              style={{ padding: "0 10px" }}
                              onClick={() =>
                                setVariantModal({
                                  ...variantModal,
                                  addVariantModal: true
                                })
                              }
                            >
                              Add Variant Group
                            </Button>
                          </div>
                        )}
                        {open && (
                          <VariantChoicesForm
                            data={productVariantsSelection}
                            product={product}
                          />
                        )}
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div style={{ marginTop: 15 }}>
                <ExpansionPanel
                  onChange={() => handleExpandedAdditional()}
                  style={{ boxShadow: "none" }}
                >
                  <ExpansionPanelSummary
                    style={{
                      borderBottom: "1px solid #002c42",
                      borderTop: "1px solid #002c42"
                    }}
                    expandIcon={<ExpandMore />}
                  >
                    <div className={classes.header2}>
                      Additional Information
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    style={{ borderBottom: "1px solid #d1d5dd", padding: 20 }}
                  >
                    <div style={{ width: "100%" }}>
                      <div>
                        <Text textType="p3_Semibold" content="Description" />
                        <SunEditor
                          ref={editorRef}
                          setContents={product.description}
                          onChange={handleDescription}
                          setOptions={{
                            buttonList: [
                              [
                                "font",
                                "fontSize",
                                "formatBlock",
                                "align",
                                "list"
                              ],
                              ["bold", "underline", "italic"],
                              ["fontColor", "hiliteColor"],
                              ["outdent", "indent"]
                            ],
                            height: 200,
                            charCounter: true,
                            maxCharCount: 512
                          }}
                        />
                      </div>
                      <div style={{ marginTop: 15 }}>
                        <Text textType="p3_Semibold" content="Category" />
                        <FormControl
                          style={{ width: "100%" }}
                          variant="outlined"
                          margin="dense"
                        >
                          <Select
                            id="category"
                            color="secondary"
                            value={key === 0 ? "No Category (Others)" : key}
                            onChange={e => {
                              setProductChanges(e.target.value, "category");
                            }}
                          >
                            {categories
                              .sort(function(a, b) {
                                if (a && b) {
                                  if (a.toLowerCase() < b.toLowerCase())
                                    return -1;
                                  if (a.toLowerCase() > b.toLowerCase())
                                    return 1;
                                }
                                return 0;
                              })
                              .map(item => {
                                return item === "" ? (
                                  ""
                                ) : (
                                  <MenuItem
                                    onClick={() => setKey(item)}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}

                            <MenuItem
                              onClick={() => setKey(0)}
                              value={"No Category (Others)"}
                            >
                              No Category (Others)
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setOpenAddCategory(true);
                              }}
                              style={{ color: "#f97663", fontWeight: 600 }}
                            >
                              + Add a new category
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {/* <TextField
                          fullWidth
                          margin="dense"
                          id="category"
                          color="secondary"
                          variant="outlined"
                          style={{ marginTop: 0 }}
                          value={product.category}
                          inputProps={{ maxLength: field_max_length.category }}
                          onChange={e =>
                            setProductChanges(e.target.value, "category")
                          }
                        /> */}
                      </div>
                      <div style={{ marginTop: 15 }}>
                        <div>
                          <Text textType="p3_Semibold" content="Product Code" />
                          <Text
                            textType="label2"
                            content="Optional. Change only if you have SKU for this product"
                          />
                          <TextField
                            fullWidth
                            margin="dense"
                            id="description"
                            color="secondary"
                            variant="outlined"
                            style={{ marginTop: 0 }}
                            value={product.product_code}
                            inputProps={{ maxLength: 32 }}
                            onChange={e =>
                              setProductChanges(e.target.value, "product_code")
                            }
                          />
                        </div>
                      </div>

                      <div style={{ marginTop: 15 }}>
                        <div className={classes.layoutDisplay}>
                          <Text
                            textType="p3_Semibold"
                            content="Publish product"
                          />
                          <Switch
                            checked={product.is_active}
                            onChange={() => handleActive()}
                            color="primary"
                          />
                        </div>
                        <Text
                          textType="label2"
                          content="Turn off the button to make the product invisible to buyer."
                        />
                      </div>
                      <div>
                        <div className={classes.layoutDisplay}>
                          <Text
                            textType="p3_Semibold"
                            content="Featured product?"
                          />
                          <Switch
                            checked={product.featured}
                            onChange={() => handleFeatured()}
                            color="primary"
                          />
                        </div>
                        <Text
                          textType="label2"
                          content="Turn on to make the product show at the featured section."
                        />
                      </div>

                      <div
                        style={{
                          marginTop: 15,
                          display: product.always_available ? "none" : "flex"
                        }}
                      ></div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div style={{ width: "100%", position: "relative" }}>
              <ProductImageCropper
                image={cropImage}
                open={cropState}
                onClose={closeCroppper}
                aspect={1 / 1}
                fieldname={cropImage.name}
                onSave={image => onCropSave(image)}
              />
              {/* <ImageCropper
                fullWidth
                setCropState={setCropState}
                filename={cropImage.name}
                image={URL.createObjectURL(cropImage)}
                onSave={value => handleChangeImage(value, "productImages")}
              /> */}
            </div>
          </div>
        )}
        <div style={{ padding: "10px 20px", display: cropState ? "none" : "" }}>
          <div style={{ marginTop: 15 }}>
            <SentroButton
              style={{ marginBottom: 5 }}
              disableElevation
              fullWidth
              label="Add another product"
              variant="outlined"
              onClick={() => {
                handleAddAnotherProduct();
                setProductChanges(uuid, "store");
                setExpandedVariant(false);
                setExpandedAdditional(false);
                setKey("");
                images.length = 0;
              }}
              disabled={!isDialogEnough || dialogHasErrors}
            />
            <SentroButton
              disableElevation
              fullWidth
              label={
                storeInfo
                  ? storeInfo.is_published === false
                    ? "Submit and publish your shop"
                    : "Submit"
                  : "Submit"
              }
              variant="contained"
              onClick={() => {
                handleSaveProduct();
                setProductChanges(uuid, "store");
                setExpandedVariant(false);
                setExpandedAdditional(false);
              }}
              disabled={!isDialogEnough || dialogHasErrors}
            />
          </div>
        </div>
        <AddProductCategory
          open={openAddCategory}
          setOpen={setOpenAddCategory}
          categories={categories}
          error={errors.categoryError}
          alert={alert}
          setAlert={setAlert}
          handleAddCategory={category => handleAddCategory(category)}
        />

        <AddVariantGroup
          open={variantModal}
          setOpen={setVariantModal}
          productVariants={productVariants}
          setProductVariants={setProductVariants}
          isMobile={true}
        />
        <EditVariantGroup
          open={variantModal}
          setOpen={setVariantModal}
          variant={variant}
          setVariant={setVariant}
          productVariants={productVariants}
          setProductVariants={setProductVariants}
          isMobile={true}
        />
        <Dialog fullWidth maxWidth="sm" open={openFeaturedModal}>
          <DialogTitle
            style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
          >
            <IconButton onClick={() => setOpenFeaturedModal(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: "0 20px 40px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "auto",
                width: "fit-content"
              }}
            >
              <ErrorIcon
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "#ee2545",
                  fontSize: 100
                }}
              />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: "#002c42",
                  padding: "10px 0"
                }}
              >
                You can only select a maximum of 3 products to feature on your
                online shop
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </Dialog>
      {/* <ExitShopCreationModal
        open={openExitCreation}
        setOpen={setOpenExitCreation}
        onClose={onClose}
      /> */}
    </div>
  );
};
export default MobileAddProducts;
