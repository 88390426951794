import { ActionTypes } from "./Actions";
import lodash from 'lodash';
const initialState = {
  currentUser: null,
  usersList: [],
  shopsList: [],
  productsList: [],
  ordersList: [],
  listLength: 0, // will be used for displaying table item length
  dateFilter: {
    type: null,
    startDate: null,
    endDate: null
  },
  reports: {
    data: [],
    loading: false
  },
  dashboardData: "",
  menuActive: "marketing",
  loading: false,
  userLoading: false,
  modalLoading: false,
  error: null,
  alert: "",

  // MARKETPLACE
  app: "",
  apps: [],
  partner: "",
  partners: [],
  categories: [],
  subscriptions: [],

  // PROMO
  promoRecipients: [],

  logisticsData: "",
  logisticsBookings: [],
};

export default function adminDashboardReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_MENU_ACTIVE:
      return {
        ...state,
        menuActive: action.payload
      };
    // current user
    case ActionTypes.FETCHING_CURRENT_USER_DATA:
      return {
        ...state,
        userLoading: true
      };
    case ActionTypes.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        currentUser: {
          ...action.payload,
          user_roles: action.user_roles
        },
        error: null
      };
    case ActionTypes.FETCH_CURRENT_USER_FAILED:
      return {
        ...state,
        userLoading: false,
        error: action.payload,
        currentUser: {
          ...action.payload,
          user_roles: []
        }
      };
    // admin dashboard home
    case ActionTypes.FETCHING_ADMIN_DASHBOARD_DATA:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload,
        error: null
      };
    case ActionTypes.FETCH_FAILED:
      return {
        ...state,
        loading: false,
        dashboardData: {},
        error: action.payload
      };
    // for user list section
    case ActionTypes.USERS_FETCHING:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.USERS_FETCH_SUCCESS:
      const usersData = !action.is_csv && {
        usersList: action.payload.list,
        listLength: action.payload.length
      };
      return {
        ...state,
        ...usersData,
        loading: false,
        error: null
      };
    case ActionTypes.USERS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        usersList: [],
        error: action.payload
      };
    // Generate reports
    case ActionTypes.GENERATING_REPORT_TASK:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
        loading: false,
        error: null
      };
    case ActionTypes.GENERATE_REPORT_TASK_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          data: [action.payload, ...state.reports.data.slice(0,4)]
        },
        loading: false,
        error: null
      };
    case ActionTypes.FETCHING_LATEST_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
        loading: false,
        error: null
      };
    case ActionTypes.FETCH_LATEST_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          data: action.payload,
          loading: false,
        },
        loading: false,
        error: null
      };
    case ActionTypes.USER_SET_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case ActionTypes.USER_SET_PERMISSION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    // for fetching shops list
    case ActionTypes.SHOPS_FETCHING:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SHOPS_FETCH_SUCCESS:
      const shopsData = !action.is_csv && {
        shopsList: action.payload.list,
        listLength: action.payload.length
      };
      return {
        ...state,
        ...shopsData,
        loading: false,
        error: null
      };
    case ActionTypes.SHOPS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    // fetch shop
    case ActionTypes.FETCH_SHOP:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCH_SHOP_SUCCESS:
      let existing = false;
      let shops = [];
      if (state.shopsList.length) {
        shops = state.shopsList.map((o) => {
          if (o.pk === action.payload.pk) {
            existing = true;
            return action.payload
          } else return o
        })
      }
      if (!existing) shops = [...shops, action.payload]
      return {
        ...state,
        shopsList: shops,
        loading: false,
        error: null
      };
    // for deleting shop
    case ActionTypes.SHOP_DELETE:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SHOP_DELETE_SUCCESS:
      console.log("REDUCER", action.uuid)
      shops = state.shopsList.filter((shop) => shop.pk !== action.uuid)
      console.log("REDUCER", shops)
      return {
        ...state,
        shopsList: shops,
        loading: false,
        error: null
      };
    // for fetching products list
    case ActionTypes.PRODUCTS_FETCHING:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.PRODUCTS_FETCH_SUCCESS:
      return {
        ...state,
        productsList: action.payload,
        loading: false,
        error: null
      };
    case ActionTypes.PRODUCTS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    // for admin user signup
    case ActionTypes.ADMIN_SIGNING_UP:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.ADMIN_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case ActionTypes.ADMIN_SIGNUP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    // date filter
    case ActionTypes.SET_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.payload
      };
    // for fetching products list
    case ActionTypes.ORDERS_FETCHING:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.ORDERS_FETCH_SUCCESS:
      const ordersData = !action.is_csv && {
        ordersList: action.payload.list,
        listLength: action.payload.length
      };
      return {
        ...state,
        ...ordersData,
        loading: false,
        error: null
      };
    case ActionTypes.ORDERS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    // MARKETPLACE
    // Create App
    case ActionTypes.CREATE_APP:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.CREATE_APP_SUCCESS:
      return {
        ...state,
        app: action.payload,
        loading: false,
        error: null
      };
    // DELETE App
    case ActionTypes.DELETE_APP:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.DELETE_APP_SUCCESS:
      const apps = state.apps.filter(app => app.id !== action.payload);
      return {
        ...state,
        apps,
        loading: false,
        error: null
      };
    // App list
    case ActionTypes.FETCHING_APPS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCHING_APPS_SUCCESS:
      return {
        ...state,
        loading: false,
        apps: action.payload,
        error: null
      };
    // App detailed
    case ActionTypes.FETCHING_APP:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCHING_APP_SUCCESS:
      return {
        ...state,
        app: action.payload,
        loading: false,
        error: null
      };
    // Partner list
    case ActionTypes.FETCHING_PARTNERS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCHING_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        partner: action.payload,
        error: null
      };
    case ActionTypes.FETCHING_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: action.payload,
        error: null
      };
    // Partner create
    case ActionTypes.CREATE_PARTNER:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.CREATE_PARTNER_SUCCESS:
      let partnersState = state.partners;
      partnersState.push(action.payload);
      return {
        ...state,
        partners: partnersState,
        loading: false,
        error: null
      };
    // Partner edit
    case ActionTypes.PATCH_PARTNER:
      return {
        ...state,
        modalLoading: true
      };
    case ActionTypes.PATCH_PARTNER_SUCCESS:
      const partners = state.partners.map(data => {
        if (data.id === action.payload.id) {
          return action.payload;
        } else {
          return data;
        }
      });
      return {
        ...state,
        partners,
        modalLoading: false,
        error: null
      };
    // Partner edit
    case ActionTypes.DELETE_PARTNER:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.DELETE_PARTNER_SUCCESS:
      const partners_remaining = state.partners.filter(
        data => action.payload !== data.id
      );
      return {
        ...state,
        partners: partners_remaining,
        loading: false,
        error: null
      };
    // Partner and Category list
    case ActionTypes.FETCHING_PARTNER_AND_CATEGORIES:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCHING_PARTNER_AND_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
        partners: action.payload.partners,
        error: null
      };

    // Category create
    case ActionTypes.CREATE_CATEGORY:
      return {
        ...state,
        modalLoading: true
      };
    case ActionTypes.CREATE_CATEGORY_SUCCESS:
      let categoriesState = state.categories;
      categoriesState.push(action.payload);
      return {
        ...state,
        categories: categoriesState,
        modalLoading: false,
        error: null
      };

    // Partner list
    case ActionTypes.UPLOAD_S3:
      return {
        ...state,
        modalLoading: true
      };
    case ActionTypes.UPLOAD_S3_SUCCESS:
      return {
        ...state,
        modalLoading: false,
        error: null
      };

    // Subscriptions list
    case ActionTypes.FETCHING_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCHING_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: action.payload,
        error: null
      };
    // Patch Subscription
    case ActionTypes.PATCH_SUBSCRIPTIONS:
      return {
        ...state,
        modalLoading: true
      };
    case ActionTypes.PATCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        modalLoading: false,
        error: null
      };

    // setting alert
    case ActionTypes.SET_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          ...action.payload
        }
      };
    
    // PROMO
    case ActionTypes.GET_PROMO_RECIPIENTS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_PROMO_RECIPIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        promoRecipients: action.payload
      };

    // logistics home
    case ActionTypes.FETCHING_LOGISTICS_HOME:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCHING_LOGISTICS_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        logisticsData: action.payload,
        error: null
      };
    case ActionTypes.FETCHING_LOGISTICS_HOME_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    // logistics bookings
    case ActionTypes.FETCH_LOGISTICS_BOOKINGS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCH_LOGISTICS_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        logisticsBookings: action.payload,
        error: null
      };
    case ActionTypes.FETCH_LOGISTICS_BOOKINGS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ActionTypes.FETCH_LOGISTICS_BOOKING:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCH_LOGISTICS_BOOKING_SUCCESS:
      existing = false;
      let bookings = state.logisticsBookings.map((o) => {
        if (o.id === action.payload.id) {
          existing = true;
          return action.payload
        } else return o
      })
      if (!existing) bookings = [...bookings, action.payload]
      return {
        ...state,
        loading: false,
        logisticsBookings: bookings,
        error: null
      };
    case ActionTypes.FETCH_LOGISTICS_BOOKING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    // setting request status
    case ActionTypes.REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return initialState;
  }
}
