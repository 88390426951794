import React from "react";
import { withStyles } from "@material-ui/core/styles";


const useStyles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    padding: "1.5em",
    border: "1px solid #1E4B83",
    backgroundColor: "#E6EAF0",
    borderRadius: 4
  },
});

class PackagesCard extends React.Component {
  
  render (){
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <p><strong>{this.props.title}</strong></p>
        <p>{this.props.description}</p>
      </div>
    )
  }
}

export default withStyles(useStyles)(PackagesCard);
