import React from "react";
import logo from "../../assets/images/sentro_vertical.png";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import SentroButton from "../common/sentroButton";
import Text from "../../common/text";
import CancelIcon from "@material-ui/icons/Cancel";
import { verifyCode, resendCode } from "./forgotPasswordAction";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import SentroLoading from "../common/loading";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
const LinkExpired = props => {
  let email = localStorage.getItem("CHANGE_PASSWORD_EMAIL");
  const dispatch = useDispatch();
  let param = props.location.search;
  let token = param.replace("?token=", "");
  React.useEffect(() => {
    dispatch(verifyCode({ token: token }));
  }, []);

  const forgotPasswordStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },

    banner: {
      width: "60vw",
      height: "100vh",
      background: "linear-gradient(286.57deg, #FFA78D -39.83%, #C24A39 99.47%)",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },

    ForgotPassword: {
      height: "100vh",
      overflow: "auto",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },

    box1: {
      marginTop: "35vh",
      [theme.breakpoints.down("sm")]: {
        padding: "8vh 5vh 0 "
      }
    },

    box2: {
      padding: "10vh 5vh 0 ",
      [theme.breakpoints.down("xs")]: {
        padding: "6vh 2vw"
      }
    },
    errorIcon: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        display: "flex"
      }
    }
  }));

  const classes = forgotPasswordStyles();
  let loading = useSelector(state => state.forgotPassword.loading);
  let resendSuccess = useSelector(state => state.forgotPassword.resendSuccess);
  return (
    <div className={classes.root}>
      <SentroLoading open={loading} />
      <Box className={classes.banner}>
        <Container>
          <Box className={classes.box1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={logo} style={{ width: 150 }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "2vh" }}
                textType="h2"
                content="Create your online shop here."
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#fff",
                  position: "absolute",
                  bottom: 25
                }}
              >
                This works best in the latest Google desktop browser.
              </Typography>
            </div>
          </Box>
        </Container>
      </Box>
      <Box className={classes.ForgotPassword}>
        <Container>
          <Box className={classes.box2}>
            <Text textType="h3" content="Reset Password" />

            <Box>
              {resendSuccess ? (
                <Alert
                  id="alert_message"
                  closable
                  message="Email sent. Please check your inbox."
                  type="success"
                  style={{ marginTop: "1vh", marginBottom: "2vh" }}
                />
              ) : (
                ""
              )}
              <Box style={{ marginTop: "4vh", display: "flex" }}>
                <div className={classes.errorIcon}>
                  <CancelIcon style={{ color: "#E24C4C", fontSize: 90 }} />
                </div>
                <Typography
                  style={{
                    fontSize: 16,
                    padding: 10,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  The Password reset request is already expired. Please make
                  another request.
                </Typography>
              </Box>
              <Box style={{ marginTop: "4vh" }}>
                <SentroButton
                  id="resend_button"
                  onClick={() => dispatch(resendCode(email))}
                  variant="contained"
                  fullWidth
                  label="Request a new password reset"
                  style={{ margin: 0 }}
                />
              </Box>
            </Box>

            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  fontSize: 13,
                  textAlign: "center",
                  color: "#2B2D33",
                  position: "absolute",
                  bottom: 35
                }}
              >
                Need help?
              </Typography>

              <div style={{ position: "absolute", bottom: 5, display: "flex" }}>
                <MailOutlineIcon style={{ color: "#828894", width: 17 }} />
                <a
                  href="mailto:support@sentro.ph"
                  style={{
                    color: "#F97663",
                    fontSize: 13,
                    cursor: "pointer",
                    fontWeight: 600,
                    marginLeft: 10
                  }}
                >
                  support@sentro.ph
                </a>
              </div>
            </div>
          </Box>
        </Container>
      </Box>
    </div>
  );
};
export default LinkExpired;
