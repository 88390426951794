import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import ACL from "../common/utilities/ACL"
import Action from "./Redux/Actions"
import AdminDashboardLayout from "./layout"
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Path from "../../Paths";
import apiConfig from "../../common/apiConfig";
import TableExport from '../common/utilities/TableExport';
import Skeleton from '@material-ui/lab/Skeleton';
import { stringify_get_params } from "../common/utilities/DateFilter"

function descendingComparator(a, b, orderBy) {
if (b[orderBy] < a[orderBy]) {
	return -1;
}
if (b[orderBy] > a[orderBy]) {
	return 1;
}
return 0;
}

function getComparator(order, orderBy) {
return order === 'desc'
	? (a, b) => descendingComparator(a, b, orderBy)
	: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: 'store_name', numeric: false, disablePadding: true, label: 'Shop Name' },
	{ id: 'owner_email', numeric: false, disablePadding: false, label: 'Shop Owner' },
	{ id: 'recipient', numeric: false, disablePadding: false, label: 'Recipient' },
	{ id: 'ref_id', numeric: false, disablePadding: false, label: 'Order Id' },
	{ id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
				<TableCell
					className={classes.tableHead}
					key={headCell.id}
					align={headCell.numeric ? 'right' : 'left'}
					style={{ paddingLeft: "1em" }}
					sortDirection={orderBy === headCell.id ? order : false}
				>
					<TableSortLabel
						active={orderBy === headCell.id}
						direction={orderBy === headCell.id ? order : 'asc'}
						onClick={createSortHandler(headCell.id)}
						hideSortIcon={true}
						active={false}
					>
						<span>{headCell.label}</span>
						<span className={classes.headIconWrapper}>
							<ArrowDropUpIcon className={classes.headIconUp} />
							<ArrowDropUpIcon className={classes.headIconDown} />
						</span>
					</TableSortLabel>
				</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	tableContainer: {
		overflowX: "auto"
	},
	table: {
		// minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	noResult: {
		width: "100%",
		display: "block",
		textAlign: "center",
		padding: "2em"
	},
	tableHead: {
		backgroundColor: "#F97663",
		color: "white !important",
		"&:hover": {
			color: 'white !important'
		},
		"&:active": {
			color: 'white !important'
		},
	},
	headIconWrapper: {
		direction: "rtl", 
		flexGrow: 1, 
		minWidth: 65, 
		display: "flex", 
		height: 20
	}, 
	headIconUp: {
		transform: "rotate(0deg)",
		position: "absolute",
		transform: "translateY(-8px)"
	},
	headIconDown: {
		transform: "rotate(180deg)",
		position: "absolute"
	},
}));

function EnhancedTable(props) {
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [data, setData] = React.useState(props.productsList);

	useEffect(() => {
		setData(props.productsList);
	  }, [props.productsList]);

	useEffect(() => {
		const rowsCount = props.rowsPerPage ? props.rowsPerPage : 5
		setRowsPerPage(rowsCount)
	}, [props.rowsPerPage]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Fragment>
			<div className={classes.tableContainer}>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size={'medium'}
					aria-label="enhanced table"
					id="table"
				>
					<EnhancedTableHead
						classes={classes}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={data.length}
					/>
					<TableBody>
						{stableSort(data, getComparator(order, orderBy))
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((row, index) => {
							const labelId = `enhanced-table-checkbox-${index}`;
							return (
								<TableRow
									key={row.name}
									hover
									// onClick={(event) => handleClick(event, row.first_name + " " + row.last_name)}
									role="checkbox"
									tabIndex={-1}
								>
									<TableCell component="th" id={labelId} scope="row" style={{ marginLeft: "1em"}}>
										<a href={`https://sentro.ph/shop/${row.store_site}`}
                                        target="_blank" rel="noopener noreferrer">{row.store_name}</a>
									</TableCell>
									<TableCell align="left">{row.owner_email}</TableCell>
									<TableCell align="left">{row.recipient}</TableCell>
									<TableCell align="left">{row.ref_id}</TableCell>
									<TableCell align="left">{row.amount}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				{
					props.loading ? 
					<>
					{
						[1, 2, 3].map((index) => (
							<div style={{ padding: "0px 15px 0"}} key={index}>
								<Skeleton width="100%" height={60} />
							</div>
						))
					}
					</>
					:
					``
				}
				{
					!props.loading && !data.length ?
					<div style={{ height: "40vh", width: "100%", overflow: "hidden" }}>
						<div style={{ paddingTop: "4em" }}>
							<div style={{
								display: "flex",
								justifyContent: "center"
							}}>
								<img
									style={{width: "7em", height: "6em"}}
									src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/SentroBlackandWhite.png"
								/>
							</div>
							<div style={{
									display: "flex",
									justifyContent: "center",
									marginTop: 40,
									fontSize: 18,
									fontWeight: 600,
									color: "#b8bcc3",
									padding: "0 3em",
									textAlign: "center"
							}}>
								Nothing to Show
							</div>
						</div>
					</div>
					:
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, data.length]}
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				}
			</div>
		</Fragment>
	);
}

const pageStyles = theme => ({
	paper: {
		overflow: "hidden",
		padding: theme.spacing(2),
		color: theme.palette.text.secondary,
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(1),
		},
	},
	card: {
		backgroundColor: "white",
		padding: 0
	},
	cardHeader: {
		fontSize: "28px",
		marginBottom: "0.5em",
		textAlign: "left",
		color: "#F97663",
		fontWeight: 600
	},
	textDivider: {
		fontSize: "16px",
		padding: "1.5em 0",
		marginBottom: 0
	},
	miniCardHeader: {
		fontSize: "16px",
		textAlign: 'left',
		color: "#F97663",
		paddingBottom: "0.5em",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
		},
	},
	miniCardBody: {
		fontSize: "28px",
		weight: "bold",
		textAlign: 'left',
		color: theme.palette.text.primary,
	},
	lineChart: {
		paddingLeft: "auto",
		paddingRight: "auto",
		paddingTop: "2em",
		position: "relative",
		height: "400px",
		width: "100%",
		display: "block"
	},
	pageTitle: {
		fontSize: 26,
		fontWeight: 600,
		marginBottom: 0,
		color: "#002C42",
		flexGrow: 1,
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	pageHeaderButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
	},
	searchBar: {
		background: "white",
		maxWidth: "240px",
		borderRadius: "4px",
		color: "gray",
		fontSize: 12,
		flexGrow: 0,
		[theme.breakpoints.down("xs")]: {
			flexGrow: 1,
			marginTop: "1em",
			maxWidth: "100%",
			width: "100%"
		},
	},
	excelExportButton: {
		height: 35,
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			marginTop: "1em",
			flex: 1
		},
		border: "solid 1px #F97663",
		background: " #F97663",
		borderRadius: 4,
		marginRight: 10,
		color: "white",
		transition: "all 0.3s linear",
		"&:hover": {
			background: "#f96a63"
		},
		"&:focus": {
			outline: 0
		},
		boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
	},
	headerActionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		},
	},
	searchInputWrapper: {
		display: "flex"
	},
	searchInput: {
		flexGrow: 1
	},
	tableWrapper: {
		overflowX: "auto"
	},
	primaryColor: {
		color: theme.palette.text.primary
	},
	dFlex: {
		display: "flex"
	}
});

class Promos extends React.Component {

	componentDidMount = async () => {
		const { dispatch, adminDashboard } = this.props
		const query_params = stringify_get_params(adminDashboard.dateFilter)
        const data = await dispatch(Action.getPromoRecipients())
        localStorage.setItem("adminMenuActive", 'Promo Recipients');
    }
	
	render (){
		const { classes, adminDashboard, history } = this.props
		return (
            <AdminDashboardLayout 
            // onDateFilterCallback={this.onDateFilterCallback}
            >
				<ACL roles={["admin", "superuser"]} redirect_to="/login">
					<div style={{
						display: "flex", 
						flexWrap: "wrap",
						alignItems: "center",
						marginBottom: "1.5em",
						padding: "0 1.5em"
					}}>
						<div className={classes.pageTitle}>
							Promo Recipients
						</div>
						{/* <div className={classes.headerActionsWrapper}>
							{
								filteredList ? 
								<TableExport
									class={classes.excelExportButton}
									filename="Sentro - Products"
									sheet="Products"
									table={<EnhancedTable productsList={filteredList} rowsPerPage={filteredList.length }/>}
								/>
								:
								null
							}
							<OutlinedInput
								className={classes.searchBar}
								id="outlined-adornment-weight"
								onChange={(event) => this.onSearch(event)}
								startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
								aria-describedby="outlined-weight-helper-text"
								inputProps={{
								'aria-label': 'weight',
								}}
								labelWidth={0}
								margin="dense"
								placeholder="Type here to search"
							/>
						</div> */}
					</div>
					<div style={{ padding: "0 1.5em 1em"}}>
						<Paper className={classes.card}>
							<EnhancedTable productsList={adminDashboard.promoRecipients} loading={adminDashboard.loading} />
						</Paper>
					</div>
				</ACL>
			</AdminDashboardLayout>
		);
	}
}

const mapStateToProps = state => ({ adminDashboard }) => ({ adminDashboard });
export default connect(mapStateToProps)(withStyles(pageStyles)(Promos))
