import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  IconButton,
  Slide
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import Form from "./form";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoreInfo,
  ReduxInitializeFormActions,
  patchStore
} from "../../../websiteBuilderActions";
import { showAlert } from "../../../../common/commonActions";
import history from "../../../../../store/history";
import Path from "../../../../../Paths";
import SpinnerLoading from "../../../../common/spinnerLoading";
import apiConfig from "../../../../../common/apiConfig";

const Transition = props => <Slide direction="up" {...props} />;

export default function QuickOrderSettings(props) {
  const useStyles = makeStyles(themes => ({
    root: {
      paddingLeft: "10vw",
      paddingRight: "10vw",
      paddingBottom: "10vh"
    },
    form: {
      backgroundColor: "#fff",
      border: "1px solid #d4d5d8",
      borderRadius: 4
    },
    actionBtns: {
      backgroundColor: "#fff",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: "0px -8px 24px rgba(0, 0, 0, 0.12)",
      borderRadius: "8px 8px 0px 0px"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const storeInfo = useSelector(state => state.webBuilder.storeInfo);
  const values = useSelector(state => state.form.quick_order_settings?.values);
  const loading = useSelector(state => state.webBuilder.loading);
  React.useEffect(() => {
    dispatch(getStoreInfo());
  }, []);

  React.useEffect(() => {
    if (storeInfo) {
      dispatch(
        ReduxInitializeFormActions.initializeQuickOrderSettingsData({
          write_up: storeInfo.write_up,
          quick_order_cover_image: {
            cropped_image: storeInfo.quick_order_cover_image,
            raw_image: storeInfo.quick_order_cover_image
          },
          product_orientation: storeInfo.product_orientation
        })
      );
    }
  }, [storeInfo]);
  const handleSave = async (gotoDeliverySettings = false) => {
    const params =
      typeof values?.quick_order_cover_image.cropped_image === "object"
        ? {
            write_up: values?.write_up,
            quick_order_cover_image:
              values?.quick_order_cover_image.cropped_image,
            product_orientation: values?.product_orientation
          }
        : {
            write_up: values?.write_up,
            product_orientation: values?.product_orientation
          };
    const res = await dispatch(patchStore("styles", storeInfo.site, params));
    console.log(res);
    try {
      if (res.status === 200) {
        history.push(
          gotoDeliverySettings ? Path.DELIVERY_SETTINGS : Path.DASHBOARD
        );
        dispatch(
          showAlert({
            type: "success",
            message: "Changes saved!"
          })
        );
      }
    } catch (e) {
      console.log(e);
      window.scrollTo(0, 0);
    }
  };

  const action_btns = (
    <div style={{ padding: isMobile ? 24 : 36 }}>
      <Button
        disableElevation
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => handleSave(false)}
      >
        {loading ? <SpinnerLoading /> : ""}
        Save changes
      </Button>
      <Button
        fullWidth
        className="mt-2"
        color="primary"
        variant="outlined"
        onClick={() => {
          handleSave(false);
          window.open(`${apiConfig.localHost}order/${storeInfo.site}`);
        }}
      >
        Preview order form
      </Button>
    </div>
  );
  return (
    <>
      {isMobile ? (
        <Dialog fullScreen open={true} TransitionComponent={Transition}>
          <div className="position-relative">
            <div
              className="p-3 bg-white d-flex align-items-center border-bottom position-fixed"
              style={{ left: 0, right: 0, top: 0, zIndex: 1 }}
            >
              <IconButton
                style={{ zIndex: 1 }}
                onClick={() => history.push(Path.DASHBOARD)}
              >
                <ArrowBack color="primary" />
              </IconButton>
              <div
                className="position-absolute w-100 text-center"
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: "#002c42",
                  right: 0,
                  left: 0
                }}
              >
                Quick order form
              </div>
            </div>
            <div style={{ backgroundColor: "#fafafa", padding: "10vh 0 24vh" }}>
              <Form gotoDeliverySettings={handleSave} />
            </div>
            <div className={classes.actionBtns}>{action_btns}</div>
          </div>
        </Dialog>
      ) : (
        <div className={classes.root}>
          <div className="d-flex align-items-center justify-content-between position-relative">
            <Button
              variant="text"
              color="primary"
              style={{ zIndex: 1 }}
              startIcon={<ArrowBack />}
              onClick={() => history.push(Path.DASHBOARD)}
            >
              Back
            </Button>
            <div
              className="text-center w-100 position-absolute"
              style={{ fontSize: 28, fontWeight: 600, color: "#002c42" }}
            >
              Quick order form
            </div>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ height: 40 }}
              onClick={() => {
                handleSave(false);
                window.open(`${apiConfig.localHost}order/${storeInfo.site}`);
              }}
            >
              Preview order form
            </Button>
          </div>
          <div className={`${classes.form} mt-4`}>
            <Form gotoDeliverySettings={handleSave} />
            {action_btns}
          </div>
        </div>
      )}
    </>
  );
}
