import Services from "./Services";

export const ActionTypes = {
	SET_MENU_ACTIVE: "SET_MENU_ACTIVE",
	// getting current user
	FETCHING_CURRENT_USER_DATA: "FETCHING_CURRENT_USER_DATA",
	FETCH_CURRENT_USER_SUCCESS: "FETCH_CURRENT_USER_SUCCESS",
	FETCH_CURRENT_USER_FAILED: "FETCH_CURRENT_USER_FAILED",
	// admin dashboard home
	FETCHING_ADMIN_DASHBOARD_DATA: "FETCH_ADMIN_DASHBOARD_DATA",
	FETCH_SUCCESS: "FETCH_SUCCESS",
	FETCH_FAILED: "FETCH_FAILED",
	// for user list section
	USERS_FETCHING: "FETCHING_ALL_USERS",
	USERS_FETCH_SUCCESS: "USERS_FETCH_SUCCESS",
	USERS_FETCH_FAILED: "USERS_FETCH_FAILED",
	// reports
	FETCHING_LATEST_REPORTS: "FETCHING_LATEST_REPORTS",
	FETCH_LATEST_REPORTS_SUCCESS: "FETCH_LATEST_REPORTS_SUCCESS",
	GENERATING_REPORT_TASK: "GENERATING_REPORT_TASK",
	GENERATE_REPORT_TASK_SUCCESS: "GENERATE_REPORT_TASK_SUCCESS",
	// for setting user permission
	USER_SETTING_PERMISSION: "USER_SETTING_PERMISSION",
	USER_SET_PERMISSION_SUCCESS: "USER_SET_PERMISSION_SUCCESS",
	USER_SET_PERMISSION_FAILED: "USER_SET_PERMISSION_FAILED",
	// for shop list section
	SHOPS_FETCHING: "SHOPS_FETCHING",
	SHOPS_FETCH_SUCCESS: "SHOPS_FETCH_SUCCESS",
	SHOPS_FETCH_FAILED: "SHOPS_FETCH_FAILED",
	// fetch shop
	FETCH_SHOP: "FETCH_SHOP",
	FETCH_SHOP_SUCCESS: "FETCH_SHOP_SUCCESS",
	// Delete shop
	SHOP_DELETE: "SHOP_DELETE",
	SHOP_DELETE_SUCCESS: "SHOP_DELETE_SUCCESS",
	// for products list section
	PRODUCTS_FETCHING: "PRODUCTS_FETCHING",
	PRODUCTS_FETCH_SUCCESS: "PRODUCTS_FETCH_SUCCESS",
	PRODUCTS_FETCH_FAILED: "PRODUCTS_FETCH_FAILED",
	// for adding admin users
	ADMIN_SIGNING_UP: "ADMIN_SIGNING_UP",
	ADMIN_SIGNUP_SUCCESS: "ADMIN_SIGNUP_SUCCESS",
	ADMIN_SIGNUP_FAILED: "ADMIN_SIGNUP_FAILED",
	// date filter
	SET_DATE_FILTER: "SET_DATE_FILTER",
	// for orders list section
	ORDERS_FETCHING: "ORDERS_FETCHING",
	ORDERS_FETCH_SUCCESS: "ORDERS_FETCH_SUCCESS",
	ORDERS_FETCH_FAILED: "ORDERS_FETCH_FAILED",

	// MARKETPLACE
	// Create App
	CREATE_APP: "CREATE_APP",
	CREATE_APP_SUCCESS: "CREATE_APP_SUCCESS",
	// Delete App
	DELETE_APP: "DELETE_APP",
	DELETE_APP_SUCCESS: "DELETE_APP_SUCCESS",
	// App list
	FETCHING_APPS: "FETCHING_APPS",
	FETCHING_APPS_SUCCESS: "FETCHING_APPS_SUCCESS",
	// App detailed
	FETCHING_APP: "FETCHING_APP",
	FETCHING_APP_SUCCESS: "FETCHING_APP_SUCCESS",
	// Partner list
	FETCHING_PARTNERS: "FETCHING_PARTNERS",
	FETCHING_PARTNER_SUCCESS: "FETCHING_PARTNER_SUCCESS",
	FETCHING_PARTNERS_SUCCESS: "FETCHING_PARTNERS_SUCCESS",
	// Partner create
	CREATE_PARTNER: "CREATE_PARTNER",
	CREATE_PARTNER_SUCCESS: "CREATE_PARTNER_SUCCESS",
	// Patch Partner
	PATCH_PARTNER: "PATCH_PARTNER",
	PATCH_PARTNER_SUCCESS: "PATCH_PARTNER_SUCCESS",
	// Delete Partner
	DELETE_PARTNER: "DELETE_PARTNER",
	DELETE_PARTNER_SUCCESS: "DELETE_PARTNER_SUCCESS",
	// Fetching partners and categories
	FETCHING_PARTNER_AND_CATEGORIES: "FETCHING_PARTNER_AND_CATEGORIES",
	FETCHING_PARTNER_AND_CATEGORIES_SUCCESS: "FETCHING_PARTNER_AND_CATEGORIES_SUCCESS",
	// Category create
	CREATE_CATEGORY: "CREATE_CATEGORY",
    CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
	// Upload S3
	UPLOAD_S3: "UPLOAD_S3",
    UPLOAD_S3_SUCCESS: "UPLOAD_S3_SUCCESS",
	// Subscription list
	FETCHING_SUBSCRIPTIONS: "FETCHING_SUBSCRIPTIONS",
	FETCHING_SUBSCRIPTIONS_SUCCESS: "FETCHING_SUBSCRIPTIONS_SUCCESS",
	// Patch Subscription
	PATCH_SUBSCRIPTIONS: "PATCH_SUBSCRIPTIONS",
    PATCH_SUBSCRIPTIONS_SUCCESS: "PATCH_SUBSCRIPTIONS_SUCCESS",
    

    // PROMO
    GET_PROMO_RECIPIENTS: "GET_PROMO_RECIPIENTS",
    GET_PROMO_RECIPIENTS_SUCCESS: "GET_PROMO_RECIPIENTS_SUCCESS",


	// LOGISTICS
	FETCHING_LOGISTICS_HOME: "FETCHING_LOGISTICS_HOME",
	FETCHING_LOGISTICS_HOME_SUCCESS: "FETCHING_LOGISTICS_HOME_SUCCESS",
    FETCHING_LOGISTICS_HOME_FAILED: "FETCHING_LOGISTICS_HOME_FAILED",
    FETCH_LOGISTICS_BOOKINGS: "FETCH_LOGISTICS_BOOKINGS",
	FETCH_LOGISTICS_BOOKINGS_SUCCESS: "FETCH_LOGISTICS_BOOKINGS_SUCCESS",
    FETCH_LOGISTICS_BOOKINGS_FAILED: "FETCH_LOGISTICS_BOOKINGS_FAILED",
    FETCH_LOGISTICS_BOOKING: "FETCH_LOGISTICS_BOOKING",
	FETCH_LOGISTICS_BOOKING_SUCCESS: "FETCH_LOGISTICS_BOOKING_SUCCESS",
    FETCH_LOGISTICS_BOOKING_FAILED: "FETCH_LOGISTICS_BOOKING_FAILED",

	// set alert
	SET_ALERT: "SET_ALERT",
	// Setting fetch status
	REQUEST_SUCCESS: "REQUEST_SUCCESS",
	REQUEST_FAILED: "REQUEST_FAILED"
};

function setUserRoles(current_user) {
	const { is_admin, is_superuser } = current_user
	let user_roles = []
	if (is_admin) {
		user_roles.push("admin");
	}
	if (is_superuser) {
		user_roles.push("superuser");
	}
	return user_roles
}

class Actions {

	static setMenuActive(menu){
		return dispatch => {
			dispatch({
				type: ActionTypes.SET_MENU_ACTIVE,
				payload: menu
			})
		}
	}

	static loadCurrentUser() {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_CURRENT_USER_DATA });
				let response = await Services.loadCurrentUser();
				if (response){
					dispatch({
						type: ActionTypes.FETCH_CURRENT_USER_SUCCESS,
						payload: response,
						user_roles: setUserRoles(response)
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.FETCH_CURRENT_USER_FAILED,
					payload: error
				});
			}
		};
	}

	static loadAdminDashboard(query_params) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_ADMIN_DASHBOARD_DATA });
				let response = await Services.getAdminDashboardData(query_params);
				if (response){
					dispatch({
						type: ActionTypes.FETCH_SUCCESS,
						payload: response
					});
				}
			} catch (error) {
				dispatch({
					type: ActionTypes.FETCH_FAILED,
					payload: error
				});
			}
		};
	}

	static fetchUsers(extra_url_param, is_csv=false) {
		return async dispatch => {
			try {
				if (is_csv) {
					dispatch({ type: ActionTypes.GENERATING_REPORT_TASK });
				} else {
					dispatch({ type: ActionTypes.USERS_FETCHING });
				}
				let response = await Services.fetchUsersList(extra_url_param);
				if (response){
					if (is_csv) {
						dispatch({
							type: ActionTypes.GENERATE_REPORT_TASK_SUCCESS,
							payload: response
						});
					} else {
						dispatch({
							type: ActionTypes.USERS_FETCH_SUCCESS,
							payload: response,
							is_csv
						});
					}
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.USERS_FETCH_FAILED,
					payload: error
				});
			}
		};
	}

	static deleteUser(email) {
		return async dispatch => {
			let data = await Services.deleteUser(email);
			return data
		};
	}

	static setUserPermission(email, is_admin) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.USER_SETTING_PERMISSION });
				let response = await Services.setUserPermission(email, is_admin);
				if (response){
					dispatch({
						type: ActionTypes.USER_SET_PERMISSION_SUCCESS,
						payload: response
					});
				}
			} catch (error) {
				dispatch({
					type: ActionTypes.USER_SET_PERMISSION_FAILED,
					payload: error
				});
			}
		};
	}

	static fetchShops(query_params, is_csv=false, filename="") {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.SHOPS_FETCHING });
				let response = await Services.fetchShopsList(query_params, is_csv, filename);
				if (response){
					dispatch({
						type: ActionTypes.SHOPS_FETCH_SUCCESS,
						payload: response,
						is_csv
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.SHOPS_FETCH_FAILED,
					payload: error
				});
			}
		};
	}

	static fetchShop(uuid) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCH_SHOP });
				const res = await Services.fetchShop(uuid);
				dispatch({
					type: ActionTypes.FETCH_SHOP_SUCCESS,
                    uuid,
                    payload: res
				});
				return true
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
				return false
			}
		};
	}

	static deleteShop(uuid) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.SHOP_DELETE });
				await Services.deleteShop(uuid);
				dispatch({
					type: ActionTypes.SHOP_DELETE_SUCCESS,
					uuid
				});
				return true
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
				return false
			}
		};
	}

	static fetchProducts(query_params) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.PRODUCTS_FETCHING });
				let response = await Services.fetchProductsList(query_params);
				if (response){
					dispatch({
						type: ActionTypes.PRODUCTS_FETCH_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.PRODUCTS_FETCH_FAILED,
					payload: error
				});
			}
		};
	}

	static userDetailMethod(method, id, data) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.ADMIN_SIGNING_UP });
				let response = await Services.userDetailMethod(method, id, data);
				if (response.status === 200){
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_SUCCESS,
						payload: response
					});
				} else {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_FAILED,
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.ADMIN_SIGNUP_FAILED,
					payload: error
				});
			}
		};
	}
	
	static fetchLatestReport(category) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_LATEST_REPORTS });
				let response = await Services.fetchLatestReport(category);
				dispatch({
					type: ActionTypes.FETCH_LATEST_REPORTS_SUCCESS,
					payload: response
				});
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		}
	}

	static signupAdminUser(data) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.ADMIN_SIGNING_UP });
				let response = await Services.signupAdminUser(data);
				if (response.status == 201) {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_SUCCESS,
						payload: response
					});
				} else {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_FAILED
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.ADMIN_SIGNUP_FAILED,
					payload: error
				});
			}
		};
	}

	static resendVerification(email) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.ADMIN_SIGNING_UP });
				let response = await Services.resendVerification(email);
				if (response.status == 200) {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_SUCCESS,
					});
				} else {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_FAILED
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.ADMIN_SIGNUP_FAILED,
					payload: error
				});
			}
		};
	}

	static nominatePasswordAdmin(method, get_params, password) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.ADMIN_SIGNING_UP });
				let response = await Services.nominatePasswordAdmin(method, get_params, password);
				if (response.status == 200) {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_SUCCESS,
					});
					if (method === "post"){
						sessionStorage.setItem("TOKEN", response.data.token)
					}
				} else {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_FAILED
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.ADMIN_SIGNUP_FAILED,
					payload: error
				});
			}
		};
	}

	static setDateFilter(filter) {
		return async dispatch => {
			dispatch({ 
				type: ActionTypes.SET_DATE_FILTER,
				payload: filter
			});
		}
	}

	static fetchOrders(query_params, is_csv=false, filename="") {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.ORDERS_FETCHING });
				let response = await Services.fetchOrdersList(query_params, is_csv, filename);
				if (response){
					dispatch({
						type: ActionTypes.ORDERS_FETCH_SUCCESS,
						payload: response,
						is_csv
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.ORDERS_FETCH_FAILED,
					payload: error
				});
			}
		};
	}

	static fetchBuxPaymentsList() {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.ORDERS_FETCHING });
				let response = await Services.fetchBuxPaymentsList();
				if (response){
					dispatch({
						type: ActionTypes.REQUEST_SUCCESS,
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.ORDERS_FETCH_FAILED,
					payload: error
				});
			}
		};
	}

	// MARKETPLACE
	static createApp(data) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.CREATE_APP });
				let response = await Services.createApp(data);
				if (response){
					dispatch({
						type: ActionTypes.CREATE_APP_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}
	static editApp(id, data) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.CREATE_APP });
				let response = await Services.editApp(id, data);
				if (response){
					dispatch({
						type: ActionTypes.CREATE_APP_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}
	static deleteApp(id) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.DELETE_APP });
				let response = await Services.deleteApp(id);
				if (response){
					dispatch({
						type: ActionTypes.DELETE_APP_SUCCESS,
						payload: id
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}
	static fetchApps(payload=false) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_APPS });
				let response = await Services.fetchApps(payload);
				if (response){
					dispatch({
						type: ActionTypes.FETCHING_APPS_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}
	static fetchApp(id, payload=false) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_APP });
				let response = await Services.fetchApp(id, payload);
				if (response){
					dispatch({
						type: ActionTypes.FETCHING_APP_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}
	static fetchPartners(query_params) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_PARTNERS });
				let response = await Services.fetchPartners(query_params);
				if (response){
					dispatch({
						type: ActionTypes.FETCHING_PARTNERS_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}
	static fetchPartner(query_params) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_PARTNERS });
				let response = await Services.fetchPartner(query_params);
				if (response){
					dispatch({
						type: ActionTypes.FETCHING_PARTNER_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	static createPartner(data) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.CREATE_PARTNER });
				let response = await Services.createPartner(data);
				if (response.status == 201) {
					dispatch({
						type: ActionTypes.CREATE_PARTNER_SUCCESS,
						payload: response.data
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	static nominatePasswordPartner(method, get_params, password) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.ADMIN_SIGNING_UP });
				let response = await Services.nominatePasswordPartner(method, get_params, password);
				if (response.status == 200) {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_SUCCESS,
					});
					if (method === "post"){
						sessionStorage.setItem("TOKEN", response.data.token)
					}
				} else {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_FAILED
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.ADMIN_SIGNUP_FAILED,
					payload: error
				});
			}
		};
	}

	static resendVerificationPartner(email) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.ADMIN_SIGNING_UP });
				let response = await Services.resendVerificationPartner(email);
				if (response.status == 200) {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_SUCCESS,
					});
				} else {
					dispatch({
						type: ActionTypes.ADMIN_SIGNUP_FAILED
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.ADMIN_SIGNUP_FAILED,
					payload: error
				});
			}
		};
	}

	static patchPartner(id, data) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.PATCH_PARTNER });
				let response = await Services.patchPartner(id, data);
				if (response.status == 201) {
					dispatch({
						type: ActionTypes.PATCH_PARTNER_SUCCESS,
						payload: response.data
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	static deletePartner(id) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.DELETE_PARTNER });
				let response = await Services.deletePartner(id);
				console.log("DELETE ACTION", response)
				if (response.status == 200 || response.status == 204) {
					dispatch({
						type: ActionTypes.DELETE_PARTNER_SUCCESS,
						payload: id
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	static fetchPartnersAndCategories() {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_PARTNER_AND_CATEGORIES });
				let partners = await Services.fetchPartners();
				let categories = await Services.fetchCategories();
				if (partners && categories){
					dispatch({
						type: ActionTypes.FETCHING_PARTNER_AND_CATEGORIES_SUCCESS,
						payload: {
							partners,
							categories
						}
					});
				}
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	// static fetchCategories() {
	// 	return async dispatch => {
	// 		try {
	// 			dispatch({ type: ActionTypes.FETCHING_CATEGORIES });
	// 			let response = await Services.fetchCategories();
	// 			if (response){
	// 				dispatch({
	// 					type: ActionTypes.FETCHING_CATEGORIES_SUCCESS,
	// 					payload: response
	// 				});
	// 			}
	// 			return response
	// 		} catch (error) {
	// 			dispatch({
	// 				type: ActionTypes.REQUEST_FAILED,
	// 				payload: error
	// 			});
	// 		}
	// 	};
	// }

	static createCategory(name) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.CREATE_CATEGORY });
				let response = await Services.createCategory({name});
				if (response){
					dispatch({
						type: ActionTypes.CREATE_CATEGORY_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}


	static uploadS3(file, path) {
		return async dispatch => {
			// try {
				dispatch({ type: ActionTypes.UPLOAD_S3 });
				let response = await Services.uploadS3(file, path);
				if (response){
					dispatch({
						type: ActionTypes.UPLOAD_S3_SUCCESS,
					});
				}
				return response
			// } catch (error) {
			// 	dispatch({
			// 		type: ActionTypes.REQUEST_FAILED,
			// 		payload: error
			// 	});
			// }
		};
	}

	static fetchSubscriptions(query_params) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_SUBSCRIPTIONS });
				let response = await Services.fetchSubscriptions(query_params);
				if (response){
					dispatch({
						type: ActionTypes.FETCHING_SUBSCRIPTIONS_SUCCESS,
						payload: response
					});
				}
				return response
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}


	static setAlert(payload) {
		return dispatch => {
			dispatch({
				type: ActionTypes.SET_ALERT,
				payload
			})
		}
	}

	static patchSubscriptions(id, data) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.PATCH_SUBSCRIPTIONS });
				let response = await Services.patchSubscriptions(id, data);
				if (response){
					dispatch({
						type: ActionTypes.PATCH_SUBSCRIPTIONS_SUCCESS,
						payload: response
					});
				}
				return true
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
	}

	static getPromoRecipients() {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.GET_PROMO_RECIPIENTS });
				let response = await Services.getPromoRecipients();
				if (response){
					dispatch({
						type: ActionTypes.GET_PROMO_RECIPIENTS_SUCCESS,
						payload: response
					});
				}
				return true
			} catch (error) {
				dispatch({
					type: ActionTypes.REQUEST_FAILED,
					payload: error
				});
			}
		};
    }
	static loadLogiscticsHome(query_params) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCHING_LOGISTICS_HOME });
				let response = await Services.getLogiscticsHomeData(query_params);
				if (response){
					dispatch({
						type: ActionTypes.FETCHING_LOGISTICS_HOME_SUCCESS,
						payload: response
					});
				}
			} catch (error) {
				dispatch({
					type: ActionTypes.FETCHING_LOGISTICS_HOME_FAILED,
					payload: error
				});
			}
		};
	}
	static fetchLogiscticsBookings(query_params) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCH_LOGISTICS_BOOKINGS });
				let response = await Services.fetchLogiscticsBookings(query_params);
				if (response){
					dispatch({
						type: ActionTypes.FETCH_LOGISTICS_BOOKINGS_SUCCESS,
						payload: response
					});
				}
			} catch (error) {
				dispatch({
					type: ActionTypes.FETCH_LOGISTICS_BOOKINGS_FAILED,
					payload: error
				});
			}
		};
	}
	static fetchLogiscticsBooking(id) {
		return async dispatch => {
			try {
				dispatch({ type: ActionTypes.FETCH_LOGISTICS_BOOKING });
				let response = await Services.fetchLogiscticsBooking(id);
				if (response){
					dispatch({
						type: ActionTypes.FETCH_LOGISTICS_BOOKING_SUCCESS,
						payload: response
					});
				}
			} catch (error) {
				dispatch({
					type: ActionTypes.FETCH_LOGISTICS_BOOKING_FAILED,
					payload: error
				});
			}
		};
	}
}

export default Actions;
