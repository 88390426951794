import React from "react";
import {
  Box,
  Container,
  Badge,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Paper
} from "@material-ui/core";
import ContentStyles from "./contentStyles";
import Text from "../../../common/text";
import icon1 from "../../../assets/images/icon1.png";
import icon2 from "../../../assets/images/icon2.png";
import Path from "../../../Paths";
import history from "../../../store/history";
import SentroButton from "../../common/sentroButton";
import Footer from "./footer";
import content from "./translation";
import { HomepageStyles, section1Images } from "./homepageStyles";
import ImageGallery from "react-image-gallery";
import section2Img from "../../../assets/images/marketing page assets/thatjuliemessenger.gif";
import feature1 from "../../../assets/images/marketing page assets/section3Img.jpg";
import feature2 from "../../../assets/images/marketing page assets/section4Img.jpg";
import feature3 from "../../../assets/images/marketing page assets/section5Img.jpg";
import feature4 from "../../../assets/images/marketing page assets/section6Img.jpg";
import logosMobile from "../../../assets/images/marketing page assets/logosDesktop2.png";
import { Carousel, WingBlank } from "antd-mobile";
const DesktopView = props => {
  const classes = ContentStyles();

  return (
    <Box className={classes.root}>
      <Container style={{ padding: "0 20px" }}>
        <Box className={classes.content1}>
          <Container className={classes.contentContainer}>
            <Box style={{ paddingTop: "15px" }}>
              <Text
                textType="contentH2"
                style={{ padding: "0 12vw" }}
                content={
                  content[props.language ? props.language : "English"]
                    .sectionHeader1
                }
              />

              <Box className={`${classes.content1Image} col-12`}>
                <div className={`text-center col-6`}>
                  <img src={icon1} style={{ width: 52 }} />
                  <Text
                    style={{ marginTop: 20 }}
                    textType="sh2"
                    content={
                      content[props.language ? props.language : "English"]
                        .leftContentTitle
                    }
                  />
                  <Text
                    style={{ marginTop: 20 }}
                    textType="p1"
                    content={
                      content[props.language ? props.language : "English"]
                        .leftContent
                    }
                  />
                </div>
                <div className={`text-center col-6`}>
                  <img src={icon2} style={{ width: 100 }} />
                  <Text
                    style={{ marginTop: 20 }}
                    textType="sh2"
                    content={
                      content[props.language ? props.language : "English"]
                        .rightContentTitle
                    }
                  />
                  <Text
                    style={{ marginTop: 20 }}
                    textType="p1"
                    content={
                      content[props.language ? props.language : "English"]
                        .rightContent
                    }
                  />
                </div>
              </Box>
            </Box>
          </Container>
        </Box>

        <Box className={classes.content2}>
          <Container className={classes.contentContainer}>
            <Box className={classes.content2Details}>
              <div className={classes.content2Title}>
                <Box className={classes.title}>
                  <Text
                    textType="contentH3"
                    content={
                      content[props.language ? props.language : "English"]
                        .contentTitle1
                    }
                  />
                </Box>
                <Text
                  style={{ marginTop: 20 }}
                  textType="p1"
                  content={
                    content[props.language ? props.language : "English"]
                      .contentBody1
                  }
                />
              </div>

              <div style={{ padding: "0 25px" }}>
                <img
                  src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/img1.png"
                  className={classes.content2Image}
                />
              </div>
            </Box>
          </Container>
        </Box>

        <Box className={classes.content2}>
          <Container className={classes.contentContainer}>
            <Box className={classes.content3Details}>
              <div style={{ padding: "0 25px" }}>
                <img
                  src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/img2.png"
                  className={classes.content3Image}
                />
              </div>
              <div className={classes.content3Title}>
                <Box className={classes.title}>
                  <Text
                    textType="contentH3"
                    content={
                      content[props.language ? props.language : "English"]
                        .contentTitle2
                    }
                  />
                </Box>
                <Text
                  style={{ marginTop: 20 }}
                  textType="p1"
                  content={
                    content[props.language ? props.language : "English"]
                      .contentBody2
                  }
                />
              </div>
            </Box>
          </Container>
        </Box>

        <Box className={classes.content2}>
          <Container className={classes.contentContainer}>
            <Box className={classes.content4Details}>
              <div className={classes.content4Title}>
                <Box className={classes.title}>
                  <Text
                    textType="contentH3"
                    content={
                      content[props.language ? props.language : "English"]
                        .contentTitle3
                    }
                  />
                </Box>
                <Text
                  style={{ marginTop: 20, width: "101%" }}
                  textType="p1"
                  content={
                    content[props.language ? props.language : "English"]
                      .contentBody3
                  }
                />
              </div>

              <div style={{ padding: "0 25px" }}>
                <img
                  src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/img3.png"
                  className={classes.content4Image}
                />
              </div>
            </Box>
          </Container>
        </Box>

        <Box className={classes.content2}>
          <Container className={classes.contentContainer}>
            <Box className={classes.content5Details}>
              <div style={{ padding: "0 25px" }}>
                <img
                  src="https://sentro-store-bucker.s3.us-east-2.amazonaws.com/img4.png"
                  className={classes.content5Image}
                />
              </div>
              <div className={classes.content5Title}>
                <Box className={classes.title}>
                  <Text
                    textType="contentH3"
                    content={
                      content[props.language ? props.language : "English"]
                        .contentTitle4
                    }
                  />
                </Box>
                <Text
                  style={{ marginTop: 20 }}
                  textType="p1"
                  content={
                    content[props.language ? props.language : "English"]
                      .contentBody4
                  }
                />
              </div>
            </Box>
          </Container>
        </Box>

        <Box className={classes.content4}>
          <Container className={classes.contentContainer}>
            <Box className={classes.collab}>
              <Text
                textType="contentH2"
                content={
                  content[props.language ? props.language : "English"]
                    .sectionHeader2
                }
                style={{
                  color: "#002C42",
                  textAlign: "center",
                  padding: "0 6em"
                }}
              />
              <Typography className={classes.contentSh2}>
                {
                  content[props.language ? props.language : "English"]
                    .sectionBody2
                }
              </Typography>
              <div className={classes.logosMargin}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <div className={classes.marketingLogos} />
                </div>
              </div>
            </Box>
          </Container>
        </Box>
      </Container>
      <Box className={classes.content3}>
        <Container className={classes.contentContainer}>
          <Box style={{ textAlign: "center" }} className={classes.lastContent}>
            <Text
              style={{ color: "#002C42", padding: "0 5em" }}
              textType="contentH1"
              content={
                content[props.language ? props.language : "English"]
                  .bottomSectionTitle
              }
            />

            <Text
              style={{ color: "#2B2D33" }}
              textType="contentSh3"
              content={
                content[props.language ? props.language : "English"]
                  .bottomSectionBody
              }
            />
            <Box style={{ marginTop: "3vh" }}>
              <Button
                color="primary"
                onClick={() => {
                  props.user
                    ? history.push(Path.DASHBOARD)
                    : history.push(Path.SIGN_UP);
                }}
                id="getStarted_button"
                className={classes.btnGetStarted}
                variant="contained"
              >
                <div style={{ display: "block" }}>
                  {
                    content[props.language ? props.language : "English"]
                      .bottomSectionButton
                  }
                  <br />
                  <div style={{ fontSize: 10 }}>
                    {props.language === "Filipino"
                      ? "Now na, siz! Ano pang hinihintay mo?"
                      : ""}
                  </div>
                </div>
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container>
        <div style={{ width: "100%", height: "70vh" }}>
          <Text
            textType="contentH2"
            content={"Take the next step for your business"}
            style={{
              color: "#002C42",
              textAlign: "center",
              padding: "0 6em"
            }}
          />
          <Typography
            className={classes.contentSh2}
            style={{ padding: "2vh 7em" }}
          >
            Turn your casual shop into an eCommerce website complete with
            payments and deliveries with Sentro, the Philippines’ first
            homegrown online shop builder.
          </Typography>
        </div>
      </Container>
      <Footer />
    </Box>
  );
};

const MobileView = props => {
  const { language } = props;
  const classes = HomepageStyles();

  return (
    <div>
      <div className={classes.section1}>
        <div className={classes.section1Header}>
          Used by more than 20,000 Filipino online sellers.
        </div>
        <div style={{ padding: "10px 20px 40px" }}>
          <ImageGallery
            slideInterval={5000}
            showNav={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={false}
            showBullets={true}
            autoPlay={true}
            additionalClass={classes.carousel}
            items={section1Images}
          />
        </div>
      </div>
      <div className={classes.section2}>
        <div className={classes.section2Header}>
          Chat to your customers directly from your shop!
        </div>
        <div className={classes.section2Subheader}>
          Link your Shop's Facebook Messenger page and embed it to your Sentro
          online shop
        </div>
        <div style={{ paddingTop: "2vh" }}>
          <img src={section2Img} style={{ width: "100%", height: "auto" }} />
        </div>
      </div>
      <div style={{ backgroundColor: "#fff" }}>
        <div className={classes.section3}>
          <img src={feature1} className={classes.featureImg} />
          <div style={{ marginTop: "2vh", padding: 20 }}>
            <div style={{ fontSize: 20, color: "#002c42", fontWeight: "bold" }}>
              {content[language ? language : "English"].contentTitle1}
            </div>
            <div style={{ fontSize: 14, color: "#54575f", marginTop: "2vh" }}>
              {content[language ? language : "English"].contentBody1}
            </div>
          </div>
        </div>
        <div className={classes.section3}>
          <img src={feature2} className={classes.featureImg} />
          <div style={{ marginTop: "2vh", padding: 20 }}>
            <div style={{ fontSize: 20, color: "#002c42", fontWeight: "bold" }}>
              {content[language ? language : "English"].contentTitle2}
            </div>
            <div style={{ fontSize: 14, color: "#54575f", marginTop: "2vh" }}>
              {content[language ? language : "English"].contentBody2}
            </div>
          </div>
        </div>
        <div className={classes.section3}>
          <img src={feature3} className={classes.featureImg} />
          <div style={{ marginTop: "2vh", padding: 20 }}>
            <div style={{ fontSize: 20, color: "#002c42", fontWeight: "bold" }}>
              {content[language ? language : "English"].contentTitle3}
            </div>
            <div style={{ fontSize: 14, color: "#54575f", marginTop: "2vh" }}>
              {content[language ? language : "English"].contentBody3}
            </div>
          </div>
        </div>
        <div className={classes.section3}>
          <img src={feature4} className={classes.featureImg} />
          <div style={{ marginTop: "2vh", padding: 20 }}>
            <div style={{ fontSize: 20, color: "#002c42", fontWeight: "bold" }}>
              {content[language ? language : "English"].contentTitle4}
            </div>
            <div style={{ fontSize: 14, color: "#54575f", marginTop: "2vh" }}>
              {content[language ? language : "English"].contentBody4}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "30px 20px 20px",
          backgroundColor: "#fff",
          textAlign: "center"
        }}
      >
        <div style={{ fontSize: 24, color: "#002c42", fontWeight: "bold" }}>
          {content[language ? language : "English"].sectionHeader2}
        </div>
        <div style={{ fontSize: 14, color: "#54575f", padding: "2vh 40px 0" }}>
          {content[language ? language : "English"].sectionBody2}
        </div>
        <div className="d-flex justify-content-center">
          <img src={logosMobile} className={classes.logos} />
        </div>
      </div>
      <div style={{ padding: "20px 0", backgroundColor: "#f8f8f8" }}>
        <WingBlank>
          <Carousel infinite autoplay={true} cellSpacing={10} slideWidth={0.8}>
            <Paper className={classes.paper}>
              <div style={{ fontSize: 12, color: "#54575f" }}>
                Ang galing nito! Gusto ko lang mag thank you kasi nagstart pa
                lang ako sa online business, at gusto ko ng website na pwede
                magcheckout customers, at user friendly. Syempre dahil bago pa
                lang, kung may free online shop builder dun tayo. I tried
                [Others] pero di sya maganda pang online shop. Wala din add to
                cart option. [Yung iba] may ads, at mahirap mag build. Then
                found this! Sobrang user friendly! Understandable na limited ang
                product variations kasi nga bago and free! Pero problem solver.
                Dami pang payment options lalo na sa mga walang gcash, online
                account sa bank at mas prefer ang cash transactions. Galing! In
                time kung saan lahat sinusubukan mag online business.
              </div>
              <div style={{ paddingTop: "3vh" }}>
                <div className={classes.owner}>-- JP Alindada</div>
                <a
                  href="https://thekofishop.sentro.ph"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.shop}
                >
                  thekofishop.sentro.ph
                </a>
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <div style={{ fontSize: 14, color: "#54575f" }}>
                " It's really difficult to venture into business during this
                pandemic, but it took me courage and a super maaasahang partner
                app like Sentro, face these challenges. I was really amazed how
                I was able to build my own shop website in just a matter of
                minutes and laking ginhawa na embedded na rin dito ang online
                payments and deliveries. Less hassle and safer transactions for
                my customers! Nakatagpo talaga ako ng kaagapay sa pag-asenso kay
                Sentro!
              </div>
              <div style={{ paddingTop: "3vh" }}>
                <div className={classes.owner}>-- Lester Verceles</div>
                <a
                  href="https://masamsamit.sentro.ph"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.shop}
                >
                  masamsamit.sentro.ph
                </a>
              </div>
            </Paper>
            <div style={{ display: "block" }}>
              <div>
                <Paper className={classes.paper}>
                  <div style={{ fontSize: 14, color: "#54575f" }}>
                    " Sentro gives our customers flexible and wide array of
                    payment methods. It is hassle-free and very convenient."
                  </div>
                  <div style={{ paddingTop: "1.4vh" }}>
                    <div className={classes.owner}>-- Rommel Dacpano</div>
                    <a
                      href="https://rainbowexpress.sentro.ph"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.shop}
                    >
                      rainbowexpress.sentro.ph
                    </a>
                  </div>
                </Paper>
              </div>
              <div style={{ marginTop: "2vh" }}>
                <Paper className={classes.paper}>
                  <div style={{ fontSize: 14, color: "#54575f" }}>
                    " Ok naman [yung Sentro]... may mga orders din but limited
                    pa for now. Bago pa lang din kasi kami."
                  </div>
                  <div style={{ paddingTop: "1.4vh" }}>
                    <div className={classes.owner}>-- Ralph</div>
                    <a
                      href="https://coffiquor.sentro.ph"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.shop}
                    >
                      coffiquor.sentro.ph
                    </a>
                  </div>
                </Paper>
              </div>
            </div>
          </Carousel>
        </WingBlank>
      </div>

      <div
        style={{ padding: 20, backgroundColor: "#fff", textAlign: "center" }}
      >
        <div
          style={{
            fontSize: 24,
            color: "#002c42",
            fontWeight: "bold"
          }}
        >
          {content[language ? language : "English"].bottomSectionTitle}
        </div>
        <div style={{ fontSize: 14, color: "#54575f", paddingTop: "2vh" }}>
          {content[language ? language : "English"].bottomSectionBody}
        </div>
        <Button
          color="primary"
          className={classes.getStarted}
          fullWidth
          variant="contained"
          onClick={() => {
            props.user
              ? history.push(Path.DASHBOARD)
              : history.push(Path.SIGN_UP);
          }}
        >
          SIGN UP
        </Button>
      </div>

      <div
        style={{ padding: 20, backgroundColor: "#fff", textAlign: "center" }}
      >
        <div
          style={{
            fontSize: 24,
            color: "#002c42",
            fontWeight: "bold"
          }}
        >
          Take the next step for your business
        </div>
        <div style={{ fontSize: 14, color: "#54575f", paddingTop: "2vh" }}>
          Turn your casual shop into an eCommerce website complete with payments
          and deliveries with Sentro, the Philippines’ first homegrown online
          shop builder.
        </div>
      </div>

      <div style={{ backgroundColor: "#f8f8f8" }}>
        <Footer />
      </div>
    </div>
  );
};
const Content = props => {
  const { language } = props;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      {mobileView ? (
        <MobileView language={language} />
      ) : (
        <DesktopView language={language} />
      )}
    </div>
  );
};

export default Content;
