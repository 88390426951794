

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as SearchSVG } from '../../assets/images/enterprise/search.svg';

import {
  Tabs,
  Tab,
  IconButton,
} from "@material-ui/core";

import ImageCard from './components/imageCard'
import Actions from './redux/actions'


const useStyles = makeStyles(theme => ({
  title: {
    color: "#2B2D32"
  },
  label: {
    fontSize: 14,
    color: "rgba(43, 45, 50, 0.8)"
  },
  header: {
    position: "sticky",
    top: 0,
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  searchBar: {
    marginLeft: "auto",
    background: "white",
    width: "100%",
    borderRadius: "4px",
    color: "gray",
    height: 40,
    fontSize: 15,
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      marginTop: "1em",
      maxWidth: "100%",
      width: "100%"
    },
  },
  list: {
    height: "calc(100vh - 120px)",
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  borderBottom: {
    borderBottom: "2px solid #EEEEEE"
  },
  borderBottomMax: {
    marginTop: -2,
    borderBottom: "2px solid #EEEEEE",
    width: "100%"
  },
  selectedTab: {
    borderBottomWidth: "4px",
    borderBottomColor: "inherit",
    zIndex: 5
  }
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: {
      minWidth: 100
    }
  };
}

export default function TemporaryDrawer(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const is_mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [tab, setTab] = React.useState(0);
  const images = useSelector(state => state.stockImages.images);
  const page = useSelector(state => state.stockImages.page);
  const max_page = useSelector(state => state.stockImages.max_page);
  const loadingImages = useSelector(state => state.stockImages.loadingImages);
  const loading = useSelector(state => state.stockImages.loading);
  const [searchTimeout, setSearchTimeout] = React.useState(0);

  const onSearch = (e) => {
    const val = e.target.value; 
    setSearch(val);
    clearTimeout(searchTimeout);
    setSearchTimeout(setTimeout(() => {
      console.log(val)
      if (val) dispatch(Actions.search({
        query: val,
        provider: tab,
        page: 1
      }))
    }, 1000))
  }

  const changeProvider = (provider) => {
    setTab(provider)
    if (search) {
      dispatch(Actions.search({
        query: search,
        provider,
        page: 1
      }))
      const div = document.getElementById("stock-images-list-parent");
      div.scrollTop = 0
    }
  }

  const convertImgUrl = async (url, startCallback, endCallback) => {
    startCallback();
    const response = await fetch(url);
    const blob = await response.blob();
    let file = new File([blob], url.split("/")[3].split("?")[0] + ".jpeg", { type: "image/jpeg" });
    if (props.onSelect) {
      props.onSelect({
        file,
        data_url: url
      })
      setSearch("");
      setOpen(false);
    }
    endCallback();
  }

  const selectImage = (url, index) => {
    const startCallback = () => {
      dispatch(Actions.setLoadingImg(url))
    }
    const endCallback = () => {
      dispatch(Actions.removeLoadingImg(url))
    }
    convertImgUrl(url, startCallback, endCallback)
  }


  // scroll paginate
  const scrollListener = React.useCallback((e) => {
    const maxScroll = e.target.scrollHeight - e.target.offsetHeight;
    const currentScroll = e.target.scrollTop;
    if (maxScroll === currentScroll) {
      if (page < max_page) {
        dispatch(Actions.search({
          query: search,
          provider: tab,
          page: page + 1,
          mode: "append"
        }))
      }
    }

  }, [search, page, tab, max_page])

  return (
    <div>
      {
        props.children ? props.children(setOpen): ""
      }
      <Drawer className="stock-photo-drawer" anchor="right" open={open} onClose={() => setOpen(false)}
        classes={{
          paper: is_mobile ? "w-100": ""
        }}>
        <div className={classes.header}>
          <div className={`p-4`}>
            <div className="d-flex align-items-center mb-3">
              <h5><strong>Stock photo library</strong></h5>
              <IconButton
                className="ml-auto"
                style={{ marginRight: "-0.5em", color: "#858997" }}
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <OutlinedInput
              className={classes.searchBar}
              value={search}
              onChange={(event) => onSearch(event)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchSVG fill="#9A9EA9" style={{height: 20, width: 20}} />
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
              'aria-label': 'weight',
              }}
              labelWidth={0}
              margin="dense"
              placeholder="Type keyword to search"
            />
            <Tabs
              className={`${classes.tabs} mt-3`}
              value={tab}
              onChange={(q, val) => changeProvider(val)}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Unsplash" {...a11yProps(0)} classes={{selected: classes.selectedTab}} />
              <Tab label="Pexel" {...a11yProps(1)} classes={{selected: classes.selectedTab}} />
            </Tabs>
            <div className={`${classes.borderBottomMax} px-4`}/>
          </div>
        </div>
        <p className={`${classes.label} px-4`}>Select an image to use.</p>
        <div id="stock-images-list-parent" className={`${classes.list} overflow-auto px-4`} 
          onScroll={scrollListener}>
          <div className="row">
            {images.map((img, index) => (
              <ImageCard data={img} key={index} selectImage={(url) => selectImage(url, index)}
                color={props.color} loading={loadingImages.includes(img.full)}/>
            ))}
          </div>
          {
            loading ?
            <div className="text-center my-3">
              <div class="spinner-border text-secondary m-auto" role="status" style={{
                height: 20, width: 20
              }}>
                <span class="sr-only">Loading...</span>
              </div>
            </div>: ""
          }
        </div>
      </Drawer>
    </div>
  );
}
