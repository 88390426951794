import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import StoreLoading from "./Loading";
import Header from "./AppBar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import StoreActions from "./StoreActions";
import history from "../../../../store/history";
import {
  TextField,
  Button,
  InputAdornment,
  Link,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import validations from "../../validations";
import SocialButton from "../../../social/socialButton";
import apiConfig from "../../../../common/apiConfig";
import FacebookIcon from "@material-ui/icons/Facebook";
import StoreAlertBox from "./StoreAlertBox";
import ForgotPassword from "./login/forgotPassword";

export default function Login(props) {
  const location = useLocation();
  const path = location.pathname;
  const param = path.replace("/login", "");
  const storeName = param.replace("/shop/", "");
  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: 80,
      backgroundColor: "#fafafa",
      width: "100%"
    },
    header: {
      marginTop: "3vh",
      fontSize: 28,
      fontWeight: 600,
      color: "#2b2d32"
    },
    content: {
      padding: "2em 32vw",
      [theme.breakpoints.up("xl")]: {
        padding: "2em 38vw"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "1em 6vw"
      }
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      color: "rgba(43,45,50, 0.8)"
    },
    adornedEnd: {
      paddingRight: 0
    },
    input: {
      backgroundColor: "#fff"
    },
    btnFacebook: {
      backgroundColor: "#3B5998",
      "&:hover": {
        backgroundColor: "#224080"
      },
      color: "#fff",
      width: "100%",
      height: 48,
      fontSize: 16,
      fontWeight: 600
    }
  }));
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const storeInfo = useSelector(state => state.store.store);
  const loading = useSelector(state => state.store.loading);
  const [showForgotPassword, setShowForgotPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState("");
  const [values, setValues] = React.useState({
    email: "",
    password: ""
  });
  const [showAlert, setShowAlert] = React.useState({
    open: false,
    alertType: "error",
    alertMessage: ""
  });
  const [error, setError] = React.useState({
    emailError: "",
    passwordError: ""
  });

  const handleFbSocialLogin = user => {
    console.log(user);
  };
  const store_theme = useSelector(state => state.store.store.color);
  const store_generated_theme = createMuiTheme({
    palette: { ...store_theme },
    typography: {
      button: {
        textTransform: "none",
        height: "48px",
        fontSize: 14,
        fontWeight: "bold"
      },
      fontFamily: ["Inter", "sans-serif"]
    }
  });

  async function handleLogin() {
    let res = await dispatch(StoreActions.buyerLogin(values));
    try {
      if (res.status === 200) {
        setError({ emailError: "", passwordError: "" });
        setShowAlert({
          open: false,
          alertType: "success",
          alertMessage: ""
        });
        history.push(`/shop/${storeName}`);
      }
    } catch (e) {
      console.log(e);
      setShowAlert({
        open: true,
        alertType: "error",
        alertMessage: "Unable to log in with provided credentials."
      });
    }
  }

  React.useEffect(() => {
    async function fetchData() {
      await dispatch(StoreActions.getMyStoreInfo(storeName));
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (storeInfo.is_published == false) {
      history.push("/not-found");
    }
  });

  return (
    <div>
      {storeInfo && storeInfo.font.css_url ? (
        <link href={storeInfo.font.css_url} rel="stylesheet"></link>
      ) : (
        ""
      )}
      <ThemeProvider theme={store_generated_theme}>
        <StoreLoading open={loading} />
        <div>
          <Header isPreview={false} storeInfo={storeInfo} />
          <div className={classes.root}>
            {showForgotPassword ? (
              <ForgotPassword
                storeName={storeName}
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                error={error}
                setError={setError}
              />
            ) : (
              <div className={classes.content}>
                <Button
                  style={{ color: "#54575f" }}
                  startIcon={<ArrowBackIcon style={{ color: "#54575f" }} />}
                  onClick={() => history.goBack()}
                >
                  Go back to shopping
                </Button>
                <div className={classes.header}>Log in</div>
                <div className="my-2">
                  <StoreAlertBox alert={showAlert} />
                </div>
                <div className="mt-3">
                  <div>
                    <div className={classes.label}>Email Address</div>
                    <TextField
                      id="email"
                      margin="dense"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: 0 }}
                      value={values.email}
                      error={error.emailError}
                      helperText={error.emailError}
                      onChange={e => {
                        let input = e.target.value;
                        setValues({ ...values, email: input });
                        if (input === "") {
                          setError({
                            ...error,
                            emailError: "This field is required."
                          });
                        } else if (
                          validations.email(input) ||
                          validations.verifyEmail(input)
                        ) {
                          setError({
                            ...error,
                            emailError: "Please enter a valid email address"
                          });
                        } else if (validations.noCaps(input)) {
                          setError({
                            ...error,
                            emailError: "Uppercase is not allowed"
                          });
                        } else {
                          setError({ ...error, emailError: "" });
                        }
                      }}
                      onBlur={e => {
                        let input = e.target.value;
                        if (input === "") {
                          setError({
                            ...error,
                            emailError: "This field is required."
                          });
                        } else if (
                          validations.email(input) ||
                          validations.verifyEmail(input)
                        ) {
                          setError({
                            ...error,
                            emailError: "Please enter a valid email address"
                          });
                        } else if (validations.noCaps(input)) {
                          setError({
                            ...error,
                            emailError: "Uppercase is not allowed"
                          });
                        } else {
                          setError({ ...error, emailError: "" });
                        }
                      }}
                      InputProps={{
                        className: classes.input
                      }}
                    />
                  </div>

                  <div className="mt-3">
                    <div className="d-flex justify-content-between">
                      <div className={classes.label}>Password</div>
                      <Link
                        id="forgotPassword"
                        onClick={() => {
                          history.push(
                            `/shop/${storeInfo &&
                              storeInfo.site}/forgot_password`
                          );
                        }}
                        style={{ fontSize: 12, color: "#54575f" }}
                      >
                        Forgot password?
                      </Link>
                    </div>

                    <TextField
                      id="password"
                      margin="dense"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: 0 }}
                      value={values.password}
                      error={error.passwordError}
                      helperText={error.passwordError}
                      type={showPassword ? "text" : "password"}
                      onChange={e => {
                        let input = e.target.value;
                        setValues({ ...values, password: input });
                        if (input === "") {
                          setError({
                            ...error,
                            passwordError: "This field is required."
                          });
                        } else {
                          setError({ ...error, passwordError: "" });
                        }
                      }}
                      onBlur={e => {
                        let input = e.target.value;
                        if (input === "") {
                          setError({
                            ...error,
                            passwordError: "This field is required."
                          });
                        } else {
                          setError({ ...error, passwordError: "" });
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              id="showPassword"
                              color="secondary"
                              style={{ fontSize: 10, height: 40 }}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? "HIDE" : "SHOW"}
                            </Button>
                          </InputAdornment>
                        ),
                        classes: {
                          adornedEnd: classes.adornedEnd
                        },
                        className: classes.input
                      }}
                    />
                  </div>
                  <div className="mt-5">
                    <Button
                      id="login_btn"
                      disableElevation
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={
                        values.email === "" ||
                        values.password === "" ||
                        error.emailError ||
                        error.passwordError
                      }
                      onClick={handleLogin}
                    >
                      Log in
                    </Button>
                    <div
                      className="my-3"
                      style={{ textAlign: "center", fontSize: 12 }}
                    >
                      or
                    </div>
                    <SocialButton
                      id="fb_login"
                      provider="facebook"
                      appId={apiConfig.fbID}
                      onLoginSuccess={handleFbSocialLogin}
                      onLoginFailure={error => console.log(error)}
                      className={classes.btnFacebook}
                      startIcon={<FacebookIcon />}
                      size="large"
                    >
                      Log in with Facebook
                    </SocialButton>
                  </div>
                  <div className="my-5" style={{ textAlign: "center" }}>
                    <div style={{ fontSize: 12 }}>
                      Don't have an account yet?{" "}
                      <Link
                        id="signup"
                        style={{ color: "#54575f" }}
                        onClick={() => {
                          history.push(`/shop/${storeName}/signup`);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Sign up
                      </Link>
                      .
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer store={storeInfo} />
        </div>
      </ThemeProvider>
    </div>
  );
}
