import React from "react";
import { makeStyles } from "@material-ui/styles";
import Popover from '@material-ui/core/Popover';
import { SketchPicker } from 'react-color';


const useStyles = makeStyles(theme => ({
  circle: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "1px solid #D4D5D8",
    cursor: "pointer"
  },
  triangle: {
    height: 15,
    width: 15,
    transform: "rotate(45deg)",
    position: "absolute",
    left: 100,
    bottom: "-7px",
    background: "white"
  },
  popoverPaper: {
    overflow: "visible"
  },
}));

const Main = ({
    input,
    label,
    maxLength,
    minLength,
    multiline,
    cut,
    noHelpText,
    meta,
    ...custom
  }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div id="color-picker-root">
      <Popover
        id="color-picker-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ root: classes.popoverPaper, paper: classes.popoverPaper}}
      >
        <SketchPicker 
          disableAlpha
          presetColors={[]} color={input.value}
          onChange={(color, e) => input.onChange(color.hex)}/>
        <div className={classes.triangle}>
        </div>
      </Popover>
      <div id="color-picker-field" className={classes.circle} style={{ backgroundColor: input.value}} 
      onClick={handleClick}>
      </div>
    </div>
  );
}
export default Main;
