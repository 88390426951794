import React from "react";
import {
  makeStyles,
  Button,
  IconButton,
  Dialog,
  Slide,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  Badge
} from "@material-ui/core";
import MobileProductCard from "./MobileProductCard";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import AlertDialogBox from "../common/AlertDialogBox";
import ProductListHead from "./SortSettings/productListHead";
import HeaderFilter from "../common/products/headerFilters";
import AddIcon from "@material-ui/icons/Add";
import ProductIcon from "../../../../../../assets/images/product_icon.svg";
import { setProductFilters, getProducts } from "../websiteBuilderActions";
import apiConfig from "../../../../../../common/apiConfig";
import axios from "axios";
const BlueWarning = props => {
  const { hide, setHide, handleCustomizeShop } = props;
  const useStyles = makeStyles(theme => ({
    blueWarning: {
      backgroundColor: "#1e4b83",
      color: "#fff",
      padding: "10px 20px 20px"
    },
    btnDesign: {
      backgroundColor: "#fff",
      color: "#1e4b83",
      height: 45
    }
  }));

  const classes = useStyles();
  return (
    <div
      style={{ display: hide ? "none" : "block" }}
      className={classes.blueWarning}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div style={{ fontSize: 13, fontWeight: 600 }}>
          Put some style to your shop
        </div>
        <IconButton onClick={() => setHide(true)}>
          <CloseIcon style={{ color: "#fff", fontSize: 14 }} />
        </IconButton>
      </div>
      <div style={{ fontSize: 12 }}>
        Upload your store logo and change your shop's colors.
      </div>
      <div style={{ marginTop: 10 }}>
        <Button
          onClick={handleCustomizeShop}
          className={classes.btnDesign}
          variant="contained"
          fullWidth
        >
          Design your shop
        </Button>
      </div>
    </div>
  );
};

const MobileProductList = props => {
  const {
    handleEditProduct,
    handleDeleteProduct,
    handleCustomizeShop,
    handleAddProduct,
    hide,
    setHide,
    user,
    openFeaturedModal,
    setOpenFeaturedModal
  } = props;

  let theme = useTheme();
  let alertMessage = useSelector(state => state.mobileDashboard.message);
  let dialogOpen = useSelector(state => state.mobileDashboard.dialogOpen);
  let loading = useSelector(state => state.mobileDashboard.products.loading);
  let products = useSelector(state => state.mobileDashboard.products.products);
  let filters = useSelector(state => state.mobileDashboard.products.filters);
  let store = useSelector(state => state.mobileDashboard.storeInfo);
  let isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [page, setPage] = React.useState(1);
  const [draft_products, set_draft_products] = React.useState([]);
  const useStyles = makeStyles(theme => ({
    btn: {
      height: 42,
      borderRadius: 40,
      backgroundColor: "#FF6B55",
      boxShadow: "0px 8px 24px rgba(0, 35, 11, 0.12)"
    }
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filterHeight, setFilterHeight] = React.useState(0);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    let status;
    if (newValue === 0) status = "active";
    else if (newValue === 1) status = "draft";
    else if (newValue === 2) status = "deleted";
    setValue(newValue);
    dispatch(setProductFilters({ ...filters, status: status, page: 1 }));
  };

  const scrollListener = React.useCallback(
    e => {
      const maxScroll = e.target.scrollHeight - e.target.offsetHeight;
      const currentScroll = e.target.scrollTop;

      if (maxScroll === currentScroll) {
        if (page < products.total_pages) {
          dispatch(
            getProducts(
              store && store.uuid,
              { ...filters, page: page + 1 },
              "append"
            )
          );
          setPage(page + 1);
        }
      }
    },
    [page, products.total_pages]
  );
  React.useEffect(() => {
    const get_draft_products = async () => {
      let params = {
        ...filters,
        status: "draft",
        page: filters.page
      };
      let res = await axios.get(
        `${apiConfig.apiURL}/api/dashboard/${store.uuid}/products/`,
        {
          headers: {
            Authorization: `JWT ${sessionStorage.getItem("TOKEN")}`
          },
          params
        }
      );
      set_draft_products(res.data.results);
    };
    get_draft_products();
  }, [products]);

  let drafts_count =
    draft_products &&
    draft_products.filter(i => !i.gallery || (i.gallery && !i.gallery.length));

  const tabs = [
    { label: "Active" },
    { label: "Drafts", count: drafts_count.length },
    { label: "Archives" }
  ];

  React.useEffect(() => {
    setPage(1);
  }, [filters]);

  return (
    <div
      style={{
        backgroundColor: "#fafafa",
        marginTop: "10vh"
      }}
    >
      {hide ? (
        ``
      ) : (
        <BlueWarning
          handleCustomizeShop={handleCustomizeShop}
          hide={hide}
          setHide={setHide}
        />
      )}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth"
        color="primary"
        style={{ borderBottom: "1px solid #d1d5dd" }}
      >
        {tabs.map((item, key) => (
          <Tab
            style={{ color: value === key ? "#f97663" : "#54575f" }}
            label={
              <Badge badgeContent={item.count} color="primary">
                {item.label}
              </Badge>
            }
          />
        ))}
      </Tabs>
      <div style={{ padding: 20 }}>
        <HeaderFilter onChange={props.filtersOnChange} />
        <div
          className="my-2"
          style={{
            overflowY: "scroll",
            height: isMobile ? (hide ? "55vh" : "34vh") : hide ? "65vh" : "54vh"
          }}
          onScroll={scrollListener}
        >
          {products.results && products.results.length ? (
            products.results.map((item, key) => (
              <MobileProductCard
                item={item}
                handleEditProduct={handleEditProduct}
                handleDeleteProduct={handleDeleteProduct}
                open={openFeaturedModal}
                setOpen={setOpenFeaturedModal}
              />
            ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "90%" }}
            >
              <div>
                <div className="d-flex justify-content-center">
                  <img src={ProductIcon} alt="icon" width="auto" height="80" />
                </div>
                <div
                  className="text-center my-4"
                  style={{ fontSize: 20, fontWeight: 600, color: "#54575f" }}
                >
                  No products to display
                </div>
                <div className="text-center px-5" style={{ color: "#54575f" }}>
                  Add your products so you can start selling.
                </div>
              </div>
            </div>
          )}
          {loading ? (
            <div className="text-center my-3">
              <div
                class="spinner-border text-secondary m-auto"
                role="status"
                style={{
                  height: 20,
                  width: 20
                }}
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div style={{ position: "absolute", bottom: 40, right: 20 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon style={{ color: "#fff" }} />}
            className={classes.btn}
            disableElevation
            onClick={handleAddProduct}
          >
            Add product
          </Button>
        </div>
      </div>
    </div>
  );
};
export default MobileProductList;
