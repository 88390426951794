import axios from "axios";
import apiConfig from "../../../../common/apiConfig";

class BuyerDashboardServices {
  static async getMyCart(site) {
    let user = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${site}/cart/`,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async viewOrder(site, type, params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${site}/cart/${type}/`,
        {
          params: { registry_ref_code: params },
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getPayments(uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/payment_method/store/${uuid}`
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async checkout(values, paymentType, enterprise) {
    let params = {
      ...values,
      payment_option: paymentType["paymentOption"],
      channel:
        paymentType["paymentOption"] === "otc" ? null : paymentType["account"],
      is_read: false
    };

    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/order/`,
        params,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getPurchasedOrders(enterprise, status, params) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/enterprise/${enterprise}/order/?status=${status}`,
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          },
          params
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getVouchers(uuid, params) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/promo/voucher/`, {
        params: { store: uuid, ...params }
      });
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async buxCheckout(value) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/order/check_code/?ref_id=${value.reference_id}`
      );
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async purchasedViewOrder(enterprise, id) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/order/${id}`, {
        headers: {
          Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
        }
      });
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  static async cancelOrder(id) {
    try {
      let response = await axios.put(
        `${apiConfig.apiURL}/api/cancel-order/${id}/`,
        {
          is_received: true
        },
        {
          headers: {
            Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
          }
        }
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async viewCheckoutOrder(id) {
    try {
      let response = await axios.get(`${apiConfig.apiURL}/api/order/${id}`, {
        headers: {
          Authorization: `TOKEN ${sessionStorage.getItem("TOKEN")}`
        }
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  static async getDeliveryLocations(uuid) {
    try {
      let response = await axios.get(
        `${apiConfig.apiURL}/api/delivery-locations/?store_uuid=${uuid}`
      );
      return response;
    } catch (e) {
      throw e.response;
    }
  }

  static async computeShipping(params) {
    try {
      let response = await axios.post(
        `${apiConfig.apiURL}/api/mrspeedy/calculate_order/`,
        params
      );

      return response;
    } catch (error) {
      throw error.response;
    }
  }
}

export default BuyerDashboardServices;
