import { actionTypes } from "./actions";

const initialState = {
  subscription: "",
  loading: false,
  showBanner: false,
  subscription_payment: ""
};

export default function subscriptionReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
        showBanner: !action.payload.subscription_type ? true : false
      };
    case actionTypes.CLOSE_BANNER:
      return {
        ...state,
        showBanner: false
      };
    case actionTypes.POST_SUBSCRIPTION_PAYMENT:
      return {
        ...state,
        subscription_payment: "",
        loading: true
      };
    case actionTypes.POST_SUBSCRIPTION_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        subscription_payment: action.payload
      };
    case actionTypes.POST_SUBSCRIPTION_PAYMENT_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
