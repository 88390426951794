import React, { memo } from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import CloseIcon from "@material-ui/icons/Close";
import { Instagram, Facebook } from "@material-ui/icons";
import {
  Slide,
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Tabs,
  Tab
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/core/styles";
import SentroButton from "../../../../sentroButton";
import validation from "../../../../../common/validations";
import { patchStore } from "../websiteBuilderActions";
import fbIcon from "../../../../../../assets/images/icons/fb.png";
import instagramIcon from "../../../../../../assets/images/icons/insta.png";
import shopeeIcon from "../../../../../../assets/images/icons/shopee.png";
import lazadaIcon from "../../../../../../assets/images/icons/lazada.png";
import kumuIcon from "../../../../../../assets/images/icons/kumu.png";
import OrderInstructionsForm from "./orderInstructionsForm";
import { Form as RenameForm } from "./MobileCreateShop";
import OTPActions from "../../../../../otp/redux/actions";
import OTPModal from "../../../../../otp/OTPModal";

const useStyles = theme => ({
  rootHeader: {
    padding: 15,
    paddingBottom: 0
  },
  rootBody: {
    padding: 15
  },
  appBar: {
    position: "relative",
    backgroundColor: "#fafafa",
    boxShadow: "none",
    borderBottom: "1px solid #d1d5dd",
    height: 55
  },
  header: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  title: {
    color: "#002c42",
    fontSize: 16,
    fontWeight: 600,
    width: "100%"
  },
  errorText: {
    fontSize: 12,
    color: "red"
  },
  closeBtn: { position: "absolute", left: 15 },
  previewButton: {
    height: 38
  },
  formGroup: {
    margin: "15px 0",
    "& > .fieldTitle": {
      marginBottom: 0,
      fontSize: 14,
      fontWeight: 600,
      color: "#54575F"
    },
    "& > .fieldDescription": {
      fontSize: 12,
      color: "#54575F",
      marginBottom: 0
    },
    "& > .field": {
      marginTop: 8
    }
  },
  inlineWrapper: {
    display: "flex",
    alignItems: "center",
    "& > .buttonWrapper": {
      marginLeft: "auto",
      "& > button": {
        marginTop: "0 !important"
      }
    },
    "& > .textWrapper": {
      "& > .name": {
        fontSize: 16,
        color: "#002C42",
        fontWeight: 600,
        marginBottom: 5
      },
      "& > .site": {
        fontSize: 14,
        color: "#54575F",
        fontWeight: "normal",
        marginBottom: 5
      }
    }
  },
  helperText: { position: "absolute", right: 0, textAlign: "right" },
  checkIcon: {
    textAlign: "center",
    width: "100%",
    color: "#38b99f",
    fontSize: 100
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    color: "#002c42"
  },
  site: {
    fontSize: 14,
    color: "#54575d"
  },
  tabsContainer: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
  },
  tab: {
    fontWeight: 400,
    color: "#54575F",
    fontSize: "14px",
    lineHeight: "17px"
  },
  buttonBottomFixed: {
    paddingTop: 0,
    height: 70,
    position: "absolute",
    bottom: 0,
    width: "100%"
  }
});

const FIELD_MAX_LENGTHS = {
  slogan: 100,
  address: 100,
  mobile: 11,
  landline: 30,
  email: 128,
  facebook: 32,
  instagram: 32,
  shopee: 32,
  lazada: 32,
  kumu: 32
};

const DialogTransition = props => <Slide direction="up" {...props} />;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

class ShopDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        slogan: false,
        description: false,
        address: false,
        mobile: false,
        landline: false,
        email: false
      },
      currentTab: 0,
      formIsValid: true,
      responseModalStat: false,
      responseStat: true
    };
  }

  componentDidMount = () => {
    // HotJar heat map
    if (typeof window.hj === "function") {
      window.hj("trigger", "sentroEditDetails_mobile");
    }
    const { values, setValues, storeInfo } = this.props;
    setValues({ ...values, ...storeInfo });
  };

  componentDidUpdate = prevProps => {
    if (
      !prevProps.values.shop_details_form_open &&
      this.props.values.shop_details_form_open
    ) {
      const storeInfo = {};
      const storeInfoKeys = Object.keys(this.props.storeInfo);
      for (var i in storeInfoKeys) {
        const key = storeInfoKeys[i];
        storeInfo[key] =
          this.props.storeInfo[key] === "null" ? "" : this.props.storeInfo[key];
      }

      this.props.setValues({ ...this.props.values, ...storeInfo });
      this.setFormValidity();
    }
  };

  setCurrentTab = (e, newVal) => {
    this.setState({ currentTab: newVal });
  };

  onSwipeViews = (index, indexLatest) => {
    this.setState({ currentTab: index });
  };

  charactersLeft = fieldname => {
    const { values } = this.props;
    const fieldLength = values[fieldname] ? values[fieldname].length : 0;
    const charsRemaining = FIELD_MAX_LENGTHS[fieldname] - fieldLength;
    return `${charsRemaining} characters left`;
  };

  setError = (fieldName, errorMessage) => {
    this.setState(
      {
        ...this.state,
        errors: {
          ...this.state.errors,
          [fieldName]: errorMessage
        }
      },
      () => this.setFormValidity()
    );
  };

  setFormValidity = () => {
    const errorKeys = Object.keys(this.state.errors);
    let hasError = false;
    for (let i in errorKeys) {
      if (this.state.errors[errorKeys[i]]) {
        hasError = true;
        break;
      }
    }
    const { slogan, description, address, mobile, email } = this.props.values;
    this.setState({
      ...this.state,
      errors: { ...this.state.errors },
      formIsValid: !hasError && slogan && description && address && mobile
    });
  };

  submit = async () => {
    const { dispatch, values, setValues } = this.props;
    const res = await dispatch(
      patchStore(
        this.props.storeInfo.site,
        {
          name: values.name,
          site: values.site,
          slogan: values.slogan,
          description: values.description,
          address: values.address,
          mobile: values.mobile,
          landline: values.landline,
          email: values.email,
          facebook: values.facebook,
          instagram: values.instagram,
          shopee: values.shopee,
          lazada: values.lazada,
          kumu: values.kumu,
          order_instructions: values.order_instructions
        },
        {
          image_crop_area: values.image_crop_area,
          image_settings: values.image_settings
        }
      )
    );
    try {
      if (res.status === 200) {
        setValues({ ...values, shop_details_form_open: false });
        this.setState({
          ...this.state,
          responseModalStat: true,
          responseStat: true
        });
      }
    } catch {
      this.setState({
        ...this.state,
        responseModalStat: true,
        responseStat: false
      });
    }
  };

  handleSubmit = () => {
    const { values, dispatch, storeInfo } = this.props;
    if (storeInfo.mobile !== values.mobile) {
      dispatch(OTPActions.SEND_SMS_OTP(values.mobile));
    } else {
      this.submit();
    }
  };

  render() {
    const {
      values,
      setValues,
      storeInfo,
      classes,
      theme,
      language
    } = this.props;
    const { currentTab } = this.state;

    return (
      <>
        <OTPModal proceedCallback={() => this.submit(values)} />
        <Dialog
          fullScreen
          open={values.shop_details_form_open}
          TransitionComponent={DialogTransition}
        >
          <div>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() =>
                    setValues({ ...values, shop_details_form_open: false })
                  }
                  className={classes.closeBtn}
                >
                  <CloseIcon style={{ color: "#f97663" }} />
                </IconButton>
                <div className={classes.header}>Shop Information</div>
              </Toolbar>
            </AppBar>
            <div
              className={classes.root}
              style={{
                padding: "0 15px"
              }}
            >
              <div className={classes.formGroup}>
                <div className={classes.inlineWrapper}>
                  <div className="textWrapper">
                    <p className="name">{storeInfo.name}</p>
                    <p className="site">{`${storeInfo.site}.sentro.ph`}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            className={classes.tabsContainer}
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            onChange={this.setCurrentTab}
          >
            <Tab className={classes.tab} label="Name" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Details" {...a11yProps(1)} />
            <Tab
              style={{ minWidth: 150 }}
              className={classes.tab}
              label="Order Instructions"
              {...a11yProps(2)}
            />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={currentTab}
            enableMouseEvents
            onChangeIndex={this.onSwipeViews}
          >
            <TabPanel>
              <RenameForm
                values={values}
                setValues={setValues}
                language={language}
                errorCallback={hasErr =>
                  this.setState({
                    ...this.state,
                    formIsValid: !hasErr
                  })
                }
              />
            </TabPanel>
            <TabPanel>
              <div style={{ height: "calc(100vh - 188px)" }}>
                <div
                  className={classes.rootBody}
                  style={{
                    height: "calc(100vh - 266px)",
                    overflowY: "auto"
                  }}
                >
                  <div className={classes.formGroup}>
                    <p className="fieldTitle">
                      {language === "English"
                        ? "What is your shop's slogan or tagline?"
                        : "Anong slogan o tagline ng shop mo?"}
                    </p>
                    <p className="fieldDescription">
                      {language === "English"
                        ? 'Examples are: "Bida ang Saya" or "Love ko to"'
                        : 'Halimbawa: "Bida ang Saya" o "Love ko to"'}
                    </p>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="slogan"
                      color="secondary"
                      variant="outlined"
                      className="field"
                      value={values.slogan}
                      error={this.state.errors.slogan && true}
                      onChange={e => {
                        const raw_input = e.target.value;
                        if (raw_input.length <= FIELD_MAX_LENGTHS["slogan"]) {
                          setValues({ ...values, slogan: raw_input });
                          this.setError(
                            "slogan",
                            validation.required(raw_input)
                          );
                        }
                      }}
                      helperText={
                        <span className={classes.helperText}>
                          {this.state.errors.slogan ||
                            this.charactersLeft("slogan")}
                        </span>
                      }
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <p className="fieldTitle">
                      {language === "English"
                        ? "Please tell us a bit about your business"
                        : "Kwentuhan mo kami tungkol sa iyong business"}
                    </p>
                    <p className="fieldDescription">
                      {language === "English"
                        ? "A short history or description about your business"
                        : "Pwede mong ilagay rito kung paano kayo nagsimula o kung ano ang business mo"}
                    </p>
                    <TextField
                      fullWidth
                      multiline
                      margin="dense"
                      id="description"
                      color="secondary"
                      variant="outlined"
                      className="field"
                      value={values.description}
                      rows={8}
                      onChange={e => {
                        const raw_input = e.target.value;
                        setValues({ ...values, description: raw_input });
                        this.setError(
                          "description",
                          validation.required(raw_input)
                        );
                      }}
                      error={this.state.errors.description && true}
                      helperText={
                        <span className={classes.helperText}>
                          {this.state.errors.description}
                        </span>
                      }
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <p className="fieldTitle">
                      {" "}
                      {language === "English"
                        ? "Where is your store located?"
                        : "Ano'ng address ng business mo?"}
                    </p>
                    <p className="fieldDescription">Business Address</p>
                    <TextField
                      fullWidth
                      multiline
                      margin="dense"
                      id="address"
                      color="secondary"
                      variant="outlined"
                      className="field"
                      value={values.address}
                      rows={2}
                      onChange={e => {
                        const raw_input = e.target.value;
                        if (raw_input.length <= FIELD_MAX_LENGTHS["address"]) {
                          setValues({ ...values, address: raw_input });
                          this.setError(
                            "address",
                            validation.required(raw_input)
                          );
                        }
                      }}
                      error={this.state.errors.address && true}
                      helperText={
                        <span className={classes.helperText}>
                          {this.state.errors.address ||
                            this.charactersLeft("address")}
                        </span>
                      }
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <p className="fieldTitle">
                      {language === "English"
                        ? "Link your social media accounts?"
                        : "Meron bang social media accounts ang business mo?"}
                    </p>
                    <p className="fieldTitle">
                      {language === "English"
                        ? "Please set your accounts to public"
                        : "Siguraduhing naka-set sa Public ang iyong mga social media accounts para mas malawak ang iyong digital reach"}
                    </p>
                    <p className="fieldDescription">Facebook</p>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="facebook"
                      color="secondary"
                      variant="outlined"
                      className="field"
                      value={values.facebook}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            style={{
                              marginRight: "0vw"
                            }}
                          >
                            <img src={fbIcon} alt="fbicon" />
                            <span style={{ marginLeft: "1vw" }}>
                              facebook.com/
                            </span>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => {
                        const raw_input = e.target.value;
                        if (raw_input.length <= FIELD_MAX_LENGTHS["facebook"]) {
                          setValues({ ...values, facebook: raw_input });
                        }
                      }}
                    />
                    <p className="fieldDescription">Instagram</p>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="instagram"
                      color="secondary"
                      variant="outlined"
                      className="field"
                      value={values.instagram}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            style={{
                              marginRight: "0vw"
                            }}
                          >
                            <img src={instagramIcon} alt="fbicon" />
                            <span style={{ marginLeft: "1vw" }}>
                              instagram.com/
                            </span>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => {
                        const raw_input = e.target.value;
                        if (
                          raw_input.length <= FIELD_MAX_LENGTHS["instagram"]
                        ) {
                          setValues({ ...values, instagram: raw_input });
                        }
                      }}
                    />
                    <p className="fieldDescription">Shopee</p>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="shopee"
                      color="secondary"
                      variant="outlined"
                      className="field"
                      value={values.shopee}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            style={{
                              marginRight: "0vw"
                            }}
                          >
                            <img
                              src={shopeeIcon}
                              alt="fbicon"
                              style={{ height: 24 }}
                            />
                            <span style={{ marginLeft: "1vw" }}>
                              shopee.ph/
                            </span>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => {
                        const raw_input = e.target.value;
                        if (raw_input.length <= FIELD_MAX_LENGTHS["shopee"]) {
                          setValues({ ...values, shopee: raw_input });
                        }
                      }}
                    />
                    <p className="fieldDescription">Lazada</p>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="lazada"
                      color="secondary"
                      variant="outlined"
                      className="field"
                      value={values.lazada}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            style={{
                              marginRight: "0vw"
                            }}
                          >
                            <img src={lazadaIcon} alt="fbicon" />
                            <span style={{ marginLeft: "1vw" }}>
                              lazada.com.ph/shop/
                            </span>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => {
                        const raw_input = e.target.value;
                        if (raw_input.length <= FIELD_MAX_LENGTHS["lazada"]) {
                          setValues({ ...values, lazada: raw_input });
                        }
                      }}
                    />
                    <p className="fieldDescription">Kumu username</p>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="kumu"
                      color="secondary"
                      variant="outlined"
                      className="field"
                      value={values.kumu}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            style={{
                              marginRight: "0vw"
                            }}
                          >
                            <img src={kumuIcon} alt="fbicon" />
                            <span style={{ marginLeft: "1vw" }}>@</span>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => {
                        const raw_input = e.target.value;
                        if (raw_input.length <= FIELD_MAX_LENGTHS["kumu"]) {
                          setValues({ ...values, kumu: raw_input });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className={`${classes.formGroup} p-3 my-0`}>
                <p className="fieldTitle">
                  {language === "English"
                    ? "Where can your customers contact you?"
                    : "Saan ka pwedeng ma-contact ng iyong mga customers?"}
                </p>
                <p className="fieldDescription">Mobile number</p>
                <TextField
                  fullWidth
                  multiline
                  margin="dense"
                  id="mobile"
                  color="secondary"
                  variant="outlined"
                  className="field"
                  value={values.mobile}
                  onChange={e => {
                    const raw_input = e.target.value.replace(/[^0-9]/g, "");
                    if (raw_input.length <= FIELD_MAX_LENGTHS["mobile"]) {
                      setValues({ ...values, mobile: raw_input });
                    }
                    this.setError(
                      "mobile",
                      validation.required(raw_input) ||
                        validation.mobileNumber(raw_input)
                    );
                  }}
                  onBlur={e => {
                    const raw_input = e.target.value;
                    this.setError(
                      "mobile",
                      validation.required(raw_input) ||
                        validation.mobileNumber(raw_input)
                    );
                  }}
                  error={this.state.errors.mobile && true}
                  helperText={
                    <span className={classes.helperText}>
                      {this.state.errors.mobile ||
                        this.charactersLeft("mobile")}
                    </span>
                  }
                />
                <p className="fieldDescription" style={{ marginTop: "1em" }}>
                  Landline Number
                </p>
                <TextField
                  fullWidth
                  multiline
                  margin="dense"
                  id="landline"
                  color="secondary"
                  variant="outlined"
                  className="field"
                  value={values.landline}
                  onChange={e => {
                    const raw_input = e.target.value.replace(/[^0-9]/g, "");
                    if (raw_input.length <= FIELD_MAX_LENGTHS["landline"]) {
                      setValues({ ...values, landline: raw_input });
                    }
                  }}
                />
                <p className="fieldDescription">Email Address</p>
                <TextField
                  fullWidth
                  multiline
                  margin="dense"
                  id="email"
                  color="secondary"
                  variant="outlined"
                  className="field"
                  value={values.email}
                  onChange={e => {
                    const raw_input = e.target.value;
                    if (raw_input.length <= FIELD_MAX_LENGTHS["email"]) {
                      setValues({ ...values, email: raw_input });
                      if (raw_input.length === 0) {
                        this.setError("email", "");
                      } else {
                        this.setError("email", validation.email(raw_input));
                      }
                    }
                  }}
                  error={this.state.errors.email && true}
                  helperText={
                    <span className={classes.helperText}>
                      {this.state.errors.email}
                    </span>
                  }
                />
              </div>
              <OrderInstructionsForm
                setValues={setValues}
                values={values}
                state={this.state}
                setState={states => this.setState(states)}
              />
            </TabPanel>
          </SwipeableViews>
          <div
            className="formGroup"
            style={{
              padding: 15,
              background: "white",
              position: "fixed !important",
              bottom: 0,
              width: "100%",
              zIndex: 3,
              WebkitTransform: "translateZ(0)"
            }}
          >
            <SentroButton
              disableElevation
              fullWidth
              label="Submit"
              variant="contained"
              style={{ marginTop: 0 }}
              onClick={() => this.handleSubmit()}
              disabled={!this.state.formIsValid}
            />
          </div>
        </Dialog>
        <Dialog fullWidth maxWidth="md" open={this.state.responseModalStat}>
          <DialogTitle
            style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
          >
            <IconButton
              onClick={() =>
                this.setState({ ...this.state, responseModalStat: false })
              }
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: "5px 20px 20px" }}>
            {this.state.responseStat ? (
              <>
                <CheckCircleIcon className={classes.checkIcon} />
                <div style={{ textAlign: "center" }}>
                  <div className={classes.name}>{values.name}</div>
                  <div className={classes.site}>{values.site}.sentro.ph</div>
                  <div
                    style={{ marginTop: 30, padding: "0 15px" }}
                    className={classes.site}
                  >
                    You have successfully edited your store details
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{ padding: "0 15px", textAlign: "center" }}
                className={classes.site}
              >
                Oops, something went wrong. Please try again.
              </div>
            )}
            <div style={{ marginTop: 40 }}>
              <Button
                disableElevation
                fullWidth
                variant="contained"
                color="primary"
                className={classes.btnProduct}
                onClick={() =>
                  this.setState({ ...this.state, responseModalStat: false })
                }
              >
                OK
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => () => ({
  // app: marketplace.app,
  // loading: marketplace.loading
});
export default connect(mapStateToProps)(
  withStyles(useStyles, { withTheme: true })(ShopDetailsForm)
);
