

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Truncate from 'react-truncate';


export default function ImageCard(props) {
const useStyles = makeStyles(theme => ({
  img: {
    maxHeight: "125px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 100
    },
    transition: "all 0.15s ease-in-out",
    boxShadow: "none",
    borderRadius: 5,
    height: 160,
    position: "relative",
    "&:hover": {
      "& > .attributionIcon": {
        opacity: "1 !important"
      },
      "& > .dropShadow": {
        opacity: "1 !important"
      },
    },
    "& > .attributionIcon": {
      transition: "all 0.15s ease-in-out",
      color: "white",
      top: 43,
      left: 40,
      borderRadius: 5,
      opacity: 0,
      cursor: "pointer",
      zIndex: 6,
      width: 120,
      fontSize: 14
    },
    "& > .dropShadow": {
      backgroundColor: "rgba(0,0,0,0.2)",
      zIndex: 5,
      borderRadius: 5,
      opacity: 0,
      transition: "all 0.15s ease-in-out",
    },
  },
  attrFooter: {
    background: "rgba(0,0,0,0.8)",
    color: "white",
    fontSize: 12
  },
  menu: {
    minWidth: 200,
    "& .MuiList-padding": {
      padding: "0 !important"
    },
    fontSize: 14
  },
  link: {
    color: props.color || "#f97663",
    fontSize: 12,
    margin: "0 5px",
    textDecoration: "underline"
  },
  avatar: {
    height: 30,
    width: 30,
    borderRadius: '50%',
    backgroundSize: "cover",
    backgroundPosition: "left",
    boxShadow: "0px 1px 5px 0 rgba(0,0,0,0.05)"
  },
  description: {
    width: 265,
    fontFamily: "Oswald"
  },
  btn: {
    padding: "0.5em 1em",
    color: "white",
    border: "2px solid white",
    borderRadius: 4,
  }
}));
  const classes = useStyles();

  const handleClick = (event) => {
    if (props.selectImage) props.selectImage(props.data.full)
  };

  return (
    <div className="col-6 mt-3 text-center">
      <div className={`img-fluid mx-auto ${classes.img}`} style={{
          backgroundImage: `url(${props.data.src})`,
          backgroundSize: "cover",
      }}>
          {
            props.loading ? 
            <div className="d-flex justify-content-center align-items-center
              position-absolute w-100 h-100">
              <div className="spinner-border mx-auto" role="status" style={{ 
              color: "white", fontSize: 12,
              height: 20, width: 20
              }}>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            :
            <div className="position-absolute attributionIcon">
              <div className={`${classes.btn} stock-photo-use-image`} onClick={handleClick}>Use image</div>
            </div>
          }
          <div className={`${classes.dropShadow} dropShadow position-absolute w-100 h-100`} />
      </div>
      {/* <Truncate lines={1} ellipsis={<span>...</span>}> */}
        {
          props.data.attribution.artist ? 
          <p className="mb-0 mt-1" style={{
            fontSize: 12,
            color: props.color
          }}>
            Photo by {
              props.data.attribution.artist_url ? 
              <a className={classes.link} href={props.data.attribution.artist_url} 
              rel="noopener noreferrer" target="_blank">
              {props.data.attribution.artist}</a>
              : <span className={classes.link}>{props.data.attribution.artist}</span>
            } on <a className={classes.link} href={props.data.attribution.original_url} 
            rel="noopener noreferrer" target="_blank">{props.data.attribution.platform}</a>
          </p>
          :""
        }
      {/* </Truncate> */}
    </div>
  );
}
