import { lazy } from "react";
import Paths from "./paths";

const routes = [
  {
    path: Paths.home,
    Component: lazy(() => import("../pages/home")),
    exact: true
  },
  {
    path: Paths.cart,
    Component: lazy(() => import("../pages/cart")),
    exact: true
  },
  {
    path: Paths.delivery,
    Component: lazy(() => import("../pages/delivery")),
    exact: true
  },
  {
    path: Paths.customer_details,
    Component: lazy(() => import("../pages/customer_details")),
    exact: true
  },
  {
    path: Paths.payment,
    Component: lazy(() => import("../pages/payment")),
    exact: true
  },
  {
    path: Paths.checkout,
    Component: lazy(() => import("../pages/checkout")),
    exact: true
  }
  // Add pages here
];

export default routes;
