import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ButtonGroup from './buttonGroup'
import DateRangePicker from './dateRangePicker'

const useStyles = theme => ({
  title: {
    fontWeight: "bold",
    fontSize: 28,
    color: "#002C42"
  },
  backHeader: {
    width: 120,
    display: "flex !important",
    alignItems: "center",
    padding: "0 0 2em",
    fontWeight: 600,
    fontSize: 14,
    color: "#F97663 !important"
  },
  searchBar: {
    marginLeft: "auto",
    background: "white",
    maxWidth: 280,
    borderRadius: "4px",
    color: "gray",
    height: 35,
    fontSize: 12,
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      marginTop: "1em",
      maxWidth: "100%",
      width: "100%"
    },
  },

  buttonGroup: {
    backgroundColor: "white",
    "& .button": {
      padding: "0.5em 1em",
      height: "35px",
      color: '#F97663',
      background: 'white',
      border: "1px solid #F97663",
      fontWeight: 400,
      boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.1)",
    },
  },
  filterTitle: {
    minWidth: 110,
    fontSize: 14,
    color: "#54575F"
  }
});

class Header extends React.Component {

  state = {
    date_created: "Today",
    status: "All",
    search: ""
  }
  searchTimeout = ""

  setFilter = (key, val) => {
    if (this.props.onChange) this.props.onChange({[key]: val})
  }

  setDateFilter = (dates) => {
    if (this.props.onChange) this.props.onChange(dates)
  }

  componentDidMount = () => {
    this.setState({
      ...this.state,
      ...this.props.filter
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        ...this.state,
        ...this.props.filter
      })
    }
  }

  search = (e) => {
    const val = e.target.value; 
    this.setState({
      ...this.state,
      search: val
    })
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (this.props.onChange) this.props.onChange({ search: val })
    }, 300)
  }

  render (){
    const { classes } = this.props;
    return (
      <>
        <div className="d-flex mb-2">
          <h2 className={`mb-3 flex-grow-1 ${classes.title}`}>{this.props.title}</h2>
          <OutlinedInput
            className={classes.searchBar}
            value={this.state.search}
            onChange={(event) => this.search(event)}
            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
            'aria-label': 'weight',
            }}
            labelWidth={0}
            margin="dense"
            placeholder="Type here to search"
          />
        </div>
        <div className="d-flex mb-2 align-items-center">
          <p className={`mb-0 ${classes.filterTitle}`}>Date Ordered: </p>
          <ButtonGroup default="Today" value={this.state.date_created} choices={[
            "Today", "Yesterday", "7 Days", "YTD", "Custom"
          ]} onChange={(val) => {
            this.setFilter("date_created", val)
          }}/>
          {
            this.state.date_created === "Custom" &&
            <div className="ml-3">
              <DateRangePicker value={{
                start: this.state.start,
                end: this.state.end
              }} onChange={({start, end}) => {
                this.setDateFilter({
                  start,
                  end
                })
              }}/>
            </div>
          }
        </div>
        <div className="d-flex mb-2 align-items-center">
          <p className={`mb-0 ${classes.filterTitle}`}>Status: </p>
          <ButtonGroup default="All" value={this.state.status} choices={[
            "All", "To Confirm", "For Payment", "Paid", "Cancelled"
          ]}  onChange={(val) => {
            this.setFilter("status", val)
          }}/>
        </div>
      </>
    )
  }
}


const mapStateToProps = state => ({ marketplace }) => ({ 
//   apps: marketplace.apps
});
export default connect(mapStateToProps)(withStyles(useStyles)(Header));

