import React, { memo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '@material-ui/core/TextField';
import Actions from "../../Redux/Actions";
import Services from "../../Redux/Services";

const useStyles = theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  field: {
    fontSize: 12,
    margin: "1.5em 0 0",
    width: '50%',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    },
    display: "flex"
  },
  modalField: {
    fontSize: 12,
    margin: "1.5em 0 0",
    width: '100%',
  },
  divider: {
    margin: "1em 0"
  },
  modalDiv: {
    flexDirection: "column",
    padding: "2.5em",
    // minWidth: 500,
    [theme.breakpoints.down("xs")]: {
      // minWidth: "auto",
    },
  },
  modalTile: {
    fontSize: 26,
    color: "#2B2D33",
    fontWeight: 600,
    // marginBottom: 25,
    lineHeight: "1em"
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    color: "#54575F",
    marginBottom: 25
  },
  modalActionsWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  modalActions: {
    minWidth: 100,
    padding: "0 1.5em",
    height: 35,
    fontSize: 14,
    fontWeight: 600
  },
  actionIcons: {
    fontSize: 20,
    marginTop: 18,
    marginLeft: 10,
    color: 'gray',
    cursor: 'pointer'
  }
});

class PartnerSelect extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      dialogStat: false,
      dialogType: "add",
      targetIndex: `Choose Partner`,
      // fields
      name: '',
      email: '',
      sentro_fee: 0,
      valid: true
    }
    this.setDialogState = this.setDialogState.bind(this)
    this.textFieldOnChange = this.textFieldOnChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.add = this.add.bind(this)
    this.edit = this.edit.bind(this)
    this.delete = this.delete.bind(this)
    this.resetFields = this.resetFields.bind(this)
  }

  setDialogState(dialogStat, targetIndex=this.state.targetIndex, dialogType="add"){
    this.setState({
      dialogStat,
      dialogType,
      targetIndex
    })
    if(dialogType==="edit"){
      const partner = this.props.partners.filter((data) => data.id === targetIndex)
      if(partner.length){
        this.setState({
          name: partner[0].name,
          email: partner[0].email,
          sentro_fee: partner[0].sentro_fee,
        })
      }
    } else {
      this.resetFields()
    }
  }

  textFieldOnChange = async (event, fieldname) => {
    this.setState({
      ...this.state,
      [fieldname]: event.target.value
    })
    if (fieldname==="email"){
      const is_unique = await Services.checkPartner(event.target.value)
      this.setState({
        valid: is_unique
      })
    }
  }

  handleSelectChange = (event) => {
    this.setState({
      ...this.state,
      targetIndex: event.target.value
    });
    this.props.onChange(event.target.value)
  };

  add = async() => {
    const { dispatch } = this.props;
    const response = await dispatch(Actions.createPartner({
      name: this.state.name, 
      email: this.state.email,
      sentro_fee: this.state.sentro_fee
    }))
    this.setDialogState(false, response.id)
    this.props.onChange(response.id)
  }

  edit = async (index) => {
    // console.log("EDIT", index)
    const { dispatch } = this.props;
    const response = await dispatch(Actions.patchPartner(index, {
      name: this.state.name, 
      email: this.state.email,
      sentro_fee: this.state.sentro_fee
    }))
    this.setDialogState(false, response.id)
    this.props.onChange(response.id)
  }

  delete(index){
    console.log("delete", index)
    // let variations = this.props.variations;
    // delete variations[variationIndex];
    // this.props.updateVariation(variations);
    // this.resetFields();
  }

  resetFields(){
    this.setState({
      name: '',
      email: ''
    });
  }

  componentWillReceiveProps(props){
    if(props.initialValue){
      this.setState({
        targetIndex: props.initialValue,
      });
    }
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    if(this.props.initialValue){
      this.setState({
        targetIndex: this.props.initialValue
      })
    }
  }

  render (){
    const { classes, partners, modalLoading } = this.props;
    const { dialogStat, dialogType, targetIndex, name, email, sentro_fee, valid } = this.state;
    const dialogAction = (index=0) => {
      switch(dialogType){
        case "add":
          return this.add()
        case "edit": 
          return this.edit(index)
        case "delete":
          return this.delete(index)
        default:
          return this.add()
      }
    }

    const dialogTitle = () => {
      switch(dialogType){
        case "add":
          return `Add Partner`
        case "edit": 
          return `Edit Partner`
        case "delete":
          return `Edit Partner`
        default:
          return `Create Partner`
      }
    }

    const dialogContent = () => {
      switch(dialogType){
        case "delete":
          return `Delete Partner?`
        default:
          return (
            <div className={classes.modalBody}>
              <TextField
                className={classes.modalField}
                onChange={(event) => this.textFieldOnChange(event, "name")}
                label="Name"
                margin="dense"
                placeholder="Name"
                variant="outlined"
                value={name}
              />
              <TextField
                className={classes.modalField}
                onChange={(event) => this.textFieldOnChange(event, "email")}
                label="Email"
                margin="dense"
                placeholder="Email"
                variant="outlined"
                value={email}
                error={!valid}
                helperText={!valid ? `This email already exists`: ``}
              />
              <TextField
                className={classes.modalField}
                onChange={(event) => this.textFieldOnChange(event, "sentro_fee")}
                label="Sentro Fee (Percentage)"
                margin="dense"
                placeholder="Sentro Fee"
                variant="outlined"
                value={sentro_fee}
                error={!valid}
                type="number"
                // helperText={!valid ? `This email already exists`: ``}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div>
          )
      }
    }

    return (
      <>
        <Dialog
          open={dialogStat}
          onClose={(event) => this.setDialogState(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth={true}
        >	
          <div className={classes.modalDiv}>
            <div className={classes.modalTile}>
              {dialogTitle()}
            </div>
            {/* <div className={classes.modalBody}> */}
              {dialogContent()}
            {/* </div> */}
            <div className={classes.modalActionsWrapper}>
              <Button
                id="action_button"
                variant="outlined"
                onClick={(event) => {
                  this.setDialogState(false)
                  this.resetFields()
                }} 
                color="primary"
                margin="dense"
                className={classes.modalActions}
                style={{ marginLeft: "auto" }}
              >
                Cancel
              </Button>
              <Button
                id="action_button"
                variant="contained"
                onClick={(event) => {
                  dialogAction(targetIndex)
                }}
                color="primary"
                margin="dense"
                className={classes.modalActions}
                style={{ marginLeft: "1em" }}
                disabled={!valid || !name.length || !/\S+@\S+\.\S+/.test(email) ||
                !(sentro_fee >= 0 && sentro_fee <= 100 && sentro_fee.length)}
              >
                {modalLoading ? <>
                  <CircularProgress color="secondary" style={{height: 20, width: 20, color: "white", marginRight: 5}}/> 
                  <span>Saving...</span> 
                </>: 'Save'}
              </Button>
            </div>
          </div>
        </Dialog>
        <div className={classes.root}>
          <FormControl variant="outlined" className={classes.field}>
            <InputLabel id="demo-simple-select-outlined-label">Partner</InputLabel>
            <Select
              margin="dense"
              value={targetIndex}
              onChange={this.handleSelectChange}
              label="Partner"
            >
              <MenuItem value={`Choose Partner`}>Choose Partner</MenuItem>
              {
                partners.map((partner) => (
                  <MenuItem key={partner.id} value={partner.id}>{`${partner.name} (${partner.email})`}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <AddCircleOutlineIcon className={classes.actionIcons} onClick={() => {
            this.setDialogState(true)
          }}/>
          <EditOutlinedIcon className={classes.actionIcons} onClick={() => {
            this.state.targetIndex !== `Choose Partner` && this.setDialogState(true, this.state.targetIndex, "edit")
          }}/>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ adminDashboard }) => ({ 
  partners: adminDashboard.partners,
  modalLoading: adminDashboard.modalLoading
});
export default connect(mapStateToProps)(withStyles(useStyles)(PartnerSelect));

