import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Box } from "@material-ui/core";
import NPCLogo from "./../../../../../assets/images/npc202507.jpg";

const PrivacyPolicy = props => {
  const useStyles = makeStyles(theme => ({
    header: {
      fontSize: 14,
      fontWeight: 600,
      color: "#54575f"
    },
    content: {
      fontSize: 14,
      color: "#54575f"
    },
    accntData: {
      marginTop: "3vh",
      display: "flex",
      textAlign: "center",
      flex: 12,
      [theme.breakpoints.down("xs")]: {
        display: "block"
      }
    },
    relDocuments: {
      flex: 6,
      display: "block",
      padding: "0 10px",
      [theme.breakpoints.down("xs")]: {
        flex: 12,
        padding: 0,
        marginTop: 10
      }
    }
  }));
  const classes = useStyles();

  return (
    <div>
      <Box style={{ marginTop: "2vh" }}>
        <Typography className={classes.header}>Privacy Statement</Typography>
        <Typography className={classes.content}>
          We at Sentro respect your right to privacy and want you to understand
          how we collect, use, and share information about you. This Privacy
          Policy covers our data processing practices and describes your data
          privacy rights under the Philippine Data Privacy Act of 2012.
        </Typography>
      </Box>
      <Box style={{ marginTop: "4vh" }}>
        <Typography className={classes.content}>
          This Privacy Policy applies when you visit or use the Sentro website,
          use the Application Programming Interfaces (API) or related services
          (the “Services”) of the platform. By using the Services, you agree to
          the terms of this Privacy Policy.
        </Typography>
      </Box>

      <Box style={{ marginTop: "4vh" }}>
        <Typography className={classes.header}>
          What Data We Collect From You
        </Typography>
        <Typography className={classes.content}>
          Sentro collects certain data from you directly, like information you
          enter yourself and data from third-party platforms you connect with in
          and through Sentro.
        </Typography>
      </Box>

      <Box style={{ marginTop: "4vh" }}>
        <Typography className={classes.header}>
          Data You Provide to Us
        </Typography>

        <Typography className={classes.content}>
          Sentro may collect your personal information depending on how you use
          the Services. When you create an account and use the Services,
          including through a third-party platform, Sentro collects any
          information you directly provide including the following:
        </Typography>
      </Box>

      <Box style={{ marginTop: "4vh" }}>
        <div style={{ display: "flex" }}>
          <Typography className={classes.content}>1.</Typography>
          <Typography
            style={{
              fontSize: 14,
              color: "#54575f",
              marginLeft: 10
            }}
          >
            <strong>Account Data and KYC (know-your-client) Information</strong>
            <br />
            In order to use certain features of the Services, you need to create
            a user account. When you create or update your account, we collect
            and store the data you provide such as full name, mother’s maiden
            name, gender, email address, line of business, mobile number,
            present and permanent addresses, ID(front and dorsal portions), ID
            type, ID number, nationality, date of birth, birthplace, civil
            status, employername, employment status, employer nature of
            business, employer address, occupation, source of funds,selfie
            picture and all other information that you have provided during
            account creation or updating.
            <div style={{ marginTop: "3vh" }}>
              For entities, Sentro collects the following information when your
              representatives or authorized personnel create or update your
              enterprise account in Sentro: business information (type, name,
              nature, address details, years in business, website/URL), social
              media pages or accounts (Facebook, Instagram, etc.),annual gross
              sales information, average ticket size, projected annual sales,
              services offered, list of stockholders, directors, and officers,
              and all other information that are required to be provided during
              account creation or updating.
            </div>
            <div style={{ marginTop: "3vh" }}>
              As part of Sentro’s KYC, Sentro also requires the submission or
              provision of the following business-related documents for sole
              proprietorships or other entities that open enterprise accounts in
              Sentro:
            </div>
            <div className={classes.accntData}>
              <div className={classes.relDocuments}>
                <strong>Sole Proprietorship</strong>
                <div>DTI Registration</div>
                <div>Business Permit</div>
                <div>BIR Registration</div>
                <div>Mayor’s Permit</div>
                <div>Income Tax Return (optional)</div>
                <div>Bank Account information for settlement</div>
              </div>
              <div className={classes.relDocuments}>
                <strong>Corporate Entities</strong>
                <div>SEC Registration</div>
                <div>Articles of Incorporation</div>
                <div>By-Laws</div>
                <div>Latest General Information Sheet (GIS)</div>
                <div>Business Permit</div>
                <div>
                  Secretary’s Certificate or Board Resolution for Authorized
                </div>
                <div>Representatives or Signatories</div>
                <div>Latest Audited Financial Statement (optional)</div>
                <div>Bank Account information for settlement</div>
              </div>
            </div>
            <div style={{ marginTop: "3vh" }}>
              Sentro also collects individual information from the authorized
              users of enterprise accounts as they are required to create
              individual accounts. Upon successful creation of the enterprise
              account, the individual accounts shall be provided with their
              access rights. On top of the usual information to be required and
              collected for individual accounts, enterprise-related individual
              accounts shall also provide their designation for the roles of:{" "}
              <span style={{ color: "#f97663" }}>
                SPOC - Main Contact Person / Master Admin, Sales, Finance,
                Support,Admin and IT for enterprise accounts
              </span>
              .
            </div>
          </Typography>
        </div>
        <div style={{ display: "flex", marginTop: 10 }}>
          <Typography className={classes.content}>2.</Typography>
          <Typography
            style={{
              fontSize: 14,
              color: "#54575f",
              marginLeft: 10
            }}
          >
            <strong>Profile Data</strong>
            <br />
            You may choose to provide your profile information which may be
            viewable by others in Sentro.
          </Typography>
        </div>
        <div style={{ display: "flex", marginTop: 10 }}>
          <Typography className={classes.content}>3.</Typography>
          <Typography
            style={{
              fontSize: 14,
              color: "#54575f",
              marginLeft: 10
            }}
          >
            <strong>Data About Your Accounts on Other Services</strong>
            <br />
            Sentro obtains certain information through your social media or
            other online accounts if they are connected to your Sentro account.
            The information Sentro receives depend on what information you
            decide to provide to Sentro via your privacy settings or the
            platform or service. If you access or use Sentro’s Services through
            a third-party platform or service, or click on any third-party
            links, the collection,use, and sharing of your data will also be
            subject to the privacy policies and other agreements of that third
            party.
          </Typography>
        </div>
        <div style={{ display: "flex", marginTop: 10 }}>
          <Typography className={classes.content}>4.</Typography>
          <Typography
            style={{
              fontSize: 14,
              color: "#54575f",
              marginLeft: 10
            }}
          >
            <strong>Payment and Transaction Data</strong>
            <br />
            Sentro collects and uses certain information, including your
            frequency of subscriptions, business industry information, your
            plug-ins and applications you have downloaded or are subscribed to.
            For your security,Sentro does not collect or store sensitive bank
            account information. However, Sentro’s payment partners,payment
            channels or payment aggregators will access your information for
            generating the checkout of transactions. Such information may
            include your transaction amount, description, and customer’s
            personal information such as email address, mobile number, type of
            payment channel and payment expiry. These will be used to store and
            validate the payment transaction you have made and will bepassed on
            to Sentro’s payment partners, channels or aggregators. Once payment
            is made by your customers, these information will be go back to
            Sentro to validate the transactions.
          </Typography>
        </div>

        <div style={{ display: "flex", marginTop: 10 }}>
          <Typography className={classes.content}>4.</Typography>
          <Typography
            style={{
              fontSize: 14,
              color: "#54575f",
              marginLeft: 10
            }}
          >
            <strong>Payouts</strong>
            <br />
            Sentro’s payment partners, payment channels or payment aggregators
            collect and store your bank account information needed to access and
            generate your checkout transactions. These information will then be
            passed on your nominated settlement bank.
          </Typography>
        </div>
      </Box>

      <Box style={{ marginTop: "5vh" }}>
        <Typography className={classes.header}>
          How we Get Data About you
        </Typography>
        <Typography className={classes.content}>
          Signing up to Sentro may be done via website, Facebook Messenger or
          mobile app. During sign up,Sentro collects the data you have provided.
          These include the information enumerated above under“Data You Provide
          to Us.”
        </Typography>
      </Box>

      <Box style={{ marginTop: "5vh" }}>
        <Typography className={classes.header}>Analytics</Typography>
        <Typography className={classes.content}>
          Sentro uses Google Analytics. This tool enables Sentro to analyze your
          use of the Services, including information on the frequency of your
          visits, events you look at within the Services, usage and performance
          data. Sentro uses these data to improve its Services, better
          understand how the Services perform on different devices, and provide
          information that may be of interest to you.
        </Typography>
      </Box>

      <Box style={{ marginTop: "5vh" }}>
        <Typography className={classes.header}>
          What We Use Your Data For
        </Typography>
        <Typography className={classes.content}>
          Sentro uses your data to provide its Services, communicate with you,
          provide measures to mitigate potential fraud or abuse in the platform,
          improve and update its Services, offer additional products,promotions
          or related services, and strengthen security and integrity of the
          Platform as a whole.
        </Typography>
      </Box>

      <Box style={{ marginTop: "5vh" }}>
        <Typography className={classes.header}>
          Who We Share Your Data With
        </Typography>
        <Typography className={classes.content}>
          Sentro shares certain data about you with our third-party service
          providers like banks, payment gateways,payment channels, payment
          aggregators and your social media providers. Sentro may also share
          yourdata in other ways if it is aggregated or de-identified or upon
          your consent.
        </Typography>
      </Box>

      <Box style={{ marginTop: "5vh" }}>
        <Typography className={classes.header}>Data Security</Typography>
        <Typography className={classes.content}>
          Sentro uses appropriate security based on the type and sensitivity of
          data being stored. As with any internet-enabled system, there is
          always a risk of unauthorized access. Thus, it is important to protect
          your password and user credentials. You have to immediately contact us
          if you suspect any unauthorized access to your Sentro account.
          <br />
          <br />
          Sentro takes appropriate security measures to protect against
          unauthorized access, alteration, disclosure,or destruction of your
          personal data that it collects and stores. These measures vary based
          on the type and sensitivity of the data. While Sentro has the
          appropriate security measures in place, it cannot guarantee that
          communications between you and Sentro, its Services, or any
          information you have provided to Sentro (in connection with the data
          it collects through the Services) always willbe free from unauthorized
          access by third parties. Your password and user credentials are
          important aspects of Sentro’s security system. While Sentro does its
          duty to keep the platform secure at all times, you also have to do
          your fair share in ensuring that your Sentro account and credentials
          are known only to you. Should you have reason to believe that your
          password or account has been compromised, you should change it
          immediately and contact Sentro for any concerns.
        </Typography>
      </Box>

      <Box style={{ marginTop: "5vh" }}>
        <Typography className={classes.header}>
          Your Data Privacy Rights
        </Typography>
        <Typography className={classes.content}>
          You have certain rights around the use of your data, including the
          ability to opt out of promotional emails,and collection of your data
          by certain analytics providers. You can update or terminate your
          account from Sentro and can also contact us for individual rights
          requests about your personal data.
        </Typography>

        <Typography className={classes.content}>
          Based on reasonable grounds, you have the right to suspend, withdraw 
          or order blocking, removal or destruction of your personal data from 
          our filing system, without prejudice to UBX’s continuous processing for 
          commercial, operational, legal, and regulatory purposes. To request for 
          removal of your personal data please contact our Data Protection Officer 
          (DPO) at dpo@ubx.ph. Our DPO will coordinate with the technical team to 
          remove your personal data within seven (7) working days, subject to any 
          additional documentation that the DPO may require. You will receive an 
          email confirmation once the data has been successfully removed.
        </Typography>
      </Box>

      <Box style={{ marginTop: "5vh" }}>
        <Typography className={classes.header}>Contact Us</Typography>
        <Typography className={classes.content}>
          If you have any questions, concerns, or disputes regarding our Privacy
          Policy, please feel free to contact our Data Protection Officer at{" "}
          <a href="mailto:dpo@ubx.ph">dpo@ubx.ph</a>
        </Typography>
      </Box>

      <Box style={{ marginTop: "5vh" }}>
        <img src={NPCLogo} width={120} alt="NPC Logo" loading="lazy" />
      </Box>
    </div>
  );
};

export default PrivacyPolicy;
