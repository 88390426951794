import React from "react";
import Content from "./content";
import Footer from "../../../../homepage/components/footer";

const Layout = props => {
  return (
    <div>
      <Content />
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
